import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';

import { AppChannelSettingsTypes } from './appChannelSettings.actions';
import * as action from './appChannelSettings.actions';
import {
  GetAppChannelSettings,
  SetAppChannelSettings
} from 'src/models/AppChannelSettings';

@Injectable()
export class AppChannelSettingsEffects {
  @Effect({ dispatch: false })
  setAppChannelSettings = this.actions$.pipe(
    ofType(AppChannelSettingsTypes.UI_SET_APP_CHANNEL_SETTINGS),
    map((action: action.SetAppChannelSettings) => {
      this._socketGateway.sendSocketMessage(
        new SetAppChannelSettings(action.whiteListEnabled)
      );
    })
  );

  @Effect({ dispatch: false })
  getAppChannelSettings = this.actions$.pipe(
    ofType(AppChannelSettingsTypes.UI_GET_APP_CHANNEL_SETTINGS),
    map((action: action.GetAppChannelSettings) => {
      this._socketGateway.sendSocketMessage(new GetAppChannelSettings());
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
