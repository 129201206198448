import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { CalendarTimetable } from 'src/models/Calendar';

@Component({
  selector: 'app-preview-calendar',
  templateUrl: './preview-calendar.component.html',
  styleUrls: ['./preview-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewCalendarComponent {
  @Input() hideFooter = false;
  @Input() selectedCalendar: CalendarTimetable;
  @Input() autoAcceptCalendar = false;
  @Input() isMenuAttache = false;
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  setAutoAcceptCalendar(e: boolean) {
    this.autoAcceptCalendar = e;
  }
  getCalendar() {
    return this.selectedCalendar;
  }

  getAutoAcceptCalendar(): boolean {
    return this.autoAcceptCalendar;
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }
}
