/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-file.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../media-center-loading-cover/media-center-loading-cover.component.ngfactory";
import * as i3 from "../media-center-loading-cover/media-center-loading-cover.component";
import * as i4 from "../../shared/pipes/file-size/file-size.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./media-center-file.component";
import * as i7 from "../media-center.service";
var styles_MediaCenterFileComponent = [i0.styles];
var RenderType_MediaCenterFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterFileComponent, data: {} });
export { RenderType_MediaCenterFileComponent as RenderType_MediaCenterFileComponent };
function View_MediaCenterFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-loading-cover", [], null, [[null, "hideMediaCover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hideMediaCover" === en)) {
        var pd_0 = (_co.hideCover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MediaCenterLoadingCoverComponent_0, i2.RenderType_MediaCenterLoadingCoverComponent)), i1.ɵdid(1, 638976, null, 0, i3.MediaCenterLoadingCoverComponent, [], { type: [0, "type"], size: [1, "size"], status: [2, "status"], progress: [3, "progress"] }, { hideMediaCover: "hideMediaCover" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mediaType; var currVal_1 = _co.fileSize; var currVal_2 = _co.mediaStatus; var currVal_3 = _co.progress; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_MediaCenterFileComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.FileSizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "file-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterFileComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "file-holder-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "file-holder-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-PDF"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "file-holder-text truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "header truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCover; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fileName; _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.fileSize)); _ck(_v, 12, 0, currVal_2); }); }
export function View_MediaCenterFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-file", [], null, null, null, View_MediaCenterFileComponent_0, RenderType_MediaCenterFileComponent)), i1.ɵdid(1, 114688, null, 0, i6.MediaCenterFileComponent, [i7.MediaCenterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediaCenterFileComponentNgFactory = i1.ɵccf("app-media-center-file", i6.MediaCenterFileComponent, View_MediaCenterFileComponent_Host_0, { url: "url", fileName: "fileName", localMedia: "localMedia", progress: "progress", mediaStatus: "mediaStatus", fileSize: "fileSize" }, {}, []);
export { MediaCenterFileComponentNgFactory as MediaCenterFileComponentNgFactory };
