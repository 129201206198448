import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-standard-date',
  template: `
    <ng-content></ng-content>
    <ng-template [ngIf]="!isPost">
      {{
        (todayDate | date: 'yMd') === (timestamp | date: 'yMd')
          ? (timestamp | date: 'hh:mm a')
          : (timestamp | date: timesFormat)
      }}
    </ng-template>
    <ng-template [ngIf]="isPost">
      {{
        (todayDate | date: 'y') === (timestamp | date: 'y')
          ? (timestamp | date: "MMM dd 'at' hh:mm a")
          : (timestamp | date: "MMM dd,y 'at' hh:mm a")
      }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StandardDateComponent {
  @Input()
  timestamp: string;
  @Input()
  timesFormat: string = 'MMM dd,y';
  @Input()
  isPost: boolean;
  todayDate: Date;

  constructor() {
    this.todayDate = new Date();
  }
}
