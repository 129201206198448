import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ComponentActions from './component.actions';

import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { MessageTypes } from 'src/models/constants';
import { ImageSet } from 'src/models/ChannelAppConfig';
import {
  LocalComponent,
  LocalContainer
} from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class ComponentDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateComponent(
    componentRef: string,
    componentUpdatedProps: LocalComponent
  ): void {
    this._store.dispatch(
      new ComponentActions.UpdateComponent(componentRef, componentUpdatedProps)
    );
  }

  reorderComponent(componentRef: string, newPosition: number): void {
    this._store.dispatch(
      new ComponentActions.ReorderComponent(componentRef, newPosition)
    );
  }

  openSection(component: LocalComponent): void {
    this._store.dispatch(new ComponentActions.OpenSection(component));
  }

  closeSection(component: LocalComponent): void {
    this._store.dispatch(new ComponentActions.CloseSection(component));
  }

  deleteComponentRequest(component: LocalComponent): void {
    this._store.dispatch(
      new ComponentActions.DeleteComponentRequest(component)
    );
  }

  deleteComponent(component: LocalComponent): void {
    this._store.dispatch(new ComponentActions.DeleteComponent(component));
  }

  deleteComponentsOfContainer(container: LocalContainer): void {
    this._store.dispatch(
      new ComponentActions.DeleteComponentsOfContainer(container)
    );
  }

  addComponents(components: LocalComponent[]): void {
    this._store.dispatch(new ComponentActions.AddComponents(components));
  }

  addComponentRequest(parentContainer: any): void {
    this._store.dispatch(
      new ComponentActions.AddComponentRequest(parentContainer)
    );
  }

  addComponent(component: LocalComponent): void {
    this._store.dispatch(new ComponentActions.AddComponent(component));
  }

  uploadComponentMedia(
    selectedFile: ISelectedFiles,
    component: LocalComponent,
    isBackground?: boolean
  ): void {
    this._store.dispatch(
      new ComponentActions.UploadComponentMedia(
        selectedFile,
        component,
        isBackground
      )
    );
  }

  downloadComponentMedia(
    imageId: string,
    blobType: MessageTypes,
    component: LocalComponent
  ): void {
    this._store.dispatch(
      new ComponentActions.DownloadComponentMedia(imageId, blobType, component)
    );
  }

  componentMediaUploadSuccess(
    imageUrl: string,
    localImage: string,
    component: LocalComponent,
    isBackground?: boolean
  ): void {
    this._store.dispatch(
      new ComponentActions.ComponentMediaUploadSuccess(
        imageUrl,
        localImage,
        component,
        isBackground
      )
    );
  }

  componentMediaDownloadSuccess(
    localImage: string,
    component: LocalComponent
  ): void {
    this._store.dispatch(
      new ComponentActions.ComponentMediaDownloadSuccess(localImage, component)
    );
  }

  selectComponent(selectedComponent: LocalComponent): void {
    this._store.dispatch(
      new ComponentActions.SelectComponent(selectedComponent)
    );
  }

  UploadSplashPageLogo(componentRef: string, selectedFile: ISelectedFiles) {
    this._store.dispatch(
      new ComponentActions.UploadSplashPageLogo(componentRef, selectedFile)
    );
  }

  uploadVideoBackground(
    componentRef: string,
    currentPage: string,
    selectedFile: ISelectedFiles
  ) {
    this._store.dispatch(
      new ComponentActions.UploadVideoBackground(
        componentRef,
        currentPage,
        selectedFile
      )
    );
  }

  UploadSplashPageLogoSuccess(
    componentRef: string,
    imageUrl: string,
    imageSet: ImageSet
  ) {
    this._store.dispatch(
      new ComponentActions.UploadSplashPageLogoSuccess(
        componentRef,
        imageUrl,
        imageSet
      )
    );
  }
}
