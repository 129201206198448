import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';

@Component({
  selector: 'app-no-support',
  templateUrl: './no-support.component.html',
  styleUrls: ['./no-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoSupportComponent implements OnInit {
  constructor(
    public _dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit() {}
  openLink(link) {
    window.open(link);
  }
  logOut() {
    this._dashboardService.logout();
    this.router.navigate(['/login']);
  }
}
