import {
  DELETE_COLLECTION_ELEMENT_METHOD,
  GET_COLLECTION_ITEM_METHOD,
  GET_COLLECTION_PRODUCTS_ITEM_METHOD,
  GET_COLLECTION_PRODUCT_METHOD,
  LIST_COLLECTION_ITEM_METHOD,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE,
  PageDirection,
  SCREEN_STATUS,
  SET_COLLECTION_ITEM_METHOD
} from './constants';
import { ProductStore } from './productStore';

export interface CollectionUI {
  currentPage?: Collection[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  productGroupId?: number;
  ownerGroupId?: string;
  selectedItem?: Collection;
  isItemSelected?: boolean;
  selectedItems?: Collection[];
}

export interface Collection {
  id?: number;
  name?: string;
  status?: string;
  description?: string;
  image?: CollectionUIImage[];
  product_style?: string;
  products?: ProductStore[];
  json?: any;
  soft_id?: string;
}

export interface CollectionUIImage {
  width?: number;
  height?: number;
  url?: string;
}

export class GetListCollectionsMethod {
  readonly page_size = 100;
  readonly method = LIST_COLLECTION_ITEM_METHOD;
  constructor(public direction?: number, public reference?: number) {}
}

export class SetCollectionMethod {
  readonly method = SET_COLLECTION_ITEM_METHOD;
  constructor(
    public id?: number,
    public name?: string,
    public status?: string,
    public description?: string,
    public image?: CollectionUIImage[],
    public product_style?: string,
    public json?: any,
    public soft_id?: string
  ) {}
}
export class GetCollectionMethod {
  readonly method = GET_COLLECTION_ITEM_METHOD;
  constructor(public id?: number) {}
}

export class SetCollectionProductMethod {
  readonly method = GET_COLLECTION_PRODUCTS_ITEM_METHOD;
  constructor(public collection_id?: number, public product_id?: number[]) {}
}

export class DeleteCollectionMethod {
  readonly method = DELETE_COLLECTION_ELEMENT_METHOD;
  constructor(public collection_id?: number) {}
}
export class GetCollectionProductsMethod {
  readonly method = GET_COLLECTION_PRODUCT_METHOD;
  constructor(public id?: number) {}
}
