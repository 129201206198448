import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UISections } from 'src/models/constants';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent implements OnInit, OnChanges {
  @Input() sectionLink: string;
  @Input() isStartOver: string;
  @Output() cancelNotifier = new EventEmitter<boolean>(false);

  uiSections: typeof UISections = UISections;

  url;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['sectionLink'];
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(chng.currentValue);
  }

  closeHelpWindow() {
    this.cancelNotifier.emit(true);
  }

  openDocumentWeb() {
    window.open('https://docs.nandbox.com', '_blank');
  }
}
