import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE,
  GET_BLACKLIST_METHOD,
  ADD_BLACKLIST,
  DELETE_BLACKLIST_METHOD,
  GET_BLACKLIST_PATTERNS_METHOD,
  ADD_BLACKLIST_PATTERNS_METHOD,
  DELETE_BLACKLIST_PATTERNS_METHOD
} from './constants';
import { AbstractTableColumn } from './AbstractItem';

export interface BlackListsUI {
  currentPage?: BlackList[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  msisdn?: string;
  screenStatus?: string;
}

export interface BlackList {
  id?: string;
  msisdn?: string;
  pattern?: string;
  example?: string;
  reference?: string;
  recordIndex?: number;
}

export const blacklistsTableColumns: AbstractTableColumn[] = [
  {
    colType: 'Block Method',
    title: 'ID',
    class: 'md'
  },
  {
    colType: 'name',
    title: 'Signup User',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'action'
  }
];
export const blacklistsTablePatternColumns: AbstractTableColumn[] = [
  {
    colType: 'Block Method',
    title: 'ID',
    class: 'md'
  },
  {
    colType: 'name',
    title: 'Signup Template',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'action'
  }
];

export class GetBlackList {
  readonly method = GET_BLACKLIST_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public tester?: number,
    public direction?: number,
    public hash?: string,
    public reference?: number,
    public msisdn?: string
  ) {}
}

export class AddUserToBlacklist {
  readonly method = ADD_BLACKLIST;
  constructor(
    public users: string[],
    public chat_id: string,
    public reference?: string,
    public recordIndex?: number
  ) {}
}

export class RemoveUserFromBlacklist {
  readonly method = DELETE_BLACKLIST_METHOD;
  constructor(public users: string[], public chat_id: string) {}
}

export class GetBlacklistPattern {
  readonly method = GET_BLACKLIST_PATTERNS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;

  constructor(
    public tester?: number,
    public direction?: number,
    public hash?: string,
    public reference?: number
  ) {}
}

export class AddPatternToBlacklist {
  readonly method = ADD_BLACKLIST_PATTERNS_METHOD;
  constructor(
    public data: BlackList[],
    public chat_id: string,
    public reference?: string,
    public recordIndex?: number
  ) {}
}
export class RemovePatternFromBlacklist {
  readonly method = DELETE_BLACKLIST_PATTERNS_METHOD;
  constructor(public pattern: string[], public chat_id: string) {}
}
