import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as BookingExceptionsActions from './bookingExceptions.actions';

import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class BookingExceptionsDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveBookingExceptions(chatId: string, dates: string[]): void {
    this._store.dispatch(
      new BookingExceptionsActions.ReceiveBookingExceptions(chatId, dates)
    );
  }

  setBookingExceptions(
    chatId: string,
    dates: string[],
    recordIndex?: number
  ): void {
    this._store.dispatch(
      new BookingExceptionsActions.SetBookingExceptions(
        chatId,
        dates,
        recordIndex
      )
    );
  }

  deleteBookingExceptions(chatId: string, dates: string[]): void {
    this._store.dispatch(
      new BookingExceptionsActions.DeleteBookingExceptions(chatId, dates)
    );
  }
}
