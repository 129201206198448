import { Store } from '@ngrx/store';
import * as otherDriverTripsActionTypes from './otherDriverTrips.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class OtherTripsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getOtherTrips(trip_id, account_id) {
        this._store.dispatch(new otherDriverTripsActionTypes.GetOtherTrips(trip_id, account_id));
    }
    driverTripsReceived(payload) {
        this._store.dispatch(new otherDriverTripsActionTypes.OtherTipsReceived(payload));
    }
}
OtherTripsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OtherTripsDispatchers_Factory() { return new OtherTripsDispatchers(i0.ɵɵinject(i1.Store)); }, token: OtherTripsDispatchers, providedIn: "root" });
