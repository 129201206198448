import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FACEBOOK_SHARE_LINK,
  TWITTER_SHARE_LINK,
  GOOGLEPLUS_SHARE_LINK,
  LINK_SHARED_SUCCESSFULLY
} from 'src/models/constants';
import { AbstractNotifiersService } from 'src/app/abstract-notifiers/abstract-notifiers.service';

@Component({
  selector: 'app-post-share',
  templateUrl: './post-share.component.html',
  styleUrls: ['./post-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostShareComponent implements OnChanges {
  @Input() shareLink: string;

  sharedWindow = null;
  shareOn = '';

  constructor(private _abstractNotifier: AbstractNotifiersService) {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['shareLink'];
    if (chng && chng.currentValue && chng.currentValue !== chng.previousValue) {
      this.changeSocialWindowLocation();
    }
  }

  copySuccessfullyEvent() {
    this._abstractNotifier.showSnakbarNotifierMessage(
      LINK_SHARED_SUCCESSFULLY,
      false
    );
  }

  shareOnSocial(e: Event, network: string) {
    const w = 580;
    const h = 296;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    const windowStyles = `toolbar=no,
                          location=no,
                          directories=no,
                          status=no,
                          menubar=no,
                          scrollbars=no,
                          resizable=no,
                          copyhistory=no,
                          width=${w},
                          height=${h},
                          top=${top},
                          left=${left}`;
    if (!this.shareLink) {
      this.shareOn = network;
    }
    if (e) {
      e.preventDefault();
      switch (network) {
        case 'F':
          this.sharedWindow = window.open(
            FACEBOOK_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        case 'T':
          this.sharedWindow = window.open(
            TWITTER_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        case 'G':
          this.sharedWindow = window.open(
            GOOGLEPLUS_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        default:
          break;
      }
    }
  }

  changeSocialWindowLocation() {
    switch (this.shareOn) {
      case 'F':
        this.sharedWindow.location = FACEBOOK_SHARE_LINK + this.shareLink;
        break;
      case 'T':
        this.sharedWindow.location = TWITTER_SHARE_LINK + this.shareLink;
        break;
      case 'G':
        this.sharedWindow.location = GOOGLEPLUS_SHARE_LINK + this.shareLink;
        break;
      default:
        break;
    }
  }
}
