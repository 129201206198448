import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as MapDriverMethods from 'src/models/MapDriver';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MapDriverActionTypes } from './mapTripDriver.actions';
import * as MapDriverActions from './mapTripDriver.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class MapDriverEffects {
  @Effect({ dispatch: false })
  getDriverAssigned = this.actions$.pipe(
    ofType(MapDriverActionTypes.GET_MAP_TRIPS_DRIVER),
    map((action: MapDriverActions.GetMapDriver) => {
      this._socketGateway.sendSocketMessage(
        new MapDriverMethods.GetMapTripUser(action.trip_id)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteDriverAssigned = this.actions$.pipe(
    ofType(MapDriverActionTypes.DELETE_MAP_TRIPS_DRIVER),
    map((action: MapDriverActions.DeleteMapDriver) => {
      this._socketGateway.sendSocketMessage(
        new MapDriverMethods.DeleteMapTripUser(
          action.account_id,
          action.trip_id
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>
  ) {}
}
