import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';

import { AllGroupActionTypes } from './allGroup.actions';
import * as action from './allGroup.actions';
import * as MessagesMethods from 'src/models/IMessage';
import { ChatDispatchers } from '../chats/chat.dispatchers';

@Injectable()
export class AllGroupEffects {
  @Effect({ dispatch: false })
  setAppChannelSettings = this.actions$.pipe(
    ofType(AllGroupActionTypes.UI_RECEIVE_ALL_GROUP),
    map((action: action.ReceiveAllGroup) => {
      if (action.chatGroups.length > 0) {
        // action.chatGroups.forEach(res => {
        //   this.getChatHistoryFromBusinessServer(
        //     0,
        //     10,
        //     null,
        //     null,
        //     null,
        //     res.id
        //   );
        //   this.chatDispatchers.getChatAdministrators(res.id);
        // });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private chatDispatchers: ChatDispatchers,
    private _socketGateway: SocketGateway
  ) { }

  private getChatHistoryFromBusinessServer(
    reference: number,
    page_size: number,
    reply_to_message_id?: string,
    lv?: number,
    user_id?: string,
    chat_id?: string
  ) {
    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetMessagesHistoryFromBusinessServer(
        null,
        reply_to_message_id,
        null,
        null,
        null,
        null,
        null,
        null,
        user_id,
        page_size,
        lv,
        reference,
        0,
        chat_id
      )
    );
  }
}
