import { merge, of, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractNotifiersService } from './abstract-notifiers/abstract-notifiers.service';
import * as i0 from "@angular/core";
import * as i1 from "./abstract-notifiers/abstract-notifiers.service";
export class AppService {
    constructor(abstractNotifiersService) { }
    get online$() {
        return merge(of(navigator.onLine), fromEvent(window, 'online').pipe(map(() => true)), fromEvent(window, 'offline').pipe(map(() => false)));
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.AbstractNotifiersService)); }, token: AppService, providedIn: "root" });
