import { Injectable } from '@angular/core';
import { AbstractItem } from 'src/models/AbstractItem';
import { Observable } from 'rxjs';
import { AbstractUI } from 'src/models/AbstractUI';

@Injectable({
  providedIn: 'root'
})
export abstract class TableService {
  abstract selectedItems: string[];
  abstract dismissedAdmins: string[];
  abstract adminsRemove: string[];

  constructor() {}

  // the  list observable selectedChatMembers
  abstract get list$(): Observable<AbstractItem[]>;

  abstract getNextPage(name?: string): void;

  abstract getPreviousPage(name?: string): void;

  abstract resetPaging(
    name?: string,
    status?: any,
    view?: number,
    accountTypes?: string[]
  ): void;

  abstract setSearchBy(val?: number): void;

  updateSelectedItems(addedList: string[], removedList: string[]): void {
    let newListAfterDeletion: string[] = [];
    if (removedList && removedList.length > 0) {
      newListAfterDeletion = this.selectedItems.filter(
        itemId => !removedList.find(id => id === itemId)
      );
    }
    this.selectedItems = [...newListAfterDeletion];

    if (addedList && addedList.length > 0) {
      addedList.forEach(addedItem => {
        if (!newListAfterDeletion.find(id => id === addedItem)) {
          this.selectedItems.push(addedItem);
        }
      });
    }
  }

  updateDismissedAdmins(admins) {
    this.dismissedAdmins = [...admins];
  }

  updateAdminsRemove(admins) {
    this.adminsRemove = [...admins];
  }

  resetSelectedItems(): void {
    this.selectedItems = [];
  }

  abstract get statusUI$(): Observable<AbstractUI>;
}
