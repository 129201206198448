import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { WhitelistPatternsUiDispatchers } from './whitelistPatternsUI.dispatchers';
import * as WhitelistUI from 'src/models/Whitelist';
import { PageDirection } from 'src/models/constants';
export class WhitelistPatternsUIEffects {
    constructor(actions$, _socketGateway, _whitelistPatternsUiDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._whitelistPatternsUiDispatchers = _whitelistPatternsUiDispatchers;
        this._store = _store;
        this.afterWhitelistResetPaging = this.actions$.pipe(ofType("UI_WHITELIST_PATTERNS_RESET_PAGING" /* UI_WHITELIST_PATTERNS_RESET_PAGING */), map((action) => {
            this._whitelistPatternsUiDispatchers.getNextWhitelistPatternsPage();
        }));
        this.getNextWhitelistPage = this.actions$.pipe(ofType("UI_GET_NEXT_WHITELIST_PATTERNS_PAGE" /* UI_GET_NEXT_WHITELIST_PATTERNS_PAGE */), withLatestFrom(this._store
            .select(state => state.whitelistPatternsUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, whitelistPatternsUiState]) => {
            const action = (val);
            const prevRequest = whitelistPatternsUiState.previousRequest;
            if (!(prevRequest.eop === whitelistPatternsUiState.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                prevRequest.hash === whitelistPatternsUiState.hash)) {
                this._socketGateway.sendSocketMessage(new WhitelistUI.GetWhitelistPatternsMethod(null, 0, whitelistPatternsUiState.hash, whitelistPatternsUiState.eop));
            }
            else {
                this._whitelistPatternsUiDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevWhitelistPage = this.actions$.pipe(ofType("UI_GET_PREV_WHITELIST_PATTERNS_PAGE" /* UI_GET_PREV_WHITELIST_PATTERNS_PAGE */), withLatestFrom(this._store
            .select(state => state.whitelistPatternsUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, whitelistPatternsUiState]) => {
            const action = (val);
            const prevRequest = whitelistPatternsUiState.previousRequest;
            if (!(prevRequest.sop === whitelistPatternsUiState.sop &&
                PageDirection.PREV === prevRequest.direction &&
                prevRequest.hash === whitelistPatternsUiState.hash)) {
                this._socketGateway.sendSocketMessage(new WhitelistUI.GetWhitelistPatternsMethod(null, 1, whitelistPatternsUiState.hash, whitelistPatternsUiState.sop));
            }
            else {
                this._whitelistPatternsUiDispatchers.setStatusAsIdle();
            }
        }));
        this.addPatternWhitelistToList = this.actions$.pipe(ofType("UI_ADD_WHITELIST_PATTERNS_TO_LIST" /* UI_ADD_WHITELIST_PATTERNS_TO_LIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new WhitelistUI.AddPatternToWhitelist(action.chat_id, action.users));
        }));
        this.deletePatternFromWhitelist = this.actions$.pipe(ofType("UI_DELETE_PATTERNS_FROM_WHITELIST" /* UI_DELETE_PATTERNS_FROM_WHITELIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new WhitelistUI.RemovePatternFromWhitelist(action.users, action.chat_id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], WhitelistPatternsUIEffects.prototype, "afterWhitelistResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], WhitelistPatternsUIEffects.prototype, "getNextWhitelistPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], WhitelistPatternsUIEffects.prototype, "getPrevWhitelistPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], WhitelistPatternsUIEffects.prototype, "addPatternWhitelistToList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], WhitelistPatternsUIEffects.prototype, "deletePatternFromWhitelist", void 0);
