import { GET_APP_TEMPLATE_LIST } from './constants';

export interface AppTemplate {
  id?: string;
  ios_image_url?: string;
  android_image_url?: string;
  selected?: boolean;
}

export class GetTemplatesByCategory {
  readonly method = GET_APP_TEMPLATE_LIST;
  constructor(public category: string, public id: string) {}
}
