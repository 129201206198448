import { Store } from '@ngrx/store';
import * as Billing from './billingUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BillingUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receivedSelecetedPLan(planID, cancelEnd) {
        this._store.dispatch(new Billing.ReceivedSelecetedPLan(planID, cancelEnd));
    }
    setCurrentScreen(screen, lastScreen) {
        this._store.dispatch(new Billing.SetCurrentScreen(screen, lastScreen));
    }
    setCurrentSelectedPlan(plan) {
        this._store.dispatch(new Billing.SetCurrentSelectedPlan(plan));
    }
    sendSubscribetionToPlan(subscribeDetails, lm_data) {
        this._store.dispatch(new Billing.SendSubscribetionToPlan(subscribeDetails, lm_data));
    }
    sendCoupon(coupon) {
        this._store.dispatch(new Billing.SendCouponUI(coupon));
    }
    addNewCreditCard(token) {
        this._store.dispatch(new Billing.AddNewCreditCardUI(token));
    }
    setDefaultCardUI(card_id) {
        this._store.dispatch(new Billing.SetDefaultCardUI(card_id));
    }
    deleteCreditCardUI(card_id) {
        this._store.dispatch(new Billing.DeleteCreditCardUI(card_id));
    }
    getInvoicesListUI() {
        this._store.dispatch(new Billing.GetInvoicesListUI());
    }
    getBillingInfoUI() {
        this._store.dispatch(new Billing.GetBillingInfoUI());
    }
    receveInvoicesLisUI(list) {
        this._store.dispatch(new Billing.ReceveInvoicesListUI(list));
    }
    changePlanRequest(new_plan_id, coupon) {
        this._store.dispatch(new Billing.ChangePlanRequest(new_plan_id, coupon));
    }
    subscribeToAddonsRequest(plan_id, coupon) {
        this._store.dispatch(new Billing.SubscribeToAddonsRequest(plan_id, coupon));
    }
    receveStripeErrorMasage(msg) {
        this._store.dispatch(new Billing.ReceveStripeErrorMasage(msg));
    }
    cancelStripeSubsCription(all, plan_id, reason, subMain, cancelQuantity) {
        this._store.dispatch(new Billing.CancelStripeSubsCription(all, plan_id, reason, subMain, cancelQuantity));
    }
    setBillingStatus(status) {
        this._store.dispatch(new Billing.SetBillingStatus(status));
    }
    receveCouponResponse(coupon) {
        this._store.dispatch(new Billing.ReceveCouponResponse(coupon));
    }
    receveBillingInfoResponse(data, main_plan) {
        this._store.dispatch(new Billing.ReceveBillingInfoResponse(data, main_plan));
    }
    goToLastSection() {
        this._store.dispatch(new Billing.GoToLastSection());
    }
    setBillingPeriodStatus(status) {
        this._store.dispatch(new Billing.SetBillingPeriodStatus(status));
    }
    revokeCancelMainPlan() {
        this._store.dispatch(new Billing.RevokeCancelMainPlan());
    }
    receveEndFreeTrileDate(date) {
        this._store.dispatch(new Billing.ReceveEndFreeTrileDate(date));
    }
    receveIsMainPlanExpire(status) {
        this._store.dispatch(new Billing.ReceveIsMainPlanExpire(status));
    }
}
BillingUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BillingUiDispatchers_Factory() { return new BillingUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: BillingUiDispatchers, providedIn: "root" });
