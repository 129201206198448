<div class="no-support">
  <a
    class="app-logo clickable-cursor"
    href="https://nandbox.com"
    target="_blank"
  >
    <img
      class="main-logo"
      src="./assets/img/no-sport-logo-large.svg"
      height="50"
      draggable="false"
    />
    <img
      class="small-logo"
      src="./assets/img/no-sport-logo-small.svg"
      height="50"
      draggable="false"
    />
  </a>
  <button class="logout" (click)="logOut()">
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-logout"></use>
    </svg>
    Log Out
  </button>
  <div class="no-support-content">
    <div class="no-support-details">
      <img class="lap" src="/assets/img/mac-book.png" alt="mac-book" />
      <div class="no-support-header">
        Use a desktop to access nandbox App Builder
      </div>
      <div class="no-support-tagline">
        nandbox App builder is not avaliable for mobile, please login from a
        desktop.
      </div>
      <div class="actions">
        <button
          class="support"
          (click)="
            openLink(
              'https://calendly.com/nandbox-app-builder/create-your-own-app-in-minutes-schedule-a-call-to-explore-your-no-code-app-building-requirements'
            )
          "
        >
          Free Consultation: Act Now!
        </button>
        <button
          class="videos"
          (click)="
            openLink(
              'https://www.youtube.com/watch?v=kdNj3-ecrT0&list=PL7l4b3PO2OO6DFCQ5DmNs3FhVpk5D-LeG&index=1'
            )
          "
        >
          <img src="/assets/img/play-icon.svg" /> Watch Videos
        </button>
      </div>
    </div>

    <footer class="footer">
      <div class="footer-term">
        <div
          cla
          style="padding-right: 15px;"
          class="link"
          (click)="openLink('https://nandbox.com')"
        >
          © 2023 nandbox ® Inc.
        </div>
        <div
          class="service link"
          (click)="openLink('https://mailchimp.com/legal/terms/')"
        >
          Terms of Service
        </div>
        <div
          style="padding-left: 15px;"
          class="link"
          (click)="openLink('https://nandbox.com/privacy-policy/')"
        >
          Privacy Policy
        </div>
      </div>
      <div class="footer-policy">
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <div
            class="service link"
            (click)="openLink('https://nandbox.com/terms/')"
          >
            Terms of Service
          </div>
          <div
            style="padding-left: 15px;"
            class="link"
            (click)="openLink('https://nandbox.com/privacy-policy/')"
          >
            Privacy Policy
          </div>
        </div>
        <div class="link" (click)="openLink('https://nandbox.com')">
          © 2023 nandbox ® Inc.
        </div>
      </div>
      <div class="version">nandbox App Builder - Version 1.6.145.</div>
    </footer>
  </div>
</div>
