/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-article-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-medium-editor";
import * as i3 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i4 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i5 from "@angular/common";
import * as i6 from "./instant-article-editor.component";
import * as i7 from "../instant-article.service";
import * as i8 from "../../../../network/gateway/upload.gateway";
import * as i9 from "../../../../core/file.service";
var styles_InstantArticleEditorComponent = [i0.styles];
var RenderType_InstantArticleEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantArticleEditorComponent, data: {} });
export { RenderType_InstantArticleEditorComponent as RenderType_InstantArticleEditorComponent };
function View_InstantArticleEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Done "]))], null, null); }
function View_InstantArticleEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
export function View_InstantArticleEditorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { imageInputFile: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 27, "div", [["class", "edit-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "edit-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "input-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Title"])), (_l()(), i1.ɵeld(7, 0, [["titleHeader", 1]], null, 0, "input", [["class", "title-row single-line"], ["contenteditable", ""], ["placeholder", "Enter your article title"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setTitle(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "input-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Writer Name"])), (_l()(), i1.ɵeld(11, 0, [["authorFiled", 1]], null, 0, "input", [["class", "author-row single-line"], ["contenteditable", ""], ["placeholder", "Enter your name"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setAuthor(i1.ɵnov(_v, 11).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [["class", "input-article"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Article"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "article"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "medium-editor", [], null, [[null, "editorModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editorModelChange" === en)) {
        var pd_0 = ((_co.textVar = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 737280, null, 0, i2.MediumEditorDirective, [i1.ElementRef], { model: [0, "model"], options: [1, "options"], placeholder: [2, "placeholder"] }, { update: "editorModelChange" }), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "image-upload clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [["for", "file-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "far fa-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, [[1, 0], ["imageInputFile", 1]], null, 0, "input", [["accept", "image/*"], ["id", "file-input"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.getFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "app-border-btn", [["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.saveDraft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BorderBtnComponent_0, i3.RenderType_BorderBtnComponent)), i1.ɵdid(24, 573440, null, 0, i4.BorderBtnComponent, [], { disabled: [0, "disabled"] }, { btnClicked: "btnClicked" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InstantArticleEditorComponent_1)), i1.ɵdid(26, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InstantArticleEditorComponent_2)), i1.ɵdid(28, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textVar; var currVal_1 = _co.editorOptions; var currVal_2 = _co.placeholderVar; _ck(_v, 17, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.canSend; _ck(_v, 24, 0, currVal_3); var currVal_4 = !_co.uploading; _ck(_v, 26, 0, currVal_4); var currVal_5 = _co.uploading; _ck(_v, 28, 0, currVal_5); }, null); }
export function View_InstantArticleEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instant-article-editor", [], null, null, null, View_InstantArticleEditorComponent_0, RenderType_InstantArticleEditorComponent)), i1.ɵdid(1, 311296, null, 0, i6.InstantArticleEditorComponent, [i7.InstantArticleService, i8.UploadGateway, i9.FileService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantArticleEditorComponentNgFactory = i1.ɵccf("app-instant-article-editor", i6.InstantArticleEditorComponent, View_InstantArticleEditorComponent_Host_0, {}, { cancelNotfiy: "cancelNotfiy", publishNotfiy: "publishNotfiy" }, []);
export { InstantArticleEditorComponentNgFactory as InstantArticleEditorComponentNgFactory };
