import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as AuthActions from './auth.actions';
import { AuthDispatchers } from './auth.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MessageDispatchers } from '../messages/message.dispatchers';
import { SettingsService } from 'src/app/+merchant/+dashboard/settings/settings.service';
import * as AuthMethods from 'src/models/IAuth';
import * as ChatMethods from 'src/models/IChat';
import * as TagMethods from 'src/models/ITag';
import * as ChatMembersMethods from 'src/models/IChatMember';
import { APP_MENU_CATEGORIES, CHANNEL, CHANNEL_APP_SEARCH_CATEGORIES } from 'src/models/constants';
import { RequestMyBots, GetBotTemplates } from 'src/models/IProfile';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { RequestAppChannelData } from 'src/models/ChannelAppConfig';
import { GetAppsMethod } from 'src/models/MultipleApps';
import { GetAppsSetupMethod, GetAppStoreMethod } from 'src/models/AppPublishInfo';
import { GetMyPagesListRequest, GetInstantPagesRequest } from 'src/models/MyPageConfig';
import * as isEqual from 'lodash.isequal';
import { AuthService } from 'src/app/core/auth.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { RegistrationService } from 'src/app/+merchant/+registration/registration.service';
import * as MessagesMethods from 'src/models/IMessage';
import { ListAppMenus } from 'src/models/AppMenu';
import { ISAUTH } from 'src/models/constants';
import { StorageService } from 'src/app/core/storage.service';
import { SocketService } from 'src/app/network/services/socket.service';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
export class AuthEffects {
    constructor(_route, store$, actions$, _socketGateway, _authDispatcher, _uiDispacthers, _messageDispatchers, _authService, appManagementService, _settingsService, _rgstService, localStorage, wSocketService, _mainChatDispatchers) {
        this._route = _route;
        this.store$ = store$;
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._authDispatcher = _authDispatcher;
        this._uiDispacthers = _uiDispacthers;
        this._messageDispatchers = _messageDispatchers;
        this._authService = _authService;
        this.appManagementService = appManagementService;
        this._settingsService = _settingsService;
        this._rgstService = _rgstService;
        this.localStorage = localStorage;
        this.wSocketService = wSocketService;
        this._mainChatDispatchers = _mainChatDispatchers;
        this.requestNewQRcode = this.actions$.pipe(ofType(AuthActions.REQUEST_NEW_QRCODE), map(p => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetQRCode());
        }));
        this.requestQRcode = this.actions$.pipe(ofType(AuthActions.REQUEST_QRCODE), map(p => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetQRCode());
        }));
        this.reopenNewConnection = this.actions$.pipe(ofType(AuthActions.REOPEN_NEW_CONNECTION), map(p => {
            this._socketGateway.openNewConnection();
        }));
        this.rcvdMultiLogin = this.actions$.pipe(ofType(AuthActions.RECIVE_MULTI_LOGIN), map(p => {
            this._socketGateway.closeCurrentSocket();
        }));
        this.getChatData = this.actions$.pipe(ofType(AuthActions.GET_CHAT_DATA), map((action) => {
            this._socketGateway.sendSocketMessage(new ChatMethods.GetChatTemplate(action.chat_id));
        }));
        this.rcvdChatList = this.actions$.pipe(ofType(AuthActions.RECIVE_AUTH_CHAT_LIST), withLatestFrom(this.store$
            .select(state => state.authReducer.chatId)
            .pipe(distinctUntilChanged())), map(([val, chatID]) => {
            const action = val;
            // IF user already login before on another channel
            if (chatID) {
                // this._socketGateway.sendSocketMessage(
                //   new AuthMethods.SendSelectChat(chatID)
                // );
            }
            else if (action.payload) {
                // Get Chat Info
                const upgradedChannels = action.payload.filter(chat => chat.type === CHANNEL);
                // The Token auth OK does not return a ChatId in case of user login by QR as the message comes from the Home server not the business Server
                // Therefore there is no way to return the ChatId (defalut selected chat) as workaround a GetChat is called to return selected ChatId.
                // no chatId
                if (upgradedChannels.length == 1 &&
                    this.localStorage.getRecord('loginType') !== 'email') {
                    this._socketGateway.sendSocketMessage(new ChatMethods.GetChatTemplate(upgradedChannels[0].id));
                    // // Select chat
                    this._socketGateway.sendSocketMessage(new AuthMethods.SendSelectChat(upgradedChannels[0].id, ISAUTH.qrcode));
                }
                else if (action.payload.length > 1) {
                    this._authDispatcher.showAuthChatList();
                }
                else if (action.payload.length == 0) {
                    this._authDispatcher.logOut();
                }
            }
        }));
        this.tokenChangeRcvd = this.actions$.pipe(ofType(AuthActions.RECIVE_AUTH_TOKEN_CHANGE), withLatestFrom(this.store$
            .select(state => state.authReducer.url)
            .pipe(distinctUntilChanged())), map(([val, url]) => {
            const action = val;
            if (url) {
                this._socketGateway.openSocketWithUrl(url);
            }
        }));
        this.selectChat = this.actions$.pipe(ofType(AuthActions.SET_CHAT_SELECT), map((action) => {
            this._uiDispacthers.setAuthPrivilege([]);
            this._mainChatDispatchers.resetAllEndOfPage();
            this._socketGateway.sendSocketMessage(new AuthMethods.SendSelectChat(action.payload, action.is_auth));
        }));
        this.requestAdminQR = this.actions$.pipe(ofType(AuthActions.REQUEST_ADMIN_QR), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetAdminQRCode());
        }));
        /* after select channel in merchant request another requests */
        this.authChatSelect = this.actions$.pipe(ofType(AuthActions.RECEIVE_AUTH_CHAT_SELECTED), withLatestFrom(this.store$
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this.store$
            .select(state => state.authReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats, iAuth]) => {
            const action = val;
            const selectedMainChat = mainChats.find(mainChat => mainChat.id === action.payload.chatId);
            if (iAuth.role == 1 && iAuth.main_group_id === iAuth.chatId) {
                this._route.navigate(['/app']);
                // this.appManagementService.goToSpecificPage(1);
            }
            else {
                this._route.navigate(['/']);
            }
            if (!selectedMainChat) {
                this._socketGateway.sendSocketMessage(new ChatMethods.GetChatTemplate(action.payload.chatId));
            }
            const selectedChat = selectedMainChat
                ? selectedMainChat
                : { id: action.payload.chatId, type: CHANNEL, subChannel: true };
            // GetAuthPrivilege & Roles for all types of users
            // this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
            // this._socketGateway.sendSocketMessage(new ChatMembersMethods.GetRoles());
            // SetChat Selected;
            this._uiDispacthers.chatSelected(selectedChat);
        }));
        this.chatPrivilege = this.actions$.pipe(ofType(AuthActions.GET_CHAT_PRIVILLEGES), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
        }));
        this.roles = this.actions$.pipe(ofType(AuthActions.GET_ROLES), map((action) => {
            this._socketGateway.sendSocketMessage(new ChatMembersMethods.GetRoles());
        }));
        this.myProfiles = this.actions$.pipe(ofType(AuthActions.GET_MY_PROFILES), map((action) => {
            this._socketGateway.sendSocketMessage(new MessagesMethods.GetMyProfiles());
        }));
        this.upgradedChatList = this.actions$.pipe(ofType(AuthActions.GET_UPGRADED_CHAT_LIST_METHOD), map((action) => {
            this._socketGateway.sendSocketMessage(new MessagesMethods.GetUpgradedChatList(action.is_default));
        }));
        this.searchFilters = this.actions$.pipe(ofType(AuthActions.GET_SEARCH_FILTERS), map((action) => {
            const keys = [
                CHANNEL_APP_SEARCH_CATEGORIES.AREA,
                CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY,
                CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
            ];
            this._socketGateway.sendSocketMessage(new RequestAppChannelData(keys));
        }));
        this.appSetup = this.actions$.pipe(ofType(AuthActions.GET_APPS_SETUP), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppsMethod());
            this._socketGateway.sendSocketMessage(new GetAppStoreMethod());
            this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
        }));
        this.bots = this.actions$.pipe(ofType(AuthActions.GET_BOTS), map((action) => {
            this._socketGateway.sendSocketMessage(new RequestMyBots());
            this._socketGateway.sendSocketMessage(new GetBotTemplates());
            this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
            this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
            // need to be redesign
            // this._messageDispatchers.getLastPostsHistory();
            // setTimeout(() => {
            //   this._messageDispatchers.getLastRepliesHistory();
            // }, 5000);
            // this._messageDispatchers.listAwardMessages();
            // this._messageDispatchers.listScheduleMessages();
            this._socketGateway.sendSocketMessage(new TagMethods.GetTags());
            this._socketGateway.sendSocketMessage(new ListAppMenus(APP_MENU_CATEGORIES.MAP));
        }));
        this.receiveAuthPrivilege = this.actions$.pipe(ofType(AuthActions.RECEIVE_AUTH_PRIVILEGE), withLatestFrom(this.store$
            .select(s => s.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            this._uiDispacthers.setAuthPrivilege(action.payload);
        }));
        this.changePassword = this.actions$.pipe(ofType(AuthActions.CHANGE_PASSWORD), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.ChangePassword(action.payload.oldPassword, action.payload.newPassword, action.payload.confirmNewPassword));
        }));
        this.changePasswordSet = this.actions$.pipe(ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_SET), map((action) => {
            this._authDispatcher.logOut();
        }));
        this.changePasswordError = this.actions$.pipe(ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_ERROR), map((action) => {
            this._settingsService.setServerError(SERVER_ERROR_MESSAGES[action.payload]);
        }));
        this.registerDemoAccount = this.actions$.pipe(ofType(AuthActions.REGISTER_DEMO_ACCOUNT), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.RegisterDemoAccount(action.deviceId, action.os, action.osVersion, action.msisdn, action.email, action.typ, action.tac, action.countryCode));
        }));
        this.getDemoAccountStatus = this.actions$.pipe(ofType(AuthActions.GET_DEMO_ACCOUNT_STATUS), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetDemoAccountStatus(action.msisdn, action.countryCode));
        }));
        this.resendVerCode = this.actions$.pipe(ofType(AuthActions.RESEND_VER_CODE), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.ResendVerificationCode(action.msisdn, action.countryCode));
        }));
        this.signupDemoAccount = this.actions$.pipe(ofType(AuthActions.SIGNUP_DEMO_ACCOUNT), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.SingUpDemoAccount(action.deviceId, action.os, action.osVersion, action.msisdn, action.email, action.typ, action.countryCode, action.countryIso));
        }));
        this.setBusinessInfo = this.actions$.pipe(ofType(AuthActions.SET_BUSINESS_INFO), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.SetBusinessInfoMethod(action.company_name, action.website));
        }));
        this.getBusinessInfo = this.actions$.pipe(ofType(AuthActions.GET_BUSINESS_INFO), map((action) => {
            this._socketGateway.sendSocketMessage(new AuthMethods.GetBusinessInfoMethod());
        }));
        this.closeWS = this.actions$.pipe(ofType(AuthActions.CLOSE_SOCKET_INIT), map((action) => {
            this.wSocketService.closeCurrentSocket();
        }));
        this.getChatList = this.actions$.pipe(ofType(AuthActions.GET_CHAT_LIST), map((action) => {
            this._socketGateway.sendSocketMessage(new MessagesMethods.GetChatList(action.upgraded));
        }));
    }
    requestIfHasPrivilege(privileges, isMain) {
        // TODO: change request location
        // get messages ( First load )
        // if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
        //   this._messageDispatchers.getLastPostsHistory();
        //   setTimeout(() => {
        //     this._messageDispatchers.getLastRepliesHistory();
        //   }, 5000);
        //   this._messageDispatchers.listAwardMessages();
        //   this._messageDispatchers.listScheduleMessages();
        // }
        // get billing
        // if (privileges.indexOf(PrivilegesName.listbilling) > -1) {
        //   this._socketGateway.sendSocketMessage(
        //     new PaymentMethods.GetPaymentInfo()
        //   );
        // }
        // get Tags
        // if (privileges.indexOf(PrivilegesName.listtag) > -1) {
        //   this._socketGateway.sendSocketMessage(new TagMethods.GetTags());
        // }
        // get menus
        // if (privileges.indexOf(PrivilegesName.listmenu) > -1) {
        // this._socketGateway.sendSocketMessage(new MenuMethods.ListMyMenu());
        // }
        // get bots
        // if (privileges.indexOf(PrivilegesName.listbot) > -1) {
        // this._socketGateway.sendSocketMessage(new RequestMyBots());
        // this._socketGateway.sendSocketMessage(new GetBotTemplates());
        // }
        // if (privileges.indexOf(PrivilegesName.listAppChannelData) > -1) {
        // const keys = [
        //   CHANNEL_APP_SEARCH_CATEGORIES.AREA,
        //   CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY,
        //   CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
        // ];
        // this._socketGateway.sendSocketMessage(new RequestAppChannelData(keys));
        // }
        // Need to add detailed privileges for Map Feature
        // if (privileges.indexOf(PrivilegesName.listmarker) > -1) {
        // this._socketGateway.sendSocketMessage(new GetMapServices());
        // this._socketGateway.sendSocketMessage(new GetMapRoutes());
        // this._socketGateway.sendSocketMessage(new GetMapMarkers());
        // this._socketGateway.sendSocketMessage(new GetMapTrips());
        // this._socketGateway.sendSocketMessage(new GetMapTripUsers());
        // }
        // if (privileges.indexOf(PrivilegesName.listapp) > -1) {
        //   // this._socketGateway.sendSocketMessage(new GetAppsMethod());
        //   // this._socketGateway.sendSocketMessage(new GetAppStoreMethod());
        //   // this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
        //   this.appManagementService.getInstantPages$().subscribe(res => {
        //     if (res.length < 1) {
        //       this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
        //       this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
        //     }
        //   });
        // }
        // if (privileges.indexOf(PrivilegesName.listappchannel) > -1) {
        //   if (this.store$.select(state => state.myPageReducer.length < 1)) {
        //     this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
        //     this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
        //   }
        // }
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "requestNewQRcode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "requestQRcode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "reopenNewConnection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "rcvdMultiLogin", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "getChatData", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "rcvdChatList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "tokenChangeRcvd", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "selectChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "requestAdminQR", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "authChatSelect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "chatPrivilege", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "roles", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "myProfiles", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "upgradedChatList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "searchFilters", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "appSetup", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "bots", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "receiveAuthPrivilege", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "changePassword", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "changePasswordSet", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "changePasswordError", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "registerDemoAccount", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "getDemoAccountStatus", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "resendVerCode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "signupDemoAccount", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "setBusinessInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "getBusinessInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "closeWS", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "getChatList", void 0);
