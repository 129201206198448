import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { GetMapStopTimeMethod, SetMapStopTime, SetMapStopTimesMethod } from 'src/models/MapStopTime';
export class MapStopTimeEffects {
    constructor(actions$, _socketGateway, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this.setMapStopTime = this.actions$.pipe(ofType("CREATE_MAP_STOP_TIME" /* CREATE_MAP_STOP_TIME */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetMapStopTime(action.MapStopTime));
        }));
        this.getMapStopsTimes = this.actions$.pipe(ofType("GET_MAP_STOP_TIME" /* GET_MAP_STOP_TIME */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetMapStopTimeMethod(action.trip_id, 0));
        }));
        this.setMapStopsTimes = this.actions$.pipe(ofType("SET_MAP_STOP_TIMES" /* SET_MAP_STOP_TIMES */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetMapStopTimesMethod(action.tripID, action.MapStopTimes, 0));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapStopTimeEffects.prototype, "setMapStopTime", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapStopTimeEffects.prototype, "getMapStopsTimes", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapStopTimeEffects.prototype, "setMapStopsTimes", void 0);
