import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { BundlesUiDispatchers } from 'src/app/store/bundlesUI/bundlesUI.dispatchers';
import { ProductsUiDispatchers } from 'src/app/store/productsUI/productsUI.dispatchers';
import { BundleScreens, ChatTypes } from 'src/models/constants';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/bundlesUI/bundlesUI.dispatchers";
import * as i3 from "../../../store/productsUI/productsUI.dispatchers";
import * as i4 from "../../../store/storeMenuUI/storeMenuUI.dispatchers";
export class BundlesService {
    constructor(_rootStore, _bundleDispatcher, _productsDispatchers, _storeMenuUiDispatchers) {
        this._rootStore = _rootStore;
        this._bundleDispatcher = _bundleDispatcher;
        this._productsDispatchers = _productsDispatchers;
        this._storeMenuUiDispatchers = _storeMenuUiDispatchers;
        this.chatTypes = ChatTypes;
        this.confirmDeleteBundle = false;
        this._currentBundleScreen = new BehaviorSubject(BundleScreens.BUNDLE_LIST);
        this.currentBundleScreen$ = this._currentBundleScreen.asObservable();
    }
    get products$() {
        return this._rootStore.getProducts$();
    }
    get bundles$() {
        return this._rootStore.getBundles$();
    }
    getSelectedBundle$(id) {
        return this._rootStore.getBundle$(id);
    }
    getGroup$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
    resetPaging() {
        this._bundleDispatcher.resetBundlesPaging();
    }
    isBooking(prod) {
        return (prod &&
            (prod.type === this.chatTypes.BOOKING_CHANNEL ||
                prod.type === this.chatTypes.BOOKING_GROUP));
    }
    isGroup(prod) {
        return (prod &&
            (prod.type === this.chatTypes.GROUP ||
                prod.type === this.chatTypes.CHANNEL));
    }
    updateBundle(bundle) {
        if (bundle && bundle.id) {
            this.addBundleProducts(bundle.id, bundle.products);
            this._bundleDispatcher.setBundle(bundle.id, bundle);
        }
        else {
            this._bundleDispatcher.createBundle(bundle);
        }
        this.closeItemCardEvent();
    }
    addBundleProducts(bundleId, prods) {
        // remove products from bundle products
        const val = prods.map(r => {
            return Object.assign({}, r, { product: null });
        });
        this._bundleDispatcher.addBundleProducts(bundleId, val);
    }
    createItemEvent() {
        this.setCurrentScreen(BundleScreens.BUNDLE_CREATION);
        this.setSelectedBundle(null);
    }
    readItemEvent(bundle) {
        this.setCurrentScreen(BundleScreens.BUNDLE_VIEW);
        this.setSelectedBundle(bundle);
    }
    updateItemEvent(bundle) {
        this.setCurrentScreen(BundleScreens.BUNDLE_EDIT);
        this.setSelectedBundle(bundle);
    }
    closeItemCardEvent() {
        this.setCurrentScreen(BundleScreens.BUNDLE_LIST);
        this.setSelectedBundle(null);
    }
    deleteBundle(bundleId) {
        this._bundleDispatcher.deleteBundle(bundleId);
    }
    toggleConfirmDeleteBundle(e, bundle) {
        this.confirmDeleteBundle = e;
        this.selectedRemovedBundle = bundle;
    }
    setCurrentScreen(screen) {
        this._currentBundleScreen.next(screen);
    }
    setSelectedBundle(bundle) {
        if (bundle) {
            if (bundle.products && bundle.products.length > 0) {
                bundle.products.forEach(e => this._productsDispatchers.getProduct(e.product_id, 0, bundle.id));
            }
            if (bundle.store_menu_id) {
                this._storeMenuUiDispatchers.getStoreMenu(bundle.store_menu_id);
            }
        }
        this.selectedBundle = bundle;
    }
    getNextPage() {
        this._bundleDispatcher.getNextBundlesPage();
    }
    getPreviousPage() {
        this._bundleDispatcher.getPrevBundlesPage();
    }
    getChannelDetails$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
}
BundlesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BundlesService_Factory() { return new BundlesService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.BundlesUiDispatchers), i0.ɵɵinject(i3.ProductsUiDispatchers), i0.ɵɵinject(i4.StoreMenuUiDispatchers)); }, token: BundlesService, providedIn: "root" });
