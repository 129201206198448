<ng-container *ngFor="let item of rowStyle">
  <div class="row-item" *ngIf="item?.cat === currentCategory?.id">
    <div
      class="item-container"
      (click)="onClickMenuItem(item)"
      [class.three]="
        item?.length === 3 &&
        (item?.key !== rowStyles?.SMALLHL && item?.key !== rowStyles?.WIDELIFT)
      "
      [class.button]="item?.key === rowStyles?.BACK"
      [class.disabled]="
        3 - length < item.length ||
        (length >= 0 && item?.key === rowStyles?.BACK)
      "
      (dragstart)="drag($event, item)"
    >
      <img class="img" [src]="item?.img" />
      <!-- <label [for]="item?.key">{{ item?.name }}</label> -->
    </div>
  </div>
</ng-container>
