import { UpdateAppLocalInfo } from './channelAppLocalInfo/appLocalInfo.actions';
import { Injectable } from '@angular/core';

import { AuthDispatchers } from './auth/auth.dispatchers';
import { ProfileDispatchers } from './profile/profile.dispatchers';
import { MyProfileDispatchers } from './myProfile/myProfile.dispatchers';
import { ChatDispatchers } from './chats/chat.dispatchers';
import { MessageDispatchers } from './messages/message.dispatchers';
import { CampaignDispatchers } from './campaigns/campaign.dispatchers';
import { CampaignUIDispatchers } from './campaignsUI/campaignUI.dispatchers';
import { CouponDispatchers } from './coupons/coupon.dispatchers';
import { CouponUIDispatchers } from './couponsUI/couponUI.dispatchers';
import { ChatMemberDispatchers } from './chatMembers/chatMember.dispatchers';
import { AppConfigDispatchers } from './channelAppConfig/appConfig.dispatchers';
import { AppReleaseDispatchers } from './channelAppReleases/appRelease.dispatchers';
import { ContainerDispatchers } from './channelAppContainers/container.dispatchers';
import { InstantDispatchers } from './instants/instant.dispatchers';
import { EventUiDispatchers } from './eventsUI/eventUI.dispatchers';
import { AppMenuDispatchers } from './channelAppMenu/appMenu.dispatchers';
import { AppPublishingDispatchers } from './appPublishing/appPublishing.dispatchers';
import { MediaCenterDispatchers } from './mediaCenter/mediaCenter.dispatchers';
import { InstantPageDispatchers } from './channelAppInstantPage/instantPage.dispatchers';
import { BookingUiDispatchers } from './bookingsUI/bookingUI.dispatchers';
import { BookingPeriodsDispatchers } from './bookingPeriods/bookingPeriods.dispatchers';
import { MyBookingsDispatchers } from './myBookings/myBookings.dispatchers';
import { PaymentDispatchers } from './payments/payment.dispatchers';
import { BulkDispatchers } from './bulk/bulk.dispatchers';
import { AppConfigHistoryDispatchers } from './appConfigHistory/appConfigHistory.dispatchers';

import { BookingPeriods } from 'src/models/BookingPeriods';
import { MyBookings } from 'src/models/MyBookings';
import { MyPage } from 'src/models/MyPageConfig';
import { IAuth } from 'src/models/IAuth';
import { IChat } from 'src/models/IChat';
import { ChatMember } from 'src/models/IChatMember';
import { AppInfo } from 'src/models/ChannelAppConfig';
import {
  ServerErrors,
  SERVER_ERROR_MESSAGES
} from 'src/models/server-errors.enum';
import {
  MESSAGE_SEEN_CALLBACK,
  MESSAGE_CALLBACK,
  TOKEN_AUTH_OK_CALLBACK,
  TOKEN_CALLBACK,
  TOKEN_CHANGE_CALLBACK,
  QR_CODE_CALLBACK,
  CONTACTS_CALLBACK,
  USER_CALLBACK,
  MESSAGE_ACK_CALLBACK,
  MY_PROFILE_CALLBACK,
  MESSAGE_DELIVERED_CALLBACK,
  INVALIDATE_USER_CALLBACK,
  CHAT_LIST_CALLBACK,
  CHAT_CALLBACK,
  CHAT_INVALID_CALLBACK,
  CHAT_COUNTERS_CALLBACK,
  LIKE_MESSAGE_ACK,
  CHAT_MEMBER_COUNT,
  LEFT_CHAT_CALLBACK,
  CHAT_SELECTED_CALLBACK,
  MESSAGE_SCHEDULED_CALLBACK,
  MESSAGE_SCHEDULED_CANCELED_CALLBACK,
  CAMPAIGNS_CALLBACK,
  CAMPAIGN_CALLBACK,
  COUPONS_CALLBACK,
  COUPON_CALLBACK,
  TAGS_CALLBACK,
  CHAT_MEMBER_CALLBACK,
  APP_CONFIG_CALLBACK,
  REQUEST_CONFIG,
  PUBLISH_APP,
  CREATE_ARTICLE_ACK_CALLBACK,
  CREATE_EVENT_REF,
  TEMPLATE_CALLBACK,
  APP_RELEASE_CALLBACK,
  TAGS_VALUE_CALLBACK,
  TAB_TAGS_REF_APP,
  ADMIN_QR_CODE_CALLBACK,
  SUB_CHAT_LIST_CALLBACK,
  CREATE_GROUP_REF,
  UPDATE_EVENT_REF,
  AUTH_PRIVILEGE_CALLBACK,
  CREATE_CHANNEL_REF,
  CHAT_ADMINISTRATORS_CALLBACK,
  MY_MENUS_CALLBACK,
  APP_MENU_CALLBACK,
  MY_MENU_CALLBACK,
  APP_MENUS_CALLBACK,
  UPDATE_APP_CHANNEL_REF,
  MY_MEMBER_CHATS_CALLBACK,
  MY_MEMBERS_CALLBACK,
  TAB_TAGS_REF_CHANNEL,
  PUBLISH_CHANNEL,
  ROLES_CALLBACK,
  MY_PAGE_CALLBACK,
  MY_PAGES_CALLBACK,
  MY_CALL_BACK_STATS_CALLBACK,
  APP_RELEASES_CALLBACK,
  entityStatuses,
  APP_STORE_CALLBACK,
  MY_MEDIA_CALLBACK,
  INSTANT_PAGE_CALLBACK,
  INSTANT_PAGES_CALLBACK,
  REQUEST_APPLE_CODE_CALLBACK,
  GENERATE_APP_REF,
  CREATE_BOOKING_SPACE_REF,
  BOOKING_PERIODS_CALLBACK,
  MY_BOOKINGS_CALLBACK,
  PAYMENT_TOKEN_CALLBACK,
  APP_CONFIG_HISTORY_CALLBACK,
  APP_CONFIGS_HISTORY_LIST_CALLBACK,
  PAYMENT_SET_CALLBACK,
  PAYMENT_CALLBACK,
  BOTS_CALLBACK,
  BOT_CALLBACK,
  BOT_TEMPLATES_CALLBACK,
  PASSWORD_SET_CALLBACK,
  ADD_BOT_AS_ADMIN_REF,
  CREATE_BOT_REF,
  APP_CHANNEL_DATA_CALLBACK,
  GET_BOOKING_EXCEPTIONS_CALLBACK,
  SET_BOOKING_EXCEPTIONS_CALLBACK,
  RecordTypes,
  MAP_SERVICE_CALLBACK,
  MAP_SERVICES_CALLBACK,
  CREATE_MAP_SERVICE_REF,
  PUBLISH_BOT_CALLBACK,
  MAP_MARKERS_CALLBACK,
  MAP_MARKER_CALLBACK,
  CREATE_MAP_MARKER_REF,
  MAP_ROUTE_CALLBACK,
  MAP_ROUTES_CALLBACK,
  MAP_STOP_TIME_CALLBACK,
  MAP_STOP_TIMES_CALLBACK,
  CREATE_MAP_ROUTE_REF,
  MAP_TRIP_CALLBACK,
  CREATE_MAP_TRIP_REF,
  MAP_TRIPS_CALLBACK,
  MAP_TRIP_USER_CALLBACK,
  CREATE_MAP_TRIP_USER_REF,
  MAP_TRIP_USERS_CALLBACK,
  CALENDAR_CALLBACK,
  CALENDAR_DETAILS_CALLBACK,
  CALENDARS_CALLBACK,
  CREATE_CALENDAR_REF,
  EVENT_CHANNEL,
  BOOKING_CHANNEL,
  GROUP,
  CHANNEL,
  CONFIG_QR_CODE_CALLBACK,
  DEFAULT_CHAT_LIST_CALLBACK,
  SERVICE_ORDERS_CALLBACK,
  PAYMENT_ORDERS_CALLBACK,
  PAYMENT_PROVIDERS_CALLBACK,
  SET_PAYMENT_PROVIDER_CALLBACK,
  PRODUCTS_CALLBACK,
  PRODUCT_CALLBACK,
  BUNDLE_CALLBACK,
  BUNDLES_CALLBACK,
  USER_BALANCE_CALLBACK,
  USER_PRODUCT_BALANCE_CALLBACK,
  PACKAGES_CALLBACK,
  PACKAGE_CALLBACK,
  APPS_CALLBACK,
  UPDATE_GROUP_REF,
  UPDATE_CHANNEL_REF,
  MY_MEMBER_CALLBACK,
  PAYMENT_ORDER_USERS_REF,
  CASH_PAYMENT_CALLBACK,
  MSTORE_CALLBACK,
  MSTORES_CALLBACK,
  SUBMENU_CALLBACK,
  MY_BOOKING_USERS_REF,
  REQUEST_MY_BOOKING,
  PAYMENT_ORDER_GROUP_REF,
  STOREMENU_CALLBACK,
  STOREMENUS_CALLBACK,
  SUBMENUS_CALLBACK,
  CANCEL_MY_BOOKING_REF,
  CREATE_PACKAGE_REF,
  CREATE_BUNDLE_REF,
  CREATE_PRODUCT_REF,
  CREATE_STORE_REF,
  CREATE_STORE_MENU_REF,
  CREATE_SUB_MENU_REF,
  TICKET_STATUS_CALLBACK,
  GROUP_DELETED_CALLBACK,
  CREATE_BULK_REF,
  CREATE_CAMPAIGN_REF,
  CREATE_COUPON_REF,
  UPDATE_BULK_REF,
  CREATE_BOT_MENU_REF,
  ADS_PROVIDERS_CALLBACK,
  AdsConfig,
  CREATE_VIRTUAL_APP_REF,
  UPDATE_VIRTUAL_APP_REF,
  CREATE_COUPON_POPUP_REF,
  UserStatus,
  DELETE_MEMBER_FROM_MAIN_CHAT_REF,
  DELETE_MEMBER_FROM_SUB_CHAT_REF,
  WHITELIST_CALLBACK,
  BLACKLIST_CALLBACK,
  ADD_BLACKLIST_ACK_CALLBACK,
  DELETE_BLACKLIST_ACK_CALLBACK,
  BLACKLIST_PATTERNS_CALLBACK,
  DELETE_BLACKLIST_PATTERNS_CALLBACK,
  ADD_BLACKLIST_PATTERNS_ACK_CALLBACK,
  ADD_WHITELIST_CALLBACK,
  DELETE_WHITELIST_CALLBACK,
  WHITELIST_PATTERNS_CALLBACK,
  ADD_WHITELIST_PATTERNS_CALLBACK,
  DELETE_WHITELIST_PATTERNS_CALLBACK,
  CONNECTE_STRIPE_CALLBACK,
  APP_CHANNEL_SETTINGS_CALLBACK,
  USER_DASHBOARD_CALLBACK,
  APP_CONFIG_HISTORY_INFO,
  MAP_ACTIVE_TRIPS_CALLBACK,
  MAP_ACCOUNT_LINKS_CALLBACK,
  MAP_LINKED_ACCOUNTS_CALLBACK,
  MAP_ACCOUNT_LINK_CALLBACK,
  MAP_ASSIGN_TRIPS_CALLBACK,
  MAP_TRIPS_ASSIGN_CALLBACK,
  OTHER_TRIPS_CALLBACK,
  GET_SMTP_CALLBACK,
  STATS_CALLBACK,
  VERFY_DEMO_ACCOUNT_CALLBACK,
  GET_DEMO_ACCOUNT_STATUS_CALLBACK,
  THIRD_PARTY_INFO_CALLBACK,
  TESTED_MESSAGING_VENDOR_CALLBACK,
  MESSAGING_VENDOR_CALLBACK,
  SIGNED_UP_DEMO_ACCOUNT_CALLBACK,
  TAC_DEMO_ACCOUNT_CALLBACK,
  TEMPLATE_META_CALL_BACK,
  APPS_SETUP_CALLBACK,
  APP_SETUP_CALLBACK,
  ISAUTH,
  TabTypes,
  AUTH_PRIVILEGE_MODULES_CALLBACK,
  CREATE_PAGE_REF,
  SUBSCRIBE_TO_PLAN_RESPONSE_CALLBACK,
  GET_INVOICES_RESPONSE_CALLBACK,
  RETRIEVE_COUPON_RESPONSE_CALLBACK,
  SUBSCRIBE_TO_ADDONS_RESPONSE_CALLBACK,
  CANCEL_STRIPE_SUBSCRIPTION_CALLBACK,
  CREATE_CARD_RESPONSE_CALLBACK,
  BillingsPageScreens,
  CHANGE_PLAN_RESPONSE_CALLBACK,
  PAYMENT_INFO_RESPONSE_CALLBACK,
  DELETE_CARD_RESPONSE_CALLBACK,
  SET_DEFAULT_CARD_RESPONSE_CALLBACK,
  UISections,
  CONF_MODULES_CALLBACK,
  paymentProcessFaild,
  REVOKE_SUBSCRIPTION_RESPONSE_CALLBACK,
  GET_BUSINESS_INFO_RESPONSE,
  SET_BUSINESS_INFO_RESPONSE,
  GET_CHAT_ID_RESPONSE,
  PRODUCTS_STORE_CALLBACK,
  GET_PRODUCT_ITEM_RESPONSE,
  SET_PRODUCT_ITEM_RESPONSE,
  DELETE_PRODUCT_RESPONSE,
  DELETE_PRODUCT_ELEMENT_RESPONSE,
  LIST_COLLECTIONS_CALLBACK,
  SET_COLLECTION_CALLBACK,
  DELETE_COLLECTION_CALLBACK,
  GET_COLLECTION_PRODUCTS_CALLBACK,
  GET_COLLECTION_CALLBACK,
  GET_STORE_CALLBACK,
  list_STORE_CALLBACK,
  DELETE_MSTORE_CALL_BACK,
  SET_STORE_CALLBACK,
  SET_PAYMENT_SERVICE_CALLBACK,
  SET_PRODUCT_ITEM_ARRAY_RESPONSE,
  VERIFICATION_TEMPLATE_CALLBACK,
  CHAT_LABEL_CALLBACK,
  DEFAULT_TEMP,
  ThemeStatus,
  CHAT_THEME_CALLBACK,
  GET_LIST_USERS_CALLBACK
} from 'src/models/constants';
import { LocalAppInfo } from 'src/models/ChannelAppLocalConfig';
import { BookingExceptionsDispatchers } from './bookingExceptions/bookingExceptions.dispatchers';
import { AppChannelDataDispatchers } from './appChannelData/appChannelData.dispatchers';
import { MapServiceDispatchers } from './mapServices/mapService.dispatchers';
import { MapMarkerDispatchers } from './mapMarkers/mapMarker.dispatchers';
import { MapRouteDispatchers } from './mapRoutes/mapRoute.dispatchers';
import { MapTripDispatchers } from './mapTrips/mapTrip.dispatchers';
import { MapTripUserDispatchers } from './mapTripUsers/mapTripUser.dispatchers';
import { CalendarUiDispatchers } from './calendarUI/calendarUI.dispatchers';
import { SubGroupUiDispatchers } from './subGroupsUI/subGroupsUI.dispatchers';
import { SubChannelUiDispatchers } from './subChannelsUI/subChannelsUI.dispatchers';
import { MembersUiDispatchers } from './membersUI/membersUI.dispatchers';
import { ServiceOrdersUiDispatchers } from './serviceOrdersUI/serviceOrdersUI.dispatchers';
import { PaymentOrdersUiDispatchers } from './paymentOrdersUI/paymentOrdersUI.dispatchers';
import { PaymentProvidersDispatchers } from './paymentProviders/paymentProviders.dispatchers';
import { ProductsUiDispatchers } from './productsUI/productsUI.dispatchers';
import { BundlesUiDispatchers } from './bundlesUI/bundlesUI.dispatchers';
import { PackagesUiDispatchers } from './packagesUI/packagesUI.dispatchers';
import { AppLocalInfoDispatchers } from './channelAppLocalInfo/appLocalInfo.dispatchers';
import { StoreUiDispatchers } from './storesUI/storeUI.dispatchers';
import { SubMenuUiDispatchers } from './subMenuUI/subMenuUI.dispatchers';
import { StoreMenuUiDispatchers } from './storeMenuUI/storeMenuUI.dispatchers';
import { AdsProvidersDispatchers } from './adsProvider/adsProvider.dispatchers';
import { MenuUIDispatchers } from './menusUI/menuUI.dispatchers';
import { BlackListUiDispatchers } from './balckListUI/blackListUI.dispatchers';
import { BlackListPatternsUiDispatchers } from './balckListPatternsUI/blackListPatternsUI.dispatchers';
import { VirtualAppUiDispatchers } from './virtualAppsUI/virtualAppsUI.dispatchers';
import { WhitelistPatternsUiDispatchers } from './whitelistPatternsUI/whitelistPatternsUI.dispatchers';
import { WhitelistUiDispatchers } from './whitelistMainUI/whitelistMainUI.dispatchers';
import { AppChannelSettingsDispatchers } from './appChannelSettings/appChannelSettings.dispatchers';
import { SummaryUIDispatchers } from './summaryUI/summaryUI.dispatchers';
import { MapStopTimeDispatchers } from './mapStopTime/mapStopTime.dispatchers';
import { MapTripHistoryDispatchers } from './mapTripsHistory/mapTripsHistory.dispatchers';
import { MapTripsNotifiersDispatchers } from './mapTripsNotifiers/mapTripsNotifiers.dispatchers';
import { DriverTripsDispatchers } from './driverAssigned/driverAssigned.dispatchers';
import { MapDriverDispatchers } from './mapTripDriver/mapTripsDriver.dispatchers';
import { MyPageDispatchers } from './channelMyPage/myPage.dispatchers';
import { AllGroupDispatchers } from './allGroup/allGroup.dispatchers';
import { Router } from '@angular/router';
import { SocketService } from '../network/services/socket.service';
import { OtherTripsDispatchers } from './otherDriverTrips/otherDriverTrips.dispatchers';
import { StmpDispatchers } from './smtp/smtp.dispatchers';
import { ChartDispatchers } from './chartStore/chart.dispatchers';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { ThirdPartyInfoDispatchers } from './thirdPartyInfo/thirdPartyInfo.dispatchers';
import { RegistrationService } from '../+merchant/+registration/registration.service';
import { AuthService } from 'src/app/core/auth.service';
import { EventsService } from '../+merchant/+dashboard/events/events.service';
import { StorageService } from '../core/storage.service';
import { BillingUiDispatchers } from './billing/billingUI.dispatchers';
import { UIDispatchers } from './ui/ui.dispatchers';
import { BillingCoupon } from 'src/models/billings';
import { SubChatsService } from '../+merchant/+dashboard/sub-chats/sub-chats.service';
import { BookingsService } from '../+merchant/+dashboard/bookings/bookings.service';
import { ProductsStoreDispatchers } from './product-store/product-store.dispatchers';
import { CollectionsUIDispatchers } from './collectionsUI/collectionsUI.dispatchers';
import { MobileWorkFlowStoreDispatchers } from './mobileWorkflowStore/mobileWorkflowStore.dispatchers';
import { MobileWorkFlowStoreService } from './../+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';
import { BotsService } from '../+merchant/+dashboard/bots/bots.service';
import { TagsDispatchers } from './tags/tags.dispatchers';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { UiService } from 'src/app/core/ui.service';
import { ChatThemesDispatchers } from './chatThemes/chatThemes.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class RootStoreService {
  currntPage: any;
  buttonId: any;
  constructor(
    private _authDispatchers: AuthDispatchers,
    private _uiDispacthers: UIDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _profileDispatcher: ProfileDispatchers,
    private _myProfileDispatcher: MyProfileDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _chatMemberDispatchers: ChatMemberDispatchers,
    private _campaignDispatchers: CampaignDispatchers,
    private _campaignUIDispatchers: CampaignUIDispatchers,
    private _couponDispatchers: CouponDispatchers,
    private _couponUIDispatchers: CouponUIDispatchers,
    private _instantArticleDispatchers: InstantDispatchers,
    private _appConfigDispatchers: AppConfigDispatchers,
    private _appConfigHistoryDispatchers: AppConfigHistoryDispatchers,
    private _appReleaseDispatchers: AppReleaseDispatchers,
    private _containerDispatchers: ContainerDispatchers,
    private _eventUiDispatchers: EventUiDispatchers,
    private _bookingUiDispatchers: BookingUiDispatchers,
    private _subGroupUiDispatchers: SubGroupUiDispatchers,
    private _subChannelUiDispatchers: SubChannelUiDispatchers,
    private _virtualAppUiDispatchers: VirtualAppUiDispatchers,
    private _membersUiDispatchers: MembersUiDispatchers,
    private _bookingPeriodsDispatchers: BookingPeriodsDispatchers,
    private _myBookingsDispatchers: MyBookingsDispatchers,
    private _appMenuDispatchers: AppMenuDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _instantPageDispatchers: InstantPageDispatchers,
    private _appPublishingDispatchers: AppPublishingDispatchers,
    private _mediaCenterDispatchers: MediaCenterDispatchers,
    private _paymentDispatchers: PaymentDispatchers,
    private _bulkDispatchers: BulkDispatchers,
    private _appChannelDataDispatchers: AppChannelDataDispatchers,
    private _bookingExceptionsDispatchers: BookingExceptionsDispatchers,
    private _mapServiceDispatchers: MapServiceDispatchers,
    private _mapMarkerDispatchers: MapMarkerDispatchers,
    private _mapRouteDispatchers: MapRouteDispatchers,
    private _mapTripDispatchers: MapTripDispatchers,
    private _mapTripUserDispatchers: MapTripUserDispatchers,
    private _calendarUiDispatchers: CalendarUiDispatchers,
    private _serviceOrdersUiDispatchers: ServiceOrdersUiDispatchers,
    private _paymentOrdersUiDispatchers: PaymentOrdersUiDispatchers,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers,
    private _adsProvidersDispatchers: AdsProvidersDispatchers,
    private _productsDispatchers: ProductsUiDispatchers,
    private _bundlesDispatchers: BundlesUiDispatchers,
    private _packagesDispatchers: PackagesUiDispatchers,
    private _appLocalInfoDispatchers: AppLocalInfoDispatchers,
    private _storesDispatchers: StoreUiDispatchers,
    private _subMenuDispatchers: SubMenuUiDispatchers,
    private _storeMenuDispatchers: StoreMenuUiDispatchers,
    private _menuUIDispatchers: MenuUIDispatchers,
    private _whitelistPatternsDispatchers: WhitelistPatternsUiDispatchers,
    private _whitelistDispatchers: WhitelistUiDispatchers,
    private _blackListUiDispatchers: BlackListUiDispatchers,
    private _blacklistPatternsUiDispatchers: BlackListPatternsUiDispatchers,
    private _appChannelSettingsDispatchers: AppChannelSettingsDispatchers,
    private _summaryUIDispatchers: SummaryUIDispatchers,
    private _mapTripHistoryDispatchers: MapTripHistoryDispatchers,
    private _mapStopTimeDispatchers: MapStopTimeDispatchers,
    private mapTripsNotifiersDispatchers: MapTripsNotifiersDispatchers,
    private _driverTripsDispatchers: DriverTripsDispatchers,
    private _mapDriverTripsDispatchers: MapDriverDispatchers,
    private _allGroupDispatchers: AllGroupDispatchers,
    private socketService: SocketService,
    private _router: Router,
    private _smtpDispatchers: StmpDispatchers,
    private _otherTripsDispatchers: OtherTripsDispatchers,
    private _chartDispatchers: ChartDispatchers,
    private appMangemtService: AppMgmtService,
    private mobileWorkflowStoreService: MobileWorkFlowStoreService,
    private _thirdPartyInfoDispatchers: ThirdPartyInfoDispatchers,
    private _rgsService: RegistrationService,
    private _route: Router,
    private authService: AuthService,
    private _eventService: EventsService,
    private _subChatsService: SubChatsService,
    private _bookingsService: BookingsService,
    private localStorage: StorageService,
    private _billingUiDispatchers: BillingUiDispatchers,
    private _uIDispatchers: UIDispatchers,
    private _productsStoreDispatchers: ProductsStoreDispatchers,
    private _collectionsUIDispatchers: CollectionsUIDispatchers,
    private _mobileWorkflowStoreDispachers: MobileWorkFlowStoreDispatchers,
    private botsService: BotsService,
    private _tagsDispatchers: TagsDispatchers,
    private dashboardService: DashboardService,
    private uiService: UiService,
    private _chatThemesDispatchers: ChatThemesDispatchers
  ) {}

  redirectMessage(data: any): void {
    if (data.error) {
      switch (Number(data.error)) {
        case ServerErrors.TokenExpired:
        case ServerErrors.NoPermission:
        case ServerErrors.AuthError:
          this._authDispatchers.reciveAuthNot();
          break;
        case ServerErrors.MultiLogin:
          this._authDispatchers.reciveMultiLogin();
          break;
        case ServerErrors.OldPasswordNotCorrect:
        case ServerErrors.PasswordTooShort:
        case ServerErrors.PasswordTooLong:
        case ServerErrors.PasswordMustContainLowerCase:
        case ServerErrors.PasswordMustContainUpperCase:
        case ServerErrors.PasswordMustContainDigit:
        case ServerErrors.PasswordMustContainSpecial:
        case ServerErrors.PasswordMatchesPattern:
        case ServerErrors.PasswordMatchesDictionary:
        case ServerErrors.PasswordInHistory:
        case ServerErrors.PasswordsDoNotMatch:
          this._authDispatchers.receiveChangePasswordError(data.error);
          break;
        case ServerErrors.GoogleAccountNotOpened:
        case ServerErrors.GoogleReleaseManagerNotSetup:
        case ServerErrors.AppNameNotProvided:
        case ServerErrors.GoogleScJsonNotProvided:
        case ServerErrors.AppleAccountNotOpened:
        case ServerErrors.AppleAppManagerNotSetup:
        case ServerErrors.AppleAppNameNotProvided:
        case ServerErrors.GooglePackageIdNotProvided:
        case ServerErrors.AppleBundleIdNotProvided:
        case ServerErrors.AppleTeamNameNotProvided:
        case ServerErrors.GoogleScJsonInvalidFormat:
        case ServerErrors.AppSpecificPasswordNotProvided:
        case ServerErrors.GooglePackageIdHasInvalidFormat:
        case ServerErrors.AppleBundleIdHasInvalidFormat:
        case ServerErrors.AppleDeveloperPasswordNotProvided:
        case ServerErrors.ApplePushNotiKeyNotProiveded:
        case ServerErrors.AppleUserNotProvided:
        case ServerErrors.AndroidFormatHasWrongValue:
        case ServerErrors.AppleAppManagerNotConfirmed:
        case ServerErrors.AppNameIsAlreadyUsed:
          this._appPublishingDispatchers.receivePublishInfoError(data.error);
          break;
        case ServerErrors.DifferentCurrency:
        case ServerErrors.SKUIsAlreadyUsed:
        case ServerErrors.PatternNotmatched:
        case ServerErrors.InvalidPattern:
        case ServerErrors.NotifierIsAlreadyExist:
        case ServerErrors.DifferentSubChannels:
        case ServerErrors.NotAllowed:
        case ServerErrors.UserBanned:
        case ServerErrors.DeviceBanned:
        case ServerErrors.RegionError:
        case ServerErrors.DeleteChatError:
        case ServerErrors.FailedTestVendor:
        case ServerErrors.ConfigurationTestVendorNotExist:
        case ServerErrors.MissingDataForTest:
        case ServerErrors.Blocked:
        case ServerErrors.WRONG_FORMAT:
        case ServerErrors.TacIsWrong:
        case ServerErrors.INVALID_NUMBER:
          this.appMangemtService._loading.next(false);
          this._appPublishingDispatchers.receiveErrorCode(data.error);
          break;
        case ServerErrors.UniqueBotNameError:
          this._profileDispatcher.receivePublishBotError(data.error);
          break;
        case ServerErrors.DeleteChatError:
          this._chatDispatchers.chatDeletedError(data.group_id, data.error);
          break;
        case ServerErrors.UnAuthenticated:
          window.location.reload();
          break;
        case ServerErrors.MAXIMUM_BUILD:
          this._uIDispatchers.addToastMessage(
            SERVER_ERROR_MESSAGES[ServerErrors.MAXIMUM_BUILD],
            false
          );
          this._appReleaseDispatchers.removeAppReleaseWaitingItem();
          break;
        case ServerErrors.APP_EXPIRED:
          this._appReleaseDispatchers.removeAppReleaseWaitingItem();
          this._uIDispatchers.addToastMessage(
            SERVER_ERROR_MESSAGES[ServerErrors.APP_EXPIRED],
            false
          );
          break;
        case ServerErrors.OVER_LAPING_DAYS_AVILABOL:
          this._uIDispatchers.addToastMessage(
            SERVER_ERROR_MESSAGES[ServerErrors.OVER_LAPING_DAYS_AVILABOL],
            false
          );
          break;
        default:
          break;
      }
    } else if (data.method) {
      switch (data.method) {
        case QR_CODE_CALLBACK:
          this._authDispatchers.reciveAuthQR(data);
          break;
        case TOKEN_CALLBACK:
          this._authDispatchers.reciveAuthToken(data);
          break;
        case TOKEN_CHANGE_CALLBACK:
          this._authDispatchers.reciveAuthTokenChange(data);
          break;
        case TOKEN_AUTH_OK_CALLBACK:
          const localData = { ...data, chatId: data.chat_id };
          this._chatThemesDispatchers.getChatThemeFromServer();
          // let chatId;
          // if (data.chat_id) {
          //   chatId = data.chat_id;
          // } else {
          //   chatId = data.main_group_id;
          // }

          if (data.role == 1 && data.appConfig == 0) {
            this.selectedChatAuth(data.main_group_id, ISAUTH.signup);
            // this.signUpAuthCalls();
          } else {
            if (data.chat_id) {
              this.selectedChatAuth(data.chat_id, ISAUTH.login);
              if (data.role == 1) {
                this._appConfigDispatchers.setRuleEngineAccess();
              }
            } else if (data.role == 1) {
              this.selectedChatAuth(data.main_group_id, ISAUTH.login);
              this._appConfigDispatchers.setRuleEngineAccess();
            }

            this.loginAuthCalls(true);
            this.loginAuthCalls(false);
          }

          this.commonAuthCalls();

          this._authDispatchers.reciveAuthOK(localData);

          // For merchant project only rcvd this payment info
          this._paymentDispatchers.rcvdPaymentAuthInfo(data);
          this._paymentDispatchers.getPricePlans();
          this._billingUiDispatchers.receivedSelecetedPLan(
            data.plan_id,
            data.cancel_end
          );
          this._billingUiDispatchers.receveEndFreeTrileDate(
            data.trial_end_date
          );
          if (!data.trial_end_date) {
            this._uIDispatchers.toggleExpiredPopup(false);
          }
          if (data.expire) {
            this._uIDispatchers.toggleExpiredPopup(paymentProcessFaild);
            this._billingUiDispatchers.receveIsMainPlanExpire(data.expire);
          } else {
            this._billingUiDispatchers.receveIsMainPlanExpire(false);
          }
          break;
        case ADMIN_QR_CODE_CALLBACK:
          this._authDispatchers.receiveAdminQR(data.qrCode, data.chat_id);
          break;
        case AUTH_PRIVILEGE_CALLBACK:
          this._authDispatchers.receiveAuthPrivilege(
            data.privileges,
            data.reference
          );
          break;

        case AUTH_PRIVILEGE_MODULES_CALLBACK:
          this._uiDispacthers.setAuthPrivilegeModules(data.modules);
          break;

        case PASSWORD_SET_CALLBACK:
          this._authDispatchers.receiveChangePasswordSet();
          break;
        case MESSAGE_CALLBACK:
          this._messageDispatchers.initReceivedMessage(data);
          break;
        case MESSAGE_ACK_CALLBACK:
          this._messageDispatchers.messageSent(data.ack);
          break;
        case MESSAGE_DELIVERED_CALLBACK:
          this._messageDispatchers.messageDelivered(data);
          break;
        case MESSAGE_SEEN_CALLBACK:
          this._messageDispatchers.messagesSeenReceived(data.message_ids);
          break;
        case MY_PROFILE_CALLBACK:
          this._myProfileDispatcher.myProfileReceiving(data.user);
          break;
        case CONTACTS_CALLBACK:
          this._profileDispatcher.contactsReceived(data.contacts);
          break;
        case BOTS_CALLBACK:
          this._profileDispatcher.contactsReceived(data.bots, true);
          break;
        case BOT_TEMPLATES_CALLBACK:
          this._profileDispatcher.contactsReceived(
            data.bot_templates,
            false,
            true
          );
          break;
        case USER_CALLBACK:
          this._profileDispatcher.contactDetailsReceived(data.user);
          break;
        case BOT_CALLBACK:
          if (data.reference && ADD_BOT_AS_ADMIN_REF === data.reference) {
            this._profileDispatcher.botAddedAsAdmin(data.bot.id);
          } else if (
            data.bot &&
            data.status &&
            data.status === entityStatuses.DELETED
          ) {
            this._profileDispatcher.myBotDeleted(data.bot.id);
          } else if (
            data.reference &&
            CREATE_BOT_REF === data.reference &&
            data.bot
          ) {
            this._profileDispatcher.botCreated(data.bot.id, data.bot.name);
            this._profileDispatcher.profileInvalidated(data.bot.id);
          } else {
            this._profileDispatcher.contactDetailsReceived(data.bot);
          }
          break;
        case PUBLISH_BOT_CALLBACK: {
          this._profileDispatcher.profileInvalidated(data.id);
          break;
        }
        case INVALIDATE_USER_CALLBACK:
          this._profileDispatcher.profileInvalidated(data.user_id);
          break;
        case CHAT_INVALID_CALLBACK:
          if (CREATE_EVENT_REF === data.reference) {
            const createdEvent = this.chatReceived(data);
            this._eventUiDispatchers.setChat(data);
            this._eventUiDispatchers.eventPhotoUploadUI(createdEvent);
            // this._eventUiDispatchers.resetEventPaging();
          } else if (CREATE_BOOKING_SPACE_REF === data.reference) {
            const createdBookingSpace = this.chatReceived(data);
            this._bookingUiDispatchers.setBookingChat(data);
            // may be I'll change this event name
            this._bookingUiDispatchers.bookingPhotoUploadUI(
              createdBookingSpace
            );
            // this._bookingUiDispatchers.resetBookingPaging();
          } else if (CREATE_GROUP_REF === data.reference) {
            const createdGroup = this.chatReceived(data);
            this._subGroupUiDispatchers.setGroupChat(data);
            this._subGroupUiDispatchers.subGroupPhotoUploadUI(createdGroup);
            // this._subGroupUiDispatchers.resetSubGroupPaging();
          } else if (CREATE_CHANNEL_REF === data.reference) {
            const createdGroup = this.chatReceived(data);
            this._subChannelUiDispatchers.setChannelChat(data);
            this._subChannelUiDispatchers.subChannelPhotoUploadUI(createdGroup);
            // this._subChannelUiDispatchers.resetSubChannelPaging();
          } else if (CREATE_VIRTUAL_APP_REF === data.reference) {
            const createdGroup = this.chatReceived(data);
            this._virtualAppUiDispatchers.virtualAppPhotoUploadUI(createdGroup);
            this._virtualAppUiDispatchers.resetVirtualAppPaging();
          } else if (
            CREATE_BULK_REF === data.reference ||
            UPDATE_BULK_REF === data.reference
          ) {
            if (data.recordIndex) {
              this._bulkDispatchers.recordCreated(
                data.recordIndex,
                RecordTypes.CHAT,
                data.chat_id,
                data.reference
              );
            }
          } else {
            this._chatDispatchers.chatInvalidated(data.chat_id);
            setTimeout(() => {
              this._subChatsService._wattingForUploadImage.next(false);
              this._bookingsService._wattingForUploadImage.next(false);
              this._eventService._wattingForUploadImage.next(false);
            }, 1000);
          }
          break;
        case GET_CHAT_ID_RESPONSE:
          this._chatDispatchers.receiveGenratedChatID(data.id);
          break;
        case CHAT_CALLBACK:
          this._chatDispatchers.chatDetailsReceived(data);

          if (UPDATE_EVENT_REF === data.reference) {
            this._eventUiDispatchers.closeEventEditUI();
          } else if (data.reference === UPDATE_GROUP_REF) {
            this._subGroupUiDispatchers.closeEditSubGroupUI();
          } else if (data.reference === UPDATE_CHANNEL_REF) {
            this._subChannelUiDispatchers.closeEditSubChannelUI();
          } else if (data.reference === UPDATE_VIRTUAL_APP_REF) {
            this._virtualAppUiDispatchers.closeEditVirtualAppUI();
          } else if (UPDATE_APP_CHANNEL_REF === data.reference) {
            const appInfo: AppInfo = {};
            appInfo.channel_name = (<IChat>data.chat).title;
            this._appConfigDispatchers.updateAppInfo(appInfo);
          }

          break;
        case TAGS_CALLBACK:
          // this._chatDispatchers.tagsReceived(data, data.chat.id);
          this._tagsDispatchers.tagsReceived(data, data.chat.id);
          if (data.chat && data.chat.recordIndex) {
            this._bulkDispatchers.recordCreated(
              data.chat.recordIndex,
              RecordTypes.TAG
            );
          }
          break;
        case DEFAULT_CHAT_LIST_CALLBACK:
          this._authDispatchers.reciveAuthChatList(data);
          this._chatDispatchers.chatListReceived(data);
          break;
        case CHAT_LIST_CALLBACK:
          if (
            data.ref &&
            data.ref === PAYMENT_ORDER_GROUP_REF &&
            data.chat_list
          ) {
            const groupNamesObj = {};
            data.chat_list.forEach(chat => {
              groupNamesObj[chat.id] = chat.name;
            });
            this._paymentOrdersUiDispatchers.paymentOrdersGroupsReceived(
              groupNamesObj
            );
          } else {
            this._chatDispatchers.chatListReceived(data);
          }
          break;
        case SUB_CHAT_LIST_CALLBACK:
          if (data.type && data.type.length > 0 && data.chat_list) {
            (<string[]>data.type).forEach(type => {
              this._chatDispatchers.subChatListReceived(
                {
                  chat_list: (<IChat[]>data.chat_list).filter(
                    chat => chat.type === type
                  )
                },
                type
              );
            });

            if (EVENT_CHANNEL === data.type[0]) {
              this._eventUiDispatchers.receivedEventHistoryChunk(
                data.eop,
                data.sop,
                data.chat_list
              );
            } else if (BOOKING_CHANNEL === data.type[0]) {
              this._bookingUiDispatchers.receivedBookingHistoryChunk(
                data.eop,
                data.sop,
                data.chat_list
              );
            } else if (GROUP === data.type[0]) {
              this._allGroupDispatchers.receiveAllGroup(data.chat_list);
              if (data.chat_list.length == 0) {
                this._allGroupDispatchers.setLoaderStatus('done');
              }
              this._subGroupUiDispatchers.receivedSubGroupHistoryChunk(
                data.eop,
                data.sop,
                data.chat_list
              );
            } else if (CHANNEL === data.type[0]) {
              if (data.vapp && data.vapp == 1) {
                this._virtualAppUiDispatchers.receivedVirtualAppHistoryChunk(
                  data.eop,
                  data.sop,
                  data.chat_list
                );
              } else {
                this._subChannelUiDispatchers.receivedSubChannelHistoryChunk(
                  data.eop,
                  data.sop,
                  data.chat_list
                );
              }
            }
          } else {
            this._chatDispatchers.subChatListReceived(data);
          }
          break;
        case CHAT_SELECTED_CALLBACK:
          const auth: IAuth = {};

          auth.chatId = data.chat_id;
          auth.progressWeb = data.progressWeb;
          auth.mode = data.mode;
          auth.appConfig = data.appConfig;
          auth.is_nandbox = data.is_nandbox;
          auth.role = data.role;

          auth.main_group_id = data.main_group_id;
          this._authDispatchers.receiveAuthChatSelected(auth);
          if (data.isAuth == ISAUTH.signup) {
            this.signUpAuthCalls();
            this.selectTemplate();
          }

          if (data.isAuth == ISAUTH.login || data.isAuth == ISAUTH.qrcode) {
            // this.userPrivillegeCalls();
          }

          this.appPropertiesUserAuthCalls(data.chat_id);

          // setTimeout(
          //   () => this._summaryUIDispatchers.getUserDashboardList(),
          //   500
          // );

          if (data.role == 1 && data.chat_id === data.main_group_id) {
            this.appSettingsAuthCalls();
            this.appPropertiesOwnerAuthCalls();
            this._appConfigDispatchers.setRuleEngineAccess();
          }
          this.appGeneralCalls();
          break;
        case CHAT_COUNTERS_CALLBACK:
          this._messageDispatchers.chatCountersReceived(data.message_ids);
          break;
        case LIKE_MESSAGE_ACK:
          this._messageDispatchers.likeAckReceived(data);
          break;
        case CHAT_MEMBER_COUNT:
          this._chatDispatchers.chatMemberCountReceived(data.chat);
          break;
        case LEFT_CHAT_CALLBACK:
          const chatMember = <ChatMember>data.chatMember;
          if (this._authDispatchers.getLoginID() === chatMember.user.id) {
            this._chatDispatchers.loggedInLeftChat(chatMember);
          }
          break;
        case MESSAGE_SCHEDULED_CALLBACK:
          this._messageDispatchers.initReceivedMessage(data);
          break;
        case MESSAGE_SCHEDULED_CANCELED_CALLBACK:
          this._messageDispatchers.receiveScheduleMessageCanceled(data);
          break;
        case CAMPAIGNS_CALLBACK:
          this._campaignUIDispatchers.receivedCampaignHistoryChunk(
            data.eop,
            data.sop,
            data.campaigns
          );
          break;
        case CAMPAIGN_CALLBACK:
          this._campaignDispatchers.campaignReceived(data);
          if (data.reference === CREATE_CAMPAIGN_REF) {
            this._campaignUIDispatchers.resetCampaignPaging();
          }
          break;
        case COUPONS_CALLBACK:
          this._couponUIDispatchers.receivedCoupons(
            data.eop,
            data.sop,
            data.coupons,
            data.group_id
          );
          break;
        case COUPON_CALLBACK:
          this._couponDispatchers.couponReceived(data);
          if (data.reference === CREATE_COUPON_REF) {
            this._couponUIDispatchers.resetCouponPaging();
          } else if (data.reference === CREATE_COUPON_POPUP_REF) {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            const formatMonth = month < 10 ? '0' + month : month;
            const formatDay = day < 10 ? `0${day}` : day;
            this._couponUIDispatchers.resetCouponPaging(
              'A',
              `${year}-${formatMonth}-${formatDay}`
            );
          }
          break;
        case GROUP_DELETED_CALLBACK:
          this._chatDispatchers.chatDeleted(data.group_id);
          break;
        case APP_CONFIG_CALLBACK:
          if (REQUEST_CONFIG === data.reference) {
            this._appConfigDispatchers.appConfigAck(
              data.onlineChannelConfigWeb,
              data.appConfig
            );
          } else if (PUBLISH_APP === data.reference) {
            this._appConfigDispatchers.appConfigPublished(false);
            this._appConfigHistoryDispatchers.requestAppConfigsHistory();
          } else if (PUBLISH_CHANNEL === data.reference) {
            this._appConfigDispatchers.appConfigPublished(true);
          } else if (GENERATE_APP_REF === data.reference) {
            this._appReleaseDispatchers.listAppReleases();
          }

          if (
            this.appMangemtService.getTempId() &&
            this.appMangemtService.getTempId() !==
              data.appConfig.app.system.app_info.tempId
          ) {
            const appInfo: AppInfo = {};
            appInfo.tempId = this.appMangemtService.getTempId();
            this.appMangemtService.updateAppInfo(appInfo);
            // this.appMangemtService.savePage(false);
          }

          if (
            !data.appConfig.app.system.app_info.lang ||
            data.appConfig.app.system.app_info.lang === null
          ) {
            const eng: {} = {
              key: 'English',
              value: 'en',
              direction: 'ltr'
            };
            const appInfo: AppInfo = {};
            appInfo.lang = [eng];
            this.appMangemtService.updateAppInfo(appInfo);
          }
          if (!data.appConfig.app.system.app_info.onboarding_style) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            newAppInfo.layout = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          }
          if (
            !data.appConfig.app.system.app_info.layout ||
            data.appConfig.app.system.app_info.layout === ThemeStatus.Top
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            newAppInfo.layout = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          } else if (
            data.appConfig.app.system.app_info.layout === ThemeStatus.Bottom
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '1';
            newAppInfo.layout = '1';
            this.appMangemtService.updateAppInfo(newAppInfo);
          } else if (
            data.appConfig.app.system.app_info.layout === ThemeStatus.Menu
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            newAppInfo.layout = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          }
          // this._appConfigDispatchers.getAppPrivilleges();
          break;
        case APP_CONFIG_HISTORY_CALLBACK: {
          if (data.app_config_history) {
            this._appConfigDispatchers.appConfigAck(
              data.app_config_history.onlineChannelConfigWeb,
              data.app_config_history.appConfig
            );
            this._appConfigDispatchers.UpldTempImageToProfile(
              data.app_config_history.appConfig.app.system.app_info.image_id
            );
          }
          break;
        }
        case APP_CONFIGS_HISTORY_LIST_CALLBACK: {
          this._appConfigHistoryDispatchers.receiveAppConfigsHistory(
            data.app_configs_history
          );
          break;
        }
        case CREATE_ARTICLE_ACK_CALLBACK:
          this._instantArticleDispatchers.instantArticleReceived(data);
          break;
        case TEMPLATE_CALLBACK:
          this._appConfigDispatchers.receiveAppTemplate(
            data.onlineChannelConfigWeb,
            data.appConfig,
            data.onlineAppConfigIosWeb,
            data.appConfigIos,
            data.modules
          );

          // setchat image after template recived

          // if (
          //   data.appConfig &&
          //   data.appConfig.app &&
          //   data.appConfig.app.system &&
          //   data.appConfig.app.system.app_info &&
          //   data.appConfig.app.system.app_info.image_id
          // ) {
          this._appConfigDispatchers.UpldTempImageToProfile(
            data.appConfig.app.system.app_info.image_id
          );
          // }
          // this.appMangemtService.savePage(false);
          setTimeout(() => {
            this.authService._waitForTempConfig.next(false);
          }, 500);
          if (
            !data.appConfig.app.system.app_info.lang ||
            data.appConfig.app.system.app_info.lang === null
          ) {
            const eng: {} = {
              key: 'English',
              value: 'en',
              direction: 'ltr'
            };
            const appInfo: AppInfo = {};
            appInfo.lang = [eng];
            this.appMangemtService.updateAppInfo(appInfo);
          }

          if (!data.appConfig.app.system.app_info.onboarding_style) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          }

          if (
            !data.appConfig.app.system.app_info.layout ||
            data.appConfig.app.system.app_info.layout === ThemeStatus.Top
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            newAppInfo.layout = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          } else if (
            data.appConfig.app.system.app_info.layout === ThemeStatus.Bottom
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '1';
            newAppInfo.layout = '1';
            this.appMangemtService.updateAppInfo(newAppInfo);
          } else if (
            data.appConfig.app.system.app_info.layout === ThemeStatus.Menu
          ) {
            const newAppInfo: AppInfo = {};
            newAppInfo.onboarding_style = '0';
            newAppInfo.layout = '0';
            this.appMangemtService.updateAppInfo(newAppInfo);
          }
          break;
        case APP_RELEASE_CALLBACK:
          if (data.status && data.status === entityStatuses.DELETED) {
            this._appReleaseDispatchers.removeAppReleaseSuccess(data.id);
          } else {
            this._appReleaseDispatchers.rcvdAppRelease(data);
          }
          break;
        case APP_RELEASES_CALLBACK:
          this._appReleaseDispatchers.listAppReleasesSuccess(
            data.eop,
            data.sop,
            data.values
          );
          break;
        case TAGS_VALUE_CALLBACK:
          if (TAB_TAGS_REF_APP === data.reference) {
            this._containerDispatchers.receiveTagValues(data.tags, false);
          } else if (TAB_TAGS_REF_CHANNEL === data.reference) {
            this._containerDispatchers.receiveTagValues(data.tags, true);
          }
          break;
        case CHAT_ADMINISTRATORS_CALLBACK:
          if (data.chatAdministrators.chat) {
            this._chatDispatchers.chatAdminsReceived(
              data.chatAdministrators.chat.id,
              data.chatAdministrators.administrators.map(admin => admin.id)
            );
            data.chatAdministrators.administrators.forEach(
              admin => this._profileDispatcher.profileInvalidated(admin.id)
              // this._chatMemberDispatchers.getMemberChats(admin.id)
            );
          }
          break;
        case CONFIG_QR_CODE_CALLBACK:
          if (data.chat_id && data.qrCode) {
            this._chatDispatchers.configQrCodeReceived(
              data.chat_id,
              data.qrCode
            );
          }
          break;
        case MY_MENUS_CALLBACK:
          this._menuUIDispatchers.menusReceiving(data);
          break;
        case MY_MENU_CALLBACK:
          this._menuUIDispatchers.menuReceiving(data);
          if (data.ref && data.ref === CREATE_BOT_MENU_REF) {
            this._menuUIDispatchers.resetMenuPaging();
          }
          break;
        case APP_MENU_CALLBACK:
          this._appMenuDispatchers.appMenuDetailsReceived(data.app_menu);
          break;
        case APP_MENUS_CALLBACK:
          this._appMenuDispatchers.receiveAppMenus(data.app_menus);
          break;

        case ROLES_CALLBACK:
          this._chatDispatchers.rolesDelivered(data.roles);
          break;
        case CHAT_MEMBER_CALLBACK:
          if (data.recordIndex) {
            this._bulkDispatchers.recordCreated(
              data.recordIndex,
              RecordTypes.MEMBER
            );
          } else if (
            (data &&
              data.chatMember.status === UserStatus.LEFT &&
              data.reference === DELETE_MEMBER_FROM_MAIN_CHAT_REF) ||
            (data && data.chatMember.status === UserStatus.BANNED) ||
            (data &&
              data.chatMember.status === UserStatus.ACTIVE &&
              data.reference === 'UNBAN_REF')
          ) {
            this._chatMemberDispatchers.removeOrBanUserSuccess(
              data.chatMember.user.id
            );
          } else if (
            data &&
            data.reference === DELETE_MEMBER_FROM_SUB_CHAT_REF
          ) {
            this._chatMemberDispatchers.removeChatFromMemberSuccess(
              data.chatMember.chat.id
            );
          } else {
            this._chatMemberDispatchers.chatMemberReceiving(data.chatMember);
          }
          break;
        case MY_MEMBER_CHATS_CALLBACK:
          const { user_id, members } = data;
          this._chatMemberDispatchers.myMemberChatsReceived({
            user_id,
            members
          });
          break;
        case MY_MEMBERS_CALLBACK:
          this._chatMemberDispatchers.chatMembersReceived(data.members);
          if (data.members) {
            this._membersUiDispatchers.receivedMembersHistoryChunk(
              data.eop,
              data.members.map(member => {
                return { user_id: member.user.id };
              }),
              data.page_number
            );
          }
          break;
        case MY_MEMBER_CALLBACK:
          if (data.member) {
            const userNamesObj = {};
            data.member.forEach(member => {
              userNamesObj[member.user.id] = member.name;
            });
            if (PAYMENT_ORDER_USERS_REF === data.ref) {
              this._paymentOrdersUiDispatchers.paymentOrdersUsersReceived(
                userNamesObj
              );
            } else if (MY_BOOKING_USERS_REF === data.ref) {
              this._myBookingsDispatchers.myBookingsUsersReceived(userNamesObj);
            }
          }
          break;

        case WHITELIST_CALLBACK:
          if (data.whitelist.users.length > 0) {
            this._whitelistDispatchers.setLoadingScreenStatus('Loading');
          }
          this._whitelistDispatchers.receivedWhitelistHistoryChunk(
            data.eop,
            data.sop,
            data.whitelist.users,
            data.hash
          );
          break;
        case ADD_WHITELIST_CALLBACK:
          this._whitelistDispatchers.resetPaging(null);
          // this._whitelistDispatchers.addWhitelistSuccess(data.users);
          break;
        case DELETE_WHITELIST_CALLBACK:
          this._whitelistDispatchers.deleteUsersFromWhitelistSuccess(
            data.users
          );
          break;
        case WHITELIST_PATTERNS_CALLBACK:
          this._whitelistPatternsDispatchers.receivedWhitelistPatternsHistoryChunk(
            data.eop,
            data.sop,
            data.whitelistpatten,
            data.hash
          );
          break;
        case ADD_WHITELIST_PATTERNS_CALLBACK:
          this._whitelistPatternsDispatchers.addWhitelistPatternsSuccess(
            data.data
          );
          break;
        case DELETE_WHITELIST_PATTERNS_CALLBACK:
          this._whitelistPatternsDispatchers.deletePatternCallback(
            data.pattern
          );
          break;
        case BLACKLIST_CALLBACK:
          if (data.blacklist.users.length > 0) {
            this._blackListUiDispatchers.setLoadingScreenStatus('Loading');
          }
          this._blackListUiDispatchers.receivedBlacklistsHistoryChunk(
            data.eop,
            data.sop,
            data.blacklist.users,
            data.hash
          );
          break;
        case BLACKLIST_PATTERNS_CALLBACK:
          this._blacklistPatternsUiDispatchers.receivedBlacklistsPatternsHistoryChunk(
            data.eop,
            data.sop,
            data.blacklistpatten,
            data.hash
          );
          break;
        case DELETE_BLACKLIST_PATTERNS_CALLBACK:
          this._blacklistPatternsUiDispatchers.deletePatternFromBlacklistSuccess(
            data.pattern
          );
          break;
        case ADD_BLACKLIST_ACK_CALLBACK:
          if (CREATE_BULK_REF === data.reference) {
            this._bulkDispatchers.recordCreated(
              data.recordIndex,
              RecordTypes.BLACKLIST_SIGNUP
            );
          } else {
            //this._blackListUiDispatchers.resetBlacklistPaging(null);
            this._blackListUiDispatchers.addBlacklistSuccess(data.users);
          }
          break;
        case ADD_BLACKLIST_PATTERNS_ACK_CALLBACK:
          if (CREATE_BULK_REF === data.reference) {
            this._bulkDispatchers.recordCreated(
              data.recordIndex,
              RecordTypes.BLACKLIST_SIGNUP_PATTERN
            );
          }
          this._blacklistPatternsUiDispatchers.addPatternToBlacklistSuccess(
            data.data,
            data.chat_id
          );
          break;
        case DELETE_BLACKLIST_ACK_CALLBACK:
          this._blackListUiDispatchers.removeUserFromBlacklistSuccess(
            data.users
          );
          break;
        case USER_DASHBOARD_CALLBACK:
          this._summaryUIDispatchers.receivedDashboardHistoryChunk(
            data.dashboard
          );
          if (!data.dashboard) {
            this._summaryUIDispatchers.saveDefaultedDashboardList();
          }
          break;
        case TICKET_STATUS_CALLBACK:
          if (data.data) {
            if (data.response && CANCEL_MY_BOOKING_REF === data.reference) {
              switch (data.response) {
                case ServerErrors.BookingNotFound:
                case ServerErrors.CancelExpired:
                case ServerErrors.CancelIsNotAllowedForThisBooking:
                case ServerErrors.BookingStartTimePassed:
                  this._myBookingsDispatchers.receivedMyBookingsError(
                    data.response
                  );
                  break;
              }
            } else {
              this._myBookingsDispatchers.myBookingStatusUpdated(
                data.chat_id,
                data.data[0].user_id,
                data.data[0].reference,
                data.data[0].date,
                data.data[0].start_time,
                data.data[0].status,
                data.data[0].cancelable
              );
            }
          }
          break;
        // case MY_ADMINS_CALLBACK:
        //   this._chatMemberDispatchers.chatMembersReceived(data.members);
        //   break;
        case MY_PAGE_CALLBACK:
          if (data.reference) {
            this._myPageDispatchers.myPageCreated(data.mypage, data.reference);
            this.asignMyPage(data);
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._myPageDispatchers.myPageDeleted(data.id);
            } else {
              this._myPageDispatchers.receiveMyPage(data.mypage);
            }
          }
          break;
        case MY_PAGES_CALLBACK:
          this._myPageDispatchers.receiveMyPages(data.mypages);
          break;
        case INSTANT_PAGE_CALLBACK:
          this._instantPageDispatchers.receiveInstantPageDetails(
            data.instant_page
          );
          if (data.reference) {
            this._myPageDispatchers.pageTemplateCreated(
              data.reference,
              (<MyPage>data.instant_page).id
            );
          }
          break;
        case INSTANT_PAGES_CALLBACK:
          this._instantPageDispatchers.receiveInstantPages(data.instant_pages);
          break;
        case MY_CALL_BACK_STATS_CALLBACK:
          this._messageDispatchers.setMessageMenuStats(
            data.message_id,
            data.data
          );
          break;
        case APP_STORE_CALLBACK:
          setTimeout(
            () =>
              this._appPublishingDispatchers.receiveAppStoreInfo(
                data.app_store
              ),
            1000
          );

          break;
        case MY_MEDIA_CALLBACK:
          this._mediaCenterDispatchers.myMediaGalleryRcvd(data.data);
          break;
        case REQUEST_APPLE_CODE_CALLBACK:
          this._appReleaseDispatchers.showAppleKeyForm(data.release_id);
          break;
        case BOOKING_PERIODS_CALLBACK: {
          if (data.recordIndex) {
            this._bulkDispatchers.recordCreated(
              data.recordIndex,
              RecordTypes.BOOKING_PERIODS
            );
          }
          const bookingPeriods: BookingPeriods = {};
          bookingPeriods.chat_id = data.chat_id;
          bookingPeriods.data = data.data;
          this._bookingPeriodsDispatchers.receiveBookingPeriods(bookingPeriods);

          break;
        }
        case GET_BOOKING_EXCEPTIONS_CALLBACK:
        case SET_BOOKING_EXCEPTIONS_CALLBACK: {
          if (data.recordIndex) {
            this._bulkDispatchers.recordCreated(
              data.recordIndex,
              RecordTypes.BOOKING_HOLIDAYS
            );
          }
          this._bookingExceptionsDispatchers.receiveBookingExceptions(
            data.chat_id,
            data.data
          );
          break;
        }
        case MY_BOOKINGS_CALLBACK: {
          if (data.ref && data.ref === REQUEST_MY_BOOKING) {
            const myBookings: MyBookings = {};
            myBookings.chat_id = data.chat_id;
            myBookings.start_date = data.start_date;
            myBookings.data = data.data;
            this._myBookingsDispatchers.receiveMyBookings(myBookings);
          }
          break;
        }
        case APP_CHANNEL_SETTINGS_CALLBACK:
          this._appChannelSettingsDispatchers.receiveAppChannelSettingsHistory(
            data.whiteList_enabled,
            data.deep_link_schema
          );
          break;
        case APP_CONFIG_HISTORY_INFO:
          this._appReleaseDispatchers.rcvdAppReleaseVerData(
            data.app_config_history
          );
          break;
        case PAYMENT_TOKEN_CALLBACK:
          this._paymentDispatchers.setPaymentToken(data.token);
          break;
        case PAYMENT_SET_CALLBACK:
          this._paymentDispatchers.paymentSet();
          break;
        case PAYMENT_CALLBACK:
          this._paymentDispatchers.setPaymentInfo(data);
          break;
        case APP_CHANNEL_DATA_CALLBACK:
          this._appChannelDataDispatchers.receiveAppChannelData(data.values);
          break;
        case MAP_SERVICE_CALLBACK:
          if (data.reference && data.reference === CREATE_MAP_SERVICE_REF) {
            // this._mapServiceDispatchers.mapServiceCreated(data.mapService);
            this._mapServiceDispatchers.resetMapServicePaging();
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._mapServiceDispatchers.mapServiceDeleted(data.id);
            } else {
              this._mapServiceDispatchers.mapServiceDetailsReceived(
                data.mapService
              );
            }
          }
          break;
        case MAP_SERVICES_CALLBACK:
          this._mapServiceDispatchers.mapServiceListReceived(
            data.eop,
            data.sop,
            data.mapServices,
            data.hash
          );
          break;
        case MAP_MARKER_CALLBACK:
          if (
            data.reference === CREATE_BULK_REF ||
            data.reference === UPDATE_BULK_REF
          ) {
            if (data.recordIndex) {
              this._bulkDispatchers.recordCreated(
                data.recordIndex,
                RecordTypes.MAP_TRACKING,
                data.id,
                data.reference
              );
            }
          } else if (
            data.reference &&
            data.reference === CREATE_MAP_MARKER_REF
          ) {
            // this._mapMarkerDispatchers.mapMarkerCreated(data.marker);
            this._mapMarkerDispatchers.resetMapMarkerPaging();
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._mapMarkerDispatchers.mapMarkerDeleted(data.id);
            } else {
              this._mapMarkerDispatchers.mapMarkerDetailsReceived(data.marker);
            }
          }
          break;
        case MAP_MARKERS_CALLBACK:
          this._mapMarkerDispatchers.mapMarkerListReceived(data);
          break;
        case MAP_ROUTE_CALLBACK:
          if (data.reference && data.reference === CREATE_MAP_ROUTE_REF) {
            // this._mapRouteDispatchers.mapRouteCreated(data.mapRoute);
            this._mapRouteDispatchers.resetMapRoutePaging();
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._mapRouteDispatchers.mapRouteDeleted(data.id);
            } else {
              this._mapRouteDispatchers.mapRouteDetailsReceived(data.mapRoute);
            }
          }
          break;
        case MAP_ROUTES_CALLBACK:
          this._mapRouteDispatchers.mapRouteListReceived(
            data.eop,
            data.sop,
            data.mapRoutes,
            data.hash
          );
          break;
        case MAP_TRIP_CALLBACK:
          if (data.reference && data.reference === CREATE_MAP_TRIP_REF) {
            // this._mapTripDispatchers.mapTripCreated(data.mapTrip);
            this._mapTripDispatchers.resetMapTripPaging();
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._mapTripDispatchers.mapTripDeleted(data.id);
            } else {
              this._mapTripDispatchers.mapTripDetailsReceived(data.mapTrip);
            }
          }
          break;
        case MAP_TRIPS_CALLBACK:
          this._mapTripDispatchers.mapTripListReceived(
            data.eop,
            data.sop,
            data.mapTrips,
            data.hash
          );
          break;
        case MAP_TRIP_USER_CALLBACK:
          if (data.reference && data.reference === CREATE_MAP_TRIP_USER_REF) {
            this._mapTripUserDispatchers.mapTripUserCreated(data.mapTripUser);
          } else {
            if (data.status && data.status === entityStatuses.DELETED) {
              this._mapTripUserDispatchers.mapTripUserDeleted(data.id);
            } else {
              this._mapTripUserDispatchers.mapTripUserDetailsReceived(
                data.mapTripUser
              );
            }
          }
          break;
        case MAP_STOP_TIME_CALLBACK:
          this._mapStopTimeDispatchers.createMapStopTimeReceived(
            data.mapStopTime
          );
          break;
        case MAP_STOP_TIMES_CALLBACK:
          this._mapStopTimeDispatchers.MapStopTimesListReceived(
            data.mapStopTimes
          );
          break;

        case MAP_TRIP_USERS_CALLBACK:
          this._mapTripUserDispatchers.mapTripUserListReceived(
            data.eop,
            data.sop,
            data.mapTripUsers,
            data.hash
          );
          if (data.mapTripUsers && data.mapTripUsers.length > 0) {
            data.mapTripUsers.map(res => {
              this._profileDispatcher.profileInvalidated(res.account_id);
              if (res.checkin_id && res.checkin_id !== '0') {
                this._mapMarkerDispatchers.getMapMarker(res.checkin_id);
              }
              if (res.checkout_id && res.checkout_id !== '0') {
                this._mapMarkerDispatchers.getMapMarker(res.checkout_id);
              }
            });
          }
          break;
        case MAP_ACTIVE_TRIPS_CALLBACK:
          this._mapTripHistoryDispatchers.mapTripHistoryListReceived(
            data.eop,
            data.sop,
            data.mapActiveTrips,
            data.hash
          );
          if (data.mapActiveTrips && data.mapActiveTrips.length > 0) {
            data.mapActiveTrips.map(res => {
              if (res.trip_id) {
                this._mapTripDispatchers.getMapTripById(res.trip_id);
              }
            });
          }
          break;
        case MAP_ACCOUNT_LINKS_CALLBACK:
          if (data.reference === 'CreateAccountLinks') {
            this.mapTripsNotifiersDispatchers.mapAccountLinksCreated(
              data.mapAccountLinks
            );
          } else {
            data.mapAccountLinks.map(res => {
              if (res.link_id !== '0') {
                this._profileDispatcher.profileInvalidated(res.link_id);
              }
            });
            this.mapTripsNotifiersDispatchers.notifiersListReceived(
              data.mapAccountLinks,
              data.eop,
              data.sop,
              data.reference
            );
          }
          break;
        case MAP_ACCOUNT_LINK_CALLBACK:
          this.mapTripsNotifiersDispatchers.removeAccountLinkSuccess(data.id);
          break;
        case MAP_LINKED_ACCOUNTS_CALLBACK:
          data.mapLinkedAccounts.map(res =>
            this._profileDispatcher.profileInvalidated(res.account_id)
          );
          this.mapTripsNotifiersDispatchers.mapAccountLinksListReceived(
            data.mapLinkedAccounts
          );
          break;

        case MAP_ASSIGN_TRIPS_CALLBACK:
          this._driverTripsDispatchers.driverTripsReceived(data.mapAssignTrips);
          break;
        case MAP_TRIPS_ASSIGN_CALLBACK:
          this._mapDriverTripsDispatchers.mapDriverReceived(
            data.tripAssignedAccountsIds
          );
          break;
        case OTHER_TRIPS_CALLBACK:
          this._otherTripsDispatchers.driverTripsReceived(data);
          break;
        case GET_SMTP_CALLBACK:
          this._smtpDispatchers.receiveStmp(data);
          break;
        case CALENDARS_CALLBACK:
          if (data.calendars && data.calendars.length > 0) {
            this._calendarUiDispatchers.receivedCalendarHistoryChunk(
              data.eop,
              data.sop,
              data.calendars
            );
          }
          break;
        case CALENDAR_CALLBACK:
          this._calendarUiDispatchers.calendarReceived(data.calendar);
          if (data.reference && CREATE_CALENDAR_REF === data.reference) {
            this._calendarUiDispatchers.setCalendarInCreation(data.calendar);
          }
          break;
        case CALENDAR_DETAILS_CALLBACK:
          this._calendarUiDispatchers.calendarDetailsReceived(
            data.calendar_id,
            data.data
          );
          break;
        case SERVICE_ORDERS_CALLBACK:
          if (data.data) {
            this._serviceOrdersUiDispatchers.receivedServiceOrdersHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case PAYMENT_ORDERS_CALLBACK:
          if (data.data) {
            this._paymentOrdersUiDispatchers.receivedPaymentOrdersHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case PAYMENT_PROVIDERS_CALLBACK:
          this._paymentProvidersDispatchers.receivePaymentProviders(data.data);
          break;
        case ADS_PROVIDERS_CALLBACK:
          const adsProvider: AdsConfig = {};
          adsProvider.provider_id = data.provider_id;
          adsProvider.config = data.config;
          this._adsProvidersDispatchers.receiveAdsProviders(adsProvider);
          break;
        case SET_PAYMENT_PROVIDER_CALLBACK:
          this._paymentProvidersDispatchers.receivePaymentProvider(
            data.provider_id,
            data.config
          );
          break;
        case USER_BALANCE_CALLBACK:
          this._profileDispatcher.userBalanceReceived(
            data.user_id,
            data.user_balance
          );
          break;
        case USER_PRODUCT_BALANCE_CALLBACK:
          this._profileDispatcher.userProductBalanceReceived(
            data.user_id,
            data.user_product_balance
          );
          break;
        case APPS_CALLBACK:
          const appLocalInfo: LocalAppInfo = {};
          appLocalInfo.listOfAvailableApps = data.apps;
          this._appLocalInfoDispatchers.updateAppLocalInfo(appLocalInfo);
          break;
        case APPS_SETUP_CALLBACK:
          this._appPublishingDispatchers.receiveAppsSetups(data.apps);
          break;
        case APP_SETUP_CALLBACK:
          setTimeout(
            () => this._appPublishingDispatchers.receiveAppSetupInfo(data.app),
            1000
          );

          break;
        case CASH_PAYMENT_CALLBACK:
          this._bundlesDispatchers.cashPaymentStatus(data);
          break;
        case PRODUCTS_CALLBACK:
          if (data.products) {
            this._productsDispatchers.receivedProductsHistoryChunk(
              data.eop,
              data.sop,
              data.products
            );
          }
          break;
        case PRODUCT_CALLBACK:
          if (
            data.reference === CREATE_BULK_REF ||
            data.reference === UPDATE_BULK_REF
          ) {
            if (data.recordIndex) {
              this._bulkDispatchers.recordCreated(
                data.recordIndex,
                RecordTypes.PRODUCT,
                data.product.id,
                data.reference
              );
            }
          } else if (data.bundle_id) {
            this._bundlesDispatchers.bundleProductInfoReceived(
              data.bundle_id,
              data.product
            );
          } else {
            if (data.status === entityStatuses.DELETED) {
              this._productsDispatchers.protuctDeleted(data.id);
            } else {
              // ethier way recived product in case ref=create then reset
              this._productsDispatchers.productReceived(data.product);
              if (data.reference === CREATE_PRODUCT_REF) {
                this._productsDispatchers.resetProductsPaging();
              }
            }
          }
          break;
        case BUNDLES_CALLBACK:
          if (data.data) {
            this._bundlesDispatchers.receivedBundlesHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case BUNDLE_CALLBACK:
          if (
            data.reference === CREATE_BULK_REF ||
            data.reference === UPDATE_BULK_REF
          ) {
            if (data.recordIndex) {
              this._bulkDispatchers.recordCreated(
                data.recordIndex,
                RecordTypes.BUNDLE,
                data.bundle.id,
                data.reference
              );
            }
          } else {
            if (data.status === entityStatuses.DELETED) {
              this._bundlesDispatchers.bundleDeleted(data.id);
            } else {
              this._bundlesDispatchers.bundleReceived(data.bundle);
              if (data.reference === CREATE_BUNDLE_REF) {
                this._bundlesDispatchers.resetBundlesPaging();
              }
            }
          }
          break;
        case PACKAGES_CALLBACK:
          if (data.data) {
            this._packagesDispatchers.receivedPackagesHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case PACKAGE_CALLBACK:
          if (
            data.reference === CREATE_BULK_REF ||
            data.reference === UPDATE_BULK_REF
          ) {
            if (data.recordIndex) {
              this._bulkDispatchers.recordCreated(
                data.recordIndex,
                RecordTypes.PACKAGE,
                data.package_group.id,
                data.reference
              );
            }
          } else {
            if (data.status === entityStatuses.DELETED) {
              this._packagesDispatchers.packageDeleted(data.id);
            } else {
              this._packagesDispatchers.packageReceived(data.package_group);
              if (data.reference === CREATE_PACKAGE_REF) {
                this._packagesDispatchers.resetPackagesPaging();
              }
            }
          }
          break;
        case MSTORES_CALLBACK:
          if (data.data) {
            this._storesDispatchers.receivedStoreHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case MSTORE_CALLBACK:
          if (data.status === entityStatuses.DELETED) {
            this._storesDispatchers.storeMobileDeleted(data.id);
          } else {
            this._storesDispatchers.storeReceived(data.mStore);
            if (data.reference === CREATE_STORE_REF) {
              this._storesDispatchers.resetStorePaging();
            }
          }
          break;
        case STOREMENUS_CALLBACK:
          if (data.data) {
            this._storeMenuDispatchers.receivedStoreMenuHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case STOREMENU_CALLBACK:
          if (data.status === entityStatuses.DELETED) {
            this._storeMenuDispatchers.storeMenuDeleted(data.id);
          } else {
            this._storeMenuDispatchers.storeMenuReceived(data.store_menu);
            if (data.reference === CREATE_STORE_MENU_REF) {
              this._storeMenuDispatchers.resetStoreMenuPaging();
            }
          }
          break;
        case SUBMENUS_CALLBACK:
          if (data.data) {
            this._subMenuDispatchers.receivedSubMenuHistoryChunk(
              data.eop,
              data.sop,
              data.data
            );
          }
          break;
        case SUBMENU_CALLBACK:
          // this._storeMenuDispatchers.subMenuStoreReceived(data.);
          if (data.reference === CREATE_SUB_MENU_REF) {
            this._subMenuDispatchers.resetSubMenuPaging();
          }
          break;
        case CONNECTE_STRIPE_CALLBACK:
          if (data.stripe_connected_account_url) {
            window.open(data.stripe_connected_account_url);
          }
          break;
        case STATS_CALLBACK:
          this._chartDispatchers.receiveChartData(
            data.type,
            data.value,
            data.start_date,
            data.end_date,
            data.interval
          );
          break;
        case VERFY_DEMO_ACCOUNT_CALLBACK:
          {
            this.appMangemtService.verfyDemoAcc();
          }
          break;
        case GET_DEMO_ACCOUNT_STATUS_CALLBACK:
          {
            this.appMangemtService._demoAccStatus.next(data.active);
          }
          break;
        case THIRD_PARTY_INFO_CALLBACK:
          this.appMangemtService.savePage(false);
          break;
        case TESTED_MESSAGING_VENDOR_CALLBACK:
          this._thirdPartyInfoDispatchers.receiveTestThirdPartyInfo(
            data.accepted,
            data.message,
            data.tac
          );
          break;
        case MESSAGING_VENDOR_CALLBACK:
          this.appMangemtService._oldVendors.next(data.vendor);
          this._thirdPartyInfoDispatchers.receiveThirdPartyInfo(data.vendor);
          break;
        case SIGNED_UP_DEMO_ACCOUNT_CALLBACK:
          this.appMangemtService._loading.next(false);
          this.appMangemtService._mayAttempts.next(true);
          this.appMangemtService._demoAccMustWait.next(data.mustWait);
          break;
        case TAC_DEMO_ACCOUNT_CALLBACK:
          this.appMangemtService._demoAccMustWait.next(data.mustWait);
          break;

        case TEMPLATE_META_CALL_BACK:
          this.appMangemtService._selectedTempate.next(data);
          break;
        case SUBSCRIBE_TO_PLAN_RESPONSE_CALLBACK:
          if (data.success) {
            // this._billingUiDispatchers.receivedSelecetedPLan(data.plan_id);
            // this._billingUiDispatchers.goToLastSection();
            this._uIDispatchers.addToastMessage(
              'Plan Subscription Successfully',
              true
            );
          } else {
            this._billingUiDispatchers.setBillingStatus('');
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case CREATE_CARD_RESPONSE_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Card Added Successfully',
              true
            );
            this._uIDispatchers.setSection(UISections.BILLINGS);
          } else {
            this._billingUiDispatchers.setBillingStatus('');
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case CHANGE_PLAN_RESPONSE_CALLBACK:
          if (data.success) {
            this._billingUiDispatchers.setCurrentScreen(
              BillingsPageScreens.BILLINGS_DETAILS_SCREEN
            );
            this._uIDispatchers.addToastMessage(
              'Plan Changed Successfully',
              true
            );
          } else {
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case GET_INVOICES_RESPONSE_CALLBACK:
          this._billingUiDispatchers.receveInvoicesLisUI(data.data);
          break;
        case RETRIEVE_COUPON_RESPONSE_CALLBACK:
          if (data.success) {
            const coupon: BillingCoupon = { ...data.data };
            coupon.status = true;
            this._billingUiDispatchers.receveCouponResponse(coupon);
          } else {
            const coupon: BillingCoupon = {};
            coupon.status = false;
            this._billingUiDispatchers.receveCouponResponse(coupon);
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case DELETE_CARD_RESPONSE_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Card Deleted Successfully',
              true
            );
            this._billingUiDispatchers.setBillingStatus('');
          } else {
            this._billingUiDispatchers.setBillingStatus('');
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case SET_DEFAULT_CARD_RESPONSE_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Set Default Card Successfully',
              true
            );
            this._billingUiDispatchers.setBillingStatus('');
          } else {
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case SUBSCRIBE_TO_ADDONS_RESPONSE_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Subscription Added Successfully',
              true
            );
            this._billingUiDispatchers.setBillingStatus('');
          } else {
            this._billingUiDispatchers.setBillingStatus('');
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case CANCEL_STRIPE_SUBSCRIPTION_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Subscription Canceled Successfully',
              true
            );
            this._billingUiDispatchers.setBillingStatus('');
          } else {
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case PAYMENT_INFO_RESPONSE_CALLBACK:
          this._billingUiDispatchers.receveBillingInfoResponse(
            data,
            data.main_plan
          );
          break;
        case REVOKE_SUBSCRIPTION_RESPONSE_CALLBACK:
          if (data.success) {
            this._uIDispatchers.addToastMessage(
              'Subscription Activated Successfully',
              true
            );
          } else {
            this._uIDispatchers.addToastMessage(data.error_msg, false);
          }
          break;
        case CONF_MODULES_CALLBACK:
          this._uIDispatchers.receiveConfModules(data.modules);
          break;
        case SET_BUSINESS_INFO_RESPONSE:
        case GET_BUSINESS_INFO_RESPONSE:
          this._authDispatchers.reseaveBusinessInfoResponse(
            data.company_name,
            data.website
          );
          break;
        case PRODUCTS_STORE_CALLBACK:
          this._productsStoreDispatchers.receivedProductsStoreHistoryChunk(
            data.eop,
            data.sop,
            data.data
          );
          break;
        case GET_PRODUCT_ITEM_RESPONSE:
          this._productsStoreDispatchers.receivedProductStore(data.data);
          break;
        case SET_PRODUCT_ITEM_RESPONSE:
          this._productsStoreDispatchers.setSelectedProductStore(data.data);
          break;
        case DELETE_PRODUCT_ELEMENT_RESPONSE:
          this._productsStoreDispatchers.removeProductItemSuccess(
            data.data.product_id
          );
          break;
        case SET_PRODUCT_ITEM_ARRAY_RESPONSE:
          this._productsStoreDispatchers.setProductItemArraySuccess(
            data.data.parent_id,
            data.data.products
          );
          break;
        case LIST_COLLECTIONS_CALLBACK:
          this._collectionsUIDispatchers.receiveListCollectionsHistoryChunk(
            data.eop,
            data.sop,
            data.data
          );
          break;
        case SET_COLLECTION_CALLBACK:
          this._collectionsUIDispatchers.collectionReceived(data.data);
          break;
        case GET_COLLECTION_CALLBACK:
          this._collectionsUIDispatchers.collectionReceived(data.data);
          break;
        case DELETE_COLLECTION_CALLBACK:
          this._collectionsUIDispatchers.deleteCollectionItemSuccess(
            data.data.collection_id
          );
          break;
        case GET_COLLECTION_PRODUCTS_CALLBACK:
          this._collectionsUIDispatchers.receveCollectionProducts(data.data);
          break;
        case GET_STORE_CALLBACK:
          this.mobileWorkflowStoreService._storeData.next(data.data);
          this.setDefaultStoreMenu(data.data);

        case list_STORE_CALLBACK:
          this._mobileWorkflowStoreDispachers.menusReceiving(data);
          break;
        case SET_STORE_CALLBACK:
          this.updateStoreMenuList(data.data);
          this.mobileWorkflowStoreService._waiting.next(false);
          break;
        case DELETE_MSTORE_CALL_BACK:
          this._mobileWorkflowStoreDispachers.listMenus();
          break;
        case SET_PAYMENT_SERVICE_CALLBACK:
          this.botsService.savePaymentServiceResponse(data.product);
          break;
        case VERIFICATION_TEMPLATE_CALLBACK:
          this._thirdPartyInfoDispatchers.receiveVerificationMessageTemplate(
            data.email,
            data.sms
          );
          break;
        case CHAT_LABEL_CALLBACK:
          if (data.chat_label !== null && data.chat_label !== 'null') {
            this._uIDispatchers.setChatLabels(data.chat_label);
          }
          break;
        case CHAT_THEME_CALLBACK:
          this._chatThemesDispatchers.receveChatThemeFromServer(
            data.chat_theme
          );
          break;
        case GET_LIST_USERS_CALLBACK:
          this._membersUiDispatchers.reseaveChatUsersList(data.users);
          for (let i = 0; i < data.users.length; i++) {
            this._profileDispatcher.profileInvalidated(data.users[i]);
          }
          break;
        default:
          break;
      }
    }
  }

  private chatReceived(data): IChat {
    const createdGroup: IChat = {};
    createdGroup.id = data.chat_id;
    createdGroup.admin = true;
    this._chatDispatchers.newChatReceived(createdGroup);
    return createdGroup;
  }

  private commonAuthCalls() {
    this._authDispatchers.getMyProfiles();
  }

  private loginAuthCalls(is_default: boolean) {
    this._authDispatchers.getUpgradedChatList(is_default);
  }

  private signUpAuthCalls() {
    this._paymentDispatchers.setSelectedFreePayment();
  }

  private selectTemplate() {
    if (
      this.localStorage.getRecord('tempId') !== '0' &&
      this.localStorage.getRecord('tempId') !== null &&
      this.localStorage.getRecord('tempId') !== 'null'
      // new Template exist
    ) {
      // this.localStorage.setRecord(
      //   'tempId',
      //   this.localStorage.getRecord('newTempId')
      // );
      // this.localStorage.setRecord('newTempId', null);

      const tempId = this.localStorage.getRecord('tempId');

      this._appConfigDispatchers.requestAppTemplate(tempId);
    }
    // new template does not exist
    else if (
      this.localStorage.getRecord('tempId') === null ||
      this.localStorage.getRecord('tempId') === 'null'
    ) {
      // template is not exist

      this.localStorage.setRecord('tempId', DEFAULT_TEMP);
      this.localStorage.setRecord('newTempId', null);

      const tempId = this.localStorage.getRecord('tempId');
      this._appConfigDispatchers.requestAppTemplate(DEFAULT_TEMP);
    }
  }

  private userPrivillegeCalls() {
    this._authDispatchers.getChatPrivilleges();
  }

  private appSettingsAuthCalls() {
    this._paymentProvidersDispatchers.getPaymentProviders();
    this._authDispatchers.getSearchFilters();
    this._authDispatchers.getAppSetup();
  }

  private appPropertiesOwnerAuthCalls() {
    this._paymentDispatchers.getPaymentInfo();
  }

  private appGeneralCalls() {
    this._uiDispacthers.getChatLabels();
    this._authDispatchers.getBots();
  }

  private appPropertiesUserAuthCalls(chatId) {
    this._summaryUIDispatchers.getUserDashboardList();
    this._chatDispatchers.getChatAdministrators(chatId);

    this._authDispatchers.getRoles();
  }

  private selectedChatAuth(chatId, isAuth?) {
    this._authDispatchers.setChatSelect(chatId, isAuth);

    this._authDispatchers.getChatData(chatId);
  }

  private asignMyPage(data) {
    switch (data.reference) {
      case CREATE_PAGE_REF.TAB:
        this.asignPageToTab(data);
        break;
      case CREATE_PAGE_REF.NAV:
        this.asignPageToNav(data);
        break;
      case CREATE_PAGE_REF.SUBTOTAB:
        this.asignSubPageToTab(data);
        break;
      case CREATE_PAGE_REF.BTN:
        this.asignPageToBtn(data);
        break;
      case CREATE_PAGE_REF.TAB_TEMP:
        this.asignPageToTabWithTemplate(data);
        break;
      case CREATE_PAGE_REF.NAV_TEMP:
        this.asignPageToNavWithTemp(data);
        break;
      case CREATE_PAGE_REF.BTN_TEMP:
        this.asignPageToBtnWithTemp(data);
        break;
      case CREATE_PAGE_REF.SUBTOBTN:
        break;
      case CREATE_PAGE_REF.SUBTONAV:
        this.asignSubPageToNav(data);
        break;
      case CREATE_PAGE_REF.SUBTOBTN:
        break;
      case CREATE_PAGE_REF.FEATURELIST:
        break;
    }
    // this.appMangemtService.getDetailsOfLinkedPage(data.mypage.id);
    this.appMangemtService._mypageButtonId.next(undefined);
    this.authService._waitForTempConfig.next(false);
    this.appMangemtService._scrollDashBoard.next(false);
  }

  private asignPageToTab(data) {
    let selectedTab;
    const sTab = this.appMangemtService.effectiveSelectedTab$.subscribe(tab => {
      selectedTab = tab;
    });

    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;
    const lTabs = this.appMangemtService.tabsContainers$.subscribe(list => {
      newContainer.order = list.length;
    });

    let tabsAppClass = {};
    this.appMangemtService.tabsAppClass$.subscribe(appClass => {
      tabsAppClass = appClass;
    });

    newContainer.type = TabTypes.PAGE;
    newContainer.icon_ios = 'ic_feeds_books_white_24_dp';

    this.appMangemtService.addContainer(tabsAppClass, newContainer);

    this.appMangemtService.selectLastTab();
    this.appMangemtService._createNewTab.next(false);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
    sTab.unsubscribe();
    lTabs.unsubscribe();
  }

  private asignPageToTabWithTemplate(data) {
    let selectedTab;
    this.appMangemtService.effectiveSelectedTab$.subscribe(tab => {
      selectedTab = tab;
    });
    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;

    let tabsAppClass = {};
    this.appMangemtService.tabsAppClass$.subscribe(appClass => {
      tabsAppClass = appClass;
    });

    newContainer.type = TabTypes.PAGE;
    newContainer.icon_ios = 'ic_feeds_books_white_24_dp';

    this.appMangemtService.addContainer(tabsAppClass, newContainer);

    this.appMangemtService.selectLastTab();
    this.appMangemtService._createNewTab.next(false);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
  }

  private asignPageToNav(data) {
    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;
    let navComponent = {};
    this.appMangemtService.navMenuComponent$.subscribe(component => {
      navComponent = component;
    });
    newContainer.link = TabTypes.PAGE;
    newContainer.icon_ios = 'ic-channels-29-px';
    this.appMangemtService.navMenuItems$.subscribe(sideMenuList => {
      newContainer.order = sideMenuList.length;
    });

    this.appMangemtService.addItem(navComponent, newContainer);
  }

  private asignPageToNavWithTemp(data) {
    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;
    let navComponent = {};
    this.appMangemtService.navMenuComponent$.subscribe(component => {
      navComponent = component;
    });
    newContainer.link = TabTypes.PAGE;
    newContainer.icon_ios = 'ic-channels-29-px';
    this.appMangemtService.navMenuItems$.subscribe(sideMenuList => {
      newContainer.order = sideMenuList.length;
    });

    this.appMangemtService.addItem(navComponent, newContainer);
  }

  private asignSubPageToTab(data) {
    let selectedTab;
    this.appMangemtService.effectiveSelectedTab$.subscribe(tab => {
      selectedTab = tab;
    });
    const updateContainer: any = {};
    let subPages = [];

    if (selectedTab.sub_pages) {
      subPages = selectedTab.sub_pages;
    }
    subPages.push(data.mypage.id);
    updateContainer.sub_pages = subPages;

    this.appMangemtService.updateContainer(selectedTab.ref, updateContainer);
    this.appMangemtService._selectedPageId.next(data.mypage.id);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
  }

  private asignSubPageToNav(data) {
    let selectedItem;
    this.appMangemtService.effectiveSelectedItem$.subscribe(item => {
      selectedItem = item;
    });
    const updateContainer: any = {};
    let subPages = [];

    if (selectedItem.sub_pages) {
      subPages = selectedItem.sub_pages;
    }
    subPages.push(data.mypage.id);
    updateContainer.sub_pages = subPages;

    this.appMangemtService.updateItem(selectedItem.ref, updateContainer);
    this.appMangemtService._selectedPageId.next(data.mypage.id);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
  }

  private asignPageToBtn(data) {
    this.appMangemtService.mypageButtonId$.subscribe(btnId => {
      if (btnId) {
        this.buttonId = btnId;
      }
    });

    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;

    let tabsAppClass = {};
    this.appMangemtService.tabsAppClass$.subscribe(appClass => {
      tabsAppClass = appClass;
    });

    newContainer.type = TabTypes.PAGE;
    newContainer.icon_ios = 'ic_feeds_books_white_24_dp';

    newContainer.layout = 'button';
    newContainer.id = this.buttonId;
    this.appMangemtService._pageId.next(data.mypage.id);
    // this.appMangemtService._selectedMenuButtonTab.next(newContainer);

    this.appMangemtService.addContainer(tabsAppClass, newContainer);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
  }

  private asignPageToBtnWithTemp(data) {
    this.appMangemtService.mypageButtonId$.subscribe(btnId => {
      if (btnId) {
        this.buttonId = btnId;
      }
    });
    const newContainer: any = {};
    newContainer.page_id = data.mypage.id;
    newContainer.title = data.mypage.name;
    newContainer.icon = 'ic_feeds_books_white_24_dp';

    newContainer.url = data.mypage.url;

    let tabsAppClass = {};
    this.appMangemtService.tabsAppClass$.subscribe(appClass => {
      tabsAppClass = appClass;
    });

    newContainer.type = TabTypes.PAGE;
    newContainer.icon_ios = 'ic_feeds_books_white_24_dp';

    newContainer.layout = 'button';
    newContainer.id = this.buttonId;
    this.appMangemtService._pageId.next(data.mypage.id);
    // this.appMangemtService._selectedMenuButtonTab.next(newContainer);

    this.appMangemtService.addContainer(tabsAppClass, newContainer);
    this.appMangemtService._selectedPageIdToView.next(data.mypage.id);
  }
  setDefaultStoreMenu(data) {
    this.mobileWorkflowStoreService._isDefault.next(data.is_default);

    if (data.shop) {
      const uiSub = this.uiService.uiCollection$.subscribe(collection => {
        if (collection.section === UISections.APP) {
          const newStoreAppList: any[] = [];
          const mobSub = this.mobileWorkflowStoreService.mobileStoreAppList$.subscribe(
            (store: any) => {
              if (store) {
                store.forEach(st => {
                  if (st && st.id && st.id !== data.id) {
                    const savedStore: any = {};
                    savedStore.id = st.id;
                    savedStore.currency = st.currency;
                    savedStore.is_default = st.is_default;
                    savedStore.data = st.data;
                    newStoreAppList.push(savedStore);
                  }
                });
              }
            }
          );
          mobSub.unsubscribe();
          const newStore: any = {};
          newStore.id = data.id;
          newStore.currency = data.currency;
          newStore.is_default = data.is_default;
          newStore.data = data;
          let found = false;
          newStoreAppList.forEach(store => {
            if (store.id === newStore) {
              found = true;
            }
          });
          if (!found) {
            newStoreAppList.push(newStore);
          }

          this.mobileWorkflowStoreService._mobileStoreAppList.next(
            newStoreAppList
          );
          const tabSub = this.appMangemtService.effectiveSelectedTab$.subscribe(
            tab => {
              if (tab && tab.type === 'menu' && tab.sub_type) {
                newStoreAppList.forEach(store => {
                  if (tab && tab.store_id && store.id + '' === tab.store_id) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  } else if (tab && !tab.store_id && store.is_default) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        // this.appMangemtService._selectedMenu.next(menu);
                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  }
                });
              }
            }
          );
          tabSub.unsubscribe();

          const itemSub = this.appMangemtService.effectiveSelectedItem$.subscribe(
            tab => {
              if (tab && tab.link === 'menu' && tab.sub_type) {
                newStoreAppList.forEach(store => {
                  if (tab && tab.store_id && store.id + '' === tab.store_id) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  } else if (tab && !tab.store_id && store.is_default) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        // this.appMangemtService._selectedMenu.next(menu);

                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  }
                });
              }
            }
          );
          itemSub.unsubscribe();
          const btnTabSub = this.appMangemtService.selectedMenuButtonTab$.subscribe(
            tab => {
              if (tab && tab.type === 'menu' && tab.sub_type) {
                newStoreAppList.forEach(store => {
                  if (tab && tab.store_id && store.id + '' === tab.store_id) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  } else if (tab && !tab.store_id && store.is_default) {
                    data.shop.forEach(menu => {
                      if (store.data.menu_id === menu.menu_id) {
                        // this.appMangemtService._selectedMenu.next(menu);

                        this.appMangemtService._selectedMenu.next(menu);
                        this.mobileWorkflowStoreService._selectedMenu.next(
                          menu
                        );
                        this.mobileWorkflowStoreService._mainMenuId.next(
                          data.menu_id
                        );
                        this.mobileWorkflowStoreService._selectedMenuList.next(
                          data.shop
                        );
                        this.appMangemtService._selectedMenuButton.next(false);
                      }
                    });
                    if (data.id) {
                      this.mobileWorkflowStoreService._selectedStoreId.next(
                        data.id
                      );
                    }
                  }
                });
              }
            }
          );
          btnTabSub.unsubscribe();
        } else {
          data.shop.forEach(menu => {
            if (data.menu_id === menu.menu_id) {
              this.appMangemtService._selectedMenu.next(menu);
              this.mobileWorkflowStoreService._selectedMenu.next(menu);
              this.mobileWorkflowStoreService._mainMenuId.next(data.menu_id);
              this.mobileWorkflowStoreService._selectedMenuList.next(data.shop);
              this.appMangemtService._selectedMenuButton.next(false);
            }
          });
          if (data.id) {
            this.mobileWorkflowStoreService._selectedStoreId.next(data.id);
          }
        }
      });
      uiSub.unsubscribe();
    } else {
      const newMenu = this.setFirstMenu();
      this.appMangemtService._selectedMenu.next(newMenu);
      this.mobileWorkflowStoreService._selectedMenu.next(newMenu);
      this.mobileWorkflowStoreService._mainMenuId.next(newMenu);
      this.mobileWorkflowStoreService._selectedMenuList.next([newMenu]);
      this.appMangemtService._selectedMenuButton.next(false);
    }
  }

  setFirstMenu() {
    let id = null;

    id = 1;
    const date = new Date();
    const newId = Math.floor(date.getDate() + Math.random() * 90000000);
    id = 'store' + newId;

    const api = null;

    return {
      id: null,
      menu_id: id,
      api_id: api,
      menu_ref: id,
      menu_date: null,
      menu_name: id,
      next_menu: null,
      rows: []
    };
  }

  updateStoreMenuList(data) {
    if (data.id) {
      this.mobileWorkflowStoreService._selectedStoreId.next(data.id);
    }
    if (data.is_default && data.is_default !== '0') {
      this.mobileWorkflowStoreService._isDefault.next(1);
    } else {
      this.mobileWorkflowStoreService._isDefault.next(0);
    }
    // if (data.shop) {
    //   data.shop.forEach(menu => {
    //     if (data.menu_id === menu.menu_id) {
    //       // this.appMangemtService._selectedMenu.next(menu);
    //       // this.mobileWorkflowStoreService._selectedMenu.next(menu);
    //       console.log('data.shop', data.shop)
    //       this.mobileWorkflowStoreService._mainMenuId.next(data.menu_id);
    //       this.mobileWorkflowStoreService._selectedMenuList.next(data.shop);
    //       this.appMangemtService._selectedMenuButton.next(false);
    //     }

    //   });

    // }
    const uiSub = this.uiService.uiCollection$.subscribe(collection => {
      if (collection.section === UISections.APP) {
        const newStoreAppList: any[] = [];
        const mobSub = this.mobileWorkflowStoreService.mobileStoreAppList$.subscribe(
          (store: any) => {
            if (store) {
              store.forEach(st => {
                if (st && st.id && st.id !== data.id) {
                  const savedStore: any = {};
                  savedStore.id = st.id;
                  savedStore.currency = st.currency;
                  savedStore.is_default = st.is_default;
                  savedStore.data = st.data;
                  newStoreAppList.push(savedStore);
                } else if (st && (!st.id || st.id === data.id)) {
                  const newStore: any = {};
                  newStore.currency = data.currency;
                  newStore.id = data.id;
                  newStore.is_default = data.is_default;
                  newStore.data = data;

                  newStoreAppList.push(newStore);
                }
              });
            }
          }
        );

        mobSub.unsubscribe();

        this.mobileWorkflowStoreService._mobileStoreAppList.next(
          newStoreAppList
        );
      }
    });

    uiSub.unsubscribe();
  }
}
