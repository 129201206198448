import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CampaignUIDispatchers } from './campaignUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { GetCampaigns, GetCampaign } from 'src/models/ICampaign';
export class CampaignUiEffects {
    constructor(actions$, _socketGateway, _store, _campaignDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._campaignDispatchers = _campaignDispatchers;
        this.getCampaigns = this.actions$.pipe(ofType("UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK" /* UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK */), withLatestFrom(this._store
            .select(state => state.campaignReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, campaigns]) => {
            const action = val;
            action.currentPage.forEach(campaignId => {
                const isCampExist = campaigns.find(camp => camp.campaignId === campaignId);
                if (!isCampExist) {
                    this._socketGateway.sendSocketMessage(new GetCampaign(campaignId));
                }
            });
        }));
        this.getNextCalendarPage = this.actions$.pipe(ofType("UI_CAMPAIGN_GET_NEXT_PAGE" /* UI_CAMPAIGN_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.campaignUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, campaignUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCampaigns(0, campaignUiState.hash, campaignUiState.eop));
        }));
        this.getPrevCalendarPage = this.actions$.pipe(ofType("UI_CAMPAIGN_GET_PREV_PAGE" /* UI_CAMPAIGN_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.campaignUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, campaignUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCampaigns(1, campaignUiState.hash, campaignUiState.sop));
        }));
        this.afterCalendarSectionSelection = this.actions$.pipe(ofType("UI_CAMPAIGN_LIST" /* UI_LIST_CAMPAIGN */), map((action) => {
            this._campaignDispatchers.getNextCampaignPage();
        }));
        this.afterCalendarResetPaging = this.actions$.pipe(ofType("UI_CAMPAIGN_RESET_PAGING" /* UI_CAMPAIGN_RESET_PAGING */), map((action) => {
            this._campaignDispatchers.getNextCampaignPage();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignUiEffects.prototype, "getCampaigns", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignUiEffects.prototype, "getNextCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignUiEffects.prototype, "getPrevCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignUiEffects.prototype, "afterCalendarSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignUiEffects.prototype, "afterCalendarResetPaging", void 0);
