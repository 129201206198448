import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deebLinks'
})
export class DeebLinksPipe implements PipeTransform {
  transform(content) {
    const link = content.match(
      /[A-Za-z0-9]+[A-Za-z0-9\-]*\:\/\/user\?id=[0-9]+&name=[A-Za-z0-9%20]+/g
    );
    let values = content;

    if (link && link.length) {
      for (let i = 0; i < link.length; i++) {
        const name = link[i].match(/name=[A-Za-z0-9%20]+/g);
        const aTag = `<a href="${link[i]}">@~${decodeURIComponent(
          name[0].replace('name=', '')
        )}</a>`;
        values = values.replace(link[i], aTag);
      }
    }
    return values;
  }
}
