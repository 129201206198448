import { Store } from '@ngrx/store';
import * as MapMarkerActions from './mapMarker.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapMarkerDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createMapMarker(mapMarker) {
        this._store.dispatch(new MapMarkerActions.CreateMapMarker(mapMarker));
    }
    getMapMarker(id) {
        this._store.dispatch(new MapMarkerActions.GetMapMarker(id));
    }
    mapMarkerCreated(mapMarker) {
        this._store.dispatch(new MapMarkerActions.MapMarkerCreated(mapMarker));
    }
    updateMapMarker(id, mapMarker) {
        this._store.dispatch(new MapMarkerActions.UpdateMapMarker(id, mapMarker));
    }
    mapMarkerDetailsReceived(mapMarker) {
        this._store.dispatch(new MapMarkerActions.MapMarkerDetailsReceived(mapMarker));
    }
    mapMarkerListReceived(payload) {
        this._store.dispatch(new MapMarkerActions.MapMarkerListReceived(payload));
    }
    deleteMapMarker(id) {
        this._store.dispatch(new MapMarkerActions.DeleteMapMarker(id));
    }
    mapMarkerDeleted(id) {
        this._store.dispatch(new MapMarkerActions.MapMarkerDeleted(id));
    }
    getNextMapMarkerPage() {
        this._store.dispatch(new MapMarkerActions.GetNextMapMarkerPage());
    }
    getPrevMapMarkerPage() {
        this._store.dispatch(new MapMarkerActions.GetPrevMapMarkerPage());
    }
    setStatusAsIdle() {
        this._store.dispatch(new MapMarkerActions.SetStatusAsIdle());
    }
    resetMapMarkerPaging() {
        this._store.dispatch(new MapMarkerActions.ResetMapMarkerPaging());
    }
    setMapMarkerSelectedChat(selected_chat, type) {
        this._store.dispatch(new MapMarkerActions.SetMapMarkerSelectedChat(selected_chat, type));
    }
}
MapMarkerDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapMarkerDispatchers_Factory() { return new MapMarkerDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapMarkerDispatchers, providedIn: "root" });
