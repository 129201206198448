import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _serverError: BehaviorSubject<string> = new BehaviorSubject(null);
  public serverError$ = this._serverError.asObservable();

  constructor(private _authDispatchers: AuthDispatchers) {}

  setServerError(val: string) {
    this._serverError.next(val);
  }

  setNewPassword(
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    this._authDispatchers.changePassword(
      oldPassword,
      newPassword,
      confirmNewPassword
    );
  }
}
