<div class="media-container">
  <div class="loader-container">
    <!-- In case of Dwonloading or uploading -->
    <div
      class="loader-t"
      *ngIf="
        mediaStatus === messageMediaStatus.UPLOADING ||
        mediaStatus === messageMediaStatus.DOWNLOADING
      "
    >
      <svg-icon
        class="cancel"
        src="./assets/img/close-white.svg"
        [stretch]="true"
        [svgStyle]="{ 'width.px': 14, fill: '#C0C0C0' }"
        (click)="cancelEvent()"
      >
      </svg-icon>
      <div *ngIf="!percent" class="loader" [ngStyle]="theme"></div>
      <circle-progress
        *ngIf="percent"
        [percent]="percent"
        [outerStrokeColor]="themeColor"
      ></circle-progress>
    </div>
    <!-- In case of Faild -->
    <div
      (click)="retryEvent()"
      class="resticker clickable-cursor"
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOAD_FAILED ||
        mediaStatus === messageMediaStatus.UPLOAD_FAILED
      "
    >
      <i
        class="fas"
        [class.fa-long-arrow-alt-up]="
          mediaStatus === messageMediaStatus.UPLOAD_FAILED
        "
        [class.fa-long-arrow-alt-down]="
          mediaStatus === messageMediaStatus.DOWNLOAD_FAILED
        "
        aria-hidden="true"
      >
      </i>
    </div>
  </div>
  <!-- Display media blur image in case of media exist -->
  <img *ngIf="localMedia" [src]="media" alt="" class="media-center fit" />
</div>
