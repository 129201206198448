<!-- <app-headerless-closable-frame
  (closeNotify)="cancelEvent()"
  [showCancel]="showCancel"
>
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">
        {{ title ? title : 'Error Message' }}
      </ng-container>
      <ng-container ngProjectAs="[form-body]">
        <div class="form-group">
          <div class="call" [innerHTML]="message"></div>
        </div>
        <div class="form-submit">
          <app-border-btn
            cancel
            (btnClicked)="cancelEvent()"
            *ngIf="!validationMsg"
          >
            Ok
          </app-border-btn>
          <app-border-btn
            cancel
            (btnClicked)="cancelEvent()"
            *ngIf="validationMsg"
          >
            {{ cancelTitle }}
          </app-border-btn>
          <app-border-btn
            submit
            (btnClicked)="agreeEvent()"
            *ngIf="validationMsg"
          >
            {{ validationTitle }}
          </app-border-btn>
        </div>
      </ng-container>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame> -->

<div class="popup-settings">
  <div class="popup-settings-header">
    <div class="header-text">
      <span>
        {{ title ? title : 'Error Message' }}
      </span>
    </div>
    <svg
      class="svg-icon icon-icon"
      (click)="closeEvent()"
      *ngIf="!hideclose && menus"
    >
      <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
    </svg>
    <svg
      class="svg-icon icon-icon"
      (click)="cancelEvent()"
      *ngIf="!hideclose && !menus"
    >
      <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
    </svg>
  </div>
  <div>
    <div class="settings-container">
      <div class="popup-settings-body">
        {{ message }}
      </div>
    </div>
    <div class="popup-settings-footer">
      <!-- <app-border-btn next [type]="'submit'">
      {{ buttonTitle }}
    </app-border-btn> -->

      <app-border-btn
        cancel
        (btnClicked)="cancelEvent()"
        *ngIf="!validationMsg"
      >
        OK
      </app-border-btn>
      <app-border-btn next (btnClicked)="agreeEvent()" *ngIf="validationMsg">
        {{ validationTitle }}
      </app-border-btn>
      <app-border-btn back (btnClicked)="cancelEvent()" *ngIf="validationMsg">
        {{ cancelTitle }}
      </app-border-btn>

      <!-- <button (click)="" *ngIf="validationMsg">
    }
    </button>
    <button (click)="cancelEvent()" *ngIf="validationMsg">
     
    </button> -->
    </div>
  </div>
</div>
