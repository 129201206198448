<!-- <app-popup-setting-frame
  [title]="'Write a new instant article'"
  [hideFooter]="true"
  (closeNotifier)="cancelEvent()"
> -->
<div class="edit-container">
  <!-- <div class="header">
      <i
        class="fas fa-times close clickable-cursor"
        aria-hidden="true"
        (click)="cancelEvent()"
      >
      </i>
    </div> -->
  <div class="body">
    <div class="edit-area">
      <div class="input-row">
        <span>Title</span>
        <input
          class="title-row single-line"
          contenteditable
          #titleHeader
          placeholder="Enter your article title"
          (input)="setTitle(titleHeader.value)"
        />
      </div>
      <div class="input-row">
        <span>Writer Name</span>
        <input
          class="author-row single-line"
          contenteditable
          #authorFiled
          placeholder="Enter your name"
          (input)="setAuthor(authorFiled.value)"
        />
      </div>
      <div class="input-article">
        <span>Article</span>
        <div class="article">
          <medium-editor
            [(editorModel)]="textVar"
            [editorOptions]="editorOptions"
            [editorPlaceholder]="placeholderVar"
          >
          </medium-editor>
        </div>
        <div class="image-upload clickable-cursor">
          <label for="file-input"> <i class="far fa-image"></i> </label>
          <input
            #imageInputFile
            id="file-input"
            type="file"
            accept="image/*"
            (change)="getFile()"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-border-btn submit [disabled]="canSend" (btnClicked)="saveDraft()">
      <span *ngIf="!uploading">Done </span>
      <i class="fas fa-spinner fa-spin" *ngIf="uploading"></i>
    </app-border-btn>
  </div>
</div>
<!-- </app-popup-setting-frame> -->
