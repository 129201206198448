import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { UIBillingSubRouts, UISections } from 'src/models/constants';

@Component({
  selector: 'app-abstract-expire-popup',
  templateUrl: './abstract-expire-popup.component.html',
  styleUrls: ['./abstract-expire-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractExpirePopupComponent implements OnInit {
  @Input() data: any;
  @Output() clickNotifay = new EventEmitter<boolean>();

  uiSections: typeof UISections = UISections;
  constructor(private _uiDispatchers: UIDispatchers) {}

  ngOnInit() {}

  goToBillingScreen() {
    switch (this.data.id) {
      case 1:
        this._uiDispatchers.setSection(
          this.uiSections.BILLINGS,
          UIBillingSubRouts.PLANS
        );
        break;
      case 2:
        this._uiDispatchers.setSection(
          this.uiSections.BILLINGS,
          UIBillingSubRouts.PLANS
        );
        break;
    }
  }
}
