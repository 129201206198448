import { Router, NavigationEnd } from '@angular/router';
import { GOOGLE_ANALYTICS_KEY } from 'src/models/constants';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class AnalyticsService {
    constructor(router) {
        this.router = router;
    }
    init() {
        this.listenForRouteChanges();
        try {
            const script2 = document.createElement('script');
            script2.innerHTML = `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${GOOGLE_ANALYTICS_KEY}', 'auto');  // Change the UA-ID to the one you got from Google Analytics`;
            document.getElementsByTagName('footer')[0].appendChild(script2);
        }
        catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
        }
    }
    listenForRouteChanges() {
        if (environment.production) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    ga('config', GOOGLE_ANALYTICS_KEY, {
                        page_path: event.urlAfterRedirects
                    });
                    // ga(
                    //   'config',
                    //   GOOGLE_ANALYTICS_SECOND_KEY,
                    //   {
                    //     page_path: event.urlAfterRedirects
                    //   },
                    //   'clientTracker'
                    // );
                    ga('send', 'pageview');
                    // ga('clientTracker.send', 'pageview');
                }
            });
        }
    }
    // create our event emitter to send our data to Google Analytics
    eventEmitter(eventCategory, eventAction, eventLabel = null, eventValue = null) {
        if (environment.production) {
            ga('send', 'event', {
                eventCategory,
                eventLabel,
                eventAction,
                eventValue
            });
            ga('clientTracker.send', 'event', {
                eventCategory,
                eventLabel,
                eventAction,
                eventValue
            });
        }
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.Router)); }, token: AnalyticsService, providedIn: "root" });
