<div class="main">
  <div class="page-title">
    Welcome {{ (authService?.authCollection$ | async)?.name }}
  </div>
  <div class="page-subtitle">
    How to create your own mobile app in five steps?
  </div>
  <div class="mob-view">
    <div class="demo-video d-flex jc-center ai-center">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/IeRLM9QqAGA?autoplay=1"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      >
      </iframe>
    </div>
    <div class="controlles ">
      <div class="more" (click)="continue()">
        <span> Skip & </span>
        <span class="custom">Continue ...</span>
      </div>
    </div>
  </div>
</div>
