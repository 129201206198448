import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as DriverAssignedMethods from 'src/models/DrverAssigned';
import { DriverAssignedMiddleware } from './driverAssigned.middleware';
export class DriverAssignedEffects {
    constructor(actions$, _store, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        // send update to the server after insert in store
        this.getDriverAssigned = this.actions$.pipe(ofType("GET_DRIVER_TRIPS" /* GET_DRIVER_TRIPS */), map((action) => {
            this._socketGateway.sendSocketMessage(new DriverAssignedMethods.GetDriverAssigned(action.trip_id, action.account_id, action.start_day, action.end_day));
        }));
        this.setDriverAssigned = this.actions$.pipe(ofType("SET_DRIVER_TRIPS" /* SET_DRIVER_TRIPS */), map((action) => {
            const cpToSend = DriverAssignedMiddleware.mapListToServer(action.driverAssignedList);
            this._socketGateway.sendSocketMessage(new DriverAssignedMethods.SetDriverAssigned(action.trip_id + '', action.account_id + '', cpToSend));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], DriverAssignedEffects.prototype, "getDriverAssigned", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], DriverAssignedEffects.prototype, "setDriverAssigned", void 0);
