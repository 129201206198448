<div class="main" *ngIf="(page == 2 || page == 3) && mode == 0">
  <div class="module-container" *ngIf="page == 3">
    <div class="question">
      <div class="title">What additional</div>
      <div class="title">features would you</div>
      <div class="title">like in your app?</div>
      <div class="dec-top">Or click 'Continue' if you don't want to</div>
      <div class="dec">add any additional features.</div>
      <app-startover-submit-btn
        [title]="'Continue'"
        (saveNotfiy)="addMoreItems()"
        [type]="'submit'"
      ></app-startover-submit-btn>
    </div>
    <div class="answers-add ">
      <app-startover-more-modules
        [item]="item"
        [moreTypes]="moreTypes"
        [appInfo]="appInfo"
        (checkedItemNotify)="addRemoveMoreTyes($event)"
        *ngFor="let item of filterModules"
      ></app-startover-more-modules>
    </div>
  </div>

  <!-- <div
      class="module-container reveal"
      (mouseenter)="show(true)"
      (mouseleave)="show(false)"
      *ngIf="page == 4"
    >
      <div class="question">
        <div class="title">How technical are</div>
        <div class="title">you?</div>
        <div class="dec">Choose one of the options to help</div>
        <div class="dec">us finish building your app.</div>
      </div>
      <div class="answers-tec">
        <app-startover-droplist
          [type]="'teq'"
          [showList]="showList"
          (saveNotify)="saveTeq($event)"
        ></app-startover-droplist>
      </div>
    </div> -->

  <div
    class="container reveal"
    (mouseenter)="show(true)"
    (mouseleave)="show(false)"
    [class.active]="page === 2"
    *ngIf="page === 2"
  >
    <!-- <app-startover-temps
        *ngIf="
          !appManagementService.getTestTemp() &&
          authService.getTemplatesByCategory(tempType?.type) as tmps
        "
        [category]="tempType"
        (setTempNotify)="selectTemp($event)"
      ></app-startover-temps> -->
    <app-startover-temp-view
      [temp]="currentTemp?.template"
      [category]="currentTemp?.category"
      [tempId]="currentTemp?.id"
      [choosenTemp]="currentTemp"
      [selectedCategory]="category"
      [relatedTemp]="tempsList"
      (startWith)="selectTemp($event)"
      (backNotfiy)="back()"
    >
    </app-startover-temp-view>
  </div>
</div>

<div class="main-temp" *ngIf="page == 1">
  <div
    class="container"
    (mouseenter)="show(true)"
    (mouseleave)="show(false)"
    *ngIf="page == 1"
  >
    <div class="temps-container">
      <div class="cat-list">
        <app-startover-droplist
          (saveNotify)="saveCat($event)"
          [type]="'cat'"
          [showList]="showList"
          [category]="appManagementService?.selectedTempCat$ | async"
        ></app-startover-droplist>
      </div>
      <app-startover-temps
        [category]="tempType"
        [loading]="tempLoading"
        [tempsList]="tempsList"
        (tempView)="tempView($event)"
      ></app-startover-temps>
    </div>
  </div>
</div>
<!-- <app-startover-mob-view
    *ngIf="page == 4 && (mode == 0 || mode == 1)"
    (startAgainNotify)="start()"
    (getNewAppNotify)="continue()"
    (presionlizeNotify)="branding()"
    (customizeNotify)="cutomize()"
    [userData]="userData"
    [tabContainerList]="appManagementService?.tabsContainers$ | async"
    [itemList]="appManagementService?.navMenuItems$ | async"
    [appInfo]="appManagementService?.appInfo$ | async"
    [availableApps]="appManagementService.availableApps$ | async"
    (showStartOverNotify)="showConfirmation($event)"
  ></app-startover-mob-view> -->
<app-startover-guide-video
  *ngIf="page == 10 && (mode == 0 || mode == 1)"
  [appInfo]="appManagementService.appInfo$ | async"
></app-startover-guide-video>
<!-- <app-startover-app-theme
    *ngIf="page == 8 && mode == 0"
    (continue)="continue()"
    (custome)="cutomize($event)"
  ></app-startover-app-theme> -->
<!-- <app-google-calender
    *ngIf="page == 8 && (mode == 0 || mode == 1)"
  ></app-google-calender> -->
<app-startover-menu-theme
  [appClass]="appManagementService.tabsAppClass$ | async"
  [menuApp]="appManagementService?.menusAppClass$ | async"
  [appInfo]="appInfo"
  [tabContainerList]="tabs"
  [itemList]="navItems"
  (mainMenuNotifiy)="cutomize($event)"
  [menuIdex]="menuIdex"
  [currentMenu]="currentMenu"
  [toBeEdited]="toBeEdited"
  (editNotify)="edit($event)"
  (newMenuNotify)="newMenu($event)"
  (uploadNotify)="uploadImage($event)"
  *ngIf="page == 9 && appInfo?.layout === themeStatus?.Menu"
></app-startover-menu-theme>
<!-- <div class="release" *ngIf="page == 5 && (mode == 0 || mode == 1)">
    <app-releases-app
      [appReleaseList]="appManagementService.appReleaseList$ | async"
      [showReleases]="false"
      [hideBackButton]="false"
      (backNotify)="back()"
    ></app-releases-app>
  </div>
  <div class="app-container" *ngIf="page == 7 && (mode == 0 || mode == 1)">
    <div class="mob-themes">
      <app-main-page-view
        [tabContainerList]="appManagementService.tabsContainers$ | async"
        [appClass]="appManagementService.tabsAppClass$ | async"
        [tabContainer]="appManagementService.effectiveSelectedTab$ | async"
        [mobileType]="(appManagementService.appInfo$ | async)?.mob_ver"
        [mobileTheme]="(appManagementService.appInfo$ | async)?.layout"
        [menus]="(appManagementService?.menusAppClass$ | async)?.menus"
      >
      </app-main-page-view>
      <div class="mob-div"></div>
      <app-color-confg
        class="md-screen"
        [startover]="true"
        [systemConfg]="appManagementService.systemConfig$ | async"
        (helpNotify)="helpValue($event)"
        (bubbleChangedNotify)="bubbleChanged($event)"
        [availableApps]="appManagementService.availableApps$ | async"
        [tabContainerList]="appManagementService.tabsContainers$ | async"
        [appInfo]="appManagementService?.appInfo$ | async"
        [itemList]="appManagementService?.navMenuItems$ | async"
        [hideNext]="true"
        [hideBackButton]="false"
        [mode]="(authService?.authCollection$ | async)?.mode"
        (backNotify)="back()"
        [appIconOption]="appManagementService?.appIcon$ | async"
      >
      </app-color-confg>
    </div>
  </div> -->

<!-- <app-startover-cntr-btns
    *ngIf="
      (mode == 0 &&
        page != 9 &&
        page != 10 &&
        appInfo?.layout !== themeStatus?.Menu) ||
      (mode == 1 && page == 7) ||
      (mode == 1 && page == 5)
    "
    [page]="page"

    (skpNotfiy)="skip()"
  ></app-startover-cntr-btns> -->

<app-modal-container
  *ngIf="startOverFirstValidation"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotify)="showConfirmation(false)"
>
  <app-popup-notifier
    [title]="'Warning'"
    [validationMsg]="true"
    [message]="
      'Please note that starting over will erase all your app configuration, do you want to proceed?'
    "
    (hidePopupNotify)="showConfirmation(false)"
    (agreePopupNotify)="secConfirmation(true)"
    [cancelTitle]="'No'"
    [validationTitle]="'Yes'"
  >
  </app-popup-notifier>
</app-modal-container>
<app-modal-container
  *ngIf="startOverSecValidation"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotify)="secConfirmation(false)"
>
  <app-popup-notifier
    [title]="'Warning'"
    [validationMsg]="true"
    [message]="
      'Please note that all your app customizations and features will be lost, do you want to proceed?'
    "
    (hidePopupNotify)="secConfirmation(false)"
    (agreePopupNotify)="start()"
    [cancelTitle]="'Cancel'"
    [validationTitle]="'Confirm'"
  >
  </app-popup-notifier>
</app-modal-container>
