import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { PaymentOrdersUiDispatchers } from 'src/app/store/paymentOrdersUI/paymentOrdersUI.dispatchers';
import { ServiceOrdersUiDispatchers } from 'src/app/store/serviceOrdersUI/serviceOrdersUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/paymentOrdersUI/paymentOrdersUI.dispatchers";
import * as i2 from "../../../store/serviceOrdersUI/serviceOrdersUI.dispatchers";
import * as i3 from "../../../store/root-store.selectors";
export class PaymentsService {
    constructor(_paymentOrdersUiDispatchers, _serviceOrdersUiDispatchers, _rootSelector) {
        this._paymentOrdersUiDispatchers = _paymentOrdersUiDispatchers;
        this._serviceOrdersUiDispatchers = _serviceOrdersUiDispatchers;
        this._rootSelector = _rootSelector;
        this._selectedOrderId = new BehaviorSubject(null);
        this.selectedOrderId$ = this._selectedOrderId.asObservable();
    }
    get paymentOrders$() {
        return this._rootSelector.getPaymentOrders$();
    }
    get serviceOrders$() {
        return this._rootSelector.getServiceOrders$();
    }
    selectPaymentOrderById(payment) {
        this.resetServiceOrdersPaging(payment.oid);
        this._selectedOrderId.next(payment);
    }
    resetSelectedPaymentOrder() {
        this._selectedOrderId.next(null);
    }
    /** Payment Orders */
    resetPaymentOrdersPaging() {
        this._paymentOrdersUiDispatchers.resetPaymentOrdersPaging();
    }
    getNextPaymentOrdersPage() {
        this._paymentOrdersUiDispatchers.getNextPaymentOrdersPage();
    }
    getPrevPaymentOrdersPage() {
        this._paymentOrdersUiDispatchers.getPrevPaymentOrdersPage();
    }
    /** service Orders */
    resetServiceOrdersPaging(paymentOrderID) {
        this._serviceOrdersUiDispatchers.resetServiceOrdersPaging(paymentOrderID);
    }
    getNextServiceOrdersPage(paymentOrderID) {
        this._serviceOrdersUiDispatchers.getNextServiceOrdersPage(paymentOrderID);
    }
    getPrevServiceOrdersPage(paymentOrderID) {
        this._serviceOrdersUiDispatchers.getPrevServiceOrdersPage(paymentOrderID);
    }
    createStripConnectionSubChannel() {
        this._paymentOrdersUiDispatchers.paymentStripeConnectSubChannel();
    }
    resetStripConnectionSubChannel(paymentProviderId) {
        this._paymentOrdersUiDispatchers.paymentStripeResetSubChannel(paymentProviderId);
    }
}
PaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.ɵɵinject(i1.PaymentOrdersUiDispatchers), i0.ɵɵinject(i2.ServiceOrdersUiDispatchers), i0.ɵɵinject(i3.RootStoreSelectors)); }, token: PaymentsService, providedIn: "root" });
