import { ChartsUI, ChartValues } from 'src/models/ChartUI';
import { ChartTypes } from 'src/models/summaryUI';
import * as ChartActions from './chart.actions';
import { ChartMiddleware } from './chart.middleware';

const INITIAL_STATE: ChartsUI = {
  user: {
    type: ChartTypes[0].value,
    values: [],
    empty: false
  }
};

export function chartReducer(
  state = INITIAL_STATE,
  action: ChartActions.ChartActions
): ChartsUI {
  switch (action.type) {
    case ChartActions.ChartActionTypes.RECEIVE_CHART_DATA:
      const chartValues = ChartMiddleware.getDatesInRange(
        action.values,
        new Date(action.startDate),
        new Date(action.endDate),
        action.interval
      );
      const values: ChartValues = {};
      if (1 === action.chart_type) {
        values.type = action.chart_type;
        values.values = [...chartValues];
        values.empty = action.values && action.values.length > 0 ? false : true;
        state.user = { ...values };
      }
      return { ...state };
    default:
      return state;
  }
}
