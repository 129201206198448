import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Sticky } from 'src/models/MessageParts';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';

@Component({
  selector: 'app-compose-sticky-input',
  templateUrl: './compose-sticky-input.component.html',
  styleUrls: ['./compose-sticky-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeStickyInputComponent implements OnInit {
  form: FormGroup;

  @Output() stickyInputNotify = new EventEmitter<Sticky>();
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  constructor(private _validateForm: FormValidatorsService) {}

  ngOnInit() {
    this.form = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(7)
      ]),
      description: new FormControl('', [
        Validators.minLength(1),
        Validators.maxLength(7)
      ])
    });
  }

  get title() {
    return this.form.get('title');
  }
  get description() {
    return this.form.get('description');
  }

  onSubmit() {
    if (this.form.valid) {
      this.stickyInputNotify.emit(this.form.value);
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }
}
