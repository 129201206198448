import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import {
  MAP_API_KEY,
  MessageMediaStatus,
  BUBBLE_MIN_MEDIA_SIZE,
  BUBBLE_MAX_MEDIA_SIZE
} from 'src/models/constants';

@Injectable({ providedIn: 'root' })
export class CellsService {
  currentPlayingMedia = null;

  MAP_API_KEY = MAP_API_KEY;
  GOOGLE_MAP_LINK = 'https://www.google.com.eg/maps/place/';
  MEDIA_UPLOADED = MessageMediaStatus.UPLOADED;
  MEDIA_DOWNLOADED = MessageMediaStatus.DOWNLOADED;
  GOOGLE_MAP_IMAGE_LINK =
    'https://maps.googleapis.com/maps/api/staticmap?maptype=roadmap&markers=color:blue%7Clabel:S%7C';

  constructor(private _sanitizer: DomSanitizer) {}

  resizeMedia(width: number, height: number) {
    const min = BUBBLE_MIN_MEDIA_SIZE;
    const max = BUBBLE_MAX_MEDIA_SIZE;

    const aspect = width / height;
    if (height < width) {
      if (height < min) {
        height = min;
        width = height * aspect;
      }
    } else if (width < min) {
      width = min;
      height = width / aspect;
    }

    if (height > width) {
      if (height > max) {
        height = max;
        width = height * aspect;
      }
    } else if (width > max) {
      width = max;
      height = width / aspect;
    }

    return [width, height];
  }

  getMedia(localMedia: string) {
    if (localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(localMedia);
    }
    return '';
  }
}
