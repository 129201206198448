/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snackbar-notifier.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./snackbar-notifier.component";
var styles_SnackbarNotifierComponent = [i0.styles];
var RenderType_SnackbarNotifierComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackbarNotifierComponent, data: {} });
export { RenderType_SnackbarNotifierComponent as RenderType_SnackbarNotifierComponent };
function View_SnackbarNotifierComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "snackbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "snackbar-refresh"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "refresh"], ["height", "40"], ["src", "./assets/img/refresh.svg"], ["width", "40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "snackbar-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Refresh"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You are using an older version"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "snackbar-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "snackbar-btn-refresh"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshWindowEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Refresh"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "snackbar-btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_SnackbarNotifierComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "snackbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "snackbar-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "info"], ["height", "40"], ["src", "./assets/img/info.svg"], ["width", "40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "snackbar-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "snackbar-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "snackbar-info-btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); }); }
export function View_SnackbarNotifierComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackbarNotifierComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SnackbarNotifierComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.refresh && !_co.info); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.refresh && _co.info); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SnackbarNotifierComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snackbar-notifier", [], null, null, null, View_SnackbarNotifierComponent_0, RenderType_SnackbarNotifierComponent)), i1.ɵdid(1, 245760, null, 0, i3.SnackbarNotifierComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackbarNotifierComponentNgFactory = i1.ɵccf("app-snackbar-notifier", i3.SnackbarNotifierComponent, View_SnackbarNotifierComponent_Host_0, { title: "title", message: "message", refresh: "refresh", info: "info" }, {}, []);
export { SnackbarNotifierComponentNgFactory as SnackbarNotifierComponentNgFactory };
