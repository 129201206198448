import { Store } from '@ngrx/store';
import * as ChannelAppUIActions from './channelAppUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ChannelAppUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getNextPage() {
        this._store.dispatch(new ChannelAppUIActions.GetNextPage());
    }
    getPreviousPage() {
        this._store.dispatch(new ChannelAppUIActions.GetPreviousPage());
    }
    toggleAndroidVersion(payload) {
        this._store.dispatch(new ChannelAppUIActions.ToggleAndroidVersion(payload));
    }
    toggleIosVersion(payload) {
        this._store.dispatch(new ChannelAppUIActions.ToggleIosVersion(payload));
    }
    selectTab(selectedTab) {
        this._store.dispatch(new ChannelAppUIActions.SelectTab(selectedTab));
    }
    goToSpecificPage(pageNumber) {
        this._store.dispatch(new ChannelAppUIActions.GoToSpecificPage(pageNumber));
    }
    updatePublishButtonFlag(canPublish) {
        this._store.dispatch(new ChannelAppUIActions.UpdatePublishButtonFlag(canPublish));
    }
    updateTotalAppConfFlag(totalAppConf) {
        this._store.dispatch(new ChannelAppUIActions.UpdateTotalAppConfigFlag(totalAppConf));
    }
}
ChannelAppUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChannelAppUiDispatchers_Factory() { return new ChannelAppUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: ChannelAppUiDispatchers, providedIn: "root" });
