import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { StorageService } from 'src/app/core/storage.service';
import {
  ConfigrationPravilages,
  UIBillingSubRouts,
  UISections
} from 'src/models/constants';

@Component({
  selector: 'app-abstract-upgrade-plan-frame',
  templateUrl: './abstract-upgrade-plan-frame.component.html',
  styleUrls: ['./abstract-upgrade-plan-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractUpgradePlanFrameComponent implements OnInit {
  @Input() moduleID: any;
  @Input() isMainPlanCanceld: any;
  @Input() privillage: any;
  @Output() closeNotify = new EventEmitter<boolean>(false);
  configrationPravilages = ConfigrationPravilages;

  constructor(
    public dashboardService: DashboardService,
    public localStorage: StorageService
  ) {}

  ngOnInit() {}

  get getModulePlanDetails() {
    return this.configrationPravilages.find(
      res => res.moduleName === this.moduleID
    );
  }

  get getModulePlanDetailsByPrivillage() {
    return this.configrationPravilages.find(
      res => res.privillage === this.privillage
    );
  }

  closePopup() {
    this.closeNotify.emit(true);
  }

  showSubscriptionModal() {
    this.closePopup();
    // this._subscriptionModal.openSubscriptionModal();
    if (!this.isMainPlanCanceld) {
      this.dashboardService.setSection(
        UISections.BILLINGS,
        UIBillingSubRouts.PLANS
      );
    } else {
      this.dashboardService.setSection(UISections.BILLINGS);
    }
  }
}
