import { Store } from '@ngrx/store';
import * as actions from './allGroup.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AllGroupDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveAllGroup(chatGroups) {
        this._store.dispatch(new actions.ReceiveAllGroup(chatGroups));
    }
    setLoaderStatus(status) {
        this._store.dispatch(new actions.SetLoaderStatus(status));
    }
}
AllGroupDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AllGroupDispatchers_Factory() { return new AllGroupDispatchers(i0.ɵɵinject(i1.Store)); }, token: AllGroupDispatchers, providedIn: "root" });
