import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as ChartMethods from 'src/models/ChartUI';
export class ChartEffects {
    constructor(_store, actions$, _socketGateway) {
        this._store = _store;
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.getChartData = this.actions$.pipe(ofType("GET_CHART_DATA" /* GET_CHART_DATA */), map(val => {
            const action = val;
            this._socketGateway.sendSocketMessage(new ChartMethods.GetChartData(action.chat_id, action.chart_type, action.interval, action.startDate, action.endDate));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChartEffects.prototype, "getChartData", void 0);
