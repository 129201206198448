import * as tslib_1 from "tslib";
import { ComponentDispatchers } from './component.dispatchers';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { NO_FILE_NAME, NOT_SUPPORTED_FILE_TYPE, FILE_SIZE_EXCEEDED, ERROR_NOT_IMAGE, ContainerTypes, TAB_UPLOAD_TYPE, MessageTypes, REACHED_MAX_ITEM_LIMIT, REACHED_MIN_ITEM_LIMIT, SPLASH_PAGE_UPLOAD_TYPE, ERROR_NOT_PNG_IMAGE, LOGO_WIDTH, LOGO_HEIGHT, ERROR_IMAGE_SHOULD_BE_1024X1024, FAILED_TO_UPLOAD_IMAGE, MessageMediaStatus } from 'src/models/constants';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { DownloadGateway } from 'src/app/network/gateway/download.gateway';
import { Store } from '@ngrx/store';
import { initNewComponent } from './component.middleware';
import * as isEqual from 'lodash.isequal';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
export class ComponentEffects {
    constructor(actions$, _componentDispatchers, _uiDispatchers, _fileService, _uploadGateway, _downloadGateway, _store, appManagmentService) {
        this.actions$ = actions$;
        this._componentDispatchers = _componentDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._downloadGateway = _downloadGateway;
        this._store = _store;
        this.appManagmentService = appManagmentService;
        this.addComponentRequest = this.actions$.pipe(ofType("ADD_COMPONENT_REQUEST" /* ADD_COMPONENT_REQUEST */), withLatestFrom(this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, components]) => {
            const action = val;
            let componentsOfContainer = [];
            componentsOfContainer = components.filter(container => container.parentContainerRef === action.container.ref);
            if (action.container.max_components &&
                componentsOfContainer &&
                componentsOfContainer.length >= action.container.max_components) {
                this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
            }
            else {
                const newComp = initNewComponent(action.container, componentsOfContainer);
                this._componentDispatchers.addComponent(newComp);
                // May be in the future I'll need to add items beneath the added component
            }
        }));
        this.deleteComponentRequest = this.actions$.pipe(ofType("DELETE_COMPONENT_REQUEST" /* DELETE_COMPONENT_REQUEST */), withLatestFrom(this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, components, containers]) => {
            const action = val;
            let componentsOfContainer = [];
            componentsOfContainer = components.filter(component => component.parentContainerRef === action.component.parentContainerRef);
            const parentContainer = containers.find(container => container.ref === action.component.parentContainerRef);
            if (parentContainer &&
                parentContainer.min_components &&
                componentsOfContainer &&
                componentsOfContainer.length <= parentContainer.min_components) {
                this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
            }
            else {
                this._componentDispatchers.deleteComponent(action.component);
            }
        }));
        this.downloadComponentMedia = this.actions$.pipe(ofType("DOWNLOAD_COMPONENT_MEDIA" /* DOWNLOAD_COMPONENT_MEDIA */), map((action) => {
            const localType = this._fileService.getMimeTypeFromMessageType(action.fileType);
            this._downloadGateway
                .downloadMediaFile(action.imageId, localType, false)
                .then(res => {
                this._componentDispatchers.componentMediaDownloadSuccess(res, action.component);
            })
                .catch(err => {
                console.log('Failed to download component image');
            });
        }));
        this.uploadComponentMedia = this.actions$.pipe(ofType("UPLOAD_COMPONENT_MEDIA" /* UPLOAD_COMPONENT_MEDIA */), withLatestFrom(this._store
            .select(state => state.containerReducer.filter(cont => cont.container_type === ContainerTypes.TAB))
            .pipe(distinctUntilChanged(isEqual))), map(([val, tabs]) => {
            const action = val;
            const parentTab = tabs.find(tab => tab.ref === action.component.parentContainerRef);
            this.validateFile(action.selectedFile, 'image');
            this._fileService
                .readFileAsArrayBuffer(action.selectedFile.localFile)
                .then(fileAsArrayBuffer => {
                this._uploadGateway
                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, TAB_UPLOAD_TYPE, parentTab.id, null, true)
                    .subscribe(event => {
                    if (event.type === HttpEventType.Response) {
                        const res = event.body;
                        const imageUrl = res.media.permanentUrl;
                        this._componentDispatchers.componentMediaUploadSuccess(imageUrl, imageUrl, action.component, action.isBackground);
                    }
                }, error => {
                    console.log('Failed to upload component image');
                });
            });
        }));
        this.uploadVideoBackground = this.actions$.pipe(ofType("UPLOAD_VIDEO_BACKGROUND" /* UPLOAD_VIDEO_BACKGROUND */), map((action) => {
            this._fileService
                .readFileAsArrayBuffer(action.selectedFile.localFile)
                .then(fileAsArrayBuffer => {
                this._fileService
                    .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                    .then(blobUrl => {
                    this._fileService.getImageMetadata(blobUrl).then(props => {
                        this._uploadGateway
                            .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, SPLASH_PAGE_UPLOAD_TYPE, null, null, true)
                            .subscribe(event => {
                            if (event.type === HttpEventType.Response) {
                                const res = event.body;
                                let imageUrl = '';
                                if (res.files[4]) {
                                    imageUrl = res.files[4].media.permanentUrl;
                                }
                                const imageSet = {};
                                imageSet.hdpi = res.files[0].media.permanentUrl;
                                imageSet.mdpi = res.files[1].media.permanentUrl;
                                imageSet.xhdpi = res.files[2].media.permanentUrl;
                                imageSet.xxhdpi = res.files[3].media.permanentUrl;
                                imageSet.xxxhdpi = res.files[4].media.permanentUrl;
                                imageSet.ios1x = res.files[5].media.permanentUrl;
                                imageSet.ios2x = res.files[6].media.permanentUrl;
                                imageSet.ios3x = res.files[7].media.permanentUrl;
                                if (imageUrl !== '' && action.currentPage) {
                                    const containerDetails = {};
                                    containerDetails.image_set = imageSet;
                                    containerDetails.image_url = imageUrl;
                                    this.appManagmentService._imageUrl.next(imageUrl);
                                    this.appManagmentService.updateContainer(action.componentRef, containerDetails);
                                }
                                else if (imageUrl !== '' && !action.currentPage) {
                                    const containerDetails = {};
                                    containerDetails.image_set = imageSet;
                                    containerDetails.image_url = imageUrl;
                                    this.appManagmentService._imageUrl.next(imageUrl);
                                    this.appManagmentService.updateItem(action.componentRef, containerDetails);
                                }
                            }
                        }, error => {
                            this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                        });
                    });
                });
            });
        }));
        this.uploadSplashPageLogo = this.actions$.pipe(ofType("UPLOAD_SPLASH_PAGE_LOGO" /* UPLOAD_SPLASH_PAGE_LOGO */), map((action) => {
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width !== props.height ||
                                props.width < LOGO_WIDTH ||
                                props.height < LOGO_HEIGHT) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                                const newComponent = {};
                                newComponent.uploadingSplashImage =
                                    MessageMediaStatus.UPLOAD_FAILED;
                                this.appManagmentService.updateComponent(action.componentRef, newComponent);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, SPLASH_PAGE_UPLOAD_TYPE, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const res = event.body;
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageUrl = res.files[4].media.permanentUrl;
                                        }
                                        const imageSet = {};
                                        imageSet.hdpi = res.files[0].media.permanentUrl;
                                        imageSet.mdpi = res.files[1].media.permanentUrl;
                                        imageSet.xhdpi = res.files[2].media.permanentUrl;
                                        imageSet.xxhdpi = res.files[3].media.permanentUrl;
                                        imageSet.xxxhdpi = res.files[4].media.permanentUrl;
                                        imageSet.ios1x = res.files[5].media.permanentUrl;
                                        imageSet.ios2x = res.files[6].media.permanentUrl;
                                        imageSet.ios3x = res.files[7].media.permanentUrl;
                                        this._componentDispatchers.UploadSplashPageLogoSuccess(action.componentRef, imageUrl, imageSet);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                });
                            }
                        });
                    });
                });
            }
        }));
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return;
        }
    }
    validateImage(selectedFile) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (selectedFile.localFile.type.indexOf('image/png') === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "addComponentRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "deleteComponentRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "downloadComponentMedia", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "uploadComponentMedia", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "uploadVideoBackground", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ComponentEffects.prototype, "uploadSplashPageLogo", void 0);
