import { withLatestFrom, map, distinctUntilChanged } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { Location } from '@angular/common';
import { PaymentDispatchers } from '../store/payments/payment.dispatchers';
import { RootStoreSelectors } from '../store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../store/payments/payment.dispatchers";
import * as i2 from "../store/root-store.selectors";
import * as i3 from "@angular/common";
export class SubscriptionService {
    constructor(_paymentDispatchers, _selector, location) {
        this._paymentDispatchers = _paymentDispatchers;
        this._selector = _selector;
        this.location = location;
        // _paymentDispatchers.getPaymentInfo();
    }
    get paymentStore$() {
        return this._selector.getPaymentStore$();
    }
    get paymentStatus$() {
        return this.paymentStore$.pipe(withLatestFrom(this._selector.getSelectedChat$()), map(([paymentStore, selectedChat]) => {
            return {
                currentPlan: paymentStore.payment_plan,
                currentPlanStatus: paymentStore.payment_status,
                currentPaymentFailure: paymentStore.payment_failure_message,
                isSub: selectedChat && selectedChat.subChannel
                    ? selectedChat.subChannel
                    : false
            };
        }), distinctUntilChanged(isEqual));
    }
    setCanceledPayment(plan) {
        this._paymentDispatchers.setCanceledPayment(plan);
    }
    setCanceledFreePayment(plan) {
        this._paymentDispatchers.setCanceledFreePayment(plan);
    }
    resetPaymentPlan() {
        this._paymentDispatchers.resetPaymentPlan();
    }
    setSelectedPayment(plan) {
        this._paymentDispatchers.setSelectedPayment(plan);
    }
    editSelectedPayment(plan) {
        this._paymentDispatchers.editSelectedPayment(plan);
    }
    selectFreePayment(plan) {
        this._paymentDispatchers.setSelectedFreePayment(plan);
    }
    getPaymentToken() {
        this._paymentDispatchers.getPaymentToken();
    }
}
SubscriptionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.ɵɵinject(i1.PaymentDispatchers), i0.ɵɵinject(i2.RootStoreSelectors), i0.ɵɵinject(i3.Location)); }, token: SubscriptionService, providedIn: "root" });
