import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { LinkPreviewGateway } from 'src/app/network/gateway/link-preview.gateway';
import { InstantDispatchers } from './instant.dispatchers';
import * as InstantMethods from 'src/models/InstantArticle';
import { MessageLinkPreviewStatus, PAGE_LINK } from 'src/models/constants';
export class InstantEffects {
    constructor(actions$, _socketGateway, _linkPreviewGateway, _InstantArticleDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._linkPreviewGateway = _linkPreviewGateway;
        this._InstantArticleDispatchers = _InstantArticleDispatchers;
        // call server to get chat member info after reciving message on socket
        /** Check before send request if it is alrady exist */
        this.instantArticleCreating = this.actions$.pipe(ofType("INSTANT_ARTICLE_CREATING" /* INSTANT_ARTICLE_CREATING */), map((action) => {
            /**
             * if conent exist so i will create article
             */
            if (action.payload.content) {
                this._socketGateway.sendSocketMessage(new InstantMethods.GreateArticle(action.payload.title, action.payload.author, action.payload.content, action.payload.reference));
            }
            else if (action.payload.linkPreviewUrl) {
                this.getUrlInfo(action.payload, action.payload.linkPreviewUrl);
            }
        }));
        this.instantArticleReceived = this.actions$.pipe(ofType("INSTANT_ARTICLE_RECEIVED" /* INSTANT_ARTICLE_RECEIVED */), map((action) => {
            const url = `${PAGE_LINK}/${action.payload.id}`;
            this.getUrlInfo(action.payload, url);
        }));
    }
    /**
     * Get link meta data previews ( HTTP Request )
     * @param payload (InstantArticle object)
     * @param url (string)
     */
    getUrlInfo(payload, url) {
        const msg = {};
        this._InstantArticleDispatchers.setLinkPreviewStatus(Object.assign({}, payload, { linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FETCHING }));
        this._linkPreviewGateway.getMetaFormUrl(url).subscribe(res => {
            if (res) {
                msg.reference = payload.reference;
                msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FETCHED;
                msg.linkPreviewUrl = res.linkPreviewUrl;
                msg.linkPreviewTitle = res.linkPreviewTitle;
                msg.linkPreviewDescription = res.linkPreviewDescription;
                msg.linkPreviewRootUrl = res.linkPreviewRootUrl;
                if (res.linkPreviewImage) {
                    // we don't nedd them
                    msg.linkPreviewImage = this._linkPreviewGateway.replaceHttp(res.linkPreviewImage);
                    this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
                }
                else {
                    this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
                }
            }
            else {
                this._InstantArticleDispatchers.setLinkPreviewStatus(Object.assign({}, payload, { linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FAILED }));
            }
        }, err => {
            msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FAILED;
            this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
        }, () => { });
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], InstantEffects.prototype, "instantArticleCreating", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], InstantEffects.prototype, "instantArticleReceived", void 0);
