import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appConfigHistory.actions';

import { AppState } from 'src/models/AppState';
import { AppConfigHistoryRecord } from 'src/models/ChannelAppConfig';

@Injectable({
  providedIn: 'root'
})
export class AppConfigHistoryDispatchers {
  constructor(private _store: Store<AppState>) {}

  requestAppConfigsHistory(): void {
    this._store.dispatch(new actions.RequestAppConfigsHistory());
  }

  receiveAppConfigsHistory(configHistoryList: AppConfigHistoryRecord[]): void {
    this._store.dispatch(
      new actions.ReceiveAppConfigsHistory(configHistoryList)
    );
  }

  restoreAppConfigByID(id: string): void {
    this._store.dispatch(new actions.RestoreAppConfigByID(id));
  }
}
