<div
  (click)="mediaClickedEvent()"
  class="media-container"
  [class.full-screen]="isFullScreen"
  [style.width.px]="width"
  [style.height.px]="height"
  [class.caption]="caption"
>
  <!-- loader for downloading/uploading or failed -->
  <div class="loader-container" appCancelEvent>
    <div
      class="loader-t"
      *ngIf="
        mediaStatus === messageMediaStatus.UPLOADING ||
        mediaStatus === messageMediaStatus.DOWNLOADING
      "
    >
      <svg-icon
        class="cancel"
        src="./assets/img/close-white.svg"
        [stretch]="true"
        [svgStyle]="{ 'width.px': 14, fill: '#FFF' }"
        (click)="cancelEvent()"
      >
      </svg-icon>
      <div *ngIf="!percent" class="loader" [ngStyle]="theme"></div>
      <circle-progress
        *ngIf="percent"
        [percent]="percent"
        [outerStrokeColor]="themeColor"
      >
      </circle-progress>
    </div>
    <div
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOAD_FAILED ||
        mediaStatus === messageMediaStatus.UPLOAD_FAILED
      "
      class="reprocess"
      (click)="retryEvent()"
    >
      <i
        class="fas"
        [class.fa-long-arrow-alt-up]="
          mediaStatus === messageMediaStatus.UPLOAD_FAILED
        "
        [class.fa-long-arrow-alt-down]="
          mediaStatus === messageMediaStatus.DOWNLOAD_FAILED
        "
        aria-hidden="true"
      >
      </i>
      {{ fileSize | fileSize }}
    </div>
  </div>

  <!-- Start: NotFullScreen -->
  <!-- Display thumbnail if exist -->
  <img
    *ngIf="thumbnail && !isFullScreen"
    [src]="thumbnail"
    alt=""
    class="media-center cropped img-blur"
  />

  <!-- Display gif wrapper if media exist -->
  <div *ngIf="localMedia && !isFullScreen" class="gif-wrapper media-center fit">
    <!--cover over gif -->
    <!-- <div class="cover"
      *ngIf="isPause">
      <div class="play-gif clickable-cursor"
        (click)="playGif()"
        *ngIf="mediaStatus !== messageMediaStatus.DOWNLOAD_FAILED && mediaStatus !== messageMediaStatus.UPLOAD_FAILED">
        GIF
      </div>
    </div> -->

    <!-- media -->
    <video #gifPlayer loop autoplay class="media-center fit">
      <source [src]="media" type="video/webm" />
    </video>
  </div>
  <!-- End: NotFullScrren -->

  <!-- Start: FullScreen -->
  <!-- Display thumbnail if exist and no local media -->
  <img
    *ngIf="thumbnail && !localMedia && isFullScreen"
    [src]="thumbnail"
    alt=""
    class="media img-blur"
  />
  <!-- video tag -->
  <video
    *ngIf="localMedia && isFullScreen"
    appCancelEvent
    #gifPlayer
    loop
    autoplay
    class="media"
  >
    <source [src]="media" type="video/webm" />
  </video>
  <!-- End: FullScreen -->

  <ng-content></ng-content>
</div>

<app-text-cell
  *ngIf="caption"
  class="caption-box wrap-text copyable-item text-cursor"
  dir="auto"
  [text]="caption"
  [style.width.px]="width"
>
</app-text-cell>
