import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as InstancePageActions from './instantPage.actions';
import { initRcvdPage } from '../channelMyPage/myPage.middleware';

import { AppState } from 'src/models/AppState';
import { MyPage } from 'src/models/MyPageConfig';

@Injectable({
  providedIn: 'root'
})
export class InstantPageDispatchers {
  constructor(private _store: Store<AppState>) {}

  requestInstantPageDetails(id: string): void {
    this._store.dispatch(new InstancePageActions.RequestInstantPageDetails(id));
  }

  receiveInstantPageDetails(rcvdPage: MyPage): void {
    const initializedPage: MyPage = initRcvdPage(true, rcvdPage);
    this._store.dispatch(
      new InstancePageActions.ReceiveInstantPageDetails(initializedPage)
    );
  }

  receiveInstantPages(rcvdPages: MyPage[]): void {
    this._store.dispatch(
      new InstancePageActions.ReceiveInstantPages(rcvdPages)
    );
  }
}
