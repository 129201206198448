import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppConfigHistoryActionTypes } from './appConfigHistory.actions';
import * as AppPublishingActions from './appConfigHistory.actions';
import { map } from 'rxjs/operators';
import {
  GetAppConfigsHistory,
  GetAppConfigHistoryByID
} from 'src/models/ChannelAppConfig';

@Injectable()
export class AppConfigHistoryEffects {
  @Effect({ dispatch: false })
  requestAppConfigsHistoryList = this.actions$.pipe(
    ofType(AppConfigHistoryActionTypes.REQUEST_APP_CONFIGS_HISTORY),
    map((action: AppPublishingActions.RequestAppConfigsHistory) => {
      this._socketGateway.sendSocketMessage(new GetAppConfigsHistory());
    })
  );

  @Effect({ dispatch: false })
  requestAppConfigById = this.actions$.pipe(
    ofType(AppConfigHistoryActionTypes.RESTORE_APP_CONFIG_BY_ID),
    map((action: AppPublishingActions.RestoreAppConfigByID) => {
      this._socketGateway.sendSocketMessage(
        new GetAppConfigHistoryByID(action.id)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
