import { Store } from '@ngrx/store';
import * as BookingExceptionsActions from './bookingExceptions.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BookingExceptionsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveBookingExceptions(chatId, dates) {
        this._store.dispatch(new BookingExceptionsActions.ReceiveBookingExceptions(chatId, dates));
    }
    setBookingExceptions(chatId, dates, recordIndex) {
        this._store.dispatch(new BookingExceptionsActions.SetBookingExceptions(chatId, dates, recordIndex));
    }
    deleteBookingExceptions(chatId, dates) {
        this._store.dispatch(new BookingExceptionsActions.DeleteBookingExceptions(chatId, dates));
    }
}
BookingExceptionsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingExceptionsDispatchers_Factory() { return new BookingExceptionsDispatchers(i0.ɵɵinject(i1.Store)); }, token: BookingExceptionsDispatchers, providedIn: "root" });
