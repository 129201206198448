import { Actions } from './appLocalInfo.actions';
import * as actions from './appLocalInfo.actions';
import { LocalAppInfo } from 'src/models/ChannelAppLocalConfig';

const INITIAL_STATE: LocalAppInfo = {};

export function appLocalInfoReducer(
  state: LocalAppInfo = INITIAL_STATE,
  action: Actions
): LocalAppInfo {
  switch (action.type) {
    case actions.AppLocalInfoActionTypes.UPDATE_APP_LOCAL_INFO: {
      return { ...state, ...action.localAppInfo };
    }
    case actions.AppLocalInfoActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
