import { BehaviorSubject } from 'rxjs';
import { MarketTypes, ChatTypes, mStoreMenuScreens } from 'src/models/constants';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/storeMenuUI/storeMenuUI.dispatchers";
export class StoreMenuService {
    constructor(_rootStore, _storeMenuDispatchers) {
        this._rootStore = _rootStore;
        this._storeMenuDispatchers = _storeMenuDispatchers;
        this._selectedStoreMenu = new BehaviorSubject(null);
        this.selectedStoreMenu$ = this._selectedStoreMenu.asObservable();
        this._currentStoreScreen = new BehaviorSubject(mStoreMenuScreens.mStoreMenu_LIST);
        this.currentStoreMenuScreen$ = this._currentStoreScreen.asObservable();
        this.marketTypes = MarketTypes;
        this.chatTypes = ChatTypes;
        this.confirmDeleteMenu = false;
    }
    get storeMenus$() {
        return this._rootStore.getStoreMenus$();
    }
    storeMenuById$(id) {
        return this._rootStore.getStoreMenuById$(id);
    }
    getStoreMenu(store_menu_id) {
        this._storeMenuDispatchers.getStoreMenu(store_menu_id);
    }
    resetPaging() {
        this._storeMenuDispatchers.resetStoreMenuPaging();
    }
    getProduct$(id) {
        return this._rootStore.getProductById$(id);
    }
    getBundle$(id) {
        return this._rootStore.getBundle$(id);
    }
    getPackage$(id) {
        return this._rootStore.getPackageById$(id);
    }
    updateStoreMenu(storeMenu) {
        if (storeMenu && storeMenu.id) {
            // if (storeMenu.items) {
            //   const itemsIds: StoreMenuItem[] = [];
            //   for (let i = 0; i < storeMenu.items.length; i++) {
            //     const newItem: StoreMenuItem = {};
            //     // if (store.items[i].product_id) {
            //     //   newItem.id = store.items[i].product_id;
            //     // } else {
            //     //   newItem.id = store.items[i].id;
            //     // }
            //     newItem.sub_menu = storeMenu.items[i].sub_menu;
            //     // newItem.min_item = 1;
            //     // newItem.max_item = 1;
            //     itemsIds.push(newItem);
            //   }
            //   this._storeMenuDispatchers.addStoreMenuItem(storeMenu.id, itemsIds);
            // }
            this._storeMenuDispatchers.setStoreMenu(storeMenu.id, storeMenu);
        }
        else {
            this._storeMenuDispatchers.createStoreMenu(storeMenu);
        }
        this.closeItemCardEvent();
    }
    refreshStoreMenu() {
        this._storeMenuDispatchers.refreshStoreMenu();
    }
    setStoreMenuItem(storeMenuId, items) {
        // const itemsIds = [];
        // for (let i = 0; i < items.length; i++) {
        //   const item: StoreMenuItem = {};
        //   // if (items[i].product_id) {
        //   //   item.id = items[i].product_id;
        //   // } else {
        //   //   item.id = items[i].id;
        //   // }
        //   item.sub_menu_id = items[i].id;
        //   item.max_item = 1;
        //   item.min_item = 1;
        //   itemsIds.push(item);
        // }
        this._storeMenuDispatchers.addStoreMenuItem(storeMenuId, items);
    }
    createItemEvent() {
        this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_CREATION);
        this.setSelectedStoreMenu(null);
    }
    readItemEvent(storeMenu) {
        this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_VIEW);
        this.setSelectedStoreMenu(storeMenu);
    }
    updateItemEvent(storeMenu) {
        this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_EDIT);
        this.setSelectedStoreMenu(storeMenu);
    }
    deleteMenu(id) {
        this._storeMenuDispatchers.deleteStoreMenu(id);
    }
    toggleConfirmDeleteMenu(e, menu) {
        this.confirmDeleteMenu = e;
        this.selectedMenu = menu;
    }
    closeItemCardEvent() {
        this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_LIST);
        this.setSelectedStoreMenu(null);
    }
    setSelectedStoreMenu(storeMenu) {
        this._selectedStoreMenu.next(storeMenu);
    }
    setCurrentScreen(screen) {
        this._currentStoreScreen.next(screen);
    }
    getNextPage() {
        this._storeMenuDispatchers.getNextStoreMenuPage();
    }
    getPreviousPage() {
        this._storeMenuDispatchers.getPrevStoreMenuPage();
    }
    getChannelDetails$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
}
StoreMenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreMenuService_Factory() { return new StoreMenuService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.StoreMenuUiDispatchers)); }, token: StoreMenuService, providedIn: "root" });
