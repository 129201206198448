import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-circle-input',
  templateUrl: './circle-input.component.html',
  styleUrls: ['./circle-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleInputComponent implements OnChanges {
  @Input() type = 'text';
  @Input() focused = true;
  @Output() changeEvent = new EventEmitter<any>();
  @ViewChild('circleInputId', { static: true }) circleInput: ElementRef;

  constructor() {}

  onChange(value) {
    this.changeEvent.emit(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['focused'];
    if (chng) {
      if (chng.currentValue) {
        this.circleInput.nativeElement.focus();
      } else {
        this.circleInput.nativeElement.blur();
      }
    }
  }
}
