import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CreateMyPageConfigRequest, GetMyPageConfigRequest, RemoveMyPageRequest, CreateMyPageTemplate, SetMyPageConfigRequest } from 'src/models/MyPageConfig';
import { AppClassNames, TabTypes, PageFormContainerTypes, FAILED_TO_CREATE_PAGE_TEMPLATE, ALREADY_HAS_TEMPLATE } from 'src/models/constants';
import { ItemDispatchers } from '../channelAppItems/item.dispatchers';
import { ContainerDispatchers } from '../channelAppContainers/container.dispatchers';
import { MyPageDispatchers } from './myPage.dispatchers';
import { AppConfigDispatchers } from '../channelAppConfig/appConfig.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import * as isEqual from 'lodash.isequal';
import { AuthService } from 'src/app/core/auth.service';
export class MyPageEffects {
    constructor(actions$, _socketGateway, _containerDispatchers, _itemDispatchers, _myPageDispatchers, _appConfigDispatchers, _uiDispatchers, _store, authService) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._containerDispatchers = _containerDispatchers;
        this._itemDispatchers = _itemDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._store = _store;
        this.authService = authService;
        this.createMyPageEffect = this.actions$.pipe(ofType("CREATE_MY_PAGE" /* CREATE_MY_PAGE */), map((action) => {
            this.authService._waitForTempConfig.next(true);
            this._socketGateway.sendSocketMessage(new CreateMyPageConfigRequest(action.reference, action.myPageForm, action.name, action.url, action.image, action.desc, action.templateId));
        }));
        this.createPageTemplateEffect = this.actions$.pipe(ofType("CREATE_PAGE_TEMPLATE" /* CREATE_PAGE_TEMPLATE */), map((action) => {
            // If the page has template id -- then nothing needs to be done
            if ((!action.myPage.template_id || action.myPage.template_id == '0') &&
                !(action.myPage.content && action.myPage.content.childTemplateId)) {
                const updatedPage = this.preparePageToCreateTemplate(action.myPage);
                if (updatedPage) {
                    this._socketGateway.sendSocketMessage(new CreateMyPageTemplate(action.myPage.id, updatedPage, action.name, action.image, action.desc));
                }
                else {
                    this._uiDispatchers.showPopup(FAILED_TO_CREATE_PAGE_TEMPLATE);
                }
            }
            else {
                this._uiDispatchers.showPopup(ALREADY_HAS_TEMPLATE);
            }
        }));
        this.pageTemplateCreatedEffect = this.actions$.pipe(ofType("PAGE_TEMPLATE_CREATED" /* PAGE_TEMPLATE_CREATED */), withLatestFrom(this._store
            .select(state => state.myPageReducer.filter(page => page.needUpdate))
            .pipe(distinctUntilChanged(isEqual))), map(([val, pages]) => {
            const action = val;
            const selectedPage = pages.find(page => page.id === action.pageId);
            this._socketGateway.sendSocketMessage(new SetMyPageConfigRequest(selectedPage.id, selectedPage.content, selectedPage.name, selectedPage.url, selectedPage.image, selectedPage.desc));
        }));
        this.deleteMyPageEffect = this.actions$.pipe(ofType("DELETE_MY_PAGE" /* DELETE_MY_PAGE */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveMyPageRequest(action.pageID));
        }));
        this.getDetailsOfLinkedPage = this.actions$.pipe(ofType("GET_DETAILS_OF_LINKED_PAGE" /* GET_DETAILS_OF_LINKED_PAGE */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetMyPageConfigRequest(action.pageID));
        }));
        this.myPageCreated = this.actions$.pipe(ofType("MY_PAGE_CREATED" /* MY_PAGE_CREATED */), withLatestFrom(this._store
            .select(state => state.containerReducer.filter(cont => cont.appClassName === AppClassNames.TABS &&
            cont.type === TabTypes.PAGE))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer.filter(item => item.appClassName === AppClassNames.NAV_MENU &&
            item.link === TabTypes.PAGE))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myPageReducer.filter(page => page.content && page.content.container && page.needPageID))
            .pipe(distinctUntilChanged(isEqual))), map(([val, pageTabs, pageItems, pages]) => {
            let needAutoSave = false;
            const action = val;
            const pageCont = pageTabs.find(cont => !cont.page_id && cont.page_id === action.createdPage.id);
            if (pageCont) {
                needAutoSave = true;
                this._containerDispatchers.updateContainer(pageCont.ref, {
                    page_id: action.createdPage.id,
                    url: action.createdPage.url
                });
            }
            else {
                const pageItem = pageItems.find(item => !item.page_id && item.page_id === action.createdPage.id);
                if (pageItem) {
                    needAutoSave = true;
                    this._itemDispatchers.updateItem(pageItem.ref, {
                        page_id: action.createdPage.id,
                        url: action.createdPage.url
                    });
                }
            }
            this._myPageDispatchers.receiveInitializedPage(action.createdPage);
            if (pages && pages.length > 0) {
                pages.forEach(page => {
                    page.content.container.forEach(cont => {
                        if (cont.container_type === PageFormContainerTypes.LIST &&
                            cont.component) {
                            cont.component.forEach(comp => {
                                if (!comp.form_id &&
                                    comp.template_id &&
                                    comp.page_ref &&
                                    comp.page_ref === action.createdPage.reference &&
                                    comp.template_id === action.createdPage.template_id) {
                                    needAutoSave = true;
                                    const componentUpdatedProps = {};
                                    componentUpdatedProps.form_id = action.createdPage.id;
                                    this._myPageDispatchers.updateMyPageComponent(page.id, cont.id, comp.id, componentUpdatedProps, true);
                                }
                            });
                        }
                    });
                });
            }
            if (needAutoSave) {
                setTimeout(() => this._appConfigDispatchers.setAppConfig(false), 1000);
            }
        }));
        this.myPageRcvd = this.actions$.pipe(ofType("RECEIVE_MY_PAGE" /* RECEIVE_MY_PAGE */), withLatestFrom(this._store
            .select(state => state.myPageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, pages]) => {
            const action = val;
            if (action.rcvdPage.content && action.rcvdPage.content.container) {
                const listContainers = action.rcvdPage.content.container.filter(cont => cont.container_type === PageFormContainerTypes.LIST);
                if (listContainers && listContainers.length > 0) {
                    listContainers.forEach(cont => {
                        if (cont.component) {
                            cont.component.forEach(comp => {
                                if (comp.form_id &&
                                    !pages.find(page => page.id === comp.form_id)) {
                                    this._socketGateway.sendSocketMessage(new GetMyPageConfigRequest(comp.form_id));
                                }
                                else if (!comp.form_id &&
                                    comp.template_id &&
                                    !comp.page_link_requested) {
                                    const pageRef = action.rcvdPage.id;
                                    const componentUpdatedProps = {};
                                    componentUpdatedProps.page_ref = pageRef;
                                    componentUpdatedProps.page_link_requested = 1;
                                    this._myPageDispatchers.updateMyPageComponent(action.rcvdPage.id, cont.id, comp.id, componentUpdatedProps, true);
                                    // should create page with same ref using the template id
                                    // this._myPageDispatchers.createMyPage(
                                    //   pageRef,
                                    //   null,
                                    //   null,
                                    //   null,
                                    //   null,
                                    //   null,
                                    //   comp.template_id
                                    // );
                                }
                            });
                        }
                    });
                }
            }
        }));
        this.preparePageToCreateTemplate = (myPage) => {
            let canCreateTemplate = true;
            const myPageForm = myPage.content;
            if (myPageForm && myPageForm.container) {
                const updatedContainers = myPageForm.container.map(cont => {
                    if (cont.container_type === PageFormContainerTypes.LIST &&
                        cont.component) {
                        const updatedComponents = cont.component.map(comp => {
                            if (comp.form_id || comp.page_ref) {
                                if (!comp.template_id) {
                                    canCreateTemplate = false;
                                }
                                const updatedComp = Object.assign({}, comp);
                                delete updatedComp.form_id;
                                delete updatedComp.page_ref;
                                return updatedComp;
                            }
                            return comp;
                        });
                        const updatedContainer = Object.assign({}, cont);
                        updatedContainer.component = updatedComponents;
                        return updatedContainer;
                    }
                    return cont;
                });
                const updatedPageForm = Object.assign({}, myPageForm);
                delete updatedPageForm.id;
                updatedPageForm.container = updatedContainers;
                return canCreateTemplate ? updatedPageForm : null;
            }
            else {
                if (!myPageForm) {
                    const newPage = {};
                    newPage.id = myPage.id;
                    return newPage;
                }
                else {
                    return myPageForm;
                }
            }
        };
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "createMyPageEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "createPageTemplateEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "pageTemplateCreatedEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "deleteMyPageEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "getDetailsOfLinkedPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "myPageCreated", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyPageEffects.prototype, "myPageRcvd", void 0);
