import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as CampaignUIActions from './campaignUI.actions';

import { AppState } from 'src/models/AppState';
import { ICampaign } from 'src/models/ICampaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignUIDispatchers {
  constructor(private _store: Store<AppState>) {}

  campaignListUI() {
    this._store.dispatch(new CampaignUIActions.ListCampaignUI());
  }
  createCampaignUI() {
    this._store.dispatch(new CampaignUIActions.CreateCampaignUI());
  }
  viewCampaignUI(selectedCampaign: ICampaign) {
    this._store.dispatch(
      new CampaignUIActions.ViewCampaignUI(selectedCampaign)
    );
  }
  editCampaignUI(selectedCampaign: ICampaign) {
    this._store.dispatch(
      new CampaignUIActions.EditCampaignUI(selectedCampaign)
    );
  }
  closeCreateCampaignUI() {
    this._store.dispatch(new CampaignUIActions.CloseCreateCampaignUI());
  }
  closeViewCampaignUI() {
    this._store.dispatch(new CampaignUIActions.CloseViewCampaignUI());
  }
  closeEditCampaignUI() {
    this._store.dispatch(new CampaignUIActions.CloseEditCampaignUI());
  }
  getNextCampaignPage() {
    this._store.dispatch(new CampaignUIActions.GetNextCampaignPage());
  }
  getPrevCampaignPage() {
    this._store.dispatch(new CampaignUIActions.GetPrevCampaignPage());
  }
  receivedCampaignHistoryChunk(
    eop: number,
    sop: number,
    currentPage: string[]
  ) {
    this._store.dispatch(
      new CampaignUIActions.ReceivedCampaignHistoryChunk(eop, sop, currentPage)
    );
  }
  resetCampaignPaging() {
    this._store.dispatch(new CampaignUIActions.ResetCampaignPaging());
  }
  setStatusAsIdle() {
    this._store.dispatch(new CampaignUIActions.SetStatusAsIdle());
  }
  reset() {
    this._store.dispatch(new CampaignUIActions.Reset());
  }
}
