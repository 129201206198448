import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { MenuUIDispatchers } from 'src/app/store/menusUI/menuUI.dispatchers';
import { CREATE_MY_MENU_METHOD, IS_PRODUCTION, UISections } from 'src/models/constants';
import * as uuid from 'uuid/v4';
import { UiService } from 'src/app/core/ui.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/ui.service";
import * as i2 from "../../../store/root-store.selectors";
import * as i3 from "../../../store/ui/ui.dispatchers";
import * as i4 from "../../../store/menusUI/menuUI.dispatchers";
export class MenusService {
    constructor(uiService, _rootStore, _uiDispatchers, _menuUIDispatchers) {
        this.uiService = uiService;
        this._rootStore = _rootStore;
        this._uiDispatchers = _uiDispatchers;
        this._menuUIDispatchers = _menuUIDispatchers;
        this.isProduction = IS_PRODUCTION;
    }
    selectMenu(menu) {
        this._uiDispatchers.botMenuSelected(menu);
    }
    setBotMenuEdit(menu) {
        this._uiDispatchers.botMenuSelected(menu);
    }
    getMenuWithRefId(refId) {
        return this._rootStore.getBotMenuWRefId$(refId);
    }
    setMenu(menu, responseMenuRef, mainMenu = false, click = false) {
        const newId = uuid();
        const newRef = new Date().getTime();
        if (!menu.menu_id && !menu.menu_ref) {
            menu.reference = newRef;
            menu.menu_date = newRef;
            menu.menu_id = newId;
            menu.menu_ref = newId;
        }
        if (mainMenu) {
            menu.type = 1;
        }
        else {
            menu.type = 0;
        }
        // if (responseMenuRef) {
        menu = this.setMenuResponse(menu, responseMenuRef);
        //  }
        if (menu.id) {
            this._menuUIDispatchers.updateMenu(menu);
            this.setTemplate(menu);
        }
        else {
            this._menuUIDispatchers.createMenu(menu);
        }
        if (mainMenu) {
            this.goToApp();
        }
        else if (!click) {
            this.backToMenus();
        }
    }
    setMenuResponse(menu, next_menu) {
        const rows = menu.rows.map(row => {
            return Object.assign({}, row, { buttons: row.buttons.map(btn => {
                    return Object.assign({}, btn, { next_menu });
                }) });
        });
        const menuRows = Object.assign({}, menu, { rows });
        return menuRows;
    }
    backToMenus() {
        this._uiDispatchers.setSection(UISections.MENUS);
    }
    goToApp() {
        this._uiDispatchers.setSection(UISections.APP);
    }
    getNextPage() {
        this._menuUIDispatchers.getNextMenuPage();
    }
    getPrevPage() {
        this._menuUIDispatchers.getPrevMenuPage();
    }
    resetPaging() {
        this._menuUIDispatchers.resetMenuPaging();
    }
    /** Selectors */
    get botMenus$() {
        return this._rootStore.botMenus$;
    }
    getListofMenusByType(typ) {
        this._menuUIDispatchers.listMenus(typ);
    }
    uploadMenuImgLogo(componentRef, selectedFile) {
        this._menuUIDispatchers.UploadMenuImg(componentRef, selectedFile);
    }
    setTemplate(menu) {
        const menuSend = menu;
        const id = menuSend.id.toString();
        delete menuSend['id'];
        const menutemp = {
            menu: menuSend,
            sub_menu: menuSend,
            name: menu.menu_name,
            reference: menu.menu_ref,
            next_menu: menu.next_menu,
            type: menu.type,
            method: CREATE_MY_MENU_METHOD
        };
        const messageType = 'poll';
        this.uiService.setTemplateMessage('', messageType, id, menutemp);
    }
}
MenusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenusService_Factory() { return new MenusService(i0.ɵɵinject(i1.UiService), i0.ɵɵinject(i2.RootStoreSelectors), i0.ɵɵinject(i3.UIDispatchers), i0.ɵɵinject(i4.MenuUIDispatchers)); }, token: MenusService, providedIn: "root" });
