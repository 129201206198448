/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toast-item.component";
import * as i4 from "../../../core/ui.service";
var styles_ToastItemComponent = [i0.styles];
var RenderType_ToastItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastItemComponent, data: {} });
export { RenderType_ToastItemComponent as RenderType_ToastItemComponent };
function View_ToastItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon"], ["fill", "currentColor"], ["height", "16"], ["width", "16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-error-black"]], null, null, null, null, null))], null, null); }
function View_ToastItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"], ["fill", "currentColor"], ["height", "16"], ["width", "16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-shape-done"]], null, null, null, null, null))], null, null); }
export function View_ToastItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "toast active"]], [[2, "error", null], [2, "success", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "t-icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastItemComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastItemComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "t-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "t-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, ":svg:svg", [["class", "t-close"], ["fill", "currentColor"], ["height", "16"], ["viewBox", "0 0 16 16"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.uiService == null) ? null : _co.uiService.removeToastMessage(((_co.toast == null) ? null : _co.toast.key))) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:path", [["d", "M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "t-progress-bar"], ["style", "--toast-duration:10000ms;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !((_co.toast == null) ? null : _co.toast.status); _ck(_v, 3, 0, currVal_2); var currVal_3 = ((_co.toast == null) ? null : _co.toast.status); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.toast == null) ? null : _co.toast.status); var currVal_1 = ((_co.toast == null) ? null : _co.toast.status); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = ((_co.toast == null) ? null : _co.toast.value); _ck(_v, 7, 0, currVal_4); }); }
export function View_ToastItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "labs-toast-item", [], null, null, null, View_ToastItemComponent_0, RenderType_ToastItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToastItemComponent, [i4.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastItemComponentNgFactory = i1.ɵccf("labs-toast-item", i3.ToastItemComponent, View_ToastItemComponent_Host_0, { toast: "toast" }, {}, []);
export { ToastItemComponentNgFactory as ToastItemComponentNgFactory };
