<ng-container>
  <div class="container" [class.active]="pool" [class.main]="isMainScreen">
    <svg class="svg-icon icon-icon">
      <use
        attr.xlink:href="./assets/svg/app-sections.svg#{{
          section?.symbolImage
        }}"
      ></use>
    </svg>

    <div class="section-title" [class.section]="pool">Empty!</div>
    <div class="section-desc" *ngIf="pool">
      {{ section?.emptyDesc | translate }}
    </div>
    <div
      class="section-desc"
      [class.section]="!pool"
      *ngIf="!pool"
      (click)="createNew()"
    >
      {{ section?.emptyTitle | translate }}
    </div>

    <ng-content select="[create-new-btn]"></ng-content>
  </div>
</ng-container>
