import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapDriverActions from './mapTripDriver.actions';
import { AppState } from 'src/models/AppState';
import { MapTrip } from 'src/models/MapTrip';

@Injectable({
  providedIn: 'root'
})
export class MapDriverDispatchers {
  constructor(private _store: Store<AppState>) {}

  mapDriverReceived(mapTrip: any): void {
    this._store.dispatch(new MapDriverActions.MapDriverTipsReceived(mapTrip));
  }

  getMapDriverTripById(tripId: string) {
    this._store.dispatch(new MapDriverActions.GetMapDriver(tripId));
  }

  deleteMapDriverTripById(userId: string, tripId: string) {
    this._store.dispatch(new MapDriverActions.DeleteMapDriver(userId, tripId));
  }
}
