import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-text-file-cell',
  templateUrl: './text-file-cell.component.html',
  styleUrls: ['./text-file-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFileCellComponent implements OnChanges {
  @Input()
  localMedia: string;
  @Input()
  localMessageString: string;

  isOpen = false;
  reminder = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.localMedia.currentValue) {
      const client = new XMLHttpRequest();
      client.open('GET', this.localMedia, false);
      client.onreadystatechange = () => {
        this.reminder = client.responseText.replace(
          this.localMessageString,
          ''
        );
      };
      client.send('');
    }
  }

  onClick() {
    this.isOpen = true;
    this.localMessageString += this.reminder;
  }
}
