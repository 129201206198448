<div style="min-height: 100vh; background-color: #fff;">
  <a
    class="app-logo clickable-cursor"
    href="https://nandbox.com"
    target="_blank"
    *ngIf="!localStorage?.getRecord('TOKEN')"
  >
    <img
      class="main-logo"
      src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp
"
      alt="nandbox Native App Builder logo"
      width="100%"
      height="40"
      draggable="false"
    />
    <img
      class="small-logo"
      src="https://nandbox.com/wp-content/uploads/2023/05/nandbox-logo-n.webp"
      height="40"
      draggable="false"
    />
  </a>
  <div class="startover-logo" *ngIf="localStorage?.getRecord('TOKEN')">
    <a
      class="app-logo clickable-cursor"
      href="https://nandbox.com"
      target="_blank"
    >
      <img
        src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"
        height="40"
        alt="nandbox Native App Builder logo"
        draggable="false"
      />
    </a>
  </div>

  <app-signup
    class="auth"
    [countryIso]="(authService?.authCollection$ | async)?.countryIso"
    [auth]="authService?.authCollection$ | async"
  >
  </app-signup>
</div>
