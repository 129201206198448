import { Action } from '@ngrx/store';

import { IMyProfile } from '../../../models/IMyProfile';

export const enum MyProfileActionTypes {
  MY_PROFILE_RECEIVING = 'MY_PROFILE_RECEIVING',
  MY_PROFILE_RECEIVED = 'MY_PROFILE_RECEIVED',
  MY_THUMBNAIL_DOWNLOADED = 'MY_THUMBNAIL_DOWNLOADED',
  MY_IMAGE_DOWNLOADED = 'MY_IMAGE_DOWNLOADED'
}

export class MyProfileReceiving implements Action {
  readonly type = MyProfileActionTypes.MY_PROFILE_RECEIVING;
  constructor(public payload: IMyProfile) {}
}

export class MyProfileReceived implements Action {
  readonly type = MyProfileActionTypes.MY_PROFILE_RECEIVED;
  constructor(public payload: IMyProfile) {}
}

export class MyThumbnailDownloaded implements Action {
  readonly type = MyProfileActionTypes.MY_THUMBNAIL_DOWNLOADED;
  constructor(public payload: IMyProfile) {}
}

export class MyImageDownloaded implements Action {
  readonly type = MyProfileActionTypes.MY_IMAGE_DOWNLOADED;
  constructor(public payload: IMyProfile) {}
}

export type MyProfileActions =
  | MyProfileReceiving
  | MyProfileReceived
  | MyThumbnailDownloaded
  | MyImageDownloaded;
