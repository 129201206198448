import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { BlackListPatternsUiDispatchers } from './blackListPatternsUI.dispatchers';
import * as BlackListPatternsActions from './blacklistPatternsUI.actions';
import { BlacklistPatternsUIActionTypes } from './blacklistPatternsUI.actions';

import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import {
  AddPatternToBlacklist,
  GetBlacklistPattern,
  RemovePatternFromBlacklist
} from 'src/models/BlackListsUI';

@Injectable()
export class BlackListPatternsUiEffects {
  @Effect({ dispatch: false })
  afterBlackListPatternsResetPaging = this.actions$.pipe(
    ofType(BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_RESET_PAGING),
    map((action: BlackListPatternsActions.ResetBlackListPatternsPaging) => {
      this._uiBlackListPatternsDispatchers.getNextBlacklistPatternsPage();
    })
  );
  @Effect({ dispatch: false })
  getBlacklistPatternsNextPage = this.actions$.pipe(
    ofType(BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.blackListPatternsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, blackListPatternsUiReducer]) => {
      const action = <BlackListPatternsActions.GetNextBlacklistPatternsPage>val;
      const prevRequest = blackListPatternsUiReducer.previousRequest;
      if (
        !(
          blackListPatternsUiReducer.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          blackListPatternsUiReducer.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBlacklistPattern(
            null,
            0,
            blackListPatternsUiReducer.hash,
            blackListPatternsUiReducer.eop
          )
        );
      } else {
        this._uiBlackListPatternsDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getBlacklistPatternsPrevPage = this.actions$.pipe(
    ofType(BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.blackListPatternsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, blackListPatternsUiReducer]) => {
      const action = <BlackListPatternsActions.GetPrevBlacklistPatternsPage>val;
      const prevRequest = blackListPatternsUiReducer.previousRequest;
      if (
        !(
          blackListPatternsUiReducer.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          blackListPatternsUiReducer.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBlacklistPattern(
            null,
            1,
            blackListPatternsUiReducer.hash,
            blackListPatternsUiReducer.sop
          )
        );
      } else {
        this._uiBlackListPatternsDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  deletePatternsFromBlacklist = this.actions$.pipe(
    ofType(BlacklistPatternsUIActionTypes.UI_DELETE_PATTERNS_FROM_BLACKLIST),
    map((action: BlackListPatternsActions.DeletePatternFromBlacklist) => {
      this._socketGateway.sendSocketMessage(
        new RemovePatternFromBlacklist(action.data, action.mainChatID)
      );
    })
  );

  @Effect({ dispatch: false })
  addPatternToBlacklist = this.actions$.pipe(
    ofType(BlacklistPatternsUIActionTypes.UI_ADD_BLACKLIST_PATTERNS),
    map((action: BlackListPatternsActions.AddPatternToBlacklist) => {
      this._socketGateway.sendSocketMessage(
        new AddPatternToBlacklist(
          action.data,
          action.mainChatID,
          action.reference,
          action.recordIndex
        )
      );
    })
  );
  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiBlackListPatternsDispatchers: BlackListPatternsUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
