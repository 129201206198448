import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnChanges
} from '@angular/core';

import {
  TabTypes,
  ChannelAppScreens,
  PaymentProviderIDs,
  LOGIN_TYPES
} from 'src/models/constants';
import { AppInfo, AppClass } from 'src/models/ChannelAppConfig';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { BotMenuButton, BotMenuRow, BotMenu } from 'src/models/IBotMenu';
import { distinctUntilChanged } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { AuthService } from 'src/app/core/auth.service';
import { StorageService } from 'src/app/core/storage.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { UiService } from 'src/app/core/ui.service';
import { MenusService } from 'src/app/+merchant/+dashboard/menus/menus.service';
import {
  LocalAppClass,
  LocalContainer
} from 'src/models/ChannelAppLocalConfig';

@Component({
  selector: 'app-startover-menu-theme',
  templateUrl: './startover-menu-theme.component.html',
  styleUrls: ['./startover-menu-theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverMenuThemeComponent implements OnInit, OnChanges {
  @Input() appClass: LocalAppClass;
  @Input() tabContainerList: LocalContainer[];
  @Input() itemList: any[];
  @Input() appInfo: AppInfo;
  @Input() hideBackButton = true;
  @Input() hideNext = false;
  @Output() mainMenuNotifiy = new EventEmitter<boolean>();
  @Output() editNotify = new EventEmitter<boolean>();
  @Output() newMenuNotify = new EventEmitter<any>();
  @Output() uploadNotify = new EventEmitter<boolean>();
  @Input() menuApp: AppClass;
  @Input() menuIdex: number;
  newForm = false;
  subMenu = false;
  featuresIds: string[] = [];
  appScreens = ChannelAppScreens;
  tabTypes = TabTypes;
  form: FormGroup;
  paymentProviderIDs = PaymentProviderIDs;
  loginTypes = LOGIN_TYPES;
  @Input() currentMenu: BotMenu;
  @Input() menusAppClass: AppClass;
  @Input() toBeEdited: boolean;
  constructor(
    public appManagementService: AppMgmtService,
    public uiService: UiService,
    public botMenusService: MenusService,
    private _ref: ChangeDetectorRef,
    private _fb: FormBuilder,
    public authService: AuthService,
    public _localStorage: StorageService
  ) {}

  ngOnInit() {
    if (
      this.currentMenu !== {} &&
      this.menuApp &&
      this.menuApp.menus &&
      this.menuApp.menus.length > 0
    ) {
      this.currentMenu = this.menuApp.menus[0];
      this.form = this.setFormGroup();
    } else {
      this.form = this.setFormEmptyGroup();
    }
  }
  ngOnChanges() {}
  get displayedMenu() {
    return this.currentMenu;
  }
  setFormEmptyGroup() {
    let id = null;
    if (
      this.currentMenu &&
      this.menuApp.menus &&
      this.menuApp.menus.length > 0
    ) {
      id = this.menuApp.menus[this.menuApp.menus.length - 1].menu_id;
      id = id.replace('menu', '');
      id = 'menu' + (parseInt(id) + 1);
    } else if (this.currentMenu) {
      id = 1;
      id = 'menu' + id;
    }
    return this._fb.group({
      id: [id],
      menu_id: [null],
      menu_ref: [null],
      menu_date: [null],
      menu_name: [null, [Validators.required]],
      next_menu: [null],
      rows: this.setRows(null)
    });
  }

  setRows(rows: BotMenuRow[]) {
    const botRows = [];
    if (rows && rows.length > 0) {
      for (let row = 0; row < rows.length; row++) {
        const button = rows[row];
        botRows.push(this.setRow(button, row));
      }
    }
    return this._fb.array(botRows);
  }

  setRow(row: BotMenuRow, rowIndex?: number) {
    return this._fb.group({
      row_id: [row.row_id ? row.row_id : null],
      row_order: [row.row_order ? row.row_order : null],
      buttons: this.setButtons(row.buttons, rowIndex)
    });
  }

  setButtons(cols: BotMenuButton[], rowIndex?: number) {
    const buttons = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const button = cols[col];
        buttons.push(this.setButton(button, rowIndex, col));
      }
    }
    return this._fb.array(buttons);
  }

  setButton(button: BotMenuButton, rowIndex?: number, colIndex?: number) {
    return this._fb.group({
      button_id: [button.button_id ? button.button_id : null],
      next_menu: [button.next_menu ? button.next_menu : null],
      button_next_page: [
        button.button_next_page ? button.button_next_page : null
      ],
      button_span: [button.button_span ? button.button_span : null],
      button_order: [button.button_order ? button.button_order : null],
      button_textcolor: [
        button.button_textcolor ? button.button_textcolor : null
      ],
      button_icon: [button.button_icon ? button.button_icon : null],
      button_icon_bgcolor: [
        button.button_icon_bgcolor ? button.button_icon_bgcolor : null
      ],
      button_callback: [
        button.button_id ? button.button_id : null,
        [Validators.required]
      ],
      button_bgcolor: [button.button_bgcolor ? button.button_bgcolor : null],
      button_label: [
        button.button_label ? button.button_label : null,
        [Validators.required]
      ],
      button_url: [button.button_url ? button.button_url : null],
      button_query: [button.button_query ? button.button_query : null],
      button_style: [button.button_style ? button.button_style : null],
      button_description: [
        button.button_description ? button.button_description : null
      ],
      button_img_url: [button.button_img_url ? button.button_img_url : null],
      next_menu_id: [button.next_menu_id ? button.next_menu_id : null]
    });
  }
  saveApp(event) {
    // const tabHasVideo = this.tabContainerList.find(
    //   res => res.ui_module_id === '503'
    // );
    // const navHasVideo = this.itemList.find(res => res.ui_module_id === '503');
    // if (!tabHasVideo && !navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 0;
    //   appInfo.call_enabled = 0;
    //   this.appManagementService.updateAppInfo(appInfo);
    // } else if (tabHasVideo || navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 1;
    //   appInfo.call_enabled = 1;
    //   this.appManagementService.updateAppInfo(appInfo);
    // }
    this.tabContainerList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });
    this.itemList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });
    this.featuresIds = this.addExtraModules(this.featuresIds);
    this.appManagementService.savePage(false, null, this.featuresIds);
    this.back();
  }

  back() {
    // this.backNotify.emit(true);
  }
  closeEditBotMenuFormEvent() {
    this.uiService.backFromGroupReplies();
  }
  getForm(event) {
    this.form = event;
    this.subMenu = false;
    this.newForm = false;
    this.newMenuNotify.emit(event.value);
    this.editNotify.emit(this.subMenu);
  }

  getNewForm(event) {
    this.form = event;
    this.subMenu = false;
    this.menuIdex = -1;

    this.newMenuNotify.emit(event.value);

    this.editNotify.emit(this.subMenu);
  }

  menuForm() {
    if (
      !this.form.value &&
      this.menuApp.menus &&
      this.menuApp.menus.length == 1
    ) {
      this.form = this.setFormGroup();
    }
    return this.form.value;
  }
  newMenu() {
    this.newForm = true;
    this.form = this.setFormEmptyGroup();
  }
  goToApp() {
    this.mainMenuNotifiy.emit(true);
  }

  setFormGroup() {
    return this._fb.group({
      id: [this.currentMenu.id ? this.currentMenu.id : null],
      menu_id: [this.currentMenu.menu_id ? this.currentMenu.menu_id : null],
      menu_ref: [this.currentMenu.menu_ref ? this.currentMenu.menu_ref : null],
      menu_date: [
        this.currentMenu.menu_date ? this.currentMenu.menu_date : null
      ],
      menu_name: [
        this.currentMenu.menu_name ? this.currentMenu.menu_name : null,
        [Validators.required]
      ],
      next_menu: [
        this.currentMenu.next_menu ? this.currentMenu.next_menu : null
      ],
      rows: this.setRows(this.currentMenu.rows)
    });
  }

  uploadImage(event) {
    this.uploadNotify.emit(event);
  }

  addExtraModules(featuresIds) {
    const cashModuleId = '30105';
    const payPalModuleId = '30104';
    const stripeModuleId = '30100';
    const emailModuleId = '30305';
    const noLoginModuleId = '30311';
    const mobileModuleId = '30200';
    const googleAdsModuleId = '30103';
    const appRatingModuleId = '30304';

    if (this.appInfo && this.appInfo.payment_provider) {
      this.appInfo.payment_provider.forEach(provider => {
        if (provider === this.paymentProviderIDs.CASH) {
          featuresIds.push(cashModuleId);
        } else if (provider === this.paymentProviderIDs.PAYPAL) {
          featuresIds.push(payPalModuleId);
        } else if (provider === this.paymentProviderIDs.STRIPE) {
          featuresIds.push(stripeModuleId);
        }
      });
    }

    if (this.appInfo && this.appInfo.login_type === this.loginTypes.EMAIL) {
      featuresIds.push(emailModuleId);
    }
    if (this.appInfo && this.appInfo.login_type === this.loginTypes.NO_LOGIN) {
      featuresIds.push(noLoginModuleId);
    }
    if (this.appInfo && this.appInfo.login_type === this.loginTypes.MSISDN) {
      featuresIds.push(mobileModuleId);
    }

    if (this.appInfo && this.appInfo.show_submitting_app_review) {
      featuresIds.push(appRatingModuleId);
    }

    if (
      this.appInfo &&
      this.appInfo.ads_provider === this.paymentProviderIDs.GOOGLEPROVIDER
    ) {
      featuresIds.push(googleAdsModuleId);
    }

    return featuresIds;
  }
}
