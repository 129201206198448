<ng-container
  *ngIf="(layoutService?.uiCollection$ | async)?.section as currentSection"
>
  <h1
    *ngIf="currentSection === uiSections.HOME; else sectionTitle"
    class="title"
    [innerHTML]="appInfo?.channel_name"
  ></h1>
  <ng-template #sectionTitle>
    <div class="title" [innerHTML]="getSectionTitle(currentSection)"></div>
  </ng-template>
</ng-container>
