import { Action } from '@ngrx/store';

import { InstantArticle } from 'src/models/InstantArticle';

export const enum InstantArticleActionTypes {
  INSTANT_ARTICLE_CREATING = 'INSTANT_ARTICLE_CREATING',
  INSTANT_ARTICLE_RECEIVED = 'INSTANT_ARTICLE_RECEIVED',
  SET_LINK_PREVIEW_STATUS = 'SET_LINK_PREVIEW_STATUS',
  LINK_PREVIEW_DETAILS_RECEIVED = 'LINK_PREVIEW_DETAILS_RECEIVED'
}
export const RESET = 'RESET';

export class InstantArticleCreating implements Action {
  readonly type = InstantArticleActionTypes.INSTANT_ARTICLE_CREATING;
  constructor(public payload: InstantArticle) {}
}

export class InstantArticleReceived implements Action {
  readonly type = InstantArticleActionTypes.INSTANT_ARTICLE_RECEIVED;
  constructor(public payload: InstantArticle) {}
}

export class SetLinkPreviewStatus implements Action {
  readonly type = InstantArticleActionTypes.SET_LINK_PREVIEW_STATUS;
  constructor(public payload: InstantArticle) {}
}

export class LinkPreviewDetailsReceived implements Action {
  readonly type = InstantArticleActionTypes.LINK_PREVIEW_DETAILS_RECEIVED;
  constructor(public payload: InstantArticle) {}
}

export type InstantActions =
  | InstantArticleCreating
  | InstantArticleReceived
  | SetLinkPreviewStatus
  | LinkPreviewDetailsReceived;
