/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./signup/signup.component.ngfactory";
import * as i4 from "./signup/signup.component";
import * as i5 from "../../validators/form-validators.service";
import * as i6 from "../../core/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "../../core/storage.service";
import * as i9 from "../../core/analytics.service";
import * as i10 from "../../store/channelAppConfig/appConfig.dispatchers";
import * as i11 from "../+dashboard/dashboard.service";
import * as i12 from "../../network/services/socket.service";
import * as i13 from "./registration.component";
import * as i14 from "@angular/platform-browser";
var styles_RegistrationComponent = [i0.styles];
var RenderType_RegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationComponent, data: {} });
export { RenderType_RegistrationComponent as RenderType_RegistrationComponent };
function View_RegistrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "app-logo clickable-cursor"], ["href", "https://nandbox.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "nandbox Native App Builder logo"], ["class", "main-logo"], ["draggable", "false"], ["height", "40"], ["src", "https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp\n"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "small-logo"], ["draggable", "false"], ["height", "40"], ["src", "https://nandbox.com/wp-content/uploads/2023/05/nandbox-logo-n.webp"]], null, null, null, null, null))], null, null); }
function View_RegistrationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "startover-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "app-logo clickable-cursor"], ["href", "https://nandbox.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "nandbox Native App Builder logo"], ["draggable", "false"], ["height", "40"], ["src", "https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"]], null, null, null, null, null))], null, null); }
export function View_RegistrationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "min-height: 100vh; background-color: #fff;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegistrationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegistrationComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "app-signup", [["class", "auth"]], null, null, null, i3.View_SignupComponent_0, i3.RenderType_SignupComponent)), i1.ɵdid(6, 4833280, null, 0, i4.SignupComponent, [i1.ChangeDetectorRef, i5.FormValidatorsService, i6.AuthService, i7.Router, i7.ActivatedRoute, i8.StorageService, i2.Location, i9.AnalyticsService, i10.AppConfigDispatchers, i11.DashboardService, i12.SocketService], { countryIso: [0, "countryIso"], auth: [1, "auth"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.localStorage == null) ? null : _co.localStorage.getRecord("TOKEN")); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.localStorage == null) ? null : _co.localStorage.getRecord("TOKEN")); _ck(_v, 4, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = (((tmp_2_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(((_co.authService == null) ? null : _co.authService.authCollection$)))) == null) ? null : tmp_2_0.countryIso); var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform(((_co.authService == null) ? null : _co.authService.authCollection$))); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_RegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration", [], null, null, null, View_RegistrationComponent_0, RenderType_RegistrationComponent)), i1.ɵdid(1, 245760, null, 0, i13.RegistrationComponent, [i7.ActivatedRoute, i6.AuthService, i14.Title, i7.Router, i11.DashboardService, i14.Meta, i8.StorageService, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationComponentNgFactory = i1.ɵccf("app-registration", i13.RegistrationComponent, View_RegistrationComponent_Host_0, {}, {}, []);
export { RegistrationComponentNgFactory as RegistrationComponentNgFactory };
