import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appLocalInfo.actions';

import { AppState } from 'src/models/AppState';
import { LocalAppInfo } from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class AppLocalInfoDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateAppLocalInfo(localAppInfo: LocalAppInfo): void {
    this._store.dispatch(new actions.UpdateAppLocalInfo(localAppInfo));
  }
}
