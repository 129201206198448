import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ProductsUiDispatchers } from 'src/app/store/productsUI/productsUI.dispatchers';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';
import { ProductScreens } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/productsUI/productsUI.dispatchers";
import * as i3 from "../../../store/storeMenuUI/storeMenuUI.dispatchers";
export class ProductsService {
    constructor(_rootStore, _productDispatcher, _storeMenuUiDispatchers) {
        this._rootStore = _rootStore;
        this._productDispatcher = _productDispatcher;
        this._storeMenuUiDispatchers = _storeMenuUiDispatchers;
        this._selectedProductId = null;
        this._currentProductScreen = new BehaviorSubject(ProductScreens.PRODUCT_LIST);
        this.currentProductScreen$ = this._currentProductScreen.asObservable();
        this.confirmProduct = false;
    }
    get products$() {
        return this._rootStore.getProducts$();
    }
    get bookings$() {
        return this._rootStore.getBookingList$();
    }
    get getBookingListWithProduct$() {
        return this._rootStore.getBookingListWithProduct$();
    }
    resetPaging(product_group_id, ownerGroupId) {
        this._productDispatcher.resetProductsPaging(product_group_id, ownerGroupId);
    }
    updateProduct(prod) {
        if (prod && prod.id) {
            this._productDispatcher.setProduct(prod.id, prod);
        }
        else {
            this._productDispatcher.createProduct(prod);
        }
        this.closeItemCardEvent();
    }
    addInventory(prodId, units) {
        this._productDispatcher.addInventory(prodId, units);
    }
    createItemEvent() {
        this.setCurrentScreen(ProductScreens.PRODUCT_CREATION);
        this.setSelectedProduct(null);
    }
    readItemEvent(product) {
        this.setCurrentScreen(ProductScreens.PRODUCT_VIEW);
        this.setSelectedProduct(product);
    }
    updateItemEvent(product) {
        this.setCurrentScreen(ProductScreens.PRODUCT_EDIT);
        this.setSelectedProduct(product);
    }
    closeItemCardEvent() {
        this.setCurrentScreen(ProductScreens.PRODUCT_LIST);
        this.setSelectedProduct(null);
    }
    deleteProduct(productId) {
        this._productDispatcher.deleteProduct(productId);
    }
    toggleConfirmDeleteProduct(e, product) {
        this.confirmProduct = e;
        this.selectedProduct = product;
    }
    setSelectedProduct(product) {
        if (product) {
            this._selectedProductId = product.id;
            if (product.store_menu_id) {
                this._storeMenuUiDispatchers.getStoreMenu(product.store_menu_id);
            }
        }
        else {
            this._selectedProductId = null;
        }
    }
    get selectedProduct$() {
        return this._rootStore.getProductById$(this._selectedProductId);
    }
    getProductById(prdId) {
        this._productDispatcher.getProduct(prdId, null, null);
    }
    productById$(id) {
        return id ? this._rootStore.getProductById$(id.toString()) : null;
    }
    setCurrentScreen(screen) {
        this._currentProductScreen.next(screen);
    }
    getNextPage() {
        this._productDispatcher.getNextProductsPage();
    }
    getPreviousPage() {
        this._productDispatcher.getPrevProductsPage();
    }
    getChannelDetails$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
}
ProductsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsService_Factory() { return new ProductsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ProductsUiDispatchers), i0.ɵɵinject(i3.StoreMenuUiDispatchers)); }, token: ProductsService, providedIn: "root" });
