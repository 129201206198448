<app-headerless-closable-frame (closeNotify)="closeEvent()">
  <ng-container ngProjectAs="content">
    <div class="plans-container">
      <img
        alt="nandbox logo"
        class="nandbox-logo"
        src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp
"
        alt="nandbox Native App Builder logo"
        draggable="false"
        height="30"
      />
      <div class="close-sec clickable-cursor" (click)="closeEvent()">
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-close"></use>
        </svg>
      </div>
      <div class="header">
        <div class="plans-title">
          Pricing Plans
        </div>
        <div class="plans-desc">
          Select your plan and start building your app now!
        </div>
      </div>
      <div class="plans">
        <app-subscription-plan
          *ngFor="let plan of plans"
          [planInput]="plan"
          [paymentPlan]="
            (subscriptionService.paymentStore$ | async)?.payment_plan
          "
          [isYealy]="isYearly"
          (closeNotify)="closeEvent()"
          [playStore]="payStore"
        >
        </app-subscription-plan>
      </div>
      <!-- <div class="extra-charges">
        <span class="img">
          <svg class="svg-icon icon-icon img">
            <use xlink:href="/assets/svg/app-sections.svg#icon-icon-copy"></use>
          </svg>
        </span>
        After free trial, $0.16 extra charges will apply per each new user signs
        up to your App.
      </div> -->
    </div>
  </ng-container>
</app-headerless-closable-frame>
