import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-empty-item-frame',
  templateUrl: './empty-item-frame.component.html',
  styleUrls: ['./empty-item-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyItemFrameComponent {
  @Input() emptySymbol: string;
  @Input() emptyTitle: string;
  @Input() emptyDesc: string;
  @Input() noBg: boolean;
}
