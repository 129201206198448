<div
  [class.form-group]="!isInline"
  [class.form-inline]="isInline"
  [class.space-between]="usingCustom"
>
  <label
    *ngIf="!icon"
    [for]="fieldId"
    class="control-label"
    [class.md]="isInline"
    style="display: flex; align-items: center;"
  >
    {{ filedLabel }}

    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    ></app-hover-tooltip-icon>
  </label>
  <div class="form-group lg-input" [class.customize]="usingCustom">
    <ng-container *ngIf="icon">
      <div class="input-group">
        <span class="input-group-addon" [class.oval-addon]="ovalShaped">
          <img [src]="icon" [alt]="filedLabel" />
        </span>

        <input
          class="form-control"
          [class.oval-input]="ovalShaped"
          [id]="fieldId"
          [formControl]="control"
          [type]="filedType"
          [placeholder]="placeHolder || filedLabel"
          [readonly]="readOnly"
          [autocomplete]="autocomplete ? 'new-password' : null"
          style="background-color: #fff;"
          [class.touched]="touched"
          [maxlength]="maxlength"
          [class.cursor-not-alow]="notAllowCursor"
          min="0"
          onkeypress="return event.charCode >= 48"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="!icon">
      <input
        *ngIf="!usingPositiveNumber"
        class="form-control"
        [class.line-input]="lineShaped"
        [id]="fieldId"
        [class.oval-input-no-icon]="ovalShaped"
        [formControl]="control"
        [type]="filedType"
        [placeholder]="placeHolder || filedLabel"
        [readonly]="readOnly"
        autocomplete="new-password"
        style="background-color: #fff;"
        [class.touched]="touched"
        [maxlength]="maxlength"
        [class.cursor-not-alow]="notAllowCursor"
        [min]="min"
      />
      <input
        *ngIf="usingPositiveNumber"
        class="form-control"
        [class.line-input]="lineShaped"
        [id]="fieldId"
        [class.oval-input-no-icon]="ovalShaped"
        [formControl]="control"
        [type]="filedType"
        [placeholder]="placeHolder || filedLabel"
        [readonly]="readOnly"
        autocomplete="new-password"
        style="background-color: #fff;"
        [class.touched]="touched"
        [maxlength]="maxlength"
        [class.cursor-not-alow]="notAllowCursor"
        [min]="min"
        onkeypress="return event.charCode >= 48"
      />
    </ng-container>

    <ng-container *ngIf="showError">
      <ng-container *ngIf="validationErrors$ | async as errors">
        <app-validation-errors
          [fieldErrors]="errors"
          [fieldName]="filedLabel"
          [fieldType]="filedType"
        >
        </app-validation-errors>
      </ng-container>
    </ng-container>
  </div>
</div>
