import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { COLLECTIONS } from 'src/models/AppSections';
import { Collection } from 'src/models/collectionsUI';
import { CollectionsService } from '../../collections/collections.service';

@Component({
  selector: 'app-collections-pool',
  templateUrl: './collections-pool.component.html',
  styleUrls: ['./collections-pool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsPoolComponent implements OnInit {
  collectionSec = COLLECTIONS;

  currentSelectedCollections: Collection[] = [];
  currentSelectedCollection: Collection;
  @Input() selectedCollections: Collection[] = [];
  @Input() selectedCollection: Collection;
  @Input() isSingle = false;

  @Output() selectCollectionsNotfiy = new EventEmitter<Collection[]>();
  @Output() selectSingleCollectionNotfiy = new EventEmitter<Collection>();
  @Output() closeNotify = new EventEmitter<boolean>();

  constructor(public collectionsService: CollectionsService) {}

  ngOnInit() {
    this.collectionsService.resetPaging();
    this.currentSelectedCollections = [...this.selectedCollections];
    this.currentSelectedCollection = { ...this.selectedCollection };
  }

  setSelectMessage(colllection: Collection) {
    if (!this.isSingle) {
      this.currentSelectedCollections.push(colllection);
    } else {
      this.currentSelectedCollection = { ...colllection };
    }
  }

  resetSelectMessage(product: Collection) {
    this.currentSelectedCollections = this.currentSelectedCollections.filter(
      pro => pro.id !== product.id
    );
  }

  isCollectionSelected(id: number) {
    if (!this.isSingle) {
      return (
        this.currentSelectedCollections.filter(res => res.id === id).length > 0
      );
    } else {
      return this.currentSelectedCollection.id === id;
    }
  }

  insertEvent() {
    if (!this.isSingle) {
      this.selectCollectionsNotfiy.emit(this.currentSelectedCollections);
    } else {
      this.selectSingleCollectionNotfiy.emit(this.currentSelectedCollection);
    }
  }

  closeEvent() {
    this.closeNotify.emit(true);
  }
}
