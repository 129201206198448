import { Store } from '@ngrx/store';
import * as MyProfileActions from './myProfile.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MyProfileDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    myProfileReceiving(payload) {
        this._store.dispatch(new MyProfileActions.MyProfileReceiving(payload));
    }
    myProfileReceived(payload) {
        this._store.dispatch(new MyProfileActions.MyProfileReceived(payload));
    }
    myThumbnailDownloaded(payload) {
        this._store.dispatch(new MyProfileActions.MyThumbnailDownloaded(payload));
    }
    myImageDownloaded(payload) {
        this._store.dispatch(new MyProfileActions.MyImageDownloaded(payload));
    }
}
MyProfileDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyProfileDispatchers_Factory() { return new MyProfileDispatchers(i0.ɵɵinject(i1.Store)); }, token: MyProfileDispatchers, providedIn: "root" });
