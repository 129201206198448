import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as BulkActions from './bulk.actions';

import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class BulkDispatchers {
  constructor(private _store: Store<AppState>) {}

  recordCreated(index: number, recordType: string, id?: string, ref?: string) {
    this._store.dispatch(
      new BulkActions.RecordCreated(index, recordType, id, ref)
    );
  }
}
