/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-guide-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./startover-guide-video.component";
import * as i4 from "../../../core/auth.service";
import * as i5 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
var styles_StartoverGuideVideoComponent = [i0.styles];
var RenderType_StartoverGuideVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverGuideVideoComponent, data: {} });
export { RenderType_StartoverGuideVideoComponent as RenderType_StartoverGuideVideoComponent };
export function View_StartoverGuideVideoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Welcome ", " "])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "page-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How to create your own mobile app in five steps? "])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "mob-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "demo-video d-flex jc-center ai-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "iframe", [["allow", "autoplay; encrypted-media"], ["allowfullscreen", ""], ["frameborder", "0"], ["height", "315"], ["src", "https://www.youtube.com/embed/IeRLM9QqAGA?autoplay=1"], ["width", "560"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "controlles "]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Skip & "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "custom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue ..."]))], null, function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(((_co.authService == null) ? null : _co.authService.authCollection$)))) == null) ? null : tmp_0_0.name); _ck(_v, 2, 0, currVal_0); }); }
export function View_StartoverGuideVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-guide-video", [], null, null, null, View_StartoverGuideVideoComponent_0, RenderType_StartoverGuideVideoComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartoverGuideVideoComponent, [i4.AuthService, i5.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverGuideVideoComponentNgFactory = i1.ɵccf("app-startover-guide-video", i3.StartoverGuideVideoComponent, View_StartoverGuideVideoComponent_Host_0, { appInfo: "appInfo" }, {}, []);
export { StartoverGuideVideoComponentNgFactory as StartoverGuideVideoComponentNgFactory };
