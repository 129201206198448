<div class="questions">
  <app-startover-logo
    (saveNotify)="tempList.saveCat($event)"
    [page]="1"
    [mode]="0"
  ></app-startover-logo>

  <app-startover-questions
    #tempList
    [mode]="0"
    [page]="1"
    [appMenu]="appManagementService.menusAppClass$ | async"
    [component]="appManagementService.navMenuComponent$ | async"
    (customizeNotify)="cutomize()"
    [tabs]="appManagementService?.tabsContainers$ | async"
    [navItems]="appManagementService?.navMenuItems$ | async"
    (startOverCurrentPageNotify)="setStartOverPage($event)"
    [page]="(authService.authCollection$ | async)?.progressWeb"
    [appInfo]="appManagementService.appInfo$ | async"
    [menuIdex]="menuIdex"
    [currentMenu]="currentMenu"
    [toBeEdited]="toBeEdited"
    (editNotify)="edit($event)"
    (newMenuNotify)="menuToEdit({})"
    (uploadNotify)="uploadImage($event)"
  ></app-startover-questions>
</div>
