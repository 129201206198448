import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth.service';
import { slideToLeft } from 'src/app/app-animations';
import {
  AuthStatus,
  SIGNUP_LINK,
  PRIVACY_LINK,
  TERMS_LINK
} from 'src/models/constants';
import { StorageService } from 'src/app/core/storage.service';
import { SocketService } from './../../../network/services/socket.service';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
  animations: [slideToLeft],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanComponent implements OnInit {
  email = 'email';
  qrSize: number;
  isPasswordForgot = false;
  remControl: FormControl;
  authStatus: typeof AuthStatus = AuthStatus;

  helpVideoVisibility = false;

  signupLink = SIGNUP_LINK;
  privacyLink = PRIVACY_LINK;
  termsLink = TERMS_LINK;

  auth2: any;

  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

  constructor(
    public appService: AppService,
    public authService: AuthService,
    public localStorage: StorageService,
    public _ref: ChangeDetectorRef,
    public socketService: SocketService
  ) {
    if (this.localStorage.getRecord('loginType') === 'qr') {
      this.email = this.localStorage.getRecord('loginType');
    } else {
      this.email = 'email';
    }
  }

  ngOnInit() {
    if (this.localStorage.getRecord('loginType') === 'qr') {
      this.authService.requestQRcode();
    }
    this.remControl = new FormControl(this.authService.authRem);
    this.remControl.valueChanges.subscribe(res => this.selectRemEvent(res));
    // setTimeout(() => this.googleAuthSDK(), 1000);
    const windowSize = window.innerWidth;
    if (windowSize <= 400) {
      this.qrSize = 150;
    } else if (windowSize >= 401) {
      this.qrSize = 200;
    }
  }
  selectRemEvent(res: boolean) {
    this.authService.setRem(res);
  }

  logoutEvent() {
    this.authService.logout();
  }

  goEmail() {
    this.email = 'email';
    this.localStorage.setRecord('loginType', 'email');
    this.authService.loginUseEmail();
    this._ref.detectChanges();
  }
  loginQr() {
    this.localStorage.setRecord('loginType', 'qr');
    this.email = 'qr';
    this.authService.requestQRcode();
    this._ref.detectChanges();
  }
  qrCodeReload() {
    // this.authService.logout();
    this.authService.requestNewQRcode();
  }

  showNeedHelp(b: boolean) {
    // this.helpVideoVisibility = b;
    window.open('https://docs.nandbox.com/docs/registered-users');
  }

  openLink(link) {
    window.open(link);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const windowSize = window.innerWidth;
    if (windowSize <= 400) {
      this.qrSize = 150;
    } else if (windowSize >= 401) {
      this.qrSize = 200;
    }
  }
}
