import { Action } from '@ngrx/store';

import { BotMenu } from 'src/models/IBotMenu';
import { IMessage } from 'src/models/IMessage';
import { MainChat } from 'src/models/MainChat';
import { MapMarker } from 'src/models/MapMarker';

export const enum UIActionTypes {
  UI_MESSAGE_SELECTED_FOR_STATS = 'UI_MESSAGE_SELECTED_FOR_STATS',
  UI_CHAT_SELECTED = 'UI_CHAT_SELECTED',
  UI_MESSAGE_SELECTED = 'UI_MESSAGE_SELECTED',
  UI_USER_SELECTED = 'UI_USER_SELECTED',
  UI_SUB_USER_SELECTED = 'UI_SUB_USER_SELECTED',
  UI_SUB_USER_DESELECTED = 'UI_SUB_USER_DESELECTED',
  UI_GROUP_REPLIES_SELECTED = 'UI_GROUP_REPLIES_SELECTED',
  UI_BACK_FROM_GROUP_REPLIES = 'UI_BACK_FROM_GROUP_REPLIES',
  UI_LIST_SELECTED = 'UI_LIST_SELECTED',
  UI_SELECT_ALL_CHANNELS = 'UI_SELECT_ALL_CHANNELS',
  UI_SELECT_ONE_TO_ONE_REPLY = 'UI_SELECT_ONE_TO_ONE_REPLY',
  UI_SELECT_REPLY_TO_ADMIN = 'UI_SELECT_REPLY_TO_ADMIN',
  UI_SELECT_TALK_TO_ADMIN = 'UI_SELECT_TALK_TO_ADMIN',
  UI_BACK_FROM_ONE_TO_ONE_REPLY = 'UI_BACK_FROM_ONE_TO_ONE_REPLY',
  UI_SHOW_POPUP = 'UI_SHOW_POPUP',
  UI_HIDE_POPUP = 'UI_HIDE_POPUP',
  UI_SHOW_PREVIEW = 'UI_SHOW_PREVIEW',
  UI_HIDE_PREVIEW = 'UI_HIDE_PREVIEW',
  UI_SHOW_DRAG_DROP_SCREEN = 'UI_SHOW_DRAG_DROP_SCREEN',
  UI_HIDE_DRAG_DROP_SCREEN = 'UI_HIDE_DRAG_DROP_SCREEN',
  UI_SET_CHATS_LIST_PAGE_NUMBER = 'UI_SET_CHATS_LIST_PAGE_NUMBER',
  UI_SET_CHANNELS_LIST_PAGE_NUMBER = 'UI_SET_CHANNELS_LIST_PAGE_NUMBER',
  UI_SET_MESSAGES_PAGE_NUMBER = 'UI_SET_MESSAGES_PAGE_NUMBER',
  UI_SET_SUB_GROUP_MSGS_PAGE_NUMBER = 'UI_SET_SUB_GROUP_MSGS_PAGE_NUMBER',
  UI_SET_POSTS_PAGE_NUMBER = 'UI_SET_POSTS_PAGE_NUMBER',
  UI_SET_SUB_POSTS_PAGE_NUMBER = 'UI_SET_SUB_POSTS_PAGE_NUMBER',
  UI_SET_POST_REPLIES_PAGE_NUMBER = 'UI_SET_POST_REPLIES_PAGE_NUMBER',
  UI_SET_SUB_POST_REPLIES_PAGE_NUMBER = 'UI_SET_SUB_POST_REPLIES_PAGE_NUMBER',
  UI_SET_SEARCH_KEY_WORD = 'UI_SET_SEARCH_KEY_WORD',
  UI_SET_CONTACTS_COUNT = 'UI_SET_CONTACTS_COUNT',
  UI_SET_GROUPS_COUNT = 'UI_SET_GROUPS_COUNT',
  UI_DETAILS_RECEIVED = 'UI_DETAILS_RECEIVED',
  UI_HAS_NO_CONTACTS = 'UI_HAS_NO_CONTACTS',
  UI_SHOW_DASHBOARD = 'UI_SHOW_DASHBOARD',
  UI_EVALUATE_SHOW_DASHBOARD = 'UI_EVALUATE_SHOW_DASHBOARD',
  UI_SHOW_MEDIA_SCREEN = 'UI_SHOW_MEDIA_SCREEN',
  UI_HIDE_MEDIA_SCREEN = 'UI_HIDE_MEDIA_SCREEN',
  UI_SELECT_CHANNEL_POST = 'UI_SELECT_CHANNEL_POST',
  UI_SELECT_SUB_CHANNEL_POST = 'UI_SELECT_SUB_CHANNEL_POST',

  /* Merchant Web App */
  UI_SET_CLICKED_MESSAGE = 'UI_SET_CLICKED_MESSAGE',
  UI_SET_SECTION = 'UI_SET_SECTION',
  UI_SET_PAGE_NUMBER = 'UI_SET_PAGE_NUMBER',
  UI_DATE_SELECTED = 'UI_DATE_SELECTED',
  UI_BOT_MENU_SELECTED = 'UI_BOT_MENU_SELECTED',
  UI_SET_PRIVILEGE = 'UI_SET_PRIVILEGE',
  UI_SET_PRIVILEGE_MODULES = 'UI_SET_PRIVILEGE_MODULES',
  UI_SET_CHAT_LABELS = 'UI_SET_CHAT_LABELS',
  UI_SAVE_CHAT_LABELS = 'UI_SAVE_CHAT_LABELS',
  UI_GET_CHAT_LABELS = 'UI_GET_CHAT_LABELS',
  UI_SUB_CHAT_SELECTED = 'UI_SUB_CHAT_SELECTED',
  UI_SUB_CHAT_DESELECTED = 'UI_SUB_CHAT_DESELECTED',
  UI_SUB_PARENT_MESSAGE_SELECTED = 'UI_SUB_PARENT_MESSAGE_SELECTED',
  UI_BACK_FROM_SUB_GROUP_REPLIES = 'UI_BACK_FROM_SUB_GROUP_REPLIES',
  UI_SET_SIDEMENU_STATUS = 'UI_SET_SIDEMENU_STATUS',
  UI_MAP_MARKER_SELECTED = 'UI_MAP_MARKER_SELECTED',
  UI_SHOW_ERROR_POPUP = 'UI_SHOW_ERROR_POPUP',
  UI_HIDE_ERROR_POPUP = 'UI_HIDE_ERROR_POPUP',
  UI_TOGGLE_HELP_WINDOW = 'UI_TOGGLE_HELP_WINDOW',
  UI_TOGGLE_POPUP_UPGRADE_PLAN_MODULE = 'UI_TOGGLE_POPUP_UPGRADE_PLAN_MODULE',
  ADD_TOAST_MESSAGE_UI = 'ADD_TOAST_MESSAGE_UI',
  REMOVE_TOAST_MESSAGE_UI = 'REMOVE_TOAST_MESSAGE_UI',
  TOGGLE_EXPIRED_POPUP_UI = 'TOGGLE_EXPIRED_POPUP_UI',
  RECEIVE_CONF_MODULES_UI = 'RECEIVE_CONF_MODULES_UI',
  RECEIVE_MEDIA_GALLARY_UI = 'RECEIVE_MEDIA_GALLARY_UI',
  UI_SET_CHAT_TO_TEMPLATE = 'UI_SET_CHAT_TO_TEMPLATE',
  UI_SET_TEMPLATE_MESSAGE = 'UI_SET_TEMPLATE_MESSAGE',
  UI_ENABLE_SET_TEMPLATE_BTN = 'UI_ENABLE_SET_TEMPLATE_BTN'
}

/* Reset */
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class ChatSelected implements Action {
  readonly type = UIActionTypes.UI_CHAT_SELECTED;
  constructor(public payload: MainChat) {}
}

export class SubChatSelected implements Action {
  readonly type = UIActionTypes.UI_SUB_CHAT_SELECTED;
  constructor(public payload: MainChat) {}
}

export class SubChatDeselected implements Action {
  readonly type = UIActionTypes.UI_SUB_CHAT_DESELECTED;
}

export class MessageSelectedForStat implements Action {
  readonly type = UIActionTypes.UI_MESSAGE_SELECTED_FOR_STATS;
  constructor(public payload: IMessage) {}
}

export class MessageSelected implements Action {
  readonly type = UIActionTypes.UI_MESSAGE_SELECTED;
  constructor(public payload: IMessage) {}
}

export class SubParentMessageSelected implements Action {
  readonly type = UIActionTypes.UI_SUB_PARENT_MESSAGE_SELECTED;
  constructor(public payload: IMessage) {}
}

export class UserSelected implements Action {
  readonly type = UIActionTypes.UI_USER_SELECTED;
  constructor(public payload: string) {}
}

export class SubUserSelected implements Action {
  readonly type = UIActionTypes.UI_SUB_USER_SELECTED;
  constructor(public userID: string) {}
}

export class SubUserDeselected implements Action {
  readonly type = UIActionTypes.UI_SUB_USER_DESELECTED;
  constructor() {}
}

export class GroupRepliesSelected implements Action {
  readonly type = UIActionTypes.UI_GROUP_REPLIES_SELECTED;
  constructor() {}
}

export class BackFromGroupReplies implements Action {
  readonly type = UIActionTypes.UI_BACK_FROM_GROUP_REPLIES;
  constructor() {}
}

export class BackFromSubGroupReplies implements Action {
  readonly type = UIActionTypes.UI_BACK_FROM_SUB_GROUP_REPLIES;
  constructor() {}
}

export class ListSelected implements Action {
  readonly type = UIActionTypes.UI_LIST_SELECTED;
  constructor(public payload: string) {}
}

export class SelectAllChannels implements Action {
  readonly type = UIActionTypes.UI_SELECT_ALL_CHANNELS;
  constructor() {}
}

export class SelectOneToOneReply implements Action {
  readonly type = UIActionTypes.UI_SELECT_ONE_TO_ONE_REPLY;
  constructor(public payload: string) {}
}

export class SelectTalkToAdmin implements Action {
  readonly type = UIActionTypes.UI_SELECT_TALK_TO_ADMIN;
  constructor() {}
}
export class ToggleHelpWindow implements Action {
  readonly type = UIActionTypes.UI_TOGGLE_HELP_WINDOW;
  constructor(public status: boolean) {}
}

export class SelectReplyToAdmin implements Action {
  readonly type = UIActionTypes.UI_SELECT_REPLY_TO_ADMIN;
  constructor() {}
}
export class BackFromOneToOneReply implements Action {
  readonly type = UIActionTypes.UI_BACK_FROM_ONE_TO_ONE_REPLY;
  constructor() {}
}

export class ShowPopup implements Action {
  readonly type = UIActionTypes.UI_SHOW_POPUP;
  constructor(public payload: string, public title?: string) {}
}

export class ShowErrorPopup implements Action {
  readonly type = UIActionTypes.UI_SHOW_ERROR_POPUP;
  constructor(public popupErrorMessage: string) {}
}

export class HidePopup implements Action {
  readonly type = UIActionTypes.UI_HIDE_POPUP;
  constructor() {}
}

export class HideErrorSnackbar implements Action {
  readonly type = UIActionTypes.UI_HIDE_ERROR_POPUP;
  constructor() {}
}

export class ShowPreview implements Action {
  readonly type = UIActionTypes.UI_SHOW_PREVIEW;
  constructor() {}
}

export class HidePreview implements Action {
  readonly type = UIActionTypes.UI_HIDE_PREVIEW;
  constructor() {}
}

export class ShowDragDropScreen implements Action {
  readonly type = UIActionTypes.UI_SHOW_DRAG_DROP_SCREEN;
  constructor() {}
}

export class HideDragDropScreen implements Action {
  readonly type = UIActionTypes.UI_HIDE_DRAG_DROP_SCREEN;
  constructor() {}
}

export class SetChannelsListPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_CHANNELS_LIST_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetChatsListPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_CHATS_LIST_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetMessagesPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_MESSAGES_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetSubGroupMessagesPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_SUB_GROUP_MSGS_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetPostsPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_POSTS_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetSubPostsPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_SUB_POSTS_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetPostRepliesPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_POST_REPLIES_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetSubPostRepliesPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_SUB_POST_REPLIES_PAGE_NUMBER;
  constructor(public payload: number) {}
}

export class SetSearchKeyWord implements Action {
  readonly type = UIActionTypes.UI_SET_SEARCH_KEY_WORD;
  constructor(public payload: string) {}
}

export class SetContactsCount implements Action {
  readonly type = UIActionTypes.UI_SET_CONTACTS_COUNT;
  constructor(public payload: number) {}
}

export class SetGroupsCount implements Action {
  readonly type = UIActionTypes.UI_SET_GROUPS_COUNT;
  constructor(public payload: number) {}
}

export class DetailsReceived implements Action {
  readonly type = UIActionTypes.UI_DETAILS_RECEIVED;
}

export class SetHasNoContacts implements Action {
  readonly type = UIActionTypes.UI_HAS_NO_CONTACTS;
}

export class EvaluateShowDashboard implements Action {
  readonly type = UIActionTypes.UI_EVALUATE_SHOW_DASHBOARD;
}

export class ShowDashboard implements Action {
  readonly type = UIActionTypes.UI_SHOW_DASHBOARD;
}

export class ShowMediaScreen implements Action {
  readonly type = UIActionTypes.UI_SHOW_MEDIA_SCREEN;
  constructor(public payload: IMessage) {}
}

export class HideMediaScreen implements Action {
  readonly type = UIActionTypes.UI_HIDE_MEDIA_SCREEN;
  constructor() {}
}

export class SelectChannelPost implements Action {
  readonly type = UIActionTypes.UI_SELECT_CHANNEL_POST;
  constructor(public payload: IMessage) {}
}

export class SelectSubChannelPost implements Action {
  readonly type = UIActionTypes.UI_SELECT_SUB_CHANNEL_POST;
  constructor(public payload: IMessage) {}
}

export class SetClickedMessage implements Action {
  readonly type = UIActionTypes.UI_SET_CLICKED_MESSAGE;
  constructor(public payload: IMessage) {}
}
export class SetSection implements Action {
  readonly type = UIActionTypes.UI_SET_SECTION;
  constructor(public payload: string, public subSection?: string) {}
}

export class SetPageNumber implements Action {
  readonly type = UIActionTypes.UI_SET_PAGE_NUMBER;
  constructor(public payload: string) {}
}

export class DateSelected implements Action {
  readonly type = UIActionTypes.UI_DATE_SELECTED;
  constructor(public payload: Date) {}
}

export class BotMenuSelected implements Action {
  readonly type = UIActionTypes.UI_BOT_MENU_SELECTED;
  constructor(public payload: BotMenu) {}
}
export class SetAuthPrivilege implements Action {
  readonly type = UIActionTypes.UI_SET_PRIVILEGE;
  constructor(public payload: string[]) {}
}
export class SetAuthPrivilegeModules implements Action {
  readonly type = UIActionTypes.UI_SET_PRIVILEGE_MODULES;
  constructor(public payload: string[]) {}
}

export class SetChatLabels implements Action {
  readonly type = UIActionTypes.UI_SET_CHAT_LABELS;
  constructor(public payload) {}
}

export class GetChatLabels implements Action {
  readonly type = UIActionTypes.UI_GET_CHAT_LABELS;
  constructor() {}
}

export class SaveChatLabels implements Action {
  readonly type = UIActionTypes.UI_SAVE_CHAT_LABELS;
  constructor(public payload: any) {}
}
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}
export class SetSideMenuStatus implements Action {
  readonly type = UIActionTypes.UI_SET_SIDEMENU_STATUS;
  constructor(public status: number) {}
}
export class MapMarkerSelected implements Action {
  readonly type = UIActionTypes.UI_MAP_MARKER_SELECTED;
  constructor(public payload: MapMarker) {}
}
export class TogglePopupUpgradePlanModule implements Action {
  readonly type = UIActionTypes.UI_TOGGLE_POPUP_UPGRADE_PLAN_MODULE;
  constructor(public moduleID) {}
}
export class AddToastMessage implements Action {
  readonly type = UIActionTypes.ADD_TOAST_MESSAGE_UI;
  constructor(public msg: string, public status?: boolean) {}
}
export class RemoveToastMessage implements Action {
  readonly type = UIActionTypes.REMOVE_TOAST_MESSAGE_UI;
  constructor(public index: number) {}
}

export class ToggleExpiredPopup implements Action {
  readonly type = UIActionTypes.TOGGLE_EXPIRED_POPUP_UI;
  constructor(public status: any) {}
}
export class ReceiveConfModules implements Action {
  readonly type = UIActionTypes.RECEIVE_CONF_MODULES_UI;
  constructor(public modules: any[]) {}
}
export class ReceiveMediaGallary implements Action {
  readonly type = UIActionTypes.RECEIVE_MEDIA_GALLARY_UI;
  constructor(public values: string) {}
}

export class SetChatToTemplate implements Action {
  readonly type = UIActionTypes.UI_SET_CHAT_TO_TEMPLATE;
  constructor(public id: any, public itemType: string, public value: any) {}
}
export class SetTemplateMessage implements Action {
  readonly type = UIActionTypes.UI_SET_TEMPLATE_MESSAGE;
  constructor(
    public id: any,
    public itemType: string,
    public messageId: string,
    public value: any
  ) {}
}

export class EnableSetTemplateBtn implements Action {
  readonly type = UIActionTypes.UI_ENABLE_SET_TEMPLATE_BTN;
  constructor(public value) {}
}

export type UIActions =
  | ChatSelected
  | MessageSelected
  | MessageSelectedForStat
  | UserSelected
  | GroupRepliesSelected
  | BackFromGroupReplies
  | ListSelected
  | SelectAllChannels
  | SelectOneToOneReply
  | SelectTalkToAdmin
  | SelectReplyToAdmin
  | BackFromOneToOneReply
  | ShowPopup
  | ShowErrorPopup
  | HidePopup
  | HideErrorSnackbar
  | ShowPreview
  | HidePreview
  | ShowDragDropScreen
  | HideDragDropScreen
  | SetChannelsListPageNumber
  | SetChatsListPageNumber
  | SetMessagesPageNumber
  | SetPostsPageNumber
  | SetSubPostsPageNumber
  | SetSearchKeyWord
  | SetContactsCount
  | SetGroupsCount
  | DetailsReceived
  | SetHasNoContacts
  | EvaluateShowDashboard
  | ShowDashboard
  | ShowMediaScreen
  | HideMediaScreen
  | SelectChannelPost
  | SelectSubChannelPost
  | SetPostRepliesPageNumber
  | SetSubPostRepliesPageNumber
  | DeselectChannel
  | Reset
  | SetClickedMessage
  | SetSection
  | SetPageNumber
  | DateSelected
  | SetAuthPrivilege
  | BotMenuSelected
  | SubChatSelected
  | SubChatDeselected
  | BackFromSubGroupReplies
  | SubParentMessageSelected
  | SetSubGroupMessagesPageNumber
  | SubUserSelected
  | SubUserDeselected
  | SetSideMenuStatus
  | MapMarkerSelected
  | ToggleHelpWindow
  | SetAuthPrivilegeModules
  | TogglePopupUpgradePlanModule
  | AddToastMessage
  | RemoveToastMessage
  | ToggleExpiredPopup
  | ReceiveConfModules
  | ReceiveMediaGallary
  | SetChatLabels
  | GetChatLabels
  | SaveChatLabels
  | EnableSetTemplateBtn;
