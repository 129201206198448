/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupons-pool.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component.ngfactory";
import * as i3 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i6 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i7 from "../../../../abstract-frames/form-frame/form-frame.component.ngfactory";
import * as i8 from "../../../../abstract-frames/form-frame/form-frame.component";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./coupons-pool.component";
import * as i11 from "../../coupons/coupons.service";
import * as i12 from "../../dashboard.service";
import * as i13 from "../../../+layout/layout.service";
var styles_CouponsPoolComponent = [i0.styles];
var RenderType_CouponsPoolComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponsPoolComponent, data: {} });
export { RenderType_CouponsPoolComponent as RenderType_CouponsPoolComponent };
function View_CouponsPoolComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectableCellFrameComponent_0, i2.RenderType_SelectableCellFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelectableCellFrameComponent, [], { isSelected: [0, "isSelected"], selectorId: [1, "selectorId"], upcCode: [2, "upcCode"], title: [3, "title"], useNewStely: [4, "useNewStely"], cellSize: [5, "cellSize"] }, { checkClick: "checkClick", checkChanged: "checkChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.couponId) === ((_co.currentSelectMessage == null) ? null : _co.currentSelectMessage.couponId)); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.couponId); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.code); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); var currVal_4 = true; var currVal_5 = "S"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CouponsPoolComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponsPoolComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.TrackByFunction; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CouponsPoolComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i5.View_EmptyItemFrameComponent_0, i5.RenderType_EmptyItemFrameComponent)), i1.ɵdid(1, 49152, null, 0, i6.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.couponFet == null) ? null : _co.couponFet.symbolImage); var currVal_1 = ((_co.couponFet == null) ? null : _co.couponFet.emptyTitle); var currVal_2 = ((_co.couponFet == null) ? null : _co.couponFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CouponsPoolComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "navigate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "navigate-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.couponService == null) ? null : _co.couponService.getPrevPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-navigate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "navigate-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.couponService == null) ? null : _co.couponService.getNextPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"], ["style", "transform: rotate(180deg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-navigate"]], null, null, null, null, null))], null, null); }
export function View_CouponsPoolComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "app-form-frame", [["no-padding", ""]], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormFrameComponent_0, i7.RenderType_FormFrameComponent)), i1.ɵdid(1, 49152, null, 0, i8.FormFrameComponent, [], { showCancel: [0, "showCancel"] }, { closeNotify: "closeNotify" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 1, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(12, 0, null, 12, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "pool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "pool-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CouponsPoolComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CouponsPoolComponent_3)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CouponsPoolComponent_4)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, 15, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 3, "button", [["class", "add-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(28, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.couponService.getCouponList$)); _ck(_v, 16, 0, currVal_4); var tmp_5_0 = null; var currVal_5 = ((((tmp_5_0 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co.couponService.getCouponList$))) == null) ? null : tmp_5_0.length) === 0); _ck(_v, 19, 0, currVal_5); var tmp_6_0 = null; var currVal_6 = ((((tmp_6_0 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform(_co.couponService.getCouponList$))) == null) ? null : tmp_6_0.length) > 0); _ck(_v, 22, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ADDCOUPON")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("MANAGEYOURCOUPONOR")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("CREATEANEWCOUPON")); _ck(_v, 9, 0, currVal_3); var tmp_7_0 = null; var currVal_7 = ((((tmp_7_0 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform(_co.couponService.getCouponList$))) == null) ? null : tmp_7_0.length) === 0); _ck(_v, 26, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("ADD")); _ck(_v, 28, 0, currVal_8); }); }
export function View_CouponsPoolComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coupons-pool", [], null, null, null, View_CouponsPoolComponent_0, RenderType_CouponsPoolComponent)), i1.ɵdid(1, 49152, null, 0, i10.CouponsPoolComponent, [i11.CouponsService, i12.DashboardService, i13.LayoutService], null, null)], null, null); }
var CouponsPoolComponentNgFactory = i1.ɵccf("app-coupons-pool", i10.CouponsPoolComponent, View_CouponsPoolComponent_Host_0, { canAddCoupon: "canAddCoupon" }, { cancelNotfiy: "cancelNotfiy", selectCouponNotfiy: "selectCouponNotfiy" }, []);
export { CouponsPoolComponentNgFactory as CouponsPoolComponentNgFactory };
