import { CouponDispatchers } from 'src/app/store/coupons/coupon.dispatchers';
import { CouponUIDispatchers } from 'src/app/store/couponsUI/couponUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { STATUS_OPTIONS } from 'src/models/campaign';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/coupons/coupon.dispatchers";
import * as i3 from "../../../store/couponsUI/couponUI.dispatchers";
export class CouponsService {
    constructor(_rootStoreSelector, _couponDispatchers, _couponUIDispatchers) {
        this._rootStoreSelector = _rootStoreSelector;
        this._couponDispatchers = _couponDispatchers;
        this._couponUIDispatchers = _couponUIDispatchers;
    }
    getBgStatus(item) {
        if (item.status === STATUS_OPTIONS.ACTIVE) {
            return 'linear-gradient(309deg, #caf7dc, #89d1a6)';
        }
        else if (item.status === STATUS_OPTIONS.DEACTIVE) {
            return 'linear-gradient(309deg, #f4e9d4, #f4d091)';
        }
        else if (item.status === STATUS_OPTIONS.EXPIRE) {
            return 'linear-gradient(309deg, #fbe8e7, #fba5a5)';
        }
    }
    setCoupon(msg, isCreateFromBool) {
        if (msg.couponId) {
            this._couponDispatchers.updateCoupon(msg);
            this._couponUIDispatchers.closeCreateCouponUI();
        }
        else {
            this._couponDispatchers.createCoupon(msg, isCreateFromBool);
            this._couponUIDispatchers.closeEditCouponUI();
        }
    }
    getCoupon(id) {
        this._couponDispatchers.getCoupon(id);
    }
    createCoupon() {
        this._couponUIDispatchers.createCouponUI();
    }
    viewCoupon(camp) {
        this._couponUIDispatchers.viewCouponUI(camp);
    }
    editCoupon(camp) {
        this._couponUIDispatchers.editCouponUI(camp);
    }
    closeViewCoupon() {
        this._couponUIDispatchers.closeViewCouponUI();
    }
    closeEditCoupon() {
        this._couponUIDispatchers.closeEditCouponUI();
    }
    getNextPage() {
        this._couponUIDispatchers.getNextCouponPage();
    }
    getPrevPage() {
        this._couponUIDispatchers.getPrevCouponPage();
    }
    resetPaging(status, expire) {
        this._couponUIDispatchers.resetCouponPaging(status, expire);
    }
    get couponUiState$() {
        return this._rootStoreSelector.couponUiState$;
    }
    get getCouponList$() {
        return this._rootStoreSelector.getCouponList$();
    }
}
CouponsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CouponsService_Factory() { return new CouponsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.CouponDispatchers), i0.ɵɵinject(i3.CouponUIDispatchers)); }, token: CouponsService, providedIn: "root" });
