/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toast-item/toast-item.component.ngfactory";
import * as i3 from "./toast-item/toast-item.component";
import * as i4 from "../../core/ui.service";
import * as i5 from "@angular/common";
import * as i6 from "./toast.component";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: { "animation": [{ type: 7, name: "slideIn", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { transform: "translateY(0) scale(1) rotateY(0)", opacity: 1, filter: "blur(0) saturate(100%)" }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { transform: "translateY(20px) scale(1.1) rotateY(5deg)", opacity: 0, filter: "blur(2px) saturate(50%)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: { type: 4, styles: null, timings: ".3s ease-in-out" }, options: null }], options: {} }, { type: 7, name: "slideOut", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { transform: "translateX(0)  scale(1)", opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { transform: "translateX(100%) scale(.7)", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "* => void", animation: { type: 4, styles: null, timings: ".2s ease" }, options: null }], options: {} }] } });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "labs-toast-item", [["style", "font-size: 12px;"]], [[24, "@slideIn", 0], [24, "@slideOut", 0]], null, null, i2.View_ToastItemComponent_0, i2.RenderType_ToastItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.ToastItemComponent, [i4.UiService], { toast: [0, "toast"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = undefined; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 114688, null, 0, i6.ToastComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i1.ɵccf("app-toast", i6.ToastComponent, View_ToastComponent_Host_0, { messages: "messages" }, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
