/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-menu-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../menus/menu-holder/menu-holder.component.ngfactory";
import * as i3 from "../../../../menus/menu-holder/menu-holder.component";
import * as i4 from "../../../appMgmt.service";
import * as i5 from "../../../../bots/bots.service";
import * as i6 from "../../../../../+layout/layout.service";
import * as i7 from "../../../../../../core/ui.service";
import * as i8 from "../../../../mobile-workflow-store/mobile-workflow-store.service";
import * as i9 from "@angular/common";
import * as i10 from "./main-menu-view.component";
var styles_MainMenuViewComponent = [i0.styles];
var RenderType_MainMenuViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainMenuViewComponent, data: {} });
export { RenderType_MainMenuViewComponent as RenderType_MainMenuViewComponent };
function View_MainMenuViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "empty-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "./assets/img/empty-menu.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Empty Menu!"]))], null, null); }
function View_MainMenuViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-menu-holder", [], null, null, null, i2.View_MenuHolderComponent_0, i2.RenderType_MenuHolderComponent)), i1.ɵdid(1, 770048, null, 0, i3.MenuHolderComponent, [i1.ChangeDetectorRef, i4.AppMgmtService, i5.BotsService, i6.LayoutService, i7.UiService, i8.MobileWorkFlowStoreService], { menus: [0, "menus"], systemConfg: [1, "systemConfg"], mainMenu: [2, "mainMenu"], mobView: [3, "mobView"], ios: [4, "ios"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuForm; var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(((_co.appManagementService == null) ? null : _co.appManagementService.systemConfig$))); var currVal_2 = _co.mainMenu; var currVal_3 = true; var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 3).transform(((_co.appManagementService == null) ? null : _co.appManagementService.appInfo$)))) == null) ? null : tmp_4_0.mob_ver); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_MainMenuViewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "set-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgStyleImpl, i9.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i9.NgStyle, [i9.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuViewComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuViewComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadedWorkFlowImage; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.menuForm || (((_co.menuForm == null) ? null : ((_co.menuForm.rows == null) ? null : _co.menuForm.rows.length)) <= 0)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.menuForm && (((_co.menuForm == null) ? null : ((_co.menuForm.rows == null) ? null : _co.menuForm.rows.length)) >= 1)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_MainMenuViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-menu-view", [], null, null, null, View_MainMenuViewComponent_0, RenderType_MainMenuViewComponent)), i1.ɵdid(1, 770048, null, 0, i10.MainMenuViewComponent, [i4.AppMgmtService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainMenuViewComponentNgFactory = i1.ɵccf("app-main-menu-view", i10.MainMenuViewComponent, View_MainMenuViewComponent_Host_0, { menuForm: "menuForm", appMenus: "appMenus", ios: "ios", mainMenu: "mainMenu", selectedTab: "selectedTab", tabBg: "tabBg", mobScreen: "mobScreen" }, {}, []);
export { MainMenuViewComponentNgFactory as MainMenuViewComponentNgFactory };
