import * as CampaignMethods from 'src/models/ICampaign';

import { AWARD_TYPE_POINTS, AWARD_TYPE_COUPON } from 'src/models/campaign';

export class CampaignMiddleware {
  public static initMessage(
    msg: CampaignMethods.CampaignMessage
  ): CampaignMethods.ICampaign {
    const localMessage: CampaignMethods.ICampaign = {};

    const campaign = msg.campaign;
    /* Campaign Message */
    if (campaign) {
      /* CampaignAccumulators array of accumulators */
      if (campaign.accumulators && campaign.accumulators.length > 0) {
        for (let index = 0; index < campaign.accumulators.length; index++) {
          const element = campaign.accumulators[index];
          if (element.type === CampaignMethods.AccumulatorTypes.EVENT) {
            localMessage.accumulatorIdEvent = element.accumulatorId;
            localMessage.periodEvent = element.period;
            localMessage.targetEvent = element.target;
          } else if (
            element.type ===
            CampaignMethods.AccumulatorTypes.WINNER_PER_CAMPAIGN
          ) {
            localMessage.accumulatorIdUser = element.accumulatorId;
            localMessage.periodUser = element.period;
            localMessage.targetUser = element.target;
          } else if (
            element.type === CampaignMethods.AccumulatorTypes.WINNER_PER_USER
          ) {
            localMessage.accumulatorIdCampaign = element.accumulatorId;
            localMessage.periodCampaign = element.period;
            localMessage.targetCampaign = element.target;
          }
        }
      }
      /* Award Message */
      if (campaign.award) {
        const award = campaign.award;
        if (award) {
          if (award.points && award.type === AWARD_TYPE_POINTS) {
            localMessage.awardPoints = award.points;
          }
          if (award.couponId && award.type === AWARD_TYPE_COUPON) {
            localMessage.couponId = award.couponId;
          }
          if (award.senderId) {
            localMessage.senderId = award.senderId;
          }
          if (award.messageId) {
            localMessage.messageId = award.messageId;
          }
          if (award.tab) {
            localMessage.tab = award.tab;
          }
        }
      }
      /* specific for campaign */
      if (campaign.name) {
        localMessage.name = campaign.name;
      }
      if (campaign.campaignId) {
        localMessage.campaignId = campaign.campaignId;
      }
      if (campaign.groupId) {
        localMessage.groupId = campaign.groupId;
      }
      if (campaign.joinPeriod) {
        localMessage.joinPeriod = campaign.joinPeriod;
      }
      if (campaign.maxWinners) {
        localMessage.maxWinners = campaign.maxWinners;
      }
      if (campaign.numWinners) {
        localMessage.numWinners = campaign.numWinners;
      }
      if (campaign.odds) {
        localMessage.odds = campaign.odds;
      }
      if (campaign.poolSize) {
        localMessage.poolSize = campaign.poolSize;
      }
      if (campaign.status) {
        localMessage.status = campaign.status;
        localMessage.oldStatus = campaign.status;
      }
      if (campaign.scope) {
        localMessage.scope = campaign.scope;
        localMessage.oldScope = campaign.scope;
      }
      if (campaign.triggers) {
        localMessage.triggers = campaign.triggers;
      }
      if (campaign.domain) {
        localMessage.domain = campaign.domain;
      }
      if (campaign.timezone) {
        localMessage.timezone = campaign.timezone;
      }

      if (campaign.published) {
        localMessage.published = campaign.published;
      }
      if (campaign.qrCode) {
        localMessage.qrCode = campaign.qrCode;
      }

      // needs to convert before send to server
      if (!campaign.startHour) {
        localMessage.startHour = 0;
      } else if (campaign.startHour) {
        localMessage.startHour = campaign.startHour;
      }
      if (!campaign.endHour) {
        localMessage.endHour = 0;
      } else if (campaign.endHour) {
        localMessage.endHour = campaign.endHour;
      }
      if (campaign.days) {
        localMessage.days = campaign.days.split(',');
      }
      if (campaign.startDate) {
        const startDateParts = campaign.startDate.split('-');
        const localStartDate = new Date(
          Number(startDateParts[0]),
          Number(startDateParts[1]) - 1,
          Number(startDateParts[2])
        );
        localMessage.startDate = localStartDate;
      }
      if (campaign.endDate) {
        const endDateParts = campaign.endDate.split('-');
        const localEndDate = new Date(
          Number(endDateParts[0]),
          Number(endDateParts[1]) - 1,
          Number(endDateParts[2])
        );
        localMessage.endDate = localEndDate;
      }
    }

    return Object.assign({}, localMessage);
  }

  public static isCampaignExist(
    list: CampaignMethods.ICampaign[],
    rcvdObj: CampaignMethods.ICampaign
  ) {
    const cashLength = list.length;
    for (let i = 0; i < cashLength; i++) {
      if (
        (rcvdObj.campaignId && list[i].campaignId === rcvdObj.campaignId) ||
        (rcvdObj.reference && list[i].reference === rcvdObj.reference)
      ) {
        return true;
      }
    }
    return false;
  }

  public static updateCampaign(
    state: CampaignMethods.ICampaign[],
    rcvdMsg: CampaignMethods.ICampaign
  ) {
    return state.map(msg => {
      if (
        (msg.campaignId && msg.campaignId === rcvdMsg.campaignId) ||
        (msg.reference && msg.reference === rcvdMsg.reference)
      ) {
        if (rcvdMsg.campaignId) {
          msg.campaignId = rcvdMsg.campaignId;
        }
        /* Award message eathir awardPoints or couponId */
        if (rcvdMsg.awardPoints) {
          msg.awardPoints = rcvdMsg.awardPoints;
          msg.awardType = AWARD_TYPE_POINTS;
        } else {
          msg.awardPoints = null;
        }
        if (rcvdMsg.couponId) {
          msg.awardType = AWARD_TYPE_COUPON;
          msg.couponId = rcvdMsg.couponId;
        } else {
          msg.couponId = null;
        }
        if (rcvdMsg.days) {
          msg.days = rcvdMsg.days;
        }
        if (rcvdMsg.messageId) {
          msg.messageId = rcvdMsg.messageId;
        }
        if (rcvdMsg.domain) {
          msg.domain = rcvdMsg.domain;
        }
        if (rcvdMsg.endDate) {
          msg.endDate = rcvdMsg.endDate;
        }
        if (rcvdMsg.endHour) {
          msg.endHour = rcvdMsg.endHour;
        }
        if (rcvdMsg.groupId) {
          msg.groupId = rcvdMsg.groupId;
        }
        if (rcvdMsg.isActive) {
          msg.isActive = rcvdMsg.isActive;
        }
        if (rcvdMsg.isSelected) {
          msg.isSelected = rcvdMsg.isSelected;
        }
        if (rcvdMsg.joinPeriod) {
          msg.joinPeriod = rcvdMsg.joinPeriod;
        }
        if (rcvdMsg.maxWinners) {
          msg.maxWinners = rcvdMsg.maxWinners;
        }
        if (rcvdMsg.name) {
          msg.name = rcvdMsg.name;
        }
        if (rcvdMsg.numWinners) {
          msg.numWinners = rcvdMsg.numWinners;
        }
        if (rcvdMsg.odds) {
          msg.odds = rcvdMsg.odds;
        } else {
          msg.odds = 1;
        }
        if (rcvdMsg.poolSize) {
          msg.poolSize = rcvdMsg.poolSize;
        } else {
          msg.poolSize = 1;
        }
        if (rcvdMsg.reference) {
          msg.reference = rcvdMsg.reference;
        }
        if (rcvdMsg.scope) {
          msg.scope = rcvdMsg.scope;
        }
        if (rcvdMsg.startDate) {
          msg.startDate = rcvdMsg.startDate;
        }
        if (rcvdMsg.startHour) {
          msg.startHour = rcvdMsg.startHour;
        }
        if (rcvdMsg.senderId) {
          msg.senderId = rcvdMsg.senderId;
        }
        if (rcvdMsg.status) {
          msg.status = rcvdMsg.status;
        }
        if (rcvdMsg.published) {
          msg.published = rcvdMsg.published;
        }

        if (rcvdMsg.accumulatorIdEvent) {
          msg.accumulatorIdEvent = rcvdMsg.accumulatorIdEvent;
        }
        if (rcvdMsg.accumulatorIdUser) {
          msg.accumulatorIdUser = rcvdMsg.accumulatorIdUser;
        } else {
          msg.accumulatorIdUser = null;
        }
        if (rcvdMsg.accumulatorIdCampaign) {
          msg.accumulatorIdCampaign = rcvdMsg.accumulatorIdCampaign;
        } else {
          msg.accumulatorIdCampaign = null;
        }
        if (rcvdMsg.periodEvent) {
          msg.periodEvent = rcvdMsg.periodEvent;
        } else {
          msg.periodEvent = '';
        }
        if (rcvdMsg.targetEvent) {
          msg.targetEvent = rcvdMsg.targetEvent;
        }
        if (rcvdMsg.periodUser) {
          msg.periodUser = rcvdMsg.periodUser;
        } else {
          msg.periodUser = '';
        }
        if (rcvdMsg.targetUser) {
          msg.targetUser = rcvdMsg.targetUser;
        } else {
          msg.targetUser = null;
        }
        if (rcvdMsg.periodCampaign) {
          msg.periodCampaign = rcvdMsg.periodCampaign;
        } else {
          msg.periodCampaign = '';
        }
        if (rcvdMsg.targetCampaign) {
          msg.targetCampaign = rcvdMsg.targetCampaign;
        } else {
          msg.targetCampaign = null;
        }

        if (rcvdMsg.timezone) {
          msg.timezone = rcvdMsg.timezone;
        }
        if (rcvdMsg.triggers) {
          msg.triggers = rcvdMsg.triggers;
        }
        if (rcvdMsg.qrCode) {
          msg.qrCode = rcvdMsg.qrCode;
        }
      }
      return msg;
    });
  }

  public static sendCampaignMessage(
    createCampaign: CampaignMethods.ICampaign
  ): CampaignMethods.CampaignMessage {
    const msgToBeSent: CampaignMethods.CampaignMessage = {};

    // create local campaign object
    const localCamp: CampaignMethods.Campaign = {};
    if (createCampaign) {
      localCamp.campaignId = createCampaign.campaignId;
      localCamp.name = createCampaign.name;
      localCamp.days = createCampaign.days.join();
      localCamp.startHour = createCampaign.startHour;
      localCamp.endHour = createCampaign.endHour;
      localCamp.startDate = CampaignMiddleware.formatDate(
        createCampaign.startDate
      );
      localCamp.endDate = CampaignMiddleware.formatDate(createCampaign.endDate);
      localCamp.joinPeriod = createCampaign.joinPeriod;
      localCamp.maxWinners = createCampaign.maxWinners * 1; // convert it to number
      localCamp.numWinners = createCampaign.numWinners;
      localCamp.odds = createCampaign.odds;
      localCamp.poolSize = createCampaign.poolSize;
      localCamp.scope = createCampaign.scope;
      localCamp.domain = createCampaign.domain;
      localCamp.status = createCampaign.status;
      localCamp.timezone = createCampaign.timezone;
      localCamp.triggers = createCampaign.triggers;

      // create accumulators
      const localAccumulators: CampaignMethods.Accumulator[] = [];

      // Type 0
      localAccumulators.push({
        increment: createCampaign.targetEvent * 1,
        accumulatorId: createCampaign.accumulatorIdEvent
          ? createCampaign.accumulatorIdEvent
          : null,
        period: createCampaign.periodEvent,
        target: createCampaign.targetEvent * 1 /* convert it to string */,
        type: CampaignMethods.AccumulatorTypes.EVENT
      });

      // Type 1
      if (createCampaign.targetUser) {
        // in case of checked
        localAccumulators.push({
          increment: 1,
          accumulatorId: createCampaign.accumulatorIdUser
            ? createCampaign.accumulatorIdUser
            : null,
          period: createCampaign.periodUser,
          target: createCampaign.targetUser * 1,
          type: CampaignMethods.AccumulatorTypes.WINNER_PER_CAMPAIGN
        });
      } else if (!createCampaign.targetUser) {
        // in case of unchecked
        localAccumulators.push({
          increment: 1,
          accumulatorId: createCampaign.accumulatorIdUser
            ? createCampaign.accumulatorIdUser
            : null,
          type: CampaignMethods.AccumulatorTypes.WINNER_PER_CAMPAIGN,
          action: CampaignMethods.AccumlatorActions.DELETE
        });
      }

      // Type 2
      if (createCampaign.targetCampaign) {
        // in case of checked
        localAccumulators.push({
          increment: 1,
          accumulatorId: createCampaign.accumulatorIdCampaign
            ? createCampaign.accumulatorIdCampaign
            : null,
          period: createCampaign.periodCampaign,
          target: createCampaign.targetCampaign * 1,
          type: CampaignMethods.AccumulatorTypes.WINNER_PER_USER
        });
      } else if (!createCampaign.targetCampaign) {
        // in case of unchecked
        localAccumulators.push({
          increment: 1,
          accumulatorId: createCampaign.accumulatorIdCampaign
            ? createCampaign.accumulatorIdCampaign
            : null,
          type: CampaignMethods.AccumulatorTypes.WINNER_PER_USER,
          action: CampaignMethods.AccumlatorActions.DELETE
        });
      }

      // create award
      const localAward: CampaignMethods.Award = {
        couponId: createCampaign.couponId,
        senderId: createCampaign.senderId,
        points: createCampaign.awardPoints * 1 /* convert it to string */,
        messageId: createCampaign.messageId,
        tab: createCampaign.tab
      };

      if (localAward.points) {
        localAward.type = AWARD_TYPE_POINTS;
      } else {
        localAward.type = AWARD_TYPE_COUPON;
      }

      // Asemble parts
      msgToBeSent.campaign = localCamp;
      msgToBeSent.campaign.accumulators = localAccumulators;
      msgToBeSent.campaign.award = localAward;
    }
    return msgToBeSent;
  }

  public static formatDate(d: Date) {
    return (
      d.getFullYear() +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + d.getDate()).slice(-2)
    );
  }
}
