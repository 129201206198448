import { Store } from '@ngrx/store';
import * as MapTripActions from './mapTrip.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapTripDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetMapTripPaging() {
        this._store.dispatch(new MapTripActions.ResetMapTripPaging());
    }
    getNextMapTripPage() {
        this._store.dispatch(new MapTripActions.GetNextMapTripPage());
    }
    getPrevMapTripPage() {
        this._store.dispatch(new MapTripActions.GetPrevMapTripPage());
    }
    createMapTrip(mapTrip) {
        this._store.dispatch(new MapTripActions.CreateMapTrip(mapTrip));
    }
    mapTripCreated(mapTrip) {
        this._store.dispatch(new MapTripActions.MapTripCreated(mapTrip));
    }
    updateMapTrip(id, mapTrip) {
        this._store.dispatch(new MapTripActions.UpdateMapTrip(id, mapTrip));
    }
    mapTripDetailsReceived(mapTrip) {
        this._store.dispatch(new MapTripActions.MapTripDetailsReceived(mapTrip));
    }
    getMapTripById(tripId) {
        this._store.dispatch(new MapTripActions.GetMapTripById(tripId));
    }
    mapTripListReceived(eop, sop, mapTrips, hash) {
        this._store.dispatch(new MapTripActions.MapTripListReceived(eop, sop, mapTrips, hash));
    }
    setCurrentScreen(screen) {
        this._store.dispatch(new MapTripActions.SetCurrentScreen(screen));
    }
    setStatusAsIdle() {
        this._store.dispatch(new MapTripActions.SetStatusAsIdle());
    }
    deleteMapTrip(id) {
        this._store.dispatch(new MapTripActions.DeleteMapTrip(id));
    }
    mapTripDeleted(id) {
        this._store.dispatch(new MapTripActions.MapTripDeleted(id));
    }
}
MapTripDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapTripDispatchers_Factory() { return new MapTripDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapTripDispatchers, providedIn: "root" });
