<div class="container" [class.full-page]="isStartOver">
  <div class="container-header">
    Help
    <div class="cancel-btn" (click)="closeHelpWindow()">
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-cancel"></use>
      </svg>
    </div>
  </div>
  <div class="container-body">
    <iframe [src]="url"></iframe>
    <a [href]="sectionLink" target="_blank">Show More…</a>
  </div>
  <div class="read-all-btn" (click)="openDocumentWeb()">
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-document"></use>
    </svg>
    Read Full Documentation
  </div>
</div>
