import {
  GET_APP_STORE_METHOD,
  SET_APP_STORE_METHOD,
  SET_APPLE_CODE_METHOD,
  SET_APP_METHOD,
  GET_APP_REALSE_VER_DATA,
  GET_APPS_SETUP_METHOD
} from './constants';

export interface AppPublishInfo {
  app_id?: string;
  app_name?: string;

  google_account_opened?: number; // 0 or 1
  google_release_manager_setup?: number; // 0 or 1
  google_sc_json?: GoogleServiceAccountJSON;
  google_package_id?: string;
  //
  apple_account_opened?: number; // 0 or 1
  apple_account_individual?: number; // 0 or 1
  apple_developer_user?: string;
  apple_developer_password?: string;
  apple_app_manager_setup?: number; // 0 or 1
  apple_team_name?: string;
  apple_bundle_id?: string;
  apple_app_name?: string;
  apple_app_manager_confirm?: number; // 0 or 1
  apple_two_factor_enabled?: number; // 0 or 1
  app_enabled_notify?: number; // 0 or 1
  apple_app_spec_password?: string;
  apple_push_key_file?: string;
  apple_push_key_id?: string;
  // Flags to lock edit of some fields when they are 1
  apple_app_lock?: number;
  apple_account_lock?: number;
  google_app_lock?: number;
  apple_company_name?: string;
  error_msg?: string;
  apps?: AppJSON[];
  storeSetupResult?: number;
  appSetupResult?: number;
  apple_account_status?: string;
}

export interface AppStoreJSON {
  apple_account_opened?: number;
  apple_account_individual?: number;
  apple_two_factor_enabled?: number;
  apple_developer_user?: string;
  apple_developer_password?: string;
  apple_app_manager_setup?: number;
  apple_team_name?: string;
  apple_company_name?: string;
  apple_app_spec_password?: string;
  apple_app_manager_confirm?: number;
  apple_push_key_file?: string;
  apple_push_key_id?: string;
  apple_account_status?: string;
  error_msg?: string;
}

export interface AppJSON {
  app_id?: string;
  apple_bundle_id?: string;
  apple_app_name?: string;
  app_enabled_notify?: number;
  google_package_id?: string;
  app_name?: string;
  apple_app_lock?: number;
  google_app_lock?: number;
  apple_app_setup_status?: string;
  error_msg?: string;
  android_app_setup?: boolean;
}

export interface GoogleServiceAccountJSON {
  type?: string;
  project_id?: string;
  private_key_id?: string;
  private_key?: string;
  client_email?: string;
  client_id?: string;
  auth_uri?: string;
  token_uri?: string;
  auth_provider_x509_cert_url?: string;
  client_x509_cert_url?: string;
}

export interface AppleKeyCodeUI {
  release_id?: number;
  show?: boolean;
}

export class GetAppStoreMethod {
  readonly method = GET_APP_STORE_METHOD;
  constructor(public app_id?: string) {}
}
export class GetAppsSetupMethod {
  readonly method = GET_APPS_SETUP_METHOD;
}

export class SetAppStoreMethod {
  readonly method = SET_APP_STORE_METHOD;
  constructor(publishInfo: AppPublishInfo) {
    for (const key in publishInfo) {
      if (publishInfo.hasOwnProperty(key)) {
        if (key === 'google_sc_json' || key === 'apple_push_key_file') {
          this[key] = publishInfo[key] ? publishInfo[key] : '';
        } else {
          this[key] = publishInfo[key];
        }
      }
    }
  }
}

export class SetAppleStoreMethod {
  readonly method = SET_APP_STORE_METHOD;
  constructor(public os: number, public app_store: AppStoreJSON) {}
}

export class SetAppInfoMethod {
  readonly method = SET_APP_METHOD;
  constructor(public os: number, public app: AppJSON) {}
}

export class SetAppleKeyCode {
  readonly method = SET_APPLE_CODE_METHOD;
  constructor(public code: string, public release_id: number) {}
}

export class GetAppRealseVerData {
  readonly method = GET_APP_REALSE_VER_DATA;
  constructor(public id: string) {}
}
