import {
  GET_MYPAGE_METHOD,
  SET_MYPAGE_METHOD,
  CREATE_MYPAGE_METHOD,
  GET_MY_PAGES_METHOD,
  GET_INSTANT_PAGES_METHOD,
  GET_INSTANT_PAGE_METHOD,
  REMOVE_MY_PAGE_METHOD,
  CREATE_PAGE_TEMPLATE_METHOD
} from './constants';

export interface MyPageComponent {
  id?: string;
  url?: string;
  image?: string;
  desc?: string;
  action?: string;
  title?: string;
  form_id?: string; // page_id
  template_id?: string;
  page_ref?: string;
  page_link_requested?: number; // 0 or 1
  order?: number;
  type?: string;
}

export interface MyPageContainer {
  id?: string;
  container_type?: string;
  title?: string;
  style?: string;
  body?: string;
  url?: string;
  lat?: string;
  lng?: string;
  list_type?: string;
  list_id?: string;
  address?: string;
  column?: number;
  order?: number;
  cat?: string;
  component?: MyPageComponent[];
}

export interface MyPageForm {
  id?: string;
  childTemplateId?: string;
  ref?: string;
  title?: string;
  image?: string;
  template?: number;
  background?: string;
  container?: MyPageContainer[];
}

export interface MyPage {
  id?: string;
  template_id?: string;
  reference?: string;
  date?: Date;
  groupId?: string;
  name?: string;
  url?: string;
  image?: string;
  desc?: string;
  needUpdate?: boolean;
  needPageID?: boolean;
  content?: MyPageForm;
  hide?: number;
}

export class GetMyPageConfigRequest {
  readonly method = GET_MYPAGE_METHOD;
  constructor(public id: string) {}
}

export class RemoveMyPageRequest {
  readonly method = REMOVE_MY_PAGE_METHOD;
  constructor(public id: string) {}
}

export class GetMyPagesListRequest {
  readonly method = GET_MY_PAGES_METHOD;
}

export class GetInstantPagesRequest {
  readonly method = GET_INSTANT_PAGES_METHOD;
}

export class GetInstantPageDetailsRequest {
  readonly method = GET_INSTANT_PAGE_METHOD;
  constructor(public id: string) {}
}

export class SetMyPageConfigRequest {
  readonly method = SET_MYPAGE_METHOD;
  constructor(
    public id: string,
    public content: MyPageForm,
    public name?: string,
    public url?: string,
    public image?: string,
    public desc?: string
  ) {}
}

export class CreateMyPageConfigRequest {
  readonly method = CREATE_MYPAGE_METHOD;
  constructor(
    public reference: string,
    public content: MyPageForm,
    public name?: string,
    public url?: string,
    public image?: string,
    public desc?: string,
    public template_id?: string
  ) {}
}

export class CreateMyPageTemplate {
  readonly method = CREATE_PAGE_TEMPLATE_METHOD;
  constructor(
    public reference: string,
    public content: MyPageForm,
    public name?: string,
    public image?: string,
    public desc?: string
  ) {}
}

export interface PageKey {
  name?: string;
  key?: string;
}
