import { Actions } from './blacklistPatternsUI.actions';
import * as actions from './blacklistPatternsUI.actions';
import { ListRequestDetails, PageDirection } from '../../../models/constants';
import { BlackListsUI } from 'src/models/BlackListsUI';

const INITIAL_STATE: BlackListsUI = {
  eop: 0,
  sop: null,
  currentPage: [],
  hash: '',
  direction: null,
  previousRequest: {}
};
export function blackListPatternsUiReducer(
  state = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.BlacklistPatternsUIActionTypes
      .UI_BLACKLIST_PATTERNS_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.BlacklistPatternsUIActionTypes
      .UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE: {
      return { ...state, direction: PageDirection.NEXT };
    }
    case actions.BlacklistPatternsUIActionTypes
      .UI_BLACKLIST_PATTERNS_GET_PREV_PAGE: {
      return { ...state, direction: PageDirection.PREV };
    }
    case actions.BlacklistPatternsUIActionTypes
      .UI_BLACKLIST_PATTERNS_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.BlacklistPatternsUIActionTypes
      .DELETE_PATTERNS_FROM_BLACKLIST_SUCCESS: {
      return {
        ...state,
        currentPage: state.currentPage.filter(patterns => {
          if (patterns.pattern !== action.data[0]) {
            return patterns;
          }
        })
      };
    }
    case actions.BlacklistPatternsUIActionTypes
      .UI_ADD_BLACKLIST_PATTERNS_SUCCESS:
      return { ...state, currentPage: [...action.data, ...state.currentPage] };
    case actions.BlacklistPatternsUIActionTypes
      .UI_BLACKLIST_PATTERNS_SET_IDLE_STATUS:
      return { ...state };
    case actions.BlacklistPatternsUIActionTypes.RESET_BLACKLIST_PATTERN:
      return INITIAL_STATE;
    default:
      return state;
  }
}
