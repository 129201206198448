/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-file-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/pipes/multi-line/multi-line.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./text-file-cell.component";
var styles_TextFileCellComponent = [i0.styles];
var RenderType_TextFileCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextFileCellComponent, data: {} });
export { RenderType_TextFileCellComponent as RenderType_TextFileCellComponent };
function View_TextFileCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Read More..."]))], null, null); }
export function View_TextFileCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.MultiLinePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "wrap-text"], ["dir", "auto"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextFileCellComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isOpen; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.localMessageString)); _ck(_v, 1, 0, currVal_0); }); }
export function View_TextFileCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-file-cell", [], null, null, null, View_TextFileCellComponent_0, RenderType_TextFileCellComponent)), i1.ɵdid(1, 573440, null, 0, i4.TextFileCellComponent, [], null, null)], null, null); }
var TextFileCellComponentNgFactory = i1.ɵccf("app-text-file-cell", i4.TextFileCellComponent, View_TextFileCellComponent_Host_0, { localMedia: "localMedia", localMessageString: "localMessageString" }, {}, []);
export { TextFileCellComponentNgFactory as TextFileCellComponentNgFactory };
