import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as AppMenuActions from './appMenu.actions';
import { AppMenuActionTypes } from './appMenu.actions';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ListAppMenus } from 'src/models/AppMenu';

@Injectable()
export class AppMenuEffects {
  @Effect({ dispatch: false })
  listAppMenus = this.actions$.pipe(
    ofType(AppMenuActionTypes.GET_APP_MENUS),
    map((action: AppMenuActions.GetAppMenus) => {
      this._socketGateway.sendSocketMessage(new ListAppMenus(action.menuType));
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
