import { Injectable } from '@angular/core';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppClassActionTypes } from './appClass.actions';
import * as AppClassActions from './appClass.actions';
import { map } from 'rxjs/operators';
import { NOT_ALLOWED_COLORS } from 'src/models/constants';

@Injectable()
export class AppClassEffects {
  @Effect({ dispatch: false })
  chatCounters = this.actions$.pipe(
    ofType(AppClassActionTypes.NOT_ALLOWED_CLASS_BG_COLOR),
    map((action: AppClassActions.NotAllowedCLassBgColor) => {
      this._uiDispatchers.showPopup(NOT_ALLOWED_COLORS);
    })
  );

  constructor(
    private actions$: Actions,
    private _uiDispatchers: UIDispatchers
  ) {}
}
