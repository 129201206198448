import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import { SummaryUIActionTypes } from './summaryUI.actions';
import * as summaryUIAction from './summaryUI.actions';
import * as summaryMethods from 'src/models/summaryUI';
import * as isEqual from 'lodash.isequal';
import { DashboardUI } from 'src/models/summaryUI';
import { SummaryUIDispatchers } from './summaryUI.dispatchers';

@Injectable()
export class SummaryUIEffects {
  @Effect({ dispatch: false })
  setDashboard = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_SET_DASHBOARD),
    map((action: summaryUIAction.SetDashboard) => {
      this._socketGateway.sendSocketMessage(
        new summaryMethods.SetUserDashboard(action.dashboard)
      );
    })
  );

  @Effect({ dispatch: false })
  getDashboard = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_GET_USER_DASHBOARD_LIST),
    map((action: summaryUIAction.GetUserDashboardList) => {
      this._socketGateway.sendSocketMessage(
        new summaryMethods.GetUserDashboard()
      );
    })
  );

  @Effect({ dispatch: false })
  createNewWidget = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_CREATE_NEW_WIDGET),
    withLatestFrom(
      this._store
        .select(state => state.summaryUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, summaryList]) => {
      const action = <summaryUIAction.CreateNewWidget>val;
      const dashboard: DashboardUI = {
        widgets: [...summaryList.currentPage]
      };
      this._summaryUIDispatchers.setDashboard(dashboard);
    })
  );

  @Effect({ dispatch: false })
  updateDashboardList = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_UPDATE_DASHBOARD_LIST),
    withLatestFrom(
      this._store
        .select(state => state.summaryUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, summaryList]) => {
      const action = <summaryUIAction.UpdateDashboardList>val;
      const dashboard: DashboardUI = {
        widgets: [...summaryList.currentPage]
      };
      this._summaryUIDispatchers.setDashboard(dashboard);
    })
  );

  @Effect({ dispatch: false })
  updateWidget = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_UPDATE_WIDGET),
    withLatestFrom(
      this._store
        .select(state => state.summaryUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, summaryList]) => {
      const action = <summaryUIAction.UpdateWidget>val;
      const dashboard: DashboardUI = {
        widgets: [...summaryList.currentPage]
      };
      this._summaryUIDispatchers.setDashboard(dashboard);
    })
  );

  @Effect({ dispatch: false })
  removeWidget = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_REMOVE_WIDGET_FROM_LIST),
    withLatestFrom(
      this._store
        .select(state => state.summaryUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, summaryList]) => {
      const action = <summaryUIAction.RemoveWidget>val;
      const dashboard: DashboardUI = {
        widgets: [...summaryList.currentPage]
      };
      this._summaryUIDispatchers.setDashboard(dashboard);
    })
  );

  @Effect({ dispatch: false })
  saveDefaultedDashboardList = this.actions$.pipe(
    ofType(SummaryUIActionTypes.UI_SAVE_DEFAULTED_DASHBOARD_LIST),
    withLatestFrom(
      this._store
        .select(state => state.summaryUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, summaryList]) => {
      const action = <summaryUIAction.SaveDefaultedDashboardList>val;
      const dashboard: DashboardUI = {
        widgets: [...summaryList.currentPage]
      };
      this._summaryUIDispatchers.setDashboard(dashboard);
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _summaryUIDispatchers: SummaryUIDispatchers
  ) {}
}
