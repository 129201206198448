import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { GetMyBookings, GetMyBookingDays, CancelBookingReservation } from 'src/models/MyBookings';
import { GetMyMember } from 'src/models/IChatMember';
import { MY_BOOKING_USERS_REF } from 'src/models/constants';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
export class MyBookingsEffects {
    constructor(actions$, _uiDispatchers, _socketGateway) {
        this.actions$ = actions$;
        this._uiDispatchers = _uiDispatchers;
        this._socketGateway = _socketGateway;
        this.requestMyBookings = this.actions$.pipe(ofType("REQUEST_MY_BOOKINGS" /* REQUEST_MY_BOOKINGS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetMyBookings(action.chatID, action.date, action.date));
        }));
        this.requestMyBookingDays = this.actions$.pipe(ofType("REQUEST_MY_BOOKING_DAYS" /* REQUEST_MY_BOOKING_DAYS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetMyBookingDays(action.chatID, action.startDate, action.endDate));
        }));
        this.cancelReservation = this.actions$.pipe(ofType("CANCEL_MY_BOOKING_RESERVATION" /* CANCEL_MY_BOOKING_RESERVATION */), map((action) => {
            this._socketGateway.sendSocketMessage(new CancelBookingReservation(action.chat_id, action.user_id, [action.reference], action.date, action.time));
        }));
        this.afterReceivingMyBookings = this.actions$.pipe(ofType("RECEIVE_MY_BOOKINGS" /* RECEIVE_MY_BOOKINGS */), map((action) => {
            if (action.myBookings &&
                action.myBookings.data &&
                action.myBookings.data.length > 0) {
                this._socketGateway.sendSocketMessage(new GetMyMember(action.myBookings.data.map(slot => slot.user_id), MY_BOOKING_USERS_REF));
            }
        }));
        this.receiveError = this.actions$.pipe(ofType("RECEIVED_MY_BOOKINGS_ERROR" /* RECEIVED_MY_BOOKINGS_ERROR */), map((action) => {
            this._uiDispatchers.showPopup(SERVER_ERROR_MESSAGES[action.errorNumber], 'Cannot cancel');
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyBookingsEffects.prototype, "requestMyBookings", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyBookingsEffects.prototype, "requestMyBookingDays", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyBookingsEffects.prototype, "cancelReservation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyBookingsEffects.prototype, "afterReceivingMyBookings", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyBookingsEffects.prototype, "receiveError", void 0);
