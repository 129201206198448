import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { MessageTypes } from 'src/models/constants';
import { MediaCenterService } from '../media-center.service';

@Component({
  selector: 'app-media-center-file',
  templateUrl: './media-center-file.component.html',
  styleUrls: ['./media-center-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterFileComponent implements OnInit {
  @Input() url: string;
  @Input() fileName: string;
  @Input() localMedia: string;
  @Input() progress: number;
  @Input() mediaStatus: string;
  @Input() fileSize: number;

  mediaType = MessageTypes.AUDIO;
  showCover = false;

  constructor(private _mediaCenterService: MediaCenterService) {}

  ngOnInit() {
    if (!this.url) {
      this.showCover = true;
    }
  }

  get media() {
    return this._mediaCenterService.getMedia(this.localMedia);
  }

  hideCover() {
    this.showCover = false;
  }
}
