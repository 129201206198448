import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/messages/message.dispatchers";
export class TagsService {
    constructor(_storeSelector, _messageDispatchers) {
        this._storeSelector = _storeSelector;
        this._messageDispatchers = _messageDispatchers;
        // this._messageDispatchers.listTags();
    }
    getTagsForUser(tags) {
        return tags.map(val => val.id);
    }
    // CRUD Operations
    createTag(tag) {
        this._messageDispatchers.sendingCreateTag(tag);
    }
    deleteTag(tag) {
        this._messageDispatchers.sendingDeleteTag(tag);
    }
    setMemberTags(memberId, tags) {
        this._messageDispatchers.sendingMemberTags(memberId, tags);
    }
    // Selectors
    get selectedChat$() {
        return this._storeSelector.getSelectedChat$();
    }
    get selectedChatTags$() {
        return this._storeSelector.selectedChatTags$;
    }
    getSelectedChatMemberTag$(tags) {
        return this._storeSelector.getFilterTagsByIds$(tags);
    }
    getTagsListByID(chatID) {
        this._messageDispatchers.listTags(chatID);
    }
    getChatTagsByChatID$(chatID) {
        return this._storeSelector.getChatTagsByChatID$(chatID);
    }
}
TagsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagsService_Factory() { return new TagsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.MessageDispatchers)); }, token: TagsService, providedIn: "root" });
