<div class="media-center-photo">
  <app-media-center-loading-cover
    *ngIf="showCover && localCoverStatus"
    [progress]="progress"
    [size]="fileSize"
    [status]="mediaStatus"
    [type]="mediaType"
    (hideMediaCover)="hideCover()"
  >
  </app-media-center-loading-cover>
  <video class="media-center-photo-img" [src]="media"></video>
</div>
