import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { UiService } from '../../core/ui.service';
import { HelperService } from '../../core/helper.service';
import { MainChat } from '../../../models/MainChat';
import { MessageTypes, NO_MIC_FOUND } from '../../../models/constants';
import { ISelectedFiles } from '../../../models/ISelectedFiles';
import { IMessage } from 'src/models/IMessage';
import { MessageControls } from 'src/models/MessageParts';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { AppInfo } from 'src/models/ChannelAppConfig';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBoxComponent implements OnInit, OnChanges {
  @ViewChild('textBox', { static: false }) textBox: ElementRef;
  @ViewChild('dropdownEmoji', { static: false }) dropdownEmoji: ElementRef;

  @Input() selectedChat: MainChat;
  @Input() selectedMessage: IMessage;
  @Input() selectedUserId: string;
  @Input() groupReplies: boolean;
  @Input() replyToAdmin: boolean;
  @Input() talkToAdminBot: boolean;
  @Input() isScreenBot = false;
  @Input() isScreenMessages = false;
  @Input() show = true;
  @Input() deepLink: string;
  index = 0;
  isEmojiKeyboardOpen = false;
  isRecord = false;

  rangeIndex: number;
  showSend = false;

  resizeToHeight = 0;
  resizeToWidth = 0;
  selectedFile: ISelectedFiles;

  showUsers = false;
  constructor(
    public uiService: UiService,
    public layoutService: LayoutService,
    private elemRef: ElementRef,
    private _helperService: HelperService
  ) {}

  @HostListener('document:keydown', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (
      this.textBox &&
      this._helperService.clickedComponent === this.elemRef.nativeElement
    ) {
      if (event.keyCode === 13 && !event.shiftKey) {
        this._helperService.cancelEvent(event);
        this.sendMessage();
      } else if (event.keyCode === 8 || event.keyCode === 46) {
        const htmlContent = this.textBox.nativeElement;
        if (
          (htmlContent.textContent.length === 0 &&
            htmlContent.innerHTML.length === 0) ||
          htmlContent.innerHTML === '<br>'
        ) {
          this.textBox.nativeElement.textContent = '';
        }
      } else {
        // this.setFocus();
      }
    }
  }

  ngOnInit() {
    this._helperService.clickedComponent = this.elemRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['selectedChat'];
    if (chng && this.textBox) {
      if (chng.currentValue && !chng.previousValue) {
        this.textBox.nativeElement.innerHTML = chng.currentValue
          .lastTypedMessage
          ? chng.currentValue.lastTypedMessage
          : '';
      } else if (chng.previousValue && !chng.currentValue) {
        this.uiService.deselectChat(
          chng.previousValue,
          this.textBox.nativeElement.innerHTML
        );
      } else if (
        chng.currentValue &&
        chng.currentValue.id !== chng.previousValue.id
      ) {
        this.toggleUsers(false);
        this.uiService.deselectChat(
          chng.previousValue,
          this.textBox.nativeElement.innerHTML
        );
        this.textBox.nativeElement.innerHTML = chng.currentValue
          .lastTypedMessage
          ? chng.currentValue.lastTypedMessage
          : '';
      }
    }
  }

  sendMessage() {
    this.isEmojiKeyboardOpen = false;
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        if (!this.talkToAdminBot) {
          this.uiService.sendTextMessage(
            this.selectedChat,
            this.selectedMessage,
            this.selectedUserId,
            textToSend
          );
        } else {
          let misc: MessageControls = {};
          misc = {
            ...misc,
            chat_settings: 1
          };
          this.uiService.sendTextMessage(
            this.selectedChat,
            this.selectedMessage,
            this.selectedUserId,
            textToSend,
            misc
          );
        }
        this.textBox.nativeElement.textContent = '';
        this.showSend = false;
      }
    }
  }

  setFocus() {
    if (this.textBox) {
      this.textBox.nativeElement.focus();
      // const range = new Range();

      // range.setStartAfter(this.textBox.nativeElement.lastChild);
      // document.getSelection().removeAllRanges();
      // document.getSelection().addRange(range);
      // this._helperService.restoreSelection(this.index);
    }
  }

  emoijNotify($event: string) {
    this.setFocus();
    // this._helperService.restoreSelection(this.index);
    this._helperService.insertEmojiAtCursor($event);
  }

  onRichPaste(e) {
    const cData = (e.originalEvent || e).clipboardData;
    const items = (cData && cData.items) || [];
    let text;
    for (let i = 0; i < items.length; i++) {
      // paste image
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        this.sendMedia({
          localFile: file,
          type: MessageTypes.PHOTO
        });
      }
      if (items[i].kind === 'file') {
        e.preventDefault();
        return true;
      }
    }

    try {
      text = cData.getData('text/plain');
    } catch (e) {
      return true;
    }
    if (text.length) {
      const textAfter = this._helperService.convertUnifiedAndColonsToEmojiSpan(
        this._helperService.getMultiLine(text)
      );
      document.execCommand('insertHTML', false, textAfter);
      return this._helperService.cancelEvent(e);
    }
    return true;
  }

  lastfocus() {
    this.index = this._helperService.saveSelection();
  }

  keyUp(event) {
    this.index = this._helperService.saveSelection();
  }

  sendMedia(event: ISelectedFiles) {
    if (event.localFile && event.type !== 'photo') {
      this.uiService.getBlobUrl(event);
    } else {
      this.resizeToHeight = 0;
      this.resizeToWidth = 0;
      // if (event.localFile.size > 250000) {
      if (event.height >= event.width) {
        if (event.height > 512) {
          this.resizeToHeight = 512;
        } else {
          this.resizeToHeight = event.height;
        }
      } else {
        if (event.width > 512) {
          this.resizeToWidth = 512;
        } else {
          this.resizeToWidth = event.width;
        }
      }
      this.selectedFile = event;
      // } else {
      //   this.resetSelectedType();
      //   this.uiService.getBlobUrl(event);
      // }
    }
  }

  sendVoiceNote($event: { file: File; url: string }) {
    this.uiService.sendMediaMessage(
      this.selectedChat,
      this.selectedMessage,
      this.selectedUserId,
      $event.file,
      MessageTypes.VOICE,
      null,
      $event.url
    );
  }

  toggleEmojiKeyboard($event): void {
    this.isEmojiKeyboardOpen = $event;
  }

  startRecordEvent($event) {
    this.isRecord = $event;
  }
  micNotFoundEvent() {
    this.uiService.showErrorToast(NO_MIC_FOUND);
  }

  onFocus(e) {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      this.showSend = true;
    } else {
      this.showSend = false;
    }
    const text = this.textBox.nativeElement.innerHTML.match(/@[^~]*/g);
    if (text && text.length > 0) {
      this.toggleUsers(true);
    }
  }

  toggleUsers(event) {
    this.showUsers = event;
  }

  imageSelectedEvent(event: ISelectedFiles) {
    const file = {
      localFile: event.localFile,
      type: 'photo'
    };

    if (event.localFile) {
      this.uiService.getBlobUrl(file);
      this.closeImageCrroper();
    }
  }

  addMinction(item) {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (this.deepLink) {
      const valueArr = this.deepLink;
      const createElment = `<a href="${valueArr}://user?id=${
        item.user_id
      }&name=${encodeURIComponent(item.name)}">~${item.name}</a>`;
      this.toggleUsers(false);
      this.textBox.nativeElement.innerHTML = htmlText.replace(
        /@[^~]*/g,
        createElment
      );
    }
  }

  closeImageCrroper() {
    this.selectedFile = null;
  }
}
