/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-pagename.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./navbar-pagename.component";
import * as i4 from "../layout.service";
var styles_NavbarPagenameComponent = [i0.styles];
var RenderType_NavbarPagenameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarPagenameComponent, data: {} });
export { RenderType_NavbarPagenameComponent as RenderType_NavbarPagenameComponent };
function View_NavbarPagenameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h1", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.appInfo == null) ? null : _co.appInfo.channel_name); _ck(_v, 0, 0, currVal_0); }); }
function View_NavbarPagenameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSectionTitle(_v.parent.context.ngIf); _ck(_v, 0, 0, currVal_0); }); }
function View_NavbarPagenameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavbarPagenameComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["sectionTitle", 2]], null, 0, null, View_NavbarPagenameComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf === _co.uiSections.HOME); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NavbarPagenameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavbarPagenameComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.layoutService == null) ? null : _co.layoutService.uiCollection$)))) == null) ? null : tmp_0_0.section); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NavbarPagenameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar-pagename", [], null, null, null, View_NavbarPagenameComponent_0, RenderType_NavbarPagenameComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavbarPagenameComponent, [i4.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarPagenameComponentNgFactory = i1.ɵccf("app-navbar-pagename", i3.NavbarPagenameComponent, View_NavbarPagenameComponent_Host_0, { currentPage: "currentPage", appInfo: "appInfo" }, {}, []);
export { NavbarPagenameComponentNgFactory as NavbarPagenameComponentNgFactory };
