import { Actions } from './instantPage.actions';
import * as actions from './instantPage.actions';
import { MyPage } from 'src/models/MyPageConfig';

const INITIAL_STATE = [];

export function instantPageReducer(
  state: MyPage[] = INITIAL_STATE,
  action: Actions
): MyPage[] {
  switch (action.type) {
    case actions.InstantPageActionTypes.RECEIVE_INSTANT_PAGES: {
      return action.rcvdPages;
    }
    // should add the page if not existing or update it by ID
    case actions.InstantPageActionTypes.RECEIVE_INSTANT_PAGE_DETAILS: {
      if (!state.find(myPage => myPage.id == action.rcvdPage.id)) {
        return [action.rcvdPage, ...state];
      } else {
        return state.map(page => {
          if (page.id == action.rcvdPage.id) {
            let updatedPage: MyPage = {};
            updatedPage = { ...page, ...action.rcvdPage };
            return updatedPage;
          }
          return page;
        });
      }
    }
    case actions.InstantPageActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
