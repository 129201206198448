import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import {
  FeatureItem,
  FeatureAction,
  TabTypes
} from './../../../../../../../../models/constants';
import { FeaturesSublistComponent } from './../../../dragable-features/features-sublist/features-sublist.component';
import { AppMgmtService } from '../../../../appMgmt.service';
import { BotsService } from 'src/app/+merchant/+dashboard/bots/bots.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from 'src/app/core/ui.service';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';
@Component({
  selector: 'app-main-item-feature-container',
  templateUrl: './main-item-feature-container.component.html',
  styleUrls: ['./main-item-feature-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainItemFeatureContainerComponent implements OnInit, OnChanges {
  @Input() tabContainer: any;
  activeFeature: FeatureItem = {};
  adjustvideocallwindow: FeatureItem = {};
  switchcalltobluetooth: FeatureItem = {};
  // stickynote: FeatureItem = {};
  multipleadministrators: FeatureItem = {};
  callnotifications: FeatureItem = {};
  features: FeatureAction[];
  tabTypes = TabTypes;
  appupdates: FeatureItem = {};
  blockedusers: FeatureItem = {};
  cameraaccess: FeatureItem = {};
  captcha: FeatureItem = {};
  countrycodeblocking: FeatureItem = {};
  detaileddownloadhistory: FeatureItem = {};
  disableads: FeatureItem = {};
  downloadpermission: FeatureItem = {};
  eventregistration: FeatureItem = {};
  fingerprintpaymentauthorization: FeatureItem = {};
  geogence: FeatureItem = {};
  videosupport: FeatureItem = {};
  eventregisteration: FeatureItem = {};
  mediacaption: FeatureItem = {};
  geolocationreporting: FeatureItem = {};
  helpcenter: FeatureItem = {};
  banusers: FeatureItem = {};
  invitefriends: FeatureItem = {};
  ipblocker: FeatureItem = {};
  leechprotection: FeatureItem = {};
  hamburgermenu: FeatureItem = {};
  location: FeatureItem = {};
  lostdeviceprotection: FeatureItem = {};
  passcodelock: FeatureItem = {};
  passwordencryption: FeatureItem = {};
  passwordprotectedpages: FeatureItem = {};
  passwordstrength: FeatureItem = {};
  phoneverification: FeatureItem = {};
  privacysetting: FeatureItem = {};
  privatechat: FeatureItem = {};
  profile: FeatureItem = {};
  recenthistory: FeatureItem = {};
  sendamessage: FeatureItem = {};
  signup: FeatureItem = {};
  storelocator: FeatureItem = {};
  twitterlogin: FeatureItem = {};
  automatedreminderemails: FeatureItem = {};
  dailyschedulenotifications: FeatureItem = {};
  desktopnotifications: FeatureItem = {};
  emailnotifications: FeatureItem = {};
  linkedaccounts: FeatureItem = {};
  locationbasedalerts: FeatureItem = {};
  notifications: FeatureItem = {};
  pushnotifications: FeatureItem = {};
  audiomusicnotifications: FeatureItem = {};
  audiocall: FeatureItem = {};
  audiolibrary: FeatureItem = {};
  audioslidershow: FeatureItem = {};
  bassboost: FeatureItem = {};
  callrecording: FeatureItem = {};
  playlist: FeatureItem = {};
  remoteaudioandvideo: FeatureItem = {};
  soundmixing: FeatureItem = {};
  soundsettings: FeatureItem = {};
  voicememos: FeatureItem = {};
  accountcreation: FeatureItem = {};
  adancedsearch: FeatureItem = {};
  authenticationinwear: FeatureItem = {};
  contacts: FeatureItem = {};
  datasaving: FeatureItem = {};
  discoversettings: FeatureItem = {};
  displaysettings: FeatureItem = {};
  facebooklogin: FeatureItem = {};
  featuresettings: FeatureItem = {};
  filecompression: FeatureItem = {};
  forgetpassword: FeatureItem = {};
  freetrial: FeatureItem = {};
  googleassistant: FeatureItem = {};
  hmburgermenu: FeatureItem = {};
  internetaccess: FeatureItem = {};
  keywordsearch: FeatureItem = {};
  landingpage: FeatureItem = {};
  linkedinlogin: FeatureItem = {};
  rolespermissions: FeatureItem = {};
  livechat: FeatureItem = {};
  logodesign: FeatureItem = {};
  mmssettings: FeatureItem = {};
  multilevelapprovalpath: FeatureItem = {};
  multiplebrowsersupport: FeatureItem = {};
  openapi: FeatureItem = {};
  phonelogin: FeatureItem = {};
  promocodes: FeatureItem = {};
  savesearch: FeatureItem = {};
  sdcard: FeatureItem = {};
  search: FeatureItem = {};
  shufflestations: FeatureItem = {};
  signin: FeatureItem = {};
  signinviaemail: FeatureItem = {};
  signinviainstagram: FeatureItem = {};
  signuploginmodule: FeatureItem = {};
  sorting: FeatureItem = {};
  splashscreen: FeatureItem = {};
  streetpeek: FeatureItem = {};
  termsandconditionpage: FeatureItem = {};
  theme: FeatureItem = {};
  twofactorauthentication: FeatureItem = {};
  useravatar: FeatureItem = {};
  userstatus: FeatureItem = {};
  activityfeed: FeatureItem = {};
  addfriends: FeatureItem = {};
  analytics: FeatureItem = {};
  sticker: FeatureItem = {};
  audiovideomessenger: FeatureItem = {};
  broadcastmessage: FeatureItem = {};
  chat: FeatureItem = {};
  chatbackuprestore: FeatureItem = {};
  chatbot: FeatureItem = {};
  collagecreator: FeatureItem = {};
  comments: FeatureItem = {};
  contactsharing: FeatureItem = {};
  conversationthreading: FeatureItem = {};
  createsketch: FeatureItem = {};
  emaillogin: FeatureItem = {};
  emojis: FeatureItem = {};
  eventregisstration: FeatureItem = {};
  facebookfeedsdisplay: FeatureItem = {};
  fanwall: FeatureItem = {};
  filteritems: FeatureItem = {};
  flashinstantmessenger: FeatureItem = {};
  followers: FeatureItem = {};
  friendlist: FeatureItem = {};
  gallery: FeatureItem = {};
  gifs: FeatureItem = {};
  groupchat: FeatureItem = {};
  groupvideocall: FeatureItem = {};
  groups: FeatureItem = {};
  hashtag: FeatureItem = {};
  hashtaggenerator: FeatureItem = {};
  icloudsharing: FeatureItem = {};
  inappgifting: FeatureItem = {};
  instantgiftcards: FeatureItem = {};
  karmapoints: FeatureItem = {};
  likeapost: FeatureItem = {};
  linkshare: FeatureItem = {};
  linksocialaccounts: FeatureItem = {};
  livefeedcapture: FeatureItem = {};
  livefeedscheduling: FeatureItem = {};
  livestreaming: FeatureItem = {};
  mentions: FeatureItem = {};
  muteunmute: FeatureItem = {};
  nearbyfriends: FeatureItem = {};
  postcreation: FeatureItem = {};
  recognize: FeatureItem = {};
  repost: FeatureItem = {};
  share: FeatureItem = {};
  sharecalendars: FeatureItem = {};
  slideshowcreator: FeatureItem = {};
  sms: FeatureItem = {};
  smssettings: FeatureItem = {};
  storiesfeature: FeatureItem = {};
  subscriptions: FeatureItem = {};
  trending: FeatureItem = {};
  twitterfeedsdisplay: FeatureItem = {};
  universallinks: FeatureItem = {};
  uploadmedia: FeatureItem = {};
  upvotedownvote: FeatureItem = {};
  useraccount: FeatureItem = {};
  videoconversation: FeatureItem = {};
  multiplecurrencysupport: FeatureItem = {};
  payment: FeatureItem = {};
  savedcards: FeatureItem = {};
  automaticrenewals: FeatureItem = {};
  centralizedbilling: FeatureItem = {};
  collecttransactionfees: FeatureItem = {};
  digitalsignature: FeatureItem = {};
  donationpayments: FeatureItem = {};
  inapppurchase: FeatureItem = {};
  invoicebilling: FeatureItem = {};
  multitieredpricing: FeatureItem = {};
  paymentadmin: FeatureItem = {};
  premiumplancomparison: FeatureItem = {};
  refundmanagement: FeatureItem = {};
  restorepurchase: FeatureItem = {};
  splitpayments: FeatureItem = {};
  subscriptionbilling: FeatureItem = {};
  taxcalculator: FeatureItem = {};
  wallet: FeatureItem = {};
  googlelogin: FeatureItem = {};
  reviews: FeatureItem = {};
  advancedinsights: FeatureItem = {};
  crm3rdpartyintegration: FeatureItem = {};
  polling: FeatureItem = {};
  reviewprompt: FeatureItem = {};
  surveys: FeatureItem = {};
  affiliateurl: FeatureItem = {};
  bidding: FeatureItem = {};
  coupons: FeatureItem = {};
  dashboard: FeatureItem = {};
  discountoffers: FeatureItem = {};
  favorites: FeatureItem = {};
  freecredits: FeatureItem = {};
  guestlogin: FeatureItem = {};
  loyaltysystem: FeatureItem = {};
  printdata: FeatureItem = {};
  shippingaddressvalidation: FeatureItem = {};
  shippingchargecalculator: FeatureItem = {};
  shoppingcart: FeatureItem = {};
  storecredits: FeatureItem = {};
  wishlist: FeatureItem = {};
  zipcodessearch: FeatureItem = {};
  gamesscore: FeatureItem = {};
  inappsoundsvibration: FeatureItem = {};
  leaderboard: FeatureItem = {};
  multiplayersupport: FeatureItem = {};
  physicsengine: FeatureItem = {};
  amazonpayintegration: FeatureItem = {};
  apiintegration: FeatureItem = {};
  applepayintegration: FeatureItem = {};
  bhimupi: FeatureItem = {};
  bluethoothintegration: FeatureItem = {};
  customeadvertisments: FeatureItem = {};
  docusignintegration: FeatureItem = {};
  freechargeintegration: FeatureItem = {};
  freshdeskintegration: FeatureItem = {};
  gsuiteintegration: FeatureItem = {};
  gettyimageintegration: FeatureItem = {};
  googleadsenseintegration: FeatureItem = {};
  googleassistantintegration: FeatureItem = {};
  googlewalletintegration: FeatureItem = {};
  homecontroloptions: FeatureItem = {};
  hubsportintegration: FeatureItem = {};
  integratewearuilibrary: FeatureItem = {};
  intercomeintegration: FeatureItem = {};
  mailchimpintegration: FeatureItem = {};
  mailinglistintegration: FeatureItem = {};
  mixpanelanalyticsintegration: FeatureItem = {};
  mobiwikintegration: FeatureItem = {};
  neuralnetapiforandroid: FeatureItem = {};
  newsintegrtation: FeatureItem = {};
  onlinebookingintegration: FeatureItem = {};
  outlookintegration: FeatureItem = {};
  payrollintegration: FeatureItem = {};
  paytmwalletintegration: FeatureItem = {};
  quickbooksintegration: FeatureItem = {};
  radiointegration: FeatureItem = {};
  rssfeeds: FeatureItem = {};
  rupayintegration: FeatureItem = {};
  salesforceintegration: FeatureItem = {};
  scheduleonceintegration: FeatureItem = {};
  sendyintegration: FeatureItem = {};
  simpledisqusintegration: FeatureItem = {};
  slackintegration: FeatureItem = {};
  syncswithevernote: FeatureItem = {};
  twiliovoiceintegration: FeatureItem = {};
  weatherintegration: FeatureItem = {};
  youtubeintegration: FeatureItem = {};
  barcodesscanner: FeatureItem = {};
  itemavailability: FeatureItem = {};
  itemgrouper: FeatureItem = {};
  maps: FeatureItem = {};
  mapsettings: FeatureItem = {};
  ordersummary: FeatureItem = {};
  pricingengine: FeatureItem = {};
  qrcodes: FeatureItem = {};
  requestmanagement: FeatureItem = {};
  rfid: FeatureItem = {};
  settings: FeatureItem = {};
  summarycard: FeatureItem = {};
  billableourstracking: FeatureItem = {};
  candidatemanagement: FeatureItem = {};
  certificationtracking: FeatureItem = {};
  demoaccount: FeatureItem = {};
  formapprovalworkflow: FeatureItem = {};
  leadmanagement: FeatureItem = {};
  advancedsearch: FeatureItem = {};
  organisationhierarchy: FeatureItem = {};
  switchaccounts: FeatureItem = {};
  timesheetmanagement: FeatureItem = {};
  usergroups: FeatureItem = {};
  archive: FeatureItem = {};
  audioequalizer: FeatureItem = {};
  bookmarkarticlesimages: FeatureItem = {};
  hdvideoformats: FeatureItem = {};
  imagepicker: FeatureItem = {};
  imagethumbnailgeneration: FeatureItem = {};
  imagesmanagement: FeatureItem = {};
  photofilter: FeatureItem = {};
  photos: FeatureItem = {};
  preview: FeatureItem = {};
  rawformatprocessing: FeatureItem = {};
  startvideochat: FeatureItem = {};
  subtitles: FeatureItem = {};
  videoautoplay: FeatureItem = {};
  videobackgrounds: FeatureItem = {};
  videocall: FeatureItem = {};
  videoeditingtools: FeatureItem = {};
  videolibrary: FeatureItem = {};
  videomanagement: FeatureItem = {};
  videotrimming: FeatureItem = {};
  videos: FeatureItem = {};
  webinars: FeatureItem = {};
  annotations: FeatureItem = {};
  appointmentmanagement: FeatureItem = {};
  conferencecall: FeatureItem = {};
  convertfile: FeatureItem = {};
  datastorage: FeatureItem = {};
  donotdisturb: FeatureItem = {};
  downloadoptions: FeatureItem = {};
  elasticsearch: FeatureItem = {};
  googlecalendarsync: FeatureItem = {};
  icalendarsupport: FeatureItem = {};
  kanbanboard: FeatureItem = {};
  languageoptions: FeatureItem = {};
  navigationmenu: FeatureItem = {};
  offlinebrowsing: FeatureItem = {};
  onboardingguide: FeatureItem = {};
  prioritizeconversation: FeatureItem = {};
  recurringappointments: FeatureItem = {};
  screenshoteditor: FeatureItem = {};
  splitviewinterface: FeatureItem = {};
  syncoptions: FeatureItem = {};
  volumecontrol: FeatureItem = {};
  worldclock: FeatureItem = {};
  xmlcsvtemplatebuilder: FeatureItem = {};
  abusedetector: FeatureItem = {};
  answerbot: FeatureItem = {};
  barcodesettings: FeatureItem = {};
  contexttagging: FeatureItem = {};
  coreml: FeatureItem = {};
  duplicatedetection: FeatureItem = {};
  emotiondetection: FeatureItem = {};
  facetagging: FeatureItem = {};
  imagecategorisation: FeatureItem = {};
  keywordextractor: FeatureItem = {};
  languagedetection: FeatureItem = {};
  multiplegesturerecognizer: FeatureItem = {};
  productrecommendation: FeatureItem = {};
  repeatingtasks: FeatureItem = {};
  sentimentanalysis: FeatureItem = {};
  targetedfeed: FeatureItem = {};
  textcomparison: FeatureItem = {};
  visualsearch: FeatureItem = {};
  addcreditcard: FeatureItem = {};
  baselinereporting: FeatureItem = {};
  categoriessubcategories: FeatureItem = {};
  ideamanagement: FeatureItem = {};
  notificationssettings: FeatureItem = {};
  projectprotfolio: FeatureItem = {};
  projectreporting: FeatureItem = {};
  projecttemplates: FeatureItem = {};
  qatracker: FeatureItem = {};
  adhocreporting: FeatureItem = {};
  admanager: FeatureItem = {};
  budgetingforecasting: FeatureItem = {};
  comprehensivereporting: FeatureItem = {};
  dailyemailsignup: FeatureItem = {};
  deeplinking: FeatureItem = {};
  emaillists: FeatureItem = {};
  events: FeatureItem = {};
  graphicalcharts: FeatureItem = {};
  inventorytrendanalysis: FeatureItem = {};
  locallistingads: FeatureItem = {};
  pledgetracking: FeatureItem = {};
  promotecontent: FeatureItem = {};
  referrals: FeatureItem = {};
  salesreporting: FeatureItem = {};
  searchtermsreports: FeatureItem = {};
  statisticsreports: FeatureItem = {};
  trafficsources: FeatureItem = {};
  videoads: FeatureItem = {};
  visualanalytics: FeatureItem = {};
  automatedprioritization: FeatureItem = {};
  devicemanagement: FeatureItem = {};
  leavetracker: FeatureItem = {};
  prioritize: FeatureItem = {};
  scheduling: FeatureItem = {};
  taskallocator: FeatureItem = {};
  taskblocker: FeatureItem = {};
  taskgroups: FeatureItem = {};
  tasklist: FeatureItem = {};
  taskviews: FeatureItem = {};
  teambuilder: FeatureItem = {};
  timetracker: FeatureItem = {};
  accountscoreranking: FeatureItem = {};
  accounttransfertool: FeatureItem = {};
  activitylog: FeatureItem = {};
  addressmanagement: FeatureItem = {};
  approvalprocesscontrol: FeatureItem = {};
  customizableusersubscriptions: FeatureItem = {};
  dailylimit: FeatureItem = {};
  dashboardguests: FeatureItem = {};
  livechatsummary: FeatureItem = {};
  proposalgeneration: FeatureItem = {};
  remoteadministration: FeatureItem = {};
  rolespremissions: FeatureItem = {};
  servicespecificsettingsadmin: FeatureItem = {};
  singlesignonsso: FeatureItem = {};
  agerestriction: FeatureItem = {};
  audittrail: FeatureItem = {};
  automatedpublishing: FeatureItem = {};
  blogimporting: FeatureItem = {};
  blogpostsmanagement: FeatureItem = {};
  bulkcommentmanagement: FeatureItem = {};
  bulkuploading: FeatureItem = {};
  catalog: FeatureItem = {};
  communityforum: FeatureItem = {};
  contentexporting: FeatureItem = {};
  contentflag: FeatureItem = {};
  contentmoderation: FeatureItem = {};
  customform: FeatureItem = {};
  dataimportexportcsv: FeatureItem = {};
  datamigration: FeatureItem = {};
  documentation: FeatureItem = {};
  documentdistribution: FeatureItem = {};
  documentpreviewing: FeatureItem = {};
  download: FeatureItem = {};
  dynamiccontent: FeatureItem = {};
  fileattachment: FeatureItem = {};
  importphotoshopdocumentspsd: FeatureItem = {};
  library: FeatureItem = {};
  manageblogcomments: FeatureItem = {};
  mergingcontent: FeatureItem = {};
  multipageforms: FeatureItem = {};
  notes: FeatureItem = {};
  paidcontent: FeatureItem = {};
  pdfconversion: FeatureItem = {};
  readermode: FeatureItem = {};
  spellcheck: FeatureItem = {};
  testimonials: FeatureItem = {};
  travelguide: FeatureItem = {};
  wordlookup: FeatureItem = {};
  realtimeupdates: FeatureItem = {};
  tutorials: FeatureItem = {};
  advancedclashmanagement: FeatureItem = {};
  // contextMenu: FeatureItem = {};
  customizedorderstatus: FeatureItem = {};
  deliveryestimator: FeatureItem = {};
  expensetracking: FeatureItem = {};
  expressdelivery: FeatureItem = {};
  orderdetailview: FeatureItem = {};
  reservations: FeatureItem = {};
  waitinglist: FeatureItem = {};
  whitelisingemails: FeatureItem = {};
  smartcategorization: FeatureItem = {};
  calllogdetail: FeatureItem = {};
  viewprofile: FeatureItem = {};
  switchvideocalltoaudiocall: FeatureItem = {};
  muteunmutemicrophone: FeatureItem = {};
  turnspeakeronoff: FeatureItem = {};
  switchcalltobluethooth: FeatureItem = {};
  connectingtone: FeatureItem = {};
  ringtone: FeatureItem = {};
  switchcamera: FeatureItem = {};
  adjustvediocallwindow: FeatureItem = {};
  ip6ip4callconnectivity: FeatureItem = {};
  callcancel: FeatureItem = {};
  multitaskingfeature: FeatureItem = {};
  callnotification: FeatureItem = {};
  lightnotification: FeatureItem = {};
  lastseen: FeatureItem = {};
  onlinestatus: FeatureItem = {};
  readreceipt: FeatureItem = {};
  sendtextmessagewithbackground: FeatureItem = {};
  sendfile: FeatureItem = {};
  deletechat: FeatureItem = {};
  copytextmessage: FeatureItem = {};
  editmessage: FeatureItem = {};
  recallmessage: FeatureItem = {};
  forwardmessage: FeatureItem = {};
  videostreaming: FeatureItem = {};
  mediacompression: FeatureItem = {};
  linkmetadatafetching: FeatureItem = {};
  sendinstantarticle: FeatureItem = {};
  locationsharing: FeatureItem = {};
  currentlocationsharing: FeatureItem = {};
  receivingshare: FeatureItem = {};
  viewuseravatar: FeatureItem = {};
  allowPublic: FeatureItem = {};
  mediacaptain: FeatureItem = {};
  commentcounter: FeatureItem = {};
  panusers: FeatureItem = {};
  blacklistuser: FeatureItem = {};
  invitefriendsviarequest: FeatureItem = {};
  multipleadminstrators: FeatureItem = {};
  invitationlink: FeatureItem = {};
  invitationqrcodes: FeatureItem = {};
  leavegroup: FeatureItem = {};
  reportabuse: FeatureItem = {};
  savetogallery: FeatureItem = {};
  pinconversation: FeatureItem = {};
  chatdashboard: FeatureItem = {};
  poststyle: FeatureItem = {};
  readcounter: FeatureItem = {};
  // talktoadmin: FeatureItem = {};
  commentdashboard: FeatureItem = {};
  stikynote: FeatureItem = {};
  invitetodownloadapp: FeatureItem = {};
  accountrecognition: FeatureItem = {};
  grouplist: FeatureItem = {};
  refresh: FeatureItem = {};
  startbot: FeatureItem = {};
  // postfooter: FeatureItem = {};
  // postfilter: FeatureItem = {};
  ticketRedeem: FeatureItem = {};
  ticketValidet: FeatureItem = {};
  googlesync: FeatureItem = {};
  geofence: FeatureItem = {};
  webview: FeatureItem = {};
  imageArray: string[] = [];
  subfeatures: FeatureItem[];
  featureTitle: string;
  featureDesc: string;
  defaultImg = ['./assets/img/chat-view.png'];
  featuresItems: FeatureItem[];
  featureId: number;

  imageConfg = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#ffffff', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: false, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-repeat',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt'
    },
    btnShow: {
      zoomIn: false,
      zoomOut: false,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: false,
      prev: false
    }
  };
  featuresSubList = new FeaturesSublistComponent(
    this._ref,
    this.appMgntService,
    this.botService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );
  constructor(
    private _ref: ChangeDetectorRef,
    public appMgntService: AppMgmtService,
    public botService: BotsService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {
    this.appupdates.icon = '';
    this.appupdates.title = 'App Updates';
    this.appupdates.subTitle =
      'A feature to view all the updates in the application';
    this.appupdates.image = '';
    this.appupdates.price = 1320;
    this.appupdates.duration = 2;
    this.appupdates.id = 10001;

    this.blockedusers.icon = '';
    this.blockedusers.title = 'Blocked Users';
    this.blockedusers.subTitle =
      'To stop users from viewing or using any application feature by adding them to the block list';
    this.blockedusers.image = '';
    this.blockedusers.price = 930;
    this.blockedusers.duration = 1;
    this.blockedusers.id = 10002;

    this.cameraaccess.icon = '';
    this.cameraaccess.title = 'Camera Access';
    this.cameraaccess.subTitle =
      'Users can take photos and videos via the application.';
    this.cameraaccess.image = '';
    this.cameraaccess.price = 1170;
    this.cameraaccess.duration = 0.5;
    this.cameraaccess.id = 10003;

    this.captcha.icon = 'icon-10004';
    this.captcha.title = 'Captcha';
    this.captcha.subTitle =
      'Captcha is a type of challenge-response test used to determine whether or not the user is a human';
    this.captcha.image = '';
    this.captcha.price = 480;
    this.captcha.duration = 0.5;
    this.captcha.id = 10004;

    this.countrycodeblocking.icon = 'icon-10005';
    this.countrycodeblocking.title = 'Country Code Blocking';
    this.countrycodeblocking.subTitle =
      'A feature to block IP and users from using your service from a particular county based on its country code';
    this.countrycodeblocking.image = '';
    this.countrycodeblocking.price = 1200;
    this.countrycodeblocking.duration = 1.5;
    this.countrycodeblocking.id = 10005;

    this.detaileddownloadhistory.icon = 'icon-10006';
    this.detaileddownloadhistory.title = 'Detailed download history';
    this.detaileddownloadhistory.subTitle =
      'A feature to add detailed download history that shows time, size, file location, file source, etc.';
    this.detaileddownloadhistory.image = '';
    this.detaileddownloadhistory.price = 900;
    this.detaileddownloadhistory.duration = 1;
    this.detaileddownloadhistory.id = 10006;

    this.disableads.icon = 'icon-10007';
    this.disableads.title = 'Disable Ads';
    this.disableads.subTitle = 'Allows the users to disable ads.';
    this.disableads.image = '';
    this.disableads.price = 480;
    this.disableads.duration = 0.5;
    this.disableads.id = 10007;

    this.downloadpermission.icon = 'icon-10008';
    this.downloadpermission.title = 'Download Permission';
    this.downloadpermission.subTitle =
      'Allows the users to set limits/permissions on the download of their content.';
    this.downloadpermission.image = '';
    this.downloadpermission.price = 900;
    this.downloadpermission.duration = 1;
    this.downloadpermission.id = 10008;

    this.fingerprintpaymentauthorization.icon = 'icon-10009';
    this.fingerprintpaymentauthorization.title =
      'Fingerprint Payment Authorization';
    this.fingerprintpaymentauthorization.subTitle =
      'A feature to implement fingerprint authentication method for the payment of your product';
    this.fingerprintpaymentauthorization.image = '';
    this.fingerprintpaymentauthorization.price = 1800;
    this.fingerprintpaymentauthorization.duration = 2;
    this.fingerprintpaymentauthorization.id = 10009;

    this.geofence.icon = '';
    this.geofence.title = 'Geofence';
    this.geofence.subTitle =
      'Sending alerts/notifications when a device enters a specified area. l';
    this.geofence.image = '';
    this.geofence.price = 900;
    this.geofence.duration = 1;
    this.geofence.id = 10010;

    this.geolocationreporting.icon = 'icon-10011';
    this.geolocationreporting.title = 'Geo-location reporting';
    this.geolocationreporting.subTitle =
      'A feature to add geolocation to get geographical information about a mobile phone or an Internet-connected computer.';
    this.geolocationreporting.image = '';
    this.geolocationreporting.price = 1200;
    this.geolocationreporting.duration = 1.5;
    this.geolocationreporting.id = 10011;

    this.helpcenter.icon = 'icon-10012';
    this.helpcenter.title = 'Help Center';
    this.helpcenter.subTitle =
      'A feature to add a help center for similar/ frequently questions asked about your product';
    this.helpcenter.image = '';
    this.helpcenter.price = 900;
    this.helpcenter.duration = 1;
    this.helpcenter.id = 10012;

    this.invitefriends.icon = 'icon-10013';
    this.invitefriends.title = 'Invite Friends';
    this.invitefriends.subTitle =
      'For users to invite their friends through a link of a group/channel created on your app';
    this.invitefriends.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Invite-Friends.webp';
    this.invitefriends.price = 900;
    this.invitefriends.duration = 1;
    this.invitefriends.id = 10013;

    this.ipblocker.icon = 'icon-10014';
    this.ipblocker.title = 'IP Blocker';
    this.ipblocker.subTitle =
      'A feature to block a particular IP or set of IP from accessing your service';
    this.ipblocker.image = '';
    this.ipblocker.price = 900;
    this.ipblocker.duration = 1;
    this.ipblocker.id = 10014;

    this.leechprotection.icon = 'icon-10015';
    this.leechprotection.title = 'Leech Protection';
    this.leechprotection.subTitle =
      'A feature to use the Leech Protection interface to set a maximum number of logins that can occur within a two-hour time limit.';
    this.leechprotection.image = '';
    this.leechprotection.price = 1800;
    this.leechprotection.duration = 2;
    this.leechprotection.id = 10015;

    this.location.icon = 'icon-10016';
    this.location.title = 'Location';
    this.location.subTitle =
      'A simple option to allow users to add and edit their location';
    this.location.image = '';
    this.location.price = 900;
    this.location.duration = 1;
    this.location.id = 10016;

    this.lostdeviceprotection.icon = 'icon-10017';
    this.lostdeviceprotection.title = 'Lost Device Protection';
    this.lostdeviceprotection.subTitle =
      'Allows app admin to clear all the files from the lost devices';
    this.lostdeviceprotection.image = '';
    this.lostdeviceprotection.price = 900;
    this.lostdeviceprotection.duration = 1;
    this.lostdeviceprotection.id = 10017;

    this.passcodelock.icon = 'icon-10018';
    this.passcodelock.title = 'Passcode lock';
    this.passcodelock.subTitle =
      'Ability to block access to the application by requiring a 4-digit PIN.';
    this.passcodelock.image = '';
    this.passcodelock.price = 480;
    this.passcodelock.duration = 0.5;
    this.passcodelock.id = 10018;

    this.passwordencryption.icon = '';
    this.passwordencryption.title = 'Password Encryption';
    this.passwordencryption.subTitle =
      'A feature to encrypt the password before saving in the application';
    this.passwordencryption.image = '';
    this.passwordencryption.price = 1320;
    this.passwordencryption.duration = 2;
    this.passwordencryption.id = 10019;

    this.passwordprotectedpages.icon = 'icon-10020';
    this.passwordprotectedpages.title = 'Password Protected Pages';
    this.passwordprotectedpages.subTitle =
      'A feature to encrypt the password before saving in the application';
    this.passwordprotectedpages.image = '';
    this.passwordprotectedpages.price = 1200;
    this.passwordprotectedpages.duration = 1.5;
    this.passwordprotectedpages.id = 10020;

    this.passwordstrength.icon = 'icon-10021';
    this.passwordstrength.title = 'Password Strength';
    this.passwordstrength.subTitle =
      'A feature to create password-protected pages to limit access and secure data.';
    this.passwordstrength.image = '';
    this.passwordstrength.price = 1200;
    this.passwordstrength.duration = 1.5;
    this.passwordstrength.id = 10021;

    this.phoneverification.icon = 'icon-10022';
    this.phoneverification.title = 'Phone Verification';
    this.phoneverification.subTitle =
      "Send a verification code to the user's device before they can signup/login.";
    this.phoneverification.image = '';
    this.phoneverification.price = 480;
    this.phoneverification.duration = 0.5;
    this.phoneverification.id = 10022;

    this.privacysetting.icon = 'icon-10023';
    this.privacysetting.title = 'Privacy setting';
    this.privacysetting.subTitle =
      'A feature that indicates the strength of any password of a user at the time of password creation';
    this.privacysetting.image = '';
    this.privacysetting.price = 900;
    this.privacysetting.duration = 1;
    this.privacysetting.id = 10023;

    this.privatechat.icon = 'icon-10024';
    this.privatechat.title = 'Private Chat';
    this.privatechat.subTitle =
      'Ability to have an end-to-end encrypted conversation with other users';
    this.privatechat.image = '';
    this.privatechat.price = 900;
    this.privatechat.duration = 1;
    this.privatechat.id = 10024;

    this.profile.icon = 'icon-10025';
    this.profile.title = 'Profile';
    this.profile.subTitle =
      'An editable profile view that contains basic information about you, which other users can see.';
    this.profile.image = '';
    this.profile.price = 900;
    this.profile.duration = 1;
    this.profile.id = 10025;

    this.recenthistory.icon = 'icon-10026';
    this.recenthistory.title = 'Recent History';
    this.recenthistory.subTitle =
      'Allows users to access the recently searched items.';
    this.recenthistory.image = '';
    this.recenthistory.price = 480;
    this.recenthistory.duration = 0.5;
    this.recenthistory.id = 10026;

    this.sendamessage.icon = '';
    this.sendamessage.title = 'Send a message';
    this.sendamessage.subTitle =
      'For users to send messages, whether in one-to-one chats or in groups';
    this.sendamessage.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Send-a-message.webp';
    this.sendamessage.price = 930;
    this.sendamessage.duration = 1;
    this.sendamessage.id = 10027;

    this.signup.icon = '';
    this.signup.title = 'Sign up';
    this.signup.subTitle = 'Registration';
    this.signup.image = '';
    this.signup.price = 810;
    this.signup.duration = 0.5;
    this.signup.id = 10028;

    this.storelocator.icon = 'icon-10029';
    this.storelocator.title = 'Store Locator';
    this.storelocator.subTitle =
      'Allows app users to find locations of the business within proximity of an address or postal code or within a selected region.';
    this.storelocator.image = '';
    this.storelocator.price = 900;
    this.storelocator.duration = 1;
    this.storelocator.id = 10029;

    this.twitterlogin.icon = 'icon-10030';
    this.twitterlogin.title = 'Twitter Login';
    this.twitterlogin.subTitle = 'Login or register using Twitter account. ';
    this.twitterlogin.image = '';
    this.twitterlogin.price = 480;
    this.twitterlogin.duration = 0.5;
    this.twitterlogin.id = 10030;

    this.automatedreminderemails.icon = 'icon-10031';
    this.automatedreminderemails.title = 'Automated Reminder Emails';
    this.automatedreminderemails.subTitle =
      'Support for sending automated reminder emails to the users.';
    this.automatedreminderemails.image = '';
    this.automatedreminderemails.price = 900;
    this.automatedreminderemails.duration = 1;
    this.automatedreminderemails.id = 10031;

    this.dailyschedulenotifications.icon = 'icon-10032';
    this.dailyschedulenotifications.title = 'Daily Schedule Notifications';
    this.dailyschedulenotifications.subTitle =
      "Sending notifications for the day's schedule";
    this.dailyschedulenotifications.image = '';
    this.dailyschedulenotifications.price = 900;
    this.dailyschedulenotifications.duration = 1;
    this.dailyschedulenotifications.id = 10032;

    this.desktopnotifications.icon = 'icon-10033';
    this.desktopnotifications.title = 'Desktop Notifications';
    this.desktopnotifications.subTitle =
      'Support for sending automated reminder emails to the users.';
    this.desktopnotifications.image = '';
    this.desktopnotifications.price = 900;
    this.desktopnotifications.duration = 1;
    this.desktopnotifications.id = 10033;

    this.emailnotifications.icon = 'icon-10034';
    this.emailnotifications.title = 'Email Notifications';
    this.emailnotifications.subTitle =
      'Email notifications to users, ability to unsubscribe to such emails';
    this.emailnotifications.image = '';
    this.emailnotifications.price = 900;
    this.emailnotifications.duration = 1;
    this.emailnotifications.id = 10034;

    this.linkedaccounts.icon = '';
    this.linkedaccounts.title = 'Linked Accounts';
    this.linkedaccounts.subTitle =
      'Email notifications to users, ability to unsubscribe to such emails.';
    this.linkedaccounts.image = '';
    this.linkedaccounts.price = 1320;
    this.linkedaccounts.duration = 2;
    this.linkedaccounts.id = 10035;

    this.locationbasedalerts.icon = 'icon-10036';
    this.locationbasedalerts.title = 'Location-Based Alerts';
    this.locationbasedalerts.subTitle = 'Enable linked accounts l';
    this.locationbasedalerts.image = '';
    this.locationbasedalerts.price = 1800;
    this.locationbasedalerts.duration = 2;
    this.locationbasedalerts.id = 10036;

    this.notifications.icon = 'icon-10037';
    this.notifications.title = 'Notifications';
    this.notifications.subTitle =
      'Notifications page that displays recent notifications, show unread ones, and provides an ability to respond to different types of messages';
    this.notifications.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Notifications.webp';
    this.notifications.price = 1200;
    this.notifications.duration = 1.5;
    this.notifications.id = 10037;

    this.pushnotifications.icon = 'icon-10038';
    this.pushnotifications.title = 'Push Notifications';
    this.pushnotifications.subTitle =
      "Send notifications to app users at any time; users don't have to be on the app or using their devices to receive them";
    this.pushnotifications.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Push-Notifications-1.webp';
    this.pushnotifications.price = 480;
    this.pushnotifications.duration = 0.5;
    this.pushnotifications.id = 10038;

    this.audiomusicnotifications.icon = 'icon-10039';
    this.audiomusicnotifications.title = 'Audio & Music Notifications';
    this.audiomusicnotifications.subTitle =
      "Send notifications to app users at any time; users don't have to be on the app or using their devices to receive them";
    this.audiomusicnotifications.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Audio-Music-Notifications.webp';
    this.audiomusicnotifications.price = 900;
    this.audiomusicnotifications.duration = 1;
    this.audiomusicnotifications.id = 10039;

    this.audiocall.icon = '';
    this.audiocall.title = 'Audio Call';
    this.audiocall.subTitle =
      'Enables you to make voice calls to app users via your app';
    this.audiocall.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Audio-Call.webp';
    this.audiocall.price = 1320;
    this.audiocall.duration = 2;
    this.audiocall.id = 10040;

    this.audiolibrary.icon = 'icon-10041';
    this.audiolibrary.title = 'Audio Library';
    this.audiolibrary.subTitle =
      'Maintaining a library of audio files, company recordings, etc.';
    this.audiolibrary.image = '';
    this.audiolibrary.price = 900;
    this.audiolibrary.duration = 1;
    this.audiolibrary.id = 10041;

    this.audioslidershow.icon = 'icon-10042';
    this.audioslidershow.title = 'Audio Slider Show';
    this.audioslidershow.subTitle =
      'A feature to present a series of pictures, presented one at a time, with an accompanying audio';
    this.audioslidershow.image = '';
    this.audioslidershow.price = 900;
    this.audioslidershow.duration = 1;
    this.audioslidershow.id = 10042;

    this.bassboost.icon = '';
    this.bassboost.title = 'Bass Boost';
    this.bassboost.subTitle = 'Use personal settings to mix track';
    this.bassboost.image = '';
    this.bassboost.price = 1320;
    this.bassboost.duration = 2;
    this.bassboost.id = 10043;

    this.callrecording.icon = 'icon-10044';
    this.callrecording.title = 'Call Recording';
    this.callrecording.subTitle =
      'Allows the users to keep a video recording of meetings.';
    this.callrecording.image = '';
    this.callrecording.price = 1800;
    this.callrecording.duration = 2;
    this.callrecording.id = 10044;

    this.playlist.icon = 'icon-10045';
    this.playlist.title = 'Playlist';
    this.playlist.subTitle =
      'A feature to stack up content as a playlist and maintain a library';
    this.playlist.image = '';
    this.playlist.price = 480;
    this.playlist.duration = 0.5;
    this.playlist.id = 10045;

    this.remoteaudioandvideo.icon = '';
    this.remoteaudioandvideo.title = 'Remote Audio and Video';
    this.remoteaudioandvideo.subTitle =
      'A feature to hear music or system sounds and see videos from the remote computer during a connection';
    this.remoteaudioandvideo.image = '';
    this.remoteaudioandvideo.price = 1800;
    this.remoteaudioandvideo.duration = 2;
    this.remoteaudioandvideo.id = 10046;

    this.soundmixing.icon = 'icon-10047';
    this.soundmixing.title = 'Sound Mixing';
    this.soundmixing.subTitle =
      'A feature to add filters and make changes to the sound';
    this.soundmixing.image = '';
    this.soundmixing.price = 1470;
    this.soundmixing.duration = 1;
    this.soundmixing.id = 10047;

    this.soundsettings.icon = '';
    this.soundsettings.title = 'Sound Settings';
    this.soundsettings.subTitle = 'Change sound settings for a recording';
    this.soundsettings.image = '';
    this.soundsettings.price = 1470;
    this.soundsettings.duration = 1;
    this.soundsettings.id = 10048;

    this.voicememos.icon = 'icon-10049';
    this.voicememos.title = 'Voice Memos';
    this.voicememos.subTitle = 'Enables recording of voice notes';
    this.voicememos.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Voice-Memos.webp';
    this.voicememos.price = 1800;
    this.voicememos.duration = 2;
    this.voicememos.id = 10049;

    this.accountcreation.icon = '';
    this.accountcreation.title = 'Account Creation';
    this.accountcreation.subTitle =
      'A feature to setup an account by filling out personal details';
    this.accountcreation.image = '';
    this.accountcreation.price = 1320;
    this.accountcreation.duration = 2;
    this.accountcreation.id = 10050;

    this.advancedsearch.icon = 'icon-10051';
    this.advancedsearch.title = 'Advanced Search';
    this.advancedsearch.subTitle =
      'A feature to add additional preferences for search in the search option of your e-commerce product';
    this.advancedsearch.image = '';
    this.advancedsearch.price = 1200;
    this.advancedsearch.duration = 1.5;
    this.advancedsearch.id = 10051;

    this.authenticationinwear.icon = 'icon-10052';
    this.authenticationinwear.title = 'Authentication in Wear';
    this.authenticationinwear.subTitle =
      'A feature for Android Wear standalone apps to manage authentication on their own when the apps need to access data from the cloud';
    this.authenticationinwear.image = '';
    this.authenticationinwear.price = 1800;
    this.authenticationinwear.duration = 1;
    this.authenticationinwear.id = 10052;

    this.contacts.icon = '';
    this.contacts.title = 'Contacts';
    this.contacts.subTitle =
      "A feature that enables syncing the user's device address book with your app";
    this.contacts.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Contacts.webp';
    this.contacts.price = 600;
    this.contacts.duration = 1;
    this.contacts.id = 10053;

    this.datasaving.icon = '';
    this.datasaving.title = 'Data Saving';
    this.datasaving.subTitle =
      'Upload high-resolution photos and videos on WiFi only';
    this.datasaving.image = '';
    this.datasaving.price = 660;
    this.datasaving.duration = 1;
    this.datasaving.id = 10054;

    this.discoversettings.icon = '';
    this.discoversettings.title = 'Discover Settings';
    this.discoversettings.subTitle =
      'A feature to collect personalized settings';
    this.discoversettings.image = '';
    this.discoversettings.price = 1320;
    this.discoversettings.duration = 2;
    this.discoversettings.id = 10055;

    this.displaysettings.icon = '';
    this.displaysettings.title = 'Display Settings';
    this.displaysettings.subTitle =
      'A feature to customize the theme of an application';
    this.displaysettings.image = '';
    this.displaysettings.price = 1170;
    this.displaysettings.duration = 2;
    this.displaysettings.id = 10056;

    this.facebooklogin.icon = 'icon-10057';
    this.facebooklogin.title = 'Facebook Login';
    this.facebooklogin.subTitle = 'Login or register using Facebook account.';
    this.facebooklogin.image = '';
    this.facebooklogin.price = 480;
    this.facebooklogin.duration = 0.5;
    this.facebooklogin.id = 10057;

    this.featuresettings.icon = '';
    this.featuresettings.title = 'Feature Settings';
    this.featuresettings.subTitle =
      'A feature to choose application-related preferences from settings';
    this.featuresettings.image = '';
    this.featuresettings.price = 600;
    this.featuresettings.duration = 1;
    this.featuresettings.id = 10058;

    this.filecompression.icon = '';
    this.filecompression.title = 'File Compression';
    this.filecompression.subTitle =
      'To enable compressing files through the app ';
    this.filecompression.image = '';
    this.filecompression.price = 1320;
    this.filecompression.duration = 2;
    this.filecompression.id = 10059;

    this.forgetpassword.icon = '';
    this.forgetpassword.title = 'Forget Password';
    this.forgetpassword.subTitle =
      'A feature to put a request to recover the password';
    this.forgetpassword.image = '';
    this.forgetpassword.price = 1320;
    this.forgetpassword.duration = 2;
    this.forgetpassword.id = 10060;

    this.freetrial.icon = 'icon-10061';
    this.freetrial.title = 'Free Trial';
    this.freetrial.subTitle =
      'Offers a free trial version of your app to your users. ';
    this.freetrial.image = '';
    this.freetrial.price = 900;
    this.freetrial.duration = 1;
    this.freetrial.id = 10061;

    this.googleassistant.icon = '';
    this.googleassistant.title = 'Google Assistant';
    this.googleassistant.subTitle =
      'A feature that allows the application to work with human voice command ';
    this.googleassistant.image = '';
    this.googleassistant.price = 600;
    this.googleassistant.duration = 1;
    this.googleassistant.id = 10062;

    this.hamburgermenu.icon = '';
    this.hamburgermenu.title = 'Hamburger Menu';
    this.hamburgermenu.subTitle = 'To view program options on mobile devices.';
    this.hamburgermenu.image = '';
    this.hamburgermenu.price = 1320;
    this.hamburgermenu.duration = 2;
    this.hamburgermenu.id = 10063;

    this.internetaccess.icon = '';
    this.internetaccess.title = 'Internet Access';
    this.internetaccess.subTitle =
      'A feature that allows user to show their internet status and device connections';
    this.internetaccess.image = '';
    this.internetaccess.price = 750;
    this.internetaccess.duration = 0.5;
    this.internetaccess.id = 10064;

    this.keywordsearch.icon = 'icon-10065';
    this.keywordsearch.title = 'Keyword Search';
    this.keywordsearch.subTitle =
      "Search for keywords through all your app's content";
    this.keywordsearch.image = '';
    this.keywordsearch.price = 1200;
    this.keywordsearch.duration = 1.5;
    this.keywordsearch.id = 10065;

    this.landingpage.icon = '';
    this.landingpage.title = 'Landing Page';
    this.landingpage.subTitle =
      'A screen that is the entry point of any application and allows you to read general information about it. ';
    this.landingpage.image = '';
    this.landingpage.price = 300;
    this.landingpage.duration = 1;
    this.landingpage.id = 10066;

    this.linkedinlogin.icon = 'icon-10067';
    this.linkedinlogin.title = 'Linkedin Login';
    this.linkedinlogin.subTitle =
      'Allows users to log in or register using a LinkedIn account';
    this.linkedinlogin.image = '';
    this.linkedinlogin.price = 900;
    this.linkedinlogin.duration = 1;
    this.linkedinlogin.id = 10067;

    this.livechat.icon = 'icon-10068';
    this.livechat.title = 'Live Chat';
    this.livechat.subTitle =
      'Peer-to-peer real-time text chatting using live chat plugin integration';
    this.livechat.image = '';
    this.livechat.price = 900;
    this.livechat.duration = 1;
    this.livechat.id = 10068;

    this.logodesign.icon = 'icon-10069';
    this.logodesign.title = 'Logo Design';
    this.logodesign.subTitle =
      'A feature to select from a preset of logos or design a logo with a set of edit tools';
    this.logodesign.image = '';
    this.logodesign.price = 1800;
    this.logodesign.duration = 2;
    this.logodesign.id = 10069;

    this.mmssettings.icon = '';
    this.mmssettings.title = 'MMS Settings';
    this.mmssettings.subTitle =
      'A feature to setup the delivery reports, file limit, etc.for multimedia messages from settings ';
    this.mmssettings.image = '';
    this.mmssettings.price = 2310;
    this.mmssettings.duration = 1;
    this.mmssettings.id = 10070;

    this.multilevelapprovalpath.icon = 'icon-10071';
    this.multilevelapprovalpath.title = 'Multi-Level Approval Path';
    this.multilevelapprovalpath.subTitle =
      'A feature to have planning and approval of compensation data that differs from customary compensation l';
    this.multilevelapprovalpath.image = '';
    this.multilevelapprovalpath.price = 1800;
    this.multilevelapprovalpath.duration = 2;
    this.multilevelapprovalpath.id = 10071;

    this.multiplebrowsersupport.icon = 'icon-10072';
    this.multiplebrowsersupport.title = 'Multiple Browser Support';
    this.multiplebrowsersupport.subTitle =
      'A feature that supports multiple browsers like Chrome, Firefox, etc';
    this.multiplebrowsersupport.image = '';
    this.multiplebrowsersupport.price = 900;
    this.multiplebrowsersupport.duration = 1;
    this.multiplebrowsersupport.id = 10072;

    this.openapi.icon = 'icon-10073';
    this.openapi.title = 'Open API';
    this.openapi.subTitle =
      'A feature to access publicly available application programming interface that provides developers with program';
    this.openapi.image = '';
    this.openapi.price = 1200;
    this.openapi.duration = 1.5;
    this.openapi.id = 10073;

    this.phonelogin.icon = '';
    this.phonelogin.title = 'Phone Login';
    this.phonelogin.subTitle = "Registration via the user's phone number";
    this.phonelogin.image = '';
    this.phonelogin.price = 930;
    this.phonelogin.duration = 2;
    this.phonelogin.id = 10074;

    this.promocodes.icon = '';
    this.promocodes.title = 'Promo Codes';
    this.promocodes.subTitle =
      'Ability to create promo codes and apply them through the application';
    this.promocodes.image = '';
    this.promocodes.price = 540;
    this.promocodes.duration = 2;
    this.promocodes.id = 10075;

    this.savesearch.icon = '';
    this.savesearch.title = 'Save Search';
    this.savesearch.subTitle =
      'Mark and save search parameters to use later in the application';
    this.savesearch.image = '';
    this.savesearch.price = 660;
    this.savesearch.duration = 1;
    this.savesearch.id = 10076;

    this.sdcard.icon = '';
    this.sdcard.title = 'SD Card';
    this.sdcard.subTitle =
      'Browse folders and files that are contained in the SD card';
    this.sdcard.image = '';
    this.sdcard.price = 1320;
    this.sdcard.duration = 2;
    this.sdcard.id = 10077;

    this.search.icon = 'icon-10078';
    this.search.title = 'Search';
    this.search.subTitle =
      'A powerful search feature that can navigate all over your app, using ElasticSearch, and shows results instantly';
    this.search.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Search.webp';
    this.search.price = 1800;
    this.search.duration = 2;
    this.search.id = 10078;

    this.shufflestations.icon = '';
    this.shufflestations.title = 'Shuffle Stations';
    this.shufflestations.subTitle = 'Mix stations ';
    this.shufflestations.image = '';
    this.shufflestations.price = 1320;
    this.shufflestations.duration = 2;
    this.shufflestations.id = 10079;

    this.signin.icon = '';
    this.signin.title = 'Sign In';
    this.signin.subTitle = 'Sign In module';
    this.signin.image = '';
    this.signin.price = 810;
    this.signin.duration = 0.5;
    this.signin.id = 10080;

    this.signinviaemail.icon = '';
    this.signinviaemail.title = 'Sign in via email';
    this.signinviaemail.subTitle =
      'Registration via email address and password ';
    this.signinviaemail.image = '';
    this.signinviaemail.price = 810;
    this.signinviaemail.duration = 0.5;
    this.signinviaemail.id = 10081;

    this.signinviainstagram.icon = '';
    this.signinviainstagram.title = 'Sign in via Instagram';
    this.signinviainstagram.subTitle =
      'Ability to log in the application through an Instagram account';
    this.signinviainstagram.image = '';
    this.signinviainstagram.price = 810;
    this.signinviainstagram.duration = 0.5;
    this.signinviainstagram.id = 10082;

    this.signuploginmodule.icon = '';
    this.signuploginmodule.title = 'Signup/Login Module';
    this.signuploginmodule.subTitle =
      'Login/sign up, are used to onboard users to the app. Get the required email/phone and verify user authenticity.';
    this.signuploginmodule.image = '';
    this.signuploginmodule.price = 810;
    this.signuploginmodule.duration = 0.5;
    this.signuploginmodule.id = 10083;

    this.sorting.icon = 'icon-10084';
    this.sorting.title = 'Sorting';
    this.sorting.subTitle =
      'Allowing users to quickly sort content as per the selected criteria (location, rating, name, etc)';
    this.sorting.image = '';
    this.sorting.price = 480;
    this.sorting.duration = 0.5;
    this.sorting.id = 10084;

    this.splashscreen.icon = '';
    this.splashscreen.title = 'Splash Screen';
    this.splashscreen.subTitle =
      'A graphical element to notify the user that the program is in the process of loading';
    this.splashscreen.image = '';
    this.splashscreen.price = 150;
    this.splashscreen.duration = 0.5;
    this.splashscreen.id = 10085;

    this.streetpeek.icon = '';
    this.streetpeek.title = 'Street Peek';
    this.streetpeek.subTitle =
      'Use GPS to see home prices in augmented reality';
    this.streetpeek.image = '';
    this.streetpeek.price = 1320;
    this.streetpeek.duration = 2;
    this.streetpeek.id = 10086;

    this.termsandconditionpage.icon = '';
    this.termsandconditionpage.title = 'Terms And Condition Page';
    this.termsandconditionpage.subTitle = 'Term and Condition Page';
    this.termsandconditionpage.image = '';
    this.termsandconditionpage.price = 1320;
    this.termsandconditionpage.duration = 2;
    this.termsandconditionpage.id = 10087;

    this.theme.icon = '';
    this.theme.title = 'Theme';
    this.theme.subTitle =
      'A feature that allows users to apply a style to an entire activity or application, rather than an individual view.';
    this.theme.image = '';
    this.theme.price = 1170;
    this.theme.duration = 2;
    this.theme.id = 10088;

    this.twofactorauthentication.icon = 'icon-10089';
    this.twofactorauthentication.title = 'Two-Factor Authentication';
    this.twofactorauthentication.subTitle =
      'Two authentication factors to verify if a user is who they say they are.';
    this.twofactorauthentication.image = '';
    this.twofactorauthentication.price = 900;
    this.twofactorauthentication.duration = 1;
    this.twofactorauthentication.id = 10089;

    this.useravatar.icon = 'icon-10090';
    this.useravatar.title = 'User Avatar';
    this.useravatar.subTitle =
      'Allows the users to have avatars as their profile picture';
    this.useravatar.image = '';
    this.useravatar.price = 480;
    this.useravatar.duration = 0.5;
    this.useravatar.id = 10090;

    this.userstatus.icon = '';
    this.userstatus.title = 'User Status';
    this.userstatus.subTitle =
      "A feature to record and display the user's status in the application";
    this.userstatus.image = '';
    this.userstatus.price = 1470;
    this.userstatus.duration = 1;
    this.userstatus.id = 10091;

    this.activityfeed.icon = '';
    this.activityfeed.title = 'Activity-Feed';
    this.activityfeed.subTitle =
      'Recent activity, status, views of the users, campaigns, or events you follow';
    this.activityfeed.image = '';
    this.activityfeed.price = 900;
    this.activityfeed.duration = 1;
    this.activityfeed.id = 10092;

    this.addfriends.icon = 'icon-10093';
    this.addfriends.title = 'Add Friends';
    this.addfriends.subTitle = 'Enables users to add friends to their network';
    this.addfriends.image = '';
    this.addfriends.price = 900;
    this.addfriends.duration = 1;
    this.addfriends.id = 10093;

    this.analytics.icon = 'icon-10094';
    this.analytics.title = 'Analytics';
    this.analytics.subTitle =
      'Ability to track events, prepare statistics and reports for different scenarios to gain customer insights';
    this.analytics.image = '';
    this.analytics.price = 900;
    this.analytics.duration = 1;
    this.analytics.id = 10094;

    this.sticker.icon = '';
    this.sticker.title = 'Sticker';
    this.sticker.subTitle = "Add animated Emoji's ";
    this.sticker.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Sticker.webp';
    this.sticker.price = 900;
    this.sticker.duration = 1;
    this.sticker.id = 10095;

    this.audiovideomessenger.icon = '';
    this.audiovideomessenger.title = 'Audio/Video Messenger';
    this.audiovideomessenger.subTitle =
      'Create a messenger where users can send  text, audio, and video messages to other users';
    this.audiovideomessenger.image =
      'https://nandbox.com/wp-content/uploads/2022/12/AudioVideo-Messenger.webp';
    this.audiovideomessenger.price = 1800;
    this.audiovideomessenger.duration = 2;
    this.audiovideomessenger.id = 10096;

    this.broadcastmessage.icon = '';
    this.broadcastmessage.title = 'Broadcast message';
    this.broadcastmessage.subTitle =
      'A feature to broadcast a message to multiple users at the same time';
    this.broadcastmessage.image = '';
    this.broadcastmessage.price = 900;
    this.broadcastmessage.duration = 1;
    this.broadcastmessage.id = 10097;

    this.chat.icon = 'icon-10098';
    this.chat.title = 'Chat';
    this.chat.subTitle =
      'Your app users can chat, share images, videos, files, and receive push & in-app notifications';
    this.chat.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Chat.webp';
    this.chat.price = 1800;
    this.chat.duration = 2;
    this.chat.id = 10098;

    this.chatbackuprestore.icon = '';
    this.chatbackuprestore.title = 'Chat Backup/Restore';
    this.chatbackuprestore.subTitle =
      'Ability to restore previous chats or backup current chats';
    this.chatbackuprestore.image = '';
    this.chatbackuprestore.price = 900;
    this.chatbackuprestore.duration = 1;
    this.chatbackuprestore.id = 10099;

    this.chatbot.icon = 'icon-10100';
    this.chatbot.title = 'Chatbot';
    this.chatbot.subTitle =
      'A special program designed to simulate a conversation with human users';
    this.chatbot.image = '';
    this.chatbot.price = 900;
    this.chatbot.duration = 1;
    this.chatbot.id = 10100;

    this.collagecreator.icon = 'icon-10101';
    this.collagecreator.title = 'Collage Creator';
    this.collagecreator.subTitle =
      'Auto-create collages from your photo collection.';
    this.collagecreator.image = '';
    this.collagecreator.price = 900;
    this.collagecreator.duration = 1;
    this.collagecreator.id = 10101;

    this.comments.icon = 'icon-10102';
    this.comments.title = 'Comments';
    this.comments.subTitle =
      'Ability to view and post comments, including nested replies (up to one level)';
    this.comments.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Comments.webp';
    this.comments.price = 900;
    this.comments.duration = 1;
    this.comments.id = 10102;

    this.contactsharing.icon = 'icon-10103';
    this.contactsharing.title = 'Contact Sharing';
    this.contactsharing.subTitle =
      "Allows the users to share one or more of their contacts' information, ";
    this.contactsharing.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Contact-Sharing.webp';
    this.contactsharing.price = 900;
    this.contactsharing.duration = 1;
    this.contactsharing.id = 10103;

    this.conversationthreading.icon = 'icon-10104';
    this.conversationthreading.title = 'Conversation Threading';
    this.conversationthreading.subTitle =
      'Allowing bulletin boards, newsgroups, and forums to have a visual grouping of messages with their replies';
    this.conversationthreading.image = '';
    this.conversationthreading.price = 900;
    this.conversationthreading.duration = 1;
    this.conversationthreading.id = 10104;

    this.createsketch.icon = '';
    this.createsketch.title = 'Create Sketch';
    this.createsketch.subTitle = 'Create a sketch on the device';
    this.createsketch.image = '';
    this.createsketch.price = 1320;
    this.createsketch.duration = 2;
    this.createsketch.id = 10105;

    this.emaillogin.icon = '';
    this.emaillogin.title = 'Email Login';
    this.emaillogin.subTitle =
      'Login or register with email, forgot password, reset password';
    this.emaillogin.image = '';
    this.emaillogin.price = 1350;
    this.emaillogin.duration = 1;
    this.emaillogin.id = 10106;

    this.emojis.icon = 'icon-10107';
    this.emojis.title = 'Emojis';
    this.emojis.subTitle =
      'Indulge your users with emojis and stickers to use in chats and posts';
    this.emojis.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Emojis.webp';
    this.emojis.price = 900;
    this.emojis.duration = 1;
    this.emojis.id = 10107;

    this.eventregistration.icon = '';
    this.eventregistration.title = 'Event Registration';
    this.eventregistration.subTitle =
      "A feature to record and display the user's status in the application. ";
    this.eventregistration.image = '';
    this.eventregistration.price = 480;
    this.eventregistration.duration = 0.5;
    this.eventregistration.id = 10108;

    this.facebookfeedsdisplay.icon = '';
    this.facebookfeedsdisplay.title = 'Facebook Feeds Display';
    this.facebookfeedsdisplay.subTitle =
      'A feature to embed a completely customizable, responsive and search engine crawlable version of a Facebook feed';
    this.facebookfeedsdisplay.image = '';
    this.facebookfeedsdisplay.price = 1800;
    this.facebookfeedsdisplay.duration = 2;
    this.facebookfeedsdisplay.id = 10109;

    this.fanwall.icon = '';
    this.fanwall.title = 'Fan wall';
    this.fanwall.subTitle =
      'A feature to add a fan wall to your platform and encourage clients to write share their experience. This helps in better branding and increasing the customer base.';
    this.fanwall.image = '';
    this.fanwall.price = 900;
    this.fanwall.duration = 1;
    this.fanwall.id = 10110;

    this.filteritems.icon = '';
    this.filteritems.title = 'Filter Items';
    this.filteritems.subTitle =
      'Filter feature gives the user a quick and easy way to find and work with a subset of data.';
    this.filteritems.image = '';
    this.filteritems.price = 900;
    this.filteritems.duration = 1;
    this.filteritems.id = 10111;

    this.flashinstantmessenger.icon = '';
    this.flashinstantmessenger.title = 'Flash Instant Messenger';
    this.flashinstantmessenger.subTitle =
      'A feature to create a messenger where users can send temporary auto-delete flash messages';
    this.flashinstantmessenger.image = '';
    this.flashinstantmessenger.price = 900;
    this.flashinstantmessenger.duration = 1;
    this.flashinstantmessenger.id = 10112;

    this.followers.icon = 'icon-10113';
    this.followers.title = 'Followers';
    this.followers.subTitle =
      'A simple feature to allow users to follow other users in the application.';
    this.followers.image = '';
    this.followers.price = 900;
    this.followers.duration = 1;
    this.followers.id = 10113;

    this.friendlist.icon = '';
    this.friendlist.title = 'Friend List';
    this.friendlist.subTitle =
      'A list of all the user friends on the application, including searching, and filtering by recently added';
    this.friendlist.image = '';
    this.friendlist.price = 900;
    this.friendlist.duration = 1;
    this.friendlist.id = 10114;

    this.gallery.icon = 'icon-10115';
    this.gallery.title = 'Gallery ';
    this.gallery.subTitle =
      'Gallery section to view all photos, videos, and shared content in Group/Channel';
    this.gallery.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Gallery.webp';
    this.gallery.price = 1800;
    this.gallery.duration = 2;
    this.gallery.id = 10115;

    this.gifs.icon = 'icon-10116';
    this.gifs.title = 'GIFs';
    this.gifs.subTitle = 'For users to send GIFs in chat groups or channels';
    this.gifs.image =
      'https://nandbox.com/wp-content/uploads/2022/12/GIFs.webp';
    this.gifs.price = 1200;
    this.gifs.duration = 1.5;
    this.gifs.id = 10116;

    this.groupchat.icon = '';
    this.groupchat.title = 'Group Chat';
    this.groupchat.subTitle =
      'For users to chat in groups of up to 10,000 members';
    this.groupchat.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Group-Chat.webp';
    this.groupchat.price = 1200;
    this.groupchat.duration = 1.5;
    this.groupchat.id = 10117;

    this.groupvideocall.icon = '';
    this.groupvideocall.title = 'Group Video Call';
    this.groupvideocall.subTitle =
      'Users can connect with multiple users via video call.';
    this.groupvideocall.image = '';
    this.groupvideocall.price = 2100;
    this.groupvideocall.duration = 2.5;
    this.groupvideocall.id = 10118;

    this.groups.icon = '';
    this.groups.title = 'Groups';
    this.groups.subTitle = 'For users to create groups on your app';
    this.groups.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Groups.webp';
    this.groups.price = 2100;
    this.groups.duration = 2.5;
    this.groups.id = 10119;

    this.hashtag.icon = 'icon-10120';
    this.hashtag.title = 'Hashtag';
    this.hashtag.subTitle =
      'A hashtag feature to make it easier for users to find messages with a specific theme or content.';
    this.hashtag.image = '';
    this.hashtag.price = 900;
    this.hashtag.duration = 1;
    this.hashtag.id = 10120;

    this.hashtaggenerator.icon = '';
    this.hashtaggenerator.title = 'Hashtag generator';
    this.hashtaggenerator.subTitle =
      'A feature that uses artificial intelligence to generate hashtags based on the uploaded/ selected images.';
    this.hashtaggenerator.image = '';
    this.hashtaggenerator.price = 1200;
    this.hashtaggenerator.duration = 1.5;
    this.hashtaggenerator.id = 10121;

    this.icloudsharing.icon = '';
    this.icloudsharing.title = 'iCloud Sharing';
    this.icloudsharing.subTitle = 'A feature to enable iCloud Sharing';
    this.icloudsharing.image = '';
    this.icloudsharing.price = 480;
    this.icloudsharing.duration = 0.5;
    this.icloudsharing.id = 10122;

    this.inappgifting.icon = '';
    this.inappgifting.title = 'in App Gifting';
    this.inappgifting.subTitle = 'Ability to gift in-app items to other users.';
    this.inappgifting.image = '';
    this.inappgifting.price = 900;
    this.inappgifting.duration = 1;
    this.inappgifting.id = 10123;

    this.instantgiftcards.icon = '';
    this.instantgiftcards.title = 'Instant Gift Cards';
    this.instantgiftcards.subTitle =
      'A feature create and send instantly redeemable gift cards on your platform';
    this.instantgiftcards.image = '';
    this.instantgiftcards.price = 900;
    this.instantgiftcards.duration = 1;
    this.instantgiftcards.id = 10124;

    this.karmapoints.icon = '';
    this.karmapoints.title = 'Karma points';
    this.karmapoints.subTitle =
      'Ability to earn online points with good content submissions and comments.';
    this.karmapoints.image = '';
    this.karmapoints.price = 480;
    this.karmapoints.duration = 0.5;
    this.karmapoints.id = 10125;

    this.likeapost.icon = '';
    this.likeapost.title = 'Like A Post';
    this.likeapost.subTitle =
      'Enable the users to engage with a post, by liking it';
    this.likeapost.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Like-A-Post.webp';
    this.likeapost.price = 1850;
    this.likeapost.duration = 2;
    this.likeapost.id = 10126;

    this.linkshare.icon = 'icon-10127';
    this.linkshare.title = 'Link Share';
    this.linkshare.subTitle =
      'For users to share photos, videos, and documents via a generated link';
    this.linkshare.image = '';
    this.linkshare.price = 480;
    this.linkshare.duration = 0.5;
    this.linkshare.id = 10127;

    this.linksocialaccounts.icon = 'icon-10128';
    this.linksocialaccounts.title = 'Link Social Accounts';
    this.linksocialaccounts.subTitle =
      'Add content to your app from some social profiles, like Twitter or Instagram.';
    this.linksocialaccounts.image = '';
    this.linksocialaccounts.price = 900;
    this.linksocialaccounts.duration = 1;
    this.linksocialaccounts.id = 10128;

    this.livefeedcapture.icon = '';
    this.livefeedcapture.title = 'Live Feed capture';
    this.livefeedcapture.subTitle =
      'A feature to capture the live feed activity';
    this.livefeedcapture.image = '';
    this.livefeedcapture.price = 1800;
    this.livefeedcapture.duration = 2;
    this.livefeedcapture.id = 10129;

    this.livefeedscheduling.icon = '';
    this.livefeedscheduling.title = 'Live Feed Scheduling';
    this.livefeedscheduling.subTitle =
      'A feature to schedule the capturing of the live feed activity';
    this.livefeedscheduling.image = '';
    this.livefeedscheduling.price = 1800;
    this.livefeedscheduling.duration = 2;
    this.livefeedscheduling.id = 10130;

    this.livestreaming.icon = 'icon-10131';
    this.livestreaming.title = 'Live Streaming';
    this.livestreaming.subTitle =
      'A feature to add live streaming to broadcast your live event';
    this.livestreaming.image = '';
    this.livestreaming.price = 900;
    this.livestreaming.duration = 1;
    this.livestreaming.id = 10131;

    this.mentions.icon = 'icon-10132';
    this.mentions.title = 'Mentions';
    this.mentions.subTitle =
      "Allows the users to reference or link a user's profile to a post or a comment.";
    this.mentions.image = '';
    this.mentions.price = 900;
    this.mentions.duration = 1;
    this.mentions.id = 10132;

    this.muteunmute.icon = '';
    this.muteunmute.title = 'Mute/Unmute';
    this.muteunmute.subTitle =
      'Enables mute/unmute messages from selected conversations';
    this.muteunmute.image =
      'https://nandbox.com/wp-content/uploads/2022/12/MuteUnmute.webp';
    this.muteunmute.price = 660;
    this.muteunmute.duration = 1;
    this.muteunmute.id = 10133;

    this.nearbyfriends.icon = 'icon-10134';
    this.nearbyfriends.title = 'Nearby Friends';
    this.nearbyfriends.subTitle =
      'A feature to find nearby connects based on geolocation';
    this.nearbyfriends.image = '';
    this.nearbyfriends.price = 900;
    this.nearbyfriends.duration = 1;
    this.nearbyfriends.id = 10134;

    this.postcreation.icon = '';
    this.postcreation.title = 'Post Creation';
    this.postcreation.subTitle =
      'Enabling users to post text-only status updates, tag location, tag friends and upload media.';
    this.postcreation.image = '';
    this.postcreation.price = 1800;
    this.postcreation.duration = 2;
    this.postcreation.id = 10135;

    this.recognize.icon = '';
    this.recognize.title = 'Recognize ';
    this.recognize.subTitle =
      'Recognize  the plant to its nearest possible match of the snap taken';
    this.recognize.image = '';
    this.recognize.price = 1320;
    this.recognize.duration = 2;
    this.recognize.id = 10136;

    this.repost.icon = 'icon-10137';
    this.repost.title = 'Repost';
    this.repost.subTitle =
      'Allows the users to repost content to their feed and/or to their followers.';
    this.repost.image = '';
    this.repost.price = 900;
    this.repost.duration = 1;
    this.repost.id = 10137;

    this.share.icon = 'icon-10138';
    this.share.title = 'Share';
    this.share.subTitle =
      'Ability to share photos, videos, and content via social media & email.';
    this.share.image = '';
    this.share.price = 1800;
    this.share.duration = 2;
    this.share.id = 10138;

    this.sharecalendars.icon = 'icon-10139';
    this.sharecalendars.title = 'Share Calendars';
    this.sharecalendars.subTitle =
      'Allows the users to share calendars with other users on the app.';
    this.sharecalendars.image = '';
    this.sharecalendars.price = 900;
    this.sharecalendars.duration = 1;
    this.sharecalendars.id = 10139;

    this.slideshowcreator.icon = 'icon-10140';
    this.slideshowcreator.title = 'Slideshow Creator';
    this.slideshowcreator.subTitle =
      'Auto-create slideshows from your photo collection.';
    this.slideshowcreator.image = '';
    this.slideshowcreator.price = 900;
    this.slideshowcreator.duration = 1;
    this.slideshowcreator.id = 10140;

    this.sms.icon = 'icon-10141';
    this.sms.title = 'SMS';
    this.sms.subTitle = 'Text messaging-based SMS service for interaction';
    this.sms.image = '';
    this.sms.price = 900;
    this.sms.duration = 1;
    this.sms.id = 10141;

    this.smssettings.icon = '';
    this.smssettings.title = 'SMS Settings';
    this.smssettings.subTitle =
      'A feature to setup the delivery reports, characters counter, etc. for messages under settings';
    this.smssettings.image = '';
    this.smssettings.price = 2310;
    this.smssettings.duration = 1;
    this.smssettings.id = 10142;

    this.storiesfeature.icon = 'icon-10143';
    this.storiesfeature.title = 'Stories Feature';
    this.storiesfeature.subTitle =
      'Allows the users to share photos and videos which disappear after 24 hours.';
    this.storiesfeature.image = '';
    this.storiesfeature.price = 900;
    this.storiesfeature.duration = 1;
    this.storiesfeature.id = 10143;

    this.subscriptions.icon = '';
    this.subscriptions.title = 'Subscriptions';
    this.subscriptions.subTitle = 'Ability to subscribe to specific content';
    this.subscriptions.image = '';
    this.subscriptions.price = 480;
    this.subscriptions.duration = 0.5;
    this.subscriptions.id = 10144;

    this.trending.icon = 'icon-10145';
    this.trending.title = 'Trending';
    this.trending.subTitle =
      'Trending helps you discover interesting and relevant topics being discussed on the platform.';
    this.trending.image = '';
    this.trending.price = 480;
    this.trending.duration = 0.5;
    this.trending.id = 10145;

    this.twitterfeedsdisplay.icon = '';
    this.twitterfeedsdisplay.title = 'Twitter Feeds Display';
    this.twitterfeedsdisplay.subTitle =
      'A feature to embed a completely customizable, responsive and search engine crawlable version of a Twitter feed';
    this.twitterfeedsdisplay.image = '';
    this.twitterfeedsdisplay.price = 1800;
    this.twitterfeedsdisplay.duration = 2;
    this.twitterfeedsdisplay.id = 10146;

    this.universallinks.icon = '';
    this.universallinks.title = 'Universal Links';
    this.universallinks.subTitle =
      'A seamless support system to navigate content deep within your app from Siri, Search, or links to other apps.';
    this.universallinks.image = '';
    this.universallinks.price = 1800;
    this.universallinks.duration = 2;
    this.universallinks.id = 10147;

    this.uploadmedia.icon = '';
    this.uploadmedia.title = 'Upload media';
    this.uploadmedia.subTitle =
      'Ability to submit pictures, documents, or videos to the app.';
    this.uploadmedia.image = '';
    this.uploadmedia.price = 900;
    this.uploadmedia.duration = 1;
    this.uploadmedia.id = 10148;

    this.upvotedownvote.icon = '';
    this.upvotedownvote.title = 'Upvote/Downvote';
    this.upvotedownvote.subTitle =
      'Upvote/downvote system to let users show their liking/disliking of the content; this metric can be used to con more...';
    this.upvotedownvote.image = '';
    this.upvotedownvote.price = 900;
    this.upvotedownvote.duration = 1;
    this.upvotedownvote.id = 10149;

    this.useraccount.icon = '';
    this.useraccount.title = 'User Account';
    this.useraccount.subTitle = 'User Account module';
    this.useraccount.image = '';
    this.useraccount.price = 1470;
    this.useraccount.duration = 1;
    this.useraccount.id = 10150;

    this.videoconversation.icon = '';
    this.videoconversation.title = 'Video Conversation';
    this.videoconversation.subTitle = 'A feature to launch video conversation';
    this.videoconversation.image = '';
    this.videoconversation.price = 1320;
    this.videoconversation.duration = 2;
    this.videoconversation.id = 10151;

    this.multiplecurrencysupport.icon = 'icon-10152';
    this.multiplecurrencysupport.title = 'Multiple Currency Support';
    this.multiplecurrencysupport.subTitle =
      'Supporting multiple currencies (USD/EUR/JPY/GBP/CAD/AUD).';
    this.multiplecurrencysupport.image = '';
    this.multiplecurrencysupport.price = 480;
    this.multiplecurrencysupport.duration = 0.5;
    this.multiplecurrencysupport.id = 10152;

    this.payment.icon = 'icon-10153';
    this.payment.title = 'Payment';
    this.payment.subTitle =
      'A system to accept payments from credit or debit cards added by the user. Consists of logic to process the payments, ability to accept payments and notify payment status';
    this.payment.image = '';
    this.payment.price = 1800;
    this.payment.duration = 2;
    this.payment.id = 10153;

    this.savedcards.icon = 'icon-10154';
    this.savedcards.title = 'Saved Cards';
    this.savedcards.subTitle =
      'Allow users breeze through checkout, without having to enter their credit/debit card information each time they place an order.';
    this.savedcards.image = '';
    this.savedcards.price = 480;
    this.savedcards.duration = 0.5;
    this.savedcards.id = 10154;

    this.automaticrenewals.icon = 'icon-10155';
    this.automaticrenewals.title = 'Automatic Renewals';
    this.automaticrenewals.subTitle =
      'Allows the users to opt for automatic renewal of their subscription';
    this.automaticrenewals.image = '';
    this.automaticrenewals.price = 900;
    this.automaticrenewals.duration = 1;
    this.automaticrenewals.id = 10155;

    this.centralizedbilling.icon = 'icon-10156';
    this.centralizedbilling.title = 'Centralized Billing';
    this.centralizedbilling.subTitle =
      'Allows the admin to pay for all the accounts on a single bill';
    this.centralizedbilling.image = '';
    this.centralizedbilling.price = 1200;
    this.centralizedbilling.duration = 1.5;
    this.centralizedbilling.id = 10156;

    this.collecttransactionfees.icon = 'icon-10157';
    this.collecttransactionfees.title = 'Collect Transaction Fees';
    this.collecttransactionfees.subTitle =
      'A feature where a fee (%) is deducted from the transaction amount.';
    this.collecttransactionfees.image = '';
    this.collecttransactionfees.price = 900;
    this.collecttransactionfees.duration = 1;
    this.collecttransactionfees.id = 10157;

    this.digitalsignature.icon = 'icon-10158';
    this.digitalsignature.title = 'Digital Signature';
    this.digitalsignature.subTitle =
      'Allows the users to do digital signatures (3rd party integration). ';
    this.digitalsignature.image = '';
    this.digitalsignature.price = 480;
    this.digitalsignature.duration = 0.5;
    this.digitalsignature.id = 10158;

    this.donationpayments.icon = 'icon-10159';
    this.donationpayments.title = 'Donation Payments';
    this.donationpayments.subTitle =
      'Allows the users to make donation payments.';
    this.donationpayments.image = '';
    this.donationpayments.price = 900;
    this.donationpayments.duration = 1;
    this.donationpayments.id = 10159;

    this.inapppurchase.icon = 'icon-10160';
    this.inapppurchase.title = 'in App Purchase';
    this.inapppurchase.subTitle =
      'The ability for users to make an in-app purchase in order to access special content or features in an app such as power-ups, restricted levels, virtual money, special characters, boosts, etc.';
    this.inapppurchase.image = '';
    this.inapppurchase.price = 900;
    this.inapppurchase.duration = 1;
    this.inapppurchase.id = 10160;

    this.invoicebilling.icon = 'icon-10161';
    this.invoicebilling.title = 'Invoice Billing';
    this.invoicebilling.subTitle =
      'Invoice Billing allows you to bill your customers.';
    this.invoicebilling.image = '';
    this.invoicebilling.price = 1200;
    this.invoicebilling.duration = 1.5;
    this.invoicebilling.id = 10161;

    this.multitieredpricing.icon = 'icon-10162';
    this.multitieredpricing.title = 'Multi-tiered Pricing';
    this.multitieredpricing.subTitle =
      'A feature that lets you offer a quantity discount on the catalog list and product detail at a multi-tiered pricing ';
    this.multitieredpricing.image = '';
    this.multitieredpricing.price = 1200;
    this.multitieredpricing.duration = 1.5;
    this.multitieredpricing.id = 10162;

    this.paymentadmin.icon = 'icon-10163';
    this.paymentadmin.title = 'Payment Admin';
    this.paymentadmin.subTitle =
      'Providing section in the admin panel to manage payments and entries';
    this.paymentadmin.image = '';
    this.paymentadmin.price = 1200;
    this.paymentadmin.duration = 1.5;
    this.paymentadmin.id = 10163;

    this.premiumplancomparison.icon = '';
    this.premiumplancomparison.title = 'Premium Plan Comparison';
    this.premiumplancomparison.subTitle =
      'A feature to compare the feature listings of various premium plans l';
    this.premiumplancomparison.image = '';
    this.premiumplancomparison.price = 900;
    this.premiumplancomparison.duration = 1;
    this.premiumplancomparison.id = 10164;

    this.refundmanagement.icon = 'icon-10165';
    this.refundmanagement.title = 'Refund Management';
    this.refundmanagement.subTitle =
      'Allows the users to manage rejected requests, refunded items, or rejected applications.';
    this.refundmanagement.image = '';
    this.refundmanagement.price = 900;
    this.refundmanagement.duration = 1;
    this.refundmanagement.id = 10165;

    this.restorepurchase.icon = '';
    this.restorepurchase.title = 'Restore Purchase';
    this.restorepurchase.subTitle =
      'A feature that allows you to transfer in-app purchases to other devices';
    this.restorepurchase.image = '';
    this.restorepurchase.price = 1470;
    this.restorepurchase.duration = 1;
    this.restorepurchase.id = 10166;

    this.splitpayments.icon = 'icon-10167';
    this.splitpayments.title = 'Split Payments';
    this.splitpayments.subTitle =
      'Allows users to split transaction fees across two accounts.';
    this.splitpayments.image = '';
    this.splitpayments.price = 1800;
    this.splitpayments.duration = 2;
    this.splitpayments.id = 10167;

    this.subscriptionbilling.icon = 'icon-10168';
    this.subscriptionbilling.title = 'Subscription Billing';
    this.subscriptionbilling.subTitle =
      'Bill consumers on a time determined basis';
    this.subscriptionbilling.image = '';
    this.subscriptionbilling.price = 480;
    this.subscriptionbilling.duration = 0.5;
    this.subscriptionbilling.id = 10168;

    this.taxcalculator.icon = '';
    this.taxcalculator.title = 'Tax Calculator';
    this.taxcalculator.subTitle =
      'A feature that allows admin to create tax logic and apply them through the application';
    this.taxcalculator.image = '';
    this.taxcalculator.price = 1170;
    this.taxcalculator.duration = 2;
    this.taxcalculator.id = 10169;

    this.wallet.icon = '';
    this.wallet.title = 'Wallet';
    this.wallet.subTitle =
      'Ability to create a wallet in the application and add/ withdraw money from it.';
    this.wallet.image = '';
    this.wallet.price = 1170;
    this.wallet.duration = 2;
    this.wallet.id = 10170;

    this.googlelogin.icon = '';
    this.googlelogin.title = 'Google+ Login';
    this.googlelogin.subTitle =
      'A feature that allows you to sign in using Google plus account';
    this.googlelogin.image = '';
    this.googlelogin.price = 600;
    this.googlelogin.duration = 1;
    this.googlelogin.id = 10171;

    this.reviews.icon = 'icon-10172';
    this.reviews.title = 'Reviews';
    this.reviews.subTitle =
      'Ability to add reviews along with ratings for the items in the application.';
    this.reviews.image = '';
    this.reviews.price = 900;
    this.reviews.duration = 1;
    this.reviews.id = 10172;

    this.advancedinsights.icon = 'icon-10173';
    this.advancedinsights.title = 'Advanced Insights';
    this.advancedinsights.subTitle =
      'Allows the users to monitor utilization rates and gain valuable new insights into your business.';
    this.advancedinsights.image = '';
    this.advancedinsights.price = 1800;
    this.advancedinsights.duration = 2;
    this.advancedinsights.id = 10173;

    this.crm3rdpartyintegration.icon = '';
    this.crm3rdpartyintegration.title = 'CRM (3rd party integration)';
    this.crm3rdpartyintegration.subTitle =
      'CRM is simply a portal to help you manage the relationship with your customer.';
    this.crm3rdpartyintegration.image = '';
    this.crm3rdpartyintegration.price = 900;
    this.crm3rdpartyintegration.duration = 1;
    this.crm3rdpartyintegration.id = 10174;

    this.polling.icon = 'icon-10175';
    this.polling.title = 'Polling';
    this.polling.subTitle = 'A fully-capable simple polling feature.';
    this.polling.image = '';
    this.polling.price = 900;
    this.polling.duration = 1;
    this.polling.id = 10175;

    this.reviewprompt.icon = '';
    this.reviewprompt.title = 'Review Prompt';
    this.reviewprompt.subTitle = '5-star rating review prompt.';
    this.reviewprompt.image = '';
    this.reviewprompt.price = 480;
    this.reviewprompt.duration = 0.5;
    this.reviewprompt.id = 10176;

    this.surveys.icon = '';
    this.surveys.title = 'Surveys';
    this.surveys.subTitle = 'Ability to create and analyze surveys.';
    this.surveys.image = '';
    this.surveys.price = 900;
    this.surveys.duration = 1;
    this.surveys.id = 10177;

    this.affiliateurl.icon = 'icon-10178';
    this.affiliateurl.title = 'Affiliate URL';
    this.affiliateurl.subTitle =
      'Buy/Sell option for an item viewable as a card. On click, it takes you to the 3rd party marketplace.';
    this.affiliateurl.image = '';
    this.affiliateurl.price = 900;
    this.affiliateurl.duration = 1;
    this.affiliateurl.id = 10178;

    this.bidding.icon = 'icon-10179';
    this.bidding.title = 'Bidding';
    this.bidding.subTitle =
      'A feature that lets users bid and take part in-app auctions.';
    this.bidding.image = '';
    this.bidding.price = 1800;
    this.bidding.duration = 2;
    this.bidding.id = 10179;

    this.coupons.icon = 'icon-10180';
    this.coupons.title = 'Coupons';
    this.coupons.subTitle =
      'Ability to create a coupon, set and apply them to your selection.';
    this.coupons.image = '';
    this.coupons.price = 900;
    this.coupons.duration = 1;
    this.coupons.id = 10180;

    this.dashboard.icon = 'icon-10181';
    this.dashboard.title = 'Dashboard';
    this.dashboard.subTitle =
      'A single place to monitor the current state of the business.';
    this.dashboard.image = '';
    this.dashboard.price = 1800;
    this.dashboard.duration = 2;
    this.dashboard.id = 10181;

    this.discountoffers.icon = 'icon-10182';
    this.discountoffers.title = 'Discount & Offers';
    this.discountoffers.subTitle =
      'A dedicated section listing all the app discounts & offers.';
    this.discountoffers.image = '';
    this.discountoffers.price = 900;
    this.discountoffers.duration = 1;
    this.discountoffers.id = 10182;

    this.favorites.icon = '';
    this.favorites.title = 'Favorites';
    this.favorites.subTitle =
      'For users to create customized lists of their favorite friends, photos, and posts';
    this.favorites.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Favorites.webp';
    this.favorites.price = 480;
    this.favorites.duration = 0.5;
    this.favorites.id = 10183;

    this.freecredits.icon = '';
    this.freecredits.title = 'Free Credits';
    this.freecredits.subTitle = 'User can earn ride credit';
    this.freecredits.image = '';
    this.freecredits.price = 480;
    this.freecredits.duration = 0.5;
    this.freecredits.id = 10184;

    this.guestlogin.icon = 'icon-10185';
    this.guestlogin.title = 'Guest Login';
    this.guestlogin.subTitle =
      'A feature to have guest access plugin that allows users to try out the product as a guest user';
    this.guestlogin.image = '';
    this.guestlogin.price = 900;
    this.guestlogin.duration = 1;
    this.guestlogin.id = 10185;

    this.loyaltysystem.icon = '';
    this.loyaltysystem.title = 'Loyalty System';
    this.loyaltysystem.subTitle =
      'Loyalty feature that offers discount/rewards to customers based on the kind of engagement.';
    this.loyaltysystem.image = '';
    this.loyaltysystem.price = 480;
    this.loyaltysystem.duration = 0.5;
    this.loyaltysystem.id = 10186;

    this.printdata.icon = '';
    this.printdata.title = 'Print Data';
    this.printdata.subTitle =
      'Ability to connect the application with a printer so as to print data like documents, images etc.';
    this.printdata.image = '';
    this.printdata.price = 660;
    this.printdata.duration = 1;
    this.printdata.id = 10187;

    this.shippingaddressvalidation.icon = '';
    this.shippingaddressvalidation.title = 'Shipping Address Validation';
    this.shippingaddressvalidation.subTitle =
      'A feature that checks the validity of the shipping address provided by the user during the checkout. ';
    this.shippingaddressvalidation.image = '';
    this.shippingaddressvalidation.price = 900;
    this.shippingaddressvalidation.duration = 1;
    this.shippingaddressvalidation.id = 10188;

    this.shippingchargecalculator.icon = '';
    this.shippingchargecalculator.title = 'Shipping Charge Calculator';
    this.shippingchargecalculator.subTitle =
      'A feature to Integrate with USPS, FedEx and Australia Post for real-time shipping charge calculation l';
    this.shippingchargecalculator.image = '';
    this.shippingchargecalculator.price = 900;
    this.shippingchargecalculator.duration = 1;
    this.shippingchargecalculator.id = 10189;

    this.shoppingcart.icon = '';
    this.shoppingcart.title = 'Shopping Cart';
    this.shoppingcart.subTitle =
      'A feature for customers to accumulate a list of items for purchase.';
    this.shoppingcart.image = '';
    this.shoppingcart.price = 480;
    this.shoppingcart.duration = 2.5;
    this.shoppingcart.id = 10190;

    this.storecredits.icon = '';
    this.storecredits.title = 'Store Credits';
    this.storecredits.subTitle =
      'A feature to create store credits as a convenient way to accumulate funds and convert gift card or refund to positive balance';
    this.storecredits.image = '';
    this.storecredits.price = 900;
    this.storecredits.duration = 1;
    this.storecredits.id = 10191;

    this.wishlist.icon = 'icon-10192';
    this.wishlist.title = 'Wishlist';
    this.wishlist.subTitle =
      'Allows users to add their favorite products for later purchase or share with other people.';
    this.wishlist.image = '';
    this.wishlist.price = 900;
    this.wishlist.duration = 1;
    this.wishlist.id = 10192;

    this.zipcodessearch.icon = '';
    this.zipcodessearch.title = 'Zip-codes Search';
    this.zipcodessearch.subTitle =
      'A feature to search location based on ZIP codes';
    this.zipcodessearch.image = '';
    this.zipcodessearch.price = 480;
    this.zipcodessearch.duration = 0.5;
    this.zipcodessearch.id = 10193;

    this.gamesscore.icon = 'icon-10194';
    this.gamesscore.title = 'Games Score';
    this.gamesscore.subTitle = 'Show game score.';
    this.gamesscore.image = '';
    this.gamesscore.price = 480;
    this.gamesscore.duration = 0.5;
    this.gamesscore.id = 10194;

    this.inappsoundsvibration.icon = 'icon-10195';
    this.inappsoundsvibration.title = 'In-App Sounds/Vibration';
    this.inappsoundsvibration.subTitle =
      'For users to customize app sounds, vibration, and notification tones';
    this.inappsoundsvibration.image =
      'https://nandbox.com/wp-content/uploads/2022/12/In-App-SoundsVibration.webp';
    this.inappsoundsvibration.price = 480;
    this.inappsoundsvibration.duration = 0.5;
    this.inappsoundsvibration.id = 10195;

    this.leaderboard.icon = 'icon-10196';
    this.leaderboard.title = 'Leaderboard';
    this.leaderboard.subTitle =
      'Show leaderboard for all the users on the application';
    this.leaderboard.image = '';
    this.leaderboard.price = 900;
    this.leaderboard.duration = 1;
    this.leaderboard.id = 10196;

    this.multiplayersupport.icon = 'icon-10197';
    this.multiplayersupport.title = 'Multiplayer Support';
    this.multiplayersupport.subTitle =
      'Allows real-time multiplayer support with ability to invite friends so they can join a gaming session.';
    this.multiplayersupport.image = '';
    this.multiplayersupport.price = 1800;
    this.multiplayersupport.duration = 2;
    this.multiplayersupport.id = 10197;

    this.physicsengine.icon = 'icon-10198';
    this.physicsengine.title = 'Physics Engine';
    this.physicsengine.subTitle =
      'Providing an approximate simulation of certain physical systems in games.';
    this.physicsengine.image = '';
    this.physicsengine.price = 480;
    this.physicsengine.duration = 0.5;
    this.physicsengine.id = 10198;

    this.amazonpayintegration.icon = '';
    this.amazonpayintegration.title = 'Amazon Pay Integration';
    this.amazonpayintegration.subTitle =
      'Ability to integrate Amazon Pay wallet option available while shopping in the application';
    this.amazonpayintegration.image = '';
    this.amazonpayintegration.price = 480;
    this.amazonpayintegration.duration = 0.5;
    this.amazonpayintegration.id = 10199;

    this.apiintegration.icon = '';
    this.apiintegration.title = 'API Integration';
    this.apiintegration.subTitle =
      'Integrate API with the front end to send requests and receive responses';
    this.apiintegration.image = '';
    this.apiintegration.price = 1350;
    this.apiintegration.duration = 1;
    this.apiintegration.id = 10200;

    this.applepayintegration.icon = '';
    this.applepayintegration.title = 'Apple Pay Integration';
    this.applepayintegration.subTitle =
      'Ability to integrate Apple pay option available while shopping in the application';
    this.applepayintegration.image = '';
    this.applepayintegration.price = 480;
    this.applepayintegration.duration = 0.5;
    this.applepayintegration.id = 10201;

    this.bhimupi.icon = '';
    this.bhimupi.title = 'Bhim UPI';
    this.bhimupi.subTitle =
      'Ability to integrate UPI option available while shopping in the application';
    this.bhimupi.image = '';
    this.bhimupi.price = 480;
    this.bhimupi.duration = 0.5;
    this.bhimupi.id = 10202;

    this.bluethoothintegration.icon = '';
    this.bluethoothintegration.title = 'Bluethooth Integration';
    this.bluethoothintegration.subTitle =
      "A feature that allows app to start automatically when phone connect to car's bluetooth ";
    this.bluethoothintegration.image = '';
    this.bluethoothintegration.price = 1320;
    this.bluethoothintegration.duration = 2;
    this.bluethoothintegration.id = 10203;

    this.customeadvertisments.icon = '';
    this.customeadvertisments.title = 'Custome Advertisments';
    this.customeadvertisments.subTitle =
      'Ability to create custom banners through admin console that can be visible in the application.';
    this.customeadvertisments.image = '';
    this.customeadvertisments.price = 480;
    this.customeadvertisments.duration = 1;
    this.customeadvertisments.id = 10204;

    this.docusignintegration.icon = '';
    this.docusignintegration.title = 'DocuSign Integration';
    this.docusignintegration.subTitle =
      'A feature for DocuSign Integration Framework that gives a broad range of functionality that can be readily integrated into custom solutions for your business';
    this.docusignintegration.image = '';
    this.docusignintegration.price = 480;
    this.docusignintegration.duration = 0.5;
    this.docusignintegration.id = 10205;

    this.freechargeintegration.icon = '';
    this.freechargeintegration.title = 'Freecharge Integration';
    this.freechargeintegration.subTitle =
      'Ability to integrate Freecharge wallet option available while shopping in the application';
    this.freechargeintegration.image = '';
    this.freechargeintegration.price = 480;
    this.freechargeintegration.duration = 0.5;
    this.freechargeintegration.id = 10206;

    this.freshdeskintegration.icon = '';
    this.freshdeskintegration.title = 'Freshdesk Integration';
    this.freshdeskintegration.subTitle =
      'A feature to integrate Freshdesk on iOS for online cloud-based customer service with all smart automation to get things done faster.';
    this.freshdeskintegration.image = '';
    this.freshdeskintegration.price = 480;
    this.freshdeskintegration.duration = 0.5;
    this.freshdeskintegration.id = 10207;

    this.gsuiteintegration.icon = '';
    this.gsuiteintegration.title = 'G Suite Integration';
    this.gsuiteintegration.subTitle =
      'A feature to integrate google wallet into your product';
    this.gsuiteintegration.image = '';
    this.gsuiteintegration.price = 480;
    this.gsuiteintegration.duration = 0.5;
    this.gsuiteintegration.id = 10208;

    this.gettyimageintegration.icon = '';
    this.gettyimageintegration.title = 'Getty Image Integration';
    this.gettyimageintegration.subTitle =
      'A feature to integrate Getty Image for a collection of over 40 million high-quality creative and editorial photos.';
    this.gettyimageintegration.image = '';
    this.gettyimageintegration.price = 480;
    this.gettyimageintegration.duration = 0.5;
    this.gettyimageintegration.id = 10209;

    this.googleadsenseintegration.icon = '';
    this.googleadsenseintegration.title = 'Google Adsense Integration';
    this.googleadsenseintegration.subTitle =
      'Ability to integrate Google Adsense account with the application so as to show Ads';
    this.googleadsenseintegration.image = '';
    this.googleadsenseintegration.price = 600;
    this.googleadsenseintegration.duration = 1;
    this.googleadsenseintegration.id = 10210;

    this.googleassistantintegration.icon = '';
    this.googleassistantintegration.title = 'Google Assistant Integration';
    this.googleassistantintegration.subTitle = 'Hands free with alexa';
    this.googleassistantintegration.image = '';
    this.googleassistantintegration.price = 600;
    this.googleassistantintegration.duration = 1;
    this.googleassistantintegration.id = 10211;

    this.googlewalletintegration.icon = '';
    this.googlewalletintegration.title = 'Google Wallet Integration';
    this.googlewalletintegration.subTitle =
      'A feature to integrate google wallet into your product';
    this.googlewalletintegration.image = '';
    this.googlewalletintegration.price = 480;
    this.googlewalletintegration.duration = 0.5;
    this.googlewalletintegration.id = 10212;

    this.homecontroloptions.icon = '';
    this.homecontroloptions.title = 'Home Control Options';
    this.homecontroloptions.subTitle =
      'A feature to control your home devices like AC,TV etc. via google assistant';
    this.homecontroloptions.image = '';
    this.homecontroloptions.price = 1320;
    this.homecontroloptions.duration = 2;
    this.homecontroloptions.id = 10213;

    this.hubsportintegration.icon = '';
    this.hubsportintegration.title = 'HubSport Integration';
    this.hubsportintegration.subTitle =
      'HubSpot integration allows you to seamlessly gather reviews and use them to better engage your users and generate new leads';
    this.hubsportintegration.image = '';
    this.hubsportintegration.price = 480;
    this.hubsportintegration.duration = 0.5;
    this.hubsportintegration.id = 10214;

    this.integratewearuilibrary.icon = '';
    this.integratewearuilibrary.title = 'Integrate Wear UI Library';
    this.integratewearuilibrary.subTitle =
      'A system to use Android wear libraries to enable consistent, optimized user interfaces across wearable apps.';
    this.integratewearuilibrary.image = '';
    this.integratewearuilibrary.price = 900;
    this.integratewearuilibrary.duration = 1;
    this.integratewearuilibrary.id = 10215;

    this.intercomeintegration.icon = '';
    this.intercomeintegration.title = 'Intercome Integration';
    this.intercomeintegration.subTitle =
      'A feature to integrate intercom for real-time customer support and more';
    this.intercomeintegration.image = '';
    this.intercomeintegration.price = 480;
    this.intercomeintegration.duration = 0.5;
    this.intercomeintegration.id = 10216;

    this.mailchimpintegration.icon = '';
    this.mailchimpintegration.title = 'MailChimp Integration';
    this.mailchimpintegration.subTitle =
      'A feature to integrate MailChimp as e-commerce providers so you can use your purchase data to send personalized campaigns';
    this.mailchimpintegration.image = '';
    this.mailchimpintegration.price = 480;
    this.mailchimpintegration.duration = 0.5;
    this.mailchimpintegration.id = 10217;

    this.mailinglistintegration.icon = '';
    this.mailinglistintegration.title = 'Mailing List Integration';
    this.mailinglistintegration.subTitle =
      'A feature to connect your MailChimp account and start building your list of contacts';
    this.mailinglistintegration.image = '';
    this.mailinglistintegration.price = 480;
    this.mailinglistintegration.duration = 0.5;
    this.mailinglistintegration.id = 10218;

    this.mixpanelanalyticsintegration.icon = '';
    this.mixpanelanalyticsintegration.title = 'Mixpanel Analytics Integration';
    this.mixpanelanalyticsintegration.subTitle =
      'A feature to integrate mixpanel SDK, api for analytics';
    this.mixpanelanalyticsintegration.image = '';
    this.mixpanelanalyticsintegration.price = 480;
    this.mixpanelanalyticsintegration.duration = 0.5;
    this.mixpanelanalyticsintegration.id = 10219;

    this.mobiwikintegration.icon = '';
    this.mobiwikintegration.title = 'Mobiwik Integration';
    this.mobiwikintegration.subTitle =
      'Ability to integrate Mobikwik wallet option available while shopping in the application';
    this.mobiwikintegration.image = '';
    this.mobiwikintegration.price = 480;
    this.mobiwikintegration.duration = 0.5;
    this.mobiwikintegration.id = 10220;

    this.neuralnetapiforandroid.icon = '';
    this.neuralnetapiforandroid.title = 'Neural Net API for Android';
    this.neuralnetapiforandroid.subTitle =
      'Enable machine intelligence on Android, with Neural Networks API via the NDK. It enables hardware-accelerated inference operations on supported devices';
    this.neuralnetapiforandroid.image = '';
    this.neuralnetapiforandroid.price = 1800;
    this.neuralnetapiforandroid.duration = 2;
    this.neuralnetapiforandroid.id = 10221;

    this.newsintegrtation.icon = '';
    this.newsintegrtation.title = 'News Integrtation';
    this.newsintegrtation.subTitle =
      'Ability to synchronize  with Google News and display results in the application';
    this.newsintegrtation.image = '';
    this.newsintegrtation.price = 1170;
    this.newsintegrtation.duration = 0.5;
    this.newsintegrtation.id = 10222;

    this.onlinebookingintegration.icon = '';
    this.onlinebookingintegration.title = 'Online Booking Integration';
    this.onlinebookingintegration.subTitle =
      'Integrating 3rd party apps that allow online booking.';
    this.onlinebookingintegration.image = '';
    this.onlinebookingintegration.price = 1200;
    this.onlinebookingintegration.duration = 1.5;
    this.onlinebookingintegration.id = 10223;

    this.outlookintegration.icon = '';
    this.outlookintegration.title = 'Outlook Integration';
    this.outlookintegration.subTitle =
      'A feature to integrate Outlooks to get contacts, tasks and calendar events synchronized automatically';
    this.outlookintegration.image = '';
    this.outlookintegration.price = 480;
    this.outlookintegration.duration = 0.5;
    this.outlookintegration.id = 10224;

    this.payrollintegration.icon = '';
    this.payrollintegration.title = 'Payroll Integration';
    this.payrollintegration.subTitle =
      'A feature to integrate Outlooks to get contacts, tasks and calendar events synchronized automatically';
    this.payrollintegration.image = '';
    this.payrollintegration.price = 480;
    this.payrollintegration.duration = 0.5;
    this.payrollintegration.id = 10225;

    this.paytmwalletintegration.icon = '';
    this.paytmwalletintegration.title = 'Paytm Wallet Integration';
    this.paytmwalletintegration.subTitle =
      'Ability to integrate Paytm wallet option available while shopping in the application';
    this.paytmwalletintegration.image = '';
    this.paytmwalletintegration.price = 480;
    this.paytmwalletintegration.duration = 0.5;
    this.paytmwalletintegration.id = 10226;

    this.quickbooksintegration.icon = '';
    this.quickbooksintegration.title = 'Quickbooks Integration';
    this.quickbooksintegration.subTitle =
      'Ability to integrate Paytm wallet option available while shopping in the application';
    this.quickbooksintegration.image = '';
    this.quickbooksintegration.price = 480;
    this.quickbooksintegration.duration = 0.5;
    this.quickbooksintegration.id = 10227;

    this.radiointegration.icon = '';
    this.radiointegration.title = 'Radio Integration';
    this.radiointegration.subTitle = 'A feature to integrate and listen radio';
    this.radiointegration.image = '';
    this.radiointegration.price = 2910;
    this.radiointegration.duration = 2;
    this.radiointegration.id = 10228;

    this.rssfeeds.icon = '';
    this.rssfeeds.title = 'RSS feeds';
    this.rssfeeds.subTitle =
      'A feature to add a RSS feed for content syndication from across multiple platforms/ publishers';
    this.rssfeeds.image = '';
    this.rssfeeds.price = 480;
    this.rssfeeds.duration = 0.5;
    this.rssfeeds.id = 10229;

    this.rupayintegration.icon = '';
    this.rupayintegration.title = 'Rupay Integration';
    this.rupayintegration.subTitle =
      'A feature to add RSS feed for content syndication from across multiple platforms/ publishers';
    this.rupayintegration.image = '';
    this.rupayintegration.price = 480;
    this.rupayintegration.duration = 0.5;
    this.rupayintegration.id = 10230;

    this.salesforceintegration.icon = '';
    this.salesforceintegration.title = 'Salesforce Integration';
    this.salesforceintegration.subTitle =
      'Integration with Salesforce platform technologies.';
    this.salesforceintegration.image = '';
    this.salesforceintegration.price = 900;
    this.salesforceintegration.duration = 1;
    this.salesforceintegration.id = 10231;

    this.scheduleonceintegration.icon = '';
    this.scheduleonceintegration.title = 'ScheduleOnce Integration';
    this.scheduleonceintegration.subTitle =
      'Integration with Salesforce platform technologies.';
    this.scheduleonceintegration.image = '';
    this.scheduleonceintegration.price = 480;
    this.scheduleonceintegration.duration = 0.5;
    this.scheduleonceintegration.id = 10232;

    this.sendyintegration.icon = '';
    this.sendyintegration.title = 'Sendy Integration';
    this.sendyintegration.subTitle =
      'A feature to subscribe/ unsubscribe users and send newsletters';
    this.sendyintegration.image = '';
    this.sendyintegration.price = 930;
    this.sendyintegration.duration = 1;
    this.sendyintegration.id = 10233;

    this.simpledisqusintegration.icon = '';
    this.simpledisqusintegration.title = 'Simple Disqus Integration';
    this.simpledisqusintegration.subTitle =
      'A feature to add to your website a Disqus by simply adding your Disqus ID';
    this.simpledisqusintegration.image = '';
    this.simpledisqusintegration.price = 480;
    this.simpledisqusintegration.duration = 0.5;
    this.simpledisqusintegration.id = 10234;

    this.slackintegration.icon = '';
    this.slackintegration.title = 'Slack Integration';
    this.slackintegration.subTitle =
      'Integration of Slack to send app-related updates to specific channels.';
    this.slackintegration.image = '';
    this.slackintegration.price = 480;
    this.slackintegration.duration = 0.5;
    this.slackintegration.id = 10235;

    this.syncswithevernote.icon = '';
    this.syncswithevernote.title = 'Syncs with Evernote';
    this.syncswithevernote.subTitle =
      "A feature that let's user sync their local content with Evernote";
    this.syncswithevernote.image = '';
    this.syncswithevernote.price = 900;
    this.syncswithevernote.duration = 1;
    this.syncswithevernote.id = 10236;

    this.twiliovoiceintegration.icon = '';
    this.twiliovoiceintegration.title = 'Twilio Voice Integration';
    this.twiliovoiceintegration.subTitle =
      'A feature to integrate Twilio for phone call experiences with one API to make, receive, control and monitor calls around the globe.';
    this.twiliovoiceintegration.image = '';
    this.twiliovoiceintegration.price = 480;
    this.twiliovoiceintegration.duration = 0.5;
    this.twiliovoiceintegration.id = 10237;

    this.weatherintegration.icon = '';
    this.weatherintegration.title = 'Weather Integration';
    this.weatherintegration.subTitle =
      'Ability to syncronize with Google Weather report and display results in the application .';
    this.weatherintegration.image = '';
    this.weatherintegration.price = 1170;
    this.weatherintegration.duration = 0.5;
    this.weatherintegration.id = 10238;

    this.youtubeintegration.icon = '';
    this.youtubeintegration.title = 'Youtube Integration';
    this.youtubeintegration.subTitle =
      'For users to search for and share youtube videos';
    this.youtubeintegration.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Youtube-Integration.webp';
    this.youtubeintegration.price = 900;
    this.youtubeintegration.duration = 1;
    this.youtubeintegration.id = 10239;

    this.barcodesscanner.icon = '';
    this.barcodesscanner.title = 'Barcodes Scanner';
    this.barcodesscanner.subTitle =
      'Ability to read, scan and display results with a barcode scanning feature.';
    this.barcodesscanner.image = '';
    this.barcodesscanner.price = 900;
    this.barcodesscanner.duration = 1;
    this.barcodesscanner.id = 10240;

    this.itemavailability.icon = '';
    this.itemavailability.title = 'Item Availability';
    this.itemavailability.subTitle =
      "A feature to calculate the availability of a product in the user's cart based on the amount of current reservation of the product and the stock limit of the same product. ";
    this.itemavailability.image = '';
    this.itemavailability.price = 1200;
    this.itemavailability.duration = 1.5;
    this.itemavailability.id = 10241;

    this.itemgrouper.icon = '';
    this.itemgrouper.title = 'Item Grouper';
    this.itemgrouper.subTitle =
      'A feature to group the terms in the card and in the wishlist according to predefined grouping rules. ';
    this.itemgrouper.image = '';
    this.itemgrouper.price = 1200;
    this.itemgrouper.duration = 1.5;
    this.itemgrouper.id = 10242;

    this.maps.icon = '';
    this.maps.title = 'Maps';
    this.maps.subTitle =
      'A feature to add a map in the application using Google API.';
    this.maps.image = '';
    this.maps.price = 480;
    this.maps.duration = 2;
    this.maps.id = 10243;

    this.mapsettings.icon = '';
    this.mapsettings.title = 'Map Settings';
    this.mapsettings.subTitle = 'Display movements on the map ';
    this.mapsettings.image = '';
    this.mapsettings.price = 1320;
    this.mapsettings.duration = 2;
    this.mapsettings.id = 10244;

    this.ordersummary.icon = '';
    this.ordersummary.title = 'Order Summary';
    this.ordersummary.subTitle = 'Order Summary list';
    this.ordersummary.image = '';
    this.ordersummary.price = 1320;
    this.ordersummary.duration = 2;
    this.ordersummary.id = 10245;

    this.pricingengine.icon = '';
    this.pricingengine.title = 'Pricing Engine';
    this.pricingengine.subTitle =
      'A feature to predict the expected price based on the different selections and variables.';
    this.pricingengine.image = '';
    this.pricingengine.price = 1800;
    this.pricingengine.duration = 2;
    this.pricingengine.id = 10246;

    this.qrcodes.icon = '';
    this.qrcodes.title = 'QR Codes';
    this.qrcodes.subTitle =
      'Ability to read, scan QR codes and, display results.';
    this.qrcodes.image = '';
    this.qrcodes.price = 900;
    this.qrcodes.duration = 1;
    this.qrcodes.id = 10247;

    this.requestmanagement.icon = '';
    this.requestmanagement.title = 'Request Management';
    this.requestmanagement.subTitle =
      'A feature to help the teams manage all the incoming requests and queries.';
    this.requestmanagement.image = '';
    this.requestmanagement.price = 900;
    this.requestmanagement.duration = 1;
    this.requestmanagement.id = 10248;

    this.rfid.icon = 'icon-10249';
    this.rfid.title = 'RFID';
    this.rfid.subTitle =
      'Ability to automatically identify and track tags attached to objects.';
    this.rfid.image = '';
    this.rfid.price = 900;
    this.rfid.duration = 1;
    this.rfid.id = 10249;

    this.settings.icon = 'icon-10250';
    this.settings.title = 'Settings';
    this.settings.subTitle =
      'Basic app settings such as change password, deactivate an account, change profile picture, and edit profile info.';
    this.settings.image = '';
    this.settings.price = 1200;
    this.settings.duration = 1.5;
    this.settings.id = 10250;

    this.summarycard.icon = '';
    this.summarycard.title = 'Summary Card';
    this.summarycard.subTitle =
      'A card view summarising all the selections made.';
    this.summarycard.image = '';
    this.summarycard.price = 480;
    this.summarycard.duration = 0.5;
    this.summarycard.id = 10251;

    this.billableourstracking.icon = '';
    this.billableourstracking.title = 'Billable ours tracking';
    this.billableourstracking.subTitle =
      'A feature to track billable hours for better payment management';
    this.billableourstracking.image = '';
    this.billableourstracking.price = 1800;
    this.billableourstracking.duration = 2;
    this.billableourstracking.id = 10252;

    this.candidatemanagement.icon = 'icon-10253';
    this.candidatemanagement.title = 'Candidate Management';
    this.candidatemanagement.subTitle =
      "Allowing adding (storage), editing, deleting, searching, filtering, and organizing for candidate's profile & resume.";
    this.candidatemanagement.image = '';
    this.candidatemanagement.price = 1800;
    this.candidatemanagement.duration = 2;
    this.candidatemanagement.id = 10253;

    this.certificationtracking.icon = 'icon-10254';
    this.certificationtracking.title = 'Certification Tracking';
    this.certificationtracking.subTitle =
      'Centralizing and managing the administration of third-party training required of employees in their designated job role.';
    this.certificationtracking.image = '';
    this.certificationtracking.price = 900;
    this.certificationtracking.duration = 1;
    this.certificationtracking.id = 10254;

    this.demoaccount.icon = '';
    this.demoaccount.title = 'Demo Account';
    this.demoaccount.subTitle =
      'Allows the users to have practice sessions on a demo account before going live with a real account';
    this.demoaccount.image = '';
    this.demoaccount.price = 1800;
    this.demoaccount.duration = 2;
    this.demoaccount.id = 10255;

    this.formapprovalworkflow.icon = '';
    this.formapprovalworkflow.title = 'Form Approval Workflow';
    this.formapprovalworkflow.subTitle =
      'A feature to streamline your forms approval processes by allowing employees to quickly approve, deny or comment';
    this.formapprovalworkflow.image = '';
    this.formapprovalworkflow.price = 1800;
    this.formapprovalworkflow.duration = 2;
    this.formapprovalworkflow.id = 10256;

    this.leadmanagement.icon = '';
    this.leadmanagement.title = 'Lead Management ';
    this.leadmanagement.subTitle =
      'A feature to efficiently track, and manage all the sales leads to your product/ service';
    this.leadmanagement.image = '';
    this.leadmanagement.price = 1800;
    this.leadmanagement.duration = 2;
    this.leadmanagement.id = 10257;

    this.organisationhierarchy.icon = '';
    this.organisationhierarchy.title = 'Organisation Hierarchy';
    this.organisationhierarchy.subTitle =
      'A feature to show the lines of authority, communications, rights and duties of an organization';
    this.organisationhierarchy.image = '';
    this.organisationhierarchy.price = 1200;
    this.organisationhierarchy.duration = 1.5;
    this.organisationhierarchy.id = 10258;

    this.switchaccounts.icon = '';
    this.switchaccounts.title = 'Switch Accounts';
    this.switchaccounts.subTitle =
      'A feature that allows you to add multiple accounts, and switch between them without having to log out of the application';
    this.switchaccounts.image = '';
    this.switchaccounts.price = 1320;
    this.switchaccounts.duration = 2;
    this.switchaccounts.id = 10259;

    this.timesheetmanagement.icon = 'icon-10260';
    this.timesheetmanagement.title = 'Timesheet Management ';
    this.timesheetmanagement.subTitle =
      'Timesheet management and prediction that looks at the availability of the resources and does billing suggestions';
    this.timesheetmanagement.image = '';
    this.timesheetmanagement.price = 1800;
    this.timesheetmanagement.duration = 2;
    this.timesheetmanagement.id = 10260;

    this.usergroups.icon = 'icon-10261';
    this.usergroups.title = 'User Groups';
    this.usergroups.subTitle =
      "Allows users to easily manage team members' access to specific folders";
    this.usergroups.image = '';
    this.usergroups.price = 900;
    this.usergroups.duration = 1;
    this.usergroups.id = 10261;

    this.videosupport.icon = '';
    this.videosupport.title = '360 Video Support';
    this.videosupport.subTitle =
      'A feature that allows you to view the 360-degree videos in the application';
    this.videosupport.image = '';
    this.videosupport.price = 2910;
    this.videosupport.duration = 2;
    this.videosupport.id = 10262;

    this.archive.icon = 'icon-10263';
    this.archive.title = 'Archive';
    this.archive.subTitle =
      'Allows users to hide their photos, videos, and documents without deleting them';
    this.archive.image = '';
    this.archive.price = 900;
    this.archive.duration = 1;
    this.archive.id = 10263;

    this.audioequalizer.icon = '';
    this.audioequalizer.title = 'Audio Equalizer';
    this.audioequalizer.subTitle =
      'A feature that allows the user to see graphically and control individually a number of different frequency bands in the application';
    this.audioequalizer.image = '';
    this.audioequalizer.price = 1320;
    this.audioequalizer.duration = 2;
    this.audioequalizer.id = 10264;

    this.bookmarkarticlesimages.icon = 'icon-10265';
    this.bookmarkarticlesimages.title = 'Bookmark articles /images';
    this.bookmarkarticlesimages.subTitle =
      'A feature for users to bookmark links, articles, images on your platform';
    this.bookmarkarticlesimages.image = '';
    this.bookmarkarticlesimages.price = 900;
    this.bookmarkarticlesimages.duration = 1;
    this.bookmarkarticlesimages.id = 10265;

    this.hdvideoformats.icon = 'icon-10266';
    this.hdvideoformats.title = 'HD Video Formats';
    this.hdvideoformats.subTitle = 'Enable uploading and downloading HD videos';
    this.hdvideoformats.image =
      'https://nandbox.com/wp-content/uploads/2022/12/HD-Video-Formats.webp';
    this.hdvideoformats.price = 900;
    this.hdvideoformats.duration = 1;
    this.hdvideoformats.id = 10266;

    this.imagepicker.icon = 'icon-10267';
    this.imagepicker.title = 'Image Picker';
    this.imagepicker.subTitle =
      'A feature to support upload and download of Images';
    this.imagepicker.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Image-Picker.webp';
    this.imagepicker.price = 480;
    this.imagepicker.duration = 0.5;
    this.imagepicker.id = 10267;

    this.imagethumbnailgeneration.icon = 'icon-10268';
    this.imagethumbnailgeneration.title = 'Image Thumbnail Generation';
    this.imagethumbnailgeneration.subTitle =
      'Enables showing a thumbnail with the preview of an image';
    this.imagethumbnailgeneration.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Image-Thumbnail-Generation.webp';
    this.imagethumbnailgeneration.price = 900;
    this.imagethumbnailgeneration.duration = 1;
    this.imagethumbnailgeneration.id = 10268;

    this.imagesmanagement.icon = 'icon-10269';
    this.imagesmanagement.title = 'Images Management ';
    this.imagesmanagement.subTitle =
      'Enable saving and managing images in different formats, such as JPG, PNG, SVG, and more';
    this.imagesmanagement.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Images-Management.webp';
    this.imagesmanagement.price = 1800;
    this.imagesmanagement.duration = 2;
    this.imagesmanagement.id = 10269;

    this.photofilter.icon = '';
    this.photofilter.title = 'Photo Filter';
    this.photofilter.subTitle =
      'Edit your photo and apply effects and filters to your photo directly.';
    this.photofilter.image = '';
    this.photofilter.price = 1800;
    this.photofilter.duration = 2;
    this.photofilter.id = 10270;

    this.photos.icon = 'icon-10271';
    this.photos.title = 'Photos';
    this.photos.subTitle =
      'Enable users to share, delete, or view photos in full screen';
    this.photos.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Photos.webp';
    this.photos.price = 1800;
    this.photos.duration = 2;
    this.photos.id = 10271;

    this.preview.icon = '';
    this.preview.title = 'Preview';
    this.preview.subTitle = 'Enable processing of images in RAW format';
    this.preview.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Preview.webp';
    this.preview.price = 1320;
    this.preview.duration = 2;
    this.preview.id = 10272;

    this.rawformatprocessing.icon = '';
    this.rawformatprocessing.title = 'RAW format Processing';
    this.rawformatprocessing.subTitle =
      'A feature to allow your app to process RAW format';
    this.rawformatprocessing.image = '';
    this.rawformatprocessing.price = 1800;
    this.rawformatprocessing.duration = 2;
    this.rawformatprocessing.id = 10273;

    this.startvideochat.icon = '';
    this.startvideochat.title = 'Start Video Chat';
    this.startvideochat.subTitle =
      'A feature that lets you start a video call with other users on the app';
    this.startvideochat.image = '';
    this.startvideochat.price = 1320;
    this.startvideochat.duration = 2;
    this.startvideochat.id = 10274;

    this.subtitles.icon = '';
    this.subtitles.title = 'Subtitles';
    this.subtitles.subTitle = 'Video contains subtitles';
    this.subtitles.image = '';
    this.subtitles.price = 330;
    this.subtitles.duration = 0.5;
    this.subtitles.id = 10275;

    this.videoautoplay.icon = 'icon-10276';
    this.videoautoplay.title = 'Video Autoplay';
    this.videoautoplay.subTitle =
      'Allows users to automatically play the next video on the list.';
    this.videoautoplay.image = '';
    this.videoautoplay.price = 900;
    this.videoautoplay.duration = 1;
    this.videoautoplay.id = 10276;

    this.videobackgrounds.icon = '';
    this.videobackgrounds.title = 'Video Backgrounds';
    this.videobackgrounds.subTitle =
      'A feature to add a video background to your landing page or your app login for a better user experience.';
    this.videobackgrounds.image = '';
    this.videobackgrounds.price = 1200;
    this.videobackgrounds.duration = 1.5;
    this.videobackgrounds.id = 10277;

    this.videocall.icon = '';
    this.videocall.title = 'Video Call';
    this.videocall.subTitle = 'Enables video calling';
    this.videocall.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Video-Call.webp';
    this.videocall.price = 1320;
    this.videocall.duration = 2;
    this.videocall.id = 10278;

    this.videoeditingtools.icon = 'icon-10279';
    this.videoeditingtools.title = 'Video Editing Tools';
    this.videoeditingtools.subTitle =
      'Allows the users to add filters and effects to videos, with a selection of built-in filters, and tools for adjusting the brightness, color, warmth, saturation, contrast, fade, and other effects.';
    this.videoeditingtools.image = '';
    this.videoeditingtools.price = 900;
    this.videoeditingtools.duration = 1;
    this.videoeditingtools.id = 10279;

    this.videolibrary.icon = 'icon-10280';
    this.videolibrary.title = 'Video Library';
    this.videolibrary.subTitle = 'Maintaining a library of videos.';
    this.videolibrary.image = '';
    this.videolibrary.price = 900;
    this.videolibrary.duration = 1;
    this.videolibrary.id = 10280;

    this.videomanagement.icon = 'icon-10281';
    this.videomanagement.title = 'Video Management ';
    this.videomanagement.subTitle =
      'A feature to manage your videos in different formats such as MP4, ProRes, MOV and more';
    this.videomanagement.image = '';
    this.videomanagement.price = 1800;
    this.videomanagement.duration = 2;
    this.videomanagement.id = 10281;

    this.videotrimming.icon = 'icon-10282';
    this.videotrimming.title = 'Video Trimming';
    this.videotrimming.subTitle =
      'A video editor for trimming videos for a previously recorded clip, and enables re-encoding it to lower quality (compressing)';
    this.videotrimming.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Video-Trimming.webp';
    this.videotrimming.price = 1800;
    this.videotrimming.duration = 2;
    this.videotrimming.id = 10282;

    this.videos.icon = 'icon-10283';
    this.videos.title = 'Videos';
    this.videos.subTitle =
      'Support for uploaded video files with simple controls, pause, play, volume control in normal and full-screen mode. ';
    this.videos.image = '';
    this.videos.price = 1800;
    this.videos.duration = 2;
    this.videos.id = 10283;

    this.webinars.icon = 'icon-10284';
    this.webinars.title = 'Webinars';
    this.webinars.subTitle =
      'A presentation, lecture, workshop or seminar that is transmitted over the Web.';
    this.webinars.image = '';
    this.webinars.price = 480;
    this.webinars.duration = 0.5;
    this.webinars.id = 10284;

    this.annotations.icon = 'icon-10285';
    this.annotations.title = 'Annotations';
    this.annotations.subTitle =
      'A feature to drop critical or explanatory note or body of notes added to a text as annotations';
    this.annotations.image = '';
    this.annotations.price = 900;
    this.annotations.duration = 1;
    this.annotations.id = 10285;

    this.appointmentmanagement.icon = 'icon-10286';
    this.appointmentmanagement.title = 'Appointment Management';
    this.appointmentmanagement.subTitle =
      'Allows the users to set up an appointment; this includes SMS/Email integration for updates and reminders.';
    this.appointmentmanagement.image = '';
    this.appointmentmanagement.price = 1200;
    this.appointmentmanagement.duration = 1.5;
    this.appointmentmanagement.id = 10286;

    this.conferencecall.icon = 'icon-10287';
    this.conferencecall.title = 'Conference Call';
    this.conferencecall.subTitle =
      'Allows the users to have video/audio call with several people at the same time.';
    this.conferencecall.image = '';
    this.conferencecall.price = 1200;
    this.conferencecall.duration = 1.5;
    this.conferencecall.id = 10287;

    this.convertfile.icon = 'icon-10288';
    this.convertfile.title = 'Convert File';
    this.convertfile.subTitle = 'Convert a File to a specific format';
    this.convertfile.image = '';
    this.convertfile.price = 900;
    this.convertfile.duration = 1;
    this.convertfile.id = 10288;

    this.datastorage.icon = '';
    this.datastorage.title = 'Data Storage';
    this.datastorage.subTitle =
      'A feature that manages and stores personal documents on the application';
    this.datastorage.image = '';
    this.datastorage.price = 660;
    this.datastorage.duration = 1;
    this.datastorage.id = 10289;

    this.donotdisturb.icon = '';
    this.donotdisturb.title = 'Do Not Disturb';
    this.donotdisturb.subTitle = 'A feature to enable Do Not Disturb mode.';
    this.donotdisturb.image = '';
    this.donotdisturb.price = 900;
    this.donotdisturb.duration = 1;
    this.donotdisturb.id = 10290;

    this.downloadoptions.icon = '';
    this.downloadoptions.title = 'Download Options';
    this.downloadoptions.subTitle =
      'Enable downloading documents or media files shared on your app';
    this.downloadoptions.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Download-Options.webp';
    this.downloadoptions.price = 330;
    this.downloadoptions.duration = 0.5;
    this.downloadoptions.id = 10291;

    this.elasticsearch.icon = '';
    this.elasticsearch.title = 'Elastic Search';
    this.elasticsearch.subTitle =
      'A feature that lets you show word predictions or hints basis current entered data';
    this.elasticsearch.image = '';
    this.elasticsearch.price = 1320;
    this.elasticsearch.duration = 2;
    this.elasticsearch.id = 10292;

    this.googlecalendarsync.icon = '';
    this.googlecalendarsync.title = 'Google Calendar Sync';
    this.googlecalendarsync.subTitle = 'A feature to sync google calendar';
    this.googlecalendarsync.image = '';
    this.googlecalendarsync.price = 480;
    this.googlecalendarsync.duration = 0.5;
    this.googlecalendarsync.id = 10293;

    this.icalendarsupport.icon = 'icon-10294';
    this.icalendarsupport.title = 'iCalendar Support';
    this.icalendarsupport.subTitle =
      'A feature to add iCalendar support for Mac OSX users';
    this.icalendarsupport.image = '';
    this.icalendarsupport.price = 480;
    this.icalendarsupport.duration = 0.5;
    this.icalendarsupport.id = 10294;

    this.kanbanboard.icon = 'icon-10295';
    this.kanbanboard.title = 'Kanban Board';
    this.kanbanboard.subTitle = 'A list of lists to track a project and tasks.';
    this.kanbanboard.image = '';
    this.kanbanboard.price = 1800;
    this.kanbanboard.duration = 2;
    this.kanbanboard.id = 10295;

    this.languageoptions.icon = '';
    this.languageoptions.title = 'Language Options';
    this.languageoptions.subTitle =
      'A feature to manage language options and change app content in selected language';
    this.languageoptions.image = '';
    this.languageoptions.price = 1170;
    this.languageoptions.duration = 2;
    this.languageoptions.id = 10296;

    this.navigationmenu.icon = '';
    this.navigationmenu.title = 'Navigation Menu';
    this.navigationmenu.subTitle =
      'A feature to display a mobile navigation menu instead of  desktop navigation.';
    this.navigationmenu.image = '';
    this.navigationmenu.price = 1320;
    this.navigationmenu.duration = 2;
    this.navigationmenu.id = 10297;

    this.offlinebrowsing.icon = '';
    this.offlinebrowsing.title = 'Offline Browsing';
    this.offlinebrowsing.subTitle = 'Download content for offline reading';
    this.offlinebrowsing.image = '';
    this.offlinebrowsing.price = 1470;
    this.offlinebrowsing.duration = 1;
    this.offlinebrowsing.id = 10298;

    this.onboardingguide.icon = '';
    this.onboardingguide.title = 'Onboarding Guide';
    this.onboardingguide.subTitle =
      'A feature to show USPs of the application so as to make users familiar with the app';
    this.onboardingguide.image = '';
    this.onboardingguide.price = 300;
    this.onboardingguide.duration = 0.5;
    this.onboardingguide.id = 10299;

    this.prioritizeconversation.icon = '';
    this.prioritizeconversation.title = 'Prioritize Conversation';
    this.prioritizeconversation.subTitle =
      'Enables you to elevate and demote conversations';
    this.prioritizeconversation.image = '';
    this.prioritizeconversation.price = 1320;
    this.prioritizeconversation.duration = 2;
    this.prioritizeconversation.id = 10300;

    this.recurringappointments.icon = 'icon-10301';
    this.recurringappointments.title = 'Recurring Appointments';
    this.recurringappointments.subTitle =
      'A feature creating a series of appointments that occur at regular intervals over a set period of time';
    this.recurringappointments.image = '';
    this.recurringappointments.price = 900;
    this.recurringappointments.duration = 1;
    this.recurringappointments.id = 10301;

    this.screenshoteditor.icon = '';
    this.screenshoteditor.title = 'Screenshot Editor';
    this.screenshoteditor.subTitle = 'A feature to add a screenshot editor';
    this.screenshoteditor.image = '';
    this.screenshoteditor.price = 1200;
    this.screenshoteditor.duration = 1.5;
    this.screenshoteditor.id = 10302;

    this.splitviewinterface.icon = '';
    this.splitviewinterface.title = 'Split View Interface';
    this.splitviewinterface.subTitle =
      'A feature to Provide Split View Interface';
    this.splitviewinterface.image = '';
    this.splitviewinterface.price = 1200;
    this.splitviewinterface.duration = 1.5;
    this.splitviewinterface.id = 10303;

    this.syncoptions.icon = '';
    this.syncoptions.title = 'Sync Options';
    this.syncoptions.subTitle =
      'An option to Synchronise the application with other devices using your account';
    this.syncoptions.image = '';
    this.syncoptions.price = 600;
    this.syncoptions.duration = 1;
    this.syncoptions.id = 10304;

    this.volumecontrol.icon = '';
    this.volumecontrol.title = 'Volume Control';
    this.volumecontrol.subTitle =
      'A feature to control volume through an application';
    this.volumecontrol.image = '';
    this.volumecontrol.price = 660;
    this.volumecontrol.duration = 1;
    this.volumecontrol.id = 10305;

    this.worldclock.icon = '';
    this.worldclock.title = 'World Clock';
    this.worldclock.subTitle =
      'A feature that displays world clocks on a view basis country selected';
    this.worldclock.image = '';
    this.worldclock.price = 660;
    this.worldclock.duration = 1;
    this.worldclock.id = 10306;

    this.xmlcsvtemplatebuilder.icon = '';
    this.xmlcsvtemplatebuilder.title = 'XML &CSV Template Builder';
    this.xmlcsvtemplatebuilder.subTitle =
      'A feature to add a builder that can edit XML and CSV templates';
    this.xmlcsvtemplatebuilder.image = '';
    this.xmlcsvtemplatebuilder.price = 1200;
    this.xmlcsvtemplatebuilder.duration = 1.5;
    this.xmlcsvtemplatebuilder.id = 10307;

    this.abusedetector.icon = '';
    this.abusedetector.title = 'Abuse Detector';
    this.abusedetector.subTitle =
      'A feature that uses Natural Language Processing to detect abusive text content';
    this.abusedetector.image = '';
    this.abusedetector.price = 1200;
    this.abusedetector.duration = 1.5;
    this.abusedetector.id = 10308;

    this.answerbot.icon = '';
    this.answerbot.title = 'Answer Bot';
    this.answerbot.subTitle =
      'Enables a Bot to respond to support tickets by sending an automated email that lists potentially relevant knowledge base, using machine learning ';
    this.answerbot.image = '';
    this.answerbot.price = 1800;
    this.answerbot.duration = 2;
    this.answerbot.id = 10309;

    this.barcodesettings.icon = '';
    this.barcodesettings.title = 'Barcode Settings';
    this.barcodesettings.subTitle =
      'Ability to custom set Barcode settings via the application. ';
    this.barcodesettings.image = '';
    this.barcodesettings.price = 1320;
    this.barcodesettings.duration = 2;
    this.barcodesettings.id = 10310;

    this.contexttagging.icon = 'icon-10311';
    this.contexttagging.title = 'Context Tagging';
    this.contexttagging.subTitle =
      'Allows the users to group similar tasks by using context-specific tags';
    this.contexttagging.image = '';
    this.contexttagging.price = 900;
    this.contexttagging.duration = 1;
    this.contexttagging.id = 10311;

    this.coreml.icon = 'icon-10312';
    this.coreml.title = 'Core ML';
    this.coreml.subTitle = 'Core ML integration for Apple apps';
    this.coreml.image = '';
    this.coreml.price = 1800;
    this.coreml.duration = 1;
    this.coreml.id = 10312;

    this.duplicatedetection.icon = '';
    this.duplicatedetection.title = 'Duplicate Detection';
    this.duplicatedetection.subTitle =
      'A feature to detect duplicate content on your platform to avoid data duplicity and redundancy';
    this.duplicatedetection.image = '';
    this.duplicatedetection.price = 1800;
    this.duplicatedetection.duration = 2;
    this.duplicatedetection.id = 10313;

    this.emotiondetection.icon = '';
    this.emotiondetection.title = 'Emotion Detection';
    this.emotiondetection.subTitle =
      'A feature to predict the alignment of any text with each of 5 emotions - anger, fear, joy, sadness, and surprise)';
    this.emotiondetection.image = '';
    this.emotiondetection.price = 1800;
    this.emotiondetection.duration = 2;
    this.emotiondetection.id = 10314;

    this.facetagging.icon = 'icon-10315';
    this.facetagging.title = 'Face Tagging';
    this.facetagging.subTitle =
      'Automatically identifies faces in the catalog images and allows users to tag them.';
    this.facetagging.image = '';
    this.facetagging.price = 1800;
    this.facetagging.duration = 2;
    this.facetagging.id = 10315;

    this.imagecategorisation.icon = 'icon-10316';
    this.imagecategorisation.title = 'Image Categorisation';
    this.imagecategorisation.subTitle =
      'Clustering of visually similar image using machine learning';
    this.imagecategorisation.image = '';
    this.imagecategorisation.price = 1800;
    this.imagecategorisation.duration = 2;
    this.imagecategorisation.id = 10316;

    this.keywordextractor.icon = '';
    this.keywordextractor.title = 'Keyword Extractor';
    this.keywordextractor.subTitle =
      'A feature that uses Artificial Intelligence to extract keywords from a set of words, paragraphs, sentences.';
    this.keywordextractor.image = '';
    this.keywordextractor.price = 1800;
    this.keywordextractor.duration = 2;
    this.keywordextractor.id = 10317;

    this.languagedetection.icon = 'icon-10318';
    this.languagedetection.title = 'Language Detection';
    this.languagedetection.subTitle =
      'A feature to detect the language used by the user.';
    this.languagedetection.image = '';
    this.languagedetection.price = 1800;
    this.languagedetection.duration = 2;
    this.languagedetection.id = 10318;

    this.multiplegesturerecognizer.icon = '';
    this.multiplegesturerecognizer.title = 'Multiple Gesture Recognizer';
    this.multiplegesturerecognizer.subTitle =
      'A feature to discover multiple gesture recognizers on the same view.';
    this.multiplegesturerecognizer.image = '';
    this.multiplegesturerecognizer.price = 1800;
    this.multiplegesturerecognizer.duration = 2;
    this.multiplegesturerecognizer.id = 10319;

    this.productrecommendation.icon = '';
    this.productrecommendation.title = 'Product Recommendation';
    this.productrecommendation.subTitle =
      'A feature to discover multiple gesture recognizers on the same view.';
    this.productrecommendation.image = '';
    this.productrecommendation.price = 1800;
    this.productrecommendation.duration = 2;
    this.productrecommendation.id = 10320;

    this.repeatingtasks.icon = '';
    this.repeatingtasks.title = 'Repeating Tasks';
    this.repeatingtasks.subTitle =
      'A feature to set tasks to repeat daily, weekly, monthly or even annually.';
    this.repeatingtasks.image = '';
    this.repeatingtasks.price = 1800;
    this.repeatingtasks.duration = 2;
    this.repeatingtasks.id = 10321;

    this.sentimentanalysis.icon = '';
    this.sentimentanalysis.title = 'Sentiment Analysis';
    this.sentimentanalysis.subTitle =
      'A feature that uses Machine Learning to analyze the sentiment of any text - statement, comment, review, tweet, etc.';
    this.sentimentanalysis.image = '';
    this.sentimentanalysis.price = 1800;
    this.sentimentanalysis.duration = 2;
    this.sentimentanalysis.id = 10322;

    this.targetedfeed.icon = '';
    this.targetedfeed.title = 'Targeted Feed';
    this.targetedfeed.subTitle =
      'A feature to automatically sort very tasks into categories such as what is due today tomorrow and the coming week.';
    this.targetedfeed.image = '';
    this.targetedfeed.price = 1800;
    this.targetedfeed.duration = 2;
    this.targetedfeed.id = 10323;

    this.textcomparison.icon = '';
    this.textcomparison.title = 'Text Comparison';
    this.textcomparison.subTitle =
      'A feature that allows to just paste and compare the difference between two texts.';
    this.textcomparison.image = '';
    this.textcomparison.price = 1800;
    this.textcomparison.duration = 2;
    this.textcomparison.id = 10324;

    this.visualsearch.icon = '';
    this.visualsearch.title = 'Visual Search';
    this.visualsearch.subTitle =
      'A feature that uses Computer Vision allows users to search for an image and find a similar looking image.';
    this.visualsearch.image = '';
    this.visualsearch.price = 1800;
    this.visualsearch.duration = 2;
    this.visualsearch.id = 10325;

    this.addcreditcard.icon = '';
    this.addcreditcard.title = 'Add Credit Card';
    this.addcreditcard.subTitle = 'Add payment method';
    this.addcreditcard.image = '';
    this.addcreditcard.price = 1320;
    this.addcreditcard.duration = 2;
    this.addcreditcard.id = 10326;

    this.baselinereporting.icon = 'icon-10327';
    this.baselinereporting.title = 'Baseline Reporting';
    this.baselinereporting.subTitle =
      'Create reports to compare current projects data to what it looked like on a specific date in past.';
    this.baselinereporting.image = '';
    this.baselinereporting.price = 1200;
    this.baselinereporting.duration = 1.5;
    this.baselinereporting.id = 10327;

    this.categoriessubcategories.icon = '';
    this.categoriessubcategories.title = 'Categories/Sub-Categories';
    this.categoriessubcategories.subTitle =
      'A feature to categorize products from the product catalog into separate categories and subcategories';
    this.categoriessubcategories.image = '';
    this.categoriessubcategories.price = 1800;
    this.categoriessubcategories.duration = 2;
    this.categoriessubcategories.id = 10328;

    this.ideamanagement.icon = '';
    this.ideamanagement.title = 'Idea Management';
    this.ideamanagement.subTitle =
      'A management system to track & manage your business ideas & notes.';
    this.ideamanagement.image = '';
    this.ideamanagement.price = 1800;
    this.ideamanagement.duration = 2;
    this.ideamanagement.id = 10329;

    this.notificationssettings.icon = '';
    this.notificationssettings.title = 'Notifications Settings';
    this.notificationssettings.subTitle =
      'A feature to define notification actions from settings';
    this.notificationssettings.image = '';
    this.notificationssettings.price = 1320;
    this.notificationssettings.duration = 2;
    this.notificationssettings.id = 10330;

    this.projectprotfolio.icon = '';
    this.projectprotfolio.title = 'Project Protfolio';
    this.projectprotfolio.subTitle =
      'A feature to create a Project portfolio for a set of project proposals, projects, programs, sub-portfolios, an more...';
    this.projectprotfolio.image = '';
    this.projectprotfolio.price = 480;
    this.projectprotfolio.duration = 0.5;
    this.projectprotfolio.id = 10331;

    this.projectreporting.icon = 'icon-10332';
    this.projectreporting.title = 'Project Reporting';
    this.projectreporting.subTitle =
      'Allows the users to have custom per project report.';
    this.projectreporting.image = '';
    this.projectreporting.price = 900;
    this.projectreporting.duration = 1;
    this.projectreporting.id = 10332;

    this.projecttemplates.icon = 'icon-10333';
    this.projecttemplates.title = 'Project Templates';
    this.projecttemplates.subTitle =
      'Allows the users to choose from multiple project templates.';
    this.projecttemplates.image = '';
    this.projecttemplates.price = 900;
    this.projecttemplates.duration = 1;
    this.projecttemplates.id = 10333;

    this.qatracker.icon = '';
    this.qatracker.title = 'QA Tracker';
    this.qatracker.subTitle =
      'A tracker for QA team, enabling them to pass or fail QA tests and add comments.';
    this.qatracker.image = '';
    this.qatracker.price = 1800;
    this.qatracker.duration = 2;
    this.qatracker.id = 10334;

    this.adhocreporting.icon = 'icon-10335';
    this.adhocreporting.title = 'Ad Hoc Reporting';
    this.adhocreporting.subTitle =
      'A feature where users can create reports and analyze solution to answer their business questions';
    this.adhocreporting.image = '';
    this.adhocreporting.price = 1800;
    this.adhocreporting.duration = 2;
    this.adhocreporting.id = 10335;

    this.admanager.icon = '';
    this.admanager.title = 'Ad Manager';
    this.admanager.subTitle =
      'Allows the users to edit ads, see how the ads are performing, and update billing information.';
    this.admanager.image = '';
    this.admanager.price = 1200;
    this.admanager.duration = 1.5;
    this.admanager.id = 10336;

    this.budgetingforecasting.icon = 'icon-10337';
    this.budgetingforecasting.title = 'Budgeting & Forecasting';
    this.budgetingforecasting.subTitle =
      'A feature to forecast expenses, revenue, and budget future cycles';
    this.budgetingforecasting.image = '';
    this.budgetingforecasting.price = 1200;
    this.budgetingforecasting.duration = 1.5;
    this.budgetingforecasting.id = 10337;

    this.comprehensivereporting.icon = '';
    this.comprehensivereporting.title = 'Comprehensive Reporting';
    this.comprehensivereporting.subTitle =
      'A built-in reporting feature to log incoming and outgoing connections to know who did what, when, and for how more...';
    this.comprehensivereporting.image = '';
    this.comprehensivereporting.price = 1800;
    this.comprehensivereporting.duration = 2;
    this.comprehensivereporting.id = 10338;

    this.dailyemailsignup.icon = 'icon-10339';
    this.dailyemailsignup.title = 'Daily Email Signup';
    this.dailyemailsignup.subTitle =
      'Allows users to signup for a daily dose of news (content).';
    this.dailyemailsignup.image = '';
    this.dailyemailsignup.price = 900;
    this.dailyemailsignup.duration = 1;
    this.dailyemailsignup.id = 10339;

    this.deeplinking.icon = '';
    this.deeplinking.title = 'Deeplinking';
    this.deeplinking.subTitle =
      'Links your app to a default URL that users can share with their friends to download it';
    this.deeplinking.image = '';
    this.deeplinking.price = 900;
    this.deeplinking.duration = 1;
    this.deeplinking.id = 10340;

    this.emaillists.icon = '';
    this.emaillists.title = 'Email Lists';
    this.emaillists.subTitle = 'Ability to view, edit and delete email list.';
    this.emaillists.image = '';
    this.emaillists.price = 900;
    this.emaillists.duration = 1;
    this.emaillists.id = 10341;

    this.events.icon = '';
    this.events.title = 'Events';
    this.events.subTitle =
      'Events feature to enable your users to track & manage your events.';
    this.events.image = '';
    this.events.price = 900;
    this.events.duration = 1;
    this.events.id = 10342;

    this.graphicalcharts.icon = '';
    this.graphicalcharts.title = 'Graphical Charts';
    this.graphicalcharts.subTitle =
      'A feature to add graphical charts that represent various analytical data points';
    this.graphicalcharts.image = '';
    this.graphicalcharts.price = 1800;
    this.graphicalcharts.duration = 2;
    this.graphicalcharts.id = 10343;

    this.inventorytrendanalysis.icon = 'icon-10344';
    this.inventorytrendanalysis.title = 'Inventory Trend Analysis';
    this.inventorytrendanalysis.subTitle =
      'A feature for the examination of inventory to determine the optimum amount to keep on hand';
    this.inventorytrendanalysis.image = '';
    this.inventorytrendanalysis.price = 1200;
    this.inventorytrendanalysis.duration = 1.5;
    this.inventorytrendanalysis.id = 10344;

    this.locallistingads.icon = 'icon-10345';
    this.locallistingads.title = 'Local Listing Ads';
    this.locallistingads.subTitle =
      'Allows the listing of ads for local businesses.';
    this.locallistingads.image = '';
    this.locallistingads.price = 900;
    this.locallistingads.duration = 1;
    this.locallistingads.id = 10345;

    this.pledgetracking.icon = '';
    this.pledgetracking.title = 'Pledge Tracking';
    this.pledgetracking.subTitle =
      'A feature to track pledges accurately with reporting, ability to write off a balance, and applying donations to a pledge seamlessly.';
    this.pledgetracking.image = '';
    this.pledgetracking.price = 1200;
    this.pledgetracking.duration = 1.5;
    this.pledgetracking.id = 10346;

    this.promotecontent.icon = 'icon-10347';
    this.promotecontent.title = 'Promote Content';
    this.promotecontent.subTitle =
      'To help businesses and those that want to make money from their posts by expanding the reach of the post.';
    this.promotecontent.image = '';
    this.promotecontent.price = 900;
    this.promotecontent.duration = 1;
    this.promotecontent.id = 10347;

    this.referrals.icon = 'icon-10348';
    this.referrals.title = 'Referrals';
    this.referrals.subTitle =
      'Acquiring new users who are suggested or referred to by existing users';
    this.referrals.image = '';
    this.referrals.price = 900;
    this.referrals.duration = 1;
    this.referrals.id = 10348;

    this.salesreporting.icon = '';
    this.salesreporting.title = 'Sales Reporting';
    this.salesreporting.subTitle =
      "A sales report to show the trends that occur in a company's sales volume over time. ";
    this.salesreporting.image = '';
    this.salesreporting.price = 1200;
    this.salesreporting.duration = 1.5;
    this.salesreporting.id = 10349;

    this.searchtermsreports.icon = '';
    this.searchtermsreports.title = 'Search Terms Reports';
    this.searchtermsreports.subTitle =
      'A feature to use search terms report to see how your ads performed when triggered by actual searches within the Search Network';
    this.searchtermsreports.image = '';
    this.searchtermsreports.price = 1200;
    this.searchtermsreports.duration = 1.5;
    this.searchtermsreports.id = 10350;

    this.statisticsreports.icon = '';
    this.statisticsreports.title = 'Statistics & Reports';
    this.statisticsreports.subTitle =
      'A feature to use search terms report to see how your ads performed when triggered by actual searches within the Search Network';
    this.statisticsreports.image = '';
    this.statisticsreports.price = 1200;
    this.statisticsreports.duration = 1.5;
    this.statisticsreports.id = 10351;

    this.trafficsources.icon = '';
    this.trafficsources.title = 'Traffic Sources';
    this.trafficsources.subTitle =
      'A feature to learn which websites, social networks, and other marketing channels are driving interest and sales of your product/ service.';
    this.trafficsources.image = '';
    this.trafficsources.price = 900;
    this.trafficsources.duration = 1;
    this.trafficsources.id = 10352;

    this.videoads.icon = '';
    this.videoads.title = 'Video Ads';
    this.videoads.subTitle = 'Showing video ads in the app.';
    this.videoads.image = '';
    this.videoads.price = 900;
    this.videoads.duration = 1;
    this.videoads.id = 10353;

    this.visualanalytics.icon = 'icon-10354';
    this.visualanalytics.title = 'Visual Analytics';
    this.visualanalytics.subTitle =
      'Displaying large & complex data in graphs, charts, and other interactive visual interfaces.';
    this.visualanalytics.image = '';
    this.visualanalytics.price = 1200;
    this.visualanalytics.duration = 1.5;
    this.visualanalytics.id = 10354;

    this.automatedprioritization.icon = '';
    this.automatedprioritization.title = 'Automated Prioritization';
    this.automatedprioritization.subTitle =
      'A feature that highlights tasks that are few a minutes overdue in orange and whats way overdue in red.';
    this.automatedprioritization.image = '';
    this.automatedprioritization.price = 1800;
    this.automatedprioritization.duration = 2;
    this.automatedprioritization.id = 10355;

    this.devicemanagement.icon = '';
    this.devicemanagement.title = 'Device Management';
    this.devicemanagement.subTitle =
      'You can stream shows & movies on Smart Tv,playstation XBox,Apple Tv, etc';
    this.devicemanagement.image = '';
    this.devicemanagement.price = 1320;
    this.devicemanagement.duration = 2;
    this.devicemanagement.id = 10356;

    this.leavetracker.icon = '';
    this.leavetracker.title = 'Leave Tracker';
    this.leavetracker.subTitle =
      'A leave management feature to help you track and manage all your employee leave information from one central location.';
    this.leavetracker.image = '';
    this.leavetracker.price = 1800;
    this.leavetracker.duration = 2;
    this.leavetracker.id = 10357;

    this.prioritize.icon = 'icon-10358';
    this.prioritize.title = 'Prioritize';
    this.prioritize.subTitle =
      'Allows the users to set priorities and due dates.';
    this.prioritize.image = '';
    this.prioritize.price = 900;
    this.prioritize.duration = 1;
    this.prioritize.id = 10358;

    this.scheduling.icon = 'icon-10359';
    this.scheduling.title = 'Scheduling ';
    this.scheduling.subTitle =
      'Ability to prioritize your tasks and schedule them as per your future plan';
    this.scheduling.image = '';
    this.scheduling.price = 900;
    this.scheduling.duration = 1;
    this.scheduling.id = 10359;

    this.taskallocator.icon = '';
    this.taskallocator.title = 'Task Allocator';
    this.taskallocator.subTitle =
      'A feature to let the users quickly allocate tasks to their team members';
    this.taskallocator.image = '';
    this.taskallocator.price = 900;
    this.taskallocator.duration = 1;
    this.taskallocator.id = 10360;

    this.taskblocker.icon = '';
    this.taskblocker.title = 'Task Blocker';
    this.taskblocker.subTitle =
      'A feature to help your team make the dependent tasks highly visible and drive whatever collaboration is necessary to get them resolved.';
    this.taskblocker.image = '';
    this.taskblocker.price = 900;
    this.taskblocker.duration = 1;
    this.taskblocker.id = 10361;

    this.taskgroups.icon = '';
    this.taskgroups.title = 'Task Groups';
    this.taskgroups.subTitle =
      "An organizer for project tasks in groups that let's user align tasks to milestones, progress stages or anything else";
    this.taskgroups.image = '';
    this.taskgroups.price = 1200;
    this.taskgroups.duration = 1.5;
    this.taskgroups.id = 10362;

    this.tasklist.icon = '';
    this.tasklist.title = 'Task List';
    this.tasklist.subTitle =
      'Ability to create, manage, and track a TO-DO list of tasks';
    this.tasklist.image = '';
    this.tasklist.price = 1800;
    this.tasklist.duration = 2;
    this.tasklist.id = 10363;

    this.taskviews.icon = '';
    this.taskviews.title = 'Task Views';
    this.taskviews.subTitle =
      "A feature to visualize your project tasks in a simple list or in a columns view and let's user switch between views in a click.";
    this.taskviews.image = '';
    this.taskviews.price = 1200;
    this.taskviews.duration = 1.5;
    this.taskviews.id = 10364;

    this.teambuilder.icon = '';
    this.teambuilder.title = 'Team Builder';
    this.teambuilder.subTitle = 'A feature to create, edit, and view teams.';
    this.teambuilder.image = '';
    this.teambuilder.price = 1800;
    this.teambuilder.duration = 2;
    this.teambuilder.id = 10365;

    this.timetracker.icon = '';
    this.timetracker.title = 'Time Tracker';
    this.timetracker.subTitle = 'A feature to track a session length.';
    this.timetracker.image = '';
    this.timetracker.price = 900;
    this.timetracker.duration = 1;
    this.timetracker.id = 10366;

    this.accountscoreranking.icon = '';
    this.accountscoreranking.title = 'Account Score/Ranking';
    this.accountscoreranking.subTitle =
      'A feature to have account scoring formula based on custom contact level attributes';
    this.accountscoreranking.image = '';
    this.accountscoreranking.price = 1800;
    this.accountscoreranking.duration = 2;
    this.accountscoreranking.id = 10367;

    this.accounttransfertool.icon = '';
    this.accounttransfertool.title = 'Account Transfer Tool';
    this.accounttransfertool.subTitle =
      'Allows users to easily transfer files from one user to another when responsibilities change.';
    this.accounttransfertool.image = '';
    this.accounttransfertool.price = 1350;
    this.accounttransfertool.duration = 2;
    this.accounttransfertool.id = 10368;

    this.activitylog.icon = '';
    this.activitylog.title = 'Activity Log';
    this.activitylog.subTitle =
      'Enables you to view the list of actions/replies performed by the users inside a group or channel';
    this.activitylog.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Activity-Log.webp';
    this.activitylog.price = 900;
    this.activitylog.duration = 1;
    this.activitylog.id = 10369;

    this.addressmanagement.icon = '';
    this.addressmanagement.title = 'Address Management ';
    this.addressmanagement.subTitle =
      'A feature that allows users to add and edit address to the application';
    this.addressmanagement.image = '';
    this.addressmanagement.price = 1620;
    this.addressmanagement.duration = 1;
    this.addressmanagement.id = 10370;

    this.approvalprocesscontrol.icon = '';
    this.approvalprocesscontrol.title = 'Approval Process Control';
    this.approvalprocesscontrol.subTitle =
      "Allows the users to track where the project is at as well as provide visibility to others about the request's more...";
    this.approvalprocesscontrol.image = '';
    this.approvalprocesscontrol.price = 900;
    this.approvalprocesscontrol.duration = 1;
    this.approvalprocesscontrol.id = 10371;

    this.customizableusersubscriptions.icon = '';
    this.customizableusersubscriptions.title =
      'Customizable User Subscriptions';
    this.customizableusersubscriptions.subTitle =
      'A feature that lets you customize user subscription model (e.g. Free, Premium, Gold)';
    this.customizableusersubscriptions.image = '';
    this.customizableusersubscriptions.price = 900;
    this.customizableusersubscriptions.duration = 1;
    this.customizableusersubscriptions.id = 10372;

    this.dailylimit.icon = '';
    this.dailylimit.title = 'Daily Limit';
    this.dailylimit.subTitle =
      'A feature to specify the maximum number of hours per day that you would like a team member to work on a given project or task. ';
    this.dailylimit.image = '';
    this.dailylimit.price = 900;
    this.dailylimit.duration = 1;
    this.dailylimit.id = 10373;

    this.dashboardguests.icon = '';
    this.dashboardguests.title = 'Dashboard Guests';
    this.dashboardguests.subTitle =
      'A feature to invite people outside of your workspace to view real-time project information via an external dashboard';
    this.dashboardguests.image = '';
    this.dashboardguests.price = 1800;
    this.dashboardguests.duration = 2;
    this.dashboardguests.id = 10374;

    this.livechatsummary.icon = '';
    this.livechatsummary.title = 'Live Chat Summary';
    this.livechatsummary.subTitle =
      "A feature for Live Chat Summarisation that gives you a bird's eye view of your live-chat channel";
    this.livechatsummary.image = '';
    this.livechatsummary.price = 480;
    this.livechatsummary.duration = 0.5;
    this.livechatsummary.id = 10375;

    this.proposalgeneration.icon = '';
    this.proposalgeneration.title = 'Proposal Generation';
    this.proposalgeneration.subTitle =
      'A feature for automating your proposal generation to save you a lot of time and makes the sales configuring process efficient and completely customizable.';
    this.proposalgeneration.image = '';
    this.proposalgeneration.price = 1200;
    this.proposalgeneration.duration = 1.5;
    this.proposalgeneration.id = 10376;

    this.remoteadministration.icon = '';
    this.remoteadministration.title = 'Remote Administration';
    this.remoteadministration.subTitle =
      'A feature you control, manage systems and team remotely using remote administration';
    this.remoteadministration.image = '';
    this.remoteadministration.price = 1200;
    this.remoteadministration.duration = 1.5;
    this.remoteadministration.id = 10377;

    this.rolespermissions.icon = '';
    this.rolespermissions.title = 'Roles & Permissions';
    this.rolespermissions.subTitle =
      'Enables the creation of user groups, each with restricted access to specific content';
    this.rolespermissions.image = '';
    this.rolespermissions.price = 900;
    this.rolespermissions.duration = 1;
    this.rolespermissions.id = 10378;

    this.servicespecificsettingsadmin.icon = '';
    this.servicespecificsettingsadmin.title =
      'Service-specific Settings (Admin)';
    this.servicespecificsettingsadmin.subTitle =
      'A feature to enable tailored service settings for different users';
    this.servicespecificsettingsadmin.image = '';
    this.servicespecificsettingsadmin.price = 1200;
    this.servicespecificsettingsadmin.duration = 1.5;
    this.servicespecificsettingsadmin.id = 10379;

    this.singlesignonsso.icon = '';
    this.singlesignonsso.title = 'Single Sign on (SSO)';
    this.singlesignonsso.subTitle =
      'Single sign-on (SSO) is user authentication service that permits a user to use one set of login credentials (e.g., name and password) to access multiple applications. ';
    this.singlesignonsso.image = '';
    this.singlesignonsso.price = 900;
    this.singlesignonsso.duration = 1;
    this.singlesignonsso.id = 10380;

    this.agerestriction.icon = '';
    this.agerestriction.title = 'Age Restriction';
    this.agerestriction.subTitle =
      'Setting age-restriction on content - photo, video, and document';
    this.agerestriction.image = '';
    this.agerestriction.price = 900;
    this.agerestriction.duration = 1;
    this.agerestriction.id = 10381;

    this.audittrail.icon = 'icon-10382';
    this.audittrail.title = 'Audit Trail';
    this.audittrail.subTitle =
      'Chronological records that provide documentary evidence of the sequence of activities that have affected at any time a specific operation, procedure, or event.';
    this.audittrail.image = '';
    this.audittrail.price = 900;
    this.audittrail.duration = 1;
    this.audittrail.id = 10382;

    this.automatedpublishing.icon = 'icon-10383';
    this.automatedpublishing.title = 'Automated Publishing';
    this.automatedpublishing.subTitle =
      'Chronological records that provide documentary evidence of the sequence of activities that have affected at any time a specific operation, procedure, or event.';
    this.automatedpublishing.image = '';
    this.automatedpublishing.price = 1200;
    this.automatedpublishing.duration = 1.5;
    this.automatedpublishing.id = 10383;

    this.blogimporting.icon = '';
    this.blogimporting.title = 'Blog Importing';
    this.blogimporting.subTitle =
      'Chronological records that provide documentary evidence of the sequence of activities that have affected at any time a specific operation, procedure, or event.';
    this.blogimporting.image = '';
    this.blogimporting.price = 900;
    this.blogimporting.duration = 1;
    this.blogimporting.id = 10384;

    this.blogpostsmanagement.icon = '';
    this.blogpostsmanagement.title = 'Blog Posts Management ';
    this.blogpostsmanagement.subTitle =
      'A feature to manage, organize and curate your blog more efficiently';
    this.blogpostsmanagement.image = '';
    this.blogpostsmanagement.price = 1800;
    this.blogpostsmanagement.duration = 2;
    this.blogpostsmanagement.id = 10385;

    this.bulkcommentmanagement.icon = '';
    this.bulkcommentmanagement.title = 'Bulk Comment Management';
    this.bulkcommentmanagement.subTitle =
      'A feature to perform bulk operations on your comments.';
    this.bulkcommentmanagement.image = '';
    this.bulkcommentmanagement.price = 1800;
    this.bulkcommentmanagement.duration = 2;
    this.bulkcommentmanagement.id = 10386;

    this.bulkuploading.icon = 'icon-10387';
    this.bulkuploading.title = 'Bulk Uploading';
    this.bulkuploading.subTitle =
      'Support for bulk upload of files - photos, videos, and documents.';
    this.bulkuploading.image = '';
    this.bulkuploading.price = 900;
    this.bulkuploading.duration = 1;
    this.bulkuploading.id = 10387;

    this.catalog.icon = 'icon-10388';
    this.catalog.title = 'Catalog';
    this.catalog.subTitle =
      'A list of the contents arranged according to any of various systems.';
    this.catalog.image = '';
    this.catalog.price = 900;
    this.catalog.duration = 1;
    this.catalog.id = 10388;

    this.communityforum.icon = '';
    this.communityforum.title = 'Community Forum';
    this.communityforum.subTitle = 'Lets users engage in discussions.';
    this.communityforum.image = '';
    this.communityforum.price = 900;
    this.communityforum.duration = 1;
    this.communityforum.id = 10389;

    this.contentexporting.icon = '';
    this.contentexporting.title = 'Content Exporting';
    this.contentexporting.subTitle =
      'A feature to export content to Wordpress.';
    this.contentexporting.image = '';
    this.contentexporting.price = 900;
    this.contentexporting.duration = 1;
    this.contentexporting.id = 10390;

    this.contentflag.icon = '';
    this.contentflag.title = 'Content Flag';
    this.contentflag.subTitle = 'Ability to flag inappropriate content.';
    this.contentflag.image = '';
    this.contentflag.price = 480;
    this.contentflag.duration = 0.5;
    this.contentflag.id = 10391;

    this.contentmoderation.icon = '';
    this.contentmoderation.title = 'Content Moderation';
    this.contentmoderation.subTitle =
      'Content Moderation is the practice of monitoring and applying a pre-determined set of rules and guidelines to user-generated content to determine if the communication (a post, in particular) is permissible or not. ';
    this.contentmoderation.image = '';
    this.contentmoderation.price = 900;
    this.contentmoderation.duration = 1;
    this.contentmoderation.id = 10392;

    this.customform.icon = 'icon-10393';
    this.customform.title = 'Custom Form';
    this.customform.subTitle =
      'Allows the users to have a custom form with custom fields.';
    this.customform.image = '';
    this.customform.price = 900;
    this.customform.duration = 1;
    this.customform.id = 10393;

    this.dataimportexportcsv.icon = '';
    this.dataimportexportcsv.title = 'Data Import/Export (CSV)';
    this.dataimportexportcsv.subTitle =
      'Allows the users to Import/Export data from CSV files';
    this.dataimportexportcsv.image = '';
    this.dataimportexportcsv.price = 900;
    this.dataimportexportcsv.duration = 1;
    this.dataimportexportcsv.id = 10394;

    this.datamigration.icon = 'icon-10395';
    this.datamigration.title = 'Data Migration';
    this.datamigration.subTitle =
      'A feature to migrate data from one server to another';
    this.datamigration.image = '';
    this.datamigration.price = 900;
    this.datamigration.duration = 1;
    this.datamigration.id = 10395;

    this.documentation.icon = 'icon-10396';
    this.documentation.title = 'Documentation';
    this.documentation.subTitle =
      'Allows the users to look up all the company or business related documentation at one place.';
    this.documentation.image = '';
    this.documentation.price = 900;
    this.documentation.duration = 1;
    this.documentation.id = 10396;

    this.documentdistribution.icon = 'icon-10397';
    this.documentdistribution.title = 'Document Distribution';
    this.documentdistribution.subTitle =
      'Allows the users to share important documents with colleagues and business partners anywhere in an instant.';
    this.documentdistribution.image = '';
    this.documentdistribution.price = 1800;
    this.documentdistribution.duration = 2;
    this.documentdistribution.id = 10397;

    this.documentpreviewing.icon = '';
    this.documentpreviewing.title = 'Document Previewing';
    this.documentpreviewing.subTitle =
      'A view controller that previews, opens, or prints files whose file format cannot be handled directly by your app.';
    this.documentpreviewing.image = '';
    this.documentpreviewing.price = 900;
    this.documentpreviewing.duration = 1;
    this.documentpreviewing.id = 10398;

    this.download.icon = 'icon-10399';
    this.download.title = 'Download';
    this.download.subTitle = 'Enabling download for your app content.';
    this.download.image = '';
    this.download.price = 480;
    this.download.duration = 0.5;
    this.download.id = 10399;

    this.dynamiccontent.icon = '';
    this.dynamiccontent.title = 'Dynamic Content';
    this.dynamiccontent.subTitle =
      'Content that changes based on user access time, user preferences, and personal information.';
    this.dynamiccontent.image = '';
    this.dynamiccontent.price = 900;
    this.dynamiccontent.duration = 1;
    this.dynamiccontent.id = 10400;

    this.fileattachment.icon = 'icon-10401';
    this.fileattachment.title = 'File Attachment';
    this.fileattachment.subTitle =
      'Allows the users to attach files - document, photo, & video.';
    this.fileattachment.image = '';
    this.fileattachment.price = 480;
    this.fileattachment.duration = 0.5;
    this.fileattachment.id = 10401;

    this.importphotoshopdocumentspsd.icon = '';
    this.importphotoshopdocumentspsd.title = 'Import Photoshop Documents (PSD)';
    this.importphotoshopdocumentspsd.subTitle =
      "A feature that let's user import photoshop documents";
    this.importphotoshopdocumentspsd.image = '';
    this.importphotoshopdocumentspsd.price = 480;
    this.importphotoshopdocumentspsd.duration = 0.5;
    this.importphotoshopdocumentspsd.id = 10402;

    this.library.icon = '';
    this.library.title = 'Library';
    this.library.subTitle =
      'A feature to make the list of preferences at one place';
    this.library.image = '';
    this.library.price = 1320;
    this.library.duration = 2;
    this.library.id = 10403;

    this.manageblogcomments.icon = '';
    this.manageblogcomments.title = 'Manage Blog Comments';
    this.manageblogcomments.subTitle =
      'A feature to manage blog comments by approving or deleting them';
    this.manageblogcomments.image = '';
    this.manageblogcomments.price = 1200;
    this.manageblogcomments.duration = 1.5;
    this.manageblogcomments.id = 10404;

    this.mergingcontent.icon = '';
    this.mergingcontent.title = 'Merging Content';
    this.mergingcontent.subTitle =
      'A feature where after the content changes on the staging website is made, you can merge or schedule merging of the staging website to another';
    this.mergingcontent.image = '';
    this.mergingcontent.price = 900;
    this.mergingcontent.duration = 1;
    this.mergingcontent.id = 10405;

    this.multipageforms.icon = '';
    this.multipageforms.title = 'Multipage Forms';
    this.multipageforms.subTitle =
      'A feature to create multiple pages from breaking up long forms and creating Sections.';
    this.multipageforms.image = '';
    this.multipageforms.price = 1200;
    this.multipageforms.duration = 1.5;
    this.multipageforms.id = 10406;

    this.notes.icon = 'icon-10407';
    this.notes.title = 'Notes';
    this.notes.subTitle = 'Lets users type and save notes (time-stamped). ';
    this.notes.image = '';
    this.notes.price = 480;
    this.notes.duration = 0.5;
    this.notes.id = 10407;

    this.paidcontent.icon = 'icon-10408';
    this.paidcontent.title = 'Paid Content';
    this.paidcontent.subTitle =
      'Allows users to pay for the premium content - text, graphics, video, and downloads.';
    this.paidcontent.image = '';
    this.paidcontent.price = 900;
    this.paidcontent.duration = 1;
    this.paidcontent.id = 10408;

    this.pdfconversion.icon = 'icon-10409';
    this.pdfconversion.title = 'PDF Conversion';
    this.pdfconversion.subTitle =
      'A feature to convert files from PDF format to different format like .doc, .docx etc.';
    this.pdfconversion.image = '';
    this.pdfconversion.price = 900;
    this.pdfconversion.duration = 1;
    this.pdfconversion.id = 10409;

    this.readermode.icon = '';
    this.readermode.title = 'Reader Mode';
    this.readermode.subTitle =
      'A feature that lets you view only the elements within the body of the story, so you can stay focused on the text and pertinent images. ';
    this.readermode.image = '';
    this.readermode.price = 1320;
    this.readermode.duration = 2;
    this.readermode.id = 10410;

    this.spellcheck.icon = '';
    this.spellcheck.title = 'Spell Check';
    this.spellcheck.subTitle =
      'A feature to check the spelling in real time text editing';
    this.spellcheck.image = '';
    this.spellcheck.price = 900;
    this.spellcheck.duration = 1;
    this.spellcheck.id = 10411;

    this.testimonials.icon = 'icon-10412';
    this.testimonials.title = 'Testimonials';
    this.testimonials.subTitle =
      'Allows the businesses to collect kudos from customers and clients and display them on your site to add credibility.';
    this.testimonials.image = '';
    this.testimonials.price = 480;
    this.testimonials.duration = 0.5;
    this.testimonials.id = 10412;

    this.travelguide.icon = '';
    this.travelguide.title = 'Travel Guide';
    this.travelguide.subTitle =
      'Allows the businesses to collect kudos from customers and clients and display them on your site to add credibility.';
    this.travelguide.image = '';
    this.travelguide.price = 900;
    this.travelguide.duration = 1;
    this.travelguide.id = 10413;

    this.wordlookup.icon = '';
    this.wordlookup.title = 'Word Lookup';
    this.wordlookup.subTitle =
      'Allows the businesses to collect kudos from customers and clients and display them on your site to add credibility.';
    this.wordlookup.image = '';
    this.wordlookup.price = 480;
    this.wordlookup.duration = 0.5;
    this.wordlookup.id = 10414;

    this.realtimeupdates.icon = 'icon-10415';
    this.realtimeupdates.title = 'Real Time Updates';
    this.realtimeupdates.subTitle =
      'Get real-time updates on data on your dashboard';
    this.realtimeupdates.image = '';
    this.realtimeupdates.price = 1200;
    this.realtimeupdates.duration = 1.5;
    this.realtimeupdates.id = 10415;

    this.tutorials.icon = '';
    this.tutorials.title = 'Tutorials';
    this.tutorials.subTitle =
      'The Screen that is displayed just after the splash screen';
    this.tutorials.image = '';
    this.tutorials.price = 600;
    this.tutorials.duration = 2;
    this.tutorials.id = 10416;

    this.advancedclashmanagement.icon = 'icon-10417';
    this.advancedclashmanagement.title = 'Advanced Clash Management';
    this.advancedclashmanagement.subTitle =
      'Allows users to detect and manage clashing schedules efficiently.';
    this.advancedclashmanagement.image = '';
    this.advancedclashmanagement.price = 1800;
    this.advancedclashmanagement.duration = 2;
    this.advancedclashmanagement.id = 10417;

    this.customizedorderstatus.icon = '';
    this.customizedorderstatus.title = 'Customized Order Status';
    this.customizedorderstatus.subTitle =
      'Allows users to maintain a waitlist to manage high demand or overbooking of a service or product. A feature to create as many custom labels for your orders, so that you can specify as many statuses as you need for your order';
    this.customizedorderstatus.image = '';
    this.customizedorderstatus.price = 900;
    this.customizedorderstatus.duration = 1;
    this.customizedorderstatus.id = 10418;

    this.deliveryestimator.icon = '';
    this.deliveryestimator.title = 'Delivery Estimator';
    this.deliveryestimator.subTitle =
      'A feature that predicts when a customer can expect to get the item delivered.';
    this.deliveryestimator.image = '';
    this.deliveryestimator.price = 1800;
    this.deliveryestimator.duration = 2;
    this.deliveryestimator.id = 10419;

    this.expensetracking.icon = 'icon-10420';
    this.expensetracking.title = 'Expense Tracking';
    this.expensetracking.subTitle = 'Allows the users to track their expenses.';
    this.expensetracking.image = '';
    this.expensetracking.price = 1200;
    this.expensetracking.duration = 1.5;
    this.expensetracking.id = 10420;

    this.expressdelivery.icon = '';
    this.expressdelivery.title = 'Express Delivery';
    this.expressdelivery.subTitle =
      'A feature that computes express delivery charges and displays time to deliver or be picked up from store';
    this.expressdelivery.image = '';
    this.expressdelivery.price = 1320;
    this.expressdelivery.duration = 2;
    this.expressdelivery.id = 10421;

    this.orderdetailview.icon = '';
    this.orderdetailview.title = 'Order Detail View';
    this.orderdetailview.subTitle =
      'A feature that gives order number, customer ID, order status, shipment status, product name, size or configuration, and more';
    this.orderdetailview.image = '';
    this.orderdetailview.price = 1200;
    this.orderdetailview.duration = 1.5;
    this.orderdetailview.id = 10422;

    this.reservations.icon = 'icon-10423';
    this.reservations.title = 'Reservations';
    this.reservations.subTitle =
      'A feature to reserve a ticket for an event or in any form of transportation';
    this.reservations.image = '';
    this.reservations.price = 900;
    this.reservations.duration = 1;
    this.reservations.id = 10423;

    this.waitinglist.icon = 'icon-10424';
    this.waitinglist.title = 'Waiting List';
    this.waitinglist.subTitle =
      'Allows users to maintain a waitlist to manage high demand or overbooking of a service or product.';
    this.waitinglist.image = '';
    this.waitinglist.price = 900;
    this.waitinglist.duration = 1;
    this.waitinglist.id = 10424;

    this.whitelisingemails.icon = '';
    this.whitelisingemails.title = 'Whitelising Emails';
    this.whitelisingemails.subTitle =
      "A feature of Whitelisting emails to ensure proper delivery of emails to the user's inbox.";
    this.whitelisingemails.image = '';
    this.whitelisingemails.price = 900;
    this.whitelisingemails.duration = 1;
    this.whitelisingemails.id = 10425;

    this.smartcategorization.icon = '';
    this.smartcategorization.title = 'Smart Categorization';
    this.smartcategorization.subTitle =
      'A feature to automatically sort very tasks into categories such as what is due today tomorrow and the coming week.';
    this.smartcategorization.image = '';
    this.smartcategorization.price = 1800;
    this.smartcategorization.duration = 2;
    this.smartcategorization.id = 10426;

    this.calllogdetail.icon = 'icon-10427';
    this.calllogdetail.title = 'Call Log Detail';
    this.calllogdetail.subTitle =
      'For users to track their incoming and outgoing calls through the app';
    this.calllogdetail.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Call-Log-Detail.webp';
    this.calllogdetail.price = 900;
    this.calllogdetail.duration = 1;
    this.calllogdetail.id = 10427;

    this.viewprofile.icon = 'icon-10428';
    this.viewprofile.title = 'View Profile';
    this.viewprofile.subTitle =
      "A profile view that shows the user's information";
    this.viewprofile.image =
      'https://nandbox.com/wp-content/uploads/2022/12/View-Profile.webp';
    this.viewprofile.price = 480;
    this.viewprofile.duration = 0.5;
    this.viewprofile.id = 10428;

    this.switchvideocalltoaudiocall.icon = 'icon-10429';
    this.switchvideocalltoaudiocall.title = 'Switch Video Call to Audio Call';
    this.switchvideocalltoaudiocall.subTitle =
      'User can switch from video to audio calls and vice versa';
    this.switchvideocalltoaudiocall.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Switch-Video-Call-to-Audio-Call.webp';
    this.switchvideocalltoaudiocall.price = 1800;
    this.switchvideocalltoaudiocall.duration = 2;
    this.switchvideocalltoaudiocall.id = 10429;

    this.muteunmutemicrophone.icon = 'icon-10430';
    this.muteunmutemicrophone.title = 'Mute/Unmute Microphone';
    this.muteunmutemicrophone.subTitle =
      'User can mute or unmute the microphone during calls made from your app';
    this.muteunmutemicrophone.image =
      'https://nandbox.com/wp-content/uploads/2022/12/MuteUnmute-Microphone.webp';
    this.muteunmutemicrophone.price = 480;
    this.muteunmutemicrophone.duration = 0.5;
    this.muteunmutemicrophone.id = 10430;

    this.turnspeakeronoff.icon = 'icon-10431';
    this.turnspeakeronoff.title = 'Turn Speaker on/off';
    this.turnspeakeronoff.subTitle =
      'User can turn a speaker on or off during calls made from the app';
    this.turnspeakeronoff.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Turn-Speaker-onoff.webp';
    this.turnspeakeronoff.price = 480;
    this.turnspeakeronoff.duration = 0.5;
    this.turnspeakeronoff.id = 10431;

    this.switchcalltobluetooth.icon = 'icon-10432';
    this.switchcalltobluetooth.title = 'Switch Call to Bluetooth';
    this.switchcalltobluetooth.subTitle =
      'Users can make calls from your app using Bluetooth accessories';
    this.switchcalltobluetooth.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Switch-Call-to-Bluetooth.webp';
    this.switchcalltobluetooth.price = 1320;
    this.switchcalltobluetooth.duration = 2;
    this.switchcalltobluetooth.id = 10432;

    this.connectingtone.icon = 'icon-10433';
    this.connectingtone.title = 'Connecting Tone';
    this.connectingtone.subTitle =
      'Let your app play a tone when the user starts a call connection';
    this.connectingtone.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Connecting-Tone.webp';
    this.connectingtone.price = 480;
    this.connectingtone.duration = 0.5;
    this.connectingtone.id = 10433;

    this.ringtone.icon = 'icon-10434';
    this.ringtone.title = 'Ring Tone';
    this.ringtone.subTitle =
      'Notify your app user with a tone played after establishing connectivity';
    this.ringtone.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Ring-Tone.webp';
    this.ringtone.price = 480;
    this.ringtone.duration = 0.5;
    this.ringtone.id = 10434;

    this.switchcamera.icon = 'icon-10435';
    this.switchcamera.title = 'Switch Camera ';
    this.switchcamera.subTitle =
      'For users to switch between front and back cameras during their video calls on your app';
    this.switchcamera.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Switch-Camera.webp';
    this.switchcamera.price = 900;
    this.switchcamera.duration = 1;
    this.switchcamera.id = 10435;

    this.adjustvideocallwindow.icon = 'icon-10436';
    this.adjustvideocallwindow.title = 'Adjust Video Call Window';
    this.adjustvideocallwindow.subTitle =
      'For users to maximize and minimize  their video call window';
    this.adjustvideocallwindow.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Adjust-Video-Call-Window.webp';
    this.adjustvideocallwindow.price = 480;
    this.adjustvideocallwindow.duration = 0.5;
    this.adjustvideocallwindow.id = 10436;

    this.ip6ip4callconnectivity.icon = 'icon-10437';
    this.ip6ip4callconnectivity.title = 'IP6/IP4 Call Connectivity';
    this.ip6ip4callconnectivity.subTitle =
      'A backend feature that allows your app to route calls between Internet Protocol Version 4 (IP4) to Version 6 (IP6) and vice versa';
    this.ip6ip4callconnectivity.image = '';
    this.ip6ip4callconnectivity.price = 1800;
    this.ip6ip4callconnectivity.duration = 2;
    this.ip6ip4callconnectivity.id = 10437;

    this.callcancel.icon = 'icon-10438';
    this.callcancel.title = 'Call Cancel ';
    this.callcancel.subTitle =
      'For users to cancel or end voice and video calls made through your app';
    this.callcancel.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Call-Cancel.webp';
    this.callcancel.price = 480;
    this.callcancel.duration = 0.5;
    this.callcancel.id = 10438;

    this.multitaskingfeature.icon = 'icon-10439';
    this.multitaskingfeature.title = 'Multi-tasking feature';
    this.multitaskingfeature.subTitle =
      'For your app user to carry out other tasks while the call is running on your app';
    this.multitaskingfeature.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Multi-tasking-feature.webp';
    this.multitaskingfeature.price = 900;
    this.multitaskingfeature.duration = 1;
    this.multitaskingfeature.id = 10439;

    this.callnotifications.icon = 'icon-10440';
    this.callnotifications.title = 'Call Notifications';
    this.callnotifications.subTitle =
      'Enables push notifications when a call is missed or not answered';
    this.callnotifications.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Call-Notifications.webp';
    this.callnotifications.price = 480;
    this.callnotifications.duration = 0.5;
    this.callnotifications.id = 10440;

    this.lightnotification.icon = 'icon-10441';
    this.lightnotification.title = 'Light Notification';
    this.lightnotification.subTitle =
      "Enables LED notification colors to the user's phone when a call is missed or not answered";
    this.lightnotification.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Light-Notification.webp';
    this.lightnotification.price = 480;
    this.lightnotification.duration = 0.5;
    this.lightnotification.id = 10441;

    this.lastseen.icon = 'icon-10442';
    this.lastseen.title = 'Last Seen';
    this.lastseen.subTitle =
      "Displays the time of user's last login in peer-to-peer chats. If the user disables this feature, they won't be able to see other people's last seen status";
    this.lastseen.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Last-Seen.webp';
    this.lastseen.price = 900;
    this.lastseen.duration = 1;
    this.lastseen.id = 10442;

    this.onlinestatus.icon = 'icon-10443';
    this.onlinestatus.title = 'Online Status';
    this.onlinestatus.subTitle =
      "Displays the user's online status in peer-to-peer chats. If the user disables this feature, they won't be able to see other people's online status";
    this.onlinestatus.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Online-Status.webp';
    this.onlinestatus.price = 900;
    this.onlinestatus.duration = 1;
    this.onlinestatus.id = 10443;

    this.readreceipt.icon = 'icon-10444';
    this.readreceipt.title = 'Read Receipt';
    this.readreceipt.subTitle =
      "Allow users to receive notifications when their messages are read. If the user disables this feature, they won't be able to see read receipts from other people";
    this.readreceipt.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Read-Receipt.webp';
    this.readreceipt.price = 900;
    this.readreceipt.duration = 1;
    this.readreceipt.id = 10444;

    this.sendtextmessagewithbackground.icon = 'icon-10445';
    this.sendtextmessagewithbackground.title =
      'Send Text Message With Background';
    this.sendtextmessagewithbackground.subTitle =
      'For users to add colorful backgrounds to their text messages';
    this.sendtextmessagewithbackground.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Send-Text-Message-With-Background.webp';
    this.sendtextmessagewithbackground.price = 480;
    this.sendtextmessagewithbackground.duration = 0.5;
    this.sendtextmessagewithbackground.id = 10445;

    this.sendfile.icon = 'icon-10446';
    this.sendfile.title = 'Send File';
    this.sendfile.subTitle =
      'For users to send media files such as pdf, doc, and much more to other users on your app';
    this.sendfile.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Send-File.webp';
    this.sendfile.price = 900;
    this.sendfile.duration = 1;
    this.sendfile.id = 10446;

    this.deletechat.icon = 'icon-10447';
    this.deletechat.title = 'Delete Chat';
    this.deletechat.subTitle =
      'For users to delete their previous and current chats';
    this.deletechat.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Delete-Chat.webp';
    this.deletechat.price = 900;
    this.deletechat.duration = 1;
    this.deletechat.id = 10447;

    this.copytextmessage.icon = 'icon-10448';
    this.copytextmessage.title = 'Copy Text message';
    this.copytextmessage.subTitle = 'For users to copy text messages';
    this.copytextmessage.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Copy-Text-message.webp';
    this.copytextmessage.price = 480;
    this.copytextmessage.duration = 0.5;
    this.copytextmessage.id = 10448;

    this.editmessage.icon = 'icon-10449';
    this.editmessage.title = 'Edit Message';
    this.editmessage.subTitle =
      'For users to edit their messages after being sent. The edited message will be propagated  accordingly to the receivers';
    this.editmessage.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Edit-Message.webp';
    this.editmessage.price = 1800;
    this.editmessage.duration = 2;
    this.editmessage.id = 10449;

    this.recallmessage.icon = 'icon-10450';
    this.recallmessage.title = 'Recall message';
    this.recallmessage.subTitle =
      'For users to delete their sent messages without a time limit';
    this.recallmessage.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Recall-message.webp';
    this.recallmessage.price = 1800;
    this.recallmessage.duration = 2;
    this.recallmessage.id = 10450;

    this.forwardmessage.icon = 'icon-10451';
    this.forwardmessage.title = 'Forward message';
    this.forwardmessage.subTitle =
      'For a user to forward  messages in the chat to others';
    this.forwardmessage.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Forward-message.webp';
    this.forwardmessage.price = 900;
    this.forwardmessage.duration = 1;
    this.forwardmessage.id = 10451;

    this.videostreaming.icon = 'icon-10452';
    this.videostreaming.title = 'Video Streaming';
    this.videostreaming.subTitle =
      'For live streaming videos from Youtube, Vimeo, and others';
    this.videostreaming.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Video-Streaming.webp';
    this.videostreaming.price = 900;
    this.videostreaming.duration = 1;
    this.videostreaming.id = 10452;

    this.mediacompression.icon = 'icon-10453';
    this.mediacompression.title = 'Media Compression';
    this.mediacompression.subTitle =
      'Enables compressing images and videos before sending, to best suit mobile display for better user experience';
    this.mediacompression.image = '';
    this.mediacompression.price = 1800;
    this.mediacompression.duration = 2;
    this.mediacompression.id = 10453;

    this.linkmetadatafetching.icon = 'icon-10454';
    this.linkmetadatafetching.title = 'Link Metadata Fetching';
    this.linkmetadatafetching.subTitle =
      'Enables fetching metadata from a URL upon sending it';
    this.linkmetadatafetching.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Link-Metadata-Fetching.webp';
    this.linkmetadatafetching.price = 900;
    this.linkmetadatafetching.duration = 1;
    this.linkmetadatafetching.id = 10454;

    this.sendinstantarticle.icon = 'icon-10455';
    this.sendinstantarticle.title = 'Send Instant Article';
    this.sendinstantarticle.subTitle =
      'For users to send instant articles on your app';
    this.sendinstantarticle.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Send-Instant-Article.webp';
    this.sendinstantarticle.price = 1800;
    this.sendinstantarticle.duration = 2;
    this.sendinstantarticle.id = 10455;

    this.locationsharing.icon = 'icon-10456';
    this.locationsharing.title = 'Location Sharing';
    this.locationsharing.subTitle = 'For users to share a location instantly';
    this.locationsharing.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Location-Sharing.webp';
    this.locationsharing.price = 900;
    this.locationsharing.duration = 1;
    this.locationsharing.id = 10456;

    this.currentlocationsharing.icon = 'icon-10457';
    this.currentlocationsharing.title = 'Current Location Sharing';
    this.currentlocationsharing.subTitle =
      'For users to share their current locations with other users within your app';
    this.currentlocationsharing.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Current-Location-Sharing.webp';
    this.currentlocationsharing.price = 900;
    this.currentlocationsharing.duration = 1;
    this.currentlocationsharing.id = 10457;

    this.receivingshare.icon = 'icon-10458';
    this.receivingshare.title = 'Receiving Share';
    this.receivingshare.subTitle =
      'For users to share photos, videos, and files from another App';
    this.receivingshare.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Receiving-Share.webp';
    this.receivingshare.price = 1800;
    this.receivingshare.duration = 2;
    this.receivingshare.id = 10458;

    this.viewuseravatar.icon = '';
    this.viewuseravatar.title = 'View User Avatar';
    this.viewuseravatar.subTitle =
      "A feature to view a user's chosen avatars and names";
    this.viewuseravatar.image =
      'https://nandbox.com/wp-content/uploads/2022/12/View-User-Avatar.webp';
    this.viewuseravatar.price = 480;
    this.viewuseravatar.duration = 0.5;
    this.viewuseravatar.id = 10459;

    this.allowPublic.icon = '';
    this.allowPublic.title = 'Allow public groups';
    this.allowPublic.subTitle = 'Allow creating public groups from mobile';
    this.allowPublic.image = '';
    this.allowPublic.price = 480;
    this.allowPublic.duration = 0.5;
    this.allowPublic.id = 1000000;

    this.mediacaption.icon = '';
    this.mediacaption.title = 'Media Caption';
    this.mediacaption.subTitle =
      'For users to add a caption to photos, voice notes, audio, video, and files';
    this.mediacaption.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Media-Caption.webp';
    this.mediacaption.price = 1800;
    this.mediacaption.duration = 2;
    this.mediacaption.id = 10460;

    this.commentcounter.icon = '';
    this.commentcounter.title = 'Comment Counter';
    this.commentcounter.subTitle = 'Displays the number of unread comments';
    this.commentcounter.image = '';
    this.commentcounter.price = 480;
    this.commentcounter.duration = 0.5;
    this.commentcounter.id = 10461;

    this.banusers.icon = '';
    this.banusers.title = 'Ban Users';
    this.banusers.subTitle =
      'Allows administrators to kick out specific users from groups or channels created on your app';
    this.banusers.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Ban-Users.webp';
    this.banusers.price = 900;
    this.banusers.duration = 1;
    this.banusers.id = 10462;

    this.blacklistuser.icon = '';
    this.blacklistuser.title = 'Blacklist User';
    this.blacklistuser.subTitle =
      'Allows administrators to specify users and prevent them from joining a group or channel created on your app';
    this.blacklistuser.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Blacklist-User.webp';
    this.blacklistuser.price = 900;
    this.blacklistuser.duration = 1;
    this.blacklistuser.id = 10463;

    this.invitefriendsviarequest.icon = '';
    this.invitefriendsviarequest.title = 'Invite Friends via Request';
    this.invitefriendsviarequest.subTitle =
      'Your app users can invite their friends by sending them an invitation request to join the Group/Channel';
    this.invitefriendsviarequest.image = '';
    this.invitefriendsviarequest.price = 1200;
    this.invitefriendsviarequest.duration = 2;
    this.invitefriendsviarequest.id = 10464;

    this.multipleadministrators.icon = '';
    this.multipleadministrators.title = 'Multiple Administrators';
    this.multipleadministrators.subTitle =
      'For groups or channels created on your app to have multiple administrators';
    this.multipleadministrators.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Multiple-Administrators.webp';
    this.multipleadministrators.price = 1800;
    this.multipleadministrators.duration = 2;
    this.multipleadministrators.id = 10465;

    this.invitationlink.icon = '';
    this.invitationlink.title = 'Invitation Link';
    this.invitationlink.subTitle =
      'For users to share generated links of a group or a channel created on your app to invite others';
    this.invitationlink.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Invitation-Link.webp';
    this.invitationlink.price = 480;
    this.invitationlink.duration = 0.5;
    this.invitationlink.id = 10466;

    this.invitationqrcodes.icon = '';
    this.invitationqrcodes.title = 'Invitation QR Codes';
    this.invitationqrcodes.subTitle =
      'For users to subscribe to a group or channel on your app via scanning QR codes';
    this.invitationqrcodes.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Invitation-QR-Codes.webp';
    this.invitationqrcodes.price = 900;
    this.invitationqrcodes.duration = 1;
    this.invitationqrcodes.id = 10467;

    this.leavegroup.icon = '';
    this.leavegroup.title = 'Leave Group';
    this.leavegroup.subTitle =
      'For users to unsubscribe from a group or a channel created on your app';
    this.leavegroup.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Leave-Group.webp';
    this.leavegroup.price = 900;
    this.leavegroup.duration = 1;
    this.leavegroup.id = 10468;

    this.reportabuse.icon = '';
    this.reportabuse.title = 'Report Abuse';
    this.reportabuse.subTitle =
      'Enables Natural Language Processing to detect abusive text content';
    this.reportabuse.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Report-Abuse.webp';
    this.reportabuse.price = 1200;
    this.reportabuse.duration = 1.5;
    this.reportabuse.id = 10469;

    this.savetogallery.icon = '';
    this.savetogallery.title = 'Save to Gallery';
    this.savetogallery.subTitle =
      "For users to save media content in their device's gallery";
    this.savetogallery.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Save-to-Gallery.webp';
    this.savetogallery.price = 1800;
    this.savetogallery.duration = 2;
    this.savetogallery.id = 10470;

    this.pinconversation.icon = '';
    this.pinconversation.title = 'Pin Conversation';
    this.pinconversation.subTitle =
      'Enables users to pin up to three conversation chats to the top';
    this.pinconversation.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Pin-Conversation.webp';
    this.pinconversation.price = 1320;
    this.pinconversation.duration = 2;
    this.pinconversation.id = 10471;

    this.chatdashboard.icon = '';
    this.chatdashboard.title = 'Chat Dashboard';
    this.chatdashboard.subTitle =
      'A single place to monitor all chats (peer to peer, botchat, and Group chat)';
    this.chatdashboard.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Chat-Dashboard.webp';
    this.chatdashboard.price = 1800;
    this.chatdashboard.duration = 2;
    this.chatdashboard.id = 10472;

    this.poststyle.icon = '';
    this.poststyle.title = 'Post Style';
    this.poststyle.subTitle = 'Choose a style for your post view';
    this.poststyle.image = '';
    this.poststyle.price = 480;
    this.poststyle.duration = 0.5;
    this.poststyle.id = 10473;

    this.readcounter.icon = '';
    this.readcounter.title = 'Read Counter';
    this.readcounter.subTitle =
      'Displays simple statistics for how many users viewed a message within its first two weeks';
    this.readcounter.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Read-Counter.webp';
    this.readcounter.price = 900;
    this.readcounter.duration = 1;
    this.readcounter.id = 10474;

    // this.talktoadmin.icon = '';
    // this.talktoadmin.title = 'Talk to Selected Admins';
    // this.talktoadmin.subTitle =
    //   'For users to send a direct message to selected admins';
    // this.talktoadmin.image =
    //   'https://nandbox.com/wp-content/uploads/2022/12/Talk-to-Selected-Admins.webp';
    // this.talktoadmin.price = 900;
    // this.talktoadmin.duration = 1;
    // this.talktoadmin.id = 10475;

    this.commentdashboard.icon = '';
    this.commentdashboard.title = 'Comment Dashboard';
    this.commentdashboard.subTitle =
      'Collects comments and displays them in a list for channel/group administrators';
    this.commentdashboard.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Comment-Dashboard.webp';
    this.commentdashboard.price = 1800;
    this.commentdashboard.duration = 2;
    this.commentdashboard.id = 10476;

    // this.stickynote.icon = '';
    // this.stickynote.title = 'Sticky Note';
    // this.stickynote.subTitle =
    //   'A small bubble that can be attached to a post to grab attention to offers and discounts';
    // this.stickynote.image = '';
    // this.stickynote.price = 1200;
    // this.stickynote.duration = 2;
    // this.stickynote.id = 10477;

    this.invitetodownloadapp.icon = '';
    this.invitetodownloadapp.title = 'Invite to Download App';
    this.invitetodownloadapp.subTitle =
      'For users to invite their friends to download your App';
    this.invitetodownloadapp.image = '';
    this.invitetodownloadapp.price = 480;
    this.invitetodownloadapp.duration = 0.5;
    this.invitetodownloadapp.id = 10478;

    this.accountrecognition.icon = '';
    this.accountrecognition.title = 'Account Recognition';
    this.accountrecognition.subTitle =
      'Enables automatic recognition of the contacts by synching emails and mobile numbers';
    this.accountrecognition.image = '';
    this.accountrecognition.price = 1800;
    this.accountrecognition.duration = 2;
    this.accountrecognition.id = 10479;

    this.grouplist.icon = '';
    this.grouplist.title = 'Group List';
    this.grouplist.subTitle =
      'A display for the user to view a list of their subscribed groups and channels';
    this.grouplist.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Group-List.webp';
    this.grouplist.price = 900;
    this.grouplist.duration = 1;
    this.grouplist.id = 10480;

    this.refresh.icon = '';
    this.refresh.title = 'Refresh';
    this.refresh.subTitle =
      'For users to get the latest update of their subscribed groups/channels';
    this.refresh.image =
      'https://nandbox.com/wp-content/uploads/2022/12/Refresh.webp';
    this.refresh.price = 480;
    this.refresh.duration = 0.5;
    this.refresh.id = 10481;

    this.startbot.icon = '';
    this.startbot.title = 'Start Bot';
    this.startbot.subTitle =
      'Allows the users to re-start and initialize the bot';
    this.startbot.image = '';
    this.startbot.price = 900;
    this.startbot.duration = 1;
    this.startbot.id = 10482;

    // this.postfooter.icon = '';
    // this.postfooter.title = 'Post footer';
    // this.postfooter.subTitle =
    //   'Give a Facebook-look-and-feel to the posts published on your app; Users can like, comment, and share posts';
    // this.postfooter.image = '';
    // this.postfooter.price = 900;
    // this.postfooter.duration = 1;
    // this.postfooter.id = 10483;

    // this.postfilter.icon = '';
    // this.postfilter.title = 'Post filter';
    // this.postfilter.subTitle =
    //   'For well-targeted communication, use this feature to tag and reach a selected segment of your app users';
    // this.postfilter.image = '';
    // this.postfilter.price = 900;
    // this.postfilter.duration = 1;
    // this.postfilter.id = 10484;

    this.googlesync.icon = '';
    this.googlesync.title = 'Sync with System Calendar';
    this.googlesync.subTitle =
      'A feature that allows the app to access the device calendar and sync with it.';
    this.googlesync.image = '';
    this.googlesync.price = 1800;
    this.googlesync.duration = 2;
    this.googlesync.id = 15000;

    this.webview.icon = '';
    this.webview.title = 'Web view';
    this.webview.subTitle =
      'A feature that enables displaying your website on your app in a responsive webpage';
    this.webview.image = '';
    this.webview.price = 1800;
    this.webview.duration = 2;
    this.webview.id = 15001;

    this.ticketValidet.icon = '';
    this.ticketValidet.title = 'Ticket Validate';
    this.ticketValidet.subTitle =
      "A feature that allows you to validate the users' tickets";
    this.ticketValidet.image = '';
    this.ticketValidet.price = 900;
    this.ticketValidet.duration = 1;
    this.ticketValidet.id = 30000;

    this.ticketRedeem.icon = '';
    this.ticketRedeem.title = 'Ticket Redeem';
    this.ticketRedeem.subTitle =
      'A feature that allows users to redeem their tickets';
    this.ticketRedeem.image = '';
    this.ticketRedeem.price = 900;
    this.ticketRedeem.duration = 1;
    this.ticketRedeem.id = 30001;

    // this.contextMenu.icon = 'icon-menu';
    // this.contextMenu.title = 'Context Menu';
    // this.contextMenu.subTitle = '';
    // this.contextMenu.image = '';
    // this.contextMenu.price = 480;
    // this.contextMenu.duration = 2;
    // this.contextMenu.id = 10485;

    this.featuresItems = [
      this.appupdates,
      this.blockedusers,
      this.cameraaccess,
      this.captcha,
      this.countrycodeblocking,
      this.detaileddownloadhistory,
      this.disableads,
      this.downloadpermission,
      this.fingerprintpaymentauthorization,
      this.geofence,
      this.geolocationreporting,
      this.helpcenter,
      this.invitefriends,
      this.ipblocker,
      this.leechprotection,
      this.location,
      this.lostdeviceprotection,
      this.passcodelock,
      this.passwordencryption,
      this.passwordprotectedpages,
      this.passwordstrength,
      this.phoneverification,
      this.privacysetting,
      this.privatechat,
      this.profile,
      this.recenthistory,
      this.sendamessage,
      this.signup,
      this.storelocator,
      this.twitterlogin,
      this.automatedreminderemails,
      this.dailyschedulenotifications,
      this.desktopnotifications,
      this.emailnotifications,
      this.linkedaccounts,
      this.locationbasedalerts,
      this.notifications,
      this.pushnotifications,
      this.audiomusicnotifications,
      this.audiocall,
      this.audiolibrary,
      this.audioslidershow,
      this.bassboost,
      this.callrecording,
      this.playlist,
      this.remoteaudioandvideo,
      this.soundmixing,
      this.soundsettings,
      this.voicememos,
      this.accountcreation,
      this.advancedsearch,
      this.authenticationinwear,
      this.contacts,
      this.datasaving,
      this.discoversettings,
      this.displaysettings,
      this.facebooklogin,
      this.featuresettings,
      this.filecompression,
      this.forgetpassword,
      this.freetrial,
      this.googleassistant,
      this.hamburgermenu,
      this.internetaccess,
      this.keywordsearch,
      this.landingpage,
      this.linkedinlogin,
      this.livechat,
      this.logodesign,
      this.mmssettings,
      this.multilevelapprovalpath,
      this.multiplebrowsersupport,
      this.openapi,
      this.phonelogin,
      this.promocodes,
      this.savesearch,
      this.sdcard,
      this.search,
      this.shufflestations,
      this.signin,
      this.signinviaemail,
      this.signinviainstagram,
      this.signuploginmodule,
      this.sorting,
      this.splashscreen,
      this.streetpeek,
      this.termsandconditionpage,
      this.theme,
      this.twofactorauthentication,
      this.useravatar,
      this.userstatus,
      this.activityfeed,
      this.addfriends,
      this.analytics,
      this.sticker,
      this.audiovideomessenger,
      this.broadcastmessage,
      this.chat,
      this.chatbackuprestore,
      this.chatbot,
      this.collagecreator,
      this.comments,
      this.contactsharing,
      this.conversationthreading,
      this.createsketch,
      this.emaillogin,
      this.emojis,
      this.eventregistration,
      this.facebookfeedsdisplay,
      this.fanwall,
      this.filteritems,
      this.flashinstantmessenger,
      this.followers,
      this.friendlist,
      this.gallery,
      this.gifs,
      this.groupchat,
      this.groupvideocall,
      this.groups,
      this.hashtag,
      this.hashtaggenerator,
      this.icloudsharing,
      this.inappgifting,
      this.instantgiftcards,
      this.karmapoints,
      this.likeapost,
      this.linkshare,
      this.linksocialaccounts,
      this.livefeedcapture,
      this.livefeedscheduling,
      this.livestreaming,
      this.mentions,
      this.muteunmute,
      this.nearbyfriends,
      this.postcreation,
      this.recognize,
      this.repost,
      this.share,
      this.sharecalendars,
      this.slideshowcreator,
      this.sms,
      this.smssettings,
      this.storiesfeature,
      this.subscriptions,
      this.trending,
      this.twitterfeedsdisplay,
      this.universallinks,
      this.uploadmedia,
      this.upvotedownvote,
      this.useraccount,
      this.videoconversation,
      this.multiplecurrencysupport,
      this.payment,
      this.savedcards,
      this.automaticrenewals,
      this.centralizedbilling,
      this.collecttransactionfees,
      this.digitalsignature,
      this.donationpayments,
      this.inapppurchase,
      this.invoicebilling,
      this.multitieredpricing,
      this.paymentadmin,
      this.premiumplancomparison,
      this.refundmanagement,
      this.restorepurchase,
      this.splitpayments,
      this.subscriptionbilling,
      this.taxcalculator,
      this.wallet,
      this.googlelogin,
      this.reviews,
      this.advancedinsights,
      this.crm3rdpartyintegration,
      this.polling,
      this.reviewprompt,
      this.surveys,
      this.affiliateurl,
      this.bidding,
      this.coupons,
      this.dashboard,
      this.discountoffers,
      this.favorites,
      this.freecredits,
      this.guestlogin,
      this.loyaltysystem,
      this.printdata,
      this.shippingaddressvalidation,
      this.shippingchargecalculator,
      this.shoppingcart,
      this.storecredits,
      this.wishlist,
      this.zipcodessearch,
      this.gamesscore,
      this.inappsoundsvibration,
      this.leaderboard,
      this.multiplayersupport,
      this.physicsengine,
      this.amazonpayintegration,
      this.apiintegration,
      this.applepayintegration,
      this.bhimupi,
      this.bluethoothintegration,
      this.customeadvertisments,
      this.docusignintegration,
      this.freechargeintegration,
      this.freshdeskintegration,
      this.gsuiteintegration,
      this.gettyimageintegration,
      this.googleadsenseintegration,
      this.googleassistantintegration,
      this.googlewalletintegration,
      this.homecontroloptions,
      this.hubsportintegration,
      this.integratewearuilibrary,
      this.intercomeintegration,
      this.mailchimpintegration,
      this.mailinglistintegration,
      this.mixpanelanalyticsintegration,
      this.mobiwikintegration,
      this.neuralnetapiforandroid,
      this.newsintegrtation,
      this.onlinebookingintegration,
      this.outlookintegration,
      this.payrollintegration,
      this.paytmwalletintegration,
      this.quickbooksintegration,
      this.radiointegration,
      this.rssfeeds,
      this.rupayintegration,
      this.salesforceintegration,
      this.scheduleonceintegration,
      this.sendyintegration,
      this.simpledisqusintegration,
      this.slackintegration,
      this.syncswithevernote,
      this.twiliovoiceintegration,
      this.weatherintegration,
      this.youtubeintegration,
      this.barcodesscanner,
      this.itemavailability,
      this.itemgrouper,
      this.maps,
      this.mapsettings,
      this.ordersummary,
      this.pricingengine,
      this.qrcodes,
      this.requestmanagement,
      this.rfid,
      this.settings,
      this.summarycard,
      this.billableourstracking,
      this.candidatemanagement,
      this.certificationtracking,
      this.demoaccount,
      this.formapprovalworkflow,
      this.leadmanagement,
      this.organisationhierarchy,
      this.switchaccounts,
      this.timesheetmanagement,
      this.usergroups,
      this.videosupport,
      this.archive,
      this.audioequalizer,
      this.bookmarkarticlesimages,
      this.hdvideoformats,
      this.imagepicker,
      this.imagethumbnailgeneration,
      this.imagesmanagement,
      this.photofilter,
      this.photos,
      this.preview,
      this.rawformatprocessing,
      this.startvideochat,
      this.subtitles,
      this.videoautoplay,
      this.videobackgrounds,
      this.videocall,
      this.videoeditingtools,
      this.videolibrary,
      this.videomanagement,
      this.videotrimming,
      this.videos,
      this.webinars,
      this.annotations,
      this.appointmentmanagement,
      this.conferencecall,
      this.convertfile,
      this.datastorage,
      this.donotdisturb,
      this.downloadoptions,
      this.elasticsearch,
      this.googlecalendarsync,
      this.icalendarsupport,
      this.kanbanboard,
      this.languageoptions,
      this.navigationmenu,
      this.offlinebrowsing,
      this.onboardingguide,
      this.prioritizeconversation,
      this.recurringappointments,
      this.screenshoteditor,
      this.splitviewinterface,
      this.syncoptions,
      this.volumecontrol,
      this.worldclock,
      this.xmlcsvtemplatebuilder,
      this.abusedetector,
      this.answerbot,
      this.barcodesettings,
      this.contexttagging,
      this.coreml,
      this.duplicatedetection,
      this.emotiondetection,
      this.facetagging,
      this.imagecategorisation,
      this.keywordextractor,
      this.languagedetection,
      this.multiplegesturerecognizer,
      this.productrecommendation,
      this.repeatingtasks,
      this.sentimentanalysis,
      this.targetedfeed,
      this.textcomparison,
      this.visualsearch,
      this.addcreditcard,
      this.baselinereporting,
      this.categoriessubcategories,
      this.ideamanagement,
      this.notificationssettings,
      this.projectprotfolio,
      this.projectreporting,
      this.projecttemplates,
      this.qatracker,
      this.adhocreporting,
      this.admanager,
      this.budgetingforecasting,
      this.comprehensivereporting,
      this.dailyemailsignup,
      this.deeplinking,
      this.emaillists,
      this.events,
      this.graphicalcharts,
      this.inventorytrendanalysis,
      this.locallistingads,
      this.pledgetracking,
      this.promotecontent,
      this.referrals,
      this.salesreporting,
      this.searchtermsreports,
      this.statisticsreports,
      this.trafficsources,
      this.videoads,
      this.visualanalytics,
      this.automatedprioritization,
      this.devicemanagement,
      this.leavetracker,
      this.prioritize,
      this.scheduling,
      this.taskallocator,
      this.taskblocker,
      this.taskgroups,
      this.tasklist,
      this.taskviews,
      this.teambuilder,
      this.timetracker,
      this.accountscoreranking,
      this.accounttransfertool,
      this.activitylog,
      this.addressmanagement,
      this.approvalprocesscontrol,
      this.customizableusersubscriptions,
      this.dailylimit,
      this.dashboardguests,
      this.livechatsummary,
      this.proposalgeneration,
      this.remoteadministration,
      this.rolespermissions,
      this.servicespecificsettingsadmin,
      this.singlesignonsso,
      this.agerestriction,
      this.audittrail,
      this.automatedpublishing,
      this.blogimporting,
      this.blogpostsmanagement,
      this.bulkcommentmanagement,
      this.bulkuploading,
      this.catalog,
      this.communityforum,
      this.contentexporting,
      this.contentflag,
      this.contentmoderation,
      this.customform,
      this.dataimportexportcsv,
      this.datamigration,
      this.documentation,
      this.documentdistribution,
      this.documentpreviewing,
      this.download,
      this.dynamiccontent,
      this.fileattachment,
      this.importphotoshopdocumentspsd,
      this.library,
      this.manageblogcomments,
      this.mergingcontent,
      this.multipageforms,
      this.notes,
      this.paidcontent,
      this.pdfconversion,
      this.readermode,
      this.spellcheck,
      this.testimonials,
      this.travelguide,
      this.wordlookup,
      this.realtimeupdates,
      this.tutorials,
      this.advancedclashmanagement,
      this.customizedorderstatus,
      this.deliveryestimator,
      this.expensetracking,
      this.expressdelivery,
      this.orderdetailview,
      this.reservations,
      this.waitinglist,
      this.whitelisingemails,
      this.smartcategorization,
      this.calllogdetail,
      this.viewprofile,
      this.switchvideocalltoaudiocall,
      this.muteunmutemicrophone,
      this.turnspeakeronoff,
      this.switchcalltobluetooth,
      this.connectingtone,
      this.ringtone,
      this.switchcamera,
      this.adjustvideocallwindow,
      this.ip6ip4callconnectivity,
      this.callcancel,
      this.multitaskingfeature,
      this.callnotifications,
      this.lightnotification,
      this.lastseen,
      this.onlinestatus,
      this.readreceipt,
      this.sendtextmessagewithbackground,
      this.sendfile,
      this.deletechat,
      this.copytextmessage,
      this.editmessage,
      this.recallmessage,
      this.forwardmessage,
      this.videostreaming,
      this.mediacompression,
      this.linkmetadatafetching,
      this.sendinstantarticle,
      this.locationsharing,
      this.currentlocationsharing,
      this.receivingshare,
      this.viewuseravatar,
      this.allowPublic,
      this.mediacaption,
      this.commentcounter,
      this.banusers,
      this.blacklistuser,
      this.invitefriendsviarequest,
      this.multipleadministrators,
      this.invitationlink,
      this.invitationqrcodes,
      this.leavegroup,
      this.reportabuse,
      this.savetogallery,
      this.pinconversation,
      this.chatdashboard,
      this.poststyle,
      this.readcounter,
      this.commentdashboard,
      this.invitetodownloadapp,
      this.accountrecognition,
      this.grouplist,
      this.refresh,
      this.startbot,
      this.googlesync,
      this.webview,
      this.ticketValidet,
      this.ticketRedeem
    ];
  }

  ngOnInit() {
    let imagePath = '';
    this.imageArray = [];
    this.subfeatures = [];

    if (this.tabContainer && this.tabContainer.ui_module_id) {
      this.features = this.featuresSubList.getModuleItem(
        this.tabContainer.ui_module_id
      );
    }
    this.activeFeature = {};
    if (this.features && this.features.length >= 1) {
      for (let i = 0; i < this.features.length; i++) {
        for (let j = 0; j < this.featuresItems.length; j++) {
          if (this.features[i].id == this.featuresItems[j].id) {
            this.subfeatures.push(this.featuresItems[j]);
            break;
          }
        }
      }
    }
    if (
      this.subfeatures[0] &&
      (this.subfeatures[0].id == 10477 ||
        this.subfeatures[0].id == 10475 ||
        this.subfeatures[0].id == 10484 ||
        this.subfeatures[0].id == 10483)
    ) {
      this.featureId = this.subfeatures[0].id;
      this.activeFeature = this.subfeatures[0];
    } else if (this.subfeatures[0]) {
      this.featureTitle = this.subfeatures[0].title;
      this.featureDesc = this.subfeatures[0].subTitle;
      this.featureId = null;
      this.activeFeature = this.subfeatures[0];
    }

    if (
      this.tabContainer.type === this.tabTypes.QR ||
      this.tabContainer.link === this.tabTypes.QR
    ) {
      if (this.tabContainer.options === '1') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
      } else if (this.tabContainer.options === '0') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/scanner-android.webp';
      } else if (this.tabContainer.options === '2') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/qremail-android.webp';
      }
    } else if (this.activeFeature && this.activeFeature.image) {
      imagePath = this.activeFeature.image;
    } else {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/invisible-android.webp';
    }

    this.imageArray = [imagePath];
  }
  ngOnChanges() {
    if (this.tabContainer) {
      this.features = this.featuresSubList.getModuleItem(
        this.tabContainer.ui_module_id
      );
      this.subfeatures = [];
      if (this.features && this.features.length >= 1) {
        for (let i = 0; i < this.features.length; i++) {
          for (let j = 0; j < this.featuresItems.length; j++) {
            if (this.features[i].id == this.featuresItems[j].id) {
              this.subfeatures.push(this.featuresItems[j]);
              break;
            }
          }
        }
      }
      this.activeFeature = this.subfeatures[0];

      let imagePath = '';
      if (
        this.tabContainer.type === this.tabTypes.QR ||
        this.tabContainer.link === this.tabTypes.QR
      ) {
        if (this.tabContainer.options === '1') {
          imagePath =
            'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
        } else if (this.tabContainer.options === '0') {
          imagePath =
            'https://nandbox.com/wp-content/uploads/2022/12/scanner-android.webp';
        } else if (this.tabContainer.options === '2') {
          imagePath =
            'https://nandbox.com/wp-content/uploads/2022/12/qremail-android.webp';
        }
      } else if (this.activeFeature && this.activeFeature.image) {
        imagePath = this.activeFeature.image;
      } else {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/invisible-android.webp';
      }
      this.imageArray = [imagePath];
      this._ref.detectChanges();
    }
  }
  showFeatureImage(event) {
    if (event) {
      this.featureTitle = event.title;
      this.featureDesc = event.subTitle;

      this.featureId = null;

      this.activeFeature = event;
    }
    let imagePath = '';
    if (
      this.tabContainer.type === this.tabTypes.QR ||
      this.tabContainer.link === this.tabTypes.QR
    ) {
      if (this.tabContainer.options === '1') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
      } else if (this.tabContainer.options === '0') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/scanner-android.webp';
      } else if (this.tabContainer.options === '2') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/qremail-android.webp';
      }
    } else if (this.activeFeature && this.activeFeature.image) {
      imagePath = this.activeFeature.image;
    } else {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/invisible-android.webp';
    }

    this.imageArray = [imagePath];

    this._ref.detectChanges();
  }
  setDefaults() {}

  getTotalPrices(itemsIds) {
    if (itemsIds) {
      const pricingList: FeatureItem[] = [];
      for (let i = 0; i < itemsIds.length; i++) {
        if (itemsIds[i]) {
          for (let x = 0; x < itemsIds[i].length; x++) {
            if (itemsIds[i][x].default) {
              for (let j = 0; j < this.featuresItems.length; j++) {
                if (itemsIds[i][x].id == this.featuresItems[j].id) {
                  if (pricingList.indexOf(this.featuresItems[j]) <= -1) {
                    pricingList.push(this.featuresItems[j]);
                  }
                }
              }
            }
          }
        }
      }
      return pricingList;
    }
  }
  getFeatureId(event) {
    this.featureId = event.id;
    this.activeFeature = event;
    this.featureTitle = event.title;
    this.featureDesc = event.subTitle;
    let imagePath = '';
    if (
      this.tabContainer.type === this.tabTypes.QR ||
      this.tabContainer.link === this.tabTypes.QR
    ) {
      if (this.tabContainer.options === '1') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
      } else if (this.tabContainer.options === '0') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/scanner-android.webp';
      } else if (this.tabContainer.options === '2') {
        imagePath =
          'https://nandbox.com/wp-content/uploads/2022/12/qremail-android.webp';
      }
    } else if (this.activeFeature && this.activeFeature.image) {
      imagePath = this.activeFeature.image;
    } else {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/invisible-android.webp';
    }
    this.imageArray = [imagePath];
    this._ref.detectChanges();
  }

  changeQrImage(event) {
    let imagePath =
      'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
    if (event === '1') {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/QR-Scanner-android.webp';
    } else if (event === '0') {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/scanner-android.webp';
    } else if (event === '2') {
      imagePath =
        'https://nandbox.com/wp-content/uploads/2022/12/qremail-android.webp';
    }

    this.imageArray = [imagePath];
  }
}
