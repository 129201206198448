import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductsStoreDispatchers } from 'src/app/store/product-store/product-store.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ProductStore } from 'src/models/productStore';

@Injectable({
  providedIn: 'root'
})
export class ProductsStoreService {
  confirmProduct = false;
  selectedProduct: any;

  public _hideOptionBtn = new BehaviorSubject<boolean>(false);
  public hideOptionBtn$ = this._hideOptionBtn.asObservable();

  constructor(
    private _rootStore: RootStoreSelectors,
    private _productStoreDispatcher: ProductsStoreDispatchers
  ) { }

  get productsStore$() {
    return this._rootStore.getProductsStore$();
  }

  get selectedProductStore$() {
    return this._rootStore.getSelecetedProductStore$();
  }

  resetPaging() {
    this._hideOptionBtn.next(false);
    this._productStoreDispatcher.resetProductsStorePaging();
  }

  getNext() {
    this._productStoreDispatcher.getNextProductsStorePage();
  }

  getPrev() {
    this._productStoreDispatcher.getPrevProductsStorePage();
  }

  setProductStoreItem(product: ProductStore) {
    this._productStoreDispatcher.setProductStoreItem(product);
  }

  setProductsItemArray(product: ProductStore[], parent_id?: number, type?: number) {
    this._productStoreDispatcher.setProductsItemArray(product, parent_id, type);
  }

  editEvent(product: ProductStore) {
    this._productStoreDispatcher.setSelectedProductStore(product);
  }

  getProductItem(id: number) {
    this._productStoreDispatcher.getProductItem(id);
  }

  removeProductItem(id: number) {
    this._productStoreDispatcher.removeProductItem(id);
  }

  toggleConfirmDeleteProduct(e: boolean, product?: any) {
    this.confirmProduct = e;
    this.selectedProduct = product;
  }

  setCollectionsToProduct(productID: number, collections: number[]) {
    this._productStoreDispatcher.setCollectionsToProduct(productID, collections);
  }
}
