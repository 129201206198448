<ng-container *ngIf="!privillage">
  <div class="container" *ngIf="getModulePlanDetails as ModuleDetails">
    <div class="container-image">
      <img [src]="ModuleDetails.imageUrl" />
    </div>
    <div class="container-body">
      <div class="close-sec clickable-cursor" (click)="closePopup()">
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-close"></use>
        </svg>
      </div>
      <div class="module-icon">
        <svg class="svg-icon icon-icon">
          <use attr.xlink:href="./assets/svg/{{ ModuleDetails.icon }}"></use>
        </svg>
      </div>
      <div class="module-title">{{ ModuleDetails.title }}</div>
      <div class="module-desc">
        {{ ModuleDetails.description }}
      </div>
      <div class="module-accesses">
        Available in:
        <div class="accesses">
          <span *ngFor="let plan of ModuleDetails.plans">
            <svg class="svg-icon icon-icon">
              <use
                attr.xlink:href="./assets/svg/app-extra-icons.svg#{{
                  !plan.status ? 'icon-uncheck-circle' : 'icon-check-circle'
                }}"
              ></use>
            </svg>
            <span style="margin-top: 1px;">
              {{ plan.planName }}
            </span>
          </span>
        </div>
      </div>
      <button
        class="upgrade-btn"
        *ngIf="
          localStorage.getRecord('loginType') === null ||
          localStorage.getRecord('loginType') === 'null' ||
          localStorage.getRecord('loginType') === 'email'
        "
        (click)="showSubscriptionModal()"
      >
        Upgrade
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="privillage">
  <div
    class="container"
    *ngIf="getModulePlanDetailsByPrivillage as ModuleDetails"
  >
    <div class="container-image">
      <img [src]="ModuleDetails.imageUrl" />
    </div>

    <div class="container-body">
      <div class="module-icon">
        <svg class="svg-icon icon-icon">
          <use attr.xlink:href="./assets/svg/{{ ModuleDetails.icon }}"></use>
        </svg>
      </div>
      <div class="module-title">{{ ModuleDetails.title }}</div>
      <div class="module-desc">
        {{ ModuleDetails.description }}
      </div>
      <div class="module-accesses">
        Available in:
        <div class="accesses">
          <span *ngFor="let plan of ModuleDetails.plans">
            <svg class="svg-icon icon-icon">
              <use
                attr.xlink:href="./assets/svg/app-extra-icons.svg#{{
                  !plan.status ? 'icon-uncheck-circle' : 'icon-check-circle'
                }}"
              ></use>
            </svg>
            <span style="margin-top: 1px;">
              {{ plan.planName }}
            </span>
          </span>
        </div>
      </div>
      <button
        class="upgrade-btn"
        *ngIf="
          localStorage.getRecord('loginType') === null ||
          localStorage.getRecord('loginType') === 'null' ||
          localStorage.getRecord('loginType') === 'email'
        "
        (click)="showSubscriptionModal()"
      >
        Upgrade
      </button>
    </div>
  </div>
</ng-container>
