import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  MarketTypes,
  ChatTypes,
  mStoreMenuScreens
} from 'src/models/constants';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { IChat } from 'src/models/IChat';
import { StoreMenu, StoreMenuItem } from 'src/models/StoreMenu';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';
import { SubMenuStore } from 'src/models/SubMenu';

@Injectable({
  providedIn: 'root'
})
export class StoreMenuService {
  private _selectedStoreMenu = new BehaviorSubject<StoreMenu>(null);
  public selectedStoreMenu$ = this._selectedStoreMenu.asObservable();

  private _currentStoreScreen = new BehaviorSubject<string>(
    mStoreMenuScreens.mStoreMenu_LIST
  );
  public currentStoreMenuScreen$ = this._currentStoreScreen.asObservable();
  marketTypes = MarketTypes;
  chatTypes: typeof ChatTypes = ChatTypes;
  confirmDeleteMenu = false;
  selectedMenu: any;
  constructor(
    private _rootStore: RootStoreSelectors,
    private _storeMenuDispatchers: StoreMenuUiDispatchers
  ) {}

  get storeMenus$() {
    return this._rootStore.getStoreMenus$();
  }

  storeMenuById$(id: string) {
    return this._rootStore.getStoreMenuById$(id);
  }
  getStoreMenu(store_menu_id) {
    this._storeMenuDispatchers.getStoreMenu(store_menu_id);
  }
  resetPaging() {
    this._storeMenuDispatchers.resetStoreMenuPaging();
  }
  getProduct$(id: string) {
    return this._rootStore.getProductById$(id);
  }
  getBundle$(id: string) {
    return this._rootStore.getBundle$(id);
  }
  getPackage$(id: string) {
    return this._rootStore.getPackageById$(id);
  }
  updateStoreMenu(storeMenu: StoreMenu) {
    if (storeMenu && storeMenu.id) {
      // if (storeMenu.items) {
      //   const itemsIds: StoreMenuItem[] = [];
      //   for (let i = 0; i < storeMenu.items.length; i++) {
      //     const newItem: StoreMenuItem = {};
      //     // if (store.items[i].product_id) {
      //     //   newItem.id = store.items[i].product_id;
      //     // } else {
      //     //   newItem.id = store.items[i].id;
      //     // }
      //     newItem.sub_menu = storeMenu.items[i].sub_menu;
      //     // newItem.min_item = 1;
      //     // newItem.max_item = 1;
      //     itemsIds.push(newItem);
      //   }
      //   this._storeMenuDispatchers.addStoreMenuItem(storeMenu.id, itemsIds);
      // }
      this._storeMenuDispatchers.setStoreMenu(storeMenu.id, storeMenu);
    } else {
      this._storeMenuDispatchers.createStoreMenu(storeMenu);
    }
    this.closeItemCardEvent();
  }

  refreshStoreMenu() {
    this._storeMenuDispatchers.refreshStoreMenu();
  }

  setStoreMenuItem(storeMenuId: string, items: StoreMenuItem[]) {
    // const itemsIds = [];
    // for (let i = 0; i < items.length; i++) {
    //   const item: StoreMenuItem = {};
    //   // if (items[i].product_id) {
    //   //   item.id = items[i].product_id;
    //   // } else {
    //   //   item.id = items[i].id;
    //   // }
    //   item.sub_menu_id = items[i].id;
    //   item.max_item = 1;
    //   item.min_item = 1;
    //   itemsIds.push(item);
    // }
    this._storeMenuDispatchers.addStoreMenuItem(storeMenuId, items);
  }

  createItemEvent() {
    this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_CREATION);
    this.setSelectedStoreMenu(null);
  }
  readItemEvent(storeMenu: StoreMenu) {
    this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_VIEW);
    this.setSelectedStoreMenu(storeMenu);
  }
  updateItemEvent(storeMenu: StoreMenu) {
    this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_EDIT);
    this.setSelectedStoreMenu(storeMenu);
  }
  deleteMenu(id: string) {
    this._storeMenuDispatchers.deleteStoreMenu(id);
  }
  toggleConfirmDeleteMenu(e: boolean, menu?: any) {
    this.confirmDeleteMenu = e;
    this.selectedMenu = menu;
  }
  closeItemCardEvent() {
    this.setCurrentScreen(mStoreMenuScreens.mStoreMenu_LIST);
    this.setSelectedStoreMenu(null);
  }

  setSelectedStoreMenu(storeMenu: StoreMenu) {
    this._selectedStoreMenu.next(storeMenu);
  }

  setCurrentScreen(screen: string) {
    this._currentStoreScreen.next(screen);
  }
  getNextPage() {
    this._storeMenuDispatchers.getNextStoreMenuPage();
  }

  getPreviousPage() {
    this._storeMenuDispatchers.getPrevStoreMenuPage();
  }
  getChannelDetails$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
}
