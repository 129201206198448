<div class="instant-cell">
  <app-link-preview-cell *ngIf="
      message?.linkPreviewStatus === msgLinkPreviewStat.LINK_PREVIEW_FETCHED
    "
    class="instant-cell-preview wrap-text"
    [title]="message?.linkPreviewTitle"
    [showSite]="false"
    [description]="message?.linkPreviewDescription"
    [image]="message?.linkPreviewImage"
    [height]="message?.linkPreviewHeight"
    [width]="message?.linkPreviewWidth"
    [url]="message?.linkPreviewUrl"
    [style]="message?.style"
    [rootUrl]="message?.linkPreviewRootUrl"
    [redirect]="redirect">
    <ng-content></ng-content>
  </app-link-preview-cell>
  <div *ngIf="
      message?.linkPreviewStatus === msgLinkPreviewStat.LINK_PREVIEW_FETCHING
    "
    class="instant-cell-loader">
    <i class="fas fa-circle-notch fa-spin"></i>
  </div>
</div>