import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as CalendarUIActions from './calendarUI.actions';

import { AppState } from 'src/models/AppState';
import { CalendarTimetable, CalendarDay } from 'src/models/Calendar';

@Injectable({
  providedIn: 'root'
})
export class CalendarUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createCalendar(calendar: CalendarTimetable): void {
    this._store.dispatch(new CalendarUIActions.CreateCalendar(calendar));
  }

  updateCalendar(calendarId: string, calendar: CalendarTimetable): void {
    this._store.dispatch(
      new CalendarUIActions.UpdateCalendar(calendarId, calendar)
    );
  }

  calendarReceived(calendar: CalendarTimetable): void {
    this._store.dispatch(new CalendarUIActions.CalendarReceived(calendar));
  }

  // no need for reducer for this action, only at the effects request the details
  calendarListReceived(calendars: CalendarTimetable[]): void {
    this._store.dispatch(new CalendarUIActions.CalendarListReceived(calendars));
  }

  setCalendarDetails(
    calendar_id: string,
    data: CalendarDay[],
    reference?: string
  ): void {
    this._store.dispatch(
      new CalendarUIActions.SetCalendarDetails(calendar_id, data)
    );
  }

  calendarDetailsReceived(calendar_id: string, data: CalendarDay[]): void {
    this._store.dispatch(
      new CalendarUIActions.CalendarDetailsReceived(calendar_id, data)
    );
  }

  deleteCalendarDetail(calendar_id: string, references: string[]): void {
    this._store.dispatch(
      new CalendarUIActions.DeleteCalendarDetail(calendar_id, references)
    );
  }

  viewCalendarUI(selectedCalendar: CalendarTimetable): void {
    this._store.dispatch(
      new CalendarUIActions.ViewCalendarUI(selectedCalendar)
    );
  }

  closeViewCalendar(): void {
    this._store.dispatch(new CalendarUIActions.CloseViewCalendar());
  }

  editCalendarUI(selectedCalendar: CalendarTimetable): void {
    this._store.dispatch(
      new CalendarUIActions.EditCalendarUI(selectedCalendar)
    );
  }

  closeEditCalendar(): void {
    this._store.dispatch(new CalendarUIActions.CloseCalendarEdit());
  }

  createCalendarUI(): void {
    this._store.dispatch(new CalendarUIActions.CreateCalendarUI());
  }

  calendarListUI(): void {
    this._store.dispatch(new CalendarUIActions.CalendarListUI());
  }

  calendarCreationComplete(): void {
    this._store.dispatch(new CalendarUIActions.CalendarCreationComplete());
  }

  setCalendarInCreation(createdCalendar: CalendarTimetable): void {
    this._store.dispatch(
      new CalendarUIActions.SetCalendarInCreation(createdCalendar)
    );
  }

  // paging dispatchers
  getNextCalendarPage(): void {
    this._store.dispatch(new CalendarUIActions.GetNextCalendarPage());
  }

  getPrevCalendarPage(): void {
    this._store.dispatch(new CalendarUIActions.GetPrevCalendarPage());
  }

  receivedCalendarHistoryChunk(
    eop: number,
    sop: number,
    currentPage: CalendarTimetable[]
  ): void {
    this._store.dispatch(
      new CalendarUIActions.ReceivedCalendarHistoryChunk(eop, sop, currentPage)
    );
  }
  resetCalendarPaging(): void {
    this._store.dispatch(new CalendarUIActions.ResetCalendarPaging());
  }
}
