<button
  class="app-btn"
  [disabled]="disabled"
  (click)="onClick()"
  (mouseover)="mouseover()"
  (mouseleave)="mouseleave()"
  [type]="type"
  [class.active]="frame"
>
  <span><ng-content></ng-content></span>
</button>
