import * as tslib_1 from "tslib";
import { UIDispatchers } from '../ui/ui.dispatchers';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { NOT_ALLOWED_COLORS } from 'src/models/constants';
export class AppClassEffects {
    constructor(actions$, _uiDispatchers) {
        this.actions$ = actions$;
        this._uiDispatchers = _uiDispatchers;
        this.chatCounters = this.actions$.pipe(ofType("NOT_ALLOWED_CLASS_BG_COLOR" /* NOT_ALLOWED_CLASS_BG_COLOR */), map((action) => {
            this._uiDispatchers.showPopup(NOT_ALLOWED_COLORS);
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppClassEffects.prototype, "chatCounters", void 0);
