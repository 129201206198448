<!-- <div class="drp-list">
  <span>{{ selected?.title }}</span>
</div> -->
<ng-container *ngIf="!isDropDown">
  <div class="list-tec" *ngIf="type === 'teq'" [class.active]="type === 'teq'">
    <div
      class="list-item-tec"
      *ngFor="let item of teqQuestionItems"
      (click)="selectedItem(item)"
    >
      <img [src]="'./assets/img/' + item.img" />
      <div class="title">
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>

  <div class="list" *ngIf="type === 'cat'">
    <div class="list-item">
      <div class="cat-header">
        CATEGORIES
      </div>
      <div class="cat-title" (click)="selectedItem(allCat)">
        <!-- <svg class="svg-icon icon-icon">
        <use att.xlink:href="./assets/img/{{ item.img }}"></use>
        </svg> -->
        <div
          class="unselected-cat"
          *ngIf="
            (appMgmtService?.selectedTempCat$ | async).type !== allCat.type
          "
        >
          <svg-icon [src]="'./assets/img/selectedAllTemplates.svg'"></svg-icon>
          <div
            class="header"
            *ngIf="
              (appMgmtService?.selectedTempCat$ | async).type !== allCat.type
            "
          >
            All Template
          </div>
        </div>
        <div
          class="selected-cat"
          *ngIf="
            (appMgmtService?.selectedTempCat$ | async).type === allCat.type
          "
        >
          <svg-icon [src]="'./assets/img/all-templates.svg'"></svg-icon>
          <div class="header">
            All Template
          </div>
        </div>
      </div>
      <ng-container *ngIf="appMgmtService?.selectedTempCat$ | async as cat">
        <ng-container *ngFor="let item of catQuestionItems">
          <div class="cat-title" (click)="selectedItem(item)">
            <!-- <svg class="svg-icon icon-icon">
            <use att.xlink:href="./assets/img/{{ item.img }}"></use>
            </svg> -->
            <div class="unselected-cat" *ngIf="cat?.type !== item?.type">
              <svg-icon [src]="'./assets/img/' + item.img"></svg-icon>
              <div class="header">
                {{ item.title }}
              </div>
            </div>

            <div class="selected-cat" *ngIf="cat?.type === item?.type">
              <svg-icon [src]="'./assets/img/' + item.imgS"></svg-icon>
              <div class="header">
                {{ item.title }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- <div class="cat-desc">
        {{ item.desc }}
      </div> -->
    </div>
  </div>
</ng-container>

<div
  dropdown
  class="btn-group clickable-cursor menu-dropdown"
  *ngIf="isDropDown"
  placement="bottom right"
>
  <div dropdownToggle>
    <svg class="svg-icon icon-icon" style="width: 24px; height: 24px;">
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-menu-alt"></use>
    </svg>
  </div>
  <ul
    *dropdownMenu
    class="dropdown-menu no-copyable-item menu-container"
    role="menu"
  >
    <li
      class="menuitem clickable-cursor drop-item-menu"
      role="menuitem"
      type="button"
      (click)="selectedItem(allCat)"
    >
      <!-- <ng-container
        *ngIf="(appMgmtService?.selectedTempCat$ | async).type !== allCat.type"
      > -->
      <svg-icon
        [src]="'./assets/img/selectedAllTemplates.svg'"
        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      ></svg-icon>
      <div class="header">
        All Template
      </div>
      <!-- </ng-container> -->
    </li>
    <ng-container *ngIf="appMgmtService?.selectedTempCat$ | async as cat">
      <ng-container *ngFor="let item of catQuestionItems">
        <li
          class="menuitem clickable-cursor drop-item-menu"
          role="menuitem"
          type="button"
          (click)="selectedItem(item)"
        >
          <!-- <svg class="svg-icon icon-icon">
          <use att.xlink:href="./assets/img/{{ item.img }}"></use>
          </svg> -->
          <ng-container *ngIf="cat?.type !== item?.type">
            <svg-icon
              [src]="'./assets/img/' + item.img"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            ></svg-icon>
            <div class="header">
              {{ item.title }}
            </div>
          </ng-container>

          <ng-container *ngIf="cat?.type === item?.type">
            <svg-icon
              [src]="'./assets/img/' + item.imgS"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            ></svg-icon>
            <div class="header">
              {{ item.title }}
            </div>
          </ng-container>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
