import { ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import * as i0 from "@angular/core";
export class SubscriptionModalService {
    constructor(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    appendSubscriptionModalComponentToBody() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SubscriptionModalComponent);
        const componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        componentRef.instance.onClose.subscribe(() => {
            this.removeSubscriptionModalComponentFromBody();
        });
        const domElem = componentRef.hostView
            .rootNodes[0];
        document.body.appendChild(domElem);
        this.subscriptionModalComponentRef = componentRef;
    }
    removeSubscriptionModalComponentFromBody() {
        this.appRef.detachView(this.subscriptionModalComponentRef.hostView);
        this.subscriptionModalComponentRef.destroy();
    }
    openSubscriptionModal() {
        this.appendSubscriptionModalComponentToBody();
    }
}
SubscriptionModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionModalService_Factory() { return new SubscriptionModalService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: SubscriptionModalService, providedIn: "root" });
