<div class="profile-cell clickable-cursor"
  [class.selected]="chat?.id === (uiService.uiCollection$ | async)?.selectedSubChat?.id">
  <app-profile-image class="profile-cell-avtar"
    [id]="chat?.id"
    [chatType]="chat?.type"
    [size]="size"
    [emSize]="emSize"
    [chat]="chat"
    [isPostScreen]="isPostScreen">
  </app-profile-image>
  <div class="profile-cell-core  truncate-text"
    [class.remove-border]="isLastItem">
    <div class="header ">
      <div class="header-name ">
        <img *ngIf="chat?.type === 'Group'"
          src="./assets/img/groups-black.svg"
          alt="group icon" />
        <!-- <div class="name text-capitalize truncate-text">
          {{ textTruncate(chat?.name) }}
        </div> -->
        <span class="name text-capitalize "
          [innerHTML]="textTruncate(chat?.name) | twemoji">
        </span>
      </div>
      <app-standard-date *ngIf="!hideDate"
        class="header-time"
        [timestamp]="chat?.lastMsgTime"
        [timesFormat]="timesFormat"
        class="time"></app-standard-date>
    </div>
    <div class="footer">
      <svg *ngIf="chat?.lastReplyStatus"
        class="svg-icon icon-icon">
        <use [appMessageStatusIcon]="chat?.lastReplyStatus"
          xlink:href=""></use>
      </svg>

      <svg *ngIf="chat?.lastReplyType"
        class="svg-icon icon-icon">
        <use [appMessageTypesIcon]="chat?.lastReplyType"
          xlink:href=""></use>
      </svg>
      <span *ngIf="chat?.lastReplyType && !chat?.lastReplyMessage"
        class="truncate-text"
        dir="auto"
        [innerHTML]="
          chat?.lastSenderNameInGroup
            ? (chat?.lastSenderNameInGroup + ' : ' + chat?.lastReplyType
              | localType)
            : (chat?.lastReplyType | localType)
        ">
      </span>
      <span *ngIf="chat?.lastReplyMessage"
        class="truncate-text"
        dir="auto"
        [innerHTML]="
          chat?.lastSenderNameInGroup
            ? (chat?.lastSenderNameInGroup + ' : ' + chat?.lastReplyMessage
              | twemoji)
            : (chat?.lastReplyMessage | twemoji)
        "
        style="width: 75%; text-align: left;">
      </span>
      <span class="full-width"></span>
      <span class="tail">
        <img *ngIf="chat?.priority && !chat?.pinned_date"
          src="./assets/img/priorit.svg"
          alt="priorit" />
        <img *ngIf="chat?.pinned_date"
          src="./assets/img/pin-icon.svg"
          alt="pin" />
        <span *ngIf="
            chat?.unreadCounter && chat?.unreadCounter !== 0 && !chat?.selected
          "
          class="unread-counter"
          [class.profile]="chat?.type !== 'Channel'"
          [class.channel]="chat?.type === 'Channel'">
          {{ chat?.unreadCounter | countSize }}
        </span>
      </span>
    </div>
  </div>
  <!-- <img
    src="./assets/img/selectedSection.png"
    *ngIf="chat?.id === (layoutService?.selectedChat$ | async)?.id"
  /> -->
</div>