import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import * as Methods from 'src/models/mapTripsNotifiers';
import { MapTripsNotifiersDispatchers } from './mapTripsNotifiers.dispatchers';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
export class MapTripsNotifiersEffects {
    constructor(actions$, _store, _socketGateway, mapTripsNotifiersDispatchers) {
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        this.mapTripsNotifiersDispatchers = mapTripsNotifiersDispatchers;
        this.resetMapTripsNotifiersPaging = this.actions$.pipe(ofType("UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING" /* UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING */), map((action) => {
            this.mapTripsNotifiersDispatchers.getTripsNotifiers();
        }));
        this.getTripsNotifiers = this.actions$.pipe(ofType("UI_GET_TRIPS_NOTIFIERS" /* UI_GET_TRIPS_NOTIFIERS */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.GetMapTripsNotifiers(0, ''));
        }));
        this.createTripsNotifiers = this.actions$.pipe(ofType("UI_CREATE_MAP_ACCOUNT_LINKS" /* UI_CREATE_MAP_ACCOUNT_LINKS */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapTripNotifiers(action.tripNotifier.account_id, action.tripNotifier.link_ids, 'CreateAccountLinks'));
        }));
        this.getNotifiersNextPage = this.actions$.pipe(ofType("UI_GET_NOTIFIERS_LIST_NEXT_PAGE" /* UI_GET_NOTIFIERS_LIST_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripsNotifiersReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsNotifiers]) => {
            const action = val;
            const prevRequest = mapTripsNotifiers.previousRequest;
            if (!(mapTripsNotifiers.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapTripsNotifiers.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetNotifiersList(mapTripsNotifiers.accountID, 0, mapTripsNotifiers.eop));
            }
        }));
        this.getNotifiersPrevPage = this.actions$.pipe(ofType("UI_GET_NOTIFIERS_LIST_PREV_PAGE" /* UI_GET_NOTIFIERS_LIST_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripsNotifiersReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsNotifiers]) => {
            const action = val;
            const prevRequest = mapTripsNotifiers.previousRequest;
            if (!(mapTripsNotifiers.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapTripsNotifiers.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetNotifiersList(mapTripsNotifiers.accountID, 1, mapTripsNotifiers.sop));
            }
        }));
        this.resetNotifiersPaging = this.actions$.pipe(ofType("UI_GET_NOTIFIERS_LIST_RESET_PAGING" /* UI_GET_NOTIFIERS_LIST_RESET_PAGING */), map((action) => {
            this.mapTripsNotifiersDispatchers.getNextNotifiersList();
        }));
        this.updateMapAccountLinks = this.actions$.pipe(ofType("UI_UPDATE_ACCOUNT_LINK_SUCCESS" /* UI_UPDATE_ACCOUNT_LINK_SUCCESS */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.UpdateMapTripNotifiers(action.accountID, action.link_ids, 'setMapAccountLinks'));
        }));
        this.removeAccountLink = this.actions$.pipe(ofType("UI_REMOVE_ACCOUNT_LINK" /* UI_REMOVE_ACCOUNT_LINK */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveAccountLink(action.id, 'removeMapAccountLink'));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "resetMapTripsNotifiersPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "getTripsNotifiers", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "createTripsNotifiers", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "getNotifiersNextPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "getNotifiersPrevPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "resetNotifiersPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "updateMapAccountLinks", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsNotifiersEffects.prototype, "removeAccountLink", void 0);
