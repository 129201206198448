<div class="timer" [class.hidde]="hideCountDown$ | async">
  <div class="timer-title">Free trial</div>
  <div class="timer-countdown">
    <div class="count" style="margin-right: 8px;">
      <div class="value" id="days"></div>
      <div class="label">Days</div>
    </div>
    <div class="count" style="margin-right: 4px;">
      <div class="value" id="hours"></div>
      <div class="label">Hours</div>
    </div>
    <div class="dots">:</div>
    <div class="count" style="margin-left: 4px;">
      <div class="value" id="minutes"></div>
      <div class="label">Minutes</div>
    </div>
  </div>
</div>
