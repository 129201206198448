import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DownloadImageService {
  constructor(private _http: HttpClient) {}

  downloadFile(imageUrl: string) {
    return this._http.get(imageUrl, {
      responseType: 'arraybuffer',
      reportProgress: true,
      observe: 'events'
    });
  }
}
