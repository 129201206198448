import { Actions } from './eventUI.actions';
import * as actions from './eventUI.actions';
import { EventScreens, SCREEN_STATUS } from '../../../models/constants';
import { EventUI } from 'src/models/EventUI';

const INITIAL_STATE: EventUI = {
  // selectedEvent: null,
  creationProcessEvent: null,
  currentEventScreen: EventScreens.EVENT_LIST,
  eop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE
};

export function eventUiReducer(
  state = INITIAL_STATE,
  action: Actions
): EventUI {
  switch (action.type) {
    case actions.EventUIActionTypes.UI_LIST_EVENT: {
      return {
        ...state,
        // selectedEvent: null,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_LIST,
        eop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.EventUIActionTypes.UI_CREATE_EVENT: {
      const res = {
        ...state,
        selectedEvent: null,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_CREATION
      };
      return res;
    }
    case actions.EventUIActionTypes.UI_VIEW_EVENT: {
      return {
        ...state,
        selectedEvent: action.selectedEvent,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_VIEW
      };
    }
    case actions.EventUIActionTypes.UI_EDIT_EVENT: {
      return {
        ...state,
        file: action.file,
        selectedEvent: action.selectedEvent,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_EDIT
      };
    }

    case actions.EventUIActionTypes.UI_EVENT_UPLOAD_PHOTO: {
      if (state.currentEventScreen === EventScreens.EVENT_EDIT) {
        return state;
      } else {
        return {
          ...state,
          creationProcessEvent: action.createdEvent,
          currentEventScreen: EventScreens.EVENT_PHOTO_UPLOADING
        };
      }
    }
    case actions.EventUIActionTypes.UI_EVENT_CREATION_COMPLETE: {
      return {
        ...state,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_LIST
      };
    }

    case actions.EventUIActionTypes.UI_CLOSE_VIEW_EVENT: {
      return {
        ...state,
        // selectedEvent: null,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_LIST
      };
    }
    case actions.EventUIActionTypes.UI_CLOSE_EDIT_EVENT: {
      return {
        ...state,
        creationProcessEvent: null,
        currentEventScreen: EventScreens.EVENT_VIEW
      };
    }

    case actions.EventUIActionTypes.UI_EVENT_GET_NEXT_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.EventUIActionTypes.UI_EVENT_GET_PREV_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.EventUIActionTypes.UI_EVENT_RECEIVED_HISTORY_CHUNK: {
      return {
        ...state,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.EventUIActionTypes.UI_EVENT_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.EventUIActionTypes.UI_SET_WAITTING_SPINER:
      return {
        ...state,
        currentPage: state.currentPage.map(res =>
          res.id === action.id ? { ...res, uploadImage: true } : res
        )
      };
    case actions.EventUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
