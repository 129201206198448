import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';

import { MainChat } from 'src/models/MainChat';

declare var Recorder: any;

@Component({
  selector: 'app-record-voice-note',
  templateUrl: './record-voice-note.component.html',
  styleUrls: ['./record-voice-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordVoiceNoteComponent implements OnChanges {
  @Input() selectedChat: MainChat;
  @Input() isBotScreen = false;

  @Output() recordVoice = new EventEmitter<boolean>(false);
  @Output() sendVoiceNotify = new EventEmitter<{
    file: File;
    url: string;
  }>();
  @Output() micNotFoundNotify = new EventEmitter<boolean>(false);

  // Timer voice
  public voiceRecordDurationInterval = 0;
  private voiceRecordDurationIntervalId = null;
  public recInited = false;
  private recCancelAfterInit = false;
  public voiceRecorder = null;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['selectedChat'];
    if (chng && this.recInited) {
      if (
        (chng.currentValue && !chng.previousValue) ||
        (chng.previousValue && !chng.currentValue) ||
        chng.currentValue.id !== chng.previousValue.id
      ) {
        this.cancelRecord();
      }
    }
  }

  record2() {
    this.micChecker()
      .then(() => {
        this.recordVoice.emit(true);
        if (this.voiceRecorder && this.voiceRecorder.processing) {
          return;
        }
        this.voiceRecorder = new Recorder({
          monitorGain: 0,
          duration: 0,
          numberOfChannels: 1,
          bitRate: 64000,
          encoderSampleRate: 48000,
          encoderPath: './assets/js/encoder_worker.js'
        });

        this.recInited = false;
        this.recCancelAfterInit = false;

        this.voiceRecorder.addEventListener(
          'streamReady',
          this.onRecordStreamReady.bind(this)
        );
        this.voiceRecorder.addEventListener(
          'start',
          this.onRecordStart.bind(this)
        );
        this.voiceRecorder.initStream();
        this._changeDetectorRef.detectChanges();
      })
      .catch(err => {
        this.micNotFoundNotify.emit(true);
      });
  }

  onRecordStreamReady() {
    this.recInited = true;
    this._changeDetectorRef.detectChanges();
    if (this.recCancelAfterInit) {
      this.voiceRecorderStop();
      return;
    }
    if (this.voiceRecorder) {
      this.voiceRecorder.start();
    }
  }

  voiceRecorderStop() {
    if (!this.recInited) {
      this.recCancelAfterInit = true;
      this._changeDetectorRef.detectChanges();
      return;
    }
    if (this.voiceRecorder) {
      this.voiceRecorder.stop();
      this.voiceRecorder.removeEventListener(
        'streamReady',
        this.onRecordStreamReady.bind(this)
      );
      this.voiceRecorder.removeEventListener(
        'start',
        this.onRecordStart.bind(this)
      );

      if (this.voiceRecorder.audioContext) {
        if (this.voiceRecorder.scriptProcessorNode) {
          this.voiceRecorder.scriptProcessorNode.disconnect();
        }
        this.voiceRecorder.clearStream();

        this.voiceRecorder.audioContext.close();
        this.voiceRecorder.audioContext = null;
      }
    }
  }

  cancelRecord() {
    this.recordVoice.emit(false);
    this.voiceRecorderStop();
    if (this.voiceRecorder.recording) {
      this.stopTimer();
      this.voiceRecorder.recording = false;
      this.voiceRecorder.duration = 0;
      this.recInited = false;
      this._changeDetectorRef.detectChanges();
    }
  }

  onRecordStop() {
    this.recordVoice.emit(false);
    if (this.voiceRecorder.duration > 0) {
      this.voiceRecorder.processing = true;
      this.voiceRecorder.addEventListener('dataAvailable', e => {
        const blob = this.blobConstruct([e.detail], 'audio/ogg');
        const url = URL.createObjectURL(blob);
        if (blob.size !== undefined && blob.size > 1024) {
          // voice
        }
        this.voiceRecorder.processing = false;
        this._changeDetectorRef.detectChanges();

        const file = this.blobToFile(blob);
        this.sendVoiceNotify.emit({ file, url });
      });
    }
    this.cancelRecord();
  }

  onRecordStart() {
    this.startTimer();
    this.voiceRecorder.recording = true;
    this._changeDetectorRef.detectChanges();
  }

  public blobToFile(theBlob: Blob): File {
    return new File([theBlob], `${new Date().getTime()}`, {
      type: 'audio/ogg'
    });
  }

  /* Timer section */
  startTimer() {
    this.voiceRecorder.duration = 0;
    this._changeDetectorRef.detectChanges();
    this.voiceRecordDurationIntervalId = setInterval(() => {
      this.voiceRecordDurationInterval += 1000;
      this.voiceRecorder.duration += 1000;
      this._changeDetectorRef.detectChanges();
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.voiceRecordDurationIntervalId);
    this.voiceRecordDurationInterval = 0;
  }

  blobConstruct(blobParts, mimeType) {
    let blob;
    try {
      blob = new Blob(blobParts, { type: mimeType });
    } catch (e) {
      console.log('', e);
    }
    return blob;
  }

  micChecker(): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({ audio: true });
  }
}
