import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as AuthMethods from 'src/models/IAuth';
import { AuthService } from 'src/app/core/auth.service';
import { HtttpMethodErrors } from 'src/models/constants';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
import { AppForm } from 'src/models/AppForm';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit, AppForm {
  waiting: boolean;
  form: FormGroup;
  showChangePasswordScreen = false;

  @Output() goBackNotify = new EventEmitter<boolean>(false);

  constructor(
    public authService: AuthService,
    private _validateForm: FormValidatorsService,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.waiting = false;
    this.form = this.setFormGroup();
  }

  get email() {
    return this.form.get('email');
  }

  setFormGroup() {
    return new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        noWhitespaceValidator
      ])
    });
  }

  // Request Tac
  onSubmit() {
    this.waiting = true;
    if (this.form.valid) {
      this.authService
        .getResetPasswordTac(this.email.value)
        .subscribe(response => {
          const res = <AuthMethods.HttpResult>response;

          if (res.result === 0) {
            this.authService.emailTacSuccess(this.form.value);
            this.showChangePasswordScreen = true;
          } else if (res.error) {
            switch (res.error) {
              case HtttpMethodErrors.FREE_MAIL:
                this.email.setErrors({ noFreeMail: true });
                break;
              case HtttpMethodErrors.WRONG_FORMAT:
                this.email.setErrors({ wrongFormat: true });
                break;
              case HtttpMethodErrors.MAIL_USED:
              case HtttpMethodErrors.EMAIL_DOES_NOT_EXIST:
                this.email.setErrors({ emailNotExist: true });
                break;
              case HtttpMethodErrors.MAIL_USED_2:
              case HtttpMethodErrors.MAIL_USED_3:
                this.email.setErrors({ mailUsed: true });
                break;
              default:
                this.email.setErrors({ generalError: true });
                break;
            }
          }
          this.waiting = false;
          this._ref.detectChanges();
        });
    } else {
      this.waiting = false;
      this._validateForm.validateAllFormFields(this.form);
      this._ref.detectChanges();
    }
  }

  goBackEvent() {
    this.goBackNotify.emit(true);
  }
}
