import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';

import { RegistrationComponent } from './registration.component';
import { SignupComponent } from './signup/signup.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { NgxModule } from 'src/app/ngx.module';

@NgModule({
  imports: [
    CommonModule,
    NgxModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractControlsModule,
    AbstractFramesModule
  ],
  declarations: [RegistrationComponent, SignupComponent, VerifyAccountComponent]
})
export class RegistrationModule {}
