import { Store } from '@ngrx/store';
import * as WhitelistPatternsUIActions from './whitelistPatternsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class WhitelistPatternsUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetPaging() {
        this._store.dispatch(new WhitelistPatternsUIActions.ResetWhitelistPatternsPaging());
    }
    getNextWhitelistPatternsPage() {
        this._store.dispatch(new WhitelistPatternsUIActions.GetNextWhitelistPatternsPage());
    }
    getPrevWhitelistPatternsPage() {
        this._store.dispatch(new WhitelistPatternsUIActions.GetPrevWhitelistPatternsPage());
    }
    addWhitelistPatternsToList(chat_id, users) {
        this._store.dispatch(new WhitelistPatternsUIActions.AddWhitelistPatternsToList(chat_id, users));
    }
    addWhitelistPatternsSuccess(data) {
        this._store.dispatch(new WhitelistPatternsUIActions.AddWhitelistPatternsSuccess(data));
    }
    deletePatternFromWhitelist(chat_id, users) {
        this._store.dispatch(new WhitelistPatternsUIActions.DeletePatternFromWhitelist(users, chat_id));
    }
    deletePatternCallback(users) {
        this._store.dispatch(new WhitelistPatternsUIActions.DeletePatternCallback(users));
    }
    receivedWhitelistPatternsHistoryChunk(eop, sop, currentPage, hash) {
        this._store.dispatch(new WhitelistPatternsUIActions.ReceivedWhitelistPatternsHistoryChunk(eop, sop, currentPage, hash));
    }
    setStatusAsIdle() {
        this._store.dispatch(new WhitelistPatternsUIActions.SetStatusAsIdle());
    }
    reset() {
        this._store.dispatch(new WhitelistPatternsUIActions.ResetWhitelistPatterns());
    }
}
WhitelistPatternsUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhitelistPatternsUiDispatchers_Factory() { return new WhitelistPatternsUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: WhitelistPatternsUiDispatchers, providedIn: "root" });
