export enum PrivilegesName {
  dashboard = 'listdashboard',
  listevent = 'listevent',
  editevent = 'editevent',
  listmenu = 'listpoll',
  editmenu = 'editpoll',
  listcampaign = 'listcampaign',
  editcampaign = 'editcampaign',
  listcoupon = 'listcoupon',
  editcoupon = 'editcoupon',
  listbooking = 'listbooking',
  editbooking = 'editbooking',
  listcalendar = 'listcalendar',
  editcalendar = 'editcalendar',
  listmember = 'listmember',
  editmember = 'editmember',
  listadmin = 'listadmin',
  editadmin = 'editadmin',
  listtag = 'listtag',
  edittag = 'edittag',
  listchatgroup = 'listgroup',
  editchatgroup = 'editgroup',
  listvirtualapp = 'listvirtualapp',
  editvirtualapp = 'editvirtualapp',
  listsettings = 'listsettings',
  editsettings = 'editsettings',
  listapp = 'listapp',
  editapp = 'editapp',

  //to be reviewed agian originally  listappchannel
  // listappchannel = 'listapps',
  // editappchannel = 'editapps',
  listmessage = 'listmessage',
  createmessage = 'createmessage',
  deletemessage = 'deletemessage',
  editmessage = 'editmessage',
  replymessage = 'replymessage',
  sendphoto = 'sendphoto',
  sendvideo = 'sendvideo',
  sendaudio = 'sendaudio',
  sendgif = 'sendgif',
  senddoc = 'senddoc',
  sendarticle = 'sendarticle',
  sendcoupon = 'sendcoupon',
  sendmenu = 'sendmenu',
  sendsticky = 'sendsticky',
  listbilling = 'listbilling',
  uploadTemps = 'listtemplate',
  editbilling = 'editbilling',
  listbulk = 'listbulk',
  editbulk = 'editbulk',
  listbot = 'listbot',
  editbot = 'editbot',
  listcustomercare = 'listcustomercare',
  editcustomercare = 'editcustomercare',
  listtransaction = 'listorder',
  edittransaction = 'editorder',
  listbundle = 'listbundle',
  editbundle = 'editbundle',
  listproduct = 'listproduct',
  editproduct = 'editproduct',
  listcollection = 'listcollection',
  editcollection = 'editcollection',
  listmychannel = 'listmychannel',
  editmychannel = 'editmychannel',
  listchannel = 'listchannel',
  editchannel = 'editchannel',
  listmstore = 'listmstore',
  editmstore = 'editmstore',
  listproductaddons = 'listproductaddons',
  editproductaddons = 'editproductaddons',
  listpackage = 'listpackage',
  editpackage = 'editpackage',
  listtemplate = 'listtemplate',
  edittemplate = 'edittemplate',
  // listAppChannelData = 'listappchanneldata',
  // editAppChannelData = 'editappchanneldata',
  listmarker = 'listmarker',
  editmarker = 'editmarker',
  listblacklist = 'listblacklist',
  editblacklist = 'editblacklist',
  listmapservices = 'listmapservice',
  editmapservice = 'editmapservice',
  listroutes = 'listroute',
  editroute = 'editroute',
  listtripnotifiers = 'listtripnotifier',
  edittripnotifiers = 'edittripnotifier',
  listtrips = 'listtrip',
  edittrips = 'edittrip',
  listtripshistory = 'listtripshistory',
  listwhitelist = 'listwhitelist',
  editwhitelist = 'editwhitelist',
  configbuildandroid = 'configbuildandroid',
  configsocialactivechannels = 'configsocialactivechannels',
  configsocialuserchannels = 'configsocialuserchannels',
  configsocialchannellist = 'configsocialchannellist',
  configsocialusergroups = 'configsocialusergroups',
  configsocialchatgroup = 'configsocialchatgroup',
  configsocialgrouplist = 'configsocialgrouplist',
  configsocialposts = 'configsocialposts',
  configsocialvideo = 'configsocialvideo',
  configcommunicationcalllog = 'configcommunicationcalllog',
  configcommunicationmessenger = 'configcommunicationmessenger',
  configcommunicationaudiovideocalling = 'configcommunicationaudiovideocalling',
  configcommunicationcontactlist = 'configcommunicationcontactlist',
  configcommunicationdirectchat = 'configcommunicationdirectchat',
  configessentialsuserinvitations = 'configessentialsuserinvitations',
  configessentialsqrscanner = 'configessentialsqrscanner',
  configessentialswebview = 'configessentialswebview',
  configessentialssearch = 'configessentialssearch',
  configpages = 'configpages',
  configappsettingsplash = 'configappsettingsplash',
  configappsettingbubbletheme = 'configappsettingbubbletheme',
  configappsettinginvitationallinks = 'configappsettinginvitationallinks',
  configappsettingshowappdetails = 'configappsettingshowappdetails',
  configappsettingrequestapprating = 'configappsettingrequestapprating',
  configappsettingloginsignup = 'configappsettingloginsignup',
  configappsettinguserprofile = 'configappsettinguserprofile',
  configappsettingstoreverificationaccount = 'configappsettingstoreverificationaccount',
  configappsettingenablewhitelist = 'configappsettingenablewhitelist',
  configappsettingchatbots = 'configappsettingchatbots',
  configappsettingtermspolicy = 'configappsettingtermspolicy',
  configappsettingsupportemail = 'configappsettingsupportemail',
  configsocialchatbot = 'configsocialchatbot',
  configsocialloyalty = 'configsocialloyalty',
  configsecuritydigitalid = 'configsecuritydigitalid',
  configbookingeventuserbooking = 'configbookingeventuserbooking',
  configbookingeventeventlist = 'configbookingeventeventlist',
  configbookingeventbooking = 'configbookingeventbooking',
  configbookingeventbookingvalidation = 'configbookingeventbookingvalidation',
  configbookingeventredemption = 'configbookingeventredemption',
  configmobilestorestore = 'configmobilestorestore',
  configappsettingloginsignupmobile = 'configappsettingloginsignupmobile',
  configappsettingpaymentcreditcard = 'configappsettingpaymentcreditcard',
  configappsettingpaymentpaypal = 'configappsettingpaymentpaypal',
  configmapmapssearch = 'configmapmapssearch',
  configappsettinggoogleads = 'configappsettinggoogleads',
  configappsettingpaymentinapppurchase = 'configappsettingpaymentinapppurchase',
  configbuildios = 'configbuildios'
}

export enum PrivilegesModules {
  dashboard = 'dashboard',
  post = 'post',
  poll = 'poll',
  channel = 'channel',
  chatgroup = 'chatgroup',
  booking = 'booking',
  event = 'event',
  calendar = 'calendar',
  mstore = 'mstore',
  product = 'product',
  bundle = 'bundle',
  plans = 'plans',
  addons = 'addons',
  order = 'order',
  campaign = 'campaign',
  coupon = 'coupon',
  maptracking = 'maptracking',
  mapsearch = 'mapsearch',
  usermanager = 'usermanager',
  manualorder = 'manualorder',
  bulkupload = 'bulkupload',
  api = 'api',
  mychannel = 'mychannel',
  vapp = 'vapp'
}
