<div
  style="display: flex; align-items: center; margin: 0 5px;"
  *ngIf="!secStyle"
>
  <svg
    *ngIf="!inside"
    (mouseover)="toggleTooltipVisibility(true)"
    (mouseleave)="toggleTooltipVisibility(false)"
    class="svg-icon icon-icon clickable-cursor"
  >
    <use
      [attr.xlink:href]="'./assets/svg/app-sections.svg#icon-info-tooltip'"
    ></use>
  </svg>
  <img
    (mouseover)="toggleTooltipVisibility(true)"
    (mouseleave)="toggleTooltipVisibility(false)"
    *ngIf="inside"
    src="./assets/img/help-info.svg"
  />
  <div *ngIf="showTooltip" class="tooltip-text">
    <div class="tooltip-title">{{ title }}</div>
    <div class="tooltip-description">
      {{ description }}
    </div>
  </div>
</div>

<div
  style="display: flex; align-items: center; margin: 0 5px;"
  *ngIf="secStyle"
  (mouseover)="toggleTooltipVisibility(true)"
  (mouseleave)="toggleTooltipVisibility(false)"
>
  <svg class="svg-icon icon-icon clickable-cursor">
    <use
      [attr.xlink:href]="'./assets/svg/app-sections.svg#icon-info-tooltip'"
    ></use>
  </svg>

  <div
    *ngIf="showTooltip"
    class="tooltip-text"
    (mouseover)="toggleTooltipVisibility(true)"
    (mouseleave)="toggleTooltipVisibility(false)"
  >
    <div class="tooltip-title">{{ title }}</div>
    <div class="tooltip-description">
      Click <a [href]="link" target="_blank">Here</a> For More Information
    </div>
  </div>
</div>
