export const environment = {
  // same
  CHANNELAPISUFFIX: '/nandbox/business/',
  WSURLAPISUFFIX: '/nandbox/api/',
  DOWNLOADURLAPISUFFIX: '/nandbox/download/',
  UPLOADURLAPISUFFIX: '/nandbox/upload/',
  METAURLAPISUFFIX: '/nandbox/metadata/?url=',
  CANCELPAYSUFFIX: '/cancel/',
  // diff
  env_name: 'dev',
  sentry_dns: 'https://2037eff118ae48c8b99c3a68e31f87d1@sentry.io/1858550',
  production: false,
  serviceworker: false,
  encodeSocket: false,
  sentry: false,
  hotjar: '',
  inspectlet: '',
  formilla: '',
  signup_link: 'https://nandbox.com/getstarted/',
  terms_link: 'https://nandbox.com/terms',
  privacy_link: 'https://nandbox.com/terms',
  MAP_API_KEY: 'AIzaSyDYC0eyYdnbU9rgIKqT35Bb237WBhPkDZY',
  MAINWEB: 'https://nandbox.com',
  SHARE_LINK: 'https://s1.ourpage.me/',
  PAGE_LINK: 'https://page1.ourpage.me',
  PAY_LINK: 'https://pay1.ourpage.me',
  YOUTUBE_API_CHANNEL_LINK: 'https://go1.ourpage.me/ytc',
  YOUTUBE_API_PLAYLIST_LINK: 'https://go1.ourpage.me/ytp',
  MAP_AUTOCOMPLETE_API_LINK: 'https://go1.ourpage.me/places',
  MAP_PLACE_API_LINK: 'https://go1.ourpage.me/place',
  SMTP_API_LINK: 'https://go1.ourpage.me/validate-smtp',
  AVR_COLOR_LINK: 'https://go1.ourpage.me/average-color',
  COUNTRY_DATA_API_LINK: 'https://go.ndbx.me/country-data',
  PLANS_DATA_API_LINK: 'https://go1.ourpage.me/plans',
  googleAnalyticsKey: 'UA-144808415-2',
  clarityKey: 'e2eu30z9go',
  stripeKey: 'pk_test_lbpZsa8KnyGfxQ7ekKX8UCCc00HnDbMVoz',
  // googleAnalyticsKeySecondKey: 'G-ZSY0XB2W88',
  BASEURL: 'd1.nandbox.net:5080',
  PROJECT_NAME: 'MERCHANT',
  WEB_VERSION: '9.7D',
  DEFAULT_TEMP: '68253010',
  LICENSES: [
    'listdashboard',
    'listevent',
    'editevent',
    'listpoll',
    'editpoll',
    'listcampaign',
    'editcampaign',
    'listcoupon',
    'editcoupon',
    'listbooking',
    'editbooking',
    'listcalendar',
    'editcalendar',
    'listmember',
    'editmember',
    'listadmin',
    'editadmin',
    'listtag',
    'edittag',
    'listgroup',
    'editgroup',
    'listvirtualapp',
    'editvirtualapp',
    'listsettings',
    'editsettings',
    'listapp',
    'editapp',
    'listmessage',
    'createmessage',
    'deletemessage',
    'editmessage',
    'replymessage',
    'sendphoto',
    'sendvideo',
    'sendaudio',
    'sendgif',
    'senddoc',
    'sendarticle',
    'sendcoupon',
    'sendmenu',
    'sendsticky',
    'listbilling',
    'listtemplate',
    'editbilling',
    'listbulk',
    'editbulk',
    'listbot',
    'editbot',
    'listcustomercare',
    'editcustomercare',
    'listorder',
    'editorder',
    'listbundle',
    'editbundle',
    'listproduct',
    'editproduct',
    'listcollection',
    'editcollection',
    'listmychannel',
    'editmychannel',
    'listchannel',
    'editchannel',
    'listmstore',
    'editmstore',
    'listproductaddons',
    'editproductaddons',
    'listpackage',
    'editpackage',
    'listtemplate',
    'edittemplate',
    'listmarker',
    'editmarker',
    'listblacklist',
    'editblacklist',
    'listmapservice',
    'editmapservice',
    'listroute',
    'editroute',
    'listtripnotifier',
    'edittripnotifier',
    'listtrip',
    'edittrip',
    'listtripshistory',
    'listwhitelist',
    'editwhitelist',
    'configbuildandroid',
    'configsocialactivechannels',
    'configsocialuserchannels',
    'configsocialchannellist',
    'configsocialusergroups',
    'configsocialchatgroup',
    'configsocialgrouplist',
    'configsocialposts',
    'configsocialvideo',
    'configcommunicationcalllog',
    'configcommunicationmessenger',
    'configcommunicationaudiovideocalling',
    'configcommunicationcontactlist',
    'configcommunicationdirectchat',
    'configessentialsuserinvitations',
    'configessentialsqrscanner',
    'configessentialswebview',
    'configessentialssearch',
    'configpages',
    'configappsettingsplash',
    'configappsettingbubbletheme',
    'configappsettinginvitationallinks',
    'configappsettingshowappdetails',
    'configappsettingrequestapprating',
    'configappsettingloginsignup',
    'configappsettinguserprofile',
    'configappsettingstoreverificationaccount',
    'configappsettingenablewhitelist',
    'configappsettingchatbots',
    'configappsettingtermspolicy',
    'configappsettingsupportemail',
    'configsocialchatbot',
    'configsocialloyalty',
    'configsecuritydigitalid',
    'configbookingeventuserbooking',
    'configbookingeventeventlist',
    'configbookingeventbooking',
    'configbookingeventbookingvalidation',
    'configbookingeventredemption',
    'configmobilestorestore',
    'configappsettingloginsignupmobile',
    'configappsettingpaymentcreditcard',
    'configappsettingpaymentpaypal',
    'configmapmapssearch',
    'configappsettinggoogleads',
    'configappsettingpaymentinapppurchase',
    'configbuildios'
  ]
};
