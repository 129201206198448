import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { PackagesUIActionTypes } from './packagesUI.actions';
import * as PackagesUiActions from './packagesUI.actions';
import {
  CreatePackageMethod,
  Package,
  SetPackageMethod,
  GetPackagesMethod,
  RemovePackageBundlesMethod,
  AddPackageBundlesMethod,
  RemovePackageMethod,
  GetPackagesAfterDeleteMethod
} from 'src/models/Package';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import { PackagesUiDispatchers } from './packagesUI.dispatchers';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class PackagesUiEffects {
  @Effect({ dispatch: false })
  createPackage = this.actions$.pipe(
    ofType(PackagesUIActionTypes.CREATE_PACKAGE),
    map((action: PackagesUiActions.CreatePackage) => {
      this._socketGateway.sendSocketMessage(
        new CreatePackageMethod(action.packageDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  updatePackage = this.actions$.pipe(
    ofType(PackagesUIActionTypes.SET_PACKAGE),
    map((action: PackagesUiActions.SetPackage) => {
      const packageDetails: Package = {
        ...action.packageDetails,
        id: action.packageID
      };
      this._socketGateway.sendSocketMessage(
        new SetPackageMethod(packageDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  addPackageBundles = this.actions$.pipe(
    ofType(PackagesUIActionTypes.ADD_PACKAGE_ITEMS),
    map((action: PackagesUiActions.AddPackageItems) => {
      this._socketGateway.sendSocketMessage(
        new AddPackageBundlesMethod(action.packageID, action.itemsIDs)
      );
    })
  );

  @Effect({ dispatch: false })
  removePackageBundles = this.actions$.pipe(
    ofType(PackagesUIActionTypes.REMOVE_PACKAGE_BUNDLES),
    map((action: PackagesUiActions.RemovePackageBundles) => {
      this._socketGateway.sendSocketMessage(
        new RemovePackageBundlesMethod(action.packageID, action.bundlesIDs)
      );
    })
  );

  //
  @Effect({ dispatch: false })
  getNextPackagesPage = this.actions$.pipe(
    ofType(PackagesUIActionTypes.UI_PACKAGES_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.packagesUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, packagesUiState]) => {
      const action = <PackagesUiActions.GetNextPackagesPage>val;
      const prevRequest = packagesUiState.previousRequest;
      if (
        !(
          packagesUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          packagesUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetPackagesMethod(0, packagesUiState.eop)
        );
      } else {
        this._uiPackagesDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevPackagesPage = this.actions$.pipe(
    ofType(PackagesUIActionTypes.UI_PACKAGES_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.packagesUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, packagesUiState]) => {
      const action = <PackagesUiActions.GetPrevPackagesPage>val;
      const prevRequest = packagesUiState.previousRequest;
      if (
        !(
          packagesUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          packagesUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetPackagesMethod(1, packagesUiState.sop)
        );
      } else {
        this._uiPackagesDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterPackagesResetPaging = this.actions$.pipe(
    ofType(PackagesUIActionTypes.UI_PACKAGES_RESET_PAGING),
    map((action: PackagesUiActions.ResetPackagesPaging) => {
      this._uiPackagesDispatchers.getNextPackagesPage();
    })
  );

  @Effect({ dispatch: false })
  deletePackage = this.actions$.pipe(
    ofType(PackagesUIActionTypes.DELETE_PACKAGE),
    map((action: PackagesUiActions.DeletePackage) => {
      this._socketGateway.sendSocketMessage(
        new RemovePackageMethod(action.packageID)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiPackagesDispatchers: PackagesUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
