import { Store } from '@ngrx/store';
import * as actions from './appLocalInfo.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppLocalInfoDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateAppLocalInfo(localAppInfo) {
        this._store.dispatch(new actions.UpdateAppLocalInfo(localAppInfo));
    }
}
AppLocalInfoDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppLocalInfoDispatchers_Factory() { return new AppLocalInfoDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppLocalInfoDispatchers, providedIn: "root" });
