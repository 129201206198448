<ng-container *ngIf="!showChangePasswordScreen">
  <div class="forgot-password">
    <div class="forgot-password-header">
      Forgot Your Password?
    </div>
    <div class="forgot-password-title">
      Enter your email address & we'll send you a code to reset your password.
    </div>

    <form
      form-body
      class="forgot-password-form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="faild">
        <!-- <mat-form-field> -->
        <label for="Email">Email</label>
        <input
          placeholder="Email"
          type="email"
          formControlName="email"
          autocomplete="off"
          id="Email"
        />
        <!-- </mat-form-field> -->
        <div style="min-height: 25px;">
          <app-validation-errors
            class="forgot-password-error"
            *ngIf="email?.errors && email?.touched"
            [fieldName]="'Email'"
            [fieldErrors]="email?.errors"
          >
          </app-validation-errors>
        </div>
      </div>

      <!-- <div class="form-submit">
        <app-border-btn next invert lg [type]="'submit'">
          Send <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
        <app-border-btn next lg (btnClicked)="goBackEvent()">
          Back
        </app-border-btn>
      </div> -->
      <button type="submit" class="submit-btn">Send Reset Link</button>
      <app-link-btn
        [isDefaultLink]="true"
        (btnClicked)="goBackEvent()"
        style="font-size: 16px;"
      >
        <ng-container ngProjectAs="link-content-clicked">
          Back to sign in
        </ng-container>
      </app-link-btn>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="showChangePasswordScreen">
  <app-change-password [accountEmail]="email.value"> </app-change-password>
</ng-container>
