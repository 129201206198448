/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./delete-frame-icon.component";
var styles_DeleteFrameIconComponent = ["[_nghost-%COMP%] {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }"];
var RenderType_DeleteFrameIconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DeleteFrameIconComponent, data: {} });
export { RenderType_DeleteFrameIconComponent as RenderType_DeleteFrameIconComponent };
export function View_DeleteFrameIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-frame"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-delete-frame"]], null, null, null, null, null))], null, null); }
export function View_DeleteFrameIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-frame-icon", [], null, null, null, View_DeleteFrameIconComponent_0, RenderType_DeleteFrameIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.DeleteFrameIconComponent, [], null, null)], null, null); }
var DeleteFrameIconComponentNgFactory = i0.ɵccf("app-delete-frame-icon", i1.DeleteFrameIconComponent, View_DeleteFrameIconComponent_Host_0, { fillColor: "fillColor" }, {}, []);
export { DeleteFrameIconComponentNgFactory as DeleteFrameIconComponentNgFactory };
