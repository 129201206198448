import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MenuUIDispatchers } from './menuUI.dispatchers';
import * as MenuMethods from 'src/models/IBotMenu';
import * as isEqual from 'lodash.isequal';
import { GetMenus } from 'src/models/IBotMenu';
import { PageDirection, FAILED_TO_UPLOAD_IMAGE, LOGO_WIDTH, LOGO_HEIGHT, ERROR_IMAGE_SHOULD_BE_1024X1024, SPLASH_PAGE_UPLOAD_TYPE, NO_FILE_NAME, ERROR_NOT_PNG_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes } from 'src/models/constants';
import { HttpEventType } from '@angular/common/http';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
export class MenuUIEffects {
    constructor(actions$, _store, _menuUIDispatchers, _uiDispatchers, _fileService, _uploadGateway, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._menuUIDispatchers = _menuUIDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._socketGateway = _socketGateway;
        this.listCoupons = this.actions$.pipe(ofType("UI_MENU_LIST_MENUS" /* UI_MENU_LIST_MENUS */), map((action) => {
            this._socketGateway.sendSocketMessage(new MenuMethods.ListMyMenu(action.payload));
        }));
        this.uploadMenuImg = this.actions$.pipe(ofType("UPLOAD_MENU_IMG" /* UPLOAD_MENU_IMG */), map((action) => {
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width !== props.height ||
                                props.width < LOGO_WIDTH ||
                                props.height < LOGO_HEIGHT) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, SPLASH_PAGE_UPLOAD_TYPE, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const res = event.body;
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageUrl = res.files[4].media.permanentUrl;
                                        }
                                        const imageSet = {};
                                        imageSet.hdpi = res.files[0].media.permanentUrl;
                                        imageSet.mdpi = res.files[1].media.permanentUrl;
                                        imageSet.xhdpi = res.files[2].media.permanentUrl;
                                        imageSet.xxhdpi = res.files[3].media.permanentUrl;
                                        imageSet.xxxhdpi = res.files[4].media.permanentUrl;
                                        imageSet.ios1x = res.files[5].media.permanentUrl;
                                        imageSet.ios2x = res.files[6].media.permanentUrl;
                                        imageSet.ios3x = res.files[7].media.permanentUrl;
                                        this._menuUIDispatchers.UploadMenuImgSuccess(action.componentRef, imageUrl, imageSet);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                });
                            }
                        });
                    });
                });
            }
        }));
        this.menusReceiving = this.actions$.pipe(ofType("UI_MENU_MENUS_RECEIVING" /* UI_MENU_MENUS_RECEIVING */), map((action) => {
            const pyln = action.payload;
            if (pyln.mymenus) {
                for (const menu of pyln.mymenus) {
                    if (menu.sub_menu) {
                        const localMenu = menu.sub_menu;
                        localMenu.id = menu.id;
                        this._menuUIDispatchers.menuReceived(localMenu);
                    }
                }
            }
        }));
        this.menuReceving = this.actions$.pipe(ofType("UI_MENU_MENU_RECEIVING" /* UI_MENU_MENU_RECEIVING */), map((action) => {
            const localMenu = action.payload.mymenu.menu;
            localMenu.id = action.payload.mymenu.id;
            this._menuUIDispatchers.menuReceived(localMenu);
        }));
        this.sendingMenu = this.actions$.pipe(ofType("UI_MENU_CREATE_MENU" /* UI_MENU_CREATE_MENU */, "UI_MENU_UPDATE_MENU" /* UI_MENU_UPDATE_MENU */), map((action) => {
            const { id } = action.payload;
            if (id) {
                this._socketGateway.sendSocketMessage(new MenuMethods.SetMyMenu(action.payload, action.payload, action.payload.menu_name, id, action.payload.menu_ref, action.payload.next_menu));
            }
            else {
                this._socketGateway.sendSocketMessage(new MenuMethods.CreateMenu(action.payload, action.payload, action.payload.menu_name, action.payload.menu_ref, action.payload.next_menu, action.payload.type));
            }
        }));
        this.getNextMenuPage = this.actions$.pipe(ofType("UI_MENU_GET_NEXT_PAGE" /* UI_MENU_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, menuUiState]) => {
            const action = val;
            const prevRequest = menuUiState.previousRequest;
            if (!(menuUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                menuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMenus(0, menuUiState.hash, menuUiState.eop));
            }
            else {
                this._menuUIDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMenuPage = this.actions$.pipe(ofType("UI_MENU_GET_PREV_PAGE" /* UI_MENU_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.menuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, menuUiState]) => {
            const action = val;
            const prevRequest = menuUiState.previousRequest;
            if (!(menuUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                menuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMenus(1, menuUiState.hash, menuUiState.sop));
            }
            else {
                this._menuUIDispatchers.setStatusAsIdle();
            }
        }));
        this.afterMenuResetPaging = this.actions$.pipe(ofType("UI_MENU_RESET_PAGING" /* UI_MENU_RESET_PAGING */), map((action) => {
            this._menuUIDispatchers.getNextMenuPage();
        }));
    }
    validateImage(selectedFile) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (selectedFile.localFile.type.indexOf('image/png') === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "listCoupons", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "uploadMenuImg", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "menusReceiving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "menuReceving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "sendingMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "getNextMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "getPrevMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MenuUIEffects.prototype, "afterMenuResetPaging", void 0);
