import {
  LocalAppConfig,
  LocalContainer,
  LocalAppClass
} from '../../../models/ChannelAppLocalConfig';
import { Action } from '@ngrx/store';
import { TabTypes } from 'src/models/constants';
import { TabTagItem } from 'src/models/ChannelAppConfig';

export const enum ContainerActionTypes {
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  UPDATE_CONTAINER = 'UPDATE_CONTAINER',
  REORDER_CONTAINER = 'REORDER_CONTAINER',
  DELETE_CONTAINER = 'DELETE_CONTAINER',
  DELETE_CONTAINER_REQUEST = 'DELETE_CONTAINER_REQUEST',
  ADD_CONTAINER_REQUEST = 'ADD_CONTAINER_REQUEST',
  ADD_CONTAINER = 'ADD_CONTAINER',
  SELECT_CONTAINER = 'SELECT_CONTAINER',
  SELECT_FIRST_CONTAINER = 'SELECT_FIRST_CONTAINER',
  SELECT_LAST_CONTAINER = 'SELECT_LAST_CONTAINER',
  UPDATE_TAB_TYPE = 'UPDATE_TAB_TYPE',
  UPDATE_TAB_TYPE_REQUEST = 'UPDATE_TAB_TYPE_REQUEST',
  RECEIVE_TAG_VALUES = 'RECEIVE_TAG_VALUES',
  UPDATE_MAP_CONTAINER_ON_PUBLISH = 'UPDATE_MAP_CONTAINER_ON_PUBLISH',
  ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST',
  RESET = 'RESET'
}

export class AppConfigDataChanged implements Action {
  readonly type = ContainerActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class UpdateContainer implements Action {
  readonly type = ContainerActionTypes.UPDATE_CONTAINER;
  constructor(
    public containerRef: string,
    public containerUpdatedProps: LocalContainer
  ) {}
}

export class ReorderContainer implements Action {
  readonly type = ContainerActionTypes.REORDER_CONTAINER;
  constructor(public containerRef: string, public newPosition: number) {}
}

export class DeleteContainerRequest implements Action {
  readonly type = ContainerActionTypes.DELETE_CONTAINER_REQUEST;
  constructor(public container: LocalContainer) {}
}

export class DeleteContainer implements Action {
  readonly type = ContainerActionTypes.DELETE_CONTAINER;
  constructor(public container: LocalContainer) {}
}

export class AddContainerRequest implements Action {
  readonly type = ContainerActionTypes.ADD_CONTAINER_REQUEST;
  constructor(
    public appClass: LocalAppClass,
    public specialContainerValues?: LocalContainer
  ) {}
}

export class AddContainer implements Action {
  readonly type = ContainerActionTypes.ADD_CONTAINER;
  constructor(public container: LocalContainer) {}
}

export class SelectContainer implements Action {
  readonly type = ContainerActionTypes.SELECT_CONTAINER;
  constructor(public selectedContainer: LocalContainer) {}
}

export class SelectFirstContainer implements Action {
  readonly type = ContainerActionTypes.SELECT_FIRST_CONTAINER;
  constructor(public appClassName: string) {}
}

export class SelectLastContainer implements Action {
  readonly type = ContainerActionTypes.SELECT_LAST_CONTAINER;
  constructor(public appClassName: string) {}
}

export class UpdateTabType implements Action {
  readonly type = ContainerActionTypes.UPDATE_TAB_TYPE;
  constructor(
    public containerRef: string,
    public tabType: TabTypes,
    public tabID: string
  ) {}
}

export class UpdateTabTypeRequest implements Action {
  readonly type = ContainerActionTypes.UPDATE_TAB_TYPE_REQUEST;
  constructor(
    public containerRef: string,
    public tabType: TabTypes,
    public pageId: string
  ) {}
}

export class ReceiveTagValues implements Action {
  readonly type = ContainerActionTypes.RECEIVE_TAG_VALUES;
  constructor(
    public tabsTags: TabTagItem[],
    public isChannelConfigOnly: boolean
  ) {}
}

export class UpdateMapContainerOnPublish implements Action {
  readonly type = ContainerActionTypes.UPDATE_MAP_CONTAINER_ON_PUBLISH;
  constructor(public isChannelOnly: boolean) {}
}

export class AddSectionRequest implements Action {
  readonly type = ContainerActionTypes.ADD_SECTION_REQUEST;
  constructor(
    public parentTabRef: string,
    public specialContainerValues?: LocalContainer
  ) {}
}

export class Reset implements Action {
  readonly type = ContainerActionTypes.RESET;
}

export type Actions =
  | AppConfigDataChanged
  | UpdateContainer
  | ReorderContainer
  | DeleteContainerRequest
  | DeleteContainer
  | AddContainerRequest
  | AddContainer
  | SelectContainer
  | SelectFirstContainer
  | UpdateTabType
  | UpdateTabTypeRequest
  | ReceiveTagValues
  | UpdateMapContainerOnPublish
  | Reset;
