import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { BotMenu, BotMenuButton } from 'src/models/IBotMenu';
import {
  MENU_BOTTON_STYLE,
  MENU_CELLS_TYPE,
  MENU_CELLS_CATEGORIES,
  TabTypes
} from 'src/models/constants';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';

import { FeaturesSublistComponent } from '../../app-mgmt/custom/dragable-features/features-sublist/features-sublist.component';
import { BotsService } from '../../bots/bots.service';
import { AppMgmtService } from '../../app-mgmt/appMgmt.service';
import { OnDestroy } from '@angular/core';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from 'src/app/core/ui.service';
import { MobileWorkFlowStoreService } from '../../mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-menu-holder',
  templateUrl: './menu-holder.component.html',
  styleUrls: ['./menu-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuHolderComponent implements OnChanges, OnInit, OnDestroy {
  @Input() menus: BotMenu;
  @Input() messageDetails: any;
  @Input() config = false;
  @Input() showRemove = false;
  @Input() bot = false;
  @Input() hideTextOnlySec = false;
  @Input() tabsList: LocalContainer[];
  @Input() systemConfg: any;
  @Input() mainMenu = false;
  menuCat = MENU_CELLS_CATEGORIES;
  rowStyles = MENU_BOTTON_STYLE;
  @Output() createButtonNotifiy = new EventEmitter<number>();
  @Output() readButtonNotifiy = new EventEmitter<{
    row: number;
    col: number;
  }>();
  @Output() deleteButtonNotifiy = new EventEmitter<{
    row: number;
    col: number;
  }>();
  @Output() deleteRowNotifiy = new EventEmitter<number>();
  @Output() dropEmitter = new EventEmitter<any>();
  @Input() mobView = false;
  @Input() hideSittings = false;
  @Input() ios = false;
  feature: any;
  selectedFeature: any;
  @Input() ui_module_id: any;
  @Input() menu_ref: any;
  @Output() showSettingsNotify = new EventEmitter<LocalContainer>();
  @Input() showSettings: boolean;
  @Input() appMenus: any[];
  @Output() subMenuSelectNotify = new EventEmitter<any>();
  @Output() buttonClickedNotify = new EventEmitter<any>();
  @Output() dropButtondNotify = new EventEmitter<any>();
  tabTypes = TabTypes;
  menusSubscribe = null;
  getFeature = new FeaturesSublistComponent(
    this._ref,
    this.appManagementService,
    this.botService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );
  constructor(
    private _ref: ChangeDetectorRef,
    public appManagementService: AppMgmtService,
    public botService: BotsService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {}
  ngOnDestroy() {
    if (this.menusSubscribe) {
      this.menusSubscribe.unsubscribe();
    }
  }
  ngOnInit() {}
  ngOnChanges() {
    // if (this.mobView) {
    //   setTimeout(() => {
    //     this._ref.detectChanges();
    //   }, 500);
    // }
  }

  getButtonStyle(button: BotMenuButton) {
    if (button.button_bgcolor !== null) {
      return {
        'background-color': button.button_bgcolor
          ? button.button_bgcolor
          : '#e7ebf8',
        color: button.button_textcolor ? button.button_textcolor : '#000000'
      };
    } else {
      return {
        'background-color': 'unset',
        color: button.button_textcolor ? button.button_textcolor : '#000000'
      };
    }
  }

  getButtonOptionValue(button, optId) {
    let value = '';
    if (
      button.button_value &&
      button.button_value !== null &&
      button.button_value.length > 0
    ) {
      button.button_value.find(res => {
        if (res.id === optId) {
          value = res.value;
        }
      });
      return value;
    }
  }

  createButton(pos: number) {
    this.createButtonNotifiy.emit(pos);
  }

  readButton(pos: { row: number; col: number }, menuCol) {
    this.buttonClicked(menuCol);
    if (this.getTabContainer(menuCol)) {
      if (
        this.getTabContainer(menuCol).type === this.tabTypes.PAGE ||
        this.getTabContainer(menuCol).link === this.tabTypes.PAGE
      ) {
        this.appManagementService._selectedMenuButtonTab.next(
          this.getTabContainer(menuCol)
        );
      }
    }
    // this.appManagementService._showMenuBtn.next(true);
    this.readButtonNotifiy.emit(pos);
  }

  deleteButton(pos: { row: number; col: number }) {
    this.deleteButtonNotifiy.emit(pos);
  }

  deleteRow(pos: number) {
    this.deleteRowNotifiy.emit(pos);
  }

  getFeatureModule(value) {
    return (this.feature = this.getFeature.getModuleData(value));
  }
  onDrop(event, buttonId) {
    let data = event.dataTransfer.getData('text');
    data = data + ',' + buttonId;

    this.dropEmitter.emit(data);
  }
  getTabContainer(menuCol) {
    if (this.tabsList) {
      const container: LocalContainer = this.tabsList.find(
        res => res.id === menuCol.button_id
      );

      return container;
    }
    return {};
  }
  get selectItem() {
    return './assets/svg/app-features-icons.svg#' + this.feature.icon;
  }
  get selectItem2() {
    if (this.selectedFeature && this.selectedFeature.icon) {
      return './assets/svg/app-features-icons.svg#' + this.selectedFeature.icon;
    } else {
      return '';
    }
  }
  showSetting(container) {
    this.showSettings = true;
    this.selectedFeature = this.getFeature.getModuleData(
      container.ui_module_id
    );

    // this.appManagementService.selectTab(container);
    this.showSettingsNotify.emit(container);
  }

  deleteContainer(container) {
    const newContainer: LocalContainer = {};
    newContainer.type = '';
    newContainer.ui_module_id = null;
    this.appManagementService.updateContainer(container.ref, newContainer);
  }

  getBck(value) {
    return {
      'background-image': 'url(' + value.button_img_url + ')',
      'background-size': 'cover'
    };
  }

  subMenuSelect(id) {
    this.subMenuSelectNotify.emit(id);
  }

  buttonClicked(menuCol) {
    if (this.bot && this.messageDetails && menuCol.button_callback) {
      const buttonData: any = {};
      buttonData.message_id = this.messageDetails.message_id;
      buttonData.menu_ref = this.menu_ref;
      buttonData.button_label = menuCol.button_label;
      buttonData.button_callback = menuCol.button_callback;
      buttonData.reference = this.messageDetails.reference;
      this.buttonClickedNotify.emit(buttonData);
    }
  }

  getSubMenuTitle(id) {
    let subMenuTitle = '';

    this.menusSubscribe = this.appManagementService.menusAppClass$.subscribe(
      res => {
        if (res && res.menus) {
          res.menus.forEach(r => {
            if (r.menu_id === id) {
              subMenuTitle = r.menu_name;
            }
          });
        }
      }
    );
    return subMenuTitle;
  }

  addButtonStyle(event, row, col, id) {
    let data = event.dataTransfer.getData('text');

    data = data + ',' + row + ',' + col + ',' + id;

    this.dropButtondNotify.emit(data);
  }

  getMenu(menuId) {
    if (this.appMenus) {
      const menu = this.appMenus.filter(res => res.menu_id === menuId);
      return menu;
    }
    return [{}];
  }
  checkCardStyle(btn) {
    let check = false;
    const currentStyle = btn.button_style.split('');

    if (currentStyle[currentStyle.length - 1] === '1') {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  checkDividerStyle(btn) {
    let check = false;
    const currentStyle = btn.button_style.split('');

    if (currentStyle[currentStyle.length - 2] === '1') {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  checkReverseStyle(btn) {
    let check = false;
    const currentStyle = btn.button_style.split('');

    if (
      currentStyle[currentStyle.length - 3] === '1' &&
      !this.singleToggleCheck(btn.button_style) &&
      !this.singleCheck(btn.button_style)
    ) {
      check = true;
    } else {
      check = false;
    }

    return check;
  }
  checkReverseStyleSingle(btn) {
    let check = false;
    const currentStyle = btn.button_style.split('');

    if (
      currentStyle[currentStyle.length - 3] === '1' &&
      (this.singleToggleCheck(btn.button_style) ||
        this.singleCheck(btn.button_style))
    ) {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  getButtonOptionImage(btnd, optId) {
    let image = './assets/img/empty.jpg';
    btnd.button_option.find(res => {
      if (res.id === optId && res.image && res.image !== null) {
        image = res.image;
      }
    });

    return image;
  }
  imgCheck(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.SINGLECHOICEIMG);
    const icon = Number(this.rowStyles.SINGLECHOICEICON);
    const checkImg = img <= btnStyle;
    const checkImg2 = btnStyle < icon;
    let check = false;
    if (checkImg && checkImg2) {
      check = true;
    }
    return check;
  }

  iconCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.SINGLECHOICE);
    const icon = Number(this.rowStyles.SINGLECHOICEICON);
    const checkIcon = icon <= btnStyle;
    const checkIcon2 = btnStyle < single;

    let check = false;
    if (checkIcon && checkIcon2) {
      check = true;
    }
    return check;
  }

  singleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.SINGLECHOICE);
    const checkSingle = single <= btnStyle;

    let check = false;
    if (checkSingle) {
      check = true;
    }
    return check;
  }

  imgToggleCheck(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.TOGGLECHOICEIMG);
    const icon = Number(this.rowStyles.TOGGLECHOICEICON);
    const checkImg = img <= btnStyle;
    const checkImg2 = btnStyle < icon;
    let check = false;
    if (checkImg && checkImg2) {
      check = true;
    }
    return check;
  }

  iconToggleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.TOGGLECHOICE);
    const icon = Number(this.rowStyles.TOGGLECHOICEICON);
    const checkIcon = icon <= btnStyle;
    const checkIcon2 = btnStyle < single;

    let check = false;
    if (checkIcon && checkIcon2) {
      check = true;
    }
    return check;
  }

  singleToggleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.TOGGLECHOICE);
    const checkSingle = single <= btnStyle;
    let check = false;
    if (checkSingle) {
      check = true;
    }
    return check;
  }

  checkForToggle(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.TOGGLECHOICEIMG);
    const checkImg = img <= btnStyle;
    let check = false;
    if (checkImg) {
      check = true;
    }
    return check;
  }

  generatToggalId(id) {
    id = id.replace(/ /g, '');
    const newId = 'slider' + id;
    return newId;
  }
  toggleActionColor(color, value, id) {
    let actionColor = '#24aef5';
    let asignedColor = '#abb6d0';
    id = id.replace(/ /g, '');
    const styleEl = document.createElement('style');
    let styleSheet;

    // Append style element to head
    document.head.appendChild(styleEl);

    // Grab style sheet
    styleSheet = styleEl.sheet;
    if (color && color !== null && color !== '' && value === 'true') {
      asignedColor = color;
      actionColor = color;
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          actionColor +
          '!important;}'
      );
    } else if (value === 'true') {
      asignedColor = this.systemConfg.color.primary;
      actionColor = this.systemConfg.color.primary;
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          this.systemConfg.color.primary +
          '!important;}'
      );
    } else if (value === 'false') {
      actionColor = '#24aef5';
      asignedColor = '#abb6d0';
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          asignedColor +
          '!important;}'
      );
    }
    return asignedColor;
  }

  getBtnBg(bg) {
    if (bg !== null) {
      return bg;
    } else {
      return 'unset';
    }
  }
}
