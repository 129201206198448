<div class="calendar-preview">
  <div class="calendar-preview-container">
    <div class="calendar-preview-symbol">
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-calendar"></use>
      </svg>
    </div>
    <div class="calendar-preview-core wrap-text">
      <div class="calendar-preview-header">
        <img
          *ngIf="calendarAvtar"
          [src]="media"
          alt=""
          class="calendar-preview-avtar"
        />
        <div class="calendar-preview-name">
          {{ calendarName }}
        </div>
      </div>
      <div class="calendar-preview-footer">
        {{ calendarDescription }}
      </div>
    </div>
  </div>

  <div *ngIf="calendarAccept" class="calendar-preview-accept">
    <div class="calendar-preview-btn">
      ACCEPT
    </div>
  </div>
</div>
