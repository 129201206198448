import { Store } from '@ngrx/store';
import * as ChatThemes from './chatThemes.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ChatThemesDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    setChatTheme(style) {
        this._store.dispatch(new ChatThemes.SetChatTheme(style));
    }
    getChatThemeFromServer() {
        this._store.dispatch(new ChatThemes.GetChatThemeFromServer());
    }
    receveChatThemeFromServer(style) {
        this._store.dispatch(new ChatThemes.ReceveChatThemeFromServer(style));
    }
}
ChatThemesDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatThemesDispatchers_Factory() { return new ChatThemesDispatchers(i0.ɵɵinject(i1.Store)); }, token: ChatThemesDispatchers, providedIn: "root" });
