import { SubMenuStore } from './SubMenu';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  GET_STORE_MENU_METHOD,
  GET_STORE_MENUS_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_STORE_MENU_METHOD,
  CREATE_STORE_MENU_REF,
  SET_STORE_MENU_METHOD,
  ADD_STORE_MENU_ITEM_METHOD,
  REMOVE_STORE_MENU_ITEM_METHOD,
  REMOVE_STORE_MENU_METHOD
} from './constants';

export interface StoreMenu {
  id?: string;
  date?: string;
  image?: string;
  modified_date?: string;
  photo_id?: string;
  version?: string;
  url?: string;
  group_id?: string;
  name?: string;
  desc?: string;
  status?: string;
  items?: StoreMenuItem[];
  maxItem?: string;
  minItems?: string;
  owner?: string;
}
export interface StoreMenuItem {
  sub_menu?: SubMenuStore;
  min_item?: number;
  max_item?: number;
}

export interface StoreMenuUI {
  currentPage?: StoreMenu[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
}

export class GetStoreMenuMethod {
  readonly method = GET_STORE_MENU_METHOD;
  constructor(public id: string) {}
}

export class GetStoreMenusMethod {
  readonly method = GET_STORE_MENUS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(public direction?: number, public reference?: number) {}
}

export class CreateStoreMenuMethod {
  readonly method = CREATE_STORE_MENU_METHOD;
  readonly reference = CREATE_STORE_MENU_REF;
  constructor(storeMenuToBeCreated: StoreMenu) {
    for (const key in storeMenuToBeCreated) {
      if (storeMenuToBeCreated.hasOwnProperty(key)) {
        this[key] = storeMenuToBeCreated[key];
      }
    }
  }
}

export class SetStoreMenuMethod {
  readonly method = SET_STORE_MENU_METHOD;
  constructor(storeMenutoBeUpdated: StoreMenu) {
    for (const key in storeMenutoBeUpdated) {
      if (storeMenutoBeUpdated.hasOwnProperty(key)) {
        this[key] = storeMenutoBeUpdated[key];
      }
    }
  }
}

// data is string[] of Bundle IDs
export class AddStoreMenuItemMethod {
  readonly method = ADD_STORE_MENU_ITEM_METHOD;
  constructor(public id: string, public data: string[], public clear = 1) {}
}

// data is array of bundle IDs ["835935","835936"]
export class RemoveStoreMenuItemMethod {
  readonly method = REMOVE_STORE_MENU_ITEM_METHOD;
  constructor(public id: string, public data: string[]) {}
}

export class RemoveStoreMenuMethod {
  readonly method = REMOVE_STORE_MENU_METHOD;
  constructor(public id: string) {}
}
