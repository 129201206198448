import { LocalComponent } from 'src/models/ChannelAppLocalConfig';
import {
  uuidv4,
  AppClassNames,
  ComponentTypes,
  TabTypes
} from 'src/models/constants';

const DEFAULT_SPLASH_PAGE_VALUES: LocalComponent = {
  title: 'Title',
  desc: 'Description'
};
export function initNewComponent(
  parentContainer: any,
  siblingComponents: LocalComponent[]
): LocalComponent {
  let newComponent: LocalComponent = {};
  newComponent.ref = uuidv4();
  newComponent.parentContainerRef = parentContainer.ref;
  newComponent.order = siblingComponents ? siblingComponents.length : 0;
  newComponent.appClassName = parentContainer.appClassName;
  if (parentContainer.type === TabTypes.MAP_SEARCH) {
    newComponent.component_type = parentContainer.type;
  } else if (parentContainer.link === TabTypes.MAP_SEARCH) {
    newComponent.component_type = parentContainer.link;
  }
  switch (parentContainer.appClassName) {
    case AppClassNames.SPLASH: {
      newComponent.component_type = ComponentTypes.PAGE;
      newComponent = { ...newComponent, ...DEFAULT_SPLASH_PAGE_VALUES };
      break;
    }
  }
  return newComponent;
}
