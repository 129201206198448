<div class="map-container" [class.caption]="locationName">
  <a target="_blank" [href]="googleLink">
    <img [src]="mapImage" alt="location" class="img-map" />
  </a>
</div>

<div
  *ngIf="locationName"
  dir="auto"
  class="caption-box wrap-text copyable-item text-cursor"
>
  {{ locationName }}
  <br />
  {{ locationDetails }}
</div>
