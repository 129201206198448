import * as i0 from "@angular/core";
export class TableService {
    constructor() { }
    updateSelectedItems(addedList, removedList) {
        let newListAfterDeletion = [];
        if (removedList && removedList.length > 0) {
            newListAfterDeletion = this.selectedItems.filter(itemId => !removedList.find(id => id === itemId));
        }
        this.selectedItems = [...newListAfterDeletion];
        if (addedList && addedList.length > 0) {
            addedList.forEach(addedItem => {
                if (!newListAfterDeletion.find(id => id === addedItem)) {
                    this.selectedItems.push(addedItem);
                }
            });
        }
    }
    updateDismissedAdmins(admins) {
        this.dismissedAdmins = [...admins];
    }
    updateAdminsRemove(admins) {
        this.adminsRemove = [...admins];
    }
    resetSelectedItems() {
        this.selectedItems = [];
    }
}
TableService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TableService_Factory() { return new TableService(); }, token: TableService, providedIn: "root" });
