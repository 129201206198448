/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headerless-closable-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./headerless-closable-frame.component";
var styles_HeaderlessClosableFrameComponent = [i0.styles];
var RenderType_HeaderlessClosableFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderlessClosableFrameComponent, data: {} });
export { RenderType_HeaderlessClosableFrameComponent as RenderType_HeaderlessClosableFrameComponent };
export function View_HeaderlessClosableFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "closable-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_HeaderlessClosableFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-headerless-closable-frame", [], null, null, null, View_HeaderlessClosableFrameComponent_0, RenderType_HeaderlessClosableFrameComponent)), i1.ɵdid(1, 49152, null, 0, i2.HeaderlessClosableFrameComponent, [], null, null)], null, null); }
var HeaderlessClosableFrameComponentNgFactory = i1.ɵccf("app-headerless-closable-frame", i2.HeaderlessClosableFrameComponent, View_HeaderlessClosableFrameComponent_Host_0, { showCancel: "showCancel" }, { closeNotify: "closeNotify" }, ["content"]);
export { HeaderlessClosableFrameComponentNgFactory as HeaderlessClosableFrameComponentNgFactory };
