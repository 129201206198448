import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { SubGroupUiDispatchers } from 'src/app/store/subGroupsUI/subGroupsUI.dispatchers';
import { SubChannelUiDispatchers } from 'src/app/store/subChannelsUI/subChannelsUI.dispatchers';

import { IChat } from 'src/models/IChat';
import { MainChat } from 'src/models/MainChat';
import { SubChatUI } from 'src/models/SubGroupUI';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
// import { ISelectedFiles } from 'src/models/ISelectedFiles';
import {
  CHANNEL_APP_SEARCH_CATEGORIES,
  GROUP,
  CHANNEL,
  UPDATE_CHANNEL_REF,
  UPDATE_GROUP_REF,
  ChatTypes,
  UPDATE_VIRTUAL_APP_REF
} from 'src/models/constants';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';
import { SUBGROUPS, SUBCHANNELS, VIRTUAL_APPS } from 'src/models/AppSections';
import { VirtualAppUiDispatchers } from 'src/app/store/virtualAppsUI/virtualAppsUI.dispatchers';
import { AllGroupDispatchers } from 'src/app/store/allGroup/allGroup.dispatchers';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SubChatsService {
  public chatType = GROUP;
  private _chatTypes: typeof ChatTypes = ChatTypes;

  searchEditForm: any = {
    type: null,
    area: null,
    classification: null,
    category: null
  };

  confoirmDeleteWindow = false;
  selectedChat: IChat;

  public _currentPhotoUploaded = new BehaviorSubject<any>({});
  public currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();

  public _wattingForUploadImage = new BehaviorSubject<boolean>(false);
  public wattingForUploadImage$ = this._wattingForUploadImage.asObservable();

  constructor(
    private _rootSelector: RootStoreSelectors,
    private _uiDispatchers: UIDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _subGroupUiDispatchers: SubGroupUiDispatchers,
    private _subChannelUiDispatchers: SubChannelUiDispatchers,
    private _virtualAppUiDispatchers: VirtualAppUiDispatchers,
    private _allGroupDispatchers: AllGroupDispatchers,
    private router: Router
  ) {}

  setSearchForm(val: any) {
    this.searchEditForm = { ...this.searchEditForm, ...val };
  }

  selectSubGroupToChat(chat: any) {
    this._uiDispatchers.subChatSelected(chat);
  }
  createSubGroup(subGroup: IChat, subChat?): void {
    if (subGroup.type === GROUP) {
      // this._wattingForUploadImage.next(true);
      this._chatDispatchers.createGroup(subGroup, subChat);
      // this.router.navigate(['/subgroups']);
    } else if (subGroup.type === CHANNEL) {
      if (subGroup.vapp && subGroup.vapp === 1) {
        this._chatDispatchers.createVirtualApp(subGroup);
      } else {
        this._chatDispatchers.createChannel(subGroup, subChat);
        // this.router.navigate(['/subchannels']);
      }
    }
  }

  subChatDeselected() {
    this._uiDispatchers.subChatDeselected();
  }

  backFromSubGroupReplies() {
    this._uiDispatchers.backFromSubGroupReplies();
  }

  subUserDeselected() {
    this._uiDispatchers.subUserDeselected();
  }

  viewSubGroup(subGroup: IChat): void {
    this.getChatAdministrators(subGroup.id);
    this._wattingForUploadImage.next(false);
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.viewSubGroupUI(subGroup);
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.viewSubChannelUI(subGroup);
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.viewVirtualAppUI(subGroup);
    }
  }

  closeViewSubGroup(): void {
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.closeViewSubGroupUI();
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.closeViewSubChannelUI();
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.closeViewVirtualAppUI();
    }
  }

  showEditSubGroupScreen(subGroup: IChat, isMyChannel?: boolean): void {
    this.getChatAdministrators(subGroup.id);
    const photo = {
      file: null,
      url: subGroup.localThumbnail
    };
    this._currentPhotoUploaded.next(photo);
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.editSubGroupUI(subGroup);
      this.router.navigate(['/subgroups/form']);
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      if (!isMyChannel) {
        this.router.navigate(['/subchannels/form']);
      }
      this._subChannelUiDispatchers.editSubChannelUI(subGroup);
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.editVirtualAppUI(subGroup);
    }
  }

  closeEditSubGroupScreen(): void {
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.closeEditSubGroupUI();
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.closeEditSubChannelUI();
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.closeEditVirtualAppUI();
    }
  }

  deleteChat(id: string) {
    this._chatDispatchers.deleteChat(id);
  }

  toggleDeleteConfoirm(e: boolean, chat?: IChat) {
    this.confoirmDeleteWindow = e;
    this.selectedChat = chat;
  }

  updateSubGroup(chatID: string, updatedSubGroupProps: IChat) {
    updatedSubGroupProps.id = chatID;
    const updateRef =
      updatedSubGroupProps.type === GROUP
        ? UPDATE_GROUP_REF
        : updatedSubGroupProps.type === CHANNEL &&
          updatedSubGroupProps.vapp &&
          updatedSubGroupProps.vapp === 1
        ? UPDATE_VIRTUAL_APP_REF
        : UPDATE_CHANNEL_REF;
    this._chatDispatchers.updateChat(updatedSubGroupProps, updateRef);
  }
  updateStore(chatID: string, updatedSubGroupProps: IChat, create?: boolean) {
    updatedSubGroupProps.id = chatID;
    const updateRef =
      updatedSubGroupProps.type === GROUP
        ? UPDATE_GROUP_REF
        : updatedSubGroupProps.type === CHANNEL &&
          updatedSubGroupProps.vapp &&
          updatedSubGroupProps.vapp === 1
        ? UPDATE_VIRTUAL_APP_REF
        : UPDATE_CHANNEL_REF;

    this._chatDispatchers.updateStore(updatedSubGroupProps, updateRef);
  }

  uploadSubGroupImage(file): void {
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.uploadSubGroupImage(file);
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.uploadSubChannelImage(file);
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.uploadVirtualAppImage(file);
    }
  }

  subGroupCreationComplete(type: string, vapp?: number): void {
    if (type === GROUP) {
      this._subGroupUiDispatchers.subGroupCreationComplete();
    } else if (type === CHANNEL) {
      if (vapp && vapp === 1) {
        this._virtualAppUiDispatchers.virtualAppCreationComplete();
      } else {
        this._subChannelUiDispatchers.subChannelCreationComplete();
      }
    }
  }

  createSubGroupUI() {
    this._wattingForUploadImage.next(false);
    if (this.chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.createSubGroupUI();
      this.router.navigate(['/subgroups/form']);
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      this.router.navigate(['/subchannels/form']);
      this._subChannelUiDispatchers.createSubChannelUI();
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.createVirtualAppUI();
    }
  }

  confirmDelete(id: string) {
    this.deleteChat(id);
    this.toggleDeleteConfoirm(false);
  }

  getNextPage(chatType?: string): void {
    if (chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.getNextSubGroupPage();
    } else if (chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.getNextSubChannelPage();
    } else if (chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.getNextVirtualAppPage();
    }
  }

  getPreviousPage(chatType?: string): void {
    if (chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.getPrevSubGroupPage();
    } else if (chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.getPrevSubChannelPage();
    } else if (chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.getPrevVirtualAppPage();
    }
  }

  resetPaging(chatType: string): void {
    if (chatType === this._chatTypes.GROUP) {
      this._subGroupUiDispatchers.resetSubGroupPaging();
    } else if (chatType === this._chatTypes.CHANNEL) {
      this._subChannelUiDispatchers.resetSubChannelPaging();
    } else if (chatType === this._chatTypes.VIRTUAL_APP) {
      this._virtualAppUiDispatchers.resetVirtualAppPaging();
    }
  }

  // selectors
  getChatTypeIcon(chatType: string, vapp?: number) {
    if (chatType === GROUP) {
      return SUBGROUPS.symbolImage;
    } else if (chatType === CHANNEL) {
      if (vapp && vapp == 1) {
        return VIRTUAL_APPS.symbolImage;
      } else {
        return SUBCHANNELS.symbolImage;
      }
    }
  }
  getChatTypeColor(chatType: string, vapp?: number) {
    if (chatType === GROUP) {
      return SUBGROUPS.symbolColor;
    } else if (chatType === CHANNEL) {
      if (vapp && vapp == 1) {
        return VIRTUAL_APPS.symbolColor;
      } else {
        return SUBCHANNELS.symbolColor;
      }
    }
  }
  getChatTypeColor2(chatType: string, vapp?: number) {
    if (chatType === GROUP) {
      return SUBGROUPS.symbolColor2;
    } else if (chatType === CHANNEL) {
      if (vapp && vapp == 1) {
        return VIRTUAL_APPS.symbolColor2;
      } else {
        return SUBCHANNELS.symbolColor2;
      }
    }
  }
  getChatTypeBG(chatType: string, vapp?: number) {
    if (chatType === GROUP) {
      return SUBGROUPS.coverColor;
    } else if (chatType === CHANNEL) {
      if (vapp && vapp == 1) {
        return VIRTUAL_APPS.coverColor;
      } else {
        return SUBCHANNELS.coverColor;
      }
    }
  }

  get subChats$() {
    return this._rootSelector.subChats$;
  }

  get pagedChats$() {
    return this._rootSelector.getMainSubChats$();
  }

  get allPagedChats$() {
    return this._rootSelector.getAllMainSubChats$();
  }

  getPagedSubChatsByType$(chatType: string) {
    if (chatType === this._chatTypes.GROUP) {
      return this._rootSelector.getPagedSubChats$();
    } else if (chatType === this._chatTypes.CHANNEL) {
      return this._rootSelector.getPagedSubChannels$();
    } else if (chatType === this._chatTypes.VIRTUAL_APP) {
      return this._rootSelector.getPagedVirtualApps$();
    }
  }

  get subChatsList$() {
    return this._rootSelector.getFilteredSubChats$().pipe(
      map(s =>
        s
          .filter(c =>
            this.searchEditForm.category
              ? c.category === this.searchEditForm.category
              : c
          )
          .filter(c =>
            this.searchEditForm.classification
              ? c.classification === this.searchEditForm.classification
              : c
          )
          .filter(c =>
            this.searchEditForm.area ? c.area === this.searchEditForm.area : c
          )
          .filter(c =>
            this.searchEditForm.type ? c.type === this.searchEditForm.type : c
          )
      )
    );
  }

  getChatWelcomeMessage$(messageId: string) {
    return this._rootSelector.getChatWelcomeMessage$(messageId);
  }

  get selectedSubGroupMain$(): Observable<MainChat> {
    if (this.chatType === this._chatTypes.GROUP) {
      return this._rootSelector
        .getSelectedSubGroup$()
        .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      return this._rootSelector
        .getSelectedSubChannel$()
        .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      return this._rootSelector
        .getSelectedVirtualApp$()
        .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
    }
  }

  get selectedSubGroup$(): Observable<IChat> {
    if (this.chatType === this._chatTypes.GROUP) {
      return this._rootSelector.getSelectedSubGroup$();
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      return this._rootSelector.getSelectedSubChannel$();
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      return this._rootSelector.getSelectedVirtualApp$();
    }
  }

  get subGroupInCreationProcess$(): Observable<IChat> {
    if (this.chatType === this._chatTypes.GROUP) {
      return this._rootSelector.getSubGroupInCreationProcess$();
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      return this._rootSelector.getSubChannelInCreationProcess$();
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      return this._rootSelector.getVirtualAppInCreationProcess$();
    }
  }

  get subGroupUiState$(): Observable<SubChatUI> {
    if (this.chatType === this._chatTypes.GROUP) {
      return this._rootSelector.subGroupUiState$;
    } else if (this.chatType === this._chatTypes.CHANNEL) {
      return this._rootSelector.subChannelUiState$;
    } else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
      return this._rootSelector.virtualAppUiState$;
    }
  }

  get area$() {
    return this._rootSelector.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.AREA
    );
  }
  get classification$() {
    return this._rootSelector.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
    );
  }
  get category$() {
    return this._rootSelector.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY
    );
  }

  requestChatDetaislById(id: string) {
    this._chatDispatchers.requestChatDetails(id);
  }

  get getMainSubChatsPageCount$() {
    return this._rootSelector.getMainSubChatsPageCount$();
  }

  setAllGroupLoaderStatus(status: string) {
    this._allGroupDispatchers.setLoaderStatus(status);
  }

  get getUIAllGroup$() {
    return this._rootSelector.getUIAllGroup$();
  }

  getChatAdministrators(id: string) {
    this._chatDispatchers.getChatAdministrators(id);
  }

  setChatPhoto(chat: IChat) {
    this._chatDispatchers.updateChat(chat);
  }
}
