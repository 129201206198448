import { Action } from '@ngrx/store';

import { ICoupon } from 'src/models/ICoupon';

export const enum CouponUIActionTypes {
  UI_LIST_COUPON = 'UI_COUPON_LIST',
  UI_CREATE_COUPON = 'UI_CREATE_COUPON',
  UI_VIEW_COUPON = 'UI_VIEW_COUPON',
  UI_EDIT_COUPON = 'UI_EDIT_COUPON',
  UI_CLOSE_CREATE_COUPON = 'UI_CLOSE_CREATE_COUPON',
  UI_CLOSE_VIEW_COUPON = 'UI_CLOSE_VIEW_COUPON',
  UI_CLOSE_EDIT_COUPON = 'UI_CLOSE_EDIT_COUPON',
  UI_COUPON_GET_NEXT_PAGE = 'UI_COUPON_GET_NEXT_PAGE',
  UI_COUPON_GET_PREV_PAGE = 'UI_COUPON_GET_PREV_PAGE',
  UI_COUPON_RECEIVED_HISTORY_CHUNK = 'UI_COUPON_RECEIVED_HISTORY_CHUNK',
  UI_COUPONS_RECEIVED = 'UI_COUPONS_RECEIVED',
  UI_COUPON_RESET_PAGING = 'UI_COUPON_RESET_PAGING',
  UI_COUPON_SET_IDLE_STATUS = 'UI_COUPON_SET_IDLE_STATUS'
}

export const RESET = 'RESET';
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';

export class ListCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_LIST_COUPON;
  constructor() { }
}

export class CreateCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_CREATE_COUPON;
}

export class ViewCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_VIEW_COUPON;
  constructor(public selectedCoupon: ICoupon) { }
}

export class EditCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_EDIT_COUPON;
  constructor(public selectedCoupon: ICoupon) { }
}

export class CloseCreateCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_CLOSE_CREATE_COUPON;
}
export class CloseViewCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_CLOSE_VIEW_COUPON;
}

export class CloseEditCouponUI implements Action {
  readonly type = CouponUIActionTypes.UI_CLOSE_EDIT_COUPON;
}

export class GetNextCouponPage implements Action {
  readonly type = CouponUIActionTypes.UI_COUPON_GET_NEXT_PAGE;
}

export class GetPrevCouponPage implements Action {
  readonly type = CouponUIActionTypes.UI_COUPON_GET_PREV_PAGE;
}

export class ReceivedCoupons implements Action {
  readonly type = CouponUIActionTypes.UI_COUPONS_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: ICoupon[],
    public groupId: any
  ) { }
}
export class ReceivedCouponHistoryChunk implements Action {
  readonly type = CouponUIActionTypes.UI_COUPON_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: ICoupon[]
  ) { }
}

export class ResetCouponPaging implements Action {
  readonly type = CouponUIActionTypes.UI_COUPON_RESET_PAGING;
  constructor(public status?: string, public expire?: string) { }
}

export class SetStatusAsIdle implements Action {
  readonly type = CouponUIActionTypes.UI_COUPON_SET_IDLE_STATUS;
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type Actions =
  | ListCouponUI
  | CreateCouponUI
  | ViewCouponUI
  | EditCouponUI
  | CloseCreateCouponUI
  | CloseViewCouponUI
  | CloseEditCouponUI
  | GetNextCouponPage
  | GetPrevCouponPage
  | ReceivedCouponHistoryChunk
  | ResetCouponPaging
  | SetStatusAsIdle
  | DeselectChannel
  | Reset;
