import { Router, NavigationEnd } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class WidgetService {
    constructor(router) {
        this.router = router;
        this.router.events.subscribe(event => {
            // hide formilla-form
            if (event instanceof NavigationEnd) {
                const formillaElem = document.getElementById('formilla-frame');
                if (formillaElem) {
                    if (event.urlAfterRedirects.indexOf('app') === -1) {
                        formillaElem.style.display = 'none';
                    }
                    else {
                        formillaElem.style.display = 'block';
                    }
                }
            }
        });
    }
}
WidgetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WidgetService_Factory() { return new WidgetService(i0.ɵɵinject(i1.Router)); }, token: WidgetService, providedIn: "root" });
