import { Bundle, BundleProduct } from './Bundle';
import { Product } from 'src/models/Product';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  GET_SUB_MENU_METHOD,
  GET_SUB_MENUS_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_SUB_MENU_METHOD,
  SET_SUB_MENU_METHOD,
  ADD_SUB_MENU_ITEM_METHOD,
  REMOVE_SUB_MENU_ITEM_METHOD,
  CREATE_SUB_MENU_REF
} from './constants';

export interface SubMenuStore {
  id?: string;
  date?: string;
  image?: string;
  modified_date?: string;
  photo_id?: string;
  version?: string;
  url?: string;
  group_id?: string;
  name?: string;
  desc?: string;
  status?: string;
  products?: any[];
  type?: number;
}
export interface SubMenuItem {
  is_default?: number;
  product_id?: string;
}

export interface SubMenuUI {
  currentPage?: SubMenuStore[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
}

export class GetSubMenuMethod {
  readonly method = GET_SUB_MENU_METHOD;
  constructor(public id: string) {}
}

export class GetSubMenusMethod {
  readonly method = GET_SUB_MENUS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(public direction?: number, public reference?: number) {}
}

export class CreateSubMenuMethod {
  readonly method = CREATE_SUB_MENU_METHOD;
  readonly reference = CREATE_SUB_MENU_REF;
  constructor(packageToBeCreated: SubMenuStore) {
    for (const key in packageToBeCreated) {
      if (packageToBeCreated.hasOwnProperty(key)) {
        this[key] = packageToBeCreated[key];
      }
    }
  }
}

export class SetSubMenuMethod {
  readonly method = SET_SUB_MENU_METHOD;
  constructor(storetoBeUpdated: SubMenuStore) {
    for (const key in storetoBeUpdated) {
      if (storetoBeUpdated.hasOwnProperty(key)) {
        this[key] = storetoBeUpdated[key];
      }
    }
  }
}

// data is string[] of Bundle IDs
export class AddSubMenuItemMethod {
  readonly method = ADD_SUB_MENU_ITEM_METHOD;
  constructor(public id: string, public data: string[], public clear = 1) {}
}

// data is array of bundle IDs ["835935","835936"]
export class RemoveSubMenuItemMethod {
  readonly method = REMOVE_SUB_MENU_ITEM_METHOD;
  constructor(public id: string, public data: string[]) {}
}
