import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PaymentActions from './payment.actions';

import { AppState } from 'src/models/AppState';
import { IPricePlan } from 'src/models/IPricePlan';
import { RcvdPayment } from 'src/models/IPayment';

@Injectable({
  providedIn: 'root'
})
export class PaymentDispatchers {
  constructor(private _store: Store<AppState>) {}

  paymentSet() {
    this._store.dispatch(new PaymentActions.PaymentSet());
  }
  getPricePlans() {
    this._store.dispatch(new PaymentActions.GetPricePlans());
  }
  setPricePlans(plans: IPricePlan[][]) {
    this._store.dispatch(new PaymentActions.SetPricePlans(plans));
  }
  getPaymentInfo() {
    this._store.dispatch(new PaymentActions.GetPaymentInfo());
  }
  rcvdPaymentAuthInfo(paymentPlan: RcvdPayment) {
    this._store.dispatch(new PaymentActions.RcvdPaymentAuthInfo(paymentPlan));
  }
  setCurrentPayment(paymentPlan: IPricePlan) {
    this._store.dispatch(new PaymentActions.SetCurrentPayment(paymentPlan));
  }
  setPaymentInfo(paymentPlan: RcvdPayment) {
    this._store.dispatch(new PaymentActions.SetPaymentInfo(paymentPlan));
  }
  getPaymentToken() {
    this._store.dispatch(new PaymentActions.GetPaymentToken());
  }
  setPaymentToken(token: string) {
    this._store.dispatch(new PaymentActions.SetPaymentToken(token));
  }
  setPaymentUrl(token: string) {
    this._store.dispatch(new PaymentActions.SetPaymentUrl(token));
  }
  setSelectedPayment(plan: IPricePlan) {
    this._store.dispatch(new PaymentActions.SetSelectedPayment(plan));
  }
  setSelectedFreePayment(plan?: IPricePlan) {
    this._store.dispatch(new PaymentActions.SetSelectedFreePayment(plan));
  }

  editSelectedPayment(plan: IPricePlan) {
    this._store.dispatch(new PaymentActions.EditSelectedPayment(plan));
  }
  setCanceledPayment(plan: IPricePlan) {
    this._store.dispatch(new PaymentActions.SetCanceledPayment(plan));
  }

  setCanceledFreePayment(plan: IPricePlan) {
    this._store.dispatch(new PaymentActions.SetCanceledFreePayment(plan));
  }
  resetPaymentPlan() {
    this._store.dispatch(new PaymentActions.ResetPaymentPlan());
  }
}
