<div
  class="url-container no-copyable-item clickable-cursor media-container"
  (click)="gotoLink()"
>
  <div
    *ngIf="image && !isSqure"
    class="header"
    [title]="rootUrl"
    [ngStyle]="headerImg"
  ></div>
  <div class="body">
    <!-- <img *ngIf="image && isSqure" class="head" [src]="image" [alt]="rootUrl" /> -->
    <div
      *ngIf="image && isSqure"
      class="head"
      [title]="rootUrl"
      [ngStyle]="headImg"
    ></div>
    <div class="core wrap-text">
      <div *ngIf="title" class="url-title" dir="auto">{{ title }}</div>
      <div *ngIf="description" class="url-desc" dir="auto">
        {{
          description.split(' ').length > 20
            ? description
                .split(' ')
                .splice(0, 20)
                .join(' ') + ' ...'
            : description
        }}
      </div>
      <div *ngIf="rootUrl && showSite" class="url-site text-uppercase">
        {{ rootUrl }}
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
