import { Action } from '@ngrx/store';
import { Stops } from 'src/models/MapStopTime';

export const enum MapStopTimeActionTypes {
  CREATE_MAP_STOP_TIME = 'CREATE_MAP_STOP_TIME',
  CREATE_MAP_STOP_TIME_RECEIVED = 'CREATE_MAP_STOP_TIME_RECEIVED',
  SET_MAP_STOP_TIMES = 'SET_MAP_STOP_TIMES',
  GET_MAP_STOP_TIME = 'GET_MAP_STOP_TIME',
  MAP_STOP_TIMES_LIST_RECEIVED = 'MAP_STOP_TIMES_LIST_RECEIVED',
  MAP_STOPS_RESET = 'MAP_STOPS_RESET'
}

export class CreateMapStopTime implements Action {
  readonly type = MapStopTimeActionTypes.CREATE_MAP_STOP_TIME;
  constructor(public MapStopTime: Stops) {}
}
export class SetMapStopTimes implements Action {
  readonly type = MapStopTimeActionTypes.SET_MAP_STOP_TIMES;
  constructor(public tripID: string, public MapStopTimes: Stops[]) {}
}

export class CreateMapStopTimeReceived implements Action {
  readonly type = MapStopTimeActionTypes.CREATE_MAP_STOP_TIME_RECEIVED;
  constructor(public MapStopTime: Stops) {}
}

export class GetMapStopTime implements Action {
  readonly type = MapStopTimeActionTypes.GET_MAP_STOP_TIME;
  constructor(public trip_id: string) {}
}

export class MapStopTimesListReceived implements Action {
  readonly type = MapStopTimeActionTypes.MAP_STOP_TIMES_LIST_RECEIVED;
  constructor(public MapStopTimes: Stops[]) {}
}

export class Rest implements Action {
  readonly type = MapStopTimeActionTypes.MAP_STOPS_RESET;
}

export type Actions =
  | CreateMapStopTime
  | CreateMapStopTimeReceived
  | GetMapStopTime
  | MapStopTimesListReceived
  | Rest;
