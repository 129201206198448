<div class="no-items" [class.no-bg]="noBg">
  <div class="no-items-symbol">
    <svg class="svg-icon icon-icon">
      <use
        attr.xlink:href="./assets/svg/app-sections.svg#{{ emptySymbol }}"
      ></use>
    </svg>
  </div>
  <div class="no-items-title">{{ emptyTitle }}</div>
  <div class="no-items-desc">{{ emptyDesc }}</div>
</div>
