import { MainChat } from 'src/models/MainChat';
import { UIState } from 'src/models/UIState';

export class UIMiddleware {
  public static isAllProfilesAndChatsRcvd(allChats: MainChat[], ui: UIState) {
    const groupsCount = ui && ui.groupsCount ? ui.groupsCount : 0;
    const contactsCount = ui && ui.contactsCount ? ui.contactsCount : 0;

    const allChatsWithDetails = allChats
      ? allChats.filter(chat => chat.detailsReceived)
      : [];

    if (
      allChatsWithDetails &&
      allChatsWithDetails.length >= contactsCount + groupsCount
    ) {
      return true;
    }
    return false;
  }
}
