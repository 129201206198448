import { Store } from '@ngrx/store';
import * as BlackList from './blacklistUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BlackListUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetBlacklistPaging(name) {
        this._store.dispatch(new BlackList.ResetBlackListPaging(name));
    }
    getNextBlacklistsPage() {
        this._store.dispatch(new BlackList.GetNextBlacklistsPage());
    }
    getPrevBlacklistsPage() {
        this._store.dispatch(new BlackList.GetPrevBlacklistsPage());
    }
    setStatusAsIdle() {
        this._store.dispatch(new BlackList.SetStatusAsIdle());
    }
    addUserBlacklist(users, mainChatID, reference, recordIndex) {
        this._store.dispatch(new BlackList.AddUserToBlacklist(users, mainChatID, reference, recordIndex));
    }
    receivedBlacklistsHistoryChunk(eop, sop, currentPage, hash) {
        this._store.dispatch(new BlackList.ReceivedBlacklistsHistoryChunk(eop, sop, currentPage, hash));
    }
    addBlacklistSuccess(users) {
        this._store.dispatch(new BlackList.AddBlacklistSuccess(users));
    }
    removeUserFromBlacklist(user, mainChatID) {
        this._store.dispatch(new BlackList.RemoveUserFromBlacklist(user, mainChatID));
    }
    removeUserFromBlacklistSuccess(user) {
        this._store.dispatch(new BlackList.RemoveUserFromBlacklistSuccess(user));
    }
    resetObject() {
        this._store.dispatch(new BlackList.Reset());
    }
    setLoadingScreenStatus(status) {
        this._store.dispatch(new BlackList.SetLoadingScreenStatus(status));
    }
}
BlackListUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlackListUiDispatchers_Factory() { return new BlackListUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: BlackListUiDispatchers, providedIn: "root" });
