<app-form-frame
  no-padding
  [showCancel]="true"
  (closeNotify)="cancelEvent()"
  *ngIf="!isPostScreen"
>
  <ng-container ngProjectAs="title">Add Group/Channel</ng-container>
  <ng-container ngProjectAs="desc">
    Manage your Group/Channel or
    <span class="link" (click)="createNewItem()">
      create a new
      {{
        selectedIndex ? ('GROUP' | translate) : ('CHANNEL' | translate)
      }}</span
    >.
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <mat-tab-group (selectedIndexChange)="selectedChanged($event)">
        <ng-container>
          <ng-container>
            <mat-tab>
              <ng-template mat-tab-label>
                <svg class="svg-icon icon-icon">
                  <use
                    attr.xlink:href="./assets/svg/app-sections.svg#{{
                      subChannelFet?.symbolImage
                    }}"
                  ></use>
                </svg>
                {{ 'CHANNELS' | translate }}
              </ng-template>
              <div class="pool-body">
                <ng-container
                  *ngIf="
                    (
                      subChatsService.getPagedSubChatsByType$(
                        chatTypes.CHANNEL
                      ) | async
                    )?.length > 0;
                    else isSubChannelsEmpty
                  "
                >
                  <app-selectable-cell-frame
                    *ngFor="
                      let grp of subChatsService.getPagedSubChatsByType$(
                        chatTypes.CHANNEL
                      ) | async;
                      trackBy: TrackByFunction
                    "
                    [symbolColor]="subChannelFet?.symbolColor"
                    [symbolImage]="subChannelFet?.symbolImage"
                    [coverColor]="subChannelFet?.coverColor"
                    [coverPhoto]="grp?.localMedia"
                    [title]="grp?.title"
                    [desc]="grp?.description"
                    [selectorId]="grp?.id"
                    [isSelected]="isProductSelected(grp?.id)"
                    [useNewStely]="true"
                    [cellSize]="'M'"
                    (checkClick)="setSelectMessage(grp)"
                    (checkChanged)="resetSelectMessage(grp)"
                  >
                    <ng-container icon>
                      <svg class="svg-icon icon-import">
                        <use
                          attr.xlink:href="./assets/svg/app-sections.svg#{{
                            subChannelFet?.symbolImage
                          }}"
                        ></use>
                      </svg>
                      {{ 'CHANNELS' | translate }}
                    </ng-container>
                  </app-selectable-cell-frame>
                </ng-container>
                <ng-template #isSubChannelsEmpty>
                  <app-empty-item-frame
                    [emptySymbol]="subChannelFet?.symbolImage"
                    [emptyTitle]="subChannelFet?.emptyTitle"
                    [emptyDesc]="subChannelFet?.emptyPoolDesc"
                  >
                  </app-empty-item-frame>
                </ng-template>
              </div>
              <div
                class="navigate"
                *ngIf="
                  (
                    subChatsService.getPagedSubChatsByType$(chatTypes.CHANNEL)
                    | async
                  )?.length > 0
                "
              >
                <div
                  class="navigate-btn"
                  (click)="subChatsService.getPreviousPage(chatTypes.CHANNEL)"
                >
                  <svg class="svg-icon icon-icon">
                    <use
                      xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
                    ></use>
                  </svg>
                </div>
                <div
                  class="navigate-btn"
                  (click)="subChatsService.getNextPage(chatTypes.CHANNEL)"
                >
                  <svg
                    class="svg-icon icon-icon"
                    style="transform: rotate(180deg);"
                  >
                    <use
                      xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
                    ></use>
                  </svg>
                </div>
              </div>
            </mat-tab>
          </ng-container>
        </ng-container>
        <ng-container>
          <mat-tab>
            <ng-template mat-tab-label>
              <svg class="svg-icon icon-icon">
                <use
                  attr.xlink:href="./assets/svg/app-sections.svg#{{
                    subGroupFet?.symbolImage
                  }}"
                ></use>
              </svg>
              {{ 'GROUPS' | translate }}
            </ng-template>
            <div class="pool-body">
              <ng-container
                *ngIf="
                  (
                    subChatsService.getPagedSubChatsByType$(chatTypes.GROUP)
                    | async
                  )?.length > 0;
                  else isSubGroupsEmpty
                "
              >
                <app-selectable-cell-frame
                  *ngFor="
                    let grp of subChatsService.getPagedSubChatsByType$(
                      chatTypes.GROUP
                    ) | async;
                    trackBy: TrackByFunction
                  "
                  [symbolColor]="subGroupFet?.symbolColor"
                  [symbolImage]="subGroupFet?.symbolImage"
                  [coverColor]="subGroupFet?.coverColor"
                  [coverPhoto]="grp?.localMedia"
                  [title]="grp?.title"
                  [desc]="grp?.description"
                  [selectorId]="grp?.id"
                  [isSelected]="isProductSelected(grp?.id)"
                  [useNewStely]="true"
                  [cellSize]="'M'"
                  (checkClick)="setSelectMessage(grp)"
                  (checkChanged)="resetSelectMessage(grp)"
                >
                  <ng-container icon>
                    <svg class="svg-icon icon-import">
                      <use
                        attr.xlink:href="./assets/svg/app-sections.svg#{{
                          subGroupFet?.symbolImage
                        }}"
                      ></use>
                    </svg>
                    {{ 'GROUPS' | translate }}
                  </ng-container>
                </app-selectable-cell-frame>
              </ng-container>
              <ng-template #isSubGroupsEmpty>
                <app-empty-item-frame
                  [emptySymbol]="subGroupFet?.symbolImage"
                  [emptyTitle]="subGroupFet?.emptyTitle"
                  [emptyDesc]="subGroupFet?.emptyPoolDesc"
                >
                </app-empty-item-frame>
              </ng-template>
            </div>
            <div
              class="navigate"
              *ngIf="
                (
                  subChatsService.getPagedSubChatsByType$(chatTypes.GROUP)
                  | async
                )?.length > 0
              "
            >
              <div
                class="navigate-btn"
                (click)="subChatsService.getPreviousPage(chatTypes.GROUP)"
              >
                <svg class="svg-icon icon-icon">
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
                  ></use>
                </svg>
              </div>
              <div
                class="navigate-btn"
                (click)="subChatsService.getNextPage(chatTypes.GROUP)"
              >
                <svg
                  class="svg-icon icon-icon"
                  style="transform: rotate(180deg);"
                >
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
                  ></use>
                </svg>
              </div>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button class="add-btn" (click)="insertEvent()">
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
<app-form-frame
  no-padding
  [showCancel]="true"
  (closeNotify)="cancelEvent()"
  *ngIf="isPostScreen"
>
  <ng-container ngProjectAs="title">Add Group/Channel</ng-container>
  <ng-container ngProjectAs="desc">
    {{ 'SUBCHATSELECTORDESC' | translate }}
    <span dropdown placement="bottom left" style="position: relative;">
      <span class="link" dropdownToggle>
        create a new
        <div class="fas fa-chevron-down clickable-cursor"></div></span
      >.
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" (click)="createNewChannel()">
          <div class="text">
            <svg class="svg-icon icon-import">
              <use
                attr.xlink:href="./assets/svg/app-sections.svg#{{
                  subChannelFet?.symbolImage
                }}"
              ></use>
            </svg>
            {{ 'CHANNELS' | translate }}
          </div>
        </li>
        <li role="menuitem" (click)="createNewGroup()">
          <div class="text">
            <svg class="svg-icon icon-import">
              <use
                attr.xlink:href="./assets/svg/app-sections.svg#{{
                  subGroupFet?.symbolImage
                }}"
              ></use>
            </svg>
            {{ 'GROUPS' | translate }}
          </div>
        </li>
      </ul>
    </span>
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container
          *ngIf="dashboardService.getChannelsAndGroupList$ | async as chats"
        >
          <app-selectable-cell-frame
            *ngFor="let grp of chats; trackBy: TrackByFunction"
            [symbolColor]="subChannelFet?.symbolColor"
            [symbolImage]="subChannelFet?.symbolImage"
            [coverColor]="subChannelFet?.coverColor"
            [coverPhoto]="grp?.image"
            [title]="grp?.name"
            [desc]="grp?.description"
            [selectorId]="grp?.id"
            [isSelected]="isProductSelected(grp?.id)"
            [useNewStely]="true"
            [cellSize]="'M'"
            (checkClick)="setSelectMessages(grp)"
            (checkChanged)="resetSelectMessage(grp)"
          >
            <ng-container icon>
              <ng-container *ngIf="grp?.type === chatTypes.CHANNEL">
                <svg class="svg-icon icon-import">
                  <use
                    attr.xlink:href="./assets/svg/app-sections.svg#{{
                      subChannelFet?.symbolImage
                    }}"
                  ></use>
                </svg>
                {{ 'CHANNELS' | translate }}
              </ng-container>
              <ng-container *ngIf="grp?.type === chatTypes.GROUP">
                <svg class="svg-icon icon-import">
                  <use
                    attr.xlink:href="./assets/svg/app-sections.svg#{{
                      subGroupFet?.symbolImage
                    }}"
                  ></use>
                </svg>
                {{ 'GROUPS' | translate }}
              </ng-container>
            </ng-container>
          </app-selectable-cell-frame>
        </ng-container>
        <!-- <app-empty-item-frame
          *ngIf="(_locationsService.mapMarkers$ | async).length === 0"
          [emptySymbol]="locationsFet?.symbolImage"
          [emptyTitle]="locationsFet?.emptyTitle"
          [emptyDesc]="locationsFet?.emptyPoolDesc"
        >
        </app-empty-item-frame> -->
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button class="add-btn" (click)="insertEvent()">
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
