import { BehaviorSubject } from 'rxjs';
import { ScreenSizeService } from 'src/app/core/screen-size.service';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { SIDE_MENU } from 'src/models/AppSections';
import { GridStyle, ThemeStatus, UISections } from 'src/models/constants';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../store/root-store.selectors";
import * as i2 from "../../store/messages/message.dispatchers";
import * as i3 from "../../core/screen-size.service";
import * as i4 from "../../store/ui/ui.dispatchers";
export class LayoutService {
    constructor(_selectors, _messageDispatchers, _screenSize, _uiDispatcher) {
        this._selectors = _selectors;
        this._messageDispatchers = _messageDispatchers;
        this._screenSize = _screenSize;
        this._uiDispatcher = _uiDispatcher;
        this.appMenu = SIDE_MENU;
        this.uiSections = UISections;
        this.themeStatus = ThemeStatus;
        this._listGridStyle = new BehaviorSubject(GridStyle.card);
        this.listGridStyle$ = this._listGridStyle.asObservable();
        this._stickyStyle = new BehaviorSubject(false);
        this.stickyStyle$ = this._stickyStyle.asObservable();
        this._sectionLink = new BehaviorSubject('');
        this.sectionLink$ = this._sectionLink.asObservable();
        this._appModelOpenStatus = new BehaviorSubject(0);
        this.appModelOpenStatus$ = this._appModelOpenStatus.asObservable();
        this._appModelStatus = new BehaviorSubject(false);
        this.appModelStatus$ = this._appModelStatus.asObservable();
    }
    getSideNavStatus() {
        return this._screenSize.mobileQuery;
    }
    setListGridStyle(n) {
        this._listGridStyle.next(n);
    }
    setSideMenuStatus(status) {
        this._uiDispatcher.setSideMenuStatus(status);
    }
    get messagesCollection$() {
        return this._selectors.messagesCollection$;
    }
    get uiCollection$() {
        return this._selectors.uiCollection$;
    }
    get selectedChat$() {
        return this._selectors.getSelectedChat$();
    }
    get allChannels$() {
        return this._selectors.getChatsMenu$();
    }
    getsideMenuStatus$() {
        return this._selectors.getSideMenuStatus();
    }
    deselectChannel() {
        this._messageDispatchers.deselectChannel();
    }
    getDocumentationLinkOfSection(section, currentPage, themeStatus) {
        switch (section) {
            case this.uiSections.APP:
                if (currentPage == 1) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/branding-section');
                }
                else if (currentPage == 2 && themeStatus === this.themeStatus.Menu) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users#assigning-modules-to-buttons');
                }
                else if (currentPage == 2 && themeStatus !== this.themeStatus.Menu) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/home-menu');
                }
                else if (currentPage == 3) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/home-menu#side-menu');
                }
                else if (currentPage == 4) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/app-settings');
                }
                else if (currentPage == 5) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/create-apple-store-connect-account#configure-app-store-and-app-info-setup');
                }
                break;
            case this.uiSections.CHANNEL:
                if (currentPage == 1) {
                    this._sectionLink.next('https://docs.nandbox.com/#img-src-media-buildericons-home-5b8af844-svg-alt-buildericon-nandbox-app-builder-branding-section');
                }
                else if (currentPage == 2) {
                    this._sectionLink.next('https://docs.nandbox.com/#img-src-media-buildericons-home-5b8af844-svg-alt-buildericon-nandbox-app-builder-home-menu');
                }
                break;
            case this.uiSections.POSTS:
                this._sectionLink.next('https://docs.nandbox.com/docs/posts-feed-module#module-walkthrough');
                break;
            case this.uiSections.SCHEDULE:
                this._sectionLink.next('https://docs.nandbox.com/docs/posts-feed-module#create-a-scheduled-post');
                break;
            case this.uiSections.MENUS:
                this._sectionLink.next('https://docs.nandbox.com/#img-src-media-buildericons-like-b69b718b-svg-alt-likeicon-social-loyalty-module-create-a-competition-poll-create-a-menu');
                break;
            case this.uiSections.SUBCHANNEL:
                this._sectionLink.next('https://docs.nandbox.com/docs/channel-modules#module-walkthrough');
                break;
            case this.uiSections.SUBGROUP:
                this._sectionLink.next('https://docs.nandbox.com/docs/chat-group-modules#module-walkthrough');
                break;
            case this.uiSections.EVENTS:
                this._sectionLink.next('https://docs.nandbox.com/docs/events-list-module#module-walkthrough');
                break;
            case this.uiSections.BOOKING:
                this._sectionLink.next('https://docs.nandbox.com/docs/booking-module#module-walkthrough');
                break;
            case this.uiSections.CALENDAR:
                this._sectionLink.next('https://docs.nandbox.com/docs/school-app#creating-a-new-calendar');
                break;
            case this.uiSections.MSTORE:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#setting-up-a-store');
                break;
            case this.uiSections.PRODUCTS:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#creating-a-new-product');
                break;
            case this.uiSections.BUNDLES:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#creating-a-new-bundle');
                break;
            case this.uiSections.PACKAGES:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#creating-a-new-multi-tiered-plan');
                break;
            case this.uiSections.STOREMENU:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#adding-product-add-ons');
                break;
            case this.uiSections.TRANSACTIONS:
                this._sectionLink.next('https://docs.nandbox.com/docs/store-module#checking-your-orders');
                break;
            case this.uiSections.CAMPAIGNS:
                this._sectionLink.next('https://docs.nandbox.com/docs/loyalty-module#module-walkthrough');
                break;
            case this.uiSections.COUPONS:
                this._sectionLink.next('https://docs.nandbox.com/docs/loyalty-module#create-a-coupon');
                break;
            case this.uiSections.USERS:
                this._sectionLink.next('https://docs.nandbox.com/docs/user-management');
                break;
            case this.uiSections.TAGS:
                this._sectionLink.next('https://docs.nandbox.com/docs/user-management#create-new-tag');
                break;
            case this.uiSections.BLACKLIST:
                this._sectionLink.next('https://docs.nandbox.com/docs/user-management#blacklist');
                break;
            case this.uiSections.WHITELIST:
                this._sectionLink.next('https://docs.nandbox.com/docs/user-management#whitelist');
                break;
            case this.uiSections.BOTS:
                this._sectionLink.next('https://docs.nandbox.com/docs/app-settings-2#api-and-integrations');
                break;
            case this.uiSections.SETTINGS:
                this._sectionLink.next('https://docs.nandbox.com/docs/settings');
                break;
            case this.uiSections.BILLINGS:
                this._sectionLink.next('https://docs.nandbox.com/docs/settings#changing-billing-information');
                break;
            case 'startOver':
                if (currentPage == 1) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users');
                }
                else if (currentPage == 3) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users#additional-features');
                }
                else if (currentPage == 4) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users#simple-mode');
                }
                else if (currentPage == 5) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/build-publish');
                }
                else if (currentPage == 6) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/build-publish');
                }
                else if (currentPage == 7) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/branding-section');
                }
                else if (currentPage == 8) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users#customizing-app-menu');
                }
                else if (currentPage == 9) {
                    this._sectionLink.next('https://docs.nandbox.com/docs/new-users#creating-the-main-menu');
                }
                break;
            case this.uiSections.BULKUPLOAD:
                this._sectionLink.next('https://docs.nandbox.com/docs/bulk-upload');
                break;
            default:
                this._sectionLink.next('https://docs.nandbox.com/');
                break;
        }
    }
}
LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.MessageDispatchers), i0.ɵɵinject(i3.ScreenSizeService), i0.ɵɵinject(i4.UIDispatchers)); }, token: LayoutService, providedIn: "root" });
