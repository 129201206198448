import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from 'src/app/core/auth.service';
import { LayoutService } from '../layout.service';

import { MainChat } from 'src/models/MainChat';
import {
  ISAUTH,
  BillingsPageScreens,
  SummaryScreens,
  UIBillingSubRouts,
  UISections,
  languages,
  IS_PRODUCTION
} from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { UiService } from 'src/app/core/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../+dashboard/dashboard.service';
import { SummaryService } from '../../+dashboard/summary/summary.service';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { SubscriptionModalService } from 'src/app/subscription/subscription-modal.service';
import { Location } from '@angular/common';
import { BillingsService } from '../../+dashboard/billings/billings.service';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit, OnDestroy, OnChanges {
  uiSections: typeof UISections = UISections;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  @Input() selectedChatId: string;
  @Input() helpWindowStatus: boolean;
  @Input() mode: any;
  @Input() role: any;
  @Input() currentSection: any;
  @Input() currentSelectedPlan: any;
  @Input() trileEndDate: any;
  @Input() mainChat: any;

  @Output() searchNotify = new EventEmitter<string>();
  @Output() toggleSidenavNotify = new EventEmitter<boolean>();
  @Output() sectionNotify = new EventEmitter<string>();
  @Output() talkToAdminNotify = new EventEmitter<string>();
  @Output() openHelpWindowNotify = new EventEmitter<boolean>();
  @Output() closeHelpWindowNotify = new EventEmitter<boolean>();
  @Output() logoutNotify = new EventEmitter<boolean>();
  targetChat: MainChat;
  searchTerm = new FormControl();
  serchTerms$: Observable<string> = this.searchTerm.valueChanges;

  serchTermsSubscription: Subscription = null;
  @Input() selectedFeature: any;
  @Input() splashPages: any[];
  @Input() splashFooter: any;
  @Input() membersRols: any[];
  @Input() cancelEnd: any;
  @Input() mainPlanStatus: boolean;
  langList = languages;
  showLangs = false;
  production = IS_PRODUCTION;
  public summaryScreens = SummaryScreens;
  billingsPageScreens = BillingsPageScreens;
  ui: any;
  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public translate: TranslateService,
    public router: Router,
    public dashboardService: DashboardService,
    public summaryService: SummaryService,
    public appManagementService: AppMgmtService,
    public _chatDispatchers: ChatDispatchers,
    private _subscriptionModal: SubscriptionModalService,
    public billingsService: BillingsService,
    public location: Location,
    public _ref: ChangeDetectorRef
  ) {}

  ngOnChanges() {}

  ngOnInit() {
    if (this.selectedChatId) {
      this.uiService.getChatByID$(this.selectedChatId).subscribe(res => {
        this.targetChat = res;
      });
    }
    this.serchTermsSubscription = this.serchTerms$
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => this.searchEvent(searchTerm));
  }

  ngOnDestroy() {
    if (this.serchTermsSubscription) {
      this.serchTermsSubscription.unsubscribe();
    }
    if (this.ui) {
      this.ui.unsubscribe();
    }
  }

  toggleSidenavEvent() {
    this.toggleSidenavNotify.emit(true);
  }

  searchEvent(searchTerm: string): void {
    this.searchNotify.emit(searchTerm);
  }
  sectionEvent(direction: string): void {
    let validation = true;
    if (this.selectedFeature && this.selectedFeature.id === 10008) {
      validation = this.validateSplashPages();
    }
    if (validation) {
      this.sectionNotify.emit(direction);
    }
  }
  selectChatEvent(chat: MainChat) {
    const countDownDate = new Date(this.trileEndDate).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (!this.trileEndDate || distance > 0) {
      this.layoutService.deselectChannel();

      this.authService.selectChat(chat.id);
      this.router.navigate(['/']);
    } else if (distance < 0) {
      this.uiService.addToastMessage('please subscripe on plan', false);
    }
    // const chatEdit: MainChat = {};
    // chatEdit.id = chat.id;
    // this.uiService.selectChat(chatEdit);
  }
  selectTalkToAdmin() {
    // this.uiService.messageViewed(this.targetChat);
    this.uiService._showNotifications.next(false);
    this.talkToAdminNotify.emit(this.selectedChatId);
  }
  openHelpWindow() {
    if (!this.helpWindowStatus) {
      this.openHelpWindowNotify.emit(true);
    } else {
      this.closeHelpWindowNotify.emit(true);
    }
  }

  logoutEvent() {
    this.logoutNotify.emit(true);
  }

  switchLanguage(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  TrackByFunction(index, chat: MainChat) {
    return chat.id;
  }
  validateSplashPages() {
    let validate = true;
    this.splashPages.forEach(res => {
      if (
        res.title === '' ||
        res.title === null ||
        !res.title ||
        res.desc === '' ||
        res.desc === null ||
        !res.desc ||
        this.splashFooter.title === '' ||
        this.splashFooter.title === null
      ) {
        validate = false;
      }
    });
    return validate;
  }
  toggleTooltip(status) {
    const appInfo: AppInfo = {};
    appInfo.help_link = ++status;
    this.appManagementService.updateAppInfo(appInfo);
    this.appManagementService.savePage(false);
  }

  showSubscriptionModal() {
    if (!this.cancelEnd && !this.mainPlanStatus) {
      this.dashboardService.setSection(
        UISections.BILLINGS,
        UIBillingSubRouts.PLANS
      );
    } else {
      this.dashboardService.setSection(UISections.BILLINGS);
    }
  }

  goToBillingScreen() {
    this.dashboardService.setSection(UISections.BILLINGS);
  }
  goToMyChannelScreen() {
    this.dashboardService.setSection(UISections.MYCH);
  }

  getRoleName(role) {
    switch (role) {
      case 1:
        return 'App Owner';
      default:
        return role
          ? this.membersRols.find(res => res.id === role).name
          : 'Moderator';
    }
  }

  openLink(link) {
    window.open(link);
  }

  showAndHideLangList(event) {
    this.showLangs = event;
    this._ref.detectChanges();
  }

  setTemplate() {
    let groupFormValues = { ...this.mainChat };

    delete groupFormValues['id'];

    const type = 'mainChannel';
    this.uiService.setTemplate(this.mainChat.id, type, {});
  }
}
