import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UiService } from 'src/app/core/ui.service';

import { MainChat } from '../../../../models/MainChat';
import { LayoutService } from './../../../+merchant/+layout/layout.service';

@Component({
  selector: 'app-profile-cell',
  templateUrl: './profile-cell.component.html',
  styleUrls: ['./profile-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCellComponent {
  @Input() chat: MainChat;
  @Input() hideDate = false;
  @Input() size = 44;
  @Input() emSize;
  @Input()
  timesFormat: string = 'MMM dd,y';
  @Input() isPostScreen = false;
  @Input() isLastItem = false;

  constructor(
    public layoutService: LayoutService,
    public uiService: UiService
  ) { }

  textTruncate(name) {
    if (name && name.length > 20) {
      name = name.substring(0, 19) + '...';
    }

    return name;
  }
}
