import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { InstantArticle } from 'src/models/InstantArticle';

import { MessageLinkPreviewStatus } from 'src/models/constants';

@Component({
  selector: 'app-preview-link',
  templateUrl: './preview-link.component.html',
  styleUrls: ['./preview-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewLinkComponent {
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  @Input() stylesComponent: number;
  @Input() instantArticle: InstantArticle;
  @Input() hideFooter = false;
  @Input() isMenuAttache = false;
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  constructor() {}

  getInstantArticle() {
    return this.instantArticle;
  }
  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }
}
