import { Action } from '@ngrx/store';
import { ChartObj } from 'src/models/ChartUI';

export const enum ChartActionTypes {
  GET_CHART_DATA = 'GET_CHART_DATA',
  RECEIVE_CHART_DATA = 'RECEIVE_CHART_DATA'
}

export class GetChartData implements Action {
  readonly type = ChartActionTypes.GET_CHART_DATA;
  constructor(
    public chat_id: string,
    public chart_type?: number,
    public interval?: number,
    public startDate?: string,
    public endDate?: string
  ) {}
}

export class ReceiveChartData implements Action {
  readonly type = ChartActionTypes.RECEIVE_CHART_DATA;
  constructor(
    public chart_type: number,
    public values: ChartObj[],
    public startDate: string,
    public endDate: string,
    public interval: number
  ) {}
}

export type ChartActions = ReceiveChartData;
