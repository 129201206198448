import { Store } from '@ngrx/store';
import * as CampaignUIActions from './campaignUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CampaignUIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    campaignListUI() {
        this._store.dispatch(new CampaignUIActions.ListCampaignUI());
    }
    createCampaignUI() {
        this._store.dispatch(new CampaignUIActions.CreateCampaignUI());
    }
    viewCampaignUI(selectedCampaign) {
        this._store.dispatch(new CampaignUIActions.ViewCampaignUI(selectedCampaign));
    }
    editCampaignUI(selectedCampaign) {
        this._store.dispatch(new CampaignUIActions.EditCampaignUI(selectedCampaign));
    }
    closeCreateCampaignUI() {
        this._store.dispatch(new CampaignUIActions.CloseCreateCampaignUI());
    }
    closeViewCampaignUI() {
        this._store.dispatch(new CampaignUIActions.CloseViewCampaignUI());
    }
    closeEditCampaignUI() {
        this._store.dispatch(new CampaignUIActions.CloseEditCampaignUI());
    }
    getNextCampaignPage() {
        this._store.dispatch(new CampaignUIActions.GetNextCampaignPage());
    }
    getPrevCampaignPage() {
        this._store.dispatch(new CampaignUIActions.GetPrevCampaignPage());
    }
    receivedCampaignHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new CampaignUIActions.ReceivedCampaignHistoryChunk(eop, sop, currentPage));
    }
    resetCampaignPaging() {
        this._store.dispatch(new CampaignUIActions.ResetCampaignPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new CampaignUIActions.SetStatusAsIdle());
    }
    reset() {
        this._store.dispatch(new CampaignUIActions.Reset());
    }
}
CampaignUIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignUIDispatchers_Factory() { return new CampaignUIDispatchers(i0.ɵɵinject(i1.Store)); }, token: CampaignUIDispatchers, providedIn: "root" });
