import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-link-btn',
  templateUrl: './link-btn.component.html',
  styleUrls: ['./link-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkBtnComponent {
  @Input() openSelf = false;
  @Input() href: string;
  @Input() isDefaultLink: boolean;
  @Output() btnClicked = new EventEmitter<boolean>();

  onClick() {
    this.btnClicked.emit(true);
  }
}
