import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  HostListener
} from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { AuthService } from 'src/app/core/auth.service';
import { StorageService } from 'src/app/core/storage.service';

import {
  APP_UPLOAD_TEMPLATES_CATEGORIES,
  IS_PRODUCTION
} from 'src/models/constants';

@Component({
  selector: 'app-startover-temp-view',
  templateUrl: './startover-temp-view.component.html',
  styleUrls: ['./startover-temp-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverTempViewComponent implements OnInit, OnChanges {
  @ViewChild('nav', { static: false }) slider: NgImageSliderComponent;
  @Input() temp: any;
  @Input() relatedTemp: any[];
  filteredrelatedTemp: any[];
  @Input() category: string;
  @Input() selectedCategory: string;
  @Input() tempId: string;
  @Input() choosenTemp: any;
  appCategories = APP_UPLOAD_TEMPLATES_CATEGORIES;
  @Output() startWith = new EventEmitter<any>();
  @Output() backNotfiy = new EventEmitter<boolean>();
  isProduction = IS_PRODUCTION;
  constructor(
    public authService: AuthService,
    public appManagementService: AppMgmtService,
    public localStorage: StorageService,
    public _ref: ChangeDetectorRef
  ) {}
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.back();
  }
  ngOnChanges() {
    if (this.selectedCategory === 'A') {
      this.filteredrelatedTemp = this.relatedTemp.filter(res => {
        if (res.category === this.category) {
          return res;
        }
      });
    } else {
      this.filteredrelatedTemp = this.relatedTemp;
    }
  }

  ngOnInit() {
    if (this.selectedCategory === 'A') {
      this.filteredrelatedTemp = this.relatedTemp.filter(res => {
        if (res.category === this.category) {
          return res;
        }
      });
    } else {
      this.filteredrelatedTemp = this.relatedTemp;
    }

    this.temp = JSON.parse(this.temp);
  }
  getCategory(id) {
    const category = this.appCategories.filter(cat => {
      if (cat.id === id) {
        return cat;
      }
    });

    return category[0].name;
  }

  startwithThisTempate() {
    // this.authService.updateProgressWeb('1', 2);

    this.localStorage.setRecord('tempId', this.tempId);
    this.startWith.emit(this.tempId);
    // this.appManagementService.goToSpecificPage(1);
  }
  changeTemplate(value) {
    this.temp = JSON.parse(value.template);
    this.choosenTemp = value;
    this._ref.detectChanges();
  }
  back() {
    this.backNotfiy.emit(true);
  }
}
