import { Store } from '@ngrx/store';
import * as AppClassActions from './appClass.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppClassDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateAppClass(appClassRef, appClassUpdatedProps) {
        this._store.dispatch(new AppClassActions.UpdateAppClass(appClassRef, appClassUpdatedProps));
    }
    notAllowedClassBgColors() {
        this._store.dispatch(new AppClassActions.NotAllowedCLassBgColor());
    }
    openClassSection(appClass) {
        this._store.dispatch(new AppClassActions.OpenClassSection(appClass));
    }
    closeClassSection(appClass) {
        this._store.dispatch(new AppClassActions.CloseClassSection(appClass));
    }
}
AppClassDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppClassDispatchers_Factory() { return new AppClassDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppClassDispatchers, providedIn: "root" });
