import { ChatMember, IChatMember } from 'src/models/IChatMember';
import { UserType } from 'src/models/constants';

export class ChatMemberMiddleware {
  public static initChatMember(
    chatMember: ChatMember,
    updateExisting?: boolean
  ) {
    const localChatMember: IChatMember = {};
    if (chatMember) {
      const {
        member_since,
        type,
        status,
        privileges,
        tags,
        role,
        chat,
        view,
        tester,
        user,
        msisdn
      } = chatMember;
      if (msisdn) {
        localChatMember.msisdn = msisdn;
      }
      if (member_since) {
        localChatMember.member_since = member_since;
      }
      if (type) {
        localChatMember.type = type;
        localChatMember.isAdmin = type === UserType.ADMIN ? true : false;
      } else if (!updateExisting) {
        localChatMember.type = UserType.MEMBER;
      }
      if (status) {
        localChatMember.status = status;
      }
      if (role) {
        localChatMember.role = role;
      }
      if (privileges) {
        localChatMember.privileges = privileges;
      }
      if (tags) {
        localChatMember.tags = tags;
      } else if (!updateExisting) {
        localChatMember.tags = [];
      }
      if (view !== null && view !== undefined) {
        localChatMember.view = view;
      }
      if (tester !== null && tester !== undefined) {
        localChatMember.tester = tester;
      }
      if (chat && chat.id) {
        localChatMember.chat_id = chat.id;
      }
      if (user && user.id) {
        localChatMember.user_id = user.id;
      }
    }
    return localChatMember;
  }

  public static isChatMemberExist(
    chatMembers: IChatMember[],
    localChatMember: IChatMember
  ) {
    const member = chatMembers.find(
      mbr =>
        mbr.user_id === localChatMember.user_id &&
        mbr.chat_id === localChatMember.chat_id
    );
    if (member) {
      return true;
    } else {
      return false;
    }
  }
}
