import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { FormValidatorsService } from 'src/app/validators/form-validators.service';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorsComponent {
  @Input() fieldErrors: ValidationErrors;
  @Input() fieldName: string;
  @Input() fieldType: string;
  @Input() usingCheckBox = false;

  constructor(private _validationService: FormValidatorsService) {}

  getError() {
    return this._validationService.getError('This field', this.fieldErrors);
  }
}
