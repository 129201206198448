import { AppPublishInfo } from 'src/models/AppPublishInfo';
import { Actions } from './appPublishing.actions';
import * as actions from './appPublishing.actions';
import { AppStoreStatus } from 'src/models/constants';

const INITIAL_STATE = {};
export function appPublishingReducer(
  state: AppPublishInfo = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppPublishingActionTypes.RECEIVE_APP_STORE_INFO: {
      return {
        ...state,
        ...action.appPublishInfo,
        apple_app_manager_setup:
          action.appPublishInfo.apple_account_status === AppStoreStatus.FINISHED
            ? 1
            : 0
      };
    }
    case actions.AppPublishingActionTypes.RECEIVE_APPS_SETUPS: {
      return { ...state, apps: [...action.appPublishInfo] };
    }
    case actions.AppPublishingActionTypes.RECEIVE_APP_SETUP_INFO: {
      return {
        ...state,
        apps: state.apps.map(app =>
          app.app_id === action.app.app_id ? { ...app, ...action.app } : app
        )
      };
    }
    case actions.AppPublishingActionTypes.SET_APPLE_STORE_INFO: {
      return {
        ...state,
        apple_account_status:
          state.apple_account_status === AppStoreStatus.PENDING
            ? state.apple_account_status
            : AppStoreStatus.VERIFYING
      };
    }
    case actions.AppPublishingActionTypes.SET_APP_INFO: {
      return {
        ...state,
        apps: state.apps.map(app =>
          app.app_id === action.appInfo.app_id
            ? {
                ...app,
                ...action.appInfo,
                apple_app_setup_status: action.os
                  ? AppStoreStatus.SETTING
                  : app.apple_app_setup_status
              }
            : app
        )
      };
    }
    case actions.AppPublishingActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
