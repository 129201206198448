import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { UISections } from 'src/models/constants';
import { ISection } from 'src/models/ISection';
import { UiService } from 'src/app/core/ui.service';

@Component({
  selector: 'app-empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptySectionComponent implements OnInit {
  @Input() section: ISection;
  @Input() pool = true;
  @Input() notPool = false;
  @Input() isMainScreen = false;
  @Input() previllage: any;
  @Output()
  newCreateNotify = new EventEmitter<any>();
  uiSections: typeof UISections = UISections;

  constructor(public uiService: UiService) {}
  ngOnInit() {
    if (this.notPool) {
      this.pool = false;
    }
  }

  get fillColor() {
    return {
      fill: '#949494',
      width: '60px',
      height: '60px'
    };
  }

  createNew() {
    this.newCreateNotify.emit();
  }
}
