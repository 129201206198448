import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as InstantActions from './instant.actions';

import { AppState } from 'src/models/AppState';
import { InstantArticle } from 'src/models/InstantArticle';

@Injectable({
  providedIn: 'root'
})
export class InstantDispatchers {
  constructor(private _store: Store<AppState>) {}

  instantArticleCreating(payload: InstantArticle) {
    this._store.dispatch(new InstantActions.InstantArticleCreating(payload));
  }
  instantArticleReceived(payload: InstantArticle) {
    this._store.dispatch(new InstantActions.InstantArticleReceived(payload));
  }

  linkPreviewDetailsReceived(msg: InstantArticle) {
    this._store.dispatch(new InstantActions.LinkPreviewDetailsReceived(msg));
  }
  setLinkPreviewStatus(msg: InstantArticle) {
    this._store.dispatch(new InstantActions.SetLinkPreviewStatus(msg));
  }
}
