import { GET_APP_MENUS_METHOD, GET_APP_MENU_METHOD } from './constants';

export interface AppMenu {
  id?: number;
  category?: string;
  value?: object;
  url?: string;
  soft_name?: string;
}

export class ListAppMenus {
  readonly method = GET_APP_MENUS_METHOD;
  constructor(public category: string) {}
}

export class GetAppMenu {
  readonly method = GET_APP_MENU_METHOD;
  constructor(public id: number) {}
}
