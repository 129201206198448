import {
  Injectable,
  ComponentRef,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef
} from '@angular/core';

import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionModalService {
  subscriptionModalComponentRef: ComponentRef<SubscriptionModalComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  private appendSubscriptionModalComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SubscriptionModalComponent
    );
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    componentRef.instance.onClose.subscribe(() => {
      this.removeSubscriptionModalComponentFromBody();
    });

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.subscriptionModalComponentRef = componentRef;
  }

  private removeSubscriptionModalComponentFromBody() {
    this.appRef.detachView(this.subscriptionModalComponentRef.hostView);
    this.subscriptionModalComponentRef.destroy();
  }

  public openSubscriptionModal() {
    this.appendSubscriptionModalComponentToBody();
  }
}
