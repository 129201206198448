import { ISection } from './ISection';
import { PrivilegesModules, PrivilegesName } from './privileges';
import { UISections, ChannelAppScreens } from './constants';
export const LANG_EN_TRANS = {
  nandbox: 'nandbox',
  reply: 'Reply',
  replyToAll: 'Replay to all',
  storeConsol: 'Store Consol',
  inbox: 'Inbox',
  sent: 'Sent',
  settings: 'Change Password',
  billingSettings: 'Billing',
  uploadTemps: 'Upload Templates',
  menus: 'Polls',
  bulkUpload: 'Bulk upload',
  botsManager: 'API & Integrations',
  tagsAndSubGroups: 'Tags & subgroups',
  manageTag: 'Tags & Membership',
  manageSubgroup: 'Groups',
  manageMenu: 'Manage Menus',
  manageEvents: 'Manage Events',
  instantArticle: 'Instant View',
  posts: 'Posts',
  messages: 'Messages',
  mediaCenter: 'Media Center',
  schedule: 'Schedule',
  compose: 'compose',
  members: 'Members',
  image: 'image',
  video: 'video',
  audio: 'audio',
  voice: 'voice',
  location: 'location',
  sticker: 'sticker',
  contact: 'contact',
  all: 'All',
  none: 'none',
  read: 'read',
  unread: 'unread',
  saved: 'Saved',
  save: 'Save',
  channelName: 'ChannelName',
  nameIsRequired: 'Name Is Required',
  nameMustBeAtLeast1CharactersLong: 'Name must be at least 1 characters long.',
  nameCannotBeMoreThan23CharactersLong:
    'Name cannot be more than 23 characters long.',
  description: 'Description',
  descriptionCannotBeMoreThan101CharactersLong:
    'Description cannot be more than 101 characters long.',
  category: 'Category',
  categoryIsRequired: 'Category Is Required',
  social: 'Social',
  entertainment: 'Entertainment',
  'Health&Finess': 'Health & Finess',
  lifestyle: 'Lifestyle',
  media: 'Media',
  'News&Magazines': 'News & Magazines',
  sports: 'Sports',
  education: 'Education',
  corporate: 'Corporate',
  goverment: 'Goverment',
  professional: 'Professional',
  'Stores&Retail': 'Stores & Retail',
  religion: 'Religion',
  portal: 'portal',
  back: 'Back',
  logout: 'logout',
  selectChannel: 'Select a Channel',
  youDoNotHaveBusinessChannels: 'You do not have a Business Channel',
  useNandboxOnYourPhoneToScanTheCode:
    'Use nandbox on your phone to scan the code',
  scanTheCode: 'Scan the code',
  reloadQRCode: 'Reload QR Code',
  keepMeSignedIn: 'Keep me signed in',
  openNandboxOnYourDevice: 'Open nandbox on your device',
  android: 'Android',
  ios: 'IOS',
  home: 'Home',
  manageYourApp: 'Design Your App',
  branding: 'Branding',
  splash: 'Splash',
  login: 'Login',
  profile: 'Profile',
  tabs: 'Tabs',
  tabsDetails: 'Tabs Details',
  navMenu: 'Side Menu',
  appRelesses: 'App Relesses',
  appActivation: 'App Activation',
  features: 'Home Menu',
  featuresDetails: 'Features',
  appSettings: 'App Settings',
  releases: 'Build & Publish',
  publish: 'Publish',
  activateYourApp: 'Activation',
  indexData: 'Index Data',
  manageYourChannel: 'Manage Your Channel',
  manageUsers: 'Users',
  usersManagement: 'User Management',
  tagingManagement: 'User Tagging',
  campaign: 'Campaigns',
  coupon: 'Coupons',
  scheduleAMessage: 'Schedule Message',
  myAccount: 'My account',
  createCoupon: 'Create Coupon',
  createCampaign: 'Create Campaign',
  createMenu: 'Create Menu',
  editCoupon: 'Edit Coupon',
  editCampaign: 'Edit Campaign',
  createInDate: 'Create in Date',
  title: 'Title',
  couponCode: 'Coupon Code',
  expireDate: 'Expire Date',
  share: 'Share',
  value: 'Value',
  status: 'Status',
  campaignName: 'Campaign Name',
  dateDuration: 'Date Duration',
  days: 'Days',
  specificHours: 'Specific Hours',
  scope: 'Scope',
  events: 'Events',
  booking: 'Booking',
  mStore: 'mStore',
  calendar: 'Calendar',
  timeZone: 'Time Zone',
  createEvent: 'Create Event'
};
export const HOME: ISection = {
  section: UISections.HOME,
  title: 'Dashboard',
  description: 'Welcome to your nandbox Dashboard',
  symbolImage: 'Dashboard',
  symbolColor: '#2ED473',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: false,
  coverColor: null,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.dashboard, // just to make sure that home listed
  subMenuSections: [UISections.HOME]
};

export const VAPP: ISection = {
  section: UISections.CHANNEL,
  title: LANG_EN_TRANS.manageYourChannel,
  description: 'Manage your channel.',
  symbolImage: 'icon-manage-channels',
  symbolColor: '#4150a8',
  symbolSideMenuColor: '#01a1ee',

  listOnHome: true,
  coverColor: `linear-gradient(145deg, #4150a8, #5e70d3)`,
  showSubMenu: false,
  subMenuScreen: null,
  hideOnSub: false,
  hideOnMain: true,
  privilegeName: PrivilegesName.listvirtualapp,
  subMenuSections: [UISections.CHANNEL],
  subMenu: [
    {
      section: UISections.CHANNEL,
      title: LANG_EN_TRANS.features,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.TABS
    },
    {
      section: UISections.CHANNEL,
      title: LANG_EN_TRANS.publish,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.PUBLISH
    }
  ]
};

export const APPSEC: ISection = {
  section: UISections.APP,
  title: 'Design Your App',
  description: 'Manage and edit your app.',
  symbolImage: 'icon-vector-design',
  symbolColor: '#2ED473',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(145deg, #2ED473, #32B8BE)`,
  showSubMenu: false,
  subMenuScreen: null,
  hideOnSub: true,
  hideOnMain: false,
  privilegeName: PrivilegesName.listapp,
  subMenuSections: [UISections.APP],
  subMenu: [
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.branding,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.UPLOAD_LOGO
    },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.splash,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.SPLASH
    // },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.login,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.LOGIN
    // },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.profile,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.PROFILE
    // },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.features,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.TABS
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.navMenu,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.NAV_MENU
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.appSettings,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.MAIN
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.releases,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.APP_RELEASES
    }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.publish,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.PUBLISH
    // }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.activateYourApp,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.ACTIVATION
    //  }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.indexData,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.SEARCH
    // }
  ]
};

export const POSTS: ISection = {
  section: UISections.POSTS,
  title: 'Posts',
  description: 'POST AND MESSAGES',
  symbolImage: 'icon-posts',
  symbolColor: '#43B2E9',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.post + ',' + PrivilegesModules.campaign,
  subMenuSections: [UISections.POSTS],
  emptyTitle: 'Create Your First Message',
  emptyDesc: 'Click here to create a new  post.',
  emptyPoolDesc: 'You can create a new post from the posts section.'
};

export const SCHEDULES: ISection = {
  section: UISections.SCHEDULE,
  title: 'Scheduled Posts',
  description: `SCHEDULE POST`,
  symbolImage: 'icon-schedules',
  symbolColor: '#C94D5C',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(134deg, #FB6B77, #A73546)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.post + ',' + PrivilegesModules.campaign,
  subMenuSections: [UISections.SCHEDULE],
  emptyTitle: 'Create Your First Scheduled Message',
  emptyDesc: 'Click here to create a new  schedule posts.',
  emptyPoolDesc:
    'You can create a new scheduled post from the scheduled posts section.'
};

export const EVENTS: ISection = {
  section: UISections.EVENTS,
  title: 'Events',
  description: 'EVENTS',
  symbolImage: 'icon-events',
  symbolColor: '#e67b7b',
  symbolColor2: '#E67B7B',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#fad8ee',
  listOnHome: true,
  coverColor: `#fbebeb`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.event,
  subMenuSections: [UISections.EVENTS],
  emptyTitle: 'Create Your First Event',
  emptyDesc: 'Click here to create a new event.',
  emptyPoolDesc: 'You can create a new event from the events section.'
};

export const CAMPAIGNS: ISection = {
  hasCategory: true,
  section: UISections.CAMPAIGNS,
  title: 'Campaigns',
  description: 'LOYALTY CAMPAIGNS',
  symbolImage: 'icon-campaigns',
  symbolColor: 'rgba(255, 255, 255, 0.7)',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#fbe4cc',
  listOnHome: true,
  coverColor: `linear-gradient(134deg, #FAD961, #E47A3E)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.campaign,
  subMenuSections: [UISections.CAMPAIGNS],
  emptyTitle: 'Create Your First Campaign',
  emptyDesc: 'Click here to create a new campaign.',
  emptyPoolDesc: 'You can add a new campaign from the capmaigns section.'
};

export const COUPONS: ISection = {
  hasCategory: true,
  section: UISections.COUPONS,
  title: 'Coupons',
  description: 'COUPONS',
  symbolImage: 'icon-coupons',
  symbolColor: 'rgba(255, 255, 255, 0.7)',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#dfd7fb',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #A393EB, #5153C2)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.campaign,
  subMenuSections: [UISections.COUPONS],
  emptyTitle: 'Create Your First Coupon',
  emptyDesc: 'Click here to create a new coupon.',
  emptyPoolDesc: 'You can create a new coupon from the coupons section.'
};

export const USERS: ISection = {
  section: UISections.USERS,
  title: 'Users',
  description: 'USERS',
  symbolImage: 'icon-user-1-1',
  symbolColor: '#59CDEE',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmember,
  subMenuSections: [UISections.USERS],
  emptyTitle: 'Empty!',
  emptyDesc: 'The list is empty.'
};
export const MAPSERVICE: ISection = {
  section: UISections.MAPSERVICE,
  title: 'Map Services',
  description: '',
  symbolImage: 'icon-location',
  symbolColor: '#D3D9E9',
  symbolColor2: '#97a7cc',
  symbolLightColor: '#ded9ff',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `#f1f3f7`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: [UISections.MAPSERVICE],
  emptyTitle: 'Empty!',
  emptyDesc: 'Click here to create a new Map Services.'
};
export const MAPROUTES: ISection = {
  section: UISections.MAPROUTES,
  title: 'Map Routes',
  description: '',
  symbolImage: 'icon-route',
  symbolColor: '#C1D9E1',
  symbolColor2: '#68a3b5',
  symbolLightColor: '#ace6ea',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `#e4eff2`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: [UISections.MAPROUTES],
  emptyTitle: 'Empty!',
  emptyDesc: 'Click here to create a new Map Route.'
};
export const TRIPS: ISection = {
  section: UISections.TRIPS,
  title: 'Trips',
  description: '',
  symbolImage: 'icon-road',
  symbolColor: '#D9D6D3',
  symbolColor2: '#9b918b',
  symbolLightColor: '#d8c5ce',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `#ecebe9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: [UISections.TRIPS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You can create a new Trips by clicking here.'
};
export const TRIPSHISTORY: ISection = {
  section: UISections.TRIPSHISTORY,
  title: 'Trip History',
  description: 'Displays the history of all the trips that your users made.',
  symbolImage: 'icon-check-list',
  symbolColor: '#42b480',
  symbolLightColor: '#42b480',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #18987e, #66cc81)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: [UISections.TRIPSHISTORY],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have Trips yet, Trips History is empty.'
};

export const TRIPNOTIFIERS: ISection = {
  section: UISections.TRIPNOTIFIERS,
  title: 'Trip Notifiers',
  description: '',
  symbolImage: 'icon-notification',
  symbolColor: '#478de5',
  symbolLightColor: '#478de5',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #18987e, #66cc81)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: [UISections.TRIPNOTIFIERS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have Passengers yet, Trip Notifiers History is empty.'
};

export const BLACKLISTS: ISection = {
  section: UISections.BLACKLIST,
  title: 'Blacklist',
  description: 'USER BLACKLIST',
  symbolImage: 'icon-blocked',
  symbolColor: '#db3c50',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #F38694, #EE0F2B)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.usermanager,
  subMenuSections: [UISections.BLACKLIST],
  emptyTitle: 'Empty!',
  emptyDesc: 'The blacklist is empty.'
};

export const TAGS: ISection = {
  section: UISections.TAGS,
  title: 'Tags',
  description: 'USER TAGS',
  symbolImage: 'icon-tag',
  symbolColor: '#3EABC3',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #2DECF6, #3EA9C2)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.usermanager,
  subMenuSections: [UISections.TAGS],
  emptyTitle: 'Empty!',
  emptyDesc: 'The list is empty.',
  emptyPoolDesc: 'You can create a new tag from the tags section.'
};

export const SUBGROUPS: ISection = {
  section: UISections.SUBGROUP,
  title: 'Chat Groups',
  description: 'CHAT GROUPS',
  symbolImage: 'icon-groups',
  symbolColor: '#51a6d5',
  symbolColor2: '#51A6D5',
  symbolLightColor: '#cae7f6',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `#e4f2fe`,
  showSubMenu: false,
  hideOnSub: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.chatgroup,

  subMenuSections: [UISections.SUBGROUP],
  emptyTitle: 'No Groups Found',
  emptyDesc: 'Click here to create a new chat group',
  emptyPoolDesc: 'You can create a new group from the groups section.'
};

export const SUBCHANNELS: ISection = {
  section: UISections.SUBCHANNEL,
  title: 'Channels',
  description: 'CHANNELS',
  symbolImage: 'icon-channel',
  symbolColor: '#998dd1',
  symbolColor2: '#998DD1',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#ebddf5',
  listOnHome: true,
  coverColor: `#efedf8`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.channel,
  subMenuSections: [UISections.SUBCHANNEL],
  emptyTitle: 'Create Your First Channel',
  emptyDesc: 'Click here to create a new channel.',
  emptyPoolDesc: 'You can create a new channel from the channels section.'
};

export const VIRTUAL_APPS: ISection = {
  section: UISections.VIRTUAL_APPS,
  title: 'Virtual Apps',
  description: 'Create and manage your Virtual Apps.',
  symbolImage: 'icon-virtual-app',
  symbolColor: '#388cb8',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#cae7f6',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #388cb8, #5bb2df)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.vapp,
  subMenuSections: [UISections.VIRTUAL_APPS],
  emptyTitle: 'No Virtual Apps Found',
  emptyDesc: 'You can create a new Virtual App by clicking here.',
  emptyPoolDesc:
    'You can create a new virtual app from the virtual app section.'
};

export const MENUS: ISection = {
  section: UISections.MENUS,
  title: 'Polls',
  description: 'CHAT POLLS',
  symbolImage: 'icon-menu',
  symbolColor: '#F7D9BD',
  symbolColor2: '#e9af78',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#fadfdf',
  listOnHome: true,
  coverColor: `#fff1e4`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.post,
  subMenuSections: [UISections.MENUS],
  emptyTitle: 'Create Your First Poll',
  emptyDesc: 'Click here to create a new poll or contest.',
  emptyPoolDesc: 'You can create a new poll from the polls section.'
};

export const BOOKINGS: ISection = {
  section: UISections.BOOKING,
  title: 'Bookings',
  description: 'BOOKINGS',
  coverColor: `#e5eeff`,
  symbolImage: 'icon-booking',
  symbolColor: '#C5D2F3',
  symbolColor2: '#6684ce',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#d1e4f8',
  listOnHome: true,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.booking,
  subMenuSections: [UISections.BOOKING],
  emptyTitle: 'No Bookings Found',
  emptyDesc: 'Click here to create a new booking',
  emptyPoolDesc: 'You can create a new booking from the bookings section.'
};

export const CALENDERS: ISection = {
  section: UISections.CALENDAR,
  title: 'Calendars',
  description: 'CALENDARS',
  symbolImage: 'icon-calendar',
  symbolColor: '#D0D2DF',
  symbolColor2: '#565F90',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#cadbf2',
  listOnHome: true,
  coverColor: `#f2f3f6`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.event,
  subMenuSections: [UISections.CALENDAR],
  emptyTitle: 'Create Your First Calendar',
  emptyDesc: 'Click here to create a new calendar.',
  emptyPoolDesc: 'You can create a new calendar from calendars section.'
};

export const BOTS: ISection = {
  hasCategory: true,
  section: UISections.BOTS,
  title: 'BOT & API',
  description: 'BOT AND API',
  symbolImage: 'icon-bot',
  symbolColor: '#7073d6',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#d2d2f4',
  listOnHome: true,
  coverColor: `#efeffa`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.api,
  subMenuSections: [UISections.BOTS],
  emptyTitle: 'Create Your First Bot',
  emptyDesc: 'Click here to create a new bot.',
  emptyPoolDesc: 'You can create a new bot from the bots manager section.'
};

export const CUSTOMERCARE: ISection = {
  hasCategory: true,
  section: UISections.CUSTOMERCARE,
  title: 'Customer Care',
  description: 'MANUAL ORDERS',
  symbolImage: 'icon-value',
  symbolColor: '#4cd39a',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#bff3dd',
  listOnHome: true,
  coverColor: `linear-gradient(139deg, #69e396 14%, #3dbaf6 85%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,

  subMenuSections: [UISections.CUSTOMERCARE],
  emptyTitle: 'Purchase Bundle',
  emptyDesc: 'Purchase a Bundle for a user by clicking here.',
  emptyPoolDesc: 'Purchase a Bundle for a user by clicking here.'
};

export const CUSTOMERCARE_SUBSCRIPTION_ACTION: ISection = {
  hasCategory: true,
  section: UISections.CUSTOMERCARE,
  title: 'Serve your customers directly.',
  description: 'Manage your bots',
  symbolImage: 'icon-value',
  symbolColor: '#4cd39a',
  symbolLightColor: '#bff3dd',
  listOnHome: true,
  coverColor: `linear-gradient(139deg, #69e396 14%, #3dbaf6 85%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.CUSTOMERCARE],
  emptyTitle: 'Purchase Subscription',
  emptyDesc: 'Purchase a Subscription for a user by clicking here.',
  emptyPoolDesc: 'Purchase a Subscription for a user by clicking here.'
};

export const BULKUPLOAD: ISection = {
  hasCategory: true,
  section: UISections.BULKUPLOAD,
  title: 'Bulk Upload',
  description: 'BULK UPLOAD',
  symbolImage: 'icon-bulk',
  symbolColor: '#62C3B8',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(138deg, #7EEEE1, #28AC9C)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbulk,
  subMenuSections: [UISections.BULKUPLOAD]
};

export const SETTINGS: ISection = {
  hasCategory: true,
  section: UISections.SETTINGS,
  title: 'Change Password',
  description: 'CHANGE PASSWORD',
  symbolImage: 'icon-gear',
  symbolColor: '#2552A7',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #6078EA, #1D419D)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listsettings,
  subMenuSections: [UISections.SETTINGS]
};

export const BILLINGS: ISection = {
  hasCategory: true,
  section: UISections.BILLINGS,
  title: 'Billing',
  description: 'ACCOUNT BILLING',
  symbolImage: 'icon-money-billing',
  symbolColor: '#bd3b62',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #fdaeac, #bd3b62)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbilling,
  subMenuSections: [UISections.BILLINGS]
};

export const TRANSACTIONS: ISection = {
  hasCategory: true,
  section: UISections.TRANSACTIONS,
  title: 'Orders',
  description: 'PURCHASE ORDERS',
  symbolImage: 'icon-payment',
  symbolColor: '#fba5a5',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #f5d6de, #fba5a5)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.TRANSACTIONS],
  emptyTitle: 'No Transactions Found',
  emptyDesc: ''
};

export const BUNDLES: ISection = {
  hasCategory: true,
  section: UISections.BUNDLES,
  title: 'Bundles',
  description: 'PRODUCT BUNDLES',
  symbolImage: 'icon-bundle',
  symbolColor: '#E4D3E4',
  symbolColor2: '#ab73a9',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#f4d8ed',
  listOnHome: true,
  coverColor: `#f3ebf3`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.BUNDLES],
  emptyTitle: 'Create Your First Bundle',
  emptyDesc: 'Click here to create a new bundle',
  emptyPoolDesc: 'You can create a new bundle from the bundles section.'
};

export const WHITELIST: ISection = {
  hasCategory: true,
  section: UISections.WHITELIST,
  title: 'Whitelist',
  description: 'USER WHITELIST',
  symbolImage: 'icon-Whitelist',
  symbolColor: '#5ab9e1',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#f4d8ed',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #5ab9e1, #5e70d3)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.usermanager,
  subMenuSections: [UISections.WHITELIST],
  emptyTitle: 'Empty!',
  emptyDesc: 'The whitelist is empty.'
};

export const PACKAGES: ISection = {
  hasCategory: true,
  section: UISections.PACKAGES,
  title: 'Multi-tiered Plans',
  description: 'PRODUCT PACKAGES',
  symbolImage: 'icon-package',
  symbolColor: '#D1D0DA',
  symbolColor2: '#6f698e',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#d4d7f5',
  listOnHome: true,
  coverColor: `#f0f0f3`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.PACKAGES],
  emptyTitle: 'Create Your First Plan',
  emptyDesc: ' Click here to create a new multi-tiered plan.',
  emptyPoolDesc:
    'You can create a new plan from the multi-tiered plans section.'
};

export const PRODUCTS: ISection = {
  hasCategory: true,
  section: UISections.PRODUCTS,
  title: 'Products',
  description: 'PRODUCTS',
  symbolImage: 'icon-product',
  symbolColor: '#54c6d2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `#E8F7F9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.PRODUCTS],
  emptyTitle: 'Create Your First Product',
  emptyDesc: 'Click here to create a new product.',
  emptyPoolDesc: 'You can create a new product from the products section.'
};

export const PRODUCT: ISection = {
  hasCategory: true,
  section: UISections.PRODUCT,
  title: 'Product',
  description: 'PRODUCTS',
  symbolImage: 'icon-product-box',
  symbolColor: '#54c6d2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `#E8F7F9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.PRODUCT],
  emptyTitle: 'Create Your First Product',
  emptyDesc: 'Create Your First Product',
  emptyPoolDesc: 'You can create a new product from the products section.'
};

export const WEBSITTINGS: ISection = {
  hasCategory: true,
  section: UISections.WEBSITTINGS,
  title: 'Web Settings',
  description: '',
  symbolImage: 'icon-display-settings',
  symbolColor: '#54c6d2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `#E8F7F9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbulk,
  subMenuSections: [UISections.WEBSITTINGS]
};

export const COLLECTIONS: ISection = {
  hasCategory: true,
  section: UISections.COLLECTIONS,
  title: 'Collections',
  description: 'COLLECTIONS',
  symbolImage: 'icon-collections',
  symbolColor: '#54c6d2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `#E8F7F9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.COLLECTIONS],
  emptyTitle: 'Create Your First Collection',
  emptyDesc: 'Create Your First Collection',
  emptyPoolDesc: 'EMPTYPOOLDESCCOLLECTIONS'
};

export const MSTORE: ISection = {
  hasCategory: true,
  section: UISections.MSTORE,
  title: 'mStore',
  description: 'MOBILE STORES',
  symbolImage: 'icon-mstore',
  symbolColor: '#F7CFD7',
  symbolColor2: '#de657e',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#ffd7d8',
  listOnHome: true,
  coverColor: `#fee9ed`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.MSTORE],
  emptyTitle: 'Create Your First Store',
  emptyDesc: 'Click here to create a new m-Store.',
  emptyPoolDesc: 'You can add a new mStore from mStore section.'
};

export const MOBILESTORE: ISection = {
  hasCategory: true,
  section: UISections.MOBILESTORE,
  title: 'Mobile Store',
  description: 'MOBILE STORES',
  symbolImage: 'icon-mobile-store',
  symbolColor: '#F7CFD7',
  symbolColor2: '#de657e',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#ffd7d8',
  listOnHome: true,
  coverColor: `#fee9ed`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.MOBILESTORE],
  emptyTitle: 'Create Your First Store',
  emptyDesc: 'Click here to create a new m-Store.',
  emptyPoolDesc: 'You can add a new mStore from mStore section.'
};

export const SUBMENU: ISection = {
  hasCategory: true,
  section: UISections.SUBMENU,
  title: 'Sub Menu',
  description: 'Create new sub menu.',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmstore,
  subMenuSections: [UISections.SUBMENU],
  emptyTitle: 'No Sub menu Found',
  emptyDesc: 'You can create a new Sub menu by clicking here.',
  emptyPoolDesc:
    'You can add a new Sub menu from the add new field in Sub menu section.'
};

export const STOREMENU: ISection = {
  hasCategory: true,
  section: UISections.STOREMENU,
  title: 'Product Add-Ons',
  description: 'PRODUCT ADD-ONS',
  symbolImage: 'icon-adds-on',
  symbolColor: '#CDD3E8',
  symbolColor2: '#6777b8',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#abdef1',
  listOnHome: true,
  coverColor: `#eaecf5`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: [UISections.STOREMENU],
  emptyTitle: 'Create Your First Product Add-on',
  emptyDesc: 'Click here to create a new product add-on.',
  emptyPoolDesc:
    'You can create a new product add-on from the product add-on section.'
};

export const MEDIA_CENTER: ISection = {
  section: UISections.MEDIACENTER,
  title: 'Media Center',
  description: 'Create and manage your media.',
  symbolImage: 'icon-gallery',
  symbolColor: '#C65373',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmessage,
  subMenuSections: [UISections.MEDIACENTER],
  emptyTitle: 'Create Your First Media',
  emptyDesc: '',
  emptyPoolDesc: 'You can add new Media from the add new field.'
};

/** Dev only Section */
export const UPLOAD_TEMPS: ISection = {
  section: UISections.UPLOADTEMPS,
  title: 'Upload Templates',
  description: 'Upload you app templates.',
  symbolImage: 'icon-templates',
  symbolColor: '#957EAC',
  listOnHome: false,
  coverColor: `linear-gradient(135deg, #FEC080, #EA6671)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtemplate,
  subMenuSections: [UISections.UPLOADTEMPS]
};

export const PAYMENT_MENU: ISection[] = [
  // MSTORE,
  MOBILESTORE,
  COLLECTIONS,
  // PRODUCTS,
  // BUNDLES,
  // PACKAGES,
  // STOREMENU,

  PRODUCT,
  TRANSACTIONS
  // PRODUCT,
  // COLLECTIONS
];
export const USERS_MANAG: ISection[] = [WHITELIST, BLACKLISTS, USERS, TAGS];

/**
 * Menu Categories
 */
export const PRODUCT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Store Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,

  privilegeModule: PrivilegesModules.mstore,
  subMenuSections: []
};
export const USERS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Users Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.usermanager,
  subMenuSections: []
};
export const CONTENT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Content Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule:
    PrivilegesModules.post +
    ',' +
    PrivilegesModules.chatgroup +
    ',' +
    PrivilegesModules.vapp,
  subMenuSections: []
};

export const EVENT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Event Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule:
    PrivilegesModules.event +
    ',' +
    PrivilegesModules.booking +
    ',' +
    PrivilegesModules.calendar,
  subMenuSections: []
};
export const MSTORE_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'mStore Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmstore,
  subMenuSections: []
};
export const ORDER_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Payments Orders',
  description: "Monitor your clients' orders and transactions.",
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};
export const LOYALTY_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Loyalty Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.campaign,
  subMenuSections: []
};
export const OTHERS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Settings',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listcustomercare +
    ',' +
    PrivilegesName.listbot +
    ',' +
    PrivilegesName.listbulk,

  subMenuSections: []
};
export const MAP_TRACKING: ISection = {
  isCategory: true,
  section: null,
  title: 'Maps',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.maptracking,
  subMenuSections: []
};
export const SETTINGS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Settings',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: true,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listsettings + ',' + PrivilegesName.listbilling,
  subMenuSections: []
};

export const LOCATIONS: ISection = {
  section: UISections.LOCATIONS,
  title: 'Locations',
  description: 'Control your Location settings.',
  symbolImage: 'icon-map-marker',
  symbolColor: '#4fb8eb',
  symbolColor2: '#4fb8eb',
  symbolLightColor: '#cadbf2',
  symbolSideMenuColor: '#01a1ee',
  listOnHome: true,
  coverColor: `#DCF1FB`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeModule: PrivilegesModules.mapsearch,
  subMenuSections: [UISections.LOCATIONS],
  emptyTitle: 'Create Your First Location',
  emptyDesc: 'You can create a new Location by clicking here.',
  emptyPoolDesc:
    'You can add a new location from the add new field in location section.'
};
export const MYCH: ISection = {
  section: UISections.MYCH,
  title: 'My Channel',
  description: 'Manage your sub channels.',
  symbolImage: 'icon-My_Channel_24dp',
  symbolColor: '#b27cb2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#ebddf5',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #c990c9, #381038)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmychannel + ',' + PrivilegesName.editmychannel,
  subMenuSections: [UISections.MYCH]
};
/**************************************************************************** */

export const M_COMMERCE_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'mCommerce',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmember +
    ',' +
    PrivilegesName.listproduct +
    ',' +
    PrivilegesName.listbundle +
    ',' +
    PrivilegesName.listpackage +
    ',' +
    PrivilegesName.listmstore +
    ',' +
    PrivilegesName.listproductaddons,
  subMenuSections: []
};

export const M_COMMERCE_USER: ISection = {
  isCategory: true,
  section: null,
  title: 'Users',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmember,
  subMenuSections: []
};

export const M_COMMERCE_PRODUCTS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Products',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#BAE8EC',
  symbolColor2: '#54c6d2',
  symbolSideMenuColor: '#01a1ee',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `#E8F7F9`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listproduct +
    ',' +
    PrivilegesName.listbundle +
    ',' +
    PrivilegesName.listpackage +
    ',' +
    PrivilegesName.listmstore +
    ',' +
    PrivilegesName.listproductaddons,
  subMenuSections: []
};

export const M_COMMERCE_ORDER_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Order Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};

export const M_COMMERCE_INTEGRATION_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Integration',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};

export const M_COMMERCE_MARKETINGS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Marketings',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcampaign + ',' + PrivilegesName.listcoupon,
  subMenuSections: []
};

export const M_COMMERCE_SUPPORT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Support',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: []
};

export const M_COMMERCE_GENERAL_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'General',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: []
};

export const SIDE_MENU: ISection[] = [
  VAPP,
  APPSEC,
  BOTS,
  HOME,
  MYCH,
  CONTENT_MANAGEMENT,
  POSTS,
  SCHEDULES,
  MENUS,
  SUBCHANNELS,

  SUBGROUPS,
  // VIRTUAL_APPS,
  EVENT_MANAGEMENT,
  BOOKINGS,
  EVENTS,
  CALENDERS,
  PRODUCT_MANAGEMENT,
  ...PAYMENT_MENU,
  // MSTORE_MANAGEMENT,
  // MSTORE,
  // ORDER_MANAGEMENT,
  // TRANSACTIONS,
  LOYALTY_MANAGEMENT,
  CAMPAIGNS,
  COUPONS,
  // MAP_TRACKING,
  // MAPSERVICE,
  // MAPROUTES,
  // TRIPS,
  // TRIPNOTIFIERS,
  LOCATIONS,
  // TRIPSHISTORY,
  USERS_MANAGEMENT,
  ...USERS_MANAG,
  OTHERS_MANAGEMENT,
  // CUSTOMERCARE,
  // BULKUPLOAD,
  WEBSITTINGS
  // SETTINGS_MANAGEMENT,
  // SETTINGS,
  // BILLINGS,
  // UPLOAD_TEMPS
];

export const DASHBOARD_SELECTION_MENU: ISection[] = [
  POSTS,
  SUBCHANNELS,
  SUBGROUPS,
  BOOKINGS,
  EVENTS,
  CALENDERS,
  MSTORE,
  PRODUCTS,
  BUNDLES,
  // PACKAGES,
  STOREMENU,
  USERS,
  BLACKLISTS,
  WHITELIST,
  MAPSERVICE,
  MAPROUTES,
  TRIPS
];

export const SIDE_MENU_TEMP: ISection[] = [
  APPSEC,
  M_COMMERCE_MANAGEMENT,
  M_COMMERCE_USER,
  USERS,
  M_COMMERCE_PRODUCTS_MANAGEMENT,
  PRODUCTS,
  BUNDLES,
  STOREMENU,
  // PACKAGES,
  MSTORE,
  M_COMMERCE_ORDER_MANAGEMENT,
  M_COMMERCE_INTEGRATION_MANAGEMENT,
  TRANSACTIONS,
  M_COMMERCE_MARKETINGS_MANAGEMENT,
  CAMPAIGNS,
  COUPONS,
  M_COMMERCE_SUPPORT_MANAGEMENT,
  CUSTOMERCARE,
  M_COMMERCE_GENERAL_MANAGEMENT,
  POSTS,
  SCHEDULES,
  CALENDERS,
  LOCATIONS,
  MYCH,
  MENUS,
  SUBGROUPS,
  SUBCHANNELS,
  VIRTUAL_APPS,
  EVENTS,
  BOOKINGS,
  TAGS,
  BOTS,
  BULKUPLOAD,
  WEBSITTINGS
  // BILLINGS,
  // SETTINGS,
  // SETTINGS_MANAGEMENT
  //  UPLOAD_TEMPS
];
