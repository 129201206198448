import { Action } from '@ngrx/store';
import { DashboardUI, WidgetUI } from 'src/models/summaryUI';

export const enum SummaryUIActionTypes {
  UI_SET_DASHBOARD = 'UI_SET_DASHBOARD',
  UI_RECEIVED_DASHBOARD_HISTORY_CHUNK = 'UI_RECEIVED_DASHBOARD_HISTORY_CHUNK',
  UI_UPDATE_DASHBOARD_LIST = 'UI_UPDATE_DASHBOARD_LIST',
  UI_REORDER_DASHBOARD_LIST = 'UI_REORDER_DASHBOARD_LIST',
  UI_CREATE_NEW_WIDGET = 'UI_CREATE_NEW_WIDGET',
  UI_UPDATE_WIDGET = 'UI_UPDATE_WIDGET',
  UI_GET_USER_DASHBOARD_LIST = 'UI_GET_USER_DASHBOARD_LIST',
  UI_REMOVE_WIDGET_FROM_LIST = 'UI_REMOVE_WIDGET_FROM_LIST',
  UI_SAVE_DEFAULTED_DASHBOARD_LIST = 'UI_SAVE_DEFAULTED_DASHBOARD_LIST',
  RESET = 'RESET'
}

export class SetDashboard implements Action {
  readonly type = SummaryUIActionTypes.UI_SET_DASHBOARD;
  constructor(public dashboard: DashboardUI) {}
}

export class CreateNewWidget implements Action {
  readonly type = SummaryUIActionTypes.UI_CREATE_NEW_WIDGET;
  constructor(public widget: WidgetUI) {}
}
export class UpdateWidget implements Action {
  readonly type = SummaryUIActionTypes.UI_UPDATE_WIDGET;
  constructor(public widget: WidgetUI) {}
}

export class GetUserDashboardList implements Action {
  readonly type = SummaryUIActionTypes.UI_GET_USER_DASHBOARD_LIST;
}

export class ReceivedDashboardHistoryChunk implements Action {
  readonly type = SummaryUIActionTypes.UI_RECEIVED_DASHBOARD_HISTORY_CHUNK;
  constructor(public dashboard: DashboardUI) {}
}

export class ReOrderList implements Action {
  readonly type = SummaryUIActionTypes.UI_REORDER_DASHBOARD_LIST;
  constructor(public widgets: WidgetUI[]) {}
}

export class UpdateDashboardList implements Action {
  readonly type = SummaryUIActionTypes.UI_UPDATE_DASHBOARD_LIST;
  constructor() {}
}

export class RemoveWidget implements Action {
  readonly type = SummaryUIActionTypes.UI_REMOVE_WIDGET_FROM_LIST;
  constructor(public widget: WidgetUI) {}
}

export class SaveDefaultedDashboardList implements Action {
  readonly type = SummaryUIActionTypes.UI_SAVE_DEFAULTED_DASHBOARD_LIST;
}
export class Rest implements Action {
  readonly type = SummaryUIActionTypes.RESET;
}

export type Actions =
  | ReceivedDashboardHistoryChunk
  | CreateNewWidget
  | ReOrderList
  | UpdateWidget
  | RemoveWidget
  | Rest;
