/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-cell-actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./post-cell-actions.component";
import * as i6 from "../../core/ui.service";
var styles_PostCellActionsComponent = [i0.styles];
var RenderType_PostCellActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostCellActionsComponent, data: {} });
export { RenderType_PostCellActionsComponent as RenderType_PostCellActionsComponent };
function View_PostCellActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "dot-unread"]], null, null, null, null, null))], null, null); }
function View_PostCellActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "actions-reply"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-reply-message"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numberOfUnreadReplies; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PostCellActionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "menu-item clickable-cursor item"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "menu-link"]], [[8, "href", 4], [8, "download", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-menu menu-icon-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-download-for-post"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Download "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; var currVal_1 = _co.mediaId; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PostCellActionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "menu-item clickable-cursor item"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.statsEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-menu menu-icon-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-extra-icons.svg#icon-data_usage"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" open chart "]))], null, null); }
function View_PostCellActionsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "menu-item clickable-cursor item item-delete"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recallEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-trash"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete "]))], null, null); }
function View_PostCellActionsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "menu-item clickable-cursor item item-delete"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Set Template "]))], null, null); }
function View_PostCellActionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ul", [["class", "dropdown-menu dropdown-menu-right"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [["class", "menu-item clickable-cursor item"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-menu menu-icon-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-open-in-new-widow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Open Post "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PostCellActionsComponent_7)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDownloadable; _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.isMenu && (_co.projectName === _co.merchant)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isAdmin; _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.uiService.getEnableSetTemplateBtn$)); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_PostCellActionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 7, "div", [["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "onShown"], [null, "onHidden"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onShown" === en)) {
        var pd_0 = (_co.toggleMenu(true) !== false);
        ad = (pd_0 && ad);
    } if (("onHidden" === en)) {
        var pd_1 = (_co.toggleMenu(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(5, 212992, null, 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], null, { onShown: "onShown", onHidden: "onHidden" }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "menu-icon"], ["dropdownToggle", ""]], [[2, "drop-down", null], [1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-ellipsis-h"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.numberOfReplies; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 5).dropup; var currVal_2 = i1.ɵnov(_v, 5).isOpen; var currVal_3 = (i1.ɵnov(_v, 5).isOpen && i1.ɵnov(_v, 5).isBs4); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.toggleMenuStatus; var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 7).isDisabled; var currVal_7 = i1.ɵnov(_v, 7).isOpen; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_PostCellActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-cell-actions", [], null, null, null, View_PostCellActionsComponent_0, RenderType_PostCellActionsComponent)), i1.ɵdid(1, 114688, null, 0, i5.PostCellActionsComponent, [i6.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostCellActionsComponentNgFactory = i1.ɵccf("app-post-cell-actions", i5.PostCellActionsComponent, View_PostCellActionsComponent_Host_0, { media: "media", mediaId: "mediaId", isAdmin: "isAdmin", loggedInIsSender: "loggedInIsSender", isDownloadable: "isDownloadable", isMenu: "isMenu", isPostScreen: "isPostScreen", numberOfReplies: "numberOfReplies", numberOfUnreadReplies: "numberOfUnreadReplies" }, { recallNotfiy: "recallNotfiy", repostNotfiy: "repostNotfiy", editNotfiy: "editNotfiy", statsNotfiy: "statsNotfiy", selectNotfiy: "selectNotfiy", setTemplateNotfiy: "setTemplateNotfiy" }, []);
export { PostCellActionsComponentNgFactory as PostCellActionsComponentNgFactory };
