<input
  #circleInputId
  class="circle-input"
  [type]="type"
  maxlength="1"
  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
  (change)="onChange(circleInputId?.value)"
/>

<!-- min="0"
  max="1" -->
