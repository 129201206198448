/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact-cell.component";
var styles_ContactCellComponent = [i0.styles];
var RenderType_ContactCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactCellComponent, data: {} });
export { RenderType_ContactCellComponent as RenderType_ContactCellComponent };
export function View_ContactCellComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "avtar"], ["src", "./assets/img/AvatarBlank60pt@3x.png"], ["width", "48"]], [[8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "core copyable-item text-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.phoneName; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.phoneName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.phoneNumber; _ck(_v, 6, 0, currVal_2); }); }
export function View_ContactCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-cell", [], null, null, null, View_ContactCellComponent_0, RenderType_ContactCellComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContactCellComponent, [], null, null)], null, null); }
var ContactCellComponentNgFactory = i1.ɵccf("app-contact-cell", i2.ContactCellComponent, View_ContactCellComponent_Host_0, { phoneNumber: "phoneNumber", phoneName: "phoneName" }, {}, []);
export { ContactCellComponentNgFactory as ContactCellComponentNgFactory };
