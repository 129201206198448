import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import * as AuthActions from './auth.actions';

import { AuthDispatchers } from './auth.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MessageDispatchers } from '../messages/message.dispatchers';
import { SettingsService } from 'src/app/+merchant/+dashboard/settings/settings.service';

import * as AuthMethods from 'src/models/IAuth';
import * as ChatMethods from 'src/models/IChat';
import * as TagMethods from 'src/models/ITag';
import * as ChatMembersMethods from 'src/models/IChatMember';
import { AppState } from 'src/models/AppState';
import {
  APP_MENU_CATEGORIES,
  CHANNEL,
  CHANNEL_APP_SEARCH_CATEGORIES
} from 'src/models/constants';
import { RequestMyBots, GetBotTemplates } from 'src/models/IProfile';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { RequestAppChannelData } from 'src/models/ChannelAppConfig';
import { MainChat } from 'src/models/MainChat';
import { GetAppsMethod } from 'src/models/MultipleApps';
import {
  GetAppsSetupMethod,
  GetAppStoreMethod
} from 'src/models/AppPublishInfo';
import {
  GetMyPagesListRequest,
  GetInstantPagesRequest
} from 'src/models/MyPageConfig';

import * as isEqual from 'lodash.isequal';
import { AuthService } from 'src/app/core/auth.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { RegistrationService } from 'src/app/+merchant/+registration/registration.service';
import * as MessagesMethods from 'src/models/IMessage';
import { ListAppMenus } from 'src/models/AppMenu';
import { ISAUTH } from 'src/models/constants';
import { StorageService } from 'src/app/core/storage.service';
import { SocketService } from 'src/app/network/services/socket.service';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  requestNewQRcode = this.actions$.pipe(
    ofType(AuthActions.REQUEST_NEW_QRCODE),
    map(p => {
      this._socketGateway.sendSocketMessage(new AuthMethods.GetQRCode());
    })
  );

  @Effect({ dispatch: false })
  requestQRcode = this.actions$.pipe(
    ofType(AuthActions.REQUEST_QRCODE),
    map(p => {
      this._socketGateway.sendSocketMessage(new AuthMethods.GetQRCode());
    })
  );

  @Effect({ dispatch: false })
  reopenNewConnection = this.actions$.pipe(
    ofType(AuthActions.REOPEN_NEW_CONNECTION),
    map(p => {
      this._socketGateway.openNewConnection();
    })
  );

  @Effect({ dispatch: false })
  rcvdMultiLogin = this.actions$.pipe(
    ofType(AuthActions.RECIVE_MULTI_LOGIN),
    map(p => {
      this._socketGateway.closeCurrentSocket();
    })
  );

  @Effect({ dispatch: false })
  getChatData = this.actions$.pipe(
    ofType(AuthActions.GET_CHAT_DATA),
    map((action: AuthActions.GetChatData) => {
      this._socketGateway.sendSocketMessage(
        new ChatMethods.GetChatTemplate(action.chat_id)
      );
    })
  );

  @Effect({ dispatch: false })
  rcvdChatList = this.actions$.pipe(
    ofType(AuthActions.RECIVE_AUTH_CHAT_LIST),
    withLatestFrom(
      this.store$
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged())
    ),
    map(([val, chatID]) => {
      const action = val as AuthActions.ReciveAuthChatList;
      // IF user already login before on another channel
      if (chatID) {
        // this._socketGateway.sendSocketMessage(
        //   new AuthMethods.SendSelectChat(chatID)
        // );
      } else if (action.payload) {
        // Get Chat Info
        const upgradedChannels = action.payload.filter(
          chat => chat.type === CHANNEL
        );
        // The Token auth OK does not return a ChatId in case of user login by QR as the message comes from the Home server not the business Server
        // Therefore there is no way to return the ChatId (defalut selected chat) as workaround a GetChat is called to return selected ChatId.
        // no chatId

        if (
          upgradedChannels.length == 1 &&
          this.localStorage.getRecord('loginType') !== 'email'
        ) {
          this._socketGateway.sendSocketMessage(
            new ChatMethods.GetChatTemplate(upgradedChannels[0].id)
          );
          // // Select chat
          this._socketGateway.sendSocketMessage(
            new AuthMethods.SendSelectChat(
              upgradedChannels[0].id,
              ISAUTH.qrcode
            )
          );
        } else if (action.payload.length > 1) {
          this._authDispatcher.showAuthChatList();
        } else if (action.payload.length == 0) {
          this._authDispatcher.logOut();
        }
      }
    })
  );

  @Effect({ dispatch: false })
  tokenChangeRcvd = this.actions$.pipe(
    ofType(AuthActions.RECIVE_AUTH_TOKEN_CHANGE),
    withLatestFrom(
      this.store$
        .select(state => state.authReducer.url)
        .pipe(distinctUntilChanged())
    ),
    map(([val, url]) => {
      const action = <AuthActions.ReciveAuthTokenChange>val;
      if (url) {
        this._socketGateway.openSocketWithUrl(url);
      }
    })
  );

  @Effect({ dispatch: false })
  selectChat = this.actions$.pipe(
    ofType(AuthActions.SET_CHAT_SELECT),
    map((action: AuthActions.SetChatSelect) => {
      this._uiDispacthers.setAuthPrivilege([]);
      this._mainChatDispatchers.resetAllEndOfPage();
      this._socketGateway.sendSocketMessage(
        new AuthMethods.SendSelectChat(action.payload, action.is_auth)
      );
    })
  );

  @Effect({ dispatch: false })
  requestAdminQR = this.actions$.pipe(
    ofType(AuthActions.REQUEST_ADMIN_QR),
    map((action: AuthActions.RequestAdminQR) => {
      this._socketGateway.sendSocketMessage(new AuthMethods.GetAdminQRCode());
    })
  );

  /* after select channel in merchant request another requests */
  @Effect({ dispatch: false })
  authChatSelect = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_AUTH_CHAT_SELECTED),
    withLatestFrom(
      this.store$
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this.store$
        .select(state => state.authReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats, iAuth]) => {
      const action = <AuthActions.ReceiveAuthChatSelected>val;
      const selectedMainChat = mainChats.find(
        mainChat => mainChat.id === action.payload.chatId
      );

      if (iAuth.role == 1 && iAuth.main_group_id === iAuth.chatId) {
        this._route.navigate(['/app']);
        // this.appManagementService.goToSpecificPage(1);
      } else {
        this._route.navigate(['/']);
      }

      if (!selectedMainChat) {
        this._socketGateway.sendSocketMessage(
          new ChatMethods.GetChatTemplate(action.payload.chatId)
        );
      }
      const selectedChat: MainChat = selectedMainChat
        ? selectedMainChat
        : { id: action.payload.chatId, type: CHANNEL, subChannel: true };

      // GetAuthPrivilege & Roles for all types of users
      // this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
      // this._socketGateway.sendSocketMessage(new ChatMembersMethods.GetRoles());
      // SetChat Selected;

      this._uiDispacthers.chatSelected(selectedChat);
    })
  );

  @Effect({ dispatch: false })
  chatPrivilege = this.actions$.pipe(
    ofType(AuthActions.GET_CHAT_PRIVILLEGES),
    map((action: AuthActions.GetChatPrivilleges) => {
      this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
    })
  );

  @Effect({ dispatch: false })
  roles = this.actions$.pipe(
    ofType(AuthActions.GET_ROLES),
    map((action: AuthActions.GetRoles) => {
      this._socketGateway.sendSocketMessage(new ChatMembersMethods.GetRoles());
    })
  );

  @Effect({ dispatch: false })
  myProfiles = this.actions$.pipe(
    ofType(AuthActions.GET_MY_PROFILES),
    map((action: AuthActions.GetMyProfiles) => {
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.GetMyProfiles()
      );
    })
  );

  @Effect({ dispatch: false })
  upgradedChatList = this.actions$.pipe(
    ofType(AuthActions.GET_UPGRADED_CHAT_LIST_METHOD),
    map((action: AuthActions.GetUpgradedChatList) => {
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.GetUpgradedChatList(action.is_default)
      );
    })
  );

  @Effect({ dispatch: false })
  searchFilters = this.actions$.pipe(
    ofType(AuthActions.GET_SEARCH_FILTERS),
    map((action: AuthActions.GetSearchFilters) => {
      const keys = [
        CHANNEL_APP_SEARCH_CATEGORIES.AREA,
        CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY,
        CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
      ];

      this._socketGateway.sendSocketMessage(new RequestAppChannelData(keys));
    })
  );

  @Effect({ dispatch: false })
  appSetup = this.actions$.pipe(
    ofType(AuthActions.GET_APPS_SETUP),
    map((action: AuthActions.GetAppSetup) => {
      this._socketGateway.sendSocketMessage(new GetAppsMethod());
      this._socketGateway.sendSocketMessage(new GetAppStoreMethod());
      this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
    })
  );

  @Effect({ dispatch: false })
  bots = this.actions$.pipe(
    ofType(AuthActions.GET_BOTS),
    map((action: AuthActions.GetBots) => {
      this._socketGateway.sendSocketMessage(new RequestMyBots());
      this._socketGateway.sendSocketMessage(new GetBotTemplates());
      this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
      this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
      // need to be redesign
      // this._messageDispatchers.getLastPostsHistory();
      // setTimeout(() => {
      //   this._messageDispatchers.getLastRepliesHistory();
      // }, 5000);
      // this._messageDispatchers.listAwardMessages();
      // this._messageDispatchers.listScheduleMessages();
      this._socketGateway.sendSocketMessage(new TagMethods.GetTags());
      this._socketGateway.sendSocketMessage(
        new ListAppMenus(APP_MENU_CATEGORIES.MAP)
      );
    })
  );

  @Effect({ dispatch: false })
  receiveAuthPrivilege = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_AUTH_PRIVILEGE),
    withLatestFrom(
      this.store$
        .select(s => s.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <AuthActions.ReceiveAuthPrivilege>val;
      this._uiDispacthers.setAuthPrivilege(action.payload);
    })
  );

  @Effect({ dispatch: false })
  changePassword = this.actions$.pipe(
    ofType(AuthActions.CHANGE_PASSWORD),
    map((action: AuthActions.ChangePassword) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.ChangePassword(
          action.payload.oldPassword,
          action.payload.newPassword,
          action.payload.confirmNewPassword
        )
      );
    })
  );

  @Effect({ dispatch: false })
  changePasswordSet = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_SET),
    map((action: AuthActions.ReceiveChangePasswordSet) => {
      this._authDispatcher.logOut();
    })
  );

  @Effect({ dispatch: false })
  changePasswordError = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_ERROR),
    map((action: AuthActions.ReceiveChangePasswordError) => {
      this._settingsService.setServerError(
        SERVER_ERROR_MESSAGES[action.payload]
      );
    })
  );

  @Effect({ dispatch: false })
  registerDemoAccount = this.actions$.pipe(
    ofType(AuthActions.REGISTER_DEMO_ACCOUNT),
    map((action: AuthActions.RegisterDemoAccount) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.RegisterDemoAccount(
          action.deviceId,
          action.os,
          action.osVersion,
          action.msisdn,
          action.email,
          action.typ,
          action.tac,
          action.countryCode
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getDemoAccountStatus = this.actions$.pipe(
    ofType(AuthActions.GET_DEMO_ACCOUNT_STATUS),
    map((action: AuthActions.GetDemoAccountStatus) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.GetDemoAccountStatus(action.msisdn, action.countryCode)
      );
    })
  );

  @Effect({ dispatch: false })
  resendVerCode = this.actions$.pipe(
    ofType(AuthActions.RESEND_VER_CODE),
    map((action: AuthActions.ResendCode) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.ResendVerificationCode(
          action.msisdn,
          action.countryCode
        )
      );
    })
  );

  @Effect({ dispatch: false })
  signupDemoAccount = this.actions$.pipe(
    ofType(AuthActions.SIGNUP_DEMO_ACCOUNT),
    map((action: AuthActions.SignUpDemoAccount) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.SingUpDemoAccount(
          action.deviceId,
          action.os,
          action.osVersion,
          action.msisdn,
          action.email,
          action.typ,
          action.countryCode,
          action.countryIso
        )
      );
    })
  );

  @Effect({ dispatch: false })
  setBusinessInfo = this.actions$.pipe(
    ofType(AuthActions.SET_BUSINESS_INFO),
    map((action: AuthActions.SetBusinessInfo) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.SetBusinessInfoMethod(
          action.company_name,
          action.website
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getBusinessInfo = this.actions$.pipe(
    ofType(AuthActions.GET_BUSINESS_INFO),
    map((action: AuthActions.GetBusinessInfo) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.GetBusinessInfoMethod()
      );
    })
  );

  @Effect({ dispatch: false })
  closeWS = this.actions$.pipe(
    ofType(AuthActions.CLOSE_SOCKET_INIT),
    map((action: AuthActions.CloseWs) => {
      this.wSocketService.closeCurrentSocket();
    })
  );

  @Effect({ dispatch: false })
  getChatList = this.actions$.pipe(
    ofType(AuthActions.GET_CHAT_LIST),
    map((action: AuthActions.GetChatList) => {
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.GetChatList(action.upgraded)
      );
    })
  );

  constructor(
    private _route: Router,
    private store$: Store<AppState>,
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _authDispatcher: AuthDispatchers,
    private _uiDispacthers: UIDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _authService: AuthService,
    private appManagementService: AppMgmtService,
    private _settingsService: SettingsService,
    private _rgstService: RegistrationService,
    private localStorage: StorageService,
    private wSocketService: SocketService,
    private _mainChatDispatchers: MainChatDispatchers
  ) {}

  private requestIfHasPrivilege(privileges: string[], isMain: boolean) {
    // TODO: change request location
    // get messages ( First load )
    // if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
    //   this._messageDispatchers.getLastPostsHistory();
    //   setTimeout(() => {
    //     this._messageDispatchers.getLastRepliesHistory();
    //   }, 5000);
    //   this._messageDispatchers.listAwardMessages();
    //   this._messageDispatchers.listScheduleMessages();
    // }
    // get billing
    // if (privileges.indexOf(PrivilegesName.listbilling) > -1) {
    //   this._socketGateway.sendSocketMessage(
    //     new PaymentMethods.GetPaymentInfo()
    //   );
    // }
    // get Tags
    // if (privileges.indexOf(PrivilegesName.listtag) > -1) {
    //   this._socketGateway.sendSocketMessage(new TagMethods.GetTags());
    // }
    // get menus
    // if (privileges.indexOf(PrivilegesName.listmenu) > -1) {
    // this._socketGateway.sendSocketMessage(new MenuMethods.ListMyMenu());
    // }
    // get bots
    // if (privileges.indexOf(PrivilegesName.listbot) > -1) {
    // this._socketGateway.sendSocketMessage(new RequestMyBots());
    // this._socketGateway.sendSocketMessage(new GetBotTemplates());
    // }
    // if (privileges.indexOf(PrivilegesName.listAppChannelData) > -1) {
    // const keys = [
    //   CHANNEL_APP_SEARCH_CATEGORIES.AREA,
    //   CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY,
    //   CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
    // ];
    // this._socketGateway.sendSocketMessage(new RequestAppChannelData(keys));
    // }
    // Need to add detailed privileges for Map Feature
    // if (privileges.indexOf(PrivilegesName.listmarker) > -1) {
    // this._socketGateway.sendSocketMessage(new GetMapServices());
    // this._socketGateway.sendSocketMessage(new GetMapRoutes());
    // this._socketGateway.sendSocketMessage(new GetMapMarkers());
    // this._socketGateway.sendSocketMessage(new GetMapTrips());
    // this._socketGateway.sendSocketMessage(new GetMapTripUsers());
    // }
    // if (privileges.indexOf(PrivilegesName.listapp) > -1) {
    //   // this._socketGateway.sendSocketMessage(new GetAppsMethod());
    //   // this._socketGateway.sendSocketMessage(new GetAppStoreMethod());
    //   // this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
    //   this.appManagementService.getInstantPages$().subscribe(res => {
    //     if (res.length < 1) {
    //       this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
    //       this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
    //     }
    //   });
    // }
    // if (privileges.indexOf(PrivilegesName.listappchannel) > -1) {
    //   if (this.store$.select(state => state.myPageReducer.length < 1)) {
    //     this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
    //     this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
    //   }
    // }
  }
}
