<ng-container>
  <div>
    <app-select-message-targets
      message-target
      #targetsComponent
      [allSelectedTabs]="composeMessageService.tabsContainers$ | async"
      [chatLabels]="uiService?.chatLable$ | async"
      [messageSendingType]="messageSendingType"
      [selectedSubChat]="selectedSubChat"
    >
    </app-select-message-targets>
  </div>
  <div class="post-body">
    <!-- <div class="head"> -->
    <app-select-message-type
      [messageType]="uiService.previewMessageType$ | async"
      [messageMediaPreview]="(uiService.uiCollection$ | async)?.showPreview"
      [messageLinkPreview]="iv$ | async"
      class="types"
      (textNotifiy)="setTextType($event)"
      (urlSelectedNotifiy)="urlSelectedEvent($event)"
      (fileSelectedNotifiy)="fileSelectedEvent($event)"
      (calendarSelectedNotifiy)="calendarSelectedEvent($event)"
      (linkSelectedNotifiy)="linkSelectedEvent()"
    >
    </app-select-message-type>

    <div class="content">
      <div class="content-body">
        <ng-container
          *ngIf="(uiService.previewMessageType$ | async) !== mType.CONTACT"
        >
          <!-- Media -->
          <app-preview-media
            *ngIf="
              (uiService.uiCollection$ | async)?.showPreview &&
              !(iv$ | async) &&
              !(selectedCalendar$ | async)
            "
            class="preview"
            no-padding
            #postMediaPreview
            [hideHeader]="true"
            [messageStyle]="stylesComponent?.getMessageStyle()"
            [selectedChat]="uiService.selectedChat$ | async"
            [mediaMetadata]="uiService.previewMediaMetadata$ | async"
            [mediaError]="uiService.previewMediaError$ | async"
            [files]="uiService.previewFiles$ | async"
            [messageType]="uiService.previewMessageType$ | async"
            [isErrorMessageShown]="(uiService.uiCollection$ | async)?.showPopup"
            [hideFooter]="true"
            [isMenuAttache]="selectedMenu || couponMenu ? true : false"
          >
            <ng-container
              *ngIf="
                uiService?.previewMessageType$ | async as currentSelectedType
              "
              message-sticky-post
            >
              <ng-container
                *ngIf="
                  stickyPost &&
                  currentSelectedType !== mType.TEXT &&
                  currentSelectedType !== mType.DOCUMENT &&
                  currentSelectedType !== mType.AUDIO &&
                  currentSelectedType !== mType.CALENDAR &&
                  (!stylesComponent ||
                    stylesComponent?.getMessageStyle() !== mStyles.LEFT_SIDE)
                "
              >
                <div
                  *ngIf="
                    composeMessageService?.getPostComponentBySectionId$(
                      targetsComponent?.localSelectedTab?.id
                    ) | async as component
                  "
                  class="sticky-post"
                  [ngStyle]="{ 'background-color': component.sticky_bg }"
                >
                  <div class="sticky-post-body">
                    <svg
                      class="svg-icon icon-cancel"
                      (click)="resetStickyPost($event)"
                    >
                      <use
                        xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                      ></use>
                    </svg>
                    <span
                      class="title"
                      [ngStyle]="{ color: component.sticky_text_color }"
                      >{{ stickyPost.title }}</span
                    >
                    <span
                      class="desc"
                      [ngStyle]="{ color: component.sticky_text_color }"
                      >{{ stickyPost.description }}</span
                    >
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div style="width: 100%;" message-attachs>
              <div class="menu-attach" *ngIf="selectedMenu">
                <svg
                  class="svg-icon icon-cancel"
                  (click)="setSelectedMenu(null)"
                >
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
                <app-menu-holder
                  [menus]="selectedMenu"
                  [bot]="true"
                  [hideTextOnlySec]="true"
                ></app-menu-holder>
              </div>
              <div *ngIf="couponMenu" class="coupon">
                <ngx-barcode
                  [bc-value]="couponMenu?.code"
                  [bc-display-value]="false"
                  [bc-font-size]="12"
                  [bc-element-type]="'img'"
                  [bc-background]="'transparent'"
                  [bc-width]="1"
                  [bc-height]="20"
                  [bc-margin]="0"
                  [bc-margin-top]="0"
                  [bc-margin-bottom]="0"
                  [bc-margin-right]="0"
                  [bc-margin-left]="0"
                >
                </ngx-barcode>
                {{ couponMenu?.code }}
                <svg class="svg-icon icon-cancel" (click)="setCouponMenu(null)">
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
              </div>
            </div>
          </app-preview-media>
          <!----------------------------------------------------->

          <!-- Text -->
          <app-compose-text-input
            *ngIf="
              !(uiService.uiCollection$ | async)?.showPreview &&
              !(iv$ | async) &&
              !(selectedCalendar$ | async)
            "
            #textInputComponent
            [isMenuAttache]="selectedMenu || couponMenu ? true : false"
          >
            <ng-container message-attachs>
              <div class="menu-attach" *ngIf="selectedMenu">
                <svg
                  class="svg-icon icon-cancel"
                  (click)="setSelectedMenu(null)"
                >
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
                <app-menu-holder
                  [menus]="selectedMenu"
                  [bot]="true"
                  [hideTextOnlySec]="true"
                ></app-menu-holder>
              </div>
              <div *ngIf="couponMenu" class="coupon">
                <ngx-barcode
                  [bc-value]="couponMenu?.code"
                  [bc-display-value]="false"
                  [bc-font-size]="12"
                  [bc-element-type]="'img'"
                  [bc-background]="'transparent'"
                  [bc-width]="1"
                  [bc-height]="20"
                  [bc-margin]="0"
                  [bc-margin-top]="0"
                  [bc-margin-bottom]="0"
                  [bc-margin-right]="0"
                  [bc-margin-left]="0"
                >
                </ngx-barcode>
                {{ couponMenu?.code }}
                <svg class="svg-icon icon-cancel" (click)="setCouponMenu(null)">
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
              </div>
            </ng-container>
          </app-compose-text-input>
          <!----------------------------------------------------->

          <!-- Calendar Preview -->
          <app-preview-calendar
            *ngIf="
              !(uiService.uiCollection$ | async)?.showPreview &&
                !(iv$ | async) &&
                selectedCalendar$ | async
            "
            #calendarComponent
            class="link-preview"
            [selectedCalendar]="selectedCalendar$ | async"
            [autoAcceptCalendar]="autoAcceptCalendar"
            [hideFooter]="true"
            [isMenuAttache]="selectedMenu || couponMenu ? true : false"
            (cancelNotfiy)="resetSelectedType()"
          >
            <ng-container auto-accept>
              <div class="cell-calender">
                <div class="cell-details">
                  <div class="title">Auto Accept</div>
                </div>
                <label
                  class="switch-btn"
                  [class.active-switch]="autoAcceptCalendar"
                  [for]="'accept'"
                >
                  <input
                    type="checkbox"
                    [id]="'accept'"
                    [checked]="autoAcceptCalendar"
                    (change)="setAutoAcceptCalendar($event.target.checked)"
                  />
                  <div class="circle"></div>
                </label>
              </div>
            </ng-container>
            <ng-container message-attachs>
              <div class="menu-attach" *ngIf="selectedMenu">
                <svg
                  class="svg-icon icon-cancel"
                  (click)="setSelectedMenu(null)"
                >
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
                <app-menu-holder
                  [menus]="selectedMenu"
                  [bot]="true"
                  [hideTextOnlySec]="true"
                ></app-menu-holder>
              </div>
              <div *ngIf="couponMenu" class="coupon">
                <ngx-barcode
                  [bc-value]="couponMenu?.code"
                  [bc-display-value]="false"
                  [bc-font-size]="12"
                  [bc-element-type]="'img'"
                  [bc-background]="'transparent'"
                  [bc-width]="1"
                  [bc-height]="20"
                  [bc-margin]="0"
                  [bc-margin-top]="0"
                  [bc-margin-bottom]="0"
                  [bc-margin-right]="0"
                  [bc-margin-left]="0"
                >
                </ngx-barcode>
                {{ couponMenu?.code }}
                <svg class="svg-icon icon-cancel" (click)="setCouponMenu(null)">
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
              </div>
            </ng-container>
          </app-preview-calendar>
          <!----------------------------------------------------->
          <!-- Link Preview -->
          <app-preview-link
            *ngIf="
              !(uiService.uiCollection$ | async)?.showPreview &&
              (iv$ | async) &&
              !(selectedCalendar$ | async)
            "
            style="margin: auto;"
            #instantArticleComponent
            [instantArticle]="iv$ | async"
            [stylesComponent]="stylesComponent?.getMessageStyle()"
            [hideFooter]="true"
            [isMenuAttache]="selectedMenu || couponMenu ? true : false"
            (cancelNotfiy)="resetSelectedType()"
          >
            <ng-container
              message-sticky-post
              *ngIf="
                stickyPost &&
                (uiService?.previewMessageType$ | async) !== mType.TEXT &&
                (uiService?.previewMessageType$ | async) !== mType.DOCUMENT &&
                (uiService?.previewMessageType$ | async) !== mType.AUDIO &&
                (uiService?.previewMessageType$ | async) !== mType.CALENDAR &&
                stylesComponent?.getMessageStyle() !== mStyles.LEFT_SIDE
              "
            >
              <div
                *ngIf="
                  composeMessageService.getPostComponentBySectionId$(
                    targetsComponent.localSelectedTab.id
                  ) | async as component
                "
                class="sticky-post"
                [ngStyle]="{
                  'background-color': component.sticky_bg,
                  bottom: '50px',
                  right: '10px'
                }"
              >
                <div class="sticky-post-body">
                  <svg
                    class="svg-icon icon-cancel"
                    (click)="resetStickyPost($event)"
                  >
                    <use
                      xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                    ></use>
                  </svg>
                  <span
                    class="title"
                    [ngStyle]="{ color: component.sticky_text_color }"
                    >{{ stickyPost.title }}</span
                  >
                  <span
                    class="desc"
                    [ngStyle]="{ color: component.sticky_text_color }"
                    >{{ stickyPost.description }}</span
                  >
                </div>
              </div>
            </ng-container>
            <div message-attachs>
              <div class="menu-attach" *ngIf="selectedMenu">
                <svg
                  class="svg-icon icon-cancel"
                  (click)="setSelectedMenu(null)"
                >
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
                <app-menu-holder
                  [menus]="selectedMenu"
                  [bot]="true"
                  [hideTextOnlySec]="true"
                ></app-menu-holder>
              </div>
              <div *ngIf="couponMenu" class="coupon">
                <ngx-barcode
                  [bc-value]="couponMenu?.code"
                  [bc-display-value]="false"
                  [bc-font-size]="12"
                  [bc-element-type]="'img'"
                  [bc-background]="'transparent'"
                  [bc-width]="1"
                  [bc-height]="20"
                  [bc-margin]="0"
                  [bc-margin-top]="0"
                  [bc-margin-bottom]="0"
                  [bc-margin-right]="0"
                  [bc-margin-left]="0"
                >
                </ngx-barcode>
                {{ couponMenu?.code }}
                <svg class="svg-icon icon-cancel" (click)="setCouponMenu(null)">
                  <use
                    xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                  ></use>
                </svg>
              </div>
            </div>
            <!-- </ng-container> -->
          </app-preview-link>
          <!----------------------------------------------------->
        </ng-container>
        <div
          class="link-preview"
          *ngIf="(uiService?.previewMessageType$ | async) === mType.CONTACT"
        >
          <div class="link-preview-content">
            <!-- Link Preview -->
            <app-preview-link
              *ngIf="
                !(uiService.uiCollection$ | async)?.showPreview &&
                (iv$ | async) &&
                !(selectedCalendar$ | async)
              "
              #instantArticleComponent
              [instantArticle]="iv$ | async"
              [stylesComponent]="stylesComponent?.getMessageStyle()"
              [hideFooter]="true"
              [isMenuAttache]="selectedMenu || couponMenu ? true : false"
              (cancelNotfiy)="resetSelectedType()"
            >
              <ng-container
                message-sticky-post
                *ngIf="
                  stickyPost &&
                  (uiService?.previewMessageType$ | async) !== mType.TEXT &&
                  (uiService?.previewMessageType$ | async) !== mType.DOCUMENT &&
                  (uiService?.previewMessageType$ | async) !== mType.AUDIO &&
                  (uiService?.previewMessageType$ | async) !== mType.CALENDAR &&
                  stylesComponent?.getMessageStyle() !== mStyles.LEFT_SIDE
                "
              >
                <div
                  *ngIf="
                    composeMessageService.getPostComponentBySectionId$(
                      targetsComponent.localSelectedTab.id
                    ) | async as component
                  "
                  class="sticky-post"
                  [ngStyle]="{
                    'background-color': component.sticky_bg,
                    bottom: '50px',
                    right: '10px'
                  }"
                >
                  <div class="sticky-post-body">
                    <svg
                      class="svg-icon icon-cancel"
                      (click)="resetStickyPost($event)"
                    >
                      <use
                        xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                      ></use>
                    </svg>
                    <span
                      class="title"
                      [ngStyle]="{ color: component.sticky_text_color }"
                      >{{ stickyPost.title }}</span
                    >
                    <span
                      class="desc"
                      [ngStyle]="{ color: component.sticky_text_color }"
                      >{{ stickyPost.description }}</span
                    >
                  </div>
                </div>
              </ng-container>
              <div message-attachs>
                <div class="menu-attach" *ngIf="selectedMenu">
                  <svg
                    class="svg-icon icon-cancel"
                    (click)="setSelectedMenu(null)"
                  >
                    <use
                      xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                    ></use>
                  </svg>
                  <app-menu-holder
                    [menus]="selectedMenu"
                    [bot]="true"
                    [hideTextOnlySec]="true"
                  ></app-menu-holder>
                </div>
                <div *ngIf="couponMenu" class="coupon">
                  <ngx-barcode
                    [bc-value]="couponMenu?.code"
                    [bc-display-value]="false"
                    [bc-font-size]="12"
                    [bc-element-type]="'img'"
                    [bc-background]="'transparent'"
                    [bc-width]="1"
                    [bc-height]="20"
                    [bc-margin]="0"
                    [bc-margin-top]="0"
                    [bc-margin-bottom]="0"
                    [bc-margin-right]="0"
                    [bc-margin-left]="0"
                  >
                  </ngx-barcode>
                  {{ couponMenu?.code }}
                  <svg
                    class="svg-icon icon-cancel"
                    (click)="setCouponMenu(null)"
                  >
                    <use
                      xlink:href="/assets/svg/app-extra-icons.svg#icon-close"
                    ></use>
                  </svg>
                </div>
              </div>
              <!-- </ng-container> -->
            </app-preview-link>
            <!----------------------------------------------------->
          </div>
          <app-compose-url-input
            (publishNotfiy)="urlSelectedEvent($event)"
            (resetNotfiy)="resetLinkPrivewType()"
            [isLink]="true"
          >
          </app-compose-url-input>
        </div>
      </div>
      <div class="post-attach">
        <div class="post-attach-header">
          <h3>Post Properties</h3>
        </div>
        <div class="post-attach-body">
          <ng-container *ngIf="uiService.previewMessageType$ | async as Type">
            <ng-container
              *ngIf="
                Type !== mType.DOCUMENT &&
                Type !== mType.AUDIO &&
                Type !== mType.VIDEO &&
                Type !== mType.CONTACT &&
                Type !== mType.TEXT &&
                Type !== mType.CALENDAR &&
                Type !== mType.LINK
              "
            >
              <h4>Layout</h4>
              <p>Select your post layout.</p>
              <app-select-message-style
                *ngIf="
                  ((uiService.uiCollection$ | async)?.showPreview &&
                    !(iv$ | async) &&
                    !(selectedCalendar$ | async)) ||
                  (!(uiService.uiCollection$ | async)?.showPreview &&
                    (iv$ | async) &&
                    !(selectedCalendar$ | async))
                "
                message-style
                #stylesComponent
                [messageType]="uiService.previewMessageType$ | async"
              >
              </app-select-message-style>
            </ng-container>
          </ng-container>

          <!--Attachments-->
          <app-select-message-attachs
            #attachComponent
            [selectedTab]="
              composeMessageService?.getPostComponentBySectionId$(
                composeMessageService?.currentSelectedTapRef$ | async
              ) | async
            "
            [currentSelectedType]="uiService?.previewMessageType$ | async"
            [selectedChat]="selectedSubChat"
            [privileges]="uiService?.privileges$ | async"
            (stickyNotify)="setStickyPost($event)"
            (menuNotify)="setSelectedMenu($event)"
            (couponNotify)="setCouponMenu($event)"
          ></app-select-message-attachs>
          <!----------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
  <div class="compose-footer">
    <div class="viewer">
      <div class="viewer-date" *ngIf="scheduleInput">
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-sections.svg#icon-clock"></use>
        </svg>
        Your message will be sent on
        <strong style="font-weight: 500;margin-left: 4px;"
          >{{ scheduleInput | date: 'MMMM dd' }}th at
          {{ scheduleInput | date: 'hh:mm aa' }}</strong
        >.
      </div>
    </div>
    <div class="actions">
      <ng-container
        *ngIf="
          (uiService.uiCollection$ | async)?.showPreview &&
          !(iv$ | async) &&
          !(selectedCalendar$ | async)
        "
      >
        <div
          class="compose-send-btn"
          [class.schedule-send-btn]="
            messageSendingType === attachType?.SCHEDULE
          "
        >
          <button
            class="send-btn"
            #btn
            (click)="sendMediaMessageEvent(postMediaPreview?.getMediaToSend())"
            [disabled]="canSendMedia"
          >
            <ng-container *ngIf="messageSendingType !== attachType?.WELCOME">
              {{ 'SEND' | translate }}
            </ng-container>
            <ng-container *ngIf="messageSendingType === attachType?.WELCOME">
              <span style="color: #fff;">
                {{ 'DONE' | translate }}
              </span>
            </ng-container>
          </button>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !(uiService.uiCollection$ | async)?.showPreview &&
          !(iv$ | async) &&
          !(selectedCalendar$ | async)
        "
      >
        <span style="position: relative;" footer-submit>
          <div
            class="compose-send-btn"
            [class.schedule-send-btn]="
              messageSendingType === attachType?.SCHEDULE
            "
          >
            <button
              class="send-btn"
              #btn
              [disabled]="canSend"
              (click)="
                sendTextMessageEvent(textInputComponent?.getTextToSend())
              "
            >
              <ng-container *ngIf="messageSendingType !== attachType?.WELCOME">
                {{ 'SEND' | translate }}
              </ng-container>
              <ng-container *ngIf="messageSendingType === attachType?.WELCOME">
                <span style="color: #fff;">
                  {{ 'DONE' | translate }}
                </span>
              </ng-container>
            </button>
          </div>
        </span>
      </ng-container>
      <ng-container
        *ngIf="
          !(uiService.uiCollection$ | async)?.showPreview &&
          (iv$ | async) &&
          !(selectedCalendar$ | async)
        "
      >
        <div
          class="compose-send-btn"
          [class.schedule-send-btn]="
            messageSendingType === attachType?.SCHEDULE
          "
        >
          <button
            class="send-btn"
            #btn
            (click)="
              sendLinkPreviewEvent(instantArticleComponent?.getInstantArticle())
            "
            [disabled]="canSendMedia"
          >
            <ng-container *ngIf="messageSendingType !== attachType?.WELCOME">
              {{ 'SEND' | translate }}
            </ng-container>
            <ng-container *ngIf="messageSendingType === attachType?.WELCOME">
              <span style="color: #fff;">
                {{ 'DONE' | translate }}
              </span>
            </ng-container>
          </button>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !(uiService.uiCollection$ | async)?.showPreview &&
            !(iv$ | async) &&
            selectedCalendar$ | async
        "
      >
        <div
          class="compose-send-btn"
          [class.schedule-send-btn]="
            messageSendingType === attachType?.SCHEDULE
          "
        >
          <button
            class="send-btn"
            #btn
            (click)="sendCalendarEvent(calendarComponent?.getCalendar())"
            [disabled]="canSendMedia"
          >
            <ng-container *ngIf="messageSendingType !== attachType?.WELCOME">
              {{ 'SEND' | translate }}
            </ng-container>
            <ng-container *ngIf="messageSendingType === attachType?.WELCOME">
              <span style="color: #fff;">
                {{ 'DONE' | translate }}
              </span>
            </ng-container>
          </button>
        </div>
      </ng-container>
      <div
        class="schedule-btn"
        (click)="toggleSchedule(true)"
        *ngIf="messageSendingType === attachType?.SCHEDULE"
      >
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-sections.svg#icon-clock"></use>
        </svg>
      </div>
    </div>
  </div>
</ng-container>

<app-modal-container
  *ngIf="toggleMenu$ | async"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="toggleSchedule(false)"
>
  <app-compose-schedule-input
    footer-tag
    #scheduleInputComponent
    (isCancelNotify)="toggleSchedule(false)"
    (schedule)="setSchedule($event)"
    (isScheduleDateValid)="isValid($event)"
    [schedule_date]="scheduleInput"
  >
  </app-compose-schedule-input>
</app-modal-container>
<app-modal-container
  *ngIf="selectedFile"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="closeImageCrroper()"
>
  <app-abstract-crop
    [imageChangedEvent]="selectedFile"
    [selectedFile]="selectedFile?.localFile"
    [onlyScaleDown]="onlyScaleDown"
    [maintainAspectRatio]="true"
    [resizeToHeight]="resizeToHeight"
    [resizeToWidth]="resizeToWidth"
    [imageQuality]="50"
    [roundCropper]="false"
    [mimeType]="selectedFile.localFile.type"
    [aspectRatio]="selectedFile.width / selectedFile.height"
    [skipCropper]="selectedFile?.localFile?.size <= 250000"
    (filesSelectedNotifay)="imageSelectedEvent($event)"
    (closeNotifay)="closeImageCrroper()"
  ></app-abstract-crop>
</app-modal-container>
