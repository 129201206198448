import { Actions } from './channelAppUI.actions';
import * as actions from './channelAppUI.actions';
import { ChannelAppUIState } from '../../../models/ChannelAppUIState';
import { ChannelAppScreens } from '../../../models/constants';

const INITIAL_STATE: ChannelAppUIState = {
  iosVersion: false,
  androidVersion: true,
  currentScreen: 1
};

export function channelAppUiReducer(
  state = INITIAL_STATE,
  action: Actions
): ChannelAppUIState {
  switch (action.type) {
    case actions.ChannelAppUIActionTypes.GET_NEXT_PAGE: {
      const totalNumberOfScreens = Object.keys(ChannelAppScreens).length / 2;
      if (state.currentScreen === totalNumberOfScreens) {
        return state;
      } else {
        return { ...state, currentScreen: ++state.currentScreen };
      }
    }
    case actions.ChannelAppUIActionTypes.GET_PREVIOUS_PAGE: {
      if (state.currentScreen === 1) {
        return state;
      } else {
        return { ...state, currentScreen: --state.currentScreen };
      }
    }
    case actions.ChannelAppUIActionTypes.TOGGLE_ANDROID_VERSION: {
      return { ...state, androidVersion: action.payload };
    }
    case actions.ChannelAppUIActionTypes.TOGGLE_IOS_VERSION: {
      return { ...state, iosVersion: action.payload };
    }
    case actions.ChannelAppUIActionTypes.GO_TO_SPECIFIC_PAGE: {
      return {
        ...state,
        currentScreen: action.pageNumber
      };
    }
    case actions.ChannelAppUIActionTypes.UPDATE_PUBLISH_BUTTON_FLAG: {
      return {
        ...state,
        canPublishFlag: action.canPublish
      };
    }
    case actions.ChannelAppUIActionTypes.UPDATE_TOTAL_APP_CONF_FLAG: {
      return {
        ...state,
        isTotalAppConfig: action.totalAppConfig
      };
    }
    case actions.ChannelAppUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
