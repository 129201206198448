import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { MenuCallBackStats } from 'src/models/MenuCallBackStats';

@Component({
  selector: 'app-menu-stats',
  templateUrl: './menu-stats.component.html',
  styleUrls: ['./menu-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuStatsComponent implements OnChanges {
  @Input() messageStats: MenuCallBackStats[];
  @Output() closeNotfify = new EventEmitter<boolean>(null);

  doughnutChartType = 'doughnut';
  doughnutChartLabels: string[] = [];
  doughnutChartData: any[] = [];

  constructor(private _ch: ChangeDetectorRef) {}

  ngOnChanges(chng: SimpleChanges) {
    const localChange = chng['messageStats'];

    if (localChange.currentValue) {
      this.doughnutChartLabels = this.messageStats.map(
        stat => stat.button_label
      );
      this.doughnutChartData = this.messageStats.map(stat => stat.count);
      this._ch.detectChanges();
    }
  }

  closeEvent() {
    this.closeNotfify.emit(true);
  }
}
