import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

export const enum VirtualAppUIActionTypes {
  UI_LIST_VIRTUAL_APP = 'UI_LIST_VIRTUAL_APP',
  UI_CREATE_VIRTUAL_APP = 'UI_CREATE_VIRTUAL_APP',
  UI_VIEW_VIRTUAL_APP = 'UI_VIEW_VIRTUAL_APP',
  UI_EDIT_VIRTUAL_APP = 'UI_EDIT_VIRTUAL_APP',
  UPLOAD_VIRTUAL_APP_IMAGE = 'UPLOAD_VIRTUAL_APP_IMAGE',
  UI_UPLOAD_VIRTUAL_APP_PHOTO = 'UI_UPLOAD_VIRTUAL_APP_PHOTO',
  UI_VIRTUAL_APP_CREATION_COMPLETE = 'UI_VIRTUAL_APP_CREATION_COMPLETE',
  UI_CLOSE_VIEW_VIRTUAL_APP = 'UI_CLOSE_VIEW_VIRTUAL_APP',
  UI_CLOSE_EDIT_VIRTUAL_APP = 'UI_CLOSE_EDIT_VIRTUAL_APP',
  UI_VIRTUAL_APP_GET_NEXT_PAGE = 'UI_VIRTUAL_APP_GET_NEXT_PAGE',
  UI_VIRTUAL_APP_GET_PREV_PAGE = 'UI_VIRTUAL_APP_GET_PREV_PAGE',
  UI_VIRTUAL_APP_RECEIVED_HISTORY_CHUNK = 'UI_VIRTUAL_APP_RECEIVED_HISTORY_CHUNK',
  UI_VIRTUAL_APP_RESET_PAGING = 'UI_VIRTUAL_APP_RESET_PAGING',
  UI_VIRTUAL_APP_SET_IDLE_STATUS = 'UI_VIRTUAL_APP_SET_IDLE_STATUS',
  RESET = 'RESET'
}

export class ListVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_LIST_VIRTUAL_APP;
  constructor() {}
}
export class CreateVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_CREATE_VIRTUAL_APP;
}
export class ViewVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIEW_VIRTUAL_APP;
  constructor(public selectedVirtualApp: IChat) {}
}
export class EditVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_EDIT_VIRTUAL_APP;
  constructor(public selectedVirtualApp: IChat) {}
}

export class UploadVirtualAppImage implements Action {
  readonly type = VirtualAppUIActionTypes.UPLOAD_VIRTUAL_APP_IMAGE;
  constructor(public selectedFile: ISelectedFiles) {}
}
export class VirtualAppPhotoUploadUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_UPLOAD_VIRTUAL_APP_PHOTO;
  constructor(public createdVirtualApp: IChat) {}
}

export class VirtualAppCreationComplete implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_CREATION_COMPLETE;
}

export class CloseViewVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_CLOSE_VIEW_VIRTUAL_APP;
}
export class CloseEditVirtualAppUI implements Action {
  readonly type = VirtualAppUIActionTypes.UI_CLOSE_EDIT_VIRTUAL_APP;
}
//
// Paging Actions

export class GetNextVirtualAppPage implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevVirtualAppPage implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_GET_PREV_PAGE;
  constructor() {}
}

// we can detect that by listening to the return of getsubchatlist of type Group or Channel
export class ReceivedVirtualAppHistoryChunk implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChat[]
  ) {}
}

export class ResetVirtualAppPaging implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = VirtualAppUIActionTypes.UI_VIRTUAL_APP_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = VirtualAppUIActionTypes.RESET;
}

export type Actions =
  | ListVirtualAppUI
  | CreateVirtualAppUI
  | ViewVirtualAppUI
  | EditVirtualAppUI
  | UploadVirtualAppImage
  | VirtualAppPhotoUploadUI
  | VirtualAppCreationComplete
  | CloseViewVirtualAppUI
  | CloseEditVirtualAppUI
  | GetNextVirtualAppPage
  | GetPrevVirtualAppPage
  | ReceivedVirtualAppHistoryChunk
  | ResetVirtualAppPaging
  | SetStatusAsIdle
  | Reset;
