import { DriverTrip } from 'src/models/DrverAssigned';
import * as otherTripsAction from './otherDriverTrips.actions';
import { DriverAssignedMiddleware } from '.././driverAssigned/driverAssigned.middleware';

const INITIAL_STATE: DriverTrip = {
  text: null,
  employeeID: 0,
  startDate: new Date(),
  endDate: new Date(),
  diff: null
};
export function otherTripsReducer(
  state: DriverTrip[] = [],
  action: otherTripsAction.otherTripsActions
): DriverTrip[] {
  switch (action.type) {
    case otherTripsAction.otherDriverTripsActionTypes.OTHER_TRIPS_RECEIVED:
      if (action.payload.otherMapAssignTrips.length > 0) {
        const localPayload = DriverAssignedMiddleware.mapListFromServer(
          action.payload.otherMapAssignTrips
        );
        state = localPayload;
      } else {
        state = [];
      }

      return state;

    case otherTripsAction.RESET:
      return [];
    default:
      return state;
  }
}
