import { Store } from '@ngrx/store';
import * as ItemActions from './subItem.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SubItemDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateSubItem(itemRef, itemUpdatedProps) {
        this._store.dispatch(new ItemActions.UpdateSubItem(itemRef, itemUpdatedProps));
    }
    updateSubItemLink(itemRef, itemLink, pageId) {
        this._store.dispatch(new ItemActions.UpdateSubItemLink(itemRef, itemLink, pageId));
    }
    reorderSubItem(itemRef, newPosition) {
        this._store.dispatch(new ItemActions.ReorderSubItem(itemRef, newPosition));
    }
    deleteSubItemRequest(item) {
        this._store.dispatch(new ItemActions.DeleteSubItemRequest(item));
    }
    deleteSubItem(item) {
        this._store.dispatch(new ItemActions.DeleteSubItem(item));
    }
    addSubItemRequest(parentComponent, specialItemValues) {
        this._store.dispatch(new ItemActions.AddSubItemRequest(parentComponent, specialItemValues));
    }
    addSubItem(item) {
        this._store.dispatch(new ItemActions.AddSubItem(item));
        this.updateSubItemLink(item.ref, item.link, item.page_id);
    }
    deleteSubItemsOfComponent(parentComponent) {
        this._store.dispatch(new ItemActions.DeleteSubItemsOfComponent(parentComponent));
    }
    addSubItems(items) {
        this._store.dispatch(new ItemActions.AddSubItems(items));
    }
}
SubItemDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubItemDispatchers_Factory() { return new SubItemDispatchers(i0.ɵɵinject(i1.Store)); }, token: SubItemDispatchers, providedIn: "root" });
