import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-circle-btn',
  templateUrl: './circle-btn.component.html',
  styleUrls: ['./circle-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleBtnComponent {
  @Input() disabled: boolean;
  @Input() type = 'button';
  @Input() frame;
  @Output() btnClicked = new EventEmitter<boolean>();
  @Output() mouseLeaveNotify = new EventEmitter<boolean>();
  @Output() mouseOverNotify = new EventEmitter<boolean>();

  onClick() {
    this.btnClicked.emit(true);
  }
  mouseleave() {
    this.mouseLeaveNotify.emit(true);
  }
  mouseover() {
    this.mouseOverNotify.emit();
  }
}
