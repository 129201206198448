import {
  AfterViewInit,
  Component,
  ChangeDetectionStrategy
} from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-google-btn',
  templateUrl: './google-btn.component.html',
  styleUrls: ['./google-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleBtnComponent implements AfterViewInit {
  constructor(public authService: AuthService) {}
  ngAfterViewInit(): void {
    setTimeout(() => this.authService.callLoginButton('login'), 500);
  }
}
