import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCancelEvent]'
})
export class CancelEventDirective {
  @Input() cancelEvent = true;

  constructor() {}

  @HostListener('click', ['$event'])
  onHostClicked(event: any) {
    event = event || window.event;
    if (event) {
      event = event.originalEvent || event;

      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault && this.cancelEvent) {
        event.preventDefault();
      }
      event.returnValue = false;
      event.cancelBubble = true;
    }
    return false;
  }
}
