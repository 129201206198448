<div class="snackbar-error">
  <div class="snackbar-error-icon">
    <img width="40" height="40" src="./assets/img/close (2).svg" alt="error" />
  </div>
  <div class="snackbar-error-body">
    <h3>Error</h3>
    <p>
      {{ message }}
    </p>
  </div>
  <div class="snackbar-error-action">
    <a (click)="cancelEvent()">Close</a>
  </div>
</div>
