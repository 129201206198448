import { Actions } from './serviceOrdersUI.actions';
import * as actions from './serviceOrdersUI.actions';
import {
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection
} from '../../../models/constants';
import { ServiceOrdersUI } from 'src/models/ServiceOrdersUI';

const INITIAL_STATE: ServiceOrdersUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function serviceOrdersUiReducer(
  state = INITIAL_STATE,
  action: Actions
): ServiceOrdersUI {
  switch (action.type) {
    case actions.ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_NEXT_PAGE: {
      const newHash =
        (action.paymentOrderID ? action.paymentOrderID + '|' : '') +
        (action.serviceOrderID ? action.serviceOrderID : '');
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_PREV_PAGE: {
      const newHash =
        (action.paymentOrderID ? action.paymentOrderID + '|' : '') +
        (action.serviceOrderID ? action.serviceOrderID : '');
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.ServiceOrdersUIActionTypes
      .UI_SERVICE_ORDERS_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.ServiceOrdersUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
