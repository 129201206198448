import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as ChannelAppUIActions from './channelAppUI.actions';
import * as AuthActions from './../auth/auth.actions';
import { ChannelAppUIActionTypes } from './channelAppUI.actions';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { ContainerDispatchers } from '../channelAppContainers/container.dispatchers';
import { ChannelAppUiDispatchers } from './channelAppUI.dispatchers';

import {
  APP_CONFIG_COMPLETE,
  UISections,
  ChannelAppScreens
} from 'src/models/constants';
import { AppState } from 'src/models/AppState';

import * as isEqual from 'lodash.isequal';
@Injectable()
export class ChannelAppUIEffects {
  /* after select channel in merchant request another requests */
  @Effect({ dispatch: false })
  authChatSelect = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_AUTH_CHAT_SELECTED),
    withLatestFrom(
      this.store$
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats]) => {
      const action = <AuthActions.ReceiveAuthChatSelected>val;

      const selectedMainChat = mainChats.find(
        mainChat => mainChat.id === action.payload.chatId
      );

      if (
        selectedMainChat &&
        !selectedMainChat.subChannel &&
        APP_CONFIG_COMPLETE !== `${action.payload.progressWeb}` &&
        action.payload.appConfig
      ) {
        let screenNum = parseInt(action.payload.progressWeb, 10);
        this._uiDispacthers.setSection(UISections.APP);

        const totalNumberOfScreens = Object.keys(ChannelAppScreens).length / 2;
        if (
          screenNum !== null &&
          screenNum !== undefined &&
          screenNum < totalNumberOfScreens
        ) {
          screenNum = screenNum === 0 ? 1 : screenNum;
          this._channelAppUiDispatchers.goToSpecificPage(screenNum);
        } else if (screenNum === totalNumberOfScreens) {
          this._channelAppUiDispatchers.goToSpecificPage(screenNum);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  selectTab = this.actions$.pipe(
    ofType(ChannelAppUIActionTypes.SELECT_TAB),
    map((action: ChannelAppUIActions.SelectTab) => {
      this._containerDispatchers.selectContainer(action.selectedTab);
    })
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private _uiDispacthers: UIDispatchers,
    private _channelAppUiDispatchers: ChannelAppUiDispatchers,
    private _containerDispatchers: ContainerDispatchers
  ) {}
}
