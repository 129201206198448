import {
  CLEAR_VERIFICATION_TEMPLATE_METHOD,
  GET_MESSAGING_VENDOR_METHOD,
  GET_VERIFICATION_TEMPLATE_METHOD,
  SET_THIRD_PARTY_INFO_METHOD,
  SET_VERIFICATION_TEMPLATE_METHOD,
  TEST_MESSAGING_VENDOR_METHOD
} from './constants';

export interface ThirdPartyInfoUI {
  name?: string;
  type?: string;
  access?: {
    base_url?: string;
    user?: string;
    password?: string;
    from?: string;
    api_key?: string;
  };
}

export interface VendorTest {
  name?: string;
  type?: string;
  msisdn?: string;
  countryCode?: string;
}

export interface VendorsUI {
  tac?: string;
  vendors?: ThirdPartyInfoUI[];
  oldVendors?: ThirdPartyInfoUI[];
  isSaved?: boolean;
  smsVendor?: ThirdPartyInfoUI;
  verificationMessageEmail?: any;
  verificationMessageSMS?: any;
  VerificationMessageEmailType?: number;
  VerificationMessageSMSType?: number;
}

export class SaveThirdPartyInfoMethod {
  readonly method = SET_THIRD_PARTY_INFO_METHOD;
  constructor(public vendors: ThirdPartyInfoUI[]) { }
}
export class TestThirdPartyInfoMethod {
  readonly method = TEST_MESSAGING_VENDOR_METHOD;
  constructor(public vendor: VendorTest) { }
}

export class GetThirdPartyInfoMethod {
  readonly method = GET_MESSAGING_VENDOR_METHOD;
  constructor(public name: string) { }
}
export class SendVerificationTemplate {
  readonly method = SET_VERIFICATION_TEMPLATE_METHOD;
  constructor(public email: string, public sms: string) { }
}
export class GetVerificationTemplate {
  readonly method = GET_VERIFICATION_TEMPLATE_METHOD;
}

export class DeleteVerificationTemplate {
  readonly method = CLEAR_VERIFICATION_TEMPLATE_METHOD;
  constructor(public email: boolean, public sms: boolean) { }
}
