import { Action } from '@ngrx/store';
import { MStore, MStoreItem } from 'src/models/Stores';

export const enum StoresUIActionTypes {
  CREATE_STORE = 'CREATE_STORE',
  GET_STORE = 'GET_STORE',
  SET_STORE = 'SET_STORE',
  ADD_STORE_ITEM = 'ADD_STORE_ITEM',
  ASSIGN_STORE_CHANNEL = 'ASSIGN_STORE_CHANNEL',
  REMOVE_ITEM = 'REMOVE_ITEM',
  UI_STORE_GET_NEXT_PAGE = 'UI_STORE_GET_NEXT_PAGE',
  UI_STORE_GET_PREV_PAGE = 'UI_STORE_GET_PREV_PAGE',
  UI_STORE_RECEIVED_HISTORY_CHUNK = 'UI_STORE_RECEIVED_HISTORY_CHUNK',
  STORE_RECEIVED = 'STORE_RECEIVED',
  UI_STORE_RESET_PAGING = 'UI_STORE_RESET_PAGING',
  UI_STORE_SET_IDLE_STATUS = 'UI_STORE_SET_IDLE_STATUS',
  UI_STORE_SET_SELECTED_CHAT = 'UI_STORE_SET_SELECTED_CHAT',
  STORE_INFO_RECEIVED = 'STORE_INFO_RECEIVED',
  DELETE_STORE = 'DELETE_STORE',
  STORE_DELETE = 'STORE_DELETE',
  RESET = 'RESET'
}

export class CreateStore implements Action {
  readonly type = StoresUIActionTypes.CREATE_STORE;
  constructor(public storesDetails: MStore) {}
}

export class GetStore implements Action {
  readonly type = StoresUIActionTypes.GET_STORE;
  constructor(public storeID: string) {}
}

export class SetStore implements Action {
  readonly type = StoresUIActionTypes.SET_STORE;
  constructor(public storeID: string, public storeDetails: MStore) {}
}

export class AddStoreItem implements Action {
  readonly type = StoresUIActionTypes.ADD_STORE_ITEM;
  constructor(public storeID: string, public items: MStoreItem[]) {}
}

export class AssignStoreChannel implements Action {
  readonly type = StoresUIActionTypes.ASSIGN_STORE_CHANNEL;
  constructor(public storeID: string, public chatIds: any[]) {}
}

export class RemoveStoreItem implements Action {
  readonly type = StoresUIActionTypes.REMOVE_ITEM;
  constructor(public storeID: string, public itemIDs: any[]) {}
}

export class DeleteStore implements Action {
  readonly type = StoresUIActionTypes.DELETE_STORE;
  constructor(public storeID: string) {}
}

export class StoreDelete implements Action {
  readonly type = StoresUIActionTypes.STORE_DELETE;
  constructor(public id: string) {}
}

// Paging Actions

export class GetNextStorePage implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevStorePage implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedStoreHistoryChunk implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: MStore[]
  ) {}
}

export class StoreReceived implements Action {
  readonly type = StoresUIActionTypes.STORE_RECEIVED;
  constructor(public store: MStore) {}
}

export class ResetStorePaging implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_SET_IDLE_STATUS;
  constructor() {}
}

export class StoreInfoReceived implements Action {
  readonly type = StoresUIActionTypes.STORE_INFO_RECEIVED;
  constructor(public mStoreID: string, public mStore: MStore) {}
}
export class SetStoreSelectedChat implements Action {
  readonly type = StoresUIActionTypes.UI_STORE_SET_SELECTED_CHAT;
  constructor(public selected_chat: string) {}
}

export class Reset implements Action {
  readonly type = StoresUIActionTypes.RESET;
}

export type Actions =
  | CreateStore
  | GetStore
  | SetStore
  | AddStoreItem
  | RemoveStoreItem
  | GetNextStorePage
  | GetPrevStorePage
  | ReceivedStoreHistoryChunk
  | ResetStorePaging
  | StoreReceived
  | SetStatusAsIdle
  | DeleteStore
  | StoreDelete
  | SetStoreSelectedChat
  | Reset;
