import { MapService, MapServiceUI } from 'src/models/MapService';
import { Actions } from './mapService.actions';

import * as actions from './mapService.actions';
import {
  ListRequestDetails,
  MapServiceScreens,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';

const INITIAL_STATE: MapServiceUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  currentMapServiceScreen: MapServiceScreens.MAP_SERVICE_LIST
};

export function mapServiceReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.MapServiceActionTypes.RESET_MAP_SERVICE_PAGING:
      return INITIAL_STATE;
    case actions.MapServiceActionTypes.GET_NEXT_MAP_SERVICE_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    }
    case actions.MapServiceActionTypes.GET_PREV_MAP_SERVICE_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV
      };
    }
    case actions.MapServiceActionTypes.MAP_SERVICE_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.mapServices && action.mapServices.length) > 0
            ? action.mapServices
            : state.currentPage
      };
    }
    case actions.MapServiceActionTypes.MAP_SERVICE_DETAILS_RECEIVED: {
      const isFound =
        state.currentPage.filter(fn => fn.id === action.mapService.id).length >
        0;

      if (isFound) {
        return {
          ...state,
          currentPage: state.currentPage.map(r => {
            if (r.id === action.mapService.id) {
              return action.mapService;
            }
            return r;
          })
        };
      }

      return {
        ...state,
        currentPage: [...state.currentPage, action.mapService]
      };
    }
    case actions.MapServiceActionTypes.MAP_SERVICE_CREATED: {
      if (
        !state.currentPage.find(
          mapSetting => mapSetting.id === action.mapService.id
        )
      ) {
        return {
          ...state,
          currentPage: [action.mapService, ...state.currentPage]
        };
      } else {
        return state;
      }
    }
    case actions.MapServiceActionTypes.MAP_SERVICE_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          mapService => mapService.id !== action.id
        )
      };
    case actions.MapServiceActionTypes.UI_MAP_SERVICE_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.MapServiceActionTypes.UI_SET_MAP_SERVICE_CURRENT_SCREEN: {
      return {
        ...state,
        currentMapServiceScreen: action.screen
      };
    }
    case actions.MapServiceActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
