import { AppChannelSettingsUI } from 'src/models/AppChannelSettings';
import * as actions from './appChannelSettings.actions';
import { Actions } from './appChannelSettings.actions';

const INITIAL_STATE: AppChannelSettingsUI = {
  whiteList_enabled: false
};

export function appChannelSettingsReducer(
  state = INITIAL_STATE,
  action: Actions
): AppChannelSettingsUI {
  switch (action.type) {
    case actions.AppChannelSettingsTypes
      .UI_RECEIVE_APP_CHANNEL_SETTINGS_HISTORY: {
      return {
        ...state,
        whiteList_enabled: action.whiteListEnabled,
        deep_link_schema: action.deep_link_schema
      };
    }
    default:
      return state;
  }
}
