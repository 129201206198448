import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { MapTripActionTypes } from './mapTrip.actions';
import * as MapActions from './mapTrip.actions';
import * as Methods from 'src/models/MapTrip';
import { MapTrip } from 'src/models/MapTrip';
import { MapTripDispatchers } from './mapTrip.dispatchers';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class MapTripEffects {
  @Effect({ dispatch: false })
  resetMapTripPaging = this.actions$.pipe(
    ofType(MapTripActionTypes.RESET_MAP_TRIP_PAGING),
    map((action: MapActions.ResetMapTripPaging) => {
      this._mapTripDispatchers.getNextMapTripPage();
    })
  );

  @Effect({ dispatch: false })
  getNextMapServicePage = this.actions$.pipe(
    ofType(MapTripActionTypes.GET_NEXT_MAP_TRIP_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsUiState]) => {
      const action = <MapActions.GetNextMapTripPage>val;
      const prevRequest = mapTripsUiState.previousRequest;
      if (
        !(
          mapTripsUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapTripsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTrips(0, mapTripsUiState.eop, mapTripsUiState.hash)
        );
      } else {
        this._mapTripDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMapServicePage = this.actions$.pipe(
    ofType(MapTripActionTypes.GET_PREV_MAP_TRIP_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsUiState]) => {
      const action = <MapActions.GetPrevMapTripPage>val;
      const prevRequest = mapTripsUiState.previousRequest;
      if (
        !(
          mapTripsUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapTripsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTrips(1, mapTripsUiState.sop, mapTripsUiState.hash)
        );
      } else {
        this._mapTripDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  createMapTrip = this.actions$.pipe(
    ofType(MapTripActionTypes.CREATE_MAP_TRIP),
    map((action: MapActions.CreateMapTrip) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapTrip(action.mapTrip)
      );
    })
  );

  @Effect({ dispatch: false })
  updateMapTrip = this.actions$.pipe(
    ofType(MapTripActionTypes.UPDATE_MAP_TRIP),
    map((action: MapActions.UpdateMapTrip) => {
      const mapTrip: MapTrip = {
        ...action.mapTripDetails,
        id: action.id
      };
      this._socketGateway.sendSocketMessage(new Methods.SetMapTrip(mapTrip));
    })
  );

  @Effect({ dispatch: false })
  getMapTripId = this.actions$.pipe(
    ofType(MapTripActionTypes.GET_MAP_TRIP_BY_ID),
    map((action: MapActions.GetMapTripById) => {
      this._socketGateway.sendSocketMessage(
        new Methods.GetMapTrip(action.tripId)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMapTrip = this.actions$.pipe(
    ofType(MapTripActionTypes.DELETE_MAP_TRIP),
    map((action: MapActions.DeleteMapTrip) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveMapTrip(action.id)
      );
    })
  );

  /** No need for the following effect because of all the data comes with the list */

  // @Effect({ dispatch: false })
  // listReceived = this.actions$.pipe(
  //   ofType(MapTripActionTypes.MAP_TRIP_LIST_RECEIVED),
  //   map((action: MapActions.MapTripListReceived) => {
  //     if (action.mapTrips && action.mapTrips.length > 0) {
  //       action.mapTrips.forEach(trip => {
  //         this._socketGateway.sendSocketMessage(
  //           new Methods.GetMapTrip(trip.id)
  //         );
  //       });
  //     }
  //   })
  // );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _mapTripDispatchers: MapTripDispatchers,
    private _store: Store<AppState>
  ) {}
}
