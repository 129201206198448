import { Actions } from './appConfigHistory.actions';
import * as actions from './appConfigHistory.actions';
import { AppConfigHistoryRecord } from 'src/models/ChannelAppConfig';

const INITIAL_STATE = [];
export function appConfigHistoryReducer(
  state: AppConfigHistoryRecord[] = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppConfigHistoryActionTypes.RECEIVE_APP_CONFIGS_HISTORY: {
      return action.configHistoryList;
    }
    case actions.AppConfigHistoryActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
