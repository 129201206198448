import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as MediaCenterMethods from 'src/models/IMediaCenter';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { FileService } from 'src/app/core/file.service';
import { GROUP_IMAGE_UPLOAD_TYPE, MessageMediaStatus } from 'src/models/constants';
import { MediaCenterDispatchers } from './mediaCenter.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
export class MediaCenterEffects {
    constructor(actions$, _fileService, _uploadGateway, _socketGateway, _store, _uiDispatchers, _mediaCenterDispatchers) {
        this.actions$ = actions$;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._uiDispatchers = _uiDispatchers;
        this._mediaCenterDispatchers = _mediaCenterDispatchers;
        this.uploadSubscriptions = [];
        this.getMyGallery = this.actions$.pipe(ofType("GET_MY_MEDIA_GALLERY" /* GET_MY_MEDIA_GALLERY */), withLatestFrom(this._store
            .select(state => state.uiReducer.receveMediaGallary)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mediaGallary]) => {
            const action = val;
            if (!mediaGallary.includes(action.mediaType)) {
                this._socketGateway.sendSocketMessage(new MediaCenterMethods.GetMyMediaGallery(action.mediaType));
                this._uiDispatchers.receiveMediaGallary(action.mediaType);
            }
        }));
        this.uploadMediaFile = this.actions$.pipe(ofType("MEDIA_UPLOADING" /* MEDIA_UPLOADING */), map((action) => {
            const msg = {};
            this._fileService
                .readFileAsArrayBuffer(action.localFile)
                .then(fileAsArrayBuffer => {
                this._fileService
                    .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.fileType)
                    .then(blobUrl => {
                    msg.loadingProgress = 0;
                    msg.reference = new Date().getTime();
                    msg.url = blobUrl; // set blob url in file from local
                    msg.file = blobUrl; // set file from blobUrl from local
                    msg.mediaStatus = MessageMediaStatus.UPLOADING;
                    msg.size = action.localFile.size;
                    msg.name = action.localFile.name;
                    msg.fileAsArrayBuffer = fileAsArrayBuffer;
                    msg.localFile = action.localFile;
                    msg.type = action.fileType;
                    msg.aspectRatio = action.uploadPrams.ar;
                    this._mediaCenterDispatchers.mediaCenterUploadingProcessed(msg, action.uploadPrams);
                });
            });
        }));
        this.uploadMediaFileProcessed = this.actions$.pipe(ofType("MEDIA_UPLOADING_PROCESSED" /* MEDIA_UPLOADING_PROCESSED */), map((action) => {
            this.uploadMedia(action.payload, action.uploadPrams, action.payload.fileAsArrayBuffer, action.payload.localFile);
        }));
        this.deleteMediaCenterItem = this.actions$.pipe(ofType("DELETE_MEDIA_CENTER_ITEM" /* DELETE_MEDIA_CENTER_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new MediaCenterMethods.DeleteMyMediaGalleryItem(action.payload));
        }));
    }
    uploadMedia(msg, prams, fileAsArrayBuffer, localFile) {
        let progressMonitor = 0;
        this.uploadSubscriptions[msg.reference] = this._uploadGateway
            .uploadWithProgress(fileAsArrayBuffer, localFile.type, localFile.name, false, GROUP_IMAGE_UPLOAD_TYPE, prams.tabID, prams.groupID, true, true, prams.botID, null, null, prams.hd, prams.ar, prams.bg)
            .subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                const progressVal = Math.round((event.loaded / event.total) * 100);
                if (progressVal - progressMonitor >= 18 || progressVal === 100) {
                    progressMonitor = progressVal;
                    this._mediaCenterDispatchers.updateMediaCenterLoadingProgress(msg, progressVal);
                }
            }
            else if (event.type === HttpEventType.Response) {
                const res = event.body;
                msg.file = res.file; // set file from responce of api
                msg.url = res.media.permanentUrl; // set url from url
                msg.filename = res.file; // set fileName to use it in delete
                msg.loadingProgress = 100;
                msg.width = res.width;
                msg.height = res.height;
                msg.size = res.size;
                msg.permanentUrl = res.media.permanentUrl;
                msg.private_url = res.media.url;
                this._mediaCenterDispatchers.updateMediaCenterStatus(msg, MessageMediaStatus.UPLOADED, res.media.permanentUrl, res.file);
                delete this.uploadSubscriptions[msg.reference];
            }
        }, error => {
            console.log('Failed to upload subGroup image');
        });
    }
    cancelUploadMediaFile(msg) {
        if (this.uploadSubscriptions[msg.reference]) {
            this.uploadSubscriptions[msg.reference].unsubscribe();
            this._mediaCenterDispatchers.updateMediaCenterStatus(msg, MessageMediaStatus.UPLOAD_FAILED, null, null);
            delete this.uploadSubscriptions[msg.reference];
        }
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MediaCenterEffects.prototype, "getMyGallery", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MediaCenterEffects.prototype, "uploadMediaFile", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MediaCenterEffects.prototype, "uploadMediaFileProcessed", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MediaCenterEffects.prototype, "deleteMediaCenterItem", void 0);
