import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { Whitelist } from 'src/models/Whitelist';
import * as WhitelistPatternsUIActions from './whitelistPatternsUI.actions';

@Injectable({
  providedIn: 'root'
})
export class WhitelistPatternsUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetPaging() {
    this._store.dispatch(
      new WhitelistPatternsUIActions.ResetWhitelistPatternsPaging()
    );
  }

  getNextWhitelistPatternsPage() {
    this._store.dispatch(
      new WhitelistPatternsUIActions.GetNextWhitelistPatternsPage()
    );
  }

  getPrevWhitelistPatternsPage() {
    this._store.dispatch(
      new WhitelistPatternsUIActions.GetPrevWhitelistPatternsPage()
    );
  }

  addWhitelistPatternsToList(chat_id: string, users: Whitelist[]) {
    this._store.dispatch(
      new WhitelistPatternsUIActions.AddWhitelistPatternsToList(chat_id, users)
    );
  }

  addWhitelistPatternsSuccess(data: Whitelist[]) {
    this._store.dispatch(
      new WhitelistPatternsUIActions.AddWhitelistPatternsSuccess(data)
    );
  }

  deletePatternFromWhitelist(chat_id: string, users: string[]) {
    this._store.dispatch(
      new WhitelistPatternsUIActions.DeletePatternFromWhitelist(users, chat_id)
    );
  }

  deletePatternCallback(users: string[]) {
    this._store.dispatch(
      new WhitelistPatternsUIActions.DeletePatternCallback(users)
    );
  }

  receivedWhitelistPatternsHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Whitelist[],
    hash: string
  ) {
    this._store.dispatch(
      new WhitelistPatternsUIActions.ReceivedWhitelistPatternsHistoryChunk(
        eop,
        sop,
        currentPage,
        hash
      )
    );
  }

  setStatusAsIdle() {
    this._store.dispatch(new WhitelistPatternsUIActions.SetStatusAsIdle());
  }

  reset() {
    this._store.dispatch(
      new WhitelistPatternsUIActions.ResetWhitelistPatterns()
    );
  }
}
