import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-contact-cell',
  templateUrl: './contact-cell.component.html',
  styleUrls: ['./contact-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCellComponent {
  @Input()
  phoneNumber: string;
  @Input()
  phoneName: string;
}
