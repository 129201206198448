import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CouponMiddleware } from './coupon.middleware';

import * as CouponActions from './coupon.actions';
import * as CouponMethods from 'src/models/ICoupon';

import { AppState } from 'src/models/AppState';

@Injectable()
export class CouponEffects {
  // send update to the server after insert in store
  @Effect({ dispatch: false })
  getCoupon = this.actions$.pipe(
    ofType(CouponActions.CouponActionTypes.GET_COUPON),
    map((action: CouponActions.GetCoupon) => {
      this._socketGateway.sendSocketMessage(
        new CouponMethods.GetCoupon(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  createCoupon = this.actions$.pipe(
    ofType(CouponActions.CouponActionTypes.CREATE_COUPON),
    map((action: CouponActions.CreateCoupon) => {
      if (!action.isCreateFromBool) {
        const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
        this._socketGateway.sendSocketMessage(
          new CouponMethods.CreateCoupon(cpToSend)
        );
      } else {
        const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
        this._socketGateway.sendSocketMessage(
          new CouponMethods.CreateCouponFromBool(cpToSend)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  updateCoupon = this.actions$.pipe(
    ofType(CouponActions.CouponActionTypes.UPDATE_COUPON),
    map((action: CouponActions.UpdateCoupon) => {
      const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
      this._socketGateway.sendSocketMessage(
        new CouponMethods.UpdateCoupon(cpToSend)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _socketGateway: SocketGateway
  ) {}
}
