import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { BlackListPatternsUiDispatchers } from './blackListPatternsUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import { AddPatternToBlacklist, GetBlacklistPattern, RemovePatternFromBlacklist } from 'src/models/BlackListsUI';
export class BlackListPatternsUiEffects {
    constructor(actions$, _socketGateway, _uiBlackListPatternsDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiBlackListPatternsDispatchers = _uiBlackListPatternsDispatchers;
        this._store = _store;
        this.afterBlackListPatternsResetPaging = this.actions$.pipe(ofType("UI_BLACKLIST_PATTERNS_RESET_PAGING" /* UI_BLACKLIST_PATTERNS_RESET_PAGING */), map((action) => {
            this._uiBlackListPatternsDispatchers.getNextBlacklistPatternsPage();
        }));
        this.getBlacklistPatternsNextPage = this.actions$.pipe(ofType("UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE" /* UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.blackListPatternsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, blackListPatternsUiReducer]) => {
            const action = val;
            const prevRequest = blackListPatternsUiReducer.previousRequest;
            if (!(blackListPatternsUiReducer.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                blackListPatternsUiReducer.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBlacklistPattern(null, 0, blackListPatternsUiReducer.hash, blackListPatternsUiReducer.eop));
            }
            else {
                this._uiBlackListPatternsDispatchers.setStatusAsIdle();
            }
        }));
        this.getBlacklistPatternsPrevPage = this.actions$.pipe(ofType("UI_BLACKLIST_PATTERNS_GET_PREV_PAGE" /* UI_BLACKLIST_PATTERNS_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.blackListPatternsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, blackListPatternsUiReducer]) => {
            const action = val;
            const prevRequest = blackListPatternsUiReducer.previousRequest;
            if (!(blackListPatternsUiReducer.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                blackListPatternsUiReducer.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBlacklistPattern(null, 1, blackListPatternsUiReducer.hash, blackListPatternsUiReducer.sop));
            }
            else {
                this._uiBlackListPatternsDispatchers.setStatusAsIdle();
            }
        }));
        this.deletePatternsFromBlacklist = this.actions$.pipe(ofType("UI_DELETE_PATTERNS_FROM_BLACKLIST" /* UI_DELETE_PATTERNS_FROM_BLACKLIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemovePatternFromBlacklist(action.data, action.mainChatID));
        }));
        this.addPatternToBlacklist = this.actions$.pipe(ofType("UI_ADD_BLACKLIST_PATTERNS" /* UI_ADD_BLACKLIST_PATTERNS */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddPatternToBlacklist(action.data, action.mainChatID, action.reference, action.recordIndex));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListPatternsUiEffects.prototype, "afterBlackListPatternsResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListPatternsUiEffects.prototype, "getBlacklistPatternsNextPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListPatternsUiEffects.prototype, "getBlacklistPatternsPrevPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListPatternsUiEffects.prototype, "deletePatternsFromBlacklist", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListPatternsUiEffects.prototype, "addPatternToBlacklist", void 0);
