<div class="actions">
  <div class="actions-reply" *ngIf="numberOfReplies">
    <span class="dot-unread" *ngIf="numberOfUnreadReplies"></span>
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-reply-message"></use>
    </svg>
  </div>
  <div dropdown (onShown)="toggleMenu(true)" (onHidden)="toggleMenu(false)">
    <div [class.drop-down]="toggleMenuStatus" class="menu-icon" dropdownToggle>
      <i class="fas fa-ellipsis-h"></i>
    </div>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
      <li
        role="menuitem"
        class="menu-item clickable-cursor item"
        (click)="selectEvent()"
      >
        <svg class="svg-icon icon-menu menu-icon-item">
          <use
            xlink:href="/assets/svg/app-sections.svg#icon-open-in-new-widow"
          ></use>
        </svg>
        Open Post
      </li>
      <li
        role="menuitem"
        class="menu-item clickable-cursor item"
        *ngIf="isDownloadable"
      >
        <a class="menu-link" [href]="media" [download]="mediaId">
          <svg class="svg-icon icon-menu menu-icon-item">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-download-for-post"
            ></use>
          </svg>
          Download
        </a>
      </li>
      <li
        *ngIf="isMenu && projectName === merchant"
        role="menuitem"
        class="menu-item clickable-cursor item"
        (click)="statsEvent()"
      >
        <svg class="svg-icon icon-menu menu-icon-item">
          <use
            xlink:href="./assets/svg/app-extra-icons.svg#icon-data_usage"
          ></use>
        </svg>
        open chart
      </li>
      <li
        *ngIf="isAdmin"
        role="menuitem"
        class="menu-item clickable-cursor item item-delete"
        (click)="recallEvent()"
      >
        <svg class="svg-icon icon-icon clickable-cursor">
          <use xlink:href="./assets/svg/app-sections.svg#icon-trash"></use>
        </svg>
        Delete
      </li>
      <li
        *ngIf="uiService.getEnableSetTemplateBtn$ | async"
        role="menuitem"
        class="menu-item clickable-cursor item item-delete"
        (click)="setTemplate()"
      >
        Set Template
      </li>
    </ul>
  </div>
</div>
