import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { EventUIActionTypes } from './eventUI.actions';
import * as EventActions from './eventUI.actions';
import { FileService } from 'src/app/core/file.service';
import { UIDispatchers } from '../ui/ui.dispatchers';
import {
  ERROR_NOT_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  NOT_SUPPORTED_FILE_TYPE,
  NO_FILE_NAME,
  GROUP_UPLOAD_TYPE,
  EVENT_CHANNEL,
  UPDATE_EVENT_REF
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { IUploadResponse } from 'src/models/IUploadResponse';
import { Photo } from 'src/models/Photo';
import { IChat, SetChat, GetSubChats } from 'src/models/IChat';
import { EventUiDispatchers } from './eventUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { EventsService } from 'src/app/+merchant/+dashboard/events/events.service';

@Injectable()
export class EventUiEffects {
  @Effect({ dispatch: false })
  uploadEventImage = this.actions$.pipe(
    ofType(EventUIActionTypes.UPLOAD_EVENT_IMAGE),
    withLatestFrom(
      this._store
        .select(state => state.eventUiReducer.creationProcessEvent)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.eventUiReducer.selectedEvent)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer.enableSetTemplateBtn)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, creationEvent, updatedEvent, enableSetTemplateBtn]) => {
      const action = <EventActions.UploadEventImage>val;

      if (this.validateFile(action.selectedFile.file, 'image')) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.file.localFile)
          .then(fileAsArrayBuffer => {
            let groupID = '';
            if (!enableSetTemplateBtn) {
              groupID = creationEvent
                ? creationEvent.id
                : updatedEvent && updatedEvent.id
                ? updatedEvent.id
                : action.selectedFile.event.id;
            }
            this._uploadGateway
              .uploadWithProgress(
                fileAsArrayBuffer,
                action.selectedFile.file.localFile.type,
                action.selectedFile.file.localFile.name,
                false,
                null,
                null,
                groupID,
                true,
                false,
                '',
                false,
                false,
                0,
                '1x1'
              )
              .subscribe(
                event => {
                  if (event.type === HttpEventType.Response) {
                    const res = <IUploadResponse>event.body;
                    let chat: IChat = {};
                    const photo: Photo = { id: res.file };
                    chat.id = creationEvent
                      ? creationEvent.id
                      : updatedEvent && updatedEvent.id
                      ? updatedEvent.id
                      : action.selectedFile.event.id;
                    chat.photo = photo;
                    chat = { ...chat, ...action.selectedFile.event };
                    // this.eventsService._currentPhotoUploaded.next(res);
                    this._socketGateway.sendSocketMessage(
                      new SetChat(chat, UPDATE_EVENT_REF)
                    );
                    this.eventsService.showEditEventScreen(chat);
                    this.eventsService._wattingForUploadImage.next(false);
                    // if (enableSetTemplateBtn) {
                    //   const id = chat.id;
                    //   const type = chat.type.toLocaleLowerCase();
                    //   const tempChat = { ...chat };
                    //   delete tempChat['id'];
                    //   this._uiDispatchers.setChatToTemplate(id, type, tempChat);
                    // }
                  }
                },
                error => {
                  console.log('Failed to upload event image');
                  this.eventsService._wattingForUploadImage.next(false);
                }
              );
          });
      }
    })
  );

  @Effect({ dispatch: false })
  afterEventCreation = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_EVENT_UPLOAD_PHOTO),
    map((action: EventActions.EventPhotoUploadUI) => {
      this._chatDispatchers.chatInvalidated(action.createdEvent.id);
    })
  );

  @Effect({ dispatch: false })
  getNextEventPage = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_EVENT_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.eventUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, eventUiState]) => {
      // const action = <EventActions.GetNextEventPage>val;
      this._socketGateway.sendSocketMessage(
        new GetSubChats([EVENT_CHANNEL], 0, eventUiState.hash, eventUiState.eop)
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevEventPage = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_EVENT_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.eventUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, eventUiState]) => {
      //  const action = <EventActions.GetPrevEventPage>val;

      this._socketGateway.sendSocketMessage(
        new GetSubChats([EVENT_CHANNEL], 1, eventUiState.hash, eventUiState.sop)
      );
    })
  );

  @Effect({ dispatch: false })
  afterEventSectionSelection = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_LIST_EVENT),
    map((action: EventActions.ListEventUI) => {
      this._uiEventDispatchers.getNextEventPage();
    })
  );

  @Effect({ dispatch: false })
  afterEventResetPaging = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_EVENT_RESET_PAGING),
    map((action: EventActions.ResetEventPaging) => {
      this._uiEventDispatchers.getNextEventPage();
    })
  );

  @Effect({ dispatch: false })
  setChat = this.actions$.pipe(
    ofType(EventUIActionTypes.UI_SET_EVENT_CHAT),
    withLatestFrom(
      this._store
        .select(state => state.eventUiReducer.file)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, file]) => {
      if (file && file !== null) {
        const action = <EventActions.SetChat>val;
        const chat = { ...file.event, id: action.data.chat_id };
        const evntObject: any = { ...file };
        evntObject.event = chat;
        this._uiEventDispatchers.uploadEventImage(evntObject);
        // this._socketGateway.sendSocketMessage(new SetChat(chat, UPDATE_EVENT_REF));
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _chatDispatchers: ChatDispatchers,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _uiEventDispatchers: EventUiDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private eventsService: EventsService,
    private _store: Store<AppState>
  ) {}

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
