import { Actions } from './appClass.actions';
import * as actions from './appClass.actions';
import { LocalAppClass } from 'src/models/ChannelAppLocalConfig';

const INITIAL_STATE = [];

export function appClassReducer(
  state: LocalAppClass[] = INITIAL_STATE,
  action: Actions
): LocalAppClass[] {
  switch (action.type) {
    case actions.AppClassActionTypes.APP_CONFIG_DATA_CHANGED: {
      return [
        ...action.onlineAppConfig.appClasses,
        ...action.offlineAppConfig.appClasses
      ];
    }
    case actions.AppClassActionTypes.UPDATE_APP_CLASS: {
      return state.map(appClass => {
        if (appClass.ref === action.appClassRef) {
          let updatedAppClass: LocalAppClass = {};
          updatedAppClass = { ...appClass, ...action.appClassUpdatedProps };

          return updatedAppClass;
        }
        return appClass;
      });
    }
    case actions.AppClassActionTypes.OPEN_CLASS_SECTION: {
      return state.map(appClass => {
        if (appClass.ref === action.appClass.ref) {
          let updatedAppClass: LocalAppClass = {};
          updatedAppClass.currentlyOpened = 1;
          updatedAppClass = { ...appClass, ...updatedAppClass };
          return updatedAppClass;
        }
        return appClass;
      });
    }
    case actions.AppClassActionTypes.CLOSE_CLASS_SECTION: {
      return state.map(appClass => {
        if (appClass.ref === action.appClass.ref) {
          let updatedAppClass: LocalAppClass = {};
          updatedAppClass.currentlyOpened = 0;
          updatedAppClass = { ...appClass, ...updatedAppClass };
          return updatedAppClass;
        }
        return appClass;
      });
    }
    case actions.AppClassActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
