import { Component, ChangeDetectionStrategy } from '@angular/core';

import { DashboardService } from '../../+dashboard/dashboard.service';

import { slideToLeft } from '../../../app-animations';
import { AuthService } from 'src/app/core/auth.service';
import { MainChat } from 'src/models/MainChat';
import { AuthStatus } from 'src/models/constants';

@Component({
  selector: 'app-auth-list',
  templateUrl: './auth-list.component.html',
  styleUrls: ['./auth-list.component.scss'],
  animations: [slideToLeft],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthListComponent {
  authStatus: typeof AuthStatus = AuthStatus;

  constructor(
    public dashboardService: DashboardService,
    public authService: AuthService
  ) {}

  selectChatEvent(chat: MainChat) {
    this.authService.selectChat(chat.id);
  }

  logoutEvent() {
    this.authService.logout();
  }

  TrackByFunction(index, chat: MainChat) {
    return chat.id;
  }
}
