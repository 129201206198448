import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { FormValidatorsService } from 'src/app/validators/form-validators.service';

@Component({
  selector: 'app-icon-validation-errors',
  templateUrl: './icon-validation-errors.component.html',
  styleUrls: ['./icon-validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconValidationErrorsComponent {
  @Input() fieldErrors: ValidationErrors;
  @Input() fieldName: string;
  @Input() fieldType: string;

  constructor(private _validationService: FormValidatorsService) {}

  getError() {
    return this._validationService.getError(this.fieldName, this.fieldErrors);
  }
}
