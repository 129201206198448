import { Action } from '@ngrx/store';
import { DriverTrip } from 'src/models/DrverAssigned';

export const enum MapDriverActionTypes {
  MAP_TRIPS_DRIVER_RECEIVED = 'MAP_TRIPS_DRIVER_RECEIVED',
  GET_MAP_TRIPS_DRIVER = 'GET_MAP_TRIPS_DRIVER',
  DELETE_MAP_TRIPS_DRIVER = 'DELETE_MAP_TRIPS_DRIVER',
  RESET = 'RESET'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class GetMapDriver implements Action {
  readonly type = MapDriverActionTypes.GET_MAP_TRIPS_DRIVER;
  constructor(public trip_id: string) {}
}

export class DeleteMapDriver implements Action {
  readonly type = MapDriverActionTypes.DELETE_MAP_TRIPS_DRIVER;
  constructor(public account_id: string, public trip_id: string) {}
}

export class MapDriverTipsReceived implements Action {
  readonly type = MapDriverActionTypes.MAP_TRIPS_DRIVER_RECEIVED;
  constructor(public payload: any) {}
}
// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type MapDriverActions =
  | GetMapDriver
  | DeleteMapDriver
  | MapDriverTipsReceived
  | DeselectChannel
  | Reset;
