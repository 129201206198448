/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./record-voice-note.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/cancel-drag/cancel-drag.directive";
import * as i3 from "../../shared/pipes/media-duration/media-duration.pipe";
import * as i4 from "../../core/helper.service";
import * as i5 from "@angular/common";
import * as i6 from "./record-voice-note.component";
var styles_RecordVoiceNoteComponent = [i0.styles];
var RenderType_RecordVoiceNoteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecordVoiceNoteComponent, data: {} });
export { RenderType_RecordVoiceNoteComponent as RenderType_RecordVoiceNoteComponent };
function View_RecordVoiceNoteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa-microphone layer"]], [[2, "fas", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_RecordVoiceNoteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [["appCancelDrag", ""], ["class", "control-container"]], null, [[null, "dragstart"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDragEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDragEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDragEvents($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onDragEvents($event) !== false);
        ad = (pd_3 && ad);
    } if (("drop" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).onDragEvents($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CancelDragDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "stop"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelRecord() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "recorder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "send"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRecordStop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "send"], ["src", "./assets/img/send-voice-note.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.voiceRecordDurationInterval)); _ck(_v, 7, 0, currVal_0); }); }
export function View_RecordVoiceNoteComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.MediaDurationPipe, [i4.HelperService]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.record2() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecordVoiceNoteComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecordVoiceNoteComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.recInited; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.recInited; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RecordVoiceNoteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-record-voice-note", [], null, null, null, View_RecordVoiceNoteComponent_0, RenderType_RecordVoiceNoteComponent)), i1.ɵdid(1, 573440, null, 0, i6.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var RecordVoiceNoteComponentNgFactory = i1.ɵccf("app-record-voice-note", i6.RecordVoiceNoteComponent, View_RecordVoiceNoteComponent_Host_0, { selectedChat: "selectedChat", isBotScreen: "isBotScreen" }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" }, []);
export { RecordVoiceNoteComponentNgFactory as RecordVoiceNoteComponentNgFactory };
