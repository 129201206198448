import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationsPoolComponent } from './locations-pool.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { LocationsMarkersPoolComponent } from './locations-markers-pool/locations-markers-pool.component';
import { LocationMiniFormComponent } from './locations-markers-pool/location-mini-form/location-mini-form.component';
import { AbstractTablesModule } from 'src/app/abstract-tables/abstract-tables.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    LocationsPoolComponent,
    LocationsMarkersPoolComponent,
    LocationMiniFormComponent
  ],
  imports: [
    CommonModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractTablesModule,
    AbstractControlsModule,
    ColorPickerModule,
    TranslateModule
  ],
  exports: [LocationsPoolComponent, LocationsMarkersPoolComponent]
})
export class LocationsPoolModule {}
