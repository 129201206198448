/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-loading-cover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/pipes/file-size/file-size.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./media-center-loading-cover.component";
var styles_MediaCenterLoadingCoverComponent = [i0.styles];
var RenderType_MediaCenterLoadingCoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterLoadingCoverComponent, data: {} });
export { RenderType_MediaCenterLoadingCoverComponent as RenderType_MediaCenterLoadingCoverComponent };
function View_MediaCenterLoadingCoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "loading-cover-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "statement"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Uploading\u2026"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "type"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "(", ")"])), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, [" \u200B "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "progress-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "progress-border-meter"]], [[4, "width", "%"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.size)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.progress; _ck(_v, 8, 0, currVal_2); }); }
function View_MediaCenterLoadingCoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "loading-cover-loadedd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "checkmark-cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-sent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "statement"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Complete"]))], null, null); }
export function View_MediaCenterLoadingCoverComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.FileSizePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "loading-cover"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterLoadingCoverComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterLoadingCoverComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.status === _co.messageMediaStatus.UPLOADING) || (_co.status === _co.messageMediaStatus.DOWNLOADING)); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.status === _co.messageMediaStatus.UPLOADED) || (_co.status === _co.messageMediaStatus.DOWNLOADED)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_MediaCenterLoadingCoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-loading-cover", [], null, null, null, View_MediaCenterLoadingCoverComponent_0, RenderType_MediaCenterLoadingCoverComponent)), i1.ɵdid(1, 638976, null, 0, i4.MediaCenterLoadingCoverComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MediaCenterLoadingCoverComponentNgFactory = i1.ɵccf("app-media-center-loading-cover", i4.MediaCenterLoadingCoverComponent, View_MediaCenterLoadingCoverComponent_Host_0, { type: "type", size: "size", status: "status", progress: "progress" }, { hideMediaCover: "hideMediaCover" }, []);
export { MediaCenterLoadingCoverComponentNgFactory as MediaCenterLoadingCoverComponentNgFactory };
