import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { Whitelist } from 'src/models/Whitelist';
import * as WhitelistUIActionTypes from './whitelistMainUI.actions';

@Injectable({
  providedIn: 'root'
})
export class WhitelistUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetPaging(name: string) {
    this._store.dispatch(new WhitelistUIActionTypes.ResetWhitelistPaging(name));
  }

  getNextWhitelistPage() {
    this._store.dispatch(new WhitelistUIActionTypes.GetNextWhitelistPage());
  }

  getPrevWhitelistPage() {
    this._store.dispatch(new WhitelistUIActionTypes.GetPrevWhitelistPage());
  }

  addSignupIDToWhitelist(chat_id: string, users: Whitelist[]) {
    this._store.dispatch(
      new WhitelistUIActionTypes.AddSignupIDToWhitelist(chat_id, users)
    );
  }

  addWhitelistSuccess(users: Whitelist[]) {
    this._store.dispatch(new WhitelistUIActionTypes.AddWhitelistSuccess(users));
  }

  deleteUsersFromWhitelist(chat_id: string, users: string[]) {
    this._store.dispatch(
      new WhitelistUIActionTypes.DeleteUsersFromWhitelist(users, chat_id)
    );
  }

  deleteUsersFromWhitelistSuccess(users: string[]) {
    this._store.dispatch(
      new WhitelistUIActionTypes.DeleteUsersFromWhitelistSuccess(users)
    );
  }

  receivedWhitelistHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Whitelist[],
    hash: string
  ) {
    this._store.dispatch(
      new WhitelistUIActionTypes.ReceivedWhitelistHistoryChunk(
        eop,
        sop,
        currentPage,
        hash
      )
    );
  }

  setStatusAsIdle() {
    this._store.dispatch(new WhitelistUIActionTypes.SetStatusAsIdle());
  }

  setLoadingScreenStatus(status: string) {
    this._store.dispatch(
      new WhitelistUIActionTypes.SetLoadingScreenStatus(status)
    );
  }

  reset() {
    this._store.dispatch(new WhitelistUIActionTypes.ResetWhitelist());
  }
}
