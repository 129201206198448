import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ProductsStoreDispatchers } from './product-store.dispatchers';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import { GetProductItemMethod, GetProductStoreMethod, RemoveProductItemMethod, SetCollectionsToProductMethod, SetProductItemArray, SetProductStoreMethod } from 'src/models/productStore';
import { Router } from '@angular/router';
import { ProductsStoreService } from 'src/app/+merchant/+dashboard/product/products-store.service';
export class ProductsStoreUiEffects {
    constructor(actions$, router, _socketGateway, _uiProductsStoreDispatchers, _productsStoreService, _store) {
        this.actions$ = actions$;
        this.router = router;
        this._socketGateway = _socketGateway;
        this._uiProductsStoreDispatchers = _uiProductsStoreDispatchers;
        this._productsStoreService = _productsStoreService;
        this._store = _store;
        this.productsStoreResetPaging = this.actions$.pipe(ofType("UI_PRODUCTS_STORE_RESET_PAGING" /* UI_PRODUCTS_STORE_RESET_PAGING */), map((action) => {
            this._uiProductsStoreDispatchers.getNextProductsStorePage();
        }));
        this.getNextproductsStorePage = this.actions$.pipe(ofType("UI_PRODUCTS_STORE_GET_NEXT_PAGE" /* UI_PRODUCTS_STORE_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.productsStoreUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, productsUiState]) => {
            const action = val;
            const prevRequest = productsUiState.previousRequest;
            if (!(productsUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                productsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetProductStoreMethod(0, productsUiState.eop));
            }
            else {
                this._uiProductsStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevProductsPage = this.actions$.pipe(ofType("UI_PRODUCTS_STORE_GET_PREV_PAGE" /* UI_PRODUCTS_STORE_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.productsStoreUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, productsUiState]) => {
            const action = val;
            const prevRequest = productsUiState.previousRequest;
            if (!(productsUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                productsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetProductStoreMethod(1, productsUiState.sop));
            }
            else {
                this._uiProductsStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.updateProduct = this.actions$.pipe(ofType("UI_SET_PRODUCTS_STORE" /* UI_SET_PRODUCTS_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetProductStoreMethod(action.product.id, action.product.name, action.product.description, 9, action.product.type, action.product.price, action.product.image, action.product.sku, action.product.status, action.product.compare_at_price, action.product.option, action.product.attribute, action.product.tag));
        }));
        this.getProduct = this.actions$.pipe(ofType("UI_GET_PRODUCT_ITEM" /* UI_GET_PRODUCT_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetProductItemMethod(action.id));
        }));
        this.receivedProductStore = this.actions$.pipe(ofType("UI_RECEIVE_PRODUCT_STORE_ITEM" /* UI_RECEIVE_PRODUCT_STORE_ITEM */), map((action) => {
            this._uiProductsStoreDispatchers.setSelectedProductStore(action.product);
            if (action.product && action.product.option && action.product.option.length === 3) {
                this._productsStoreService._hideOptionBtn.next(true);
            }
            this.router.navigate(['/product/form']);
        }));
        this.setProductsItems = this.actions$.pipe(ofType("UI_SET_PRODUCTS_ITEM_ARRAY" /* UI_SET_PRODUCTS_ITEM_ARRAY */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetProductItemArray(action.product, action.parent_id, action.typeOf));
        }));
        this.removeProductItem = this.actions$.pipe(ofType("UI_REMOVE_PRODUCT_ITEM" /* UI_REMOVE_PRODUCT_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveProductItemMethod(action.id));
        }));
        this.setCollectionsToProduct = this.actions$.pipe(ofType("UI_SET_COLLECTIONS_TO_PRODUCT" /* UI_SET_COLLECTIONS_TO_PRODUCT */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetCollectionsToProductMethod(action.productID, action.collections));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "productsStoreResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "getNextproductsStorePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "getPrevProductsPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "updateProduct", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "getProduct", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "receivedProductStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "setProductsItems", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "removeProductItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsStoreUiEffects.prototype, "setCollectionsToProduct", void 0);
