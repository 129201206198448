<a
  class="app-logo clickable-cursor"
  href="https://nandbox.com/en/"
  aria-label="nandbox App Builder website"
>
  <img
    class="main-logo"
    height="40"
    width="100%"
    alt="nandbox Native App Builder logo"
    src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"
    draggable="false"
  />
  <img
    class="small-logo"
    height="40"
    width="67"
    alt="nandbox Native App Builder logo"
    src="https://nandbox.com/wp-content/uploads/2023/06/nandbox-logo-.webp"
    draggable="false"
  />
</a>

<app-scan
  *ngIf="
    !(authService.authCollection$ | async) ||
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_CHATLIST_SET
  "
  class="auth no-copyable-item"
>
</app-scan>

<!-- Show this component if AUTH_CHATLIST_SET || AUTH_OK -->
<app-auth-list
  *ngIf="
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.AUTH_CHATLIST_SET &&
    (dashboard.allChannels$ | async)?.length > 1
  "
  class="auth no-copyable-item"
>
</app-auth-list>

<!-- Show this component if in init state -->
<!-- 
<div
  class="loader-container"
  *ngIf="
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.INIT_SOKET &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_QR_SET &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_QR_EXPIRE &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_LOGIN_BY_EMAIL &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_CHATLIST_SET
  "
>
  <div class="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div> -->
