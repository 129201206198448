import { Action } from '@ngrx/store';

import { ICampaign } from 'src/models/ICampaign';

export const enum CampaignUIActionTypes {
  UI_LIST_CAMPAIGN = 'UI_CAMPAIGN_LIST',
  UI_CREATE_CAMPAIGN = 'UI_CREATE_CAMPAIGN',
  UI_VIEW_CAMPAIGN = 'UI_VIEW_CAMPAIGN',
  UI_EDIT_CAMPAIGN = 'UI_EDIT_CAMPAIGN',
  UI_CLOSE_CREATE_CAMPAIGN = 'UI_CLOSE_CREATE_CAMPAIGN',
  UI_CLOSE_VIEW_CAMPAIGN = 'UI_CLOSE_VIEW_CAMPAIGN',
  UI_CLOSE_EDIT_CAMPAIGN = 'UI_CLOSE_EDIT_CAMPAIGN',
  UI_CAMPAIGN_GET_NEXT_PAGE = 'UI_CAMPAIGN_GET_NEXT_PAGE',
  UI_CAMPAIGN_GET_PREV_PAGE = 'UI_CAMPAIGN_GET_PREV_PAGE',
  UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK = 'UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK',
  UI_CAMPAIGN_RESET_PAGING = 'UI_CAMPAIGN_RESET_PAGING',
  UI_CAMPAIGN_SET_IDLE_STATUS = 'UI_CAMPAIGN_SET_IDLE_STATUS'
}

export const RESET = 'RESET';
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';

export class ListCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_LIST_CAMPAIGN;
  constructor() {}
}

export class CreateCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_CREATE_CAMPAIGN;
}

export class ViewCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_VIEW_CAMPAIGN;
  constructor(public selectedCampaign: ICampaign) {}
}

export class EditCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_EDIT_CAMPAIGN;
  constructor(public selectedCampaign: ICampaign) {}
}

export class CloseCreateCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_CLOSE_CREATE_CAMPAIGN;
}
export class CloseViewCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_CLOSE_VIEW_CAMPAIGN;
}

export class CloseEditCampaignUI implements Action {
  readonly type = CampaignUIActionTypes.UI_CLOSE_EDIT_CAMPAIGN;
}

export class GetNextCampaignPage implements Action {
  readonly type = CampaignUIActionTypes.UI_CAMPAIGN_GET_NEXT_PAGE;
}

export class GetPrevCampaignPage implements Action {
  readonly type = CampaignUIActionTypes.UI_CAMPAIGN_GET_PREV_PAGE;
}

export class ReceivedCampaignHistoryChunk implements Action {
  readonly type = CampaignUIActionTypes.UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: string[]
  ) {}
}

export class ResetCampaignPaging implements Action {
  readonly type = CampaignUIActionTypes.UI_CAMPAIGN_RESET_PAGING;
}

export class SetStatusAsIdle implements Action {
  readonly type = CampaignUIActionTypes.UI_CAMPAIGN_SET_IDLE_STATUS;
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type Actions =
  | ListCampaignUI
  | CreateCampaignUI
  | ViewCampaignUI
  | EditCampaignUI
  | CloseCreateCampaignUI
  | CloseViewCampaignUI
  | CloseEditCampaignUI
  | GetNextCampaignPage
  | GetPrevCampaignPage
  | ReceivedCampaignHistoryChunk
  | ResetCampaignPaging
  | SetStatusAsIdle
  | DeselectChannel
  | Reset;
