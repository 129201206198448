import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from '../services/http.service';
import { StorageService } from 'src/app/core/storage.service';
import { META_API } from 'src/models/constants';
import { UrlMeta } from 'src/models/MetaData';

@Injectable()
export class LinkPreviewGateway {
  constructor(
    private _httpService: HttpService,
    private _storageService: StorageService
  ) {}

  getMetaFormUrl(linkPreviewUrl: string): Observable<UrlMeta> {
    const linkPreviewRootUrl = this.extractRootDomain(linkPreviewUrl);
    const fullUrl = this._storageService.getApiUrl(META_API) + linkPreviewUrl;
    return this._httpService.getUrl(fullUrl).pipe(
      map(data => {
        if (data && data.data) {
          return {
            linkPreviewTitle: data.data.TITLE,
            linkPreviewDescription: data.data.DESCRIPTION,
            linkPreviewImage: data.data.IMAGE_URL,
            linkPreviewUrl,
            linkPreviewRootUrl
          };
        }
        return null;
      })
    );
  }

  /* Get first link to disply */
  getFirstLink(str) {
    const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    const match = regexp.exec(str);
    return match ? (match[0] ? match[0] : null) : null;
  }

  private extractRootDomain(url: string): string {
    const r = /:\/\/(.[^/]+)/;
    return url.match(r)[1];
  }

  /* Check if image exist and get meta info for it */
  isImageExists(imageUrl: string) {
    if (imageUrl) {
      return false;
    }
    const http = new XMLHttpRequest();
    http.open('HEAD', imageUrl, false);
    http.send();
    return http.status === 200;
  }

  replaceHttp(url: string) {
    return url.replace('http://', 'https://');
  }
}
