import {
  CREATE_MY_MENU_METHOD,
  SET_MY_MENU_METHOD,
  GET_MY_MENUS_METHOD,
  MERCHANT_PAGE_SIZE,
  GET_MENU_METHOD,
  CREATE_BOT_MENU_REF,
  UPDATE_BOT_MENU_REF
} from './constants';

export interface SubMenu {
  id?: string;
  sub_menu?: BotMenu;
  menu?: BotMenu;
}

export interface RcvdMyMenusMessage {
  chat_id?: string;
  mymenus?: SubMenu[];
  sop?: number;
  eop?: number;
}

export interface RcvdMyMenuMessage {
  chat_id?: string;
  mymenu?: SubMenu;
  reference?: string;
}

export interface BotMenuButton {
  button_id?: string; // local
  next_menu?: string;
  button_next_page?: NextPage;
  button_span?: number;
  button_order?: number;
  button_access?: any;
  button_login?: any;
  button_textcolor?: string;
  button_callback?: string;
  button_bgcolor?: string;
  button_label?: string;
  button_icon?: string;
  button_icon_bgcolor?: string;
  button_url?: null;
  button_query?: null;
  next_menu_id?: string; // Web Local ID
  button_style?: string;
  button_img_url?: string;
  button_description?: string;
  button_action?: string;
  button_data?: string;
  type?: number;
  url_2?: string;
  url_3?: string;
  bg_color_2?: string;
  bg_color_3?: string;
  length?: number;
  button_form?: string;
  button_type?: string;
  button_value?: any[];
  button_sublabel?: string;
  button_border_color?: string;
  button_keyboard?: string;
  button_asbect_ratio?: string[];
  button_option_color?: string;
  button_option?: BotMenuOption[];
  button_newpage?: boolean;
  button_db?: number;
  button_label_color?: string;
  button_sublabel_color?: string;
}

export interface BotMenuOption {
  id?: string;
  label?: string;
  sublabel?: string;
  image?: string;
  icon?: string;
  value?: string;
}

export interface NextPage {
  id?: string;
  type?: string;
}

export interface BotMenuButtonValue {
  id?: string;
  value?: string;
}

export interface BotMenuRow {
  row_id?: number; // local
  row_order?: number;
  buttons?: BotMenuButton[];
}

export interface BotMenu {
  id?: string; // with server
  reference?: number; // with server
  menu_id?: string; // local
  menu_ref?: string; // local
  menu_name?: string;
  menu_date?: number;
  next_menu?: string;
  api_id?: string;
  rows?: BotMenuRow[];
  type?: number;
}

export class ListMyMenu {
  readonly method = GET_MY_MENUS_METHOD;
  constructor(public type?: number) {}
}

export class SetMyMenu {
  readonly method = SET_MY_MENU_METHOD;
  constructor(
    public menu: BotMenu,
    public sub_menu: BotMenu,
    public name: string,
    public id: string,
    public reference: string,
    public next_menu: string,
    public ref = UPDATE_BOT_MENU_REF
  ) {}
}

export class CreateMenu {
  readonly method = CREATE_MY_MENU_METHOD;
  constructor(
    public menu: BotMenu,
    public sub_menu: BotMenu,
    public name: string,
    public reference: string,
    public next_menu: string,
    public type: number,
    public ref = CREATE_BOT_MENU_REF
  ) {}
}

export class GetMenus {
  readonly method = GET_MENU_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public hash?: string,
    public reference?: number
  ) {}
}
