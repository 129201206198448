import { Store } from '@ngrx/store';
import * as MyPageActions from './myPage.actions';
import { initRcvdPage } from './myPage.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MyPageDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    requestMyPage(id) {
        this._store.dispatch(new MyPageActions.RequestMyPage(id));
    }
    deleteMyPage(pageID) {
        this._store.dispatch(new MyPageActions.DeleteMyPage(pageID));
    }
    myPageDeleted(pageID) {
        this._store.dispatch(new MyPageActions.MyPageDeleted(pageID));
    }
    getDetailsOfLinkedPage(pageID) {
        this._store.dispatch(new MyPageActions.GetDetailsOfLinkedPage(pageID));
    }
    receiveMyPage(rcvdPage) {
        const initializedPage = initRcvdPage(false, rcvdPage);
        this._store.dispatch(new MyPageActions.ReceiveMyPage(initializedPage));
    }
    receiveInitializedPage(rcvdPage) {
        this._store.dispatch(new MyPageActions.ReceiveMyPage(rcvdPage));
    }
    receiveMyPages(rcvdPages) {
        this._store.dispatch(new MyPageActions.ReceiveMyPages(rcvdPages));
    }
    createMyPage(reference, myPageForm, name, url, image, desc, templateId) {
        this._store.dispatch(new MyPageActions.CreateMyPage(reference, myPageForm, name, url, image, desc, templateId));
    }
    createPageTemplate(myPage, name, image, desc) {
        this._store.dispatch(new MyPageActions.CreatePageTemplate(myPage, name, image, desc));
    }
    myPageCreated(createdPage, reference) {
        const initializedPage = initRcvdPage(false, createdPage, reference);
        this._store.dispatch(new MyPageActions.MyPageCreated(initializedPage, reference));
    }
    pageTemplateCreated(pageId, childTemplateId) {
        this._store.dispatch(new MyPageActions.PageTemplateCreated(pageId, childTemplateId));
    }
    updateMyPage(id, myPageDetails) {
        this._store.dispatch(new MyPageActions.UpdateMyPage(id, myPageDetails));
    }
    updateMyPageForm(formID, title, image, template, background) {
        this._store.dispatch(new MyPageActions.UpdateMyPageForm(formID, title, image, template, background));
    }
    updateMyPageContainer(formID, containerID, containerUpdatedProps) {
        this._store.dispatch(new MyPageActions.UpdateMyPageContainer(formID, containerID, containerUpdatedProps));
    }
    deleteMyPageContainer(formID, container) {
        this._store.dispatch(new MyPageActions.DeleteMyPageContainer(formID, container));
    }
    addMyPageContainer(formID, container) {
        this._store.dispatch(new MyPageActions.AddMyPageContainer(formID, container));
    }
    reorderPageContainers(formID, containerID, newPosition) {
        this._store.dispatch(new MyPageActions.ReorderPageContainers(formID, containerID, newPosition));
    }
    updateMyPageComponent(formID, containerID, componentID, componentUpdatedProps, needPageID) {
        this._store.dispatch(new MyPageActions.UpdateMyPageComponent(formID, containerID, componentID, componentUpdatedProps, needPageID));
    }
    deleteMyPageComponent(formID, containerID, component) {
        this._store.dispatch(new MyPageActions.DeleteMyPageComponent(formID, containerID, component));
    }
    addMyPageComponent(formID, containerID, component) {
        this._store.dispatch(new MyPageActions.AddMyPageComponent(formID, containerID, component));
    }
    reorderPageComponents(formID, containerID, componentID, newPosition) {
        this._store.dispatch(new MyPageActions.ReorderPageComponents(formID, containerID, componentID, newPosition));
    }
}
MyPageDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyPageDispatchers_Factory() { return new MyPageDispatchers(i0.ɵɵinject(i1.Store)); }, token: MyPageDispatchers, providedIn: "root" });
