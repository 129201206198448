import { Actions } from './storesUI.actions';

import * as actions from './storesUI.actions';
import { StoreUI } from 'src/models/Stores';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';
import { MobileStoreMiddleware } from './storesUIMiddleware';

const INITIAL_STATE: StoreUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  selected_chat: null
};

export function storeUiReducer(
  state = INITIAL_STATE,
  action: Actions
): StoreUI {
  switch (action.type) {
    case actions.StoresUIActionTypes.STORE_RECEIVED: {
      if (
        !MobileStoreMiddleware.isMobileStoreExist(
          state.currentPage,
          action.store
        )
      ) {
        return {
          ...state,
          currentPage: [...state.currentPage, action.store]
        };
      }
      return {
        ...state,
        currentPage: state.currentPage.map(r => {
          if (r.id === action.store.id) {
            return action.store;
          }
          return r;
        })
      };
    }
    case actions.StoresUIActionTypes.STORE_DELETE:
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.id !== action.id)
      };
    case actions.StoresUIActionTypes.UI_STORE_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.StoresUIActionTypes.UI_STORE_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.StoresUIActionTypes.UI_STORE_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.StoresUIActionTypes.UI_STORE_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.StoresUIActionTypes.UI_STORE_SET_SELECTED_CHAT: {
      return {
        ...state,
        selected_chat: action.selected_chat
      };
    }
    case actions.StoresUIActionTypes.UI_STORE_RESET_PAGING: {
      return {
        ...INITIAL_STATE,
        selected_chat: state.selected_chat
      };
    }
    case actions.StoresUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
