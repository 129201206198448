import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AppForm } from 'src/models/AppForm';
import * as AuthMethods from 'src/models/IAuth';
import { AuthService } from 'src/app/core/auth.service';
import { HtttpMethodErrors } from 'src/models/constants';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
import { mustMatchValidator } from 'src/app/validators/mustMatchValidator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent implements OnInit, AppForm {
  waiting: boolean;
  form: FormGroup;
  showLoginScreen = false;
  hideResendCode = false;

  @Input() accountEmail: string;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private _validateForm: FormValidatorsService,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.waiting = false;
    this.form = this.setFormGroup();
  }

  get tac() {
    return this.form.get('tac');
  }

  get newPassword() {
    return this.form.get('newPassword');
  }

  get confirmNewPassword() {
    return this.form.get('confirmNewPassword');
  }

  setFormGroup() {
    return this.formBuilder.group(
      {
        tac: [
          '',
          [
            Validators.required,
            noWhitespaceValidator,
            Validators.pattern(/\d/g)
          ]
        ],
        newPassword: ['', [Validators.required, noWhitespaceValidator]],
        confirmNewPassword: ['', [Validators.required, noWhitespaceValidator]]
      },
      {
        validator: mustMatchValidator('newPassword', 'confirmNewPassword')
      }
    );
  }

  onSubmit() {
    this.waiting = true;
    if (this.form.valid) {
      this.authService
        .resetPassword(
          this.accountEmail,
          this.tac.value,
          this.newPassword.value,
          this.confirmNewPassword.value
        )
        .subscribe(response => {
          const res = <AuthMethods.HttpResult>response;
          if (res.result === 0) {
            this.authService.emailTacSuccess(this.form.value);
            this.showLoginScreen = true;
          } else if (
            res.error === HtttpMethodErrors.WRONG_TAC_2 ||
            res.error === HtttpMethodErrors.WRONG_TAC
          ) {
            this.tac.setErrors({ wrongTac: true });
            this.tac.setErrors({ resetCodePatteren: true });
          } else if (res.error === HtttpMethodErrors.TAC_EXPIRED) {
            this.tac.setErrors({ expired: true });
          } else if (res.error === HtttpMethodErrors.PASSWORD_IN_HISTORY) {
            this.newPassword.setErrors({ passwordInHistory: true });
          } else if (res.error === HtttpMethodErrors.PASSWORD_NOT_MATCH) {
            this.confirmNewPassword.setErrors({ passwordNotMatch: true });
          } else if (
            res.error === HtttpMethodErrors.PASSWORD_TOO_SHORT ||
            res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_LOWERCASE ||
            res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_UPPERCASE ||
            res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_DIGIT ||
            res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_SPECIAL ||
            res.error === HtttpMethodErrors.PASSWORD_MATCHES_PATTERN ||
            res.error === HtttpMethodErrors.PASSWORD_MATCHES_DICTIONARY ||
            res.error === HtttpMethodErrors.PASSWORD_TOO_LONG
          ) {
            this.newPassword.setErrors({ patternIsNotMarch: true });
            this.confirmNewPassword.setErrors({});
          } else {
            // this.newPassword.setErrors({ generalError: true });
            this.confirmNewPassword.setErrors({ generalError: true });
          }
          this.waiting = false;
          this._ref.detectChanges();
        });
    } else {
      this.waiting = false;
      this._validateForm.validateAllFormFields(this.form);
      this._ref.detectChanges();
    }
  }

  reSendCode() {
    this.authService
      .getResetPasswordTac(this.accountEmail)
      .subscribe(res => res);
    this.hideResendCode = true;
    var timeLeft = 30;
    var elem = document.getElementById('countdown');
    elem.innerHTML = 'Resend Code in ' + timeLeft + ' seconds';
    timeLeft--;
    var timerId = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(timerId);
        elem.innerHTML = '';
        this.hideResendCode = false;
        this._ref.detectChanges();
      } else {
        elem.innerHTML = 'Resend Code in ' + timeLeft + ' seconds';
        timeLeft--;
      }
    }, 1000);
  }
}
