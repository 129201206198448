/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./border-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./border-btn.component";
var styles_BorderBtnComponent = [i0.styles];
var RenderType_BorderBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BorderBtnComponent, data: {} });
export { RenderType_BorderBtnComponent as RenderType_BorderBtnComponent };
export function View_BorderBtnComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { btn: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["borderBtnId", 1]], null, 2, "button", [["class", "app-btn"]], [[8, "disabled", 0], [8, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.type; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_BorderBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-border-btn", [], null, null, null, View_BorderBtnComponent_0, RenderType_BorderBtnComponent)), i1.ɵdid(1, 573440, null, 0, i2.BorderBtnComponent, [], null, null)], null, null); }
var BorderBtnComponentNgFactory = i1.ɵccf("app-border-btn", i2.BorderBtnComponent, View_BorderBtnComponent_Host_0, { disabled: "disabled", type: "type", focused: "focused" }, { btnClicked: "btnClicked" }, ["*"]);
export { BorderBtnComponentNgFactory as BorderBtnComponentNgFactory };
