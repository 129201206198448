import {
  LocalComponent,
  LocalItem
} from '../../../models/ChannelAppLocalConfig';
import { uuidv4 } from 'src/models/constants';

export function initNewItem(
  parentComponent: LocalComponent,
  specialItemValues?: LocalItem
): LocalItem {
  let newItem: LocalItem = {};
  newItem.ref = uuidv4();
  newItem.parentComponentRef = parentComponent.ref;
  newItem.order = 0;
  newItem.appClassName = parentComponent.appClassName;
  newItem.containerType = parentComponent.containerType;
  newItem.componentType = parentComponent.component_type;
  if (specialItemValues) {
    newItem = { ...newItem, ...specialItemValues };
  }
  return newItem;
}
