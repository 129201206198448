import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';
import { Actions } from './storeMenuUI.actions';
import * as actions from './storeMenuUI.actions';
import { StoreMenuUI } from 'src/models/StoreMenu';

const INITIAL_STATE: StoreMenuUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function storeMenuUiReducer(
  state = INITIAL_STATE,
  action: Actions
): StoreMenuUI {
  switch (action.type) {
    case actions.StoreMenuUIActionTypes.STORE_MENU_RECEIVED: {
      const isFound =
        state.currentPage.filter(fn => fn.id === action.storeMenu.id).length >
        0;

      if (isFound) {
        return {
          ...state,
          currentPage: state.currentPage.map(r => {
            if (r.id === action.storeMenu.id) {
              return action.storeMenu;
            }
            return r;
          })
        };
      }

      return {
        ...state,
        currentPage: [...state.currentPage, action.storeMenu]
      };
    }
    case actions.StoreMenuUIActionTypes.MENU_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.id !== action.id)
      };
    case actions.StoreMenuUIActionTypes.UI_STORE_MENU_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.StoreMenuUIActionTypes.UI_STORE_MENU_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.StoreMenuUIActionTypes.UI_STORE_MENU_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.StoreMenuUIActionTypes.UI_STORE_MENU_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }

    case actions.StoreMenuUIActionTypes.UI_STORE_MENU_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.StoreMenuUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
