import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { SetPaymentProviderMethod, GetPaymentProvidersMethod, EnableAllServicesMethod } from 'src/models/PaymentGateways';
export class PaymentProvidersEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.setPaymentProvider = this.actions$.pipe(ofType("SET_PAYMENT_PROVIDER" /* SET_PAYMENT_PROVIDER */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetPaymentProviderMethod(action.providerId, action.config, action.active));
        }));
        this.getPaymentProviders = this.actions$.pipe(ofType("GET_PAYMENT_PROVIDERS" /* GET_PAYMENT_PROVIDERS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetPaymentProvidersMethod());
        }));
        this.enableAllServices = this.actions$.pipe(ofType("ENABLE_ALL_SERVICES" /* ENABLE_ALL_SERVICES */), map((action) => {
            this._socketGateway.sendSocketMessage(new EnableAllServicesMethod());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentProvidersEffects.prototype, "setPaymentProvider", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentProvidersEffects.prototype, "getPaymentProviders", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentProvidersEffects.prototype, "enableAllServices", void 0);
