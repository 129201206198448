import * as ProductMethos from 'src/models/Product';

export class productsUIMiddleware {
  public static isProductExist(
    list: ProductMethos.Product[],
    rcvdObj: ProductMethos.Product
  ) {
    for (let i = 0; i < list.length; i++) {
      if (rcvdObj.id && list[i].id === rcvdObj.id) {
        return true;
      }
    }
    return false;
  }
}
