import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as summaryMethods from 'src/models/summaryUI';
import * as isEqual from 'lodash.isequal';
import { SummaryUIDispatchers } from './summaryUI.dispatchers';
export class SummaryUIEffects {
    constructor(actions$, _socketGateway, _store, _summaryUIDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._summaryUIDispatchers = _summaryUIDispatchers;
        this.setDashboard = this.actions$.pipe(ofType("UI_SET_DASHBOARD" /* UI_SET_DASHBOARD */), map((action) => {
            this._socketGateway.sendSocketMessage(new summaryMethods.SetUserDashboard(action.dashboard));
        }));
        this.getDashboard = this.actions$.pipe(ofType("UI_GET_USER_DASHBOARD_LIST" /* UI_GET_USER_DASHBOARD_LIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new summaryMethods.GetUserDashboard());
        }));
        this.createNewWidget = this.actions$.pipe(ofType("UI_CREATE_NEW_WIDGET" /* UI_CREATE_NEW_WIDGET */), withLatestFrom(this._store
            .select(state => state.summaryUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, summaryList]) => {
            const action = val;
            const dashboard = {
                widgets: [...summaryList.currentPage]
            };
            this._summaryUIDispatchers.setDashboard(dashboard);
        }));
        this.updateDashboardList = this.actions$.pipe(ofType("UI_UPDATE_DASHBOARD_LIST" /* UI_UPDATE_DASHBOARD_LIST */), withLatestFrom(this._store
            .select(state => state.summaryUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, summaryList]) => {
            const action = val;
            const dashboard = {
                widgets: [...summaryList.currentPage]
            };
            this._summaryUIDispatchers.setDashboard(dashboard);
        }));
        this.updateWidget = this.actions$.pipe(ofType("UI_UPDATE_WIDGET" /* UI_UPDATE_WIDGET */), withLatestFrom(this._store
            .select(state => state.summaryUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, summaryList]) => {
            const action = val;
            const dashboard = {
                widgets: [...summaryList.currentPage]
            };
            this._summaryUIDispatchers.setDashboard(dashboard);
        }));
        this.removeWidget = this.actions$.pipe(ofType("UI_REMOVE_WIDGET_FROM_LIST" /* UI_REMOVE_WIDGET_FROM_LIST */), withLatestFrom(this._store
            .select(state => state.summaryUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, summaryList]) => {
            const action = val;
            const dashboard = {
                widgets: [...summaryList.currentPage]
            };
            this._summaryUIDispatchers.setDashboard(dashboard);
        }));
        this.saveDefaultedDashboardList = this.actions$.pipe(ofType("UI_SAVE_DEFAULTED_DASHBOARD_LIST" /* UI_SAVE_DEFAULTED_DASHBOARD_LIST */), withLatestFrom(this._store
            .select(state => state.summaryUIReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, summaryList]) => {
            const action = val;
            const dashboard = {
                widgets: [...summaryList.currentPage]
            };
            this._summaryUIDispatchers.setDashboard(dashboard);
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "setDashboard", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "getDashboard", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "createNewWidget", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "updateDashboardList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "updateWidget", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "removeWidget", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SummaryUIEffects.prototype, "saveDefaultedDashboardList", void 0);
