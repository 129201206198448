import { Store } from '@ngrx/store';
import * as MainChatActions from './mainChat.actions';
import { INDIVIDUAL, GROUP, CHANNEL } from '../../../models/constants';
import { HelperService } from '../../core/helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../core/helper.service";
export class MainChatDispatchers {
    constructor(_store, _helperService) {
        this._store = _store;
        this._helperService = _helperService;
    }
    mainChatReceived(payload) {
        this._store.dispatch(new MainChatActions.MainChatReceived(payload));
    }
    mainChatDeselected(id, lastTypedMessage) {
        this._store.dispatch(new MainChatActions.MainChatDeselected(id, lastTypedMessage));
    }
    mainChatSelected(mainchat) {
        this._store.dispatch(new MainChatActions.MainChatSelected(mainchat));
    }
    mainChatHistoryRequested(id) {
        this._store.dispatch(new MainChatActions.MainChatHistoryRequested(id));
    }
    mainChatMemberCountReceived(id, memberCount) {
        this._store.dispatch(new MainChatActions.MainChatMemberCountReceived(id, memberCount));
    }
    loggedInLeftMainChat(id) {
        this._store.dispatch(new MainChatActions.LoggedInLeftMainChat(id));
    }
    updateNumberOfUnreadMessages(id, unreadMessagesCount, isFirstLevelMessage, isRecalled) {
        this._store.dispatch(new MainChatActions.UpdateNumberOfUnreadMessages(id, unreadMessagesCount, isFirstLevelMessage, isRecalled));
    }
    updateLastMessage(mainChat, lastMessage, afterRecall) {
        this._store.dispatch(new MainChatActions.UpdateLastMessage(mainChat, lastMessage, afterRecall));
    }
    updateLastMessageStatus(id, lastMessageStatus, reference) {
        this._store.dispatch(new MainChatActions.UpdateLastMessageStatus(id, lastMessageStatus, reference));
    }
    incrementNumberOfNotViewedMessages(mainChat) {
        this._store.dispatch(new MainChatActions.IncrementNumberOfNotViewedMessages(mainChat));
    }
    decrementNumberOfNotViewedMessages(mainChat) {
        this._store.dispatch(new MainChatActions.DecrementNumberOfNotViewedMessages(mainChat));
    }
    resetNumberOfNotViewedMessages(mainChatID) {
        this._store.dispatch(new MainChatActions.ResetNumberOfNotViewedMessages(mainChatID));
    }
    updateEndOfPage(mainChatID, eopLevel1) {
        this._store.dispatch(new MainChatActions.UpdateEndOfPage(mainChatID, eopLevel1));
    }
    UpdateEopLevel1(mainChatID, endOfPage) {
        this._store.dispatch(new MainChatActions.UpdateEopLevel1(mainChatID, endOfPage));
    }
    requestHistory(parentMsgID, userID, requestOnce, subChatID) {
        this._store.dispatch(new MainChatActions.RequestHistory(parentMsgID, userID, requestOnce, subChatID));
    }
    replyReceivedInSelectedChat(message) {
        this._store.dispatch(new MainChatActions.ReplyReceivedInSelectedChat(message));
    }
    updateMainChatImages(id, imageUrl) {
        this._store.dispatch(new MainChatActions.UpdateMainChatImages(id, imageUrl));
    }
    getNumberOfUnreadMessages(currentMsgs, mainChat) {
        return currentMsgs.filter(msg => {
            if (mainChat.type === INDIVIDUAL) {
                return (!msg.group_id &&
                    (mainChat.id === msg.sender_id || mainChat.id === msg.receiver_id) &&
                    !msg.loggedInIsSender &&
                    !msg.isRead);
            }
            else {
                return (mainChat.id === msg.group_id && !msg.loggedInIsSender && !msg.isRead);
            }
        }).length;
    }
    getLastMessageInChat(currentMsgs, mainChat) {
        const lastMessages = currentMsgs
            .filter(msg => {
            if (mainChat.type === INDIVIDUAL) {
                return (!msg.group_id &&
                    (msg.sender_id === mainChat.id || msg.receiver_id === mainChat.id));
            }
            else {
                if (mainChat.type === GROUP) {
                    return msg.group_id === mainChat.id && msg.group_type === 100;
                }
                else if (mainChat.type === CHANNEL) {
                    return msg.group_id === mainChat.id && msg.group_type === 101;
                }
            }
        })
            .reverse();
        return lastMessages[0]
            ? this._helperService.handleDifferentReplyTypes(lastMessages[0])
            : null;
    }
    mainChatDeleted(chat_id) {
        this._store.dispatch(new MainChatActions.MainChatDeleted(chat_id));
    }
    contactsNamesReceived(contacts) {
        this._store.dispatch(new MainChatActions.ContactsNamesReceived(contacts));
    }
    resetAllEndOfPage() {
        this._store.dispatch(new MainChatActions.ResetAllEndOfPage());
    }
}
MainChatDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MainChatDispatchers_Factory() { return new MainChatDispatchers(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.HelperService)); }, token: MainChatDispatchers, providedIn: "root" });
