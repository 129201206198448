import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { MessageMediaStatus } from 'src/models/constants';
import { CellsService } from '../cells.service';

@Component({
  selector: 'app-sticker-cell',
  templateUrl: './sticker-cell.component.html',
  styleUrls: ['./sticker-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickerCellComponent {
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  @Input() localMedia: string;
  @Input() mediaStatus: string;
  @Input() percent: number;
  @Input() themeColor: string;

  @Output() retryNotify: EventEmitter<boolean> = new EventEmitter(false);
  @Output() cancelNotify: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private _cellsService: CellsService) {}

  get theme() {
    return {
      'border-top-color': this.themeColor,
      'border-right-color': this.themeColor
    };
  }

  get media() {
    return this._cellsService.getMedia(this.localMedia);
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
