import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keepHtml'
})
export class KeepHtmlPipe implements PipeTransform {
  transform(content) {
    return content.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }
}
