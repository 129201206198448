/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./colored-text-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/pipes/twemoji/twemoji.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../shared/pipes/multi-line/multi-line.pipe";
import * as i5 from "../../shared/pipes/keep-html/keep-html.pipe";
import * as i6 from "./colored-text-cell.component";
var styles_ColoredTextCellComponent = [i0.styles];
var RenderType_ColoredTextCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColoredTextCellComponent, data: {} });
export { RenderType_ColoredTextCellComponent as RenderType_ColoredTextCellComponent };
export function View_ColoredTextCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.TwemojiPipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.MultiLinePipe, []), i1.ɵpid(0, i5.KeepHtmlPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "colored-text"]], [[4, "backgroundColor", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), i1.ɵppd(6, 1), i1.ɵppd(7, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bgColor; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 2), _co.text)))))); _ck(_v, 4, 0, currVal_1); }); }
export function View_ColoredTextCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-colored-text-cell", [], null, null, null, View_ColoredTextCellComponent_0, RenderType_ColoredTextCellComponent)), i1.ɵdid(1, 114688, null, 0, i6.ColoredTextCellComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColoredTextCellComponentNgFactory = i1.ɵccf("app-colored-text-cell", i6.ColoredTextCellComponent, View_ColoredTextCellComponent_Host_0, { text: "text", bgColor: "bgColor" }, {}, []);
export { ColoredTextCellComponentNgFactory as ColoredTextCellComponentNgFactory };
