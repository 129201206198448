import {
  GET_MAP_SERVICE_METHOD,
  GET_MAP_SERVICES_METHOD,
  REMOVE_MAP_SERVICE_METHOD,
  CREATE_MAP_SERVICE_METHOD,
  CREATE_MAP_SERVICE_REF,
  SET_MAP_SERVICE_METHOD,
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection,
  MERCHANT_PAGE_SIZE
} from './constants';

export interface MapService {
  id?: string; // The key
  start_date?: string; // "2019-01-30"
  end_date?: string; // "2019-12-31"
  map_id?: string;
  name?: string;
  service_id?: string;
  exceptions?: string[];
  ms?: number; // the type of the map service ex: school bus
  ms_name?: string;
  saturday?: number; // 0 or 1 means on or off on that day
  sunday?: number; // 0 or 1 means on or off on that day
  monday?: number; // 0 or 1 means on or off on that day
  tuesday?: number; // 0 or 1 means on or off on that day
  wednesday?: number; // 0 or 1 means on or off on that day
  thursday?: number; // 0 or 1 means on or off on that day
  friday?: number; // 0 or 1 means on or off on that day
}

export interface MapServiceUI {
  currentPage?: MapService[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  currentMapServiceScreen?: string;
}

export class GetMapService {
  readonly method = GET_MAP_SERVICE_METHOD;
  constructor(public service_id: string) {}
}

export class GetMapServices {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_SERVICES_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public hash?: string
  ) {}
}

export class RemoveMapService {
  readonly method = REMOVE_MAP_SERVICE_METHOD;
  constructor(public id: string) {}
}

export class CreateMapService {
  readonly method = CREATE_MAP_SERVICE_METHOD;
  readonly reference = CREATE_MAP_SERVICE_REF;
  constructor(mapService: MapService) {
    for (const key in mapService) {
      if (mapService.hasOwnProperty(key)) {
        this[key] = mapService[key];
      }
    }
  }
}

export class SetMapService {
  readonly method = SET_MAP_SERVICE_METHOD;
  constructor(mapService: MapService) {
    for (const key in mapService) {
      if (mapService.hasOwnProperty(key)) {
        this[key] = mapService[key];
      }
    }
  }
}
