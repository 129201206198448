import { GET_APPS_METHOD, ADD_APP_METHOD } from './constants';

export interface ConfiguredApp {
  id?: string;
  name?: string;
}

export class GetAppsMethod {
  readonly method = GET_APPS_METHOD;
  constructor() {}
}

export class AddAppMethod {
  readonly method = ADD_APP_METHOD;
  constructor(public name: string) {}
}
