import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemDispatchers } from 'src/app/store/channelAppItems/item.dispatchers';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { MyPageDispatchers } from 'src/app/store/channelMyPage/myPage.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ContainerDispatchers } from 'src/app/store/channelAppContainers/container.dispatchers';
import { AppClassDispatchers } from 'src/app/store/channelAppClasses/appClass.dispatchers';
import { AppConfigDispatchers } from 'src/app/store/channelAppConfig/appConfig.dispatchers';
import { ComponentDispatchers } from 'src/app/store/channelAppComponents/component.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';
import { AppReleaseDispatchers } from 'src/app/store/channelAppReleases/appRelease.dispatchers';
import { InstantPageDispatchers } from 'src/app/store/channelAppInstantPage/instantPage.dispatchers';
import { ChannelAppUiDispatchers } from 'src/app/store/channelAppUI/channelAppUI.dispatchers';
import { AppPublishingDispatchers } from 'src/app/store/appPublishing/appPublishing.dispatchers';
import { AppConfigHistoryDispatchers } from 'src/app/store/appConfigHistory/appConfigHistory.dispatchers';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
import { AppClassNames, MessageTypes, ReleasesPageScreens, ReleasesIOSPageScreens } from 'src/models/constants';
import { AdsProvidersDispatchers } from './../../../store/adsProvider/adsProvider.dispatchers';
import { AppChannelSettingsDispatchers } from 'src/app/store/appChannelSettings/appChannelSettings.dispatchers';
import { StmpDispatchers } from './../../../store/smtp/smtp.dispatchers';
import { MainChatDispatchers } from './../../../store/mainChats/mainChat.dispatchers';
// import { UploadAppIcon } from './../../../store/channelAppConfig/appConfig.actions';
import { ThirdPartyInfoDispatchers } from 'src/app/store/thirdPartyInfo/thirdPartyInfo.dispatchers';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/auth/auth.dispatchers";
import * as i3 from "../../../store/channelAppUI/channelAppUI.dispatchers";
import * as i4 from "../../../store/channelAppItems/item.dispatchers";
import * as i5 from "../../../store/channelAppComponents/component.dispatchers";
import * as i6 from "../../../store/channelAppContainers/container.dispatchers";
import * as i7 from "../../../store/channelAppClasses/appClass.dispatchers";
import * as i8 from "../../../store/channelAppConfig/appConfig.dispatchers";
import * as i9 from "../../../store/channelAppReleases/appRelease.dispatchers";
import * as i10 from "../../../store/channelMyPage/myPage.dispatchers";
import * as i11 from "../../../store/channelAppInstantPage/instantPage.dispatchers";
import * as i12 from "../../../store/appPublishing/appPublishing.dispatchers";
import * as i13 from "../../../store/appConfigHistory/appConfigHistory.dispatchers";
import * as i14 from "../../../store/chatMembers/chatMember.dispatchers";
import * as i15 from "../../../store/paymentProviders/paymentProviders.dispatchers";
import * as i16 from "../../../store/adsProvider/adsProvider.dispatchers";
import * as i17 from "../../../store/appChannelSettings/appChannelSettings.dispatchers";
import * as i18 from "../../../store/smtp/smtp.dispatchers";
import * as i19 from "../../../store/mainChats/mainChat.dispatchers";
import * as i20 from "../../../store/thirdPartyInfo/thirdPartyInfo.dispatchers";
import * as i21 from "../../../store/ui/ui.dispatchers";
export class AppMgmtService {
    constructor(_selectors, _authDispatchers, _channelAppDispatchers, _itemDispatchers, _componentDispatchers, _containerDispatchers, _appClassDispatchers, _appConfigDispatchers, _appReleaseDispatchers, _myPageDispatchers, _instantPageDispatchers, _appPublishingDispatchers, _appConfigHistoryDispatchers, _chatMemberDispatchers, _paymentProvidersDispatchers, _adsProvidersDispatchers, _appChannelSettingsDispatchers, _smtpDispatchers, _mainChatDispatchers, _thirdPartyInfoDispatchers, _uiDispatcher) {
        this._selectors = _selectors;
        this._authDispatchers = _authDispatchers;
        this._channelAppDispatchers = _channelAppDispatchers;
        this._itemDispatchers = _itemDispatchers;
        this._componentDispatchers = _componentDispatchers;
        this._containerDispatchers = _containerDispatchers;
        this._appClassDispatchers = _appClassDispatchers;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._appReleaseDispatchers = _appReleaseDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this._instantPageDispatchers = _instantPageDispatchers;
        this._appPublishingDispatchers = _appPublishingDispatchers;
        this._appConfigHistoryDispatchers = _appConfigHistoryDispatchers;
        this._chatMemberDispatchers = _chatMemberDispatchers;
        this._paymentProvidersDispatchers = _paymentProvidersDispatchers;
        this._adsProvidersDispatchers = _adsProvidersDispatchers;
        this._appChannelSettingsDispatchers = _appChannelSettingsDispatchers;
        this._smtpDispatchers = _smtpDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this._thirdPartyInfoDispatchers = _thirdPartyInfoDispatchers;
        this._uiDispatcher = _uiDispatcher;
        this.showAppleKeyForm = new BehaviorSubject({
            show: false
        });
        this.showAppleKeyFormObservable$ = this.showAppleKeyForm.asObservable();
        this._imageUrl = new BehaviorSubject('');
        this.imageUrl$ = this._imageUrl.asObservable();
        this._mayAttempts = new BehaviorSubject(false);
        this.mayAttempts$ = this._mayAttempts.asObservable();
        this._loading = new BehaviorSubject(false);
        this.loading$ = this._loading.asObservable();
        this._selectedCreditCard = new BehaviorSubject({});
        this.selectedCreditCard$ = this._selectedCreditCard.asObservable();
        this._buttonArray = new BehaviorSubject([]);
        this.buttonArray$ = this._buttonArray.asObservable();
        this._newCreatedButtons = new BehaviorSubject([]);
        this.newCreatedButtons$ = this._newCreatedButtons.asObservable();
        this._navMenuSelectedItem = new BehaviorSubject({});
        this.navMenuSelectedItem$ = this._navMenuSelectedItem.asObservable();
        this._showConfirmMsg = new BehaviorSubject(null);
        this.showConfirmMsg$ = this._showConfirmMsg.asObservable();
        this._selectedItem = new BehaviorSubject({});
        this.selectedItem$ = this._selectedItem.asObservable();
        this._appIcon = new BehaviorSubject(null);
        this.appIcon$ = this._appIcon.asObservable();
        this._selectedVendor = new BehaviorSubject('');
        this.selectedVendor$ = this._selectedVendor.asObservable();
        this._demoAccStatus = new BehaviorSubject(null);
        this.demoAccStatus$ = this._demoAccStatus.asObservable();
        this._demoAccTyp = new BehaviorSubject(null);
        this.demoAccTyp$ = this._demoAccTyp.asObservable();
        this._demoAccMustWait = new BehaviorSubject(0);
        this.demoAccMustWait$ = this._demoAccMustWait.asObservable();
        this._selectedTempCat = new BehaviorSubject(null);
        this.selectedTempCat$ = this._selectedTempCat.asObservable();
        this._oldVendors = new BehaviorSubject([]);
        this.oldVendors$ = this._oldVendors.asObservable();
        this._showSavePopup = new BehaviorSubject(false);
        this.showSavePopup$ = this._showSavePopup.asObservable();
        this._privilegModule = new BehaviorSubject({});
        this.privilegModule$ = this._privilegModule.asObservable();
        this._selectedTempate = new BehaviorSubject(null);
        this.selectedTempate$ = this._selectedTempate.asObservable();
        this._selectedMenu = new BehaviorSubject({});
        this.selectedMenu$ = this._selectedMenu.asObservable();
        this._createNewTab = new BehaviorSubject(false);
        this.createNewTab$ = this._createNewTab.asObservable();
        this._showSetting = new BehaviorSubject(false);
        this.showSetting$ = this._showSetting.asObservable();
        this._selectedMenuButton = new BehaviorSubject(undefined);
        this.selectedMenuButton$ = this._selectedMenuButton.asObservable();
        this._selectedMenuButtonCat = new BehaviorSubject(undefined);
        this.selectedMenuButtonCat$ = this._selectedMenuButtonCat.asObservable();
        this._showMenuButtonValidation = new BehaviorSubject(undefined);
        this.showMenuButtonValidation$ = this._showMenuButtonValidation.asObservable();
        this._selectedMenuButtonTab = new BehaviorSubject(undefined);
        this.selectedMenuButtonTab$ = this._selectedMenuButtonTab.asObservable();
        this._mypageSection = new BehaviorSubject(null);
        this.mypageSection$ = this._mypageSection.asObservable();
        this._pageId = new BehaviorSubject(null);
        this.pageId$ = this._pageId.asObservable();
        this._selectedPageId = new BehaviorSubject(undefined);
        this.selectedPageId$ = this._selectedPageId.asObservable();
        this._selectedPageIdToView = new BehaviorSubject(undefined);
        this.selectedPageIdToView$ = this._selectedPageIdToView.asObservable();
        this._mypageButtonId = new BehaviorSubject(null);
        this.mypageButtonId$ = this._mypageButtonId.asObservable();
        this._containerType = new BehaviorSubject({});
        this.containerType$ = this._containerType.asObservable();
        this._selectedFeature = new BehaviorSubject({});
        this.selectedFeature$ = this._selectedFeature.asObservable();
        this._selectedTabFirstTime = new BehaviorSubject(null);
        this.selectedTabFirstTime$ = this._selectedTabFirstTime.asObservable();
        this._createPage = new BehaviorSubject(false);
        this.createPage$ = this._createPage.asObservable();
        this._workFlowScreens = new BehaviorSubject(true);
        this.workFlowScreens$ = this._workFlowScreens.asObservable();
        this._currentReleaseScreen = new BehaviorSubject(ReleasesPageScreens.RELEASE_LIST);
        this.currentReleaseScreen$ = this._currentReleaseScreen.asObservable();
        this._isAndroidSet = new BehaviorSubject(false);
        this.isAndroidSet$ = this._isAndroidSet.asObservable();
        this._isIOSSet = new BehaviorSubject(false);
        this.isIOSSet$ = this._isIOSSet.asObservable();
        this._toggleConfairm = new BehaviorSubject(null);
        this.toggleConfairm$ = this._toggleConfairm.asObservable();
        this._toggleIconConfairm = new BehaviorSubject(null);
        this.toggleIconConfairm$ = this._toggleIconConfairm.asObservable();
        this._toggleTabsConfairm = new BehaviorSubject(false);
        this.toggleTabsConfairm$ = this._toggleTabsConfairm.asObservable();
        this._gotSubPages = new BehaviorSubject(false);
        this.gotSubPages$ = this._gotSubPages.asObservable();
        this._showMenuBtn = new BehaviorSubject(false);
        this.showMenuBtn$ = this._showMenuBtn.asObservable();
        this._currentReleaseIOSScreen = new BehaviorSubject(ReleasesIOSPageScreens.IOS_SETUP);
        this.currentReleaseIOSScreen$ = this._currentReleaseIOSScreen.asObservable();
        this._errorMassage = new BehaviorSubject(null);
        this.errorMassage$ = this._errorMassage.asObservable();
        this._createNewScreen = new BehaviorSubject(false);
        this.createNewScreen$ = this._createNewScreen.asObservable();
        this._menuOnclickAction = new BehaviorSubject(undefined);
        this.menuOnclickAction$ = this._menuOnclickAction.asObservable();
        this._selectedNextMenu = new BehaviorSubject(undefined);
        this.selectedNextMenu$ = this._selectedNextMenu.asObservable();
        this._selectedNextMenuPos = new BehaviorSubject(undefined);
        this.selectedNextMenuPos$ = this._selectedNextMenuPos.asObservable();
        this._selectedNextMenuCol = new BehaviorSubject(undefined);
        this.selectedNextMenuCol$ = this._selectedNextMenuCol.asObservable();
        this._waitForSave = new BehaviorSubject(false);
        this.waitForSave$ = this._waitForSave.asObservable();
        this._menuStatusTabNav = new BehaviorSubject(false);
        this.menuStatusTabNav$ = this._menuStatusTabNav.asObservable();
        this._createPageContainer = new BehaviorSubject(undefined);
        this.createPageContainer$ = this._createPageContainer.asObservable();
        this._optionFormNotVaild = new BehaviorSubject(true);
        this.optionFormNotVaild$ = this._optionFormNotVaild.asObservable();
        this._scrollDashBoard = new BehaviorSubject(false);
        this.scrollDashBoard$ = this._scrollDashBoard.asObservable();
        this._updatedChatLabel = new BehaviorSubject(undefined);
        this.updatedChatLabel$ = this._updatedChatLabel.asObservable();
        this._selectedPage = new BehaviorSubject({});
        this.selectedPage$ = this._selectedPage.asObservable();
        this._selectedMenuButtonStore = new BehaviorSubject(false);
        this.selectedMenuButtonStore$ = this._selectedMenuButtonStore.asObservable();
        this._selectedLastMenu = new BehaviorSubject(false);
        this.selectedLastMenu$ = this._selectedLastMenu.asObservable();
    }
    /*
     * Channel APP Selectors
     */
    get currentPage$() {
        return this._selectors.currentPage$.pipe();
    }
    get channelAppUiCollection$() {
        return this._selectors.channelAppUiCollection$.pipe();
    }
    get appInfo$() {
        return this._selectors.appInfo$.pipe();
    }
    // Navigation Screen selectors
    get navMenuItems$() {
        return this._selectors.navMenuItems$.pipe();
    }
    navMenuItemByref$(ref) {
        return this._selectors.getNavMenuItemByRef$(ref).pipe();
    }
    get navMenuComponent$() {
        return this._selectors.navMenuComponent$.pipe();
    }
    get navHeaderComponent$() {
        return this._selectors.navHeaderComponent$.pipe();
    }
    // Tabs Selectors
    get tabsContainers$() {
        return this._selectors.tabsContainers$.pipe();
    }
    get mainTabsContainers$() {
        return this._selectors.mainTabsContainers$.pipe();
    }
    get mainItemsContainers$() {
        return this._selectors.mainItemsContainers$.pipe();
    }
    get tabsAppClass$() {
        return this._selectors.tabsAppClass$.pipe();
    }
    get menusAppClass$() {
        return this._selectors.menusAppClass$.pipe();
    }
    get sideMenuAppClass$() {
        return this._selectors.sideMenuAppClass$.pipe();
    }
    get channelDefaultAppClass$() {
        return this._selectors.channelDefaultAppClass$.pipe();
    }
    get effectiveSelectedTab$() {
        return this._selectors.getEffectiveSelectedTabOrSection$().pipe();
    }
    get effectiveSelectedItem$() {
        return this._selectors.getEffectiveSelectedItemOrSection$().pipe();
    }
    // Tab Footer
    get footerComponentOfSelectedTab$() {
        return this._selectors.getFooterComponentOfSelectedTab$().pipe();
    }
    get footerItemsOfSelectedTab$() {
        return this._selectors.getFooterItemsOfSelectedTab$().pipe();
    }
    // Tab Menu
    get menuComponentOfSelectedTab$() {
        return this._selectors.getMenuComponentOfSelectedTab$().pipe();
    }
    get menuItemsOfSelectedTab$() {
        return this._selectors.getMenuItemsOfSelectedTab$().pipe();
    }
    get getMenuRefOfSelectedTab$() {
        return this._selectors.getMenuRefOfSelectedTab$().pipe();
    }
    // Tab Post
    get postComponentOfSelectedTab$() {
        return this._selectors.getPostComponentOfSelectedTab$().pipe();
    }
    // Tab Card
    get cardComponentOfSelectedTab$() {
        return this._selectors.getCardComponentOfSelectedTab$().pipe();
    }
    // Tab Wallet
    get walletComponentOfSelectedTab$() {
        return this._selectors.getWalletComponentOfSelectedTab$().pipe();
    }
    // Tab Web View
    get webviewComponentOfSelectedTab$() {
        return this._selectors.getWebviewComponentOfSelectedTab$().pipe();
    }
    webviewComponentOfTab$(tab) {
        return this._selectors.getWebviewComponentOfTab$(tab).pipe();
    }
    // accept invitation component
    get acceptInvitationCompOfSelectedTab$() {
        return this._selectors.getAcceptInvitationCompOfSelectedTab$().pipe();
    }
    // reject invitation component
    get rejectInvitationCompOfSelectedTab$() {
        return this._selectors.getRejectInvitationCompOfSelectedTab$().pipe();
    }
    // Search Tab
    get searchComponentOfSelectedTab$() {
        return this._selectors.getSearchComponentOfSelectedTab$().pipe();
    }
    searchComponentOfTab$(tab) {
        return this._selectors.getSearchComponentOfTab$(tab).pipe();
    }
    // Search sidemenu
    get searchComponentOfSelectedItem$() {
        return this._selectors.getSearchComponentOfSelectedItem$().pipe();
    }
    // MapSearch Tab
    get mapSearchComponentOfSelectedTab$() {
        return this._selectors.getMapSearchComponentOfSelectedTab$().pipe();
    }
    // MapSearch sidemenu
    get mapSearchComponentOfSelectedItem$() {
        return this._selectors.getMapSearchComponentOfSelectedItem$().pipe();
    }
    get mapSearchItemsOfSelectedTab$() {
        return this._selectors.getMapSearchItemsOfSelectedTab$().pipe();
    }
    get mapSearchItemsOfSelectedItem$() {
        return this._selectors.getMapSearchItemsOfSelectedItem$().pipe();
    }
    get searchItemsOfSelectedTab$() {
        return this._selectors.getSearchItemsOfSelectedTab$().pipe();
    }
    get searchItemsOfSelectedItem$() {
        return this._selectors.getSearchItemsOfSelectedItem$().pipe();
    }
    searchItemsOfTab$(tab) {
        return this._selectors.getSearchItemsOfTab$(tab).pipe();
    }
    // to get the search index containers
    get searchContainers$() {
        return this._selectors.searchContainers$.pipe();
    }
    get activeSearchContainers$() {
        return this._selectors.activeSearchContainers$.pipe();
    }
    get activeSearchItem$() {
        return this._selectors.activeSearchItem$.pipe();
    }
    get searchAppClass$() {
        return this._selectors.searchAppClass$.pipe();
    }
    get paymentProviderConfg$() {
        return this._selectors.getPaymentProviders$().pipe();
    }
    get adsProviderConfg$() {
        return this._selectors.getAdsroviders$().pipe();
    }
    // Channel APP Actions
    getNextPage() {
        this._channelAppDispatchers.getNextPage();
    }
    setAppChannelSettings(state) {
        this._appChannelSettingsDispatchers.setAppChannelSettings(state);
    }
    getAppChannelSettings() {
        this._appChannelSettingsDispatchers.getAppChannelSettings();
    }
    get getAppChannelSettings$() {
        return this._selectors.getAppChannelSettings$();
    }
    getPreviousPage() {
        this._channelAppDispatchers.getPreviousPage();
    }
    selectTab(selectedTab) {
        this._channelAppDispatchers.selectTab(selectedTab);
    }
    selectFirstTab() {
        this._containerDispatchers.selectFirstContainer(AppClassNames.TABS);
    }
    selectLastTab() {
        this._containerDispatchers.selectLastContainer(AppClassNames.TABS);
    }
    unselectItem() {
        const unSelect = this.effectiveSelectedItem$.subscribe(item => {
            if (item) {
                const newItem = {};
                newItem.selected = false;
                this.updateItem(item.ref, newItem);
            }
        });
        unSelect.unsubscribe();
    }
    goToSpecificPage(pageNumber) {
        this._channelAppDispatchers.goToSpecificPage(pageNumber);
    }
    updatePublishButtonFlag(canPublish) {
        this._channelAppDispatchers.updatePublishButtonFlag(canPublish);
    }
    updateTotalAppConfFlag(totalAppConf) {
        this._channelAppDispatchers.updateTotalAppConfFlag(totalAppConf);
    }
    toggleAndroidVersion(payload) {
        this._channelAppDispatchers.toggleAndroidVersion(payload);
    }
    toggleIosVersion(payload) {
        this._channelAppDispatchers.toggleIosVersion(payload);
    }
    updateItem(itemRef, itemUpdatedProps) {
        this._itemDispatchers.updateItem(itemRef, itemUpdatedProps);
    }
    updateItemLink(itemRef, itemLink, pageId) {
        this._itemDispatchers.requestUpdateItemLink(itemRef, itemLink, pageId);
    }
    reorderItem(itemRef, newPosition) {
        this._itemDispatchers.reorderItem(itemRef, newPosition);
    }
    deleteItem(item) {
        this._itemDispatchers.deleteItemRequest(item);
    }
    addItem(component, specialItemValues) {
        this._itemDispatchers.addItemRequest(component, specialItemValues);
    }
    updateComponent(componetRef, componentUpdatedProps) {
        this._componentDispatchers.updateComponent(componetRef, componentUpdatedProps);
    }
    openComponentSection(component) {
        this._componentDispatchers.openSection(component);
    }
    closeComponentSection(component) {
        this._componentDispatchers.closeSection(component);
    }
    openClassSection(appClass) {
        this._appClassDispatchers.openClassSection(appClass);
    }
    closeClassSection(appClass) {
        this._appClassDispatchers.closeClassSection(appClass);
    }
    updateContainer(containerRef, containerUpdatedProps) {
        this._containerDispatchers.updateContainer(containerRef, containerUpdatedProps);
    }
    updateTabType(containerRef, tabType, pageId) {
        this._containerDispatchers.updateTabTypeRequest(containerRef, tabType, pageId);
    }
    deleteContainer(container) {
        this._containerDispatchers.deleteContainerRequest(container);
    }
    deleteActulContainer(container) {
        this._containerDispatchers.deleteContainer(container);
    }
    addContainer(parentAppClass, specialContainerValues) {
        this._containerDispatchers.addContainerRequest(parentAppClass, specialContainerValues);
    }
    reorderContainer(containerRef, newPosition) {
        this._containerDispatchers.reorderContainer(containerRef, newPosition);
    }
    updateAppClass(appClassRef, appClassUpdatedProps) {
        this._appClassDispatchers.updateAppClass(appClassRef, appClassUpdatedProps);
    }
    notAllowedClassBgColors() {
        this._appClassDispatchers.notAllowedClassBgColors();
    }
    savePage(channelConfOnly, selectedPageNumber, featuresIds, dashboard) {
        const chatLabelSub = this.updatedChatLabel$.subscribe(chatLabel => {
            if (chatLabel) {
                this._uiDispatcher.saveChatLabels(chatLabel);
            }
        });
        chatLabelSub.unsubscribe();
        if (this.uploadedIcon) {
            // this._waitForSave.next(true);
            this._appConfigDispatchers.uploadAppImage(this.uploadedIcon, selectedPageNumber, featuresIds, dashboard);
            this.uploadedIcon = undefined;
        }
        else {
            this._appConfigDispatchers.setAppConfig(channelConfOnly, selectedPageNumber, featuresIds, dashboard);
        }
    }
    setMStore(menuId, currency, shop) {
        this._appConfigDispatchers.setMStore(menuId, currency);
    }
    getMStore(id) {
        this._appConfigDispatchers.getMStore(id);
    }
    savePaymentProviderConfg(providerId, config, active) {
        this._paymentProvidersDispatchers.setPaymentProvider(providerId, config, active);
    }
    saveAdsProviderConfg(adsProviderId, config) {
        this._adsProvidersDispatchers.setAdsProvider(adsProviderId, config);
    }
    getAdsConfig$(adsProviderId) {
        this._adsProvidersDispatchers.getAdsProviders(adsProviderId);
    }
    publishOnlineConfig(isChannelOnly, track = 0, versionName, versionDesc) {
        this._containerDispatchers.updateMapContainerOnPublish(isChannelOnly);
        setTimeout(() => this._appConfigDispatchers.publishAppConfig(isChannelOnly, track, versionName, versionDesc), 1000);
    }
    generateApp(appId, os, android_format, ver_name, ver_desc) {
        if (+os === 2) {
            this._appConfigDispatchers.generateApp(appId, 0, android_format, ver_name, ver_desc);
            this._appConfigDispatchers.generateApp(appId, 1, android_format, ver_name, ver_desc);
        }
        else {
            this._appConfigDispatchers.generateApp(appId, +os, android_format, ver_name, ver_desc);
        }
    }
    // upload and download actions
    uploadComponentImage(file, component, isBackground) {
        const selectedFile = {};
        selectedFile.localFile = file;
        selectedFile.type = MessageTypes.PHOTO;
        this._componentDispatchers.uploadComponentMedia(selectedFile, component, isBackground);
    }
    get systemConfig$() {
        return this._selectors.systemConfig$.pipe();
    }
    updateAppColors(appColors) {
        this._appConfigDispatchers.updateAppColors(appColors);
    }
    uploadColoredLogo(selectedFile) {
        this._appConfigDispatchers.uploadAppColoredLogo(selectedFile);
    }
    getDomaintColor(selectedFile) {
        this._appConfigDispatchers.getDomainatColor(selectedFile);
    }
    uploadWhiteLogo(selectedFile) {
        this._appConfigDispatchers.uploadAppWhiteLogo(selectedFile);
    }
    uploadIosStoreLogo(selectedFile) {
        this._appConfigDispatchers.uploadIosStoreLogo(selectedFile);
    }
    //
    listAppReleases() {
        this._appReleaseDispatchers.listAppReleases();
    }
    listAppReleaseId(appRelese) {
        this._appReleaseDispatchers.listAppReleaseId(appRelese);
    }
    removeAppRelease(id) {
        this._appReleaseDispatchers.removeAppRelease(id);
    }
    get appReleaseList$() {
        return this._selectors.appReleaseList$.pipe();
    }
    getAppReleaseById$(id) {
        return this._selectors.getAppReleaseById$(id);
    }
    hideAppReleaseDetails(id) {
        this._appReleaseDispatchers.hideAppReleaseDetails(id);
    }
    showAppReleaseDetails(id) {
        this._appReleaseDispatchers.viewAppReleaseDetails(id);
    }
    // Templates By Category
    get myProfileID() {
        return this._authDispatchers.getLoginID();
    }
    get localAppInfo$() {
        return this._selectors.localAppInfo$.pipe();
    }
    get availableApps$() {
        return this._selectors.localAppInfo$.pipe(map(info => info.listOfAvailableApps), map(apps => {
            const options = [];
            if (apps && apps.length > 0) {
                apps.forEach((app, index) => {
                    const option = { key: app.id, value: app.name };
                    if (index === 0) {
                        option.selected = true;
                    }
                    options.push(option);
                });
            }
            return options;
        }));
    }
    getAppsSetupInfoById$(appId) {
        return this._selectors.getAppSetupById$(appId);
    }
    // Splash Selectors
    get splashPages$() {
        return this._selectors.splashPages$.pipe();
    }
    get splashFooter$() {
        return this._selectors.splashFooter$.pipe();
    }
    get splashFooterButton$() {
        return this._selectors.splashFooterButton$.pipe();
    }
    get splashHeader$() {
        return this._selectors.splashHeader$.pipe();
    }
    get selectedSplashPage$() {
        return this._selectors.selectedSplashPage$.pipe();
    }
    reorderComponent(componentRef, newPosition) {
        this._componentDispatchers.reorderComponent(componentRef, newPosition);
    }
    addComponent(parentContainer) {
        this._componentDispatchers.addComponentRequest(parentContainer);
    }
    deleteComponent(component) {
        this._componentDispatchers.deleteComponentRequest(component);
    }
    selectComponent(component) {
        this._componentDispatchers.selectComponent(component);
    }
    // Update app Info
    updateAppInfo(appInfo) {
        this._appConfigDispatchers.updateAppInfo(appInfo);
    }
    // Upload splash page logo
    uploadSplashPageLogo(componentRef, selectedFile) {
        this._componentDispatchers.UploadSplashPageLogo(componentRef, selectedFile);
    }
    uploadVideoBackground(componentRef, currentPage, selectedFile) {
        this._componentDispatchers.uploadVideoBackground(componentRef, currentPage, selectedFile);
    }
    updateMainChannelImage(id, imageUrl) {
        this._mainChatDispatchers.updateMainChatImages(id, imageUrl);
    }
    // Profiles
    get profileFields$() {
        return this._selectors.profileContainers$.pipe();
    }
    get profilesAppClass$() {
        return this._selectors.profilesAppClass$.pipe();
    }
    // Maps
    get mapAppMenus$() {
        return this._selectors.mapAppMenus$.pipe();
    }
    // My Page
    deleteMyPage(pageID) {
        let pageContainer = {};
        let pageItem = {};
        // delete page from tab
        this._selectors.tabPageContainer(pageID).subscribe(res => {
            pageContainer = res[0];
        });
        if (pageContainer) {
            this.deleteContainer(pageContainer);
        }
        // delete page from side menu
        this._selectors.navMenuPagesItems(pageID).subscribe(res => {
            pageItem = res[0];
        });
        if (pageItem) {
            this.deleteItem(pageItem);
        }
        //remove sub page from tab
        this._selectors.tabsWithSubPageContainers().subscribe(tabs => {
            tabs.forEach(tab => {
                if (tab.sub_pages.indexOf(pageID) >= 0) {
                    const newSubPages = [];
                    tab.sub_pages.forEach(subPage => {
                        if (subPage !== pageID) {
                            newSubPages.push(subPage);
                        }
                    });
                    const newContainer = {};
                    newContainer.sub_pages = newSubPages;
                    this.updateContainer(tab.ref, newContainer);
                }
            });
        });
        this._myPageDispatchers.deleteMyPage(pageID);
        this.savePage(false);
    }
    getTabPageContainer(page_id) {
        return this._selectors.tabPageContainer(page_id);
    }
    getMyPagePyID$(pageID, formWhere) {
        return this._selectors.getMyPageByID$(pageID);
    }
    getMyPageForSection$(pageID, formWhere) {
        return this._selectors.getMyPageByIDForSection$(pageID);
    }
    getLeatestMyPage$(name, temId) {
        return this._selectors.getLeatestMyPage$(name, temId);
    }
    getMyPages$(pageIdToExclude) {
        return this._selectors.getMyPages$(pageIdToExclude).pipe();
    }
    // Instant Pages
    // call this on click on a specific page
    requestPageDetails(pageId) {
        this._instantPageDispatchers.requestInstantPageDetails(pageId);
    }
    getInstantPageByID$(pageID) {
        return this._selectors.getInstantPageByID$(pageID).pipe();
    }
    getInstantPages$() {
        return this._selectors.getInstantPages$().pipe();
    }
    //
    updateMyPage(id, myPageDetails) {
        this._myPageDispatchers.updateMyPage(id, myPageDetails);
    }
    getDetailsOfLinkedPage(linkedPageID) {
        this._myPageDispatchers.getDetailsOfLinkedPage(linkedPageID);
    }
    createMyPage(name, image, desc, myPageForm, templateId, pageRef) {
        let ref = Date.now() + '';
        if (pageRef) {
            ref = pageRef;
        }
        this._myPageDispatchers.createMyPage(ref, myPageForm, name, null, image, desc, templateId);
    }
    createPageTemplate(name, image, desc, myPage) {
        this._myPageDispatchers.createPageTemplate(myPage, name, image, desc);
    }
    createAppTemplate(androidImageUrl, iosImageUrl, categories, name, order, template, id) {
        this._appConfigDispatchers.createAppTemplate(androidImageUrl, iosImageUrl, categories, name, order, template, id);
    }
    getAppTemplateMeta(id) {
        this._appConfigDispatchers.getAppTemplateMeta(id);
    }
    requestAppTemplate(templateID) {
        this._appConfigDispatchers.requestAppTemplate(templateID);
    }
    updateMyPageForm(formID, title, image, template, background) {
        this._myPageDispatchers.updateMyPageForm(formID, title, image, template, background);
    }
    updateMyPageContainer(formID, containerID, containerUpdatedProps) {
        this._myPageDispatchers.updateMyPageContainer(formID, containerID, containerUpdatedProps);
    }
    deleteMyPageContainer(formID, container) {
        this._myPageDispatchers.deleteMyPageContainer(formID, container);
    }
    addMyPageContainer(formID, container) {
        this._myPageDispatchers.addMyPageContainer(formID, container);
    }
    reorderPageContainers(formID, containerID, newPosition) {
        this._myPageDispatchers.reorderPageContainers(formID, containerID, newPosition);
    }
    updateMyPageComponent(formID, containerID, componentID, componentUpdatedProps) {
        this._myPageDispatchers.updateMyPageComponent(formID, containerID, componentID, componentUpdatedProps);
    }
    deleteMyPageComponent(formID, containerID, component) {
        this._myPageDispatchers.deleteMyPageComponent(formID, containerID, component);
    }
    addMyPageComponent(formID, containerID, component) {
        this._myPageDispatchers.addMyPageComponent(formID, containerID, component);
    }
    reorderPageComponents(formID, containerID, componentID, newPosition) {
        this._myPageDispatchers.reorderPageComponents(formID, containerID, componentID, newPosition);
    }
    saveAppPublishingInfo(appPublishingInfo) {
        this._appPublishingDispatchers.setAppStoreInfo(appPublishingInfo);
    }
    saveAppStoreInfo(os, appStoreInfo, setCompanyName) {
        this._appPublishingDispatchers.setAppleStoreInfo(os, appStoreInfo, setCompanyName);
    }
    saveAppInfo(os, appInfo) {
        this._appPublishingDispatchers.setAppInfo(os, appInfo);
    }
    getAppPublishingInfo$() {
        return this._selectors.getAppPublishingInfo$().pipe();
    }
    showAppleForm(releaseId) {
        this.showAppleKeyForm.next({ show: true, release_id: releaseId });
    }
    sendAppleKeyCode(code, release_id) {
        this._appReleaseDispatchers.sendAppleKeyCode(code, release_id);
        this.hideAppleKeyForm();
    }
    hideAppleKeyForm() {
        this.showAppleKeyForm.next({ show: false, release_id: null });
    }
    // App Config History
    getAppConfigHistoryList$() {
        return this._selectors.getAppConfigHistoryList$().pipe();
    }
    restoreAppConfigHistoryById(id) {
        this._appConfigHistoryDispatchers.restoreAppConfigByID(id);
    }
    requestAppConfigsHistory() {
        this._appConfigHistoryDispatchers.requestAppConfigsHistory();
    }
    requestAppStoreInfo(appId) {
        this._appPublishingDispatchers.requestAppStoreInfo(appId);
    }
    // App Testers
    // use it for the shown list in ui
    getAppTesters$() {
        return this._selectors.testerMembers$.pipe();
    }
    removeTester(userId) {
        this._chatMemberDispatchers.setMemberTesterFlag(userId, 0);
    }
    // get qr code to be used in add
    getQrCodeToAddTester$() {
        return this._selectors.qrCodeToAddTester$;
    }
    requestAppTesters() {
        this._appPublishingDispatchers.requestAppTesters();
    }
    getConfigQrCode$() {
        return this._selectors.configQrCode$;
    }
    requestConfigQrCode() {
        this._appConfigDispatchers.requestConfigQrCode();
    }
    // Sections Selectors
    // parentTabRef (the ref of the selected tab that we want to get its sections)
    getTabSections$(parentTabRef) {
        return this._selectors.getTabSections$(parentTabRef).pipe();
    }
    // sectionRef: is the ref of the selected container (this container is a section)
    getSectionDetails$(sectionRef) {
        return this._selectors.getEffectiveSelectedTabOrSection$(sectionRef).pipe();
    }
    // Section Tab Footer
    getFooterComponentBySectionRef$(sectionRef) {
        return this._selectors.getFooterComponentOfSelectedTab$(sectionRef).pipe();
    }
    getFooterItemsBySectionRef$(sectionRef) {
        return this._selectors.getFooterItemsOfSelectedTab$(sectionRef).pipe();
    }
    // Section Tab Menu
    getMenuComponentBySectionRef$(sectionRef) {
        return this._selectors.getMenuComponentOfSelectedTab$(sectionRef).pipe();
    }
    getMenuItemsBySectionRef$(sectionRef) {
        return this._selectors.getMenuItemsOfSelectedTab$(sectionRef).pipe();
    }
    // Section Tab Post
    getPostComponentBySectionRef$(sectionRef) {
        return this._selectors.getPostComponentOfSelectedTab$(sectionRef).pipe();
    }
    // Section Tab Card
    getCardComponentBySectionRef$(sectionRef) {
        return this._selectors.getCardComponentOfSelectedTab$(sectionRef).pipe();
    }
    // Section Tab Wallet
    getWalletComponentBySectionRef$(sectionRef) {
        return this._selectors.getWalletComponentOfSelectedTab$(sectionRef).pipe();
    }
    // Section Tab Web View
    getWebviewComponentBySectionRef$(sectionRef) {
        return this._selectors.getWebviewComponentOfSelectedTab$(sectionRef).pipe();
    }
    //  Section Tab accept invitation component
    getAcceptInvitationCompBySectionRef$(sectionRef) {
        return this._selectors
            .getAcceptInvitationCompOfSelectedTab$(sectionRef)
            .pipe();
    }
    //  Section Tab reject invitation component
    getRejectInvitationCompBySectionRef$(sectionRef) {
        return this._selectors
            .getRejectInvitationCompOfSelectedTab$(sectionRef)
            .pipe();
    }
    // Section Tab  Search
    getSearchComponentBySectionRef$(sectionRef) {
        return this._selectors.getSearchComponentOfSelectedTab$(sectionRef).pipe();
    }
    getSearchItemsBySectionRef$() {
        return this._selectors.getSearchItemsOfSelectedTab$().pipe();
    }
    addSection(parentTabRef, specialContainerValues) {
        this._containerDispatchers.addSectionRequest(parentTabRef, specialContainerValues);
    }
    setUserData(userData, tempId) {
        this.setTempId(tempId);
        this._appConfigDispatchers.setApp(userData, tempId);
    }
    requestAppConfig() {
        this._appConfigDispatchers.requestAppConfig();
    }
    saveInAppPurchases(json) {
        this._appConfigDispatchers.saveInAppPurchases(json);
    }
    getAppRealseVerData(id) {
        this._appReleaseDispatchers.getAppVer(id);
    }
    uploadTempImageToProfile(imageId) {
        this._appConfigDispatchers.UpldTempImageToProfile(imageId);
    }
    setSmtp(smtp) {
        this._smtpDispatchers.setStmp(smtp);
    }
    getSmtp() {
        this._smtpDispatchers.getStmp();
    }
    get smtp$() {
        return this._selectors.getSmtp$().pipe();
    }
    setUploadedIcon(icon) {
        this.uploadedIcon = icon;
    }
    setTestTemp(tmp) {
        this.appTestTmp = tmp;
    }
    getTestTemp() {
        return this.appTestTmp;
    }
    setAppSettingFeature(feature) {
        this.selectedAppSettingFeature = feature;
    }
    getAppSettingFeature() {
        return this.selectedAppSettingFeature;
    }
    confirmMassage(item) {
        const appInfo = {};
        switch (item.id) {
            case 27:
                this._showConfirmMsg.next(null);
                this._selectedItem.next(null);
                this.setAppChannelSettings(true);
                break;
            case 28:
                this._showConfirmMsg.next(null);
                this._selectedItem.next(null);
                appInfo.public_links_hidden = true;
                this.updateAppInfo(appInfo);
                break;
        }
    }
    updateThirdPartyInfo(vendors) {
        this._thirdPartyInfoDispatchers.updateThirdPartyInfo(vendors);
    }
    saveThirdPartyInfo() {
        this._thirdPartyInfoDispatchers.saveThirdPartyInfo();
    }
    getThirdPartyInfo$() {
        return this._selectors.getThirdPartyInfo$();
    }
    signUpdemoAccount(deviceId, os, osVersion, msisdn, email, typ, countryDial, countryIso) {
        this._authDispatchers.signUpDemoAccount(deviceId, os, osVersion, msisdn, email, typ, countryDial, countryIso);
    }
    registerdemoAccount(deviceId, os, osVersion, msisdn, typ, tac, countryDial, countryName, isoCode, email) {
        this.demoAccData = {};
        this.demoAccData.email = email;
        this.demoAccData.msisdn = msisdn;
        if (typ === 'email') {
            this.demoAccData.typ = 'Email';
        }
        else {
            this.demoAccData.typ = 'Mobile';
        }
        this.demoAccData.tac = tac;
        this.demoAccData.countryDial = countryDial;
        this.demoAccData.countryName = countryName;
        this.demoAccData.isoCode = isoCode;
        this._authDispatchers.registerDemoAccount(deviceId, os, osVersion, msisdn, typ, tac, countryDial, email);
    }
    verfyDemoAcc() {
        const appInfo = {};
        appInfo.demoAccData = this.demoAccData;
        this.getDemoAccountStatus(appInfo.demoAccData.msisdn, appInfo.demoAccData.countryDial);
        this.updateAppInfo(appInfo);
    }
    getDemoAccountStatus(msisdn, countryCode) {
        this._authDispatchers.getDemoAccountStatus(msisdn, countryCode);
    }
    resendVerCode(msisdn, countryCode) {
        this._authDispatchers.resendVerCode(msisdn, countryCode);
    }
    testThirdPartyInfo(vendor) {
        this._thirdPartyInfoDispatchers.testThirdPartyInfo(vendor);
    }
    get getResultOfTestThirdPartyInfo$() {
        return this._selectors.getResultOfTestThirdPartyInfo$();
    }
    restErrorMassage() {
        this._thirdPartyInfoDispatchers.restErrorMassage();
    }
    getMessagingVendorUI(name) {
        this._thirdPartyInfoDispatchers.getMessagingVendorUI(name);
    }
    updateDemoAccountMustWait(mustWait) {
        const appInfo = {};
        if (!this.demoAccData) {
            this.demoAccData = {};
        }
        this.demoAccData.mustWait = mustWait;
        appInfo.demoAccData = this.demoAccData;
        this.updateAppInfo(appInfo);
    }
    setTempId(id) {
        this.tempId = id;
    }
    getTempId() {
        return this.tempId;
    }
    getAppMenuById(menuId) {
        const menu = this._selectors.getMenuById(menuId);
        return menu;
    }
    setCurrentScreen(screen) {
        this._currentReleaseScreen.next(screen);
    }
    addAppReleaseWaitingItem(appRelease) {
        this._appReleaseDispatchers.addAppReleaseWaitingItem(appRelease);
    }
    setCurrentIOSScreen(screen) {
        this._currentReleaseIOSScreen.next(screen);
    }
    getAppsSetupInfo() {
        this._appPublishingDispatchers.getAppsSetupInfo();
    }
    setBusinessInfo(company_name, website) {
        this._authDispatchers.setBusinessInfo(company_name, website);
    }
    getBusinessInfo() {
        this._authDispatchers.getBusinessInfo();
    }
    setVerificationMessageTemplate(value, type) {
        this._thirdPartyInfoDispatchers.setVerificationMessageTemplate(value, type);
    }
    sendVerificationMessageTemplate() {
        this._thirdPartyInfoDispatchers.sendVerificationMessageTemplate();
    }
    getVerificationMessageTemplate() {
        this._thirdPartyInfoDispatchers.getVerificationMessageTemplate();
    }
    selectTypeOfMessage(type, typeOfLogin) {
        this._thirdPartyInfoDispatchers.selectTypeOfMessage(type, typeOfLogin);
    }
    deleteVerificationMessageTemplate() {
        this._thirdPartyInfoDispatchers.deleteVerificationMessageTemplate();
    }
    getTagsByTabId$(id) {
        return this._selectors.getTagsByTabId$(id).pipe();
    }
    enableAllService() {
        this._paymentProvidersDispatchers.enableAllServices();
    }
}
AppMgmtService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppMgmtService_Factory() { return new AppMgmtService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.AuthDispatchers), i0.ɵɵinject(i3.ChannelAppUiDispatchers), i0.ɵɵinject(i4.ItemDispatchers), i0.ɵɵinject(i5.ComponentDispatchers), i0.ɵɵinject(i6.ContainerDispatchers), i0.ɵɵinject(i7.AppClassDispatchers), i0.ɵɵinject(i8.AppConfigDispatchers), i0.ɵɵinject(i9.AppReleaseDispatchers), i0.ɵɵinject(i10.MyPageDispatchers), i0.ɵɵinject(i11.InstantPageDispatchers), i0.ɵɵinject(i12.AppPublishingDispatchers), i0.ɵɵinject(i13.AppConfigHistoryDispatchers), i0.ɵɵinject(i14.ChatMemberDispatchers), i0.ɵɵinject(i15.PaymentProvidersDispatchers), i0.ɵɵinject(i16.AdsProvidersDispatchers), i0.ɵɵinject(i17.AppChannelSettingsDispatchers), i0.ɵɵinject(i18.StmpDispatchers), i0.ɵɵinject(i19.MainChatDispatchers), i0.ɵɵinject(i20.ThirdPartyInfoDispatchers), i0.ɵɵinject(i21.UIDispatchers)); }, token: AppMgmtService, providedIn: "root" });
