import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import {
  MapTripsNotifiers,
  MapTripsNotifiersUI
} from 'src/models/mapTripsNotifiers';
import * as MapTripsNotifiersActions from './mapTripsNotifiers.actions';

@Injectable({
  providedIn: 'root'
})
export class MapTripsNotifiersDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetMapTripsNotifiersPaging() {
    this._store.dispatch(
      new MapTripsNotifiersActions.ResetMapTripsNotifiersPaging()
    );
  }

  getTripsNotifiers() {
    this._store.dispatch(new MapTripsNotifiersActions.GetTripsNotifiers());
  }

  createMapAccountLinks(tripNotifier: MapTripsNotifiers) {
    this._store.dispatch(
      new MapTripsNotifiersActions.CreateMapAccountLinks(tripNotifier)
    );
  }

  mapAccountLinksCreated(tripNotifiers: MapTripsNotifiers[]) {
    this._store.dispatch(
      new MapTripsNotifiersActions.MapAccountLinksCreated(tripNotifiers)
    );
  }

  getNotifiersList(accountID: string) {
    this.restNotifiersList();
    this._store.dispatch(
      new MapTripsNotifiersActions.ResetNotifiersListPaging(accountID)
    );
  }

  getNextNotifiersList() {
    this._store.dispatch(new MapTripsNotifiersActions.GetNextNotifiersPage());
  }

  getPrevNotifiersList() {
    this._store.dispatch(new MapTripsNotifiersActions.GetPrevNotifiersPage());
  }

  removeAccountLink(id: string) {
    this._store.dispatch(new MapTripsNotifiersActions.RemoveAccountLink(id));
  }

  updateMapAccountLinks(accountID: string, link_ids: string[]) {
    this._store.dispatch(
      new MapTripsNotifiersActions.UpdateMapAccountLinks(accountID, link_ids)
    );
  }

  setMapAccountLinksCount(accountID: string, count: number) {
    this._store.dispatch(
      new MapTripsNotifiersActions.SetMapAccountLinksCount(accountID, count)
    );
  }

  removeAccountLinkSuccess(id: string) {
    this._store.dispatch(
      new MapTripsNotifiersActions.RemoveAccountLinkSuccess(id)
    );
  }

  restNotifiersList() {
    this._store.dispatch(new MapTripsNotifiersActions.RestNotifiersList());
  }

  notifiersListReceived(
    tripNotifiers: MapTripsNotifiers[],
    eop: number,
    sop: number,
    reference?: string
  ) {
    this._store.dispatch(
      new MapTripsNotifiersActions.NotifiersListReceived(
        eop,
        sop,
        tripNotifiers,
        reference
      )
    );
  }

  mapAccountLinksListReceived(accountID: MapTripsNotifiers[]) {
    this._store.dispatch(
      new MapTripsNotifiersActions.MapAccountLinksListReceived(accountID)
    );
  }
}
