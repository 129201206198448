/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./help.component";
import * as i3 from "@angular/platform-browser";
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
export { RenderType_HelpComponent as RenderType_HelpComponent };
export function View_HelpComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], [[2, "full-page", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Help "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeHelpWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-cancel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "container-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "iframe", [], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show More\u2026"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "read-all-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDocumentWeb() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-document"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Read Full Documentation "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isStartOver; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.url; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.sectionLink; _ck(_v, 8, 0, currVal_2); }); }
export function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 638976, null, 0, i2.HelpComponent, [i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpComponentNgFactory = i1.ɵccf("app-help", i2.HelpComponent, View_HelpComponent_Host_0, { sectionLink: "sectionLink", isStartOver: "isStartOver" }, { cancelNotifier: "cancelNotifier" }, []);
export { HelpComponentNgFactory as HelpComponentNgFactory };
