import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  OnInit,
  AfterViewChecked,
  Input
} from '@angular/core';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContainerComponent implements OnDestroy, OnInit {
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  private openStatus = null;
  @Input() count: number;
  constructor(private _ref: ElementRef, private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService._appModelOpenStatus.next(++this.count);
    this.layoutService._appModelStatus.next(true);
  }

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this._ref.nativeElement === event.target) {
      event.stopPropagation();
      this.cancelNotfiy.emit(true);
    }
  }

  ngOnDestroy() {
    this.layoutService._appModelOpenStatus.next(--this.count);
    if (this.count < 1) {
      this.layoutService._appModelStatus.next(false);
    }
  }
}
