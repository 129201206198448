import { Action } from '@ngrx/store';
import { IPricePlan } from 'src/models/IPricePlan';

import { RcvdPayment } from 'src/models/IPayment';

export const enum PaymentActionTypes {
  PAYMENT_SET = '[PaymentReducer]PAYMENT_SET',
  GET_PRICE_PLANS = '[PaymentReducer]GET_PRICE_PLANS',
  SET_PRICE_PLANS = '[PaymentReducer]SET_PRICE_PLANS',
  GET_PAYMENT_INFO = '[PaymentReducer]GET_PAYMENT_INFO',
  RCVD_PAYMENT_AUTH_INFO = '[PaymentReducer]RCVD_PAYMENT_AUTH_INFO',
  SET_CURRENT_PAYMENT = '[PaymentReducer]SET_CURRENT_PAYMENT',
  SET_PAYMENT_INFO = '[PaymentReducer]SET_PAYMENT_INFO',
  GET_PAYMENT_TOKEN = '[PaymentReducer]GET_PAYMENT_TOKEN',
  SET_PAYMENT_TOKEN = '[PaymentReducer]SET_PAYMENT_TOKEN',
  SET_PAYMENT_URL = '[PaymentReducer]SET_PAYMENT_URL',
  SET_SELECTED_PAYMENT = '[PaymentReducer]SET_SELECTED_PAYMENT',
  EDIT_SELECTED_PAYMENT = '[PaymentReducer]EDIT_SELECTED_PAYMENT',
  SET_CANCELED_PAYMENT = '[PaymentReducer]SET_CANCELED_PAYMENT',
  SET_SELECTED_FREE_PAYMENT = '[PaymentReducer]SET_SELECTED_FREE_PAYMENT',
  SET_CANCELED_FREE_PAYMENT = '[PaymentReducer]SET_CANCELED_FREE_PAYMENT',
  RESET_PAYMENT_PLAN = '[PaymentReducer]RESET_PAYMENT_PLAN'
}
export const RESET = 'RESET';

export class PaymentSet implements Action {
  readonly type = PaymentActionTypes.PAYMENT_SET;
}
export class GetPricePlans implements Action {
  readonly type = PaymentActionTypes.GET_PRICE_PLANS;
}

export class SetPricePlans implements Action {
  readonly type = PaymentActionTypes.SET_PRICE_PLANS;
  constructor(public payload: IPricePlan[][]) {}
}

export class GetPaymentInfo implements Action {
  readonly type = PaymentActionTypes.GET_PAYMENT_INFO;
}

export class SetPaymentInfo implements Action {
  readonly type = PaymentActionTypes.SET_PAYMENT_INFO;
  constructor(public payload: RcvdPayment) {}
}
export class RcvdPaymentAuthInfo implements Action {
  readonly type = PaymentActionTypes.RCVD_PAYMENT_AUTH_INFO;
  constructor(public payload: RcvdPayment) {}
}

export class SetCurrentPayment implements Action {
  readonly type = PaymentActionTypes.SET_CURRENT_PAYMENT;
  constructor(public payload: IPricePlan) {}
}

export class GetPaymentToken implements Action {
  readonly type = PaymentActionTypes.GET_PAYMENT_TOKEN;
}
export class SetPaymentToken implements Action {
  readonly type = PaymentActionTypes.SET_PAYMENT_TOKEN;
  constructor(public payload: string) {}
}

export class SetPaymentUrl implements Action {
  readonly type = PaymentActionTypes.SET_PAYMENT_URL;
  constructor(public payload: string) {}
}

export class SetSelectedPayment implements Action {
  readonly type = PaymentActionTypes.SET_SELECTED_PAYMENT;
  constructor(public payload: IPricePlan) {}
}

export class SetSelectedFreePayment implements Action {
  readonly type = PaymentActionTypes.SET_SELECTED_FREE_PAYMENT;
  constructor(public payload?: IPricePlan) {}
}

export class SetCanceledPayment implements Action {
  readonly type = PaymentActionTypes.SET_CANCELED_PAYMENT;
  constructor(public payload: IPricePlan) {}
}

export class SetCanceledFreePayment implements Action {
  readonly type = PaymentActionTypes.SET_CANCELED_FREE_PAYMENT;
  constructor(public payload: IPricePlan) {}
}
export class EditSelectedPayment implements Action {
  readonly type = PaymentActionTypes.EDIT_SELECTED_PAYMENT;
  constructor(public payload: IPricePlan) {}
}

export class ResetPaymentPlan implements Action {
  readonly type = PaymentActionTypes.RESET_PAYMENT_PLAN;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type PaymentActions =
  | PaymentSet
  | GetPricePlans
  | SetPricePlans
  | GetPaymentInfo
  | SetPaymentInfo
  | RcvdPaymentAuthInfo
  | SetCurrentPayment
  | GetPaymentToken
  | SetPaymentToken
  | SetPaymentUrl
  | SetSelectedPayment
  | SetSelectedFreePayment
  | SetCanceledPayment
  | SetCanceledFreePayment
  | EditSelectedPayment
  | ResetPaymentPlan
  | Reset;
