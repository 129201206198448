import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ServiceOrdersUIActions from './serviceOrdersUI.actions';

import { AppState } from 'src/models/AppState';
import { ServiceOrder } from 'src/models/PaymentGateways';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrdersUiDispatchers {
  constructor(private _store: Store<AppState>) {}
  // paging dispatchers
  getNextServiceOrdersPage(
    paymentOrderID?: string,
    serviceOrderID?: string
  ): void {
    this._store.dispatch(
      new ServiceOrdersUIActions.GetNextServiceOrdersPage(
        paymentOrderID,
        serviceOrderID
      )
    );
  }

  getPrevServiceOrdersPage(
    paymentOrderID?: string,
    serviceOrderID?: string
  ): void {
    this._store.dispatch(
      new ServiceOrdersUIActions.GetPrevServiceOrdersPage(
        paymentOrderID,
        serviceOrderID
      )
    );
  }

  receivedServiceOrdersHistoryChunk(
    eop: number,
    sop: number,
    currentPage: ServiceOrder[]
  ): void {
    this._store.dispatch(
      new ServiceOrdersUIActions.ReceivedServiceOrdersHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  resetServiceOrdersPaging(
    paymentOrderID?: string,
    serviceOrderID?: string
  ): void {
    this._store.dispatch(
      new ServiceOrdersUIActions.ResetServiceOrdersPaging(
        paymentOrderID,
        serviceOrderID
      )
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new ServiceOrdersUIActions.SetStatusAsIdle());
  }
}
