import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CouponUIDispatchers } from './couponUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { GetCoupons } from 'src/models/ICoupon';
import { CouponDispatchers } from '../coupons/coupon.dispatchers';
export class CouponUiEffects {
    constructor(actions$, _socketGateway, _store, _couponDispatchers, _couponsDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._couponDispatchers = _couponDispatchers;
        this._couponsDispatchers = _couponsDispatchers;
        this.getCoupons = this.actions$.pipe(ofType("UI_COUPONS_RECEIVED" /* UI_COUPONS_RECEIVED */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState, selectedChat]) => {
            const action = val;
            if (selectedChat.id === action.groupId) {
                this._couponDispatchers.receivedCouponHistoryChunk(action.eop, action.sop, action.currentPage);
                this._couponsDispatchers.receivedCouponsList(action.currentPage);
            }
        }));
        this.getNextCalendarPage = this.actions$.pipe(ofType("UI_COUPON_GET_NEXT_PAGE" /* UI_COUPON_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState]) => {
            const prevRequest = couponUiState.previousRequest;
            // if (
            //   !(
            //     couponUiState.eop === prevRequest.eop &&
            //     PageDirection.NEXT === prevRequest.direction &&
            //     couponUiState.hash === prevRequest.hash
            //   )
            // ) {
            this._socketGateway.sendSocketMessage(new GetCoupons(0, couponUiState.hash, couponUiState.eop, couponUiState.couponsStatus, couponUiState.expire));
            // }
        }));
        this.getPrevCalendarPage = this.actions$.pipe(ofType("UI_COUPON_GET_PREV_PAGE" /* UI_COUPON_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState]) => {
            const prevRequest = couponUiState.previousRequest;
            // if (
            //   !(
            //     couponUiState.sop === prevRequest.sop &&
            //     PageDirection.PREV === prevRequest.direction &&
            //     couponUiState.hash === prevRequest.hash
            //   )
            // ) {
            this._socketGateway.sendSocketMessage(new GetCoupons(1, couponUiState.hash, couponUiState.sop, couponUiState.couponsStatus, couponUiState.expire));
            // }
        }));
        this.afterCalendarSectionSelection = this.actions$.pipe(ofType("UI_COUPON_LIST" /* UI_LIST_COUPON */), map((action) => {
            this._couponDispatchers.getNextCouponPage();
        }));
        this.afterCalendarResetPaging = this.actions$.pipe(ofType("UI_COUPON_RESET_PAGING" /* UI_COUPON_RESET_PAGING */), map((action) => {
            this._couponDispatchers.getNextCouponPage();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getCoupons", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getNextCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getPrevCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "afterCalendarSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "afterCalendarResetPaging", void 0);
