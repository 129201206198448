import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';
import { SharedDirectivesModule } from '../shared/shared-directives.module';

import { DeleteBtnComponent } from './delete-btn.component';
import { MoveBtnComponent } from './move-btn.component';
import { CloseBtnComponent } from './close-btn.component';
import { EditBtnComponent } from './edit-btn.component';
import { PayBtnComponent } from './pay-btn.component';
import { AddBtnComponent } from './add-btn.component';

import { BorderBtnComponent } from './border-btn/border-btn.component';
import { CircleBtnComponent } from './circle-btn/circle-btn.component';
import { SquareBtnComponent } from './square-btn/square-btn.component';
import { SwitchBtnComponent } from './switch-btn/switch-btn.component';
import { InfoBtnComponent } from './info-btn/info-btn.component';
import { LinkBtnComponent } from './link-btn/link-btn.component';
import { PagingBtnComponent } from './paging-btn/paging-btn.component';
import { RoundPagingBtnComponent } from './round-paging-btn/round-paging-btn.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { AlignBtnComponent } from './align-btn/align-btn.component';
import { ReservationsBtnComponent } from './reservations-btn.component';
import { CancelBtnComponent } from './cancel-btn.component';
import { EnableServiceBtnComponent } from './enable-service-btn.component';
import { HeaderButtonsComponent } from '../+merchant/+dashboard/app-mgmt/custom/custom-area/common/header-buttons/header-buttons.component';
import { NextButtonComponent } from '../+merchant/+dashboard/app-mgmt/custom/custom-area/common/next-button/next-button.component';
import { CustomSwitchBtnComponent } from './custom-switch-btn/custom-switch-btn.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    AbstractIconsModule,
    SharedDirectivesModule
  ],
  declarations: [
    DeleteBtnComponent,
    CancelBtnComponent,
    MoveBtnComponent,
    CloseBtnComponent,
    EditBtnComponent,
    PayBtnComponent,
    AddBtnComponent,
    BorderBtnComponent,
    CircleBtnComponent,
    SquareBtnComponent,
    SwitchBtnComponent,
    InfoBtnComponent,
    LinkBtnComponent,
    PagingBtnComponent,
    RoundPagingBtnComponent,
    ProgressBarComponent,
    AlignBtnComponent,
    HeaderButtonsComponent,
    NextButtonComponent,
    ReservationsBtnComponent,
    EnableServiceBtnComponent,
    CustomSwitchBtnComponent
  ],
  exports: [
    DeleteBtnComponent,
    CancelBtnComponent,
    MoveBtnComponent,
    HeaderButtonsComponent,
    NextButtonComponent,
    CloseBtnComponent,
    EditBtnComponent,
    PayBtnComponent,
    AddBtnComponent,
    BorderBtnComponent,
    CircleBtnComponent,
    SquareBtnComponent,
    SwitchBtnComponent,
    InfoBtnComponent,
    LinkBtnComponent,
    PagingBtnComponent,
    RoundPagingBtnComponent,
    ProgressBarComponent,
    AlignBtnComponent,
    ReservationsBtnComponent,
    EnableServiceBtnComponent,
    CustomSwitchBtnComponent
  ]
})
export class AbstractBtnsModule {}
