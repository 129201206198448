import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputCodeComponent implements OnInit {
  firstFocused = true;
  secondFocused = false;
  thirdFocused = false;
  fourthFocused = false;
  fifthFocused = false;
  sixthFocused = false;

  @Output() valuesEntered = new EventEmitter<string>();

  currentCode: string[] = [];
  constructor() {}

  ngOnInit() {}

  onChange(event: string, id: number) {
    this.currentCode[id] = event;
    const codeVal = this.currentCode.join('');
    // if (codeVal.length === 6) {
    this.valuesEntered.emit(codeVal);
    // }
  }

  onInput(id) {
    switch (id) {
      case 0: {
        this.secondFocused = true;
        break;
      }
      case 1: {
        this.thirdFocused = true;
        break;
      }
      case 2: {
        this.fourthFocused = true;
        break;
      }
      case 3: {
        this.fifthFocused = true;
        break;
      }
      case 4: {
        this.sixthFocused = true;
        break;
      }
      case 5: {
        this.sixthFocused = false;
        this.firstFocused = true;
        break;
      }
    }
  }
}
