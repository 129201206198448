import * as PaymentActions from './payment.actions';

import { IPayment } from 'src/models/IPayment';
import { PAY_LINK, CANCEL_PAY_SUFFIX } from 'src/models/constants';

export function paymentsReducer(
  state: IPayment = {},
  action: PaymentActions.PaymentActions
): IPayment {
  switch (action.type) {
    case PaymentActions.PaymentActionTypes.SET_PAYMENT_TOKEN:
      if (action.payload) {
        return {
          ...state,
          paymentToken: action.payload
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.SET_PRICE_PLANS:
      if (action.payload) {
        return {
          ...state,
          pricePlans: action.payload
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.RCVD_PAYMENT_AUTH_INFO:
      if (action.payload) {
        return {
          ...state,
          payment_status: action.payload.payment_status,
          payment_plan: action.payload.plan_id
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.SET_CURRENT_PAYMENT:
      if (action.payload) {
        return {
          ...state,
          current_plan: action.payload
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.SET_PAYMENT_INFO:
      if (action.payload) {
        return {
          ...state,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
          email: action.payload.email,
          domain: action.payload.domain,
          payment_status: action.payload.payment_status,
          payment_failure_message: action.payload.payment_failure_message,
          payment_plan: action.payload.plan_id
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.SET_PAYMENT_URL:
      if (action.payload) {
        return {
          ...state,
          paymentToken: action.payload,
          url: `${PAY_LINK}/${state.plan.id}/${action.payload}`
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.SET_SELECTED_PAYMENT:
      if (action.payload && state.paymentToken) {
        return {
          ...state,
          plan: action.payload,
          url: `${PAY_LINK}/${action.payload.id}/${state.paymentToken}`
        };
      }
      return {
        ...state,
        plan: action.payload
      };
    case PaymentActions.PaymentActionTypes.SET_CANCELED_PAYMENT:
      if (action.payload) {
        return {
          ...state,
          canceled_plan: action.payload,
          canceled_url: `${PAY_LINK}${CANCEL_PAY_SUFFIX}${action.payload.id}/${state.paymentToken}`
        };
      }
      return state;
    case PaymentActions.PaymentActionTypes.RESET_PAYMENT_PLAN:
      return {
        ...state,
        canceled_plan: null,
        plan: null
      };
    case PaymentActions.RESET:
      return {};
    default:
      return state;
  }
}
