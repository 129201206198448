import {
  GET_CHAT_METHOD,
  GET_CHAT_COUNTERS_METHOD,
  CHAT_CALLBACK,
  GET_CHAT_HISTORY_METHOD,
  CREATE_CHAT_METHOD,
  SEND_SET_CHAT_METHOD,
  GET_SUB_CHAT_LIST_METHOD,
  EVENT_CHANNEL,
  CREATE_EVENT_REF,
  GROUP,
  CREATE_GROUP_REF,
  CHANNEL,
  CREATE_CHANNEL_REF,
  REMOVE_CHAT_ADMIN_METHOD,
  CHANNEL_CATEGORIES,
  BOOKING_CHANNEL,
  CREATE_BOOKING_SPACE_REF,
  TIMEZONE_KEY,
  SET_BOOKING_EXCEPTIONS_METHOD,
  REMOVE_BOOKING_EXCEPTIONS_METHOD,
  GET_BOOKING_EXCEPTIONS_METHOD,
  MERCHANT_PAGE_SIZE,
  GET_CONFIG_QR_CODE_METHOD,
  ENABLE_SERVICE_METHOD,
  GET_CHAT_INFO_METHOD,
  DELETE_CHAT_METHOD,
  CREATE_VIRTUAL_APP_REF,
  CREATE_CONNECTED_ACCOUNT,
  RESET_CONNECTED_ACCOUNT,
  GET_CHAT_ID_METHOD
} from './constants';
import { Photo } from './Photo';
import { ITag } from './ITag';
import { IRole } from './IRole';
import { RcvdMessage } from './MessageParts';
// import { AbstractTableColumn } from './AbstractItem';

export interface IChat {
  image?: string;
  inivite_link?: string;
  language_code?: any;
  regions?: any;
  description?: string;
  photo?: Photo;
  id?: string;
  parent_id?: string;
  member_count?: number;
  title?: string;
  type?: string; // Channel or Group
  version?: string;
  localMedia?: string;
  localThumbnail?: string;
  mute?: boolean;
  pinned_date?: number;
  favourite?: boolean;
  admin?: boolean; // Boolean flag to indicate if the logged in user is an admin to that chat
  backgroundColor?: string;
  lastTypedMessage?: string;
  isPublic?: number; //  flag 0 or 1
  isHistoryRequested?: boolean;
  detailsReceived?: boolean;
  tagsDefinition?: ITag[];
  chatRoles?: IRole[];
  // Event Fields
  address?: string;
  address2?: string;
  longitude?: string;
  latitude?: string;
  startDate?: string; // 'YYYY-MM-DD'
  endDate?: string; // 'YYYY-MM-DD'
  startTime?: number;
  endTime?: number;
  allDay?: number; //  flag 0 or 1
  domain?: string; // new field used during creating channel in signup
  reference?: string;
  map_id?: string;
  timezone?: string; // Timezone name
  p?: number; // Permenant flag used in input
  admins?: string[]; // IDs of admin users to this chat
  subChannel?: boolean;
  isChannelApp?: boolean;
  isUpgradedChannel?: boolean;
  createdDate?: number;
  slotDuration?: number;
  slotSpace?: number;
  slotMax?: number;

  approved?: number;

  // Bulk Upload
  recordIndex?: number;
  // Searching Categories
  area?: string;
  classification?: string;
  category?: string;
  configQrCode?: string;
  // Welcome Message
  welcome_message?: RcvdMessage;
  welcomeMessageId?: string;

  splash?: number;
  splash_config?: {
    title?: string;
    image_url?: string;
    desc?: string;
    btn_text?: string;
    btn_bgcolor?: string;
    btn_fgcolor?: string;
  };
  payment_enabled?: number;
  cancel?: number; // 0 or 1 means canellable or not
  cancel_period?: string; // 'HOUR','DAY','WEEK','MONTH'
  cancel_period_number?: number; //  multiples of the period
  price?: string;
  currency?: string;
  period?: string;
  period_number?: string;
  product_id?: number;
  provider_id?: string;
  balance?: number;
  //location
  marker?: string;
  mStore?: string;
  vapp?: number;
  adFree?: number;
  permanent?: number;
  owner_id?: string;
  uploadImage?: boolean;
  onboard?: boolean;
}

export interface ReceivedChats {
  method?: string;
  chat_list?: IChat[];
  reference?: string;
}

export class ReceiveChat {
  readonly method = CHAT_CALLBACK;
  constructor(public chat: IChat) {}
}

export class GetChatTemplate {
  readonly method = GET_CHAT_METHOD;
  readonly p = 1;
  constructor(public chat_id: string) {}
}

export class GetChatHistory {
  readonly method = GET_CHAT_HISTORY_METHOD;
  constructor(public chat_id: string) {}
}

export class GetChatCounters {
  readonly method = GET_CHAT_COUNTERS_METHOD;
  constructor(public chat_id: string, public message_ids: string[]) {}
}

export class CreateChat {
  readonly method = CREATE_CHAT_METHOD;
  constructor(public chat: IChat) {
    if (!chat.timezone) {
      chat.timezone = localStorage.getItem(TIMEZONE_KEY);
    }
  }
}

export class GetChatId {
  readonly method = GET_CHAT_ID_METHOD;
  constructor(public type: string) {}
}

export class DeleteChat {
  readonly method = DELETE_CHAT_METHOD;
  constructor(public group_id: string) {}
}

export class SetChat {
  readonly method = SEND_SET_CHAT_METHOD;
  constructor(public chat: IChat, public reference?: string) {
    chat.p = 1;
    if (!chat.timezone) {
      chat.timezone = localStorage.getItem(TIMEZONE_KEY);
    }
  }
}

// reference is the eop
// direction (0 --> Next, 1 --> Back)
export class GetSubChats {
  readonly method = GET_SUB_CHAT_LIST_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public type: string[],
    public direction?: number,
    public hash?: string,
    public reference?: number,
    public is_product?: number,
    public name?: string,
    public vapp: number = 0
  ) {}
}

// Need privilege REMOVE_ADMINS
export class RemoveChatAdminMethod {
  readonly method = REMOVE_CHAT_ADMIN_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}

// data is a string array of dates in the format 2019-06-25
export class SetBookingExceptions {
  readonly method = SET_BOOKING_EXCEPTIONS_METHOD;
  readonly clear = 1;
  constructor(
    public chat_id: string,
    public data: string[],
    public recordIndex?: number
  ) {}
}

// date in the format 2019-06-25
export class GetBookingExceptions {
  readonly method = GET_BOOKING_EXCEPTIONS_METHOD;
  constructor(public chat_id: string, public date: string) {}
}

export class RemoveBookingExceptions {
  readonly method = REMOVE_BOOKING_EXCEPTIONS_METHOD;
  constructor(public chat_id: string, public data: string[]) {}
}

export class GetConfigQrCode {
  readonly method = GET_CONFIG_QR_CODE_METHOD;
  constructor() {}
}

// Events
export function createEventMethod(chat: IChat) {
  chat.type = EVENT_CHANNEL;
  chat.isPublic = 1;
  chat.reference = chat.reference ? chat.reference : CREATE_EVENT_REF;
  return new CreateChat(chat);
}

// creating booking space
export function createBookingChannelMethod(chat: IChat) {
  chat.type = BOOKING_CHANNEL;
  chat.isPublic = 1;
  chat.reference = chat.reference ? chat.reference : CREATE_BOOKING_SPACE_REF;
  return new CreateChat(chat);
}

export function createGroupMethod(chat: IChat) {
  chat.type = chat.type || GROUP;
  chat.reference = chat.reference ? chat.reference : CREATE_GROUP_REF;
  chat.isPublic = 1;
  if (chat.type === CHANNEL) {
    chat.category = chat.category ? chat.category : CHANNEL_CATEGORIES.Social;
  }
  return new CreateChat(chat);
}

export function createChannelMethod(chat: IChat) {
  chat.type = CHANNEL;
  chat.reference = chat.reference ? chat.reference : CREATE_CHANNEL_REF;
  // chat.isPublic = 1;
  if (chat.type === CHANNEL) {
    chat.category = chat.category ? chat.category : CHANNEL_CATEGORIES.Social;
  }
  return new CreateChat(chat);
}

export function createVirtualAppMethod(chat: IChat) {
  chat.type = CHANNEL;
  chat.reference = chat.reference ? chat.reference : CREATE_VIRTUAL_APP_REF;
  chat.isPublic = 1;
  chat.category = chat.category ? chat.category : CHANNEL_CATEGORIES.Social;
  chat.vapp = 1;
  return new CreateChat(chat);
}

export class EnableServiceMethod {
  readonly method = ENABLE_SERVICE_METHOD;
  constructor(
    public chat_id: string,
    public period?: string,
    public period_number?: number,
    public price?: number,
    public currency?: string
  ) {}
}

export class GetChatInfo {
  readonly method = GET_CHAT_INFO_METHOD;
  constructor(public chats: string[], public ref?: string) {}
}

export class CreateStripeSub {
  readonly method = CREATE_CONNECTED_ACCOUNT;
  constructor() {}
}

export class ResetStripeSub {
  readonly method = RESET_CONNECTED_ACCOUNT;
  constructor(public provider_id: string) {}
}
export type ChatMethods =
  | ReceiveChat
  | DeleteChat
  | GetChatTemplate
  | GetChatHistory
  | GetChatCounters
  | CreateChat
  | GetSubChats
  | RemoveChatAdminMethod
  | EnableServiceMethod;
