<a
  *ngIf="href"
  [href]="href"
  [target]="openSelf ? '_self' : '_blank'"
  class="app-btn"
  [class.default]="isDefaultLink"
>
  <ng-content select="link-content"></ng-content>
</a>

<div
  *ngIf="!href"
  class="app-btn"
  (click)="onClick()"
  [class.default]="isDefaultLink"
>
  <ng-content select="link-content-clicked"></ng-content>
</div>
