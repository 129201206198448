<div class="container">
  <div class="container-box"
    (click)="selectEvent(mStyles.WIDE)">
    <div class="container-box-header">
      <span>
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-extra-icons.svg#icon-wide"></use>
        </svg>
        Wide
      </span>
      <div class="checked-style">
        <input type="checkbox"
          class="check-checkbox"
          [checked]="localState?.slectedWide"
          id="wide"
          #wide />
        <label class="check-Label"
          for="wide">
          <svg class="svg-icon icon-icon">
            <use xlink:href="/assets/svg/app-extra-icons.svg#icon-done-radio"></use>
          </svg>
        </label>
      </div>
    </div>
    <img src="./assets/img/wilde.png"
      alt="wilde">
  </div>
  <div class="container-box"
    (click)="selectEvent(mStyles.LEFT_SIDE)">
    <div class="container-box-header">
      <span>
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-extra-icons.svg#icon-left-side"></use>
        </svg>
        Left Side
      </span>
      <div class="checked-style">
        <input type="checkbox"
          class="check-checkbox"
          [checked]="localState?.slectedLeftSide"
          id="leftSide"
          #leftSide />
        <label class="check-Label"
          for="leftSide">
          <svg class="svg-icon icon-icon">
            <use xlink:href="/assets/svg/app-extra-icons.svg#icon-done-radio"></use>
          </svg>
        </label>
      </div>
    </div>
    <img src="./assets/img/left-side.png"
      alt="left-side">
  </div>
</div>