import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as PaymentProvidersActions from './paymentProviders.actions';
import { map } from 'rxjs/operators';
import {
  SetPaymentProviderMethod,
  GetPaymentProvidersMethod,
  EnableAllServicesMethod
} from 'src/models/PaymentGateways';

@Injectable()
export class PaymentProvidersEffects {
  @Effect({ dispatch: false })
  setPaymentProvider = this.actions$.pipe(
    ofType(
      PaymentProvidersActions.PaymentProvidersActionTypes.SET_PAYMENT_PROVIDER
    ),
    map((action: PaymentProvidersActions.SetPaymentProvider) => {
      this._socketGateway.sendSocketMessage(
        new SetPaymentProviderMethod(
          action.providerId,
          action.config,
          action.active
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getPaymentProviders = this.actions$.pipe(
    ofType(
      PaymentProvidersActions.PaymentProvidersActionTypes.GET_PAYMENT_PROVIDERS
    ),
    map((action: PaymentProvidersActions.GetPaymentProviders) => {
      this._socketGateway.sendSocketMessage(new GetPaymentProvidersMethod());
    })
  );

  @Effect({ dispatch: false })
  enableAllServices = this.actions$.pipe(
    ofType(
      PaymentProvidersActions.PaymentProvidersActionTypes.ENABLE_ALL_SERVICES
    ),
    map((action: PaymentProvidersActions.EnableAllServices) => {
      this._socketGateway.sendSocketMessage(new EnableAllServicesMethod());
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
