import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { BlackListUiDispatchers } from './blackListUI.dispatchers';
import { BlacklistUIActionTypes } from './blacklistUI.actions';
import * as BlackListActions from './blacklistUI.actions';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { blackListUiReducer } from './blackListUI.reducer';
import { PageDirection } from 'src/models/constants';

import * as isEqual from 'lodash.isequal';
import {
  GetBlackList,
  AddUserToBlacklist,
  RemoveUserFromBlacklist
} from 'src/models/BlackListsUI';

@Injectable()
export class BlackListUiEffects {
  @Effect({ dispatch: false })
  afterBlackListResetPaging = this.actions$.pipe(
    ofType(BlacklistUIActionTypes.UI_BLACKLIST_RESET_PAGING),
    map((action: BlackListActions.ResetBlackListPaging) => {
      this._uiBlackListDispatchers.getNextBlacklistsPage();
    })
  );

  @Effect({ dispatch: false })
  getNextBlackListPage = this.actions$.pipe(
    ofType(BlacklistUIActionTypes.UI_BLACKLIST_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.blackListUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, blackListUiReducer]) => {
      const action = <BlackListActions.GetNextBlacklistsPage>val;
      const prevRequest = blackListUiReducer.previousRequest;
      if (
        !(
          blackListUiReducer.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          blackListUiReducer.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBlackList(
            null,
            0,
            blackListUiReducer.hash,
            blackListUiReducer.eop,
            blackListUiReducer.msisdn
          )
        );
      } else {
        this._uiBlackListDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  addUserToBlacklist = this.actions$.pipe(
    ofType(BlacklistUIActionTypes.UI_ADD_USER_TO_BLACKLIST),
    map((action: BlackListActions.AddUserToBlacklist) => {
      this._socketGateway.sendSocketMessage(
        new AddUserToBlacklist(
          action.users,
          action.mainChatID,
          action.reference,
          action.recordIndex
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevBlackListPage = this.actions$.pipe(
    ofType(BlacklistUIActionTypes.UI_BLACKLIST_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.blackListUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, blackListUiReducer]) => {
      const action = <BlackListActions.GetNextBlacklistsPage>val;
      const prevRequest = blackListUiReducer.previousRequest;
      if (
        !(
          blackListUiReducer.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          blackListUiReducer.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBlackList(
            null,
            1,
            blackListUiReducer.hash,
            blackListUiReducer.sop,
            blackListUiReducer.msisdn
          )
        );
      } else {
        this._uiBlackListDispatchers.setStatusAsIdle();
      }
    })
  );
  @Effect({ dispatch: false })
  deleteUserFromBlacklist = this.actions$.pipe(
    ofType(BlacklistUIActionTypes.UI_REMOVE_USER_FROM_BLACKLIST),
    map((action: BlackListActions.RemoveUserFromBlacklist) => {
      this._socketGateway.sendSocketMessage(
        new RemoveUserFromBlacklist(action.users, action.mainChatID)
      );
    })
  );
  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiBlackListDispatchers: BlackListUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
