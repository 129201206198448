import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { SetAppStoreMethod, GetAppStoreMethod, SetAppleStoreMethod, SetAppInfoMethod, GetAppsSetupMethod } from 'src/models/AppPublishInfo';
import { GetMyMembers } from 'src/models/IChatMember';
import { UserStatus } from 'src/models/constants';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { GetAppsMethod } from 'src/models/MultipleApps';
export class AppPublishingEffects {
    constructor(actions$, _socketGateway, _uiDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this.setAppStoreInfo = this.actions$.pipe(ofType("SET_APP_STORE_INFO" /* SET_APP_STORE_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppStoreMethod(action.appPublishInfo));
            this._socketGateway.sendSocketMessage(new GetAppsMethod());
        }));
        this.setAppleStoreInfo = this.actions$.pipe(ofType("SET_APPLE_STORE_INFO" /* SET_APPLE_STORE_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppleStoreMethod(action.os, action.appStoreInfo));
        }));
        this.setAppInfo = this.actions$.pipe(ofType("SET_APP_INFO" /* SET_APP_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppInfoMethod(action.os, action.appInfo));
        }));
        this.requestTesters = this.actions$.pipe(ofType("REQUEST_APP_TESTERS" /* REQUEST_APP_TESTERS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetMyMembers(UserStatus.ACTIVE, 1));
        }));
        this.receiveError = this.actions$.pipe(ofType("RECEIVE_PUBLISH_INFO_ERROR" /* RECEIVE_PUBLISH_INFO_ERROR */), map((action) => {
            this._uiDispatchers.showPopup(SERVER_ERROR_MESSAGES[action.errorNumber]);
        }));
        this.receiveErrorCode = this.actions$.pipe(ofType("RECEIVE_ERROR_CODE" /* RECEIVE_ERROR_CODE */), map((action) => {
            this._uiDispatchers.showErrorPopup(SERVER_ERROR_MESSAGES[action.errorNumber]);
        }));
        this.requestAppStore = this.actions$.pipe(ofType("REQUEST_APP_STORE_INFO" /* REQUEST_APP_STORE_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppStoreMethod(action.appID));
        }));
        this.receiveAppSetupInfo = this.actions$.pipe(ofType("GET_APPS_SETUP_INFO" /* GET_APPS_SETUP_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "setAppStoreInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "setAppleStoreInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "setAppInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "requestTesters", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "receiveError", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "receiveErrorCode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "requestAppStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppPublishingEffects.prototype, "receiveAppSetupInfo", void 0);
