import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/core/analytics.service';

import { AuthService } from 'src/app/core/auth.service';
// import { checkChecked } from 'src/app/validators/checkChecked';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
// import { templateIdValidators } from 'src/app/validators/templateIdValidator';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';

import * as AuthMethods from 'src/models/IAuth';
import { COUNTRIES } from 'src/models/Countries';
import { AppForm } from 'src/models/AppForm';
import {
  SignupStatus,
  HtttpMethodErrors,
  SIGNUP_LINK,
  PRIVACY_LINK,
  TERMS_LINK,
  UISections
} from 'src/models/constants';
// import { ANALYTICS_EVENTS } from 'src/models/IAnalytics';
declare var LinkMink: any;
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/core/storage.service';
import { AppConfigDispatchers } from 'src/app/store/channelAppConfig/appConfig.dispatchers';
import { DashboardService } from './../../+dashboard/dashboard.service';
import { SocketService } from 'src/app/network/services/socket.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent
  implements OnInit, OnChanges, AfterViewInit, AppForm {
  @Input() countryIso = '';
  @Input() auth;
  @ViewChild('loginRef', { static: false }) loginElement: ElementRef;
  form: FormGroup;
  hidePassword = true;
  waiting = false;
  signupLink = SIGNUP_LINK;
  privacyLink = PRIVACY_LINK;
  termsLink = TERMS_LINK;
  countryOptions = COUNTRIES;

  signupStatus: typeof SignupStatus = SignupStatus;
  selectedCountry: string;
  searchValue: string;
  auth2: any;
  constructor(
    private _ref: ChangeDetectorRef,
    private _validateForm: FormValidatorsService,
    public authService: AuthService,
    private _route: Router,
    private route: ActivatedRoute,
    public localStorage: StorageService,
    private location: Location,
    private _analyticsService: AnalyticsService,
    public _appConfigDispatchers: AppConfigDispatchers,
    public dashboardService: DashboardService,
    public socketService: SocketService
  ) {
    this.form = this.setFormGroup();
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.authService.callLoginButton('signup'), 500);
  }

  ngOnInit() {
    const url = this.location.path();
    const urlPath = url.split('?');
    if (urlPath[0] === '/signup' && urlPath[1]) {
      const id = urlPath[1].replace('id=', '');

      if (id && id !== 'undefined' && id !== 'null' && id !== null) {
        this.localStorage.setRecord('tempId', id);

        // if (id && this.localStorage.getRecord('TOKEN')) {
        //   this.localStorage.setRecord('newTempId', id);

        // console.log('in sign up form');
        // this._appConfigDispatchers.requestAppTemplate(id, 'startover');

        // setTimeout(() => {
        //   this._route.navigate(['/app']);
        //   this.dashboardService.setSection(UISections.APP);
        // }, 3000);
        // }
      }

      // this.route.queryParams.subscribe(params => {
      //   if (params.lmref && params.lmref !== 'undefined') {
      //     this.localStorage.setRecord('lmref', params.lmref);
      //   }
      // });
    } else if (urlPath[0] === '/signup') {
      this.localStorage.setRecord('tempId', null);
    }
    this.form = this.setFormGroup();

    // const scriptLinkmink = document.createElement('script');
    // scriptLinkmink.src = 'https://cdn.linkmink.com/lm-js/2.3.1/lm.js';
    // scriptLinkmink.async = true;
    // document.body.appendChild(scriptLinkmink);
    setTimeout(
      () =>
        LinkMink('pub_live_GVfRGSTJojMWs6mFEP2J')
          .initTracking()
          .then(),
      1000
    );
  }

  ngOnChanges(chngs: SimpleChanges) {
    const countryIsoChng = chngs['countryIso'];
    if (
      countryIsoChng &&
      countryIsoChng.currentValue &&
      countryIsoChng.currentValue !== countryIsoChng.previousValue &&
      this.form
    ) {
      this.form.get('countryIso').setValue(countryIsoChng.currentValue);
      this.selectedCountry = this.countryOptions.find(
        res => res.key === this.countryIso
      ).value;
    }
  }

  setFormGroup() {
    return new FormGroup({
      appName: new FormControl('My App', [Validators.required]),
      name: new FormControl(this.auth && this.auth.name ? this.auth.name : '', [
        Validators.required
      ]),
      email: new FormControl(
        this.auth && this.auth.email ? this.auth.email : '',
        [Validators.required, Validators.email]
      ),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(8),
        noWhitespaceValidator
      ]),
      terms: new FormControl(true),
      privacy: new FormControl(true),
      countryIso: new FormControl(
        this.auth && this.auth.countryIso ? this.auth.countryIso : '',
        [Validators.required]
      ),
      agree: new FormControl(true)
    });
  }

  onSubmit() {
    if (this.form.valid) {
      // this._analyticsService.eventEmitter(
      //   ANALYTICS_EVENTS.SIGN_UP_EVENT.category,
      //   ANALYTICS_EVENTS.SIGN_UP_EVENT.action,
      //   ANALYTICS_EVENTS.SIGN_UP_EVENT.label,
      //   ANALYTICS_EVENTS.SIGN_UP_EVENT.value
      // );
      this.waiting = true;
      this._ref.detectChanges();
      this.authService.getEmailTac(this.form.value).subscribe(response => {
        const res = <AuthMethods.HttpResult>response;
        if (res.result === 0) {
          this.authService.emailTacSuccess(this.form.value);
          this._route.navigate(['/verifyCode']);
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.FREE_MAIL) {
          this.email.setErrors({ noFreeMail: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.WRONG_FORMAT) {
          this.email.setErrors({ wrongFormat: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED) {
          this.email.setErrors({ mailUsed: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED_2) {
          this.email.setErrors({ mailUsed2: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED_3) {
          this.email.setErrors({ mailUsed3: true });
          this.waiting = false;
          this._ref.detectChanges();
        }
      });
    } else {
      // Validate on submit
      this._validateForm.validateAllFormFields(this.form);
    }
  }
  get email() {
    return this.form.get('email');
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  setSelectedCountry(country, dd) {
    this.selectedCountry = country.value;
    this.form.patchValue({
      countryIso: country.key
    });

    dd.hide();
  }

  searchNotfire(event) {
    this.searchValue = event.target.value;
  }

  goEmail() {
    this.localStorage.setRecord('loginType', 'email');
    this.authService.loginUseEmail();
    this._ref.detectChanges();
  }

  openLink(link) {
    window.open(link);
  }
}
