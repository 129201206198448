<img *ngIf="profile.image"
  appCancelDrag
  [src]="thumbnail"
  [alt]="profile?.name"
  class="circle-color"
  [class.square]="isSquare"
  [ngStyle]="cssStyles"
  draggable="false" />

<!-- <span
  *ngIf="!profile.image"
  appCancelDrag
  class="circle-color"
  [class.square]="isSquare"
  [innerHTML]="profile?.name | nickName | twemoji"
  [ngStyle]="cssStyles"
>
</span> -->
<img *ngIf="!profile.image"
  [src]="cssPlaceHolderImage"
  [class.square]="isSquare"
  [ngStyle]="cssStyles"
  [alt]="chatType"
  class="circle-color"
  draggable="false" />