import { Store } from '@ngrx/store';
import { MapTripsNotifiers } from 'src/models/mapTripsNotifiers';
import * as MapTripsNotifiersActions from './mapTripsNotifiers.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapTripsNotifiersDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetMapTripsNotifiersPaging() {
        this._store.dispatch(new MapTripsNotifiersActions.ResetMapTripsNotifiersPaging());
    }
    getTripsNotifiers() {
        this._store.dispatch(new MapTripsNotifiersActions.GetTripsNotifiers());
    }
    createMapAccountLinks(tripNotifier) {
        this._store.dispatch(new MapTripsNotifiersActions.CreateMapAccountLinks(tripNotifier));
    }
    mapAccountLinksCreated(tripNotifiers) {
        this._store.dispatch(new MapTripsNotifiersActions.MapAccountLinksCreated(tripNotifiers));
    }
    getNotifiersList(accountID) {
        this.restNotifiersList();
        this._store.dispatch(new MapTripsNotifiersActions.ResetNotifiersListPaging(accountID));
    }
    getNextNotifiersList() {
        this._store.dispatch(new MapTripsNotifiersActions.GetNextNotifiersPage());
    }
    getPrevNotifiersList() {
        this._store.dispatch(new MapTripsNotifiersActions.GetPrevNotifiersPage());
    }
    removeAccountLink(id) {
        this._store.dispatch(new MapTripsNotifiersActions.RemoveAccountLink(id));
    }
    updateMapAccountLinks(accountID, link_ids) {
        this._store.dispatch(new MapTripsNotifiersActions.UpdateMapAccountLinks(accountID, link_ids));
    }
    setMapAccountLinksCount(accountID, count) {
        this._store.dispatch(new MapTripsNotifiersActions.SetMapAccountLinksCount(accountID, count));
    }
    removeAccountLinkSuccess(id) {
        this._store.dispatch(new MapTripsNotifiersActions.RemoveAccountLinkSuccess(id));
    }
    restNotifiersList() {
        this._store.dispatch(new MapTripsNotifiersActions.RestNotifiersList());
    }
    notifiersListReceived(tripNotifiers, eop, sop, reference) {
        this._store.dispatch(new MapTripsNotifiersActions.NotifiersListReceived(eop, sop, tripNotifiers, reference));
    }
    mapAccountLinksListReceived(accountID) {
        this._store.dispatch(new MapTripsNotifiersActions.MapAccountLinksListReceived(accountID));
    }
}
MapTripsNotifiersDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapTripsNotifiersDispatchers_Factory() { return new MapTripsNotifiersDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapTripsNotifiersDispatchers, providedIn: "root" });
