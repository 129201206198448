import { Actions } from './packagesUI.actions';

import * as actions from './packagesUI.actions';
import { PackageUI } from 'src/models/Package';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';

const INITIAL_STATE: PackageUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function packagesUiReducer(
  state = INITIAL_STATE,
  action: Actions
): PackageUI {
  switch (action.type) {
    case actions.PackagesUIActionTypes.PACKAGE_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(r => {
          if (r.id === action.pkg.id) {
            return action.pkg;
          }
          return r;
        })
      };
    }
    case actions.PackagesUIActionTypes.PACKAGE_DELETED: {
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.id !== action.id)
      };
    }
    case actions.PackagesUIActionTypes.UI_PACKAGES_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.PackagesUIActionTypes.UI_PACKAGES_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.PackagesUIActionTypes.UI_PACKAGES_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.PackagesUIActionTypes.UI_PACKAGES_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.PackagesUIActionTypes.UI_PACKAGES_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.PackagesUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
