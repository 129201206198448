import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from '../../network/gateway/socket.gateway';
import { CampaignMiddleware } from './campaign.middleware';

import { AppState } from 'src/models/AppState';
import * as CampaignActions from './campaign.actions';
import * as MessagesMethods from 'src/models/IMessage';
import * as CampaignMethods from 'src/models/ICampaign';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class CampaignEffects {
  // get messageId for scope message to make sure it is exist when display campaign
  @Effect({ dispatch: false })
  campaignReceived = this.actions$.pipe(
    ofType(CampaignActions.CampaignActionTypes.REQUEST_SCOPE_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.scheduleReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messages, schedules]) => {
      const action = <CampaignActions.RequestScopeMessage>val;
      const isInMessages = messages.find(
        msg => msg.message_id === action.payload
      );
      const isInSchedules = schedules.find(
        msg => msg.message_id === action.payload
      );
      if (!isInMessages && !isInSchedules) {
        this._socketGateway.sendSocketMessage(
          new MessagesMethods.GetMessagesHistoryFromBusinessServer(
            action.payload,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          )
        );
      }
    })
  );

  // create campaign or update it
  @Effect({ dispatch: false })
  createCampaign = this.actions$.pipe(
    ofType(CampaignActions.CampaignActionTypes.CREATE_CAMPAIGN),
    map((action: CampaignActions.CreateCampaign) => {
      const campToSend = CampaignMiddleware.sendCampaignMessage(action.payload);
      this._socketGateway.sendSocketMessage(
        new CampaignMethods.CreateCampaign(campToSend)
      );
    })
  );

  @Effect({ dispatch: false })
  updateCampaign = this.actions$.pipe(
    ofType(CampaignActions.CampaignActionTypes.UPDATE_CAMPAIGN),
    map((action: CampaignActions.UpdateCampaign) => {
      const campToSend = CampaignMiddleware.sendCampaignMessage(action.payload);
      this._socketGateway.sendSocketMessage(
        new CampaignMethods.UpdateCampaign(campToSend)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _socketGateway: SocketGateway
  ) {}
}
