import { AppTemplate } from 'src/models/AppTemplate';
import { Actions } from './templates.actions';
import * as actions from './templates.actions';

const INITIAL_STATE: AppTemplate[] = [];

export function templateReducer(
  state = INITIAL_STATE,
  action: Actions
): AppTemplate[] {
  switch (action.type) {
    case actions.TemplateActionTypes.RECEIVE_TEMPLATES:
      return action.templates.map((template, index) => {
        if (index === 0) {
          template.selected = true;
        }
        return template;
      });
    case actions.TemplateActionTypes.SELECT_APP_TEMPLATE:
      return state.map(template => {
        let updatedTemplate: AppTemplate = {};
        if (template.id === action.template.id) {
          updatedTemplate.selected = true;
          updatedTemplate = { ...template, ...updatedTemplate };
          return updatedTemplate;
        } else {
          updatedTemplate.selected = false;
          updatedTemplate = { ...template, ...updatedTemplate };
          return updatedTemplate;
        }
      });
    case actions.TemplateActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
