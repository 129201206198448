import { Injectable } from '@angular/core';

import {
  EMOJI,
  EMOJI_CATEGORIES,
  EMOJI_CATEGORY_SPRITESHEET_DIMENS
} from './config';

@Injectable({ providedIn: 'root' })
export class EmojiboxService {
  emojis = {};
  shortcuts = {};
  spritesheetPositions = [];
  emojiMap = {};
  emojiCodeMap = {};

  constructor() {
    this.setEmojisData();
  }

  private setEmojisData() {
    const iconSize = 26;
    for (let i = 0; i < EMOJI_CATEGORIES.length; i++) {
      const totalColumns = EMOJI_CATEGORY_SPRITESHEET_DIMENS[i][1];
      for (let j = 0; j < EMOJI_CATEGORIES[i].length; j++) {
        // get emoji data
        const code = EMOJI_CATEGORIES[i][j];
        const emoji = EMOJI[code];
        if (emoji) {
          const shortcut = emoji[1][0];
          const emoji_utf8 = emoji[0];

          // build shortcuts and sprite sheet positions
          this.emojis[code] = [emoji[0], shortcut];
          this.shortcuts[shortcut] = code;
          this.spritesheetPositions.push([
            code,
            i,
            j,
            iconSize * (j % totalColumns),
            iconSize * Math.floor(j / totalColumns),
            emoji_utf8[0]
          ]);

          // build emojis dictionary
          for (let z = 0; z < emoji_utf8.length; z++) {
            this.emojiMap[emoji_utf8[i]] = code;
            this.emojiCodeMap[code] = emoji_utf8;
          }
        }
      }
    }
  }

  getEmojiSpritesheetCoords(emojiCode: string) {
    for (let category = 0; category < EMOJI_CATEGORIES.length; category++) {
      const totalColumns = EMOJI_CATEGORY_SPRITESHEET_DIMENS[category][1];
      const i = EMOJI_CATEGORIES[category].indexOf(emojiCode);
      if (i > -1) {
        const row = Math.floor(i / totalColumns);
        const column = i % totalColumns;

        return {
          category,
          row,
          column
        };
      }
    }
    // console.error('emoji not found in spritesheet', emojiCode);
    return null;
  }
}
