import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ItemActions from './subItem.actions';

import { AppState } from 'src/models/AppState';
import { LocalItem, LocalComponent } from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class SubItemDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateSubItem(itemRef: string, itemUpdatedProps: LocalItem): void {
    this._store.dispatch(
      new ItemActions.UpdateSubItem(itemRef, itemUpdatedProps)
    );
  }

  updateSubItemLink(itemRef: string, itemLink: string, pageId: string): void {
    this._store.dispatch(
      new ItemActions.UpdateSubItemLink(itemRef, itemLink, pageId)
    );
  }

  reorderSubItem(itemRef: string, newPosition: number): void {
    this._store.dispatch(new ItemActions.ReorderSubItem(itemRef, newPosition));
  }

  deleteSubItemRequest(item: LocalItem): void {
    this._store.dispatch(new ItemActions.DeleteSubItemRequest(item));
  }

  deleteSubItem(item: LocalItem): void {
    this._store.dispatch(new ItemActions.DeleteSubItem(item));
  }

  addSubItemRequest(
    parentComponent: LocalComponent,
    specialItemValues?: LocalItem
  ): void {
    this._store.dispatch(
      new ItemActions.AddSubItemRequest(parentComponent, specialItemValues)
    );
  }

  addSubItem(item: LocalItem): void {
    this._store.dispatch(new ItemActions.AddSubItem(item));
    this.updateSubItemLink(item.ref, item.link, item.page_id);
  }

  deleteSubItemsOfComponent(parentComponent: LocalComponent): void {
    this._store.dispatch(
      new ItemActions.DeleteSubItemsOfComponent(parentComponent)
    );
  }

  addSubItems(items: LocalItem[]): void {
    this._store.dispatch(new ItemActions.AddSubItems(items));
  }
}
