<div class="item-container">
  <div class="checked option-box">
    <div class="desc">
      <div class="icon-container">
        <svg class="tab-icon-svg">
          <use
            [attr.xlink:href]="
              './assets/svg/app-features-icons.svg#' + item?.icon
            "
            xlink:href=""
          ></use>
        </svg>
      </div>
      <div>
        {{ item?.title }}
      </div>
    </div>
    <input
      class="check-checkbox"
      type="checkbox"
      [checked]="moreTypes.indexOf(item) > -1"
      (change)="checkedItem(item)"
      [id]="item?.id"
    />

    <label class="check-Label" [for]="item?.id"> </label>
  </div>
</div>
