/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-options.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-options.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../core/ui.service";
var styles_MessageOptionsComponent = [i0.styles];
var RenderType_MessageOptionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageOptionsComponent, data: {} });
export { RenderType_MessageOptionsComponent as RenderType_MessageOptionsComponent };
function View_MessageOptionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "action-btn"]], [[8, "href", 4], [8, "download", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "download"], ["src", "./assets/img/ic-download-grey.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; var currVal_1 = ((_co.message == null) ? null : _co.message.media_id); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MessageOptionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "action-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recallEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-delete"]], null, null, null, null, null))], null, null); }
function View_MessageOptionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "action-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Tem\n"]))], null, null); }
export function View_MessageOptionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageOptionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageOptionsComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageOptionsComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDownloadable; _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.message == null) ? null : _co.message.loggedInIsSender) || _co.isAdmin); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.uiService.getEnableSetTemplateBtn$)); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MessageOptionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-options", [], null, null, null, View_MessageOptionsComponent_0, RenderType_MessageOptionsComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessageOptionsComponent, [i4.DomSanitizer, i5.UiService], null, null)], null, null); }
var MessageOptionsComponentNgFactory = i1.ɵccf("app-message-options", i3.MessageOptionsComponent, View_MessageOptionsComponent_Host_0, { message: "message", isAdmin: "isAdmin" }, { recallNotfiy: "recallNotfiy", setTemplateNotfiy: "setTemplateNotfiy" }, []);
export { MessageOptionsComponentNgFactory as MessageOptionsComponentNgFactory };
