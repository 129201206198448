import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy
} from '@angular/core';

import { UiService } from 'src/app/core/ui.service';
import { AuthService } from 'src/app/core/auth.service';
import { LayoutService } from './layout.service';
import { DashboardService } from '../+dashboard/dashboard.service';
import { AppMgmtService } from '../+dashboard/app-mgmt/appMgmt.service';

import { UISections, ChannelAppScreens } from 'src/models/constants';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { EventsService } from 'src/app/+merchant/+dashboard/events/events.service';
import { CalendarsService } from './../+dashboard/calendars/calendars.service';
import { Router } from '@angular/router';
import { AppMgmtComponent } from '../+dashboard/app-mgmt/app-mgmt.component';
import { RootStoreSelectors } from './../../store/root-store.selectors';
import { AppService } from 'src/app/app.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { PaymentsService } from '../+dashboard/payments/payments.service';
import { Observable } from 'rxjs';
import { AppleKeyCodeUI } from 'src/models/AppPublishInfo';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageService } from 'src/app/core/storage.service';
import { MembersService } from '../+dashboard/members/members.service';
import { BillingsService } from '../+dashboard/billings/billings.service';
import { MobileWorkFlowStoreService } from '../+dashboard/mobile-workflow-store/mobile-workflow-store.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: any;
  @Input() role: any;
  @Input() currentPage: any;
  @Input() appInfo: AppInfo;

  uiSection = UISections;
  appScreens = ChannelAppScreens;
  open: boolean;
  hidePopUp: boolean;
  private confirmInfo = null;
  private sideMenuStatus = null;
  authSubscribtion = null;
  toggleButtonMenu = false;
  startOverFirstValidation = false;
  appMgmt = new AppMgmtComponent(
    this.layoutService,
    this._ref,
    this._selector,
    this.appManagementService,
    this.authService,
    this._route,
    this.uiService,
    this.appServer,
    this._dashboardService,
    this._uiDispatchers,
    this.paymentService,
    this.eventService,
    this.calendarService,
    this.subscriptionService,
    this._route,
    this.mobileWorkFlowStoreService
  );
  constructor(
    public _dashboardService: DashboardService,
    public uiService: UiService,
    public layoutService: LayoutService,
    public authService: AuthService,
    public appManagementService: AppMgmtService,
    private _ref: ChangeDetectorRef,
    public _uiDispatchers: UIDispatchers,
    public eventService: EventsService,
    public calendarService: CalendarsService,
    private _route: Router,
    public _selector: RootStoreSelectors,
    public appServer: AppService,
    public subscriptionService: SubscriptionService,
    public membersService: MembersService,
    public billingsService: BillingsService,
    public paymentService: PaymentsService,
    private localStorage: StorageService,
    public mobileWorkFlowStoreService: MobileWorkFlowStoreService
  ) {}
  ngOnChanges() {}
  ngOnInit() {
    // setTimeout(() => {
    //   this.authSubscribtion = this.authService.authCollection$.subscribe(
    //     res => {
    //       if (
    //         res.is_nandbox &&
    //         res.main_group_id !== res.chatId &&
    //         this.role == 2
    //       ) {
    //         this._uiDispatchers.setSection(UISections.CHANNEL);
    //       } else if (!res.is_nandbox && res.main_group_id !== res.chatId) {
    //         this._uiDispatchers.setSection(UISections.HOME);
    //       } else if (
    //         res.appConfig ||
    //         (res.mode == 0 && res.appConfig == 0) ||
    //         (!res.is_nandbox &&
    //           res.main_group_id === res.chatId &&
    //           res.role == 1)
    //       ) {
    //         this._uiDispatchers.setSection(UISections.APP);
    //       } else {
    //         this._uiDispatchers.setSection(UISections.HOME);
    //       }
    //     }
    //   );
    // }, 1500);
    if (this.localStorage.getRecord('sideMenu') === 'open') {
      this.layoutService.setSideMenuStatus(1);
    } else if (this.localStorage.getRecord('sideMenu') === 'close') {
      this.layoutService.setSideMenuStatus(0);
    } else {
      this.sideMenuStatus = this.layoutService
        .getSideNavStatus()
        .subscribe(res =>
          res.matches
            ? this.layoutService.setSideMenuStatus(1)
            : this.layoutService.setSideMenuStatus(0)
        );
    }
    this.confirmInfo = this.appManagementService.appInfo$.subscribe(res => {
      if (res) {
        if (res.hide_module_added_msg === 1) {
          this.hidePopUp = false;
        } else if (res.hide_module_added_msg === 0) {
          this.hidePopUp = true;
        } else if (res) {
          this.hidePopUp = true;
        }
      }
    });
  }

  sectionEvent(direction: string) {
    this.paginationEvent('rest');
    this.layoutService.getDocumentationLinkOfSection(
      direction,
      this.currentPage,
      this.appInfo ? this.appInfo.layout : null
    );
    this.eventService.closeViewEvent();
    this.calendarService.closeViewCalendar();
    this._dashboardService.setSection(direction);
  }

  searchEvent(searchTerm: string) {
    this.paginationEvent('rest');
    this._dashboardService.setSearchKeyword(searchTerm);
  }

  paginationEvent(value: string) {
    this._dashboardService.setPageNumber(value);
  }

  logoutEvent($event) {
    if ($event) {
      this._dashboardService.logout();
    }
  }

  talkToAdminEvent(chatId: string) {
    this.uiService.requestHistory(chatId);
    this.uiService.selectTalkToAdmin();
  }

  toggleHelpWindowNotify(status: boolean) {
    this.uiService.toggleHelpWindow(status);
  }

  closeAndOpenSideMenu() {
    if (this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
    this._ref.detectChanges();
  }

  setSideNavStatus(status) {
    if (status) {
      this.layoutService.setSideMenuStatus(0);
      this.appManagementService._workFlowScreens.next(false);
    } else {
      this.layoutService.setSideMenuStatus(1);
      this.appManagementService._workFlowScreens.next(true);
    }
  }

  togglePopUp() {
    this.hidePopUp = false;
  }

  close() {
    this.layoutService.setSideMenuStatus(1);
  }

  showMenuButton(state: boolean) {
    this.toggleButtonMenu = state;
  }

  ngOnDestroy() {
    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
    if (this.confirmInfo) {
      this.confirmInfo.unsubscribe();
    }
    if (this.sideMenuStatus) {
      this.sideMenuStatus.unsubscribe();
    }
  }

  startOver() {
    this.startOverFirstValidation = true;
    this._ref.detectChanges();
  }
  showConfirmation() {
    this.startOverFirstValidation = false;
    this._ref.detectChanges();
  }
  secConfirmation() {
    this._route.navigate(['/signup'], { queryParamsHandling: 'merge' });
  }

  get appleKeyFormStatus$(): Observable<AppleKeyCodeUI> {
    return this.appManagementService.showAppleKeyFormObservable$.pipe(
      distinctUntilChanged()
    );
  }

  setAppleKeyCode(code: string, release_id: number) {
    this.appManagementService.sendAppleKeyCode(code, release_id);
    //   this.changeDetector.detectChanges();
  }

  hideAppleKeyForm() {
    this.appManagementService.hideAppleKeyForm();
  }
}
