import { MyProfileMiddleware } from './myProfile.middleware';
import * as MyProfileActions from './myProfile.actions';

import { IMyProfile } from '../../../models/IMyProfile';

export function myProfileReducer(
  state: IMyProfile[] = [],
  action: MyProfileActions.MyProfileActions
): IMyProfile[] {
  switch (action.type) {
    case MyProfileActions.MyProfileActionTypes.MY_PROFILE_RECEIVED:
      if (MyProfileMiddleware.getProfileByRelation(action.payload, state)) {
        return state.map(profile => {
          if (profile.relation === action.payload.relation) {
            let updatedProfile: IMyProfile = {};
            updatedProfile = { ...profile, ...action.payload };
            if (profile.backgroundColor) {
              updatedProfile.backgroundColor = profile.backgroundColor;
            }
            return updatedProfile;
          }
          return profile;
        });
      } else {
        return [action.payload, ...state];
      }
    case MyProfileActions.MyProfileActionTypes.MY_THUMBNAIL_DOWNLOADED:
      return state.map(profile => {
        if (profile.relation === action.payload.relation) {
          const updatedProfile: IMyProfile = {};
          updatedProfile.imageThumbnail = action.payload.imageThumbnail;
          return { ...profile, ...updatedProfile };
        }
        return profile;
      });
    case MyProfileActions.MyProfileActionTypes.MY_IMAGE_DOWNLOADED:
      return state.map(profile => {
        if (profile.relation === action.payload.relation) {
          const updatedProfile: IMyProfile = {};
          updatedProfile.image = action.payload.image;
          return { ...profile, ...updatedProfile };
        }
        return profile;
      });
    default:
      return state;
  }
}
