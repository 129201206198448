import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as driverAssigned from 'src/models/DrverAssigned';
export class OtherTripsEffects {
    constructor(actions$, _store, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        // send update to the server after insert in store
        this.getOtherTrips = this.actions$.pipe(ofType("GET_OTHER_TRIPS" /* GET_OTHER_TRIPS */), map((action) => {
            this._socketGateway.sendSocketMessage(new driverAssigned.GetOtherTrips(action.trip_id, action.account_id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], OtherTripsEffects.prototype, "getOtherTrips", void 0);
