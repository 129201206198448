import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';

import { MediaCenterService } from '../media-center.service';
import { MessageTypes } from 'src/models/constants';
import { IMediaCenter } from 'src/models/IMediaCenter';
import { MEDIA_CENTER } from 'src/models/AppSections';
import { NgxMasonryComponent } from 'ngx-masonry';

@Component({
  selector: 'app-media-center-uploader-list',
  templateUrl: './media-center-uploader-list.component.html',
  styleUrls: ['./media-center-uploader-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterUploaderListComponent {
  @Input() selectedMediaType: string;
  @Input() aspectRatio: string;
  @Output() mediaCenterNotify = new EventEmitter<IMediaCenter>();
  messageTypes: typeof MessageTypes = MessageTypes;
  mediaCenterFet = MEDIA_CENTER;

  constructor(public mediaCenterService: MediaCenterService) {}

  mediaCenterEvent(item: IMediaCenter) {
    this.mediaCenterNotify.emit(item);
  }
  mediaCenterDelete(item: IMediaCenter) {
    this.mediaCenterService.deleteMediaCenterItem(item);
  }
  TrackByFunction(index, item: IMediaCenter) {
    return item.file;
  }

  setMasonry(masonry: any) {
    setTimeout(() => {
      masonry.reloadItems();
      masonry.layout();
    }, 1000);
  }
}
