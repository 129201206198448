import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  DoCheck
} from '@angular/core';

import { InstantArticle } from 'src/models/InstantArticle';
import { InstantArticleService } from '../instant-article.service';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { IUploadResponse } from 'src/models/IUploadResponse';

@Component({
  selector: 'app-instant-article-editor',
  templateUrl: './instant-article-editor.component.html',
  styleUrls: ['./instant-article-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstantArticleEditorComponent implements DoCheck {
  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;

  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() publishNotfiy = new EventEmitter<number>();

  uploading = false;
  canSend = true;
  // Content
  textVar = '';

  content = {
    textVar: '',
    titleVar: '',
    authorVar: ''
  };

  // options
  placeholderVar = 'Write your article …';
  editorOptions = {
    toolbar: {
      /* These are the default options for the toolbar,
         if nothing is passed this is what is used */
      allowMultiParagraphSelection: true,
      buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote'],
      diffLeft: 0,
      diffTop: -10,
      firstButtonClass: 'medium-editor-button-first',
      lastButtonClass: 'medium-editor-button-last',
      relativeContainer: null,
      standardizeSelectionStart: false,
      static: false,
      /* options which only apply when static is true */
      align: 'center',
      sticky: false,
      updateOnEmptySelection: false
    }
  };

  constructor(
    private _instantArticleService: InstantArticleService,
    private _uploadGateway: UploadGateway,
    private _fileService: FileService,
    private _ref: ChangeDetectorRef
  ) {}
  ngDoCheck() {
    if (this.content.authorVar && this.content.titleVar && this.textVar) {
      this.canSend = false;
    } else {
      this.canSend = true;
    }
  }

  saveDraft() {
    const iv: InstantArticle = {
      author: this.content.authorVar,
      title: this.content.titleVar,
      content: this.textVar,
      isInstant: true, // need this flag to distinguish between  send url and instant
      reference: new Date().getTime()
    };
    this.publishNotfiy.emit(iv.reference);
    this._instantArticleService.setNewArticle(iv);
  }

  getFile() {
    if (this.imageInputFile.nativeElement.files[0]) {
      const file = this.imageInputFile.nativeElement.files[0];
      const localInputFile = {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };

      this._fileService
        .readFileAsArrayBuffer(localInputFile.localFile)
        .then(fileAsArrayBuffer => {
          this._fileService
            .readArrayBufferAsBlobUrl(fileAsArrayBuffer, localInputFile.type)
            .then(blobUrl => {
              const localMedia = blobUrl;
              const tempImageTag = `<img src="${localMedia}" alt="" style="border-radius: 6px;height: 190px;width: 100%;"/>`;
              // const tempImageTag = `<div [ngStyle]></div>`;
              this.textVar += tempImageTag;
              this.canSend = true;
              this.uploading = true;
              this._ref.detectChanges();
              this._uploadGateway
                .uploadWithProgress(
                  fileAsArrayBuffer,
                  localInputFile.localFile.type,
                  localInputFile.localFile.name,
                  false,
                  null,
                  null,
                  null,
                  true,
                  null,
                  null,
                  null,
                  true
                )
                .subscribe(
                  event => {
                    if (event.type === HttpEventType.Response) {
                      const res = <IUploadResponse>event.body;
                      // replace tempImageTag with FinalImageTag
                      this.textVar = this.textVar.replace(localMedia, res.url);
                      this.canSend = false;
                      this.uploading = false;
                      this._ref.detectChanges();
                    }
                  },
                  error => {
                    this.canSend = false;
                    this.uploading = false;
                  }
                );
            });
        });
    }
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setTitle(n: string) {
    this.content = { ...this.content, titleVar: n };
  }

  setAuthor(n: string) {
    this.content = { ...this.content, authorVar: n };
  }
}
