import { Action } from '@ngrx/store';
import { ProductStore } from 'src/models/productStore';

export const enum ProductsStoreUIActionTypes {
  UI_PRODUCTS_STORE_RESET_PAGING = 'UI_PRODUCTS_STORE_RESET_PAGING',
  UI_PRODUCTS_STORE_GET_NEXT_PAGE = 'UI_PRODUCTS_STORE_GET_NEXT_PAGE',
  UI_PRODUCTS_STORE_GET_PREV_PAGE = 'UI_PRODUCTS_STORE_GET_PREV_PAGE',
  UI_PRODUCTS_STORE_RECEIVED_HISTORY_CHUNK = 'UI_PRODUCTS_STORE_RECEIVED_HISTORY_CHUNK',
  UI_SET_PRODUCTS_STORE = 'UI_SET_PRODUCTS_STORE',
  UI_SET_SELECETED_PRODUCTS_STORE = 'UI_SET_SELECETED_PRODUCTS_STORE',
  UI_SET_PRODUCTS_ITEM_ARRAY = 'UI_SET_PRODUCTS_ITEM_ARRAY',
  UI_RECEIVE_PRODUCT_STORE_ITEM = 'UI_RECEIVE_PRODUCT_STORE_ITEM',
  UI_GET_PRODUCT_ITEM = 'UI_GET_PRODUCT_ITEM',
  UI_REMOVE_PRODUCT_ITEM = 'UI_REMOVE_PRODUCT_ITEM',
  UI_REMOVE_PRODUCT_ITEM_SUCCESS = 'UI_REMOVE_PRODUCT_ITEM_SUCCESS',
  UI_PRODUCTS_SET_IDLE_STATUS = 'UI_PRODUCTS_SET_IDLE_STATUS',
  UI_SET_COLLECTIONS_TO_PRODUCT = 'UI_SET_COLLECTIONS_TO_PRODUCT',
  UI_SET_PRODUCT_ITEM_ARRAY_SUCCESS = 'UI_SET_PRODUCT_ITEM_ARRAY_SUCCESS'
}

export class ResetProductsStoreUIPaging implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_RESET_PAGING;
  constructor() { }
}

export class GetNextProductsStorePage implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_NEXT_PAGE;
  constructor() { }
}
export class GetPrevProductsStorePage implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_PREV_PAGE;
  constructor() { }
}

export class ReceivedProductsStoreHistoryChunk implements Action {
  readonly type =
    ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: ProductStore[]
  ) { }
}
export class SetProductStoreItem implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_SET_PRODUCTS_STORE;
  constructor(public product: ProductStore) { }
}
export class SetSelectedProductStore implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_SET_SELECETED_PRODUCTS_STORE;
  constructor(public product: ProductStore) { }
}
export class SetProductsItemArray implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_SET_PRODUCTS_ITEM_ARRAY;
  constructor(public product: ProductStore[], public parent_id?: number, public typeOf?: number) { }
}
export class ReceivedProductStore implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_RECEIVE_PRODUCT_STORE_ITEM;
  constructor(public product: ProductStore) { }
}
export class GetProductItem implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_GET_PRODUCT_ITEM;
  constructor(public id: number) { }
}

export class RemoveProductItem implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_REMOVE_PRODUCT_ITEM;
  constructor(public id: number) { }
}
export class RemoveProductItemSuccess implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_REMOVE_PRODUCT_ITEM_SUCCESS;
  constructor(public id: number) { }
}
export class SetProductItemArraySuccess implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_SET_PRODUCT_ITEM_ARRAY_SUCCESS;
  constructor(public id: number, public product: ProductStore[]) { }
}

export class SetStatusAsIdle implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_PRODUCTS_SET_IDLE_STATUS;
  constructor() { }
}

export class SetCollectionsToProduct implements Action {
  readonly type = ProductsStoreUIActionTypes.UI_SET_COLLECTIONS_TO_PRODUCT;
  constructor(public productID: number, public collections: number[]) { }
}

export type Actions =
  | ResetProductsStoreUIPaging
  | GetNextProductsStorePage
  | GetPrevProductsStorePage
  | ReceivedProductsStoreHistoryChunk
  | SetSelectedProductStore
  | ReceivedProductStore
  | RemoveProductItemSuccess
  | SetProductItemArraySuccess
  | SetStatusAsIdle;
