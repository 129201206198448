import { Injectable } from '@angular/core';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { MenuUIDispatchers } from 'src/app/store/menusUI/menuUI.dispatchers';

import { BotMenu } from 'src/models/IBotMenu';
import { UISections } from 'src/models/constants';

import * as uuid from 'uuid/v4';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { MobileWorkFlowStoreDispatchers } from 'src/app/store/mobileWorkflowStore/mobileWorkflowStore.dispatchers';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class MobileWorkFlowStoreService {
  public _selectedMenu = new BehaviorSubject<any>(undefined);
  public selectedMenu$ = this._selectedMenu.asObservable();
  public _selectedMenuList = new BehaviorSubject<any>(undefined);
  public selectedMenuList$ = this._selectedMenuList.asObservable();
  public _mainMenuId = new BehaviorSubject<any>(undefined);
  public mainMenuId$ = this._mainMenuId.asObservable();
  public _selectedStoreId = new BehaviorSubject<any>(undefined);
  public selectedStoreId$ = this._selectedStoreId.asObservable();
  public _isDefault = new BehaviorSubject<number>(0);
  public isDefault$ = this._isDefault.asObservable();
  public _waiting = new BehaviorSubject<any>(false);
  public waiting$ = this._waiting.asObservable();

  public _storeData = new BehaviorSubject<any>(undefined);
  public storeData$ = this._storeData.asObservable();

  public _mobileStoreAppList = new BehaviorSubject<any[]>(undefined);
  public mobileStoreAppList$ = this._mobileStoreAppList.asObservable();

  public _selectedMenuImage = new BehaviorSubject<any>(undefined);
  public selectedMenuImage$ = this._selectedMenuImage.asObservable();

  public _selectedMenuName = new BehaviorSubject<any>(undefined);
  public selectedMenuName$ = this._selectedMenuName.asObservable();

  public _selectedMenuDesc = new BehaviorSubject<any>(undefined);
  public selectedMenuDesc$ = this._selectedMenuDesc.asObservable();

  constructor(
    private _rootStore: RootStoreSelectors,
    private _uiDispatchers: UIDispatchers,
    private _mobileWorkFlowStoreDispatchers: MobileWorkFlowStoreDispatchers
  ) {}

  selectMenu(menu: BotMenu) {
    this._uiDispatchers.botMenuSelected(menu);
  }

  setBotMenuEdit(menu: BotMenu) {
    this._uiDispatchers.botMenuSelected(menu);
  }

  getMenuWithRefId(refId: string) {
    return this._rootStore.getBotMenuWRefId$(refId);
  }

  setMenuResponse(menu: BotMenu, next_menu: string): BotMenu {
    const rows = menu.rows.map(row => {
      return {
        ...row,
        buttons: row.buttons.map(btn => {
          return { ...btn, next_menu };
        })
      };
    });
    const menuRows = {
      ...menu,
      rows
    };

    return menuRows;
  }

  backToMenus() {
    this._uiDispatchers.setSection(UISections.MENUS);
  }
  goToApp() {
    this._uiDispatchers.setSection(UISections.APP);
  }
  getNextPage(): void {
    this._mobileWorkFlowStoreDispatchers.getNextMenuPage();
  }

  getPrevPage(): void {
    this._mobileWorkFlowStoreDispatchers.getPrevMenuPage();
  }

  resetPaging(): void {
    this._mobileWorkFlowStoreDispatchers.resetMenuPaging();
  }

  /** Selectors */
  get botMenus$() {
    return this._rootStore.botMenus$;
  }

  getListofMenus() {
    this._mobileWorkFlowStoreDispatchers.listMenus();
  }

  uploadMenuImgLogo(componentRef: any, selectedFile: ISelectedFiles) {
    this._mobileWorkFlowStoreDispatchers.UploadMenuImg(
      componentRef,
      selectedFile
    );
  }

  setStore(
    menu_id: string,
    currency?: string,
    shop?: any,

    image?: any,
    name?: string,
    description?: string,
    is_default?: number,
    id?: string
  ) {
    this._mobileWorkFlowStoreDispatchers.createMenu(
      menu_id,
      currency,
      shop,
      image,
      name,
      description,
      is_default,
      id
    );
  }
  get getStoreList$() {
    return this._rootStore.getMobileStoreShops$();
  }

  getMobileStoreById(id?: any) {
    this._mobileWorkFlowStoreDispatchers.getMobileStore(id);
  }

  deleteStore(id) {
    this._mobileWorkFlowStoreDispatchers.deleteMenu(id);
  }

  stores$() {
    this._rootStore.getStores$();
  }
}
