import { Injectable } from '@angular/core';
import { BlackListPatternsUiDispatchers } from 'src/app/store/balckListPatternsUI/blackListPatternsUI.dispatchers';
import { BlackListUiDispatchers } from 'src/app/store/balckListUI/blackListUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { BlackList } from 'src/models/BlackListsUI';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {
  constructor(
    private _blackListDispatchers: BlackListUiDispatchers,
    private _blacklistPatternsDispatchers: BlackListPatternsUiDispatchers,
    private _selectors: RootStoreSelectors
  ) {}

  resetPaging(name: string) {
    this._blackListDispatchers.resetBlacklistPaging(name);
  }

  getNextPage() {
    this._blackListDispatchers.getNextBlacklistsPage();
  }

  getPrevPage() {
    this._blackListDispatchers.getPrevBlacklistsPage();
  }

  addUserToBlacklist(users: string[], mainChatID: string) {
    this._blackListDispatchers.addUserBlacklist(users, mainChatID);
  }

  resetObject() {
    this._blackListDispatchers.resetObject();
  }

  get blackListsUi$() {
    return this._selectors.blackListsUi$;
  }

  removeUserFromBlacklist(user, mainChatID: string) {
    this._blackListDispatchers.removeUserFromBlacklist(user, mainChatID);
  }

  // Blacklist Patterns
  resetBlacklistPatternsPaging() {
    this._blacklistPatternsDispatchers.resetBlacklistPatternsPaging();
  }

  getNextBlacklistPatternsPage() {
    this._blacklistPatternsDispatchers.getNextBlacklistPatternsPage();
  }

  getPrevBlacklistPatternsPage() {
    this._blacklistPatternsDispatchers.getPrevBlacklistPatternsPage();
  }

  addPatternToBlacklist(data: BlackList[], mainChatID: string) {
    this._blacklistPatternsDispatchers.addPatternToBlacklist(data, mainChatID);
  }

  get blackListPatternsUi$() {
    return this._selectors.blackListsPatternsUi$;
  }

  resetObjectBlacklistPatterns() {
    this._blacklistPatternsDispatchers.resetObject();
  }

  deletePatternsFromBlacklist(data: string[], mainChatID: string) {
    this._blacklistPatternsDispatchers.deletePatternFromBlacklist(
      data,
      mainChatID
    );
  }

  setLoadingScreenStatus(status: string) {
    this._blackListDispatchers.setLoadingScreenStatus(status);
  }
}
