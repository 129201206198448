import { Store } from '@ngrx/store';
import * as ServiceOrdersUIActions from './serviceOrdersUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ServiceOrdersUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    // paging dispatchers
    getNextServiceOrdersPage(paymentOrderID, serviceOrderID) {
        this._store.dispatch(new ServiceOrdersUIActions.GetNextServiceOrdersPage(paymentOrderID, serviceOrderID));
    }
    getPrevServiceOrdersPage(paymentOrderID, serviceOrderID) {
        this._store.dispatch(new ServiceOrdersUIActions.GetPrevServiceOrdersPage(paymentOrderID, serviceOrderID));
    }
    receivedServiceOrdersHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new ServiceOrdersUIActions.ReceivedServiceOrdersHistoryChunk(eop, sop, currentPage));
    }
    resetServiceOrdersPaging(paymentOrderID, serviceOrderID) {
        this._store.dispatch(new ServiceOrdersUIActions.ResetServiceOrdersPaging(paymentOrderID, serviceOrderID));
    }
    setStatusAsIdle() {
        this._store.dispatch(new ServiceOrdersUIActions.SetStatusAsIdle());
    }
}
ServiceOrdersUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceOrdersUiDispatchers_Factory() { return new ServiceOrdersUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: ServiceOrdersUiDispatchers, providedIn: "root" });
