import { Action } from '@ngrx/store';
import { KeysConfig } from './../../../models/constants';

export const enum AdsProvidersActionTypes {
  RECEIVE_ADS_PROVIDERS = 'RECEIVE_ADS_PROVIDERS',
  RECEIVE_ADS_PROVIDER = 'RECEIVE_ADS_PROVIDER',
  SET_ADS_PROVIDER = 'SET_ADS_PROVIDER',
  GET_ADS_PROVIDERS = 'GET_ADS_PROVIDERS',
  RESET = 'RESET'
}

export class ReceiveAdsProvider implements Action {
  readonly type = AdsProvidersActionTypes.RECEIVE_ADS_PROVIDER;
  constructor(public adsProviderId: string, public config: KeysConfig) {}
}

export class SetAdsProvider implements Action {
  readonly type = AdsProvidersActionTypes.SET_ADS_PROVIDER;
  constructor(public adsProviderId: string, public config: KeysConfig) {}
}

export class GetAdsProviders implements Action {
  readonly type = AdsProvidersActionTypes.GET_ADS_PROVIDERS;
  constructor(public adsProviderId: string) {}
}

export class Reset implements Action {
  readonly type = AdsProvidersActionTypes.RESET;
}

export type Actions =
  | ReceiveAdsProvider
  | SetAdsProvider
  | GetAdsProviders
  | Reset;
