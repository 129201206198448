import { Store } from '@ngrx/store';
import * as BookingPeriodsActions from './bookingPeriods.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BookingPeriodsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveBookingPeriods(bookingPeriods) {
        this._store.dispatch(new BookingPeriodsActions.ReceiveBookingPeriods(bookingPeriods));
    }
    createBookingPeriods(bookingPeriods) {
        this._store.dispatch(new BookingPeriodsActions.CreateBookingPeriods(bookingPeriods));
    }
    updateBookingPeriods(bookingPeriods) {
        this._store.dispatch(new BookingPeriodsActions.UpdateBookingPeriods(bookingPeriods));
    }
    deleteBookingPeriods(chatId, data) {
        this._store.dispatch(new BookingPeriodsActions.DeleteBookingPeriods(chatId, data));
    }
}
BookingPeriodsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingPeriodsDispatchers_Factory() { return new BookingPeriodsDispatchers(i0.ɵɵinject(i1.Store)); }, token: BookingPeriodsDispatchers, providedIn: "root" });
