import { Actions } from './paymentOrdersUI.actions';
import * as actions from './paymentOrdersUI.actions';
import {
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection
} from '../../../models/constants';
import { PaymentOrdersUI } from 'src/models/PaymentOrdersUI';

const INITIAL_STATE: PaymentOrdersUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function paymentOrdersUiReducer(
  state = INITIAL_STATE,
  action: Actions
): PaymentOrdersUI {
  switch (action.type) {
    case actions.PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_NEXT_PAGE: {
      const newHash = action.paymentOrderID;
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_PREV_PAGE: {
      const newHash = action.paymentOrderID;
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.PaymentOrdersUIActionTypes
      .UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.PaymentOrdersUIActionTypes.PAYMENT_ORDERS_USERS_RECEIVED: {
      let paymentOrders = [...state.currentPage];
      if (paymentOrders && action.userNames) {
        paymentOrders = paymentOrders.map(order => {
          const updatedOrder = { ...order };
          updatedOrder.receiver_name = action.userNames[order.receiver_id];
          updatedOrder.account_name = action.userNames[order.account_id];
          return updatedOrder;
        });
      }
      return {
        ...state,
        currentPage: paymentOrders
      };
    }
    case actions.PaymentOrdersUIActionTypes.PAYMENT_ORDERS_GROUPS_RECEIVED: {
      let paymentOrders = [...state.currentPage];
      if (paymentOrders && action.groupsNames) {
        paymentOrders = paymentOrders.map(order => {
          const updatedOrder = { ...order };
          updatedOrder.group_name = action.groupsNames[order.group_id];
          return updatedOrder;
        });
      }
      return {
        ...state,
        currentPage: paymentOrders
      };
    }
    case actions.PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.PaymentOrdersUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
