import {
  GET_CAMPAIGNS_METHOD,
  GET_CAMPAIGN_METHOD,
  CREATE_CAMPAIGN_METHOD,
  UPDATE_CAMPAIGN_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_CAMPAIGN_REF,
  UPDATE_CAMPAIGN_REF
} from './constants';
import { ICoupon } from './ICoupon';

export enum AccumulatorTypes {
  EVENT = 0,
  WINNER_PER_CAMPAIGN = 2,
  WINNER_PER_USER = 1
}

export enum AccumlatorActions {
  DELETE = 1
}

export interface ICampaign {
  // for local use
  isSelected?: boolean;
  isActive?: boolean;
  oldScope?: string;
  oldStatus?: string;

  // from the server
  reference?: number;
  campaignId?: string;
  groupId?: string; // groupId of selected chat
  name?: string;
  timezone?: string;
  startDate?: Date;
  endDate?: Date;
  scope?: string;
  couponId?: string;
  domain?: string;
  days?: string[];
  status?: string;
  startHour?: number;
  endHour?: number;
  numWinners?: number;
  maxWinners?: number;
  odds?: number;
  poolSize?: number;
  joinPeriod?: number;
  triggers?: number[];
  published?: number;
  tab?: string;
  qrCode?: string;

  // Accumulator object
  accumulatorIdEvent?: string;
  accumulatorIdUser?: string;
  accumulatorIdCampaign?: string;
  periodEvent?: string;
  targetEvent?: number;
  periodUser?: string;
  targetUser?: number;
  periodCampaign?: string;
  targetCampaign?: number;

  // Award Object
  awardType?: number;
  awardPoints?: number;
  senderId?: string;
  messageId?: string;
}

// Campaign message
export interface CampaignMessage {
  method?: string;
  reference?: number;
  campaign?: Campaign;
}
export interface Campaign {
  campaignId?: string;
  groupId?: string;
  name?: string;
  timezone?: string;
  startDate?: string;
  endDate?: string;
  scope?: string;
  couponId?: string;
  domain?: string;
  days?: string;
  status?: string;
  startHour?: number;
  endHour?: number;
  numWinners?: number;
  maxWinners?: number;
  odds?: number;
  poolSize?: number;
  joinPeriod?: number;
  triggers?: number[];
  published?: number;
  qrCode?: string;
  award?: Award;
  accumulators?: Accumulator[];
}
export interface Award {
  type?: number;
  points?: number;
  couponId?: string;
  senderId?: string;
  messageId?: string;
  tab?: string;
}
export interface Accumulator {
  name?: string;
  period?: string;
  target?: number;
  type?: number;
  accumulatorId?: string;
  increment?: number;
  action?: number;
}

export class GetCampaigns {
  readonly method = GET_CAMPAIGNS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public hash?: string,
    public reference?: number
  ) {}
}

export class GetCampaign {
  readonly method = GET_CAMPAIGN_METHOD;
  constructor(public campaignId: string) {}
}

export class CreateCampaign {
  readonly method = CREATE_CAMPAIGN_METHOD;
  readonly reference = CREATE_CAMPAIGN_REF;
  constructor(campaign: CampaignMessage) {
    for (const key in campaign) {
      if (campaign.hasOwnProperty(key)) {
        this[key] = campaign[key];
      }
    }
  }
}

export class UpdateCampaign {
  readonly method = UPDATE_CAMPAIGN_METHOD;
  readonly reference = UPDATE_CAMPAIGN_REF;
  constructor(campaign: CampaignMessage) {
    for (const key in campaign) {
      if (campaign.hasOwnProperty(key)) {
        this[key] = campaign[key];
      }
    }
  }
}

export type CampaignMethods =
  | GetCampaigns
  | GetCampaign
  | CreateCampaign
  | UpdateCampaign;
