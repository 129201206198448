import * as MainChatMethods from 'src/models/MainChat';
import * as ChatMethods from 'src/models/IChat';

import { IChat } from 'src/models/IChat';
import { THEME_COLORS } from 'src/models/constants';
import { ReplyMessage } from 'src/models/ReplyMessage';
import { IChatMember, ChatMember } from 'src/models/IChatMember';

export class ChatMiddleware {
  public static initChat(chat: IChat): IChat {
    if (chat.image) {
      chat.localThumbnail = `data:image/jpeg;base64,${chat.image}`;
    } else {
      chat.localThumbnail = null;
    }
    if (chat.photo && chat.photo.permanentUrl) {
      chat.localMedia = chat.photo.permanentUrl;
    } else {
      chat.localMedia = null;
    }
    chat.backgroundColor =
      THEME_COLORS[Math.floor(Math.random() * THEME_COLORS.length)];
    return chat;
  }

  public static initChatMember(rcvdMember: ChatMember): IChatMember {
    const chatMember: IChatMember = {};
    if (rcvdMember.user) {
      chatMember.user_id = rcvdMember.user.id;
    }
    if (rcvdMember.chat) {
      chatMember.chat_id = rcvdMember.chat.id;
    }
    chatMember.status = rcvdMember.status;
    return chatMember;
  }

  public static isNewChat(chatID: string, state: IChat[]): boolean {
    if (this.isChatExist(chatID, state)) {
      return false;
    } else {
      return true;
    }
  }

  public static isChatExist(chatID: string, chatList: IChat[]) {
    const exitingChat: IChat = chatList.find(chat => chat.id === chatID);
    if (exitingChat) {
      return true;
    } else {
      return false;
    }
  }

  public static getMainChatFromChat(
    chat: ChatMethods.IChat
  ): MainChatMethods.MainChat {
    const mainChat: MainChatMethods.MainChat = {};
    mainChat.id = chat.id;
    mainChat.name = chat.title;
    mainChat.desc = chat.description;
    mainChat.image = chat.localMedia;
    mainChat.imageThumbnail = chat.localThumbnail;
    mainChat.status = chat.description;
    mainChat.priority = chat.favourite;
    mainChat.pinned_date = chat.pinned_date;
    mainChat.backgroundColor = chat.backgroundColor;
    mainChat.type = chat.type;
    mainChat.isAdmin = chat.admin;
    mainChat.memberCount = chat.member_count;
    mainChat.isPublic = chat.isPublic ? true : false;
    mainChat.detailsReceived = chat.detailsReceived;
    mainChat.subChannel = chat.subChannel;
    mainChat.isChannelApp = chat.isChannelApp;
    mainChat.isUpgradedChannel = chat.isUpgradedChannel;
    mainChat.lastTypedMessage = chat.lastTypedMessage;
    mainChat.inivite_link = chat.inivite_link;
    mainChat.isHistoryRequested = chat.isHistoryRequested;
    mainChat.category = chat.category;
    mainChat.createdDate = chat.createdDate;
    mainChat.onboard = chat.onboard;
    return mainChat;
  }

  public static getChatFromMainChat(
    mainChat: MainChatMethods.MainChat
  ): ChatMethods.IChat {
    const chat: ChatMethods.IChat = {};
    chat.id = mainChat.id;
    chat.title = mainChat.name;
    chat.description = mainChat.desc;
    chat.localMedia = mainChat.image;
    chat.localThumbnail = mainChat.imageThumbnail;
    chat.description = mainChat.status;
    chat.favourite = mainChat.priority;
    chat.pinned_date = mainChat.pinned_date;
    chat.backgroundColor = mainChat.backgroundColor;
    chat.type = mainChat.type;
    chat.admin = mainChat.isAdmin;
    chat.member_count = mainChat.memberCount;
    chat.isPublic = mainChat.isPublic ? 1 : 0;
    chat.detailsReceived = mainChat.detailsReceived;
    chat.subChannel = mainChat.subChannel;
    chat.isChannelApp = mainChat.isChannelApp;
    chat.isUpgradedChannel = mainChat.isUpgradedChannel;
    chat.lastTypedMessage = mainChat.lastTypedMessage;
    chat.inivite_link = mainChat.inivite_link;
    chat.isHistoryRequested = mainChat.isHistoryRequested;
    chat.category = mainChat.category;
    chat.createdDate = mainChat.createdDate;
    chat.onboard = mainChat.onboard;
    return chat;
  }

  public static initNewMainChat(
    lastMessage: ReplyMessage,
    mainChat: MainChatMethods.MainChat
  ): MainChatMethods.MainChat {
    mainChat.lastReplyID = lastMessage.lastReplyID;
    mainChat.lastReplyReference = lastMessage.lastReplyReference;
    mainChat.lastMsgTime = lastMessage.lastReplyTime;
    mainChat.lastReplyMessage = lastMessage.lastReplyMessage;
    mainChat.lastReplyStatus = lastMessage.lastReplyStatus;
    mainChat.lastReplyType = lastMessage.lastReplyType;
    mainChat.backgroundColor =
      THEME_COLORS[Math.floor(Math.random() * THEME_COLORS.length)];
    return mainChat;
  }

  public static addNewChat(state: IChat[], chat: IChat): IChat[] {
    return state.length > 0 ? [chat, ...state] : [chat];
  }

  public static handleDate(date: Date) {
    const startDay = date.getDate();
    const startMonthNum = date.getMonth() + 1;
    const startMonth: string =
      startMonthNum < 10 ? '0' + startMonthNum : '' + startMonthNum;
    const startYear = date.getFullYear();
    const startDateString = startYear + '-' + startMonth + '-' + startDay;
    return startDateString;
  }
}
