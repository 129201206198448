import { MapTrip, MapTripUI } from 'src/models/MapTrip';
import { Actions } from './mapTrip.actions';

import * as actions from './mapTrip.actions';
import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS,
  TripsScreens
} from 'src/models/constants';
const INITIAL_STATE: MapTripUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  currentMapTripScreen: TripsScreens.TRIPS_LIST
};

export function mapTripReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.MapTripActionTypes.RESET_MAP_TRIP_PAGING:
      return INITIAL_STATE;
    case actions.MapTripActionTypes.GET_NEXT_MAP_TRIP_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    }
    case actions.MapTripActionTypes.GET_PREV_MAP_TRIP_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV
      };
    }
    case actions.MapTripActionTypes.MAP_TRIP_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.mapTrips && action.mapTrips.length) > 0
            ? action.mapTrips
            : state.currentPage
      };
    }
    case actions.MapTripActionTypes.MAP_TRIP_DETAILS_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(mapTrip => {
          if (mapTrip.id === action.mapTrip.id) {
            return action.mapTrip;
          }
          return mapTrip;
        })
      };
    }
    case actions.MapTripActionTypes.MAP_TRIP_CREATED: {
      if (
        !state.currentPage.find(
          mapSetting => mapSetting.id === action.mapTrip.id
        )
      ) {
        return {
          ...state,
          currentPage: [action.mapTrip, ...state.currentPage]
        };
      } else {
        return state;
      }
    }
    case actions.MapTripActionTypes.UI_SET_MAP_TRIP_CURRENT_SCREEN: {
      return {
        ...state,
        currentMapTripScreen: action.screen
      };
    }
    case actions.MapTripActionTypes.MAP_TRIP_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          mapTrip => mapTrip.id !== action.id
        )
      };
    case actions.MapTripActionTypes.UI_MAP_TRIP_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.MapTripActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
