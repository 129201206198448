import { Store } from '@ngrx/store';
import * as CollectionsUIActions from './collectionsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CollectionsUIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetCollectionsPaging() {
        this._store.dispatch(new CollectionsUIActions.ResetCollectionsPaging());
    }
    getCollectionsNextPage() {
        this._store.dispatch(new CollectionsUIActions.GetCollectionsNextPage());
    }
    getCollectionsPrevPage() {
        this._store.dispatch(new CollectionsUIActions.GetCollectionsPrevPage());
    }
    receiveListCollectionsHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new CollectionsUIActions.ReceiveListCollectionsHistoryChunk(eop, sop, currentPage));
    }
    setCollectionItem(collection) {
        this._store.dispatch(new CollectionsUIActions.SetCollectionItem(collection));
    }
    getCollectionItem(id, isSelectedItem) {
        this._store.dispatch(new CollectionsUIActions.GetCollectionItem(id, isSelectedItem));
    }
    setCollectionProducts(collectionID, productsID) {
        this._store.dispatch(new CollectionsUIActions.SetCollectionProducts(collectionID, productsID));
    }
    collectionReceived(collection) {
        this._store.dispatch(new CollectionsUIActions.CollectionReceived(collection));
    }
    deleteCollectionItem(id) {
        this._store.dispatch(new CollectionsUIActions.DeleteCollectionItem(id));
    }
    deleteCollectionItemSuccess(id) {
        this._store.dispatch(new CollectionsUIActions.DeleteCollectionItemSuccess(id));
    }
    getCollectionProducts(collectionID) {
        this._store.dispatch(new CollectionsUIActions.GetCollectionProducts(collectionID));
    }
    receveCollectionProducts(productStore) {
        this._store.dispatch(new CollectionsUIActions.ReceveCollectionProducts(productStore));
    }
}
CollectionsUIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectionsUIDispatchers_Factory() { return new CollectionsUIDispatchers(i0.ɵɵinject(i1.Store)); }, token: CollectionsUIDispatchers, providedIn: "root" });
