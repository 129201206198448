import { Actions } from './calendarUI.actions';
import * as actions from './calendarUI.actions';

import { CalendarScreens, SCREEN_STATUS } from 'src/models/constants';
import { CalendarUI } from 'src/models/CalendarUI';
import { HoursRange, CalendarTimetable } from 'src/models/Calendar';

const INITIAL_STATE: CalendarUI = {
  selectedCalendar: null,
  creationProcessCalendar: null,
  currentCalendarScreen: CalendarScreens.CALENDAR_LIST,
  eop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE
};

const ascCompare = (first: HoursRange, second: HoursRange) => {
  const firstTime = first.start_time ? first.start_time : '0';
  const secondTime = second.start_time ? second.start_time : '0';
  const endTime1 = first.end_time ? first.end_time : '0';
  const endTime2 = second.end_time ? second.end_time : '0';

  if (firstTime < secondTime) {
    return -1;
  } else if (firstTime > secondTime) {
    return 1;
  } else if (endTime1 > endTime2) {
    return 1;
  } else {
    return -1;
  }
};

export function calendarUiReducer(
  state = INITIAL_STATE,
  action: Actions
): CalendarUI {
  switch (action.type) {
    case actions.CalendarUIActionTypes.CALENDAR_RECEIVED: {
      if (
        !state.currentPage.find(calendar => calendar.id == action.calendar.id)
      ) {
        return {
          ...state,
          currentPage: [...state.currentPage, action.calendar]
        };
      } else {
        return {
          ...state,
          currentPage: state.currentPage.map(calendar => {
            if (calendar.id == action.calendar.id) {
              return { ...calendar, ...action.calendar };
            }
            return calendar;
          })
        };
      }
    }
    case actions.CalendarUIActionTypes.CALENDAR_DETAILS_RECEIVED: {
      let rcvdData = action.data;
      if (action.data) {
        const data = action.data.map(day => {
          const sortedDay = day;
          sortedDay.hours = sortedDay.hours.sort(ascCompare);
          return sortedDay;
        });
        rcvdData = data;
      }
      if (
        !state.currentPage.find(calendar => calendar.id == action.calendar_id)
      ) {
        const cal: CalendarTimetable = {};
        cal.id = action.calendar_id;
        cal.data = rcvdData;
        return {
          ...state,
          currentPage: [cal, ...state.currentPage]
        };
      } else {
        return {
          ...state,
          currentPage: state.currentPage.map(calendar => {
            if (calendar.id == action.calendar_id) {
              const updatedCalendar: CalendarTimetable = { ...calendar };
              updatedCalendar.data = rcvdData;
              return updatedCalendar;
            }
            return calendar;
          })
        };
      }
    }
    case actions.CalendarUIActionTypes.UI_VIEW_CALENDAR: {
      return {
        ...state,
        selectedCalendar: action.selectedCalendar,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_VIEW
      };
    }
    case actions.CalendarUIActionTypes.UI_CLOSE_CALENDAR_VIEW: {
      return {
        ...state,
        selectedCalendar: null,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_LIST
      };
    }
    case actions.CalendarUIActionTypes.UI_EDIT_CALENDAR: {
      return {
        ...state,
        selectedCalendar: action.selectedCalendar,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_EDIT
      };
    }
    case actions.CalendarUIActionTypes.UI_CLOSE_EDIT_CALENDAR: {
      return {
        ...state,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_VIEW
      };
    }
    case actions.CalendarUIActionTypes.UI_CREATE_CALENDAR: {
      const res = {
        ...state,
        selectedCalendar: null,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_CREATION
      };
      return res;
    }
    case actions.CalendarUIActionTypes.UI_CALENDAR_LIST: {
      return {
        ...state,
        selectedCalendar: null,
        creationProcessCalendar: null,
        eop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        currentCalendarScreen: CalendarScreens.CALENDAR_LIST
      };
    }
    case actions.CalendarUIActionTypes.UI_SET_CALENDAR_IN_CREATION: {
      if (state.currentCalendarScreen === CalendarScreens.CALENDAR_EDIT) {
        return state;
      } else {
        return {
          ...state,
          creationProcessCalendar: action.createdCalendar,
          selectedCalendar: action.createdCalendar,
          currentCalendarScreen: CalendarScreens.CALENDAR_EDIT
        };
      }
    }
    case actions.CalendarUIActionTypes.UI_CALENDAR_CREATION_COMPLETE: {
      return {
        ...state,
        creationProcessCalendar: null,
        currentCalendarScreen: CalendarScreens.CALENDAR_LIST
      };
    }

    // calendars paging system
    case actions.CalendarUIActionTypes.UI_CALENDAR_GET_NEXT_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.CalendarUIActionTypes.UI_CALENDAR_GET_PREV_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.CalendarUIActionTypes.UI_CALENDAR_RECEIVED_HISTORY_CHUNK: {
      return {
        ...state,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.CalendarUIActionTypes.UI_CALENDAR_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.DESELECT_CHANNEL:
    case actions.CalendarUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
