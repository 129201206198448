import { Pipe, PipeTransform } from '@angular/core';

import { ClientMessageTypes } from 'src/models/constants';

@Pipe({
  name: 'localType'
})
export class LocalTypePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      return ClientMessageTypes[value] || '';
    }
  }
}
