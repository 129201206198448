import { Injectable } from '@angular/core';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { MenuUIDispatchers } from 'src/app/store/menusUI/menuUI.dispatchers';

import { BotMenu } from 'src/models/IBotMenu';
import {
  CREATE_MY_MENU_METHOD,
  IS_PRODUCTION,
  UISections
} from 'src/models/constants';

import * as uuid from 'uuid/v4';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UiService } from 'src/app/core/ui.service';

@Injectable({ providedIn: 'root' })
export class MenusService {
  isProduction = IS_PRODUCTION;
  constructor(
    private uiService: UiService,
    private _rootStore: RootStoreSelectors,
    private _uiDispatchers: UIDispatchers,
    private _menuUIDispatchers: MenuUIDispatchers
  ) {}

  selectMenu(menu: BotMenu) {
    this._uiDispatchers.botMenuSelected(menu);
  }

  setBotMenuEdit(menu: BotMenu) {
    this._uiDispatchers.botMenuSelected(menu);
  }

  getMenuWithRefId(refId: string) {
    return this._rootStore.getBotMenuWRefId$(refId);
  }

  setMenu(
    menu: BotMenu,
    responseMenuRef?: string,
    mainMenu = false,
    click = false
  ) {
    const newId = uuid();
    const newRef = new Date().getTime();

    if (!menu.menu_id && !menu.menu_ref) {
      menu.reference = newRef;
      menu.menu_date = newRef;
      menu.menu_id = newId;
      menu.menu_ref = newId;
    }

    if (mainMenu) {
      menu.type = 1;
    } else {
      menu.type = 0;
    }
    // if (responseMenuRef) {
    menu = this.setMenuResponse(menu, responseMenuRef);
    //  }

    if (menu.id) {
      this._menuUIDispatchers.updateMenu(menu);
      this.setTemplate(menu);
    } else {
      this._menuUIDispatchers.createMenu(menu);
    }

    if (mainMenu) {
      this.goToApp();
    } else if (!click) {
      this.backToMenus();
    }
  }

  setMenuResponse(menu: BotMenu, next_menu: string): BotMenu {
    const rows = menu.rows.map(row => {
      return {
        ...row,
        buttons: row.buttons.map(btn => {
          return { ...btn, next_menu };
        })
      };
    });
    const menuRows = {
      ...menu,
      rows
    };

    return menuRows;
  }

  backToMenus() {
    this._uiDispatchers.setSection(UISections.MENUS);
  }
  goToApp() {
    this._uiDispatchers.setSection(UISections.APP);
  }
  getNextPage(): void {
    this._menuUIDispatchers.getNextMenuPage();
  }

  getPrevPage(): void {
    this._menuUIDispatchers.getPrevMenuPage();
  }

  resetPaging(): void {
    this._menuUIDispatchers.resetMenuPaging();
  }

  /** Selectors */
  get botMenus$() {
    return this._rootStore.botMenus$;
  }

  getListofMenusByType(typ) {
    this._menuUIDispatchers.listMenus(typ);
  }

  uploadMenuImgLogo(componentRef: any, selectedFile: ISelectedFiles) {
    this._menuUIDispatchers.UploadMenuImg(componentRef, selectedFile);
  }

  setTemplate(menu) {
    const menuSend = menu;
    const id = menuSend.id.toString();
    delete menuSend['id'];
    const menutemp = {
      menu: menuSend,
      sub_menu: menuSend,
      name: menu.menu_name,
      reference: menu.menu_ref,
      next_menu: menu.next_menu,
      type: menu.type,
      method: CREATE_MY_MENU_METHOD
    };

    const messageType = 'poll';
    this.uiService.setTemplateMessage('', messageType, id, menutemp);
  }
}
