/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apple-id-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./input-code/input-code.component.ngfactory";
import * as i3 from "./input-code/input-code.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i6 from "../../../../../../abstract-btns/border-btn/border-btn.component";
import * as i7 from "./apple-id-popup.component";
var styles_AppleIdPopupComponent = [i0.styles];
var RenderType_AppleIdPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppleIdPopupComponent, data: {} });
export { RenderType_AppleIdPopupComponent as RenderType_AppleIdPopupComponent };
function View_AppleIdPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You should enter the whole 6-digit code before submit "]))], null, null); }
export function View_AppleIdPopupComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "popup-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Apple ID"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Two-Factor Authentication"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-input-code", [], null, [[null, "valuesEntered"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valuesEntered" === en)) {
        var pd_0 = (_co.submitCode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InputCodeComponent_0, i2.RenderType_InputCodeComponent)), i1.ɵdid(6, 114688, null, 0, i3.InputCodeComponent, [], null, { valuesEntered: "valuesEntered" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppleIdPopupComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A message with a verification code has been sent to your devices. Enter the code to continue. "])), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You must enter the code in "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["05:00"])), (_l()(), i1.ɵted(-1, null, [" Min "])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "form-submit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "app-border-btn", [["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.setCode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BorderBtnComponent_0, i5.RenderType_BorderBtnComponent)), i1.ɵdid(19, 573440, null, 0, i6.BorderBtnComponent, [], { focused: [0, "focused"] }, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Submit "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "app-border-btn", [["cancel", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BorderBtnComponent_0, i5.RenderType_BorderBtnComponent)), i1.ɵdid(22, 573440, null, 0, i6.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _co.hasErrors; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.submitFocused; _ck(_v, 19, 0, currVal_1); }, null); }
export function View_AppleIdPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apple-id-popup", [], null, null, null, View_AppleIdPopupComponent_0, RenderType_AppleIdPopupComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppleIdPopupComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppleIdPopupComponentNgFactory = i1.ɵccf("app-apple-id-popup", i7.AppleIdPopupComponent, View_AppleIdPopupComponent_Host_0, {}, { codeEntered: "codeEntered", closeNotify: "closeNotify" }, []);
export { AppleIdPopupComponentNgFactory as AppleIdPopupComponentNgFactory };
