import { Action } from '@ngrx/store';

import { ICampaign, CampaignMessage } from 'src/models/ICampaign';

export const enum CampaignActionTypes {
  CAMPAIGN_RECEIVED = 'CAMPAIGN_RECEIVED',
  REQUEST_SCOPE_MESSAGE = 'REQUEST_SCOPE_MESSAGE',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class CampaignReceived implements Action {
  readonly type = CampaignActionTypes.CAMPAIGN_RECEIVED;
  constructor(public payload: CampaignMessage) {}
}
export class RequestScopeMessage implements Action {
  readonly type = CampaignActionTypes.REQUEST_SCOPE_MESSAGE;
  constructor(public payload: string) {}
}

export class CreateCampaign implements Action {
  readonly type = CampaignActionTypes.CREATE_CAMPAIGN;
  constructor(public payload: ICampaign) {}
}

export class UpdateCampaign implements Action {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN;
  constructor(public payload: ICampaign) {}
}

// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type CampaignActions =
  | CampaignReceived
  | RequestScopeMessage
  | CreateCampaign
  | UpdateCampaign
  | DeselectChannel
  | Reset;
