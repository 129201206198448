<div class="post-types">
  <div
    class="post-type-label"
    mat-raised-button
    matTooltip="Write Text"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" (click)="setText()">
      <div class="icon" [ngStyle]="getPostIconColors(mType.TEXT)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.TEXT)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-text"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'TEXT' | translate }}
      </div>
    </label>
    <div
      class="bar"
      [ngStyle]="{ color: messageType === mType.TEXT ? '#06a1bf' : '#ffffff' }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendphoto"
    class="post-type-label"
    mat-raised-button
    matTooltip="Upload Image"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" for="image-upload">
      <div class="icon" [ngStyle]="getPostIconColors(mType.PHOTO)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.PHOTO)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-photo"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'IMAGE' | translate }}
      </div>
    </label>
    <input
      #imageInputFile
      id="image-upload"
      type="file"
      accept="image/*"
      (change)="onFilesSelected()"
    />
    <div
      class="bar"
      [ngStyle]="{ color: messageType === mType.PHOTO ? '#4063a8' : '#ffffff' }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendvideo"
    class="post-type-label"
    mat-raised-button
    matTooltip="Add Video"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" for="video-upload">
      <div class="icon" [ngStyle]="getPostIconColors(mType.VIDEO)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.VIDEO)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-video"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'VIDEO' | translate }}
      </div>
    </label>
    <input
      #videoInputFile
      id="video-upload"
      type="file"
      accept="video/mp4,video/x-m4v,video/*"
      (change)="onFilesSelected()"
    />
    <div
      class="bar"
      [ngStyle]="{ color: messageType === mType.VIDEO ? '#09a331' : '#ffffff' }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendgif"
    class="post-type-label"
    mat-raised-button
    matTooltip="Add Animated Image (GIF)"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" for="gif-upload">
      <div class="icon" [ngStyle]="getPostIconColors(mType.GIF)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.GIF)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-gif"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'GIF' | translate }}
      </div>
    </label>
    <input
      #gifInputFile
      id="gif-upload"
      type="file"
      accept=".gif"
      (change)="onFilesSelected()"
    />
    <div
      class="bar"
      [ngStyle]="{ color: messageType === mType.GIF ? '#e44a1e' : '#ffffff' }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendaudio"
    class="post-type-label"
    mat-raised-button
    matTooltip="Upload Audio"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" for="audio-upload">
      <div class="icon" [ngStyle]="getPostIconColors(mType.AUDIO)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.AUDIO)">
          <use
            xlink:href="./assets/svg/app-sections.svg#icon-headphones-msg"
          ></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'AUDIO' | translate }}
      </div>
    </label>
    <input
      #audioInputFile
      id="audio-upload"
      type="file"
      accept="audio/*"
      (change)="onFilesSelected()"
    />
    <div
      class="bar"
      [ngStyle]="{ color: messageType === mType.AUDIO ? '#009eea' : '#ffffff' }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.senddoc"
    class="post-type-label"
    mat-raised-button
    matTooltip="Upload File"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor" for="document-upload">
      <div class="icon" [ngStyle]="getPostIconColors(mType.DOCUMENT)">
        <svg
          class="svg-icon icon-icon"
          [ngStyle]="getIconStyle(mType.DOCUMENT)"
        >
          <use xlink:href="./assets/svg/app-sections.svg#icon-paper"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'FILE' | translate }}
      </div>
    </label>
    <input
      #documentInputFile
      id="document-upload"
      type="file"
      (change)="onFilesSelected()"
    />
    <div
      class="bar"
      [ngStyle]="{
        color: messageType === mType.DOCUMENT ? '#4a4fe1' : '#ffffff'
      }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <!-- <div
    *appShowIfHasPrivilege="privilegesName.sendarticle"
    class="post-type-label"
    mat-raised-button
    matTooltip="Attach an Article URL"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
    (click)="showComposeUrlEvent(true, true)"
  >
    <label class="post-type-btn clickable-cursor">
      <div class="icon" [ngStyle]="getPostIconColors(mType.ARTICLE)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.ARTICLE)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-article"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'ARTICLE' | translate }}
      </div>
    </label>
    <div
      class="bar"
      [ngStyle]="{
        color: messageType === mType.ARTICLE ? '#ef9500' : '#ffffff'
      }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div> -->

  <div
    class="post-type-label"
    (click)="setLinkPrivew()"
    mat-raised-button
    matTooltip="Link a website"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor">
      <div class="icon" [ngStyle]="getPostIconColors(mType.CONTACT)">
        <svg class="svg-icon icon-icon" [ngStyle]="getIconStyle(mType.CONTACT)">
          <use xlink:href="./assets/svg/app-sections.svg#icon-link"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'LINK' | translate }}
      </div>
    </label>
    <div
      class="bar"
      [ngStyle]="{
        color: messageType === mType.CONTACT ? '#3673d7' : '#ffffff'
      }"
    >
      <span></span>
    </div>
    <div class="border-right"></div>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.editcalendar"
    class="post-type-label"
    (click)="showComposeCalendarEvent(true)"
    mat-raised-button
    matTooltip="Attach Calendar"
    matTooltipPosition="above"
    matTooltipClass="multiline-tooltip"
  >
    <label class="post-type-btn clickable-cursor">
      <div class="icon" [ngStyle]="getPostIconColors(mType.CALENDAR)">
        <svg
          class="svg-icon icon-icon"
          [ngStyle]="getIconStyle(mType.CALENDAR)"
        >
          <use xlink:href="./assets/svg/app-sections.svg#icon-calendar"></use>
        </svg>
      </div>
      <div class="tab-text">
        {{ 'CALENDAR' | translate }}
      </div>
    </label>
    <div
      class="bar"
      [ngStyle]="{
        color: messageType === mType.CALENDAR ? '#e75365' : '#ffffff'
      }"
    >
      <span></span>
    </div>
  </div>
</div>

<app-modal-container
  *ngIf="showComposeUrl"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="showComposeUrlEvent(false)"
>
  <app-compose-url-input
    (cancelNotfiy)="showComposeUrlEvent(false)"
    (publishNotfiy)="urlSelectedEvent($event)"
    [isInstant]="isInstant$ | async"
  >
  </app-compose-url-input>
</app-modal-container>

<app-modal-container
  *ngIf="showComposeCalendar"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="showComposeCalendarEvent(false)"
>
  <app-calendars-pool
    (selectCalendarNotfiy)="calendarSelectedEvent($event)"
    (cancelNotfiy)="showComposeCalendarEvent(false)"
  >
  </app-calendars-pool>
</app-modal-container>
