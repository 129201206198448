import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { RootStoreSelectors } from '../store/root-store.selectors';
import { MediaCenterDispatchers } from '../store/mediaCenter/mediaCenter.dispatchers';
import { MessageMediaStatus } from 'src/models/constants';
import { IMediaCenter, MediaCenterPrams } from 'src/models/IMediaCenter';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class MediaCenterService {
  constructor(
    public rootSelector: RootStoreSelectors,
    private _mediaCenterDispatchers: MediaCenterDispatchers,
    private _sanitizer: DomSanitizer
  ) {}

  getMyMediaGallery(mediaType: string) {
    this._mediaCenterDispatchers.getMyMediaGallery(mediaType);
  }

  getMediaCenterItems$(type: string, aspectRatio?: string) {
    return this.rootSelector.getMediaCenterItems$(type, aspectRatio);
  }

  getMedia(localMedia: string) {
    if (localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(localMedia);
    }
    return '';
  }

  getCover(status: string) {
    if (
      status === MessageMediaStatus.UPLOADING ||
      status === MessageMediaStatus.DOWNLOADING
    ) {
      return true;
    }
    return false;
  }

  deleteMediaCenterItem(item: IMediaCenter) {
    this._mediaCenterDispatchers.deleteMediaCenterItem(item);
  }

  mediaCenterUploading(event: ISelectedFiles, type: MediaCenterPrams) {
    this._mediaCenterDispatchers.mediaCenterUploading(
      event.localFile,
      event.type,
      type
    );
  }
}
