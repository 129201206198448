import { Store } from '@ngrx/store';
import * as AdsProvidersActions from './adsProvider.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AdsProvidersDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getAdsProviders(adsProviderId) {
        this._store.dispatch(new AdsProvidersActions.GetAdsProviders(adsProviderId));
    }
    receiveAdsProviders(adsProviders) {
        this._store.dispatch(new AdsProvidersActions.ReceiveAdsProvider(adsProviders.provider_id, adsProviders.config));
    }
    receivePaymentProvider(adsProviderId, config) {
        this._store.dispatch(new AdsProvidersActions.ReceiveAdsProvider(adsProviderId, config));
    }
    setAdsProvider(adsProviderId, config) {
        this._store.dispatch(new AdsProvidersActions.SetAdsProvider(adsProviderId, config));
    }
}
AdsProvidersDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdsProvidersDispatchers_Factory() { return new AdsProvidersDispatchers(i0.ɵɵinject(i1.Store)); }, token: AdsProvidersDispatchers, providedIn: "root" });
