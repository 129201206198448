<div class="feature-counter">
  <div class="head">
    <div class="head-price">
      Cost
    </div>
    <div class="head-date">
      DURATION
    </div>
  </div>
  <!-- <div class="feature-counter-seaction-title">
    <div class="feature-counter-seaction-title-others">
      Price
    </div>
    <div class="feature-counter-seaction-title-nandbox">
      $<ng2-odometer [number]="nandboxPrice" [config]="{}"></ng2-odometer>
    </div>
    <div class="feature-counter-seaction-title-others">
      <div class="not-valid" [class.active]="odometerFinished">
        ـــــــــــــــــــــــ
      </div>
      $<ng2-odometer [number]="price" [config]="{}"></ng2-odometer>
    </div>
  </div> -->
  <!-- <div class="feature-counter-seaction-title">
    <div class="feature-counter-seaction-title-others">
      Delivered in
    </div>
    <div class="feature-counter-seaction-title-nandbox">
      40 minutes
    </div>
    <div class="feature-counter-seaction-title-others">
      <div class="not-valid" [class.active]="odometerFinished">
        ـــــــــــــــــــــــــ
      </div>
      <ng2-odometer [number]="weeks" [config]="{}"></ng2-odometer> weeks
    </div>
  </div> -->
  <div class="cell" style="color: #06c755;">
    <div class="cell-header" style="background: #06c755;">nandbox</div>
    <div class="cell-price">
      $<ng2-odometer [number]="nandboxPrice" [config]="{}"></ng2-odometer>
    </div>
    <div class="cell-date">15 Mins</div>
  </div>
  <div class="cell" style="color: #545f6f;">
    <div class="cell-header">Others</div>
    <div class="cell-price">
      $<ng2-odometer [number]="price" [config]="{}"></ng2-odometer>
      <div class="not-valid" [class.active]="odometerFinished">
        ـــــــــــــــــــ
      </div>
    </div>
    <div class="cell-date">
      Weeks
      <div class="not-valid" [class.active]="odometerFinished">
        ـــــــــــــــ
      </div>
    </div>
  </div>
  <div class="feature-counter-seaction-title">
    <div class="feature-counter-seaction-title-header">
      Save Time & Money
    </div>
    <div class="feature-counter-seaction-title-subheader">
      <div class="selected-features">{{ userFeatures }}/200</div>
      <div class="text">
        Selected features
      </div>
    </div>
  </div>
</div>
