import { Action } from '@ngrx/store';
import { MyPage } from 'src/models/MyPageConfig';

export const enum InstantPageActionTypes {
  REQUEST_INSTANT_PAGE_DETAILS = 'REQUEST_INSTANT_PAGE_DETAILS',
  RECEIVE_INSTANT_PAGE_DETAILS = 'RECEIVE_INSTANT_PAGE_DETAILS',
  RECEIVE_INSTANT_PAGES = 'RECEIVE_INSTANT_PAGES',
  RESET = 'RESET'
}

export class RequestInstantPageDetails implements Action {
  readonly type = InstantPageActionTypes.REQUEST_INSTANT_PAGE_DETAILS;
  constructor(public id: string) {}
}

export class ReceiveInstantPageDetails implements Action {
  readonly type = InstantPageActionTypes.RECEIVE_INSTANT_PAGE_DETAILS;
  constructor(public rcvdPage: MyPage) {}
}

export class ReceiveInstantPages implements Action {
  readonly type = InstantPageActionTypes.RECEIVE_INSTANT_PAGES;
  constructor(public rcvdPages: MyPage[]) {}
}

export class Reset implements Action {
  readonly type = InstantPageActionTypes.RESET;
}

export type Actions =
  | RequestInstantPageDetails
  | ReceiveInstantPageDetails
  | ReceiveInstantPages
  | Reset;
