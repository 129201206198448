import {
  GET_MAP_MARKER_METHOD,
  GET_MAP_MARKERS_METHOD,
  REMOVE_MAP_MARKER_METHOD,
  CREATE_MAP_MARKER_METHOD,
  CREATE_MAP_MARKER_REF,
  SET_MAP_MARKER_METHOD,
  MERCHANT_PAGE_SIZE,
  GET_MAP_MARKER_REF
} from './constants';

export interface MapMarkerAction {
  type: string;
  id: string;
  title?: string;
  symbolColor?: string;
  symbolImage?: string;
}
export interface MapMarker {
  id?: string; // The key
  title?: string;
  snippet?: string;
  icon?: string;
  lat?: string;
  lon?: string;
  type?: string;
  url?: string; // like a website attached to the location for more info
  photo?: string;
  image_url?: string;
  actions?: MapMarkerAction[];
  address?: string;
  owner?: string;
  reference?: string;
  recordIndex?: number;
}

export class GetMapMarker {
  readonly method = GET_MAP_MARKER_METHOD;
  reference = GET_MAP_MARKER_REF;
  constructor(public id: string) {}
}

export class GetMapMarkers {
  readonly method = GET_MAP_MARKERS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public hash?: string,
    public reference?: number,
    public selected_chat: string = null,
    public type?: string
  ) {}
}

export class RemoveMapMarker {
  readonly method = REMOVE_MAP_MARKER_METHOD;
  constructor(public id: string) {}
}

export class CreateMapMarker {
  readonly method = CREATE_MAP_MARKER_METHOD;
  reference = CREATE_MAP_MARKER_REF;
  constructor(mapMarker: MapMarker) {
    if (mapMarker.reference) this.reference = mapMarker.reference;
    for (const key in mapMarker) {
      if (mapMarker.hasOwnProperty(key)) {
        this[key] = mapMarker[key];
      }
    }
  }
}

export class SetMapMarker {
  readonly method = SET_MAP_MARKER_METHOD;
  constructor(mapMarker: MapMarker) {
    for (const key in mapMarker) {
      if (mapMarker.hasOwnProperty(key)) {
        this[key] = mapMarker[key];
      }
    }
  }
}

export interface RcvdMapMarkerMessage {
  markers?: MapMarker[];
  sop?: number;
  eop?: number;
}
