import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ngxMediumModule } from 'ngx-medium-editor';

import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';

import { InstantArticleEditorComponent } from './instant-article-editor/instant-article-editor.component';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

@NgModule({
  imports: [
    CommonModule,
    ngxMediumModule,
    AbstractBtnsModule,
    AbstractFramesModule
  ],
  declarations: [InstantArticleEditorComponent],
  exports: [InstantArticleEditorComponent]
})
export class InstantArticleModule {}
