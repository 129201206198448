import {
  ADD_MAP_TRIP_STOP_TIMES_METHOD,
  CREATE_MAP_STOP_TIME_METHOD,
  GET_MAP_STOP_TIMES_METHOD
} from './constants';

export interface Stops {
  id?: string;
  trip_id?: string;
  marker?: string;
  arrival_time?: string;
  departure_time?: string;
  name?: string;
}

export class SetMapStopTime {
  readonly method = CREATE_MAP_STOP_TIME_METHOD;
  constructor(mapStops: Stops) {
    for (const key in mapStops) {
      if (mapStops.hasOwnProperty(key)) {
        this[key] = mapStops[key];
      }
    }
  }
}

export class GetMapStopTimeMethod {
  readonly method = GET_MAP_STOP_TIMES_METHOD;
  constructor(public trip_id: string, public reference: number) {}
}
export class SetMapStopTimesMethod {
  readonly method = ADD_MAP_TRIP_STOP_TIMES_METHOD;
  constructor(
    public trip_id: string,
    public data: Stops[],
    public reference: number
  ) {}
}
