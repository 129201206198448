/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/cancel-drag/cancel-drag.directive";
import * as i4 from "./profile-image.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../store/root-store.selectors";
var styles_ProfileImageComponent = [i0.styles];
var RenderType_ProfileImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileImageComponent, data: {} });
export { RenderType_ProfileImageComponent as RenderType_ProfileImageComponent };
function View_ProfileImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [["appCancelDrag", ""], ["class", "circle-color"], ["draggable", "false"]], [[8, "src", 4], [8, "alt", 0], [2, "square", null]], [[null, "dragstart"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onDragEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onDragEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onDragEvents($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onDragEvents($event) !== false);
        ad = (pd_3 && ad);
    } if (("drop" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onDragEvents($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 16384, null, 0, i3.CancelDragDirective, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.cssStyles; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnail; var currVal_1 = ((_co.profile == null) ? null : _co.profile.name); var currVal_2 = _co.isSquare; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ProfileImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "circle-color"], ["draggable", "false"]], [[8, "src", 4], [2, "square", null], [8, "alt", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.cssStyles; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cssPlaceHolderImage; var currVal_1 = _co.isSquare; var currVal_2 = _co.chatType; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProfileImageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.profile.image; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ProfileImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-image", [], null, null, null, View_ProfileImageComponent_0, RenderType_ProfileImageComponent)), i1.ɵdid(1, 704512, null, 0, i4.ProfileImageComponent, [i5.DomSanitizer, i6.RootStoreSelectors, i1.ChangeDetectorRef], null, null)], null, null); }
var ProfileImageComponentNgFactory = i1.ɵccf("app-profile-image", i4.ProfileImageComponent, View_ProfileImageComponent_Host_0, { id: "id", name: "name", size: "size", emSize: "emSize", isSquare: "isSquare", chatType: "chatType", chat: "chat", isPostScreen: "isPostScreen" }, {}, []);
export { ProfileImageComponentNgFactory as ProfileImageComponentNgFactory };
