import { Action } from '@ngrx/store';
import { ServiceOrder } from 'src/models/PaymentGateways';

export const enum ServiceOrdersUIActionTypes {
  UI_SERVICE_ORDERS_GET_NEXT_PAGE = 'UI_SERVICE_ORDERS_GET_NEXT_PAGE',
  UI_SERVICE_ORDERS_GET_PREV_PAGE = 'UI_SERVICE_ORDERS_GET_PREV_PAGE',
  UI_SERVICE_ORDERS_RECEIVED_HISTORY_CHUNK = 'UI_SERVICE_ORDERS_RECEIVED_HISTORY_CHUNK',
  UI_SERVICE_ORDERS_RESET_PAGING = 'UI_SERVICE_ORDERS_RESET_PAGING',
  UI_SERVICE_ORDERS_SET_IDLE_STATUS = 'UI_SERVICE_ORDERS_SET_IDLE_STATUS',
  RESET = 'RESET'
}

// Paging Actions

export class GetNextServiceOrdersPage implements Action {
  readonly type = ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_NEXT_PAGE;
  constructor(public paymentOrderID: string, public serviceOrderID: string) {}
}

export class GetPrevServiceOrdersPage implements Action {
  readonly type = ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_PREV_PAGE;
  constructor(public paymentOrderID: string, public serviceOrderID: string) {}
}

export class ReceivedServiceOrdersHistoryChunk implements Action {
  readonly type =
    ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: ServiceOrder[]
  ) {}
}

export class ResetServiceOrdersPaging implements Action {
  readonly type = ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_RESET_PAGING;
  constructor(public paymentOrderID: string, public serviceOrderID: string) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = ServiceOrdersUIActionTypes.RESET;
}

export type Actions =
  | GetNextServiceOrdersPage
  | GetPrevServiceOrdersPage
  | ReceivedServiceOrdersHistoryChunk
  | ResetServiceOrdersPaging
  | SetStatusAsIdle
  | Reset;
