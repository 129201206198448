import { Store } from '@ngrx/store';
import * as ThirdPartyInfo from './thirdPartyInfo.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ThirdPartyInfoDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveThirdPartyInfo(vendors) {
        this._store.dispatch(new ThirdPartyInfo.ReceiveThirdPartyInfo(vendors));
    }
    updateThirdPartyInfo(vendors) {
        this._store.dispatch(new ThirdPartyInfo.UpdateThirdPartyInfo(vendors));
    }
    testThirdPartyInfo(vendor) {
        this._store.dispatch(new ThirdPartyInfo.TestThirdPartyInfo(vendor));
    }
    saveThirdPartyInfo() {
        this._store.dispatch(new ThirdPartyInfo.SaveThirdPartyInfo());
    }
    receiveTestThirdPartyInfo(accepted, message, tac) {
        this._store.dispatch(new ThirdPartyInfo.ReceiveTestThirdPartyInfo(accepted, message, tac));
    }
    restErrorMassage() {
        this._store.dispatch(new ThirdPartyInfo.RestErrorMassage());
    }
    getMessagingVendorUI(name) {
        this._store.dispatch(new ThirdPartyInfo.GetMessagingVendorUI(name));
    }
    setVerificationMessageTemplate(value, type) {
        this._store.dispatch(new ThirdPartyInfo.SetVerificationMessage(value, type));
    }
    sendVerificationMessageTemplate() {
        this._store.dispatch(new ThirdPartyInfo.SendVerificationMessageTemplate());
    }
    getVerificationMessageTemplate() {
        this._store.dispatch(new ThirdPartyInfo.GetVerificationMessageTemplate());
    }
    receiveVerificationMessageTemplate(email, sms) {
        this._store.dispatch(new ThirdPartyInfo.ReceiveVerificationMessageTemplate(email, sms));
    }
    selectTypeOfMessage(type, loginType) {
        this._store.dispatch(new ThirdPartyInfo.SelectTypeOfMessage(type, loginType));
    }
    deleteVerificationMessageTemplate() {
        this._store.dispatch(new ThirdPartyInfo.DeleteVerificationMessageTemplate());
    }
}
ThirdPartyInfoDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThirdPartyInfoDispatchers_Factory() { return new ThirdPartyInfoDispatchers(i0.ɵɵinject(i1.Store)); }, token: ThirdPartyInfoDispatchers, providedIn: "root" });
