import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { AppInfo } from 'src/models/ChannelAppConfig';

@Component({
  selector: 'app-startover-guide-video',
  templateUrl: './startover-guide-video.component.html',
  styleUrls: ['./startover-guide-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverGuideVideoComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public appMgmtService: AppMgmtService
  ) {}
  @Input() appInfo: AppInfo;

  ngOnInit() {}

  continue() {
    let page: any;
    page = 0;

    const newAppInfo: AppInfo = {};
    if (!this.appInfo.demo_video) {
      newAppInfo.demo_video = 1;
    } else {
      newAppInfo.demo_video = ++this.appInfo.demo_video;
    }
    this.appMgmtService.updateAppInfo(newAppInfo);
    this.appMgmtService.savePage(false);
    this.appMgmtService.goToSpecificPage(1);
    this.authService.updateProgressWeb('1', 2);
  }
}
