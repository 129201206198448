import { Stops } from 'src/models/MapStopTime';
import * as actions from './mapStopTime.actions';
import { Actions } from './mapStopTime.actions';

const INITIAL_STATE: Stops[] = [];
export function mapTimeStopsReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.MapStopTimeActionTypes.CREATE_MAP_STOP_TIME_RECEIVED: {
      return state.length > 0
        ? [...state, action.MapStopTime]
        : [action.MapStopTime];
    }
    case actions.MapStopTimeActionTypes.MAP_STOP_TIMES_LIST_RECEIVED: {
      return [...action.MapStopTimes];
    }
    case actions.MapStopTimeActionTypes.MAP_STOPS_RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
