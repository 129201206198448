import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';
import { Actions } from './collectionsUI.actions';

import * as actions from './collectionsUI.actions';
import { CollectionUI } from 'src/models/collectionsUI';

const INITIAL_STATE: CollectionUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  productGroupId: null,
  ownerGroupId: null,
  selectedItem: null
};

export function collectionsUiReducer(
  state = INITIAL_STATE,
  action: Actions
): CollectionUI {
  switch (action.type) {
    case actions.CollectionsUIActionTypes.UI_COLLECTIONS_RESET_PAGING:
      return INITIAL_STATE;
    case actions.CollectionsUIActionTypes.UI_GET_COLLECTIONS_NEXT_PAGE:
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    case actions.CollectionsUIActionTypes.UI_GET_COLLECTIONS_PREV_PAGE:
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    case actions.CollectionsUIActionTypes.UI_RECEIVE_COLLECTIONS_ITEM: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.CollectionsUIActionTypes.UI_GET_COLLECTION_ITEM: {
      return {
        ...state
      };
    }
    case actions.CollectionsUIActionTypes.UI_COLLECTION_RECEIVED_ITEM:
      const currentItem = state.currentPage.find(
        collection => action.collection.id === collection.id
      );
      return {
        ...state,
        currentPage: currentItem
          ? state.currentPage.map(collection =>
              action.collection.id === collection.id
                ? { ...collection, ...action.collection }
                : collection
            )
          : [action.collection, ...state.currentPage],
        selectedItem: action.collection
      };
    case actions.CollectionsUIActionTypes.UI_REMOVE_COLLECTION_ITEM_SUCCESS:
      return {
        ...state,
        currentPage: state.currentPage.filter(item => item.id !== action.id)
      };
    case actions.CollectionsUIActionTypes.UI_RECEVE_COLLECTION_PRODUCTS:
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          products: [...action.productStore]
        }
      };
    default:
      return state;
  }
}
