import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as Methods from 'src/models/MapRoute';
import { MapRouteDispatchers } from './mapRoute.dispatchers';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
export class MapRouteEffects {
    /** No need for the following effect because of all the data comes with the list */
    // @Effect({ dispatch: false })
    // listReceived = this.actions$.pipe(
    //   ofType(MapRouteActionTypes.MAP_ROUTE_LIST_RECEIVED),
    //   map((action: MapActions.MapRouteListReceived) => {
    //     if (action.mapRoutes && action.mapRoutes.length > 0) {
    //       action.mapRoutes.forEach(route => {
    //         this._socketGateway.sendSocketMessage(
    //           new Methods.GetMapRoute(route.id)
    //         );
    //       });
    //     }
    //   })
    // );
    constructor(actions$, _socketGateway, _mapRouteDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._mapRouteDispatchers = _mapRouteDispatchers;
        this._store = _store;
        this.resetMapRoute = this.actions$.pipe(ofType("RESET_MAP_ROUTES_PAGING" /* RESET_MAP_ROUTES_PAGING */), map((action) => {
            this._mapRouteDispatchers.getNextMapRoutePage();
        }));
        this.getNextMapRoutePage = this.actions$.pipe(ofType("GET_NEXT_MAP_ROUTES_PAGE" /* GET_NEXT_MAP_ROUTES_PAGE */), withLatestFrom(this._store
            .select(state => state.mapRouteReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapRouteUiState]) => {
            const action = val;
            const prevRequest = mapRouteUiState.previousRequest;
            if (!(mapRouteUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapRouteUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapRoutes(0, mapRouteUiState.eop, mapRouteUiState.hash));
            }
            else {
                this._mapRouteDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMapRoutePage = this.actions$.pipe(ofType("GET_PREV_MAP_ROUTES_PAGE" /* GET_PREV_MAP_ROUTES_PAGE */), withLatestFrom(this._store
            .select(state => state.mapRouteReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapRouteUiState]) => {
            const action = val;
            const prevRequest = mapRouteUiState.previousRequest;
            if (!(mapRouteUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapRouteUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapRoutes(1, mapRouteUiState.sop, mapRouteUiState.hash));
            }
            else {
                this._mapRouteDispatchers.setStatusAsIdle();
            }
        }));
        this.createMapRoute = this.actions$.pipe(ofType("CREATE_MAP_ROUTE" /* CREATE_MAP_ROUTE */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapRoute(action.mapRoute));
        }));
        this.getMapRouteByID = this.actions$.pipe(ofType("GET_MAP_ROUTE_BY_ID" /* GET_MAP_ROUTE_BY_ID */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.GetMapRoute(action.id));
        }));
        this.updateMapRoute = this.actions$.pipe(ofType("UPDATE_MAP_ROUTE" /* UPDATE_MAP_ROUTE */), map((action) => {
            const mapRoute = Object.assign({}, action.mapRouteDetails, { id: action.id });
            this._socketGateway.sendSocketMessage(new Methods.SetMapRoute(mapRoute));
        }));
        this.deleteMapRoute = this.actions$.pipe(ofType("DELETE_MAP_ROUTE" /* DELETE_MAP_ROUTE */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveMapRoute(action.id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "resetMapRoute", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "getNextMapRoutePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "getPrevMapRoutePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "createMapRoute", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "getMapRouteByID", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "updateMapRoute", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapRouteEffects.prototype, "deleteMapRoute", void 0);
