/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-item-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./empty-item-frame.component";
var styles_EmptyItemFrameComponent = [i0.styles];
var RenderType_EmptyItemFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyItemFrameComponent, data: {} });
export { RenderType_EmptyItemFrameComponent as RenderType_EmptyItemFrameComponent };
export function View_EmptyItemFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "no-items"]], [[2, "no-bg", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "no-items-symbol"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "no-items-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "no-items-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noBg; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", _co.emptySymbol, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.emptyTitle; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.emptyDesc; _ck(_v, 7, 0, currVal_3); }); }
export function View_EmptyItemFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, View_EmptyItemFrameComponent_0, RenderType_EmptyItemFrameComponent)), i1.ɵdid(1, 49152, null, 0, i2.EmptyItemFrameComponent, [], null, null)], null, null); }
var EmptyItemFrameComponentNgFactory = i1.ɵccf("app-empty-item-frame", i2.EmptyItemFrameComponent, View_EmptyItemFrameComponent_Host_0, { emptySymbol: "emptySymbol", emptyTitle: "emptyTitle", emptyDesc: "emptyDesc", noBg: "noBg" }, {}, []);
export { EmptyItemFrameComponentNgFactory as EmptyItemFrameComponentNgFactory };
