import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as BulkActions from './bulk.actions';
import { map } from 'rxjs/operators';
import { UiService } from 'src/app/core/ui.service';

@Injectable()
export class BulkEffects {
  // get campaign after receive campaigns
  @Effect({ dispatch: false })
  getCampaign = this.actions$.pipe(
    ofType(BulkActions.BulkActionTypes.RECORD_CREATED),
    map((action: BulkActions.RecordCreated) => {
      this._uiService._recordAdd.next({
        index: action.payload,
        recordType: action.recordType,
        id: action.id,
        ref: action.ref
      });
    })
  );

  constructor(private actions$: Actions, private _uiService: UiService) {}
}
