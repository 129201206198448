import { DatePipe } from '@angular/common';
import { ChartObj } from 'src/models/ChartUI';

export class ChartMiddleware {
  public static getDatesInRange(
    values: ChartObj[],
    startDate,
    endDate,
    interval
  ) {
    const date = new Date(startDate.getTime());
    const dates: ChartObj[] = [];
    const selectedEndDate: ChartObj = {
      x: new DatePipe('en-US').transform(endDate, 'yyyy-MM-dd'),
      y: 0
    };
    let chartData = [...values, selectedEndDate];
    let i = 0;
    let formatDate = 'dd MMM';
    if (interval == 1) {
      formatDate = 'dd MMM';

      while (date <= endDate) {
        const obj: ChartObj = {};
        if (date < new Date(chartData[i].x)) {
          obj.x = new DatePipe('en-US').transform(date, formatDate);
          obj.y = 0;
          dates.push(obj);
        } else {
          const chartOpj: ChartObj = {
            ...chartData[i],
            x: new DatePipe('en-US').transform(chartData[i].x, formatDate)
          };
          dates.push(chartOpj);
          i++;
        }
        date.setDate(date.getDate() + 1);
      }
    } else if (interval == 2) {
      formatDate = 'dd MMM';
      const startDate = new Date(date);
      while (startDate < endDate) {
        const obj: ChartObj = {};
        if (startDate < new Date(chartData[i].x)) {
          obj.x = new DatePipe('en-US').transform(startDate, formatDate);
          obj.y = 0;
          dates.push(obj);
        } else {
          const chartOpj: ChartObj = {
            ...chartData[i],
            x: new DatePipe('en-US').transform(chartData[i].x, formatDate)
          };
          dates.push(chartOpj);
          i++;
        }
        startDate.setDate(startDate.getDate() + 7);
      }
    } else if (interval == 3) {
      formatDate = 'MMM yyyy';
      while (date <= endDate) {
        const obj: ChartObj = {};
        if (date < new Date(chartData[i].x)) {
          obj.x = new DatePipe('en-US').transform(date, formatDate);
          obj.y = 0;
          dates.push(obj);
        } else {
          const chartOpj: ChartObj = {
            ...chartData[i],
            x: new DatePipe('en-US').transform(chartData[i].x, formatDate)
          };
          dates.push(chartOpj);
          i++;
        }
        date.setMonth(date.getMonth() + 1);
      }
    } else if (interval == 4) {
      formatDate = 'yyyy';
      while (date <= endDate) {
        const obj: ChartObj = {};
        if (date < new Date(chartData[i].x)) {
          obj.x = new DatePipe('en-US').transform(date, formatDate);
          obj.y = 0;
          dates.push(obj);
        } else {
          const chartOpj: ChartObj = {
            ...chartData[i],
            x: new DatePipe('en-US').transform(chartData[i].x, formatDate)
          };
          dates.push(chartOpj);
          i++;
        }
        date.setFullYear(date.getFullYear() + 1);
      }
    }
    return dates;
  }
}
