/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./google-btn.component";
import * as i3 from "../../../core/auth.service";
var styles_GoogleBtnComponent = [i0.styles];
var RenderType_GoogleBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleBtnComponent, data: {} });
export { RenderType_GoogleBtnComponent as RenderType_GoogleBtnComponent };
export function View_GoogleBtnComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "google"], ["id", "loginRef"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-google"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sign in with Google\n"]))], null, null); }
export function View_GoogleBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-google-btn", [], null, null, null, View_GoogleBtnComponent_0, RenderType_GoogleBtnComponent)), i1.ɵdid(1, 4243456, null, 0, i2.GoogleBtnComponent, [i3.AuthService], null, null)], null, null); }
var GoogleBtnComponentNgFactory = i1.ɵccf("app-google-btn", i2.GoogleBtnComponent, View_GoogleBtnComponent_Host_0, {}, {}, []);
export { GoogleBtnComponentNgFactory as GoogleBtnComponentNgFactory };
