import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { CellsService } from '../cells.service';

@Component({
  selector: 'app-map-cell',
  templateUrl: './map-cell.component.html',
  styleUrls: ['./map-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapCellComponent {
  @Input() width: number;
  @Input() height: number;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() locationName: string;
  @Input() locationDetails: string;

  constructor(private _cellsService: CellsService) {}

  get googleLink() {
    return `${this._cellsService.GOOGLE_MAP_LINK}${this.latitude},${this.longitude}`;
  }

  get mapImage() {
    return `${this._cellsService.GOOGLE_MAP_IMAGE_LINK}${+this.latitude},${+this
      .longitude}&center=${+this.latitude},${+this.longitude}&zoom=14&size=${
      this.width
    }x${this.height}&key=${this._cellsService.MAP_API_KEY}`;
  }
}
