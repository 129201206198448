import { Store } from '@ngrx/store';
import * as DriverAssignedActionTypes from './driverAssigned.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class DriverTripsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getDriverAssigned(trip_id, account_id, start_day, end_day) {
        this._store.dispatch(new DriverAssignedActionTypes.GetDriverTrips(trip_id, account_id, start_day, end_day));
    }
    setDriverAssigned(tripId, accountId, driverAssignedList) {
        this._store.dispatch(new DriverAssignedActionTypes.SetDriverTrips(tripId, accountId, driverAssignedList));
    }
    driverTripsReceived(payload) {
        this._store.dispatch(new DriverAssignedActionTypes.DriverTipsReceived(payload));
    }
}
DriverTripsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DriverTripsDispatchers_Factory() { return new DriverTripsDispatchers(i0.ɵɵinject(i1.Store)); }, token: DriverTripsDispatchers, providedIn: "root" });
