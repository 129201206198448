import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CollectionsUIDispatchers } from 'src/app/store/collectionsUI/collectionsUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { Collection } from 'src/models/collectionsUI';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  public _selectedCollection = new BehaviorSubject<Collection>(null);
  public selectedCollection$ = this._selectedCollection.asObservable();

  confirmProduct = false;
  selectedCollection: Collection;

  constructor(
    private _collectionsUIDispatchers: CollectionsUIDispatchers,
    private _rootStore: RootStoreSelectors
  ) {}

  get getCollectionsList$() {
    return this._rootStore.getCollectionsList$();
  }

  getSelectedCollection$() {
    return this._rootStore.getSelectedCollection$();
  }

  resetPaging() {
    this._collectionsUIDispatchers.resetCollectionsPaging();
  }

  setCollectionItem(collection: Collection) {
    this._collectionsUIDispatchers.setCollectionItem(collection);
  }

  updateCollectionItem(collection: Collection) {
    this._selectedCollection.next({ ...collection });
  }

  getCollectionItem(id: number, isSelectedItem?: boolean) {
    this._collectionsUIDispatchers.getCollectionItem(id, isSelectedItem);
  }

  setCollectionProductsItem(id: number, productIDs: number[]) {
    this._collectionsUIDispatchers.setCollectionProducts(id, productIDs);
  }

  getNextPage() {
    this._collectionsUIDispatchers.getCollectionsNextPage();
  }

  getPrevPage() {
    this._collectionsUIDispatchers.getCollectionsPrevPage();
  }

  deleteCollectionItem(id: number) {
    this._collectionsUIDispatchers.deleteCollectionItem(id);
  }

  getCollectionProducts(id: number) {
    this._collectionsUIDispatchers.getCollectionProducts(id);
  }

  toggleConfirmDeleteProduct(e: boolean, collection?: Collection) {
    this.confirmProduct = e;
    this.selectedCollection = collection;
  }

  getSelectedCollectionByID$(id: number) {
    return this._rootStore.getSelectedCollectionByID$(id);
  }
}
