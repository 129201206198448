<div class="uploader">
  <div class="uploader-input">
    <!-- <div class="uploader-input-label"> -->
    <div class="upload-file">
      <label for="file-upload" class="create-btn">
        <i class="fas fa-plus"></i>
        Add Media
      </label>
      <input
        #fileUpload
        id="file-upload"
        type="file"
        (change)="onFileSelectedEvent()"
        [accept]="mimeTypes"
      />
    </div>
    <!-- </div> -->
  </div>
</div>
