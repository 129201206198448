import { Action } from '@ngrx/store/public_api';
import { AppRelease } from 'src/models/AppRelease';

export const enum AppReleaseActionTypes {
  UI_RELEASE_RESET_PAGING = 'UI_RELEASE_RESET_PAGING',
  UI_RELEASE_GET_NEXT_PAGE = 'UI_RELEASE_GET_NEXT_PAGE',
  UI_RELEASE_GET_PREV_PAGE = 'UI_RELEASE_GET_PREV_PAGE',
  LIST_APP_RELEASES = 'LIST_APP_RELEASES',
  LIST_APP_RELEASE_ID = 'LIST_APP_RELEASE_ID',
  LIST_APP_RELEASES_SUCCESS = 'LIST_APP_RELEASES_SUCCESS',
  RCVD_APP_RELEASE = 'RCVD_APP_RELEASE',
  REMOVE_APP_RELEASE = 'REMOVE_APP_RELEASE',
  REMOVE_APP_RELEASE_SUCCESS = 'REMOVE_APP_RELEASE_SUCCESS',
  VIEW_APP_RELEASE_DETAILS = 'VIEW_APP_RELEASE_DETAILS',
  HIDE_APP_RELEASE_DETAILS = 'HIDE_APP_RELEASE_DETAILS',
  SHOW_APPLE_KEY_FORM = 'SHOW_APPLE_KEY_FORM',
  SEND_APPLE_KEY_CODE = 'SEND_APPLE_KEY_CODE',
  GET_APP_RELEASE_VER = 'GET_APP_RELEASE_VER',
  RCVD_APP_RELEASE_VER = ' RCVD_APP_RELEASE_VER',
  ADD_APP_RELEASE_WAITING_ITEM = ' ADD_APP_RELEASE_WAITING_ITEM',
  REMOVE_APP_RELEASE_WAITING_ITEM = 'REMOVE_APP_RELEASE_WAITING_ITEM',
  RESET = 'RESET'
}

export class ResetReleasePaging implements Action {
  readonly type = AppReleaseActionTypes.UI_RELEASE_RESET_PAGING;
}
export class GetNextPageRelease implements Action {
  readonly type = AppReleaseActionTypes.UI_RELEASE_GET_NEXT_PAGE;
}
export class GetPrevPageRelease implements Action {
  readonly type = AppReleaseActionTypes.UI_RELEASE_GET_PREV_PAGE;
}
export class ListAppReleases implements Action {
  readonly type = AppReleaseActionTypes.LIST_APP_RELEASES;
}

export class ListAppReleaseId implements Action {
  readonly type = AppReleaseActionTypes.LIST_APP_RELEASE_ID;
  constructor(public payload: AppRelease) {}
}

export class ListAppReleasesSuccess implements Action {
  readonly type = AppReleaseActionTypes.LIST_APP_RELEASES_SUCCESS;
  constructor(
    public eop: number,
    public sop: number,
    public appReleases: AppRelease[]
  ) {}
}

export class RcvdAppRelease implements Action {
  readonly type = AppReleaseActionTypes.RCVD_APP_RELEASE;
  constructor(public appReleases: AppRelease) {}
}

export class RemoveAppRelease implements Action {
  readonly type = AppReleaseActionTypes.REMOVE_APP_RELEASE;
  constructor(public id: number) {}
}

export class RemoveAppReleaseSuccess implements Action {
  readonly type = AppReleaseActionTypes.REMOVE_APP_RELEASE_SUCCESS;
  constructor(public id: number) {}
}

export class ViewAppReleaseDetails implements Action {
  readonly type = AppReleaseActionTypes.VIEW_APP_RELEASE_DETAILS;
  constructor(public id: number) {}
}

export class HideAppReleaseDetails implements Action {
  readonly type = AppReleaseActionTypes.HIDE_APP_RELEASE_DETAILS;
  constructor(public id: number) {}
}

export class ShowAppleKeyForm implements Action {
  readonly type = AppReleaseActionTypes.SHOW_APPLE_KEY_FORM;
  constructor(public release_id: number) {}
}

export class SendAppleKeyCode implements Action {
  readonly type = AppReleaseActionTypes.SEND_APPLE_KEY_CODE;
  constructor(public code: string, public release_id: number) {}
}

export class GetAppVer implements Action {
  readonly type = AppReleaseActionTypes.GET_APP_RELEASE_VER;
  constructor(public release_id: number) {}
}

export class RcvdAppReleaseVer implements Action {
  readonly type = AppReleaseActionTypes.RCVD_APP_RELEASE_VER;
  constructor(public appReleases: AppRelease) {}
}
export class AddAppReleaseWaitingItem implements Action {
  readonly type = AppReleaseActionTypes.ADD_APP_RELEASE_WAITING_ITEM;
  constructor(public appReleases: AppRelease) {}
}
export class RemoveAppReleaseWaitingItem implements Action {
  readonly type = AppReleaseActionTypes.REMOVE_APP_RELEASE_WAITING_ITEM;
}

export class Reset implements Action {
  readonly type = AppReleaseActionTypes.RESET;
}

export type Actions =
  | ResetReleasePaging
  | GetNextPageRelease
  | GetPrevPageRelease
  | ListAppReleases
  | ListAppReleaseId
  | ListAppReleasesSuccess
  | RcvdAppRelease
  | RemoveAppRelease
  | RemoveAppReleaseSuccess
  | ViewAppReleaseDetails
  | HideAppReleaseDetails
  | ShowAppleKeyForm
  | SendAppleKeyCode
  | GetAppVer
  | RcvdAppReleaseVer
  | AddAppReleaseWaitingItem
  | RemoveAppReleaseWaitingItem
  | Reset;
