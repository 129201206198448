import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { AddNewCreditCard, CancelStripeSubscription, ChangePlanMethod, DeleteCreditCard, GetBillingInfoMethod, GetInvoicesMethod, RevokeSubscription, SendBillingSubscribetion, SendCouponToServer, SetDefaultCreditCard, SubscribeToAddons } from 'src/models/billings';
import * as isEqual from 'lodash.isequal';
import { UIDispatchers } from '../ui/ui.dispatchers';
export class BillingUiEffects {
    constructor(actions$, _socketGateway, _uiDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this._store = _store;
        this.sendSubscribetionToPlan = this.actions$.pipe(ofType("SEND_SUBSCRIBETION_TO_PLAN_UI" /* SEND_SUBSCRIBETION_TO_PLAN_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new SendBillingSubscribetion(action.subscribeDetails.phone, action.subscribeDetails.name, action.subscribeDetails.email, action.subscribeDetails.card_token, action.subscribeDetails.plan_id, action.subscribeDetails.coupon, action.subscribeDetails.sub_main, action.lm_data));
        }));
        this.sendCouponUI = this.actions$.pipe(ofType("SEND_COUPON_UI" /* SEND_COUPON_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new SendCouponToServer(action.coupon));
        }));
        this.addNewCreditCard = this.actions$.pipe(ofType("ADD_NEW_CREDIT_CARD_UI" /* ADD_NEW_CREDIT_CARD_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddNewCreditCard(action.token.card_token));
        }));
        this.setDefaultCreditCardUI = this.actions$.pipe(ofType("SET_DEFAULT_CARD_UI" /* SET_DEFAULT_CARD_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetDefaultCreditCard(action.cardId));
        }));
        this.deleteCreditCardRecuset = this.actions$.pipe(ofType("DELETE_CREDIT_CARD_UI" /* DELETE_CREDIT_CARD_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new DeleteCreditCard(action.cardId));
        }));
        this.getBillingInfo = this.actions$.pipe(ofType("GET_BILLING_INFO_SETUP" /* GET_BILLING_INFO_SETUP */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetBillingInfoMethod());
        }));
        this.getInvoicesListUI = this.actions$.pipe(ofType("GET_INVOICES_LIST_UI" /* GET_INVOICES_LIST_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetInvoicesMethod());
        }));
        this.changePlanRequest = this.actions$.pipe(ofType("CHANGE_PLAN_REQUEST_UI" /* CHANGE_PLAN_REQUEST_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new ChangePlanMethod(action.new_plan_id, action.coupon, true));
        }));
        this.subscribeToAddonsRequest = this.actions$.pipe(ofType("SUBSCRIBE_TO_ADDONS_REQUEST_UI" /* SUBSCRIBE_TO_ADDONS_REQUEST_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new SubscribeToAddons(action.plan_id, false, action.coupon));
        }));
        this.cancelStripeSubsCription = this.actions$.pipe(ofType("CANCEL_STRIPE_SUBSCRIPTION_UI" /* CANCEL_STRIPE_SUBSCRIPTION_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new CancelStripeSubscription(action.all, action.plan_id, action.reason, action.subMain, action.cancelQuantity));
        }));
        this.goToLastSectionUI = this.actions$.pipe(ofType("GO_TO_LAST_SECTION_UI" /* GO_TO_LAST_SECTION_UI */), withLatestFrom(this._store
            .select(state => state.billingUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, lastSection]) => {
            const action = val;
            if (lastSection.lastScreen) {
                this._uiDispatchers.setSection(lastSection.lastScreen);
            }
        }));
        this.revokeCancelMainPlan = this.actions$.pipe(ofType("REVOKE_CANCEL_MAINPLAN_UI" /* REVOKE_CANCEL_MAINPLAN_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new RevokeSubscription());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "sendSubscribetionToPlan", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "sendCouponUI", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "addNewCreditCard", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "setDefaultCreditCardUI", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "deleteCreditCardRecuset", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "getBillingInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "getInvoicesListUI", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "changePlanRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "subscribeToAddonsRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "cancelStripeSubsCription", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "goToLastSectionUI", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BillingUiEffects.prototype, "revokeCancelMainPlan", void 0);
