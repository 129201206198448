<div class="main">
  <div class="seaction" style="margin-right: 35px; ">
    <span>
      Android Icon
    </span>
    <app-selector-input-icon
      class="icon-droplist"
      [disabled]="false"
      [show]="true"
      [iconName]="tabcontainer?.icon || 'ic_feeds_books_white_24_dp'"
      (updateItemIconNotify)="updateItemIconEvent($event, tabcontainer)"
    >
    </app-selector-input-icon>
  </div>
  <div class="seaction">
    <span>
      iOS Icon
    </span>
    <app-selector-input-icon
      class="icon-droplist"
      [disabled]="false"
      [show]="true"
      [iconName]="tabcontainer?.icon_ios || 'ic_feeds_books_white_24_dp'"
      (updateItemIconNotify)="updateItemIconIosEvent($event, tabcontainer)"
    >
    </app-selector-input-icon>
  </div>
</div>
