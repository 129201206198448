import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  GET_BUNDLE_METHOD,
  GET_BUNDLES_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_BUNDLE_METHOD,
  CREATE_BUNDLE_REF,
  SET_BUNDLE_METHOD,
  ADD_BUNDLE_PRODUCTS_METHOD,
  REMOVE_BUNDLE_PRODUCTS_METHOD,
  MarketTypes,
  REMOVE_BUNDLE_METHOD
} from './constants';
import { Product } from './Product';
import { StoreMenu } from './StoreMenu';

export interface BundleProduct {
  product_id?: string;
  units?: string;
  price?: string;
  currency?: string;
  period?: string;
  period_repeat?: number;
  timezone?: string;
  balance?: string;
  product?: Product;
  type?: MarketTypes;
}

export interface Bundle {
  id?: string;
  date?: string;
  image?: string;
  modified_date?: string;
  version?: string;
  url?: string;
  group_id?: string;
  price?: string;
  name?: string;
  invitation_message?: string;
  currency?: string;
  desc?: string;
  status?: string;
  photo_id?: string;
  display_name?: string;
  products?: BundleProduct[];
  store_menu_id?: string;
  store_menu?: StoreMenu;
  max_unit?: number;
  area?: string;
  classification?: string;
  category?: string;
  recordIndex?: number;
  reference?: string;
  keywords?: string;
  owner?: string;
}

export interface BundleUI {
  currentPage?: Bundle[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
}

//
export class GetBundleMethod {
  readonly method = GET_BUNDLE_METHOD;
  constructor(public id: string) {}
}

export class GetBundlesMethod {
  readonly method = GET_BUNDLES_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(public direction?: number, public reference?: number) {}
}

export class CreateBundleMethod {
  readonly method = CREATE_BUNDLE_METHOD;
  readonly reference = CREATE_BUNDLE_REF;
  constructor(bundleToBeCreated: Bundle) {
    for (const key in bundleToBeCreated) {
      if (bundleToBeCreated.hasOwnProperty(key)) {
        this[key] = bundleToBeCreated[key];
      }
    }
  }
}

export class SetBundleMethod {
  readonly method = SET_BUNDLE_METHOD;
  constructor(bundleToBeCreated: Bundle) {
    for (const key in bundleToBeCreated) {
      if (bundleToBeCreated.hasOwnProperty(key)) {
        this[key] = bundleToBeCreated[key];
      }
    }
  }
}

export class AddBundleProductsMethod {
  readonly method = ADD_BUNDLE_PRODUCTS_METHOD;
  constructor(
    public bundle_id: string,
    public data: BundleProduct[],
    public clear = 1
  ) {}
}

// data is array of product IDs ["835935","835936"]
export class RemoveBundleProductsMethod {
  readonly method = REMOVE_BUNDLE_PRODUCTS_METHOD;
  constructor(public bundle_id: string, public data: string[]) {}
}

export class RemoveBundleMethod {
  readonly method = REMOVE_BUNDLE_METHOD;
  constructor(public id: string) {}
}
