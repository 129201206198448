import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import * as ProfileMethods from 'src/models/IProfile';

import { StorageService } from 'src/app/core/storage.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import * as MessagesActions from '../messages/message.actions';
import { AuthDispatchers } from './../auth/auth.dispatchers';
import { ProfileDispatchers } from './profile.dispatchers';
import * as ProfileActions from './profile.actions';
import { ProfileActionTypes } from './profile.actions';
import {
  INDIVIDUAL,
  THEME_COLORS,
  NO_FILE_NAME,
  NOT_SUPPORTED_FILE_TYPE,
  MessageTypes,
  FILE_SIZE_EXCEEDED,
  ERROR_NOT_IMAGE,
  PUBLISHING_ERROR
} from 'src/models/constants';
import { RECEIVE_MESSAGE } from '../messages/message.actions';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import { ProfileMiddleware } from './profile.middleware';
import { AppState } from 'src/models/AppState';
import { MainChat } from 'src/models/MainChat';
import { HelperService } from 'src/app/core/helper.service';
import { ReplyMessage } from 'src/models/ReplyMessage';
import { ProfileDetails } from 'src/models/ProfileDetails';
import { Photo } from 'src/models/Photo';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  IProfile,
  GetUserBalance,
  GetUserProductBalance,
  GetContactsNamesFromServer
} from 'src/models/IProfile';
import { ChatMemberDispatchers } from '../chatMembers/chatMember.dispatchers';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class ProfileEffects {
  @Effect({ dispatch: false })
  getContacts = this.actions$.pipe(
    ofType(ProfileActionTypes.CONTACTS_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profilesState]) => {
      const action = <ProfileActions.ContactsReceived>val;

      if (profilesState) {
        this._uiDispatchers.setContactsCount(profilesState.length);
      }
      if (!profilesState || profilesState.length === 0) {
        this._uiDispatchers.setHasNoContacts();
      }

      profilesState.forEach(profile =>
        this._storageService
          .getProfileFromCache(profile.user_id)
          .then(cachedProfile => {
            if (
              cachedProfile &&
              ((profile.version && cachedProfile.version === profile.version) ||
                !profile.version)
            ) {
              action.payload.forEach(contact => {
                if (contact.user_id === profile.user_id) {
                  cachedProfile.relation = contact.relation;
                  cachedProfile.pinned_date = contact.pinned_date;
                  cachedProfile.priority = contact.priority;
                  cachedProfile.pinned = contact.pinned;
                  cachedProfile.mute = contact.mute;
                }
              });
              this._profileDispatcher.contactDetailsFromCache(cachedProfile);
            } else {
              this.getProfileDetails(profile.user_id);
            }
          })
          .catch(err => {
            this.getProfileDetails(profile.user_id);
          })
      );
    })
  );

  @Effect({ dispatch: false })
  userDetailsReceived = this.actions$.pipe(
    ofType(ProfileActionTypes.CONTACT_DETAILS_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profilesState]) => {
      const action = <ProfileActions.ContactDetailsReceived>val;

      const rcvdProfile = profilesState.find(
        profile => profile.user_id === action.payload.user_id
      );
      if (rcvdProfile && (rcvdProfile.contact || rcvdProfile.unknownContact)) {
        this._mainChatDispatchers.mainChatReceived(
          this.getMainChatFromProfile(rcvdProfile)
        );
      }

      // this._storageService.setProfileToCache(
      //   action.payload.user_id,
      //   action.payload
      // );
    })
  );

  @Effect({ dispatch: false })
  userDetailsRcvdFromCache = this.actions$.pipe(
    ofType(ProfileActionTypes.CONTACT_DETAILS_FROM_CACHE),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profilesState]) => {
      const action = <ProfileActions.ContactDetailsFromCache>val;

      const rcvdProfile = profilesState.find(
        profile => profile.user_id === action.payload.user_id
      );
      // Call dispatch chatmember with chatmember rcvd to update data
      if (rcvdProfile && (rcvdProfile.contact || rcvdProfile.unknownContact)) {
        this._mainChatDispatchers.mainChatReceived(
          this.getMainChatFromProfile(rcvdProfile)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  profileDeselected = this.actions$.pipe(
    ofType(ProfileActionTypes.DESELECT_PROFILE),
    map((action: ProfileActions.DeselectProfile) => {
      this._mainChatDispatchers.mainChatDeselected(
        action.id,
        action.lastTypedMessage
      );
    })
  );

  @Effect({ dispatch: false })
  messageReceived = this.actions$.pipe(
    ofType(RECEIVE_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profilesState]) => {
      const action = <MessagesActions.ReceiveMessage>val;

      if (!action.payload.group_id) {
        if (action.payload.sender_id !== this._authDispatcher.getLoginID()) {
          if (
            ProfileMiddleware.isNewProfile(
              action.payload.sender_id,
              profilesState
            )
          ) {
            // need to add the new unknown profile to the MainChats with the last message
            const mainChat: MainChat = {};
            mainChat.id = action.payload.sender_id;
            mainChat.name = action.payload.sender_name;
            mainChat.type = INDIVIDUAL;
            mainChat.unknownContact = true;
            const lastMessage = this._helperService.handleDifferentReplyTypes(
              action.payload
            );
            this._profileDispatcher.unknownProfile(
              action.payload.sender_id,
              true
            );

            this._mainChatDispatchers.mainChatReceived(
              this.initNewMainChat(lastMessage, mainChat)
            );

            this.getProfileDetails(action.payload.sender_id);
          } else if (!action.payload.endOfPage) {
            const profileWithDifferentVersion = profilesState.find(
              prof =>
                prof.user_id === action.payload.sender_id &&
                prof.version &&
                action.payload.sender_version &&
                action.payload.sender_version.indexOf(prof.version) === -1
            );
            if (profileWithDifferentVersion) {
              this.getProfileDetails(action.payload.sender_id);
            }
          }
        } else {
          // the logged in user is the sender
          if (
            ProfileMiddleware.isNewProfile(
              action.payload.receiver_id,
              profilesState
            )
          ) {
            // need to add the new profile to the MainChats with the last message
            const mainChat: MainChat = {};
            mainChat.id = action.payload.receiver_id;
            mainChat.type = INDIVIDUAL;
            mainChat.unknownContact = true;
            const lastMessage = this._helperService.handleDifferentReplyTypes(
              action.payload
            );
            this._profileDispatcher.unknownProfile(
              action.payload.receiver_id,
              true
            );

            this._mainChatDispatchers.mainChatReceived(
              this.initNewMainChat(lastMessage, mainChat)
            );
            //

            this.getProfileDetails(action.payload.receiver_id);
          }
        }
      }
    })
  );

  @Effect({ dispatch: false })
  profileInvalidated = this.actions$.pipe(
    ofType(ProfileActionTypes.PROFILE_INVALIDATED),
    map((action: ProfileActions.ProfileInvalidated) => {
      this.getProfileDetails(action.payload);
    })
  );

  @Effect({ dispatch: false })
  createBot = this.actions$.pipe(
    ofType(ProfileActionTypes.CREATE_BOT),
    map((action: ProfileActions.CreateBot) => {
      this._socketGateway.sendSocketMessage(
        new ProfileMethods.CreateNewBot(action.name)
      );
    })
  );

  @Effect({ dispatch: false })
  cloneBotFromTemplate = this.actions$.pipe(
    ofType(ProfileActionTypes.CLONE_BOT_FROM_TEMPLATE),
    map((action: ProfileActions.CloneBotFromTemplate) => {
      this._socketGateway.sendSocketMessage(
        new ProfileMethods.AddBotAsAdmin(action.userID)
      );
    })
  );

  @Effect({ dispatch: false })
  setBot = this.actions$.pipe(
    ofType(ProfileActionTypes.UPDATE_BOT),
    map((action: ProfileActions.UpdateBot) => {
      if (action.botDetails && action.botDetails.user_id) {
        const profDetails: ProfileDetails = {};
        profDetails.id = action.botDetails.user_id;
        if (action.botDetails.name) {
          profDetails.name = action.botDetails.name;
        }

        profDetails.paid = action.botDetails.paid;

        if (action.botDetails.product) {
          profDetails.product = action.botDetails.product;
        }
        if (
          action.botDetails.filter !== null &&
          action.botDetails.filter !== undefined
        ) {
          profDetails.filter = action.botDetails.filter;
        }
        if (action.botDetails.status) {
          profDetails.status = action.botDetails.status;
        }
        if (action.botDetails.about) {
          profDetails.about = action.botDetails.about;
        }
        if (action.botDetails.email) {
          profDetails.email = action.botDetails.email;
        }
        if (action.botDetails.disallowGroup) {
          profDetails.disallow_group = action.botDetails.disallowGroup;
        }
        if (action.botDetails.photo_id) {
          const photo: Photo = { id: action.botDetails.photo_id };
          profDetails.photo = photo;
        }
        if (
          action.botDetails.isPublic !== null &&
          action.botDetails.isPublic !== undefined
        ) {
          profDetails.is_public = action.botDetails.isPublic;
        }
        if (
          action.botDetails.inline !== null &&
          action.botDetails.inline !== undefined
        ) {
          profDetails.inline = action.botDetails.inline;
        }
        this._socketGateway.sendSocketMessage(
          new ProfileMethods.SetBot(profDetails)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  uploadBotImage = this.actions$.pipe(
    ofType(ProfileActionTypes.UPLOAD_BOT_IMAGE),
    map((action: ProfileActions.UploadBotImage) => {
      if (this.validateFile(action.file, 'image')) {
        this._fileService
          .readFileAsArrayBuffer(action.file.localFile)
          .then(fileAsArrayBuffer => {
            this._uploadGateway
              .uploadWithProgress(
                fileAsArrayBuffer,
                action.file.localFile.type,
                action.file.localFile.name,
                false,
                null,
                null,
                null,
                true,
                null,
                action.bot.user_id
              )
              .subscribe(
                event => {
                  if (event.type === HttpEventType.Response) {
                    const res = <IUploadResponse>event.body;
                    const profile: IProfile = { ...action.bot };
                    profile.photo_id = res.file;
                    this._profileDispatcher.updateBot(profile);
                    profile.image = res.media.permanentUrl;
                    this._profileDispatcher.imageDownloaded(profile);
                  }
                },
                error => {
                  console.log('Failed to upload bot image');
                }
              );
          });
      }
    })
  );

  @Effect({ dispatch: false })
  activateBot = this.actions$.pipe(
    ofType(ProfileActionTypes.ACTIVATE_BOT),
    withLatestFrom(
      this._store
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChatID]) => {
      const action = <ProfileActions.ActivateBot>val;
      this._chatmemberDispatchers.addAdminToChat(mainChatID, action.userID);
    })
  );

  @Effect({ dispatch: false })
  setBotPrivileges = this.actions$.pipe(
    ofType(ProfileActionTypes.UPDATE_ADMIN_PRIVILEGES),
    withLatestFrom(
      this._store
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChatID]) => {
      const action = <ProfileActions.UpdateAdminPrivileges>val;
      this._socketGateway.sendSocketMessage(
        new ProfileMethods.SetAdminPrivileges(
          action.userID,
          mainChatID,
          action.privileges
        )
      );
    })
  );

  @Effect({ dispatch: false })
  requestBotToken = this.actions$.pipe(
    ofType(ProfileActionTypes.REQUEST_BOT_TOKEN),
    withLatestFrom(
      this._store
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChatID]) => {
      const action = <ProfileActions.RequestBotToken>val;
      this._socketGateway.sendSocketMessage(
        new ProfileMethods.GetBotToken(action.botId, mainChatID)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteBot = this.actions$.pipe(
    ofType(ProfileActionTypes.DELETE_BOT),
    map((action: ProfileActions.DeleteBot) => {
      this._socketGateway.sendSocketMessage(
        new ProfileMethods.RemoveBot(action.userID)
      );
    })
  );

  @Effect({ dispatch: false })
  publishBot = this.actions$.pipe(
    ofType(ProfileActionTypes.PUBLISH_BOT),
    map((action: ProfileActions.PublishBot) => {
      if (action.shortName) {
        this._socketGateway.sendSocketMessage(
          new ProfileMethods.PublishBot(action.id, action.shortName)
        );
      } else {
        this._socketGateway.sendSocketMessage(
          new ProfileMethods.PublishBot(action.id)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  receiveError = this.actions$.pipe(
    ofType(ProfileActionTypes.RECEIVE_PUBLISH_BOT_ERROR),
    map((action: ProfileActions.ReceivePublishBotError) => {
      this._uiDispatchers.showPopup(
        SERVER_ERROR_MESSAGES[action.errorNumber],
        PUBLISHING_ERROR
      );
    })
  );

  //
  @Effect({ dispatch: false })
  requestUserBalance = this.actions$.pipe(
    ofType(ProfileActionTypes.REQUEST_USER_BALANCE),
    map((action: ProfileActions.RequestUserBalance) => {
      this._socketGateway.sendSocketMessage(new GetUserBalance(action.userID));
    })
  );

  @Effect({ dispatch: false })
  requestUserProductBalance = this.actions$.pipe(
    ofType(ProfileActionTypes.REQUEST_USER_PRODUCT_BALANCE),
    map((action: ProfileActions.RequestUserProductBalance) => {
      this._socketGateway.sendSocketMessage(
        new GetUserProductBalance(action.userID)
      );
    })
  );

  @Effect({ dispatch: false })
  getContactsNames = this.actions$.pipe(
    ofType(ProfileActionTypes.GET_CONTACTS_NAMES),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profilesState]) => {
      const action = <ProfileActions.GetContactsNames>val;

      const contacts = profilesState.map(res => res.user_id);
      // this._profileDispatcher.getContactsNames(contacts);
      let items = [];
      let contactsArrys = [];
      contacts.map(res => {
        if (res && items.length <= 50) {
          items.push(res);
        }
        if (items.length == 50) {
          contactsArrys.push(items);
          items = [];
        }
      });

      if (items.length > 0) {
        contactsArrys.push(items);
        items = [];
      }

      contactsArrys.forEach(res => {
        this.getContactsNamesMethods(res);
      });
      // this._socketGateway.sendSocketMessage(
      //   new GetContactsNamesFromServer(action.contacts)
      // );
    })
  );

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }

  private getProfileDetails(userID: string) {
    if (userID) {
      this._socketGateway.sendSocketMessage(new ProfileMethods.GetUser(userID));
    }
  }

  private getContactsNamesMethods(contacts: string[]) {
    this._socketGateway.sendSocketMessage(
      new GetContactsNamesFromServer(contacts)
    );
  }

  private getMainChatFromProfile(profile: ProfileMethods.IProfile): MainChat {
    const mainChat: MainChat = {};
    mainChat.id = profile.user_id;
    mainChat.name = profile.name;
    mainChat.image = profile.image;
    mainChat.imageThumbnail = profile.imageThumbnail;
    mainChat.status = profile.status;
    mainChat.priority = profile.priority;
    mainChat.pinned_date = profile.pinned_date;
    mainChat.backgroundColor = profile.backgroundColor;
    mainChat.last_seen = profile.last_seen;
    mainChat.type = INDIVIDUAL;
    mainChat.online = profile.online;
    mainChat.detailsReceived = profile.detailsReceived;
    mainChat.lastTypedMessage = profile.lastTypedMessage;
    mainChat.contact = profile.contact;
    mainChat.unknownContact = profile.unknownContact;
    return mainChat;
  }

  private initNewMainChat(
    lastMessage: ReplyMessage,
    mainChat: MainChat
  ): MainChat {
    mainChat.lastReplyID = lastMessage.lastReplyID;
    mainChat.lastReplyReference = lastMessage.lastReplyReference;
    mainChat.lastMsgTime = lastMessage.lastReplyTime;
    mainChat.lastReplyMessage = lastMessage.lastReplyMessage;
    mainChat.lastReplyStatus = lastMessage.lastReplyStatus;
    mainChat.lastReplyType = lastMessage.lastReplyType;
    mainChat.backgroundColor =
      THEME_COLORS[Math.floor(Math.random() * THEME_COLORS.length)];
    return mainChat;
  }

  constructor(
    private actions$: Actions,
    private _profileDispatcher: ProfileDispatchers,
    private _socketGateway: SocketGateway,
    private _authDispatcher: AuthDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _storageService: StorageService,
    private _mainChatDispatchers: MainChatDispatchers,
    private _chatmemberDispatchers: ChatMemberDispatchers,
    private _helperService: HelperService,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _store: Store<AppState>
  ) {}
}
