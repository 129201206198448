import { Store } from '@ngrx/store';
import * as TagsActions from './tags.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class TagsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    tagsReceived(payload, id) {
        const { chat } = payload;
        this._store.dispatch(new TagsActions.TagsReceived(chat, id));
    }
}
TagsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagsDispatchers_Factory() { return new TagsDispatchers(i0.ɵɵinject(i1.Store)); }, token: TagsDispatchers, providedIn: "root" });
