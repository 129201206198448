import { Bundle } from './Bundle';
import { Product } from 'src/models/Product';

import {
  GET_PACKAGE_METHOD,
  GET_PACKAGES_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_PACKAGE_METHOD,
  CREATE_PACKAGE_REF,
  SET_PACKAGE_METHOD,
  ADD_PACKAGE_ITEMS_METHOD,
  REMOVE_PACKAGE_ITEMS_METHOD,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  REMOVE_PACKAGE
} from './constants';

export interface Package {
  id?: string;
  items_type?: number;
  date?: string;
  image?: string;
  modified_date?: string;
  photo_id?: string;
  version?: string;
  url?: string;
  group_id?: string;
  price?: string;
  name?: string;
  invitation_message?: string;
  currency?: string;
  desc?: string;
  status?: string;
  items?: PackageItem[];
  bundles?: Bundle[];
  products?: Product[];
  style?: number;
  area?: string;
  classification?: string;
  category?: string;
  type?: string;
  recordIndex?: number;
  reference?: string;
  keywords?: string;
  owner?: string;
}

export interface PackageItem {
  id?: string;
  type?: number;
}

export interface PackageUI {
  currentPage?: Package[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
}

export class GetPackageMethod {
  readonly method = GET_PACKAGE_METHOD;
  constructor(public id: string) {}
}

export class GetPackagesMethod {
  readonly method = GET_PACKAGES_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(public direction?: number, public reference?: number) {}
}

export class GetPackagesAfterDeleteMethod {
  readonly method = GET_PACKAGES_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(public direction?: 0, public reference?: 0) {}
}

export class CreatePackageMethod {
  readonly method = CREATE_PACKAGE_METHOD;
  readonly reference = CREATE_PACKAGE_REF;
  constructor(packageToBeCreated: Package) {
    for (const key in packageToBeCreated) {
      if (packageToBeCreated.hasOwnProperty(key)) {
        this[key] = packageToBeCreated[key];
      }
    }
  }
}

export class SetPackageMethod {
  readonly method = SET_PACKAGE_METHOD;
  constructor(packagetoBeUpdated: Package) {
    for (const key in packagetoBeUpdated) {
      if (packagetoBeUpdated.hasOwnProperty(key)) {
        this[key] = packagetoBeUpdated[key];
      }
    }
  }
}

// data is string[] of Bundle IDs
export class AddPackageBundlesMethod {
  readonly method = ADD_PACKAGE_ITEMS_METHOD;
  constructor(
    public package_id: string,
    public data: PackageItem[],
    public clear = 1
  ) {}
}

// data is array of bundle IDs ["835935","835936"]
export class RemovePackageBundlesMethod {
  readonly method = REMOVE_PACKAGE_ITEMS_METHOD;
  constructor(public package_id: string, public data: string[]) {}
}

export class RemovePackageMethod {
  readonly method = REMOVE_PACKAGE;
  constructor(public id: string) {}
}
