import { Action } from '@ngrx/store/public_api';
import { LocalAppInfo } from 'src/models/ChannelAppLocalConfig';

export const enum AppLocalInfoActionTypes {
  UPDATE_APP_LOCAL_INFO = 'UPDATE_APP_LOCAL_INFO',
  RESET = 'RESET'
}

export class UpdateAppLocalInfo implements Action {
  readonly type = AppLocalInfoActionTypes.UPDATE_APP_LOCAL_INFO;
  constructor(public localAppInfo: LocalAppInfo) {}
}

export class Reset implements Action {
  readonly type = AppLocalInfoActionTypes.RESET;
}

export type Actions = UpdateAppLocalInfo | Reset;
