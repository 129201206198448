import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { CHANNELS_THEME_COLOR, UISections } from 'src/models/constants';
import { UiService } from 'src/app/core/ui.service';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

@Component({
  selector: 'app-messages-admin',
  templateUrl: './messages-admin.component.html',
  styleUrls: ['./messages-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesAdminComponent implements OnInit {
  themeColor = CHANNELS_THEME_COLOR;
  uiSections: typeof UISections = UISections;

  constructor(
    public uiService: UiService,
    public dashboardService: DashboardService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {}

  backFromGroupReplies() {
    this.uiService.backFromGroupReplies();
  }
  backFromOneToOneReply() {
    this.uiService.backFromOneToOneReply();
  }

  sendReplyMediaPreview(mediaToSend: any) {
    this.uiService.sendMediaMessage(
      mediaToSend.selectedChat,
      mediaToSend.selectedMessage,
      mediaToSend.selectedUserId,
      mediaToSend.files,
      mediaToSend.messageType,
      mediaToSend.mediaMetadata
    );
    this.uiService.hidePreview();
  }
}
