import { Action } from '@ngrx/store';
import {
  MapTripsNotifiers,
  MapTripsNotifiersUI
} from 'src/models/mapTripsNotifiers';

export const enum MapTripsNotifiersActionTypes {
  UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING = 'UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING',
  UI_CREATE_MAP_ACCOUNT_LINKS = 'UI_CREATE_MAP_ACCOUNT_LINKS',
  UI_MAP_ACCOUNT_LINKS_CREATED = 'UI_MAP_ACCOUNT_LINKS_CREATED',
  UI_MAP_ACCOUNT_LINKS_LIST_RECEIVED = 'UI_MAP_ACCOUNT_LINKS_LIST_RECEIVED',
  UI_GET_TRIPS_NOTIFIERS = 'UI_GET_TRIPS_NOTIFIERS',
  UI_GET_NOTIFIERS_LIST_RESET_PAGING = 'UI_GET_NOTIFIERS_LIST_RESET_PAGING',
  UI_GET_NOTIFIERS_LIST_NEXT_PAGE = 'UI_GET_NOTIFIERS_LIST_NEXT_PAGE',
  UI_GET_NOTIFIERS_LIST_PREV_PAGE = 'UI_GET_NOTIFIERS_LIST_PREV_PAGE',
  UI_NOTIFIERS_LIST_RECEIVED = 'UI_NOTIFIERS_LIST_RECEIVED',
  UI_REMOVE_ACCOUNT_LINK = 'UI_REMOVE_ACCOUNT_LINK',
  UI_REST_NOTIFIERS_LIST = 'UI_REST_NOTIFIERS_LIST',
  UI_REMOVE_ACCOUNT_LINK_SUCCESS = 'UI_REMOVE_ACCOUNT_LINK_SUCCESS',
  UI_UPDATE_ACCOUNT_LINK_SUCCESS = 'UI_UPDATE_ACCOUNT_LINK_SUCCESS',
  UI_SET_MAP_ACCOUNT_LINKS_COUNT = 'UI_SET_MAP_ACCOUNT_LINKS_COUNT'
}

export class ResetMapTripsNotifiersPaging implements Action {
  readonly type =
    MapTripsNotifiersActionTypes.UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING;
  constructor() {}
}
export class GetTripsNotifiers implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_GET_TRIPS_NOTIFIERS;
  constructor() {}
}

export class CreateMapAccountLinks implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_CREATE_MAP_ACCOUNT_LINKS;
  constructor(public tripNotifier: MapTripsNotifiers) {}
}

export class MapAccountLinksCreated implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_MAP_ACCOUNT_LINKS_CREATED;
  constructor(public tripNotifier: MapTripsNotifiers[]) {}
}
export class MapAccountLinksListReceived implements Action {
  readonly type =
    MapTripsNotifiersActionTypes.UI_MAP_ACCOUNT_LINKS_LIST_RECEIVED;
  constructor(public accountID: MapTripsNotifiers[]) {}
}
export class ResetNotifiersListPaging implements Action {
  readonly type =
    MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_RESET_PAGING;
  constructor(public accountID: string) {}
}

export class GetNextNotifiersPage implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_NEXT_PAGE;
  constructor() {}
}
export class GetPrevNotifiersPage implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_PREV_PAGE;
  constructor() {}
}

export class RemoveAccountLink implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_REMOVE_ACCOUNT_LINK;
  constructor(public id: string) {}
}

export class RemoveAccountLinkSuccess implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_REMOVE_ACCOUNT_LINK_SUCCESS;
  constructor(public id: string) {}
}

export class UpdateMapAccountLinks implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_UPDATE_ACCOUNT_LINK_SUCCESS;
  constructor(public accountID: string, public link_ids: string[]) {}
}

export class SetMapAccountLinksCount implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_SET_MAP_ACCOUNT_LINKS_COUNT;
  constructor(public accountID: string, public count: number) {}
}

export class RestNotifiersList implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_REST_NOTIFIERS_LIST;
}

export class NotifiersListReceived implements Action {
  readonly type = MapTripsNotifiersActionTypes.UI_NOTIFIERS_LIST_RECEIVED;
  constructor(
    public eop?: number,
    public sop?: number,
    public tripNotifiers?: MapTripsNotifiers[],
    public reference?: string
  ) {}
}

export type Actions =
  | ResetMapTripsNotifiersPaging
  | GetTripsNotifiers
  | MapAccountLinksCreated
  | MapAccountLinksListReceived
  | ResetNotifiersListPaging
  | GetNextNotifiersPage
  | GetPrevNotifiersPage
  | NotifiersListReceived
  | RestNotifiersList
  | RemoveAccountLinkSuccess
  | SetMapAccountLinksCount;
