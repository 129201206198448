import { MapTripUser, MapTripUserUI } from 'src/models/MapTripUser';
import { Actions } from './mapTripUser.actions';

import * as actions from './mapTripUser.actions';
import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';

const INITIAL_STATE: MapTripUserUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  tripID: ''
};

export function mapTripUserReducer(
  state = INITIAL_STATE,
  action: Actions
): MapTripUserUI {
  switch (action.type) {
    case actions.MapTripUserActionTypes.RESET_MAP_TRIP_USERS_PAGING:
      return { ...INITIAL_STATE, tripID: action.tripID };
    case actions.MapTripUserActionTypes.GET_NEXT_MAP_TRIP_USERS_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    }
    case actions.MapTripUserActionTypes.GET_PREV_MAP_TRIP_USERS_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV
      };
    }
    case actions.MapTripUserActionTypes.MAP_TRIP_USER_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.mapTripUsers && action.mapTripUsers.length) > 0
            ? action.mapTripUsers
            : state.currentPage
      };
    }
    case actions.MapTripUserActionTypes.MAP_TRIP_USER_DETAILS_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(mapTripUser => {
          if (mapTripUser.id === action.mapTripUser.id) {
            return action.mapTripUser;
          }
          return mapTripUser;
        })
      };
    }
    case actions.MapTripUserActionTypes.MAP_TRIP_USER_CREATED: {
      if (
        !state.currentPage.find(
          mapSetting => mapSetting.id === action.mapTripUser.id
        )
      ) {
        return {
          ...state,
          currentPage: [action.mapTripUser, ...state.currentPage]
        };
      } else {
        return state;
      }
    }
    case actions.MapTripUserActionTypes.MAP_TRIP_USER_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          mapTripUser => mapTripUser.id !== action.id
        )
      };
    case actions.MapTripUserActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
