import { Store } from '@ngrx/store';
import * as MyBookingsActions from './myBookings.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MyBookingsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveMyBookings(myBookings) {
        this._store.dispatch(new MyBookingsActions.ReceiveMyBookings(myBookings));
    }
    requestMyBookings(chatID, date) {
        this._store.dispatch(new MyBookingsActions.RequestMyBookings(chatID, date));
    }
    RequestMyBookingDays(chatID, startDate, endDate) {
        this._store.dispatch(new MyBookingsActions.RequestMyBookingDays(chatID, startDate, endDate));
    }
    ReceiveMyBookingDays(chatID, bookedDates) {
        this._store.dispatch(new MyBookingsActions.ReceiveMyBookingDays(chatID, bookedDates));
    }
    cancelMyBookingReservation(chat_id, user_id, reference, date, time) {
        this._store.dispatch(new MyBookingsActions.CancelMyBookingReservation(chat_id, user_id, reference, date, time));
    }
    myBookingStatusUpdated(chat_id, user_id, reference, date, time, status, cancelable) {
        this._store.dispatch(new MyBookingsActions.MyBookingStatusUpdated(chat_id, user_id, reference, date, time, status, cancelable));
    }
    myBookingsUsersReceived(userNames) {
        this._store.dispatch(new MyBookingsActions.MyBookingsUsersReceived(userNames));
    }
    receivedMyBookingsError(errorCode) {
        this._store.dispatch(new MyBookingsActions.ReceivedMyBookingsError(errorCode));
    }
}
MyBookingsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyBookingsDispatchers_Factory() { return new MyBookingsDispatchers(i0.ɵɵinject(i1.Store)); }, token: MyBookingsDispatchers, providedIn: "root" });
