import { Store } from '@ngrx/store';
import * as ProductStoreUIActions from './product-store.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ProductsStoreDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetProductsStorePaging() {
        this._store.dispatch(new ProductStoreUIActions.ResetProductsStoreUIPaging());
    }
    getNextProductsStorePage() {
        this._store.dispatch(new ProductStoreUIActions.GetNextProductsStorePage());
    }
    getPrevProductsStorePage() {
        this._store.dispatch(new ProductStoreUIActions.GetPrevProductsStorePage());
    }
    receivedProductsStoreHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new ProductStoreUIActions.ReceivedProductsStoreHistoryChunk(eop, sop, currentPage));
    }
    setProductStoreItem(product) {
        this._store.dispatch(new ProductStoreUIActions.SetProductStoreItem(product));
    }
    setSelectedProductStore(product) {
        this._store.dispatch(new ProductStoreUIActions.SetSelectedProductStore(product));
    }
    getProductItem(id) {
        this._store.dispatch(new ProductStoreUIActions.GetProductItem(id));
    }
    receivedProductStore(product) {
        this._store.dispatch(new ProductStoreUIActions.ReceivedProductStore(product));
    }
    setProductsItemArray(product, parent_id, type) {
        this._store.dispatch(new ProductStoreUIActions.SetProductsItemArray(product, parent_id, type));
    }
    removeProductItem(id) {
        this._store.dispatch(new ProductStoreUIActions.RemoveProductItem(id));
    }
    removeProductItemSuccess(id) {
        this._store.dispatch(new ProductStoreUIActions.RemoveProductItemSuccess(id));
    }
    setProductItemArraySuccess(id, product) {
        this._store.dispatch(new ProductStoreUIActions.SetProductItemArraySuccess(id, product));
    }
    setStatusAsIdle() {
        this._store.dispatch(new ProductStoreUIActions.SetStatusAsIdle());
    }
    setCollectionsToProduct(productID, collections) {
        this._store.dispatch(new ProductStoreUIActions.SetCollectionsToProduct(productID, collections));
    }
}
ProductsStoreDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsStoreDispatchers_Factory() { return new ProductsStoreDispatchers(i0.ɵɵinject(i1.Store)); }, token: ProductsStoreDispatchers, providedIn: "root" });
