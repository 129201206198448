import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BillingUiDispatchers } from 'src/app/store/billing/billingUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "@angular/router";
import * as i3 from "../../../store/billing/billingUI.dispatchers";
// import { BillingsPageScreens } from 'src/models/constants';
export class BillingsService {
    constructor(_selectors, router, _billingUiDispatchers) {
        this._selectors = _selectors;
        this.router = router;
        this._billingUiDispatchers = _billingUiDispatchers;
        this._lastScreen = new BehaviorSubject('');
        this.lastScreen$ = this._lastScreen.asObservable();
        this._currentSelectedPlan = new BehaviorSubject('');
        this.currentSelectedPlan$ = this._currentSelectedPlan.asObservable();
        this._confirmSubscribtion = new BehaviorSubject('');
        this.confirmSubscribtion$ = this._confirmSubscribtion.asObservable();
    }
    setCurrentScreen(screen, lastScreen) {
        this._billingUiDispatchers.setCurrentScreen(screen, lastScreen);
    }
    setCurrentSelectedPlan(plan) {
        this._billingUiDispatchers.setCurrentSelectedPlan(plan);
    }
    toggleConfirmPopup(data) {
        this._confirmSubscribtion.next(data);
        this._billingUiDispatchers.receveCouponResponse(null);
    }
    sendSubscribetionToPlan(subscribeDetails, lm_data) {
        this._billingUiDispatchers.sendSubscribetionToPlan(subscribeDetails, lm_data);
    }
    sendCouponToServer(coupon) {
        this._billingUiDispatchers.sendCoupon(coupon);
    }
    addNewCreditCard(token) {
        this._billingUiDispatchers.addNewCreditCard(token);
    }
    deleteCard(card_id) {
        this._billingUiDispatchers.deleteCreditCardUI(card_id);
    }
    setDefaultCreditCard(card_id) {
        this._billingUiDispatchers.setDefaultCardUI(card_id);
    }
    receveStripeErrorMasage(msg) {
        this._billingUiDispatchers.receveStripeErrorMasage(msg);
    }
    setBillingStatus(status) {
        this._billingUiDispatchers.setBillingStatus(status);
    }
    subscribeToAddonsRequest(plan_id, coupon) {
        this._billingUiDispatchers.subscribeToAddonsRequest(plan_id, coupon);
    }
    getBillingInfoUI() {
        this._billingUiDispatchers.getBillingInfoUI();
    }
    getInvoicesListUI() {
        this._billingUiDispatchers.getInvoicesListUI();
    }
    changePlanRequest(new_plan_id, coupon) {
        this._billingUiDispatchers.changePlanRequest(new_plan_id, coupon);
    }
    cancelStripeSubsCription(all, plan_id, reason, subMain, cancelQuantity) {
        this._billingUiDispatchers.cancelStripeSubsCription(all, plan_id, reason, subMain, cancelQuantity);
    }
    setBillingPeriodStatus(status) {
        this._billingUiDispatchers.setBillingPeriodStatus(status);
    }
    canActivate(route, state) {
        let planId = '';
        this.currentSelecetedPlan$.subscribe(res => (planId = res));
        if (!planId ||
            planId === 'plan_4' ||
            planId === 'plan_1' ||
            planId === 'plan_2' ||
            planId === 'plan_3') {
            this.router.navigate(['/billings/plans']);
            return false;
        }
        else {
            return true;
        }
    }
    revokeCancelMainPlan() {
        this._billingUiDispatchers.revokeCancelMainPlan();
    }
    get getCurrentScreen$() {
        return this._selectors.currentSelecetedBillingScreen$;
    }
    get getcurrentSelecetedPlan$() {
        return this._selectors.currentSelecetedBillingPlan$;
    }
    get getStripeErrorMessage$() {
        return this._selectors.getStripeErrorMessage$;
    }
    get getBillingStatus$() {
        return this._selectors.getBillingStatus$;
    }
    get getBillingCoupon$() {
        return this._selectors.getBillingCoupon$;
    }
    get getBillingInvoices$() {
        return this._selectors.getBillingInvoices$;
    }
    get getBillingInfo$() {
        return this._selectors.getBillingInfo$;
    }
    get getBillingMainPlan$() {
        return this._selectors.getBillingMainPlan$;
    }
    get currentSelecetedPlan$() {
        return this._selectors.currentSelecetedPlan$;
    }
    get getBillingPeriodStatus$() {
        return this._selectors.getBillingPeriodStatus$;
    }
    get getBillingSubscriptions$() {
        return this._selectors.getBillingSubscriptions$;
    }
    get isMainPlanCanceled$() {
        return this._selectors.isMainPlanCanceled$;
    }
    get getBillingsEndTriles$() {
        return this._selectors.getBillingsEndTriles$;
    }
    get getMainPlanStatus$() {
        return this._selectors.getMainPlanStatus$;
    }
}
BillingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BillingsService_Factory() { return new BillingsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.BillingUiDispatchers)); }, token: BillingsService, providedIn: "root" });
