import { environment } from './environments/environment';

// hotjar
if (environment.hotjar) {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: environment.hotjar, hjsv: 6 };
    a = o.getElementsByTagName('footer')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

// inspectlet;
if (environment.inspectlet) {
  (() => {
    window.__insp = window.__insp || [];
    window.__insp.push(['wid', environment.inspectlet]);
    const ldinsp = () => {
      if (typeof window.__inspld != 'undefined') {
        return;
      }
      window.__inspld = 1;
      const insp = document.createElement('script');
      insp.type = 'text/javascript';
      insp.async = true;
      insp.id = 'inspsync';
      insp.src =
        ('https:' == document.location.protocol ? 'https' : 'http') +
        '://cdn.inspectlet.com/inspectlet.js?wid=452822201&r=' +
        Math.floor(new Date().getTime() / 3600000);
      const x = document.getElementsByTagName('footer')[0];
      x.parentNode.insertBefore(insp, x);
    };
    setTimeout(ldinsp, 0);
  })();
}
