import { UiService } from 'src/app/core/ui.service';
import { SummaryScreens, UISections, UserStatus } from 'src/models/constants';
import { BlacklistService } from '../blacklist/blacklist.service';
import { BookingsService } from '../bookings/bookings.service';
import { BundlesService } from '../bundles/bundles.service';
import { CalendarsService } from '../calendars/calendars.service';
import { EventsService } from '../events/events.service';
import { MembersService } from '../members/members.service';
import { MobileStoresService } from '../mobile-store/mobile-store.service';
import { PackagesService } from '../packages/packages.service';
import { ProductsService } from '../products/products.service';
import { StoreMenuService } from '../store-menu/store-menu.service';
import { SubChatsService } from '../sub-chats/sub-chats.service';
import { WhitelistService } from '../white-list/white-list.service';
import { SummaryUIDispatchers } from 'src/app/store/summaryUI/summaryUI.dispatchers';
import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { DashboardService } from '../dashboard.service';
import { ChartDispatchers } from 'src/app/store/chartStore/chart.dispatchers';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../members/members.service";
import * as i2 from "../bookings/bookings.service";
import * as i3 from "../events/events.service";
import * as i4 from "../calendars/calendars.service";
import * as i5 from "../mobile-store/mobile-store.service";
import * as i6 from "../products/products.service";
import * as i7 from "../bundles/bundles.service";
import * as i8 from "../packages/packages.service";
import * as i9 from "../store-menu/store-menu.service";
import * as i10 from "../blacklist/blacklist.service";
import * as i11 from "../../../core/ui.service";
import * as i12 from "../sub-chats/sub-chats.service";
import * as i13 from "../white-list/white-list.service";
import * as i14 from "../../../store/summaryUI/summaryUI.dispatchers";
import * as i15 from "../../../store/root-store.selectors";
import * as i16 from "../dashboard.service";
import * as i17 from "../../../store/chartStore/chart.dispatchers";
import * as i18 from "../../../store/messages/message.dispatchers";
export class SummaryService {
    constructor(memberService, bookingService, eventService, calendarService, storesService, productService, bundleService, packagesService, storeMenuService, blacklistService, uiService, subChatsService, whitelistService, summaryUIDispatchers, _storeSelector, _dashboardService, chartUIDispatchers, _messageDispatchers) {
        this.memberService = memberService;
        this.bookingService = bookingService;
        this.eventService = eventService;
        this.calendarService = calendarService;
        this.storesService = storesService;
        this.productService = productService;
        this.bundleService = bundleService;
        this.packagesService = packagesService;
        this.storeMenuService = storeMenuService;
        this.blacklistService = blacklistService;
        this.uiService = uiService;
        this.subChatsService = subChatsService;
        this.whitelistService = whitelistService;
        this.summaryUIDispatchers = summaryUIDispatchers;
        this._storeSelector = _storeSelector;
        this._dashboardService = _dashboardService;
        this.chartUIDispatchers = chartUIDispatchers;
        this._messageDispatchers = _messageDispatchers;
        this.userStatus = UserStatus;
        this.sections = UISections;
        this.featuresIds = [];
        this._currentSummaryScreen = new BehaviorSubject(SummaryScreens.SUMMARY_VIEW);
        this.currentSummaryScreen$ = this._currentSummaryScreen.asObservable();
    }
    setCurrentScreen(screen) {
        this._currentSummaryScreen.next(screen);
    }
    resetPaging(card) {
        switch (card.section) {
            case this.sections.POSTS:
                this._messageDispatchers.getLastPostsHistory();
                this.uiService.setPostsPageNumber(1);
                break;
            case this.sections.USERS:
                this.memberService.resetPaging(null, this.userStatus.ACTIVE);
                break;
            case this.sections.SUBCHANNEL:
                this.subChatsService.resetPaging(card.section);
                break;
            case this.sections.SUBGROUP:
                this.subChatsService.resetPaging(card.section);
                break;
            case this.sections.BOOKING:
                this.bookingService.resetPaging();
                break;
            case this.sections.EVENTS:
                this.eventService.resetPaging();
                break;
            case this.sections.CALENDAR:
                this.calendarService.resetPaging();
                break;
            case this.sections.MSTORE:
                this.storesService.resetPaging();
                break;
            case this.sections.PRODUCTS:
                this.productService.resetPaging();
                break;
            case this.sections.BUNDLES:
                this.bundleService.resetPaging();
                break;
            case this.sections.PACKAGES:
                this.packagesService.resetPaging();
                break;
            case this.sections.STOREMENU:
                this.storeMenuService.resetPaging();
                break;
            case this.sections.BLACKLIST:
                this.blacklistService.resetPaging(null);
                break;
            case this.sections.WHITELIST:
                this.whitelistService.resetWhitelistPaging(null);
                break;
        }
    }
    editItemEvent(item, section) {
        this._dashboardService.setSection(section);
        switch (section) {
            case this.sections.POSTS:
                break;
            case this.sections.USERS:
                this.memberService.selectMemberEvent(item);
                break;
            case this.sections.SUBCHANNEL:
                this.subChatsService.showEditSubGroupScreen(item);
                break;
            case this.sections.SUBGROUP:
                this.subChatsService.showEditSubGroupScreen(item);
                break;
            case this.sections.BOOKING:
                setTimeout(() => this.bookingService.showEditBookingScreen(item), 100);
                break;
            case this.sections.EVENTS:
                this.eventService.showEditEventScreen(item);
                break;
            case this.sections.CALENDAR:
                this.calendarService.showEditCalendarScreen(item);
                break;
            case this.sections.MSTORE:
                this.storesService.updateItemEvent(item);
                break;
            case this.sections.PRODUCTS:
                this.productService.updateItemEvent(item);
                break;
            case this.sections.BUNDLES:
                this.bundleService.updateItemEvent(item);
                break;
            case this.sections.PACKAGES:
                this.packagesService.updateItemEvent(item);
                break;
            case this.sections.STOREMENU:
                setTimeout(() => this.storeMenuService.updateItemEvent(item), 100);
                break;
            case this.sections.BLACKLIST:
                break;
            case this.sections.WHITELIST:
                break;
        }
    }
    previewLinkMessage(massage) {
        this.uiService.previewLinkMessage(massage);
    }
    get dashboardList$() {
        return this._storeSelector.dashboardList$;
    }
    createNewWidget(widget) {
        this.resetPaging(widget);
        this.summaryUIDispatchers.createNewWidget(widget);
    }
    setDashboard(dashboard) {
        this.summaryUIDispatchers.setDashboard(dashboard);
    }
    reOrderList(widgets) {
        this.summaryUIDispatchers.reOrderList(widgets);
    }
    updateDashboardList() {
        this.summaryUIDispatchers.updateDashboardList();
        this.setCurrentScreen(SummaryScreens.SUMMARY_VIEW);
    }
    updateWidget(widget) {
        this.summaryUIDispatchers.updateWidget(widget);
    }
    removeWidget(widget) {
        this.summaryUIDispatchers.removeWidget(widget);
    }
    getChartData(chat_id, chart_type, interval, startDate, endDate) {
        this.chartUIDispatchers.getChartData(chat_id, chart_type, interval, startDate, endDate);
    }
    getChartData$(type) {
        return this._storeSelector.getChartData$(type);
    }
    getChartDataStatus$(type) {
        return this._storeSelector.getChartDataStatus$(type);
    }
}
SummaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SummaryService_Factory() { return new SummaryService(i0.ɵɵinject(i1.MembersService), i0.ɵɵinject(i2.BookingsService), i0.ɵɵinject(i3.EventsService), i0.ɵɵinject(i4.CalendarsService), i0.ɵɵinject(i5.MobileStoresService), i0.ɵɵinject(i6.ProductsService), i0.ɵɵinject(i7.BundlesService), i0.ɵɵinject(i8.PackagesService), i0.ɵɵinject(i9.StoreMenuService), i0.ɵɵinject(i10.BlacklistService), i0.ɵɵinject(i11.UiService), i0.ɵɵinject(i12.SubChatsService), i0.ɵɵinject(i13.WhitelistService), i0.ɵɵinject(i14.SummaryUIDispatchers), i0.ɵɵinject(i15.RootStoreSelectors), i0.ɵɵinject(i16.DashboardService), i0.ɵɵinject(i17.ChartDispatchers), i0.ɵɵinject(i18.MessageDispatchers)); }, token: SummaryService, providedIn: "root" });
