import { ChatThemesUI, DefulatStyle } from 'src/models/chatThemes';
import * as ChatThemesActions from './chatThemes.actions';

const INITIAL_STATE: ChatThemesUI = {
  selectedTheme: DefulatStyle
};

export function chatThemesReducer(
  state = INITIAL_STATE,
  action: ChatThemesActions.ChatThemesAction
): ChatThemesUI {
  switch (action.type) {
    case ChatThemesActions.ChatThemesActionTypes.RECEVE_CHAT_THEME_UI:
      return {
        ...state,
        selectedTheme: action.style ? { ...action.style } : state.selectedTheme
      };
    case ChatThemesActions.ChatThemesActionTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
