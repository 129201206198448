import {
  CREATE_MAP_ACCOUNT_LINKS_METHOD,
  GET_MAP_LINKED_ACCOUNTS_METHOD,
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS,
  GET_MAP_ACCOUNT_LINKS_METHOD,
  MERCHANT_PAGE_SIZE,
  REMOVE_MAP_ACCOUNT_LINK_METHOD,
  SET_MAP_ACCOUNT_LINKS_METHOD
} from './constants';

export interface MapTripsNotifiersUI {
  currentPage?: MapTripsNotifiers[];
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  selected_chat?: string;
  currentStateScreen?: string;
  accountID?: string;
  notifiersList?: MapTripsNotifiers[];
}

export interface MapTripsNotifiers {
  id?: string;
  account_id?: string;
  link_ids?: string[];
  link_id?: string;
  notifiers_count?: number;
}

export class GetMapTripsNotifiers {
  readonly method = GET_MAP_LINKED_ACCOUNTS_METHOD;
  constructor(public direction?: number, public reference?: string) {}
}
export class RemoveAccountLink {
  readonly method = REMOVE_MAP_ACCOUNT_LINK_METHOD;
  constructor(public id?: string, public reference?: string) {}
}

export class GetNotifiersList {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_ACCOUNT_LINKS_METHOD;
  constructor(
    public account_id?: string,
    public direction?: number,
    public reference?: number
  ) {}
}

export class CreateMapTripNotifiers {
  readonly method = CREATE_MAP_ACCOUNT_LINKS_METHOD;
  constructor(
    public account_id: string,
    public link_ids: string[],
    public reference?: string
  ) {}
}
export class UpdateMapTripNotifiers {
  readonly method = SET_MAP_ACCOUNT_LINKS_METHOD;
  constructor(
    public account_id: string,
    public link_ids: string[],
    public reference?: string
  ) {}
}
