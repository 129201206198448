import {
  GET_COUPONS_METHOD,
  GET_COUPON_METHOD,
  CREATE_COUPON_METHOD,
  UPDATE_COUPON_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_COUPON_REF,
  UPDATE_COUPON_REF,
  CREATE_COUPON_POPUP_REF
} from './constants';

export interface ICoupon {
  // for local use only
  isSelected?: boolean;
  reference?: number;
  // from server
  code?: string;
  groupId?: string; // groupId of selected chat
  expire?: Date;
  name?: string;
  description?: string;
  couponId?: string;
  status?: string;
  share?: number;
  value?: number;
  expireDateStr?: Date;
}

export interface OutCouponMessage {
  method?: string;
  reference?: number;
  code?: string;
  groupId?: string; // groupId of selected chat
  expire?: string;
  name?: string;
  description?: string;
  couponId?: string;
  status?: string;
  share?: number;
  value?: number;
}

export interface RcvdCouponMessage {
  reference?: number;
  coupon: {
    code?: string;
    groupId?: string; // groupId of selected chat
    expire?: string;
    name?: string;
    description?: string;
    couponId?: string;
    status?: string;
    share?: number;
    value?: number;
  };
}

export class GetCoupons {
  readonly method = GET_COUPONS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public hash?: string,
    public reference?: number,
    public status?: string,
    public expire?: string
  ) { }
}

export class GetCoupon {
  readonly method = GET_COUPON_METHOD;
  constructor(
    public couponId: string,
    public expired?: string,
    public status?: string
  ) { }
}

export class CreateCoupon {
  readonly method = CREATE_COUPON_METHOD;
  readonly reference = CREATE_COUPON_REF;
  constructor(coupon: OutCouponMessage) {
    for (const key in coupon) {
      if (coupon.hasOwnProperty(key)) {
        this[key] = coupon[key];
      }
    }
  }
}
export class CreateCouponFromBool {
  readonly method = CREATE_COUPON_METHOD;
  readonly reference = CREATE_COUPON_POPUP_REF;
  constructor(coupon: OutCouponMessage) {
    for (const key in coupon) {
      if (coupon.hasOwnProperty(key)) {
        this[key] = coupon[key];
      }
    }
  }
}

export class UpdateCoupon {
  readonly method = UPDATE_COUPON_METHOD;
  readonly reference = UPDATE_COUPON_REF;

  constructor(coupon: OutCouponMessage) {
    for (const key in coupon) {
      if (coupon.hasOwnProperty(key)) {
        this[key] = coupon[key];
      }
    }
  }
}

export type MemberMethods =
  | GetCoupons
  | GetCoupon
  | CreateCoupon
  | UpdateCoupon;
