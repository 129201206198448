import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { APP_UPLOAD_TEMPLATES_CATEGORIES } from 'src/models/constants';
@Component({
  selector: 'app-startover-temp-item',
  templateUrl: './startover-temp-item.component.html',
  styleUrls: ['./startover-temp-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverTempItemComponent implements OnInit {
  @Input() temp: any;
  @Input() index: any;
  @Input() seeMore: boolean;
  @Output() setTempNotify = new EventEmitter<any>();
  @Output() setTempReview = new EventEmitter<any>();
  appCategories = APP_UPLOAD_TEMPLATES_CATEGORIES;
  constructor(public appMgmtService: AppMgmtService) {}

  ngOnInit() {}

  setTemplate() {
    this.setTempReview.emit(this.temp);
  }
  getCategory(id) {
    const category = this.appCategories.filter(cat => {
      if (cat.id === id) {
        return cat;
      }
    });
    return category[0].name;
  }
}
