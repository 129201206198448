import { Action } from '@ngrx/store';
import { MapService } from 'src/models/MapService';

export const enum MapServiceActionTypes {
  RESET_MAP_SERVICE_PAGING = 'RESET_MAP_SERVICE_PAGING',
  GET_NEXT_MAP_SERVICE_PAGE = 'GET_NEXT_MAP_SERVICE_PAGE',
  GET_PREV_MAP_SERVICE_PAGE = 'GET_PREV_MAP_SERVICE_PAGE',
  GET_MAP_SERVICE_BY_ID = 'GET_MAP_SERVICE_BY_ID',
  UI_MAP_SERVICE_SET_IDLE_STATUS = 'UI_MAP_SERVICE_SET_IDLE_STATUS',
  CREATE_MAP_SERVICE = 'CREATE_MAP_SERVICE',
  MAP_SERVICE_CREATED = 'MAP_SERVICE_CREATED',
  UPDATE_MAP_SERVICE = 'UPDATE_MAP_SERVICE',
  MAP_SERVICE_DETAILS_RECEIVED = 'MAP_SERVICE_DETAILS_RECEIVED',
  MAP_SERVICE_LIST_RECEIVED = 'MAP_SERVICE_LIST_RECEIVED',
  DELETE_MAP_SERVICE = 'DELETE_MAP_SERVICE',
  MAP_SERVICE_DELETED = 'MAP_SERVICE_DELETED',
  UI_SET_MAP_SERVICE_CURRENT_SCREEN = 'UI_SET_MAP_SERVICE_CURRENT_SCREEN',
  RESET = 'RESET'
}

export class ResetMapServicePaging implements Action {
  readonly type = MapServiceActionTypes.RESET_MAP_SERVICE_PAGING;
}

export class GetNextMapServicePage implements Action {
  readonly type = MapServiceActionTypes.GET_NEXT_MAP_SERVICE_PAGE;
  constructor() {}
}
export class GetPrevMapServicePage implements Action {
  readonly type = MapServiceActionTypes.GET_PREV_MAP_SERVICE_PAGE;
  constructor() {}
}

export class GetMapServiceByID implements Action {
  readonly type = MapServiceActionTypes.GET_MAP_SERVICE_BY_ID;
  constructor(public id: string) {}
}
export class CreateMapService implements Action {
  readonly type = MapServiceActionTypes.CREATE_MAP_SERVICE;
  constructor(public mapService: MapService) {}
}

export class MapServiceCreated implements Action {
  readonly type = MapServiceActionTypes.MAP_SERVICE_CREATED;
  constructor(public mapService: MapService) {}
}

export class UpdateMapService implements Action {
  readonly type = MapServiceActionTypes.UPDATE_MAP_SERVICE;
  constructor(public id: string, public mapServiceDetails: MapService) {}
}

export class MapServiceDetailsReceived implements Action {
  readonly type = MapServiceActionTypes.MAP_SERVICE_DETAILS_RECEIVED;
  constructor(public mapService: MapService) {}
}

export class MapServiceListReceived implements Action {
  readonly type = MapServiceActionTypes.MAP_SERVICE_LIST_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public mapServices: MapService[],
    public hash: string
  ) {}
}

export class DeleteMapService implements Action {
  readonly type = MapServiceActionTypes.DELETE_MAP_SERVICE;
  constructor(public id: string) {}
}

export class MapServiceDeleted implements Action {
  readonly type = MapServiceActionTypes.MAP_SERVICE_DELETED;
  constructor(public id: string) {}
}
export class SetStatusAsIdle implements Action {
  readonly type = MapServiceActionTypes.UI_MAP_SERVICE_SET_IDLE_STATUS;
  constructor() {}
}

export class SetCurrentScreen implements Action {
  readonly type = MapServiceActionTypes.UI_SET_MAP_SERVICE_CURRENT_SCREEN;
  constructor(public screen: string) {}
}

export class Reset implements Action {
  readonly type = MapServiceActionTypes.RESET;
}

export type Actions =
  | ResetMapServicePaging
  | GetNextMapServicePage
  | GetPrevMapServicePage
  | CreateMapService
  | MapServiceCreated
  | UpdateMapService
  | SetStatusAsIdle
  | MapServiceDetailsReceived
  | MapServiceListReceived
  | DeleteMapService
  | MapServiceDeleted
  | SetCurrentScreen
  | Reset;
