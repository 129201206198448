import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';

import { MenuStatsComponent } from './menu-stats/menu-stats.component';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

@NgModule({
  imports: [ChartsModule, CommonModule, AbstractFramesModule],
  declarations: [MenuStatsComponent],
  exports: [MenuStatsComponent]
})
export class StatisticsModule {}
