/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./map-cell.component";
import * as i4 from "../cells.service";
var styles_MapCellComponent = [i0.styles];
var RenderType_MapCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapCellComponent, data: {} });
export { RenderType_MapCellComponent as RenderType_MapCellComponent };
function View_MapCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "caption-box wrap-text copyable-item text-cursor"], ["dir", "auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locationName; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.locationDetails; _ck(_v, 3, 0, currVal_1); }); }
export function View_MapCellComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "map-container"]], [[2, "caption", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "location"], ["class", "img-map"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapCellComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.locationName; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locationName; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.googleLink; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.mapImage; _ck(_v, 2, 0, currVal_2); }); }
export function View_MapCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-cell", [], null, null, null, View_MapCellComponent_0, RenderType_MapCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.MapCellComponent, [i4.CellsService], null, null)], null, null); }
var MapCellComponentNgFactory = i1.ɵccf("app-map-cell", i3.MapCellComponent, View_MapCellComponent_Host_0, { width: "width", height: "height", latitude: "latitude", longitude: "longitude", locationName: "locationName", locationDetails: "locationDetails" }, {}, []);
export { MapCellComponentNgFactory as MapCellComponentNgFactory };
