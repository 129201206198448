import { ProductStore } from "src/models/productStore";


export class productStoreMiddleware {
  public static isProductExist(
    list: ProductStore[],
    rcvdObj: ProductStore
  ) {
    for (let i = 0; i < list.length; i++) {
      if (rcvdObj.id && list[i].id === rcvdObj.id) {
        return true;
      }
    }
    return false;
  }
}
