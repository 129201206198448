import { WidgetUI, defaultDashboard, SummaryUI } from 'src/models/summaryUI';
import * as actions from './summaryUI.actions';
import { Actions } from './summaryUI.actions';

const INITIAL_STATE: SummaryUI = {
  currentPage: [],
  saveDefaultList: false
};

export function summaryUIReducer(
  state: SummaryUI = INITIAL_STATE,
  action: Actions
): SummaryUI {
  switch (action.type) {
    case actions.SummaryUIActionTypes.UI_RECEIVED_DASHBOARD_HISTORY_CHUNK:
      return {
        ...state,
        saveDefaultList: action.dashboard ? false : true,
        currentPage: action.dashboard
          ? [...action.dashboard.widgets]
          : [...defaultDashboard]
      };
    case actions.SummaryUIActionTypes.UI_CREATE_NEW_WIDGET:
      return {
        ...state,
        currentPage: [...state.currentPage, action.widget]
      };
    case actions.SummaryUIActionTypes.UI_UPDATE_WIDGET:
      return {
        ...state,
        currentPage: state.currentPage.map(res => {
          if (res.section === action.widget.section) {
            return action.widget;
          } else {
            return res;
          }
        })
      };
    case actions.SummaryUIActionTypes.UI_REORDER_DASHBOARD_LIST:
      return { ...state, currentPage: [...action.widgets] };
    case actions.SummaryUIActionTypes.UI_REMOVE_WIDGET_FROM_LIST:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          res => res.section !== action.widget.section
        )
      };
    case actions.SummaryUIActionTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
