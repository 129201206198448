import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { FileService } from 'src/app/core/file.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { SubChannelUIActionTypes } from './subChannelsUI.actions';
import * as SubChannelActions from './subChannelsUI.actions';
import { Photo } from 'src/models/Photo';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IChat, SetChat, GetSubChats } from 'src/models/IChat';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  ERROR_NOT_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  NOT_SUPPORTED_FILE_TYPE,
  NO_FILE_NAME,
  GROUP_UPLOAD_TYPE,
  CHANNEL,
  PageDirection,
  UPDATE_CHANNEL_REF
} from 'src/models/constants';
import { SubChannelUiDispatchers } from './subChannelsUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { SubChatsService } from 'src/app/+merchant/+dashboard/sub-chats/sub-chats.service';

@Injectable()
export class SubChannelUiEffects {
  @Effect({ dispatch: false })
  uploadSubChannelImage = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UPLOAD_SUB_CHANNEL_IMAGE),
    withLatestFrom(
      this._store
        .select(state => state.subChannelUiReducer.creationProcessSubChat)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.subChannelUiReducer.selectedSubGroup)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer.enableSetTemplateBtn)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(
      ([val, creationSubChannel, updatedSubChannel, enableSetTemplateBtn]) => {
        const action = <SubChannelActions.UploadSubChannelImage>val;
        if (this.validateFile(action.selectedFile.file, 'image')) {
          this._fileService
            .readFileAsArrayBuffer(action.selectedFile.file.localFile)
            .then(fileAsArrayBuffer => {
              let groupId = '';
              if (!enableSetTemplateBtn) {
                groupId = creationSubChannel
                  ? creationSubChannel.id
                  : updatedSubChannel && updatedSubChannel.id
                  ? updatedSubChannel.id
                  : action.selectedFile.chat.id;
              }
              this._uploadGateway
                .uploadWithProgress(
                  fileAsArrayBuffer,
                  action.selectedFile.file.localFile.type,
                  action.selectedFile.file.localFile.name,
                  false,
                  null,
                  null,
                  groupId,
                  true,
                  false,
                  '',
                  false,
                  false,
                  0,
                  '1x1'
                )
                .subscribe(
                  event => {
                    if (event.type === HttpEventType.Response) {
                      const res = <IUploadResponse>event.body;
                      let chat: IChat = {};
                      const photo: Photo = { id: res.file };

                      chat = { ...chat, ...action.selectedFile.chat };
                      chat.id = creationSubChannel
                        ? creationSubChannel.id
                        : updatedSubChannel && updatedSubChannel.id
                        ? updatedSubChannel.id
                        : action.selectedFile.chat.id;
                      chat.photo = photo;
                      // this.subChatsService._currentPhotoUploaded.next(res);
                      this.subChatsService._wattingForUploadImage.next(false);
                      this._socketGateway.sendSocketMessage(
                        new SetChat(chat, UPDATE_CHANNEL_REF)
                      );
                      this.subChatsService.showEditSubGroupScreen(chat);
                      // if (enableSetTemplateBtn) {
                      //   const id = chat.id;
                      //   const type = chat.type.toLocaleLowerCase();
                      //   const tempChat = { ...chat };
                      //   delete tempChat['id'];
                      //   this._uiDispatchers.setChatToTemplate(id, type, tempChat);
                      // }
                    }
                  },
                  error => {
                    console.log('Failed to upload subChannel image');
                  }
                );
            });
        }
      }
    )
  );

  @Effect({ dispatch: false })
  afterSubChannelCreation = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_UPLOAD_SUB_CHANNEL_PHOTO),
    map((action: SubChannelActions.SubChannelPhotoUploadUI) => {
      this._chatDispatchers.chatInvalidated(action.createdSubChannel.id);
      this._chatDispatchers.getChatAdministrators(action.createdSubChannel.id);
    })
  );

  @Effect({ dispatch: false })
  getNextSubChannelPage = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subChannelUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subChannelUiState]) => {
      const action = <SubChannelActions.GetNextSubChannelPage>val;
      const prevRequest = subChannelUiState.previousRequest;
      if (
        !(
          subChannelUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          subChannelUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats(
            [CHANNEL],
            0,
            subChannelUiState.hash,
            subChannelUiState.eop
          )
        );
      } else {
        this._subChannelUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevSubChannelPage = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subChannelUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subChannelUiState]) => {
      const action = <SubChannelActions.GetPrevSubChannelPage>val;
      const prevRequest = subChannelUiState.previousRequest;
      if (
        !(
          subChannelUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          subChannelUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats(
            [CHANNEL],
            1,
            subChannelUiState.hash,
            subChannelUiState.sop
          )
        );
      } else {
        this._subChannelUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterSubChannelSectionSelection = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_LIST_SUB_CHANNEL),
    map((action: SubChannelActions.ListSubChannelUI) => {
      this._subChannelUiDispatchers.resetSubChannelPaging();
    })
  );

  @Effect({ dispatch: false })
  afterSubChatResetPaging = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_SUB_CHANNEL_RESET_PAGING),
    map((action: SubChannelActions.ResetSubChannelPaging) => {
      this._subChannelUiDispatchers.getNextSubChannelPage();
    })
  );

  @Effect({ dispatch: false })
  setChannelChat = this.actions$.pipe(
    ofType(SubChannelUIActionTypes.UI_SET_CHANNEL_CHAT),
    withLatestFrom(
      this._store
        .select(state => state.subChannelUiReducer.file)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, file]) => {
      if (file && file !== null) {
        const action = <SubChannelActions.SetChannelChat>val;
        const chat = { ...file.chat, id: action.data.chat_id };
        const evntObject: any = { ...file };
        evntObject.chat = chat;
        this._subChannelUiDispatchers.uploadSubChannelImage(evntObject);
      }
      // this._socketGateway.sendSocketMessage(
      //   new SetChat(chat, UPDATE_CHANNEL_REF)
      // );
    })
  );

  constructor(
    private actions$: Actions,
    private _chatDispatchers: ChatDispatchers,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _subChannelUiDispatchers: SubChannelUiDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private subChatsService: SubChatsService,
    private _store: Store<AppState>
  ) {}

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
