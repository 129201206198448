import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

import { MenusService } from '../menus.service';

import {
  BotMenu,
  BotMenuButton,
  BotMenuButtonValue,
  BotMenuOption,
  BotMenuRow
} from 'src/models/IBotMenu';
import { AppForm } from 'src/models/AppForm';
import {
  LocalAppClass,
  LocalContainer,
  LocalItem
} from './../../../../../models/ChannelAppLocalConfig';
import { AppMgmtService } from './../../app-mgmt/appMgmt.service';
import { AppClass, AppInfo, ImageSet } from 'src/models/ChannelAppConfig';
import {
  TabTypes,
  SPLASH_PAGE_UPLOAD_TYPE,
  ERROR_IMAGE_SHOULD_BE_1024X1024,
  LOGO_WIDTH,
  LOGO_HEIGHT,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  ERROR_NOT_PNG_IMAGE,
  NO_FILE_NAME,
  FAILED_TO_UPLOAD_IMAGE,
  MENU_BOTTON_STYLE,
  MENU_CELLS_CATEGORIES,
  ChannelAppScreens,
  KEYBOARD_TYPES,
  submitType,
  CREATE_PAGE_REF,
  MENU_ONCLICK_ACTIONS,
  IS_PRODUCTION,
  UISections
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IUploadResponseImage } from 'src/models/IUploadResponseImage';
import { HttpEventType } from '@angular/common/http';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
// import { IOption } from 'src/models/campaign';
import { StorageService } from 'src/app/core/storage.service';
import { BotsService } from './../../bots/bots.service';
import { LayoutService } from './../../../+layout/layout.service';
import { FeaturesSublistComponent } from '../../app-mgmt/custom/dragable-features/features-sublist/features-sublist.component';
// import { forEach } from 'jszip';
import { IMediaCenter } from 'src/models/IMediaCenter';
import { MyPage } from 'src/models/MyPageConfig';
import { FeaturesCategoriesComponent } from '../../app-mgmt/custom/dragable-features/features-categories/features-categories.component';
import { AuthService } from './../../../../core/auth.service';
import { UiService } from 'src/app/core/ui.service';
import { PrivilegesName } from 'src/models/privileges';
import { MobileWorkFlowStoreService } from '../../mobile-workflow-store/mobile-workflow-store.service';
import { CollectionsService } from '../../collections/collections.service';
import { CollectionUIImage } from 'src/models/collectionsUI';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuFormComponent
  implements OnChanges, AppForm, OnInit, OnDestroy {
  form: FormGroup;
  @Input() startOverFirstValidation = false;
  startOverSecValidation = false;
  menusPoolVisibility = false;
  @Input() startOver = false;
  @Input() store = false;
  @Input() myPage: any;
  @Input() section: any;
  @Input() mobileStoreAppList: any[];
  showSubMenus = true;
  selectedPos: any;
  nextMenuSelected = false;
  showOptionImage = false;
  @Input() selectedButton: { row: number; col: number };
  defaultBtn: BotMenuButton = {
    button_label: 'Button',
    button_textcolor: '#FFFFFF',
    button_icon: null,
    button_icon_bgcolor: '#FFFFFF',
    button_bgcolor: '#e7ebf8'
  };
  privilegesName: typeof PrivilegesName = PrivilegesName;
  uiSection = UISections;
  nextMenu: string;
  selectedFeature: any;
  @Input() selectedTab: any;
  @Input() selectedButtonTab: any;
  @Input() showBottonConfig = false;
  @Input() botMenuEdit: BotMenu = {};
  @Input() currentMenu: BotMenu = {};
  @Input() enableSetTemplateBtn = false;
  @Output() closeNotifiy = new EventEmitter<boolean>();
  @Output() formEmitter = new EventEmitter<any>();
  @Output() newformEmitter = new EventEmitter<any>();
  @Output() mainMenuNotifiy = new EventEmitter<boolean>();
  @Output() startOverMenuSaveNotify = new EventEmitter<boolean>();
  @Output() uploadNotify = new EventEmitter<boolean>();
  @Output() newMenuNotify = new EventEmitter<null>();
  @Output() selecteButtonNotify = new EventEmitter<any>();
  @Output() removeParentIdNotify = new EventEmitter<any>();
  @Output() showSettingsNotify = new EventEmitter<LocalContainer>();
  @Output() showConfigButtonNotify = new EventEmitter<boolean>();
  @Input() mainMenu = false;
  @Input() tabsContainer: LocalContainer[];
  @Input() appClass: AppClass;
  @Input() menusAppClass: AppClass;
  @Input() tabsList: LocalContainer[];
  @Input() itemsList: any[];
  @Input() toBeEdited: boolean;
  @Input() currentTabs: any[];
  @Input() config = false;
  @Input() showRemove = false;
  @Input() showSettings: boolean;
  @Input() currentPage: any;
  @Input() menuSection: any;
  selectTab = false;
  @Output() dropEmitter = new EventEmitter<any>();
  @Output() subMenuSelectNotify = new EventEmitter<any>();
  @Output() closeMenuConfNotify = new EventEmitter<any>();
  @Output() deleteButtonNotifiy = new EventEmitter<{
    row: number;
    col: number;
  }>();

  @Output() youTubeNotify = new EventEmitter<any[]>();
  @Output() addParentIdNotify = new EventEmitter<any[]>();
  @Output() pageIdNotify = new EventEmitter<any>();
  @Input() pageToView: any;
  @Input() createNewScreen = false;
  @Input() storeMenuList: any[] = [];
  pageRef = CREATE_PAGE_REF;
  buttonsLength = 0;
  showAddButton = false;
  uploaded = false;
  rowNumber: number;
  subMenu = false;
  tabTypes = TabTypes;
  showRows = false;
  buttonId = '';
  optionId = '';
  rowStyles = MENU_BOTTON_STYLE;
  nextMenuList: BotMenu[] = [];
  newCr = false;
  newButtons = [];
  @Input() withMenus = false;
  @Input() systemConfg: any;
  @Input() selectedStoreId: any;
  menusSubscribe = null;
  feature: any;
  showValidation = false;
  showAddImage: boolean;
  showAddMedia: boolean;
  showAddBackground: boolean;
  menuCat = MENU_CELLS_CATEGORIES;
  appScreens = ChannelAppScreens;
  keyboardTypes = KEYBOARD_TYPES;
  submitTypes = submitType;
  submitValue: any;
  actionTypes = MENU_ONCLICK_ACTIONS;
  showScreens = false;
  showCollections = false;
  optionBtn: { row: number; col: number };
  optionBtnOptId = '';
  getCollectionRun = false;
  getFeature = new FeaturesSublistComponent(
    this._ref,
    this.appManagementService,
    this.botService,
    this.layoutService,
    this.uiService,
    this.mobileWorkFlowService
  );
  getFeatureCategory = new FeaturesCategoriesComponent(
    this._ref,
    this.layoutService,
    this.authService,
    this.appManagementService
  );
  selectedAspectRatio: string;
  selectedBgColor: string;

  isProduction = IS_PRODUCTION;

  accessTypes = [
    {
      id: null,
      value: 'any'
    },
    { id: 1, value: 'admin' }
  ];

  loginTypes = [
    {
      id: null,
      value: 'no'
    },
    { id: 'email', value: 'email' },
    { id: 'msisdn', value: 'msisdn' }
  ];

  constructor(
    private _fb: FormBuilder,
    private _botMenusService: MenusService,
    private _ref: ChangeDetectorRef,
    public appManagementService: AppMgmtService,
    private _uiDispatchers: UIDispatchers,
    private botService: BotsService,
    private layoutService: LayoutService,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private authService: AuthService,
    private _localStorage: StorageService,
    public uiService: UiService,
    public mobileWorkFlowService: MobileWorkFlowStoreService,
    public collectionService: CollectionsService
  ) {}

  ngOnDestroy() {
    if (this.menusSubscribe) {
      this.menusSubscribe.unsubscribe();
    }
  }

  ngOnInit() {
    if (!this.tabsList) {
      this.appManagementService._selectedMenu.next(this.botMenuEdit);
    }
    // this.form = this.setFormGroup();
    if (!this.currentTabs) {
      this.currentTabs = [];
    }

    this.appManagementService._newCreatedButtons.next([]);
    if (this.botMenuEdit) {
      this.appManagementService._selectedMenu.next(this.botMenuEdit);
      this.mobileWorkFlowService._selectedMenu.next(this.botMenuEdit);
      this.currentMenu = this.botMenuEdit;

      this.form = this.setFormGroup();
      this.nextMenu = this.botMenuEdit.next_menu;
    } else if (
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.menusAppClass.menus.length > 0
    ) {
      this.mainMenu = true;

      this.form = this.setFormGroup();
    } else {
      this.form = this.setFormEmptyGroup();

      this.nextMenu = null;
    }
    if (this.selectedButton) {
      this.form = this.setFormGroup();
      if (!this.selectedButtonTab) {
        this.setOutOutSelectedBtn(this.selectedButton);
      }
    }

    if (
      this.startOver &&
      this.form.value.menu_id &&
      (!this.menusAppClass.menus || this.menusAppClass.menus.length == 0)
    ) {
      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
      this.appManagementService._selectedMenu.next(this.form.value);
      if (
        this.section === this.uiSection.MOBILESTORE ||
        ((this.selectedTab.type === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedTab.link === this.tabTypes.MENUS &&
            this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedButtonTab &&
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
      ) {
        const newMenuList = [];
        if (this.storeMenuList && this.storeMenuList.length > 0) {
          this.storeMenuList.forEach(menu => {
            if (menu.menu_id === this.form.value.menu_id) {
              newMenuList.push(this.form.value);
            } else {
              newMenuList.push(menu);
            }
          });
        } else {
          newMenuList.push(this.form.value);
        }
        const newMobileAppList: any[] = [];
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (store.id !== this.selectedStoreId) {
              newMobileAppList.push(store);
            } else {
              const updatedStore: any = {};
              updatedStore.id = store.id;
              updatedStore.isUpdated = 1;
              updatedStore.currency = store.currency;
              updatedStore.is_default = store.is_default;
              if (store.data) {
                updatedStore.data = store.data;
              } else {
                updatedStore.data = {};
              }

              if (store.image) {
                const defaultImage: CollectionUIImage = {};
                defaultImage.url =
                  'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
                updatedStore.image = defaultImage;
                updatedStore.name = 'Default Store';
                updatedStore.desc = 'Default Store';
              }

              updatedStore.data.shop = newMenuList;
              newMobileAppList.push(updatedStore);
            }
          });
        } else {
          const updatedStore: any = {};
          const defaultImage: CollectionUIImage = {};
          defaultImage.url =
            'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
          updatedStore.image = defaultImage;
          updatedStore.name = 'Default Store';
          updatedStore.desc = 'Default Store';
          updatedStore.id = undefined;
          updatedStore.is_default = 1;
          updatedStore.isUpdated = 1;
          updatedStore.data = {};
          updatedStore.currency = null;
          updatedStore.data.menu_id = this.form.value.menu_id;
          updatedStore.data.shop = newMenuList;
          newMobileAppList.push(updatedStore);
        }

        this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

        this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

        this.mobileWorkFlowService._selectedMenu.next(this.form.value);
      } else {
        const menuAppClass: AppClass = {};
        menuAppClass.menus = [this.form.value];

        this.appManagementService.updateAppClass(
          this.menusAppClass.ref,
          menuAppClass
        );
      }
    }
  }

  ngOnChanges() {
    if (
      this.selectedButton &&
      this.selectedButtonTab &&
      this.form &&
      this.menuSection === this.actionTypes.MODULE
    ) {
      this.onClickFeature(this.selectedButton);
    }
    if (this.createNewScreen) {
      this.saveMenu(true);
      this.appManagementService._createNewScreen.next(false);
    }

    if (!this.myPage) {
      this.appManagementService.pageId$.subscribe(id => {
        this.appManagementService.getMyPagePyID$(id).subscribe(mypage => {
          this.myPage = mypage;
        });
      });
    }
    if (
      this.form &&
      this.form.value &&
      this.currentMenu &&
      (this.form.value.menu_id !== this.currentMenu.menu_id ||
        (this.currentMenu.rows &&
          this.form.value.rows &&
          this.form.value.rows.length < this.currentMenu.rows.length) ||
        (this.form.value.menu_id !== this.botMenuEdit.menu_id ||
          (this.form.value.rows &&
            this.botMenuEdit.rows &&
            this.form.value.rows.length < this.botMenuEdit.rows.length)))
    ) {
      this.form = this.setFormGroup();
    }
    if (
      this.form &&
      this.form.value &&
      ((this.currentMenu && this.currentMenu.rows) ||
        (this.botMenuEdit && this.botMenuEdit.rows))
    ) {
      let changed = 0;

      for (let i = 0; this.currentMenu.rows.length > i; i++) {
        if (this.currentMenu.rows[i].buttons) {
          for (let j = 0; this.currentMenu.rows[i].buttons.length > j; j++) {
            if (
              !this.form.value.rows[i] ||
              (this.form.value.rows[i] &&
                this.currentMenu.rows[i] &&
                this.currentMenu.rows[i].buttons[j] &&
                this.form.value.rows[i].buttons[j] &&
                this.currentMenu.rows[i].buttons[j].button_style !==
                  this.form.value.rows[i].buttons[j].button_style)
            ) {
              changed++;
            }
          }
        }
      }
      // for (let i = 0; this.botMenuEdit.rows.length > i; i++) {
      //   if (this.botMenuEdit.rows[i].buttons) {
      //     for (let j = 0; this.botMenuEdit.rows[i].buttons.length > j; j++) {
      //       if (
      //         this.form.value.rows[i] ||
      //         this.botMenuEdit.rows[i].buttons[j].button_style !==
      //           this.form.value.rows[i].buttons[j].button_style
      //       ) {
      //         changed++;
      //       }
      //     }
      //   }
      // }
      if (changed > 0) {
        this.form = this.setFormGroup();
      }
    }

    if (
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.menusAppClass.menus.length > 0
    ) {
      this.nextMenuList = [];
      this.menusAppClass.menus.forEach(res => {
        if (
          this.form &&
          res &&
          res.menu_id !== this.form.get('menu_id').value
        ) {
          this.nextMenuList.push(res);
        }
      });
      // if (this.botMenuEdit && !this.botMenuEdit.menu_id) {
      //   this.form = this.setFormEmptyGroup();

      //   this.appManagementService._selectedMenu.next(this.form.value);
      //   // this.botMenuEdit = this.form.value;
      // }
    }
  }

  setFormGroup() {
    let id = null;
    if (
      this.mainMenu &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.menusAppClass.menus.length > 0 &&
      this.menusAppClass.menus[this.menusAppClass.menus.length - 1] &&
      ((this.selectedTab && !this.selectedTab.sub_type) ||
        this.section === this.uiSection.MENUS)
    ) {
      id = this.menusAppClass.menus[this.menusAppClass.menus.length - 1]
        .menu_id;
      id = id.replace('menu', '');
      if (
        id.length ==
        this.menusAppClass.menus[this.menusAppClass.menus.length - 1].menu_id
          .length
      ) {
        id = this.menusAppClass.menus.length + '';
      }
      id = 'menu' + (parseInt(id) + 1);
    } else if (
      this.mainMenu &&
      this.menusAppClass &&
      this.selectedTab &&
      ((this.selectedTab && !this.selectedTab.sub_type) ||
        this.section === this.uiSection.MENUS)
    ) {
      id = 1;

      id = 'menu' + id;
    }
    const date = new Date();
    const newId = Math.floor(date.getDate() + Math.random() * 90000000);
    if (
      this.mainMenu &&
      this.storeMenuList &&
      this.storeMenuList.length > 0 &&
      this.storeMenuList[this.storeMenuList.length - 1] &&
      ((this.selectedTab && this.selectedTab.sub_type) ||
        this.section === this.uiSection.MOBILESTORE)
    ) {
      id = this.storeMenuList[this.storeMenuList.length - 1].menu_id;

      id = id.replace('store', '');
      id = 'store' + newId;
    } else if (
      this.mainMenu &&
      this.storeMenuList &&
      ((this.selectedTab && this.selectedTab.sub_type) ||
        this.section === this.uiSection.MOBILESTORE)
    ) {
      id = 1;

      id = 'store' + newId;
    }

    let apiId = null;
    if (this.selectedTab && this.selectedTab.api_id) {
      apiId = this.selectedTab.api_id;
    }

    return this._fb.group({
      id: [this.currentMenu ? this.currentMenu.id : null],
      menu_id: [
        this.currentMenu && this.currentMenu.menu_id
          ? this.currentMenu.menu_id
          : id
      ],
      menu_ref: [
        this.currentMenu && this.currentMenu.menu_id
          ? this.currentMenu.menu_id
          : id
      ],
      menu_date: [this.currentMenu ? this.currentMenu.menu_date : null],
      menu_name: [
        this.currentMenu ? this.currentMenu.menu_name : null,
        [Validators.required]
      ],
      next_menu: [this.currentMenu ? this.currentMenu.next_menu : null],
      api_id: [
        this.currentMenu && this.currentMenu.api_id
          ? this.currentMenu.api_id
          : apiId
      ],
      rows: this.currentMenu
        ? this.setRows(this.currentMenu.rows)
        : new FormArray([])
    });
  }

  clearFormGroup() {
    const id = 'menu1';
    let apiId = null;
    if (this.selectedTab && this.selectedTab.api_id) {
      apiId = this.selectedTab.api_id;
    }
    return this._fb.group({
      id: [this.currentMenu ? this.currentMenu.id : null],
      menu_id: [
        this.currentMenu && this.currentMenu.menu_id
          ? this.currentMenu.menu_id
          : id
      ],
      api_id: [
        this.currentMenu && this.currentMenu.api_id
          ? this.currentMenu.api_id
          : apiId
      ],
      menu_ref: [
        this.currentMenu && this.currentMenu.menu_id
          ? this.currentMenu.menu_id
          : id
      ],
      menu_date: [this.currentMenu ? this.currentMenu.menu_date : null],
      menu_name: [
        this.currentMenu ? this.currentMenu.menu_name : null,
        [Validators.required]
      ],
      next_menu: [null],
      rows: new FormArray([])
    });
  }

  // goNext() {
  //   this.onSubmit();

  //   if (!this.currentTabs || this.currentTabs.length == 0) {
  //     this.deleteOldTabs();
  //   }

  //   this.mainMenuNotifiy.emit(true);
  // }

  deleteTab(deletedButtons) {
    for (let i = 0; i < this.currentTabs.length; i++) {
      deletedButtons.forEach(res => {
        if (res.button_id === this.currentTabs[i].key) {
          const newContainer: LocalContainer = {};
          newContainer.ref = this.currentTabs[i].value;

          this.appManagementService.deleteContainer(newContainer);
        }
      });
    }
  }

  setFormEmptyGroup() {
    let id = null;

    if (
      this.mainMenu &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.menusAppClass.menus.length > 0 &&
      this.menusAppClass.menus[this.menusAppClass.menus.length - 1] &&
      ((this.selectedTab && !this.selectedTab.sub_type) ||
        this.section === this.uiSection.MENUS)
    ) {
      id = this.menusAppClass.menus[this.menusAppClass.menus.length - 1]
        .menu_id;

      id = id.replace('menu', '');
      if (
        id.length ==
        this.menusAppClass.menus[this.menusAppClass.menus.length - 1].menu_id
          .length
      ) {
        id = this.menusAppClass.menus.length;
      }
      id = 'menu' + (parseInt(id) + 1);
    } else if (
      this.mainMenu &&
      this.menusAppClass &&
      ((this.selectedTab && !this.selectedTab.sub_type) ||
        this.section === this.uiSection.MENUS)
    ) {
      id = 1;

      id = 'menu' + id;
    }
    const date = new Date();
    const newId = Math.floor(date.getDate() + Math.random() * 90000000);
    if (
      this.mainMenu &&
      this.storeMenuList &&
      this.storeMenuList.length > 0 &&
      this.storeMenuList[this.storeMenuList.length - 1] &&
      ((this.selectedTab && this.selectedTab.sub_type) ||
        this.section === this.uiSection.MOBILESTORE)
    ) {
      id = this.storeMenuList[this.storeMenuList.length - 1].menu_id;

      id = id.replace('store', '');
      id = 'store' + newId;
    } else if (
      this.mainMenu &&
      this.storeMenuList &&
      ((this.selectedTab && this.selectedTab.sub_type) ||
        this.section === this.uiSection.MOBILESTORE)
    ) {
      id = 1;

      id = 'store' + newId;
    }

    this.subMenuSelect(id);

    let api = null;
    if (this.selectedTab && this.selectedTab.api_id) {
      api = this.selectedTab.api_id;
    }
    return this._fb.group({
      id: [null],
      menu_id: [id],
      api_id: [api],
      menu_ref: [id],
      menu_date: [null],
      menu_name: [id, [Validators.required]],
      next_menu: [null],
      rows: this.setRows(new FormArray([]))
    });
  }

  setRows(rows) {
    const botRows = [];

    if (rows && rows.length > 0 && rows[0]) {
      for (let row = 0; row < rows.length; row++) {
        const button = rows[row];

        botRows.push(this.setRow(button, row));
      }
    } else if (rows && rows.value > 0 && rows.value[0]) {
      for (let row = 0; row < rows.length; row++) {
        const button = rows.value[row];

        botRows.push(this.setRow(button, row));
      }
    }
    return this._fb.array(botRows);
  }

  get butId() {
    return this.form;
  }

  setRow(row: BotMenuRow, rowIndex?: number) {
    return this._fb.group({
      row_id: [row.row_id ? row.row_id : null],
      row_order: [row.row_order ? row.row_order : null],
      buttons: this.setButtons(row.buttons, rowIndex)
    });
  }

  setButtons(cols: BotMenuButton[], rowIndex?: number) {
    const buttons = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const button = cols[col];
        buttons.push(this.setButton(button, rowIndex, col));
      }
    }
    return this._fb.array(buttons);
  }

  setButton(button: any, rowIndex?: number, colIndex?: number) {
    // if (
    //   this.form &&
    //   this.form.get('rows').value &&
    //   this.form.get('rows').value[rowIndex]
    // ) {
    //   this.buttonId = this.form.get('rows').value[rowIndex].buttons[colIndex]
    //     .length
    //     ? this.form.get('rows').value[rowIndex].buttons[colIndex].length + 1
    //     : 1;
    // } else {
    //   this.buttonId = 1;
    // }
    let db = 1;
    if (button.button_db == 0) {
      db = 0;
    }
    this.buttonId = Math.random()
      .toString(36)
      .substr(2, 9);
    const buttonIdStr = 'C' + this.buttonId;
    let menuId = null;
    if (this.form) {
      menuId = this.form.get('menu_id').value;
    }
    let subLabel = null;
    if (button && button.button_sublabel) {
      subLabel = button.button_sublabel;
    }
    let newpage = true;
    if (button && !button.button_newpage && button.button_newpage !== false) {
      newpage = true;
    } else {
      newpage = button.button_newpage;
    }
    if (button.button_form === this.menuCat.SUBMIT) {
      return this._fb.group({
        button_id: [button.button_id ? button.button_id : buttonIdStr],
        next_menu: [button.next_menu ? button.next_menu : null],
        button_next_page: [
          button.button_next_page ? button.button_next_page : null
        ],
        button_span: [button.button_span ? button.button_span : null],
        button_order: [button.button_order ? button.button_order : null],
        button_access: [button.button_access ? button.button_access : null],
        button_login: [button.button_login ? button.button_login : null],
        button_textcolor: [
          button.button_textcolor ? button.button_textcolor : null
        ],
        button_icon: [button.button_icon ? button.button_icon : null],
        button_icon_bgcolor: [
          button.button_icon_bgcolor ? button.button_icon_bgcolor : null
        ],
        button_callback: [
          button.button_callback ? button.button_callback : buttonIdStr
        ],
        button_bgcolor: [button.button_bgcolor ? button.button_bgcolor : null],
        button_label: [button.button_label ? button.button_label : null],
        button_sublabel: [subLabel],

        button_value: [button.button_value ? button.button_value : []],
        button_keyboard: [
          button.button_keyboard ? button.button_keyboard : 'text'
        ],
        button_url: [button.button_url ? button.button_url : null],
        button_query: [button.button_query ? button.button_query : null],
        button_style: [button.button_style ? button.button_style : null],
        button_description: [
          button.button_description ? button.button_description : null
        ],
        next_menu_id: [button.next_menu_id ? button.next_menu_id : null],
        button_img_url: [button.button_img_url ? button.button_img_url : null],
        button_form: [button.button_form ? button.button_form : null],
        button_type: [button.button_type ? button.button_type : null],
        button_border_color: [
          button.button_border_color ? button.button_border_color : null
        ],
        button_asbect_ratio: [
          button.button_asbect_ratio ? button.button_asbect_ratio : null
        ],
        button_option_color: [
          button.button_option_color ? button.button_option_color : null
        ],
        button_option: this.setOptions(button.button_option, colIndex),
        button_newpage: [newpage],
        button_db: [db],
        button_label_color: [
          button.button_label_color
            ? button.button_label_color
            : button.button_textcolor
        ],
        button_sublabel_color: [
          button.button_sublabel_color
            ? button.button_sublabel_color
            : button.button_textcolor
        ]
      });
    } else {
      return this._fb.group({
        button_id: [button.button_id ? button.button_id : buttonIdStr],
        next_menu: [button.next_menu ? button.next_menu : null],
        button_next_page: [
          button.button_next_page ? button.button_next_page : null
        ],
        button_span: [button.button_span ? button.button_span : null],
        button_order: [button.button_order ? button.button_order : null],
        button_access: [button.button_access ? button.button_access : null],
        button_login: [button.button_login ? button.button_login : null],
        button_textcolor: [
          button.button_textcolor ? button.button_textcolor : null
        ],
        button_icon: [button.button_icon ? button.button_icon : null],
        button_icon_bgcolor: [
          button.button_icon_bgcolor ? button.button_icon_bgcolor : null
        ],
        button_callback: [
          button.button_callback ? button.button_callback : buttonIdStr
        ],
        button_bgcolor: [button.button_bgcolor ? button.button_bgcolor : null],
        button_label: [button.button_label ? button.button_label : null],
        button_sublabel: [subLabel],

        button_value: [button.button_value ? button.button_value : []],
        button_keyboard: [
          button.button_keyboard ? button.button_keyboard : 'text'
        ],
        button_url: [button.button_url ? button.button_url : null],
        button_query: [button.button_query ? button.button_query : null],
        button_style: [button.button_style ? button.button_style : null],
        button_description: [
          button.button_description ? button.button_description : null
        ],
        next_menu_id: [button.next_menu_id ? button.next_menu_id : null],
        button_img_url: [button.button_img_url ? button.button_img_url : null],
        button_form: [button.button_form ? button.button_form : null],
        button_type: [button.button_type ? button.button_type : null],
        button_border_color: [
          button.button_border_color ? button.button_border_color : null
        ],
        button_asbect_ratio: [
          button.button_asbect_ratio ? button.button_asbect_ratio : null
        ],
        button_option_color: [
          button.button_option_color ? button.button_option_color : null
        ],
        button_option: this.setOptions(button.button_option, colIndex),
        button_newpage: [newpage],
        button_label_color: [
          button.button_label_color
            ? button.button_label_color
            : button.button_textcolor
        ],
        button_sublabel_color: [
          button.button_sublabel_color
            ? button.button_sublabel_color
            : button.button_textcolor
        ]
      });
    }
  }

  setOptions(cols: BotMenuOption[], colIndex?: number) {
    const options = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const option = cols[col];
        options.push(this.setOption(option, colIndex, col));
      }
    }
    return this._fb.array(options);
  }

  setValues(cols: any[], colIndex?: number) {
    const options = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const option = cols[col];
        options.push(this.setValue(option, colIndex, col));
      }
    }
    return this._fb.array(options);
  }

  setOption(option: BotMenuOption, rowIndex?: number, colIndex?: number) {
    this.optionId = Math.random()
      .toString(36)
      .substr(2, 9);
    const optionIdStr = 'ID-' + this.optionId;
    return this._fb.group({
      id: [option.id ? option.id : optionIdStr],
      label: [option.label ? option.label : null],
      sublabel: [option.sublabel ? option.sublabel : null],
      image: [option.image ? option.image : null],
      icon: [option.icon ? option.icon : null],
      value: [option.value ? option.value : null]
    });
  }

  setValue(option: any, rowIndex?: number, colIndex?: number) {
    return this._fb.group({
      id: [null],
      value: [option.value ? option.value : null]
    });
  }

  getButtonStyle(button: BotMenuButton) {
    return {
      'border-radius': '8px',
      'background-color': '#d3edff',
      'border-left': '6px solid #25adf0'
    };
  }

  onSubmit() {
    if (this.mainMenu) {
      this.appManagementService._newCreatedButtons.next([]);
      this.newButtons = [];
      if (this.selectedTab && !this.selectedTab.sub_type) {
        const menuAppClass: AppClass = {};

        if (
          this.menusAppClass &&
          this.menusAppClass.menus &&
          this.menusAppClass.menus.length > 0
        ) {
          const id = this.form.get('menu_id').value;

          const i = this.findMenuToBeUpdated(id);

          if (
            this.findMenuToBeUpdated(id) >= 0 &&
            this.findMenuToBeUpdated(id) !== null
          ) {
            menuAppClass.menus = this.newMenusList(i);
          } else {
            menuAppClass.menus = this.newMenusList(-1);
            const newMenu = this.form.value;
            if (this.selectedTab && this.selectedTab.api_id) {
              newMenu.api_id = this.selectedTab.api_id;
            }

            menuAppClass.menus.push(newMenu);
          }
        } else {
          const newMenu = this.form.value;
          if (this.selectedTab && this.selectedTab.api_id) {
            newMenu.api_id = this.selectedTab.api_id;
          }

          menuAppClass.menus = [newMenu];
        }
        if (this.menusAppClass) {
          this.appManagementService.updateAppClass(
            this.menusAppClass.ref,
            menuAppClass
          );
        }
      }
      // this.appManagementService._selectedMenu.next(this.form.value);
      // this.appManagementService.savePage(false);

      if (this.subMenu) {
        this.subMenu = false;
        setTimeout(() => {
          this.cancelMenu();
        }, 500);
      }
    } else {
      if (this.section === this.uiSection.MENUS) {
        this._botMenusService.setMenu(
          this.form.value,
          this.form.value.next_menu,
          this.mainMenu,
          this.startOver
        );
      }

      this.closeEvent();
    }
  }

  addTab() {
    for (let i = 0; i < this.newButtons.length; i++) {
      const buttonContainer: LocalContainer = {};
      buttonContainer.type = '';

      buttonContainer.layout = 'button';
      buttonContainer.id = this.newButtons[i].button_id;
      buttonContainer.title = this.newButtons[i].button_label;
      buttonContainer.title_ios = this.newButtons[i].button_label;

      this.appManagementService.addContainer(this.appClass, buttonContainer);
    }
  }

  closeEvent() {
    this.closeNotifiy.emit(true);
  }

  toggleMenusPoolModalVisibility(e: boolean) {
    this.menusPoolVisibility = e;
  }

  setMenuResponse(mnu: BotMenu) {
    this.form.patchValue({
      next_menu: mnu.menu_ref
    });
    this.nextMenu = mnu.menu_ref;
    this.toggleMenusPoolModalVisibility(false);
  }

  getMenuWithRefId$() {
    return this._botMenusService.getMenuWithRefId(this.nextMenu);
  }

  resetMenuRef() {
    this.form.patchValue({
      next_menu: ''
    });
    this.nextMenu = '';
  }
  /** CRUD operations on row and colums  */

  getButton(event, i) {
    let defaultBtn: BotMenuButton = {};
    if (
      this.imgCheck(event.button_style) ||
      this.iconCheck(event.button_style) ||
      this.singleCheck(event.button_style) ||
      this.imgToggleCheck(event.button_style) ||
      this.iconToggleCheck(event.button_style) ||
      this.singleToggleCheck(event.button_style)
    ) {
      if (i == 0) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.button_bgcolor,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.button_img_url,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.button_bgcolor,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.button_img_url,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      } else if (i == 1) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_2,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_2,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_2,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_2,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      } else if (i == 2) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_3,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_3,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_3,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_3,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_option: this.getoption(event, 2),
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      }
    } else {
      if (i == 0) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.button_bgcolor,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.button_img_url,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.button_bgcolor,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.button_img_url,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      } else if (i == 1) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_2,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_2,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_2,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_2,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      } else if (i == 2) {
        if (event.button_form === this.menuCat.SUBMIT) {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_3,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_3,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_db: event.button_db,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        } else {
          defaultBtn = {
            button_label: event.button_label,
            button_textcolor: event.button_textcolor,
            button_bgcolor: event.bg_color_3,
            button_description: 'Your description.',
            button_style: event.button_style,
            button_img_url: event.url_3,
            button_form: event.button_form,
            button_type: event.button_type,
            button_sublabel: event.button_sublabel,
            button_value: event.button_value,
            button_keyboard: event.button_keyboard,
            button_icon: event.button_icon,
            button_icon_bgcolor: event.button_icon_bgcolor,
            button_border_color: event.button_border_color,
            button_asbect_ratio: event.aspectRatio,
            button_access: event.button_access,
            button_login: event.button_login,
            button_label_color: event.button_label_color,
            button_sublabel_color: event.button_sublabel_color
          };
        }
        return defaultBtn;
      }
    }
  }
  getoption(button, index?) {
    let minLength = 2;
    if (index) {
      minLength = index;
    }

    const options: BotMenuOption[] = [];
    for (let i = 0; i < minLength; i++) {
      let count = i + 1;
      if (button.button_option && button.button_option.length > 1) {
        count = button.button_option.length + 1;
      }
      const btnStyle = Number(button.button_style);

      const img = Number(this.rowStyles.SINGLECHOICEIMG);
      const icon = Number(this.rowStyles.SINGLECHOICEICON);
      const single = Number(this.rowStyles.SINGLECHOICE);

      const img2 = Number(this.rowStyles.TOGGLECHOICEIMG);
      const icon2 = Number(this.rowStyles.TOGGLECHOICEICON);
      const single2 = Number(this.rowStyles.TOGGLECHOICE);

      const checkImg = img <= btnStyle;
      const checkImg2 = btnStyle < icon;
      const checkIcon = icon <= btnStyle;
      const checkIcon2 = btnStyle < single;
      const checkSingle = single <= btnStyle;

      const checkImg3 = img2 <= btnStyle;
      const checkImg4 = btnStyle < icon2;
      const checkIcon3 = icon2 <= btnStyle;
      const checkIcon4 = btnStyle < single2;
      const checkSingle2 = single2 <= btnStyle;

      if ((checkImg && checkImg2) || (checkImg3 && checkImg4)) {
        const defaultOpt: BotMenuOption = {
          label: 'option ' + count,
          image: ''
        };
        options.push(defaultOpt);
      } else if ((checkIcon && checkIcon2) || (checkIcon3 && checkIcon4)) {
        let iconName = 'ic_home_24dp';
        if (i == 1) {
          iconName = 'ic_work_24dp';
        }
        const defaultOpt: BotMenuOption = {
          label: 'option ' + count,
          icon: iconName
        };
        options.push(defaultOpt);
      } else if (checkSingle || checkSingle2) {
        const defaultOpt: BotMenuOption = {
          label: 'option ' + count
        };
        options.push(defaultOpt);
      }
    }

    return options;
  }

  getValue(button, index?) {
    let value = '';
    if (
      button &&
      button.value &&
      button.value.button_value &&
      button.value.button_value.length > 0
    ) {
      value = button.value.button_value[0].value;
    }

    return value;
  }

  addNewRow(event) {
    const rows = <FormArray>this.form.controls.rows || new FormArray([]);

    const rowsIndex = rows.length + 1;

    const buttonArray = [];
    if (
      event.length === '3' &&
      (event.button_style !== this.rowStyles.SMALLHL &&
        event.button_style !== this.rowStyles.WIDELIFT)
    ) {
      const btn1 = this.getButton(event, 0);
      const btn2 = this.getButton(event, 1);
      const btn3 = this.getButton(event, 2);
      buttonArray.push(btn1);
      buttonArray.push(btn2);
      buttonArray.push(btn3);
    } else if (event.length === '2') {
      const btn1 = this.getButton(event, 0);
      const btn2 = this.getButton(event, 1);
      buttonArray.push(btn1);
      buttonArray.push(btn2);
    } else {
      buttonArray.push(this.getButton(event, 0));
    }

    const rowButtons = this.setRow(
      {
        buttons: buttonArray
      },
      rowsIndex
    );

    rows.push(rowButtons);

    if (this.startOver) {
      rows.value.forEach(res => {
        this.createdButtons(res.buttons);
      });
    }

    if (this.tabsList) {
      this.onSubmit();
    }
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];

      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.currency = store.currency;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this._ref.detectChanges();
  }
  addNewRowOnClick(
    event,
    selectedTab,
    form,
    startOver,
    menusAppClass,
    currentTabs,
    appManagementService,
    mainMenu,
    mobileWorkflowStoreService?,
    storeList?,
    mobileStoreAppList?,
    selectedStoreId?,
    bundle?
  ) {
    this.selectedTab = selectedTab;
    this.form = form;
    this.menusAppClass = menusAppClass;
    this.mainMenu = mainMenu;
    this.appManagementService = appManagementService;
    this.mobileWorkFlowService = mobileWorkflowStoreService;
    this.startOver = startOver;
    this.storeMenuList = storeList;
    this.mobileStoreAppList = mobileStoreAppList;
    this.selectedStoreId = selectedStoreId;
    this._ref.detectChanges();
    const rows = <FormArray>form.controls.rows || new FormArray([]);

    const rowsIndex = rows.length + 1;

    const buttonArray = [];
    if (
      event.length === '3' &&
      (event.button_style !== this.rowStyles.SMALLHL &&
        event.button_style !== this.rowStyles.WIDELIFT)
    ) {
      const btn1 = this.getButton(event, 0);
      const btn2 = this.getButton(event, 1);
      const btn3 = this.getButton(event, 2);
      buttonArray.push(btn1);
      buttonArray.push(btn2);
      buttonArray.push(btn3);
    } else if (event.length === '2') {
      const btn1 = this.getButton(event, 0);
      const btn2 = this.getButton(event, 1);
      buttonArray.push(btn1);
      buttonArray.push(btn2);
    } else {
      buttonArray.push(this.getButton(event, 0));
    }

    const rowButtons = this.setRow(
      {
        buttons: buttonArray
      },
      rowsIndex
    );

    rows.push(rowButtons);

    if (startOver) {
      rows.value.forEach(res => {
        this.createdButtonsOnClick(
          res.buttons,
          selectedTab,
          form,
          startOver,
          menusAppClass,
          currentTabs
        );
      });
      this.onSubmit();
    }

    appManagementService._selectedMenu.next(form.value);
    if (bundle) {
      const newMenuList = [];

      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            if (store.data) {
              updatedStore.data = store.data;
            } else {
              updatedStore.data = {};
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = [this.form.value];
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);
    }
    this.mobileWorkFlowService._selectedMenu.next(form.value);
    this._ref.detectChanges();
  }

  addNewButtonInRow(event) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][this.rowNumber];

    const buttons = <FormArray>control['controls'].buttons;
    const rowIndex = this.rowNumber + 1;
    const colIndex = buttons.length + 1;
    if (event.length === 2) {
      const btn1 = this.getButton(event, 1);
      const btn2 = this.getButton(event, 2);
      buttons.push(this.setButton(btn1, rowIndex, colIndex));
      buttons.push(this.setButton(btn2, rowIndex, colIndex));
    }
    if (event.length === 1) {
      const btn1 = this.getButton(event, 1);
      buttons.push(this.setButton(btn1, rowIndex, colIndex));
    }
    this.createdButtons(buttons.value);
    this.showaddButtonSelector();

    this.formEmitter.emit(this.form);
  }

  createdButtons(newButtons) {
    const id = this.form.get('menu_id').value;
    const buttons = [];
    let found = 0;
    if (
      this.startOver &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.menusAppClass.menus.length > 0 &&
      this.menusAppClass.menus[0] &&
      id === this.menusAppClass.menus[0].menu_id
    ) {
      for (let i = 0; i < newButtons.length; i++) {
        const result = this.newButtons.find(res => {
          if (res.button_id === newButtons[i].button_id) {
            found++;
            return res;
          }
        });
        const isExist = this.currentTabs.find(res => {
          if (res.key === newButtons[i].button_id) {
            found++;
            return res;
          }
        });
        if (found <= 0) {
          buttons.push(newButtons[i]);
        } else {
          found = 0;
        }
      }
      this.newButtons = [...this.newButtons, ...buttons];

      this.appManagementService._newCreatedButtons.next([...this.newButtons]);
    }
  }

  createdButtonsOnClick(
    newButtons,
    selectedTab,
    form,
    startOver,
    menusAppClass,
    currentTabs
  ) {
    this.selectedTab = selectedTab;
    const id = form.get('menu_id').value;
    const buttons = [];
    let found = 0;
    if (
      startOver &&
      menusAppClass.menus &&
      menusAppClass.menus.length > 0 &&
      menusAppClass.menus[0] &&
      id === menusAppClass.menus[0].menu_id
    ) {
      for (let i = 0; i < newButtons.length; i++) {
        const result = this.newButtons.find(res => {
          if (res.button_id === newButtons[i].button_id) {
            found++;
            return res;
          }
        });
        const isExist = currentTabs.find(res => {
          if (res.key === newButtons[i].button_id) {
            found++;
            return res;
          }
        });
        if (found <= 0) {
          buttons.push(newButtons[i]);
        } else {
          found = 0;
        }
      }
      this.newButtons = [...this.newButtons, ...buttons];

      this.appManagementService._newCreatedButtons.next([...this.newButtons]);
    }
  }

  addNewButton(rowNumber: number) {
    const rows = <FormArray>this.form.controls.rows;

    const control = rows['controls'][rowNumber];

    const buttons = <FormArray>control['controls'].buttons;
    const rowIndex = rowNumber + 1;
    const colIndex = buttons.length + 1;
    buttons.push(this.setButton({}, rowIndex, colIndex));

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.currency = null;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    // this.formEmitter.emit(this.form);
    this.rowNumber = rowNumber;
    this.buttonsLength = buttons.length;
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
    // this.showAddButton = true;
  }

  addNewOption(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const buttonControl = <FormArray>buttons['controls'][pos.col];
    const option = <FormArray>buttonControl['controls']['button_option'];
    const opt = this.getoption(buttonControl.value, 1)[0];

    option.push(this.setOption(opt, pos.row, pos.col));

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  addAsignOptionValue(pos, optionId) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const button = <FormArray>buttons['controls'][pos.col];
    const option = <FormArray>button['controls']['button_option'];
    const value: any[] = [];
    option.value.forEach(opt => {
      const optValue: BotMenuButtonValue = {};
      if (optionId === opt.id) {
        optValue.value = 'true';
        optValue.id = optionId;
      } else {
        optValue.value = 'false';
        optValue.id = opt.id;
      }
      value.push(optValue);
    });

    this.getButtonValue(pos).setValue(value);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  addAsignCheckValue(pos, optionId, event) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const button = <FormArray>buttons['controls'][pos.col];
    const option = <FormArray>button['controls']['button_option'];
    const value: any[] = [];

    option.value.forEach(opt => {
      const optValue: BotMenuButtonValue = {};
      if (optionId === opt.id) {
        optValue.value = event.target.checked + '';
        optValue.id = optionId;
        value.push(optValue);
      }
    });
    if (
      this.getButtonValue(pos).value &&
      this.getButtonValue(pos).value.length > 0 &&
      option.value.length > 0
    ) {
      const oldValues = this.getButtonValue(pos).value;
      oldValues.forEach(val => {
        if (val.id !== null && val.id !== optionId) {
          value.push(val);
        }
      });
    } else if (
      this.getButtonValue(pos).value &&
      this.getButtonValue(pos).value.length > 0
    ) {
      const oldValues = this.getButtonValue(pos).value;
      oldValues.forEach(val => {
        value.push(val);
      });
    }

    this.getButtonValue(pos).setValue(value);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;

            const defaultImage: CollectionUIImage = {};
            defaultImage.url =
              'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
            updatedStore.image = defaultImage;
            updatedStore.name = 'Default Store';
            updatedStore.desc = 'Default Store';

            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  updateOptionIdValue(pos, optionId, newId) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const button = <FormArray>buttons['controls'][pos.col];
    const option = <FormArray>button['controls']['button_option'];
    const value: any[] = [];
    option.value.forEach(opt => {
      const optValue: BotMenuButtonValue = {};
      if (optionId === opt.id) {
        optValue.id = newId;
        optValue.value = opt.value;
      } else {
        optValue.id = opt.id;
        optValue.value = opt.value;
      }
      value.push(optValue);
    });

    this.getButtonValue(pos).setValue(value);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  removeRow(rowNumber: number) {
    const rows = <FormArray>this.form.controls.rows;
    this.deletedButtonsFromNewButtons(rows.value[rowNumber]);
    if (this.currentTabs && this.currentTabs.length > 0) {
      this.deleteTab(rows.value[rowNumber].buttons);
    }
    if (this.selectedButton && this.selectedButton.row === rowNumber) {
      this.selectedButton = null;
    }

    rows.removeAt(rowNumber);

    this.formEmitter.emit(this.form);
  }

  deletedButtonsFromNewButtons(value) {
    for (let i = 0; i < value.buttons.length; i++) {
      this.newButtons = this.newButtons.filter(
        res => res.button_id !== value.buttons[i].button_id
      );
    }

    this.appManagementService._newCreatedButtons.next([...this.newButtons]);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
    // const newAppInfo: AppInfo = {};
    // newAppInfo.menuTabs = this.currentTabs;
    // this.appManagementService.updateAppInfo(newAppInfo);
  }
  removeButton(pos: { row: number; col: number }) {
    const btnId = this.getButtonId(pos).value;
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    buttons.removeAt(pos.col);
    if (buttons.length === 0) {
      rows.removeAt(pos.row);
    }
    this.selectedButton = null;

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    if (this.getTabContainerByButtonId(btnId)) {
      this.deleteContainer(this.getTabContainerByButtonId(btnId));
    }
    if (
      this.tabsList ||
      this.itemsList ||
      this.section === this.uiSection.MOBILESTORE
    ) {
      this.onSubmit();
    }
  }
  setSelectedBtn(pos: { row: number; col: number }) {
    this.showSubMenus = true;
    this.showBottonConfig = true;
    const butttonId = this.getButtonId(pos).value;
    let tab: LocalContainer = {};
    if (this.tabsList) {
      this.tabsList.forEach(res => {
        if (res.id === butttonId && res.type !== '' && res.type !== null) {
          this.showSubMenus = false;
          tab = res;
        }
      });
    } else if (this.itemsList) {
      this.itemsList.forEach(res => {
        if (res.id === butttonId && res.link !== '' && res.link !== null) {
          this.showSubMenus = false;
          tab = res;
        }
      });
    }
    this.appManagementService._selectedPageIdToView.next(tab.page_id);
    this.feature = this.getFeatureModule(tab.ui_module_id);
    this.selectedButton = { ...this.selectedButton, ...pos };
    this.selecteButtonNotify.emit(this.selectedButton);
    this.appManagementService._selectedMenuButton.next(this.selectedButton);
    const buttonCat = this.getButtonForm(pos).value;
    this.appManagementService._selectedMenuButtonCat.next(buttonCat);
    this.showConfigButtonNotify.emit(this.showBottonConfig);
  }
  setOutOutSelectedBtn(pos: { row: number; col: number }) {
    this.showSubMenus = true;
    this.showBottonConfig = true;
    const butttonId = this.getButtonId(pos).value;
    if (this.tabsList) {
      this.tabsList.forEach(res => {
        if (res.id === butttonId && res.type !== '' && res.type !== null) {
          this.showSubMenus = false;
        }
      });
    } else if (this.itemsList) {
      this.itemsList.forEach(res => {
        if (res.id === butttonId && res.link !== '' && res.link !== null) {
          this.showSubMenus = false;
        }
      });
    }
    const buttonCat = this.getButtonForm(pos).value;
    this.selectedButton = { ...this.selectedButton, ...pos };
    this.appManagementService._selectedMenuButton.next(this.selectedButton);
    this.appManagementService._selectedMenuButtonCat.next(buttonCat);
    this._ref.detectChanges();
  }
  resetSelectedBtn() {
    this.showBottonConfig = false;
    this.selectedButton = null;
    this.selecteButtonNotify.emit(null);
    this.showConfigButtonNotify.emit(this.showBottonConfig);
    this.appManagementService._selectedMenuButton.next(this.selectedButton);
    this.formEmitter.emit(this.form);
  }

  updatedButtonTitle(col, title) {
    for (let i = 0; i < this.currentTabs.length; i++) {
      if (this.currentTabs[i].key === col.button_id) {
        const newContainer: LocalContainer = {};
        newContainer.title = title;
        newContainer.title_ios = title;
        this.appManagementService.updateContainer(
          this.currentTabs[i].value,
          newContainer
        );
      } else {
        const newContainer: LocalContainer = {};
        newContainer.title = title;
        newContainer.title_ios = title;

        newContainer.layout = 'button';
        newContainer.id = col.button_id;
        this.appManagementService.updateContainer(
          this.currentTabs[i].value,
          newContainer
        );
      }
    }

    for (let i = 0; i < this.newButtons.length; i++) {
      if (this.newButtons[i].button_id === col.button_id) {
        this.newButtons[i].button_label = title;
      }
    }

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    this.newformEmitter.emit(this.form);
  }

  updatedButtonTabTitle(col, title) {
    for (let i = 0; i < this.tabsList.length; i++) {
      if (this.tabsList[i].id === col.button_id) {
        const newContainer: LocalContainer = {};
        newContainer.title = title;
        newContainer.title_ios = title;
        this.appManagementService.updateContainer(
          this.tabsList[i].ref,
          newContainer
        );
      }
    }

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    this.newformEmitter.emit(this.form);
  }

  updatedButtonDesc(col, desc) {
    for (let i = 0; i < this.currentTabs.length; i++) {
      if (this.currentTabs[i].key === col.button_id) {
        const newContainer: LocalContainer = {};
        newContainer.desc = desc;
        this.appManagementService.updateContainer(
          this.currentTabs[i].value,
          newContainer
        );
      } else {
        const newContainer: LocalContainer = {};
        newContainer.desc = desc;
        newContainer.layout = 'button';

        newContainer.id = col.button_id;
        this.appManagementService.updateContainer(
          this.currentTabs[i].value,
          newContainer
        );
      }
    }
    for (let i = 0; i < this.newButtons.length; i++) {
      if (this.newButtons[i].button_id === col.button_id) {
        this.newButtons[i].button_description = desc;
        this.newButtons[i].button_sublabel = desc;
      }
    }

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    this.newformEmitter.emit(this.form);
  }
  updatedButtonValue(col, val, pos) {
    const newObj: any = {};
    for (let i = 0; i < this.newButtons.length; i++) {
      if (this.newButtons[i].button_id === col.button_id) {
        newObj.id = null;
        newObj.value = val;
        this.newButtons[i].button_value = [newObj];
      }
    }
    newObj.id = null;
    newObj.value = val;
    this.getButtonValue(pos).setValue([newObj]);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }
  updatedButtonCallback(val, pos) {
    this.getButtonCallback(pos).setValue(val);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }
  updatedButtonKeyboard(col, val, pos) {
    this.getButtonKeyboard(pos).setValue(val);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonAccess(col, val, pos) {
    if (val === 'null') {
      val = null;
    }
    this.getButtonAccess(pos).setValue(val);
    this.appManagementService._selectedMenu.next(this.form.value);
    this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    this.newformEmitter.emit(this.form);
  }

  updatedButtonLogin(col, val, pos) {
    if (val === 'null') {
      val = null;
    }
    this.getButtonLogin(pos).setValue(val);
    this.appManagementService._selectedMenu.next(this.form.value);
    this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    this.newformEmitter.emit(this.form);
  }

  updatedButtonActionType(col, val, pos) {
    for (let i = 0; i < this.newButtons.length; i++) {
      if (this.newButtons[i].button_id === col.button_id) {
        this.newButtons[i].button_type = val.target.value;
      }
    }
    this.getButtonActionType(pos).setValue(val.target.value);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    this.newformEmitter.emit(this.form);
  }
  getButtonNextMenu(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('next_menu');
  }
  getButtonNextPage(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_next_page');
  }
  getButtonNextMenuOnClick(pos, form) {
    const rows = <FormArray>form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('next_menu');
  }

  getButtonOptionValue(pos, optId) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    let value = '';

    if (!this.getButtonValue(pos).value[0]) {
      if (!this.getButtonValue(pos).value.value) {
        const newObj: any = {};
        newObj.id = null;
        newObj.value = this.getButtonValue(pos).value;
        const list = [];
        list.push(newObj);

        this.getButtonValue(pos).setValue(list);
      } else if (
        this.getButtonValue(pos).value.value &&
        this.getButtonValue(pos).value.id
      ) {
        const list = [];

        list.push(this.getButtonValue(pos).value);
        this.getButtonValue(pos).setValue(list);
      }
    }

    if (
      this.getButtonValue(pos).value &&
      this.getButtonValue(pos).value !== null &&
      this.getButtonValue(pos).value[0]
    ) {
      this.getButtonValue(pos).value.forEach(res => {
        if (res.id === optId) {
          value = res.value;
        }
      });
      return value;
    }
  }
  getButtonValue(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;

    return <FormArray>buttons['controls'][pos.col].get('button_value');
  }
  getButtonCallback(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;

    return <FormArray>buttons['controls'][pos.col].get('button_callback');
  }
  getButtonOptionImage(pos, optId) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    let image = './assets/img/empty.jpg';

    buttons['controls'][pos.col].get('button_option').value.find(res => {
      if (res.id === optId && res.image && res.image !== null) {
        image = res.image;
      }
    });

    return image;
  }

  changeButtonOptionDesc(pos, optId, value) {
    const newOptions: BotMenuOption[] = [];
    this.getButtonOption(pos).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === optId) {
        newOption = opt;
        newOption.sublabel = value;
        newOptions.push(newOption);
      } else {
        newOptions.push(opt);
      }
    });
    this.getButtonOption(pos).setValue(newOptions);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  changeButtonOptionValue(pos, optId, value) {
    const newOptions: BotMenuOption[] = [];
    this.getButtonOption(pos).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === optId) {
        newOption = opt;
        newOption.value = value;
        newOptions.push(newOption);
      } else {
        newOptions.push(opt);
      }
    });
    this.getButtonOption(pos).setValue(newOptions);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  removeButtonOption(pos, index) {
    const btnId = this.getButtonId(pos).value;
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const buttonControl = <FormArray>buttons['controls'][pos.col];
    const option = <FormArray>buttonControl['controls']['button_option'];
    option.removeAt(index);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    this.deleteContainer(this.getTabContainerByButtonId(btnId));
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  changeButtonOptionLabel(pos, optId, value) {
    const newOptions: BotMenuOption[] = [];

    this.getButtonOption(pos).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === optId) {
        newOption = opt;
        newOption.label = value;
        newOptions.push(newOption);
      } else {
        newOptions.push(opt);
      }
    });

    this.getButtonOption(pos).setValue(newOptions);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  changeButtonOptionImage(value) {
    const newOptions: BotMenuOption[] = [];
    this.getButtonOption(this.optionBtn).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === this.optionBtnOptId) {
        newOption = opt;
        newOption.image = value.url;
        newOptions.push(newOption);
      } else {
        newOptions.push(opt);
      }
    });
    this.getButtonOption(this.optionBtn).setValue(newOptions);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  changeButtonOptionIcon(pos, optId, value) {
    const newOptions: BotMenuOption[] = [];
    this.getButtonOption(pos).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === optId) {
        newOption = opt;
        newOption.icon = value;
        newOptions.push(newOption);
      } else {
        newOptions.push(opt);
      }
    });
    this.getButtonOption(pos).setValue(newOptions);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }
  changeButtonOptionId(pos, optId, value) {
    const newOptions: BotMenuOption[] = [];
    let validation = true;
    this.updateOptionIdValue(pos, optId, value);
    this.getButtonOption(pos).value.forEach(opt => {
      let newOption: BotMenuOption = {};
      if (opt.id === optId) {
        newOption = opt;
        newOption.id = value;
        newOptions.push(newOption);
        if (value === '' || value === null) {
          validation = false;
        }
      } else {
        newOptions.push(opt);
        if (opt.id === '' || opt.id === null) {
          validation = false;
        }
      }
    });
    this.getButtonOption(pos).setValue(newOptions);

    this.appManagementService._optionFormNotVaild.next(validation);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.currency = null;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.formEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }
  deleteNextMenu(pos) {
    this.getButtonNextMenu(pos).setValue(null);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.currency = null;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }
  deleteNextPage(pos) {
    this.getButtonNextPage(pos).setValue(null);
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }
  getButtonUrl(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_url');
  }

  getButtonUrlOnClick(pos, form) {
    const rows = <FormArray>form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_url');
  }
  getButtonTitle(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_label');
  }

  getButtonAccess(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_access');
  }

  getButtonLogin(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_login');
  }

  updatedButtonNextMenu(pos, menuId, col) {
    if (this.nextMenuSelected) {
      this.getButtonNextMenu(pos).setValue(menuId);
      const currentTab: any = this.getTabContainer(col);
      const newContainer: LocalContainer = {};
      newContainer.type = null;
      if (currentTab) {
        this.appManagementService.updateContainer(currentTab.ref, newContainer);
      }
      this.getButtonUrl(pos).setValue(null);

      if (
        this.section === this.uiSection.MOBILESTORE ||
        ((this.selectedTab.type === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedTab.link === this.tabTypes.MENUS &&
            this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedButtonTab &&
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
        this.selectedButtonTab
      ) {
        const newMenuList = [];
        if (this.storeMenuList && this.storeMenuList.length > 0) {
          this.storeMenuList.forEach(menu => {
            if (menu.menu_id === this.form.value.menu_id) {
              newMenuList.push(this.form.value);
            } else {
              newMenuList.push(menu);
            }
          });
        } else {
          newMenuList.push(this.form.value);
        }
        const newMobileAppList: any[] = [];
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (store.id !== this.selectedStoreId) {
              newMobileAppList.push(store);
            } else {
              const updatedStore: any = {};
              updatedStore.id = store.id;
              updatedStore.currency = store.currency;
              updatedStore.is_default = store.is_default;
              updatedStore.data = store.data;
              updatedStore.isUpdated = 1;
              if (store.image) {
                const defaultImage: CollectionUIImage = {};
                defaultImage.url =
                  'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
                updatedStore.image = defaultImage;
                updatedStore.name = 'Default Store';
                updatedStore.desc = 'Default Store';
              }
              updatedStore.data.shop = newMenuList;
              newMobileAppList.push(updatedStore);
            }
          });
        } else {
          const updatedStore: any = {};
          updatedStore.id = undefined;
          updatedStore.is_default = 1;
          updatedStore.isUpdated = 1;
          updatedStore.currency = null;

          const defaultImage: CollectionUIImage = {};
          defaultImage.url =
            'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
          updatedStore.image = defaultImage;
          updatedStore.name = 'Default Store';
          updatedStore.desc = 'Default Store';

          updatedStore.data = {};
          updatedStore.data.menu_id = this.form.value.menu_id;
          updatedStore.data.shop = newMenuList;
          newMobileAppList.push(updatedStore);
        }

        this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
        this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

        this.mobileWorkFlowService._selectedMenu.next(this.form.value);
      }
      if (
        this.section === this.uiSection.MOBILESTORE ||
        ((this.selectedTab.type === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedTab.link === this.tabTypes.MENUS &&
            this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedButtonTab &&
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
        this.selectedButtonTab
      ) {
        const newList = [];
        this.storeMenuList.forEach(men => {
          if (men.menu_id === this.form.value.menu_id) {
            newList.push(this.form.value);
          } else {
            newList.push(men);
          }
        });
        const newMobileAppList: any[] = [];
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (store.id !== this.selectedStoreId) {
              newMobileAppList.push(store);
            } else {
              const updatedStore: any = {};
              updatedStore.id = store.id;
              updatedStore.currency = store.currency;
              updatedStore.is_default = store.is_default;
              updatedStore.data = store.data;
              updatedStore.isUpdated = 1;
              if (store.image) {
                const defaultImage: CollectionUIImage = {};
                defaultImage.url =
                  'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
                updatedStore.image = defaultImage;
                updatedStore.name = 'Default Store';
                updatedStore.desc = 'Default Store';
              }
              updatedStore.data.shop = newList;
              newMobileAppList.push(updatedStore);
            }
          });
        } else {
          const updatedStore: any = {};
          updatedStore.id = undefined;
          updatedStore.is_default = 1;
          updatedStore.isUpdated = 1;
          updatedStore.currency = null;

          const defaultImage: CollectionUIImage = {};
          defaultImage.url =
            'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
          updatedStore.image = defaultImage;
          updatedStore.name = 'Default Store';
          updatedStore.desc = 'Default Store';

          updatedStore.data = {};
          updatedStore.data.menu_id = this.form.value.menu_id;
          updatedStore.data.shop = newList;
          newMobileAppList.push(updatedStore);
        }

        this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
        this.mobileWorkFlowService._selectedMenuList.next(newList);
      }
      if (!this.selectedButtonTab) {
        this.appManagementService._selectedMenuButtonTab.next(false);

        this.appManagementService._selectedMenu.next(this.form.value);
        this.newformEmitter.emit(this.form);
      }
      this.showMenuScreens(false);
    }
  }

  updatedButtonNextPage(event, pos, menuId) {
    const newNextPage: any = {};
    newNextPage.type = 'collection';
    newNextPage.id = event.id;

    if (event.soft_id) {
      newNextPage.soft_id = event.soft_id;
    } else {
      newNextPage.soft_id = null;
    }
    this.getButtonNextPage(pos).setValue(newNextPage);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
      this.selectedButtonTab
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.currency = store.currency;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
      this.selectedButtonTab
    ) {
      const newList = [];
      this.storeMenuList.forEach(men => {
        if (men.menu_id === this.form.value.menu_id) {
          newList.push(this.form.value);
        } else {
          newList.push(men);
        }
      });
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newList);
    }
    if (!this.selectedButtonTab) {
      this.appManagementService._selectedMenuButtonTab.next(false);

      this.appManagementService._selectedMenu.next(this.form.value);
      this.newformEmitter.emit(this.form);
    }
    this.showMenuCollections(false);
  }

  updatedButtonNextPageSoftId(val, pos) {
    const newNextPage: any = this.getButtonNextPage(pos).value;
    newNextPage.soft_id = val;
    this.getButtonNextPage(pos).setValue(newNextPage);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
      this.selectedButtonTab
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.currency = store.currency;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE)) ||
      this.selectedButtonTab
    ) {
      const newList = [];
      this.storeMenuList.forEach(men => {
        if (men.menu_id === this.form.value.menu_id) {
          newList.push(this.form.value);
        } else {
          newList.push(men);
        }
      });
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newList);
    }
    if (!this.selectedButtonTab) {
      this.appManagementService._selectedMenuButtonTab.next(false);

      this.appManagementService._selectedMenu.next(this.form.value);
      this.newformEmitter.emit(this.form);
    }
  }

  updatedButtonURL(col, url, pos, base) {
    for (let i = 0; i < this.newButtons.length; i++) {
      if (this.newButtons[i].button_id === col.button_id) {
        this.newButtons[i].button_url = url;
      }
    }
    this.getButtonNextMenu(pos).setValue(null);
    const currentTab: any = this.getTabContainer(base);
    const newContainer: LocalContainer = {};
    newContainer.type = null;
    if (currentTab) {
      this.appManagementService.updateContainer(currentTab.ref, newContainer);
    }
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.currency = null;
        updatedStore.data = {};
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }

    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newList = [];
      this.storeMenuList.forEach(men => {
        if (men.menu_id === this.form.value.menu_id) {
          newList.push(this.form.value);
        } else {
          newList.push(men);
        }
      });
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.data = {};

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.isUpdated = 1;
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newList);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonIcon(pos, iconName) {
    this.getIconName(pos).setValue(iconName);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonIconColor(pos, iconColor) {
    this.getIconColor(pos).setValue(iconColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonActionColor(pos, actionColor) {
    this.getActionColor(pos).setValue(actionColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonView(pos, view) {
    this.getButtonView(pos).setValue(view);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonBorderColor(pos, borderColor) {
    this.getButtonBorderColor(pos).setValue(borderColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }
  updatedButtonTextColor(pos, textColor) {
    this.getTextColor(pos).setValue(textColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonBasicTextColor(pos, textColor) {
    this.getBaseTextColor(pos).setValue(textColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonSubLabelColor(pos, textColor) {
    this.getSubLabelColor(pos).setValue(textColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonBgColor(pos, bgColor) {
    this.getBgColor(pos).setValue(bgColor);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
  }

  updatedButtonBgColorOffOrOn(pos, event) {
    if (!event.target.checked) {
      this.getBgColor(pos).setValue(null);

      this.appManagementService._selectedMenu.next(this.form.value);
      this.newformEmitter.emit(this.form);
    }
  }
  /*************************************** */

  getTextColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_label_color');
  }

  getBaseTextColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_textcolor');
  }

  getSubLabelColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_sublabel_color');
  }
  getImgUrl(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_img_url');
  }
  getBgColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_bgcolor');
  }
  getIconColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_icon_bgcolor');
  }

  getActionColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_option_color');
  }
  getButtonView(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_newpage');
  }

  getButtonDB(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_db');
  }

  getIconName(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const value = buttons['controls'][pos.col].get('button_icon');

    return buttons['controls'][pos.col].get('button_icon');
  }

  getButtonId(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_id');
  }

  getButtonStyles(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_style');
  }
  getButtonActionColor(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_option_color');
  }
  getButtonForm(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_form');
  }

  getButtonAsbectratio(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    const buttonsLength = buttons.value.length;
    const bgButtonColor = buttons['controls'][pos.col].get('button_bgcolor')
      .value;
    this.selectedBgColor = bgButtonColor
      ? bgButtonColor.replace('#', '')
      : null;
    const asbectratio = buttons['controls'][pos.col].get('button_asbect_ratio')
      .value;

    return asbectratio && asbectratio.length > 0
      ? asbectratio.length < buttonsLength
        ? asbectratio[asbectratio.length - 1]
        : asbectratio[buttonsLength - 1]
      : null;
  }

  getButtonKeyboard(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_keyboard');
  }
  getButtonOption(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_option');
  }

  getButtonActionType(pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_type');
  }
  getButtonBorderColor(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_border_color');
  }

  get(pos) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    return buttons['controls'][pos.col].get('button_border_color');
  }

  checkButtonHasTab(buttonId) {
    let valid = false;

    this.currentTabs.forEach(res => {
      if (res.key === buttonId) {
        valid = true;
      }
    });

    return valid;
  }
  showRowsSelector() {
    this.showRows = !this.showRows;
  }
  showaddButtonSelector() {
    this.showAddButton = !this.showAddButton;
    if (!this.showAddButton) {
      this.buttonsLength = 0;
    }
  }

  newMenusList(index) {
    const newMenusLst: BotMenu[] = [];

    for (let i = 0; i < this.menusAppClass.menus.length; i++) {
      if (i == index) {
        const newMenu = this.form.value;

        newMenusLst.push(newMenu);
      } else {
        const newMenu = this.menusAppClass.menus[i];

        newMenusLst.push(newMenu);
      }
    }
    return newMenusLst;
  }

  setButtonNewPage(event, pos) {
    if (event.target.checked) {
      this.getButtonView(pos).setValue(true);
    } else {
      this.getButtonView(pos).setValue(false);
    }
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newList = [];
      this.storeMenuList.forEach(men => {
        if (men.menu_id === this.form.value.menu_id) {
          newList.push(this.form.value);
        } else {
          newList.push(men);
        }
      });
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newList);
    }
    this.newformEmitter.emit(this.form);
    // this.onSubmit();
  }

  setButtonDB(event, pos) {
    if (event.target.checked) {
      this.getButtonDB(pos).setValue(1);
    } else {
      this.getButtonDB(pos).setValue(0);
    }
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newList = [];
      this.storeMenuList.forEach(men => {
        if (men.menu_id === this.form.value.menu_id) {
          newList.push(this.form.value);
        } else {
          newList.push(men);
        }
      });
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.data.shop = newList;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newList);
    }
    this.newformEmitter.emit(this.form);
    // this.onSubmit();
  }

  // deleteOldTabs() {
  //   this.tabsList.forEach(result => {
  //     this.appManagementService.deleteActulContainer(result);
  //   });
  // }
  addTabs() {
    if (this.menusAppClass) {
      let mainMenu: BotMenu = {};
      let maianMenuAppClass: AppClass = {};

      mainMenu = this.menusAppClass.menus[0];

      maianMenuAppClass = this.menusAppClass;
      const menucontainer: LocalContainer = {};
      menucontainer.type = this.tabTypes.MENUS;
      menucontainer.menu_id = maianMenuAppClass.ref;
      this.appManagementService.addContainer(this.appClass, menucontainer);
      let buttonId = 0;

      for (let i = 0; i < mainMenu.rows.length; i++) {
        for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
          buttonId++;
          const buttonContainer: LocalContainer = {};
          buttonContainer.type = '';
          buttonContainer.layout = 'button';

          buttonContainer.id = mainMenu.rows[i].buttons[j].button_id;
          buttonContainer.menu_id = mainMenu.menu_ref;
          buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
          buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

          this.appManagementService.addContainer(
            this.appClass,
            buttonContainer
          );
        }
      }
    }
  }
  onFilesSelected(ev, component) {
    const event: ISelectedFiles = ev;
    this.uploaded = true;
    this.uploadNotify.emit(this.uploaded);

    if (event.localFile) {
      if (this.validateImage(event.localFile)) {
        this._fileService
          .readFileAsArrayBuffer(event.localFile)
          .then(fileAsArrayBuffer => {
            this._fileService
              .readArrayBufferAsBlobUrl(fileAsArrayBuffer, event.localFile.type)
              .then(blobUrl => {
                this._fileService.getImageMetadata(blobUrl).then(props => {
                  if (props.width !== props.height) {
                    this.uploaded = false;

                    this.uploadNotify.emit(this.uploaded);

                    this._uiDispatchers.showPopup(
                      ERROR_IMAGE_SHOULD_BE_1024X1024
                    );
                  } else {
                    this._uploadGateway
                      .uploadWithProgress(
                        fileAsArrayBuffer,
                        event.localFile.type,
                        event.localFile.name,
                        false,
                        SPLASH_PAGE_UPLOAD_TYPE,
                        null,
                        null,
                        true
                      )
                      .subscribe(
                        e => {
                          if (e.type === HttpEventType.Response) {
                            const res = <IUploadResponseImage>e.body;
                            let imageUrl = '';
                            if (res.files[4]) {
                              imageUrl = res.files[4].url;
                            }

                            const imageSet: ImageSet = {};
                            imageSet.hdpi = res.files[0].url;
                            imageSet.mdpi = res.files[1].url;
                            imageSet.xhdpi = res.files[2].url;
                            imageSet.xxhdpi = res.files[3].url;
                            imageSet.xxxhdpi = res.files[4].url;
                            imageSet.ios1x = res.files[5].url;
                            imageSet.ios2x = res.files[6].url;
                            imageSet.ios3x = res.files[7].url;

                            this.reciveImageUrl(imageUrl, component);
                            this.uploaded = false;

                            this.uploadNotify.emit(this.uploaded);
                          }
                        },

                        error => {
                          this.uploaded = false;

                          this.uploadNotify.emit(this.uploaded);
                          this._ref.detectChanges();

                          this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                        }
                      );
                  }
                });
              });
          });
      }

      this.closeEvent();
    }
  }
  private validateImage(selectedFile): boolean {
    if (!selectedFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (selectedFile.type.indexOf('image/png') === -1) {
      this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.size)
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    }
    return true;
  }

  openPreviewMedia(event: ISelectedFiles, pos: { row: number; col: number }) {
    this._botMenusService.uploadMenuImgLogo(pos, event);
  }

  reciveImageUrl(imageUrl, pos: { row: number; col: number }) {
    const rows = <FormArray>this.form.controls.rows;
    const control = rows['controls'][pos.row];
    const buttons = <FormArray>control['controls'].buttons;
    buttons['controls'][pos.col].get('button_img_url').setValue(imageUrl);
    this._ref.detectChanges();

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
    return buttons['controls'][pos.col].get('button_img_url');
  }

  check() {
    const rows = <FormArray>this.form.controls.rows;
    if (
      this.form &&
      this.form.get('menu_name').value &&
      !this.form.get('menu_id').value &&
      !this.tabsList &&
      rows.length > 0
    ) {
      return false;
    } else if (this.form && this.form.get('menu_id').value && rows.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  checkUpload() {
    if (this.uploaded) {
      return true;
    } else {
      return false;
    }
  }
  showConfirmation(value) {
    // this.startOverFirstValidation = undefined;
    // this._ref.detectChanges();
    this.closeMenuConfNotify.emit();
  }
  secConfirmation(value) {
    this.startOverSecValidation = value;
    this.closeMenuConfNotify.emit();
    // this.startOverFirstValidation = undefined;
  }
  saveMenu(save) {
    // if (this.check()) {
    //   this._uiDispatchers.showPopup(
    //     'This is an incompleted menu , Failed to save'
    //   );
    // } else {
    this.subMenu = true;
    if (save) {
      this.onSubmit();
      if (this.section !== this.uiSection.MOBILESTORE) {
        this.appManagementService.savePage(false);
      }
    } else {
      if (this.subMenu) {
        this.subMenu = false;

        this.cancelMenu();
      }
      // }
    }
    this.closeMenuConfNotify.emit();
  }
  cancelMenu() {
    this.form = this.setFormEmptyGroup();

    this.appManagementService._selectedMenu.next(this.form.value);
    this.mobileWorkFlowService._selectedMenu.next(this.form.value);

    if (
      (this.selectedTab && this.selectedTab.sub_type) ||
      this.section === this.uiSection.MOBILESTORE
    ) {
      const newMenuList = [];

      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          newMenuList.push(menu);
        });
      }
      newMenuList.push(this.form.value);
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.isUpdated = 1;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);
    }
    this.newformEmitter.emit(this.form);
  }

  findMenuToBeUpdated(id) {
    if (this.menusAppClass && this.menusAppClass.menus) {
      for (let i = 0; i < this.menusAppClass.menus.length; i++) {
        if (id === this.menusAppClass.menus[i].menu_id) {
          return i;
        }
      }
    }
    return null;
  }

  dropHandler(event) {
    const data = event.dataTransfer.getData('text');
    const feature = [];
    data.split(',').forEach(feat => {
      if (feat === 'null' || feat === 'undefined') {
        feature.push(null);
      } else {
        feature.push(feat);
      }
    });

    // const feature = data.split(',');
    const item: any = {};
    let title = '';

    if (feature[11] === this.menuCat.SUBMIT) {
      title = 'Submit';
    } else if (
      feature[11] === this.menuCat.SEPARATOR &&
      feature[0] === this.rowStyles.EMPTY
    ) {
      title = null;
    } else {
      title = 'Title';
    }

    if (feature.length > 1) {
      item.button_label = title;
      item.button_textcolor = feature[7];
      item.button_label_color = feature[7];
      item.button_sublabel_color = feature[7];
      item.button_bgcolor = feature[5];
      item.button_description = 'Your description.';
      item.button_style = feature[0];
      item.button_img_url = feature[3];
      item.url_2 = feature[9];
      item.url_3 = feature[10];
      item.url = feature[3];
      item.bg_color_2 = feature[6];
      item.bg_color_3 = feature[4];
      item.length = feature[8];
      item.button_form = feature[11];
      item.button_type = feature[12];
      item.button_sublabel = 'Your description.';
      if (feature[13]) {
        item.button_sublabel = feature[13];
      }
      if (feature[14]) {
        item.button_icon = feature[14];
      }
      if (feature[15]) {
        item.button_icon_bgcolor = feature[15];
      }
      if (feature[16]) {
        item.button_border_color = feature[16];
      }
      const newObj: any = {};
      newObj.id = null;
      newObj.value = '20$';
      const valueList = [];
      valueList.push(newObj);
      item.button_value = [];
      item.button_keyboard = 'text';

      if (feature[18] !== null && feature[18]) {
        item.aspectRatio = feature[18].split('-');
      }
      this.addNewRow(item);
    }
  }

  getTabContainer(menuCol) {
    if (this.tabsList) {
      const container: LocalContainer = this.tabsList.find(
        res => res.id === menuCol.get('button_id').value
      );

      return container;
    }

    return false;
  }

  getTabContainerByButtonId(buttonId) {
    if (this.tabsList) {
      const container: LocalContainer = this.tabsList.find(
        res => res.id === buttonId
      );

      return container;
    }
    return false;
  }

  onDrop(event, buttonId, pos) {
    this.getButtonNextMenu(pos).setValue(null);
    this.getButtonUrl(pos).setValue(null);
    this._ref.detectChanges();
    this.newformEmitter.emit(this.form);
    let data = event.dataTransfer.getData('text');

    const feature = data.split(',');
    this.appManagementService._mypageButtonId.next(
      buttonId.get('button_id').value
    );

    if (feature[0] === 'createNewPage') {
      this.appManagementService.createMyPage(
        'New Page',
        null,
        null,
        null,
        null,
        this.pageRef.BTN
      );
      this.appManagementService._mypageSection.next(this.currentPage);
    } else {
      data = data + ',' + buttonId.get('button_id').value;

      this.dropEmitter.emit(data);
    }
  }

  onClickFeature(pos) {
    // this.getButtonNextMenu(pos).setValue(null);
    // this.getButtonUrl(pos).setValue(null);

    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.currency = store.currency;
            updatedStore.isUpdated = 1;
            updatedStore.is_default = store.is_default;
            if (store.data) {
              updatedStore.data = store.data;
            } else {
              updatedStore.data = {};
            }
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this._ref.detectChanges();
    // this.onSubmit();
  }

  deleteButton(pos: { row: number; col: number }) {
    this.deleteButtonNotifiy.emit(pos);
  }

  deleteContainer(container) {
    if (this.tabsList) {
      this.appManagementService.deleteContainer(container);
    }

    this.appManagementService._selectedMenuButtonTab.next(false);
  }

  getFeatureModule(value) {
    return (this.feature = this.getFeature.getModuleData(value));
  }

  countTabFeatures(value) {
    this.feature = this.getFeature.getModuleData(value);
    let activeFeatures = 0;
    this.feature.features.forEach(feat => {
      if (feat.default) {
        activeFeatures++;
      }
    });
    return activeFeatures;
  }

  get selectItem() {
    if (this.feature && this.feature.icon) {
      return './assets/svg/app-features-icons.svg#' + this.feature.icon;
      // return './assets/svg/app-features-icons.svg#';
    }
  }

  selectItem2(icon) {
    return './assets/svg/app-icons.svg#' + icon;
  }

  showSetting(container) {
    this.showSettings = true;
    this.selectedFeature = this.getFeature.getModuleData(
      container.ui_module_id
    );

    this.appManagementService._selectedLastMenu.next(this.form.value);
    this.appManagementService._selectedMenuButtonTab.next(container);
    this.showSettingsNotify.emit(container);
  }
  getSubMenuTitle(id) {
    let subMenuTitle = '';

    this.menusSubscribe = this.appManagementService.menusAppClass$.subscribe(
      res => {
        if (res && res.menus) {
          res.menus.forEach(r => {
            if (r.menu_id === id) {
              subMenuTitle = r.menu_name;
            }
          });
        }
      }
    );
    return subMenuTitle;
  }
  subMenuSelect(id) {
    this.subMenuSelectNotify.emit(id);
  }
  removeParentId($event) {
    this.removeParentIdNotify.emit();
  }
  deleteMainMenu(tab, menu) {
    if (
      tab.type === this.tabTypes.MENUS &&
      this.currentPage === this.appScreens.TABS
    ) {
      const menus = [];
      this.menusAppClass.menus.forEach(res => {
        if (res.menu_id !== tab.menu_id) {
          menus.push(res);
        } else {
          menus.push(menu);
          res.rows.forEach(row => {
            row.buttons.forEach(btn => {
              let bTab: LocalContainer = {};
              bTab = this.tabsList.filter(tb => {
                if (tb.id == btn.button_id) {
                  return tb;
                }
              });

              if (bTab && bTab[0]) {
                this.appManagementService.deleteContainer(bTab[0]);
              }
            });
          });
        }
      });
      const menuAppClass: LocalAppClass = {};
      menuAppClass.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        menuAppClass
      );
    } else if (
      tab.link === this.tabTypes.MENUS &&
      this.currentPage === this.appScreens.NAV_MENU
    ) {
      const menus = [];
      this.menusAppClass.menus.forEach(res => {
        if (res.menu_id !== tab.menu_id) {
          menus.push(res);
        } else {
          menus.push(menu);
          res.rows.forEach(row => {
            row.buttons.forEach(btn => {
              let bTab: any = {};
              bTab = this.tabsList.filter(tb => {
                if (tb.id == btn.button_id) {
                  return tb;
                }
              });

              if (bTab && bTab[0]) {
                this.appManagementService.deleteContainer(bTab[0]);
              }
            });
          });
        }
      });
      const menuAppClass: LocalAppClass = {};
      menuAppClass.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        menuAppClass
      );
    }
    // const newTab: LocalContainer = {};
    // newTab.menu_id = '';
    // this.appManagementService.updateContainer(tab.ref, newTab);
  }

  deleteSubMenu(menu) {
    const menus = [];
    this.menusAppClass.menus.forEach(res => {
      if (res.menu_id !== menu.menu_id) {
        menus.push(res);
      } else {
        menus.push(menu);
        this.currentMenu.rows.forEach(row => {
          if (row) {
            row.buttons.forEach(btn => {
              if (this.tabsList) {
                let bTab: LocalContainer = {};
                bTab = this.tabsList.filter(tb => {
                  if (tb.id == btn.button_id) {
                    return tb;
                  }
                });

                if (bTab && bTab[0]) {
                  this.appManagementService.deleteContainer(bTab[0]);
                }
              }
            });
          }
        });
      }
    });
    const menuAppClass: LocalAppClass = {};
    menuAppClass.menus = menus;
    this.appManagementService._selectedMenu.next(menu);
    this.appManagementService.updateAppClass(
      this.menusAppClass.ref,
      menuAppClass
    );
  }

  deleteMenu(menu) {
    // else {
    //   this.deleteSubMenu(menu, this.selectedTab);
    // }
    this.form = this.clearFormGroup();
    if (
      this.selectedTab &&
      this.selectedTab.menu_id === menu.menu_id &&
      !this.selectedTab.sub_type
    ) {
      this.deleteMainMenu(this.selectedTab, this.form.value);
    } else if (this.menusAppClass && !this.selectedTab.sub_type) {
      this.deleteSubMenu(this.form.value);
      // const i = this.findMenuToBeUpdated(this.selectedTab.menu_id);

      // this.appManagementService._selectedMenu.next(this.menusAppClass.menus[i]);
    }
    this.appManagementService._selectedMenu.next(this.form.value);
    this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(men => {
          if (men.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(men);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.currency = store.currency;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.data = {};
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.currency = null;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);
    }
  }

  setMainMenu(state) {
    if (!state) {
      if (this.currentPage === this.appScreens.TABS) {
        if (this.currentMenu.menu_id === this.selectedTab.menu_id) {
          const subMenu = this.selectedTab.sub_menus;
          subMenu.push(this.currentMenu.menu_id);
          const tab: LocalContainer = {};
          tab.menu_id = '';
          tab.sub_menus = subMenu;
          this.appManagementService.updateContainer(this.selectedTab.ref, tab);
        } else if (
          this.selectedTab.sub_menus &&
          this.selectedTab.sub_menus.length > 0
        ) {
          const subMenu = [];
          this.selectedTab.sub_menus.forEach(res => {
            if (this.currentMenu.menu_id !== res) {
              subMenu.push(res);
            }
          });

          subMenu.push(this.selectedTab.menu_id);
          const tab: LocalContainer = {};
          tab.menu_id = this.currentMenu.menu_id;
          tab.sub_menus = subMenu;
          this.appManagementService.updateContainer(this.selectedTab.ref, tab);
        }
      } else if (this.currentPage === this.appScreens.NAV_MENU) {
        if (this.currentMenu.menu_id === this.selectedTab.menu_id) {
          const subMenu = this.selectedTab.sub_menus;
          subMenu.push(this.currentMenu.menu_id);
          const item: LocalItem = {};
          item.menu_id = '';
          item.sub_menus = subMenu;
          this.appManagementService.updateItem(this.selectedTab.ref, item);
        } else if (
          this.selectedTab.sub_menus &&
          this.selectedTab.sub_menus.length > 0
        ) {
          const subMenu = [];
          this.selectedTab.sub_menus.forEach(res => {
            if (this.currentMenu.menu_id !== res) {
              subMenu.push(res);
            }
          });

          subMenu.push(this.selectedTab.menu_id);
          const item: LocalItem = {};
          item.menu_id = this.currentMenu.menu_id;
          item.sub_menus = subMenu;
          this.appManagementService.updateItem(this.selectedTab.ref, item);
        }
      }
    }
  }

  dropButton(event) {
    const data = event;
    const feature = [];
    data.split(',').forEach(feat => {
      if (feat === 'null' || feat === 'undefined') {
        feature.push(null);
      } else {
        feature.push(feat);
      }
    });

    const rowIndex = Number(feature[feature.length - 3]);
    const colIndex = Number(feature[feature.length - 2]);
    const rowNumber = rowIndex;

    const rows = <FormArray>this.form.controls.rows;
    // const rowLength = rows.value[colIndex]['buttons'].length - 1;

    const control = rows['controls'][rowNumber];

    const buttons = <FormArray>control['controls'].buttons;
    const currenBtns = buttons.length - 1;

    const cellWeight = this.rowStyles.MENU_ROW_MAX_LENGTH - currenBtns;
    if (cellWeight >= feature[feature.length - 5]) {
      let title = '';
      if (feature[11] === this.menuCat.SUBMIT) {
        title = 'Submit';
      } else if (
        feature[11] === this.menuCat.SEPARATOR &&
        feature[0] === this.rowStyles.EMPTY
      ) {
        title = null;
      } else {
        title = 'Title';
      }
      for (let i = 0; i < this.currentTabs.length; i++) {
        if (this.currentTabs[i].key === buttons.value[colIndex].button_id) {
          const newContainer: LocalContainer = {};

          newContainer.title = title;
          newContainer.title_ios = title;

          this.appManagementService.updateContainer(
            this.currentTabs[i].value,
            newContainer
          );
        } else {
          const newContainer: LocalContainer = {};

          newContainer.title = title;
          newContainer.title_ios = title;

          newContainer.layout = 'button';
          newContainer.id = feature[feature.length - 1];
          this.appManagementService.updateContainer(
            this.currentTabs[i].value,
            newContainer
          );
        }
      }
      for (let i = 0; i < buttons.value.length; i++) {
        if (buttons.value[i].button_id === buttons.value[colIndex].button_id) {
          if (feature[11] === this.menuCat.SUBMIT) {
            buttons.value[i].button_label = 'Submit';
          } else if (
            feature[11] === this.menuCat.SEPARATOR &&
            feature[0] === this.rowStyles.EMPTY
          ) {
            title = null;
          } else {
            buttons.value[i].button_label = 'Title';
          }

          buttons.value[i].button_id = feature[feature.length - 1];
          buttons.value[i].button_textcolor = feature[7];
          buttons.value[i].button_label_color = feature[7];
          buttons.value[i].button_sublabel_color = feature[7];
          buttons.value[i].button_icon = null;
          buttons.value[i].button_icon_bgcolor = '#FFFFFF';
          buttons.value[i].button_bgcolor = feature[5];
          buttons.value[i].button_description = 'Your description.';
          buttons.value[i].button_style = feature[0];
          buttons.value[i].button_form = feature[11];
          buttons.value[i].button_type = feature[12];
          buttons.value[i].button_sublabel = 'Your description.';
          buttons.value[i].button_img_url = feature[3];
          buttons.value[i].url_2 = feature[9];
          buttons.value[i].url_3 = feature[10];
          buttons.value[i].url = feature[3];
          buttons.value[i].bg_color_2 = feature[6];
          buttons.value[i].bg_color_3 = feature[4];
          buttons.value[i].button_icon = feature[14];
          buttons.value[i].button_icon_bgcolor = feature[15];
          buttons.value[i].button_border_color = feature[16];
          const newObj: any = {};
          newObj.id = null;
          newObj.value = '20$';
          const valueList = [];
          valueList.push(newObj);
          buttons.value[i].button_value = [];
          buttons.value[i].button_keyboard = 'text';
          buttons.value[i].length = feature[8];
          if (feature[18] && feature[18] !== null) {
            buttons.value[i].button_asbect_ratio = feature[18].split('-');
          }
        }
      }

      this.appManagementService._selectedMenu.next(this.form.value);

      if (
        this.section === this.uiSection.MOBILESTORE ||
        ((this.selectedTab.type === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedTab.link === this.tabTypes.MENUS &&
            this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
          (this.selectedButtonTab &&
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
      ) {
        const newMenuList = [];
        if (this.storeMenuList && this.storeMenuList.length > 0) {
          this.storeMenuList.forEach(menu => {
            if (menu.menu_id === this.form.value.menu_id) {
              newMenuList.push(this.form.value);
            } else {
              newMenuList.push(menu);
            }
          });
        } else {
          newMenuList.push(this.form.value);
        }
        const newMobileAppList: any[] = [];
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (store.id !== this.selectedStoreId) {
              newMobileAppList.push(store);
            } else {
              const updatedStore: any = {};
              updatedStore.id = store.id;
              updatedStore.is_default = store.is_default;
              updatedStore.data = store.data;
              updatedStore.isUpdated = 1;
              updatedStore.currency = store.currency;
              if (store.image) {
                const defaultImage: CollectionUIImage = {};
                defaultImage.url =
                  'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
                updatedStore.image = defaultImage;
                updatedStore.name = 'Default Store';
                updatedStore.desc = 'Default Store';
              }
              updatedStore.data.shop = newMenuList;
              newMobileAppList.push(updatedStore);
            }
          });
        } else {
          const updatedStore: any = {};
          updatedStore.id = undefined;
          updatedStore.is_default = 1;

          const defaultImage: CollectionUIImage = {};
          defaultImage.url =
            'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
          updatedStore.image = defaultImage;
          updatedStore.name = 'Default Store';
          updatedStore.desc = 'Default Store';

          updatedStore.data = {};
          updatedStore.currency = null;
          updatedStore.isUpdated = 1;
          updatedStore.data.menu_id = this.form.value.menu_id;
          updatedStore.data.shop = newMenuList;
          newMobileAppList.push(updatedStore);
        }

        this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);

        this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

        this.mobileWorkFlowService._selectedMenu.next(this.form.value);
      }
      this.newformEmitter.emit(this.form);
    } else {
      this.showValidation = true;
      this.appManagementService._showMenuButtonValidation.next(true);
    }
    this._ref.detectChanges();
  }

  addButtonOnClick(
    data,
    selectedTab,
    form,
    menuCat,
    rowStyles,
    currentTabs,
    menusAppClass,
    appManagementService,
    mainMenu,
    mobileWorkflowStoreService?,
    storeList?,
    mobileStoreAppList?,
    selectedStoreId?,
    bundle?
  ) {
    this.selectedTab = selectedTab;
    this.form = form;
    this.storeMenuList = storeList;
    this.menusAppClass = menusAppClass;
    this.mainMenu = mainMenu;
    this.mobileStoreAppList = mobileStoreAppList;
    this.appManagementService = appManagementService;
    this.mobileStoreAppList = mobileStoreAppList;
    this.selectedStoreId = selectedStoreId;
    const feature = [];
    data.split(',').forEach(feat => {
      if (feat === 'null' || feat === 'undefined') {
        feature.push(null);
      } else {
        feature.push(feat);
      }
    });

    const rowIndex = parseInt(feature[feature.length - 3]);
    const colIndex = parseInt(feature[feature.length - 2]);
    const rowNumber = rowIndex;

    const rows = <FormArray>form.controls.rows;
    // const rowLength = rows.value[colIndex]['buttons'].length - 1;

    const control = rows['controls'][rowNumber];

    const buttons = <FormArray>control['controls'].buttons;
    const currenBtns = buttons.length - 1;

    const cellWeight = rowStyles.MENU_ROW_MAX_LENGTH - currenBtns;
    if (cellWeight >= feature[feature.length - 5]) {
      let title = '';
      if (feature[11] === menuCat.SUBMIT) {
        title = 'Submit';
      } else if (
        feature[11] === menuCat.SEPARATOR &&
        feature[0] === rowStyles.EMPTY
      ) {
        title = null;
      } else {
        title = 'Title';
      }
      for (let i = 0; i < currentTabs.length; i++) {
        if (this.currentTabs[i].key === buttons.value[colIndex].button_id) {
          const newContainer: LocalContainer = {};

          newContainer.title = title;
          newContainer.title_ios = title;

          this.appManagementService.updateContainer(
            currentTabs[i].value,
            newContainer
          );
        } else {
          const newContainer: LocalContainer = {};

          newContainer.title = title;
          newContainer.title_ios = title;

          newContainer.layout = 'button';
          newContainer.id = feature[feature.length - 1];
          this.appManagementService.updateContainer(
            this.currentTabs[i].value,
            newContainer
          );
        }
      }
      for (let i = 0; i < buttons.value.length; i++) {
        if (buttons.value[i].button_id === buttons.value[colIndex].button_id) {
          if (feature[11] === menuCat.SUBMIT) {
            buttons.value[i].button_label = 'Submit';
          } else if (
            feature[11] === menuCat.SEPARATOR &&
            feature[0] === rowStyles.EMPTY
          ) {
            title = null;
          } else {
            buttons.value[i].button_label = 'Title';
          }

          buttons.value[i].button_id = feature[feature.length - 1];
          buttons.value[i].button_textcolor = feature[7];
          buttons.value[i].button_label_color = feature[7];
          buttons.value[i].button_sublabel_color = feature[7];
          buttons.value[i].button_icon = null;
          buttons.value[i].button_icon_bgcolor = '#FFFFFF';
          buttons.value[i].button_bgcolor = feature[5];
          buttons.value[i].button_description = 'Your description.';
          buttons.value[i].button_style = feature[0];
          buttons.value[i].button_form = feature[11];
          buttons.value[i].button_type = feature[12];
          buttons.value[i].button_sublabel = 'Your description.';
          buttons.value[i].button_img_url = feature[3];
          buttons.value[i].url_2 = feature[9];
          buttons.value[i].url_3 = feature[10];
          buttons.value[i].url = feature[3];
          buttons.value[i].bg_color_2 = feature[6];
          buttons.value[i].bg_color_3 = feature[4];
          buttons.value[i].button_icon = feature[14];
          buttons.value[i].button_icon_bgcolor = feature[15];
          buttons.value[i].button_border_color = feature[16];
          const newObj: any = {};
          newObj.id = null;
          newObj.value = '20$';
          const valueList = [];
          valueList.push(newObj);
          buttons.value[i].button_value = [];
          buttons.value[i].button_keyboard = 'text';
          buttons.value[i].length = feature[8];
          if (feature[18] && feature[18] !== null) {
            buttons.value[i].button_asbect_ratio = feature[18].split('-');
          }
        }
      }
      if (this.mainMenu) {
        this.onSubmit();
      }
      this.appManagementService._selectedMenu.next(form.value);
      if (bundle) {
        if (mobileWorkflowStoreService) {
          const newMenuList = [];

          if (this.storeMenuList && this.storeMenuList.length > 0) {
            this.storeMenuList.forEach(menu => {
              if (menu.menu_id === form.value.menu_id) {
                newMenuList.push(form.value);
              } else {
                newMenuList.push(menu);
              }
            });
          } else {
            newMenuList.push(form.value);
          }
          const newMobileAppList: any[] = [];
          if (mobileStoreAppList && mobileStoreAppList.length >= 1) {
            mobileStoreAppList.forEach(store => {
              if (store.id !== this.selectedStoreId) {
                newMobileAppList.push(store);
              } else {
                const updatedStore: any = {};
                updatedStore.id = store.id;
                updatedStore.is_default = store.is_default;
                updatedStore.data = store.data;
                updatedStore.currency = store.currency;
                if (store.image) {
                  const defaultImage: CollectionUIImage = {};
                  defaultImage.url =
                    'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
                  updatedStore.image = defaultImage;
                  updatedStore.name = 'Default Store';
                  updatedStore.desc = 'Default Store';
                }
                updatedStore.data.shop = newMenuList;
                newMobileAppList.push(updatedStore);
              }
            });
          } else {
            const updatedStore: any = {};
            updatedStore.id = undefined;
            updatedStore.is_default = 1;
            updatedStore.data = {};
            updatedStore.currency = null;

            const defaultImage: CollectionUIImage = {};
            defaultImage.url =
              'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
            updatedStore.image = defaultImage;
            updatedStore.name = 'Default Store';
            updatedStore.desc = 'Default Store';

            updatedStore.isUpdated = 1;
            updatedStore.data.menu_id = form.value.menu_id;
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
          mobileWorkflowStoreService._mobileStoreAppList.next(newMobileAppList);

          mobileWorkflowStoreService._selectedMenuList.next(newMenuList);
        }
      }
      mobileWorkflowStoreService._selectedMenu.next(form.value);

      // this.newformEmitter.emit(form);
    } else {
      this.showValidation = true;
      this.appManagementService._showMenuButtonValidation.next(true);
    }
    this._ref.detectChanges();
  }

  closeAddScreen() {
    // this.startOverFirstValidation = false;
    this.closeMenuConfNotify.emit();
  }

  hideValidation() {
    this.showValidation = false;
    this.appManagementService._showMenuButtonValidation.next(false);
  }

  updateItemPageTitleEvent(value: string, container?: any) {
    const newMypage: MyPage = {};
    newMypage.name = value;

    this.appManagementService.updateMyPage(this.myPage.id, newMypage);
  }
  updateItemPageDescEvent(value: string, container?: any) {
    const newMypage: MyPage = {};
    newMypage.desc = value;

    this.appManagementService.updateMyPage(this.myPage.id, newMypage);
  }
  updateImage(value: IMediaCenter) {
    this.hideAdd();
    const newMypage: MyPage = {};
    newMypage.image = value.url;
    this.appManagementService.updateMyPage(this.myPage.id, newMypage);
  }

  updateImageToButton(value: IMediaCenter) {
    this.hideMedia();
    this.reciveImageUrl(value.url, this.selectedPos);
  }

  updateBackground(value: IMediaCenter) {
    this.appManagementService.updateMyPageForm(
      this.myPage.id,
      null,
      null,
      null,
      value.url
    );
    // this.hideAddBackgroundImage();
  }

  showAdd() {
    this.showAddImage = true;
  }
  hideAdd() {
    this.showAddImage = false;
  }
  showMedia(pos: { row: number; col: number }) {
    this.selectedAspectRatio = this.getButtonAsbectratio(pos);
    this.selectedPos = pos;
    this.showAddMedia = true;
    this._ref.detectChanges();
  }
  hideMedia() {
    this.showAddMedia = false;
    this.selectedAspectRatio = null;
  }
  showAddBackgroundImage() {
    this.showAddBackground = true;
  }
  hideAddBackgroundImage() {
    this.showAddBackground = false;
  }
  get uploadedImage() {
    if (this.myPage && this.myPage.image) {
      return {
        'background-image': 'url(' + this.myPage.image + ')'
      };
    }
  }

  get uploadedImageButton() {
    if (this.selectedButton) {
      return {
        'background-image':
          'url(' + this.getImgUrl(this.selectedButton).value + ')'
      };
    }
  }

  get uploadedBackground() {
    if (this.myPage && this.myPage.content && this.myPage.content.background) {
      return {
        'background-image': 'url(' + this.myPage.content.background + ')'
      };
    }
    return '';
  }

  addParentId(values) {
    this.addParentIdNotify.emit(values);
  }

  goToPage(pageId) {
    this.pageIdNotify.emit(pageId);
  }
  youTube(event) {
    this.youTubeNotify.emit(event);
  }
  pageVideo(event) {
    this.appManagementService.updateMyPageContainer(
      event.formId,
      event.containerId,
      event.newContainer
    );
    this._ref.detectChanges();
  }

  selectAction(action) {
    this.appManagementService._menuOnclickAction.next(action);
  }

  showMenuScreens(value, pos?, col?) {
    this.nextMenuSelected = false;
    this.showScreens = value;
    this.appManagementService._selectedNextMenu.next(false);
    if (pos) {
      this.appManagementService._selectedNextMenuPos.next(pos);
    }
    if (col) {
      this.appManagementService._selectedNextMenuCol.next(col);
    }
    this._ref.detectChanges();
  }

  showMenuCollections(value, pos?, col?) {
    this.showCollections = value;
    if (pos) {
      this.appManagementService._selectedNextMenuPos.next(pos);
    }
    if (col) {
      this.appManagementService._selectedNextMenuCol.next(col);
    }
    this._ref.detectChanges();
  }
  getMainMenu(tab) {
    if (this.menusAppClass.menus && tab) {
      const menu = this.menusAppClass.menus.filter(
        res => res.menu_id === tab.menu_id
      );

      return menu;
    }
    return [{}];
  }

  getMenu(menuId) {
    if (this.menusAppClass && this.menusAppClass.menus && this.startOver) {
      const menu = this.menusAppClass.menus.filter(
        res => res.menu_id === menuId
      );
      return menu;
    }
    return [{}];
  }
  getStoreMenu(menuId) {
    if (this.storeMenuList) {
      const menu = this.storeMenuList.filter(res => res.menu_id === menuId);
      return menu;
    }
    return [{}];
  }

  selectNextMenu(value) {
    this.nextMenuSelected = true;
    this.appManagementService._selectedNextMenu.next(value);
  }
  addRemoveCardStyle(event, pos) {
    const currentStyle = this.getButtonStyles(pos).value;
    let newStyle;
    if (event.target.checked) {
      newStyle = Number(currentStyle) + 1;
    } else {
      newStyle = Number(currentStyle) - 1;
    }
    this.getButtonStyles(pos).setValue(newStyle + '');
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.isUpdated = 1;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  addRemoveDividerStyle(event, pos) {
    const currentStyle = this.getButtonStyles(pos).value;
    let newStyle;
    if (event.target.checked) {
      newStyle = Number(currentStyle) + 10;
    } else {
      newStyle = Number(currentStyle) - 10;
    }
    this.getButtonStyles(pos).setValue(newStyle + '');
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.data = {};
        updatedStore.currency = null;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.isUpdated = 1;
        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  addRemoveMirroStyle(event, pos) {
    const currentStyle = this.getButtonStyles(pos).value;

    let newStyle;
    if (event.target.checked) {
      newStyle = Number(currentStyle) + 100;
    } else {
      newStyle = Number(currentStyle) - 100;
    }
    this.getButtonStyles(pos).setValue(newStyle + '');
    this.appManagementService._selectedMenu.next(this.form.value);
    if (
      this.section === this.uiSection.MOBILESTORE ||
      ((this.selectedTab.type === this.tabTypes.MENUS &&
        this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedTab.link === this.tabTypes.MENUS &&
          this.selectedTab.sub_type === this.tabTypes.BUNDLE) ||
        (this.selectedButtonTab &&
          this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type === this.tabTypes.BUNDLE))
    ) {
      const newMenuList = [];
      if (this.storeMenuList && this.storeMenuList.length > 0) {
        this.storeMenuList.forEach(menu => {
          if (menu.menu_id === this.form.value.menu_id) {
            newMenuList.push(this.form.value);
          } else {
            newMenuList.push(menu);
          }
        });
      } else {
        newMenuList.push(this.form.value);
      }
      const newMobileAppList: any[] = [];
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.id !== this.selectedStoreId) {
            newMobileAppList.push(store);
          } else {
            const updatedStore: any = {};
            updatedStore.id = store.id;
            updatedStore.is_default = store.is_default;
            updatedStore.data = store.data;
            updatedStore.isUpdated = 1;
            updatedStore.currency = store.currency;
            if (store.image) {
              const defaultImage: CollectionUIImage = {};
              defaultImage.url =
                'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
              updatedStore.image = defaultImage;
              updatedStore.name = 'Default Store';
              updatedStore.desc = 'Default Store';
            }
            updatedStore.data.shop = newMenuList;
            newMobileAppList.push(updatedStore);
          }
        });
      } else {
        const updatedStore: any = {};
        updatedStore.id = undefined;
        updatedStore.is_default = 1;
        updatedStore.data = {};
        updatedStore.currency = null;
        updatedStore.isUpdated = 1;

        const defaultImage: CollectionUIImage = {};
        defaultImage.url =
          'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
        updatedStore.image = defaultImage;
        updatedStore.name = 'Default Store';
        updatedStore.desc = 'Default Store';

        updatedStore.data.menu_id = this.form.value.menu_id;
        updatedStore.data.shop = newMenuList;
        newMobileAppList.push(updatedStore);
      }

      this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
      this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

      this.mobileWorkFlowService._selectedMenu.next(this.form.value);
    }
    this.newformEmitter.emit(this.form);
    if (this.tabsList || this.itemsList) {
      this.onSubmit();
    }
  }

  checkCardStyle(pos) {
    let check = false;
    const currentStyle = this.getButtonStyles(pos).value.split('');

    if (currentStyle[currentStyle.length - 1] === '1') {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  checkDividerStyle(pos) {
    let check = false;
    const currentStyle = this.getButtonStyles(pos).value.split('');
    if (currentStyle[currentStyle.length - 2] === '1') {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  checkMirrorStyle(pos) {
    let check = false;
    const currentStyle = this.getButtonStyles(pos).value.split('');
    if (currentStyle[currentStyle.length - 3] === '1') {
      check = true;
    } else {
      check = false;
    }

    return check;
  }

  hideOptionImg() {
    this.showOptionImage = false;
    this._ref.detectChanges();
  }

  showOptionImg(pos, optId) {
    this.showOptionImage = true;
    this.optionBtn = pos;
    this.optionBtnOptId = optId;
    this._ref.detectChanges();
  }

  imgCheck(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.SINGLECHOICEIMG);
    const icon = Number(this.rowStyles.SINGLECHOICEICON);
    const checkImg = img <= btnStyle;
    const checkImg2 = btnStyle < icon;
    let check = false;
    if (checkImg && checkImg2) {
      check = true;
    }
    return check;
  }

  iconCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.SINGLECHOICE);
    const icon = Number(this.rowStyles.SINGLECHOICEICON);
    const checkIcon = icon <= btnStyle;
    const checkIcon2 = btnStyle < single;

    let check = false;
    if (checkIcon && checkIcon2) {
      check = true;
    }
    return check;
  }

  singleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.SINGLECHOICE);
    const toggle = Number(this.rowStyles.TOGGLECHOICEIMG);
    const checkSingle = single <= btnStyle;
    const checkSingle2 = btnStyle < toggle;

    let check = false;
    if (checkSingle && checkSingle2) {
      check = true;
    }
    return check;
  }

  imgToggleCheck(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.TOGGLECHOICEIMG);
    const icon = Number(this.rowStyles.TOGGLECHOICEICON);
    const checkImg = img <= btnStyle;
    const checkImg2 = btnStyle < icon;
    let check = false;
    if (checkImg && checkImg2) {
      check = true;
    }
    return check;
  }

  iconToggleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.TOGGLECHOICE);
    const icon = Number(this.rowStyles.TOGGLECHOICEICON);
    const checkIcon = icon <= btnStyle;
    const checkIcon2 = btnStyle < single;

    let check = false;
    if (checkIcon && checkIcon2) {
      check = true;
    }
    return check;
  }

  singleToggleCheck(style) {
    const btnStyle = Number(style);
    const single = Number(this.rowStyles.TOGGLECHOICE);
    const checkSingle = single <= btnStyle;
    let check = false;
    if (checkSingle) {
      check = true;
    }
    return check;
  }

  checkForToggle(style) {
    const btnStyle = Number(style);
    const img = Number(this.rowStyles.TOGGLECHOICEIMG);
    const checkImg = img <= btnStyle;
    let check = false;
    if (checkImg) {
      check = true;
    }
    return check;
  }

  checkValidation(val) {
    let check = false;

    if (val.trim().length <= 0) {
      check = true;
    }
    return check;
  }

  toggleActionColor(color, value, id) {
    let actionColor = '#24aef5';
    let asignedColor = '#abb6d0';
    id = id.replace(/ /g, '');
    const styleEl = document.createElement('style');
    let styleSheet;

    // Append style element to head
    document.head.appendChild(styleEl);

    // Grab style sheet
    styleSheet = styleEl.sheet;
    if (color && color !== null && color !== '' && value === 'true') {
      asignedColor = color;
      actionColor = color;
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          actionColor +
          '!important;}'
      );
    } else if (value === 'true') {
      asignedColor = this.systemConfg.color.primary;
      actionColor = this.systemConfg.color.primary;
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          this.systemConfg.color.primary +
          '!important;}'
      );
    } else if (value === 'false') {
      actionColor = '#24aef5';
      asignedColor = '#abb6d0';
      styleSheet.insertRule(
        '#slider' +
          id +
          ':before{ background-color:' +
          asignedColor +
          '!important;}'
      );
    }
    return asignedColor;
  }

  actionColor(color) {
    if (color && color !== null && color !== '') {
      return {
        'accent-color': color
      };
    } else {
      return {
        'accent-color': this.systemConfg.color.primary
      };
    }
  }

  actionColorPicker(color) {
    if (color && color !== null && color !== '') {
      return color;
    } else {
      return this.systemConfg.color.primary;
    }
  }

  generatToggalId(id) {
    id = id.replace(/ /g, '');
    const newId = 'slider' + id;
    return newId;
  }
  setMainMenuStore(id) {
    // if (this.selectedButtonTab) {
    //   const tab: LocalContainer = {};
    //   tab.menu_id = id;

    //   this.appManagementService.updateContainer(
    //     this.selectedButtonTab.ref,
    //     tab
    //   );
    // } else if (this.currentPage === this.appScreens.TABS) {
    //   const tab: LocalContainer = {};
    //   tab.menu_id = id;

    //   this.appManagementService.updateContainer(this.selectedTab.ref, tab);
    // } else if (this.currentPage === this.appScreens.NAV_MENU) {
    //   const tab: any = {};
    //   tab.menu_id = id;

    //   this.appManagementService.updateItem(this.selectedTab.ref, tab);
    // }

    this.mobileWorkFlowService._mainMenuId.next(id);
    const newMenuList = [];
    this.storeMenuList.forEach(menu => {
      if (menu.menu_id === id) {
        newMenuList.push(menu);
      }
    });
    this.storeMenuList.forEach(menu => {
      if (menu.menu_id !== id) {
        newMenuList.push(menu);
      }
    });

    const newMobileAppList: any[] = [];
    if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
      this.mobileStoreAppList.forEach(store => {
        if (store.id !== this.selectedStoreId) {
          newMobileAppList.push(store);
        } else {
          const updatedStore: any = {};
          updatedStore.id = store.id;
          updatedStore.is_default = store.is_default;
          updatedStore.data = store.data;
          updatedStore.data.menu_id = id;
          updatedStore.isUpdated = 1;
          updatedStore.currency = store.currency;
          if (store.image) {
            const defaultImage: CollectionUIImage = {};
            defaultImage.url =
              'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
            updatedStore.image = defaultImage;
            updatedStore.name = 'Default Store';
            updatedStore.desc = 'Default Store';
          }
          updatedStore.data.shop = newMenuList;
          newMobileAppList.push(updatedStore);
        }
      });
    } else {
      const updatedStore: any = {};
      updatedStore.id = undefined;
      updatedStore.is_default = 1;
      updatedStore.data = {};
      updatedStore.currency = null;

      const defaultImage: CollectionUIImage = {};
      defaultImage.url =
        'https://nandbox.com/wp-content/uploads/2023/10/store-2-cl.webp';
      updatedStore.image = defaultImage;
      updatedStore.name = 'Default Store';
      updatedStore.desc = 'Default Store';

      updatedStore.isUpdated = 1;
      updatedStore.data.menu_id = id;
      updatedStore.data.shop = newMenuList;
      newMobileAppList.push(updatedStore);
    }

    this.mobileWorkFlowService._mobileStoreAppList.next(newMobileAppList);
    this.mobileWorkFlowService._selectedMenuList.next(newMenuList);

    this.mobileWorkFlowService._selectedMenu.next(this.form.value);
  }

  getCollection(id) {
    if (id && !this.getCollectionRun) {
      const sub = this.collectionService
        .getSelectedCollectionByID$(id)
        .subscribe(col => {
          if (!col) {
            this.collectionService.getCollectionItem(id);
          }
        });
      this.getCollectionRun = true;
      sub.unsubscribe();
    }
  }
}
