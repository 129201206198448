/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-files.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/dropdown";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "./upload-files.component";
import * as i5 from "../../core/file.service";
var styles_UploadFilesComponent = [i0.styles];
var RenderType_UploadFilesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadFilesComponent, data: {} });
export { RenderType_UploadFilesComponent as RenderType_UploadFilesComponent };
function View_UploadFilesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 45, "ul", [["class", "dropdown-menu dropdown"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "li", [["class", "menuitem"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "label", [["class", "icon"], ["for", "image-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-photo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "tooltiptext"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Image "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-photo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["imageInputFile", 1]], null, 0, "input", [["accept", "image/*"], ["id", "image-upload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesSelectedEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "li", [["class", "menuitem"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "label", [["class", "icon"], ["for", "gif-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-gif"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "span", [["class", "tooltiptext"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GIF "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-gif"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, [[3, 0], ["gifInputFile", 1]], null, 0, "input", [["accept", ".gif"], ["id", "gif-upload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesSelectedEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 8, "li", [["class", "menuitem"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "label", [["class", "icon"], ["for", "video-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 3, "span", [["class", "tooltiptext"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Video "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, [[2, 0], ["videoInputFile", 1]], null, 0, "input", [["accept", "video/mp4,video/x-m4v,video/*"], ["id", "video-upload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesSelectedEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 8, "li", [["class", "menuitem"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 6, "label", [["class", "icon"], ["for", "audio-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-audio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 3, "span", [["class", "tooltiptext"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Audio "])), (_l()(), i1.ɵeld(34, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-audio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, [[4, 0], ["audioInputFile", 1]], null, 0, "input", [["accept", "audio/*"], ["id", "audio-upload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesSelectedEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 8, "li", [["class", "menuitem"], ["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 6, "label", [["class", "icon"], ["for", "document-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 3, "span", [["class", "tooltiptext icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Document "])), (_l()(), i1.ɵeld(43, 0, null, null, 1, ":svg:svg", [["class", "svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, [[5, 0], ["documentInputFile", 1]], null, 0, "input", [["id", "document-upload"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.filesSelectedEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_UploadFilesComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { imageInputFile: 0 }), i1.ɵqud(671088640, 2, { videoInputFile: 0 }), i1.ɵqud(671088640, 3, { gifInputFile: 0 }), i1.ɵqud(671088640, 4, { audioInputFile: 0 }), i1.ɵqud(671088640, 5, { documentInputFile: 0 }), (_l()(), i1.ɵeld(5, 16777216, null, null, 7, "div", [["class", "btn-group"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleMenuEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.BsDropdownState, i2.BsDropdownState, []), i1.ɵdid(7, 212992, null, 0, i2.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.BsDropdownConfig, i2.BsDropdownState], { dropup: [0, "dropup"], isOpen: [1, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.resetEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 147456, null, 0, i2.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i2.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i2.BsDropdownState], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0], [2, "open", null], [2, "fa", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFilesComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.BsDropdownMenuDirective, [i2.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = _co.isMenuOpen; _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).dropup; var currVal_1 = i1.ɵnov(_v, 7).isOpen; var currVal_2 = (i1.ɵnov(_v, 7).isOpen && i1.ɵnov(_v, 7).isBs4); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 9).isDisabled; var currVal_7 = i1.ɵnov(_v, 9).isOpen; _ck(_v, 8, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.className; var currVal_9 = _co.isMenuOpen; var currVal_10 = true; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_UploadFilesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-files", [], null, null, null, View_UploadFilesComponent_0, RenderType_UploadFilesComponent)), i1.ɵdid(1, 114688, null, 0, i4.UploadFilesComponent, [i5.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadFilesComponentNgFactory = i1.ɵccf("app-upload-files", i4.UploadFilesComponent, View_UploadFilesComponent_Host_0, { isBotScreen: "isBotScreen" }, { filesSelectedNotify: "filesSelectedNotify" }, []);
export { UploadFilesComponentNgFactory as UploadFilesComponentNgFactory };
