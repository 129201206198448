import { MapMarker } from 'src/models/MapMarker';
import { Actions } from './mapMarker.actions';

import * as actions from './mapMarker.actions';
import { MapMarkerUI } from 'src/models/MapMarkerUI';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';
import { MapMarkerMiddleware } from './mapMarker.middleware';

const INITIAL_STATE: MapMarkerUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  selected_chat: null,
  type: null
};

export function mapMarkerReducer(
  state = INITIAL_STATE,
  action: Actions
): MapMarkerUI {
  switch (action.type) {
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_DETAILS_RECEIVED: {
      if (
        !MapMarkerMiddleware.ismapMarkerExist(state.currentPage, action.payload)
      ) {
        return {
          ...state,
          currentPage: [action.payload, ...state.currentPage]
        };
      }
      return {
        ...state,
        currentPage: state.currentPage.map(mapMarker => {
          if (mapMarker.id === action.payload.id) {
            let updatedMarker: MapMarker = {};
            updatedMarker = { ...mapMarker, ...action.payload };
            return updatedMarker;
          }
          return mapMarker;
        })
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_CREATED: {
      let markers = state.currentPage;
      if (
        !state.currentPage.find(
          mapSetting => mapSetting.id === action.payload.id
        )
      ) {
        markers =
          state.currentPage.length > 0
            ? [action.payload, ...state.currentPage]
            : [action.payload];
      }
      return {
        ...state,
        currentPage: markers
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          mapMarker => mapMarker.id !== action.id
        )
      };
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        currentPage:
          action.payload &&
          action.payload.markers &&
          action.payload.markers.length > 0
            ? action.payload.markers
            : state.currentPage,
        eop: action.payload.eop ? action.payload.eop : state.eop,
        sop: action.payload.sop ? action.payload.sop : state.sop,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_SET_SELECTED_CHAT:
      return {
        ...state,
        selected_chat: action.selected_chat,
        type: action.typeLocation ? action.typeLocation : null
      };
    case actions.MapMarkerActionTypes.UI_MAP_MARKER_RESET_PAGING:
      return {
        ...INITIAL_STATE,
        selected_chat: state.selected_chat,
        type: state.type
      };
    default: {
      return state;
    }
  }
}
