import { Store } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import { ProfileMiddleware } from './profile.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ProfileDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    contactsReceived(payload, myBots, templateBots) {
        const profiles = ProfileMiddleware.initProfileList(payload, myBots, templateBots);
        this._store.dispatch(new ProfileActions.ContactsReceived(profiles));
    }
    contactDetailsReceived(payload) {
        const profile = ProfileMiddleware.initProfile(payload);
        this._store.dispatch(new ProfileActions.ContactDetailsReceived(profile));
    }
    contactDetailsFromCache(payload) {
        this._store.dispatch(new ProfileActions.ContactDetailsFromCache(payload));
    }
    unknownProfile(userID, unknownContact) {
        this._store.dispatch(new ProfileActions.UnknownProfile(userID, unknownContact));
    }
    thumbnailDownloaded(payload) {
        this._store.dispatch(new ProfileActions.ThumbnailDownloaded(payload));
    }
    imageDownloaded(payload) {
        this._store.dispatch(new ProfileActions.ImageDownloaded(payload));
    }
    profileInvalidated(userID) {
        this._store.dispatch(new ProfileActions.ProfileInvalidated(userID));
    }
    deselectProfile(id, lastTypedMessage) {
        this._store.dispatch(new ProfileActions.DeselectProfile(id, lastTypedMessage));
    }
    createBot(name) {
        this._store.dispatch(new ProfileActions.CreateBot(name));
    }
    botCreated(userID, name) {
        this._store.dispatch(new ProfileActions.BotCreated(userID, name));
    }
    updateBot(botDetails) {
        this._store.dispatch(new ProfileActions.UpdateBot(botDetails));
    }
    uploadBotImage(botID, file) {
        this._store.dispatch(new ProfileActions.UploadBotImage(botID, file));
    }
    activateBot(botID) {
        this._store.dispatch(new ProfileActions.ActivateBot(botID));
    }
    requestBotToken(botID) {
        this._store.dispatch(new ProfileActions.RequestBotToken(botID));
    }
    updateStore(bot) {
        this._store.dispatch(new ProfileActions.UpdateStore(bot));
    }
    deleteBot(botID) {
        this._store.dispatch(new ProfileActions.DeleteBot(botID));
    }
    publishBot(botID, username) {
        this._store.dispatch(new ProfileActions.PublishBot(botID, username));
    }
    myBotDeleted(botID) {
        this._store.dispatch(new ProfileActions.MyBotDeleted(botID));
    }
    botAddedAsAdmin(userID) {
        this._store.dispatch(new ProfileActions.BotAddedAsAdmin(userID));
    }
    cloneBotFromTemplate(userID) {
        this._store.dispatch(new ProfileActions.CloneBotFromTemplate(userID));
    }
    updateAdminPrivileges(userID, privileges) {
        this._store.dispatch(new ProfileActions.UpdateAdminPrivileges(userID, privileges));
    }
    receivePublishBotError(errorNumber) {
        this._store.dispatch(new ProfileActions.ReceivePublishBotError(errorNumber));
    }
    requestUserBalance(userID) {
        this._store.dispatch(new ProfileActions.RequestUserBalance(userID));
    }
    requestUserProductBalance(userID) {
        this._store.dispatch(new ProfileActions.RequestUserProductBalance(userID));
    }
    userBalanceReceived(userID, userBalanceList) {
        this._store.dispatch(new ProfileActions.UserBalanceReceived(userID, userBalanceList));
    }
    userProductBalanceReceived(userID, userProductBalanceList) {
        this._store.dispatch(new ProfileActions.UserProductBalanceReceived(userID, userProductBalanceList));
    }
    getContactsNames() {
        this._store.dispatch(new ProfileActions.GetContactsNames());
    }
}
ProfileDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileDispatchers_Factory() { return new ProfileDispatchers(i0.ɵɵinject(i1.Store)); }, token: ProfileDispatchers, providedIn: "root" });
