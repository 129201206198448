import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartoverQuestionsComponent } from './startover/startover-questions/startover-questions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxEditorModule } from 'ngx-editor';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxModule } from '../ngx.module';
import { SharedModule } from '../shared/shared.module';
import { AccessorsModule } from '../accessors/accessors.module';
import { MediaCenterModule } from '../media-center/media-center.module';
import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractUploadModule } from '../abstract-upload/abstract-upload.module';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from '../abstract-controls/abstract-controls.module';
import { AppStatusModule } from '../+merchant/+dashboard/app-status/app-status.module';
import { InstantArticleModule } from '../+merchant/+dashboard/instant-article/instant-article.module';

import {
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import { ClickOutsideModule } from 'ng-click-outside';
import { AbstractTooltip } from '../abstract-tooltip/abstract-tooltip.module';
import { AbstractPoolsModule } from '../abstract-pools/abstract-pools.module';
import { AbstractNotifiersModule } from '../abstract-notifiers/abstract-notifiers.module';
import { StartoverSubmitBtnComponent } from './startover/startover-submit-btn/startover-submit-btn.component';
import { StartoverCntrBtnsComponent } from './startover/startover-cntr-btns/startover-cntr-btns.component';
import { StartoverDroplistComponent } from './startover/startover-droplist/startover-droplist.component';
import { StartoverMobViewComponent } from './startover/startover-mob-view/startover-mob-view.component';
import { StartoverTempsComponent } from './startover/startover-temps/startover-temps.component';
import { StartoverTempItemComponent } from './startover/startover-temps/startover-temp-item/startover-temp-item.component';
import { StartoverMoreModulesComponent } from './startover/startover-more-modules/startover-more-modules.component';
import { StartoverLogoComponent } from './startover/startover-logo/startover-logo.component';
import { StartoverGuideVideoComponent } from './startover/startover-guide-video/startover-guide-video.component';
import { StartoverAppThemeComponent } from './startover/startover-app-theme/startover-app-theme.component';
import { StartoverMenuThemeComponent } from './startover/startover-menu-theme/startover-menu-theme.component';
import { StartoverTempViewComponent } from './startover/startover-temp-view/startover-temp-view.component';
import { RouterModule } from '@angular/router';
import { MenuFormModule } from '../+merchant/+dashboard/menus/menu-form/menu.form.module';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ImageViewerModule } from 'ngx-image-viewer';
import { NgImageSliderModule } from 'ng-image-slider';
import { StartoverComponent } from './startover/startover.component';

@NgModule({
  declarations: [
    StartoverComponent,
    StartoverQuestionsComponent,
    StartoverSubmitBtnComponent,
    StartoverCntrBtnsComponent,
    StartoverDroplistComponent,
    StartoverMobViewComponent,
    StartoverTempsComponent,
    StartoverTempItemComponent,
    StartoverMoreModulesComponent,
    StartoverLogoComponent,
    StartoverGuideVideoComponent,
    StartoverAppThemeComponent,
    StartoverMenuThemeComponent,
    StartoverTempViewComponent
  ],
  imports: [
    RouterModule.forChild([{ path: '', component: StartoverComponent }]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxDnDModule.forRoot(),
    NgxEditorModule,
    ColorPickerModule,
    ImageCropperModule,
    AngularSvgIconModule,
    NgxModule,
    SharedModule,
    AccessorsModule,
    MediaCenterModule,
    AbstractIconsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractUploadModule,
    AbstractControlsModule,
    AppStatusModule,
    InstantArticleModule,
    ImageViewerModule.forRoot(),
    AbstractPoolsModule,
    AbstractNotifiersModule,
    MenuFormModule,
    AbstractTooltip,
    ClickOutsideModule,
    MatTooltipModule,
    NgImageSliderModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule
  ]
})
export class TemplatesModule { }
