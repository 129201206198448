import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { ThirdPartyInfoUI, VendorTest } from 'src/models/thirdPartyInfo';
import * as ThirdPartyInfo from './thirdPartyInfo.actions';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyInfoDispatchers {
  constructor(private _store: Store<AppState>) { }

  receiveThirdPartyInfo(vendors: ThirdPartyInfoUI[]) {
    this._store.dispatch(new ThirdPartyInfo.ReceiveThirdPartyInfo(vendors));
  }

  updateThirdPartyInfo(vendors: ThirdPartyInfoUI) {
    this._store.dispatch(new ThirdPartyInfo.UpdateThirdPartyInfo(vendors));
  }

  testThirdPartyInfo(vendor: VendorTest) {
    this._store.dispatch(new ThirdPartyInfo.TestThirdPartyInfo(vendor));
  }

  saveThirdPartyInfo() {
    this._store.dispatch(new ThirdPartyInfo.SaveThirdPartyInfo());
  }

  receiveTestThirdPartyInfo(accepted, message, tac) {
    this._store.dispatch(
      new ThirdPartyInfo.ReceiveTestThirdPartyInfo(accepted, message, tac)
    );
  }

  restErrorMassage() {
    this._store.dispatch(new ThirdPartyInfo.RestErrorMassage());
  }

  getMessagingVendorUI(name: string) {
    this._store.dispatch(new ThirdPartyInfo.GetMessagingVendorUI(name));
  }

  setVerificationMessageTemplate(value, type) {
    this._store.dispatch(new ThirdPartyInfo.SetVerificationMessage(value, type));
  }

  sendVerificationMessageTemplate() {
    this._store.dispatch(new ThirdPartyInfo.SendVerificationMessageTemplate());
  }

  getVerificationMessageTemplate() {
    this._store.dispatch(new ThirdPartyInfo.GetVerificationMessageTemplate())
  }

  receiveVerificationMessageTemplate(email, sms) {
    this._store.dispatch(new ThirdPartyInfo.ReceiveVerificationMessageTemplate(email, sms));
  }

  selectTypeOfMessage(type, loginType) {
    this._store.dispatch(new ThirdPartyInfo.SelectTypeOfMessage(type, loginType));
  }

  deleteVerificationMessageTemplate() {
    this._store.dispatch(new ThirdPartyInfo.DeleteVerificationMessageTemplate());
  }
}
