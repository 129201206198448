import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import {
  TabTypes,
  UserData,
  ThemeStatus,
  UISections
} from 'src/models/constants';
import { AppMenu } from 'src/models/AppMenu';
import { LocalContainer, LocalItem } from 'src/models/ChannelAppLocalConfig';
import { BotsService } from 'src/app/+merchant/+dashboard/bots/bots.service';
import { AppInfo, AppClass } from 'src/models/ChannelAppConfig';
import { BotMenu } from 'src/models/IBotMenu';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { FeaturesSublistComponent } from 'src/app/+merchant/+dashboard/app-mgmt/custom/dragable-features/features-sublist/features-sublist.component';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
// import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { UiService } from 'src/app/core/ui.service';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/+merchant/+registration/registration.service';
import { StorageService } from 'src/app/core/storage.service';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { MobileWorkFlowStoreService } from './../../../+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-startover-questions',
  templateUrl: './startover-questions.component.html',
  styleUrls: ['./startover-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverQuestionsComponent
  implements OnInit, OnDestroy, OnChanges {
  showList: boolean;
  menuItem: AppMenu;
  themeStatus = ThemeStatus;
  @Input() tabs: LocalContainer[];
  @Input() navItems: LocalItem[];
  @Input() appMenu: AppMenu[];
  @Input() component: any;
  @Input() page: any;
  @Input() mode: number;
  @Input() menuIdex: number;
  @Input() currentMenu: BotMenu;
  @Output() uploadNotify = new EventEmitter<boolean>();
  helpPage: string;
  category: any;
  tempType: any;
  bubbleTheme: string;
  @Input() moreTypes: any[] = [];
  tabsTypes = TabTypes;
  userData: UserData = {};
  tempId: any;
  appClass: AppClass;
  @Input() appInfo: AppInfo;
  @Input() toBeEdited: boolean;
  @Output() customizeNotify = new EventEmitter<boolean>();
  @Output() startOverCurrentPageNotify = new EventEmitter<number>();
  @Output() editNotify = new EventEmitter<boolean>();
  @Output() newMenuNotify = new EventEmitter<null>();
  @Input() startOverFirstValidation = false;
  @Input() startOverSecValidation = false;
  @Output() startAgainNotify = new EventEmitter<boolean>();
  @Input() payment: any;
  relatedTemp: any[];
  tempLoading = false;
  profileImageUploaded;
  menuSubscription = null;
  mobTheme = ThemeStatus;
  helpStatus: number;
  currentTemp: any;
  tempsList: any[];
  generalList: any[];
  getFeature = new FeaturesSublistComponent(
    this._ref,
    this.appManagementService,
    this.botsService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );

  constructor(
    public appManagementService: AppMgmtService,
    private _ref: ChangeDetectorRef,
    public authService: AuthService,
    public botsService: BotsService,
    public layoutService: LayoutService,
    public uiService: UiService,
    private _router: Router,
    public dashboard: DashboardService,
    public rgsService: RegistrationService,
    public localStorage: StorageService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {
    // _router.events.subscribe((event: NavigationStart) => {
    //   if (event.navigationTrigger === 'popstate') {
    //     // Perform actions
    //     this.back();
    //   }
    // });
  }

  ngOnChanges() {
    if (this.appInfo && this.appInfo.help_link) {
      this.helpStatus = this.appInfo.help_link;
    }
    if (this.appInfo && !this.appInfo.layout) {
      const appInfo: AppInfo = {};
      appInfo.layout = this.mobTheme.Top;
      appInfo.help_link = this.helpStatus
        ? this.helpStatus
        : this.appInfo.help_link;
      this.appManagementService.updateAppInfo(appInfo);
    }

    if (this.tempsList && this.tempsList.length > 0) {
      this.tempLoading = false;
      this._ref.detectChanges();
    }
  }
  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }
  ngOnInit() {
    this.appManagementService._appIcon.next({});
    this.tempLoading = true;
    this.authService.getTemplatesByCategory('A').subscribe(res => {
      this.generalList = res['templates'];

      this.category = 'A';
      this.tempLoading = false;

      this.tempsList = this.generalList;
      this._ref.detectChanges();
    });
  }

  show(vlu) {
    this.showList = vlu;
  }

  back() {
    this.page = 1;
    this._ref.detectChanges();
  }
  skip() {
    this.page = this.page + 1;
  }
  continue() {
    let pageToNo: number = this.page;
    this.page = ++pageToNo;
  }

  selectTemp(event) {
    this.tempId = event;

    if (this.authService && this.authService.token) {
      this.appManagementService.requestAppTemplate(event);
      this.appManagementService.setTempId(event);
      this.appManagementService.goToSpecificPage(1);
      // this.authService.updateProgressWeb('0', 2);

      this._router.navigate(['/app']);
      this.dashboard.setSection(UISections.APP);
      this.appManagementService.setUserData(null, event);
    } else {
      this.rgsService.setTempId(event);

      if (
        this.localStorage.getRecord('lmref') &&
        this.localStorage.getRecord('lmref') !== 'undefined'
      ) {
        this._router.navigate(['/signup'], {
          queryParams: { lmref: this.localStorage.getRecord('lmref') }
        });
      } else {
        this._router.navigate(['/signup']);
      }
    }
  }
  start() {
    this.page = 1;
    const newAppInfo: AppInfo = {};
    newAppInfo.demo_video = 0;
    this.startOverSecValidation = false;
    this.appManagementService.updateAppInfo(newAppInfo);
  }
  saveCat(item) {
    this._ref.detectChanges();
    this.category = item;
    this.filterTempsbyCat();
    this.tempType = item;
    this.userData.category = item.title;
  }

  filterTempsbyCat() {
    if (this.generalList) {
      this.tempsList = this.generalList.filter(
        res => res.category === this.category.type
      );
      if (this.category.type === 'A') {
        this.tempsList = this.generalList;
      }
    }
  }

  saveTeq(item) {}
  helpValue(event) {
    this.helpPage = event;
    this._ref.detectChanges();
  }
  bubbleChanged(event) {
    this.bubbleTheme = event;
  }
  branding() {
    this.page = 7;
  }
  cutomize(event?) {}
  addRemoveMoreTyes(item) {
    const itemIndex = this.moreTypes.indexOf(item);
    if (itemIndex > -1) {
      this.moreTypes.splice(itemIndex, 1);
    } else {
      this.moreTypes.push(item);
    }
  }
  // addTabs() {
  //   let mainMenu: BotMenu = {};
  //   let maianMenuAppClass: AppClass = {};
  //   this.menuSubscription = this.appManagementService.menusAppClass$.subscribe(
  //     resApp => {
  //       mainMenu = resApp.menus[0];
  //       maianMenuAppClass = resApp;
  //     }
  //   );

  //   this.appManagementService.tabsAppClass$.subscribe(resApp => {
  //     this.appClass = resApp;
  //   });
  //   const menucontainer: LocalContainer = {};
  //   menucontainer.type = this.tabsTypes.MENUS;
  //   menucontainer.menu_id = mainMenu.menu_ref;
  //   this.appManagementService.addContainer(this.appClass, menucontainer);
  //   let buttonId = 0;

  //   for (let i = 0; i < mainMenu.rows.length; i++) {
  //     for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
  //       buttonId++;
  //       const buttonContainer: LocalContainer = {};
  //       buttonContainer.type = '';
  //       buttonContainer.layout = 'button';
  //       buttonContainer.id = 'button' + buttonId;
  //       buttonContainer.menu_id = mainMenu.menu_ref;
  //       buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
  //       buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

  //       this.appManagementService.addContainer(this.appClass, buttonContainer);
  //     }
  //   }
  // }
  addMoreItems() {
    for (let i = 0; i < this.moreTypes.length; i++) {
      const newContainer: any = {};

      newContainer.icon_ios = 'group-5';
      newContainer.link = this.moreTypes[i].link;
      newContainer.title = this.moreTypes[i].title;
      newContainer.ui_module_id = '' + this.moreTypes[i].id + '';
      newContainer.icon = 'ic_assignment_24dp';

      if (newContainer.link === this.tabsTypes.MAP) {
        this.menuItem = this.appMenu.find(menu => menu.id === 93);
        newContainer.map_menu_id = this.menuItem.id;
        newContainer.map = this.menuItem.value;
        newContainer.url = this.menuItem.url;
      }

      if (newContainer.link === this.tabsTypes.OPENCHAT) {
        newContainer.sub_type = this.moreTypes[i].parameter;
      }

      if (newContainer.link === this.tabsTypes.MYLIST) {
        newContainer.sub_type = this.moreTypes[i].parameter;
      }

      switch (newContainer.link) {
        case this.tabsTypes.BOOKING:
          newContainer.icon = 'ic_baseline_event_24dp';
          break;
        case this.tabsTypes.BUNDLE:
          newContainer.icon = 'ic_redeem_24dp';
          break;
        case this.tabsTypes.CALENDAR:
          newContainer.icon = 'ic_calendar_24dp';
          break;
        case this.tabsTypes.CALL_LOG:
          newContainer.icon = 'ic_settings_phone_24dp';
          break;
        case this.tabsTypes.CHANNELS:
          newContainer.icon = 'ic_outline_rss_feed_24dp';
          break;
        case this.tabsTypes.CHATS:
          newContainer.icon = 'ic_question_answer_24dp';
          break;
        case this.tabsTypes.INVITATION:
          newContainer.icon = 'ic_person_add_24dp';
          break;
        case this.tabsTypes.MAP:
          newContainer.icon = 'ic_room_24dp';
          break;
        case this.tabsTypes.MAP_SEARCH:
          newContainer.icon = 'ic_zoom_in_24dp';
          break;
        case this.tabsTypes.MENUS:
          newContainer.icon = 'ic_toc_24dp';
          break;
        case this.tabsTypes.OPENCHAT:
          newContainer.icon = 'ic_person_outline_24dp';
          break;
        case this.tabsTypes.MYLIST:
          newContainer.icon = 'ic_reorder_24dp';
          break;
        case this.tabsTypes.PACKAGE:
          newContainer.icon = 'ic_add_shopping_cart_24dp';
          break;
        case this.tabsTypes.QR:
          newContainer.icon = 'ic_line_style_24dp';
          break;
        case this.tabsTypes.QR_PAGE:
          newContainer.icon = 'ic_line_style_24dp';
          break;
        case this.tabsTypes.REDEEM_TICKET:
          newContainer.icon = 'ic_fingerprint_24dp';
          break;
        case this.tabsTypes.SEARCH:
          newContainer.icon = 'ic_search_24dp';
          break;
        case this.tabsTypes.SETTINGS:
          newContainer.icon = 'ic_settings_24dp';
          break;
        case this.tabsTypes.VALIDATE_TICKET:
          newContainer.icon = 'ic_check_circle_red_24_px';
          break;
        case this.tabsTypes.WEBVIEW:
          newContainer.icon = 'ic_public_24dp';
          break;
      }
      this.appManagementService.addItem(this.component, newContainer);
    }
    this.continue();
  }

  get filterModules() {
    const appTypes = [];
    const excludedTypes = [
      this.tabsTypes.SETTINGS,
      this.tabsTypes.WEBVIEW,
      this.tabsTypes.MAP,
      this.tabsTypes.MAP_SEARCH,
      this.tabsTypes.SECTION,
      this.tabsTypes.FEED,
      this.tabsTypes.MEMBERSHIP,
      this.tabsTypes.WALLET,
      this.tabsTypes.MENUS,
      this.tabsTypes.QR_PAGE
    ];

    if (this.tabs && this.navItems) {
      this.tabs.map(res => appTypes.push(res.type));
      this.navItems.map(res => appTypes.push(res.link));
      return this.getFeature.filterModules(appTypes, excludedTypes);
    }
  }

  edit(event) {
    this.editNotify.emit(event);
  }
  newMenu(event) {
    this.newMenuNotify.emit(event);
  }

  uploadImage(event) {
    this.uploadNotify.emit(event);
  }
  showConfirmation(value) {
    this.startOverFirstValidation = value;
  }

  secConfirmation(value) {
    this.startOverSecValidation = value;
    this.startOverFirstValidation = false;
    this.appManagementService._buttonArray.next([]);
  }

  startAgain() {
    this.startAgainNotify.emit(true);
  }
  tempView(event) {
    this.currentTemp = event;
    this.continue();
  }
}
