import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as BookingPeriodsActions from './bookingPeriods.actions';

import { AppState } from 'src/models/AppState';
import { BookingPeriods, DayPeriods } from 'src/models/BookingPeriods';

@Injectable({
  providedIn: 'root'
})
export class BookingPeriodsDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveBookingPeriods(bookingPeriods: BookingPeriods): void {
    this._store.dispatch(
      new BookingPeriodsActions.ReceiveBookingPeriods(bookingPeriods)
    );
  }

  createBookingPeriods(bookingPeriods: BookingPeriods): void {
    this._store.dispatch(
      new BookingPeriodsActions.CreateBookingPeriods(bookingPeriods)
    );
  }

  updateBookingPeriods(bookingPeriods: BookingPeriods): void {
    this._store.dispatch(
      new BookingPeriodsActions.UpdateBookingPeriods(bookingPeriods)
    );
  }

  deleteBookingPeriods(chatId: string, data: DayPeriods[]): void {
    this._store.dispatch(
      new BookingPeriodsActions.DeleteBookingPeriods(chatId, data)
    );
  }
}
