import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import * as ProductStoreUIActions from './product-store.actions';
import { ProductStore } from 'src/models/productStore';

@Injectable({
  providedIn: 'root'
})
export class ProductsStoreDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetProductsStorePaging() {
    this._store.dispatch(
      new ProductStoreUIActions.ResetProductsStoreUIPaging()
    );
  }

  getNextProductsStorePage(): void {
    this._store.dispatch(new ProductStoreUIActions.GetNextProductsStorePage());
  }

  getPrevProductsStorePage(): void {
    this._store.dispatch(new ProductStoreUIActions.GetPrevProductsStorePage());
  }

  receivedProductsStoreHistoryChunk(
    eop: number,
    sop: number,
    currentPage: ProductStore[]
  ): void {
    this._store.dispatch(
      new ProductStoreUIActions.ReceivedProductsStoreHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  setProductStoreItem(product: ProductStore) {
    this._store.dispatch(
      new ProductStoreUIActions.SetProductStoreItem(product)
    );
  }

  setSelectedProductStore(product: ProductStore) {
    this._store.dispatch(
      new ProductStoreUIActions.SetSelectedProductStore(product)
    );
  }

  getProductItem(id: number) {
    this._store.dispatch(new ProductStoreUIActions.GetProductItem(id));
  }

  receivedProductStore(product: ProductStore) {
    this._store.dispatch(
      new ProductStoreUIActions.ReceivedProductStore(product)
    );
  }

  setProductsItemArray(
    product: ProductStore[],
    parent_id?: number,
    type?: number
  ) {
    this._store.dispatch(
      new ProductStoreUIActions.SetProductsItemArray(product, parent_id, type)
    );
  }

  removeProductItem(id) {
    this._store.dispatch(new ProductStoreUIActions.RemoveProductItem(id));
  }

  removeProductItemSuccess(id: number) {
    this._store.dispatch(
      new ProductStoreUIActions.RemoveProductItemSuccess(id)
    );
  }

  setProductItemArraySuccess(id: number, product: ProductStore[]) {
    this._store.dispatch(
      new ProductStoreUIActions.SetProductItemArraySuccess(id, product)
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new ProductStoreUIActions.SetStatusAsIdle());
  }

  setCollectionsToProduct(productID: number, collections: number[]) {
    this._store.dispatch(
      new ProductStoreUIActions.SetCollectionsToProduct(productID, collections)
    );
  }
}
