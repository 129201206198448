import { Store } from '@ngrx/store';
import * as MapTripsHistoryAction from './mapTripsHistory.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapTripHistoryDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    restMapTripsHistoryPaging(startDate, endDate) {
        this._store.dispatch(new MapTripsHistoryAction.ResetMapTripHistoryPaging(startDate, endDate));
    }
    getNextMapTripsHistoryPage() {
        this._store.dispatch(new MapTripsHistoryAction.GetNextMapTripsHistoryPage());
    }
    getPrevMapTripsHistoryPage() {
        this._store.dispatch(new MapTripsHistoryAction.GetPrevMapTripsHistoryPage());
    }
    mapTripHistoryListReceived(eop, sop, mapTrips, hash) {
        this._store.dispatch(new MapTripsHistoryAction.MapTripHistoryListReceived(eop, sop, mapTrips, hash));
    }
    setStatusAsIdle() {
        this._store.dispatch(new MapTripsHistoryAction.SetStatusAsIdle());
    }
}
MapTripHistoryDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapTripHistoryDispatchers_Factory() { return new MapTripHistoryDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapTripHistoryDispatchers, providedIn: "root" });
