import { Store } from '@ngrx/store';
import * as StoresUIAction from './storesUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class StoreUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createStore(storeDetails) {
        this._store.dispatch(new StoresUIAction.CreateStore(storeDetails));
    }
    getStore(storeId) {
        this._store.dispatch(new StoresUIAction.GetStore(storeId));
    }
    setStore(storeID, storeDetails) {
        this._store.dispatch(new StoresUIAction.SetStore(storeID, storeDetails));
    }
    addStoreItem(storeID, itemIDs) {
        this._store.dispatch(new StoresUIAction.AddStoreItem(storeID, itemIDs));
    }
    assignStoreChannel(storeID, chatIds) {
        this._store.dispatch(new StoresUIAction.AssignStoreChannel(storeID, chatIds));
    }
    removeStoreItem(storeID, itemIDs) {
        this._store.dispatch(new StoresUIAction.RemoveStoreItem(storeID, itemIDs));
    }
    deleteStoreMobile(id) {
        this._store.dispatch(new StoresUIAction.DeleteStore(id));
    }
    storeMobileDeleted(id) {
        this._store.dispatch(new StoresUIAction.StoreDelete(id));
    }
    // paging dispatchers
    getNextStorePage() {
        this._store.dispatch(new StoresUIAction.GetNextStorePage());
    }
    getPrevStorePage() {
        this._store.dispatch(new StoresUIAction.GetPrevStorePage());
    }
    receivedStoreHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new StoresUIAction.ReceivedStoreHistoryChunk(eop, sop, currentPage));
    }
    storeReceived(pkg) {
        this._store.dispatch(new StoresUIAction.StoreReceived(pkg));
    }
    resetStorePaging() {
        this._store.dispatch(new StoresUIAction.ResetStorePaging());
    }
    setStoreSelectedChat(selected_chat = null) {
        this._store.dispatch(new StoresUIAction.SetStoreSelectedChat(selected_chat));
    }
    setStatusAsIdle() {
        this._store.dispatch(new StoresUIAction.SetStatusAsIdle());
    }
}
StoreUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreUiDispatchers_Factory() { return new StoreUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: StoreUiDispatchers, providedIn: "root" });
