import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as ThirdPartyInfoMethods from 'src/models/thirdPartyInfo';
export class ThirdPartyInfoUIEffect {
    constructor(actions$, _socketGateway, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this.saveThirdPartyInfo = this.actions$.pipe(ofType("UI_SAVE_THIRD_PARTY_INFO" /* UI_SAVE_THIRD_PARTY_INFO */), withLatestFrom(this._store
            .select(state => state.thirdPartyInfoReducer.vendors)
            .pipe(distinctUntilChanged(isEqual))), map(([val, vendors]) => {
            const action = val;
            this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.SaveThirdPartyInfoMethod(vendors));
        }));
        this.testThirdPartyInfo = this.actions$.pipe(ofType("UI_TEST_THIRD_PARTY_INFO" /* UI_TEST_THIRD_PARTY_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.TestThirdPartyInfoMethod(action.vendor));
        }));
        this.getThirdPartyInfo = this.actions$.pipe(ofType("UI_GET_THIRD_PARTY_INFO" /* UI_GET_THIRD_PARTY_INFO */), map((action) => {
            this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.GetThirdPartyInfoMethod(action.name));
        }));
        this.sendVerificationMessage = this.actions$.pipe(ofType("UI_DELETE_VERIFICATION_MASSAGE" /* UI_DELETE_VERIFICATION_MASSAGE */), withLatestFrom(this._store
            .select(state => state.thirdPartyInfoReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, vendors]) => {
            const action = val;
            const deleteEmailMessage = vendors.VerificationMessageEmailType ? false : true;
            const deleteSMSMessage = vendors.VerificationMessageSMSType ? false : true;
            if (vendors.verificationMessageEmail && deleteEmailMessage || vendors.verificationMessageSMS && deleteSMSMessage) {
                this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.DeleteVerificationTemplate(deleteEmailMessage, deleteSMSMessage));
            }
        }));
        this.deleteVerificationMessage = this.actions$.pipe(ofType("UI_SEND_VERIFICATION_MASSAGE" /* UI_SEND_VERIFICATION_MASSAGE */), withLatestFrom(this._store
            .select(state => state.thirdPartyInfoReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, vendors]) => {
            const action = val;
            const email = vendors.VerificationMessageEmailType ? vendors.verificationMessageEmail : null;
            const sms = vendors.VerificationMessageSMSType ? vendors.verificationMessageSMS : null;
            if (email || sms) {
                this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.SendVerificationTemplate(email, sms));
            }
        }));
        this.getVerificationMessage = this.actions$.pipe(ofType("UI_GET_VERIFICATION_MASSAGE" /* UI_GET_VERIFICATION_MASSAGE */), map((action) => {
            this._socketGateway.sendSocketMessage(new ThirdPartyInfoMethods.GetVerificationTemplate());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "saveThirdPartyInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "testThirdPartyInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "getThirdPartyInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "sendVerificationMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "deleteVerificationMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ThirdPartyInfoUIEffect.prototype, "getVerificationMessage", void 0);
