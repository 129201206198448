import {
  BOOKING_DAYS,
  CREATE_CALENDAR_METHOD,
  SET_CALENDAR_METHOD,
  GET_CALENDAR_METHOD,
  GET_CALENDARS_METHOD,
  SET_CALENDAR_DETAIL_METHOD,
  GET_CALENDAR_DETAILS_METHOD,
  CREATE_CALENDAR_REF,
  MERCHANT_PAGE_SIZE
} from './constants';

export interface HoursRange {
  id?: string;
  calendar_id?: string;
  title?: string;
  description?: string;
  start_time?: string; // "01:00"
  end_time?: string; // "02:00"
  url?: string; // image url
  photo_id?: string; // image id
}

export interface CalendarDay {
  day?: BOOKING_DAYS;
  hours?: HoursRange[];
}

export interface CalendarTimetable {
  id?: string;
  group_id?: string;
  title?: string;
  start_date?: string; // "2019-09-15"
  end_date?: string; // "2019-09-15"
  url?: string; // image url
  photo_id?: string; // image id
  description?: string;
  exceptions?: string[]; // ["2019-10-06","2019-07-23"]
  data?: CalendarDay[];
}

export class CreateCalendar {
  readonly method = CREATE_CALENDAR_METHOD;
  readonly reference = CREATE_CALENDAR_REF;
  constructor(calendar: CalendarTimetable) {
    for (const key in calendar) {
      if (calendar.hasOwnProperty(key) && key !== 'data') {
        this[key] = calendar[key];
      }
    }
  }
}

export class SetCalendar {
  readonly method = SET_CALENDAR_METHOD;
  constructor(calendar: CalendarTimetable) {
    for (const key in calendar) {
      if (calendar.hasOwnProperty(key) && key !== 'data') {
        this[key] = calendar[key];
      }
    }
  }
}

export class GetCalendar {
  readonly method = GET_CALENDAR_METHOD;
  constructor(public id: string) {}
}

export class GetCalendars {
  readonly method = GET_CALENDARS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public hash?: string,
    public reference?: number
  ) {}
}

export class SetCalendarDetail {
  readonly method = SET_CALENDAR_DETAIL_METHOD;
  readonly clear = 1;
  constructor(public calendar_id: string, public data: CalendarDay[]) {}
}

export class GetCalendarDetails {
  readonly method = GET_CALENDAR_DETAILS_METHOD;
  constructor(public calendar_id: string) {}
}

// The "references" is an array of the IDs of hours that need to be deleted
export class RemoveCalendarDetail {
  readonly method = GET_CALENDAR_DETAILS_METHOD;
  constructor(public calendar_id: string, public references: string[]) {}
}
