import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import { WhitelistPatternsUiDispatchers } from './whitelistPatternsUI.dispatchers';
import { WhitelistPatternsUIActionTypes } from './whitelistPatternsUI.actions';
import * as WhitelistPatternsUIActions from './whitelistPatternsUI.actions';
import * as WhitelistUI from 'src/models/Whitelist';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class WhitelistPatternsUIEffects {
  @Effect({ dispatch: false })
  afterWhitelistResetPaging = this.actions$.pipe(
    ofType(WhitelistPatternsUIActionTypes.UI_WHITELIST_PATTERNS_RESET_PAGING),
    map((action: WhitelistPatternsUIActions.ResetWhitelistPatternsPaging) => {
      this._whitelistPatternsUiDispatchers.getNextWhitelistPatternsPage();
    })
  );

  @Effect({ dispatch: false })
  getNextWhitelistPage = this.actions$.pipe(
    ofType(WhitelistPatternsUIActionTypes.UI_GET_NEXT_WHITELIST_PATTERNS_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.whitelistPatternsUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, whitelistPatternsUiState]) => {
      const action = <WhitelistPatternsUIActions.GetNextWhitelistPatternsPage>(
        val
      );
      const prevRequest = whitelistPatternsUiState.previousRequest;
      if (
        !(
          prevRequest.eop === whitelistPatternsUiState.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          prevRequest.hash === whitelistPatternsUiState.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new WhitelistUI.GetWhitelistPatternsMethod(
            null,
            0,
            whitelistPatternsUiState.hash,
            whitelistPatternsUiState.eop
          )
        );
      } else {
        this._whitelistPatternsUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevWhitelistPage = this.actions$.pipe(
    ofType(WhitelistPatternsUIActionTypes.UI_GET_PREV_WHITELIST_PATTERNS_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.whitelistPatternsUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, whitelistPatternsUiState]) => {
      const action = <WhitelistPatternsUIActions.GetPrevWhitelistPatternsPage>(
        val
      );
      const prevRequest = whitelistPatternsUiState.previousRequest;
      if (
        !(
          prevRequest.sop === whitelistPatternsUiState.sop &&
          PageDirection.PREV === prevRequest.direction &&
          prevRequest.hash === whitelistPatternsUiState.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new WhitelistUI.GetWhitelistPatternsMethod(
            null,
            1,
            whitelistPatternsUiState.hash,
            whitelistPatternsUiState.sop
          )
        );
      } else {
        this._whitelistPatternsUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  addPatternWhitelistToList = this.actions$.pipe(
    ofType(WhitelistPatternsUIActionTypes.UI_ADD_WHITELIST_PATTERNS_TO_LIST),
    map((action: WhitelistPatternsUIActions.AddWhitelistPatternsToList) => {
      this._socketGateway.sendSocketMessage(
        new WhitelistUI.AddPatternToWhitelist(action.chat_id, action.users)
      );
    })
  );

  @Effect({ dispatch: false })
  deletePatternFromWhitelist = this.actions$.pipe(
    ofType(WhitelistPatternsUIActionTypes.UI_DELETE_PATTERNS_FROM_WHITELIST),
    map((action: WhitelistPatternsUIActions.DeletePatternFromWhitelist) => {
      this._socketGateway.sendSocketMessage(
        new WhitelistUI.RemovePatternFromWhitelist(action.users, action.chat_id)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _whitelistPatternsUiDispatchers: WhitelistPatternsUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
