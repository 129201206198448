/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-support.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./no-support.component";
import * as i3 from "../../../+merchant/+dashboard/dashboard.service";
import * as i4 from "@angular/router";
var styles_NoSupportComponent = [i0.styles];
var RenderType_NoSupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoSupportComponent, data: {} });
export { RenderType_NoSupportComponent as RenderType_NoSupportComponent };
export function View_NoSupportComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "no-support"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "app-logo clickable-cursor"], ["href", "https://nandbox.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "main-logo"], ["draggable", "false"], ["height", "50"], ["src", "./assets/img/no-sport-logo-large.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "small-logo"], ["draggable", "false"], ["height", "50"], ["src", "./assets/img/no-sport-logo-small.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-logout"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Log Out "])), (_l()(), i1.ɵeld(8, 0, null, null, 30, "div", [["class", "no-support-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "no-support-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "mac-book"], ["class", "lap"], ["src", "/assets/img/mac-book.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "no-support-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Use a desktop to access nandbox App Builder "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "no-support-tagline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" nandbox App builder is not avaliable for mobile, please login from a desktop. "])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "support"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://calendly.com/nandbox-app-builder/create-your-own-app-in-minutes-schedule-a-call-to-explore-your-no-code-app-building-requirements") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Free Consultation: Act Now! "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "videos"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://www.youtube.com/watch?v=kdNj3-ecrT0&list=PL7l4b3PO2OO6DFCQ5DmNs3FhVpk5D-LeG&index=1") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["src", "/assets/img/play-icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Watch Videos "])), (_l()(), i1.ɵeld(21, 0, null, null, 17, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "footer-term"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [["cla", ""], ["class", "link"], ["style", "padding-right: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://nandbox.com") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2023 nandbox \u00AE Inc. "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "service link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://mailchimp.com/legal/terms/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Terms of Service "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "div", [["class", "link"], ["style", "padding-left: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://nandbox.com/privacy-policy/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Privacy Policy "])), (_l()(), i1.ɵeld(29, 0, null, null, 7, "div", [["class", "footer-policy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["style", "display: flex; align-items: center; margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "service link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://nandbox.com/terms/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Terms of Service "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "div", [["class", "link"], ["style", "padding-left: 15px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://nandbox.com/privacy-policy/") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Privacy Policy "])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "div", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLink("https://nandbox.com") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2023 nandbox \u00AE Inc. "])), (_l()(), i1.ɵeld(37, 0, null, null, 1, "div", [["class", "version"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["nandbox App Builder - Version 1.6.145."]))], null, null); }
export function View_NoSupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-support", [], null, null, null, View_NoSupportComponent_0, RenderType_NoSupportComponent)), i1.ɵdid(1, 114688, null, 0, i2.NoSupportComponent, [i3.DashboardService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoSupportComponentNgFactory = i1.ɵccf("app-no-support", i2.NoSupportComponent, View_NoSupportComponent_Host_0, {}, {}, []);
export { NoSupportComponentNgFactory as NoSupportComponentNgFactory };
