import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { ChartObj } from 'src/models/ChartUI';
import * as ChartActions from './chart.actions';

@Injectable({
  providedIn: 'root'
})
export class ChartDispatchers {
  constructor(private _store: Store<AppState>) {}

  getChartData(
    chat_id: string,
    chart_type?: number,
    interval?: number,
    startDate?: string,
    endDate?: string
  ) {
    this._store.dispatch(
      new ChartActions.GetChartData(
        chat_id,
        chart_type,
        interval,
        startDate,
        endDate
      )
    );
  }

  receiveChartData(
    chart_type: number,
    values: ChartObj[],
    startDate: string,
    endDate: string,
    interval: number
  ) {
    this._store.dispatch(
      new ChartActions.ReceiveChartData(
        chart_type,
        values,
        startDate,
        endDate,
        interval
      )
    );
  }
}
