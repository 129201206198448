import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { IMessage } from 'src/models/IMessage';
import { MessageLinkPreviewStatus } from 'src/models/constants';

@Component({
  selector: 'app-instant-cell',
  templateUrl: './instant-cell.component.html',
  styleUrls: ['./instant-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstantCellComponent implements OnInit {
  @Input() message: IMessage;
  @Input() redirect = true;

  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  constructor() {}

  ngOnInit() {}
}
