import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import * as Methods from 'src/models/MapMarker';
import { GetMapMarkers } from 'src/models/MapMarker';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import { MapMarkerDispatchers } from './mapMarker.dispatchers';
export class MapMarkerEffects {
    constructor(actions$, _store, _mapMarkerDispatchers, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._mapMarkerDispatchers = _mapMarkerDispatchers;
        this._socketGateway = _socketGateway;
        this.createMapMarker = this.actions$.pipe(ofType("UI_CREATE_MAP_MARKER" /* UI_CREATE_MAP_MARKER */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapMarker(action.payload));
        }));
        this.getMapMarker = this.actions$.pipe(ofType("UI_GET_MAP_MARKER" /* UI_GET_MAP_MARKER */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.GetMapMarker(action.id));
        }));
        this.updateMapMarker = this.actions$.pipe(ofType("UI_UPDATE_MAP_MARKER" /* UI_UPDATE_MAP_MARKER */), map((action) => {
            const mapMarker = Object.assign({}, action.mapMarkerDetails, { id: action.id });
            this._socketGateway.sendSocketMessage(new Methods.SetMapMarker(mapMarker));
        }));
        this.deleteMapMarker = this.actions$.pipe(ofType("UI_DELETE_MAP_MARKER" /* UI_DELETE_MAP_MARKER */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveMapMarker(action.id));
        }));
        this.getNextMapMarkerPage = this.actions$.pipe(ofType("UI_MAP_MARKER_GET_NEXT_PAGE" /* UI_MAP_MARKER_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.mapMarkerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapMarkerUiState]) => {
            const action = val;
            const prevRequest = mapMarkerUiState.previousRequest;
            if (!(mapMarkerUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapMarkerUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMapMarkers(0, mapMarkerUiState.hash, mapMarkerUiState.eop, mapMarkerUiState.selected_chat, mapMarkerUiState.type));
            }
            else {
                this._mapMarkerDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMapMarkerPage = this.actions$.pipe(ofType("UI_MAP_MARKER_GET_PREV_PAGE" /* UI_MAP_MARKER_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.mapMarkerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapMarkerUiState]) => {
            const action = val;
            const prevRequest = mapMarkerUiState.previousRequest;
            if (!(mapMarkerUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapMarkerUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMapMarkers(1, mapMarkerUiState.hash, mapMarkerUiState.sop, mapMarkerUiState.selected_chat, mapMarkerUiState.type));
            }
            else {
                this._mapMarkerDispatchers.setStatusAsIdle();
            }
        }));
        this.afterMapMarkerResetPaging = this.actions$.pipe(ofType("UI_MAP_MARKER_RESET_PAGING" /* UI_MAP_MARKER_RESET_PAGING */), map((action) => {
            this._mapMarkerDispatchers.getNextMapMarkerPage();
        }));
        this.afterMapMarkerDeleted = this.actions$.pipe(ofType("UI_MAP_MARKER_DELETED" /* UI_MAP_MARKER_DELETED */), map((action) => {
            this._mapMarkerDispatchers.resetMapMarkerPaging();
        }));
        this.setMapMarkerSelectedChat = this.actions$.pipe(ofType("UI_MAP_MARKER_SET_SELECTED_CHAT" /* UI_MAP_MARKER_SET_SELECTED_CHAT */), map((action) => {
            this._mapMarkerDispatchers.resetMapMarkerPaging();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "createMapMarker", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "getMapMarker", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "updateMapMarker", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "deleteMapMarker", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "getNextMapMarkerPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "getPrevMapMarkerPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "afterMapMarkerResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "afterMapMarkerDeleted", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapMarkerEffects.prototype, "setMapMarkerSelectedChat", void 0);
