import { Store } from '@ngrx/store';
import * as BundleUiActions from './bundlesUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BundlesUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createBundle(bundle) {
        this._store.dispatch(new BundleUiActions.CreateBundle(bundle));
    }
    setBundle(bundleID, bundle) {
        this._store.dispatch(new BundleUiActions.SetBundle(bundleID, bundle));
    }
    addBundleProducts(bundleID, bundleProducts) {
        this._store.dispatch(new BundleUiActions.AddBundleProducts(bundleID, bundleProducts));
    }
    bundleProductInfoReceived(bundleID, product) {
        this._store.dispatch(new BundleUiActions.BundleProductInfoReceived(bundleID, product));
    }
    removeBundleProducts(bundleID, productsIds) {
        this._store.dispatch(new BundleUiActions.RemoveBundleProducts(bundleID, productsIds));
    }
    bundleReceived(bundle) {
        this._store.dispatch(new BundleUiActions.BundleReceived(bundle));
    }
    // paging dispatchers
    getNextBundlesPage() {
        this._store.dispatch(new BundleUiActions.GetNextBundlesPage());
    }
    getPrevBundlesPage() {
        this._store.dispatch(new BundleUiActions.GetPrevBundlesPage());
    }
    receivedBundlesHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new BundleUiActions.ReceivedBundlesHistoryChunk(eop, sop, currentPage));
    }
    resetBundlesPaging() {
        this._store.dispatch(new BundleUiActions.ResetBundlesPaging());
    }
    deleteBundle(id) {
        this._store.dispatch(new BundleUiActions.DeleteBundle(id));
    }
    bundleDeleted(id) {
        this._store.dispatch(new BundleUiActions.BundleDeleted(id));
    }
    setStatusAsIdle() {
        this._store.dispatch(new BundleUiActions.SetStatusAsIdle());
    }
    buyBundleOnBehalfOfUser(userId, bundle) {
        this._store.dispatch(new BundleUiActions.BuyBundleOnBehalfOfUser(userId, bundle));
    }
    cashPaymentStatus(payResult) {
        this._store.dispatch(new BundleUiActions.CashPaymentStatus(payResult));
    }
}
BundlesUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BundlesUiDispatchers_Factory() { return new BundlesUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: BundlesUiDispatchers, providedIn: "root" });
