import { BotMenu } from 'src/models/IBotMenu';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { UiService } from 'src/app/core/ui.service';
import { ComposeMessageService } from '../compose-message.service';

import { SelectMessageTargetsComponent } from '../select-message-targets/select-message-targets.component';
import { ComposeTextInputComponent } from '../compose-text-input/compose-text-input.component';
import { SelectMessageStyleComponent } from '../select-message-style/select-message-style.component';
import { ComposeScheduleInputComponent } from '../compose-schedule-input/compose-schedule-input.component';
import { SelectMessageAttachsComponent } from '../select-message-attachs/select-message-attachs.component';
import { PreviewCalendarComponent } from '../preview-calendar/preview-calendar.component';

import { IMessage } from 'src/models/IMessage';
import { MessageControls, Sticky } from 'src/models/MessageParts';
import { InstantArticle } from 'src/models/InstantArticle';
import { CalendarTimetable } from 'src/models/Calendar';
import { IChat } from 'src/models/IChat';
import { MainChat } from 'src/models/MainChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import {
  CHANNELS_THEME_COLOR,
  MessageSendingType,
  MessageTypes,
  LinkPreviewOptions,
  MessageLinkPreviewStatus,
  MessageStyles,
  UISections
} from 'src/models/constants';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';
import { PreviewMediaComponent } from 'src/app/previews/preview-media/preview-media.component';
import { AppMgmtService } from '../../app-mgmt/appMgmt.service';
import { PreviewLinkComponent } from '../preview-link/preview-link.component';
import { ICoupon } from 'src/models/ICoupon';
import { COUPONS } from 'src/models/AppSections';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { InstantArticleService } from '../../instant-article/instant-article.service';
import { SubChatsService } from '../../sub-chats/sub-chats.service';
import { Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-compose-message-modal',
  templateUrl: './compose-message-modal.component.html',
  styleUrls: ['./compose-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeMessageModalComponent implements OnInit {
  showPreview = false;
  silentFlag = false;
  toggleDatePacker = false;
  toggleMenu = false;
  autoAcceptCalendar = false;
  themeColor = CHANNELS_THEME_COLOR;
  iv$: Observable<InstantArticle> = null;
  scheduleValid: boolean;
  massageInputValid: boolean;

  showTooltipHover = false;
  textTooltip: string;
  silentIcon = 'icon-volume-on';
  stickyPost: Sticky;
  selectedMenu: BotMenu;
  couponMenu: ICoupon;
  couponFet = COUPONS;

  selectedFile: ISelectedFiles;

  private _selectedCalendar = new BehaviorSubject<CalendarTimetable>(null);
  public selectedCalendar$ = this._selectedCalendar.asObservable();

  attachType: typeof MessageSendingType = MessageSendingType;
  mType: typeof MessageTypes = MessageTypes;
  mStyles: typeof MessageStyles = MessageStyles;
  scheduleInput: number;

  resizeToHeight = 0;
  resizeToWidth = 0;

  onlyScaleDown = true;

  @ViewChild('targetsComponent', { static: false })
  targetsComponent: SelectMessageTargetsComponent;
  @ViewChild('textInputComponent', { static: false })
  textInputComponent: ComposeTextInputComponent;
  @ViewChild('scheduleInputComponent', { static: false })
  scheduleInputComponent: ComposeScheduleInputComponent;
  @ViewChild('stylesComponent', { static: false })
  stylesComponent: SelectMessageStyleComponent;
  @ViewChild('attachComponent', { static: false })
  attachComponent: SelectMessageAttachsComponent;
  @ViewChild('calendarComponent', { static: false })
  calendarComponent: PreviewCalendarComponent;
  @ViewChild('postMediaPreview', { static: false })
  postMediaPreview: PreviewMediaComponent;
  @ViewChild('instantArticleComponent', { static: false })
  instantArticleComponent: PreviewLinkComponent;
  @ViewChild('silentBtn', { static: false })
  silentBtn: ElementRef;
  @ViewChild('menuBtn', { static: false })
  menuBtn: ElementRef;
  @ViewChild('menu', { static: false })
  menu: ElementRef;
  @ViewChild('schedule', { static: false })
  schedule: ElementRef;

  @Input() selectedSubChat: MainChat;
  @Input() selectedMessage: IMessage;
  @Input() selectedUserId: string;
  @Input() chatAdminName: string;
  @Input() messageSendingType: string;
  @Input() isSubChatSelected = false;
  @Input() isMainChannel = false;

  @Output() cancelNotfiy = new EventEmitter<boolean>();

  public _toggleMenu = new BehaviorSubject<boolean>(false);
  public toggleMenu$ = this._toggleMenu.asObservable();

  constructor(
    public uiService: UiService,
    public composeMessageService: ComposeMessageService,
    private renderer: Renderer2,
    public appMgmtService: AppMgmtService,
    public layoutService: LayoutService,
    public subChatsService: SubChatsService,
    public router: Router,
    public dashboardService: DashboardService,
    private _instantArticleService: InstantArticleService
  ) {}

  ngOnInit() {
    this.uiService._previewMessageType.next(this.mType.TEXT);
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.schedule && this.menu) {
        const btnClicked = this.schedule.nativeElement.contains(e.target);
        const menuClicked = this.menu.nativeElement.contains(e.target);
        if (!btnClicked && !menuClicked) {
          this._toggleMenu.next(false);
          this.schedule.nativeElement.checked = false;
        }
      }
    });
  }
  /**************************************************************************/
  // Send Events
  /*************************************************************************/
  /** isText flag used to include bgColor or not */
  setMessageControl(isText?: boolean, chatID?: string): MessageControls {
    let misc: MessageControls = {};
    const silentFlag = this.silentFlag;
    const tags = this.targetsComponent.getSelectedTags(chatID);
    const tab = this.targetsComponent.getSelectedTab();
    const coupon = this.couponMenu;
    const menu = this.selectedMenu;
    if (this.calendarComponent) {
      const autoAcceptCalendar = this.calendarComponent.getAutoAcceptCalendar();
      if (autoAcceptCalendar) {
        misc = {
          ...misc,
          auto_accept: Number(autoAcceptCalendar)
        };
      }
    }

    if (silentFlag) {
      misc = {
        ...misc,
        disable_notification: true
      };
    }

    if (coupon) {
      misc = {
        ...misc,
        c_code: coupon.code,
        c_exp: new Date(coupon.expireDateStr).getTime()
      };
    }
    if (menu) {
      misc = {
        ...misc,
        inline_menu: [menu],
        menu_ref: menu.menu_ref,
        reply_to_admin: true
      };
    }

    misc = { ...misc, tags, tab };

    if (isText) {
      if (this.textInputComponent) {
        const bgColor = this.textInputComponent.getSelectedColor();
        if (bgColor) {
          misc = { ...misc, bg_color: bgColor };
        }
      }
    } else if (!isText) {
      const style = this.stylesComponent
        ? this.stylesComponent.getMessageStyle()
        : this.mStyles.WIDE;
      const sticky = style !== 1 ? this.attachComponent.getStickyInput() : null;
      if (typeof style === 'number') {
        misc = { ...misc, style };
      }
      if (sticky) {
        misc = {
          ...misc,
          sticky_title: sticky.title,
          sticky_desc: sticky.description
        };
      }
    }
    if (this.messageSendingType === MessageSendingType.AWARD) {
      misc = { ...misc, award: 2 };
    } else if (this.messageSendingType === MessageSendingType.WELCOME) {
      misc = { ...misc, welcome: 1, disable_reply: 1 };
    } else if (this.messageSendingType === MessageSendingType.SCHEDULE) {
      misc = {
        ...misc,
        schedule_date: this.scheduleInput
      };
    }
    return misc;
  }

  /** Send Text Message */
  sendTextMessageEvent(text: string) {
    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const miscs = this.setMessageControl(true, group.id);
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendTextMessage(grp, null, null, text, miscs);
      }
    } else {
      const misc = this.setMessageControl(true, this.selectedSubChat.id);
      this.uiService.sendTextMessage(
        this.selectedSubChat,
        null,
        null,
        text,
        misc
      );
    }
    this.cancelEvent();
    if (this.messageSendingType === MessageSendingType.WELCOME) {
      this.returnToSelectedChannel();
    }
  }

  /** Send links */
  sendLinkPreviewEvent(link: InstantArticle) {
    if (link.isInstant) {
      this.sendInstantUrl(link);
    } else {
      this.sendLinkPreview(link);
    }
  }

  sendLinkPreview(instantArticle: InstantArticle) {
    // let misc = this.setMessageControl(false);
    const subgroups = this.targetsComponent.getSelectedSubgroups();

    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        let miscs = this.setMessageControl(false, group.id);
        miscs = {
          ...miscs,
          web_page_preview: LinkPreviewOptions.WEB_PREVIEW_HIDE_LINK
        };
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendTextMessage(
          grp,
          null,
          null,
          instantArticle.linkPreviewUrl,
          miscs
        );
      }
    } else {
      let misc = this.setMessageControl(false, this.selectedSubChat.id);
      misc = {
        ...misc,
        web_page_preview: LinkPreviewOptions.WEB_PREVIEW_HIDE_LINK
      };
      this.uiService.sendTextMessage(
        this.selectedSubChat,
        null,
        null,
        instantArticle.linkPreviewUrl,
        misc
      );
    }
    this.cancelEvent();
    if (this.messageSendingType === MessageSendingType.WELCOME) {
      this.returnToSelectedChannel();
    }
  }

  sendInstantUrl(linkPreview: InstantArticle) {
    if (
      linkPreview.linkPreviewStatus ===
      MessageLinkPreviewStatus.LINK_PREVIEW_FAILED
    ) {
      this.sendTextMessageEvent(linkPreview.linkPreviewUrl);
    } else {
      let misc = this.setMessageControl(false);
      misc = {
        ...misc,
        web_page_preview: LinkPreviewOptions.WEB_PREVIEW_INSTANCE_WITHOUT_LINK
      };

      const subgroups = this.targetsComponent.getSelectedSubgroups();
      if (subgroups && subgroups.length > 0) {
        for (const group of subgroups) {
          const grp = this.getMainChatFromChat(group);
          this.uiService.sendInstantUrl(grp, null, null, linkPreview, misc);
        }
        if (misc.tab) {
          this.uiService.sendInstantUrl(
            this.selectedSubChat,
            null,
            null,
            linkPreview,
            misc
          );
        }
      } else {
        this.uiService.sendInstantUrl(
          this.selectedSubChat,
          null,
          null,
          linkPreview,
          misc
        );
      }
    }
    this.cancelEvent();

    if (this.messageSendingType === MessageSendingType.WELCOME) {
      this.returnToSelectedChannel();
    }
  }

  /** Send Media */
  sendMediaMessageEvent(mediaToSend: any) {
    const tabs = this.targetsComponent.getSelectedTab();
    // for (let i = 0; i < tabs.length; i++) {
    // const misc = this.setMessageControl(false);

    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const miscs = this.setMessageControl(false, group.id);
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendMediaMessage(
          grp,
          null,
          null,
          mediaToSend.files,
          mediaToSend.messageType,
          mediaToSend.mediaMetadata,
          null,
          miscs
        );
      }
    } else {
      const misc = this.setMessageControl(false, this.selectedSubChat.id);
      this.uiService.sendMediaMessage(
        this.selectedSubChat,
        null,
        null,
        mediaToSend.files,
        mediaToSend.messageType,
        mediaToSend.mediaMetadata,
        null,
        misc
      );
    }

    this.uiService.hidePreview();
    // }
    this.cancelEvent();

    if (this.messageSendingType === MessageSendingType.WELCOME) {
      this.returnToSelectedChannel();
    }
  }

  /** Send Calendar */
  sendCalendarEvent(calendar: CalendarTimetable) {
    // const misc = this.setMessageControl(true);
    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const grp = this.getMainChatFromChat(group);
        const miscs = this.setMessageControl(true, group.id);
        this.uiService.sendCalendarMessage(grp, null, null, calendar, miscs);
      }
    } else {
      const misc = this.setMessageControl(true, this.selectedSubChat.id);
      this.uiService.sendCalendarMessage(
        this.selectedSubChat,
        null,
        null,
        calendar,
        misc
      );
    }
    this.cancelEvent();

    if (this.messageSendingType === MessageSendingType.WELCOME) {
      this.returnToSelectedChannel();
    }
  }
  /**************************************************************************/
  // Selected Events
  /*************************************************************************/
  urlSelectedEvent(n: number) {
    this.resetSelectedType();
    this.iv$ = this.composeMessageService.getNewArticle(n);
  }

  fileSelectedEvent(event: ISelectedFiles) {
    if (event.localFile && event.type !== 'photo') {
      this.resetSelectedType();
      this.uiService.getBlobUrl(event);
    } else {
      this.resizeToHeight = 0;
      this.resizeToWidth = 0;
      // if (event.localFile.size > 250000) {
      if (event.height >= event.width) {
        if (event.height > 512) {
          this.resizeToHeight = 512;
        } else {
          this.resizeToHeight = event.height;
        }
      } else {
        if (event.width > 512) {
          this.resizeToWidth = 512;
        } else {
          this.resizeToWidth = event.width;
        }
      }
      this.selectedFile = event;
      // } else {
      //   this.resetSelectedType();
      //   this.uiService.getBlobUrl(event);
      // }
    }
  }

  calendarSelectedEvent(event: CalendarTimetable) {
    this.resetSelectedType();
    this._selectedCalendar.next(event);
    this.uiService._previewMessageType.next(this.mType.CALENDAR);
  }

  linkSelectedEvent() {
    this.resetSelectedType();
    this.uiService._previewMessageType.next(this.mType.CONTACT);
  }

  cancelEvent() {
    this.resetSelectedType();
    this.cancelNotfiy.emit(true);
  }

  /**************************************************************************/
  // Reset part
  /*************************************************************************/
  resetSelectedType() {
    this.iv$ = null;
    this._selectedCalendar.next(null);
    this.uiService.hidePreview();
    // this.uiService._previewMessageType.next(this.mType.TEXT);
  }

  setTextType(type) {
    this.iv$ = null;
    this._selectedCalendar.next(null);
    this.uiService.hidePreview();
    this.uiService._previewMessageType.next(this.mType.TEXT);
  }

  getMainChatFromChat(grp: IChat) {
    return ChatMiddleware.getMainChatFromChat(grp);
  }

  isValid(e) {
    this.scheduleValid = e;
  }

  get canSend() {
    this.massageInputValid = this.textInputComponent
      ? this.textInputComponent.canSend
      : null;

    if (this.messageSendingType === this.attachType.SCHEDULE) {
      if (this.scheduleValid && this.massageInputValid) {
        return false;
      } else {
        return true;
      }
    } else {
      return !this.massageInputValid;
    }
  }

  get canSendMedia() {
    if (this.messageSendingType === this.attachType.SCHEDULE) {
      if (this.scheduleValid) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  toggleHoverTooltip(e) {
    if (this.messageSendingType === this.attachType.SCHEDULE) {
      this.showTooltipHover = e;

      this.textTooltip = `Schedule publishing your post`;
    } else {
      this.showTooltipHover = e;
      this.textTooltip = `Publish immediately`;
    }
  }

  toggleSilentFlag(event: boolean) {
    this.silentIcon = event ? 'icon-volume-off' : 'icon-volume-on';
    this.silentFlag = event;
  }

  isSendBtnDisabled(e) {
    if (!e.disabled) {
      return { 'background-color': '#459fed' };
    } else {
      return { 'background-color': '#b6c6e3' };
    }
  }

  isSchdlBtn() {
    return { 'background-color': '#459fed' };
  }

  toggleDatePackerVisibility(event) {
    this.toggleDatePacker = event;
    this._toggleMenu.next(false);
    this.schedule.nativeElement.checked = false;
  }

  toggleSchedule(event) {
    this._toggleMenu.next(event);
  }

  setSchedule(event) {
    this.scheduleInput = event;
  }

  setStickyPost(event) {
    this.stickyPost = event;
  }

  resetStickyPost(e) {
    e.stopPropagation();
    this.stickyPost = null;
    this.attachComponent.resetStickyInput();
  }

  getTitle(type) {
    switch (type) {
      case this.mType.TEXT:
        return 'Text';
      case this.mType.PHOTO:
        return 'Image';
      case this.mType.VIDEO:
        return 'Video';
      case this.mType.GIF:
        return 'GIF';
      case this.mType.AUDIO:
        return 'Audio';
      case this.mType.DOCUMENT:
        return 'File';
      case this.mType.ARTICLE:
        return 'Instant Articles';
      case this.mType.CONTACT:
        return 'URL';
      case this.mType.CALENDAR:
        return 'Calendar';
    }
  }

  setAutoAcceptCalendar(e: boolean) {
    this.autoAcceptCalendar = e;
  }

  setSelectedMenu(event) {
    this.selectedMenu = event;
  }
  setCouponMenu(event) {
    this.couponMenu = event;
  }

  resetLinkPrivewType() {
    this.iv$ = null;
  }

  imageSelectedEvent(event: ISelectedFiles) {
    const file = {
      localFile: event.localFile,
      type: 'photo'
    };

    if (event.localFile) {
      this.resetSelectedType();
      this.uiService.getBlobUrl(file);
      this.closeImageCrroper();
    }
  }

  closeImageCrroper() {
    this.selectedFile = null;
  }

  returnToSelectedChannel() {
    const chat = ChatMiddleware.getChatFromMainChat(this.selectedSubChat);
    this.subChatsService.showEditSubGroupScreen(chat);
    if (chat.type === UISections.SUBCHANNEL) {
      this.dashboardService.setSection(UISections.SUBCHANNEL);
      this.router.navigate(['/subchannels/form']);
    } else {
      this.dashboardService.setSection(UISections.SUBGROUP);
      this.router.navigate(['/subgroups/form']);
    }
  }
}
