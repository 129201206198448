/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menus-pool.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component.ngfactory";
import * as i3 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/empty-section/empty-section.component.ngfactory";
import * as i6 from "../../../../shared/components/empty-section/empty-section.component";
import * as i7 from "../../../../core/ui.service";
import * as i8 from "../../../../abstract-frames/form-frame/form-frame.component.ngfactory";
import * as i9 from "../../../../abstract-frames/form-frame/form-frame.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./menus-pool.component";
import * as i12 from "../../dashboard.service";
import * as i13 from "../../menus/menus.service";
var styles_MenusPoolComponent = [i0.styles];
var RenderType_MenusPoolComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenusPoolComponent, data: {} });
export { RenderType_MenusPoolComponent as RenderType_MenusPoolComponent };
function View_MenusPoolComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectableCellFrameComponent_0, i2.RenderType_SelectableCellFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], isSelected: [3, "isSelected"], selectorId: [4, "selectorId"], title: [5, "title"], useNewStely: [6, "useNewStely"], cellSize: [7, "cellSize"] }, { checkClick: "checkClick", checkChanged: "checkChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.menuFet == null) ? null : _co.menuFet.symbolColor); var currVal_1 = ((_co.menuFet == null) ? null : _co.menuFet.symbolImage); var currVal_2 = ((_co.menuFet == null) ? null : _co.menuFet.coverColor); var currVal_3 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.id) === ((_co.currentSelectMessage == null) ? null : _co.currentSelectMessage.id)); var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.menu_name); var currVal_6 = true; var currVal_7 = "M"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MenusPoolComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenusPoolComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.TrackByFunction; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MenusPoolComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-section", [["style", "width: 100%; display: block;"]], null, [[null, "newCreateNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newCreateNotify" === en)) {
        var pd_0 = (_co.createNewItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EmptySectionComponent_0, i5.RenderType_EmptySectionComponent)), i1.ɵdid(1, 114688, null, 0, i6.EmptySectionComponent, [i7.UiService], { section: [0, "section"], pool: [1, "pool"] }, { newCreateNotify: "newCreateNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuFet; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MenusPoolComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "navigate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "navigate-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.menusService == null) ? null : _co.menusService.getNextPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-navigate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "navigate-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.menusService == null) ? null : _co.menusService.getPrevPage()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"], ["style", "transform: rotate(180deg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-navigate"]], null, null, null, null, null))], null, null); }
export function View_MenusPoolComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "app-form-frame", [["no-padding", ""]], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FormFrameComponent_0, i8.RenderType_FormFrameComponent)), i1.ɵdid(1, 49152, null, 0, i9.FormFrameComponent, [], { showCancel: [0, "showCancel"] }, { closeNotify: "closeNotify" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 1, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Manage your Polls in this Message or "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(11, 0, null, 12, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "pool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "pool-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MenusPoolComponent_1)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MenusPoolComponent_3)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MenusPoolComponent_4)), i1.ɵdid(21, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(23, 0, null, 15, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["class", "add-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(27, null, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform(_co.menusService.botMenus$)); _ck(_v, 15, 0, currVal_3); var tmp_4_0 = null; var currVal_4 = ((((tmp_4_0 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform(_co.menusService.botMenus$))) == null) ? null : tmp_4_0.length) === 0); _ck(_v, 18, 0, currVal_4); var tmp_5_0 = null; var currVal_5 = ((((tmp_5_0 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform(_co.menusService.botMenus$))) == null) ? null : tmp_5_0.length) > 0); _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ADDPOLL")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("CREATEANEWPOLL")); _ck(_v, 8, 0, currVal_2); var tmp_6_0 = null; var currVal_6 = ((((tmp_6_0 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform(_co.menusService.botMenus$))) == null) ? null : tmp_6_0.length) === 0); _ck(_v, 25, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("ADD")); _ck(_v, 27, 0, currVal_7); }); }
export function View_MenusPoolComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menus-pool", [], null, null, null, View_MenusPoolComponent_0, RenderType_MenusPoolComponent)), i1.ɵdid(1, 49152, null, 0, i11.MenusPoolComponent, [i12.DashboardService, i13.MenusService], null, null)], null, null); }
var MenusPoolComponentNgFactory = i1.ɵccf("app-menus-pool", i11.MenusPoolComponent, View_MenusPoolComponent_Host_0, {}, { cancelNotfiy: "cancelNotfiy", selectMenuNotfiy: "selectMenuNotfiy" }, []);
export { MenusPoolComponentNgFactory as MenusPoolComponentNgFactory };
