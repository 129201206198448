import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors
} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {
  constructor() { }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.markAllAsTouched();
      }
    });
  }

  getError(fieldName: string, fieldErrors: ValidationErrors) {
    if (fieldErrors) {
      if (fieldErrors.groupName) {
        return `${fieldName} must be at least ${fieldErrors.minLength} character and max is ${fieldErrors.maxLength} characters`;
      } else if (fieldErrors.groupDesc) {
        return `${fieldName} must be not greater than ${fieldErrors.maxLength} character`;
      } else if (fieldErrors.required) {
        return `${fieldName} is required`;
      } else if (fieldErrors.whitespace) {
        return `${fieldName} must be include at least one character`;
      } else if (fieldErrors.pattern) {
        return `${fieldName} has invalid pattern`;
      } else if (fieldErrors.resetCodePatteren) {
        return `Use the 4 digit code sent to your email`;
      } else if (fieldErrors.checkChecked) {
        return `${fieldName} must be Checked`;
      } else if (fieldErrors.minlength) {
        return `${fieldName} must be at least ${fieldErrors.minlength.requiredLength} characters`;
      } else if (fieldErrors.maxlength) {
        return `${fieldName} must be less than or equal  ${fieldErrors.maxlength.requiredLength} characters`;
      } else if (fieldErrors.mustMatch) {
        return `Passwords are not the same`;
      } else if (fieldErrors.wrongCredentials) {
        return `Wrong email or password`;
      } else if (fieldErrors.email) {
        return `Invalid email`;
      } else if (fieldErrors.noFreeMail) {
        return `Must be a valid business email`;
      } else if (fieldErrors.wrongFormat) {
        return `Wrong formated email`;
      } else if (fieldErrors.mailUsed) {
        return `This email is used`;
      } else if (fieldErrors.generalError) {
        return `Something went wrong`;
      } else if (fieldErrors.passwordNotMatch) {
        return `Passwords are not the same`;
      } else if (fieldErrors.wrongTac) {
        return `Use the 4 digit code sent to your email`;
      } else if (fieldErrors.expired) {
        return `${fieldName} is expired`;
      } else if (fieldErrors.passwordInHistory) {
        return `This password was used before in your password history`;
      } else if (fieldErrors.emailNotExist) {
        return `This email is not registered, please Sign-up first`;
      } else if (fieldErrors.patternIsNotMarch) {
        return `Password must be at least 8 and not more than 20 characters long, alphanumeric, at least 1 Capital letter, and 1 special character ($, #, @, !,%,^,&,*, ( , ) )`;
      } else if (fieldErrors.max) {
        return `${fieldName} must be not greater than ${fieldErrors.max.max}`;
      }
    }
    return '';
  }
}
