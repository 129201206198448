import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';

import { AppMgmtService } from '../../../../appMgmt.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { ChannelAppScreens } from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { AppPublishInfo } from 'src/models/AppPublishInfo';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-header-buttons',
  templateUrl: './header-buttons.component.html',
  styleUrls: ['./header-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderButtonsComponent implements OnInit {
  @Input() hideBackButton: boolean;
  @Input() hideNextButton: boolean;
  @Input() currentPage: number;
  @Input() showPublishButton: boolean;
  @Input() showNewAppButton: boolean;
  @Input() hideSaveButton: boolean;
  @Input() isChannelConfOnly: boolean;
  @Input() showReleases: boolean;
  @Input() tabContainerList: any[];
  @Input() itemList: any[];
  @Input() publishInfoData: AppPublishInfo;
  @Output() hideReleasesNotify = new EventEmitter<boolean>();
  @Output() saveNotify = new EventEmitter<boolean>();
  @Output() backNotify = new EventEmitter<boolean>();
  @Input() startover = false;
  @Input() mode: number;
  @Input() appName: any;
  @Input() progressWeb: any;
  @Input() saveTitle: any;
  @Input() wait: any;
  @Output() showModalNotify = new EventEmitter<boolean>(false);
  @Output() publishModalNotify = new EventEmitter<boolean>(false);
  systemModal = false;
  publishModal = false;
  appScreens = ChannelAppScreens;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(
    public appManagementService: AppMgmtService,
    public authService: AuthService,
    public subscriptionService: SubscriptionService
  ) {}
  ngOnInit() {}

  getPreviousPage() {
    this.appManagementService.getPreviousPage();
  }
  get nextStyle() {
    if (
      this.currentPage === ChannelAppScreens.UPLOAD_LOGO &&
      this.showPublishButton
    ) {
      return {
        float: 'right',
        'margin-top': '-50px'
      };
    } else if (this.currentPage === ChannelAppScreens.UPLOAD_LOGO) {
      return {
        float: 'right'
        /* margin-top: -20px; */
      };
    }
  }

  getNewApp(event) {
    this.appManagementService.generateApp(
      event.appId,
      event.os,
      event.android_format,
      event.version_name,
      event.version_desc
    );
    this.appManagementService.updatePublishButtonFlag(true);
  }

  showSystemModal(event: boolean) {
    this.showModalNotify.emit(event);
  }

  showPublishModal(event: boolean) {
    this.publishModalNotify.emit(event);
    // this.publishModal = event;
  }

  hideReleases() {
    this.hideReleasesNotify.emit(true);
  }
  saveApp() {
    this.saveNotify.emit(true);
  }
  back() {
    this.backNotify.emit(true);
  }
}
