import { Injectable } from '@angular/core';
import { withLatestFrom, map, distinctUntilChanged } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { Location } from '@angular/common';
import { IPricePlan } from 'src/models/IPricePlan';
import { PaymentDispatchers } from '../store/payments/payment.dispatchers';
import { RootStoreSelectors } from '../store/root-store.selectors';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(
    private _paymentDispatchers: PaymentDispatchers,
    private _selector: RootStoreSelectors,
    private location: Location
  ) {
    // _paymentDispatchers.getPaymentInfo();
  }

  get paymentStore$() {
    return this._selector.getPaymentStore$();
  }

  get paymentStatus$() {
    return this.paymentStore$.pipe(
      withLatestFrom(this._selector.getSelectedChat$()),
      map(([paymentStore, selectedChat]) => {
        return {
          currentPlan: paymentStore.payment_plan,
          currentPlanStatus: paymentStore.payment_status,
          currentPaymentFailure: paymentStore.payment_failure_message,
          isSub:
            selectedChat && selectedChat.subChannel
              ? selectedChat.subChannel
              : false
        };
      }),
      distinctUntilChanged(isEqual)
    );
  }

  setCanceledPayment(plan: IPricePlan) {
    this._paymentDispatchers.setCanceledPayment(plan);
  }

  setCanceledFreePayment(plan: IPricePlan) {
    this._paymentDispatchers.setCanceledFreePayment(plan);
  }

  resetPaymentPlan() {
    this._paymentDispatchers.resetPaymentPlan();
  }

  setSelectedPayment(plan: IPricePlan) {
    this._paymentDispatchers.setSelectedPayment(plan);
  }

  editSelectedPayment(plan: IPricePlan) {
    this._paymentDispatchers.editSelectedPayment(plan);
  }
  selectFreePayment(plan: IPricePlan) {
    this._paymentDispatchers.setSelectedFreePayment(plan);
  }

  getPaymentToken() {
    this._paymentDispatchers.getPaymentToken();
  }
}
