<!-- <app-popup-setting-frame
  [title]="'Add Media'"
  [hideFooter]="true"
  (closeNotifier)="cancelNotifyClick()"
>
  <div class="media-body">
    <app-media-center-uploader-input
      (filesSelectedNotify)="fileSelectedEvent($event)"
      [mimeTypes]="mimeTypes"
    >
    </app-media-center-uploader-input>

    <app-media-center-tabs
      class="media-types"
      [isPhotoMedia]="isPhotoMedia"
      [selectedMediaTab]="mediaCenterType?.type"
      (mediaCenterTypeNotify)="mediaCenterTypeEvent($event)"
    >
    </app-media-center-tabs>
    <app-media-center-uploader-list
      [selectedMediaType]="mediaCenterType?.type"
      [aspectRatio]="aspectRatio"
      (mediaCenterNotify)="mediaCenterEvent($event)"
    >
    </app-media-center-uploader-list>
  </div>
</app-popup-setting-frame> -->

<app-form-frame
  no-padding
  [showCancel]="true"
  (closeNotify)="cancelNotifyClick()"
>
  <ng-container ngProjectAs="title">
    {{ 'MEDIACENTER' | translate }}
  </ng-container>
  <ng-container ngProjectAs="desc">
    {{ 'MEDIACENTERDESC' | translate }}
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container *ngIf="!toggleCropermoduel">
          <app-media-center-uploader-input
            (filesSelectedNotify)="fileSelectedEvent($event)"
            [mimeTypes]="mimeTypes"
            [enableFileMetaData]="enableFileMetaData"
          >
          </app-media-center-uploader-input>
          <app-media-center-uploader-list
            [selectedMediaType]="mediaCenterType?.type"
            [aspectRatio]="aspectRatio"
            (mediaCenterNotify)="mediaCenterEvent($event)"
          >
          </app-media-center-uploader-list>
        </ng-container>
        <ng-container *ngIf="toggleCropermoduel">
          <div class="crop">
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [imageFileChanged]="selectedFile"
              [resizeToHeight]="resizeToHeight"
              [resizeToWidth]="resizeToWidth"
              [imageQuality]="80"
              [maintainAspectRatio]="true"
              [aspectRatio]="aspectRatioValue || 1 / 1"
              [onlyScaleDown]="true"
              [roundCropper]="false"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer" *ngIf="toggleCropermoduel">
      <button class="add-btn" (click)="onFilesSelected(imageFile)">
        {{ 'CONFIRM' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>

<!-- <app-modal-container
  *ngIf="toggleCropermoduel"
  [count]="layoutService?.appModelOpenStatus$ | async"
>
  <div class="image-crop">
    <div class="close" (click)="closePreview()">
      <div class="form-title">Crop Image</div>
      <img src="./assets/img/close.svg" alt="close" />
    </div>
    <div class="crop">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageFileChanged]="selectedFile"
        [resizeToHeight]="resizeToHeight"
        [imageQuality]="80"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatioValue || 1 / 1"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <div class="preview">
      <img [src]="croppedImage" />
      <div class="upload-button">
        <label class="icon" (click)="onFilesSelected(imageFile)">
          <img src="./assets/img/image-upload-card.svg" alt="gif" /> Upload
          Image
        </label>
      </div>
    </div>
  </div>
</app-modal-container> -->
