import {
  ADD_ADMIN_TO_CHAT_METHOD,
  ADD_MEMBER_TO_CHAT_METHOD,
  REMOVE_ADMIN_FROM_CHAT_METHOD,
  REMOVE_MEMBER_FROM_CHAT_METHOD,
  GET_CHAT_MEMBER_METHOD,
  GET_MY_CHAT_MEMBERS_METHOD,
  GET_MY_MEMBER_CHATS_METHOD,
  PROMOTE_CHAT_MEMBER_METHOD,
  SET_CHAT_MEMBER_ROLE_METHOD,
  GET_ROLES_METHOD,
  SET_USER_VIEW_METHOD,
  MERCHANT_PAGE_SIZE,
  PAY_CASH_METHOD,
  GET_MY_MEMBER_METHOD,
  BAN_CHAT_MEMBER_METHOD,
  UNBAN_CHAT_MEMBER_METHOD,
  GET_MY_CHAT_ADMIS_METHOD,
  GET_CHAT_MEMBERS_METHOD,
  GET_CHAT_ADMINISTRATORS_METHOD,
  GET_CHAT_USER_GROUP_METHOD,
  GET_LIST_USERS_METHOD
} from './constants';
import { Chat, User } from './MessageParts';
import { AbstractTableColumn } from './AbstractItem';

export interface IChatMember {
  // this was number but must be string for js
  member_since?: number;
  privileges?: string[];
  role?: number;
  /*
    â€œactiveâ€�: where user is an active member in the Chat
    â€œdeletedâ€� : where user has been deleted from Chat.
    â€œbannedâ€�: when user has been banned from Chat.
    â€œleftâ€� : when user left the Chat
  */
  status?: string;
  tags?: string[];
  /*
    â€œmemberâ€� if user is a member, â€œadminâ€� if user is an admin.
  */
  type?: string;
  view?: number;
  tester?: number;
  user_id?: string;
  chat_id?: string;

  // will get it from profile
  name?: string;
  version?: string; // if version changed then it means this profile img need to be updated
  relation?: string; // The relation, other/family/friend/work where other=0
  image?: string;
  imageThumbnail?: string; // The image we get from downloading the thumbnail
  mute?: boolean;
  contact?: boolean; // flag indicates that this profile is a contact
  priority?: boolean;
  pinned?: boolean;
  pinned_date?: number;
  backgroundColor?: string;
  last_seen?: number; // Date Timestamp
  thumbnail_id?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
  photo_id?: string;
  photo_width?: number;
  photo_height?: number;
  unknownContact?: boolean;
  lastTypedMessage?: string;
  online?: boolean;
  detailsReceived?: boolean;
  isBot?: boolean;

  // local used
  myChatsRequested?: boolean;
  isMemberAdminOnSub?: boolean;

  isAdmin?: boolean;
  role_name?: string;
  // new fields added with bot
  owner?: number; // number 0 or 1
  filter?: number;
  isPublished?: number; // number 0 or 1
  about?: string;
  botType?: number;
  inline?: number;
  domain?: string;
  isPublic?: number; // number 0 or 1
  shortName?: string;
  email?: string;
  disallowGroup?: number; // number 0 or 1
  //
  isMyBot?: boolean;
  isTemplateBot?: boolean;
  msisdn?: string;
}

export interface ChatMember {
  user?: User;
  chat?: Chat;
  role?: number;
  status?: string;
  tags?: string[];
  type?: string;
  view?: number;
  tester?: number;
  version?: string; // if version changed then it means this profile img need to be updated
  privileges?: string[];
  member_since?: number;
  msisdn?: string;
}

export const usersTableColumns: AbstractTableColumn[] = [
  {
    colType: 'avatar',
    title: '',
    class: 'avatar',
    avatar: {
      type: 'Individual', // ex: Individual
      size: 40
    }
  },
  {
    colType: 'name',
    title: 'Name',
    class: 'sm'
  },
  {
    colType: 'msidin',
    title: 'Login ID',
    class: 'lg'
  },
  {
    colType: 'date',
    title: 'Last Modified Date',
    class: 'lg',
    dateFormat: 'MMM dd,yyyy'
  },
  {
    colType: 'role',
    title: 'Role',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'action'
  }
];

// "search_by": 0, // 0 --> name, 1 --> msisdn
export class GetMyMembers {
  readonly method = GET_MY_CHAT_MEMBERS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public status: string,
    public page_number: number,
    public hash?: string,
    public keyword?: string,
    public search_by?: number,
    public view?: number,
    public account_types?: string[]
  ) {
    // this.page_size = MERCHANT_PAGE_SIZE;
    // this.page_size = 4;
  }
}

// to get the detail of a bunch of members at the same time
export class GetMyMember {
  readonly method = GET_MY_MEMBER_METHOD;
  constructor(public user: string[], public ref?: string) {}
}
//  no need for the following API now, as getMyMembers will return both admins and members
// export class GetMyAdmins {
//   readonly method = GET_MY_CHAT_ADMIS_METHOD;
//   constructor(public status: string, public tester?: number) {}
// }
// export class GetChatMembers {
//   readonly method = GET_CHAT_MEMBERS_METHOD;
//   constructor(public chat_id: string) {}
// }

export class GetChatMember {
  readonly method = GET_CHAT_MEMBER_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}
export class GetMyMemberChats {
  readonly method = GET_MY_MEMBER_CHATS_METHOD;
  constructor(public user_id: string, public status: string) {}
}

export class PromoteChatMember {
  readonly method = PROMOTE_CHAT_MEMBER_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}

export class AddMemberToChat {
  readonly method = ADD_MEMBER_TO_CHAT_METHOD;
  constructor(
    public chat_id: string,
    public user_id: string,
    public recordIndex?: number
  ) {}
}
export class AddAdminToChat {
  readonly method = ADD_ADMIN_TO_CHAT_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}
export class RemoveAdminFromChat {
  readonly method = REMOVE_ADMIN_FROM_CHAT_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}
export class RemoveMemberFromChat {
  readonly method = REMOVE_MEMBER_FROM_CHAT_METHOD;
  constructor(
    public chat_id: string,
    public user_id: string,
    public reference?: string
  ) {}
}

export class BanMemberFromChat {
  readonly method = BAN_CHAT_MEMBER_METHOD;
  constructor(public chat_id: string, public user_id: string) {}
}
export class UnBanMemberFromChat {
  readonly method = UNBAN_CHAT_MEMBER_METHOD;
  readonly reference = 'UNBAN_REF';
  constructor(public chat_id: string, public user_id: string) {}
}

export class GetRoles {
  readonly method = GET_ROLES_METHOD;
}

export class SetChatMemberRole {
  readonly method = SET_CHAT_MEMBER_ROLE_METHOD;
  constructor(
    public chat_id: string,
    public user_id: string,
    public role: string
  ) {}
}

export class SetChatMemberView {
  readonly method = SET_USER_VIEW_METHOD;
  constructor(public user_id: string, public view: number) {}
}

export class SetMemberTesterFlag {
  readonly method = SET_USER_VIEW_METHOD;
  constructor(public user_id: string, public tester: number) {}
}

export class PayCash {
  readonly method = PAY_CASH_METHOD;
  constructor(
    public user_id: string,
    public bundle_id: string,
    public amount: string,
    public unites: number,
    public currency: string,
    public reference: string,
    public product_id?: string
  ) {}
}
export class GetChatAdministrators {
  readonly method = GET_CHAT_ADMINISTRATORS_METHOD;
  constructor(public chat_id: string) {}
}

export class GetUserGroupRole {
  readonly method = GET_CHAT_USER_GROUP_METHOD;
  constructor(public group_id: string, public user_id: string) {}
}

export class GetListUsers {
  readonly method = GET_LIST_USERS_METHOD;
  constructor(public group_id: string, public access?: number) {}
}

export type ChatMemberMethods =
  | GetMyMembers
  | GetChatMember
  | GetMyMemberChats
  | AddMemberToChat
  | AddAdminToChat
  | RemoveAdminFromChat
  | RemoveMemberFromChat
  | BanMemberFromChat
  | UnBanMemberFromChat
  | GetRoles
  | SetChatMemberRole
  | SetChatMemberView
  | PayCash;
