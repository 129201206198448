<div class="set-container" [ngStyle]="uploadedWorkFlowImage">
  <div class="empty-menu" *ngIf="!menuForm || menuForm?.rows?.length <= 0">
    <img src="./assets/img/empty-menu.svg" />
    <span>Empty Menu!</span>
  </div>
  <app-menu-holder
    *ngIf="menuForm && menuForm?.rows?.length >= 1"
    [mainMenu]="mainMenu"
    [menus]="menuForm"
    [systemConfg]="appManagementService?.systemConfig$ | async"
    [mobView]="true"
    [ios]="(appManagementService?.appInfo$ | async)?.mob_ver"
  >
  </app-menu-holder>
</div>
