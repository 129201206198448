<!-- header with title -->
<div *ngIf="!hideHeader && !isBot" class="header">
  <i
    class="fas fa-times close clickable-cursor"
    (click)="cancelMediaPreview()"
    aria-hidden="true"
  ></i>
</div>
<div *ngIf="isBot" class="bot-header">
  Preview
  <svg class="svg-icon icon-icon close" (click)="cancelMediaPreview()">
    <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
  </svg>
</div>

<!-- preview section -->
<div class="preview">
  <!-- disply if no media -->
  <span *ngIf="!mediaMetadata?.mediaUrl && !mediaError" class="spiner-loader">
    <div class="preview-spinner" [ngStyle]="theme"></div>
  </span>

  <!-- if media not suportted -->
  <span *ngIf="!mediaMetadata?.mediaUrl && mediaError">
    The file you are trying to upload is not a supported file type
  </span>

  <!-- if media supported and exist -->
  <div class="media-container" style="overflow: overlay;">
    <div class="media-show" [style.width.em]="width / 12">
      <div class="media-attach" [ngStyle]="getContainerAttachStyle">
        <div class="caption-holder" *ngIf="selectedChat.type !== 'Group'">
          {{ messageType !== mTypes.DOCUMENT ? getCaptionToSend() : '' }}
        </div>
        <div class="menu-show" [ngStyle]="getImageContainerStyle">
          <ng-content select="[message-sticky-post]"></ng-content>
          <!-- image tag -->
          <app-image-cell
            *ngIf="
              messageType === mTypes.PHOTO ||
              (messageType === mTypes.GIF && files?.name?.endsWith('.gif'))
            "
            class="no-copyable-item"
            [ngStyle]="getImageStyle"
            [mediaStatus]="messageMediaStatus.DOWNLOADED"
            [localThumbnail]="mediaMetadata?.mediaUrl"
            [localMedia]="mediaMetadata?.mediaUrl"
            [themeColor]="themeColorMedia"
            [height]="mediaMetadata?.height"
            [width]="mediaMetadata?.width"
            [messageStyle]="messageStyle"
          >
          </app-image-cell>

          <!-- video tag -->
          <video
            *ngIf="
              messageType === mTypes.VIDEO ||
              (messageType === mTypes.GIF && files?.name?.endsWith('.mp4'))
            "
            controls
            #videoTag
            class="edit"
          >
            <source [src]="media" type="video/mp4" />
          </video>

          <!-- audio tag -->
          <app-audio-cell
            *ngIf="messageType === mTypes.AUDIO"
            class="media audio"
            [mediaStatus]="messageMediaStatus.DOWNLOADED"
            [localMedia]="mediaMetadata?.mediaUrl"
            [fileSize]="mediaMetadata?.fileSize | fileSize"
            [type]="'audio'"
            [title]="mediaMetadata?.fileName"
            [mainThemeColor]="themeColorFile"
            [mediaDuration]="mediaMetadata?.duration"
            [secondThemeColor]="'#d5dee2'"
          >
          </app-audio-cell>

          <!-- document tag -->
          <div
            *ngIf="messageType === mTypes.DOCUMENT"
            class="document wrap-text edit"
          >
            <div class="icon">
              <i class="fas fa-file"></i>
            </div>
            <div class="document-details">
              <div class="file-name">{{ mediaMetadata?.fileName }}</div>
              <div class="file-size">
                {{ mediaMetadata?.fileSize | fileSize }}
              </div>
            </div>
          </div>

          <!-- Message Targets ( Tags and Tab ) -->
          <ng-content
            *ngIf="
              messageType === mTypes.PHOTO ||
              messageType === mTypes.VIDEO ||
              messageType === mTypes.GIF
            "
            select="[message-style]"
          ></ng-content>
          <!------------------------------------------------->
        </div>
      </div>
      <ng-content select="[message-attachs]"></ng-content>
    </div>
  </div>
</div>

<!-- chat Box -->
<div style="display: flex; align-items: center; width: 100%;">
  <div style="flex: 1;">
    <div
      [hidden]="messageType === mTypes.DOCUMENT"
      class="chat-box"
      [class.white]="chatStyle"
    >
      <div class="box" *ngIf="showUsers" style="background: #fff;">
        <div style="display: flex;">
          <img
            alt="close"
            src="./assets/img/screens-close.svg"
            (click)="toggleUsers(false)"
            style="margin-left: auto;"
          />
        </div>
        <ul>
          <ng-container
            *ngFor="let item of _uiService?.getChatUsersList$ | async"
          >
            <li
              *ngIf="_uiService?.getProfileByID$(item) | async as user"
              (click)="addMinction(user)"
            >
              <app-profile-image
                class="projection-avatar"
                [id]="user?.user_id"
                [chatType]="'Individual'"
                [emSize]="2.8"
                style="margin-right: 1em;"
              >
              </app-profile-image>
              {{ user?.name }}
            </li>
          </ng-container>
        </ul>
      </div>
      <!-- Emoji Box -->
      <div
        class="btn-group clickable-cursor"
        dropdown
        [autoClose]="false"
        [dropup]="true"
      >
        <div dropdownToggle type="button">
          <!-- <i
            class="emoji far fa-smile"
            [class.open]="isEmojiKeyboardOpen"
            aria-hidden="true"
          ></i> -->
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-sections.svg#icon-emoji-copy"
            ></use>
          </svg>
        </div>
        <ul
          *dropdownMenu
          appCancelEvent
          class="dropdown-menu emoji-box"
          role="menu"
        >
          <app-emoji-keyboard
            (emoijNotify)="emoijNotify($event)"
          ></app-emoji-keyboard>
        </ul>
      </div>

      <!-- Caption Box -->
      <div
        class="caption wrap-text copyable-item text-cursor"
        appAutoFocus
        data-text="Add a caption…"
        contenteditable
        dir="auto"
        #captionBox
        [selectedChat]="selectedChat?.id"
        (paste)="onRichPaste($event)"
        (mouseup)="lastfocus($event)"
        (keyup)="lastfocus($event)"
      ></div>
    </div>
  </div>
  <ng-content select="[footer-submit]"></ng-content>
</div>
<!-- Message Attachs -->
<ng-content select="[message-attachs]"></ng-content>
<!------------------------------------------------->
<!-- Message Targets ( Tags and Tab ) -->
<ng-content select="[message-target]"></ng-content>
<!------------------------------------------------->

<!-- buttons -->
<div class="footer" *ngIf="!hideFooter">
  <div class="form-submit">
    <ng-content select="[footer-tag]"></ng-content>
    <!-- <ng-content select="[footer-submit]"></ng-content> -->
    <ng-content select="[footer-cancel]"></ng-content>
  </div>
</div>
