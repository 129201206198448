import { Store } from '@ngrx/store';
import * as SmtpActionTypes from './smtp.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class StmpDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getStmp() {
        this._store.dispatch(new SmtpActionTypes.GetStmp());
    }
    receiveStmp(smtp) {
        this._store.dispatch(new SmtpActionTypes.ReceiveStmp(smtp));
    }
    setStmp(smtp) {
        this._store.dispatch(new SmtpActionTypes.SetStmp(smtp));
    }
}
StmpDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StmpDispatchers_Factory() { return new StmpDispatchers(i0.ɵɵinject(i1.Store)); }, token: StmpDispatchers, providedIn: "root" });
