<!-- <button class="btn btn-danger" id="loginRef" #loginRef>
  Login with Google
</button> -->
<!-- <ng-container *ngIf="email === 'qr'">
  <div class="login-qr">
    <h1 class="login-qr-header">
      Login
    </h1>

    <div class="login-qr-title">
      Login as&nbsp;
      <app-link-btn (btnClicked)="goEmail()">
        <ng-container ngProjectAs="link-content-clicked">
          App Owner
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-container">
      <qr-code
        class="login-qr-qr"
        *ngIf="
          (authService.authCollection$ | async)?.qrCode &&
          (appService.online$ | async)
        "
        [size]="200"
        [value]="(authService.authCollection$ | async)?.qrCode"
      >
      </qr-code>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus !==
            authStatus.INIT_SOKET &&
          (authService.authCollection$ | async)?.qrExpire
        "
        class="login-qr-mask"
      >
        <div
          *ngIf="!(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
          (click)="qrCodeReload()"
        >
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
        <div
          *ngIf="(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
          (click)="qrCodeReload()"
        >
          <i class="fas fa-sync fan-btn"></i>
        </div>
      </div>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus ===
          authStatus.INIT_SOKET
        "
        class="login-qr-mask"
        (click)="qrCodeReload()"
      >
        <div class="circle-btn">
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
      </div>
    </div>

    <div class="login-qr-systems form-inline space-inbetween">
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/andriod-icon.svg" />
        <div class="system-spec-core">
          <div class="system-spec-title">Android</div>
          <div class="system-spec-desc">Menu > QR Scan</div>
        </div>
      </div>
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/ios-icon.svg" />
        <div class="system-spec-core">
          <div class="system-spec-title">IOS</div>
          <div class="system-spec-desc">QR Scan Tab</div>
        </div>
      </div>
    </div>

    <div class="login-qr-manage">
      New to nandbox?&nbsp;
      <app-link-btn [openSelf]="true" [href]="signupLink">
        <ng-container ngProjectAs="link-content">
          SignUp
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-rem form-inline space-between">
       <app-reactive-checkbox-input
        [filedId]="'inputRem'"
        [control]="remControl"
      >
        Remember me
      </app-reactive-checkbox-input>
      <app-link-btn (btnClicked)="showNeedHelp(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Need help to get started?
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-terms">
      By logging in, you agree to our
      <app-link-btn [href]="termsLink">
        <ng-container ngProjectAs="link-content">
          Terms of Use & Privacy Policy.
        </ng-container>
      </app-link-btn>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="email === 'email'">
  <app-login (qrScan)="loginQr()"></app-login>
</ng-container> -->

<div class="login-qr" *ngIf="email === 'qr'">
  <div class="login-qr-header">
    <div class="title">App Admin Sign In</div>
    <div class="desc">Open your app, and scan the QR code to sign in</div>
  </div>
  <div class="login-qr-body">
    <div class="login-qr-container">
      <qr-code
        class="login-qr-qr"
        *ngIf="(authService.authCollection$ | async)?.qrCode"
        [size]="qrSize"
        [value]="(authService.authCollection$ | async)?.qrCode"
      >
      </qr-code>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus !==
            authStatus.INIT_SOKET &&
          (authService.authCollection$ | async)?.qrExpire
        "
        class="login-qr-mask"
      >
        <div
          *ngIf="!(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
          (click)="qrCodeReload()"
        >
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
        <div
          *ngIf="(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
          (click)="qrCodeReload()"
        >
          <i class="fas fa-sync fan-btn"></i>
        </div>
      </div>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus ===
          authStatus.INIT_SOKET
        "
        class="login-qr-mask"
        (click)="qrCodeReload()"
      >
        <div class="circle-btn">
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
      </div>
    </div>
    <div class="login-qr-systems form-inline space-inbetween">
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/andriod-icon.svg" />
      </div>
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/ios-icon.svg" />
      </div>
    </div>
    <div class="text">
      Scan the QR code using your App
    </div>
  </div>
  <div>
    <label class="need-help">
      <span (click)="showNeedHelp()">Need help to get started?</span>
      <img
        src="./assets/img/help-info.svg"
        alt="help"
        style="width: 25px; height: 25px; cursor: pointer;"
        mat-raised-button
        matTooltip="Help"
        matTooltipPosition="above"
        matTooltipClass="multiline-tooltip"
        (click)="showNeedHelp()"
      />
    </label>
    <iframe
      width="100%"
      height="250"
      src="https://www.youtube.com/embed/jktQw3q1zQs"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>

  <div class="action-header">
    <div class="text">Are you an App Owner?</div>
    <div class="signin-btn" (click)="goEmail()">
      <div class="icon">
        <img class="svg-icon icon-icon" src="./assets/img/go-to-login.svg" />
      </div>
      Sign in as an app owner
    </div>
  </div>
</div>
<ng-container *ngIf="email === 'email'">
  <app-login (qrScan)="loginQr()"></app-login>
</ng-container>

<footer class="footer">
  <div class="footer-term">
    <div
      cla
      style="padding-right: 15px;"
      class="link"
      (click)="openLink('https://nandbox.com')"
    >
      © 2023 nandbox ® Inc.
    </div>
    <div class="service link" (click)="openLink('https://nandbox.com/terms/')">
      Terms of Service
    </div>
    <div
      style="padding-left: 15px;"
      class="link"
      (click)="openLink('https://nandbox.com/privacy-policy/')"
    >
      Privacy Policy
    </div>
  </div>
  <div class="footer-policy">
    <div style="display: flex; align-items: center; margin-bottom: 10px;">
      <div
        class="service link"
        (click)="openLink('https://mailchimp.com/legal/terms/')"
      >
        Terms of Service
      </div>
      <div
        style="padding-left: 15px;"
        class="link"
        (click)="openLink('https://nandbox.com/privacy-policy/')"
      >
        Privacy Policy
      </div>
    </div>
    <div class="link" (click)="openLink('https://nandbox.com')">
      © 2023 nandbox ® Inc.
    </div>
  </div>
  <div class="version">nandbox App Builder - Version 1.6.145.</div>
</footer>
<!-- <app-lightbox-container
  *ngIf="helpVideoVisibility"
  [titleHeader]="'Need help to get started?'"
  (cancelNotfiy)="showNeedHelp(false)"
>
  <iframe
    style="height: 380px;"
    width="100%"
    height="380"
    src="https://www.youtube.com/embed/bnAe54Tod18"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</app-lightbox-container> -->
