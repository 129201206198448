import * as i0 from "@angular/core";
export class NavigateService {
    constructor() { }
    preventBackButton() {
        // history.pushState(null, null, document.URL);
        // window.addEventListener('popstate', () =>
        //   history.pushState(null, null, document.URL)
        // );
    }
    goBack() {
        //   go back
        window.addEventListener('popstate', () => window.history.pushState(history.back(), null, document.URL));
    }
}
NavigateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigateService_Factory() { return new NavigateService(); }, token: NavigateService, providedIn: "root" });
