import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MobileWorkFlowStoreDispatchers } from './mobileWorkflowStore.dispatchers';
import * as isEqual from 'lodash.isequal';
import { FAILED_TO_UPLOAD_IMAGE, LOGO_WIDTH, LOGO_HEIGHT, ERROR_IMAGE_SHOULD_BE_1024X1024, SPLASH_PAGE_UPLOAD_TYPE, NO_FILE_NAME, ERROR_NOT_PNG_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes } from 'src/models/constants';
import { HttpEventType } from '@angular/common/http';
import { DeleteMStore, GetDefaultMStore, GetMStoreList, SetMStore, SetMStoreWithImage } from 'src/models/ChannelAppConfig';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
export class MobileWorkflowStoreEffects {
    constructor(actions$, _store, _mobileWorkFlowStoreDispatchers, _uiDispatchers, _fileService, _uploadGateway, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._mobileWorkFlowStoreDispatchers = _mobileWorkFlowStoreDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._socketGateway = _socketGateway;
        this.listMobileWorkflowStore = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORE_LIST" /* MOBILE_WORKFLOW_STORE_LIST */), map(() => {
            this._socketGateway.sendSocketMessage(new GetMStoreList());
        }));
        this.uploadMenuImg = this.actions$.pipe(ofType("UPLOAD_MOBILE_WORKFLOW_STORE_IMG" /* UPLOAD_MOBILE_WORKFLOW_STORE_IMG */), map((action) => {
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width !== props.height ||
                                props.width < LOGO_WIDTH ||
                                props.height < LOGO_HEIGHT) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, SPLASH_PAGE_UPLOAD_TYPE, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const res = event.body;
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageUrl = res.files[4].media.permanentUrl;
                                        }
                                        const imageSet = {};
                                        imageSet.hdpi = res.files[0].media.permanentUrl;
                                        imageSet.mdpi = res.files[1].media.permanentUrl;
                                        imageSet.xhdpi = res.files[2].media.permanentUrl;
                                        imageSet.xxhdpi = res.files[3].media.permanentUrl;
                                        imageSet.xxxhdpi = res.files[4].media.permanentUrl;
                                        imageSet.ios1x = res.files[5].media.permanentUrl;
                                        imageSet.ios2x = res.files[6].media.permanentUrl;
                                        imageSet.ios3x = res.files[7].media.permanentUrl;
                                        this._mobileWorkFlowStoreDispatchers.UploadMenuImgSuccess(action.componentRef, imageUrl, imageSet);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                });
                            }
                        });
                    });
                });
            }
        }));
        this.menusReceiving = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORES_RECEIVING" /* MOBILE_WORKFLOW_STORES_RECEIVING */), map((action) => {
            // const pyln = action.payload;
            // if (pyln.mymenus) {
            //   for (const menu of pyln.mymenus) {
            //     if (menu.sub_menu) {
            //       const localMenu = menu.sub_menu;
            //       localMenu.id = menu.id;
            //       this._mobileWorkFlowStoreDispatchers.menuReceived(localMenu);
            //     }
            //   }
            // }
        }));
        this.menuReceving = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORE_RECEIVING" /* MOBILE_WORKFLOW_STORE_RECEIVING */), map((action) => {
            // const localMenu = action.payload.mymenu.menu;
            // localMenu.id = action.payload.mymenu.id;
            // this._mobileWorkFlowStoreDispatchers.menuReceived(localMenu);
        }));
        this.sendingMenu = this.actions$.pipe(ofType("CREATE_MOBILE_WORKFLOW_STORE" /* CREATE_MOBILE_WORKFLOW_STORE */), withLatestFrom(this._store
            .select(state => state.uiReducer.enableSetTemplateBtn)
            .pipe(distinctUntilChanged(isEqual))), map(([val, enableSetTemplateBtn]) => {
            const action = val;
            let setStore;
            if (action.image) {
                setStore = new SetMStoreWithImage(action.menu_id, action.currency, action.shop, [action.image], action.name, action.description, action.id, action.is_default);
            }
            else {
                setStore = new SetMStore(action.menu_id, action.currency, action.shop, action.id, action.is_default);
            }
            if (enableSetTemplateBtn && action.id) {
                const type = 'store';
                const value = {
                    menu_id: action.menu_id,
                    currency: action.currency,
                    shop: action.shop,
                    image: [action.image],
                    name: action.name,
                    description: action.description,
                    is_default: action.is_default
                };
                this._uiDispatchers.setChatToTemplate(action.id, type, value);
            }
            this._socketGateway.sendSocketMessage(setStore);
        }));
        this.deletingMenu = this.actions$.pipe(ofType("DELETE_MOBILE_WORKFLOW_STORE" /* DELETE_MOBILE_WORKFLOW_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new DeleteMStore([action.id + '']));
        }));
        this.getStoreMenu = this.actions$.pipe(ofType("GET_MOBILE_WORKFLOW_STORE" /* GET_MOBILE_WORKFLOW_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetDefaultMStore(action.id));
        }));
        this.getNextMenuPage = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE" /* MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.MobileWorkflowStoreReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val], mobileStore) => {
            const action = val;
            const eop = mobileStore.eop;
            if (!eop) {
                this._socketGateway.sendSocketMessage(new GetMStoreList(eop, 1));
            }
        }));
        this.getPrevMenuPage = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORE_GET_PREV_PAGE" /* MOBILE_WORKFLOW_STORE_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.MobileWorkflowStoreReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val], mobileStore) => {
            const action = val;
            const eop = mobileStore.eop;
            if (!eop) {
                this._socketGateway.sendSocketMessage(new GetMStoreList(eop, 0));
            }
        }));
        this.afterMenuResetPaging = this.actions$.pipe(ofType("MOBILE_WORKFLOW_STORE_RESET_PAGING" /* MOBILE_WORKFLOW_STORE_RESET_PAGING */), map((action) => {
            this._mobileWorkFlowStoreDispatchers.getNextMenuPage();
        }));
    }
    validateImage(selectedFile) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (selectedFile.localFile.type.indexOf('image/png') === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "listMobileWorkflowStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "uploadMenuImg", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "menusReceiving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "menuReceving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "sendingMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "deletingMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "getStoreMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "getNextMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "getPrevMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MobileWorkflowStoreEffects.prototype, "afterMenuResetPaging", void 0);
