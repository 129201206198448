import {
  Input,
  Output,
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';

import { CellsService } from '../cells.service';
import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-image-cell',
  templateUrl: './image-cell.component.html',
  styleUrls: ['./image-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCellComponent implements OnChanges {
  @Input() localMedia: string;
  @Input() localThumbnail: string;
  @Input() mediaStatus: string;
  @Input() fileSize: number;
  @Input() height: number;
  @Input() width: number;
  @Input() caption: string;
  @Input() percent: number;
  @Input() themeColor: string;
  @Input() type: string;
  @Input() isFullScreen = false;
  @Input() messageStyle;
  @Input() isPostCell = false;

  @Output() retryNotify = new EventEmitter<boolean>(false);
  @Output() cancelNotify = new EventEmitter<boolean>(false);
  @Output() mediaClickedNotifier = new EventEmitter<boolean>(false);

  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  constructor(private _cellsService: CellsService) {}

  ngOnChanges() {
    if (this.width && this.height && !this.messageStyle) {
      [this.width, this.height] = this._cellsService.resizeMedia(
        this.width,
        this.height
      );
    }
  }

  get them() {
    return {
      'border-top-color': this.themeColor,
      'border-right -color': this.themeColor
    };
  }

  get media() {
    return this._cellsService.getMedia(this.localMedia);
  }

  get thumbnail() {
    return this._cellsService.getMedia(this.localThumbnail);
  }

  mediaClickedEvent() {
    this.mediaClickedNotifier.emit(true);
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
