import * as ChatMemberActions from './chatMember.actions';
import { ChatMemberMiddleware } from './chatMember.middleware';
const INITIAL_STATE = [];
export function chatMemberReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "CHAT_MEMBERS_RECEIVED" /* CHAT_MEMBERS_RECEIVED */:
            const localChatMembersState = [];
            if (action.payload && action.payload.length > 0) {
                action.payload.forEach(mbr => {
                    const contact = ChatMemberMiddleware.initChatMember(mbr);
                    if (!localChatMembersState.find(prof => prof.user_id === contact.user_id) &&
                        !ChatMemberMiddleware.isChatMemberExist(state, contact)) {
                        localChatMembersState.push(contact);
                    }
                });
            }
            return [...state, ...localChatMembersState];
        case "MY_MEMBER_CHATS_RECEIVED" /* MY_MEMBER_CHATS_RECEIVED */:
            // Add all member and chat
            if (action.payload &&
                action.payload.members &&
                action.payload.members.length) {
                let localState = [...state];
                action.payload.members.forEach(mbr => {
                    const localMember = ChatMemberMiddleware.initChatMember(mbr);
                    if (!ChatMemberMiddleware.isChatMemberExist(state, localMember)) {
                        localState = [...localState, localMember];
                    }
                });
                return localState;
            }
            return state;
        case "CHAT_MEMBER_RECEIVED" /* CHAT_MEMBER_RECEIVED */:
            if (!ChatMemberMiddleware.isChatMemberExist(state, action.payload)) {
                return [...state, action.payload];
            }
            return state.map(item => {
                if (item.user_id === action.payload.user_id &&
                    item.chat_id === action.payload.chat_id) {
                    const updatedMember = Object.assign({}, item, action.payload);
                    return updatedMember;
                }
                return item;
            });
        case "CHAT_MEMBER_UPDATED" /* CHAT_MEMBER_UPDATED */:
            return state.map(member => {
                if (member.user_id === action.payload.user_id) {
                    let updatedProfile = {};
                    updatedProfile = Object.assign({}, member, action.payload);
                    return updatedProfile;
                }
                return member;
            });
        case "REMOVE_OR_BAN_USER_SUCCESS" /* REMOVE_OR_BAN_USER_SUCCESS */:
            return state.filter(member => member.user_id !== action.ID);
        case "REMOVE_CHAT_FROM_MEMBER_SUCCESS" /* REMOVE_CHAT_FROM_MEMBER_SUCCESS */:
            return state.filter(member => member.chat_id !== action.chatId);
        case ChatMemberActions.DESELECT_CHANNEL:
        case ChatMemberActions.RESET:
            return [];
        default:
            return state;
    }
}
const reducer = (accumulator, currentValue) => accumulator || currentValue;
const ɵ0 = reducer;
export { ɵ0 };
