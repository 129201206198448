<div
  (click)="mediaClickedEvent()"
  class="media-container"
  [class.full-screen]="isFullScreen"
  [style.width.px]="width"
  [style.height.px]="height"
  [class.caption]="caption"
>
  <!-- loader for downloading/uploading or failed -->
  <div class="loader-container" appCancelEvent>
    <div
      *ngIf="
        mediaStatus === messageMediaStatus.UPLOADING ||
        mediaStatus === messageMediaStatus.DOWNLOADING
      "
      class="loader-t"
    >
      <svg-icon
        class="cancel"
        src="./assets/img/close-white.svg"
        [stretch]="true"
        [svgStyle]="{ 'width.px': 14, fill: '#C0C0C0' }"
        (click)="cancelEvent()"
      >
      </svg-icon>
      <div *ngIf="!percent" class="loader" [ngStyle]="theme"></div>
      <circle-progress
        *ngIf="percent"
        [percent]="percent"
        [outerStrokeColor]="themeColor"
      ></circle-progress>
    </div>
    <div
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOAD_FAILED ||
        mediaStatus === messageMediaStatus.UPLOAD_FAILED
      "
      class="reprocess"
      (click)="retryEvent()"
    >
      <i
        class="fas"
        [class.fa-long-arrow-alt-up]="
          mediaStatus === messageMediaStatus.UPLOAD_FAILED
        "
        [class.fa-long-arrow-alt-down]="
          mediaStatus === messageMediaStatus.DOWNLOAD_FAILED
        "
        aria-hidden="true"
      >
      </i>
      {{ fileSize | fileSize }}
    </div>
  </div>

  <!-- Start: NotFullScreen -->
  <!-- Display thumbnail if exist -->
  <img
    *ngIf="thumbnail && !isFullScreen"
    [src]="thumbnail"
    class="media-center cropped img-blur"
  />

  <!-- Display video wrapper if media exist -->
  <div *ngIf="!isFullScreen" class="video-wrapper media-center fit">
    <!-- cover over video shows play/puse and media information -->
    <div *ngIf="isPause && !isFullScreen" class="cover">
      <div class="details duration">
        <img src="./assets/img/play-btn.svg" alt="" />
        {{ mediaDuration | mediaDuration }}
      </div>

      <img
        *ngIf="
          mediaStatus === messageMediaStatus.UPLOADED ||
          mediaStatus === messageMediaStatus.DOWNLOADED
        "
        src="./assets/img/play-arrow.svg"
        class="play clickable-cursor"
        (click)="playVideo()"
      />

      <div class="details size">
        {{ fileSize | fileSize }}
        <img src="./assets/img/ic-sd-storage-black-18-dp.svg" alt="" />
      </div>
    </div>

    <img
      *ngIf="thumbnail"
      class="media-center fit img-blur"
      [src]="thumbnail"
      alt=""
    />
  </div>
  <!-- End: NotFullScrren -->

  <!-- Start: FullScreen -->
  <!-- Display thumbnail if exist and no local media -->
  <img
    *ngIf="thumbnail && !localMedia && isFullScreen"
    [src]="thumbnail"
    alt=""
    class="media img-blur"
  />
  <!-- video tag -->
  <video
    *ngIf="localMedia && isFullScreen"
    appCancelEvent
    autoplay
    (play)="videoPlayNow()"
    #videoPlayer
    [controls]="true"
    class="media"
  >
    <source [src]="media" type="video/webm" />
  </video>
  <!-- End: FullScreen -->

  <ng-content></ng-content>
</div>

<app-text-cell
  *ngIf="caption"
  class="caption-box wrap-text copyable-item text-cursor"
  dir="auto"
  [text]="caption"
  [style.width.px]="width"
>
</app-text-cell>
