import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ProductsUIActionTypes } from './productsUI.actions';
import * as ProductsUiActions from './productsUI.actions';
import {
  CreateProductMethod,
  Product,
  SetProductMethod,
  GetProductsMethod,
  AddInventoryMethod,
  GetProductMethod,
  RemoveProductMethod
} from 'src/models/Product';

import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import { ProductsUiDispatchers } from './productsUI.dispatchers';
import { ChatDispatchers } from '../chats/chat.dispatchers';

import * as isEqual from 'lodash.isequal';
@Injectable()
export class ProductsUiEffects {
  @Effect({ dispatch: false })
  createProduct = this.actions$.pipe(
    ofType(ProductsUIActionTypes.CREATE_PRODUCT),
    map((action: ProductsUiActions.CreateProduct) => {
      this._socketGateway.sendSocketMessage(
        new CreateProductMethod(action.product)
      );
    })
  );

  @Effect({ dispatch: false })
  updateProduct = this.actions$.pipe(
    ofType(ProductsUIActionTypes.SET_PRODUCT),
    map((action: ProductsUiActions.SetProduct) => {
      const product: Product = {
        ...action.productDetails,
        id: action.productID
      };
      this._socketGateway.sendSocketMessage(new SetProductMethod(product));
    })
  );

  @Effect({ dispatch: false })
  addInventory = this.actions$.pipe(
    ofType(ProductsUIActionTypes.ADD_INVENTORY),
    map((action: ProductsUiActions.AddInventory) => {
      this._socketGateway.sendSocketMessage(
        new AddInventoryMethod(action.productID, action.units)
      );
    })
  );

  //
  @Effect({ dispatch: false })
  getNextProductsPage = this.actions$.pipe(
    ofType(ProductsUIActionTypes.UI_PRODUCTS_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.productsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, productsUiState]) => {
      const action = <ProductsUiActions.GetNextProductsPage>val;
      const prevRequest = productsUiState.previousRequest;
      if (
        !(
          productsUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          productsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetProductsMethod(
            0,
            productsUiState.eop,
            productsUiState.productGroupId,
            productsUiState.ownerGroupId
          )
        );
      } else {
        this._uiProductsDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevProductsPage = this.actions$.pipe(
    ofType(ProductsUIActionTypes.UI_PRODUCTS_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.productsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, productsUiState]) => {
      const action = <ProductsUiActions.GetPrevProductsPage>val;
      const prevRequest = productsUiState.previousRequest;
      if (
        !(
          productsUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          productsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetProductsMethod(
            1,
            productsUiState.sop,
            productsUiState.productGroupId,
            productsUiState.ownerGroupId
          )
        );
      } else {
        this._uiProductsDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterProductsResetPaging = this.actions$.pipe(
    ofType(ProductsUIActionTypes.UI_PRODUCTS_RESET_PAGING),
    map((action: ProductsUiActions.ResetProductsPaging) => {
      this._uiProductsDispatchers.getNextProductsPage();
    })
  );

  @Effect({ dispatch: false })
  getProduct = this.actions$.pipe(
    ofType(ProductsUIActionTypes.GET_PRODUCT),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, groupsState]) => {
      const action = <ProductsUiActions.GetProduct>val;
      if (!action.productType) {
        this._socketGateway.sendSocketMessage(
          new GetProductMethod(action.productID, action.bundleID, 0)
        );
      }
      // else if (MarketTypes.CHAT === action.productType) {
      //   let timeOutMilliSecs = 0;
      //   if (ChatMiddleware.isNewChat(action.productID, groupsState)) {
      //     const newChat: IChat = {};
      //     newChat.id = action.productID;
      //     this._chatDispatchers.newChatReceived(newChat);
      //     timeOutMilliSecs = 300;
      //   }
      //   setTimeout(
      //     () => this._chatDispatchers.requestChatDetails(action.productID),
      //     timeOutMilliSecs
      //   );
      // }
    })
  );

  @Effect({ dispatch: false })
  deleteProduct = this.actions$.pipe(
    ofType(ProductsUIActionTypes.DELETE_PRODUCT),
    map((action: ProductsUiActions.DeleteProduct) => {
      this._socketGateway.sendSocketMessage(
        new RemoveProductMethod(action.productId)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiProductsDispatchers: ProductsUiDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _store: Store<AppState>
  ) {}
}
