import { Store } from '@ngrx/store';
import * as PaymentActions from './payment.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class PaymentDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    paymentSet() {
        this._store.dispatch(new PaymentActions.PaymentSet());
    }
    getPricePlans() {
        this._store.dispatch(new PaymentActions.GetPricePlans());
    }
    setPricePlans(plans) {
        this._store.dispatch(new PaymentActions.SetPricePlans(plans));
    }
    getPaymentInfo() {
        this._store.dispatch(new PaymentActions.GetPaymentInfo());
    }
    rcvdPaymentAuthInfo(paymentPlan) {
        this._store.dispatch(new PaymentActions.RcvdPaymentAuthInfo(paymentPlan));
    }
    setCurrentPayment(paymentPlan) {
        this._store.dispatch(new PaymentActions.SetCurrentPayment(paymentPlan));
    }
    setPaymentInfo(paymentPlan) {
        this._store.dispatch(new PaymentActions.SetPaymentInfo(paymentPlan));
    }
    getPaymentToken() {
        this._store.dispatch(new PaymentActions.GetPaymentToken());
    }
    setPaymentToken(token) {
        this._store.dispatch(new PaymentActions.SetPaymentToken(token));
    }
    setPaymentUrl(token) {
        this._store.dispatch(new PaymentActions.SetPaymentUrl(token));
    }
    setSelectedPayment(plan) {
        this._store.dispatch(new PaymentActions.SetSelectedPayment(plan));
    }
    setSelectedFreePayment(plan) {
        this._store.dispatch(new PaymentActions.SetSelectedFreePayment(plan));
    }
    editSelectedPayment(plan) {
        this._store.dispatch(new PaymentActions.EditSelectedPayment(plan));
    }
    setCanceledPayment(plan) {
        this._store.dispatch(new PaymentActions.SetCanceledPayment(plan));
    }
    setCanceledFreePayment(plan) {
        this._store.dispatch(new PaymentActions.SetCanceledFreePayment(plan));
    }
    resetPaymentPlan() {
        this._store.dispatch(new PaymentActions.ResetPaymentPlan());
    }
}
PaymentDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentDispatchers_Factory() { return new PaymentDispatchers(i0.ɵɵinject(i1.Store)); }, token: PaymentDispatchers, providedIn: "root" });
