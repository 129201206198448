<div
  class="toast active"
  [class.error]="!toast?.status"
  [class.success]="toast?.status"
>
  <div class="t-icon">
    <svg
      class="svg-icon icon"
      width="16"
      height="16"
      fill="currentColor"
      *ngIf="!toast?.status"
    >
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-error-black"></use>
    </svg>
    <svg
      class="svg-icon icon-icon"
      width="16"
      height="16"
      fill="currentColor"
      *ngIf="toast?.status"
    >
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-shape-done"></use>
    </svg>
  </div>
  <div class="t-message">
    {{ toast?.value }}
  </div>
  <div class="t-close">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="t-close"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      (click)="uiService?.removeToastMessage(toast?.key)"
    >
      <path
        d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
      ></path>
    </svg>
  </div>
  <div class="t-progress-bar" style="--toast-duration:10000ms;"></div>
</div>
