import {
  SEND_QR_GET_METHOD,
  SEND_PING_METHOD,
  SEND_SELECT_CHAT_METHOD,
  GET_EMAIL_TAC_METHOD,
  SEND_TOKEN_AUTH_METHOD,
  CREATE_ACCOUNT_METHOD,
  GET_TOKEN,
  GET_ADMIN_QR_METHOD,
  GET_AUTH_PRIVILEGE_METHOD,
  CHANGE_PASSWORD_METHOD,
  RESET_PASSWORD_METHOD,
  SIGNUP_DEMO_ACCOUNT,
  REGISTER_DEMO_ACCOUNT,
  GET_DEMO_ACCOUNT_STATUS,
  RESEND_VER_CODE,
  CREATE_ACCOUNT_WITH_GOOGLE_METHOD,
  LOGIN_WITH_GOOGLE_METHOD,
  SET_BUSINESS_INFO,
  GET_BUSINESS_INFO
} from './constants';
import { AppTemplate } from 'src/models/AppTemplate';

export interface IAuth {
  qrCode?: string;
  botId?: string;
  token?: string;
  localId?: string;
  name?: string;
  url?: string;
  authStatus?: string;
  rem?: boolean;
  qrExpire?: boolean;
  ID?: string;
  date?: number;
  offset?: number;
  chatId?: string;
  countryIso?: string;
  timezone?: string;
  tac?: string;
  email?: string;
  password?: string;
  appName?: string;
  signupStatus?: string;
  selectedTemplateID?: string;
  progressWeb?: any;
  mode?: any;
  adminQRCode?: string;
  appConfig?: number;
  role?: number;
  main_group_id?: string;
  is_nandbox?: number;
  company_name?: string;
  website?: string;
}

export interface AuthForm {
  email?: string;
  password?: string;
  appName?: string;
  name?: string;
  terms?: boolean;
  privacy?: boolean;
  countryIso?: string;
  templateID?: string;
  tac?: string;
  rem?: boolean;
  su?: string;
  google?: string;
}

export interface CountryData {
  country_code?: string;
  timezone?: string;
}

export interface HttpResult {
  result?: number;
  error?: number;
  token?: string;
  templates?: AppTemplate[];
  su?: any;
}

export interface HttpTokenResult {
  result?: number;
  token?: string;
}

export class GetQRCode {
  readonly method = SEND_QR_GET_METHOD;
}

export class Ping {
  readonly method = SEND_PING_METHOD;
}

export class SendTokenAuth {
  readonly method = SEND_TOKEN_AUTH_METHOD;
  constructor(public token: string, public rem: boolean) {}
}

export class SendSelectChat {
  readonly method = SEND_SELECT_CHAT_METHOD;
  constructor(public chat_id: string, public isAuth?: number) {}
}

// send type by 2 in case of reset
export class SendGetEmailTac {
  readonly method = GET_EMAIL_TAC_METHOD;
  constructor(
    public countryIso: string,
    public email: string,
    public type?: number
  ) {}
}

export class ResetPassword {
  readonly method = RESET_PASSWORD_METHOD;
  constructor(
    public email: string,
    public tac: string,
    public newPassword: string,
    public confirmNewPassword: string
  ) {}
}

export class CreateAccount {
  readonly method = CREATE_ACCOUNT_METHOD;
  constructor(
    public tac: string,
    public email: string,
    public password: string,
    public name: string,
    public template_id: string,
    public full_name: string
  ) {}
}
export class CreateGoogleAccount {
  readonly method = CREATE_ACCOUNT_WITH_GOOGLE_METHOD;
  constructor(
    public email: string,
    public google: string,
    public countryIso: string,
    public name: string,
    public full_name: string
  ) {}
}
export class LoginGoogleAccount {
  readonly method = LOGIN_WITH_GOOGLE_METHOD;
  constructor(public email: string, public google: string) {}
}

export class GetToken {
  readonly method = GET_TOKEN;
  constructor(
    public email: string,
    public password: string,
    public su?: string
  ) {}
}

export class GetAdminQRCode {
  readonly method = GET_ADMIN_QR_METHOD;
}

export class GetAuthPrivilege {
  readonly method = GET_AUTH_PRIVILEGE_METHOD;
  constructor(public reference?: string) {}
}

export class ChangePassword {
  readonly method = CHANGE_PASSWORD_METHOD;
  constructor(
    public oldPassword: string,
    public newPassword: string,
    public confirmNewPassword: string
  ) {}
}

export class SingUpDemoAccount {
  readonly method = SIGNUP_DEMO_ACCOUNT;
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public email: string,
    public type: string,
    public countryCode: string,
    public countryIso: string
  ) {}
}

export class RegisterDemoAccount {
  readonly method = REGISTER_DEMO_ACCOUNT;
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public email: string,
    public type: string,
    public tac: string,
    public countryCode: string
  ) {}
}

export class GetDemoAccountStatus {
  readonly method = GET_DEMO_ACCOUNT_STATUS;
  constructor(public msisdn: string, public countryCode: string) {}
}

export class ResendVerificationCode {
  readonly method = RESEND_VER_CODE;
  constructor(public msisdn: string, public countryCode: string) {}
}
export class SetBusinessInfoMethod {
  readonly method = SET_BUSINESS_INFO;
  constructor(public company_name: string, public website?: string) {}
}
export class GetBusinessInfoMethod {
  readonly method = GET_BUSINESS_INFO;
}

export type AuthMethods =
  | GetQRCode
  | Ping
  | SendTokenAuth
  | SendSelectChat
  | SendGetEmailTac
  | CreateAccount
  | GetToken
  | GetAdminQRCode
  | GetAuthPrivilege
  | SingUpDemoAccount
  | RegisterDemoAccount
  | GetDemoAccountStatus
  | ResendVerificationCode
  | ChangePassword;
