import { Title } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnChanges,
  HostListener,
  ViewChildren,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { ScreenSizeService } from 'src/app/core/screen-size.service';
import { DashboardService } from './dashboard.service';
import { AppMgmtService } from './app-mgmt/appMgmt.service';

import { slideToDown } from 'src/app/app-animations';
import { AppService } from 'src/app/app.service';
import { UiService } from 'src/app/core/ui.service';
import { AuthService } from 'src/app/core/auth.service';

import { IMessage } from 'src/models/IMessage';
import { PrivilegesName } from 'src/models/privileges';
import {
  CHANNELS,
  UISections,
  MessageSendingType,
  AuthStatus,
  MAIN_WEB
} from 'src/models/constants';
import { NavigateService } from 'src/app/core/navigate.service';
import { UIDispatchers } from './../../store/ui/ui.dispatchers';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ShowIfHasPrivilegeDirective } from 'src/app/accessors/show-if-has-privilege.directive';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { BillingsService } from './billings/billings.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [slideToDown],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('appScroll', { static: false }) myScrollContainer: ElementRef;
  uiSections: typeof UISections = UISections;
  authStatus: typeof AuthStatus = AuthStatus;

  isChrome = true;
  isMobile = false;
  // UI variables
  showTagModal = false;
  isMenuOpen = true;
  initComposeMessage: IMessage = null;
  messageSendingType: typeof MessageSendingType = MessageSendingType;
  privilegesName: typeof PrivilegesName = PrivilegesName;
  authSubscribtion = null;
  scrollSubscribtion: Subscription = null;
  ui: any;
  isVisible = false;
  stop$ = new Subject();
  authSatus = AuthStatus;

  @ViewChildren(ShowIfHasPrivilegeDirective) privileges;
  @ViewChild('layout', { static: false }) layout: ElementRef;
  constructor(
    public screenSize: ScreenSizeService,
    private _navigatedService: NavigateService,
    public router: Router,
    public uiService: UiService,
    public appServer: AppService,
    public authService: AuthService,
    public appMgmtService: AppMgmtService,
    public dashboardService: DashboardService,
    public _uiDispatchers: UIDispatchers,
    public location: Location,
    public billingsService: BillingsService,
    private title: Title,
    public layoutService: LayoutService,
    public _ref: ChangeDetectorRef
  ) {
    this.scrollSubscribtion = this.appMgmtService.scrollDashBoard$.subscribe(
      scrl => {
        if (scrl) {
          this.scrollToBottom();
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.scrollSubscribtion && this.scrollSubscribtion !== null) {
      this.scrollSubscribtion.unsubscribe();
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.checkPath();
  }
  ngOnInit() {
    const linkDevexpress1 = document.createElement('link');
    linkDevexpress1.type = 'text/css';
    linkDevexpress1.rel = 'stylesheet';
    linkDevexpress1.href =
      'https://cdn3.devexpress.com/jslib/20.2.5/css/dx.common.css';
    document.body.appendChild(linkDevexpress1);

    const linkDevexpress2 = document.createElement('link');
    linkDevexpress2.type = 'text/css';
    linkDevexpress2.rel = 'stylesheet';
    linkDevexpress2.href =
      'https://cdn3.devexpress.com/jslib/20.2.5/css/dx.light.css';
    document.body.appendChild(linkDevexpress2);

    this.uiService.selectTab(CHANNELS);

    this.authSubscribtion = this.authService.authCollection$.subscribe(auth => {
      if (
        auth.authStatus === this.authSatus.INIT_SOKET ||
        auth.authStatus === this.authSatus.AUTH_QR_SET ||
        auth.authStatus === this.authSatus.LOGGED_OUT
      ) {
        this.reNavigate();
      }
    });

    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
    /**
     * Check if browser is chrome as currently we are support chrome oly
     */

    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    this.title.setTitle('The Native App Builder');
  }
  ngOnChanges() {
    this.authSubscribtion = this.authService.authCollection$.subscribe(auth => {
      if (
        auth.authStatus === this.authSatus.INIT_SOKET ||
        auth.authStatus === this.authSatus.AUTH_QR_SET ||
        auth.authStatus === this.authSatus.LOGGED_OUT
      ) {
        this.reNavigate();
      }
    });

    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
  }

  checkPath() {
    this.ui = this.dashboardService.uiCollection$.subscribe(res => {
      const url = this.location.path();
      const urlPath = url.split('?');
      if (urlPath[0] !== '/signup' && urlPath[0] !== '/verifyCode') {
        switch (this.location.path()) {
          /**
           * Sections not pagination
           */
          case '/campaigns':
            if (res.section !== UISections.CAMPAIGNS) {
              this.dashboardService.setSection(UISections.CAMPAIGNS);
            }
            break;
          case '/coupons':
            if (res.section !== UISections.COUPONS) {
              this.dashboardService.setSection(UISections.COUPONS);
            }
            break;
          case '/bots':
            if (res.section !== UISections.BOTS) {
              this.dashboardService.setSection(UISections.BOTS);
            }
            break;
          case '/polls':
            if (res.section !== UISections.MENUS) {
              this.dashboardService.setSection(UISections.MENUS);
            }
            break;
          case '/locations':
            if (res.section !== UISections.LOCATIONS) {
              this.dashboardService.setSection(UISections.LOCATIONS);
            }
            break;
          /******************************************/
          case '/subgroups':
            if (res.section !== UISections.SUBGROUP) {
              this.dashboardService.setSection(UISections.SUBGROUP);
            }
            break;
          case '/subchannels':
            if (res.section !== UISections.SUBCHANNEL) {
              this.dashboardService.setSection(UISections.SUBCHANNEL);
            }

            break;
          case '/vapps':
            if (res.section !== UISections.MYCH) {
              this.dashboardService.setSection(UISections.MYCH);
            }

            break;

          case '/my-channel':
            if (res.section !== UISections.MYCH) {
              this.dashboardService.setSection(UISections.MYCH);
            }
            // UISections.MYCH

            break;
          case '/billings':
            if (res.section !== UISections.BILLINGS) {
              this.dashboardService.setSection(UISections.BILLINGS);
            }
            break;
          case '/app':
            if (res.section !== UISections.APP) {
              this.dashboardService.setSection(UISections.APP);
            }
            break;

          case '/messages':
            if (res.section !== UISections.POSTS) {
              this.dashboardService.setSection(UISections.POSTS);
            }
            break;
          case '/schedules':
            if (res.section !== UISections.SCHEDULE) {
              this.dashboardService.setSection(UISections.SCHEDULE);
            }
            break;

          case '/bookings':
            if (res.section !== UISections.BOOKING) {
              this.dashboardService.setSection(UISections.BOOKING);
            }
            break;
          case '/calendars':
            if (res.section !== UISections.CALENDAR) {
              this.dashboardService.setSection(UISections.CALENDAR);
            }
            break;
          case '/events':
            if (res.section !== UISections.EVENTS) {
              this.dashboardService.setSection(UISections.EVENTS);
            }
            break;
          case '/users':
            if (res.section !== UISections.USERS) {
              this.dashboardService.setSection(UISections.USERS);
            }
            break;
          case '/blackList':
            if (res.section !== UISections.BLACKLIST) {
              this.dashboardService.setSection(UISections.BLACKLIST);
            }
            // UISections.BLACKLIST
            break;
          case '/tags':
            if (res.section !== UISections.TAGS) {
              this.dashboardService.setSection(UISections.TAGS);
            }

            break;
          case '/bulkupload':
            if (res.section !== UISections.BULKUPLOAD) {
              this.dashboardService.setSection(UISections.BULKUPLOAD);
            }

            break;
          case '/settings':
            if (res.section !== UISections.SETTINGS) {
              this.dashboardService.setSection(UISections.SETTINGS);
            }

            break;
          case '/transactions':
            if (res.section !== UISections.TRANSACTIONS) {
              this.dashboardService.setSection(UISections.TRANSACTIONS);
            }

            break;
          case '/packages':
            if (res.section !== UISections.PACKAGES) {
              this.dashboardService.setSection(UISections.PACKAGES);
            }

            break;
          case '/bundles':
            if (res.section !== UISections.BUNDLES) {
              this.dashboardService.setSection(UISections.BUNDLES);
            }

            break;
          case '/customercare':
            if (res.section !== UISections.CUSTOMERCARE) {
              this.dashboardService.setSection(UISections.CUSTOMERCARE);
            }

            break;
          case '/products':
            if (res.section !== UISections.PRODUCTS) {
              this.dashboardService.setSection(UISections.PRODUCTS);
            }

            break;
          case '/tempsupload':
            if (res.section !== UISections.UPLOADTEMPS) {
              this.dashboardService.setSection(UISections.UPLOADTEMPS);
            }

            break;
          case '/mediacenter':
            if (res.section !== UISections.MEDIACENTER) {
              this.dashboardService.setSection(UISections.MEDIACENTER);
            }

            break;
          case '/mStore':
            if (res.section !== UISections.MSTORE) {
              this.dashboardService.setSection(UISections.MSTORE);
            }

            break;
          case '/storeMenu':
            if (res.section !== UISections.STOREMENU) {
              this.dashboardService.setSection(UISections.STOREMENU);
            }

            break;
          case '/MS':
            if (res.section !== UISections.MAPSERVICE) {
              this.dashboardService.setSection(UISections.MAPSERVICE);
            }

            break;
          case '/MR':
            if (res.section !== UISections.MAPROUTES) {
              this.dashboardService.setSection(UISections.MAPROUTES);
            }

            break;

          case '/Trips':
            if (res.section !== UISections.TRIPS) {
              this.dashboardService.setSection(UISections.TRIPS);
            }
            break;

          case '/TH':
            if (res.section !== UISections.TRIPSHISTORY) {
              this.dashboardService.setSection(UISections.TRIPSHISTORY);
            }
            break;
          case '/TN':
            if (res.section !== UISections.TRIPNOTIFIERS) {
              this.dashboardService.setSection(UISections.TRIPNOTIFIERS);
            }
            break;

          case '/wl':
            if (res.section !== UISections.WHITELIST) {
              this.dashboardService.setSection(UISections.WHITELIST);
            }
            break;
          case '/store':
            if (res.section !== UISections.MOBILESTORE) {
              this.dashboardService.setSection(UISections.MOBILESTORE);
            }
            break;
          case '/web-settings':
            if (res.section !== UISections.WEBSITTINGS) {
              this.dashboardService.setSection(UISections.WEBSITTINGS);
            }
            break;
          default:
            this.dashboardService.setSection(UISections.HOME);

            break;
        }
      }
    });
    this.ui.unsubscribe();
  }

  reNavigate() {
    if (this.location.path() !== '/login') {
      this.router.navigate(['/login']);
    }
  }
  /** Events section */
  backFromGroupReplies() {
    this.uiService.backFromGroupReplies();
  }
  backFromOneToOneReply() {
    this.uiService.backFromOneToOneReply();
  }
  talkToAdminEvent(chatId: string) {
    this.uiService.requestHistory(chatId);
    this.uiService.selectTalkToAdmin();
  }

  toggleMenuEvent() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  searchEvent(searchTerm: string): void {
    this.paginationEvent('rest');
    this.dashboardService.setSearchKeyword(searchTerm);
  }
  sectionEvent(direction: string): void {
    this.paginationEvent('rest');
    this.dashboardService.setSection(direction);
  }
  selectEvent(action: string) {
    this.dashboardService.setSelect(action);
  }

  paginationEvent(value: string): void {
    this.dashboardService.setPageNumber(value);
  }
  filterDateEvent($event) {
    this.dashboardService.setScheduleDate($event);
  }
  logoutEvent($event) {
    if ($event) {
      this.dashboardService.logout();
    }
  }
  useHereEvent() {
    this.authService.reopenNewConnection();
  }
  closeWindowEvent() {
    (<any>window).location.assign(MAIN_WEB);
  }
  togglePopupUpgradePlanModule(moduleID) {
    this._uiDispatchers.togglePopupUpgradePlanModule(moduleID);
  }
  isFunction(functionToCheck) {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === '[object Function]'
    );
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
    this.appMgmtService._scrollDashBoard.next(false);
  }
}
