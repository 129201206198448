import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { ChatDispatchers } from '../chats/chat.dispatchers';
export class TagsEffects {
    constructor(actions$, _store, _chatDispatchers) {
        this.actions$ = actions$;
        this._store = _store;
        this._chatDispatchers = _chatDispatchers;
        this.setMainChatTags = this.actions$.pipe(ofType("UI_RECEIVED_TAGS" /* UI_RECEIVED_TAGS */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            // const chat: ChatMethods.ReceiveChat = {...action.payload};
            if (selectedChat.id === action.id) {
                this._chatDispatchers.tagsReceived(action.payload);
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], TagsEffects.prototype, "setMainChatTags", void 0);
