<div class="loading-cover">
  <div
    *ngIf="
      status === messageMediaStatus.UPLOADING ||
      status === messageMediaStatus.DOWNLOADING
    "
    class="loading-cover-loading"
  >
    <div class="statement">File Uploading…</div>
    <div class="type">{{ type }}({{ size | fileSize }})</div>
    ​
    <div class="progress-border">
      <div class="progress-border-meter" [style.width.%]="progress"></div>
    </div>
  </div>

  <div
    *ngIf="
      status === messageMediaStatus.UPLOADED ||
      status === messageMediaStatus.DOWNLOADED
    "
    class="loading-cover-loadedd"
  >
    <div class="checkmark-cover">
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-sent"></use>
      </svg>
    </div>
    <div class="statement">Upload Complete</div>
  </div>
</div>
