import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';

import { IconLabelledComponent } from './icon-labelled/icon-labelled.component';
import { TextLabelledComponent } from './text-labelled/text-labelled.component';
import { ActiveDaysLabelledComponent } from './active-days-labelled/active-days-labelled.component';

@NgModule({
  imports: [CommonModule, SharedModule, AbstractBtnsModule],
  declarations: [
    IconLabelledComponent,
    TextLabelledComponent,
    ActiveDaysLabelledComponent
  ],
  exports: [IconLabelledComponent, TextLabelledComponent]
})
export class AbstractLabelledModule {}
