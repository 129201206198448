import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MenuUIActions from './menuUI.actions';

import { AppState } from 'src/models/AppState';
import {
  RcvdMyMenusMessage,
  BotMenu,
  RcvdMyMenuMessage
} from 'src/models/IBotMenu';
import { ImageSet } from 'src/models/ChannelAppConfig';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class MenuUIDispatchers {
  constructor(private _store: Store<AppState>) {}

  listMenus(type?: number) {
    this._store.dispatch(new MenuUIActions.ListMenus(type));
  }

  menusReceiving(menu: RcvdMyMenusMessage) {
    this._store.dispatch(new MenuUIActions.MenusReceiving(menu));
  }

  menuReceiving(menu: RcvdMyMenuMessage) {
    this._store.dispatch(new MenuUIActions.MenuReceiveing(menu));
  }

  menuReceived(menu: BotMenu) {
    this._store.dispatch(new MenuUIActions.MenuReceived(menu));
  }

  createMenu(menu: BotMenu) {
    this._store.dispatch(new MenuUIActions.CreateMenu(menu));
  }

  updateMenu(menu: BotMenu) {
    this._store.dispatch(new MenuUIActions.UpdateMenu(menu));
  }

  deleteMenu(menu: BotMenu) {
    this._store.dispatch(new MenuUIActions.DeleteMenu(menu));
  }

  getNextMenuPage() {
    this._store.dispatch(new MenuUIActions.GetNextMenuPage());
  }
  getPrevMenuPage() {
    this._store.dispatch(new MenuUIActions.GetPrevMenuPage());
  }
  resetMenuPaging() {
    this._store.dispatch(new MenuUIActions.ResetMenuPaging());
  }
  setStatusAsIdle(): void {
    this._store.dispatch(new MenuUIActions.SetStatusAsIdle());
  }

  UploadMenuImg(componentRef: string, selectedFile: ISelectedFiles) {
    this._store.dispatch(
      new MenuUIActions.UploadMenuImg(componentRef, selectedFile)
    );
  }

  UploadMenuImgSuccess(
    componentRef: any,
    imageUrl: string,
    imageSet: ImageSet
  ) {
    // this.formMenu.reciveImageUrl(imageUrl, componentRef);
  }
  reset() {
    this._store.dispatch(new MenuUIActions.Reset());
  }
}
