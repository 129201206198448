import { combineLatest, BehaviorSubject } from 'rxjs';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { EventUiDispatchers } from 'src/app/store/eventsUI/eventUI.dispatchers';
import { UPDATE_EVENT_REF } from 'src/models/constants';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/chats/chat.dispatchers";
import * as i3 from "../../../store/eventsUI/eventUI.dispatchers";
import * as i4 from "@angular/router";
export class EventsService {
    constructor(_selectors, _chatDispatchers, _eventUiDispatchers, router) {
        this._selectors = _selectors;
        this._chatDispatchers = _chatDispatchers;
        this._eventUiDispatchers = _eventUiDispatchers;
        this.router = router;
        this.confoirmDeleteWindow = false;
        this._currentPhotoUploaded = new BehaviorSubject({});
        this.currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();
        this._cerateNew = new BehaviorSubject(false);
        this.cerateNew$ = this._cerateNew.asObservable();
        this._uploadingImgIds = new BehaviorSubject([]);
        this.uploadingImgIds$ = this._uploadingImgIds.asObservable();
        this._wattingForUploadImage = new BehaviorSubject(false);
        this.wattingForUploadImage$ = this._wattingForUploadImage.asObservable();
    }
    createEvent(event, secandEvent) {
        this._chatDispatchers.createEvent(event, secandEvent);
    }
    resetPaging() {
        this._eventUiDispatchers.resetEventPaging();
    }
    viewEvent(event) {
        this.getChatAdministrators(event.id);
        this._eventUiDispatchers.viewEventUI(event);
    }
    closeViewEvent() {
        this._eventUiDispatchers.closeViewEventUI();
    }
    showEditEventScreen(event) {
        this._wattingForUploadImage.next(false);
        const photo = {
            file: null,
            url: event.localThumbnail
        };
        this._currentPhotoUploaded.next(photo);
        this.getChatAdministrators(event.id);
        this._eventUiDispatchers.editEventUI(event);
    }
    showCreateEventScreen() {
        this._wattingForUploadImage.next(false);
        this._eventUiDispatchers.createEventUI();
        // this.getGeneratedChatID(EVENT_CHANNEL);
        this.router.navigate(['/events/form']);
    }
    closeEditEventScreen() {
        this._eventUiDispatchers.closeEventEditUI();
    }
    uploadEventImage(file) {
        this._eventUiDispatchers.uploadEventImage(file);
    }
    eventCreationComplete() {
        this._eventUiDispatchers.eventCreationComplete();
    }
    updateEvent(chatID, updatedEventProps) {
        updatedEventProps.id = chatID;
        this._chatDispatchers.updateChat(updatedEventProps, UPDATE_EVENT_REF);
    }
    updateStore(chatID, updatedEventProps) {
        updatedEventProps.id = chatID;
        this._chatDispatchers.updateStore(updatedEventProps, UPDATE_EVENT_REF);
        this._eventUiDispatchers.closeEventEditUI();
    }
    confirmDelete(id) {
        this.deleteEvent(id);
        this.toggleConfirmDeleteWindow(false);
    }
    deleteEvent(id) {
        this._chatDispatchers.deleteChat(id);
    }
    toggleConfirmDeleteWindow(e, evant) {
        this.confoirmDeleteWindow = e;
        this.selectedEvant = evant;
    }
    getNextPage() {
        this._eventUiDispatchers.getNextEventPage();
    }
    getPreviousPage() {
        this._eventUiDispatchers.getPrevEventPage();
    }
    get eventUiState$() {
        return this._selectors.eventUiState$;
    }
    get eventList$() {
        return this._selectors.getEventList$();
    }
    get selectedEvent$() {
        return this._selectors.getSelectedEvent$();
    }
    get eventInCreationProcess$() {
        return this._selectors.getEventInCreationProcess$();
    }
    get componentContext$() {
        return combineLatest([
            this.eventUiState$,
            this.eventList$,
            this.selectedEvent$,
            this.eventInCreationProcess$
        ]);
    }
    getChatAdministrators(id) {
        this._chatDispatchers.getChatAdministrators(id);
    }
    getUserGroupRole(id, userId) {
        this._chatDispatchers.getUserGroupRole(id, userId);
    }
    updateEventPhoto(chat) {
        this._chatDispatchers.updateChat(chat);
    }
    routeBackToEventList() {
        this.router.navigate(['/events']);
    }
}
EventsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventsService_Factory() { return new EventsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ChatDispatchers), i0.ɵɵinject(i3.EventUiDispatchers), i0.ɵɵinject(i4.Router)); }, token: EventsService, providedIn: "root" });
