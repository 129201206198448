import { Action } from '@ngrx/store';
import { Product } from 'src/models/Product';
import { MarketTypes } from 'src/models/constants';

export const enum ProductsUIActionTypes {
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  SET_PRODUCT = 'SET_PRODUCT',
  ADD_INVENTORY = 'ADD_INVENTORY',
  GET_PRODUCT = 'GET_PRODUCT',
  PRODUCT_RECEIVED = 'PRODUCT_RECEIVED',
  PRODUCTS_RECEIVED = 'PRODUCTS_RECEIVED',
  UI_PRODUCTS_GET_NEXT_PAGE = 'UI_PRODUCTS_GET_NEXT_PAGE',
  UI_PRODUCTS_GET_PREV_PAGE = 'UI_PRODUCTS_GET_PREV_PAGE',
  UI_PRODUCTS_RECEIVED_HISTORY_CHUNK = 'UI_PRODUCTS_RECEIVED_HISTORY_CHUNK',
  UI_PRODUCTS_RESET_PAGING = 'UI_PRODUCTS_RESET_PAGING',
  UI_PRODUCTS_SET_IDLE_STATUS = 'UI_PRODUCTS_SET_IDLE_STATUS',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  PRODUCT_DELETED = 'PRODUCT_DELETED',
  RESET = 'RESET'
}

export class CreateProduct implements Action {
  readonly type = ProductsUIActionTypes.CREATE_PRODUCT;
  constructor(public product: Product) {}
}

export class SetProduct implements Action {
  readonly type = ProductsUIActionTypes.SET_PRODUCT;
  constructor(public productID: string, public productDetails: Product) {}
}

export class AddInventory implements Action {
  readonly type = ProductsUIActionTypes.ADD_INVENTORY;
  constructor(public productID: string, public units: number) {}
}

export class GetProduct implements Action {
  readonly type = ProductsUIActionTypes.GET_PRODUCT;
  constructor(
    public productID: string,
    public productType: MarketTypes,
    public bundleID?: string
  ) {}
}

export class ProductReceived implements Action {
  readonly type = ProductsUIActionTypes.PRODUCT_RECEIVED;
  constructor(public product: Product) {}
}

export class ProductsReceived implements Action {
  readonly type = ProductsUIActionTypes.PRODUCTS_RECEIVED;
  constructor(public product: Product[]) {}
}

// Paging Actions

export class GetNextProductsPage implements Action {
  readonly type = ProductsUIActionTypes.UI_PRODUCTS_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevProductsPage implements Action {
  readonly type = ProductsUIActionTypes.UI_PRODUCTS_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedProductsHistoryChunk implements Action {
  readonly type = ProductsUIActionTypes.UI_PRODUCTS_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: Product[]
  ) {}
}

export class ResetProductsPaging implements Action {
  readonly type = ProductsUIActionTypes.UI_PRODUCTS_RESET_PAGING;
  constructor(public product_group_id?: number, public ownerGroupId?: string) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = ProductsUIActionTypes.UI_PRODUCTS_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = ProductsUIActionTypes.RESET;
}

export class DeleteProduct implements Action {
  readonly type = ProductsUIActionTypes.DELETE_PRODUCT;
  constructor(public productId: string) {}
}

export class ProductDeleted implements Action {
  readonly type = ProductsUIActionTypes.PRODUCT_DELETED;
  constructor(public id: string) {}
}

export type Actions =
  | CreateProduct
  | SetProduct
  | AddInventory
  | ProductReceived
  | ProductsReceived
  | GetProduct
  | GetNextProductsPage
  | GetPrevProductsPage
  | ReceivedProductsHistoryChunk
  | ResetProductsPaging
  | SetStatusAsIdle
  | DeleteProduct
  | ProductDeleted
  | Reset;
