import { Injectable } from '@angular/core';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { IChat } from 'src/models/IChat';
import { Observable } from 'rxjs';
// import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { SubChannelUiDispatchers } from 'src/app/store/subChannelsUI/subChannelsUI.dispatchers';
import { SubChatsService } from '../sub-chats/sub-chats.service';

@Injectable({
  providedIn: 'root'
})
export class MyChannelService {
  private selected = null;
  constructor(
    private _rootSelector: RootStoreSelectors,
    private _subChannelUiDispatchers: SubChannelUiDispatchers,
    private subgroupService: SubChatsService
  ) {
    // this.selected = this.selectedSubGroup$.subscribe(res => {
    //   if (res) {
    //     subgroupService.getChatAdministrators(res.id);
    //   }
    // });
  }

  get selectedSubGroup$(): Observable<IChat> {
    return this._rootSelector.getEffectiveSelectedIChat$();
  }

  uploadSubGroupImage(file): void {
    this._subChannelUiDispatchers.uploadSubChannelImage(file);
  }
}
