import { Store } from '@ngrx/store';
import * as MapDriverActions from './mapTripDriver.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapDriverDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    mapDriverReceived(mapTrip) {
        this._store.dispatch(new MapDriverActions.MapDriverTipsReceived(mapTrip));
    }
    getMapDriverTripById(tripId) {
        this._store.dispatch(new MapDriverActions.GetMapDriver(tripId));
    }
    deleteMapDriverTripById(userId, tripId) {
        this._store.dispatch(new MapDriverActions.DeleteMapDriver(userId, tripId));
    }
}
MapDriverDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapDriverDispatchers_Factory() { return new MapDriverDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapDriverDispatchers, providedIn: "root" });
