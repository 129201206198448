import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PackagesUiDispatchers } from 'src/app/store/packagesUI/packagesUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';

import { Bundle } from 'src/models/Bundle';
import { Package } from 'src/models/Package';
import { Product } from 'src/models/Product';
import { PackageScreens, MarketTypes } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  private _selectedPackage = new BehaviorSubject<Package>(null);
  public selectedPackage$ = this._selectedPackage.asObservable();

  private _currentPackageScreen = new BehaviorSubject<string>(
    PackageScreens.PACKAGE_LIST
  );
  public currentPackageScreen$ = this._currentPackageScreen.asObservable();

  confirmPackage = false;
  selectedPackage: any;

  constructor(
    private _rootStore: RootStoreSelectors,
    private _packagesDispatchers: PackagesUiDispatchers
  ) {}

  get packages$() {
    return this._rootStore.getPackages$();
  }

  resetPaging() {
    this._packagesDispatchers.resetPackagesPaging();
  }

  updatePackage(pkg: Package) {
    if (pkg && pkg.id) {
      if (pkg.bundles) {
        const bundleIds = pkg.bundles.map(r => {
          return { id: r.id, type: MarketTypes.BUNDLE };
        });
        this._packagesDispatchers.addPackageItems(pkg.id, bundleIds);
      }
      if (pkg.products) {
        const productsIds = pkg.products.map(r => {
          return { id: r.id, type: MarketTypes.PRODUCT };
        });
        this._packagesDispatchers.addPackageItems(pkg.id, productsIds);
      }
      this._packagesDispatchers.setPackage(pkg.id, pkg);
    } else {
      this._packagesDispatchers.createPackage(pkg);
    }
    this.closeItemCardEvent();
  }

  setPackageProducts(pkgId: string, items: Product[]) {
    const productIDs = items.map(r => {
      return { id: r.id, type: MarketTypes.PRODUCT };
    });
    this._packagesDispatchers.addPackageItems(pkgId, productIDs);
  }

  setPackageBundles(pkgId: string, items: Bundle[]) {
    const bundleIDs = items.map(r => {
      const pr = { id: r.id, type: MarketTypes.BUNDLE };
      return pr;
    });
    this._packagesDispatchers.addPackageItems(pkgId, bundleIDs);
  }

  removePackageBundle(pkgId: string, bundleIds: string[]) {
    this._packagesDispatchers.removePackageBundles(pkgId, bundleIds);
  }

  createItemEvent() {
    this.setCurrentScreen(PackageScreens.PACKAGE_CREATION);
    this.setSelectedPackage(null);
  }
  readItemEvent(pkg: Package) {
    this.setCurrentScreen(PackageScreens.PACKAGE_VIEW);
    this.setSelectedPackage(pkg);
  }
  updateItemEvent(pkg: Package) {
    this.setCurrentScreen(PackageScreens.PACKAGE_EDIT);
    this.setSelectedPackage(pkg);
  }
  closeItemCardEvent() {
    this.setCurrentScreen(PackageScreens.PACKAGE_LIST);
    this.setSelectedPackage(null);
  }
  deletePackage(packageId: string) {
    this._packagesDispatchers.deletePackages(packageId);
  }
  toggelConfirmDeletePackage(e: boolean, packg?: any) {
    this.confirmPackage = e;
    this.selectedPackage = packg;
  }
  setSelectedPackage(pkg: Package) {
    this._selectedPackage.next(pkg);
  }

  setCurrentScreen(screen: string) {
    this._currentPackageScreen.next(screen);
  }

  getNextPage() {
    this._packagesDispatchers.getNextPackagesPage();
  }

  getPreviousPage() {
    this._packagesDispatchers.getPrevPackagesPage();
  }

  getChannelDetails$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
}
