<a class="app-logo clickable-cursor" href="https://nandbox.com/en/">
  <img src="./assets/img/logo-w-text.svg" />
</a>
<div class="login-form">
  <div class="login-form-header">
    Support Log In
  </div>

  <div class="login-form-title">
    Log in with an existing account
  </div>

  <form class="login-form-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field>
        <input
          matInput
          placeholder="Email"
          type="email"
          formControlName="email"
        />
        <app-icon-validation-errors
          *ngIf="email?.errors && email?.touched"
          matSuffix
          [fieldErrors]="email?.errors"
          [fieldName]="'Email'"
        >
        </app-icon-validation-errors>
      </mat-form-field>

      <app-validation-errors
        class="login-form-error"
        *ngIf="email?.errors && email?.touched"
        [fieldName]="'Email'"
        [fieldErrors]="email?.errors"
      >
      </app-validation-errors>
    </div>

    <div class="form-group">
      <mat-form-field>
        <input
          matInput
          placeholder="Password"
          type="password"
          formControlName="password"
        />
        <app-icon-validation-errors
          *ngIf="password?.errors && password?.touched"
          matSuffix
          [fieldErrors]="password?.errors"
          [fieldName]="'Password'"
        >
        </app-icon-validation-errors>
      </mat-form-field>

      <app-validation-errors
        class="login-form-error"
        *ngIf="password?.errors && password?.touched"
        [fieldName]="'Password'"
        [fieldErrors]="password?.errors"
      >
      </app-validation-errors>
    </div>

    <div class="form-group">
      <mat-form-field>
        <input
          matInput
          placeholder="Customer login ID"
          type="email"
          formControlName="su"
        />
        <app-icon-validation-errors
          *ngIf="customerEmail?.errors && customerEmail?.touched"
          matSuffix
          [fieldErrors]="customerEmail?.errors"
          [fieldName]="'Customer Email'"
        >
        </app-icon-validation-errors>
      </mat-form-field>

      <app-validation-errors
        class="login-form-error"
        *ngIf="customerEmail?.errors && customerEmail?.touched"
        [fieldName]="'Customer Email'"
        [fieldErrors]="customerEmail?.errors"
      >
      </app-validation-errors>
    </div>

    <div class="form-submit">
      <app-border-btn next invert lg [type]="'submit'">
        Log In <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
      </app-border-btn>
    </div>
  </form>

  <div class="login-form-terms">
    By logging in, you agree to our
    <app-link-btn [href]="termsLink">
      <ng-container ngProjectAs="link-content">
        Terms of Use & Privacy Policy.
      </ng-container>
    </app-link-btn>
  </div>
</div>
