import { ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { UiService } from '../core/ui.service';
import { SnackbarNotifierComponent } from './snackbar-notifier/snackbar-notifier.component';
import * as i0 from "@angular/core";
import * as i1 from "../core/ui.service";
export class AbstractNotifiersService {
    constructor(_uiService, componentFactoryResolver, appRef, injector) {
        this._uiService = _uiService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        this.isPopup = false;
    }
    showPopupNotifierMessage(msg, title) {
        this._uiService.showPopupMessage(msg, title);
    }
    showSnakbarNotifierMessage(m, showRefresh, t, info) {
        this.appendSnackbarNotifierComponentToBody(m, showRefresh, t, info);
    }
    /**
     * Snakbar notifier dynmic component
     */
    appendSnackbarNotifierComponentToBody(message, showRefresh, title, info) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SnackbarNotifierComponent);
        const componentRef = componentFactory.create(this.injector);
        this.appRef.attachView(componentRef.hostView);
        // componentRef.instance.onClose.subscribe(() => {
        //   this.removeSnackbarNotifierComponentFromBody();
        // });
        componentRef.instance.refresh = showRefresh;
        componentRef.instance.message = message;
        componentRef.instance.title = title;
        componentRef.instance.info = info;
        const domElem = componentRef.hostView
            .rootNodes[0];
        document.body.appendChild(domElem);
        this.snackbarNotifierComponentRef = componentRef;
    }
    removeSnackbarNotifierComponentFromBody() {
        this.appRef.detachView(this.snackbarNotifierComponentRef.hostView);
        this.snackbarNotifierComponentRef.destroy();
    }
}
AbstractNotifiersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AbstractNotifiersService_Factory() { return new AbstractNotifiersService(i0.ɵɵinject(i1.UiService), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.INJECTOR)); }, token: AbstractNotifiersService, providedIn: "root" });
