<div
  class="btn-group"
  dropdown
  [dropup]="true"
  [isOpen]="isMenuOpen"
  (isOpenChange)="toggleMenuEvent($event)"
>
  <div dropdownToggle type="button" (click)="resetEvent()">
    <i
      [class]="className"
      [class.open]="isMenuOpen"
      [class.fa]="true"
      aria-hidden="true"
    ></i>
  </div>
  <ul *dropdownMenu class="dropdown-menu dropdown" role="menu">
    <li role="menuitem" class="menuitem">
      <label for="image-upload" class="icon">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-photo"></use>
        </svg>
        <span class="tooltiptext">
          Image
          <svg class="svg-icon icon-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-photo"></use>
          </svg>
        </span>
      </label>
      <input
        #imageInputFile
        id="image-upload"
        type="file"
        accept="image/*"
        (change)="filesSelectedEvent()"
      />
    </li>

    <li role="menuitem" class="menuitem">
      <label for="gif-upload" class="icon">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-gif"></use>
        </svg>

        <span class="tooltiptext">
          GIF
          <svg class="svg-icon icon-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-gif"></use>
          </svg>
        </span>
      </label>
      <input
        #gifInputFile
        id="gif-upload"
        type="file"
        accept=".gif"
        (change)="filesSelectedEvent()"
      />
    </li>

    <li role="menuitem" class="menuitem">
      <label for="video-upload" class="icon">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-video"></use>
        </svg>
        <span class="tooltiptext">
          Video
          <svg class="svg-icon icon-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-video"></use>
          </svg>
        </span>
      </label>
      <input
        #videoInputFile
        id="video-upload"
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        (change)="filesSelectedEvent()"
      />
    </li>

    <li role="menuitem" class="menuitem">
      <label for="audio-upload" class="icon">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-audio"></use>
        </svg>
        <span class="tooltiptext">
          Audio
          <svg class="svg-icon icon-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-audio"></use>
          </svg>
        </span>
      </label>
      <input
        #audioInputFile
        id="audio-upload"
        type="file"
        accept="audio/*"
        (change)="filesSelectedEvent()"
      />
    </li>

    <li role="menuitem" class="menuitem">
      <label for="document-upload" class="icon">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-file"></use>
        </svg>
        <span class="tooltiptext icon-icon">
          Document
          <svg class="svg-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-file"></use>
          </svg>
        </span>
      </label>
      <input
        #documentInputFile
        id="document-upload"
        type="file"
        (change)="filesSelectedEvent()"
      />
    </li>
  </ul>
</div>
