import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BillingUiDispatchers } from 'src/app/store/billing/billingUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { AddCredit } from 'src/models/billings';
// import { BillingsPageScreens } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class BillingsService implements CanActivate {
  private _lastScreen = new BehaviorSubject<string>('');
  public lastScreen$ = this._lastScreen.asObservable();
  public _currentSelectedPlan = new BehaviorSubject<string>('');
  public currentSelectedPlan$ = this._currentSelectedPlan.asObservable();
  private _confirmSubscribtion = new BehaviorSubject<any>('');
  public confirmSubscribtion$ = this._confirmSubscribtion.asObservable();

  constructor(
    private _selectors: RootStoreSelectors,
    private router: Router,
    private _billingUiDispatchers: BillingUiDispatchers
  ) {}

  setCurrentScreen(screen: string, lastScreen?: string) {
    this._billingUiDispatchers.setCurrentScreen(screen, lastScreen);
  }

  setCurrentSelectedPlan(plan: any) {
    this._billingUiDispatchers.setCurrentSelectedPlan(plan);
  }

  toggleConfirmPopup(data) {
    this._confirmSubscribtion.next(data);
    this._billingUiDispatchers.receveCouponResponse(null);
  }

  sendSubscribetionToPlan(subscribeDetails: AddCredit, lm_data?: any) {
    this._billingUiDispatchers.sendSubscribetionToPlan(
      subscribeDetails,
      lm_data
    );
  }

  sendCouponToServer(coupon: string) {
    this._billingUiDispatchers.sendCoupon(coupon);
  }

  addNewCreditCard(token: AddCredit) {
    this._billingUiDispatchers.addNewCreditCard(token);
  }

  deleteCard(card_id: string) {
    this._billingUiDispatchers.deleteCreditCardUI(card_id);
  }

  setDefaultCreditCard(card_id: string) {
    this._billingUiDispatchers.setDefaultCardUI(card_id);
  }

  receveStripeErrorMasage(msg: string) {
    this._billingUiDispatchers.receveStripeErrorMasage(msg);
  }

  setBillingStatus(status: string) {
    this._billingUiDispatchers.setBillingStatus(status);
  }

  subscribeToAddonsRequest(plan_id: string, coupon?: string) {
    this._billingUiDispatchers.subscribeToAddonsRequest(plan_id, coupon);
  }

  getBillingInfoUI() {
    this._billingUiDispatchers.getBillingInfoUI();
  }

  getInvoicesListUI() {
    this._billingUiDispatchers.getInvoicesListUI();
  }

  changePlanRequest(new_plan_id: string, coupon?: string) {
    this._billingUiDispatchers.changePlanRequest(new_plan_id, coupon);
  }

  cancelStripeSubsCription(
    all: boolean,
    plan_id?: string,
    reason?: string,
    subMain?: boolean,
    cancelQuantity?: number
  ) {
    this._billingUiDispatchers.cancelStripeSubsCription(
      all,
      plan_id,
      reason,
      subMain,
      cancelQuantity
    );
  }

  setBillingPeriodStatus(status: boolean) {
    this._billingUiDispatchers.setBillingPeriodStatus(status);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let planId = '';
    this.currentSelecetedPlan$.subscribe(res => (planId = res));
    if (
      !planId ||
      planId === 'plan_4' ||
      planId === 'plan_1' ||
      planId === 'plan_2' ||
      planId === 'plan_3'
    ) {
      this.router.navigate(['/billings/plans']);
      return false;
    } else {
      return true;
    }
  }

  revokeCancelMainPlan() {
    this._billingUiDispatchers.revokeCancelMainPlan();
  }

  get getCurrentScreen$() {
    return this._selectors.currentSelecetedBillingScreen$;
  }

  get getcurrentSelecetedPlan$() {
    return this._selectors.currentSelecetedBillingPlan$;
  }

  get getStripeErrorMessage$() {
    return this._selectors.getStripeErrorMessage$;
  }
  get getBillingStatus$() {
    return this._selectors.getBillingStatus$;
  }
  get getBillingCoupon$() {
    return this._selectors.getBillingCoupon$;
  }
  get getBillingInvoices$() {
    return this._selectors.getBillingInvoices$;
  }
  get getBillingInfo$() {
    return this._selectors.getBillingInfo$;
  }
  get getBillingMainPlan$() {
    return this._selectors.getBillingMainPlan$;
  }
  get currentSelecetedPlan$() {
    return this._selectors.currentSelecetedPlan$;
  }
  get getBillingPeriodStatus$() {
    return this._selectors.getBillingPeriodStatus$;
  }
  get getBillingSubscriptions$() {
    return this._selectors.getBillingSubscriptions$;
  }

  get isMainPlanCanceled$() {
    return this._selectors.isMainPlanCanceled$;
  }
  get getBillingsEndTriles$() {
    return this._selectors.getBillingsEndTriles$;
  }
  get getMainPlanStatus$() {
    return this._selectors.getMainPlanStatus$;
  }
}
