import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as VirtualAppUIActions from './virtualAppsUI.actions';

import { IChat } from 'src/models/IChat';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class VirtualAppUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  listVirtualAppUI() {
    this._store.dispatch(new VirtualAppUIActions.ListVirtualAppUI());
  }
  createVirtualAppUI() {
    this._store.dispatch(new VirtualAppUIActions.CreateVirtualAppUI());
  }
  viewVirtualAppUI(selectedVirtualApp: IChat) {
    this._store.dispatch(
      new VirtualAppUIActions.ViewVirtualAppUI(selectedVirtualApp)
    );
  }
  editVirtualAppUI(selectedVirtualApp: IChat) {
    this._store.dispatch(
      new VirtualAppUIActions.EditVirtualAppUI(selectedVirtualApp)
    );
  }

  uploadVirtualAppImage(selectedFile) {
    this._store.dispatch(
      new VirtualAppUIActions.UploadVirtualAppImage(selectedFile)
    );
  }
  virtualAppPhotoUploadUI(createdVirtualApp: IChat) {
    this._store.dispatch(
      new VirtualAppUIActions.VirtualAppPhotoUploadUI(createdVirtualApp)
    );
  }
  virtualAppCreationComplete() {
    this._store.dispatch(new VirtualAppUIActions.VirtualAppCreationComplete());
  }

  closeViewVirtualAppUI() {
    this._store.dispatch(new VirtualAppUIActions.CloseViewVirtualAppUI());
  }
  closeEditVirtualAppUI() {
    this._store.dispatch(new VirtualAppUIActions.CloseEditVirtualAppUI());
  }

  // paging dispatchers
  getNextVirtualAppPage() {
    this._store.dispatch(new VirtualAppUIActions.GetNextVirtualAppPage());
  }

  getPrevVirtualAppPage() {
    this._store.dispatch(new VirtualAppUIActions.GetPrevVirtualAppPage());
  }

  receivedVirtualAppHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChat[]
  ): void {
    this._store.dispatch(
      new VirtualAppUIActions.ReceivedVirtualAppHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  resetVirtualAppPaging() {
    this._store.dispatch(new VirtualAppUIActions.ResetVirtualAppPaging());
  }

  setStatusAsIdle() {
    this._store.dispatch(new VirtualAppUIActions.SetStatusAsIdle());
  }
}
