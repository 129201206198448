import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { FileService } from 'src/app/core/file.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { SetChat, GetSubChats } from 'src/models/IChat';
import { ERROR_NOT_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes, NOT_SUPPORTED_FILE_TYPE, NO_FILE_NAME, GROUP_UPLOAD_TYPE, PageDirection, CHANNEL } from 'src/models/constants';
import { VirtualAppUiDispatchers } from './virtualAppsUI.dispatchers';
import * as isEqual from 'lodash.isequal';
export class VirtualAppUiEffects {
    constructor(actions$, _chatDispatchers, _socketGateway, _uiDispatchers, _virtualAppUiDispatchers, _fileService, _uploadGateway, _store) {
        this.actions$ = actions$;
        this._chatDispatchers = _chatDispatchers;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this._virtualAppUiDispatchers = _virtualAppUiDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._store = _store;
        this.uploadVirtualAppImage = this.actions$.pipe(ofType("UPLOAD_VIRTUAL_APP_IMAGE" /* UPLOAD_VIRTUAL_APP_IMAGE */), withLatestFrom(this._store
            .select(state => state.virtualAppUiReducer.creationProcessSubChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.virtualAppUiReducer.selectedSubGroup)
            .pipe(distinctUntilChanged(isEqual))), map(([val, creationVirtualApp, updatedVirtualApp]) => {
            const action = val;
            if (this.validateFile(action.selectedFile, 'image')) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, GROUP_UPLOAD_TYPE, null, creationVirtualApp
                        ? creationVirtualApp.id
                        : updatedVirtualApp.id, true)
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            const chat = {};
                            const photo = { id: res.file };
                            chat.id = creationVirtualApp
                                ? creationVirtualApp.id
                                : updatedVirtualApp.id;
                            chat.photo = photo;
                            this._socketGateway.sendSocketMessage(new SetChat(chat));
                        }
                    }, error => {
                        console.log('Failed to upload Virtual app image');
                    });
                });
            }
        }));
        this.afterVirtualAppCreation = this.actions$.pipe(ofType("UI_UPLOAD_VIRTUAL_APP_PHOTO" /* UI_UPLOAD_VIRTUAL_APP_PHOTO */), map((action) => {
            this._chatDispatchers.chatInvalidated(action.createdVirtualApp.id);
        }));
        this.getNextVirtualAppPage = this.actions$.pipe(ofType("UI_VIRTUAL_APP_GET_NEXT_PAGE" /* UI_VIRTUAL_APP_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.virtualAppUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, virtualAppUiState]) => {
            const action = val;
            const prevRequest = virtualAppUiState.previousRequest;
            if (!(virtualAppUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                virtualAppUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubChats([CHANNEL], 0, virtualAppUiState.hash, virtualAppUiState.eop, null, null, 1));
            }
            else {
                this._virtualAppUiDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevVirtualAppUiStatePage = this.actions$.pipe(ofType("UI_VIRTUAL_APP_GET_PREV_PAGE" /* UI_VIRTUAL_APP_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.virtualAppUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, virtualAppUiState]) => {
            const action = val;
            const prevRequest = virtualAppUiState.previousRequest;
            if (!(virtualAppUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                virtualAppUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubChats([CHANNEL], 1, virtualAppUiState.hash, virtualAppUiState.sop, null, null, 1));
            }
            else {
                this._virtualAppUiDispatchers.setStatusAsIdle();
            }
        }));
        this.afterVirtualAppSectionSelection = this.actions$.pipe(ofType("UI_LIST_VIRTUAL_APP" /* UI_LIST_VIRTUAL_APP */), map((action) => {
            this._virtualAppUiDispatchers.resetVirtualAppPaging();
        }));
        this.afterSubChatResetPaging = this.actions$.pipe(ofType("UI_VIRTUAL_APP_RESET_PAGING" /* UI_VIRTUAL_APP_RESET_PAGING */), map((action) => {
            this._virtualAppUiDispatchers.getNextVirtualAppPage();
        }));
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "uploadVirtualAppImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "afterVirtualAppCreation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "getNextVirtualAppPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "getPrevVirtualAppUiStatePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "afterVirtualAppSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], VirtualAppUiEffects.prototype, "afterSubChatResetPaging", void 0);
