import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';

import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import { SubscriptionPriceTableComponent } from './subscription-price-table/subscription-price-table.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubscriptionConfirmComponent } from './subscription-confirm/subscription-confirm.component';
import { SubscriptionConfirmCancelComponent } from './subscription-confirm-cancel/subscription-confirm-cancel.component';

@NgModule({
  imports: [
    CommonModule,
    AbstractFramesModule,
    AbstractBtnsModule,
    SharedModule
  ],
  declarations: [
    SubscriptionModalComponent,
    SubscriptionPriceTableComponent,
    SubscriptionPlanComponent,
    SubscriptionConfirmComponent,
    SubscriptionConfirmCancelComponent
  ],
  entryComponents: [SubscriptionModalComponent]
})
export class NbxSubscriptionModule {}
