import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-snackbaar-error-notifier',
  templateUrl: './snackbaar-error-notifier.component.html',
  styleUrls: ['./snackbaar-error-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbaarErrorNotifierComponent implements OnInit {
  @Input() message: string;

  @Output() hidePopupNotify = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit() {}

  cancelEvent() {
    this.hidePopupNotify.emit(true);
  }
}
