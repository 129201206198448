import * as mobileStoreMethods from 'src/models/Stores';

export class MobileStoreMiddleware {
  public static isMobileStoreExist(
    list: mobileStoreMethods.MStore[],
    rcvdObj: mobileStoreMethods.MStore
  ) {
    for (let i = 0; i < list.length; i++) {
      if (rcvdObj.id && list[i].id === rcvdObj.id) {
        return true;
      }
    }
    return false;
  }
}
