import { Action } from '@ngrx/store';

export const enum RepliesEOPTypes {
  UPDATE_FIRST_DEPTH_EOP = 'UPDATE_FIRST_DEPTH_EOP',
  UPDATE_SECOND_DEPTH_EOP = 'UPDATE_SECOND_DEPTH_EOP'
}

// Reset part
export const RESET = 'RESET';
export class UpdateFirstDepthEOP implements Action {
  readonly type = RepliesEOPTypes.UPDATE_FIRST_DEPTH_EOP;
  constructor(
    public parentMsgID: string,
    public eopFirstDepth: number,
    public chatID: string
  ) {}
}

export class UpdateSecondDepthEOP implements Action {
  readonly type = RepliesEOPTypes.UPDATE_SECOND_DEPTH_EOP;
  constructor(
    public parentMsgID: string,
    public eopSecondDepth: number,
    public userID: string,
    public chatID: string
  ) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type RepliesEOPActions =
  | UpdateFirstDepthEOP
  | UpdateSecondDepthEOP
  | Reset;
