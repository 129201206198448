import { Store } from '@ngrx/store';
import * as MapStopTimeActions from './mapStopTime.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapStopTimeDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createMapStopTime(MapStopTime) {
        this._store.dispatch(new MapStopTimeActions.CreateMapStopTime(MapStopTime));
    }
    setMapStopTimes(tripID, MapStopTimes) {
        this._store.dispatch(new MapStopTimeActions.SetMapStopTimes(tripID, MapStopTimes));
    }
    createMapStopTimeReceived(MapStopTime) {
        this._store.dispatch(new MapStopTimeActions.CreateMapStopTimeReceived(MapStopTime));
    }
    MapStopTimesListReceived(MapStopTimes) {
        this._store.dispatch(new MapStopTimeActions.MapStopTimesListReceived(MapStopTimes));
    }
    getMapStopTime(trip_id) {
        this._store.dispatch(new MapStopTimeActions.GetMapStopTime(trip_id));
    }
    rest() {
        this._store.dispatch(new MapStopTimeActions.Rest());
    }
}
MapStopTimeDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapStopTimeDispatchers_Factory() { return new MapStopTimeDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapStopTimeDispatchers, providedIn: "root" });
