import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import { MapTripsNotifiersActionTypes } from './mapTripsNotifiers.actions';
import * as MapTripsNotifiersActions from './mapTripsNotifiers.actions';
import * as Methods from 'src/models/mapTripsNotifiers';
import { MapTripsNotifiersDispatchers } from './mapTripsNotifiers.dispatchers';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class MapTripsNotifiersEffects {
  @Effect({ dispatch: false })
  resetMapTripsNotifiersPaging = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING),
    map((action: MapTripsNotifiersActions.ResetMapTripsNotifiersPaging) => {
      this.mapTripsNotifiersDispatchers.getTripsNotifiers();
    })
  );

  @Effect({ dispatch: false })
  getTripsNotifiers = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_GET_TRIPS_NOTIFIERS),
    map((action: MapTripsNotifiersActions.GetTripsNotifiers) => {
      this._socketGateway.sendSocketMessage(
        new Methods.GetMapTripsNotifiers(0, '')
      );
    })
  );

  @Effect({ dispatch: false })
  createTripsNotifiers = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_CREATE_MAP_ACCOUNT_LINKS),
    map((action: MapTripsNotifiersActions.CreateMapAccountLinks) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapTripNotifiers(
          action.tripNotifier.account_id,
          action.tripNotifier.link_ids,
          'CreateAccountLinks'
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getNotifiersNextPage = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripsNotifiersReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsNotifiers]) => {
      const action = <MapTripsNotifiersActions.GetNextNotifiersPage>val;
      const prevRequest = mapTripsNotifiers.previousRequest;
      if (
        !(
          mapTripsNotifiers.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapTripsNotifiers.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetNotifiersList(
            mapTripsNotifiers.accountID,
            0,
            mapTripsNotifiers.eop
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  getNotifiersPrevPage = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripsNotifiersReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsNotifiers]) => {
      const action = <MapTripsNotifiersActions.GetPrevNotifiersPage>val;
      const prevRequest = mapTripsNotifiers.previousRequest;
      if (
        !(
          mapTripsNotifiers.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapTripsNotifiers.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetNotifiersList(
            mapTripsNotifiers.accountID,
            1,
            mapTripsNotifiers.sop
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  resetNotifiersPaging = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_RESET_PAGING),
    map((action: MapTripsNotifiersActions.ResetNotifiersListPaging) => {
      this.mapTripsNotifiersDispatchers.getNextNotifiersList();
    })
  );

  @Effect({ dispatch: false })
  updateMapAccountLinks = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_UPDATE_ACCOUNT_LINK_SUCCESS),
    map((action: MapTripsNotifiersActions.UpdateMapAccountLinks) => {
      this._socketGateway.sendSocketMessage(
        new Methods.UpdateMapTripNotifiers(
          action.accountID,
          action.link_ids,
          'setMapAccountLinks'
        )
      );
    })
  );

  @Effect({ dispatch: false })
  removeAccountLink = this.actions$.pipe(
    ofType(MapTripsNotifiersActionTypes.UI_REMOVE_ACCOUNT_LINK),
    map((action: MapTripsNotifiersActions.RemoveAccountLink) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveAccountLink(action.id, 'removeMapAccountLink')
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _socketGateway: SocketGateway,
    private mapTripsNotifiersDispatchers: MapTripsNotifiersDispatchers
  ) {}
}
