<div class="media-list">
  <ng-container></ng-container>

  <ng-container
    *ngIf="
      mediaCenterService.getMediaCenterItems$(
        selectedMediaType,
        selectedMediaType === messageTypes.PHOTO ? aspectRatio : null
      ) | async as medias
    "
  >
    <ng-container *ngIf="medias?.length > 0; else isEmpty">
      <!-- <div class="media-list-items"> -->
      <ngx-masonry #msornys>
        <ngxMasonryItem
          *ngFor="let item of medias; trackBy: TrackByFunction; let last = last"
          class="media-item"
          (click)="mediaCenterEvent(item)"
        >
          <!-- <app-delete-btn
              *ngIf="item?.filename"
              appCancelEvent
              delete
              class="delete-btn"
              (btnClicked)="mediaCenterDelete(item)"
            >
            </app-delete-btn> -->
          <img
            *ngIf="item?.filename"
            src="./assets/img/deletholiday.png"
            (click)="mediaCenterDelete(item)"
            class="delete-btn"
          />
          <app-media-center-photo
            *ngIf="
              item?.type === messageTypes.PHOTO ||
              item?.type === messageTypes.GIF
            "
            [url]="item?.url"
            [localMedia]="item?.url"
            [localThumbnail]="item?.localThumbnail"
            [height]="item?.height"
            [width]="item?.width"
            [mediaStatus]="item?.mediaStatus"
            [fileSize]="item?.size"
            [progress]="item?.loadingProgress"
          >
          </app-media-center-photo>
          <app-media-center-video
            *ngIf="item?.type === messageTypes.VIDEO"
            [url]="item?.url"
            [localMedia]="item?.url"
            [localThumbnail]="item?.localThumbnail"
            [height]="item?.height"
            [width]="item?.width"
            [mediaStatus]="item?.mediaStatus"
            [fileSize]="item?.size"
            [progress]="item?.loadingProgress"
          >
          </app-media-center-video>
          <app-media-center-audio
            *ngIf="item?.type === messageTypes.AUDIO"
            [url]="item?.url"
            [localMedia]="item?.url"
            [mediaStatus]="item?.mediaStatus"
            [fileSize]="item?.size"
            [fileName]="item?.name"
            [progress]="item?.loadingProgress"
          >
          </app-media-center-audio>
          <app-media-center-file
            *ngIf="item?.type === messageTypes.DOCUMENT"
            [url]="item?.url"
            [localMedia]="item?.url"
            [mediaStatus]="item?.mediaStatus"
            [fileSize]="item?.size"
            [fileName]="item?.name"
            [progress]="item?.loadingProgress"
          >
          </app-media-center-file>
          {{ last ? setMasonry(msornys) : '' }}
        </ngxMasonryItem>
      </ngx-masonry>
      <!-- </div> -->
    </ng-container>
  </ng-container>
</div>

<ng-template #isEmpty>
  <app-empty-item-frame
    [emptySymbol]="mediaCenterFet?.symbolImage"
    [emptyTitle]="mediaCenterFet?.emptyTitle"
    [emptyDesc]="mediaCenterFet?.emptyPoolDesc"
  >
  </app-empty-item-frame>
</ng-template>
