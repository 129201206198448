import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {
  ChannelAppScreens,
  MENU_BOTTON_STYLE,
  MENU_CELLS_CATEGORIES,
  MENU_CELLS_TYPE,
  UISections
} from 'src/models/constants';
import { BotMenuButton, BotMenuOption, BotMenuRow } from 'src/models/IBotMenu';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { MenuFormComponent } from '../menu-form/menu-form.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenusService } from '../menus.service';
import { AppMgmtService } from '../../app-mgmt/appMgmt.service';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { BotsService } from '../../bots/bots.service';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { AuthService } from 'src/app/core/auth.service';
import { StorageService } from 'src/app/core/storage.service';
import { UiService } from 'src/app/core/ui.service';
import { AppClass } from 'src/models/ChannelAppConfig';
import { LocalContainer, LocalItem } from 'src/models/ChannelAppLocalConfig';
import { MobileWorkFlowStoreService } from './../../mobile-workflow-store/mobile-workflow-store.service';
import { CollectionsService } from './../../collections/collections.service';

@Component({
  selector: 'app-menu-row-item',
  templateUrl: './menu-row-item.component.html',
  styleUrls: ['./menu-row-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuRowItemComponent implements OnInit, OnChanges {
  rowStyles = MENU_BOTTON_STYLE;
  menuCategories = MENU_CELLS_CATEGORIES;

  menuType = MENU_CELLS_TYPE;
  @Input() button = false;
  @Input() length: number;
  @Input() currentCategory;
  @Input() isApp = false;
  @Input() section: any;
  @Input() botMenuEdit: any;
  @Input() currentTabs: any[];
  @Input() currentPage: any;
  appScreen = ChannelAppScreens;
  mainMenu = true;
  @Input() menusAppClass: AppClass;
  @Input() selectedTab: any;
  @Input() menuStoreList: any;
  @Input() mobileStoreAppList: any[];
  @Input() selectedButtonTab: any;
  buttonId = '';
  optionId = '';
  form: FormGroup;
  selectedStyle: string;
  rowStyle: any[];
  rowStyle2: any[];
  selectedItem: any;
  uiSections = UISections;
  @Input() selectedStoreId: any;
  mainMenuComponet = new MenuFormComponent(
    this._fb,
    this._botMenusService,
    this.ref,
    this.appManagementService,
    this._uiDispatchers,
    this.botService,
    this.layoutService,
    this._fileService,
    this._uploadGateway,
    this.authService,
    this._localStorage,
    this.uiService,
    this.mobilewWorkflowService,
    this.collectionService
  );
  @Output() styleSelectedNotify = new EventEmitter<BotMenuButton>();
  constructor(
    public ref: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _botMenusService: MenusService,

    public appManagementService: AppMgmtService,
    private _uiDispatchers: UIDispatchers,
    private botService: BotsService,
    private layoutService: LayoutService,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private authService: AuthService,
    private _localStorage: StorageService,
    public uiService: UiService,
    public mobilewWorkflowService: MobileWorkFlowStoreService,
    public collectionService: CollectionsService
  ) {}
  ngOnChanges() {}

  ngOnInit() {
    if (this.isApp && this.section !== this.uiSections.MENUS) {
      if (this.currentCategory) {
        this.currentCategory = {};
        this.currentCategory.id = this.menuCategories.SEPARATOR;
      }

      this.rowStyle = [
        {
          key: this.rowStyles.CENTERIMG_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-title-and-text-box.webp',
          url: 'https://nandbox.com/wp-content/uploads/2022/12/yoga.webp',
          url_2: 'https://nandbox.com/wp-content/uploads/2022/12/yoga.webp',
          url_3: 'https://nandbox.com/wp-content/uploads/2022/12/yoga.webp',
          bg_color_1: '#fff0d7',
          bg_color_2: '#fff0d7',
          bg_color_3: '#fff0d7',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null,
          aspectRatio: '1x1-1x1-1x1'
        },
        {
          key: this.rowStyles.TOPIMG_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-bg-title-text-box.webp',
          url:
            'https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp',
          url_2:
            'https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp',
          url_3:
            'https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp',
          bg_color_3: '#D8ECFF',
          bg_color_1: '#D8ECFF',
          bg_color_2: '#D8ECFF',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null,
          aspectRatio: '3.8x1-1.8x1-1.2x1'
        },

        {
          key: this.rowStyles.WIDEBG_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-text-with-bg-full.webp',
          url: 'https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp',
          url_2:
            'https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp',
          url_3:
            'https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp',
          bg_color_3: null,
          bg_color_1: null,
          bg_color_2: null,
          text_color: '#FFFFFF',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null,
          aspectRatio: '1.9x1-1x1-0.6x1'
        },

        {
          key: this.rowStyles.TEXTONLY_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-text-box.webp',
          url: '',
          bg_color_3: '#E8E3F6',
          bg_color_1: '#E8E3F6',
          bg_color_2: '#E8E3F6',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },

        {
          key: this.rowStyles.BACK_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-title-no-icon-blue.webp',
          url: '',
          bg_color_3: '#8896bf',
          bg_color_1: '#5e8de4',
          bg_color_2: '#da5767',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },

        {
          key: this.rowStyles.SUBMITBASICICON,
          cat: this.menuCategories.SUBMIT,
          typ: this.menuType.SUBMIT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-submit-icon-white-La.webp',
          url: '',
          bg_color_3: '',
          bg_color_1: '',
          bg_color_2: '',
          length: 1,
          icon: 'ic_feeds_books_white_24_dp',
          text_color: '#5181a9',
          sub_label: null,
          icon_bg: '#5181a9',
          weight: 1,
          border_color: '#5181a9'
        },
        {
          key: this.rowStyles.SUBMITICONCOLOR,
          cat: this.menuCategories.SUBMIT,
          typ: this.menuType.SUBMIT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-submit-blue-La.webp',
          url: '',
          bg_color_3: '#2D546B',
          bg_color_1: '#2D546B',
          bg_color_2: '#2D546B',
          text_color: '#FFFFFF',
          length: 1,
          icon: 'ic_feeds_books_white_24_dp',
          icon_bg: '#FFFFFF',
          weight: 1,
          sub_label: null
        },
        {
          key: this.rowStyles.SUBMITCOLOR,
          cat: this.menuCategories.SUBMIT,
          typ: this.menuType.SUBMIT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-submit-blue-La-2.webp',
          url: '',
          bg_color_3: '#5181A9',
          bg_color_1: '#5181A9',
          bg_color_2: '#5181A9',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },
        {
          key: this.rowStyles.SUBMIT,
          cat: this.menuCategories.SUBMIT,
          typ: this.menuType.SUBMIT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-submit-white-La.webp',
          url: '',
          bg_color_3: '',
          bg_color_1: '',
          bg_color_2: '',
          text_color: '#5181a9',
          length: 1,
          sub_label: null,
          icon: null,
          icon_bg: '#5181a9',
          weight: 1,
          border_color: '#5181a9'
        },

        {
          key: this.rowStyles.INPUT,
          cat: this.menuCategories.INPUT,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-Label-head.webp',
          url: '',
          bg_color_3: '#8896bf',
          bg_color_1: '#5e8de4',
          bg_color_2: '#da5767',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },

        {
          key: this.rowStyles.PAYMENT,
          cat: this.menuCategories.OUTPUT,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-cost-box.webp',
          url: '',
          bg_color_3: '#4d5a9e',
          bg_color_1: '#4d5a9e',
          bg_color_2: '#4d5a9e',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },

        {
          key: this.rowStyles.COST,
          cat: this.menuCategories.OUTPUT,
          typ: this.menuType.OUTPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-total-cost-box.webp',
          url: '',
          bg_color_3: '#4d7891',
          bg_color_1: '#4d7891',
          bg_color_2: '#4d7891',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: 'put you desc. here',
          weight: 1,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.INFORMATION,
          cat: this.menuCategories.OUTPUT,
          typ: this.menuType.OUTPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-name-tag-with-cost-box.webp',
          url: '',
          bg_color_3: '#36afe5',
          bg_color_1: '#36afe5',
          bg_color_2: '#36afe5',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: 'put you desc. here',
          weight: 1,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.INPUTLABEL,
          cat: this.menuCategories.INPUT,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-Label-text.webp',
          url: '',
          bg_color_3: '#8896bf',
          bg_color_1: '#5e8de4',
          bg_color_2: '#da5767',
          text_color: '#000000',
          length: 1,
          sub_label: 'put you desc. here',
          weight: 1,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.LABEL,
          cat: this.menuCategories.INPUT,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-Label-empty.webp',
          url: '',
          bg_color_3: '#8896bf',
          bg_color_1: '#5e8de4',
          bg_color_2: '#da5767',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },

        {
          key: this.rowStyles.WIDELIFT_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-text-sport-title.webp',
          url: 'https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp',
          bg_color_3: '#f4e6f2',
          bg_color_1: '#f4e6f2',
          bg_color_2: '#f4e6f2',
          text_color: '#000000',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          aspectRatio: '1x1-1x1-1x1'
        },
        {
          key: this.rowStyles.SMALLHL_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-sport-title.webp',

          url: 'https://nandbox.com/wp-content/uploads/2022/12/fitness.webp',

          bg_color_3: '#D8ECFF',
          bg_color_1: '#D8ECFF',
          bg_color_2: '#D8ECFF',
          text_color: '#000000',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          aspectRatio: '1x1-1x1-1x1'
        },

        {
          key: this.rowStyles.EMPTY,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/02/empty-cell.webp',

          url: '',

          bg_color_3: '#d9e2e9',
          bg_color_1: '#d9e2e9',
          bg_color_2: '#d9e2e9',
          text_color: '#000000',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.EMPTYWITHTITLE,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/02/empty-cell-title.webp',

          url: '',

          bg_color_3: '#d9e2e9',
          bg_color_1: '#d9e2e9',
          bg_color_2: '#d9e2e9',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.EMPTYWITHTITLEANDBOARDER,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/02/empty-cell-title-border.webp',

          url: '',

          bg_color_3: '#d9e2e9',
          bg_color_1: '#d9e2e9',
          bg_color_2: '#d9e2e9',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/02/empty-cell-bottom-border.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.LEFTTITLE,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/03/left-title-bar.webp',

          url: '',

          bg_color_3: '#d9e2e9',
          bg_color_1: '#d9e2e9',
          bg_color_2: '#d9e2e9',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null
        },

        {
          key: this.rowStyles.LEFTTILEBORDER,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/03/left-title-border-bar.webp',

          url: '',

          bg_color_3: '#d9e2e9',
          bg_color_1: '#d9e2e9',
          bg_color_2: '#d9e2e9',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.LEFTTITLEBOTTOMBORDER,
          cat: this.menuCategories.SEPARATOR,
          typ: this.menuType.BAR,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/03/left-title-bottom-border-bar.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 1,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SINGLECHOICE,
          cat: this.menuCategories.SINGLE_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/06/elements-2.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SINGLECHOICEICON,
          cat: this.menuCategories.SINGLE_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/06/elements-3.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: '#000000',
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SINGLECHOICEIMG,
          cat: this.menuCategories.SINGLE_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/06/elements-1.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.MULTICHOICE,
          cat: this.menuCategories.MULTI_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/06/Single-Choice-1.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.MULTICHOICEICON,
          cat: this.menuCategories.MULTI_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/06/Single-Choice-2.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: '#000000',
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.MULTICHOICEIMG,
          cat: this.menuCategories.MULTI_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/06/Single-Choice-3.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.TOGGLECHOICE,
          cat: this.menuCategories.MULTI_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/06/Single-Choice-tg3.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.TOGGLECHOICEICON,
          cat: this.menuCategories.MULTI_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/06/Single-Choice-tg1.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: '#000000',
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.FULLROWCIRCLE,
          cat: this.menuCategories.COLLECTION,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/09/store-3-img.png',

          url: 'https://nandbox.com/wp-content/uploads/2023/09/sw_img.png',
          url_2: 'https://nandbox.com/wp-content/uploads/2023/09/divimg.png',
          url_3:
            'https://nandbox.com/wp-content/uploads/2023/09/skincareimg.png',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 3,
          weight: 4,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.HALFROWSQUAREWITHBKGD,
          cat: this.menuCategories.COLLECTION,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/09/store-2-col-img.png',

          url: 'https://nandbox.com/wp-content/uploads/2023/09/sw_img.png',
          url_2: 'https://nandbox.com/wp-content/uploads/2023/09/trav-img.png',

          bg_color_3: '#f9dfd4',
          bg_color_1: '#d1effd',
          bg_color_2: '#f9dfd4',
          text_color: '#2d546b',
          length: 2,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#f9dfd4'
        },
        {
          key: this.rowStyles.HALFROWSQUAREWITHOUTTEXT,
          cat: this.menuCategories.COLLECTION,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/09/store-2-img.png',

          url: 'https://nandbox.com/wp-content/uploads/2023/09/light-img.png',
          url_2: 'https://nandbox.com/wp-content/uploads/2023/09/room-img.png',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 2,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.HALFROWSQUAREWITHBKGDNOTEXT,
          cat: this.menuCategories.COLLECTION,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/09/store-2-bg-img.png',

          url: 'https://nandbox.com/wp-content/uploads/2023/09/skincareimg.png',
          url_2: 'https://nandbox.com/wp-content/uploads/2023/09/skin-img.png',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 2,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },

        // {
        //   key: this.rowStyles.SQUAREWITHNOBKGDNOTEXT,
        //   cat: this.menuCategories.COLLECTION,
        //   typ: this.menuType.INPUT,
        //   name: 'Displays One Button',
        //   img:
        //     'https://nandbox.com/wp-content/uploads/2023/09/store-1-bg-img.png',

        //   url: 'https://nandbox.com/wp-content/uploads/2023/09/acc.png',

        //   bg_color_3: '#ffffff',
        //   bg_color_1: '#ffffff',
        //   bg_color_2: '#ffffff',
        //   text_color: '#2d546b',
        //   length: 1,
        //   weight: 3,
        //   sub_label: null,
        //   icon: null,
        //   icon_bg: null,
        //   border_color: '#2d546b'
        // },
        {
          key: this.rowStyles.SQUAREWITHNOBKGDTEXT,
          cat: this.menuCategories.COLLECTION,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/09/store-1-img.png',

          url: 'https://nandbox.com/wp-content/uploads/2023/09/play.png',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SELECTCHOICETITLE,
          cat: this.menuCategories.SELECT_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2023/11/box-with-cb.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SELECTCHOICEFANCYTILE,
          cat: this.menuCategories.SELECT_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/11/box-nb.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: '#000000',
          border_color: '#2d546b'
        },
        {
          key: this.rowStyles.SELECTCHOICE,
          cat: this.menuCategories.SELECT_CHOICE,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img: 'https://nandbox.com/wp-content/uploads/2023/11/box-with-b.webp',

          url: '',

          bg_color_3: '#ffffff',
          bg_color_1: '#ffffff',
          bg_color_2: '#ffffff',
          text_color: '#2d546b',
          length: 1,
          weight: 3,
          sub_label: null,
          icon: null,
          icon_bg: null,
          border_color: '#2d546b'
        }
      ];
    } else {
      this.rowStyle = [
        {
          key: this.rowStyles.TEXTONLY_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.BUTTON,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-text-box.webp',
          url: '',
          bg_color_3: '#E8E3F6',
          bg_color_1: '#E8E3F6',
          bg_color_2: '#E8E3F6',
          text_color: '#000000',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        },
        {
          key: this.rowStyles.BACK_NEW,
          cat: this.menuCategories.BUTTON,
          typ: this.menuType.INPUT,
          name: 'Displays One Button',
          img:
            'https://nandbox.com/wp-content/uploads/2022/12/nandbox-title-no-icon-blue.webp',
          url: '',
          bg_color_3: '#8896bf',
          bg_color_1: '#5e8de4',
          bg_color_2: '#da5767',
          text_color: '#FFFFFF',
          length: 1,
          sub_label: null,
          icon: null,
          weight: 1,
          icon_bg: null
        }
      ];
    }
  }
  selectStyle(item) {
    let title = '';
    if (item === this.menuCategories.SUBMIT) {
      title = 'Submit';
    } else {
      title = 'Title';
    }

    const defaultBtn: BotMenuButton = {
      button_label: title,
      button_textcolor: item.text_color,
      button_icon: null,
      button_icon_bgcolor: '#FFFFFF',
      button_bgcolor: item.bg_color_1,
      button_description: 'Your description.',
      button_style: item.key,
      button_img_url: item.url,
      url_2: item.url_2,
      url_3: item.url_3,
      bg_color_2: item.bg_color_2,
      bg_color_3: item.bg_color_3,
      length: item.length
    };
    this.selectedItem = item;
    this.selectedStyle = item.key;
    this.styleSelectedNotify.emit(defaultBtn);
  }

  drag(event, feature) {
    let data: any;
    data =
      feature.key +
      ',' +
      feature.name +
      ',' +
      feature.img +
      ',' +
      feature.url +
      ',' +
      feature.bg_color_3 +
      ',' +
      feature.bg_color_1 +
      ',' +
      feature.bg_color_2 +
      ',' +
      feature.text_color +
      ',' +
      feature.length +
      ',' +
      feature.url_2 +
      ',' +
      feature.url_3 +
      ',' +
      feature.cat +
      ',' +
      feature.typ +
      ',' +
      feature.sub_label +
      ',' +
      feature.icon +
      ',' +
      feature.icon_bg +
      ',' +
      feature.border_color +
      ',' +
      feature.weight +
      ',' +
      feature.aspectRatio;

    event.dataTransfer.setData('text', data);
  }

  setFormGroup() {
    let id = null;

    if (
      this.mainMenu &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      this.section !== this.uiSections.MENUS
    ) {
      id = this.menusAppClass.menus.length + 1;
      id = 'menu' + id;
    } else if (this.mainMenu && this.section !== this.uiSections.MENUS) {
      id = 1;
      id = 'menu' + id;
    }

    let apiId = null;
    if (this.selectedTab && this.selectedTab.api_id) {
      apiId = this.selectedTab.api_id;
    }

    return this._fb.group({
      id: [this.botMenuEdit ? this.botMenuEdit.id : null],
      menu_id: [
        this.botMenuEdit && this.botMenuEdit.menu_id
          ? this.botMenuEdit.menu_id
          : id
      ],
      menu_ref: [
        this.botMenuEdit && this.botMenuEdit.menu_id
          ? this.botMenuEdit.menu_id
          : id
      ],
      menu_date: [this.botMenuEdit ? this.botMenuEdit.menu_date : null],
      menu_name: [
        this.botMenuEdit ? this.botMenuEdit.menu_name : null,
        [Validators.required]
      ],
      next_menu: [this.botMenuEdit ? this.botMenuEdit.next_menu : null],
      api_id: [
        this.botMenuEdit && this.botMenuEdit.api_id
          ? this.botMenuEdit.api_id
          : apiId
      ],
      rows: this.botMenuEdit
        ? this.setRows(this.botMenuEdit.rows)
        : new FormArray([])
    });
  }
  setRows(rows) {
    const botRows = [];

    if (rows && rows.length > 0 && rows[0]) {
      for (let row = 0; row < rows.length; row++) {
        const button = rows[row];

        botRows.push(this.setRow(button, row));
      }
    } else if (rows && rows.value > 0 && rows.value[0]) {
      for (let row = 0; row < rows.length; row++) {
        const button = rows.value[row];

        botRows.push(this.setRow(button, row));
      }
    }
    return this._fb.array(botRows);
  }
  setRow(row: BotMenuRow, rowIndex?: number) {
    return this._fb.group({
      row_id: [row.row_id ? row.row_id : null],
      row_order: [row.row_order ? row.row_order : null],
      buttons: this.setButtons(row.buttons, rowIndex)
    });
  }

  setButtons(cols: BotMenuButton[], rowIndex?: number) {
    const buttons = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const button = cols[col];
        buttons.push(this.setButton(button, rowIndex, col));
      }
    }
    return this._fb.array(buttons);
  }

  setButton(button: BotMenuButton, rowIndex?: number, colIndex?: number) {
    // if (
    //   this.form &&
    //   this.form.get('rows').value &&
    //   this.form.get('rows').value[rowIndex]
    // ) {
    //   this.buttonId = this.form.get('rows').value[rowIndex].buttons[colIndex]
    //     .length
    //     ? this.form.get('rows').value[rowIndex].buttons[colIndex].length + 1
    //     : 1;
    // } else {
    //   this.buttonId = 1;
    // }
    let db = 1;
    if (button.button_db == 0) {
      db = 0;
    }
    this.buttonId = Math.random()
      .toString(36)
      .substr(2, 9);
    const buttonIdStr = 'button' + this.buttonId;
    let menuId = null;
    if (this.form) {
      menuId = this.form.get('menu_id').value;
    }
    let subLabel = null;
    if (button && button.button_sublabel) {
      subLabel = button.button_sublabel;
    }
    let newpage = true;
    if (button && !button.button_newpage && button.button_newpage !== false) {
      newpage = true;
    } else {
      newpage = button.button_newpage;
    }
    if (button.button_form === this.menuCategories.SUBMIT) {
      return this._fb.group({
        button_id: [button.button_id ? button.button_id : buttonIdStr],
        next_menu: [button.next_menu ? button.next_menu : null],
        button_next_page: [
          button.button_next_page ? button.button_next_page : null
        ],
        button_span: [button.button_span ? button.button_span : null],
        button_order: [button.button_order ? button.button_order : null],
        button_textcolor: [
          button.button_textcolor ? button.button_textcolor : null
        ],
        button_icon: [button.button_icon ? button.button_icon : null],
        button_icon_bgcolor: [
          button.button_icon_bgcolor ? button.button_icon_bgcolor : null
        ],
        button_callback: [button.button_id ? button.button_id : buttonIdStr],
        button_bgcolor: [button.button_bgcolor ? button.button_bgcolor : null],
        button_label: [button.button_label ? button.button_label : null],
        button_sublabel: [subLabel],
        button_value: [button.button_value ? button.button_value : []],
        button_keyboard: [
          button.button_keyboard ? button.button_keyboard : 'text'
        ],
        button_url: [button.button_url ? button.button_url : null],
        button_query: [button.button_query ? button.button_query : null],
        button_style: [button.button_style ? button.button_style : null],
        button_description: [
          button.button_description ? button.button_description : null
        ],
        next_menu_id: [button.next_menu_id ? button.next_menu_id : null],
        button_img_url: [button.button_img_url ? button.button_img_url : null],
        button_form: [button.button_form ? button.button_form : null],
        button_type: [button.button_type ? button.button_type : null],
        button_border_color: [
          button.button_border_color ? button.button_border_color : null
        ],
        button_asbect_ratio: [
          button.button_asbect_ratio ? button.button_asbect_ratio : null
        ],
        button_option_color: [
          button.button_option_color ? button.button_option_color : null
        ],
        button_option: this.setOptions(button.button_option, colIndex),
        button_newpage: [newpage],
        button_db: [db],
        button_label_color: [
          button.button_label_color
            ? button.button_label_color
            : button.button_textcolor
        ],
        button_sublabel_color: [
          button.button_sublabel_color
            ? button.button_sublabel_color
            : button.button_textcolor
        ]
      });
    } else {
      return this._fb.group({
        button_id: [button.button_id ? button.button_id : buttonIdStr],
        next_menu: [button.next_menu ? button.next_menu : null],
        button_next_page: [
          button.button_next_page ? button.button_next_page : null
        ],
        button_span: [button.button_span ? button.button_span : null],
        button_order: [button.button_order ? button.button_order : null],
        button_textcolor: [
          button.button_textcolor ? button.button_textcolor : null
        ],
        button_icon: [button.button_icon ? button.button_icon : null],
        button_icon_bgcolor: [
          button.button_icon_bgcolor ? button.button_icon_bgcolor : null
        ],
        button_callback: [button.button_id ? button.button_id : buttonIdStr],
        button_bgcolor: [button.button_bgcolor ? button.button_bgcolor : null],
        button_label: [button.button_label ? button.button_label : null],
        button_sublabel: [subLabel],
        button_value: [button.button_value ? button.button_value : []],
        button_keyboard: [
          button.button_keyboard ? button.button_keyboard : 'text'
        ],
        button_url: [button.button_url ? button.button_url : null],
        button_query: [button.button_query ? button.button_query : null],
        button_style: [button.button_style ? button.button_style : null],
        button_description: [
          button.button_description ? button.button_description : null
        ],
        next_menu_id: [button.next_menu_id ? button.next_menu_id : null],
        button_img_url: [button.button_img_url ? button.button_img_url : null],
        button_form: [button.button_form ? button.button_form : null],
        button_type: [button.button_type ? button.button_type : null],
        button_border_color: [
          button.button_border_color ? button.button_border_color : null
        ],
        button_asbect_ratio: [
          button.button_asbect_ratio ? button.button_asbect_ratio : null
        ],
        button_option_color: [
          button.button_option_color ? button.button_option_color : null
        ],
        button_option: this.setOptions(button.button_option, colIndex),
        button_newpage: [newpage],
        button_label_color: [
          button.button_label_color
            ? button.button_label_color
            : button.button_textcolor
        ],
        button_sublabel_color: [
          button.button_sublabel_color
            ? button.button_sublabel_color
            : button.button_textcolor
        ]
      });
    }
  }

  setOptions(cols: BotMenuOption[], colIndex?: number) {
    const options = [];
    if (cols && cols.length > 0) {
      for (let col = 0; col < cols.length; col++) {
        const option = cols[col];
        options.push(this.setOption(option, colIndex, col));
      }
    }
    return this._fb.array(options);
  }

  setOption(option: BotMenuOption, rowIndex?: number, colIndex?: number) {
    this.optionId = Math.random()
      .toString(36)
      .substr(2, 9);
    const optionIdStr = 'ID-' + this.optionId;
    return this._fb.group({
      id: [option.id ? option.id : optionIdStr],
      label: [option.label ? option.label : null],
      sublabel: [option.sublabel ? option.sublabel : null],
      image: [option.image ? option.image : null],
      icon: [option.icon ? option.icon : null]
    });
  }

  onClickMenuItem(featureData) {
    this.form = this.setFormGroup();
    let data: any;
    data =
      featureData.key +
      ',' +
      featureData.name +
      ',' +
      featureData.img +
      ',' +
      featureData.url +
      ',' +
      featureData.bg_color_3 +
      ',' +
      featureData.bg_color_1 +
      ',' +
      featureData.bg_color_2 +
      ',' +
      featureData.text_color +
      ',' +
      featureData.length +
      ',' +
      featureData.url_2 +
      ',' +
      featureData.url_3 +
      ',' +
      featureData.cat +
      ',' +
      featureData.typ +
      ',' +
      featureData.sub_label +
      ',' +
      featureData.icon +
      ',' +
      featureData.icon_bg +
      ',' +
      featureData.border_color +
      ',' +
      featureData.weight +
      ',' +
      featureData.aspectRatio;
    let btnId;
    let row;
    let col;

    if (
      this.botMenuEdit &&
      this.botMenuEdit !== 'null' &&
      this.botMenuEdit !== null &&
      this.botMenuEdit.rows
    ) {
      for (let i = 0; this.botMenuEdit.rows.length > i; i++) {
        for (let j = 0; this.botMenuEdit.rows[i].buttons.length > j; j++) {
          if (this.botMenuEdit.rows[i].buttons[j].button_style === null) {
            if (!btnId) {
              btnId = this.botMenuEdit.rows[i].buttons[j].button_id;
              row = i;
              col = j;
            }
          }
        }
      }
    }
    if (btnId) {
      data = data + ',' + row + ',' + col + ',' + btnId;
      this.addToButton(data);
    } else {
      this.addToRow(data);
    }
    // this.appManagementService._scrollDashBoard.next(true);
  }

  addToRow(data) {
    const feature = [];
    data.split(',').forEach(feat => {
      if (feat === 'null' || feat === 'undefined') {
        feature.push(null);
      } else {
        feature.push(feat);
      }
    });

    // const feature = data.split(',');
    const item: any = {};
    let title = '';

    if (feature[11] === this.menuCategories.SUBMIT) {
      title = 'Submit';
    } else if (
      feature[11] === this.menuCategories.SEPARATOR &&
      feature[0] === this.rowStyles.EMPTY
    ) {
      title = null;
    } else {
      title = 'Title';
    }

    if (feature.length > 1) {
      item.button_label = title;
      item.button_textcolor = feature[7];
      item.button_label_color = feature[7];
      item.button_sublabel_color = feature[7];
      item.button_bgcolor = feature[5];
      item.button_description = 'Your description.';
      item.button_style = feature[0];
      item.button_img_url = feature[3];
      item.url_2 = feature[9];
      item.url_3 = feature[10];
      item.url = feature[3];
      item.bg_color_2 = feature[6];
      item.bg_color_3 = feature[4];
      item.length = feature[8];
      item.button_form = feature[11];
      item.button_type = feature[12];
      item.button_sublabel = 'Your description.';
      if (feature[13]) {
        item.button_sublabel = feature[13];
      }
      if (feature[14]) {
        item.button_icon = feature[14];
      }
      if (feature[15]) {
        item.button_icon_bgcolor = feature[15];
      }
      if (feature[16]) {
        item.button_border_color = feature[16];
      }
      const newObj: any = {};
      newObj.id = null;
      newObj.value = '20$';
      const valueList = [];
      valueList.push(newObj);
      item.button_value = null;

      item.button_keyboard = 'text';

      if (feature[18] !== null && feature[18]) {
        item.aspectRatio = feature[18].split('-');
      }
      if (
        this.section !== this.uiSections.MENUS &&
        this.section !== this.uiSections.MOBILESTORE
      ) {
        let bundle = false;
        let tab = {};
        if (
          this.selectedTab &&
          this.selectedTab.sub_type &&
          !this.selectedButtonTab
        ) {
          bundle = true;
          tab = this.selectedTab;
        } else if (this.selectedButtonTab && this.selectedButtonTab.sub_type) {
          bundle = true;
          tab = this.selectedButtonTab;
        }

        this.mainMenuComponet.addNewRowOnClick(
          item,
          tab,
          this.form,
          true,
          this.menusAppClass,
          this.currentTabs,
          this.appManagementService,
          true,
          this.mobilewWorkflowService,
          this.menuStoreList,
          this.mobileStoreAppList,
          this.selectedStoreId,
          bundle
        );
      } else if (this.section === this.uiSections.MENUS) {
        this.mainMenuComponet.addNewRowOnClick(
          item,
          undefined,
          this.form,
          false,
          this.menusAppClass,
          this.currentTabs,
          this.appManagementService,
          false
        );
      } else if (this.section === this.uiSections.MOBILESTORE) {
        this.mainMenuComponet.addNewRowOnClick(
          item,
          undefined,
          this.form,
          false,
          this.menusAppClass,
          this.currentTabs,
          this.appManagementService,
          false,
          this.mobilewWorkflowService,
          this.menuStoreList,
          this.mobileStoreAppList,
          this.selectedStoreId,
          true
        );
      }
    }
  }

  addToButton(data) {
    if (
      this.section !== this.uiSections.MENUS &&
      this.section !== this.uiSections.MOBILESTORE
    ) {
      let bundle = false;
      let tab = {};
      if (
        this.selectedTab &&
        this.selectedTab.sub_type &&
        !this.selectedButtonTab
      ) {
        bundle = true;
        tab = this.selectedTab;
      } else if (this.selectedButtonTab && this.selectedButtonTab.sub_type) {
        bundle = true;
        tab = this.selectedButtonTab;
      }
      this.mainMenuComponet.addButtonOnClick(
        data,
        tab,
        this.form,
        this.menuCategories,
        this.rowStyles,
        this.currentTabs,
        this.menusAppClass,
        this.appManagementService,
        true,
        this.mobilewWorkflowService,
        this.menuStoreList,
        this.mobileStoreAppList,
        this.selectedStoreId,
        bundle
      );
    } else if (this.section === this.uiSections.MENUS) {
      this.mainMenuComponet.addButtonOnClick(
        data,
        undefined,
        this.form,
        this.menuCategories,
        this.rowStyles,
        this.currentTabs,
        this.menusAppClass,
        this.appManagementService,
        false
      );
    } else if (this.section === this.uiSections.MOBILESTORE) {
      this.mainMenuComponet.addButtonOnClick(
        data,
        undefined,
        this.form,
        this.menuCategories,
        this.rowStyles,
        this.currentTabs,
        this.menusAppClass,
        this.appManagementService,
        false,
        this.mobilewWorkflowService,
        this.menuStoreList,
        this.mobileStoreAppList,
        this.selectedStoreId,
        true
      );
    }
  }
}
