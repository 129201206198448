import { Store } from '@ngrx/store';
import * as actions from './appRelease.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppReleaseDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetReleasePaging() {
        this._store.dispatch(new actions.ResetReleasePaging());
    }
    getNextPageRelease() {
        this._store.dispatch(new actions.GetNextPageRelease());
    }
    getPrevPageRelease() {
        this._store.dispatch(new actions.GetPrevPageRelease());
    }
    listAppReleases() {
        this._store.dispatch(new actions.ListAppReleases());
    }
    listAppReleaseId(appRelese) {
        this._store.dispatch(new actions.ListAppReleaseId(appRelese));
    }
    listAppReleasesSuccess(eop, sop, appReleases) {
        this._store.dispatch(new actions.ListAppReleasesSuccess(eop, sop, appReleases));
    }
    rcvdAppRelease(appRelease) {
        this._store.dispatch(new actions.RcvdAppRelease(appRelease));
    }
    removeAppRelease(id) {
        this._store.dispatch(new actions.RemoveAppRelease(id));
    }
    removeAppReleaseSuccess(id) {
        this._store.dispatch(new actions.RemoveAppReleaseSuccess(id));
    }
    viewAppReleaseDetails(id) {
        this._store.dispatch(new actions.ViewAppReleaseDetails(id));
    }
    hideAppReleaseDetails(id) {
        this._store.dispatch(new actions.HideAppReleaseDetails(id));
    }
    showAppleKeyForm(releaseID) {
        this._store.dispatch(new actions.ShowAppleKeyForm(releaseID));
    }
    sendAppleKeyCode(code, releaseID) {
        this._store.dispatch(new actions.SendAppleKeyCode(code, releaseID));
    }
    getAppVer(releaseID) {
        this._store.dispatch(new actions.GetAppVer(releaseID));
    }
    rcvdAppReleaseVerData(appRelease) {
        this._store.dispatch(new actions.RcvdAppReleaseVer(appRelease));
    }
    addAppReleaseWaitingItem(appRelease) {
        this._store.dispatch(new actions.AddAppReleaseWaitingItem(appRelease));
    }
    removeAppReleaseWaitingItem() {
        this._store.dispatch(new actions.RemoveAppReleaseWaitingItem());
    }
}
AppReleaseDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppReleaseDispatchers_Factory() { return new AppReleaseDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppReleaseDispatchers, providedIn: "root" });
