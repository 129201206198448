import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ProductUiActions from './productsUI.actions';

import { AppState } from 'src/models/AppState';
import { Product } from 'src/models/Product';
import { MarketTypes } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class ProductsUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createProduct(product: Product): void {
    this._store.dispatch(new ProductUiActions.CreateProduct(product));
  }

  setProduct(productID: string, product: Product): void {
    this._store.dispatch(new ProductUiActions.SetProduct(productID, product));
  }

  addInventory(productID: string, units: number): void {
    this._store.dispatch(new ProductUiActions.AddInventory(productID, units));
  }

  getProduct(
    productID: string,
    productType: MarketTypes,
    bundleID?: string
  ): void {
    this._store.dispatch(
      new ProductUiActions.GetProduct(productID, productType, bundleID)
    );
  }

  productReceived(product: Product): void {
    this._store.dispatch(new ProductUiActions.ProductReceived(product));
  }

  productsReceived(products: Product[]): void {
    this._store.dispatch(new ProductUiActions.ProductsReceived(products));
  }

  // paging dispatchers
  getNextProductsPage(): void {
    this._store.dispatch(new ProductUiActions.GetNextProductsPage());
  }

  getPrevProductsPage(): void {
    this._store.dispatch(new ProductUiActions.GetPrevProductsPage());
  }

  deleteProduct(id: string): void {
    this._store.dispatch(new ProductUiActions.DeleteProduct(id));
  }

  protuctDeleted(id: string): void {
    this._store.dispatch(new ProductUiActions.ProductDeleted(id));
  }

  receivedProductsHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Product[]
  ): void {
    this._store.dispatch(
      new ProductUiActions.ReceivedProductsHistoryChunk(eop, sop, currentPage)
    );
  }

  resetProductsPaging(product_group_id?: number, ownerGroupId?: string): void {
    this._store.dispatch(
      new ProductUiActions.ResetProductsPaging(product_group_id, ownerGroupId)
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new ProductUiActions.SetStatusAsIdle());
  }
}
