import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { setBookingPeriods, RemoveBookingPeriods } from 'src/models/BookingPeriods';
export class BookingPeriodsEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.createBookingPeriods = this.actions$.pipe(ofType("CREATE_BOOKING_PERIODS" /* CREATE_BOOKING_PERIODS */), map((action) => {
            this._socketGateway.sendSocketMessage(setBookingPeriods(action.bookingPeriods, true));
        }));
        this.updateBookingPeriods = this.actions$.pipe(ofType("UPDATE_BOOKING_PERIODS" /* UPDATE_BOOKING_PERIODS */), map((action) => {
            this._socketGateway.sendSocketMessage(setBookingPeriods(action.bookingPeriods));
        }));
        this.deleteBookingPeriods = this.actions$.pipe(ofType("DELETE_BOOKING_PERIODS" /* DELETE_BOOKING_PERIODS */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveBookingPeriods(action.chatId, action.data));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingPeriodsEffects.prototype, "createBookingPeriods", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingPeriodsEffects.prototype, "updateBookingPeriods", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingPeriodsEffects.prototype, "deleteBookingPeriods", void 0);
