import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appMenu.actions';

import { AppState } from 'src/models/AppState';
import { AppMenu } from 'src/models/AppMenu';

@Injectable({
  providedIn: 'root'
})
export class AppMenuDispatchers {
  constructor(private _store: Store<AppState>) {}

  getAppMenus(menuType: string): void {
    this._store.dispatch(new actions.GetAppMenus(menuType));
  }

  receiveAppMenus(appMenus: AppMenu[]): void {
    this._store.dispatch(new actions.ReceiveAppMenus(appMenus));
  }

  appMenuDetailsReceived(appMenu: AppMenu): void {
    this._store.dispatch(new actions.AppMenuDetailsReceived(appMenu));
  }
}
