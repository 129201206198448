import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/storage.service';
import { GetChatThemes, SetChatThemes } from 'src/models/chatThemes';
export class ChatThemesUIEffects {
    constructor(actions$, _socketGateway, sorage, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.sorage = sorage;
        this._store = _store;
        this.setChatThemeUi = this.actions$.pipe(ofType("SET_CHAT_THEME_UI" /* SET_CHAT_THEME_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetChatThemes(action.style));
        }));
        this.receveChatThemeUi = this.actions$.pipe(ofType("RECEVE_CHAT_THEME_UI" /* RECEVE_CHAT_THEME_UI */), map((action) => {
            const style = JSON.stringify(action.style);
            // this.sorage.setRecord('styleChat', style);
        }));
        this.getChatThemeUi = this.actions$.pipe(ofType("GET_CHAT_THEME_UI" /* GET_CHAT_THEME_UI */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetChatThemes());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatThemesUIEffects.prototype, "setChatThemeUi", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatThemesUIEffects.prototype, "receveChatThemeUi", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatThemesUIEffects.prototype, "getChatThemeUi", void 0);
