import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
export class PermissionService {
    constructor() { }
    getBrowserLocation() {
        return Observable.create(observer => {
            navigator.geolocation.getCurrentPosition(position => {
                observer.next(position);
                observer.complete();
            }, observer.error.bind(observer));
        });
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(); }, token: PermissionService, providedIn: "root" });
