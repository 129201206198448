import { BehaviorSubject } from 'rxjs';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/auth/auth.dispatchers";
export class SettingsService {
    constructor(_authDispatchers) {
        this._authDispatchers = _authDispatchers;
        this._serverError = new BehaviorSubject(null);
        this.serverError$ = this._serverError.asObservable();
    }
    setServerError(val) {
        this._serverError.next(val);
    }
    setNewPassword(oldPassword, newPassword, confirmNewPassword) {
        this._authDispatchers.changePassword(oldPassword, newPassword, confirmNewPassword);
    }
}
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.AuthDispatchers)); }, token: SettingsService, providedIn: "root" });
