import {
  BubbleStatus,
  GET_CHAT_THEME_METHOD,
  SET_CHAT_THEME_METHOD
} from './constants';

export const DefulatStyle: ChatThemeOpj = {
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: 'rgba(62, 193, 188, 0.95)',
  secandryBgColor: 'rgba(46, 212, 115,0.95)',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#efeae2',
  rightBubbelBgColor: '#d9fdd3',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};

export const NandboxBubbleThemes = {
  id: BubbleStatus.nandbox_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '62, 193, 188',
  secandryBgColor: '46, 212, 115',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#efeae2',
  rightBubbelBgColor: '#d9fdd3',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};

export const DarkThemeBubble: ChatThemeOpj = {
  id: BubbleStatus.darktheme_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '34, 52, 68',
  secandryBgColor: '52, 112, 167',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#2E5156',
  rightBubbelBgColor: '#3470A7',
  leftBubbelBgColor: '#223444',
  primiryTextColor: '#ffffff',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
export const WhatsAppBubble: ChatThemeOpj = {
  id: BubbleStatus.whatsapp_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '62, 193, 188',
  secandryBgColor: '46, 212, 115',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#E5DDD5',
  rightBubbelBgColor: '#E2FFC7',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
export const ViberBubble: ChatThemeOpj = {
  id: BubbleStatus.viber_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '62, 193, 188',
  secandryBgColor: '46, 212, 115',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#DDE0E0',
  rightBubbelBgColor: '#B0E0F5',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
export const WeChatBubble: ChatThemeOpj = {
  id: BubbleStatus.wechat_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '62, 193, 188',
  secandryBgColor: '46, 212, 115',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#EAEAEA',
  rightBubbelBgColor: '#A0E758',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
export const TelegramBubble: ChatThemeOpj = {
  id: BubbleStatus.telegram_bubble,
  backgroundImage:
    'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
  primiryBgColor: '62, 193, 188',
  secandryBgColor: '46, 212, 115',
  logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
  chatBgImage:
    'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
  chatBgColor: '#D2DAE4',
  rightBubbelBgColor: '#EFFDDD',
  leftBubbelBgColor: '#ffffff',
  primiryTextColor: '#000000',
  primiryColor: '#01a1ee',
  secandryColor: '#f0f2f5',
  blaceholderImage:
    'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};

export interface ChatThemeOpj {
  id?: string;
  backgroundImage?: string;
  primiryBgColor?: string;
  secandryBgColor?: string;
  logo?: string;
  chatBgImage?: string;
  chatBgColor?: string;
  rightBubbelBgColor?: string;
  leftBubbelBgColor?: string;
  primiryTextColor?: string;
  primiryColor?: string;
  secandryColor?: string;
  blaceholderImage?: string;
  menu?: any;
}

export interface ChatThemesUI {
  channelID?: string;
  selectedTheme?: ChatThemeOpj;
}

export class GetChatThemes {
  readonly method = GET_CHAT_THEME_METHOD;
}
export class SetChatThemes {
  readonly method = SET_CHAT_THEME_METHOD;
  constructor(public chat_theme: ChatThemeOpj) {}
}
