import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';

import { AppInfo } from 'src/models/ChannelAppConfig';

@Component({
  selector: 'app-startover-more-modules',
  templateUrl: './startover-more-modules.component.html',
  styleUrls: ['./startover-more-modules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverMoreModulesComponent implements OnInit {
  @Input() item: any;
  @Input() moreTypes: any[];
  @Input() appInfo: AppInfo;
  @Output() checkedItemNotify = new EventEmitter<any>();
  constructor(public appMgmtService: AppMgmtService) {}

  ngOnInit() {}

  checkedItem(item) {
    this.checkedItemNotify.emit(item);
  }
}
