import { Action } from '@ngrx/store';
import { Collection } from 'src/models/collectionsUI';
import { ProductStore } from 'src/models/productStore';

export const enum CollectionsUIActionTypes {
  UI_COLLECTIONS_RESET_PAGING = 'UI_COLLECTIONS_RESET_PAGING',
  UI_GET_COLLECTIONS_NEXT_PAGE = 'UI_GET_COLLECTIONS_NEXT_PAGE',
  UI_GET_COLLECTIONS_PREV_PAGE = 'UI_GET_COLLECTIONS_PREV_PAGE',
  UI_RECEIVE_COLLECTIONS_ITEM = 'UI_RECEIVE_COLLECTIONS_ITEM',
  UI_SET_COLLECTION_ITEM = 'UI_SET_COLLECTION_ITEM',
  UI_SET_COLLECTION_PRODUCTS_ITEM = 'UI_SET_COLLECTION_PRODUCTS_ITEM',
  UI_COLLECTION_RECEIVED_ITEM = 'UI_COLLECTION_RECEIVED_ITEM',
  UI_DELETE_COLLECTION_ITEM = 'UI_DELETE_COLLECTION_ITEM',
  UI_GET_COLLECTION_ITEM = 'UI_GET_COLLECTION_ITEM',
  UI_REMOVE_COLLECTION_ITEM_SUCCESS = 'UI_REMOVE_COLLECTION_ITEM_SUCCESS',
  UI_RECEVE_COLLECTION_PRODUCTS = 'UI_RECEVE_COLLECTION_PRODUCTS',
  UI_GET_COLLECTION_PRODUCTS = 'UI_GET_COLLECTION_PRODUCTS'
}

export class ResetCollectionsPaging implements Action {
  readonly type = CollectionsUIActionTypes.UI_COLLECTIONS_RESET_PAGING;
}

export class GetCollectionsNextPage implements Action {
  readonly type = CollectionsUIActionTypes.UI_GET_COLLECTIONS_NEXT_PAGE;
  constructor() {}
}

export class GetCollectionsPrevPage implements Action {
  readonly type = CollectionsUIActionTypes.UI_GET_COLLECTIONS_PREV_PAGE;
  constructor() {}
}

export class ReceiveListCollectionsHistoryChunk implements Action {
  readonly type = CollectionsUIActionTypes.UI_RECEIVE_COLLECTIONS_ITEM;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: any[]
  ) {}
}

export class SetCollectionItem implements Action {
  readonly type = CollectionsUIActionTypes.UI_SET_COLLECTION_ITEM;
  constructor(public collection?: Collection) {}
}
export class GetCollectionItem implements Action {
  readonly type = CollectionsUIActionTypes.UI_GET_COLLECTION_ITEM;
  constructor(public id?: number, public isSelectedItem?: boolean) {}
}
export class SetCollectionProducts implements Action {
  readonly type = CollectionsUIActionTypes.UI_SET_COLLECTION_PRODUCTS_ITEM;
  constructor(public collectionID: number, public productsID: number[]) {}
}
export class CollectionReceived implements Action {
  readonly type = CollectionsUIActionTypes.UI_COLLECTION_RECEIVED_ITEM;
  constructor(public collection?: Collection) {}
}
export class DeleteCollectionItem implements Action {
  readonly type = CollectionsUIActionTypes.UI_DELETE_COLLECTION_ITEM;
  constructor(public id?: number) {}
}
export class DeleteCollectionItemSuccess implements Action {
  readonly type = CollectionsUIActionTypes.UI_REMOVE_COLLECTION_ITEM_SUCCESS;
  constructor(public id?: number) {}
}
export class GetCollectionProducts implements Action {
  readonly type = CollectionsUIActionTypes.UI_GET_COLLECTION_PRODUCTS;
  constructor(public id?: number) {}
}
export class ReceveCollectionProducts implements Action {
  readonly type = CollectionsUIActionTypes.UI_RECEVE_COLLECTION_PRODUCTS;
  constructor(public productStore?: ProductStore[]) {}
}

export type Actions =
  | ResetCollectionsPaging
  | GetCollectionsNextPage
  | GetCollectionsPrevPage
  | ReceiveListCollectionsHistoryChunk
  | CollectionReceived
  | DeleteCollectionItemSuccess
  | GetCollectionItem
  | ReceveCollectionProducts;
