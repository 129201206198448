<div class="popup-settings">
  <div class="popup-settings-header" [class.error]="error">
    <div class="header-text">
      <div class="title">
        <img
          class="svg-icon icon-icon"
          (click)="closePopup()"
          *ngIf="showBack"
          [src]="backButtonTitle"
          (mouseenter)="backHover()"
          (mouseleave)="backNormal()"
        />
        <img [src]="imageUrl" [alt]="title" *ngIf="imageUrl" class="icon-img" />
        <div class="defult-Icon" *ngIf="!imageUrl && defaultIcon">
          <svg class="icon-defult">
            <use
              attr.xlink:href="./assets/svg/app-sections.svg#{{ defaultIcon }}"
            ></use>
          </svg>
        </div>
        {{ title }}
      </div>
      <span class="desc">
        {{ desc }}
      </span>
    </div>
    <svg class="svg-icon icon-icon" (click)="closePopup()" *ngIf="!hideclose">
      <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
    </svg>
  </div>
  <div class="popup-settings-body" [class.no-padding]="removePadding">
    <ng-content></ng-content>
  </div>
  <div class="popup-settings-footer" *ngIf="!hideFooter">
    <!-- <app-border-btn next [type]="'submit'">
      {{ buttonTitle }}
    </app-border-btn> -->
    <!-- <button (click)="onSave()" [disabled]="disabledBtn">
      {{ buttonTitle }}
    </button> -->
    <app-border-btn submit (btnClicked)="onSave()" [disabled]="disabledBtn">
      {{ buttonTitle }}
    </app-border-btn>
  </div>
</div>
