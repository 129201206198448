import { Store } from '@ngrx/store';
import * as actions from './appChannelSettings.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppChannelSettingsDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    setAppChannelSettings(state) {
        this._store.dispatch(new actions.SetAppChannelSettings(state));
    }
    getAppChannelSettings() {
        this._store.dispatch(new actions.GetAppChannelSettings());
    }
    receiveAppChannelSettingsHistory(state, deep_link_schema) {
        this._store.dispatch(new actions.ReceiveAppChannelSettingsHistory(state, deep_link_schema));
    }
}
AppChannelSettingsDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppChannelSettingsDispatchers_Factory() { return new AppChannelSettingsDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppChannelSettingsDispatchers, providedIn: "root" });
