<app-form-frame no-padding [showCancel]="true" (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">{{ 'ADDPOLL' | translate }}</ng-container>
  <ng-container ngProjectAs="desc">
    Manage your Polls in this Message or
    <span class="link" (click)="createNewItem()">{{
      'CREATEANEWPOLL' | translate
    }}</span
    >.
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container *ngIf="menusService.botMenus$ | async as botMenus">
          <app-selectable-cell-frame
            *ngFor="let menu of botMenus; trackBy: TrackByFunction"
            [symbolColor]="menuFet?.symbolColor"
            [symbolImage]="menuFet?.symbolImage"
            [coverColor]="menuFet?.coverColor"
            [title]="menu?.menu_name"
            [selectorId]="menu?.id"
            [isSelected]="menu?.id === currentSelectMessage?.id"
            [useNewStely]="true"
            [cellSize]="'M'"
            (checkClick)="setSelectMessage(menu)"
            (checkChanged)="resetSelectMessage()"
          >
          </app-selectable-cell-frame>
        </ng-container>
        <app-empty-section
          [pool]="false"
          [section]="menuFet"
          (newCreateNotify)="createNewItem()"
          style="width: 100%; display: block;"
          *ngIf="(menusService.botMenus$ | async)?.length === 0"
        >
        </app-empty-section>
      </div>
      <div
        class="navigate"
        *ngIf="(menusService.botMenus$ | async)?.length > 0"
      >
        <div class="navigate-btn" (click)="menusService?.getNextPage()">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
        <div class="navigate-btn" (click)="menusService?.getPrevPage()">
          <svg class="svg-icon icon-icon" style="transform: rotate(180deg);">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button
        class="add-btn"
        (click)="insertEvent()"
        [disabled]="(menusService.botMenus$ | async)?.length === 0"
      >
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
