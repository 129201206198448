<div
  class="container sticky"
  [class.active]="
    (!(layoutService?.getSideNavStatus() | async)?.matches &&
      (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus) ||
    (layoutService?.getSideNavStatus() | async)?.matches
  "
>
  <ng-container
    *ngIf="
      appManagementService.channelAppUiCollection$ | async as appUiCollection
    "
  >
    <!-- <ng-container>
      <div class="start-over-container">
        <div class="start-over-desc">
          Not seeing what you expect?
        </div>
        <div class="start-over" (click)="startOver()">
          <img src="./assets/img/start-over.svg" />
          <div class="start-title">
            Start Over
          </div>
        </div>
      </div>
    </ng-container> -->
    <app-features-counter
      [mode]="(authService?.authCollection$ | async)?.mode"
      [tabsList]="appManagementService.tabsContainers$ | async"
      [navMenuItems]="appManagementService.navMenuItems$ | async"
      [currentPage]="currentPage"
      [appInfo]="appManagementService.appInfo$ | async"
    ></app-features-counter>
    <div class="app-progress">
      <div class="top-seaction">
        <div class="progress-bar-app" [ngStyle]="progressBar"></div>
        <ul id="progress">
          <li
            (click)="goToSpecificPage(1, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 1"
            [class.select]="appUiCollection?.currentScreen == 1"
          >
            <div class="before-titel" [ngStyle]="beforeBranding"></div>
            <span>Branding</span>
          </li>
          <li
            (click)="goToSpecificPage(2, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 2"
            [class.select]="appUiCollection?.currentScreen == 2"
          >
            <div class="before-titel" [ngStyle]="beforeHome"></div>
            <span> Home Menu</span>
          </li>
          <li
            *ngIf="section === uiSection.APP"
            (click)="goToSpecificPage(3, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 3"
            [class.select]="appUiCollection?.currentScreen == 3"
          >
            <div class="before-titel" [ngStyle]="beforeSideMenu"></div>
            <span>Side Menu</span>
          </li>
          <li
            (click)="goToSpecificPage(4, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 4"
            [class.select]="appUiCollection?.currentScreen == 4"
          >
            <div class="before-titel" [ngStyle]="beforeSplash"></div>
            <span *ngIf="section === uiSection?.APP"> App Settings</span>
            <span *ngIf="section !== uiSection?.APP"> Channel Settings</span>
          </li>
          <!-- <li
      (click)="goToSpecificPage(3, progressWeb)"
      [class.active]="appUiCollection?.currentScreen === 3"
    >
      <div class="before-titel" [ngStyle]="beforeLogin">{{ login }}</div>
      Login
      <div class="after-titel" [ngStyle]="afterLogin"></div>
      <div class="arrow" [ngStyle]="arrowLogin"></div>
    </li>
    <li
      (click)="goToSpecificPage(4, progressWeb)"
      [class.active]="appUiCollection?.currentScreen === 4"
    >
      <div class="before-titel" [ngStyle]="beforeProfile">{{ profile }}</div>
      Profile
      <div class="after-titel" [ngStyle]="afterProfile"></div>
      <div class="arrow" [ngStyle]="arrowProfile"></div>
    </li> -->

          <!-- <li
      (click)="goToSpecificPage(7, progressWeb)"
      [class.active]="appUiCollection?.currentScreen === 7"
    >
      <div class="before-titel" [ngStyle]="beforeFeatures">{{ features }}</div>
      Features
      <div class="after-titel" [ngStyle]="afterFeatures"></div>
      <div class="arrow" [ngStyle]="arrowFeatures"></div>
    </li> -->

          <li
            *ngIf="section === uiSection.APP"
            (click)="goToSpecificPage(5, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 5"
            [class.select]="appUiCollection?.currentScreen == 5"
          >
            <div class="before-titel" [ngStyle]="beforeRelease"></div>
            <span>Build</span>
          </li>

          <li
            *ngIf="!isProduction || section === uiSection.CHANNEL"
            (click)="goToSpecificPage(6, progressWeb)"
            [class.active]="appUiCollection?.currentScreen == 6"
            [class.select]="appUiCollection?.currentScreen == 6"
          >
            <div class="before-titel" [ngStyle]="beforePublish"></div>
            <span> Publish</span>
            <!-- <div class="after-titel" [ngStyle]="afterSplash"></div>
      <div class="arrow" [ngStyle]="arrowSplash"></div> -->
          </li>
          <!-- <li
      (click)="goToSpecificPage(7, progressWeb)"
      [class.active]="appUiCollection?.currentScreen === 7"
    >
      <div class="before-titel" [ngStyle]="beforeActivate">
        {{ activation }}
      </div>
      Activation
      <div class="after-titel" [ngStyle]="afterActivate"></div>
      <div class="arrow" [ngStyle]="arrowActivate"></div>
    </li>
    <li
      (click)="goToSpecificPage(8, progressWeb)"
      [class.active]="appUiCollection?.currentScreen === 8"
    >
      <div class="before-titel" [ngStyle]="beforeIndexData">
        {{ indexData }}
      </div>
      Index Data
    </li> -->
        </ul>
      </div>
      <!-- <div>
        <div
          *ngIf="section === uiSection.CHANNEL"
          class="simple-mode"
          (click)="stripeConnect()"
          (mouseenter)="showHelpItem(true)"
          (mouseleave)="showHelpItem(false)"
        >
          Connect With Stripe
        </div>
      </div> -->
    </div>
  </ng-container>
  <div class="actions">
    <div class="btn-container">
      <app-header-buttons
        *ngIf="
          currentPage == appScreens.MAIN ||
          currentPage == appScreens.TABS ||
          currentPage == appScreens.NAV_MENU
        "
        [hideBackButton]="hideBackButton"
        [hideNextButton]="true"
        [currentPage]="currentPage"
        [isChannelConfOnly]="(uiService?.selectedChat$ | async)?.subChannel"
        [hideSaveButton]="showSettings"
        (saveNotify)="save($event)"
        [isChannelConfOnly]="section === uiSections.CHANNEL"
        [saveTitle]="'Save & Continue'"
      >
      </app-header-buttons>
      <app-header-buttons
        *ngIf="currentPage == appScreens?.UPLOAD_LOGO"
        [hideBackButton]="hideBackButton"
        [hideNextButton]="true"
        [currentPage]="appScreens?.UPLOAD_LOGO"
        [startover]="colorConfigComp?.startover"
        [mode]="2"
        [progressWeb]="(authService?.authCollection$ | async)?.progressWeb"
        [appName]="
          (appManagementService?.getAppPublishingInfo$() | async)?.app_name
        "
        [isChannelConfOnly]="(uiService?.selectedChat$ | async)?.subChannel"
        (saveNotify)="saveApp($event)"
        [saveTitle]="'Save & Continue'"
      >
      </app-header-buttons>
      <!-- <div
        class="text"
        *ngIf="
          currentPage == appScreens.MAIN ||
          currentPage == appScreens.TABS ||
          currentPage == appScreens.NAV_MENU ||
          currentPage == appScreens?.UPLOAD_LOGO
        "
      >
        OR
      </div> -->
    </div>
    <!-- 
    <div class="start-over" (click)="startOver()">
      <img src="./assets/img/start-over.svg" alt="Start Over" />
      <div class="start-title">
        Start Over
      </div>
    </div> -->
  </div>
</div>
