import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { SubGroupUiDispatchers } from './subGroupsUI/subGroupsUI.dispatchers';
import { AppConfigDispatchers } from './channelAppConfig/appConfig.dispatchers';
import * as MessagesMethos from 'src/models/IMessage';
import { GetAppMenu } from 'src/models/AppMenu';
import { UISections, APP_MENU_CATEGORIES, MERCHANT_PAGE_SIZE, REACHED_END, GROUP, CHANNEL, VIRTUAL_APP, UIBillingSubRouts } from 'src/models/constants';
import { SubChatsService } from '../+merchant/+dashboard/sub-chats/sub-chats.service';
import { SubChannelUiDispatchers } from './subChannelsUI/subChannelsUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { VirtualAppUiDispatchers } from './virtualAppsUI/virtualAppsUI.dispatchers';
import { RegistrationService } from '../+merchant/+registration/registration.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { DashboardService } from '../+merchant/+dashboard/dashboard.service';
import { PrivilegesName } from 'src/models/privileges';
import { StorageService } from '../core/storage.service';
import { Location } from '@angular/common';
export class SectionEffects {
    constructor(_router, actions$, _store, _socketGateway, _subChatServices, _subGroupUiDispatchers, _subChannelUiDispatchers, _appConfigDispatchers, _virtualAppUiDispatchers, _rgsService, dashboardService, appMgment, localStorage, location) {
        this._router = _router;
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        this._subChatServices = _subChatServices;
        this._subGroupUiDispatchers = _subGroupUiDispatchers;
        this._subChannelUiDispatchers = _subChannelUiDispatchers;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._virtualAppUiDispatchers = _virtualAppUiDispatchers;
        this._rgsService = _rgsService;
        this.dashboardService = dashboardService;
        this.appMgment = appMgment;
        this.localStorage = localStorage;
        this.location = location;
        this.chatSelected = this.actions$.pipe(ofType("UI_CHAT_SELECTED" /* UI_CHAT_SELECTED */), withLatestFrom(this._store
            .select(state => state.authReducer.selectedTemplateID)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.progressWeb)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.mode)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer.app_info)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, templateID, progressWeb, mode, appInfo, selectedChat, auth]) => {
            const action = val;
            if (this._rgsService.getTempId() && auth.appConfig == 0 && !appInfo) {
                // this._appConfigDispatchers.requestAppTemplate(
                //   this._rgsService.getTempId()
                // );
                this.appMgment.setTempId(this._rgsService.getTempId());
                this._appConfigDispatchers.setApp(null, this._rgsService.getTempId());
            }
            else if ((auth.chatId === auth.main_group_id && auth.appConfig && !appInfo) ||
                (auth.chatId !== auth.main_group_id &&
                    auth.is_nandbox > 0 &&
                    auth.appConfig &&
                    !appInfo)) {
                this._appConfigDispatchers.requestAppConfig();
            }
        }));
        /********************************* */
        // page change effect
        this.pageChanged = this.actions$.pipe(ofType("UI_SET_PAGE_NUMBER" /* UI_SET_PAGE_NUMBER */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats, selectedChat]) => {
            const action = val;
            const targetChat = mainChats.find(chat => chat.id === selectedChat.id);
            if (action.payload === 'new' && targetChat.eopLevel1 !== REACHED_END) {
                this._socketGateway.sendSocketMessage(new MessagesMethos.GetMessagesHistoryFromBusinessServer(null, null, null, null, null, null, null, null, null, MERCHANT_PAGE_SIZE, 1, targetChat.eopLevel1, 0));
            }
        }));
        this.sectionSelected = this.actions$.pipe(ofType("UI_SET_SECTION" /* UI_SET_SECTION */), withLatestFrom(this._store
            .select(state => {
            return state.appMenuReducer.filter(menu => menu.category === APP_MENU_CATEGORIES.MAP && !menu.value);
        })
            .pipe(distinctUntilChanged(isEqual))), map(([val, emptyValueMapMenus]) => {
            const action = val;
            if ((action.payload === UISections.APP ||
                action.payload === UISections.CHANNEL) &&
                emptyValueMapMenus &&
                emptyValueMapMenus.length > 0) {
                emptyValueMapMenus.forEach(mapMenu => {
                    this._socketGateway.sendSocketMessage(new GetAppMenu(mapMenu.id));
                });
            }
        }));
        this.sectionChanged = this.actions$.pipe(ofType("UI_SET_SECTION" /* UI_SET_SECTION */), map((action) => {
            const url = this.location.path();
            const urlPath = url.split('?');
            if (urlPath[0] !== '/signup' && urlPath[0] !== '/verifyCode') {
                switch (action.payload) {
                    /**
                     * Sections not pagination
                     */
                    case UISections.CAMPAIGNS:
                        this._router.navigate(['/campaigns']);
                        break;
                    case UISections.COUPONS:
                        this._router.navigate(['/coupons']);
                        break;
                    case UISections.BOTS:
                        this._router.navigate(['/bots']);
                        break;
                    case UISections.MENUS:
                        this._router.navigate(['/polls']);
                        break;
                    case UISections.LOCATIONS:
                        this._router.navigate(['/locations']);
                        break;
                    /******************************************/
                    case UISections.SUBGROUP:
                        // this._subGroupUiDispatchers.listSubGroupUI();
                        this._subChatServices.chatType = GROUP;
                        this._router.navigate(['/subgroups']);
                        break;
                    case UISections.SUBCHANNEL:
                        // this._subChannelUiDispatchers.listSubChannelUI();
                        this._subChatServices.chatType = CHANNEL;
                        this._router.navigate(['/subchannels']);
                        break;
                    case UISections.VIRTUAL_APPS:
                        this._virtualAppUiDispatchers.listVirtualAppUI();
                        this._subChatServices.chatType = VIRTUAL_APP;
                        this._router.navigate(['/vapps']);
                        break;
                    case UISections.MYCH:
                        this._subChatServices.chatType = CHANNEL;
                        this._router.navigate(['/my-channel']);
                        break;
                    case UISections.BILLINGS:
                        if (!action.subSection) {
                            this._router.navigate(['/billings'], {
                                queryParamsHandling: 'merge'
                            });
                        }
                        else if (action.subSection === UIBillingSubRouts.PLANS) {
                            this._router.navigate(['/billings/plans']);
                        }
                        break;
                    case UISections.APP:
                        this._router.navigate(['/app']);
                        break;
                    case UISections.CHANNEL:
                        this._router.navigate(['/app']);
                        break;
                    case UISections.POSTS:
                        this._router.navigate(['/messages']);
                        break;
                    case UISections.SCHEDULE:
                        this._router.navigate(['/schedules']);
                        break;
                    case UISections.BOOKING:
                        this._router.navigate(['/bookings/']);
                        break;
                    case UISections.CALENDAR:
                        this._router.navigate(['/calendars']);
                        break;
                    case UISections.EVENTS:
                        this._router.navigate(['/events']);
                        break;
                    case UISections.USERS:
                        this._router.navigate(['/users']);
                        break;
                    case UISections.BLACKLIST:
                        this._router.navigate(['/blackList']);
                        break;
                    case UISections.TAGS:
                        this._router.navigate(['/tags']);
                        break;
                    case UISections.BULKUPLOAD:
                        this._router.navigate(['/bulkupload']);
                        break;
                    case UISections.SETTINGS:
                        this._router.navigate(['/settings']);
                        break;
                    case UISections.TRANSACTIONS:
                        this._router.navigate(['/transactions']);
                        break;
                    case UISections.PACKAGES:
                        this._router.navigate(['/packages']);
                        break;
                    case UISections.BUNDLES:
                        this._router.navigate(['/bundles']);
                        break;
                    case UISections.CUSTOMERCARE:
                        this._router.navigate(['/customercare']);
                        break;
                    case UISections.PRODUCTS:
                        this._router.navigate(['/products']);
                        break;
                    case UISections.PRODUCT:
                        this._router.navigate(['/product']);
                        break;
                    case UISections.UPLOADTEMPS:
                        this._router.navigate(['/tempsupload']);
                        break;
                    case UISections.MEDIACENTER:
                        this._router.navigate(['/mediacenter']);
                        break;
                    case UISections.MSTORE:
                        this._router.navigate(['/mStore']);
                        break;
                    case UISections.STOREMENU:
                        this._router.navigate(['/storeMenu']);
                        break;
                    case UISections.MAPSERVICE:
                        this._router.navigate(['/MS']);
                        break;
                    case UISections.MAPROUTES:
                        this._router.navigate(['/MR']);
                        break;
                    case UISections.TRIPS:
                        this._router.navigate(['/Trips']);
                        break;
                    case UISections.TRIPSHISTORY:
                        this._router.navigate(['/TH']);
                        break;
                    case UISections.TRIPNOTIFIERS:
                        this._router.navigate(['/TN']);
                        break;
                    case UISections.WHITELIST:
                        this._router.navigate(['/wl']);
                        break;
                    case UISections.MOBILESTORE:
                        this._router.navigate(['/store']);
                        break;
                    case UISections.COLLECTIONS:
                        this._router.navigate(['/collections']);
                        break;
                    case UISections.WEBSITTINGS:
                        this._router.navigate(['/web-settings']);
                        break;
                    default:
                        this._router.navigate(['/']);
                        break;
                }
            }
        }));
        this.privilegesName = PrivilegesName;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "chatSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "pageChanged", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "sectionSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "sectionChanged", void 0);
