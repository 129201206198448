<!-- Date Picker Box -->
<app-popup-setting-frame
  [hideFooter]="true"
  [hideclose]="true"
  [title]="'Schedule Post'"
  no-padding
>
  <div class="schedule-post">
    <div style="position: relative;" dropdown>
      <label
        for="date"
        class="schedule-post-input"
        #menuButtonDate
        dropdownToggle
      >
        <div class="date-input">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-calendar-event"
            ></use>
          </svg>
          <span>{{ schedule_date | date: 'MMM dd yyyy' }}</span>
        </div>
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="/assets/svg/app-extra-icons.svg#icon-expand-more"
          ></use>
        </svg>
      </label>
      <!-- <input type="checkbox" id="date" (change)="toggleScheduleDate($event)" /> -->
      <div
        class="Datepicker-Popover"
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu datepicker-box"
      >
        <datepicker
          [(ngModel)]="scheduleDate"
          [minDate]="minDate"
          [showWeeks]="false"
          [minMode]="'day'"
          (isOpenChange)="dateChanges($event)"
          (ngModelChange)="dateChanges($event)"
        >
        </datepicker>
      </div>
    </div>
    <div style="position: relative;">
      <div
        class="schedule-post-input"
        (click)="toggleTimePicker(true)"
        #menuButtonTime
      >
        <div class="date-input">
          <svg class="svg-icon icon-icon">
            <use xlink:href="/assets/svg/app-sections.svg#icon-clock"></use>
          </svg>
          <span>{{ schedule_date | date: 'hh:mm aa' }}</span>
        </div>
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="/assets/svg/app-extra-icons.svg#icon-expand-more"
          ></use>
        </svg>
      </div>
      <div class="Timepicker-Popover" *ngIf="openTimePicker" #menuTime>
        <div style="display:flex; height: 85%;">
          <div class="time-numbers">
            <span>
              Hours
            </span>
            <div class="time-numbers-selection">
              <div
                class="swiper-slide"
                *ngFor="let hour of hoursArray"
                [ngStyle]="selectedHour(hour)"
                (click)="setTime(hour, null, null)"
              >
                {{ hour < 10 ? '0' + hour : hour }}
              </div>
            </div>
          </div>
          <div class="time-numbers">
            <span>
              Minutes
            </span>
            <div class="time-numbers-selection">
              <div
                class="swiper-slide"
                *ngFor="let minute of minutesArray"
                [ngStyle]="selectedMinute(minute)"
                (click)="setTime(null, minute, null)"
              >
                {{ minute < 10 ? '0' + minute : minute }}
              </div>
            </div>
          </div>
          <div class="time-numbers">
            <span>
              AM/PM
            </span>
            <div
              class="time-numbers-selection"
              style="justify-content: center; border:none;"
            >
              <div
                class="swiper-am"
                (click)="setTime(null, null, 'AM')"
                [ngStyle]="selectedAM('AM')"
              >
                AM
              </div>
              <div
                class="swiper-am"
                (click)="setTime(null, null, 'PM')"
                [ngStyle]="selectedAM('PM')"
              >
                PM
              </div>
            </div>
          </div>
        </div>
        <div class="Timepicker-footer">
          <button (click)="toggleTimePicker(false)">Set</button>
        </div>
      </div>
    </div>
  </div>
  <div class="schedule-footer">
    <app-border-btn back (btnClicked)="cancelClicked(false)">
      Cancel
    </app-border-btn>
    <app-border-btn
      next
      (btnClicked)="setScheduleTime()"
      [disabled]="
        minDate?.getTime() > schedule_date &&
        minDate?.getDate() >= schedule_date
      "
    >
      Schedule Post
    </app-border-btn>
  </div>
</app-popup-setting-frame>
<!-- end date Picker -->
