import { Store } from '@ngrx/store';
import * as SubMenuUIActionTypes from './subMenuUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SubMenuUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createSubMenu(storeSubMenuDetails) {
        this._store.dispatch(new SubMenuUIActionTypes.CreateSubMenu(storeSubMenuDetails));
    }
    setSubMenu(storeSubMenuID, storeSubMenuDetails) {
        this._store.dispatch(new SubMenuUIActionTypes.SetSubMenu(storeSubMenuID, storeSubMenuDetails));
    }
    addSubMenuItem(storeSubMenuID, itemIDs) {
        this._store.dispatch(new SubMenuUIActionTypes.AddSubMenuItem(storeSubMenuID, itemIDs));
    }
    RemoveSubMenuItem(storeSubMenuID, itemIDs) {
        this._store.dispatch(new SubMenuUIActionTypes.RemoveSubMenuItem(storeSubMenuID, itemIDs));
    }
    // paging dispatchers
    getNextSubMenuPage() {
        this._store.dispatch(new SubMenuUIActionTypes.GetNextSubMenuPage());
    }
    getPrevSubMenuPage() {
        this._store.dispatch(new SubMenuUIActionTypes.GetPrevSubMenuPage());
    }
    receivedSubMenuHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new SubMenuUIActionTypes.ReceivedSubMenuHistoryChunk(eop, sop, currentPage));
    }
    subMenuStoreReceived(subMenuStore) {
        this._store.dispatch(new SubMenuUIActionTypes.SubMenuStoreReceived(subMenuStore));
    }
    resetSubMenuPaging() {
        this._store.dispatch(new SubMenuUIActionTypes.ResetSubMenuPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new SubMenuUIActionTypes.SetStatusAsIdle());
    }
}
SubMenuUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubMenuUiDispatchers_Factory() { return new SubMenuUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: SubMenuUiDispatchers, providedIn: "root" });
