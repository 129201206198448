import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { PageDirection } from 'src/models/constants';
import { SubMenuUiDispatchers } from './subMenuUI.dispatchers';
import { AddSubMenuItemMethod, CreateSubMenuMethod, SetSubMenuMethod, RemoveSubMenuItemMethod, GetSubMenusMethod } from 'src/models/SubMenu';
import * as isEqual from 'lodash.isequal';
export class SubMenuUiEffects {
    constructor(actions$, _socketGateway, _uiSubMenuDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiSubMenuDispatchers = _uiSubMenuDispatchers;
        this._store = _store;
        this.createSubMenu = this.actions$.pipe(ofType("CREATE_SUB_MENU" /* CREATE_SUB_MENU */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateSubMenuMethod(action.subMenusDetails));
        }));
        this.updateSubMenu = this.actions$.pipe(ofType("SET_SUB_MENU" /* SET_SUB_MENU */), map((action) => {
            const subMenuDetails = Object.assign({}, action.subMenuDetails, { id: action.subMenuID });
            this._socketGateway.sendSocketMessage(new SetSubMenuMethod(subMenuDetails));
        }));
        this.addSubMenuItem = this.actions$.pipe(ofType("ADD_SUB_MENU_ITEM" /* ADD_SUB_MENU_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddSubMenuItemMethod(action.subMenuID, action.items));
        }));
        this.removeSubMenuItem = this.actions$.pipe(ofType("REMOVE_SUB_MENU_ITEM" /* REMOVE_SUB_MENU_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveSubMenuItemMethod(action.subMenuID, action.itemIDs));
        }));
        //   //
        this.getNextSubMenuPage = this.actions$.pipe(ofType("UI_SUB_MENU_GET_NEXT_PAGE" /* UI_SUB_MENU_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.subMenuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, subMenuUiState]) => {
            const action = val;
            const prevRequest = subMenuUiState.previousRequest;
            if (!(subMenuUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                subMenuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubMenusMethod(0, subMenuUiState.eop));
            }
            else {
                this._uiSubMenuDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevSubMenuPage = this.actions$.pipe(ofType("UI_SUB_MENU_GET_PREV_PAGE" /* UI_SUB_MENU_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.subMenuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, subMenuUiState]) => {
            const action = val;
            const prevRequest = subMenuUiState.previousRequest;
            if (!(subMenuUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                subMenuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubMenusMethod(1, subMenuUiState.sop));
            }
            else {
                this._uiSubMenuDispatchers.setStatusAsIdle();
            }
        }));
        this.afterSubMenuResetPaging = this.actions$.pipe(ofType("UI_SUB_MENU_RESET_PAGING" /* UI_SUB_MENU_RESET_PAGING */), map((action) => {
            this._uiSubMenuDispatchers.getNextSubMenuPage();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "createSubMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "updateSubMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "addSubMenuItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "removeSubMenuItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "getNextSubMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "getPrevSubMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubMenuUiEffects.prototype, "afterSubMenuResetPaging", void 0);
