import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'app-border-btn',
  templateUrl: './border-btn.component.html',
  styleUrls: ['./border-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BorderBtnComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() type = 'button';
  @Input() focused = false;
  @Output() btnClicked = new EventEmitter<boolean>();
  @ViewChild('borderBtnId', { static: false }) btn: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['focused'];
    if (chng) {
      if (chng.currentValue) {
        this.btn.nativeElement.focus();
      } else {
        this.btn.nativeElement.blur();
      }
    }
  }

  onClick() {
    this.btnClicked.emit(true);
  }
}
