import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { STATUS_OPTIONS } from 'src/models/campaign';

@Component({
  selector: 'app-selectable-cell-frame',
  templateUrl: './selectable-cell-frame.component.html',
  styleUrls: ['./selectable-cell-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableCellFrameComponent implements OnInit {
  @Input() symbolColor: string;
  @Input() symbolImage: string;
  @Input() coverColor: string;
  @Input() coverPhoto: string;

  @Input() isSelected: boolean;
  @Input() selectorId: string;
  @Input() upcCode: string;
  @Input() title: string;
  @Input() desc: string;
  @Input() status: string;
  @Input() price: any;
  @Input() useNewStely = false;
  @Input() hideCheckBox = false;
  @Input() showDelete = false;
  @Input() cellSize: string;

  @Output() checkClick = new EventEmitter<boolean>(false);
  @Output() checkChanged = new EventEmitter<boolean>(false);
  @Output() deleteClickedNotify = new EventEmitter<boolean>(false);

  statusOptions = STATUS_OPTIONS;

  constructor() {}

  ngOnInit() {}

  checkChangedEvent(e: any) {
    const val = e.currentTarget.checked;
    if (!val) {
      this.checkChanged.emit(true);
    } else {
      this.checkClick.emit(true);
    }
  }

  checkChangedClick(event) {
    if (!event) {
      this.checkChanged.emit(true);
    } else {
      this.checkClick.emit(true);
    }
  }

  deleteClicked() {
    this.deleteClickedNotify.emit(true);
  }

  get cellSizeStyle() {
    switch (this.cellSize) {
      case 'M':
        return {
          height: '210px'
        };
      case 'S':
        return {
          height: '145px'
        };
      default:
        return;
    }
  }
}
