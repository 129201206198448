import { Store } from '@ngrx/store';
import * as MapServiceActions from './mapService.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapServiceDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetMapServicePaging() {
        this._store.dispatch(new MapServiceActions.ResetMapServicePaging());
    }
    getNextMapService() {
        this._store.dispatch(new MapServiceActions.GetNextMapServicePage());
    }
    getPrevMapService() {
        this._store.dispatch(new MapServiceActions.GetPrevMapServicePage());
    }
    getMapServiceByID(id) {
        this._store.dispatch(new MapServiceActions.GetMapServiceByID(id));
    }
    createMapService(mapService) {
        this._store.dispatch(new MapServiceActions.CreateMapService(mapService));
    }
    mapServiceCreated(mapService) {
        this._store.dispatch(new MapServiceActions.MapServiceCreated(mapService));
    }
    updateMapService(id, mapService) {
        this._store.dispatch(new MapServiceActions.UpdateMapService(id, mapService));
    }
    mapServiceDetailsReceived(mapService) {
        this._store.dispatch(new MapServiceActions.MapServiceDetailsReceived(mapService));
    }
    mapServiceListReceived(eop, sop, mapServices, hash) {
        this._store.dispatch(new MapServiceActions.MapServiceListReceived(eop, sop, mapServices, hash));
    }
    setCurrentScreen(screen) {
        this._store.dispatch(new MapServiceActions.SetCurrentScreen(screen));
    }
    deleteMapService(id) {
        this._store.dispatch(new MapServiceActions.DeleteMapService(id));
    }
    mapServiceDeleted(id) {
        this._store.dispatch(new MapServiceActions.MapServiceDeleted(id));
    }
    setStatusAsIdle() {
        this._store.dispatch(new MapServiceActions.SetStatusAsIdle());
    }
    Reset() {
        this._store.dispatch(new MapServiceActions.Reset());
    }
}
MapServiceDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapServiceDispatchers_Factory() { return new MapServiceDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapServiceDispatchers, providedIn: "root" });
