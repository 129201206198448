<!-- <div class="message-info"># {{ parentId | slice: -3 }}</div>
<div class="fill-space"></div>

<div class="btn-group" role="group">
  <i
    *ngIf="isNested"
    class="fas fa-chevron-left clickable-cursor"
    (click)="backEvent()"
    aria-hidden="true"
  >
  </i>

  <i
    class="fas fa-times close clickable-cursor"
    aria-hidden="true"
    (click)="closeEvent()"
  >
  </i>
</div> -->

<div class="container-header">
  <div class="back-btn" (click)="backEvent()" *ngIf="isNested">
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"></use>
    </svg>
  </div>
  Notifications
  <div class="cancel-btn" (click)="closeEvent()">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-cancel"></use>
    </svg>
  </div>
</div>
