/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-share.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-clipboard";
import * as i3 from "./post-share.component";
import * as i4 from "../../../abstract-notifiers/abstract-notifiers.service";
var styles_PostShareComponent = [i0.styles];
var RenderType_PostShareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostShareComponent, data: {} });
export { RenderType_PostShareComponent as RenderType_PostShareComponent };
export function View_PostShareComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "share-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Share With:"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "share-social"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "share-social-box facebook clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareOnSocial($event, "F") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fab fa-facebook-f"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "share-social-box twitter clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareOnSocial($event, "T") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fab fa-twitter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "share-social-box google clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareOnSocial($event, "G") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fab fa-google-plus-g"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "share-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "share-copy-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "share-copy-box-logo clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "share-copy-box-text truncate-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "share-copy-box-action clickable-cursor"], ["ngxClipboard", ""]], null, [[null, "cbOnSuccess"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("cbOnSuccess" === en)) {
        var pd_1 = (_co.copySuccessfullyEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 212992, null, 0, i2.ClipboardDirective, [i2.ClipboardService], { targetElm: [0, "targetElm"], cbContent: [1, "cbContent"] }, { cbOnSuccess: "cbOnSuccess" }), (_l()(), i1.ɵted(-1, null, [" Copy "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; var currVal_2 = _co.shareLink; _ck(_v, 17, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareLink; _ck(_v, 15, 0, currVal_0); }); }
export function View_PostShareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-share", [], null, null, null, View_PostShareComponent_0, RenderType_PostShareComponent)), i1.ɵdid(1, 573440, null, 0, i3.PostShareComponent, [i4.AbstractNotifiersService], null, null)], null, null); }
var PostShareComponentNgFactory = i1.ɵccf("app-post-share", i3.PostShareComponent, View_PostShareComponent_Host_0, { shareLink: "shareLink" }, {}, []);
export { PostShareComponentNgFactory as PostShareComponentNgFactory };
