import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { CalendarUiDispatchers } from 'src/app/store/calendarUI/calendarUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/calendarUI/calendarUI.dispatchers";
export class CalendarsService {
    constructor(_selectors, _calendarUiDispatchers) {
        this._selectors = _selectors;
        this._calendarUiDispatchers = _calendarUiDispatchers;
    }
    createCalendar(calendar) {
        this._calendarUiDispatchers.createCalendar(calendar);
    }
    updateCalendar(calendarId, calendar) {
        this._calendarUiDispatchers.updateCalendar(calendarId, calendar);
    }
    setCalendarDetails(calendar_id, data) {
        this._calendarUiDispatchers.setCalendarDetails(calendar_id, data);
    }
    // The "references" is an array of the IDs of hours that need to be deleted
    deleteCalendarDetail(calendar_id, references) {
        this._calendarUiDispatchers.deleteCalendarDetail(calendar_id, references);
    }
    viewCalendar(calendar) {
        this._calendarUiDispatchers.viewCalendarUI(calendar);
    }
    closeViewCalendar() {
        this._calendarUiDispatchers.closeViewCalendar();
    }
    showEditCalendarScreen(calendar) {
        this._calendarUiDispatchers.editCalendarUI(calendar);
    }
    closeEditCalendarScreen() {
        this._calendarUiDispatchers.closeEditCalendar();
    }
    showCreateCalendarScreen() {
        this._calendarUiDispatchers.createCalendarUI();
    }
    calendarCreationComplete() {
        this._calendarUiDispatchers.calendarCreationComplete();
    }
    getNextPage() {
        this._calendarUiDispatchers.getNextCalendarPage();
    }
    getPrevPage() {
        this._calendarUiDispatchers.getPrevCalendarPage();
    }
    resetPaging() {
        this._calendarUiDispatchers.resetCalendarPaging();
    }
    // calendar selectors
    get calendarChannelList$() {
        return this._selectors.getCalendarList$();
    }
    get selectedCalendar$() {
        return this._selectors.getSelectedCalendar$();
    }
    get calendarInCreationProcess$() {
        return this._selectors.getCalendarInCreationProcess$();
    }
    get calendarUiState$() {
        return this._selectors.calendarUiState$;
    }
}
CalendarsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarsService_Factory() { return new CalendarsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.CalendarUiDispatchers)); }, token: CalendarsService, providedIn: "root" });
