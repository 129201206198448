import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';

import { MessageMiddleware } from './message.middleware';

import { NotifyService } from 'src/app/core/notify.service';
import { FileService } from 'src/app/core/file.service';
import { HelperService } from 'src/app/core/helper.service';
import { UiService } from 'src/app/core/ui.service';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MessageGateway } from 'src/app/network/gateway/message.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { DownloadGateway } from 'src/app/network/gateway/download.gateway';
import { LinkPreviewGateway } from 'src/app/network/gateway/link-preview.gateway';

import { UIDispatchers } from './../ui/ui.dispatchers';
import { MessageDispatchers } from './message.dispatchers';
import { ChatMemberDispatchers } from '../chatMembers/chatMember.dispatchers';
import { RepliesEOPDispatchers } from '../repliesEOP/repliesEOP.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import { AuthDispatchers } from '../auth/auth.dispatchers';
import * as MessageActions from './message.actions';
import * as UiActions from './../ui/ui.actions';

import * as TagMethods from 'src/models/ITag';
import * as MenuCallBackStatsMethods from 'src/models/MenuCallBackStats';
import * as PostMethods from 'src/models/PostCounters';
import * as MessagesMethods from 'src/models/IMessage';
import { AppState } from 'src/models/AppState';
import { IMessage } from 'src/models/IMessage';
import { IUploadResponse } from 'src/models/IUploadResponse';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UIState } from 'src/models/UIState';
import { MainChat } from 'src/models/MainChat';
import { ReplyMessage } from 'src/models/ReplyMessage';
import { IMyProfile } from 'src/models/IMyProfile';
import { UrlPreviewMeta } from 'src/models/MetaData';
import { MessageControls } from 'src/models/MessageParts';
import { InstantArticle } from 'src/models/InstantArticle';
import {
  INDIVIDUAL,
  GROUP,
  CHANNEL,
  MessageSendingStatus,
  MessageStatuses,
  MessageActionTypes,
  PROJECT_NAME,
  MERCHANT_PROJECT,
  MessageTypes,
  NO_FILE_NAME,
  NOT_SUPPORTED_FILE_TYPE,
  FILE_SIZE_EXCEEDED,
  MessageMediaStatus,
  MessageLinkPreviewStatus,
  SERVER_PAGE_SIZE
} from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { CalendarTimetable } from 'src/models/Calendar';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { BotMenu } from 'src/models/IBotMenu';

import * as isEqual from 'lodash.isequal';
import { StorageService } from 'src/app/core/storage.service';
import { FILE_SIZE_ZERO_LENGTH } from './../../../models/constants';

const groupTimer = [];
@Injectable()
export class MessageEffects {
  uploadSubscriptions: Subscription[] = [];
  private _previewArrayBuffer: ArrayBuffer = null;

  @Effect({ dispatch: false })
  getLastPostsHistoryEffect = this.actions$.pipe(
    ofType(MessageActions.GET_LAST_POSTS_HISTORY),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.privileges)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([n, privileges]) => {
      if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
        this.getLastPostsHistory();
      }
    })
  );

  @Effect({ dispatch: false })
  getLastRepliesHistoryEffect = this.actions$.pipe(
    ofType(MessageActions.GET_LAST_REPLIES_HISTORY),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.privileges)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([n, privileges]) => {
      if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
        this.getLastRepliesHistory();
      }
    })
  );

  @Effect({ dispatch: false })
  listScheduleMessages = this.actions$.pipe(
    ofType(MessageActions.LIST_SCHEDULE_MESSAGES),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.privileges)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([n, privileges]) => {
      if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
        this.getScheduleMessagesHistory();
      }
    })
  );

  @Effect({ dispatch: false })
  listAwardMessages = this.actions$.pipe(
    ofType(MessageActions.LIST_AWARD_MESSAGES),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.privileges)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([n, privileges]) => {
      if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
        this.getAwardMessagesHistory();
      }
    })
  );

  @Effect({ dispatch: false })
  listTags = this.actions$.pipe(
    ofType(MessageActions.LIST_TAGS),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.privileges)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([n, privileges]) => {
      const action = <MessageActions.ListTags>n;
      if (privileges.indexOf(PrivilegesName.listtag) > -1) {
        this._socketGateway.sendSocketMessage(
          new TagMethods.GetTags(action.chat_ID)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  initRcvdMsg = this.actions$.pipe(
    ofType(MessageActions.INIT_RECEIVED_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer.selectedSubChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState, selectedSubChat]) => {
      const action = <MessageActions.InitReceivedMessage>val;
      if (
        action.payload.message.reply_to_message_id &&
        !this.isMessageExisting(
          {
            message_id: action.payload.message.reply_to_message_id
          },
          messagesState
        ) &&
        (!selectedSubChat || !selectedSubChat.onboard)
      ) {
        if (PROJECT_NAME === MERCHANT_PROJECT) {
          this._socketGateway.sendSocketMessage(
            new MessagesMethods.GetMessagesHistoryFromBusinessServer(
              action.payload.message.reply_to_message_id
            )
          );
        } else {
          // Do nothing as we do not have the parent message of the received message

          return;
        }
      }

      const loginID = this._authDispatchers.getLoginID();
      const key =
        action.payload.message.reply_to_message_id +
        '$' +
        action.payload.message.from.id;
      const messagesIds = this._storageService.getRecord(key);
      const msg = MessageMiddleware.initRcvdMessage(
        action.payload,
        loginID,
        JSON.parse(messagesIds)
      );

      // check if message is schedule or award
      if (msg.schedule_date) {
        this._messageDispatchers.receiveScheduleMessage(msg);
        return;
      } else if (msg.award) {
        this._messageDispatchers.receiveAwardMessage(msg);
        return;
      } else if (msg.welcome) {
        this._messageDispatchers.receiveWelcomeMessage(msg);
        return;
      }
      msg.existedBefore = this.isMessageExisting(msg, messagesState);

      if (
        !msg.updateStatus ||
        MessageStatuses.NEW === msg.updateStatus ||
        (!msg.existedBefore && MessageStatuses.UPDATED === msg.updateStatus)
      ) {
        msg.actionType = MessageActionTypes.NORMAL;
      } else if (
        msg.existedBefore &&
        MessageStatuses.UPDATED === msg.updateStatus
      ) {
        msg.actionType = MessageActionTypes.UPDATED;
      } else if (
        msg.existedBefore &&
        MessageStatuses.DELETED === msg.updateStatus
      ) {
        msg.actionType = MessageActionTypes.DELETED;
      }

      this._messageDispatchers.receiveMessage(msg);
    })
  );

  @Effect({ dispatch: false })
  messagesSeen = this.actions$.pipe(
    ofType(MessageActions.MESSAGES_SEEN_RECEIVED),
    map((action: MessageActions.MessagesSeenReceived) => {
      for (let i = 0; i < action.message_ids.length; i++) {
        this._messageDispatchers.messageSeen(action.message_ids[i]);
      }
    })
  );

  @Effect({ dispatch: false })
  hidePreview = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_HIDE_PREVIEW),
    map((action: UiActions.HidePreview) => {
      this._uiService._previewMediaMetadata.next({});
      this._uiService._previewMediaError.next(false);
      // should add effect in message effects on hide of preview to set this to null
      this._uiService._previewMessageType.next(null);
      this._uiService._previewFiles.next(null);
      this._previewArrayBuffer = null;
    })
  );

  @Effect({ dispatch: false })
  downloadMessageThumbnail = this.actions$.pipe(
    ofType(MessageActions.DOWNLOAD_MESSAGE_THUMBNAIL),
    map((action: MessageActions.DownloadMessageThumbnail) => {
      const msg = action.payload;
      if (!msg.localThumbnail && msg.thumbnail_id && !msg.thumbnailStatus) {
        msg.thumbnailStatus = MessageMediaStatus.DOWNLOADING;
        this._messageDispatchers.setMessageThumbnailStatus(msg);
        this._downloadGateway
          .downloadMediaFile(action.payload.thumbnail_id, 'image/png')
          .then(res => {
            this._messageDispatchers.setMessageLocalThumbnail({
              ...action.payload,
              localThumbnail: res,
              thumbnailStatus: MessageMediaStatus.DOWNLOADED
            });
          })
          .catch(err => {
            msg.localThumbnail = MessageMediaStatus.DOWNLOAD_FAILED;
            this._messageDispatchers.setMessageThumbnailStatus(msg);
          });
      }
    })
  );

  // CHAT_MESSAGES_OUT_OF_VIEW_PORT
  @Effect({ dispatch: false })
  cancelDownloadOfChatMsgs = this.actions$.pipe(
    ofType(MessageActions.CHAT_MESSAGES_OUT_OF_VIEW_PORT),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.ChatMessagesOutOfViewPort>val;

      const filteredMediaMsgs = messagesState.filter(
        msg =>
          msg.group_id &&
          ((action.groupID && msg.group_id === action.groupID) ||
            (!action.groupID && msg.group_type === 101)) &&
          (msg.mediaStatus === MessageMediaStatus.DOWNLOADING &&
            msg.loadingProgress &&
            msg.loadingProgress < 40)
      );

      filteredMediaMsgs.forEach(message =>
        this._messageDispatchers.cancelDownloadMediaMessage(message)
      );
    })
  );

  @Effect({ dispatch: false })
  cancelDownloadOfContactMsgs = this.actions$.pipe(
    ofType(MessageActions.CONTACT_MESSAGES_OUT_OF_VIEW_PORT),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.ContactMessagesOutOfViewPort>val;
      const filteredMediaMsgs = messagesState.filter(
        msg =>
          !msg.group_id &&
          action.id &&
          (action.id === msg.sender_id || action.id === msg.receiver_id) &&
          (msg.mediaStatus === MessageMediaStatus.DOWNLOADING &&
            msg.loadingProgress &&
            msg.loadingProgress < 40)
      );

      filteredMediaMsgs.forEach(message =>
        this._messageDispatchers.cancelDownloadMediaMessage(message)
      );
    })
  );

  @Effect({ dispatch: false })
  downloadMessageMedia = this.actions$.pipe(
    ofType(MessageActions.DOWNLOAD_MEDIA_MESSAGE),
    map((action: MessageActions.DownloadMediaMessage) => {
      const localType = this._fileService.getMimeTypeFromMessageType(
        action.payload.type
      );
      const msg: IMessage = action.payload;
      if (!msg.localMedia) {
        msg.mediaStatus = MessageMediaStatus.DOWNLOADING;
        this._messageDispatchers.setMessageMediaStatus(msg);
        this._downloadGateway
          .downloadMediaFile(
            action.payload.media_id,
            localType,
            false,
            msg.message_id,
            this._messageDispatchers
          )
          .then(res => {
            this._messageDispatchers.setMessageLocalMedia({
              ...action.payload,
              localMedia: res,
              mediaStatus: MessageMediaStatus.DOWNLOADED
            });
          })
          .catch(err => {
            msg.mediaStatus = MessageMediaStatus.DOWNLOAD_FAILED;
            this._messageDispatchers.setMessageMediaStatus(msg);
          });
      }
    })
  );

  @Effect({ dispatch: false })
  cancelDownloadMessageMedia = this.actions$.pipe(
    ofType(MessageActions.CANCEL_DOWNLOAD_MEDIA_MESSAGE),
    map((action: MessageActions.CancelDownloadMediaMessage) => {
      const msg: IMessage = action.payload;
      msg.mediaStatus = MessageMediaStatus.DOWNLOAD_FAILED;
      this._downloadGateway.cancelDownload(msg.message_id);
      this._messageDispatchers.setMessageMediaStatus(msg);
    })
  );

  @Effect({ dispatch: false })
  updateExistingMessage = this.actions$.pipe(
    ofType(MessageActions.UPDATE_EXISTING_MESSAGE),
    map((action: MessageActions.UpdateExistingMessage) => {
      const msg: IMessage = action.payload;
      if (msg.thumbnail_id) {
        this._messageDispatchers.downloadMessageThumbnail(action.payload);
      }
      if (msg.endOfPage && !msg.reply_to_message_id) {
        const targetChat: MainChat = {};
        if (!action.payload.group_id) {
          if (!action.payload.loggedInIsSender) {
            targetChat.id = action.payload.sender_id;
          } else {
            targetChat.id = action.payload.receiver_id;
          }
        } else {
          targetChat.id = action.payload.group_id;
        }
        this._mainChatDispatchers.updateEndOfPage(targetChat.id, msg.endOfPage);
      }
    })
  );

  @Effect({ dispatch: false })
  messageReceived = this.actions$.pipe(
    ofType(MessageActions.RECEIVE_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats, allProfiles]) => {
      const action = <MessageActions.ReceiveMessage>val;

      if (action.payload.thumbnail_id) {
        this._messageDispatchers.downloadMessageThumbnail(action.payload);
      }

      // const mainChat: MainChat = {};
      let targetChat: MainChat = {};

      if (action.payload.group_id) {
        targetChat = mainChats.find(
          chat => chat.id === action.payload.group_id
        );
      } else {
        if (!action.payload.loggedInIsSender) {
          targetChat = mainChats.find(
            chat => chat.id === action.payload.sender_id
          );
        } else {
          targetChat = mainChats.find(
            chat => chat.id === action.payload.receiver_id
          );
        }
      }
      // The following condition means do that if not a history message as the history message for now is considered read and viewed
      if (!action.payload.loggedInIsSender && !action.payload.isViewed) {
        let unreadMsgsCount: number;

        if (targetChat && !targetChat.selected) {
          unreadMsgsCount = targetChat.unreadCounter
            ? targetChat.unreadCounter
            : 0;
          this._mainChatDispatchers.updateNumberOfUnreadMessages(
            targetChat.id,
            ++unreadMsgsCount,
            !action.payload.reply_to_message_id ? true : false,
            false
          );
        } else if (targetChat && targetChat.selected) {
          if (!action.payload.reply_to_message_id) {
            this._mainChatDispatchers.incrementNumberOfNotViewedMessages(
              targetChat
            );
          } else {
            this._mainChatDispatchers.replyReceivedInSelectedChat(
              action.payload
            );
          }
        }
      }
      if (targetChat && targetChat.selected) {
        if (!action.payload.reply_to_message_id) {
          if (!action.payload.endOfPage) {
            this._uiService.msgRcvdInSelectedChatNotify();
          }
        }
        if (action.payload.endOfPage) {
          this._uiService.historyMsgRcvdInSelectedChatNotify(action.payload);
        }
      }
      if (action.payload && targetChat) {
        const mainChat = Object.assign({}, targetChat);
        if (targetChat.type === GROUP) {
          if (!action.payload.loggedInIsSender) {
            const senderProfile = allProfiles.find(
              prof => prof.user_id === mainChat.id
            );
            mainChat.lastSenderIDInGroup = action.payload.sender_id;
            mainChat.lastSenderNameInGroup = senderProfile
              ? senderProfile.name
              : action.payload.sender_name;
          } else {
            mainChat.lastSenderIDInGroup = null;
            mainChat.lastSenderNameInGroup = null;
          }
        }
        this._mainChatDispatchers.updateLastMessage(
          mainChat,
          this._helperService.handleDifferentReplyTypes(action.payload)
        );
        if (action.payload.endOfPage) {
          if (action.payload.level && action.payload.level === 1) {
            this._mainChatDispatchers.UpdateEopLevel1(
              targetChat.id,
              action.payload.endOfPage
            );
          }
          if (!action.payload.reply_to_message_id) {
            this._mainChatDispatchers.updateEndOfPage(
              targetChat.id,
              action.payload.endOfPage
            );
          } else {
            // this condition should match the condition of group replies and one to one reply in channels
            if (
              targetChat.type === GROUP ||
              (targetChat.type === CHANNEL && !targetChat.isAdmin)
            ) {
              this._repliesEOPDispatchers.updateFirstDepthEOP(
                action.payload.reply_to_message_id,
                action.payload.endOfPage,
                targetChat.id
              );
            } else if (targetChat.type === CHANNEL && targetChat.isAdmin) {
              if (action.payload.level === 3) {
                let userID = null;
                if (action.payload.from_admin) {
                  userID = action.payload.receiver_id;
                } else {
                  userID = action.payload.sender_id;
                }
                this._repliesEOPDispatchers.updateSecondDepthEOP(
                  action.payload.reply_to_message_id,
                  action.payload.endOfPage,
                  userID,
                  targetChat.id
                );
              } else if (action.payload.level === 2) {
                this._repliesEOPDispatchers.updateFirstDepthEOP(
                  action.payload.reply_to_message_id,
                  action.payload.endOfPage,
                  targetChat.id
                );
              }
            }
          }
        }
      }
      if (!action.payload.endOfPage && !action.payload.loggedInIsSender) {
        const date = new Date();
        let timer = 0;
        if (groupTimer[action.payload.sender_id]) {
          timer = date.getTime() - groupTimer[action.payload.sender_id];
        }
        if (targetChat) {
          if (
            (targetChat.type === 'Group' || targetChat.type === 'Channel') &&
            (timer === 0 || timer >= 40000)
          ) {
            groupTimer[action.payload.sender_id] = date.getTime();
            this._notfiyservice.showNotification(targetChat, action);
          } else if (targetChat.type === 'Individual') {
            this._notfiyservice.showNotification(targetChat, action);
          }
        } else {
          this._notfiyservice.showNotification(targetChat, action);
        }

        this._uiService._showNotifications.next(true);
      }

      if (this._uiService.masonryComponent) {
        this._uiService.masonryComponent.reloadItems();
        this._uiService.masonryComponent.layout();
      }
    })
  );

  // sending message after the store is updated, then update last message , and send message
  @Effect({ dispatch: false })
  sendingMsg = this.actions$.pipe(
    ofType(MessageActions.SENDING_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.SendingMessage>val;

      const mainChat: MainChat = {};
      if (!action.payload.group_id) {
        mainChat.id = action.payload.receiver_id;
      } else {
        mainChat.id = action.payload.group_id;
      }
      const message = messagesState.find(
        msg => msg.reference === action.payload.reference && !msg.message_id
      );

      const targetChat = Object.assign({}, mainChat);

      targetChat.lastSenderIDInGroup = null;
      targetChat.lastSenderNameInGroup = null;
      this._mainChatDispatchers.updateLastMessage(
        targetChat,
        this._helperService.handleDifferentReplyTypes(message)
      );

      if (this._uiService.masonryComponent) {
        this._uiService.masonryComponent.reloadItems();
        this._uiService.masonryComponent.layout();
      }
    })
  );

  @Effect({ dispatch: false })
  messageRecalled = this.actions$.pipe(
    ofType(MessageActions.MESSAGE_RECALLED),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState, mainChatsState, allProfiles, uiState]) => {
      const action = <MessageActions.MessageRecalled>val;

      let lastMessage: ReplyMessage = {};
      let targetChat: MainChat = {};

      if (
        uiState.selectedMessage &&
        action.message.message_id === uiState.selectedMessage.message_id
      ) {
        this._uiDispatchers.backFromGroupReplies();
      }

      if (!action.message.loggedInIsSender) {
        let unreadMsgsCount: number;
        if (!action.message.group_id) {
          targetChat = mainChatsState.find(
            chat => chat.id === action.message.sender_id
          );
        } else {
          targetChat = mainChatsState.find(
            chat => chat.id === action.message.group_id
          );
        }
        if (targetChat && !targetChat.selected) {
          unreadMsgsCount = targetChat.unreadCounter
            ? targetChat.unreadCounter
            : 0;
          if (!action.message.isRead) {
            this._mainChatDispatchers.updateNumberOfUnreadMessages(
              targetChat.id,
              unreadMsgsCount > 0 ? --unreadMsgsCount : 0,
              !action.message.reply_to_message_id ? true : false,
              true
            );
          }
        } else if (targetChat && targetChat.selected) {
          if (!action.message.isViewed && !action.message.reply_to_message_id) {
            this._mainChatDispatchers.decrementNumberOfNotViewedMessages(
              targetChat
            );
          }
        }
      } else {
        if (!action.message.group_id) {
          targetChat.id = action.message.receiver_id;
          targetChat.type = INDIVIDUAL;
        } else {
          targetChat.id = action.message.group_id;
          targetChat.type = action.message.group_type === 100 ? GROUP : CHANNEL;
        }
      }

      if (targetChat) {
        lastMessage = this._mainChatDispatchers.getLastMessageInChat(
          messagesState,
          targetChat
        );
        if (lastMessage) {
          const mainChat = Object.assign({}, targetChat);
          if (targetChat.type === GROUP) {
            mainChat.lastSenderIDInGroup = lastMessage.lastReplySenderID;
            const senderProfile = allProfiles.find(
              prof => prof.user_id === lastMessage.lastReplySenderID
            );
            mainChat.lastSenderNameInGroup = senderProfile
              ? senderProfile.name
              : null;
          }
          this._mainChatDispatchers.updateLastMessage(
            mainChat,
            lastMessage,
            true
          );
        } else {
          this._mainChatDispatchers.updateLastMessage(targetChat, null);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  msgSent = this.actions$.pipe(
    ofType(MessageActions.MESSAGE_SENT),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.MessageSent>val;

      const message = messagesState.find(
        msg => msg.message_id === action.payload.message_id
      );
      if (message) {
        const mainChat: MainChat = {};
        if (!message.group_id) {
          mainChat.id = message.receiver_id;
        } else {
          mainChat.id = message.group_id;
        }

        this._mainChatDispatchers.updateLastMessageStatus(
          mainChat.id,
          MessageSendingStatus.SENT,
          action.payload.reference
        );
      }
    })
  );

  @Effect({ dispatch: false })
  msgDelivered = this.actions$.pipe(
    ofType(MessageActions.MESSAGE_DELIVERED),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.MessageDelivered>val;

      const message = messagesState.find(
        msg => msg.message_id === action.payload.message_id
      );
      if (message) {
        const mainChat: MainChat = {};
        if (!message.group_id) {
          mainChat.id = message.receiver_id;
        } else {
          mainChat.id = message.group_id;
        }

        this._mainChatDispatchers.updateLastMessageStatus(
          mainChat.id,
          MessageSendingStatus.DELIVERED,
          action.payload.reference
        );
      }
    })
  );

  @Effect({ dispatch: false })
  msgSeen = this.actions$.pipe(
    ofType(MessageActions.MESSAGE_SEEN),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.MessageSeen>val;

      const message = messagesState.find(
        msg => msg.message_id === action.message_id
      );
      if (message) {
        const mainChat: MainChat = {};
        if (!message.group_id) {
          mainChat.id = message.receiver_id;
        } else {
          mainChat.id = message.group_id;
        }

        this._mainChatDispatchers.updateLastMessageStatus(
          mainChat.id,
          MessageSendingStatus.SEEN,
          message.reference
        );
      }
    })
  );

  @Effect({ dispatch: false })
  shareMessage = this.actions$.pipe(
    ofType(MessageActions.SHARE_MESSAGE),
    map((action: MessageActions.ShareMessage) => {
      const msg: IMessage = action.payload;
      const text = msg.media_id ? msg.caption : msg.text;
      this._socketGateway.sendSocketMessage(
        new PostMethods.SharePost(
          msg.sender_id,
          msg.sender_name,
          msg.message_id,
          msg.group_id,
          msg.group_name,
          msg.date,
          text,
          msg.media_id
        )
      );
    })
  );

  @Effect({ dispatch: false })
  likeMessage = this.actions$.pipe(
    ofType(MessageActions.LIKE_MESSAGE),
    map((action: MessageActions.LikeMessage) => {
      const msg: IMessage = action.payload;
      this._socketGateway.sendSocketMessage(
        new PostMethods.LikePost(1, msg.group_id, msg.message_id)
      );
    })
  );

  @Effect({ dispatch: false })
  unlikeMessage = this.actions$.pipe(
    ofType(MessageActions.UNLIKE_MESSAGE),
    map((action: MessageActions.UnlikeMessage) => {
      const msg: IMessage = action.payload;
      this._socketGateway.sendSocketMessage(
        new PostMethods.LikePost(0, msg.group_id, msg.message_id)
      );
    })
  );

  @Effect({ dispatch: false })
  historyMessage = this.actions$.pipe(
    ofType(MessageActions.HISTORY_EFFECT),
    withLatestFrom(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, messagesState]) => {
      const action = <MessageActions.HistoryEffect>val;

      const msg: IMessage = action.message;
      const parentMessageId = msg.reply_to_message_id
        ? msg.reply_to_message_id
        : msg.message_id;
      const parentMessage = messagesState.find(
        localMSG => parentMessageId === localMSG.message_id
      );
      this._uiDispatchers.messageSelected(parentMessage);
      if (msg.reply_to_message_id) {
        const memberId = msg.from_admin ? msg.send_to_user_id : msg.sender_id;
        this._uiDispatchers.selectOneToOneReply(memberId);
      }
    })
  );

  // Send Logic
  @Effect({ dispatch: false })
  sendMessageNowEffect = this.actions$.pipe(
    ofType(MessageActions.SEND_MESSAGE_NOW),
    map(val => {
      const action = <MessageActions.SendMessageNow>val;
      this._messageGateway.sendMessage(action.payload);
    })
  );

  @Effect({ dispatch: false })
  cancelScheduleMessageEffect = this.actions$.pipe(
    ofType(MessageActions.CANCEL_SCHEDULE_MESSAGE),
    map(val => {
      const action = <MessageActions.CancelScheduleMessage>val;
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.CancelScheduledMessage(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  sendTextEffect = this.actions$.pipe(
    ofType(MessageActions.SEND_TEXT),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.myProfileReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, uiState, myProfiles, menus]) => {
      const action = <MessageActions.SendTextMessage>val;
      const loggedInProfile = myProfiles.find(
        profile => profile.relation === 'Other'
      );

      if (action.misc && action.misc.inline_menu) {
        const responseMenu = this.getResponseMenu(
          menus.currentPage,
          action.misc.inline_menu[0].next_menu
        );

        if (responseMenu) {
          action.misc.inline_menu.push(responseMenu);
        }
      }
      this.sendText(
        action.mainChat,
        action.parentMessage,
        action.userId,
        uiState,
        loggedInProfile,
        action.misc,
        action.text,
        null,
        null
      );
    })
  );

  @Effect({ dispatch: false })
  sendInstantUrlEffect = this.actions$.pipe(
    ofType(MessageActions.SEND_INSTANT_URL),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.myProfileReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, uiState, myProfiles, menus]) => {
      const action = <MessageActions.SendInstantUrlMessage>val;
      const loggedInProfile = myProfiles.find(
        profile => profile.relation === 'Other'
      );
      if (action.misc && action.misc.inline_menu) {
        const responseMenu = this.getResponseMenu(
          menus.currentPage,
          action.misc.inline_menu[0].next_menu
        );
        if (responseMenu) {
          action.misc.inline_menu.push(responseMenu);
        }
      }
      this.sendText(
        action.mainChat,
        action.parentMessage,
        action.userId,
        uiState,
        loggedInProfile,
        action.misc,
        null,
        action.instantArticle,
        null
      );
    })
  );

  @Effect({ dispatch: false })
  sendMediaEffect = this.actions$.pipe(
    ofType(MessageActions.SEND_MEDIA),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.myProfileReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, uiState, myProfiles, menus]) => {
      const action = <MessageActions.SendMediaMessage>val;
      const loggedInProfile = myProfiles.find(
        profile => profile.relation === 'Other'
      );
      if (action.misc && action.misc.inline_menu) {
        const responseMenu = this.getResponseMenu(
          menus.currentPage,
          action.misc.inline_menu[0].next_menu
        );
        if (responseMenu) {
          action.misc.inline_menu.push(responseMenu);
        }
      }
      this.sendMedia(
        action.mainChat,
        action.parentMessage,
        action.userId,
        uiState,
        loggedInProfile,
        action.file,
        action.mediaType,
        action.previewMetadata,
        action.blobUrl,
        action.misc
      );
    })
  );

  @Effect({ dispatch: false })
  sendCalendarEffect = this.actions$.pipe(
    ofType(MessageActions.SEND_CALENDAR),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.myProfileReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, uiState, myProfiles, menus]) => {
      const action = <MessageActions.SendCalendarMessage>val;
      const loggedInProfile = myProfiles.find(
        profile => profile.relation === 'Other'
      );
      if (action.misc && action.misc.inline_menu) {
        const responseMenu = this.getResponseMenu(
          menus.currentPage,
          action.misc.inline_menu[0].next_menu
        );
        if (responseMenu) {
          action.misc.inline_menu.push(responseMenu);
        }
      }
      this.sendText(
        action.mainChat,
        action.parentMessage,
        action.userId,
        uiState,
        loggedInProfile,
        action.misc,
        null,
        null,
        action.calendar
      );
    })
  );

  /*************************************************************************************/

  @Effect({ dispatch: false })
  getBlobUrlEffect = this.actions$.pipe(
    ofType(MessageActions.GET_BLOB_URL),
    map((action: MessageActions.GetBlobUrl) => {
      this.getblobUrl(action.selectedFiles);
    })
  );

  @Effect({ dispatch: false })
  retryOperation = this.actions$.pipe(
    ofType(MessageActions.RETRY_MEDIA_OPERATION),
    map((action: MessageActions.RetryMediaOperation) => {
      this.retryEvent(action.payload);
    })
  );

  @Effect({ dispatch: false })
  cancelOperation = this.actions$.pipe(
    ofType(MessageActions.CANCEL_MEDIA_OPERATION),
    map((action: MessageActions.CancelMediaOperation) => {
      this.cancelEvent(action.payload);
    })
  );

  @Effect({ dispatch: false })
  previewLinkMsg = this.actions$.pipe(
    ofType(MessageActions.PREVIEW_LINK_MESSAGE),
    map((action: MessageActions.PreviewLinkMessage) => {
      this.previewLinkMessage(action.payload);
    })
  );

  @Effect({ dispatch: false })
  recallMessage = this.actions$.pipe(
    ofType(MessageActions.RECALL_MESSAGE),
    map(val => {
      const action = <MessageActions.RecallMessage>val;
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.RecallMessage(
          action.message.group_id, // set groupId from message
          action.message.message_id,
          action.message.reference,
          action.message.send_to_user_id
        )
      );
    })
  );

  @Effect({ dispatch: false })
  sendingCreateTag = this.actions$.pipe(
    ofType(MessageActions.SENDING_CREATE_TAG),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <MessageActions.SendingCreateTag>val;
      this._socketGateway.sendSocketMessage(
        new TagMethods.SetTags(
          action.payload.id,
          action.payload.description,
          action.payload.name,
          action.payload.isPrivate,
          action.payload.recordIndex
        )
      );
    })
  );

  @Effect({ dispatch: false })
  sendingDeleteTag = this.actions$.pipe(
    ofType(MessageActions.SENDING_DELETE_TAG),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <MessageActions.SendingDeleteTag>val;
      this._socketGateway.sendSocketMessage(
        new TagMethods.DeleteTags([action.payload.id])
      );
    })
  );

  @Effect({ dispatch: false })
  sendingSetMemberTags = this.actions$.pipe(
    ofType(MessageActions.SENDING_SET_MEMBER_TAGS),
    map((action: MessageActions.SendingSetMemberTags) => {
      this._chatMemberDispatchers.chatMemberUpdated({
        user_id: action.payload.user_id,
        tags: action.payload.tags
      });
      this._socketGateway.sendSocketMessage(
        new TagMethods.SetMemberTags(
          action.payload.user_id,
          action.payload.tags ? action.payload.tags : []
        )
      );
    })
  );

  @Effect({ dispatch: false })
  sendingGetMessageMenuStats = this.actions$.pipe(
    ofType(MessageActions.GET_MESSAGE_MENU_STATS),
    map((action: MessageActions.GetMessageMenuStats) => {
      this._socketGateway.sendSocketMessage(
        new MenuCallBackStatsMethods.GetMyCallBacksStats(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  sendingGetInlineMessageCallBack = this.actions$.pipe(
    ofType(MessageActions.GET_INLINE_MESSAGE_CALLBACK),
    map((action: MessageActions.GetInlineMessageCallBack) => {
      this._socketGateway.sendSocketMessage(
        new MessagesMethods.InlineCallBackMessage(
          action.payload.to_user_id,
          action.payload.button_callback,
          null,
          action.payload.chat_id,
          action.payload.message_id,
          action.payload.menu_ref,
          action.payload.button_label,
          action.payload.reference
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _uploadGateway: UploadGateway,
    private _downloadGateway: DownloadGateway,
    private _messageGateway: MessageGateway,
    private _socketGateway: SocketGateway,
    private _linkPreviewGateway: LinkPreviewGateway,
    private _uiService: UiService,
    private _fileService: FileService,
    private _helperService: HelperService,
    private _notfiyservice: NotifyService,
    private _uiDispatchers: UIDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _authDispatchers: AuthDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _mainChatDispatchers: MainChatDispatchers,
    private _chatMemberDispatchers: ChatMemberDispatchers,
    private _repliesEOPDispatchers: RepliesEOPDispatchers,
    private _storageService: StorageService,
    private _store: Store<AppState>
  ) {}

  private getAwardMessagesHistory() {
    // get award messages

    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetMessagesHistoryFromBusinessServer(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        10 * SERVER_PAGE_SIZE,
        null,
        0,
        2
      )
    );
  }
  private getScheduleMessagesHistory() {
    // get schedule messages
    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetScheduledMessagesHistory()
    );
  }
  private getLastPostsHistory() {
    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetMessagesHistoryFromBusinessServer(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        SERVER_PAGE_SIZE,
        null,
        0,
        0
      )
    );
  }

  private getLastRepliesHistory() {
    // request to get replies message to order posts by lastReplay
    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetMessagesHistoryFromBusinessServer(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        SERVER_PAGE_SIZE,
        1,
        0,
        0
      )
    );
  }

  private isMessageExisting(msg: IMessage, messagesState: IMessage[]): boolean {
    const existingMessage = messagesState.find(
      message =>
        (message.message_id && message.message_id === msg.message_id) ||
        (message.sender_id &&
          message.sender_id === msg.sender_id &&
          message.reference &&
          message.reference === msg.reference) ||
        (!message.sender_id &&
          message.reference &&
          message.reference === msg.reference)
    );
    if (existingMessage) {
      return true;
    } else {
      return false;
    }
  }

  private setMessageMisc(misc: MessageControls): IMessage {
    const msg: IMessage = {};

    for (const key in misc) {
      if (misc.hasOwnProperty(key)) {
        msg[key] = misc[key];
      }
    }
    return msg;
  }

  private sendText(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    uiState: UIState,
    loggedInProfile: IMyProfile,
    misc: MessageControls,
    text?: string,
    instantArticle?: InstantArticle,
    calendar?: CalendarTimetable
  ) {
    let msg = MessageMiddleware.initMessageToBeSent(
      mainChat,
      parentMessage,
      userId,
      uiState,
      loggedInProfile
    );
    const msgMisc = this.setMessageMisc(misc);
    msg = { ...msg, ...msgMisc };

    /**
     * This types are the same using same attr but diffrent message type
     */
    if (instantArticle) {
      msg.type = MessageTypes.ARTICLE;
      msg.url = instantArticle.linkPreviewUrl;
      // local Use
      msg.linkPreviewStatus = instantArticle.linkPreviewStatus;
      msg.linkPreviewUrl = instantArticle.linkPreviewUrl;
      msg.linkPreviewRootUrl = instantArticle.linkPreviewRootUrl;
      msg.linkPreviewTitle = instantArticle.linkPreviewTitle;
      msg.linkPreviewDescription = instantArticle.linkPreviewDescription;
      msg.linkPreviewImage = instantArticle.linkPreviewImage;
      msg.linkPreviewWidth = instantArticle.linkPreviewWidth;
      msg.linkPreviewHeight = instantArticle.linkPreviewHeight;
    } else if (calendar) {
      msg.type = MessageTypes.CALENDAR;
      msg.json = calendar;
    } else if (text) {
      msg.type = MessageTypes.TEXT;
      msg.text = text;
    }

    /**
     * For ui effect
     */
    if (msg.isChannelPost) {
      this._uiService.scrollToUpMethod();
    } else {
      this._uiService.scrollToBottomMethod();
    }
    this.sendMessage(msg);
  }

  private sendMedia(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    uiState: UIState,
    loggedInProfile: IMyProfile,
    file: File,
    mediaType: string,
    previewMetadata?: UrlPreviewMeta,
    blobUrl?: string,
    misc?: MessageControls
  ) {
    let msg = MessageMiddleware.initMessageToBeSent(
      mainChat,
      parentMessage,
      userId,
      uiState,
      loggedInProfile
    );
    msg.mediaStatus = MessageMediaStatus.UPLOADING;
    msg.reference = this._helperService.getCurrentTime();
    msg.type = mediaType;

    if (misc) {
      const msgMisc = this.setMessageMisc(misc);
      msg = { ...msg, ...msgMisc };
    }
    msg.title = file.name;
    msg.file_name = file.name;
    msg.media_id = file.name;
    msg.file_size = file.size;
    msg.tempFile = file;
    if (msg.isChannelPost) {
      this._uiService.scrollToUpMethod();
    } else {
      this._uiService.scrollToBottomMethod();
    }
    /* chek if  previewMetadata exist for media ( PHOTO, VIDEO & GIF ) */
    if (previewMetadata) {
      msg.localMedia = previewMetadata.mediaUrl;
      msg.document_name = previewMetadata.fileName;
      msg.media_height = previewMetadata.height ? previewMetadata.height : null;
      msg.media_width = previewMetadata.width ? previewMetadata.width : null;
      msg.caption = previewMetadata.caption ? previewMetadata.caption : null;
      msg.media_duration = previewMetadata.duration
        ? previewMetadata.duration
        : null;

      if (msg.schedule_date) {
        this._messageDispatchers.sendingScheduleMessage(msg);
      } else if (msg.award) {
        this._messageDispatchers.sendingAwardMessage(msg);
      } else if (msg.welcome) {
        this._chatDispatchers.sendingWelcomeMessage(msg);
        this._messageDispatchers.sendingWelcomeMessage(msg);
      } else {
        this._messageDispatchers.sendingMessage(msg);
      }
      this.uploadAndSendMsg(msg, this._previewArrayBuffer, file);
    } else if (!previewMetadata) {
      // this for voice note only
      if (!file.name) {
        this._uiDispatchers.showPopup(NO_FILE_NAME);
        return;
      } else if (!this._fileService.isMimeTypeValid(mediaType, file.type)) {
        this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
        return;
      } else if (!this._fileService.isFileSizeValid(mediaType, file.size)) {
        this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
        return;
      } else if (file.size <= 0) {
        this._uiDispatchers.showPopup(FILE_SIZE_ZERO_LENGTH);
        return;
      }

      /* Set local media info in store then upload and send media */
      this._fileService.getAudioMetadata(blobUrl).then(res => {
        msg.media_duration = res.duration;
        msg.localMedia = blobUrl;

        if (msg.schedule_date) {
          this._messageDispatchers.sendingScheduleMessage(msg);
        } else if (msg.award) {
          this._messageDispatchers.sendingAwardMessage(msg);
        } else if (msg.welcome) {
          this._chatDispatchers.sendingWelcomeMessage(msg);
          this._messageDispatchers.sendingWelcomeMessage(msg);
        } else {
          this._messageDispatchers.sendingMessage(msg);
        }
        this._fileService
          .readFileAsArrayBuffer(file)
          .then(fileAsArrayBuffer => {
            this.uploadAndSendMsg(msg, fileAsArrayBuffer, file);
          });
      });
    }
  }

  private uploadAndSendMsg(
    msg: IMessage,
    fileAsArrayBuffer: ArrayBuffer,
    file: File
  ) {
    let fileType = file.type;
    if (file.name.includes('.rar')) {
      fileType = 'application/x-rar-compressed';
    }
    let progressMonitor = 0;
    this.uploadSubscriptions[
      msg.reference
    ] = this._uploadGateway
      .uploadWithProgress(
        fileAsArrayBuffer,
        fileType,
        file.name,
        msg.type === MessageTypes.DOCUMENT
      )
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progressVal = Math.round((event.loaded / event.total) * 100);
            if (progressVal - progressMonitor >= 18 || progressVal === 100) {
              progressMonitor = progressVal;
              this._messageDispatchers.updateLoadingProgress(msg, progressVal);
            }
          } else if (event.type === HttpEventType.Response) {
            const res = <IUploadResponse>event.body;
            msg.media_id = res.file;
            msg.mediaStatus = MessageMediaStatus.UPLOADED;
            msg.tempFile = null;
            msg.loadingProgress = 100;
            this.sendMessage(msg);
            delete this.uploadSubscriptions[msg.reference];
          }
        },
        error => {
          msg.mediaStatus = MessageMediaStatus.UPLOAD_FAILED;
          this._messageDispatchers.setMessageMediaStatus(msg);
        }
      );
  }

  private retryToUploadAndSendMsg(msg: IMessage) {
    msg.mediaStatus = MessageMediaStatus.UPLOADING;
    this._messageDispatchers.setMessageMediaStatus(msg);
    this._fileService
      .readFileAsArrayBuffer(msg.tempFile)
      .then(fileAsArrayBuffer => {
        this.uploadAndSendMsg(msg, fileAsArrayBuffer, msg.tempFile);
      });
  }

  private sendMessage(msg: IMessage) {
    // Check message type to add to the right store
    msg.reference = msg.reference
      ? msg.reference
      : this._helperService.getCurrentTime();
    if (msg.schedule_date) {
      this._messageDispatchers.sendingScheduleMessage(msg);
    } else if (msg.award) {
      this._messageDispatchers.sendingAwardMessage(msg);
    } else if (msg.welcome) {
      this._chatDispatchers.sendingWelcomeMessage(msg);
      this._messageDispatchers.sendingWelcomeMessage(msg);
    } else {
      this._messageDispatchers.sendingMessage(msg);
    }
    this._messageGateway.sendMessage(msg);
  }

  private cancelUploadMediaFile(message: IMessage) {
    if (this.uploadSubscriptions[message.reference]) {
      this.uploadSubscriptions[message.reference].unsubscribe();
      delete this.uploadSubscriptions[message.reference];
      message.mediaStatus = MessageMediaStatus.UPLOAD_FAILED;
      this._messageDispatchers.setMessageMediaStatus(message);
    }
  }

  private cancelEvent(message: IMessage) {
    if (message.mediaStatus === MessageMediaStatus.UPLOADING) {
      this.cancelUploadMediaFile(message);
    } else if (message.mediaStatus === MessageMediaStatus.DOWNLOADING) {
      this._messageDispatchers.cancelDownloadMediaMessage(message);
    }
  }

  private retryEvent(message: IMessage) {
    if (message.mediaStatus === MessageMediaStatus.UPLOAD_FAILED) {
      this.retryToUploadAndSendMsg(message);
    } else if (message.mediaStatus === MessageMediaStatus.DOWNLOAD_FAILED) {
      this._messageDispatchers.downloadMediaMessage(message);
    }
  }

  private getblobUrl(event: ISelectedFiles) {
    /* check on name, type and size before preview */

    this._uiDispatchers.hideDragDropScreen();
    if (!event.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return;
    } else if (
      !this._fileService.isMimeTypeValid(event.type, event.localFile.type)
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return;
    } else if (
      !this._fileService.isFileSizeValid(event.type, event.localFile.size)
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return;
    } else if (event.localFile.size <= 0) {
      this._uiDispatchers.showPopup(FILE_SIZE_ZERO_LENGTH);
      return;
    }
    this._uiDispatchers.showPreview();
    this._uiService._previewFiles.next(event.localFile);

    const previewMetadata: UrlPreviewMeta = {};
    previewMetadata.fileName = event.localFile.name;
    previewMetadata.fileSize = event.localFile.size;

    this._fileService
      .readFileAsArrayBuffer(event.localFile)
      .then(fileAsArrayBuffer => {
        this._previewArrayBuffer = fileAsArrayBuffer;
        const mediaToGenrate =
          event.type === MessageTypes.GIF &&
          event.localFile.name.endsWith('.mp4')
            ? MessageTypes.VIDEO
            : event.type;
        this._fileService
          .readArrayBufferAsBlobUrl(fileAsArrayBuffer, mediaToGenrate)
          .then(blobUrl => {
            previewMetadata.mediaUrl = blobUrl;
            if (
              event.type === MessageTypes.PHOTO ||
              (event.type === MessageTypes.GIF &&
                event.localFile.name.endsWith('.gif'))
            ) {
              this._fileService
                .getImageMetadata(blobUrl)
                .then(res => {
                  previewMetadata.width = res.width;
                  previewMetadata.height = res.height;

                  this._uiService._previewMessageType.next(event.type);
                  this._uiService._previewMediaMetadata.next(previewMetadata);
                })
                .catch(err => {
                  this._uiService._previewMediaError.next(true);
                });
            } else if (
              event.type === MessageTypes.VIDEO ||
              (event.type === MessageTypes.GIF &&
                event.localFile.name.endsWith('.mp4'))
            ) {
              this._fileService
                .getVideoMetadata(blobUrl)
                .then(res => {
                  previewMetadata.width = res.width;
                  previewMetadata.height = res.height;
                  previewMetadata.duration = res.duration;
                  if (!res.width) {
                    this._uiService._previewMessageType.next(
                      MessageTypes.AUDIO
                    );
                  } else {
                    this._uiService._previewMessageType.next(event.type);
                  }
                  this._uiService._previewMediaMetadata.next(previewMetadata);
                })
                .catch(err => {
                  this._uiService._previewMediaError.next(true);
                });
            } else if (event.type === MessageTypes.AUDIO) {
              this._fileService
                .getAudioMetadata(blobUrl)
                .then(res => {
                  previewMetadata.duration = res.duration;
                  this._uiService._previewMessageType.next(event.type);
                  this._uiService._previewMediaMetadata.next(previewMetadata);
                })
                .catch(err => {
                  this._uiService._previewMediaError.next(true);
                });
            } else if (event.type === MessageTypes.DOCUMENT) {
              this._uiService._previewMessageType.next(event.type);
              this._uiService._previewMediaMetadata.next(previewMetadata);
            }
          });
      });
  }

  private previewLinkMessage(message: IMessage): void {
    if (message.type === MessageTypes.TEXT) {
      const url = this._linkPreviewGateway.getFirstLink(message.text);
      if (url) {
        this.setPreviewLinkDetails(message, url);
      }
    } else if (message.type === MessageTypes.ARTICLE) {
      if (message.url) {
        this.setPreviewLinkDetails(message, message.url);
      }
    }
  }

  private setPreviewLinkDetails(message: IMessage, url: string) {
    const msg: IMessage = { ...message };
    this._messageDispatchers.setLinkPreviewStatus({
      ...message,
      linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FETCHING
    });
    this._linkPreviewGateway.getMetaFormUrl(url).subscribe(
      res => {
        if (res) {
          msg.linkPreviewUrl = res.linkPreviewUrl;
          msg.linkPreviewTitle = res.linkPreviewTitle;
          msg.linkPreviewDescription = res.linkPreviewDescription;
          msg.linkPreviewRootUrl = res.linkPreviewRootUrl;
          msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FETCHED;
          if (res.linkPreviewImage) {
            msg.linkPreviewImage = this._linkPreviewGateway.replaceHttp(
              res.linkPreviewImage
            );
            if (msg.type === MessageTypes.TEXT) {
              this._fileService
                .getImageMetadata(res.linkPreviewImage)
                .then(imageMeta => {
                  msg.linkPreviewWidth = imageMeta.width;
                  msg.linkPreviewHeight = imageMeta.height;
                  this._messageDispatchers.linkPreviewDetailsReceived(msg);
                })
                .catch(err => {
                  this._messageDispatchers.linkPreviewDetailsReceived(msg);
                });
            } else {
              this._messageDispatchers.linkPreviewDetailsReceived(msg);
            }
          } else {
            this._messageDispatchers.linkPreviewDetailsReceived(msg);
          }
        } else {
          this._messageDispatchers.setLinkPreviewStatus({
            ...message,
            linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FAILED
          });
        }
      },
      err => {
        msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FAILED;
        this._messageDispatchers.linkPreviewDetailsReceived(msg);
      },
      () => {}
    );
  }

  private getResponseMenu(menus: BotMenu[], menu_ref: string) {
    return menus.find(m => m.menu_ref === menu_ref);
  }

  private setTheCounterInStorage(massageID: string, massageSate) {
    this._storageService.setRecord(massageID, JSON.stringify(massageSate));
  }
}
