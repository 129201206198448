import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { RootStoreService } from 'src/app/store/root-store.service';
import { IAuth } from 'src/models/IAuth';
import { AuthStatus, UISections } from 'src/models/constants';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { StorageService } from 'src/app/core/storage.service';
import { AppConfigDispatchers } from 'src/app/store/channelAppConfig/appConfig.dispatchers';
@Injectable({
  providedIn: 'root'
})
export class SocketHandlerService {
  webSocketSubscription = null;
  constructor(
    private _router: Router,
    private _socketService: SocketService,
    private _rootStoreService: RootStoreService,
    private location: Location,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private localStorage: StorageService,
    private _appConfigDispatchers: AppConfigDispatchers
  ) {
    this.webSocketSubscription = this._socketService.webSocketObservable$
      .pipe(distinctUntilChanged())
      .subscribe(skObj => {
        if (skObj) {
          skObj.onmessage = (evt: any) => this.onSocketMessage(evt.data);
        }
      });

    this._socketService.authStore$
      .pipe(
        distinctUntilChanged(
          (p: IAuth, q: IAuth) => p.authStatus === q.authStatus
        )
      )
      .subscribe(state => {
        const urlInfo = this.location.path();
        const path = urlInfo.split('?');
        switch (state.authStatus) {
          case AuthStatus.AUTH_QR_SET:
            this.route.queryParams.subscribe(params => {
              if (params.lmref && params.lmref !== 'undefined') {
                this.localStorage.setRecord('lmref', params.lmref);
              }
              // if (params.id && params.id !== 'undefined') {
              //   this.localStorage.setRecord('tempId', params.id);
              // }
            });

            if (
              path[0] !== '/login' &&
              path[0] !== '/signup' &&
              path[0] !== '/spt'
            ) {
              this._router.navigate(['/login'], {
                queryParamsHandling: 'merge'
              });
            }

            break;
          case AuthStatus.AUTH_CHAT_SELECTED:
            const url = this.location.path();
            const urlPath = url.split('?');

            if (
              state.role == 1 &&
              state.chatId === state.main_group_id &&
              urlPath[0] !== '/signup' &&
              urlPath[0] !== '/verifyCode'
            ) {
              this._router.navigate(['/app']);

              this.dashboardService.setSection(UISections.APP);
            }
            break;
        }
      });
  }

  private onSocketMessage(dataOnSocket: any) {
    this._socketService.setPingTimer();
    let data = '';
    if (environment.encodeSocket) {
      data = this.convertArrayBufferToMessage(dataOnSocket);
    } else {
      data = dataOnSocket;
    }
    this._rootStoreService.redirectMessage(JSON.parse(data));
  }

  convertArrayBufferToMessage(byteArray: Uint8Array) {
    return new (<any>window).TextDecoder().decode(byteArray);
  }
}
