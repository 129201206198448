<!-- <app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">
    Select Calendar
  </ng-container>

  <ng-container ngProjectAs="[form-selector-items]">
    <ng-container
      *ngIf="
        (calendarService.calendarChannelList$ | async)?.length > 0;
        else isEmpty
      "
    >
      <app-round-paging-btn
        class="paging-float-btn"
        (nextBtnClicked)="calendarService.getNextPage()"
        (previousBtnClicked)="calendarService.getPrevPage()"
      >
      </app-round-paging-btn>
      <app-selectable-cell-frame
        *ngFor="
          let calendar of calendarService.calendarChannelList$
            | async
            | search: (searchTerm$ | async):'title';
          trackBy: TrackByFunction
        "
        [symbolColor]="calendarFet?.symbolColor"
        [symbolImage]="calendarFet?.symbolImage"
        [coverColor]="calendarFet?.coverColor"
        [coverPhoto]="calendar?.url"
        [title]="calendar?.title"
        [desc]="calendar?.desc"
        [selectorId]="calendar?.id"
        [isSelected]="calendar?.id === currentSelectMessage?.id"
        (checkClick)="setSelectMessage(calendar)"
        (checkChanged)="resetSelectMessage()"
      >
      </app-selectable-cell-frame>
    </ng-container>

    <ng-template #isEmpty>
      <app-empty-item-frame
        [emptySymbol]="calendarFet?.symbolImage"
        [emptyTitle]="calendarFet?.emptyTitle"
        [emptyDesc]="calendarFet?.emptyPoolDesc"
      >
      </app-empty-item-frame>
    </ng-template>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn
        *ngIf="currentSelectMessage"
        submit
        (btnClicked)="insertEvent()"
      >
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame> -->

<app-form-frame no-padding [showCancel]="true" (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">{{
    'SELECTCALENDER' | translate
  }}</ng-container>
  <!-- <ng-container ngProjectAs="desc">
    Manage your products in this collection or create a new products.
  </ng-container> -->
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container
          *ngIf="calendarService.calendarChannelList$ | async as calenders"
        >
          <app-selectable-cell-frame
            *ngFor="let calendar of calenders; trackBy: TrackByFunction"
            [coverPhoto]="calendar?.url"
            [title]="calendar?.title"
            [selectorId]="calendar?.id"
            [isSelected]="calendar?.id === currentSelectMessage?.id"
            [useNewStely]="true"
            [cellSize]="'M'"
            (checkClick)="setSelectMessage(calendar)"
            (checkChanged)="resetSelectMessage()"
          >
          </app-selectable-cell-frame>
        </ng-container>
        <app-empty-item-frame
          [emptySymbol]="calendarFet?.symbolImage"
          [emptyTitle]="calendarFet?.emptyTitle"
          [emptyDesc]="calendarFet?.emptyPoolDesc"
          *ngIf="(calendarService.calendarChannelList$ | async)?.length === 0"
        >
        </app-empty-item-frame>
      </div>
      <div
        class="navigate"
        *ngIf="(calendarService.calendarChannelList$ | async)?.length > 0"
      >
        <div class="navigate-btn" (click)="calendarService?.getNextPage()">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
        <div class="navigate-btn" (click)="calendarService?.getPrevPage()">
          <svg class="svg-icon icon-icon" style="transform: rotate(180deg);">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button
        class="add-btn"
        (click)="insertEvent()"
        [disabled]="
          (calendarService.calendarChannelList$ | async)?.length === 0
        "
      >
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
