import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { MainChat } from 'src/models/MainChat';
import { UiService } from 'src/app/core/ui.service';
import { AppInfo } from 'src/models/ChannelAppConfig';

import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { AuthService } from 'src/app/core/auth.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-startover-logo',
  templateUrl: './startover-logo.component.html',
  styleUrls: ['./startover-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverLogoComponent implements OnInit, OnChanges {
  @Input() page: any;
  @Input() mode: any;
  @Output() saveNotify = new EventEmitter<string>();

  constructor(
    public layoutService: LayoutService,
    public authService: AuthService,
    public uiService: UiService,
    public _dashboardService: DashboardService,
    public appManagementService: AppMgmtService,
    private _route: Router
  ) {}

  ngOnChanges() {
    this.layoutService.getDocumentationLinkOfSection('startOver', this.page);
  }

  ngOnInit() {}

  selectChatEvent(chat: MainChat) {
    this.layoutService.deselectChannel();
    this.authService.selectChat(chat.id);
  }

  logoutEvent($) {
    this._dashboardService.logout();
    this._route.navigate(['/login']);
  }

  TrackByFunction(index, chat: MainChat) {
    return chat.id;
  }

  openHelpWindow(status) {
    this.uiService.toggleHelpWindow(status);
  }

  toggleTooltip(status) {
    const appInfo: AppInfo = {};

    appInfo.help_link = ++status;
    this.appManagementService.updateAppInfo(appInfo);
  }

  saveCat(event) {
    this.saveNotify.emit(event);
  }
}
