import { Action } from '@ngrx/store';

export const enum BulkActionTypes {
  RECORD_CREATED = 'RECORD_CREATED'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class RecordCreated implements Action {
  readonly type = BulkActionTypes.RECORD_CREATED;
  constructor(
    public payload: number,
    public recordType: string,
    public id: string,
    public ref: string
  ) {}
}

// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type BulkActions = RecordCreated | DeselectChannel | Reset;
