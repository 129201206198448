/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-temps.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./startover-temp-item/startover-temp-item.component.ngfactory";
import * as i3 from "./startover-temp-item/startover-temp-item.component";
import * as i4 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i5 from "@angular/common";
import * as i6 from "./startover-temps.component";
import * as i7 from "../../../core/auth.service";
import * as i8 from "@angular/router";
var styles_StartoverTempsComponent = [i0.styles];
var RenderType_StartoverTempsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverTempsComponent, data: {} });
export { RenderType_StartoverTempsComponent as RenderType_StartoverTempsComponent };
function View_StartoverTempsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-temp-item", [], null, [[null, "setTempReview"], [null, "setTempNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setTempReview" === en)) {
        var pd_0 = (_co.reviewTemp($event) !== false);
        ad = (pd_0 && ad);
    } if (("setTempNotify" === en)) {
        var pd_1 = (_co.setTemp($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_StartoverTempItemComponent_0, i2.RenderType_StartoverTempItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartoverTempItemComponent, [i4.AppMgmtService], { temp: [0, "temp"], index: [1, "index"], seeMore: [2, "seeMore"] }, { setTempNotify: "setTempNotify", setTempReview: "setTempReview" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _v.parent.context.index; var currVal_2 = _co.seeMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_StartoverTempsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempsComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.title) !== "General"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverTempsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "lds-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_StartoverTempsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { tempsContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "temps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "category"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Start your app with a template "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please choose a similar app, this will allow us to understand your idea better. "])), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["tempsContainer", 1]], null, 2, "div", [["class", "temps-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempsComponent_1)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempsComponent_3)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tempsList; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_StartoverTempsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-temps", [], null, null, null, View_StartoverTempsComponent_0, RenderType_StartoverTempsComponent)), i1.ɵdid(1, 638976, null, 0, i6.StartoverTempsComponent, [i7.AuthService, i1.ChangeDetectorRef, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverTempsComponentNgFactory = i1.ɵccf("app-startover-temps", i6.StartoverTempsComponent, View_StartoverTempsComponent_Host_0, { category: "category", tempsList: "tempsList", loading: "loading" }, { setTempNotify: "setTempNotify", tempView: "tempView" }, []);
export { StartoverTempsComponentNgFactory as StartoverTempsComponentNgFactory };
