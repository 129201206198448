import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { CellsService } from '../cells.service';

@Component({
  selector: 'app-calendar-preview-cell',
  templateUrl: './calendar-preview-cell.component.html',
  styleUrls: ['./calendar-preview-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarPreviewCellComponent {
  @Input() calendarAvtar: string;
  @Input() calendarName: string;
  @Input() calendarDescription: string;
  @Input() calendarAccept: boolean;

  constructor(private _cellsService: CellsService) {}

  get media() {
    return this._cellsService.getMedia(this.calendarAvtar);
  }
}
