import { Actions } from './subGroupsUI.actions';
import * as actions from './subGroupsUI.actions';
import {
  SubChatScreens,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from '../../../models/constants';
import { SubChatUI } from 'src/models/SubGroupUI';

const INITIAL_STATE: SubChatUI = {
  file: null,
  selectedSubGroup: null,
  creationProcessSubChat: null,
  currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST,
  eop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  pageCount: 0
};

export function subGroupUiReducer(
  state = INITIAL_STATE,
  action: Actions
): SubChatUI {
  switch (action.type) {
    case actions.SubGroupUIActionTypes.UI_LIST_SUB_GROUP: {
      return {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST,
        eop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.SubGroupUIActionTypes.UI_CREATE_SUB_GROUP: {
      const res = {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_CREATION
      };
      return res;
    }
    case actions.SubGroupUIActionTypes.UI_VIEW_SUB_GROUP: {
      return {
        ...state,
        selectedSubGroup: action.selectedSubGroup,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_VIEW
      };
    }
    case actions.SubGroupUIActionTypes.UI_EDIT_SUB_GROUP: {
      return {
        ...state,
        file: action.file,
        selectedSubGroup: action.selectedSubGroup,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_EDIT
      };
    }

    case actions.SubGroupUIActionTypes.UI_UPLOAD_SUB_GROUP_PHOTO: {
      if (state.currentSubChatScreen === SubChatScreens.SUB_CHAT_EDIT) {
        return state;
      } else {
        return {
          ...state,
          creationProcessSubChat: action.createdSubGroup,
          currentSubChatScreen: SubChatScreens.SUB_CHAT_PHOTO_UPLOADING
        };
      }
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_CREATION_COMPLETE: {
      return {
        ...state,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST
      };
    }

    case actions.SubGroupUIActionTypes.UI_CLOSE_VIEW_SUB_GROUP: {
      return {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST
      };
    }

    case actions.SubGroupUIActionTypes.UI_CLOSE_EDIT_SUB_GROUP: {
      return {
        ...state,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_VIEW
      };
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      let pageCount = 0;
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      pageCount =
        state.direction === PageDirection.NEXT && action.currentPage.length > 0
          ? ++state.pageCount
          : state.pageCount;
      pageCount =
        state.direction === PageDirection.PREV && action.currentPage.length > 0
          ? --state.pageCount
          : state.pageCount;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        pageCount: pageCount,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.SubGroupUIActionTypes.UI_SUB_GROUP_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        direction: null,
        previousRequest: {},
        pageCount: 0
      };
    }
    case actions.SubGroupUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
