import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  constructor() {}

  transform(value: any, query: string, field: string): any {
    return query
      ? value.reduce((prev, next) => {
          const val = next[field];
          if (val && val.toUpperCase().includes(query.toUpperCase())) {
            prev.push(next);
          }
          return prev;
        }, [])
      : value;
  }
}
