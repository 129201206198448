import { ReleaseUI } from 'src/models/AppRelease';
import { Actions } from './appRelease.actions';
import * as actions from './appRelease.actions';
import {
  IPA,
  APK,
  ListRequestDetails,
  PageDirection
} from 'src/models/constants';

const INITIAL_STATE: ReleaseUI = {
  eop: 0,
  sop: null,
  currentPage: [],
  hash: '',
  direction: null,
  previousRequest: {},
  msisdn: null,
  screenStatus: 'Done'
};

export function appReleaseReducer(
  state: ReleaseUI = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppReleaseActionTypes.UI_RELEASE_RESET_PAGING:
      return { ...INITIAL_STATE };
    case actions.AppReleaseActionTypes.UI_RELEASE_GET_NEXT_PAGE:
      return { ...state, direction: PageDirection.NEXT };
    case actions.AppReleaseActionTypes.UI_RELEASE_GET_PREV_PAGE:
      return { ...state, direction: PageDirection.PREV };
    case actions.AppReleaseActionTypes.LIST_APP_RELEASES_SUCCESS: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.direction = state.direction;

      return {
        ...state,
        prevRequest: { ...prevRequest },
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          action.appReleases && action.appReleases.length > 0
            ? action.appReleases.map(release => {
                release.type = release.os ? IPA : APK;
                return release;
              })
            : state.currentPage
      };
    }
    case actions.AppReleaseActionTypes.RCVD_APP_RELEASE:
      return {
        ...state,
        currentPage: state.currentPage.map(release =>
          release.id === action.appReleases.id
            ? { ...release, ...action.appReleases }
            : release
        )
      };
    case actions.AppReleaseActionTypes.REMOVE_APP_RELEASE_SUCCESS:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          release => release.id !== action.id
        )
      };
    case actions.AppReleaseActionTypes.VIEW_APP_RELEASE_DETAILS:
      return {
        ...state,
        currentPage: state.currentPage.map(release =>
          release.id === action.id ? { ...release, showDetails: true } : release
        )
      };
    case actions.AppReleaseActionTypes.RCVD_APP_RELEASE_VER:
      return {
        ...state,
        currentPage: state.currentPage.map(release =>
          release.publish_id + '' === action.appReleases.id + ''
            ? {
                ...release,
                version_name: action.appReleases.version_name,
                version_desc: action.appReleases.version_desc
              }
            : release
        )
      };
    case actions.AppReleaseActionTypes.HIDE_APP_RELEASE_DETAILS:
      return {
        ...state,
        currentPage: state.currentPage.map(release =>
          release.id === action.id
            ? { ...release, showDetails: false }
            : release
        )
      };
    case actions.AppReleaseActionTypes.ADD_APP_RELEASE_WAITING_ITEM:
      return {
        ...state,
        currentPage: [action.appReleases, ...state.currentPage]
      };
    case actions.AppReleaseActionTypes.REMOVE_APP_RELEASE_WAITING_ITEM:
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.status !== 'Waiting')
      };
    case actions.AppReleaseActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
