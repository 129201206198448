import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { ContainersModule } from 'src/app/containers/containers.module';
import { PreviewsModule } from 'src/app/previews/previews.module';
import { ChatboxModule } from 'src/app/chatbox/chatbox.module';

import { MessagePostOperationsComponent } from './message-post-operations/message-post-operations.component';
import { MessagesAdminComponent } from './messages-admin/messages-admin.component';

@NgModule({
  imports: [
    CommonModule,
    NgxModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    ContainersModule,
    PreviewsModule,
    ChatboxModule
  ],
  declarations: [MessagePostOperationsComponent, MessagesAdminComponent],
  exports: [MessagesAdminComponent]
})
export class MessagesAdminModule {}
