import { Store } from '@ngrx/store';
import * as PackageUiActions from './packagesUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class PackagesUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createPackage(packageDetails) {
        this._store.dispatch(new PackageUiActions.CreatePackage(packageDetails));
    }
    setPackage(packageID, packageDetails) {
        this._store.dispatch(new PackageUiActions.SetPackage(packageID, packageDetails));
    }
    addPackageItems(packageID, itemsIds) {
        this._store.dispatch(new PackageUiActions.AddPackageItems(packageID, itemsIds));
    }
    removePackageBundles(packageID, bundlesIds) {
        this._store.dispatch(new PackageUiActions.RemovePackageBundles(packageID, bundlesIds));
    }
    packageReceived(pkg) {
        this._store.dispatch(new PackageUiActions.PackageReceived(pkg));
    }
    // paging dispatchers
    getNextPackagesPage() {
        this._store.dispatch(new PackageUiActions.GetNextPackagesPage());
    }
    getPrevPackagesPage() {
        this._store.dispatch(new PackageUiActions.GetPrevPackagesPage());
    }
    deletePackages(id) {
        this._store.dispatch(new PackageUiActions.DeletePackage(id));
    }
    packageDeleted(id) {
        this._store.dispatch(new PackageUiActions.PackageDeleted(id));
    }
    receivedPackagesHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new PackageUiActions.ReceivedPackagesHistoryChunk(eop, sop, currentPage));
    }
    resetPackagesPaging() {
        this._store.dispatch(new PackageUiActions.ResetPackagesPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new PackageUiActions.SetStatusAsIdle());
    }
}
PackagesUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PackagesUiDispatchers_Factory() { return new PackagesUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: PackagesUiDispatchers, providedIn: "root" });
