import { Action } from '@ngrx/store';

import { ChatMember, IChatMember } from 'src/models/IChatMember';
import { IProfile } from 'src/models/IProfile';

export const enum ChatMemberActionTypes {
  // LIST_CHAT_MEMBERS = 'LIST_CHAT_MEMBERS',
  // LIST_CHAT_ADMINS = 'LIST_CHAT_ADMINS',
  GET_CHAT_MEMBER = 'GET_CHAT_MEMBER',
  CHAT_MEMBERS_RECEIVED = 'CHAT_MEMBERS_RECEIVED',
  CHAT_MEMBER_RECEIVING = 'CHAT_MEMBER_RECEIVING',
  CHAT_MEMBER_RECEIVED = 'CHAT_MEMBER_RECEIVED',
  MY_MEMBER_CHATS_RECEIVED = 'MY_MEMBER_CHATS_RECEIVED',
  CHAT_MEMBER_UPDATED = 'CHAT_MEMBER_UPDATED',
  GET_MEMBER_CHATS = 'GET_MEMBER_CHATS',
  PROMOTE_CHAT_MEMBER = 'PROMOTE_CHAT_MEMBER',
  ADD_MEMBER_TO_CHAT = 'ADD_MEMBER_TO_CHAT',
  REVOKE_MEMBER_FROM_CHAT = 'REVOKE_MEMBER_FROM_CHAT',
  ADD_ADMIN_TO_CHAT = 'ADD_ADMIN_TO_CHAT',
  REVOKE_ADMIN_FROM_CHAT = 'REVOKE_ADMIN_FROM_CHAT',
  SET_CHAT_MEMBER_ROLE = 'SET_CHAT_MEMBER_ROLE',
  SET_CHAT_MEMBER_VIEW = 'SET_CHAT_MEMBER_VIEW',
  SET_MEMBER_TESTER_FLAG = 'SET_MEMBER_TESTER_FLAG',
  REMOVE_OR_BAN_USER_SUCCESS = 'REMOVE_OR_BAN_USER_SUCCESS',
  GET_MEMBER_LIST = 'GET_MEMBER_LIST',
  REMOVE_CHAT_FROM_MEMBER_SUCCESS = 'REMOVE_CHAT_FROM_MEMBER_SUCCESS'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class GetChatMember implements Action {
  readonly type = ChatMemberActionTypes.GET_CHAT_MEMBER;
  constructor(public payload: IProfile) {}
}

export class ChatMemberUpdated implements Action {
  readonly type = ChatMemberActionTypes.CHAT_MEMBER_UPDATED;
  constructor(public payload: IProfile) {}
}

export class ChatMembersReceived implements Action {
  readonly type = ChatMemberActionTypes.CHAT_MEMBERS_RECEIVED;
  constructor(public payload: ChatMember[]) {}
}

export class ChatMemberReceiving implements Action {
  readonly type = ChatMemberActionTypes.CHAT_MEMBER_RECEIVING;
  constructor(public payload: ChatMember) {}
}

export class ChatMemberReceived implements Action {
  readonly type = ChatMemberActionTypes.CHAT_MEMBER_RECEIVED;
  constructor(public payload: IChatMember) {}
}

export class GetMemberChats implements Action {
  readonly type = ChatMemberActionTypes.GET_MEMBER_CHATS;
  constructor(public payload: string) {}
}
export class MyMemberChatsReceived implements Action {
  readonly type = ChatMemberActionTypes.MY_MEMBER_CHATS_RECEIVED;
  constructor(public payload: { user_id: string; members: ChatMember[] }) {}
}

export class PromoteChatMember implements Action {
  readonly type = ChatMemberActionTypes.PROMOTE_CHAT_MEMBER;
  constructor(public payload: string) {}
}

export class GetMemberList implements Action {
  readonly type = ChatMemberActionTypes.GET_MEMBER_LIST;
  constructor(public payload: any[]) {}
}

export class AddMemberToChat implements Action {
  readonly type = ChatMemberActionTypes.ADD_MEMBER_TO_CHAT;
  constructor(
    public payload: { chat_id: string; user_id: string; recordIndex?: number }
  ) {}
}
export class AddAdminToChat implements Action {
  readonly type = ChatMemberActionTypes.ADD_ADMIN_TO_CHAT;
  constructor(public payload: { chat_id: string; user_id: string }) {}
}
export class RevokeMemberFromChat implements Action {
  readonly type = ChatMemberActionTypes.REVOKE_MEMBER_FROM_CHAT;
  constructor(public payload: { chat_id: string; user_id: string }) {}
}
export class RevokeAdminFromChat implements Action {
  readonly type = ChatMemberActionTypes.REVOKE_ADMIN_FROM_CHAT;
  constructor(public payload: { chat_id: string; user_id: string }) {}
}

export class SetChatMemberRole implements Action {
  readonly type = ChatMemberActionTypes.SET_CHAT_MEMBER_ROLE;
  constructor(
    public payload: { chat_id: string; user_id: string; role: string }
  ) {}
}

export class SetChatMemberView implements Action {
  readonly type = ChatMemberActionTypes.SET_CHAT_MEMBER_VIEW;
  constructor(public payload: { user_id: string; view: number }) {}
}

export class SetMemberTesterFlag implements Action {
  readonly type = ChatMemberActionTypes.SET_MEMBER_TESTER_FLAG;
  constructor(public payload: { user_id: string; tester: number }) {}
}

export class RemoveOrBanUserSuccess implements Action {
  readonly type = ChatMemberActionTypes.REMOVE_OR_BAN_USER_SUCCESS;
  constructor(public ID: string) {}
}

export class RemoveChatFromMemberSuccess implements Action {
  readonly type = ChatMemberActionTypes.REMOVE_CHAT_FROM_MEMBER_SUCCESS;
  constructor(public chatId: string) {}
}
// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type ChatMemberActions =
  | GetChatMember
  | ChatMemberUpdated
  | ChatMembersReceived
  | ChatMemberReceiving
  | ChatMemberReceived
  | GetMemberChats
  | MyMemberChatsReceived
  | PromoteChatMember
  | AddMemberToChat
  | AddAdminToChat
  | RevokeMemberFromChat
  | RevokeAdminFromChat
  | SetChatMemberRole
  | SetChatMemberView
  | SetMemberTesterFlag
  | RemoveOrBanUserSuccess
  | RemoveChatFromMemberSuccess
  | DeselectChannel
  | GetMemberList
  | Reset;
