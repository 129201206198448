import { Store } from '@ngrx/store';
import * as ChatMemberActions from './chatMember.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ChatMemberDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    // listChatMembers(chat_id) {
    //   this._store.dispatch(new ChatMemberActions.ListChatMembers(chat_id));
    // }
    // listChatAdmins() {
    //   this._store.dispatch(new ChatMemberActions.ListChatAdmins());
    // }
    getChatMember(payload) {
        this._store.dispatch(new ChatMemberActions.GetChatMember(payload));
    }
    chatMemberUpdated(payload) {
        this._store.dispatch(new ChatMemberActions.ChatMemberUpdated(payload));
    }
    // Request and recive Chat Member Details
    chatMembersReceived(payload) {
        this._store.dispatch(new ChatMemberActions.ChatMembersReceived(payload));
    }
    chatMemberReceiving(payload) {
        this._store.dispatch(new ChatMemberActions.ChatMemberReceiving(payload));
    }
    chatMemberReceived(payload) {
        this._store.dispatch(new ChatMemberActions.ChatMemberReceived(payload));
    }
    // Get Specific member Chats
    getMemberChats(payload) {
        this._store.dispatch(new ChatMemberActions.GetMemberChats(payload));
    }
    getMemberList(payload) {
        this._store.dispatch(new ChatMemberActions.GetMemberList(payload));
    }
    myMemberChatsReceived(payload) {
        this._store.dispatch(new ChatMemberActions.MyMemberChatsReceived(payload));
    }
    // Controls
    promoteChatMember(userId) {
        this._store.dispatch(new ChatMemberActions.PromoteChatMember(userId));
    }
    addMemberToChat(chatId, userId, recordIndex) {
        this._store.dispatch(new ChatMemberActions.AddMemberToChat({
            chat_id: chatId,
            user_id: userId,
            recordIndex
        }));
    }
    addAdminToChat(chatId, userId) {
        this._store.dispatch(new ChatMemberActions.AddAdminToChat({ chat_id: chatId, user_id: userId }));
    }
    revokeMemberFromChat(chatId, userId) {
        this._store.dispatch(new ChatMemberActions.RevokeMemberFromChat({
            chat_id: chatId,
            user_id: userId
        }));
    }
    revokeAdminFromChat(chatId, userId) {
        this._store.dispatch(new ChatMemberActions.RevokeAdminFromChat({
            chat_id: chatId,
            user_id: userId
        }));
    }
    setChatMemberRole(chatId, userId, role) {
        this._store.dispatch(new ChatMemberActions.SetChatMemberRole({
            chat_id: chatId,
            user_id: userId,
            role
        }));
    }
    setChatMemberView(userId, view) {
        this._store.dispatch(new ChatMemberActions.SetChatMemberView({ user_id: userId, view }));
    }
    setMemberTesterFlag(userId, tester) {
        this._store.dispatch(new ChatMemberActions.SetMemberTesterFlag({ user_id: userId, tester }));
    }
    removeOrBanUserSuccess(ID) {
        this._store.dispatch(new ChatMemberActions.RemoveOrBanUserSuccess(ID));
    }
    removeChatFromMemberSuccess(chatId) {
        this._store.dispatch(new ChatMemberActions.RemoveChatFromMemberSuccess(chatId));
    }
}
ChatMemberDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatMemberDispatchers_Factory() { return new ChatMemberDispatchers(i0.ɵɵinject(i1.Store)); }, token: ChatMemberDispatchers, providedIn: "root" });
