import { InstantArticle } from 'src/models/InstantArticle';

export class InstantMiddleware {
  public static isInstantArticleExist(
    instantArticles: InstantArticle[],
    localInstantArticle: InstantArticle
  ) {
    const article = instantArticles.find(
      iv => iv.reference === localInstantArticle.reference
    );
    if (article) {
      return true;
    } else {
      return false;
    }
  }
}
