/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "../../../../abstract-frames/headerless-closable-frame/headerless-closable-frame.component.ngfactory";
import * as i4 from "../../../../abstract-frames/headerless-closable-frame/headerless-closable-frame.component";
import * as i5 from "@angular/common";
import * as i6 from "./menu-stats.component";
var styles_MenuStatsComponent = [i0.styles];
var RenderType_MenuStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuStatsComponent, data: {} });
export { RenderType_MenuStatsComponent as RenderType_MenuStatsComponent };
function View_MenuStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { data: [0, "data"], labels: [1, "labels"], chartType: [2, "chartType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.doughnutChartData; var currVal_1 = _co.doughnutChartLabels; var currVal_2 = _co.doughnutChartType; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MenuStatsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No data available yet."]))], null, null); }
export function View_MenuStatsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-headerless-closable-frame", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_HeaderlessClosableFrameComponent_0, i3.RenderType_HeaderlessClosableFrameComponent)), i1.ɵdid(1, 49152, null, 0, i4.HeaderlessClosableFrameComponent, [], null, { closeNotify: "closeNotify" }), (_l()(), i1.ɵeld(2, 0, null, 0, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "chart-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuStatsComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuStatsComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.messageStats && (_co.messageStats.length > 0)); _ck(_v, 5, 0, currVal_0); var currVal_1 = (!_co.messageStats || (_co.messageStats.length === 0)); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_MenuStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-stats", [], null, null, null, View_MenuStatsComponent_0, RenderType_MenuStatsComponent)), i1.ɵdid(1, 573440, null, 0, i6.MenuStatsComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var MenuStatsComponentNgFactory = i1.ɵccf("app-menu-stats", i6.MenuStatsComponent, View_MenuStatsComponent_Host_0, { messageStats: "messageStats" }, { closeNotfify: "closeNotfify" }, []);
export { MenuStatsComponentNgFactory as MenuStatsComponentNgFactory };
