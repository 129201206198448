import {
  LocalAppConfig,
  LocalAppClass
} from '../../../models/ChannelAppLocalConfig';
import { Action } from '@ngrx/store';

export const enum AppClassActionTypes {
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  UPDATE_APP_CLASS = 'UPDATE_APP_CLASS',
  NOT_ALLOWED_CLASS_BG_COLOR = 'NOT_ALLOWED_CLASS_BG_COLOR',
  OPEN_CLASS_SECTION = 'OPEN_CLASS_SECTION',
  CLOSE_CLASS_SECTION = 'CLOSE_CLASS_SECTION',
  RESET = 'RESET'
}

export class AppConfigDataChanged implements Action {
  readonly type = AppClassActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class UpdateAppClass implements Action {
  readonly type = AppClassActionTypes.UPDATE_APP_CLASS;
  constructor(
    public appClassRef: string,
    public appClassUpdatedProps: LocalAppClass
  ) {}
}

export class NotAllowedCLassBgColor implements Action {
  readonly type = AppClassActionTypes.NOT_ALLOWED_CLASS_BG_COLOR;
}

export class OpenClassSection implements Action {
  readonly type = AppClassActionTypes.OPEN_CLASS_SECTION;
  constructor(public appClass: LocalAppClass) {}
}

export class CloseClassSection implements Action {
  readonly type = AppClassActionTypes.CLOSE_CLASS_SECTION;
  constructor(public appClass: LocalAppClass) {}
}

export class Reset implements Action {
  readonly type = AppClassActionTypes.RESET;
}

export type Actions =
  | AppConfigDataChanged
  | UpdateAppClass
  | NotAllowedCLassBgColor
  | OpenClassSection
  | CloseClassSection
  | Reset;
