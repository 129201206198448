/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-menu-theme.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../+merchant/+dashboard/menus/menu-form/menu-form.component.ngfactory";
import * as i4 from "../../../+merchant/+dashboard/menus/menu-form/menu-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../+merchant/+dashboard/menus/menus.service";
import * as i7 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i8 from "../../../store/ui/ui.dispatchers";
import * as i9 from "../../../+merchant/+dashboard/bots/bots.service";
import * as i10 from "../../../+merchant/+layout/layout.service";
import * as i11 from "../../../core/file.service";
import * as i12 from "../../../network/gateway/upload.gateway";
import * as i13 from "../../../core/auth.service";
import * as i14 from "../../../core/storage.service";
import * as i15 from "../../../core/ui.service";
import * as i16 from "../../../+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service";
import * as i17 from "../../../+merchant/+dashboard/collections/collections.service";
import * as i18 from "./startover-menu-theme.component";
var styles_StartoverMenuThemeComponent = [i0.styles];
var RenderType_StartoverMenuThemeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverMenuThemeComponent, data: {} });
export { RenderType_StartoverMenuThemeComponent as RenderType_StartoverMenuThemeComponent };
function View_StartoverMenuThemeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Create Home Menu "]))], null, null); }
function View_StartoverMenuThemeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Customize Home Menu "]))], null, null); }
function View_StartoverMenuThemeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "titles"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverMenuThemeComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverMenuThemeComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fully Customize Your App Design"]))], function (_ck, _v) { var currVal_0 = (!((_v.context.ngIf == null) ? null : _v.context.ngIf.mode) || (((_v.context.ngIf == null) ? null : _v.context.ngIf.mode) == 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_v.context.ngIf == null) ? null : _v.context.ngIf.mode) && (((_v.context.ngIf == null) ? null : _v.context.ngIf.mode) == 1)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_StartoverMenuThemeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-menu-form", [], null, [[null, "closeNotifiy"], [null, "formEmitter"], [null, "newformEmitter"], [null, "mainMenuNotifiy"], [null, "uploadNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotifiy" === en)) {
        var pd_0 = (_co.closeEditBotMenuFormEvent() !== false);
        ad = (pd_0 && ad);
    } if (("formEmitter" === en)) {
        var pd_1 = (_co.getForm($event) !== false);
        ad = (pd_1 && ad);
    } if (("newformEmitter" === en)) {
        var pd_2 = (_co.getNewForm($event) !== false);
        ad = (pd_2 && ad);
    } if (("mainMenuNotifiy" === en)) {
        var pd_3 = (_co.goToApp() !== false);
        ad = (pd_3 && ad);
    } if (("uploadNotify" === en)) {
        var pd_4 = (_co.uploadImage($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_MenuFormComponent_0, i3.RenderType_MenuFormComponent)), i1.ɵdid(1, 770048, null, 0, i4.MenuFormComponent, [i5.FormBuilder, i6.MenusService, i1.ChangeDetectorRef, i7.AppMgmtService, i8.UIDispatchers, i9.BotsService, i10.LayoutService, i11.FileService, i12.UploadGateway, i13.AuthService, i14.StorageService, i15.UiService, i16.MobileWorkFlowStoreService, i17.CollectionsService], { startOver: [0, "startOver"], botMenuEdit: [1, "botMenuEdit"], currentMenu: [2, "currentMenu"], mainMenu: [3, "mainMenu"], appClass: [4, "appClass"], menusAppClass: [5, "menusAppClass"], tabsList: [6, "tabsList"], toBeEdited: [7, "toBeEdited"], currentTabs: [8, "currentTabs"], withMenus: [9, "withMenus"] }, { closeNotifiy: "closeNotifiy", formEmitter: "formEmitter", newformEmitter: "newformEmitter", mainMenuNotifiy: "mainMenuNotifiy", uploadNotify: "uploadNotify" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.currentMenu; var currVal_2 = _co.currentMenu; var currVal_3 = true; var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 2).transform(_co.appManagementService.tabsAppClass$)); var currVal_5 = _co.menuApp; var currVal_6 = _co.tabContainerList; var currVal_7 = _co.toBeEdited; var tmp_8_0 = null; var currVal_8 = (((tmp_8_0 = i1.ɵunv(_v, 1, 8, i1.ɵnov(_v, 3).transform(((_co.appManagementService == null) ? null : _co.appManagementService.appInfo$)))) == null) ? null : tmp_8_0.menuTabs); var currVal_9 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_StartoverMenuThemeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-menu-form", [], null, [[null, "newformEmitter"], [null, "closeNotifiy"], [null, "formEmitter"], [null, "mainMenuNotifiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newformEmitter" === en)) {
        var pd_0 = (_co.getNewForm($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeNotifiy" === en)) {
        var pd_1 = (_co.closeEditBotMenuFormEvent() !== false);
        ad = (pd_1 && ad);
    } if (("formEmitter" === en)) {
        var pd_2 = (_co.getForm($event) !== false);
        ad = (pd_2 && ad);
    } if (("mainMenuNotifiy" === en)) {
        var pd_3 = (_co.goToApp() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_MenuFormComponent_0, i3.RenderType_MenuFormComponent)), i1.ɵdid(1, 770048, null, 0, i4.MenuFormComponent, [i5.FormBuilder, i6.MenusService, i1.ChangeDetectorRef, i7.AppMgmtService, i8.UIDispatchers, i9.BotsService, i10.LayoutService, i11.FileService, i12.UploadGateway, i13.AuthService, i14.StorageService, i15.UiService, i16.MobileWorkFlowStoreService, i17.CollectionsService], { startOver: [0, "startOver"], botMenuEdit: [1, "botMenuEdit"], mainMenu: [2, "mainMenu"], appClass: [3, "appClass"], menusAppClass: [4, "menusAppClass"], tabsList: [5, "tabsList"], currentTabs: [6, "currentTabs"], withMenus: [7, "withMenus"] }, { closeNotifiy: "closeNotifiy", formEmitter: "formEmitter", newformEmitter: "newformEmitter", mainMenuNotifiy: "mainMenuNotifiy" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = ""; var currVal_2 = true; var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 2).transform(_co.appManagementService.tabsAppClass$)); var currVal_4 = _co.menuApp; var currVal_5 = _co.tabContainerList; var tmp_6_0 = null; var currVal_6 = (((tmp_6_0 = i1.ɵunv(_v, 1, 6, i1.ɵnov(_v, 3).transform(((_co.appManagementService == null) ? null : _co.appManagementService.appInfo$)))) == null) ? null : tmp_6_0.menuTabs); var currVal_7 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_StartoverMenuThemeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverMenuThemeComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "mob-themes"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverMenuThemeComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverMenuThemeComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(((_co.authService == null) ? null : _co.authService.authCollection$))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.menuApp == null) ? null : ((_co.menuApp.menus == null) ? null : _co.menuApp.menus.length)) >= 1); _ck(_v, 6, 0, currVal_1); var currVal_2 = (((_co.menuApp == null) ? null : ((_co.menuApp.menus == null) ? null : _co.menuApp.menus.length)) <= 0); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_StartoverMenuThemeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-menu-theme", [], null, null, null, View_StartoverMenuThemeComponent_0, RenderType_StartoverMenuThemeComponent)), i1.ɵdid(1, 638976, null, 0, i18.StartoverMenuThemeComponent, [i7.AppMgmtService, i15.UiService, i6.MenusService, i1.ChangeDetectorRef, i5.FormBuilder, i13.AuthService, i14.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverMenuThemeComponentNgFactory = i1.ɵccf("app-startover-menu-theme", i18.StartoverMenuThemeComponent, View_StartoverMenuThemeComponent_Host_0, { appClass: "appClass", tabContainerList: "tabContainerList", itemList: "itemList", appInfo: "appInfo", hideBackButton: "hideBackButton", hideNext: "hideNext", menuApp: "menuApp", menuIdex: "menuIdex", currentMenu: "currentMenu", menusAppClass: "menusAppClass", toBeEdited: "toBeEdited" }, { mainMenuNotifiy: "mainMenuNotifiy", editNotify: "editNotify", newMenuNotify: "newMenuNotify", uploadNotify: "uploadNotify" }, []);
export { StartoverMenuThemeComponentNgFactory as StartoverMenuThemeComponentNgFactory };
