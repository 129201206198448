import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { skipWhile, map } from 'rxjs/operators';

import { RootStoreSelectors } from '../../../store/root-store.selectors';
import { BOOKING_CHANNEL, CHANNEL, EVENT_CHANNEL, GROUP, INDIVIDUAL } from 'src/models/constants';
import { MainChat } from 'src/models/MainChat';
import { IProfile } from 'src/models/IProfile';
import { IMyProfile } from 'src/models/IMyProfile';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileImageComponent implements OnChanges, OnDestroy {
  @Input() id: string;
  @Input() name: string;
  @Input() size: string;
  @Input() emSize: string;
  @Input() isSquare: boolean;
  @Input() chatType: string;
  @Input() chat: MainChat;
  @Input() isPostScreen = false;

  profileSubscription = null;

  profile = {
    name: '',
    backgroundColor: ' ',
    image: ''
  };

  constructor(
    private _sanitizer: DomSanitizer,
    private _selector: RootStoreSelectors,
    private _changeDetector: ChangeDetectorRef
  ) { }

  ngOnChanges() {
    this.resetProfile();

    if (this.chatType === CHANNEL && !this.isPostScreen) {
      this.profileSubscription = this._selector.channelsAndSubChannels$
        .pipe(
          skipWhile(item => !item),
          map(items => items.find(chat => chat.id === this.id))
        )
        .subscribe(res => {
          this.setProfile(res);
          this._changeDetector.markForCheck();
        });
    } else if (this.chatType === GROUP && !this.isPostScreen) {
      this.profileSubscription = this._selector.chats$
        .pipe(
          skipWhile(item => !item),
          map(items => items.find(chat => chat.id === this.id))
        )
        .subscribe(res => {
          this.setProfile(res);
          this._changeDetector.markForCheck();
        });
    } else if (this.chatType === INDIVIDUAL && !this.isPostScreen) {
      this.profileSubscription = this._selector.allUsers$
        .pipe(
          skipWhile(item => !item),
          map(items => items.find(chat => chat.user_id === this.id))
        )
        .subscribe(res => {
          this.setProfile(res);
          this._changeDetector.markForCheck();
        });
    } else if (this.chatType === 'MyProfile' && !this.isPostScreen) {
      this.profileSubscription = this._selector
        .myProfiles$()
        .pipe(
          skipWhile(item => !item),
          map(items => items.find(profile => profile.relation === 'Other'))
        )
        .subscribe(res => {
          this.setProfile(res);
          this._changeDetector.markForCheck();
        });
    } else if (this.isPostScreen && this.chat) {
      this.setProfile(this.chat);
    }
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  setProfile(res: MainChat | IProfile | IMyProfile) {
    if (res) {
      this.profile = {
        ...this.profile,
        name: res.name,
        backgroundColor: res.backgroundColor,
        image: res.imageThumbnail || ''
      };
    }
  }

  resetProfile() {
    this.profile = {
      ...this.profile,
      name: '',
      backgroundColor: '',
      image: ''
    };
  }

  get thumbnail() {
    if (this.profile && this.profile.image) {
      return this._sanitizer.bypassSecurityTrustUrl(this.profile.image);
    }
    return '';
  }

  get cssStyles() {
    if (!this.emSize) {
      return {
        'width.px': this.size,
        'height.px': this.size
        // 'background-color': this.profile.image
        //   ? null
        //   : this.profile.backgroundColor
      };
    } else {
      return {
        'width.em': this.emSize,
        'height.em': this.emSize
        // 'background-color': this.profile.image
        //   ? null
        //   : this.profile.backgroundColor
      };
    }
  }

  get cssStyleForGroup() {
    return {
      'height.px': this.size + 7
    };
  }

  get cssPlaceHolderImage() {
    switch (this.chatType) {
      case INDIVIDUAL:
        return './assets/img/Contact-placeholder--54dp.svg';
      case CHANNEL:
        return './assets/img/channels-placeholder.svg';
      case GROUP:
        return './assets/img/group-placeholder.svg';
      case EVENT_CHANNEL:
        return './assets/img/event-placeholder.svg';
      case BOOKING_CHANNEL:
        return './assets/img/booking-placeholder.svg';
      case 'MyProfile':
        return './assets/img/Contact-placeholder--54dp.svg';
    }
  }
}
