import { IOption } from './campaign';
import { GET_STATS_METHOD } from './constants';

export interface ChartObj {
  x?: string;
  y?: number;
}

export interface ChartValues {
  type?: number;
  values?: ChartObj[];
  empty?: boolean;
}

export interface ChartsUI {
  user?: ChartValues;
}

export const Days: IOption[] = [
  {
    key: 7,
    value: 'Last 7 Days'
  },
  {
    key: 14,
    value: 'Last 14 Days'
  },
  {
    key: 21,
    value: 'Last 21 Days'
  },
  {
    key: 27,
    value: 'Last 27 Days'
  },
  {
    key: 34,
    value: 'Last 34 Days'
  },
  {
    key: 41,
    value: 'Last 41 Days'
  }
];
export const Weeks: IOption[] = [
  {
    key: 21,
    value: 'Last 3 Weeks'
  },
  {
    key: 42,
    value: 'Last 6 Weeks'
  },
  {
    key: 63,
    value: 'Last 9 Weeks'
  },
  {
    key: 84,
    value: 'Last 12 Weeks'
  },
  {
    key: 105,
    value: 'Last 15 Weeks'
  },
  {
    key: 126,
    value: 'Last 18 Weeks'
  }
];
export const Months: IOption[] = [
  {
    key: 3,
    value: 'Last 3 Months'
  },
  {
    key: 6,
    value: 'Last 6 Months'
  },
  {
    key: 9,
    value: 'Last 9 Months'
  },
  {
    key: 12,
    value: 'Last 12 Months'
  },
  {
    key: 15,
    value: 'Last 15 Months'
  },
  {
    key: 18,
    value: 'Last 18 Months'
  }
];
export const Years: IOption[] = [
  {
    key: 2,
    value: 'Last 2 Years'
  },
  {
    key: 4,
    value: 'Last 4 Years'
  },
  {
    key: 6,
    value: 'Last 6 Years'
  },
  {
    key: 8,
    value: 'Last 8 Years'
  },
  {
    key: 10,
    value: 'Last 10 Years'
  }
];

export class GetChartData {
  readonly method = GET_STATS_METHOD;
  constructor(
    public chat_id: string,
    public type?: number,
    public interval?: number,
    public start_date?: string,
    public end_date?: string
  ) {}
}
