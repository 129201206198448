import { Action } from '@ngrx/store';

import { MainChat } from 'src/models/MainChat';
import { IMessage } from 'src/models/IMessage';
import { LikeMessageAck } from 'src/models/PostCounters';
import { RcvdMessage, MessageControls } from 'src/models/MessageParts';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UrlPreviewMeta } from 'src/models/MetaData';
import { ITag } from 'src/models/ITag';
import { InstantArticle } from 'src/models/InstantArticle';
import { MenuCallBackStats } from 'src/models/MenuCallBackStats';
import { CalendarTimetable } from 'src/models/Calendar';

// list part
export const GET_LAST_POSTS_HISTORY = 'GET_LAST_POSTS_HISTORY';
export const GET_LAST_REPLIES_HISTORY = 'GET_LAST_REPLIES_HISTORY';
export const LIST_SCHEDULE_MESSAGES = 'LIST_SCHEDULE_MESSAGES';
export const LIST_AWARD_MESSAGES = 'LIST_AWARD_MESSAGES';
export const LIST_TAGS = 'LIST_TAGS';

// RECEIVE part
export const CANCEL_SCHEDULE_MESSAGE = 'CANCEL_SCHEDULE_MESSAGE';
export const SEND_MESSAGE_NOW = 'SEND_MESSAGE_NOW';
export const SEND_TEXT = 'SEND_TEXT';
export const SEND_MEDIA = 'SEND_MEDIA';
export const SEND_INSTANT_URL = 'SEND_INSTANT_URL';
export const SEND_CALENDAR = 'SEND_CALENDAR';
export const SEND_QUICK_REPLY_FROM_ADMIN = 'SEND_QUICK_REPLY_FROM_ADMIN';
export const GET_BLOB_URL = 'GET_BLOB_URL';
export const CANCEL_MEDIA_OPERATION = 'CANCEL_MEDIA_OPERATION';
export const RETRY_MEDIA_OPERATION = 'RETRY_MEDIA_OPERATION';
export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const SENDING_SCHEDULED_MESSAGE = 'SENDING_SCHEDULED_MESSAGE';
export const SENDING_AWARD_MESSAGE = 'SENDING_AWARD_MESSAGE';
export const SENDING_WELCOME_MESSAGE = 'SENDING_WELCOME_MESSAGE';
export const INIT_RECEIVED_MESSAGE = 'INIT_RECEIVED_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const SCHEDULE_MESSAGE_RECEIVED = 'SCHEDULE_MESSAGE_RECEIVED';
export const AWARD_MESSAGE_RECEIVED = 'AWARD_MESSAGE_RECEIVED';
export const WELCOME_MESSAGE_RECEIVED = 'WELCOME_MESSAGE_RECEIVED';
export const SCHEDULE_MESSAGE_CANCELED_RECEIVED =
  'SCHEDULE_MESSAGE_CANCELED_RECEIVED';
export const MESSAGE_SENT = 'MESSAGE_SENT';
export const MESSAGE_DELIVERED = 'MESSAGE_DELIVERED';
export const MESSAGE_SEEN = 'MESSAGE_SEEN';
export const MESSAGES_SEEN_RECEIVED = 'MESSAGES_SEEN_RECEIVED';
export const CHAT_COUNTERS_RECEIVED = 'CHAT_COUNTERS_RECEIVED';
export const LIKE_ACK_RECEIVED = 'LIKE_ACK_RECEIVED';
export const LINK_PREVIEW_DETAILS_RECEIVED = 'LINK_PREVIEW_DETAILS_RECEIVED';
export const RECALL_MESSAGE = 'RECALL_MESSAGE';

// SET Properties part
export const SET_LOCAL_MEDIA = 'SET_LOCAL_MEDIA';
export const SET_LOCAL_THUMBNAIL = 'SET_LOCAL_THUMBNAIL';
export const SET_MESSAGE_MEDIA_STATUS = 'SET_MESSAGE_MEDIA_STATUS';
export const SET_MESSAGE_THUMBNAIL_STATUS = 'SET_MESSAGE_THUMBNAIL_STATUS';
export const DOWNLOAD_MEDIA_MESSAGE = 'DOWNLOAD_MEDIA_MESSAGE';
export const DOWNLOAD_MESSAGE_THUMBNAIL = 'DOWNLOAD_MESSAGE_THUMBNAIL';
export const CANCEL_DOWNLOAD_MEDIA_MESSAGE = 'CANCEL_DOWNLOAD_MEDIA_MESSAGE';
export const CANCEL_UPLOAD_MEDIA_MESSAGE = 'CANCEL_UPLOAD_MEDIA_MESSAGE';
export const UPDATE_LOADING_PROGRESS = 'UPDATE_LOADING_PROGRESS';
export const LIKE_MESSAGE = 'LIKE_MESSAGE';
export const UNLIKE_MESSAGE = 'UNLIKE_MESSAGE';
export const SHARE_MESSAGE = 'SHARE_MESSAGE';
export const SET_LINK_PREVIEW_STATUS = 'SET_LINK_PREVIEW_STATUS';
export const MESSAGE_IS_VIEWED = 'MESSAGE_IS_VIEWED';
export const MESSAGE_RECALLED = 'MESSAGE_RECALLED';
export const CHAT_MESSAGES_OUT_OF_VIEW_PORT = 'CHAT_MESSAGES_OUT_OF_VIEW_PORT';
export const CONTACT_MESSAGES_OUT_OF_VIEW_PORT =
  'CONTACT_MESSAGES_OUT_OF_VIEW_PORT';
export const READ_CHAT_MESSAGES = 'READ_CHAT_MESSAGES';
export const CHAT_DESELECTED = 'CHAT_DESELECTED';
export const UPDATE_EXISTING_MESSAGE = 'UPDATE_EXISTING_MESSAGE';
export const RESET_NUMBER_OF_UNREAD_REPLIES = 'RESET_NUMBER_OF_UNREAD_REPLIES';
export const MESSAGE_EDITED = 'MESSAGE_EDITED';
export const HISTORY_EFFECT = 'HISTORY_EFFECT';
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const PREVIEW_LINK_MESSAGE = 'PREVIEW_LINK_MESSAGE';
export const SENDING_CREATE_TAG = 'SENDING_CREATE_TAG';
export const SENDING_DELETE_TAG = 'SENDING_DELETE_TAG';
export const SENDING_SET_MEMBER_TAGS = 'SENDING_SET_MEMBER_TAGS';
export const GET_MESSAGE_MENU_STATS = 'GET_MESSAGE_MENU_STATS';
export const SET_MESSAGE_MENU_STATS = 'SET_MESSAGE_MENU_STATS';
export const GET_INLINE_MESSAGE_CALLBACK = 'GET_INLINE_MESSAGE_CALLBACK';
export const SET_COUNTER_IF_EXISTING = 'SET_COUNTER_IF_EXISTING';

export const RESET = 'RESET';

export class GetLastPostsHistory implements Action {
  readonly type = GET_LAST_POSTS_HISTORY;
}
export class GetLastRepliesHistory implements Action {
  readonly type = GET_LAST_REPLIES_HISTORY;
}
export class ListScheduleMessages implements Action {
  readonly type = LIST_SCHEDULE_MESSAGES;
}
export class ListAwardMessages implements Action {
  readonly type = LIST_AWARD_MESSAGES;
}
export class ListTags implements Action {
  readonly type = LIST_TAGS;
  constructor(public chat_ID?: string) {}
}

export class InitReceivedMessage implements Action {
  readonly type = INIT_RECEIVED_MESSAGE;
  constructor(public payload: RcvdMessage) {}
}

export class ReceiveMessage implements Action {
  readonly type = RECEIVE_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class ReceiveScheduleMessage implements Action {
  readonly type = SCHEDULE_MESSAGE_RECEIVED;
  constructor(public payload: IMessage) {}
}
export class ReceiveAwardMessage implements Action {
  readonly type = AWARD_MESSAGE_RECEIVED;
  constructor(public payload: IMessage) {}
}
export class ReceiveWelcomeMessage implements Action {
  readonly type = WELCOME_MESSAGE_RECEIVED;
  constructor(public payload: IMessage) {}
}
export class ReceiveScheduleMessageCanceled implements Action {
  readonly type = SCHEDULE_MESSAGE_CANCELED_RECEIVED;
  constructor(public payload: IMessage) {}
}

export class SendingMessage implements Action {
  readonly type = SENDING_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class SendingScheduleMessage implements Action {
  readonly type = SENDING_SCHEDULED_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class SendingAwardMessage implements Action {
  readonly type = SENDING_AWARD_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class SendingWelcomeMessage implements Action {
  readonly type = SENDING_WELCOME_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class MessageSent implements Action {
  readonly type = MESSAGE_SENT;
  constructor(public payload: IMessage) {}
}

export class MessageDelivered implements Action {
  readonly type = MESSAGE_DELIVERED;
  constructor(public payload: IMessage) {}
}

export class MessageSeen implements Action {
  readonly type = MESSAGE_SEEN;
  constructor(public message_id: string) {}
}

export class MessagesSeenReceived implements Action {
  readonly type = MESSAGES_SEEN_RECEIVED;
  constructor(public message_ids: string[]) {}
}

export class SetMessageLocalMedia implements Action {
  readonly type = SET_LOCAL_MEDIA;
  constructor(public payload: IMessage) {}
}

export class SetMessageLocalThumbnail implements Action {
  readonly type = SET_LOCAL_THUMBNAIL;
  constructor(public payload: IMessage) {}
}

export class DownloadMediaMessage implements Action {
  readonly type = DOWNLOAD_MEDIA_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class DownloadMessageThumbnail implements Action {
  readonly type = DOWNLOAD_MESSAGE_THUMBNAIL;
  constructor(public payload: IMessage) {}
}

export class CancelDownloadMediaMessage implements Action {
  readonly type = CANCEL_DOWNLOAD_MEDIA_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class SetMessageMediaStatus implements Action {
  readonly type = SET_MESSAGE_MEDIA_STATUS;
  constructor(public payload: IMessage) {}
}

export class SetMessageThumbnailStatus implements Action {
  readonly type = SET_MESSAGE_THUMBNAIL_STATUS;
  constructor(public payload: IMessage) {}
}

export class ChatCountersReceived implements Action {
  readonly type = CHAT_COUNTERS_RECEIVED;
  constructor(public msgs: IMessage[]) {}
}

export class CancelUpload implements Action {
  readonly type = CANCEL_UPLOAD_MEDIA_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class UpdateLoadingProgress implements Action {
  readonly type = UPDATE_LOADING_PROGRESS;
  constructor(public message: IMessage, public progress: number) {}
}

export class MessageIsViewed implements Action {
  readonly type = MESSAGE_IS_VIEWED;
  constructor(public payload: IMessage, public msgIdsViewed?: string[]) {}
}

export class MessageRecalled implements Action {
  readonly type = MESSAGE_RECALLED;
  constructor(public message: IMessage) {}
}

export class RecallMessage implements Action {
  readonly type = RECALL_MESSAGE;
  constructor(public message: IMessage) {}
}

export class LikeMessage implements Action {
  readonly type = LIKE_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class UnlikeMessage implements Action {
  readonly type = UNLIKE_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class ShareMessage implements Action {
  readonly type = SHARE_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class LikeAckReceived implements Action {
  readonly type = LIKE_ACK_RECEIVED;
  constructor(public payload: LikeMessageAck) {}
}

export class SetLinkPreviewStatus implements Action {
  readonly type = SET_LINK_PREVIEW_STATUS;
  constructor(public payload: IMessage) {}
}

export class LinkPreviewDetailsReceived implements Action {
  readonly type = LINK_PREVIEW_DETAILS_RECEIVED;
  constructor(public payload: IMessage) {}
}

export class ChatMessagesOutOfViewPort implements Action {
  readonly type = CHAT_MESSAGES_OUT_OF_VIEW_PORT;
  constructor(public groupID: string) {}
}

export class ContactMessagesOutOfViewPort implements Action {
  readonly type = CONTACT_MESSAGES_OUT_OF_VIEW_PORT;
  constructor(public id: string) {}
}

export class ReadChatMessages implements Action {
  readonly type = READ_CHAT_MESSAGES;
  constructor(public chat: MainChat) {}
}

export class ChatDeselected implements Action {
  readonly type = CHAT_DESELECTED;
  constructor(public chat: MainChat) {}
}

export class UpdateExistingMessage implements Action {
  readonly type = UPDATE_EXISTING_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class ResetNumberOfUnreadReplies implements Action {
  readonly type = RESET_NUMBER_OF_UNREAD_REPLIES;
  constructor(public message_id: string) {}
}

export class HistoryEffect implements Action {
  readonly type = HISTORY_EFFECT;
  constructor(public message: IMessage) {}
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class MessageEdited implements Action {
  readonly type = MESSAGE_EDITED;
  constructor(public payload: IMessage) {}
}

export class GetBlobUrl implements Action {
  readonly type = GET_BLOB_URL;
  constructor(public selectedFiles: ISelectedFiles) {}
}

export class CancelMediaOperation implements Action {
  readonly type = CANCEL_MEDIA_OPERATION;
  constructor(public payload: IMessage) {}
}

export class RetryMediaOperation implements Action {
  readonly type = RETRY_MEDIA_OPERATION;
  constructor(public payload: IMessage) {}
}

export class SendMessageNow implements Action {
  readonly type = SEND_MESSAGE_NOW;
  constructor(public payload: IMessage) {}
}

export class CancelScheduleMessage implements Action {
  readonly type = CANCEL_SCHEDULE_MESSAGE;
  constructor(public payload: string) {}
}

export class SendTextMessage implements Action {
  readonly type = SEND_TEXT;
  constructor(
    public mainChat: MainChat,
    public parentMessage: IMessage,
    public userId: string,
    public text: string,
    public misc?: MessageControls
  ) {}
}

export class SendInstantUrlMessage implements Action {
  readonly type = SEND_INSTANT_URL;
  constructor(
    public mainChat: MainChat,
    public parentMessage: IMessage,
    public userId: string,
    public instantArticle: InstantArticle,
    public misc?: MessageControls
  ) {}
}

export class SendMediaMessage implements Action {
  readonly type = SEND_MEDIA;
  constructor(
    public mainChat: MainChat,
    public parentMessage: IMessage,
    public userId: string,
    public file: File,
    public mediaType: string,
    public previewMetadata?: UrlPreviewMeta,
    public blobUrl?: string,
    public misc?: MessageControls
  ) {}
}

export class SendCalendarMessage implements Action {
  readonly type = SEND_CALENDAR;
  constructor(
    public mainChat: MainChat,
    public parentMessage: IMessage,
    public userId: string,
    public calendar: CalendarTimetable,
    public misc?: MessageControls
  ) {}
}

export class PreviewLinkMessage implements Action {
  readonly type = PREVIEW_LINK_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class SendingCreateTag implements Action {
  readonly type = SENDING_CREATE_TAG;
  constructor(public payload: ITag) {}
}
export class SendingDeleteTag implements Action {
  readonly type = SENDING_DELETE_TAG;
  constructor(public payload: ITag) {}
}
export class SendingSetMemberTags implements Action {
  readonly type = SENDING_SET_MEMBER_TAGS;
  constructor(public payload: { user_id: string; tags: string[] }) {}
}

export class GetMessageMenuStats implements Action {
  readonly type = GET_MESSAGE_MENU_STATS;
  constructor(public payload: string) {}
}
export class SetMessageMenuStats implements Action {
  readonly type = SET_MESSAGE_MENU_STATS;
  constructor(
    public payload: { message_id: string; menuStats: MenuCallBackStats[] }
  ) {}
}

export class GetInlineMessageCallBack implements Action {
  readonly type = GET_INLINE_MESSAGE_CALLBACK;
  constructor(public payload: any) {}
}

export class SetCounterIfExisting implements Action {
  readonly type = SET_COUNTER_IF_EXISTING;
  constructor(public message_id: string, public messageCounter?: any) {}
}
export class Reset implements Action {
  readonly type = RESET;
}

export type MessagesActions =
  | GetLastPostsHistory
  | GetLastRepliesHistory
  | ListScheduleMessages
  | ListAwardMessages
  | ListTags
  | InitReceivedMessage
  | ReceiveMessage
  | ReceiveAwardMessage
  | ReceiveWelcomeMessage
  | ReceiveScheduleMessage
  | ReceiveScheduleMessageCanceled
  | SendingMessage
  | SendingScheduleMessage
  | SendingAwardMessage
  | SendingWelcomeMessage
  | SendMessageNow
  | CancelScheduleMessage
  | SendTextMessage
  | SendMediaMessage
  | SendInstantUrlMessage
  | SendCalendarMessage
  | GetBlobUrl
  | MessageSent
  | MessageDelivered
  | MessageSeen
  | MessagesSeenReceived
  | SetMessageMediaStatus
  | SetMessageThumbnailStatus
  | SetMessageLocalMedia
  | SetMessageLocalThumbnail
  | DownloadMediaMessage
  | DownloadMessageThumbnail
  | CancelDownloadMediaMessage
  | CancelUpload
  | MessageIsViewed
  | ChatCountersReceived
  | UpdateLoadingProgress
  | LikeMessage
  | UnlikeMessage
  | ShareMessage
  | LikeAckReceived
  | SetLinkPreviewStatus
  | LinkPreviewDetailsReceived
  | MessageRecalled
  | RecallMessage
  | ChatMessagesOutOfViewPort
  | ContactMessagesOutOfViewPort
  | ReadChatMessages
  | ChatDeselected
  | UpdateExistingMessage
  | ResetNumberOfUnreadReplies
  | MessageEdited
  | HistoryEffect
  | DeselectChannel
  | CancelMediaOperation
  | RetryMediaOperation
  | PreviewLinkMessage
  | SendingCreateTag
  | SendingDeleteTag
  | SendingSetMemberTags
  | GetMessageMenuStats
  | SetMessageMenuStats
  | SetCounterIfExisting
  | Reset;
