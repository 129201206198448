import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as TemplateActions from './templates.actions';

import { AppState } from 'src/models/AppState';
import { AppTemplate } from 'src/models/AppTemplate';

@Injectable({
  providedIn: 'root'
})
export class TemplateDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveTemplates(templates: AppTemplate[]): void {
    this._store.dispatch(new TemplateActions.ReceiveTemplates(templates));
  }

  selectAppTemplate(template: AppTemplate): void {
    this._store.dispatch(new TemplateActions.SelectAppTemplate(template));
  }
}
