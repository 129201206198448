import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { IPricePlan } from 'src/models/IPricePlan';

@Component({
  selector: 'app-subscription-confirm-cancel',
  templateUrl: './subscription-confirm-cancel.component.html',
  styleUrls: ['./subscription-confirm-cancel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionConfirmCancelComponent {
  @Input() plan: IPricePlan;
  @Input() url: string;
  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() backNotify = new EventEmitter<boolean>();

  closeEvent() {
    this.closeNotify.emit(true);
  }
  backEvent() {
    this.backNotify.emit(true);
  }
  goToPaymentPage() {
    window.open(this.url, '_blank');
    this.closeNotify.emit(true);
  }
}
