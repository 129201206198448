import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-align-btn',
  templateUrl: './align-btn.component.html',
  styleUrls: ['./align-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlignBtnComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() type = 'button';
  @Input() focused = false;
  @Output() btnClicked = new EventEmitter<boolean>();
  @ViewChild('alignBtnId', { static: false }) btn: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['focused'];
    if (chng) {
      if (chng.currentValue) {
        this.btn.nativeElement.focus();
      } else {
        this.btn.nativeElement.blur();
      }
    }
  }

  onClick() {
    this.btnClicked.emit(true);
  }
}
