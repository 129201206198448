import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-colored-text-cell',
  templateUrl: './colored-text-cell.component.html',
  styleUrls: ['./colored-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColoredTextCellComponent implements OnInit {
  @Input() text: string;
  @Input() bgColor: string;

  constructor() {}

  ngOnInit() {}
}
