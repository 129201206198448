/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menus-rows.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../abstract-frames/headerless-closable-frame/headerless-closable-frame.component.ngfactory";
import * as i3 from "../../../../abstract-frames/headerless-closable-frame/headerless-closable-frame.component";
import * as i4 from "@angular/common";
import * as i5 from "../menu-row-item/menu-row-item.component.ngfactory";
import * as i6 from "../menu-row-item/menu-row-item.component";
import * as i7 from "@angular/forms";
import * as i8 from "../menus.service";
import * as i9 from "../../app-mgmt/appMgmt.service";
import * as i10 from "../../../../store/ui/ui.dispatchers";
import * as i11 from "../../bots/bots.service";
import * as i12 from "../../../+layout/layout.service";
import * as i13 from "../../../../core/file.service";
import * as i14 from "../../../../network/gateway/upload.gateway";
import * as i15 from "../../../../core/auth.service";
import * as i16 from "../../../../core/storage.service";
import * as i17 from "../../../../core/ui.service";
import * as i18 from "../../mobile-workflow-store/mobile-workflow-store.service";
import * as i19 from "../../collections/collections.service";
import * as i20 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i21 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i22 from "./menus-rows.component";
var styles_MenusRowsComponent = [i0.styles];
var RenderType_MenusRowsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenusRowsComponent, data: {} });
export { RenderType_MenusRowsComponent as RenderType_MenusRowsComponent };
function View_MenusRowsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Menu Style "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please choose the menu style from the list below: "]))], null, null); }
function View_MenusRowsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Poll Style "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please choose the poll style from the list below: "]))], null, null); }
export function View_MenusRowsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "app-headerless-closable-frame", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderlessClosableFrameComponent_0, i2.RenderType_HeaderlessClosableFrameComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderlessClosableFrameComponent, [], { showCancel: [0, "showCancel"] }, { closeNotify: "closeNotify" }), (_l()(), i1.ɵeld(2, 0, null, 0, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "rows-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "title-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenusRowsComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenusRowsComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["src", "./assets/img/close-menu-row.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "app-menu-row-item", [], null, [[null, "styleSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("styleSelectedNotify" === en)) {
        var pd_0 = (_co.selectedStyle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MenuRowItemComponent_0, i5.RenderType_MenuRowItemComponent)), i1.ɵdid(13, 638976, null, 0, i6.MenuRowItemComponent, [i1.ChangeDetectorRef, i7.FormBuilder, i8.MenusService, i9.AppMgmtService, i10.UIDispatchers, i11.BotsService, i12.LayoutService, i13.FileService, i14.UploadGateway, i15.AuthService, i16.StorageService, i17.UiService, i18.MobileWorkFlowStoreService, i19.CollectionsService], { button: [0, "button"], length: [1, "length"], isApp: [2, "isApp"], menuStoreList: [3, "menuStoreList"] }, { styleSelectedNotify: "styleSelectedNotify" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "app-border-btn", [["publish", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.finalSelectedStyle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_BorderBtnComponent_0, i20.RenderType_BorderBtnComponent)), i1.ɵdid(17, 573440, null, 0, i21.BorderBtnComponent, [], { type: [0, "type"] }, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Add "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isApp; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isApp; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.button; var currVal_4 = _co.length; var currVal_5 = _co.isApp; var currVal_6 = i1.ɵunv(_v, 13, 3, i1.ɵnov(_v, 14).transform(((_co.mobileStoreService == null) ? null : _co.mobileStoreService.selectedMenuList$))); _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = "submit"; _ck(_v, 17, 0, currVal_7); }, null); }
export function View_MenusRowsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menus-rows", [], null, null, null, View_MenusRowsComponent_0, RenderType_MenusRowsComponent)), i1.ɵdid(1, 114688, null, 0, i22.MenusRowsComponent, [i18.MobileWorkFlowStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenusRowsComponentNgFactory = i1.ɵccf("app-menus-rows", i22.MenusRowsComponent, View_MenusRowsComponent_Host_0, { button: "button", length: "length", isApp: "isApp" }, { styleSelectedNotify: "styleSelectedNotify", cancelNotify: "cancelNotify" }, []);
export { MenusRowsComponentNgFactory as MenusRowsComponentNgFactory };
