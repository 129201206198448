import { Action } from '@ngrx/store';
import {
  AppConfig,
  AppInfo,
  App,
  AppColors,
  ImageSet,
  TabTagItem
} from '../../../models/ChannelAppConfig';
import { LocalAppConfig } from '../../../models/ChannelAppLocalConfig';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UserData } from 'src/models/constants';

export const enum AppConfigActionTypes {
  REQUEST_APP_CONFIG = 'REQUEST_APP_CONFIG',
  APP_CONFIG_ACK = 'APP_CONFIG_ACK',
  RECEIVE_APP_CONFIG = 'RECEIVE_APP_CONFIG',
  SET_RULE_ENGINE_ACCESS = 'SET_RULE_ENGINE_ACCESS',
  RECEIVE_DEFAULT_APP_CONFIG = 'RECEIVE_DEFAULT_APP_CONFIG',
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  SET_APP_CONFIG = 'SET_APP_CONFIG',
  SET_APP = 'SET_APP',
  PUBLISH_APP_CONFIG = 'PUBLISH_APP_CONFIG',
  GENERATE_APP = 'GENERATE_APP',
  APP_CONFIG_PUBLISHED = 'APP_CONFIG_PUBLISHED',
  UPDATE_APP_INFO = 'UPDATE_APP_INFO',
  REQUEST_APP_TEMPLATE = 'REQUEST_APP_TEMPLATE',
  RECEIVE_APP_TEMPLATE = 'RECEIVE_APP_TEMPLATE',
  UPDATE_APP_COLORS = 'UPDATE_APP_COLORS',
  UPLOAD_APP_COLORED_LOGO = 'UPLOAD_APP_COLORED_LOGO',
  UPLOAD_APP_CHAT_ICON = 'UPLOAD_APP_CHAT_ICON',
  UPLOAD_APP_ICON = 'UPLOAD_APP_ICON',
  UPLOAD_APP_COLORED_LOGO_SUCCESS = 'UPLOAD_APP_COLORED_LOGO_SUCCESS',
  UPDATE_DOMAINANT_COLOR = 'UPDATE_DOMAINANT_COLOR',
  UPLOAD_APP_WHITE_LOGO = 'UPLOAD_APP_WHITE_LOGO',
  GET_DOMAINAT_COLOR = 'GET_DOMAINAT_COLOR',
  UPLOAD_APP_WHITE_LOGO_SUCCESS = 'UPLOAD_APP_WHITE_LOGO_SUCCESS',
  UPLOAD_IOS_STORE_LOGO = 'UPLOAD_IOS_STORE_LOGO',
  UPLOAD_IOS_STORE_LOGO_SUCCESS = 'UPLOAD_IOS_STORE_LOGO_SUCCESS',
  CREATE_APP_TEMPLATE = 'CREATE_APP_TEMPLATE',
  GET_APP_TEMPLATE_META = 'CREATE_APP_TEMPLATE_META',
  REQUEST_CONFIG_QR_CODE = 'REQUEST_CONFIG_QR_CODE',
  RECEIVE_TAG_VALUES = 'RECEIVE_TAG_VALUES',
  SET_IN_APP_PURCHASES = 'SET_IN_APP_PURCHASES',
  UPLOAD_TEMP_IMAGE_PROFILE = 'UPLOAD_TEMP_IMAGE_PROFILE',
  GET_APP_PRIVILLEGES = 'GET_APP_PRIVILLEGES',
  SET_MSTORE = 'SET_MSTORE',
  GET_MSTORE = 'GET_MSTORE',
  RESET = 'RESET'
}

export class RequestAppConfig implements Action {
  readonly type = AppConfigActionTypes.REQUEST_APP_CONFIG;
}

export class RequestConfigQrCode implements Action {
  readonly type = AppConfigActionTypes.REQUEST_CONFIG_QR_CODE;
}

export class AppConfigAck implements Action {
  readonly type = AppConfigActionTypes.APP_CONFIG_ACK;
  constructor(public onlineAppConfig: App, public offlineAppConfig) {}
}

export class ReceiveAppConfig implements Action {
  readonly type = AppConfigActionTypes.RECEIVE_APP_CONFIG;
  constructor(
    public onlineAppConfig: AppConfig,
    public offlineAppConfig: AppConfig
  ) {}
}

export class SetRuleEngineAccess implements Action {
  readonly type = AppConfigActionTypes.SET_RULE_ENGINE_ACCESS;
  constructor() {}
}
export class ReceiveDefaultAppConfig implements Action {
  readonly type = AppConfigActionTypes.RECEIVE_DEFAULT_APP_CONFIG;
  constructor(public offlineAppConfig: AppConfig) {}
}

export class AppConfigDataChanged implements Action {
  readonly type = AppConfigActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class SetAppConfig implements Action {
  readonly type = AppConfigActionTypes.SET_APP_CONFIG;
  constructor(
    public channelConfOnly: boolean,
    public selectedPageNumber?: number,

    public modules?: string[],
    public dashboard?: any
  ) {}
}

export class SetMStore implements Action {
  readonly type = AppConfigActionTypes.SET_MSTORE;
  constructor(public menu_id?: string, public currency?: string) {}
}

export class GetDefaultMStore implements Action {
  readonly type = AppConfigActionTypes.GET_MSTORE;
  constructor(public id?: string) {}
}

export class SetApp implements Action {
  readonly type = AppConfigActionTypes.SET_APP;
  constructor(public userData?: UserData, public tempId?: string) {}
}

export class CreateAppTemplate implements Action {
  readonly type = AppConfigActionTypes.CREATE_APP_TEMPLATE;
  constructor(
    public androidImageUrl: string,
    public iosImageUrl: string,
    public categories: string[],
    public name: string,
    public order: number,
    public template: any,
    public id?: string
  ) {}
}

export class GetAppTemplate implements Action {
  readonly type = AppConfigActionTypes.GET_APP_TEMPLATE_META;
  constructor(public id?: string) {}
}

export class GetAppPrivilleges implements Action {
  readonly type = AppConfigActionTypes.GET_APP_PRIVILLEGES;
  constructor() {}
}

export class PublishAppConfig implements Action {
  readonly type = AppConfigActionTypes.PUBLISH_APP_CONFIG;
  constructor(
    public isChannelOnly: boolean,
    public track: number,
    public versionName: string,
    public versionDesc: string
  ) {}
}

export class GenerateApp implements Action {
  readonly type = AppConfigActionTypes.GENERATE_APP;
  constructor(
    public appId: string,
    public os: number,
    public android_format: string,
    public version_name: string,
    public version_desc: string
  ) {}
}

export class UpdateAppInfo implements Action {
  readonly type = AppConfigActionTypes.UPDATE_APP_INFO;
  constructor(public payload: AppInfo) {}
}

export class AppConfigPublished implements Action {
  readonly type = AppConfigActionTypes.APP_CONFIG_PUBLISHED;
  constructor(public isChannelOnly: boolean) {}
}

export class RequestAppTemplate implements Action {
  readonly type = AppConfigActionTypes.REQUEST_APP_TEMPLATE;
  constructor(public templateID: string, public reference?: string) {}
}

export class ReceiveAppTemplate implements Action {
  readonly type = AppConfigActionTypes.RECEIVE_APP_TEMPLATE;
  constructor(
    public onlineAppConfig: App,
    public offlineAppConfig: App,
    public onlineAppConfigIOS: App,
    public offlineAppConfigIOS: App,
    public modules?: any[]
  ) {}
}

export class UpdateAppColors implements Action {
  readonly type = AppConfigActionTypes.UPDATE_APP_COLORS;
  constructor(public appColors: AppColors) {}
}

export class UploadAppColoredLogo implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_COLORED_LOGO;
  constructor(public selectedFile: ISelectedFiles) {}
}

export class GetDomainatColor implements Action {
  readonly type = AppConfigActionTypes.GET_DOMAINAT_COLOR;
  constructor(public selectedFile: ISelectedFiles) {}
}

export class UploadAppChatIcon implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_CHAT_ICON;
  constructor(public selectedFile: ISelectedFiles) {}
}

export class UploadAppIcon implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_ICON;
  constructor(
    public selectedFile: ISelectedFiles,
    public selectedPageNumber?: number,
    public featuresId?: string[],
    public dashboard?: any
  ) {}
}

export class UploadAppColoredLogoSuccess implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_COLORED_LOGO_SUCCESS;
  constructor(
    public imageId: string,
    public imageUrl: string,
    public imageSet: ImageSet,
    public dominantColor?: string
  ) {}
}

export class UploadAppDomaintColor implements Action {
  readonly type = AppConfigActionTypes.UPDATE_DOMAINANT_COLOR;
  constructor(public dominantColor?: string) {}
}

export class UploadAppWhiteLogo implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_WHITE_LOGO;
  constructor(public selectedFile: ISelectedFiles) {}
}

export class UploadAppWhiteLogoSuccess implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_APP_WHITE_LOGO_SUCCESS;
  constructor(public imageUrl: string, public imageSet: ImageSet) {}
}

export class UploadIosStoreLogo implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_IOS_STORE_LOGO;
  constructor(public selectedFile: ISelectedFiles) {}
}

export class UploadIosStoreLogoSuccess implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_IOS_STORE_LOGO_SUCCESS;
  constructor(public imageUrl: string) {}
}

export class ReceiveTagValues implements Action {
  readonly type = AppConfigActionTypes.RECEIVE_TAG_VALUES;
  constructor(
    public tabsTags: TabTagItem[],
    public isChannelConfigOnly: boolean
  ) {}
}

export class SetInAppPurchases implements Action {
  readonly type = AppConfigActionTypes.SET_IN_APP_PURCHASES;
  constructor(public json: string) {}
}

export class UploadProfileImage implements Action {
  readonly type = AppConfigActionTypes.UPLOAD_TEMP_IMAGE_PROFILE;
  constructor(public imageId: string) {}
}

export class Reset implements Action {
  readonly type = AppConfigActionTypes.RESET;
}

export type Actions =
  | RequestAppConfig
  | RequestConfigQrCode
  | AppConfigAck
  | ReceiveAppConfig
  | ReceiveDefaultAppConfig
  | AppConfigDataChanged
  | SetAppConfig
  | PublishAppConfig
  | UpdateAppInfo
  | AppConfigPublished
  | RequestAppTemplate
  | ReceiveAppTemplate
  | UpdateAppColors
  | UploadAppColoredLogo
  | UploadAppColoredLogoSuccess
  | UploadAppWhiteLogo
  | UploadAppWhiteLogoSuccess
  | UploadIosStoreLogo
  | UploadIosStoreLogoSuccess
  | GenerateApp
  | CreateAppTemplate
  | ReceiveTagValues
  | SetInAppPurchases
  | UploadAppDomaintColor
  | GetDomainatColor
  | UploadAppIcon
  | SetMStore
  | GetDefaultMStore
  | Reset;
