import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import * as MenuUIActions from './menuUI.actions';
import { MenuUIDispatchers } from './menuUI.dispatchers';

import { AppState } from 'src/models/AppState';
import * as MenuMethods from 'src/models/IBotMenu';
import { PrivilegesName } from 'src/models/privileges';

import * as isEqual from 'lodash.isequal';
import { GetMenus } from 'src/models/IBotMenu';
import {
  PageDirection,
  FAILED_TO_UPLOAD_IMAGE,
  LOGO_WIDTH,
  LOGO_HEIGHT,
  ERROR_IMAGE_SHOULD_BE_1024X1024,
  SPLASH_PAGE_UPLOAD_TYPE,
  NO_FILE_NAME,
  ERROR_NOT_PNG_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes
} from 'src/models/constants';
import { IUploadResponseImage } from 'src/models/IUploadResponseImage';
import { HttpEventType } from '@angular/common/http';
import { ImageSet } from 'src/models/ChannelAppConfig';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable()
export class MenuUIEffects {
  @Effect({ dispatch: false })
  listCoupons = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_LIST_MENUS),
    map((action: MenuUIActions.ListMenus) => {
      this._socketGateway.sendSocketMessage(
        new MenuMethods.ListMyMenu(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  uploadMenuImg = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UPLOAD_MENU_IMG),
    map((action: MenuUIActions.UploadMenuImg) => {
      if (this.validateImage(action.selectedFile)) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.localFile)
          .then(fileAsArrayBuffer => {
            this._fileService
              .readArrayBufferAsBlobUrl(
                fileAsArrayBuffer,
                action.selectedFile.type
              )
              .then(blobUrl => {
                this._fileService.getImageMetadata(blobUrl).then(props => {
                  if (
                    props.width !== props.height ||
                    props.width < LOGO_WIDTH ||
                    props.height < LOGO_HEIGHT
                  ) {
                    this._uiDispatchers.showPopup(
                      ERROR_IMAGE_SHOULD_BE_1024X1024
                    );
                  } else {
                    this._uploadGateway
                      .uploadWithProgress(
                        fileAsArrayBuffer,
                        action.selectedFile.localFile.type,
                        action.selectedFile.localFile.name,
                        false,
                        SPLASH_PAGE_UPLOAD_TYPE,
                        null,
                        null,
                        true
                      )
                      .subscribe(
                        event => {
                          if (event.type === HttpEventType.Response) {
                            const res = <IUploadResponseImage>event.body;
                            let imageUrl = '';
                            if (res.files[4]) {
                              imageUrl = res.files[4].media.permanentUrl;
                            }
                            const imageSet: ImageSet = {};
                            imageSet.hdpi = res.files[0].media.permanentUrl;
                            imageSet.mdpi = res.files[1].media.permanentUrl;
                            imageSet.xhdpi = res.files[2].media.permanentUrl;
                            imageSet.xxhdpi = res.files[3].media.permanentUrl;
                            imageSet.xxxhdpi = res.files[4].media.permanentUrl;
                            imageSet.ios1x = res.files[5].media.permanentUrl;
                            imageSet.ios2x = res.files[6].media.permanentUrl;
                            imageSet.ios3x = res.files[7].media.permanentUrl;

                            this._menuUIDispatchers.UploadMenuImgSuccess(
                              action.componentRef,
                              imageUrl,
                              imageSet
                            );
                          }
                        },
                        error => {
                          this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                        }
                      );
                  }
                });
              });
          });
      }
    })
  );

  @Effect({ dispatch: false })
  menusReceiving = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_MENUS_RECEIVING),
    map((action: MenuUIActions.MenusReceiving) => {
      const pyln = action.payload;
      if (pyln.mymenus) {
        for (const menu of pyln.mymenus) {
          if (menu.sub_menu) {
            const localMenu = menu.sub_menu;
            localMenu.id = menu.id;
            this._menuUIDispatchers.menuReceived(localMenu);
          }
        }
      }
    })
  );

  @Effect({ dispatch: false })
  menuReceving = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_MENU_RECEIVING),
    map((action: MenuUIActions.MenuReceiveing) => {
      const localMenu = action.payload.mymenu.menu;
      localMenu.id = action.payload.mymenu.id;
      this._menuUIDispatchers.menuReceived(localMenu);
    })
  );

  @Effect({ dispatch: false })
  sendingMenu = this.actions$.pipe(
    ofType(
      MenuUIActions.MenuUIActionTypes.UI_MENU_CREATE_MENU,
      MenuUIActions.MenuUIActionTypes.UI_MENU_UPDATE_MENU
    ),
    map((action: MenuUIActions.UpdateMenu) => {
      const { id } = action.payload;
      if (id) {
        this._socketGateway.sendSocketMessage(
          new MenuMethods.SetMyMenu(
            action.payload,
            action.payload,
            action.payload.menu_name,
            id,
            action.payload.menu_ref,
            action.payload.next_menu
          )
        );
      } else {
        this._socketGateway.sendSocketMessage(
          new MenuMethods.CreateMenu(
            action.payload,
            action.payload,
            action.payload.menu_name,
            action.payload.menu_ref,
            action.payload.next_menu,
            action.payload.type
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  getNextMenuPage = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, menuUiState]) => {
      const action = <MenuUIActions.GetNextMenuPage>val;
      const prevRequest = menuUiState.previousRequest;
      if (
        !(
          menuUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          menuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMenus(0, menuUiState.hash, menuUiState.eop)
        );
      } else {
        this._menuUIDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMenuPage = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.menuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, menuUiState]) => {
      const action = <MenuUIActions.GetPrevMenuPage>val;
      const prevRequest = menuUiState.previousRequest;
      if (
        !(
          menuUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          menuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMenus(1, menuUiState.hash, menuUiState.sop)
        );
      } else {
        this._menuUIDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterMenuResetPaging = this.actions$.pipe(
    ofType(MenuUIActions.MenuUIActionTypes.UI_MENU_RESET_PAGING),
    map((action: MenuUIActions.ResetMenuPaging) => {
      this._menuUIDispatchers.getNextMenuPage();
    })
  );
  private validateImage(selectedFile: ISelectedFiles): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (selectedFile.localFile.type.indexOf('image/png') === -1) {
      this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    }
    return true;
  }
  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _menuUIDispatchers: MenuUIDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _socketGateway: SocketGateway
  ) {}
}
