<div
  [class.main-container]="startOver || store"
  [class.main-off]="selectedButton"
>
  <div
    class="menu-bar"
    *ngIf="(startOver || section === uiSection?.MOBILESTORE) && !selectedButton"
  >
    <div class="bar-data">
      <div
        class="title"
        *ngIf="
          (appManagementService?.effectiveSelectedTab$ | async)?.menu_id ===
            botMenuEdit?.menu_id &&
          !selectedTab?.sub_type &&
          currentPage === appScreens?.TABS &&
          section !== uiSection?.MOBILESTORE &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="
          (appManagementService?.effectiveSelectedItem$ | async)?.menu_id ===
            botMenuEdit?.menu_id &&
          !selectedTab?.sub_type &&
          section !== uiSection?.MOBILESTORE &&
          currentPage === appScreens?.NAV_MENU &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          (selectedTab?.sub_type && currentPage === appScreens?.TABS) &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="appManagementService?.selectedMenuButtonTab$ | async"
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          selectedTab?.sub_type &&
          currentPage === appScreens?.NAV_MENU &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          section === uiSection?.MOBILESTORE
        "
      >
        Home
      </div>
      <div
        class="title"
        *ngIf="
          (appManagementService?.effectiveSelectedTab$ | async)?.menu_id !==
            botMenuEdit?.menu_id &&
          !selectedTab?.sub_type &&
          currentPage === appScreens?.TABS &&
          section !== uiSection?.MOBILESTORE &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Sub Menu
      </div>

      <ng-container
        *ngIf="!selectedTab?.sub_type && currentPage === appScreens?.NAV_MENU"
      >
        <div
          class="title"
          *ngIf="
            (appManagementService?.effectiveSelectedItem$ | async)?.menu_id !==
              botMenuEdit?.menu_id &&
            section !== uiSection?.MOBILESTORE &&
            currentPage === appScreens?.NAV_MENU &&
            !(appManagementService?.selectedMenuButtonTab$ | async)
          "
        >
          Sub Menu
        </div>
      </ng-container>
      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          (selectedTab?.sub_type && currentPage === appScreens?.TABS) &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Sub Menu
      </div>

      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          section === uiSection?.MOBILESTORE
        "
      >
        Sub Menu
      </div>
      <div
        class="title"
        *ngIf="
          (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id &&
          selectedTab?.sub_type &&
          currentPage === appScreens?.NAV_MENU &&
          !(appManagementService?.selectedMenuButtonTab$ | async)
        "
      >
        Sub Menu
      </div>
    </div>

    <div
      class="cnt-section"
      *ngIf="
        currentPage === appScreens?.NAV_MENU &&
        !selectedTab?.sub_type &&
        section !== uiSection?.MOBILESTORE &&
        !(appManagementService?.selectedMenuButtonTab$ | async)
      "
    >
      <!-- <div class="create-menu" *ngIf="startOver" (click)="saveMenu(true)">
        <img src="./assets/img/add-new-screen.svg" />
        <div class="title">
          Add New Screen
        </div>
      </div> -->

      <div class="set-home">
        <img
          src="./assets/img/main-menu-check.svg"
          style="cursor: not-allowed;"
          *ngIf="
            (appManagementService?.effectiveSelectedItem$ | async)?.menu_id ===
            botMenuEdit?.menu_id
          "
          (click)="('')"
        />
        <img
          src="./assets/img/main-menu-uncheck.svg"
          *ngIf="
            (appManagementService?.effectiveSelectedItem$ | async)?.menu_id !==
            botMenuEdit?.menu_id as sub
          "
          (click)="setMainMenu(false)"
        />
        <div
          class="sub-title"
          *ngIf="
            (appManagementService?.effectiveSelectedItem$ | async)?.menu_id ===
            botMenuEdit?.menu_id
          "
        >
          Main Screen
        </div>
        <div
          class="sub-title"
          *ngIf="
            (appManagementService?.effectiveSelectedItem$ | async)?.menu_id !==
            botMenuEdit?.menu_id
          "
        >
          Set As Main Screen
        </div>
      </div>
      <!-- <img style="margin-left: 5px;" src="./assets/img/settings-menu-form.svg" /> -->
      <div
        class="clear"
        [class.disabled-item]="
          currentMenu?.rows?.length == 0 || !currentMenu?.rows
        "
        (click)="deleteMenu(currentMenu, selectedTab)"
      >
        Clear All
      </div>
      <!-- <img
      style="margin-right: 5px;"
      src="./assets/img/restart.svg"
      (click)="deleteMenu(currentMenu, selectedTab)"
    /> -->
    </div>
    <div
      class="cnt-section"
      *ngIf="
        !selectedTab?.sub_type &&
        currentPage === appScreens?.TABS &&
        section !== uiSection?.MOBILESTORE &&
        !(appManagementService?.selectedMenuButtonTab$ | async)
      "
    >
      <!-- <div class="create-menu" *ngIf="startOver" (click)="saveMenu(true)">
      <img src="./assets/img/add-new-screen.svg" />
      <div class="title">
        Add New Screen
      </div>
    </div> -->
      <div class="set-home">
        <img
          src="./assets/img/main-menu-check.svg"
          style="cursor: not-allowed;"
          *ngIf="
            !(appManagementService?.selectedMenuButtonTab$ | async) &&
            (appManagementService?.effectiveSelectedTab$ | async)?.menu_id ===
              currentMenu?.menu_id
          "
          (click)="('')"
        />
        <img
          src="./assets/img/main-menu-uncheck.svg"
          *ngIf="
            !(appManagementService?.selectedMenuButtonTab$ | async) &&
            (appManagementService?.effectiveSelectedTab$ | async)?.menu_id !==
              currentMenu?.menu_id as sub
          "
          (click)="setMainMenu(false)"
        />
        <div
          class="sub-title"
          *ngIf="
            !(appManagementService?.selectedMenuButtonTab$ | async) &&
            (appManagementService?.effectiveSelectedTab$ | async)?.menu_id ===
              currentMenu?.menu_id
          "
        >
          Main Screen
        </div>
        <div
          class="sub-title"
          *ngIf="
            !(appManagementService?.selectedMenuButtonTab$ | async) &&
            (appManagementService?.effectiveSelectedTab$ | async)?.menu_id !==
              currentMenu?.menu_id
          "
        >
          Set As Main Screen
        </div>
      </div>

      <!-- <img style="margin-left: 5px;" src="./assets/img/settings-menu-form.svg" /> -->
      <div
        class="clear"
        [class.disabled-item]="
          currentMenu?.rows?.length == 0 || !currentMenu?.rows
        "
        (click)="deleteMenu(currentMenu, selectedTab)"
      >
        Clear All
      </div>
      <!-- <img
    style="margin-right: 5px;"
    src="./assets/img/restart.svg"
    (click)="deleteMenu(currentMenu, selectedTab)"
  /> -->
    </div>

    <div
      class="cnt-section"
      *ngIf="
        (currentPage === appScreens?.TABS && selectedTab?.sub_type) ||
        section === uiSection?.MOBILESTORE
      "
    >
      <!-- <div class="create-menu" *ngIf="startOver" (click)="saveMenu(true)">
      <img src="./assets/img/add-new-screen.svg" />
      <div class="title">
        Add New Screen
      </div>
    </div> -->
      <div class="set-home">
        <img
          src="./assets/img/main-menu-check.svg"
          style="cursor: not-allowed;"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
          (click)="('')"
        />
        <img
          src="./assets/img/main-menu-uncheck.svg"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
          (click)="setMainMenuStore(botMenuEdit?.menu_id)"
        />
        <div
          class="sub-title"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
        >
          Main Screen
        </div>
        <div
          class="sub-title"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
        >
          Set As Main Screen
        </div>
      </div>
      <!-- <img style="margin-left: 5px;" src="./assets/img/settings-menu-form.svg" /> -->
      <div
        class="clear"
        [class.disabled-item]="
          currentMenu?.rows?.length == 0 || !currentMenu?.rows
        "
        (click)="deleteMenu(currentMenu, selectedTab)"
      >
        Clear All
      </div>
      <!-- <img
    style="margin-right: 5px;"
    src="./assets/img/restart.svg"
    (click)="deleteMenu(currentMenu, selectedTab)"
  /> -->
    </div>

    <div
      class="cnt-section"
      *ngIf="appManagementService?.selectedMenuButtonTab$ | async"
    >
      <!-- <div class="create-menu" *ngIf="startOver" (click)="saveMenu(true)">
    <img src="./assets/img/add-new-screen.svg" />
    <div class="title">
      Add New Screen
    </div>
  </div> -->
      <div class="set-home">
        <img
          src="./assets/img/main-menu-check.svg"
          style="cursor: not-allowed;"
          (click)="('')"
        />

        <div class="sub-title">
          Main Screen
        </div>
      </div>
      <!-- <img style="margin-left: 5px;" src="./assets/img/settings-menu-form.svg" /> -->
      <div
        class="clear"
        [class.disabled-item]="
          currentMenu?.rows?.length == 0 || !currentMenu?.rows
        "
        (click)="deleteMenu(currentMenu, selectedTab)"
      >
        Clear All
      </div>
      <!-- <img
  style="margin-right: 5px;"
  src="./assets/img/restart.svg"
  (click)="deleteMenu(currentMenu, selectedTab)"
/> -->
    </div>
    <div
      class="cnt-section"
      *ngIf="currentPage === appScreens?.NAV_MENU && selectedTab?.sub_type"
    >
      <!-- <div class="create-menu" *ngIf="startOver" (click)="saveMenu(true)">
    <img src="./assets/img/add-new-screen.svg" />
    <div class="title">
      Add New Screen
    </div>
  </div> -->
      <div class="set-home">
        <img
          src="./assets/img/main-menu-check.svg"
          style="cursor: not-allowed;"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
          (click)="('')"
        />
        <img
          src="./assets/img/main-menu-uncheck.svg"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
          (click)="setMainMenuStore(botMenuEdit?.menu_id)"
        />
        <div
          class="sub-title"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) ===
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
        >
          Main Screen
        </div>
        <div
          class="sub-title"
          *ngIf="
            (mobileWorkFlowService?.mainMenuId$ | async) !==
            (mobileWorkFlowService?.selectedMenu$ | async)?.menu_id
          "
        >
          Set As Main Screen
        </div>
      </div>
      <!-- <img style="margin-left: 5px;" src="./assets/img/settings-menu-form.svg" /> -->
      <div
        class="clear"
        [class.disabled-item]="
          currentMenu?.rows?.length == 0 || !currentMenu?.rows
        "
        (click)="deleteMenu(currentMenu, selectedTab)"
      >
        Clear All
      </div>
      <!-- <img
  style="margin-right: 5px;"
  src="./assets/img/restart.svg"
  (click)="deleteMenu(currentMenu, selectedTab)"
/> -->
    </div>
  </div>

  <div
    class="customize"
    [class.polls]="!startOver && !store"
    [class.tab]="startOver || store"
  >
    <div class="container-title" [hidden]="startOver || store">
      <div *ngIf="startOver">Menu</div>
      <div *ngIf="store">Store</div>
      <div *ngIf="!startOver && !store">Create a New Poll</div>
    </div>

    <!-- <div class="add-action" (click)="showConfirmation(true)">
    Add New Menu
  </div> -->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div
        class="menu-form-container"
        [class.settings]="selectedButton && (startOver || store)"
      >
        <!-- <div class="creating-title" *ngIf="!showBottonConfig && startOver">
        Create Your Menu
      </div> -->
        <div class="menus-container" *ngIf="!showBottonConfig">
          <div class="menu-header-form" [hidden]="startOver || store">
            <div class="form-input">
              <label for="inputMenuName" class="name">Name</label>
              <input
                *ngIf="startOver || store"
                type="text"
                id="inputMenuName"
                class="form-control"
                formControlName="menu_name"
                placeholder="Enter Menu Name"
              />
              <input
                *ngIf="!startOver && !store"
                type="text"
                id="inputMenuName"
                class="form-control"
                formControlName="menu_name"
                placeholder="Enter Poll Name"
              />
            </div>

            <div class="form-input" *ngIf="!startOver && !store">
              <label for="inputMenuName">Response Message</label>

              <app-chips-frame
                class="next-menu-ref"
                *ngIf="nextMenu"
                (removeNotify)="resetMenuRef()"
              >
                {{ (getMenuWithRefId$() | async)?.menu_name }}
              </app-chips-frame>

              <app-align-btn
                (btnClicked)="toggleMenusPoolModalVisibility(true)"
              >
                Set Response Message
              </app-align-btn>
            </div>

            <!-- <div class="form-input">
          <label for="inputMenuId">ID</label>
          <input
            type="text"
            id="inputMenuId"
            class="form-control"
            formControlName="menu_id"
            placeholder="Menu ID"
          />
        </div> -->
          </div>
          <!-- <div
          class="empty-menu"
          *ngIf="form?.value?.rows.length <= 0 && !startOver"
        >
          <img src="./assets/img/empty-menu.svg" />
          <span class="title">Empty Poll!</span>
          <span>Click 'Add Poll' to create a new poll. </span>
        </div>

        <div
          class="empty-menu"
          *ngIf="form?.value?.rows.length <= 0 && startOver"
        >
          <img src="./assets/img/empty-menu.svg" />
          <span class="title">Empty Menu!</span>
          <span>Click 'Add Menu' to create a new menu. </span>
        </div> -->

          <app-menu-holder
            [menus]="form.value"
            [showRemove]="true"
            [systemConfg]="appManagementService?.systemConfig$ | async"
            [tabsList]="appManagementService?.tabsContainers$ | async"
            (createButtonNotifiy)="addNewButton($event)"
            (readButtonNotifiy)="setSelectedBtn($event)"
            (deleteButtonNotifiy)="removeButton($event)"
            (deleteRowNotifiy)="removeRow($event)"
            (dropButtondNotify)="dropButton($event)"
          >
          </app-menu-holder>
          <div (drop)="dropHandler($event)">
            <div
              appCancelActionIfNotHasPrivilege
              [privilege]="
                (appManagementService?.privilegModule$ | async)?.privilege
              "
              [moduleID]="(appManagementService?.privilegModule$ | async)?.id"
              class="drag-box"
            >
              <img src="./assets/img/drag.svg" />
              <div class="drag-title">
                Drop items here
              </div>
              <div class="drag-subtitle">
                <div class="drag-subtitle">
                  <span
                    >Drag and drop what suits you from the elements of the
                    form</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <div
          class="menu-add-btn"
          (click)="showRowsSelector()"
          *ngIf="startOver"
        >
          <img
            src="./assets/img/menu-add-btn.svg"
            alt="add row"
            class="clickable-cursor"
          />
          <span>Add Menu </span>
        </div>
        <div
          class="menu-add-btn"
          (click)="showRowsSelector()"
          *ngIf="!startOver"
        >
          <img
            src="./assets/img/menu-add-btn.svg"
            alt="add row"
            class="clickable-cursor"
          />
          <span>Add Poll </span>
        </div> -->
        </div>
        <div class="test" [hidden]="!selectedButton">
          <div
            formArrayName="rows"
            *ngFor="let row of form['controls'].rows['controls']; let i = index"
          >
            <div [hidden]="selectedButton?.row !== i" [formGroupName]="i">
              <div
                formArrayName="buttons"
                *ngFor="
                  let col of row['controls'].buttons['controls'];
                  let j = index
                "
              >
                <div
                  class="button-form"
                  [hidden]="selectedButton?.col !== j"
                  [formGroupName]="j"
                >
                  <div
                    class="form-control-header"
                    *ngIf="false"
                    [ngStyle]="getButtonStyle(col.value)"
                  >
                    {{ col.value['button_label'] }}
                  </div>
                  <div class="set-up">
                    <div class="form-control-name">
                      <div class="body">
                        <mat-tab-group
                          style="overflow: hidden;"
                          [@.disabled]="true"
                        >
                          <mat-tab>
                            <ng-template mat-tab-label>
                              Settings
                            </ng-template>
                            <div class="custom-row">
                              <div
                                class="body first"
                                *ngIf="
                                  getButtonStyles({ row: i, col: j })?.value !==
                                    rowStyles.TEXTONLY_NEW &&
                                  getButtonStyles({ row: i, col: j })?.value !==
                                    rowStyles.BACK_NEW &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.SUBMIT &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.OUTPUT &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.INPUT &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.SEPARATOR &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.SINGLE_CHOICE &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.SELECT_CHOICE &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.MULTI_CHOICE &&
                                  getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.TOGGLE_CHOICE
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/image-section.svg"
                                      />
                                    </div>
                                    <div class="item-title">Image</div>
                                  </div>
                                  <div class="image-container">
                                    <div
                                      class="profile-media-btn"
                                      [class.center-img]="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.BUTTON &&
                                        getButtonStyles({ row: i, col: j })
                                          ?.value === rowStyles.CENTERIMG_NEW
                                      "
                                      [class.top-image]="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.BUTTON &&
                                        (getButtonStyles({ row: i, col: j })
                                          ?.value === rowStyles.TOPIMG_NEW ||
                                          getButtonStyles({ row: i, col: j })
                                            ?.value === rowStyles.WIDEBG_NEW)
                                      "
                                      [class.img-left-big]="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.BUTTON &&
                                        (getButtonStyles({ row: i, col: j })
                                          ?.value === rowStyles.WIDELIFT_NEW ||
                                          getButtonStyles({ row: i, col: j })
                                            ?.value === rowStyles.SMALLHL_NEW)
                                      "
                                      (click)="showMedia({ row: i, col: j })"
                                    >
                                      <div
                                        class="uploaded-image-btn"
                                        [ngStyle]="uploadedImageButton"
                                      ></div>
                                    </div>
                                    <div class="recommend">
                                      Recommended PNG image format.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SUBMIT &&
                                  (getButtonStyles({ row: i, col: j })
                                    ?.value === rowStyles.SUBMITBASICICON ||
                                    getButtonStyles({ row: i, col: j })
                                      ?.value === rowStyles.SUBMITICONCOLOR)
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/icons-section.svg"
                                      />
                                    </div>
                                    <div class="item-title">Icons</div>
                                  </div>
                                  <div>
                                    <span for="inputGroupName">
                                      Icon
                                    </span>
                                    <app-selector-input-icon
                                      class="icon-droplist"
                                      [iconName]="
                                        getIconName({ row: i, col: j })?.value
                                      "
                                      (updateItemIconNotify)="
                                        updatedButtonIcon(
                                          { row: i, col: j },
                                          $event
                                        )
                                      "
                                      [iconList]="'Android'"
                                    >
                                    </app-selector-input-icon>
                                  </div>
                                </div>
                              </div>
                              <div class="body first">
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/info-black-24-dp.svg"
                                      />
                                    </div>
                                    <div class="item-title">Info</div>
                                  </div>
                                  <div>
                                    <span>Button ID</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      formControlName="button_id"
                                      placeholder="Title"
                                      disabled
                                    />
                                  </div>
                                  <div>
                                    <span>Button Callback</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      formControlName="button_callback"
                                      placeholder="Callback"
                                      (change)="
                                        updatedButtonCallback(
                                          $event.target.value,
                                          {
                                            row: i,
                                            col: j
                                          }
                                        )
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SELECT_CHOICE ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.COLLECTION &&
                                    (getButtonStyles({ row: i, col: j })
                                      .value ===
                                      rowStyles.SQUAREWITHNOBKGDTEXT ||
                                      getButtonStyles({ row: i, col: j })
                                        .value ===
                                        rowStyles.HALFROWSQUAREWITHBKGDNOTEXT ||
                                      getButtonStyles({ row: i, col: j })
                                        .value ===
                                        rowStyles.HALFROWSQUAREWITHBKGD ||
                                      getButtonStyles({ row: i, col: j })
                                        .value === rowStyles.FULLROWCIRCLE)) ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SEPARATOR &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.EMPTY) ||
                                  (getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.SEPARATOR &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SINGLE_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SELECT_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.MULTI_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.TOGGLE_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.COLLECTION) ||
                                  (getButtonStyles({ row: i, col: j }).value !==
                                    rowStyles.BACK_NEW &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.INPUT &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SUBMIT &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.OUTPUT &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SEPARATOR &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SINGLE_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.SELECT_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.MULTI_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.TOGGLE_CHOICE &&
                                    getButtonForm({ row: i, col: j })?.value !==
                                      menuCat.COLLECTION) ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.OUTPUT &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.PAYMENT &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.COST) ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.OUTPUT
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/inputs-section.svg"
                                      />
                                    </div>
                                    <div class="item-title">Inputs</div>
                                  </div>
                                  <div
                                    *ngIf="
                                      getButtonForm({ row: i, col: j })
                                        ?.value === menuCat.SELECT_CHOICE ||
                                      (getButtonForm({ row: i, col: j })
                                        ?.value === menuCat.SEPARATOR &&
                                        getButtonStyles({ row: i, col: j })
                                          ?.value !== rowStyles.EMPTY) ||
                                      (getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.SEPARATOR &&
                                        getButtonForm({ row: i, col: j })
                                          ?.value !== menuCat.COLLECTION) ||
                                      (getButtonForm({ row: i, col: j })
                                        ?.value === menuCat.COLLECTION &&
                                        (getButtonStyles({ row: i, col: j })
                                          .value ===
                                          rowStyles.SQUAREWITHNOBKGDTEXT ||
                                          getButtonStyles({ row: i, col: j })
                                            .value ===
                                            rowStyles.HALFROWSQUAREWITHBKGDNOTEXT ||
                                          getButtonStyles({ row: i, col: j })
                                            .value ===
                                            rowStyles.HALFROWSQUAREWITHBKGD ||
                                          getButtonStyles({ row: i, col: j })
                                            .value === rowStyles.FULLROWCIRCLE))
                                    "
                                  >
                                    <span>Label</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      formControlName="button_label"
                                      placeholder="Title"
                                      (change)="
                                        updatedButtonTitle(
                                          col.value,
                                          $event.target.value
                                        )
                                      "
                                    />
                                  </div>
                                  <div
                                    *ngIf="
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.SELECT_CHOICE &&
                                      getButtonStyles({ row: i, col: j })
                                        .value !== rowStyles.BACK_NEW &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.INPUT &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.SUBMIT &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.OUTPUT &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.SEPARATOR &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.COLLECTION
                                    "
                                  >
                                    <span>Sub label</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      formControlName="button_sublabel"
                                      placeholder="Description"
                                      (change)="
                                        updatedButtonDesc(
                                          col.value,
                                          $event.target.value
                                        )
                                      "
                                    />
                                  </div>
                                  <div
                                    *ngIf="
                                      getButtonForm({ row: i, col: j })
                                        ?.value === menuCat.OUTPUT &&
                                      getButtonStyles({ row: i, col: j })
                                        ?.value !== rowStyles.PAYMENT &&
                                      getButtonStyles({ row: i, col: j })
                                        ?.value !== rowStyles.COST &&
                                      getButtonForm({ row: i, col: j })
                                        ?.value !== menuCat.COLLECTION
                                    "
                                  >
                                    <span>Sub label</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      formControlName="button_sublabel"
                                      placeholder="Description"
                                      (change)="
                                        updatedButtonDesc(
                                          col.value,
                                          $event.target.value
                                        )
                                      "
                                    />
                                  </div>

                                  <div
                                    *ngIf="
                                      getButtonForm({ row: i, col: j })
                                        ?.value === menuCat.OUTPUT
                                    "
                                  >
                                    <span>Value</span>

                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="35"
                                      placeholder="Value"
                                      [value]="getValue(col)"
                                      (change)="
                                        updatedButtonValue(
                                          col.value,
                                          $event.target.value,
                                          {
                                            row: i,
                                            col: j
                                          },
                                          null
                                        )
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SINGLE_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SELECT_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.MULTI_CHOICE
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img src="./assets/img/option-icon.svg" />
                                    </div>
                                    <div class="item-title">Options</div>
                                  </div>
                                  <div class="options-container">
                                    <div
                                      class="option"
                                      *ngFor="
                                        let opt of getButtonOption({
                                          row: i,
                                          col: j
                                        })?.value;
                                        let ind = index
                                      "
                                    >
                                      <span>Option {{ ind + 1 }}</span>
                                      <div class="options-control">
                                        <div class="option-data">
                                          <input
                                            #optionId
                                            [class.option-input]="
                                              !checkValidation(opt?.id)
                                            "
                                            style="margin-right: 8px;"
                                            type="text"
                                            [value]="opt?.id"
                                            maxlength="35"
                                            [class.error]="
                                              checkValidation(opt?.id)
                                            "
                                            placeholder="Title"
                                            (blur)="
                                              changeButtonOptionId(
                                                { row: i, col: j },
                                                opt?.id,
                                                optionId.value
                                              )
                                            "
                                            disabled
                                          />
                                          <div
                                            class="option-img"
                                            (click)="
                                              showOptionImg(
                                                { row: i, col: j },
                                                opt?.id
                                              )
                                            "
                                            *ngIf="
                                              getButtonForm({ row: i, col: j })
                                                ?.value !==
                                                menuCat.SELECT_CHOICE &&
                                              (imgCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              ) ||
                                                imgToggleCheck(
                                                  getButtonStyles({
                                                    row: i,
                                                    col: j
                                                  })?.value
                                                ))
                                            "
                                          >
                                            <img
                                              class="img-option"
                                              [src]="
                                                getButtonOptionImage(
                                                  { row: i, col: j },
                                                  opt?.id
                                                )
                                              "
                                            />
                                          </div>
                                          <app-selector-input-icon
                                            *ngIf="
                                              iconCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              ) ||
                                              iconToggleCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              )
                                            "
                                            class="icon-droplist"
                                            [iconName]="opt?.icon"
                                            (updateItemIconNotify)="
                                              changeButtonOptionIcon(
                                                { row: i, col: j },
                                                opt?.id,
                                                $event
                                              )
                                            "
                                            [iconList]="'Android'"
                                          >
                                          </app-selector-input-icon>
                                        </div>
                                        <div
                                          class="option-data"
                                          style="margin-left: 8px;"
                                        >
                                          <input
                                            #optionLabel
                                            class="option-input"
                                            type="text"
                                            [value]="opt?.label"
                                            maxlength="50"
                                            placeholder="Title"
                                            (blur)="
                                              changeButtonOptionLabel(
                                                { row: i, col: j },
                                                opt?.id,
                                                optionLabel.value
                                              )
                                            "
                                          />
                                          <input
                                            #subLabel
                                            *ngIf="
                                              getButtonForm({ row: i, col: j })
                                                ?.value !==
                                              menuCat.SELECT_CHOICE
                                            "
                                            class="option-input"
                                            style="margin-left: 8px;"
                                            type="text"
                                            [value]="opt?.sublabel"
                                            maxlength="35"
                                            placeholder="DECS"
                                            (blur)="
                                              changeButtonOptionDesc(
                                                { row: i, col: j },
                                                opt?.id,
                                                subLabel.value
                                              )
                                            "
                                          />
                                          <input
                                            #subLabel
                                            *ngIf="
                                              getButtonForm({ row: i, col: j })
                                                ?.value ===
                                              menuCat.SELECT_CHOICE
                                            "
                                            class="option-input"
                                            style="margin-left: 8px;"
                                            type="text"
                                            [value]="opt?.value"
                                            maxlength="35"
                                            placeholder="VALUE"
                                            (blur)="
                                              changeButtonOptionValue(
                                                { row: i, col: j },
                                                opt?.id,
                                                subLabel.value
                                              )
                                            "
                                          />
                                        </div>
                                      </div>
                                      <input
                                        *ngIf="
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.SINGLE_CHOICE
                                        "
                                        type="radio"
                                        class="option-radio"
                                        [ngStyle]="
                                          actionColor(
                                            getButtonActionColor({
                                              row: i,
                                              col: j
                                            })?.value
                                          )
                                        "
                                        [checked]="
                                          getButtonOptionValue(
                                            { row: i, col: j },
                                            opt?.id
                                          ) === 'true'
                                        "
                                        (click)="
                                          addAsignOptionValue(
                                            { row: i, col: j },
                                            opt?.id
                                          )
                                        "
                                      />

                                      <input
                                        *ngIf="
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.MULTI_CHOICE &&
                                          !checkForToggle(
                                            getButtonStyles({
                                              row: i,
                                              col: j
                                            })?.value
                                          )
                                        "
                                        type="checkbox"
                                        class="option-radio"
                                        [ngStyle]="
                                          actionColor(
                                            getButtonActionColor({
                                              row: i,
                                              col: j
                                            })?.value
                                          )
                                        "
                                        [checked]="
                                          getButtonOptionValue(
                                            { row: i, col: j },
                                            opt?.id
                                          ) === 'true'
                                        "
                                        (change)="
                                          addAsignCheckValue(
                                            { row: i, col: j },
                                            opt?.id,
                                            $event
                                          )
                                        "
                                      />
                                      <label
                                        class="switch-mobile-type"
                                        *ngIf="
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.MULTI_CHOICE &&
                                          checkForToggle(
                                            getButtonStyles({
                                              row: i,
                                              col: j
                                            })?.value
                                          )
                                        "
                                      >
                                        <input
                                          type="checkbox"
                                          [checked]="
                                            getButtonOptionValue(
                                              { row: i, col: j },
                                              opt?.id
                                            ) === 'true'
                                          "
                                          (change)="
                                            addAsignCheckValue(
                                              { row: i, col: j },
                                              opt?.id,
                                              $event
                                            )
                                          "
                                        />

                                        <div
                                          [id]="generatToggalId(opt?.id)"
                                          class="slider round "
                                          [ngStyle]="{
                                            'background-color': toggleActionColor(
                                              getButtonActionColor({
                                                row: i,
                                                col: j
                                              })?.value,
                                              getButtonOptionValue(
                                                { row: i, col: j },
                                                opt?.id
                                              ),
                                              opt?.id
                                            )
                                          }"
                                        ></div>
                                      </label>

                                      <img
                                        style="cursor: pointer;"
                                        class="delete-img"
                                        src="./assets/img/delete-option.svg"
                                        (click)="
                                          removeButtonOption(
                                            { row: i, col: j },
                                            ind
                                          )
                                        "
                                      />
                                    </div>
                                    <div
                                      class="option"
                                      style="margin-bottom: -20px !important;"
                                    >
                                      <div class="fake-span"></div>
                                      <div class="options-control">
                                        <div class="option-data-fake">
                                          <div
                                            class="option-input-add"
                                            style="margin-right: 8px;"
                                          ></div>
                                          <div
                                            class="option-img-fake"
                                            *ngIf="
                                              imgCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              ) ||
                                              imgToggleCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              )
                                            "
                                          ></div>
                                          <div
                                            *ngIf="
                                              iconCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              ) ||
                                              iconToggleCheck(
                                                getButtonStyles({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              )
                                            "
                                            class="btn-group"
                                          ></div>
                                        </div>
                                        <div
                                          class="option-data-fake"
                                          style="margin-left: 8px;"
                                        >
                                          <div class="option-input-add"></div>
                                          <div
                                            class="option-input-add"
                                            (click)="
                                              addNewOption({ row: i, col: j })
                                            "
                                          >
                                            Add Option
                                          </div>
                                        </div>
                                      </div>
                                      <div class="option-radio"></div>

                                      <div class="img-option"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="body first"
                                *ngIf="
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SEPARATOR &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !==
                                      rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !==
                                      rowStyles.LEFTTITLEBOTTOMBORDER) ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.BUTTON &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.WIDEBG_NEW) ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.OUTPUT ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SUBMIT &&
                                    (getButtonStyles({ row: i, col: j })
                                      ?.value === rowStyles.SUBMITCOLOR ||
                                      getButtonStyles({ row: i, col: j })
                                        ?.value ===
                                        rowStyles.SUBMITICONCOLOR)) ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SUBMIT &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.SUBMIT &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.SUBMITBASICICON &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.SUBMITICONCOLOR &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.SUBMITCOLOR) ||
                                  ((getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SEPARATOR &&
                                    getButtonStyles({ row: i, col: j })
                                      ?.value !== rowStyles.EMPTY) ||
                                    getButtonForm({ row: i, col: j })?.value ===
                                      menuCat.BUTTON ||
                                    getButtonForm({ row: i, col: j })?.value ===
                                      menuCat.OUTPUT ||
                                    getButtonForm({ row: i, col: j })?.value ===
                                      menuCat.INPUT ||
                                    (getButtonForm({ row: i, col: j })
                                      ?.value === menuCat.SUBMIT &&
                                      (getButtonStyles({ row: i, col: j })
                                        ?.value === rowStyles.SUBMIT ||
                                        getButtonStyles({
                                          row: i,
                                          col: j
                                        })?.value ===
                                          rowStyles.SUBMITBASICICON ||
                                        getButtonStyles({
                                          row: i,
                                          col: j
                                        })?.value ===
                                          rowStyles.SUBMITICONCOLOR ||
                                        getButtonStyles({
                                          row: i,
                                          col: j
                                        })?.value ===
                                          rowStyles.SUBMITCOLOR))) ||
                                  ((getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SEPARATOR &&
                                    (getButtonStyles({ row: i, col: j })
                                      ?.value ===
                                      rowStyles.EMPTYWITHTITLEANDBOARDER ||
                                      getButtonStyles({ row: i, col: j })
                                        ?.value ===
                                        rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER ||
                                      getButtonStyles({ row: i, col: j })
                                        ?.value === rowStyles.LEFTTILEBORDER ||
                                      getButtonStyles({ row: i, col: j })
                                        ?.value ===
                                        rowStyles.LEFTTITLEBOTTOMBORDER)) ||
                                    (getButtonForm({ row: i, col: j })
                                      ?.value === menuCat.SUBMIT &&
                                      (getButtonStyles({ row: i, col: j })
                                        ?.value === rowStyles.SUBMITBASICICON ||
                                        getButtonStyles({ row: i, col: j })
                                          ?.value === rowStyles.SUBMIT))) ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SUBMIT &&
                                    (getButtonStyles({ row: i, col: j })
                                      ?.value === rowStyles.SUBMITBASICICON ||
                                      getButtonStyles({ row: i, col: j })
                                        ?.value ===
                                        rowStyles.SUBMITICONCOLOR)) ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SINGLE_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SELECT_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.MULTI_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.TOGGLE_CHOICE ||
                                  (getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.COLLECTION &&
                                    (getButtonStyles({ row: i, col: j })
                                      .value ===
                                      rowStyles.SQUAREWITHNOBKGDTEXT ||
                                      getButtonStyles({ row: i, col: j })
                                        .value ===
                                        rowStyles.HALFROWSQUAREWITHBKGDNOTEXT ||
                                      getButtonStyles({ row: i, col: j })
                                        .value ===
                                        rowStyles.HALFROWSQUAREWITHBKGD ||
                                      getButtonStyles({ row: i, col: j })
                                        .value === rowStyles.FULLROWCIRCLE ||
                                      getButtonStyles({ row: i, col: j })
                                        .value ===
                                        rowStyles.HALFROWSQUAREWITHBKGD))
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/color-section.svg"
                                      />
                                    </div>
                                    <div class="item-title">Colors</div>
                                  </div>
                                  <div class="color-container">
                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SEPARATOR &&
                                        getButtonStyles({ row: i, col: j })
                                          ?.value !==
                                          rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER &&
                                        getButtonStyles({ row: i, col: j })
                                          ?.value !==
                                          rowStyles.LEFTTITLEBOTTOMBORDER
                                      "
                                    >
                                      <div class="transparent-title">
                                        Background Color On
                                      </div>
                                      <input
                                        type="checkbox"
                                        style="    height: 15px;
                                        width: 15px;
                                         margin-top: 5px;"
                                        class="class-check-box"
                                        [checked]="
                                          getBgColor({ row: i, col: j })
                                            .value !== null
                                        "
                                        (change)="
                                          updatedButtonBgColor(
                                            { row: i, col: j },
                                            null
                                          )
                                        "
                                      />
                                    </div>
                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SEPARATOR &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !==
                                            rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !==
                                            rowStyles.LEFTTITLEBOTTOMBORDER) ||
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.BUTTON &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !== rowStyles.WIDEBG_NEW) ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.OUTPUT ||
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SUBMIT &&
                                          (getButtonStyles({ row: i, col: j })
                                            ?.value === rowStyles.SUBMITCOLOR ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value ===
                                              rowStyles.SUBMITICONCOLOR)) ||
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.COLLECTION &&
                                          getButtonStyles({ row: i, col: j })
                                            .value ===
                                            rowStyles.HALFROWSQUAREWITHBKGD)
                                      "
                                    >
                                      <span>Background Color</span>

                                      <div class="picker">
                                        <div class="p-container">
                                          <input
                                            readonly="true"
                                            [colorPicker]="
                                              getBgColor({ row: i, col: j })
                                                .value
                                            "
                                            [style.background]="
                                              getBgColor({ row: i, col: j })
                                                .value
                                            "
                                            [cpAlphaChannel]="'disabled'"
                                            [cpOKButton]="true"
                                            [cpOutputFormat]="'hex'"
                                            [cpPosition]="'right'"
                                            (colorPickerChange)="
                                              updatedButtonBgColor(
                                                { row: i, col: j },
                                                $event
                                              )
                                            "
                                            class="clickable-cursor"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SINGLE_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SELECT_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.MULTI_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.TOGGLE_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.INPUT ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.OUTPUT
                                      "
                                    >
                                      <ng-container>
                                        <span>Text Color</span>
                                        <div class="picker">
                                          <div class="p-container">
                                            <input
                                              readonly="true"
                                              [colorPicker]="
                                                getBaseTextColor({
                                                  row: i,
                                                  col: j
                                                }).value
                                              "
                                              [style.background]="
                                                getBaseTextColor({
                                                  row: i,
                                                  col: j
                                                }).value
                                              "
                                              [cpOKButton]="true"
                                              [cpAlphaChannel]="'disabled'"
                                              [cpOutputFormat]="'hex'"
                                              [cpPosition]="'left'"
                                              (colorPickerChange)="
                                                updatedButtonBasicTextColor(
                                                  { row: i, col: j },
                                                  $event
                                                )
                                              "
                                              class="clickable-cursor"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div style="    margin-right: 45px;">
                                      <ng-container
                                        *ngIf="
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.SUBMIT &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !== rowStyles.SUBMIT &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !==
                                            rowStyles.SUBMITBASICICON &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !==
                                            rowStyles.SUBMITICONCOLOR &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !== rowStyles.SUBMITCOLOR
                                        "
                                      >
                                        <span>Label Color</span>
                                        <div class="picker">
                                          <div class="p-container">
                                            <input
                                              readonly="true"
                                              [colorPicker]="
                                                getTextColor({ row: i, col: j })
                                                  .value
                                              "
                                              [style.background]="
                                                getTextColor({ row: i, col: j })
                                                  .value
                                              "
                                              [cpOKButton]="true"
                                              [cpAlphaChannel]="'disabled'"
                                              [cpOutputFormat]="'hex'"
                                              [cpPosition]="'left'"
                                              (colorPickerChange)="
                                                updatedButtonTextColor(
                                                  { row: i, col: j },
                                                  $event
                                                )
                                              "
                                              class="clickable-cursor"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>

                                      <ng-container
                                        *ngIf="
                                          (getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.SEPARATOR &&
                                            getButtonStyles({ row: i, col: j })
                                              ?.value !== rowStyles.EMPTY) ||
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.BUTTON ||
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.OUTPUT ||
                                          (getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.SUBMIT &&
                                            (getButtonStyles({ row: i, col: j })
                                              ?.value === rowStyles.SUBMIT ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              })?.value ===
                                                rowStyles.SUBMITBASICICON ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              })?.value ===
                                                rowStyles.SUBMITICONCOLOR ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              })?.value ===
                                                rowStyles.SUBMITCOLOR)) ||
                                          (getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.COLLECTION &&
                                            (getButtonStyles({ row: i, col: j })
                                              .value ===
                                              rowStyles.SQUAREWITHNOBKGDTEXT ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              }).value ===
                                                rowStyles.HALFROWSQUAREWITHBKGDNOTEXT ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              }).value ===
                                                rowStyles.HALFROWSQUAREWITHBKGD ||
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              }).value ===
                                                rowStyles.FULLROWCIRCLE))
                                        "
                                      >
                                        <span>Label Color</span>
                                        <div class="picker">
                                          <div class="p-container">
                                            <input
                                              readonly="true"
                                              [colorPicker]="
                                                getTextColor({ row: i, col: j })
                                                  .value
                                              "
                                              [style.background]="
                                                getTextColor({ row: i, col: j })
                                                  .value
                                              "
                                              [cpAlphaChannel]="'disabled'"
                                              [cpOKButton]="true"
                                              [cpOutputFormat]="'hex'"
                                              [cpPosition]="'right'"
                                              (colorPickerChange)="
                                                updatedButtonTextColor(
                                                  { row: i, col: j },
                                                  $event
                                                )
                                              "
                                              class="clickable-cursor"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div
                                      *ngIf="
                                        (getButtonForm({ row: i, col: j })
                                          ?.value !== menuCat.SELECT_CHOICE &&
                                          getButtonStyles({ row: i, col: j })
                                            .value !== rowStyles.BACK_NEW &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.INPUT &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.SUBMIT &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.OUTPUT &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.SEPARATOR &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.COLLECTION &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.SINGLE_CHOICE &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.SELECT_CHOICE &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !== menuCat.MULTI_CHOICE &&
                                          getButtonForm({ row: i, col: j })
                                            ?.value !==
                                            menuCat.TOGGLE_CHOICE) ||
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.OUTPUT &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !== rowStyles.PAYMENT &&
                                          getButtonStyles({ row: i, col: j })
                                            ?.value !== rowStyles.COST)
                                      "
                                      style="    margin-right: 45px;"
                                    >
                                      <ng-container>
                                        <span>Sub Label Color</span>
                                        <div class="picker">
                                          <div class="p-container">
                                            <input
                                              readonly="true"
                                              [colorPicker]="
                                                getSubLabelColor({
                                                  row: i,
                                                  col: j
                                                }).value
                                              "
                                              [style.background]="
                                                getSubLabelColor({
                                                  row: i,
                                                  col: j
                                                }).value
                                              "
                                              [cpAlphaChannel]="'disabled'"
                                              [cpOKButton]="true"
                                              [cpOutputFormat]="'hex'"
                                              [cpPosition]="'right'"
                                              (colorPickerChange)="
                                                updatedButtonSubLabelColor(
                                                  { row: i, col: j },
                                                  $event
                                                )
                                              "
                                              class="clickable-cursor"
                                            />
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>

                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SEPARATOR &&
                                          (getButtonStyles({ row: i, col: j })
                                            ?.value ===
                                            rowStyles.EMPTYWITHTITLEANDBOARDER ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value ===
                                              rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value ===
                                              rowStyles.LEFTTILEBORDER ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value ===
                                              rowStyles.LEFTTITLEBOTTOMBORDER)) ||
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SUBMIT &&
                                          (getButtonStyles({ row: i, col: j })
                                            ?.value ===
                                            rowStyles.SUBMITBASICICON ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value === rowStyles.SUBMIT))
                                      "
                                    >
                                      <span>Border Color</span>

                                      <div class="picker">
                                        <div class="p-container">
                                          <input
                                            readonly="true"
                                            [colorPicker]="
                                              getButtonBorderColor({
                                                row: i,
                                                col: j
                                              }).value
                                            "
                                            [style.background]="
                                              getButtonBorderColor({
                                                row: i,
                                                col: j
                                              }).value
                                            "
                                            [cpOKButton]="true"
                                            [cpAlphaChannel]="'disabled'"
                                            [cpOutputFormat]="'hex'"
                                            [cpPosition]="'right'"
                                            (colorPickerChange)="
                                              updatedButtonBorderColor(
                                                { row: i, col: j },
                                                $event
                                              )
                                            "
                                            class="clickable-cursor"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        (getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SUBMIT &&
                                          (getButtonStyles({ row: i, col: j })
                                            ?.value ===
                                            rowStyles.SUBMITBASICICON ||
                                            getButtonStyles({ row: i, col: j })
                                              ?.value ===
                                              rowStyles.SUBMITICONCOLOR)) ||
                                        ((getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SINGLE_CHOICE ||
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.SELECT_CHOICE ||
                                          getButtonForm({ row: i, col: j })
                                            ?.value === menuCat.MULTI_CHOICE ||
                                          getButtonForm({ row: i, col: j })
                                            ?.value ===
                                            menuCat.TOGGLE_CHOICE) &&
                                          (iconCheck(
                                            getButtonStyles({
                                              row: i,
                                              col: j
                                            })?.value
                                          ) ||
                                            iconToggleCheck(
                                              getButtonStyles({
                                                row: i,
                                                col: j
                                              })?.value
                                            )))
                                      "
                                    >
                                      <span>Icon Color</span>

                                      <div class="picker">
                                        <div class="p-container">
                                          <input
                                            readonly="true"
                                            [colorPicker]="
                                              getIconColor({ row: i, col: j })
                                                .value
                                            "
                                            [style.background]="
                                              getIconColor({ row: i, col: j })
                                                .value
                                            "
                                            [cpAlphaChannel]="'disabled'"
                                            [cpOKButton]="true"
                                            [cpOutputFormat]="'hex'"
                                            [cpOKButton]="true"
                                            [cpPosition]="'left'"
                                            (colorPickerChange)="
                                              updatedButtonIconColor(
                                                { row: i, col: j },
                                                $event
                                              )
                                            "
                                            class="clickable-cursor"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style="    margin-right: 45px;"
                                      *ngIf="
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.SINGLE_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.MULTI_CHOICE ||
                                        getButtonForm({ row: i, col: j })
                                          ?.value === menuCat.TOGGLE_CHOICE
                                      "
                                    >
                                      <span>Action Color</span>

                                      <div class="picker">
                                        <div class="p-container">
                                          <input
                                            readonly="true"
                                            [colorPicker]="
                                              actionColorPicker(
                                                getButtonActionColor({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              )
                                            "
                                            [style.background]="
                                              actionColorPicker(
                                                getButtonActionColor({
                                                  row: i,
                                                  col: j
                                                })?.value
                                              )
                                            "
                                            [cpAlphaChannel]="'disabled'"
                                            [cpOKButton]="true"
                                            [cpOutputFormat]="'hex'"
                                            [cpOKButton]="true"
                                            [cpPosition]="'left'"
                                            (colorPickerChange)="
                                              updatedButtonActionColor(
                                                { row: i, col: j },
                                                $event
                                              )
                                            "
                                            class="clickable-cursor"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                  menuCat.INPUT
                                "
                              >
                                <div class="item-container">
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/keyboard-section.svg"
                                      />
                                    </div>
                                    <div class="item-title">Keyboard</div>
                                  </div>
                                  <div>
                                    <div class="keyboard-title">
                                      Please select the type of keyboard you
                                      want to open
                                    </div>

                                    <div class="seaction-container">
                                      <div
                                        class="seaction"
                                        [class.active]="
                                          keyboardTypes.STANDARD ===
                                          getButtonKeyboard({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                      >
                                        <div class="action-container">
                                          <input
                                            type="radio"
                                            formControlName="button_keyboard"
                                            [value]="keyboardTypes.STANDARD"
                                            [checked]="
                                              keyboardTypes.STANDARD ===
                                              getButtonKeyboard({
                                                row: i,
                                                col: j
                                              })?.value
                                            "
                                            (change)="
                                              updatedButtonKeyboard(
                                                col.value,
                                                keyboardTypes.STANDARD,
                                                {
                                                  row: i,
                                                  col: j
                                                }
                                              )
                                            "
                                          />
                                          <div class="profile-media">
                                            <div class="category">Standard</div>
                                          </div>
                                        </div>
                                        <img
                                          src="https://nandbox.com/wp-content/uploads/2023/05/Standard.webp"
                                        />
                                      </div>

                                      <div
                                        class="seaction"
                                        [class.active]="
                                          keyboardTypes.NUMERIC ===
                                          getButtonKeyboard({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                      >
                                        <div class="action-container">
                                          <input
                                            type="radio"
                                            formControlName="button_keyboard"
                                            [value]="keyboardTypes.NUMERIC"
                                            [checked]="
                                              keyboardTypes.NUMERIC ===
                                              getButtonKeyboard({
                                                row: i,
                                                col: j
                                              })?.value
                                            "
                                            (change)="
                                              updatedButtonKeyboard(
                                                col.value,
                                                keyboardTypes.NUMERIC,
                                                {
                                                  row: i,
                                                  col: j
                                                }
                                              )
                                            "
                                          />
                                          <div class="profile-media">
                                            <div class="category">Numeric</div>
                                          </div>
                                        </div>
                                        <img
                                          src="https://nandbox.com/wp-content/uploads/2023/05/Numeric.webp"
                                        />
                                      </div>

                                      <div
                                        class="seaction"
                                        [class.active]="
                                          keyboardTypes.PHONE ===
                                          getButtonKeyboard({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                      >
                                        <div class="action-container">
                                          <input
                                            type="radio"
                                            formControlName="button_keyboard"
                                            [value]="keyboardTypes.PHONE"
                                            [checked]="
                                              keyboardTypes.PHONE ===
                                              getButtonKeyboard({
                                                row: i,
                                                col: j
                                              })?.value
                                            "
                                            (change)="
                                              updatedButtonKeyboard(
                                                col.value,
                                                keyboardTypes.PHONE,
                                                {
                                                  row: i,
                                                  col: j
                                                }
                                              )
                                            "
                                          />
                                          <div class="profile-media">
                                            <div class="category">Phone</div>
                                          </div>
                                        </div>
                                        <img
                                          src="https://nandbox.com/wp-content/uploads/2023/05/Phone.webp"
                                        />
                                      </div>

                                      <div
                                        class="seaction"
                                        [class.active]="
                                          keyboardTypes.EMAIL ===
                                          getButtonKeyboard({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                      >
                                        <div class="action-container">
                                          <input
                                            formControlName="button_keyboard"
                                            type="radio"
                                            [value]="keyboardTypes.EMAIL"
                                            [checked]="
                                              keyboardTypes.EMAIL ===
                                              getButtonKeyboard({
                                                row: i,
                                                col: j
                                              })?.value
                                            "
                                            (change)="
                                              updatedButtonKeyboard(
                                                col.value,
                                                keyboardTypes.EMAIL,
                                                {
                                                  row: i,
                                                  col: j
                                                }
                                              )
                                            "
                                          />
                                          <div class="profile-media">
                                            <div class="category">Email</div>
                                          </div>
                                        </div>
                                        <img
                                          src="https://nandbox.com/wp-content/uploads/2023/05/Email.webp"
                                        />
                                      </div>

                                      <div
                                        class="seaction"
                                        [class.active]="
                                          keyboardTypes.PASSWORD ===
                                          getButtonKeyboard({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                      >
                                        <div class="action-container">
                                          <input
                                            formControlName="button_keyboard"
                                            type="radio"
                                            [value]="keyboardTypes.PASSWORD"
                                            [checked]="
                                              keyboardTypes.PASSWORD ===
                                              getButtonKeyboard({
                                                row: i,
                                                col: j
                                              })?.value
                                            "
                                            (change)="
                                              updatedButtonKeyboard(
                                                col.value,
                                                keyboardTypes.PASSWORD,
                                                {
                                                  row: i,
                                                  col: j
                                                }
                                              )
                                            "
                                          />
                                          <div class="profile-media">
                                            <div class="category">Password</div>
                                          </div>
                                        </div>
                                        <img
                                          src="https://nandbox.com/wp-content/uploads/2023/05/Password.webp"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SINGLE_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.MULTI_CHOICE ||
                                  getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.TOGGLE_CHOICE
                                "
                              >
                                <div
                                  class="item-container"
                                  style="margin-bottom: -10px;"
                                >
                                  <div class="title-container">
                                    <div class="feature-icon">
                                      <img
                                        src="./assets/img/layout-section-icon.svg"
                                      />
                                    </div>
                                    <div class="item-title">Layout</div>
                                  </div>
                                  <div>
                                    <div class="layout-container">
                                      <div class="seaction">
                                        <div class="layout-action-container">
                                          <div class="img-desc-container">
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.SINGLE_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Card-Cell.webp"
                                            />
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                  menuCat.MULTI_CHOICE &&
                                                !checkForToggle(
                                                  getButtonStyles({
                                                    row: i,
                                                    col: j
                                                  })?.value
                                                )
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Card-Cell-checkbox.webp"
                                            />
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                  menuCat.MULTI_CHOICE &&
                                                checkForToggle(
                                                  getButtonStyles({
                                                    row: i,
                                                    col: j
                                                  })?.value
                                                )
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Card-Cell-tg.webp"
                                            />
                                            <div class="style-container">
                                              <div class="style-title">
                                                Card Cell
                                              </div>
                                              <div class="style-desc">
                                                Enable this feature to use a
                                                card design as your cell's
                                                background.
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            class="class-check-box"
                                            [checked]="
                                              checkCardStyle({
                                                row: i,
                                                col: j
                                              })
                                            "
                                            (change)="
                                              addRemoveCardStyle($event, {
                                                row: i,
                                                col: j
                                              })
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="layout-container"
                                      *ngIf="
                                        !checkForToggle(
                                          getButtonStyles({
                                            row: i,
                                            col: j
                                          })?.value
                                        )
                                      "
                                    >
                                      <div class="seaction">
                                        <div class="layout-action-container">
                                          <div class="img-desc-container">
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.SINGLE_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Divider.webp"
                                            />
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.MULTI_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Divider-checkbox.webp"
                                            />
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.TOGGLE_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Divider-tg.webp"
                                            />
                                            <div class="style-container">
                                              <div class="style-title">
                                                Divider
                                              </div>
                                              <div class="style-desc">
                                                Enable this feature to introduce
                                                a divider between individual
                                                options.
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            class="class-check-box"
                                            [checked]="
                                              checkDividerStyle({
                                                row: i,
                                                col: j
                                              })
                                            "
                                            (change)="
                                              addRemoveDividerStyle($event, {
                                                row: i,
                                                col: j
                                              })
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="layout-container"
                                      style="margin-bottom: 10px;"
                                      *ngIf="
                                        checkForToggle(
                                          getButtonStyles({
                                            row: i,
                                            col: j
                                          })?.value
                                        )
                                      "
                                    >
                                      <div class="seaction">
                                        <div class="layout-action-container">
                                          <div class="img-desc-container">
                                            <img
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Divider-tg.webp"
                                            />
                                            <div class="style-container">
                                              <div class="style-title">
                                                Divider
                                              </div>
                                              <div class="style-desc">
                                                Enable this feature to introduce
                                                a divider between individual
                                                options.
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            class="class-check-box"
                                            [checked]="
                                              checkDividerStyle({
                                                row: i,
                                                col: j
                                              })
                                            "
                                            (change)="
                                              addRemoveDividerStyle($event, {
                                                row: i,
                                                col: j
                                              })
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="layout-container"
                                      style="margin-bottom: 10px;"
                                      *ngIf="
                                        !checkForToggle(
                                          getButtonStyles({
                                            row: i,
                                            col: j
                                          })?.value
                                        )
                                      "
                                    >
                                      <div class="seaction">
                                        <div class="layout-action-container">
                                          <div class="img-desc-container">
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.SINGLE_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Mirrored.webp"
                                            />
                                            <img
                                              *ngIf="
                                                getButtonForm({
                                                  row: i,
                                                  col: j
                                                })?.value ===
                                                menuCat.MULTI_CHOICE
                                              "
                                              class="img-desc"
                                              src="https://nandbox.com/wp-content/uploads/2023/06/Mirrored-checkbox.webp"
                                            />

                                            <div class="style-container">
                                              <div class="style-title">
                                                Mirrored
                                              </div>
                                              <div class="style-desc">
                                                Enable this feature to relocate
                                                the radio buttons from the
                                                conventional right position to
                                                the left.
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            type="checkbox"
                                            class="class-check-box"
                                            [checked]="
                                              checkMirrorStyle({
                                                row: i,
                                                col: j
                                              })
                                            "
                                            (change)="
                                              addRemoveMirroStyle($event, {
                                                row: i,
                                                col: j
                                              })
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="body first"
                                *ngIf="
                                  getButtonForm({ row: i, col: j })?.value ===
                                  menuCat.SUBMIT
                                "
                              >
                                <div class="action-type-container">
                                  <span>Action type</span>

                                  <select
                                    formControlName="button_type"
                                    (change)="
                                      updatedButtonActionType(
                                        col.value,
                                        $event,
                                        {
                                          row: i,
                                          col: j
                                        }
                                      )
                                    "
                                    disabled
                                  >
                                    <option
                                      *ngFor="let typ of submitTypes"
                                      [value]="typ"
                                      [selected]="true"
                                      >submit-all</option
                                    >
                                    <!-- <option
                                  *ngFor="let typ of submitTypes"
                                  [value]="typ"
                                  >{{ typ }}</option
                                > -->
                                  </select>
                                </div>
                              </div>
                            </div>
                          </mat-tab>
                          <mat-tab
                            *ngIf="
                              getButtonForm({ row: i, col: j })?.value !==
                                menuCat.SEPARATOR &&
                              (startOver || store) &&
                              getButtonForm({ row: i, col: j })?.value !==
                                menuCat.SINGLE_CHOICE &&
                              getButtonForm({ row: i, col: j })?.value !==
                                menuCat.SELECT_CHOICE &&
                              getButtonForm({ row: i, col: j })?.value !==
                                menuCat.MULTI_CHOICE
                            "
                          >
                            <ng-template mat-tab-label>
                              On Click
                            </ng-template>
                            <div class="item-container">
                              <div class="note">
                                <img src="./assets/img/info-i.png" />
                                <div class="note-data">
                                  Set this button to do one of the following
                                  actions: open a module installed by drag and
                                  drop, open a specified link in the URL field,
                                  or open a screen you previously created.
                                </div>
                              </div>
                              <div class="seaction-container">
                                <div
                                  class="action-main-container-new-screen"
                                  *ngIf="
                                    getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.COLLECTION
                                  "
                                >
                                  <div class="action-seaction">
                                    <div class="section-data">
                                      <div class="section-icon">
                                        <img
                                          src="./assets/img/new-screen-action.svg"
                                        />
                                      </div>
                                      <div class="data-container">
                                        <div class="section-title">
                                          New Screen
                                        </div>
                                        <div class="section-desc">
                                          Open next action in New Screen
                                        </div>
                                      </div>
                                    </div>

                                    <div class="action-container">
                                      <input
                                        type="checkbox"
                                        formControlName="button_newpage"
                                        [checked]="
                                          getButtonView({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                        (change)="
                                          setButtonNewPage($event, {
                                            row: i,
                                            col: j
                                          })
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="action-main-container-new-screen"
                                  *ngIf="
                                    getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.SUBMIT
                                  "
                                >
                                  <div class="action-seaction">
                                    <div class="section-data">
                                      <div class="section-icon">
                                        <img
                                          src="./assets/img/memory-cache.svg"
                                        />
                                      </div>
                                      <div class="data-container">
                                        <div class="section-title">
                                          Menu Cache
                                        </div>
                                        <div class="section-desc">
                                          Enable menu caching.
                                        </div>
                                      </div>
                                    </div>

                                    <div class="action-container">
                                      <input
                                        type="checkbox"
                                        formControlName="button_db"
                                        [checked]="
                                          getButtonDB({
                                            row: i,
                                            col: j
                                          })?.value
                                        "
                                        (change)="
                                          setButtonDB($event, {
                                            row: i,
                                            col: j
                                          })
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="action-main-container-new-screen"
                                  *ngIf="
                                    getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.COLLECTION
                                  "
                                >
                                  <div
                                    class="action-seaction"
                                    style="margin-bottom: 25px;"
                                  >
                                    <div class="section-data">
                                      <div class="section-icon">
                                        <img src="./assets/img/access.svg" />
                                      </div>
                                      <div class="data-container">
                                        <div class="section-title">
                                          Access
                                        </div>
                                        <div class="section-desc">
                                          Select who can access
                                        </div>
                                      </div>
                                    </div>
                                    <div class="action-container">
                                      <select
                                        class="onclick-select"
                                        formControlName="button_access"
                                        #access
                                        (change)="
                                          updatedButtonAccess(
                                            col.value,
                                            access.value,
                                            {
                                              row: i,
                                              col: j
                                            }
                                          )
                                        "
                                      >
                                        <ng-container
                                          *ngFor="let typ of accessTypes"
                                        >
                                          <option [value]="typ?.id">{{
                                            typ?.value
                                          }}</option>
                                        </ng-container>
                                        <!-- <option
                                  *ngFor="let typ of submitTypes"
                                  [value]="typ"
                                  >{{ typ }}</option
                                > -->
                                      </select>
                                    </div>
                                  </div>
                                  <div class="action-seaction">
                                    <div class="section-data">
                                      <div class="section-icon">
                                        <img
                                          src="./assets/img/registration.svg"
                                        />
                                      </div>
                                      <div class="data-container">
                                        <div class="section-title">
                                          Required Registration
                                        </div>
                                        <div class="section-desc">
                                          Select the type of required
                                          registration
                                        </div>
                                      </div>
                                    </div>
                                    <div class="action-container">
                                      <select
                                        class="onclick-select"
                                        formControlName="button_login"
                                        #login
                                        (change)="
                                          updatedButtonLogin(
                                            col.value,
                                            login.value,
                                            {
                                              row: i,
                                              col: j
                                            }
                                          )
                                        "
                                      >
                                        <ng-container
                                          *ngFor="let typ of loginTypes"
                                        >
                                          <option [value]="typ?.id">{{
                                            typ?.value
                                          }}</option>
                                        </ng-container>
                                        <!-- <option
                                    *ngFor="let typ of submitTypes"
                                    [value]="typ"
                                    >{{ typ }}</option
                                  > -->
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="actions-container"
                                  *ngIf="
                                    getButtonForm({ row: i, col: j })?.value !==
                                    menuCat.COLLECTION
                                  "
                                >
                                  <div
                                    class="action-main-container"
                                    [class.active]="
                                      (getButtonNextMenu({
                                        row: i,
                                        col: j
                                      })?.value &&
                                        !(
                                          appManagementService?.menuOnclickAction$
                                          | async
                                        )) ||
                                      (appManagementService?.menuOnclickAction$
                                        | async) === actionTypes?.NEXT_SCREEN
                                    "
                                  >
                                    <div class="action-seaction">
                                      <div class="section-data">
                                        <div class="section-icon">
                                          <img
                                            *ngIf="
                                              (getButtonNextMenu({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                )) ||
                                              (appManagementService?.menuOnclickAction$
                                                | async) ===
                                                actionTypes?.NEXT_SCREEN
                                            "
                                            src="./assets/img/selected-next-screen.svg"
                                          />
                                          <img
                                            *ngIf="
                                              (!getButtonNextMenu({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                ) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.NEXT_SCREEN) ||
                                              ((appManagementService?.menuOnclickAction$
                                                | async) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.NEXT_SCREEN)
                                            "
                                            src="./assets/img/next-screen-section.svg"
                                          />
                                        </div>
                                        <div class="data-container">
                                          <div class="section-title">
                                            Next Screen
                                          </div>
                                          <div class="section-desc">
                                            By clicking this button, Open a
                                            screen you previously created.
                                          </div>
                                        </div>
                                      </div>
                                      <div class="action-container">
                                        <input
                                          type="radio"
                                          formControlName="button_keyboard"
                                          [value]="keyboardTypes.STANDARD"
                                          [checked]="
                                            (getButtonNextMenu({
                                              row: i,
                                              col: j
                                            })?.value &&
                                              !(
                                                appManagementService?.menuOnclickAction$
                                                | async
                                              )) ||
                                            (appManagementService?.menuOnclickAction$
                                              | async) ===
                                              actionTypes?.NEXT_SCREEN
                                          "
                                          (change)="
                                            selectAction(
                                              actionTypes?.NEXT_SCREEN
                                            )
                                          "
                                        />
                                      </div>
                                    </div>

                                    <div
                                      class="next-menu-section"
                                      *ngIf="
                                        (getButtonNextMenu({ row: i, col: j })
                                          ?.value &&
                                          !(
                                            appManagementService?.menuOnclickAction$
                                            | async
                                          )) ||
                                        (appManagementService?.menuOnclickAction$
                                          | async) === actionTypes?.NEXT_SCREEN
                                      "
                                    >
                                      <div
                                        class="screen-container"
                                        *ngIf="
                                          !getButtonNextMenu({ row: i, col: j })
                                            ?.value
                                        "
                                        (click)="
                                          showMenuScreens(
                                            true,
                                            { row: i, col: j },
                                            col
                                          )
                                        "
                                      >
                                        <div class="screen">
                                          <img
                                            src="./assets/img/add-screen.svg"
                                          />
                                          <div class="screen-desc">
                                            Select Screen
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="screen-container"
                                        *ngIf="
                                          getButtonNextMenu({
                                            row: i,
                                            col: j
                                          })?.value &&
                                          getButtonNextMenu({
                                            row: i,
                                            col: j
                                          })?.value as menu_id
                                        "
                                        (click)="
                                          showMenuScreens(
                                            true,
                                            { row: i, col: j },
                                            col
                                          )
                                        "
                                      >
                                        <app-main-menu-view
                                          *ngIf="
                                            !(
                                              appManagementService.effectiveSelectedItem$
                                              | async
                                            )?.sub_type &&
                                            !(
                                              appManagementService.effectiveSelectedTab$
                                              | async
                                            )?.sub_type &&
                                            section !==
                                              uiSection?.MOBILESTORE &&
                                            !(
                                              appManagementService.selectedMenuButtonTab$
                                              | async
                                            )
                                          "
                                          [menuForm]="getMenu(menu_id)[0]"
                                          [appMenus]="
                                            (
                                              appManagementService?.menusAppClass$
                                              | async
                                            )?.menus
                                          "
                                        ></app-main-menu-view>

                                        <app-main-menu-view
                                          *ngIf="
                                            (
                                              appManagementService.effectiveSelectedItem$
                                              | async
                                            )?.sub_type ||
                                            (
                                              appManagementService.effectiveSelectedTab$
                                              | async
                                            )?.sub_type ||
                                            section ===
                                              uiSection?.MOBILESTORE ||
                                            (appManagementService.selectedMenuButtonTab$
                                              | async)
                                          "
                                          [menuForm]="getStoreMenu(menu_id)[0]"
                                          [appMenus]="
                                            (
                                              appManagementService?.menusAppClass$
                                              | async
                                            )?.menus
                                          "
                                        ></app-main-menu-view>
                                      </div>

                                      <div
                                        class="next-menu-cnt"
                                        *ngIf="
                                          getButtonNextMenu({
                                            row: i,
                                            col: j
                                          })?.value &&
                                          getButtonNextMenu({
                                            row: i,
                                            col: j
                                          })?.value as menu_id
                                        "
                                      >
                                        <div class="menu-title">
                                          {{ menu_id }}
                                        </div>
                                        <div class="next-screen-cnt">
                                          <div
                                            class="replace"
                                            (click)="
                                              showMenuScreens(
                                                true,
                                                { row: i, col: j },
                                                col
                                              )
                                            "
                                          >
                                            Replace
                                          </div>
                                          <div
                                            class="delete"
                                            (click)="
                                              deleteNextMenu({
                                                row: i,
                                                col: j
                                              })
                                            "
                                          >
                                            Delete
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="action-main-container"
                                    [class.active]="
                                      (getButtonUrl({
                                        row: i,
                                        col: j
                                      })?.value &&
                                        !(
                                          appManagementService?.menuOnclickAction$
                                          | async
                                        )) ||
                                      (appManagementService?.menuOnclickAction$
                                        | async) === actionTypes?.URL
                                    "
                                  >
                                    <div class="action-seaction">
                                      <div class="section-data">
                                        <div class="section-icon">
                                          <img
                                            *ngIf="
                                              (!getButtonUrl({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                ) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.URL) ||
                                              ((appManagementService?.menuOnclickAction$
                                                | async) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !== actionTypes?.URL)
                                            "
                                            src="./assets/img/url-section.svg"
                                          />
                                          <img
                                            *ngIf="
                                              (getButtonUrl({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                )) ||
                                              (appManagementService?.menuOnclickAction$
                                                | async) === actionTypes?.URL
                                            "
                                            src="./assets/img/selected-url-section.svg"
                                          />
                                        </div>
                                        <div class="data-container">
                                          <div class="section-title">
                                            URL
                                          </div>
                                          <div class="section-desc">
                                            By clicking this button, open a
                                            specified link.
                                          </div>
                                        </div>
                                      </div>
                                      <div class="action-container">
                                        <input
                                          type="radio"
                                          formControlName="button_keyboard"
                                          [checked]="
                                            (getButtonUrl({
                                              row: i,
                                              col: j
                                            })?.value &&
                                              !(
                                                appManagementService?.menuOnclickAction$
                                                | async
                                              )) ||
                                            (appManagementService?.menuOnclickAction$
                                              | async) === actionTypes?.URL
                                          "
                                          (change)="
                                            selectAction(actionTypes?.URL)
                                          "
                                        />
                                      </div>
                                    </div>
                                    <div
                                      class="url-section"
                                      *ngIf="
                                        (getButtonUrl({
                                          row: i,
                                          col: j
                                        })?.value &&
                                          !(
                                            appManagementService?.menuOnclickAction$
                                            | async
                                          )) ||
                                        (appManagementService?.menuOnclickAction$
                                          | async) === actionTypes?.URL
                                      "
                                    >
                                      <span>URL</span>

                                      <input
                                        type="text"
                                        class="form-control"
                                        formControlName="button_url"
                                        placeholder="Enter URL"
                                        (change)="
                                          updatedButtonURL(
                                            col.value,
                                            $event.target.value,
                                            { row: i, col: j },
                                            col
                                          )
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="
                                      section !== uiSection.MOBILESTORE &&
                                      !(
                                        appManagementService?.selectedMenuButtonStore$
                                        | async
                                      ) &&
                                      !selectedTab?.sub_type
                                    "
                                    class="action-main-container"
                                    [class.active]="
                                      (getTabContainer(col)?.type &&
                                        !(
                                          appManagementService?.menuOnclickAction$
                                          | async
                                        )) ||
                                      (appManagementService?.menuOnclickAction$
                                        | async) === actionTypes?.MODULE
                                    "
                                  >
                                    <div class="action-seaction">
                                      <div class="section-data">
                                        <div class="section-icon">
                                          <img
                                            *ngIf="
                                              (!getTabContainer(col)?.type &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                ) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.MODULE) ||
                                              ((appManagementService?.menuOnclickAction$
                                                | async) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.MODULE)
                                            "
                                            src="./assets/img/action-module-section.svg"
                                          />
                                          <img
                                            *ngIf="
                                              (getTabContainer(col)?.type &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                )) ||
                                              (appManagementService?.menuOnclickAction$
                                                | async) === actionTypes?.MODULE
                                            "
                                            src="./assets/img/selected-module.svg"
                                          />
                                        </div>
                                        <div class="data-container">
                                          <div class="section-title">
                                            Attach Module
                                          </div>
                                          <div class="section-desc">
                                            By clicking this button, open a
                                            module installed by drag and drop.
                                          </div>
                                        </div>
                                      </div>
                                      <div class="action-container">
                                        <input
                                          type="radio"
                                          formControlName="button_keyboard"
                                          [value]="keyboardTypes.PHONE"
                                          [checked]="
                                            (getTabContainer(col)?.type &&
                                              !(
                                                appManagementService?.menuOnclickAction$
                                                | async
                                              )) ||
                                            (appManagementService?.menuOnclickAction$
                                              | async) === actionTypes?.MODULE
                                          "
                                          (change)="
                                            selectAction(actionTypes?.MODULE)
                                          "
                                        />
                                      </div>
                                    </div>
                                    <div
                                      *ngIf="
                                        ((getTabContainer(col)?.type &&
                                          !(
                                            appManagementService?.menuOnclickAction$
                                            | async
                                          )) ||
                                          (appManagementService?.menuOnclickAction$
                                            | async) === actionTypes?.MODULE) &&
                                        (!getTabContainer(col)?.type &&
                                          tabsList)
                                      "
                                      style="width: 100%;
                                    margin-top: 10px;"
                                      (drop)="
                                        onDrop($event, col, { row: i, col: j })
                                      "
                                    >
                                      <div
                                        class="drag-box"
                                        appCancelActionIfNotHasPrivilege
                                        [privilege]="
                                          (
                                            appManagementService?.privilegModule$
                                            | async
                                          )?.privilege
                                        "
                                        [moduleID]="
                                          (
                                            appManagementService?.privilegModule$
                                            | async
                                          )?.id
                                        "
                                      >
                                        <img src="./assets/img/drag.svg" />
                                        <div class="drag-title">
                                          Drop items here
                                        </div>
                                        <div class="drag-subtitle">
                                          <div class="drag-subtitle">
                                            <span
                                              >Drag and drop what suits you from
                                              the elements of the form</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      *ngIf="
                                        ((getTabContainer(col)?.type &&
                                          !(
                                            appManagementService?.menuOnclickAction$
                                            | async
                                          )) ||
                                          (appManagementService?.menuOnclickAction$
                                            | async) === actionTypes?.MODULE) &&
                                        (getTabContainer(col)?.type ||
                                          getTabContainer(col)?.link)
                                      "
                                      class="container-Item"
                                      [class.side-item]="ture"
                                    >
                                      <div class="custom-row-item">
                                        <div class="tabs-data">
                                          <div class="tab-icon">
                                            <svg
                                              class="tab-icon-svg"
                                              *ngIf="
                                                getTabContainer(col)?.type !==
                                                tabTypes?.PAGE
                                              "
                                            >
                                              <use
                                                [attr.xlink:href]="
                                                  './assets/svg/app-features-icons.svg#' +
                                                  getFeatureModule(
                                                    getTabContainer(col)
                                                      ?.ui_module_id
                                                  )?.icon
                                                "
                                              ></use>
                                            </svg>
                                            <svg
                                              class="tab-icon-svg"
                                              *ngIf="
                                                getTabContainer(col)?.type ===
                                                tabTypes?.PAGE
                                              "
                                            >
                                              <use
                                                [attr.xlink:href]="
                                                  './assets/svg/app-icons.svg#' +
                                                  getTabContainer(col)?.icon
                                                "
                                              ></use>
                                            </svg>
                                          </div>
                                          <div class="feature-data">
                                            <div
                                              class="tab-title"
                                              *ngIf="
                                                getTabContainer(col)?.type !==
                                                tabTypes?.PAGE
                                              "
                                            >
                                              {{
                                                getFeatureModule(
                                                  getTabContainer(col)
                                                    ?.ui_module_id
                                                )?.title
                                              }}
                                            </div>
                                            <div
                                              class="tab-title"
                                              *ngIf="
                                                getTabContainer(col)?.type ===
                                                tabTypes?.PAGE
                                              "
                                            >
                                              {{
                                                getTabContainer(col)?.title ||
                                                  getTabContainer(col)?.name
                                              }}
                                            </div>
                                            <div
                                              class="tab-desc"
                                              *ngIf="
                                                getTabContainer(col)?.type !==
                                                  tabTypes?.SETTINGS &&
                                                getTabContainer(col)?.type !==
                                                  tabTypes?.PAGE
                                              "
                                            >
                                              {{
                                                getFeatureCategory?.getModuleCategory(
                                                  getTabContainer(col)
                                                    ?.ui_module_id
                                                )
                                              }}
                                              Category
                                            </div>
                                            <div
                                              class="tab-desc"
                                              *ngIf="
                                                getTabContainer(col)?.type ===
                                                tabTypes?.PAGE
                                              "
                                            >
                                              Pages Category
                                            </div>
                                          </div>
                                        </div>

                                        <div class="con-container">
                                          <div
                                            class="features-controlles"
                                            *ngIf="showSetting"
                                            [class.active]="
                                              getTabContainer(col)?.id ==
                                                10008 ||
                                              getTabContainer(col)?.id ==
                                                10009 ||
                                              getTabContainer(col)?.id ==
                                                10010 ||
                                              getTabContainer(col)?.id == 10011
                                            "
                                          >
                                            <div
                                              class="img-container"
                                              *ngIf="
                                                getTabContainer(col)?.id !==
                                                  10008 &&
                                                getTabContainer(col)?.id !==
                                                  10009 &&
                                                getTabContainer(col)?.id !==
                                                  10010 &&
                                                getTabContainer(col)?.id !==
                                                  10011
                                              "
                                            >
                                              <img
                                                src="./assets/img/delete-item.svg"
                                                (click)="
                                                  deleteContainer(
                                                    getTabContainer(col)
                                                  )
                                                "
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="data">
                                        <div class="title">
                                          <label>
                                            Title
                                          </label>
                                          <input
                                            *ngIf="
                                              getTabContainer(col)?.title ||
                                              getTabContainer(col)?.name
                                            "
                                            [value]="
                                              getTabContainer(col)?.title ||
                                              getTabContainer(col)?.name
                                            "
                                            type="text"
                                            maxlength="35"
                                            placeholder="Title"
                                            (change)="
                                              updatedButtonTabTitle(
                                                col.value,
                                                $event.target.value
                                              )
                                            "
                                          />
                                          <input
                                            *ngIf="
                                              getTabContainer(col)?.title &&
                                              getTabContainer(col)?.name
                                            "
                                            [value]="''"
                                            type="text"
                                            maxlength="35"
                                            placeholder="Title"
                                            (change)="
                                              updatedButtonTabTitle(
                                                col.value,
                                                $event.target.value
                                              )
                                            "
                                          />
                                        </div>
                                        <div class="icons">
                                          <app-menus-icons-conf
                                            [tabcontainer]="
                                              getTabContainer(col)
                                            "
                                          ></app-menus-icons-conf>
                                        </div>
                                      </div>
                                      <div class="footer">
                                        <div
                                          class="feature-count-simpl"
                                          *ngIf="
                                            getTabContainer(col)?.type !==
                                              'page' &&
                                            getFeatureModule(
                                              getTabContainer(col)?.ui_module_id
                                            )?.features?.length >= 1 &&
                                            getTabContainer(col)?.TYPE !==
                                              tabTypes?.MENUS &&
                                            getTabContainer(col)?.link !==
                                              tabTypes?.MENUS &&
                                            getTabContainer(col)?.type !==
                                              tabTypes?.PAGE
                                          "
                                        >
                                          <div class="count-no">
                                            {{
                                              getFeatureModule(
                                                getTabContainer(col)
                                                  ?.ui_module_id
                                              )?.features?.length
                                            }}/{{
                                              getFeatureModule(
                                                getTabContainer(col)
                                                  ?.ui_module_id
                                              )?.features?.length
                                            }}
                                          </div>
                                          <div>Features included</div>
                                        </div>
                                        <div
                                          class="feature-count-simpl"
                                          *ngIf="
                                            getFeatureModule(
                                              getTabContainer(col)?.ui_module_id
                                            )?.features?.length <= 0
                                          "
                                        >
                                          <div class="count-no">1/1</div>
                                          <div>Features included</div>
                                        </div>
                                        <div
                                          class="config-container"
                                          (click)="
                                            showSetting(getTabContainer(col))
                                          "
                                          *ngIf="
                                            getTabContainer(col)?.link !==
                                              tabTypes?.SETTINGS &&
                                            getTabContainer(col)?.type !==
                                              tabTypes?.SETTINGS
                                          "
                                        >
                                          <img
                                            src="./assets/img/config-settings.svg"
                                          />
                                          <div class="config-title">
                                            Configure
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="actions-container"
                                  *ngIf="
                                    getButtonForm({ row: i, col: j })?.value ===
                                    menuCat.COLLECTION
                                  "
                                >
                                  <div
                                    class="action-main-container"
                                    [class.active]="true"
                                  >
                                    <div class="action-seaction">
                                      <div class="section-data">
                                        <div class="section-icon">
                                          <img
                                            *ngIf="
                                              (getButtonNextMenu({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                )) ||
                                              (appManagementService?.menuOnclickAction$
                                                | async) ===
                                                actionTypes?.NEXT_SCREEN
                                            "
                                            src="./assets/img/selected-next-screen.svg"
                                          />
                                          <img
                                            *ngIf="
                                              (!getButtonNextMenu({
                                                row: i,
                                                col: j
                                              })?.value &&
                                                !(
                                                  appManagementService?.menuOnclickAction$
                                                  | async
                                                ) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.NEXT_SCREEN) ||
                                              ((appManagementService?.menuOnclickAction$
                                                | async) &&
                                                (appManagementService?.menuOnclickAction$
                                                  | async) !==
                                                  actionTypes?.NEXT_SCREEN)
                                            "
                                            src="./assets/img/next-screen-section.svg"
                                          />
                                        </div>
                                        <div class="data-container">
                                          <div class="section-title">
                                            Collection Id
                                          </div>
                                          <div class="section-desc">
                                            By clicking this button, Open a
                                            screen you previously created.
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="next-menu-section"
                                      style="flex-direction: column;"
                                    >
                                      <div
                                        style="display: flex;     align-items: center;"
                                      >
                                        <div
                                          class="screen-container"
                                          *ngIf="
                                            !getButtonNextPage({
                                              row: i,
                                              col: j
                                            })?.value?.id
                                          "
                                          (click)="
                                            showMenuCollections(
                                              true,
                                              { row: i, col: j },
                                              col
                                            )
                                          "
                                        >
                                          <div class="screen">
                                            <img
                                              src="./assets/img/add-screen.svg"
                                            />
                                            <div class="screen-desc">
                                              Select Collection id
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="screen-container"
                                          *ngIf="
                                            getButtonNextPage({
                                              row: i,
                                              col: j
                                            })?.value?.id as id
                                          "
                                          (click)="
                                            showMenuCollections(
                                              true,
                                              { row: i, col: j },
                                              col
                                            )
                                          "
                                        >
                                          <div
                                            class="screen"
                                            *ngIf="
                                              selectedButton &&
                                              selectedButton.row === i &&
                                              selectedButton.col === j
                                            "
                                          >
                                            <ng-container
                                              *ngIf="getCollection(id)"
                                            ></ng-container>
                                            <img
                                              style="    width: 100%;"
                                              [src]="
                                                ((
                                                  collectionService?.getSelectedCollectionByID$(
                                                    id
                                                  ) | async
                                                )?.image)[0]?.url
                                              "
                                            />
                                          </div>
                                        </div>

                                        <div
                                          class="next-menu-cnt"
                                          *ngIf="
                                            getButtonNextPage({
                                              row: i,
                                              col: j
                                            })?.value
                                          "
                                        >
                                          <div class="menu-title">
                                            {{
                                              getButtonNextPage({
                                                row: i,
                                                col: j
                                              })?.value?.id
                                            }}
                                          </div>
                                          <div class="next-screen-cnt">
                                            <div
                                              class="replace"
                                              (click)="
                                                showMenuCollections(
                                                  true,
                                                  { row: i, col: j },
                                                  col
                                                )
                                              "
                                            >
                                              Replace
                                            </div>
                                            <div
                                              class="delete"
                                              (click)="
                                                deleteNextPage({
                                                  row: i,
                                                  col: j
                                                })
                                              "
                                            >
                                              Delete
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <!-- <div
                                        style="display:flex; margin-top: 5px; align-items: center;"
                                      >
                                        <div
                                          class="soft-title"
                                          style="margin-right: 10px;"
                                        >
                                          Soft Id :
                                        </div>
                                        <input
                                          class="soft-input"
                                          disabled
                                          #softId
                                          [value]="
                                            getButtonNextPage({
                                              row: i,
                                              col: j
                                            })?.value?.soft_id
                                          "
                                          (change)="
                                            updatedButtonNextPageSoftId(
                                              softId?.value,
                                              {
                                                row: i,
                                                col: j
                                              }
                                            )
                                          "
                                        />
                                      </div> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="controller" [hidden]="startOver || store">
        <div class="form-submit" *ngIf="showBottonConfig">
          <app-border-btn
            back
            (btnClicked)="resetSelectedBtn()"
            [disabled]="checkUpload()"
          >
            Back
          </app-border-btn>
        </div>
        <app-border-btn
          [disabled]="check()"
          publish
          (btnClicked)="onSubmit()"
          [type]="'submit'"
          *ngIf="!selectedButton && (startOver || store)"
        >
          Save Menu
        </app-border-btn>
        <ng-container *appShowIfHasPrivilege="privilegesName.editmenu">
          <app-border-btn
            [disabled]="check()"
            publish
            (btnClicked)="onSubmit()"
            [type]="'submit'"
            *ngIf="!selectedButton && !startOver && !store"
          >
            Save Poll
          </app-border-btn>
        </ng-container>
        <app-border-btn
          [disabled]="check()"
          submit
          (btnClicked)="goNext()"
          [type]="'submit'"
          *ngIf="!selectedButton && (startOver || store)"
        >
          Next
        </app-border-btn>
      </div>
    </form>
  </div>
</div>
<div
  *ngIf="!isProduction && enableSetTemplateBtn && !selectedButton && !startOver"
  style="font-size: 2em; font-weight: 500; margin-top: 1em;"
>
  Template Mode
</div>
<app-modal-container
  *ngIf="menusPoolVisibility"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="toggleMenusPoolModalVisibility(false)"
>
  <app-menus-pool
    (cancelNotfiy)="toggleMenusPoolModalVisibility(false)"
    (selectMenuNotfiy)="setMenuResponse($event)"
  >
  </app-menus-pool>
</app-modal-container>
<app-modal-container
  *ngIf="showRows"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="showRowsSelector()"
>
  <app-menus-rows
    (styleSelectedNotify)="addNewRow($event)"
    (cancelNotify)="showRowsSelector()"
    [isApp]="startOver || store"
  ></app-menus-rows>
</app-modal-container>
<app-modal-container
  *ngIf="showAddButton"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="showaddButtonSelector()"
>
  <app-menus-rows
    [button]="true"
    (styleSelectedNotify)="addNewButtonInRow($event)"
    (cancelNotify)="showaddButtonSelector()"
    [length]="buttonsLength"
    [isApp]="startOver || store"
  ></app-menus-rows>
</app-modal-container>
<app-modal-container
  *ngIf="startOverFirstValidation"
  [count]="layoutService?.appModelOpenStatus$ | async"
>
  <app-popup-notifier
    [title]="'Save Menu'"
    [menus]="true"
    [showCancel]="false"
    [validationMsg]="true"
    [message]="'Do you want to save the current menu?'"
    [cancelTitle]="'No'"
    [validationTitle]="'Yes'"
    (agreePopupNotify)="saveMenu(true)"
    (hidePopupNotify)="saveMenu(false)"
    (closePopupNotify)="closeAddScreen()"
  >
  </app-popup-notifier>
</app-modal-container>

<app-modal-container
  *ngIf="appManagementService.showMenuButtonValidation$ | async"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="hideValidation()"
>
  <app-popup-notifier
    [title]="'Button incompatible with cell'"
    [validationMsg]="false"
    [validationTitle]="'OK'"
    [message]="
      'This button is incompatible with this cell, please choose a different button.'
    "
    (hidePopupNotify)="hideValidation()"
  >
  </app-popup-notifier>
</app-modal-container>
<app-modal-container
  *ngIf="showAddImage"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="hideAdd()"
>
  <app-media-center-modal
    (mediaCenterNotify)="hideAdd()"
    (cancelNotify)="hideAdd()"
    (mediaCenterNotify)="updateImage($event)"
    [isPhotoMedia]="'true'"
    [mimeTypes]="'image/*'"
  >
  </app-media-center-modal>
</app-modal-container>
<app-modal-container *ngIf="showOptionImage" (cancelNotfiy)="hideOptionImg()">
  <app-media-center-modal
    (mediaCenterNotify)="hideOptionImg()"
    (cancelNotify)="hideOptionImg()"
    (mediaCenterNotify)="changeButtonOptionImage($event)"
    [isPhotoMedia]="'true'"
    [aspectRatio]="'1 x 1'"
    [mimeTypes]="'image/*'"
  >
  </app-media-center-modal>
</app-modal-container>
<app-modal-container
  *ngIf="showAddMedia"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="hideMedia()"
>
  <app-media-center-modal
    (mediaCenterNotify)="hideMedia()"
    (cancelNotify)="hideMedia()"
    (mediaCenterNotify)="updateImageToButton($event)"
    [isPhotoMedia]="'true'"
    [aspectRatio]="selectedAspectRatio"
    [backgroundColor]="selectedBgColor"
    [mimeTypes]="'image/*'"
  >
  </app-media-center-modal>
</app-modal-container>

<app-modal-container
  *ngIf="showAddBackground"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="hideAddBackgroundImage()"
>
  <app-media-center-modal
    (mediaCenterNotify)="hideAddBackgroundImage()"
    (cancelNotify)="hideAddBackgroundImage()"
    (mediaCenterNotify)="updateBackground($event)"
    [isPhotoMedia]="'true'"
    [mimeTypes]="'image/*'"
  >
  </app-media-center-modal>
</app-modal-container>

<app-modal-container
  *ngIf="showScreens"
  (cancelNotfiy)="showMenuScreens(false)"
>
  <div class="contanier-screens">
    <div class="header">
      <div>Screens</div>
      <img
        src="./assets/img/screens-close.svg"
        (click)="showMenuScreens(false)"
      />
    </div>
    <div
      class="screens-contanier"
      *ngIf="
        (uiService?.uiCollection$ | async)?.section === uiSection?.APP &&
          currentPage === appScreens?.TABS &&
          !(appManagementService?.effectiveSelectedTab$ | async)?.sub_type &&
          !(appManagementService?.selectedMenuButtonTab$ | async) &&
          appManagementService?.effectiveSelectedTab$ | async as cnt
      "
    >
      <div
        class="empty-screen"
        *ngIf="
          (cnt?.sub_menus && cnt?.sub_menus.length <= 0) || !cnt?.sub_menus
        "
      >
        <img src="./assets/img/empty-add-screens.svg" />
      </div>
      <div
        *ngIf="botMenuEdit?.menu_id !== cnt?.menu_id"
        class="menu-screen"
        [class.selected-menu]="
          (appManagementService?.selectedNextMenu$ | async) === cnt?.menu_id
        "
        (click)="selectNextMenu(cnt?.menu_id)"
      >
        <div class="cnt">
          <div class="title">
            {{ cnt?.title }}
          </div>
        </div>

        <div class="post-image" id="cnt?.menu_id">
          <app-main-menu-view
            [menuForm]="getMainMenu(cnt)[0]"
            [appMenus]="(appManagementService?.menusAppClass$ | async)?.menus"
          ></app-main-menu-view>
        </div>
      </div>
      <ng-container *ngFor="let submenu of cnt?.sub_menus">
        <ng-container
          *ngFor="
            let menu of (appManagementService?.menusAppClass$ | async)?.menus
          "
        >
          <ng-container
            *ngIf="
              menu &&
              menu?.menu_id === submenu &&
              menu?.menu_id !== cnt?.menu_id
            "
          >
            <div
              *ngIf="botMenuEdit?.menu_id !== menu?.menu_id"
              class="menu-screen"
              (click)="selectNextMenu(menu?.menu_id)"
              [class.selected-menu]="
                (appManagementService?.selectedNextMenu$ | async) ===
                menu?.menu_id
              "
            >
              <div class="cnt">
                <div class="title">
                  {{ menu?.menu_id }}
                </div>
              </div>
              <div id="submenu" class="post-image" *ngIf="menu?.menu_id">
                <app-main-menu-view
                  [menuForm]="getMenu(menu?.menu_id)[0]"
                  [appMenus]="
                    (appManagementService?.menusAppClass$ | async)?.menus
                  "
                ></app-main-menu-view>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="screens-contanier"
      *ngIf="
        (uiService?.uiCollection$ | async)?.section === uiSection?.APP &&
          currentPage === appScreens?.NAV_MENU &&
          !(appManagementService?.effectiveSelectedItem$ | async)?.sub_type &&
          !(appManagementService?.selectedMenuButtonTab$ | async) &&
          appManagementService?.effectiveSelectedItem$ | async as cnt
      "
    >
      <div
        class="empty-screen"
        *ngIf="
          (cnt?.sub_menus && cnt?.sub_menus.length <= 0) || !cnt?.sub_menus
        "
      >
        <img src="./assets/img/empty-add-screens.svg" />
      </div>
      <div
        *ngIf="botMenuEdit?.menu_id !== cnt?.menu_id"
        class="menu-screen"
        [class.selected-menu]="
          (appManagementService?.selectedNextMenu$ | async) === cnt?.menu_id
        "
        (click)="selectNextMenu(cnt?.menu_id)"
      >
        <div class="cnt">
          <div class="title">
            {{ cnt?.title }}
          </div>
        </div>

        <div class="post-image" id="cnt?.menu_id">
          <app-main-menu-view
            [menuForm]="getMainMenu(cnt)[0]"
            [appMenus]="(appManagementService?.menusAppClass$ | async)?.menus"
          ></app-main-menu-view>
        </div>
      </div>
      <ng-container *ngFor="let submenu of cnt?.sub_menus">
        <ng-container
          *ngFor="
            let menu of (appManagementService?.menusAppClass$ | async)?.menus
          "
        >
          <ng-container
            *ngIf="
              menu &&
              menu?.menu_id === submenu &&
              menu?.menu_id !== cnt?.menu_id
            "
          >
            <div
              *ngIf="botMenuEdit?.menu_id !== menu?.menu_id"
              class="menu-screen"
              (click)="selectNextMenu(menu?.menu_id)"
              [class.selected-menu]="
                (appManagementService?.selectedNextMenu$ | async) ===
                menu?.menu_id
              "
            >
              <div class="cnt">
                <div class="title">
                  {{ menu?.menu_id }}
                </div>
              </div>
              <div id="submenu" class="post-image" *ngIf="menu?.menu_id">
                <app-main-menu-view
                  [menuForm]="getMenu(menu?.menu_id)[0]"
                  [appMenus]="
                    (appManagementService?.menusAppClass$ | async)?.menus
                  "
                ></app-main-menu-view>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="screens-contanier"
      *ngIf="
        (uiService?.uiCollection$ | async)?.section ===
          uiSection?.MOBILESTORE ||
        (appManagementService?.effectiveSelectedItem$ | async)?.sub_type ||
        (appManagementService?.effectiveSelectedTab$ | async)?.sub_type ||
        (appManagementService?.selectedMenuButtonTab$ | async)
      "
    >
      <div
        class="empty-screen"
        *ngIf="
          !(mobileWorkFlowService?.selectedMenuList$ | async) ||
          (mobileWorkFlowService?.selectedMenuList$ | async).length <= 1
        "
      >
        <img src="./assets/img/empty-add-screens.svg" />
      </div>

      <ng-container *ngIf="mobileWorkFlowService?.selectedMenu$ | async as cnt">
        <ng-container
          *ngFor="let menu of mobileWorkFlowService?.selectedMenuList$ | async"
        >
          <ng-container *ngIf="menu && menu?.menu_id !== cnt?.menu_id">
            <div
              *ngIf="botMenuEdit?.menu_id !== menu?.menu_id"
              class="menu-screen"
              (click)="selectNextMenu(menu?.menu_id)"
              [class.selected-menu]="
                (appManagementService?.selectedNextMenu$ | async) ===
                menu?.menu_id
              "
            >
              <div class="cnt">
                <div class="title">
                  {{ menu?.menu_id }}
                </div>
              </div>
              <div id="submenu" class="post-image" *ngIf="menu?.menu_id">
                <app-main-menu-view
                  [menuForm]="getStoreMenu(menu?.menu_id)[0]"
                  [appMenus]="mobileWorkFlowService?.selectedMenuList$ | async"
                ></app-main-menu-view>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-container
      *ngIf="
        !(appManagementService?.selectedNextMenuCol$ | async) ||
        (appManagementService?.selectedNextMenuCol$ | async) as nextMenuCol
      "
    >
      <div
        class="screens-footer"
        *ngIf="
          !(appManagementService?.selectedNextMenuPos$ | async) ||
          (appManagementService?.selectedNextMenuPos$ | async) as nextMenuPos
        "
      >
        <div
          *ngIf="
            !(appManagementService?.selectedNextMenu$ | async) ||
            (appManagementService?.selectedNextMenu$ | async) as menu_id
          "
          class="screens-action"
          [class.not-allowed]="!nextMenuSelected"
          (click)="updatedButtonNextMenu(nextMenuPos, menu_id, nextMenuCol)"
        >
          Add
        </div>
      </div>
    </ng-container>
  </div>
</app-modal-container>

<app-modal-container
  *ngIf="
    showCollections &&
    (appManagementService?.selectedNextMenuPos$ | async) as nextMenuPos
  "
  (cancelNotfiy)="showMenuCollections(false)"
>
  <app-collections-pool
    [isSingle]="true"
    (closeNotify)="showMenuCollections(false)"
    (selectSingleCollectionNotfiy)="
      updatedButtonNextPage($event, nextMenuPos, menu_id)
    "
  ></app-collections-pool>
</app-modal-container>
