import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

import * as MediaCenterActions from './mediaCenter.actions';
import * as MediaCenterMethods from 'src/models/IMediaCenter';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { FileService } from 'src/app/core/file.service';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  GROUP_IMAGE_UPLOAD_TYPE,
  MessageMediaStatus
} from 'src/models/constants';
import { MediaCenterDispatchers } from './mediaCenter.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class MediaCenterEffects {
  uploadSubscriptions: Subscription[] = [];

  @Effect({ dispatch: false })
  getMyGallery = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.GET_MY_MEDIA_GALLERY),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.receveMediaGallary)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mediaGallary]) => {
      const action = <MediaCenterActions.GetMyMediaGallery>val;
      if (!mediaGallary.includes(action.mediaType)) {
        this._socketGateway.sendSocketMessage(
          new MediaCenterMethods.GetMyMediaGallery(action.mediaType)
        );
        this._uiDispatchers.receiveMediaGallary(action.mediaType);
      }
    })
  );

  @Effect({ dispatch: false })
  uploadMediaFile = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.MEDIA_UPLOADING),
    map((action: MediaCenterActions.MediaCenterUploading) => {
      const msg: MediaCenterMethods.IMediaCenter = {};
      this._fileService
        .readFileAsArrayBuffer(action.localFile)
        .then(fileAsArrayBuffer => {
          this._fileService
            .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.fileType)
            .then(blobUrl => {
              msg.loadingProgress = 0;
              msg.reference = new Date().getTime();
              msg.url = blobUrl; // set blob url in file from local
              msg.file = blobUrl; // set file from blobUrl from local
              msg.mediaStatus = MessageMediaStatus.UPLOADING;
              msg.size = action.localFile.size;
              msg.name = action.localFile.name;
              msg.fileAsArrayBuffer = fileAsArrayBuffer;
              msg.localFile = action.localFile;
              msg.type = action.fileType;
              msg.aspectRatio = action.uploadPrams.ar;
              this._mediaCenterDispatchers.mediaCenterUploadingProcessed(
                msg,
                action.uploadPrams
              );
            });
        });
    })
  );

  @Effect({ dispatch: false })
  uploadMediaFileProcessed = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.MEDIA_UPLOADING_PROCESSED),
    map((action: MediaCenterActions.MediaCenterUploadingProcessed) => {
      this.uploadMedia(
        action.payload,
        action.uploadPrams,
        action.payload.fileAsArrayBuffer,
        action.payload.localFile
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMediaCenterItem = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.DELETE_MEDIA_CENTER_ITEM),
    map((action: MediaCenterActions.DeleteMediaCenterItem) => {
      this._socketGateway.sendSocketMessage(
        new MediaCenterMethods.DeleteMyMediaGalleryItem(action.payload)
      );
    })
  );
  constructor(
    private actions$: Actions,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _uiDispatchers: UIDispatchers,
    private _mediaCenterDispatchers: MediaCenterDispatchers
  ) {}

  private uploadMedia(
    msg: MediaCenterMethods.IMediaCenter,
    prams: MediaCenterMethods.MediaCenterPrams,
    fileAsArrayBuffer: ArrayBuffer,
    localFile: File
  ) {
    let progressMonitor = 0;
    this.uploadSubscriptions[
      msg.reference
    ] = this._uploadGateway
      .uploadWithProgress(
        fileAsArrayBuffer,
        localFile.type,
        localFile.name,
        false,
        GROUP_IMAGE_UPLOAD_TYPE,
        prams.tabID,
        prams.groupID,
        true,
        true,
        prams.botID,
        null,
        null,
        prams.hd,
        prams.ar,
        prams.bg
      )
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progressVal = Math.round((event.loaded / event.total) * 100);
            if (progressVal - progressMonitor >= 18 || progressVal === 100) {
              progressMonitor = progressVal;
              this._mediaCenterDispatchers.updateMediaCenterLoadingProgress(
                msg,
                progressVal
              );
            }
          } else if (event.type === HttpEventType.Response) {
            const res = <IUploadResponse>event.body;
            msg.file = res.file; // set file from responce of api
            msg.url = res.media.permanentUrl; // set url from url
            msg.filename = res.file; // set fileName to use it in delete
            msg.loadingProgress = 100;
            msg.width = res.width;
            msg.height = res.height;
            msg.size = res.size;
            msg.permanentUrl = res.media.permanentUrl;
            msg.private_url = res.media.url;
            this._mediaCenterDispatchers.updateMediaCenterStatus(
              msg,
              MessageMediaStatus.UPLOADED,
              res.media.permanentUrl,
              res.file
            );
            delete this.uploadSubscriptions[msg.reference];
          }
        },
        error => {
          console.log('Failed to upload subGroup image');
        }
      );
  }

  cancelUploadMediaFile(msg: MediaCenterMethods.IMediaCenter) {
    if (this.uploadSubscriptions[msg.reference]) {
      this.uploadSubscriptions[msg.reference].unsubscribe();
      this._mediaCenterDispatchers.updateMediaCenterStatus(
        msg,
        MessageMediaStatus.UPLOAD_FAILED,
        null,
        null
      );
      delete this.uploadSubscriptions[msg.reference];
    }
  }
}
