import { Actions } from './adsProvider.actions';
import * as actions from './adsProvider.actions';
import { AdsConfig } from 'src/models/constants';

const INITIAL_STATE: AdsConfig = {};

export function adsProvidersReducer(
  state = INITIAL_STATE,
  action: Actions
): AdsConfig {
  switch (action.type) {
    case actions.AdsProvidersActionTypes.RECEIVE_ADS_PROVIDER: {
      return action;
    }

    case actions.AdsProvidersActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
