/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circle-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./circle-btn.component";
var styles_CircleBtnComponent = [i0.styles];
var RenderType_CircleBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CircleBtnComponent, data: {} });
export { RenderType_CircleBtnComponent as RenderType_CircleBtnComponent };
export function View_CircleBtnComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "app-btn"]], [[8, "disabled", 0], [8, "type", 0], [2, "active", null]], [[null, "click"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (_co.mouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.mouseleave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.type; var currVal_2 = _co.frame; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CircleBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-circle-btn", [], null, null, null, View_CircleBtnComponent_0, RenderType_CircleBtnComponent)), i1.ɵdid(1, 49152, null, 0, i2.CircleBtnComponent, [], null, null)], null, null); }
var CircleBtnComponentNgFactory = i1.ɵccf("app-circle-btn", i2.CircleBtnComponent, View_CircleBtnComponent_Host_0, { disabled: "disabled", type: "type", frame: "frame" }, { btnClicked: "btnClicked", mouseLeaveNotify: "mouseLeaveNotify", mouseOverNotify: "mouseOverNotify" }, ["*"]);
export { CircleBtnComponentNgFactory as CircleBtnComponentNgFactory };
