import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PaymentOrdersUIActions from './paymentOrdersUI.actions';

import { AppState } from 'src/models/AppState';
import { PaymentOrder } from 'src/models/PaymentGateways';

@Injectable({
  providedIn: 'root'
})
export class PaymentOrdersUiDispatchers {
  constructor(private _store: Store<AppState>) {}
  // paging dispatchers
  getNextPaymentOrdersPage(paymentOrderID?: string): void {
    this._store.dispatch(
      new PaymentOrdersUIActions.GetNextPaymentOrdersPage(paymentOrderID)
    );
  }

  getPrevPaymentOrdersPage(paymentOrderID?: string): void {
    this._store.dispatch(
      new PaymentOrdersUIActions.GetPrevPaymentOrdersPage(paymentOrderID)
    );
  }

  receivedPaymentOrdersHistoryChunk(
    eop: number,
    sop: number,
    currentPage: PaymentOrder[]
  ): void {
    this._store.dispatch(
      new PaymentOrdersUIActions.ReceivedPaymentOrdersHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  paymentOrdersUsersReceived(userNames: object) {
    this._store.dispatch(
      new PaymentOrdersUIActions.PaymentOrdersUsersReceived(userNames)
    );
  }
  paymentStripeConnectSubChannel() {
    this._store.dispatch(new PaymentOrdersUIActions.CreateStripeSubChannel());
  }
  paymentStripeResetSubChannel(provider_id: string) {
    this._store.dispatch(
      new PaymentOrdersUIActions.ResetStripeSubChannel(provider_id)
    );
  }
  paymentOrdersGroupsReceived(groupsNames: object) {
    this._store.dispatch(
      new PaymentOrdersUIActions.PaymentOrdersGroupsReceived(groupsNames)
    );
  }

  resetPaymentOrdersPaging(paymentOrderID?: string): void {
    this._store.dispatch(
      new PaymentOrdersUIActions.ResetPaymentOrdersPaging(paymentOrderID)
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new PaymentOrdersUIActions.SetStatusAsIdle());
  }
}
