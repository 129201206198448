import { Store } from '@ngrx/store';
import * as ChatActions from './chat.actions';
import { ChatMiddleware } from './chat.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
// import { IChat } from 'src/models/IChat';
export class ChatDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    chatDetailsReceived(payload) {
        const chat = ChatMiddleware.initChat(payload.chat);
        this._store.dispatch(new ChatActions.ChatDetailsReceived(chat));
    }
    chatDetailsFromCache(payload) {
        this._store.dispatch(new ChatActions.ChatDetailsFromCache(payload));
    }
    thumbnailDownloaded(payload) {
        this._store.dispatch(new ChatActions.ThumbnailDownloaded(payload));
    }
    chatImageDownloaded(payload) {
        this._store.dispatch(new ChatActions.ChatImageDownloaded(payload));
    }
    chatListReceived(payload) {
        this._store.dispatch(new ChatActions.ChatListReceived(payload.chat_list));
    }
    subChatListReceived(payload, listType) {
        this._store.dispatch(new ChatActions.SubChatListReceived(payload.chat_list, listType));
    }
    chatSelected(payload) {
        this._store.dispatch(new ChatActions.ChatSelected(payload));
    }
    chatInvalidated(payload) {
        this._store.dispatch(new ChatActions.ChatInvalidated(payload));
    }
    receiveGenratedChatID(id) {
        this._store.dispatch(new ChatActions.ReceiveChatID(id));
    }
    deselectChat(id, lastTypedMessage) {
        this._store.dispatch(new ChatActions.DeselectChat(id, lastTypedMessage));
    }
    requestChatCounters(chatID, messageIDs) {
        this._store.dispatch(new ChatActions.RequestChatCounters(chatID, messageIDs));
    }
    requestChatHistory(payload) {
        this._store.dispatch(new ChatActions.RequestChatHistory(payload));
    }
    newChatReceived(payload) {
        this._store.dispatch(new ChatActions.NewChatReceived(payload));
    }
    chatMemberCountReceived(payload) {
        this._store.dispatch(new ChatActions.ChatMemberCountReceived(payload));
    }
    loggedInLeftChat(payload) {
        const chatMember = ChatMiddleware.initChatMember(payload);
        this._store.dispatch(new ChatActions.LoggedInLeftChat(chatMember));
    }
    isNewChat(chatId, chatsState) {
        return ChatMiddleware.isNewChat(chatId, chatsState);
    }
    tagsReceived(payload, id) {
        // const { chat } = payload;
        this._store.dispatch(new ChatActions.TagsReceived(payload, id));
    }
    rolesDelivered(payload) {
        this._store.dispatch(new ChatActions.RolesDelivered(payload));
    }
    rolesReceived(payload) {
        this._store.dispatch(new ChatActions.RolesReceived(payload));
    }
    createEvent(eventDetails, secandEvent) {
        this._store.dispatch(new ChatActions.CreateEvent(eventDetails, secandEvent));
    }
    createBooking(bookingDetails, subChat) {
        this._store.dispatch(new ChatActions.CreateBooking(bookingDetails, subChat));
    }
    createGroup(groupDetails, subChat) {
        this._store.dispatch(new ChatActions.CreateGroup(groupDetails, subChat));
    }
    createChannel(channelDetails, subChat) {
        this._store.dispatch(new ChatActions.CreateChannel(channelDetails, subChat));
    }
    createVirtualApp(virtualAppDetails) {
        this._store.dispatch(new ChatActions.CreateVirtualApp(virtualAppDetails));
    }
    deleteChat(chat_id) {
        this._store.dispatch(new ChatActions.DeleteChat(chat_id));
    }
    chatDeleted(chat_id) {
        this._store.dispatch(new ChatActions.ChatDeleted(chat_id));
    }
    chatDeletedError(chat_id, errorNumber) {
        this._store.dispatch(new ChatActions.ChatDeletedError(chat_id, errorNumber));
    }
    updateChat(chatDetails, reference) {
        this._store.dispatch(new ChatActions.UpdateChat(chatDetails, reference));
    }
    uploadChatPhoto(chatID, selectedFile) {
        this._store.dispatch(new ChatActions.UploadChatPhoto(chatID, selectedFile));
    }
    chatAdminsReceived(chatID, admins) {
        this._store.dispatch(new ChatActions.ChatAdminsReceived(chatID, admins));
    }
    configQrCodeReceived(chatID, configQrCode) {
        this._store.dispatch(new ChatActions.ConfigQrCodeReceived(chatID, configQrCode));
    }
    updateStore(chatDetails, reference) {
        this._store.dispatch(new ChatActions.UpdateStore(chatDetails, reference));
    }
    sendingWelcomeMessage(payload) {
        this._store.dispatch(new ChatActions.SendingWelcomeMessage(payload));
    }
    enableService(chat_id, period, period_number, price, currency) {
        this._store.dispatch(new ChatActions.EnableService(chat_id, period, period_number, price, currency));
    }
    buySubscriptionOnBehalfOfUser(chat_id, member_id, currency) {
        this._store.dispatch(new ChatActions.BuySubscriptionOnBehalfOfUser(chat_id, member_id, currency));
    }
    getBookingPeriods(chat_id) {
        this._store.dispatch(new ChatActions.GetBookingPeriods(chat_id));
    }
    requestChatDetails(chat_id) {
        this._store.dispatch(new ChatActions.RequestChatDetails(chat_id));
    }
    getChatAdministrators(id) {
        this._store.dispatch(new ChatActions.GetChatAdministrators(id));
    }
    getUserGroupRole(id, userId) {
        this._store.dispatch(new ChatActions.GetUserGroupRole(id, userId));
    }
    setWaittingSpiner(id, status, imageUrl) {
        this._store.dispatch(new ChatActions.SetWaittingSpiner(id, status, imageUrl));
    }
}
ChatDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatDispatchers_Factory() { return new ChatDispatchers(i0.ɵɵinject(i1.Store)); }, token: ChatDispatchers, providedIn: "root" });
