import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MyBookingsActions from './myBookings.actions';

import { AppState } from 'src/models/AppState';
import { MyBookings } from 'src/models/MyBookings';

@Injectable({
  providedIn: 'root'
})
export class MyBookingsDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveMyBookings(myBookings: MyBookings): void {
    this._store.dispatch(new MyBookingsActions.ReceiveMyBookings(myBookings));
  }

  requestMyBookings(chatID: string, date: string): void {
    this._store.dispatch(new MyBookingsActions.RequestMyBookings(chatID, date));
  }

  RequestMyBookingDays(
    chatID: string,
    startDate: string,
    endDate: string
  ): void {
    this._store.dispatch(
      new MyBookingsActions.RequestMyBookingDays(chatID, startDate, endDate)
    );
  }

  ReceiveMyBookingDays(chatID: string, bookedDates: string[]): void {
    this._store.dispatch(
      new MyBookingsActions.ReceiveMyBookingDays(chatID, bookedDates)
    );
  }

  cancelMyBookingReservation(
    chat_id: string,
    user_id: string,
    reference: string,
    date: string,
    time: string
  ): void {
    this._store.dispatch(
      new MyBookingsActions.CancelMyBookingReservation(
        chat_id,
        user_id,
        reference,
        date,
        time
      )
    );
  }

  myBookingStatusUpdated(
    chat_id: string,
    user_id: string,
    reference: string,
    date: string,
    time: string,
    status: string,
    cancelable: number
  ): void {
    this._store.dispatch(
      new MyBookingsActions.MyBookingStatusUpdated(
        chat_id,
        user_id,
        reference,
        date,
        time,
        status,
        cancelable
      )
    );
  }

  myBookingsUsersReceived(userNames: object) {
    this._store.dispatch(
      new MyBookingsActions.MyBookingsUsersReceived(userNames)
    );
  }

  receivedMyBookingsError(errorCode: number) {
    this._store.dispatch(
      new MyBookingsActions.ReceivedMyBookingsError(errorCode)
    );
  }
}
