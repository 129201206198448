import { Store } from '@ngrx/store';
import * as ChartActions from './chart.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ChartDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getChartData(chat_id, chart_type, interval, startDate, endDate) {
        this._store.dispatch(new ChartActions.GetChartData(chat_id, chart_type, interval, startDate, endDate));
    }
    receiveChartData(chart_type, values, startDate, endDate, interval) {
        this._store.dispatch(new ChartActions.ReceiveChartData(chart_type, values, startDate, endDate, interval));
    }
}
ChartDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChartDispatchers_Factory() { return new ChartDispatchers(i0.ɵɵinject(i1.Store)); }, token: ChartDispatchers, providedIn: "root" });
