import {
  Component,
  Input,
  ChangeDetectionStrategy,
  AfterViewChecked,
  OnDestroy,
  ElementRef,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { UiService } from '../../core/ui.service';
import { IMessage } from 'src/models/IMessage';
import { MainChat } from 'src/models/MainChat';
import {
  CHANNELS_THEME_COLOR,
  CHAT_THEME_COLOR,
  MessageTypes
} from 'src/models/constants';

export const enum Direction {
  UNKNOWN,
  NEXT,
  PREV
}

@Component({
  selector: 'app-media-full-screen',
  templateUrl: './media-full-screen.component.html',
  styleUrls: ['./media-full-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaFullScreenComponent implements AfterViewChecked, OnDestroy {
  @Input() currentMediaMessages: IMessage[];
  @Input() message: IMessage;
  @Input() selectedChat: MainChat;

  @ViewChild('videoTag', { static: false }) videoTag: ElementRef;

  private lastSelectedMessage: IMessage;

  constructor(private _sanitizer: DomSanitizer, public uiService: UiService) {}

  ngAfterViewChecked() {
    if (
      !this.lastSelectedMessage ||
      this.lastSelectedMessage.reference +
        this.lastSelectedMessage.sender_id !==
        this.message.reference + this.message.sender_id
    ) {
      this.scrollToSelectedMedia();
      this.lastSelectedMessage = this.message;
    }
  }

  ngOnDestroy() {
    if (this.videoTag && !this.videoTag.nativeElement.paused) {
      this.videoTag.nativeElement.pause();
    }
  }

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }

  get themeColor() {
    if (
      this.message &&
      this.message.group_id &&
      this.message.group_type === 101
    ) {
      return CHANNELS_THEME_COLOR;
    }
    return CHAT_THEME_COLOR;
  }

  bkGroundImage(msg: IMessage) {
    if (!msg.localMedia && !msg.localThumbnail) {
      return {};
    }

    if (
      (msg.type === MessageTypes.VIDEO && msg.localThumbnail) ||
      (msg.type === MessageTypes.GIF && msg.localThumbnail)
    ) {
      return {
        'background-image': `url("${msg.localThumbnail}")`
      };
    } else if (msg.type === MessageTypes.PHOTO) {
      return {
        'background-image': `url("${
          msg.localMedia ? msg.localMedia : msg.localThumbnail
        }")`
      };
    } else {
      return {};
    }
  }

  scrollToSelectedMedia() {
    const seleElem = document.querySelector('.selected.indicators');
    if (seleElem) {
      seleElem.scrollIntoView({ block: 'start' });
    }
  }

  cancel() {
    this.uiService.hideMediaScreen();
  }

  private getIndexOfCurrentMessage(): number {
    if (this.currentMediaMessages) {
      return this.currentMediaMessages.findIndex(
        msg =>
          msg.reference + msg.sender_id ===
          this.message.reference + this.message.sender_id
      );
    }
  }
  private getNextMessage(direction: Direction): IMessage {
    const indexOfCurrentMsg = this.getIndexOfCurrentMessage();
    if (indexOfCurrentMsg !== -1) {
      switch (direction) {
        case Direction.NEXT: {
          const nextSlideIndex = !this.isLast(indexOfCurrentMsg)
            ? indexOfCurrentMsg + 1
            : indexOfCurrentMsg;
          return this.currentMediaMessages[nextSlideIndex];
        }
        case Direction.PREV: {
          const nextSlideIndex =
            indexOfCurrentMsg > 0 ? indexOfCurrentMsg - 1 : indexOfCurrentMsg;
          return this.currentMediaMessages[nextSlideIndex];
        }
        default:
          return this.message;
      }
    } else {
      return this.message;
    }
  }

  isLast(index: number): boolean {
    return index + 1 >= this.currentMediaMessages.length;
  }

  next() {
    const nxtMediaMsg = this.getNextMessage(Direction.NEXT);
    if (
      nxtMediaMsg &&
      nxtMediaMsg.reference + nxtMediaMsg.sender_id !==
        this.message.reference + this.message.sender_id
    ) {
      this.uiService.showMediaScreen(nxtMediaMsg);
    }
  }

  previous() {
    const nxtMediaMsg = this.getNextMessage(Direction.PREV);
    if (
      nxtMediaMsg &&
      nxtMediaMsg.reference + nxtMediaMsg.sender_id !==
        this.message.reference + this.message.sender_id
    ) {
      this.uiService.showMediaScreen(nxtMediaMsg);
    }
  }

  selectMedia(message: IMessage) {
    this.uiService.showMediaScreen(message);
  }

  retryEvent() {
    this.uiService.retryMediaOperation(this.message);
  }

  cancelEvent() {
    this.uiService.cancelMediaOperation(this.message);
  }

  TrackByFunction(index, item: IMessage) {
    return item.sender_id ? item.reference + item.sender_id : item.reference;
  }
}
