import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MobileWorkFlowStoreActionTypes from './mobileWorkflowStore.actions';

import { AppState } from 'src/models/AppState';
import {
  RcvdMyMenusMessage,
  BotMenu,
  RcvdMyMenuMessage
} from 'src/models/IBotMenu';
import { ImageSet } from 'src/models/ChannelAppConfig';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class MobileWorkFlowStoreDispatchers {
  constructor(private _store: Store<AppState>) {}

  listMenus() {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.ListMenus());
  }

  menusReceiving(data) {
    this._store.dispatch(
      new MobileWorkFlowStoreActionTypes.MenusReceiving(data)
    );
  }

  menuReceiving(menu: RcvdMyMenuMessage) {
    this._store.dispatch(
      new MobileWorkFlowStoreActionTypes.MenuReceiveing(menu)
    );
  }

  menuReceived(menu: BotMenu) {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.MenuReceived(menu));
  }

  createMenu(
    menu_id: string,
    currency?: string,
    shop?: any,
    image?: any,
    name?: string,
    description?: string,
    is_default?: number,
    id?: string
  ) {
    this._store.dispatch(
      new MobileWorkFlowStoreActionTypes.CreateMenu(
        menu_id,
        currency,
        shop,

        image,
        name,
        description,
        is_default,
        id
      )
    );
  }

  updateMenu(
    menu_id: string,
    currency?: string,
    shop?: any,
    is_default?: number,
    id?: string
  ) {
    this._store.dispatch(
      new MobileWorkFlowStoreActionTypes.UpdateMenu(
        menu_id,
        currency,
        shop,
        is_default,
        id
      )
    );
  }

  deleteMenu(menu: BotMenu) {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.DeleteMenu(menu));
  }

  getNextMenuPage() {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetNextMenuPage());
  }
  getPrevMenuPage() {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetPrevMenuPage());
  }
  resetMenuPaging() {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.ResetMenuPaging());
  }
  setStatusAsIdle(): void {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.SetStatusAsIdle());
  }

  UploadMenuImg(componentRef: string, selectedFile: ISelectedFiles) {
    this._store.dispatch(
      new MobileWorkFlowStoreActionTypes.UploadMenuImg(
        componentRef,
        selectedFile
      )
    );
  }

  UploadMenuImgSuccess(
    componentRef: any,
    imageUrl: string,
    imageSet: ImageSet
  ) {
    // this.formMenu.reciveImageUrl(imageUrl, componentRef);
  }
  getMobileStore(id?: any) {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetMobileStore(id));
  }
  reset() {
    this._store.dispatch(new MobileWorkFlowStoreActionTypes.Reset());
  }
}
