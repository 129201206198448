<div class="cont" (click)="saveAndCont()" *ngIf="type === 'submit'">
  <span>{{ title }}</span>
</div>
<div class="submit" (click)="saveAndCont()" *ngIf="type === 'build'">
  <img src="./assets/img/build.svg" />
  <span>{{ title }}</span>
</div>
<div class="person" (click)="saveAndCont()" *ngIf="type === 'personalize'">
  <img src="./assets/img/presonlize.svg" />
  <span>{{ title }}</span>
</div>
<div class="person" (click)="saveAndCont()" *ngIf="type === 'cstz'">
  <img src="./assets/img/customize.svg" />
  <span>{{ title }}</span>
</div>
