import { InstantDispatchers } from 'src/app/store/instants/instant.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/instants/instant.dispatchers";
export class InstantArticleService {
    constructor(_instantArticleDispatchers) {
        this._instantArticleDispatchers = _instantArticleDispatchers;
    }
    setNewArticle(iv) {
        this._instantArticleDispatchers.instantArticleCreating(iv);
    }
}
InstantArticleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstantArticleService_Factory() { return new InstantArticleService(i0.ɵɵinject(i1.InstantDispatchers)); }, token: InstantArticleService, providedIn: "root" });
