import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { FileService } from 'src/app/core/file.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { VirtualAppUIActionTypes } from './virtualAppsUI.actions';
import * as VirtualAppActions from './virtualAppsUI.actions';
import { Photo } from 'src/models/Photo';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IChat, SetChat, GetSubChats } from 'src/models/IChat';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  ERROR_NOT_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  NOT_SUPPORTED_FILE_TYPE,
  NO_FILE_NAME,
  GROUP_UPLOAD_TYPE,
  PageDirection,
  CHANNEL
} from 'src/models/constants';
import { VirtualAppUiDispatchers } from './virtualAppsUI.dispatchers';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class VirtualAppUiEffects {
  @Effect({ dispatch: false })
  uploadVirtualAppImage = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UPLOAD_VIRTUAL_APP_IMAGE),
    withLatestFrom(
      this._store
        .select(state => state.virtualAppUiReducer.creationProcessSubChat)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.virtualAppUiReducer.selectedSubGroup)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, creationVirtualApp, updatedVirtualApp]) => {
      const action = <VirtualAppActions.UploadVirtualAppImage>val;

      if (this.validateFile(action.selectedFile, 'image')) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.localFile)
          .then(fileAsArrayBuffer => {
            this._uploadGateway
              .uploadWithProgress(
                fileAsArrayBuffer,
                action.selectedFile.localFile.type,
                action.selectedFile.localFile.name,
                false,
                GROUP_UPLOAD_TYPE,
                null,
                creationVirtualApp
                  ? creationVirtualApp.id
                  : updatedVirtualApp.id,
                true
              )
              .subscribe(
                event => {
                  if (event.type === HttpEventType.Response) {
                    const res = <IUploadResponse>event.body;
                    const chat: IChat = {};
                    const photo: Photo = { id: res.file };
                    chat.id = creationVirtualApp
                      ? creationVirtualApp.id
                      : updatedVirtualApp.id;
                    chat.photo = photo;
                    this._socketGateway.sendSocketMessage(new SetChat(chat));
                  }
                },
                error => {
                  console.log('Failed to upload Virtual app image');
                }
              );
          });
      }
    })
  );

  @Effect({ dispatch: false })
  afterVirtualAppCreation = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UI_UPLOAD_VIRTUAL_APP_PHOTO),
    map((action: VirtualAppActions.VirtualAppPhotoUploadUI) => {
      this._chatDispatchers.chatInvalidated(action.createdVirtualApp.id);
    })
  );

  @Effect({ dispatch: false })
  getNextVirtualAppPage = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UI_VIRTUAL_APP_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.virtualAppUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, virtualAppUiState]) => {
      const action = <VirtualAppActions.GetNextVirtualAppPage>val;
      const prevRequest = virtualAppUiState.previousRequest;
      if (
        !(
          virtualAppUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          virtualAppUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats(
            [CHANNEL],
            0,
            virtualAppUiState.hash,
            virtualAppUiState.eop,
            null,
            null,
            1
          )
        );
      } else {
        this._virtualAppUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevVirtualAppUiStatePage = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UI_VIRTUAL_APP_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.virtualAppUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, virtualAppUiState]) => {
      const action = <VirtualAppActions.GetPrevVirtualAppPage>val;
      const prevRequest = virtualAppUiState.previousRequest;
      if (
        !(
          virtualAppUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          virtualAppUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats(
            [CHANNEL],
            1,
            virtualAppUiState.hash,
            virtualAppUiState.sop,
            null,
            null,
            1
          )
        );
      } else {
        this._virtualAppUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterVirtualAppSectionSelection = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UI_LIST_VIRTUAL_APP),
    map((action: VirtualAppActions.ListVirtualAppUI) => {
      this._virtualAppUiDispatchers.resetVirtualAppPaging();
    })
  );

  @Effect({ dispatch: false })
  afterSubChatResetPaging = this.actions$.pipe(
    ofType(VirtualAppUIActionTypes.UI_VIRTUAL_APP_RESET_PAGING),
    map((action: VirtualAppActions.ResetVirtualAppPaging) => {
      this._virtualAppUiDispatchers.getNextVirtualAppPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _chatDispatchers: ChatDispatchers,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _virtualAppUiDispatchers: VirtualAppUiDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _store: Store<AppState>
  ) {}

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
