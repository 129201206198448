import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { BundlesUIActionTypes } from './bundlesUI.actions';
import { BundlesUiDispatchers } from './bundlesUI.dispatchers';
import * as BundlesUiActions from './bundlesUI.actions';
import {
  CreateBundleMethod,
  Bundle,
  SetBundleMethod,
  GetBundlesMethod,
  RemoveBundleProductsMethod,
  AddBundleProductsMethod,
  RemoveBundleMethod
} from 'src/models/Bundle';
import { PayCash } from 'src/models/IChatMember';
import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import { UIDispatchers } from '../ui/ui.dispatchers';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class BundlesUiEffects {
  @Effect({ dispatch: false })
  createBundle = this.actions$.pipe(
    ofType(BundlesUIActionTypes.CREATE_BUNDLE),
    map((action: BundlesUiActions.CreateBundle) => {
      this._socketGateway.sendSocketMessage(
        new CreateBundleMethod(action.bundle)
      );
    })
  );

  @Effect({ dispatch: false })
  updateBundle = this.actions$.pipe(
    ofType(BundlesUIActionTypes.SET_BUNDLE),
    map((action: BundlesUiActions.SetBundle) => {
      const bundle: Bundle = {
        ...action.bundleDetails,
        id: action.bundleID
      };
      this._socketGateway.sendSocketMessage(new SetBundleMethod(bundle));
    })
  );

  @Effect({ dispatch: false })
  addBundleProducts = this.actions$.pipe(
    ofType(BundlesUIActionTypes.ADD_BUNDLE_PRODUCTS),
    map((action: BundlesUiActions.AddBundleProducts) => {
      this._socketGateway.sendSocketMessage(
        new AddBundleProductsMethod(action.bundleID, action.bundleProducts)
      );
    })
  );

  @Effect({ dispatch: false })
  removeBundleProducts = this.actions$.pipe(
    ofType(BundlesUIActionTypes.REMOVE_BUNDLE_PRODUCTS),
    map((action: BundlesUiActions.RemoveBundleProducts) => {
      this._socketGateway.sendSocketMessage(
        new RemoveBundleProductsMethod(action.bundleID, action.productsIDs)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteBundle = this.actions$.pipe(
    ofType(BundlesUIActionTypes.DELETE_BUNDLE),
    map((action: BundlesUiActions.DeleteBundle) => {
      this._socketGateway.sendSocketMessage(
        new RemoveBundleMethod(action.bundleID)
      );
    })
  );

  //
  @Effect({ dispatch: false })
  getNextBundlesPage = this.actions$.pipe(
    ofType(BundlesUIActionTypes.UI_BUNDLES_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.bundlesUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, bundlesUiState]) => {
      const action = <BundlesUiActions.GetNextBundlesPage>val;
      const prevRequest = bundlesUiState.previousRequest;
      if (
        !(
          bundlesUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          bundlesUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBundlesMethod(0, bundlesUiState.eop)
        );
      } else {
        this._uiBundlesDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevBundlesPage = this.actions$.pipe(
    ofType(BundlesUIActionTypes.UI_BUNDLES_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.bundlesUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, bundlesUiState]) => {
      const action = <BundlesUiActions.GetPrevBundlesPage>val;
      const prevRequest = bundlesUiState.previousRequest;
      if (
        !(
          bundlesUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          bundlesUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetBundlesMethod(1, bundlesUiState.sop)
        );
      } else {
        this._uiBundlesDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterBundlesResetPaging = this.actions$.pipe(
    ofType(BundlesUIActionTypes.UI_BUNDLES_RESET_PAGING),
    map((action: BundlesUiActions.ResetBundlesPaging) => {
      this._uiBundlesDispatchers.getNextBundlesPage();
    })
  );

  @Effect({ dispatch: false })
  buyBundleOnBehalfOfUser = this.actions$.pipe(
    ofType(BundlesUIActionTypes.BUY_BUNDLE_ON_BEHALF_OF_USER),
    map((action: BundlesUiActions.BuyBundleOnBehalfOfUser) => {
      this._socketGateway.sendSocketMessage(
        new PayCash(
          action.userId,
          action.bundle.id,
          action.bundle.price,
          1,
          action.bundle.currency,
          'PAY_USER'
        )
      );
    })
  );

  @Effect({ dispatch: false })
  cashPaymentStatus = this.actions$.pipe(
    ofType(BundlesUIActionTypes.CASH_PAYMENT_STATUS),
    map((action: BundlesUiActions.CashPaymentStatus) => {
      const title = action.payResult.result;
      const message = action.payResult.message;
      if (action.payResult.payment_error) {
        this._uiDispatchers.showPopup(message, title);
      } else {
        this._uiDispatchers.showPopup(
          `Bundle has been solid successfully`,
          title
        );
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiBundlesDispatchers: BundlesUiDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _store: Store<AppState>
  ) {}
}
