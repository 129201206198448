import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CreateProductMethod, SetProductMethod, GetProductsMethod, AddInventoryMethod, GetProductMethod, RemoveProductMethod } from 'src/models/Product';
import { PageDirection } from 'src/models/constants';
import { ProductsUiDispatchers } from './productsUI.dispatchers';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import * as isEqual from 'lodash.isequal';
export class ProductsUiEffects {
    constructor(actions$, _socketGateway, _uiProductsDispatchers, _chatDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiProductsDispatchers = _uiProductsDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._store = _store;
        this.createProduct = this.actions$.pipe(ofType("CREATE_PRODUCT" /* CREATE_PRODUCT */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateProductMethod(action.product));
        }));
        this.updateProduct = this.actions$.pipe(ofType("SET_PRODUCT" /* SET_PRODUCT */), map((action) => {
            const product = Object.assign({}, action.productDetails, { id: action.productID });
            this._socketGateway.sendSocketMessage(new SetProductMethod(product));
        }));
        this.addInventory = this.actions$.pipe(ofType("ADD_INVENTORY" /* ADD_INVENTORY */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddInventoryMethod(action.productID, action.units));
        }));
        //
        this.getNextProductsPage = this.actions$.pipe(ofType("UI_PRODUCTS_GET_NEXT_PAGE" /* UI_PRODUCTS_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.productsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, productsUiState]) => {
            const action = val;
            const prevRequest = productsUiState.previousRequest;
            if (!(productsUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                productsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetProductsMethod(0, productsUiState.eop, productsUiState.productGroupId, productsUiState.ownerGroupId));
            }
            else {
                this._uiProductsDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevProductsPage = this.actions$.pipe(ofType("UI_PRODUCTS_GET_PREV_PAGE" /* UI_PRODUCTS_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.productsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, productsUiState]) => {
            const action = val;
            const prevRequest = productsUiState.previousRequest;
            if (!(productsUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                productsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetProductsMethod(1, productsUiState.sop, productsUiState.productGroupId, productsUiState.ownerGroupId));
            }
            else {
                this._uiProductsDispatchers.setStatusAsIdle();
            }
        }));
        this.afterProductsResetPaging = this.actions$.pipe(ofType("UI_PRODUCTS_RESET_PAGING" /* UI_PRODUCTS_RESET_PAGING */), map((action) => {
            this._uiProductsDispatchers.getNextProductsPage();
        }));
        this.getProduct = this.actions$.pipe(ofType("GET_PRODUCT" /* GET_PRODUCT */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, groupsState]) => {
            const action = val;
            if (!action.productType) {
                this._socketGateway.sendSocketMessage(new GetProductMethod(action.productID, action.bundleID, 0));
            }
            // else if (MarketTypes.CHAT === action.productType) {
            //   let timeOutMilliSecs = 0;
            //   if (ChatMiddleware.isNewChat(action.productID, groupsState)) {
            //     const newChat: IChat = {};
            //     newChat.id = action.productID;
            //     this._chatDispatchers.newChatReceived(newChat);
            //     timeOutMilliSecs = 300;
            //   }
            //   setTimeout(
            //     () => this._chatDispatchers.requestChatDetails(action.productID),
            //     timeOutMilliSecs
            //   );
            // }
        }));
        this.deleteProduct = this.actions$.pipe(ofType("DELETE_PRODUCT" /* DELETE_PRODUCT */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveProductMethod(action.productId));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "createProduct", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "updateProduct", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "addInventory", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "getNextProductsPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "getPrevProductsPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "afterProductsResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "getProduct", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ProductsUiEffects.prototype, "deleteProduct", void 0);
