import * as InstantActions from './instant.actions';

import { InstantArticle } from 'src/models/InstantArticle';
import { InstantMiddleware } from './instant.middleware';

const INITIAL_STATE = [];

export function instantReducer(
  state: InstantArticle[] = INITIAL_STATE,
  action: InstantActions.InstantActions
): InstantArticle[] {
  switch (action.type) {
    case InstantActions.InstantArticleActionTypes.INSTANT_ARTICLE_CREATING:
      if (!InstantMiddleware.isInstantArticleExist(state, action.payload)) {
        return [...state, action.payload];
      }
      return state;
    case InstantActions.InstantArticleActionTypes.INSTANT_ARTICLE_RECEIVED:
      return state.map(iv => {
        if (iv.reference === action.payload.reference) {
          let updatedArticle: InstantArticle = {};
          updatedArticle = { ...iv, ...action.payload };
          return updatedArticle;
        }
        return iv;
      });
    /********************************************************************************************************/
    /** Link Preview scetion */
    case InstantActions.InstantArticleActionTypes.SET_LINK_PREVIEW_STATUS:
      return state.map(message => {
        if (
          message.reference &&
          message.reference === action.payload.reference
        ) {
          const newMsg: InstantArticle = {};
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case InstantActions.InstantArticleActionTypes.LINK_PREVIEW_DETAILS_RECEIVED:
      return state.map(message => {
        if (
          message.reference &&
          message.reference === action.payload.reference
        ) {
          const newMsg: InstantArticle = {};
          newMsg.linkPreviewTitle = action.payload.linkPreviewTitle;
          if (
            action.payload.linkPreviewDescription &&
            action.payload.linkPreviewDescription.length > 0
          ) {
            newMsg.linkPreviewDescription = action.payload.linkPreviewDescription.trim();
          }
          newMsg.linkPreviewImage = action.payload.linkPreviewImage;
          newMsg.linkPreviewWidth = action.payload.linkPreviewWidth;
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          newMsg.linkPreviewHeight = action.payload.linkPreviewHeight;
          newMsg.linkPreviewUrl = action.payload.linkPreviewUrl;
          newMsg.linkPreviewRootUrl = action.payload.linkPreviewRootUrl;
          return { ...message, ...newMsg };
        }
        return message;
      });
    /********************************************************************************************************/
    default:
      return state;
  }
}
