<div class="notify-modal" [class.no-shadow]="hideShadow">
  <div class="notify-modal-header">
    <div *ngIf="showBack" class="clickable-cursor" (click)="onBack()">
      <i class="fas fa-arrow-left"></i>
    </div>
    <div class="title">
      {{ title }}

      <app-hover-tooltip-icon
        *ngIf="showTooltip"
        [title]="titleTooltip"
        [description]="descriptionTooltip"
        [top]="topHoverTooltip"
        [left]="leftHoverTooltip"
      ></app-hover-tooltip-icon>
    </div>
    <div class="full-w"></div>
    <div *ngIf="showClose" class="close clickable-cursor" (click)="onClose()">
      <img src="./assets/img/cancel-key.svg" alt="" />
    </div>
    <ng-content select="notify-action"></ng-content>
  </div>
  <div class="notify-modal-body">
    <ng-content></ng-content>
  </div>
</div>
