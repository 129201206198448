import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxModule } from '../ngx.module';
import { SharedModule } from '../shared/shared.module';
import { CellsModule } from '../cells/cells.module';
import { EmojiboxModule } from '../emojibox/emojibox.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';

import { PreviewMediaComponent } from './preview-media/preview-media.component';
import { MediaFullScreenComponent } from './media-full-screen/media-full-screen.component';

@NgModule({
  imports: [
    CommonModule,
    NgxModule,
    SharedModule,
    CellsModule,
    EmojiboxModule,
    AbstractBtnsModule
  ],
  declarations: [PreviewMediaComponent, MediaFullScreenComponent],
  exports: [
    PreviewMediaComponent,
    MediaFullScreenComponent,
    PreviewMediaComponent
  ]
})
export class PreviewsModule {}
