import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { BundlesUiDispatchers } from './bundlesUI.dispatchers';
import { CreateBundleMethod, SetBundleMethod, GetBundlesMethod, RemoveBundleProductsMethod, AddBundleProductsMethod, RemoveBundleMethod } from 'src/models/Bundle';
import { PayCash } from 'src/models/IChatMember';
import { PageDirection } from 'src/models/constants';
import { UIDispatchers } from '../ui/ui.dispatchers';
import * as isEqual from 'lodash.isequal';
export class BundlesUiEffects {
    constructor(actions$, _socketGateway, _uiBundlesDispatchers, _uiDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiBundlesDispatchers = _uiBundlesDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._store = _store;
        this.createBundle = this.actions$.pipe(ofType("CREATE_BUNDLE" /* CREATE_BUNDLE */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateBundleMethod(action.bundle));
        }));
        this.updateBundle = this.actions$.pipe(ofType("SET_BUNDLE" /* SET_BUNDLE */), map((action) => {
            const bundle = Object.assign({}, action.bundleDetails, { id: action.bundleID });
            this._socketGateway.sendSocketMessage(new SetBundleMethod(bundle));
        }));
        this.addBundleProducts = this.actions$.pipe(ofType("ADD_BUNDLE_PRODUCTS" /* ADD_BUNDLE_PRODUCTS */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddBundleProductsMethod(action.bundleID, action.bundleProducts));
        }));
        this.removeBundleProducts = this.actions$.pipe(ofType("REMOVE_BUNDLE_PRODUCTS" /* REMOVE_BUNDLE_PRODUCTS */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveBundleProductsMethod(action.bundleID, action.productsIDs));
        }));
        this.deleteBundle = this.actions$.pipe(ofType("DELETE_BUNDLE" /* DELETE_BUNDLE */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveBundleMethod(action.bundleID));
        }));
        //
        this.getNextBundlesPage = this.actions$.pipe(ofType("UI_BUNDLES_GET_NEXT_PAGE" /* UI_BUNDLES_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.bundlesUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, bundlesUiState]) => {
            const action = val;
            const prevRequest = bundlesUiState.previousRequest;
            if (!(bundlesUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                bundlesUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBundlesMethod(0, bundlesUiState.eop));
            }
            else {
                this._uiBundlesDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevBundlesPage = this.actions$.pipe(ofType("UI_BUNDLES_GET_PREV_PAGE" /* UI_BUNDLES_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.bundlesUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, bundlesUiState]) => {
            const action = val;
            const prevRequest = bundlesUiState.previousRequest;
            if (!(bundlesUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                bundlesUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBundlesMethod(1, bundlesUiState.sop));
            }
            else {
                this._uiBundlesDispatchers.setStatusAsIdle();
            }
        }));
        this.afterBundlesResetPaging = this.actions$.pipe(ofType("UI_BUNDLES_RESET_PAGING" /* UI_BUNDLES_RESET_PAGING */), map((action) => {
            this._uiBundlesDispatchers.getNextBundlesPage();
        }));
        this.buyBundleOnBehalfOfUser = this.actions$.pipe(ofType("BUY_BUNDLE_ON_BEHALF_OF_USER" /* BUY_BUNDLE_ON_BEHALF_OF_USER */), map((action) => {
            this._socketGateway.sendSocketMessage(new PayCash(action.userId, action.bundle.id, action.bundle.price, 1, action.bundle.currency, 'PAY_USER'));
        }));
        this.cashPaymentStatus = this.actions$.pipe(ofType("CASH_PAYMENT_STATUS" /* CASH_PAYMENT_STATUS */), map((action) => {
            const title = action.payResult.result;
            const message = action.payResult.message;
            if (action.payResult.payment_error) {
                this._uiDispatchers.showPopup(message, title);
            }
            else {
                this._uiDispatchers.showPopup(`Bundle has been solid successfully`, title);
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "createBundle", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "updateBundle", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "addBundleProducts", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "removeBundleProducts", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "deleteBundle", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "getNextBundlesPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "getPrevBundlesPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "afterBundlesResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "buyBundleOnBehalfOfUser", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BundlesUiEffects.prototype, "cashPaymentStatus", void 0);
