import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapTripActions from './mapTrip.actions';
import { AppState } from 'src/models/AppState';
import { MapTrip } from 'src/models/MapTrip';

@Injectable({
  providedIn: 'root'
})
export class MapTripDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetMapTripPaging() {
    this._store.dispatch(new MapTripActions.ResetMapTripPaging());
  }

  getNextMapTripPage() {
    this._store.dispatch(new MapTripActions.GetNextMapTripPage());
  }

  getPrevMapTripPage() {
    this._store.dispatch(new MapTripActions.GetPrevMapTripPage());
  }

  createMapTrip(mapTrip: MapTrip): void {
    this._store.dispatch(new MapTripActions.CreateMapTrip(mapTrip));
  }

  mapTripCreated(mapTrip: MapTrip): void {
    this._store.dispatch(new MapTripActions.MapTripCreated(mapTrip));
  }

  updateMapTrip(id: string, mapTrip: MapTrip): void {
    this._store.dispatch(new MapTripActions.UpdateMapTrip(id, mapTrip));
  }

  mapTripDetailsReceived(mapTrip: MapTrip): void {
    this._store.dispatch(new MapTripActions.MapTripDetailsReceived(mapTrip));
  }

  getMapTripById(tripId: string) {
    this._store.dispatch(new MapTripActions.GetMapTripById(tripId));
  }

  mapTripListReceived(
    eop: number,
    sop: number,
    mapTrips: MapTrip[],
    hash: string
  ): void {
    this._store.dispatch(
      new MapTripActions.MapTripListReceived(eop, sop, mapTrips, hash)
    );
  }

  setCurrentScreen(screen: string) {
    this._store.dispatch(new MapTripActions.SetCurrentScreen(screen));
  }

  setStatusAsIdle() {
    this._store.dispatch(new MapTripActions.SetStatusAsIdle());
  }

  deleteMapTrip(id: string): void {
    this._store.dispatch(new MapTripActions.DeleteMapTrip(id));
  }

  mapTripDeleted(id: string): void {
    this._store.dispatch(new MapTripActions.MapTripDeleted(id));
  }
}
