import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IMessage } from 'src/models/IMessage';
import { MessageLinkPreviewStatus } from 'src/models/constants';

@Component({
  selector: 'app-text-cell',
  templateUrl: './text-cell.component.html',
  styleUrls: ['./text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCellComponent {
  @Input() text: string;
  @Input() message: IMessage;
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;
}
