import { Store } from '@ngrx/store';
import * as InstancePageActions from './instantPage.actions';
import { initRcvdPage } from '../channelMyPage/myPage.middleware';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class InstantPageDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    requestInstantPageDetails(id) {
        this._store.dispatch(new InstancePageActions.RequestInstantPageDetails(id));
    }
    receiveInstantPageDetails(rcvdPage) {
        const initializedPage = initRcvdPage(true, rcvdPage);
        this._store.dispatch(new InstancePageActions.ReceiveInstantPageDetails(initializedPage));
    }
    receiveInstantPages(rcvdPages) {
        this._store.dispatch(new InstancePageActions.ReceiveInstantPages(rcvdPages));
    }
}
InstantPageDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstantPageDispatchers_Factory() { return new InstantPageDispatchers(i0.ɵɵinject(i1.Store)); }, token: InstantPageDispatchers, providedIn: "root" });
