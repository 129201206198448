import { BehaviorSubject } from 'rxjs';
import { CollectionsUIDispatchers } from 'src/app/store/collectionsUI/collectionsUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/collectionsUI/collectionsUI.dispatchers";
import * as i2 from "../../../store/root-store.selectors";
export class CollectionsService {
    constructor(_collectionsUIDispatchers, _rootStore) {
        this._collectionsUIDispatchers = _collectionsUIDispatchers;
        this._rootStore = _rootStore;
        this._selectedCollection = new BehaviorSubject(null);
        this.selectedCollection$ = this._selectedCollection.asObservable();
        this.confirmProduct = false;
    }
    get getCollectionsList$() {
        return this._rootStore.getCollectionsList$();
    }
    getSelectedCollection$() {
        return this._rootStore.getSelectedCollection$();
    }
    resetPaging() {
        this._collectionsUIDispatchers.resetCollectionsPaging();
    }
    setCollectionItem(collection) {
        this._collectionsUIDispatchers.setCollectionItem(collection);
    }
    updateCollectionItem(collection) {
        this._selectedCollection.next(Object.assign({}, collection));
    }
    getCollectionItem(id, isSelectedItem) {
        this._collectionsUIDispatchers.getCollectionItem(id, isSelectedItem);
    }
    setCollectionProductsItem(id, productIDs) {
        this._collectionsUIDispatchers.setCollectionProducts(id, productIDs);
    }
    getNextPage() {
        this._collectionsUIDispatchers.getCollectionsNextPage();
    }
    getPrevPage() {
        this._collectionsUIDispatchers.getCollectionsPrevPage();
    }
    deleteCollectionItem(id) {
        this._collectionsUIDispatchers.deleteCollectionItem(id);
    }
    getCollectionProducts(id) {
        this._collectionsUIDispatchers.getCollectionProducts(id);
    }
    toggleConfirmDeleteProduct(e, collection) {
        this.confirmProduct = e;
        this.selectedCollection = collection;
    }
    getSelectedCollectionByID$(id) {
        return this._rootStore.getSelectedCollectionByID$(id);
    }
}
CollectionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CollectionsService_Factory() { return new CollectionsService(i0.ɵɵinject(i1.CollectionsUIDispatchers), i0.ɵɵinject(i2.RootStoreSelectors)); }, token: CollectionsService, providedIn: "root" });
