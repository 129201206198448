<a class="app-logo clickable-cursor" href="https://nandbox.com" target="_blank">
  <img
    class="main-logo"
    src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp
"
    alt="nandbox Native App Builder logo"
    height="40"
    width="100%"
    draggable="false"
  />
  <img
    class="small-logo"
    src="https://nandbox.com/wp-content/uploads/2023/05/nandbox-logo-n.webp"
    height="40"
    width="100%"
    draggable="false"
  />
</a>
<div class="login-form no-copyable-item">
  <div class="login-form-header">
    Verify Your Account
  </div>

  <div class="login-form-title">
    Please enter the verification code we sent to your email:
    <span style="color: #01a1ee;">
      {{ form?.get('email').value }}
    </span>
    <a (click)="routeBack()" class="link">Edit</a>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="text" hidden formControlName="appName" />
    <input type="text" hidden formControlName="password" />
    <input type="text" hidden formControlName="email" />
    <input type="text" hidden formControlName="tac" [(value)]="tacValue" />

    <div class="faild">
      <label for="1">Code</label>
      <input
        autofocus
        #fst
        type="text"
        id="1"
        formControlName="fst"
        min="0"
        max="9"
        maxlength="6"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        placeholder="Please enter the verification code"
        (input)="onInputEntry($event, '2')"
        (change)="setTacValue(fst?.value)"
      />
    </div>
    <div class="errors">
      <div *ngIf="form?.errors?.wrongTac">Wrong code</div>
    </div>

    <!-- <div class="login-form-submit">
      <app-border-btn next invert lg [type]="'submit'" [disabled]="!form.valid">
        Get Started <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
      </app-border-btn>
    </div> -->
    <button type="submit" class="submit-btn" [disabled]="!form.valid">
      <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i> Get Started
    </button>
  </form>

  <div class="login-form-terms">
    <div>Didn't receive the verification code?</div>
    <div>Please check your junk/spam folder or click to resend code</div>
    <app-link-btn (btnClicked)="reSend()" *ngIf="!hideResendCode">
      <ng-container ngProjectAs="link-content-clicked">
        Resend Code
      </ng-container>
    </app-link-btn>
    <div id="countdown" style="color: #1096eb;"></div>
  </div>
</div>
