import { Store } from '@ngrx/store';
import * as MenuUIActions from './menuUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MenuUIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listMenus(type) {
        this._store.dispatch(new MenuUIActions.ListMenus(type));
    }
    menusReceiving(menu) {
        this._store.dispatch(new MenuUIActions.MenusReceiving(menu));
    }
    menuReceiving(menu) {
        this._store.dispatch(new MenuUIActions.MenuReceiveing(menu));
    }
    menuReceived(menu) {
        this._store.dispatch(new MenuUIActions.MenuReceived(menu));
    }
    createMenu(menu) {
        this._store.dispatch(new MenuUIActions.CreateMenu(menu));
    }
    updateMenu(menu) {
        this._store.dispatch(new MenuUIActions.UpdateMenu(menu));
    }
    deleteMenu(menu) {
        this._store.dispatch(new MenuUIActions.DeleteMenu(menu));
    }
    getNextMenuPage() {
        this._store.dispatch(new MenuUIActions.GetNextMenuPage());
    }
    getPrevMenuPage() {
        this._store.dispatch(new MenuUIActions.GetPrevMenuPage());
    }
    resetMenuPaging() {
        this._store.dispatch(new MenuUIActions.ResetMenuPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new MenuUIActions.SetStatusAsIdle());
    }
    UploadMenuImg(componentRef, selectedFile) {
        this._store.dispatch(new MenuUIActions.UploadMenuImg(componentRef, selectedFile));
    }
    UploadMenuImgSuccess(componentRef, imageUrl, imageSet) {
        // this.formMenu.reciveImageUrl(imageUrl, componentRef);
    }
    reset() {
        this._store.dispatch(new MenuUIActions.Reset());
    }
}
MenuUIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuUIDispatchers_Factory() { return new MenuUIDispatchers(i0.ɵɵinject(i1.Store)); }, token: MenuUIDispatchers, providedIn: "root" });
