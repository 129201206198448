import { Actions } from './subItem.actions';
import * as actions from './subItem.actions';
import { LocalItem } from 'src/models/ChannelAppLocalConfig';

const INITIAL_STATE = [];

export function subItemReducer(
  state: LocalItem[] = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.SubItemActionTypes.APP_CONFIG_DATA_CHANGED: {
      return [
        ...action.onlineAppConfig.subItems,
        ...action.offlineAppConfig.subItems
      ];
    }
    case actions.SubItemActionTypes.UPDATE_SUB_ITEM: {
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = { ...item, ...action.itemUpdatedProps };
          return updatedItem;
        }
        return item;
      });
    }
    case actions.SubItemActionTypes.UPDATE_SUB_ITEM_LINK: {
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = {
            ...item,
            ...{ link: action.itemLink }
          };
          return updatedItem;
        }
        return item;
      });
    }
    case actions.SubItemActionTypes.REORDER_SUB_ITEM: {
      const targetItem = state.find(item => item.ref === action.itemRef);
      const oldPosition = targetItem ? targetItem.order : null;
      const parentRef = targetItem ? targetItem.parentComponentRef : null;
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = { ...item, order: action.newPosition };
          return updatedItem;
        } else if (
          item.parentComponentRef === parentRef &&
          oldPosition !== null &&
          item.order >= 0
        ) {
          if (action.newPosition < oldPosition && item.order < oldPosition) {
            // MOVING_UP
            let updatedItem: LocalItem = {};
            updatedItem = { ...item, order: item.order + 1 };
            return updatedItem;
          } else if (
            action.newPosition > oldPosition &&
            item.order <= action.newPosition
          ) {
            // MOVING_DOWN
            let updatedItem: LocalItem = {};
            updatedItem = { ...item, order: item.order - 1 };
            return updatedItem;
          }
        }
        return item;
      });
    }
    case actions.SubItemActionTypes.DELETE_SUB_ITEM: {
      const orderOfDeletedItem = action.item.order;
      const parentRef = action.item.parentComponentRef;
      const remainingList = state.filter(item => item.ref !== action.item.ref);
      if (remainingList) {
        return remainingList.map(item => {
          if (
            item.parentComponentRef === parentRef &&
            (orderOfDeletedItem !== null && orderOfDeletedItem !== undefined) &&
            item.order >= 0
          ) {
            if (item.order > orderOfDeletedItem) {
              let updatedItem: LocalItem = {};
              updatedItem = { ...item, order: item.order - 1 };
              return updatedItem;
            }
          }
          return item;
        });
      } else {
        return INITIAL_STATE;
      }
    }
    case actions.SubItemActionTypes.ADD_SUB_ITEM: {
      const updatedState = state.map(item => {
        if (
          item.parentComponentRef === action.item.parentComponentRef &&
          item.order >= 0
        ) {
          let updatedItem: LocalItem = {};
          updatedItem = { ...item, order: item.order + 1 };
          return updatedItem;
        }
        return item;
      });
      return [action.item, ...updatedState];
    }
    case actions.SubItemActionTypes.DELETE_SUB_ITEMS_OF_COMPONENT: {
      return state.filter(
        item => item.parentComponentRef !== action.component.ref
      );
    }
    case actions.SubItemActionTypes.ADD_SUB_ITEMS: {
      return [...action.items, ...state];
    }
    case actions.SubItemActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
