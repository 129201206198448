/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./standard-date.component";
var styles_StandardDateComponent = [];
var RenderType_StandardDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StandardDateComponent, data: {} });
export { RenderType_StandardDateComponent as RenderType_StandardDateComponent };
function View_StandardDateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, [" ", " "])), i0.ɵppd(1, 2), i0.ɵppd(2, 2), i0.ɵppd(3, 2), i0.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.todayDate, "yMd")) === i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, "yMd"))) ? i0.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, "hh:mm a")) : i0.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, _co.timesFormat))); _ck(_v, 0, 0, currVal_0); }); }
function View_StandardDateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, [" ", " "])), i0.ɵppd(1, 2), i0.ɵppd(2, 2), i0.ɵppd(3, 2), i0.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.todayDate, "y")) === i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, "y"))) ? i0.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, "MMM dd 'at' hh:mm a")) : i0.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.timestamp, "MMM dd,y 'at' hh:mm a"))); _ck(_v, 0, 0, currVal_0); }); }
export function View_StandardDateComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StandardDateComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StandardDateComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPost; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isPost; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_StandardDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-standard-date", [], null, null, null, View_StandardDateComponent_0, RenderType_StandardDateComponent)), i0.ɵdid(1, 49152, null, 0, i2.StandardDateComponent, [], null, null)], null, null); }
var StandardDateComponentNgFactory = i0.ɵccf("app-standard-date", i2.StandardDateComponent, View_StandardDateComponent_Host_0, { timestamp: "timestamp", timesFormat: "timesFormat", isPost: "isPost" }, {}, ["*"]);
export { StandardDateComponentNgFactory as StandardDateComponentNgFactory };
