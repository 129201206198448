import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';
import { CellsService } from 'src/app/cells/cells.service';
import { FileService } from 'src/app/core/file.service';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Component({
  selector: 'app-abstract-crop',
  templateUrl: './abstract-crop.component.html',
  styleUrls: ['./abstract-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbstractCropComponent implements OnInit {
  @Input() imageChangedEvent;
  @Input() selectedFile;
  @Input() resizeToWidth = 0;
  @Input() resizeToHeight = 0;
  @Input() cropFormat;
  @Input() imageQuality;
  @Input() aspectRatio;
  @Input() mimeType;
  @Input() onlyScaleDown;
  @Input() maintainAspectRatio = false;
  @Input() roundCropper = false;

  @Input() cropperMaxWidth;
  @Input() cropperMaxHeight;

  @Input() skipCropper = false;

  @Output() filesSelectedNotifay = new EventEmitter<ISelectedFiles>();
  @Output() closeNotifay = new EventEmitter<boolean>();

  public _spinner = new BehaviorSubject<boolean>(true);
  public spinner$ = this._spinner.asObservable();

  public _blobUrl = new BehaviorSubject<any>('');
  public blobUrl$ = this._blobUrl.asObservable();

  imageFile: ISelectedFiles;
  croppedImage: any = '';

  blobUrl: string;

  constructor(
    private _fileService: FileService,
    private _cellsService: CellsService
  ) {}

  ngOnInit() {
    if (this.skipCropper) {
      this.getBlobURL(this.selectedFile);
    }
  }

  getBlobURL(file: any) {
    this._fileService.readFileAsArrayBuffer(file).then(fileAsArrayBuffer =>
      this._fileService
        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, file.type)
        .then(blobUrl => {
          const media = this._cellsService.getMedia(blobUrl);
          this._blobUrl.next(media);
          this._spinner.next(false);
        })
    );
  }

  closePreview() {
    this.closeNotifay.emit(true);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageFile = event.file;
    this.imageFile.localFile = this.getBlob(this.croppedImage);
  }

  imageLoaded() {}

  onDragEvents(event) {
    event.stopPropagation();
  }

  loadImageFailed() {}

  cropperReady() {
    this._spinner.next(false);
  }

  getBlob(b64Data: string) {
    const contentType = this.mimeType;
    const sliceSize = 512;

    b64Data = b64Data.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const b: any = blob;

    b.lastModifiedDate = new Date();
    b.name = 'uploadedImage';

    return b;
  }

  onFilesSelected(file: ISelectedFiles) {
    if (this.skipCropper) {
      this.filesSelectedNotifay.emit(this.imageChangedEvent);
    } else {
      this.filesSelectedNotifay.emit(file);
    }
  }
}
