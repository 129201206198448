import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import * as MapActions from './mapStopTime.actions';
import { map } from 'rxjs/operators';
import { MapStopTimeActionTypes } from './mapStopTime.actions';
import {
  GetMapStopTimeMethod,
  SetMapStopTime,
  SetMapStopTimesMethod
} from 'src/models/MapStopTime';

@Injectable()
export class MapStopTimeEffects {
  @Effect({ dispatch: false })
  setMapStopTime = this.actions$.pipe(
    ofType(MapStopTimeActionTypes.CREATE_MAP_STOP_TIME),
    map((action: MapActions.CreateMapStopTime) => {
      this._socketGateway.sendSocketMessage(
        new SetMapStopTime(action.MapStopTime)
      );
    })
  );
  @Effect({ dispatch: false })
  getMapStopsTimes = this.actions$.pipe(
    ofType(MapStopTimeActionTypes.GET_MAP_STOP_TIME),
    map((action: MapActions.GetMapStopTime) => {
      this._socketGateway.sendSocketMessage(
        new GetMapStopTimeMethod(action.trip_id, 0)
      );
    })
  );

  @Effect({ dispatch: false })
  setMapStopsTimes = this.actions$.pipe(
    ofType(MapStopTimeActionTypes.SET_MAP_STOP_TIMES),
    map((action: MapActions.SetMapStopTimes) => {
      this._socketGateway.sendSocketMessage(
        new SetMapStopTimesMethod(action.tripID, action.MapStopTimes, 0)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>
  ) {}
}
