import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxBarcodeModule } from 'ngx-barcode';

import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { PreviewsModule } from 'src/app/previews/previews.module';
import { EmojiboxModule } from 'src/app/emojibox/emojibox.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';

import { TagsPoolModule } from '../tags-pool/tags-pool.module';
import { SubChatsPoolModule } from '../sub-chats-pool/sub-chats-pool.module';
import { MenusPoolModule } from '../menus-pool/menus-pool.module';
import { CouponsPoolModule } from '../coupons-pool/coupons-pool.module';
import { CalendarsPoolModule } from '../calendars-pool/calendars-pool.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

import { CouponFormModule } from '../coupons/coupon-form.module';
import { InstantArticleModule } from '../instant-article/instant-article.module';
import { ComposeMessageModalComponent } from './compose-message-modal/compose-message-modal.component';
import { ComposeScheduleInputComponent } from './compose-schedule-input/compose-schedule-input.component';
import { ComposeAttachsPreviewComponent } from './compose-attachs-preview/compose-attachs-preview.component';
import { ComposeUrlInputComponent } from './compose-url-input/compose-url-input.component';
import { ComposeStickyInputComponent } from './compose-sticky-input/compose-sticky-input.component';
import { ComposeTextInputComponent } from './compose-text-input/compose-text-input.component';
import { SelectMessageTypeComponent } from './select-message-type/select-message-type.component';
import { SelectMessageTargetsComponent } from './select-message-targets/select-message-targets.component';
import { SelectMessageAttachsComponent } from './select-message-attachs/select-message-attachs.component';
import { SelectMessageStyleComponent } from './select-message-style/select-message-style.component';
import { PreviewLinkComponent } from './preview-link/preview-link.component';
import { PreviewCalendarComponent } from './preview-calendar/preview-calendar.component';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { AbstractTooltip } from 'src/app/abstract-tooltip/abstract-tooltip.module';
import { AbstractIconsModule } from 'src/app/abstract-icons/abstract-icons.module';
import { MenuFormModule } from '../menus/menu-form/menu.form.module';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcodeModule,
    NgxModule,
    AbstractBtnsModule,
    CouponFormModule,
    AccessorsModule,
    InstantArticleModule,
    EmojiboxModule,
    SharedModule,
    PreviewsModule,
    TagsPoolModule,
    MenusPoolModule,
    CouponsPoolModule,
    AbstractFramesModule,
    AbstractControlsModule,
    CalendarsPoolModule,
    SubChatsPoolModule,
    AbstractTooltip,
    AbstractIconsModule,
    MenuFormModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    MatCheckboxModule
  ],
  declarations: [
    ComposeMessageModalComponent,
    ComposeTextInputComponent,
    ComposeScheduleInputComponent,
    ComposeUrlInputComponent,
    ComposeStickyInputComponent,
    ComposeAttachsPreviewComponent,
    SelectMessageTypeComponent,
    SelectMessageStyleComponent,
    SelectMessageTargetsComponent,
    SelectMessageAttachsComponent,
    PreviewLinkComponent,
    PreviewCalendarComponent
  ],
  exports: [ComposeMessageModalComponent]
})
export class ComposeMessageModule { }
