import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as BundleUiActions from './bundlesUI.actions';

import { AppState } from 'src/models/AppState';
import { Bundle, BundleProduct } from 'src/models/Bundle';
import { Product } from 'src/models/Product';
import { PayCashResponse } from 'src/models/PayCash';

@Injectable({
  providedIn: 'root'
})
export class BundlesUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createBundle(bundle: Bundle): void {
    this._store.dispatch(new BundleUiActions.CreateBundle(bundle));
  }

  setBundle(bundleID: string, bundle: Bundle): void {
    this._store.dispatch(new BundleUiActions.SetBundle(bundleID, bundle));
  }

  addBundleProducts(bundleID: string, bundleProducts: BundleProduct[]): void {
    this._store.dispatch(
      new BundleUiActions.AddBundleProducts(bundleID, bundleProducts)
    );
  }

  bundleProductInfoReceived(bundleID: string, product: Product): void {
    this._store.dispatch(
      new BundleUiActions.BundleProductInfoReceived(bundleID, product)
    );
  }

  removeBundleProducts(bundleID: string, productsIds: string[]): void {
    this._store.dispatch(
      new BundleUiActions.RemoveBundleProducts(bundleID, productsIds)
    );
  }

  bundleReceived(bundle: Bundle): void {
    this._store.dispatch(new BundleUiActions.BundleReceived(bundle));
  }

  // paging dispatchers
  getNextBundlesPage(): void {
    this._store.dispatch(new BundleUiActions.GetNextBundlesPage());
  }

  getPrevBundlesPage(): void {
    this._store.dispatch(new BundleUiActions.GetPrevBundlesPage());
  }

  receivedBundlesHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Bundle[]
  ): void {
    this._store.dispatch(
      new BundleUiActions.ReceivedBundlesHistoryChunk(eop, sop, currentPage)
    );
  }

  resetBundlesPaging(): void {
    this._store.dispatch(new BundleUiActions.ResetBundlesPaging());
  }

  deleteBundle(id: string): void {
    this._store.dispatch(new BundleUiActions.DeleteBundle(id));
  }

  bundleDeleted(id: string): void {
    this._store.dispatch(new BundleUiActions.BundleDeleted(id));
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new BundleUiActions.SetStatusAsIdle());
  }

  buyBundleOnBehalfOfUser(userId: string, bundle: Bundle) {
    this._store.dispatch(
      new BundleUiActions.BuyBundleOnBehalfOfUser(userId, bundle)
    );
  }

  cashPaymentStatus(payResult: PayCashResponse) {
    this._store.dispatch(new BundleUiActions.CashPaymentStatus(payResult));
  }
}
