/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../link-preview-cell/link-preview-cell.component.ngfactory";
import * as i3 from "../link-preview-cell/link-preview-cell.component";
import * as i4 from "../../shared/pipes/twemoji/twemoji.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../shared/pipes/multi-line/multi-line.pipe";
import * as i7 from "../../shared/pipes/deeb-links/deeb-links.pipe";
import * as i8 from "../../shared/pipes/keep-html/keep-html.pipe";
import * as i9 from "@angular/common";
import * as i10 from "./text-cell.component";
var styles_TextCellComponent = [i0.styles];
var RenderType_TextCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextCellComponent, data: {} });
export { RenderType_TextCellComponent as RenderType_TextCellComponent };
function View_TextCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-preview-cell", [["class", "link-preview wrap-text"]], null, null, null, i2.View_LinkPreviewCellComponent_0, i2.RenderType_LinkPreviewCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.LinkPreviewCellComponent, [], { url: [0, "url"], title: [1, "title"], description: [2, "description"], image: [3, "image"], width: [4, "width"], height: [5, "height"], rootUrl: [6, "rootUrl"], style: [7, "style"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.message == null) ? null : _co.message.linkPreviewUrl); var currVal_1 = ((_co.message == null) ? null : _co.message.linkPreviewTitle); var currVal_2 = ((_co.message == null) ? null : _co.message.linkPreviewDescription); var currVal_3 = ((_co.message == null) ? null : _co.message.linkPreviewImage); var currVal_4 = ((_co.message == null) ? null : _co.message.linkPreviewWidth); var currVal_5 = ((_co.message == null) ? null : _co.message.linkPreviewHeight); var currVal_6 = ((_co.message == null) ? null : _co.message.linkPreviewRootUrl); var currVal_7 = ((_co.message == null) ? null : _co.message.style); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_TextCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.TwemojiPipe, [i5.DomSanitizer]), i1.ɵpid(0, i6.MultiLinePipe, []), i1.ɵpid(0, i7.DeebLinksPipe, []), i1.ɵpid(0, i8.KeepHtmlPipe, []), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), i1.ɵppd(6, 1), i1.ɵppd(7, 1), i1.ɵppd(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextCellComponent_1)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.message == null) ? null : _co.message.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FETCHED); _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 2), i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 3), _co.text)))))))); _ck(_v, 4, 0, currVal_0); }); }
export function View_TextCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-cell", [], null, null, null, View_TextCellComponent_0, RenderType_TextCellComponent)), i1.ɵdid(1, 49152, null, 0, i10.TextCellComponent, [], null, null)], null, null); }
var TextCellComponentNgFactory = i1.ɵccf("app-text-cell", i10.TextCellComponent, View_TextCellComponent_Host_0, { text: "text", message: "message" }, {}, []);
export { TextCellComponentNgFactory as TextCellComponentNgFactory };
