import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
// import { AppClass } from 'src/models/ChannelAppConfig';
import { BotMenu } from 'src/models/IBotMenu';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';

@Component({
  selector: 'app-main-menu-view',
  templateUrl: './main-menu-view.component.html',
  styleUrls: ['./main-menu-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() menuForm: any;
  @Input() appMenus: BotMenu[];
  @Input() ios = false;
  @Input() mainMenu = false;
  @Input() selectedTab: any;
  @Input() tabBg: any;
  @Input() mobScreen = false;
  currentMenu: BotMenu;
  firstTab: any;
  selectedMenu: any;
  constructor(
    public appManagementService: AppMgmtService,
    public _ref: ChangeDetectorRef
  ) {}
  ngOnDestroy() {
    if (this.firstTab) {
      this.firstTab.unsubscribe();
    }
    if (this.selectedMenu) {
      this.selectedMenu.unsubscribe();
    }
  }
  ngOnChanges() {
    if (this.mainMenu) {
      this.appManagementService._selectedMenu.next(this.menuForm);
      this._ref.detectChanges();
    }
  }

  ngOnInit() {
    if (!this.menuForm || !this.menuForm.menu_id) {
      this.firstTab = this.appManagementService.effectiveSelectedTab$.subscribe(
        res => {
          if (res && res.menu_id) {
            const selectedMenu = this.appManagementService.getAppMenuById(
              res.menu_id
            );

            this.appManagementService._selectedMenu.next(selectedMenu);
          }
        }
      );
    }
  }

  get uploadedWorkFlowImage() {
    if (
      this.selectedTab &&
      this.selectedTab !== null &&
      this.selectedTab.tab_bg &&
      this.selectedTab.tab_bg.image
    ) {
      return {
        'background-image': 'url(' + this.selectedTab.tab_bg.image + ')',
        'background-size': '100% 100%'
      };
    } else if (
      this.selectedTab &&
      this.selectedTab !== null &&
      this.selectedTab.tab_bg &&
      this.selectedTab.tab_bg.start &&
      this.selectedTab.tab_bg.end &&
      this.selectedTab.tab_bg.start !== '' &&
      this.selectedTab.tab_bg.end !== ''
    ) {
      return {
        'background-image':
          'linear-gradient(' +
          this.selectedTab.tab_bg.start +
          ',' +
          this.selectedTab.tab_bg.end +
          ')'
      };
    } else if (
      this.selectedTab &&
      this.selectedTab !== null &&
      this.selectedTab.tab_bg &&
      this.selectedTab.tab_bg.start &&
      this.selectedTab.tab_bg.start !== ''
    ) {
      return { 'background-color': this.selectedTab.tab_bg.start };
    } else if (
      this.selectedTab &&
      this.selectedTab !== null &&
      this.selectedTab.tab_bg &&
      this.selectedTab.tab_bg.end &&
      this.selectedTab.tab_bg.end !== ''
    ) {
      return { 'background-color': this.selectedTab.tab_bg.end };
    }
  }
}
