import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  SimpleChanges,
  HostListener,
  ViewChild,
  ElementRef,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';

import { MainChat } from '../../../models/MainChat';
import { IMessage } from '../../../models/IMessage';
import { ChannelMember } from '../../../models/ChannelMember';
import { Observable, BehaviorSubject } from 'rxjs';
import { CHAT_PAGE_SIZE } from '../../../models/constants';
import { UiService } from '../../core/ui.service';

@Component({
  selector: 'app-channel-replies',
  templateUrl: './channel-replies.component.html',
  styleUrls: ['./channel-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelRepliesComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('ScrollContainer', { static: true }) scrollContainer: ElementRef;
  @ViewChild('messagesContainer', { static: true })
  messagesContainer: ElementRef;

  @ViewChild('postCell', { static: false }) postCell: ElementRef;

  @Input() message: IMessage;
  @Input() chat: MainChat;
  @Input() selected: boolean;
  @Input() isSubChat: boolean;
  @Input() hideMenu: boolean;
  @Input() isNotifications: boolean;
  @Input() hidePostCell = false;
  @Input() isScreenMessages = false;
  @Input() hideRepliesSec = true;

  @Output() postHightNotifier = new EventEmitter<number>();
  @Input() isPublicChannel: boolean;

  private pageNumber: BehaviorSubject<number> = new BehaviorSubject(1);
  private pageNumber$: Observable<number> = this.pageNumber.asObservable();
  private currentPageNumber: number;
  private paginationSubscribtion = null;
  private debounceTimer: number;
  private totalNumberOfPages = 1;
  private lastMessageElement = null;
  private historyMsgRcvdSubscription = null;
  private historyCounter = 0;
  private timeout;

  postHight: number;

  constructor(
    public uiService: UiService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.paginationSubscribtion = this.pageNumber$
      .pipe(distinctUntilChanged())
      .subscribe(pageNumber => {
        this.currentPageNumber = pageNumber;
        if (this.isSubChat) {
          this.uiService.setSubPostRepliesPageNumber(this.currentPageNumber);
        } else {
          this.uiService.setPostRepliesPageNumber(this.currentPageNumber);
        }
      });

    this.historyMsgRcvdSubscription = this.uiService.historyMsgRcvd$
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (
          res &&
          res.reply_to_message_id &&
          this.message &&
          res.reply_to_message_id === this.message.message_id &&
          res.level === 2
        ) {
          this.historyCounter++;
          if (this.historyCounter >= CHAT_PAGE_SIZE) {
            // if (!this.lastMessageElement) {
            //   this.lastMessageElement = this.messagesContainer.nativeElement.lastElementChild;
            // }
            this.historyCounter = 0;
            this.pageNumber.next(++this.currentPageNumber);
            if (
              this.scrollContainer.nativeElement.scrollHeight <
              document.body.scrollHeight
            ) {
              this.uiService.requestHistory(this.message.message_id);
            }
          }

          if (this.lastMessageElement) {
            setTimeout(
              () =>
                (<HTMLElement>this.lastMessageElement).scrollIntoView(false),
              2
            );
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.setPostHight();
    this.increasePageNumberIfNeeded();
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['message'];
    if (
      chng &&
      ((chng.currentValue && !chng.previousValue) ||
        (chng.currentValue &&
          chng.previousValue &&
          chng.currentValue.message_id !== chng.previousValue.message_id))
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.increasePageNumberIfNeeded();
      this.pageNumber.next(1);
      this.historyCounter = 0;
      this.lastMessageElement = null;
    } else if (
      chng &&
      chng.currentValue &&
      chng.previousValue &&
      chng.currentValue.message_id === chng.previousValue.message_id &&
      chng.currentValue.numberOfReplies !== chng.previousValue.numberOfReplies
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.increasePageNumberIfNeeded();
    }
  }

  ngOnDestroy(): void {
    if (this.paginationSubscribtion) {
      this.paginationSubscribtion.unsubscribe();
    }
    if (this.historyMsgRcvdSubscription) {
      this.historyMsgRcvdSubscription.unsubscribe();
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  increasePageNumberIfNeeded() {
    this.timeout = setTimeout(() => {
      if (
        this.scrollContainer.nativeElement.scrollHeight <
        document.body.scrollHeight
      ) {
        if (this.currentPageNumber < this.totalNumberOfPages) {
          this.pageNumber.next(++this.currentPageNumber);
          this.increasePageNumberIfNeeded();
        }
      }
    }, 10);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (this.debounceTimer) {
      window.clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = window.setTimeout(() => {
      this.scrollEventHandler();
    }, 66);
  }

  scrollEventHandler() {
    if (
      Math.abs(
        this.scrollContainer.nativeElement.scrollTop +
        this.scrollContainer.nativeElement.clientHeight -
        this.scrollContainer.nativeElement.scrollHeight
      ) < 1
    ) {
      this.onYEnd();
    }
  }

  onYEnd() {
    this.lastMessageElement = this.messagesContainer.nativeElement.lastElementChild;
    if (this.currentPageNumber < this.totalNumberOfPages) {
      this.pageNumber.next(++this.currentPageNumber);

      if (this.lastMessageElement) {
        setTimeout(
          () => (<HTMLElement>this.lastMessageElement).scrollIntoView(false),
          // (<HTMLElement>this.lastMessageElement).scrollIntoView({
          //
          //   block: 'center'
          // })
          5
        );
      }
    } else {
      this.uiService.requestHistory(this.message.message_id);
    }
  }

  TrackByFunction(index, member: ChannelMember) {
    if (member) {
      return member.profile.user_id;
    }
  }

  onLastElement(totalNumberOfPages: number) {
    if (totalNumberOfPages !== this.totalNumberOfPages) {
      this.totalNumberOfPages = totalNumberOfPages;
      this.changeDetector.markForCheck();
    }
  }

  get profilesRepliedToChannel$() {
    if (this.isSubChat) {
      return this.uiService.profilesWhoRepliedToSubChannelPost$;
    } else {
      return this.uiService.profilesWhoRepliedToChannelPost$;
    }
  }

  setPostHight() {
    if (this.postCell) {
      this.postHight =
        this.postCell.nativeElement.offsetHeight > 450
          ? this.postCell.nativeElement.offsetHeight
          : 450;
      this.postHightNotifier.emit(this.postCell.nativeElement.offsetHeight);
    }
  }
}
