import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ChatMemberActions from './chatMember.actions';

import { AppState } from 'src/models/AppState';
import { IProfile } from 'src/models/IProfile';
import { ChatMember, IChatMember } from 'src/models/IChatMember';

@Injectable({
  providedIn: 'root'
})
export class ChatMemberDispatchers {
  constructor(private _store: Store<AppState>) {}

  // listChatMembers(chat_id) {
  //   this._store.dispatch(new ChatMemberActions.ListChatMembers(chat_id));
  // }
  // listChatAdmins() {
  //   this._store.dispatch(new ChatMemberActions.ListChatAdmins());
  // }

  getChatMember(payload: IProfile) {
    this._store.dispatch(new ChatMemberActions.GetChatMember(payload));
  }

  chatMemberUpdated(payload: IProfile) {
    this._store.dispatch(new ChatMemberActions.ChatMemberUpdated(payload));
  }

  // Request and recive Chat Member Details
  chatMembersReceived(payload: ChatMember[]) {
    this._store.dispatch(new ChatMemberActions.ChatMembersReceived(payload));
  }
  chatMemberReceiving(payload: ChatMember) {
    this._store.dispatch(new ChatMemberActions.ChatMemberReceiving(payload));
  }
  chatMemberReceived(payload: IChatMember) {
    this._store.dispatch(new ChatMemberActions.ChatMemberReceived(payload));
  }

  // Get Specific member Chats
  getMemberChats(payload: string) {
    this._store.dispatch(new ChatMemberActions.GetMemberChats(payload));
  }
  getMemberList(payload: any[]) {
    this._store.dispatch(new ChatMemberActions.GetMemberList(payload));
  }
  myMemberChatsReceived(payload: { user_id: string; members: ChatMember[] }) {
    this._store.dispatch(new ChatMemberActions.MyMemberChatsReceived(payload));
  }

  // Controls
  promoteChatMember(userId: string) {
    this._store.dispatch(new ChatMemberActions.PromoteChatMember(userId));
  }
  addMemberToChat(chatId: string, userId: string, recordIndex?: number) {
    this._store.dispatch(
      new ChatMemberActions.AddMemberToChat({
        chat_id: chatId,
        user_id: userId,
        recordIndex
      })
    );
  }
  addAdminToChat(chatId: string, userId: string) {
    this._store.dispatch(
      new ChatMemberActions.AddAdminToChat({ chat_id: chatId, user_id: userId })
    );
  }
  revokeMemberFromChat(chatId: string, userId: string) {
    this._store.dispatch(
      new ChatMemberActions.RevokeMemberFromChat({
        chat_id: chatId,
        user_id: userId
      })
    );
  }
  revokeAdminFromChat(chatId: string, userId: string) {
    this._store.dispatch(
      new ChatMemberActions.RevokeAdminFromChat({
        chat_id: chatId,
        user_id: userId
      })
    );
  }

  setChatMemberRole(chatId: string, userId: string, role: string) {
    this._store.dispatch(
      new ChatMemberActions.SetChatMemberRole({
        chat_id: chatId,
        user_id: userId,
        role
      })
    );
  }

  setChatMemberView(userId: string, view: number) {
    this._store.dispatch(
      new ChatMemberActions.SetChatMemberView({ user_id: userId, view })
    );
  }

  setMemberTesterFlag(userId: string, tester: number) {
    this._store.dispatch(
      new ChatMemberActions.SetMemberTesterFlag({ user_id: userId, tester })
    );
  }

  removeOrBanUserSuccess(ID: string) {
    this._store.dispatch(new ChatMemberActions.RemoveOrBanUserSuccess(ID));
  }

  removeChatFromMemberSuccess(chatId: string) {
    this._store.dispatch(
      new ChatMemberActions.RemoveChatFromMemberSuccess(chatId)
    );
  }
}
