import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MobSystemNamePipe } from './mob-system-name.pipe';
import { MobSystemFileTypePipe } from './mob-system-file-type.pipe';
import { MobSystemIconComponent } from './mob-system-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MobSystemNamePipe,
    MobSystemFileTypePipe,
    MobSystemIconComponent
  ],
  exports: [MobSystemNamePipe, MobSystemFileTypePipe, MobSystemIconComponent]
})
export class AppStatusModule {}
