<a
  *ngIf="isDownloadable"
  class="action-btn"
  [href]="media"
  [download]="message?.media_id"
>
  <img src="./assets/img/ic-download-grey.svg" alt="download" />
</a>
<!-- if i send it or if i am admin -->

<a
  *ngIf="message?.loggedInIsSender || isAdmin"
  class="action-btn"
  (click)="recallEvent()"
>
  <svg class="svg-icon icon-icon clickable-cursor">
    <use xlink:href="./assets/svg/app-sections.svg#icon-delete"></use>
  </svg>
</a>
<a
  *ngIf="uiService.getEnableSetTemplateBtn$ | async"
  class="action-btn"
  (click)="setTemplate()"
>
  Tem
</a>

<!-- if i send it only -->
<!--
  <a *ngIf="message?.loggedInIsSender"
    class="action-btn">
    <svg class="svg-icon icon-icon clickable-cursor">
      <use xlink:href="./assets/svg/app-sections.svg#icon-edit"></use>
    </svg>
  </a>
-->
<!-- if i am admin only -->
<!--
  <a *ngIf="isAdmin"
    class="action-btn">
    <img src="./assets/img/ic-repost-grey.svg"
      alt="repost">
  </a>
-->
