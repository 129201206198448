import { Action } from '@ngrx/store';
import {
  LocalAppConfig,
  LocalItem,
  LocalComponent
} from 'src/models/ChannelAppLocalConfig';

export const enum SubItemActionTypes {
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  UPDATE_SUB_ITEM = 'UPDATE_SUB_ITEM',
  UPDATE_SUB_ITEM_LINK = 'UPDATE_SUB_ITEM_LINK',
  REORDER_SUB_ITEM = 'REORDER_SUB_ITEM',
  DELETE_SUB_ITEM = 'DELETE_SUB_ITEM',
  DELETE_SUB_ITEM_REQUEST = 'DELETE_SUB_ITEM_REQUEST',
  ADD_SUB_ITEM_REQUEST = 'ADD_SUB_ITEM_REQUEST',
  ADD_SUB_ITEM = 'ADD_SUB_ITEM',
  DELETE_SUB_ITEMS_OF_COMPONENT = 'DELETE_SUB_ITEMS_OF_COMPONENT',
  ADD_SUB_ITEMS = 'ADD_SUB_ITEMS',
  RESET = 'RESET'
}

export class AppConfigDataChanged implements Action {
  readonly type = SubItemActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class UpdateSubItem implements Action {
  readonly type = SubItemActionTypes.UPDATE_SUB_ITEM;
  constructor(public itemRef: string, public itemUpdatedProps: LocalItem) {}
}

export class UpdateSubItemLink implements Action {
  readonly type = SubItemActionTypes.UPDATE_SUB_ITEM_LINK;
  constructor(
    public itemRef: string,
    public itemLink: string,
    public pageId: string
  ) {}
}

export class ReorderSubItem implements Action {
  readonly type = SubItemActionTypes.REORDER_SUB_ITEM;
  constructor(public itemRef: string, public newPosition: number) {}
}

export class DeleteSubItemRequest implements Action {
  readonly type = SubItemActionTypes.DELETE_SUB_ITEM_REQUEST;
  constructor(public item: LocalItem) {}
}

export class DeleteSubItem implements Action {
  readonly type = SubItemActionTypes.DELETE_SUB_ITEM;
  constructor(public item: LocalItem) {}
}

export class AddSubItemRequest implements Action {
  readonly type = SubItemActionTypes.ADD_SUB_ITEM_REQUEST;
  constructor(
    public component: LocalComponent,
    public specialItemValues?: LocalItem
  ) {}
}

export class AddSubItem implements Action {
  readonly type = SubItemActionTypes.ADD_SUB_ITEM;
  constructor(public item: LocalItem) {}
}

export class DeleteSubItemsOfComponent implements Action {
  readonly type = SubItemActionTypes.DELETE_SUB_ITEMS_OF_COMPONENT;
  constructor(public component: LocalComponent) {}
}

export class AddSubItems implements Action {
  readonly type = SubItemActionTypes.ADD_SUB_ITEMS;
  constructor(public items: LocalItem[]) {}
}

export class Reset implements Action {
  readonly type = SubItemActionTypes.RESET;
}

export type Actions =
  | AppConfigDataChanged
  | UpdateSubItem
  | UpdateSubItemLink
  | ReorderSubItem
  | DeleteSubItemRequest
  | DeleteSubItem
  | AddSubItemRequest
  | AddSubItem
  | DeleteSubItemsOfComponent
  | AddSubItems
  | Reset;
