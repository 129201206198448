import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { MapTripUserActionTypes } from './mapTripUser.actions';
import * as MapActions from './mapTripUser.actions';
import * as Methods from 'src/models/MapTripUser';
import { MapTripUser } from 'src/models/MapTripUser';
import { MapTripUserDispatchers } from './mapTripUser.dispatchers';
import { AppState } from 'src/models/AppState';
import { Store } from '@ngrx/store';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class MapTripUserEffects {
  @Effect({ dispatch: false })
  resetMapTripUsersPaging = this.actions$.pipe(
    ofType(MapTripUserActionTypes.RESET_MAP_TRIP_USERS_PAGING),
    map((action: MapActions.CreateMapTripUser) => {
      this._mapTripUserDispatchers.getNextMapTripUsersPage();
    })
  );

  @Effect({ dispatch: false })
  getNextMapServicePage = this.actions$.pipe(
    ofType(MapTripUserActionTypes.GET_NEXT_MAP_TRIP_USERS_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripUserReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsUserUiState]) => {
      const action = <MapActions.GetNextMapTripUsersPage>val;
      const prevRequest = mapTripsUserUiState.previousRequest;
      if (
        !(
          mapTripsUserUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapTripsUserUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTripUsers(
            0,
            mapTripsUserUiState.eop,
            mapTripsUserUiState.hash,
            mapTripsUserUiState.tripID
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMapServicePage = this.actions$.pipe(
    ofType(MapTripUserActionTypes.GET_PREV_MAP_TRIP_USERS_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripUserReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsUserUiState]) => {
      const action = <MapActions.GetPrevMapTripUsersPage>val;
      const prevRequest = mapTripsUserUiState.previousRequest;
      if (
        !(
          mapTripsUserUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapTripsUserUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTripUsers(
            1,
            mapTripsUserUiState.sop,
            mapTripsUserUiState.hash,
            mapTripsUserUiState.tripID
          )
        );
      }
    })
  );
  @Effect({ dispatch: false })
  createMapTripUser = this.actions$.pipe(
    ofType(MapTripUserActionTypes.CREATE_MAP_TRIP_USER),
    map((action: MapActions.CreateMapTripUser) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapTripUser(action.mapTripUser)
      );
    })
  );

  @Effect({ dispatch: false })
  updateMapTripUser = this.actions$.pipe(
    ofType(MapTripUserActionTypes.UPDATE_MAP_TRIP_USER),
    map((action: MapActions.UpdateMapTripUser) => {
      const mapTripUser: MapTripUser = {
        ...action.mapTripUserDetails,
        id: action.id
      };
      this._socketGateway.sendSocketMessage(
        new Methods.SetMapTripUser(mapTripUser)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMapTripUser = this.actions$.pipe(
    ofType(MapTripUserActionTypes.DELETE_MAP_TRIP_USER),
    map((action: MapActions.DeleteMapTripUser) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveMapTripUser(action.id, action.trip_id)
      );
    })
  );

  /** No need for the following effect because of all the data comes with the list */

  // @Effect({ dispatch: false })
  // listReceived = this.actions$.pipe(
  //   ofType(MapTripUserActionTypes.MAP_TRIP_USER_LIST_RECEIVED),
  //   map((action: MapActions.MapTripUserListReceived) => {
  //     if (action.mapTripUsers && action.mapTripUsers.length > 0) {
  //       action.mapTripUsers.forEach(mapTripUser => {
  //         this._socketGateway.sendSocketMessage(
  //           new Methods.GetMapTripUser(mapTripUser.id)
  //         );
  //       });
  //     }
  //   })
  // );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _mapTripUserDispatchers: MapTripUserDispatchers,
    private _store: Store<AppState>
  ) {}
}
