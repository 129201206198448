import { Action } from '@ngrx/store';
import { MapTripsHistoryOpj } from 'src/models/MapTripsHistory';

export const enum MapTripsHistoryTypes {
  RESET_MAP_TRIPS_HISTORY_PAGING = 'RESET_MAP_TRIPS_HISTORY_PAGING',
  GET_NEXT_MAP_TRIPS_HISTORY_PAGE = 'GET_NEXT_MAP_TRIPS_HISTORY_PAGE',
  GET_PREV_MAP_TRIPS_HISTORY_PAGE = 'GET_PREV_MAP_TRIPS_HISTORY_PAGE',
  MAP_TRIP_HISTORY_LIST_RECEIVED = 'MAP_TRIP_HISTORY_LIST_RECEIVED',
  UI_MAP_TRIP_HISTORY_SET_IDLE_STATUS = 'UI_MAP_TRIP_HISTORY_SET_IDLE_STATUS'
}

export class ResetMapTripHistoryPaging implements Action {
  readonly type = MapTripsHistoryTypes.RESET_MAP_TRIPS_HISTORY_PAGING;
  constructor(public startDate?: string, public endDate?: string) {}
}

export class GetNextMapTripsHistoryPage implements Action {
  readonly type = MapTripsHistoryTypes.GET_NEXT_MAP_TRIPS_HISTORY_PAGE;
  constructor() {}
}

export class GetPrevMapTripsHistoryPage implements Action {
  readonly type = MapTripsHistoryTypes.GET_PREV_MAP_TRIPS_HISTORY_PAGE;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = MapTripsHistoryTypes.UI_MAP_TRIP_HISTORY_SET_IDLE_STATUS;
  constructor() {}
}

export class MapTripHistoryListReceived implements Action {
  readonly type = MapTripsHistoryTypes.MAP_TRIP_HISTORY_LIST_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public mapTripsHistory: MapTripsHistoryOpj[],
    public hash: string
  ) {}
}

export type Actions =
  | ResetMapTripHistoryPaging
  | GetNextMapTripsHistoryPage
  | GetPrevMapTripsHistoryPage
  | MapTripHistoryListReceived
  | SetStatusAsIdle;
