import { Actions } from './appMenu.actions';
import * as actions from './appMenu.actions';
import { AppMenu } from 'src/models/AppMenu';

const INITIAL_STATE = [];

export function appMenuReducer(
  state: AppMenu[] = INITIAL_STATE,
  action: Actions
): AppMenu[] {
  switch (action.type) {
    case actions.AppMenuActionTypes.RECEIVE_APP_MENUS: {
      return action.appMenus;
    }
    case actions.AppMenuActionTypes.APP_MENU_DETAILS_RECEIVED: {
      return state.map(menu => {
        if (menu.id === action.appMenu.id) {
          let updatedMenu: AppMenu = {};
          updatedMenu = { ...menu, ...action.appMenu };
          return updatedMenu;
        }
        return menu;
      });
    }
    case actions.AppMenuActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
