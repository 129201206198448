import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'app-delete-btn',
  template: `
    <app-circle-btn delete (btnClicked)="btnEvent()" [frame]="frame">
      <app-delete-icon *ngIf="!frame"></app-delete-icon>
      <app-delete-frame-icon *ngIf="frame"></app-delete-frame-icon>
    </app-circle-btn>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBtnComponent {
  @Output() btnClicked = new EventEmitter<boolean>();
  @Input() disabled: boolean;
  @Input() frame = false;
  btnEvent() {
    this.btnClicked.emit(true);
  }
}
