import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { GetSmtpMethod, SetSmtpMethod } from 'src/models/Smtp';
export class SmtpEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.setStmp = this.actions$.pipe(ofType("SET_SMTP" /* SET_SMTP */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetSmtpMethod(action.smtp));
        }));
        this.getPaymentProviders = this.actions$.pipe(ofType("GET_SMTP" /* GET_SMTP */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetSmtpMethod());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SmtpEffects.prototype, "setStmp", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SmtpEffects.prototype, "getPaymentProviders", void 0);
