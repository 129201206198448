import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { RootStoreService } from 'src/app/store/root-store.service';
import { AuthStatus, UISections } from 'src/models/constants';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { StorageService } from 'src/app/core/storage.service';
import { AppConfigDispatchers } from 'src/app/store/channelAppConfig/appConfig.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./socket.service";
import * as i3 from "../../store/root-store.service";
import * as i4 from "@angular/common";
import * as i5 from "../../+merchant/+dashboard/dashboard.service";
import * as i6 from "../../core/storage.service";
import * as i7 from "../../store/channelAppConfig/appConfig.dispatchers";
export class SocketHandlerService {
    constructor(_router, _socketService, _rootStoreService, location, dashboardService, route, localStorage, _appConfigDispatchers) {
        this._router = _router;
        this._socketService = _socketService;
        this._rootStoreService = _rootStoreService;
        this.location = location;
        this.dashboardService = dashboardService;
        this.route = route;
        this.localStorage = localStorage;
        this._appConfigDispatchers = _appConfigDispatchers;
        this.webSocketSubscription = null;
        this.webSocketSubscription = this._socketService.webSocketObservable$
            .pipe(distinctUntilChanged())
            .subscribe(skObj => {
            if (skObj) {
                skObj.onmessage = (evt) => this.onSocketMessage(evt.data);
            }
        });
        this._socketService.authStore$
            .pipe(distinctUntilChanged((p, q) => p.authStatus === q.authStatus))
            .subscribe(state => {
            const urlInfo = this.location.path();
            const path = urlInfo.split('?');
            switch (state.authStatus) {
                case AuthStatus.AUTH_QR_SET:
                    this.route.queryParams.subscribe(params => {
                        if (params.lmref && params.lmref !== 'undefined') {
                            this.localStorage.setRecord('lmref', params.lmref);
                        }
                        // if (params.id && params.id !== 'undefined') {
                        //   this.localStorage.setRecord('tempId', params.id);
                        // }
                    });
                    if (path[0] !== '/login' &&
                        path[0] !== '/signup' &&
                        path[0] !== '/spt') {
                        this._router.navigate(['/login'], {
                            queryParamsHandling: 'merge'
                        });
                    }
                    break;
                case AuthStatus.AUTH_CHAT_SELECTED:
                    const url = this.location.path();
                    const urlPath = url.split('?');
                    if (state.role == 1 &&
                        state.chatId === state.main_group_id &&
                        urlPath[0] !== '/signup' &&
                        urlPath[0] !== '/verifyCode') {
                        this._router.navigate(['/app']);
                        this.dashboardService.setSection(UISections.APP);
                    }
                    break;
            }
        });
    }
    onSocketMessage(dataOnSocket) {
        this._socketService.setPingTimer();
        let data = '';
        if (environment.encodeSocket) {
            data = this.convertArrayBufferToMessage(dataOnSocket);
        }
        else {
            data = dataOnSocket;
        }
        this._rootStoreService.redirectMessage(JSON.parse(data));
    }
    convertArrayBufferToMessage(byteArray) {
        return new window.TextDecoder().decode(byteArray);
    }
}
SocketHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketHandlerService_Factory() { return new SocketHandlerService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SocketService), i0.ɵɵinject(i3.RootStoreService), i0.ɵɵinject(i4.Location), i0.ɵɵinject(i5.DashboardService), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i6.StorageService), i0.ɵɵinject(i7.AppConfigDispatchers)); }, token: SocketHandlerService, providedIn: "root" });
