import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as MyBookingsActions from './myBookings.actions';
import { map } from 'rxjs/operators';
import {
  GetMyBookings,
  GetMyBookingDays,
  CancelBookingReservation
} from 'src/models/MyBookings';
import { GetMyMember } from 'src/models/IChatMember';
import { MY_BOOKING_USERS_REF } from 'src/models/constants';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';

@Injectable()
export class MyBookingsEffects {
  @Effect({ dispatch: false })
  requestMyBookings = this.actions$.pipe(
    ofType(MyBookingsActions.MyBookingsActionTypes.REQUEST_MY_BOOKINGS),
    map((action: MyBookingsActions.RequestMyBookings) => {
      this._socketGateway.sendSocketMessage(
        new GetMyBookings(action.chatID, action.date, action.date)
      );
    })
  );

  @Effect({ dispatch: false })
  requestMyBookingDays = this.actions$.pipe(
    ofType(MyBookingsActions.MyBookingsActionTypes.REQUEST_MY_BOOKING_DAYS),
    map((action: MyBookingsActions.RequestMyBookingDays) => {
      this._socketGateway.sendSocketMessage(
        new GetMyBookingDays(action.chatID, action.startDate, action.endDate)
      );
    })
  );

  @Effect({ dispatch: false })
  cancelReservation = this.actions$.pipe(
    ofType(
      MyBookingsActions.MyBookingsActionTypes.CANCEL_MY_BOOKING_RESERVATION
    ),
    map((action: MyBookingsActions.CancelMyBookingReservation) => {
      this._socketGateway.sendSocketMessage(
        new CancelBookingReservation(
          action.chat_id,
          action.user_id,
          [action.reference],
          action.date,
          action.time
        )
      );
    })
  );

  @Effect({ dispatch: false })
  afterReceivingMyBookings = this.actions$.pipe(
    ofType(MyBookingsActions.MyBookingsActionTypes.RECEIVE_MY_BOOKINGS),
    map((action: MyBookingsActions.ReceiveMyBookings) => {
      if (
        action.myBookings &&
        action.myBookings.data &&
        action.myBookings.data.length > 0
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMyMember(
            action.myBookings.data.map(slot => slot.user_id),
            MY_BOOKING_USERS_REF
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  receiveError = this.actions$.pipe(
    ofType(MyBookingsActions.MyBookingsActionTypes.RECEIVED_MY_BOOKINGS_ERROR),
    map((action: MyBookingsActions.ReceivedMyBookingsError) => {
      this._uiDispatchers.showPopup(
        SERVER_ERROR_MESSAGES[action.errorNumber],
        'Cannot cancel'
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _uiDispatchers: UIDispatchers,
    private _socketGateway: SocketGateway
  ) {}
}
