import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import { WhitelistUIActionTypes } from './whitelistMainUI.actions';
import * as WhitelistUIActions from './whitelistMainUI.actions';

import * as isEqual from 'lodash.isequal';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

import { PageDirection } from 'src/models/constants';
import * as Whitelist from 'src/models/Whitelist';
import { WhitelistUiDispatchers } from './whitelistMainUI.dispatchers';

@Injectable()
export class WhitelistUIEffects {
  @Effect({ dispatch: false })
  afterWhitelistResetPaging = this.actions$.pipe(
    ofType(WhitelistUIActionTypes.UI_WHITELIST_RESET_PAGING),
    map((action: WhitelistUIActions.ResetWhitelistPaging) => {
      this._whitelistUiDispatchers.getNextWhitelistPage();
    })
  );

  @Effect({ dispatch: false })
  getNextWhitelistPage = this.actions$.pipe(
    ofType(WhitelistUIActionTypes.UI_GET_NEXT_WHITELIST_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.whitelistUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, whitelistUiState]) => {
      const action = <WhitelistUIActions.GetNextWhitelistPage>val;
      const prevRequest = whitelistUiState.previousRequest;
      if (
        !(
          prevRequest.eop === whitelistUiState.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          prevRequest.hash === whitelistUiState.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Whitelist.GetWhitelistMethod(
            null,
            0,
            whitelistUiState.hash,
            whitelistUiState.eop,
            whitelistUiState.msisdn
          )
        );
      } else {
        this._whitelistUiDispatchers.setStatusAsIdle();
      }
    })
  );
  @Effect({ dispatch: false })
  getPrevWhitelistPage = this.actions$.pipe(
    ofType(WhitelistUIActionTypes.UI_GET_PREV_WHITELIST_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.whitelistUIReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, whitelistUiState]) => {
      const action = <WhitelistUIActions.GetNextWhitelistPage>val;
      const prevRequest = whitelistUiState.previousRequest;
      if (
        !(
          prevRequest.sop === whitelistUiState.sop &&
          PageDirection.PREV === prevRequest.direction &&
          prevRequest.hash === whitelistUiState.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Whitelist.GetWhitelistMethod(
            null,
            1,
            whitelistUiState.hash,
            whitelistUiState.sop,
            whitelistUiState.msisdn
          )
        );
      } else {
        this._whitelistUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  addUserToWhitelist = this.actions$.pipe(
    ofType(WhitelistUIActionTypes.UI_ADD_USER_TO_WHITELIST),
    map((action: WhitelistUIActions.AddSignupIDToWhitelist) => {
      this._socketGateway.sendSocketMessage(
        new Whitelist.AddUsersToWhitelist(action.chat_id, action.users)
      );
    })
  );
  @Effect({ dispatch: false })
  deleteUserFromWhitelist = this.actions$.pipe(
    ofType(WhitelistUIActionTypes.UI_DELETE_USER_FROM_WHITELIST),
    map((action: WhitelistUIActions.DeleteUsersFromWhitelist) => {
      this._socketGateway.sendSocketMessage(
        new Whitelist.RemoveUserFromWhitelist(action.users, action.chat_id)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _whitelistUiDispatchers: WhitelistUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
