import { Action } from '@ngrx/store/public_api';
import { IChat } from 'src/models/IChat';

export const enum AllGroupActionTypes {
  UI_RECEIVE_ALL_GROUP = 'UI_RECEIVE_ALL_GROUP',
  UI_SET_LOADER_STATUS = 'UI_SET_LOADER_STATUS',
  RESET = 'RESET'
}

export class ReceiveAllGroup implements Action {
  readonly type = AllGroupActionTypes.UI_RECEIVE_ALL_GROUP;
  constructor(public chatGroups: IChat[]) {}
}

export class SetLoaderStatus implements Action {
  readonly type = AllGroupActionTypes.UI_SET_LOADER_STATUS;
  constructor(public status: string) {}
}

export class Reset implements Action {
  readonly type = AllGroupActionTypes.RESET;
}

export type Actions = ReceiveAllGroup | SetLoaderStatus | Reset;
