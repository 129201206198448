import * as MenuUIActions from './menuUI.actions';

import {
  SCREEN_STATUS,
  CouponScreens,
  ListRequestDetails,
  PageDirection
} from 'src/models/constants';
import { BotMenuUI } from 'src/models/BotMenuUI';
import { MenuUIMiddleware } from './menuUI.middleware';
import { BotMenu, BotMenuRow, BotMenuButton } from 'src/models/IBotMenu';
import { _INITIAL_STATE } from '@ngrx/store/src/tokens';

const INITIAL_STATE: BotMenuUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function menuUiReducer(
  state = INITIAL_STATE,
  action: MenuUIActions.Actions
): BotMenuUI {
  switch (action.type) {
    case MenuUIActions.MenuUIActionTypes.UI_MENU_MENU_RECEIVED:
    case MenuUIActions.MenuUIActionTypes.UI_MENU_UPDATE_MENU: {
      if (!MenuUIMiddleware.isMenuExist(state.currentPage, action.payload)) {
        return {
          ...state,
          currentPage: [action.payload, ...state.currentPage]
        };
      }
      return {
        ...state,
        currentPage: state.currentPage.map(menu => {
          if (
            (menu.id && menu.id === action.payload.id) ||
            (menu.reference && menu.reference === action.payload.reference) ||
            (menu.menu_id && menu.menu_id === action.payload.menu_id)
          ) {
            let updatedRows: BotMenuRow[] = [];
            updatedRows = action.payload.rows.map(row => {
              const updatedButtons: BotMenuButton[] = row.buttons.map(
                button => button
              );
              const updatedRow: BotMenuRow = {};
              updatedRow.row_id = row.row_id;
              updatedRow.row_order = row.row_order;
              updatedRow.buttons = updatedButtons;
              return updatedRow;
            });
            const updatedMenu: BotMenu = {};
            updatedMenu.id = action.payload.id;
            updatedMenu.reference = action.payload.reference;
            updatedMenu.menu_id = menu.menu_id;
            updatedMenu.menu_ref = action.payload.menu_ref;
            updatedMenu.menu_date = action.payload.menu_date;
            updatedMenu.menu_name = action.payload.menu_name;
            updatedMenu.next_menu = action.payload.next_menu;
            updatedMenu.rows = updatedRows;
            return updatedMenu;
          }
          return menu;
        })
      };
    }
    case MenuUIActions.MenuUIActionTypes.UI_MENU_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case MenuUIActions.MenuUIActionTypes.UI_MENU_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case MenuUIActions.MenuUIActionTypes.UI_MENU_MENUS_RECEIVING: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      const currentPage = action.payload.mymenus.map(botMenu => {
        const localMenu = botMenu.sub_menu;
        localMenu.id = botMenu.id;
        return localMenu;
      });
      return {
        ...state,
        previousRequest: prevRequest,
        currentPage:
          (currentPage && currentPage.length) > 0
            ? currentPage
            : state.currentPage,
        eop: action.payload.eop ? action.payload.eop : state.eop,
        sop: action.payload.sop ? action.payload.sop : state.sop,
        status: SCREEN_STATUS.IDLE
      };
    }
    case MenuUIActions.MenuUIActionTypes.UI_MENU_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case MenuUIActions.MenuUIActionTypes.UI_MENU_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case MenuUIActions.DESELECT_CHANNEL:
    case MenuUIActions.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
