import { Store } from '@ngrx/store';
import * as CampaignActions from './campaign.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CampaignDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    campaignReceived(payload) {
        this._store.dispatch(new CampaignActions.CampaignReceived(payload));
    }
    requestScopeMessage(payload) {
        this._store.dispatch(new CampaignActions.RequestScopeMessage(payload));
    }
    createCampaign(payload) {
        this._store.dispatch(new CampaignActions.CreateCampaign(payload));
    }
    updateCampaign(payload) {
        this._store.dispatch(new CampaignActions.UpdateCampaign(payload));
    }
}
CampaignDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignDispatchers_Factory() { return new CampaignDispatchers(i0.ɵɵinject(i1.Store)); }, token: CampaignDispatchers, providedIn: "root" });
