import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
// import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { SubChannelUiDispatchers } from 'src/app/store/subChannelsUI/subChannelsUI.dispatchers';
import { SubChatsService } from '../sub-chats/sub-chats.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/subChannelsUI/subChannelsUI.dispatchers";
import * as i3 from "../sub-chats/sub-chats.service";
export class MyChannelService {
    constructor(_rootSelector, _subChannelUiDispatchers, subgroupService) {
        this._rootSelector = _rootSelector;
        this._subChannelUiDispatchers = _subChannelUiDispatchers;
        this.subgroupService = subgroupService;
        this.selected = null;
        // this.selected = this.selectedSubGroup$.subscribe(res => {
        //   if (res) {
        //     subgroupService.getChatAdministrators(res.id);
        //   }
        // });
    }
    get selectedSubGroup$() {
        return this._rootSelector.getEffectiveSelectedIChat$();
    }
    uploadSubGroupImage(file) {
        this._subChannelUiDispatchers.uploadSubChannelImage(file);
    }
}
MyChannelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MyChannelService_Factory() { return new MyChannelService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.SubChannelUiDispatchers), i0.ɵɵinject(i3.SubChatsService)); }, token: MyChannelService, providedIn: "root" });
