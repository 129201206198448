import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ProductsStoreDispatchers } from './product-store.dispatchers';
import { AppState } from 'src/models/AppState';
import { ProductsStoreUIActionTypes } from './product-store.actions';
import * as ProductsStoreUiActions from './product-store.actions';

import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import {
  GetProductItemMethod,
  GetProductStoreMethod,
  RemoveProductItemMethod,
  SetCollectionsToProductMethod,
  SetProductItemArray,
  SetProductStoreMethod
} from 'src/models/productStore';
import { Router } from '@angular/router';
import { ProductsStoreService } from 'src/app/+merchant/+dashboard/product/products-store.service';

@Injectable()
export class ProductsStoreUiEffects {
  @Effect({ dispatch: false })
  productsStoreResetPaging = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_RESET_PAGING),
    map((action: ProductsStoreUiActions.ResetProductsStoreUIPaging) => {
      this._uiProductsStoreDispatchers.getNextProductsStorePage();
    })
  );

  @Effect({ dispatch: false })
  getNextproductsStorePage = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.productsStoreUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, productsUiState]) => {
      const action = <ProductsStoreUiActions.GetNextProductsStorePage>val;
      const prevRequest = productsUiState.previousRequest;
      if (
        !(
          productsUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          productsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetProductStoreMethod(0, productsUiState.eop)
        );
      } else {
        this._uiProductsStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevProductsPage = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.productsStoreUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, productsUiState]) => {
      const action = <ProductsStoreUiActions.GetPrevProductsStorePage>val;
      const prevRequest = productsUiState.previousRequest;
      if (
        !(
          productsUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          productsUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetProductStoreMethod(1, productsUiState.sop)
        );
      } else {
        this._uiProductsStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  updateProduct = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_SET_PRODUCTS_STORE),
    map((action: ProductsStoreUiActions.SetProductStoreItem) => {
      this._socketGateway.sendSocketMessage(
        new SetProductStoreMethod(
          action.product.id,
          action.product.name,
          action.product.description,
          9,
          action.product.type,
          action.product.price,
          action.product.image,
          action.product.sku,
          action.product.status,
          action.product.compare_at_price,
          action.product.option,
          action.product.attribute,
          action.product.tag
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getProduct = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_GET_PRODUCT_ITEM),
    map((action: ProductsStoreUiActions.GetProductItem) => {
      this._socketGateway.sendSocketMessage(
        new GetProductItemMethod(action.id)
      );
    })
  );
  @Effect({ dispatch: false })
  receivedProductStore = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_RECEIVE_PRODUCT_STORE_ITEM),
    map((action: ProductsStoreUiActions.ReceivedProductStore) => {
      this._uiProductsStoreDispatchers.setSelectedProductStore(action.product);
      if (action.product && action.product.option && action.product.option.length === 3) {
        this._productsStoreService._hideOptionBtn.next(true);
      }
      this.router.navigate(['/product/form']);
    })
  );

  @Effect({ dispatch: false })
  setProductsItems = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_SET_PRODUCTS_ITEM_ARRAY),
    map((action: ProductsStoreUiActions.SetProductsItemArray) => {
      this._socketGateway.sendSocketMessage(
        new SetProductItemArray(action.product, action.parent_id, action.typeOf)
      );
    })
  );

  @Effect({ dispatch: false })
  removeProductItem = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_REMOVE_PRODUCT_ITEM),
    map((action: ProductsStoreUiActions.RemoveProductItem) => {
      this._socketGateway.sendSocketMessage(
        new RemoveProductItemMethod(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  setCollectionsToProduct = this.actions$.pipe(
    ofType(ProductsStoreUIActionTypes.UI_SET_COLLECTIONS_TO_PRODUCT),
    map((action: ProductsStoreUiActions.SetCollectionsToProduct) => {
      this._socketGateway.sendSocketMessage(
        new SetCollectionsToProductMethod(action.productID, action.collections)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private _socketGateway: SocketGateway,
    private _uiProductsStoreDispatchers: ProductsStoreDispatchers,
    private _productsStoreService: ProductsStoreService,
    private _store: Store<AppState>
  ) { }
}
