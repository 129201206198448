import * as ProfileActions from './profile.actions';
import { ProfileMiddleware } from './profile.middleware';
import { IProfile } from '../../../models/IProfile';

export function profileReducer(
  state: IProfile[] = [],
  action: ProfileActions.ProfileActions
): IProfile[] {
  switch (action.type) {
    case ProfileActions.ProfileActionTypes.UNKNOWN_PROFILE: {
      const newContact: IProfile = {};
      newContact.user_id = action.userID;
      newContact.unknownContact = action.unknownContact;
      return ProfileMiddleware.addNewProfile(state, newContact);
    }
    case ProfileActions.ProfileActionTypes.BOT_CREATED: {
      const newBot: IProfile = {};
      newBot.user_id = action.userID;
      newBot.name = action.name;
      newBot.isBot = true;
      newBot.isMyBot = true;
      newBot.owner = 1;
      return ProfileMiddleware.addNewProfile(state, newBot);
    }
    case ProfileActions.ProfileActionTypes.BOT_ADDED_AS_ADMIN: {
      const alreadyExists = state.find(prof => prof.user_id === action.userID);
      if (alreadyExists) {
        return state.map(contact => {
          if (contact.user_id === action.userID) {
            let updatedProfile: IProfile = {};
            updatedProfile = {
              ...contact,
              isMyBot: true
            };
            return updatedProfile;
          }
          return contact;
        });
      } else {
        const newBot: IProfile = {};
        newBot.user_id = action.userID;
        newBot.isBot = true;
        newBot.isMyBot = true;
        return ProfileMiddleware.addNewProfile(state, newBot);
      }
    }
    case ProfileActions.ProfileActionTypes.CONTACTS_RECEIVED: {
      const profiles: IProfile[] = [];
      const profilesToBeUpdated: IProfile[] = [];
      let updatedState: IProfile[] = state;
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach(contact => {
          const alreadyExists = state.find(
            prof => prof.user_id === contact.user_id
          );
          if (
            !profiles.find(prof => prof.user_id === contact.user_id) &&
            !alreadyExists
          ) {
            profiles.push(contact);
          } else if (alreadyExists) {
            profilesToBeUpdated.push(contact);
          }
        });

        if (profilesToBeUpdated.length > 0) {
          updatedState = state.map(contact => {
            const rcvdContact = profilesToBeUpdated.find(
              prof => prof.user_id === contact.user_id
            );
            if (rcvdContact) {
              return { ...contact, ...rcvdContact };
            }
            return contact;
          });
        }
      }
      return [...updatedState, ...profiles];
    }
    case ProfileActions.ProfileActionTypes.CONTACT_DETAILS_RECEIVED:
    case ProfileActions.ProfileActionTypes.CONTACT_DETAILS_FROM_CACHE:
      if (!state.find(contact => contact.user_id === action.payload.user_id)) {
        const newProfile: IProfile = action.payload;
        return [...state, newProfile];
      }
      return state.map(contact => {
        if (contact.user_id === action.payload.user_id) {
          let updatedProfile: IProfile = {};
          updatedProfile = {
            ...contact,
            ...action.payload
          };
          if (contact.backgroundColor) {
            updatedProfile.backgroundColor = contact.backgroundColor;
          }
          if (contact.image && !action.payload.image) {
            updatedProfile.image = contact.image;
          }
          updatedProfile.detailsReceived = true;
          return updatedProfile;
        }
        return contact;
      });

    case ProfileActions.ProfileActionTypes.THUMBNAIL_DOWNLOADED:
      return state.map(contact => {
        if (contact.user_id === action.payload.user_id) {
          const updatedProfile: IProfile = {};
          updatedProfile.imageThumbnail = action.payload.imageThumbnail;
          return { ...contact, ...updatedProfile };
        }
        return contact;
      });
    case ProfileActions.ProfileActionTypes.IMAGE_DOWNLOADED:
      return state.map(contact => {
        if (contact.user_id === action.payload.user_id) {
          const updatedProfile: IProfile = {};
          updatedProfile.image = action.payload.image;
          updatedProfile.photo_id = action.payload.photo_id;
          return { ...contact, ...updatedProfile };
        }
        return contact;
      });
    case ProfileActions.ProfileActionTypes.DESELECT_PROFILE:
      return state.map(contact => {
        if (contact.user_id === action.id) {
          const updatedProfile: IProfile = {};
          updatedProfile.lastTypedMessage = action.lastTypedMessage;
          return { ...contact, ...updatedProfile };
        }
        return contact;
      });
    case ProfileActions.ProfileActionTypes.MY_BOT_DELETED: {
      const targetProfile = state.find(prof => prof.user_id === action.userID);
      if (targetProfile) {
        if (!targetProfile.isTemplateBot) {
          return state.filter(user => user.user_id !== action.userID);
        } else {
          return state.map(contact => {
            if (contact.user_id === action.userID) {
              const updatedProfile: IProfile = {};
              updatedProfile.isMyBot = false;
              return { ...contact, ...updatedProfile };
            }
            return contact;
          });
        }
      }
      return state;
    }

    case ProfileActions.ProfileActionTypes.UPDATE_BOT: {
      const profiles: IProfile[] = [];
      const profilesToBeUpdated: IProfile[] = [];
      let updatedState: IProfile[] = state;

      profilesToBeUpdated.push(action.botDetails);

      if (profilesToBeUpdated.length > 0) {
        updatedState = state.map(contact => {
          const rcvdContact = profilesToBeUpdated.find(
            prof => prof.user_id === contact.user_id
          );
          if (rcvdContact) {
            return { ...contact, ...rcvdContact };
          }
          return contact;
        });
      }

      return [...updatedState, ...profiles];
    }

    case ProfileActions.ProfileActionTypes.UPDATE_BOT_STORE: {
      const profiles: IProfile[] = [];
      const profilesToBeUpdated: IProfile[] = [];
      let updatedState: IProfile[] = state;

      profilesToBeUpdated.push(action.botDetails);

      if (profilesToBeUpdated.length > 0) {
        updatedState = state.map(contact => {
          const rcvdContact = profilesToBeUpdated.find(
            prof => prof.user_id === contact.user_id
          );
          if (rcvdContact) {
            return { ...contact, ...rcvdContact };
          }
          return contact;
        });
      }

      return [...updatedState, ...profiles];
    }

    case ProfileActions.ProfileActionTypes.USER_BALANCE_RECEIVED:
      return state.map(user => {
        if (user.user_id === action.userID) {
          const updatedUser: IProfile = { ...user };
          updatedUser.userBalance = action.userBalanceList;
          return updatedUser;
        }
        return user;
      });
    case ProfileActions.ProfileActionTypes.USER_PRODUCT_BALANCE_RECEIVED:
      return state.map(user => {
        if (user.user_id === action.userID) {
          const updatedUser: IProfile = { ...user };
          updatedUser.userProductBalance = action.userProductBalanceList;
          return updatedUser;
        }
        return user;
      });
    case ProfileActions.RESET:
      return [];
    default:
      return state;
  }
}
