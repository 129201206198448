import { Store } from '@ngrx/store';
import * as MobileWorkFlowStoreActionTypes from './mobileWorkflowStore.actions';
import { BotMenu, RcvdMyMenuMessage } from 'src/models/IBotMenu';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MobileWorkFlowStoreDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listMenus() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.ListMenus());
    }
    menusReceiving(data) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.MenusReceiving(data));
    }
    menuReceiving(menu) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.MenuReceiveing(menu));
    }
    menuReceived(menu) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.MenuReceived(menu));
    }
    createMenu(menu_id, currency, shop, image, name, description, is_default, id) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.CreateMenu(menu_id, currency, shop, image, name, description, is_default, id));
    }
    updateMenu(menu_id, currency, shop, is_default, id) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.UpdateMenu(menu_id, currency, shop, is_default, id));
    }
    deleteMenu(menu) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.DeleteMenu(menu));
    }
    getNextMenuPage() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetNextMenuPage());
    }
    getPrevMenuPage() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetPrevMenuPage());
    }
    resetMenuPaging() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.ResetMenuPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.SetStatusAsIdle());
    }
    UploadMenuImg(componentRef, selectedFile) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.UploadMenuImg(componentRef, selectedFile));
    }
    UploadMenuImgSuccess(componentRef, imageUrl, imageSet) {
        // this.formMenu.reciveImageUrl(imageUrl, componentRef);
    }
    getMobileStore(id) {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.GetMobileStore(id));
    }
    reset() {
        this._store.dispatch(new MobileWorkFlowStoreActionTypes.Reset());
    }
}
MobileWorkFlowStoreDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileWorkFlowStoreDispatchers_Factory() { return new MobileWorkFlowStoreDispatchers(i0.ɵɵinject(i1.Store)); }, token: MobileWorkFlowStoreDispatchers, providedIn: "root" });
