import { Store } from '@ngrx/store';
import * as MapTripUserActions from './mapTripUser.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapTripUserDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetMapTripUsersPaging(tripID) {
        this._store.dispatch(new MapTripUserActions.ResetMapTripUsersPaging(tripID));
    }
    getNextMapTripUsersPage() {
        this._store.dispatch(new MapTripUserActions.GetNextMapTripUsersPage());
    }
    getPrevMapTripUsersPage() {
        this._store.dispatch(new MapTripUserActions.GetPrevMapTripUsersPage());
    }
    createMapTripUser(mapTripUser) {
        this._store.dispatch(new MapTripUserActions.CreateMapTripUser(mapTripUser));
    }
    mapTripUserCreated(mapTripUser) {
        this._store.dispatch(new MapTripUserActions.MapTripUserCreated(mapTripUser));
    }
    updateMapTripUser(id, mapTripUser) {
        this._store.dispatch(new MapTripUserActions.UpdateMapTripUser(id, mapTripUser));
    }
    mapTripUserDetailsReceived(mapTripUser) {
        this._store.dispatch(new MapTripUserActions.MapTripUserDetailsReceived(mapTripUser));
    }
    mapTripUserListReceived(eop, sop, mapTripUsers, hash) {
        this._store.dispatch(new MapTripUserActions.MapTripUserListReceived(eop, sop, mapTripUsers, hash));
    }
    deleteMapTripUser(id, trip_id) {
        this._store.dispatch(new MapTripUserActions.DeleteMapTripUser(id, trip_id));
    }
    mapTripUserDeleted(id) {
        this._store.dispatch(new MapTripUserActions.MapTripUserDeleted(id));
    }
}
MapTripUserDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapTripUserDispatchers_Factory() { return new MapTripUserDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapTripUserDispatchers, providedIn: "root" });
