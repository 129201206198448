import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { SubGroupUiDispatchers } from 'src/app/store/subGroupsUI/subGroupsUI.dispatchers';
import { SubChannelUiDispatchers } from 'src/app/store/subChannelsUI/subChannelsUI.dispatchers';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
// import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { CHANNEL_APP_SEARCH_CATEGORIES, GROUP, CHANNEL, UPDATE_CHANNEL_REF, UPDATE_GROUP_REF, ChatTypes, UPDATE_VIRTUAL_APP_REF } from 'src/models/constants';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';
import { SUBGROUPS, SUBCHANNELS, VIRTUAL_APPS } from 'src/models/AppSections';
import { VirtualAppUiDispatchers } from 'src/app/store/virtualAppsUI/virtualAppsUI.dispatchers';
import { AllGroupDispatchers } from 'src/app/store/allGroup/allGroup.dispatchers';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/ui/ui.dispatchers";
import * as i3 from "../../../store/chats/chat.dispatchers";
import * as i4 from "../../../store/subGroupsUI/subGroupsUI.dispatchers";
import * as i5 from "../../../store/subChannelsUI/subChannelsUI.dispatchers";
import * as i6 from "../../../store/virtualAppsUI/virtualAppsUI.dispatchers";
import * as i7 from "../../../store/allGroup/allGroup.dispatchers";
import * as i8 from "@angular/router";
export class SubChatsService {
    constructor(_rootSelector, _uiDispatchers, _chatDispatchers, _subGroupUiDispatchers, _subChannelUiDispatchers, _virtualAppUiDispatchers, _allGroupDispatchers, router) {
        this._rootSelector = _rootSelector;
        this._uiDispatchers = _uiDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._subGroupUiDispatchers = _subGroupUiDispatchers;
        this._subChannelUiDispatchers = _subChannelUiDispatchers;
        this._virtualAppUiDispatchers = _virtualAppUiDispatchers;
        this._allGroupDispatchers = _allGroupDispatchers;
        this.router = router;
        this.chatType = GROUP;
        this._chatTypes = ChatTypes;
        this.searchEditForm = {
            type: null,
            area: null,
            classification: null,
            category: null
        };
        this.confoirmDeleteWindow = false;
        this._currentPhotoUploaded = new BehaviorSubject({});
        this.currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();
        this._wattingForUploadImage = new BehaviorSubject(false);
        this.wattingForUploadImage$ = this._wattingForUploadImage.asObservable();
    }
    setSearchForm(val) {
        this.searchEditForm = Object.assign({}, this.searchEditForm, val);
    }
    selectSubGroupToChat(chat) {
        this._uiDispatchers.subChatSelected(chat);
    }
    createSubGroup(subGroup, subChat) {
        if (subGroup.type === GROUP) {
            // this._wattingForUploadImage.next(true);
            this._chatDispatchers.createGroup(subGroup, subChat);
            // this.router.navigate(['/subgroups']);
        }
        else if (subGroup.type === CHANNEL) {
            if (subGroup.vapp && subGroup.vapp === 1) {
                this._chatDispatchers.createVirtualApp(subGroup);
            }
            else {
                this._chatDispatchers.createChannel(subGroup, subChat);
                // this.router.navigate(['/subchannels']);
            }
        }
    }
    subChatDeselected() {
        this._uiDispatchers.subChatDeselected();
    }
    backFromSubGroupReplies() {
        this._uiDispatchers.backFromSubGroupReplies();
    }
    subUserDeselected() {
        this._uiDispatchers.subUserDeselected();
    }
    viewSubGroup(subGroup) {
        this.getChatAdministrators(subGroup.id);
        this._wattingForUploadImage.next(false);
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.viewSubGroupUI(subGroup);
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.viewSubChannelUI(subGroup);
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.viewVirtualAppUI(subGroup);
        }
    }
    closeViewSubGroup() {
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.closeViewSubGroupUI();
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.closeViewSubChannelUI();
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.closeViewVirtualAppUI();
        }
    }
    showEditSubGroupScreen(subGroup, isMyChannel) {
        this.getChatAdministrators(subGroup.id);
        const photo = {
            file: null,
            url: subGroup.localThumbnail
        };
        this._currentPhotoUploaded.next(photo);
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.editSubGroupUI(subGroup);
            this.router.navigate(['/subgroups/form']);
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            if (!isMyChannel) {
                this.router.navigate(['/subchannels/form']);
            }
            this._subChannelUiDispatchers.editSubChannelUI(subGroup);
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.editVirtualAppUI(subGroup);
        }
    }
    closeEditSubGroupScreen() {
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.closeEditSubGroupUI();
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.closeEditSubChannelUI();
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.closeEditVirtualAppUI();
        }
    }
    deleteChat(id) {
        this._chatDispatchers.deleteChat(id);
    }
    toggleDeleteConfoirm(e, chat) {
        this.confoirmDeleteWindow = e;
        this.selectedChat = chat;
    }
    updateSubGroup(chatID, updatedSubGroupProps) {
        updatedSubGroupProps.id = chatID;
        const updateRef = updatedSubGroupProps.type === GROUP
            ? UPDATE_GROUP_REF
            : updatedSubGroupProps.type === CHANNEL &&
                updatedSubGroupProps.vapp &&
                updatedSubGroupProps.vapp === 1
                ? UPDATE_VIRTUAL_APP_REF
                : UPDATE_CHANNEL_REF;
        this._chatDispatchers.updateChat(updatedSubGroupProps, updateRef);
    }
    updateStore(chatID, updatedSubGroupProps, create) {
        updatedSubGroupProps.id = chatID;
        const updateRef = updatedSubGroupProps.type === GROUP
            ? UPDATE_GROUP_REF
            : updatedSubGroupProps.type === CHANNEL &&
                updatedSubGroupProps.vapp &&
                updatedSubGroupProps.vapp === 1
                ? UPDATE_VIRTUAL_APP_REF
                : UPDATE_CHANNEL_REF;
        this._chatDispatchers.updateStore(updatedSubGroupProps, updateRef);
    }
    uploadSubGroupImage(file) {
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.uploadSubGroupImage(file);
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.uploadSubChannelImage(file);
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.uploadVirtualAppImage(file);
        }
    }
    subGroupCreationComplete(type, vapp) {
        if (type === GROUP) {
            this._subGroupUiDispatchers.subGroupCreationComplete();
        }
        else if (type === CHANNEL) {
            if (vapp && vapp === 1) {
                this._virtualAppUiDispatchers.virtualAppCreationComplete();
            }
            else {
                this._subChannelUiDispatchers.subChannelCreationComplete();
            }
        }
    }
    createSubGroupUI() {
        this._wattingForUploadImage.next(false);
        if (this.chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.createSubGroupUI();
            this.router.navigate(['/subgroups/form']);
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            this.router.navigate(['/subchannels/form']);
            this._subChannelUiDispatchers.createSubChannelUI();
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.createVirtualAppUI();
        }
    }
    confirmDelete(id) {
        this.deleteChat(id);
        this.toggleDeleteConfoirm(false);
    }
    getNextPage(chatType) {
        if (chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.getNextSubGroupPage();
        }
        else if (chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.getNextSubChannelPage();
        }
        else if (chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.getNextVirtualAppPage();
        }
    }
    getPreviousPage(chatType) {
        if (chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.getPrevSubGroupPage();
        }
        else if (chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.getPrevSubChannelPage();
        }
        else if (chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.getPrevVirtualAppPage();
        }
    }
    resetPaging(chatType) {
        if (chatType === this._chatTypes.GROUP) {
            this._subGroupUiDispatchers.resetSubGroupPaging();
        }
        else if (chatType === this._chatTypes.CHANNEL) {
            this._subChannelUiDispatchers.resetSubChannelPaging();
        }
        else if (chatType === this._chatTypes.VIRTUAL_APP) {
            this._virtualAppUiDispatchers.resetVirtualAppPaging();
        }
    }
    // selectors
    getChatTypeIcon(chatType, vapp) {
        if (chatType === GROUP) {
            return SUBGROUPS.symbolImage;
        }
        else if (chatType === CHANNEL) {
            if (vapp && vapp == 1) {
                return VIRTUAL_APPS.symbolImage;
            }
            else {
                return SUBCHANNELS.symbolImage;
            }
        }
    }
    getChatTypeColor(chatType, vapp) {
        if (chatType === GROUP) {
            return SUBGROUPS.symbolColor;
        }
        else if (chatType === CHANNEL) {
            if (vapp && vapp == 1) {
                return VIRTUAL_APPS.symbolColor;
            }
            else {
                return SUBCHANNELS.symbolColor;
            }
        }
    }
    getChatTypeColor2(chatType, vapp) {
        if (chatType === GROUP) {
            return SUBGROUPS.symbolColor2;
        }
        else if (chatType === CHANNEL) {
            if (vapp && vapp == 1) {
                return VIRTUAL_APPS.symbolColor2;
            }
            else {
                return SUBCHANNELS.symbolColor2;
            }
        }
    }
    getChatTypeBG(chatType, vapp) {
        if (chatType === GROUP) {
            return SUBGROUPS.coverColor;
        }
        else if (chatType === CHANNEL) {
            if (vapp && vapp == 1) {
                return VIRTUAL_APPS.coverColor;
            }
            else {
                return SUBCHANNELS.coverColor;
            }
        }
    }
    get subChats$() {
        return this._rootSelector.subChats$;
    }
    get pagedChats$() {
        return this._rootSelector.getMainSubChats$();
    }
    get allPagedChats$() {
        return this._rootSelector.getAllMainSubChats$();
    }
    getPagedSubChatsByType$(chatType) {
        if (chatType === this._chatTypes.GROUP) {
            return this._rootSelector.getPagedSubChats$();
        }
        else if (chatType === this._chatTypes.CHANNEL) {
            return this._rootSelector.getPagedSubChannels$();
        }
        else if (chatType === this._chatTypes.VIRTUAL_APP) {
            return this._rootSelector.getPagedVirtualApps$();
        }
    }
    get subChatsList$() {
        return this._rootSelector.getFilteredSubChats$().pipe(map(s => s
            .filter(c => this.searchEditForm.category
            ? c.category === this.searchEditForm.category
            : c)
            .filter(c => this.searchEditForm.classification
            ? c.classification === this.searchEditForm.classification
            : c)
            .filter(c => this.searchEditForm.area ? c.area === this.searchEditForm.area : c)
            .filter(c => this.searchEditForm.type ? c.type === this.searchEditForm.type : c)));
    }
    getChatWelcomeMessage$(messageId) {
        return this._rootSelector.getChatWelcomeMessage$(messageId);
    }
    get selectedSubGroupMain$() {
        if (this.chatType === this._chatTypes.GROUP) {
            return this._rootSelector
                .getSelectedSubGroup$()
                .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            return this._rootSelector
                .getSelectedSubChannel$()
                .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            return this._rootSelector
                .getSelectedVirtualApp$()
                .pipe(map(r => ChatMiddleware.getMainChatFromChat(r)));
        }
    }
    get selectedSubGroup$() {
        if (this.chatType === this._chatTypes.GROUP) {
            return this._rootSelector.getSelectedSubGroup$();
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            return this._rootSelector.getSelectedSubChannel$();
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            return this._rootSelector.getSelectedVirtualApp$();
        }
    }
    get subGroupInCreationProcess$() {
        if (this.chatType === this._chatTypes.GROUP) {
            return this._rootSelector.getSubGroupInCreationProcess$();
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            return this._rootSelector.getSubChannelInCreationProcess$();
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            return this._rootSelector.getVirtualAppInCreationProcess$();
        }
    }
    get subGroupUiState$() {
        if (this.chatType === this._chatTypes.GROUP) {
            return this._rootSelector.subGroupUiState$;
        }
        else if (this.chatType === this._chatTypes.CHANNEL) {
            return this._rootSelector.subChannelUiState$;
        }
        else if (this.chatType === this._chatTypes.VIRTUAL_APP) {
            return this._rootSelector.virtualAppUiState$;
        }
    }
    get area$() {
        return this._rootSelector.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.AREA);
    }
    get classification$() {
        return this._rootSelector.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS);
    }
    get category$() {
        return this._rootSelector.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY);
    }
    requestChatDetaislById(id) {
        this._chatDispatchers.requestChatDetails(id);
    }
    get getMainSubChatsPageCount$() {
        return this._rootSelector.getMainSubChatsPageCount$();
    }
    setAllGroupLoaderStatus(status) {
        this._allGroupDispatchers.setLoaderStatus(status);
    }
    get getUIAllGroup$() {
        return this._rootSelector.getUIAllGroup$();
    }
    getChatAdministrators(id) {
        this._chatDispatchers.getChatAdministrators(id);
    }
    setChatPhoto(chat) {
        this._chatDispatchers.updateChat(chat);
    }
}
SubChatsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubChatsService_Factory() { return new SubChatsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.UIDispatchers), i0.ɵɵinject(i3.ChatDispatchers), i0.ɵɵinject(i4.SubGroupUiDispatchers), i0.ɵɵinject(i5.SubChannelUiDispatchers), i0.ɵɵinject(i6.VirtualAppUiDispatchers), i0.ɵɵinject(i7.AllGroupDispatchers), i0.ɵɵinject(i8.Router)); }, token: SubChatsService, providedIn: "root" });
