/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-questions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../startover-more-modules/startover-more-modules.component.ngfactory";
import * as i3 from "../startover-more-modules/startover-more-modules.component";
import * as i4 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i5 from "../startover-submit-btn/startover-submit-btn.component.ngfactory";
import * as i6 from "../startover-submit-btn/startover-submit-btn.component";
import * as i7 from "@angular/common";
import * as i8 from "../startover-temp-view/startover-temp-view.component.ngfactory";
import * as i9 from "../startover-temp-view/startover-temp-view.component";
import * as i10 from "../../../core/auth.service";
import * as i11 from "../../../core/storage.service";
import * as i12 from "../startover-droplist/startover-droplist.component.ngfactory";
import * as i13 from "../startover-droplist/startover-droplist.component";
import * as i14 from "../startover-temps/startover-temps.component.ngfactory";
import * as i15 from "../startover-temps/startover-temps.component";
import * as i16 from "@angular/router";
import * as i17 from "../startover-guide-video/startover-guide-video.component.ngfactory";
import * as i18 from "../startover-guide-video/startover-guide-video.component";
import * as i19 from "../startover-menu-theme/startover-menu-theme.component.ngfactory";
import * as i20 from "../startover-menu-theme/startover-menu-theme.component";
import * as i21 from "../../../core/ui.service";
import * as i22 from "../../../+merchant/+dashboard/menus/menus.service";
import * as i23 from "@angular/forms";
import * as i24 from "../../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i25 from "../../../shared/components/modal-container/modal-container.component";
import * as i26 from "../../../+merchant/+layout/layout.service";
import * as i27 from "../../../abstract-notifiers/popup-notifier/popup-notifier.component.ngfactory";
import * as i28 from "../../../abstract-notifiers/popup-notifier/popup-notifier.component";
import * as i29 from "./startover-questions.component";
import * as i30 from "../../../+merchant/+dashboard/bots/bots.service";
import * as i31 from "../../../+merchant/+dashboard/dashboard.service";
import * as i32 from "../../../+merchant/+registration/registration.service";
import * as i33 from "../../../+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service";
var styles_StartoverQuestionsComponent = [i0.styles];
var RenderType_StartoverQuestionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverQuestionsComponent, data: {} });
export { RenderType_StartoverQuestionsComponent as RenderType_StartoverQuestionsComponent };
function View_StartoverQuestionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-more-modules", [], null, [[null, "checkedItemNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkedItemNotify" === en)) {
        var pd_0 = (_co.addRemoveMoreTyes($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StartoverMoreModulesComponent_0, i2.RenderType_StartoverMoreModulesComponent)), i1.ɵdid(1, 114688, null, 0, i3.StartoverMoreModulesComponent, [i4.AppMgmtService], { item: [0, "item"], moreTypes: [1, "moreTypes"], appInfo: [2, "appInfo"] }, { checkedItemNotify: "checkedItemNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.moreTypes; var currVal_2 = _co.appInfo; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_StartoverQuestionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "module-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "question"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What additional"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["features would you"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["like in your app?"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "dec-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Or click 'Continue' if you don't want to"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "dec"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["add any additional features."])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-startover-submit-btn", [], null, [[null, "saveNotfiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveNotfiy" === en)) {
        var pd_0 = (_co.addMoreItems() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_StartoverSubmitBtnComponent_0, i5.RenderType_StartoverSubmitBtnComponent)), i1.ɵdid(13, 114688, null, 0, i6.StartoverSubmitBtnComponent, [], { title: [0, "title"], type: [1, "type"] }, { saveNotfiy: "saveNotfiy" }), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "answers-add "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_3)), i1.ɵdid(16, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Continue"; var currVal_1 = "submit"; _ck(_v, 13, 0, currVal_0, currVal_1); var currVal_2 = _co.filterModules; _ck(_v, 16, 0, currVal_2); }, null); }
function View_StartoverQuestionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container reveal"]], [[2, "active", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.show(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.show(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-startover-temp-view", [], null, [[null, "startWith"], [null, "backNotfiy"], ["window", "popstate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:popstate" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onPopState($event) !== false);
        ad = (pd_0 && ad);
    } if (("startWith" === en)) {
        var pd_1 = (_co.selectTemp($event) !== false);
        ad = (pd_1 && ad);
    } if (("backNotfiy" === en)) {
        var pd_2 = (_co.back() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_StartoverTempViewComponent_0, i8.RenderType_StartoverTempViewComponent)), i1.ɵdid(2, 638976, null, 0, i9.StartoverTempViewComponent, [i10.AuthService, i4.AppMgmtService, i11.StorageService, i1.ChangeDetectorRef], { temp: [0, "temp"], relatedTemp: [1, "relatedTemp"], category: [2, "category"], selectedCategory: [3, "selectedCategory"], tempId: [4, "tempId"], choosenTemp: [5, "choosenTemp"] }, { startWith: "startWith", backNotfiy: "backNotfiy" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.currentTemp == null) ? null : _co.currentTemp.template); var currVal_2 = _co.tempsList; var currVal_3 = ((_co.currentTemp == null) ? null : _co.currentTemp.category); var currVal_4 = _co.category; var currVal_5 = ((_co.currentTemp == null) ? null : _co.currentTemp.id); var currVal_6 = _co.currentTemp; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page === 2); _ck(_v, 0, 0, currVal_0); }); }
function View_StartoverQuestionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_4)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page == 3); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.page === 2); _ck(_v, 4, 0, currVal_1); }, null); }
function View_StartoverQuestionsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.show(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.show(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "temps-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "cat-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-startover-droplist", [], null, [[null, "saveNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveNotify" === en)) {
        var pd_0 = (_co.saveCat($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_StartoverDroplistComponent_0, i12.RenderType_StartoverDroplistComponent)), i1.ɵdid(4, 638976, null, 0, i13.StartoverDroplistComponent, [i1.ChangeDetectorRef, i4.AppMgmtService], { category: [0, "category"], type: [1, "type"], showList: [2, "showList"] }, { saveNotify: "saveNotify" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-startover-temps", [], null, [[null, "tempView"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tempView" === en)) {
        var pd_0 = (_co.tempView($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_StartoverTempsComponent_0, i14.RenderType_StartoverTempsComponent)), i1.ɵdid(7, 638976, null, 0, i15.StartoverTempsComponent, [i10.AuthService, i1.ChangeDetectorRef, i16.Router, i16.ActivatedRoute], { category: [0, "category"], tempsList: [1, "tempsList"], loading: [2, "loading"] }, { tempView: "tempView" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(((_co.appManagementService == null) ? null : _co.appManagementService.selectedTempCat$))); var currVal_1 = "cat"; var currVal_2 = _co.showList; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.tempType; var currVal_4 = _co.tempsList; var currVal_5 = _co.tempLoading; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }, null); }
function View_StartoverQuestionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-temp"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_6)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page == 1); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverQuestionsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-startover-guide-video", [], null, null, null, i17.View_StartoverGuideVideoComponent_0, i17.RenderType_StartoverGuideVideoComponent)), i1.ɵdid(1, 114688, null, 0, i18.StartoverGuideVideoComponent, [i10.AuthService, i4.AppMgmtService], { appInfo: [0, "appInfo"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.appManagementService.appInfo$)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_StartoverQuestionsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-startover-menu-theme", [], null, [[null, "mainMenuNotifiy"], [null, "editNotify"], [null, "newMenuNotify"], [null, "uploadNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mainMenuNotifiy" === en)) {
        var pd_0 = (_co.cutomize($event) !== false);
        ad = (pd_0 && ad);
    } if (("editNotify" === en)) {
        var pd_1 = (_co.edit($event) !== false);
        ad = (pd_1 && ad);
    } if (("newMenuNotify" === en)) {
        var pd_2 = (_co.newMenu($event) !== false);
        ad = (pd_2 && ad);
    } if (("uploadNotify" === en)) {
        var pd_3 = (_co.uploadImage($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i19.View_StartoverMenuThemeComponent_0, i19.RenderType_StartoverMenuThemeComponent)), i1.ɵdid(1, 638976, null, 0, i20.StartoverMenuThemeComponent, [i4.AppMgmtService, i21.UiService, i22.MenusService, i1.ChangeDetectorRef, i23.FormBuilder, i10.AuthService, i11.StorageService], { appClass: [0, "appClass"], tabContainerList: [1, "tabContainerList"], itemList: [2, "itemList"], appInfo: [3, "appInfo"], menuApp: [4, "menuApp"], menuIdex: [5, "menuIdex"], currentMenu: [6, "currentMenu"], toBeEdited: [7, "toBeEdited"] }, { mainMenuNotifiy: "mainMenuNotifiy", editNotify: "editNotify", newMenuNotify: "newMenuNotify", uploadNotify: "uploadNotify" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.appManagementService.tabsAppClass$)); var currVal_1 = _co.tabs; var currVal_2 = _co.navItems; var currVal_3 = _co.appInfo; var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 3).transform(((_co.appManagementService == null) ? null : _co.appManagementService.menusAppClass$))); var currVal_5 = _co.menuIdex; var currVal_6 = _co.currentMenu; var currVal_7 = _co.toBeEdited; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_StartoverQuestionsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal-container", [], null, [[null, "cancelNotify"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotify" === en)) {
        var pd_1 = (_co.showConfirmation(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i24.View_ModalContainerComponent_0, i24.RenderType_ModalContainerComponent)), i1.ɵdid(1, 245760, null, 0, i25.ModalContainerComponent, [i1.ElementRef, i26.LayoutService], { count: [0, "count"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-popup-notifier", [], null, [[null, "hidePopupNotify"], [null, "agreePopupNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hidePopupNotify" === en)) {
        var pd_0 = (_co.showConfirmation(false) !== false);
        ad = (pd_0 && ad);
    } if (("agreePopupNotify" === en)) {
        var pd_1 = (_co.secConfirmation(true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i27.View_PopupNotifierComponent_0, i27.RenderType_PopupNotifierComponent)), i1.ɵdid(4, 114688, null, 0, i28.PopupNotifierComponent, [], { title: [0, "title"], message: [1, "message"], validationMsg: [2, "validationMsg"], cancelTitle: [3, "cancelTitle"], validationTitle: [4, "validationTitle"] }, { hidePopupNotify: "hidePopupNotify", agreePopupNotify: "agreePopupNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.layoutService == null) ? null : _co.layoutService.appModelOpenStatus$))); _ck(_v, 1, 0, currVal_0); var currVal_1 = "Warning"; var currVal_2 = "Please note that starting over will erase all your app configuration, do you want to proceed?"; var currVal_3 = true; var currVal_4 = "No"; var currVal_5 = "Yes"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_StartoverQuestionsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal-container", [], null, [[null, "cancelNotify"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotify" === en)) {
        var pd_1 = (_co.secConfirmation(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i24.View_ModalContainerComponent_0, i24.RenderType_ModalContainerComponent)), i1.ɵdid(1, 245760, null, 0, i25.ModalContainerComponent, [i1.ElementRef, i26.LayoutService], { count: [0, "count"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-popup-notifier", [], null, [[null, "hidePopupNotify"], [null, "agreePopupNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hidePopupNotify" === en)) {
        var pd_0 = (_co.secConfirmation(false) !== false);
        ad = (pd_0 && ad);
    } if (("agreePopupNotify" === en)) {
        var pd_1 = (_co.start() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i27.View_PopupNotifierComponent_0, i27.RenderType_PopupNotifierComponent)), i1.ɵdid(4, 114688, null, 0, i28.PopupNotifierComponent, [], { title: [0, "title"], message: [1, "message"], validationMsg: [2, "validationMsg"], cancelTitle: [3, "cancelTitle"], validationTitle: [4, "validationTitle"] }, { hidePopupNotify: "hidePopupNotify", agreePopupNotify: "agreePopupNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.layoutService == null) ? null : _co.layoutService.appModelOpenStatus$))); _ck(_v, 1, 0, currVal_0); var currVal_1 = "Warning"; var currVal_2 = "Please note that all your app customizations and features will be lost, do you want to proceed?"; var currVal_3 = true; var currVal_4 = "Cancel"; var currVal_5 = "Confirm"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_StartoverQuestionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_5)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_7)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_8)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_9)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverQuestionsComponent_10)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.page == 2) || (_co.page == 3)) && (_co.mode == 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.page == 1); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.page == 10) && ((_co.mode == 0) || (_co.mode == 1))); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.page == 9) && (((_co.appInfo == null) ? null : _co.appInfo.layout) === ((_co.themeStatus == null) ? null : _co.themeStatus.Menu))); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.startOverFirstValidation; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.startOverSecValidation; _ck(_v, 11, 0, currVal_5); }, null); }
export function View_StartoverQuestionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-questions", [], null, null, null, View_StartoverQuestionsComponent_0, RenderType_StartoverQuestionsComponent)), i1.ɵdid(1, 770048, null, 0, i29.StartoverQuestionsComponent, [i4.AppMgmtService, i1.ChangeDetectorRef, i10.AuthService, i30.BotsService, i26.LayoutService, i21.UiService, i16.Router, i31.DashboardService, i32.RegistrationService, i11.StorageService, i33.MobileWorkFlowStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverQuestionsComponentNgFactory = i1.ɵccf("app-startover-questions", i29.StartoverQuestionsComponent, View_StartoverQuestionsComponent_Host_0, { tabs: "tabs", navItems: "navItems", appMenu: "appMenu", component: "component", page: "page", mode: "mode", menuIdex: "menuIdex", currentMenu: "currentMenu", moreTypes: "moreTypes", appInfo: "appInfo", toBeEdited: "toBeEdited", startOverFirstValidation: "startOverFirstValidation", startOverSecValidation: "startOverSecValidation", payment: "payment" }, { uploadNotify: "uploadNotify", customizeNotify: "customizeNotify", startOverCurrentPageNotify: "startOverCurrentPageNotify", editNotify: "editNotify", newMenuNotify: "newMenuNotify", startAgainNotify: "startAgainNotify" }, []);
export { StartoverQuestionsComponentNgFactory as StartoverQuestionsComponentNgFactory };
