import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as SmtpActionTypes from './smtp.actions';

import { AppState } from 'src/models/AppState';
import { Smtp } from 'src/models/Smtp';

@Injectable({
  providedIn: 'root'
})
export class StmpDispatchers {
  constructor(private _store: Store<AppState>) {}

  getStmp(): void {
    this._store.dispatch(new SmtpActionTypes.GetStmp());
  }

  receiveStmp(smtp: Smtp): void {
    this._store.dispatch(new SmtpActionTypes.ReceiveStmp(smtp));
  }

  setStmp(smtp: Smtp): void {
    this._store.dispatch(new SmtpActionTypes.SetStmp(smtp));
  }
}
