import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-startover-submit-btn',
  templateUrl: './startover-submit-btn.component.html',
  styleUrls: ['./startover-submit-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverSubmitBtnComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Output() saveNotfiy = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}
  saveAndCont() {
    this.saveNotfiy.emit(true);
  }
}
