import { BehaviorSubject } from 'rxjs';
import { ProductsStoreDispatchers } from 'src/app/store/product-store/product-store.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/product-store/product-store.dispatchers";
export class ProductsStoreService {
    constructor(_rootStore, _productStoreDispatcher) {
        this._rootStore = _rootStore;
        this._productStoreDispatcher = _productStoreDispatcher;
        this.confirmProduct = false;
        this._hideOptionBtn = new BehaviorSubject(false);
        this.hideOptionBtn$ = this._hideOptionBtn.asObservable();
    }
    get productsStore$() {
        return this._rootStore.getProductsStore$();
    }
    get selectedProductStore$() {
        return this._rootStore.getSelecetedProductStore$();
    }
    resetPaging() {
        this._hideOptionBtn.next(false);
        this._productStoreDispatcher.resetProductsStorePaging();
    }
    getNext() {
        this._productStoreDispatcher.getNextProductsStorePage();
    }
    getPrev() {
        this._productStoreDispatcher.getPrevProductsStorePage();
    }
    setProductStoreItem(product) {
        this._productStoreDispatcher.setProductStoreItem(product);
    }
    setProductsItemArray(product, parent_id, type) {
        this._productStoreDispatcher.setProductsItemArray(product, parent_id, type);
    }
    editEvent(product) {
        this._productStoreDispatcher.setSelectedProductStore(product);
    }
    getProductItem(id) {
        this._productStoreDispatcher.getProductItem(id);
    }
    removeProductItem(id) {
        this._productStoreDispatcher.removeProductItem(id);
    }
    toggleConfirmDeleteProduct(e, product) {
        this.confirmProduct = e;
        this.selectedProduct = product;
    }
    setCollectionsToProduct(productID, collections) {
        this._productStoreDispatcher.setCollectionsToProduct(productID, collections);
    }
}
ProductsStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsStoreService_Factory() { return new ProductsStoreService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ProductsStoreDispatchers)); }, token: ProductsStoreService, providedIn: "root" });
