import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { MyProfileMiddleware } from './myProfile.middleware';
import { MyProfileDispatchers } from './myProfile.dispatchers';
export class MyProfileEffects {
    constructor(actions$, _myProfileDispatcher) {
        this.actions$ = actions$;
        this._myProfileDispatcher = _myProfileDispatcher;
        this.myProfileReceived = this.actions$.pipe(ofType("MY_PROFILE_RECEIVING" /* MY_PROFILE_RECEIVING */), map((action) => {
            const profile = MyProfileMiddleware.initMyProfile(action.payload);
            this._myProfileDispatcher.myProfileReceived(profile);
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MyProfileEffects.prototype, "myProfileReceived", void 0);
