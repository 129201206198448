import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as InstantPageActions from './instantPage.actions';
import { InstantPageActionTypes } from './instantPage.actions';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { GetInstantPageDetailsRequest } from 'src/models/MyPageConfig';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class InstantPageEffects {
  @Effect({ dispatch: false })
  getDetailsOfInstantPage = this.actions$.pipe(
    ofType(InstantPageActionTypes.REQUEST_INSTANT_PAGE_DETAILS),
    withLatestFrom(
      this._store
        .select(state => state.instantPageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, pages]) => {
      const action = <InstantPageActions.RequestInstantPageDetails>val;
      const selectedPage = pages.find(page => page.id === action.id);

      if (
        !selectedPage ||
        (selectedPage &&
          (!selectedPage.content ||
            (selectedPage.content &&
              (!selectedPage.content.container ||
                selectedPage.content.container.length === 0))))
      ) {
        this._socketGateway.sendSocketMessage(
          new GetInstantPageDetailsRequest(action.id)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  receiveInstantPages = this.actions$.pipe(
    ofType(InstantPageActionTypes.RECEIVE_INSTANT_PAGES),
    withLatestFrom(
      this._store
        .select(state => state.instantPageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, pages]) => {
      const action = <InstantPageActions.ReceiveInstantPages>val;
      pages.forEach(page => {
        this._socketGateway.sendSocketMessage(
          new GetInstantPageDetailsRequest(page.id)
        );
      });
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>
  ) {}
}
