<span type="button" (click)="record2()">
  <i *ngIf="!recInited" [class.fas]="true" class="fa-microphone layer"></i>
</span>
<span *ngIf="recInited" appCancelDrag class="control-container">
  <span type="button" (click)="cancelRecord()" class="stop">
    <i class="fas fa-times"></i>
  </span>
  <span class="time">
    <span class="recorder"></span>
    <span class="timer">{{ voiceRecordDurationInterval | mediaDuration }}</span>
  </span>
  <span type="button" (click)="onRecordStop()" class="send">
    <img src="./assets/img/send-voice-note.svg" alt="send" />
  </span>
</span>
