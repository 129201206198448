import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as DriverAssignedActionTypes from './driverAssigned.actions';

import { AppState } from 'src/models/AppState';
import { DriverTrip } from 'src/models/DrverAssigned';

@Injectable({
  providedIn: 'root'
})
export class DriverTripsDispatchers {
  constructor(private _store: Store<AppState>) {}

  getDriverAssigned(
    trip_id: number,
    account_id: string,
    start_day?: Date,
    end_day?: Date
  ): void {
    this._store.dispatch(
      new DriverAssignedActionTypes.GetDriverTrips(
        trip_id,
        account_id,
        start_day,
        end_day
      )
    );
  }

  setDriverAssigned(
    tripId: string,
    accountId,
    driverAssignedList: DriverTrip[]
  ): void {
    this._store.dispatch(
      new DriverAssignedActionTypes.SetDriverTrips(
        tripId,
        accountId,
        driverAssignedList
      )
    );
  }

  driverTripsReceived(payload: any): void {
    this._store.dispatch(
      new DriverAssignedActionTypes.DriverTipsReceived(payload)
    );
  }
}
