import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';

import { SharedModule } from '../shared/shared.module';

import { UploadFileBtnComponent } from './upload-file-btn/upload-file-btn.component';
import { UpldImgWithTitleComponent } from './upld-img-with-title/upld-img-with-title.component';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ImageCropperModule,
    AbstractFramesModule,
    TranslateModule
  ],
  declarations: [UpldImgWithTitleComponent, UploadFileBtnComponent],
  exports: [UpldImgWithTitleComponent, UploadFileBtnComponent]
})
export class AbstractUploadModule {}
