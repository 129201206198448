/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/dropdown";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "../../../shared/directives/click-elsewhere/click-elsewhere.directive";
import * as i5 from "../../../shared/components/profile-image/profile-image.component.ngfactory";
import * as i6 from "../../../shared/components/profile-image/profile-image.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../store/root-store.selectors";
import * as i9 from "@angular/common";
import * as i10 from "../startover-droplist/startover-droplist.component.ngfactory";
import * as i11 from "../startover-droplist/startover-droplist.component";
import * as i12 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i13 from "./startover-logo.component";
import * as i14 from "../../../+merchant/+layout/layout.service";
import * as i15 from "../../../core/auth.service";
import * as i16 from "../../../core/ui.service";
import * as i17 from "../../../+merchant/+dashboard/dashboard.service";
import * as i18 from "@angular/router";
var styles_StartoverLogoComponent = [i0.styles];
var RenderType_StartoverLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverLogoComponent, data: {} });
export { RenderType_StartoverLogoComponent as RenderType_StartoverLogoComponent };
function View_StartoverLogoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "dropdown-menu no-copyable-item"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [["class", "menuitem clickable-cursor"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], null, null); }
function View_StartoverLogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 10, "div", [["appClickElsewhere", ""], ["class", "btn-group clickable-cursor list"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "clickElsewhere"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickElsewhere" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).hide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.BsDropdownState, i2.BsDropdownState, []), i1.ɵdid(2, 212992, [["dd", 4]], 0, i2.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.BsDropdownConfig, i2.BsDropdownState], null, null), i1.ɵdid(3, 16384, null, 0, i4.ClickElsewhereDirective, [i1.ElementRef], null, { clickElsewhere: "clickElsewhere" }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "head-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "app-profile-image", [["class", "head"], ["dropdownToggle", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ProfileImageComponent_0, i5.RenderType_ProfileImageComponent)), i1.ɵdid(6, 147456, null, 0, i2.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i2.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i2.BsDropdownState], null, null), i1.ɵdid(7, 704512, null, 0, i6.ProfileImageComponent, [i7.DomSanitizer, i8.RootStoreSelectors, i1.ChangeDetectorRef], { id: [0, "id"], size: [1, "size"], chatType: [2, "chatType"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverLogoComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.BsDropdownMenuDirective, [i2.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var tmp_6_0 = null; var currVal_6 = (((tmp_6_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.layoutService.selectedChat$))) == null) ? null : tmp_6_0.id); var currVal_7 = 32; var currVal_8 = "Channel"; _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 6).isDisabled; var currVal_5 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_StartoverLogoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "startover-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "app-logo clickable-cursor"], ["href", "https://nandbox.com"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "nandbox Native App Builder logo"], ["draggable", "false"], ["height", "40"], ["src", "https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-startover-droplist", [["style", "margin-right: 10px;"]], null, [[null, "saveNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveNotify" === en)) {
        var pd_0 = (_co.saveCat($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_StartoverDroplistComponent_0, i10.RenderType_StartoverDroplistComponent)), i1.ɵdid(5, 638976, null, 0, i11.StartoverDroplistComponent, [i1.ChangeDetectorRef, i12.AppMgmtService], { category: [0, "category"], isDropDown: [1, "isDropDown"] }, { saveNotify: "saveNotify" }), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverLogoComponent_1)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(((_co.appManagementService == null) ? null : _co.appManagementService.selectedTempCat$))); var currVal_1 = true; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = ((_co.authService == null) ? null : _co.authService.token); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_StartoverLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-logo", [], null, null, null, View_StartoverLogoComponent_0, RenderType_StartoverLogoComponent)), i1.ɵdid(1, 638976, null, 0, i13.StartoverLogoComponent, [i14.LayoutService, i15.AuthService, i16.UiService, i17.DashboardService, i12.AppMgmtService, i18.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverLogoComponentNgFactory = i1.ɵccf("app-startover-logo", i13.StartoverLogoComponent, View_StartoverLogoComponent_Host_0, { page: "page", mode: "mode" }, { saveNotify: "saveNotify" }, []);
export { StartoverLogoComponentNgFactory as StartoverLogoComponentNgFactory };
