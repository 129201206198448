import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { PageDirection } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
import { DeleteCollectionMethod, GetCollectionMethod, GetCollectionProductsMethod, GetListCollectionsMethod, SetCollectionMethod, SetCollectionProductMethod } from 'src/models/collectionsUI';
import { CollectionsUIDispatchers } from './collectionsUI.dispatchers';
export class CollectionsUiEffects {
    constructor(actions$, _socketGateway, _collectionsUIDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._collectionsUIDispatchers = _collectionsUIDispatchers;
        this._store = _store;
        this.productsStoreResetPaging = this.actions$.pipe(ofType("UI_COLLECTIONS_RESET_PAGING" /* UI_COLLECTIONS_RESET_PAGING */), map((action) => {
            this._collectionsUIDispatchers.getCollectionsNextPage();
        }));
        this.getNextCollections = this.actions$.pipe(ofType("UI_GET_COLLECTIONS_NEXT_PAGE" /* UI_GET_COLLECTIONS_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.collectionsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, collectionUiState]) => {
            const action = val;
            const prevRequest = collectionUiState.previousRequest;
            if (!(collectionUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                collectionUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetListCollectionsMethod(0, collectionUiState.eop));
            }
            else {
                // this._uiProductsStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.getprevCollections = this.actions$.pipe(ofType("UI_GET_COLLECTIONS_PREV_PAGE" /* UI_GET_COLLECTIONS_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.collectionsUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, collectionUiState]) => {
            const action = val;
            const prevRequest = collectionUiState.previousRequest;
            if (!(collectionUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                collectionUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetListCollectionsMethod(1, collectionUiState.sop));
            }
            else {
                // this._uiProductsStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.updateCollection = this.actions$.pipe(ofType("UI_SET_COLLECTION_ITEM" /* UI_SET_COLLECTION_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetCollectionMethod(action.collection.id, action.collection.name, action.collection.status, action.collection.description, action.collection.image, action.collection.product_style, action.collection.json, action.collection.soft_id));
        }));
        this.getCollection = this.actions$.pipe(ofType("UI_GET_COLLECTION_ITEM" /* UI_GET_COLLECTION_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetCollectionMethod(action.id));
        }));
        this.setCollectionProducts = this.actions$.pipe(ofType("UI_SET_COLLECTION_PRODUCTS_ITEM" /* UI_SET_COLLECTION_PRODUCTS_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetCollectionProductMethod(action.collectionID, action.productsID));
        }));
        this.deleteCollectionItem = this.actions$.pipe(ofType("UI_DELETE_COLLECTION_ITEM" /* UI_DELETE_COLLECTION_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new DeleteCollectionMethod(action.id));
        }));
        this.getCollectionProducts = this.actions$.pipe(ofType("UI_GET_COLLECTION_PRODUCTS" /* UI_GET_COLLECTION_PRODUCTS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetCollectionProductsMethod(action.id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "productsStoreResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "getNextCollections", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "getprevCollections", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "updateCollection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "getCollection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "setCollectionProducts", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "deleteCollectionItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CollectionsUiEffects.prototype, "getCollectionProducts", void 0);
