<div
  class="message"
  appCancelDrag
  [class.float-opposite]="message?.position === 'RIGHT'"
>
  <!-- Start: Avatar section -->
  <div
    *ngIf="
      (chatType === 'Group' || isOneToOneReplie) &&
      !message?.isAdminReply &&
      message?.position === 'LEFT'
    "
    class="avatar no-copyable-item"
    [class.one-to-one]="isOneToOneReplie"
  >
    <app-profile-image
      [id]="message?.sender_id"
      [chatType]="'Individual'"
      [size]="30"
    >
    </app-profile-image>
  </div>
  <div
    *ngIf="
      isOneToOneReplie && message?.isAdminReply && message?.position === 'LEFT'
    "
    class="avatar no-copyable-item"
    [class.one-to-one]="isOneToOneReplie"
  >
    <div *ngIf="message?.sender_type !== 'Bot'">
      <app-profile-image
        [id]="message?.group_id"
        [chatType]="'Channel'"
        [size]="30"
      >
      </app-profile-image>
    </div>

    <div *ngIf="message?.sender_type === 'Bot'">
      <app-profile-image
        [id]="message?.sender_id"
        [chatType]="'Individual'"
        [size]="40"
      >
      </app-profile-image>
    </div>
  </div>
  <!-- End: Avatar section -->

  <!-- Start: Core section -->
  <div class="content">
    <div class="core">
      <!-- Start: Core Header -->

      <div
        class="header no-copyable-item"
        [class.one-to-one]="isOneToOneReplie"
      >
        <div
          *ngIf="message?.position === 'LEFT'"
          [innerHTML]="
            (uiService.getProfileByID$(message?.sender_id) | async)?.name
              | twemoji
          "
        ></div>

        <ng-container
          *ngIf="
            isOneToOneReplie &&
            message?.isAdminReply &&
            message?.position === 'LEFT'
          "
        >
          <div
            *ngIf="message?.sender_type === 'Bot'"
            class="header no-copyable-item"
            [innerHTML]="
              (uiService.getProfileByID$(message?.sender_id) | async)?.name
                | twemoji
            "
          ></div>
          <div
            *ngIf="message?.sender_type !== 'Bot'"
            class="header no-copyable-item"
            [innerHTML]="chatName | twemoji"
          ></div>
        </ng-container>
        <ng-container
          *ngIf="
            isOneToOneReplie &&
            message?.isAdminReply &&
            message?.position === 'RIGHT'
          "
        >
          <span [innerHTML]="chatName | twemoji"></span>
          <span> . </span>
          <span
            class="admin-name"
            [innerHTML]="
              (message?.loggedInIsSender
                ? (uiService.myPublicProfile$ | async)?.name
                : (uiService.getProfileByID$(message?.sender_id) | async)
                    ?.name) | twemoji
            "
          >
          </span>
        </ng-container>
        <!-- Start: action menu section -->
        <div
          class="btn-group clickable-cursor actions"
          dropdown
          *ngIf="!hideActions"
        >
          <div dropdownToggle type="button">
            <img
              src="./assets/img/post-heade-more.svg"
              class="post-header-more"
              alt="more"
            />
          </div>
          <div
            *dropdownMenu
            class="dropdown-menu action-menu"
            [class.dropdown-menu-right]="message?.position === 'RIGHT'"
            role="menu"
          >
            <div class="no-copyable-item clickable-cursor select">
              <app-message-options
                [message]="message"
                [isAdmin]="isAdmin"
                (recallNotfiy)="recallEvent()"
                (setTemplateNotfiy)="setTemplate()"
              ></app-message-options>
            </div>
          </div>
        </div>
        <!-- End: action menu section -->
      </div>
      <!-- End: Core Header -->

      <!-- Start: Core body -->
      <!-- Text section -->
      <app-text-cell
        *ngIf="
          message?.type === mType.TEXT &&
          (message?.web_page_preview !==
            linkPreviewStyle.WEB_PREVIEW_HIDE_LINK ||
            !message?.linkPreviewTitle) &&
          !message?.bg_color
        "
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        class="copyable-item text-cursor"
        dir="auto"
        [text]="message?.text"
        [message]="message"
      >
      </app-text-cell>

      <app-colored-text-cell
        *ngIf="
          message?.type === mType.TEXT &&
          (message?.web_page_preview !==
            linkPreviewStyle.WEB_PREVIEW_HIDE_LINK ||
            !message?.linkPreviewTitle) &&
          message?.bg_color
        "
        class="text"
        dir="auto"
        [text]="message?.text"
        [bgColor]="message?.bg_color"
      >
      </app-colored-text-cell>

      <app-instant-cell
        *ngIf="message?.type === mType.ARTICLE"
        class="text"
        dir="auto"
        [message]="message"
      >
      </app-instant-cell>

      <app-text-file-cell
        *ngIf="message?.type === mType.TEXT_FILE"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        class="copyable-item text-cursor"
        [localMedia]="message?.localMedia"
        [localMessageString]="message?.text"
      >
      </app-text-file-cell>

      <app-contact-cell
        *ngIf="message?.type === mType.CONTACT"
        class="no-copyable-item"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [phoneNumber]="message?.phone_number"
        [phoneName]="message?.contact_name"
      >
      </app-contact-cell>

      <!-- medoa section -->
      <app-image-cell
        *ngIf="
          message?.type === mType.PHOTO ||
          (message?.type === mType.GIF && !message?.media_id?.endsWith('mp4'))
        "
        class="no-copyable-item clickable-cursor"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [mediaStatus]="message?.mediaStatus"
        [percent]="message?.loadingProgress"
        [localMedia]="message?.localMedia"
        [localThumbnail]="message?.localThumbnail"
        [fileSize]="message?.file_size"
        [caption]="message?.caption"
        [height]="height"
        [width]="width"
        [themeColor]="themeColorMedia"
        [type]="message?.type"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
        (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
      >
      </app-image-cell>

      <app-gif-cell
        *ngIf="
          message?.type === mType.GIF && message?.media_id?.endsWith('mp4')
        "
        class="no-copyable-item clickable-cursor"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [percent]="message?.loadingProgress"
        [localMedia]="message?.localMedia"
        [localThumbnail]="message?.localThumbnail"
        [mediaStatus]="message?.mediaStatus"
        [fileSize]="message?.file_size"
        [height]="height"
        [width]="width"
        [caption]="message?.caption"
        [themeColor]="themeColorMedia"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
        (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
      >
      </app-gif-cell>

      <app-video-cell
        *ngIf="message?.type === mType.VIDEO"
        class="no-copyable-item clickable-cursor"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [localMedia]="message?.localMedia"
        [localThumbnail]="message?.localThumbnail"
        [percent]="message?.loadingProgress"
        [mediaStatus]="message?.mediaStatus"
        [fileSize]="message?.file_size"
        [mediaDuration]="message?.media_duration"
        [height]="height"
        [width]="width"
        [caption]="message?.caption"
        [themeColor]="themeColorMedia"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
        (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
      >
      </app-video-cell>

      <app-sticker-cell
        *ngIf="message?.type === mType.STICKER"
        class="no-copyable-item"
        [mediaStatus]="message?.mediaStatus"
        [percent]="message?.loadingProgress"
        [localMedia]="message?.localMedia"
        [themeColor]="themeColorFile"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
      >
      </app-sticker-cell>

      <app-map-cell
        *ngIf="message?.type === mType.LOCATION"
        class="no-copyable-item"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [locationName]="message?.location_name"
        [locationDetails]="message?.location_details"
        [width]="'246'"
        [height]="'185'"
        [latitude]="message?.latitude"
        [longitude]="message?.longitude"
      >
      </app-map-cell>

      <app-audio-cell
        *ngIf="message?.type === mType.VOICE || message?.type === mType.AUDIO"
        class="no-copyable-item"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [class.bubbel-right-theme]="
          message?.position === 'RIGHT' && !isOneToOneReplie
        "
        [class.bubbel-left-theme]="
          message?.position === 'LEFT' && !isOneToOneReplie
        "
        [class.post-theme]="isOneToOneReplie"
        [type]="message?.type"
        [percent]="message?.loadingProgress"
        [localMedia]="message?.localMedia"
        [mediaStatus]="message?.mediaStatus"
        [title]="message?.title"
        [caption]="message?.caption"
        [performer]="message?.performer"
        [fileSize]="message?.file_size"
        [mediaDuration]="message?.media_duration"
        [mainThemeColor]="themeColorFile"
        [secondThemeColor]="'#FFFFFF'"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
      >
        <app-profile-image
          [isSquare]="true"
          [id]="message.sender_id"
          [chatType]="
            message?.position === 'RIGHT' ? 'MyProfile' : 'Individual'
          "
          [size]="48"
        >
        </app-profile-image>
      </app-audio-cell>

      <app-file-cell
        *ngIf="message?.type === mType.DOCUMENT"
        class="no-copyable-item clickable-cursor"
        [ngClass]="messageStyle"
        [ngStyle]="cellColor"
        [localMedia]="message?.localMedia"
        [fileName]="message?.document_name"
        [fileSize]="message?.file_size"
        [mediaStatus]="message?.mediaStatus"
        [percent]="message?.loadingProgress"
        [themeColor]="'#fff'"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
      >
      </app-file-cell>

      <div class="calendar" *ngIf="message?.type === mType.CALENDAR">
        <div class="calendar-image">
          <svg class="svg-icon icon-icon">
            <use xlink:href="./assets/svg/app-sections.svg#icon-calendar"></use>
          </svg>
        </div>
        <div class="calendar-details">
          <span class="title">{{ message?.json?.title }}</span>
          <span class="desc">{{ message?.json?.description }}</span>
        </div>
      </div>

      <app-menu-holder
        *ngIf="message?.inline_menu"
        [menus]="menus"
        [messageDetails]="message"
        [bot]="true"
        (buttonClickedNotify)="buttonClicked($event)"
      ></app-menu-holder>

      <!-- Hello Button Cell! -->

      <!-- End: Core body -->

      <!-- Start: Core footer -->
      <div
        class="footer no-copyable-item"
        [class.float-opposite]="message?.position === 'RIGHT'"
        [class.one-to-one]="isOneToOneReplie"
      >
        <app-standard-date
          [timestamp]="message?.date"
          [isPost]="true"
        ></app-standard-date>
        <svg *ngIf="message?.position === 'RIGHT'" class="svg-icon icon-icon">
          <use [appMessageStatusIcon]="message?.status" xlink:href=""></use>
        </svg>
        <div
          *ngIf="
            chatType === 'Group' &&
            message?.message_id &&
            !message?.reply_to_message_id &&
            message?.numberOfReplies > 0
          "
          class="replies-counter"
        >
          {{ message?.numberOfReplies | countSize }}
          <span *ngIf="message?.numberOfReplies > 1">Replies</span>
          <span *ngIf="message?.numberOfReplies === 1">Reply</span>
        </div>
      </div>
      <!-- End: Core Footer -->
    </div>
    <!-- End: core sction -->

    <!-- Start: action section -->
    <div
      *ngIf="
        (chatType === 'Group' || isSubChat) &&
        !message?.reply_to_message_id &&
        message?.message_id
      "
      class="action no-copyable-item clickable-cursor select"
    >
      <span
        type="button"
        (click)="getReplies(message)"
        class="reply-button"
        [class.unread-replies]="message?.numberOfUnreadReplies"
      >
        <!-- <img src="./assets/img/reply-btn.svg" alt="" /> -->
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="./assets/svg/app-sections.svg#icon-reply-message"
          ></use>
        </svg>
      </span>
      <span *ngIf="message?.numberOfUnreadReplies" class="unread-counter chat">
        {{ message?.numberOfUnreadReplies | countSize }}
      </span>
    </div>
  </div>
  <!-- End: action section -->
</div>
