import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { ICoupon } from 'src/models/ICoupon';
import { CHANNELS_THEME_COLOR, UISections } from 'src/models/constants';
import { CouponsService } from 'src/app/+merchant/+dashboard/coupons/coupons.service';
import { COUPONS } from 'src/models/AppSections';
import { PrivilegesName } from 'src/models/privileges';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-coupons-pool',
  templateUrl: './coupons-pool.component.html',
  styleUrls: ['./coupons-pool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponsPoolComponent {
  @Input() canAddCoupon = true;

  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectCouponNotfiy = new EventEmitter<ICoupon>();

  showCouponFormModal = false;
  themeColor = CHANNELS_THEME_COLOR;
  currentSelectMessage: ICoupon = null;
  couponFet = COUPONS;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(
    public couponService: CouponsService,
    public dashboardService: DashboardService,
    public layoutService: LayoutService
  ) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formatMonth = month < 10 ? '0' + month : month;
    const formatDay = day < 10 ? `0${day}` : day;

    this.couponService.resetPaging('A', `${year}-${formatMonth}-${formatDay}`);
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(message: ICoupon) {
    this.currentSelectMessage = { ...message };
  }
  resetSelectMessage() {
    this.currentSelectMessage = null;
  }
  insertEvent() {
    this.selectCouponNotfiy.emit(this.currentSelectMessage);
  }
  toggleCouponFormModalVisibility(e: boolean) {
    this.showCouponFormModal = e;
  }

  TrackByFunction(index, item: ICoupon) {
    return item.couponId;
  }

  createNewItem() {
    this.dashboardService.setSection(UISections.COUPONS);
    this.couponService.createCoupon();
  }
}
