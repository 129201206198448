import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { TemplateDispatchers } from 'src/app/store/templates/templates.dispatchers';
import { AppTemplate } from 'src/models/AppTemplate';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService implements CanActivate {
  constructor(private _templateDispatchers: TemplateDispatchers) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return false;
  }

  public tmpId: any;

  receiveTemplates(templates: AppTemplate[]) {
    this._templateDispatchers.receiveTemplates(templates);
  }

  selectAppTemplate(template: AppTemplate) {
    this._templateDispatchers.selectAppTemplate(template);
  }

  setTempId(id) {
    this.tmpId = id;
  }
  getTempId() {
    return this.tmpId;
  }
}
