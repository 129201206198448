import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import { CollectionsUIActionTypes } from './collectionsUI.actions';
import * as CollectionsUIActions from './collectionsUI.actions';
import { PageDirection } from 'src/models/constants';

import * as isEqual from 'lodash.isequal';
import {
  DeleteCollectionMethod,
  GetCollectionMethod,
  GetCollectionProductsMethod,
  GetListCollectionsMethod,
  SetCollectionMethod,
  SetCollectionProductMethod
} from 'src/models/collectionsUI';
import { CollectionsUIDispatchers } from './collectionsUI.dispatchers';

@Injectable()
export class CollectionsUiEffects {
  @Effect({ dispatch: false })
  productsStoreResetPaging = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_COLLECTIONS_RESET_PAGING),
    map((action: CollectionsUIActions.ResetCollectionsPaging) => {
      this._collectionsUIDispatchers.getCollectionsNextPage();
    })
  );

  @Effect({ dispatch: false })
  getNextCollections = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_GET_COLLECTIONS_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.collectionsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, collectionUiState]) => {
      const action = <CollectionsUIActions.GetCollectionsNextPage>val;
      const prevRequest = collectionUiState.previousRequest;
      if (
        !(
          collectionUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          collectionUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetListCollectionsMethod(0, collectionUiState.eop)
        );
      } else {
        // this._uiProductsStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getprevCollections = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_GET_COLLECTIONS_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.collectionsUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, collectionUiState]) => {
      const action = <CollectionsUIActions.GetCollectionsPrevPage>val;
      const prevRequest = collectionUiState.previousRequest;
      if (
        !(
          collectionUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          collectionUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetListCollectionsMethod(1, collectionUiState.sop)
        );
      } else {
        // this._uiProductsStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  updateCollection = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_SET_COLLECTION_ITEM),
    map((action: CollectionsUIActions.SetCollectionItem) => {
      this._socketGateway.sendSocketMessage(
        new SetCollectionMethod(
          action.collection.id,
          action.collection.name,
          action.collection.status,
          action.collection.description,
          action.collection.image,
          action.collection.product_style,
          action.collection.json,
          action.collection.soft_id
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getCollection = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_GET_COLLECTION_ITEM),
    map((action: CollectionsUIActions.GetCollectionItem) => {
      this._socketGateway.sendSocketMessage(new GetCollectionMethod(action.id));
    })
  );

  @Effect({ dispatch: false })
  setCollectionProducts = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_SET_COLLECTION_PRODUCTS_ITEM),
    map((action: CollectionsUIActions.SetCollectionProducts) => {
      this._socketGateway.sendSocketMessage(
        new SetCollectionProductMethod(action.collectionID, action.productsID)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteCollectionItem = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_DELETE_COLLECTION_ITEM),
    map((action: CollectionsUIActions.DeleteCollectionItem) => {
      this._socketGateway.sendSocketMessage(
        new DeleteCollectionMethod(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  getCollectionProducts = this.actions$.pipe(
    ofType(CollectionsUIActionTypes.UI_GET_COLLECTION_PRODUCTS),
    map((action: CollectionsUIActions.GetCollectionProducts) => {
      this._socketGateway.sendSocketMessage(
        new GetCollectionProductsMethod(action.id)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _collectionsUIDispatchers: CollectionsUIDispatchers,
    private _store: Store<AppState>
  ) {}
}
