export interface IOption {
  key?: any;
  value?: any;
  selected?: boolean;
}

export const AWARD_TYPE_POINTS = 2;
export const AWARD_TYPE_COUPON = 1;

export const SCOPE: IOption[] = [];

export enum STATUS_OPTIONS {
  ACTIVE = 'A',
  DEACTIVE = 'N',
  EXPIRE = 'E'
}

export const EVENTS_ARRAY = [
  'Like',
  'Share',
  'Reply',
  'Join Channel Account',
  'Open Channel',
  'Forward',
  'Invite to Channel',
  'QR Code',
  'Button'
];
export const STATUS: IOption[] = [
  { key: 'Active', value: 'A' },
  { key: 'Inactive', value: 'N' },
  { key: 'Expired', value: 'E' }
];
export const SHARE: IOption[] = [
  { key: 'Yes', value: 1 },
  { key: 'No', value: 0 }
];

export const DAYS: IOption[] = [
  { key: 'Monday', value: 'Monday' },
  { key: 'Tuesday', value: 'Tuesday' },
  { key: 'Wednesday', value: 'Wednesday' },
  { key: 'Thursday', value: 'Thursday' },
  { key: 'Friday', value: 'Friday' },
  { key: 'Saturday', value: 'Saturday' },
  { key: 'Sunday', value: 'Sunday' }
];

export const EVENTS: IOption[] = [
  { key: 'Like', value: 1, selected: false },
  { key: 'Share', value: 2, selected: false },
  { key: 'Reply', value: 3, selected: false },
  { key: 'Join Channel Account', value: 5, selected: false },
  { key: 'Open Channel', value: 7, selected: false },
  { key: 'Forward', value: 8, selected: false },
  { key: 'Invite to Channel', value: 9, selected: false },
  { key: 'QR Code', value: 10, selected: false },
  { key: 'Button', value: 11, selected: false }
];

export const PER: IOption[] = [
  { key: 'HOUR', value: 'Hour' },
  { key: 'DAY', value: 'Day' },
  { key: 'WEEK', value: 'Week' },
  { key: 'MONTH', value: 'Month' }
];

export const ZONE: IOption[] = [
  {
    key: 'Pacific/Niue',
    value: 'Niue (GMT-11:00)'
  },
  {
    key: 'Pacific/Pago_Pago',
    value: 'Pago Pago (GMT-11:00)'
  },
  {
    key: 'Pacific/Honolulu',
    value: 'Hawaii Time (GMT-10:00)'
  },
  {
    key: 'Pacific/Rarotonga',
    value: 'Rarotonga (GMT-10:00)'
  },
  {
    key: 'Pacific/Tahiti',
    value: 'Tahiti (GMT-10:00)'
  },
  {
    key: 'Pacific/Marquesas',
    value: 'Marquesas (GMT-09:30)'
  },
  {
    key: 'America/Anchorage',
    value: 'Alaska Time (GMT-09:00)'
  },
  {
    key: 'Pacific/Gambier',
    value: 'Gambier (GMT-09:00)'
  },
  {
    key: 'America/Los_Angeles',
    value: 'Pacific Time (GMT-08:00)'
  },
  {
    key: 'America/Tijuana',
    value: 'Pacific Time - Tijuana (GMT-08:00)'
  },
  {
    key: 'America/Vancouver',
    value: 'Pacific Time - Vancouver (GMT-08:00)'
  },
  {
    key: 'America/Whitehorse',
    value: 'Pacific Time - Whitehorse (GMT-08:00)'
  },
  {
    key: 'Pacific/Pitcairn',
    value: 'Pitcairn (GMT-08:00)'
  },
  {
    key: 'America/Dawson_Creek',
    value: 'Mountain Time - Dawson Creek (GMT-07:00)'
  },
  {
    key: 'America/Denver',
    value: 'Mountain Time (GMT-07:00)'
  },
  {
    key: 'America/Edmonton',
    value: 'Mountain Time - Edmonton (GMT-07:00)'
  },
  {
    key: 'America/Hermosillo',
    value: 'Mountain Time - Hermosillo (GMT-07:00)'
  },
  {
    key: 'America/Mazatlan',
    value: 'Mountain Time - Chihuahua, Mazatlan (GMT-07:00)'
  },
  {
    key: 'America/Phoenix',
    value: 'Mountain Time - Arizona (GMT-07:00)'
  },
  {
    key: 'America/Yellowknife',
    value: 'Mountain Time - Yellowknife (GMT-07:00)'
  },
  {
    key: 'America/Belize',
    value: 'Belize (GMT-06:00)'
  },
  {
    key: 'America/Chicago',
    value: 'Central Time (GMT-06:00)'
  },
  {
    key: 'America/Costa_Rica',
    value: 'Costa Rica (GMT-06:00)'
  },
  {
    key: 'America/El_Salvador',
    value: 'El Salvador (GMT-06:00)'
  },
  {
    key: 'America/Guatemala',
    value: 'Guatemala (GMT-06:00)'
  },
  {
    key: 'America/Managua',
    value: 'Managua (GMT-06:00)'
  },
  {
    key: 'America/Mexico_City',
    value: 'Central Time - Mexico City (GMT-06:00)'
  },
  {
    key: 'America/Regina',
    value: 'Central Time - Regina (GMT-06:00)'
  },
  {
    key: 'America/Tegucigalpa',
    value: 'Central Time - Tegucigalpa (GMT-06:00)'
  },
  {
    key: 'America/Winnipeg',
    value: 'Central Time - Winnipeg (GMT-06:00)'
  },
  {
    key: 'Pacific/Galapagos',
    value: 'Galapagos (GMT-06:00)'
  },
  {
    key: 'America/Bogota',
    value: 'Bogota (GMT-05:00)'
  },
  {
    key: 'America/Cancun',
    value: 'America Cancun (GMT-05:00)'
  },
  {
    key: 'America/Cayman',
    value: 'Cayman (GMT-05:00)'
  },
  {
    key: 'America/Guayaquil',
    value: 'Guayaquil (GMT-05:00)'
  },
  {
    key: 'America/Havana',
    value: 'Havana (GMT-05:00)'
  },
  {
    key: 'America/Iqaluit',
    value: 'Eastern Time - Iqaluit (GMT-05:00)'
  },
  {
    key: 'America/Jamaica',
    value: 'Jamaica (GMT-05:00)'
  },
  {
    key: 'America/Lima',
    value: 'Lima (GMT-05:00)'
  },
  {
    key: 'America/Nassau',
    value: 'Nassau (GMT-05:00)'
  },
  {
    key: 'America/New_York',
    value: 'Eastern Time (GMT-05:00)'
  },
  {
    key: 'America/Panama',
    value: 'Panama (GMT-05:00)'
  },
  {
    key: 'America/Port-au-Prince',
    value: 'Port-au-Prince (GMT-05:00)'
  },
  {
    key: 'America/Rio_Branco',
    value: 'Rio Branco (GMT-05:00)'
  },
  {
    key: 'America/Toronto',
    value: 'Eastern Time - Toronto (GMT-05:00)'
  },
  {
    key: 'Pacific/Easter',
    value: 'Easter Island (GMT-05:00)'
  },
  {
    key: 'America/Caracas',
    value: 'Caracas (GMT-04:30)'
  },
  {
    key: 'America/Asuncion',
    value: 'Asuncion (GMT-03:00)'
  },
  {
    key: 'America/Barbados',
    value: 'Barbados (GMT-04:00)'
  },
  {
    key: 'America/Boa_Vista',
    value: 'Boa Vista (GMT-04:00)'
  },
  {
    key: 'America/Campo_Grande',
    value: 'Campo Grande (GMT-03:00)'
  },
  {
    key: 'America/Cuiaba',
    value: 'Cuiaba (GMT-03:00)'
  },
  {
    key: 'America/Curacao',
    value: 'Curacao (GMT-04:00)'
  },
  {
    key: 'America/Grand_Turk',
    value: 'Grand Turk (GMT-04:00)'
  },
  {
    key: 'America/Guyana',
    value: 'Guyana (GMT-04:00)'
  },
  {
    key: 'America/Halifax',
    value: 'Atlantic Time - Halifax (GMT-04:00)'
  },
  {
    key: 'America/La_Paz',
    value: 'La Paz (GMT-04:00)'
  },
  {
    key: 'America/Manaus',
    value: 'Manaus (GMT-04:00)'
  },
  {
    key: 'America/Martinique',
    value: 'Martinique (GMT-04:00)'
  },
  {
    key: 'America/Port_of_Spain',
    value: 'Port of Spain (GMT-04:00)'
  },
  {
    key: 'America/Porto_Velho',
    value: 'Porto Velho (GMT-04:00)'
  },
  {
    key: 'America/Puerto_Rico',
    value: 'Puerto Rico (GMT-04:00)'
  },
  {
    key: 'America/Santo_Domingo',
    value: 'Santo Domingo (GMT-04:00)'
  },
  {
    key: 'America/Thule',
    value: 'Thule (GMT-04:00)'
  },
  {
    key: 'Atlantic/Bermuda',
    value: 'Bermuda (GMT-04:00)'
  },
  {
    key: 'America/St_Johns',
    value: 'Newfoundland Time - St. Johns (GMT-03:30)'
  },
  {
    key: 'America/Araguaina',
    value: 'Araguaina (GMT-03:00)'
  },
  {
    key: 'America/Argentina/Buenos_Aires',
    value: 'Buenos Aires (GMT-03:00)'
  },
  {
    key: 'America/Bahia',
    value: 'Salvador (GMT-03:00)'
  },
  {
    key: 'America/Belem',
    value: 'Belem (GMT-03:00)'
  },
  {
    key: 'America/Cayenne',
    value: 'Cayenne (GMT-03:00)'
  },
  {
    key: 'America/Fortaleza',
    value: 'Fortaleza (GMT-03:00)'
  },
  {
    key: 'America/Godthab',
    value: 'Godthab (GMT-03:00)'
  },
  {
    key: 'America/Maceio',
    value: 'Maceio (GMT-03:00)'
  },
  {
    key: 'America/Miquelon',
    value: 'Miquelon (GMT-03:00)'
  },
  {
    key: 'America/Montevideo',
    value: 'Montevideo (GMT-03:00)'
  },
  {
    key: 'America/Paramaribo',
    value: 'Paramaribo (GMT-03:00)'
  },
  {
    key: 'America/Recife',
    value: 'Recife (GMT-03:00)'
  },
  {
    key: 'America/Santiago',
    value: 'Santiago (GMT-03:00)'
  },
  {
    key: 'America/Sao_Paulo',
    value: 'Sao Paulo (GMT-02:00)'
  },
  {
    key: 'Antarctica/Palmer',
    value: 'Palmer (GMT-03:00)'
  },
  {
    key: 'Antarctica/Rothera',
    value: 'Rothera (GMT-03:00)'
  },
  {
    key: 'Atlantic/Stanley',
    value: 'Stanley (GMT-03:00)'
  },
  {
    key: 'America/Noronha',
    value: 'Noronha (GMT-02:00)'
  },
  {
    key: 'Atlantic/South_Georgia',
    value: 'South Georgia (GMT-02:00)'
  },
  {
    key: 'America/Scoresbysund',
    value: 'Scoresbysund (GMT-01:00)'
  },
  {
    key: 'Atlantic/Azores',
    value: 'Azores (GMT-01:00)'
  },
  {
    key: 'Atlantic/Cape_Verde',
    value: 'Cape Verde (GMT-01:00)'
  },
  {
    key: 'Africa/Abidjan',
    value: 'Abidjan (GMT+00:00)'
  },
  {
    key: 'Africa/Accra',
    value: 'Accra (GMT+00:00)'
  },
  {
    key: 'Africa/Bissau',
    value: 'Bissau (GMT+00:00)'
  },
  {
    key: 'Africa/Casablanca',
    value: 'Casablanca (GMT+00:00)'
  },
  {
    key: 'Africa/El_Aaiun',
    value: 'El Aaiun (GMT+00:00)'
  },
  {
    key: 'Africa/Monrovia',
    value: 'Monrovia (GMT+00:00)'
  },
  {
    key: 'America/Danmarkshavn',
    value: 'Danmarkshavn (GMT+00:00)'
  },
  {
    key: 'Atlantic/Canary',
    value: 'Canary Islands (GMT+00:00)'
  },
  {
    key: 'Atlantic/Faroe',
    value: 'Faeroe (GMT+00:00)'
  },
  {
    key: 'Atlantic/Reykjavik',
    value: 'Reykjavik (GMT+00:00)'
  },
  {
    key: 'Etc/GMT',
    value: 'GMT (no daylight saving) (GMT+00:00)'
  },
  {
    key: 'Europe/Dublin',
    value: 'Dublin (GMT+00:00)'
  },
  {
    key: 'Europe/Lisbon',
    value: 'Lisbon (GMT+00:00)'
  },
  {
    key: 'Europe/London',
    value: 'London (GMT+00:00)'
  },
  {
    key: 'Africa/Algiers',
    value: 'Algiers (GMT+01:00)'
  },
  {
    key: 'Africa/Ceuta',
    value: 'Ceuta (GMT+01:00)'
  },
  {
    key: 'Africa/Lagos',
    value: 'Lagos (GMT+01:00)'
  },
  {
    key: 'Africa/Ndjamena',
    value: 'Ndjamena (GMT+01:00)'
  },
  {
    key: 'Africa/Tunis',
    value: 'Tunis (GMT+01:00)'
  },
  {
    key: 'Africa/Windhoek',
    value: 'Windhoek (GMT+02:00)'
  },
  {
    key: 'Europe/Amsterdam',
    value: 'Amsterdam (GMT+01:00)'
  },
  {
    key: 'Europe/Andorra',
    value: 'Andorra (GMT+01:00)'
  },
  {
    key: 'Europe/Belgrade',
    value: 'Central European Time - Belgrade (GMT+01:00)'
  },
  {
    key: 'Europe/Berlin',
    value: 'Berlin (GMT+01:00)'
  },
  {
    key: 'Europe/Brussels',
    value: 'Brussels (GMT+01:00)'
  },
  {
    key: 'Europe/Budapest',
    value: 'Budapest (GMT+01:00)'
  },
  {
    key: 'Europe/Copenhagen',
    value: 'Copenhagen (GMT+01:00)'
  },
  {
    key: 'Europe/Gibraltar',
    value: 'Gibraltar (GMT+01:00)'
  },
  {
    key: 'Europe/Luxembourg',
    value: 'Luxembourg (GMT+01:00)'
  },
  {
    key: 'Europe/Madrid',
    value: 'Madrid (GMT+01:00)'
  },
  {
    key: 'Europe/Malta',
    value: 'Malta (GMT+01:00)'
  },
  {
    key: 'Europe/Monaco',
    value: 'Monaco (GMT+01:00)'
  },
  {
    key: 'Europe/Oslo',
    value: 'Oslo (GMT+01:00)'
  },
  {
    key: 'Europe/Paris',
    value: 'Paris (GMT+01:00)'
  },
  {
    key: 'Europe/Prague',
    value: 'Central European Time - Prague (GMT+01:00)'
  },
  {
    key: 'Europe/Rome',
    value: 'Rome (GMT+01:00)'
  },
  {
    key: 'Europe/Stockholm',
    value: 'Stockholm (GMT+01:00)'
  },
  {
    key: 'Europe/Tirane',
    value: 'Tirane (GMT+01:00)'
  },
  {
    key: 'Europe/Vienna',
    value: 'Vienna (GMT+01:00)'
  },
  {
    key: 'Europe/Warsaw',
    value: 'Warsaw (GMT+01:00)'
  },
  {
    key: 'Europe/Zurich',
    value: 'Zurich (GMT+01:00)'
  },
  {
    key: 'Africa/Cairo',
    value: 'Cairo (GMT+02:00)'
  },
  {
    key: 'Africa/Johannesburg',
    value: 'Johannesburg (GMT+02:00)'
  },
  {
    key: 'Africa/Maputo',
    value: 'Maputo (GMT+02:00)'
  },
  {
    key: 'Africa/Tripoli',
    value: 'Tripoli (GMT+02:00)'
  },
  {
    key: 'Asia/Amman',
    value: 'Amman (GMT+02:00)'
  },
  {
    key: 'Asia/Beirut',
    value: 'Beirut (GMT+02:00)'
  },
  {
    key: 'Asia/Damascus',
    value: 'Damascus (GMT+02:00)'
  },
  {
    key: 'Asia/Gaza',
    value: 'Gaza (GMT+02:00)'
  },
  {
    key: 'Asia/Jerusalem',
    value: 'Jerusalem (GMT+02:00)'
  },
  {
    key: 'Asia/Nicosia',
    value: 'Nicosia (GMT+02:00)'
  },
  {
    key: 'Europe/Athens',
    value: 'Athens (GMT+02:00)'
  },
  {
    key: 'Europe/Bucharest',
    value: 'Bucharest (GMT+02:00)'
  },
  {
    key: 'Europe/Chisinau',
    value: 'Chisinau (GMT+02:00)'
  },
  {
    key: 'Europe/Helsinki',
    value: 'Helsinki (GMT+02:00)'
  },
  {
    key: 'Europe/Istanbul',
    value: 'Istanbul (GMT+02:00)'
  },
  {
    key: 'Europe/Kaliningrad',
    value: 'Moscow-01 - Kaliningrad (GMT+02:00)'
  },
  {
    key: 'Europe/Kiev',
    value: 'Kiev (GMT+02:00)'
  },
  {
    key: 'Europe/Riga',
    value: 'Riga (GMT+02:00)'
  },
  {
    key: 'Europe/Sofia',
    value: 'Sofia (GMT+02:00)'
  },
  {
    key: 'Europe/Tallinn',
    value: 'Tallinn (GMT+02:00)'
  },
  {
    key: 'Europe/Vilnius',
    value: 'Vilnius (GMT+02:00)'
  },
  {
    key: 'Africa/Khartoum',
    value: 'Khartoum (GMT+03:00)'
  },
  {
    key: 'Africa/Nairobi',
    value: 'Nairobi (GMT+03:00)'
  },
  {
    key: 'Antarctica/Syowa',
    value: 'Syowa (GMT+03:00)'
  },
  {
    key: 'Asia/Baghdad',
    value: 'Baghdad (GMT+03:00)'
  },
  {
    key: 'Asia/Qatar',
    value: 'Qatar (GMT+03:00)'
  },
  {
    key: 'Asia/Riyadh',
    value: 'Riyadh (GMT+03:00)'
  },
  {
    key: 'Europe/Minsk',
    value: 'Minsk (GMT+03:00)'
  },
  {
    key: 'Europe/Moscow',
    value: 'Moscow+00 - Moscow (GMT+03:00)'
  },
  {
    key: 'Asia/Tehran',
    value: 'Tehran (GMT+03:30)'
  },
  {
    key: 'Asia/Baku',
    value: 'Baku (GMT+04:00)'
  },
  {
    key: 'Asia/Dubai',
    value: 'Dubai (GMT+04:00)'
  },
  {
    key: 'Asia/Tbilisi',
    value: 'Tbilisi (GMT+04:00)'
  },
  {
    key: 'Asia/Yerevan',
    value: 'Yerevan (GMT+04:00)'
  },
  {
    key: 'Europe/Samara',
    value: 'Moscow+01 - Samara (GMT+04:00)'
  },
  {
    key: 'Indian/Mahe',
    value: 'Mahe (GMT+04:00)'
  },
  {
    key: 'Indian/Mauritius',
    value: 'Mauritius (GMT+04:00)'
  },
  {
    key: 'Indian/Reunion',
    value: 'Reunion (GMT+04:00)'
  },
  {
    key: 'Asia/Kabul',
    value: 'Kabul (GMT+04:30)'
  },
  {
    key: 'Antarctica/Mawson',
    value: 'Mawson (GMT+05:00)'
  },
  {
    key: 'Asia/Aqtau',
    value: 'Aqtau (GMT+05:00)'
  },
  {
    key: 'Asia/Aqtobe',
    value: 'Aqtobe (GMT+05:00)'
  },
  {
    key: 'Asia/Ashgabat',
    value: 'Ashgabat (GMT+05:00)'
  },
  {
    key: 'Asia/Dushanbe',
    value: 'Dushanbe (GMT+05:00)'
  },
  {
    key: 'Asia/Karachi',
    value: 'Karachi (GMT+05:00)'
  },
  {
    key: 'Asia/Tashkent',
    value: 'Tashkent (GMT+05:00)'
  },
  {
    key: 'Asia/Yekaterinburg',
    value: 'Moscow+02 - Yekaterinburg (GMT+05:00)'
  },
  {
    key: 'Indian/Kerguelen',
    value: 'Kerguelen (GMT+05:00)'
  },
  {
    key: 'Indian/Maldives',
    value: 'Maldives (GMT+05:00)'
  },
  {
    key: 'Asia/Calcutta',
    value: 'India Standard Time (GMT+05:30)'
  },
  {
    key: 'Asia/Colombo',
    value: 'Colombo (GMT+05:30)'
  },
  {
    key: 'Asia/Katmandu',
    value: 'Katmandu (GMT+05:45)'
  },
  {
    key: 'Antarctica/Vostok',
    value: 'Vostok (GMT+06:00)'
  },
  {
    key: 'Asia/Almaty',
    value: 'Almaty (GMT+06:00)'
  },
  {
    key: 'Asia/Bishkek',
    value: 'Bishkek (GMT+06:00)'
  },
  {
    key: 'Asia/Dhaka',
    value: 'Dhaka (GMT+06:00)'
  },
  {
    key: 'Asia/Omsk',
    value: 'Moscow+03 - Omsk, Novosibirsk (GMT+06:00)'
  },
  {
    key: 'Asia/Thimphu',
    value: 'Thimphu (GMT+06:00)'
  },
  {
    key: 'Indian/Chagos',
    value: 'Chagos (GMT+06:00)'
  },
  {
    key: 'Asia/Rangoon',
    value: 'Rangoon (GMT+06:30)'
  },
  {
    key: 'Indian/Cocos',
    value: 'Cocos (GMT+06:30)'
  },
  {
    key: 'Antarctica/Davis',
    value: 'Davis (GMT+07:00)'
  },
  {
    key: 'Asia/Bangkok',
    value: 'Bangkok (GMT+07:00)'
  },
  {
    key: 'Asia/Hovd',
    value: 'Hovd (GMT+07:00)'
  },
  {
    key: 'Asia/Jakarta',
    value: 'Jakarta (GMT+07:00)'
  },
  {
    key: 'Asia/Krasnoyarsk',
    value: 'Moscow+04 - Krasnoyarsk (GMT+07:00)'
  },
  {
    key: 'Asia/Saigon',
    value: 'Hanoi (GMT+07:00)'
  },
  {
    key: 'Indian/Christmas',
    value: 'Christmas (GMT+07:00)'
  },
  {
    key: 'Antarctica/Casey',
    value: 'Casey (GMT+08:00)'
  },
  {
    key: 'Asia/Brunei',
    value: 'Brunei (GMT+08:00)'
  },
  {
    key: 'Asia/Choibalsan',
    value: 'Choibalsan (GMT+08:00)'
  },
  {
    key: 'Asia/Hong_Kong',
    value: 'Hong Kong (GMT+08:00)'
  },
  {
    key: 'Asia/Irkutsk',
    value: 'Moscow+05 - Irkutsk (GMT+08:00)'
  },
  {
    key: 'Asia/Kuala_Lumpur',
    value: 'Kuala Lumpur (GMT+08:00)'
  },
  {
    key: 'Asia/Macau',
    value: 'Macau (GMT+08:00)'
  },
  {
    key: 'Asia/Makassar',
    value: 'Makassar (GMT+08:00)'
  },
  {
    key: 'Asia/Manila',
    value: 'Manila (GMT+08:00)'
  },
  {
    key: 'Asia/Shanghai',
    value: 'China Time - Beijing (GMT+08:00)'
  },
  {
    key: 'Asia/Singapore',
    value: 'Singapore (GMT+08:00)'
  },
  {
    key: 'Asia/Taipei',
    value: 'Taipei (GMT+08:00)'
  },
  {
    key: 'Asia/Ulaanbaatar',
    value: 'Ulaanbaatar (GMT+08:00)'
  },
  {
    key: 'Australia/Perth',
    value: 'Western Time - Perth (GMT+08:00)'
  },
  {
    key: 'Asia/Pyongyang',
    value: 'Pyongyang (GMT+08:30)'
  },
  {
    key: 'Asia/Dili',
    value: 'Dili (GMT+09:00)'
  },
  {
    key: 'Asia/Jayapura',
    value: 'Jayapura (GMT+09:00)'
  },
  {
    key: 'Asia/Seoul',
    value: 'Seoul (GMT+09:00)'
  },
  {
    key: 'Asia/Tokyo',
    value: 'Tokyo (GMT+09:00)'
  },
  {
    key: 'Asia/Yakutsk',
    value: 'Moscow+06 - Yakutsk (GMT+09:00)'
  },
  {
    key: 'Pacific/Palau',
    value: 'Palau (GMT+09:00)'
  },
  {
    key: 'Australia/Adelaide',
    value: 'Central Time - Adelaide (GMT+10:30)'
  },
  {
    key: 'Australia/Darwin',
    value: 'Central Time - Darwin (GMT+09:30)'
  },
  {
    key: 'Antarctica/DumontDUrville',
    value: "Dumont D'Urville (GMT+10:00)"
  },
  {
    key: 'Asia/Magadan',
    value: 'Moscow+07 - Magadan (GMT+10:00)'
  },
  {
    key: 'Asia/Vladivostok',
    value: 'Moscow+07 - Yuzhno-Sakhalinsk (GMT+10:00)'
  },
  {
    key: 'Australia/Brisbane',
    value: 'Eastern Time - Brisbane (GMT+10:00)'
  },
  {
    key: 'Australia/Hobart',
    value: 'Eastern Time - Hobart (GMT+11:00)'
  },
  {
    key: 'Australia/Sydney',
    value: 'Eastern Time - Melbourne, Sydney (GMT+11:00)'
  },
  {
    key: 'Pacific/Chuuk',
    value: 'Truk (GMT+10:00)'
  },
  {
    key: 'Pacific/Guam',
    value: 'Guam (GMT+10:00)'
  },
  {
    key: 'Pacific/Port_Moresby',
    value: 'Port Moresby (GMT+10:00)'
  },
  {
    key: 'Pacific/Efate',
    value: 'Efate (GMT+11:00)'
  },
  {
    key: 'Pacific/Guadalcanal',
    value: 'Guadalcanal (GMT+11:00)'
  },
  {
    key: 'Pacific/Kosrae',
    value: 'Kosrae (GMT+11:00)'
  },
  {
    key: 'Pacific/Norfolk',
    value: 'Norfolk (GMT+11:00)'
  },
  {
    key: 'Pacific/Noumea',
    value: 'Noumea (GMT+11:00)'
  },
  {
    key: 'Pacific/Pohnpei',
    value: 'Ponape (GMT+11:00)'
  },
  {
    key: 'Asia/Kamchatka',
    value: 'Moscow+09 - Petropavlovsk-Kamchatskiy (GMT+12:00)'
  },
  {
    key: 'Pacific/Auckland',
    value: 'Auckland (GMT+13:00)'
  },
  {
    key: 'Pacific/Fiji',
    value: 'Fiji (GMT+13:00)'
  },
  {
    key: 'Pacific/Funafuti',
    value: 'Funafuti (GMT+12:00)'
  },
  {
    key: 'Pacific/Kwajalein',
    value: 'Kwajalein (GMT+12:00)'
  },
  {
    key: 'Pacific/Majuro',
    value: 'Majuro (GMT+12:00)'
  },
  {
    key: 'Pacific/Nauru',
    value: 'Nauru (GMT+12:00)'
  },
  {
    key: 'Pacific/Tarawa',
    value: 'Tarawa (GMT+12:00)'
  },
  {
    key: 'Pacific/Wake',
    value: 'Wake (GMT+12:00)'
  },
  {
    key: 'Pacific/Wallis',
    value: 'Wallis (GMT+12:00)'
  },
  {
    key: 'Pacific/Apia',
    value: 'Apia (GMT+14:00)'
  },
  {
    key: 'Pacific/Enderbury',
    value: 'Enderbury (GMT+13:00)'
  },
  {
    key: 'Pacific/Fakaofo',
    value: 'Fakaofo (GMT+13:00)'
  },
  {
    key: 'Pacific/Tongatapu',
    value: 'Tongatapu (GMT+13:00)'
  },
  {
    key: 'Pacific/Kiritimati',
    value: 'Kiritimati (GMT+14:00)'
  }
];
