import {
  Input,
  Directive,
  OnChanges,
  ElementRef,
  Renderer2
} from '@angular/core';

import { MessageSendingStatus } from 'src/models/constants';

@Directive({
  selector: '[appMessageStatusIcon]'
})
export class MessageStatusIconDirective implements OnChanges {
  @Input() appMessageStatusIcon: string;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges() {
    switch (this.appMessageStatusIcon) {
      case MessageSendingStatus.PENDING:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-pending'
        );
        break;
      case MessageSendingStatus.SENT:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-sent'
        );
        break;
      case MessageSendingStatus.DELIVERED:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-delivered'
        );
        break;
      case MessageSendingStatus.SEEN:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-seen'
        );
        break;
      default:
        break;
    }
  }
}
