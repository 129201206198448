import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges
} from '@angular/core';

import { MediaCenterService } from '../media-center.service';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IMediaCenter, MediaCenterPrams } from 'src/models/IMediaCenter';
import { MessageMediaStatus, MessageTypes } from 'src/models/constants';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

@Component({
  selector: 'app-media-center-modal',
  templateUrl: './media-center-modal.component.html',
  styleUrls: ['./media-center-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterModalComponent implements OnChanges, OnInit {
  @Input() mimeTypes: string;
  @Input() isPhotoMedia: boolean;
  @Input() tabID: string;
  @Input() groupID: string;
  @Input() botID: string;
  @Input() hd: number;
  @Input() aspectRatio: string;
  @Input() backgroundColor: string;
  @Input() resizeToHeight = 300;
  @Input() resizeToWidth = 300;
  @Input() enableFileMetaData = false;
  @Output() mediaCenterNotify = new EventEmitter<IMediaCenter>();
  @Output() cancelNotify = new EventEmitter<boolean>(false);

  toggleCropermoduel = false;

  messageTypes: typeof MessageTypes = MessageTypes;

  mediaCenterType: MediaCenterPrams = {
    type: null,
    tabID: null,
    groupID: null,
    botID: null,
    hd: null,
    ar: '',
    bg: ''
  };

  imageFile: ISelectedFiles;
  croppedImage: any = '';
  imageChangedEvent: any = '';
  selectedFile: any;
  aspectRatioValue: any;
  constructor(
    private _mediaCenterService: MediaCenterService,
    public layoutService: LayoutService
  ) {}
  ngOnInit(): void {
    const type = this.isPhotoMedia ? 'image' : null;
    this._mediaCenterService.getMyMediaGallery(type);
  }

  ngOnChanges() {
    this.mediaCenterType = {
      ...this.mediaCenterType,
      type: this.isPhotoMedia ? this.messageTypes.PHOTO : null,
      tabID: this.tabID,
      groupID: this.groupID,
      botID: this.botID,
      ar: this.aspectRatio,
      bg: this.backgroundColor
    };
    if (this.aspectRatio) {
      const test = this.aspectRatio.split('x');
      this.aspectRatioValue = Number(test[0]) / Number(test[1]);
    }
  }

  mediaCenterEvent(item: IMediaCenter) {
    if (
      item.mediaStatus === MessageMediaStatus.DOWNLOADED ||
      item.mediaStatus === MessageMediaStatus.UPLOADED ||
      item.url.includes('http')
    ) {
      this.mediaCenterNotify.emit(item);
    }
  }
  mediaCenterTypeEvent(type: string) {
    this.mediaCenterType = { ...this.mediaCenterType, type };
  }

  fileSelectedEvent(event: ISelectedFiles) {
    if (this.hd) {
      this.mediaCenterType.hd = this.hd;
    }
    if (this.mediaCenterType.type !== this.messageTypes.PHOTO) {
      this._mediaCenterService.mediaCenterUploading(
        event,
        this.mediaCenterType
      );
    } else {
      this.toggleCropermoduel = true;
      this.imageChangedEvent = event;
      if (event.height && event.width) {
        this.resizeToHeight = 0;
        this.resizeToWidth = 0;
        this.aspectRatioValue = event.width / event.height;
        if (event.height >= event.width) {
          if (event.height > 512) {
            this.resizeToHeight = 512;
          } else {
            this.resizeToHeight = event.height;
          }
        } else {
          if (event.width > 512) {
            this.resizeToWidth = 512;
          } else {
            this.resizeToWidth = event.width;
          }
        }
      }
      this.selectedFile = event.localFile;
    }
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
    this.imageFile = event.file;
    this.imageFile.localFile = this.getBlob(this.croppedImage);
  }

  getBlob(b64Data) {
    const contentType = 'image/png';
    const sliceSize = 512;

    b64Data = b64Data.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const b: any = blob;

    b.lastModifiedDate = new Date();
    b.name = 'uploadedImage.png';

    return b;
  }

  imageLoaded() {
    // this.showCropper = true;
    // show cropper
  }
  loadImageFailed() {
    // show message
  }

  closePreview() {
    this.toggleCropermoduel = false;
    this.selectedFile = null;
    this.imageChangedEvent = null;
  }

  onFilesSelected(imageFile: ISelectedFiles) {
    const image = {
      ...imageFile,
      type: this.messageTypes.PHOTO,
      aspectRatio: this.aspectRatio
    };
    this.toggleCropermoduel = false;
    this.selectedFile = null;
    this.imageChangedEvent = null;
    this._mediaCenterService.mediaCenterUploading(image, this.mediaCenterType);
  }

  cancelNotifyClick() {
    this.cancelNotify.emit(true);
  }
}
