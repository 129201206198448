import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { BotMenuButton } from 'src/models/IBotMenu';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-menus-rows',
  templateUrl: './menus-rows.component.html',
  styleUrls: ['./menus-rows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenusRowsComponent implements OnInit {
  @Output() styleSelectedNotify = new EventEmitter<BotMenuButton>();
  @Output() cancelNotify = new EventEmitter<null>();
  @Input() button = false;
  @Input() length: number;
  @Input() isApp: boolean;
  defaultBtn: BotMenuButton = {};
  constructor(public mobileStoreService: MobileWorkFlowStoreService) {}

  ngOnInit() {}
  selectedStyle(btn) {
    this.defaultBtn = btn;
  }

  finalSelectedStyle() {
    if (this.defaultBtn.button_style) {
      this.styleSelectedNotify.emit(this.defaultBtn);
    }
  }
  cancelEvent() {
    this.cancelNotify.emit();
  }
}
