/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-replies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../cells/post-cell/post-cell.component.ngfactory";
import * as i3 from "../../cells/post-cell/post-cell.component";
import * as i4 from "../../core/ui.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../core/auth.service";
import * as i7 from "../../core/storage.service";
import * as i8 from "../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i9 from "@angular/common";
import * as i10 from "../../cells/reply-cell/reply-cell.component.ngfactory";
import * as i11 from "../../cells/reply-cell/reply-cell.component";
import * as i12 from "./channel-replies.component";
var styles_ChannelRepliesComponent = [i0.styles];
var RenderType_ChannelRepliesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelRepliesComponent, data: {} });
export { RenderType_ChannelRepliesComponent as RenderType_ChannelRepliesComponent };
function View_ChannelRepliesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-cell", [], null, null, null, i2.View_PostCellComponent_0, i2.RenderType_PostCellComponent)), i1.ɵdid(1, 770048, null, 0, i3.PostCellComponent, [i4.UiService, i5.DomSanitizer, i6.AuthService, i7.StorageService, i8.AppMgmtService], { chat: [0, "chat"], message: [1, "message"], isSubChat: [2, "isSubChat"], fixedShare: [3, "fixedShare"], isPostScreen: [4, "isPostScreen"], hideMenu: [5, "hideMenu"], isPublicChannel: [6, "isPublicChannel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chat; var currVal_1 = _co.message; var currVal_2 = _co.isSubChat; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.hideMenu; var currVal_6 = _co.isPublicChannel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ChannelRepliesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[3, 0], ["postCell", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChannelRepliesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i10.View_ReplyCellComponent_0, i10.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i11.ReplyCellComponent, [i4.UiService], { member: [0, "member"], parentPost: [1, "parentPost"], chatId: [2, "chatId"], isSubChat: [3, "isSubChat"], isScreenMessages: [4, "isScreenMessages"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.message; var currVal_2 = ((_co.chat == null) ? null : _co.chat.id); var currVal_3 = _co.isSubChat; var currVal_4 = _co.isScreenMessages; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var tmp_5_0 = null; var currVal_5 = (_v.context.last ? _co.onLastElement((((tmp_5_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_5_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_5); }); }
function View_ChannelRepliesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_5)), i1.ɵdid(1, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChannelRepliesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i10.View_ReplyCellComponent_0, i10.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i11.ReplyCellComponent, [i4.UiService], { member: [0, "member"], chatId: [1, "chatId"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co.chat == null) ? null : _co.chat.id); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var tmp_2_0 = null; var currVal_2 = (_v.context.last ? _co.onLastElement((((tmp_2_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_2_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_2); }); }
function View_ChannelRepliesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_7)), i1.ɵdid(1, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChannelRepliesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "replies-sec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["messagesContainer", 1]], null, 6, "div", [["class", "replies"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["style", "font-size: 15px; font-weight: 500; color: #2d2d2d; margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Replies "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_4)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_6)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.message; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postHight; _ck(_v, 1, 0, currVal_0); }); }
function View_ChannelRepliesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "notified"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-notifications"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get Notified Here"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This is where you\u2019ll see all your notifications."]))], null, null); }
function View_ChannelRepliesComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i10.View_ReplyCellComponent_0, i10.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i11.ReplyCellComponent, [i4.UiService], { member: [0, "member"], parentPost: [1, "parentPost"], chatId: [2, "chatId"], isSubChat: [3, "isSubChat"], isScreenMessages: [4, "isScreenMessages"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.message; var currVal_2 = ((_co.chat == null) ? null : _co.chat.id); var currVal_3 = _co.isSubChat; var currVal_4 = _co.isScreenMessages; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var tmp_5_0 = null; var currVal_5 = (_v.context.last ? _co.onLastElement((((tmp_5_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_5_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_5); }); }
function View_ChannelRepliesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_11)), i1.ɵdid(1, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChannelRepliesComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "margin-bottom: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i10.View_ReplyCellComponent_0, i10.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i11.ReplyCellComponent, [i4.UiService], { member: [0, "member"], chatId: [1, "chatId"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co.chat == null) ? null : _co.chat.id); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var tmp_2_0 = null; var currVal_2 = (_v.context.last ? _co.onLastElement((((tmp_2_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_2_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_2); }); }
function View_ChannelRepliesComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_13)), i1.ɵdid(1, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChannelRepliesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "replies-sec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["messagesContainer", 1]], null, 6, "div", [["class", "replies"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_10)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_12)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (_co.message && ((((tmp_0_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_0_0.channelMemberList.length) > 0)); _ck(_v, 3, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (!_co.message && ((((tmp_1_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_1_0.channelMemberList.length) > 0)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ChannelRepliesComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { scrollContainer: 0 }), i1.ɵqud(402653184, 2, { messagesContainer: 0 }), i1.ɵqud(671088640, 3, { postCell: 0 }), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["ScrollContainer", 1]], null, 12, "div", [["class", "copyable-item scrollable-body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "content-scroll-area"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_3)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_8)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_9)), i1.ɵdid(14, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "header-scroll-area"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 6, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = (_co.hideRepliesSec && ((((tmp_1_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_1_0.channelMemberList.length) > 0)); _ck(_v, 8, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = (_co.isNotifications && !(((tmp_2_0 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_2_0.channelMemberList)); _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.hideRepliesSec; _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ChannelRepliesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-channel-replies", [], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChannelRepliesComponent_0, RenderType_ChannelRepliesComponent)), i1.ɵdid(1, 4964352, null, 0, i12.ChannelRepliesComponent, [i4.UiService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelRepliesComponentNgFactory = i1.ɵccf("app-channel-replies", i12.ChannelRepliesComponent, View_ChannelRepliesComponent_Host_0, { message: "message", chat: "chat", selected: "selected", isSubChat: "isSubChat", hideMenu: "hideMenu", isNotifications: "isNotifications", hidePostCell: "hidePostCell", isScreenMessages: "isScreenMessages", hideRepliesSec: "hideRepliesSec", isPublicChannel: "isPublicChannel" }, { postHightNotifier: "postHightNotifier" }, []);
export { ChannelRepliesComponentNgFactory as ChannelRepliesComponentNgFactory };
