import {
  Injectable,
  ComponentRef,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef
} from '@angular/core';

import { UiService } from '../core/ui.service';
import { SnackbarNotifierComponent } from './snackbar-notifier/snackbar-notifier.component';

@Injectable({
  providedIn: 'root'
})
export class AbstractNotifiersService {
  snackbarNotifierComponentRef: ComponentRef<SnackbarNotifierComponent>;
  public isPopup = false;
  constructor(
    private _uiService: UiService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  showPopupNotifierMessage(msg: string, title?: string) {
    this._uiService.showPopupMessage(msg, title);
  }

  showSnakbarNotifierMessage(
    m: string,
    showRefresh: boolean,
    t?: string,
    info?: boolean
  ) {
    this.appendSnackbarNotifierComponentToBody(m, showRefresh, t, info);
  }

  /**
   * Snakbar notifier dynmic component
   */
  private appendSnackbarNotifierComponentToBody(
    message: string,
    showRefresh: boolean,
    title?: string,
    info?: boolean
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SnackbarNotifierComponent
    );
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    // componentRef.instance.onClose.subscribe(() => {
    //   this.removeSnackbarNotifierComponentFromBody();
    // });

    componentRef.instance.refresh = showRefresh;
    componentRef.instance.message = message;
    componentRef.instance.title = title;
    componentRef.instance.info = info;
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.snackbarNotifierComponentRef = componentRef;
  }

  private removeSnackbarNotifierComponentFromBody() {
    this.appRef.detachView(this.snackbarNotifierComponentRef.hostView);
    this.snackbarNotifierComponentRef.destroy();
  }
}
