<div #ScrollContainer
  (scroll)="onScroll($event)"
  class="copyable-item scrollable-body">
  <div class="content-scroll-area">
    <div #postCell
      *ngIf="message">
      <app-post-cell *ngIf="message"
        [message]="message"
        [chat]="chat"
        [isSubChat]="isSubChat"
        [fixedShare]="true"
        [isPostScreen]="true"
        [isPublicChannel]="isPublicChannel"
        [hideMenu]="hideMenu">
      </app-post-cell>
    </div>
    <div class="replies-sec"
      *ngIf="
        hideRepliesSec &&
        (profilesRepliedToChannel$ | async)?.channelMemberList.length > 0
      ">
      <div class="replies"
        #messagesContainer
        [style.height.px]="postHight">
        <div style="font-size: 15px; font-weight: 500; color: #2d2d2d; margin-bottom: 10px;">
          Replies
        </div>
        <ng-template [ngIf]="message">
          <div *ngFor="
              let profile of (profilesRepliedToChannel$ | async)
                ?.channelMemberList;
              trackBy: TrackByFunction;
              let last = last
            "
            style="margin-bottom: 20px;">
            <app-reply-cell [member]="profile"
              [chatId]="chat?.id"
              [parentPost]="message"
              [isSubChat]="isSubChat"
              [isScreenMessages]="isScreenMessages">
            </app-reply-cell>
            {{
            last
            ? onLastElement(
            (profilesRepliedToChannel$ | async)?.totalNumberOfPages
            )
            : ''
            }}
          </div>
        </ng-template>
        <ng-template [ngIf]="!message">
          <div *ngFor="
              let profile of (uiService.profilesRepliedToAdmin$ | async)
                ?.channelMemberList;
              trackBy: TrackByFunction;
              let last = last
            "
            style="margin-bottom: 20px;">
            <app-reply-cell [member]="profile"
              [chatId]="chat?.id">
            </app-reply-cell>
            {{
            last
            ? onLastElement(
            (uiService.profilesRepliedToAdmin$ | async)
            ?.totalNumberOfPages
            )
            : ''
            }}
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="
        isNotifications &&
        !(uiService.profilesRepliedToAdmin$ | async)?.channelMemberList
      "
      class="notified">
      <svg class="svg-icon icon-icon">
        <use xlink:href="/assets/svg/app-extra-icons.svg#icon-notifications"></use>
      </svg>
      <div class="title">Get Notified Here</div>
      <div class="desc">This is where you’ll see all your notifications.</div>
    </div>
    <div class="replies-sec"
      *ngIf="!hideRepliesSec">
      <div class="replies"
        #messagesContainer>
        <ng-template [ngIf]="
            message &&
            (profilesRepliedToChannel$ | async)?.channelMemberList.length > 0
          ">
          <div *ngFor="
              let profile of (profilesRepliedToChannel$ | async)
                ?.channelMemberList;
              trackBy: TrackByFunction;
              let last = last
            "
            style="margin-bottom: 20px;">
            <app-reply-cell [member]="profile"
              [chatId]="chat?.id"
              [parentPost]="message"
              [isSubChat]="isSubChat"
              [isScreenMessages]="isScreenMessages">
            </app-reply-cell>
            {{
            last
            ? onLastElement(
            (profilesRepliedToChannel$ | async)?.totalNumberOfPages
            )
            : ''
            }}
          </div>
        </ng-template>
        <ng-template [ngIf]="
            !message &&
            (uiService.profilesRepliedToAdmin$ | async)?.channelMemberList
              .length > 0
          ">
          <div *ngFor="
              let profile of (uiService.profilesRepliedToAdmin$ | async)
                ?.channelMemberList;
              trackBy: TrackByFunction;
              let last = last
            "
            style="margin-bottom: 20px;">
            <app-reply-cell [member]="profile"
              [chatId]="chat?.id">
            </app-reply-cell>
            {{
            last
            ? onLastElement(
            (uiService.profilesRepliedToAdmin$ | async)
            ?.totalNumberOfPages
            )
            : ''
            }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="header-scroll-area"></div>
</div>