import {
  GET_MAP_TRIP_METHOD,
  GET_MAP_TRIPS_METHOD,
  REMOVE_MAP_TRIP_METHOD,
  CREATE_MAP_TRIP_METHOD,
  CREATE_MAP_TRIP_REF,
  SET_MAP_TRIP_METHOD,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE
} from './constants';

export interface MapTrip {
  id?: string; // The key
  duration?: string; // "100"
  start_time?: string; //  "09:30:00"
  start_location?: string; // "Cairo Stadium"
  route_id?: string; // "102" // From Map Route table
  timezone?: string; // "GMT+02:00" // will not be used in calculations
  service_id?: string; // "102" // From Map Service table
  name?: string; // "Nasr City - Haram"
  end_time?: string; // "19:45:00"
  end_location?: string; //  "Nasr El din"
  type?: string; // "0" // 1 means temp ex:Kareem app, the trip is created on the fly in case of 1,but 0 means permanent
  every?: string; // "30"
  trip_id?: string;
}

export interface MapTripUI {
  currentPage?: MapTrip[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  currentMapTripScreen?: string;
}

export class GetMapTrip {
  readonly method = GET_MAP_TRIP_METHOD;
  constructor(public trip_id: string) {}
}

export class GetMapTrips {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_TRIPS_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public hash?: string
  ) {}
}

export class RemoveMapTrip {
  readonly method = REMOVE_MAP_TRIP_METHOD;
  constructor(public id: string) {}
}

export class CreateMapTrip {
  readonly method = CREATE_MAP_TRIP_METHOD;
  readonly reference = CREATE_MAP_TRIP_REF;
  constructor(mapTrip: MapTrip) {
    for (const key in mapTrip) {
      if (mapTrip.hasOwnProperty(key)) {
        this[key] = mapTrip[key];
      }
    }
  }
}

export class SetMapTrip {
  readonly method = SET_MAP_TRIP_METHOD;
  constructor(mapTrip: MapTrip) {
    for (const key in mapTrip) {
      if (mapTrip.hasOwnProperty(key)) {
        this[key] = mapTrip[key];
      }
    }
  }
}
