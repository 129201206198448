import { IRepliesEOP, UserReplyEOP } from '../../../models/IRepliesEOP';
import * as HistoryRepliesActions from './repliesEOP.actions';

export function repliesEOPReducer(
  state: IRepliesEOP[] = [],
  action: HistoryRepliesActions.RepliesEOPActions
): IRepliesEOP[] {
  switch (action.type) {
    case HistoryRepliesActions.RepliesEOPTypes.UPDATE_FIRST_DEPTH_EOP:
      if (isNewRecord(action.parentMsgID, state)) {
        const newItem: IRepliesEOP = {};
        newItem.messageID = action.parentMsgID;
        newItem.eopFirstDepth = action.eopFirstDepth;
        newItem.chatID = action.chatID;
        return [newItem, ...state];
      } else {
        return state.map(replyItem => {
          if (replyItem.messageID === action.parentMsgID) {
            const updatedReplyEop: IRepliesEOP = {};
            updatedReplyEop.eopFirstDepth = action.eopFirstDepth;
            return { ...replyItem, ...updatedReplyEop };
          }
          return replyItem;
        });
      }
    case HistoryRepliesActions.RepliesEOPTypes.UPDATE_SECOND_DEPTH_EOP:
      return state.map(replyItem => {
        if (replyItem.messageID === action.parentMsgID) {
          // there should always be an entry for the parent message at
          // this stage as the second level can't exist without first level
          let updatedUsersList = replyItem.usersRepliesEOPList;
          if (
            replyItem.usersRepliesEOPList &&
            isUserRecordExist(action.userID, replyItem.usersRepliesEOPList)
          ) {
            updatedUsersList = replyItem.usersRepliesEOPList.map(item => {
              if (item.userID === action.userID) {
                const userItem: UserReplyEOP = {};
                userItem.eopSecondDepth = action.eopSecondDepth;
                return { ...item, ...userItem };
              }
              return item;
            });
          } else {
            const userItem: UserReplyEOP = {};
            userItem.userID = action.userID;
            userItem.eopSecondDepth = action.eopSecondDepth;
            updatedUsersList = updatedUsersList
              ? [...updatedUsersList, userItem]
              : [userItem];
          }
          const updatedReplyEop: IRepliesEOP = {};
          updatedReplyEop.chatID = action.chatID;
          updatedReplyEop.usersRepliesEOPList = updatedUsersList;
          return { ...replyItem, ...updatedReplyEop };
        }
        return replyItem;
      });
    case HistoryRepliesActions.RESET:
      return [];
    default:
      return state;
  }

  function isNewRecord(
    parentMsgID: string,
    repliesEOP: IRepliesEOP[]
  ): boolean {
    const existingRecord = repliesEOP.find(
      item => item.messageID === parentMsgID
    );
    if (existingRecord) {
      return false;
    } else {
      return true;
    }
  }

  function isUserRecordExist(
    userID: string,
    userReplyList: UserReplyEOP[]
  ): boolean {
    const existingRecord = userReplyList.find(item => item.userID === userID);
    if (existingRecord) {
      return true;
    } else {
      return false;
    }
  }
}
