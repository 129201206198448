import {
  CANCEL_STRIPE_SUBSCRIPTION_METHOD,
  CHANGE_PLAN_METHOD,
  CREATE_CARD_METHOD,
  DELETE_CREDIT_CARD_METHOD,
  GET_BILLING_INFO_METHOD,
  GET_INVOICES_METHOD,
  RETRIEVE_COUPON_METHOD,
  REVOKE_SUBSCRIPTION_METHOD,
  SET_DEFAULT_CARD_METHOD,
  SET_RULE_ENGINE_ACCESS,
  SUBSCRIBE_TO_ADDONS_METHOD,
  SUBSCRIBE_TO_PLAN_METHOD
} from './constants';

export interface AddCredit {
  phone?: string;
  name?: string;
  email?: string;
  card_token?: string;
  plan_id?: string;
  coupon?: string;
  sub_main?: boolean;
}

export interface BillingCoupon {
  amount_off?: number;
  percent_off?: number;
  status?: boolean;
}

export interface BillingUI {
  selectedPLan?: string;
  currentScreen?: string;
  currentPlanDetails?: any;
  invoicesList?: InvoiceOpj[];
  errorMessage?: string;
  status?: string;
  coupon?: BillingCoupon;
  billingInfo?: any;
  mainPlan?: any;
  lastScreen?: string;
  billingPlanStatus?: boolean;
  subscriptions?: SubscriptionItemUI[];
  trialEndDate?: number;
  cancel_end?: any;
  expire?: boolean;
}

// export interface MainPlanBillingUI{

// }

export interface InvoiceOpj {
  period_end?: number;
  total?: string;
  invoice_pdf?: string;
  line_item?: any[];
  total_excluding_tax?: string;
  period_start?: number;
  paid?: boolean;
  total_discount_amount?: string;
  total_tax_amount?: string;
  currency?: string;
  id?: string;
  amount_due?: string;
  created_at?: number;
}

export interface SubscriptionItemUI {
  amount_after_discount?: string;
  amount_before_discount?: string;
  discount_amount?: string;
  period?: string;
  period_end?: number;
  period_start?: number;
  plan_id?: string;
  plan_name?: string;
  stripe_plan_id?: string;
  subscription_id?: string;
  unit_amount?: number;
  current_period_start?: number;
  current_period_end?: number;
  quantity?: number;
  item_id?: string;
}

export class SendBillingSubscribetion {
  readonly method = SUBSCRIBE_TO_PLAN_METHOD;
  constructor(
    public phone: string,
    public name: string,
    public email: string,
    public card_token: string,
    public plan_id: string,
    public coupon?: string,
    public sub_main?: boolean,
    public lm_data?: any
  ) {}
}
export class SendCouponToServer {
  readonly method = RETRIEVE_COUPON_METHOD;
  constructor(public coupon_id: string) {}
}
export class AddNewCreditCard {
  readonly method = CREATE_CARD_METHOD;
  constructor(public card_token: string) {}
}
export class SetDefaultCreditCard {
  readonly method = SET_DEFAULT_CARD_METHOD;
  constructor(public card_id: string) {}
}
export class DeleteCreditCard {
  readonly method = DELETE_CREDIT_CARD_METHOD;
  constructor(public card_id: string) {}
}
export class GetInvoicesMethod {
  readonly method = GET_INVOICES_METHOD;
}
export class GetBillingInfoMethod {
  readonly method = GET_BILLING_INFO_METHOD;
}

export class ChangePlanMethod {
  readonly method = CHANGE_PLAN_METHOD;
  constructor(
    public new_plan_id: string,
    public coupon?: string,
    public sub_main?: boolean
  ) {}
}

export class SubscribeToAddons {
  readonly method = SUBSCRIBE_TO_ADDONS_METHOD;
  constructor(
    public plan_id: string,
    public sub_main: boolean,
    public coupon?: string
  ) {}
}
export class CancelStripeSubscription {
  readonly method = CANCEL_STRIPE_SUBSCRIPTION_METHOD;
  constructor(
    public all: boolean,
    public plan_id?: string,
    public reason?: string,
    public sub_main?: boolean,
    public cancel_quantity?: number
  ) {}
}
export class RevokeSubscription {
  readonly method = REVOKE_SUBSCRIPTION_METHOD;
}
