import { Actions } from './mapTripsHistory.actions';
import { MapTripHistoryUI } from 'src/models/MapTripsHistory';

import * as actions from './mapTripsHistory.actions';
import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';
import { DatePipe } from '@angular/common';
const twoWeeks = 14 * 24 * 60 * 60 * 1000;
const INITIAL_STATE: MapTripHistoryUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  startDate: new DatePipe('en-US').transform(
    Date.now() - twoWeeks,
    'yyyy-MM-dd'
  ),
  endDate: new DatePipe('en-US').transform(Date.now(), 'yyyy-MM-dd')
};

export function mapTripsHistoryReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.MapTripsHistoryTypes.RESET_MAP_TRIPS_HISTORY_PAGING: {
      return {
        ...INITIAL_STATE,
        startDate: action.startDate ? action.startDate : state.startDate,
        endDate: action.endDate ? action.endDate : state.endDate
      };
    }
    case actions.MapTripsHistoryTypes.GET_NEXT_MAP_TRIPS_HISTORY_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    }
    case actions.MapTripsHistoryTypes.GET_PREV_MAP_TRIPS_HISTORY_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV
      };
    }
    case actions.MapTripsHistoryTypes.MAP_TRIP_HISTORY_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.mapTripsHistory && action.mapTripsHistory.length) > 0
            ? action.mapTripsHistory
            : state.currentPage
      };
    }
    case actions.MapTripsHistoryTypes.UI_MAP_TRIP_HISTORY_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    default: {
      return state;
    }
  }
}
