import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';

import { AuthService } from 'src/app/core/auth.service';
import { AuthStatus, UISections } from 'src/models/constants';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { NavigateService } from 'src/app/core/navigate.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from 'src/app/core/storage.service';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent implements OnInit, OnDestroy {
  authStatus: typeof AuthStatus = AuthStatus;
  auth: any;
  count = 0;
  constructor(
    public authService: AuthService,
    private _navigatedService: NavigateService,
    public dashboard: DashboardService,
    private location: Location,
    private title: Title,
    private _router: Router,
    private localStorage: StorageService
  ) {}

  ngOnInit() {
    this.localStorage.setRecord('REM', true);
    this.auth = this.authService.authCollection$.subscribe(res => {
      if (
        res.token &&
        (res.appConfig == 0 || res.appConfig == 1) &&
        res.is_nandbox == 0 &&
        this.location.path() === '/login' &&
        res.chatId &&
        res.chatId === res.main_group_id
      ) {
        this._router.navigate(['/app']);
        this.dashboard.setSection(UISections.APP);
      }
    });
    // this._navigatedService.preventBackButton();
    this.title.setTitle('The Native App Builder Login');
    // this.createCanonicalURL();
  }

  createCanonicalURL(url?: string) {
    let canURL = window.location.href;
    let link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  ngOnDestroy() {
    if (this.auth) {
      this.auth.unsubscribe();
    }
    // this._navigatedService.goBack();
  }
}
