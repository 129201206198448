import { IOption } from './campaign';

export const CURRENCY: IOption[] = [
  {
    key: 'XUA',
    value: 'ADB Unit of Account'
  },
  {
    key: 'AFN',
    value: 'Afghan Afghani'
  },
  {
    key: 'ALL',
    value: 'Albanian Lek'
  },
  {
    key: 'DZD',
    value: 'Algerian Dinar'
  },
  {
    key: 'ADP',
    value: 'Andorran Peseta'
  },
  {
    key: 'AOA',
    value: 'Angolan Kwanza'
  },
  {
    key: 'ARS',
    value: 'Argentine Peso'
  },
  {
    key: 'AMD',
    value: 'Armenian Dram'
  },
  {
    key: 'AWG',
    value: 'Aruban Florin'
  },
  {
    key: 'AUD',
    value: 'Australian Dollar'
  },
  {
    key: 'ATS',
    value: 'Austrian Schilling'
  },
  {
    key: 'AYM',
    value: 'AYM'
  },
  {
    key: 'AZN',
    value: 'Azerbaijan Manat'
  },
  {
    key: 'BSD',
    value: 'Bahamian Dollar'
  },
  {
    key: 'BHD',
    value: 'Bahraini Dinar'
  },
  {
    key: 'BDT',
    value: 'Bangladeshi Taka'
  },
  {
    key: 'BBD',
    value: 'Barbadian Dollar'
  },
  {
    key: 'BYN',
    value: 'Belarusian Ruble'
  },
  {
    key: 'BEF',
    value: 'Belgian Franc'
  },
  {
    key: 'BZD',
    value: 'Belize Dollar'
  },
  {
    key: 'BMD',
    value: 'Bermudan Dollar'
  },
  {
    key: 'BTN',
    value: 'Bhutanese Ngultrum'
  },
  {
    key: 'BOB',
    value: 'Bolivian Boliviano'
  },
  {
    key: 'BOV',
    value: 'Bolivian Mvdol'
  },
  {
    key: 'BAM',
    value: 'Bosnia-Herzegovina Convertible Mark'
  },
  {
    key: 'BWP',
    value: 'Botswanan Pula'
  },
  {
    key: 'BRL',
    value: 'Brazilian Real'
  },
  {
    key: 'GBP',
    value: 'British Pound Sterling'
  },
  {
    key: 'BND',
    value: 'Brunei Dollar'
  },
  {
    key: 'BGL',
    value: 'Bulgarian Hard Lev'
  },
  {
    key: 'BGN',
    value: 'Bulgarian Lev'
  },
  {
    key: 'BIF',
    value: 'Burundian Franc'
  },
  {
    key: 'KHR',
    value: 'Cambodian Riel'
  },
  {
    key: 'CAD',
    value: 'Canadian Dollar'
  },
  {
    key: 'CVE',
    value: 'Cape Verdean Escudo'
  },
  {
    key: 'KYD',
    value: 'Cayman Islands Dollar'
  },
  {
    key: 'XOF',
    value: 'CFA Franc BCEAO'
  },
  {
    key: 'XAF',
    value: 'CFA Franc BEAC'
  },
  {
    key: 'XPF',
    value: 'CFP Franc'
  },
  {
    key: 'CHE',
    value: 'CHE'
  },
  {
    key: 'CLP',
    value: 'Chilean Peso'
  },
  {
    key: 'CNY',
    value: 'Chinese Yuan'
  },
  {
    key: 'CHW',
    value: 'CHW'
  },
  {
    key: 'COP',
    value: 'Colombian Peso'
  },
  {
    key: 'KMF',
    value: 'Comorian Franc'
  },
  {
    key: 'CDF',
    value: 'Congolese Franc'
  },
  {
    key: 'CRC',
    value: 'Costa Rican Colón'
  },
  {
    key: 'COU',
    value: 'COU'
  },
  {
    key: 'CUC',
    value: 'Cuban Convertible Peso'
  },
  {
    key: 'CUP',
    value: 'Cuban Peso'
  },
  {
    key: 'CYP',
    value: 'Cypriot Pound'
  },
  {
    key: 'CZK',
    value: 'Czech Republic Koruna'
  },
  {
    key: 'DKK',
    value: 'Danish Krone'
  },
  {
    key: 'DJF',
    value: 'Djiboutian Franc'
  },
  {
    key: 'DOP',
    value: 'Dominican Peso'
  },
  {
    key: 'NLG',
    value: 'Dutch Guilder'
  },
  {
    key: 'XCD',
    value: 'East Caribbean Dollar'
  },
  {
    key: 'EGP',
    value: 'Egyptian Pound'
  },
  {
    key: 'ERN',
    value: 'Eritrean Nakfa'
  },
  {
    key: 'EEK',
    value: 'Estonian Kroon'
  },
  {
    key: 'ETB',
    value: 'Ethiopian Birr'
  },
  {
    key: 'EUR',
    value: 'Euro'
  },
  {
    key: 'XBA',
    value: 'European Composite Unit'
  },
  {
    key: 'XBB',
    value: 'European Monetary Unit'
  },
  {
    key: 'FKP',
    value: 'Falkland Islands Pound'
  },
  {
    key: 'FJD',
    value: 'Fijian Dollar'
  },
  {
    key: 'FIM',
    value: 'Finnish Markka'
  },
  {
    key: 'FRF',
    value: 'French Franc'
  },
  {
    key: 'XFO',
    value: 'French Gold Franc'
  },
  {
    key: 'XFU',
    value: 'French UIC-Franc'
  },
  {
    key: 'GMD',
    value: 'Gambian Dalasi'
  },
  {
    key: 'GEL',
    value: 'Georgian Lari'
  },
  {
    key: 'DEM',
    value: 'German Mark'
  },
  {
    key: 'GHS',
    value: 'Ghanaian Cedi'
  },
  {
    key: 'GIP',
    value: 'Gibraltar Pound'
  },
  {
    key: 'XAU',
    value: 'Gold'
  },
  {
    key: 'GRD',
    value: 'Greek Drachma'
  },
  {
    key: 'GTQ',
    value: 'Guatemalan Quetzal'
  },
  {
    key: 'GWP',
    value: 'Guinea-Bissau Peso'
  },
  {
    key: 'GNF',
    value: 'Guinean Franc'
  },
  {
    key: 'GYD',
    value: 'Guyanaese Dollar'
  },
  {
    key: 'HTG',
    value: 'Haitian Gourde'
  },
  {
    key: 'HNL',
    value: 'Honduran Lempira'
  },
  {
    key: 'HKD',
    value: 'Hong Kong Dollar'
  },
  {
    key: 'HUF',
    value: 'Hungarian Forint'
  },
  {
    key: 'ISK',
    value: 'Icelandic Króna'
  },
  {
    key: 'INR',
    value: 'Indian Rupee'
  },
  {
    key: 'IDR',
    value: 'Indonesian Rupiah'
  },
  {
    key: 'IRR',
    value: 'Iranian Rial'
  },
  {
    key: 'IQD',
    value: 'Iraqi Dinar'
  },
  {
    key: 'IEP',
    value: 'Irish Pound'
  },
  {
    key: 'ILS',
    value: 'Israeli New Sheqel'
  },
  {
    key: 'ITL',
    value: 'Italian Lira'
  },
  {
    key: 'JMD',
    value: 'Jamaican Dollar'
  },
  {
    key: 'JPY',
    value: 'Japanese Yen'
  },
  {
    key: 'JOD',
    value: 'Jordanian Dinar'
  },
  {
    key: 'KZT',
    value: 'Kazakhstani Tenge'
  },
  {
    key: 'KES',
    value: 'Kenyan Shilling'
  },
  {
    key: 'HRK',
    value: 'Kuna'
  },
  {
    key: 'KWD',
    value: 'Kuwaiti Dinar'
  },
  {
    key: 'KGS',
    value: 'Kyrgystani Som'
  },
  {
    key: 'LAK',
    value: 'Lao Kip'
  },
  {
    key: 'LVL',
    value: 'Latvian Lats'
  },
  {
    key: 'LBP',
    value: 'Lebanese Pound'
  },
  {
    key: 'LSL',
    value: 'Lesotho Loti'
  },
  {
    key: 'LRD',
    value: 'Liberian Dollar'
  },
  {
    key: 'LYD',
    value: 'Libyan Dinar'
  },
  {
    key: 'LTL',
    value: 'Lithuanian Litas'
  },
  {
    key: 'LUF',
    value: 'Luxembourgian Franc'
  },
  {
    key: 'MOP',
    value: 'Macanese Pataca'
  },
  {
    key: 'MKD',
    value: 'Macedonian Denar'
  },
  {
    key: 'MGA',
    value: 'Malagasy Ariary'
  },
  {
    key: 'MGF',
    value: 'Malagasy Franc'
  },
  {
    key: 'MWK',
    value: 'Malawian Malawi Kwacha'
  },
  {
    key: 'MYR',
    value: 'Malaysian Ringgit'
  },
  {
    key: 'MVR',
    value: 'Maldivian Rufiyaa'
  },
  {
    key: 'MTL',
    value: 'Maltese Lira'
  },
  {
    key: 'MRO',
    value: 'Mauritanian Ouguiya'
  },
  {
    key: 'MRU',
    value: 'Mauritanian Ouguiya'
  },
  {
    key: 'MUR',
    value: 'Mauritian Rupee'
  },
  {
    key: 'MXV',
    value: 'Mexican Investment Unit'
  },
  {
    key: 'MXN',
    value: 'Mexican Peso'
  },
  {
    key: 'MDL',
    value: 'Moldovan Leu'
  },
  {
    key: 'MNT',
    value: 'Mongolian Tugrik'
  },
  {
    key: 'MAD',
    value: 'Moroccan Dirham'
  },
  {
    key: 'MZN',
    value: 'Mozambican Metical'
  },
  {
    key: 'MMK',
    value: 'Myanma Kyat'
  },
  {
    key: 'NAD',
    value: 'Namibian Dollar'
  },
  {
    key: 'NPR',
    value: 'Nepalese Rupee'
  },
  {
    key: 'ANG',
    value: 'Netherlands Antillean Guilder'
  },
  {
    key: 'TWD',
    value: 'New Taiwan Dollar'
  },
  {
    key: 'NZD',
    value: 'New Zealand Dollar'
  },
  {
    key: 'NIO',
    value: 'Nicaraguan Córdoba'
  },
  {
    key: 'NGN',
    value: 'Nigerian Naira'
  },
  {
    key: 'KPW',
    value: 'North Korean Won'
  },
  {
    key: 'NOK',
    value: 'Norwegian Krone'
  },
  {
    key: 'OMR',
    value: 'Omani Rial'
  },
  {
    key: 'PKR',
    value: 'Pakistani Rupee'
  },
  {
    key: 'XPD',
    value: 'Palladium'
  },
  {
    key: 'PAB',
    value: 'Panamanian Balboa'
  },
  {
    key: 'PGK',
    value: 'Papua New Guinean Kina'
  },
  {
    key: 'PYG',
    value: 'Paraguayan Guarani'
  },
  {
    key: 'PEN',
    value: 'Peruvian Sol'
  },
  {
    key: 'PHP',
    value: 'Philippine Peso'
  },
  {
    key: 'XPT',
    value: 'Platinum'
  },
  {
    key: 'PLN',
    value: 'Polish Zloty'
  },
  {
    key: 'PTE',
    value: 'Portuguese Escudo'
  },
  {
    key: 'QAR',
    value: 'Qatari Rial'
  },
  {
    key: 'RON',
    value: 'Romanian Leu'
  },
  {
    key: 'RUB',
    value: 'Russian Ruble'
  },
  {
    key: 'RWF',
    value: 'Rwandan Franc'
  },
  {
    key: 'SHP',
    value: 'Saint Helena Pound'
  },
  {
    key: 'SVC',
    value: 'Salvadoran Colón'
  },
  {
    key: 'WST',
    value: 'Samoan Tala'
  },
  {
    key: 'STD',
    value: 'São Tomé and Príncipe Dobra'
  },
  {
    key: 'STN',
    value: 'São Tomé and Príncipe Dobra'
  },
  {
    key: 'SAR',
    value: 'Saudi Riyal'
  },
  {
    key: 'RSD',
    value: 'Serbian Dinar'
  },
  {
    key: 'SCR',
    value: 'Seychellois Rupee'
  },
  {
    key: 'SLL',
    value: 'Sierra Leonean Leone'
  },
  {
    key: 'XAG',
    value: 'Silver'
  },
  {
    key: 'SGD',
    value: 'Singapore Dollar'
  },
  {
    key: 'SKK',
    value: 'Slovak Koruna'
  },
  {
    key: 'SIT',
    value: 'Slovenian Tolar'
  },
  {
    key: 'SBD',
    value: 'Solomon Islands Dollar'
  },
  {
    key: 'SOS',
    value: 'Somali Shilling'
  },
  {
    key: 'ZAR',
    value: 'South African Rand'
  },
  {
    key: 'KRW',
    value: 'South Korean Won'
  },
  {
    key: 'SSP',
    value: 'South Sudanese Pound'
  },
  {
    key: 'ESP',
    value: 'Spanish Peseta'
  },
  {
    key: 'XDR',
    value: 'Special Drawing Rights'
  },
  {
    key: 'LKR',
    value: 'Sri Lankan Rupee'
  },
  {
    key: 'XSU',
    value: 'Sucre'
  },
  {
    key: 'SDG',
    value: 'Sudanese Pound'
  },
  {
    key: 'SRD',
    value: 'Surinamese Dollar'
  },
  {
    key: 'SRG',
    value: 'Surinamese Guilder'
  },
  {
    key: 'SZL',
    value: 'Swazi Lilangeni'
  },
  {
    key: 'SEK',
    value: 'Swedish Krona'
  },
  {
    key: 'CHF',
    value: 'Swiss Franc'
  },
  {
    key: 'SYP',
    value: 'Syrian Pound'
  },
  {
    key: 'TJS',
    value: 'Tajikistani Somoni'
  },
  {
    key: 'TZS',
    value: 'Tanzanian Shilling'
  },
  {
    key: 'THB',
    value: 'Thai Baht'
  },
  {
    key: 'TPE',
    value: 'Timorese Escudo'
  },
  {
    key: 'TOP',
    value: 'Tongan Paʻanga'
  },
  {
    key: 'TTD',
    value: 'Trinidad and Tobago Dollar'
  },
  {
    key: 'TND',
    value: 'Tunisian Dinar'
  },
  {
    key: 'TRY',
    value: 'Turkish Lira'
  },
  {
    key: 'TMT',
    value: 'Turkmenistani Manat'
  },
  {
    key: 'UGX',
    value: 'Ugandan Shilling'
  },
  {
    key: 'UAH',
    value: 'Ukrainian Hryvnia'
  },
  {
    key: 'AED',
    value: 'United Arab Emirates Dirham'
  },
  {
    key: 'XXX',
    value: 'Unknown Currency'
  },
  {
    key: 'UYU',
    value: 'Uruguayan Peso'
  },
  {
    key: 'USD',
    value: 'US Dollar'
  },
  {
    key: 'UYI',
    value: 'UYI'
  },
  {
    key: 'UZS',
    value: 'Uzbekistan Som'
  },
  {
    key: 'VUV',
    value: 'Vanuatu Vatu'
  },
  {
    key: 'VEF',
    value: 'Venezuelan Bolívar'
  },
  {
    key: 'VES',
    value: 'Venezuelan Bolívar Soberano'
  },
  {
    key: 'VND',
    value: 'Vietnamese Dong'
  },
  {
    key: 'YER',
    value: 'Yemeni Rial'
  },
  {
    key: 'ZMK',
    value: 'Zambian Kwacha'
  },
  {
    key: 'ZMW',
    value: 'ZMW'
  },
  {
    key: 'ZWN',
    value: 'ZWN'
  }
];

export const APPLE_CURRENCY: IOption[] = [
  {
    value: 'UAE Dirham',
    key: 'AED'
  },
  {
    value: 'Australian Dollar',
    key: 'AUD'
  },
  {
    value: 'Bulgarian Lev',
    key: 'BGN'
  },
  {
    value: 'Brazilian Real',
    key: 'BRL'
  },
  {
    value: 'Canadian Dollar',
    key: 'CAD'
  },
  {
    value: 'Swiss Franc',
    key: 'CHF'
  },
  {
    value: 'Chilean Peso',
    key: 'CLP'
  },
  {
    value: 'China Yuan',
    key: 'CNY'
  },
  {
    value: 'Colombian Peso',
    key: 'COP'
  },
  {
    value: 'Czech Koruna',
    key: 'CZK'
  },
  {
    value: 'Danish Krone',
    key: 'DKK'
  },
  {
    value: 'Egyptian Pound',
    key: 'EGP'
  },
  {
    value: 'Euro',
    key: 'EUR'
  },
  {
    value: 'British Pound',
    key: 'GBP'
  },
  {
    value: 'Hong Kong Dollar',
    key: 'HKD'
  },
  {
    value: 'Croatian Kuna',
    key: 'HRK'
  },
  {
    value: 'Hungarian Forint',
    key: 'HUF'
  },
  {
    value: 'Indonesian Rupiah',
    key: 'IDR'
  },
  {
    value: 'New Shekel',
    key: 'ILS'
  },
  {
    value: 'Indian Rupee',
    key: 'INR'
  },
  {
    value: 'Japanese Yen',
    key: 'JPY'
  },
  {
    value: 'South Korean Won',
    key: 'KRW'
  },
  {
    value: 'Kazakhstani Tenge',
    key: 'KZT'
  },
  {
    value: 'Mexican Peso',
    key: 'MXN'
  },
  {
    value: 'Malaysian Ringgit',
    key: 'MYR'
  },
  {
    value: 'Nigerian Naira',
    key: 'NGN'
  },
  {
    value: 'Norway Krone',
    key: 'NOK'
  },
  {
    value: 'New Zealand Dollar',
    key: 'NZD'
  },
  {
    value: 'Peruvian Sol',
    key: 'PEN'
  },
  {
    value: 'Philippine Peso',
    key: 'PHP'
  },
  {
    value: 'Pakistani Rupee',
    key: 'PKR'
  },
  {
    value: 'Polish Zloty',
    key: 'PLN'
  },
  {
    value: 'Qatari Riyal',
    key: 'QAR'
  },
  {
    value: 'Romanian Leu',
    key: 'RON'
  },
  {
    value: 'Russian Ruble',
    key: 'RUB'
  },
  {
    value: 'Saudi Riyal',
    key: 'SAR'
  },
  {
    value: 'Swedish Krona',
    key: 'SEK'
  },
  {
    value: 'Singapore Dollar',
    key: 'SGD'
  },
  {
    value: 'Thai Baht',
    key: 'THB'
  },
  {
    value: 'Turkish Lira',
    key: 'TRY'
  },
  {
    value: 'New Taiwan Dollar',
    key: 'TWD'
  },
  {
    value: 'Tanzanian Shilling',
    key: 'TZS'
  },
  {
    value: 'US Dollar',
    key: 'USD'
  },
  {
    value: 'Vietnamese Dong',
    key: 'VND'
  },
  {
    value: 'South African Rand',
    key: 'ZAR'
  }
];

export const PAYPALCURRENCY: IOption[] = [
  { key: 'AUD', value: 'Australian Dollar' },
  { key: 'CAD', value: 'Canadian Dollar' },
  { key: 'CHF', value: 'Swiss Franc' },
  { key: 'CZK', value: 'Czech Koruna' },
  { key: 'DKK', value: 'Danish Krone' },
  { key: 'EUR', value: 'Euro' },
  { key: 'GBP', value: 'British Pound' },
  { key: 'HKD', value: 'Hong Kong Dollar' },
  { key: 'HUF', value: 'Hungarian Forint' },
  { key: 'ILS', value: 'Israeli New Shekel' },
  { key: 'JPY', value: 'Japanese Yen' },
  { key: 'MXN', value: 'Mexican Peso' },
  { key: 'NOK', value: 'Norwegian Krone' },
  { key: 'NZD', value: 'New Zealand Dollar' },
  { key: 'PHP', value: 'Philippine Peso' },
  { key: 'PLN', value: 'Polish Zloty' },
  { key: 'RUB', value: 'Russian Ruble' },
  { key: 'SEK', value: 'Swedish Krona' },
  { key: 'SGD', value: 'Singapore Dollar' },
  { key: 'THB', value: 'Thai Baht' },
  { key: 'TWD', value: 'New Taiwan Dollar' },
  { key: 'USD', value: 'United States Dollar' }
];
