import { Actions } from './allGroup.actions';
import * as actions from './allGroup.actions';
import { AllGroupUI } from 'src/models/AllGroupUI';

const INITIAL_STATE: AllGroupUI = {
  currentPage: [],
  loaderStatus: 'done',
  isAllList: false
};
export function allGroupReducer(
  state = INITIAL_STATE,
  action: Actions
): AllGroupUI {
  switch (action.type) {
    case actions.AllGroupActionTypes.UI_RECEIVE_ALL_GROUP: {
      return {
        ...state,
        currentPage: [...state.currentPage, ...action.chatGroups],
        isAllList: action.chatGroups.length == 0 ? true : false
      };
    }
    case actions.AllGroupActionTypes.UI_SET_LOADER_STATUS: {
      return {
        ...state,
        loaderStatus: action.status
      };
    }
    case actions.AllGroupActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
