import { Action } from '@ngrx/store';

import { CalendarTimetable, CalendarDay } from 'src/models/Calendar';

export const enum CalendarUIActionTypes {
  CREATE_CALENDAR = 'CREATE_CALENDAR',
  // CALENDAR_CREATED = 'CALENDAR_CREATED',
  UPDATE_CALENDAR = 'UPDATE_CALENDAR',
  CALENDAR_RECEIVED = 'CALENDAR_RECEIVED',
  CALENDAR_LIST_RECEIVED = 'CALENDAR_LIST_RECEIVED',
  SET_CALENDAR_DETAIL = 'SET_CALENDAR_DETAIL',
  CALENDAR_DETAILS_RECEIVED = 'CALENDAR_DETAILS_RECEIVED',
  DELETE_CALENDAR_DETAIL = 'DELETE_CALENDAR_DETAIL',
  UI_VIEW_CALENDAR = 'UI_VIEW_CALENDAR',
  UI_CLOSE_CALENDAR_VIEW = 'UI_CLOSE_CALENDAR_VIEW',
  UI_CLOSE_EDIT_CALENDAR = 'UI_CLOSE_EDIT_CALENDAR ',
  UI_EDIT_CALENDAR = 'UI_EDIT_CALENDAR',
  UI_CREATE_CALENDAR = 'UI_CREATE_CALENDAR',
  UI_CALENDAR_LIST = 'UI_CALENDAR_LIST',
  UI_SET_CALENDAR_IN_CREATION = 'UI_SET_CALENDAR_IN_CREATION',
  UI_CALENDAR_CREATION_COMPLETE = 'UI_CALENDAR_CREATION_COMPLETE',
  UI_CALENDAR_GET_NEXT_PAGE = 'UI_CALENDAR_GET_NEXT_PAGE',
  UI_CALENDAR_GET_PREV_PAGE = 'UI_CALENDAR_GET_PREV_PAGE',
  UI_CALENDAR_RECEIVED_HISTORY_CHUNK = 'UI_CALENDAR_RECEIVED_HISTORY_CHUNK',
  UI_CALENDAR_RESET_PAGING = 'UI_CALENDAR_RESET_PAGING',
  RESET = 'RESET'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class CreateCalendar implements Action {
  readonly type = CalendarUIActionTypes.CREATE_CALENDAR;
  constructor(public calendar: CalendarTimetable) {}
}

// export class CalendarCreated implements Action {
//   readonly type = CalendarUIActionTypes.CALENDAR_CREATED;
//   constructor(public calendar: CalendarTimetable) {}
// }

export class UpdateCalendar implements Action {
  readonly type = CalendarUIActionTypes.UPDATE_CALENDAR;
  constructor(public calendarId: string, public calendar: CalendarTimetable) {}
}

export class CalendarReceived implements Action {
  readonly type = CalendarUIActionTypes.CALENDAR_RECEIVED;
  constructor(public calendar: CalendarTimetable) {}
}

export class CalendarListReceived implements Action {
  readonly type = CalendarUIActionTypes.CALENDAR_LIST_RECEIVED;
  constructor(public calendars: CalendarTimetable[]) {}
}

export class SetCalendarDetails implements Action {
  readonly type = CalendarUIActionTypes.SET_CALENDAR_DETAIL;
  constructor(
    public calendar_id: string,
    public data: CalendarDay[],
    public clear = 1,
    public reference?: string
  ) {}
}

export class CalendarDetailsReceived implements Action {
  readonly type = CalendarUIActionTypes.CALENDAR_DETAILS_RECEIVED;
  constructor(public calendar_id: string, public data: CalendarDay[]) {}
}

export class DeleteCalendarDetail implements Action {
  readonly type = CalendarUIActionTypes.DELETE_CALENDAR_DETAIL;
  constructor(public calendar_id: string, public references: string[]) {}
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}

export class ViewCalendarUI implements Action {
  readonly type = CalendarUIActionTypes.UI_VIEW_CALENDAR;
  constructor(public selectedCalendar: CalendarTimetable) {}
}

export class CreateCalendarUI implements Action {
  readonly type = CalendarUIActionTypes.UI_CREATE_CALENDAR;
}

export class CalendarListUI implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_LIST;
}

export class CalendarCreationComplete implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_CREATION_COMPLETE;
}

export class CloseViewCalendar implements Action {
  readonly type = CalendarUIActionTypes.UI_CLOSE_CALENDAR_VIEW;
}

export class EditCalendarUI implements Action {
  readonly type = CalendarUIActionTypes.UI_EDIT_CALENDAR;
  constructor(public selectedCalendar: CalendarTimetable) {}
}

export class CloseCalendarEdit implements Action {
  readonly type = CalendarUIActionTypes.UI_CLOSE_EDIT_CALENDAR;
}

export class SetCalendarInCreation implements Action {
  readonly type = CalendarUIActionTypes.UI_SET_CALENDAR_IN_CREATION;
  constructor(public createdCalendar: CalendarTimetable) {}
}

export class GetNextCalendarPage implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_GET_NEXT_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

export class GetPrevCalendarPage implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_GET_PREV_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

// we can detect that by listening to the return of getSubChatList of type event
export class ReceivedCalendarHistoryChunk implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: CalendarTimetable[]
  ) {}
}

export class ResetCalendarPaging implements Action {
  readonly type = CalendarUIActionTypes.UI_CALENDAR_RESET_PAGING;
  constructor() {}
}

export class Reset implements Action {
  readonly type = CalendarUIActionTypes.RESET;
}

export type Actions =
  | CreateCalendar
  // | CalendarCreated
  | UpdateCalendar
  | CalendarReceived
  | CalendarListReceived
  | SetCalendarDetails
  | CalendarDetailsReceived
  | DeleteCalendarDetail
  | DeselectChannel
  | ViewCalendarUI
  | CloseViewCalendar
  | CreateCalendarUI
  | CalendarListUI
  | CalendarCreationComplete
  | EditCalendarUI
  | CloseCalendarEdit
  | SetCalendarInCreation
  | GetNextCalendarPage
  | GetPrevCalendarPage
  | ReceivedCalendarHistoryChunk
  | ResetCalendarPaging
  | Reset;
