/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-message-style.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./select-message-style.component";
var styles_SelectMessageStyleComponent = [i0.styles];
var RenderType_SelectMessageStyleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectMessageStyleComponent, data: {} });
export { RenderType_SelectMessageStyleComponent as RenderType_SelectMessageStyleComponent };
export function View_SelectMessageStyleComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { wide: 0 }), i1.ɵqud(671088640, 2, { leftSide: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "container-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectEvent(_co.mStyles.WIDE) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "container-box-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-wide"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Wide "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "checked-style"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["wide", 1]], null, 0, "input", [["class", "check-checkbox"], ["id", "wide"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "label", [["class", "check-Label"], ["for", "wide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-done-radio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", "wilde"], ["src", "./assets/img/wilde.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 11, "div", [["class", "container-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectEvent(_co.mStyles.LEFT_SIDE) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 9, "div", [["class", "container-box-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-left-side"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Left Side "])), (_l()(), i1.ɵeld(21, 0, null, null, 4, "div", [["class", "checked-style"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, [[2, 0], ["leftSide", 1]], null, 0, "input", [["class", "check-checkbox"], ["id", "leftSide"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "label", [["class", "check-Label"], ["for", "leftSide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-done-radio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "img", [["alt", "left-side"], ["src", "./assets/img/left-side.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.localState == null) ? null : _co.localState.slectedWide); _ck(_v, 10, 0, currVal_0); var currVal_1 = ((_co.localState == null) ? null : _co.localState.slectedLeftSide); _ck(_v, 22, 0, currVal_1); }); }
export function View_SelectMessageStyleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-message-style", [], null, null, null, View_SelectMessageStyleComponent_0, RenderType_SelectMessageStyleComponent)), i1.ɵdid(1, 49152, null, 0, i2.SelectMessageStyleComponent, [], null, null)], null, null); }
var SelectMessageStyleComponentNgFactory = i1.ɵccf("app-select-message-style", i2.SelectMessageStyleComponent, View_SelectMessageStyleComponent_Host_0, { messageType: "messageType" }, {}, []);
export { SelectMessageStyleComponentNgFactory as SelectMessageStyleComponentNgFactory };
