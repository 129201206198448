import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { AppState } from 'src/models/AppState';

import * as CouponUIActions from './couponUI.actions';
import { CouponUIDispatchers } from './couponUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { GetCoupons, GetCoupon } from 'src/models/ICoupon';
import { CouponDispatchers } from '../coupons/coupon.dispatchers';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class CouponUiEffects {
  @Effect({ dispatch: false })
  getCoupons = this.actions$.pipe(
    ofType(
      CouponUIActions.CouponUIActionTypes.UI_COUPONS_RECEIVED
    ),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual)),
    ),
    map(([val, couponUiState, selectedChat]) => {
      const action = <CouponUIActions.ReceivedCoupons>val;
      if (selectedChat.id === action.groupId) {
        this._couponDispatchers.receivedCouponHistoryChunk(action.eop, action.sop, action.currentPage);
        this._couponsDispatchers.receivedCouponsList(action.currentPage);
      }
    })
  );

  @Effect({ dispatch: false })
  getNextCalendarPage = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, couponUiState]) => {
      const prevRequest = couponUiState.previousRequest;
      // if (
      //   !(
      //     couponUiState.eop === prevRequest.eop &&
      //     PageDirection.NEXT === prevRequest.direction &&
      //     couponUiState.hash === prevRequest.hash
      //   )
      // ) {
      this._socketGateway.sendSocketMessage(
        new GetCoupons(
          0,
          couponUiState.hash,
          couponUiState.eop,
          couponUiState.couponsStatus,
          couponUiState.expire
        )
      );
      // }
    })
  );

  @Effect({ dispatch: false })
  getPrevCalendarPage = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, couponUiState]) => {
      const prevRequest = couponUiState.previousRequest;
      // if (
      //   !(
      //     couponUiState.sop === prevRequest.sop &&
      //     PageDirection.PREV === prevRequest.direction &&
      //     couponUiState.hash === prevRequest.hash
      //   )
      // ) {
      this._socketGateway.sendSocketMessage(
        new GetCoupons(
          1,
          couponUiState.hash,
          couponUiState.sop,
          couponUiState.couponsStatus,
          couponUiState.expire
        )
      );
      // }
    })
  );

  @Effect({ dispatch: false })
  afterCalendarSectionSelection = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_LIST_COUPON),
    map((action: CouponUIActions.ListCouponUI) => {
      this._couponDispatchers.getNextCouponPage();
    })
  );

  @Effect({ dispatch: false })
  afterCalendarResetPaging = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_RESET_PAGING),
    map((action: CouponUIActions.ResetCouponPaging) => {
      this._couponDispatchers.getNextCouponPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _couponDispatchers: CouponUIDispatchers,
    private _couponsDispatchers: CouponDispatchers
  ) { }
}
