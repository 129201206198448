import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from '../../network/gateway/socket.gateway';
import { CampaignMiddleware } from './campaign.middleware';
import * as MessagesMethods from 'src/models/IMessage';
import * as CampaignMethods from 'src/models/ICampaign';
import * as isEqual from 'lodash.isequal';
export class CampaignEffects {
    constructor(actions$, _store, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        // get messageId for scope message to make sure it is exist when display campaign
        this.campaignReceived = this.actions$.pipe(ofType("REQUEST_SCOPE_MESSAGE" /* REQUEST_SCOPE_MESSAGE */), withLatestFrom(this._store
            .select(state => state.messageReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.scheduleReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, messages, schedules]) => {
            const action = val;
            const isInMessages = messages.find(msg => msg.message_id === action.payload);
            const isInSchedules = schedules.find(msg => msg.message_id === action.payload);
            if (!isInMessages && !isInSchedules) {
                this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(action.payload, null, null, null, null, null, null, null, null, null, null, null, null));
            }
        }));
        // create campaign or update it
        this.createCampaign = this.actions$.pipe(ofType("CREATE_CAMPAIGN" /* CREATE_CAMPAIGN */), map((action) => {
            const campToSend = CampaignMiddleware.sendCampaignMessage(action.payload);
            this._socketGateway.sendSocketMessage(new CampaignMethods.CreateCampaign(campToSend));
        }));
        this.updateCampaign = this.actions$.pipe(ofType("UPDATE_CAMPAIGN" /* UPDATE_CAMPAIGN */), map((action) => {
            const campToSend = CampaignMiddleware.sendCampaignMessage(action.payload);
            this._socketGateway.sendSocketMessage(new CampaignMethods.UpdateCampaign(campToSend));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignEffects.prototype, "campaignReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignEffects.prototype, "createCampaign", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CampaignEffects.prototype, "updateCampaign", void 0);
