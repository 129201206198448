import { Injectable } from '@angular/core';

import { InstantDispatchers } from 'src/app/store/instants/instant.dispatchers';
import { InstantArticle } from 'src/models/InstantArticle';

@Injectable({ providedIn: 'root' })
export class InstantArticleService {
  constructor(private _instantArticleDispatchers: InstantDispatchers) {}

  setNewArticle(iv: InstantArticle) {
    this._instantArticleDispatchers.instantArticleCreating(iv);
  }
}
