import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { WhitelistPatternsUiDispatchers } from 'src/app/store/whitelistPatternsUI/whitelistPatternsUI.dispatchers';
import { WhitelistUiDispatchers } from 'src/app/store/whitelistMainUI/whitelistMainUI.dispatchers';
import { AppChannelSettingsDispatchers } from 'src/app/store/appChannelSettings/appChannelSettings.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/whitelistMainUI/whitelistMainUI.dispatchers";
import * as i2 from "../../../store/whitelistPatternsUI/whitelistPatternsUI.dispatchers";
import * as i3 from "../../../store/appChannelSettings/appChannelSettings.dispatchers";
import * as i4 from "../../../store/root-store.selectors";
export class WhitelistService {
    constructor(_whitelistDispatchers, _whitelistPatternsDispatchers, _appChannelSettingsDispatchers, _storeSelector) {
        this._whitelistDispatchers = _whitelistDispatchers;
        this._whitelistPatternsDispatchers = _whitelistPatternsDispatchers;
        this._appChannelSettingsDispatchers = _appChannelSettingsDispatchers;
        this._storeSelector = _storeSelector;
        this._isPattern = new BehaviorSubject(false);
        this.isPattern$ = this._isPattern.asObservable();
        this._isLoading = new BehaviorSubject(true);
        this.isLoading$ = this._isLoading.asObservable();
        this._userViewer = new BehaviorSubject({});
        this.userViewer$ = this._userViewer.asObservable();
    }
    resetWhitelistPaging(name) {
        this._whitelistDispatchers.resetPaging(name);
    }
    getWhitelistNextPage() {
        this._whitelistDispatchers.getNextWhitelistPage();
    }
    getWhitelistPrevPage() {
        this._whitelistDispatchers.getPrevWhitelistPage();
    }
    addSignupIDToWhitelist(chat_id, users) {
        this._whitelistDispatchers.addSignupIDToWhitelist(chat_id, users);
    }
    deleteUserFromWhitelist(chat_id, users) {
        this._whitelistDispatchers.deleteUsersFromWhitelist(chat_id, users);
    }
    get whitelistList$() {
        return this._storeSelector.getWhitelist$;
    }
    resetWhitelistPatternsPaging() {
        this._whitelistPatternsDispatchers.resetPaging();
    }
    getWhitelistPatternsNextPage() {
        this._whitelistPatternsDispatchers.getNextWhitelistPatternsPage();
    }
    getWhitelistPatternsPrevPage() {
        this._whitelistPatternsDispatchers.getPrevWhitelistPatternsPage();
    }
    addWhitelistPatternsToList(chat_id, users) {
        this._whitelistPatternsDispatchers.addWhitelistPatternsToList(chat_id, users);
    }
    deletePatternFromWhitelist(chat_id, users) {
        this._whitelistPatternsDispatchers.deletePatternFromWhitelist(chat_id, users);
    }
    get getWhitelistPatterns$() {
        return this._storeSelector.getWhitelistPatterns$;
    }
    get getWhitelistScreenState$() {
        return this._storeSelector.getWhitelistScreenState$();
    }
    get getWhitelistPatternsScreenState$() {
        return this._storeSelector.getWhitelistPatternsScreenState$();
    }
    reset() {
        this._whitelistDispatchers.reset();
    }
    resetWhitelistPatterns() {
        this._whitelistPatternsDispatchers.reset();
    }
    setLoadingScreenStatus(status) {
        this._whitelistDispatchers.setLoadingScreenStatus(status);
    }
    setAppChannelSettings(state) {
        this._appChannelSettingsDispatchers.setAppChannelSettings(state);
    }
    getAppChannelSettings() {
        this._appChannelSettingsDispatchers.getAppChannelSettings();
    }
    get getAppChannelSettings$() {
        return this._storeSelector.getAppChannelSettings$();
    }
}
WhitelistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhitelistService_Factory() { return new WhitelistService(i0.ɵɵinject(i1.WhitelistUiDispatchers), i0.ɵɵinject(i2.WhitelistPatternsUiDispatchers), i0.ɵɵinject(i3.AppChannelSettingsDispatchers), i0.ɵɵinject(i4.RootStoreSelectors)); }, token: WhitelistService, providedIn: "root" });
