import { WhitelistUI } from 'src/models/Whitelist';

import { Actions } from './whitelistPatternsUI.actions';
import * as actions from './whitelistPatternsUI.actions';
import { ListRequestDetails, PageDirection } from 'src/models/constants';

const INITIAL_STATE: WhitelistUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  direction: null,
  previousRequest: {},
  screenState: 'Loading'
};

export function whitelistPatternsUIReducer(
  state = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.WhitelistPatternsUIActionTypes
      .UI_WHITELIST_PATTERNS_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_GET_NEXT_WHITELIST_PATTERNS_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        screenState: 'Loading'
      };
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_GET_PREV_WHITELIST_PATTERNS_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        screenState: 'Loading'
      };
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_RECEIVED_WHITELIST_PATTERNS_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage,
        screenState: 'Done'
      };
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_ADD_WHITELIST_PATTERNS_SUCCESS: {
      return { ...state, currentPage: [...action.data, ...state.currentPage] };
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_DELETE_PATTERNS_FROM_WHITELIST_SUCCESS: {
      return {
        ...state,
        currentPage: state.currentPage.filter(user => {
          if (user.pattern !== action.users[0]) {
            return user;
          }
        })
      };
    }
    case actions.WhitelistPatternsUIActionTypes
      .UI_WHITELIST_PATTERNS_SET_IDLE_STATUS: {
      return { ...state, screenState: 'Done' };
    }
    case actions.WhitelistPatternsUIActionTypes.UI_RESET_WHITELIST_PATTERNS:
      return INITIAL_STATE;
    default:
      return state;
  }
}
