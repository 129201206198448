import { Injectable } from '@angular/core';

import { RootStoreSelectors } from '../store/root-store.selectors';
import { AuthDispatchers } from '../store/auth/auth.dispatchers';

import { IAuth } from 'src/models/IAuth';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  constructor(
    private _authDispatchers: AuthDispatchers,
    private _selectors: RootStoreSelectors
  ) {}

  get credential() {
    return this._authDispatchers.localAuth;
  }

  get authStore() {
    return this._selectors.authCollection$;
  }

  // setChatSelect(chatID: string) {
  //   return this._authDispatchers.setChatSelect(chatID);
  // }

  setQRExpire(): void {
    this._authDispatchers.setAuthQRExpire();
  }

  resetAuth() {
    this._authDispatchers.resetStore();
  }

  fireTokenReceivedEvent(payload: IAuth) {
    this._authDispatchers.reciveAuthToken(payload);
  }

  removeLocalAuth() {
    this._authDispatchers.removeLocalAuth();
  }
}
