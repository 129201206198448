import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './allGroup.actions';

import { AppState } from 'src/models/AppState';
import { IChat } from 'src/models/IChat';

@Injectable({
  providedIn: 'root'
})
export class AllGroupDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveAllGroup(chatGroups: IChat[]) {
    this._store.dispatch(new actions.ReceiveAllGroup(chatGroups));
  }

  setLoaderStatus(status: string) {
    this._store.dispatch(new actions.SetLoaderStatus(status));
  }
}
