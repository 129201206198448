import { Action } from '@ngrx/store';
import { AddCredit, BillingCoupon, InvoiceOpj } from 'src/models/billings';

export const enum BillingUIActionTypes {
  SET_CURRENT_SCREEN_UI = 'SET_CURRENT_SCREEN_UI',
  SET_CURRENT_SELECTED_PLAN_UI = 'SET_CURRENT_SELECTED_PLAN_UI',
  SEND_SUBSCRIBETION_TO_PLAN_UI = 'SEND_SUBSCRIBETION_TO_PLAN_UI',
  RECEIVED_SELECETED_PLAN = 'RECEIVED_SELECETED_PLAN',
  ADD_NEW_CREDIT_CARD_UI = 'ADD_NEW_CREDIT_CARD_UI',
  SET_DEFAULT_CARD_UI = 'SET_DEFAULT_CARD_UI',
  DELETE_CREDIT_CARD_UI = 'DELETE_CREDIT_CARD_UI',
  GET_INVOICES_LIST_UI = 'GET_INVOICES_LIST_UI',
  RECEVE_INVOICES_LIST_UI = 'RECEVE_INVOICES_LIST_UI',
  CHANGE_PLAN_REQUEST_UI = 'CHANGE_PLAN_REQUEST_UI',
  SUBSCRIBE_TO_ADDONS_REQUEST_UI = 'SUBSCRIBE_TO_ADDONS_REQUEST_UI',
  RECEVE_STRIPE_ERROR_MESAGE_UI = 'RECEVE_STRIPE_ERROR_MESAGE_UI',
  CANCEL_STRIPE_SUBSCRIPTION_UI = 'CANCEL_STRIPE_SUBSCRIPTION_UI',
  SET_BILLING_STATUS_UI = 'SET_BILLING_STATUS_UI',
  RECEVE_COUPON_RESPONSE_UI = 'RECEVE_COUPON_RESPONSE_UI',
  RECEVE_BILLING_INFO_RESPONSE_UI = 'RECEVE_BILLING_INFO_RESPONSE_UI',
  GET_BILLING_INFO_SETUP = 'GET_BILLING_INFO_SETUP',
  GO_TO_LAST_SECTION_UI = 'GO_TO_LAST_SECTION_UI',
  SEND_COUPON_UI = 'SEND_COUPON_UI',
  SET_BILLING_PERIOD_STATUS_UI = 'SET_BILLING_PERIOD_STATUS_UI',
  REVOKE_CANCEL_MAINPLAN_UI = 'REVOKE_CANCEL_MAINPLAN_UI',
  RECEVE_END_FREE_TRILE_DATE_UI = 'RECEVE_END_FREE_TRILE_DATE_UI',
  RECEVE_IS_MAIN_PLAN_EXPIRE_UI = 'RECEVE_IS_MAIN_PLAN_EXPIRE_UI',
  RESET = 'RESET'
}

export class ReceivedSelecetedPLan implements Action {
  readonly type = BillingUIActionTypes.RECEIVED_SELECETED_PLAN;
  constructor(public planID: string, public cancelEnd?: any) {}
}

export class SetCurrentScreen implements Action {
  readonly type = BillingUIActionTypes.SET_CURRENT_SCREEN_UI;
  constructor(public screen: string, public lastScreen?: string) {}
}

export class SetCurrentSelectedPlan implements Action {
  readonly type = BillingUIActionTypes.SET_CURRENT_SELECTED_PLAN_UI;
  constructor(public plan: any) {}
}

export class SendSubscribetionToPlan implements Action {
  readonly type = BillingUIActionTypes.SEND_SUBSCRIBETION_TO_PLAN_UI;
  constructor(public subscribeDetails: AddCredit, public lm_data?: any) {}
}
export class SendCouponUI implements Action {
  readonly type = BillingUIActionTypes.SEND_COUPON_UI;
  constructor(public coupon: string) {}
}
export class AddNewCreditCardUI implements Action {
  readonly type = BillingUIActionTypes.ADD_NEW_CREDIT_CARD_UI;
  constructor(public token: AddCredit) {}
}
export class SetDefaultCardUI implements Action {
  readonly type = BillingUIActionTypes.SET_DEFAULT_CARD_UI;
  constructor(public cardId: string) {}
}
export class DeleteCreditCardUI implements Action {
  readonly type = BillingUIActionTypes.DELETE_CREDIT_CARD_UI;
  constructor(public cardId: string) {}
}
export class GetInvoicesListUI implements Action {
  readonly type = BillingUIActionTypes.GET_INVOICES_LIST_UI;
}
export class GetBillingInfoUI implements Action {
  readonly type = BillingUIActionTypes.GET_BILLING_INFO_SETUP;
}
export class ReceveInvoicesListUI implements Action {
  readonly type = BillingUIActionTypes.RECEVE_INVOICES_LIST_UI;
  constructor(public list: InvoiceOpj[]) {}
}
export class ChangePlanRequest implements Action {
  readonly type = BillingUIActionTypes.CHANGE_PLAN_REQUEST_UI;
  constructor(public new_plan_id: string, public coupon?: string) {}
}
export class SubscribeToAddonsRequest implements Action {
  readonly type = BillingUIActionTypes.SUBSCRIBE_TO_ADDONS_REQUEST_UI;
  constructor(public plan_id: string, public coupon?: string) {}
}

export class ReceveStripeErrorMasage implements Action {
  readonly type = BillingUIActionTypes.RECEVE_STRIPE_ERROR_MESAGE_UI;
  constructor(public msg: string) {}
}
export class SetBillingStatus implements Action {
  readonly type = BillingUIActionTypes.SET_BILLING_STATUS_UI;
  constructor(public status: string) {}
}
export class CancelStripeSubsCription implements Action {
  readonly type = BillingUIActionTypes.CANCEL_STRIPE_SUBSCRIPTION_UI;
  constructor(
    public all: boolean,
    public plan_id?: string,
    public reason?: string,
    public subMain?: boolean,
    public cancelQuantity?: number
  ) {}
}
export class ReceveCouponResponse implements Action {
  readonly type = BillingUIActionTypes.RECEVE_COUPON_RESPONSE_UI;
  constructor(public coupon: BillingCoupon) {}
}
export class ReceveBillingInfoResponse implements Action {
  readonly type = BillingUIActionTypes.RECEVE_BILLING_INFO_RESPONSE_UI;
  constructor(public data: any, public main_plan: any) {}
}

export class GoToLastSection implements Action {
  readonly type = BillingUIActionTypes.GO_TO_LAST_SECTION_UI;
}
export class SetBillingPeriodStatus implements Action {
  readonly type = BillingUIActionTypes.SET_BILLING_PERIOD_STATUS_UI;
  constructor(public status: boolean) {}
}
export class RevokeCancelMainPlan implements Action {
  readonly type = BillingUIActionTypes.REVOKE_CANCEL_MAINPLAN_UI;
}
export class ReceveEndFreeTrileDate implements Action {
  readonly type = BillingUIActionTypes.RECEVE_END_FREE_TRILE_DATE_UI;
  constructor(public date: number) {}
}
export class ReceveIsMainPlanExpire implements Action {
  readonly type = BillingUIActionTypes.RECEVE_IS_MAIN_PLAN_EXPIRE_UI;
  constructor(public status: boolean) {}
}
export class Reset implements Action {
  readonly type = BillingUIActionTypes.RESET;
}

export type Actions =
  | ReceivedSelecetedPLan
  | SetCurrentScreen
  | SetCurrentSelectedPlan
  | ReceveInvoicesListUI
  | SetBillingStatus
  | ReceveStripeErrorMasage
  | ReceveCouponResponse
  | ReceveBillingInfoResponse
  | SetBillingPeriodStatus
  | ReceveEndFreeTrileDate
  | ReceveIsMainPlanExpire
  | Reset;
