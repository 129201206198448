import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxModule } from '../ngx.module';
import { EmojiboxModule } from '../emojibox/emojibox.module';
import { SharedModule } from '../shared/shared.module';

import { ChatBoxComponent } from './chat-box/chat-box.component';
import { RecordVoiceNoteComponent } from './record-voice-note/record-voice-note.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';

@NgModule({
  imports: [
    CommonModule,
    NgxModule,
    SharedModule,
    EmojiboxModule,
    AbstractFramesModule
  ],
  declarations: [
    ChatBoxComponent,
    RecordVoiceNoteComponent,
    UploadFilesComponent
  ],
  exports: [ChatBoxComponent]
})
export class ChatboxModule {}
