/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./abstract-crop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i3 from "ngx-image-cropper";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../form-frame/form-frame.component.ngfactory";
import * as i7 from "../form-frame/form-frame.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./abstract-crop.component";
import * as i10 from "../../core/file.service";
import * as i11 from "../../cells/cells.service";
var styles_AbstractCropComponent = [i0.styles];
var RenderType_AbstractCropComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AbstractCropComponent, data: {} });
export { RenderType_AbstractCropComponent as RenderType_AbstractCropComponent };
function View_AbstractCropComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_AbstractCropComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [], [[4, "text-align", null]], [[null, "mousedown"], [null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], [null, "cropperReady"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("mousedown" === en)) {
        var pd_5 = (_co.onDragEvents($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageCropped" === en)) {
        var pd_6 = (_co.imageCropped($event) !== false);
        ad = (pd_6 && ad);
    } if (("imageLoaded" === en)) {
        var pd_7 = (_co.imageLoaded() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } if (("cropperReady" === en)) {
        var pd_9 = (_co.cropperReady() !== false);
        ad = (pd_9 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageFileChanged: [0, "imageFileChanged"], imageChangedEvent: [1, "imageChangedEvent"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"], resizeToHeight: [5, "resizeToHeight"], roundCropper: [6, "roundCropper"], onlyScaleDown: [7, "onlyScaleDown"], imageQuality: [8, "imageQuality"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedFile; var currVal_2 = _co.imageChangedEvent; var currVal_3 = _co.maintainAspectRatio; var currVal_4 = _co.aspectRatio; var currVal_5 = _co.resizeToWidth; var currVal_6 = _co.resizeToHeight; var currVal_7 = _co.roundCropper; var currVal_8 = _co.onlyScaleDown; var currVal_9 = 80; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
function View_AbstractCropComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.blobUrl$)); var currVal_1 = i1.ɵunv(_v, 0, 1, i1.ɵnov(_v, 2).transform(_co.blobUrl$)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AbstractCropComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "app-form-frame", [["no-padding", ""]], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.closePreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormFrameComponent_0, i6.RenderType_FormFrameComponent)), i1.ɵdid(1, 49152, null, 0, i7.FormFrameComponent, [], { showCancel: [0, "showCancel"] }, { closeNotify: "closeNotify" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 12, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "pool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "pool-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "crop"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AbstractCropComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AbstractCropComponent_2)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AbstractCropComponent_3)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, 15, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "add-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilesSelected(_co.imageFile) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.spinner$)); _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.skipCropper; _ck(_v, 13, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform((_co.skipCropper && _co.blobUrl$))); _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("IMAGECROPPER")); _ck(_v, 3, 0, currVal_1); var currVal_5 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("CONFIRM")); _ck(_v, 20, 0, currVal_5); }); }
export function View_AbstractCropComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-abstract-crop", [], null, null, null, View_AbstractCropComponent_0, RenderType_AbstractCropComponent)), i1.ɵdid(1, 114688, null, 0, i9.AbstractCropComponent, [i10.FileService, i11.CellsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AbstractCropComponentNgFactory = i1.ɵccf("app-abstract-crop", i9.AbstractCropComponent, View_AbstractCropComponent_Host_0, { imageChangedEvent: "imageChangedEvent", selectedFile: "selectedFile", resizeToWidth: "resizeToWidth", resizeToHeight: "resizeToHeight", cropFormat: "cropFormat", imageQuality: "imageQuality", aspectRatio: "aspectRatio", mimeType: "mimeType", onlyScaleDown: "onlyScaleDown", maintainAspectRatio: "maintainAspectRatio", roundCropper: "roundCropper", cropperMaxWidth: "cropperMaxWidth", cropperMaxHeight: "cropperMaxHeight", skipCropper: "skipCropper" }, { filesSelectedNotifay: "filesSelectedNotifay", closeNotifay: "closeNotifay" }, []);
export { AbstractCropComponentNgFactory as AbstractCropComponentNgFactory };
