import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractTablesModule } from 'src/app/abstract-tables/abstract-tables.module';
import { AbstractUploadModule } from 'src/app/abstract-upload/abstract-upload.module';
import { AbstractNotifiersModule } from 'src/app/abstract-notifiers/abstract-notifiers.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SectionContainerComponent } from './section-container/section-container.component';
import { SectionConfComponent } from './section-conf/section-conf.component';
import { SectionComponentsComponent } from './section-components/section-components.component';
import { YoutubeListConfComponent } from './section-components/youtube-list-conf/youtube-list-conf.component';
import { VideoConfComponent } from './section-components/video-conf/video-conf.component';
import { PdfViwerConfComponent } from './section-components/pdf-viwer-conf/pdf-viwer-conf.component';
import { MediaGalleryConfComponent } from './section-components/media-gallery-conf/media-gallery-conf.component';
import { GalleryLayoutComponent } from './section-components/media-gallery-conf/gallery-layout/gallery-layout.component';
import { LocationConfComponent } from './section-components/location-conf/location-conf.component';
import { ImageSliderConfComponent } from './section-components/image-slider-conf/image-slider-conf.component';
import { FeaturesListConfComponent } from './section-components/features-list-conf/features-list-conf.component';
import { ListLayoutComponent } from './section-components/features-list-conf/list-layout/list-layout.component';
import { PagesListComponent } from './pages-list/pages-list.component';
import { MarketPlaceComponent } from './market-place/market-place.component';
import { MarketItemInfoComponent } from './market-place/market-item-info/market-item-info.component';
import { MarketItemComponent } from './market-place/market-item/market-item.component';
import { ElementsListComponent } from './elements-list/elements-list.component';
import { AddPageComponent } from './add-page/add-page.component';
import { PageListComponent } from './add-page/page-list/page-list.component';
import { CreatePageComponent } from './add-page/create-page/create-page.component';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { NgxMasonryModule } from 'ngx-masonry';
import { MediaCenterModule } from 'src/app/media-center/media-center.module';
import { AbstractIconsModule } from 'src/app/abstract-icons/abstract-icons.module';
import { TextConfComponent } from './section-components/text-conf/text-conf.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CellsModule } from 'src/app/cells/cells.module';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxEditorModule } from 'ngx-editor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InstantArticleModule } from 'src/app/+merchant/+dashboard/instant-article/instant-article.module';
import {
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import { NgImageSliderModule } from 'ng-image-slider';
import { ClickOutsideModule } from 'ng-click-outside';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AbstractTooltip } from 'src/app/abstract-tooltip/abstract-tooltip.module';
import { AbstractPoolsModule } from 'src/app/abstract-pools/abstract-pools.module';
import { ImageViewerModule } from 'ngx-image-viewer';

@NgModule({
  imports: [
    CommonModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractTablesModule,
    ReactiveFormsModule,
    ColorPickerModule,
    SharedModule,
    NgxModule,
    AbstractUploadModule,
    AbstractNotifiersModule,
    AbstractControlsModule,
    AngularSvgIconModule,
    NgxDnDModule.forRoot(),
    NgxMasonryModule,
    MediaCenterModule,
    AbstractIconsModule,
    FormsModule,
    ClipboardModule,
    CellsModule,
    UiSwitchModule,
    NgxEditorModule,
    ImageCropperModule,
    InstantArticleModule,
    ImageViewerModule.forRoot(),
    AbstractPoolsModule,
    AbstractTooltip,
    DragDropModule,
    ClickOutsideModule,
    MatTooltipModule,
    NgImageSliderModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule
  ],
  declarations: [
    SectionContainerComponent,
    SectionConfComponent,
    SectionComponentsComponent,
    YoutubeListConfComponent,
    VideoConfComponent,
    TextConfComponent,
    PdfViwerConfComponent,
    MediaGalleryConfComponent,
    GalleryLayoutComponent,
    LocationConfComponent,
    ImageSliderConfComponent,
    FeaturesListConfComponent,
    ListLayoutComponent,
    PagesListComponent,
    MarketPlaceComponent,
    MarketItemInfoComponent,
    MarketItemComponent,
    ElementsListComponent,
    AddPageComponent,
    PageListComponent,
    CreatePageComponent
  ],

  exports: [
    SectionContainerComponent,
    SectionConfComponent,
    SectionComponentsComponent,
    YoutubeListConfComponent,
    VideoConfComponent,
    PdfViwerConfComponent,
    MediaGalleryConfComponent,
    GalleryLayoutComponent,
    LocationConfComponent,
    ImageSliderConfComponent,
    FeaturesListConfComponent,
    ListLayoutComponent,
    PagesListComponent,
    MarketPlaceComponent,
    MarketItemInfoComponent,
    MarketItemComponent,
    ElementsListComponent,
    AddPageComponent,
    PageListComponent,
    CreatePageComponent,
    TextConfComponent
  ]
})
export class PageBuilderConfModule {}
