import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appChannelData.actions';

import { AppState } from 'src/models/AppState';
import { ChannelData } from 'src/models/ChannelAppConfig';

@Injectable({
  providedIn: 'root'
})
export class AppChannelDataDispatchers {
  constructor(private _store: Store<AppState>) {}

  receiveAppChannelData(channelData: ChannelData[]): void {
    this._store.dispatch(new actions.ReceiveAppChannelData(channelData));
  }
}
