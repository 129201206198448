import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/AppState';
import { IMessage } from '../../../models/IMessage';
import { MainChat } from '../../../models/MainChat';
import * as MainChatActions from './mainChat.actions';
import { ReplyMessage } from '../../../models/ReplyMessage';
import { INDIVIDUAL, GROUP, CHANNEL } from '../../../models/constants';
import { HelperService } from '../../core/helper.service';

@Injectable({
  providedIn: 'root'
})
export class MainChatDispatchers {
  constructor(
    private _store: Store<AppState>,
    private _helperService: HelperService
  ) { }

  mainChatReceived(payload: MainChat) {
    this._store.dispatch(new MainChatActions.MainChatReceived(payload));
  }

  mainChatDeselected(id: string, lastTypedMessage: string) {
    this._store.dispatch(
      new MainChatActions.MainChatDeselected(id, lastTypedMessage)
    );
  }

  mainChatSelected(mainchat: MainChat) {
    this._store.dispatch(new MainChatActions.MainChatSelected(mainchat));
  }

  mainChatHistoryRequested(id: string) {
    this._store.dispatch(new MainChatActions.MainChatHistoryRequested(id));
  }

  mainChatMemberCountReceived(id: string, memberCount: number) {
    this._store.dispatch(
      new MainChatActions.MainChatMemberCountReceived(id, memberCount)
    );
  }

  loggedInLeftMainChat(id: string): void {
    this._store.dispatch(new MainChatActions.LoggedInLeftMainChat(id));
  }

  updateNumberOfUnreadMessages(
    id: string,
    unreadMessagesCount: number,
    isFirstLevelMessage: boolean,
    isRecalled: boolean
  ) {
    this._store.dispatch(
      new MainChatActions.UpdateNumberOfUnreadMessages(
        id,
        unreadMessagesCount,
        isFirstLevelMessage,
        isRecalled
      )
    );
  }

  updateLastMessage(
    mainChat: MainChat,
    lastMessage: ReplyMessage,
    afterRecall?: boolean
  ) {
    this._store.dispatch(
      new MainChatActions.UpdateLastMessage(mainChat, lastMessage, afterRecall)
    );
  }

  updateLastMessageStatus(
    id: string,
    lastMessageStatus: string,
    reference: number
  ) {
    this._store.dispatch(
      new MainChatActions.UpdateLastMessageStatus(
        id,
        lastMessageStatus,
        reference
      )
    );
  }

  incrementNumberOfNotViewedMessages(mainChat: MainChat) {
    this._store.dispatch(
      new MainChatActions.IncrementNumberOfNotViewedMessages(mainChat)
    );
  }

  decrementNumberOfNotViewedMessages(mainChat: MainChat) {
    this._store.dispatch(
      new MainChatActions.DecrementNumberOfNotViewedMessages(mainChat)
    );
  }

  resetNumberOfNotViewedMessages(mainChatID: string) {
    this._store.dispatch(
      new MainChatActions.ResetNumberOfNotViewedMessages(mainChatID)
    );
  }

  updateEndOfPage(mainChatID: string, eopLevel1: number) {
    this._store.dispatch(
      new MainChatActions.UpdateEndOfPage(mainChatID, eopLevel1)
    );
  }

  UpdateEopLevel1(mainChatID: string, endOfPage: number) {
    this._store.dispatch(
      new MainChatActions.UpdateEopLevel1(mainChatID, endOfPage)
    );
  }

  requestHistory(
    parentMsgID?: string,
    userID?: string,
    requestOnce?: boolean,
    subChatID?: string
  ): void {
    this._store.dispatch(
      new MainChatActions.RequestHistory(
        parentMsgID,
        userID,
        requestOnce,
        subChatID
      )
    );
  }

  replyReceivedInSelectedChat(message: IMessage) {
    this._store.dispatch(
      new MainChatActions.ReplyReceivedInSelectedChat(message)
    );
  }

  updateMainChatImages(id: string, imageUrl: string): void {
    this._store.dispatch(
      new MainChatActions.UpdateMainChatImages(id, imageUrl)
    );
  }

  getNumberOfUnreadMessages(
    currentMsgs: IMessage[],
    mainChat: MainChat
  ): number {
    return currentMsgs.filter(msg => {
      if (mainChat.type === INDIVIDUAL) {
        return (
          !msg.group_id &&
          (mainChat.id === msg.sender_id || mainChat.id === msg.receiver_id) &&
          !msg.loggedInIsSender &&
          !msg.isRead
        );
      } else {
        return (
          mainChat.id === msg.group_id && !msg.loggedInIsSender && !msg.isRead
        );
      }
    }).length;
  }

  getLastMessageInChat(
    currentMsgs: IMessage[],
    mainChat: MainChat
  ): ReplyMessage {
    const lastMessages = currentMsgs
      .filter(msg => {
        if (mainChat.type === INDIVIDUAL) {
          return (
            !msg.group_id &&
            (msg.sender_id === mainChat.id || msg.receiver_id === mainChat.id)
          );
        } else {
          if (mainChat.type === GROUP) {
            return msg.group_id === mainChat.id && msg.group_type === 100;
          } else if (mainChat.type === CHANNEL) {
            return msg.group_id === mainChat.id && msg.group_type === 101;
          }
        }
      })
      .reverse();
    return lastMessages[0]
      ? this._helperService.handleDifferentReplyTypes(lastMessages[0])
      : null;
  }

  mainChatDeleted(chat_id: string) {
    this._store.dispatch(new MainChatActions.MainChatDeleted(chat_id));
  }

  contactsNamesReceived(contacts: any[]) {
    this._store.dispatch(new MainChatActions.ContactsNamesReceived(contacts));
  }

  resetAllEndOfPage() {
    this._store.dispatch(new MainChatActions.ResetAllEndOfPage());
  }
}
