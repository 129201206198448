/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-droplist.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i4 from "angular-svg-icon";
import * as i5 from "ngx-bootstrap/dropdown";
import * as i6 from "ngx-bootstrap/component-loader";
import * as i7 from "./startover-droplist.component";
import * as i8 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
var styles_StartoverDroplistComponent = [i0.styles];
var RenderType_StartoverDroplistComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverDroplistComponent, data: {} });
export { RenderType_StartoverDroplistComponent as RenderType_StartoverDroplistComponent };
function View_StartoverDroplistComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "list-item-tec"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ("./assets/img/" + _v.context.$implicit.img); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_StartoverDroplistComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list-tec"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.teqQuestionItems; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "teq"); _ck(_v, 0, 0, currVal_0); }); }
function View_StartoverDroplistComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All Template "]))], null, null); }
function View_StartoverDroplistComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "unselected-cat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverDroplistComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "./assets/img/selectedAllTemplates.svg"; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(((_co.appMgmtService == null) ? null : _co.appMgmtService.selectedTempCat$))).type !== _co.allCat.type); _ck(_v, 4, 0, currVal_1); }, null); }
function View_StartoverDroplistComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "selected-cat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All Template "]))], function (_ck, _v) { var currVal_0 = "./assets/img/all-templates.svg"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverDroplistComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "unselected-cat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ("./assets/img/" + _v.parent.context.$implicit.img); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_StartoverDroplistComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "selected-cat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ("./assets/img/" + _v.parent.context.$implicit.imgS); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_StartoverDroplistComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "cat-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_10)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_11)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.type) !== ((_v.context.$implicit == null) ? null : _v.context.$implicit.type)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.type) === ((_v.context.$implicit == null) ? null : _v.context.$implicit.type)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_StartoverDroplistComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.catQuestionItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverDroplistComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "cat-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CATEGORIES "])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "cat-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedItem(_co.allCat) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverDroplistComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverDroplistComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverDroplistComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(((_co.appMgmtService == null) ? null : _co.appMgmtService.selectedTempCat$))).type !== _co.allCat.type); _ck(_v, 6, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(((_co.appMgmtService == null) ? null : _co.appMgmtService.selectedTempCat$))).type === _co.allCat.type); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(((_co.appMgmtService == null) ? null : _co.appMgmtService.selectedTempCat$))); _ck(_v, 12, 0, currVal_2); }, null); }
function View_StartoverDroplistComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "teq"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.type === "cat"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_StartoverDroplistComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], svgStyle: [1, "svgStyle"] }, null), i1.ɵpod(3, { "width.px": 0, "height.px": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ("./assets/img/" + _v.parent.context.$implicit.img); var currVal_1 = _ck(_v, 3, 0, 16, 16); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 5, 0, currVal_2); }); }
function View_StartoverDroplistComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], svgStyle: [1, "svgStyle"] }, null), i1.ɵpod(3, { "width.px": 0, "height.px": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ("./assets/img/" + _v.parent.context.$implicit.imgS); var currVal_1 = _ck(_v, 3, 0, 16, 16); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 5, 0, currVal_2); }); }
function View_StartoverDroplistComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [["class", "menuitem clickable-cursor drop-item-menu"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_16)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_17)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.type) !== ((_v.context.$implicit == null) ? null : _v.context.$implicit.type)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.type) === ((_v.context.$implicit == null) ? null : _v.context.$implicit.type)); _ck(_v, 5, 0, currVal_1); }, null); }
function View_StartoverDroplistComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_15)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.catQuestionItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverDroplistComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ul", [["class", "dropdown-menu no-copyable-item menu-container"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "li", [["class", "menuitem clickable-cursor drop-item-menu"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedItem(_co.allCat) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "svg-icon", [], null, null, null, i3.View_SvgIconComponent_0, i3.RenderType_SvgIconComponent)), i1.ɵdid(3, 1032192, null, 0, i4.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i4.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], svgStyle: [1, "svgStyle"] }, null), i1.ɵpod(4, { "width.px": 0, "height.px": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All Template "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverDroplistComponent_14)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "./assets/img/selectedAllTemplates.svg"; var currVal_1 = _ck(_v, 4, 0, 16, 16); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(((_co.appMgmtService == null) ? null : _co.appMgmtService.selectedTempCat$))); _ck(_v, 8, 0, currVal_2); }, null); }
function View_StartoverDroplistComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "div", [["class", "btn-group clickable-cursor menu-dropdown"], ["dropdown", ""], ["placement", "bottom right"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i5.BsDropdownState, i5.BsDropdownState, []), i1.ɵdid(2, 212992, null, 0, i5.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i6.ComponentLoaderFactory, i5.BsDropdownConfig, i5.BsDropdownState], { placement: [0, "placement"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["dropdownToggle", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i5.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i5.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i5.BsDropdownState], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"], ["style", "width: 24px; height: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-menu-alt"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_13)), i1.ɵdid(8, 16384, null, 0, i5.BsDropdownMenuDirective, [i5.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_3 = "bottom right"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 4).isDisabled; var currVal_6 = i1.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_StartoverDroplistComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverDroplistComponent_12)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDropDown; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isDropDown; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StartoverDroplistComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-droplist", [], null, null, null, View_StartoverDroplistComponent_0, RenderType_StartoverDroplistComponent)), i1.ɵdid(1, 638976, null, 0, i7.StartoverDroplistComponent, [i1.ChangeDetectorRef, i8.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverDroplistComponentNgFactory = i1.ɵccf("app-startover-droplist", i7.StartoverDroplistComponent, View_StartoverDroplistComponent_Host_0, { category: "category", type: "type", showList: "showList", isDropDown: "isDropDown" }, { saveNotify: "saveNotify" }, []);
export { StartoverDroplistComponentNgFactory as StartoverDroplistComponentNgFactory };
