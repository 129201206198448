import * as MobileWorkFlowStoreActionTypes from './mobileWorkflowStore.actions';

import {
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection
} from 'src/models/constants';
import { BotMenuUI } from 'src/models/BotMenuUI';
import { _INITIAL_STATE } from '@ngrx/store/src/tokens';

const INITIAL_STATE: any = {
  eop: 0,
  group_id: '',
  main_group_id: '',
  direction: null,
  data: []
};

export function MobileWorkflowStoreReducer(
  state = INITIAL_STATE,
  action: MobileWorkFlowStoreActionTypes.Actions
): any {
  switch (action.type) {
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORE_RECEIVING: {
      return {
        ...state,
        data: action.payload.data,
        eop: action.payload.eop,
        group_id: action.payload.group_id,
        main_group_id: action.payload.main_group_id
      };
    }
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORE_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORES_RECEIVING: {
      return {
        ...state
      };
    }
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case MobileWorkFlowStoreActionTypes.MobileWorkFlowStoreActionTypes
      .MOBILE_WORKFLOW_STORE_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case MobileWorkFlowStoreActionTypes.DESELECT_CHANNEL:
    case MobileWorkFlowStoreActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
