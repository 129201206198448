import { Action } from '@ngrx/store';
import {
  RcvdMyMenusMessage,
  BotMenu,
  RcvdMyMenuMessage
} from 'src/models/IBotMenu';
import {
  CREATE_BOT_MENU_REF,
  UPDATE_BOT_MENU_REF,
  DELETE_BOT_MENU_REF
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ImageSet } from 'src/models/ChannelAppConfig';

export const enum MenuUIActionTypes {
  UI_MENU_LIST_MENUS = 'UI_MENU_LIST_MENUS',
  UI_MENU_MENUS_RECEIVING = 'UI_MENU_MENUS_RECEIVING',
  UI_MENU_MENU_RECEIVING = 'UI_MENU_MENU_RECEIVING',
  UI_MENU_MENU_RECEIVED = 'UI_MENU_MENU_RECEIVED',
  UI_MENU_CREATE_MENU = 'UI_MENU_CREATE_MENU',
  UI_MENU_UPDATE_MENU = 'UI_MENU_UPDATE_MENU',
  UI_MENU_DELETE_MENU = 'UI_MENU_DELETE_MENU',
  UI_MENU_GET_NEXT_PAGE = 'UI_MENU_GET_NEXT_PAGE',
  UI_MENU_GET_PREV_PAGE = 'UI_MENU_GET_PREV_PAGE',
  UI_MENU_RESET_PAGING = 'UI_MENU_RESET_PAGING',
  UI_MENU_SET_IDLE_STATUS = 'UI_MENU_SET_IDLE_STATUS',
  UPLOAD_MENU_IMG = 'UPLOAD_MENU_IMG',
  UPLOAD_MENU_IMG_SUCCESS = 'UPLOAD_MENU_IMG_SUCCESS'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class ListMenus implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_LIST_MENUS;
  constructor(public payload) {}
}

export class MenusReceiving implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_MENUS_RECEIVING;
  constructor(public payload: RcvdMyMenusMessage) {}
}

export class MenuReceiveing implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_MENU_RECEIVING;
  constructor(public payload: RcvdMyMenuMessage) {}
}

export class MenuReceived implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_MENU_RECEIVED;
  constructor(public payload: BotMenu) {}
}

export class CreateMenu implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_CREATE_MENU;
  readonly ref = CREATE_BOT_MENU_REF;
  constructor(public payload: BotMenu) {}
}

export class UpdateMenu implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_UPDATE_MENU;
  readonly ref = UPDATE_BOT_MENU_REF;
  constructor(public payload: BotMenu) {}
}

export class DeleteMenu implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_DELETE_MENU;
  readonly ref = DELETE_BOT_MENU_REF;
  constructor(public payload: BotMenu) {}
}

export class GetNextMenuPage implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_GET_NEXT_PAGE;
}

export class GetPrevMenuPage implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_GET_PREV_PAGE;
}

export class ResetMenuPaging implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_RESET_PAGING;
}

export class SetStatusAsIdle implements Action {
  readonly type = MenuUIActionTypes.UI_MENU_SET_IDLE_STATUS;
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}

export class UploadMenuImg implements Action {
  readonly type = MenuUIActionTypes.UPLOAD_MENU_IMG;
  constructor(public componentRef: any, public selectedFile: ISelectedFiles) {}
}
export class UploadMenuImgSuccess implements Action {
  readonly type = MenuUIActionTypes.UPLOAD_MENU_IMG_SUCCESS;
  constructor(
    public componentRef: string,
    public imageUrl: string,
    public imageSet: ImageSet
  ) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type Actions =
  | ListMenus
  | MenusReceiving
  | MenuReceiveing
  | MenuReceived
  | CreateMenu
  | UpdateMenu
  | DeleteMenu
  | DeselectChannel
  | GetNextMenuPage
  | GetPrevMenuPage
  | ResetMenuPaging
  | SetStatusAsIdle
  | Reset;
