/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-container.component";
import * as i3 from "../../../+merchant/+layout/layout.service";
var styles_ModalContainerComponent = [i0.styles];
var RenderType_ModalContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalContainerComponent, data: {} });
export { RenderType_ModalContainerComponent as RenderType_ModalContainerComponent };
export function View_ModalContainerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-container"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_ModalContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-container", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ModalContainerComponent_0, RenderType_ModalContainerComponent)), i1.ɵdid(1, 245760, null, 0, i2.ModalContainerComponent, [i1.ElementRef, i3.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalContainerComponentNgFactory = i1.ɵccf("app-modal-container", i2.ModalContainerComponent, View_ModalContainerComponent_Host_0, { count: "count" }, { cancelNotfiy: "cancelNotfiy" }, ["*"]);
export { ModalContainerComponentNgFactory as ModalContainerComponentNgFactory };
