import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-apple-id-popup',
  templateUrl: './apple-id-popup.component.html',
  styleUrls: ['./apple-id-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppleIdPopupComponent implements OnInit {
  @Output() codeEntered = new EventEmitter<string>();
  @Output() closeNotify = new EventEmitter<boolean>();

  updatedCode = null;
  hasErrors = false;
  submitFocused = false;
  constructor(public changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.updatedCode = null;
  }

  submitCode(code) {
    this.updatedCode = code;
    if (this.updatedCode && this.updatedCode.length === 6) {
      this.hasErrors = false;
      this.submitFocused = true;
      this.changeDetector.detectChanges();
    }
  }

  onClose() {
    this.closeNotify.emit(true);
  }

  setCode() {
    if (
      !this.updatedCode ||
      (this.updatedCode && this.updatedCode.length !== 6)
    ) {
      this.hasErrors = true;
      this.changeDetector.detectChanges();
    } else {
      this.codeEntered.emit(this.updatedCode);
    }
  }
}
