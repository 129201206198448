import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { LinkPreviewGateway } from 'src/app/network/gateway/link-preview.gateway';
import { InstantDispatchers } from './instant.dispatchers';

import * as InstantActions from './instant.actions';
import * as InstantMethods from 'src/models/InstantArticle';
import { MessageLinkPreviewStatus, PAGE_LINK } from 'src/models/constants';

@Injectable()
export class InstantEffects {
  // call server to get chat member info after reciving message on socket
  /** Check before send request if it is alrady exist */
  @Effect({ dispatch: false })
  instantArticleCreating = this.actions$.pipe(
    ofType(InstantActions.InstantArticleActionTypes.INSTANT_ARTICLE_CREATING),
    map((action: InstantActions.InstantArticleCreating) => {
      /**
       * if conent exist so i will create article
       */
      if (action.payload.content) {
        this._socketGateway.sendSocketMessage(
          new InstantMethods.GreateArticle(
            action.payload.title,
            action.payload.author,
            action.payload.content,
            action.payload.reference
          )
        );
      } else if (action.payload.linkPreviewUrl) {
        this.getUrlInfo(action.payload, action.payload.linkPreviewUrl);
      }
    })
  );

  @Effect({ dispatch: false })
  instantArticleReceived = this.actions$.pipe(
    ofType(InstantActions.InstantArticleActionTypes.INSTANT_ARTICLE_RECEIVED),
    map((action: InstantActions.InstantArticleReceived) => {
      const url = `${PAGE_LINK}/${action.payload.id}`;
      this.getUrlInfo(action.payload, url);
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _linkPreviewGateway: LinkPreviewGateway,
    private _InstantArticleDispatchers: InstantDispatchers
  ) {}

  /**
   * Get link meta data previews ( HTTP Request )
   * @param payload (InstantArticle object)
   * @param url (string)
   */
  getUrlInfo(payload: InstantMethods.InstantArticle, url: string) {
    const msg: InstantMethods.InstantArticle = {};
    this._InstantArticleDispatchers.setLinkPreviewStatus({
      ...payload,
      linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FETCHING
    });
    this._linkPreviewGateway.getMetaFormUrl(url).subscribe(
      res => {
        if (res) {
          msg.reference = payload.reference;
          msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FETCHED;
          msg.linkPreviewUrl = res.linkPreviewUrl;
          msg.linkPreviewTitle = res.linkPreviewTitle;
          msg.linkPreviewDescription = res.linkPreviewDescription;
          msg.linkPreviewRootUrl = res.linkPreviewRootUrl;
          if (res.linkPreviewImage) {
            // we don't nedd them
            msg.linkPreviewImage = this._linkPreviewGateway.replaceHttp(
              res.linkPreviewImage
            );
            this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
          } else {
            this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
          }
        } else {
          this._InstantArticleDispatchers.setLinkPreviewStatus({
            ...payload,
            linkPreviewStatus: MessageLinkPreviewStatus.LINK_PREVIEW_FAILED
          });
        }
      },
      err => {
        msg.linkPreviewStatus = MessageLinkPreviewStatus.LINK_PREVIEW_FAILED;
        this._InstantArticleDispatchers.linkPreviewDetailsReceived(msg);
      },
      () => {}
    );
  }
}
