import { Actions } from './bookingPeriods.actions';
import * as actions from './bookingPeriods.actions';
import { BookingPeriods } from 'src/models/BookingPeriods';

const INITIAL_STATE: BookingPeriods[] = [];

export function bookingPeriodsReducer(
  state = INITIAL_STATE,
  action: Actions
): BookingPeriods[] {
  switch (action.type) {
    case actions.BookingPeriodsActionTypes.RECEIVE_BOOKING_PERIODS: {
      if (
        !state.find(
          bookingPeriods =>
            bookingPeriods.chat_id === action.bookingPeriods.chat_id
        )
      ) {
        return [...state, action.bookingPeriods];
      } else {
        return state.map(bookingPeriods => {
          if (bookingPeriods.chat_id === action.bookingPeriods.chat_id) {
            let updatedBooking: BookingPeriods = {};
            updatedBooking = { ...action.bookingPeriods };
            return updatedBooking;
          }
          return bookingPeriods;
        });
      }
    }
    case actions.BookingPeriodsActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
