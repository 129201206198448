<div class="main-container">
  <div class="titles" *ngIf="authService?.authCollection$ | async as auth">
    <div class="title" *ngIf="!auth?.mode || auth?.mode == 0">
      Create Home Menu
    </div>
    <div class="title" *ngIf="auth?.mode && auth?.mode == 1">
      Customize Home Menu
    </div>
    <div class="sub-title">
      <div>Fully Customize Your App Design</div>
    </div>
  </div>
  <div class="mob-themes">
    <!-- <app-main-page-view
      [tabContainerList]="tabContainerList"
      [appClass]="appManagementService.tabsAppClass$ | async"
      [tabContainer]="appManagementService.effectiveSelectedTab$ | async"
      [mobileType]="(appManagementService.appInfo$ | async)?.mob_ver"
      [menuForm]="menuForm()"
      [mobileTheme]="(appManagementService.appInfo$ | async)?.layout"
      [menus]="(appManagementService?.menusAppClass$ | async)?.menus"
    >
    </app-main-page-view> -->
    <app-menu-form
      *ngIf="menuApp?.menus?.length >= 1"
      [botMenuEdit]="currentMenu"
      [currentMenu]="currentMenu"
      [mainMenu]="true"
      [menusAppClass]="menuApp"
      [tabsList]="tabContainerList"
      (closeNotifiy)="closeEditBotMenuFormEvent()"
      (formEmitter)="getForm($event)"
      (newformEmitter)="getNewForm($event)"
      (mainMenuNotifiy)="goToApp()"
      [startOver]="true"
      [toBeEdited]="toBeEdited"
      [withMenus]="true"
      [appClass]="appManagementService.tabsAppClass$ | async"
      [currentTabs]="(appManagementService?.appInfo$ | async)?.menuTabs"
      (uploadNotify)="uploadImage($event)"
    ></app-menu-form>
    <app-menu-form
      *ngIf="menuApp?.menus?.length <= 0"
      [botMenuEdit]="''"
      [appClass]="appManagementService.tabsAppClass$ | async"
      [tabsList]="tabContainerList"
      [mainMenu]="true"
      (newformEmitter)="getNewForm($event)"
      [menusAppClass]="menuApp"
      (closeNotifiy)="closeEditBotMenuFormEvent()"
      (formEmitter)="getForm($event)"
      (mainMenuNotifiy)="goToApp()"
      [startOver]="true"
      [withMenus]="false"
      [currentTabs]="(appManagementService?.appInfo$ | async)?.menuTabs"
    ></app-menu-form>
  </div>
</div>
