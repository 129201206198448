import * as moment from 'moment';
import { DriverTrip } from 'src/models/DrverAssigned';
import * as datepipe from '@angular/common';

export interface DriverTripServer {
  text?: string;
  diff: string;
  start_day?: string;
  end_day?: string;
  day?: string;
  status?: string;
  account_id?: string;
}

export class DriverAssignedMiddleware {
  public static mapListToServer(driverAssignedList: DriverTrip[]) {
    const driverAssignedListForServer: DriverTripServer[] = [];
    driverAssignedList.forEach(trip => {
      const serverTrip: DriverTripServer = {
        start_day: null,
        end_day: null,
        diff: null
      };
      const startDate = Intl.DateTimeFormat('en-GB').format(trip.startDate);
      const endDate = Intl.DateTimeFormat('en-GB').format(trip.endDate);
      serverTrip.start_day = startDate
        .toString()
        .split(',')[0]
        .split('/')
        .reverse()
        .join('-');
      serverTrip.end_day = endDate
        .toString()
        .split(',')[0]
        .split('/')
        .reverse()
        .join('-');
      serverTrip.diff = trip.diff + '';
      driverAssignedListForServer.push(serverTrip);
    });

    return driverAssignedListForServer;
  }

  public static mapListFromServer(driverAssignedList: DriverTripServer[]) {
    const driverAssignedListForWeb: DriverTrip[] = [];
    driverAssignedList.forEach(trip => {
      const webTrip: DriverTrip = {
        text: null,
        employeeID: null,
        startDate: null,
        endDate: null
      };
      const startDate = new Date(trip.day);
      const endDate = new Date(trip.day);
      webTrip.startDate = startDate;
      webTrip.endDate = endDate;
      webTrip.text = trip.text;
      webTrip.employeeID = +trip.account_id;
      webTrip.diff = trip.diff;
      webTrip.text = null;
      driverAssignedListForWeb.push(webTrip);
    });

    return driverAssignedListForWeb;
  }
}
