import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as actions from './appRelease.actions';

import { AppState } from 'src/models/AppState';
import { AppRelease } from 'src/models/AppRelease';

@Injectable({
  providedIn: 'root'
})
export class AppReleaseDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetReleasePaging() {
    this._store.dispatch(new actions.ResetReleasePaging());
  }

  getNextPageRelease() {
    this._store.dispatch(new actions.GetNextPageRelease());
  }

  getPrevPageRelease() {
    this._store.dispatch(new actions.GetPrevPageRelease());
  }

  listAppReleases(): void {
    this._store.dispatch(new actions.ListAppReleases());
  }

  listAppReleaseId(appRelese: AppRelease): void {
    this._store.dispatch(new actions.ListAppReleaseId(appRelese));
  }

  listAppReleasesSuccess(
    eop: number,
    sop: number,
    appReleases: AppRelease[]
  ): void {
    this._store.dispatch(
      new actions.ListAppReleasesSuccess(eop, sop, appReleases)
    );
  }

  rcvdAppRelease(appRelease: AppRelease) {
    this._store.dispatch(new actions.RcvdAppRelease(appRelease));
  }

  removeAppRelease(id: number): void {
    this._store.dispatch(new actions.RemoveAppRelease(id));
  }

  removeAppReleaseSuccess(id: number): void {
    this._store.dispatch(new actions.RemoveAppReleaseSuccess(id));
  }

  viewAppReleaseDetails(id: number): void {
    this._store.dispatch(new actions.ViewAppReleaseDetails(id));
  }

  hideAppReleaseDetails(id: number): void {
    this._store.dispatch(new actions.HideAppReleaseDetails(id));
  }

  showAppleKeyForm(releaseID: number): void {
    this._store.dispatch(new actions.ShowAppleKeyForm(releaseID));
  }

  sendAppleKeyCode(code: string, releaseID: number) {
    this._store.dispatch(new actions.SendAppleKeyCode(code, releaseID));
  }

  getAppVer(releaseID: number) {
    this._store.dispatch(new actions.GetAppVer(releaseID));
  }

  rcvdAppReleaseVerData(appRelease: AppRelease) {
    this._store.dispatch(new actions.RcvdAppReleaseVer(appRelease));
  }

  addAppReleaseWaitingItem(appRelease: AppRelease) {
    this._store.dispatch(new actions.AddAppReleaseWaitingItem(appRelease));
  }
  removeAppReleaseWaitingItem() {
    this._store.dispatch(new actions.RemoveAppReleaseWaitingItem());
  }
}
