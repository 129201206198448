import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { UISections } from 'src/models/constants';
import { MobileWorkFlowStoreDispatchers } from 'src/app/store/mobileWorkflowStore/mobileWorkflowStore.dispatchers';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/ui/ui.dispatchers";
import * as i3 from "../../../store/mobileWorkflowStore/mobileWorkflowStore.dispatchers";
export class MobileWorkFlowStoreService {
    constructor(_rootStore, _uiDispatchers, _mobileWorkFlowStoreDispatchers) {
        this._rootStore = _rootStore;
        this._uiDispatchers = _uiDispatchers;
        this._mobileWorkFlowStoreDispatchers = _mobileWorkFlowStoreDispatchers;
        this._selectedMenu = new BehaviorSubject(undefined);
        this.selectedMenu$ = this._selectedMenu.asObservable();
        this._selectedMenuList = new BehaviorSubject(undefined);
        this.selectedMenuList$ = this._selectedMenuList.asObservable();
        this._mainMenuId = new BehaviorSubject(undefined);
        this.mainMenuId$ = this._mainMenuId.asObservable();
        this._selectedStoreId = new BehaviorSubject(undefined);
        this.selectedStoreId$ = this._selectedStoreId.asObservable();
        this._isDefault = new BehaviorSubject(0);
        this.isDefault$ = this._isDefault.asObservable();
        this._waiting = new BehaviorSubject(false);
        this.waiting$ = this._waiting.asObservable();
        this._storeData = new BehaviorSubject(undefined);
        this.storeData$ = this._storeData.asObservable();
        this._mobileStoreAppList = new BehaviorSubject(undefined);
        this.mobileStoreAppList$ = this._mobileStoreAppList.asObservable();
        this._selectedMenuImage = new BehaviorSubject(undefined);
        this.selectedMenuImage$ = this._selectedMenuImage.asObservable();
        this._selectedMenuName = new BehaviorSubject(undefined);
        this.selectedMenuName$ = this._selectedMenuName.asObservable();
        this._selectedMenuDesc = new BehaviorSubject(undefined);
        this.selectedMenuDesc$ = this._selectedMenuDesc.asObservable();
    }
    selectMenu(menu) {
        this._uiDispatchers.botMenuSelected(menu);
    }
    setBotMenuEdit(menu) {
        this._uiDispatchers.botMenuSelected(menu);
    }
    getMenuWithRefId(refId) {
        return this._rootStore.getBotMenuWRefId$(refId);
    }
    setMenuResponse(menu, next_menu) {
        const rows = menu.rows.map(row => {
            return Object.assign({}, row, { buttons: row.buttons.map(btn => {
                    return Object.assign({}, btn, { next_menu });
                }) });
        });
        const menuRows = Object.assign({}, menu, { rows });
        return menuRows;
    }
    backToMenus() {
        this._uiDispatchers.setSection(UISections.MENUS);
    }
    goToApp() {
        this._uiDispatchers.setSection(UISections.APP);
    }
    getNextPage() {
        this._mobileWorkFlowStoreDispatchers.getNextMenuPage();
    }
    getPrevPage() {
        this._mobileWorkFlowStoreDispatchers.getPrevMenuPage();
    }
    resetPaging() {
        this._mobileWorkFlowStoreDispatchers.resetMenuPaging();
    }
    /** Selectors */
    get botMenus$() {
        return this._rootStore.botMenus$;
    }
    getListofMenus() {
        this._mobileWorkFlowStoreDispatchers.listMenus();
    }
    uploadMenuImgLogo(componentRef, selectedFile) {
        this._mobileWorkFlowStoreDispatchers.UploadMenuImg(componentRef, selectedFile);
    }
    setStore(menu_id, currency, shop, image, name, description, is_default, id) {
        this._mobileWorkFlowStoreDispatchers.createMenu(menu_id, currency, shop, image, name, description, is_default, id);
    }
    get getStoreList$() {
        return this._rootStore.getMobileStoreShops$();
    }
    getMobileStoreById(id) {
        this._mobileWorkFlowStoreDispatchers.getMobileStore(id);
    }
    deleteStore(id) {
        this._mobileWorkFlowStoreDispatchers.deleteMenu(id);
    }
    stores$() {
        this._rootStore.getStores$();
    }
}
MobileWorkFlowStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileWorkFlowStoreService_Factory() { return new MobileWorkFlowStoreService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.UIDispatchers), i0.ɵɵinject(i3.MobileWorkFlowStoreDispatchers)); }, token: MobileWorkFlowStoreService, providedIn: "root" });
