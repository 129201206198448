import * as campaignUIActions from './campaignUI.actions';

import { SCREEN_STATUS, CampaignScreens } from 'src/models/constants';
import { CampaignUI } from 'src/models/CampaignUI';

const INITIAL_STATE: CampaignUI = {
  selectedCampaign: null,
  currentCampaignScreen: CampaignScreens.CAMPAIGN_LIST,
  eop: 0,
  sop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE
};

export function campaignUiReducer(
  state = INITIAL_STATE,
  action: campaignUIActions.Actions
): CampaignUI {
  switch (action.type) {
    case campaignUIActions.CampaignUIActionTypes.UI_LIST_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: null,
        eop: 0,
        sop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_LIST
      };
    }
    case campaignUIActions.CampaignUIActionTypes.UI_CREATE_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: null,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_CREATION
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_VIEW_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: action.selectedCampaign,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_VIEW
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_EDIT_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: action.selectedCampaign,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_EDIT
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_CLOSE_CREATE_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: null,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_LIST
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_CLOSE_VIEW_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: null,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_LIST
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_CLOSE_EDIT_CAMPAIGN: {
      return {
        ...state,
        currentCampaignScreen: CampaignScreens.CAMPAIGN_VIEW
      };
    }

    // calendars paging system
    case campaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_GET_NEXT_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case campaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_GET_PREV_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case campaignUIActions.CampaignUIActionTypes
      .UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK: {
      return {
        ...state,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }

    case campaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case campaignUIActions.DESELECT_CHANNEL:
    case campaignUIActions.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
