import {
  GET_USER_DASHBOARD_METHOD,
  SET_USER_DASHBOARD_METHOD
} from './constants';
import { PrivilegesName } from './privileges';
import { UISections } from './constants';
import { IOption } from './campaign';

export interface WidgetUI {
  title?: string;
  privilege?: string;
  section?: string;
  locate?: number;
  chartColor?: string;
  type?: number;
  interval?: number;
  chartStyle?: string;
  dateFormat?: number;
}

export interface DashboardUI {
  widgets?: WidgetUI[];
}

export interface SummaryUI {
  currentPage?: WidgetUI[];
  saveDefaultList?: boolean;
}

// export const enum ChartTypes {
//   USER = 1
// }

export const ChartTypes: IOption[] = [
  {
    key: 1,
    value: 'user'
  }
];

export const gettingStartedList = [
  {
    icon: 'icon-api',
    title: 'nandbox API',
    link: 'https://docs.nandbox.com/reference/nandbox-api'
  },
  {
    icon: 'icon-settings-1',
    title: 'How API/Bot works?',
    link: 'https://docs.nandbox.com/reference/how-api-works'
  },
  {
    icon: 'icon-ic-info',
    title: 'Authorization Token',
    link: 'https://docs.nandbox.com/reference/auth-token'
  },
  {
    icon: 'icon-loupe',
    title: 'Bot Search Index',
    link: 'https://docs.nandbox.com/reference/bot-search-index'
  }
  // {
  //   icon: 'icon-ic-favourite',
  //   title: 'API Bot Features',
  //   link: 'https://api.nandbox.com/#api-bot-features'
  // },
  // {
  //   icon: 'icon-api-functions',
  //   title: 'API Functions',
  //   link: 'https://api.nandbox.com/#api-functions'
  // },
  // {
  //   icon: 'icon-inbox-copy',
  //   title: 'Incoming Messages',
  //   link: 'https://api.nandbox.com/#incoming-messages'
  // },
  // {
  //   icon: 'icon-ic-outcoming',
  //   title: 'Outgoing Messages',
  //   link: 'https://api.nandbox.com/#outgoing-messages'
  // },
  // {
  //   icon: 'icon-ic-data-types',
  //   title: 'Data Types',
  //   link: 'https://api.nandbox.com/#data-types'
  // }
];
export const documentationList = [
  {
    icon: 'icon-vector-design',
    title: 'nandbox App Builder',
    link: 'https://docs.nandbox.com/docs/introduction'
  },
  {
    icon: 'icon-channel',
    title: 'Channel Modules',
    link: 'https://docs.nandbox.com/docs/channel-modules'
  },
  {
    icon: 'icon-team',
    title: 'Chat Group Module',
    link: 'https://docs.nandbox.com/docs/chat-group-modules'
  },
  {
    icon: 'icon-mail',
    title: 'Posts/Feed Module',
    link: 'https://docs.nandbox.com/docs/posts-feed-module'
  }
  // {
  //   icon: 'icon-question-1',
  //   title: 'How to Navigate',
  //   link: ''
  // },
  // {
  //   icon: 'icon-video-call',
  //   title: 'Audio & Video Calling Module',
  //   link:
  //     'https://docs.nandbox.com/#img-src-media-buildericons-24-hours-73a183a9-svg-alt-img-communication-audio-amp-video-calling'
  // },
  // {
  //   icon: 'icon-chat-1',
  //   title: 'Direct Chat Module',
  //   link:
  //     'https://docs.nandbox.com/#img-src-media-buildericons-24-hours-73a183a9-svg-alt-img-communication-direct-chat-module'
  // }
];

export const widgets = [
  {
    title: 'Posts',
    description: 'A list of your most recent posts.',
    privilege: PrivilegesName.listmessage,
    section: UISections.POSTS,
    icon: 'icon-posts-dashboard',
    backgroundColor: '#ffefd9',
    chartColor: ''
  },
  {
    title: 'Channels',
    description: 'A list of your active channels.',
    privilege: PrivilegesName.listchatgroup,
    section: UISections.SUBCHANNEL,
    icon: 'icon-channel-dashboard',
    backgroundColor: '#ddf1e2',
    chartColor: ''
  },
  {
    title: 'Chat Groups',
    description: 'A list of your active chat groups.',
    privilege: PrivilegesName.listchatgroup,
    section: UISections.SUBGROUP,
    icon: 'icon-group-dashboard',
    backgroundColor: '#daeffe',
    chartColor: ''
  },
  {
    title: 'Bookings',
    description: 'A list of your created bookings.',
    privilege: PrivilegesName.listbooking,
    section: UISections.BOOKING,
    icon: 'icon-booking-dashboard',
    backgroundColor: '#e4e2fd',
    chartColor: ''
  },
  {
    title: 'Events',
    description: 'A list of your created events.',
    privilege: PrivilegesName.listevent,
    section: UISections.EVENTS,
    icon: 'icon-events-dashboard',
    backgroundColor: '#ffe2de',
    chartColor: ''
  },
  {
    title: 'Calendars',
    description: 'A list of your created calendars.',
    privilege: PrivilegesName.listcalendar,
    section: UISections.CALENDAR,
    icon: 'icon-calendar-dashboard',
    backgroundColor: '#f2ead1',
    chartColor: ''
  },
  {
    title: 'mStore',
    description: 'A list of your created mobile stores.',
    privilege: PrivilegesName.listmstore,
    section: UISections.MSTORE,
    icon: 'icon-mStore-dashboard',
    backgroundColor: '#dbeaef',
    chartColor: ''
  },
  {
    title: 'Products',
    description: 'A list of your created products.',
    privilege: PrivilegesName.listproduct,
    section: UISections.PRODUCTS,
    icon: 'icon-product-dashboard',
    backgroundColor: '#eceff2',
    chartColor: ''
  },
  {
    title: 'Bundles',
    description: 'A list of your created bundles.',
    privilege: PrivilegesName.listbundle,
    section: UISections.BUNDLES,
    icon: 'icon-bundles-dashboard',
    backgroundColor: '#fff0eb',
    chartColor: ''
  },
  {
    title: 'Multi-tiered Plans',
    description: 'A list of your created multi-tiered plans.',
    privilege: PrivilegesName.listpackage,
    section: UISections.PACKAGES,
    icon: 'icon-plans-dashboard',
    backgroundColor: '#ebe2f9',
    chartColor: ''
  },
  {
    title: 'Product Adds-On',
    description: 'A list of your created product add-ons.',
    privilege: PrivilegesName.listproductaddons,
    section: UISections.STOREMENU,
    icon: 'icon-productAdds-On-dashboard',
    backgroundColor: '#f4efe4',
    chartColor: ''
  },
  {
    title: 'Users',
    description: 'Displays a list of your current app users.',
    privilege: PrivilegesName.listmember,
    section: UISections.USERS,
    icon: 'icon-users-dashboard',
    backgroundColor: '#ffe1dd',
    chartColor: ''
  },
  {
    title: 'Blacklist',
    description: 'A list of all your blacklisted users.',
    privilege: PrivilegesName.listblacklist,
    section: UISections.BLACKLIST,
    icon: 'icon-blacklist-dashboard',
    backgroundColor: '#f2dad7',
    chartColor: ''
  },
  {
    title: 'Whitelist',
    description: 'A list of all your whitelisted users.',
    privilege: PrivilegesName.listwhitelist,
    section: UISections.WHITELIST,
    icon: 'icon-whitelist-dashboard',
    backgroundColor: '#deeef3',
    chartColor: ''
  }
  // {
  //   title: 'Booking Chart',
  //   description: 'Displays the number of bookings per day, month, or year.',
  //   privilege: 'BOOKINGCHART',
  //   section: 'BOOKINGCHART',
  //   icon: '',
  //   backgroundColor: '#deeef3',
  //   chartColor: '#01a1ee'
  // }
];

export const defaultDashboard = [
  {
    title: 'Posts',
    privilege: 'listmessage',
    section: 'AP',
    locate: 1
  },
  {
    title: 'Users',
    privilege: 'listmember',
    section: 'MU',
    locate: 1
  },
  {
    title: 'Users Acquisition',
    privilege: 'chart',
    section: 'userChart',
    locate: 2,
    chartColor: '#01a1ee',
    type: ChartTypes[0].key,
    interval: 1,
    chartStyle: 'bar',
    dateFormat: 7
  },
  {
    title: 'Getting Started',
    privilege: 'gettingStarted',
    section: 'gettingStarted',
    locate: 3
  },
  {
    title: 'Documentation',
    privilege: 'documentation',
    section: 'documentation',
    locate: 3
  }
];

export const colorsChart = [
  {
    color: '#01a1ee',
    borderColor: '#007ab4'
  },
  {
    color: '#f4ab00',
    borderColor: '#da8f13'
  },
  {
    color: '#f14931',
    borderColor: '#c33716'
  },
  {
    color: '#4db8d9',
    borderColor: '#398da6'
  },
  {
    color: '#398da6',
    borderColor: '#204baf'
  },
  {
    color: '#97a0b0',
    borderColor: '#6f7e99'
  },
  {
    color: '#54bb6f',
    borderColor: '#2e8e47'
  },
  {
    color: '#c4394a',
    borderColor: '#c4394a'
  },
  {
    color: '#4a84ff',
    borderColor: '#235eda'
  },
  {
    color: '#3e436b',
    borderColor: '#0a1148'
  }
];

export const IntervalTypes: IOption[] = [
  {
    key: 1,
    value: 'Daily'
  },
  {
    key: 2,
    value: 'Weekly'
  },
  {
    key: 3,
    value: 'Monthly'
  },
  {
    key: 4,
    value: 'Yearly'
  }
];

export class SetUserDashboard {
  readonly method = SET_USER_DASHBOARD_METHOD;
  constructor(public dashboard: DashboardUI) {}
}

export class GetUserDashboard {
  readonly method = GET_USER_DASHBOARD_METHOD;
}
