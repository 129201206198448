<app-popup-setting-frame
  no-padding
  [title]="isInstant ? headerPopupText : 'Link a website'"
  [hideFooter]="true"
  (closeNotifier)="cancelEvent()"
  *ngIf="!isLink"
>
  <div
    class="article"
    *ngIf="!isArticleLink && !showComposeInstance && isInstant"
  >
    <p>Choose one of the following options.</p>
    <div class="article-btn" (click)="showInstantArticleEvent(true)">
      <div class="article-btn-icon">
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="/assets/svg/app-extra-icons.svg#icon-edit-pencil"
          ></use>
        </svg>
      </div>
      Write a new instant article
    </div>
    <div class="article-btn" (click)="toggleArticleLink(true)">
      <div class="article-btn-icon">
        <svg class="svg-icon icon-icon">
          <use
            xlink:href="./assets/svg/app-features-icons.svg#icon-link-2"
          ></use>
        </svg>
      </div>
      Attach article link
    </div>
  </div>
  <ng-container *ngIf="isArticleLink || !isInstant">
    <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="body">
        <app-reactive-text-input
          [filedType]="'url'"
          [placeHolder]="'Enter your URL here'"
          [fieldId]="'urlFiled'"
          [control]="form?.get('url')"
          [maxlength]="100"
        >
        </app-reactive-text-input>
      </div>

      <div class="form-group footer">
        <div class="form-submit">
          <app-border-btn submit [type]="'submit'">
            Send
          </app-border-btn>
          <app-border-btn back (btnClicked)="toggleArticleLink(false)">
            Cancel
          </app-border-btn>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="showComposeInstance">
    <app-instant-article-editor
      (cancelNotfiy)="showInstantArticleEvent(false)"
      (publishNotfiy)="publishInstantArticleEvent($event)"
    >
    </app-instant-article-editor>
  </ng-container>
</app-popup-setting-frame>

<div class="link-preview-input" *ngIf="isLink">
  <svg class="svg-icon icon-icon">
    <use xlink:href="./assets/svg/app-sections.svg#icon-link"></use>
  </svg>
  <input
    type="text"
    [placeholder]="'PLEASEENTERYOURURLHERE' | translate"
    (input)="showLinkPrivew($event.target.value)"
  />
</div>
