import { AbstractTableColumn } from './AbstractItem';
import {
  SET_PAYMENT_PROVIDER_METHOD,
  PaymentProviderIDs,
  GET_PAYMENT_PROVIDERS_METHOD,
  GET_PAYMENT_ORDERS_METHOD,
  GET_SERVICE_ORDERS_METHOD,
  MERCHANT_PAGE_SIZE,
  SET_ADS_PROVIDER_METHOD,
  AdsConfig,
  GET_ADS_PROVIDERS_METHOD,
  KeysConfig,
  ENABLE_ALL_SERVICE_METHOD
} from './constants';

// temp comment

export interface ProviderConfig {
  integration_id?: string;
  hmac_secret?: string;
  api_key?: string;
  publish_key?: string;
  webhook_secret?: string;
  secret_key?: string;
  merchant_email?: string;
  access_token?: string;
  currency?: string;
  env?: string;
  merchant_id?: string;
  merchant_account_id?: string;
  public_key?: string;
  private_key?: string;
  charges_enabled?: number;
}

export const paymentOrderTableColumns: AbstractTableColumn[] = [
  {
    colType: 'date',
    title: 'Date',
    class: 'md-title-tag'
  },
  {
    colType: 'oid',
    title: 'Order ID',
    class: 'md-2'
  },
  {
    colType: 'group_name',
    title: 'Channel',
    class: 'md-2'
  },
  {
    colType: 'receiver_name',
    title: 'Customer',
    class: 'lg'
  },
  {
    colType: 'amount',
    title: 'Amount',
    class: 'lg'
  },
  {
    colType: 'currency',
    title: 'Currency',
    class: 'lg'
  },
  {
    colType: 'provider_id',
    title: 'Method',
    class: 'lg'
  },
  {
    colType: 'account_name',
    title: 'Done by',
    class: 'lg'
  },
  {
    colType: 'status',
    title: 'Status',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'sm'
  }
];

export interface PaymentProvider {
  provider_id?: string;
  config?: ProviderConfig;
}

export interface PaymentAddress {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  postal_code?: string;
  state?: string;
  country_iso3?: string;
}

export interface PaymentOrder {
  date?: string;
  debit_amount_cents?: string;
  amount?: string;
  receiver_id?: string;
  credit_amount?: string;
  billing_address?: PaymentAddress;
  oid?: string;
  debit_amount?: string;
  order_ref?: string;
  sender_id?: string;
  payment_ref?: string;
  account_id?: string;
  profile_id?: string;
  domain?: string;
  provider_id?: string;
  currency?: string;
  id?: string;
  shipping_address?: PaymentAddress;
  provider_request?: string;
  provider_response?: string;
  com1?: string;
  status?: string;
  group_id?: string;
  group_name?: string;
  receiver_name?: string;
  account_name?: string;
}

export interface ServiceOrder {
  amount?: string;
  receiver_id?: string;
  credit_amount?: string;
  oid?: string;
  debit_amount?: string;
  units?: string;
  order_ref?: string;
  sender_id?: string;
  sid?: string;
  account_id?: string;
  profile_id?: string;
  product_id?: string;
  domain?: string;
  provider_id?: string;
  id?: string;
  product_display_name?: string;
  product_sku?: string;
  provider_response?: string;
  com1?: string;
  status?: string;
}

export class SetPaymentProviderMethod {
  readonly method = SET_PAYMENT_PROVIDER_METHOD;
  constructor(
    public provider_id: PaymentProviderIDs,
    public config: ProviderConfig,
    public active: number
  ) {}
}

export class GetPaymentProvidersMethod {
  readonly method = GET_PAYMENT_PROVIDERS_METHOD;
  constructor() {}
}

export class GetPaymentOrdersMethod {
  readonly method = GET_PAYMENT_ORDERS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public reference?: number,
    public oid?: string
  ) {}
}

export class SetAdsProviderMethod {
  readonly method = SET_ADS_PROVIDER_METHOD;
  constructor(public provider_id: string, public config: KeysConfig) {}
}

export class GetAdsProvidersMethod {
  readonly method = GET_ADS_PROVIDERS_METHOD;
  constructor(public provider_id: string) {}
}

export class GetServiceOrdersMethod {
  readonly method = GET_SERVICE_ORDERS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public reference?: number,
    public oid?: string,
    public sid?: string
  ) {}
}

export class EnableAllServicesMethod {
  readonly method = ENABLE_ALL_SERVICE_METHOD;
}
