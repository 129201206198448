import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ChatThemes from './chatThemes.actions';

import { AppState } from 'src/models/AppState';
import { ChatThemeOpj } from 'src/models/chatThemes';

@Injectable({
  providedIn: 'root'
})
export class ChatThemesDispatchers {
  constructor(private _store: Store<AppState>) {}

  setChatTheme(style: ChatThemeOpj) {
    this._store.dispatch(new ChatThemes.SetChatTheme(style));
  }

  getChatThemeFromServer() {
    this._store.dispatch(new ChatThemes.GetChatThemeFromServer());
  }

  receveChatThemeFromServer(style: ChatThemeOpj) {
    this._store.dispatch(new ChatThemes.ReceveChatThemeFromServer(style));
  }
}
