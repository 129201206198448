import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MyPageActions from './myPage.actions';
import { initRcvdPage } from './myPage.middleware';

import { AppState } from 'src/models/AppState';
import {
  MyPage,
  MyPageForm,
  MyPageContainer,
  MyPageComponent
} from 'src/models/MyPageConfig';

@Injectable({
  providedIn: 'root'
})
export class MyPageDispatchers {
  constructor(private _store: Store<AppState>) {}

  requestMyPage(id: string): void {
    this._store.dispatch(new MyPageActions.RequestMyPage(id));
  }

  deleteMyPage(pageID: string): void {
    this._store.dispatch(new MyPageActions.DeleteMyPage(pageID));
  }

  myPageDeleted(pageID: string): void {
    this._store.dispatch(new MyPageActions.MyPageDeleted(pageID));
  }

  getDetailsOfLinkedPage(pageID: string): void {
    this._store.dispatch(new MyPageActions.GetDetailsOfLinkedPage(pageID));
  }

  receiveMyPage(rcvdPage: MyPage): void {
    const initializedPage: MyPage = initRcvdPage(false, rcvdPage);
    this._store.dispatch(new MyPageActions.ReceiveMyPage(initializedPage));
  }

  receiveInitializedPage(rcvdPage: MyPage): void {
    this._store.dispatch(new MyPageActions.ReceiveMyPage(rcvdPage));
  }

  receiveMyPages(rcvdPages: MyPage[]): void {
    this._store.dispatch(new MyPageActions.ReceiveMyPages(rcvdPages));
  }

  createMyPage(
    reference: string,
    myPageForm?: MyPageForm,
    name?: string,
    url?: string,
    image?: string,
    desc?: string,
    templateId?: string
  ): void {
    this._store.dispatch(
      new MyPageActions.CreateMyPage(
        reference,
        myPageForm,
        name,
        url,
        image,
        desc,
        templateId
      )
    );
  }

  createPageTemplate(
    myPage?: MyPage,
    name?: string,
    image?: string,
    desc?: string
  ): void {
    this._store.dispatch(
      new MyPageActions.CreatePageTemplate(myPage, name, image, desc)
    );
  }

  myPageCreated(createdPage: MyPage, reference: string): void {
    const initializedPage: MyPage = initRcvdPage(false, createdPage, reference);
    this._store.dispatch(
      new MyPageActions.MyPageCreated(initializedPage, reference)
    );
  }

  pageTemplateCreated(pageId: string, childTemplateId: string) {
    this._store.dispatch(
      new MyPageActions.PageTemplateCreated(pageId, childTemplateId)
    );
  }

  updateMyPage(id: string, myPageDetails: MyPage): void {
    this._store.dispatch(new MyPageActions.UpdateMyPage(id, myPageDetails));
  }

  updateMyPageForm(
    formID: string,
    title: string,
    image: string,
    template: number,
    background: string
  ): void {
    this._store.dispatch(
      new MyPageActions.UpdateMyPageForm(
        formID,
        title,
        image,
        template,
        background
      )
    );
  }

  updateMyPageContainer(
    formID: string,
    containerID: string,
    containerUpdatedProps: MyPageContainer
  ): void {
    this._store.dispatch(
      new MyPageActions.UpdateMyPageContainer(
        formID,
        containerID,
        containerUpdatedProps
      )
    );
  }

  deleteMyPageContainer(formID: string, container: MyPageContainer): void {
    this._store.dispatch(
      new MyPageActions.DeleteMyPageContainer(formID, container)
    );
  }

  addMyPageContainer(formID: string, container: MyPageContainer): void {
    this._store.dispatch(
      new MyPageActions.AddMyPageContainer(formID, container)
    );
  }

  reorderPageContainers(
    formID: string,
    containerID: string,
    newPosition: number
  ): void {
    this._store.dispatch(
      new MyPageActions.ReorderPageContainers(formID, containerID, newPosition)
    );
  }

  updateMyPageComponent(
    formID: string,
    containerID: string,
    componentID: string,
    componentUpdatedProps: MyPageComponent,
    needPageID?: boolean
  ): void {
    this._store.dispatch(
      new MyPageActions.UpdateMyPageComponent(
        formID,
        containerID,
        componentID,
        componentUpdatedProps,
        needPageID
      )
    );
  }

  deleteMyPageComponent(
    formID: string,
    containerID: string,
    component: MyPageComponent
  ): void {
    this._store.dispatch(
      new MyPageActions.DeleteMyPageComponent(formID, containerID, component)
    );
  }

  addMyPageComponent(
    formID: string,
    containerID: string,
    component: MyPageComponent
  ): void {
    this._store.dispatch(
      new MyPageActions.AddMyPageComponent(formID, containerID, component)
    );
  }

  reorderPageComponents(
    formID: string,
    containerID: string,
    componentID: string,
    newPosition: number
  ): void {
    this._store.dispatch(
      new MyPageActions.ReorderPageComponents(
        formID,
        containerID,
        componentID,
        newPosition
      )
    );
  }
}
