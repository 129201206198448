import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { AddCredit, BillingCoupon, InvoiceOpj } from 'src/models/billings';

import * as Billing from './billingUI.actions';

@Injectable({
  providedIn: 'root'
})
export class BillingUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  receivedSelecetedPLan(planID: string, cancelEnd?: any) {
    this._store.dispatch(new Billing.ReceivedSelecetedPLan(planID, cancelEnd));
  }

  setCurrentScreen(screen: string, lastScreen?: string) {
    this._store.dispatch(new Billing.SetCurrentScreen(screen, lastScreen));
  }

  setCurrentSelectedPlan(plan: any) {
    this._store.dispatch(new Billing.SetCurrentSelectedPlan(plan));
  }

  sendSubscribetionToPlan(subscribeDetails: AddCredit, lm_data?: any) {
    this._store.dispatch(
      new Billing.SendSubscribetionToPlan(subscribeDetails, lm_data)
    );
  }

  sendCoupon(coupon: string) {
    this._store.dispatch(new Billing.SendCouponUI(coupon));
  }

  addNewCreditCard(token: AddCredit) {
    this._store.dispatch(new Billing.AddNewCreditCardUI(token));
  }

  setDefaultCardUI(card_id: string) {
    this._store.dispatch(new Billing.SetDefaultCardUI(card_id));
  }

  deleteCreditCardUI(card_id: string) {
    this._store.dispatch(new Billing.DeleteCreditCardUI(card_id));
  }

  getInvoicesListUI() {
    this._store.dispatch(new Billing.GetInvoicesListUI());
  }
  getBillingInfoUI() {
    this._store.dispatch(new Billing.GetBillingInfoUI());
  }

  receveInvoicesLisUI(list: InvoiceOpj[]) {
    this._store.dispatch(new Billing.ReceveInvoicesListUI(list));
  }

  changePlanRequest(new_plan_id: string, coupon?: string) {
    this._store.dispatch(new Billing.ChangePlanRequest(new_plan_id, coupon));
  }
  subscribeToAddonsRequest(plan_id: string, coupon?: string) {
    this._store.dispatch(new Billing.SubscribeToAddonsRequest(plan_id, coupon));
  }

  receveStripeErrorMasage(msg: string) {
    this._store.dispatch(new Billing.ReceveStripeErrorMasage(msg));
  }

  cancelStripeSubsCription(
    all: boolean,
    plan_id?: string,
    reason?: string,
    subMain?: boolean,
    cancelQuantity?: number
  ) {
    this._store.dispatch(
      new Billing.CancelStripeSubsCription(
        all,
        plan_id,
        reason,
        subMain,
        cancelQuantity
      )
    );
  }

  setBillingStatus(status: string) {
    this._store.dispatch(new Billing.SetBillingStatus(status));
  }

  receveCouponResponse(coupon: BillingCoupon) {
    this._store.dispatch(new Billing.ReceveCouponResponse(coupon));
  }

  receveBillingInfoResponse(data: any, main_plan: any) {
    this._store.dispatch(
      new Billing.ReceveBillingInfoResponse(data, main_plan)
    );
  }

  goToLastSection() {
    this._store.dispatch(new Billing.GoToLastSection());
  }

  setBillingPeriodStatus(status: boolean) {
    this._store.dispatch(new Billing.SetBillingPeriodStatus(status));
  }

  revokeCancelMainPlan() {
    this._store.dispatch(new Billing.RevokeCancelMainPlan());
  }

  receveEndFreeTrileDate(date: number) {
    this._store.dispatch(new Billing.ReceveEndFreeTrileDate(date));
  }
  receveIsMainPlanExpire(status: boolean) {
    this._store.dispatch(new Billing.ReceveIsMainPlanExpire(status));
  }
}
