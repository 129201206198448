import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges
} from '@angular/core';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { LocalItem } from './../../../../../../../models/ChannelAppLocalConfig';
import { FeaturesSublistComponent } from './../features-sublist/features-sublist.component';
import { MainItemFeatureContainerComponent } from './../../custom-area/main-page-conf/main-item-feature-container/main-item-feature-container.component';
import { AppMgmtService } from '../../../appMgmt.service';
import {
  FeatureAction,
  TabTypes,
  ChannelAppScreens,
  OpenChatTypes,
  MyListTabTypes,
  PaymentProviderIDs,
  LOGIN_TYPES
} from 'src/models/constants';
import { FeatureItem } from './../../../../../../../models/constants';
import { BotsService } from './../../../../bots/bots.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from 'src/app/core/ui.service';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';
@Component({
  selector: 'app-features-counter',
  templateUrl: './features-counter.component.html',
  styleUrls: ['./features-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesCounterComponent implements OnInit, OnChanges {
  price: number;
  weeks: number;
  nandboxPrice = 49;
  userFeatures: number;
  features: FeatureAction[][];
  featuresData: FeatureItem[];
  featuresIds: string[] = [];
  paymentProviderIDs = PaymentProviderIDs;
  loginTypes = LOGIN_TYPES;
  noIds = false;
  @Input() tabsList: LocalContainer[];
  @Input() currentPage: any;
  @Input() mode: number;
  @Input() appInfo: AppInfo;
  appScreens = ChannelAppScreens;
  @Input() navMenuItems: LocalItem[];
  odometerFinished: boolean;
  tabTypes = TabTypes;
  openChats = OpenChatTypes;
  myListTypes = MyListTabTypes;
  featuresSubList = new FeaturesSublistComponent(
    this._ref,
    this.appMgntService,
    this.botService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );
  mainItemContainer = new MainItemFeatureContainerComponent(
    this._ref,
    this.appMgntService,
    this.botService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );
  constructor(
    private _ref: ChangeDetectorRef,
    public appMgntService: AppMgmtService,
    public botService: BotsService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {}

  ngOnInit() {
    if (this.mode == 2) {
      for (let i = 0; i < this.tabsList.length; i++) {
        if (!this.tabsList[i].ui_module_id) {
          if (this.tabsList[i].type === this.tabTypes.FEED) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '505';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.MEMBERSHIP) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '800';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.WALLET) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '700';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.WEBVIEW) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '1001';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.SETTINGS) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '0';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.SECTION) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '0';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.SEARCH) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '1002';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }

          if (this.tabsList[i].type === this.tabTypes.PAGE) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '0';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.QR) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '1000';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.OPENCHAT) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '512';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.MYLIST) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '509';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.MAP) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '1100';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.INVITATION) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '900';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.CHATS) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '502';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.CHANNELS) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '501';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.CALL_LOG) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '500';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.CALENDAR) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '600';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.BOOKING) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '1200';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );
            this.noIds = true;
          }

          if (this.featuresIds.indexOf(this.tabsList[i].ui_module_id) <= -1) {
            this.featuresIds.push(this.tabsList[i].ui_module_id);
          }
        } else {
          if (
            this.tabsList[i].ui_module_id === '502' &&
            this.tabsList[i].type === this.tabTypes.FEED
          ) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '505';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );

            this.noIds = true;
          }
          if (this.tabsList[i].ui_module_id === '504') {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '505';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );

            this.noIds = true;
          }

          if (
            this.tabsList[i].type === this.tabTypes.CHATS &&
            this.tabsList[i].ui_module_id !== '503' &&
            this.tabsList[i].ui_module_id !== '502'
          ) {
            const feedContainer: LocalContainer = {};
            feedContainer.ui_module_id = '502';
            this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateContainer(
              this.tabsList[i].ref,
              feedContainer
            );

            this.noIds = true;
          }
          if (this.tabsList[i].type === this.tabTypes.OPENCHAT) {
            if (this.tabsList[i].ui_module_id === '510') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.BOT;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            } else if (this.tabsList[i].ui_module_id === '511') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.CONTACT;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            } else if (this.tabsList[i].ui_module_id === '512') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.GROUP;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            }
          }

          if (this.tabsList[i].type === this.tabTypes.MYLIST) {
            if (this.tabsList[i].ui_module_id === '507') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.CHANNEL;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            } else if (this.tabsList[i].ui_module_id === '508') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.CONTACT;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            } else if (this.tabsList[i].ui_module_id === '509') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.GROUP;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            } else if (this.tabsList[i].ui_module_id === '515') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.BOOKING;
              this.appMgntService.updateContainer(
                this.tabsList[i].ref,
                feedContainer
              );
            }
          }
          if (this.featuresIds.indexOf(this.tabsList[i].ui_module_id) <= -1) {
            this.featuresIds.push(this.tabsList[i].ui_module_id);
          }
        }
      }

      for (let j = 0; j < this.navMenuItems.length; j++) {
        if (!this.navMenuItems[j].ui_module_id) {
          if (this.navMenuItems[j].link === this.tabTypes.FEED) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '505';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.MEMBERSHIP) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '800';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.WALLET) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '700';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.WEBVIEW) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '1001';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.SETTINGS) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '0';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.SECTION) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '0';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.SEARCH) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '1002';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.PAGE) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '0';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.QR) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '1000';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.OPENCHAT) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '512';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.MYLIST) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '509';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.MAP) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '1100';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.INVITATION) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '900';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.CHATS) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '502';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.CHANNELS) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '501';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.CALL_LOG) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '500';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.CALENDAR) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '600';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }
          if (this.navMenuItems[j].link === this.tabTypes.BOOKING) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '1200';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );
          }

          if (
            this.featuresIds.indexOf(this.navMenuItems[j].ui_module_id) <= -1
          ) {
            this.featuresIds.push(this.navMenuItems[j].ui_module_id);
          }

          this.noIds = true;
        } else {
          if (
            this.navMenuItems[j].link === this.tabTypes.CHATS &&
            this.navMenuItems[j].ui_module_id !== `503` &&
            this.navMenuItems[j].ui_module_id !== `502`
          ) {
            const feedContainer: LocalItem = {};
            feedContainer.ui_module_id = '502';
            this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
            this.appMgntService.updateItem(
              this.navMenuItems[j].ref,
              feedContainer
            );

            this.noIds = true;
          }
          if (this.navMenuItems[j].link === this.tabTypes.OPENCHAT) {
            if (this.navMenuItems[j].ui_module_id === '510') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.BOT;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            } else if (this.navMenuItems[j].ui_module_id === '511') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.CONTACT;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            } else if (this.navMenuItems[j].ui_module_id === '512') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.openChats.GROUP;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            }
          }

          if (this.navMenuItems[j].link === this.tabTypes.MYLIST) {
            if (this.navMenuItems[j].ui_module_id === '507') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.CHANNEL;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            } else if (this.navMenuItems[j].ui_module_id === '508') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.CONTACT;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            } else if (this.navMenuItems[j].ui_module_id === '509') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.GROUP;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            } else if (this.navMenuItems[j].ui_module_id === '515') {
              const feedContainer: LocalContainer = {};
              feedContainer.sub_type = this.myListTypes.BOOKING;
              this.appMgntService.updateItem(
                this.navMenuItems[j].ref,
                feedContainer
              );
            }
          }

          if (
            this.featuresIds.indexOf(this.navMenuItems[j].ui_module_id) <= -1
          ) {
            this.featuresIds.push(this.navMenuItems[j].ui_module_id);
          }
        }
      }

      this.odometerFinished = false;
      this.nandboxPrice = 49;
      this.features = this.featuresSubList.getTotalPrice(
        this.navMenuItems,
        this.tabsList
      );
      this.featuresData = this.mainItemContainer.getTotalPrices(this.features);
      this.getTotalPrice(this.featuresData);
      setTimeout(() => this.odometerFinish(), 3000);
    }
    if (this.noIds) {
      this.featuresIds = this.addExtraModules(this.featuresIds);
      this.appMgntService.savePage(false, null, this.featuresIds);
    }
  }
  ngOnChanges() {
    // for (let i = 0; i < this.tabsList.length; i++) {
    //   if (!this.tabsList[i].ui_module_id) {
    //     if (this.tabsList[i].type === this.tabTypes.FEED) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '505';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.MEMBERSHIP) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '800';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.WALLET) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '700';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.WEBVIEW) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '1001';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.SETTINGS) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '0';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.SECTION) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '0';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.SEARCH) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '1002';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.PAGE) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '0';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.QR) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '1000';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.OPENCHAT) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '512';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.MYLIST) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '509';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.MAP) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '1100';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.INVITATION) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '900';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.CHATS) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '502';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.CHANNELS) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '501';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.CALL_LOG) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '500';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.CALENDAR) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '600';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.tabsList[i].type === this.tabTypes.BOOKING) {
    //       const feedContainer: LocalContainer = {};
    //       feedContainer.ui_module_id = '1200';
    //       this.tabsList[i].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateContainer(
    //         this.tabsList[i].ref,
    //         feedContainer
    //       );
    //     }
    //   }
    // }

    // for (let j = 0; j < this.navMenuItems.length; j++) {
    //   if (!this.navMenuItems[j].ui_module_id) {
    //     if (this.navMenuItems[j].link === this.tabTypes.FEED) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '505';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.MEMBERSHIP) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '800';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.WALLET) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '700';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.WEBVIEW) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '1001';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.SETTINGS) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '0';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.SECTION) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '0';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.SEARCH) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '1002';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.PAGE) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '0';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.QR) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '1000';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.OPENCHAT) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '512';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.MYLIST) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '509';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.MAP) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '1100';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.INVITATION) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '900';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.CHATS) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '502';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.CHANNELS) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '501';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.CALL_LOG) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '500';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.CALENDAR) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '600';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //     if (this.navMenuItems[j].link === this.tabTypes.BOOKING) {
    //       const feedContainer: LocalItem = {};
    //       feedContainer.ui_module_id = '1200';
    //       this.navMenuItems[j].ui_module_id = feedContainer.ui_module_id;
    //       this.appMgntService.updateItem(
    //         this.navMenuItems[j].ref,
    //         feedContainer
    //       );
    //     }
    //   }
    // }
    this.features = this.featuresSubList.getTotalPrice(
      this.navMenuItems,
      this.tabsList
    );
    this.featuresData = this.mainItemContainer.getTotalPrices(this.features);
    this.getTotalPrice(this.featuresData);
    this._ref.detectChanges();
  }
  odometerFinish() {
    this.odometerFinished = true;
    // if (
    //   (this.currentPage === this.appScreens.TABS ||
    //     this.currentPage === this.appScreens.UPLOAD_LOGO ||
    //     this.currentPage === this.appScreens.NAV_MENU ||
    //     this.currentPage === this.appScreens.MAIN ||
    //     this.currentPage === this.appScreens.PUBLISH ||
    //     this.currentPage === this.appScreens.APP_RELEASES) &&
    //   this.mode == 2 &&
    //   this._ref
    // ) {
    //   this._ref.detectChanges();
    // }
  }
  getTotalPrice(featuresData) {
    let totalPrice = 0;
    let totalDuration = 0;
    let selectedFeatures = 0;
    for (let i = 0; i < featuresData.length; i++) {
      totalPrice = totalPrice + featuresData[i].price;
      totalDuration = totalDuration + featuresData[i].duration;
    }
    selectedFeatures = featuresData.length;
    this.price = totalPrice * 1.5;
    this.weeks = totalDuration;
    this.userFeatures = selectedFeatures;
  }

  addExtraModules(featuresIds) {
    const cashModuleId = '30105';
    const payPalModuleId = '30104';
    const stripeModuleId = '30100';
    const emailModuleId = '30305';
    const noLoginModuleId = '30311';
    const mobileModuleId = '30200';
    const googleAdsModuleId = '30103';
    const appRatingModuleId = '30304';

    if (this.appInfo && this.appInfo.payment_provider) {
      this.appInfo.payment_provider.forEach(provider => {
        if (provider === this.paymentProviderIDs.CASH) {
          featuresIds.push(cashModuleId);
        } else if (provider === this.paymentProviderIDs.PAYPAL) {
          featuresIds.push(payPalModuleId);
        } else if (provider === this.paymentProviderIDs.STRIPE) {
          featuresIds.push(stripeModuleId);
        }
      });
    }

    if (this.appInfo.login_type === this.loginTypes.EMAIL) {
      featuresIds.push(emailModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.NO_LOGIN) {
      featuresIds.push(noLoginModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.MSISDN) {
      featuresIds.push(mobileModuleId);
    }

    if (this.appInfo.show_submitting_app_review) {
      featuresIds.push(appRatingModuleId);
    }

    if (this.appInfo.ads_provider === this.paymentProviderIDs.GOOGLEPROVIDER) {
      featuresIds.push(googleAdsModuleId);
    }

    return featuresIds;
  }
}
