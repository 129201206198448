import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as BookingUIActions from './bookingUI.actions';

import { IChat } from 'src/models/IChat';
import { AppState } from 'src/models/AppState';
import { EnableServiceConfig } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class BookingUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  listBookingUI(): void {
    this._store.dispatch(new BookingUIActions.ListBookingUI());
  }
  createBookingUI(): void {
    this._store.dispatch(new BookingUIActions.CreateBookingUI());
  }
  viewBookingUI(selectedBooking: IChat) {
    this._store.dispatch(new BookingUIActions.ViewBookingUI(selectedBooking));
  }
  editBookingUI(selectedBooking: IChat, subChat?: any) {
    this._store.dispatch(
      new BookingUIActions.EditBookingUI(selectedBooking, subChat)
    );
  }

  uploadBookingImage(booking) {
    this._store.dispatch(new BookingUIActions.UploadBookingImage(booking));
  }
  bookingPhotoUploadUI(createdBooking: IChat) {
    this._store.dispatch(
      new BookingUIActions.BookingPhotoUploadUI(createdBooking)
    );
  }
  bookingCreationComplete() {
    this._store.dispatch(new BookingUIActions.BookingCreationComplete());
  }

  showReservations(selectedBooking: IChat) {
    this._store.dispatch(
      new BookingUIActions.ShowReservationsUI(selectedBooking)
    );
  }

  closeViewBookingUI() {
    this._store.dispatch(new BookingUIActions.CloseViewBookingUI());
  }
  closeEditBookingUI(): void {
    this._store.dispatch(new BookingUIActions.CloseEditBookingUI());
  }

  // paging dispatchers
  getNextBookingPage(): void {
    this._store.dispatch(new BookingUIActions.GetNextBookingPage());
  }

  getPrevBookingPage(): void {
    this._store.dispatch(new BookingUIActions.GetPrevBookingPage());
  }

  receivedBookingHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChat[]
  ): void {
    this._store.dispatch(
      new BookingUIActions.ReceivedBookingHistoryChunk(eop, sop, currentPage)
    );
  }
  setEnableService(config: EnableServiceConfig): void {
    this._store.dispatch(new BookingUIActions.SetEnableService(config));
  }
  resetBookingPaging(): void {
    this._store.dispatch(new BookingUIActions.ResetBookingPaging());
  }

  setChat(chat: IChat) {
    this._store.dispatch(new BookingUIActions.SetChat(chat));
  }

  setBookingChat(data) {
    this._store.dispatch(new BookingUIActions.SetBookingChat(data));
  }

  setPaymentService(config) {
    this._store.dispatch(new BookingUIActions.SetPaymentService(config));
  }
}
