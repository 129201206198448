/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-more-modules.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./startover-more-modules.component";
import * as i3 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
var styles_StartoverMoreModulesComponent = [i0.styles];
var RenderType_StartoverMoreModulesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverMoreModulesComponent, data: {} });
export { RenderType_StartoverMoreModulesComponent as RenderType_StartoverMoreModulesComponent };
export function View_StartoverMoreModulesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "item-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "checked option-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "tab-icon-svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [[":xlink:href", ""]], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "input", [["class", "check-checkbox"], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkedItem(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "label", [["class", "check-Label"]], [[8, "htmlFor", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("./assets/svg/app-features-icons.svg#" + ((_co.item == null) ? null : _co.item.icon)); _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.item == null) ? null : _co.item.title); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.moreTypes.indexOf(_co.item) > (0 - 1)); var currVal_3 = ((_co.item == null) ? null : _co.item.id); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = ((_co.item == null) ? null : _co.item.id); _ck(_v, 9, 0, currVal_4); }); }
export function View_StartoverMoreModulesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-more-modules", [], null, null, null, View_StartoverMoreModulesComponent_0, RenderType_StartoverMoreModulesComponent)), i1.ɵdid(1, 114688, null, 0, i2.StartoverMoreModulesComponent, [i3.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverMoreModulesComponentNgFactory = i1.ɵccf("app-startover-more-modules", i2.StartoverMoreModulesComponent, View_StartoverMoreModulesComponent_Host_0, { item: "item", moreTypes: "moreTypes", appInfo: "appInfo" }, { checkedItemNotify: "checkedItemNotify" }, []);
export { StartoverMoreModulesComponentNgFactory as StartoverMoreModulesComponentNgFactory };
