import { Action } from '@ngrx/store';

import {
  IMediaCenter,
  MediaCenterRcvd,
  MediaCenterPrams
} from 'src/models/IMediaCenter';

export const enum MediaCenterActionTypes {
  GET_MY_MEDIA_GALLERY = 'GET_MY_MEDIA_GALLERY',
  MY_MEDIA_GALLERY_RCVD = 'MY_MEDIA_GALLERY_RCVD',
  UPDATE_MEDIA_CENTER_LOADING_PROGRESS = 'UPDATE_MEDIA_CENTER_LOADING_PROGRESS',
  MEDIA_UPLOADING = 'MEDIA_UPLOADING',
  MEDIA_UPLOADING_PROCESSED = 'MEDIA_UPLOADING_PROCESSED',
  UPDATE_MEDIA_CENTER_STATUS = 'UPDATE_MEDIA_CENTER_STATUS',
  DELETE_MEDIA_CENTER_ITEM = 'DELETE_MEDIA_CENTER_ITEM'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class MyMediaGalleryRcvd implements Action {
  readonly type = MediaCenterActionTypes.MY_MEDIA_GALLERY_RCVD;
  constructor(public payload: MediaCenterRcvd[]) {}
}

export class GetMyMediaGallery implements Action {
  readonly type = MediaCenterActionTypes.GET_MY_MEDIA_GALLERY;
  constructor(public mediaType: string) {}
}

export class UpdateMediaCenterLoadingProgress implements Action {
  readonly type = MediaCenterActionTypes.UPDATE_MEDIA_CENTER_LOADING_PROGRESS;
  constructor(public msg: IMediaCenter, public progress: number) {}
}

export class MediaCenterUploading implements Action {
  readonly type = MediaCenterActionTypes.MEDIA_UPLOADING;
  constructor(
    public localFile: File,
    public fileType: string,
    public uploadPrams: MediaCenterPrams
  ) {}
}

export class MediaCenterUploadingProcessed implements Action {
  readonly type = MediaCenterActionTypes.MEDIA_UPLOADING_PROCESSED;
  constructor(
    public payload: IMediaCenter,
    public uploadPrams: MediaCenterPrams
  ) {}
}

export class UpdateMediaCenterStatus implements Action {
  readonly type = MediaCenterActionTypes.UPDATE_MEDIA_CENTER_STATUS;
  constructor(
    public msg: IMediaCenter,
    public status: string,
    public url: string,
    public file: string
  ) {}
}

export class DeleteMediaCenterItem implements Action {
  readonly type = MediaCenterActionTypes.DELETE_MEDIA_CENTER_ITEM;
  constructor(public payload: string) {}
}

// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type MediaCenterActions =
  | MyMediaGalleryRcvd
  | GetMyMediaGallery
  | MediaCenterUploading
  | MediaCenterUploadingProcessed
  | UpdateMediaCenterLoadingProgress
  | UpdateMediaCenterStatus
  | DeleteMediaCenterItem
  | DeselectChannel
  | Reset;
