import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { AuthService } from 'src/app/core/auth.service';
import { BotMenu } from 'src/models/IBotMenu';

@Component({
  selector: 'app-startover',
  templateUrl: './startover.component.html',
  styleUrls: ['./startover.component.scss']
})
export class StartoverComponent implements OnInit {
  startOverPage: number;
  menuIdex: number;
  currentMenu: BotMenu;
  toBeEdited = true;

  constructor(
    private _ref: ChangeDetectorRef,
    public appManagementService: AppMgmtService,
    public authService: AuthService,
    private title: Title
  ) {}

  ngOnInit() {
    this.title.setTitle('The Native App Builder Templates');
  }

  cutomize() {
    this.authService.updateProgressWeb('0', 2);
    // this.authService.updateWebMode(2);
    // this.cutomizeMore = true;
  }

  setStartOverPage(page) {
    this.startOverPage = page;
  }

  edit(event) {
    this.toBeEdited = event;

    this._ref.detectChanges();
  }

  menuToEdit(event) {
    // if (!this.uploading) {
    //   this.toBeEdited = true;
    //   this.currentMenu = event;
    //   this._ref.detectChanges();
    // } else {
    //   // this._uiDispatchers.showPopup('Pleas wait till image Uploaded');
    // }
  }

  uploadImage(event) {
    // this.uploading = event;
    // this._ref.detectChanges();
  }
}
