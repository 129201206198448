/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emoji-keyboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./emoji-keyboard.component";
import * as i4 from "../emojibox.service";
var styles_EmojiKeyboardComponent = [i0.styles];
var RenderType_EmojiKeyboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmojiKeyboardComponent, data: {} });
export { RenderType_EmojiKeyboardComponent as RenderType_EmojiKeyboardComponent };
function View_EmojiKeyboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["appCancelDrag", ""], ["class", "clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emoijEvent(i1.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, [["emojiImage", 1]], null, 5, "img", [["class", "emoji-w26"], ["onresizestart", "false"], ["src", "./assets/img/blank.gif"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "emoji-spritesheet-0": 0, "emoji-spritesheet-1": 1, "emoji-spritesheet-2": 2, "emoji-spritesheet-3": 3, "emoji-spritesheet-4": 4 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "emoji-w26"; var currVal_2 = _ck(_v, 4, 0, (_v.context.$implicit[1] === 0), (_v.context.$implicit[1] === 1), (_v.context.$implicit[1] === 2), (_v.context.$implicit[1] === 3), (_v.context.$implicit[1] === 4)); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.bkGroundPos(_v.context.$implicit[3], _v.context.$implicit[4]); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit[5]; _ck(_v, 1, 0, currVal_0); }); }
export function View_EmojiKeyboardComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "keyboard"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmojiKeyboardComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emojiService.spritesheetPositions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EmojiKeyboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-emoji-keyboard", [], null, null, null, View_EmojiKeyboardComponent_0, RenderType_EmojiKeyboardComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmojiKeyboardComponent, [i4.EmojiboxService], null, null)], null, null); }
var EmojiKeyboardComponentNgFactory = i1.ɵccf("app-emoji-keyboard", i3.EmojiKeyboardComponent, View_EmojiKeyboardComponent_Host_0, {}, { emoijNotify: "emoijNotify" }, []);
export { EmojiKeyboardComponentNgFactory as EmojiKeyboardComponentNgFactory };
