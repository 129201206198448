<!-- Not Forget Password -->
<ng-container *ngIf="!isPasswordForgot">
  <!-- <div class="login-form">
    <div class="login-form-header">
      Login
    </div>

    <div class="login-form-title">
      Login to
      <app-link-btn (btnClicked)="logWithQr()">
        <ng-container ngProjectAs="link-content-clicked">
          <div class="manage">manage</div>
        </ng-container>
      </app-link-btn>
      your App
    </div>

    <form class="login-form-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            type="email"
            formControlName="email"
          />
          <app-icon-validation-errors
            *ngIf="email?.errors && email?.touched"
            matSuffix
            [fieldErrors]="email?.errors"
            [fieldName]="'Email'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          class="login-form-error"
          *ngIf="email?.errors && email?.touched"
          [fieldName]="'Email'"
          [fieldErrors]="email?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            type="password"
            formControlName="password"
          />
          <app-icon-validation-errors
            *ngIf="password?.errors && password?.touched"
            matSuffix
            [fieldErrors]="password?.errors"
            [fieldName]="'Password'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          class="login-form-error"
          *ngIf="password?.errors && password?.touched"
          [fieldName]="'Password'"
          [fieldErrors]="password?.errors"
        >
        </app-validation-errors>
      </div>

      <div
        class="form-inline space-between form-remember"
        style="display: flex;
      justify-content: flex-end;"
      >
         <app-reactive-checkbox-input [filedId]="'inputRem'" [control]="rem">
          Remember me
        </app-reactive-checkbox-input>
        <app-link-btn
          [isDefaultLink]="true"
          (btnClicked)="toggleForgotPasswordScreenVisibility(true)"
        >
          <ng-container ngProjectAs="link-content-clicked">
            Forgot your password?
          </ng-container>
        </app-link-btn>
      </div>

      <div class="form-submit">
        <app-border-btn publish invert lg [type]="'submit'">
          Login <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
      </div>
    </form>

    <div class="login-form-manage">
      New to nandbox?&nbsp;
      <app-link-btn [openSelf]="true" [href]="signupLink">
        <ng-container ngProjectAs="link-content">
          Sign Up
        </ng-container>
      </app-link-btn>
    </div>
    <div class="need">
      <app-link-btn (btnClicked)="showNeedHelp(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Need help to get started?
        </ng-container>
      </app-link-btn>
    </div>
    <div class="login-form-terms">
      By logging in, you agree to our
      <app-link-btn [href]="termsLink">
        <ng-container ngProjectAs="link-content">
          Terms of Use & Privacy Policy.
        </ng-container>
      </app-link-btn>
    </div>
  </div> -->
  <div class="login-form">
    <div class="login-form-header">
      <div class="title">App Owner Sign In</div>
      <div class="desc-container">
        <div class="desc" style="margin-right: 5px;">
          Sign in and create a
        </div>
        <div class="desc-native">native</div>
        <div class="desc" style="margin-left: 5px;">mobile app in minutes</div>
      </div>
      <!-- <div class="google" id="loginRef">
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-extra-icons.svg#icon-google"></use>
        </svg>
        Sign in with Google
      </div> -->
      <app-google-btn></app-google-btn>
      <div class="error" id="login-error">
        <!-- <ng-container *ngIf="authService?.errorNotify$ | async">
          This account does not exists. Please sign up.
        </ng-container> -->
      </div>
    </div>
    <div class="login-form-body">
      <div class="floting-text">Or sign in with email</div>
      <form class="login-form-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="faild">
          <!-- <mat-form-field>
            <input
              matInput
              placeholder="Email"
              type="email"
              formControlName="email"
            />
            <app-icon-validation-errors
              *ngIf="email?.errors && email?.touched"
              matSuffix
              [fieldErrors]="email?.errors"
              [fieldName]="'Email'"
            >
            </app-icon-validation-errors>
          </mat-form-field> -->
          <label for="email">Email</label>
          <input
            placeholder="email@example.com"
            type="email"
            formControlName="email"
            id="email"
          />
          <div style="min-height: 25px;">
            <app-validation-errors
              class="login-form-error"
              *ngIf="email?.errors && email?.touched"
              [fieldName]="'Email'"
              [fieldErrors]="email?.errors"
            >
            </app-validation-errors>
          </div>
        </div>

        <div class="faild">
          <!-- <mat-form-field>
            <input
              matInput
              placeholder="Password"
              type="password"
              formControlName="password"
            />
            <app-icon-validation-errors
              *ngIf="password?.errors && password?.touched"
              matSuffix
              [fieldErrors]="password?.errors"
              [fieldName]="'Password'"
            >
            </app-icon-validation-errors>
          </mat-form-field> -->
          <div class="form-inline space-between" style="margin-bottom: 0;">
            <label for="Password">
              Password
            </label>
          </div>
          <div style="position: relative;">
            <input
              placeholder="Enter password"
              type="password"
              formControlName="password"
              id="Password"
              #passwordInput
            />
            <img
              class="svg-icon icon-icon"
              (click)="showPassword()"
              *ngIf="passwordInput?.type === 'password'"
              style="fill: #012a42"
              alt="Show password"
              src="./assets/img/see-password.svg"
            />

            <img
              class="svg-icon icon-icon"
              (click)="hidePassword()"
              alt="Hide password"
              *ngIf="passwordInput?.type === 'text'"
              src="./assets/img/hide-password.svg"
            />

            <div class="forget-password">
              <div>
                <app-validation-errors
                  class="login-form-error"
                  *ngIf="password?.errors && password?.touched"
                  [fieldName]="'Password'"
                  [fieldErrors]="password?.errors"
                >
                </app-validation-errors>
              </div>
              <app-link-btn
                [isDefaultLink]="true"
                (btnClicked)="toggleForgotPasswordScreenVisibility(true)"
              >
                <ng-container ngProjectAs="link-content-clicked">
                  Forgot your password?
                </ng-container>
              </app-link-btn>
            </div>
          </div>
        </div>

        <!-- <div class="form-inline space-between form-remember">
          <app-reactive-checkbox-input [filedId]="'inputRem'" [control]="rem">
            Remember me
          </app-reactive-checkbox-input>
        </div> -->

        <!-- <div class="form-submit">
          <app-border-btn publish invert lg [type]="'submit'">
            Login <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
          </app-border-btn>
        </div> -->
        <button type="submit" class="submit-btn" [disabled]="waiting">
          <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i> SIGN IN
        </button>
      </form>
    </div>
    <div class="login-form-manage">
      Don't have an account?&nbsp;
      <app-link-btn
        [openSelf]="true"
        [href]="signupLink"
        [isDefaultLink]="true"
      >
        <ng-container ngProjectAs="link-content">
          Sign up
        </ng-container>
      </app-link-btn>
    </div>
    <div class="action-header">
      <div class="text">Are you an admin?</div>
      <div class="signin-btn" (click)="logWithQr()">
        <div class="icon">
          <img
            alt="nandbox QR login"
            class="svg-icon icon-icon"
            src="./assets/img/go-to-qr-code.svg"
          />
        </div>
        Sign in as an admin
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isPasswordForgot">
  <app-forgot-password
    (goBackNotify)="toggleForgotPasswordScreenVisibility(false)"
  >
  </app-forgot-password>
</ng-container>
