import * as actions from './tags.actions';
import { Actions } from './tags.actions';

export function tagsReducer(state: any[] = [], action: Actions): any[] {
  switch (action.type) {
    case actions.TagsUIActionTypes.UI_RECEIVED_TAGS:
      const chatTags = state.find(
        chatTags => chatTags.id === action.payload.id
      );

      return chatTags
        ? state.map(chatTags =>
            chatTags.id === action.payload.id
              ? { ...chatTags, ...action.payload }
              : chatTags
          )
        : [...state, action.payload];

    default:
      return state;
  }
}
