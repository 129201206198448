import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiService } from 'src/app/core/ui.service';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { subscriptionExpired } from 'src/models/constants';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  timer = null;
  @Input() endDate: string;
  public _hideCountDown = new BehaviorSubject<boolean>(false);
  public hideCountDown$ = this._hideCountDown.asObservable();
  constructor(
    public uiService: UiService,
    private _uiDispatchers: UIDispatchers
  ) {}

  ngOnInit() {
    this.countDownStart();
  }

  countDownStart() {
    const countDownDate = new Date(this.endDate).getTime();
    const expired = subscriptionExpired;
    this.timer = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      let countdownDays = document.getElementById('days');
      let countdownHours = document.getElementById('hours');
      let countdownMinutes = document.getElementById('minutes');

      countdownDays.innerHTML = days < 10 ? '0' + days : days.toString();
      countdownHours.innerHTML = hours < 10 ? '0' + hours : hours.toString();
      countdownMinutes.innerHTML =
        minutes < 10 ? '0' + minutes : minutes.toString();
      // If the count down is finished, write some text
      if (distance < 0) {
        this._uiDispatchers.toggleExpiredPopup(expired);
        clearInterval(this.timer);
        this._hideCountDown.next(true);
        countdownDays.innerHTML = '00';
        countdownHours.innerHTML = '00';
        countdownMinutes.innerHTML = '00';
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
