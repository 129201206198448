import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
// import { PopStateEvent } from '@angular/common';
@Component({
  selector: 'app-startover-temps',
  templateUrl: './startover-temps.component.html',
  styleUrls: ['./startover-temps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverTempsComponent implements OnChanges, OnInit {
  @ViewChild('tempsContainer', { static: false }) tempsContainer: ElementRef;
  @Input() category: any;
  @Input() tempsList: any[];
  @Input() loading: boolean;
  seeMore = false;

  @Output() setTempNotify = new EventEmitter<any>();
  @Output() tempView = new EventEmitter<any>();
  constructor(
    public authService: AuthService,
    private _ref: ChangeDetectorRef,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnInit() {
    if (this.tempsContainer) {
      this.tempsContainer.nativeElement.focus();
    }
  }

  ngOnChanges() {
    if (this.tempsContainer) {
      this.tempsContainer.nativeElement.focus();
    }
  }
  More(value) {
    this.seeMore = value;
  }
  setTemp(event) {
    this.setTempNotify.emit(event);
  }

  reviewTemp(event) {
    const queryParams: Params = { id: event.id };
    this._router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge' // remove to replace all query params by provided
    });

    window.scrollTo(0, 0);

    this.tempView.emit(event);
  }
}
