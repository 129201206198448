import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { AppState } from 'src/models/AppState';

import * as tagsUIAction from './tags.actions';
import { TagsUIActionTypes } from './tags.actions';

import * as isEqual from 'lodash.isequal';
import { ChatDispatchers } from '../chats/chat.dispatchers';

import * as ChatMethods from 'src/models/IChat';

@Injectable()
export class TagsEffects {
  @Effect({ dispatch: false })
  setMainChatTags = this.actions$.pipe(
    ofType(TagsUIActionTypes.UI_RECEIVED_TAGS),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <tagsUIAction.TagsReceived>val;
      // const chat: ChatMethods.ReceiveChat = {...action.payload};
      if (selectedChat.id === action.id) {
        this._chatDispatchers.tagsReceived(action.payload);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _chatDispatchers: ChatDispatchers
  ) {}
}
