import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapStopTimeActions from './mapStopTime.actions';
import { AppState } from 'src/models/AppState';
import { Stops } from 'src/models/MapStopTime';

@Injectable({
  providedIn: 'root'
})
export class MapStopTimeDispatchers {
  constructor(private _store: Store<AppState>) {}

  createMapStopTime(MapStopTime: Stops) {
    this._store.dispatch(new MapStopTimeActions.CreateMapStopTime(MapStopTime));
  }

  setMapStopTimes(tripID: string, MapStopTimes: Stops[]) {
    this._store.dispatch(
      new MapStopTimeActions.SetMapStopTimes(tripID, MapStopTimes)
    );
  }

  createMapStopTimeReceived(MapStopTime: Stops) {
    this._store.dispatch(
      new MapStopTimeActions.CreateMapStopTimeReceived(MapStopTime)
    );
  }

  MapStopTimesListReceived(MapStopTimes: Stops[]) {
    this._store.dispatch(
      new MapStopTimeActions.MapStopTimesListReceived(MapStopTimes)
    );
  }

  getMapStopTime(trip_id: string) {
    this._store.dispatch(new MapStopTimeActions.GetMapStopTime(trip_id));
  }
  rest() {
    this._store.dispatch(new MapStopTimeActions.Rest());
  }
}
