import { Action } from '@ngrx/store';
import {
  RcvdMyMenusMessage,
  BotMenu,
  RcvdMyMenuMessage
} from 'src/models/IBotMenu';
import {
  CREATE_BOT_MENU_REF,
  UPDATE_BOT_MENU_REF,
  DELETE_BOT_MENU_REF
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ImageSet } from 'src/models/ChannelAppConfig';

export const enum MobileWorkFlowStoreActionTypes {
  MOBILE_WORKFLOW_STORE_LIST = 'MOBILE_WORKFLOW_STORE_LIST',
  MOBILE_WORKFLOW_STORE_RECEIVING = 'MOBILE_WORKFLOW_STORE_RECEIVING',
  MOBILE_WORKFLOW_STORES_RECEIVING = 'MOBILE_WORKFLOW_STORES_RECEIVING',
  MOBILE_WORKFLOW_STORE_RECEIVED = 'UI_MENU_MENU_RECEIVED',
  CREATE_MOBILE_WORKFLOW_STORE = 'CREATE_MOBILE_WORKFLOW_STORE',
  UPDATE_MOBILE_WORKFLOW_STORE = 'UPDATE_MOBILE_WORKFLOW_STORE',
  DELETE_MOBILE_WORKFLOW_STORE = 'DELETE_MOBILE_WORKFLOW_STORE',
  MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE = 'MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE',
  MOBILE_WORKFLOW_STORE_GET_PREV_PAGE = 'MOBILE_WORKFLOW_STORE_GET_PREV_PAGE',
  MOBILE_WORKFLOW_STORE_RESET_PAGING = 'MOBILE_WORKFLOW_STORE_RESET_PAGING',
  MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS = 'MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS',
  GET_MOBILE_WORKFLOW_STORE = 'GET_MOBILE_WORKFLOW_STORE',
  UPLOAD_MOBILE_WORKFLOW_STORE_IMG = 'UPLOAD_MOBILE_WORKFLOW_STORE_IMG',
  UPLOAD_MOBILE_WORKFLOW_STORE_IMG_SUCCESS = 'UPLOAD_MOBILE_WORKFLOW_STORE_IMG_SUCCESS'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class ListMenus implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_LIST;
  constructor() {}
}

export class GetMobileStore implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.GET_MOBILE_WORKFLOW_STORE;
  constructor(public id?: any) {}
}

export class MenusReceiving implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_RECEIVING;
  constructor(public payload: any) {}
}

export class MenuReceiveing implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORES_RECEIVING;
  constructor(public action: any) {}
}

export class MenuReceived implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_RECEIVED;
  constructor(public action: any) {}
}

export class CreateMenu implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.CREATE_MOBILE_WORKFLOW_STORE;
  constructor(
    public menu_id: string,
    public currency?: string,
    public shop?: any,
    public image?: any,
    public name?: string,
    public description?: string,
    public is_default?: number,
    public id?: string
  ) {}
}

export class UpdateMenu implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.UPDATE_MOBILE_WORKFLOW_STORE;
  constructor(
    public menu_id: string,
    public currency?: string,
    public shop?: any,
    public is_default?: number,
    public id?: string
  ) {}
}

export class DeleteMenu implements Action {
  readonly type = MobileWorkFlowStoreActionTypes.DELETE_MOBILE_WORKFLOW_STORE;
  constructor(public id: any) {}
}

export class GetNextMenuPage implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE;
}

export class GetPrevMenuPage implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_GET_PREV_PAGE;
}

export class ResetMenuPaging implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_RESET_PAGING;
}

export class SetStatusAsIdle implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS;
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}

export class UploadMenuImg implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.UPLOAD_MOBILE_WORKFLOW_STORE_IMG;
  constructor(public componentRef: any, public selectedFile: ISelectedFiles) {}
}
export class UploadMenuImgSuccess implements Action {
  readonly type =
    MobileWorkFlowStoreActionTypes.UPLOAD_MOBILE_WORKFLOW_STORE_IMG_SUCCESS;
  constructor(
    public componentRef: string,
    public imageUrl: string,
    public imageSet: ImageSet
  ) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type Actions =
  | ListMenus
  | MenusReceiving
  | MenuReceiveing
  | MenuReceived
  | CreateMenu
  | UpdateMenu
  | DeleteMenu
  | DeselectChannel
  | GetNextMenuPage
  | GetPrevMenuPage
  | ResetMenuPaging
  | SetStatusAsIdle
  | Reset;
