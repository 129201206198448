import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { ServiceOrdersUIActionTypes } from './serviceOrdersUI.actions';
import * as ServiceOrdersActions from './serviceOrdersUI.actions';
import { ServiceOrdersUiDispatchers } from './serviceOrdersUI.dispatchers';
import { GetServiceOrdersMethod } from 'src/models/PaymentGateways';
import { PageDirection } from 'src/models/constants';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class ServiceOrdersUiEffects {
  @Effect({ dispatch: false })
  getNextServiceOrdersPage = this.actions$.pipe(
    ofType(ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.serviceOrdersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, serviceOrdersUiState]) => {
      const action = <ServiceOrdersActions.GetNextServiceOrdersPage>val;
      const prevRequest = serviceOrdersUiState.previousRequest;
      if (
        !(
          serviceOrdersUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          serviceOrdersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetServiceOrdersMethod(
            0,
            serviceOrdersUiState.eop,
            action.paymentOrderID,
            action.serviceOrderID
          )
        );
      } else {
        this._uiServiceOrdersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevServiceOrdersPage = this.actions$.pipe(
    ofType(ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.serviceOrdersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, serviceOrdersUiState]) => {
      const action = <ServiceOrdersActions.GetPrevServiceOrdersPage>val;
      const prevRequest = serviceOrdersUiState.previousRequest;
      if (
        !(
          serviceOrdersUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          serviceOrdersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetServiceOrdersMethod(
            1,
            serviceOrdersUiState.sop,
            action.paymentOrderID,
            action.serviceOrderID
          )
        );
      } else {
        this._uiServiceOrdersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterServiceOrdersResetPaging = this.actions$.pipe(
    ofType(ServiceOrdersUIActionTypes.UI_SERVICE_ORDERS_RESET_PAGING),
    map((action: ServiceOrdersActions.ResetServiceOrdersPaging) => {
      this._uiServiceOrdersDispatchers.getNextServiceOrdersPage(
        action.paymentOrderID,
        action.serviceOrderID
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiServiceOrdersDispatchers: ServiceOrdersUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
