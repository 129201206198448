import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  withLatestFrom
} from 'rxjs/operators';

import { UiService } from './ui.service';
import { HelperService } from './helper.service';
import { RootStoreSelectors } from '../store/root-store.selectors';

import { MainChat } from 'src/models/MainChat';
import { IMessageNotify } from 'src/models/IMessageNotify';
import {
  CHATS,
  CHANNEL,
  CHANNELS,
  INDIVIDUAL,
  MessageTypes,
  GROUP
} from 'src/models/constants';

const arr = [];

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private w: any = window;
  private currentClickedNotify = null;
  private currentNotify = null;
  // private audio = new Audio('./assets/audio/isnt-it.mp3');

  private _notifyObservable: Subject<IMessageNotify> = new Subject();
  public notifyObservable$ = this._notifyObservable.asObservable();

  constructor(
    private _platformLocation: PlatformLocation,
    private _rootSelector: RootStoreSelectors,
    private _uiService: UiService,
    private _helperService: HelperService
  ) {
    // listent to notfiy service and notfiy if data changed
    this.notifyObservable$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        withLatestFrom(this._rootSelector.selectedList$)
      )
      .subscribe(res => {
        if (
          this.w.Notification &&
          this.w.Notification.permission === 'granted' &&
          (!document.hasFocus() ||
            !res[0].isSelected ||
            ((CHATS === res[1] && CHANNEL === res[0].targetChat.type) ||
              (CHANNELS === res[1] &&
                (INDIVIDUAL === res[0].targetChat.type ||
                  GROUP === res[0].targetChat.type))))
        ) {
          if (arr[res[0].senderId]) {
            const notify = arr[res[0].senderId];
            notify.close();
          }
          const notification: Notification = this.spawnNotification(
            res[0].body,
            res[0].icon,
            res[0].title,
            res[0].senderId,
            res[0].image,
            res[0].group_type
          );
          notification.addEventListener(
            'click',
            () => {
              this._uiService.selectChat(res[0].targetChat);
              notification.close();
            },
            false
          );
          notification.onclick = e => {
            window.focus();
            this._uiService.blink('notification', 0);
            if (res[0].group_type === 101) {
              this._uiService.selectTab(CHANNELS);
            } else {
              this._uiService.selectTab(CHATS);
            }
          };
        }
      });
  }

  spawnNotification(body, icon, title, senderId, image, group_type) {
    const options = {
      body,
      icon
    };

    const notify = new Notification(title, options);
    arr[senderId] = notify;
    return notify;
  }

  checkNotfiy(): boolean {
    return this.w.Notification && this.w.Notification.permission === 'default';
  }

  setNotfiy() {
    if (this.checkNotfiy) {
      this.w.Notification.requestPermission((status: any) => {
        if (this.w.Notification.permission !== status) {
          this.w.Notification.permission = status;
        }
      });
    }
  }

  showNotfiy(options: IMessageNotify) {
    // If the user agreed to get notified
    this.setNotfiy();
    // Let's try to send the notifications
    const notification = this._notifyObservable.next(options);
    return notification;
  }

  showNotification(targetChat, action) {
    const date = new Date(action.payload.date);
    const messageNotfiy: IMessageNotify = {};
    const typ = action.payload.type;
    const options = {
      hour: 'numeric',
      minute: 'numeric'
    };
    const time = date.toLocaleTimeString('en-US', options);
    let file = '';
    let image = '';
    let audio = '';
    let video = '';
    let location = '';
    let contact = '';
    const createdTargetChat: MainChat = {};
    if (this.getOperatingSystemVer() > 8) {
      file = '🗎';
      image = '📷';
      audio = '🎧';
      video = '🎥';
      location = '📍';
      contact = '👤';
    }
    if (!action.payload.from_admin && action.payload.group_name) {
      messageNotfiy.body = 'Message From' + ' ' + action.payload.sender_name;
    } else if (typ === MessageTypes.TEXT) {
      messageNotfiy.body = action.payload.text + '   ' + time;
    } else if (typ === MessageTypes.PHOTO) {
      messageNotfiy.body = image + 'Photo' + '   ' + time;
    } else if (typ === MessageTypes.AUDIO) {
      const dutation = this._helperService.convertMilliSecsToReadableTime(
        action.payload.media_duration
      );
      messageNotfiy.body =
        audio + 'Audio:' + '(' + dutation + ')' + '   ' + time;
    } else if (typ === MessageTypes.DOCUMENT) {
      messageNotfiy.body = file + 'File' + '   ' + time;
    } else if (typ === MessageTypes.LOCATION) {
      messageNotfiy.body = location + 'Location' + '   ' + time;
    } else if (typ === MessageTypes.VIDEO) {
      const dutation = this._helperService.convertMilliSecsToReadableTime(
        action.payload.media_duration
      );
      messageNotfiy.body =
        video + 'Video:' + '(' + dutation + ')' + '   ' + time;
    } else if (typ === MessageTypes.CONTACT) {
      messageNotfiy.body = contact + action.payload.contact_name + '   ' + time;
    } else if (typ === MessageTypes.VOICE) {
      const dutation = this._helperService.convertMilliSecsToReadableTime(
        action.payload.media_duration
      );
      messageNotfiy.body =
        audio + 'Voice note:' + '(' + dutation + ')' + '   ' + time;
    } else if (typ === MessageTypes.STICKER) {
      messageNotfiy.body = 'Sticker' + '   ' + time;
    } else if (typ === MessageTypes.GIF) {
      messageNotfiy.body = 'GIF' + '   ' + time;
    }
    messageNotfiy.senderId = action.payload.sender_id;
    messageNotfiy.targetChat = targetChat;
    messageNotfiy.group_type = action.payload.group_type;
    if (targetChat) {
      if (targetChat.selected) {
        messageNotfiy.isSelected = targetChat.selected;
      } else {
        messageNotfiy.isSelected = false;
      }
      messageNotfiy.title = targetChat.name;
      if (targetChat.imageThumbnail) {
        messageNotfiy.icon = targetChat.imageThumbnail;
      } else if (action.payload.group_type === 100) {
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/group-defult.png';
      } else if (action.payload.group_type === 101) {
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/channal-defult.png';
      } else {
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/blank-profile.png';
      }
    } else {
      createdTargetChat.lastTypedMessage = '';
      createdTargetChat.selected = false;
      createdTargetChat.id = action.payload.sender_id;
      createdTargetChat.name = action.payload.sender_name;
      messageNotfiy.targetChat = createdTargetChat;
      messageNotfiy.isSelected = false;
      if (action.payload.group_type === 100) {
        messageNotfiy.title = action.payload.group_name;
        createdTargetChat.id = action.payload.group_id;
        createdTargetChat.name = action.payload.group_name;
        createdTargetChat.type = 'Group';
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/group-defult.png';
      } else if (action.payload.group_type === 101) {
        messageNotfiy.title = action.payload.group_name;
        createdTargetChat.id = action.payload.group_id;
        createdTargetChat.name = action.payload.group_name;
        createdTargetChat.type = 'Channel';
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/channal-defult.png';
      } else {
        messageNotfiy.title = action.payload.sender_name;
        createdTargetChat.id = action.payload.sender_id;
        createdTargetChat.name = action.payload.sender_name;
        createdTargetChat.type = 'Individual';
        messageNotfiy.icon =
          (this._platformLocation as any).location.href +
          'assets/img/blank-profile.png';
      }
      messageNotfiy.targetChat = createdTargetChat;
      // messageNotfiy.icon = '';
    }

    // const notifyCount = this.dashboardService.notifyCount + 1;
    this._uiService.blink(' notification', 5);
    this.showNotfiy(messageNotfiy);
  }
  getOperatingSystemVer() {
    let osName = null;
    if (window.navigator.userAgent.indexOf('Mac') !== -1) {
      osName = 13;
    } else if (window.navigator.userAgent.indexOf('X11') !== -1) {
      osName = 12;
    } else if (window.navigator.userAgent.indexOf('Linux') !== -1) {
      osName = 11;
    } else if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
      osName = 10;
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
      osName = 9;
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
      osName = 8;
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
      osName = 7;
    } else if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
      osName = 6;
    } else if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
      osName = 5;
    } else {
      osName = 14;
    }
    return osName;
  }
  playSound() {
    // this.audio.play();
  }
}
