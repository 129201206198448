import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { FileService } from 'src/app/core/file.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { SetChat, GetSubChats } from 'src/models/IChat';
import { ERROR_NOT_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes, NOT_SUPPORTED_FILE_TYPE, NO_FILE_NAME, CHANNEL, PageDirection, UPDATE_CHANNEL_REF } from 'src/models/constants';
import { SubChannelUiDispatchers } from './subChannelsUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { SubChatsService } from 'src/app/+merchant/+dashboard/sub-chats/sub-chats.service';
export class SubChannelUiEffects {
    constructor(actions$, _chatDispatchers, _socketGateway, _uiDispatchers, _subChannelUiDispatchers, _fileService, _uploadGateway, subChatsService, _store) {
        this.actions$ = actions$;
        this._chatDispatchers = _chatDispatchers;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this._subChannelUiDispatchers = _subChannelUiDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this.subChatsService = subChatsService;
        this._store = _store;
        this.uploadSubChannelImage = this.actions$.pipe(ofType("UPLOAD_SUB_CHANNEL_IMAGE" /* UPLOAD_SUB_CHANNEL_IMAGE */), withLatestFrom(this._store
            .select(state => state.subChannelUiReducer.creationProcessSubChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.subChannelUiReducer.selectedSubGroup)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.enableSetTemplateBtn)
            .pipe(distinctUntilChanged(isEqual))), map(([val, creationSubChannel, updatedSubChannel, enableSetTemplateBtn]) => {
            const action = val;
            if (this.validateFile(action.selectedFile.file, 'image')) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.file.localFile)
                    .then(fileAsArrayBuffer => {
                    let groupId = '';
                    if (!enableSetTemplateBtn) {
                        groupId = creationSubChannel
                            ? creationSubChannel.id
                            : updatedSubChannel && updatedSubChannel.id
                                ? updatedSubChannel.id
                                : action.selectedFile.chat.id;
                    }
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.file.localFile.type, action.selectedFile.file.localFile.name, false, null, null, groupId, true, false, '', false, false, 0, '1x1')
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            let chat = {};
                            const photo = { id: res.file };
                            chat = Object.assign({}, chat, action.selectedFile.chat);
                            chat.id = creationSubChannel
                                ? creationSubChannel.id
                                : updatedSubChannel && updatedSubChannel.id
                                    ? updatedSubChannel.id
                                    : action.selectedFile.chat.id;
                            chat.photo = photo;
                            // this.subChatsService._currentPhotoUploaded.next(res);
                            this.subChatsService._wattingForUploadImage.next(false);
                            this._socketGateway.sendSocketMessage(new SetChat(chat, UPDATE_CHANNEL_REF));
                            this.subChatsService.showEditSubGroupScreen(chat);
                            // if (enableSetTemplateBtn) {
                            //   const id = chat.id;
                            //   const type = chat.type.toLocaleLowerCase();
                            //   const tempChat = { ...chat };
                            //   delete tempChat['id'];
                            //   this._uiDispatchers.setChatToTemplate(id, type, tempChat);
                            // }
                        }
                    }, error => {
                        console.log('Failed to upload subChannel image');
                    });
                });
            }
        }));
        this.afterSubChannelCreation = this.actions$.pipe(ofType("UI_UPLOAD_SUB_CHANNEL_PHOTO" /* UI_UPLOAD_SUB_CHANNEL_PHOTO */), map((action) => {
            this._chatDispatchers.chatInvalidated(action.createdSubChannel.id);
            this._chatDispatchers.getChatAdministrators(action.createdSubChannel.id);
        }));
        this.getNextSubChannelPage = this.actions$.pipe(ofType("UI_SUB_CHANNEL_GET_NEXT_PAGE" /* UI_SUB_CHANNEL_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.subChannelUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, subChannelUiState]) => {
            const action = val;
            const prevRequest = subChannelUiState.previousRequest;
            if (!(subChannelUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                subChannelUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubChats([CHANNEL], 0, subChannelUiState.hash, subChannelUiState.eop));
            }
            else {
                this._subChannelUiDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevSubChannelPage = this.actions$.pipe(ofType("UI_SUB_CHANNEL_GET_PREV_PAGE" /* UI_SUB_CHANNEL_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.subChannelUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, subChannelUiState]) => {
            const action = val;
            const prevRequest = subChannelUiState.previousRequest;
            if (!(subChannelUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                subChannelUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetSubChats([CHANNEL], 1, subChannelUiState.hash, subChannelUiState.sop));
            }
            else {
                this._subChannelUiDispatchers.setStatusAsIdle();
            }
        }));
        this.afterSubChannelSectionSelection = this.actions$.pipe(ofType("UI_LIST_SUB_CHANNEL" /* UI_LIST_SUB_CHANNEL */), map((action) => {
            this._subChannelUiDispatchers.resetSubChannelPaging();
        }));
        this.afterSubChatResetPaging = this.actions$.pipe(ofType("UI_SUB_CHANNEL_RESET_PAGING" /* UI_SUB_CHANNEL_RESET_PAGING */), map((action) => {
            this._subChannelUiDispatchers.getNextSubChannelPage();
        }));
        this.setChannelChat = this.actions$.pipe(ofType("UI_SET_CHANNEL_CHAT" /* UI_SET_CHANNEL_CHAT */), withLatestFrom(this._store
            .select(state => state.subChannelUiReducer.file)
            .pipe(distinctUntilChanged(isEqual))), map(([val, file]) => {
            if (file && file !== null) {
                const action = val;
                const chat = Object.assign({}, file.chat, { id: action.data.chat_id });
                const evntObject = Object.assign({}, file);
                evntObject.chat = chat;
                this._subChannelUiDispatchers.uploadSubChannelImage(evntObject);
            }
            // this._socketGateway.sendSocketMessage(
            //   new SetChat(chat, UPDATE_CHANNEL_REF)
            // );
        }));
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "uploadSubChannelImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "afterSubChannelCreation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "getNextSubChannelPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "getPrevSubChannelPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "afterSubChannelSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "afterSubChatResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubChannelUiEffects.prototype, "setChannelChat", void 0);
