<div
  class="plan"
  *ngIf="
    (plan?.id === 'plan_4' && paymentPlan && paymentPlan === 'plan_4') ||
    (plan?.id !== 'plan_4' && paymentPlan)
  "
>
  <div class="plan-header" [style.background]="plan?.mainColor">
    <div class="plan-title">
      {{ plan?.title }}
    </div>
    <div class="plan-tagline">
      {{ plan?.tagline }}
    </div>
    <div *ngIf="plan?.isPopular" class="plan-popular">
      Popular
    </div>
  </div>

  <ng-container *ngIf="!plan?.isScalable">
    <ul class="plan-points">
      <li
        *ngFor="let option of plan?.options; let first = first"
        [style.color]="first ? plan?.mainColor : 'inherit'"
      >
        {{ option }}
      </li>
    </ul>
    <div class="plan-brief" [style.background]="plan?.secondColor">
      <svg class="svg-icon icon-icon" [style.fill]="plan?.mainColor">
        <use xlink:href="./assets/svg/app-sections.svg#icon-check"></use>
      </svg>
      <div class="brief-title" [style.color]="plan?.mainColor">
        Included in this plan
      </div>
      <div class="brief-points">
        <ul>
          <li *ngFor="let brifItem of brief">{{ brifItem }}</li>
        </ul>
        <!-- {{ plan?.brief }} -->
      </div>
    </div>
    <div class="plan-action">
      <div class="action-details">
        See full plan details
        <app-link-btn [href]="plan?.url">
          <ng-container ngProjectAs="link-content">
            here
          </ng-container>
        </app-link-btn>
      </div>

      <ng-container *ngIf="paymentPlan === plan?.id">
        <app-border-btn [disabled]="true" [style.color]="plan?.mainColor">
          Current Plan
        </app-border-btn>
        <!-- <div class="plan-cancel clickable-cursor" (click)="cancelPlanEvent()">
          Cancel Plan
        </div> -->
        <div
          class="plan-cancel clickable-cursor"
          *ngIf="paymentPlan !== 'plan_4'"
          (click)="editPlanEvent()"
        >
          Update Billing Information
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          (paymentPlan === 'plan_4' && plan?.id !== 'plan_4') || !paymentPlan
        "
      >
        <app-border-btn
          (btnClicked)="planEvent()"
          [style.color]="plan?.mainColor"
          [disabled]="
            plan?.id === 'plan_4' && paymentPlan && paymentPlan !== 'plan_4'
          "
        >
          Start Free Trial
        </app-border-btn>
      </ng-container>

      <ng-container
        *ngIf="
          (paymentPlan !== 'plan_4' &&
            paymentPlan !== plan?.id &&
            plan?.id !== 'plan_4') ||
          !paymentPlan
        "
      >
        <app-border-btn
          (btnClicked)="planEvent()"
          [style.color]="plan?.mainColor"
          [disabled]="
            plan?.id === 'plan_4' && paymentPlan && paymentPlan !== 'plan_4'
          "
        >
          Change Plan
        </app-border-btn>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="plan?.isScalable">
    <div class="plan-contact">
      <img
        class="contact-icon"
        src="./assets/img/pay-as-you-growth.svg"
        alt=""
      />
      <div class="contact-details">
        In this plan, you should communicate with the management.
      </div>
      <ng-container *ngIf="paymentPlan === plan?.id">
        <app-border-btn [disabled]="true" [style.color]="plan?.mainColor">
          Current Plan
        </app-border-btn>
        <!-- <div class="plan-cancel clickable-cursor" (click)="cancelPlanEvent()">
          Cancel Plan
        </div> -->
        <div
          class="plan-cancel clickable-cursor"
          *ngIf="paymentPlan !== 'plan_4'"
          (click)="editPlanEvent()"
        >
          Update Billing Information
        </div>
      </ng-container>

      <ng-container *ngIf="paymentPlan !== plan?.id || !paymentPlan">
        <app-border-btn
          (btnClicked)="planEvent()"
          [style.color]="plan?.mainColor"
        >
          Select
        </app-border-btn>
      </ng-container>
    </div>
  </ng-container>
</div>
