<div class="temps">
  <!-- <div class="temps-title">
    Your goal is
  </div> -->

  <div class="container">
    <div class="list-container">
      <div class="info">
        <h1 class="category">
          Start your app with a template
        </h1>
        <div class="desc">
          Please choose a similar app, this will allow us to understand your
          idea better.
        </div>
      </div>
      <div class="temps-container" #tempsContainer>
        <ng-container *ngFor="let temp of tempsList; let index = index">
          <app-startover-temp-item
            *ngIf="temp?.title !== 'General'"
            [temp]="temp"
            [index]="index"
            [seeMore]="seeMore"
            (setTempReview)="reviewTemp($event)"
            (setTempNotify)="setTemp($event)"
          ></app-startover-temp-item>
        </ng-container>
      </div>
      <div class="loader-container" *ngIf="loading">
        <div class="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <!-- <div
        class="see-more"
        (click)="More(true)"
        *ngIf="tempsList?.length > 4 && !seeMore"
      >
        See More ...
      </div>
      <div
        class="see-more"
        (click)="More(false)"
        *ngIf="tempsList?.length > 4 && seeMore"
      >
        Show Less ...
      </div> -->

    <!-- <div class="temps-container-general">

      <ng-container *ngFor="let temp of tempsList; let index = index">
        <app-startover-temp-item
          *ngIf="temp?.title === 'General'"
          [temp]="temp"
          [index]="index"
          [seeMore]="seeMore"
          (setTempNotify)="setTemp($event)"
        ></app-startover-temp-item>
      </ng-container>
    </div> -->
  </div>
</div>
