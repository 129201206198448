import { Component, OnInit } from '@angular/core';

import { AuthService } from '../core/auth.service';
import { WidgetService } from '../core/widget.service';
import { AnalyticsService } from '../core/analytics.service';
import { SocketHandlerService } from '../network/services/socket-merchant.service';

// import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UIDispatchers } from './../store/ui/ui.dispatchers';
import { UISections } from 'src/models/constants';
import { ClarityService } from '../core/clarity.service';
declare var $: any;
@Component({
  selector: 'app-app',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  /**
   * Required to inject SocketHandlerService not used but the constructor of SocketHandlerService
   * will do connection logic **Don't remove this import**
   */

  UISections = UISections;
  authSubscribtion = null;

  constructor(
    private _socketHandlerService: SocketHandlerService,
    private _authService: AuthService,
    private _analyticsService: AnalyticsService,
    private _widget: WidgetService,
    private clarity: ClarityService,
    public translate: TranslateService,
    public _uiDispatchers: UIDispatchers
  ) {
    const hostName = window.location.hostname;
    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('locale')) {
      this.translate.use(localStorage.getItem('locale'));
    } else {
      this.translate.use('en');
    }

    this._authService.getCountryISO();
    // if (environment.production) {
    //   this._analyticsService.init();
    // }
  }
  ngOnInit() {
    this._authService.googleAuthSDK();
    $(document).ready(() => {
      const keyCodes = [61, 107, 173, 109, 187, 189];
      const keyCodes2 = [9, 76];
      $(document).mouseenter(event => {
        $(document).unbind('wheel');
      });

      $(document).keydown(event => {
        if (event.metaKey == true) {
          $(document).bind('wheel', () => {
            return false;
          });
        }
        if (event.metaKey == true && keyCodes.indexOf(event.which) != -1) {
          event.preventDefault();
        }
        if (event.metaKey == true && keyCodes.indexOf(event.which) == -1) {
          $(document).unbind('wheel');
        }
      });

      $(document).keydown(event => {
        if (event.ctrlKey == true) {
          $(document).bind('wheel', () => {
            return false;
          });
        }
        if (event.ctrlKey == true && keyCodes.indexOf(event.which) != -1) {
          event.preventDefault();
        }
        if (event.ctrlKey == true && keyCodes2.indexOf(event.which) != -1) {
          $(document).unbind('wheel');
        }
      });

      $(document).keyup(event => {
        $(document).unbind('wheel');
      });
    });
  }
}
