import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';

import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { EventUiDispatchers } from 'src/app/store/eventsUI/eventUI.dispatchers';

import { IChat } from 'src/models/IChat';
import { EventUI } from 'src/models/EventUI';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { EVENT_CHANNEL, UPDATE_EVENT_REF } from 'src/models/constants';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class EventsService {
  confoirmDeleteWindow = false;
  selectedEvant: IChat;

  public _currentPhotoUploaded = new BehaviorSubject<any>({});
  public currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();

  public _cerateNew = new BehaviorSubject<boolean>(false);
  public cerateNew$ = this._cerateNew.asObservable();

  public _uploadingImgIds = new BehaviorSubject<string[]>([]);
  public uploadingImgIds$ = this._uploadingImgIds.asObservable();

  public _wattingForUploadImage = new BehaviorSubject<boolean>(false);
  public wattingForUploadImage$ = this._wattingForUploadImage.asObservable();

  uploadingImgIds: string[];
  constructor(
    private _selectors: RootStoreSelectors,
    private _chatDispatchers: ChatDispatchers,
    private _eventUiDispatchers: EventUiDispatchers,
    public router: Router
  ) {}

  createEvent(event: IChat, secandEvent): void {
    this._chatDispatchers.createEvent(event, secandEvent);
  }

  resetPaging() {
    this._eventUiDispatchers.resetEventPaging();
  }

  viewEvent(event: IChat): void {
    this.getChatAdministrators(event.id);
    this._eventUiDispatchers.viewEventUI(event);
  }

  closeViewEvent(): void {
    this._eventUiDispatchers.closeViewEventUI();
  }

  showEditEventScreen(event: IChat): void {
    this._wattingForUploadImage.next(false);
    const photo = {
      file: null,
      url: event.localThumbnail
    };
    this._currentPhotoUploaded.next(photo);
    this.getChatAdministrators(event.id);

    this._eventUiDispatchers.editEventUI(event);
  }

  showCreateEventScreen() {
    this._wattingForUploadImage.next(false);
    this._eventUiDispatchers.createEventUI();
    // this.getGeneratedChatID(EVENT_CHANNEL);
    this.router.navigate(['/events/form']);
  }

  closeEditEventScreen(): void {
    this._eventUiDispatchers.closeEventEditUI();
  }

  uploadEventImage(file): void {
    this._eventUiDispatchers.uploadEventImage(file);
  }

  eventCreationComplete(): void {
    this._eventUiDispatchers.eventCreationComplete();
  }

  updateEvent(chatID: string, updatedEventProps: IChat) {
    updatedEventProps.id = chatID;
    this._chatDispatchers.updateChat(updatedEventProps, UPDATE_EVENT_REF);
  }

  updateStore(chatID: string, updatedEventProps: IChat) {
    updatedEventProps.id = chatID;
    this._chatDispatchers.updateStore(updatedEventProps, UPDATE_EVENT_REF);
    this._eventUiDispatchers.closeEventEditUI();
  }

  confirmDelete(id: string) {
    this.deleteEvent(id);
    this.toggleConfirmDeleteWindow(false);
  }

  deleteEvent(id: string) {
    this._chatDispatchers.deleteChat(id);
  }

  toggleConfirmDeleteWindow(e: boolean, evant?: IChat) {
    this.confoirmDeleteWindow = e;
    this.selectedEvant = evant;
  }

  getNextPage(): void {
    this._eventUiDispatchers.getNextEventPage();
  }

  getPreviousPage(): void {
    this._eventUiDispatchers.getPrevEventPage();
  }

  get eventUiState$(): Observable<EventUI> {
    return this._selectors.eventUiState$;
  }

  get eventList$(): Observable<IChat[]> {
    return this._selectors.getEventList$();
  }

  get selectedEvent$(): Observable<IChat> {
    return this._selectors.getSelectedEvent$();
  }

  get eventInCreationProcess$(): Observable<IChat> {
    return this._selectors.getEventInCreationProcess$();
  }

  get componentContext$() {
    return combineLatest([
      this.eventUiState$,
      this.eventList$,
      this.selectedEvent$,
      this.eventInCreationProcess$
    ]);
  }

  getChatAdministrators(id: string) {
    this._chatDispatchers.getChatAdministrators(id);
  }
  getUserGroupRole(id: string, userId: string) {
    this._chatDispatchers.getUserGroupRole(id, userId);
  }

  updateEventPhoto(chat: IChat) {
    this._chatDispatchers.updateChat(chat);
  }

  routeBackToEventList() {
    this.router.navigate(['/events']);
  }
}
