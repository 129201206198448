import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapRouteActions from './mapRoute.actions';

import { AppState } from 'src/models/AppState';
import { MapRoute } from 'src/models/MapRoute';

@Injectable({
  providedIn: 'root'
})
export class MapRouteDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetMapRoutePaging() {
    this._store.dispatch(new MapRouteActions.ResetMapRoutesPaging());
  }

  getNextMapRoutePage() {
    this._store.dispatch(new MapRouteActions.GetNextMapRoutesPage());
  }

  getPrevMapRoutePage() {
    this._store.dispatch(new MapRouteActions.GetPrevMapRoutesPage());
  }

  getMapRouteByID(id: string) {
    this._store.dispatch(new MapRouteActions.GetMapRouteByID(id));
  }

  createMapRoute(mapRoute: MapRoute): void {
    this._store.dispatch(new MapRouteActions.CreateMapRoute(mapRoute));
  }

  mapRouteCreated(mapRoute: MapRoute): void {
    this._store.dispatch(new MapRouteActions.MapRouteCreated(mapRoute));
  }

  updateMapRoute(id: string, mapRoute: MapRoute): void {
    this._store.dispatch(new MapRouteActions.UpdateMapRoute(id, mapRoute));
  }

  mapRouteDetailsReceived(mapRoute: MapRoute): void {
    this._store.dispatch(new MapRouteActions.MapRouteDetailsReceived(mapRoute));
  }

  mapRouteListReceived(
    eop: number,
    sop: number,
    mapRoutes: MapRoute[],
    hash: string
  ): void {
    this._store.dispatch(
      new MapRouteActions.MapRouteListReceived(eop, sop, mapRoutes, hash)
    );
  }

  setCurrentScreen(screen: string) {
    this._store.dispatch(new MapRouteActions.SetCurrentScreen(screen));
  }

  deleteMapRoute(id: string): void {
    this._store.dispatch(new MapRouteActions.DeleteMapRoute(id));
  }

  mapRouteDeleted(id: string): void {
    this._store.dispatch(new MapRouteActions.MapRouteDeleted(id));
  }

  setStatusAsIdle() {
    this._store.dispatch(new MapRouteActions.SetStatusAsIdle());
  }
}
