import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';

@Directive({
  selector: '[appHideIfMainChat]'
})
export class HideIfMainChatDirective implements OnInit, OnDestroy {
  @Input() appHideIfMainChat = false;
  isVisible = false;
  stop$ = new Subject();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _rootStore: RootStoreSelectors,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.appHideIfMainChat) {
      this._rootStore
        .getEffectiveSelectedIChat$()
        .pipe(
          takeUntil(this.stop$),
          map(chat => chat && !chat.parent_id),
          distinctUntilChanged()
        )
        .subscribe(isMain => {
          if (isMain) {
            // If the user does not have the role,
            // we update the `isVisible` property and clear
            // the contents of the viewContainerRef
            if (this.isVisible) {
              this.isVisible = false;
              this.viewContainerRef.clear();
            }
          } else {
            this.setEmbedded();
          }
        });
    } else {
      this.setEmbedded();
    }
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }

  setEmbedded() {
    if (!this.isVisible) {
      // We update the `isVisible` property and add the
      // templateRef to the view using the
      // 'createEmbeddedView' method of the viewContainerRef
      this.isVisible = true;

      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.ref.detectChanges();
    }
  }
}
