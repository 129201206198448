import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ComponentRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-snackbar-notifier',
  templateUrl: './snackbar-notifier.component.html',
  styleUrls: ['./snackbar-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarNotifierComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  @Input() refresh = false;
  @Input() info = false;
  private _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public componentRef: ComponentRef<any>;

  constructor(public _ref: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.info) {
      setTimeout(() => {
        this.closeEvent();
      }, 3000);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeEvent() {
    this.refresh = false;
    this._ref.detectChanges();
  }

  refreshWindowEvent() {
    window.location.reload();
  }
}
