<app-form-frame no-padding [showCancel]="true" (closeNotify)="closePreview()">
  <ng-container ngProjectAs="title">
    {{ 'IMAGECROPPER' | translate }}
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <div class="crop">
          <i class="fas fa-spinner fa-spin" *ngIf="spinner$ | async"></i>
          <image-cropper
            *ngIf="!skipCropper"
            (mousedown)="onDragEvents($event)"
            [imageChangedEvent]="imageChangedEvent"
            [imageFileChanged]="selectedFile"
            [maintainAspectRatio]="maintainAspectRatio"
            [resizeToWidth]="resizeToWidth"
            [resizeToHeight]="resizeToHeight"
            [aspectRatio]="aspectRatio"
            [onlyScaleDown]="onlyScaleDown"
            [roundCropper]="roundCropper"
            [imageQuality]="80"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (loadImageFailed)="loadImageFailed()"
            (cropperReady)="cropperReady()"
          ></image-cropper>
          <img
            [src]="blobUrl$ | async"
            [alt]="blobUrl$ | async"
            *ngIf="skipCropper && blobUrl$ | async"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button class="add-btn" (click)="onFilesSelected(imageFile)">
        {{ 'CONFIRM' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
