import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appPublishing.actions';

import { AppState } from 'src/models/AppState';
import {
  AppJSON,
  AppPublishInfo,
  AppStoreJSON
} from 'src/models/AppPublishInfo';

@Injectable({
  providedIn: 'root'
})
export class AppPublishingDispatchers {
  constructor(private _store: Store<AppState>) {}

  setAppStoreInfo(appPublishingInfo: AppPublishInfo): void {
    this._store.dispatch(new actions.SetAppStoreInfo(appPublishingInfo));
  }

  setAppleStoreInfo(
    os: number,
    appStoreInfo: AppStoreJSON,
    setCompanyName?: boolean
  ) {
    this._store.dispatch(
      new actions.SetAppleStoreInfo(os, appStoreInfo, setCompanyName)
    );
  }

  setAppInfo(os: number, appInfo: AppJSON) {
    this._store.dispatch(new actions.SetAppInfo(os, appInfo));
  }

  receiveAppStoreInfo(appPublishingInfo: AppPublishInfo): void {
    this._store.dispatch(new actions.ReceiveAppStoreInfo(appPublishingInfo));
  }

  receiveAppsSetups(appPublishingInfo: AppJSON[]): void {
    this._store.dispatch(new actions.ReceiveAppsSetups(appPublishingInfo));
  }

  requestAppTesters() {
    this._store.dispatch(new actions.RequestAppTesters());
  }

  receivePublishInfoError(errorNumber: number) {
    this._store.dispatch(new actions.ReceivePublishInfoError(errorNumber));
  }

  receiveErrorCode(errorNumber: number) {
    this._store.dispatch(new actions.ReceiveErrorCode(errorNumber));
  }

  requestAppStoreInfo(appID?: string) {
    this._store.dispatch(new actions.RequestAppStoreInfo(appID));
  }

  receiveAppSetupInfo(app: AppJSON) {
    this._store.dispatch(new actions.ReceiveAppSetupInfo(app));
  }

  getAppsSetupInfo() {
    this._store.dispatch(new actions.GetAppsSetupInfo());
  }
}
