import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { GetAppChannelSettings, SetAppChannelSettings } from 'src/models/AppChannelSettings';
export class AppChannelSettingsEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.setAppChannelSettings = this.actions$.pipe(ofType("UI_SET_APP_CHANNEL_SETTINGS" /* UI_SET_APP_CHANNEL_SETTINGS */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppChannelSettings(action.whiteListEnabled));
        }));
        this.getAppChannelSettings = this.actions$.pipe(ofType("UI_GET_APP_CHANNEL_SETTINGS" /* UI_GET_APP_CHANNEL_SETTINGS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppChannelSettings());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppChannelSettingsEffects.prototype, "setAppChannelSettings", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppChannelSettingsEffects.prototype, "getAppChannelSettings", void 0);
