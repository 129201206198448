import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { MessageTypes } from 'src/models/constants';
import { MediaCenterService } from '../media-center.service';

@Component({
  selector: 'app-media-center-audio',
  templateUrl: './media-center-audio.component.html',
  styleUrls: ['./media-center-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterAudioComponent {
  @Input() url: string;
  @Input() localMedia: string;
  @Input() progress: number;
  @Input() mediaStatus: string;
  @Input() fileSize: number;
  @Input() fileName: string;

  mediaType = MessageTypes.AUDIO;
  localCoverStatus = true;

  constructor(private _mediaCenterService: MediaCenterService) {}

  get media() {
    return this._mediaCenterService.getMedia(this.localMedia);
  }

  get showCover() {
    return this._mediaCenterService.getCover(this.mediaStatus);
  }

  hideCover() {
    this.localCoverStatus = false;
  }
}
