import { Action } from '@ngrx/store';
import { Whitelist } from 'src/models/Whitelist';

export const enum WhitelistUIActionTypes {
  UI_WHITELIST_RESET_PAGING = 'UI_WHITELIST_RESET_PAGING',
  UI_GET_NEXT_WHITELIST_PAGE = 'UI_GET_NEXT_WHITELIST_PAGE',
  UI_GET_PREV_WHITELIST_PAGE = 'UI_GET_PREV_WHITELIST_PAGE',
  UI_ADD_USER_TO_WHITELIST = 'UI_ADD_USER_TO_WHITELIST',
  UI_ADD_USER_TO_WHITELIST_SUCCESS = 'UI_ADD_USER_TO_WHITELIST_SUCCESS',
  UI_DELETE_USER_FROM_WHITELIST = 'UI_DELETE_USER_FROM_WHITELIST',
  UI_DELETE_USER_FROM_WHITELIST_SUCCESS = 'UI_DELETE_USER_FROM_WHITELIST_SUCCESS',
  UI_RECEIVED_WHITELIST_HISTORY_CHUNK = 'UI_RECEIVED_WHITELIST_HISTORY_CHUNK',
  UI_WHITELIST_SET_IDLE_STATUS = 'UI_WHITELIST_SET_IDLE_STATUS',
  UI_SET_WHITELIST_LOADING_SCREEN_STATUS = 'UI_SET_WHITELIST_LOADING_SCREEN_STATUS',
  UI_RESET_WHITELIST = 'UI_RESET_WHITELIST'
}

export class ResetWhitelistPaging implements Action {
  readonly type = WhitelistUIActionTypes.UI_WHITELIST_RESET_PAGING;
  constructor(public name: string) {}
}

export class GetNextWhitelistPage implements Action {
  readonly type = WhitelistUIActionTypes.UI_GET_NEXT_WHITELIST_PAGE;
  constructor() {}
}
export class GetPrevWhitelistPage implements Action {
  readonly type = WhitelistUIActionTypes.UI_GET_PREV_WHITELIST_PAGE;
  constructor() {}
}

export class AddSignupIDToWhitelist implements Action {
  readonly type = WhitelistUIActionTypes.UI_ADD_USER_TO_WHITELIST;
  constructor(public chat_id: string, public users: Whitelist[]) {}
}

export class ReceivedWhitelistHistoryChunk implements Action {
  readonly type = WhitelistUIActionTypes.UI_RECEIVED_WHITELIST_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: Whitelist[],
    public hash: string
  ) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = WhitelistUIActionTypes.UI_WHITELIST_SET_IDLE_STATUS;
  constructor() {}
}

export class AddWhitelistSuccess implements Action {
  readonly type = WhitelistUIActionTypes.UI_ADD_USER_TO_WHITELIST_SUCCESS;
  constructor(public users: Whitelist[]) {}
}

export class DeleteUsersFromWhitelist implements Action {
  readonly type = WhitelistUIActionTypes.UI_DELETE_USER_FROM_WHITELIST;
  constructor(public users: string[], public chat_id: string) {}
}
export class DeleteUsersFromWhitelistSuccess implements Action {
  readonly type = WhitelistUIActionTypes.UI_DELETE_USER_FROM_WHITELIST_SUCCESS;
  constructor(public users: string[]) {}
}

export class SetLoadingScreenStatus implements Action {
  readonly type = WhitelistUIActionTypes.UI_SET_WHITELIST_LOADING_SCREEN_STATUS;
  constructor(public status: string) {}
}

export class ResetWhitelist implements Action {
  readonly type = WhitelistUIActionTypes.UI_RESET_WHITELIST;
}

export type Actions =
  | ResetWhitelistPaging
  | GetNextWhitelistPage
  | GetPrevWhitelistPage
  | AddSignupIDToWhitelist
  | AddWhitelistSuccess
  | DeleteUsersFromWhitelist
  | DeleteUsersFromWhitelistSuccess
  | ReceivedWhitelistHistoryChunk
  | SetStatusAsIdle
  | SetLoadingScreenStatus
  | ResetWhitelist;
