import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as Methods from 'src/models/MapTrip';
import { MapTripDispatchers } from './mapTrip.dispatchers';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
export class MapTripEffects {
    /** No need for the following effect because of all the data comes with the list */
    // @Effect({ dispatch: false })
    // listReceived = this.actions$.pipe(
    //   ofType(MapTripActionTypes.MAP_TRIP_LIST_RECEIVED),
    //   map((action: MapActions.MapTripListReceived) => {
    //     if (action.mapTrips && action.mapTrips.length > 0) {
    //       action.mapTrips.forEach(trip => {
    //         this._socketGateway.sendSocketMessage(
    //           new Methods.GetMapTrip(trip.id)
    //         );
    //       });
    //     }
    //   })
    // );
    constructor(actions$, _socketGateway, _mapTripDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._mapTripDispatchers = _mapTripDispatchers;
        this._store = _store;
        this.resetMapTripPaging = this.actions$.pipe(ofType("RESET_MAP_TRIP_PAGING" /* RESET_MAP_TRIP_PAGING */), map((action) => {
            this._mapTripDispatchers.getNextMapTripPage();
        }));
        this.getNextMapServicePage = this.actions$.pipe(ofType("GET_NEXT_MAP_TRIP_PAGE" /* GET_NEXT_MAP_TRIP_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsUiState]) => {
            const action = val;
            const prevRequest = mapTripsUiState.previousRequest;
            if (!(mapTripsUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapTripsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTrips(0, mapTripsUiState.eop, mapTripsUiState.hash));
            }
            else {
                this._mapTripDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMapServicePage = this.actions$.pipe(ofType("GET_PREV_MAP_TRIP_PAGE" /* GET_PREV_MAP_TRIP_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsUiState]) => {
            const action = val;
            const prevRequest = mapTripsUiState.previousRequest;
            if (!(mapTripsUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapTripsUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTrips(1, mapTripsUiState.sop, mapTripsUiState.hash));
            }
            else {
                this._mapTripDispatchers.setStatusAsIdle();
            }
        }));
        this.createMapTrip = this.actions$.pipe(ofType("CREATE_MAP_TRIP" /* CREATE_MAP_TRIP */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapTrip(action.mapTrip));
        }));
        this.updateMapTrip = this.actions$.pipe(ofType("UPDATE_MAP_TRIP" /* UPDATE_MAP_TRIP */), map((action) => {
            const mapTrip = Object.assign({}, action.mapTripDetails, { id: action.id });
            this._socketGateway.sendSocketMessage(new Methods.SetMapTrip(mapTrip));
        }));
        this.getMapTripId = this.actions$.pipe(ofType("GET_MAP_TRIP_BY_ID" /* GET_MAP_TRIP_BY_ID */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.GetMapTrip(action.tripId));
        }));
        this.deleteMapTrip = this.actions$.pipe(ofType("DELETE_MAP_TRIP" /* DELETE_MAP_TRIP */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveMapTrip(action.id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "resetMapTripPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "getNextMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "getPrevMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "createMapTrip", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "updateMapTrip", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "getMapTripId", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripEffects.prototype, "deleteMapTrip", void 0);
