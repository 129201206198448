import { Injectable } from '@angular/core';

import { SocketService } from '../services/socket.service';

@Injectable()
export class SocketGateway {
  constructor(private _socketService: SocketService) {}

  sendSocketMessage(data: any) {
    this._socketService.emitSocketMessage(data);
  }

  openSocketWithUrl(url: string) {
    this._socketService.openNewSocketWithURL(url);
  }

  openNewConnection() {
    this._socketService.openNewSocket();
  }

  closeCurrentSocket() {
    this._socketService.closeCurrentSocket();
  }
}
