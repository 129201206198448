import { WhitelistUI } from 'src/models/Whitelist';
import { ListRequestDetails, PageDirection } from 'src/models/constants';

import { Actions } from './whitelistMainUI.actions';
import * as actions from './whitelistMainUI.actions';

const INITIAL_STATE: WhitelistUI = {
  eop: 0,
  sop: null,
  hash: '',
  msisdn: null,
  currentPage: [],
  direction: null,
  previousRequest: {},
  screenState: 'Done'
};

export function whitelistUIReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.WhitelistUIActionTypes.UI_WHITELIST_RESET_PAGING: {
      return { ...INITIAL_STATE, msisdn: action.name ? action.name : null };
    }
    case actions.WhitelistUIActionTypes.UI_GET_NEXT_WHITELIST_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    }
    case actions.WhitelistUIActionTypes.UI_GET_PREV_WHITELIST_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV
      };
    }
    case actions.WhitelistUIActionTypes.UI_RECEIVED_WHITELIST_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.WhitelistUIActionTypes.UI_ADD_USER_TO_WHITELIST_SUCCESS: {
      return { ...state, currentPage: [...action.users, ...state.currentPage] };
    }
    case actions.WhitelistUIActionTypes.UI_DELETE_USER_FROM_WHITELIST_SUCCESS: {
      return {
        ...state,
        currentPage: state.currentPage.filter(user => {
          if (user.signup_user !== action.users[0]) {
            return user;
          }
        })
      };
    }
    case actions.WhitelistUIActionTypes
      .UI_SET_WHITELIST_LOADING_SCREEN_STATUS: {
      return { ...state, screenState: action.status };
    }
    case actions.WhitelistUIActionTypes.UI_WHITELIST_SET_IDLE_STATUS: {
      return { ...state };
    }
    case actions.WhitelistUIActionTypes.UI_RESET_WHITELIST:
      return INITIAL_STATE;
    default:
      return state;
  }
}
