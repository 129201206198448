/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menus-icons-conf.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../abstract-controls/selector-input-icon/selector-input-icon.component.ngfactory";
import * as i3 from "../../../../abstract-controls/selector-input-icon/selector-input-icon.component";
import * as i4 from "./menus-icons-conf.component";
import * as i5 from "../../app-mgmt/appMgmt.service";
var styles_MenusIconsConfComponent = [i0.styles];
var RenderType_MenusIconsConfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenusIconsConfComponent, data: {} });
export { RenderType_MenusIconsConfComponent as RenderType_MenusIconsConfComponent };
export function View_MenusIconsConfComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "seaction"], ["style", "margin-right: 35px; "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Android Icon "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-selector-input-icon", [["class", "icon-droplist"]], null, [[null, "updateItemIconNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateItemIconNotify" === en)) {
        var pd_0 = (_co.updateItemIconEvent($event, _co.tabcontainer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectorInputIconComponent_0, i2.RenderType_SelectorInputIconComponent)), i1.ɵdid(5, 638976, null, 0, i3.SelectorInputIconComponent, [], { disabled: [0, "disabled"], show: [1, "show"], iconName: [2, "iconName"] }, { updateItemIconNotify: "updateItemIconNotify" }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "seaction"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" iOS Icon "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-selector-input-icon", [["class", "icon-droplist"]], null, [[null, "updateItemIconNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updateItemIconNotify" === en)) {
        var pd_0 = (_co.updateItemIconIosEvent($event, _co.tabcontainer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectorInputIconComponent_0, i2.RenderType_SelectorInputIconComponent)), i1.ɵdid(10, 638976, null, 0, i3.SelectorInputIconComponent, [], { disabled: [0, "disabled"], show: [1, "show"], iconName: [2, "iconName"] }, { updateItemIconNotify: "updateItemIconNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = (((_co.tabcontainer == null) ? null : _co.tabcontainer.icon) || "ic_feeds_books_white_24_dp"); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = false; var currVal_4 = true; var currVal_5 = (((_co.tabcontainer == null) ? null : _co.tabcontainer.icon_ios) || "ic_feeds_books_white_24_dp"); _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MenusIconsConfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menus-icons-conf", [], null, null, null, View_MenusIconsConfComponent_0, RenderType_MenusIconsConfComponent)), i1.ɵdid(1, 114688, null, 0, i4.MenusIconsConfComponent, [i5.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenusIconsConfComponentNgFactory = i1.ɵccf("app-menus-icons-conf", i4.MenusIconsConfComponent, View_MenusIconsConfComponent_Host_0, { tabcontainer: "tabcontainer", currentPage: "currentPage" }, {}, []);
export { MenusIconsConfComponentNgFactory as MenusIconsConfComponentNgFactory };
