import { GET_MY_CALL_BACK_STATS_METHOD } from './constants';

export interface MenuCallBackStats {
  button_callback?: string;
  button_label?: string;
  count?: number;
}

export class GetMyCallBacksStats {
  readonly method = GET_MY_CALL_BACK_STATS_METHOD;
  constructor(public message_id: string) {}
}

export type MenuCallBackStatsMethods = GetMyCallBacksStats;
