import { Injectable } from '@angular/core';
import { CLARITY_KEY } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class ClarityService {
  constructor() {}

  public init() {
    try {
      const clarityScript = document.createElement('script');
      clarityScript.type = 'type=“text/javascript”';
      clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, 'clarity', 'script', '${CLARITY_KEY}');
      `;
      document.getElementsByTagName('head')[0].appendChild(clarityScript);
    } catch (er) {
      console.error('Error appending google analytics');
      console.error(er);
    }
  }
}
