import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { MembersAssignComponent } from './members-assign/members-assign.component';
import { AbstractPoolsModule } from 'src/app/abstract-pools/abstract-pools.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractPoolsModule,
    AccessorsModule,
    TranslateModule
  ],
  declarations: [MembersAssignComponent],
  exports: [MembersAssignComponent]
})
export class MembersPoolModule {}
