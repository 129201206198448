import { IChat } from './IChat';
import {
  BookingScreens,
  SCREEN_STATUS,
  SET_ENABLE_SERVICE_METHOD,
  SET_PAYMENT_SERVICE_METHOD
} from './constants';
import { EnableServiceConfig } from 'src/models/constants';
export interface BookingUI {
  selectedBooking?: IChat;
  creationProcessBooking?: IChat;
  currentBookingScreen?: BookingScreens;
  currentPage?: IChat[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  file?: any;
}
export class SetEnableService {
  readonly method = SET_ENABLE_SERVICE_METHOD;
  constructor(public config: EnableServiceConfig) {}
}
export class SetPaymentService {
  readonly method = SET_PAYMENT_SERVICE_METHOD;
  constructor(public config) {}
}
