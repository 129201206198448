import * as couponUIActions from './couponUI.actions';

import {
  SCREEN_STATUS,
  CouponScreens,
  ListRequestDetails
} from 'src/models/constants';

import { CouponUI } from 'src/models/CouponUI';

const INITIAL_STATE: CouponUI = {
  selectedCoupon: null,
  currentCouponScreen: CouponScreens.COUPON_LIST,
  eop: 0,
  sop: 0,
  currentPage: [],
  couponsStatus: null,
  expire: null,
  hash: '',
  status: SCREEN_STATUS.IDLE
};

export function couponUiReducer(
  state = INITIAL_STATE,
  action: couponUIActions.Actions
): CouponUI {
  switch (action.type) {
    case couponUIActions.CouponUIActionTypes.UI_LIST_COUPON: {
      return {
        ...state,
        selectedCoupon: null,
        eop: 0,
        sop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        currentCouponScreen: CouponScreens.COUPON_LIST
      };
    }
    case couponUIActions.CouponUIActionTypes.UI_CREATE_COUPON: {
      return {
        ...state,
        selectedCoupon: null,
        currentCouponScreen: CouponScreens.COUPON_CREATION
      };
    }

    case couponUIActions.CouponUIActionTypes.UI_VIEW_COUPON: {
      return {
        ...state,
        selectedCoupon: action.selectedCoupon,
        currentCouponScreen: CouponScreens.COUPON_VIEW
      };
    }

    case couponUIActions.CouponUIActionTypes.UI_EDIT_COUPON: {
      return {
        ...state,
        selectedCoupon: action.selectedCoupon,
        currentCouponScreen: CouponScreens.COUPON_EDIT
      };
    }

    case couponUIActions.CouponUIActionTypes.UI_CLOSE_CREATE_COUPON: {
      return {
        ...state,
        selectedCoupon: null,
        currentCouponScreen: CouponScreens.COUPON_LIST
      };
    }

    case couponUIActions.CouponUIActionTypes.UI_CLOSE_VIEW_COUPON: {
      return {
        ...state,
        selectedCoupon: null,
        currentCouponScreen: CouponScreens.COUPON_LIST
      };
    }

    case couponUIActions.CouponUIActionTypes.UI_CLOSE_EDIT_COUPON: {
      return {
        ...state,
        currentCouponScreen: CouponScreens.COUPON_LIST
      };
    }

    // calendars paging system
    case couponUIActions.CouponUIActionTypes.UI_COUPON_GET_NEXT_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
        // expire: action.expire,
        // couponsStatus: action.status
      };
    }
    case couponUIActions.CouponUIActionTypes.UI_COUPON_GET_PREV_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case couponUIActions.CouponUIActionTypes.UI_COUPON_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case couponUIActions.CouponUIActionTypes.UI_COUPON_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        couponsStatus: action.status ? action.status : null,
        expire: action.expire ? action.expire : null
      };
    }
    case couponUIActions.DESELECT_CHANNEL:
    case couponUIActions.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
