<div class="file-holder">
  <app-media-center-loading-cover
    *ngIf="showCover"
    [progress]="progress"
    [size]="fileSize"
    [status]="mediaStatus"
    [type]="mediaType"
    (hideMediaCover)="hideCover()"
  >
  </app-media-center-loading-cover>
  <div class="file-holder-content">
    <div class="file-holder-avatar">
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-PDF"></use>
      </svg>
    </div>
    <div class="file-holder-text truncate-text">
      <div class="header truncate-text">{{ fileName }}</div>
      <div class="footer">{{ fileSize | fileSize }}</div>
    </div>
  </div>
</div>
