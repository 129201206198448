import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';

import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { ThirdPartyInfoUIActionTypes } from './thirdPartyInfo.actions';
import * as ThirdPartyInfoUIActions from './thirdPartyInfo.actions';

import * as ThirdPartyInfoMethods from 'src/models/thirdPartyInfo';

@Injectable()
export class ThirdPartyInfoUIEffect {
  @Effect({ dispatch: false })
  saveThirdPartyInfo = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_SAVE_THIRD_PARTY_INFO),
    withLatestFrom(
      this._store
        .select(state => state.thirdPartyInfoReducer.vendors)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, vendors]) => {
      const action = <ThirdPartyInfoUIActions.SaveThirdPartyInfo>val;

      this._socketGateway.sendSocketMessage(
        new ThirdPartyInfoMethods.SaveThirdPartyInfoMethod(vendors)
      );
    })
  );

  @Effect({ dispatch: false })
  testThirdPartyInfo = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_TEST_THIRD_PARTY_INFO),
    map((action: ThirdPartyInfoUIActions.TestThirdPartyInfo) => {
      this._socketGateway.sendSocketMessage(
        new ThirdPartyInfoMethods.TestThirdPartyInfoMethod(action.vendor)
      );
    })
  );

  @Effect({ dispatch: false })
  getThirdPartyInfo = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_GET_THIRD_PARTY_INFO),
    map((action: ThirdPartyInfoUIActions.GetMessagingVendorUI) => {
      this._socketGateway.sendSocketMessage(
        new ThirdPartyInfoMethods.GetThirdPartyInfoMethod(action.name)
      );
    })
  );


  @Effect({ dispatch: false })
  sendVerificationMessage = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_DELETE_VERIFICATION_MASSAGE),
    withLatestFrom(
      this._store
        .select(state => state.thirdPartyInfoReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, vendors]) => {
      const action = <ThirdPartyInfoUIActions.DeleteVerificationMessageTemplate>val;
      const deleteEmailMessage = vendors.VerificationMessageEmailType ? false : true;
      const deleteSMSMessage = vendors.VerificationMessageSMSType ? false : true;
      if (vendors.verificationMessageEmail && deleteEmailMessage || vendors.verificationMessageSMS && deleteSMSMessage) {
        this._socketGateway.sendSocketMessage(
          new ThirdPartyInfoMethods.DeleteVerificationTemplate(deleteEmailMessage, deleteSMSMessage)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  deleteVerificationMessage = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_SEND_VERIFICATION_MASSAGE),
    withLatestFrom(
      this._store
        .select(state => state.thirdPartyInfoReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, vendors]) => {
      const action = <ThirdPartyInfoUIActions.SendVerificationMessageTemplate>val;
      const email = vendors.VerificationMessageEmailType ? vendors.verificationMessageEmail : null;
      const sms = vendors.VerificationMessageSMSType ? vendors.verificationMessageSMS : null;
      if (email || sms) {
        this._socketGateway.sendSocketMessage(
          new ThirdPartyInfoMethods.SendVerificationTemplate(email, sms)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  getVerificationMessage = this.actions$.pipe(
    ofType(ThirdPartyInfoUIActionTypes.UI_GET_VERIFICATION_MASSAGE),
    map((action: ThirdPartyInfoUIActions.GetVerificationMessageTemplate) => {
      this._socketGateway.sendSocketMessage(
        new ThirdPartyInfoMethods.GetVerificationTemplate()
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>
  ) { }
}
