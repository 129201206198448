import { BlackListPatternsUiDispatchers } from 'src/app/store/balckListPatternsUI/blackListPatternsUI.dispatchers';
import { BlackListUiDispatchers } from 'src/app/store/balckListUI/blackListUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/balckListUI/blackListUI.dispatchers";
import * as i2 from "../../../store/balckListPatternsUI/blackListPatternsUI.dispatchers";
import * as i3 from "../../../store/root-store.selectors";
export class BlacklistService {
    constructor(_blackListDispatchers, _blacklistPatternsDispatchers, _selectors) {
        this._blackListDispatchers = _blackListDispatchers;
        this._blacklistPatternsDispatchers = _blacklistPatternsDispatchers;
        this._selectors = _selectors;
    }
    resetPaging(name) {
        this._blackListDispatchers.resetBlacklistPaging(name);
    }
    getNextPage() {
        this._blackListDispatchers.getNextBlacklistsPage();
    }
    getPrevPage() {
        this._blackListDispatchers.getPrevBlacklistsPage();
    }
    addUserToBlacklist(users, mainChatID) {
        this._blackListDispatchers.addUserBlacklist(users, mainChatID);
    }
    resetObject() {
        this._blackListDispatchers.resetObject();
    }
    get blackListsUi$() {
        return this._selectors.blackListsUi$;
    }
    removeUserFromBlacklist(user, mainChatID) {
        this._blackListDispatchers.removeUserFromBlacklist(user, mainChatID);
    }
    // Blacklist Patterns
    resetBlacklistPatternsPaging() {
        this._blacklistPatternsDispatchers.resetBlacklistPatternsPaging();
    }
    getNextBlacklistPatternsPage() {
        this._blacklistPatternsDispatchers.getNextBlacklistPatternsPage();
    }
    getPrevBlacklistPatternsPage() {
        this._blacklistPatternsDispatchers.getPrevBlacklistPatternsPage();
    }
    addPatternToBlacklist(data, mainChatID) {
        this._blacklistPatternsDispatchers.addPatternToBlacklist(data, mainChatID);
    }
    get blackListPatternsUi$() {
        return this._selectors.blackListsPatternsUi$;
    }
    resetObjectBlacklistPatterns() {
        this._blacklistPatternsDispatchers.resetObject();
    }
    deletePatternsFromBlacklist(data, mainChatID) {
        this._blacklistPatternsDispatchers.deletePatternFromBlacklist(data, mainChatID);
    }
    setLoadingScreenStatus(status) {
        this._blackListDispatchers.setLoadingScreenStatus(status);
    }
}
BlacklistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlacklistService_Factory() { return new BlacklistService(i0.ɵɵinject(i1.BlackListUiDispatchers), i0.ɵɵinject(i2.BlackListPatternsUiDispatchers), i0.ɵɵinject(i3.RootStoreSelectors)); }, token: BlacklistService, providedIn: "root" });
