import { Action } from '@ngrx/store';
import { SubMenuStore } from './../../../models/SubMenu';
import { StoreMenu } from 'src/models/StoreMenu';

export const enum StoreMenuUIActionTypes {
  CREATE_STOERE_MENU = 'CREATE_STOERE_MENU',
  GET_STOERE_MENU = 'GET_STOERE_MENU',
  STORE_MENU_RECEIVED = 'STORE_MENU_RECEIVED',
  SET_STORE_MENU = 'SET_STORE_MENU',
  ADD_STORE_MENU_ITEM = 'ADD_STORE_MENU_ITEM',
  REMOVE_STORE_MENU_ITEM = 'REMOVE_STORE_MENU_ITEM',
  UI_STORE_MENU_GET_NEXT_PAGE = 'UI_STORE_MENU_GET_NEXT_PAGE',
  UI_STORE_MENU_GET_PREV_PAGE = 'UI_STORE_MENU_GET_PREV_PAGE',
  UI_STORE_MENU_RECEIVED_HISTORY_CHUNK = 'UI_STORE_MENU_RECEIVED_HISTORY_CHUNK',
  UI_STORE_MENU_RESET_PAGING = 'UI_STORE_MENU_RESET_PAGING',
  UI_STORE_MENU_SET_IDLE_STATUS = 'UI_STORE_MENU_SET_IDLE_STATUS',
  DELETE_MENU = 'DELETE_MENU',
  MENU_DELETED = 'MENU_DELETED',
  REFESH_STORE_MENU = 'REFESH_STORE_MENU',
  RESET = 'RESET'
}

export class CreateStoreMenu implements Action {
  readonly type = StoreMenuUIActionTypes.CREATE_STOERE_MENU;
  constructor(public storeMenusDetails: SubMenuStore) {}
}

export class GetStoreMenu implements Action {
  readonly type = StoreMenuUIActionTypes.GET_STOERE_MENU;
  constructor(public id: string) {}
}

export class SetStoreMenu implements Action {
  readonly type = StoreMenuUIActionTypes.SET_STORE_MENU;
  constructor(
    public storeMenuID: string,
    public storeMenuDetails: SubMenuStore
  ) {}
}

export class AddStoreMenuItem implements Action {
  readonly type = StoreMenuUIActionTypes.ADD_STORE_MENU_ITEM;
  constructor(public storeMenuID: string, public items: any[]) {}
}

export class RemoveStoreMenuItem implements Action {
  readonly type = StoreMenuUIActionTypes.REMOVE_STORE_MENU_ITEM;
  constructor(public storeMenuID: string, public itemIDs: string[]) {}
}

// Paging Actions

export class GetNextStoreMenuPage implements Action {
  readonly type = StoreMenuUIActionTypes.UI_STORE_MENU_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevStoreMenuPage implements Action {
  readonly type = StoreMenuUIActionTypes.UI_STORE_MENU_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedStoreMenuHistoryChunk implements Action {
  readonly type = StoreMenuUIActionTypes.UI_STORE_MENU_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: SubMenuStore[]
  ) {}
}

export class StoreMenuReceived implements Action {
  readonly type = StoreMenuUIActionTypes.STORE_MENU_RECEIVED;
  constructor(public storeMenu: StoreMenu) {}
}

export class ResetStoreMenuPaging implements Action {
  readonly type = StoreMenuUIActionTypes.UI_STORE_MENU_RESET_PAGING;
  constructor() {}
}

export class DeleteStoreMenu implements Action {
  readonly type = StoreMenuUIActionTypes.DELETE_MENU;
  constructor(public menuID: string) {}
}

export class StoreMenuDeleted implements Action {
  readonly type = StoreMenuUIActionTypes.MENU_DELETED;
  constructor(public id: string) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = StoreMenuUIActionTypes.UI_STORE_MENU_SET_IDLE_STATUS;
  constructor() {}
}

export class RefreshStoreMenu implements Action {
  readonly type = StoreMenuUIActionTypes.REFESH_STORE_MENU;
  constructor() {}
}

export class Reset implements Action {
  readonly type = StoreMenuUIActionTypes.RESET;
}

export type Actions =
  | CreateStoreMenu
  | GetStoreMenu
  | SetStoreMenu
  | AddStoreMenuItem
  | RemoveStoreMenuItem
  | GetNextStoreMenuPage
  | GetPrevStoreMenuPage
  | ReceivedStoreMenuHistoryChunk
  | StoreMenuReceived
  | ResetStoreMenuPaging
  | SetStatusAsIdle
  | DeleteStoreMenu
  | StoreMenuDeleted
  | RefreshStoreMenu
  | Reset;
