import { Action } from '@ngrx/store';
import { ChatThemeOpj } from 'src/models/chatThemes';

export const enum ChatThemesActionTypes {
  GET_CHAT_THEME_UI = 'GET_CHAT_THEME_UI',
  RECEVE_CHAT_THEME_UI = 'RECEVE_CHAT_THEME_UI',
  SET_CHAT_THEME_UI = 'SET_CHAT_THEME_UI',
  RESET = 'RESET'
}

export class SetChatTheme implements Action {
  readonly type = ChatThemesActionTypes.SET_CHAT_THEME_UI;
  constructor(public style: ChatThemeOpj) {}
}

export class GetChatThemeFromServer implements Action {
  readonly type = ChatThemesActionTypes.GET_CHAT_THEME_UI;
}
export class ReceveChatThemeFromServer implements Action {
  readonly type = ChatThemesActionTypes.RECEVE_CHAT_THEME_UI;
  constructor(public style: ChatThemeOpj) {}
}

export class Reset implements Action {
  readonly type = ChatThemesActionTypes.RESET;
}

export type ChatThemesAction = SetChatTheme | ReceveChatThemeFromServer | Reset;
