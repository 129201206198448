import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ScreenSizeService } from 'src/app/core/screen-size.service';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';

import { SIDE_MENU } from 'src/models/AppSections';
import { GridStyle, ThemeStatus, UISections } from 'src/models/constants';
import { BreakpointState } from '@angular/cdk/layout';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  appMenu = SIDE_MENU;
  uiSections = UISections;
  themeStatus = ThemeStatus;
  private _listGridStyle = new BehaviorSubject<number>(GridStyle.card);
  public listGridStyle$ = this._listGridStyle.asObservable();

  public _stickyStyle = new BehaviorSubject<boolean>(false);
  public stickyStyle$ = this._stickyStyle.asObservable();

  public _sectionLink = new BehaviorSubject<string>('');
  public sectionLink$ = this._sectionLink.asObservable();

  constructor(
    private _selectors: RootStoreSelectors,
    private _messageDispatchers: MessageDispatchers,
    private _screenSize: ScreenSizeService,
    private _uiDispatcher: UIDispatchers
  ) {}

  public _appModelOpenStatus = new BehaviorSubject<number>(0);
  public appModelOpenStatus$ = this._appModelOpenStatus.asObservable();

  public _appModelStatus = new BehaviorSubject<boolean>(false);
  public appModelStatus$ = this._appModelStatus.asObservable();

  getSideNavStatus(): Observable<BreakpointState> {
    return this._screenSize.mobileQuery;
  }
  setListGridStyle(n: number) {
    this._listGridStyle.next(n);
  }
  setSideMenuStatus(status: number) {
    this._uiDispatcher.setSideMenuStatus(status);
  }
  get messagesCollection$() {
    return this._selectors.messagesCollection$;
  }

  get uiCollection$() {
    return this._selectors.uiCollection$;
  }

  get selectedChat$() {
    return this._selectors.getSelectedChat$();
  }

  get allChannels$() {
    return this._selectors.getChatsMenu$();
  }
  getsideMenuStatus$() {
    return this._selectors.getSideMenuStatus();
  }

  deselectChannel() {
    this._messageDispatchers.deselectChannel();
  }

  getDocumentationLinkOfSection(
    section: string,
    currentPage?: any,
    themeStatus?: string
  ) {
    switch (section) {
      case this.uiSections.APP:
        if (currentPage == 1) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/branding-section'
          );
        } else if (currentPage == 2 && themeStatus === this.themeStatus.Menu) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/new-users#assigning-modules-to-buttons'
          );
        } else if (currentPage == 2 && themeStatus !== this.themeStatus.Menu) {
          this._sectionLink.next('https://docs.nandbox.com/docs/home-menu');
        } else if (currentPage == 3) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/home-menu#side-menu'
          );
        } else if (currentPage == 4) {
          this._sectionLink.next('https://docs.nandbox.com/docs/app-settings');
        } else if (currentPage == 5) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/create-apple-store-connect-account#configure-app-store-and-app-info-setup'
          );
        }
        break;
      case this.uiSections.CHANNEL:
        if (currentPage == 1) {
          this._sectionLink.next(
            'https://docs.nandbox.com/#img-src-media-buildericons-home-5b8af844-svg-alt-buildericon-nandbox-app-builder-branding-section'
          );
        } else if (currentPage == 2) {
          this._sectionLink.next(
            'https://docs.nandbox.com/#img-src-media-buildericons-home-5b8af844-svg-alt-buildericon-nandbox-app-builder-home-menu'
          );
        }
        break;
      case this.uiSections.POSTS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/posts-feed-module#module-walkthrough'
        );

        break;
      case this.uiSections.SCHEDULE:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/posts-feed-module#create-a-scheduled-post'
        );
        break;
      case this.uiSections.MENUS:
        this._sectionLink.next(
          'https://docs.nandbox.com/#img-src-media-buildericons-like-b69b718b-svg-alt-likeicon-social-loyalty-module-create-a-competition-poll-create-a-menu'
        );
        break;
      case this.uiSections.SUBCHANNEL:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/channel-modules#module-walkthrough'
        );

        break;
      case this.uiSections.SUBGROUP:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/chat-group-modules#module-walkthrough'
        );
        break;
      case this.uiSections.EVENTS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/events-list-module#module-walkthrough'
        );
        break;
      case this.uiSections.BOOKING:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/booking-module#module-walkthrough'
        );
        break;
      case this.uiSections.CALENDAR:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/school-app#creating-a-new-calendar'
        );
        break;
      case this.uiSections.MSTORE:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#setting-up-a-store'
        );
        break;
      case this.uiSections.PRODUCTS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#creating-a-new-product'
        );
        break;
      case this.uiSections.BUNDLES:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#creating-a-new-bundle'
        );
        break;
      case this.uiSections.PACKAGES:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#creating-a-new-multi-tiered-plan'
        );
        break;
      case this.uiSections.STOREMENU:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#adding-product-add-ons'
        );
        break;
      case this.uiSections.TRANSACTIONS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/store-module#checking-your-orders'
        );
        break;
      case this.uiSections.CAMPAIGNS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/loyalty-module#module-walkthrough'
        );
        break;
      case this.uiSections.COUPONS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/loyalty-module#create-a-coupon'
        );
        break;
      case this.uiSections.USERS:
        this._sectionLink.next('https://docs.nandbox.com/docs/user-management');
        break;
      case this.uiSections.TAGS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/user-management#create-new-tag'
        );
        break;
      case this.uiSections.BLACKLIST:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/user-management#blacklist'
        );
        break;
      case this.uiSections.WHITELIST:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/user-management#whitelist'
        );
        break;
      case this.uiSections.BOTS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/app-settings-2#api-and-integrations'
        );
        break;
      case this.uiSections.SETTINGS:
        this._sectionLink.next('https://docs.nandbox.com/docs/settings');
        break;
      case this.uiSections.BILLINGS:
        this._sectionLink.next(
          'https://docs.nandbox.com/docs/settings#changing-billing-information'
        );
        break;
      case 'startOver':
        if (currentPage == 1) {
          this._sectionLink.next('https://docs.nandbox.com/docs/new-users');
        } else if (currentPage == 3) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/new-users#additional-features'
          );
        } else if (currentPage == 4) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/new-users#simple-mode'
          );
        } else if (currentPage == 5) {
          this._sectionLink.next('https://docs.nandbox.com/docs/build-publish');
        } else if (currentPage == 6) {
          this._sectionLink.next('https://docs.nandbox.com/docs/build-publish');
        } else if (currentPage == 7) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/branding-section'
          );
        } else if (currentPage == 8) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/new-users#customizing-app-menu'
          );
        } else if (currentPage == 9) {
          this._sectionLink.next(
            'https://docs.nandbox.com/docs/new-users#creating-the-main-menu'
          );
        }
        break;
      case this.uiSections.BULKUPLOAD:
        this._sectionLink.next('https://docs.nandbox.com/docs/bulk-upload');
        break;
      default:
        this._sectionLink.next('https://docs.nandbox.com/');
        break;
    }
  }
}
