import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'app-headerless-closable-frame',
  templateUrl: './headerless-closable-frame.component.html',
  styleUrls: ['./headerless-closable-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderlessClosableFrameComponent {
  @Output() closeNotify = new EventEmitter<boolean>();
  @Input() showCancel = true;
  closeEvent() {
    this.closeNotify.emit(true);
  }
}
