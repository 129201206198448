import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { SetBookingExceptions, RemoveBookingExceptions } from 'src/models/IChat';
export class BookingExceptionsEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.updateBookingPeriods = this.actions$.pipe(ofType("SET_BOOKING_EXCEPTIONS" /* SET_BOOKING_EXCEPTIONS */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetBookingExceptions(action.chatId, action.dates, action.recordIndex));
        }));
        this.deleteBookingExceptions = this.actions$.pipe(ofType("DELETE_BOOKING_EXCEPTIONS" /* DELETE_BOOKING_EXCEPTIONS */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveBookingExceptions(action.chatId, action.dates));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingExceptionsEffects.prototype, "updateBookingPeriods", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingExceptionsEffects.prototype, "deleteBookingExceptions", void 0);
