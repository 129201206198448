import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as AdsProvidersActions from './adsProvider.actions';
import { map } from 'rxjs/operators';
import {
  SetAdsProviderMethod,
  GetAdsProvidersMethod
} from 'src/models/PaymentGateways';

@Injectable()
export class AdsProvidersEffects {
  @Effect({ dispatch: false })
  setAdsProvider = this.actions$.pipe(
    ofType(AdsProvidersActions.AdsProvidersActionTypes.SET_ADS_PROVIDER),
    map((action: AdsProvidersActions.SetAdsProvider) => {
      this._socketGateway.sendSocketMessage(
        new SetAdsProviderMethod(action.adsProviderId, action.config)
      );
    })
  );

  @Effect({ dispatch: false })
  getAdsProvider = this.actions$.pipe(
    ofType(AdsProvidersActions.AdsProvidersActionTypes.GET_ADS_PROVIDERS),
    map((action: AdsProvidersActions.GetAdsProviders) => {
      this._socketGateway.sendSocketMessage(
        new GetAdsProvidersMethod(action.adsProviderId)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
