import { Store } from '@ngrx/store';
import * as actions from './appChannelData.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppChannelDataDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveAppChannelData(channelData) {
        this._store.dispatch(new actions.ReceiveAppChannelData(channelData));
    }
}
AppChannelDataDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppChannelDataDispatchers_Factory() { return new AppChannelDataDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppChannelDataDispatchers, providedIn: "root" });
