import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import {
  AVR_COLOR_LINK,
  PLANS_DATA_API_LINK,
  SMTP_API_LINK
} from 'src/models/constants';

@Injectable()
export class PlansGateway {
  constructor(private _httpService: HttpService) {}

  getAllPlans() {
    return this._httpService.httpGetRequest(PLANS_DATA_API_LINK);
  }
  getPlan(plan_id: string) {
    return this._httpService.httpGetRequest(
      PLANS_DATA_API_LINK + '/' + plan_id
    );
  }
  validateSMTP(dns, port, usr, pass, secure) {
    return this._httpService.httpGetRequest(
      SMTP_API_LINK +
        '?dns=' +
        dns +
        '&port=' +
        port +
        '&usr=' +
        usr +
        '&pass=' +
        pass +
        '&secure=' +
        secure
    );
  }
  getAvrColor(img) {
    return this._httpService.httpGetRequest(AVR_COLOR_LINK + '?img=' + img);
  }
}
