import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { MapRouteActionTypes } from './mapRoute.actions';
import * as MapActions from './mapRoute.actions';
import * as Methods from 'src/models/MapRoute';
import { MapRoute } from 'src/models/MapRoute';
import { MapRouteDispatchers } from './mapRoute.dispatchers';
import { AppState } from 'src/models/AppState';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class MapRouteEffects {
  @Effect({ dispatch: false })
  resetMapRoute = this.actions$.pipe(
    ofType(MapRouteActionTypes.RESET_MAP_ROUTES_PAGING),
    map((action: MapActions.ResetMapRoutesPaging) => {
      this._mapRouteDispatchers.getNextMapRoutePage();
    })
  );

  @Effect({ dispatch: false })
  getNextMapRoutePage = this.actions$.pipe(
    ofType(MapRouteActionTypes.GET_NEXT_MAP_ROUTES_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapRouteReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapRouteUiState]) => {
      const action = <MapActions.GetNextMapRoutesPage>val;
      const prevRequest = mapRouteUiState.previousRequest;
      if (
        !(
          mapRouteUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapRouteUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapRoutes(0, mapRouteUiState.eop, mapRouteUiState.hash)
        );
      } else {
        this._mapRouteDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMapRoutePage = this.actions$.pipe(
    ofType(MapRouteActionTypes.GET_PREV_MAP_ROUTES_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapRouteReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapRouteUiState]) => {
      const action = <MapActions.GetNextMapRoutesPage>val;
      const prevRequest = mapRouteUiState.previousRequest;
      if (
        !(
          mapRouteUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapRouteUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapRoutes(1, mapRouteUiState.sop, mapRouteUiState.hash)
        );
      } else {
        this._mapRouteDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  createMapRoute = this.actions$.pipe(
    ofType(MapRouteActionTypes.CREATE_MAP_ROUTE),
    map((action: MapActions.CreateMapRoute) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapRoute(action.mapRoute)
      );
    })
  );

  @Effect({ dispatch: false })
  getMapRouteByID = this.actions$.pipe(
    ofType(MapRouteActionTypes.GET_MAP_ROUTE_BY_ID),
    map((action: MapActions.GetMapRouteByID) => {
      this._socketGateway.sendSocketMessage(new Methods.GetMapRoute(action.id));
    })
  );

  @Effect({ dispatch: false })
  updateMapRoute = this.actions$.pipe(
    ofType(MapRouteActionTypes.UPDATE_MAP_ROUTE),
    map((action: MapActions.UpdateMapRoute) => {
      const mapRoute: MapRoute = {
        ...action.mapRouteDetails,
        id: action.id
      };
      this._socketGateway.sendSocketMessage(new Methods.SetMapRoute(mapRoute));
    })
  );

  @Effect({ dispatch: false })
  deleteMapRoute = this.actions$.pipe(
    ofType(MapRouteActionTypes.DELETE_MAP_ROUTE),
    map((action: MapActions.DeleteMapRoute) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveMapRoute(action.id)
      );
    })
  );

  /** No need for the following effect because of all the data comes with the list */

  // @Effect({ dispatch: false })
  // listReceived = this.actions$.pipe(
  //   ofType(MapRouteActionTypes.MAP_ROUTE_LIST_RECEIVED),
  //   map((action: MapActions.MapRouteListReceived) => {
  //     if (action.mapRoutes && action.mapRoutes.length > 0) {
  //       action.mapRoutes.forEach(route => {
  //         this._socketGateway.sendSocketMessage(
  //           new Methods.GetMapRoute(route.id)
  //         );
  //       });
  //     }
  //   })
  // );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _mapRouteDispatchers: MapRouteDispatchers,
    private _store: Store<AppState>
  ) {}
}
