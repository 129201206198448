import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartoverComponent } from '../templates/startover/startover.component';
import { AuthComponent } from './+auth/auth.component';
import { SupportLogInComponent } from './+auth/support-log-in/support-log-in.component';
import { RegistrationComponent } from './+registration/registration.component';
import { VerifyAccountComponent } from './+registration/verify-account/verify-account.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'login',
    data: { title: 'The Native App Builder Login' },
    component: AuthComponent
  },
  {
    path: 'signup',
    data: { title: 'The Native App Builder Signup' },
    component: RegistrationComponent
  },
  {
    path: 'signup/:id',
    data: { title: 'The Native App Builder Signup' },
    component: RegistrationComponent
  },
  { path: 'spt', component: SupportLogInComponent },

  {
    path: 'verifyCode',
    component: VerifyAccountComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
