import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ItemDispatchers } from './item.dispatchers';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { initNewItem, extractItemData } from './item.middleware';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { REACHED_MAX_ITEM_LIMIT, REACHED_MIN_ITEM_LIMIT, TabTypes, ALREADY_HAD_MAP } from 'src/models/constants';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import { alreadyHadMap } from '../channelAppConfig/appConfig.middleware';
import * as isEqual from 'lodash.isequal';
import { SEARCH_COMPONENT } from 'src/app/+merchant/+dashboard/app-mgmt/templates';
import { ComponentDispatchers } from '../channelAppComponents/component.dispatchers';
export class ItemEffects {
    constructor(actions$, _componentDispatchers, _itemDispatchers, _uiDispatchers, _myPageDispatchers, _store) {
        this.actions$ = actions$;
        this._componentDispatchers = _componentDispatchers;
        this._itemDispatchers = _itemDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this._store = _store;
        this.addItemRequest = this.actions$.pipe(ofType("ADD_ITEM_REQUEST" /* ADD_ITEM_REQUEST */), withLatestFrom(this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, items, containers, sysConfig]) => {
            const action = (val);
            let itemsOfComponent = [];
            itemsOfComponent = items.filter(item => item.parentComponentRef === action.component.ref);
            if (action.component.max_items &&
                itemsOfComponent.length >= action.component.max_items) {
                this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
            }
            else {
                const newItem = initNewItem(action.component, action.specialItemValues, items);
                if (newItem.link === TabTypes.MAP && alreadyHadMap(containers, items)) {
                    this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
                }
                else {
                    let containerChildren = null;
                    switch (newItem.link) {
                        case TabTypes.SEARCH: {
                            containerChildren = extractItemData(JSON.parse(SEARCH_COMPONENT), newItem, sysConfig);
                            break;
                        }
                        default:
                            break;
                    }
                    if (containerChildren) {
                        this._componentDispatchers.addComponents(containerChildren.components);
                        newItem.component = containerChildren.components;
                    }
                    this._itemDispatchers.addItem(newItem);
                }
            }
        }));
        this.deleteItemRequest = this.actions$.pipe(ofType("DELETE_ITEM_REQUEST" /* DELETE_ITEM_REQUEST */), withLatestFrom(this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, items, components]) => {
            const action = val;
            let itemsOfComponent = [];
            itemsOfComponent = items.filter(item => item.parentComponentRef === action.item.parentComponentRef);
            const parentComponent = components.find(comp => comp.ref === action.item.parentComponentRef);
            if (parentComponent &&
                parentComponent.min_items &&
                itemsOfComponent.length <= parentComponent.min_items) {
                this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
            }
            else {
                this._itemDispatchers.deleteItem(action.item);
            }
        }));
        this.updateItemLinkRequest = this.actions$.pipe(ofType("REQUEST_UPDATE_ITEM_LINK" /* REQUEST_UPDATE_ITEM_LINK */), withLatestFrom(this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, items, containers]) => {
            const action = val;
            const targetItem = items.find(item => item.ref === action.itemRef);
            if (action.itemLink === TabTypes.MAP &&
                targetItem.link !== TabTypes.MAP &&
                alreadyHadMap(containers, items)) {
                this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
            }
            else {
                this._itemDispatchers.updateItemLink(action.itemRef, action.itemLink, action.pageId);
            }
        }));
        this.updateItemLink = this.actions$.pipe(ofType("UPDATE_ITEM_LINK" /* UPDATE_ITEM_LINK */), map((action) => {
            if (action.itemLink === TabTypes.PAGE && !action.pageId) {
                const pageRef = Date.now() + '';
                this._itemDispatchers.updateItem(action.itemRef, {
                    page_ref: pageRef,
                    page_id: null,
                    template_id: null
                });
                this._myPageDispatchers.createMyPage(pageRef);
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ItemEffects.prototype, "addItemRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ItemEffects.prototype, "deleteItemRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ItemEffects.prototype, "updateItemLinkRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ItemEffects.prototype, "updateItemLink", void 0);
