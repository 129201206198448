import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-chips-frame',
  templateUrl: './chips-frame.component.html',
  styleUrls: ['./chips-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsFrameComponent implements OnInit {
  @Output() removeNotify = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit() {}

  removeEvent() {
    this.removeNotify.emit(true);
  }
}
