import { Store } from '@ngrx/store';
import * as TemplateActions from './templates.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class TemplateDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    receiveTemplates(templates) {
        this._store.dispatch(new TemplateActions.ReceiveTemplates(templates));
    }
    selectAppTemplate(template) {
        this._store.dispatch(new TemplateActions.SelectAppTemplate(template));
    }
}
TemplateDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TemplateDispatchers_Factory() { return new TemplateDispatchers(i0.ɵɵinject(i1.Store)); }, token: TemplateDispatchers, providedIn: "root" });
