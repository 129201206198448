import { Action } from '@ngrx/store';
import { MapTrip } from 'src/models/MapTrip';

export const enum MapTripActionTypes {
  RESET_MAP_TRIP_PAGING = 'RESET_MAP_TRIP_PAGING',
  GET_NEXT_MAP_TRIP_PAGE = 'GET_NEXT_MAP_TRIP_PAGE',
  GET_PREV_MAP_TRIP_PAGE = 'GET_PREV_MAP_TRIP_PAGE',
  SET_MAP_STOP_TIME = 'SET_MAP_STOP_TIME',
  CREATE_MAP_TRIP = 'CREATE_MAP_TRIP',
  MAP_TRIP_CREATED = 'MAP_TRIP_CREATED',
  UPDATE_MAP_TRIP = 'UPDATE_MAP_TRIP',
  GET_MAP_TRIP_BY_ID = 'GET_MAP_TRIP_BY_ID',
  MAP_TRIP_DETAILS_RECEIVED = 'MAP_TRIP_DETAILS_RECEIVED',
  MAP_TRIP_LIST_RECEIVED = 'MAP_TRIP_LIST_RECEIVED',
  DELETE_MAP_TRIP = 'DELETE_MAP_TRIP',
  MAP_TRIP_DELETED = 'MAP_TRIP_DELETED',
  UI_MAP_TRIP_SET_IDLE_STATUS = 'UI_MAP_TRIP_SET_IDLE_STATUS',
  UI_SET_MAP_TRIP_CURRENT_SCREEN = 'UI_SET_MAP_TRIP_CURRENT_SCREEN',
  RESET = 'RESET'
}

export class ResetMapTripPaging implements Action {
  readonly type = MapTripActionTypes.RESET_MAP_TRIP_PAGING;
}

export class GetNextMapTripPage implements Action {
  readonly type = MapTripActionTypes.GET_NEXT_MAP_TRIP_PAGE;
  constructor() {}
}

export class GetPrevMapTripPage implements Action {
  readonly type = MapTripActionTypes.GET_PREV_MAP_TRIP_PAGE;
  constructor() {}
}

export class CreateMapTrip implements Action {
  readonly type = MapTripActionTypes.CREATE_MAP_TRIP;
  constructor(public mapTrip: MapTrip) {}
}

export class MapTripCreated implements Action {
  readonly type = MapTripActionTypes.MAP_TRIP_CREATED;
  constructor(public mapTrip: MapTrip) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = MapTripActionTypes.UI_MAP_TRIP_SET_IDLE_STATUS;
  constructor() {}
}

export class UpdateMapTrip implements Action {
  readonly type = MapTripActionTypes.UPDATE_MAP_TRIP;
  constructor(public id: string, public mapTripDetails: MapTrip) {}
}

export class MapTripDetailsReceived implements Action {
  readonly type = MapTripActionTypes.MAP_TRIP_DETAILS_RECEIVED;
  constructor(public mapTrip: MapTrip) {}
}

export class GetMapTripById implements Action {
  readonly type = MapTripActionTypes.GET_MAP_TRIP_BY_ID;
  constructor(public tripId: string) {}
}

export class MapTripListReceived implements Action {
  readonly type = MapTripActionTypes.MAP_TRIP_LIST_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public mapTrips: MapTrip[],
    public hash: string
  ) {}
}

export class SetCurrentScreen implements Action {
  readonly type = MapTripActionTypes.UI_SET_MAP_TRIP_CURRENT_SCREEN;
  constructor(public screen: string) {}
}
export class DeleteMapTrip implements Action {
  readonly type = MapTripActionTypes.DELETE_MAP_TRIP;
  constructor(public id: string) {}
}

export class MapTripDeleted implements Action {
  readonly type = MapTripActionTypes.MAP_TRIP_DELETED;
  constructor(public id: string) {}
}

export class Reset implements Action {
  readonly type = MapTripActionTypes.RESET;
}

export type Actions =
  | ResetMapTripPaging
  | GetNextMapTripPage
  | GetPrevMapTripPage
  | CreateMapTrip
  | SetStatusAsIdle
  | MapTripCreated
  | UpdateMapTrip
  | MapTripDetailsReceived
  | MapTripListReceived
  | DeleteMapTrip
  | MapTripDeleted
  | SetCurrentScreen
  | Reset;
