/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-row-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./menu-row-item.component";
import * as i4 from "@angular/forms";
import * as i5 from "../menus.service";
import * as i6 from "../../app-mgmt/appMgmt.service";
import * as i7 from "../../../../store/ui/ui.dispatchers";
import * as i8 from "../../bots/bots.service";
import * as i9 from "../../../+layout/layout.service";
import * as i10 from "../../../../core/file.service";
import * as i11 from "../../../../network/gateway/upload.gateway";
import * as i12 from "../../../../core/auth.service";
import * as i13 from "../../../../core/storage.service";
import * as i14 from "../../../../core/ui.service";
import * as i15 from "../../mobile-workflow-store/mobile-workflow-store.service";
import * as i16 from "../../collections/collections.service";
var styles_MenuRowItemComponent = [i0.styles];
var RenderType_MenuRowItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuRowItemComponent, data: {} });
export { RenderType_MenuRowItemComponent as RenderType_MenuRowItemComponent };
function View_MenuRowItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "item-container"]], [[2, "three", null], [2, "button", null], [2, "disabled", null]], [[null, "click"], [null, "dragstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickMenuItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.drag($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.length) === 3) && ((((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.key) !== ((_co.rowStyles == null) ? null : _co.rowStyles.SMALLHL)) && (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.key) !== ((_co.rowStyles == null) ? null : _co.rowStyles.WIDELIFT)))); var currVal_1 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.key) === ((_co.rowStyles == null) ? null : _co.rowStyles.BACK)); var currVal_2 = (((3 - _co.length) < _v.parent.context.$implicit.length) || ((_co.length >= 0) && (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.key) === ((_co.rowStyles == null) ? null : _co.rowStyles.BACK)))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.img); _ck(_v, 2, 0, currVal_3); }); }
function View_MenuRowItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuRowItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.cat) === ((_co.currentCategory == null) ? null : _co.currentCategory.id)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MenuRowItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuRowItemComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowStyle; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MenuRowItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-row-item", [], null, null, null, View_MenuRowItemComponent_0, RenderType_MenuRowItemComponent)), i1.ɵdid(1, 638976, null, 0, i3.MenuRowItemComponent, [i1.ChangeDetectorRef, i4.FormBuilder, i5.MenusService, i6.AppMgmtService, i7.UIDispatchers, i8.BotsService, i9.LayoutService, i10.FileService, i11.UploadGateway, i12.AuthService, i13.StorageService, i14.UiService, i15.MobileWorkFlowStoreService, i16.CollectionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuRowItemComponentNgFactory = i1.ɵccf("app-menu-row-item", i3.MenuRowItemComponent, View_MenuRowItemComponent_Host_0, { button: "button", length: "length", currentCategory: "currentCategory", isApp: "isApp", section: "section", botMenuEdit: "botMenuEdit", currentTabs: "currentTabs", currentPage: "currentPage", menusAppClass: "menusAppClass", selectedTab: "selectedTab", menuStoreList: "menuStoreList", mobileStoreAppList: "mobileStoreAppList", selectedButtonTab: "selectedButtonTab", selectedStoreId: "selectedStoreId" }, { styleSelectedNotify: "styleSelectedNotify" }, []);
export { MenuRowItemComponentNgFactory as MenuRowItemComponentNgFactory };
