import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit
} from '@angular/core';
import { UiService } from 'src/app/core/ui.service';
import { IS_PRODUCTION, MERCHANT_PROJECT, PROJECT_NAME } from 'src/models/constants';

@Component({
  selector: 'app-post-cell-actions',
  templateUrl: './post-cell-actions.component.html',
  styleUrls: ['./post-cell-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCellActionsComponent implements OnInit {
  @Input() media: string;
  @Input() mediaId: string;
  @Input() isAdmin: boolean;
  @Input() loggedInIsSender: boolean;
  @Input() isDownloadable: boolean;
  @Input() isMenu: boolean;
  @Input() isPostScreen = false;
  @Input() numberOfReplies: boolean;
  @Input() numberOfUnreadReplies: boolean;
  projectName = PROJECT_NAME;
  merchant = MERCHANT_PROJECT;
  @Output() recallNotfiy = new EventEmitter<boolean>();
  @Output() repostNotfiy = new EventEmitter<boolean>();
  @Output() editNotfiy = new EventEmitter<boolean>();
  @Output() statsNotfiy = new EventEmitter<boolean>();
  @Output() selectNotfiy = new EventEmitter<boolean>();
  @Output() setTemplateNotfiy = new EventEmitter<boolean>();

  toggleMenuStatus = false;

  isProduction = IS_PRODUCTION;

  constructor(public uiService: UiService) { }
  ngOnInit(): void { }

  recallEvent() {
    this.recallNotfiy.emit(true);
  }
  editEvent() {
    this.editNotfiy.emit(true);
  }
  repostEvent() {
    this.repostNotfiy.emit(true);
  }
  statsEvent() {
    this.statsNotfiy.emit(true);
  }

  selectEvent() {
    this.selectNotfiy.emit(true);
  }

  setTemplate() {
    this.setTemplateNotfiy.emit(true);
  }

  toggleMenu(status: boolean) {
    this.toggleMenuStatus = status;
  }
}
