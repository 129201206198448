import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { MessageStyles } from 'src/models/constants';

@Component({
  selector: 'app-link-preview-cell',
  templateUrl: './link-preview-cell.component.html',
  styleUrls: ['./link-preview-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPreviewCellComponent {
  @Input() url: string;
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() width: number;
  @Input() height: number;
  @Input() rootUrl: string;
  @Input() style: number;
  @Input() isInstant: boolean;

  @Input() redirect = true;

  mStyle: typeof MessageStyles = MessageStyles;

  @Input() showSite = true;

  get headerImg() {
    return {
      'background-image': `url('${this.image}')`,
      height: this.height ? `${this.height}px` : `15.5em`
    };
  }
  get headImg() {
    return {
      'background-image': `url('${this.image}')`
    };
  }

  get isSqure(): boolean {
    if (this.style) {
      if (this.style === this.mStyle.LEFT_SIDE) {
        return true;
      }
      return false;
    } else if (this.style === this.mStyle.WIDE) {
      return false;
    } else if (
      !this.style &&
      this.width &&
      this.height &&
      (this.width === this.height || (this.width < 200 && this.height < 200))
    ) {
      return true;
    }
    return false;
  }

  gotoLink() {
    if (this.redirect) {
      window.open(this.url, '_blank');
    }
  }
}
