import { Store } from '@ngrx/store';
import * as ProductUiActions from './productsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ProductsUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createProduct(product) {
        this._store.dispatch(new ProductUiActions.CreateProduct(product));
    }
    setProduct(productID, product) {
        this._store.dispatch(new ProductUiActions.SetProduct(productID, product));
    }
    addInventory(productID, units) {
        this._store.dispatch(new ProductUiActions.AddInventory(productID, units));
    }
    getProduct(productID, productType, bundleID) {
        this._store.dispatch(new ProductUiActions.GetProduct(productID, productType, bundleID));
    }
    productReceived(product) {
        this._store.dispatch(new ProductUiActions.ProductReceived(product));
    }
    productsReceived(products) {
        this._store.dispatch(new ProductUiActions.ProductsReceived(products));
    }
    // paging dispatchers
    getNextProductsPage() {
        this._store.dispatch(new ProductUiActions.GetNextProductsPage());
    }
    getPrevProductsPage() {
        this._store.dispatch(new ProductUiActions.GetPrevProductsPage());
    }
    deleteProduct(id) {
        this._store.dispatch(new ProductUiActions.DeleteProduct(id));
    }
    protuctDeleted(id) {
        this._store.dispatch(new ProductUiActions.ProductDeleted(id));
    }
    receivedProductsHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new ProductUiActions.ReceivedProductsHistoryChunk(eop, sop, currentPage));
    }
    resetProductsPaging(product_group_id, ownerGroupId) {
        this._store.dispatch(new ProductUiActions.ResetProductsPaging(product_group_id, ownerGroupId));
    }
    setStatusAsIdle() {
        this._store.dispatch(new ProductUiActions.SetStatusAsIdle());
    }
}
ProductsUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsUiDispatchers_Factory() { return new ProductsUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: ProductsUiDispatchers, providedIn: "root" });
