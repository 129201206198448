/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/profile-image/profile-image.component.ngfactory";
import * as i3 from "../../shared/components/profile-image/profile-image.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../store/root-store.selectors";
import * as i6 from "@angular/common";
import * as i7 from "../../shared/directives/cancel-event/cancel-event.directive";
import * as i8 from "../../emojibox/emoji-keyboard/emoji-keyboard.component.ngfactory";
import * as i9 from "../../emojibox/emoji-keyboard/emoji-keyboard.component";
import * as i10 from "../../emojibox/emojibox.service";
import * as i11 from "../record-voice-note/record-voice-note.component.ngfactory";
import * as i12 from "../record-voice-note/record-voice-note.component";
import * as i13 from "ngx-bootstrap/dropdown";
import * as i14 from "ngx-bootstrap/component-loader";
import * as i15 from "../upload-files/upload-files.component.ngfactory";
import * as i16 from "../upload-files/upload-files.component";
import * as i17 from "../../core/file.service";
import * as i18 from "../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i19 from "../../shared/components/modal-container/modal-container.component";
import * as i20 from "../../+merchant/+layout/layout.service";
import * as i21 from "../../abstract-frames/abstract-crop/abstract-crop.component.ngfactory";
import * as i22 from "../../abstract-frames/abstract-crop/abstract-crop.component";
import * as i23 from "../../cells/cells.service";
import * as i24 from "./chat-box.component";
import * as i25 from "../../core/ui.service";
import * as i26 from "../../core/helper.service";
var styles_ChatBoxComponent = [i0.styles];
var RenderType_ChatBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatBoxComponent, data: {} });
export { RenderType_ChatBoxComponent as RenderType_ChatBoxComponent };
function View_ChatBoxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addMinction(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-profile-image", [["class", "projection-avatar"], ["style", "margin-right: 1em;"]], null, null, null, i2.View_ProfileImageComponent_0, i2.RenderType_ProfileImageComponent)), i1.ɵdid(2, 704512, null, 0, i3.ProfileImageComponent, [i4.DomSanitizer, i5.RootStoreSelectors, i1.ChangeDetectorRef], { id: [0, "id"], emSize: [1, "emSize"], chatType: [2, "chatType"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.user_id); var currVal_1 = 2.8; var currVal_2 = "Individual"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.name); _ck(_v, 3, 0, currVal_3); }); }
function View_ChatBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChatBoxComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(((_co.uiService == null) ? null : _co.uiService.getProfileByID$(_v.context.$implicit)))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChatBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "box"], ["style", "background: #fff;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "close"], ["src", "./assets/img/screens-close.svg"], ["style", "margin-left: auto;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleUsers(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChatBoxComponent_2)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(((_co.uiService == null) ? null : _co.uiService.getChatUsersList$))); _ck(_v, 5, 0, currVal_0); }, null); }
function View_ChatBoxComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i7.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_EmojiKeyboardComponent_0, i8.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i9.EmojiKeyboardComponent, [i10.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
function View_ChatBoxComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-record-voice-note", [["class", "align-end clickable-cursor"]], null, [[null, "recordVoice"], [null, "sendVoiceNotify"], [null, "micNotFoundNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recordVoice" === en)) {
        var pd_0 = (_co.startRecordEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendVoiceNotify" === en)) {
        var pd_1 = (_co.sendVoiceNote($event) !== false);
        ad = (pd_1 && ad);
    } if (("micNotFoundNotify" === en)) {
        var pd_2 = (_co.micNotFoundEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_RecordVoiceNoteComponent_0, i11.RenderType_RecordVoiceNoteComponent)), i1.ɵdid(1, 573440, null, 0, i12.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], { selectedChat: [0, "selectedChat"] }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedChat; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ChatBoxComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon send"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-send"]], null, null, null, null, null))], null, null); }
function View_ChatBoxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "chat-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[2, 0], ["dropdownEmoji", 1]], null, 8, "div", [["class", "btn-group align-end clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i13.BsDropdownState, i13.BsDropdownState, []), i1.ɵdid(4, 212992, null, 0, i13.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i14.ComponentLoaderFactory, i13.BsDropdownConfig, i13.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i13.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i13.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i13.BsDropdownState], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-emoji-copy"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_5)), i1.ɵdid(10, 16384, null, 0, i13.BsDropdownMenuDirective, [i13.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-upload-files", [["class", "align-end clickable-cursor"]], [[2, "hide", null]], [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.sendMedia($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_UploadFilesComponent_0, i15.RenderType_UploadFilesComponent)), i1.ɵdid(12, 114688, null, 0, i16.UploadFilesComponent, [i17.FileService], null, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["textBox", 1]], null, 0, "div", [["class", "edit wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Type a message..."], ["id", "chatBox"]], [[2, "hide", null]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onFocus($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_6)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_7)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.isEmojiKeyboardOpen; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 12, 0); var currVal_11 = !_co.showSend; _ck(_v, 15, 0, currVal_11); var currVal_12 = _co.showSend; _ck(_v, 17, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 6).isDisabled; var currVal_8 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.isRecord; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.isRecord; _ck(_v, 13, 0, currVal_10); }); }
function View_ChatBoxComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i7.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_EmojiKeyboardComponent_0, i8.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i9.EmojiKeyboardComponent, [i10.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
function View_ChatBoxComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "chat-bot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "chat-bot-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[2, 0], ["dropdownEmoji", 1]], null, 7, "div", [["class", "btn-group align-end clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i13.BsDropdownState, i13.BsDropdownState, []), i1.ɵdid(4, 212992, null, 0, i13.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i14.ComponentLoaderFactory, i13.BsDropdownConfig, i13.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i13.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i13.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i13.BsDropdownState], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "smile-emoji far fa-smile"]], [[2, "hide", null], [2, "open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_9)), i1.ɵdid(9, 16384, null, 0, i13.BsDropdownMenuDirective, [i13.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["textBox", 1]], null, 0, "div", [["class", "edit wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Type a message..."]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-upload-files", [["class", "align-end clickable-cursor"]], [[2, "hide", null]], [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.sendMedia($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_UploadFilesComponent_0, i15.RenderType_UploadFilesComponent)), i1.ɵdid(12, 114688, null, 0, i16.UploadFilesComponent, [i17.FileService], { isBotScreen: [0, "isBotScreen"] }, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-record-voice-note", [["class", "align-end clickable-cursor"]], null, [[null, "recordVoice"], [null, "sendVoiceNotify"], [null, "micNotFoundNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recordVoice" === en)) {
        var pd_0 = (_co.startRecordEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendVoiceNotify" === en)) {
        var pd_1 = (_co.sendVoiceNote($event) !== false);
        ad = (pd_1 && ad);
    } if (("micNotFoundNotify" === en)) {
        var pd_2 = (_co.micNotFoundEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_RecordVoiceNoteComponent_0, i11.RenderType_RecordVoiceNoteComponent)), i1.ɵdid(14, 573440, null, 0, i12.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], { selectedChat: [0, "selectedChat"], isBotScreen: [1, "isBotScreen"] }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "send-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.isEmojiKeyboardOpen; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); var currVal_13 = true; _ck(_v, 12, 0, currVal_13); var currVal_14 = _co.selectedChat; var currVal_15 = true; _ck(_v, 14, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 6).isDisabled; var currVal_8 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.isRecord; var currVal_10 = _co.isEmojiKeyboardOpen; _ck(_v, 7, 0, currVal_9, currVal_10); var currVal_11 = _co.isRecord; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.isRecord; _ck(_v, 11, 0, currVal_12); }); }
function View_ChatBoxComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i7.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_EmojiKeyboardComponent_0, i8.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i9.EmojiKeyboardComponent, [i10.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
function View_ChatBoxComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "chat-messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "chat-messages-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[2, 0], ["dropdownEmoji", 1]], null, 7, "div", [["class", "btn-group align-end clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i13.BsDropdownState, i13.BsDropdownState, []), i1.ɵdid(4, 212992, null, 0, i13.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i14.ComponentLoaderFactory, i13.BsDropdownConfig, i13.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i13.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i13.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i13.BsDropdownState], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "smile-emoji far fa-smile"]], [[2, "hide", null], [2, "open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_11)), i1.ɵdid(9, 16384, null, 0, i13.BsDropdownMenuDirective, [i13.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["textBox", 1]], null, 0, "div", [["class", "edit wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Type a message..."]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-upload-files", [["class", "align-end clickable-cursor"]], [[2, "hide", null]], [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.sendMedia($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_UploadFilesComponent_0, i15.RenderType_UploadFilesComponent)), i1.ɵdid(12, 114688, null, 0, i16.UploadFilesComponent, [i17.FileService], { isBotScreen: [0, "isBotScreen"] }, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-record-voice-note", [["class", "align-end clickable-cursor"]], null, [[null, "recordVoice"], [null, "sendVoiceNotify"], [null, "micNotFoundNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recordVoice" === en)) {
        var pd_0 = (_co.startRecordEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendVoiceNotify" === en)) {
        var pd_1 = (_co.sendVoiceNote($event) !== false);
        ad = (pd_1 && ad);
    } if (("micNotFoundNotify" === en)) {
        var pd_2 = (_co.micNotFoundEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_RecordVoiceNoteComponent_0, i11.RenderType_RecordVoiceNoteComponent)), i1.ɵdid(14, 573440, null, 0, i12.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], { selectedChat: [0, "selectedChat"], isBotScreen: [1, "isBotScreen"] }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "send-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-send"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.isEmojiKeyboardOpen; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); var currVal_13 = true; _ck(_v, 12, 0, currVal_13); var currVal_14 = _co.selectedChat; var currVal_15 = true; _ck(_v, 14, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 6).isDisabled; var currVal_8 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.isRecord; var currVal_10 = _co.isEmojiKeyboardOpen; _ck(_v, 7, 0, currVal_9, currVal_10); var currVal_11 = _co.isRecord; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.isRecord; _ck(_v, 11, 0, currVal_12); }); }
function View_ChatBoxComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.closeImageCrroper() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_ModalContainerComponent_0, i18.RenderType_ModalContainerComponent)), i1.ɵdid(1, 245760, null, 0, i19.ModalContainerComponent, [i1.ElementRef, i20.LayoutService], { count: [0, "count"] }, { cancelNotfiy: "cancelNotfiy" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "app-abstract-crop", [], null, [[null, "filesSelectedNotifay"], [null, "closeNotifay"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotifay" === en)) {
        var pd_0 = (_co.imageSelectedEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeNotifay" === en)) {
        var pd_1 = (_co.closeImageCrroper() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i21.View_AbstractCropComponent_0, i21.RenderType_AbstractCropComponent)), i1.ɵdid(4, 114688, null, 0, i22.AbstractCropComponent, [i17.FileService, i23.CellsService], { imageChangedEvent: [0, "imageChangedEvent"], selectedFile: [1, "selectedFile"], resizeToWidth: [2, "resizeToWidth"], resizeToHeight: [3, "resizeToHeight"], imageQuality: [4, "imageQuality"], aspectRatio: [5, "aspectRatio"], mimeType: [6, "mimeType"], onlyScaleDown: [7, "onlyScaleDown"], maintainAspectRatio: [8, "maintainAspectRatio"], roundCropper: [9, "roundCropper"], skipCropper: [10, "skipCropper"] }, { filesSelectedNotifay: "filesSelectedNotifay", closeNotifay: "closeNotifay" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.layoutService == null) ? null : _co.layoutService.appModelOpenStatus$))); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.selectedFile; var currVal_2 = ((_co.selectedFile == null) ? null : _co.selectedFile.localFile); var currVal_3 = _co.resizeToWidth; var currVal_4 = _co.resizeToHeight; var currVal_5 = 50; var currVal_6 = (_co.selectedFile.width / _co.selectedFile.height); var currVal_7 = _co.selectedFile.localFile.type; var currVal_8 = true; var currVal_9 = true; var currVal_10 = false; var currVal_11 = (((_co.selectedFile == null) ? null : ((_co.selectedFile.localFile == null) ? null : _co.selectedFile.localFile.size)) <= 250000); _ck(_v, 4, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_ChatBoxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { textBox: 0 }), i1.ɵqud(671088640, 2, { dropdownEmoji: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_8)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_10)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_12)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showUsers; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.isScreenBot && !_co.isScreenMessages); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.isScreenBot && !_co.isScreenMessages); _ck(_v, 7, 0, currVal_2); var currVal_3 = (!_co.isScreenBot && _co.isScreenMessages); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.selectedFile; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ChatBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-box", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onEnterKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChatBoxComponent_0, RenderType_ChatBoxComponent)), i1.ɵdid(1, 638976, null, 0, i24.ChatBoxComponent, [i25.UiService, i20.LayoutService, i1.ElementRef, i26.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatBoxComponentNgFactory = i1.ɵccf("app-chat-box", i24.ChatBoxComponent, View_ChatBoxComponent_Host_0, { selectedChat: "selectedChat", selectedMessage: "selectedMessage", selectedUserId: "selectedUserId", groupReplies: "groupReplies", replyToAdmin: "replyToAdmin", talkToAdminBot: "talkToAdminBot", isScreenBot: "isScreenBot", isScreenMessages: "isScreenMessages", show: "show", deepLink: "deepLink" }, {}, []);
export { ChatBoxComponentNgFactory as ChatBoxComponentNgFactory };
