import { AuthService } from 'src/app/core/auth.service';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { UiService } from '../../core/ui.service';

import { MainChat } from 'src/models/MainChat';
import { IMessage } from 'src/models/IMessage';
import {
  MEDIA_PROGRESS_THEME_COLOR,
  CHANNELS_THEME_COLOR,
  SHOW_REPLIES_COUNT,
  MessageMediaStatus,
  MessageTypes,
  MessageStyles,
  LinkPreviewOptions,
  MessageLinkPreviewStatus,
  PROJECT_NAME,
  MERCHANT_PROJECT
} from 'src/models/constants';
import { slideToUp } from 'src/app/app-animations';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { BotMenu } from 'src/models/IBotMenu';
import { StorageService } from 'src/app/core/storage.service';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';

@Component({
  selector: 'app-post-cell',
  templateUrl: './post-cell.component.html',
  styleUrls: ['./post-cell.component.scss'],
  animations: [slideToUp],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCellComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chat: MainChat;
  @Input() message: IMessage;
  @Input() isSubChat: boolean;
  @Input() fixedShare = false;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() isPostScreen = false;
  @Input() hideMenu = false;
  @Input() tabsList: LocalContainer[];
  @Input() isPublicChannel = true;
  @Input() chatLabel: any[];
  @Input() hedeChatLabel = false;

  @Output() retryNotify = new EventEmitter<IMessage>();
  @Output() cancelNotify = new EventEmitter<IMessage>();
  @Output() viewNotify = new EventEmitter<IMessage>();

  @Output() removeNotify = new EventEmitter<IMessage>();
  project = PROJECT_NAME;
  merchant = MERCHANT_PROJECT;
  openShare = false;
  mType: typeof MessageTypes = MessageTypes;
  mStyles: typeof MessageStyles = MessageStyles;
  linkPreviewStyle: typeof LinkPreviewOptions = LinkPreviewOptions;
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  showRepliesCount = SHOW_REPLIES_COUNT;

  alerts: any[] = [
    {
      isShow: false,
      msg: `Link Copied`,
      timeout: 5000
    }
  ];
  themeColorMedia = MEDIA_PROGRESS_THEME_COLOR;
  themeColorFile = CHANNELS_THEME_COLOR;
  menus: BotMenu;
  constructor(
    public uiService: UiService,
    private _sanitizer: DomSanitizer,
    public authService: AuthService,
    private storageServers: StorageService,
    public appMgmtService: AppMgmtService
  ) {}

  ngOnInit() {
    if (this.message && this.message.message_id) {
      this.uiService.getMessageCounters(this.message.group_id, [
        this.message.message_id
      ]);
    }

    if (this.message && this.message.inline_menu) {
      this.menus = this.message.inline_menu[0];
    }
  }

  ngOnChanges(chng: SimpleChanges) {
    if (
      this.message &&
      this.message.inline_menu &&
      this.message.sender_type === 'Bot'
    ) {
      this.menus = this.message.inline_menu[0];
    }

    this.viewNotify.emit(this.message);
  }

  ngOnDestroy() {
    this.openShare = false;
  }

  get isShareable() {
    if (
      this.message &&
      this.message.message_id &&
      this.message.type &&
      (this.message.type === MessageTypes.TEXT ||
        this.message.type === MessageTypes.TEXT_FILE ||
        this.message.type === MessageTypes.PHOTO ||
        (this.message.type === MessageTypes.GIF &&
          this.message.media_id &&
          !this.message.media_id.endsWith('mp4')))
    ) {
      return true;
    }
    return false;
  }

  /* Component Events */
  selectEvent() {
    if (this.isSubChat) {
      this.uiService.selectSubChannelPost(this.message);
    } else {
      this.uiService.selectChannelPost(this.message);
    }
    if (!this.message.rcvdHistoryReply) {
      if (this.isSubChat) {
        this.uiService.requestHistory(
          this.message.message_id,
          null,
          null,
          this.message.group_id
        );
      } else {
        this.uiService.requestHistory(this.message.message_id);
      }
    }
  }

  likeEvent() {
    if (this.message && this.message.myLike) {
      this.uiService.unlikePost(this.message);
    } else if (this.message && !this.message.myLike) {
      this.uiService.likePost(this.message);
    }
  }

  closeShareEvent() {
    this.openShare = false;
  }

  shareEvent() {
    if (this.isShareable) {
      this.openShare = true;
      if (this.message && this.message.message_id) {
        this.uiService.sharePost(this.message);
      }
    }
  }

  get isDownloadable() {
    if (this.message) {
      if (
        (this.message.mediaStatus === MessageMediaStatus.DOWNLOADED ||
          this.message.mediaStatus === MessageMediaStatus.UPLOADED) &&
        (this.message.type === MessageTypes.PHOTO ||
          this.message.type === MessageTypes.GIF ||
          this.message.type === MessageTypes.VIDEO ||
          this.message.type === MessageTypes.VOICE ||
          this.message.type === MessageTypes.AUDIO ||
          this.message.type === MessageTypes.DOCUMENT)
      ) {
        return true;
      }
    }
    return false;
  }

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }

  fullScreenEvent(mediaStatus: string) {
    if (
      !this.hideHeader &&
      (mediaStatus === MessageMediaStatus.DOWNLOADED ||
        mediaStatus === MessageMediaStatus.UPLOADED)
    ) {
      this.uiService.showMediaScreen(this.message);
    }
  }

  recallEvent() {
    this.storageServers.removeRecord(this.message.message_id);
    this.uiService.recallMessage(this.message);
  }

  editEvent() {
    // TODO: add edit message event
  }

  repostEvent() {
    // TODO: add repost message event
  }

  retryEvent($event) {
    if ($event) {
      this.retryNotify.emit(this.message);
    }
  }

  cancelEvent($event) {
    if ($event) {
      this.cancelNotify.emit(this.message);
    }
  }

  getMessageMenuStats() {
    this.uiService.getMessageMenuStats(this.message);
  }

  getTabContainer(tabID) {
    const container: LocalContainer = this.tabsList.find(
      res => res.id === tabID
    );
    return container;
  }

  buttonClicked(event) {
    const buttonData = event;
    const currentMenu = this.menus;
    buttonData.to_user_id = this.message.sender_id;
    // buttonData.chat_id = this.chat.id;
    buttonData.reference = this.message.reference.toString();
    buttonData.menu_ref = this.message.menu_ref;
    if (this.message.sender_type !== 'Bot') {
      for (let i = 0; i < this.message.inline_menu.length; i++) {
        if (
          this.message.inline_menu[i] &&
          this.message.inline_menu[i].menu_id === currentMenu.next_menu
        ) {
          this.menus = this.message.inline_menu[i];
        }
      }
    } else {
      this.uiService.getInlineMessageCallBack(buttonData);
    }
  }

  setTemplate() {
    const massage: any = {
      echo: 1,
      chat_id: this.message.group_id,
      reference: this.message.reference,
      tags: this.message.tags || []
    };
    if (this.message.tab) {
      massage.tab = this.message.tab;
    }
    if (this.message.sticky_title) {
      massage.sticky_title = this.message.sticky_title;
    }
    if (this.message.sticky_desc) {
      massage.sticky_desc = this.message.sticky_desc;
    }
    if (this.message.menu_ref && this.message.inline_menu) {
      massage.menu_ref = this.message.menu_ref;
      massage.inline_menu = this.message.inline_menu;
      massage.reply_to_admin = true;
    }
    if (this.message.c_code && this.message.c_exp) {
      massage.c_code = this.message.c_code;
      massage.c_exp = this.message.c_exp;
    }
    if ('style' in this.message) {
      massage.style = this.message.style;
    }
    if ('bg_color' in this.message) {
      massage.bg_color = this.message.bg_color;
    }
    if (this.message.type === this.mType.VIDEO) {
      massage.video = this.message.media_id;
      massage.width = this.message.media_width;
      massage.height = this.message.media_height;
      massage.size = this.message.file_size;
      massage.caption = this.message.caption;
      massage.duration = this.message.media_duration;
    } else if (
      this.message.type === this.mType.PHOTO ||
      this.message.type === this.mType.GIF
    ) {
      massage.caption = this.message.caption;
      massage.photo = this.message.media_id;
      massage.width = this.message.media_width;
      massage.height = this.message.media_height;
      massage.size = this.message.file_size;
    } else if (this.message.type === this.mType.AUDIO) {
      massage.title = this.message.title;
      massage.audio = this.message.media_id;
      massage.size = this.message.file_size;
      massage.caption = this.message.caption;
      massage.duration = this.message.media_duration;
    } else if (this.message.type === this.mType.DOCUMENT) {
      massage.document = this.message.media_id;
      massage.size = this.message.file_size;
    } else if (this.message.type === this.mType.ARTICLE) {
      massage.title = this.message.linkPreviewTitle;
      massage.description = this.message.linkPreviewDescription;
      massage.photo_url = this.message.linkPreviewImage;
      massage.url = this.message.linkPreviewUrl;
    } else if (this.message.type === this.mType.TEXT) {
      massage.text = this.message.text;
    } else if (this.message.type === this.mType.CALENDAR) {
      massage.json = this.message.json;
      massage.photo = this.message.json.photo_id;
      massage.title = this.message.json.title;
      massage.description = this.message.json.description;
      massage.calendar_id = this.message.json.id;
    }
    const messageType = this.message.type.toLowerCase();

    this.uiService.setTemplateMessage(
      this.message.group_id,
      messageType,
      this.message.message_id,
      massage
    );
  }

  getChatLabel(tab) {
    let title = '';
    if (this.chatLabel && this.chatLabel.length > 0) {
      this.chatLabel.forEach(label => {
        if (label.id === tab) {
          title = label.label;
        }
      });
    }

    return title;
  }
}
