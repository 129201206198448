import * as MapMarkerMethods from 'src/models/MapMarker';

export class MapMarkerMiddleware {
  public static ismapMarkerExist(
    list: MapMarkerMethods.MapMarker[],
    rcvdObj: MapMarkerMethods.MapMarker
  ) {
    for (let i = 0; i < list.length; i++) {
      if (rcvdObj.id && list[i].id === rcvdObj.id) {
        return true;
      }
    }
    return false;
  }
}
