import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as MapDriverMethods from 'src/models/MapDriver';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/internal/operators/map';
export class MapDriverEffects {
    constructor(actions$, _socketGateway, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this.getDriverAssigned = this.actions$.pipe(ofType("GET_MAP_TRIPS_DRIVER" /* GET_MAP_TRIPS_DRIVER */), map((action) => {
            this._socketGateway.sendSocketMessage(new MapDriverMethods.GetMapTripUser(action.trip_id));
        }));
        this.deleteDriverAssigned = this.actions$.pipe(ofType("DELETE_MAP_TRIPS_DRIVER" /* DELETE_MAP_TRIPS_DRIVER */), map((action) => {
            this._socketGateway.sendSocketMessage(new MapDriverMethods.DeleteMapTripUser(action.account_id, action.trip_id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapDriverEffects.prototype, "getDriverAssigned", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapDriverEffects.prototype, "deleteDriverAssigned", void 0);
