/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./circle-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./circle-input.component";
var styles_CircleInputComponent = [i0.styles];
var RenderType_CircleInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CircleInputComponent, data: {} });
export { RenderType_CircleInputComponent as RenderType_CircleInputComponent };
export function View_CircleInputComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { circleInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["circleInputId", 1]], null, 0, "input", [["class", "circle-input"], ["maxlength", "1"], ["oninput", "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');"]], [[8, "type", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(((i1.ɵnov(_v, 1) == null) ? null : i1.ɵnov(_v, 1).value)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); }); }
export function View_CircleInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-circle-input", [], null, null, null, View_CircleInputComponent_0, RenderType_CircleInputComponent)), i1.ɵdid(1, 573440, null, 0, i2.CircleInputComponent, [], null, null)], null, null); }
var CircleInputComponentNgFactory = i1.ɵccf("app-circle-input", i2.CircleInputComponent, View_CircleInputComponent_Host_0, { type: "type", focused: "focused" }, { changeEvent: "changeEvent" }, []);
export { CircleInputComponentNgFactory as CircleInputComponentNgFactory };
