import { Action } from '@ngrx/store';
import { AppTemplate } from 'src/models/AppTemplate';

export const enum TemplateActionTypes {
  RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES',
  SELECT_APP_TEMPLATE = 'SELECT_APP_TEMPLATE',
  RESET = 'RESET'
}

export class ReceiveTemplates implements Action {
  readonly type = TemplateActionTypes.RECEIVE_TEMPLATES;
  constructor(public templates: AppTemplate[]) {}
}

export class SelectAppTemplate implements Action {
  readonly type = TemplateActionTypes.SELECT_APP_TEMPLATE;
  constructor(public template: AppTemplate) {}
}

export class Reset implements Action {
  readonly type = TemplateActionTypes.RESET;
}

export type Actions = SelectAppTemplate | ReceiveTemplates | Reset;
