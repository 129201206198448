import { Action } from '@ngrx/store';
import { ThirdPartyInfoUI, VendorTest } from 'src/models/thirdPartyInfo';

export const enum ThirdPartyInfoUIActionTypes {
  UI_RECEIVE_THIRD_PARTY_INFO = 'UI_RECEIVE_THIRD_PARTY_INFO',
  UI_UPDATE_THIRD_PARTY_INFO = 'UI_UPDATE_THIRD_PARTY_INFO',
  UI_SAVE_THIRD_PARTY_INFO = 'UI_SAVE_THIRD_PARTY_INFO',
  UI_TEST_THIRD_PARTY_INFO = 'UI_TEST_THIRD_PARTY_INFO',
  UI_RECEIVE_TEST_THIRD_PARTY_INFO = 'UI_RECEIVE_TEST_THIRD_PARTY_INFO',
  UI_REST_ERROR_MASSAGE = 'UI_REST_ERROR_MASSAGE',
  UI_GET_THIRD_PARTY_INFO = 'UI_GET_THIRD_PARTY_INFO',
  UI_SET_VERIFICATION_MASSAGE = 'UI_SET_VERIFICATION_MASSAGE',
  UI_SEND_VERIFICATION_MASSAGE = 'UI_SEND_VERIFICATION_MASSAGE',
  UI_GET_VERIFICATION_MASSAGE = 'UI_GET_VERIFICATION_MASSAGE',
  UI_RECEIVE_VERIFICATION_MASSAGE = 'UI_RECEIVE_VERIFICATION_MASSAGE',
  UI_SELECT_VERIFICATION_MASSAGE_TYPE = 'UI_SELECT_VERIFICATION_MASSAGE_TYPE',
  UI_DELETE_VERIFICATION_MASSAGE = 'UI_DELETE_VERIFICATION_MASSAGE'
}

export class ReceiveThirdPartyInfo implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_RECEIVE_THIRD_PARTY_INFO;
  constructor(public vendor: ThirdPartyInfoUI[]) { }
}
export class UpdateThirdPartyInfo implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_UPDATE_THIRD_PARTY_INFO;
  constructor(public vendors: ThirdPartyInfoUI) { }
}

export class TestThirdPartyInfo implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_TEST_THIRD_PARTY_INFO;
  constructor(public vendor: VendorTest) { }
}
export class ReceiveTestThirdPartyInfo implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_RECEIVE_TEST_THIRD_PARTY_INFO;
  constructor(
    public accepted: boolean,
    public message: string,
    public tac: string
  ) { }
}

export class RestErrorMassage implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_REST_ERROR_MASSAGE;
}

export class SaveThirdPartyInfo implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_SAVE_THIRD_PARTY_INFO;
}
export class GetMessagingVendorUI implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_GET_THIRD_PARTY_INFO;
  constructor(public name: string) { }
}
export class SetVerificationMessage implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_SET_VERIFICATION_MASSAGE;
  constructor(public value, public typeMessage) { }
}
export class SendVerificationMessageTemplate implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_SEND_VERIFICATION_MASSAGE;
}

export class GetVerificationMessageTemplate implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_GET_VERIFICATION_MASSAGE;
}
export class DeleteVerificationMessageTemplate implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_DELETE_VERIFICATION_MASSAGE;
}
export class ReceiveVerificationMessageTemplate implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_RECEIVE_VERIFICATION_MASSAGE;
  constructor(public email, public sms) { }
}
export class SelectTypeOfMessage implements Action {
  readonly type = ThirdPartyInfoUIActionTypes.UI_SELECT_VERIFICATION_MASSAGE_TYPE;
  constructor(public typeMessage, public loginType) { }
}

export type Actions =
  | ReceiveThirdPartyInfo
  | UpdateThirdPartyInfo
  | ReceiveTestThirdPartyInfo
  | RestErrorMassage
  | SetVerificationMessage
  | ReceiveVerificationMessageTemplate
  | SelectTypeOfMessage;
