import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { TagsService } from '../../tags/tags.service';
import { ITag } from 'src/models/ITag';
import { ComposeMessageService } from '../compose-message.service';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { PrivilegesName } from 'src/models/privileges';
import { IChat } from 'src/models/IChat';
import {
  CHANNEL,
  CHANNELS,
  ChatTypes,
  GROUP,
  MessageSendingType,
  UISections
} from 'src/models/constants';
import { AuthService } from './../../../../core/auth.service';
import { AppMgmtService } from './../../app-mgmt/appMgmt.service';
import { BehaviorSubject } from 'rxjs';
import { MainChat } from 'src/models/MainChat';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from 'src/app/core/ui.service';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';

@Component({
  selector: 'app-select-message-targets',
  templateUrl: './select-message-targets.component.html',
  styleUrls: ['./select-message-targets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageTargetsComponent implements OnInit, OnChanges {
  silentFlag = false;
  showTagsModal = false;
  showPrivateTagsModal = false;
  showSubgroupsModal = false;
  type: string;

  selectedTags: any[] = [];
  currentSelectedTags: string[] = [];
  selectedSubgroups: IChat[] = [];
  localSelectedTab: LocalContainer = null;
  chatTypes: typeof ChatTypes = ChatTypes;

  @Input() allSelectedTabs: LocalContainer[];
  @Input() selectedSubChat: MainChat;
  @Input() messageSendingType: string;
  @Input() isMainChannel = false;
  @Input() chatLabels: any[];
  privilegesName: typeof PrivilegesName = PrivilegesName;
  uiSections: typeof UISections = UISections;
  hoverTags: boolean;
  hoverSegment: boolean;
  hoverGroup: boolean;
  hoverSilent: boolean;
  hoverFeed: boolean;
  hoverTooltipSendTo = false;
  hideNavigateBtn = false;
  silentTextTooltip: string;
  selectedTabs: LocalContainer[] = [];
  allTabs: any[] = [];
  @ViewChild('menuButton', { static: false }) menuButton: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @ViewChild('list', { static: false }) list: ElementRef;
  group = GROUP;
  channel = CHANNEL;
  messageType = MessageSendingType;
  public _toggleMenu = new BehaviorSubject<boolean>(false);
  public toggleMenu$ = this._toggleMenu.asObservable();
  public _toggleSubMenu = new BehaviorSubject<boolean>(false);
  public toggleSubMenu$ = this._toggleSubMenu.asObservable();

  constructor(
    public composeMessageService: ComposeMessageService,
    public authService: AuthService,
    public appMgmtService: AppMgmtService,
    private _ref: ChangeDetectorRef,
    public _tagsService: TagsService,
    public uiService: UiService,
    public layoutService: LayoutService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    const chng = changes['selectedSubChat'];
    if (this.list) {
      this.list.nativeElement.clientWidth < this.list.nativeElement.scrollWidth
        ? this._toggleMenu.next(true)
        : this._toggleMenu.next(false);
    }
  }

  ngOnInit() {
    if (!this.localSelectedTab) {
      this.localSelectedTab = this.chatLabels[0];
      this.selectedTabs.push(this.allSelectedTabs[0]);
      if (this.chatLabels[0] && this.chatLabels[0].id) {
        this.composeMessageService._currentSelectedTapRef.next(
          this.chatLabels[0].id
        );
      }
    }
    if (this.selectedSubChat.subChannel) {
      const chat = ChatMiddleware.getChatFromMainChat(this.selectedSubChat);
      this.selectedSubgroups.push(chat);
    }
    let selectedLabelTags: string[] = [];
    const tagsSub = this.appMgmtService
      .getTagsByTabId$(this.localSelectedTab.id)
      .subscribe(res => (selectedLabelTags = res));
    tagsSub.unsubscribe();
    if (
      this.allSelectedTabs[0] &&
      selectedLabelTags &&
      selectedLabelTags.length > 0
    ) {
      this._tagsService
        .getSelectedChatMemberTag$(selectedLabelTags)
        .subscribe(res => {
          if (!this.selectedSubChat.subChannel) {
            const cht = {
              chat_id: this.selectedSubChat.id,
              tags: [...res]
            };
            this.selectedTags.push(cht);
          }
        });
    }
  }

  /** Set Slient */
  setSilentFlag(e: boolean) {
    this.silentFlag = e;
  }
  /** Set Tab */
  setSelectedTab(tab: any) {
    this.localSelectedTab = { ...tab };

    let selectedLabelTags: string[] = [];
    const tagsSub = this.appMgmtService
      .getTagsByTabId$(tab.id)
      .subscribe(res => (selectedLabelTags = res));
    tagsSub.unsubscribe();
    if (selectedLabelTags && selectedLabelTags.length > 0) {
      this.selectedTags = [];
      this._tagsService
        .getSelectedChatMemberTag$(selectedLabelTags)
        .subscribe(res => {
          const cht = {
            chat_id: this.selectedSubChat.id,
            tags: [...res]
          };
          this.selectedTags.push(cht);
        })
        .unsubscribe();
    } else {
      this.selectedTags = [];
    }

    this.composeMessageService._currentSelectedTapRef.next(tab.id);
    this._toggleSubMenu.next(false);
  }

  /** Set Tags */
  showTagsModalEvent(show: boolean) {
    this.showTagsModal = show;
  }
  showPrivateTagsModalEvent(show: boolean) {
    this.showPrivateTagsModal = show;
  }

  setTagsEvent(tags: ITag[], chatID: string) {
    const chatTags = this.selectedTags.find(
      chatTag => chatTag.chat_id === chatID
    );
    if (chatTags) {
      if (tags.length > 0) {
        this.selectedTags = this.selectedTags.map(chatTag =>
          chatTag.chat_id === chatID ? { chat_id: chatID, tags } : chatTag
        );
      } else {
        this.selectedTags = this.selectedTags.filter(
          chatTag => chatTag.chat_id !== chatID
        );
      }
    } else {
      const tag = { chat_id: chatID, tags };
      this.selectedTags.push(tag);
    }
  }

  selectTags(event, tag: ITag, chatID: string) {
    let chatTags = this.selectedTags.find(
      chatTag => chatTag.chat_id === chatID
    );
    if (event) {
      if (chatTags) {
        this.selectedTags = this.selectedTags.map(chatTag =>
          chatTag.chat_id === chatID
            ? { chat_id: chatID, tags: [...chatTag.tags, tag] }
            : chatTag
        );
      } else {
        const chtTags = { chat_id: chatID, tags: [tag] };
        this.selectedTags.push(chtTags);
      }
    } else {
      if (chatTags) {
        chatTags = {
          ...chatTags,
          tags: chatTags.tags.filter(item => tag.id !== item.id)
        };
        if (chatTags.tags === 0) {
          this.selectedTags = this.selectedTags.filter(
            chatTag => chatTag.id !== chatID
          );
        } else {
          this.selectedTags = this.selectedTags.map(chat =>
            chat.chat_id === chatID ? { ...chat, ...chatTags } : chat
          );
        }
      }
    }
    this.returnNumberOfSelecetedTags(chatID);
  }

  isTagSelected(tag: ITag, chatID) {
    const chat = this.selectedTags.find(cht => cht.chat_id === chatID);

    return chat && chat.tags
      ? chat.tags.filter(res => res.id === tag.id).length > 0
      : false;
  }

  returnNumberOfSelecetedTags(chatID: string) {
    const chat = this.selectedTags.find(cht => cht.chat_id === chatID);
    return chat && chat.tags ? chat.tags.length : 0;
  }

  setPrivateTagsEvent(tags: ITag[]) {
    this.showPrivateTagsModalEvent(false);
    this.selectedTags = [...tags];
  }
  removeTagEvent(id: string) {
    this.selectedTags = [...this.selectedTags.filter(tag => tag.id !== id)];
    this.currentSelectedTags = [
      ...this.currentSelectedTags.filter(tag => tag !== id)
    ];
  }
  createTagEvent(tag: ITag) {
    this._tagsService.createTag(tag);
  }

  /** Set Subgroups */

  showSubgroupsModalEvent(show: boolean, type?: string) {
    this.showSubgroupsModal = show;
    this.type = type;
    // this.menuButton.nativeElement.checked = false;
    this._toggleSubMenu.next(false);
  }
  setSubgroupsEvent(subgroups: IChat[]) {
    this.showSubgroupsModalEvent(false);
    if (subgroups.length > 0) {
      subgroups.map(item => {
        const chat = this.selectedSubgroups.find(
          chatGroup => chatGroup.id === item.id
        );
        if (!chat) {
          this._tagsService.getTagsListByID(item.id);
        }
      });
      this.selectedSubgroups = [...subgroups];
    }
  }
  removeSubgroupsEvent(id: string) {
    this.selectedSubgroups = this.selectedSubgroups.filter(
      grp => grp.id !== id
    );
  }

  removeTabsEvent(id: string) {
    this.selectedTabs = this.selectedTabs.filter(tab => tab.id !== id);
  }

  /** Selected  the selected */
  getSilentFlag(): boolean {
    return this.silentFlag;
  }
  getSelectedTags(chatID): string[] {
    if (this.selectedTags) {
      const chatTags = this.selectedTags.find(
        chatTag => chatTag.chat_id === chatID
      );
      if (chatTags) {
        return chatTags.tags.map(res => res.id);
      } else {
        return [];
      }
    }
    return [];
  }
  getSelectedSubgroups(): IChat[] {
    if (this.selectedSubgroups) {
      return this.selectedSubgroups.map(grp => grp);
    }
    return [];
  }

  getSelectedTab(): string {
    if (this.localSelectedTab) {
      return this.localSelectedTab.id;
    }
    return null;
  }

  onClick(event) {
    event.stopPropagation();
  }

  getNextPage() {
    this.list.nativeElement.scrollTo({
      left: this.list.nativeElement.scrollLeft + 300,
      behavior: 'smooth'
    });
  }

  getPreviousPage() {
    this.list.nativeElement.scrollTo({
      left: this.list.nativeElement.scrollLeft - 300,
      behavior: 'smooth'
    });
  }

  removeTabs(tab) {
    this.allTabs = this.allTabs.filter(res => res.id !== tab.id);
    if (tab.container_type === 'tab') {
      this.localSelectedTab = null;
    }
    if (
      tab.type === this.uiSections.SUBCHANNEL ||
      tab.type === this.uiSections.SUBGROUP
    ) {
      this.removeSubgroupsEvent(tab.id);
    }

    if (!tab.type && !tab.container_type) {
      this.removeTagEvent(tab.id);
    }
    if (this.list) {
      this.list.nativeElement.clientWidth < this.list.nativeElement.scrollWidth
        ? this._toggleMenu.next(true)
        : this._toggleMenu.next(false);
    }
  }

  onOpenChange(event) {
    this._toggleSubMenu.next(event);
  }

  removedTagsEvent(event: string[]) {
    this.allTabs = this.allTabs.filter(res => !event.includes(res.id));
  }

  removeSubgroupsChatsEvent(event: string[]) {
    this.allTabs = this.allTabs.filter(res => !event.includes(res.id));
  }
}
