import { Action } from '@ngrx/store';

import { IProfile } from 'src/models/IProfile';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ADMIN_PRIVILEGES } from 'src/models/constants';
import { UserBalance, UserProductBalance } from 'src/models/UserBalance';

export const enum ProfileActionTypes {
  CONTACTS_RECEIVED = 'CONTACTS_RECEIVED',
  CONTACT_DETAILS_RECEIVED = 'CONTACT_DETAILS_RECEIVED',
  UNKNOWN_PROFILE = 'UNKNOWN_PROFILE',
  THUMBNAIL_DOWNLOADED = 'THUMBNAIL_DOWNLOADED',
  IMAGE_DOWNLOADED = 'IMAGE_DOWNLOADED',
  PROFILE_INVALIDATED = 'PROFILE_INVALIDATED',
  DESELECT_PROFILE = 'DESELECT_PROFILE',
  CONTACT_DETAILS_FROM_CACHE = 'CONTACT_DETAILS_FROM_CACHE',
  CREATE_BOT = 'CREATE_BOT',
  BOT_CREATED = 'BOT_CREATED',
  UPDATE_BOT = 'UPDATE_BOT',
  UPDATE_BOT_STORE = 'UPDATE_BOT_STORE',
  UPLOAD_BOT_IMAGE = 'UPLOAD_BOT_IMAGE',
  ACTIVATE_BOT = 'ACTIVATE_BOT',
  REQUEST_BOT_TOKEN = 'REQUEST_BOT_TOKEN',
  DELETE_BOT = 'DELETE_BOT',
  MY_BOT_DELETED = 'MY_BOT_DELETED',
  BOT_ADDED_AS_ADMIN = 'BOT_ADDED_AS_ADMIN',
  CLONE_BOT_FROM_TEMPLATE = 'CLONE_BOT_FROM_TEMPLATE',
  UPDATE_ADMIN_PRIVILEGES = 'UPDATE_ADMIN_PRIVILEGES',
  PUBLISH_BOT = 'PUBLISH_BOT',
  RECEIVE_PUBLISH_BOT_ERROR = 'RECEIVE_PUBLISH_BOT_ERROR',
  REQUEST_USER_BALANCE = 'REQUEST_USER_BALANCE',
  REQUEST_USER_PRODUCT_BALANCE = 'REQUEST_USER_PRODUCT_BALANCE',
  USER_BALANCE_RECEIVED = 'USER_BALANCE_RECEIVED',
  GET_CONTACTS_NAMES = 'GET_CONTACTS_NAMES',
  CONTACTS_NAMES_RECEIVED = 'CONTACTS_NAMES_RECEIVED',
  USER_PRODUCT_BALANCE_RECEIVED = 'USER_PRODUCT_BALANCE_RECEIVED'
}

export const RESET = 'RESET';

export class ContactsReceived implements Action {
  readonly type = ProfileActionTypes.CONTACTS_RECEIVED;
  constructor(public payload: IProfile[]) {}
}

export class ContactDetailsReceived implements Action {
  readonly type = ProfileActionTypes.CONTACT_DETAILS_RECEIVED;
  constructor(public payload: IProfile) {}
}

export class ContactDetailsFromCache implements Action {
  readonly type = ProfileActionTypes.CONTACT_DETAILS_FROM_CACHE;
  constructor(public payload: IProfile) {}
}

export class UnknownProfile implements Action {
  readonly type = ProfileActionTypes.UNKNOWN_PROFILE;
  constructor(public userID: string, public unknownContact?: boolean) {}
}

export class ThumbnailDownloaded implements Action {
  readonly type = ProfileActionTypes.THUMBNAIL_DOWNLOADED;
  constructor(public payload: IProfile) {}
}

export class ImageDownloaded implements Action {
  readonly type = ProfileActionTypes.IMAGE_DOWNLOADED;
  constructor(public payload: IProfile) {}
}

export class ProfileInvalidated implements Action {
  readonly type = ProfileActionTypes.PROFILE_INVALIDATED;
  constructor(public payload: string) {}
}

export class DeselectProfile implements Action {
  readonly type = ProfileActionTypes.DESELECT_PROFILE;
  constructor(public id: string, public lastTypedMessage: string) {}
}

export class CreateBot implements Action {
  readonly type = ProfileActionTypes.CREATE_BOT;
  constructor(public name: string) {}
}

export class BotCreated implements Action {
  readonly type = ProfileActionTypes.BOT_CREATED;
  constructor(public userID: string, public name: string) {}
}

export class UpdateBot implements Action {
  readonly type = ProfileActionTypes.UPDATE_BOT;
  constructor(public botDetails: IProfile) {}
}

export class UpdateStore implements Action {
  readonly type = ProfileActionTypes.UPDATE_BOT_STORE;
  constructor(public botDetails: IProfile) {}
}

export class UploadBotImage implements Action {
  readonly type = ProfileActionTypes.UPLOAD_BOT_IMAGE;
  constructor(public bot, public file: ISelectedFiles) {}
}

export class ActivateBot implements Action {
  readonly type = ProfileActionTypes.ACTIVATE_BOT;
  constructor(public userID: string) {}
}

export class RequestBotToken implements Action {
  readonly type = ProfileActionTypes.REQUEST_BOT_TOKEN;
  constructor(public botId: string) {}
}

export class DeleteBot implements Action {
  readonly type = ProfileActionTypes.DELETE_BOT;
  constructor(public userID: string) {}
}

export class PublishBot implements Action {
  readonly type = ProfileActionTypes.PUBLISH_BOT;
  constructor(public id: string, public shortName?: string) {}
}

export class MyBotDeleted implements Action {
  readonly type = ProfileActionTypes.MY_BOT_DELETED;
  constructor(public userID: string) {}
}

export class BotAddedAsAdmin implements Action {
  readonly type = ProfileActionTypes.BOT_ADDED_AS_ADMIN;
  constructor(public userID: string) {}
}

export class CloneBotFromTemplate implements Action {
  readonly type = ProfileActionTypes.CLONE_BOT_FROM_TEMPLATE;
  constructor(public userID: string) {}
}

export class UpdateAdminPrivileges implements Action {
  readonly type = ProfileActionTypes.UPDATE_ADMIN_PRIVILEGES;
  constructor(public userID: string, public privileges: ADMIN_PRIVILEGES[]) {}
}

export class ReceivePublishBotError implements Action {
  readonly type = ProfileActionTypes.RECEIVE_PUBLISH_BOT_ERROR;
  constructor(public errorNumber: number) {}
}

export class RequestUserBalance implements Action {
  readonly type = ProfileActionTypes.REQUEST_USER_BALANCE;
  constructor(public userID: string) {}
}

export class RequestUserProductBalance implements Action {
  readonly type = ProfileActionTypes.REQUEST_USER_PRODUCT_BALANCE;
  constructor(public userID: string) {}
}

export class UserBalanceReceived implements Action {
  readonly type = ProfileActionTypes.USER_BALANCE_RECEIVED;
  constructor(public userID: string, public userBalanceList: UserBalance[]) {}
}

export class UserProductBalanceReceived implements Action {
  readonly type = ProfileActionTypes.USER_PRODUCT_BALANCE_RECEIVED;
  constructor(
    public userID: string,
    public userProductBalanceList: UserProductBalance[]
  ) {}
}

export class GetContactsNames implements Action {
  readonly type = ProfileActionTypes.GET_CONTACTS_NAMES;
  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type ProfileActions =
  | ContactsReceived
  | ContactDetailsReceived
  | UnknownProfile
  | ThumbnailDownloaded
  | ImageDownloaded
  | ProfileInvalidated
  | DeselectProfile
  | ContactDetailsFromCache
  | CreateBot
  | BotCreated
  | UpdateBot
  | UpdateStore
  | UploadBotImage
  | ActivateBot
  | RequestBotToken
  | DeleteBot
  | MyBotDeleted
  | BotAddedAsAdmin
  | CloneBotFromTemplate
  | UpdateAdminPrivileges
  | PublishBot
  | ReceivePublishBotError
  | RequestUserBalance
  | RequestUserProductBalance
  | UserBalanceReceived
  | UserProductBalanceReceived
  | Reset;
