import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { MyProfileMiddleware } from './myProfile.middleware';
import * as myProfileActions from './myProfile.actions';
import { MyProfileDispatchers } from './myProfile.dispatchers';

@Injectable()
export class MyProfileEffects {
  @Effect({ dispatch: false })
  myProfileReceived = this.actions$.pipe(
    ofType(myProfileActions.MyProfileActionTypes.MY_PROFILE_RECEIVING),
    map((action: myProfileActions.MyProfileReceiving) => {
      const profile = MyProfileMiddleware.initMyProfile(action.payload);
      this._myProfileDispatcher.myProfileReceived(profile);
    })
  );

  constructor(
    private actions$: Actions,
    private _myProfileDispatcher: MyProfileDispatchers
  ) {}
}
