import { CREATE_ARTICLE_METHOD } from './constants';

export interface InstantArticle {
  id?: string;
  reference?: number;
  title?: string;
  author?: string;
  content?: string;
  isInstant?: boolean;
  // preview links props
  linkPreviewUrl?: string;
  linkPreviewTitle?: string;
  linkPreviewStatus?: string;
  linkPreviewDescription?: string;
  linkPreviewImage?: string;
  linkPreviewWidth?: number;
  linkPreviewHeight?: number;
  linkPreviewRootUrl?: string;
}

export class GreateArticle {
  readonly method = CREATE_ARTICLE_METHOD;
  constructor(
    public title: string,
    public author: string,
    public content: string,
    public reference: number
  ) {}
}
