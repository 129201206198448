import { Action } from '@ngrx/store';
import {
  LocalAppConfig,
  LocalComponent,
  LocalContainer
} from '../../../models/ChannelAppLocalConfig';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { MessageTypes } from 'src/models/constants';
import { ImageSet } from 'src/models/ChannelAppConfig';

export const enum ComponentActionTypes {
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  REORDER_COMPONENT = 'REORDER_COMPONENT',
  ADD_COMPONENT_REQUEST = 'ADD_COMPONENT_REQUEST',
  ADD_COMPONENT = 'ADD_COMPONENT',
  OPEN_SECTION = 'OPEN_SECTION',
  CLOSE_SECTION = 'CLOSE_SECTION',
  DELETE_COMPONENT = 'DELETE_COMPONENT',
  DELETE_COMPONENT_REQUEST = 'DELETE_COMPONENT_REQUEST',
  DELETE_COMPONENTS_OF_CONTAINER = 'DELETE_COMPONENTS_OF_CONTAINER',
  ADD_COMPONENTS = 'ADD_COMPONENTS',
  DOWNLOAD_COMPONENT_MEDIA = 'DOWNLOAD_COMPONENT_MEDIA',
  UPLOAD_COMPONENT_MEDIA = 'UPLOAD_COMPONENT_MEDIA',
  COMPONENT_MEDIA_UPLOAD_SUCCESS = 'COMPONENT_MEDIA_UPLOAD_SUCCESS',
  COMPONENT_MEDIA_DOWNLOAD_SUCCESS = 'COMPONENT_MEDIA_DOWNLOAD_SUCCESS',
  SELECT_COMPONENT = 'SELECT_COMPONENT',
  UPLOAD_SPLASH_PAGE_LOGO = 'UPLOAD_SPLASH_PAGE_LOGO',
  UPLOAD_VIDEO_BACKGROUND = 'UPLOAD_VIDEO_BACKGROUND',
  UPLOAD_SPLASH_PAGE_LOGO_SUCCESS = 'UPLOAD_SPLASH_PAGE_LOGO_SUCCESS',
  RESET = 'RESET'
}

export class AppConfigDataChanged implements Action {
  readonly type = ComponentActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class UpdateComponent implements Action {
  readonly type = ComponentActionTypes.UPDATE_COMPONENT;
  constructor(
    public componentRef: string,
    public componentUpdatedProps: LocalComponent
  ) {}
}

export class OpenSection implements Action {
  readonly type = ComponentActionTypes.OPEN_SECTION;
  constructor(public component: LocalComponent) {}
}

export class CloseSection implements Action {
  readonly type = ComponentActionTypes.CLOSE_SECTION;
  constructor(public component: LocalComponent) {}
}

export class DeleteComponentRequest implements Action {
  readonly type = ComponentActionTypes.DELETE_COMPONENT_REQUEST;
  constructor(public component: LocalComponent) {}
}

export class DeleteComponent implements Action {
  readonly type = ComponentActionTypes.DELETE_COMPONENT;
  constructor(public component: LocalComponent) {}
}

export class DeleteComponentsOfContainer implements Action {
  readonly type = ComponentActionTypes.DELETE_COMPONENTS_OF_CONTAINER;
  constructor(public container: LocalContainer) {}
}

export class AddComponents implements Action {
  readonly type = ComponentActionTypes.ADD_COMPONENTS;
  constructor(public components: LocalComponent[]) {}
}

export class UploadComponentMedia implements Action {
  readonly type = ComponentActionTypes.UPLOAD_COMPONENT_MEDIA;
  constructor(
    public selectedFile: ISelectedFiles,
    public component: LocalComponent,
    public isBackground?: boolean
  ) {}
}

export class DownloadComponentMedia implements Action {
  readonly type = ComponentActionTypes.DOWNLOAD_COMPONENT_MEDIA;
  constructor(
    public imageId: string,
    public fileType: MessageTypes,
    public component: LocalComponent
  ) {}
}

export class ComponentMediaUploadSuccess implements Action {
  readonly type = ComponentActionTypes.COMPONENT_MEDIA_UPLOAD_SUCCESS;
  constructor(
    public imageUrl: string,
    public localImage: string,
    public component: LocalComponent,
    public isBackground?: boolean
  ) {}
}

export class ComponentMediaDownloadSuccess implements Action {
  readonly type = ComponentActionTypes.COMPONENT_MEDIA_DOWNLOAD_SUCCESS;
  constructor(public localImage: string, public component: LocalComponent) {}
}

export class ReorderComponent implements Action {
  readonly type = ComponentActionTypes.REORDER_COMPONENT;
  constructor(public componentRef: string, public newPosition: number) {}
}

export class AddComponentRequest implements Action {
  readonly type = ComponentActionTypes.ADD_COMPONENT_REQUEST;
  constructor(public container: any) {}
}

export class AddComponent implements Action {
  readonly type = ComponentActionTypes.ADD_COMPONENT;
  constructor(public component: LocalComponent) {}
}

export class SelectComponent implements Action {
  readonly type = ComponentActionTypes.SELECT_COMPONENT;
  constructor(public selectedComponent: LocalComponent) {}
}

export class UploadSplashPageLogo implements Action {
  readonly type = ComponentActionTypes.UPLOAD_SPLASH_PAGE_LOGO;
  constructor(
    public componentRef: string,
    public selectedFile: ISelectedFiles
  ) {}
}
export class UploadVideoBackground implements Action {
  readonly type = ComponentActionTypes.UPLOAD_VIDEO_BACKGROUND;
  constructor(
    public componentRef: string,
    public currentPage: string,
    public selectedFile: ISelectedFiles
  ) {}
}

export class UploadSplashPageLogoSuccess implements Action {
  readonly type = ComponentActionTypes.UPLOAD_SPLASH_PAGE_LOGO_SUCCESS;
  constructor(
    public componentRef: string,
    public imageUrl: string,
    public imageSet: ImageSet
  ) {}
}

export class Reset implements Action {
  readonly type = ComponentActionTypes.RESET;
}

export type Actions =
  | AppConfigDataChanged
  | UpdateComponent
  | OpenSection
  | CloseSection
  | DeleteComponent
  | DeleteComponentsOfContainer
  | AddComponents
  | UploadComponentMedia
  | DownloadComponentMedia
  | ComponentMediaUploadSuccess
  | ComponentMediaDownloadSuccess
  | ReorderComponent
  | AddComponentRequest
  | AddComponent
  | SelectComponent
  | UploadSplashPageLogo
  | UploadSplashPageLogoSuccess
  | UploadVideoBackground
  | Reset;
