import { Action } from '@ngrx/store';

import { IChat } from 'src/models/IChat';
import { IAuth, CountryData } from 'src/models/IAuth';

// RECIVE Part
export const REOPEN_NEW_CONNECTION = 'REOPEN_NEW_CONNECTION';

export const REQUEST_NEW_QRCODE = 'REQUEST_NEW_QRCODE';
export const REQUEST_QRCODE = 'REQUEST_QRCODE';
export const RECIVE_AUTH_QR = 'RECIVE_AUTH_QR';
export const RECIVE_AUTH_TOKEN = 'RECIVE_AUTH_TOKEN';
export const RECIVE_AUTH_TOKEN_CHANGE = 'RECIVE_AUTH_TOKEN_CHANGE';
export const RECIVE_AUTH_OK = 'RECIVED_AUTH_OK';
export const RECIVE_AUTH_NOT = 'RECIVE_AUTH_NOT';
export const RECIVE_MULTI_LOGIN = 'RECIVE_MULTI_LOGIN';
// SET Part
export const SET_AUTH_QR_EXPIRE = 'SET_AUTH_QR_EXPIRE';
// RESET Part
export const RESET = 'RESET';
export const LOGGED_OUT = 'LOGGED_OUT';

/* Merchant Web Only  */
export const RECIVE_AUTH_CHAT_LIST = 'RECIVE_AUTH_CHAT_LIST';
export const SHOW_AUTH_CHAT_LIST = 'SHOW_AUTH_CHAT_LIST';
export const SET_CHAT_SELECT = 'SET_CHAT_SELECT';
export const RECEIVE_AUTH_CHAT_SELECTED = 'RECEIVE_AUTH_CHAT_SELECTED';
// SIGN UP Part
export const GET_COUNTRY_DATA = 'GET_COUNTRY_DATA';
export const GET_CHAT_DATA = 'GET_CHAT_DATA';
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';
export const GET_EMAIL_TAC = 'GET_EMAIL_TAC';
export const EMAIL_TAC_SUCCESS = 'EMAIL_TAC_SUCCESS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const GET_TOKEN_ACTION = 'GET_TOKEN_ACTION';
export const EMAIL_TOKEN_RECEIVED = 'EMAIL_TOKEN_RECEIVED';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const SET_APP_PROGRESS = 'SET_APP_PROGRESS';
export const SET_APP_MODE = 'SET_APP_MODE';
export const LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL';
export const RECEIVE_ADMIN_QR = 'RECEIVE_ADMIN_QR';
export const REQUEST_ADMIN_QR = 'REQUEST_ADMIN_QR';
export const RECEIVE_AUTH_PRIVILEGE = 'RECEIVE_AUTH_PRIVILEGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD_SET = 'RECEIVE_CHANGE_PASSWORD_SET';
export const RECEIVE_CHANGE_PASSWORD_ERROR = 'RECEIVE_CHANGE_PASSWORD_ERROR';
export const SIGNUP_DEMO_ACCOUNT = 'SIGNUP_DEMO_ACCOUNT';
export const REGISTER_DEMO_ACCOUNT = 'REGISTER_DEMO_ACCOUNT';
export const GET_DEMO_ACCOUNT_STATUS = 'GET_DEMO_ACCOUNT_STATUS';
export const GET_CHAT_PRIVILLEGES = 'GET_CHAT_PRIVILLEGES';
export const GET_ROLES = 'GET_ROLES';
export const GET_MY_PROFILES = 'GET_MY_PROFILES';
export const GET_UPGRADED_CHAT_LIST_METHOD = 'GET_UPGRADED_CHAT_LIST_METHOD';
export const GET_SEARCH_FILTERS = 'GET_SEARCH_FILTERS';
export const GET_APPS_SETUP = 'GET_APPS_SETUP';
export const GET_BOTS = 'GET_BOTS';
export const RESEND_VER_CODE = 'RESEND_VER_CODE';
export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export const GET_BUSINESS_INFO = 'GET_BUSINESS_INFO';
export const RETURN_TO_SOCKET_INIT = 'RETURN_TO_SOCKET_INIT';
export const RESEAVE_BUSINESS_INFO_RESPONSE = 'RESEAVE_BUSINESS_INFO_RESPONSE';
export const CLOSE_SOCKET_INIT = 'CLOSE_SOCKET_INIT';
export const GET_CHAT_LIST = 'GET_CHAT_LIST';
export class ReopenNewConnection implements Action {
  readonly type = REOPEN_NEW_CONNECTION;
}

export class RequestNewQRcode implements Action {
  readonly type = REQUEST_NEW_QRCODE;
}

export class RequestQRcode implements Action {
  readonly type = REQUEST_QRCODE;
}

export class ReciveAuthQR implements Action {
  readonly type = RECIVE_AUTH_QR;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthToken implements Action {
  readonly type = RECIVE_AUTH_TOKEN;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthTokenChange implements Action {
  readonly type = RECIVE_AUTH_TOKEN_CHANGE;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthOK implements Action {
  readonly type = RECIVE_AUTH_OK;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthNot implements Action {
  readonly type = RECIVE_AUTH_NOT;
}

export class ReciveMultiLogin implements Action {
  readonly type = RECIVE_MULTI_LOGIN;
}

export class SetAuthQRExpire implements Action {
  readonly type = SET_AUTH_QR_EXPIRE;
}

export class LoggedOut implements Action {
  readonly type = LOGGED_OUT;
}
/* Merchant Web Only */
export class ReciveAuthChatList implements Action {
  readonly type = RECIVE_AUTH_CHAT_LIST;
  constructor(public payload: IChat[]) {}
}

export class ShowAuthChatList implements Action {
  readonly type = SHOW_AUTH_CHAT_LIST;
  constructor() {}
}
export class SetChatSelect implements Action {
  readonly type = SET_CHAT_SELECT;
  constructor(public payload: string, public is_auth?: number) {}
}

export class GetChatData implements Action {
  readonly type = GET_CHAT_DATA;
  constructor(public chat_id: string) {}
}
export class ReceiveAuthChatSelected implements Action {
  readonly type = RECEIVE_AUTH_CHAT_SELECTED;
  constructor(public payload: IAuth) {}
}

/* Channel App Sign Up*/
export class GetCountryData implements Action {
  readonly type = GET_COUNTRY_DATA;
}

export class SetCountryData implements Action {
  readonly type = SET_COUNTRY_DATA;
  constructor(public countryData: CountryData) {}
}

export class GetEmailTac implements Action {
  readonly type = GET_EMAIL_TAC;
  constructor(public email: string, public tacType?: number) {}
}

export class EmailTacSuccess implements Action {
  readonly type = EMAIL_TAC_SUCCESS;
  constructor(
    public email: string,
    public appName: string,
    public name: string,
    public password: string,
    public countryIso?: string
  ) {}
}

export class CreateAccount implements Action {
  readonly type = CREATE_ACCOUNT;
  constructor(public tac: string) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = CREATE_ACCOUNT_SUCCESS;
  constructor(public password: string) {}
}

export class GetTokenAction implements Action {
  readonly type = GET_TOKEN_ACTION;
  constructor(public email: string, public password: string) {}
}

export class EmailTokenReceived implements Action {
  readonly type = EMAIL_TOKEN_RECEIVED;
  constructor(public token: string) {}
}

export class SelectTemplate implements Action {
  readonly type = SELECT_TEMPLATE;
  constructor(public templateID: string) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export class SetAppProgress implements Action {
  readonly type = SET_APP_PROGRESS;
  constructor(public progressWeb: string, public mode?: number) {}
}

export class SetAppMode implements Action {
  readonly type = SET_APP_MODE;
  constructor(public webMode: number) {}
}

export class LoginByEmail implements Action {
  readonly type = LOGIN_BY_EMAIL;
}

export class ReceiveAdminQR implements Action {
  readonly type = RECEIVE_ADMIN_QR;
  constructor(public adminQR: string, public chatID: string) {}
}

export class RequestAdminQR implements Action {
  readonly type = REQUEST_ADMIN_QR;
}

export class ReceiveAuthPrivilege implements Action {
  readonly type = RECEIVE_AUTH_PRIVILEGE;
  constructor(public payload: string[], public reference?: string) {}
}

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;
  constructor(
    public payload: {
      oldPassword: string;
      newPassword: string;
      confirmNewPassword: string;
    }
  ) {}
}

export class ReceiveChangePasswordSet implements Action {
  readonly type = RECEIVE_CHANGE_PASSWORD_SET;
}

export class ReceiveChangePasswordError implements Action {
  readonly type = RECEIVE_CHANGE_PASSWORD_ERROR;
  constructor(public payload: number) {}
}

export class SignUpDemoAccount implements Action {
  readonly type = SIGNUP_DEMO_ACCOUNT;
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public email: string,
    public typ: string,
    public countryCode: string,
    public countryIso: string
  ) {}
}

export class RegisterDemoAccount implements Action {
  readonly type = REGISTER_DEMO_ACCOUNT;
  constructor(
    public deviceId: string,
    public os: string,
    public osVersion: string,
    public msisdn: string,
    public typ: string,
    public tac: string,
    public countryCode: string,
    public email?: string
  ) {}
}

export class GetDemoAccountStatus implements Action {
  readonly type = GET_DEMO_ACCOUNT_STATUS;
  constructor(public msisdn: string, public countryCode: string) {}
}

export class GetChatPrivilleges implements Action {
  readonly type = GET_CHAT_PRIVILLEGES;
  constructor() {}
}

export class GetRoles implements Action {
  readonly type = GET_ROLES;
  constructor() {}
}

export class GetMyProfiles implements Action {
  readonly type = GET_MY_PROFILES;
  constructor() {}
}

export class GetUpgradedChatList implements Action {
  readonly type = GET_UPGRADED_CHAT_LIST_METHOD;
  constructor(public is_default: boolean) {}
}

export class GetSearchFilters implements Action {
  readonly type = GET_SEARCH_FILTERS;
  constructor() {}
}

export class GetAppSetup implements Action {
  readonly type = GET_APPS_SETUP;
  constructor() {}
}

export class GetBots implements Action {
  readonly type = GET_BOTS;
  constructor() {}
}

export class ResendCode implements Action {
  readonly type = RESEND_VER_CODE;
  constructor(public msisdn: string, public countryCode: string) {}
}
export class SetBusinessInfo implements Action {
  readonly type = SET_BUSINESS_INFO;
  constructor(public company_name: string, public website?: string) {}
}
export class GetBusinessInfo implements Action {
  readonly type = GET_BUSINESS_INFO;
}
export class ReseaveBusinessInfoResponse implements Action {
  readonly type = RESEAVE_BUSINESS_INFO_RESPONSE;
  constructor(public company_name: string, public website?: string) {}
}

export class ReturnToInIt implements Action {
  readonly type = RETURN_TO_SOCKET_INIT;
  constructor() {}
}

export class CloseWs implements Action {
  readonly type = CLOSE_SOCKET_INIT;
  constructor() {}
}
export class GetChatList implements Action {
  readonly type = GET_CHAT_LIST;
  constructor(public upgraded?: boolean) {}
}

export type AuthActions =
  | ReopenNewConnection
  | ReciveAuthQR
  | ReciveAuthToken
  | ReciveAuthTokenChange
  | ReciveAuthOK
  | ReciveAuthNot
  | ReciveMultiLogin
  | SetAuthQRExpire
  | LoggedOut
  | ReciveAuthChatList
  | SetChatSelect
  | ReceiveAuthChatSelected
  | GetCountryData
  | SetCountryData
  | GetEmailTac
  | EmailTacSuccess
  | CreateAccount
  | CreateAccountSuccess
  | GetTokenAction
  | EmailTokenReceived
  | SelectTemplate
  | SetAppProgress
  | LoginByEmail
  | ReceiveAdminQR
  | RequestAdminQR
  | ReceiveAuthPrivilege
  | ChangePassword
  | ReceiveChangePasswordSet
  | ReceiveChangePasswordError
  | SignUpDemoAccount
  | RegisterDemoAccount
  | GetDemoAccountStatus
  | ResendCode
  | GetChatPrivilleges
  | GetMyProfiles
  | GetUpgradedChatList
  | GetSearchFilters
  | GetAppSetup
  | GetRoles
  | GetBots
  | ShowAuthChatList
  | RequestNewQRcode
  | ReseaveBusinessInfoResponse
  | ReturnToInIt
  | CloseWs
  | Reset;
