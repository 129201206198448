import * as tslib_1 from "tslib";
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { PageDirection } from 'src/models/constants';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { StoreMenuUiDispatchers } from './storeMenuUI.dispatchers';
import { CreateStoreMenuMethod, SetStoreMenuMethod, AddStoreMenuItemMethod, RemoveStoreMenuItemMethod, GetStoreMenusMethod, GetStoreMenuMethod, RemoveStoreMenuMethod } from 'src/models/StoreMenu';
import * as isEqual from 'lodash.isequal';
export class StoreMenuUiEffects {
    constructor(actions$, _socketGateway, _uiStoreMenuDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiStoreMenuDispatchers = _uiStoreMenuDispatchers;
        this._store = _store;
        this.createStoreMenu = this.actions$.pipe(ofType("CREATE_STOERE_MENU" /* CREATE_STOERE_MENU */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateStoreMenuMethod(action.storeMenusDetails));
        }));
        this.getStoreMenu = this.actions$.pipe(ofType("GET_STOERE_MENU" /* GET_STOERE_MENU */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetStoreMenuMethod(action.id));
        }));
        this.updateStoreMenu = this.actions$.pipe(ofType("SET_STORE_MENU" /* SET_STORE_MENU */), map((action) => {
            const storeMenuDetails = Object.assign({}, action.storeMenuDetails, { id: action.storeMenuID });
            this._socketGateway.sendSocketMessage(new SetStoreMenuMethod(storeMenuDetails));
        }));
        this.addStoreMenuItem = this.actions$.pipe(ofType("ADD_STORE_MENU_ITEM" /* ADD_STORE_MENU_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddStoreMenuItemMethod(action.storeMenuID, action.items));
        }));
        this.removeStoreMenuItem = this.actions$.pipe(ofType("REMOVE_STORE_MENU_ITEM" /* REMOVE_STORE_MENU_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveStoreMenuItemMethod(action.storeMenuID, action.itemIDs));
        }));
        //   //
        this.getNextStoreMenuPage = this.actions$.pipe(ofType("UI_STORE_MENU_GET_NEXT_PAGE" /* UI_STORE_MENU_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.storeMenuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, storeMenuUiState]) => {
            const action = val;
            const prevRequest = storeMenuUiState.previousRequest;
            if (!(storeMenuUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                storeMenuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetStoreMenusMethod(0, storeMenuUiState.eop));
            }
            else {
                this._uiStoreMenuDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevStoreMenuPage = this.actions$.pipe(ofType("UI_STORE_MENU_GET_PREV_PAGE" /* UI_STORE_MENU_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.storeMenuUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, storeMenuUiState]) => {
            const action = val;
            const prevRequest = storeMenuUiState.previousRequest;
            if (!(storeMenuUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                storeMenuUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetStoreMenusMethod(1, storeMenuUiState.sop));
            }
            else {
                this._uiStoreMenuDispatchers.setStatusAsIdle();
            }
        }));
        this.afterStoreMenuResetPaging = this.actions$.pipe(ofType("UI_STORE_MENU_RESET_PAGING" /* UI_STORE_MENU_RESET_PAGING */), map((action) => {
            this._uiStoreMenuDispatchers.getNextStoreMenuPage();
        }));
        this.deletePackage = this.actions$.pipe(ofType("DELETE_MENU" /* DELETE_MENU */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveStoreMenuMethod(action.menuID));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "createStoreMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "getStoreMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "updateStoreMenu", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "addStoreMenuItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "removeStoreMenuItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "getNextStoreMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "getPrevStoreMenuPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "afterStoreMenuResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreMenuUiEffects.prototype, "deletePackage", void 0);
