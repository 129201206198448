import * as MenuMethos from 'src/models/IBotMenu';

export class MenuUIMiddleware {
  public static isMenuExist(
    list: MenuMethos.BotMenu[],
    rcvdObj: MenuMethos.BotMenu
  ) {
    for (let i = 0; i < list.length; i++) {
      if (
        (rcvdObj.id && list[i].id === rcvdObj.id) ||
        (rcvdObj.reference && list[i].reference === rcvdObj.reference) ||
        (rcvdObj.menu_id && list[i].menu_id === rcvdObj.menu_id)
      ) {
        return true;
      }
    }
    return false;
  }
}
