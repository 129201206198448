/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../media-center-loading-cover/media-center-loading-cover.component.ngfactory";
import * as i3 from "../media-center-loading-cover/media-center-loading-cover.component";
import * as i4 from "@angular/common";
import * as i5 from "./media-center-video.component";
import * as i6 from "../media-center.service";
var styles_MediaCenterVideoComponent = [i0.styles];
var RenderType_MediaCenterVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterVideoComponent, data: {} });
export { RenderType_MediaCenterVideoComponent as RenderType_MediaCenterVideoComponent };
function View_MediaCenterVideoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-loading-cover", [], null, [[null, "hideMediaCover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hideMediaCover" === en)) {
        var pd_0 = (_co.hideCover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MediaCenterLoadingCoverComponent_0, i2.RenderType_MediaCenterLoadingCoverComponent)), i1.ɵdid(1, 638976, null, 0, i3.MediaCenterLoadingCoverComponent, [], { type: [0, "type"], size: [1, "size"], status: [2, "status"], progress: [3, "progress"] }, { hideMediaCover: "hideMediaCover" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mediaType; var currVal_1 = _co.fileSize; var currVal_2 = _co.mediaStatus; var currVal_3 = _co.progress; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_MediaCenterVideoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "media-center-photo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterVideoComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "video", [["class", "media-center-photo-img"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showCover && _co.localCoverStatus); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.media; _ck(_v, 3, 0, currVal_1); }); }
export function View_MediaCenterVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-video", [], null, null, null, View_MediaCenterVideoComponent_0, RenderType_MediaCenterVideoComponent)), i1.ɵdid(1, 49152, null, 0, i5.MediaCenterVideoComponent, [i6.MediaCenterService], null, null)], null, null); }
var MediaCenterVideoComponentNgFactory = i1.ɵccf("app-media-center-video", i5.MediaCenterVideoComponent, View_MediaCenterVideoComponent_Host_0, { url: "url", localMedia: "localMedia", localThumbnail: "localThumbnail", progress: "progress", mediaStatus: "mediaStatus", fileSize: "fileSize", height: "height", width: "width" }, {}, []);
export { MediaCenterVideoComponentNgFactory as MediaCenterVideoComponentNgFactory };
