import { Action } from '@ngrx/store/public_api';

export const enum AppChannelSettingsTypes {
  UI_SET_APP_CHANNEL_SETTINGS = 'UI_SET_APP_CHANNEL_SETTINGS',
  UI_RECEIVE_APP_CHANNEL_SETTINGS_HISTORY = 'UI_RECEIVE_APP_CHANNEL_SETTINGS_HISTORY',
  UI_GET_APP_CHANNEL_SETTINGS = 'UI_GET_APP_CHANNEL_SETTINGS'
}

export class SetAppChannelSettings implements Action {
  readonly type = AppChannelSettingsTypes.UI_SET_APP_CHANNEL_SETTINGS;
  constructor(public whiteListEnabled: boolean) {}
}

export class GetAppChannelSettings implements Action {
  readonly type = AppChannelSettingsTypes.UI_GET_APP_CHANNEL_SETTINGS;
}

export class ReceiveAppChannelSettingsHistory implements Action {
  readonly type =
    AppChannelSettingsTypes.UI_RECEIVE_APP_CHANNEL_SETTINGS_HISTORY;
  constructor(
    public whiteListEnabled?: boolean,
    public deep_link_schema?: string
  ) {}
}

export type Actions =
  | SetAppChannelSettings
  | ReceiveAppChannelSettingsHistory
  | GetAppChannelSettings;
