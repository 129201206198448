import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as AppReleaseActions from './appRelease.actions';
import { AppReleaseActionTypes } from './appRelease.actions';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

import * as AppReleasesMethods from 'src/models/AppRelease';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import {
  GetAppRealseVerData,
  SetAppleKeyCode
} from 'src/models/AppPublishInfo';
import { AppReleaseDispatchers } from './appRelease.dispatchers';
import * as isEqual from 'lodash.isequal';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class AppReleaseEffects {
  @Effect({ dispatch: false })
  listAppReleasesResetPaging = this.actions$.pipe(
    ofType(AppReleaseActionTypes.UI_RELEASE_RESET_PAGING),
    map((action: AppReleaseActions.ResetReleasePaging) => {
      this._appReleaseDispatchers.getNextPageRelease();
    })
  );

  @Effect({ dispatch: false })
  getNextBlackListPage = this.actions$.pipe(
    ofType(AppReleaseActionTypes.UI_RELEASE_RESET_PAGING),
    withLatestFrom(
      this._store
        .select(state => state.appReleaseReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, appReleaseUiReducer]) => {
      const action = <AppReleaseActions.GetNextPageRelease>val;
      const prevRequest = appReleaseUiReducer.previousRequest;
      if (
        !(
          appReleaseUiReducer.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          appReleaseUiReducer.hash === prevRequest.hash
        )
      ) {
        // this._socketGateway.sendSocketMessage(
        //   new GetBlackList(
        //     null,
        //     0,
        //     blackListUiReducer.hash,
        //     blackListUiReducer.eop,
        //   )
        // );
      } else {
        // this._uiBlackListDispatchers.setStatusAsIdle();
      }
    })
  );
  @Effect({ dispatch: false })
  getPrevBlackListPage = this.actions$.pipe(
    ofType(AppReleaseActionTypes.UI_RELEASE_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.appReleaseReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, appReleaseUiReducer]) => {
      const action = <AppReleaseActions.GetPrevPageRelease>val;
      const prevRequest = appReleaseUiReducer.previousRequest;
      if (
        !(
          appReleaseUiReducer.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          appReleaseUiReducer.hash === prevRequest.hash
        )
      ) {
        // this._socketGateway.sendSocketMessage(
        //   new GetBlackList(
        //     null,
        //     0,
        //     blackListUiReducer.hash,
        //     blackListUiReducer.eop,
        //   )
        // );
      } else {
        // this._uiBlackListDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  listAppReleases = this.actions$.pipe(
    ofType(AppReleaseActionTypes.LIST_APP_RELEASES),
    map((action: AppReleaseActions.ListAppReleases) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.ListAppRelease()
      );
    })
  );

  @Effect({ dispatch: false })
  listAppReleaseId = this.actions$.pipe(
    ofType(AppReleaseActionTypes.LIST_APP_RELEASE_ID),
    map((action: AppReleaseActions.ListAppReleaseId) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.ListAppReleaseId(action.payload.id)
      );
    })
  );

  @Effect({ dispatch: false })
  removeAppRelease = this.actions$.pipe(
    ofType(AppReleaseActionTypes.REMOVE_APP_RELEASE),
    map((action: AppReleaseActions.RemoveAppRelease) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.RemoveAppRelease(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  needAppleKey = this.actions$.pipe(
    ofType(AppReleaseActionTypes.SHOW_APPLE_KEY_FORM),
    map((action: AppReleaseActions.ShowAppleKeyForm) => {
      this._appMgmtService.showAppleForm(action.release_id);
    })
  );

  @Effect({ dispatch: false })
  sendAppleKeyCode = this.actions$.pipe(
    ofType(AppReleaseActionTypes.SEND_APPLE_KEY_CODE),
    map((action: AppReleaseActions.SendAppleKeyCode) => {
      this._socketGateway.sendSocketMessage(
        new SetAppleKeyCode(action.code, action.release_id)
      );
    })
  );

  @Effect({ dispatch: false })
  getAppRealseVerData = this.actions$.pipe(
    ofType(AppReleaseActionTypes.GET_APP_RELEASE_VER),
    map((action: AppReleaseActions.GetAppVer) => {
      this._socketGateway.sendSocketMessage(
        new GetAppRealseVerData(action.release_id + '')
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _appReleaseDispatchers: AppReleaseDispatchers,
    private _store: Store<AppState>,
    private _appMgmtService: AppMgmtService
  ) {}
}
