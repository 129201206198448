import { MessageMiddleware } from '../messages/message.middleware';
import * as MessageActions from './../messages/message.actions';
import { IMessage } from '../../../models/IMessage';
import { RIGHT, MessageSendingStatus } from '../../../models/constants';

export function scheduleReducer(
  state: IMessage[] = [],
  action: MessageActions.MessagesActions
): IMessage[] {
  switch (action.type) {
    case MessageActions.SCHEDULE_MESSAGE_RECEIVED:
      if (action.payload.schedule_date) {
        if (!MessageMiddleware.isMessageExisting(action.payload, state)) {
          return MessageMiddleware.sortMessages([action.payload, ...state]);
        } else {
          return MessageMiddleware.updateMessage(state, action.payload);
        }
      }
      return state;
    case MessageActions.SENDING_SCHEDULED_MESSAGE:
      if (!MessageMiddleware.isMessageExisting(action.payload, state)) {
        const msgToBeSent = action.payload;
        msgToBeSent.status = MessageSendingStatus.PENDING;
        msgToBeSent.position = RIGHT;
        msgToBeSent.date = msgToBeSent.reference;
        return state.length > 0 ? [...state, msgToBeSent] : [msgToBeSent];
      } else {
        return state.map(message => {
          if (
            (message.message_id &&
              message.message_id === action.payload.message_id) ||
            (message.reference &&
              message.reference === action.payload.reference)
          ) {
            let newMsg: IMessage = {};
            newMsg = action.payload;
            return { ...message, ...newMsg };
          }
          return message;
        });
      }
    case MessageActions.RECEIVE_MESSAGE:
    case MessageActions.SCHEDULE_MESSAGE_CANCELED_RECEIVED:
      return state.filter(
        msg =>
          !(
            msg.message_id === action.payload.message_id ||
            msg.reply_to_message_id === action.payload.message_id ||
            msg.reference === action.payload.reference
          )
      );
    /**********************************************************************************/
    /** Media section */
    case MessageActions.SET_LOCAL_THUMBNAIL:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.localThumbnail = action.payload.localThumbnail;
          newMsg.thumbnailStatus = action.payload.thumbnailStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_LOCAL_MEDIA:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.localMedia = action.payload.localMedia;
          if (action.payload.mediaStatus) {
            newMsg.mediaStatus = action.payload.mediaStatus;
          }
          if (action.payload.media_width) {
            newMsg.media_width = action.payload.media_width;
          }
          if (action.payload.media_height) {
            newMsg.media_height = action.payload.media_height;
          }
          if (action.payload.media_duration) {
            newMsg.media_duration = action.payload.media_duration;
          }
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_MESSAGE_MEDIA_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.mediaStatus = action.payload.mediaStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_MESSAGE_THUMBNAIL_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.thumbnailStatus = action.payload.thumbnailStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.UPDATE_LOADING_PROGRESS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.message.message_id) ||
          (message.reference && message.reference === action.message.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.loadingProgress = action.progress;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.CANCEL_DOWNLOAD_MEDIA_MESSAGE:
    case MessageActions.CANCEL_UPLOAD_MEDIA_MESSAGE:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.loadingProgress = 0;
          return { ...message, ...newMsg };
        }
        return message;
      });
    /********************************************************************************************************/
    /** Link Preview scetion */
    case MessageActions.SET_LINK_PREVIEW_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.LINK_PREVIEW_DETAILS_RECEIVED:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.linkPreviewTitle = action.payload.linkPreviewTitle;
          if (
            action.payload.linkPreviewDescription &&
            action.payload.linkPreviewDescription.length > 0
          ) {
            newMsg.linkPreviewDescription = action.payload.linkPreviewDescription.trim();
          }
          newMsg.linkPreviewImage = action.payload.linkPreviewImage;
          newMsg.linkPreviewWidth = action.payload.linkPreviewWidth;
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          newMsg.linkPreviewHeight = action.payload.linkPreviewHeight;
          newMsg.linkPreviewUrl = action.payload.linkPreviewUrl;
          newMsg.linkPreviewRootUrl = action.payload.linkPreviewRootUrl;
          return { ...message, ...newMsg };
        }
        return message;
      });
    /********************************************************************************************************/
    case MessageActions.DESELECT_CHANNEL:
    case MessageActions.RESET:
      return [];
    default:
      return state;
  }
}
