import {
  GET_CHAT_LABEL,
  SET_CHAT_LABEL,
  SET_TEMPLATE_FUNCTION,
  SET_TEMPLATE_MESSAGE
} from './constants';

export class SetTemplateFunctionMethod {
  readonly method = SET_TEMPLATE_FUNCTION;
  constructor(
    public function_id?: any,
    public template_id?: any,
    public function_name?: string,
    public param?: any
  ) {}
}

export class SetTemplateMessageMethod {
  readonly method = SET_TEMPLATE_MESSAGE;
  constructor(
    public function_id: any,
    public message_type: string,
    public message_id: string,
    public message: any,
    public template_id?: any
  ) {}
}

export class SetChatLabels {
  readonly method = SET_CHAT_LABEL;
  constructor(public chat_label?: any[]) {}
}

export class GetChatLabels {
  readonly method = GET_CHAT_LABEL;
  constructor() {}
}
