/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./compose-url-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../abstract-controls/reactive-text-input/reactive-text-input.component.ngfactory";
import * as i4 from "../../../../abstract-controls/reactive-text-input/reactive-text-input.component";
import * as i5 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i6 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i7 from "../../instant-article/instant-article-editor/instant-article-editor.component.ngfactory";
import * as i8 from "../../instant-article/instant-article-editor/instant-article-editor.component";
import * as i9 from "../../instant-article/instant-article.service";
import * as i10 from "../../../../network/gateway/upload.gateway";
import * as i11 from "../../../../core/file.service";
import * as i12 from "../../../../abstract-frames/popup-setting-frame/popup-setting-frame.component.ngfactory";
import * as i13 from "../../../../abstract-frames/popup-setting-frame/popup-setting-frame.component";
import * as i14 from "@angular/common";
import * as i15 from "@ngx-translate/core";
import * as i16 from "./compose-url-input.component";
import * as i17 from "../../../../validators/form-validators.service";
var styles_ComposeUrlInputComponent = [i0.styles];
var RenderType_ComposeUrlInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComposeUrlInputComponent, data: {} });
export { RenderType_ComposeUrlInputComponent as RenderType_ComposeUrlInputComponent };
function View_ComposeUrlInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "article"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose one of the following options."])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "article-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showInstantArticleEvent(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "article-btn-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-edit-pencil"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Write a new instant article "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "article-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleArticleLink(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "article-btn-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-features-icons.svg#icon-link-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Attach article link "]))], null, null); }
function View_ComposeUrlInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "form", [["form-body", ""], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-reactive-text-input", [], null, null, null, i3.View_ReactiveTextInputComponent_0, i3.RenderType_ReactiveTextInputComponent)), i1.ɵdid(8, 311296, null, 0, i4.ReactiveTextInputComponent, [], { fieldId: [0, "fieldId"], placeHolder: [1, "placeHolder"], filedType: [2, "filedType"], maxlength: [3, "maxlength"], control: [4, "control"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "form-group footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "form-submit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-border-btn", [["submit", ""]], null, null, null, i5.View_BorderBtnComponent_0, i5.RenderType_BorderBtnComponent)), i1.ɵdid(12, 573440, null, 0, i6.BorderBtnComponent, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(-1, 0, [" Send "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "app-border-btn", [["back", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.toggleArticleLink(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BorderBtnComponent_0, i5.RenderType_BorderBtnComponent)), i1.ɵdid(15, 573440, null, 0, i6.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_8 = "urlFiled"; var currVal_9 = "Enter your URL here"; var currVal_10 = "url"; var currVal_11 = 100; var currVal_12 = ((_co.form == null) ? null : _co.form.get("url")); _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "submit"; _ck(_v, 12, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ComposeUrlInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-instant-article-editor", [], null, [[null, "cancelNotfiy"], [null, "publishNotfiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelNotfiy" === en)) {
        var pd_0 = (_co.showInstantArticleEvent(false) !== false);
        ad = (pd_0 && ad);
    } if (("publishNotfiy" === en)) {
        var pd_1 = (_co.publishInstantArticleEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_InstantArticleEditorComponent_0, i7.RenderType_InstantArticleEditorComponent)), i1.ɵdid(2, 311296, null, 0, i8.InstantArticleEditorComponent, [i9.InstantArticleService, i10.UploadGateway, i11.FileService, i1.ChangeDetectorRef], null, { cancelNotfiy: "cancelNotfiy", publishNotfiy: "publishNotfiy" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ComposeUrlInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-popup-setting-frame", [["no-padding", ""]], null, [[null, "closeNotifier"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotifier" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_PopupSettingFrameComponent_0, i12.RenderType_PopupSettingFrameComponent)), i1.ɵdid(1, 114688, null, 0, i13.PopupSettingFrameComponent, [], { title: [0, "title"], hideFooter: [1, "hideFooter"] }, { closeNotifier: "closeNotifier" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ComposeUrlInputComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ComposeUrlInputComponent_3)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ComposeUrlInputComponent_4)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isInstant ? _co.headerPopupText : "Link a website"); var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((!_co.isArticleLink && !_co.showComposeInstance) && _co.isInstant); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.isArticleLink || !_co.isInstant); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.showComposeInstance; _ck(_v, 7, 0, currVal_4); }, null); }
function View_ComposeUrlInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "link-preview-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "input", [["type", "text"]], [[8, "placeholder", 0]], [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.showLinkPrivew($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i15.TranslatePipe, [i15.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PLEASEENTERYOURURLHERE")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ComposeUrlInputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposeUrlInputComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposeUrlInputComponent_5)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLink; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLink; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ComposeUrlInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-compose-url-input", [], null, null, null, View_ComposeUrlInputComponent_0, RenderType_ComposeUrlInputComponent)), i1.ɵdid(1, 114688, null, 0, i16.ComposeUrlInputComponent, [i9.InstantArticleService, i17.FormValidatorsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComposeUrlInputComponentNgFactory = i1.ɵccf("app-compose-url-input", i16.ComposeUrlInputComponent, View_ComposeUrlInputComponent_Host_0, { isInstant: "isInstant", isArticleLink: "isArticleLink", isLink: "isLink" }, { cancelNotfiy: "cancelNotfiy", resetNotfiy: "resetNotfiy", publishNotfiy: "publishNotfiy" }, []);
export { ComposeUrlInputComponentNgFactory as ComposeUrlInputComponentNgFactory };
