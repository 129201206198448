<div class="selectable-cell" *ngIf="!useNewStely">
  <!-- Image -->
  <!-- In case no background image-->
  <ng-container *ngIf="!coverPhoto">
    <div class="selectable-cell-img" [style.background]="coverColor">
      <svg class="svg-icon icon-icon">
        <use
          attr.xlink:href="./assets/svg/app-sections.svg#{{ symbolImage }}"
        ></use>
      </svg>
    </div>
  </ng-container>

  <!-- In case coverPhoto-->
  <ng-container *ngIf="coverPhoto">
    <div class="selectable-cell-img">
      <img [src]="coverPhoto" alt="" />
    </div>
  </ng-container>
  <!--------------------------------------->

  <!-- Symbol -->
  <div class="selectable-cell-symbol" [style.background]="symbolColor">
    <svg class="svg-icon icon-icon">
      <use
        attr.xlink:href="./assets/svg/app-sections.svg#{{ symbolImage }}"
      ></use>
    </svg>
  </div>

  <!-- Title & desc -->
  <div class="selectable-cell-title truncate-text" [innerHTML]="title"></div>
  <div *ngIf="desc" class="selectable-cell-details" [innerHTML]="desc"></div>
  <div *ngIf="upcCode" class="selectable-cell-upc">
    <ngx-barcode
      [bc-value]="upcCode"
      [bc-display-value]="true"
      [bc-font-size]="12"
      [bc-element-type]="'img'"
      [bc-background]="'transparent'"
      [bc-width]="1"
      [bc-height]="20"
      [bc-margin]="0"
      [bc-margin-top]="0"
      [bc-margin-bottom]="0"
      [bc-margin-right]="0"
      [bc-margin-left]="0"
    >
    </ngx-barcode>
  </div>

  <!-- checkbox -->
  <div class="selectable-cell-checkbox">
    <input
      type="checkbox"
      [id]="selectorId"
      [checked]="isSelected"
      (change)="checkChangedEvent($event)"
    />
    <label [for]="selectorId"></label>
  </div>
</div>

<div
  class="card-selector"
  [class.selected]="isSelected"
  [ngStyle]="cellSizeStyle"
  *ngIf="useNewStely"
>
  <div class="header">
    <div class="status">
      <ng-content select="[icon]"></ng-content>
      <ng-container *ngIf="status">
        <span
          class="dot"
          [class.online]="status === statusOptions?.ACTIVE"
          [class.offline]="status === statusOptions?.DEACTIVE"
        ></span>
        <ng-container *ngIf="status === statusOptions?.ACTIVE">
          {{ 'ONLINE' | translate }}
        </ng-container>
        <ng-container *ngIf="status === statusOptions?.DEACTIVE">
          {{ 'OFFLINE' | translate }}
        </ng-container>
      </ng-container>
    </div>
    <mat-checkbox
      [checked]="isSelected"
      (change)="checkChangedClick($event.checked)"
      *ngIf="!hideCheckBox"
    ></mat-checkbox>
    <svg
      class="svg-icon icon-delete"
      *ngIf="showDelete"
      (click)="deleteClicked()"
    >
      <use xlink:href="./assets/svg/app-extra-icons.svg#icon-delete-fill"></use>
    </svg>
  </div>
  <img *ngIf="coverPhoto && !upcCode" [src]="coverPhoto" [alt]="title" />
  <div class="placeholder-image" *ngIf="!coverPhoto && !upcCode">
    <svg class="svg-icon icon-icon ng-star-inserted">
      <use
        xlink:href="./assets/svg/app-extra-icons.svg#icon-wallpaper-black"
      ></use>
    </svg>
  </div>
  <ngx-barcode
    *ngIf="upcCode"
    [bc-value]="upcCode"
    [bc-display-value]="false"
    [bc-font-size]="12"
    [bc-element-type]="'img'"
    [bc-background]="'transparent'"
    [bc-width]="1"
    [bc-height]="55"
    [bc-margin]="0"
    [bc-margin-top]="0"
    [bc-margin-bottom]="0"
    [bc-margin-right]="0"
    [bc-margin-left]="0"
  >
  </ngx-barcode>
  <div class="title">{{ title }}</div>
  <div class="secand-style" *ngIf="price">{{ price }}</div>
</div>
