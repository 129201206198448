import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

export const enum SubChannelUIActionTypes {
  UI_LIST_SUB_CHANNEL = 'UI_LIST_SUB_CHANNEL',
  UI_CREATE_SUB_CHANNEL = 'UI_CREATE_SUB_CHANNEL',
  UI_VIEW_SUB_CHANNEL = 'UI_VIEW_SUB_CHANNEL',
  UI_EDIT_SUB_CHANNEL = 'UI_EDIT_SUB_CHANNEL',
  UPLOAD_SUB_CHANNEL_IMAGE = 'UPLOAD_SUB_CHANNEL_IMAGE',
  UI_UPLOAD_SUB_CHANNEL_PHOTO = 'UI_UPLOAD_SUB_CHANNEL_PHOTO',
  UI_SUB_CHANNEL_CREATION_COMPLETE = 'UI_SUB_CHANNEL_CREATION_COMPLETE',
  UI_CLOSE_VIEW_SUB_CHANNEL = 'UI_CLOSE_VIEW_SUB_CHANNEL',
  UI_CLOSE_EDIT_SUB_CHANNEL = 'UI_CLOSE_EDIT_SUB_CHANNEL',
  UI_SUB_CHANNEL_GET_NEXT_PAGE = 'UI_SUB_CHANNEL_GET_NEXT_PAGE',
  UI_SUB_CHANNEL_GET_PREV_PAGE = 'UI_SUB_CHANNEL_GET_PREV_PAGE',
  UI_SUB_CHANNEL_RECEIVED_HISTORY_CHUNK = 'UI_SUB_CHANNEL_RECEIVED_HISTORY_CHUNK',
  UI_SUB_CHANNEL_RESET_PAGING = 'UI_SUB_CHANNEL_RESET_PAGING',
  UI_SUB_CHANNEL_SET_IDLE_STATUS = 'UI_SUB_CHANNEL_SET_IDLE_STATUS',
  UI_SET_CHANNEL_CHAT = 'UI_SET_CHANNEL_CHAT',
  RESET = 'RESET'
}

export class ListSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_LIST_SUB_CHANNEL;
  constructor() {}
}
export class CreateSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_CREATE_SUB_CHANNEL;
}
export class ViewSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_VIEW_SUB_CHANNEL;
  constructor(public selectedSubChannel: IChat) {}
}
export class EditSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_EDIT_SUB_CHANNEL;
  constructor(public selectedSubChannel: IChat, public file?: any) {}
}

export class UploadSubChannelImage implements Action {
  readonly type = SubChannelUIActionTypes.UPLOAD_SUB_CHANNEL_IMAGE;
  constructor(public selectedFile) {}
}
export class SubChannelPhotoUploadUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_UPLOAD_SUB_CHANNEL_PHOTO;
  constructor(public createdSubChannel: IChat) {}
}

export class SubChannelCreationComplete implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_CREATION_COMPLETE;
}

export class CloseViewSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_CLOSE_VIEW_SUB_CHANNEL;
}
export class CloseEditSubChannelUI implements Action {
  readonly type = SubChannelUIActionTypes.UI_CLOSE_EDIT_SUB_CHANNEL;
}
//
// Paging Actions

export class GetNextSubChannelPage implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevSubChannelPage implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_PREV_PAGE;
  constructor() {}
}

// we can detect that by listening to the return of getsubchatlist of type Group or Channel
export class ReceivedSubChannelHistoryChunk implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChat[]
  ) {}
}

export class ResetSubChannelPaging implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = SubChannelUIActionTypes.UI_SUB_CHANNEL_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = SubChannelUIActionTypes.RESET;
}
export class SetChannelChat implements Action {
  readonly type = SubChannelUIActionTypes.UI_SET_CHANNEL_CHAT;
  constructor(public data: any) {}
}

export type Actions =
  | ListSubChannelUI
  | CreateSubChannelUI
  | ViewSubChannelUI
  | EditSubChannelUI
  | UploadSubChannelImage
  | SubChannelPhotoUploadUI
  | SubChannelCreationComplete
  | CloseViewSubChannelUI
  | CloseEditSubChannelUI
  | GetNextSubChannelPage
  | GetPrevSubChannelPage
  | ReceivedSubChannelHistoryChunk
  | ResetSubChannelPaging
  | SetStatusAsIdle
  | Reset;
