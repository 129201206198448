import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as PackageUiActions from './packagesUI.actions';

import { AppState } from 'src/models/AppState';
import { Package, PackageItem } from 'src/models/Package';

@Injectable({
  providedIn: 'root'
})
export class PackagesUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createPackage(packageDetails: Package): void {
    this._store.dispatch(new PackageUiActions.CreatePackage(packageDetails));
  }

  setPackage(packageID: string, packageDetails: Package): void {
    this._store.dispatch(
      new PackageUiActions.SetPackage(packageID, packageDetails)
    );
  }

  addPackageItems(packageID: string, itemsIds: PackageItem[]): void {
    this._store.dispatch(
      new PackageUiActions.AddPackageItems(packageID, itemsIds)
    );
  }

  removePackageBundles(packageID: string, bundlesIds: string[]): void {
    this._store.dispatch(
      new PackageUiActions.RemovePackageBundles(packageID, bundlesIds)
    );
  }

  packageReceived(pkg: Package) {
    this._store.dispatch(new PackageUiActions.PackageReceived(pkg));
  }

  // paging dispatchers
  getNextPackagesPage(): void {
    this._store.dispatch(new PackageUiActions.GetNextPackagesPage());
  }

  getPrevPackagesPage(): void {
    this._store.dispatch(new PackageUiActions.GetPrevPackagesPage());
  }

  deletePackages(id): void {
    this._store.dispatch(new PackageUiActions.DeletePackage(id));
  }
  packageDeleted(id: string): void {
    this._store.dispatch(new PackageUiActions.PackageDeleted(id));
  }

  receivedPackagesHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Package[]
  ): void {
    this._store.dispatch(
      new PackageUiActions.ReceivedPackagesHistoryChunk(eop, sop, currentPage)
    );
  }

  resetPackagesPaging(): void {
    this._store.dispatch(new PackageUiActions.ResetPackagesPaging());
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new PackageUiActions.SetStatusAsIdle());
  }
}
