import { Store } from '@ngrx/store';
import * as PaymentProvidersActions from './paymentProviders.actions';
import { PaymentProviderIDs } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class PaymentProvidersDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getPaymentProviders() {
        this._store.dispatch(new PaymentProvidersActions.GetPaymentProviders());
    }
    receivePaymentProviders(paymentProviders) {
        this._store.dispatch(new PaymentProvidersActions.ReceivePaymentProviders(paymentProviders));
    }
    receivePaymentProvider(providerId, config) {
        this._store.dispatch(new PaymentProvidersActions.ReceivePaymentProvider(providerId, config));
    }
    setPaymentProvider(providerId, config, active) {
        this._store.dispatch(new PaymentProvidersActions.SetPaymentProvider(providerId, config, active));
    }
    enableAllServices() {
        this._store.dispatch(new PaymentProvidersActions.EnableAllServices());
    }
}
PaymentProvidersDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentProvidersDispatchers_Factory() { return new PaymentProvidersDispatchers(i0.ɵɵinject(i1.Store)); }, token: PaymentProvidersDispatchers, providedIn: "root" });
