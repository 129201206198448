import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';

import { AppMgmtService } from '../../../appMgmt.service';
import {
  ChannelAppScreens,
  MimeTypes,
  BubbleStatus,
  ThemeStatus,
  TabTypes,
  UISections,
  regExpAnyLaunch,
  PaymentProviderIDs,
  LOGIN_TYPES
} from 'src/models/constants';
import { LocalContainer, LocalAppInfo } from 'src/models/ChannelAppLocalConfig';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import {
  SystemConfig,
  AppColors,
  AppInfo,
  AppClass
} from 'src/models/ChannelAppConfig';
import { AppJSON, AppPublishInfo } from 'src/models/AppPublishInfo';
import { IOption } from 'src/models/campaign';
import { MenusService } from './../../../../menus/menus.service';

import { BotMenu, BotMenuButton } from 'src/models/IBotMenu';

import { UiService } from './../../../../../../core/ui.service';
import { LayoutService } from './../../../../../+layout/layout.service';
import { AuthService } from 'src/app/core/auth.service';
import { PlansGateway } from 'src/app/network/gateway/plans.gateway';

@Component({
  selector: 'app-color-confg',
  templateUrl: './color-confg.component.html',
  styleUrls: ['./color-confg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorConfgComponent implements OnInit, OnChanges {
  persentColorHelper = `The top column is the primary colors and the bottom column is the secondary colors.`;
  textColorHelper = `You can check the suitability of the selected colors on the phone displayed`;
  iosIconColorHelper = `You can check the suitability of the selected colors on the phone displayed`;
  androidIconColorHelper = ` You can check the suitability of the selected colors on the phone displayed.`;
  customColorHelper = `Click on the color to enter a custom hex code.`;
  appIconHelper = `Note that your app logo must be in a PNG format and sized 1024x1024.`;
  appNameHelper = ` Make sure your app name stands out!.`;

  bubbleStatus = BubbleStatus;
  appScreens = ChannelAppScreens;
  MimeTypes = MimeTypes;
  @Input() showHelpContainer: boolean;
  @Input() hideNext = false;
  @Input() appClass: AppClass;
  @Input() subChannel = false;
  @Input() section: string;
  @Input() startover = false;
  @Input() mode: any;
  @Input() selectedChat: any;
  @Input() appIconOption: any;
  @Input() image: any;
  new = false;
  @Input() iconBg: any;
  uiSections = UISections;
  textColorHelp: boolean;
  helpText: string;
  iosIcon: boolean;
  customColor: boolean;
  androidIcon: boolean;
  presentTheme: boolean;
  appIcon: boolean;
  general: boolean;
  appName: boolean;
  div1Seleced: boolean;

  div2Seleced: boolean;

  div3Seleced: boolean;

  div4Seleced: boolean;

  div5Seleced: boolean;

  div6Seleced: boolean;

  div7Seleced: boolean;

  div8Seleced: boolean;
  div9Seleced: boolean;

  div10Seleced: boolean;

  div11Seleced: boolean;

  div12Seleced: boolean;

  div13Seleced: boolean;

  div14Seleced: boolean;
  @Input()
  systemConfg: SystemConfig;
  @Input() hideBackButton = true;
  appColors: AppColors = {};
  paletteOne: string[];
  paletteTwo: string[];
  paletteThree: string[];
  paletteFour: string[];
  paletteFive: string[];
  paletteSix: string[];
  paletteSeven: string[];
  paletteEight: string[];
  paletteNine: string[];
  paletteTen: string[];
  paletteEleven: string[];
  paletteTwolv: string[];
  paletteThreeten: string[];
  paletteFourten: string[];
  paletteFiveten: string[];
  paletteSixten: string[];
  paletteSeventen: string[];
  paletteEighten: string[];
  paletteNinten: string[];
  paletteTwinten: string[];
  paletteTwintenOne: string[];
  selectedPalette: string[];
  @Input()
  tabContainerList: LocalContainer[];
  @Input() itemList: any[];
  @Input() appInfo: AppInfo;
  @Input() mainMenu: BotMenu[];
  @Input() availableApps: IOption[];
  @Input() appSetupInfo: AppJSON;
  @Input() publishInfoData: AppPublishInfo;
  @Input() currentTabs: IOption[];
  @Input() newButtons: any[];
  @Output() helpNotify = new EventEmitter<string>();
  @Output() bubbleChangedNotify = new EventEmitter<string>();
  @Output() backNotify = new EventEmitter<boolean>();
  @Input() menusAppClass: AppClass;
  paymentProviderIDs = PaymentProviderIDs;
  loginTypes = LOGIN_TYPES;
  featuresIds: string[] = [];
  themeStatus = ThemeStatus;
  tabTypes = TabTypes;
  channelName: any;

  appNameError = false;
  appNameVlidationError = false;
  constructor(
    public appManagementService: AppMgmtService,
    public menusService: MenusService,
    public uiService: UiService,
    public layoutService: LayoutService,
    public authService: AuthService,
    public _ref: ChangeDetectorRef,
    public _planeGateway: PlansGateway
  ) {}

  ngOnChanges() {
    if (
      this.appInfo &&
      this.appIconOption &&
      this.image !== this.appIconOption.image
    ) {
      this.image = this.appIconOption.image;
      this.iconBg = this.appIconOption.dominantColor;
      this._ref.detectChanges();
    } else if (
      this.appInfo &&
      this.appIconOption &&
      this.image !== this.appInfo.image &&
      !this.new
    ) {
      this.image = this.appInfo.image;
      this.iconBg = this.appInfo.color_logo_bg;
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    } else if (
      !this.appIconOption &&
      this.appInfo &&
      this.image !== this.appInfo.image
    ) {
      this.image = this.appInfo.image;
      this.iconBg = this.appInfo.color_logo_bg;
      this.appIconOption = {};
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    }
    if (
      !this.appIconOption &&
      this.systemConfg &&
      this.systemConfg.app_info &&
      this.image !== this.systemConfg.app_info.image
    ) {
      this.channelName = this.systemConfg.app_info.channel_name;
      this.image = this.systemConfg.app_info.image;
      this.iconBg = this.systemConfg.app_info.color_logo_bg;
      this.appIconOption = {};
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    }

    if (
      this.publishInfoData &&
      this.publishInfoData.apps &&
      this.publishInfoData.apps.length > 0 &&
      this.availableApps &&
      this.availableApps.length > 0
    ) {
      this.appSetupInfo = this.publishInfoData.apps.find(
        res => res.app_id === this.availableApps[0].key
      );
    }
  }
  ngOnInit() {
    // const appInfo: AppInfo = {};
    // appInfo.layout = 'Menu';
    // this.appManagementService.updateAppInfo(appInfo);
    if (this.appInfo && this.appInfo.image) {
      this.image = this.appInfo.image;
      this.iconBg = this.appInfo.color_logo_bg;
      this.appIconOption = {};
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    }
    if (
      this.systemConfg &&
      this.systemConfg.app_info &&
      this.systemConfg.app_info.image &&
      (!this.appIconOption || (this.appIconOption && !this.appIconOption.image))
    ) {
      this.channelName = this.systemConfg.app_info.channel_name;
      this.image = this.systemConfg.app_info.image;
      this.iconBg = this.systemConfg.app_info.color_logo_bg;
      this.appIconOption = {};
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    } else if (
      !this.appIconOption &&
      this.systemConfg &&
      this.systemConfg.app_info
    ) {
      this.channelName = this.systemConfg.app_info.channel_name;
      this.image = this.systemConfg.app_info.image;
      this.iconBg = this.systemConfg.app_info.color_logo_bg;
      this.appIconOption = {};
      this.appIconOption.image = this.image;
      this.appIconOption.dominantColor = this.iconBg;
      this._ref.detectChanges();
    }

    // if (
    //   this.tabContainerList &&
    //   this.tabContainerList.length <= 0 &&
    //   this.menusAppClass &&
    //   this.menusAppClass.menus
    // ) {
    //   this.addTabs();
    // } else if (
    //   this.newButtons &&
    //   this.newButtons.length > 0 &&
    //   this.currentTabs &&
    //   this.currentTabs.length > 0
    // ) {
    //   this.addTab();
    // }
    this._ref.detectChanges();

    const newAppInfo: AppInfo = {};
    newAppInfo.menuTabs = [];
    this.appManagementService.updateAppInfo(newAppInfo);
    this.iosIcon = false;
    this.customColor = false;
    this.androidIcon = false;
    this.presentTheme = false;
    this.appIcon = false;
    this.general = false;
    this.appName = false;
    this.paletteOne = [
      '#075e54',
      '#128c7e',
      '#00342b',
      '#25d366',
      '#6bff96',
      '#00a038'
    ];
    this.paletteTwo = [
      '#665cac',
      '#9789de',
      '#36327c',
      '#54c0d4',
      '#8bf3ff',
      '#008fa3'
    ];
    this.paletteThree = [
      '#2f8819',
      '#64b94a',
      '#005a00',
      '#5cc928',
      '#92fd5e',
      '#199700'
    ];
    this.paletteFour = [
      '#4995be',
      '#7ec6f1',
      '#00678e',
      '#6cc1e3',
      '#a0f4ff',
      '#3491b1'
    ];
    this.paletteFive = [
      '#c1558b',
      '#f685bb',
      '#8e235e',
      '#ffc273',
      '#ffdf9e',
      '#e39632'
    ];
    this.paletteSix = [
      '#3c5898',
      '#6e84c9',
      '#002f69',
      '#9cb2ce',
      '#cee4ff',
      '#6d839d'
    ];
    this.paletteSeven = [
      '#222828',
      '#4a5050',
      '#000000',
      '#707fd2',
      '#a2aeff',
      '#3d53a0'
    ];
    this.paletteEight = [
      '#09091a',
      '#303140',
      '#000000',
      '#1fbad6',
      '#6aedff',
      '#008aa5'
    ];
    this.paletteNine = [
      '#54bb6f',
      '#87ee9e',
      '#188a43',
      '#024064',
      '#3f6b92',
      '#001a39'
    ];
    this.paletteTen = [
      '#da0504',
      '#ff5334',
      '#a00000',
      '#c7d392',
      '#fbffc3',
      '#96a264'
    ];
    this.paletteEleven = [
      '#38706d',
      '#669f9b',
      '#024442',
      '#ecbe58',
      '#fff088',
      '#b78e28'
    ];
    this.paletteTwolv = [
      '#3b5898',
      '#6d84c9',
      '#003069',
      '#f6ce2e',
      '#ffff65',
      '#bf9d00'
    ];
    this.paletteThreeten = [
      '#005dbf',
      '#69b3ff',
      '#0058cb',
      '#fa3c4c',
      '#ff7478',
      '#c00024'
    ];
    this.paletteFourten = [
      '#ff9e00',
      '#ffcf48',
      '#c66f00',
      '#009ece',
      '#5ecfff',
      '#00709d'
    ];
    this.paletteFiveten = [
      '#465892',
      '#7684c3',
      '#122f64',
      '#bbc1c6',
      '#edf4f9',
      '#8b9195'
    ];
    this.paletteSixten = [
      '#553833',
      '#af7468',
      '#2b120c',
      '#d7c688',
      '#fff9b8',
      '#a4965a'
    ];
    this.paletteSeventen = [
      '#517da2',
      '#81acd3',
      '#1f5173',
      '#6ba1cf',
      '#9dd2ff',
      '#38739e'
    ];
    this.paletteEighten = [
      '#b94445',
      '#f07470',
      '#840d1e',
      '#3d53a0',
      '#707fd2',
      '#002b71'
    ];
    this.paletteNinten = [
      '#8a49a1',
      '#bc77d3',
      '#5a1c72',
      '#2ca1c6',
      '#a0f4ff',
      '#3491b1'
    ];
    this.paletteTwinten = [
      '#66757f',
      '#94a4ae',
      '#3b4953',
      '#64b5f3',
      '#9ae7ff',
      '#2385c0'
    ];
    this.paletteTwintenOne = [
      '#ff9900',
      '#ffca47',
      '#c66a00',
      '#232f3e',
      '#4c5869',
      '#000518'
    ];
    this._ref.detectChanges();
  }
  // addTabs() {
  //   let mainMenu: BotMenu = {};
  //   let maianMenuAppClass: AppClass = {};

  //   mainMenu = this.menusAppClass.menus[0];

  //   maianMenuAppClass = this.menusAppClass;

  //   const menucontainer: LocalContainer = {};
  //   menucontainer.type = this.tabTypes.MENUS;
  //   menucontainer.title = mainMenu.menu_name;
  //   menucontainer.menu_id = mainMenu.menu_ref;
  //   this.appManagementService.addContainer(this.appClass, menucontainer);
  //   // let buttonId = 0;

  //   for (let i = 0; i < mainMenu.rows.length; i++) {
  //     for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
  //       const buttonContainer: LocalContainer = {};
  //       buttonContainer.type = '';
  //       buttonContainer.layout = 'button';
  //       buttonContainer.id = mainMenu.rows[i].buttons[j].button_id;

  //       buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
  //       buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

  //       this.appManagementService.addContainer(this.appClass, buttonContainer);
  //     }
  //   }
  // }

  // addTab() {
  //   for (let i = 0; i < this.newButtons.length; i++) {
  //     const buttonContainer: LocalContainer = {};
  //     buttonContainer.type = '';
  //     buttonContainer.layout = 'button';
  //     buttonContainer.id = this.newButtons[i].button_id;
  //     buttonContainer.title = this.newButtons[i].button_label;
  //     buttonContainer.title_ios = this.newButtons[i].button_label;
  //     this.appManagementService.addContainer(this.appClass, buttonContainer);
  //   }
  // }

  updateSystemColors(appColors) {
    this.appManagementService.updateAppColors(appColors);
  }

  selectPalette($event) {
    if ($event === 'paletteOne') {
      this.selectedPalette = this.paletteOne;
      this.appColors.primary = this.paletteOne[0];
      this.appColors.primary_light = this.paletteOne[1];
      this.appColors.primary_dark = this.paletteOne[2];
      this.appColors.secondary = this.paletteOne[3];
      this.appColors.secondary_light = this.paletteOne[4];
      this.appColors.secondary_dark = this.paletteOne[5];
    } else if ($event === 'paletteTwo') {
      this.selectedPalette = this.paletteTwo;
      this.appColors.primary = this.paletteTwo[0];
      this.appColors.primary_light = this.paletteTwo[1];
      this.appColors.primary_dark = this.paletteTwo[2];
      this.appColors.secondary = this.paletteTwo[3];
      this.appColors.secondary_light = this.paletteTwo[4];
      this.appColors.secondary_dark = this.paletteTwo[5];
    } else if ($event === 'paletteThree') {
      this.selectedPalette = this.paletteThree;
      this.appColors.primary = this.paletteThree[0];
      this.appColors.primary_light = this.paletteThree[1];
      this.appColors.primary_dark = this.paletteThree[2];
      this.appColors.secondary = this.paletteThree[3];
      this.appColors.secondary_light = this.paletteThree[4];
      this.appColors.secondary_dark = this.paletteThree[5];
    } else if ($event === 'paletteFour') {
      this.selectedPalette = this.paletteFour;
      this.appColors.primary = this.paletteFour[0];
      this.appColors.primary_light = this.paletteFour[1];
      this.appColors.primary_dark = this.paletteFour[2];
      this.appColors.secondary = this.paletteFour[3];
      this.appColors.secondary_light = this.paletteFour[4];
      this.appColors.secondary_dark = this.paletteFour[5];
    } else if ($event === 'paletteFive') {
      this.selectedPalette = this.paletteFive;
      this.appColors.primary = this.paletteFive[0];
      this.appColors.primary_light = this.paletteFive[1];
      this.appColors.primary_dark = this.paletteFive[2];
      this.appColors.secondary = this.paletteFive[3];
      this.appColors.secondary_light = this.paletteFive[4];
      this.appColors.secondary_dark = this.paletteFive[5];
    } else if ($event === 'paletteSix') {
      this.selectedPalette = this.paletteSix;
      this.appColors.primary = this.paletteSix[0];
      this.appColors.primary_light = this.paletteSix[1];
      this.appColors.primary_dark = this.paletteSix[2];
      this.appColors.secondary = this.paletteSix[3];
      this.appColors.secondary_light = this.paletteSix[4];
      this.appColors.secondary_dark = this.paletteSix[5];
    } else if ($event === 'paletteSeven') {
      this.selectedPalette = this.paletteSeven;
      this.appColors.primary = this.paletteSeven[0];
      this.appColors.primary_light = this.paletteSeven[1];
      this.appColors.primary_dark = this.paletteSeven[2];
      this.appColors.secondary = this.paletteSeven[3];
      this.appColors.secondary_light = this.paletteSeven[4];
      this.appColors.secondary_dark = this.paletteSeven[5];
    } else if ($event === 'paletteEight') {
      this.selectedPalette = this.paletteEight;
      this.appColors.primary = this.paletteEight[0];
      this.appColors.primary_light = this.paletteEight[1];
      this.appColors.primary_dark = this.paletteEight[2];
      this.appColors.secondary = this.paletteEight[3];
      this.appColors.secondary_light = this.paletteEight[4];
      this.appColors.secondary_dark = this.paletteEight[5];
    } else if ($event === 'paletteNine') {
      this.selectedPalette = this.paletteNine;
      this.appColors.primary = this.paletteNine[0];
      this.appColors.primary_light = this.paletteNine[1];
      this.appColors.primary_dark = this.paletteNine[2];
      this.appColors.secondary = this.paletteNine[3];
      this.appColors.secondary_light = this.paletteNine[4];
      this.appColors.secondary_dark = this.paletteNine[5];
    } else if ($event === 'paletteTen') {
      this.selectedPalette = this.paletteTen;
      this.appColors.primary = this.paletteTen[0];
      this.appColors.primary_light = this.paletteTen[1];
      this.appColors.primary_dark = this.paletteTen[2];
      this.appColors.secondary = this.paletteTen[3];
      this.appColors.secondary_light = this.paletteTen[4];
      this.appColors.secondary_dark = this.paletteTen[5];
    } else if ($event === 'paletteEleven') {
      this.selectedPalette = this.paletteEleven;
      this.appColors.primary = this.paletteEleven[0];
      this.appColors.primary_light = this.paletteEleven[1];
      this.appColors.primary_dark = this.paletteEleven[2];
      this.appColors.secondary = this.paletteEleven[3];
      this.appColors.secondary_light = this.paletteEleven[4];
      this.appColors.secondary_dark = this.paletteEleven[5];
    } else if ($event === 'paletteTwolv') {
      this.selectedPalette = this.paletteTwolv;
      this.appColors.primary = this.paletteTwolv[0];
      this.appColors.primary_light = this.paletteTwolv[1];
      this.appColors.primary_dark = this.paletteTwolv[2];
      this.appColors.secondary = this.paletteTwolv[3];
      this.appColors.secondary_light = this.paletteTwolv[4];
      this.appColors.secondary_dark = this.paletteTwolv[5];
    } else if ($event === 'paletteThreeten') {
      this.selectedPalette = this.paletteThreeten;
      this.appColors.primary = this.paletteThreeten[0];
      this.appColors.primary_light = this.paletteThreeten[1];
      this.appColors.primary_dark = this.paletteThreeten[2];
      this.appColors.secondary = this.paletteThreeten[3];
      this.appColors.secondary_light = this.paletteThreeten[4];
      this.appColors.secondary_dark = this.paletteThreeten[5];
    } else if ($event === 'paletteFourten') {
      this.selectedPalette = this.paletteFourten;
      this.appColors.primary = this.paletteFourten[0];
      this.appColors.primary_light = this.paletteFourten[1];
      this.appColors.primary_dark = this.paletteFourten[2];
      this.appColors.secondary = this.paletteFourten[3];
      this.appColors.secondary_light = this.paletteFourten[4];
      this.appColors.secondary_dark = this.paletteFourten[5];
    } else if ($event === 'palette15') {
      this.selectedPalette = this.paletteFiveten;
      this.appColors.primary = this.paletteFiveten[0];
      this.appColors.primary_light = this.paletteFiveten[1];
      this.appColors.primary_dark = this.paletteFiveten[2];
      this.appColors.secondary = this.paletteFiveten[3];
      this.appColors.secondary_light = this.paletteFiveten[4];
      this.appColors.secondary_dark = this.paletteFiveten[5];
    } else if ($event === 'palette16') {
      this.selectedPalette = this.paletteSixten;
      this.appColors.primary = this.paletteSixten[0];
      this.appColors.primary_light = this.paletteSixten[1];
      this.appColors.primary_dark = this.paletteSixten[2];
      this.appColors.secondary = this.paletteSixten[3];
      this.appColors.secondary_light = this.paletteSixten[4];
      this.appColors.secondary_dark = this.paletteSixten[5];
    } else if ($event === 'palette17') {
      this.selectedPalette = this.paletteSeventen;
      this.appColors.primary = this.paletteSeventen[0];
      this.appColors.primary_light = this.paletteSeventen[1];
      this.appColors.primary_dark = this.paletteSeventen[2];
      this.appColors.secondary = this.paletteSeventen[3];
      this.appColors.secondary_light = this.paletteSeventen[4];
      this.appColors.secondary_dark = this.paletteSeventen[5];
    } else if ($event === 'palette18') {
      this.selectedPalette = this.paletteEighten;
      this.appColors.primary = this.paletteEighten[0];
      this.appColors.primary_light = this.paletteEighten[1];
      this.appColors.primary_dark = this.paletteEighten[2];
      this.appColors.secondary = this.paletteEighten[3];
      this.appColors.secondary_light = this.paletteEighten[4];
      this.appColors.secondary_dark = this.paletteEighten[5];
    } else if ($event === 'palette19') {
      this.selectedPalette = this.paletteNinten;
      this.appColors.primary = this.paletteNinten[0];
      this.appColors.primary_light = this.paletteNinten[1];
      this.appColors.primary_dark = this.paletteNinten[2];
      this.appColors.secondary = this.paletteNinten[3];
      this.appColors.secondary_light = this.paletteNinten[4];
      this.appColors.secondary_dark = this.paletteNinten[5];
    } else if ($event === 'palette20') {
      this.selectedPalette = this.paletteTwinten;
      this.appColors.primary = this.paletteTwinten[0];
      this.appColors.primary_light = this.paletteTwinten[1];
      this.appColors.primary_dark = this.paletteTwinten[2];
      this.appColors.secondary = this.paletteTwinten[3];
      this.appColors.secondary_light = this.paletteTwinten[4];
      this.appColors.secondary_dark = this.paletteTwinten[5];
    } else if ($event === 'palette21') {
      this.selectedPalette = this.paletteTwintenOne;
      this.appColors.primary = this.paletteTwintenOne[0];
      this.appColors.primary_light = this.paletteTwintenOne[1];
      this.appColors.primary_dark = this.paletteTwintenOne[2];
      this.appColors.secondary = this.paletteTwintenOne[3];
      this.appColors.secondary_light = this.paletteTwintenOne[4];
      this.appColors.secondary_dark = this.paletteTwintenOne[5];
    }
    this.updateSystemColors(this.appColors);
  }
  disablePallettesSelection($event) {
    if (!$event) {
      this.div1Seleced = false;
      this.div2Seleced = false;
      this.div3Seleced = false;
      this.div4Seleced = false;
      this.div5Seleced = false;
      this.div6Seleced = false;
      this.div7Seleced = false;
      this.div8Seleced = false;
      this.div9Seleced = false;
      this.div10Seleced = false;
      this.div11Seleced = false;
      this.div12Seleced = false;
      this.div13Seleced = false;
      this.div14Seleced = false;
    }
  }
  get sysColors() {
    this.selectedPalette = [
      this.systemConfg.color.primary,
      this.systemConfg.color.primary_light,
      this.systemConfg.color.primary_dark,
      this.systemConfg.color.secondary,
      this.systemConfg.color.secondary_light,
      this.systemConfg.color.secondary_dark
    ];
    return this.selectedPalette;
  }

  openPreviewMedia(event: ISelectedFiles) {
    this.new = true;
    this.appManagementService.getDomaintColor(event);
    this.appManagementService.setUploadedIcon(event);
  }

  openPreviewMediaWhite(event: ISelectedFiles) {
    this.appManagementService.uploadWhiteLogo(event);
  }

  openPreviewMediaIos(event: ISelectedFiles) {
    this.appManagementService.uploadIosStoreLogo(event);
  }
  selectBubble(event) {
    const appInfo: AppInfo = {};
    appInfo.bubble_theme = event.target.value;
    this.appManagementService.updateAppInfo(appInfo);
  }
  get selectedBubble() {
    if (
      this.systemConfg.app_info.bubble_theme ===
      this.bubbleStatus.nandbox_bubble
    ) {
      return { 'background-color': '#E2FFC7' };
    } else if (
      this.systemConfg.app_info.bubble_theme ===
      this.bubbleStatus.whatsapp_bubble
    ) {
      return { 'background-color': '#E2FFC7' };
    } else if (
      this.systemConfg.app_info.bubble_theme === this.bubbleStatus.viber_bubble
    ) {
      return { 'background-color': '#B1E1F5' };
    } else if (
      this.systemConfg.app_info.bubble_theme ===
      this.bubbleStatus.darktheme_bubble
    ) {
      return { 'background-color': '#3570A7' };
    } else if (
      this.systemConfg.app_info.bubble_theme === this.bubbleStatus.wechat_bubble
    ) {
      return { 'background-color': '#A0E759' };
    } else if (
      this.systemConfg.app_info.bubble_theme ===
      this.bubbleStatus.telegram_bubble
    ) {
      return { 'background-color': '#EFFEDD' };
    }
  }
  showHelp(value) {
    this.helpNotify.emit(value);
  }

  onChangeColor(value: string) {
    const appIcon: any = {};
    this.new = true;
    if (this.appIconOption) {
      if (this.appIconOption.image) {
        appIcon.image = this.appIconOption.image;
      }
      if (this.appIconOption.imageId) {
        appIcon.imageId = this.appIconOption.imageId;
      }
      if (this.appIconOption.imageSet) {
        appIcon.imageSet = this.appIconOption.imageSet;
      }
    } else {
      appIcon.image = this.image;
    }
    appIcon.dominantColor = value;
    this.iconBg = value;
    this._ref.detectChanges();
    this.appManagementService._appIcon.next(appIcon);
    const appInfo: AppInfo = {};
    appInfo.color_logo_bg = value;
    this.appManagementService.updateAppInfo(appInfo);
  }
  bubbleChanged(event) {
    this.bubbleChangedNotify.emit(event);
  }

  showHelpItem(title: any, type) {
    if (type === 'textColor') {
      this.textColorHelp = true;
    } else if (type === 'iosIcon') {
      this.iosIcon = true;
    } else if (type === 'customColor') {
      this.customColor = true;
    } else if (type == 'androidIcon') {
      this.androidIcon = true;
    } else if (type === 'presentTheme') {
      this.presentTheme = true;
    } else if (type === 'appIcon') {
      this.appIcon = true;
    } else if (type === 'general') {
      this.general = true;
    } else if (type === 'appName') {
      this.appName = true;
    }
    this.helpText = title;
  }
  hideHelpItem(type) {
    if (type === 'textColor') {
      this.textColorHelp = false;
    } else if (type === 'iosIcon') {
      this.iosIcon = false;
    } else if (type === 'customColor') {
      this.customColor = false;
    } else if (type == 'androidIcon') {
      this.androidIcon = false;
    } else if (type === 'presentTheme') {
      this.presentTheme = false;
    } else if (type === 'appIcon') {
      this.appIcon = false;
    } else if (type === 'general') {
      this.general = false;
    } else if (type === 'appName') {
      this.appName = false;
    }
  }

  updateAppName(value) {
    if (value.trim() && regExpAnyLaunch.test(value)) {
      this.appNameError = false;
      this.appNameVlidationError = false;
      const appInfo: AppInfo = {};
      appInfo.channel_name = value.trim();
      this.appManagementService.updateAppInfo(appInfo);
    } else {
      this.appNameError = !value.trim() ? true : false;
      this.appNameVlidationError =
        !regExpAnyLaunch.test(value) && value.trim() ? true : false;
    }
  }

  saveApp(event) {
    // const tabHasVideo = this.tabContainerList.find(
    //   res => res.ui_module_id === '503'
    // );
    // const navHasVideo = this.itemList.find(res => res.ui_module_id === '503');
    // if (!tabHasVideo && !navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 0;
    //   appInfo.call_enabled = 0;
    //   this.appManagementService.updateAppInfo(appInfo);
    // } else if (tabHasVideo || navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 1;
    //   appInfo.call_enabled = 1;
    //   this.appManagementService.updateAppInfo(appInfo);
    // }

    this.tabContainerList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });
    this.itemList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });
    this.featuresIds = this.addExtraModules(this.featuresIds);
    if (this.section === this.uiSections.CHANNEL) {
      this.appManagementService.savePage(true, null, this.featuresIds);
    } else {
      this.appManagementService.savePage(false, null, this.featuresIds);
    }
    this.back();
  }
  back() {
    this.backNotify.emit(true);
  }
  setMode(e: Event) {
    e.stopPropagation();
    const buttons: IOption[] = [];
    if (this.appInfo.layout === 'Menu') {
      this.tabContainerList.map(tap => {
        if (tap.type !== 'menu') {
          const button: IOption = {};
          button.key = tap.id;
          button.value = tap.ref;
          buttons.push(button);
        }
      });
    }

    const newAppInfo: AppInfo = {};
    newAppInfo.menuTabs = buttons;
    this.appManagementService.updateAppInfo(newAppInfo);
    this.appManagementService.savePage(false);

    this.authService.updateProgressWeb('4', 1);
  }
  goToMenus() {
    this.authService.updateProgressWeb('9', 1);
  }
  showConfirmationMsg(e: Event) {
    e.stopPropagation();
    const buttons: IOption[] = [];
    if (this.appInfo.layout === 'Menu') {
      this.tabContainerList.map(tap => {
        if (tap.type !== 'menu') {
          const button: IOption = {};
          button.key = tap.id;
          button.value = tap.ref;
          buttons.push(button);
        }
      });
    }

    const newAppInfo: AppInfo = {};
    newAppInfo.menuTabs = buttons;
    this.appManagementService.updateAppInfo(newAppInfo);
    this.appManagementService.savePage(false);
    // this.appManagementService._buttonArray.next([...buttons]);
    this.goToMenus();
  }

  showNeedHelp(webSite) {
    // this.helpVideoVisibility = b;
    window.open(webSite);
  }

  addExtraModules(featuresIds) {
    const cashModuleId = '30105';
    const payPalModuleId = '30104';
    const stripeModuleId = '30100';
    const emailModuleId = '30305';
    const noLoginModuleId = '30311';
    const mobileModuleId = '30200';
    const googleAdsModuleId = '30103';
    const appRatingModuleId = '30304';

    if (this.appInfo && this.appInfo.payment_provider) {
      this.appInfo.payment_provider.forEach(provider => {
        if (provider === this.paymentProviderIDs.CASH) {
          featuresIds.push(cashModuleId);
        } else if (provider === this.paymentProviderIDs.PAYPAL) {
          featuresIds.push(payPalModuleId);
        } else if (provider === this.paymentProviderIDs.STRIPE) {
          featuresIds.push(stripeModuleId);
        }
      });
    }

    if (this.appInfo.login_type === this.loginTypes.EMAIL) {
      featuresIds.push(emailModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.NO_LOGIN) {
      featuresIds.push(noLoginModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.MSISDN) {
      featuresIds.push(mobileModuleId);
    }

    if (this.appInfo.show_submitting_app_review) {
      featuresIds.push(appRatingModuleId);
    }

    if (this.appInfo.ads_provider === this.paymentProviderIDs.GOOGLEPROVIDER) {
      featuresIds.push(googleAdsModuleId);
    }

    return featuresIds;
  }
}
