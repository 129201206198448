import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  HostListener
} from '@angular/core';

import { CellsService } from '../cells.service';
import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-file-cell',
  templateUrl: './file-cell.component.html',
  styleUrls: ['./file-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileCellComponent {
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  @Input() fileName: string;
  @Input() fileSize: number;
  @Input() mediaStatus: string;
  @Input() percent: number;
  @Input() localMedia: string;
  @Input() themeColor: string;

  @Output() retryNotify = new EventEmitter<boolean>(false);
  @Output() cancelNotify = new EventEmitter<boolean>(false);

  constructor(private _cellsService: CellsService) {}

  @HostListener('click', ['$event'])
  download(event: MouseEvent) {
    if (
      this.localMedia &&
      (this.mediaStatus === this._cellsService.MEDIA_DOWNLOADED ||
        this.mediaStatus === this._cellsService.MEDIA_UPLOADED)
    ) {
      const a: any = document.createElement('a');
      a.href = this.localMedia;
      a.download = this.fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        // window.URL.revokeObjectURL(this.localMedia);
      }, 0);
    }
  }

  get theme() {
    return {
      'border-top-color': this.themeColor,
      'border-right-color': this.themeColor
    };
  }
  get filethem() {
    return {
      color: this.themeColor
    };
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
