import { DomSanitizer } from '@angular/platform-browser';
import { MAP_API_KEY, MessageMediaStatus, BUBBLE_MIN_MEDIA_SIZE, BUBBLE_MAX_MEDIA_SIZE } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class CellsService {
    constructor(_sanitizer) {
        this._sanitizer = _sanitizer;
        this.currentPlayingMedia = null;
        this.MAP_API_KEY = MAP_API_KEY;
        this.GOOGLE_MAP_LINK = 'https://www.google.com.eg/maps/place/';
        this.MEDIA_UPLOADED = MessageMediaStatus.UPLOADED;
        this.MEDIA_DOWNLOADED = MessageMediaStatus.DOWNLOADED;
        this.GOOGLE_MAP_IMAGE_LINK = 'https://maps.googleapis.com/maps/api/staticmap?maptype=roadmap&markers=color:blue%7Clabel:S%7C';
    }
    resizeMedia(width, height) {
        const min = BUBBLE_MIN_MEDIA_SIZE;
        const max = BUBBLE_MAX_MEDIA_SIZE;
        const aspect = width / height;
        if (height < width) {
            if (height < min) {
                height = min;
                width = height * aspect;
            }
        }
        else if (width < min) {
            width = min;
            height = width / aspect;
        }
        if (height > width) {
            if (height > max) {
                height = max;
                width = height * aspect;
            }
        }
        else if (width > max) {
            width = max;
            height = width / aspect;
        }
        return [width, height];
    }
    getMedia(localMedia) {
        if (localMedia) {
            return this._sanitizer.bypassSecurityTrustUrl(localMedia);
        }
        return '';
    }
}
CellsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CellsService_Factory() { return new CellsService(i0.ɵɵinject(i1.DomSanitizer)); }, token: CellsService, providedIn: "root" });
