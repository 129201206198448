<app-headerless-closable-frame
  (closeNotify)="cancelEvent()"
  [showCancel]="false"
>
  <ng-container ngProjectAs="content">
    <div class="rows-container">
      <div class="title-container">
        <div class="container" *ngIf="isApp">
          <div class="title">
            Menu Style
          </div>
          <div class="sub-title">
            Please choose the menu style from the list below:
          </div>
        </div>
        <div class="container" *ngIf="!isApp">
          <div class="title">
            Poll Style
          </div>
          <div class="sub-title">
            Please choose the poll style from the list below:
          </div>
        </div>
        <div class="close">
          <img src="./assets/img/close-menu-row.png" (click)="cancelEvent()" />
        </div>
      </div>
      <div>
        <app-menu-row-item
          [button]="button"
          [menuStoreList]="mobileStoreService?.selectedMenuList$ | async"
          (styleSelectedNotify)="selectedStyle($event)"
          [length]="length"
          [isApp]="isApp"
        ></app-menu-row-item>
      </div>
      <div class="footer">
        <app-border-btn
          publish
          (btnClicked)="finalSelectedStyle()"
          [type]="'submit'"
        >
          Add
        </app-border-btn>
      </div>
    </div>
  </ng-container>
</app-headerless-closable-frame>
