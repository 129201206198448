import { Store } from '@ngrx/store';
import * as BulkActions from './bulk.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BulkDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    recordCreated(index, recordType, id, ref) {
        this._store.dispatch(new BulkActions.RecordCreated(index, recordType, id, ref));
    }
}
BulkDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BulkDispatchers_Factory() { return new BulkDispatchers(i0.ɵɵinject(i1.Store)); }, token: BulkDispatchers, providedIn: "root" });
