import { Action } from '@ngrx/store';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';

export const enum ChannelAppUIActionTypes {
  GET_NEXT_PAGE = 'GET_NEXT_PAGE',
  GET_PREVIOUS_PAGE = 'GET_PREVIOUS_PAGE',
  TOGGLE_ANDROID_VERSION = 'TOGGLE_ANDROID_VERSION',
  TOGGLE_IOS_VERSION = 'TOGGLE_IOS_VERSION',
  SELECT_TAB = 'SELECT_TAB',
  GO_TO_SPECIFIC_PAGE = 'GO_TO_SPECIFIC_PAGE',
  UPDATE_PUBLISH_BUTTON_FLAG = 'UPDATE_PUBLISH_BUTTON_FLAG',
  UPDATE_TOTAL_APP_CONF_FLAG = 'UPDATE_TOTAL_APP_CONF_FLAG',
  RESET = 'RESET'
}

export class GetNextPage implements Action {
  readonly type = ChannelAppUIActionTypes.GET_NEXT_PAGE;
}

export class GetPreviousPage implements Action {
  readonly type = ChannelAppUIActionTypes.GET_PREVIOUS_PAGE;
}

export class ToggleAndroidVersion implements Action {
  readonly type = ChannelAppUIActionTypes.TOGGLE_ANDROID_VERSION;
  constructor(public payload: boolean) {}
}

export class ToggleIosVersion implements Action {
  readonly type = ChannelAppUIActionTypes.TOGGLE_IOS_VERSION;
  constructor(public payload: boolean) {}
}

export class SelectTab implements Action {
  readonly type = ChannelAppUIActionTypes.SELECT_TAB;
  constructor(public selectedTab: LocalContainer) {}
}

export class GoToSpecificPage implements Action {
  readonly type = ChannelAppUIActionTypes.GO_TO_SPECIFIC_PAGE;
  constructor(public pageNumber: number) {}
}

export class UpdatePublishButtonFlag implements Action {
  readonly type = ChannelAppUIActionTypes.UPDATE_PUBLISH_BUTTON_FLAG;
  constructor(public canPublish: boolean) {}
}

export class UpdateTotalAppConfigFlag implements Action {
  readonly type = ChannelAppUIActionTypes.UPDATE_TOTAL_APP_CONF_FLAG;
  constructor(public totalAppConfig: boolean) {}
}

export class Reset implements Action {
  readonly type = ChannelAppUIActionTypes.RESET;
}

export type Actions =
  | GetNextPage
  | GetPreviousPage
  | ToggleAndroidVersion
  | ToggleIosVersion
  | SelectTab
  | GoToSpecificPage
  | UpdatePublishButtonFlag
  | UpdateTotalAppConfigFlag
  | Reset;
