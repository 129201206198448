import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { AppState } from 'src/models/AppState';

import * as CalendarUIActions from './calendarUI.actions';
import { CalendarUiDispatchers } from './calendarUI.dispatchers';
import {
  GetCalendars,
  CalendarTimetable,
  SetCalendar,
  GetCalendar,
  GetCalendarDetails,
  SetCalendarDetail,
  RemoveCalendarDetail,
  CreateCalendar
} from 'src/models/Calendar';
import * as isEqual from 'lodash.isequal';

@Injectable()
export class CalendarUiEffects {
  @Effect({ dispatch: false })
  createCalendar = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.CREATE_CALENDAR),
    map((action: CalendarUIActions.CreateCalendar) => {
      this._socketGateway.sendSocketMessage(
        new CreateCalendar(action.calendar)
      );
    })
  );

  @Effect({ dispatch: false })
  updateCalendar = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.UPDATE_CALENDAR),
    map((action: CalendarUIActions.UpdateCalendar) => {
      const calendar: CalendarTimetable = {
        ...action.calendar,
        id: action.calendarId
      };
      this._socketGateway.sendSocketMessage(new SetCalendar(calendar));
    })
  );

  @Effect({ dispatch: false })
  listReceived = this.actions$.pipe(
    ofType(
      CalendarUIActions.CalendarUIActionTypes.UI_CALENDAR_RECEIVED_HISTORY_CHUNK
    ),
    map((action: CalendarUIActions.ReceivedCalendarHistoryChunk) => {
      if (action.currentPage && action.currentPage.length > 0) {
        action.currentPage.forEach(calendar => {
          this._socketGateway.sendSocketMessage(new GetCalendar(calendar.id));
          this._socketGateway.sendSocketMessage(
            new GetCalendarDetails(calendar.id)
          );
        });
      }
    })
  );

  @Effect({ dispatch: false })
  setCalendarDetail = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.SET_CALENDAR_DETAIL),
    map((action: CalendarUIActions.SetCalendarDetails) => {
      this._socketGateway.sendSocketMessage(
        new SetCalendarDetail(action.calendar_id, action.data)
      );
    })
  );

  @Effect({ dispatch: false })
  removeDetail = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.DELETE_CALENDAR_DETAIL),
    map((action: CalendarUIActions.DeleteCalendarDetail) => {
      this._socketGateway.sendSocketMessage(
        new RemoveCalendarDetail(action.calendar_id, action.references)
      );
    })
  );
  @Effect({ dispatch: false })
  getNextCalendarPage = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.UI_CALENDAR_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.calendarUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, calendarUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCalendars(0, calendarUiState.hash, calendarUiState.eop)
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevCalendarPage = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.UI_CALENDAR_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.calendarUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, calendarUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCalendars(1, calendarUiState.hash, calendarUiState.sop)
      );
    })
  );

  @Effect({ dispatch: false })
  afterCalendarSectionSelection = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.UI_CALENDAR_LIST),
    map((action: CalendarUIActions.CalendarListUI) => {
      this._calendarDispatchers.getNextCalendarPage();
    })
  );

  @Effect({ dispatch: false })
  afterCalendarResetPaging = this.actions$.pipe(
    ofType(CalendarUIActions.CalendarUIActionTypes.UI_CALENDAR_RESET_PAGING),
    map((action: CalendarUIActions.ResetCalendarPaging) => {
      this._calendarDispatchers.getNextCalendarPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _calendarDispatchers: CalendarUiDispatchers
  ) {}
}
