import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';

@Component({
  selector: 'app-compose-schedule-input',
  templateUrl: './compose-schedule-input.component.html',
  styleUrls: ['./compose-schedule-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeScheduleInputComponent implements OnInit {
  isDatePickerKeyboardOpen = false;
  placement = 'bottom';
  minDate: Date = new Date();
  scheduleDate: Date = new Date();
  @Input() schedule_date: number = null;
  openTimePicker = false;
  openScheduleDate = false;
  datePickerConfig = {
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    minDate: new Date()
  };

  minutesArray;
  hoursArray;

  hour;
  minute;
  am;

  @Output() isScheduleDateValid = new EventEmitter<boolean>(false);
  @Output() isCancelNotify = new EventEmitter<boolean>(false);
  @Output() schedule = new EventEmitter<number>();

  @ViewChild('menuButtonTime', { static: false }) menuButtonTime: ElementRef;
  @ViewChild('menuTime', { static: false }) menuTime: ElementRef;
  @ViewChild('menuButtonDate', { static: false }) menuButtonDate: ElementRef;
  @ViewChild('menuDate', { static: false }) menuDate: ElementRef;
  constructor() {}

  ngOnInit() {
    this.minutesArray = [...new Array(60).keys()];
    this.hoursArray = [...new Array(12).keys()].map(number => number + 1);
    if (!this.schedule_date) {
      const time = new DatePipe('en-US').transform(
        this.scheduleDate,
        'hh:mm aa'
      );
      const splitTime = time.split(/[: ]/g);
      this.hour = splitTime[0];
      this.minute = splitTime[1];
      this.am = splitTime[2];
      const date = new DatePipe('en-US').transform(
        this.scheduleDate,
        'yyyy-MM-dd'
      );
      this.schedule_date = new Date(
        `${date} ${this.hour}:${this.minute} ${this.am}`
      ).getTime();
    } else {
      const date = new DatePipe('en-US').transform(
        this.schedule_date,
        'yyyy-MM-dd'
      );
      const time = new DatePipe('en-US').transform(
        this.schedule_date,
        'hh:mm aa'
      );
      const splitTime = time.split(/[: ]/g);
      this.hour = splitTime[0];
      this.minute = splitTime[1];
      this.am = splitTime[2];
      this.scheduleDate = new Date(date);
    }
  }

  setScheduleTimeMessage() {
    this.schedule_date = this.scheduleDate.getTime();
    this.toggleDatePickerKeyboard(false);
    this.isScheduleDateValid.emit(true);
  }

  toggleDatePickerKeyboard(value: boolean): void {
    this.isDatePickerKeyboardOpen = value;
  }

  resetScheduleDate() {
    this.schedule_date = null;
    this.toggleDatePickerKeyboard(false);
    this.isScheduleDateValid.emit(false);
  }

  getScheduleDate() {
    return this.schedule_date;
  }

  get isScheduleValid() {
    return this.scheduleDate.getTime() > new Date().getTime();
  }

  toggleTimePicker(e) {
    this.openTimePicker = e;
  }

  toggleScheduleDate(event) {
    this.openScheduleDate = event.target.checked;
  }

  setScheduleTime() {
    // this.schedule_date = this.scheduleDate.getTime();
    this.cancelClicked(false);
    this.isScheduleDateValid.emit(true);
    this.schedule.emit(this.schedule_date);
  }

  cancelClicked(e) {
    this.isCancelNotify.emit(e);
  }

  selectedHour(value) {
    if (value == this.hour) {
      return {
        'background-color': '#459FED',
        color: '#ffffff',
        'border-radius': '50%',
        width: '30px',
        height: '30px'
      };
    }
  }

  selectedMinute(value) {
    if (value == this.minute) {
      return {
        'background-color': '#459FED',
        color: '#ffffff',
        'border-radius': '50%',
        width: '30px',
        height: '30px'
      };
    }
  }

  selectedAM(value) {
    if (value == this.am) {
      return {
        'background-color': '#459FED',
        color: '#fff'
      };
    }
  }

  setTime(hour, minute, AM) {
    this.hour = hour ? hour : this.hour;
    this.minute = minute !== null ? minute : this.minute;
    this.am = AM || this.am;
    this.hour = hour && hour < 10 ? '0' + hour : this.hour;
    this.minute = minute !== null && minute < 10 ? '0' + minute : this.minute;
    const date = new DatePipe('en-US').transform(
      this.scheduleDate,
      'yyyy-MM-dd'
    );
    this.scheduleDate = new Date(
      `${date} ${this.hour}:${this.minute} ${this.am}`
    );
    this.schedule_date = new Date(
      `${date} ${this.hour}:${this.minute} ${this.am}`
    ).getTime();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.menuButtonTime && this.menuTime) {
      const btnTimeClicked = this.menuButtonTime.nativeElement.contains(
        event.target
      );
      const menuTimeClicked = this.menuTime.nativeElement.contains(
        event.target
      );
      if (!btnTimeClicked && !menuTimeClicked) {
        this.toggleTimePicker(false);
      }
    }
    if (this.menuButtonDate && this.menuDate) {
      const btnDateClicked = this.menuButtonDate.nativeElement.contains(
        event.target
      );
      const menuDateClicked = this.menuDate.nativeElement.contains(
        event.target
      );
      if (!btnDateClicked && !menuDateClicked) {
        this.openScheduleDate = false;
      }
    }
  }

  dateChanges(e) {
    this.scheduleDate = e;
    const date = new DatePipe('en-US').transform(
      this.scheduleDate,
      'yyyy-MM-dd'
    );
    this.schedule_date = new Date(
      `${date} ${this.hour}:${this.minute} ${this.am}`
    ).getTime();
    const time = new DatePipe('en-US').transform(
      this.schedule_date,
      'hh:mm aa'
    );
    const splitTime = time.split(/[: ]/g);
    this.hour = splitTime[0];
    this.minute = splitTime[1];
    this.am = splitTime[2];
  }
}
