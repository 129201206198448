import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from 'src/app/core/ui.service';
import {
  FeaturesCategories,
  TabTypes,
  FeaturesModule,
  MyListTabTypes,
  OpenChatTypes,
  CREATE_PAGE_REF,
  UISections,
  ModulesIDs,
  ChannelAppScreens,
  MENU_ONCLICK_ACTIONS
} from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { AppMgmtService } from '../../../appMgmt.service';
import { FeatureAction } from './../../../../../../../models/constants';
import { BotsService } from './../../../../bots/bots.service';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-features-sublist',
  templateUrl: './features-sublist.component.html',
  styleUrls: ['./features-sublist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesSublistComponent implements OnInit, OnChanges {
  @Input() modulesList: number[] = [];
  @Input() type: string;
  @Input() currentPage: any;
  @Input() appClass: any;
  @Input() inside = true;
  @Input() menus = false;
  @Input() selectedMenuButton: any;
  @Input() selectedMenuButtonTab: any;
  @Input() selectedMenuSection: any;
  @Input() currentMenu: any;
  @Input() currentCategory;
  @Output() pageTempNotify = new EventEmitter<any>();
  @Output() createPageTempNotify = new EventEmitter<any>();

  @Output()
  featureNotify = new EventEmitter<any>();
  @Output() showPageViewNotify = new EventEmitter<any>();
  @Input() mainTabs: any[];
  pageRef = CREATE_PAGE_REF;
  actionTypes = MENU_ONCLICK_ACTIONS;
  @Output() createNewMenuNotify = new EventEmitter<any>();
  appScreens = ChannelAppScreens;
  isProtectingDragAction = false;
  myPages = true;
  tempPages = true;
  subCategory = [];
  pageToView: any;
  showPagesView = false;
  categoryList: FeaturesModule[] = [];
  splash: FeaturesModule = {};
  login: FeaturesModule = {};
  profile: FeaturesModule = {};
  bubble: FeaturesModule = {};
  userVapps: FeaturesModule = {};
  calendar: FeaturesModule = {};
  invitation: FeaturesModule = {};
  qr: FeaturesModule = {};
  webview: FeaturesModule = {};
  map: FeaturesModule = {};
  booking: FeaturesModule = {};
  search: FeaturesModule = {};
  stripe: FeaturesModule = {};
  weAccept: FeaturesModule = {};
  payTabs: FeaturesModule = {};
  paypal: FeaturesModule = {};
  cash: FeaturesModule = {};
  video: FeaturesModule = {};
  googleAdds: FeaturesModule = {};
  workFlow: FeaturesModule = {};
  mobileWorkFlow: FeaturesModule = {};
  mStore: FeaturesModule = {};
  dinamicMenus: FeaturesModule = {};
  showMore = false;
  recentCalls: FeaturesModule = {};
  callsLog: FeaturesModule = {};
  activeChannels: FeaturesModule = {};
  postsFeeds: FeaturesModule = {};
  userChannels: FeaturesModule = {};
  contactsList: FeaturesModule = {};
  chatScreen: FeaturesModule = {};
  chatAdmin: FeaturesModule = {};
  chatGroup: FeaturesModule = {};
  digitalID: FeaturesModule = {};
  userBookings: FeaturesModule = {};
  userInvitations: FeaturesModule = {};
  channelsList: FeaturesModule = {};
  onlineGroupsList: FeaturesModule = {};
  channels: FeaturesModule = {};
  channelList: FeaturesModule = {};
  audioVideoCalling: FeaturesModule = {};
  contactList: FeaturesModule = {};
  blogFeeds: FeaturesModule = {};
  groupList: FeaturesModule = {};
  chatgroups: FeaturesModule = {};
  loyaltyCompetitions: FeaturesModule = {};
  membershipCard: FeaturesModule = {};
  bookingList: FeaturesModule = {};
  usersChannelsDashboard: FeaturesModule = {};
  textMediaMessenger: FeaturesModule = {};
  audioVideoMessenger: FeaturesModule = {};
  broadcast: FeaturesModule = {};
  posts: FeaturesModule = {};
  usersChannelsList: FeaturesModule = {};
  usersContactsList: FeaturesModule = {};
  usersGroupsList: FeaturesModule = {};
  chatbot: FeaturesModule = {};
  chat: FeaturesModule = {};
  groupChat: FeaturesModule = {};
  loyalty: FeaturesModule = {};
  membershipCARD: FeaturesModule = {};
  ticketValidate: FeaturesModule = {};
  ticketRedeem: FeaturesModule = {};
  usersBookingList: FeaturesModule = {};
  mapSearch: FeaturesModule = {};
  // video: FeaturesModule = {}
  usersBookingListItem929: FeatureAction = {};
  usersBookingListItem930: FeatureAction = {};
  callsLogItem1: FeatureAction = {};
  callsLogItem2: FeatureAction = {};
  callsLogItem3: FeatureAction = {};
  callsLogItem4: FeatureAction = {};
  callsLogItem5: FeatureAction = {};
  callsLogItem6: FeatureAction = {};
  callsLogItem7: FeatureAction = {};
  callsLogItem8: FeatureAction = {};
  callsLogItem9: FeatureAction = {};
  callsLogItem10: FeatureAction = {};
  callsLogItem11: FeatureAction = {};
  callsLogItem12: FeatureAction = {};
  callsLogItem13: FeatureAction = {};
  callsLogItem14: FeatureAction = {};
  callsLogItem15: FeatureAction = {};
  callsLogItem16: FeatureAction = {};
  callsLogItem17: FeatureAction = {};
  activeChannelsItem18: FeatureAction = {};
  activeChannelsItem19: FeatureAction = {};
  activeChannelsItem20: FeatureAction = {};
  activeChannelsItem21: FeatureAction = {};
  activeChannelsItem22: FeatureAction = {};
  activeChannelsItem23: FeatureAction = {};
  activeChannelsItem24: FeatureAction = {};
  activeChannelsItem25: FeatureAction = {};
  activeChannelsItem26: FeatureAction = {};
  activeChannelsItem27: FeatureAction = {};
  activeChannelsItem28: FeatureAction = {};
  activeChannelsItem29: FeatureAction = {};
  activeChannelsItem30: FeatureAction = {};
  activeChannelsItem31: FeatureAction = {};
  activeChannelsItem32: FeatureAction = {};
  activeChannelsItem33: FeatureAction = {};
  activeChannelsItem34: FeatureAction = {};
  activeChannelsItem35: FeatureAction = {};
  activeChannelsItem36: FeatureAction = {};
  activeChannelsItem37: FeatureAction = {};
  activeChannelsItem38: FeatureAction = {};
  activeChannelsItem39: FeatureAction = {};
  activeChannelsItem40: FeatureAction = {};
  activeChannelsItem41: FeatureAction = {};
  activeChannelsItem42: FeatureAction = {};
  activeChannelsItem43: FeatureAction = {};
  activeChannelsItem44: FeatureAction = {};
  activeChannelsItem45: FeatureAction = {};
  activeChannelsItem46: FeatureAction = {};
  activeChannelsItem47: FeatureAction = {};
  activeChannelsItem48: FeatureAction = {};
  activeChannelsItem49: FeatureAction = {};
  activeChannelsItem50: FeatureAction = {};
  activeChannelsItem51: FeatureAction = {};
  activeChannelsItem52: FeatureAction = {};
  activeChannelsItem53: FeatureAction = {};
  activeChannelsItem54: FeatureAction = {};
  activeChannelsItem55: FeatureAction = {};
  activeChannelsItem56: FeatureAction = {};
  activeChannelsItem57: FeatureAction = {};
  activeChannelsItem58: FeatureAction = {};
  activeChannelsItem59: FeatureAction = {};
  activeChannelsItem60: FeatureAction = {};
  activeChannelsItem61: FeatureAction = {};
  activeChannelsItem62: FeatureAction = {};
  activeChannelsItem63: FeatureAction = {};
  activeChannelsItem64: FeatureAction = {};
  activeChannelsItem65: FeatureAction = {};
  activeChannelsItem66: FeatureAction = {};
  activeChannelsItem67: FeatureAction = {};
  activeChannelsItem68: FeatureAction = {};
  activeChannelsItem69: FeatureAction = {};
  activeChannelsItem70: FeatureAction = {};
  activeChannelsItem71: FeatureAction = {};
  activeChannelsItem72: FeatureAction = {};
  activeChannelsItem73: FeatureAction = {};
  activeChannelsItem74: FeatureAction = {};
  activeChannelsItem75: FeatureAction = {};
  activeChannelsItem76: FeatureAction = {};
  activeChannelsItem77: FeatureAction = {};
  activeChannelsItem78: FeatureAction = {};
  activeChannelsItem79: FeatureAction = {};
  activeChannelsItem80: FeatureAction = {};
  activeChannelsItem81: FeatureAction = {};
  activeChannelsItem82: FeatureAction = {};
  activeChannelsItem83: FeatureAction = {};
  activeChannelsItem84: FeatureAction = {};
  activeChannelsItem85: FeatureAction = {};
  activeChannelsItem86: FeatureAction = {};
  activeChannelsItem87: FeatureAction = {};
  activeChannelsItem88: FeatureAction = {};
  activeChannelsItem89: FeatureAction = {};
  activeChannelsItem90: FeatureAction = {};
  activeChannelsItem91: FeatureAction = {};
  // activeChannelsItem92: FeatureAction = {};
  activeChannelsItem93: FeatureAction = {};
  textMediaMessengerItem94: FeatureAction = {};
  textMediaMessengerItem95: FeatureAction = {};
  textMediaMessengerItem96: FeatureAction = {};
  textMediaMessengerItem97: FeatureAction = {};
  textMediaMessengerItem98: FeatureAction = {};
  textMediaMessengerItem99: FeatureAction = {};
  textMediaMessengerItem100: FeatureAction = {};
  textMediaMessengerItem101: FeatureAction = {};
  textMediaMessengerItem102: FeatureAction = {};
  textMediaMessengerItem103: FeatureAction = {};
  textMediaMessengerItem104: FeatureAction = {};
  textMediaMessengerItem105: FeatureAction = {};
  textMediaMessengerItem106: FeatureAction = {};
  textMediaMessengerItem107: FeatureAction = {};
  textMediaMessengerItem108: FeatureAction = {};
  textMediaMessengerItem109: FeatureAction = {};
  textMediaMessengerItem110: FeatureAction = {};
  textMediaMessengerItem111: FeatureAction = {};
  textMediaMessengerItem112: FeatureAction = {};
  textMediaMessengerItem113: FeatureAction = {};
  textMediaMessengerItem114: FeatureAction = {};
  textMediaMessengerItem115: FeatureAction = {};
  textMediaMessengerItem116: FeatureAction = {};
  textMediaMessengerItem117: FeatureAction = {};
  textMediaMessengerItem118: FeatureAction = {};
  textMediaMessengerItem119: FeatureAction = {};
  textMediaMessengerItem120: FeatureAction = {};
  textMediaMessengerItem121: FeatureAction = {};
  textMediaMessengerItem122: FeatureAction = {};
  textMediaMessengerItem123: FeatureAction = {};
  textMediaMessengerItem124: FeatureAction = {};
  textMediaMessengerItem125: FeatureAction = {};
  textMediaMessengerItem126: FeatureAction = {};
  textMediaMessengerItem127: FeatureAction = {};
  textMediaMessengerItem128: FeatureAction = {};
  textMediaMessengerItem129: FeatureAction = {};
  textMediaMessengerItem130: FeatureAction = {};
  textMediaMessengerItem131: FeatureAction = {};
  textMediaMessengerItem132: FeatureAction = {};
  textMediaMessengerItem133: FeatureAction = {};
  textMediaMessengerItem134: FeatureAction = {};
  textMediaMessengerItem135: FeatureAction = {};
  textMediaMessengerItem136: FeatureAction = {};
  textMediaMessengerItem137: FeatureAction = {};
  textMediaMessengerItem138: FeatureAction = {};
  textMediaMessengerItem139: FeatureAction = {};
  textMediaMessengerItem140: FeatureAction = {};
  textMediaMessengerItem141: FeatureAction = {};
  textMediaMessengerItem142: FeatureAction = {};
  textMediaMessengerItem143: FeatureAction = {};
  textMediaMessengerItem144: FeatureAction = {};
  textMediaMessengerItem145: FeatureAction = {};
  textMediaMessengerItem146: FeatureAction = {};
  textMediaMessengerItem147: FeatureAction = {};
  textMediaMessengerItem148: FeatureAction = {};
  textMediaMessengerItem149: FeatureAction = {};
  textMediaMessengerItem150: FeatureAction = {};
  textMediaMessengerItem151: FeatureAction = {};
  textMediaMessengerItem152: FeatureAction = {};
  textMediaMessengerItem153: FeatureAction = {};
  textMediaMessengerItem154: FeatureAction = {};
  textMediaMessengerItem155: FeatureAction = {};
  textMediaMessengerItem156: FeatureAction = {};
  textMediaMessengerItem157: FeatureAction = {};
  textMediaMessengerItem158: FeatureAction = {};
  textMediaMessengerItem159: FeatureAction = {};
  textMediaMessengerItem160: FeatureAction = {};
  textMediaMessengerItem161: FeatureAction = {};
  textMediaMessengerItem162: FeatureAction = {};
  textMediaMessengerItem163: FeatureAction = {};
  audioVideoCallingItem164: FeatureAction = {};
  audioVideoCallingItem165: FeatureAction = {};
  audioVideoCallingItem166: FeatureAction = {};
  audioVideoCallingItem167: FeatureAction = {};
  audioVideoCallingItem168: FeatureAction = {};
  audioVideoCallingItem169: FeatureAction = {};
  audioVideoCallingItem170: FeatureAction = {};
  audioVideoCallingItem171: FeatureAction = {};
  audioVideoCallingItem172: FeatureAction = {};
  audioVideoCallingItem173: FeatureAction = {};
  audioVideoCallingItem174: FeatureAction = {};
  audioVideoCallingItem175: FeatureAction = {};
  audioVideoCallingItem176: FeatureAction = {};
  audioVideoCallingItem177: FeatureAction = {};
  audioVideoCallingItem178: FeatureAction = {};
  audioVideoCallingItem179: FeatureAction = {};
  audioVideoCallingItem180: FeatureAction = {};
  audioVideoCallingItem181: FeatureAction = {};
  audioVideoCallingItem182: FeatureAction = {};
  audioVideoCallingItem183: FeatureAction = {};
  audioVideoCallingItem184: FeatureAction = {};
  audioVideoCallingItem185: FeatureAction = {};
  audioVideoCallingItem186: FeatureAction = {};
  audioVideoCallingItem187: FeatureAction = {};
  audioVideoCallingItem188: FeatureAction = {};
  audioVideoCallingItem189: FeatureAction = {};
  audioVideoCallingItem190: FeatureAction = {};
  audioVideoCallingItem191: FeatureAction = {};
  audioVideoCallingItem192: FeatureAction = {};
  audioVideoCallingItem193: FeatureAction = {};
  audioVideoCallingItem194: FeatureAction = {};
  audioVideoCallingItem195: FeatureAction = {};
  audioVideoCallingItem196: FeatureAction = {};
  audioVideoCallingItem197: FeatureAction = {};
  audioVideoCallingItem198: FeatureAction = {};
  audioVideoCallingItem199: FeatureAction = {};
  audioVideoCallingItem200: FeatureAction = {};
  audioVideoCallingItem201: FeatureAction = {};
  audioVideoCallingItem202: FeatureAction = {};
  audioVideoCallingItem203: FeatureAction = {};
  audioVideoCallingItem204: FeatureAction = {};
  audioVideoCallingItem205: FeatureAction = {};
  audioVideoCallingItem206: FeatureAction = {};
  audioVideoCallingItem207: FeatureAction = {};
  audioVideoCallingItem208: FeatureAction = {};
  audioVideoCallingItem209: FeatureAction = {};
  audioVideoCallingItem210: FeatureAction = {};
  audioVideoCallingItem211: FeatureAction = {};
  audioVideoCallingItem212: FeatureAction = {};
  audioVideoCallingItem213: FeatureAction = {};
  audioVideoCallingItem214: FeatureAction = {};
  audioVideoCallingItem215: FeatureAction = {};
  audioVideoCallingItem216: FeatureAction = {};
  audioVideoCallingItem217: FeatureAction = {};
  audioVideoCallingItem218: FeatureAction = {};
  audioVideoCallingItem219: FeatureAction = {};
  audioVideoCallingItem220: FeatureAction = {};
  audioVideoCallingItem221: FeatureAction = {};
  audioVideoCallingItem222: FeatureAction = {};
  audioVideoCallingItem223: FeatureAction = {};
  audioVideoCallingItem224: FeatureAction = {};
  audioVideoCallingItem225: FeatureAction = {};
  audioVideoCallingItem226: FeatureAction = {};
  audioVideoCallingItem227: FeatureAction = {};
  audioVideoCallingItem228: FeatureAction = {};
  audioVideoCallingItem229: FeatureAction = {};
  audioVideoCallingItem230: FeatureAction = {};
  audioVideoCallingItem231: FeatureAction = {};
  audioVideoCallingItem232: FeatureAction = {};
  audioVideoCallingItem233: FeatureAction = {};
  audioVideoCallingItem234: FeatureAction = {};
  audioVideoCallingItem235: FeatureAction = {};
  audioVideoCallingItem236: FeatureAction = {};
  audioVideoCallingItem237: FeatureAction = {};
  audioVideoCallingItem238: FeatureAction = {};
  audioVideoCallingItem239: FeatureAction = {};
  audioVideoCallingItem240: FeatureAction = {};
  audioVideoCallingItem241: FeatureAction = {};
  audioVideoCallingItem242: FeatureAction = {};
  audioVideoCallingItem243: FeatureAction = {};
  audioVideoCallingItem244: FeatureAction = {};
  audioVideoCallingItem245: FeatureAction = {};
  audioVideoCallingItem246: FeatureAction = {};
  audioVideoCallingItem247: FeatureAction = {};
  audioVideoCallingItem248: FeatureAction = {};
  // postsFeedsItem324: FeatureAction = {};
  // postsFeedsItem325: FeatureAction = {};
  postsFeedsItem326: FeatureAction = {};
  postsFeedsItem327: FeatureAction = {};
  postsFeedsItem328: FeatureAction = {};
  postsFeedsItem329: FeatureAction = {};
  postsFeedsItem330: FeatureAction = {};
  postsFeedsItem331: FeatureAction = {};
  postsFeedsItem332: FeatureAction = {};
  postsFeedsItem333: FeatureAction = {};
  postsFeedsItem334: FeatureAction = {};
  postsFeedsItem335: FeatureAction = {};
  postsFeedsItem336: FeatureAction = {};
  postsFeedsItem337: FeatureAction = {};
  postsFeedsItem338: FeatureAction = {};
  postsFeedsItem339: FeatureAction = {};
  postsFeedsItem340: FeatureAction = {};
  postsFeedsItem341: FeatureAction = {};
  postsFeedsItem342: FeatureAction = {};
  postsFeedsItem343: FeatureAction = {};
  postsFeedsItem344: FeatureAction = {};
  postsFeedsItem345: FeatureAction = {};
  postsFeedsItem346: FeatureAction = {};
  postsFeedsItem347: FeatureAction = {};
  postsFeedsItem348: FeatureAction = {};
  postsFeedsItem349: FeatureAction = {};
  postsFeedsItem350: FeatureAction = {};
  postsFeedsItem351: FeatureAction = {};
  postsFeedsItem352: FeatureAction = {};
  postsFeedsItem353: FeatureAction = {};
  postsFeedsItem354: FeatureAction = {};
  postsFeedsItem355: FeatureAction = {};
  postsFeedsItem356: FeatureAction = {};
  postsFeedsItem357: FeatureAction = {};
  postsFeedsItem358: FeatureAction = {};
  postsFeedsItem359: FeatureAction = {};
  postsFeedsItem360: FeatureAction = {};
  postsFeedsItem361: FeatureAction = {};
  postsFeedsItem362: FeatureAction = {};
  postsFeedsItem363: FeatureAction = {};
  postsFeedsItem364: FeatureAction = {};
  postsFeedsItem365: FeatureAction = {};
  postsFeedsItem366: FeatureAction = {};
  postsFeedsItem367: FeatureAction = {};
  postsFeedsItem368: FeatureAction = {};
  postsFeedsItem369: FeatureAction = {};
  postsFeedsItem370: FeatureAction = {};
  postsFeedsItem371: FeatureAction = {};
  postsFeedsItem372: FeatureAction = {};
  postsFeedsItem373: FeatureAction = {};
  postsFeedsItem374: FeatureAction = {};
  postsFeedsItem375: FeatureAction = {};
  postsFeedsItem376: FeatureAction = {};
  postsFeedsItem377: FeatureAction = {};
  postsFeedsItem378: FeatureAction = {};
  postsFeedsItem379: FeatureAction = {};
  postsFeedsItem380: FeatureAction = {};
  postsFeedsItem381: FeatureAction = {};
  postsFeedsItem382: FeatureAction = {};
  postsFeedsItem383: FeatureAction = {};
  postsFeedsItem384: FeatureAction = {};
  postsFeedsItem385: FeatureAction = {};
  postsFeedsItem386: FeatureAction = {};
  postsFeedsItem387: FeatureAction = {};
  postsFeedsItem388: FeatureAction = {};
  postsFeedsItem389: FeatureAction = {};
  postsFeedsItem390: FeatureAction = {};
  postsFeedsItem391: FeatureAction = {};
  postsFeedsItem392: FeatureAction = {};
  postsFeedsItem393: FeatureAction = {};
  postsFeedsItem394: FeatureAction = {};
  postsFeedsItem395: FeatureAction = {};
  postsFeedsItem396: FeatureAction = {};
  postsFeedsItem397: FeatureAction = {};
  postsFeedsItem398: FeatureAction = {};
  postsFeedsItem399: FeatureAction = {};
  userChannelsItem399: FeatureAction = {};
  userChannelsItem400: FeatureAction = {};
  userChannelsItem401: FeatureAction = {};
  userChannelsItem402: FeatureAction = {};
  userChannelsItem403: FeatureAction = {};
  userChannelsItem404: FeatureAction = {};
  userChannelsItem405: FeatureAction = {};
  userChannelsItem406: FeatureAction = {};
  userChannelsItem407: FeatureAction = {};
  userChannelsItem408: FeatureAction = {};
  userChannelsItem409: FeatureAction = {};
  userChannelsItem410: FeatureAction = {};
  userChannelsItem411: FeatureAction = {};
  userChannelsItem412: FeatureAction = {};
  userChannelsItem413: FeatureAction = {};
  userChannelsItem414: FeatureAction = {};
  userChannelsItem415: FeatureAction = {};
  userChannelsItem416: FeatureAction = {};
  userChannelsItem417: FeatureAction = {};
  userChannelsItem418: FeatureAction = {};
  userChannelsItem419: FeatureAction = {};
  userChannelsItem420: FeatureAction = {};
  userChannelsItem421: FeatureAction = {};
  userChannelsItem422: FeatureAction = {};
  userChannelsItem423: FeatureAction = {};
  userChannelsItem424: FeatureAction = {};
  userChannelsItem425: FeatureAction = {};
  userChannelsItem426: FeatureAction = {};
  userChannelsItem427: FeatureAction = {};
  userChannelsItem428: FeatureAction = {};
  userChannelsItem429: FeatureAction = {};
  userChannelsItem430: FeatureAction = {};
  userChannelsItem431: FeatureAction = {};
  userChannelsItem432: FeatureAction = {};
  userChannelsItem433: FeatureAction = {};
  userChannelsItem434: FeatureAction = {};
  userChannelsItem435: FeatureAction = {};
  userChannelsItem436: FeatureAction = {};
  userChannelsItem437: FeatureAction = {};
  userChannelsItem438: FeatureAction = {};
  userChannelsItem439: FeatureAction = {};
  userChannelsItem440: FeatureAction = {};
  userChannelsItem441: FeatureAction = {};
  userChannelsItem442: FeatureAction = {};
  userChannelsItem443: FeatureAction = {};
  userChannelsItem444: FeatureAction = {};
  userChannelsItem445: FeatureAction = {};
  userChannelsItem446: FeatureAction = {};
  userChannelsItem447: FeatureAction = {};
  userChannelsItem448: FeatureAction = {};
  userChannelsItem449: FeatureAction = {};
  userChannelsItem450: FeatureAction = {};
  userChannelsItem451: FeatureAction = {};
  userChannelsItem452: FeatureAction = {};
  userChannelsItem453: FeatureAction = {};
  userChannelsItem454: FeatureAction = {};
  userChannelsItem455: FeatureAction = {};
  userChannelsItem456: FeatureAction = {};
  userChannelsItem457: FeatureAction = {};
  userChannelsItem458: FeatureAction = {};
  userChannelsItem459: FeatureAction = {};
  userChannelsItem460: FeatureAction = {};
  userChannelsItem461: FeatureAction = {};
  userChannelsItem462: FeatureAction = {};
  userChannelsItem463: FeatureAction = {};
  userChannelsItem464: FeatureAction = {};
  userChannelsItem465: FeatureAction = {};
  userChannelsItem466: FeatureAction = {};
  userChannelsItem467: FeatureAction = {};
  userChannelsItem468: FeatureAction = {};
  userChannelsItem469: FeatureAction = {};
  userChannelsItem470: FeatureAction = {};
  userChannelsItem471: FeatureAction = {};
  userChannelsItem472: FeatureAction = {};
  userChannelsItem473: FeatureAction = {};
  userChannelsItem474: FeatureAction = {};
  userChannelsItem475: FeatureAction = {};
  userChannelsItem476: FeatureAction = {};
  contactsListItem477: FeatureAction = {};
  contactsListItem478: FeatureAction = {};
  contactsListItem479: FeatureAction = {};
  contactsListItem480: FeatureAction = {};
  contactsListItem481: FeatureAction = {};
  contactsListItem482: FeatureAction = {};
  contactsListItem483: FeatureAction = {};
  contactsListItem484: FeatureAction = {};
  contactsListItem485: FeatureAction = {};
  contactsListItem486: FeatureAction = {};
  contactsListItem487: FeatureAction = {};
  contactsListItem488: FeatureAction = {};
  contactsListItem489: FeatureAction = {};
  contactsListItem490: FeatureAction = {};
  contactsListItem491: FeatureAction = {};
  contactsListItem492: FeatureAction = {};
  contactsListItem493: FeatureAction = {};
  contactsListItem494: FeatureAction = {};
  contactsListItem495: FeatureAction = {};
  contactsListItem496: FeatureAction = {};
  contactsListItem497: FeatureAction = {};
  contactsListItem498: FeatureAction = {};
  contactsListItem499: FeatureAction = {};
  contactsListItem500: FeatureAction = {};
  contactsListItem501: FeatureAction = {};
  contactsListItem502: FeatureAction = {};
  contactsListItem503: FeatureAction = {};
  contactsListItem504: FeatureAction = {};
  contactsListItem505: FeatureAction = {};
  contactsListItem506: FeatureAction = {};
  contactsListItem507: FeatureAction = {};
  contactsListItem508: FeatureAction = {};
  contactsListItem509: FeatureAction = {};
  contactsListItem510: FeatureAction = {};
  contactsListItem511: FeatureAction = {};
  contactsListItem512: FeatureAction = {};
  contactsListItem513: FeatureAction = {};
  contactsListItem514: FeatureAction = {};
  contactsListItem515: FeatureAction = {};
  contactsListItem516: FeatureAction = {};
  contactsListItem517: FeatureAction = {};
  contactsListItem518: FeatureAction = {};
  contactsListItem519: FeatureAction = {};
  contactsListItem520: FeatureAction = {};
  contactsListItem521: FeatureAction = {};
  contactsListItem522: FeatureAction = {};
  contactsListItem523: FeatureAction = {};
  contactsListItem524: FeatureAction = {};
  contactsListItem525: FeatureAction = {};
  contactsListItem526: FeatureAction = {};
  contactsListItem527: FeatureAction = {};
  groupListItem528: FeatureAction = {};
  groupListItem529: FeatureAction = {};
  groupListItem530: FeatureAction = {};
  groupListItem531: FeatureAction = {};
  groupListItem532: FeatureAction = {};
  groupListItem533: FeatureAction = {};
  groupListItem534: FeatureAction = {};
  groupListItem535: FeatureAction = {};
  groupListItem536: FeatureAction = {};
  groupListItem537: FeatureAction = {};
  groupListItem538: FeatureAction = {};
  groupListItem539: FeatureAction = {};
  groupListItem540: FeatureAction = {};
  groupListItem541: FeatureAction = {};
  groupListItem542: FeatureAction = {};
  groupListItem543: FeatureAction = {};
  groupListItem544: FeatureAction = {};
  groupListItem545: FeatureAction = {};
  groupListItem546: FeatureAction = {};
  groupListItem547: FeatureAction = {};
  groupListItem548: FeatureAction = {};
  groupListItem549: FeatureAction = {};
  groupListItem550: FeatureAction = {};
  groupListItem551: FeatureAction = {};
  groupListItem552: FeatureAction = {};
  groupListItem553: FeatureAction = {};
  groupListItem554: FeatureAction = {};
  groupListItem555: FeatureAction = {};
  groupListItem556: FeatureAction = {};
  groupListItem557: FeatureAction = {};
  groupListItem558: FeatureAction = {};
  groupListItem559: FeatureAction = {};
  groupListItem560: FeatureAction = {};
  groupListItem561: FeatureAction = {};
  groupListItem562: FeatureAction = {};
  groupListItem563: FeatureAction = {};
  groupListItem564: FeatureAction = {};
  groupListItem565: FeatureAction = {};
  groupListItem566: FeatureAction = {};
  groupListItem567: FeatureAction = {};
  groupListItem568: FeatureAction = {};
  groupListItem569: FeatureAction = {};
  groupListItem570: FeatureAction = {};
  groupListItem571: FeatureAction = {};
  groupListItem572: FeatureAction = {};
  groupListItem573: FeatureAction = {};
  groupListItem574: FeatureAction = {};
  groupListItem575: FeatureAction = {};
  groupListItem576: FeatureAction = {};
  groupListItem577: FeatureAction = {};
  groupListItem578: FeatureAction = {};
  groupListItem579: FeatureAction = {};
  groupListItem580: FeatureAction = {};
  groupListItem581: FeatureAction = {};
  groupListItem582: FeatureAction = {};
  groupListItem583: FeatureAction = {};
  groupListItem584: FeatureAction = {};
  groupListItem585: FeatureAction = {};
  groupListItem586: FeatureAction = {};
  groupListItem587: FeatureAction = {};
  groupListItem588: FeatureAction = {};
  groupListItem589: FeatureAction = {};
  groupListItem590: FeatureAction = {};
  groupListItem591: FeatureAction = {};
  groupListItem592: FeatureAction = {};
  groupListItem593: FeatureAction = {};
  groupListItem594: FeatureAction = {};
  groupListItem595: FeatureAction = {};
  groupListItem596: FeatureAction = {};
  groupListItem597: FeatureAction = {};
  groupListItem598: FeatureAction = {};
  groupListItem10000: FeatureAction = {};
  chatbotItem599: FeatureAction = {};
  chatbotItem600: FeatureAction = {};
  chatbotItem601: FeatureAction = {};
  chatbotItem602: FeatureAction = {};
  chatbotItem603: FeatureAction = {};
  chatbotItem604: FeatureAction = {};
  chatbotItem605: FeatureAction = {};
  chatbotItem606: FeatureAction = {};
  chatbotItem607: FeatureAction = {};
  chatbotItem608: FeatureAction = {};
  chatbotItem609: FeatureAction = {};
  chatbotItem610: FeatureAction = {};
  chatbotItem611: FeatureAction = {};
  chatbotItem612: FeatureAction = {};
  chatbotItem613: FeatureAction = {};
  chatbotItem614: FeatureAction = {};
  chatbotItem615: FeatureAction = {};
  chatbotItem616: FeatureAction = {};
  chatbotItem617: FeatureAction = {};
  chatbotItem618: FeatureAction = {};
  chatbotItem619: FeatureAction = {};
  chatbotItem620: FeatureAction = {};
  chatbotItem621: FeatureAction = {};
  chatbotItem622: FeatureAction = {};
  chatbotItem623: FeatureAction = {};
  chatbotItem624: FeatureAction = {};
  chatbotItem625: FeatureAction = {};
  chatbotItem626: FeatureAction = {};
  chatbotItem627: FeatureAction = {};
  chatbotItem628: FeatureAction = {};
  chatbotItem629: FeatureAction = {};
  chatbotItem630: FeatureAction = {};
  chatbotItem631: FeatureAction = {};
  chatbotItem632: FeatureAction = {};
  chatbotItem633: FeatureAction = {};
  chatbotItem634: FeatureAction = {};
  chatbotItem635: FeatureAction = {};
  chatbotItem636: FeatureAction = {};
  chatbotItem637: FeatureAction = {};
  chatbotItem638: FeatureAction = {};
  chatbotItem639: FeatureAction = {};
  chatbotItem640: FeatureAction = {};
  chatbotItem641: FeatureAction = {};
  chatbotItem642: FeatureAction = {};
  chatbotItem643: FeatureAction = {};
  chatbotItem644: FeatureAction = {};
  chatbotItem645: FeatureAction = {};
  chatbotItem646: FeatureAction = {};
  chatbotItem647: FeatureAction = {};
  chatbotItem648: FeatureAction = {};
  chatScreenItem649: FeatureAction = {};
  chatScreenItem650: FeatureAction = {};
  chatScreenItem651: FeatureAction = {};
  chatScreenItem652: FeatureAction = {};
  chatScreenItem653: FeatureAction = {};
  chatScreenItem654: FeatureAction = {};
  chatScreenItem655: FeatureAction = {};
  chatScreenItem656: FeatureAction = {};
  chatScreenItem657: FeatureAction = {};
  chatScreenItem658: FeatureAction = {};
  chatScreenItem659: FeatureAction = {};
  chatScreenItem660: FeatureAction = {};
  chatScreenItem661: FeatureAction = {};
  chatScreenItem662: FeatureAction = {};
  chatScreenItem663: FeatureAction = {};
  chatScreenItem664: FeatureAction = {};
  chatScreenItem665: FeatureAction = {};
  chatScreenItem666: FeatureAction = {};
  chatScreenItem667: FeatureAction = {};
  chatScreenItem668: FeatureAction = {};
  chatScreenItem669: FeatureAction = {};
  chatScreenItem670: FeatureAction = {};
  chatScreenItem671: FeatureAction = {};
  chatScreenItem672: FeatureAction = {};
  chatScreenItem673: FeatureAction = {};
  chatScreenItem674: FeatureAction = {};
  chatScreenItem675: FeatureAction = {};
  chatScreenItem676: FeatureAction = {};
  chatScreenItem677: FeatureAction = {};
  chatScreenItem678: FeatureAction = {};
  chatScreenItem679: FeatureAction = {};
  chatScreenItem680: FeatureAction = {};
  chatScreenItem681: FeatureAction = {};
  chatScreenItem682: FeatureAction = {};
  chatScreenItem683: FeatureAction = {};
  chatScreenItem684: FeatureAction = {};
  chatScreenItem685: FeatureAction = {};
  chatScreenItem686: FeatureAction = {};
  chatScreenItem687: FeatureAction = {};
  chatScreenItem688: FeatureAction = {};
  chatScreenItem689: FeatureAction = {};
  chatScreenItem690: FeatureAction = {};
  chatScreenItem691: FeatureAction = {};
  chatScreenItem692: FeatureAction = {};
  chatScreenItem693: FeatureAction = {};
  chatScreenItem694: FeatureAction = {};
  chatScreenItem695: FeatureAction = {};
  chatGroupItem696: FeatureAction = {};
  chatGroupItem697: FeatureAction = {};
  chatGroupItem698: FeatureAction = {};
  chatGroupItem699: FeatureAction = {};
  chatGroupItem700: FeatureAction = {};
  chatGroupItem701: FeatureAction = {};
  chatGroupItem702: FeatureAction = {};
  chatGroupItem703: FeatureAction = {};
  chatGroupItem704: FeatureAction = {};
  chatGroupItem705: FeatureAction = {};
  chatGroupItem706: FeatureAction = {};
  chatGroupItem707: FeatureAction = {};
  chatGroupItem708: FeatureAction = {};
  chatGroupItem709: FeatureAction = {};
  chatGroupItem710: FeatureAction = {};
  chatGroupItem711: FeatureAction = {};
  chatGroupItem712: FeatureAction = {};
  chatGroupItem713: FeatureAction = {};
  chatGroupItem714: FeatureAction = {};
  chatGroupItem715: FeatureAction = {};
  chatGroupItem716: FeatureAction = {};
  chatGroupItem717: FeatureAction = {};
  chatGroupItem718: FeatureAction = {};
  chatGroupItem719: FeatureAction = {};
  chatGroupItem720: FeatureAction = {};
  chatGroupItem721: FeatureAction = {};
  chatGroupItem722: FeatureAction = {};
  chatGroupItem723: FeatureAction = {};
  chatGroupItem724: FeatureAction = {};
  chatGroupItem725: FeatureAction = {};
  chatGroupItem726: FeatureAction = {};
  chatGroupItem727: FeatureAction = {};
  chatGroupItem728: FeatureAction = {};
  chatGroupItem729: FeatureAction = {};
  chatGroupItem730: FeatureAction = {};
  chatGroupItem731: FeatureAction = {};
  chatGroupItem732: FeatureAction = {};
  chatGroupItem733: FeatureAction = {};
  chatGroupItem734: FeatureAction = {};
  chatGroupItem735: FeatureAction = {};
  chatGroupItem736: FeatureAction = {};
  chatGroupItem737: FeatureAction = {};
  chatGroupItem738: FeatureAction = {};
  chatGroupItem739: FeatureAction = {};
  chatGroupItem740: FeatureAction = {};
  chatGroupItem741: FeatureAction = {};
  chatGroupItem742: FeatureAction = {};
  chatGroupItem743: FeatureAction = {};
  chatGroupItem744: FeatureAction = {};
  chatGroupItem745: FeatureAction = {};
  chatGroupItem746: FeatureAction = {};
  chatGroupItem747: FeatureAction = {};
  chatGroupItem748: FeatureAction = {};
  chatGroupItem749: FeatureAction = {};
  chatGroupItem750: FeatureAction = {};
  chatGroupItem751: FeatureAction = {};
  chatGroupItem752: FeatureAction = {};
  chatGroupItem753: FeatureAction = {};
  chatGroupItem754: FeatureAction = {};
  chatGroupItem755: FeatureAction = {};
  chatGroupItem756: FeatureAction = {};
  chatGroupItem757: FeatureAction = {};
  chatGroupItem758: FeatureAction = {};
  chatGroupItem759: FeatureAction = {};
  chatGroupItem760: FeatureAction = {};
  chatGroupItem761: FeatureAction = {};
  chatGroupItem762: FeatureAction = {};
  // postsFeedsItem764: FeatureAction = {};
  // loyaltyCompetitionsItem765: FeatureAction = {};
  // digitalIDItem766: FeatureAction = {};
  // postsFeedsItem768: FeatureAction = {};
  // loyaltyCompetitionsItem769: FeatureAction = {};
  // digitalIDItem770: FeatureAction = {};
  // loyaltyCompetitionsItem771: FeatureAction = {};
  // loyaltyCompetitionsItem772: FeatureAction = {};
  loyaltyCompetitionsItem773: FeatureAction = {};
  loyaltyCompetitionsItem774: FeatureAction = {};
  loyaltyCompetitionsItem775: FeatureAction = {};
  loyaltyCompetitionsItem776: FeatureAction = {};
  loyaltyCompetitionsItem777: FeatureAction = {};
  loyaltyCompetitionsItem778: FeatureAction = {};
  loyaltyCompetitionsItem779: FeatureAction = {};
  loyaltyCompetitionsItem780: FeatureAction = {};
  loyaltyCompetitionsItem781: FeatureAction = {};
  loyaltyCompetitionsItem782: FeatureAction = {};
  loyaltyCompetitionsItem783: FeatureAction = {};
  loyaltyCompetitionsItem784: FeatureAction = {};
  loyaltyCompetitionsItem785: FeatureAction = {};
  loyaltyCompetitionsItem786: FeatureAction = {};
  loyaltyCompetitionsItem787: FeatureAction = {};
  loyaltyCompetitionsItem788: FeatureAction = {};
  loyaltyCompetitionsItem789: FeatureAction = {};
  loyaltyCompetitionsItem790: FeatureAction = {};
  loyaltyCompetitionsItem791: FeatureAction = {};
  loyaltyCompetitionsItem792: FeatureAction = {};
  loyaltyCompetitionsItem793: FeatureAction = {};
  loyaltyCompetitionsItem794: FeatureAction = {};
  loyaltyCompetitionsItem795: FeatureAction = {};
  loyaltyCompetitionsItem796: FeatureAction = {};
  loyaltyCompetitionsItem797: FeatureAction = {};
  loyaltyCompetitionsItem798: FeatureAction = {};
  loyaltyCompetitionsItem799: FeatureAction = {};
  loyaltyCompetitionsItem800: FeatureAction = {};
  loyaltyCompetitionsItem801: FeatureAction = {};
  loyaltyCompetitionsItem802: FeatureAction = {};
  loyaltyCompetitionsItem803: FeatureAction = {};
  loyaltyCompetitionsItem804: FeatureAction = {};
  loyaltyCompetitionsItem805: FeatureAction = {};
  loyaltyCompetitionsItem806: FeatureAction = {};
  loyaltyCompetitionsItem807: FeatureAction = {};
  loyaltyCompetitionsItem808: FeatureAction = {};
  loyaltyCompetitionsItem809: FeatureAction = {};
  loyaltyCompetitionsItem810: FeatureAction = {};
  loyaltyCompetitionsItem811: FeatureAction = {};
  loyaltyCompetitionsItem812: FeatureAction = {};
  loyaltyCompetitionsItem813: FeatureAction = {};
  loyaltyCompetitionsItem814: FeatureAction = {};
  loyaltyCompetitionsItem815: FeatureAction = {};
  loyaltyCompetitionsItem816: FeatureAction = {};
  loyaltyCompetitionsItem817: FeatureAction = {};
  loyaltyCompetitionsItem818: FeatureAction = {};
  loyaltyCompetitionsItem819: FeatureAction = {};
  loyaltyCompetitionsItem820: FeatureAction = {};
  loyaltyCompetitionsItem821: FeatureAction = {};
  loyaltyCompetitionsItem822: FeatureAction = {};
  loyaltyCompetitionsItem823: FeatureAction = {};
  loyaltyCompetitionsItem824: FeatureAction = {};
  loyaltyCompetitionsItem825: FeatureAction = {};
  loyaltyCompetitionsItem826: FeatureAction = {};
  loyaltyCompetitionsItem827: FeatureAction = {};
  loyaltyCompetitionsItem828: FeatureAction = {};
  loyaltyCompetitionsItem829: FeatureAction = {};
  loyaltyCompetitionsItem830: FeatureAction = {};
  loyaltyCompetitionsItem831: FeatureAction = {};
  loyaltyCompetitionsItem832: FeatureAction = {};
  loyaltyCompetitionsItem833: FeatureAction = {};
  loyaltyCompetitionsItem834: FeatureAction = {};
  loyaltyCompetitionsItem835: FeatureAction = {};
  loyaltyCompetitionsItem836: FeatureAction = {};
  loyaltyCompetitionsItem837: FeatureAction = {};
  loyaltyCompetitionsItem838: FeatureAction = {};
  loyaltyCompetitionsItem839: FeatureAction = {};
  loyaltyCompetitionsItem840: FeatureAction = {};
  loyaltyCompetitionsItem841: FeatureAction = {};
  loyaltyCompetitionsItem842: FeatureAction = {};
  loyaltyCompetitionsItem843: FeatureAction = {};
  loyaltyCompetitionsItem844: FeatureAction = {};
  loyaltyCompetitionsItem845: FeatureAction = {};
  loyaltyCompetitionsItem846: FeatureAction = {};
  loyaltyCompetitionsItem847: FeatureAction = {};
  // digitalIDItem847: FeatureAction = {};
  // digitalIDItem848: FeatureAction = {};
  digitalIDItem849: FeatureAction = {};
  digitalIDItem850: FeatureAction = {};
  digitalIDItem851: FeatureAction = {};
  digitalIDItem852: FeatureAction = {};
  digitalIDItem853: FeatureAction = {};
  digitalIDItem854: FeatureAction = {};
  digitalIDItem855: FeatureAction = {};
  digitalIDItem856: FeatureAction = {};
  digitalIDItem857: FeatureAction = {};
  digitalIDItem858: FeatureAction = {};
  digitalIDItem859: FeatureAction = {};
  digitalIDItem860: FeatureAction = {};
  digitalIDItem861: FeatureAction = {};
  digitalIDItem862: FeatureAction = {};
  digitalIDItem863: FeatureAction = {};
  digitalIDItem864: FeatureAction = {};
  digitalIDItem865: FeatureAction = {};
  digitalIDItem866: FeatureAction = {};
  digitalIDItem867: FeatureAction = {};
  digitalIDItem868: FeatureAction = {};
  digitalIDItem869: FeatureAction = {};
  digitalIDItem870: FeatureAction = {};
  digitalIDItem871: FeatureAction = {};
  digitalIDItem872: FeatureAction = {};
  digitalIDItem873: FeatureAction = {};
  digitalIDItem874: FeatureAction = {};
  digitalIDItem875: FeatureAction = {};
  digitalIDItem876: FeatureAction = {};
  digitalIDItem877: FeatureAction = {};
  digitalIDItem878: FeatureAction = {};
  digitalIDItem879: FeatureAction = {};
  digitalIDItem880: FeatureAction = {};
  digitalIDItem881: FeatureAction = {};
  digitalIDItem882: FeatureAction = {};
  digitalIDItem883: FeatureAction = {};
  digitalIDItem884: FeatureAction = {};
  digitalIDItem885: FeatureAction = {};
  digitalIDItem886: FeatureAction = {};
  digitalIDItem887: FeatureAction = {};
  digitalIDItem888: FeatureAction = {};
  digitalIDItem889: FeatureAction = {};
  digitalIDItem890: FeatureAction = {};
  digitalIDItem891: FeatureAction = {};
  digitalIDItem892: FeatureAction = {};
  digitalIDItem893: FeatureAction = {};
  digitalIDItem894: FeatureAction = {};
  digitalIDItem895: FeatureAction = {};
  digitalIDItem896: FeatureAction = {};
  digitalIDItem897: FeatureAction = {};
  digitalIDItem898: FeatureAction = {};
  digitalIDItem899: FeatureAction = {};
  digitalIDItem900: FeatureAction = {};
  digitalIDItem901: FeatureAction = {};
  digitalIDItem902: FeatureAction = {};
  digitalIDItem903: FeatureAction = {};
  digitalIDItem904: FeatureAction = {};
  digitalIDItem905: FeatureAction = {};
  digitalIDItem906: FeatureAction = {};
  digitalIDItem907: FeatureAction = {};
  digitalIDItem908: FeatureAction = {};
  digitalIDItem909: FeatureAction = {};
  digitalIDItem910: FeatureAction = {};
  digitalIDItem911: FeatureAction = {};
  digitalIDItem912: FeatureAction = {};
  digitalIDItem913: FeatureAction = {};
  digitalIDItem914: FeatureAction = {};
  digitalIDItem915: FeatureAction = {};
  digitalIDItem916: FeatureAction = {};
  digitalIDItem917: FeatureAction = {};
  digitalIDItem918: FeatureAction = {};
  digitalIDItem919: FeatureAction = {};
  digitalIDItem920: FeatureAction = {};
  digitalIDItem921: FeatureAction = {};
  calendarItem1: FeatureAction = {};
  userInvitationsItem1: FeatureAction = {};
  userInvitationsItem2: FeatureAction = {};
  webviewItem1: FeatureAction = {};
  searchItem1: FeatureAction = {};
  searchItem2: FeatureAction = {};
  searchItem3: FeatureAction = {};
  searchItem4: FeatureAction = {};
  ticketValidateItem935: FeatureAction = {};
  ticketRedeemItem936: FeatureAction = {};
  channelsListItem942: FeatureAction = {};
  channelsListItem943: FeatureAction = {};
  channelsListItem944: FeatureAction = {};
  channelsListItem945: FeatureAction = {};
  channelsListItem946: FeatureAction = {};
  channelsListItem947: FeatureAction = {};
  channelsListItem948: FeatureAction = {};
  channelsListItem949: FeatureAction = {};
  channelsListItem950: FeatureAction = {};
  channelsListItem951: FeatureAction = {};
  channelsListItem952: FeatureAction = {};
  channelsListItem953: FeatureAction = {};
  channelsListItem954: FeatureAction = {};
  channelsListItem955: FeatureAction = {};
  channelsListItem956: FeatureAction = {};
  channelsListItem957: FeatureAction = {};
  channelsListItem958: FeatureAction = {};
  channelsListItem959: FeatureAction = {};
  channelsListItem960: FeatureAction = {};
  channelsListItem961: FeatureAction = {};
  channelsListItem962: FeatureAction = {};
  channelsListItem963: FeatureAction = {};
  channelsListItem964: FeatureAction = {};
  channelsListItem965: FeatureAction = {};
  channelsListItem966: FeatureAction = {};
  channelsListItem967: FeatureAction = {};
  channelsListItem968: FeatureAction = {};
  channelsListItem969: FeatureAction = {};
  channelsListItem970: FeatureAction = {};
  channelsListItem971: FeatureAction = {};
  channelsListItem972: FeatureAction = {};
  channelsListItem973: FeatureAction = {};
  channelsListItem974: FeatureAction = {};
  channelsListItem975: FeatureAction = {};
  channelsListItem976: FeatureAction = {};
  channelsListItem977: FeatureAction = {};
  channelsListItem978: FeatureAction = {};
  channelsListItem979: FeatureAction = {};
  channelsListItem980: FeatureAction = {};
  channelsListItem981: FeatureAction = {};
  channelsListItem982: FeatureAction = {};
  channelsListItem983: FeatureAction = {};
  channelsListItem984: FeatureAction = {};
  channelsListItem985: FeatureAction = {};
  channelsListItem986: FeatureAction = {};
  channelsListItem987: FeatureAction = {};
  channelsListItem988: FeatureAction = {};
  channelsListItem989: FeatureAction = {};
  channelsListItem990: FeatureAction = {};
  channelsListItem991: FeatureAction = {};
  channelsListItem992: FeatureAction = {};
  channelsListItem993: FeatureAction = {};
  channelsListItem994: FeatureAction = {};
  channelsListItem995: FeatureAction = {};
  channelsListItem996: FeatureAction = {};
  channelsListItem997: FeatureAction = {};
  channelsListItem998: FeatureAction = {};
  channelsListItem999: FeatureAction = {};
  channelsListItem1000: FeatureAction = {};
  channelsListItem1001: FeatureAction = {};
  channelsListItem1002: FeatureAction = {};
  channelsListItem1003: FeatureAction = {};
  channelsListItem1004: FeatureAction = {};
  channelsListItem1005: FeatureAction = {};
  channelsListItem1006: FeatureAction = {};
  channelsListItem1007: FeatureAction = {};
  channelsListItem1008: FeatureAction = {};
  channelsListItem1009: FeatureAction = {};
  channelsListItem1010: FeatureAction = {};
  channelsListItem1011: FeatureAction = {};
  channelsListItem1012: FeatureAction = {};
  channelsListItem1013: FeatureAction = {};
  channelsListItem1014: FeatureAction = {};
  channelsListItem1015: FeatureAction = {};
  channelsListItem1016: FeatureAction = {};
  channelsListItem1017: FeatureAction = {};
  // channelsListItem1018: FeatureAction = {};
  channelsListItem1019: FeatureAction = {};
  onlineGroupsListItem1020: FeatureAction = {};
  onlineGroupsListItem1021: FeatureAction = {};
  onlineGroupsListItem1022: FeatureAction = {};
  onlineGroupsListItem1023: FeatureAction = {};
  onlineGroupsListItem1024: FeatureAction = {};
  onlineGroupsListItem1025: FeatureAction = {};
  onlineGroupsListItem1026: FeatureAction = {};
  onlineGroupsListItem1027: FeatureAction = {};
  onlineGroupsListItem1028: FeatureAction = {};
  onlineGroupsListItem1029: FeatureAction = {};
  onlineGroupsListItem1030: FeatureAction = {};
  onlineGroupsListItem1031: FeatureAction = {};
  onlineGroupsListItem1032: FeatureAction = {};
  onlineGroupsListItem1033: FeatureAction = {};
  onlineGroupsListItem1034: FeatureAction = {};
  onlineGroupsListItem1035: FeatureAction = {};
  onlineGroupsListItem1036: FeatureAction = {};
  onlineGroupsListItem1037: FeatureAction = {};
  onlineGroupsListItem1038: FeatureAction = {};
  onlineGroupsListItem1039: FeatureAction = {};
  onlineGroupsListItem1040: FeatureAction = {};
  onlineGroupsListItem1041: FeatureAction = {};
  onlineGroupsListItem1042: FeatureAction = {};
  onlineGroupsListItem1043: FeatureAction = {};
  onlineGroupsListItem1044: FeatureAction = {};
  onlineGroupsListItem1045: FeatureAction = {};
  onlineGroupsListItem1046: FeatureAction = {};
  onlineGroupsListItem1047: FeatureAction = {};
  onlineGroupsListItem1048: FeatureAction = {};
  onlineGroupsListItem1049: FeatureAction = {};
  onlineGroupsListItem1050: FeatureAction = {};
  onlineGroupsListItem1051: FeatureAction = {};
  onlineGroupsListItem1052: FeatureAction = {};
  onlineGroupsListItem1053: FeatureAction = {};
  onlineGroupsListItem1054: FeatureAction = {};
  onlineGroupsListItem1055: FeatureAction = {};
  onlineGroupsListItem1056: FeatureAction = {};
  onlineGroupsListItem1057: FeatureAction = {};
  onlineGroupsListItem1058: FeatureAction = {};
  onlineGroupsListItem1059: FeatureAction = {};
  onlineGroupsListItem1060: FeatureAction = {};
  onlineGroupsListItem1061: FeatureAction = {};
  onlineGroupsListItem1062: FeatureAction = {};
  onlineGroupsListItem1063: FeatureAction = {};
  onlineGroupsListItem1064: FeatureAction = {};
  onlineGroupsListItem1065: FeatureAction = {};
  onlineGroupsListItem1066: FeatureAction = {};
  onlineGroupsListItem1067: FeatureAction = {};
  onlineGroupsListItem1068: FeatureAction = {};
  onlineGroupsListItem1069: FeatureAction = {};
  onlineGroupsListItem1070: FeatureAction = {};
  onlineGroupsListItem1071: FeatureAction = {};
  onlineGroupsListItem1072: FeatureAction = {};
  onlineGroupsListItem1073: FeatureAction = {};
  onlineGroupsListItem1074: FeatureAction = {};
  onlineGroupsListItem1075: FeatureAction = {};
  onlineGroupsListItem1076: FeatureAction = {};
  onlineGroupsListItem1077: FeatureAction = {};
  onlineGroupsListItem1078: FeatureAction = {};
  onlineGroupsListItem1079: FeatureAction = {};
  onlineGroupsListItem1080: FeatureAction = {};
  onlineGroupsListItem1081: FeatureAction = {};
  onlineGroupsListItem1082: FeatureAction = {};
  onlineGroupsListItem1083: FeatureAction = {};
  onlineGroupsListItem1084: FeatureAction = {};
  onlineGroupsListItem1085: FeatureAction = {};
  onlineGroupsListItem1086: FeatureAction = {};
  onlineGroupsListItem1087: FeatureAction = {};
  onlineGroupsListItem1088: FeatureAction = {};
  onlineGroupsListItem1089: FeatureAction = {};
  onlineGroupsListItem1090: FeatureAction = {};
  onlineGroupsListItem1091: FeatureAction = {};
  onlineGroupsListItem1092: FeatureAction = {};
  onlineGroupsListItem1093: FeatureAction = {};
  onlineGroupsListItem1094: FeatureAction = {};
  onlineGroupsListItem1095: FeatureAction = {};
  // onlineGroupsListItem1096: FeatureAction = {};
  onlineGroupsListItem1097: FeatureAction = {};
  digitalIDItem1098: FeatureAction = {};
  digitalIDItem1099: FeatureAction = {};
  postsFeedsItem1099: FeatureAction = {};
  postsFeedsItem1100: FeatureAction = {};
  // calendarItem1: FeatureAction = {};
  // invitationItem1: FeatureAction = {};
  // invitationItem2: FeatureAction = {};
  // webViewItem1: FeatureAction = {};
  // searchItem1: FeatureAction = {};
  // searchItem2: FeatureAction = {};
  // searchItem3: FeatureAction = {};
  // searchItem4: FeatureAction = {};

  // bookingItem1: FeatureAction = {};
  // bookingItem2: FeatureAction = {};
  // bookingItem3: FeatureAction = {};
  bookingItem4: FeatureAction = {};
  tickRedeemItem1: FeatureAction = {};
  tickValidetItem1: FeatureAction = {};
  tabTypes = TabTypes;
  myListTypes = MyListTabTypes;
  openChats = OpenChatTypes;
  helpText: string;
  uiSections = UISections;
  privilegesName: typeof PrivilegesName = PrivilegesName;
  modulesIDs = ModulesIDs;
  constructor(
    private _ref: ChangeDetectorRef,
    public appMgntService: AppMgmtService,
    public botsService: BotsService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {
    this.callsLogItem1.id = 10040;
    this.callsLogItem1.default = true;
    this.callsLogItem1.read = true;
    this.callsLogItem2.id = 10278;
    this.callsLogItem2.default = true;
    this.callsLogItem2.read = true;
    this.callsLogItem3.id = 10427;
    this.callsLogItem3.default = true;
    this.callsLogItem3.read = true;
    this.callsLogItem4.id = 10428;
    this.callsLogItem4.default = true;
    this.callsLogItem4.read = true;
    this.callsLogItem5.id = 10429;
    this.callsLogItem5.default = true;
    this.callsLogItem5.read = true;
    this.callsLogItem6.id = 10430;
    this.callsLogItem6.default = true;
    this.callsLogItem6.read = true;
    this.callsLogItem7.id = 10431;
    this.callsLogItem7.default = true;
    this.callsLogItem7.read = true;
    this.callsLogItem8.id = 10432;
    this.callsLogItem8.default = true;
    this.callsLogItem8.read = true;
    this.callsLogItem9.id = 10433;
    this.callsLogItem9.default = true;
    this.callsLogItem9.read = true;
    this.callsLogItem10.id = 10434;
    this.callsLogItem10.default = true;
    this.callsLogItem10.read = true;
    this.callsLogItem11.id = 10435;
    this.callsLogItem11.default = true;
    this.callsLogItem11.read = true;
    this.callsLogItem12.id = 10436;
    this.callsLogItem12.default = true;
    this.callsLogItem12.read = true;
    this.callsLogItem13.id = 10437;
    this.callsLogItem13.default = true;
    this.callsLogItem13.read = true;
    this.callsLogItem14.id = 10438;
    this.callsLogItem14.default = true;
    this.callsLogItem14.read = true;
    this.callsLogItem15.id = 10439;
    this.callsLogItem15.default = true;
    this.callsLogItem15.read = true;
    this.callsLogItem16.id = 10440;
    this.callsLogItem16.default = true;
    this.callsLogItem16.read = true;
    this.callsLogItem17.id = 10441;
    this.callsLogItem17.default = true;
    this.callsLogItem17.read = true;
    this.activeChannelsItem18.id = 10481;
    this.activeChannelsItem18.default = true;
    this.activeChannelsItem18.read = true;
    this.activeChannelsItem19.id = 10480;
    this.activeChannelsItem19.default = true;
    this.activeChannelsItem19.read = true;
    this.activeChannelsItem20.id = 10098;
    this.activeChannelsItem20.default = true;
    this.activeChannelsItem20.read = true;
    this.activeChannelsItem21.id = 10107;
    this.activeChannelsItem21.default = true;
    this.activeChannelsItem21.read = true;
    this.activeChannelsItem22.id = 10078;
    this.activeChannelsItem22.default = true;
    this.activeChannelsItem22.read = true;
    this.activeChannelsItem23.id = 10428;
    this.activeChannelsItem23.default = true;
    this.activeChannelsItem23.read = true;
    this.activeChannelsItem24.id = 10027;
    this.activeChannelsItem24.default = true;
    this.activeChannelsItem24.read = true;
    this.activeChannelsItem25.id = 10442;
    this.activeChannelsItem25.default = true;
    this.activeChannelsItem25.read = true;
    this.activeChannelsItem26.id = 10443;
    this.activeChannelsItem26.default = true;
    this.activeChannelsItem26.read = true;
    this.activeChannelsItem27.id = 10444;
    this.activeChannelsItem27.default = true;
    this.activeChannelsItem27.read = true;
    this.activeChannelsItem28.id = 10053;
    this.activeChannelsItem28.default = true;
    this.activeChannelsItem28.read = true;
    this.activeChannelsItem29.id = 10103;
    this.activeChannelsItem29.default = true;
    this.activeChannelsItem29.read = true;
    this.activeChannelsItem30.id = 10116;
    this.activeChannelsItem30.default = true;
    this.activeChannelsItem30.read = true;
    this.activeChannelsItem31.id = 10096;
    this.activeChannelsItem31.default = true;
    this.activeChannelsItem31.read = true;
    this.activeChannelsItem32.id = 10445;
    this.activeChannelsItem32.default = true;
    this.activeChannelsItem32.read = true;
    this.activeChannelsItem33.id = 10115;
    this.activeChannelsItem33.default = true;
    this.activeChannelsItem33.read = true;
    this.activeChannelsItem34.id = 10049;
    this.activeChannelsItem34.default = true;
    this.activeChannelsItem34.read = true;
    this.activeChannelsItem35.id = 10095;
    this.activeChannelsItem35.default = true;
    this.activeChannelsItem35.read = true;
    this.activeChannelsItem36.id = 10446;
    this.activeChannelsItem36.default = true;
    this.activeChannelsItem36.read = true;
    this.activeChannelsItem37.id = 10133;
    this.activeChannelsItem37.default = true;
    this.activeChannelsItem37.read = true;
    this.activeChannelsItem38.id = 10447;
    this.activeChannelsItem38.default = true;
    this.activeChannelsItem38.read = true;
    this.activeChannelsItem39.id = 10266;
    this.activeChannelsItem39.default = true;
    this.activeChannelsItem39.read = true;
    this.activeChannelsItem40.id = 10267;
    this.activeChannelsItem40.default = true;
    this.activeChannelsItem40.read = true;
    this.activeChannelsItem41.id = 10268;
    this.activeChannelsItem41.default = true;
    this.activeChannelsItem41.read = true;
    this.activeChannelsItem42.id = 10269;
    this.activeChannelsItem42.default = true;
    this.activeChannelsItem42.read = true;
    this.activeChannelsItem43.id = 10271;
    this.activeChannelsItem43.default = true;
    this.activeChannelsItem43.read = true;
    this.activeChannelsItem44.id = 10272;
    this.activeChannelsItem44.default = true;
    this.activeChannelsItem44.read = true;
    this.activeChannelsItem45.id = 10282;
    this.activeChannelsItem45.default = true;
    this.activeChannelsItem45.read = true;
    this.activeChannelsItem46.id = 10291;
    this.activeChannelsItem46.default = true;
    this.activeChannelsItem46.read = true;
    this.activeChannelsItem47.id = 10239;
    this.activeChannelsItem47.default = true;
    this.activeChannelsItem47.read = true;
    this.activeChannelsItem48.id = 10059;
    this.activeChannelsItem48.default = true;
    this.activeChannelsItem48.read = true;
    this.activeChannelsItem49.id = 10448;
    this.activeChannelsItem49.default = true;
    this.activeChannelsItem49.read = true;
    this.activeChannelsItem50.id = 10449;
    this.activeChannelsItem50.default = true;
    this.activeChannelsItem50.read = true;
    this.activeChannelsItem51.id = 10450;
    this.activeChannelsItem51.default = true;
    this.activeChannelsItem51.read = true;
    this.activeChannelsItem52.id = 10451;
    this.activeChannelsItem52.default = true;
    this.activeChannelsItem52.read = true;
    this.activeChannelsItem53.id = 10452;
    this.activeChannelsItem53.default = true;
    this.activeChannelsItem53.read = true;
    this.activeChannelsItem54.id = 10453;
    this.activeChannelsItem54.default = true;
    this.activeChannelsItem54.read = true;
    this.activeChannelsItem55.id = 10183;
    this.activeChannelsItem55.default = true;
    this.activeChannelsItem55.read = true;
    this.activeChannelsItem56.id = 10454;
    this.activeChannelsItem56.default = true;
    this.activeChannelsItem56.read = true;
    this.activeChannelsItem57.id = 10455;
    this.activeChannelsItem57.default = true;
    this.activeChannelsItem57.read = true;
    this.activeChannelsItem58.id = 10456;
    this.activeChannelsItem58.default = true;
    this.activeChannelsItem58.read = true;
    this.activeChannelsItem59.id = 10457;
    this.activeChannelsItem59.default = true;
    this.activeChannelsItem59.read = true;
    this.activeChannelsItem60.id = 10458;
    this.activeChannelsItem60.default = true;
    this.activeChannelsItem60.read = true;
    this.activeChannelsItem61.id = 10037;
    this.activeChannelsItem61.default = true;
    this.activeChannelsItem61.read = true;
    this.activeChannelsItem62.id = 10038;
    this.activeChannelsItem62.default = true;
    this.activeChannelsItem62.read = true;
    this.activeChannelsItem63.id = 10039;
    this.activeChannelsItem63.default = true;
    this.activeChannelsItem63.read = true;
    this.activeChannelsItem64.id = 10195;
    this.activeChannelsItem64.default = true;
    this.activeChannelsItem64.read = true;
    this.activeChannelsItem65.id = 10340;
    this.activeChannelsItem65.default = true;
    this.activeChannelsItem65.read = true;
    this.activeChannelsItem66.id = 10460;
    this.activeChannelsItem66.default = true;
    this.activeChannelsItem66.read = true;
    this.activeChannelsItem67.id = 10117;
    this.activeChannelsItem67.default = true;
    this.activeChannelsItem67.read = true;
    this.activeChannelsItem68.id = 10102;
    this.activeChannelsItem68.default = true;
    this.activeChannelsItem68.read = true;
    this.activeChannelsItem69.id = 10369;
    this.activeChannelsItem69.default = true;
    this.activeChannelsItem69.read = true;
    this.activeChannelsItem70.id = 10459;
    this.activeChannelsItem70.default = true;
    this.activeChannelsItem70.read = true;
    this.activeChannelsItem71.id = 10461;
    this.activeChannelsItem71.default = true;
    this.activeChannelsItem71.read = true;
    this.activeChannelsItem72.id = 10013;
    this.activeChannelsItem72.default = true;
    this.activeChannelsItem72.read = true;
    this.activeChannelsItem73.id = 10002;
    this.activeChannelsItem73.default = true;
    this.activeChannelsItem73.read = true;
    this.activeChannelsItem74.id = 10462;
    this.activeChannelsItem74.default = true;
    this.activeChannelsItem74.read = true;
    this.activeChannelsItem75.id = 10463;
    this.activeChannelsItem75.default = true;
    this.activeChannelsItem75.read = true;
    this.activeChannelsItem76.id = 10119;
    this.activeChannelsItem76.default = true;
    this.activeChannelsItem76.read = true;
    this.activeChannelsItem77.id = 10144;
    this.activeChannelsItem77.default = true;
    this.activeChannelsItem77.read = true;
    this.activeChannelsItem78.id = 10378;
    this.activeChannelsItem78.default = true;
    this.activeChannelsItem78.read = true;
    this.activeChannelsItem79.id = 10127;
    this.activeChannelsItem79.default = true;
    this.activeChannelsItem79.read = true;
    this.activeChannelsItem80.id = 10464;
    this.activeChannelsItem80.default = true;
    this.activeChannelsItem80.read = true;
    this.activeChannelsItem81.id = 10465;
    this.activeChannelsItem81.default = true;
    this.activeChannelsItem81.read = true;
    this.activeChannelsItem82.id = 10466;
    this.activeChannelsItem82.default = true;
    this.activeChannelsItem82.read = true;
    this.activeChannelsItem83.id = 10467;
    this.activeChannelsItem83.default = true;
    this.activeChannelsItem83.read = true;
    this.activeChannelsItem84.id = 10468;
    this.activeChannelsItem84.default = true;
    this.activeChannelsItem84.read = true;
    this.activeChannelsItem85.id = 10469;
    this.activeChannelsItem85.default = true;
    this.activeChannelsItem85.read = true;
    this.activeChannelsItem86.id = 10470;
    this.activeChannelsItem86.default = true;
    this.activeChannelsItem86.read = true;
    this.activeChannelsItem87.id = 10471;
    this.activeChannelsItem87.default = true;
    this.activeChannelsItem87.read = true;
    this.activeChannelsItem88.id = 10472;
    this.activeChannelsItem88.default = true;
    this.activeChannelsItem88.read = true;
    this.activeChannelsItem89.id = 10300;
    this.activeChannelsItem89.default = true;
    this.activeChannelsItem89.read = true;
    this.activeChannelsItem90.id = 10126;
    this.activeChannelsItem90.default = true;
    this.activeChannelsItem90.read = true;
    this.activeChannelsItem91.id = 10474;
    this.activeChannelsItem91.default = true;
    this.activeChannelsItem91.read = true;
    // this.activeChannelsItem92.id = 10475;
    // this.activeChannelsItem92.default = false;
    // this.activeChannelsItem92.read = false;
    this.activeChannelsItem93.id = 10476;
    this.activeChannelsItem93.default = true;
    this.activeChannelsItem93.read = true;
    this.textMediaMessengerItem94.id = 10098;
    this.textMediaMessengerItem94.default = true;
    this.textMediaMessengerItem94.read = true;
    this.textMediaMessengerItem95.id = 10107;
    this.textMediaMessengerItem95.default = true;
    this.textMediaMessengerItem95.read = true;
    this.textMediaMessengerItem96.id = 10078;
    this.textMediaMessengerItem96.default = true;
    this.textMediaMessengerItem96.read = true;
    this.textMediaMessengerItem97.id = 10428;
    this.textMediaMessengerItem97.default = true;
    this.textMediaMessengerItem97.read = true;
    this.textMediaMessengerItem98.id = 10027;
    this.textMediaMessengerItem98.default = true;
    this.textMediaMessengerItem98.read = true;
    this.textMediaMessengerItem99.id = 10442;
    this.textMediaMessengerItem99.default = true;
    this.textMediaMessengerItem99.read = true;
    this.textMediaMessengerItem100.id = 10443;
    this.textMediaMessengerItem100.default = true;
    this.textMediaMessengerItem100.read = true;
    this.textMediaMessengerItem101.id = 10444;
    this.textMediaMessengerItem101.default = true;
    this.textMediaMessengerItem101.read = true;
    this.textMediaMessengerItem102.id = 10053;
    this.textMediaMessengerItem102.default = true;
    this.textMediaMessengerItem102.read = true;
    this.textMediaMessengerItem103.id = 10103;
    this.textMediaMessengerItem103.default = true;
    this.textMediaMessengerItem103.read = true;
    this.textMediaMessengerItem104.id = 10116;
    this.textMediaMessengerItem104.default = true;
    this.textMediaMessengerItem104.read = true;
    this.textMediaMessengerItem105.id = 10096;
    this.textMediaMessengerItem105.default = true;
    this.textMediaMessengerItem105.read = true;
    this.textMediaMessengerItem106.id = 10445;
    this.textMediaMessengerItem106.default = true;
    this.textMediaMessengerItem106.read = true;
    this.textMediaMessengerItem107.id = 10115;
    this.textMediaMessengerItem107.default = true;
    this.textMediaMessengerItem107.read = true;
    this.textMediaMessengerItem108.id = 10049;
    this.textMediaMessengerItem108.default = true;
    this.textMediaMessengerItem108.read = true;
    this.textMediaMessengerItem109.id = 10095;
    this.textMediaMessengerItem109.default = true;
    this.textMediaMessengerItem109.read = true;
    this.textMediaMessengerItem110.id = 10446;
    this.textMediaMessengerItem110.default = true;
    this.textMediaMessengerItem110.read = true;
    this.textMediaMessengerItem111.id = 10133;
    this.textMediaMessengerItem111.default = true;
    this.textMediaMessengerItem111.read = true;
    this.textMediaMessengerItem112.id = 10447;
    this.textMediaMessengerItem112.default = true;
    this.textMediaMessengerItem112.read = true;
    this.textMediaMessengerItem113.id = 10266;
    this.textMediaMessengerItem113.default = true;
    this.textMediaMessengerItem113.read = true;
    this.textMediaMessengerItem114.id = 10267;
    this.textMediaMessengerItem114.default = true;
    this.textMediaMessengerItem114.read = true;
    this.textMediaMessengerItem115.id = 10268;
    this.textMediaMessengerItem115.default = true;
    this.textMediaMessengerItem115.read = true;
    this.textMediaMessengerItem116.id = 10269;
    this.textMediaMessengerItem116.default = true;
    this.textMediaMessengerItem116.read = true;
    this.textMediaMessengerItem117.id = 10271;
    this.textMediaMessengerItem117.default = true;
    this.textMediaMessengerItem117.read = true;
    this.textMediaMessengerItem118.id = 10272;
    this.textMediaMessengerItem118.default = true;
    this.textMediaMessengerItem118.read = true;
    this.textMediaMessengerItem119.id = 10282;
    this.textMediaMessengerItem119.default = true;
    this.textMediaMessengerItem119.read = true;
    this.textMediaMessengerItem120.id = 10291;
    this.textMediaMessengerItem120.default = true;
    this.textMediaMessengerItem120.read = true;
    this.textMediaMessengerItem121.id = 10239;
    this.textMediaMessengerItem121.default = true;
    this.textMediaMessengerItem121.read = true;
    this.textMediaMessengerItem122.id = 10059;
    this.textMediaMessengerItem122.default = true;
    this.textMediaMessengerItem122.read = true;
    this.textMediaMessengerItem123.id = 10448;
    this.textMediaMessengerItem123.default = true;
    this.textMediaMessengerItem123.read = true;
    this.textMediaMessengerItem124.id = 10449;
    this.textMediaMessengerItem124.default = true;
    this.textMediaMessengerItem124.read = true;
    this.textMediaMessengerItem125.id = 10450;
    this.textMediaMessengerItem125.default = true;
    this.textMediaMessengerItem125.read = true;
    this.textMediaMessengerItem126.id = 10451;
    this.textMediaMessengerItem126.default = true;
    this.textMediaMessengerItem126.read = true;
    this.textMediaMessengerItem127.id = 10452;
    this.textMediaMessengerItem127.default = true;
    this.textMediaMessengerItem127.read = true;
    this.textMediaMessengerItem128.id = 10453;
    this.textMediaMessengerItem128.default = true;
    this.textMediaMessengerItem128.read = true;
    this.textMediaMessengerItem129.id = 10183;
    this.textMediaMessengerItem129.default = true;
    this.textMediaMessengerItem129.read = true;
    this.textMediaMessengerItem130.id = 10454;
    this.textMediaMessengerItem130.default = true;
    this.textMediaMessengerItem130.read = true;
    this.textMediaMessengerItem131.id = 10455;
    this.textMediaMessengerItem131.default = true;
    this.textMediaMessengerItem131.read = true;
    this.textMediaMessengerItem132.id = 10456;
    this.textMediaMessengerItem132.default = true;
    this.textMediaMessengerItem132.read = true;
    this.textMediaMessengerItem133.id = 10457;
    this.textMediaMessengerItem133.default = true;
    this.textMediaMessengerItem133.read = true;
    this.textMediaMessengerItem134.id = 10458;
    this.textMediaMessengerItem134.default = true;
    this.textMediaMessengerItem134.read = true;
    this.textMediaMessengerItem135.id = 10037;
    this.textMediaMessengerItem135.default = true;
    this.textMediaMessengerItem135.read = true;
    this.textMediaMessengerItem136.id = 10038;
    this.textMediaMessengerItem136.default = true;
    this.textMediaMessengerItem136.read = true;
    this.textMediaMessengerItem137.id = 10039;
    this.textMediaMessengerItem137.default = true;
    this.textMediaMessengerItem137.read = true;
    this.textMediaMessengerItem138.id = 10195;
    this.textMediaMessengerItem138.default = true;
    this.textMediaMessengerItem138.read = true;
    this.textMediaMessengerItem139.id = 10340;
    this.textMediaMessengerItem139.default = true;
    this.textMediaMessengerItem139.read = true;
    this.textMediaMessengerItem140.id = 10460;
    this.textMediaMessengerItem140.default = true;
    this.textMediaMessengerItem140.read = true;
    this.textMediaMessengerItem141.id = 10117;
    this.textMediaMessengerItem141.default = true;
    this.textMediaMessengerItem141.read = true;
    this.textMediaMessengerItem142.id = 10102;
    this.textMediaMessengerItem142.default = true;
    this.textMediaMessengerItem142.read = true;
    this.textMediaMessengerItem143.id = 10369;
    this.textMediaMessengerItem143.default = true;
    this.textMediaMessengerItem143.read = true;
    this.textMediaMessengerItem144.id = 10459;
    this.textMediaMessengerItem144.default = true;
    this.textMediaMessengerItem144.read = true;
    this.textMediaMessengerItem145.id = 10461;
    this.textMediaMessengerItem145.default = true;
    this.textMediaMessengerItem145.read = true;
    this.textMediaMessengerItem146.id = 10013;
    this.textMediaMessengerItem146.default = true;
    this.textMediaMessengerItem146.read = true;
    this.textMediaMessengerItem147.id = 10002;
    this.textMediaMessengerItem147.default = true;
    this.textMediaMessengerItem147.read = true;
    this.textMediaMessengerItem148.id = 10462;
    this.textMediaMessengerItem148.default = true;
    this.textMediaMessengerItem148.read = true;
    this.textMediaMessengerItem149.id = 10463;
    this.textMediaMessengerItem149.default = true;
    this.textMediaMessengerItem149.read = true;
    this.textMediaMessengerItem150.id = 10119;
    this.textMediaMessengerItem150.default = true;
    this.textMediaMessengerItem150.read = true;
    this.textMediaMessengerItem151.id = 10144;
    this.textMediaMessengerItem151.default = true;
    this.textMediaMessengerItem151.read = true;
    this.textMediaMessengerItem152.id = 10378;
    this.textMediaMessengerItem152.default = true;
    this.textMediaMessengerItem152.read = true;
    this.textMediaMessengerItem153.id = 10127;
    this.textMediaMessengerItem153.default = true;
    this.textMediaMessengerItem153.read = true;
    this.textMediaMessengerItem154.id = 10464;
    this.textMediaMessengerItem154.default = true;
    this.textMediaMessengerItem154.read = true;
    this.textMediaMessengerItem155.id = 10465;
    this.textMediaMessengerItem155.default = true;
    this.textMediaMessengerItem155.read = true;
    this.textMediaMessengerItem156.id = 10466;
    this.textMediaMessengerItem156.default = true;
    this.textMediaMessengerItem156.read = true;
    this.textMediaMessengerItem157.id = 10467;
    this.textMediaMessengerItem157.default = true;
    this.textMediaMessengerItem157.read = true;
    this.textMediaMessengerItem158.id = 10468;
    this.textMediaMessengerItem158.default = true;
    this.textMediaMessengerItem158.read = true;
    this.textMediaMessengerItem159.id = 10469;
    this.textMediaMessengerItem159.default = true;
    this.textMediaMessengerItem159.read = true;
    this.textMediaMessengerItem160.id = 10470;
    this.textMediaMessengerItem160.default = true;
    this.textMediaMessengerItem160.read = true;
    this.textMediaMessengerItem161.id = 10471;
    this.textMediaMessengerItem161.default = true;
    this.textMediaMessengerItem161.read = true;
    this.textMediaMessengerItem162.id = 10472;
    this.textMediaMessengerItem162.default = true;
    this.textMediaMessengerItem162.read = true;
    this.textMediaMessengerItem163.id = 10300;
    this.textMediaMessengerItem163.default = true;
    this.textMediaMessengerItem163.read = true;
    this.audioVideoCallingItem164.id = 10098;
    this.audioVideoCallingItem164.default = true;
    this.audioVideoCallingItem164.read = true;
    this.audioVideoCallingItem165.id = 10107;
    this.audioVideoCallingItem165.default = true;
    this.audioVideoCallingItem165.read = true;
    this.audioVideoCallingItem166.id = 10078;
    this.audioVideoCallingItem166.default = true;
    this.audioVideoCallingItem166.read = true;
    this.audioVideoCallingItem167.id = 10428;
    this.audioVideoCallingItem167.default = true;
    this.audioVideoCallingItem167.read = true;
    this.audioVideoCallingItem168.id = 10027;
    this.audioVideoCallingItem168.default = true;
    this.audioVideoCallingItem168.read = true;
    this.audioVideoCallingItem169.id = 10442;
    this.audioVideoCallingItem169.default = true;
    this.audioVideoCallingItem169.read = true;
    this.audioVideoCallingItem170.id = 10443;
    this.audioVideoCallingItem170.default = true;
    this.audioVideoCallingItem170.read = true;
    this.audioVideoCallingItem171.id = 10444;
    this.audioVideoCallingItem171.default = true;
    this.audioVideoCallingItem171.read = true;
    this.audioVideoCallingItem172.id = 10053;
    this.audioVideoCallingItem172.default = true;
    this.audioVideoCallingItem172.read = true;
    this.audioVideoCallingItem173.id = 10103;
    this.audioVideoCallingItem173.default = true;
    this.audioVideoCallingItem173.read = true;
    this.audioVideoCallingItem174.id = 10116;
    this.audioVideoCallingItem174.default = true;
    this.audioVideoCallingItem174.read = true;
    this.audioVideoCallingItem175.id = 10096;
    this.audioVideoCallingItem175.default = true;
    this.audioVideoCallingItem175.read = true;
    this.audioVideoCallingItem176.id = 10445;
    this.audioVideoCallingItem176.default = true;
    this.audioVideoCallingItem176.read = true;
    this.audioVideoCallingItem177.id = 10115;
    this.audioVideoCallingItem177.default = true;
    this.audioVideoCallingItem177.read = true;
    this.audioVideoCallingItem178.id = 10049;
    this.audioVideoCallingItem178.default = true;
    this.audioVideoCallingItem178.read = true;
    this.audioVideoCallingItem179.id = 10095;
    this.audioVideoCallingItem179.default = true;
    this.audioVideoCallingItem179.read = true;
    this.audioVideoCallingItem180.id = 10446;
    this.audioVideoCallingItem180.default = true;
    this.audioVideoCallingItem180.read = true;
    this.audioVideoCallingItem181.id = 10133;
    this.audioVideoCallingItem181.default = true;
    this.audioVideoCallingItem181.read = true;
    this.audioVideoCallingItem182.id = 10447;
    this.audioVideoCallingItem182.default = true;
    this.audioVideoCallingItem182.read = true;
    this.audioVideoCallingItem183.id = 10266;
    this.audioVideoCallingItem183.default = true;
    this.audioVideoCallingItem183.read = true;
    this.audioVideoCallingItem184.id = 10267;
    this.audioVideoCallingItem184.default = true;
    this.audioVideoCallingItem184.read = true;
    this.audioVideoCallingItem185.id = 10268;
    this.audioVideoCallingItem185.default = true;
    this.audioVideoCallingItem185.read = true;
    this.audioVideoCallingItem186.id = 10269;
    this.audioVideoCallingItem186.default = true;
    this.audioVideoCallingItem186.read = true;
    this.audioVideoCallingItem187.id = 10271;
    this.audioVideoCallingItem187.default = true;
    this.audioVideoCallingItem187.read = true;
    this.audioVideoCallingItem188.id = 10272;
    this.audioVideoCallingItem188.default = true;
    this.audioVideoCallingItem188.read = true;
    this.audioVideoCallingItem189.id = 10282;
    this.audioVideoCallingItem189.default = true;
    this.audioVideoCallingItem189.read = true;
    this.audioVideoCallingItem190.id = 10291;
    this.audioVideoCallingItem190.default = true;
    this.audioVideoCallingItem190.read = true;
    this.audioVideoCallingItem191.id = 10239;
    this.audioVideoCallingItem191.default = true;
    this.audioVideoCallingItem191.read = true;
    this.audioVideoCallingItem192.id = 10059;
    this.audioVideoCallingItem192.default = true;
    this.audioVideoCallingItem192.read = true;
    this.audioVideoCallingItem193.id = 10448;
    this.audioVideoCallingItem193.default = true;
    this.audioVideoCallingItem193.read = true;
    this.audioVideoCallingItem194.id = 10449;
    this.audioVideoCallingItem194.default = true;
    this.audioVideoCallingItem194.read = true;
    this.audioVideoCallingItem195.id = 10450;
    this.audioVideoCallingItem195.default = true;
    this.audioVideoCallingItem195.read = true;
    this.audioVideoCallingItem196.id = 10451;
    this.audioVideoCallingItem196.default = true;
    this.audioVideoCallingItem196.read = true;
    this.audioVideoCallingItem197.id = 10452;
    this.audioVideoCallingItem197.default = true;
    this.audioVideoCallingItem197.read = true;
    this.audioVideoCallingItem198.id = 10453;
    this.audioVideoCallingItem198.default = true;
    this.audioVideoCallingItem198.read = true;
    this.audioVideoCallingItem199.id = 10183;
    this.audioVideoCallingItem199.default = true;
    this.audioVideoCallingItem199.read = true;
    this.audioVideoCallingItem200.id = 10454;
    this.audioVideoCallingItem200.default = true;
    this.audioVideoCallingItem200.read = true;
    this.audioVideoCallingItem201.id = 10455;
    this.audioVideoCallingItem201.default = true;
    this.audioVideoCallingItem201.read = true;
    this.audioVideoCallingItem202.id = 10456;
    this.audioVideoCallingItem202.default = true;
    this.audioVideoCallingItem202.read = true;
    this.audioVideoCallingItem203.id = 10457;
    this.audioVideoCallingItem203.default = true;
    this.audioVideoCallingItem203.read = true;
    this.audioVideoCallingItem204.id = 10458;
    this.audioVideoCallingItem204.default = true;
    this.audioVideoCallingItem204.read = true;
    this.audioVideoCallingItem205.id = 10037;
    this.audioVideoCallingItem205.default = true;
    this.audioVideoCallingItem205.read = true;
    this.audioVideoCallingItem206.id = 10038;
    this.audioVideoCallingItem206.default = true;
    this.audioVideoCallingItem206.read = true;
    this.audioVideoCallingItem207.id = 10039;
    this.audioVideoCallingItem207.default = true;
    this.audioVideoCallingItem207.read = true;
    this.audioVideoCallingItem208.id = 10195;
    this.audioVideoCallingItem208.default = true;
    this.audioVideoCallingItem208.read = true;
    this.audioVideoCallingItem209.id = 10340;
    this.audioVideoCallingItem209.default = true;
    this.audioVideoCallingItem209.read = true;
    this.audioVideoCallingItem210.id = 10460;
    this.audioVideoCallingItem210.default = true;
    this.audioVideoCallingItem210.read = true;
    this.audioVideoCallingItem211.id = 10117;
    this.audioVideoCallingItem211.default = true;
    this.audioVideoCallingItem211.read = true;
    this.audioVideoCallingItem212.id = 10102;
    this.audioVideoCallingItem212.default = true;
    this.audioVideoCallingItem212.read = true;
    this.audioVideoCallingItem213.id = 10369;
    this.audioVideoCallingItem213.default = true;
    this.audioVideoCallingItem213.read = true;
    this.audioVideoCallingItem214.id = 10459;
    this.audioVideoCallingItem214.default = true;
    this.audioVideoCallingItem214.read = true;
    this.audioVideoCallingItem215.id = 10461;
    this.audioVideoCallingItem215.default = true;
    this.audioVideoCallingItem215.read = true;
    this.audioVideoCallingItem216.id = 10013;
    this.audioVideoCallingItem216.default = true;
    this.audioVideoCallingItem216.read = true;
    this.audioVideoCallingItem217.id = 10002;
    this.audioVideoCallingItem217.default = true;
    this.audioVideoCallingItem217.read = true;
    this.audioVideoCallingItem218.id = 10462;
    this.audioVideoCallingItem218.default = true;
    this.audioVideoCallingItem218.read = true;
    this.audioVideoCallingItem219.id = 10463;
    this.audioVideoCallingItem219.default = true;
    this.audioVideoCallingItem219.read = true;
    this.audioVideoCallingItem220.id = 10119;
    this.audioVideoCallingItem220.default = true;
    this.audioVideoCallingItem220.read = true;
    this.audioVideoCallingItem221.id = 10144;
    this.audioVideoCallingItem221.default = true;
    this.audioVideoCallingItem221.read = true;
    this.audioVideoCallingItem222.id = 10378;
    this.audioVideoCallingItem222.default = true;
    this.audioVideoCallingItem222.read = true;
    this.audioVideoCallingItem223.id = 10127;
    this.audioVideoCallingItem223.default = true;
    this.audioVideoCallingItem223.read = true;
    this.audioVideoCallingItem224.id = 10464;
    this.audioVideoCallingItem224.default = true;
    this.audioVideoCallingItem224.read = true;
    this.audioVideoCallingItem225.id = 10465;
    this.audioVideoCallingItem225.default = true;
    this.audioVideoCallingItem225.read = true;
    this.audioVideoCallingItem226.id = 10466;
    this.audioVideoCallingItem226.default = true;
    this.audioVideoCallingItem226.read = true;
    this.audioVideoCallingItem227.id = 10467;
    this.audioVideoCallingItem227.default = true;
    this.audioVideoCallingItem227.read = true;
    this.audioVideoCallingItem228.id = 10468;
    this.audioVideoCallingItem228.default = true;
    this.audioVideoCallingItem228.read = true;
    this.audioVideoCallingItem229.id = 10469;
    this.audioVideoCallingItem229.default = true;
    this.audioVideoCallingItem229.read = true;
    this.audioVideoCallingItem230.id = 10470;
    this.audioVideoCallingItem230.default = true;
    this.audioVideoCallingItem230.read = true;
    this.audioVideoCallingItem231.id = 10471;
    this.audioVideoCallingItem231.default = true;
    this.audioVideoCallingItem231.read = true;
    this.audioVideoCallingItem232.id = 10472;
    this.audioVideoCallingItem232.default = true;
    this.audioVideoCallingItem232.read = true;
    this.audioVideoCallingItem233.id = 10300;
    this.audioVideoCallingItem233.default = true;
    this.audioVideoCallingItem233.read = true;
    this.audioVideoCallingItem234.id = 10040;
    this.audioVideoCallingItem234.default = true;
    this.audioVideoCallingItem234.read = true;
    this.audioVideoCallingItem235.id = 10278;
    this.audioVideoCallingItem235.default = true;
    this.audioVideoCallingItem235.read = true;
    this.audioVideoCallingItem236.id = 10429;
    this.audioVideoCallingItem236.default = true;
    this.audioVideoCallingItem236.read = true;
    this.audioVideoCallingItem237.id = 10430;
    this.audioVideoCallingItem237.default = true;
    this.audioVideoCallingItem237.read = true;
    this.audioVideoCallingItem238.id = 10431;
    this.audioVideoCallingItem238.default = true;
    this.audioVideoCallingItem238.read = true;
    this.audioVideoCallingItem239.id = 10432;
    this.audioVideoCallingItem239.default = true;
    this.audioVideoCallingItem239.read = true;
    this.audioVideoCallingItem240.id = 10433;
    this.audioVideoCallingItem240.default = true;
    this.audioVideoCallingItem240.read = true;
    this.audioVideoCallingItem241.id = 10434;
    this.audioVideoCallingItem241.default = true;
    this.audioVideoCallingItem241.read = true;
    this.audioVideoCallingItem242.id = 10435;
    this.audioVideoCallingItem242.default = true;
    this.audioVideoCallingItem242.read = true;
    this.audioVideoCallingItem243.id = 10436;
    this.audioVideoCallingItem243.default = true;
    this.audioVideoCallingItem243.read = true;
    this.audioVideoCallingItem244.id = 10437;
    this.audioVideoCallingItem244.default = true;
    this.audioVideoCallingItem244.read = true;
    this.audioVideoCallingItem245.id = 10438;
    this.audioVideoCallingItem245.default = true;
    this.audioVideoCallingItem245.read = true;
    this.audioVideoCallingItem246.id = 10439;
    this.audioVideoCallingItem246.default = true;
    this.audioVideoCallingItem246.read = true;
    this.audioVideoCallingItem247.id = 10440;
    this.audioVideoCallingItem247.default = true;
    this.audioVideoCallingItem247.read = true;
    this.audioVideoCallingItem248.id = 10441;
    this.audioVideoCallingItem248.default = true;
    this.audioVideoCallingItem248.read = true;
    // this.postsFeedsItem324.id = 10475;
    // this.postsFeedsItem324.default = false;
    // this.postsFeedsItem324.read = false;
    // this.postsFeedsItem325.id = 10477;
    // this.postsFeedsItem325.default = false;
    // this.postsFeedsItem325.read = false;
    this.postsFeedsItem326.id = 10098;
    this.postsFeedsItem326.default = true;
    this.postsFeedsItem326.read = true;
    this.postsFeedsItem327.id = 10107;
    this.postsFeedsItem327.default = true;
    this.postsFeedsItem327.read = true;
    this.postsFeedsItem328.id = 10078;
    this.postsFeedsItem328.default = true;
    this.postsFeedsItem328.read = true;
    this.postsFeedsItem329.id = 10428;
    this.postsFeedsItem329.default = true;
    this.postsFeedsItem329.read = true;
    this.postsFeedsItem330.id = 10027;
    this.postsFeedsItem330.default = true;
    this.postsFeedsItem330.read = true;
    this.postsFeedsItem331.id = 10442;
    this.postsFeedsItem331.default = true;
    this.postsFeedsItem331.read = true;
    this.postsFeedsItem332.id = 10443;
    this.postsFeedsItem332.default = true;
    this.postsFeedsItem332.read = true;
    this.postsFeedsItem333.id = 10444;
    this.postsFeedsItem333.default = true;
    this.postsFeedsItem333.read = true;
    this.postsFeedsItem334.id = 10053;
    this.postsFeedsItem334.default = true;
    this.postsFeedsItem334.read = true;
    this.postsFeedsItem335.id = 10103;
    this.postsFeedsItem335.default = true;
    this.postsFeedsItem335.read = true;
    this.postsFeedsItem336.id = 10116;
    this.postsFeedsItem336.default = true;
    this.postsFeedsItem336.read = true;
    this.postsFeedsItem337.id = 10096;
    this.postsFeedsItem337.default = true;
    this.postsFeedsItem337.read = true;
    this.postsFeedsItem338.id = 10445;
    this.postsFeedsItem338.default = true;
    this.postsFeedsItem338.read = true;
    this.postsFeedsItem339.id = 10115;
    this.postsFeedsItem339.default = true;
    this.postsFeedsItem339.read = true;
    this.postsFeedsItem340.id = 10049;
    this.postsFeedsItem340.default = true;
    this.postsFeedsItem340.read = true;
    this.postsFeedsItem341.id = 10095;
    this.postsFeedsItem341.default = true;
    this.postsFeedsItem341.read = true;
    this.postsFeedsItem342.id = 10446;
    this.postsFeedsItem342.default = true;
    this.postsFeedsItem342.read = true;
    this.postsFeedsItem343.id = 10133;
    this.postsFeedsItem343.default = true;
    this.postsFeedsItem343.read = true;
    this.postsFeedsItem344.id = 10447;
    this.postsFeedsItem344.default = true;
    this.postsFeedsItem344.read = true;
    this.postsFeedsItem345.id = 10266;
    this.postsFeedsItem345.default = true;
    this.postsFeedsItem345.read = true;
    this.postsFeedsItem346.id = 10267;
    this.postsFeedsItem346.default = true;
    this.postsFeedsItem346.read = true;
    this.postsFeedsItem347.id = 10268;
    this.postsFeedsItem347.default = true;
    this.postsFeedsItem347.read = true;
    this.postsFeedsItem348.id = 10269;
    this.postsFeedsItem348.default = true;
    this.postsFeedsItem348.read = true;
    this.postsFeedsItem349.id = 10271;
    this.postsFeedsItem349.default = true;
    this.postsFeedsItem349.read = true;
    this.postsFeedsItem350.id = 10272;
    this.postsFeedsItem350.default = true;
    this.postsFeedsItem350.read = true;
    this.postsFeedsItem351.id = 10282;
    this.postsFeedsItem351.default = true;
    this.postsFeedsItem351.read = true;
    this.postsFeedsItem352.id = 10291;
    this.postsFeedsItem352.default = true;
    this.postsFeedsItem352.read = true;
    this.postsFeedsItem353.id = 10239;
    this.postsFeedsItem353.default = true;
    this.postsFeedsItem353.read = true;
    this.postsFeedsItem354.id = 10059;
    this.postsFeedsItem354.default = true;
    this.postsFeedsItem354.read = true;
    this.postsFeedsItem355.id = 10448;
    this.postsFeedsItem355.default = true;
    this.postsFeedsItem355.read = true;
    this.postsFeedsItem356.id = 10449;
    this.postsFeedsItem356.default = true;
    this.postsFeedsItem356.read = true;
    this.postsFeedsItem357.id = 10450;
    this.postsFeedsItem357.default = true;
    this.postsFeedsItem357.read = true;
    this.postsFeedsItem358.id = 10451;
    this.postsFeedsItem358.default = true;
    this.postsFeedsItem358.read = true;
    this.postsFeedsItem359.id = 10452;
    this.postsFeedsItem359.default = true;
    this.postsFeedsItem359.read = true;
    this.postsFeedsItem360.id = 10453;
    this.postsFeedsItem360.default = true;
    this.postsFeedsItem360.read = true;
    this.postsFeedsItem361.id = 10183;
    this.postsFeedsItem361.default = true;
    this.postsFeedsItem361.read = true;
    this.postsFeedsItem362.id = 10454;
    this.postsFeedsItem362.default = true;
    this.postsFeedsItem362.read = true;
    this.postsFeedsItem363.id = 10455;
    this.postsFeedsItem363.default = true;
    this.postsFeedsItem363.read = true;
    this.postsFeedsItem364.id = 10456;
    this.postsFeedsItem364.default = true;
    this.postsFeedsItem364.read = true;
    this.postsFeedsItem365.id = 10457;
    this.postsFeedsItem365.default = true;
    this.postsFeedsItem365.read = true;
    this.postsFeedsItem366.id = 10458;
    this.postsFeedsItem366.default = true;
    this.postsFeedsItem366.read = true;
    this.postsFeedsItem367.id = 10037;
    this.postsFeedsItem367.default = true;
    this.postsFeedsItem367.read = true;
    this.postsFeedsItem368.id = 10038;
    this.postsFeedsItem368.default = true;
    this.postsFeedsItem368.read = true;
    this.postsFeedsItem369.id = 10039;
    this.postsFeedsItem369.default = true;
    this.postsFeedsItem369.read = true;
    this.postsFeedsItem370.id = 10195;
    this.postsFeedsItem370.default = true;
    this.postsFeedsItem370.read = true;
    this.postsFeedsItem371.id = 10340;
    this.postsFeedsItem371.default = true;
    this.postsFeedsItem371.read = true;
    this.postsFeedsItem372.id = 10460;
    this.postsFeedsItem372.default = true;
    this.postsFeedsItem372.read = true;
    this.postsFeedsItem373.id = 10117;
    this.postsFeedsItem373.default = true;
    this.postsFeedsItem373.read = true;
    this.postsFeedsItem374.id = 10102;
    this.postsFeedsItem374.default = true;
    this.postsFeedsItem374.read = true;
    this.postsFeedsItem375.id = 10369;
    this.postsFeedsItem375.default = true;
    this.postsFeedsItem375.read = true;
    this.postsFeedsItem376.id = 10459;
    this.postsFeedsItem376.default = true;
    this.postsFeedsItem376.read = true;
    this.postsFeedsItem377.id = 10461;
    this.postsFeedsItem377.default = true;
    this.postsFeedsItem377.read = true;
    this.postsFeedsItem378.id = 10013;
    this.postsFeedsItem378.default = true;
    this.postsFeedsItem378.read = true;
    this.postsFeedsItem379.id = 10002;
    this.postsFeedsItem379.default = true;
    this.postsFeedsItem379.read = true;
    this.postsFeedsItem380.id = 10462;
    this.postsFeedsItem380.default = true;
    this.postsFeedsItem380.read = true;
    this.postsFeedsItem381.id = 10463;
    this.postsFeedsItem381.default = true;
    this.postsFeedsItem381.read = true;
    this.postsFeedsItem382.id = 10119;
    this.postsFeedsItem382.default = true;
    this.postsFeedsItem382.read = true;
    this.postsFeedsItem383.id = 10144;
    this.postsFeedsItem383.default = true;
    this.postsFeedsItem383.read = true;
    this.postsFeedsItem384.id = 10378;
    this.postsFeedsItem384.default = true;
    this.postsFeedsItem384.read = true;
    this.postsFeedsItem385.id = 10127;
    this.postsFeedsItem385.default = true;
    this.postsFeedsItem385.read = true;
    this.postsFeedsItem386.id = 10464;
    this.postsFeedsItem386.default = true;
    this.postsFeedsItem386.read = true;
    this.postsFeedsItem387.id = 10465;
    this.postsFeedsItem387.default = true;
    this.postsFeedsItem387.read = true;
    this.postsFeedsItem388.id = 10466;
    this.postsFeedsItem388.default = true;
    this.postsFeedsItem388.read = true;
    this.postsFeedsItem389.id = 10467;
    this.postsFeedsItem389.default = true;
    this.postsFeedsItem389.read = true;
    this.postsFeedsItem390.id = 10468;
    this.postsFeedsItem390.default = true;
    this.postsFeedsItem390.read = true;
    this.postsFeedsItem391.id = 10469;
    this.postsFeedsItem391.default = true;
    this.postsFeedsItem391.read = true;
    this.postsFeedsItem392.id = 10470;
    this.postsFeedsItem392.default = true;
    this.postsFeedsItem392.read = true;
    this.postsFeedsItem393.id = 10471;
    this.postsFeedsItem393.default = true;
    this.postsFeedsItem393.read = true;
    this.postsFeedsItem394.id = 10472;
    this.postsFeedsItem394.default = true;
    this.postsFeedsItem394.read = true;
    this.postsFeedsItem395.id = 10300;
    this.postsFeedsItem395.default = true;
    this.postsFeedsItem395.read = true;
    this.postsFeedsItem396.id = 10126;
    this.postsFeedsItem396.default = true;
    this.postsFeedsItem396.read = true;
    this.postsFeedsItem397.id = 10474;
    this.postsFeedsItem397.default = true;
    this.postsFeedsItem397.read = true;
    this.postsFeedsItem398.id = 10476;
    this.postsFeedsItem398.default = true;
    this.postsFeedsItem398.read = true;
    this.postsFeedsItem399.id = 10485;
    this.postsFeedsItem399.default = true;
    this.postsFeedsItem399.read = false;
    this.userChannelsItem399.id = 10481;
    this.userChannelsItem399.default = true;
    this.userChannelsItem399.read = true;
    this.userChannelsItem400.id = 10065;
    this.userChannelsItem400.default = true;
    this.userChannelsItem400.read = true;
    this.userChannelsItem401.id = 10261;
    this.userChannelsItem401.default = true;
    this.userChannelsItem401.read = true;
    this.userChannelsItem402.id = 10104;
    this.userChannelsItem402.default = true;
    this.userChannelsItem402.read = true;
    this.userChannelsItem403.id = 10098;
    this.userChannelsItem403.default = true;
    this.userChannelsItem403.read = true;
    this.userChannelsItem404.id = 10107;
    this.userChannelsItem404.default = true;
    this.userChannelsItem404.read = true;
    this.userChannelsItem405.id = 10078;
    this.userChannelsItem405.default = true;
    this.userChannelsItem405.read = true;
    this.userChannelsItem406.id = 10428;
    this.userChannelsItem406.default = true;
    this.userChannelsItem406.read = true;
    this.userChannelsItem407.id = 10027;
    this.userChannelsItem407.default = true;
    this.userChannelsItem407.read = true;
    this.userChannelsItem408.id = 10442;
    this.userChannelsItem408.default = true;
    this.userChannelsItem408.read = true;
    this.userChannelsItem409.id = 10443;
    this.userChannelsItem409.default = true;
    this.userChannelsItem409.read = true;
    this.userChannelsItem410.id = 10444;
    this.userChannelsItem410.default = true;
    this.userChannelsItem410.read = true;
    this.userChannelsItem411.id = 10053;
    this.userChannelsItem411.default = true;
    this.userChannelsItem411.read = true;
    this.userChannelsItem412.id = 10103;
    this.userChannelsItem412.default = true;
    this.userChannelsItem412.read = true;
    this.userChannelsItem413.id = 10116;
    this.userChannelsItem413.default = true;
    this.userChannelsItem413.read = true;
    this.userChannelsItem414.id = 10096;
    this.userChannelsItem414.default = true;
    this.userChannelsItem414.read = true;
    this.userChannelsItem415.id = 10445;
    this.userChannelsItem415.default = true;
    this.userChannelsItem415.read = true;
    this.userChannelsItem416.id = 10115;
    this.userChannelsItem416.default = true;
    this.userChannelsItem416.read = true;
    this.userChannelsItem417.id = 10049;
    this.userChannelsItem417.default = true;
    this.userChannelsItem417.read = true;
    this.userChannelsItem418.id = 10095;
    this.userChannelsItem418.default = true;
    this.userChannelsItem418.read = true;
    this.userChannelsItem419.id = 10446;
    this.userChannelsItem419.default = true;
    this.userChannelsItem419.read = true;
    this.userChannelsItem420.id = 10133;
    this.userChannelsItem420.default = true;
    this.userChannelsItem420.read = true;
    this.userChannelsItem421.id = 10447;
    this.userChannelsItem421.default = true;
    this.userChannelsItem421.read = true;
    this.userChannelsItem422.id = 10266;
    this.userChannelsItem422.default = true;
    this.userChannelsItem422.read = true;
    this.userChannelsItem423.id = 10267;
    this.userChannelsItem423.default = true;
    this.userChannelsItem423.read = true;
    this.userChannelsItem424.id = 10268;
    this.userChannelsItem424.default = true;
    this.userChannelsItem424.read = true;
    this.userChannelsItem425.id = 10269;
    this.userChannelsItem425.default = true;
    this.userChannelsItem425.read = true;
    this.userChannelsItem426.id = 10271;
    this.userChannelsItem426.default = true;
    this.userChannelsItem426.read = true;
    this.userChannelsItem427.id = 10272;
    this.userChannelsItem427.default = true;
    this.userChannelsItem427.read = true;
    this.userChannelsItem428.id = 10282;
    this.userChannelsItem428.default = true;
    this.userChannelsItem428.read = true;
    this.userChannelsItem429.id = 10291;
    this.userChannelsItem429.default = true;
    this.userChannelsItem429.read = true;
    this.userChannelsItem430.id = 10239;
    this.userChannelsItem430.default = true;
    this.userChannelsItem430.read = true;
    this.userChannelsItem431.id = 10059;
    this.userChannelsItem431.default = true;
    this.userChannelsItem431.read = true;
    this.userChannelsItem432.id = 10448;
    this.userChannelsItem432.default = true;
    this.userChannelsItem432.read = true;
    this.userChannelsItem433.id = 10449;
    this.userChannelsItem433.default = true;
    this.userChannelsItem433.read = true;
    this.userChannelsItem434.id = 10450;
    this.userChannelsItem434.default = true;
    this.userChannelsItem434.read = true;
    this.userChannelsItem435.id = 10451;
    this.userChannelsItem435.default = true;
    this.userChannelsItem435.read = true;
    this.userChannelsItem436.id = 10452;
    this.userChannelsItem436.default = true;
    this.userChannelsItem436.read = true;
    this.userChannelsItem437.id = 10453;
    this.userChannelsItem437.default = true;
    this.userChannelsItem437.read = true;
    this.userChannelsItem438.id = 10183;
    this.userChannelsItem438.default = true;
    this.userChannelsItem438.read = true;
    this.userChannelsItem439.id = 10454;
    this.userChannelsItem439.default = true;
    this.userChannelsItem439.read = true;
    this.userChannelsItem440.id = 10455;
    this.userChannelsItem440.default = true;
    this.userChannelsItem440.read = true;
    this.userChannelsItem441.id = 10456;
    this.userChannelsItem441.default = true;
    this.userChannelsItem441.read = true;
    this.userChannelsItem442.id = 10457;
    this.userChannelsItem442.default = true;
    this.userChannelsItem442.read = true;
    this.userChannelsItem443.id = 10458;
    this.userChannelsItem443.default = true;
    this.userChannelsItem443.read = true;
    this.userChannelsItem444.id = 10037;
    this.userChannelsItem444.default = true;
    this.userChannelsItem444.read = true;
    this.userChannelsItem445.id = 10038;
    this.userChannelsItem445.default = true;
    this.userChannelsItem445.read = true;
    this.userChannelsItem446.id = 10039;
    this.userChannelsItem446.default = true;
    this.userChannelsItem446.read = true;
    this.userChannelsItem447.id = 10195;
    this.userChannelsItem447.default = true;
    this.userChannelsItem447.read = true;
    this.userChannelsItem448.id = 10340;
    this.userChannelsItem448.default = true;
    this.userChannelsItem448.read = true;
    this.userChannelsItem449.id = 10460;
    this.userChannelsItem449.default = true;
    this.userChannelsItem449.read = true;
    this.userChannelsItem450.id = 10117;
    this.userChannelsItem450.default = true;
    this.userChannelsItem450.read = true;
    this.userChannelsItem451.id = 10102;
    this.userChannelsItem451.default = true;
    this.userChannelsItem451.read = true;
    this.userChannelsItem452.id = 10369;
    this.userChannelsItem452.default = true;
    this.userChannelsItem452.read = true;
    this.userChannelsItem453.id = 10459;
    this.userChannelsItem453.default = true;
    this.userChannelsItem453.read = true;
    this.userChannelsItem454.id = 10461;
    this.userChannelsItem454.default = true;
    this.userChannelsItem454.read = true;
    this.userChannelsItem455.id = 10013;
    this.userChannelsItem455.default = true;
    this.userChannelsItem455.read = true;
    this.userChannelsItem456.id = 10002;
    this.userChannelsItem456.default = true;
    this.userChannelsItem456.read = true;
    this.userChannelsItem457.id = 10462;
    this.userChannelsItem457.default = true;
    this.userChannelsItem457.read = true;
    this.userChannelsItem458.id = 10463;
    this.userChannelsItem458.default = true;
    this.userChannelsItem458.read = true;
    this.userChannelsItem459.id = 10119;
    this.userChannelsItem459.default = true;
    this.userChannelsItem459.read = true;
    this.userChannelsItem460.id = 10144;
    this.userChannelsItem460.default = true;
    this.userChannelsItem460.read = true;
    this.userChannelsItem461.id = 10378;
    this.userChannelsItem461.default = true;
    this.userChannelsItem461.read = true;
    this.userChannelsItem462.id = 10127;
    this.userChannelsItem462.default = true;
    this.userChannelsItem462.read = true;
    this.userChannelsItem463.id = 10464;
    this.userChannelsItem463.default = true;
    this.userChannelsItem463.read = true;
    this.userChannelsItem464.id = 10465;
    this.userChannelsItem464.default = true;
    this.userChannelsItem464.read = true;
    this.userChannelsItem465.id = 10466;
    this.userChannelsItem465.default = true;
    this.userChannelsItem465.read = true;
    this.userChannelsItem466.id = 10467;
    this.userChannelsItem466.default = true;
    this.userChannelsItem466.read = true;
    this.userChannelsItem467.id = 10468;
    this.userChannelsItem467.default = true;
    this.userChannelsItem467.read = true;
    this.userChannelsItem468.id = 10469;
    this.userChannelsItem468.default = true;
    this.userChannelsItem468.read = true;
    this.userChannelsItem469.id = 10470;
    this.userChannelsItem469.default = true;
    this.userChannelsItem469.read = true;
    this.userChannelsItem470.id = 10471;
    this.userChannelsItem470.default = true;
    this.userChannelsItem470.read = true;
    this.userChannelsItem471.id = 10472;
    this.userChannelsItem471.default = true;
    this.userChannelsItem471.read = true;
    this.userChannelsItem472.id = 10300;
    this.userChannelsItem472.default = true;
    this.userChannelsItem472.read = true;
    this.userChannelsItem473.id = 10126;
    this.userChannelsItem473.default = true;
    this.userChannelsItem473.read = true;
    this.userChannelsItem474.id = 10474;
    this.userChannelsItem474.default = true;
    this.userChannelsItem474.read = true;
    // this.userChannelsItem475.id = 10475;
    // this.userChannelsItem475.default = false;
    // this.userChannelsItem475.read = false;
    this.userChannelsItem476.id = 10476;
    this.userChannelsItem476.default = true;
    this.userChannelsItem476.read = true;
    this.contactsListItem477.id = 10479;
    this.contactsListItem477.default = true;
    this.contactsListItem477.read = true;
    this.contactsListItem478.id = 10114;
    this.contactsListItem478.default = true;
    this.contactsListItem478.read = true;
    this.contactsListItem479.id = 10053;
    this.contactsListItem479.default = true;
    this.contactsListItem479.read = true;
    this.contactsListItem480.id = 10478;
    this.contactsListItem480.default = true;
    this.contactsListItem480.read = true;
    this.contactsListItem481.id = 10093;
    this.contactsListItem481.default = true;
    this.contactsListItem481.read = true;
    this.contactsListItem482.id = 10098;
    this.contactsListItem482.default = true;
    this.contactsListItem482.read = true;
    this.contactsListItem483.id = 10107;
    this.contactsListItem483.default = true;
    this.contactsListItem483.read = true;
    this.contactsListItem484.id = 10078;
    this.contactsListItem484.default = true;
    this.contactsListItem484.read = true;
    this.contactsListItem485.id = 10428;
    this.contactsListItem485.default = true;
    this.contactsListItem485.read = true;
    this.contactsListItem486.id = 10027;
    this.contactsListItem486.default = true;
    this.contactsListItem486.read = true;
    this.contactsListItem487.id = 10442;
    this.contactsListItem487.default = true;
    this.contactsListItem487.read = true;
    this.contactsListItem488.id = 10443;
    this.contactsListItem488.default = true;
    this.contactsListItem488.read = true;
    this.contactsListItem489.id = 10444;
    this.contactsListItem489.default = true;
    this.contactsListItem489.read = true;
    this.contactsListItem490.id = 10103;
    this.contactsListItem490.default = true;
    this.contactsListItem490.read = true;
    this.contactsListItem491.id = 10116;
    this.contactsListItem491.default = true;
    this.contactsListItem491.read = true;
    this.contactsListItem492.id = 10096;
    this.contactsListItem492.default = true;
    this.contactsListItem492.read = true;
    this.contactsListItem493.id = 10445;
    this.contactsListItem493.default = true;
    this.contactsListItem493.read = true;
    this.contactsListItem494.id = 10115;
    this.contactsListItem494.default = true;
    this.contactsListItem494.read = true;
    this.contactsListItem495.id = 10049;
    this.contactsListItem495.default = true;
    this.contactsListItem495.read = true;
    this.contactsListItem496.id = 10095;
    this.contactsListItem496.default = true;
    this.contactsListItem496.read = true;
    this.contactsListItem497.id = 10446;
    this.contactsListItem497.default = true;
    this.contactsListItem497.read = true;
    this.contactsListItem498.id = 10133;
    this.contactsListItem498.default = true;
    this.contactsListItem498.read = true;
    this.contactsListItem499.id = 10447;
    this.contactsListItem499.default = true;
    this.contactsListItem499.read = true;
    this.contactsListItem500.id = 10266;
    this.contactsListItem500.default = true;
    this.contactsListItem500.read = true;
    this.contactsListItem501.id = 10267;
    this.contactsListItem501.default = true;
    this.contactsListItem501.read = true;
    this.contactsListItem502.id = 10268;
    this.contactsListItem502.default = true;
    this.contactsListItem502.read = true;
    this.contactsListItem503.id = 10269;
    this.contactsListItem503.default = true;
    this.contactsListItem503.read = true;
    this.contactsListItem504.id = 10271;
    this.contactsListItem504.default = true;
    this.contactsListItem504.read = true;
    this.contactsListItem505.id = 10272;
    this.contactsListItem505.default = true;
    this.contactsListItem505.read = true;
    this.contactsListItem506.id = 10282;
    this.contactsListItem506.default = true;
    this.contactsListItem506.read = true;
    this.contactsListItem507.id = 10291;
    this.contactsListItem507.default = true;
    this.contactsListItem507.read = true;
    this.contactsListItem508.id = 10239;
    this.contactsListItem508.default = true;
    this.contactsListItem508.read = true;
    this.contactsListItem509.id = 10059;
    this.contactsListItem509.default = true;
    this.contactsListItem509.read = true;
    this.contactsListItem510.id = 10448;
    this.contactsListItem510.default = true;
    this.contactsListItem510.read = true;
    this.contactsListItem511.id = 10449;
    this.contactsListItem511.default = true;
    this.contactsListItem511.read = true;
    this.contactsListItem512.id = 10450;
    this.contactsListItem512.default = true;
    this.contactsListItem512.read = true;
    this.contactsListItem513.id = 10451;
    this.contactsListItem513.default = true;
    this.contactsListItem513.read = true;
    this.contactsListItem514.id = 10452;
    this.contactsListItem514.default = true;
    this.contactsListItem514.read = true;
    this.contactsListItem515.id = 10453;
    this.contactsListItem515.default = true;
    this.contactsListItem515.read = true;
    this.contactsListItem516.id = 10183;
    this.contactsListItem516.default = true;
    this.contactsListItem516.read = true;
    this.contactsListItem517.id = 10454;
    this.contactsListItem517.default = true;
    this.contactsListItem517.read = true;
    this.contactsListItem518.id = 10455;
    this.contactsListItem518.default = true;
    this.contactsListItem518.read = true;
    this.contactsListItem519.id = 10456;
    this.contactsListItem519.default = true;
    this.contactsListItem519.read = true;
    this.contactsListItem520.id = 10457;
    this.contactsListItem520.default = true;
    this.contactsListItem520.read = true;
    this.contactsListItem521.id = 10458;
    this.contactsListItem521.default = true;
    this.contactsListItem521.read = true;
    this.contactsListItem522.id = 10037;
    this.contactsListItem522.default = true;
    this.contactsListItem522.read = true;
    this.contactsListItem523.id = 10038;
    this.contactsListItem523.default = true;
    this.contactsListItem523.read = true;
    this.contactsListItem524.id = 10039;
    this.contactsListItem524.default = true;
    this.contactsListItem524.read = true;
    this.contactsListItem525.id = 10195;
    this.contactsListItem525.default = true;
    this.contactsListItem525.read = true;
    this.contactsListItem526.id = 10340;
    this.contactsListItem526.default = true;
    this.contactsListItem526.read = true;
    this.contactsListItem527.id = 10460;
    this.contactsListItem527.default = true;
    this.contactsListItem527.read = true;
    this.groupListItem528.id = 10481;
    this.groupListItem528.default = true;
    this.groupListItem528.read = true;
    this.groupListItem529.id = 10065;
    this.groupListItem529.default = true;
    this.groupListItem529.read = true;
    this.groupListItem530.id = 10261;
    this.groupListItem530.default = true;
    this.groupListItem530.read = true;
    this.groupListItem531.id = 10104;
    this.groupListItem531.default = true;
    this.groupListItem531.read = true;
    this.groupListItem532.id = 10098;
    this.groupListItem532.default = true;
    this.groupListItem532.read = true;
    this.groupListItem533.id = 10107;
    this.groupListItem533.default = true;
    this.groupListItem533.read = true;
    this.groupListItem534.id = 10078;
    this.groupListItem534.default = true;
    this.groupListItem534.read = true;
    this.groupListItem535.id = 10428;
    this.groupListItem535.default = true;
    this.groupListItem535.read = true;
    this.groupListItem536.id = 10027;
    this.groupListItem536.default = true;
    this.groupListItem536.read = true;
    this.groupListItem537.id = 10442;
    this.groupListItem537.default = true;
    this.groupListItem537.read = true;
    this.groupListItem538.id = 10443;
    this.groupListItem538.default = true;
    this.groupListItem538.read = true;
    this.groupListItem539.id = 10444;
    this.groupListItem539.default = true;
    this.groupListItem539.read = true;
    this.groupListItem540.id = 10053;
    this.groupListItem540.default = true;
    this.groupListItem540.read = true;
    this.groupListItem541.id = 10103;
    this.groupListItem541.default = true;
    this.groupListItem541.read = true;
    this.groupListItem542.id = 10116;
    this.groupListItem542.default = true;
    this.groupListItem542.read = true;
    this.groupListItem543.id = 10096;
    this.groupListItem543.default = true;
    this.groupListItem543.read = true;
    this.groupListItem544.id = 10445;
    this.groupListItem544.default = true;
    this.groupListItem544.read = true;
    this.groupListItem545.id = 10115;
    this.groupListItem545.default = true;
    this.groupListItem545.read = true;
    this.groupListItem546.id = 10049;
    this.groupListItem546.default = true;
    this.groupListItem546.read = true;
    this.groupListItem547.id = 10095;
    this.groupListItem547.default = true;
    this.groupListItem547.read = true;
    this.groupListItem548.id = 10446;
    this.groupListItem548.default = true;
    this.groupListItem548.read = true;
    this.groupListItem549.id = 10133;
    this.groupListItem549.default = true;
    this.groupListItem549.read = true;
    this.groupListItem550.id = 10447;
    this.groupListItem550.default = true;
    this.groupListItem550.read = true;
    this.groupListItem551.id = 10266;
    this.groupListItem551.default = true;
    this.groupListItem551.read = true;
    this.groupListItem552.id = 10267;
    this.groupListItem552.default = true;
    this.groupListItem552.read = true;
    this.groupListItem553.id = 10268;
    this.groupListItem553.default = true;
    this.groupListItem553.read = true;
    this.groupListItem554.id = 10269;
    this.groupListItem554.default = true;
    this.groupListItem554.read = true;
    this.groupListItem555.id = 10271;
    this.groupListItem555.default = true;
    this.groupListItem555.read = true;
    this.groupListItem556.id = 10272;
    this.groupListItem556.default = true;
    this.groupListItem556.read = true;
    this.groupListItem557.id = 10282;
    this.groupListItem557.default = true;
    this.groupListItem557.read = true;
    this.groupListItem558.id = 10291;
    this.groupListItem558.default = true;
    this.groupListItem558.read = true;
    this.groupListItem559.id = 10239;
    this.groupListItem559.default = true;
    this.groupListItem559.read = true;
    this.groupListItem560.id = 10059;
    this.groupListItem560.default = true;
    this.groupListItem560.read = true;
    this.groupListItem561.id = 10448;
    this.groupListItem561.default = true;
    this.groupListItem561.read = true;
    this.groupListItem562.id = 10449;
    this.groupListItem562.default = true;
    this.groupListItem562.read = true;
    this.groupListItem563.id = 10450;
    this.groupListItem563.default = true;
    this.groupListItem563.read = true;
    this.groupListItem564.id = 10451;
    this.groupListItem564.default = true;
    this.groupListItem564.read = true;
    this.groupListItem565.id = 10452;
    this.groupListItem565.default = true;
    this.groupListItem565.read = true;
    this.groupListItem566.id = 10453;
    this.groupListItem566.default = true;
    this.groupListItem566.read = true;
    this.groupListItem567.id = 10183;
    this.groupListItem567.default = true;
    this.groupListItem567.read = true;
    this.groupListItem568.id = 10454;
    this.groupListItem568.default = true;
    this.groupListItem568.read = true;
    this.groupListItem569.id = 10455;
    this.groupListItem569.default = true;
    this.groupListItem569.read = true;
    this.groupListItem570.id = 10456;
    this.groupListItem570.default = true;
    this.groupListItem570.read = true;
    this.groupListItem571.id = 10457;
    this.groupListItem571.default = true;
    this.groupListItem571.read = true;
    this.groupListItem572.id = 10458;
    this.groupListItem572.default = true;
    this.groupListItem572.read = true;
    this.groupListItem573.id = 10037;
    this.groupListItem573.default = true;
    this.groupListItem573.read = true;
    this.groupListItem574.id = 10038;
    this.groupListItem574.default = true;
    this.groupListItem574.read = true;
    this.groupListItem575.id = 10039;
    this.groupListItem575.default = true;
    this.groupListItem575.read = true;
    this.groupListItem576.id = 10195;
    this.groupListItem576.default = true;
    this.groupListItem576.read = true;
    this.groupListItem577.id = 10340;
    this.groupListItem577.default = true;
    this.groupListItem577.read = true;
    this.groupListItem578.id = 10460;
    this.groupListItem578.default = true;
    this.groupListItem578.read = true;
    this.groupListItem579.id = 10117;
    this.groupListItem579.default = true;
    this.groupListItem579.read = true;
    this.groupListItem580.id = 10102;
    this.groupListItem580.default = true;
    this.groupListItem580.read = true;
    this.groupListItem581.id = 10369;
    this.groupListItem581.default = true;
    this.groupListItem581.read = true;
    this.groupListItem582.id = 10459;
    this.groupListItem582.default = true;
    this.groupListItem582.read = true;
    this.groupListItem583.id = 10461;
    this.groupListItem583.default = true;
    this.groupListItem583.read = true;
    this.groupListItem584.id = 10013;
    this.groupListItem584.default = true;
    this.groupListItem584.read = true;
    this.groupListItem585.id = 10002;
    this.groupListItem585.default = true;
    this.groupListItem585.read = true;
    this.groupListItem586.id = 10462;
    this.groupListItem586.default = true;
    this.groupListItem586.read = true;
    this.groupListItem587.id = 10463;
    this.groupListItem587.default = true;
    this.groupListItem587.read = true;
    this.groupListItem588.id = 10119;
    this.groupListItem588.default = true;
    this.groupListItem588.read = true;
    this.groupListItem589.id = 10144;
    this.groupListItem589.default = true;
    this.groupListItem589.read = true;
    this.groupListItem590.id = 10378;
    this.groupListItem590.default = true;
    this.groupListItem590.read = true;
    this.groupListItem591.id = 10127;
    this.groupListItem591.default = true;
    this.groupListItem591.read = true;
    this.groupListItem592.id = 10464;
    this.groupListItem592.default = true;
    this.groupListItem592.read = true;
    this.groupListItem593.id = 10465;
    this.groupListItem593.default = true;
    this.groupListItem593.read = true;
    this.groupListItem594.id = 10466;
    this.groupListItem594.default = true;
    this.groupListItem594.read = true;
    this.groupListItem595.id = 10467;
    this.groupListItem595.default = true;
    this.groupListItem595.read = true;
    this.groupListItem596.id = 10468;
    this.groupListItem596.default = true;
    this.groupListItem596.read = true;
    this.groupListItem597.id = 10469;
    this.groupListItem597.default = true;
    this.groupListItem597.read = true;
    this.groupListItem598.id = 10470;
    this.groupListItem598.default = true;
    this.groupListItem598.read = true;
    this.groupListItem10000.id = 1000000;
    this.groupListItem10000.default = false;
    this.groupListItem10000.read = false;
    this.chatbotItem599.id = 10482;
    this.chatbotItem599.default = true;
    this.chatbotItem599.read = true;
    this.chatbotItem600.id = 10309;
    this.chatbotItem600.default = true;
    this.chatbotItem600.read = true;
    this.chatbotItem601.id = 10100;
    this.chatbotItem601.default = true;
    this.chatbotItem601.read = true;
    this.chatbotItem602.id = 10098;
    this.chatbotItem602.default = true;
    this.chatbotItem602.read = true;
    this.chatbotItem603.id = 10107;
    this.chatbotItem603.default = true;
    this.chatbotItem603.read = true;
    this.chatbotItem604.id = 10078;
    this.chatbotItem604.default = true;
    this.chatbotItem604.read = true;
    this.chatbotItem605.id = 10428;
    this.chatbotItem605.default = true;
    this.chatbotItem605.read = true;
    this.chatbotItem606.id = 10027;
    this.chatbotItem606.default = true;
    this.chatbotItem606.read = true;
    this.chatbotItem607.id = 10442;
    this.chatbotItem607.default = true;
    this.chatbotItem607.read = true;
    this.chatbotItem608.id = 10443;
    this.chatbotItem608.default = true;
    this.chatbotItem608.read = true;
    this.chatbotItem609.id = 10444;
    this.chatbotItem609.default = true;
    this.chatbotItem609.read = true;
    this.chatbotItem610.id = 10053;
    this.chatbotItem610.default = true;
    this.chatbotItem610.read = true;
    this.chatbotItem611.id = 10103;
    this.chatbotItem611.default = true;
    this.chatbotItem611.read = true;
    this.chatbotItem612.id = 10116;
    this.chatbotItem612.default = true;
    this.chatbotItem612.read = true;
    this.chatbotItem613.id = 10096;
    this.chatbotItem613.default = true;
    this.chatbotItem613.read = true;
    this.chatbotItem614.id = 10445;
    this.chatbotItem614.default = true;
    this.chatbotItem614.read = true;
    this.chatbotItem615.id = 10115;
    this.chatbotItem615.default = true;
    this.chatbotItem615.read = true;
    this.chatbotItem616.id = 10049;
    this.chatbotItem616.default = true;
    this.chatbotItem616.read = true;
    this.chatbotItem617.id = 10095;
    this.chatbotItem617.default = true;
    this.chatbotItem617.read = true;
    this.chatbotItem618.id = 10446;
    this.chatbotItem618.default = true;
    this.chatbotItem618.read = true;
    this.chatbotItem619.id = 10133;
    this.chatbotItem619.default = true;
    this.chatbotItem619.read = true;
    this.chatbotItem620.id = 10447;
    this.chatbotItem620.default = true;
    this.chatbotItem620.read = true;
    this.chatbotItem621.id = 10266;
    this.chatbotItem621.default = true;
    this.chatbotItem621.read = true;
    this.chatbotItem622.id = 10267;
    this.chatbotItem622.default = true;
    this.chatbotItem622.read = true;
    this.chatbotItem623.id = 10268;
    this.chatbotItem623.default = true;
    this.chatbotItem623.read = true;
    this.chatbotItem624.id = 10269;
    this.chatbotItem624.default = true;
    this.chatbotItem624.read = true;
    this.chatbotItem625.id = 10271;
    this.chatbotItem625.default = true;
    this.chatbotItem625.read = true;
    this.chatbotItem626.id = 10272;
    this.chatbotItem626.default = true;
    this.chatbotItem626.read = true;
    this.chatbotItem627.id = 10282;
    this.chatbotItem627.default = true;
    this.chatbotItem627.read = true;
    this.chatbotItem628.id = 10291;
    this.chatbotItem628.default = true;
    this.chatbotItem628.read = true;
    this.chatbotItem629.id = 10239;
    this.chatbotItem629.default = true;
    this.chatbotItem629.read = true;
    this.chatbotItem630.id = 10059;
    this.chatbotItem630.default = true;
    this.chatbotItem630.read = true;
    this.chatbotItem631.id = 10448;
    this.chatbotItem631.default = true;
    this.chatbotItem631.read = true;
    this.chatbotItem632.id = 10449;
    this.chatbotItem632.default = true;
    this.chatbotItem632.read = true;
    this.chatbotItem633.id = 10450;
    this.chatbotItem633.default = true;
    this.chatbotItem633.read = true;
    this.chatbotItem634.id = 10451;
    this.chatbotItem634.default = true;
    this.chatbotItem634.read = true;
    this.chatbotItem635.id = 10452;
    this.chatbotItem635.default = true;
    this.chatbotItem635.read = true;
    this.chatbotItem636.id = 10453;
    this.chatbotItem636.default = true;
    this.chatbotItem636.read = true;
    this.chatbotItem637.id = 10183;
    this.chatbotItem637.default = true;
    this.chatbotItem637.read = true;
    this.chatbotItem638.id = 10454;
    this.chatbotItem638.default = true;
    this.chatbotItem638.read = true;
    this.chatbotItem639.id = 10455;
    this.chatbotItem639.default = true;
    this.chatbotItem639.read = true;
    this.chatbotItem640.id = 10456;
    this.chatbotItem640.default = true;
    this.chatbotItem640.read = true;
    this.chatbotItem641.id = 10457;
    this.chatbotItem641.default = true;
    this.chatbotItem641.read = true;
    this.chatbotItem642.id = 10458;
    this.chatbotItem642.default = true;
    this.chatbotItem642.read = true;
    this.chatbotItem643.id = 10037;
    this.chatbotItem643.default = true;
    this.chatbotItem643.read = true;
    this.chatbotItem644.id = 10038;
    this.chatbotItem644.default = true;
    this.chatbotItem644.read = true;
    this.chatbotItem645.id = 10039;
    this.chatbotItem645.default = true;
    this.chatbotItem645.read = true;
    this.chatbotItem646.id = 10195;
    this.chatbotItem646.default = true;
    this.chatbotItem646.read = true;
    this.chatbotItem647.id = 10340;
    this.chatbotItem647.default = true;
    this.chatbotItem647.read = true;
    this.chatbotItem648.id = 10460;
    this.chatbotItem648.default = true;
    this.chatbotItem648.read = true;
    this.chatScreenItem649.id = 10098;
    this.chatScreenItem649.default = true;
    this.chatScreenItem649.read = true;
    this.chatScreenItem650.id = 10107;
    this.chatScreenItem650.default = true;
    this.chatScreenItem650.read = true;
    this.chatScreenItem651.id = 10078;
    this.chatScreenItem651.default = true;
    this.chatScreenItem651.read = true;
    this.chatScreenItem652.id = 10428;
    this.chatScreenItem652.default = true;
    this.chatScreenItem652.read = true;
    this.chatScreenItem653.id = 10027;
    this.chatScreenItem653.default = true;
    this.chatScreenItem653.read = true;
    this.chatScreenItem654.id = 10442;
    this.chatScreenItem654.default = true;
    this.chatScreenItem654.read = true;
    this.chatScreenItem655.id = 10443;
    this.chatScreenItem655.default = true;
    this.chatScreenItem655.read = true;
    this.chatScreenItem656.id = 10444;
    this.chatScreenItem656.default = true;
    this.chatScreenItem656.read = true;
    this.chatScreenItem657.id = 10053;
    this.chatScreenItem657.default = true;
    this.chatScreenItem657.read = true;
    this.chatScreenItem658.id = 10103;
    this.chatScreenItem658.default = true;
    this.chatScreenItem658.read = true;
    this.chatScreenItem659.id = 10116;
    this.chatScreenItem659.default = true;
    this.chatScreenItem659.read = true;
    this.chatScreenItem660.id = 10096;
    this.chatScreenItem660.default = true;
    this.chatScreenItem660.read = true;
    this.chatScreenItem661.id = 10445;
    this.chatScreenItem661.default = true;
    this.chatScreenItem661.read = true;
    this.chatScreenItem662.id = 10115;
    this.chatScreenItem662.default = true;
    this.chatScreenItem662.read = true;
    this.chatScreenItem663.id = 10049;
    this.chatScreenItem663.default = true;
    this.chatScreenItem663.read = true;
    this.chatScreenItem664.id = 10095;
    this.chatScreenItem664.default = true;
    this.chatScreenItem664.read = true;
    this.chatScreenItem665.id = 10446;
    this.chatScreenItem665.default = true;
    this.chatScreenItem665.read = true;
    this.chatScreenItem666.id = 10133;
    this.chatScreenItem666.default = true;
    this.chatScreenItem666.read = true;
    this.chatScreenItem667.id = 10447;
    this.chatScreenItem667.default = true;
    this.chatScreenItem667.read = true;
    this.chatScreenItem668.id = 10266;
    this.chatScreenItem668.default = true;
    this.chatScreenItem668.read = true;
    this.chatScreenItem669.id = 10267;
    this.chatScreenItem669.default = true;
    this.chatScreenItem669.read = true;
    this.chatScreenItem670.id = 10268;
    this.chatScreenItem670.default = true;
    this.chatScreenItem670.read = true;
    this.chatScreenItem671.id = 10269;
    this.chatScreenItem671.default = true;
    this.chatScreenItem671.read = true;
    this.chatScreenItem672.id = 10271;
    this.chatScreenItem672.default = true;
    this.chatScreenItem672.read = true;
    this.chatScreenItem673.id = 10272;
    this.chatScreenItem673.default = true;
    this.chatScreenItem673.read = true;
    this.chatScreenItem674.id = 10282;
    this.chatScreenItem674.default = true;
    this.chatScreenItem674.read = true;
    this.chatScreenItem675.id = 10291;
    this.chatScreenItem675.default = true;
    this.chatScreenItem675.read = true;
    this.chatScreenItem676.id = 10239;
    this.chatScreenItem676.default = true;
    this.chatScreenItem676.read = true;
    this.chatScreenItem677.id = 10059;
    this.chatScreenItem677.default = true;
    this.chatScreenItem677.read = true;
    this.chatScreenItem678.id = 10448;
    this.chatScreenItem678.default = true;
    this.chatScreenItem678.read = true;
    this.chatScreenItem679.id = 10449;
    this.chatScreenItem679.default = true;
    this.chatScreenItem679.read = true;
    this.chatScreenItem680.id = 10450;
    this.chatScreenItem680.default = true;
    this.chatScreenItem680.read = true;
    this.chatScreenItem681.id = 10451;
    this.chatScreenItem681.default = true;
    this.chatScreenItem681.read = true;
    this.chatScreenItem682.id = 10452;
    this.chatScreenItem682.default = true;
    this.chatScreenItem682.read = true;
    this.chatScreenItem683.id = 10453;
    this.chatScreenItem683.default = true;
    this.chatScreenItem683.read = true;
    this.chatScreenItem684.id = 10183;
    this.chatScreenItem684.default = true;
    this.chatScreenItem684.read = true;
    this.chatScreenItem685.id = 10454;
    this.chatScreenItem685.default = true;
    this.chatScreenItem685.read = true;
    this.chatScreenItem686.id = 10455;
    this.chatScreenItem686.default = true;
    this.chatScreenItem686.read = true;
    this.chatScreenItem687.id = 10456;
    this.chatScreenItem687.default = true;
    this.chatScreenItem687.read = true;
    this.chatScreenItem688.id = 10457;
    this.chatScreenItem688.default = true;
    this.chatScreenItem688.read = true;
    this.chatScreenItem689.id = 10458;
    this.chatScreenItem689.default = true;
    this.chatScreenItem689.read = true;
    this.chatScreenItem690.id = 10037;
    this.chatScreenItem690.default = true;
    this.chatScreenItem690.read = true;
    this.chatScreenItem691.id = 10038;
    this.chatScreenItem691.default = true;
    this.chatScreenItem691.read = true;
    this.chatScreenItem692.id = 10039;
    this.chatScreenItem692.default = true;
    this.chatScreenItem692.read = true;
    this.chatScreenItem693.id = 10195;
    this.chatScreenItem693.default = true;
    this.chatScreenItem693.read = true;
    this.chatScreenItem694.id = 10340;
    this.chatScreenItem694.default = true;
    this.chatScreenItem694.read = true;
    this.chatScreenItem695.id = 10460;
    this.chatScreenItem695.default = true;
    this.chatScreenItem695.read = true;
    this.chatGroupItem696.id = 10098;
    this.chatGroupItem696.default = true;
    this.chatGroupItem696.read = true;
    this.chatGroupItem697.id = 10107;
    this.chatGroupItem697.default = true;
    this.chatGroupItem697.read = true;
    this.chatGroupItem698.id = 10078;
    this.chatGroupItem698.default = true;
    this.chatGroupItem698.read = true;
    this.chatGroupItem699.id = 10428;
    this.chatGroupItem699.default = true;
    this.chatGroupItem699.read = true;
    this.chatGroupItem700.id = 10027;
    this.chatGroupItem700.default = true;
    this.chatGroupItem700.read = true;
    this.chatGroupItem701.id = 10442;
    this.chatGroupItem701.default = true;
    this.chatGroupItem701.read = true;
    this.chatGroupItem702.id = 10443;
    this.chatGroupItem702.default = true;
    this.chatGroupItem702.read = true;
    this.chatGroupItem703.id = 10444;
    this.chatGroupItem703.default = true;
    this.chatGroupItem703.read = true;
    this.chatGroupItem704.id = 10053;
    this.chatGroupItem704.default = true;
    this.chatGroupItem704.read = true;
    this.chatGroupItem705.id = 10103;
    this.chatGroupItem705.default = true;
    this.chatGroupItem705.read = true;
    this.chatGroupItem706.id = 10116;
    this.chatGroupItem706.default = true;
    this.chatGroupItem706.read = true;
    this.chatGroupItem707.id = 10096;
    this.chatGroupItem707.default = true;
    this.chatGroupItem707.read = true;
    this.chatGroupItem708.id = 10445;
    this.chatGroupItem708.default = true;
    this.chatGroupItem708.read = true;
    this.chatGroupItem709.id = 10115;
    this.chatGroupItem709.default = true;
    this.chatGroupItem709.read = true;
    this.chatGroupItem710.id = 10049;
    this.chatGroupItem710.default = true;
    this.chatGroupItem710.read = true;
    this.chatGroupItem711.id = 10095;
    this.chatGroupItem711.default = true;
    this.chatGroupItem711.read = true;
    this.chatGroupItem712.id = 10446;
    this.chatGroupItem712.default = true;
    this.chatGroupItem712.read = true;
    this.chatGroupItem713.id = 10133;
    this.chatGroupItem713.default = true;
    this.chatGroupItem713.read = true;
    this.chatGroupItem714.id = 10447;
    this.chatGroupItem714.default = true;
    this.chatGroupItem714.read = true;
    this.chatGroupItem715.id = 10266;
    this.chatGroupItem715.default = true;
    this.chatGroupItem715.read = true;
    this.chatGroupItem716.id = 10267;
    this.chatGroupItem716.default = true;
    this.chatGroupItem716.read = true;
    this.chatGroupItem717.id = 10268;
    this.chatGroupItem717.default = true;
    this.chatGroupItem717.read = true;
    this.chatGroupItem718.id = 10269;
    this.chatGroupItem718.default = true;
    this.chatGroupItem718.read = true;
    this.chatGroupItem719.id = 10271;
    this.chatGroupItem719.default = true;
    this.chatGroupItem719.read = true;
    this.chatGroupItem720.id = 10272;
    this.chatGroupItem720.default = true;
    this.chatGroupItem720.read = true;
    this.chatGroupItem721.id = 10282;
    this.chatGroupItem721.default = true;
    this.chatGroupItem721.read = true;
    this.chatGroupItem722.id = 10291;
    this.chatGroupItem722.default = true;
    this.chatGroupItem722.read = true;
    this.chatGroupItem723.id = 10239;
    this.chatGroupItem723.default = true;
    this.chatGroupItem723.read = true;
    this.chatGroupItem724.id = 10059;
    this.chatGroupItem724.default = true;
    this.chatGroupItem724.read = true;
    this.chatGroupItem725.id = 10448;
    this.chatGroupItem725.default = true;
    this.chatGroupItem725.read = true;
    this.chatGroupItem726.id = 10449;
    this.chatGroupItem726.default = true;
    this.chatGroupItem726.read = true;
    this.chatGroupItem727.id = 10450;
    this.chatGroupItem727.default = true;
    this.chatGroupItem727.read = true;
    this.chatGroupItem728.id = 10451;
    this.chatGroupItem728.default = true;
    this.chatGroupItem728.read = true;
    this.chatGroupItem729.id = 10452;
    this.chatGroupItem729.default = true;
    this.chatGroupItem729.read = true;
    this.chatGroupItem730.id = 10453;
    this.chatGroupItem730.default = true;
    this.chatGroupItem730.read = true;
    this.chatGroupItem731.id = 10183;
    this.chatGroupItem731.default = true;
    this.chatGroupItem731.read = true;
    this.chatGroupItem732.id = 10454;
    this.chatGroupItem732.default = true;
    this.chatGroupItem732.read = true;
    this.chatGroupItem733.id = 10455;
    this.chatGroupItem733.default = true;
    this.chatGroupItem733.read = true;
    this.chatGroupItem734.id = 10456;
    this.chatGroupItem734.default = true;
    this.chatGroupItem734.read = true;
    this.chatGroupItem735.id = 10457;
    this.chatGroupItem735.default = true;
    this.chatGroupItem735.read = true;
    this.chatGroupItem736.id = 10458;
    this.chatGroupItem736.default = true;
    this.chatGroupItem736.read = true;
    this.chatGroupItem737.id = 10037;
    this.chatGroupItem737.default = true;
    this.chatGroupItem737.read = true;
    this.chatGroupItem738.id = 10038;
    this.chatGroupItem738.default = true;
    this.chatGroupItem738.read = true;
    this.chatGroupItem739.id = 10039;
    this.chatGroupItem739.default = true;
    this.chatGroupItem739.read = true;
    this.chatGroupItem740.id = 10195;
    this.chatGroupItem740.default = true;
    this.chatGroupItem740.read = true;
    this.chatGroupItem741.id = 10340;
    this.chatGroupItem741.default = true;
    this.chatGroupItem741.read = true;
    this.chatGroupItem742.id = 10460;
    this.chatGroupItem742.default = true;
    this.chatGroupItem742.read = true;
    this.chatGroupItem743.id = 10117;
    this.chatGroupItem743.default = true;
    this.chatGroupItem743.read = true;
    this.chatGroupItem744.id = 10102;
    this.chatGroupItem744.default = true;
    this.chatGroupItem744.read = true;
    this.chatGroupItem745.id = 10369;
    this.chatGroupItem745.default = true;
    this.chatGroupItem745.read = true;
    this.chatGroupItem746.id = 10459;
    this.chatGroupItem746.default = true;
    this.chatGroupItem746.read = true;
    this.chatGroupItem747.id = 10461;
    this.chatGroupItem747.default = true;
    this.chatGroupItem747.read = true;
    this.chatGroupItem748.id = 10013;
    this.chatGroupItem748.default = true;
    this.chatGroupItem748.read = true;
    this.chatGroupItem749.id = 10002;
    this.chatGroupItem749.default = true;
    this.chatGroupItem749.read = true;
    this.chatGroupItem750.id = 10462;
    this.chatGroupItem750.default = true;
    this.chatGroupItem750.read = true;
    this.chatGroupItem751.id = 10463;
    this.chatGroupItem751.default = true;
    this.chatGroupItem751.read = true;
    this.chatGroupItem752.id = 10119;
    this.chatGroupItem752.default = true;
    this.chatGroupItem752.read = true;
    this.chatGroupItem753.id = 10144;
    this.chatGroupItem753.default = true;
    this.chatGroupItem753.read = true;
    this.chatGroupItem754.id = 10378;
    this.chatGroupItem754.default = true;
    this.chatGroupItem754.read = true;
    this.chatGroupItem755.id = 10127;
    this.chatGroupItem755.default = true;
    this.chatGroupItem755.read = true;
    this.chatGroupItem756.id = 10464;
    this.chatGroupItem756.default = true;
    this.chatGroupItem756.read = true;
    this.chatGroupItem757.id = 10465;
    this.chatGroupItem757.default = true;
    this.chatGroupItem757.read = true;
    this.chatGroupItem758.id = 10466;
    this.chatGroupItem758.default = true;
    this.chatGroupItem758.read = true;
    this.chatGroupItem759.id = 10467;
    this.chatGroupItem759.default = true;
    this.chatGroupItem759.read = true;
    this.chatGroupItem760.id = 10468;
    this.chatGroupItem760.default = true;
    this.chatGroupItem760.read = true;
    this.chatGroupItem761.id = 10469;
    this.chatGroupItem761.default = true;
    this.chatGroupItem761.read = true;
    this.chatGroupItem762.id = 10470;
    this.chatGroupItem762.default = true;
    this.chatGroupItem762.read = true;
    // this.postsFeedsItem764.id = 10483;
    // this.postsFeedsItem764.default = true;
    // this.postsFeedsItem764.read = false;
    // this.loyaltyCompetitionsItem765.id = 10483;
    // this.loyaltyCompetitionsItem765.default = true;
    // this.loyaltyCompetitionsItem765.read = false;
    // this.digitalIDItem766.id = 10483;
    // this.digitalIDItem766.default = true;
    // this.digitalIDItem766.read = false;
    // this.postsFeedsItem768.id = 10484;
    // this.postsFeedsItem768.default = false;
    // this.postsFeedsItem768.read = false;
    // this.loyaltyCompetitionsItem769.id = 10484;
    // this.loyaltyCompetitionsItem769.default = false;
    // this.loyaltyCompetitionsItem769.read = false;
    // this.digitalIDItem770.id = 10484;
    // this.digitalIDItem770.default = false;
    // this.digitalIDItem770.read = false;
    // this.loyaltyCompetitionsItem771.id = 10475;
    // this.loyaltyCompetitionsItem771.default = false;
    // this.loyaltyCompetitionsItem771.read = false;
    // this.loyaltyCompetitionsItem772.id = 10477;
    // this.loyaltyCompetitionsItem772.default = false;
    // this.loyaltyCompetitionsItem772.read = false;
    // this.loyaltyCompetitionsItem773.id = 10473;
    // this.loyaltyCompetitionsItem773.default = true;
    // this.loyaltyCompetitionsItem773.read = true;
    this.loyaltyCompetitionsItem774.id = 10098;
    this.loyaltyCompetitionsItem774.default = true;
    this.loyaltyCompetitionsItem774.read = true;
    this.loyaltyCompetitionsItem775.id = 10107;
    this.loyaltyCompetitionsItem775.default = true;
    this.loyaltyCompetitionsItem775.read = true;
    this.loyaltyCompetitionsItem776.id = 10078;
    this.loyaltyCompetitionsItem776.default = true;
    this.loyaltyCompetitionsItem776.read = true;
    this.loyaltyCompetitionsItem777.id = 10428;
    this.loyaltyCompetitionsItem777.default = true;
    this.loyaltyCompetitionsItem777.read = true;
    this.loyaltyCompetitionsItem778.id = 10027;
    this.loyaltyCompetitionsItem778.default = true;
    this.loyaltyCompetitionsItem778.read = true;
    this.loyaltyCompetitionsItem779.id = 10442;
    this.loyaltyCompetitionsItem779.default = true;
    this.loyaltyCompetitionsItem779.read = true;
    this.loyaltyCompetitionsItem780.id = 10443;
    this.loyaltyCompetitionsItem780.default = true;
    this.loyaltyCompetitionsItem780.read = true;
    this.loyaltyCompetitionsItem781.id = 10444;
    this.loyaltyCompetitionsItem781.default = true;
    this.loyaltyCompetitionsItem781.read = true;
    this.loyaltyCompetitionsItem782.id = 10053;
    this.loyaltyCompetitionsItem782.default = true;
    this.loyaltyCompetitionsItem782.read = true;
    this.loyaltyCompetitionsItem783.id = 10103;
    this.loyaltyCompetitionsItem783.default = true;
    this.loyaltyCompetitionsItem783.read = true;
    this.loyaltyCompetitionsItem784.id = 10116;
    this.loyaltyCompetitionsItem784.default = true;
    this.loyaltyCompetitionsItem784.read = true;
    this.loyaltyCompetitionsItem785.id = 10096;
    this.loyaltyCompetitionsItem785.default = true;
    this.loyaltyCompetitionsItem785.read = true;
    this.loyaltyCompetitionsItem786.id = 10445;
    this.loyaltyCompetitionsItem786.default = true;
    this.loyaltyCompetitionsItem786.read = true;
    this.loyaltyCompetitionsItem787.id = 10115;
    this.loyaltyCompetitionsItem787.default = true;
    this.loyaltyCompetitionsItem787.read = true;
    this.loyaltyCompetitionsItem788.id = 10049;
    this.loyaltyCompetitionsItem788.default = true;
    this.loyaltyCompetitionsItem788.read = true;
    this.loyaltyCompetitionsItem789.id = 10095;
    this.loyaltyCompetitionsItem789.default = true;
    this.loyaltyCompetitionsItem789.read = true;
    this.loyaltyCompetitionsItem790.id = 10446;
    this.loyaltyCompetitionsItem790.default = true;
    this.loyaltyCompetitionsItem790.read = true;
    this.loyaltyCompetitionsItem791.id = 10133;
    this.loyaltyCompetitionsItem791.default = true;
    this.loyaltyCompetitionsItem791.read = true;
    this.loyaltyCompetitionsItem792.id = 10447;
    this.loyaltyCompetitionsItem792.default = true;
    this.loyaltyCompetitionsItem792.read = true;
    this.loyaltyCompetitionsItem793.id = 10266;
    this.loyaltyCompetitionsItem793.default = true;
    this.loyaltyCompetitionsItem793.read = true;
    this.loyaltyCompetitionsItem794.id = 10267;
    this.loyaltyCompetitionsItem794.default = true;
    this.loyaltyCompetitionsItem794.read = true;
    this.loyaltyCompetitionsItem795.id = 10268;
    this.loyaltyCompetitionsItem795.default = true;
    this.loyaltyCompetitionsItem795.read = true;
    this.loyaltyCompetitionsItem796.id = 10269;
    this.loyaltyCompetitionsItem796.default = true;
    this.loyaltyCompetitionsItem796.read = true;
    this.loyaltyCompetitionsItem797.id = 10271;
    this.loyaltyCompetitionsItem797.default = true;
    this.loyaltyCompetitionsItem797.read = true;
    this.loyaltyCompetitionsItem798.id = 10272;
    this.loyaltyCompetitionsItem798.default = true;
    this.loyaltyCompetitionsItem798.read = true;
    this.loyaltyCompetitionsItem799.id = 10282;
    this.loyaltyCompetitionsItem799.default = true;
    this.loyaltyCompetitionsItem799.read = true;
    this.loyaltyCompetitionsItem800.id = 10291;
    this.loyaltyCompetitionsItem800.default = true;
    this.loyaltyCompetitionsItem800.read = true;
    this.loyaltyCompetitionsItem801.id = 10239;
    this.loyaltyCompetitionsItem801.default = true;
    this.loyaltyCompetitionsItem801.read = true;
    this.loyaltyCompetitionsItem802.id = 10059;
    this.loyaltyCompetitionsItem802.default = true;
    this.loyaltyCompetitionsItem802.read = true;
    this.loyaltyCompetitionsItem803.id = 10448;
    this.loyaltyCompetitionsItem803.default = true;
    this.loyaltyCompetitionsItem803.read = true;
    this.loyaltyCompetitionsItem804.id = 10449;
    this.loyaltyCompetitionsItem804.default = true;
    this.loyaltyCompetitionsItem804.read = true;
    this.loyaltyCompetitionsItem805.id = 10450;
    this.loyaltyCompetitionsItem805.default = true;
    this.loyaltyCompetitionsItem805.read = true;
    this.loyaltyCompetitionsItem806.id = 10451;
    this.loyaltyCompetitionsItem806.default = true;
    this.loyaltyCompetitionsItem806.read = true;
    this.loyaltyCompetitionsItem807.id = 10452;
    this.loyaltyCompetitionsItem807.default = true;
    this.loyaltyCompetitionsItem807.read = true;
    this.loyaltyCompetitionsItem808.id = 10453;
    this.loyaltyCompetitionsItem808.default = true;
    this.loyaltyCompetitionsItem808.read = true;
    this.loyaltyCompetitionsItem809.id = 10183;
    this.loyaltyCompetitionsItem809.default = true;
    this.loyaltyCompetitionsItem809.read = true;
    this.loyaltyCompetitionsItem810.id = 10454;
    this.loyaltyCompetitionsItem810.default = true;
    this.loyaltyCompetitionsItem810.read = true;
    this.loyaltyCompetitionsItem811.id = 10455;
    this.loyaltyCompetitionsItem811.default = true;
    this.loyaltyCompetitionsItem811.read = true;
    this.loyaltyCompetitionsItem812.id = 10456;
    this.loyaltyCompetitionsItem812.default = true;
    this.loyaltyCompetitionsItem812.read = true;
    this.loyaltyCompetitionsItem813.id = 10457;
    this.loyaltyCompetitionsItem813.default = true;
    this.loyaltyCompetitionsItem813.read = true;
    this.loyaltyCompetitionsItem814.id = 10458;
    this.loyaltyCompetitionsItem814.default = true;
    this.loyaltyCompetitionsItem814.read = true;
    this.loyaltyCompetitionsItem815.id = 10037;
    this.loyaltyCompetitionsItem815.default = true;
    this.loyaltyCompetitionsItem815.read = true;
    this.loyaltyCompetitionsItem816.id = 10038;
    this.loyaltyCompetitionsItem816.default = true;
    this.loyaltyCompetitionsItem816.read = true;
    this.loyaltyCompetitionsItem817.id = 10039;
    this.loyaltyCompetitionsItem817.default = true;
    this.loyaltyCompetitionsItem817.read = true;
    this.loyaltyCompetitionsItem818.id = 10195;
    this.loyaltyCompetitionsItem818.default = true;
    this.loyaltyCompetitionsItem818.read = true;
    this.loyaltyCompetitionsItem819.id = 10340;
    this.loyaltyCompetitionsItem819.default = true;
    this.loyaltyCompetitionsItem819.read = true;
    this.loyaltyCompetitionsItem820.id = 10460;
    this.loyaltyCompetitionsItem820.default = true;
    this.loyaltyCompetitionsItem820.read = true;
    this.loyaltyCompetitionsItem821.id = 10117;
    this.loyaltyCompetitionsItem821.default = true;
    this.loyaltyCompetitionsItem821.read = true;
    this.loyaltyCompetitionsItem822.id = 10102;
    this.loyaltyCompetitionsItem822.default = true;
    this.loyaltyCompetitionsItem822.read = true;
    this.loyaltyCompetitionsItem823.id = 10369;
    this.loyaltyCompetitionsItem823.default = true;
    this.loyaltyCompetitionsItem823.read = true;
    this.loyaltyCompetitionsItem824.id = 10459;
    this.loyaltyCompetitionsItem824.default = true;
    this.loyaltyCompetitionsItem824.read = true;
    this.loyaltyCompetitionsItem825.id = 10461;
    this.loyaltyCompetitionsItem825.default = true;
    this.loyaltyCompetitionsItem825.read = true;
    this.loyaltyCompetitionsItem826.id = 10013;
    this.loyaltyCompetitionsItem826.default = true;
    this.loyaltyCompetitionsItem826.read = true;
    this.loyaltyCompetitionsItem827.id = 10002;
    this.loyaltyCompetitionsItem827.default = true;
    this.loyaltyCompetitionsItem827.read = true;
    this.loyaltyCompetitionsItem828.id = 10462;
    this.loyaltyCompetitionsItem828.default = true;
    this.loyaltyCompetitionsItem828.read = true;
    this.loyaltyCompetitionsItem829.id = 10463;
    this.loyaltyCompetitionsItem829.default = true;
    this.loyaltyCompetitionsItem829.read = true;
    this.loyaltyCompetitionsItem830.id = 10119;
    this.loyaltyCompetitionsItem830.default = true;
    this.loyaltyCompetitionsItem830.read = true;
    this.loyaltyCompetitionsItem831.id = 10144;
    this.loyaltyCompetitionsItem831.default = true;
    this.loyaltyCompetitionsItem831.read = true;
    this.loyaltyCompetitionsItem832.id = 10378;
    this.loyaltyCompetitionsItem832.default = true;
    this.loyaltyCompetitionsItem832.read = true;
    this.loyaltyCompetitionsItem833.id = 10127;
    this.loyaltyCompetitionsItem833.default = true;
    this.loyaltyCompetitionsItem833.read = true;
    this.loyaltyCompetitionsItem834.id = 10464;
    this.loyaltyCompetitionsItem834.default = true;
    this.loyaltyCompetitionsItem834.read = true;
    this.loyaltyCompetitionsItem835.id = 10465;
    this.loyaltyCompetitionsItem835.default = true;
    this.loyaltyCompetitionsItem835.read = true;
    this.loyaltyCompetitionsItem836.id = 10466;
    this.loyaltyCompetitionsItem836.default = true;
    this.loyaltyCompetitionsItem836.read = true;
    this.loyaltyCompetitionsItem837.id = 10467;
    this.loyaltyCompetitionsItem837.default = true;
    this.loyaltyCompetitionsItem837.read = true;
    this.loyaltyCompetitionsItem838.id = 10468;
    this.loyaltyCompetitionsItem838.default = true;
    this.loyaltyCompetitionsItem838.read = true;
    this.loyaltyCompetitionsItem839.id = 10469;
    this.loyaltyCompetitionsItem839.default = true;
    this.loyaltyCompetitionsItem839.read = true;
    this.loyaltyCompetitionsItem840.id = 10470;
    this.loyaltyCompetitionsItem840.default = true;
    this.loyaltyCompetitionsItem840.read = true;
    this.loyaltyCompetitionsItem841.id = 10471;
    this.loyaltyCompetitionsItem841.default = true;
    this.loyaltyCompetitionsItem841.read = true;
    this.loyaltyCompetitionsItem842.id = 10472;
    this.loyaltyCompetitionsItem842.default = true;
    this.loyaltyCompetitionsItem842.read = true;
    this.loyaltyCompetitionsItem843.id = 10300;
    this.loyaltyCompetitionsItem843.default = true;
    this.loyaltyCompetitionsItem843.read = true;
    this.loyaltyCompetitionsItem844.id = 10126;
    this.loyaltyCompetitionsItem844.default = true;
    this.loyaltyCompetitionsItem844.read = true;
    this.loyaltyCompetitionsItem845.id = 10474;
    this.loyaltyCompetitionsItem845.default = true;
    this.loyaltyCompetitionsItem845.read = true;
    this.loyaltyCompetitionsItem846.id = 10476;
    this.loyaltyCompetitionsItem846.default = true;
    this.loyaltyCompetitionsItem846.read = true;
    this.loyaltyCompetitionsItem847.id = 10485;
    this.loyaltyCompetitionsItem847.default = false;
    this.loyaltyCompetitionsItem847.read = false;
    // this.digitalIDItem847.id = 10475;
    // this.digitalIDItem847.default = false;
    // this.digitalIDItem847.read = false;
    // this.digitalIDItem848.id = 10477;
    // this.digitalIDItem848.default = false;
    // this.digitalIDItem848.read = false;
    this.digitalIDItem849.id = 10098;
    this.digitalIDItem849.default = true;
    this.digitalIDItem849.read = true;
    this.digitalIDItem850.id = 10107;
    this.digitalIDItem850.default = true;
    this.digitalIDItem850.read = true;
    this.digitalIDItem851.id = 10078;
    this.digitalIDItem851.default = true;
    this.digitalIDItem851.read = true;
    this.digitalIDItem852.id = 10428;
    this.digitalIDItem852.default = true;
    this.digitalIDItem852.read = true;
    this.digitalIDItem853.id = 10027;
    this.digitalIDItem853.default = true;
    this.digitalIDItem853.read = true;
    this.digitalIDItem854.id = 10442;
    this.digitalIDItem854.default = true;
    this.digitalIDItem854.read = true;
    this.digitalIDItem855.id = 10443;
    this.digitalIDItem855.default = true;
    this.digitalIDItem855.read = true;
    this.digitalIDItem856.id = 10444;
    this.digitalIDItem856.default = true;
    this.digitalIDItem856.read = true;
    this.digitalIDItem857.id = 10053;
    this.digitalIDItem857.default = true;
    this.digitalIDItem857.read = true;
    this.digitalIDItem858.id = 10103;
    this.digitalIDItem858.default = true;
    this.digitalIDItem858.read = true;
    this.digitalIDItem859.id = 10116;
    this.digitalIDItem859.default = true;
    this.digitalIDItem859.read = true;
    this.digitalIDItem860.id = 10096;
    this.digitalIDItem860.default = true;
    this.digitalIDItem860.read = true;
    this.digitalIDItem861.id = 10445;
    this.digitalIDItem861.default = true;
    this.digitalIDItem861.read = true;
    this.digitalIDItem862.id = 10115;
    this.digitalIDItem862.default = true;
    this.digitalIDItem862.read = true;
    this.digitalIDItem863.id = 10049;
    this.digitalIDItem863.default = true;
    this.digitalIDItem863.read = true;
    this.digitalIDItem864.id = 10095;
    this.digitalIDItem864.default = true;
    this.digitalIDItem864.read = true;
    this.digitalIDItem865.id = 10446;
    this.digitalIDItem865.default = true;
    this.digitalIDItem865.read = true;
    this.digitalIDItem866.id = 10133;
    this.digitalIDItem866.default = true;
    this.digitalIDItem866.read = true;
    this.digitalIDItem867.id = 10447;
    this.digitalIDItem867.default = true;
    this.digitalIDItem867.read = true;
    this.digitalIDItem868.id = 10266;
    this.digitalIDItem868.default = true;
    this.digitalIDItem868.read = true;
    this.digitalIDItem869.id = 10267;
    this.digitalIDItem869.default = true;
    this.digitalIDItem869.read = true;
    this.digitalIDItem870.id = 10268;
    this.digitalIDItem870.default = true;
    this.digitalIDItem870.read = true;
    this.digitalIDItem871.id = 10269;
    this.digitalIDItem871.default = true;
    this.digitalIDItem871.read = true;
    this.digitalIDItem872.id = 10271;
    this.digitalIDItem872.default = true;
    this.digitalIDItem872.read = true;
    this.digitalIDItem873.id = 10272;
    this.digitalIDItem873.default = true;
    this.digitalIDItem873.read = true;
    this.digitalIDItem874.id = 10282;
    this.digitalIDItem874.default = true;
    this.digitalIDItem874.read = true;
    this.digitalIDItem875.id = 10291;
    this.digitalIDItem875.default = true;
    this.digitalIDItem875.read = true;
    this.digitalIDItem876.id = 10239;
    this.digitalIDItem876.default = true;
    this.digitalIDItem876.read = true;
    this.digitalIDItem877.id = 10059;
    this.digitalIDItem877.default = true;
    this.digitalIDItem877.read = true;
    this.digitalIDItem878.id = 10448;
    this.digitalIDItem878.default = true;
    this.digitalIDItem878.read = true;
    this.digitalIDItem879.id = 10449;
    this.digitalIDItem879.default = true;
    this.digitalIDItem879.read = true;
    this.digitalIDItem880.id = 10450;
    this.digitalIDItem880.default = true;
    this.digitalIDItem880.read = true;
    this.digitalIDItem881.id = 10451;
    this.digitalIDItem881.default = true;
    this.digitalIDItem881.read = true;
    this.digitalIDItem882.id = 10452;
    this.digitalIDItem882.default = true;
    this.digitalIDItem882.read = true;
    this.digitalIDItem883.id = 10453;
    this.digitalIDItem883.default = true;
    this.digitalIDItem883.read = true;
    this.digitalIDItem884.id = 10183;
    this.digitalIDItem884.default = true;
    this.digitalIDItem884.read = true;
    this.digitalIDItem885.id = 10454;
    this.digitalIDItem885.default = true;
    this.digitalIDItem885.read = true;
    this.digitalIDItem886.id = 10455;
    this.digitalIDItem886.default = true;
    this.digitalIDItem886.read = true;
    this.digitalIDItem887.id = 10456;
    this.digitalIDItem887.default = true;
    this.digitalIDItem887.read = true;
    this.digitalIDItem888.id = 10457;
    this.digitalIDItem888.default = true;
    this.digitalIDItem888.read = true;
    this.digitalIDItem889.id = 10458;
    this.digitalIDItem889.default = true;
    this.digitalIDItem889.read = true;
    this.digitalIDItem890.id = 10037;
    this.digitalIDItem890.default = true;
    this.digitalIDItem890.read = true;
    this.digitalIDItem891.id = 10038;
    this.digitalIDItem891.default = true;
    this.digitalIDItem891.read = true;
    this.digitalIDItem892.id = 10039;
    this.digitalIDItem892.default = true;
    this.digitalIDItem892.read = true;
    this.digitalIDItem893.id = 10195;
    this.digitalIDItem893.default = true;
    this.digitalIDItem893.read = true;
    this.digitalIDItem894.id = 10340;
    this.digitalIDItem894.default = true;
    this.digitalIDItem894.read = true;
    this.digitalIDItem895.id = 10460;
    this.digitalIDItem895.default = true;
    this.digitalIDItem895.read = true;
    this.digitalIDItem896.id = 10117;
    this.digitalIDItem896.default = true;
    this.digitalIDItem896.read = true;
    this.digitalIDItem897.id = 10102;
    this.digitalIDItem897.default = true;
    this.digitalIDItem897.read = true;
    this.digitalIDItem898.id = 10369;
    this.digitalIDItem898.default = true;
    this.digitalIDItem898.read = true;
    this.digitalIDItem899.id = 10459;
    this.digitalIDItem899.default = true;
    this.digitalIDItem899.read = true;
    this.digitalIDItem900.id = 10461;
    this.digitalIDItem900.default = true;
    this.digitalIDItem900.read = true;
    this.digitalIDItem901.id = 10013;
    this.digitalIDItem901.default = true;
    this.digitalIDItem901.read = true;
    this.digitalIDItem902.id = 10002;
    this.digitalIDItem902.default = true;
    this.digitalIDItem902.read = true;
    this.digitalIDItem903.id = 10462;
    this.digitalIDItem903.default = true;
    this.digitalIDItem903.read = true;
    this.digitalIDItem904.id = 10463;
    this.digitalIDItem904.default = true;
    this.digitalIDItem904.read = true;
    this.digitalIDItem905.id = 10119;
    this.digitalIDItem905.default = true;
    this.digitalIDItem905.read = true;
    this.digitalIDItem906.id = 10144;
    this.digitalIDItem906.default = true;
    this.digitalIDItem906.read = true;
    this.digitalIDItem907.id = 10378;
    this.digitalIDItem907.default = true;
    this.digitalIDItem907.read = true;
    this.digitalIDItem908.id = 10127;
    this.digitalIDItem908.default = true;
    this.digitalIDItem908.read = true;
    this.digitalIDItem909.id = 10464;
    this.digitalIDItem909.default = true;
    this.digitalIDItem909.read = true;
    this.digitalIDItem910.id = 10465;
    this.digitalIDItem910.default = true;
    this.digitalIDItem910.read = true;
    this.digitalIDItem911.id = 10466;
    this.digitalIDItem911.default = true;
    this.digitalIDItem911.read = true;
    this.digitalIDItem912.id = 10467;
    this.digitalIDItem912.default = true;
    this.digitalIDItem912.read = true;
    this.digitalIDItem913.id = 10468;
    this.digitalIDItem913.default = true;
    this.digitalIDItem913.read = true;
    this.digitalIDItem914.id = 10469;
    this.digitalIDItem914.default = true;
    this.digitalIDItem914.read = true;
    this.digitalIDItem915.id = 10470;
    this.digitalIDItem915.default = true;
    this.digitalIDItem915.read = true;
    this.digitalIDItem916.id = 10471;
    this.digitalIDItem916.default = true;
    this.digitalIDItem916.read = true;
    this.digitalIDItem917.id = 10472;
    this.digitalIDItem917.default = true;
    this.digitalIDItem917.read = true;
    this.digitalIDItem918.id = 10300;
    this.digitalIDItem918.default = true;
    this.digitalIDItem918.read = true;
    this.digitalIDItem919.id = 10126;
    this.digitalIDItem919.default = true;
    this.digitalIDItem919.read = true;
    this.digitalIDItem920.id = 10474;
    this.digitalIDItem920.default = true;
    this.digitalIDItem920.read = true;
    this.digitalIDItem921.id = 10476;
    this.digitalIDItem921.default = true;
    this.digitalIDItem921.read = true;
    this.calendarItem1.id = 15000;
    this.calendarItem1.default = true;
    this.calendarItem1.read = true;
    this.userInvitationsItem1.id = 10013;
    this.userInvitationsItem1.default = true;
    this.userInvitationsItem1.read = true;
    this.userInvitationsItem2.id = 10464;
    this.userInvitationsItem2.default = true;
    this.userInvitationsItem2.read = true;
    this.webviewItem1.id = 15001;
    this.webviewItem1.default = true;
    this.webviewItem1.read = true;
    this.searchItem1.id = 10051;
    this.searchItem1.default = true;
    this.searchItem1.read = true;
    this.searchItem2.id = 10065;
    this.searchItem2.default = true;
    this.searchItem2.read = true;
    this.searchItem3.id = 10076;
    this.searchItem3.default = true;
    this.searchItem3.read = true;
    this.searchItem4.id = 10078;
    this.searchItem4.default = true;
    this.searchItem4.read = true;
    this.ticketValidateItem935.id = 30000;
    this.ticketValidateItem935.default = true;
    this.ticketValidateItem935.read = true;
    this.ticketRedeemItem936.id = 30001;
    this.ticketRedeemItem936.default = true;
    this.ticketRedeemItem936.read = true;
    this.channelsListItem942.id = 10481;
    this.channelsListItem942.default = true;
    this.channelsListItem942.read = true;
    this.channelsListItem943.id = 10065;
    this.channelsListItem943.default = true;
    this.channelsListItem943.read = true;
    this.channelsListItem944.id = 10261;
    this.channelsListItem944.default = true;
    this.channelsListItem944.read = true;
    this.channelsListItem945.id = 10104;
    this.channelsListItem945.default = true;
    this.channelsListItem945.read = true;
    this.channelsListItem946.id = 10098;
    this.channelsListItem946.default = true;
    this.channelsListItem946.read = true;
    this.channelsListItem947.id = 10107;
    this.channelsListItem947.default = true;
    this.channelsListItem947.read = true;
    this.channelsListItem948.id = 10078;
    this.channelsListItem948.default = true;
    this.channelsListItem948.read = true;
    this.channelsListItem949.id = 10428;
    this.channelsListItem949.default = true;
    this.channelsListItem949.read = true;
    this.channelsListItem950.id = 10027;
    this.channelsListItem950.default = true;
    this.channelsListItem950.read = true;
    this.channelsListItem951.id = 10442;
    this.channelsListItem951.default = true;
    this.channelsListItem951.read = true;
    this.channelsListItem952.id = 10443;
    this.channelsListItem952.default = true;
    this.channelsListItem952.read = true;
    this.channelsListItem953.id = 10444;
    this.channelsListItem953.default = true;
    this.channelsListItem953.read = true;
    this.channelsListItem954.id = 10053;
    this.channelsListItem954.default = true;
    this.channelsListItem954.read = true;
    this.channelsListItem955.id = 10103;
    this.channelsListItem955.default = true;
    this.channelsListItem955.read = true;
    this.channelsListItem956.id = 10116;
    this.channelsListItem956.default = true;
    this.channelsListItem956.read = true;
    this.channelsListItem957.id = 10096;
    this.channelsListItem957.default = true;
    this.channelsListItem957.read = true;
    this.channelsListItem958.id = 10445;
    this.channelsListItem958.default = true;
    this.channelsListItem958.read = true;
    this.channelsListItem959.id = 10115;
    this.channelsListItem959.default = true;
    this.channelsListItem959.read = true;
    this.channelsListItem960.id = 10049;
    this.channelsListItem960.default = true;
    this.channelsListItem960.read = true;
    this.channelsListItem961.id = 10095;
    this.channelsListItem961.default = true;
    this.channelsListItem961.read = true;
    this.channelsListItem962.id = 10446;
    this.channelsListItem962.default = true;
    this.channelsListItem962.read = true;
    this.channelsListItem963.id = 10133;
    this.channelsListItem963.default = true;
    this.channelsListItem963.read = true;
    this.channelsListItem964.id = 10447;
    this.channelsListItem964.default = true;
    this.channelsListItem964.read = true;
    this.channelsListItem965.id = 10266;
    this.channelsListItem965.default = true;
    this.channelsListItem965.read = true;
    this.channelsListItem966.id = 10267;
    this.channelsListItem966.default = true;
    this.channelsListItem966.read = true;
    this.channelsListItem967.id = 10268;
    this.channelsListItem967.default = true;
    this.channelsListItem967.read = true;
    this.channelsListItem968.id = 10269;
    this.channelsListItem968.default = true;
    this.channelsListItem968.read = true;
    this.channelsListItem969.id = 10271;
    this.channelsListItem969.default = true;
    this.channelsListItem969.read = true;
    this.channelsListItem970.id = 10272;
    this.channelsListItem970.default = true;
    this.channelsListItem970.read = true;
    this.channelsListItem971.id = 10282;
    this.channelsListItem971.default = true;
    this.channelsListItem971.read = true;
    this.channelsListItem972.id = 10291;
    this.channelsListItem972.default = true;
    this.channelsListItem972.read = true;
    this.channelsListItem973.id = 10239;
    this.channelsListItem973.default = true;
    this.channelsListItem973.read = true;
    this.channelsListItem974.id = 10059;
    this.channelsListItem974.default = true;
    this.channelsListItem974.read = true;
    this.channelsListItem975.id = 10448;
    this.channelsListItem975.default = true;
    this.channelsListItem975.read = true;
    this.channelsListItem976.id = 10449;
    this.channelsListItem976.default = true;
    this.channelsListItem976.read = true;
    this.channelsListItem977.id = 10450;
    this.channelsListItem977.default = true;
    this.channelsListItem977.read = true;
    this.channelsListItem978.id = 10451;
    this.channelsListItem978.default = true;
    this.channelsListItem978.read = true;
    this.channelsListItem979.id = 10452;
    this.channelsListItem979.default = true;
    this.channelsListItem979.read = true;
    this.channelsListItem980.id = 10453;
    this.channelsListItem980.default = true;
    this.channelsListItem980.read = true;
    this.channelsListItem981.id = 10183;
    this.channelsListItem981.default = true;
    this.channelsListItem981.read = true;
    this.channelsListItem982.id = 10454;
    this.channelsListItem982.default = true;
    this.channelsListItem982.read = true;
    this.channelsListItem983.id = 10455;
    this.channelsListItem983.default = true;
    this.channelsListItem983.read = true;
    this.channelsListItem984.id = 10456;
    this.channelsListItem984.default = true;
    this.channelsListItem984.read = true;
    this.channelsListItem985.id = 10457;
    this.channelsListItem985.default = true;
    this.channelsListItem985.read = true;
    this.channelsListItem986.id = 10458;
    this.channelsListItem986.default = true;
    this.channelsListItem986.read = true;
    this.channelsListItem987.id = 10037;
    this.channelsListItem987.default = true;
    this.channelsListItem987.read = true;
    this.channelsListItem988.id = 10038;
    this.channelsListItem988.default = true;
    this.channelsListItem988.read = true;
    this.channelsListItem989.id = 10039;
    this.channelsListItem989.default = true;
    this.channelsListItem989.read = true;
    this.channelsListItem990.id = 10195;
    this.channelsListItem990.default = true;
    this.channelsListItem990.read = true;
    this.channelsListItem991.id = 10340;
    this.channelsListItem991.default = true;
    this.channelsListItem991.read = true;
    this.channelsListItem992.id = 10460;
    this.channelsListItem992.default = true;
    this.channelsListItem992.read = true;
    this.channelsListItem993.id = 10117;
    this.channelsListItem993.default = true;
    this.channelsListItem993.read = true;
    this.channelsListItem994.id = 10102;
    this.channelsListItem994.default = true;
    this.channelsListItem994.read = true;
    this.channelsListItem995.id = 10369;
    this.channelsListItem995.default = true;
    this.channelsListItem995.read = true;
    this.channelsListItem996.id = 10459;
    this.channelsListItem996.default = true;
    this.channelsListItem996.read = true;
    this.channelsListItem997.id = 10461;
    this.channelsListItem997.default = true;
    this.channelsListItem997.read = true;
    this.channelsListItem998.id = 10013;
    this.channelsListItem998.default = true;
    this.channelsListItem998.read = true;
    this.channelsListItem999.id = 10002;
    this.channelsListItem999.default = true;
    this.channelsListItem999.read = true;
    this.channelsListItem1000.id = 10462;
    this.channelsListItem1000.default = true;
    this.channelsListItem1000.read = true;
    this.channelsListItem1001.id = 10463;
    this.channelsListItem1001.default = true;
    this.channelsListItem1001.read = true;
    this.channelsListItem1002.id = 10119;
    this.channelsListItem1002.default = true;
    this.channelsListItem1002.read = true;
    this.channelsListItem1003.id = 10144;
    this.channelsListItem1003.default = true;
    this.channelsListItem1003.read = true;
    this.channelsListItem1004.id = 10378;
    this.channelsListItem1004.default = true;
    this.channelsListItem1004.read = true;
    this.channelsListItem1005.id = 10127;
    this.channelsListItem1005.default = true;
    this.channelsListItem1005.read = true;
    this.channelsListItem1006.id = 10464;
    this.channelsListItem1006.default = true;
    this.channelsListItem1006.read = true;
    this.channelsListItem1007.id = 10465;
    this.channelsListItem1007.default = true;
    this.channelsListItem1007.read = true;
    this.channelsListItem1008.id = 10466;
    this.channelsListItem1008.default = true;
    this.channelsListItem1008.read = true;
    this.channelsListItem1009.id = 10467;
    this.channelsListItem1009.default = true;
    this.channelsListItem1009.read = true;
    this.channelsListItem1010.id = 10468;
    this.channelsListItem1010.default = true;
    this.channelsListItem1010.read = true;
    this.channelsListItem1011.id = 10469;
    this.channelsListItem1011.default = true;
    this.channelsListItem1011.read = true;
    this.channelsListItem1012.id = 10470;
    this.channelsListItem1012.default = true;
    this.channelsListItem1012.read = true;
    this.channelsListItem1013.id = 10471;
    this.channelsListItem1013.default = true;
    this.channelsListItem1013.read = true;
    this.channelsListItem1014.id = 10472;
    this.channelsListItem1014.default = true;
    this.channelsListItem1014.read = true;
    this.channelsListItem1015.id = 10300;
    this.channelsListItem1015.default = true;
    this.channelsListItem1015.read = true;
    this.channelsListItem1016.id = 10126;
    this.channelsListItem1016.default = true;
    this.channelsListItem1016.read = true;
    this.channelsListItem1017.id = 10474;
    this.channelsListItem1017.default = true;
    this.channelsListItem1017.read = true;
    // this.channelsListItem1018.id = 10475;
    // this.channelsListItem1018.default = false;
    // this.channelsListItem1018.read = false;
    this.channelsListItem1019.id = 10476;
    this.channelsListItem1019.default = true;
    this.channelsListItem1019.read = true;
    this.onlineGroupsListItem1020.id = 10481;
    this.onlineGroupsListItem1020.default = true;
    this.onlineGroupsListItem1020.read = true;
    this.onlineGroupsListItem1021.id = 10065;
    this.onlineGroupsListItem1021.default = true;
    this.onlineGroupsListItem1021.read = true;
    this.onlineGroupsListItem1022.id = 10261;
    this.onlineGroupsListItem1022.default = true;
    this.onlineGroupsListItem1022.read = true;
    this.onlineGroupsListItem1023.id = 10104;
    this.onlineGroupsListItem1023.default = true;
    this.onlineGroupsListItem1023.read = true;
    this.onlineGroupsListItem1024.id = 10098;
    this.onlineGroupsListItem1024.default = true;
    this.onlineGroupsListItem1024.read = true;
    this.onlineGroupsListItem1025.id = 10107;
    this.onlineGroupsListItem1025.default = true;
    this.onlineGroupsListItem1025.read = true;
    this.onlineGroupsListItem1026.id = 10078;
    this.onlineGroupsListItem1026.default = true;
    this.onlineGroupsListItem1026.read = true;
    this.onlineGroupsListItem1027.id = 10428;
    this.onlineGroupsListItem1027.default = true;
    this.onlineGroupsListItem1027.read = true;
    this.onlineGroupsListItem1028.id = 10027;
    this.onlineGroupsListItem1028.default = true;
    this.onlineGroupsListItem1028.read = true;
    this.onlineGroupsListItem1029.id = 10442;
    this.onlineGroupsListItem1029.default = true;
    this.onlineGroupsListItem1029.read = true;
    this.onlineGroupsListItem1030.id = 10443;
    this.onlineGroupsListItem1030.default = true;
    this.onlineGroupsListItem1030.read = true;
    this.onlineGroupsListItem1031.id = 10444;
    this.onlineGroupsListItem1031.default = true;
    this.onlineGroupsListItem1031.read = true;
    this.onlineGroupsListItem1032.id = 10053;
    this.onlineGroupsListItem1032.default = true;
    this.onlineGroupsListItem1032.read = true;
    this.onlineGroupsListItem1033.id = 10103;
    this.onlineGroupsListItem1033.default = true;
    this.onlineGroupsListItem1033.read = true;
    this.onlineGroupsListItem1034.id = 10116;
    this.onlineGroupsListItem1034.default = true;
    this.onlineGroupsListItem1034.read = true;
    this.onlineGroupsListItem1035.id = 10096;
    this.onlineGroupsListItem1035.default = true;
    this.onlineGroupsListItem1035.read = true;
    this.onlineGroupsListItem1036.id = 10445;
    this.onlineGroupsListItem1036.default = true;
    this.onlineGroupsListItem1036.read = true;
    this.onlineGroupsListItem1037.id = 10115;
    this.onlineGroupsListItem1037.default = true;
    this.onlineGroupsListItem1037.read = true;
    this.onlineGroupsListItem1038.id = 10049;
    this.onlineGroupsListItem1038.default = true;
    this.onlineGroupsListItem1038.read = true;
    this.onlineGroupsListItem1039.id = 10095;
    this.onlineGroupsListItem1039.default = true;
    this.onlineGroupsListItem1039.read = true;
    this.onlineGroupsListItem1040.id = 10446;
    this.onlineGroupsListItem1040.default = true;
    this.onlineGroupsListItem1040.read = true;
    this.onlineGroupsListItem1041.id = 10133;
    this.onlineGroupsListItem1041.default = true;
    this.onlineGroupsListItem1041.read = true;
    this.onlineGroupsListItem1042.id = 10447;
    this.onlineGroupsListItem1042.default = true;
    this.onlineGroupsListItem1042.read = true;
    this.onlineGroupsListItem1043.id = 10266;
    this.onlineGroupsListItem1043.default = true;
    this.onlineGroupsListItem1043.read = true;
    this.onlineGroupsListItem1044.id = 10267;
    this.onlineGroupsListItem1044.default = true;
    this.onlineGroupsListItem1044.read = true;
    this.onlineGroupsListItem1045.id = 10268;
    this.onlineGroupsListItem1045.default = true;
    this.onlineGroupsListItem1045.read = true;
    this.onlineGroupsListItem1046.id = 10269;
    this.onlineGroupsListItem1046.default = true;
    this.onlineGroupsListItem1046.read = true;
    this.onlineGroupsListItem1047.id = 10271;
    this.onlineGroupsListItem1047.default = true;
    this.onlineGroupsListItem1047.read = true;
    this.onlineGroupsListItem1048.id = 10272;
    this.onlineGroupsListItem1048.default = true;
    this.onlineGroupsListItem1048.read = true;
    this.onlineGroupsListItem1049.id = 10282;
    this.onlineGroupsListItem1049.default = true;
    this.onlineGroupsListItem1049.read = true;
    this.onlineGroupsListItem1050.id = 10291;
    this.onlineGroupsListItem1050.default = true;
    this.onlineGroupsListItem1050.read = true;
    this.onlineGroupsListItem1051.id = 10239;
    this.onlineGroupsListItem1051.default = true;
    this.onlineGroupsListItem1051.read = true;
    this.onlineGroupsListItem1052.id = 10059;
    this.onlineGroupsListItem1052.default = true;
    this.onlineGroupsListItem1052.read = true;
    this.onlineGroupsListItem1053.id = 10448;
    this.onlineGroupsListItem1053.default = true;
    this.onlineGroupsListItem1053.read = true;
    this.onlineGroupsListItem1054.id = 10449;
    this.onlineGroupsListItem1054.default = true;
    this.onlineGroupsListItem1054.read = true;
    this.onlineGroupsListItem1055.id = 10450;
    this.onlineGroupsListItem1055.default = true;
    this.onlineGroupsListItem1055.read = true;
    this.onlineGroupsListItem1056.id = 10451;
    this.onlineGroupsListItem1056.default = true;
    this.onlineGroupsListItem1056.read = true;
    this.onlineGroupsListItem1057.id = 10452;
    this.onlineGroupsListItem1057.default = true;
    this.onlineGroupsListItem1057.read = true;
    this.onlineGroupsListItem1058.id = 10453;
    this.onlineGroupsListItem1058.default = true;
    this.onlineGroupsListItem1058.read = true;
    this.onlineGroupsListItem1059.id = 10183;
    this.onlineGroupsListItem1059.default = true;
    this.onlineGroupsListItem1059.read = true;
    this.onlineGroupsListItem1060.id = 10454;
    this.onlineGroupsListItem1060.default = true;
    this.onlineGroupsListItem1060.read = true;
    this.onlineGroupsListItem1061.id = 10455;
    this.onlineGroupsListItem1061.default = true;
    this.onlineGroupsListItem1061.read = true;
    this.onlineGroupsListItem1062.id = 10456;
    this.onlineGroupsListItem1062.default = true;
    this.onlineGroupsListItem1062.read = true;
    this.onlineGroupsListItem1063.id = 10457;
    this.onlineGroupsListItem1063.default = true;
    this.onlineGroupsListItem1063.read = true;
    this.onlineGroupsListItem1064.id = 10458;
    this.onlineGroupsListItem1064.default = true;
    this.onlineGroupsListItem1064.read = true;
    this.onlineGroupsListItem1065.id = 10037;
    this.onlineGroupsListItem1065.default = true;
    this.onlineGroupsListItem1065.read = true;
    this.onlineGroupsListItem1066.id = 10038;
    this.onlineGroupsListItem1066.default = true;
    this.onlineGroupsListItem1066.read = true;
    this.onlineGroupsListItem1067.id = 10039;
    this.onlineGroupsListItem1067.default = true;
    this.onlineGroupsListItem1067.read = true;
    this.onlineGroupsListItem1068.id = 10195;
    this.onlineGroupsListItem1068.default = true;
    this.onlineGroupsListItem1068.read = true;
    this.onlineGroupsListItem1069.id = 10340;
    this.onlineGroupsListItem1069.default = true;
    this.onlineGroupsListItem1069.read = true;
    this.onlineGroupsListItem1070.id = 10460;
    this.onlineGroupsListItem1070.default = true;
    this.onlineGroupsListItem1070.read = true;
    this.onlineGroupsListItem1071.id = 10117;
    this.onlineGroupsListItem1071.default = true;
    this.onlineGroupsListItem1071.read = true;
    this.onlineGroupsListItem1072.id = 10102;
    this.onlineGroupsListItem1072.default = true;
    this.onlineGroupsListItem1072.read = true;
    this.onlineGroupsListItem1073.id = 10369;
    this.onlineGroupsListItem1073.default = true;
    this.onlineGroupsListItem1073.read = true;
    this.onlineGroupsListItem1074.id = 10459;
    this.onlineGroupsListItem1074.default = true;
    this.onlineGroupsListItem1074.read = true;
    this.onlineGroupsListItem1075.id = 10461;
    this.onlineGroupsListItem1075.default = true;
    this.onlineGroupsListItem1075.read = true;
    this.onlineGroupsListItem1076.id = 10013;
    this.onlineGroupsListItem1076.default = true;
    this.onlineGroupsListItem1076.read = true;
    this.onlineGroupsListItem1077.id = 10002;
    this.onlineGroupsListItem1077.default = true;
    this.onlineGroupsListItem1077.read = true;
    this.onlineGroupsListItem1078.id = 10462;
    this.onlineGroupsListItem1078.default = true;
    this.onlineGroupsListItem1078.read = true;
    this.onlineGroupsListItem1079.id = 10463;
    this.onlineGroupsListItem1079.default = true;
    this.onlineGroupsListItem1079.read = true;
    this.onlineGroupsListItem1080.id = 10119;
    this.onlineGroupsListItem1080.default = true;
    this.onlineGroupsListItem1080.read = true;
    this.onlineGroupsListItem1081.id = 10144;
    this.onlineGroupsListItem1081.default = true;
    this.onlineGroupsListItem1081.read = true;
    this.onlineGroupsListItem1082.id = 10378;
    this.onlineGroupsListItem1082.default = true;
    this.onlineGroupsListItem1082.read = true;
    this.onlineGroupsListItem1083.id = 10127;
    this.onlineGroupsListItem1083.default = true;
    this.onlineGroupsListItem1083.read = true;
    this.onlineGroupsListItem1084.id = 10464;
    this.onlineGroupsListItem1084.default = true;
    this.onlineGroupsListItem1084.read = true;
    this.onlineGroupsListItem1085.id = 10465;
    this.onlineGroupsListItem1085.default = true;
    this.onlineGroupsListItem1085.read = true;
    this.onlineGroupsListItem1086.id = 10466;
    this.onlineGroupsListItem1086.default = true;
    this.onlineGroupsListItem1086.read = true;
    this.onlineGroupsListItem1087.id = 10467;
    this.onlineGroupsListItem1087.default = true;
    this.onlineGroupsListItem1087.read = true;
    this.onlineGroupsListItem1088.id = 10468;
    this.onlineGroupsListItem1088.default = true;
    this.onlineGroupsListItem1088.read = true;
    this.onlineGroupsListItem1089.id = 10469;
    this.onlineGroupsListItem1089.default = true;
    this.onlineGroupsListItem1089.read = true;
    this.onlineGroupsListItem1090.id = 10470;
    this.onlineGroupsListItem1090.default = true;
    this.onlineGroupsListItem1090.read = true;
    this.onlineGroupsListItem1091.id = 10471;
    this.onlineGroupsListItem1091.default = true;
    this.onlineGroupsListItem1091.read = true;
    this.onlineGroupsListItem1092.id = 10472;
    this.onlineGroupsListItem1092.default = true;
    this.onlineGroupsListItem1092.read = true;
    this.onlineGroupsListItem1093.id = 10300;
    this.onlineGroupsListItem1093.default = true;
    this.onlineGroupsListItem1093.read = true;
    this.onlineGroupsListItem1094.id = 10126;
    this.onlineGroupsListItem1094.default = true;
    this.onlineGroupsListItem1094.read = true;
    this.onlineGroupsListItem1095.id = 10474;
    this.onlineGroupsListItem1095.default = true;
    this.onlineGroupsListItem1095.read = true;
    // this.onlineGroupsListItem1096.id = 10475;
    // this.onlineGroupsListItem1096.default = false;
    // this.onlineGroupsListItem1096.read = false;
    this.onlineGroupsListItem1097.id = 10476;
    this.onlineGroupsListItem1097.default = true;
    this.onlineGroupsListItem1097.read = true;
    //  this.digitalIDItem1098.id = 10473;
    // this.digitalIDItem1098.default = false;
    // this.digitalIDItem1098.read = false;
    this.digitalIDItem1099.id = 10485;
    this.digitalIDItem1099.default = false;
    this.digitalIDItem1099.read = false;
    //   this.postsFeedsItem1099.id = 10473;
    //  this.postsFeedsItem1099.default = false;
    //  this.postsFeedsItem1099.read = false;

    this.postsFeedsItem1099.id = 10485;
    this.postsFeedsItem1099.default = false;
    this.postsFeedsItem1099.read = false;

    this.callsLog.title = 'Call Log';
    this.callsLog.subTitle = '17 Features';
    this.callsLog.desc =
      'Allow your app users to access the recent audio and video calls they made through your app.';
    this.callsLog.icon = 'icon-500';
    this.callsLog.id = 500;
    this.callsLog.link = this.tabTypes.CALL_LOG;
    this.callsLog.parameter = 0;
    this.callsLog.is_vapp = 0;
    this.callsLog.doc_link = 'https://docs.nandbox.com/docs/call-log';
    this.callsLog.features = [
      this.callsLogItem1,
      this.callsLogItem2,
      this.callsLogItem3,
      this.callsLogItem4,
      this.callsLogItem5,
      this.callsLogItem6,
      this.callsLogItem7,
      this.callsLogItem8,
      this.callsLogItem9,
      this.callsLogItem10,
      this.callsLogItem11,
      this.callsLogItem12,
      this.callsLogItem13,
      this.callsLogItem14,
      this.callsLogItem15,
      this.callsLogItem16,
      this.callsLogItem17
    ];

    this.activeChannels.title = 'Active Channels';
    this.activeChannels.subTitle = '76 Features';
    this.activeChannels.desc =
      'Displays a list of all the active channels the user has joined. ';
    this.activeChannels.icon = 'icon-501';
    this.activeChannels.id = 501;
    this.activeChannels.link = this.tabTypes.CHANNELS;
    this.activeChannels.parameter = 0;
    this.activeChannels.is_vapp = 0;
    this.activeChannels.doc_link = 'https://docs.nandbox.com/docs/channels';
    this.activeChannels.features = [
      this.activeChannelsItem18,
      this.activeChannelsItem19,
      this.activeChannelsItem20,
      this.activeChannelsItem21,
      this.activeChannelsItem22,
      this.activeChannelsItem23,
      this.activeChannelsItem24,
      this.activeChannelsItem25,
      this.activeChannelsItem26,
      this.activeChannelsItem27,
      this.activeChannelsItem28,
      this.activeChannelsItem29,
      this.activeChannelsItem30,
      this.activeChannelsItem31,
      this.activeChannelsItem32,
      this.activeChannelsItem33,
      this.activeChannelsItem34,
      this.activeChannelsItem35,
      this.activeChannelsItem36,
      this.activeChannelsItem37,
      this.activeChannelsItem38,
      this.activeChannelsItem39,
      this.activeChannelsItem40,
      this.activeChannelsItem41,
      this.activeChannelsItem42,
      this.activeChannelsItem43,
      this.activeChannelsItem44,
      this.activeChannelsItem45,
      this.activeChannelsItem46,
      this.activeChannelsItem47,
      this.activeChannelsItem48,
      this.activeChannelsItem49,
      this.activeChannelsItem50,
      this.activeChannelsItem51,
      this.activeChannelsItem52,
      this.activeChannelsItem53,
      this.activeChannelsItem54,
      this.activeChannelsItem55,
      this.activeChannelsItem56,
      this.activeChannelsItem57,
      this.activeChannelsItem58,
      this.activeChannelsItem59,
      this.activeChannelsItem60,
      this.activeChannelsItem61,
      this.activeChannelsItem62,
      this.activeChannelsItem63,
      this.activeChannelsItem64,
      this.activeChannelsItem65,
      this.activeChannelsItem66,
      this.activeChannelsItem67,
      this.activeChannelsItem68,
      this.activeChannelsItem69,
      this.activeChannelsItem70,
      this.activeChannelsItem71,
      this.activeChannelsItem72,
      this.activeChannelsItem73,
      this.activeChannelsItem74,
      this.activeChannelsItem75,
      this.activeChannelsItem76,
      this.activeChannelsItem77,
      this.activeChannelsItem78,
      this.activeChannelsItem79,
      this.activeChannelsItem80,
      this.activeChannelsItem81,
      this.activeChannelsItem82,
      this.activeChannelsItem83,
      this.activeChannelsItem84,
      this.activeChannelsItem85,
      this.activeChannelsItem86,
      this.activeChannelsItem87,
      this.activeChannelsItem88,
      this.activeChannelsItem89,
      this.activeChannelsItem90,
      this.activeChannelsItem91,
      this.activeChannelsItem93
    ];

    this.textMediaMessenger.title = 'Messenger';
    this.textMediaMessenger.subTitle = '70 Features';
    this.textMediaMessenger.desc =
      'A full functioning messenger that enables users to chat and share photos, videos, voice notes, and multimedia files. Ordered by recent conversations.';
    this.textMediaMessenger.icon = 'icon-502';
    this.textMediaMessenger.id = 502;
    this.textMediaMessenger.link = this.tabTypes.CHATS;
    this.textMediaMessenger.parameter = 0;
    this.textMediaMessenger.is_vapp = 0;
    this.textMediaMessenger.doc_link =
      'https://docs.nandbox.com/docs/messenger';
    this.textMediaMessenger.features = [
      this.textMediaMessengerItem94,
      this.textMediaMessengerItem95,
      this.textMediaMessengerItem96,
      this.textMediaMessengerItem97,
      this.textMediaMessengerItem98,
      this.textMediaMessengerItem99,
      this.textMediaMessengerItem100,
      this.textMediaMessengerItem101,
      this.textMediaMessengerItem102,
      this.textMediaMessengerItem103,
      this.textMediaMessengerItem104,
      this.textMediaMessengerItem105,
      this.textMediaMessengerItem106,
      this.textMediaMessengerItem107,
      this.textMediaMessengerItem108,
      this.textMediaMessengerItem109,
      this.textMediaMessengerItem110,
      this.textMediaMessengerItem111,
      this.textMediaMessengerItem112,
      this.textMediaMessengerItem113,
      this.textMediaMessengerItem114,
      this.textMediaMessengerItem115,
      this.textMediaMessengerItem116,
      this.textMediaMessengerItem117,
      this.textMediaMessengerItem118,
      this.textMediaMessengerItem119,
      this.textMediaMessengerItem120,
      this.textMediaMessengerItem121,
      this.textMediaMessengerItem122,
      this.textMediaMessengerItem123,
      this.textMediaMessengerItem124,
      this.textMediaMessengerItem125,
      this.textMediaMessengerItem126,
      this.textMediaMessengerItem127,
      this.textMediaMessengerItem128,
      this.textMediaMessengerItem129,
      this.textMediaMessengerItem130,
      this.textMediaMessengerItem131,
      this.textMediaMessengerItem132,
      this.textMediaMessengerItem133,
      this.textMediaMessengerItem134,
      this.textMediaMessengerItem135,
      this.textMediaMessengerItem136,
      this.textMediaMessengerItem137,
      this.textMediaMessengerItem138,
      this.textMediaMessengerItem139,
      this.textMediaMessengerItem140,
      this.textMediaMessengerItem141,
      this.textMediaMessengerItem142,
      this.textMediaMessengerItem143,
      this.textMediaMessengerItem144,
      this.textMediaMessengerItem145,
      this.textMediaMessengerItem146,
      this.textMediaMessengerItem147,
      this.textMediaMessengerItem148,
      this.textMediaMessengerItem149,
      this.textMediaMessengerItem150,
      this.textMediaMessengerItem151,
      this.textMediaMessengerItem152,
      this.textMediaMessengerItem153,
      this.textMediaMessengerItem154,
      this.textMediaMessengerItem155,
      this.textMediaMessengerItem156,
      this.textMediaMessengerItem157,
      this.textMediaMessengerItem158,
      this.textMediaMessengerItem159,
      this.textMediaMessengerItem160,
      this.textMediaMessengerItem161,
      this.textMediaMessengerItem162,
      this.textMediaMessengerItem163
    ];

    this.audioVideoCalling.title = 'Video/Audio Calling';
    this.audioVideoCalling.subTitle = '85 Features';
    this.audioVideoCalling.desc =
      'Allows users to make audio and video calls using your app.';
    this.audioVideoCalling.icon = 'icon-503';
    this.audioVideoCalling.id = 503;
    this.audioVideoCalling.link = this.tabTypes.CHATS;
    this.audioVideoCalling.parameter = 0;
    this.audioVideoCalling.is_vapp = 0;
    this.audioVideoCalling.defaultTitle = 'Calls';
    this.audioVideoCalling.doc_link =
      'https://docs.nandbox.com/docs/video-audio-calling';
    this.audioVideoCalling.features = [
      this.audioVideoCallingItem164,
      this.audioVideoCallingItem165,
      this.audioVideoCallingItem166,
      this.audioVideoCallingItem167,
      this.audioVideoCallingItem168,
      this.audioVideoCallingItem169,
      this.audioVideoCallingItem170,
      this.audioVideoCallingItem171,
      this.audioVideoCallingItem172,
      this.audioVideoCallingItem173,
      this.audioVideoCallingItem174,
      this.audioVideoCallingItem175,
      this.audioVideoCallingItem176,
      this.audioVideoCallingItem177,
      this.audioVideoCallingItem178,
      this.audioVideoCallingItem179,
      this.audioVideoCallingItem180,
      this.audioVideoCallingItem181,
      this.audioVideoCallingItem182,
      this.audioVideoCallingItem183,
      this.audioVideoCallingItem184,
      this.audioVideoCallingItem185,
      this.audioVideoCallingItem186,
      this.audioVideoCallingItem187,
      this.audioVideoCallingItem188,
      this.audioVideoCallingItem189,
      this.audioVideoCallingItem190,
      this.audioVideoCallingItem191,
      this.audioVideoCallingItem192,
      this.audioVideoCallingItem193,
      this.audioVideoCallingItem194,
      this.audioVideoCallingItem195,
      this.audioVideoCallingItem196,
      this.audioVideoCallingItem197,
      this.audioVideoCallingItem198,
      this.audioVideoCallingItem199,
      this.audioVideoCallingItem200,
      this.audioVideoCallingItem201,
      this.audioVideoCallingItem202,
      this.audioVideoCallingItem203,
      this.audioVideoCallingItem204,
      this.audioVideoCallingItem205,
      this.audioVideoCallingItem206,
      this.audioVideoCallingItem207,
      this.audioVideoCallingItem208,
      this.audioVideoCallingItem209,
      this.audioVideoCallingItem210,
      this.audioVideoCallingItem211,
      this.audioVideoCallingItem212,
      this.audioVideoCallingItem213,
      this.audioVideoCallingItem214,
      this.audioVideoCallingItem215,
      this.audioVideoCallingItem216,
      this.audioVideoCallingItem217,
      this.audioVideoCallingItem218,
      this.audioVideoCallingItem219,
      this.audioVideoCallingItem220,
      this.audioVideoCallingItem221,
      this.audioVideoCallingItem222,
      this.audioVideoCallingItem223,
      this.audioVideoCallingItem224,
      this.audioVideoCallingItem225,
      this.audioVideoCallingItem226,
      this.audioVideoCallingItem227,
      this.audioVideoCallingItem228,
      this.audioVideoCallingItem229,
      this.audioVideoCallingItem230,
      this.audioVideoCallingItem231,
      this.audioVideoCallingItem232,
      this.audioVideoCallingItem233,
      this.audioVideoCallingItem234,
      this.audioVideoCallingItem235,
      this.audioVideoCallingItem236,
      this.audioVideoCallingItem237,
      this.audioVideoCallingItem238,
      this.audioVideoCallingItem239,
      this.audioVideoCallingItem240,
      this.audioVideoCallingItem241,
      this.audioVideoCallingItem242,
      this.audioVideoCallingItem243,
      this.audioVideoCallingItem244,
      this.audioVideoCallingItem245,
      this.audioVideoCallingItem246,
      this.audioVideoCallingItem247,
      this.audioVideoCallingItem248
    ];

    this.postsFeeds.title = 'Newsfeed';
    this.postsFeeds.subTitle = '78 Features';
    this.postsFeeds.desc =
      'Give your app users a dynamic feed of posts that they can interact with.';
    this.postsFeeds.icon = 'icon-505';
    this.postsFeeds.id = 505;
    this.postsFeeds.link = this.tabTypes.FEED;
    this.postsFeeds.parameter = 0;
    this.postsFeeds.is_vapp = 1;
    this.postsFeeds.defaultTitle = 'Feeds';
    this.postsFeeds.doc_link = 'https://docs.nandbox.com/docs/newsfeed';
    this.postsFeeds.features = [
      this.postsFeedsItem326,
      this.postsFeedsItem327,
      this.postsFeedsItem328,
      this.postsFeedsItem329,
      this.postsFeedsItem330,
      this.postsFeedsItem331,
      this.postsFeedsItem332,
      this.postsFeedsItem333,
      this.postsFeedsItem334,
      this.postsFeedsItem335,
      this.postsFeedsItem336,
      this.postsFeedsItem337,
      this.postsFeedsItem338,
      this.postsFeedsItem339,
      this.postsFeedsItem340,
      this.postsFeedsItem341,
      this.postsFeedsItem342,
      this.postsFeedsItem343,
      this.postsFeedsItem344,
      this.postsFeedsItem345,
      this.postsFeedsItem346,
      this.postsFeedsItem347,
      this.postsFeedsItem348,
      this.postsFeedsItem349,
      this.postsFeedsItem350,
      this.postsFeedsItem351,
      this.postsFeedsItem352,
      this.postsFeedsItem353,
      this.postsFeedsItem354,
      this.postsFeedsItem355,
      this.postsFeedsItem356,
      this.postsFeedsItem357,
      this.postsFeedsItem358,
      this.postsFeedsItem359,
      this.postsFeedsItem360,
      this.postsFeedsItem361,
      this.postsFeedsItem362,
      this.postsFeedsItem363,
      this.postsFeedsItem364,
      this.postsFeedsItem365,
      this.postsFeedsItem366,
      this.postsFeedsItem367,
      this.postsFeedsItem368,
      this.postsFeedsItem369,
      this.postsFeedsItem370,
      this.postsFeedsItem371,
      this.postsFeedsItem372,
      this.postsFeedsItem373,
      this.postsFeedsItem374,
      this.postsFeedsItem375,
      this.postsFeedsItem376,
      this.postsFeedsItem377,
      this.postsFeedsItem378,
      this.postsFeedsItem379,
      this.postsFeedsItem380,
      this.postsFeedsItem381,
      this.postsFeedsItem382,
      this.postsFeedsItem383,
      this.postsFeedsItem384,
      this.postsFeedsItem385,
      this.postsFeedsItem386,
      this.postsFeedsItem387,
      this.postsFeedsItem388,
      this.postsFeedsItem389,
      this.postsFeedsItem390,
      this.postsFeedsItem391,
      this.postsFeedsItem392,
      this.postsFeedsItem393,
      this.postsFeedsItem394,
      this.postsFeedsItem395,
      this.postsFeedsItem396,
      this.postsFeedsItem397,
      this.postsFeedsItem398,
      this.postsFeedsItem399
    ];

    this.userChannels.title = 'My Channels';
    this.userChannels.subTitle = '78 Features';
    this.userChannels.desc =
      'Displays a list of channels that the user subscribed to.';
    this.userChannels.icon = 'icon-507';
    this.userChannels.id = 507;
    this.userChannels.link = this.tabTypes.MYLIST;
    this.userChannels.parameter = this.myListTypes.CHANNEL;
    this.userChannels.is_vapp = 0;
    this.userChannels.doc_link = 'https://docs.nandbox.com/docs/channels';
    this.userChannels.features = [
      this.userChannelsItem399,
      this.userChannelsItem400,
      this.userChannelsItem401,
      this.userChannelsItem402,
      this.userChannelsItem403,
      this.userChannelsItem404,
      this.userChannelsItem405,
      this.userChannelsItem406,
      this.userChannelsItem407,
      this.userChannelsItem408,
      this.userChannelsItem409,
      this.userChannelsItem410,
      this.userChannelsItem411,
      this.userChannelsItem412,
      this.userChannelsItem413,
      this.userChannelsItem414,
      this.userChannelsItem415,
      this.userChannelsItem416,
      this.userChannelsItem417,
      this.userChannelsItem418,
      this.userChannelsItem419,
      this.userChannelsItem420,
      this.userChannelsItem421,
      this.userChannelsItem422,
      this.userChannelsItem423,
      this.userChannelsItem424,
      this.userChannelsItem425,
      this.userChannelsItem426,
      this.userChannelsItem427,
      this.userChannelsItem428,
      this.userChannelsItem429,
      this.userChannelsItem430,
      this.userChannelsItem431,
      this.userChannelsItem432,
      this.userChannelsItem433,
      this.userChannelsItem434,
      this.userChannelsItem435,
      this.userChannelsItem436,
      this.userChannelsItem437,
      this.userChannelsItem438,
      this.userChannelsItem439,
      this.userChannelsItem440,
      this.userChannelsItem441,
      this.userChannelsItem442,
      this.userChannelsItem443,
      this.userChannelsItem444,
      this.userChannelsItem445,
      this.userChannelsItem446,
      this.userChannelsItem447,
      this.userChannelsItem448,
      this.userChannelsItem449,
      this.userChannelsItem450,
      this.userChannelsItem451,
      this.userChannelsItem452,
      this.userChannelsItem453,
      this.userChannelsItem454,
      this.userChannelsItem455,
      this.userChannelsItem456,
      this.userChannelsItem457,
      this.userChannelsItem458,
      this.userChannelsItem459,
      this.userChannelsItem460,
      this.userChannelsItem461,
      this.userChannelsItem462,
      this.userChannelsItem463,
      this.userChannelsItem464,
      this.userChannelsItem465,
      this.userChannelsItem466,
      this.userChannelsItem467,
      this.userChannelsItem468,
      this.userChannelsItem469,
      this.userChannelsItem470,
      this.userChannelsItem471,
      this.userChannelsItem472,
      this.userChannelsItem473,
      this.userChannelsItem474,
      this.userChannelsItem475,
      this.userChannelsItem476
    ];

    this.contactsList.title = 'Contacts';
    this.contactsList.subTitle = '51 Features';
    this.contactsList.desc =
      "Allows your app to access the user's contact list; which allow users to invite their contacts to the app or join a group or a channel.";
    this.contactsList.icon = 'icon-508';
    this.contactsList.id = 508;
    this.contactsList.link = this.tabTypes.MYLIST;
    this.contactsList.parameter = this.myListTypes.CONTACT;
    this.contactsList.is_vapp = 0;
    this.contactsList.doc_link = 'https://docs.nandbox.com/docs/contacts';
    this.contactsList.features = [
      this.contactsListItem477,
      this.contactsListItem478,
      this.contactsListItem479,
      this.contactsListItem480,
      this.contactsListItem481,
      this.contactsListItem482,
      this.contactsListItem483,
      this.contactsListItem484,
      this.contactsListItem485,
      this.contactsListItem486,
      this.contactsListItem487,
      this.contactsListItem488,
      this.contactsListItem489,
      this.contactsListItem490,
      this.contactsListItem491,
      this.contactsListItem492,
      this.contactsListItem493,
      this.contactsListItem494,
      this.contactsListItem495,
      this.contactsListItem496,
      this.contactsListItem497,
      this.contactsListItem498,
      this.contactsListItem499,
      this.contactsListItem500,
      this.contactsListItem501,
      this.contactsListItem502,
      this.contactsListItem503,
      this.contactsListItem504,
      this.contactsListItem505,
      this.contactsListItem506,
      this.contactsListItem507,
      this.contactsListItem508,
      this.contactsListItem509,
      this.contactsListItem510,
      this.contactsListItem511,
      this.contactsListItem512,
      this.contactsListItem513,
      this.contactsListItem514,
      this.contactsListItem515,
      this.contactsListItem516,
      this.contactsListItem517,
      this.contactsListItem518,
      this.contactsListItem519,
      this.contactsListItem520,
      this.contactsListItem521,
      this.contactsListItem522,
      this.contactsListItem523,
      this.contactsListItem524,
      this.contactsListItem525,
      this.contactsListItem526,
      this.contactsListItem527
    ];

    this.groupList.title = 'My Groups';
    this.groupList.subTitle = '71 Features';
    this.groupList.desc =
      'Allows users to access their joined group chats, send files or links, and search throughout their conversations.';
    this.groupList.icon = 'icon-509';
    this.groupList.id = 509;
    this.groupList.link = this.tabTypes.MYLIST;
    this.groupList.parameter = this.myListTypes.GROUP;
    this.groupList.is_vapp = 0;
    this.groupList.doc_link = 'https://docs.nandbox.com/docs/chat-group';
    this.groupList.features = [
      this.groupListItem10000,
      this.groupListItem528,
      this.groupListItem529,
      this.groupListItem530,
      this.groupListItem531,
      this.groupListItem532,
      this.groupListItem533,
      this.groupListItem534,
      this.groupListItem535,
      this.groupListItem536,
      this.groupListItem537,
      this.groupListItem538,
      this.groupListItem539,
      this.groupListItem540,
      this.groupListItem541,
      this.groupListItem542,
      this.groupListItem543,
      this.groupListItem544,
      this.groupListItem545,
      this.groupListItem546,
      this.groupListItem547,
      this.groupListItem548,
      this.groupListItem549,
      this.groupListItem550,
      this.groupListItem551,
      this.groupListItem552,
      this.groupListItem553,
      this.groupListItem554,
      this.groupListItem555,
      this.groupListItem556,
      this.groupListItem557,
      this.groupListItem558,
      this.groupListItem559,
      this.groupListItem560,
      this.groupListItem561,
      this.groupListItem562,
      this.groupListItem563,
      this.groupListItem564,
      this.groupListItem565,
      this.groupListItem566,
      this.groupListItem567,
      this.groupListItem568,
      this.groupListItem569,
      this.groupListItem570,
      this.groupListItem571,
      this.groupListItem572,
      this.groupListItem573,
      this.groupListItem574,
      this.groupListItem575,
      this.groupListItem576,
      this.groupListItem577,
      this.groupListItem578,
      this.groupListItem579,
      this.groupListItem580,
      this.groupListItem581,
      this.groupListItem582,
      this.groupListItem583,
      this.groupListItem584,
      this.groupListItem585,
      this.groupListItem586,
      this.groupListItem587,
      this.groupListItem588,
      this.groupListItem589,
      this.groupListItem590,
      this.groupListItem591,
      this.groupListItem592,
      this.groupListItem593,
      this.groupListItem594,
      this.groupListItem595,
      this.groupListItem596,
      this.groupListItem597,
      this.groupListItem598
    ];

    this.chatbot.title = 'Chatbot';
    this.chatbot.subTitle = '50 Features';
    this.chatbot.desc =
      'Integrate third-party bots, build your own, or choose from ready-made bots to perform certain functions within your app.';
    this.chatbot.icon = 'icon-510';
    this.chatbot.id = 510;
    this.chatbot.link = this.tabTypes.OPENCHAT;
    this.chatbot.parameter = this.openChats.BOT;
    this.chatbot.is_vapp = 0;
    this.chatbot.doc_link = 'https://docs.nandbox.com/docs/chatbot';
    this.chatbot.moduleId = 'chatbot';
    this.chatbot.features = [
      this.chatbotItem599,
      this.chatbotItem600,
      this.chatbotItem601,
      this.chatbotItem602,
      this.chatbotItem603,
      this.chatbotItem604,
      this.chatbotItem605,
      this.chatbotItem606,
      this.chatbotItem607,
      this.chatbotItem608,
      this.chatbotItem609,
      this.chatbotItem610,
      this.chatbotItem611,
      this.chatbotItem612,
      this.chatbotItem613,
      this.chatbotItem614,
      this.chatbotItem615,
      this.chatbotItem616,
      this.chatbotItem617,
      this.chatbotItem618,
      this.chatbotItem619,
      this.chatbotItem620,
      this.chatbotItem621,
      this.chatbotItem622,
      this.chatbotItem623,
      this.chatbotItem624,
      this.chatbotItem625,
      this.chatbotItem626,
      this.chatbotItem627,
      this.chatbotItem628,
      this.chatbotItem629,
      this.chatbotItem630,
      this.chatbotItem631,
      this.chatbotItem632,
      this.chatbotItem633,
      this.chatbotItem634,
      this.chatbotItem635,
      this.chatbotItem636,
      this.chatbotItem637,
      this.chatbotItem638,
      this.chatbotItem639,
      this.chatbotItem640,
      this.chatbotItem641,
      this.chatbotItem642,
      this.chatbotItem643,
      this.chatbotItem644,
      this.chatbotItem645,
      this.chatbotItem646,
      this.chatbotItem647,
      this.chatbotItem648
    ];

    this.chatScreen.title = 'Chat to bot';
    this.chatScreen.subTitle = '47 Features';
    this.chatScreen.desc = 'Allows direct chat with a user of your choice.';
    this.chatScreen.icon = 'icon-511';
    this.chatScreen.id = 511;
    this.chatScreen.link = this.tabTypes.OPENCHAT;
    this.chatScreen.parameter = this.openChats.CONTACT;
    this.chatScreen.is_vapp = 0;
    this.chatScreen.doc_link =
      'https://docs.nandbox.com/docs/direct-chat-module';
    this.chatScreen.features = [
      this.chatScreenItem649,
      this.chatScreenItem650,
      this.chatScreenItem651,
      this.chatScreenItem652,
      this.chatScreenItem653,
      this.chatScreenItem654,
      this.chatScreenItem655,
      this.chatScreenItem656,
      this.chatScreenItem657,
      this.chatScreenItem658,
      this.chatScreenItem659,
      this.chatScreenItem660,
      this.chatScreenItem661,
      this.chatScreenItem662,
      this.chatScreenItem663,
      this.chatScreenItem664,
      this.chatScreenItem665,
      this.chatScreenItem666,
      this.chatScreenItem667,
      this.chatScreenItem668,
      this.chatScreenItem669,
      this.chatScreenItem670,
      this.chatScreenItem671,
      this.chatScreenItem672,
      this.chatScreenItem673,
      this.chatScreenItem674,
      this.chatScreenItem675,
      this.chatScreenItem676,
      this.chatScreenItem677,
      this.chatScreenItem678,
      this.chatScreenItem679,
      this.chatScreenItem680,
      this.chatScreenItem681,
      this.chatScreenItem682,
      this.chatScreenItem683,
      this.chatScreenItem684,
      this.chatScreenItem685,
      this.chatScreenItem686,
      this.chatScreenItem687,
      this.chatScreenItem688,
      this.chatScreenItem689,
      this.chatScreenItem690,
      this.chatScreenItem691,
      this.chatScreenItem692,
      this.chatScreenItem693,
      this.chatScreenItem694,
      this.chatScreenItem695
    ];

    this.chatAdmin.title = 'Chat to Admin';
    this.chatAdmin.subTitle = '47 Features';
    this.chatAdmin.desc = 'Allows direct chat with a user of your choice.';
    this.chatAdmin.icon = 'icon-511';
    this.chatAdmin.id = 700000;
    this.chatAdmin.link = this.tabTypes.OPENCHAT;
    this.chatAdmin.parameter = this.openChats.ADMIN;
    this.chatAdmin.is_vapp = 0;
    this.chatAdmin.doc_link =
      'https://docs.nandbox.com/docs/direct-chat-module';
    this.chatAdmin.features = [
      this.chatScreenItem649,
      this.chatScreenItem650,
      this.chatScreenItem651,
      this.chatScreenItem652,
      this.chatScreenItem653,
      this.chatScreenItem654,
      this.chatScreenItem655,
      this.chatScreenItem656,
      this.chatScreenItem657,
      this.chatScreenItem658,
      this.chatScreenItem659,
      this.chatScreenItem660,
      this.chatScreenItem661,
      this.chatScreenItem662,
      this.chatScreenItem663,
      this.chatScreenItem664,
      this.chatScreenItem665,
      this.chatScreenItem666,
      this.chatScreenItem667,
      this.chatScreenItem668,
      this.chatScreenItem669,
      this.chatScreenItem670,
      this.chatScreenItem671,
      this.chatScreenItem672,
      this.chatScreenItem673,
      this.chatScreenItem674,
      this.chatScreenItem675,
      this.chatScreenItem676,
      this.chatScreenItem677,
      this.chatScreenItem678,
      this.chatScreenItem679,
      this.chatScreenItem680,
      this.chatScreenItem681,
      this.chatScreenItem682,
      this.chatScreenItem683,
      this.chatScreenItem684,
      this.chatScreenItem685,
      this.chatScreenItem686,
      this.chatScreenItem687,
      this.chatScreenItem688,
      this.chatScreenItem689,
      this.chatScreenItem690,
      this.chatScreenItem691,
      this.chatScreenItem692,
      this.chatScreenItem693,
      this.chatScreenItem694,
      this.chatScreenItem695
    ];

    this.chatGroup.title = 'Chat Group';
    this.chatGroup.subTitle = '67 Features';
    this.chatGroup.desc =
      'Displays a chat screen with a specific group. All group chats created on your app can include up to 10,000 users';
    this.chatGroup.icon = 'icon-512';
    this.chatGroup.id = 512;
    this.chatGroup.link = this.tabTypes.OPENCHAT;
    this.chatGroup.parameter = this.openChats.GROUP;
    this.chatGroup.is_vapp = 0;
    this.chatGroup.doc_link = 'https://docs.nandbox.com/docs/chat-group';
    this.chatGroup.features = [
      this.chatGroupItem696,
      this.chatGroupItem697,
      this.chatGroupItem698,
      this.chatGroupItem699,
      this.chatGroupItem700,
      this.chatGroupItem701,
      this.chatGroupItem702,
      this.chatGroupItem703,
      this.chatGroupItem704,
      this.chatGroupItem705,
      this.chatGroupItem706,
      this.chatGroupItem707,
      this.chatGroupItem708,
      this.chatGroupItem709,
      this.chatGroupItem710,
      this.chatGroupItem711,
      this.chatGroupItem712,
      this.chatGroupItem713,
      this.chatGroupItem714,
      this.chatGroupItem715,
      this.chatGroupItem716,
      this.chatGroupItem717,
      this.chatGroupItem718,
      this.chatGroupItem719,
      this.chatGroupItem720,
      this.chatGroupItem721,
      this.chatGroupItem722,
      this.chatGroupItem723,
      this.chatGroupItem724,
      this.chatGroupItem725,
      this.chatGroupItem726,
      this.chatGroupItem727,
      this.chatGroupItem728,
      this.chatGroupItem729,
      this.chatGroupItem730,
      this.chatGroupItem731,
      this.chatGroupItem732,
      this.chatGroupItem733,
      this.chatGroupItem734,
      this.chatGroupItem735,
      this.chatGroupItem736,
      this.chatGroupItem737,
      this.chatGroupItem738,
      this.chatGroupItem739,
      this.chatGroupItem740,
      this.chatGroupItem741,
      this.chatGroupItem742,
      this.chatGroupItem743,
      this.chatGroupItem744,
      this.chatGroupItem745,
      this.chatGroupItem746,
      this.chatGroupItem747,
      this.chatGroupItem748,
      this.chatGroupItem749,
      this.chatGroupItem750,
      this.chatGroupItem751,
      this.chatGroupItem752,
      this.chatGroupItem753,
      this.chatGroupItem754,
      this.chatGroupItem755,
      this.chatGroupItem756,
      this.chatGroupItem757,
      this.chatGroupItem758,
      this.chatGroupItem759,
      this.chatGroupItem760,
      this.chatGroupItem761,
      this.chatGroupItem762
    ];

    this.loyaltyCompetitions.title = 'Loyalty';
    this.loyaltyCompetitions.subTitle = '78 Features';
    this.loyaltyCompetitions.desc =
      'Allows you to create loyalty programs where users can collect points to redeem later.';
    this.loyaltyCompetitions.icon = 'icon-700';
    this.loyaltyCompetitions.id = 700;
    this.loyaltyCompetitions.link = this.tabTypes.WALLET;
    this.loyaltyCompetitions.parameter = 0;
    this.loyaltyCompetitions.is_vapp = 1;
    this.loyaltyCompetitions.doc_link = 'https://docs.nandbox.com/docs/loyalty';
    this.loyaltyCompetitions.moduleId = 'loyalty_competitions';
    this.loyaltyCompetitions.features = [
      this.loyaltyCompetitionsItem774,
      this.loyaltyCompetitionsItem775,
      this.loyaltyCompetitionsItem776,
      this.loyaltyCompetitionsItem777,
      this.loyaltyCompetitionsItem778,
      this.loyaltyCompetitionsItem779,
      this.loyaltyCompetitionsItem780,
      this.loyaltyCompetitionsItem781,
      this.loyaltyCompetitionsItem782,
      this.loyaltyCompetitionsItem783,
      this.loyaltyCompetitionsItem784,
      this.loyaltyCompetitionsItem785,
      this.loyaltyCompetitionsItem786,
      this.loyaltyCompetitionsItem787,
      this.loyaltyCompetitionsItem788,
      this.loyaltyCompetitionsItem789,
      this.loyaltyCompetitionsItem790,
      this.loyaltyCompetitionsItem791,
      this.loyaltyCompetitionsItem792,
      this.loyaltyCompetitionsItem793,
      this.loyaltyCompetitionsItem794,
      this.loyaltyCompetitionsItem795,
      this.loyaltyCompetitionsItem796,
      this.loyaltyCompetitionsItem797,
      this.loyaltyCompetitionsItem798,
      this.loyaltyCompetitionsItem799,
      this.loyaltyCompetitionsItem800,
      this.loyaltyCompetitionsItem801,
      this.loyaltyCompetitionsItem802,
      this.loyaltyCompetitionsItem803,
      this.loyaltyCompetitionsItem804,
      this.loyaltyCompetitionsItem805,
      this.loyaltyCompetitionsItem806,
      this.loyaltyCompetitionsItem807,
      this.loyaltyCompetitionsItem808,
      this.loyaltyCompetitionsItem809,
      this.loyaltyCompetitionsItem810,
      this.loyaltyCompetitionsItem811,
      this.loyaltyCompetitionsItem812,
      this.loyaltyCompetitionsItem813,
      this.loyaltyCompetitionsItem814,
      this.loyaltyCompetitionsItem815,
      this.loyaltyCompetitionsItem816,
      this.loyaltyCompetitionsItem817,
      this.loyaltyCompetitionsItem818,
      this.loyaltyCompetitionsItem819,
      this.loyaltyCompetitionsItem820,
      this.loyaltyCompetitionsItem821,
      this.loyaltyCompetitionsItem822,
      this.loyaltyCompetitionsItem823,
      this.loyaltyCompetitionsItem824,
      this.loyaltyCompetitionsItem825,
      this.loyaltyCompetitionsItem826,
      this.loyaltyCompetitionsItem827,
      this.loyaltyCompetitionsItem828,
      this.loyaltyCompetitionsItem829,
      this.loyaltyCompetitionsItem830,
      this.loyaltyCompetitionsItem831,
      this.loyaltyCompetitionsItem832,
      this.loyaltyCompetitionsItem833,
      this.loyaltyCompetitionsItem834,
      this.loyaltyCompetitionsItem835,
      this.loyaltyCompetitionsItem836,
      this.loyaltyCompetitionsItem837,
      this.loyaltyCompetitionsItem838,
      this.loyaltyCompetitionsItem839,
      this.loyaltyCompetitionsItem840,
      this.loyaltyCompetitionsItem841,
      this.loyaltyCompetitionsItem842,
      this.loyaltyCompetitionsItem843,
      this.loyaltyCompetitionsItem844,
      this.loyaltyCompetitionsItem845,
      this.loyaltyCompetitionsItem846,
      this.loyaltyCompetitionsItem847
    ];

    this.digitalID.title = 'Virtual Card';
    this.digitalID.subTitle = '78 Features';
    this.digitalID.desc =
      'Allows you to issue membership cards to you users, each with a unique UPC code.';
    this.digitalID.icon = 'icon-800';
    this.digitalID.id = 800;
    this.digitalID.link = this.tabTypes.MEMBERSHIP;
    this.digitalID.parameter = 0;
    this.digitalID.is_vapp = 0;
    this.digitalID.doc_link = 'https://docs.nandbox.com/docs/id';
    this.digitalID.features = [
      this.digitalIDItem849,
      this.digitalIDItem853,
      this.digitalIDItem854,
      this.digitalIDItem855,
      this.digitalIDItem856,
      this.digitalIDItem857,
      this.digitalIDItem858,
      this.digitalIDItem859,
      this.digitalIDItem860,
      this.digitalIDItem861,
      this.digitalIDItem862,
      this.digitalIDItem863,
      this.digitalIDItem864,
      this.digitalIDItem865,
      this.digitalIDItem866,
      this.digitalIDItem867,
      this.digitalIDItem868,
      this.digitalIDItem869,
      this.digitalIDItem870,
      this.digitalIDItem871,
      this.digitalIDItem872,
      this.digitalIDItem873,
      this.digitalIDItem874,
      this.digitalIDItem875,
      this.digitalIDItem876,
      this.digitalIDItem877,
      this.digitalIDItem878,
      this.digitalIDItem879,
      this.digitalIDItem880,
      this.digitalIDItem881,
      this.digitalIDItem882,
      this.digitalIDItem883,
      this.digitalIDItem884,
      this.digitalIDItem885,
      this.digitalIDItem886,
      this.digitalIDItem887,
      this.digitalIDItem888,
      this.digitalIDItem889,
      this.digitalIDItem890,
      this.digitalIDItem891,
      this.digitalIDItem892,
      this.digitalIDItem893,
      this.digitalIDItem894,
      this.digitalIDItem895,
      this.digitalIDItem896,
      this.digitalIDItem897,
      this.digitalIDItem898,
      this.digitalIDItem899,
      this.digitalIDItem900,
      this.digitalIDItem901,
      this.digitalIDItem902,
      this.digitalIDItem903,
      this.digitalIDItem904,
      this.digitalIDItem905,
      this.digitalIDItem906,
      this.digitalIDItem907,
      this.digitalIDItem908,
      this.digitalIDItem909,
      this.digitalIDItem910,
      this.digitalIDItem911,
      this.digitalIDItem912,
      this.digitalIDItem913,
      this.digitalIDItem914,
      this.digitalIDItem915,
      this.digitalIDItem916,
      this.digitalIDItem917,
      this.digitalIDItem918,
      this.digitalIDItem919,
      this.digitalIDItem920,
      this.digitalIDItem921,
      this.digitalIDItem1099
    ];

    this.mStore.title = 'Store';
    this.mStore.subTitle = '1 Feature';
    this.mStore.desc =
      'Create a fully functioning e-commerce store where you can sell your products or services through your app.';
    this.mStore.icon = 'icon-200000';
    this.mStore.id = 200000;
    this.mStore.link = this.tabTypes.BUNDLE;
    this.mStore.parameter = 0;
    this.mStore.is_vapp = 1;
    this.mStore.doc_link = 'https://docs.nandbox.com/docs/store';
    this.mStore.moduleId = 'store';

    this.userBookings.title = 'My Bookings';
    this.userBookings.subTitle = '1 Feature';
    this.userBookings.desc = 'Shows the list of bookings made by app users.';
    this.userBookings.icon = 'icon-515';
    this.userBookings.id = 515;
    this.userBookings.link = this.tabTypes.MYLIST;
    this.userBookings.parameter = this.myListTypes.BOOKING;
    this.userBookings.is_vapp = 1;
    this.userBookings.doc_link = 'https://docs.nandbox.com/docs/my-bookings';
    this.userBookings.moduleId = 'user_bookings';

    this.calendar.title = 'My Calendar';
    this.calendar.subTitle = '1 Feature';
    this.calendar.desc =
      "Allows your app's calendar to sync with the user's device calendar, and displays the user's upcoming events.";
    this.calendar.icon = 'icon-600';
    this.calendar.id = 600;
    this.calendar.link = this.tabTypes.CALENDAR;
    this.calendar.parameter = 0;
    this.calendar.is_vapp = 0;
    this.calendar.doc_link = 'https://docs.nandbox.com/docs/my-calendar';
    this.calendar.features = [this.calendarItem1];
    this.calendar.defaultTitle = 'Calendar';

    this.userInvitations.title = 'My Invitations';
    this.userInvitations.subTitle = '2 Features';
    this.userInvitations.desc =
      'Allow your app users to send invitations to their contacts to join a specific group/channel/event created on your app.';
    this.userInvitations.icon = 'icon-900';
    this.userInvitations.id = 900;
    this.userInvitations.link = this.tabTypes.INVITATION;
    this.userInvitations.parameter = 0;
    this.userInvitations.is_vapp = 0;
    this.userInvitations.doc_link =
      'https://docs.nandbox.com/docs/my-invitations';
    this.userInvitations.features = [
      this.userInvitationsItem1,
      this.userInvitationsItem2
    ];

    this.qr.title = 'QR';
    this.qr.subTitle = '1 Feature';
    this.qr.desc =
      'QR codes can be used to invite users to groups or channels, validate event tickets or login into nandbox app builder.';
    this.qr.icon = 'icon-1000';
    this.qr.id = 1000;
    this.qr.link = this.tabTypes.QR;
    this.qr.parameter = 0;
    this.qr.is_vapp = 0;
    this.qr.doc_link = 'https://docs.nandbox.com/docs/qr';

    this.webview.title = 'Webview';
    this.webview.subTitle = '1 Feature';
    this.webview.desc =
      'Allows you to redirect your users to your website, blog, or any external link of your choice.';
    this.webview.icon = 'icon-1001';
    this.webview.id = 1001;
    this.webview.link = this.tabTypes.WEBVIEW;
    this.webview.parameter = 0;
    this.webview.is_vapp = 0;
    this.webview.features = [this.webviewItem1];
    this.webview.doc_link = 'https://docs.nandbox.com/docs/web-view';

    this.map.title = 'Map Tracking';
    this.map.subTitle = '1 Feature';
    this.map.desc =
      'Allows your app to show real-time map tracking with ultimate accuracy. ';
    this.map.icon = 'icon-1100';
    this.map.id = 1100;
    this.map.link = this.tabTypes.MAP;
    this.map.parameter = 0;
    this.map.is_vapp = 0;
    this.map.moduleId = 'map_tracking';

    this.booking.title = 'Booking';
    this.booking.subTitle = '1 Feature';
    this.booking.desc =
      'Shows a booking window that allows the user to reserve a slot or buy a ticket.';
    this.booking.icon = 'icon-1200';
    this.booking.id = 1200;
    this.booking.link = this.tabTypes.BOOKING;
    this.booking.parameter = 0;
    this.booking.is_vapp = 1;
    this.booking.doc_link = 'https://docs.nandbox.com/docs/booking';
    this.booking.moduleId = 'booking';

    this.search.title = 'Search';
    this.search.subTitle = '4 Features';
    this.search.desc =
      'Allows you to include a powerful search tool that works throughout the whole app.';
    this.search.icon = 'icon-1002';
    this.search.id = 1002;
    this.search.link = this.tabTypes.SEARCH;
    this.search.parameter = 0;
    this.search.is_vapp = 1;
    this.search.doc_link = 'https://docs.nandbox.com/docs/search';
    this.search.features = [
      this.searchItem1,
      this.searchItem2,
      this.searchItem3,
      this.searchItem4
    ];

    this.ticketValidate.title = 'Validation';
    this.ticketValidate.subTitle = '1 Feature';
    this.ticketValidate.desc = "Allows admins to validate users' bookings.";
    this.ticketValidate.icon = 'icon-bubbles';
    this.ticketValidate.id = 10020;
    this.ticketValidate.link = this.tabTypes.QR_PAGE;
    this.ticketValidate.parameter = 0;
    this.ticketValidate.is_vapp = 1;
    this.ticketValidate.doc_link = 'https://docs.nandbox.com/docs/validation';
    this.ticketValidate.features = [this.ticketValidateItem935];
    this.ticketValidate.moduleId = 'booking_validation';

    this.ticketRedeem.title = 'Redemption';
    this.ticketRedeem.subTitle = '1 Feature';
    this.ticketRedeem.desc = 'Allows users to redeem their validated bookings.';
    this.ticketRedeem.icon = 'icon-bubbles';
    this.ticketRedeem.id = 10021;
    this.ticketRedeem.link = this.tabTypes.QR_PAGE;
    this.ticketRedeem.parameter = 0;
    this.ticketRedeem.is_vapp = 1;
    this.ticketRedeem.doc_link = 'https://docs.nandbox.com/docs/redemption';
    this.ticketRedeem.features = [this.ticketRedeemItem936];
    this.ticketRedeem.moduleId = 'booking_redemption';
    this.stripe.title = 'stripe';
    this.stripe.subTitle = '1 Feature';
    this.stripe.desc =
      'Stripe is a leading online payment gateway for online businesses. Drag and drop its module to integrate it instantly with your app.';
    this.stripe.icon = 'icon-Stripe_Logo';
    this.stripe.id = 30100;
    this.stripe.moduleId = 'payment_credit_card';

    this.stripe.parameter = 0;
    this.stripe.is_vapp = 0;

    this.weAccept.title = 'Accept';
    this.weAccept.subTitle = '1 Feature';
    this.weAccept.desc =
      'Accept is a payment solution provider tailored to helping businesses grow. Drag and drop its module to integrate it instantly with your app.';
    this.weAccept.icon = 'icon-weaccept';
    this.weAccept.id = 30101;

    this.weAccept.parameter = 0;
    this.weAccept.is_vapp = 0;

    this.payTabs.title = 'PayTabs';
    this.payTabs.subTitle = '1 Feature';
    this.payTabs.desc =
      'PayTabs is an award-winning, B2B payment processing company. Drag and drop its module to integrate it instantly with your app.';
    this.payTabs.icon = 'icon-paytabs-logo';
    this.payTabs.id = 30102;

    this.payTabs.parameter = 0;
    this.payTabs.is_vapp = 0;

    this.googleAdds.title = 'Google AdMob';
    this.googleAdds.subTitle = '1 Feature';
    this.googleAdds.desc =
      'Link your Google Ads account with your app which will allow you to display ads on your app.';
    this.googleAdds.icon = 'icon-bubbles';
    this.googleAdds.id = 30103;
    this.googleAdds.moduleId = 'google_admob';
    this.googleAdds.parameter = 0;
    this.googleAdds.is_vapp = 0;

    this.workFlow.title = 'Workflow with API';
    this.workFlow.subTitle = '1 Feature';
    this.workFlow.desc = '';
    this.workFlow.icon = 'icon-workflow-item';
    this.workFlow.id = 20001;
    this.workFlow.link = this.tabTypes.MENUS;
    this.workFlow.doc_link = 'https://docs.nandbox.com/docs/workflow';
    this.workFlow.moduleId = 'workflow';
    this.workFlow.defaultTitle = 'Workflow';

    this.mobileWorkFlow.title = 'Mobile Store';
    this.mobileWorkFlow.subTitle = '1 Feature';
    this.mobileWorkFlow.desc = '';
    this.mobileWorkFlow.icon = 'icon-mobile-store';
    this.mobileWorkFlow.id = 20002;
    this.mobileWorkFlow.link = this.tabTypes.MENUS;
    this.mobileWorkFlow.doc_link = 'https://docs.nandbox.com/docs/workflow';
    this.mobileWorkFlow.moduleId = 'workflow';
    this.mobileWorkFlow.defaultTitle = 'MStore';

    this.mapSearch.title = 'Map Search';
    this.mapSearch.subTitle = '1 Feature';
    this.mapSearch.desc =
      'Allows users to explore their neighborhoods and find nearby places based on their interests and geo-location.';
    this.mapSearch.icon = 'icon-1101';
    this.mapSearch.id = 1101;
    this.mapSearch.link = this.tabTypes.MAP_SEARCH;
    this.mapSearch.parameter = 0;
    this.mapSearch.is_vapp = 1;
    this.mapSearch.privilege = this.privilegesName.configmapmapssearch;
    this.mapSearch.moduleId = 'map_search';
    this.mapSearch.doc_link = 'https://docs.nandbox.com/docs/map-search';

    this.channelsList.title = 'Channel List';
    this.channelsList.subTitle = '78 Features';
    this.channelsList.desc =
      'Displays a list of all available App channels. The view can be displayed as a grid or list.';
    this.channelsList.icon = 'icon-516';
    this.channelsList.id = 516;
    this.channelsList.link = this.tabTypes.ONLINECHANNEL;
    this.channelsList.parameter = 0;
    this.channelsList.is_vapp = 0;
    this.channelsList.defaultTitle = 'Channels';
    this.channelsList.doc_link = 'https://docs.nandbox.com/docs/channels';
    this.channelsList.features = [
      this.channelsListItem942,
      this.channelsListItem943,
      this.channelsListItem944,
      this.channelsListItem945,
      this.channelsListItem946,
      this.channelsListItem947,
      this.channelsListItem948,
      this.channelsListItem949,
      this.channelsListItem950,
      this.channelsListItem951,
      this.channelsListItem952,
      this.channelsListItem953,
      this.channelsListItem954,
      this.channelsListItem955,
      this.channelsListItem956,
      this.channelsListItem957,
      this.channelsListItem958,
      this.channelsListItem959,
      this.channelsListItem960,
      this.channelsListItem961,
      this.channelsListItem962,
      this.channelsListItem963,
      this.channelsListItem964,
      this.channelsListItem965,
      this.channelsListItem966,
      this.channelsListItem967,
      this.channelsListItem968,
      this.channelsListItem969,
      this.channelsListItem970,
      this.channelsListItem971,
      this.channelsListItem972,
      this.channelsListItem973,
      this.channelsListItem974,
      this.channelsListItem975,
      this.channelsListItem976,
      this.channelsListItem977,
      this.channelsListItem978,
      this.channelsListItem979,
      this.channelsListItem980,
      this.channelsListItem981,
      this.channelsListItem982,
      this.channelsListItem983,
      this.channelsListItem984,
      this.channelsListItem985,
      this.channelsListItem986,
      this.channelsListItem987,
      this.channelsListItem988,
      this.channelsListItem989,
      this.channelsListItem990,
      this.channelsListItem991,
      this.channelsListItem992,
      this.channelsListItem993,
      this.channelsListItem994,
      this.channelsListItem995,
      this.channelsListItem996,
      this.channelsListItem997,
      this.channelsListItem998,
      this.channelsListItem999,
      this.channelsListItem1000,
      this.channelsListItem1001,
      this.channelsListItem1002,
      this.channelsListItem1003,
      this.channelsListItem1004,
      this.channelsListItem1005,
      this.channelsListItem1006,
      this.channelsListItem1007,
      this.channelsListItem1008,
      this.channelsListItem1009,
      this.channelsListItem1010,
      this.channelsListItem1011,
      this.channelsListItem1012,
      this.channelsListItem1013,
      this.channelsListItem1014,
      this.channelsListItem1015,
      this.channelsListItem1016,
      this.channelsListItem1017,
      this.channelsListItem1019
    ];

    this.onlineGroupsList.title = 'Group List';
    this.onlineGroupsList.subTitle = '78 Features';
    this.onlineGroupsList.desc =
      'Displays a list of all available app groups for users to join.';
    this.onlineGroupsList.icon = 'icon-517';
    this.onlineGroupsList.id = 517;
    this.onlineGroupsList.link = this.tabTypes.ONLINEGROUP;
    this.onlineGroupsList.parameter = 0;
    this.onlineGroupsList.is_vapp = 0;
    this.onlineGroupsList.defaultTitle = 'Groups';
    this.onlineGroupsList.doc_link = 'https://docs.nandbox.com/docs/chat-group';
    this.onlineGroupsList.features = [
      this.onlineGroupsListItem1020,
      this.onlineGroupsListItem1021,
      this.onlineGroupsListItem1022,
      this.onlineGroupsListItem1023,
      this.onlineGroupsListItem1024,
      this.onlineGroupsListItem1025,
      this.onlineGroupsListItem1026,
      this.onlineGroupsListItem1027,
      this.onlineGroupsListItem1028,
      this.onlineGroupsListItem1029,
      this.onlineGroupsListItem1030,
      this.onlineGroupsListItem1031,
      this.onlineGroupsListItem1032,
      this.onlineGroupsListItem1033,
      this.onlineGroupsListItem1034,
      this.onlineGroupsListItem1035,
      this.onlineGroupsListItem1036,
      this.onlineGroupsListItem1037,
      this.onlineGroupsListItem1038,
      this.onlineGroupsListItem1039,
      this.onlineGroupsListItem1040,
      this.onlineGroupsListItem1041,
      this.onlineGroupsListItem1042,
      this.onlineGroupsListItem1043,
      this.onlineGroupsListItem1044,
      this.onlineGroupsListItem1045,
      this.onlineGroupsListItem1046,
      this.onlineGroupsListItem1047,
      this.onlineGroupsListItem1048,
      this.onlineGroupsListItem1049,
      this.onlineGroupsListItem1050,
      this.onlineGroupsListItem1051,
      this.onlineGroupsListItem1052,
      this.onlineGroupsListItem1053,
      this.onlineGroupsListItem1054,
      this.onlineGroupsListItem1055,
      this.onlineGroupsListItem1056,
      this.onlineGroupsListItem1057,
      this.onlineGroupsListItem1058,
      this.onlineGroupsListItem1059,
      this.onlineGroupsListItem1060,
      this.onlineGroupsListItem1061,
      this.onlineGroupsListItem1062,
      this.onlineGroupsListItem1063,
      this.onlineGroupsListItem1064,
      this.onlineGroupsListItem1065,
      this.onlineGroupsListItem1066,
      this.onlineGroupsListItem1067,
      this.onlineGroupsListItem1068,
      this.onlineGroupsListItem1069,
      this.onlineGroupsListItem1070,
      this.onlineGroupsListItem1071,
      this.onlineGroupsListItem1072,
      this.onlineGroupsListItem1073,
      this.onlineGroupsListItem1074,
      this.onlineGroupsListItem1075,
      this.onlineGroupsListItem1076,
      this.onlineGroupsListItem1077,
      this.onlineGroupsListItem1078,
      this.onlineGroupsListItem1079,
      this.onlineGroupsListItem1080,
      this.onlineGroupsListItem1081,
      this.onlineGroupsListItem1082,
      this.onlineGroupsListItem1083,
      this.onlineGroupsListItem1084,
      this.onlineGroupsListItem1085,
      this.onlineGroupsListItem1086,
      this.onlineGroupsListItem1087,
      this.onlineGroupsListItem1088,
      this.onlineGroupsListItem1089,
      this.onlineGroupsListItem1090,
      this.onlineGroupsListItem1091,
      this.onlineGroupsListItem1092,
      this.onlineGroupsListItem1093,
      this.onlineGroupsListItem1094,
      this.onlineGroupsListItem1095,
      this.onlineGroupsListItem1097
    ];

    this.paypal.title = 'PayPal';
    this.paypal.subTitle = '1 Feature';
    this.paypal.desc =
      'PayPal is one of the largest online payment transfer platforms in the world. Drag and drop its module to integrate it instantly with your app.';
    this.paypal.icon = 'icon-paypal';
    this.paypal.id = 30104;
    this.paypal.parameter = 0;
    this.paypal.is_vapp = 0;
    this.paypal.moduleId = 'paypal';

    this.cash.title = 'Cash';
    this.cash.subTitle = '1 Feature';
    this.cash.desc = 'Allows users to have the option to pay by cash.';
    this.cash.icon = 'icon-30105';
    this.cash.id = 30105;
    this.cash.parameter = 0;
    this.cash.is_vapp = 0;

    this.video.title = 'Video';
    this.video.subTitle = '1 Feature';
    this.video.desc =
      'With the new video module, you can include a link that will redirect them to a video of your choice. ';
    this.video.icon = 'icon-519';
    this.video.id = 519;
    this.video.link = this.tabTypes.VIDEO;
    this.video.parameter = 0;
    this.video.is_vapp = 0;
    this.video.doc_link = 'https://docs.nandbox.com/docs/video';

    this.categoryList = [
      this.activeChannels,
      this.userChannels,
      this.channelsList,
      this.groupList,
      this.chatGroup,
      this.onlineGroupsList,
      this.chatbot,
      this.callsLog,

      this.textMediaMessenger,
      this.audioVideoCalling,
      this.postsFeeds,

      this.contactsList,
      // this.chatScreen,
      // this.chatAdmin,

      this.loyaltyCompetitions,
      this.digitalID,
      this.mStore,
      this.userBookings,
      this.calendar,
      this.userInvitations,
      this.qr,
      this.webview,
      // this.map,
      this.booking,
      this.search,
      this.ticketValidate,
      this.ticketRedeem,
      this.stripe,
      this.weAccept,
      this.payTabs,
      this.googleAdds,
      this.mapSearch,
      this.workFlow,
      this.mobileWorkFlow,
      //      this.userVapps,
      this.paypal,
      this.cash,
      this.video
    ];
  }
  ngOnInit() {
    this.subCategory = [];
    if (this.modulesList) {
      for (let i = 0; i < this.categoryList.length; i++) {
        for (let j = 0; j < this.modulesList.length; j++) {
          if (this.modulesList[j] === this.categoryList[i].id) {
            this.subCategory.push(this.categoryList[i]);
          }
        }
      }
    }
    this._ref.detectChanges();
  }

  ngOnChanges() {
    this._ref.detectChanges();
    this.subCategory = [];

    if (this.categoryList.length >= 1 && this.modulesList) {
      for (let i = 0; i < this.categoryList.length; i++) {
        for (let j = 0; j < this.modulesList.length; j++) {
          if (this.modulesList[j] === this.categoryList[i].id) {
            this.subCategory.push(this.categoryList[i]);
          }
        }
      }
    }

    this._ref.detectChanges();
  }

  getModuleItem(id: number) {
    let items = [];
    for (let i = 0; i < this.categoryList.length; i++) {
      if (id == this.categoryList[i].id) {
        items = this.categoryList[i].features;
      }
    }
    return items;
  }

  getModuleData(id: number) {
    for (let i = 0; i < this.categoryList.length; i++) {
      if (id == this.categoryList[i].id) {
        return this.categoryList[i];
      }
    }
  }

  getTotalPrice(itemsList, tabsList) {
    let items: FeatureAction[][] = [];
    for (let j = 0; j < tabsList.length; j++) {
      for (let i = 0; i < this.categoryList.length; i++) {
        if (tabsList[j].ui_module_id == this.categoryList[i].id) {
          items.push(this.categoryList[i].features);
        }
      }
    }
    for (let j = 0; j < itemsList.length; j++) {
      for (let i = 0; i < this.categoryList.length; i++) {
        if (itemsList[j].ui_module_id == this.categoryList[i].id) {
          items.push(this.categoryList[i].features);
        }
      }
    }
    return items;
  }
  pageTemp(event) {
    this.pageTempNotify.emit(event);
  }

  createPageTemp(event) {
    this.createPageTempNotify.emit(event);
  }

  showHelpItem(event) {
    if (event) {
      this.helpText = event.desc;
    } else {
      this.helpText = null;
    }
  }

  showMoreText() {
    this.showMore = true;
  }
  hideMoreText() {
    this.showMore = false;
  }

  filterModules(appTypes: any[], excludedTypes: any[]) {
    const newModules: any[] = [];
    for (let i = 0; i < this.categoryList.length; i++) {
      const index = appTypes.indexOf(this.categoryList[i].link);
      const exIndex = excludedTypes.indexOf(this.categoryList[i].link);

      if (
        index === -1 &&
        exIndex === -1 &&
        this.categoryList[i].link &&
        this.categoryList[i].link !== this.tabTypes.WALLET &&
        this.categoryList[i].link !== this.tabTypes.MEMBERSHIP &&
        this.categoryList[i].link !== this.tabTypes.FEED
      ) {
        newModules.push(this.categoryList[i]);
      }
    }

    return newModules;
  }

  createNewPage() {
    this.featureNotify.emit();
  }

  showMyPages() {
    this.myPages = !this.myPages;
  }
  showPagesTemp() {
    this.tempPages = !this.tempPages;
  }
  preparePageView(event) {
    if (event) {
      if (this.pageToView && this.pageToView.id !== event.id) {
        this.pageToView = event;
        this.getPageData(event.id);
      } else if (!this.pageToView) {
        this.pageToView = event;
        this.getPageData(event.id);
      }
      this.showPageView(true);
    } else {
      // this.pageToView = null;
      this.showPageView(false);
    }
  }

  getPageData(id) {
    this.appMgntService.getDetailsOfLinkedPage(id);
  }
  showPageView(value) {
    this.showPagesView = value;
  }
  deleteItem(id: string) {
    this.appMgntService.deleteMyPage(id);
  }
  createNewMenu() {
    this.createNewMenuNotify.emit();
  }

  drag(event, feature) {
    this.enableDragProtection();
    let data: any;
    data = 'createNewPage' + ',New Page';
    event.dataTransfer.setData('text', data);
  }

  onNewPageClick() {
    if (
      this.selectedMenuButton &&
      !this.selectedMenuButtonTab &&
      this.selectedMenuSection === this.actionTypes.MODULE
    ) {
      this.asignPageToMenuBtn();
    } else if (!this.selectedMenuButtonTab && !this.selectedMenuButton) {
      this.asignPageToTabOrNav();
    }
  }

  asignPageToMenuBtn() {
    this.appMgntService._mypageButtonId.next(
      this.currentMenu.rows[this.selectedMenuButton.row].buttons[
        this.selectedMenuButton.col
      ].button_id
    );
    this.appMgntService.createMyPage(
      'New Page',
      null,
      null,
      null,
      null,
      this.pageRef.BTN
    );
    this.appMgntService._mypageSection.next(this.currentPage);
  }
  asignPageToTabOrNav() {
    let data: any;
    data = 'createNewPage' + ',New Page';
    const feature = data.split(',');
    if (
      (feature.length > 1 &&
        this.mainTabs.length < 4 &&
        this.currentPage === this.appScreens.TABS) ||
      (feature.length > 1 && this.currentPage === this.appScreens.NAV_MENU)
    ) {
      if (feature[0] === 'createNewPage') {
        let pageRef = null;
        if (this.currentPage === this.appScreens.TABS) {
          pageRef = this.pageRef.TAB;
        } else if (this.currentPage === this.appScreens.NAV_MENU) {
          pageRef = this.pageRef.NAV;
        }
        this.appMgntService.createMyPage(
          'New Page',
          null,
          null,
          null,
          null,
          pageRef
        );
        this.appMgntService._mypageSection.next(this.currentPage);
      }
    }
  }
  public disableDragProtection(): void {
    this.isProtectingDragAction = false;
  }

  public enableDragProtection(): void {
    this.isProtectingDragAction = true;
  }
}
