import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MyProfileActions from './myProfile.actions';

import { ProfileDetails } from 'src/models/ProfileDetails';
import { IMyProfile } from 'src/models/IMyProfile';
import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class MyProfileDispatchers {
  constructor(private _store: Store<AppState>) {}

  myProfileReceiving(payload: ProfileDetails): void {
    this._store.dispatch(new MyProfileActions.MyProfileReceiving(payload));
  }

  myProfileReceived(payload: ProfileDetails): void {
    this._store.dispatch(new MyProfileActions.MyProfileReceived(payload));
  }

  myThumbnailDownloaded(payload: IMyProfile): void {
    this._store.dispatch(new MyProfileActions.MyThumbnailDownloaded(payload));
  }

  myImageDownloaded(payload: IMyProfile): void {
    this._store.dispatch(new MyProfileActions.MyImageDownloaded(payload));
  }
}
