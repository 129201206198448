/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../abstract-controls/validation-errors/validation-errors.component.ngfactory";
import * as i3 from "../../../abstract-controls/validation-errors/validation-errors.component";
import * as i4 from "../../../validators/form-validators.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "../../../abstract-btns/link-btn/link-btn.component.ngfactory";
import * as i8 from "../../../abstract-btns/link-btn/link-btn.component";
import * as i9 from "../change-password/change-password.component.ngfactory";
import * as i10 from "../change-password/change-password.component";
import * as i11 from "../../../core/auth.service";
import * as i12 from "./forgot-password.component";
var styles_ForgotPasswordComponent = [i0.styles];
var RenderType_ForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validation-errors", [["class", "forgot-password-error"]], null, null, null, i2.View_ValidationErrorsComponent_0, i2.RenderType_ValidationErrorsComponent)), i1.ɵdid(1, 49152, null, 0, i3.ValidationErrorsComponent, [i4.FormValidatorsService], { fieldErrors: [0, "fieldErrors"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.email == null) ? null : _co.email.errors); var currVal_1 = "Email"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ForgotPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 27, "div", [["class", "forgot-password"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "forgot-password-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Forgot Your Password? "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "forgot-password-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Enter your email address & we'll send you a code to reset your password. "])), (_l()(), i1.ɵeld(6, 0, null, null, 22, "form", [["autocomplete", "off"], ["class", "forgot-password-form"], ["form-body", ""], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(8, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(10, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 11, "div", [["class", "faild"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [["for", "Email"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "input", [["autocomplete", "off"], ["formControlName", "email"], ["id", "Email"], ["placeholder", "Email"], ["type", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(17, 671744, null, 0, i5.FormControlName, [[3, i5.ControlContainer], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlName]), i1.ɵdid(19, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["style", "min-height: 25px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_2)), i1.ɵdid(22, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "submit-btn"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send Reset Link"])), (_l()(), i1.ɵeld(25, 0, null, null, 3, "app-link-btn", [["style", "font-size: 16px;"]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.goBackEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LinkBtnComponent_0, i7.RenderType_LinkBtnComponent)), i1.ɵdid(26, 49152, null, 0, i8.LinkBtnComponent, [], { isDefaultLink: [0, "isDefaultLink"] }, { btnClicked: "btnClicked" }), (_l()(), i1.ɵeld(27, 0, null, 1, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Back to sign in "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 8, 0, currVal_7); var currVal_15 = "email"; _ck(_v, 17, 0, currVal_15); var currVal_16 = (((_co.email == null) ? null : _co.email.errors) && ((_co.email == null) ? null : _co.email.touched)); _ck(_v, 22, 0, currVal_16); var currVal_17 = true; _ck(_v, 26, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 19).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 19).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 19).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 19).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 19).ngClassValid; var currVal_13 = i1.ɵnov(_v, 19).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
function View_ForgotPasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-change-password", [], null, null, null, i9.View_ChangePasswordComponent_0, i9.RenderType_ChangePasswordComponent)), i1.ɵdid(2, 114688, null, 0, i10.ChangePasswordComponent, [i5.FormBuilder, i11.AuthService, i4.FormValidatorsService, i1.ChangeDetectorRef], { accountEmail: [0, "accountEmail"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.email.value; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ForgotPasswordComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_3)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showChangePasswordScreen; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showChangePasswordScreen; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i12.ForgotPasswordComponent, [i11.AuthService, i4.FormValidatorsService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordComponentNgFactory = i1.ɵccf("app-forgot-password", i12.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, {}, { goBackNotify: "goBackNotify" }, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
