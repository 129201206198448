import { Action } from '@ngrx/store';

export const enum BookingExceptionsTypes {
  RECEIVE_BOOKING_EXCEPTIONS = 'RECEIVE_BOOKING_EXCEPTIONS',
  SET_BOOKING_EXCEPTIONS = 'SET_BOOKING_EXCEPTIONS',
  DELETE_BOOKING_EXCEPTIONS = 'DELETE_BOOKING_EXCEPTIONS',
  RESET = 'RESET'
}

export class ReceiveBookingExceptions implements Action {
  readonly type = BookingExceptionsTypes.RECEIVE_BOOKING_EXCEPTIONS;
  constructor(public chatId: string, public dates: string[]) {}
}

export class SetBookingExceptions implements Action {
  readonly type = BookingExceptionsTypes.SET_BOOKING_EXCEPTIONS;
  constructor(
    public chatId: string,
    public dates: string[],
    public recordIndex?: number
  ) {}
}

export class DeleteBookingExceptions implements Action {
  readonly type = BookingExceptionsTypes.DELETE_BOOKING_EXCEPTIONS;
  constructor(public chatId: string, public dates: string[]) {}
}

export class Reset implements Action {
  readonly type = BookingExceptionsTypes.RESET;
}

export type Actions =
  | ReceiveBookingExceptions
  | SetBookingExceptions
  | DeleteBookingExceptions
  | Reset;
