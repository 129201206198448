import * as CouponMethods from 'src/models/ICoupon';

export class CouponMiddleware {
  public static initMessage(
    msg: CouponMethods.RcvdCouponMessage
  ): CouponMethods.ICoupon {
    const localMessage: CouponMethods.ICoupon = {};

    const message = msg.coupon;
    if (message.name) {
      localMessage.name = message.name;
    }
    if (message.code) {
      localMessage.code = message.code;
    }
    if (message.couponId) {
      localMessage.couponId = message.couponId;
    }
    if (message.description) {
      localMessage.description = message.description;
    }
    if (message.groupId) {
      localMessage.groupId = message.groupId;
    }
    if (message.status) {
      localMessage.status = message.status;
    }
    if (message.value) {
      localMessage.value = message.value;
    }
    if (!message.share) {
      localMessage.share = 0;
    } else {
      localMessage.share = 1;
    }
    // for localuse
    if (message.expire) {
      const expireDateParts = message.expire.split('-');
      const localExpireDate = new Date(
        Number(expireDateParts[0]),
        Number(expireDateParts[1]) - 1,
        Number(expireDateParts[2])
      );
      localMessage.expire = localExpireDate;
    }
    return Object.assign({}, localMessage);
  }

  public static isCouponExist(
    list: CouponMethods.ICoupon[],
    rcvdObj: CouponMethods.ICoupon
  ) {
    for (let i = 0; i < list.length; i++) {
      if (
        (rcvdObj.couponId && list[i].couponId === rcvdObj.couponId) ||
        (rcvdObj.reference && list[i].reference === rcvdObj.reference)
      ) {
        return true;
      }
    }
    return false;
  }

  public static updateCoupon(
    state: CouponMethods.ICoupon[],
    rcvdMsg: CouponMethods.ICoupon
  ) {
    return state.map(msg => {
      if (
        (msg.couponId && msg.couponId === rcvdMsg.couponId) ||
        (msg.reference && msg.reference === rcvdMsg.reference)
      ) {
        if (rcvdMsg.couponId) {
          msg.couponId = rcvdMsg.couponId;
        }
        if (rcvdMsg.code) {
          msg.code = rcvdMsg.code;
        }
        if (rcvdMsg.description) {
          msg.description = rcvdMsg.description;
        }
        if (rcvdMsg.expire) {
          msg.expire = rcvdMsg.expire;
        }
        if (rcvdMsg.groupId) {
          msg.groupId = rcvdMsg.groupId;
        }
        if (rcvdMsg.name) {
          msg.name = rcvdMsg.name;
        }
        if (rcvdMsg.share === 1 || rcvdMsg.share === 0) {
          msg.share = rcvdMsg.share;
        }
        if (rcvdMsg.status) {
          msg.status = rcvdMsg.status;
        }
        if (rcvdMsg.value) {
          msg.value = rcvdMsg.value;
        }
      }
      return msg;
    });
  }

  public static sendCouponMessage(coupon: CouponMethods.ICoupon) {
    const msgToBeSent: CouponMethods.OutCouponMessage = {};
    // msgToBeSent.method = message.method;
    if (coupon.couponId) {
      msgToBeSent.couponId = coupon.couponId;
    }
    if (coupon.reference) {
      msgToBeSent.reference = coupon.reference;
    }
    if (coupon.code) {
      msgToBeSent.code = coupon.code;
    }
    if (coupon.description) {
      msgToBeSent.description = coupon.description;
    }
    if (coupon.expire) {
      msgToBeSent.expire = this.formatDate(coupon.expire);
    }
    if (coupon.groupId) {
      msgToBeSent.groupId = coupon.groupId;
    }
    if (coupon.name) {
      msgToBeSent.name = coupon.name;
    }
    if (coupon.share === 1 || coupon.share === 0) {
      msgToBeSent.share = coupon.share;
    }

    if (coupon.status) {
      msgToBeSent.status = coupon.status;
    }
    if (coupon.value) {
      msgToBeSent.value = Number(coupon.value);
    }

    return msgToBeSent;
  }

  public static formatDate(d: Date) {
    const newDate = new Date(d);
    return (
      newDate.getFullYear() +
      '-' +
      ('0' + (newDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + newDate.getDate()).slice(-2)
    );
  }
}
