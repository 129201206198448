import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  OnDestroy
} from '@angular/core';

import { CellsService } from '../cells.service';
import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-gif-cell',
  templateUrl: './gif-cell.component.html',
  styleUrls: ['./gif-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GifCellComponent implements OnChanges, OnDestroy {
  @Input() localMedia: string;
  @Input() localThumbnail: string;
  @Input() mediaStatus: string;
  @Input() fileSize: number;
  @Input() height: number;
  @Input() width: number;
  @Input() caption: string;
  @Input() percent: number;
  @Input() themeColor: string;
  @Input() isFullScreen = false;

  @Output() retryNotify: EventEmitter<boolean> = new EventEmitter(false);
  @Output() cancelNotify: EventEmitter<boolean> = new EventEmitter(false);
  @Output() mediaClickedNotifier: EventEmitter<boolean> = new EventEmitter(
    false
  );

  @ViewChild('gifPlayer', { static: false }) gifPlayer: ElementRef;

  isPause = true;
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  constructor(private _cellsService: CellsService) {}

  ngOnChanges() {
    if (this.width && this.height) {
      [this.width, this.height] = this._cellsService.resizeMedia(
        this.width,
        this.height
      );
    }
  }

  ngOnDestroy() {
    if (this.gifPlayer) {
      this.gifPlayer.nativeElement.pause();
    }
  }

  get theme() {
    return {
      'border-top-color': this.themeColor,
      'border-right -color': this.themeColor
    };
  }

  get media() {
    return this._cellsService.getMedia(this.localMedia);
  }

  get thumbnail() {
    return this._cellsService.getMedia(this.localThumbnail);
  }

  playGif() {
    this.isPause = false;
    this.gifPlayer.nativeElement.play();
  }

  stopGif() {
    this.isPause = true;
    this.gifPlayer.nativeElement.pause();
    this.gifPlayer.nativeElement.currentTime = 0;
  }

  mediaClickedEvent() {
    this.mediaClickedNotifier.emit(true);
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
