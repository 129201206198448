import { MapRoute, MapRouteUI } from 'src/models/MapRoute';
import { Actions } from './mapRoute.actions';

import * as actions from './mapRoute.actions';
import {
  ListRequestDetails,
  MapRoutesScreens,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';
const INITIAL_STATE: MapRouteUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  currentMapRouteScreen: MapRoutesScreens.MAP_ROUTES_LIST
};

export function mapRouteReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.MapRouteActionTypes.RESET_MAP_ROUTES_PAGING:
      return INITIAL_STATE;
    case actions.MapRouteActionTypes.GET_NEXT_MAP_ROUTES_PAGE:
      return {
        ...state,
        direction: PageDirection.NEXT
      };
    case actions.MapRouteActionTypes.GET_PREV_MAP_ROUTES_PAGE:
      return {
        ...state,
        direction: PageDirection.PREV
      };
    case actions.MapRouteActionTypes.MAP_ROUTE_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.mapRoutes && action.mapRoutes.length) > 0
            ? action.mapRoutes
            : state.currentPage
      };
    }
    case actions.MapRouteActionTypes.MAP_ROUTE_DETAILS_RECEIVED: {
      const isFound =
        state.currentPage.filter(fn => fn.id === action.mapRoute.id).length > 0;

      if (isFound) {
        return {
          ...state,
          currentPage: state.currentPage.map(r => {
            if (r.id === action.mapRoute.id) {
              return action.mapRoute;
            }
            return r;
          })
        };
      }

      return {
        ...state,
        currentPage: [...state.currentPage, action.mapRoute]
      };
    }
    case actions.MapRouteActionTypes.MAP_ROUTE_CREATED: {
      if (
        !state.currentPage.find(
          mapSetting => mapSetting.id === action.mapRoute.id
        )
      ) {
        return {
          ...state,
          currentPage: [action.mapRoute, ...state.currentPage]
        };
      } else {
        return state;
      }
    }
    case actions.MapRouteActionTypes.UI_MAP_ROUTE_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.MapRouteActionTypes.UI_SET_MAP_ROUTE_CURRENT_SCREEN: {
      return {
        ...state,
        currentMapRouteScreen: action.screen
      };
    }
    case actions.MapRouteActionTypes.MAP_ROUTE_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(
          mapRoute => mapRoute.id !== action.id
        )
      };
    case actions.MapRouteActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
