import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import * as AuthActions from './../auth/auth.actions';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { ContainerDispatchers } from '../channelAppContainers/container.dispatchers';
import { ChannelAppUiDispatchers } from './channelAppUI.dispatchers';
import { APP_CONFIG_COMPLETE, UISections, ChannelAppScreens } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
export class ChannelAppUIEffects {
    constructor(actions$, store$, _uiDispacthers, _channelAppUiDispatchers, _containerDispatchers) {
        this.actions$ = actions$;
        this.store$ = store$;
        this._uiDispacthers = _uiDispacthers;
        this._channelAppUiDispatchers = _channelAppUiDispatchers;
        this._containerDispatchers = _containerDispatchers;
        /* after select channel in merchant request another requests */
        this.authChatSelect = this.actions$.pipe(ofType(AuthActions.RECEIVE_AUTH_CHAT_SELECTED), withLatestFrom(this.store$
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats]) => {
            const action = val;
            const selectedMainChat = mainChats.find(mainChat => mainChat.id === action.payload.chatId);
            if (selectedMainChat &&
                !selectedMainChat.subChannel &&
                APP_CONFIG_COMPLETE !== `${action.payload.progressWeb}` &&
                action.payload.appConfig) {
                let screenNum = parseInt(action.payload.progressWeb, 10);
                this._uiDispacthers.setSection(UISections.APP);
                const totalNumberOfScreens = Object.keys(ChannelAppScreens).length / 2;
                if (screenNum !== null &&
                    screenNum !== undefined &&
                    screenNum < totalNumberOfScreens) {
                    screenNum = screenNum === 0 ? 1 : screenNum;
                    this._channelAppUiDispatchers.goToSpecificPage(screenNum);
                }
                else if (screenNum === totalNumberOfScreens) {
                    this._channelAppUiDispatchers.goToSpecificPage(screenNum);
                }
            }
        }));
        this.selectTab = this.actions$.pipe(ofType("SELECT_TAB" /* SELECT_TAB */), map((action) => {
            this._containerDispatchers.selectContainer(action.selectedTab);
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChannelAppUIEffects.prototype, "authChatSelect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChannelAppUIEffects.prototype, "selectTab", void 0);
