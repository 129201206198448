import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as AppReleasesMethods from 'src/models/AppRelease';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { GetAppRealseVerData, SetAppleKeyCode } from 'src/models/AppPublishInfo';
import { AppReleaseDispatchers } from './appRelease.dispatchers';
import * as isEqual from 'lodash.isequal';
import { Store } from '@ngrx/store';
import { PageDirection } from 'src/models/constants';
export class AppReleaseEffects {
    constructor(actions$, _socketGateway, _appReleaseDispatchers, _store, _appMgmtService) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._appReleaseDispatchers = _appReleaseDispatchers;
        this._store = _store;
        this._appMgmtService = _appMgmtService;
        this.listAppReleasesResetPaging = this.actions$.pipe(ofType("UI_RELEASE_RESET_PAGING" /* UI_RELEASE_RESET_PAGING */), map((action) => {
            this._appReleaseDispatchers.getNextPageRelease();
        }));
        this.getNextBlackListPage = this.actions$.pipe(ofType("UI_RELEASE_RESET_PAGING" /* UI_RELEASE_RESET_PAGING */), withLatestFrom(this._store
            .select(state => state.appReleaseReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, appReleaseUiReducer]) => {
            const action = val;
            const prevRequest = appReleaseUiReducer.previousRequest;
            if (!(appReleaseUiReducer.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                appReleaseUiReducer.hash === prevRequest.hash)) {
                // this._socketGateway.sendSocketMessage(
                //   new GetBlackList(
                //     null,
                //     0,
                //     blackListUiReducer.hash,
                //     blackListUiReducer.eop,
                //   )
                // );
            }
            else {
                // this._uiBlackListDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevBlackListPage = this.actions$.pipe(ofType("UI_RELEASE_GET_PREV_PAGE" /* UI_RELEASE_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.appReleaseReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, appReleaseUiReducer]) => {
            const action = val;
            const prevRequest = appReleaseUiReducer.previousRequest;
            if (!(appReleaseUiReducer.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                appReleaseUiReducer.hash === prevRequest.hash)) {
                // this._socketGateway.sendSocketMessage(
                //   new GetBlackList(
                //     null,
                //     0,
                //     blackListUiReducer.hash,
                //     blackListUiReducer.eop,
                //   )
                // );
            }
            else {
                // this._uiBlackListDispatchers.setStatusAsIdle();
            }
        }));
        this.listAppReleases = this.actions$.pipe(ofType("LIST_APP_RELEASES" /* LIST_APP_RELEASES */), map((action) => {
            this._socketGateway.sendSocketMessage(new AppReleasesMethods.ListAppRelease());
        }));
        this.listAppReleaseId = this.actions$.pipe(ofType("LIST_APP_RELEASE_ID" /* LIST_APP_RELEASE_ID */), map((action) => {
            this._socketGateway.sendSocketMessage(new AppReleasesMethods.ListAppReleaseId(action.payload.id));
        }));
        this.removeAppRelease = this.actions$.pipe(ofType("REMOVE_APP_RELEASE" /* REMOVE_APP_RELEASE */), map((action) => {
            this._socketGateway.sendSocketMessage(new AppReleasesMethods.RemoveAppRelease(action.id));
        }));
        this.needAppleKey = this.actions$.pipe(ofType("SHOW_APPLE_KEY_FORM" /* SHOW_APPLE_KEY_FORM */), map((action) => {
            this._appMgmtService.showAppleForm(action.release_id);
        }));
        this.sendAppleKeyCode = this.actions$.pipe(ofType("SEND_APPLE_KEY_CODE" /* SEND_APPLE_KEY_CODE */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppleKeyCode(action.code, action.release_id));
        }));
        this.getAppRealseVerData = this.actions$.pipe(ofType("GET_APP_RELEASE_VER" /* GET_APP_RELEASE_VER */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppRealseVerData(action.release_id + ''));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "listAppReleasesResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "getNextBlackListPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "getPrevBlackListPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "listAppReleases", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "listAppReleaseId", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "removeAppRelease", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "needAppleKey", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "sendAppleKeyCode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppReleaseEffects.prototype, "getAppRealseVerData", void 0);
