import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as Methods from 'src/models/MapService';
import { MapServiceDispatchers } from './mapService.dispatchers';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
export class MapServiceEffects {
    /** No need for the following effect because of all the data comes with the list */
    // @Effect({ dispatch: false })
    // listReceived = this.actions$.pipe(
    //   ofType(MapServiceActionTypes.MAP_SERVICE_LIST_RECEIVED),
    //   map((action: MapActions.MapServiceListReceived) => {
    //     if (action.mapServices && action.mapServices.length > 0) {
    //       action.mapServices.forEach(service => {
    //         this._socketGateway.sendSocketMessage(
    //           new Methods.GetMapService(service.id)
    //         );
    //       });
    //     }
    //   })
    // );
    constructor(actions$, _socketGateway, _mapServiceDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._mapServiceDispatchers = _mapServiceDispatchers;
        this._store = _store;
        this.resetMapService = this.actions$.pipe(ofType("RESET_MAP_SERVICE_PAGING" /* RESET_MAP_SERVICE_PAGING */), map((action) => {
            this._mapServiceDispatchers.getNextMapService();
        }));
        this.getNextMapServicePage = this.actions$.pipe(ofType("GET_NEXT_MAP_SERVICE_PAGE" /* GET_NEXT_MAP_SERVICE_PAGE */), withLatestFrom(this._store
            .select(state => state.mapServiceReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapServiceUiState]) => {
            const action = val;
            const prevRequest = mapServiceUiState.previousRequest;
            if (!(mapServiceUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapServiceUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapServices(0, mapServiceUiState.eop, mapServiceUiState.hash));
            }
            else {
                this._mapServiceDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMapServicePage = this.actions$.pipe(ofType("GET_PREV_MAP_SERVICE_PAGE" /* GET_PREV_MAP_SERVICE_PAGE */), withLatestFrom(this._store
            .select(state => state.mapServiceReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapServiceUiState]) => {
            const action = val;
            const prevRequest = mapServiceUiState.previousRequest;
            if (!(mapServiceUiState.eop === prevRequest.eop &&
                PageDirection.PREV === prevRequest.direction &&
                mapServiceUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapServices(1, mapServiceUiState.sop, mapServiceUiState.hash));
            }
            else {
                this._mapServiceDispatchers.setStatusAsIdle();
            }
        }));
        this.createMapService = this.actions$.pipe(ofType("CREATE_MAP_SERVICE" /* CREATE_MAP_SERVICE */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapService(action.mapService));
        }));
        this.getMapServiceByID = this.actions$.pipe(ofType("GET_MAP_SERVICE_BY_ID" /* GET_MAP_SERVICE_BY_ID */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.GetMapService(action.id));
        }));
        this.updateMapService = this.actions$.pipe(ofType("UPDATE_MAP_SERVICE" /* UPDATE_MAP_SERVICE */), map((action) => {
            const mapService = Object.assign({}, action.mapServiceDetails, { id: action.id });
            this._socketGateway.sendSocketMessage(new Methods.SetMapService(mapService));
        }));
        this.deleteMapService = this.actions$.pipe(ofType("DELETE_MAP_SERVICE" /* DELETE_MAP_SERVICE */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveMapService(action.id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "resetMapService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "getNextMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "getPrevMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "createMapService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "getMapServiceByID", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "updateMapService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapServiceEffects.prototype, "deleteMapService", void 0);
