import { Store } from '@ngrx/store';
import * as UIActions from './ui.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class UIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    chatSelected(chat) {
        this._store.dispatch(new UIActions.ChatSelected(chat));
    }
    subChatSelected(chat) {
        this._store.dispatch(new UIActions.SubChatSelected(chat));
    }
    subChatDeselected() {
        this._store.dispatch(new UIActions.SubChatDeselected());
    }
    messageSelectedForStats(msg) {
        this._store.dispatch(new UIActions.MessageSelectedForStat(msg));
    }
    messageSelected(msg) {
        this._store.dispatch(new UIActions.MessageSelected(msg));
    }
    subParentMessageSelected(msg) {
        this._store.dispatch(new UIActions.SubParentMessageSelected(msg));
    }
    backFromSubGroupReplies() {
        this._store.dispatch(new UIActions.BackFromSubGroupReplies());
    }
    userSelected(userID) {
        this._store.dispatch(new UIActions.UserSelected(userID));
    }
    groupRepliesSelected() {
        this._store.dispatch(new UIActions.GroupRepliesSelected());
    }
    backFromGroupReplies() {
        this._store.dispatch(new UIActions.BackFromGroupReplies());
    }
    listSelected(selectedList) {
        this._store.dispatch(new UIActions.ListSelected(selectedList));
    }
    selectAllChannels() {
        this._store.dispatch(new UIActions.SelectAllChannels());
    }
    selectOneToOneReply(selectedUserID) {
        this._store.dispatch(new UIActions.SelectOneToOneReply(selectedUserID));
    }
    selectTalkToAdmin() {
        this._store.dispatch(new UIActions.SelectTalkToAdmin());
    }
    toggleHelpWindow(status) {
        this._store.dispatch(new UIActions.ToggleHelpWindow(status));
    }
    selectReplyToAdmin() {
        this._store.dispatch(new UIActions.SelectReplyToAdmin());
    }
    backFromOneToOneReply() {
        this._store.dispatch(new UIActions.BackFromOneToOneReply());
    }
    showPopup(popupMessage, popupTitle) {
        this._store.dispatch(new UIActions.ShowPopup(popupMessage, popupTitle));
    }
    showErrorPopup(popupErrorMessage) {
        this._store.dispatch(new UIActions.ShowErrorPopup(popupErrorMessage));
    }
    hidePopup() {
        this._store.dispatch(new UIActions.HidePopup());
    }
    hideErrorSnackbar() {
        this._store.dispatch(new UIActions.HideErrorSnackbar());
    }
    showPreview() {
        this._store.dispatch(new UIActions.ShowPreview());
    }
    hidePreview() {
        this._store.dispatch(new UIActions.HidePreview());
    }
    showDragDropScreen() {
        this._store.dispatch(new UIActions.ShowDragDropScreen());
    }
    hideDragDropScreen() {
        this._store.dispatch(new UIActions.HideDragDropScreen());
    }
    setChannelsListPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetChannelsListPageNumber(pageNumber));
    }
    setChatsListPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetChatsListPageNumber(pageNumber));
    }
    // Normal Post in main channel
    setPostsPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetPostsPageNumber(pageNumber));
    }
    setPostRepliesPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetPostRepliesPageNumber(pageNumber));
    }
    setMessagesPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetMessagesPageNumber(pageNumber));
    }
    // Set Sub-Post Actions
    setSubGroupMessagesPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetSubGroupMessagesPageNumber(pageNumber));
    }
    setSubPostRepliesPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetSubPostRepliesPageNumber(pageNumber));
    }
    setSubPostsPageNumber(pageNumber) {
        this._store.dispatch(new UIActions.SetSubPostsPageNumber(pageNumber));
    }
    setSearchKeyWord(searchKeyWord) {
        this._store.dispatch(new UIActions.SetSearchKeyWord(searchKeyWord));
    }
    setContactsCount(count) {
        this._store.dispatch(new UIActions.SetContactsCount(count));
    }
    setGroupsCount(count) {
        this._store.dispatch(new UIActions.SetGroupsCount(count));
    }
    evaluateShowDashboard() {
        this._store.dispatch(new UIActions.EvaluateShowDashboard());
    }
    detailsReceived() {
        this._store.dispatch(new UIActions.DetailsReceived());
        setTimeout(() => {
            this._store.dispatch(new UIActions.ShowDashboard());
        }, 1000);
    }
    setHasNoContacts() {
        this._store.dispatch(new UIActions.SetHasNoContacts());
    }
    showMediaScreen(message) {
        this._store.dispatch(new UIActions.ShowMediaScreen(message));
    }
    hideMediaScreen() {
        this._store.dispatch(new UIActions.HideMediaScreen());
    }
    selectChannelPost(message) {
        this._store.dispatch(new UIActions.SelectChannelPost(message));
    }
    selectSubChannelPost(message) {
        this._store.dispatch(new UIActions.SelectSubChannelPost(message));
    }
    /* Merchant Web Only */
    subUserSelected(userID) {
        this._store.dispatch(new UIActions.SubUserSelected(userID));
    }
    subUserDeselected() {
        this._store.dispatch(new UIActions.SubUserDeselected());
    }
    setClickedMessage(message) {
        this._store.dispatch(new UIActions.SetClickedMessage(message));
    }
    setSection(payload, subSection) {
        this._store.dispatch(new UIActions.SetSection(payload, subSection));
    }
    setPageNumber(payload) {
        this._store.dispatch(new UIActions.SetPageNumber(payload));
    }
    dateSelected(payload) {
        this._store.dispatch(new UIActions.DateSelected(payload));
    }
    botMenuSelected(payload) {
        this._store.dispatch(new UIActions.BotMenuSelected(payload));
    }
    setAuthPrivilege(payload) {
        this._store.dispatch(new UIActions.SetAuthPrivilege(payload));
    }
    setAuthPrivilegeModules(payload) {
        this._store.dispatch(new UIActions.SetAuthPrivilegeModules(payload));
    }
    setChatLabels(payload) {
        this._store.dispatch(new UIActions.SetChatLabels(payload));
    }
    getChatLabels() {
        this._store.dispatch(new UIActions.GetChatLabels());
    }
    saveChatLabels(payload) {
        this._store.dispatch(new UIActions.SaveChatLabels(payload));
    }
    setSideMenuStatus(status) {
        this._store.dispatch(new UIActions.SetSideMenuStatus(status));
    }
    mapMarkerSelected(payload) {
        this._store.dispatch(new UIActions.MapMarkerSelected(payload));
    }
    togglePopupUpgradePlanModule(moduleID) {
        this._store.dispatch(new UIActions.TogglePopupUpgradePlanModule(moduleID));
    }
    addToastMessage(msg, status) {
        this._store.dispatch(new UIActions.AddToastMessage(msg, status));
    }
    removeToastMessage(index) {
        this._store.dispatch(new UIActions.RemoveToastMessage(index));
    }
    toggleExpiredPopup(status) {
        this._store.dispatch(new UIActions.ToggleExpiredPopup(status));
    }
    receiveConfModules(modules) {
        this._store.dispatch(new UIActions.ReceiveConfModules(modules));
    }
    receiveMediaGallary(value) {
        this._store.dispatch(new UIActions.ReceiveMediaGallary(value));
    }
    setChatToTemplate(id, type, value) {
        this._store.dispatch(new UIActions.SetChatToTemplate(id, type, value));
    }
    setTemplateMessage(channelId, messageType, messageId, message) {
        this._store.dispatch(new UIActions.SetTemplateMessage(channelId, messageType, messageId, message));
    }
    enableSetTemplateBtn(value) {
        this._store.dispatch(new UIActions.EnableSetTemplateBtn(value));
    }
}
UIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UIDispatchers_Factory() { return new UIDispatchers(i0.ɵɵinject(i1.Store)); }, token: UIDispatchers, providedIn: "root" });
