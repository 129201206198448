import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AppMgmtService } from '../../../../appMgmt.service';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NextButtonComponent {
  @Input() hideNextButton: boolean;
  @Input() currentScreenNumber: number;
  @Input() isChannelConfOnly: boolean;

  constructor(public appMgmtService: AppMgmtService) {}

  getNextPage() {
    if (
      (this.currentScreenNumber <= 1 && this.isChannelConfOnly) ||
      !this.isChannelConfOnly
    ) {
      this.appMgmtService.getNextPage();
    } else if (this.currentScreenNumber == 2 && this.isChannelConfOnly) {
      this.appMgmtService.goToSpecificPage(4);
    } else {
      this.appMgmtService.goToSpecificPage(6);
    }
  }
}
