import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';

import { MessageStyles, MessageTypes } from 'src/models/constants';

@Component({
  selector: 'app-select-message-style',
  templateUrl: './select-message-style.component.html',
  styleUrls: ['./select-message-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageStyleComponent {
  @Input() messageType: string;

  localState = {
    slectedWide: true,
    slectedWideBottom: false,
    slectedLeftSide: false
  };
  mType: typeof MessageTypes = MessageTypes;
  mStyles: typeof MessageStyles = MessageStyles;
  @ViewChild('wide', { static: false }) wide: ElementRef;
  @ViewChild('leftSide', { static: false }) leftSide: ElementRef;
  constructor() {}

  selectEvent(e: number) {
    if (
      !this.localState.slectedWide &&
      !this.localState.slectedLeftSide &&
      !this.localState.slectedWideBottom
    ) {
      this.localState = {
        ...this.localState,
        slectedWide: true,
        slectedWideBottom: false,
        slectedLeftSide: false
      };
    } else if (e === this.mStyles.WIDE) {
      this.wide.nativeElement.checked = true;
      this.leftSide.nativeElement.checked = false;
      this.localState = {
        ...this.localState,
        slectedWide: true,
        slectedWideBottom: false,
        slectedLeftSide: false
      };
    } else if (e === this.mStyles.LEFT_SIDE) {
      this.wide.nativeElement.checked = false;
      this.leftSide.nativeElement.checked = true;
      this.localState = {
        ...this.localState,
        slectedWide: false,
        slectedWideBottom: false,
        slectedLeftSide: true
      };
    } else if (e === this.mStyles.WIDE_BOOTTOM) {
      this.localState = {
        ...this.localState,
        slectedWide: false,
        slectedWideBottom: true,
        slectedLeftSide: false
      };
    }
  }

  getMessageStyle() {
    if (
      this.messageType === MessageTypes.PHOTO ||
      this.messageType === MessageTypes.VIDEO ||
      this.messageType === MessageTypes.GIF ||
      this.messageType === MessageTypes.ARTICLE ||
      this.messageType === MessageTypes.TEXT
    ) {
      if (this.localState.slectedWide) {
        return this.mStyles.WIDE;
      } else if (this.localState.slectedLeftSide) {
        return this.mStyles.LEFT_SIDE;
      } else if (this.localState.slectedWideBottom) {
        return this.mStyles.WIDE_BOOTTOM;
      }
    }
    return null;
  }
}
