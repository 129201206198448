import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMasonryModule } from 'ngx-masonry';

import { SharedModule } from '../shared/shared.module';
import { NgxModule } from '../ngx.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';

import { MediaCenterFileComponent } from './media-center-file/media-center-file.component';
import { MediaCenterModalComponent } from './media-center-modal/media-center-modal.component';
import { MediaCenterPhotoComponent } from './media-center-photo/media-center-photo.component';
import { MediaCenterVideoComponent } from './media-center-video/media-center-video.component';
import { MediaCenterAudioComponent } from './media-center-audio/media-center-audio.component';
import { MediaCenterUploaderListComponent } from './media-center-uploader-list/media-center-uploader-list.component';
import { MediaCenterLoadingCoverComponent } from './media-center-loading-cover/media-center-loading-cover.component';
import { MediaCenterUploaderInputComponent } from './media-center-uploader-input/media-center-uploader-input.component';
import { MediaCenterTabsComponent } from './media-center-tabs/media-center-tabs.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    NgxMasonryModule,
    AbstractIconsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    SharedModule,
    ImageCropperModule,
    NgxModule,
    TranslateModule
  ],
  declarations: [
    MediaCenterModalComponent,
    MediaCenterUploaderInputComponent,
    MediaCenterUploaderListComponent,
    MediaCenterPhotoComponent,
    MediaCenterVideoComponent,
    MediaCenterAudioComponent,
    MediaCenterFileComponent,
    MediaCenterLoadingCoverComponent,
    MediaCenterTabsComponent
  ],
  exports: [MediaCenterModalComponent]
})
export class MediaCenterModule {}
