import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { GetAppConfigsHistory, GetAppConfigHistoryByID } from 'src/models/ChannelAppConfig';
export class AppConfigHistoryEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.requestAppConfigsHistoryList = this.actions$.pipe(ofType("REQUEST_APP_CONFIGS_HISTORY" /* REQUEST_APP_CONFIGS_HISTORY */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppConfigsHistory());
        }));
        this.requestAppConfigById = this.actions$.pipe(ofType("RESTORE_APP_CONFIG_BY_ID" /* RESTORE_APP_CONFIG_BY_ID */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppConfigHistoryByID(action.id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigHistoryEffects.prototype, "requestAppConfigsHistoryList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigHistoryEffects.prototype, "requestAppConfigById", void 0);
