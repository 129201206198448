import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';

export const enum TagsUIActionTypes {
  UI_RECEIVED_TAGS = 'UI_RECEIVED_TAGS'
}

export class TagsReceived implements Action {
  readonly type = TagsUIActionTypes.UI_RECEIVED_TAGS;
  constructor(public payload: IChat, public id: string) {}
}

export type Actions = TagsReceived;
