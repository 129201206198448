<app-headerfull-frame
  action
  [title]="'Cancel Subscription'"
  [showBack]="true"
  (cancelNotfiy)="closeEvent()"
  (backNotfiy)="backEvent()"
>
  <div class="confirm-submit">
    <div class="confirm-submit-header">
      Are you sure you want to cancel your nandbox subscription?
    </div>

    <div class="confirm-submit-footer">
      <app-border-btn (btnClicked)="goToPaymentPage()">
        Yes
      </app-border-btn>
      <app-border-btn (btnClicked)="backEvent()">
        No
      </app-border-btn>
    </div>
  </div>
</app-headerfull-frame>
