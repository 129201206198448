import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as BookingPeriodsActions from './bookingPeriods.actions';
import {
  setBookingPeriods,
  RemoveBookingPeriods
} from 'src/models/BookingPeriods';

@Injectable()
export class BookingPeriodsEffects {
  @Effect({ dispatch: false })
  createBookingPeriods = this.actions$.pipe(
    ofType(
      BookingPeriodsActions.BookingPeriodsActionTypes.CREATE_BOOKING_PERIODS
    ),
    map((action: BookingPeriodsActions.CreateBookingPeriods) => {
      this._socketGateway.sendSocketMessage(
        setBookingPeriods(action.bookingPeriods, true)
      );
    })
  );

  @Effect({ dispatch: false })
  updateBookingPeriods = this.actions$.pipe(
    ofType(
      BookingPeriodsActions.BookingPeriodsActionTypes.UPDATE_BOOKING_PERIODS
    ),
    map((action: BookingPeriodsActions.UpdateBookingPeriods) => {
      this._socketGateway.sendSocketMessage(
        setBookingPeriods(action.bookingPeriods)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteBookingPeriods = this.actions$.pipe(
    ofType(
      BookingPeriodsActions.BookingPeriodsActionTypes.DELETE_BOOKING_PERIODS
    ),
    map((action: BookingPeriodsActions.DeleteBookingPeriods) => {
      this._socketGateway.sendSocketMessage(
        new RemoveBookingPeriods(action.chatId, action.data)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
