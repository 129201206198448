<!-- chat Box -->
<div class="box" *ngIf="showUsers" style="background: #fff;">
  <div style="display: flex;">
    <img
      alt="close"
      src="./assets/img/screens-close.svg"
      (click)="toggleUsers(false)"
      style="margin-left: auto;"
    />
  </div>
  <ul>
    <ng-container *ngFor="let item of uiService?.getChatUsersList$ | async">
      <li
        *ngIf="uiService?.getProfileByID$(item) | async as user"
        (click)="addMinction(user)"
      >
        <app-profile-image
          class="projection-avatar"
          [id]="user?.user_id"
          [chatType]="'Individual'"
          [emSize]="2.8"
          style="margin-right: 1em;"
        >
        </app-profile-image>
        {{ user?.name }}
      </li>
    </ng-container>
  </ul>
</div>
<div class="chat" *ngIf="!isScreenBot && !isScreenMessages">
  <div class="chat-box">
    <div
      #dropdownEmoji
      class="btn-group align-end clickable-cursor"
      dropdown
      [autoClose]="true"
      [dropup]="true"
      [isOpen]="isEmojiKeyboardOpen"
      (isOpenChange)="toggleEmojiKeyboard($event)"
    >
      <div dropdownToggle type="button">
        <!-- <i
          class="smile-emoji far fa-smile"
          [class.hide]="isRecord"
          [class.open]="isEmojiKeyboardOpen"
          aria-hidden="true"
        ></i> -->
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-sections.svg#icon-emoji-copy"></use>
        </svg>
      </div>
      <ul
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu emoji-box"
        role="menu"
      >
        <app-emoji-keyboard
          (emoijNotify)="emoijNotify($event)"
        ></app-emoji-keyboard>
      </ul>
    </div>
    <app-upload-files
      class="align-end clickable-cursor"
      [class.hide]="isRecord"
      (filesSelectedNotify)="sendMedia($event)"
    ></app-upload-files>
    <div
      class="edit wrap-text copyable-item text-cursor"
      (keyup)="onFocus($event)"
      [class.hide]="isRecord"
      data-text="Type a message..."
      contenteditable
      #textBox
      id="chatBox"
    ></div>
    <app-record-voice-note
      *ngIf="!showSend"
      class="align-end clickable-cursor"
      [selectedChat]="selectedChat"
      (recordVoice)="startRecordEvent($event)"
      (sendVoiceNotify)="sendVoiceNote($event)"
      (micNotFoundNotify)="micNotFoundEvent()"
    ></app-record-voice-note>
    <svg
      class="svg-icon icon-icon send"
      (click)="sendMessage()"
      *ngIf="showSend"
    >
      <use xlink:href="/assets/svg/app-sections.svg#icon-send"></use>
    </svg>
  </div>
</div>
<div class="chat-bot" *ngIf="isScreenBot && !isScreenMessages">
  <div class="chat-bot-box">
    <div
      #dropdownEmoji
      class="btn-group align-end clickable-cursor"
      dropdown
      [autoClose]="true"
      [dropup]="true"
      [isOpen]="isEmojiKeyboardOpen"
      (isOpenChange)="toggleEmojiKeyboard($event)"
    >
      <div dropdownToggle type="button">
        <i
          class="smile-emoji far fa-smile"
          [class.hide]="isRecord"
          [class.open]="isEmojiKeyboardOpen"
          aria-hidden="true"
        ></i>
      </div>
      <ul
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu emoji-box"
        role="menu"
      >
        <app-emoji-keyboard
          (emoijNotify)="emoijNotify($event)"
        ></app-emoji-keyboard>
      </ul>
    </div>
    <div
      class="edit wrap-text copyable-item text-cursor"
      [class.hide]="isRecord"
      data-text="Type a message..."
      contenteditable
      #textBox
    ></div>
    <app-upload-files
      class="align-end clickable-cursor"
      [class.hide]="isRecord"
      [isBotScreen]="true"
      (filesSelectedNotify)="sendMedia($event)"
    ></app-upload-files>
    <app-record-voice-note
      class="align-end clickable-cursor"
      [selectedChat]="selectedChat"
      [isBotScreen]="true"
      (recordVoice)="startRecordEvent($event)"
      (sendVoiceNotify)="sendVoiceNote($event)"
      (micNotFoundNotify)="micNotFoundEvent()"
    ></app-record-voice-note>
  </div>
  <button class="send-btn" (click)="sendMessage()">Send</button>
</div>
<div class="chat-messages" *ngIf="!isScreenBot && isScreenMessages">
  <div class="chat-messages-box">
    <div
      #dropdownEmoji
      class="btn-group align-end clickable-cursor"
      dropdown
      [autoClose]="true"
      [dropup]="true"
      [isOpen]="isEmojiKeyboardOpen"
      (isOpenChange)="toggleEmojiKeyboard($event)"
    >
      <div dropdownToggle type="button">
        <i
          class="smile-emoji far fa-smile"
          [class.hide]="isRecord"
          [class.open]="isEmojiKeyboardOpen"
          aria-hidden="true"
        ></i>
      </div>
      <ul
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu emoji-box"
        role="menu"
      >
        <app-emoji-keyboard
          (emoijNotify)="emoijNotify($event)"
        ></app-emoji-keyboard>
      </ul>
    </div>
    <div
      class="edit wrap-text copyable-item text-cursor"
      [class.hide]="isRecord"
      data-text="Type a message..."
      contenteditable
      #textBox
    ></div>
    <app-upload-files
      class="align-end clickable-cursor"
      [class.hide]="isRecord"
      [isBotScreen]="true"
      (filesSelectedNotify)="sendMedia($event)"
    ></app-upload-files>
    <app-record-voice-note
      class="align-end clickable-cursor"
      [selectedChat]="selectedChat"
      [isBotScreen]="true"
      (recordVoice)="startRecordEvent($event)"
      (sendVoiceNotify)="sendVoiceNote($event)"
      (micNotFoundNotify)="micNotFoundEvent()"
    ></app-record-voice-note>
  </div>
  <button class="send-btn" (click)="sendMessage()">
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-extra-icons.svg#icon-send"></use>
    </svg>
  </button>
</div>

<app-modal-container
  *ngIf="selectedFile"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="closeImageCrroper()"
>
  <app-abstract-crop
    [imageChangedEvent]="selectedFile"
    [selectedFile]="selectedFile?.localFile"
    [onlyScaleDown]="true"
    [maintainAspectRatio]="true"
    [resizeToHeight]="resizeToHeight"
    [resizeToWidth]="resizeToWidth"
    [imageQuality]="50"
    [roundCropper]="false"
    [mimeType]="selectedFile.localFile.type"
    [aspectRatio]="selectedFile.width / selectedFile.height"
    [skipCropper]="selectedFile?.localFile?.size <= 250000"
    (filesSelectedNotifay)="imageSelectedEvent($event)"
    (closeNotifay)="closeImageCrroper()"
  ></app-abstract-crop>
</app-modal-container>
