import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SubItemDispatchers } from './subItem.dispatchers';
import * as ItemActions from './subItem.actions';
import { SubItemActionTypes } from './subItem.actions';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { initNewItem } from './subItem.middleware';
import { LocalItem } from 'src/models/ChannelAppLocalConfig';
import { UIDispatchers } from '../ui/ui.dispatchers';
import {
  REACHED_MAX_ITEM_LIMIT,
  REACHED_MIN_ITEM_LIMIT,
  TabTypes
} from 'src/models/constants';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import * as isEqual from 'lodash.isequal';

@Injectable()
export class SubItemEffects {
  @Effect({ dispatch: false })
  addItemRequest = this.actions$.pipe(
    ofType(SubItemActionTypes.ADD_SUB_ITEM_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.subItemReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, items]) => {
      const action: ItemActions.AddSubItemRequest = <
        ItemActions.AddSubItemRequest
      >val;
      let itemsOfComponent: LocalItem[] = [];
      itemsOfComponent = items.filter(
        item => item.parentComponentRef === action.component.ref
      );
      if (
        action.component.max_items &&
        itemsOfComponent.length >= action.component.max_items
      ) {
        this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
      } else {
        this._subItemDispatchers.addSubItem(
          initNewItem(action.component, action.specialItemValues)
        );
      }
    })
  );

  @Effect({ dispatch: false })
  deleteItemRequest = this.actions$.pipe(
    ofType(SubItemActionTypes.DELETE_SUB_ITEM_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.subItemReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.componentReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, items, components]) => {
      const action: ItemActions.DeleteSubItemRequest = <
        ItemActions.DeleteSubItemRequest
      >val;
      let itemsOfComponent: LocalItem[] = [];
      itemsOfComponent = items.filter(
        item => item.parentComponentRef === action.item.parentComponentRef
      );
      const parentComponent = components.find(
        comp => comp.ref === action.item.parentComponentRef
      );
      if (
        parentComponent &&
        parentComponent.min_items &&
        itemsOfComponent.length <= parentComponent.min_items
      ) {
        this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
      } else {
        this._subItemDispatchers.deleteSubItem(action.item);
      }
    })
  );

  @Effect({ dispatch: false })
  updateItemLink = this.actions$.pipe(
    ofType(SubItemActionTypes.UPDATE_SUB_ITEM_LINK),
    map((action: ItemActions.UpdateSubItemLink) => {
      if (action.itemLink === TabTypes.PAGE && !action.pageId) {
        const pageRef = Date.now() + '';
        this._subItemDispatchers.updateSubItem(action.itemRef, {
          page_ref: pageRef,
          page_id: null,
          template_id: null
        });
        this._myPageDispatchers.createMyPage(pageRef);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _subItemDispatchers: SubItemDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _store: Store<AppState>
  ) {}
}
