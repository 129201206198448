import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { StoreUiDispatchers } from './storeUI.dispatchers';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { PageDirection } from 'src/models/constants';
import { CreateStoreMethod, SetStoreMethod, RemoveStoreItemMethod, AddStoreItemMethod, GetStoresMethod, AssignStoreChannelMethod, RemoveStoreMethod, GetStoreMethod } from 'src/models/Stores';
import * as isEqual from 'lodash.isequal';
export class StoreUiEffects {
    constructor(actions$, _socketGateway, _uiStoreDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiStoreDispatchers = _uiStoreDispatchers;
        this._store = _store;
        this.createStore = this.actions$.pipe(ofType("CREATE_STORE" /* CREATE_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateStoreMethod(action.storesDetails));
        }));
        this.getStore = this.actions$.pipe(ofType("GET_STORE" /* GET_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetStoreMethod(action.storeID));
        }));
        this.updateStore = this.actions$.pipe(ofType("SET_STORE" /* SET_STORE */), map((action) => {
            const storeDetails = Object.assign({}, action.storeDetails, { id: action.storeID });
            this._socketGateway.sendSocketMessage(new SetStoreMethod(storeDetails));
        }));
        this.addStoreItem = this.actions$.pipe(ofType("ADD_STORE_ITEM" /* ADD_STORE_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddStoreItemMethod(action.storeID, action.items));
        }));
        this.assignStoreChannel = this.actions$.pipe(ofType("ASSIGN_STORE_CHANNEL" /* ASSIGN_STORE_CHANNEL */), map((action) => {
            this._socketGateway.sendSocketMessage(new AssignStoreChannelMethod(action.storeID, action.chatIds));
        }));
        this.removeStoreItem = this.actions$.pipe(ofType("REMOVE_ITEM" /* REMOVE_ITEM */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveStoreItemMethod(action.storeID, action.itemIDs));
        }));
        //
        this.getNextStorePage = this.actions$.pipe(ofType("UI_STORE_GET_NEXT_PAGE" /* UI_STORE_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.storeUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, storeUiState]) => {
            const action = val;
            const prevRequest = storeUiState.previousRequest;
            if (!(storeUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                storeUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetStoresMethod(0, storeUiState.eop, storeUiState.selected_chat));
            }
            else {
                this._uiStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevStorePage = this.actions$.pipe(ofType("UI_STORE_GET_PREV_PAGE" /* UI_STORE_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.storeUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, storeUiState]) => {
            const action = val;
            const prevRequest = storeUiState.previousRequest;
            if (!(storeUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                storeUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetStoresMethod(1, storeUiState.sop, storeUiState.selected_chat));
            }
            else {
                this._uiStoreDispatchers.setStatusAsIdle();
            }
        }));
        this.afterPackagesResetPaging = this.actions$.pipe(ofType("UI_STORE_RESET_PAGING" /* UI_STORE_RESET_PAGING */), map((action) => {
            this._uiStoreDispatchers.getNextStorePage();
        }));
        this.deleteStore = this.actions$.pipe(ofType("DELETE_STORE" /* DELETE_STORE */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveStoreMethod(action.storeID));
        }));
        this.setStoreSelectedChat = this.actions$.pipe(ofType("UI_STORE_SET_SELECTED_CHAT" /* UI_STORE_SET_SELECTED_CHAT */), map((action) => {
            this._uiStoreDispatchers.resetStorePaging();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "createStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "getStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "updateStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "addStoreItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "assignStoreChannel", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "removeStoreItem", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "getNextStorePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "getPrevStorePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "afterPackagesResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "deleteStore", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], StoreUiEffects.prototype, "setStoreSelectedChat", void 0);
