import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ProductsUiDispatchers } from 'src/app/store/productsUI/productsUI.dispatchers';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';

import { ProductScreens } from 'src/models/constants';
import { Product } from 'src/models/Product';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private _selectedProductId = null;

  private _currentProductScreen = new BehaviorSubject<string>(
    ProductScreens.PRODUCT_LIST
  );
  public currentProductScreen$ = this._currentProductScreen.asObservable();

  confirmProduct = false;
  selectedProduct: any;

  constructor(
    private _rootStore: RootStoreSelectors,
    private _productDispatcher: ProductsUiDispatchers,
    private _storeMenuUiDispatchers: StoreMenuUiDispatchers
  ) {}

  get products$() {
    return this._rootStore.getProducts$();
  }

  get bookings$() {
    return this._rootStore.getBookingList$();
  }

  get getBookingListWithProduct$() {
    return this._rootStore.getBookingListWithProduct$();
  }

  resetPaging(product_group_id?: number, ownerGroupId?: string) {
    this._productDispatcher.resetProductsPaging(product_group_id, ownerGroupId);
  }

  updateProduct(prod: Product) {
    if (prod && prod.id) {
      this._productDispatcher.setProduct(prod.id, prod);
    } else {
      this._productDispatcher.createProduct(prod);
    }
    this.closeItemCardEvent();
  }

  addInventory(prodId: string, units: number) {
    this._productDispatcher.addInventory(prodId, units);
  }

  createItemEvent() {
    this.setCurrentScreen(ProductScreens.PRODUCT_CREATION);
    this.setSelectedProduct(null);
  }
  readItemEvent(product: Product) {
    this.setCurrentScreen(ProductScreens.PRODUCT_VIEW);
    this.setSelectedProduct(product);
  }
  updateItemEvent(product: Product) {
    this.setCurrentScreen(ProductScreens.PRODUCT_EDIT);
    this.setSelectedProduct(product);
  }
  closeItemCardEvent() {
    this.setCurrentScreen(ProductScreens.PRODUCT_LIST);
    this.setSelectedProduct(null);
  }
  deleteProduct(productId: string) {
    this._productDispatcher.deleteProduct(productId);
  }
  toggleConfirmDeleteProduct(e: boolean, product?: any) {
    this.confirmProduct = e;
    this.selectedProduct = product;
  }
  setSelectedProduct(product: Product) {
    if (product) {
      this._selectedProductId = product.id;
      if (product.store_menu_id) {
        this._storeMenuUiDispatchers.getStoreMenu(product.store_menu_id);
      }
    } else {
      this._selectedProductId = null;
    }
  }

  get selectedProduct$() {
    return this._rootStore.getProductById$(this._selectedProductId);
  }

  getProductById(prdId: string) {
    this._productDispatcher.getProduct(prdId, null, null);
  }

  productById$(id: string) {
    return id ? this._rootStore.getProductById$(id.toString()) : null;
  }

  setCurrentScreen(screen: string) {
    this._currentProductScreen.next(screen);
  }

  getNextPage() {
    this._productDispatcher.getNextProductsPage();
  }

  getPreviousPage() {
    this._productDispatcher.getPrevProductsPage();
  }

  getChannelDetails$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
}
