import { Store } from '@ngrx/store';
import * as StoreMenuUIActionTypes from './storeMenuUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class StoreMenuUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createStoreMenu(storeMenusDetails) {
        this._store.dispatch(new StoreMenuUIActionTypes.CreateStoreMenu(storeMenusDetails));
    }
    getStoreMenu(id) {
        this._store.dispatch(new StoreMenuUIActionTypes.GetStoreMenu(id));
    }
    setStoreMenu(storeMenu, storeMenusDetails) {
        this._store.dispatch(new StoreMenuUIActionTypes.SetStoreMenu(storeMenu, storeMenusDetails));
    }
    addStoreMenuItem(storeMenu, items) {
        this._store.dispatch(new StoreMenuUIActionTypes.AddStoreMenuItem(storeMenu, items));
    }
    RemoveStoreMenuItem(storeMenu, itemIDs) {
        this._store.dispatch(new StoreMenuUIActionTypes.RemoveStoreMenuItem(storeMenu, itemIDs));
    }
    // paging dispatchers
    getNextStoreMenuPage() {
        this._store.dispatch(new StoreMenuUIActionTypes.GetNextStoreMenuPage());
    }
    getPrevStoreMenuPage() {
        this._store.dispatch(new StoreMenuUIActionTypes.GetPrevStoreMenuPage());
    }
    receivedStoreMenuHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new StoreMenuUIActionTypes.ReceivedStoreMenuHistoryChunk(eop, sop, currentPage));
    }
    storeMenuReceived(sMenu) {
        this._store.dispatch(new StoreMenuUIActionTypes.StoreMenuReceived(sMenu));
    }
    resetStoreMenuPaging() {
        this._store.dispatch(new StoreMenuUIActionTypes.ResetStoreMenuPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new StoreMenuUIActionTypes.SetStatusAsIdle());
    }
    deleteStoreMenu(id) {
        this._store.dispatch(new StoreMenuUIActionTypes.DeleteStoreMenu(id));
    }
    storeMenuDeleted(id) {
        this._store.dispatch(new StoreMenuUIActionTypes.StoreMenuDeleted(id));
    }
    refreshStoreMenu() {
        this._store.dispatch(new StoreMenuUIActionTypes.RefreshStoreMenu());
    }
}
StoreMenuUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreMenuUiDispatchers_Factory() { return new StoreMenuUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: StoreMenuUiDispatchers, providedIn: "root" });
