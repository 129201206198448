import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { BlackListUiDispatchers } from './blackListUI.dispatchers';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { PageDirection } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
import { GetBlackList, AddUserToBlacklist, RemoveUserFromBlacklist } from 'src/models/BlackListsUI';
export class BlackListUiEffects {
    constructor(actions$, _socketGateway, _uiBlackListDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiBlackListDispatchers = _uiBlackListDispatchers;
        this._store = _store;
        this.afterBlackListResetPaging = this.actions$.pipe(ofType("UI_BLACKLIST_RESET_PAGING" /* UI_BLACKLIST_RESET_PAGING */), map((action) => {
            this._uiBlackListDispatchers.getNextBlacklistsPage();
        }));
        this.getNextBlackListPage = this.actions$.pipe(ofType("UI_BLACKLIST_GET_NEXT_PAGE" /* UI_BLACKLIST_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.blackListUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, blackListUiReducer]) => {
            const action = val;
            const prevRequest = blackListUiReducer.previousRequest;
            if (!(blackListUiReducer.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                blackListUiReducer.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBlackList(null, 0, blackListUiReducer.hash, blackListUiReducer.eop, blackListUiReducer.msisdn));
            }
            else {
                this._uiBlackListDispatchers.setStatusAsIdle();
            }
        }));
        this.addUserToBlacklist = this.actions$.pipe(ofType("UI_ADD_USER_TO_BLACKLIST" /* UI_ADD_USER_TO_BLACKLIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddUserToBlacklist(action.users, action.mainChatID, action.reference, action.recordIndex));
        }));
        this.getPrevBlackListPage = this.actions$.pipe(ofType("UI_BLACKLIST_GET_PREV_PAGE" /* UI_BLACKLIST_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.blackListUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, blackListUiReducer]) => {
            const action = val;
            const prevRequest = blackListUiReducer.previousRequest;
            if (!(blackListUiReducer.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                blackListUiReducer.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetBlackList(null, 1, blackListUiReducer.hash, blackListUiReducer.sop, blackListUiReducer.msisdn));
            }
            else {
                this._uiBlackListDispatchers.setStatusAsIdle();
            }
        }));
        this.deleteUserFromBlacklist = this.actions$.pipe(ofType("UI_REMOVE_USER_FROM_BLACKLIST" /* UI_REMOVE_USER_FROM_BLACKLIST */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveUserFromBlacklist(action.users, action.mainChatID));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListUiEffects.prototype, "afterBlackListResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListUiEffects.prototype, "getNextBlackListPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListUiEffects.prototype, "addUserToBlacklist", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListUiEffects.prototype, "getPrevBlackListPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BlackListUiEffects.prototype, "deleteUserFromBlacklist", void 0);
