/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./empty-section.component";
import * as i5 from "../../../core/ui.service";
var styles_EmptySectionComponent = [i0.styles];
var RenderType_EmptySectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptySectionComponent, data: {} });
export { RenderType_EmptySectionComponent as RenderType_EmptySectionComponent };
function View_EmptySectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.section == null) ? null : _co.section.emptyDesc))); _ck(_v, 1, 0, currVal_0); }); }
function View_EmptySectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "section-desc"]], [[2, "section", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.pool; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.section == null) ? null : _co.section.emptyTitle))); _ck(_v, 1, 0, currVal_1); }); }
export function View_EmptySectionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "container"]], [[2, "active", null], [2, "main", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "section-title"]], [[2, "section", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Empty!"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptySectionComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmptySectionComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.pool; _ck(_v, 7, 0, currVal_4); var currVal_5 = !_co.pool; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pool; var currVal_1 = _co.isMainScreen; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", ((_co.section == null) ? null : _co.section.symbolImage), ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.pool; _ck(_v, 4, 0, currVal_3); }); }
export function View_EmptySectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-section", [], null, null, null, View_EmptySectionComponent_0, RenderType_EmptySectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.EmptySectionComponent, [i5.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptySectionComponentNgFactory = i1.ɵccf("app-empty-section", i4.EmptySectionComponent, View_EmptySectionComponent_Host_0, { section: "section", pool: "pool", notPool: "notPool", isMainScreen: "isMainScreen", previllage: "previllage" }, { newCreateNotify: "newCreateNotify" }, ["[create-new-btn]"]);
export { EmptySectionComponentNgFactory as EmptySectionComponentNgFactory };
