import { AbstractTableColumn } from './AbstractItem';
import {
  GET_MAP_TRIP_USER_METHOD,
  GET_MAP_TRIP_USERS_METHOD,
  REMOVE_MAP_TRIP_USER_METHOD,
  CREATE_MAP_TRIP_USER_METHOD,
  CREATE_MAP_TRIP_USER_REF,
  SET_MAP_TRIP_USER_METHOD,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE
} from './constants';

export interface MapTripUser {
  id?: string; // The key
  trip_id?: string; // The trip id of the trip the user attached to// MapTrip table
  account_id?: string; // The user id of the user ex: could be parent's id in case of school bus
  created_date?: string; // just for audit
  name?: string;
  checkin_id?: string; // Map Marker ID
  checkout_id?: string; // Map Marker ID
  diff?: number; // difference in mins from first trip time // in order to calculate the time of pickup of this user
  // The user will choose one of the times which we got from the diff array and so by index I can get the corresponding values
}

export const mapTripUsersColumns: AbstractTableColumn[] = [
  {
    colType: 'avatar',
    title: '',
    class: 'avatar'
  },
  {
    colType: 'name',
    title: 'Name',
    class: 'md-title-tag'
  },
  {
    colType: 'checkIn',
    title: 'Check In',
    class: 'lg'
  },
  {
    colType: 'checkOut',
    title: 'Check Out',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'action'
  }
];

export interface MapTripUserUI {
  currentPage?: MapTripUser[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  tripID?: string;
}

export class GetMapTripUser {
  readonly method = GET_MAP_TRIP_USER_METHOD;
  constructor(public id: string) {}
}

export class GetMapTripUsers {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_TRIP_USERS_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public hash?: string,
    public trip_id?: string
  ) {}
}

export class RemoveMapTripUser {
  readonly method = REMOVE_MAP_TRIP_USER_METHOD;
  constructor(public id: string, public trip_id: string) {}
}

export class CreateMapTripUser {
  readonly method = CREATE_MAP_TRIP_USER_METHOD;
  readonly reference = CREATE_MAP_TRIP_USER_REF;
  constructor(mapTripUser: MapTripUser) {
    for (const key in mapTripUser) {
      if (mapTripUser.hasOwnProperty(key)) {
        this[key] = mapTripUser[key];
      }
    }
  }
}

export class SetMapTripUser {
  readonly method = SET_MAP_TRIP_USER_METHOD;
  constructor(mapTripUser: MapTripUser) {
    for (const key in mapTripUser) {
      if (mapTripUser.hasOwnProperty(key)) {
        this[key] = mapTripUser[key];
      }
    }
  }
}
