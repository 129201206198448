import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppPublishingActionTypes } from './appPublishing.actions';
import * as AppPublishingActions from './appPublishing.actions';
import { map } from 'rxjs/operators';
import {
  SetAppStoreMethod,
  GetAppStoreMethod,
  SetAppleStoreMethod,
  SetAppInfoMethod,
  GetAppsSetupMethod
} from 'src/models/AppPublishInfo';
import { GetMyMembers } from 'src/models/IChatMember';
import { UserStatus } from 'src/models/constants';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { GetAppsMethod } from 'src/models/MultipleApps';

@Injectable()
export class AppPublishingEffects {
  @Effect({ dispatch: false })
  setAppStoreInfo = this.actions$.pipe(
    ofType(AppPublishingActionTypes.SET_APP_STORE_INFO),
    map((action: AppPublishingActions.SetAppStoreInfo) => {
      this._socketGateway.sendSocketMessage(
        new SetAppStoreMethod(action.appPublishInfo)
      );
      this._socketGateway.sendSocketMessage(new GetAppsMethod());
    })
  );

  @Effect({ dispatch: false })
  setAppleStoreInfo = this.actions$.pipe(
    ofType(AppPublishingActionTypes.SET_APPLE_STORE_INFO),
    map((action: AppPublishingActions.SetAppleStoreInfo) => {
      this._socketGateway.sendSocketMessage(
        new SetAppleStoreMethod(action.os, action.appStoreInfo)
      );
    })
  );

  @Effect({ dispatch: false })
  setAppInfo = this.actions$.pipe(
    ofType(AppPublishingActionTypes.SET_APP_INFO),
    map((action: AppPublishingActions.SetAppInfo) => {
      this._socketGateway.sendSocketMessage(
        new SetAppInfoMethod(action.os, action.appInfo)
      );
    })
  );

  @Effect({ dispatch: false })
  requestTesters = this.actions$.pipe(
    ofType(AppPublishingActionTypes.REQUEST_APP_TESTERS),
    map((action: AppPublishingActions.RequestAppTesters) => {
      this._socketGateway.sendSocketMessage(
        new GetMyMembers(UserStatus.ACTIVE, 1)
      );
    })
  );

  @Effect({ dispatch: false })
  receiveError = this.actions$.pipe(
    ofType(AppPublishingActionTypes.RECEIVE_PUBLISH_INFO_ERROR),
    map((action: AppPublishingActions.ReceivePublishInfoError) => {
      this._uiDispatchers.showPopup(SERVER_ERROR_MESSAGES[action.errorNumber]);
    })
  );

  @Effect({ dispatch: false })
  receiveErrorCode = this.actions$.pipe(
    ofType(AppPublishingActionTypes.RECEIVE_ERROR_CODE),
    map((action: AppPublishingActions.ReceiveErrorCode) => {
      this._uiDispatchers.showErrorPopup(
        SERVER_ERROR_MESSAGES[action.errorNumber]
      );
    })
  );

  @Effect({ dispatch: false })
  requestAppStore = this.actions$.pipe(
    ofType(AppPublishingActionTypes.REQUEST_APP_STORE_INFO),
    map((action: AppPublishingActions.RequestAppStoreInfo) => {
      this._socketGateway.sendSocketMessage(
        new GetAppStoreMethod(action.appID)
      );
    })
  );

  @Effect({ dispatch: false })
  receiveAppSetupInfo = this.actions$.pipe(
    ofType(AppPublishingActionTypes.GET_APPS_SETUP_INFO),
    map((action: AppPublishingActions.GetAppsSetupInfo) => {
      this._socketGateway.sendSocketMessage(new GetAppsSetupMethod());
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers
  ) {}
}
