import { Action } from '@ngrx/store';

import { IChat } from 'src/models/IChat';
import { IChatMember } from 'src/models/IChatMember';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IRole } from 'src/models/IRole';
import { IMessage } from 'src/models/IMessage';

export const enum ChatActionTypes {
  CHAT_DETAILS_RECEIVED = 'CHAT_DETAILS_RECEIVED',
  THUMBNAIL_DOWNLOAD = 'THUMBNAIL_DOWNLOAD',
  CHAT_LIST_RECEIVED = 'CHAT_LIST_RECEIVED',
  SUB_CHAT_LIST_RECEIVED = 'SUB_CHAT_LIST_RECEIVED',
  CHAT_IMAGE_DOWNLOADED = 'CHAT_IMAGE_DOWNLOADED',
  CHAT_SELECTED = 'CHAT_SELECTED',
  CHAT_INVALIDATED = 'CHAT_INVALIDATED',
  GET_CHAT_COUNTERS = 'GET_CHAT_COUNTERS',
  DESELECT_CHAT = 'DESELECT_CHAT',
  NEW_CHAT_RECEIVED = 'NEW_CHAT_RECEIVED',
  REQUEST_CHAT_HISTORY = 'REQUEST_CHAT_HISTORY',
  CHAT_DETAILS_FROM_CACHE = 'CHAT_DETAILS_FROM_CACHE',
  CHAT_MEMBER_COUNT_RECEIVED = 'CHAT_MEMBER_COUNT_RECEIVED',
  LOGGED_IN_LEFT_CHAT = 'LOGGED_IN_LEFT_CHAT',
  TAGS_RECEIVED = 'TAGS_RECEIVED',
  ROLES_DELIVERED = 'ROLES_DELIVERED',
  ROLES_RECEIVED = 'ROLES_RECEIVED',
  UPLOAD_CHAT_PHOTO = 'UPLOAD_CHAT_PHOTO',
  CREATE_EVENT = 'CREATE_EVENT',
  CREATE_GROUP = 'CREATE_GROUP',
  CREATE_BOOKING = 'CREATE_BOOKING',
  CREATE_CHANNEL = 'CREATE_CHANNEL',
  CREATE_VIRTUAL_APP = 'CREATE_VIRTUAL_APP',
  DELETE_CHAT = 'DELETE_CHAT',
  CHAT_DELETED = 'CHAT_DELETED',
  CHAT_DELETED_ERROR = 'CHAT_DELETED_ERROR',
  UPDATE_CHAT = 'UPDATE_CHAT',
  CHAT_ADMINS_RECEIVED = 'CHAT_ADMINS_RECEIVED',
  CONFIG_QR_CODE_RECEIVED = 'CONFIG_QR_CODE_RECEIVED',
  SENDING_WELCOME_MESSAGE = 'SENDING_WELCOME_MESSAGE',
  ENABLE_SERVICE = 'ENABLE_SERVICE',
  BUY_SUBSCRIPTION_ON_BEHALF_OF_USER = 'BUY_SUBSCRIPTION_ON_BEHALF_OF_USER',
  GET_BOOKING_PERIODS = 'GET_BOOKING_PERIODS',
  REQUEST_CHAT_DETAILS = 'REQUEST_CHAT_DETAILS',
  UPDATE_STORE = 'UPDATE_STORE',
  GET_CHAT_ADMINISTRATORS = 'GET_CHAT_ADMINISTRATORS',
  GET_GROUP_USER_ROLE = 'GET_GROUP_USER_ROLE',
  GET_GENERATED_CHAT_ID = 'GET_GENERATED_CHAT_ID',
  UI_SET_WAITTING_SPINER = 'UI_SET_WAITTING_SPINER',
  RECEIVE_GENERATED_CHAT_ID = 'RECEIVE_GENERATED_CHAT_ID'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class ChatDetailsReceived implements Action {
  readonly type = ChatActionTypes.CHAT_DETAILS_RECEIVED;
  constructor(public payload: IChat) {}
}

export class ChatDetailsFromCache implements Action {
  readonly type = ChatActionTypes.CHAT_DETAILS_FROM_CACHE;
  constructor(public payload: IChat) {}
}

export class ThumbnailDownloaded implements Action {
  readonly type = ChatActionTypes.THUMBNAIL_DOWNLOAD;
  constructor(public payload: IChat) {}
}

export class ChatImageDownloaded implements Action {
  readonly type = ChatActionTypes.CHAT_IMAGE_DOWNLOADED;
  constructor(public payload: IChat) {}
}

export class ChatListReceived implements Action {
  readonly type = ChatActionTypes.CHAT_LIST_RECEIVED;
  constructor(public payload: IChat[]) {}
}

export class SubChatListReceived implements Action {
  readonly type = ChatActionTypes.SUB_CHAT_LIST_RECEIVED;
  constructor(public payload: IChat[], public listType?: string) {}
}

export class ChatSelected implements Action {
  readonly type = ChatActionTypes.CHAT_SELECTED;
  constructor(public payload: string) {}
}

export class ChatInvalidated implements Action {
  readonly type = ChatActionTypes.CHAT_INVALIDATED;
  constructor(public payload: string) {}
}

export class ReceiveChatID implements Action {
  readonly type = ChatActionTypes.RECEIVE_GENERATED_CHAT_ID;
  constructor(public id: string) {}
}

export class RequestChatCounters implements Action {
  readonly type = ChatActionTypes.GET_CHAT_COUNTERS;
  constructor(public chatId: string, public message_ids: string[]) {}
}

export class DeselectChat implements Action {
  readonly type = ChatActionTypes.DESELECT_CHAT;
  constructor(public id: string, public lastTypedMessage: string) {}
}

export class NewChatReceived implements Action {
  readonly type = ChatActionTypes.NEW_CHAT_RECEIVED;
  constructor(public payload: IChat) {}
}

export class RequestChatHistory implements Action {
  readonly type = ChatActionTypes.REQUEST_CHAT_HISTORY;
  constructor(public payload: string) {}
}

export class ChatMemberCountReceived implements Action {
  readonly type = ChatActionTypes.CHAT_MEMBER_COUNT_RECEIVED;
  constructor(public payload: IChat) {}
}

export class LoggedInLeftChat implements Action {
  readonly type = ChatActionTypes.LOGGED_IN_LEFT_CHAT;
  constructor(public payload: IChatMember) {}
}

export class TagsReceived implements Action {
  readonly type = ChatActionTypes.TAGS_RECEIVED;
  constructor(public payload: IChat, public id: string) {}
}

export class RolesDelivered implements Action {
  readonly type = ChatActionTypes.ROLES_DELIVERED;
  constructor(public payload: IRole[]) {}
}

export class RolesReceived implements Action {
  readonly type = ChatActionTypes.ROLES_RECEIVED;
  constructor(public payload: IChat) {}
}

export class ChatAdminsReceived implements Action {
  readonly type = ChatActionTypes.CHAT_ADMINS_RECEIVED;
  constructor(public chatID: string, public admins: string[]) {}
}

export class CreateEvent implements Action {
  readonly type = ChatActionTypes.CREATE_EVENT;
  constructor(public eventDetails: IChat, public secandEvent?) {}
}

export class CreateBooking implements Action {
  readonly type = ChatActionTypes.CREATE_BOOKING;
  constructor(public bookingDetails: IChat, public subChat?: any) {}
}

export class CreateGroup implements Action {
  readonly type = ChatActionTypes.CREATE_GROUP;
  constructor(public groupDetails: IChat, public subChat?: any) {}
}

export class CreateChannel implements Action {
  readonly type = ChatActionTypes.CREATE_CHANNEL;
  constructor(public channelDetails: IChat, public subChat?: any) {}
}

export class CreateVirtualApp implements Action {
  readonly type = ChatActionTypes.CREATE_VIRTUAL_APP;
  constructor(public virtualAppDetails: IChat) {}
}
export class DeleteChat implements Action {
  readonly type = ChatActionTypes.DELETE_CHAT;
  constructor(public chat_id: string) {}
}

export class ChatDeleted implements Action {
  readonly type = ChatActionTypes.CHAT_DELETED;
  constructor(public chat_id: string) {}
}

export class ChatDeletedError implements Action {
  readonly type = ChatActionTypes.CHAT_DELETED_ERROR;
  constructor(public chat_id: string, public errorNumber: number) {}
}

export class UpdateChat implements Action {
  readonly type = ChatActionTypes.UPDATE_CHAT;
  constructor(public chatDetails: IChat, public reference?: string) {}
}

export class UpdateStore implements Action {
  readonly type = ChatActionTypes.UPDATE_STORE;
  constructor(public chatDetails: IChat, public reference?: string) {}
}

export class UploadChatPhoto implements Action {
  readonly type = ChatActionTypes.UPLOAD_CHAT_PHOTO;
  constructor(public chatID: string, public selectedFile: ISelectedFiles) {}
}

export class ConfigQrCodeReceived implements Action {
  readonly type = ChatActionTypes.CONFIG_QR_CODE_RECEIVED;
  constructor(public chatID: string, public configQrCode: string) {}
}

export class SendingWelcomeMessage implements Action {
  readonly type = ChatActionTypes.SENDING_WELCOME_MESSAGE;
  constructor(public payload: IMessage) {}
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}

export class EnableService implements Action {
  readonly type = ChatActionTypes.ENABLE_SERVICE;
  constructor(
    public chat_id: string,
    public period?: string,
    public period_number?: number,
    public price?: number,
    public currency?: string
  ) {}
}

export class BuySubscriptionOnBehalfOfUser implements Action {
  readonly type = ChatActionTypes.BUY_SUBSCRIPTION_ON_BEHALF_OF_USER;
  constructor(
    public chat_id: string,
    public member_id: string,
    public currency: string
  ) {}
}

export class RequestChatDetails implements Action {
  readonly type = ChatActionTypes.REQUEST_CHAT_DETAILS;
  constructor(public chatID: string) {}
}
export class GetBookingPeriods implements Action {
  readonly type = ChatActionTypes.GET_BOOKING_PERIODS;
  constructor(public chatID: string) {}
}

export class GetChatAdministrators implements Action {
  readonly type = ChatActionTypes.GET_CHAT_ADMINISTRATORS;
  constructor(public chatID: string) {}
}
export class GetUserGroupRole implements Action {
  readonly type = ChatActionTypes.GET_GROUP_USER_ROLE;
  constructor(public chatID: string, public userID: string) {}
}
export class SetWaittingSpiner implements Action {
  readonly type = ChatActionTypes.UI_SET_WAITTING_SPINER;
  constructor(public id: string, public status: boolean, public imageUrl?) {}
}
export class Reset implements Action {
  readonly type = RESET;
}

export type ChatActions =
  | ChatListReceived
  | ChatDetailsReceived
  | ThumbnailDownloaded
  | ChatImageDownloaded
  | ChatSelected
  | ChatInvalidated
  | RequestChatCounters
  | DeselectChat
  | NewChatReceived
  | RequestChatHistory
  | ChatDetailsFromCache
  | ChatMemberCountReceived
  | LoggedInLeftChat
  | TagsReceived
  | RolesDelivered
  | RolesReceived
  | CreateEvent
  | CreateBooking
  | CreateGroup
  | ChatDeleted
  | ChatDeletedError
  | CreateChannel
  | CreateVirtualApp
  | DeleteChat
  | UpdateChat
  | UploadChatPhoto
  | SubChatListReceived
  | ConfigQrCodeReceived
  | ChatAdminsReceived
  | SendingWelcomeMessage
  | DeselectChannel
  | EnableService
  | BuySubscriptionOnBehalfOfUser
  | RequestChatDetails
  | UpdateStore
  | GetUserGroupRole
  | SetWaittingSpiner
  | Reset;
