/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-temp-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./startover-temp-item.component";
import * as i3 from "../../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
var styles_StartoverTempItemComponent = [i0.styles];
var RenderType_StartoverTempItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverTempItemComponent, data: {} });
export { RenderType_StartoverTempItemComponent as RenderType_StartoverTempItemComponent };
export function View_StartoverTempItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "temp-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "cat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "temp-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.temp == null) ? null : _co.temp.android_image_url) || "./assets/img/default_temp.png"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.temp == null) ? null : _co.temp.title); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.getCategory(((_co.temp == null) ? null : _co.temp.category)); _ck(_v, 6, 0, currVal_2); }); }
export function View_StartoverTempItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-temp-item", [], null, null, null, View_StartoverTempItemComponent_0, RenderType_StartoverTempItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.StartoverTempItemComponent, [i3.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverTempItemComponentNgFactory = i1.ɵccf("app-startover-temp-item", i2.StartoverTempItemComponent, View_StartoverTempItemComponent_Host_0, { temp: "temp", index: "index", seeMore: "seeMore" }, { setTempNotify: "setTempNotify", setTempReview: "setTempReview" }, []);
export { StartoverTempItemComponentNgFactory as StartoverTempItemComponentNgFactory };
