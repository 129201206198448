import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as EventUIActions from './eventUI.actions';

import { IChat } from 'src/models/IChat';
import { AppState } from 'src/models/AppState';
// import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class EventUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  listEventUI(): void {
    this._store.dispatch(new EventUIActions.ListEventUI());
  }
  createEventUI(): void {
    this._store.dispatch(new EventUIActions.CreateEventUI());
  }
  viewEventUI(selectedEvent: IChat): void {
    this._store.dispatch(new EventUIActions.ViewEventUI(selectedEvent));
  }
  editEventUI(selectedEvent: IChat, file?): void {
    this._store.dispatch(new EventUIActions.EditEventUI(selectedEvent, file));
  }

  uploadEventImage(selectedFile): void {
    this._store.dispatch(new EventUIActions.UploadEventImage(selectedFile));
  }
  eventPhotoUploadUI(createdEvent: IChat): void {
    this._store.dispatch(new EventUIActions.EventPhotoUploadUI(createdEvent));
  }
  eventCreationComplete(): void {
    this._store.dispatch(new EventUIActions.EventCreationComplete());
  }

  closeViewEventUI(): void {
    this._store.dispatch(new EventUIActions.CloseViewEventUI());
  }
  closeEventEditUI(): void {
    this._store.dispatch(new EventUIActions.CloseEventEditUI());
  }

  //
  // paging dispatchers
  getNextEventPage(): void {
    this._store.dispatch(new EventUIActions.GetNextEventPage());
  }

  getPrevEventPage(): void {
    this._store.dispatch(new EventUIActions.GetPrevEventPage());
  }

  receivedEventHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChat[]
  ): void {
    this._store.dispatch(
      new EventUIActions.ReceivedEventHistoryChunk(eop, sop, currentPage)
    );
  }

  resetEventPaging(): void {
    this._store.dispatch(new EventUIActions.ResetEventPaging());
  }

  setChat(data) {
    this._store.dispatch(new EventUIActions.SetChat(data));
  }

  setWaittingSpiner(status) {
    this._store.dispatch(new EventUIActions.SetWaittingSpiner(status));
  }
}
