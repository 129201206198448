<div class="post-assign-bar">
  <div class="post-assign-bar-tabs">
    <div class="tabs" #list>
      <span>To:</span>
      <ng-container *ngIf="selectedSubChat as chat">
        <div
          class="tab"
          dropdown
          [autoClose]="false"
          #dd="bs-dropdown"
          appClickElsewhere
          (clickElsewhere)="dd.hide()"
          *ngIf="
            !chat?.subChannel || messageSendingType === messageType.WELCOME
          "
        >
          <div class="tab-details">
            <ng-container *ngIf="!chat?.subChannel">
              <div class="icon">
                <svg class="svg-icon icon-icon">
                  <use
                    xlink:href="./assets/svg/app-sections.svg#icon-dashboard"
                  ></use>
                </svg>
              </div>
              <div class="title">
                {{ 'MAINAPPDASHBOARD' | translate }}
              </div>
            </ng-container>
            <ng-container *ngIf="chat?.subChannel">
              <app-profile-image
                style="margin-right: 8px;"
                [id]="chat?.id"
                [chatType]="chat?.type"
                [emSize]="2.3"
              >
              </app-profile-image>
              <div class="title">
                {{ chat?.name }}
              </div>
            </ng-container>
          </div>
          <ng-container
            *ngIf="
              (_tagsService.getChatTagsByChatID$(chat?.id) | async)
                ?.tagsDefinition as tags
            "
          >
            <div dropdownToggle>
              <div class="link">
                {{ 'TAGS' | translate }} ({{
                  returnNumberOfSelecetedTags(chat?.id)
                }}
                Selected)
              </div>
            </div>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <div class="header">
                <span>{{ 'TAGS' | translate }}</span>
                <div
                  class="link"
                  (click)="setTagsEvent(tags, chat?.id)"
                  *ngIf="
                    tags?.length > 0 &&
                    tags?.length > returnNumberOfSelecetedTags(chat?.id)
                  "
                >
                  {{ 'SELECTALL' | translate }}
                </div>
                <div
                  class="link"
                  style="color: #ff3b30;"
                  (click)="setTagsEvent([], chat?.id)"
                  *ngIf="
                    tags?.length > 0 &&
                    tags?.length === returnNumberOfSelecetedTags(chat?.id)
                  "
                >
                  {{ 'UNSELECTALL' | translate }}
                </div>
              </div>
              <div class="selector">
                <li role="menuitem" *ngFor="let tag of tags">
                  <div class="text">{{ tag?.name }}</div>
                  <mat-checkbox
                    [checked]="isTagSelected(tag, chat?.id)"
                    (change)="selectTags($event.checked, tag, chat?.id)"
                  ></mat-checkbox>
                </li>
              </div>
            </ul>
          </ng-container>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          selectedSubgroups.length > 0 &&
          messageSendingType !== messageType.WELCOME
        "
      >
        <div
          class="tab"
          dropdown
          [autoClose]="false"
          #dd="bs-dropdown"
          appClickElsewhere
          (clickElsewhere)="dd.hide()"
          *ngFor="let chat of selectedSubgroups"
        >
          <div class="tab-details">
            <app-profile-image
              style="margin-right: 8px;"
              [id]="chat?.id"
              [chatType]="chat?.type"
              [emSize]="2.3"
            >
            </app-profile-image>
            <div class="title">
              {{ chat?.title }}
            </div>
          </div>
          <ng-container
            *ngIf="
              (_tagsService.getChatTagsByChatID$(chat?.id) | async)
                ?.tagsDefinition as tags
            "
          >
            <div dropdownToggle>
              <div class="link">
                {{ 'TAGS' | translate }} ({{
                  returnNumberOfSelecetedTags(chat?.id)
                }}
                Selected)
              </div>
            </div>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <div class="header">
                <span>{{ 'TAGS' | translate }}</span>
                <div
                  class="link"
                  (click)="setTagsEvent(tags, chat?.id)"
                  *ngIf="
                    tags?.length > 0 &&
                    tags?.length > returnNumberOfSelecetedTags(chat?.id)
                  "
                >
                  {{ 'SELECTALL' | translate }}
                </div>
                <div
                  class="link"
                  style="color: #ff3b30;"
                  (click)="setTagsEvent([])"
                  *ngIf="
                    tags?.length > 0 &&
                    tags?.length === returnNumberOfSelecetedTags(chat?.id)
                  "
                >
                  {{ 'UNSELECTALL' | translate }}
                </div>
              </div>
              <div class="selector">
                <li role="menuitem" *ngFor="let tag of tags">
                  <div class="text">{{ tag?.name }}</div>
                  <mat-checkbox
                    [checked]="isTagSelected(tag, chat?.id)"
                    (change)="selectTags($event.checked, tag, chat?.id)"
                  ></mat-checkbox>
                </li>
              </div>
            </ul>
          </ng-container>

          <svg
            class="svg-icon icon-cancel"
            (click)="removeSubgroupsEvent(chat?.id)"
            *ngIf="selectedSubgroups?.length > 1"
          >
            <use xlink:href="/assets/svg/app-extra-icons.svg#icon-close"></use>
          </svg>
        </div>
      </ng-container>

      <div
        class="label"
        *ngIf="
          !(uiService.selectedSubChat$ | async)?.subChannel &&
          chatLabels.length > 0
        "
      >
        <label for="tabs">{{ 'CHATLABEL' | translate }}:</label>
        <div class="btn-group" dropdown>
          <div dropdownToggle class="menu-selector">
            <div class="text">{{ localSelectedTab?.label }}</div>
            <div class="fas fa-chevron-down clickable-cursor"></div>
          </div>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li
              role="menuitem"
              *ngFor="let tab of chatLabels"
              (click)="setSelectedTab(tab)"
            >
              <div class="text">{{ tab?.label }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="add-channel"
      (click)="showSubgroupsModalEvent(true)"
      *ngIf="
        (uiService.selectedSubChat$ | async)?.subChannel &&
        messageSendingType !== messageType.WELCOME
      "
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="/assets/svg/app-extra-icons.svg#icon-plus-add"></use>
      </svg>
    </div>
    <div class="wellcome" *ngIf="messageSendingType === messageType.WELCOME">
      {{ 'SETUPWELCOMEMESSAGE' | translate }}
    </div>
  </div>
</div>

<!-- Subgroups Modal -->
<app-modal-container
  *ngIf="showSubgroupsModal"
  [count]="layoutService?.appModelOpenStatus$ | async"
  no-scroll
  (cancelNotfiy)="showSubgroupsModalEvent(false)"
>
  <app-sub-chats-selector
    [type]="type"
    [currentSelectChats]="selectedSubgroups"
    [isPostScreen]="true"
    (cancelNotfiy)="showSubgroupsModalEvent(false)"
    (selectChatsNotfiy)="setSubgroupsEvent($event)"
    (removedChatsNotfiy)="removeSubgroupsChatsEvent($event)"
  >
  </app-sub-chats-selector>
</app-modal-container>
