import { Action } from '@ngrx/store';
import { BlackList } from 'src/models/BlackListsUI';

export const enum BlacklistUIActionTypes {
  UI_BLACKLIST_RESET_PAGING = 'UI_BLACKLIST_RESET_PAGING',
  UI_BLACKLIST_GET_NEXT_PAGE = 'UI_BLACKLIST_GET_NEXT_PAGE',
  UI_BLACKLIST_GET_PREV_PAGE = 'UI_BLACKLIST_GET_PREV_PAGE',
  UI_BLACKLIST_SET_IDLE_STATUS = 'UI_BLACKLIST_SET_IDLE_STATUS',
  UI_ADD_USER_TO_BLACKLIST = 'UI_ADD_USER_TO_BLACKLIST',
  UI_ADD_USER_TO_BLACKLIST_SUCCESS = 'UI_ADD_USER_TO_BLACKLIST_SUCCESS',
  UI_REMOVE_USER_FROM_BLACKLIST = 'UI_REMOVE_USER_FROM_BLACKLIST',
  UI_REMOVE_USER_FROM_BLACKLIST_SUCCESS = 'UI_REMOVE_USER_FROM_BLACKLIST_SUCCESS',
  UI_BLACKLIST_RECEIVED_HISTORY_CHUNK = 'UI_BLACKLIST_RECEIVED_HISTORY_CHUNK',
  UI_SET_BLACKLIST_LOADING_SCREEN_STATUS = 'UI_SET_BLACKLIST_LOADING_SCREEN_STATUS',
  RESET_BLACKLIST = 'RESET_BLACKLIST'
}

export class ResetBlackListPaging implements Action {
  readonly type = BlacklistUIActionTypes.UI_BLACKLIST_RESET_PAGING;
  constructor(public name: string) {}
}

export class GetNextBlacklistsPage implements Action {
  readonly type = BlacklistUIActionTypes.UI_BLACKLIST_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevBlacklistsPage implements Action {
  readonly type = BlacklistUIActionTypes.UI_BLACKLIST_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedBlacklistsHistoryChunk implements Action {
  readonly type = BlacklistUIActionTypes.UI_BLACKLIST_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: [],
    public hash: string
  ) {}
}

export class AddUserToBlacklist implements Action {
  readonly type = BlacklistUIActionTypes.UI_ADD_USER_TO_BLACKLIST;
  constructor(
    public users: string[],
    public mainChatID: string,
    public reference?: string,
    public recordIndex?: number
  ) {}
}

export class AddBlacklistSuccess implements Action {
  readonly type = BlacklistUIActionTypes.UI_ADD_USER_TO_BLACKLIST_SUCCESS;
  constructor(public users: BlackList[]) {}
}

export class RemoveUserFromBlacklist implements Action {
  readonly type = BlacklistUIActionTypes.UI_REMOVE_USER_FROM_BLACKLIST;
  constructor(public users: string[], public mainChatID: string) {}
}

export class RemoveUserFromBlacklistSuccess implements Action {
  readonly type = BlacklistUIActionTypes.UI_REMOVE_USER_FROM_BLACKLIST_SUCCESS;
  constructor(public users: string[]) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = BlacklistUIActionTypes.UI_BLACKLIST_SET_IDLE_STATUS;
  constructor() {}
}
export class SetLoadingScreenStatus implements Action {
  readonly type = BlacklistUIActionTypes.UI_SET_BLACKLIST_LOADING_SCREEN_STATUS;
  constructor(public status: string) {}
}

export class Reset implements Action {
  readonly type = BlacklistUIActionTypes.RESET_BLACKLIST;
}

export type Actions =
  | ResetBlackListPaging
  | GetNextBlacklistsPage
  | GetPrevBlacklistsPage
  | AddUserToBlacklist
  | AddBlacklistSuccess
  | SetStatusAsIdle
  | RemoveUserFromBlacklist
  | RemoveUserFromBlacklistSuccess
  | ReceivedBlacklistsHistoryChunk
  | SetLoadingScreenStatus
  | Reset;
