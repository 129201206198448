import { Injectable } from '@angular/core';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractNotifiersService } from './abstract-notifiers/abstract-notifiers.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(abstractNotifiersService: AbstractNotifiersService) {}

  get online$(): Observable<boolean> {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );
  }
}
