import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { RootStoreSelectors } from '../store/root-store.selectors';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { AuthGateway } from 'src/app/network/gateway/auth.gateway';
import { HtttpMethodErrors, TOKEN_LOCAL_STORAGE_KEY, UISections } from 'src/models/constants';
import { SummaryUIDispatchers } from 'src/app/store/summaryUI/summaryUI.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ISAUTH } from 'src/models/constants';
import { UIDispatchers } from '../store/ui/ui.dispatchers';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../network/gateway/auth.gateway";
import * as i2 from "../store/root-store.selectors";
import * as i3 from "../store/auth/auth.dispatchers";
import * as i4 from "./storage.service";
import * as i5 from "../store/ui/ui.dispatchers";
import * as i6 from "@angular/router";
import * as i7 from "../store/summaryUI/summaryUI.dispatchers";
import * as i8 from "../store/chats/chat.dispatchers";
export class AuthService {
    constructor(_authGateway, _selectors, _authDispatchers, _storageService, _uiDispatchers, router, _summaryUIDispatchers, _chatDispatchers) {
        this._authGateway = _authGateway;
        this._selectors = _selectors;
        this._authDispatchers = _authDispatchers;
        this._storageService = _storageService;
        this._uiDispatchers = _uiDispatchers;
        this.router = router;
        this._summaryUIDispatchers = _summaryUIDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._waitForTempConfig = new BehaviorSubject(false);
        this.waitForTempConfig$ = this._waitForTempConfig.asObservable();
        this._errorNotify = new BehaviorSubject(false);
        this.errorNotify$ = this._errorNotify.asObservable();
        this._errorSignupNotify = new BehaviorSubject(false);
        this.errorSignupNotify$ = this._errorSignupNotify.asObservable();
        this.authCollection$.subscribe(res => (this.countryIso = res.countryIso));
    }
    reopenNewConnection() {
        this._authDispatchers.reopenNewConnection();
    }
    requestNewQRcode() {
        this._authDispatchers.requestNewQRCode();
    }
    requestQRcode() {
        this._authDispatchers.requestQRCode();
    }
    /** Auth dispacters actions on store  */
    get authCollection$() {
        return this._selectors.authCollection$;
    }
    get authRem() {
        return this._authDispatchers.authRem;
    }
    get token() {
        return this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
    }
    setRem(rem) {
        this._authDispatchers.setAuthRem(rem);
    }
    logout() {
        this._authDispatchers.logOut();
    }
    selectChat(chatId, isAuth) {
        this._authDispatchers.setChatSelect(chatId, ISAUTH.qrcode);
    }
    updateProgressWeb(no, mode) {
        this._authDispatchers.setAppProgress(no, mode);
    }
    updateWebMode(no) {
        this._authDispatchers.setAppMode(no);
    }
    getCountryISO() {
        return this._authDispatchers.getCountryData();
    }
    emailTacSuccess(form) {
        this._authDispatchers.emailTacSuccess(form.email, form.appName, form.name, form.password, form.countryIso);
    }
    reciveAuthToken(token) {
        const auth = {};
        auth.token = token;
        this._authDispatchers.reciveAuthToken(auth);
    }
    resetSocketStatus() {
        this._authDispatchers.returnToInIt();
    }
    closeSocket() {
        this._authDispatchers.closeSocket();
    }
    createAccountSuccess(password) {
        this._authDispatchers.createAccountSuccess(password);
    }
    selectTemplate(templateID) {
        this._authDispatchers.selectTemplate(templateID);
    }
    loginUseEmail() {
        this._authDispatchers.loginByEmail();
    }
    requestAdminQR() {
        this._authDispatchers.requestAdminQR();
    }
    // Auth Gateway //
    loginByEmail(form) {
        return this._authGateway.loginForm(form);
    }
    createAccount(form, tac) {
        return this._authGateway.tacForm(form, tac);
    }
    getEmailTac(form) {
        return this._authGateway.signUpForm(form);
    }
    signUpWhitGoogle(form) {
        return this._authGateway.signUpWhitGoogle(form);
    }
    loginWhitGoogle(form) {
        return this._authGateway.loginWhitGoogle(form);
    }
    getResetPasswordTac(email) {
        return this._authGateway.getResetPasswordTac(email);
    }
    resetPassword(email, tac, newPassword, confirmNewPassword) {
        return this._authGateway.resetPassword(email, tac, newPassword, confirmNewPassword);
    }
    getTemplatesByCategory(category) {
        return this._authGateway.getTemplatesByCategory(category);
    }
    getTemplateById(id) {
        return this._authGateway.getTemplatesByCategory(null, id);
    }
    getChatData(chat_id) {
        this._authDispatchers.getChatData(chat_id);
    }
    callLoginButton(registerType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const element = yield document.getElementById('loginRef');
            if (element && this.auth2) {
                this.auth2.attachClickHandler(element, {}, (googleAuthUser) => {
                    const profile = googleAuthUser.getBasicProfile();
                    if (registerType === 'login') {
                        const login = {
                            email: profile.getEmail(),
                            google: profile.getId()
                        };
                        this.loginWhitGoogle(login).subscribe(response => {
                            const res = response;
                            const error = document.getElementById('login-error');
                            if (res.result === 0) {
                                this.reciveAuthToken(res.token);
                            }
                            else if (res.error === HtttpMethodErrors.MAIL_NOT_SIGNUP_GOOGLE) {
                                error.innerText =
                                    'This account does not exist. Please sign up.';
                            }
                        });
                    }
                    else {
                        const login = {
                            email: profile.getEmail(),
                            name: profile.getName(),
                            countryIso: this.countryIso,
                            google: profile.getId()
                        };
                        const error = document.getElementById('sign-up');
                        this.signUpWhitGoogle(login).subscribe(response => {
                            const res = response;
                            if (res.result === 0) {
                                this._authDispatchers.emailTokenReceived(res.token);
                                this.router.navigate(['/app']);
                                this._uiDispatchers.setSection(UISections.APP);
                            }
                            else if (res.error === HtttpMethodErrors.MAIL_USED_GOOGLE) {
                                error.innerText =
                                    'This account already exists. Please sign in.';
                            }
                        });
                    }
                    /* Write Your Code Here */
                }, (error) => {
                    // alert(JSON.stringify(error, undefined, 2));
                });
            }
        });
    }
    googleAuthSDK() {
        window['init'] = () => {
            window['gapi'].load('auth2', () => {
                this.auth2 = window['gapi'].auth2.init({
                    client_id: '737915752999-fhbf1otlq3t498d9ghn5i0fb76frgunv.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email',
                    plugin_name: 'social login'
                });
                // this.callLoginButton();
            });
        };
        const googleMailScreen = document.createElement('script');
        googleMailScreen.src = 'https://apis.google.com/js/platform.js?onload=init';
        googleMailScreen.crossOrigin = 'anonymous';
        googleMailScreen.async = true;
        googleMailScreen.defer = true;
        document.body.appendChild(googleMailScreen);
    }
    getChatList(upgraded) {
        this._authDispatchers.getChatList(upgraded);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AuthGateway), i0.ɵɵinject(i2.RootStoreSelectors), i0.ɵɵinject(i3.AuthDispatchers), i0.ɵɵinject(i4.StorageService), i0.ɵɵinject(i5.UIDispatchers), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i7.SummaryUIDispatchers), i0.ɵɵinject(i8.ChatDispatchers)); }, token: AuthService, providedIn: "root" });
