import * as ChatActions from './chat.actions';
import { ChatMiddleware } from './chat.middleware';
import { CHANNEL, PROJECT_NAME, MERCHANT_PROJECT } from 'src/models/constants';
const descendingly = (first, second) => {
    const firstDate = first.createdDate ? first.createdDate : 0;
    const secondDate = second.createdDate ? second.createdDate : 0;
    if (firstDate < secondDate) {
        return 1;
    }
    else if (firstDate > secondDate) {
        return -1;
    }
    return 0;
};
const ɵ0 = descendingly;
const updateChat = (local, remote) => {
    let updatedChat = {};
    if (local.version !== remote.version) {
        updatedChat = Object.assign({}, local, remote);
    }
    updatedChat = Object.assign({}, local);
    // updatedChat.isUpgradedChannel = true;
    if (updatedChat.parent_id) {
        updatedChat.subChannel = true;
    }
    else {
        updatedChat.isChannelApp = true;
    }
    return updatedChat;
};
const ɵ1 = updateChat;
export function chatReducer(state = [], action) {
    switch (action.type) {
        case "CHAT_LIST_RECEIVED" /* CHAT_LIST_RECEIVED */:
            if (PROJECT_NAME === MERCHANT_PROJECT) {
                return action.payload.map(chat => {
                    const chatExist = state.find(cht => cht.id === chat.id);
                    if (chatExist) {
                        return updateChat(chatExist, chat);
                    }
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat);
                    // updatedChat.isUpgradedChannel = true;
                    if (chat.parent_id) {
                        updatedChat.subChannel = true;
                    }
                    else {
                        updatedChat.isChannelApp = true;
                    }
                    return updatedChat;
                });
            }
            else {
                return action.payload;
            }
        case "SUB_CHAT_LIST_RECEIVED" /* SUB_CHAT_LIST_RECEIVED */: {
            const rcvdList = action.payload;
            const newList = rcvdList.filter(chat => {
                if (!state.find(exitingChat => exitingChat.id === chat.id)) {
                    return chat;
                }
            });
            const updatedState = state.map(chat => {
                if (rcvdList.find(rcvdChat => rcvdChat.id === chat.id) &&
                    action.listType === CHANNEL) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat);
                    updatedChat.subChannel = true;
                    return updatedChat;
                }
                return chat;
            });
            if (newList) {
                return [...updatedState, ...newList];
            }
            else {
                return [...updatedState];
            }
        }
        case "CHAT_DETAILS_RECEIVED" /* CHAT_DETAILS_RECEIVED */:
            if (!state.find(chat => chat.id === action.payload.id)) {
                const newChat = action.payload;
                return [...state, newChat];
            }
            return state
                .map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, action.payload, { admin: chat.admin, subChannel: action.payload.type === CHANNEL &&
                            (chat.subChannel || action.payload.parent_id)
                            ? true
                            : false, isChannelApp: action.payload.type === CHANNEL &&
                            (chat.isChannelApp || !action.payload.parent_id)
                            ? true
                            : false, isUpgradedChannel: chat.isUpgradedChannel, welcomeMessageId: action.payload.welcome_message
                            ? action.payload.welcome_message.message.message_id
                            : null });
                    if (chat.backgroundColor) {
                        updatedChat.backgroundColor = chat.backgroundColor;
                    }
                    updatedChat.detailsReceived = true;
                    return updatedChat;
                }
                return chat;
            })
                .sort(descendingly);
        case "CHAT_DETAILS_FROM_CACHE" /* CHAT_DETAILS_FROM_CACHE */:
            return state
                .map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, action.payload, { admin: chat.admin, subChannel: action.payload.type === CHANNEL &&
                            (chat.subChannel || action.payload.parent_id)
                            ? true
                            : false, isChannelApp: action.payload.type === CHANNEL &&
                            (chat.isChannelApp || !action.payload.parent_id)
                            ? true
                            : false, isUpgradedChannel: chat.isUpgradedChannel, welcomeMessageId: action.payload.welcome_message
                            ? action.payload.welcome_message.message.message_id
                            : null });
                    if (chat.backgroundColor) {
                        updatedChat.backgroundColor = chat.backgroundColor;
                    }
                    updatedChat.detailsReceived = true;
                    return updatedChat;
                }
                return chat;
            })
                .sort(descendingly);
        case "CHAT_MEMBER_COUNT_RECEIVED" /* CHAT_MEMBER_COUNT_RECEIVED */:
            return state.map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    const memberCount = action.payload.member_count;
                    updatedChat = Object.assign({}, chat, { member_count: memberCount });
                    return updatedChat;
                }
                return chat;
            });
        case "TAGS_RECEIVED" /* TAGS_RECEIVED */:
            return state.map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    const tags = action.payload.tagsDefinition
                        ? action.payload.tagsDefinition
                        : [];
                    updatedChat = Object.assign({}, chat, { tagsDefinition: tags });
                    return updatedChat;
                }
                return chat;
            });
        case "ROLES_RECEIVED" /* ROLES_RECEIVED */:
            return state.map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    const roles = action.payload.chatRoles
                        ? action.payload.chatRoles
                        : [];
                    updatedChat = Object.assign({}, chat, { chatRoles: roles });
                    return updatedChat;
                }
                return chat;
            });
        case "LOGGED_IN_LEFT_CHAT" /* LOGGED_IN_LEFT_CHAT */:
            return state.map(chat => {
                if (chat.id === action.payload.chat_id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, { admin: false });
                    return updatedChat;
                }
                return chat;
            });
        case "THUMBNAIL_DOWNLOAD" /* THUMBNAIL_DOWNLOAD */:
            return state.map(chat => {
                if (chat.id === action.payload.id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, action.payload);
                    return updatedChat;
                }
                return chat;
            });
        case "CHAT_IMAGE_DOWNLOADED" /* CHAT_IMAGE_DOWNLOADED */:
            return state.map(chat => {
                if (chat.id === action.payload.id) {
                    const updatedChat = {};
                    updatedChat.localMedia = action.payload.localMedia;
                    return Object.assign({}, chat, updatedChat);
                }
                return chat;
            });
        case "DESELECT_CHAT" /* DESELECT_CHAT */:
            return state.map(chat => {
                if (chat.id === action.id) {
                    const updatedChat = {};
                    updatedChat.lastTypedMessage = action.lastTypedMessage;
                    return Object.assign({}, chat, updatedChat);
                }
                return chat;
            });
        case "CHAT_DELETED" /* CHAT_DELETED */:
            return state.filter(chat => chat.id !== action.chat_id);
        case "REQUEST_CHAT_HISTORY" /* REQUEST_CHAT_HISTORY */:
            return state.map(chat => {
                if (chat.id === action.payload) {
                    const updatedChat = {};
                    updatedChat.isHistoryRequested = true;
                    return Object.assign({}, chat, updatedChat);
                }
                return chat;
            });
        case "NEW_CHAT_RECEIVED" /* NEW_CHAT_RECEIVED */:
            if (!state.find(chat => chat.id === action.payload.id)) {
                const newChat = {};
                newChat.id = action.payload.id;
                newChat.admin = action.payload.admin;
                return ChatMiddleware.addNewChat(state, newChat).sort(descendingly);
            }
            else {
                return state;
            }
        case "CHAT_ADMINS_RECEIVED" /* CHAT_ADMINS_RECEIVED */:
            return state.map(chat => {
                if (chat.id === action.chatID) {
                    const updatedChat = {};
                    updatedChat.admins = action.admins;
                    return Object.assign({}, chat, updatedChat);
                }
                return chat;
            });
        case "CONFIG_QR_CODE_RECEIVED" /* CONFIG_QR_CODE_RECEIVED */:
            return state.map(chat => {
                if (chat.id === action.chatID) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, { configQrCode: action.configQrCode });
                    return updatedChat;
                }
                return chat;
            });
        case "UPDATE_CHAT" /* UPDATE_CHAT */:
            return state.map(chat => {
                if (chat.id === action.chatDetails.id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, action.chatDetails);
                    return updatedChat;
                }
                return chat;
            });
        case "UPDATE_STORE" /* UPDATE_STORE */:
            return state.map(chat => {
                if (chat.id === action.chatDetails.id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, action.chatDetails);
                    return updatedChat;
                }
                return chat;
            });
        case "SENDING_WELCOME_MESSAGE" /* SENDING_WELCOME_MESSAGE */:
            return state
                .map(chat => {
                if (chat.id === action.payload.receiver_id) {
                    let updatedChat = {};
                    updatedChat = Object.assign({}, chat, { welcomeMessageId: action.payload.reference
                            ? String(action.payload.reference)
                            : null });
                    return updatedChat;
                }
                return chat;
            })
                .sort(descendingly);
        case ChatActions.DESELECT_CHANNEL:
            return state.filter(chat => chat.type === CHANNEL);
        case "UI_SET_WAITTING_SPINER" /* UI_SET_WAITTING_SPINER */:
            return state.map(res => res.id === action.id
                ? Object.assign({}, res, { uploadImage: action.status, localMedia: action.imageUrl }) : res);
        case ChatActions.RESET:
            return [];
        default:
            return state;
    }
}
export { ɵ0, ɵ1 };
