import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MediaCenterService } from '../media-center.service';
import { MessageTypes } from 'src/models/constants';

@Component({
  selector: 'app-media-center-photo',
  templateUrl: './media-center-photo.component.html',
  styleUrls: ['./media-center-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterPhotoComponent {
  @Input() url: string;
  @Input() localMedia: string;
  @Input() localThumbnail: string;
  @Input() progress: number;
  @Input() mediaStatus: string;
  @Input() fileSize: number;
  @Input() height: number;
  @Input() width: number;

  mediaType = MessageTypes.PHOTO;
  localCoverStatus = true;

  constructor(private _mediaCenterService: MediaCenterService) {}

  get media() {
    return this._mediaCenterService.getMedia(this.localMedia);
  }

  get showCover() {
    return this._mediaCenterService.getCover(this.mediaStatus);
  }

  hideCover() {
    this.localCoverStatus = false;
  }
}
