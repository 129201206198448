<app-popup-setting-frame
  no-padding
  [title]="'Add Sticky Note'"
  [hideFooter]="true"
  (closeNotifier)="cancelEvent()"
>
  <form
    form-body
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="sticky-form"
  >
    <div class="sticky-form-body">
      <app-reactive-text-input
        [filedType]="'text'"
        [filedLabel]="'Title'"
        [fieldId]="'titleFiled'"
        [control]="form?.get('title')"
      >
      </app-reactive-text-input>
      <app-reactive-text-input
        [filedType]="'text'"
        [filedLabel]="'Description'"
        [fieldId]="'descriptionFiled'"
        [control]="form?.get('description')"
      >
      </app-reactive-text-input>
    </div>

    <div class="form-group sticky-form-footer">
      <div class="form-submit">
        <app-border-btn submit [type]="'submit'">
          Send
        </app-border-btn>
      </div>
    </div>
  </form>
</app-popup-setting-frame>
