<div class="startover-logo">
  <a
    class="app-logo clickable-cursor"
    href="https://nandbox.com"
    target="_blank"
  >
    <img
      src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"
      height="40"
      alt="nandbox Native App Builder logo"
      draggable="false"
    />
  </a>
  <div class="actions">
    <app-startover-droplist
      style="margin-right: 10px;"
      (saveNotify)="saveCat($event)"
      [isDropDown]="true"
      [category]="appManagementService?.selectedTempCat$ | async"
    ></app-startover-droplist>
    <div
      *ngIf="authService?.token"
      class="btn-group clickable-cursor list"
      #dd="bs-dropdown"
      dropdown
      appClickElsewhere
      (clickElsewhere)="dd.hide()"
    >
      <div class="head-action">
        <!-- <div class="help-icon">
          <svg
            class="clickable-cursor svg-icon top-icon"
            (click)="openHelpWindow(true)"
          >
            <use xlink:href="./assets/svg/app-sections.svg#icon-help-btn"></use>
          </svg>
          <div
            class="tooltip-info"
            *ngIf="
              (!(appManagementService?.appInfo$ | async)?.help_link &&
                this.mode == 0) ||
              ((appManagementService?.appInfo$ | async)?.help_link === 1 &&
                this.mode == 1)
            "
          >
            <div class="tooltip-info-body">
              Need Help? This is a step-by-step guide to every section in the
              nandbox app builder!
            </div>
            <div class="tooltip-info-footer">
              <span class="tooltip-btn" (click)="toggleTooltip(mode)">
                Got it
              </span>
            </div>
          </div>
        </div> -->

        <app-profile-image
          dropdownToggle
          class="head"
          [id]="(layoutService.selectedChat$ | async)?.id"
          [chatType]="'Channel'"
          [size]="32"
        >
        </app-profile-image>
      </div>

      <ul *dropdownMenu class="dropdown-menu no-copyable-item" role="menu">
        <li
          class="menuitem clickable-cursor"
          role="menuitem"
          type="button"
          (click)="logoutEvent()"
        >
          <a>Log Out</a>
        </li>
      </ul>
    </div>
  </div>
</div>
