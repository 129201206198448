import { Store } from '@ngrx/store';
import * as CalendarUIActions from './calendarUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CalendarUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    createCalendar(calendar) {
        this._store.dispatch(new CalendarUIActions.CreateCalendar(calendar));
    }
    updateCalendar(calendarId, calendar) {
        this._store.dispatch(new CalendarUIActions.UpdateCalendar(calendarId, calendar));
    }
    calendarReceived(calendar) {
        this._store.dispatch(new CalendarUIActions.CalendarReceived(calendar));
    }
    // no need for reducer for this action, only at the effects request the details
    calendarListReceived(calendars) {
        this._store.dispatch(new CalendarUIActions.CalendarListReceived(calendars));
    }
    setCalendarDetails(calendar_id, data, reference) {
        this._store.dispatch(new CalendarUIActions.SetCalendarDetails(calendar_id, data));
    }
    calendarDetailsReceived(calendar_id, data) {
        this._store.dispatch(new CalendarUIActions.CalendarDetailsReceived(calendar_id, data));
    }
    deleteCalendarDetail(calendar_id, references) {
        this._store.dispatch(new CalendarUIActions.DeleteCalendarDetail(calendar_id, references));
    }
    viewCalendarUI(selectedCalendar) {
        this._store.dispatch(new CalendarUIActions.ViewCalendarUI(selectedCalendar));
    }
    closeViewCalendar() {
        this._store.dispatch(new CalendarUIActions.CloseViewCalendar());
    }
    editCalendarUI(selectedCalendar) {
        this._store.dispatch(new CalendarUIActions.EditCalendarUI(selectedCalendar));
    }
    closeEditCalendar() {
        this._store.dispatch(new CalendarUIActions.CloseCalendarEdit());
    }
    createCalendarUI() {
        this._store.dispatch(new CalendarUIActions.CreateCalendarUI());
    }
    calendarListUI() {
        this._store.dispatch(new CalendarUIActions.CalendarListUI());
    }
    calendarCreationComplete() {
        this._store.dispatch(new CalendarUIActions.CalendarCreationComplete());
    }
    setCalendarInCreation(createdCalendar) {
        this._store.dispatch(new CalendarUIActions.SetCalendarInCreation(createdCalendar));
    }
    // paging dispatchers
    getNextCalendarPage() {
        this._store.dispatch(new CalendarUIActions.GetNextCalendarPage());
    }
    getPrevCalendarPage() {
        this._store.dispatch(new CalendarUIActions.GetPrevCalendarPage());
    }
    receivedCalendarHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new CalendarUIActions.ReceivedCalendarHistoryChunk(eop, sop, currentPage));
    }
    resetCalendarPaging() {
        this._store.dispatch(new CalendarUIActions.ResetCalendarPaging());
    }
}
CalendarUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarUiDispatchers_Factory() { return new CalendarUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: CalendarUiDispatchers, providedIn: "root" });
