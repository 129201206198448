import { NgModule } from '@angular/core';

import {
  PopoverModule,
  AlertModule,
  DatepickerModule,
  TimepickerModule,
  BsDatepickerModule,
  BsDropdownModule,
  CarouselModule,
  ProgressbarModule
} from 'ngx-bootstrap';

@NgModule({
  imports: [
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot()
  ],
  exports: [
    PopoverModule,
    AlertModule,
    DatepickerModule,
    TimepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    CarouselModule,
    ProgressbarModule
  ]
})
export class NgxModule {}
