import { Actions } from './component.actions';
import * as actions from './component.actions';
import { LocalComponent } from 'src/models/ChannelAppLocalConfig';

const INITIAL_STATE = [];

export function componentReducer(
  state: LocalComponent[] = INITIAL_STATE,
  action: Actions
): LocalComponent[] {
  switch (action.type) {
    case actions.ComponentActionTypes.APP_CONFIG_DATA_CHANGED: {
      return [
        ...action.onlineAppConfig.components,
        ...action.offlineAppConfig.components
      ];
    }
    case actions.ComponentActionTypes.UPDATE_COMPONENT: {
      return state.map(component => {
        if (component.ref === action.componentRef) {
          let updatedComponent: LocalComponent = {};
          updatedComponent = { ...component, ...action.componentUpdatedProps };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.REORDER_COMPONENT: {
      const targetComponent = state.find(
        component => component.ref === action.componentRef
      );
      const oldPosition = targetComponent ? targetComponent.order : null;
      const parentRef = targetComponent
        ? targetComponent.parentContainerRef
        : null;
      return state.map(component => {
        if (component.ref === action.componentRef) {
          let updatedComponent: LocalComponent = {};
          updatedComponent = { ...component, order: action.newPosition };
          return updatedComponent;
        } else if (
          component.parentContainerRef === parentRef &&
          oldPosition !== null &&
          component.order >= 0
        ) {
          if (
            action.newPosition < oldPosition &&
            component.order < oldPosition
          ) {
            // MOVING_UP
            let updatedComponent: LocalComponent = {};
            updatedComponent = { ...component, order: component.order + 1 };
            return updatedComponent;
          } else if (
            action.newPosition > oldPosition &&
            component.order <= action.newPosition
          ) {
            // MOVING_DOWN
            let updatedComponent: LocalComponent = {};
            updatedComponent = {
              ...component,
              order: component.order !== 0 ? component.order - 1 : 0
            };
            return updatedComponent;
          }
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.OPEN_SECTION: {
      return state.map(component => {
        if (component.ref === action.component.ref) {
          let updatedComponent: LocalComponent = {};
          updatedComponent.currentlyOpened = 1;
          updatedComponent = { ...component, ...updatedComponent };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.CLOSE_SECTION: {
      return state.map(component => {
        if (component.ref === action.component.ref) {
          let updatedComponent: LocalComponent = {};
          updatedComponent.currentlyOpened = 0;
          updatedComponent = { ...component, ...updatedComponent };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.DELETE_COMPONENT: {
      const orderOfDeletedComponent = action.component.order;
      const parentRef = action.component.parentContainerRef;
      const remainingList = state.filter(
        component => component.ref !== action.component.ref
      );
      if (remainingList) {
        remainingList.map(component => {
          if (
            component.parentContainerRef === parentRef &&
            (orderOfDeletedComponent !== null &&
              orderOfDeletedComponent !== undefined) &&
            component.order >= 0
          ) {
            if (component.order > orderOfDeletedComponent) {
              let updatedComponent: LocalComponent = {};
              updatedComponent = {
                ...component,
                order: component.order - 1
              };
              return updatedComponent;
            } else if (component.order === 0 && action.component.selected) {
              let updatedComponent: LocalComponent = {};
              updatedComponent = { ...component };
              return updatedComponent;
            }
          }
          return component;
        });
        remainingList[0] = { ...remainingList[0], selected: true };
        return remainingList;
      } else {
        return INITIAL_STATE;
      }
    }
    case actions.ComponentActionTypes.DELETE_COMPONENTS_OF_CONTAINER: {
      return state.filter(
        component => component.parentContainerRef !== action.container.ref
      );
    }
    case actions.ComponentActionTypes.ADD_COMPONENTS: {
      return [...action.components, ...state];
    }
    case actions.ComponentActionTypes.ADD_COMPONENT: {
      let newComponent: LocalComponent = action.component;
      if (action.component.order === 0) {
        newComponent = { ...action.component, selected: true };
      }
      return [newComponent, ...state];
    }
    case actions.ComponentActionTypes.COMPONENT_MEDIA_UPLOAD_SUCCESS: {
      return state.map(component => {
        if (component.ref === action.component.ref) {
          let updatedComponent: LocalComponent = {};
          if (action.isBackground) {
            updatedComponent.bg_image = action.imageUrl;
          } else {
            updatedComponent.image_url = action.imageUrl;
            updatedComponent.local_image = action.localImage;
          }
          updatedComponent = { ...component, ...updatedComponent };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.COMPONENT_MEDIA_DOWNLOAD_SUCCESS: {
      return state.map(component => {
        if (component.ref === action.component.ref) {
          let updatedComponent: LocalComponent = {};
          updatedComponent.local_image = action.localImage;
          updatedComponent = { ...component, ...updatedComponent };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.SELECT_COMPONENT: {
      return state.map(component => {
        if (
          action.selectedComponent &&
          component.ref === action.selectedComponent.ref
        ) {
          let updatedComponent: LocalComponent = {};
          updatedComponent = { ...component, selected: true };
          return updatedComponent;
        } else if (
          action.selectedComponent &&
          component.parentContainerRef ===
            action.selectedComponent.parentContainerRef
        ) {
          // This to update the siblings of the selected container
          let updatedComponent: LocalComponent = {};
          updatedComponent = { ...component, selected: false };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.UPLOAD_SPLASH_PAGE_LOGO_SUCCESS: {
      return state.map(component => {
        if (component.ref === action.componentRef) {
          let updatedComponent: LocalComponent = {};
          updatedComponent.image_url = action.imageUrl;
          updatedComponent.image_set = action.imageSet;
          updatedComponent = { ...component, ...updatedComponent };
          return updatedComponent;
        }
        return component;
      });
    }
    case actions.ComponentActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
