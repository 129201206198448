<div class="popup-container">
  <div class="title">Apple ID</div>
  <div class="sub-title">Two-Factor Authentication</div>

  <app-input-code (valuesEntered)="submitCode($event)"></app-input-code>
  <div *ngIf="hasErrors" class="invalid-feedback">
    You should enter the whole 6-digit code before submit
  </div>

  <div class="desc">
    A message with a verification code has been sent to your devices. Enter the
    code to continue.
  </div>
  <div class="footer-container">
    <div class="footer">
      You must enter the code in <span class="bold">05:00</span> Min
    </div>
    <div class="form-submit">
      <app-border-btn submit (btnClicked)="setCode()" [focused]="submitFocused">
        Submit
      </app-border-btn>
      <app-border-btn cancel (btnClicked)="onClose()">
        Cancel
      </app-border-btn>
    </div>
  </div>
  <!-- <div class="close-btn" (click)="onClose()">
    <img src="./assets/img/cancel-key.svg" alt="" />
  </div> -->
</div>
