<!-- Header section -->
<div class="header">
  <div class="media">
    <div class="media-avtar">
      <app-profile-image
        [id]="selectedChat?.id"
        [chatType]="selectedChat?.type"
        [size]="40"
      ></app-profile-image>
    </div>
    <div class="media-body">
      <div class="media-heading">
        <span
          class="text-capitalize"
          [innerHTML]="
            (uiService.effectiveSelectedChat$ | async)?.name | twemoji
          "
        ></span>
      </div>
    </div>
  </div>

  <div class="actions">
    <a [href]="media" [download]="message?.media_id">
      <img src="./assets/img/ic-download-grey-24-px.svg" alt="download" />
    </a>
    <div class="cancel" (click)="cancel()">
      <i class="fas fa-times cancel" aria-hidden="true"></i>
    </div>
  </div>
</div>

<!-- Body section -->
<div class="body" appCancelDrag (click)="cancel()">
  <!-- previous section -->
  <div class="item">
    <div class="nav-btn clickable-cursor " (click)="previous()" appCancelEvent>
      <i class="fas fa-angle-left"></i>
    </div>
  </div>

  <!-- Media section -->
  <div class="media-item">
    <app-image-cell
      *ngIf="
        message?.type === 'photo' ||
        (message?.type === 'gif' && !message?.media_id?.endsWith('mp4'))
      "
      class="media no-copyable-item"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localThumbnail]="message?.localThumbnail"
      [localMedia]="message?.localMedia"
      [fileSize]="message?.file_size"
      [themeColor]="themeColor"
      [isFullScreen]="true"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
    >
    </app-image-cell>

    <app-gif-cell
      *ngIf="message?.type === 'gif' && message?.media_id?.endsWith('mp4')"
      class="media no-copyable-item"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localMedia]="message?.localMedia"
      [localThumbnail]="message?.localThumbnail"
      [fileSize]="message?.file_size"
      [themeColor]="themeColor"
      [isFullScreen]="true"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
    >
    </app-gif-cell>

    <app-video-cell
      *ngIf="message?.type === 'video'"
      class="media no-copyable-item"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localMedia]="message?.localMedia"
      [localThumbnail]="message?.localThumbnail"
      [fileSize]="message?.file_size"
      [mediaDuration]="message?.media_duration"
      [themeColor]="themeColor"
      [isFullScreen]="true"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
    >
    </app-video-cell>
  </div>

  <!-- next section -->
  <div class="item">
    <div class="nav-btn clickable-cursor" (click)="next()" appCancelEvent>
      <i class="fas fa-angle-right"></i>
    </div>
  </div>
</div>

<!-- Footer section -->
<div class="footer">
  <div class="scroll">
    <span class="blank-scroll-area"></span>
    <!-- Show images -->
    <div
      *ngFor="let msg of currentMediaMessages; trackBy: TrackByFunction"
      [ngStyle]="bkGroundImage(msg)"
      class="indicators clickable-cursor"
      [class.selected]="
        message?.reference + message?.sender_id ===
        msg?.reference + msg?.sender_id
      "
      [class.no-media-image]="
        !msg?.localMedia && !msg?.localThumbnail && msg?.type === 'photo'
      "
      [class.no-media-video]="
        !msg?.localThumbnail && (msg?.type === 'video' || msg?.type === 'gif')
      "
      (click)="selectMedia(msg)"
    ></div>
    <span class="blank-scroll-area"></span>
  </div>
</div>
