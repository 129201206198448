import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appCancelDrag]'
})
export class CancelDragDirective {
  @HostListener('dragstart', ['$event'])
  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  @HostListener('dragleave', ['$event'])
  @HostListener('drop', ['$event'])
  onDragEvents(event: DragEvent) {
    event.preventDefault();
    return false;
  }
}
