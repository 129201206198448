import { Action } from '@ngrx/store/public_api';
import { AppConfigHistoryRecord } from 'src/models/ChannelAppConfig';

export const enum AppConfigHistoryActionTypes {
  REQUEST_APP_CONFIGS_HISTORY = 'REQUEST_APP_CONFIGS_HISTORY',
  RECEIVE_APP_CONFIGS_HISTORY = 'RECEIVE_APP_CONFIGS_HISTORY',
  RESTORE_APP_CONFIG_BY_ID = 'RESTORE_APP_CONFIG_BY_ID',
  RESET = 'RESET'
}

export class RequestAppConfigsHistory implements Action {
  readonly type = AppConfigHistoryActionTypes.REQUEST_APP_CONFIGS_HISTORY;
  constructor() {}
}

export class ReceiveAppConfigsHistory implements Action {
  readonly type = AppConfigHistoryActionTypes.RECEIVE_APP_CONFIGS_HISTORY;
  constructor(public configHistoryList: AppConfigHistoryRecord[]) {}
}

export class RestoreAppConfigByID implements Action {
  readonly type = AppConfigHistoryActionTypes.RESTORE_APP_CONFIG_BY_ID;
  constructor(public id: string) {}
}

export class Reset implements Action {
  readonly type = AppConfigHistoryActionTypes.RESET;
}

export type Actions =
  | RequestAppConfigsHistory
  | ReceiveAppConfigsHistory
  | RestoreAppConfigByID
  | Reset;
