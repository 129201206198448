import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as SubGroupUIActions from './subGroupsUI.actions';

import { IChat } from 'src/models/IChat';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class SubGroupUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  listSubGroupUI(): void {
    this._store.dispatch(new SubGroupUIActions.ListSubGroupUI());
  }
  createSubGroupUI(): void {
    this._store.dispatch(new SubGroupUIActions.CreateSubGroupUI());
  }
  viewSubGroupUI(selectedSubGroup: IChat): void {
    this._store.dispatch(
      new SubGroupUIActions.ViewSubGroupUI(selectedSubGroup)
    );
  }
  editSubGroupUI(selectedSubGroup: IChat, file?): void {
    this._store.dispatch(
      new SubGroupUIActions.EditSubGroupUI(selectedSubGroup, file)
    );
  }

  uploadSubGroupImage(selectedFile): void {
    this._store.dispatch(
      new SubGroupUIActions.UploadSubGroupImage(selectedFile)
    );
  }
  subGroupPhotoUploadUI(createdSubGroup: IChat): void {
    this._store.dispatch(
      new SubGroupUIActions.SubGroupPhotoUploadUI(createdSubGroup)
    );
  }
  subGroupCreationComplete(): void {
    this._store.dispatch(new SubGroupUIActions.SubGroupCreationComplete());
  }

  closeViewSubGroupUI(): void {
    this._store.dispatch(new SubGroupUIActions.CloseViewSubGroupUI());
  }
  closeEditSubGroupUI(): void {
    this._store.dispatch(new SubGroupUIActions.CloseEditSubGroupUI());
  }

  // paging dispatchers
  getNextSubGroupPage(): void {
    this._store.dispatch(new SubGroupUIActions.GetNextSubGroupPage());
  }

  getPrevSubGroupPage(): void {
    this._store.dispatch(new SubGroupUIActions.GetPrevSubGroupPage());
  }

  receivedSubGroupHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChat[]
  ): void {
    this._store.dispatch(
      new SubGroupUIActions.ReceivedSubGroupHistoryChunk(eop, sop, currentPage)
    );
  }

  resetSubGroupPaging(): void {
    this._store.dispatch(new SubGroupUIActions.ResetSubGroupPaging());
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new SubGroupUIActions.SetStatusAsIdle());
  }

  setGroupChat(data) {
    this._store.dispatch(new SubGroupUIActions.SetGroupChat(data));
  }
}
