import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS
} from 'src/models/constants';
import { Actions } from './product-store.actions';

import * as actions from './product-store.actions';

import { ProductStoreUI } from 'src/models/productStore';
import { productStoreMiddleware } from './product-store.middleware';

const INITIAL_STATE: ProductStoreUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  productGroupId: null,
  ownerGroupId: null,
  selecteedProductStore: null
};

export function productsStoreUiReducer(
  state = INITIAL_STATE,
  action: Actions
): ProductStoreUI {
  switch (action.type) {
    case actions.ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_RESET_PAGING:
      return INITIAL_STATE;
    case actions.ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_NEXT_PAGE:
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    case actions.ProductsStoreUIActionTypes.UI_PRODUCTS_STORE_GET_PREV_PAGE:
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    case actions.ProductsStoreUIActionTypes
      .UI_PRODUCTS_STORE_RECEIVED_HISTORY_CHUNK: {
        const prevRequest: ListRequestDetails = {};
        prevRequest.eop = state.eop;
        prevRequest.sop = state.sop;
        prevRequest.hash = state.hash;
        prevRequest.direction = state.direction;
        return {
          ...state,
          previousRequest: prevRequest,
          eop: action.eop ? action.eop : state.eop,
          sop: action.sop ? action.sop : state.sop,
          status: SCREEN_STATUS.IDLE,
          currentPage:
            (action.currentPage && action.currentPage.length) > 0
              ? action.currentPage
              : state.currentPage
        };
      }
    case actions.ProductsStoreUIActionTypes.UI_SET_SELECETED_PRODUCTS_STORE:
      return {
        ...state,
        selecteedProductStore: { ...action.product }
      };
    case actions.ProductsStoreUIActionTypes.UI_RECEIVE_PRODUCT_STORE_ITEM:
      if (
        !productStoreMiddleware.isProductExist(state.currentPage, action.product)
      ) {
        return {
          ...state,
          currentPage: [action.product, ...state.currentPage]
        };
      }
      return {
        ...state,
        currentPage: state.currentPage.map(res =>
          res.id === action.product.id ? { ...res, ...action.product } : res
        ),
        selecteedProductStore: {
          ...action.product
        }
      };
    case actions.ProductsStoreUIActionTypes.UI_REMOVE_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        currentPage: state.currentPage.filter(item => item.id !== action.id)
      };
    case actions.ProductsStoreUIActionTypes.UI_PRODUCTS_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.ProductsStoreUIActionTypes.UI_SET_PRODUCT_ITEM_ARRAY_SUCCESS: {
      return {
        ...state,
        selecteedProductStore: { ...state.selecteedProductStore, variant: action.product }
      }
    }
    default:
      return state;
  }
}
