import { Store } from '@ngrx/store';
import * as ComponentActions from './component.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ComponentDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateComponent(componentRef, componentUpdatedProps) {
        this._store.dispatch(new ComponentActions.UpdateComponent(componentRef, componentUpdatedProps));
    }
    reorderComponent(componentRef, newPosition) {
        this._store.dispatch(new ComponentActions.ReorderComponent(componentRef, newPosition));
    }
    openSection(component) {
        this._store.dispatch(new ComponentActions.OpenSection(component));
    }
    closeSection(component) {
        this._store.dispatch(new ComponentActions.CloseSection(component));
    }
    deleteComponentRequest(component) {
        this._store.dispatch(new ComponentActions.DeleteComponentRequest(component));
    }
    deleteComponent(component) {
        this._store.dispatch(new ComponentActions.DeleteComponent(component));
    }
    deleteComponentsOfContainer(container) {
        this._store.dispatch(new ComponentActions.DeleteComponentsOfContainer(container));
    }
    addComponents(components) {
        this._store.dispatch(new ComponentActions.AddComponents(components));
    }
    addComponentRequest(parentContainer) {
        this._store.dispatch(new ComponentActions.AddComponentRequest(parentContainer));
    }
    addComponent(component) {
        this._store.dispatch(new ComponentActions.AddComponent(component));
    }
    uploadComponentMedia(selectedFile, component, isBackground) {
        this._store.dispatch(new ComponentActions.UploadComponentMedia(selectedFile, component, isBackground));
    }
    downloadComponentMedia(imageId, blobType, component) {
        this._store.dispatch(new ComponentActions.DownloadComponentMedia(imageId, blobType, component));
    }
    componentMediaUploadSuccess(imageUrl, localImage, component, isBackground) {
        this._store.dispatch(new ComponentActions.ComponentMediaUploadSuccess(imageUrl, localImage, component, isBackground));
    }
    componentMediaDownloadSuccess(localImage, component) {
        this._store.dispatch(new ComponentActions.ComponentMediaDownloadSuccess(localImage, component));
    }
    selectComponent(selectedComponent) {
        this._store.dispatch(new ComponentActions.SelectComponent(selectedComponent));
    }
    UploadSplashPageLogo(componentRef, selectedFile) {
        this._store.dispatch(new ComponentActions.UploadSplashPageLogo(componentRef, selectedFile));
    }
    uploadVideoBackground(componentRef, currentPage, selectedFile) {
        this._store.dispatch(new ComponentActions.UploadVideoBackground(componentRef, currentPage, selectedFile));
    }
    UploadSplashPageLogoSuccess(componentRef, imageUrl, imageSet) {
        this._store.dispatch(new ComponentActions.UploadSplashPageLogoSuccess(componentRef, imageUrl, imageSet));
    }
}
ComponentDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ComponentDispatchers_Factory() { return new ComponentDispatchers(i0.ɵɵinject(i1.Store)); }, token: ComponentDispatchers, providedIn: "root" });
