import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { PaymentOrdersUIActionTypes } from './paymentOrdersUI.actions';
import * as PaymentOrdersActions from './paymentOrdersUI.actions';
import { PaymentOrdersUiDispatchers } from './paymentOrdersUI.dispatchers';
import { GetPaymentOrdersMethod } from 'src/models/PaymentGateways';
import {
  PageDirection,
  PAYMENT_ORDER_USERS_REF,
  PAYMENT_ORDER_GROUP_REF
} from 'src/models/constants';
import { GetMyMember } from 'src/models/IChatMember';
import { CreateStripeSub, GetChatInfo, ResetStripeSub } from 'src/models/IChat';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class PaymentOrdersUiEffects {
  @Effect({ dispatch: false })
  getNextPaymentOrdersPage = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.paymentOrdersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, paymentOrdersUiState]) => {
      const action = <PaymentOrdersActions.GetNextPaymentOrdersPage>val;
      const prevRequest = paymentOrdersUiState.previousRequest;
      if (
        !(
          paymentOrdersUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          paymentOrdersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetPaymentOrdersMethod(
            0,
            paymentOrdersUiState.eop,
            action.paymentOrderID
          )
        );
      } else {
        this._uiPaymentOrdersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevPaymentOrdersPage = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.paymentOrdersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, paymentOrdersUiState]) => {
      const action = <PaymentOrdersActions.GetPrevPaymentOrdersPage>val;
      const prevRequest = paymentOrdersUiState.previousRequest;
      if (
        !(
          paymentOrdersUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          paymentOrdersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetPaymentOrdersMethod(
            1,
            paymentOrdersUiState.sop,
            action.paymentOrderID
          )
        );
      } else {
        this._uiPaymentOrdersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterPaymentOrdersResetPaging = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_RESET_PAGING),
    map((action: PaymentOrdersActions.ResetPaymentOrdersPaging) => {
      this._uiPaymentOrdersDispatchers.getNextPaymentOrdersPage(
        action.paymentOrderID
      );
    })
  );

  @Effect({ dispatch: false })
  createStripeSubChannel = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.PAYMENT_STRIPE_SUB_CHANNEL),
    map((action: PaymentOrdersActions.ResetPaymentOrdersPaging) => {
      this._socketGateway.sendSocketMessage(new CreateStripeSub());
    })
  );

  @Effect({ dispatch: false })
  resetStripeSubChannel = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.PAYMENT_STRIPE_SUB_CHANNEL_RESET),
    map((action: PaymentOrdersActions.ResetStripeSubChannel) => {
      this._socketGateway.sendSocketMessage(
        new ResetStripeSub(action.provider_id)
      );
    })
  );

  @Effect({ dispatch: false })
  afterReceivePaymentOrders = this.actions$.pipe(
    ofType(PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK),
    map((action: PaymentOrdersActions.ReceivedPaymentOrdersHistoryChunk) => {
      if (action.currentPage && action.currentPage.length > 0) {
        this._socketGateway.sendSocketMessage(
          new GetMyMember(
            [
              ...new Set([
                ...action.currentPage.map(order => order.receiver_id),
                ...action.currentPage.map(order => order.account_id)
              ])
            ],
            PAYMENT_ORDER_USERS_REF
          )
        );
        this._socketGateway.sendSocketMessage(
          new GetChatInfo(
            action.currentPage.map(order => order.group_id),
            PAYMENT_ORDER_GROUP_REF
          )
        );
      }
    })
  );
  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiPaymentOrdersDispatchers: PaymentOrdersUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
