import { Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { StorageService } from 'src/app/core/storage.service';
import { TOKEN_LOCAL_STORAGE_KEY, ADMIN_NAME_LOCAL_STORAGE_KEY, REM_LOCAL_STORAGE_KEY, ID_LOCAL_STORAGE_KEY, OFFSET_STORAGE_KEY, WEB_VERSION_KEY, WEB_VERSION, CHATID_LOCAL_STORAGE_KEY, BASE_URL_LOCAL_STORAGE_KEY } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../core/storage.service";
export class AuthDispatchers {
    constructor(_store, _storageService) {
        this._store = _store;
        this._storageService = _storageService;
        this.authRem = true;
        this.setupLocalStorage();
        this.setupLocalAuthStore();
    }
    reopenNewConnection() {
        this._store.dispatch(new AuthActions.ReopenNewConnection());
    }
    requestNewQRCode() {
        this._store.dispatch(new AuthActions.RequestNewQRcode());
    }
    requestQRCode() {
        this._store.dispatch(new AuthActions.RequestQRcode());
    }
    reciveAuthQR(auth) {
        this._store.dispatch(new AuthActions.ReciveAuthQR(auth));
    }
    reciveAuthToken(auth) {
        this.localAuth = Object.assign({}, auth);
        this._store.dispatch(new AuthActions.ReciveAuthToken(auth));
    }
    reciveAuthTokenChange(auth) {
        this.localAuth = Object.assign({}, auth);
        this._store.dispatch(new AuthActions.ReciveAuthTokenChange(auth));
    }
    reciveAuthOK(auth) {
        this.localAuth = Object.assign({}, auth);
        this._store.dispatch(new AuthActions.ReciveAuthOK(auth));
    }
    reciveAuthNot() {
        this._store.dispatch(new AuthActions.ReciveAuthNot());
    }
    reciveMultiLogin() {
        this._store.dispatch(new AuthActions.ReciveMultiLogin());
    }
    setAuthQRExpire() {
        this._store.dispatch(new AuthActions.SetAuthQRExpire());
    }
    resetAuthStorage() {
        this._storageService.removeRecord(TOKEN_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(ADMIN_NAME_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(BASE_URL_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(CHATID_LOCAL_STORAGE_KEY);
    }
    setupLocalStorage() {
        const rem = this._storageService.getRecord(REM_LOCAL_STORAGE_KEY);
        const ver = this._storageService.getRecord(WEB_VERSION_KEY);
        if (!rem) {
            this._storageService.setRecord(REM_LOCAL_STORAGE_KEY, true);
        }
        else {
            this.authRem = rem === 'true';
        }
        if (WEB_VERSION !== ver) {
            this.resetAuthStorage();
            this._storageService.setRecord(WEB_VERSION_KEY, WEB_VERSION);
        }
    }
    setupLocalAuthStore() {
        const token = this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
        if (token !== null && token.length > 0) {
            this._store.dispatch(new AuthActions.ReciveAuthToken({ token }));
        }
    }
    setAuthRem(rem) {
        this._storageService.setRecord(REM_LOCAL_STORAGE_KEY, rem);
        this.authRem = rem;
    }
    set localAuth(data) {
        if (data.token) {
            this._storageService.setRecord(TOKEN_LOCAL_STORAGE_KEY, data.token);
        }
        if (data.name) {
            this._storageService.setRecord(ADMIN_NAME_LOCAL_STORAGE_KEY, data.name);
        }
        if (data.chatId) {
            this._storageService.setRecord(CHATID_LOCAL_STORAGE_KEY, data.chatId);
        }
        if (data.ID) {
            this._storageService.setRecord(ID_LOCAL_STORAGE_KEY, data.ID);
        }
    }
    get localAuth() {
        return {
            token: this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY),
            rem: this._storageService.getRecord(REM_LOCAL_STORAGE_KEY) === 'true'
        };
    }
    removeLocalAuth() {
        this._storageService.removeRecord(ADMIN_NAME_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(TOKEN_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(OFFSET_STORAGE_KEY);
        this._storageService.removeRecord(CHATID_LOCAL_STORAGE_KEY);
        this._storageService.removeRecord(ID_LOCAL_STORAGE_KEY);
    }
    isAuthSet() {
        const token = this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
        if (token !== null && token.length > 0) {
            return true;
        }
        return false;
    }
    resetStore() {
        this.removeLocalAuth();
        this._store.dispatch(new AuthActions.Reset());
    }
    logOut() {
        this.removeLocalAuth();
        this._store.dispatch(new AuthActions.LoggedOut());
    }
    getLoginID() {
        if (!this._storageService.getRecord(ID_LOCAL_STORAGE_KEY)) {
            this.logOut();
        }
        return this._storageService.getRecord(ID_LOCAL_STORAGE_KEY);
    }
    getLoginName() {
        return this._storageService.getRecord(ADMIN_NAME_LOCAL_STORAGE_KEY);
    }
    /* Merchant WEB only */
    reciveAuthChatList(payload) {
        this._store.dispatch(new AuthActions.ReciveAuthChatList(payload.chat_list));
    }
    setChatSelect(chatId, is_auth) {
        this._store.dispatch(new AuthActions.SetChatSelect(chatId, is_auth));
    }
    receiveAuthChatSelected(auth) {
        const chatId = auth.chatId;
        this.localAuth = { chatId };
        this._store.dispatch(new AuthActions.ReceiveAuthChatSelected(auth));
    }
    showAuthChatList() {
        this._store.dispatch(new AuthActions.ShowAuthChatList());
    }
    /* Channel App */
    getCountryData() {
        this._store.dispatch(new AuthActions.GetCountryData());
    }
    setCountryData(countryData) {
        this._store.dispatch(new AuthActions.SetCountryData(countryData));
    }
    getEmailTac(email, type) {
        this._store.dispatch(new AuthActions.GetEmailTac(email, type));
    }
    getChatData(chat_id) {
        this._store.dispatch(new AuthActions.GetChatData(chat_id));
    }
    emailTacSuccess(email, appName, name, password, countryIso) {
        this._store.dispatch(new AuthActions.EmailTacSuccess(email, appName, name, password, countryIso));
    }
    createAccount(tac) {
        this._store.dispatch(new AuthActions.CreateAccount(tac));
    }
    createAccountSuccess(password) {
        this._store.dispatch(new AuthActions.CreateAccountSuccess(password));
    }
    getToken(email, password) {
        this._store.dispatch(new AuthActions.GetTokenAction(email, password));
    }
    emailTokenReceived(token) {
        this.localAuth = { token };
        this._store.dispatch(new AuthActions.EmailTokenReceived(token));
    }
    selectTemplate(templateID) {
        this._store.dispatch(new AuthActions.SelectTemplate(templateID));
    }
    setAppProgress(progressWeb, webMode) {
        this._store.dispatch(new AuthActions.SetAppProgress(progressWeb, webMode));
    }
    setAppMode(webMode) {
        this._store.dispatch(new AuthActions.SetAppMode(webMode));
    }
    loginByEmail() {
        this._store.dispatch(new AuthActions.LoginByEmail());
    }
    requestAdminQR() {
        this._store.dispatch(new AuthActions.RequestAdminQR());
    }
    receiveAdminQR(adminQR, chatID) {
        this._store.dispatch(new AuthActions.ReceiveAdminQR(adminQR, chatID));
    }
    receiveAuthPrivilege(data, reference) {
        this._store.dispatch(new AuthActions.ReceiveAuthPrivilege(data, reference));
    }
    changePassword(oldPassword, newPassword, confirmNewPassword) {
        this._store.dispatch(new AuthActions.ChangePassword({
            oldPassword,
            newPassword,
            confirmNewPassword
        }));
    }
    receiveChangePasswordSet() {
        this._store.dispatch(new AuthActions.ReceiveChangePasswordSet());
    }
    receiveChangePasswordError(err) {
        this._store.dispatch(new AuthActions.ReceiveChangePasswordError(err));
    }
    signUpDemoAccount(deviceId, os, osVersion, msisdn, email, typ, countryDial, countryIso) {
        this._store.dispatch(new AuthActions.SignUpDemoAccount(deviceId, os, osVersion, msisdn, email, typ, countryDial, countryIso));
    }
    registerDemoAccount(deviceId, os, osVersion, msisdn, typ, tac, countryDial, email) {
        this._store.dispatch(new AuthActions.RegisterDemoAccount(deviceId, os, osVersion, msisdn, typ, tac, countryDial, email));
    }
    getDemoAccountStatus(msisdn, countryCode) {
        this._store.dispatch(new AuthActions.GetDemoAccountStatus(msisdn, countryCode));
    }
    getChatPrivilleges() {
        this._store.dispatch(new AuthActions.GetChatPrivilleges());
    }
    getRoles() {
        this._store.dispatch(new AuthActions.GetRoles());
    }
    getMyProfiles() {
        this._store.dispatch(new AuthActions.GetMyProfiles());
    }
    getUpgradedChatList(is_default) {
        this._store.dispatch(new AuthActions.GetUpgradedChatList(is_default));
    }
    getSearchFilters() {
        this._store.dispatch(new AuthActions.GetSearchFilters());
    }
    getAppSetup() {
        this._store.dispatch(new AuthActions.GetAppSetup());
    }
    getBots() {
        this._store.dispatch(new AuthActions.GetBots());
    }
    resendVerCode(msisdn, countryCode) {
        this._store.dispatch(new AuthActions.ResendCode(msisdn, countryCode));
    }
    setBusinessInfo(company_name, website) {
        this._store.dispatch(new AuthActions.SetBusinessInfo(company_name, website));
    }
    getBusinessInfo() {
        this._store.dispatch(new AuthActions.GetBusinessInfo());
    }
    reseaveBusinessInfoResponse(company_name, website) {
        this._store.dispatch(new AuthActions.ReseaveBusinessInfoResponse(company_name, website));
    }
    returnToInIt() {
        this._store.dispatch(new AuthActions.ReturnToInIt());
    }
    closeSocket() {
        this._store.dispatch(new AuthActions.CloseWs());
    }
    getChatList(upgraded) {
        this._store.dispatch(new AuthActions.GetChatList(upgraded));
    }
}
AuthDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthDispatchers_Factory() { return new AuthDispatchers(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.StorageService)); }, token: AuthDispatchers, providedIn: "root" });
