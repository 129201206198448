<div class="container">
  <img
    alt="nandbox logo"
    class="nandbox-logo"
    height="40"
    alt="nandbox Native App Builder logo"
    src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp
"
  />
  <div class="container-body">
    <img alt="nandbox logo" [src]="data?.img" />
    <div class="title">
      {{ data?.title }}
    </div>
    <div class="desc">
      {{ data?.desc }}
    </div>
    <button (click)="goToBillingScreen()">{{ data?.btnTitle }}</button>
  </div>
</div>
