import * as actions from './calendarUI.actions';
import { CalendarScreens, SCREEN_STATUS } from 'src/models/constants';
const INITIAL_STATE = {
    selectedCalendar: null,
    creationProcessCalendar: null,
    currentCalendarScreen: CalendarScreens.CALENDAR_LIST,
    eop: 0,
    currentPage: [],
    hash: '',
    status: SCREEN_STATUS.IDLE
};
const ascCompare = (first, second) => {
    const firstTime = first.start_time ? first.start_time : '0';
    const secondTime = second.start_time ? second.start_time : '0';
    const endTime1 = first.end_time ? first.end_time : '0';
    const endTime2 = second.end_time ? second.end_time : '0';
    if (firstTime < secondTime) {
        return -1;
    }
    else if (firstTime > secondTime) {
        return 1;
    }
    else if (endTime1 > endTime2) {
        return 1;
    }
    else {
        return -1;
    }
};
const ɵ0 = ascCompare;
export function calendarUiReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "CALENDAR_RECEIVED" /* CALENDAR_RECEIVED */: {
            if (!state.currentPage.find(calendar => calendar.id == action.calendar.id)) {
                return Object.assign({}, state, { currentPage: [...state.currentPage, action.calendar] });
            }
            else {
                return Object.assign({}, state, { currentPage: state.currentPage.map(calendar => {
                        if (calendar.id == action.calendar.id) {
                            return Object.assign({}, calendar, action.calendar);
                        }
                        return calendar;
                    }) });
            }
        }
        case "CALENDAR_DETAILS_RECEIVED" /* CALENDAR_DETAILS_RECEIVED */: {
            let rcvdData = action.data;
            if (action.data) {
                const data = action.data.map(day => {
                    const sortedDay = day;
                    sortedDay.hours = sortedDay.hours.sort(ascCompare);
                    return sortedDay;
                });
                rcvdData = data;
            }
            if (!state.currentPage.find(calendar => calendar.id == action.calendar_id)) {
                const cal = {};
                cal.id = action.calendar_id;
                cal.data = rcvdData;
                return Object.assign({}, state, { currentPage: [cal, ...state.currentPage] });
            }
            else {
                return Object.assign({}, state, { currentPage: state.currentPage.map(calendar => {
                        if (calendar.id == action.calendar_id) {
                            const updatedCalendar = Object.assign({}, calendar);
                            updatedCalendar.data = rcvdData;
                            return updatedCalendar;
                        }
                        return calendar;
                    }) });
            }
        }
        case "UI_VIEW_CALENDAR" /* UI_VIEW_CALENDAR */: {
            return Object.assign({}, state, { selectedCalendar: action.selectedCalendar, creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_VIEW });
        }
        case "UI_CLOSE_CALENDAR_VIEW" /* UI_CLOSE_CALENDAR_VIEW */: {
            return Object.assign({}, state, { selectedCalendar: null, creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_LIST });
        }
        case "UI_EDIT_CALENDAR" /* UI_EDIT_CALENDAR */: {
            return Object.assign({}, state, { selectedCalendar: action.selectedCalendar, creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_EDIT });
        }
        case "UI_CLOSE_EDIT_CALENDAR " /* UI_CLOSE_EDIT_CALENDAR */: {
            return Object.assign({}, state, { creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_VIEW });
        }
        case "UI_CREATE_CALENDAR" /* UI_CREATE_CALENDAR */: {
            const res = Object.assign({}, state, { selectedCalendar: null, creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_CREATION });
            return res;
        }
        case "UI_CALENDAR_LIST" /* UI_CALENDAR_LIST */: {
            return Object.assign({}, state, { selectedCalendar: null, creationProcessCalendar: null, eop: 0, currentPage: [], hash: '', status: SCREEN_STATUS.IDLE, currentCalendarScreen: CalendarScreens.CALENDAR_LIST });
        }
        case "UI_SET_CALENDAR_IN_CREATION" /* UI_SET_CALENDAR_IN_CREATION */: {
            if (state.currentCalendarScreen === CalendarScreens.CALENDAR_EDIT) {
                return state;
            }
            else {
                return Object.assign({}, state, { creationProcessCalendar: action.createdCalendar, selectedCalendar: action.createdCalendar, currentCalendarScreen: CalendarScreens.CALENDAR_EDIT });
            }
        }
        case "UI_CALENDAR_CREATION_COMPLETE" /* UI_CALENDAR_CREATION_COMPLETE */: {
            return Object.assign({}, state, { creationProcessCalendar: null, currentCalendarScreen: CalendarScreens.CALENDAR_LIST });
        }
        // calendars paging system
        case "UI_CALENDAR_GET_NEXT_PAGE" /* UI_CALENDAR_GET_NEXT_PAGE */: {
            return Object.assign({}, state, { status: SCREEN_STATUS.REQUESTING });
        }
        case "UI_CALENDAR_GET_PREV_PAGE" /* UI_CALENDAR_GET_PREV_PAGE */: {
            return Object.assign({}, state, { status: SCREEN_STATUS.REQUESTING });
        }
        case "UI_CALENDAR_RECEIVED_HISTORY_CHUNK" /* UI_CALENDAR_RECEIVED_HISTORY_CHUNK */: {
            return Object.assign({}, state, { eop: action.eop ? action.eop : state.eop, sop: action.sop ? action.sop : state.sop, status: SCREEN_STATUS.IDLE, currentPage: (action.currentPage && action.currentPage.length) > 0
                    ? action.currentPage
                    : state.currentPage });
        }
        case "UI_CALENDAR_RESET_PAGING" /* UI_CALENDAR_RESET_PAGING */: {
            return Object.assign({}, state, { eop: 0, sop: null, currentPage: [], hash: '', status: SCREEN_STATUS.IDLE });
        }
        case actions.DESELECT_CHANNEL:
        case "RESET" /* RESET */:
            return INITIAL_STATE;
        default: {
            return state;
        }
    }
}
export { ɵ0 };
