import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AppClassActions from './appClass.actions';

import { AppState } from 'src/models/AppState';
import { LocalAppClass } from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class AppClassDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateAppClass(
    appClassRef: string,
    appClassUpdatedProps: LocalAppClass
  ): void {
    this._store.dispatch(
      new AppClassActions.UpdateAppClass(appClassRef, appClassUpdatedProps)
    );
  }

  notAllowedClassBgColors(): void {
    this._store.dispatch(new AppClassActions.NotAllowedCLassBgColor());
  }

  openClassSection(appClass: LocalAppClass): void {
    this._store.dispatch(new AppClassActions.OpenClassSection(appClass));
  }

  closeClassSection(appClass: LocalAppClass): void {
    this._store.dispatch(new AppClassActions.CloseClassSection(appClass));
  }
}
