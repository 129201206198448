import {
  Component,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SubChatsService } from '../../sub-chats/sub-chats.service';

import { SUBGROUPS, SUBCHANNELS } from 'src/models/AppSections';
import { PrivilegesName } from 'src/models/privileges';
import { IChat } from 'src/models/IChat';
import { ChatTypes, UISections } from 'src/models/constants';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { Router } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { MainChat } from 'src/models/MainChat';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';

@Component({
  selector: 'app-sub-chats-selector',
  templateUrl: './sub-chats-selector.component.html',
  styleUrls: ['./sub-chats-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubChatsSelectorComponent implements OnInit {
  @Input() type: string;
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectChatsNotfiy = new EventEmitter<IChat[]>();
  @Output() removedChatsNotfiy = new EventEmitter<string[]>();

  @Input() currentSelectChats: IChat[] = [];
  @Input() isPostScreen: boolean = false;
  showCompose = false;

  private _searchTerm = new BehaviorSubject<string>('');
  public searchTerm$ = this._searchTerm.asObservable();
  subGroupFet = SUBGROUPS;
  subChannelFet = SUBCHANNELS;

  chatTypes: typeof ChatTypes = ChatTypes;

  privilegesName: typeof PrivilegesName = PrivilegesName;
  selectedChats: IChat[] = [];
  selectedIndex: number;
  constructor(
    public router: Router,
    public subChatsService: SubChatsService,
    public dashboardService: DashboardService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.subChatsService.resetPaging(this.chatTypes.GROUP);
    this.subChatsService.resetPaging(this.chatTypes.CHANNEL);
    this.selectedChats = [...this.currentSelectChats];
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(prod: IChat) {
    this.selectedChats.push(prod);
  }

  setSelectMessages(prod: MainChat) {
    const messages = ChatMiddleware.getChatFromMainChat(prod);
    this.selectedChats.push(messages);
  }
  resetSelectMessage(prod: IChat) {
    this.selectedChats = this.selectedChats.filter(res => res.id !== prod.id);
  }
  isProductSelected(prodId: string) {
    return this.selectedChats.filter(res => res.id === prodId).length > 0;
  }

  insertEvent() {
    const removedChats = this.selectedChats
      .filter(
        res =>
          this.selectedChats &&
          this.selectedChats.length > 0 &&
          !this.selectedChats.find(item => item.id === res.id)
      )
      .map(res => res.id);

    if (removedChats) {
      this.removedChatsNotfiy.emit(removedChats);
    }
    this.selectChatsNotfiy.emit(this.selectedChats);
  }

  addNewEvent(e: boolean) {
    this.showCompose = e;
  }

  onSearchFormSubmit(term: string): void {
    this._searchTerm.next(term);
  }

  TrackByFunction(index, item: IChat) {
    return item.id;
  }

  selectedChanged(e) {
    this.selectedIndex = e;
  }

  createNewItem() {
    if (this.selectedIndex) {
      this.dashboardService.setSection(UISections.SUBGROUP);
      this.router.navigate(['/subgroups/form']);
    } else {
      this.dashboardService.setSection(UISections.SUBCHANNEL);
      this.router.navigate(['/subchannels/form']);
    }
  }

  createNewChannel() {
    this.dashboardService.setSection(UISections.SUBCHANNEL);
    this.router.navigate(['/subchannels/form']);
  }

  createNewGroup() {
    this.dashboardService.setSection(UISections.SUBGROUP);
    this.router.navigate(['/subgroups/form']);
  }
}
