import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from 'src/app/core/auth.service';
import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';

import * as AuthMethods from 'src/models/IAuth';
import { AppForm } from 'src/models/AppForm';
import {
  HtttpMethodErrors,
  SIGNUP_LINK,
  PRIVACY_LINK,
  TERMS_LINK
} from 'src/models/constants';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, AppForm {
  form: FormGroup;
  waiting = false;
  isPasswordForgot = false;

  signupLink = SIGNUP_LINK;
  privacyLink = PRIVACY_LINK;
  termsLink = TERMS_LINK;

  @Input() newEmail = '';
  @Output() qrScan = new EventEmitter<boolean>();

  @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;
  @ViewChild('loginRef', { static: false }) loginElement: ElementRef;
  auth2: any;
  show = false;

  constructor(
    private _validateForm: FormValidatorsService,
    private _ref: ChangeDetectorRef,
    private router: Router,
    public authService: AuthService,
    public appManagementService: AppMgmtService
  ) {}

  ngOnInit() {
    this.form = this.setFormGroup();
    this.rem.valueChanges.subscribe(res => this.selectRemEvent(res));
  }

  setFormGroup() {
    return new FormGroup({
      email: new FormControl(this.newEmail, [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required,
        noWhitespaceValidator
      ]),
      rem: new FormControl(this.authService.authRem)
    });
  }

  onSubmit() {
    this.waiting = true;
    this.authService._errorNotify.next(false);
    if (this.form.valid) {
      this.authService.loginByEmail(this.form.value).subscribe(response => {
        const res = <AuthMethods.HttpResult>response;

        if (res.result === 0) {
          if (res.su) {
            this.router.navigate(['/spt'], { queryParamsHandling: 'merge' });
          } else {
            this.authService.reciveAuthToken(res.token);
          }
        } else if (
          res.error === HtttpMethodErrors.AUTH_ERROR ||
          res.error === HtttpMethodErrors.AUTH_ERROR_2
        ) {
          this.password.setErrors({ wrongCredentials: true });
          this.waiting = false;
          this._ref.detectChanges();
        }
      });
    } else {
      this.waiting = false;
      this._validateForm.validateAllFormFields(this.form);
      this._ref.detectChanges();
    }
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }
  get rem() {
    return this.form.get('rem');
  }

  selectRemEvent(res: boolean) {
    this.authService.setRem(res);
  }

  logWithQr() {
    this.qrScan.emit(false);
  }
  toggleForgotPasswordScreenVisibility(e: boolean) {
    this.isPasswordForgot = e;
  }

  showNeedHelp(b: boolean) {
    // this.helpVideoVisibility = b;
    window.open('https://docs.nandbox.com/docs');
  }

  showPassword() {
    this.passwordInput.nativeElement.type = 'text';
  }
  hidePassword() {
    this.passwordInput.nativeElement.type = 'password';
  }
}
