import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { CollectionsPoolComponent } from './collections-pool/collections-pool.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    TranslateModule
  ],
  declarations: [CollectionsPoolComponent],
  exports: [CollectionsPoolComponent]
})
export class CollectionsPoolModule { }
