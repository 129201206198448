import {
  GET_APP_CONFIG_METHOD,
  SET_APP_CONFIG_METHOD,
  PUBLISH_APP_CONFIG_METHOD,
  SET_CHAT_DEFAULTS_METHOD,
  GET_APP_TEMPLATE,
  SET_APP_PROGRESS,
  GENERATE_APP_METHOD,
  GENERATE_APP_REF,
  GET_TAGS_VALUE_METHOD,
  TAB_TAGS_REF_APP,
  REVOKE_METHOD,
  APP_REVOKE_TYPES,
  SET_CHANNEL_CONFIG_METHOD,
  GET_APP_CONFIGS_HISTORY_METHOD,
  GET_APP_CONFIG_HISTORY_BY_ID_METHOD,
  CREATE_APP_TEMPLATE_METHOD,
  CREATE_APP_TEMPLATE_REF,
  GET_APP_CHANNEL_DATA_METHOD,
  SET_APP_CHANNEL_DATA_METHOD,
  SET_APP,
  SectionStyles,
  PaymentProviderIDs,
  UserData,
  SET_APP_MODE,
  AdsGoogleConfig,
  AdsGoogleIOSConfig,
  GET_APP_TEMPLATE_METHOD,
  SET_MSTORE_METHOD,
  GET_MSTORE_METHOD,
  GET_MSTORE_LIST_METHOD,
  DELETE_MSTORE_METHOD,
  SET_RULE_ENGINE_ACCESS
} from './constants';
import {
  OnlineChannel,
  OnlineApp,
  ChannelSplash,
  MapSearchButton,
  OnlineMenu,
  OnlineFooter
} from './ChannelAppOnlineConfig';
import {
  Ads,
  Option,
  LocalContainer,
  QRActions
} from './ChannelAppLocalConfig';
import { BotMenu } from 'src/models/IBotMenu';
import { ThirdPartyInfoUI } from './thirdPartyInfo';

export interface DataItem {
  title?: string;
  value?: string;
}

export interface Item {
  ref?: string;
  icon?: string;
  icon_highlight?: string;
  color_highlight?: string;
  icon_ios?: string;
  link?: string;
  title?: string;
  color?: string;
  bg?: string; // background
  readonly?: number; // 1 if true
  fixed?: number; // 1 if true
  limit?: number;
  ads?: Ads;
  google_sync?: number; // 0 or 1
  chat_id?: string;
  options?: string; // "0" or "1"
  sub_type?: string;
  url?: string;
  separator?: number; // "0" or "1"
  page_id?: string;
  template_id?: string;
  page_ref?: string;
  type?: string;
  view_style?: number; // 0 or 1 where "0 means list" and "1 means grid"
  data?: DataItem[];
  key?: string;
  ui_module_id?: string;
  map?: object;
  map_menu_id?: number;
  desc?: string;
  disabled?: boolean;
  video_url?: string;
  message?: string;
  nearby?: string;
  radius?: string;
  button?: MapSearchButton[];
  sub_item?: Item[];
  features_count?: number;
  menu_id?: string;
  store_id?: string;
  component?: Component[];
  image_url?: string;
  image_set?: ImageSet;
  text_color?: string;
  bg_color?: string;
  generic_qr?: QRActions;
  menu?: OnlineMenu[];
  show_invite_friends?: number;
  sub_pages?: any[];
  api_id?: string;
  sub_menus?: string[];
  layout?: string;
  id?: string;
  tab_bg?: TabBg;
  currency?: string;
  param?: WorkFlowParam;
}

export interface ImageSet {
  hdpi?: string;
  mdpi?: string;
  xhdpi?: string;
  xxhdpi?: string;
  xxxhdpi?: string;
  ios16_1x?: string;
  ios32_2x?: string;
  ios48_3x?: string;
  hdpi_rounded?: string;
  mdpi_rounded?: string;
  xhdpi_rounded?: string;
  xxhdpi_rounded?: string;
  xxxhdpi_rounded?: string;

  ios1x?: string;
  ios2x?: string;
  ios3x?: string;
  ios20_1x?: string;
  ios20_2x?: string;
  ios20_3x?: string;
  ios29_1x?: string;
  ios29_2x?: string;
  ios29_3x?: string;
  ios40_2x?: string;
  ios40_3x?: string;
  ios60_3x?: string;
  ios76_1x?: string;
  ios76_2x?: string;
  ios83_2x?: string;
  ios150_1x?: string;
  ios300_2x?: string;
  ios450_3x?: string;
}

export interface Component {
  ref?: string;
  component_type?: string;
  color?: string;
  secondary_color?: string;
  bg?: string;
  type?: string | number[];
  icon?: string;
  icon_color?: string;
  image?: string;
  title?: string;
  subtitle?: string;
  min_items?: number;
  max_items?: number;
  opened?: number; // 1 if true
  image_set?: ImageSet;
  hidden?: number;
  desc?: string;
  target?: number;
  image_url?: string;
  wv_url?: string;
  column?: number;
  sticky_id?: number;
  sticky_bg?: string;
  sticky_text_color?: string;
  sticky_visible?: number;
  bg_image?: string;
  text_color?: string;
  progress_earned_color?: string;
  progress_target_color?: string;
  search_btn_bg?: string;
  search_btn_icon?: string;
  search_btn_icon_color?: string;
  hide_search_keyword?: number;
  search_text_hint?: string;
  search_by_hint?: string;
  search_text_lines_count?: number;
  key?: string;
  url?: string;
  nearby?: string;
  radius?: string;
  item?: Item[];
  filter?: string[];
  search_by_date?: boolean;
  search_by_time?: boolean;
  options?: string;
}

export interface Sequence {
  feed?: number;
  membership?: number;
  wallet?: number;
  web_view?: number;
  channel?: number;
  chat?: number;
  settings?: number;
  calendar?: number;
  booking?: number;
  invitation?: number;
  open_chat?: number;
  mylist?: number;
  qr?: number;
  map?: number;
  search?: number;
  call_log?: number;
  section?: number;
  bundle?: number;
  package?: number;
  video?: number;
  qr_page?: number;
  map_search?: number;
}

export interface Container {
  ref?: string;
  parent_tab_ref?: string;
  container_type?: string;
  type?: string;
  icon?: string;
  title?: string;
  icon_ios?: string;
  title_ios?: string;
  id?: string;
  color?: string;
  bg?: string;
  min_components?: number;
  max_components?: number;
  floating_button?: any[];
  access?: number;
  talk_admin_tag?: string;
  post_tag?: string;
  talk_admin_tag_values?: string[];
  post_tag_values?: string[];
  image_set?: ImageSet;
  desc?: string;
  link?: string;
  link_color?: string;
  policy_url?: string;
  default?: string;
  required?: number; // 1 if required
  // new fields
  options?: string;
  sub_type?: string;
  chat_id?: string;
  google_sync?: number; // 0 or 1
  limit?: number;
  ads?: Ads;

  // map fields
  map?: object;
  map_menu_id?: number;
  url?: string;
  page_id?: string;
  template_id?: string;
  page_ref?: string;
  list?: string[];
  view_style?: number; // 0 or 1 where "0 means list" and "1 means grid"
  is_on?: number;
  key?: string;
  image_url?: string;
  section_style?: SectionStyles;
  ui_module_id?: string;
  video_url?: string;
  message?: string;
  component?: Component[];
  features_count?: number;
  menu_id?: string;
  store_id?: string;
  error?: string;
  min?: number;
  max?: number;
  hint?: string;
  items?: Option[];
  lines?: number;
  exclude?: number;
  layout?: string;
  text_color?: string;
  bg_color?: string;
  generic_qr?: QRActions;
  menu?: OnlineMenu[];
  show_invite_friends?: number;
  api_id?: string;
  sub_menus?: string[];
  sub_pages?: string[];
  tab_bg?: TabBg;
  currency?: string;
  param?: WorkFlowParam;
}

export interface TabBg {
  image?: string;
  start?: string;
  end?: string;
}
export interface WorkFlowParam {
  id?: string;
  function?: string;
}

export interface DefaultClassConfig {
  message?: string;
}

export interface AppClass {
  ref?: string;
  color?: string;
  icon_color?: string;
  icon_color_ios?: string;
  color_ios?: string;
  bg?: string;
  type?: string;
  default?: DefaultClassConfig;
  min_containers?: number;
  max_containers?: number;
  sequence?: Sequence;
  opened?: number; // 1 if true
  talk_admin_bg?: string;
  profile_visible?: number; // 1 if true
  splash?: ChannelSplash;
  container?: Container[];
  signup?: DynamicForm;
  menus?: BotMenu[];
  allow_cart?: number;
  generic_qr?: QRActions;
  footer?: OnlineFooter;
  menu?: OnlineMenu[];
  floating_button?: any;
}

export interface AppColors {
  primary?: string;
  primary_light?: string;
  primary_dark?: string;
  secondary?: string;
  secondary_light?: string;
  secondary_dark?: string;
  primary_text?: string;
  primary_text_inverted?: string;
  primary_bg?: string;
  primary_icon_fill?: string;
  tool_bar_primary_text?: string;
  tool_bar_secondary_text?: string;
  side_menu_icon?: string;
  burger_icon_color?: string;
  app_name_color?: string;
}
export interface MoreButton {
  android_title: string;
  ios_title: string;
  android_icon: string;
  ios_icon: string;
  burger_icon_color;
}
export interface AppInfo {
  app_long_name?: string;
  company_name?: string;
  company_official_name?: string;
  app_dl_url?: string;
  channel_name?: string;
  image_id?: string;
  image?: string;
  white_logo_url?: string;
  ios_store_url?: string;
  logo_color?: ImageSet;
  logo_color_ios?: ImageSet;
  dominant_color?: string;
  logo_white?: ImageSet;
  login_type?: string;
  login_tags?: string;
  login_tags_values?: string[];
  call_enabled?: number; // 0 or 1 , 1 means enable voice calls in the generated app
  video_enabled?: number; // 0 or 1 , 1 means enable video and voice calls in the generated app
  hide_module_added_msg?: number;
  ads_config?: AdsGoogleConfig;
  ads_config_ios?: AdsGoogleIOSConfig;
  bubble_theme?: string;
  color_logo_bg?: string;
  payment_provider?: any;
  same_tone?: number; // 0 or 1 , 1 means use same tone of Ringing while calling
  appTheme?: number;
  demo_video?: number;
  ads_provider?: string;
  mob_ver?: number;
  layout?: string;
  hide_side_menu?: number;
  public_links_hidden?: boolean;
  allow_showing_app_details?: boolean;
  show_submitting_app_review?: boolean;
  supportEmail?: string;
  hide_change_login_account?: number;
  help_link?: number;
  menuTabs?: any[];
  demoAccData?: any;
  vendors?: ThirdPartyInfoUI[];
  tempId?: any;
  company_url?: string;
  hide_profile?: number;
  ios_more_icon?: string;
  ios_more_title?: string;
  lang?: any[];
  onboarding_style?: string;
  deep_link_schema?: string;
}

export interface ChannelDefautlt {
  footer?: OnlineFooter;
  menu?: OnlineMenu[];
  floating_button?: any;
}
export interface Posts {
  message?: string;
  url?: string;
}

export interface SystemConfig {
  color?: AppColors;
  app_info?: AppInfo;
  posts?: Posts;
}

export interface AppConfig {
  system?: SystemConfig;
  nav_menu?: AppClass;
  tabs?: AppClass;
  sections?: AppClass;
  search_category?: AppClass;
  splash?: AppClass;
  forms?: AppClass;
  settings?: AppClass;
  profile?: AppClass;
  channel_default?: AppClass;
  menus?: AppClass;
}

export interface App {
  app?: AppConfig;
}

export interface WholeApp {
  appConfig?: App;
  channelAppConfig?: OnlineChannel;
  onlineAppConfig?: OnlineApp;
}

export interface AppConfigHistoryRecord {
  createdDate?: string;
  id?: string;
  nandboxCoreVersion?: string;
  track?: number;
  user?: string;
  version_name?: string;
  version_desc?: string;
}

export interface DynamicForm {
  id?: string;
  fields?: LocalContainer[];
}
// export interface RcvdAppConfig {
//   appConfig: App;
// }
export interface DynamicForms {
  signup?: DynamicForm;
}
export class GetConfigRequestBody {
  readonly method = GET_APP_CONFIG_METHOD;
  constructor(public reference: string) {}
}
export class SetAppUserData {
  readonly method = SET_APP;
  constructor(public user_data?: UserData, public template_id?: string) {}
}

export class GetAppConfigsHistory {
  readonly method = GET_APP_CONFIGS_HISTORY_METHOD;
  constructor() {}
}

export class GetAppConfigHistoryByID {
  readonly method = GET_APP_CONFIG_HISTORY_BY_ID_METHOD;
  constructor(public id: string) {}
}

export class SetConfigRequestBody {
  readonly method = SET_APP_CONFIG_METHOD;
  constructor(
    public reference: string,
    public appConfig: App,
    public onlineChannelConfigStaging: OnlineChannel,
    public onlineChannelConfigWeb: App,
    public onlineAppConfig: OnlineApp,
    public modules?: string[],
    public dashboard?: any
  ) {}
}

export class SetRuleEngineAccess {
  readonly method = SET_RULE_ENGINE_ACCESS;
  constructor() {}
}

export class SetInAppPurchases {
  readonly method = SET_APP_CONFIG_METHOD;
  constructor(public google_sc_json: string) {}
}

export class CreateAppTemplate {
  readonly method = CREATE_APP_TEMPLATE_METHOD;
  readonly reference = CREATE_APP_TEMPLATE_REF;
  constructor(
    public appConfig: App,
    public onlineChannelConfigWeb: App,
    public android_image_url: string,
    public ios_image_url: string,
    public category: string[],
    public name: string,
    public order: number,
    public template: any,
    public id?: string
  ) {}
}

export class GetAppTemplateMeta {
  readonly method = GET_APP_TEMPLATE_METHOD;
  constructor(public template_id: any) {}
}

export class SetChannelConfigRequest {
  readonly method = SET_APP_CONFIG_METHOD;
  constructor(
    public appConfig: App,
    public onlineChannelConfigStaging: OnlineChannel,
    public onlineChannelConfigWeb: App,
    public modules?: string[]
  ) {}
}

export class PublishAppConfig {
  readonly method = PUBLISH_APP_CONFIG_METHOD;
  constructor(
    public reference: string,
    public track: number,
    public version_name?: string,
    public version_desc?: string
  ) {}
}

export class SetMStore {
  readonly method = SET_MSTORE_METHOD;
  constructor(
    public menu_id: string,
    public currency?: string,
    public shop?: any,
    public id?: string,
    public is_default?: number
  ) {}
}

export class SetMStoreWithImage {
  readonly method = SET_MSTORE_METHOD;
  constructor(
    public menu_id: string,
    public currency?: string,
    public shop?: any,
    public image?: any,
    public name?: string,
    public description?: string,
    public id?: string,
    public is_default?: number
  ) {}
}
export class DeleteMStore {
  readonly method = DELETE_MSTORE_METHOD;
  constructor(public store_id: string[]) {}
}

export class GetDefaultMStore {
  readonly method = GET_MSTORE_METHOD;
  constructor(public id: string) {}
}

export class GetMStoreList {
  readonly method = GET_MSTORE_LIST_METHOD;
  constructor(public eop?: number, direction?: number) {}
}

export class RevokeApp {
  readonly method = REVOKE_METHOD;
  constructor(public type: APP_REVOKE_TYPES, public reference?: string) {}
}

export class GenerateApp {
  readonly method = GENERATE_APP_METHOD;
  readonly reference = GENERATE_APP_REF;
  constructor(
    public app_id: string,
    public os: number,
    public version_name?: string,
    public version_desc?: string,
    public android_format?: string
  ) {}
}

export class GetAppTemplate {
  readonly method = GET_APP_TEMPLATE;
  constructor(public template_id: string, public reference?: string) {}
}

export class SetAppProgress {
  readonly method = SET_APP_PROGRESS;
  constructor(public progressWeb: string, public mode: number) {}
}

export class SetAppMode {
  readonly method = SET_APP_MODE;
  constructor(public mode: number) {}
}

export interface TabKey {
  tab?: string;
  key?: string;
  value?: string;
}

export interface TagItem {
  id?: string;
  type?: string;
  value?: string[];
}

export interface TabTagItem {
  id?: string;
  type?: string;
  value?: string;
}

export interface ChannelData {
  id?: string; // database id
  key?: string; // area
  list?: string[]; // ["Cairo","Alex"]
  title?: string; // Region
  icon?: string; // icon name
}

export class SetChatDefaults {
  readonly method = SET_CHAT_DEFAULTS_METHOD;
  constructor(public values: TabKey[]) {}
}

export class GetTagsValues {
  readonly method = GET_TAGS_VALUE_METHOD;
  readonly reference = TAB_TAGS_REF_APP;
  constructor(public tags: TagItem[]) {}
}

export class RequestAppChannelData {
  readonly method = GET_APP_CHANNEL_DATA_METHOD;
  constructor(public keys: string[]) {}
}

export class SetAppChannelDataMethod {
  readonly method = SET_APP_CHANNEL_DATA_METHOD;
  constructor(public values: ChannelData[]) {}
}
