import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractTablesModule } from 'src/app/abstract-tables/abstract-tables.module';

import { AbstractUploadModule } from 'src/app/abstract-upload/abstract-upload.module';
import { MenusPoolModule } from '../../menus-pool/menus-pool.module';
import { MenuFormComponent } from './menu-form.component';

import { MenusRowsComponent } from '../menus-rows/menus-rows.component';
import { MenuRowItemComponent } from '../menu-row-item/menu-row-item.component';
import { AbstractNotifiersModule } from 'src/app/abstract-notifiers/abstract-notifiers.module';
import { CreatedMenuItemComponent } from '../created-menus-view/created-menu-item/created-menu-item.component';
import { CreatedMenusViewComponent } from '../created-menus-view/created-menus-view.component';
import { MenusIconsConfComponent } from '../menus-icons-conf/menus-icons-conf.component';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { MenusMobViewComponent } from '../menus-mob-view/menus-mob-view.component';
import { MenusMobComponent } from '../menus-mob-view/menus-mob/menus-mob.component';
import { MenusPagesNormalBarComponent } from '../menus-mob-view/menus-pages-normal-bar/menus-pages-normal-bar.component';
import { MenusStatusBarComponent } from '../menus-mob-view/menus-status-bar/menus-status-bar.component';
import { MenusMobTypComponent } from '../menus-mob-view/menus-mob-typ/menus-mob-typ.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PageBuilderConfModule } from '../../app-mgmt/custom/custom-area/page-builder-conf/page-builder-conf.module';
import { MediaCenterModule } from 'src/app/media-center/media-center.module';
import { MainMenuViewComponent } from '../../app-mgmt/custom/mobile-view/main-menu-view/main-menu-view.component';
import { AbstractTooltip } from 'src/app/abstract-tooltip/abstract-tooltip.module';
import { CollectionsPoolModule } from '../../collections-pool/collections-pool.module';
@NgModule({
  imports: [
    CommonModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractTablesModule,
    ReactiveFormsModule,
    ColorPickerModule,
    CollectionsPoolModule,
    SharedModule,
    NgxModule,
    MenusPoolModule,
    AbstractUploadModule,
    AbstractNotifiersModule,
    AbstractControlsModule,
    AngularSvgIconModule,
    PageBuilderConfModule,
    MediaCenterModule,
    AbstractTooltip
  ],
  declarations: [
    MenuFormComponent,
    MenusRowsComponent,
    MenuRowItemComponent,
    CreatedMenuItemComponent,
    CreatedMenusViewComponent,
    MenusIconsConfComponent,
    MenusMobViewComponent,
    MenusMobComponent,
    MenusPagesNormalBarComponent,
    MenusStatusBarComponent,
    MenusMobTypComponent,
    MainMenuViewComponent
  ],

  exports: [
    MenuFormComponent,
    MenusRowsComponent,
    MenuRowItemComponent,
    CreatedMenuItemComponent,
    CreatedMenusViewComponent,
    MenusMobViewComponent,
    MenusMobComponent,
    MenusPagesNormalBarComponent,
    MenusStatusBarComponent,
    MenusMobTypComponent,
    MainMenuViewComponent
  ]
})
export class MenuFormModule {}
