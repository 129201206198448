import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapTripUserActions from './mapTripUser.actions';

import { AppState } from 'src/models/AppState';
import { MapTripUser } from 'src/models/MapTripUser';

@Injectable({
  providedIn: 'root'
})
export class MapTripUserDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetMapTripUsersPaging(tripID: string) {
    this._store.dispatch(
      new MapTripUserActions.ResetMapTripUsersPaging(tripID)
    );
  }

  getNextMapTripUsersPage() {
    this._store.dispatch(new MapTripUserActions.GetNextMapTripUsersPage());
  }
  getPrevMapTripUsersPage() {
    this._store.dispatch(new MapTripUserActions.GetPrevMapTripUsersPage());
  }

  createMapTripUser(mapTripUser: MapTripUser): void {
    this._store.dispatch(new MapTripUserActions.CreateMapTripUser(mapTripUser));
  }

  mapTripUserCreated(mapTripUser: MapTripUser): void {
    this._store.dispatch(
      new MapTripUserActions.MapTripUserCreated(mapTripUser)
    );
  }

  updateMapTripUser(id: string, mapTripUser: MapTripUser): void {
    this._store.dispatch(
      new MapTripUserActions.UpdateMapTripUser(id, mapTripUser)
    );
  }

  mapTripUserDetailsReceived(mapTripUser: MapTripUser): void {
    this._store.dispatch(
      new MapTripUserActions.MapTripUserDetailsReceived(mapTripUser)
    );
  }

  mapTripUserListReceived(
    eop: number,
    sop: number,
    mapTripUsers: MapTripUser[],
    hash: string
  ): void {
    this._store.dispatch(
      new MapTripUserActions.MapTripUserListReceived(
        eop,
        sop,
        mapTripUsers,
        hash
      )
    );
  }

  deleteMapTripUser(id: string, trip_id: string): void {
    this._store.dispatch(new MapTripUserActions.DeleteMapTripUser(id, trip_id));
  }

  mapTripUserDeleted(id: string): void {
    this._store.dispatch(new MapTripUserActions.MapTripUserDeleted(id));
  }
}
