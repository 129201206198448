import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { MapServiceActionTypes } from './mapService.actions';
import * as MapActions from './mapService.actions';
import * as Methods from 'src/models/MapService';
import { MapService } from 'src/models/MapService';
import { MapServiceDispatchers } from './mapService.dispatchers';
import { AppState } from 'src/models/AppState';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';

@Injectable()
export class MapServiceEffects {
  @Effect({ dispatch: false })
  resetMapService = this.actions$.pipe(
    ofType(MapServiceActionTypes.RESET_MAP_SERVICE_PAGING),
    map((action: MapActions.ResetMapServicePaging) => {
      this._mapServiceDispatchers.getNextMapService();
    })
  );

  @Effect({ dispatch: false })
  getNextMapServicePage = this.actions$.pipe(
    ofType(MapServiceActionTypes.GET_NEXT_MAP_SERVICE_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapServiceReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapServiceUiState]) => {
      const action = <MapActions.GetNextMapServicePage>val;
      const prevRequest = mapServiceUiState.previousRequest;
      if (
        !(
          mapServiceUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapServiceUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapServices(
            0,
            mapServiceUiState.eop,
            mapServiceUiState.hash
          )
        );
      } else {
        this._mapServiceDispatchers.setStatusAsIdle();
      }
    })
  );
  @Effect({ dispatch: false })
  getPrevMapServicePage = this.actions$.pipe(
    ofType(MapServiceActionTypes.GET_PREV_MAP_SERVICE_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapServiceReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapServiceUiState]) => {
      const action = <MapActions.GetPrevMapServicePage>val;
      const prevRequest = mapServiceUiState.previousRequest;
      if (
        !(
          mapServiceUiState.eop === prevRequest.eop &&
          PageDirection.PREV === prevRequest.direction &&
          mapServiceUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapServices(
            1,
            mapServiceUiState.sop,
            mapServiceUiState.hash
          )
        );
      } else {
        this._mapServiceDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  createMapService = this.actions$.pipe(
    ofType(MapServiceActionTypes.CREATE_MAP_SERVICE),
    map((action: MapActions.CreateMapService) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapService(action.mapService)
      );
    })
  );
  @Effect({ dispatch: false })
  getMapServiceByID = this.actions$.pipe(
    ofType(MapServiceActionTypes.GET_MAP_SERVICE_BY_ID),
    map((action: MapActions.GetMapServiceByID) => {
      this._socketGateway.sendSocketMessage(
        new Methods.GetMapService(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  updateMapService = this.actions$.pipe(
    ofType(MapServiceActionTypes.UPDATE_MAP_SERVICE),
    map((action: MapActions.UpdateMapService) => {
      const mapService: MapService = {
        ...action.mapServiceDetails,
        id: action.id
      };
      this._socketGateway.sendSocketMessage(
        new Methods.SetMapService(mapService)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMapService = this.actions$.pipe(
    ofType(MapServiceActionTypes.DELETE_MAP_SERVICE),
    map((action: MapActions.DeleteMapService) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveMapService(action.id)
      );
    })
  );

  /** No need for the following effect because of all the data comes with the list */

  // @Effect({ dispatch: false })
  // listReceived = this.actions$.pipe(
  //   ofType(MapServiceActionTypes.MAP_SERVICE_LIST_RECEIVED),
  //   map((action: MapActions.MapServiceListReceived) => {
  //     if (action.mapServices && action.mapServices.length > 0) {
  //       action.mapServices.forEach(service => {
  //         this._socketGateway.sendSocketMessage(
  //           new Methods.GetMapService(service.id)
  //         );
  //       });
  //     }
  //   })
  // );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _mapServiceDispatchers: MapServiceDispatchers,
    private _store: Store<AppState>
  ) {}
}
