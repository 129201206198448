import { Action } from '@ngrx/store';
import { Bundle, BundleProduct } from 'src/models/Bundle';
import { Product } from 'src/models/Product';
import { PayCashResponse } from 'src/models/PayCash';

export const enum BundlesUIActionTypes {
  CREATE_BUNDLE = 'CREATE_BUNDLE',
  SET_BUNDLE = 'SET_BUNDLE',
  ADD_BUNDLE_PRODUCTS = 'ADD_BUNDLE_PRODUCTS',
  REMOVE_BUNDLE_PRODUCTS = 'REMOVE_BUNDLE_PRODUCTS',
  DELETE_BUNDLE = 'DELETE_BUNDLE',
  BUNDLE_DELETED = 'BUNDLE_DELETED',
  BUNDLE_PRODUCT_INFO_RECEIVED = 'BUNDLE_PRODUCT_INFO_RECEIVED',
  BUNDLE_RECEIVED = 'BUNDLE_RECEIVED',
  //
  UI_BUNDLES_GET_NEXT_PAGE = 'UI_BUNDLES_GET_NEXT_PAGE',
  UI_BUNDLES_GET_PREV_PAGE = 'UI_BUNDLES_GET_PREV_PAGE',
  UI_BUNDLES_RECEIVED_HISTORY_CHUNK = 'UI_BUNDLES_RECEIVED_HISTORY_CHUNK',
  UI_BUNDLES_RESET_PAGING = 'UI_BUNDLES_RESET_PAGING',
  UI_BUNDLES_SET_IDLE_STATUS = 'UI_BUNDLES_SET_IDLE_STATUS',
  BUY_BUNDLE_ON_BEHALF_OF_USER = 'BUY_BUNDLE_ON_BEHALF_OF_USER',
  CASH_PAYMENT_STATUS = 'CASH_PAYMENT_STATUS',
  RESET = 'RESET'
}

export class CreateBundle implements Action {
  readonly type = BundlesUIActionTypes.CREATE_BUNDLE;
  constructor(public bundle: Bundle) {}
}

export class SetBundle implements Action {
  readonly type = BundlesUIActionTypes.SET_BUNDLE;
  constructor(public bundleID: string, public bundleDetails: Bundle) {}
}

export class AddBundleProducts implements Action {
  readonly type = BundlesUIActionTypes.ADD_BUNDLE_PRODUCTS;
  constructor(
    public bundleID: string,
    public bundleProducts: BundleProduct[]
  ) {}
}

export class BundleProductInfoReceived implements Action {
  readonly type = BundlesUIActionTypes.BUNDLE_PRODUCT_INFO_RECEIVED;
  constructor(public bundleID: string, public product: Product) {}
}

export class RemoveBundleProducts implements Action {
  readonly type = BundlesUIActionTypes.REMOVE_BUNDLE_PRODUCTS;
  constructor(public bundleID: string, public productsIDs: string[]) {}
}

export class DeleteBundle implements Action {
  readonly type = BundlesUIActionTypes.DELETE_BUNDLE;
  constructor(public bundleID: string) {}
}

export class BundleDeleted implements Action {
  readonly type = BundlesUIActionTypes.BUNDLE_DELETED;
  constructor(public id: string) {}
}

export class BundleReceived implements Action {
  readonly type = BundlesUIActionTypes.BUNDLE_RECEIVED;
  constructor(public bundle: Bundle) {}
}

// Paging Actions

export class GetNextBundlesPage implements Action {
  readonly type = BundlesUIActionTypes.UI_BUNDLES_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevBundlesPage implements Action {
  readonly type = BundlesUIActionTypes.UI_BUNDLES_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedBundlesHistoryChunk implements Action {
  readonly type = BundlesUIActionTypes.UI_BUNDLES_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: Bundle[]
  ) {}
}

export class ResetBundlesPaging implements Action {
  readonly type = BundlesUIActionTypes.UI_BUNDLES_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = BundlesUIActionTypes.UI_BUNDLES_SET_IDLE_STATUS;
  constructor() {}
}

export class BuyBundleOnBehalfOfUser implements Action {
  readonly type = BundlesUIActionTypes.BUY_BUNDLE_ON_BEHALF_OF_USER;
  constructor(public userId: string, public bundle: Bundle) {}
}

export class CashPaymentStatus implements Action {
  readonly type = BundlesUIActionTypes.CASH_PAYMENT_STATUS;
  constructor(public payResult: PayCashResponse) {}
}

export class Reset implements Action {
  readonly type = BundlesUIActionTypes.RESET;
}

export type Actions =
  | CreateBundle
  | SetBundle
  | AddBundleProducts
  | RemoveBundleProducts
  | BundleReceived
  | GetNextBundlesPage
  | GetPrevBundlesPage
  | ReceivedBundlesHistoryChunk
  | ResetBundlesPaging
  | SetStatusAsIdle
  | BundleProductInfoReceived
  | BuyBundleOnBehalfOfUser
  | CashPaymentStatus
  | DeleteBundle
  | BundleDeleted
  | Reset;
