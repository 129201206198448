import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { MapTripHistoryDispatchers } from './mapTripsHistory.dispatchers';
import { PageDirection } from 'src/models/constants';
import * as Methods from 'src/models/MapTripsHistory';
export class MapTripsHistoryEffects {
    constructor(actions$, _socketGateway, _mapTripHistoryDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._mapTripHistoryDispatchers = _mapTripHistoryDispatchers;
        this._store = _store;
        this.resetMapTripsHistoryPaging = this.actions$.pipe(ofType("RESET_MAP_TRIPS_HISTORY_PAGING" /* RESET_MAP_TRIPS_HISTORY_PAGING */), map((action) => {
            this._mapTripHistoryDispatchers.getNextMapTripsHistoryPage();
        }));
        this.getNextMapTripsHistoryPaging = this.actions$.pipe(ofType("GET_NEXT_MAP_TRIPS_HISTORY_PAGE" /* GET_NEXT_MAP_TRIPS_HISTORY_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripsHistoryReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsHistory]) => {
            const action = val;
            const prevRequest = mapTripsHistory.previousRequest;
            if (!(mapTripsHistory.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapTripsHistory.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTripsHistory(0, mapTripsHistory.eop, mapTripsHistory.hash, mapTripsHistory.startDate, mapTripsHistory.endDate));
            }
            else {
                this._mapTripHistoryDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMapTripsHistoryPaging = this.actions$.pipe(ofType("GET_PREV_MAP_TRIPS_HISTORY_PAGE" /* GET_PREV_MAP_TRIPS_HISTORY_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripsHistoryReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsHistory]) => {
            const action = val;
            const prevRequest = mapTripsHistory.previousRequest;
            if (!(mapTripsHistory.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapTripsHistory.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTripsHistory(1, mapTripsHistory.sop, mapTripsHistory.hash, mapTripsHistory.startDate, mapTripsHistory.endDate));
            }
            else {
                this._mapTripHistoryDispatchers.setStatusAsIdle();
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsHistoryEffects.prototype, "resetMapTripsHistoryPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsHistoryEffects.prototype, "getNextMapTripsHistoryPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripsHistoryEffects.prototype, "getPrevMapTripsHistoryPaging", void 0);
