import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input
} from '@angular/core';

import { AppMgmtService } from './appMgmt.service';
import { AuthService } from 'src/app/core/auth.service';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { AppService } from 'src/app/app.service';
import { UiService } from 'src/app/core/ui.service';
import { Router } from '@angular/router';
import { LayoutService } from '../../+layout/layout.service';
import { DashboardService } from '../dashboard.service';
import { ThemeStatus } from 'src/models/constants';
import { BotMenu } from 'src/models/IBotMenu';
import { AppClass } from 'src/models/ChannelAppConfig';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { PaymentsService } from '../payments/payments.service';
import { SubscriptionService } from './../../../subscription/subscription.service';
import { EventsService } from 'src/app/+merchant/+dashboard/events/events.service';
import { CalendarsService } from './../calendars/calendars.service';
import { MobileStoresService } from 'src/app/+merchant/+dashboard/mobile-store/mobile-store.service';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-mgmt',
  templateUrl: './app-mgmt.component.html',
  styleUrls: ['./app-mgmt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMgmtComponent implements OnInit, OnDestroy {
  showSettings: boolean;
  hideSettingsMenu: boolean;
  anotherPage: boolean;
  private ui = null;
  private menus = null;
  private payment = null;
  private firstTab = null;
  @Input() progressWeb: any;
  currentPage: any;
  @Input() appInfo: any;
  currentFeature: any;
  currentMenu: BotMenu;
  menuIdex: number;
  themeStatus = ThemeStatus;
  startOverFirstValidation = false;
  @Input() menuApp: AppClass;

  toBeEdited = true;
  uploading = false;
  private auth = null;
  private progress = null;
  // layOut = new LayoutComponent(
  //   this.dashboardService,
  //   this.uiService,
  //   this.layoutService,
  //   this.authService,
  //   this.appManagementService,
  //   this._ref,
  //   this._uiDispatchers,
  //   this.eventService,
  //   this.calendarSerice,
  //   this._router,
  //   this._selectors,
  //   this.appServer,
  //   this.subscriptionService,
  //   this.paymentService
  // );

  sideMenuOpen: boolean;
  startOverPage: number;
  cutomizeMore = false;
  showPaymentMsg = false;
  plan4 = [
    {
      id: 'plan_4',
      name: 'free plan',
      title: 'free plan',
      tagline: 'Get your first users onboard',
      price: 'free',
      options: ['free', '500 Users', '5 GB Storage', '2 GB Bandwidth/month'],
      brief:
        'Native Android & iOS Apps,Unlimited Push Notifications,Unlimited Instant Messages,Voice & Video Calling,Mobile Stores,Booking and Ticketing,Events Management,User Profiles & Tags,Bulk Uploads,Groups & Channels,Coupons & Loyalty Wallet,Mobile & Social Media Log-In,10 Month Messages Retention',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    },
    {
      id: 'plan_4',
      name: 'free plan',
      title: 'free plan',
      tagline: 'Get your first users onboard',
      price: 'free',
      options: ['free', '500 Users', '5 GB Storage', '2 GB Bandwidth/month'],
      brief:
        'Native Android & iOS Apps,Unlimited Push Notifications,Unlimited Instant Messages,Voice & Video Calling,Mobile Stores,Booking and Ticketing,Events Management,User Profiles & Tags,Bulk Uploads,Groups & Channels,Coupons & Loyalty Wallet,Mobile & Social Media Log-In,10 Month Messages Retention',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    }
  ];
  constructor(
    public layoutService: LayoutService,
    private _ref: ChangeDetectorRef,
    private _selectors: RootStoreSelectors,
    public appManagementService: AppMgmtService,
    public authService: AuthService,
    public router: Router,
    public uiService: UiService,
    public appServer: AppService,
    private dashboardService: DashboardService,
    private _uiDispatchers: UIDispatchers,
    public paymentService: PaymentsService,
    public eventService: EventsService,
    public calendarSerice: CalendarsService,
    public subscriptionService: SubscriptionService,
    public _router: Router,
    public mobileWorkFlowStoreService: MobileWorkFlowStoreService
  ) {}
  ngOnDestroy() {
    if (this.ui) {
      this.ui.unsubscribe();
    }
    if (this.menus) {
      this.menus.unsubscribe();
    }
    if (this.payment) {
      this.payment.unsubscribe();
    }
    if (this.auth) {
      this.auth.unsubscribe();
    }

    if (this.appInfo) {
      this.appInfo.unsubscribe();
    }
    if (this.firstTab) {
      this.firstTab.unsubscribe();
    }
  }

  ngOnInit() {
    this.auth = this.authService.authCollection$.subscribe(res => {
      if (this.appInfo && this.appInfo.image_id && res.appConfig) {
        this.appManagementService.uploadTempImageToProfile(
          this.appInfo.image_id
        );
      }

      this.progressWeb = res.progressWeb;
      this.currentPage = this.progressWeb;
    });
    // this.payment = this.subscriptionService.paymentStatus$.subscribe(res => {
    //   if (!res.currentPlan && !res.currentPlanStatus) {
    //     this.subscriptionService.selectFreePayment(this.plan4[0]);
    //   }
    // });
    this.menuIdex = 0;
    this.menus = this.appManagementService.menusAppClass$.subscribe(res => {
      if (res && res.menus && res.menus.length > 0) {
        this.currentMenu = res.menus[this.menuIdex];
      } else {
        this.currentMenu = {};
      }
    });
    this._ref.detectChanges();
    this.ui = this.uiService.selectedChat$.subscribe(res => {
      if (res && res.subChannel) {
        this.authService.updateProgressWeb('2', 2);
      }
    });
    this.hideSettingsMenu = false;
    this.showSettings = false;

    this.appManagementService.selectFirstTab();
    this.appManagementService._createNewTab.next(false);
    this.appManagementService.updateTotalAppConfFlag(true);
    this.appManagementService.updatePublishButtonFlag(false);
  }

  pageChanged(event) {
    this.showSettings = event;
    this.anotherPage = true;
    if (event) {
      this.hideSettingsMenu = true;
    } else {
      this.hideSettingsMenu = false;
    }

    this._ref.detectChanges();
  }

  hideAndShowMenu(event) {
    this.anotherPage = false;
    if (!event) {
      this.showSettings = false;
    } else {
      this.showSettings = true;
    }

    this.appManagementService._showSetting.next(this.showSettings);
    this._ref.detectChanges();
  }

  cutomize() {
    this.authService.updateProgressWeb('0', 2);
  }

  setStartOverPage(page) {
    this.startOverPage = page;
  }
  menuToEdit(event) {
    if (!this.uploading) {
      this.toBeEdited = true;
      this.currentMenu = event;
      this._ref.detectChanges();
    } else {
      this._uiDispatchers.showPopup('Pleas wait till image Uploaded');
    }
  }
  edit(event) {
    this.toBeEdited = event;

    this._ref.detectChanges();
  }
  uploadImage(event) {
    this.uploading = event;
    this._ref.detectChanges();
  }

  closePayment() {
    this.showPaymentMsg = !this.showPaymentMsg;
  }
  createPayment() {
    this.paymentService.createStripConnectionSubChannel();
    this.closePayment();
  }

  mainFeature(event) {
    this.currentFeature = event;
    this._ref.detectChanges();
  }

  startOver() {
    this.startOverFirstValidation = true;
    this._ref.detectChanges();
  }
  showConfirmation() {
    this.startOverFirstValidation = false;
    this._ref.detectChanges();
  }
  secConfirmation() {
    this._router.navigate(['/signup'], { queryParamsHandling: 'merge' });
  }
  selectTab() {
    this.appManagementService.selectLastTab();
  }
}
