import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { ChatMemberMiddleware } from './chatMember.middleware';
import { ChatMemberDispatchers } from './chatMember.dispatchers';
import { ProfileDispatchers } from '../profile/profile.dispatchers';
import * as ProfileMethods from 'src/models/IProfile';
import * as ChatMemberMethods from 'src/models/IChatMember';
import { UserStatus, UserType } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
import { GetMyMember } from 'src/models/IChatMember';
export class ChatMemberEffects {
    // @Effect({ dispatch: false })
    // getChatMembers = this.actions$.pipe(
    //   ofType(ChatMemberActions.ChatMemberActionTypes.LIST_CHAT_MEMBERS),
    //   map(val => {
    //     const action = <ChatMemberActions.ListChatMembers>val;
    //     this._socketGateway.sendSocketMessage(
    //       new ChatMemberMethods.GetChatMembers(action.chat_id)
    //     );
    //   })
    // );
    constructor(_store, actions$, _chatMemberDispatchers, _chatDispatcher, _profilesDispatchers, _socketGateway) {
        this._store = _store;
        this.actions$ = actions$;
        this._chatMemberDispatchers = _chatMemberDispatchers;
        this._chatDispatcher = _chatDispatcher;
        this._profilesDispatchers = _profilesDispatchers;
        this._socketGateway = _socketGateway;
        this.getChatMember = this.actions$.pipe(ofType("GET_CHAT_MEMBER" /* GET_CHAT_MEMBER */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            this._socketGateway.sendSocketMessage(new ChatMemberMethods.GetChatMember(selectedChat.id, action.payload.user_id));
        }));
        this.chatMemberReceiving = this.actions$.pipe(ofType("CHAT_MEMBER_RECEIVING" /* CHAT_MEMBER_RECEIVING */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.chatMemberReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, chats, memberReducer]) => {
            const action = val;
            this.initChatMemberBeforInsert(action.payload, selectedChat, chats, memberReducer);
        }));
        // After request list of members add it to store
        this.chatMembersReceiving = this.actions$.pipe(ofType("CHAT_MEMBERS_RECEIVED" /* CHAT_MEMBERS_RECEIVED */), map(val => {
            const action = val;
            const profiles = action.payload.map(ct => {
                // this._chatMemberDispatchers.getMemberChats(ct.user.id);
                this._chatMemberDispatchers.chatMemberReceiving(ct);
                // TODO: need to add ver in return object
                return { user_id: ct.user.id, version: ct.version };
            });
            this._profilesDispatchers.contactsReceived(profiles);
        }));
        this.memberChatsReceived = this.actions$.pipe(ofType("MY_MEMBER_CHATS_RECEIVED" /* MY_MEMBER_CHATS_RECEIVED */), map(val => {
            const action = val;
            action.payload.members.map(ct => {
                const selectedChat = {};
                selectedChat.id = ct.chat.id;
                this._chatDispatcher.newChatReceived(selectedChat);
                this._chatDispatcher.requestChatDetails(selectedChat.id);
                this._chatMemberDispatchers.chatMemberReceiving(ct);
            });
        }));
        // after add to store request profile when no profile stored
        this.chatMemberReceived = this.actions$.pipe(ofType("CHAT_MEMBER_RECEIVED" /* CHAT_MEMBER_RECEIVED */), withLatestFrom(this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, profiles]) => {
            const action = val;
            const profile = profiles.find(pr => pr.user_id === action.payload.user_id);
            // IF No Profile we will request it
            if (!profile) {
                this._profilesDispatchers.unknownProfile(action.payload.user_id);
                setTimeout(() => {
                    this._socketGateway.sendSocketMessage(new ProfileMethods.GetUser(action.payload.user_id));
                }, 300);
            }
        }));
        this.getMemberChats = this.actions$.pipe(ofType("GET_MEMBER_CHATS" /* GET_MEMBER_CHATS */), map(val => {
            const action = val;
            this._socketGateway.sendSocketMessage(new ChatMemberMethods.GetMyMemberChats(action.payload, UserStatus.ACTIVE));
        }));
        this.promoteChatMember = this.actions$.pipe(ofType("PROMOTE_CHAT_MEMBER" /* PROMOTE_CHAT_MEMBER */), withLatestFrom(this._store
            .select(st => st.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.PromoteChatMember(selectedChat.id, action.payload));
            }
        }));
        this.getChatMemberList = this.actions$.pipe(ofType("GET_MEMBER_LIST" /* GET_MEMBER_LIST */), map(val => {
            const action = val;
            if (action.payload) {
                const users = [];
                action.payload.forEach(res => {
                    users.push(res.user.id);
                });
                this._socketGateway.sendSocketMessage(new GetMyMember(users));
            }
        }));
        this.addMemberToChat = this.actions$.pipe(ofType("ADD_MEMBER_TO_CHAT" /* ADD_MEMBER_TO_CHAT */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.AddMemberToChat(action.payload.chat_id, action.payload.user_id, action.payload.recordIndex));
            }
        }));
        this.addAdminToChat = this.actions$.pipe(ofType("ADD_ADMIN_TO_CHAT" /* ADD_ADMIN_TO_CHAT */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.AddAdminToChat(action.payload.chat_id, action.payload.user_id));
            }
        }));
        this.revokeAdminFromChat = this.actions$.pipe(ofType("REVOKE_ADMIN_FROM_CHAT" /* REVOKE_ADMIN_FROM_CHAT */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.RemoveAdminFromChat(action.payload.chat_id, action.payload.user_id));
            }
        }));
        this.revokeMemberFromChat = this.actions$.pipe(ofType("REVOKE_MEMBER_FROM_CHAT" /* REVOKE_MEMBER_FROM_CHAT */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.RemoveMemberFromChat(action.payload.chat_id, action.payload.user_id));
            }
        }));
        this.setChatMemberRole = this.actions$.pipe(ofType("SET_CHAT_MEMBER_ROLE" /* SET_CHAT_MEMBER_ROLE */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.SetChatMemberRole(action.payload.chat_id, action.payload.user_id, action.payload.role));
            }
        }));
        this.setChatMemberView = this.actions$.pipe(ofType("SET_CHAT_MEMBER_VIEW" /* SET_CHAT_MEMBER_VIEW */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.SetChatMemberView(action.payload.user_id, action.payload.view));
            }
        }));
        this.setMemberTesterFlag = this.actions$.pipe(ofType("SET_MEMBER_TESTER_FLAG" /* SET_MEMBER_TESTER_FLAG */), map(val => {
            const action = val;
            if (action.payload) {
                this._socketGateway.sendSocketMessage(new ChatMemberMethods.SetMemberTesterFlag(action.payload.user_id, action.payload.tester));
            }
        }));
    }
    initChatMemberBeforInsert(payload, selectedChat, chats, memberReducer) {
        let roles = [];
        const targetChat = chats.find(chat => chat.id === selectedChat.id);
        if (targetChat && targetChat.chatRoles) {
            roles = targetChat.chatRoles;
        }
        // const roles = chats.find(chat => chat.id === selectedChat.id).chatRoles
        //   ? chats.find(chat => chat.id === selectedChat.id).chatRoles
        //   : [];
        // chats.find(chat => chat.id === selectedChat.id).chatRoles || [];
        let selectedRole = {};
        const targetMember = memberReducer.find(member => member.user_id === payload.user.id);
        const targetMemberChat = memberReducer.find(member => member.chat_id === payload.chat.id && member.user_id === payload.user.id);
        if (targetMember) {
            selectedRole = targetMember;
        }
        // const selectedRole = memberReducer.find(
        //   member => member.user_id === payload.user.id
        // )
        //   ? memberReducer.find(member => member.user_id === payload.user.id)
        //   : [];
        if (!payload.role &&
            selectedRole.role &&
            selectedRole.type === UserType.ADMIN &&
            payload.type === UserType.ADMIN &&
            payload.chat.id === selectedRole.chat_id) {
            payload.role = selectedRole.role;
        }
        else if (!payload.role &&
            targetMemberChat &&
            targetMemberChat.role &&
            targetMemberChat.type === UserType.ADMIN &&
            payload.type === UserType.ADMIN &&
            targetMemberChat.chat_id === payload.chat.id &&
            targetMemberChat.user_id === payload.user.id) {
            payload.role = targetMemberChat.role;
        }
        else if (!payload.role && payload.type === UserType.ADMIN) {
            payload.role = 3;
        }
        let roleName = null;
        const localMember = ChatMemberMiddleware.initChatMember(payload, true);
        if (localMember.role && roles && roles.length > 0) {
            roleName = roles.find(role => role.id === localMember.role).name;
        }
        else {
            roleName = payload.type;
        }
        localMember.role_name = roleName || '';
        this._chatMemberDispatchers.chatMemberReceived(localMember);
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "getChatMember", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "chatMemberReceiving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "chatMembersReceiving", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "memberChatsReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "chatMemberReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "getMemberChats", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "promoteChatMember", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "getChatMemberList", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "addMemberToChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "addAdminToChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "revokeAdminFromChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "revokeMemberFromChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "setChatMemberRole", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "setChatMemberView", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatMemberEffects.prototype, "setMemberTesterFlag", void 0);
