import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { REACHED_MAX_ITEM_LIMIT, REACHED_MIN_ITEM_LIMIT, AppClassNames, TabTypes, EMPTY_STRING, ContainerTypes, APP_MENU_CATEGORIES, ALREADY_HAD_MAP, UISections, ChannelAppScreens } from 'src/models/constants';
import { ContainerDispatchers } from './container.dispatchers';
import { initNewContainer, extractContainerData, getNewTabID } from './container.middleware';
import { ChannelAppUiDispatchers } from '../channelAppUI/channelAppUI.dispatchers';
import { ComponentDispatchers } from '../channelAppComponents/component.dispatchers';
import { ItemDispatchers } from '../channelAppItems/item.dispatchers';
import { TAB_TEMPLATE, TAB_COMPONENT_KEYS, HELP_SETTINGS_CONTAINER, ACCOUNT_SETTINGS_CONTAINER, CHATS_SETTINGS_CONTAINER, MEDIA_SETTINGS_CONTAINER, NOTIFICATION_SETTINGS_CONTAINER } from 'src/app/+merchant/+dashboard/app-mgmt/templates';
import { AppClassDispatchers } from '../channelAppClasses/appClass.dispatchers';
import { AppConfigDispatchers } from '../channelAppConfig/appConfig.dispatchers';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import { SubItemDispatchers } from '../channelAppSubItems/subItem.dispatchers';
import { extractComponentData, alreadyHadMap } from '../channelAppConfig/appConfig.middleware';
import * as isEqual from 'lodash.isequal';
import { MobileWorkFlowStoreService } from './../../+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';
import { AppMgmtService } from './../../+merchant/+dashboard/app-mgmt/appMgmt.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
export class ContainerEffects {
    constructor(actions$, _containerDispatchers, _componentDispatchers, _itemDispatchers, _subItemDispatchers, _appClassDispatchers, _appConfigDispatchers, _uiDispatchers, _channelAppUiDispatchers, _myPageDispatchers, mobileWorkFlowStoreService, appManagementService, layoutService, _store) {
        this.actions$ = actions$;
        this._containerDispatchers = _containerDispatchers;
        this._componentDispatchers = _componentDispatchers;
        this._itemDispatchers = _itemDispatchers;
        this._subItemDispatchers = _subItemDispatchers;
        this._appClassDispatchers = _appClassDispatchers;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._channelAppUiDispatchers = _channelAppUiDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this.mobileWorkFlowStoreService = mobileWorkFlowStoreService;
        this.appManagementService = appManagementService;
        this.layoutService = layoutService;
        this._store = _store;
        this.addContainerRequest = this.actions$.pipe(ofType("ADD_CONTAINER_REQUEST" /* ADD_CONTAINER_REQUEST */), withLatestFrom(this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store.select(state => state.uiReducer).pipe(distinctUntilChanged())), map(([val, containers, items, sysConfig, ui]) => {
            const action = val;
            let containersOfAppclass = [];
            containersOfAppclass = containers.filter(container => container.parentClassRef === action.appClass.ref);
            const mainTabs = containers.filter(res => res.appClassName === AppClassNames.TABS && !res.layout);
            if (action.appClass &&
                action.appClass.max_containers &&
                containersOfAppclass.length >= action.appClass.max_containers &&
                action.appClass.name !== AppClassNames.FORMS &&
                action.appClass.name !== AppClassNames.SPLASH &&
                action.appClass.name !== AppClassNames.CHANNEl_DEFAULT &&
                action.appClass.name !== AppClassNames.TABS &&
                action.specialContainerValues.type.length > 0 &&
                !action.specialContainerValues.layout) {
                this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
            }
            else {
                const newCont = initNewContainer(action.appClass, containersOfAppclass, action.specialContainerValues, ui.chatLabels, containers, this._uiDispatchers, this.appManagementService);
                if (action.appClass.name !== AppClassNames.TABS) {
                    this._containerDispatchers.addContainer(newCont);
                }
                if (action.appClass.name === AppClassNames.TABS) {
                    if (newCont.type === TabTypes.MAP &&
                        alreadyHadMap(containers, items)) {
                        this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
                    }
                    else {
                        this._containerDispatchers.addContainer(newCont);
                        this.updateAppClassTabSequence(newCont.type, newCont.parentClassRef, newCont.tempSeq);
                        const containerChildren = extractContainerData(JSON.parse(TAB_TEMPLATE), newCont, sysConfig);
                        this._componentDispatchers.addComponents(containerChildren.components);
                        this._itemDispatchers.addItems(containerChildren.items);
                    }
                }
                else if (action.appClass.name === AppClassNames.SETTINGS &&
                    action.specialContainerValues) {
                    let containerChildren = null;
                    switch (action.specialContainerValues.container_type) {
                        case ContainerTypes.HELP: {
                            containerChildren = extractContainerData(JSON.parse(HELP_SETTINGS_CONTAINER), newCont, sysConfig);
                            break;
                        }
                        case ContainerTypes.ACCOUNT: {
                            containerChildren = extractContainerData(JSON.parse(ACCOUNT_SETTINGS_CONTAINER), newCont, sysConfig);
                            break;
                        }
                        case ContainerTypes.CHATS: {
                            containerChildren = extractContainerData(JSON.parse(CHATS_SETTINGS_CONTAINER), newCont, sysConfig);
                            break;
                        }
                        case ContainerTypes.MEDIA: {
                            containerChildren = extractContainerData(JSON.parse(MEDIA_SETTINGS_CONTAINER), newCont, sysConfig);
                            break;
                        }
                        case ContainerTypes.NOTIFICATION: {
                            containerChildren = extractContainerData(JSON.parse(NOTIFICATION_SETTINGS_CONTAINER), newCont, sysConfig);
                            break;
                        }
                        default:
                            break;
                    }
                    if (containerChildren) {
                        this._componentDispatchers.addComponents(containerChildren.components);
                        this._itemDispatchers.addItems(containerChildren.items);
                        if (containerChildren.sub_items &&
                            containerChildren.sub_items.length > 0) {
                            this._subItemDispatchers.addSubItems(containerChildren.sub_items);
                        }
                    }
                }
            }
        }));
        this.addSectionRequest = this.actions$.pipe(ofType("ADD_SECTION_REQUEST" /* ADD_SECTION_REQUEST */), withLatestFrom(this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appClassReducer.find(appClass => appClass.name === AppClassNames.SECTIONS))
            .pipe(distinctUntilChanged(isEqual))), map(([val, containers, items, sysConfig, sectionsAppClass]) => {
            const action = val;
            let siblingContainers = [];
            siblingContainers = containers.filter(container => container.parentTabRef === action.parentTabRef &&
                container.appClassName === AppClassNames.SECTIONS);
            const newCont = initNewContainer(sectionsAppClass, siblingContainers, action.specialContainerValues);
            if (newCont.type === TabTypes.MAP && alreadyHadMap(containers, items)) {
                this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
            }
            else {
                this._containerDispatchers.addContainer(newCont);
                const containerChildren = extractContainerData(JSON.parse(TAB_TEMPLATE), newCont, sysConfig);
                this._componentDispatchers.addComponents(containerChildren.components);
                this._itemDispatchers.addItems(containerChildren.items);
            }
        }));
        this.deleteContainerRequest = this.actions$.pipe(ofType("DELETE_CONTAINER_REQUEST" /* DELETE_CONTAINER_REQUEST */), withLatestFrom(this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, containers, appClasses, components, appInfo]) => {
            const action = val;
            let containersOfAppClass = [];
            containersOfAppClass = containers.filter(container => container.parentClassRef === action.container.parentClassRef &&
                !container.layout);
            const parentAppClass = appClasses.find(appClass => appClass.ref === action.container.parentClassRef);
            if (parentAppClass &&
                parentAppClass.min_containers &&
                containersOfAppClass.length <= parentAppClass.min_containers &&
                parentAppClass.name !== AppClassNames.TABS) {
                this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
            }
            else {
                const componentsOfContainer = components.filter(component => component.parentContainerRef === action.container.ref);
                this._containerDispatchers.deleteContainer(action.container);
                if (action.container.selected) {
                    this._containerDispatchers.selectFirstContainer(action.container.appClassName);
                }
                componentsOfContainer.forEach(component => {
                    this._itemDispatchers.deleteItemsOfComponent(component);
                    this._subItemDispatchers.deleteSubItemsOfComponent(component);
                });
                this._componentDispatchers.deleteComponentsOfContainer(action.container);
            }
        }));
        this.selectFirstContainer = this.actions$.pipe(ofType("SELECT_FIRST_CONTAINER" /* SELECT_FIRST_CONTAINER */), withLatestFrom(this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, containers]) => {
            const action = val;
            const firstContainer = containers
                .filter(container => container.appClassName === action.appClassName &&
                container.layout !== 'button')
                .sort((a, b) => (a.order < b.order ? -1 : 1));
            let storeList = [];
            const uiSub = this.layoutService.uiCollection$.subscribe(ui => {
                const mainsub = this.appManagementService.currentPage$.subscribe(page => {
                    if (ui.section === UISections.APP &&
                        page === ChannelAppScreens.TABS) {
                        const menuSub = this.appManagementService.selectedMenu$.subscribe(menu => {
                            if (menu &&
                                firstContainer[0] &&
                                menu.menu_id !== firstContainer[0].menu_id) {
                                if (firstContainer[0] &&
                                    firstContainer[0].type === 'menu' &&
                                    firstContainer[0].sub_type &&
                                    storeList.length == 0) {
                                    const storeListSub = this.mobileWorkFlowStoreService.mobileStoreAppList$.subscribe(list => {
                                        storeList = list;
                                        if (firstContainer[0].store_id) {
                                            let getStore = false;
                                            if (list) {
                                                list.forEach(store => {
                                                    if (store.id + '' ===
                                                        firstContainer[0].store_id) {
                                                        store.data.shop.forEach(shop => {
                                                            if (shop.menu_id === store.data.menu_id) {
                                                                this.mobileWorkFlowStoreService._selectedMenu.next(shop);
                                                                this.appManagementService._selectedMenu.next(shop);
                                                            }
                                                        });
                                                        this.mobileWorkFlowStoreService._selectedMenuList.next(store.data.shop);
                                                        this.mobileWorkFlowStoreService._selectedStoreId.next(store.id);
                                                        this.mobileWorkFlowStoreService._mainMenuId.next(store.data.menu_id);
                                                        this.mobileWorkFlowStoreService._isDefault.next(1);
                                                        getStore = true;
                                                    }
                                                });
                                            }
                                            if (!getStore) {
                                                this.mobileWorkFlowStoreService.getMobileStoreById(firstContainer[0].store_id);
                                            }
                                        }
                                        else {
                                            let getStore = false;
                                            if (list) {
                                                list.forEach(store => {
                                                    if (store.is_default) {
                                                        store.data.shop.forEach(shop => {
                                                            if (shop.menu_id === store.data.menu_id) {
                                                                this.mobileWorkFlowStoreService._selectedMenu.next(shop);
                                                                this.appManagementService._selectedMenu.next(shop);
                                                            }
                                                        });
                                                        this.mobileWorkFlowStoreService._selectedMenuList.next(store.data.shop);
                                                        this.mobileWorkFlowStoreService._selectedStoreId.next(store.id);
                                                        this.mobileWorkFlowStoreService._mainMenuId.next(store.data.menu_id);
                                                        this.mobileWorkFlowStoreService._isDefault.next(1);
                                                        getStore = true;
                                                    }
                                                });
                                            }
                                            if (!getStore) {
                                                this.mobileWorkFlowStoreService.getMobileStoreById();
                                            }
                                        }
                                    });
                                    storeListSub.unsubscribe();
                                }
                                else if (firstContainer[0] &&
                                    firstContainer[0].type === 'menu' &&
                                    !firstContainer[0].sub_type) {
                                    const menuAppclassSub = this.appManagementService.menusAppClass$.subscribe(menusAppClass => {
                                        menusAppClass.menus.forEach(menuwork => {
                                            if (menuwork.menu_id === firstContainer[0].menu_id) {
                                                this.mobileWorkFlowStoreService._selectedMenu.next(menuwork);
                                                this.appManagementService._selectedMenu.next(menuwork);
                                            }
                                            this.mobileWorkFlowStoreService._selectedMenuList.next(false);
                                            this.mobileWorkFlowStoreService._selectedStoreId.next(false);
                                            this.mobileWorkFlowStoreService._mainMenuId.next(false);
                                            this.mobileWorkFlowStoreService._isDefault.next(0);
                                        });
                                    });
                                    menuAppclassSub.unsubscribe();
                                }
                            }
                        });
                        menuSub.unsubscribe();
                    }
                });
                mainsub.unsubscribe();
            });
            uiSub.unsubscribe();
            if (firstContainer[0]) {
                this._channelAppUiDispatchers.selectTab(firstContainer[0]);
                this._containerDispatchers.selectContainer(firstContainer[0]);
            }
        }));
        this.selectLastContainer = this.actions$.pipe(ofType("SELECT_LAST_CONTAINER" /* SELECT_LAST_CONTAINER */), withLatestFrom(this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, containers]) => {
            const action = val;
            const tabContainers = containers
                .filter(container => container.appClassName === action.appClassName &&
                container.layout !== 'button')
                .sort((a, b) => (a.order < b.order ? -1 : 1));
            const lastContainer = tabContainers[tabContainers.length - 1];
            if (lastContainer) {
                this._channelAppUiDispatchers.selectTab(lastContainer);
                this._containerDispatchers.selectContainer(lastContainer);
            }
        }));
        //
        this.updateTabTypeRequest = this.actions$.pipe(ofType("UPDATE_TAB_TYPE_REQUEST" /* UPDATE_TAB_TYPE_REQUEST */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer.filter(comp => comp.appClassName === AppClassNames.TABS))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, classes, containers, items, tabComponents, sysConfig]) => {
            const action = val;
            const targetContainer = containers.find(container => container.appClassName === AppClassNames.TABS &&
                container.ref === action.containerRef);
            const parentClass = classes.find(appClass => appClass.name === AppClassNames.TABS);
            if (targetContainer.type !== action.tabType) {
                if (action.tabType === TabTypes.MAP &&
                    alreadyHadMap(containers, items)) {
                    this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
                }
                else {
                    let compsToBeAdded = [];
                    let itemsToBeAdded = [];
                    const childrenComps = tabComponents.filter(comp => comp.parentContainerRef === action.containerRef);
                    TAB_COMPONENT_KEYS.forEach(compItem => {
                        if (!childrenComps.find(comp => comp.component_type === compItem.key) &&
                            ((!targetContainer.menu || targetContainer.menu.length <= 0) &&
                                targetContainer.type === TabTypes.FEED)) {
                            const res = extractComponentData(JSON.parse(compItem.value), targetContainer, sysConfig);
                            compsToBeAdded = [...compsToBeAdded, ...res.components];
                            itemsToBeAdded = [...itemsToBeAdded, ...res.items];
                        }
                    });
                    if (compsToBeAdded.length > 0) {
                        this._componentDispatchers.addComponents(compsToBeAdded);
                        if (itemsToBeAdded.length > 0) {
                            this._itemDispatchers.addItems(itemsToBeAdded);
                        }
                    }
                    const newTabID = getNewTabID(parentClass, action.tabType);
                    this._containerDispatchers.updateTabType(action.containerRef, action.tabType, newTabID.id);
                    if (action.tabType === TabTypes.PAGE && !action.pageId) {
                        const pageRef = Date.now() + '';
                        this._containerDispatchers.updateContainer(action.containerRef, {
                            page_ref: pageRef,
                            page_id: null,
                            template_id: null
                        });
                        this._myPageDispatchers.createMyPage(pageRef);
                    }
                    this.updateAppClassTabSequence(action.tabType, parentClass.ref, newTabID.seq);
                }
            }
        }));
        this.appConfDataParsedEffect = this.actions$.pipe(ofType("APP_CONFIG_DATA_CHANGED" /* APP_CONFIG_DATA_CHANGED */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer.filter(container => container.appClassName === AppClassNames.TABS))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, classes, containers, appConfig, chats, selectedChat]) => {
            const action = val;
            const effectiveSelectedChat = chats.find(chat => {
                if (selectedChat && chat.id === selectedChat.id) {
                    return chat;
                }
            });
            const appInfo = {};
            if (!appConfig.app_info) {
                appConfig.app_info = {};
            }
            // let channelName = appConfig.app_info.channel_name;
            let imageID = appConfig.app_info.image_id;
            let image = appConfig.app_info.image;
            let channelName = '';
            // if (
            //   !appConfig.app_info.channel_name ||
            //   appConfig.app_info.channel_name === EMPTY_STRING
            // ) {
            //   if (appName && appName !== EMPTY_STRING) {
            //     channelName = appName;
            //   } else {
            if (effectiveSelectedChat) {
                channelName = effectiveSelectedChat.title;
            }
            //   }
            // }
            if (!appConfig.app_info.image ||
                appConfig.app_info.image === EMPTY_STRING) {
                image = effectiveSelectedChat.localThumbnail;
            }
            if (!appConfig.app_info.image_id ||
                appConfig.app_info.image_id === EMPTY_STRING) {
                imageID = effectiveSelectedChat.photo
                    ? effectiveSelectedChat.photo.id
                    : '';
            }
            // appInfo.channel_name = channelName;
            appInfo.image = image;
            appInfo.image_id = imageID;
            this._appConfigDispatchers.updateAppInfo(appInfo);
            const tabsParentClass = classes.find(appClass => appClass.name === AppClassNames.TABS);
            if (tabsParentClass &&
                !tabsParentClass.feed_seq &&
                !tabsParentClass.wallet_seq &&
                !tabsParentClass.membership_seq &&
                !tabsParentClass.webview_seq &&
                !tabsParentClass.channel_seq &&
                !tabsParentClass.chat_seq &&
                !tabsParentClass.settings_seq &&
                !tabsParentClass.calendar_seq &&
                !tabsParentClass.booking_seq &&
                !tabsParentClass.invitation_seq &&
                !tabsParentClass.open_chat_seq &&
                !tabsParentClass.mylist_seq &&
                !tabsParentClass.qr_seq &&
                !tabsParentClass.map_seq &&
                !tabsParentClass.search_seq &&
                !tabsParentClass.call_log_seq &&
                !tabsParentClass.section_seq &&
                !tabsParentClass.bundle_seq &&
                !tabsParentClass.package_seq &&
                !tabsParentClass.video_seq &&
                !tabsParentClass.qr_page_seq &&
                !tabsParentClass.map_search_seq) {
                const feedTabs = containers.filter(container => container.type === TabTypes.FEED);
                const walletTabs = containers.filter(container => container.type === TabTypes.WALLET);
                const memberTabs = containers.filter(container => container.type === TabTypes.MEMBERSHIP);
                const webViewTabs = containers.filter(container => container.type === TabTypes.WEBVIEW ||
                    container.type === TabTypes.PAGE);
                const channelTabs = containers.filter(container => container.type === TabTypes.CHANNELS);
                const chatTabs = containers.filter(container => container.type === TabTypes.CHATS);
                const settingsTabs = containers.filter(container => container.type === TabTypes.SETTINGS);
                const calendarTabs = containers.filter(container => container.type === TabTypes.CALENDAR);
                const bookingTabs = containers.filter(container => container.type === TabTypes.BOOKING);
                const invitationTabs = containers.filter(container => container.type === TabTypes.INVITATION);
                const openchatTabs = containers.filter(container => container.type === TabTypes.OPENCHAT);
                const mylistTabs = containers.filter(container => container.type === TabTypes.MYLIST);
                const qrTabs = containers.filter(container => container.type === TabTypes.QR);
                const mapTabs = containers.filter(container => container.type === TabTypes.MAP);
                const searchTabs = containers.filter(container => container.type === TabTypes.SEARCH);
                const callLogTabs = containers.filter(container => container.type === TabTypes.CALL_LOG);
                const sectionTabs = containers.filter(container => container.type === TabTypes.SECTION);
                const bundleTabs = containers.filter(container => container.type === TabTypes.BUNDLE);
                const packageTabs = containers.filter(container => container.type === TabTypes.PACKAGE);
                const videoTabs = containers.filter(container => container.type === TabTypes.VIDEO);
                const qrPageTabs = containers.filter(container => container.type === TabTypes.QR_PAGE);
                const mapSearchTabs = containers.filter(container => container.type === TabTypes.MAP_SEARCH);
                let feedSeq = 0;
                let walletSeq = 0;
                let memberSeq = 0;
                let webViewSeq = 0;
                let channelSeq = 0;
                let chatSeq = 0;
                let settingsSeq = 0;
                let calendarSeq = 0;
                let bookingSeq = 0;
                let invitationSeq = 0;
                let openchatSeq = 0;
                let mylistSeq = 0;
                let qrSeq = 0;
                let mapSeq = 0;
                let searchSeq = 0;
                let callLogSeq = 0;
                let sectionSeq = 0;
                let bundleSeq = 0;
                let packageSeq = 0;
                let videoSeq = 0;
                let qrPageSeq = 0;
                let mapSearchSeq = 0;
                if (feedTabs) {
                    const ids = feedTabs.map(feedTab => parseInt(feedTab.id.slice("feed" /* FEED */.length), 10));
                    feedSeq = Math.max(...ids);
                }
                if (walletTabs) {
                    const ids = walletTabs.map(walletTab => parseInt(walletTab.id.slice("wallet" /* WALLET */.length), 10));
                    walletSeq = Math.max(...ids);
                }
                if (memberTabs) {
                    const ids = memberTabs.map(memberTab => parseInt(memberTab.id.slice("member" /* MEMBERSHIP */.length), 10));
                    memberSeq = Math.max(...ids);
                }
                if (webViewTabs) {
                    const ids = webViewTabs.map(webViewTab => parseInt(webViewTab.id.slice("webview" /* WEB_VIEW */.length), 10));
                    webViewSeq = Math.max(...ids);
                }
                if (channelTabs) {
                    const ids = channelTabs.map(channelTab => parseInt(channelTab.id.slice("channel" /* CHANNELS */.length), 10));
                    channelSeq = Math.max(...ids);
                }
                if (chatTabs) {
                    const ids = chatTabs.map(chatTab => parseInt(chatTab.id.slice("chat" /* CHATS */.length), 10));
                    chatSeq = Math.max(...ids);
                }
                if (settingsTabs) {
                    const ids = settingsTabs.map(settingsTab => parseInt(settingsTab.id.slice("settings" /* SETTINGS */.length), 10));
                    settingsSeq = Math.max(...ids);
                }
                if (calendarTabs) {
                    const ids = calendarTabs.map(calendarTab => parseInt(calendarTab.id.slice("event" /* CALENDAR */.length), 10));
                    calendarSeq = Math.max(...ids);
                }
                if (bookingTabs) {
                    const ids = calendarTabs.map(bookingTab => parseInt(bookingTab.id.slice("booking_list" /* BOOKING */.length), 10));
                    bookingSeq = Math.max(...ids);
                }
                if (invitationTabs) {
                    const ids = invitationTabs.map(invitationTab => parseInt(invitationTab.id.slice("invitation" /* INVITATION */.length), 10));
                    invitationSeq = Math.max(...ids);
                }
                if (openchatTabs) {
                    const ids = openchatTabs.map(openchatTab => parseInt(openchatTab.id.slice("open_chat" /* OPENCHAT */.length), 10));
                    openchatSeq = Math.max(...ids);
                }
                if (mylistTabs) {
                    const ids = mylistTabs.map(myListTab => parseInt(myListTab.id.slice("mylist" /* MYLIST */.length), 10));
                    mylistSeq = Math.max(...ids);
                }
                if (qrTabs) {
                    const ids = qrTabs.map(qrTab => parseInt(qrTab.id.slice("qr" /* QR */.length), 10));
                    qrSeq = Math.max(...ids);
                }
                if (mapTabs) {
                    const ids = mapTabs.map(mapTab => parseInt(mapTab.id.slice("map" /* MAP */.length), 10));
                    mapSeq = Math.max(...ids);
                }
                if (searchTabs) {
                    const ids = searchTabs.map(searchTab => parseInt(searchTab.id.slice("search" /* SEARCH */.length), 10));
                    searchSeq = Math.max(...ids);
                }
                if (callLogTabs) {
                    const ids = callLogTabs.map(callLogTab => parseInt(callLogTab.id.slice("call_log" /* CALL_LOG */.length), 10));
                    callLogSeq = Math.max(...ids);
                }
                if (sectionTabs) {
                    const ids = sectionTabs.map(sectionTab => parseInt(sectionTab.id.slice("section" /* SECTION */.length), 10));
                    sectionSeq = Math.max(...ids);
                }
                if (bundleTabs) {
                    const ids = bundleTabs.map(bundleTab => parseInt(bundleTab.id.slice("bundle" /* BUNDLE */.length), 10));
                    bundleSeq = Math.max(...ids);
                }
                if (packageTabs) {
                    const ids = packageTabs.map(packageTab => parseInt(packageTab.id.slice("package" /* PACKAGE */.length), 10));
                    packageSeq = Math.max(...ids);
                }
                if (videoTabs) {
                    const ids = videoTabs.map(videoTab => parseInt(videoTab.id.slice("video" /* VIDEO */.length), 10));
                    videoSeq = Math.max(...ids);
                }
                if (qrPageTabs) {
                    const ids = qrPageTabs.map(qrPageTab => parseInt(qrPageTab.id.slice("qr_page" /* QR_PAGE */.length), 10));
                    qrPageSeq = Math.max(...ids);
                }
                if (mapSearchTabs) {
                    const ids = mapSearchTabs.map(mapSearchTab => parseInt(mapSearchTab.id.slice("map_search" /* MAP_SEARCH */.length), 10));
                    mapSearchSeq = Math.max(...ids);
                }
                const fieldsToBeUpdated = {};
                fieldsToBeUpdated.feed_seq = feedSeq;
                fieldsToBeUpdated.wallet_seq = walletSeq;
                fieldsToBeUpdated.membership_seq = memberSeq;
                fieldsToBeUpdated.webview_seq = webViewSeq;
                fieldsToBeUpdated.channel_seq = channelSeq;
                fieldsToBeUpdated.chat_seq = chatSeq;
                fieldsToBeUpdated.settings_seq = settingsSeq;
                fieldsToBeUpdated.calendar_seq = calendarSeq;
                fieldsToBeUpdated.booking_seq = bookingSeq;
                fieldsToBeUpdated.invitation_seq = invitationSeq;
                fieldsToBeUpdated.open_chat_seq = openchatSeq;
                fieldsToBeUpdated.mylist_seq = mylistSeq;
                fieldsToBeUpdated.qr_seq = qrSeq;
                fieldsToBeUpdated.map_seq = mapSeq;
                fieldsToBeUpdated.search_seq = searchSeq;
                fieldsToBeUpdated.call_log_seq = callLogSeq;
                fieldsToBeUpdated.section_seq = sectionSeq;
                fieldsToBeUpdated.bundle_seq = bundleSeq;
                fieldsToBeUpdated.package_seq = packageSeq;
                fieldsToBeUpdated.video_seq = videoSeq;
                fieldsToBeUpdated.qr_page_seq = qrPageSeq;
                fieldsToBeUpdated.map_search_seq = mapSearchSeq;
                this._appClassDispatchers.updateAppClass(tabsParentClass.ref, fieldsToBeUpdated);
            }
        }));
        // Need to update the map containers here
        this.updateMapContainerOnPublish = this.actions$.pipe(ofType("UPDATE_MAP_CONTAINER_ON_PUBLISH" /* UPDATE_MAP_CONTAINER_ON_PUBLISH */), withLatestFrom(this._store
            .select(state => state.containerReducer.filter(cont => cont.container_type === ContainerTypes.TAB &&
            cont.type === TabTypes.MAP))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer.filter(item => item.link === TabTypes.MAP))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appMenuReducer.filter(menu => menu.category === APP_MENU_CATEGORIES.MAP))
            .pipe(distinctUntilChanged(isEqual))), map(([val, containers, items, mapMenus]) => {
            const action = val;
            if (mapMenus) {
                if (containers && containers.length > 0) {
                    containers.forEach(cont => {
                        const targetMap = mapMenus.find(mapMenu => mapMenu.id === cont.map_menu_id);
                        if (targetMap) {
                            const tabContainer = {};
                            tabContainer.map_menu_id = targetMap.id;
                            tabContainer.map = targetMap.value;
                            tabContainer.url = targetMap.url;
                            this._containerDispatchers.updateContainer(cont.ref, tabContainer);
                        }
                    });
                }
                if (items && items.length > 0) {
                    items.forEach(item => {
                        const targetMap = mapMenus.find(mapMenu => mapMenu.id === item.map_menu_id);
                        if (targetMap) {
                            const mapItem = {};
                            mapItem.map_menu_id = targetMap.id;
                            mapItem.map = targetMap.value;
                            mapItem.url = targetMap.url;
                            this._itemDispatchers.updateItem(item.ref, mapItem);
                        }
                    });
                }
            }
            setTimeout(() => this._appConfigDispatchers.setAppConfig(action.isChannelOnly), 500);
        }));
    }
    updateAppClassTabSequence(tabType, parentClassRef, newSeq) {
        const fieldsToBeUpdated = {};
        if (tabType === TabTypes.FEED) {
            fieldsToBeUpdated.feed_seq = newSeq;
        }
        else if (tabType === TabTypes.MEMBERSHIP) {
            fieldsToBeUpdated.membership_seq = newSeq;
        }
        else if (tabType === TabTypes.WALLET) {
            fieldsToBeUpdated.wallet_seq = newSeq;
        }
        else if (tabType === TabTypes.WEBVIEW || tabType === TabTypes.PAGE) {
            fieldsToBeUpdated.webview_seq = newSeq;
        }
        else if (tabType === TabTypes.CHANNELS) {
            fieldsToBeUpdated.channel_seq = newSeq;
        }
        else if (tabType === TabTypes.CHATS) {
            fieldsToBeUpdated.chat_seq = newSeq;
        }
        else if (tabType === TabTypes.SETTINGS) {
            fieldsToBeUpdated.settings_seq = newSeq;
        }
        else if (tabType === TabTypes.CALENDAR) {
            fieldsToBeUpdated.calendar_seq = newSeq;
        }
        else if (tabType === TabTypes.BOOKING) {
            fieldsToBeUpdated.booking_seq = newSeq;
        }
        else if (tabType === TabTypes.INVITATION) {
            fieldsToBeUpdated.invitation_seq = newSeq;
        }
        else if (tabType === TabTypes.OPENCHAT) {
            fieldsToBeUpdated.open_chat_seq = newSeq;
        }
        else if (tabType === TabTypes.MYLIST) {
            fieldsToBeUpdated.mylist_seq = newSeq;
        }
        else if (tabType === TabTypes.QR) {
            fieldsToBeUpdated.qr_seq = newSeq;
        }
        else if (tabType === TabTypes.MAP) {
            fieldsToBeUpdated.map_seq = newSeq;
        }
        else if (tabType === TabTypes.SEARCH) {
            fieldsToBeUpdated.search_seq = newSeq;
        }
        else if (tabType === TabTypes.CALL_LOG) {
            fieldsToBeUpdated.call_log_seq = newSeq;
        }
        else if (tabType === TabTypes.SECTION) {
            fieldsToBeUpdated.section_seq = newSeq;
        }
        else if (tabType === TabTypes.BUNDLE) {
            fieldsToBeUpdated.bundle_seq = newSeq;
        }
        else if (tabType === TabTypes.PACKAGE) {
            fieldsToBeUpdated.package_seq = newSeq;
        }
        else if (tabType === TabTypes.VIDEO) {
            fieldsToBeUpdated.video_seq = newSeq;
        }
        else if (tabType === TabTypes.QR_PAGE) {
            fieldsToBeUpdated.qr_page_seq = newSeq;
        }
        else if (tabType === TabTypes.MAP_SEARCH) {
            fieldsToBeUpdated.map_search_seq = newSeq;
        }
        this._appClassDispatchers.updateAppClass(parentClassRef, fieldsToBeUpdated);
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "addContainerRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "addSectionRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "deleteContainerRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "selectFirstContainer", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "selectLastContainer", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "updateTabTypeRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "appConfDataParsedEffect", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ContainerEffects.prototype, "updateMapContainerOnPublish", void 0);
