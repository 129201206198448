import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { BillingUIActionTypes } from './billingUI.actions';
import * as BillingUIActions from './billingUI.actions';
import {
  AddNewCreditCard,
  CancelStripeSubscription,
  ChangePlanMethod,
  DeleteCreditCard,
  GetBillingInfoMethod,
  GetInvoicesMethod,
  RevokeSubscription,
  SendBillingSubscribetion,
  SendCouponToServer,
  SetDefaultCreditCard,
  SubscribeToAddons
} from 'src/models/billings';

import * as isEqual from 'lodash.isequal';
import { UIDispatchers } from '../ui/ui.dispatchers';

@Injectable()
export class BillingUiEffects {
  @Effect({ dispatch: false })
  sendSubscribetionToPlan = this.actions$.pipe(
    ofType(BillingUIActionTypes.SEND_SUBSCRIBETION_TO_PLAN_UI),
    map((action: BillingUIActions.SendSubscribetionToPlan) => {
      this._socketGateway.sendSocketMessage(
        new SendBillingSubscribetion(
          action.subscribeDetails.phone,
          action.subscribeDetails.name,
          action.subscribeDetails.email,
          action.subscribeDetails.card_token,
          action.subscribeDetails.plan_id,
          action.subscribeDetails.coupon,
          action.subscribeDetails.sub_main,
          action.lm_data
        )
      );
    })
  );
  @Effect({ dispatch: false })
  sendCouponUI = this.actions$.pipe(
    ofType(BillingUIActionTypes.SEND_COUPON_UI),
    map((action: BillingUIActions.SendCouponUI) => {
      this._socketGateway.sendSocketMessage(
        new SendCouponToServer(action.coupon)
      );
    })
  );
  @Effect({ dispatch: false })
  addNewCreditCard = this.actions$.pipe(
    ofType(BillingUIActionTypes.ADD_NEW_CREDIT_CARD_UI),
    map((action: BillingUIActions.AddNewCreditCardUI) => {
      this._socketGateway.sendSocketMessage(
        new AddNewCreditCard(action.token.card_token)
      );
    })
  );
  @Effect({ dispatch: false })
  setDefaultCreditCardUI = this.actions$.pipe(
    ofType(BillingUIActionTypes.SET_DEFAULT_CARD_UI),
    map((action: BillingUIActions.SetDefaultCardUI) => {
      this._socketGateway.sendSocketMessage(
        new SetDefaultCreditCard(action.cardId)
      );
    })
  );
  @Effect({ dispatch: false })
  deleteCreditCardRecuset = this.actions$.pipe(
    ofType(BillingUIActionTypes.DELETE_CREDIT_CARD_UI),
    map((action: BillingUIActions.DeleteCreditCardUI) => {
      this._socketGateway.sendSocketMessage(
        new DeleteCreditCard(action.cardId)
      );
    })
  );
  @Effect({ dispatch: false })
  getBillingInfo = this.actions$.pipe(
    ofType(BillingUIActionTypes.GET_BILLING_INFO_SETUP),
    map((action: BillingUIActions.GetBillingInfoUI) => {
      this._socketGateway.sendSocketMessage(new GetBillingInfoMethod());
    })
  );
  @Effect({ dispatch: false })
  getInvoicesListUI = this.actions$.pipe(
    ofType(BillingUIActionTypes.GET_INVOICES_LIST_UI),
    map((action: BillingUIActions.GetInvoicesListUI) => {
      this._socketGateway.sendSocketMessage(new GetInvoicesMethod());
    })
  );
  @Effect({ dispatch: false })
  changePlanRequest = this.actions$.pipe(
    ofType(BillingUIActionTypes.CHANGE_PLAN_REQUEST_UI),
    map((action: BillingUIActions.ChangePlanRequest) => {
      this._socketGateway.sendSocketMessage(
        new ChangePlanMethod(action.new_plan_id, action.coupon, true)
      );
    })
  );
  @Effect({ dispatch: false })
  subscribeToAddonsRequest = this.actions$.pipe(
    ofType(BillingUIActionTypes.SUBSCRIBE_TO_ADDONS_REQUEST_UI),
    map((action: BillingUIActions.SubscribeToAddonsRequest) => {
      this._socketGateway.sendSocketMessage(
        new SubscribeToAddons(action.plan_id, false, action.coupon)
      );
    })
  );
  @Effect({ dispatch: false })
  cancelStripeSubsCription = this.actions$.pipe(
    ofType(BillingUIActionTypes.CANCEL_STRIPE_SUBSCRIPTION_UI),
    map((action: BillingUIActions.CancelStripeSubsCription) => {
      this._socketGateway.sendSocketMessage(
        new CancelStripeSubscription(
          action.all,
          action.plan_id,
          action.reason,
          action.subMain,
          action.cancelQuantity
        )
      );
    })
  );
  @Effect({ dispatch: false })
  goToLastSectionUI = this.actions$.pipe(
    ofType(BillingUIActionTypes.GO_TO_LAST_SECTION_UI),
    withLatestFrom(
      this._store
        .select(state => state.billingUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, lastSection]) => {
      const action = <BillingUIActions.GoToLastSection>val;
      if (lastSection.lastScreen) {
        this._uiDispatchers.setSection(lastSection.lastScreen);
      }
    })
  );
  @Effect({ dispatch: false })
  revokeCancelMainPlan = this.actions$.pipe(
    ofType(BillingUIActionTypes.REVOKE_CANCEL_MAINPLAN_UI),
    map((action: BillingUIActions.RevokeCancelMainPlan) => {
      this._socketGateway.sendSocketMessage(new RevokeSubscription());
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _store: Store<AppState>
  ) {}
}
