/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./snackbaar-error-notifier.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./snackbaar-error-notifier.component";
var styles_SnackbaarErrorNotifierComponent = [i0.styles];
var RenderType_SnackbaarErrorNotifierComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SnackbaarErrorNotifierComponent, data: {} });
export { RenderType_SnackbaarErrorNotifierComponent as RenderType_SnackbaarErrorNotifierComponent };
export function View_SnackbaarErrorNotifierComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "snackbar-error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "snackbar-error-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "error"], ["height", "40"], ["src", "./assets/img/close (2).svg"], ["width", "40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "snackbar-error-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "snackbar-error-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 7, 0, currVal_0); }); }
export function View_SnackbaarErrorNotifierComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-snackbaar-error-notifier", [], null, null, null, View_SnackbaarErrorNotifierComponent_0, RenderType_SnackbaarErrorNotifierComponent)), i1.ɵdid(1, 114688, null, 0, i2.SnackbaarErrorNotifierComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SnackbaarErrorNotifierComponentNgFactory = i1.ɵccf("app-snackbaar-error-notifier", i2.SnackbaarErrorNotifierComponent, View_SnackbaarErrorNotifierComponent_Host_0, { message: "message" }, { hidePopupNotify: "hidePopupNotify" }, []);
export { SnackbaarErrorNotifierComponentNgFactory as SnackbaarErrorNotifierComponentNgFactory };
