<div
  class="message"
  appCancelDrag
  appClickElsewhere
  (clickElsewhere)="closeShareEvent()"
>
  <!-- Start: Header section -->
  <div *ngIf="!hideHeader" class="header no-copyable-item">
    <!-- Profile -->
    <app-profile-image
      class="no-copyable-item default-cursor"
      [id]="message?.group_id"
      [chatType]="'Channel'"
      [size]="32"
      class="logo"
    >
    </app-profile-image>
    <!-- end profile-->

    <!-- post info -->
    <div class="post-info">
      <div
        class="post-title text-capitalize"
        [innerHTML]="
          (uiService.getChatByID$(message?.group_id) | async)?.name | twemoji
        "
      ></div>
      <div class="post-desc">
        <!-- <svg class="svg-icon status">
          <use [appMessageStatusIcon]="message?.status" xlink:href=""></use>
        </svg> -->
        <app-standard-date [timestamp]="message?.date" [isPost]="true">
        </app-standard-date>
        <svg
          *ngIf="message?.type === mType.ARTICLE"
          class="svg-icon icon-article"
        >
          <use xlink:href="./assets/svg/app-sections.svg#icon-article"></use>
        </svg>
      </div>
    </div>
    <!-- end post info -->
    <app-post-cell-actions
      *ngIf="!hideMenu"
      [isAdmin]="chat?.isAdmin"
      [loggedInIsSender]="message?.loggedInIsSender"
      [isMenu]="message?.inline_menu"
      [isDownloadable]="isDownloadable"
      [media]="media"
      [mediaId]="message?.media_id"
      [isPostScreen]="isPostScreen"
      (recallNotfiy)="recallEvent()"
      (repostNotfiy)="repostEvent()"
      (statsNotfiy)="getMessageMenuStats()"
      (editNotfiy)="editEvent()"
      (selectNotfiy)="selectEvent()"
      (setTemplateNotfiy)="setTemplate()"
      [numberOfReplies]="
        message.date !== message.lastReplyDate && message?.numberOfReplies
      "
      [numberOfUnreadReplies]="message.numberOfUnreadReplies > 0"
    >
    </app-post-cell-actions>

    <div *ngIf="hideFooter && !isPostScreen" class="reply-container">
      <div class="post-commit clickable-cursor" (click)="selectEvent()">
        <img src="./assets/img/chat-btn.svg" alt="chat" />
      </div>

      <div *ngIf="message.numberOfReplies > 0" class="post-counter">
        {{ message?.numberOfReplies | countSize }}
      </div>
    </div>
  </div>
  <!-- End: Header section -->
  <!-- start: tap section -->
  <ng-container *ngIf="project === merchant && !hedeChatLabel">
    <ng-container *ngIf="authService?.authCollection$ | async as auth">
      <ng-container *ngIf="auth?.main_group_id === auth?.chatId">
        <div class="post-tap" *ngIf="getChatLabel(message?.tab)">
          <div class="text">Chat Lable</div>
          <div class="tab">
            <div class="tab-icon" *ngIf="getTabContainer(message?.tab) as tab">
              <svg class="tab-icon-svg icon-icon">
                <use
                  attr.xlink:href="./assets/svg/app-icons.svg#{{ tab?.icon }}"
                ></use>
              </svg>
            </div>
            <div class="tab-text" style="margin-left: 10px;">
              {{ getChatLabel(message?.tab) }}
            </div>
          </div>
        </div>
        <div class="post-tap-removed" *ngIf="!getChatLabel(message?.tab)">
          <div class="text">{{ 'CHATLABEL' | translate }}</div>
          <div class="tab">
            <div class="tab-text">N/A</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!-- End: tap section -->
  <!-- Start: Body section -->
  <div
    class="body"
    [class.wide-boottom]="message?.style === mStyles.WIDE_BOOTTOM"
    [class.left-side]="message?.style === mStyles.LEFT_SIDE"
  >
    <div
      class="body-container"
      *ngIf="
        (message?.type === mType.TEXT &&
          (message?.web_page_preview !==
            linkPreviewStyle.WEB_PREVIEW_HIDE_LINK ||
            !message?.linkPreviewTitle) &&
          !message?.bg_color) ||
        message?.type === mType.TEXT_FILE ||
        message?.type === mType.CONTACT ||
        message?.type === mType.STICKER ||
        message?.type === mType.LOCATION
      "
    >
      <!-- caption section -->
      <ng-container>
        <app-text-cell
          *ngIf="message?.caption"
          class="post-caption text wrap-text copyable-item text-cursor"
          dir="auto"
          [text]="message?.caption"
        >
        </app-text-cell>

        <div
          *ngIf="message?.location_name"
          class="post-caption text wrap-text copyable-item text-cursor"
        >
          {{ message?.location_name }} <br />
          {{ message?.location_details }}
        </div>
      </ng-container>
      <!--- end post caption section -->

      <!-- text section -->

      <app-text-cell
        *ngIf="
          message?.type === mType.TEXT &&
          (message?.web_page_preview !==
            linkPreviewStyle.WEB_PREVIEW_HIDE_LINK ||
            !message?.linkPreviewTitle) &&
          !message?.bg_color
        "
        class="text wrap-text copyable-item text-cursor"
        dir="auto"
        [text]="message?.text"
      >
      </app-text-cell>

      <app-text-file-cell
        *ngIf="message?.type === mType.TEXT_FILE"
        class="text wrap-text copyable-item text-cursor"
        [localMedia]="message?.localMedia"
        [localMessageString]="message?.text"
      >
      </app-text-file-cell>

      <app-contact-cell
        *ngIf="message?.type === mType.CONTACT"
        class="text wrap-text"
        [phoneNumber]="message?.phone_number"
        [phoneName]="message?.contact_name"
      >
      </app-contact-cell>

      <app-sticker-cell
        *ngIf="message?.type === mType.STICKER"
        class="no-copyable-item"
        [mediaStatus]="message?.mediaStatus"
        [percent]="message?.loadingProgress"
        [localMedia]="message?.localMedia"
        [themeColor]="themeColorFile"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
      >
      </app-sticker-cell>

      <app-map-cell
        *ngIf="message?.type === mType.LOCATION"
        class="media no-copyable-item clickable-cursor"
        [width]="'306'"
        [height]="'300'"
        [latitude]="message?.latitude"
        [longitude]="message?.longitude"
      >
      </app-map-cell>
    </div>
    <app-audio-cell
      *ngIf="message?.type === mType.VOICE || message?.type === mType.AUDIO"
      class="post-theme no-copyable-item"
      [mediaStatus]="message?.mediaStatus"
      [localMedia]="message?.localMedia"
      [fileSize]="message?.file_size"
      [percent]="message?.loadingProgress"
      [mediaDuration]="message?.media_duration"
      [type]="message?.type"
      [title]="message?.title"
      [performer]="message?.performer"
      [mainThemeColor]="themeColorFile"
      [secondThemeColor]="'#E7EEFC'"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
    >
      <app-profile-image
        [isSquare]="true"
        [id]="message?.group_id"
        [chatType]="'Channel'"
        [size]="48"
      >
      </app-profile-image>
    </app-audio-cell>
    <div
      class="container"
      *ngIf="
        (message?.caption || message?.location_name) &&
        message?.type !== mType.ARTICLE &&
        message?.type !== mType.CALENDAR
      "
    >
      <ng-container>
        <app-text-cell
          *ngIf="message?.caption"
          class="post-caption text wrap-text copyable-item text-cursor"
          dir="auto"
          [text]="message?.caption"
        >
        </app-text-cell>

        <div
          *ngIf="message?.location_name"
          class="post-caption text wrap-text copyable-item text-cursor"
        >
          {{ message?.location_name }} <br />
          {{ message?.location_details }}
        </div>
      </ng-container>
    </div>
    <app-colored-text-cell
      *ngIf="
        message?.type === mType.TEXT &&
        (message?.web_page_preview !== linkPreviewStyle.WEB_PREVIEW_HIDE_LINK ||
          !message?.linkPreviewTitle) &&
        message?.bg_color
      "
      class="text"
      dir="auto"
      [text]="message?.text"
      [bgColor]="message?.bg_color"
    >
    </app-colored-text-cell>
    <app-calendar-preview-cell
      *ngIf="message?.type === mType.CALENDAR"
      class="text wrap-text"
      [calendarName]="message?.json?.title"
      [calendarDescription]="message?.json?.description"
      [calendarAvtar]="message?.json?.url"
    >
    </app-calendar-preview-cell>
    <app-instant-cell
      *ngIf="message?.type === mType.ARTICLE || message?.type === mType.TEXT"
      class="text"
      dir="auto"
      [message]="message"
      [redirect]="!(hideHeader && hideFooter)"
    >
      <ng-container
        *ngIf="
          tabsList &&
          tabsList?.length > 0 &&
          getTabContainer(message?.tab) as tab
        "
      >
        <ng-container
          *ngIf="
            appMgmtService.getPostComponentBySectionRef$(tab.ref)
              | async as component
          "
        >
          <div
            *ngIf="
              message?.style !== mStyles.LEFT_SIDE && message?.sticky_title
            "
            [ngStyle]="{ 'background-color': component.sticky_bg }"
            class="sticky-note"
          >
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_title }}
            </div>
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_desc }}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </app-instant-cell>
    <app-file-cell
      *ngIf="message?.type === mType.DOCUMENT"
      class="file no-copyable-item clickable-cursor"
      [localMedia]="message?.localMedia"
      [fileName]="message?.document_name"
      [fileSize]="message?.file_size"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [themeColor]="themeColorFile"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
    >
    </app-file-cell>
    <app-image-cell
      *ngIf="
        message?.type === mType.PHOTO ||
        (message?.type === mType.GIF && !message?.media_id?.endsWith('mp4'))
      "
      class="media no-copyable-item clickable-cursor"
      [class.left-side-img]="message?.style === mStyles.LEFT_SIDE"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localThumbnail]="message?.localThumbnail"
      [localMedia]="message?.localMedia"
      [fileSize]="message?.file_size"
      [themeColor]="themeColorMedia"
      [height]="message?.media_height"
      [width]="message?.media_width"
      [isPostCell]="true"
      [messageStyle]="message?.style"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
      (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
    >
      <!-- Sticky -->
      <ng-container
        *ngIf="
          tabsList &&
          tabsList?.length > 0 &&
          getTabContainer(message?.tab) as tab
        "
      >
        <ng-container
          *ngIf="
            appMgmtService.getPostComponentBySectionRef$(tab.ref)
              | async as component
          "
        >
          <div
            *ngIf="
              message?.style !== mStyles.LEFT_SIDE && message?.sticky_title
            "
            [ngStyle]="{ 'background-color': component.sticky_bg }"
            class="sticky-note"
          >
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_title }}
            </div>
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_desc }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- End: Sticky -->
    </app-image-cell>

    <app-gif-cell
      *ngIf="message?.type === mType.GIF && message?.media_id?.endsWith('mp4')"
      class="media no-copyable-item clickable-cursor"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localMedia]="message?.localMedia"
      [localThumbnail]="message?.localThumbnail"
      [fileSize]="message?.file_size"
      [themeColor]="themeColorMedia"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
      (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
    >
      <!-- Sticky -->
      <ng-container
        *ngIf="
          tabsList &&
          tabsList?.length > 0 &&
          getTabContainer(message?.tab) as tab
        "
      >
        <ng-container
          *ngIf="
            appMgmtService.getPostComponentBySectionRef$(tab.ref)
              | async as component
          "
        >
          <div
            *ngIf="
              message?.style !== mStyles.LEFT_SIDE && message?.sticky_title
            "
            [ngStyle]="{ 'background-color': component.sticky_bg }"
            class="sticky-note"
          >
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_title }}
            </div>
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_desc }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- End: Sticky -->
    </app-gif-cell>

    <app-video-cell
      *ngIf="message?.type === mType.VIDEO"
      class="media no-copyable-item clickable-cursor"
      [mediaStatus]="message?.mediaStatus"
      [percent]="message?.loadingProgress"
      [localMedia]="message?.localMedia"
      [localThumbnail]="message?.localThumbnail"
      [fileSize]="message?.file_size"
      [mediaDuration]="message?.media_duration"
      [themeColor]="themeColorMedia"
      [message]="message"
      (retryNotify)="retryEvent($event)"
      (cancelNotify)="cancelEvent($event)"
      (mediaClickedNotifier)="fullScreenEvent(message?.mediaStatus)"
    >
      <!-- Sticky -->
      <ng-container
        *ngIf="
          tabsList &&
          tabsList?.length > 0 &&
          getTabContainer(message?.tab) as tab
        "
      >
        <ng-container
          *ngIf="
            appMgmtService.getPostComponentBySectionRef$(tab.ref)
              | async as component
          "
        >
          <div
            *ngIf="
              message?.style !== mStyles.LEFT_SIDE && message?.sticky_title
            "
            [ngStyle]="{ 'background-color': component.sticky_bg }"
            class="sticky-note"
          >
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_title }}
            </div>
            <div [ngStyle]="{ color: component.sticky_text_color }">
              {{ message?.sticky_desc }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- End: Sticky -->
    </app-video-cell>
  </div>
  <app-menu-holder
    *ngIf="message?.inline_menu"
    [menus]="menus"
    [messageDetails]="message"
    [bot]="true"
    [hideTextOnlySec]="true"
    (buttonClickedNotify)="buttonClicked($event)"
  ></app-menu-holder>
  <!-- End: Body section -->

  <!-- Coupon Section -->
  <div class="post-coupon" *ngIf="message?.c_code">
    <ngx-barcode
      [bc-value]="message?.c_code"
      [bc-display-value]="true"
      [bc-font-size]="12"
      [bc-element-type]="'img'"
      [bc-background]="'transparent'"
      [bc-width]="1"
      [bc-height]="50"
      [bc-margin]="0"
      [bc-margin-top]="0"
      [bc-margin-bottom]="0"
      [bc-margin-right]="0"
      [bc-margin-left]="0"
    ></ngx-barcode>
  </div>
  <!-- end Coupon section -->

  <!-- Start: Footer section -->
  <div *ngIf="!hideFooter" class="footer no-copyable-item">
    <div class="box clickable-cursor">
      <img
        *ngIf="!message.myLike"
        src="./assets/img/heart-1.svg"
        alt="like"
        (click)="likeEvent()"
      />

      <img
        *ngIf="message.myLike"
        src="./assets/img/red-heart.svg"
        alt="like"
        (click)="likeEvent()"
        style="width: 28px; height: 28px; margin-right: 5px;"
      />
      <span>{{ message?.likes | countSize }}</span>
    </div>
    <div class="box clickable-cursor">
      <img
        *ngIf="!message?.numberOfUnreadReplies"
        src="./assets/img/comment.svg"
        alt="comment"
        (click)="selectEvent()"
      />
      <img
        *ngIf="message?.numberOfUnreadReplies"
        src="./assets/img/reply-comment.svg"
        alt="share"
        (click)="selectEvent()"
      />
      <span *ngIf="showRepliesCount">
        {{ message?.numberOfReplies | countSize }}
      </span>
    </div>
    <div class="box">
      <img src="./assets/img/eye.svg" alt="" />
      <span>{{ message?.views | countSize }}</span>
    </div>
    <ng-container *ngIf="isPublicChannel || project === merchant">
      <div class="box share" (click)="shareEvent()">
        <img
          *ngIf="isShareable"
          class="clickable-cursor"
          src="./assets/img/share.svg"
          alt="share"
        />
        <img
          *ngIf="!isShareable"
          src="./assets/img/share-deemed.svg"
          alt="share"
        />
        <span>{{ message?.shares | countSize }}</span>
      </div>
    </ng-container>
  </div>
  <!-- End: Footer section -->

  <div
    *ngIf="
      (uiService.getChatByID$(message?.group_id) | async)?.isPublic &&
      isShareable &&
      !hideFooter &&
      openShare
    "
    [@slideToUp]
    class="share-container"
  >
    <app-post-share [shareLink]="message?.link"> </app-post-share>
  </div>
</div>

<!-- Start: Share Container -->

<!-- End: Share Container -->
