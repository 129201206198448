import {
  SET_BOOKING_PERIODS_METHOD,
  CREATE_BOOKING_PERIODS_REF,
  GET_BOOKING_PERIODS_METHOD,
  BOOKING_DAYS,
  REMOVE_BOOKING_PERIODS_METHOD
} from './constants';
import { NgModuleCompileResult } from '@angular/compiler/src/ng_module_compiler';

export interface DayPeriods {
  day?: BOOKING_DAYS;
  references?: string[];
}

export interface TimeRange {
  reference?: string;
  startTime?: string;
  endTime?: string;
  maxTicketsPerSlot?: number; // default = 1
  maxTicketsPerAccount?: number; // default = 0
  price?: number;
  currency?: string;
}

export interface BookingHolidays {
  chat_id?: string;
  data?: string[];
  recordIndex?: number;
}

export interface DayTimes {
  isOpen?: boolean;
  day?: BOOKING_DAYS;
  product_id?: number;
  hours?: TimeRange[];
}

export interface BookingPeriods {
  recordIndex?: number;
  chat_id?: string;
  data?: DayTimes[];
}

class SetBookingPeriods {
  readonly method = SET_BOOKING_PERIODS_METHOD;
  constructor(
    public chat_id: string,
    public data: DayTimes[],
    public reference?: string,
    public recordIndex?: number
  ) {}
}

export class RemoveBookingPeriods {
  readonly method = REMOVE_BOOKING_PERIODS_METHOD;
  constructor(public chat_id: string, public data: DayPeriods[]) {}
}

export class GetBookingPeriods {
  readonly method = GET_BOOKING_PERIODS_METHOD;
  constructor(public chat_id: string) {}
}

export function setBookingPeriods(
  bookingPeriods: BookingPeriods,
  createNew?: boolean
) {
  let reference = null;
  if (createNew) {
    reference = CREATE_BOOKING_PERIODS_REF;
  }
  return new SetBookingPeriods(
    bookingPeriods.chat_id,
    bookingPeriods.data,
    reference,
    bookingPeriods.recordIndex
  );
}
