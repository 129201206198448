import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit
} from '@angular/core';
import { AppMgmtService } from '../appMgmt.service';
import {
  ChannelAppScreens,
  PagesLinks,
  FeatureItem,
  TabTypes,
  ThemeStatus,
  PageFormContainerTypes,
  MENU_CELLS_CATEGORIES
} from 'src/models/constants';
import { LocalItem, LocalAppClass } from 'src/models/ChannelAppLocalConfig';
import { FeaturesConfComponent } from './custom-area/features-conf/features-conf.component';
import { environment } from 'src/environments/environment';
import { LocalContainer } from './../../../../../models/ChannelAppLocalConfig';
import { BotsService } from './../../bots/bots.service';
import { MenusService } from '../../menus/menus.service';
import { AppInfo, AppClass } from 'src/models/ChannelAppConfig';
import { BotMenu } from 'src/models/IBotMenu';
// import { OnChange } from 'ngx-bootstrap';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { UiService } from './../../../../core/ui.service';
import { AuthService } from './../../../../core/auth.service';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
// import { MainPageConfComponent } from './custom-area/main-page-conf/main-page-conf.component';
import { MyChannelService } from '../../my-channel/my-channel.service';
import { DashboardService } from '../../dashboard.service';
import { SubChatsService } from '../../sub-chats/sub-chats.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { StorageService } from 'src/app/core/storage.service';
import { MyPageContainer } from 'src/models/MyPageConfig';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() item: LocalItem;
  @Input() myPage: any;
  @Input() isSubChannel: boolean;
  @Input() menusAppClass: LocalAppClass;
  @Output() settingsShowHideNotify = new EventEmitter<boolean>();
  @Output() mobViewNotify = new EventEmitter<string>();
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Input() appClass: LocalAppClass;
  @Output() selectedMainFeature = new EventEmitter<boolean>();
  @Output() tempPageCreatedNotify = new EventEmitter<boolean>();
  @Input() currentPage: any;
  @Input() pageChanged: boolean;
  @Input() appInfo: AppInfo;
  @Input() component: any;
  @Input() fired: boolean;
  slider: MyPageContainer = {};
  video: MyPageContainer = {};
  text: MyPageContainer = {};
  location: MyPageContainer = {};
  gallery: MyPageContainer = {};
  pdf: MyPageContainer = {};
  features: MyPageContainer = {};
  youTubeListContainer: MyPageContainer = {};
  pagesTypes = PageFormContainerTypes;
  workFlow = true;
  createNewMenu = false;
  showMenuBtn = false;
  pageToView: any;
  showPageSpices = false;
  newTempPage: any;
  appScreen = ChannelAppScreens;
  pagetemplate: any;
  @ViewChild(FeaturesConfComponent, { static: false })
  featuresConf: FeaturesConfComponent;
  helpPage: string;
  back: boolean;
  navMenu = true;
  tabs: boolean;
  menu: boolean;
  showSideMenu: boolean;
  channelApp: boolean;
  showReleases = true;
  appScreens = ChannelAppScreens;
  links: PagesLinks[];
  activation: boolean;
  currentPageSubscribtion = null;
  youTubeList: any[];
  isProduction = environment.production;
  createPage: boolean;
  @Input() hideFeatureMenu: boolean;
  @Input() showSettings: boolean;
  @Input() tabContainerList: LocalContainer[];
  @Input() allTabsList: LocalContainer[];
  @Input() itemList: LocalItem[];
  @Input() createdPage: any;
  @Input() sideMenuStatus: any;
  @Input() menuChanged: any;
  @Input() mobileStoreAppList: any[];
  calledList = [];
  tabsLength = 0;
  displayedMenu: BotMenu = {};
  selectedFeature: any;
  hideSettingMenu: boolean;
  splashContainer: FeatureItem[];
  bubbleTheme: string;
  mobScreen: number;
  tabTypes = TabTypes;
  themeStatus = ThemeStatus;
  formTitle: string;
  menuSubscription = null;
  cellsCat = MENU_CELLS_CATEGORIES;

  constructor(
    public appManagementService: AppMgmtService,
    private _ref: ChangeDetectorRef,
    public botsService: BotsService,
    public menusService: MenusService,
    public uiService: UiService,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers,
    public layoutService: LayoutService,
    public authService: AuthService,
    public myChannelService: MyChannelService,
    public dashboardService: DashboardService,
    public subChatsService: SubChatsService,
    public subscriptionService: SubscriptionService,

    public localStorage: StorageService,
    public mobileWorkFlowService: MobileWorkFlowStoreService
  ) {
    // this._paymentProvidersDispatchers.getPaymentProviders();
  }
  ngAfterViewInit() {
    if (
      (this.currentPage === this.appScreens.TABS ||
        this.currentPage === this.appScreens.NAV_MENU) &&
      !this.menuChanged
    ) {
      this.appManagementService._menuStatusTabNav.next(true);
      this.layoutService.setSideMenuStatus(1);
      this.appManagementService._workFlowScreens.next(true);
    } else if (
      this.currentPage !== this.appScreens.TABS &&
      this.currentPage !== this.appScreens.NAV_MENU &&
      (this.localStorage.getRecord('sideMenu') === 'null' ||
        this.localStorage.getRecord('sideMenu') === null)
    ) {
      this.layoutService.setSideMenuStatus(0);
    }
  }

  ngOnChanges() {
    if (this.tabsLength == 0 && this.tabContainerList.length > 0) {
      this.tabContainerList.forEach(tab => {
        if (tab.sub_pages) {
          tab.sub_pages.forEach(pageId => {
            this.appManagementService.getDetailsOfLinkedPage(pageId);
          });
        }

        if (tab.type === this.tabTypes.MENUS && tab.sub_type) {
          if (tab.store_id) {
            let getStore = false;
            if (this.mobileStoreAppList) {
              this.mobileStoreAppList.forEach(store => {
                if (store.id + '' === tab.store_id) {
                  getStore = true;
                }
              });
            }

            if (!getStore && this.calledList.indexOf(tab.store_id) == -1) {
              this.calledList.push(tab.store_id);

              this.mobileWorkFlowService.getMobileStoreById(tab.store_id);
            }
          } else {
            let getStore = false;
            if (this.mobileStoreAppList) {
              this.mobileStoreAppList.forEach(store => {
                if (store.is_default) {
                  getStore = true;
                }
              });
            }

            if (!getStore && this.calledList.indexOf('1') == -1) {
              this.calledList.push('1');

              this.mobileWorkFlowService.getMobileStoreById();
            }
          }
        }
      });
      this.tabsLength = this.tabContainerList.length;
      this.itemList.forEach(item => {
        if (item.page_id && item.sub_pages) {
          item.sub_pages.forEach(pageId => {
            this.appManagementService.getDetailsOfLinkedPage(pageId);
          });
        }

        if (item.page_id) {
          this.appManagementService.getDetailsOfLinkedPage(item.page_id);
        }
        if (item.link === this.tabTypes.MENUS && item.sub_type) {
          if (item.store_id) {
            let getStore = false;
            if (this.mobileStoreAppList) {
              this.mobileStoreAppList.forEach(store => {
                if (store.id + '' === item.store_id) {
                  getStore = true;
                }
              });
            }

            if (!getStore && this.calledList.indexOf(item.store_id) == -1) {
              this.calledList.push(item.store_id);

              this.mobileWorkFlowService.getMobileStoreById(item.store_id);
            }
          } else {
            let getStore = false;
            if (this.mobileStoreAppList) {
              this.mobileStoreAppList.forEach(store => {
                if (store.is_default) {
                  getStore = true;
                }
              });
            }
            if (!getStore && this.calledList.indexOf('1') == -1) {
              this.calledList.push('1');

              this.mobileWorkFlowService.getMobileStoreById();
            }
          }
        }
      });
    }
    if (
      this.localStorage.getRecord('sideMenu') === 'open' &&
      !this.sideMenuStatus &&
      !this.menuChanged
    ) {
      this.layoutService.setSideMenuStatus(1);
    } else if (
      this.localStorage.getRecord('sideMenu') === 'close' &&
      this.sideMenuStatus &&
      !this.menuChanged
    ) {
      this.layoutService.setSideMenuStatus(0);
    }

    if (
      (this.currentPage === this.appScreens.TABS ||
        this.currentPage === this.appScreens.NAV_MENU) &&
      !this.menuChanged
    ) {
      this.appManagementService._menuStatusTabNav.next(true);
      this.layoutService.setSideMenuStatus(1);
      this.appManagementService._workFlowScreens.next(true);
    } else if (
      this.currentPage !== this.appScreens.TABS &&
      this.currentPage !== this.appScreens.NAV_MENU &&
      (this.localStorage.getRecord('sideMenu') === 'null' ||
        this.localStorage.getRecord('sideMenu') === null)
    ) {
      this.layoutService.setSideMenuStatus(0);
    }

    // this.localItemAsyn();
    if (this.appInfo && !this.appInfo.layout) {
      const appInfo: AppInfo = {};
      appInfo.layout = this.themeStatus.Top;
      this.appManagementService.updateAppInfo(appInfo);
    }

    if (
      this.currentPage === this.appScreen.TABS &&
      this.appInfo &&
      this.appInfo.layout === this.themeStatus.Menu
    ) {
      this.formTitle =
        'Drag and drop modules from the categories section to install them.';
    } else if (this.currentPage === this.appScreen.TABS) {
      this.formTitle =
        'Drag and drop up to four modules from the categories section to install them.';
    } else {
      this.displayedMenu = {};
    }
  }

  ngOnInit(): void {
    if (
      this.localStorage.getRecord('sideMenu') === 'open' &&
      !this.sideMenuStatus
    ) {
      this.layoutService.setSideMenuStatus(1);
    } else if (
      this.localStorage.getRecord('sideMenu') === 'close' &&
      this.sideMenuStatus
    ) {
      this.layoutService.setSideMenuStatus(0);
    }
    if (
      this.currentPage === this.appScreens.TABS ||
      this.currentPage === this.appScreens.NAV_MENU
    ) {
      this.layoutService.setSideMenuStatus(1);
    } else if (
      this.currentPage !== this.appScreens.TABS &&
      this.currentPage !== this.appScreens.NAV_MENU &&
      (this.localStorage.getRecord('sideMenu') === 'null' ||
        this.localStorage.getRecord('sideMenu') === null)
    ) {
      this.layoutService.setSideMenuStatus(0);
    }
    this.appManagementService._showSetting.next(false);
    this.navMenu = true;
    this.tabContainerList.forEach(tab => {
      if (tab.sub_pages) {
        tab.sub_pages.forEach(pageId => {
          this.appManagementService.getDetailsOfLinkedPage(pageId);
        });
      }
      if (tab.type === this.tabTypes.MENUS && tab.sub_type) {
        if (tab.store_id) {
          let getStore = false;
          if (this.mobileStoreAppList) {
            this.mobileStoreAppList.forEach(store => {
              if (store.id + '' === tab.store_id) {
                getStore = true;
              }
            });
          }
          if (!getStore && this.calledList.indexOf(tab.store_id) == -1) {
            this.calledList.push(tab.store_id);

            this.mobileWorkFlowService.getMobileStoreById(tab.store_id);
          }
        } else {
          let getStore = false;
          if (this.mobileStoreAppList) {
            this.mobileStoreAppList.forEach(store => {
              if (store.is_default) {
                getStore = true;
              }
            });
          }
          if (!getStore && this.calledList.indexOf('1') == -1) {
            this.calledList.push('1');

            this.mobileWorkFlowService.getMobileStoreById();
          }
        }
      }
    });

    this.tabsLength = this.tabContainerList.length;
    this.itemList.forEach(item => {
      if (item.page_id && item.sub_pages) {
        item.sub_pages.forEach(pageId => {
          this.appManagementService.getDetailsOfLinkedPage(pageId);
        });
      }

      if (item.page_id) {
        this.appManagementService.getDetailsOfLinkedPage(item.page_id);
      }
      if (item.link === this.tabTypes.MENUS && item.sub_type) {
        if (item.store_id) {
          let getStore = false;
          if (this.mobileStoreAppList) {
            this.mobileStoreAppList.forEach(store => {
              if (store.id + '' === item.store_id) {
                getStore = true;
              }
            });
          }

          if (!getStore && this.calledList.indexOf(item.store_id) == -1) {
            this.calledList.push(item.store_id);

            this.mobileWorkFlowService.getMobileStoreById(item.store_id);
          }
        } else {
          let getStore = false;
          if (this.mobileStoreAppList) {
            this.mobileStoreAppList.forEach(store => {
              if (store.is_default) {
                getStore = true;
              }
            });
          }
          if (!getStore && this.calledList.indexOf('1') == -1) {
            this.calledList.push('1');

            this.mobileWorkFlowService.getMobileStoreById();
          }
        }
      }
    });

    if (this.isSubChannel) {
      this.authService.updateProgressWeb('2', 2);
      this._ref.detectChanges();
    }
    this._ref.detectChanges();
    this.bubbleTheme = '';
    this.showSettings = false;
    this.activation = false;
    this.currentPageSubscribtion = this.appManagementService.currentPage$.subscribe(
      currentPage => {
        if (currentPage === this.appScreens.APP_RELEASES) {
          this.links = [];
          const fristLink: PagesLinks = {};
          fristLink.title = '7 Steps to Enroll in The Apple Developer Program';
          fristLink.link =
            'https://nandbox.com/en/seven-steps-to-enroll-in-the-apple-developer-program/';
          const secLink: PagesLinks = {};
          secLink.title =
            '3 Steps to Add publishing@nandbox.com to Your Apple Developer Account';
          secLink.link =
            'https://nandbox.com/en/3-steps-to-add-publishingnandbox-com-to-your-apple-developer-account/';
          const thirdLink: PagesLinks = {};
          thirdLink.title = '4 Steps to Generate the App-Specific Password';
          thirdLink.link =
            'https://nandbox.com/en/the-app-specific-password-is-ready-in-four-steps-on-your-apple-account/';
          const fourLink: PagesLinks = {};
          fourLink.title =
            '2 Steps to Check the Entity Type and Team Name of your Apple Developer Account';
          fourLink.link =
            'https://nandbox.com/en/how-to-check-your-apple-developer-accounts-team-name-and-membership/';
          const fiveLink: PagesLinks = {};
          fiveLink.title = 'How to Open a Google Developer Account';
          fiveLink.link =
            'https://nandbox.com/en/how-to-open-a-google-developer-account-to-publish-your-nandbox-built-app-on-the-play-store/';
          const sixLink: PagesLinks = {};
          sixLink.title =
            '6 basic steps on Google Play Console to anchor your app on the Play Store';
          sixLink.link =
            'https://nandbox.com/en/the-ultimate-guide-to-publishing-your-app-on-google-play-store/';
          this.links = [
            fristLink,
            secLink,
            thirdLink,
            fourLink,
            fiveLink,
            sixLink
          ];
          this.activation = false;
        } else if (currentPage === this.appScreens.ACTIVATION) {
          this.activation = true;
          this.links = [];
        } else {
          this.activation = false;
          this.links = [];
          // const fristLink: PagesLinks = {};
          // fristLink.title = '7 Steps to Enroll in The Apple Developer Program';
          // fristLink.link =
          //   'https://nandbox.com/en/seven-steps-to-enroll-in-the-apple-developer-program/';
          // const secLink: PagesLinks = {};
          // secLink.title =
          //   '3 Steps to Add publishing@nandbox.com to Your Apple Developer Account';
          // secLink.link =
          //   'https://nandbox.com/en/3-steps-to-add-publishingnandbox-com-to-your-apple-developer-account/';
          // this.links = [fristLink, secLink];
        }
      }
    );
    if (this.currentPage === this.appScreens.TABS) {
      this.appManagementService._workFlowScreens.next(true);
    }
    this.showSideMenu = true;
    this.channelApp = false;
  }
  // addTabs() {
  //   if (this.menusAppClass) {
  //     let mainMenu: BotMenu = {};
  //     let maianMenuAppClass: AppClass = {};

  //     mainMenu = this.menusAppClass.menus[0];

  //     maianMenuAppClass = this.menusAppClass;

  //     const menucontainer: LocalContainer = {};
  //     menucontainer.type = this.tabTypes.MENUS;
  //     menucontainer.menu_id = mainMenu.menu_ref;
  //     this.appManagementService.addContainer(this.appClass, menucontainer);
  //     let buttonId = 0;

  //     for (let i = 0; i < mainMenu.rows.length; i++) {
  //       for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
  //         buttonId++;

  //         const buttonContainer: LocalContainer = {};
  //         buttonContainer.type = '';
  //         buttonContainer.layout = 'button';
  //         buttonContainer.id = mainMenu.rows[i].buttons[j].button_id;
  //         buttonContainer.menu_id = mainMenu.menu_ref;
  //         buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
  //         buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

  //         this.appManagementService.addContainer(
  //           this.appClass,
  //           buttonContainer
  //         );
  //       }
  //     }
  //   }
  // }
  changeType(item) {
    if (item) {
      this.item = item;
      this._ref.detectChanges();
    } else {
      this.item = null;
      this._ref.detectChanges();
    }
  }

  backToTabs() {
    this.tabs = false;
    this.menu = false;
    this.item = null;
    this.navMenu = true;
    this.appManagementService.unselectItem();
    this.appManagementService.selectFirstTab();
  }

  savePage() {
    this.tabs = false;
    this.menu = false;
    this.item = null;
    this.appManagementService.selectFirstTab();
    this.appManagementService.savePage(false);
  }

  showMenu() {
    this.tabs = false;
    this.menu = false;
    if (this.item && this.item.link === 'settings') {
      this.navMenu = false;
    } else {
      this.navMenu = true;
    }
  }
  showCustom() {
    this.tabs = true;
    this.menu = false;
  }
  get tabsValue() {
    return this.tabs;
  }
  get menuValue() {
    return this.menu;
  }
  backToNormal() {
    this.navMenu = true;
  }
  selectMenuItem(item) {
    this.tabs = false;
    this.menu = true;
    this.navMenu = false;
    this.changeType(item);
    this.item = item;
    this.appManagementService._showSetting.next(true);
    for (let i = 0; i < this.itemList.length; i++) {
      if (this.itemList[i].ref === item.ref) {
        const newItem: LocalItem = {};
        newItem.selected = true;
        this.appManagementService.updateItem(this.itemList[i].ref, newItem);
      } else {
        const newItem: LocalItem = {};
        newItem.selected = false;
        this.appManagementService.updateItem(this.itemList[i].ref, newItem);
      }
    }

    this.selectFeature(item);
  }

  localItemAsyn() {
    this.appManagementService._navMenuSelectedItem.next(this.item);
  }

  selectFeature(item) {
    this.selectedMainFeature.emit(item);
  }
  get localItem() {
    return this.item;
  }

  showReleasesAction() {
    this.showReleases = true;
  }

  hideReleases() {
    this.showReleases = false;
  }
  ngOnDestroy() {
    if (this.currentPageSubscribtion) {
      this.currentPageSubscribtion.unsubscribe();
      this.currentPageSubscribtion = null;
    }
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }
  youTube(event) {
    this.youTubeList = event;
  }

  createAppTemplate() {
    this.appManagementService.createAppTemplate(
      'androidUrl',
      'iosUrl',
      ['D', 'E'],
      '',
      0,
      null
    );
  }
  helpValue(event) {
    this.helpPage = event;
    this._ref.detectChanges();
  }
  createPages(feature) {
    this.createPage = true;
  }

  hideCreatepage() {
    this.createPage = false;
    this.pagetemplate = null;
    this.appManagementService._createPage.next(false);
    this._ref.detectChanges();
  }

  createPageTemp(event) {
    setTimeout(() => {
      const newContainer: any = {};
      this.appManagementService
        .getLeatestMyPage$(event.name, event.template_id)
        .subscribe(page => {
          newContainer.page_id = page.id;
          newContainer.title = page.name;
          newContainer.icon = 'ic_feeds_books_white_24_dp';
          newContainer.url = page.url;
          newContainer.type = this.tabTypes.PAGE;
          if (event.button_id) {
            newContainer.layout = 'button';
            newContainer.id = event.button_id;
          }
        });

      if (this.currentPage === this.appScreen.TABS) {
        const container: LocalContainer = this.allTabsList.find(
          res => res.id === event.button_id
        );

        if (!container) {
          this.appManagementService.addContainer(this.appClass, newContainer);
          // this.appManagementService._selectedMenuButtonTab.next(newContainer);
        } else {
          this.appManagementService.updateContainer(
            container.ref,
            newContainer
          );
        }
        this.appManagementService._selectedPageIdToView.next(
          newContainer.page_id
        );
        if (!this.showSettings) {
          this.appManagementService.selectLastTab();
        }
      } else if (this.currentPage === this.appScreen.NAV_MENU) {
        const comp: any = {};
        newContainer.link = this.tabTypes.PAGE;

        this.appManagementService.addItem(this.component, newContainer);
      }

      this.pagetemplate = null;
      this.createPage = false;
      this.newTempPage = null;
      this.appManagementService._createNewTab.next(false);
      this.appManagementService._createPage.next(false);
    }, 1000);
  }

  hideAndShowMenu(event) {
    // this.hideFeatureMenu = event;

    if (
      this.currentPage !== this.appScreen.TABS &&
      this.currentPage !== this.appScreen.NAV_MENU
    ) {
      this.backToTabs();
    }
    if (!event) {
      this.settingsShowHideNotify.emit(false);
    } else {
      this.settingsShowHideNotify.emit(true);
    }
  }
  hideSettingsMenu() {
    this.hideSettingMenu = true;
    this._ref.detectChanges();
  }
  bubbleChanged(event) {
    this.bubbleTheme = event;
  }

  mobView(event) {
    this.mobScreen = event;
  }
  pageTemp(event) {
    this.createPage = true;
    this.newTempPage = event;

    this._ref.detectChanges();
  }
  asignTemp(event) {
    this.pagetemplate = event;

    this._ref.detectChanges();
  }
  selectedCategory(event) {
    this.selectedFeature = event;
    this.appManagementService._selectedFeature.next(event);
  }
  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }
  selectedMenu(selectedMenu) {
    this.displayedMenu = selectedMenu;
    this._ref.detectChanges();
  }

  showPageView(event) {
    if (event !== null) {
      this.appManagementService.getDetailsOfLinkedPage(event.id);
      this.showPageSpices = true;
      this.pageToView = event;
    }
  }
  getMenu(event) {
    this.displayedMenu = event;

    this._ref.detectChanges();
  }

  showMenuConfig(event) {
    this.showMenuBtn = event;
    this.appManagementService._showMenuBtn.next(event);
    this._ref.detectChanges();
  }

  createNewMenuForm() {
    this.createNewMenu = true;
    this._ref.detectChanges();
  }
  closeMenuForm() {
    this.createNewMenu = false;
    this._ref.detectChanges();
  }
  workFlowStaus(event) {
    this.workFlow = event;
    this.appManagementService._workFlowScreens.next(event);
    this._ref.detectChanges();
  }

  onClickItem(id) {
    let found = false;
    const mypageContainer: MyPageContainer = {};

    if (id === this.pagesTypes.SLIDER) {
      mypageContainer.title = 'Image Carousel';
      mypageContainer.container_type = this.pagesTypes.SLIDER;
      found = true;
    } else if (id === this.pagesTypes.GALLERY) {
      mypageContainer.container_type = this.pagesTypes.GALLERY;
      mypageContainer.title = 'Image Gallery';
      mypageContainer.style = '1';
      found = true;
    } else if (id === this.pagesTypes.LIST) {
      mypageContainer.title = 'Features';
      mypageContainer.style = '1';
      mypageContainer.container_type = this.pagesTypes.LIST;
      found = true;
    } else if (id === this.pagesTypes.LOCATION) {
      mypageContainer.title = 'Location';
      mypageContainer.container_type = this.pagesTypes.LOCATION;
      found = true;
    } else if (id === this.pagesTypes.VIDEO) {
      mypageContainer.title = 'Video';
      mypageContainer.container_type = this.pagesTypes.VIDEO;
      found = true;
    } else if (id === this.pagesTypes.TEXT) {
      mypageContainer.title = 'Text';
      mypageContainer.container_type = this.pagesTypes.TEXT;
      found = true;
    } else if (id === this.pagesTypes.youTubeList) {
      mypageContainer.title = 'YouTube List';
      mypageContainer.container_type = this.pagesTypes.youTubeList;
      found = true;
    }
    if (!this.fired && found) {
      this.fired = true;

      this.appManagementService.addMyPageContainer(
        this.myPage,
        mypageContainer
      );
    }
    found = false;
    this._ref.detectChanges();
    this.afterFirstDrop(id);
  }
  afterFirstDrop(id) {
    let mypageContainer: MyPageContainer = {};
    let found = false;
    if (id === this.pagesTypes.SLIDER) {
      mypageContainer = { ...this.slider };
      found = true;
    } else if (id === this.pagesTypes.GALLERY) {
      mypageContainer = { ...this.gallery };
      found = true;
    } else if (id === this.pagesTypes.LIST) {
      mypageContainer = { ...this.features };
      found = true;
    } else if (id === this.pagesTypes.LOCATION) {
      mypageContainer = { ...this.location };
      found = true;
    } else if (id === this.pagesTypes.VIDEO) {
      mypageContainer = { ...this.video };
      found = true;
    } else if (id === this.pagesTypes.TEXT) {
      mypageContainer = { ...this.text };
      found = true;
    } else if (id === this.pagesTypes.youTubeList) {
      mypageContainer = { ...this.youTubeListContainer };
      found = true;
    }
    if (!this.fired && found) {
      this.appManagementService.addMyPageContainer(
        this.myPage,
        mypageContainer
      );
    }

    this.fired = false;
    found = false;
    this._ref.detectChanges();
  }
}
