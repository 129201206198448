import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-headerfull-frame',
  templateUrl: './headerfull-frame.component.html',
  styleUrls: ['./headerfull-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderfullFrameComponent {
  @Input() title: string;
  @Input() showClose = true;
  @Input() showBack = false;
  @Input() showTooltip = false;
  @Input() titleTooltip: string;
  @Input() descriptionTooltip: string;
  @Input() topHoverTooltip: number;
  @Input() leftHoverTooltip: number;
  @Input() hideShadow = false;
  @Output() cancelNotfiy = new EventEmitter<boolean>(false);
  @Output() backNotfiy = new EventEmitter<boolean>(false);

  onClose() {
    this.cancelNotfiy.emit(true);
  }
  onBack() {
    this.backNotfiy.emit(true);
  }
}
