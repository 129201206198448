import { Action } from '@ngrx/store';

import { ICoupon, RcvdCouponMessage } from 'src/models/ICoupon';

export const enum CouponActionTypes {
  COUPON_RECEIVED = '[CouponReducer]COUPON_RECEIVED',
  GET_COUPON = 'GET_COUPON',
  CREATE_COUPON = 'CREATE_COUPON',
  UPDATE_COUPON = 'UPDATE_COUPON',
  DELETE_COUPON = 'DELETE_COUPON',
  RECEIVED_COUPONS_LIST = 'RECEIVED_COUPONS_LIST'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class CouponReceived implements Action {
  readonly type = CouponActionTypes.COUPON_RECEIVED;
  constructor(public payload: RcvdCouponMessage) { }
}
export class GetCoupon implements Action {
  readonly type = CouponActionTypes.GET_COUPON;
  constructor(public payload: string) { }
}

export class CreateCoupon implements Action {
  readonly type = CouponActionTypes.CREATE_COUPON;
  constructor(public payload: ICoupon, public isCreateFromBool: boolean) { }
}
export class UpdateCoupon implements Action {
  readonly type = CouponActionTypes.UPDATE_COUPON;
  constructor(public payload: ICoupon) { }
}
export class DeleteCoupon implements Action {
  readonly type = CouponActionTypes.DELETE_COUPON;
  constructor(public payload: ICoupon) { }
}
export class ReceivedCouponsList implements Action {
  readonly type = CouponActionTypes.RECEIVED_COUPONS_LIST;
  constructor(public payload: ICoupon[]) { }
}

// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type CouponActions =
  | CouponReceived
  | CreateCoupon
  | GetCoupon
  | UpdateCoupon
  | DeleteCoupon
  | DeselectChannel
  | ReceivedCouponsList
  | Reset;
