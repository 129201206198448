import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import * as ChartActions from './chart.actions';
import * as ChartMethods from 'src/models/ChartUI';

@Injectable()
export class ChartEffects {
  @Effect({ dispatch: false })
  getChartData = this.actions$.pipe(
    ofType(ChartActions.ChartActionTypes.GET_CHART_DATA),
    map(val => {
      const action = <ChartActions.GetChartData>val;

      this._socketGateway.sendSocketMessage(
        new ChartMethods.GetChartData(
          action.chat_id,
          action.chart_type,
          action.interval,
          action.startDate,
          action.endDate
        )
      );
    })
  );

  constructor(
    private _store: Store<AppState>,
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
