import { Action } from '@ngrx/store';
import { MapTripUser } from 'src/models/MapTripUser';

export const enum MapTripUserActionTypes {
  RESET_MAP_TRIP_USERS_PAGING = 'RESET_MAP_TRIP_USERS_PAGING',
  GET_NEXT_MAP_TRIP_USERS_PAGE = 'GET_NEXT_MAP_TRIP_USERS_PAGE',
  GET_PREV_MAP_TRIP_USERS_PAGE = 'GET_PREV_MAP_TRIP_USERS_PAGE',
  CREATE_MAP_TRIP_USER = 'CREATE_MAP_TRIP_USER',
  MAP_TRIP_USER_CREATED = 'MAP_TRIP_USER_CREATED',
  UPDATE_MAP_TRIP_USER = 'UPDATE_MAP_TRIP_USER',
  MAP_TRIP_USER_DETAILS_RECEIVED = 'MAP_TRIP_USER_DETAILS_RECEIVED',
  MAP_TRIP_USER_LIST_RECEIVED = 'MAP_TRIP_USER_LIST_RECEIVED',
  DELETE_MAP_TRIP_USER = 'DELETE_MAP_TRIP_USER',
  MAP_TRIP_USER_DELETED = 'MAP_TRIP_USER_DELETED',
  RESET = 'RESET'
}

export class ResetMapTripUsersPaging implements Action {
  readonly type = MapTripUserActionTypes.RESET_MAP_TRIP_USERS_PAGING;
  constructor(public tripID: string) {}
}
export class GetNextMapTripUsersPage implements Action {
  readonly type = MapTripUserActionTypes.GET_NEXT_MAP_TRIP_USERS_PAGE;
  constructor() {}
}
export class GetPrevMapTripUsersPage implements Action {
  readonly type = MapTripUserActionTypes.GET_PREV_MAP_TRIP_USERS_PAGE;
  constructor() {}
}

export class CreateMapTripUser implements Action {
  readonly type = MapTripUserActionTypes.CREATE_MAP_TRIP_USER;
  constructor(public mapTripUser: MapTripUser) {}
}

export class MapTripUserCreated implements Action {
  readonly type = MapTripUserActionTypes.MAP_TRIP_USER_CREATED;
  constructor(public mapTripUser: MapTripUser) {}
}

export class UpdateMapTripUser implements Action {
  readonly type = MapTripUserActionTypes.UPDATE_MAP_TRIP_USER;
  constructor(public id: string, public mapTripUserDetails: MapTripUser) {}
}

export class MapTripUserDetailsReceived implements Action {
  readonly type = MapTripUserActionTypes.MAP_TRIP_USER_DETAILS_RECEIVED;
  constructor(public mapTripUser: MapTripUser) {}
}

export class MapTripUserListReceived implements Action {
  readonly type = MapTripUserActionTypes.MAP_TRIP_USER_LIST_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public mapTripUsers: MapTripUser[],
    public hash: string
  ) {}
}

export class DeleteMapTripUser implements Action {
  readonly type = MapTripUserActionTypes.DELETE_MAP_TRIP_USER;
  constructor(public id: string, public trip_id: string) {}
}

export class MapTripUserDeleted implements Action {
  readonly type = MapTripUserActionTypes.MAP_TRIP_USER_DELETED;
  constructor(public id: string) {}
}

export class Reset implements Action {
  readonly type = MapTripUserActionTypes.RESET;
}

export type Actions =
  | ResetMapTripUsersPaging
  | GetNextMapTripUsersPage
  | GetPrevMapTripUsersPage
  | CreateMapTripUser
  | MapTripUserCreated
  | UpdateMapTripUser
  | MapTripUserDetailsReceived
  | MapTripUserListReceived
  | DeleteMapTripUser
  | MapTripUserDeleted
  | Reset;
