<!-- Sidebar -->

<div
  *ngIf="mode === 'over'"
  class="item header clickable-cursor"
  (click)="toggleMenuEvent()"
>
  <div class="logo">
    <img
      alt="nandbox logo"
      src="https://nandbox.com/wp-content/uploads/2022/10/nandbox-logo.webp"
      alt="nandbox Native App Builder logo"
      height="36"
    />
  </div>
  <!-- <img
    *ngIf="isOpen"
    alt="nandbox logo"
    src="./assets/img/logo-w-text.svg"
    height="40"
  /> -->
</div>

<!-- Sections & SubSections -->

<!-- <ng-container *appShowIfHasPrivilege="privilegesName?.listapp">
  <div
    class="start-over-active"
    (click)="startOver()"
    *ngIf="(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus"
  >
    <img src="./assets/img/start-over.svg" />
  </div>
</ng-container> -->
<!-- <ng-container *ngIf="layoutService?.getsideMenuStatus$() | async as sideMenu">
  <div
    *ngIf="
      (sideMenu?.sideMenuStatus &&
        ((progressWeb != 2 && progressWeb != 3) || !progressWeb) &&
        direction === uiSections?.APP) ||
      (sideMenu?.sideMenuStatus && direction !== uiSections?.APP)
    "
    style="cursor: pointer;"
    class="menu-btn"
    [class.menu-btn-right]="sideMenu?.sideMenuStatus"
    (click)="setSideNavStatus(sideMenu?.sideMenuStatus)"
  >
    <div>
      <div class="fas fa-chevron-right clickable-cursor"></div>
    </div>
  </div>

  <div
    *ngIf="!sideMenu?.sideMenuStatus"
    style="cursor: pointer;"
    class="menu-btn"
    [class.menu-btn-left]="!sideMenu?.sideMenuStatus"
    (click)="setSideNavStatus(sideMenu?.sideMenuStatus)"
  >
    <div>
      <div class="fas fa-chevron-left clickable-cursor"></div>
    </div>
  </div>
</ng-container> -->
<div
  *ngFor="let section of layoutService?.appMenu"
  [class.side-menu-close]="
    (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
  "
  (click)="sectionEvent(section.section, null)"
>
  <ng-container *appHideIfMainChat="section?.hideOnMain">
    <ng-container *appHideIfSubChat="section?.hideOnSub">
      <ng-container
        *appHideIfSubChat="
          section?.section === uiSections?.CHANNEL &&
          !(authService?.authCollection$ | async)?.is_nandbox
        "
      >
        <ng-container>
          <ng-container>
            <ng-container *ngIf="section?.isCategory && isShowing">
              <div class="sec-category">
                <div class="sec-category-title">
                  {{ section.title | uppercase }}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- <ng-container *ngIf="section?.privilegeModule">
          <ng-container *appShowIfHasModule="section?.privilegeModule">
            <ng-container *ngIf="section?.isCategory && isShowing">
              <div class="sec-category">
                <div class="sec-category-title">
                  {{ section.title }}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container> -->
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- logic for hide app builder and BOT & API in case of normal user
   and show app builder and BOT & API and hide my channel in case of owner -->
  <ng-container
    *ngIf="
      !section.isCategory &&
      (((authService?.authCollection$ | async)?.role === 1 &&
        (authService?.authCollection$ | async)?.main_group_id ===
          (authService?.authCollection$ | async)?.chatId &&
        (section?.section === uiSections?.APP ||
          section?.section === uiSections?.BOTS)) ||
        (section?.section !== uiSections?.APP &&
          section?.section !== uiSections?.BOTS &&
          section?.section !== uiSections?.MYCH) ||
        ((authService?.authCollection$ | async)?.main_group_id !==
          (authService?.authCollection$ | async)?.chatId &&
          section?.section === uiSections?.MYCH) ||
        ((authService?.authCollection$ | async)?.main_group_id ===
          (authService?.authCollection$ | async)?.chatId &&
          (authService?.authCollection$ | async)?.role === 2 &&
          section?.section === uiSections?.MYCH))
    "
  >
    <ng-container *appHideIfMainChat="section?.hideOnMain">
      <ng-container>
        <ng-container>
          <ng-container *appHideIfSubChat="section?.hideOnSub">
            <ng-container
              *appHideIfSubChat="
                (section?.section === uiSections?.CHANNEL &&
                  !(authService?.authCollection$ | async)?.is_nandbox) ||
                (section?.section === uiSections?.CHANNEL &&
                  (authService?.authCollection$ | async)?.is_nandbox &&
                  (authService?.authCollection$ | async)?.role !== 2)
              "
            >
              <div
                class="item list clickable-cursor"
                [title]="section.title"
                [class.active]="isActive(section)"
                [ngStyle]="setItemStyle(section)"
                [class.border]="
                  (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
                "
              >
                <div class="item-inner">
                  <svg
                    class="svg-icon icon-icon"
                    [style.fill]="
                      isActive(section) ? section.symbolSideMenuColor : ''
                    "
                  >
                    <use
                      attr.xlink:href="./assets/svg/app-sections.svg#{{
                        section.symbolImage
                      }}"
                    ></use>
                  </svg>
                  <div
                    *ngIf="isOpen && isShowing"
                    class="core"
                    [style.color]="
                      isActive(section) ? section.symbolSideMenuColor : ''
                    "
                  >
                    {{ section.title }}
                  </div>

                  <div class="full-w"></div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- <ng-container *ngIf="section?.privilegeModule">
        <ng-container *appShowIfHasModule="section?.privilegeModule">
          <ng-container *appHideIfSubChat="section?.hideOnSub">
            <ng-container
              *appHideIfSubChat="
                (section?.section === uiSections?.CHANNEL &&
                  !(authService?.authCollection$ | async)?.is_nandbox) ||
                (section?.section === uiSections?.CHANNEL &&
                  (authService?.authCollection$ | async)?.is_nandbox &&
                  (authService?.authCollection$ | async)?.role !== 2)
              "
            >
              <div
                class="item list clickable-cursor"
                [title]="section.title"
                [class.active]="isActive(section)"
                [ngStyle]="setItemStyle(section)"
                [class.border]="
                  (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
                "
              >
                <div class="item-inner">
                  <svg
                    class="svg-icon icon-icon"
                    [style.fill]="
                      isActive(section) ? section.symbolSideMenuColor : ''
                    "
                  >
                    <use
                      attr.xlink:href="./assets/svg/app-sections.svg#{{
                        section.symbolImage
                      }}"
                    ></use>
                  </svg>
                  <div
                    *ngIf="isOpen && isShowing"
                    class="core"
                    [style.color]="
                      isActive(section) ? section.symbolSideMenuColor : ''
                    "
                  >
                    {{ section.title }}
                  </div>

                  <div class="full-w"></div>

                  <ng-container *ngIf="section?.subMenu && isShowing">
                  <div
                    *ngIf="section.showSubMenu"
                    class="fas fa-chevron-up clickable-cursor"
                    (click)="section.showSubMenu = !section.showSubMenu"
                  ></div>
                  <div
                    *ngIf="!section.showSubMenu"
                    class="fas fa-chevron-down clickable-cursor"
                    (click)="section.showSubMenu = !section.showSubMenu"
                  ></div>
                </ng-container> 
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container> -->
    </ng-container>
  </ng-container>
</div>

<ng-container>
  <ng-container>
    <ng-container>
      <ng-container>
        <ng-container>
          <ng-container
            *ngIf="layoutService?.getsideMenuStatus$() | async as sideMenu"
          >
            <div
              (click)="setSideNavStatus(sideMenu.sideMenuStatus)"
              class="item list clickable-cursor"
              style="box-shadow: -3px -1px 9px 0 rgba(0, 0, 0, 0.12);"
            >
              <div
                class="item-inner"
                [class.closed-icon]="!isOpen || !isShowing"
              >
                <img
                  class="svg-icon icon-icon"
                  src="./assets/img/sort-black-36-dp.svg"
                />

                <div *ngIf="isOpen && isShowing" class="core">
                  Collapse Sidebar
                </div>

                <div class="full-w"></div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
