import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SubItemDispatchers } from './subItem.dispatchers';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { initNewItem } from './subItem.middleware';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { REACHED_MAX_ITEM_LIMIT, REACHED_MIN_ITEM_LIMIT, TabTypes } from 'src/models/constants';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import * as isEqual from 'lodash.isequal';
export class SubItemEffects {
    constructor(actions$, _subItemDispatchers, _uiDispatchers, _myPageDispatchers, _store) {
        this.actions$ = actions$;
        this._subItemDispatchers = _subItemDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this._store = _store;
        this.addItemRequest = this.actions$.pipe(ofType("ADD_SUB_ITEM_REQUEST" /* ADD_SUB_ITEM_REQUEST */), withLatestFrom(this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, items]) => {
            const action = val;
            let itemsOfComponent = [];
            itemsOfComponent = items.filter(item => item.parentComponentRef === action.component.ref);
            if (action.component.max_items &&
                itemsOfComponent.length >= action.component.max_items) {
                this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
            }
            else {
                this._subItemDispatchers.addSubItem(initNewItem(action.component, action.specialItemValues));
            }
        }));
        this.deleteItemRequest = this.actions$.pipe(ofType("DELETE_SUB_ITEM_REQUEST" /* DELETE_SUB_ITEM_REQUEST */), withLatestFrom(this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, items, components]) => {
            const action = val;
            let itemsOfComponent = [];
            itemsOfComponent = items.filter(item => item.parentComponentRef === action.item.parentComponentRef);
            const parentComponent = components.find(comp => comp.ref === action.item.parentComponentRef);
            if (parentComponent &&
                parentComponent.min_items &&
                itemsOfComponent.length <= parentComponent.min_items) {
                this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
            }
            else {
                this._subItemDispatchers.deleteSubItem(action.item);
            }
        }));
        this.updateItemLink = this.actions$.pipe(ofType("UPDATE_SUB_ITEM_LINK" /* UPDATE_SUB_ITEM_LINK */), map((action) => {
            if (action.itemLink === TabTypes.PAGE && !action.pageId) {
                const pageRef = Date.now() + '';
                this._subItemDispatchers.updateSubItem(action.itemRef, {
                    page_ref: pageRef,
                    page_id: null,
                    template_id: null
                });
                this._myPageDispatchers.createMyPage(pageRef);
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubItemEffects.prototype, "addItemRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubItemEffects.prototype, "deleteItemRequest", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SubItemEffects.prototype, "updateItemLink", void 0);
