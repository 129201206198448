import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import * as CollectionsUIActions from './collectionsUI.actions';
import { Collection } from 'src/models/collectionsUI';
import { ProductStore } from 'src/models/productStore';

@Injectable({
  providedIn: 'root'
})
export class CollectionsUIDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetCollectionsPaging() {
    this._store.dispatch(new CollectionsUIActions.ResetCollectionsPaging());
  }

  getCollectionsNextPage() {
    this._store.dispatch(new CollectionsUIActions.GetCollectionsNextPage());
  }

  getCollectionsPrevPage() {
    this._store.dispatch(new CollectionsUIActions.GetCollectionsPrevPage());
  }

  receiveListCollectionsHistoryChunk(
    eop: number,
    sop: number,
    currentPage: Collection[]
  ): void {
    this._store.dispatch(
      new CollectionsUIActions.ReceiveListCollectionsHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  setCollectionItem(collection: Collection) {
    this._store.dispatch(
      new CollectionsUIActions.SetCollectionItem(collection)
    );
  }

  getCollectionItem(id: number, isSelectedItem?: boolean) {
    this._store.dispatch(
      new CollectionsUIActions.GetCollectionItem(id, isSelectedItem)
    );
  }

  setCollectionProducts(collectionID: number, productsID: number[]) {
    this._store.dispatch(
      new CollectionsUIActions.SetCollectionProducts(collectionID, productsID)
    );
  }

  collectionReceived(collection: Collection) {
    this._store.dispatch(
      new CollectionsUIActions.CollectionReceived(collection)
    );
  }

  deleteCollectionItem(id: number) {
    this._store.dispatch(new CollectionsUIActions.DeleteCollectionItem(id));
  }

  deleteCollectionItemSuccess(id: number) {
    this._store.dispatch(
      new CollectionsUIActions.DeleteCollectionItemSuccess(id)
    );
  }

  getCollectionProducts(collectionID: number) {
    this._store.dispatch(
      new CollectionsUIActions.GetCollectionProducts(collectionID)
    );
  }

  receveCollectionProducts(productStore: ProductStore[]) {
    this._store.dispatch(
      new CollectionsUIActions.ReceveCollectionProducts(productStore)
    );
  }
}
