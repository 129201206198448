import * as CouponActions from './coupon.actions';
import { CouponMiddleware } from './coupon.middleware';
import { ICoupon } from '../../../models/ICoupon';

export function couponReducer(
  state: ICoupon[] = [],
  action: CouponActions.CouponActions
): ICoupon[] {
  switch (action.type) {
    case CouponActions.CouponActionTypes.COUPON_RECEIVED:
      const localPayload = CouponMiddleware.initMessage(action.payload);
      if (!CouponMiddleware.isCouponExist(state, localPayload)) {
        return [localPayload, ...state];
      }
      return CouponMiddleware.updateCoupon(state, localPayload);
    case CouponActions.CouponActionTypes.UPDATE_COUPON:
      return CouponMiddleware.updateCoupon(state, action.payload);
    case CouponActions.CouponActionTypes.RECEIVED_COUPONS_LIST:
      return action.payload.length > 0 ? [...action.payload] : [...state];
    case CouponActions.DESELECT_CHANNEL:
    case CouponActions.RESET:
      return [];
    default:
      return state;
  }
}
