import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { BundlesUiDispatchers } from 'src/app/store/bundlesUI/bundlesUI.dispatchers';
import { ProductsUiDispatchers } from 'src/app/store/productsUI/productsUI.dispatchers';

import { Bundle, BundleProduct } from 'src/models/Bundle';
import { BundleScreens, ChatTypes, MarketTypes } from 'src/models/constants';
import { IChat } from 'src/models/IChat';
import { StoreMenuUiDispatchers } from 'src/app/store/storeMenuUI/storeMenuUI.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class BundlesService {
  selectedBundle: Bundle;
  chatTypes: typeof ChatTypes = ChatTypes;
  confirmDeleteBundle = false;
  selectedRemovedBundle: any;

  private _currentBundleScreen = new BehaviorSubject<string>(
    BundleScreens.BUNDLE_LIST
  );
  public currentBundleScreen$ = this._currentBundleScreen.asObservable();

  constructor(
    private _rootStore: RootStoreSelectors,
    private _bundleDispatcher: BundlesUiDispatchers,
    private _productsDispatchers: ProductsUiDispatchers,
    private _storeMenuUiDispatchers: StoreMenuUiDispatchers
  ) {}

  get products$() {
    return this._rootStore.getProducts$();
  }
  get bundles$() {
    return this._rootStore.getBundles$();
  }
  getSelectedBundle$(id: string) {
    return this._rootStore.getBundle$(id);
  }
  getGroup$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }

  resetPaging() {
    this._bundleDispatcher.resetBundlesPaging();
  }

  isBooking(prod: IChat) {
    return (
      prod &&
      (prod.type === this.chatTypes.BOOKING_CHANNEL ||
        prod.type === this.chatTypes.BOOKING_GROUP)
    );
  }
  isGroup(prod: IChat) {
    return (
      prod &&
      (prod.type === this.chatTypes.GROUP ||
        prod.type === this.chatTypes.CHANNEL)
    );
  }

  updateBundle(bundle: Bundle) {
    if (bundle && bundle.id) {
      this.addBundleProducts(bundle.id, bundle.products);
      this._bundleDispatcher.setBundle(bundle.id, bundle);
    } else {
      this._bundleDispatcher.createBundle(bundle);
    }
    this.closeItemCardEvent();
  }
  addBundleProducts(bundleId: string, prods: BundleProduct[]) {
    // remove products from bundle products
    const val = prods.map(r => {
      return { ...r, product: null };
    });
    this._bundleDispatcher.addBundleProducts(bundleId, val);
  }

  createItemEvent() {
    this.setCurrentScreen(BundleScreens.BUNDLE_CREATION);
    this.setSelectedBundle(null);
  }
  readItemEvent(bundle: Bundle) {
    this.setCurrentScreen(BundleScreens.BUNDLE_VIEW);
    this.setSelectedBundle(bundle);
  }
  updateItemEvent(bundle: Bundle) {
    this.setCurrentScreen(BundleScreens.BUNDLE_EDIT);
    this.setSelectedBundle(bundle);
  }
  closeItemCardEvent() {
    this.setCurrentScreen(BundleScreens.BUNDLE_LIST);
    this.setSelectedBundle(null);
  }
  deleteBundle(bundleId: string) {
    this._bundleDispatcher.deleteBundle(bundleId);
  }
  toggleConfirmDeleteBundle(e: boolean, bundle?: any) {
    this.confirmDeleteBundle = e;
    this.selectedRemovedBundle = bundle;
  }
  setCurrentScreen(screen: string) {
    this._currentBundleScreen.next(screen);
  }
  setSelectedBundle(bundle: Bundle) {
    if (bundle) {
      if (bundle.products && bundle.products.length > 0) {
        bundle.products.forEach(e =>
          this._productsDispatchers.getProduct(e.product_id, 0, bundle.id)
        );
      }
      if (bundle.store_menu_id) {
        this._storeMenuUiDispatchers.getStoreMenu(bundle.store_menu_id);
      }
    }
    this.selectedBundle = bundle;
  }

  getNextPage() {
    this._bundleDispatcher.getNextBundlesPage();
  }

  getPreviousPage() {
    this._bundleDispatcher.getPrevBundlesPage();
  }
  getChannelDetails$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
}
