import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { RootStoreSelectors } from '../store/root-store.selectors';

import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { AuthGateway } from 'src/app/network/gateway/auth.gateway';

import * as AuthMethods from 'src/models/IAuth';
import {
  HtttpMethodErrors,
  TOKEN_LOCAL_STORAGE_KEY,
  UISections
} from 'src/models/constants';
import { SummaryUIDispatchers } from 'src/app/store/summaryUI/summaryUI.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ISAUTH } from 'src/models/constants';
import { UIDispatchers } from '../store/ui/ui.dispatchers';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  countryIso: string;
  constructor(
    private _authGateway: AuthGateway,
    private _selectors: RootStoreSelectors,
    private _authDispatchers: AuthDispatchers,
    private _storageService: StorageService,
    private _uiDispatchers: UIDispatchers,
    private router: Router,
    private _summaryUIDispatchers: SummaryUIDispatchers,
    private _chatDispatchers: ChatDispatchers
  ) {
    this.authCollection$.subscribe(res => (this.countryIso = res.countryIso));
  }

  public _waitForTempConfig = new BehaviorSubject<boolean>(false);
  public waitForTempConfig$ = this._waitForTempConfig.asObservable();

  public _errorNotify = new BehaviorSubject<boolean>(false);
  public errorNotify$ = this._errorNotify.asObservable();

  public _errorSignupNotify = new BehaviorSubject<boolean>(false);
  public errorSignupNotify$ = this._errorSignupNotify.asObservable();

  auth2: any;
  reopenNewConnection() {
    this._authDispatchers.reopenNewConnection();
  }

  requestNewQRcode() {
    this._authDispatchers.requestNewQRCode();
  }
  requestQRcode() {
    this._authDispatchers.requestQRCode();
  }

  /** Auth dispacters actions on store  */
  get authCollection$(): Observable<AuthMethods.IAuth> {
    return this._selectors.authCollection$;
  }
  get authRem(): boolean {
    return this._authDispatchers.authRem;
  }
  get token(): string {
    return this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
  }

  setRem(rem: boolean) {
    this._authDispatchers.setAuthRem(rem);
  }
  logout() {
    this._authDispatchers.logOut();
  }

  selectChat(chatId: string, isAuth?) {
    this._authDispatchers.setChatSelect(chatId, ISAUTH.qrcode);
  }

  updateProgressWeb(no: string, mode: number) {
    this._authDispatchers.setAppProgress(no, mode);
  }
  updateWebMode(no: number) {
    this._authDispatchers.setAppMode(no);
  }
  getCountryISO() {
    return this._authDispatchers.getCountryData();
  }
  emailTacSuccess(form: AuthMethods.AuthForm) {
    this._authDispatchers.emailTacSuccess(
      form.email,
      form.appName,
      form.name,
      form.password,
      form.countryIso
    );
  }
  reciveAuthToken(token: string) {
    const auth: AuthMethods.IAuth = {};
    auth.token = token;
    this._authDispatchers.reciveAuthToken(auth);
  }
  resetSocketStatus() {
    this._authDispatchers.returnToInIt();
  }

  closeSocket() {
    this._authDispatchers.closeSocket();
  }

  createAccountSuccess(password: string) {
    this._authDispatchers.createAccountSuccess(password);
  }
  selectTemplate(templateID: string) {
    this._authDispatchers.selectTemplate(templateID);
  }
  loginUseEmail() {
    this._authDispatchers.loginByEmail();
  }
  requestAdminQR() {
    this._authDispatchers.requestAdminQR();
  }

  // Auth Gateway //
  loginByEmail(form: AuthMethods.AuthForm) {
    return this._authGateway.loginForm(form);
  }
  createAccount(form: AuthMethods.AuthForm, tac: string) {
    return this._authGateway.tacForm(form, tac);
  }
  getEmailTac(form: AuthMethods.AuthForm) {
    return this._authGateway.signUpForm(form);
  }
  signUpWhitGoogle(form: AuthMethods.AuthForm) {
    return this._authGateway.signUpWhitGoogle(form);
  }
  loginWhitGoogle(form: AuthMethods.AuthForm) {
    return this._authGateway.loginWhitGoogle(form);
  }

  getResetPasswordTac(email: string) {
    return this._authGateway.getResetPasswordTac(email);
  }
  resetPassword(
    email: string,
    tac: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    return this._authGateway.resetPassword(
      email,
      tac,
      newPassword,
      confirmNewPassword
    );
  }
  getTemplatesByCategory(category: string) {
    return this._authGateway.getTemplatesByCategory(category);
  }
  getTemplateById(id: string) {
    return this._authGateway.getTemplatesByCategory(null, id);
  }

  getChatData(chat_id) {
    this._authDispatchers.getChatData(chat_id);
  }

  async callLoginButton(registerType: string) {
    const element = await document.getElementById('loginRef');
    if (element && this.auth2) {
      this.auth2.attachClickHandler(
        element,
        {},
        (googleAuthUser: any) => {
          const profile = googleAuthUser.getBasicProfile();

          if (registerType === 'login') {
            const login: AuthMethods.AuthForm = {
              email: profile.getEmail(),
              google: profile.getId()
            };
            this.loginWhitGoogle(login).subscribe(response => {
              const res = <AuthMethods.HttpResult>response;
              const error = document.getElementById('login-error');
              if (res.result === 0) {
                this.reciveAuthToken(res.token);
              } else if (
                res.error === HtttpMethodErrors.MAIL_NOT_SIGNUP_GOOGLE
              ) {
                error.innerText =
                  'This account does not exist. Please sign up.';
              }
            });
          } else {
            const login: AuthMethods.AuthForm = {
              email: profile.getEmail(),
              name: profile.getName(),
              countryIso: this.countryIso,
              google: profile.getId()
            };
            const error = document.getElementById('sign-up');
            this.signUpWhitGoogle(login).subscribe(response => {
              const res = <AuthMethods.HttpResult>response;
              if (res.result === 0) {
                this._authDispatchers.emailTokenReceived(res.token);

                this.router.navigate(['/app']);
                this._uiDispatchers.setSection(UISections.APP);
              } else if (res.error === HtttpMethodErrors.MAIL_USED_GOOGLE) {
                error.innerText =
                  'This account already exists. Please sign in.';
              }
            });
          }
          /* Write Your Code Here */
        },
        (error: any) => {
          // alert(JSON.stringify(error, undefined, 2));
        }
      );
    }
  }

  googleAuthSDK() {
    (<any>window)['init'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.auth2 = (<any>window)['gapi'].auth2.init({
          client_id:
            '737915752999-fhbf1otlq3t498d9ghn5i0fb76frgunv.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
          plugin_name: 'social login'
        });
        // this.callLoginButton();
      });
    };

    const googleMailScreen = document.createElement('script');
    googleMailScreen.src = 'https://apis.google.com/js/platform.js?onload=init';
    googleMailScreen.crossOrigin = 'anonymous';
    googleMailScreen.async = true;
    googleMailScreen.defer = true;
    document.body.appendChild(googleMailScreen);
  }

  getChatList(upgraded?: boolean) {
    this._authDispatchers.getChatList(upgraded);
  }
}
