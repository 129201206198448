import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { UiService } from 'src/app/core/ui.service';
export class BulkEffects {
    constructor(actions$, _uiService) {
        this.actions$ = actions$;
        this._uiService = _uiService;
        // get campaign after receive campaigns
        this.getCampaign = this.actions$.pipe(ofType("RECORD_CREATED" /* RECORD_CREATED */), map((action) => {
            this._uiService._recordAdd.next({
                index: action.payload,
                recordType: action.recordType,
                id: action.id,
                ref: action.ref
            });
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BulkEffects.prototype, "getCampaign", void 0);
