import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { TemplateDispatchers } from 'src/app/store/templates/templates.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../store/templates/templates.dispatchers";
export class RegistrationService {
    constructor(_templateDispatchers) {
        this._templateDispatchers = _templateDispatchers;
    }
    canActivate(route, state) {
        return false;
    }
    receiveTemplates(templates) {
        this._templateDispatchers.receiveTemplates(templates);
    }
    selectAppTemplate(template) {
        this._templateDispatchers.selectAppTemplate(template);
    }
    setTempId(id) {
        this.tmpId = id;
    }
    getTempId() {
        return this.tmpId;
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.TemplateDispatchers)); }, token: RegistrationService, providedIn: "root" });
