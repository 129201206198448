import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as Methods from 'src/models/MapTripUser';
import { MapTripUserDispatchers } from './mapTripUser.dispatchers';
import { Store } from '@ngrx/store';
import { PageDirection } from 'src/models/constants';
export class MapTripUserEffects {
    /** No need for the following effect because of all the data comes with the list */
    // @Effect({ dispatch: false })
    // listReceived = this.actions$.pipe(
    //   ofType(MapTripUserActionTypes.MAP_TRIP_USER_LIST_RECEIVED),
    //   map((action: MapActions.MapTripUserListReceived) => {
    //     if (action.mapTripUsers && action.mapTripUsers.length > 0) {
    //       action.mapTripUsers.forEach(mapTripUser => {
    //         this._socketGateway.sendSocketMessage(
    //           new Methods.GetMapTripUser(mapTripUser.id)
    //         );
    //       });
    //     }
    //   })
    // );
    constructor(actions$, _socketGateway, _mapTripUserDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._mapTripUserDispatchers = _mapTripUserDispatchers;
        this._store = _store;
        this.resetMapTripUsersPaging = this.actions$.pipe(ofType("RESET_MAP_TRIP_USERS_PAGING" /* RESET_MAP_TRIP_USERS_PAGING */), map((action) => {
            this._mapTripUserDispatchers.getNextMapTripUsersPage();
        }));
        this.getNextMapServicePage = this.actions$.pipe(ofType("GET_NEXT_MAP_TRIP_USERS_PAGE" /* GET_NEXT_MAP_TRIP_USERS_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripUserReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsUserUiState]) => {
            const action = val;
            const prevRequest = mapTripsUserUiState.previousRequest;
            if (!(mapTripsUserUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                mapTripsUserUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTripUsers(0, mapTripsUserUiState.eop, mapTripsUserUiState.hash, mapTripsUserUiState.tripID));
            }
        }));
        this.getPrevMapServicePage = this.actions$.pipe(ofType("GET_PREV_MAP_TRIP_USERS_PAGE" /* GET_PREV_MAP_TRIP_USERS_PAGE */), withLatestFrom(this._store
            .select(state => state.mapTripUserReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mapTripsUserUiState]) => {
            const action = val;
            const prevRequest = mapTripsUserUiState.previousRequest;
            if (!(mapTripsUserUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                mapTripsUserUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new Methods.GetMapTripUsers(1, mapTripsUserUiState.sop, mapTripsUserUiState.hash, mapTripsUserUiState.tripID));
            }
        }));
        this.createMapTripUser = this.actions$.pipe(ofType("CREATE_MAP_TRIP_USER" /* CREATE_MAP_TRIP_USER */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.CreateMapTripUser(action.mapTripUser));
        }));
        this.updateMapTripUser = this.actions$.pipe(ofType("UPDATE_MAP_TRIP_USER" /* UPDATE_MAP_TRIP_USER */), map((action) => {
            const mapTripUser = Object.assign({}, action.mapTripUserDetails, { id: action.id });
            this._socketGateway.sendSocketMessage(new Methods.SetMapTripUser(mapTripUser));
        }));
        this.deleteMapTripUser = this.actions$.pipe(ofType("DELETE_MAP_TRIP_USER" /* DELETE_MAP_TRIP_USER */), map((action) => {
            this._socketGateway.sendSocketMessage(new Methods.RemoveMapTripUser(action.id, action.trip_id));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "resetMapTripUsersPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "getNextMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "getPrevMapServicePage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "createMapTripUser", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "updateMapTripUser", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MapTripUserEffects.prototype, "deleteMapTripUser", void 0);
