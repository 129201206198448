import {
  GET_USER_METHOD,
  CREATE_BOT_METHOD,
  GET_MY_BOTS_METHOD,
  SET_BOT_METHOD,
  CREATE_BOT_REF,
  GET_BOT_TOKEN_METHOD,
  GET_BOT_TEMPLATES_METHOD,
  REMOVE_BOT_METHOD,
  ADD_BOT_METHOD,
  ADD_BOT_AS_ADMIN_REF,
  SET_PRIVILEGES_METHOD,
  ADMIN_PRIVILEGES,
  PUBLISH_BOT_METHOD,
  GET_USER_BALANCE_METHOD,
  GET_USER_PRODUCT_BALANCE_METHOD,
  GET_CONTACTS_NAMES_METHOD
} from './constants';
import { ProfileDetails } from './ProfileDetails';
import { UserBalance, UserProductBalance } from './UserBalance';

/**
 * @prop lastMsgTime {number} - client-side Timestamp
 * that should be updated either if the loggedin send a message to this profile or received a message from this profile
 * used in ordering contact list
 */
export interface IProfile {
  user_id?: string;
  name?: string;
  version?: string; // if version changed then it means this profile img need to be updated
  relation?: string; // The relation, other/family/friend/work where other=0
  image?: string;
  imageThumbnail?: string; // The image we get from downloading the thumbnail
  mute?: boolean;
  contact?: boolean; // flag indicates that this profile is a contact
  status?: string; // status message
  priority?: boolean;
  pinned?: boolean;
  pinned_date?: number;
  backgroundColor?: string;
  last_seen?: number; // Date Timestamp
  thumbnail_id?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
  photo_id?: string;
  photo_width?: number;
  photo_height?: number;
  unknownContact?: boolean;
  lastTypedMessage?: string;
  online?: boolean;
  detailsReceived?: boolean;
  isBot?: boolean;
  tags?: string[];
  // added for bot
  owner?: number; // number 0 or 1
  filter?: number;
  isPublished?: number; // number 0 or 1
  about?: string;
  botType?: number;
  inline?: number;
  domain?: string;
  isPublic?: number; // number 0 or 1
  shortName?: string; // the bot name use this field
  email?: string;
  disallowGroup?: number; // number 0 or 1

  // For local use
  isMyBot?: boolean;
  isTemplateBot?: boolean;
  userBalance?: UserBalance[];
  userProductBalance?: UserProductBalance[];
  product?: any;
  paid?: number;
}

export class GetUser {
  readonly method = GET_USER_METHOD;
  readonly p = 1;
  constructor(public user_id: string) {}
}

export class CreateNewBot {
  readonly method = CREATE_BOT_METHOD;
  readonly reference = CREATE_BOT_REF;
  constructor(public name: string) {}
}

export class AddBotAsAdmin {
  readonly method = ADD_BOT_METHOD;
  readonly reference = ADD_BOT_AS_ADMIN_REF;
  constructor(public user_id: string) {}
}

export class SetBot {
  readonly method = SET_BOT_METHOD;
  constructor(botDetails: ProfileDetails) {
    for (const key in botDetails) {
      if (botDetails.hasOwnProperty(key)) {
        this[key] = botDetails[key];
      }
    }
  }
}

export class RequestMyBots {
  readonly method = GET_MY_BOTS_METHOD;
  constructor() {}
}

export class GetBotToken {
  readonly method = GET_BOT_TOKEN_METHOD;
  constructor(public user_id: string, public chat_id: string) {}
}

export class GetBotTemplates {
  readonly method = GET_BOT_TEMPLATES_METHOD;
  constructor() {}
}

export class RemoveBot {
  readonly method = REMOVE_BOT_METHOD;
  constructor(public user_id: string) {}
}

export class PublishBot {
  readonly method = PUBLISH_BOT_METHOD;
  constructor(public id: string, public shortName?: string) {}
}

export class SetAdminPrivileges {
  readonly method = SET_PRIVILEGES_METHOD;
  constructor(
    public user_id: string,
    public chat_id: string,
    public privileges: ADMIN_PRIVILEGES[]
  ) {}
}

export class GetUserBalance {
  readonly method = GET_USER_BALANCE_METHOD;
  constructor(public user_id: string, public currency?: string) {}
}

export class GetUserProductBalance {
  readonly method = GET_USER_PRODUCT_BALANCE_METHOD;
  constructor(public user_id: string, public product_id?: string) {}
}
export class GetContactsNamesFromServer {
  readonly method = GET_CONTACTS_NAMES_METHOD;
  constructor(public contacts: string[]) {}
}

export type ProfileMethods = GetUser;
