import { Store } from '@ngrx/store';
import * as PaymentOrdersUIActions from './paymentOrdersUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class PaymentOrdersUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    // paging dispatchers
    getNextPaymentOrdersPage(paymentOrderID) {
        this._store.dispatch(new PaymentOrdersUIActions.GetNextPaymentOrdersPage(paymentOrderID));
    }
    getPrevPaymentOrdersPage(paymentOrderID) {
        this._store.dispatch(new PaymentOrdersUIActions.GetPrevPaymentOrdersPage(paymentOrderID));
    }
    receivedPaymentOrdersHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new PaymentOrdersUIActions.ReceivedPaymentOrdersHistoryChunk(eop, sop, currentPage));
    }
    paymentOrdersUsersReceived(userNames) {
        this._store.dispatch(new PaymentOrdersUIActions.PaymentOrdersUsersReceived(userNames));
    }
    paymentStripeConnectSubChannel() {
        this._store.dispatch(new PaymentOrdersUIActions.CreateStripeSubChannel());
    }
    paymentStripeResetSubChannel(provider_id) {
        this._store.dispatch(new PaymentOrdersUIActions.ResetStripeSubChannel(provider_id));
    }
    paymentOrdersGroupsReceived(groupsNames) {
        this._store.dispatch(new PaymentOrdersUIActions.PaymentOrdersGroupsReceived(groupsNames));
    }
    resetPaymentOrdersPaging(paymentOrderID) {
        this._store.dispatch(new PaymentOrdersUIActions.ResetPaymentOrdersPaging(paymentOrderID));
    }
    setStatusAsIdle() {
        this._store.dispatch(new PaymentOrdersUIActions.SetStatusAsIdle());
    }
}
PaymentOrdersUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentOrdersUiDispatchers_Factory() { return new PaymentOrdersUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: PaymentOrdersUiDispatchers, providedIn: "root" });
