import * as CampaignActions from './campaign.actions';
import { CampaignMiddleware } from './campaign.middleware';

import { ICampaign } from 'src/models/ICampaign';

export function campaignReducer(
  state: ICampaign[] = [],
  action: CampaignActions.CampaignActions
): ICampaign[] {
  switch (action.type) {
    case CampaignActions.CampaignActionTypes.CAMPAIGN_RECEIVED:
      const localPayload = CampaignMiddleware.initMessage(action.payload);
      if (!CampaignMiddleware.isCampaignExist(state, localPayload)) {
        return [localPayload, ...state];
      }
      return CampaignMiddleware.updateCampaign(state, localPayload);
    case CampaignActions.CampaignActionTypes.UPDATE_CAMPAIGN:
      return CampaignMiddleware.updateCampaign(state, action.payload);
    case CampaignActions.DESELECT_CHANNEL:
    case CampaignActions.RESET:
      return [];
    default:
      return state;
  }
}
