import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { StartOverListItem, APP_CATEGORIES } from 'src/models/constants';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';

@Component({
  selector: 'app-startover-droplist',
  templateUrl: './startover-droplist.component.html',
  styleUrls: ['./startover-droplist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartoverDroplistComponent implements OnInit, OnChanges {
  teqQuestionItems: StartOverListItem[];
  catQuestionItems: StartOverListItem[];
  selected: StartOverListItem;
  appCategory = APP_CATEGORIES;
  @Input() category: any;
  @Input() type: string;
  @Input() showList: boolean;
  @Input() isDropDown = false;
  @Output() saveNotify = new EventEmitter<string>();
  allCat: StartOverListItem = {};
  constructor(
    public _ref: ChangeDetectorRef,
    public appMgmtService: AppMgmtService
  ) {}
  ngOnChanges() {}

  ngOnInit() {
    const teqItemOne: StartOverListItem = {};
    teqItemOne.title = 'I have no technical background';
    teqItemOne.type = 'simple';
    teqItemOne.img = 'no-tec.svg';
    const teqItemSec: StartOverListItem = {};
    teqItemSec.title = 'I am fairly familiar with technology';
    teqItemSec.type = 'simple';
    teqItemSec.img = 'familiar-tec.svg';
    const teqItemThird: StartOverListItem = {};
    teqItemThird.title = 'I know exactly what I want';
    teqItemThird.type = 'Builder';
    teqItemThird.img = 'exactly-tec.svg';
    const teqItemFourth: StartOverListItem = {};
    teqItemFourth.title = 'I can code it myself';
    teqItemFourth.type = 'Builder';
    teqItemFourth.img = 'myself-tec.svg';
    const teqItemFiveth: StartOverListItem = {};
    teqItemFiveth.title = 'I don’t know';
    teqItemFiveth.type = 'simple';
    teqItemFiveth.img = 'idonotknow-tec.svg';
    this.teqQuestionItems = [
      teqItemOne,
      teqItemSec,
      teqItemThird,
      teqItemFourth,
      teqItemFiveth
    ];

    const catItemOne: StartOverListItem = {};
    catItemOne.title = 'Communication';
    catItemOne.desc = 'Your App is similar to WhatsApp, Viber , Telegram';
    catItemOne.icons = [
      'whatsapp-startover',
      'viber-startover',
      'telegram-startover'
    ];
    catItemOne.img = 'communication-template.svg';
    catItemOne.imgS = 'communication-template - selected.svg';
    catItemOne.type = this.appCategory.NewType;
    const catItemSec: StartOverListItem = {};
    catItemSec.title = 'Social Network';
    catItemSec.desc = 'Your App is similar to facebook, WeChat, Line';
    catItemSec.icons = [
      'linked-in-startover',
      'wechat-app',
      'facebook-startover'
    ];
    catItemSec.img = 'social-template.svg';
    catItemSec.imgS = 'social-template - selected.svg';
    catItemSec.type = this.appCategory.NewType2;
    const catItemThird: StartOverListItem = {};
    catItemThird.title = 'Blank';
    catItemThird.desc = 'Your App is similar to Netflix, Home Tv, BBC News';
    catItemThird.icons = ['homeTV', 'bbc-app', 'netFlex'];
    catItemThird.img = 'blank.svg';
    catItemThird.imgS = 'blank - selected.svg';
    catItemThird.type = this.appCategory.Blank;
    const catItemFourth: StartOverListItem = {};
    catItemFourth.title = 'E-Commerce';
    catItemFourth.desc = 'Your App is similar to Amazon, Shopify';
    catItemFourth.icons = ['amazone-startover', 'shopify-app'];
    catItemFourth.img = 'e-commerce-template.svg';
    catItemFourth.imgS = 'e-commerce-template - selected.svg';
    catItemFourth.type = this.appCategory.Retail;
    const catItemFiveth: StartOverListItem = {};
    catItemFiveth.title = 'Events & Booking';
    catItemFiveth.desc = 'Your App is similar to Mindbody, Veezeta';
    catItemFiveth.icons = ['vezeeta', 'mindBody-app'];
    catItemFiveth.img = 'event-ticketing.svg';
    catItemFiveth.imgS = 'event-ticketing - selected.svg';
    catItemFiveth.type = this.appCategory.NewType3;
    const catItemSixth: StartOverListItem = {};
    catItemSixth.title = 'Community';
    catItemSixth.desc = 'Your App is similar to Instagram, Whatsapp, facebook';
    catItemSixth.icons = [
      'whatsapp-startover',
      'facebook-startover',
      'instagram-app'
    ];
    catItemSixth.img = 'social-template.svg';
    catItemSixth.imgS = 'social-template - selected.svg';
    catItemSixth.type = this.appCategory.Cities;
    const catItemSeventh: StartOverListItem = {};
    catItemSeventh.title = 'Education';
    catItemSeventh.desc = 'Your App is similar to Seesaw, iBook, Hello Parents';
    catItemSeventh.img = 'education-template.svg';
    catItemSeventh.imgS = 'education-template - selected.svg';
    catItemSeventh.type = this.appCategory.Schools;
    catItemSeventh.icons = ['seesaw', 'ibooks', 'bitmap-education'];
    const catItemeigt: StartOverListItem = {};
    catItemeigt.title = 'Maps';
    catItemeigt.desc = 'Your App is similar to Uber, Google Maps';
    catItemeigt.type = this.appCategory.Maps;
    catItemeigt.img = '';
    catItemeigt.icons = ['google-map-app', 'uber-startover'];
    const catItemNine: StartOverListItem = {};
    catItemNine.title = 'Sports';
    catItemNine.desc =
      'Your App is similar to Team Snap, Chat Sports, BBC Sports';
    catItemNine.type = this.appCategory.Club;
    catItemNine.img = 'sports-template.svg';
    catItemNine.imgS = 'sports-template - selected.svg';
    catItemNine.icons = ['bbc-sport', 'sport-app', 'teamsnap'];
    const catItemTen: StartOverListItem = {};
    catItemTen.title = 'Offering Services';
    catItemTen.desc = 'Your App is similar to Veezeta, Map Search';
    catItemTen.img = '';
    catItemTen.type = this.appCategory.Offeres;
    catItemTen.icons = ['vezeeta'];

    const catItemEleven: StartOverListItem = {};
    catItemEleven.title = 'News';
    catItemEleven.desc = 'Your App is similar to Seesaw, iBook, Hello Parents';
    catItemEleven.img = 'newspaper_black.svg';
    catItemEleven.imgS = 'newspaper_black - selected.svg';
    catItemEleven.type = this.appCategory.News;
    catItemEleven.icons = ['seesaw', 'ibooks', 'bitmap-education'];
    this.catQuestionItems = [
      catItemOne,
      // catItemSec,
      // catItemFourth,
      // catItemFiveth,
      catItemSixth,
      catItemSeventh,
      // catItemeigt,
      catItemNine,
      catItemEleven,
      catItemThird
    ];

    this.allCat.title = 'All Template';
    this.allCat.desc = '';
    this.allCat.img = 'all-templates.svg';
    this.allCat.type = this.appCategory.All;
    this.allCat.icons = ['vezeeta'];
    if (this.category == null || !this.category) {
      this.selectedItem(this.allCat);
    }
  }

  selectedItem(item) {
    // this.selected = item;
    // this._ref.detectChanges();
    this.appMgmtService._selectedTempCat.next(item);
    setTimeout(() => this.saveNotify.emit(item), 1000);
  }

  getBackground(item) {
    return {
      'background-image': 'url(/assets/img/' + item.img + ')'
    };
  }
}
