import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';

import { TagFormComponent } from './tag-form/tag-form.component';

@NgModule({
  imports: [
    CommonModule,
    AccessorsModule,
    ReactiveFormsModule,
    AbstractControlsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    SharedModule
  ],
  declarations: [TagFormComponent],
  exports: [TagFormComponent]
})
export class TagFormModule {}
