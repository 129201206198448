<div class="keyboard">
  <span
    *ngFor="let item of emojiService.spritesheetPositions"
    appCancelDrag
    class="clickable-cursor"
    (click)="emoijEvent(emojiImage)"
  >
    <img
      src="./assets/img/blank.gif"
      #emojiImage
      class="emoji-w26"
      [ngClass]="{
        'emoji-spritesheet-0': item[1] === 0,
        'emoji-spritesheet-1': item[1] === 1,
        'emoji-spritesheet-2': item[1] === 2,
        'emoji-spritesheet-3': item[1] === 3,
        'emoji-spritesheet-4': item[1] === 4
      }"
      [ngStyle]="bkGroundPos(item[3], item[4])"
      [alt]="item[5]"
      onresizestart="false"
    />
  </span>
</div>
