import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

export const enum SubGroupUIActionTypes {
  UI_LIST_SUB_GROUP = 'UI_LIST_SUB_GROUP',
  UI_CREATE_SUB_GROUP = 'UI_CREATE_SUB_GROUP',
  UI_VIEW_SUB_GROUP = 'UI_VIEW_SUB_GROUP',
  UI_EDIT_SUB_GROUP = 'UI_EDIT_SUB_GROUP',
  UPLOAD_SUB_GROUP_IMAGE = 'UPLOAD_SUB_GROUP_IMAGE',
  UI_UPLOAD_SUB_GROUP_PHOTO = 'UI_UPLOAD_SUB_GROUP_PHOTO',
  UI_SUB_GROUP_CREATION_COMPLETE = 'UI_SUB_GROUP_CREATION_COMPLETE',
  UI_CLOSE_VIEW_SUB_GROUP = 'UI_CLOSE_VIEW_SUB_GROUP',
  UI_CLOSE_EDIT_SUB_GROUP = 'UI_CLOSE_EDIT_SUB_GROUP',
  UI_SUB_GROUP_GET_NEXT_PAGE = 'UI_SUB_GROUP_GET_NEXT_PAGE',
  UI_SUB_GROUP_GET_PREV_PAGE = 'UI_SUB_GROUP_GET_PREV_PAGE',
  UI_SUB_GROUP_RECEIVED_HISTORY_CHUNK = 'UI_SUB_GROUP_RECEIVED_HISTORY_CHUNK',
  UI_SUB_GROUP_RESET_PAGING = 'UI_SUB_GROUP_RESET_PAGING',
  UI_SUB_GROUP_SET_IDLE_STATUS = 'UI_SUB_GROUP_SET_IDLE_STATUS',
  UI_SET_GROUP_CHAT = 'UI_SET_GROUP_CHAT',
  RESET = 'RESET'
}
export class ListSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_LIST_SUB_GROUP;
  constructor() {}
}
export class CreateSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_CREATE_SUB_GROUP;
}

export class ViewSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_VIEW_SUB_GROUP;
  constructor(public selectedSubGroup: IChat) {}
}

export class EditSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_EDIT_SUB_GROUP;
  constructor(public selectedSubGroup: IChat, public file?: any) {}
}

export class UploadSubGroupImage implements Action {
  readonly type = SubGroupUIActionTypes.UPLOAD_SUB_GROUP_IMAGE;
  constructor(public selectedFile) {}
}

export class SubGroupPhotoUploadUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_UPLOAD_SUB_GROUP_PHOTO;
  constructor(public createdSubGroup: IChat) {}
}

export class SubGroupCreationComplete implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_CREATION_COMPLETE;
}

export class CloseViewSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_CLOSE_VIEW_SUB_GROUP;
}

export class CloseEditSubGroupUI implements Action {
  readonly type = SubGroupUIActionTypes.UI_CLOSE_EDIT_SUB_GROUP;
}
//
// Paging Actions

export class GetNextSubGroupPage implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevSubGroupPage implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_GET_PREV_PAGE;
  constructor() {}
}

// we can detect that by listening to the return of getsubchatlist of type Group or Channel
export class ReceivedSubGroupHistoryChunk implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChat[]
  ) {}
}

export class ResetSubGroupPaging implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = SubGroupUIActionTypes.UI_SUB_GROUP_SET_IDLE_STATUS;
  constructor() {}
}

export class SetGroupChat implements Action {
  readonly type = SubGroupUIActionTypes.UI_SET_GROUP_CHAT;
  constructor(public data: any) {}
}

export class Reset implements Action {
  readonly type = SubGroupUIActionTypes.RESET;
}

export type Actions =
  | ListSubGroupUI
  | CreateSubGroupUI
  | ViewSubGroupUI
  | EditSubGroupUI
  | UploadSubGroupImage
  | SubGroupPhotoUploadUI
  | SubGroupCreationComplete
  | CloseViewSubGroupUI
  | CloseEditSubGroupUI
  | GetNextSubGroupPage
  | GetPrevSubGroupPage
  | ReceivedSubGroupHistoryChunk
  | ResetSubGroupPaging
  | SetStatusAsIdle
  | Reset;
