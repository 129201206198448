import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import * as MessagesMethods from 'src/models/IMessage';
import { ChatDispatchers } from '../chats/chat.dispatchers';
export class AllGroupEffects {
    constructor(actions$, chatDispatchers, _socketGateway) {
        this.actions$ = actions$;
        this.chatDispatchers = chatDispatchers;
        this._socketGateway = _socketGateway;
        this.setAppChannelSettings = this.actions$.pipe(ofType("UI_RECEIVE_ALL_GROUP" /* UI_RECEIVE_ALL_GROUP */), map((action) => {
            if (action.chatGroups.length > 0) {
                // action.chatGroups.forEach(res => {
                //   this.getChatHistoryFromBusinessServer(
                //     0,
                //     10,
                //     null,
                //     null,
                //     null,
                //     res.id
                //   );
                //   this.chatDispatchers.getChatAdministrators(res.id);
                // });
            }
        }));
    }
    getChatHistoryFromBusinessServer(reference, page_size, reply_to_message_id, lv, user_id, chat_id) {
        this._socketGateway.sendSocketMessage(new MessagesMethods.GetMessagesHistoryFromBusinessServer(null, reply_to_message_id, null, null, null, null, null, null, user_id, page_size, lv, reference, 0, chat_id));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AllGroupEffects.prototype, "setAppChannelSettings", void 0);
