import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import './records.js';
import * as __NgCli_bootstrap_1 from "./app/+merchant/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// import './widget.js';
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
