import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeleteIconComponent } from './delete-icon.component';
import { CloseIconComponent } from './close-icon.component';
import { EditIconComponent } from './edit-icon.component';
import { TooltipIconComponent } from './tooltip-icon.component';
import { MoveIconComponent } from './move-icon.component';
import { AddIconComponent } from './add-icon.component';
import { ReservationsIconComponent } from './reservations-icon.component';
import { CancelIconComponent } from './cancel-icon.component';
import { EnableServiceIconComponent } from './enable-service-icon.component';
import { TooltipHoverIconComponent } from './tooltip-hover-icon.compoonent';
import { EditFrameIconComponent } from './edit-frame-icon.components';
import { EnableServiceFrameIconComponent } from './enable-service-frame-icon.component';
import { DeleteFrameIconComponent } from './delete-frame-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DeleteIconComponent,
    DeleteFrameIconComponent,
    CancelIconComponent,
    EditIconComponent,
    EditFrameIconComponent,
    CloseIconComponent,
    TooltipIconComponent,
    MoveIconComponent,
    AddIconComponent,
    ReservationsIconComponent,
    EnableServiceIconComponent,
    EnableServiceFrameIconComponent,
    TooltipHoverIconComponent
  ],
  exports: [
    DeleteIconComponent,
    EnableServiceIconComponent,
    DeleteFrameIconComponent,
    EnableServiceFrameIconComponent,
    CancelIconComponent,
    EditIconComponent,
    EditFrameIconComponent,
    CloseIconComponent,
    TooltipIconComponent,
    MoveIconComponent,
    AddIconComponent,
    ReservationsIconComponent,
    TooltipHoverIconComponent
  ]
})
export class AbstractIconsModule {}
