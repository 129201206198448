/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./delete-icon.component";
var styles_DeleteIconComponent = ["[_nghost-%COMP%] {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }"];
var RenderType_DeleteIconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DeleteIconComponent, data: {} });
export { RenderType_DeleteIconComponent as RenderType_DeleteIconComponent };
export function View_DeleteIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-delete"]], null, null, null, null, null))], null, null); }
export function View_DeleteIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-icon", [], null, null, null, View_DeleteIconComponent_0, RenderType_DeleteIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.DeleteIconComponent, [], null, null)], null, null); }
var DeleteIconComponentNgFactory = i0.ɵccf("app-delete-icon", i1.DeleteIconComponent, View_DeleteIconComponent_Host_0, { fillColor: "fillColor" }, {}, []);
export { DeleteIconComponentNgFactory as DeleteIconComponentNgFactory };
