import { Action } from '@ngrx/store/public_api';
import { ChannelData } from 'src/models/ChannelAppConfig';

export const enum AppChannelDataActionTypes {
  RECEIVE_APP_CHANNEL_DATA = 'RECEIVE_APP_CHANNEL_DATA',
  RESET = 'RESET'
}

export class ReceiveAppChannelData implements Action {
  readonly type = AppChannelDataActionTypes.RECEIVE_APP_CHANNEL_DATA;
  constructor(public channelData: ChannelData[]) {}
}

export class Reset implements Action {
  readonly type = AppChannelDataActionTypes.RESET;
}

export type Actions = ReceiveAppChannelData | Reset;
