<ng-container *ngIf="layoutService?.getsideMenuStatus$() | async as sideMenu">
  <div class="layout">
    <mat-sidenav-container
      *ngIf="uiService.uiCollection$ | async as uiCollection"
      class="main-layout"
      (backdropClick)="close()"
      [class.no-z-index]="
        (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
        (layoutService?.getSideNavStatus() | async)?.matches
      "
      autosize="true"
    >
      <mat-sidenav
        *ngIf="
          (layoutService?.getSideNavStatus() | async)?.matches &&
          !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
        "
        #sidenav
        [opened]="true"
        [class.mat-sidenav]="uiCollection?.section === uiSection?.APP"
        [mode]="'over'"
        disableClose
        class="main-layout-sidenav"
        [class.open]="
          !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
          (layoutService?.getSideNavStatus() | async)?.matches
        "
        [class.close]="
          (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
          !(layoutService?.getSideNavStatus() | async)?.matches
        "
        [fixedInViewport]="(layoutService?.getSideNavStatus() | async)?.matches"
        [fixedTopGap]="
          !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
          (layoutService?.getSideNavStatus() | async)?.matches
            ? 0
            : 50
        "
        (mouseover)="showMenuButton(true)"
      >
        <app-side-menu
          class="no-copyable-item"
          appCancelDrag
          [isOpen]="true"
          [mode]="'over'"
          [isShowing]="
            !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
          "
          [showLogo]="
            !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
            (layoutService?.getSideNavStatus() | async)?.matches
          "
          [direction]="(uiService?.uiCollection$ | async)?.section"
          [progressWeb]="(authService.authCollection$ | async)?.progressWeb"
          [currentScreenNumber]="appManagementService.currentPage$ | async"
          (sectionNotify)="sectionEvent($event)"
          [splashPages]="appManagementService?.splashPages$ | async"
          [splashPages]="appManagementService?.splashPages$ | async"
          [splashFooter]="appManagementService?.splashFooterButton$ | async"
          [selectedFeature]="appManagementService?.getAppSettingFeature()"
          [class.hide-side-menu]="
            (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
            (layoutService?.getSideNavStatus() | async)?.matches
          "
          [vendors]="appManagementService?.getThirdPartyInfo$() | async"
          [oldVend]="appManagementService?.oldVendors$ | async"
          [selectedVendor]="appManagementService?.selectedVendor$ | async"
          [appInfo]="appManagementService?.appInfo$ | async"
          (startOverNotify)="('')"
        >
        </app-side-menu>
      </mat-sidenav>

      <mat-sidenav-content
        class="main-layout-content"
        [class.no-z-index]="
          (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus &&
          (layoutService?.getSideNavStatus() | async)?.matches
        "
        (mouseover)="showMenuButton(false)"
        #sidenavContent
      >
        <mat-toolbar class="toolbar">
          <div
            class="nav-bar-container"
            [class.active]="
              ((uiService?.uiCollection$ | async)?.section === uiSection?.APP &&
                (!(layoutService?.getSideNavStatus() | async)?.matches &&
                  (layoutService?.getsideMenuStatus$() | async)
                    ?.sideMenuStatus)) ||
              (layoutService?.getSideNavStatus() | async)?.matches
            "
          >
            <app-nav-bar
              *ngIf="layoutService?.getsideMenuStatus$() | async as sideMenu"
              class="main-layout-header"
              [selectedChatId]="
                (uiService.uiCollection$ | async)?.selectedChat?.id
              "
              [helpWindowStatus]="
                (uiService.uiCollection$ | async)?.helpWindowStatus
              "
              [mode]="(authService.authCollection$ | async)?.mode"
              [role]="role"
              [splashPages]="appManagementService?.splashPages$ | async"
              [splashFooter]="appManagementService?.splashFooterButton$ | async"
              [selectedFeature]="appManagementService?.getAppSettingFeature()"
              [currentSection]="(layoutService?.uiCollection$ | async)?.section"
              [membersRols]="membersService?.selectedChatRoles$ | async"
              [cancelEnd]="billingsService?.isMainPlanCanceled$ | async"
              [mainPlanStatus]="billingsService?.getMainPlanStatus$ | async"
              [currentSelectedPlan]="
                billingsService?.currentSelecetedPlan$ | async
              "
              [trileEndDate]="billingsService?.getBillingsEndTriles$ | async"
              [mainChat]="uiService?.selectedChat$ | async"
              (searchNotify)="searchEvent($event)"
              (logoutNotify)="logoutEvent($event)"
              (sectionNotify)="sectionEvent($event)"
              (talkToAdminNotify)="talkToAdminEvent($event)"
              (openHelpWindowNotify)="toggleHelpWindowNotify(true)"
              (closeHelpWindowNotify)="toggleHelpWindowNotify(false)"
              (toggleSidenavNotify)="setSideNavStatus(sideMenu?.sideMenuStatus)"
            >
            </app-nav-bar>
          </div>
        </mat-toolbar>
        <app-mgmt-progress
          *ngIf="uiCollection?.section === uiSection?.APP"
          [progressWeb]="(authService.authCollection$ | async)?.progressWeb"
          [optionFormValidation]="
            appManagementService?.optionFormNotVaild$ | async
          "
          [currentPage]="appManagementService.currentPage$ | async"
          [appInfo]="appManagementService?.appInfo$ | async"
          (pageChangedNotify)="appMgmt?.hideAndShowMenu($event)"
          [itemList]="appManagementService.navMenuItems$ | async"
          [tabContainerList]="appManagementService.tabsContainers$ | async"
          [section]="(layoutService?.uiCollection$ | async)?.section"
          [currentFeature]="''"
          [splashPages]="appManagementService?.splashPages$ | async"
          [splashFooter]="appManagementService?.splashFooterButton$ | async"
          [vendors]="appManagementService?.getThirdPartyInfo$() | async"
          [mobileStoreAppList]="mobileStoreService?.mobileStoreAppList$ | async"
          [showSettings]="''"
          [selectedVendor]="appManagementService?.selectedVendor$ | async"
          [oldVend]="appManagementService?.oldVendors$ | async"
          [tabContainerList]="appManagementService.tabsContainers$ | async"
          [authCollection]="authService.authCollection$ | async"
          [selectedTab]="appManagementService?.effectiveSelectedTab$ | async"
          [currentTabs]="appManagementService?.mainTabsContainers$ | async"
          (stripeConnectNotify)="appMgmt?.closePayment()"
          (startOverNotify)="startOver($event)"
        >
        </app-mgmt-progress>
        <div class="main-layout-body">
          <div
            class="side-menu-container"
            [class.app-mgmt]="uiCollection?.section === uiSection?.APP"
            [class.active-menu]="
              uiCollection?.section === uiSection?.POSTS &&
              (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
            "
          >
            <mat-sidenav
              *ngIf="
                !(
                  (layoutService?.getSideNavStatus() | async)?.matches &&
                  !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
                )
              "
              #sidenav
              [opened]="true"
              [class.mat-sidenav]="uiCollection?.section === uiSection?.APP"
              [mode]="
                (layoutService?.getSideNavStatus() | async)?.matches &&
                !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
                  ? 'over'
                  : 'side'
              "
              disableClose
              class="main-layout-sidenav-side"
              [class.open]="
                !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
              "
              [class.close]="
                (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
              "
              [fixedInViewport]="
                (layoutService?.getSideNavStatus() | async)?.matches
              "
              [fixedTopGap]="
                !(layoutService?.getsideMenuStatus$() | async)
                  ?.sideMenuStatus &&
                (layoutService?.getSideNavStatus() | async)?.matches
                  ? 0
                  : 50
              "
              (mouseover)="showMenuButton(true)"
            >
              <app-side-menu
                class="no-copyable-item"
                appCancelDrag
                [isOpen]="true"
                [isShowing]="
                  !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
                "
                [showLogo]="
                  !(layoutService?.getsideMenuStatus$() | async)
                    ?.sideMenuStatus &&
                  (layoutService?.getSideNavStatus() | async)?.matches
                "
                [direction]="(uiService?.uiCollection$ | async)?.section"
                [progressWeb]="
                  (authService.authCollection$ | async)?.progressWeb
                "
                [currentScreenNumber]="
                  appManagementService.currentPage$ | async
                "
                (sectionNotify)="sectionEvent($event)"
                [splashPages]="appManagementService?.splashPages$ | async"
                [splashPages]="appManagementService?.splashPages$ | async"
                [splashFooter]="
                  appManagementService?.splashFooterButton$ | async
                "
                [selectedFeature]="appManagementService?.getAppSettingFeature()"
                [class.hide-side-menu]="
                  (layoutService?.getsideMenuStatus$() | async)
                    ?.sideMenuStatus &&
                  (layoutService?.getSideNavStatus() | async)?.matches
                "
                [vendors]="appManagementService?.getThirdPartyInfo$() | async"
                [oldVend]="appManagementService?.oldVendors$ | async"
                [selectedVendor]="appManagementService?.selectedVendor$ | async"
                [appInfo]="appManagementService?.appInfo$ | async"
                (startOverNotify)="startOver($event)"
              >
              </app-side-menu>
            </mat-sidenav>
          </div>
          <div
            class="content"
            [class.active-content]="
              !(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
            "
            [class.not-active-content]="
              (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus
            "
          >
            <ng-content select="main-layout-route"></ng-content>
          </div>
          <ng-content select="main-layout-side"></ng-content>
          <app-help
            *ngIf="uiCollection?.helpWindowStatus"
            [sectionLink]="layoutService?.sectionLink$ | async"
            [isStartOver]="mode == 0 || mode == 1"
            (cancelNotifier)="toggleHelpWindowNotify(false)"
          ></app-help>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <!-- <app-modal-container
    *ngIf="
      (hidePopUp &&
        (appManagementService.channelAppUiCollection$ | async)
          ?.currentScreen === appScreens?.TABS &&
        (authService.authCollection$ | async)?.mode !== 0) ||
      (hidePopUp &&
        (appManagementService.channelAppUiCollection$ | async)
          ?.currentScreen === appScreens?.NAV_MENU &&
        (authService.authCollection$ | async)?.mode !== 0)
    "
    [count]="layoutService?.appModelOpenStatus$ | async"
  >
    <app-confirm-info (closeNotifier)="togglePopUp()"></app-confirm-info>
  </app-modal-container> -->
</ng-container>
<app-modal-container
  *ngIf="startOverFirstValidation"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotify)="showConfirmation()"
>
  <app-popup-notifier
    [title]="'Warning'"
    [validationMsg]="true"
    [message]="
      'Please note that starting over will erase all your app configuration, do you want to proceed?'
    "
    (hidePopupNotify)="showConfirmation()"
    (agreePopupNotify)="secConfirmation()"
    [cancelTitle]="'No'"
    [validationTitle]="'Yes'"
  >
  </app-popup-notifier>
</app-modal-container>

<ng-container
  *ngIf="
    appManagementService?.showAppleKeyFormObservable$ | async as appleKeyForm
  "
>
  <app-modal-container
    *ngIf="appleKeyForm?.show"
    [count]="layoutService?.appModelOpenStatus$ | async"
  >
    <app-apple-id-popup
      (codeEntered)="setAppleKeyCode($event, appleKeyForm?.release_id)"
      (closeNotify)="hideAppleKeyForm()"
    >
    </app-apple-id-popup>
  </app-modal-container>
</ng-container>

<app-modal-container
  *ngIf="authService.waitForTempConfig$ | async"
  [count]="layoutService?.appModelOpenStatus$ | async"
>
  <div class="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</app-modal-container>
