import { Actions } from './thirdPartyInfo.actions';
import * as actions from './thirdPartyInfo.actions';
import { ThirdPartyInfoUI, VendorsUI } from 'src/models/thirdPartyInfo';
import { EMAIL_VERIFICATION_MESSAGE, SMS_VERIFICATION_MESSAGE, VendorsTypes } from 'src/models/constants';

const INITIAL_STATE: VendorsUI = {
  tac: '',
  vendors: [],
  oldVendors: [],
  isSaved: false,
  smsVendor: {
    type: VendorsTypes.SMS,
    access: {}
  }
};

export function thirdPartyInfoReducer(
  state = INITIAL_STATE,
  action: Actions
): VendorsUI {
  switch (action.type) {
    case actions.ThirdPartyInfoUIActionTypes.UI_RECEIVE_THIRD_PARTY_INFO: {
      const smsVendor = action.vendor.find(
        res => res.type === VendorsTypes.SMS
      );
      return {
        ...state,
        vendors: [...action.vendor],
        smsVendor
      };
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_UPDATE_THIRD_PARTY_INFO: {
      let isEexist = false;
      const vendors: ThirdPartyInfoUI[] = state.vendors.map(res => {
        if (res.type === action.vendors.type) {
          let vendor: ThirdPartyInfoUI = {};
          vendor = {
            ...res,
            access: { ...res.access, ...action.vendors.access }
          };
          isEexist = true;
          return vendor;
        } else {
          isEexist = false;
          return res;
        }
      });
      !isEexist ? vendors.push(action.vendors) : null;

      return {
        ...state,
        tac: state.tac,
        vendors: [...vendors]
      };
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_RECEIVE_TEST_THIRD_PARTY_INFO: {
      return {
        ...state,
        tac: action.tac ? action.tac : ''
      };
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_REST_ERROR_MASSAGE: {
      return {
        ...state,
        tac: ''
      };
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_SET_VERIFICATION_MASSAGE: {
      return {
        ...state,
        verificationMessageEmail: !action.typeMessage ? action.value : state.verificationMessageEmail,
        verificationMessageSMS: action.typeMessage ? action.value : state.verificationMessageSMS
      }
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_RECEIVE_VERIFICATION_MASSAGE: {
      return {
        ...state,
        verificationMessageEmail: action.email,
        verificationMessageSMS: action.sms,
        VerificationMessageEmailType: action.email ? 1 : 0,
        VerificationMessageSMSType: action.sms ? 1 : 0
      }
    }
    case actions.ThirdPartyInfoUIActionTypes.UI_SELECT_VERIFICATION_MASSAGE_TYPE: {
      return {
        ...state,
        verificationMessageEmail: state.verificationMessageEmail ? state.verificationMessageEmail : EMAIL_VERIFICATION_MESSAGE,
        verificationMessageSMS: state.verificationMessageSMS ? state.verificationMessageSMS : SMS_VERIFICATION_MESSAGE,
        VerificationMessageEmailType: action.loginType === 'email' ? action.typeMessage : state.VerificationMessageEmailType,
        VerificationMessageSMSType: action.loginType === 'sms' ? action.typeMessage : state.VerificationMessageSMSType,
      }
    }
    default: {
      return state;
    }
  }
}
