import { AbstractTableColumn } from './AbstractItem';
import {
  ADD_WHITELIST_METHOD,
  ADD_WHITELIST_PATTERNS_METHOD,
  DELETE_WHITELIST_METHOD,
  DELETE_WHITELIST_PATTERNS_METHOD,
  GET_WHITELIST_METHOD,
  GET_WHITELIST_PATTERNS_METHOD,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE,
  PageDirection
} from './constants';

export interface WhitelistUI {
  currentPage?: Whitelist[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  msisdn?: string;
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  screenState?: string;
}

export interface Whitelist {
  id?: string;
  signup_user?: string;
  pattern?: string;
  example?: string;
  tags?: string[]; // Tags IDS
  tagNames?: string[]; // Locale
  allTags?: string[];
  tagNumber?: number;
}

export const whitelistsTableColumns: AbstractTableColumn[] = [
  {
    colType: 'Block Method',
    title: 'ID',
    class: 'xsm'
  },
  {
    colType: 'name',
    title: 'Signup User',
    class: 'lg'
  },
  {
    colType: 'tags',
    title: 'Tags',
    class: 'lg'
  },
  {
    colType: 'action',
    title: '',
    class: 'action'
  }
];

export class GetWhitelistMethod {
  readonly method = GET_WHITELIST_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public tester?: number,
    public direction?: number,
    public hash?: string,
    public reference?: number,
    public msisdn?: string
  ) {}
}

export class AddUsersToWhitelist {
  readonly method = ADD_WHITELIST_METHOD;
  constructor(public chat_id: string, public users: Whitelist[]) {}
}

export class RemoveUserFromWhitelist {
  readonly method = DELETE_WHITELIST_METHOD;
  constructor(public users: string[], public chat_id: string) {}
}

export class GetWhitelistPatternsMethod {
  readonly method = GET_WHITELIST_PATTERNS_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public tester?: number,
    public direction?: number,
    public hash?: string,
    public reference?: number
  ) {}
}

export class AddPatternToWhitelist {
  readonly method = ADD_WHITELIST_PATTERNS_METHOD;
  constructor(public chat_id: string, public data: Whitelist[]) {}
}

export class RemovePatternFromWhitelist {
  readonly method = DELETE_WHITELIST_PATTERNS_METHOD;
  constructor(public pattern: string[], public chat_id: string) {}
}
