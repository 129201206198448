import { DomSanitizer } from '@angular/platform-browser';
import { RootStoreSelectors } from '../store/root-store.selectors';
import { MediaCenterDispatchers } from '../store/mediaCenter/mediaCenter.dispatchers';
import { MessageMediaStatus } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../store/root-store.selectors";
import * as i2 from "../store/mediaCenter/mediaCenter.dispatchers";
import * as i3 from "@angular/platform-browser";
export class MediaCenterService {
    constructor(rootSelector, _mediaCenterDispatchers, _sanitizer) {
        this.rootSelector = rootSelector;
        this._mediaCenterDispatchers = _mediaCenterDispatchers;
        this._sanitizer = _sanitizer;
    }
    getMyMediaGallery(mediaType) {
        this._mediaCenterDispatchers.getMyMediaGallery(mediaType);
    }
    getMediaCenterItems$(type, aspectRatio) {
        return this.rootSelector.getMediaCenterItems$(type, aspectRatio);
    }
    getMedia(localMedia) {
        if (localMedia) {
            return this._sanitizer.bypassSecurityTrustUrl(localMedia);
        }
        return '';
    }
    getCover(status) {
        if (status === MessageMediaStatus.UPLOADING ||
            status === MessageMediaStatus.DOWNLOADING) {
            return true;
        }
        return false;
    }
    deleteMediaCenterItem(item) {
        this._mediaCenterDispatchers.deleteMediaCenterItem(item);
    }
    mediaCenterUploading(event, type) {
        this._mediaCenterDispatchers.mediaCenterUploading(event.localFile, event.type, type);
    }
}
MediaCenterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaCenterService_Factory() { return new MediaCenterService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.MediaCenterDispatchers), i0.ɵɵinject(i3.DomSanitizer)); }, token: MediaCenterService, providedIn: "root" });
