/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../abstract-icons/delete-icon.component.ngfactory";
import * as i2 from "../abstract-icons/delete-icon.component";
import * as i3 from "../abstract-icons/delete-frame-icon.component.ngfactory";
import * as i4 from "../abstract-icons/delete-frame-icon.component";
import * as i5 from "./circle-btn/circle-btn.component.ngfactory";
import * as i6 from "./circle-btn/circle-btn.component";
import * as i7 from "@angular/common";
import * as i8 from "./delete-btn.component";
var styles_DeleteBtnComponent = [];
var RenderType_DeleteBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteBtnComponent, data: {} });
export { RenderType_DeleteBtnComponent as RenderType_DeleteBtnComponent };
function View_DeleteBtnComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-icon", [], null, null, null, i1.View_DeleteIconComponent_0, i1.RenderType_DeleteIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.DeleteIconComponent, [], null, null)], null, null); }
function View_DeleteBtnComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-frame-icon", [], null, null, null, i3.View_DeleteFrameIconComponent_0, i3.RenderType_DeleteFrameIconComponent)), i0.ɵdid(1, 49152, null, 0, i4.DeleteFrameIconComponent, [], null, null)], null, null); }
export function View_DeleteBtnComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-circle-btn", [["delete", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.btnEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CircleBtnComponent_0, i5.RenderType_CircleBtnComponent)), i0.ɵdid(1, 49152, null, 0, i6.CircleBtnComponent, [], { frame: [0, "frame"] }, { btnClicked: "btnClicked" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_DeleteBtnComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_DeleteBtnComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frame; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.frame; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.frame; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_DeleteBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-btn", [], null, null, null, View_DeleteBtnComponent_0, RenderType_DeleteBtnComponent)), i0.ɵdid(1, 49152, null, 0, i8.DeleteBtnComponent, [], null, null)], null, null); }
var DeleteBtnComponentNgFactory = i0.ɵccf("app-delete-btn", i8.DeleteBtnComponent, View_DeleteBtnComponent_Host_0, { disabled: "disabled", frame: "frame" }, { btnClicked: "btnClicked" }, []);
export { DeleteBtnComponentNgFactory as DeleteBtnComponentNgFactory };
