import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/auth.service';
import {
  UPLOAD_URL_API_SUFFIX,
  DOWNLOAD_URL_API_SUFFIX,
  META_URL_API_SUFFIX
} from 'src/models/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(UPLOAD_URL_API_SUFFIX) ||
      request.url.includes(DOWNLOAD_URL_API_SUFFIX) ||
      request.url.includes(META_URL_API_SUFFIX)
    ) {
      request = request.clone({
        setHeaders: {
          'X-TOKEN': this.auth.token
        }
      });
    }
    return next.handle(request);
  }
}
