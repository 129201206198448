import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-popup-notifier',
  templateUrl: './popup-notifier.component.html',
  styleUrls: ['./popup-notifier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupNotifierComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() validationMsg = false;
  @Input() cancelTitle: string;
  @Input() validationTitle: string;
  @Output() hidePopupNotify = new EventEmitter<boolean>(false);
  @Output() closePopupNotify = new EventEmitter<boolean>(false);
  @Output() agreePopupNotify = new EventEmitter<boolean>();
  @Input() showCancel: boolean;
  @Input() hideFooter = false;
  @Input() hideclose = false;
  @Input() buttonTitle: string;
  @Input() menus = false;
  ngOnInit() {}

  cancelEvent() {
    this.hidePopupNotify.emit(true);
  }
  agreeEvent() {
    this.agreePopupNotify.emit(true);
  }
  closeEvent() {
    this.closePopupNotify.emit(true);
  }
}
