<div class="container">
  <div class="temp-container" *ngIf="temp">
    <div class="header">
      <div class="header-title">
        <div class="back-btn" (click)="back()">
          <div class="btn">
            <img src="./assets/img/back-arrow.svg" alt="back-arrow" />
          </div>
        </div>
        <div class="temp-desc" *ngIf="temp">
          <div class="title">
            {{ temp.title }}
          </div>
          <div *ngIf="!isProduction" class="temp-id">
            Template id : {{ tempId }}
          </div>
          <div class="created-by">
            <div class="create">Created by {{ temp.createdBy }}</div>
            <div class="categories">
              <div class="cat" *ngFor="let cat of temp.categories">
                - {{ getCategory(cat) }}
              </div>
            </div>
          </div>
          <div class="sFeatures">
            {{ temp.sFeatuers }} Features Included
            <div class="tFeaturs">
              ({{ temp.sFeatuers }}/{{ temp.tFeatuers }}) Selected Features.
            </div>
          </div>
        </div>
      </div>
      <div class="temp-cntrl">
        <div class="cost" *ngIf="temp">
          <!-- <div class="cost-fee">{{ temp.cost }}</div> -->
        </div>
        <div class="start" (click)="startwithThisTempate()">
          Start with this template
        </div>
      </div>
    </div>
    <div class="slider-container" *ngIf="temp">
      <ng-image-slider [images]="temp.images" #nav></ng-image-slider>
    </div>
    <div class="desc-txt" [innerHTML]="temp.desc" *ngIf="temp"></div>
  </div>
  <div class="temp-container" *ngIf="!temp && choosenTemp">
    <div class="header">
      <div class="temp-desc">
        <div class="title">
          {{ choosenTemp.title }}
        </div>
      </div>
      <div class="temp-cntrl">
        <div class="start" (click)="startwithThisTempate()">
          Start with this template
        </div>
      </div>
    </div>
  </div>
  <div class="related-temps" *ngIf="relatedTemp && relatedTemp.length > 1">
    <div class="related-title">Related Templates</div>

    <div
      class="related-temp"
      *ngFor="let temp of filteredrelatedTemp | slice: 0:5; let i = index"
      (click)="changeTemplate(temp)"
    >
      <img [src]="temp?.android_image_url" />
      <div class="desc">
        <div class="temp-title">
          {{ temp.title }}
        </div>
        <div class="temp-cat">
          {{ getCategory(temp?.category) }}
        </div>
      </div>
    </div>
  </div>
</div>
