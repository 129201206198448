import { Store } from '@ngrx/store';
import * as ItemActions from './item.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ItemDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateItem(itemRef, itemUpdatedProps) {
        this._store.dispatch(new ItemActions.UpdateItem(itemRef, itemUpdatedProps));
    }
    updateItemLink(itemRef, itemLink, pageId) {
        this._store.dispatch(new ItemActions.UpdateItemLink(itemRef, itemLink, pageId));
    }
    requestUpdateItemLink(itemRef, itemLink, pageId) {
        this._store.dispatch(new ItemActions.RequestUpdateItemLink(itemRef, itemLink, pageId));
    }
    reorderItem(itemRef, newPosition) {
        this._store.dispatch(new ItemActions.ReorderItem(itemRef, newPosition));
    }
    deleteItemRequest(item) {
        this._store.dispatch(new ItemActions.DeleteItemRequest(item));
    }
    deleteItem(item) {
        this._store.dispatch(new ItemActions.DeleteItem(item));
    }
    addItemRequest(parentComponent, specialItemValues) {
        this._store.dispatch(new ItemActions.AddItemRequest(parentComponent, specialItemValues));
    }
    addItem(item) {
        this._store.dispatch(new ItemActions.AddItem(item));
        this.updateItemLink(item.ref, item.link, item.page_id);
    }
    deleteItemsOfComponent(parentComponent) {
        this._store.dispatch(new ItemActions.DeleteItemsOfComponent(parentComponent));
    }
    addItems(items) {
        this._store.dispatch(new ItemActions.AddItems(items));
    }
}
ItemDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ItemDispatchers_Factory() { return new ItemDispatchers(i0.ɵɵinject(i1.Store)); }, token: ItemDispatchers, providedIn: "root" });
