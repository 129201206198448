<div class="share">
  <div class="share-title">Share With:</div>

  <div class="share-social">
    <a
      (click)="shareOnSocial($event, 'F')"
      class="share-social-box facebook clickable-cursor"
    >
      <i class="fab fa-facebook-f"></i>
    </a>
    <a
      (click)="shareOnSocial($event, 'T')"
      class="share-social-box twitter clickable-cursor"
    >
      <i class="fab fa-twitter"></i>
    </a>
    <a
      (click)="shareOnSocial($event, 'G')"
      class="share-social-box google clickable-cursor"
    >
      <i class="fab fa-google-plus-g"></i>
    </a>
  </div>

  <div class="share-footer">
    <div class="share-copy-box">
      <!-- Link UI -->
      <div class="share-copy-box-logo clickable-cursor">
        <i class="fas fa-link"></i>
      </div>

      <!-- Text to copy -->
      <div class="share-copy-box-text truncate-text">{{ shareLink }}</div>

      <!-- btn cliked -->
      <div
        ngxClipboard
        class="share-copy-box-action clickable-cursor"
        [cbContent]="shareLink"
        (cbOnSuccess)="copySuccessfullyEvent()"
      >
        Copy
      </div>
    </div>
  </div>
</div>
