import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';
import { HoverTooltipIconComponent } from './hover-tooltip-icon/hover-tooltip-icon.component';
import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';

@NgModule({
  imports: [CommonModule, AbstractIconsModule],
  declarations: [HoverTooltipComponent, HoverTooltipIconComponent],
  exports: [HoverTooltipComponent, HoverTooltipIconComponent]
})
export class AbstractTooltip {}
