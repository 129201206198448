import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragableFeaturesComponent } from './dragable-features.component';
import { FeaturesCategoriesComponent } from './features-categories/features-categories.component';
import { PagesControllerComponent } from './pages-controller/pages-controller.component';
import { FeaturesSublistComponent } from './features-sublist/features-sublist.component';
import { FeatureCategoryComponent } from './features-categories/feature-category/feature-category.component';
import { SublistItemComponent } from './features-sublist/sublist-item/sublist-item.component';
import { PagesControllerItemsComponent } from './pages-controller/pages-controller-items/pages-controller-items.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClosedMenuComponent } from './closed-menu/closed-menu.component';
import { BotsControlleComponent } from './bots-controlle/bots-controlle.component';
import { BotsControllerTemsComponent } from './bots-controlle/bots-controller-tems/bots-controller-tems.component';
import { HelpAreaComponent } from './../help-area/help-area.component';
import { ElementsContanierViewComponent } from '../mobile-view/page-builder-view/elements-contanier-view/elements-contanier-view.component';
import { ImageSliderViewComponent } from '../mobile-view/page-builder-view/image-slider-view/image-slider-view.component';
import { LocationViewComponent } from '../mobile-view/page-builder-view/location-view/location-view.component';
import { PdfViewComponent } from '../mobile-view/page-builder-view/pdf-view/pdf-view.component';
import { TextViewComponent } from '../mobile-view/page-builder-view/text-view/text-view.component';
import { VideoViewComponent } from '../mobile-view/page-builder-view/video-view/video-view.component';
import { GalleryViewComponent } from '../mobile-view/page-builder-view/gallery-view/gallery-view.component';
import { FeaturListViewComponent } from '../mobile-view/page-builder-view/featur-list-view/featur-list-view.component';
import { YoutubelistViewComponent } from '../mobile-view/page-builder-view/youtubelist-view/youtubelist-view.component';
import { YouTubeListVideoComponent } from '../mobile-view/page-builder-view/youtubelist-view/you-tube-list-video/you-tube-list-video.component';
import { PageBuilderViewComponent } from '../mobile-view/page-builder-view/page-builder-view.component';
import { StatusBarComponent } from '../mobile-view/common/status-bar/status-bar.component';
import { PagesNormalBarComponent } from '../mobile-view/main-page-view/pages-normal-bar/pages-normal-bar.component';
import { NgxModule } from 'src/app/ngx.module';
import { CellsModule } from 'src/app/cells/cells.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AbstractTooltip } from 'src/app/abstract-tooltip/abstract-tooltip.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AccessorsModule } from './../../../../../accessors/accessors.module';
import { MenuFormModule } from '../../../menus/menu-form/menu.form.module';
import {
  MatButtonModule,
  MatDialogModule,
  MatTooltipModule
} from '@angular/material';

@NgModule({
  declarations: [
    DragableFeaturesComponent,
    FeaturesCategoriesComponent,
    PagesControllerComponent,
    FeaturesSublistComponent,
    FeatureCategoryComponent,
    SublistItemComponent,
    PagesControllerItemsComponent,
    ClosedMenuComponent,
    BotsControlleComponent,
    BotsControllerTemsComponent,
    HelpAreaComponent,
    ElementsContanierViewComponent,
    ImageSliderViewComponent,
    LocationViewComponent,
    TextViewComponent,
    PdfViewComponent,
    VideoViewComponent,
    GalleryViewComponent,
    FeaturListViewComponent,
    ElementsContanierViewComponent,
    YoutubelistViewComponent,
    PageBuilderViewComponent,
    StatusBarComponent,
    PagesNormalBarComponent,
    YouTubeListVideoComponent
  ],
  imports: [
    CommonModule,

    SharedModule,
    NgxModule,
    CellsModule,
    AngularSvgIconModule,
    AbstractTooltip,
    AbstractBtnsModule,
    MenuFormModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    AccessorsModule
  ],

  exports: [
    FeaturesCategoriesComponent,
    FeaturesSublistComponent,
    PagesControllerComponent,
    DragableFeaturesComponent,
    ClosedMenuComponent,
    HelpAreaComponent,
    ElementsContanierViewComponent,
    ImageSliderViewComponent,
    LocationViewComponent,
    TextViewComponent,
    PdfViewComponent,
    VideoViewComponent,
    GalleryViewComponent,
    FeaturListViewComponent,
    ElementsContanierViewComponent,
    YoutubelistViewComponent,
    YouTubeListVideoComponent,
    StatusBarComponent,
    PagesNormalBarComponent,
    PageBuilderViewComponent
  ]
})
export class DragableFeaturesModule {}
