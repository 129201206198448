/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./next-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./next-button.component";
import * as i4 from "../../../../appMgmt.service";
var styles_NextButtonComponent = [i0.styles];
var RenderType_NextButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NextButtonComponent, data: {} });
export { RenderType_NextButtonComponent as RenderType_NextButtonComponent };
function View_NextButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "back-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getNextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"], ["style", "transform: rotate(180deg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-extra-icons.svg#icon-navigate"]], null, null, null, null, null))], null, null); }
export function View_NextButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NextButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideNextButton; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NextButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-next-button", [], null, null, null, View_NextButtonComponent_0, RenderType_NextButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.NextButtonComponent, [i4.AppMgmtService], null, null)], null, null); }
var NextButtonComponentNgFactory = i1.ɵccf("app-next-button", i3.NextButtonComponent, View_NextButtonComponent_Host_0, { hideNextButton: "hideNextButton", currentScreenNumber: "currentScreenNumber", isChannelConfOnly: "isChannelConfOnly" }, {}, []);
export { NextButtonComponentNgFactory as NextButtonComponentNgFactory };
