<div class="post-attach">
  <div class="post-attach-body">
    <h4>Attachments</h4>
    <p>Attach some additions to your post</p>
    <div class="cell">
      <div class="cell-details">
        <img src="./assets/img/poll-post.svg" alt="polls" />
        <div class="title">Polls</div>
      </div>
      <!-- <app-link-btn (btnClicked)="toggleMenusPool(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Attach
        </ng-container>
      </app-link-btn> -->
      <div class="link" (click)="toggleMenusPool(true)">Attach</div>
    </div>
    <div class="cell" *appShowIfHasPrivilege="privilegesName.listcoupon">
      <div class="cell-details">
        <img src="./assets/img/coupon-post.svg" alt="Coupon" />
        <div class="title">Coupon</div>
      </div>
      <!-- <app-link-btn (btnClicked)="toggleCouponsPool(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Attach
        </ng-container>
      </app-link-btn> -->
      <div class="link" (click)="toggleCouponsPool(true)">Attach</div>
    </div>
    <div
      class="cell"
      *ngIf="
        selectedTab?.sticky_visible &&
        currentSelectedType !== mType.TEXT &&
        currentSelectedType !== mType.DOCUMENT &&
        currentSelectedType !== mType.AUDIO &&
        currentSelectedType !== mType.CALENDAR &&
        selectedChat?.type !== group
      "
    >
      <div class="cell-details">
        <img src="./assets/img/sticky-post-editor.svg" alt="Coupon" />
        <div class="title">Sticky Note</div>
      </div>
      <!-- <app-link-btn (btnClicked)="toggleStickyInput(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Attach
        </ng-container>
      </app-link-btn> -->
      <div class="link" (click)="toggleStickyInput(true)">Attach</div>
    </div>
  </div>
</div>

<app-modal-container
  *ngIf="localState?.showCouponsPool"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="toggleCouponsPool(false)"
>
  <app-coupons-pool
    #couponsPool
    (selectCouponNotfiy)="setCouponEvent($event)"
    (cancelNotfiy)="toggleCouponsPool(false)"
  >
  </app-coupons-pool>
</app-modal-container>

<app-modal-container
  *ngIf="localState?.showMenusPool"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="toggleMenusPool(false)"
>
  <app-menus-pool
    #menusPool
    (selectMenuNotfiy)="setMenuEvent($event)"
    (cancelNotfiy)="toggleMenusPool(false)"
  >
  </app-menus-pool>
</app-modal-container>

<app-modal-container
  *ngIf="localState?.showStickyInput"
  [count]="layoutService?.appModelOpenStatus$ | async"
  (cancelNotfiy)="toggleStickyInput(false)"
>
  <app-compose-sticky-input
    (stickyInputNotify)="setStickyInput($event)"
    (cancelNotfiy)="toggleStickyInput(false)"
  >
  </app-compose-sticky-input>
</app-modal-container>
