import { Store } from '@ngrx/store';
import * as HistoryRepliesActions from './repliesEOP.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class RepliesEOPDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateFirstDepthEOP(parentMsgID, eopFirstDepth, chatID) {
        this._store.dispatch(new HistoryRepliesActions.UpdateFirstDepthEOP(parentMsgID, eopFirstDepth, chatID));
    }
    updateSecondDepthEOP(parentMsgID, eopSecondDepth, userID, chatID) {
        this._store.dispatch(new HistoryRepliesActions.UpdateSecondDepthEOP(parentMsgID, eopSecondDepth, userID, chatID));
    }
}
RepliesEOPDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RepliesEOPDispatchers_Factory() { return new RepliesEOPDispatchers(i0.ɵɵinject(i1.Store)); }, token: RepliesEOPDispatchers, providedIn: "root" });
