import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

import { NgxModule } from 'src/app/ngx.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { NavBarComponent } from './navbar/navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NavbarPagenameComponent } from './navbar-pagename/navbar-pagename.component';
import { LayoutComponent } from './layout.component';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';
import { HelpComponent } from '../+dashboard/app-mgmt/custom/custom-area/common/help/help.component';
import { ContainersModule } from 'src/app/containers/containers.module';
import { MatToolbarModule, MatTooltipModule } from '@angular/material';
import { AbstractNotifiersModule } from 'src/app/abstract-notifiers/abstract-notifiers.module';
import { MgmtProgressComponent } from '../+dashboard/app-mgmt/mgmt-progress/mgmt-progress.component';
import { FeaturesCounterComponent } from '../+dashboard/app-mgmt/custom/dragable-features/features-counter/features-counter.component';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { Ng2OdometerModule } from 'ng2-odometer';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { AppleIdPopupComponent } from '../+dashboard/app-mgmt/custom/custom-area/apple-id-popup/apple-id-popup.component';
import { InputCodeComponent } from '../+dashboard/app-mgmt/custom/custom-area/apple-id-popup/input-code/input-code.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';

@NgModule({
  imports: [
    CommonModule,
    AccessorsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    NgxModule,
    SharedModule,

    ContainersModule,
    MatTooltipModule,
    MatToolbarModule,
    AbstractNotifiersModule,
    AbstractBtnsModule,
    AbstractControlsModule,
    Ng2OdometerModule.forRoot()
  ],
  declarations: [
    NavBarComponent,
    SideMenuComponent,
    BreadcrumbsComponent,
    NavbarPagenameComponent,
    LayoutComponent,
    ConfirmInfoComponent,
    HelpComponent,
    MgmtProgressComponent,
    FeaturesCounterComponent,
    AppleIdPopupComponent,
    InputCodeComponent,
    CountdownTimerComponent
  ],
  exports: [BreadcrumbsComponent, LayoutComponent]
})
export class LayoutModule {}
