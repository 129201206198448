import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import { SubMenuUIActionTypes } from './subMenuUI.actions';
import * as SubMenuUIAction from './subMenuUI.actions';
import { SubMenuUiDispatchers } from './subMenuUI.dispatchers';
import {
  AddSubMenuItemMethod,
  CreateSubMenuMethod,
  SetSubMenuMethod,
  RemoveSubMenuItemMethod,
  GetSubMenusMethod
} from 'src/models/SubMenu';
import { SubMenuStore } from 'src/models/SubMenu';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class SubMenuUiEffects {
  @Effect({ dispatch: false })
  createSubMenu = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.CREATE_SUB_MENU),
    map((action: SubMenuUIAction.CreateSubMenu) => {
      this._socketGateway.sendSocketMessage(
        new CreateSubMenuMethod(action.subMenusDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  updateSubMenu = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.SET_SUB_MENU),
    map((action: SubMenuUIAction.SetSubMenu) => {
      const subMenuDetails: SubMenuStore = {
        ...action.subMenuDetails,
        id: action.subMenuID
      };
      this._socketGateway.sendSocketMessage(
        new SetSubMenuMethod(subMenuDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  addSubMenuItem = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.ADD_SUB_MENU_ITEM),
    map((action: SubMenuUIAction.AddSubMenuItem) => {
      this._socketGateway.sendSocketMessage(
        new AddSubMenuItemMethod(action.subMenuID, action.items)
      );
    })
  );

  @Effect({ dispatch: false })
  removeSubMenuItem = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.REMOVE_SUB_MENU_ITEM),
    map((action: SubMenuUIAction.RemoveSubMenuItem) => {
      this._socketGateway.sendSocketMessage(
        new RemoveSubMenuItemMethod(action.subMenuID, action.itemIDs)
      );
    })
  );

  //   //
  @Effect({ dispatch: false })
  getNextSubMenuPage = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.UI_SUB_MENU_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subMenuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subMenuUiState]) => {
      const action = <SubMenuUIAction.GetNextSubMenuPage>val;
      const prevRequest = subMenuUiState.previousRequest;
      if (
        !(
          subMenuUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          subMenuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubMenusMethod(0, subMenuUiState.eop)
        );
      } else {
        this._uiSubMenuDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevSubMenuPage = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.UI_SUB_MENU_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subMenuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subMenuUiState]) => {
      const action = <SubMenuUIAction.GetPrevSubMenuPage>val;
      const prevRequest = subMenuUiState.previousRequest;
      if (
        !(
          subMenuUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          subMenuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubMenusMethod(1, subMenuUiState.sop)
        );
      } else {
        this._uiSubMenuDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterSubMenuResetPaging = this.actions$.pipe(
    ofType(SubMenuUIActionTypes.UI_SUB_MENU_RESET_PAGING),
    map((action: SubMenuUIAction.ResetSubMenuPaging) => {
      this._uiSubMenuDispatchers.getNextSubMenuPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiSubMenuDispatchers: SubMenuUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
