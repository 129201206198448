import {
  ListRequestDetails,
  PageDirection,
  SCREEN_STATUS,
  SET_MAP_ACCOUNT_LINKS_REF
} from 'src/models/constants';
import { Actions } from './mapTripsNotifiers.actions';

import {
  MapTripsNotifiers,
  MapTripsNotifiersUI
} from 'src/models/mapTripsNotifiers';
import * as actions from './mapTripsNotifiers.actions';

const INITIAL_STATE: MapTripsNotifiersUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  selected_chat: null,
  currentStateScreen: 'Loading',
  accountID: '',
  notifiersList: []
};

export function mapTripsNotifiersReducer(
  state = INITIAL_STATE,
  action: Actions
): MapTripsNotifiersUI {
  switch (action.type) {
    case actions.MapTripsNotifiersActionTypes
      .UI_MAP_TRIPS_NOTIFIERS_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.MapTripsNotifiersActionTypes.UI_MAP_ACCOUNT_LINKS_CREATED: {
      const mapTripNotifier: MapTripsNotifiers = {};
      mapTripNotifier.account_id = action.tripNotifier[0].account_id;
      mapTripNotifier.notifiers_count = action.tripNotifier.length;
      return {
        ...state,
        currentPage: [mapTripNotifier, ...state.currentPage]
      };
    }
    case actions.MapTripsNotifiersActionTypes
      .UI_MAP_ACCOUNT_LINKS_LIST_RECEIVED: {
      return {
        ...state,
        currentPage:
          action.accountID && action.accountID.length > 0
            ? action.accountID
            : state.currentPage,
        currentStateScreen: 'Done'
      };
    }
    case actions.MapTripsNotifiersActionTypes
      .UI_GET_NOTIFIERS_LIST_RESET_PAGING: {
      return {
        ...state,
        accountID: action.accountID ? action.accountID : state.accountID
      };
    }
    case actions.MapTripsNotifiersActionTypes.UI_NOTIFIERS_LIST_RECEIVED: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        notifiersList:
          (action.tripNotifiers && action.tripNotifiers.length) > 0
            ? action.tripNotifiers
            : state.notifiersList
      };
    }
    case actions.MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_NEXT_PAGE: {
      return { ...state, direction: PageDirection.NEXT };
    }
    case actions.MapTripsNotifiersActionTypes.UI_GET_NOTIFIERS_LIST_PREV_PAGE: {
      return { ...state, direction: PageDirection.PREV };
    }
    case actions.MapTripsNotifiersActionTypes.UI_REST_NOTIFIERS_LIST: {
      return {
        ...state,
        eop: 0,
        sop: null,
        previousRequest: {},
        notifiersList: []
      };
    }
    case actions.MapTripsNotifiersActionTypes.UI_REMOVE_ACCOUNT_LINK_SUCCESS: {
      return {
        ...state,
        notifiersList: state.notifiersList.filter(res => res.id !== action.id),
        currentPage: state.currentPage.map(res => {
          if (
            res &&
            state.notifiersList.length > 0 &&
            res.account_id === state.notifiersList[0].account_id
          ) {
            return { ...res, notifiers_count: res.notifiers_count - 1 };
          } else {
            return res;
          }
        })
      };
    }
    case actions.MapTripsNotifiersActionTypes.UI_SET_MAP_ACCOUNT_LINKS_COUNT: {
      const passenger = state.currentPage.find(
        passenger => passenger.account_id === action.accountID
      );
      passenger.notifiers_count = action.count;

      return {
        ...state,
        currentPage: state.currentPage.map(res =>
          res.account_id === action.accountID ? passenger : res
        )
      };
    }
    default: {
      return state;
    }
  }
}
