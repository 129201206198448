/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hover-tooltip-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hover-tooltip-icon.component";
var styles_HoverTooltipIconComponent = [i0.styles];
var RenderType_HoverTooltipIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HoverTooltipIconComponent, data: {} });
export { RenderType_HoverTooltipIconComponent as RenderType_HoverTooltipIconComponent };
function View_HoverTooltipIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.toggleTooltipVisibility(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.toggleTooltipVisibility(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "./assets/svg/app-sections.svg#icon-info-tooltip"; _ck(_v, 1, 0, currVal_0); }); }
function View_HoverTooltipIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "./assets/img/help-info.svg"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.toggleTooltipVisibility(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.toggleTooltipVisibility(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, null); }
function View_HoverTooltipIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tooltip-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tooltip-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tooltip-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 4, 0, currVal_1); }); }
function View_HoverTooltipIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "display: flex; align-items: center; margin: 0 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inside; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.inside; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showTooltip; _ck(_v, 6, 0, currVal_2); }, null); }
function View_HoverTooltipIconComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "tooltip-text"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.toggleTooltipVisibility(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.toggleTooltipVisibility(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tooltip-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "tooltip-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Here"])), (_l()(), i1.ɵted(-1, null, [" For More Information "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.link; _ck(_v, 5, 0, currVal_1); }); }
function View_HoverTooltipIconComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "display: flex; align-items: center; margin: 0 5px;"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.toggleTooltipVisibility(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.toggleTooltipVisibility(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showTooltip; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "./assets/svg/app-sections.svg#icon-info-tooltip"; _ck(_v, 2, 0, currVal_0); }); }
export function View_HoverTooltipIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoverTooltipIconComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.secStyle; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.secStyle; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HoverTooltipIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip-icon", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HoverTooltipIconComponent_0, RenderType_HoverTooltipIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HoverTooltipIconComponentNgFactory = i1.ɵccf("app-hover-tooltip-icon", i3.HoverTooltipIconComponent, View_HoverTooltipIconComponent_Host_0, { title: "title", description: "description", link: "link", secStyle: "secStyle", inside: "inside", top: "top", left: "left" }, {}, []);
export { HoverTooltipIconComponentNgFactory as HoverTooltipIconComponentNgFactory };
