<div class="snackbar" *ngIf="refresh && !info">
  <div class="snackbar-refresh">
    <img width="40" height="40" src="./assets/img/refresh.svg" alt="refresh" />
  </div>
  <div class="snackbar-text">
    <h3>Refresh</h3>
    <p>You are using an older version</p>
  </div>
  <div class="snackbar-btn">
    <a (click)="refreshWindowEvent()" class="snackbar-btn-refresh">Refresh</a>
    <a (click)="closeEvent()" class="snackbar-btn-close">Close</a>
  </div>
</div>

<div class="snackbar" *ngIf="refresh && info">
  <div class="snackbar-info">
    <img width="40" height="40" src="./assets/img/info.svg" alt="info" />
  </div>
  <div class="snackbar-text">
    <h3>{{ title }}</h3>
    <p>{{ message }}</p>
  </div>
  <div class="snackbar-btn">
    <a (click)="closeEvent()" class="snackbar-info-btn-close">Close</a>
  </div>
</div>
