/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-preview-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./link-preview-cell.component";
var styles_LinkPreviewCellComponent = [i0.styles];
var RenderType_LinkPreviewCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkPreviewCellComponent, data: {} });
export { RenderType_LinkPreviewCellComponent as RenderType_LinkPreviewCellComponent };
function View_LinkPreviewCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.headerImg; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rootUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_LinkPreviewCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "head"]], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.headImg; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rootUrl; _ck(_v, 0, 0, currVal_0); }); }
function View_LinkPreviewCellComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "url-title"], ["dir", "auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_LinkPreviewCellComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "url-desc"], ["dir", "auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.description.split(" ").length > 20) ? (_co.description.split(" ").splice(0, 20).join(" ") + " ...") : _co.description); _ck(_v, 1, 0, currVal_0); }); }
function View_LinkPreviewCellComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "url-site text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rootUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_LinkPreviewCellComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "url-container no-copyable-item clickable-cursor media-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewCellComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewCellComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "core wrap-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewCellComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewCellComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkPreviewCellComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.image && !_co.isSqure); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.image && _co.isSqure); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.description; _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.rootUrl && _co.showSite); _ck(_v, 12, 0, currVal_4); }, null); }
export function View_LinkPreviewCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-preview-cell", [], null, null, null, View_LinkPreviewCellComponent_0, RenderType_LinkPreviewCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.LinkPreviewCellComponent, [], null, null)], null, null); }
var LinkPreviewCellComponentNgFactory = i1.ɵccf("app-link-preview-cell", i3.LinkPreviewCellComponent, View_LinkPreviewCellComponent_Host_0, { url: "url", title: "title", description: "description", image: "image", width: "width", height: "height", rootUrl: "rootUrl", style: "style", isInstant: "isInstant", redirect: "redirect", showSite: "showSite" }, {}, ["*"]);
export { LinkPreviewCellComponentNgFactory as LinkPreviewCellComponentNgFactory };
