/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sticker-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ng-circle-progress/ng-circle-progress.ngfactory";
import * as i4 from "ng-circle-progress";
import * as i5 from "../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i6 from "angular-svg-icon";
import * as i7 from "./sticker-cell.component";
import * as i8 from "../cells.service";
var styles_StickerCellComponent = [i0.styles];
var RenderType_StickerCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StickerCellComponent, data: {} });
export { RenderType_StickerCellComponent as RenderType_StickerCellComponent };
function View_StickerCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StickerCellComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "circle-progress", [], null, null, null, i3.View_CircleProgressComponent_0, i3.RenderType_CircleProgressComponent)), i1.ɵdid(1, 770048, null, 0, i4.CircleProgressComponent, [i4.CircleProgressOptions, i1.ElementRef, i2.DOCUMENT], { percent: [0, "percent"], outerStrokeColor: [1, "outerStrokeColor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.percent; var currVal_1 = _co.themeColor; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_StickerCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "loader-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "svg-icon", [["class", "cancel"], ["src", "./assets/img/close-white.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SvgIconComponent_0, i5.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i6.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i6.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], stretch: [1, "stretch"], svgStyle: [2, "svgStyle"] }, null), i1.ɵpod(3, { "width.px": 0, fill: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickerCellComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickerCellComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "./assets/img/close-white.svg"; var currVal_1 = true; var currVal_2 = _ck(_v, 3, 0, 14, "#C0C0C0"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.percent; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.percent; _ck(_v, 7, 0, currVal_4); }, null); }
function View_StickerCellComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "resticker clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retryEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas"]], [[2, "fa-long-arrow-alt-up", null], [2, "fa-long-arrow-alt-down", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mediaStatus === _co.messageMediaStatus.UPLOAD_FAILED); var currVal_1 = (_co.mediaStatus === _co.messageMediaStatus.DOWNLOAD_FAILED); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_StickerCellComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "media-center fit"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; _ck(_v, 0, 0, currVal_0); }); }
export function View_StickerCellComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "media-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "loader-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickerCellComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickerCellComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickerCellComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mediaStatus === _co.messageMediaStatus.UPLOADING) || (_co.mediaStatus === _co.messageMediaStatus.DOWNLOADING)); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.mediaStatus === _co.messageMediaStatus.DOWNLOAD_FAILED) || (_co.mediaStatus === _co.messageMediaStatus.UPLOAD_FAILED)); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.localMedia; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_StickerCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sticker-cell", [], null, null, null, View_StickerCellComponent_0, RenderType_StickerCellComponent)), i1.ɵdid(1, 49152, null, 0, i7.StickerCellComponent, [i8.CellsService], null, null)], null, null); }
var StickerCellComponentNgFactory = i1.ɵccf("app-sticker-cell", i7.StickerCellComponent, View_StickerCellComponent_Host_0, { localMedia: "localMedia", mediaStatus: "mediaStatus", percent: "percent", themeColor: "themeColor" }, { retryNotify: "retryNotify", cancelNotify: "cancelNotify" }, []);
export { StickerCellComponentNgFactory as StickerCellComponentNgFactory };
