<!-- Scroll section -->
<!-- (psScrollY)="onScrollEvent($event)"
(psYReachEnd)="onYEnd()"
(psYReachStart)="onYStart()"
[config]="config" -->
<div #ScrollContainer
  (scroll)="onScroll($event)"
  class="copyable-item chat scrollable-body">
  <div class="header-scroll-area"></div>
  <div class="channel-reply no-copyable-item">
    <i class="fas fa-lock"
      style="font-size: 19px"></i>
    <div class="title">Your Reply will appear to</div>
    <div class="desc">
      <span *ngIf="selectedUserId"
        class="text-capitalize name"
        [innerHTML]="
          (uiService.getProfileByID$(selectedUserId) | async)?.name | twemoji
        "></span>
      only
    </div>
  </div>

  <span #messagesContainer
    class="content-scroll-area">
    <ng-container *ngFor="
        let messageDetails of (chatMessages$ | async)?.messages;
        let i = index;
        trackBy: TrackByFunction;
        let last = last
      ">
      <app-bubbel-cell [message]="messageDetails"
        [chatType]="'Channel'"
        [isSubChat]="isSubChat"
        [attr.class]="messageDetails?.isViewed ? 'read' : 'unread'"
        [chatName]="(uiService.effectiveSelectedChat$ | async)?.name"
        [class.left]="messageDetails?.position === 'LEFT'"
        [class.right]="messageDetails?.position === 'RIGHT'"
        [isOneToOneReplie]="true"
        [isAdmin]="(uiService.selectedChat$ | async)?.isAdmin"
        [mainStyle]="mainStyle"
        (retryNotify)="retryEvent($event)"
        (cancelNotify)="cancelEvent($event)"
        inViewport
        (inViewportAction)="viewMessageEvent($event, messageDetails)">
      </app-bubbel-cell>
      <app-menu-holder *ngIf="messageDetails?.inline_menu"
        [menus]="(messageDetails?.inline_menu)[0]"
        [messageDetails]="messageDetails"
        [bot]="true"
        [menu_ref]="(messageDetails?.inline_menu)[0]?.menu_ref"
        (buttonClickedNotify)="buttonClicked($event)"></app-menu-holder>

      {{
      last ? onLastMessage((chatMessages$ | async)?.totalNumberOfPages) : ''
      }}
    </ng-container>
  </span>
</div>

<div *ngIf="
    (uiService.numberOfNotViewedMessagesOfSelectedUser$ | async) > 0 && !isInEnd
  "
  class="unread-container clickable-cursor"
  (click)="scrollToFirstUnReadMessage()">
  <div class="unread-msg reply">
    <span class="arrow">
      <i class="fas fa-angle-down"></i>
    </span>
    <span class="counter">
      {{
      uiService.numberOfNotViewedMessagesOfSelectedUser$ | async | countSize
      }}
      New Messages
    </span>
  </div>
</div>