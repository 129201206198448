import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';

import * as ChatThemes from './chatThemes.actions';

import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { StorageService } from 'src/app/core/storage.service';
import { GetChatThemes, SetChatThemes } from 'src/models/chatThemes';

@Injectable()
export class ChatThemesUIEffects {
  @Effect({ dispatch: false })
  setChatThemeUi = this.actions$.pipe(
    ofType(ChatThemes.ChatThemesActionTypes.SET_CHAT_THEME_UI),
    map((action: ChatThemes.SetChatTheme) => {
      this._socketGateway.sendSocketMessage(new SetChatThemes(action.style));
    })
  );
  @Effect({ dispatch: false })
  receveChatThemeUi = this.actions$.pipe(
    ofType(ChatThemes.ChatThemesActionTypes.RECEVE_CHAT_THEME_UI),
    map((action: ChatThemes.SetChatTheme) => {
      const style = JSON.stringify(action.style);

      // this.sorage.setRecord('styleChat', style);
    })
  );

  @Effect({ dispatch: false })
  getChatThemeUi = this.actions$.pipe(
    ofType(ChatThemes.ChatThemesActionTypes.GET_CHAT_THEME_UI),
    map((action: ChatThemes.GetChatThemeFromServer) => {
      this._socketGateway.sendSocketMessage(new GetChatThemes());
    })
  );
  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private sorage: StorageService,
    private _store: Store<AppState>
  ) {}
}
