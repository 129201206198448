import { Action } from '@ngrx/store/public_api';
import { AppMenu } from 'src/models/AppMenu';

export const enum AppMenuActionTypes {
  GET_APP_MENUS = 'GET_APP_MENUS',
  RECEIVE_APP_MENUS = 'RECEIVE_APP_MENUS',
  APP_MENU_DETAILS_RECEIVED = 'APP_MENU_DETAILS_RECEIVED',
  RESET = 'RESET'
}

export class GetAppMenus implements Action {
  readonly type = AppMenuActionTypes.GET_APP_MENUS;
  constructor(public menuType: string) {}
}

export class ReceiveAppMenus implements Action {
  readonly type = AppMenuActionTypes.RECEIVE_APP_MENUS;
  constructor(public appMenus: AppMenu[]) {}
}

export class AppMenuDetailsReceived implements Action {
  readonly type = AppMenuActionTypes.APP_MENU_DETAILS_RECEIVED;
  constructor(public appMenu: AppMenu) {}
}

export class Reset implements Action {
  readonly type = AppMenuActionTypes.RESET;
}

export type Actions =
  | GetAppMenus
  | ReceiveAppMenus
  | AppMenuDetailsReceived
  | Reset;
