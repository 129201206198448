import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/core/auth.service';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { UISections } from 'src/models/constants';
import { StorageService } from 'src/app/core/storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationComponent implements OnInit, OnDestroy {
  auth: any;
  constructor(
    private _route: ActivatedRoute,
    public authService: AuthService,
    private title: Title,
    private _router: Router,
    private dashboard: DashboardService,
    private meta: Meta,
    public localStorage: StorageService,
    private location: Location
  ) {}

  ngOnInit() {
    this.title.setTitle('The Native App Builder Signup');
    // this.meta.addTag({
    //   name: 'title',
    //   content: 'The Native App Builder Signup'
    // });
    this.authService.loginUseEmail();
    // Get TemplateID from url
    // const templateId = this._route.snapshot.queryParams['id'];
    // if (templateId) {
    //   this.authService.selectTemplate(templateId);
    // }
    this.auth = this.authService.authCollection$.subscribe(res => {
      const url = this.location.path();
      const urlPath = url.split('?');

      if (
        res.token &&
        (res.appConfig == 0 || res.appConfig == 1) &&
        res.is_nandbox == 0 &&
        res.chatId &&
        this.location.path() === '/login'
      ) {
        this._router.navigate(['/app']);
        this.dashboard.setSection(UISections.APP);
      }
    });
  }

  ngOnDestroy() {
    if (this.auth) {
      this.auth.unsubscribe();
    }
    // this._navigatedService.goBack();
  }
}
