import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { UiService } from 'src/app/core/ui.service';

@Component({
  selector: 'labs-toast-item',
  templateUrl: 'toast-item.component.html',
  styleUrls: ['toast-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastItemComponent implements OnInit {
  @Input()
  toast: any;
  constructor(public uiService: UiService) {}
  ngOnInit(): void {
    setTimeout(() => this.uiService.removeToastMessage(this.toast.key), 10000);
  }
}
