import { BubbleStatus, GET_CHAT_THEME_METHOD, SET_CHAT_THEME_METHOD } from './constants';
export const DefulatStyle = {
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: 'rgba(62, 193, 188, 0.95)',
    secandryBgColor: 'rgba(46, 212, 115,0.95)',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#efeae2',
    rightBubbelBgColor: '#d9fdd3',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ0 = BubbleStatus.nandbox_bubble;
export const NandboxBubbleThemes = {
    id: ɵ0,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '62, 193, 188',
    secandryBgColor: '46, 212, 115',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#efeae2',
    rightBubbelBgColor: '#d9fdd3',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ1 = BubbleStatus.darktheme_bubble;
export const DarkThemeBubble = {
    id: ɵ1,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '34, 52, 68',
    secandryBgColor: '52, 112, 167',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#2E5156',
    rightBubbelBgColor: '#3470A7',
    leftBubbelBgColor: '#223444',
    primiryTextColor: '#ffffff',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ2 = BubbleStatus.whatsapp_bubble;
export const WhatsAppBubble = {
    id: ɵ2,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '62, 193, 188',
    secandryBgColor: '46, 212, 115',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#E5DDD5',
    rightBubbelBgColor: '#E2FFC7',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ3 = BubbleStatus.viber_bubble;
export const ViberBubble = {
    id: ɵ3,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '62, 193, 188',
    secandryBgColor: '46, 212, 115',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#DDE0E0',
    rightBubbelBgColor: '#B0E0F5',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ4 = BubbleStatus.wechat_bubble;
export const WeChatBubble = {
    id: ɵ4,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '62, 193, 188',
    secandryBgColor: '46, 212, 115',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#EAEAEA',
    rightBubbelBgColor: '#A0E758',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
const ɵ5 = BubbleStatus.telegram_bubble;
export const TelegramBubble = {
    id: ɵ5,
    backgroundImage: 'https://nandbox.com/wp-content/uploads/2023/07/rectangle.webp',
    primiryBgColor: '62, 193, 188',
    secandryBgColor: '46, 212, 115',
    logo: 'https://nandbox.com/wp-content/uploads/2023/07/chat-logo.webp',
    chatBgImage: 'https://nandbox.com/wp-content/uploads/2023/07/chat2-background.webp',
    chatBgColor: '#D2DAE4',
    rightBubbelBgColor: '#EFFDDD',
    leftBubbelBgColor: '#ffffff',
    primiryTextColor: '#000000',
    primiryColor: '#01a1ee',
    secandryColor: '#f0f2f5',
    blaceholderImage: 'https://nandbox.com/wp-content/uploads/2023/07/blank-slates.webp'
};
export class GetChatThemes {
    constructor() {
        this.method = GET_CHAT_THEME_METHOD;
    }
}
export class SetChatThemes {
    constructor(chat_theme) {
        this.chat_theme = chat_theme;
        this.method = SET_CHAT_THEME_METHOD;
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
