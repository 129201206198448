<div
  class="menu-holder"
  [class.clickable-cursor]="showRemove"
  [class.mob]="mobView"
>
  <ng-container *ngIf="!showSettings && mainMenu">
    <div
      class="menu-holder-row"
      [class.active]="mobView"
      *ngFor="
        let menuRows of (appManagementService?.selectedMenu$ | async)?.rows;
        let i = index
      "
    >
      <div *ngIf="showRemove" class="menu-actions">
        <!-- <div
          class="clickable-cursor menu-actions-icon delete"
          (click)="deleteRow(i)"
        >
          <i class="fas fa-trash-alt"></i>
        </div> -->
        <div
          *ngIf="
            menuRows.buttons[0].button_style !== rowStyles.SMALLHL_NEW &&
            menuRows.buttons[0].button_style !== rowStyles.WIDELIFT_NEW &&
            (menuRows.buttons[0].button_form !== menuCat.SINGLE_CHOICE &&
              menuRows.buttons[0].button_form !== menuCat.SELECT_CHOICE &&
              menuRows.buttons[0].button_form !== menuCat.MULTI_CHOICE)
          "
          class="clickable-cursor menu-actions-icon add"
        >
          <!-- <i class="fas fa-plus"></i> -->
          <img
            (click)="createButton(i)"
            src="./assets/img/add-menu-button.svg"
            *ngIf="menuRows.buttons.length < 3"
          />
          <img
            src="./assets/img/disabled-add-menu-button.svg"
            *ngIf="menuRows.buttons.length >= 3"
          />
        </div>

        <div
          *ngIf="
            menuRows.buttons[0].button_form === menuCat.SELECT_CHOICE ||
            menuRows.buttons[0].button_form === menuCat.SINGLE_CHOICE ||
            menuRows.buttons[0].button_form === menuCat.MULTI_CHOICE ||
            (menuRows.buttons[0].button_form === menuCat.BUTTON &&
              (menuRows.buttons[0].button_style === rowStyles.SMALLHL_NEW ||
                menuRows.buttons[0].button_style === rowStyles.WIDELIFT_NEW))
          "
          class="clickable-cursor menu-actions-icon add"
        >
          <!-- <i class="fas fa-plus"></i> -->

          <img src="./assets/img/disabled-add-menu-button.svg" />
        </div>
      </div>
      <div
        class="cell-default"
        [class.cell-box]="!mobView && !config && !bot"
        *ngFor="let menuCol of menuRows?.buttons; let j = index"
      >
        <div class="menu-holder-row-button" [ngStyle]="getButtonStyle(menuCol)">
          <div
            class="drag-box"
            *ngIf="!menuCol?.button_style && !hideTextOnlySec && !mobView"
            (drop)="addButtonStyle($event, i, j, menuCol?.button_id)"
          >
            <img src="./assets/img/drag.svg" />
            <div class="drag-title">
              Drop items here
            </div>
          </div>

          <div
            class="icon-choice"
            style="    font-size: 8px;"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [class.active]="mobView"
            [class.card]="mobView && checkCardStyle(menuCol)"
            [class.dropdown-mobile-font]="
              mobView && menuCol.button_form === menuCat.SELECT_CHOICE
            "
            *ngIf="
              !hideTextOnlySec &&
              (menuCol.button_form === menuCat.SINGLE_CHOICE ||
                menuCol.button_form === menuCat.MULTI_CHOICE ||
                menuCol.button_form === menuCat.SELECT_CHOICE)
            "
          >
            <div
              class="seaction"
              [class.in-conf]="!mobView"
              [class.has-img]="imgCheck(menuCol?.button_style)"
              [class.select-choice]="
                menuCol.button_form === menuCat.SELECT_CHOICE
              "
            >
              <ng-container
                *ngIf="menuCol.button_form !== menuCat.SELECT_CHOICE"
              >
                <div
                  class="action-container"
                  *ngFor="let opt of menuCol?.button_option; let i = index"
                  [class.divider]="
                    mobView &&
                    checkDividerStyle(menuCol) &&
                    menuCol?.button_option?.length != i + 1
                  "
                  [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                  [class.row-reverse-single]="
                    mobView && checkReverseStyleSingle(menuCol)
                  "
                >
                  <div
                    class="profile-media"
                    [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                    [class.row-reverse-single]="
                      mobView && checkReverseStyleSingle(menuCol)
                    "
                  >
                    <svg
                      class="icon-svg"
                      *ngIf="
                        iconCheck(menuCol?.button_style) ||
                        iconToggleCheck(menuCol?.button_style)
                      "
                      [ngStyle]="{
                        fill: menuCol?.button_icon_bgcolor
                      }"
                    >
                      <use
                        [attr.xlink:href]="
                          ' ./assets/svg/app-icons.svg#' + opt?.icon
                        "
                        xlink:href=""
                      ></use>
                    </svg>
                    <img
                      *ngIf="
                        imgCheck(menuCol?.button_style) ||
                        imgToggleCheck(menuCol?.button_style)
                      "
                      [src]="getButtonOptionImage(menuCol, opt?.id)"
                    />
                    <div class="option-data">
                      <div
                        class="option-title"
                        [ngStyle]="{
                          color: menuCol?.button_textcolor
                        }"
                      >
                        {{ opt?.label }}
                      </div>
                      <div
                        *ngIf="opt?.sublabel"
                        class="option-desc"
                        [ngStyle]="{
                          color: menuCol?.button_textcolor
                        }"
                      >
                        {{ opt?.sublabel }}
                      </div>
                    </div>
                  </div>
                  <input
                    *ngIf="menuCol?.button_form === menuCat.SINGLE_CHOICE"
                    type="radio"
                    class="option-radio"
                    [ngStyle]="{
                      'accent-color': menuCol?.button_option_color
                    }"
                    readonly
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  />

                  <input
                    *ngIf="
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      !checkForToggle(menuCol?.button_style)
                    "
                    type="checkbox"
                    class="option-radio"
                    [ngStyle]="{
                      'accent-color': menuCol?.button_option_color
                    }"
                    readonly
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  />
                  <label
                    [class.mob-toggel]="mobView"
                    class="switch-mobile-type"
                    *ngIf="
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      checkForToggle(menuCol?.button_style) &&
                      !ios
                    "
                  >
                    <input
                      type="checkbox"
                      [checked]="
                        getButtonOptionValue(menuCol, opt?.id) === 'true'
                      "
                      readonly
                    />

                    <div
                      [id]="generatToggalId(opt?.id)"
                      class="slider round "
                      [class.mob-toggel]="mobView"
                      [ngStyle]="{
                        'background-color': toggleActionColor(
                          menuCol?.button_option_color,
                          getButtonOptionValue(menuCol, opt?.id),
                          opt?.id
                        )
                      }"
                      class="slider round"
                    ></div>
                  </label>
                  <ui-switch
                    *ngIf="
                      mobView &&
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      checkForToggle(menuCol?.button_style) &&
                      ios
                    "
                    size="small"
                    disabled
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  ></ui-switch>
                </div>
              </ng-container>
              <div
                class="action-container"
                style="    font-size: 10px;"
                [class.ios-first-style]="
                  menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                  mobView &&
                  ios
                "
                *ngIf="menuCol.button_form === menuCat.SELECT_CHOICE"
                [class.divider]="
                  mobView &&
                  checkDividerStyle(menuCol) &&
                  menuCol?.button_option?.length != i + 1
                "
                [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                [class.row-reverse-single]="
                  mobView && checkReverseStyleSingle(menuCol)
                "
              >
                <div
                  *ngIf="menuCol.button_style !== rowStyles.SELECTCHOICE"
                  [class.dropdown-label]="
                    menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                    !mobView
                  "
                  [class.dropdown-label-mob-view]="
                    menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                    mobView &&
                    !ios
                  "
                  [class.dropdown-label-sec-mob-view]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                    mobView &&
                    !ios
                  "
                  [class.dropdown-label-sec]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                    !mobView
                  "
                  [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                  [class.row-reverse-single]="
                    mobView && checkReverseStyleSingle(menuCol)
                  "
                >
                  <div [class.ios-label-font]="mobView && ios">
                    <div>
                      {{ menuCol?.button_label }}
                    </div>
                  </div>
                </div>

                <select
                  *ngIf="
                    menuCol.button_style !== rowStyles.SELECTCHOICEFANCYTILE ||
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      mobView &&
                      ios)
                  "
                  class="onclick-select-menu-holder"
                  style="position:relative ;"
                  [class.dropdown-mobile-font]="
                    mobView && menuCol.button_form === menuCat.SELECT_CHOICE
                  "
                  [class.select-full-width]="
                    mobView &&
                    ios &&
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTIL
                  "
                  [ngStyle]="{
                    border: 'solid 1.5px' + systemConfg?.color?.primary
                  }"
                  disabled
                  style="cursor: not-allowed;"
                >
                  <ng-container
                    *ngFor="let opt of menuCol?.button_option; let i = index"
                  >
                    <option [selected]="getButtonOptionValue(menuCol, opt?.id)">
                      {{ opt?.label }}
                    </option>
                  </ng-container>
                </select>
                <select
                  *ngIf="
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      !mobView &&
                      !ios) ||
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      mobView &&
                      !ios)
                  "
                  [class.dropdown-mobile-font]="
                    mobView && menuCol.button_form === menuCat.SELECT_CHOICE
                  "
                  class="onclick-select-menu-holder"
                  style="position:relative ;   padding-top: 10px !important;"
                  [class.select-dropdown]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE
                  "
                  [ngStyle]="{
                    'border-bottom': 'solid 1.5px' + systemConfg?.color?.primary
                  }"
                  disabled
                  style="cursor: not-allowed;"
                >
                  <ng-container
                    *ngFor="let opt of menuCol?.button_option; let i = index"
                  >
                    <option [selected]="getButtonOptionValue(menuCol, opt?.id)">
                      {{ opt?.label }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div
            class="collection-img"
            *ngIf="
              menuCol?.button_style === rowStyles.SQUAREWITHNOBKGDNOTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
          </div>
          <div
            class="collection-img"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.SQUAREWITHNOBKGDTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img"
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHOUTTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
          </div>
          <div
            class="collection-half-img"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHBKGDNOTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img-bk"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              color: menuCol?.button_label_color
            }"
            [class.inside]="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHBKGD &&
              menuCol.button_form === menuCat.COLLECTION
            "
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHBKGD &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.FULLROWCIRCLE &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell-circle" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="center-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [class.active]="mobView"
            *ngIf="
              (menuCol?.button_style === rowStyles.CENTERIMG ||
                menuCol?.button_style === rowStyles.CENTERIMG_NEW) &&
              !hideTextOnlySec &&
              menuCol.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />

            <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->

            <img class="image" [src]="menuCol?.button_img_url" />
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>

          <div
            class="top-image"
            [class.active]="mobView"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.TOPIMG ||
              (menuCol?.button_style === rowStyles.TOPIMG_NEW &&
                !hideTextOnlySec &&
                menuCol?.button_form === menuCat.BUTTON)
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            /> -->
            <!-- <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->
            <div class="imag-seaction">
              <img class="image" [src]="menuCol?.button_img_url" />
            </div>
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="bck-image"
            [class.active]="mobView"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="getBck(menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.BGIMG ||
                menuCol?.button_style === rowStyles.WIDEBG ||
                menuCol?.button_style === rowStyles.WIDEBG_NEW) &&
              !hideTextOnlySec &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />
            <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="text-only"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.TEXTONLY ||
                menuCol?.button_style === rowStyles.TEXTONLY_NEW) &&
              !hideTextOnlySec &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />
            <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="button"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              ((menuCol?.button_style === rowStyles.BACK ||
                menuCol?.button_style === rowStyles.BACK_NEW) &&
                menuCol?.button_form === menuCat.BUTTON) ||
              bot
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />
            <img
              *ngIf="!mobView && !config && !bot"
              class="close-btn"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
          </div>
          <div
            class="wide-left-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.WIDELIFT ||
                menuCol?.button_style === rowStyles.WIDELIFT_NEW) &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />
            <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->
            <img class="image" [src]="menuCol?.button_img_url" />
            <div class="data-row">
              <span
                [ngStyle]="{
                  color: menuCol?.button_label_color
                }"
                >{{ menuCol?.button_label }}</span
              >
              <div
                class="desc"
                [class.active]="mobView"
                [ngStyle]="{
                  color: menuCol?.button_sublabel_color
                }"
              >
                {{ menuCol?.button_sublabel }}
              </div>
            </div>
          </div>
          <div
            class="small-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.SMALLHL ||
                menuCol?.button_style === rowStyles.SMALLHL_NEW) &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
              class="middle"
              src="./assets/img/setting-menu-btn.svg"
              *ngIf="!mobView && !config && !bot"
            />
            <img
              class="close-btn"
              *ngIf="!mobView && !config && !bot"
              src="./assets/img/menu-remove.svg"
              alt=""
              (click)="deleteButton({ row: i, col: j })"
            /> -->

            <img class="image" [src]="menuCol?.button_img_url" />
            <div class="data-row">
              <span
                [ngStyle]="{
                  color: menuCol?.button_label_color
                }"
                >{{ menuCol?.button_label }}</span
              >
              <div
                class="desc"
                [class.active]="mobView"
                [ngStyle]="{
                  color: menuCol?.button_sublabel_color
                }"
              >
                {{ menuCol?.button_sublabel }}
              </div>
            </div>
          </div>
          <div
            class="submit_icon"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              border: '1px solid' + menuCol?.button_bgcolor,
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITICONCOLOR &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <svg
              class="icon-svg"
              [ngStyle]="{
                fill: menuCol?.button_icon_bgcolor
              }"
            >
              <use
                [attr.xlink:href]="
                  ' ./assets/svg/app-icons.svg#' + menuCol?.button_icon
                "
                xlink:href=""
              ></use>
            </svg>
            <div>{{ menuCol?.button_label }}</div>
          </div>

          <div
            class="submit_cell"
            [ngStyle]="{
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMIT &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <div>{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="submit_icon_basic"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITBASICICON &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <svg
              class="icon-svg"
              [ngStyle]="{
                fill: menuCol?.button_icon_bgcolor
              }"
            >
              <use
                [attr.xlink:href]="
                  ' ./assets/svg/app-icons.svg#' + menuCol?.button_icon
                "
                xlink:href=""
              ></use>
            </svg>
            <div>{{ menuCol?.button_label }}</div>
          </div>

          <div
            class="submit_color_cell"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              border: '1px solid' + menuCol?.button_bgcolor,
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITCOLOR &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <div>{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="normal-label"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="{ border: 'solid 1.5px' + systemConfg?.color?.primary }"
            *ngIf="
              menuCol?.button_style === rowStyles.LABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="normal-label-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              ios &&
              mobView
            "
          >
            <div [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
          </div>

          <div
            class="input-with-label"
            [ngStyle]="{
              'border-bottom': 'solid 1.5px' + systemConfg?.color?.primary
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUTLABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div>Input text</div> -->
          </div>

          <div
            class="input-with-label-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUTLABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              (ios && mobView)
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div class="input-ios">Input text</div> -->
          </div>

          <div
            class="normal-input"
            [ngStyle]="{ border: 'solid 1.5px' + systemConfg?.color?.primary }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUT &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div>Input text</div> -->
          </div>

          <div
            class="normal-input-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUT &&
              menuCol?.button_form === menuCat.INPUT &&
              ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div class="input-ios">Input text</div> -->
          </div>

          <div
            class="payment-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.PAYMENT &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
          </div>

          <div
            class="cost-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.COST &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
          </div>
          <div
            class="info-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INFORMATION &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
          </div>
          <div
            class="empty"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor)
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTY &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          ></div>
          <div
            class="empty-with-title"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTYWITHTITLE &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-title-border"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTYWITHTITLEANDBOARDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-title-bottom"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              'border-bottom': '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style ===
                rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTITLE &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title-border"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTILEBORDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title-bottom"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              'border-bottom': '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTITLEBOTTOMBORDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="drag"
            *ngIf="
              config && !getTabContainer(menuCol)?.type && !menuCol.next_menu
            "
            (drop)="onDrop($event, menuCol.button_id)"
            ngxDroppable
            moves="true"
          >
            {{ getTabContainer(menuCol)?.type }}
            <div
              class="drag-feature"
              [class.active]="menuRows?.buttons.length >= 3"
            >
              <img src="./assets/img/drag.svg" />
              <div class="drag-intro">
                <span class="title-intro">Drop Module here</span>
                <span *ngIf="menuRows?.buttons.length <= 1"
                  >Drag and drop features to create your app's Tab.</span
                >
              </div>
            </div>
          </div>
          <div
            class="drag"
            *ngIf="config && menuCol.next_menu"
            (click)="subMenuSelect(menuCol.next_menu)"
          >
            <div
              class="next-menu"
              [class.active]="menuRows?.buttons.length >= 3"
            >
              <div class="title-title">Assigned to Next Menu:</div>
              <div class="feature-title">
                {{ getSubMenuTitle(menuCol.next_menu) }}
              </div>
            </div>
          </div>
          <div class="drag" *ngIf="config && getTabContainer(menuCol)?.type">
            <div>
              <div
                class="feature"
                (click)="showSetting(getTabContainer(menuCol))"
                [class.active]="menuRows?.buttons.length >= 3"
              >
                <div
                  class="container"
                  [class.active]="menuRows?.buttons.length >= 3"
                >
                  <div
                    class="tab-icon"
                    *ngIf="
                      menuRows?.buttons.length <= 1 &&
                      getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                    "
                  >
                    <svg class="icon-svg">
                      <use [attr.xlink:href]="selectItem" xlink:href=""></use>
                    </svg>
                    <!-- <img [src]="selectItem"/> -->
                  </div>
                  <div class="feature-title-container">
                    <div class="title-title">
                      Added Module:
                    </div>
                    <div class="feature-title">
                      {{
                        getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                          ?.title || getTabContainer(menuCol)?.title
                      }}
                    </div>
                  </div>
                  <div class="controller" *ngIf="menuRows?.buttons.length >= 3">
                    <app-delete-btn
                      (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                    ></app-delete-btn>
                  </div>
                </div>
                <div class="container">
                  <!-- <div
                  class="feature-count"
                  [class.active]="menuRows?.buttons.length >= 3"
                  *ngIf="
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                      ?.features?.length >= 1 && menuRows?.buttons.length < 3
                  "
                >
                  <div class="count-no">
                    {{ getTabContainer(menuCol)?.features_count }}/{{
                      getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                        ?.features?.length
                    }}
                  </div>
                  <div
                    *ngIf="
                      menuRows?.buttons.length != 2 &&
                      menuRows?.buttons.length != 3
                    "
                  >
                    Selected Features
                  </div>
                </div> -->
                  <!-- <div
                  class="feature-count"
                  [class.active]="menuRows?.buttons.length >= 3"
                  *ngIf="
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                      ?.features?.length <= 0 && menuRows?.buttons.length < 3
                  "
                >
                  <div class="count-no">1/1</div>
                  <div
                    *ngIf="
                      menuRows?.buttons.length != 2 &&
                      menuRows?.buttons.length != 3
                    "
                  >
                    Selected Features
                  </div>
                </div> -->
                  <div class="controller" *ngIf="menuRows?.buttons.length < 3">
                    <app-delete-btn
                      (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                    ></app-delete-btn>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="close-container clickable-cursor"
              (click)="deleteButton({ row: i, col: j })"
            >
              <img class="close-btn" src="./assets/img/close.svg" alt="" />
            </div>
          </div>
          <img
            class="middle"
            [class.sml]="menuRows.buttons.length >= 3"
            [class.mid]="menuRows.buttons.length >= 2"
            [class.long]="menuRows.buttons.length === 1"
            src="./assets/img/settings-menu-form.svg"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="!mobView && !config && !bot && menuCol?.button_style"
          />

          <img
            class="close-btn"
            [class.sml]="menuRows.buttons.length >= 3"
            [class.mid]="menuRows.buttons.length >= 2"
            [class.long]="menuRows.buttons.length === 1"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/delete-menu-form.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showSettings && !mainMenu">
    <div
      class="menu-holder-row"
      [class.active]="mobView"
      *ngFor="let menuRows of menus?.rows; let i = index"
    >
      <div *ngIf="showRemove" class="menu-actions">
        <!-- <div
        class="clickable-cursor menu-actions-icon delete"
        (click)="deleteRow(i)"
      >
        <i class="fas fa-trash-alt"></i>
      </div> -->
        <div
          *ngIf="
            menuRows.buttons[0].button_style !== rowStyles.SMALLHL_NEW &&
            menuRows.buttons[0].button_style !== rowStyles.WIDELIFT_NEW &&
            (menuRows.buttons[0].button_form !== menuCat.SINGLE_CHOICE &&
              menuRows.buttons[0].button_form !== menuCat.SELECT_CHOICE &&
              menuRows.buttons[0].button_form !== menuCat.MULTI_CHOICE)
          "
          class="clickable-cursor menu-actions-icon add"
        >
          <!-- <i class="fas fa-plus"></i> -->
          <img
            (click)="createButton(i)"
            src="./assets/img/add-menu-button.svg"
            *ngIf="menuRows.buttons.length < 3"
          />
          <img
            src="./assets/img/disabled-add-menu-button.svg"
            *ngIf="menuRows.buttons.length >= 3"
          />
        </div>

        <div
          *ngIf="
            menuRows.buttons[0].button_form === menuCat.SINGLE_CHOICE ||
            menuRows.buttons[0].button_form === menuCat.SELECT_CHOICE ||
            menuRows.buttons[0].button_form === menuCat.MULTI_CHOICE ||
            (menuRows.buttons[0].button_form === menuCat.BUTTON &&
              (menuRows.buttons[0].button_style === rowStyles.SMALLHL_NEW ||
                menuRows.buttons[0].button_style === rowStyles.WIDELIFT_NEW))
          "
          class="clickable-cursor menu-actions-icon add"
        >
          <!-- <i class="fas fa-plus"></i> -->

          <img src="./assets/img/disabled-add-menu-button.svg" />
        </div>
      </div>
      <div
        class="cell-default"
        [class.cell-box]="!mobView && !config && !bot"
        *ngFor="let menuCol of menuRows?.buttons; let j = index"
      >
        <div class="menu-holder-row-button" [ngStyle]="getButtonStyle(menuCol)">
          <div
            class="drag-box"
            *ngIf="!menuCol?.button_style && !hideTextOnlySec && !mobView"
            (drop)="addButtonStyle($event, i, j, menuCol?.button_id)"
          >
            <img src="./assets/img/drag.svg" />
            <div class="drag-title">
              Drop items here
            </div>
          </div>
          <div
            class="collection-img"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SQUAREWITHNOBKGDNOTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
          </div>
          <div
            class="collection-img"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SQUAREWITHNOBKGDTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHOUTTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
          </div>
          <div
            class="collection-half-img"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHBKGDNOTEXT &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img-bk"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.HALFROWSQUAREWITHBKGD &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="collection-half-img"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="{
              color: menuCol?.button_label_color
            }"
            *ngIf="
              menuCol?.button_style === rowStyles.FULLROWCIRCLE &&
              menuCol.button_form === menuCat.COLLECTION
            "
            style="   margin-right: -23px ;"
            [class.circle]="
              menuCol?.button_style === rowStyles.FULLROWCIRCLE &&
              menuCol.button_form === menuCat.COLLECTION
            "
          >
            <img class="image-cell-circle" [src]="menuCol?.button_img_url" />
            <div class="collection-img-desc">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="icon-choice"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [class.active]="mobView"
            [class.dropdown-mobile-font]="
              mobView && menuCol.button_form === menuCat.SELECT_CHOICE
            "
            [class.card]="mobView && checkCardStyle(menuCol)"
            *ngIf="
              !hideTextOnlySec &&
              (menuCol.button_form === menuCat.SINGLE_CHOICE ||
                menuCol.button_form === menuCat.SELECT_CHOICE ||
                menuCol.button_form === menuCat.MULTI_CHOICE)
            "
          >
            <div
              class="seaction"
              [class.in-conf]="!mobView"
              [class.has-img]="imgCheck(menuCol?.button_style)"
              [class.select-choice]="
                menuCol.button_form === menuCat.SELECT_CHOICE
              "
            >
              <ng-container
                *ngIf="menuCol.button_form !== menuCat.SELECT_CHOICE"
              >
                <div
                  class="action-container"
                  style="    font-size: 10px;"
                  *ngFor="let opt of menuCol?.button_option; let i = index"
                  [class.divider]="
                    mobView &&
                    checkDividerStyle(menuCol) &&
                    menuCol?.button_option?.length != i + 1
                  "
                  [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                  [class.row-reverse-single]="
                    mobView && checkReverseStyleSingle(menuCol)
                  "
                >
                  <div
                    class="profile-media"
                    [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                    [class.row-reverse-single]="
                      mobView && checkReverseStyleSingle(menuCol)
                    "
                  >
                    <svg
                      class="icon-svg"
                      *ngIf="
                        iconCheck(menuCol?.button_style) ||
                        iconToggleCheck(menuCol?.button_style)
                      "
                      [ngStyle]="{
                        fill: menuCol?.button_icon_bgcolor
                      }"
                    >
                      <use
                        [attr.xlink:href]="
                          ' ./assets/svg/app-icons.svg#' + opt?.icon
                        "
                        xlink:href=""
                      ></use>
                    </svg>
                    <img
                      *ngIf="
                        imgCheck(menuCol?.button_style) ||
                        imgToggleCheck(menuCol?.button_style)
                      "
                      [src]="getButtonOptionImage(menuCol, opt?.id)"
                    />
                    <div class="option-data">
                      <div
                        class="option-title"
                        [ngStyle]="{
                          color: menuCol?.button_label_color
                        }"
                      >
                        {{ opt?.label }}
                      </div>
                      <div
                        class="option-desc"
                        *ngIf="opt?.sublabel"
                        [ngStyle]="{
                          color: menuCol?.button_sublabel_color
                        }"
                      >
                        {{ opt?.sublabel }}
                      </div>
                    </div>
                  </div>
                  <input
                    *ngIf="menuCol?.button_form === menuCat.SINGLE_CHOICE"
                    type="radio"
                    [ngStyle]="{
                      'accent-color': menuCol?.button_option_color
                    }"
                    class="option-radio"
                    readonly
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  />

                  <input
                    *ngIf="
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      !checkForToggle(menuCol?.button_style)
                    "
                    type="checkbox"
                    class="option-radio"
                    [ngStyle]="{
                      'accent-color': menuCol?.button_option_color
                    }"
                    readonly
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  />
                  <label
                    class="switch-mobile-type"
                    [class.mob-toggel]="mobView"
                    *ngIf="
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      checkForToggle(menuCol?.button_style) &&
                      !ios
                    "
                  >
                    <input
                      type="checkbox"
                      [checked]="
                        getButtonOptionValue(menuCol, opt?.id) === 'true'
                      "
                      readonly
                    />

                    <div
                      [id]="generatToggalId(opt?.id)"
                      [class.mob-toggel]="mobView"
                      class="slider round "
                      [ngStyle]="{
                        'background-color': toggleActionColor(
                          menuCol?.button_option_color,
                          getButtonOptionValue(menuCol, opt?.id),
                          opt?.id
                        )
                      }"
                      class="slider round"
                    ></div>
                  </label>
                  <ui-switch
                    *ngIf="
                      mobView &&
                      menuCol?.button_form === menuCat.MULTI_CHOICE &&
                      checkForToggle(menuCol?.button_style) &&
                      ios
                    "
                    disabled
                    size="small"
                    [checked]="
                      getButtonOptionValue(menuCol, opt?.id) === 'true'
                    "
                  ></ui-switch>
                </div>
              </ng-container>

              <div
                class="action-container"
                [class.ios-first-style]="
                  menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                  mobView &&
                  ios
                "
                style="    font-size: 10px;"
                *ngIf="menuCol.button_form === menuCat.SELECT_CHOICE"
                [class.divider]="
                  mobView &&
                  checkDividerStyle(menuCol) &&
                  menuCol?.button_option?.length != i + 1
                "
                [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                [class.row-reverse-single]="
                  mobView && checkReverseStyleSingle(menuCol)
                "
              >
                <div
                  *ngIf="menuCol.button_style !== rowStyles.SELECTCHOICE"
                  [class.dropdown-label]="
                    menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                    !mobView
                  "
                  [class.mobile-screen-view]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                    mobView &&
                    !ios
                  "
                  [class.dropdown-label-mob-view]="
                    menuCol.button_style === rowStyles.SELECTCHOICETITLE &&
                    mobView &&
                    !ios
                  "
                  [class.dropdown-label-sec]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                    !mobView
                  "
                  [class.row-reverse]="mobView && checkReverseStyle(menuCol)"
                  [class.row-reverse-single]="
                    mobView && checkReverseStyleSingle(menuCol)
                  "
                >
                  <div [class.ios-label-font]="mobView && ios">
                    <div>
                      {{ menuCol?.button_label }}
                    </div>
                  </div>
                </div>

                <select
                  *ngIf="
                    menuCol.button_style !== rowStyles.SELECTCHOICEFANCYTILE ||
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      mobView &&
                      ios)
                  "
                  class="onclick-select-menu-holder"
                  style="position:relative ;"
                  [class.select-full-width]="
                    mobView &&
                    ios &&
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE
                  "
                  [ngStyle]="{
                    border: 'solid 1.5px' + systemConfg?.color?.primary
                  }"
                  disabled
                  style="cursor: not-allowed;"
                  [class.dropdown-mobile-font]="
                    mobView && menuCol.button_form === menuCat.SELECT_CHOICE
                  "
                >
                  <ng-container
                    *ngFor="let opt of menuCol?.button_option; let i = index"
                  >
                    <option [selected]="getButtonOptionValue(menuCol, opt?.id)">
                      {{ opt?.label }}
                    </option>
                  </ng-container>
                </select>
                <select
                  *ngIf="
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      !mobView &&
                      !ios) ||
                    (menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE &&
                      mobView &&
                      !ios)
                  "
                  [class.dropdown-mobile-font]="
                    mobView && menuCol.button_form === menuCat.SELECT_CHOICE
                  "
                  class="onclick-select-menu-holder"
                  style="position:relative ;   padding-top: 10px !important;"
                  [class.select-dropdown]="
                    menuCol.button_style === rowStyles.SELECTCHOICEFANCYTILE
                  "
                  [ngStyle]="{
                    'border-bottom': 'solid 1.5px' + systemConfg?.color?.primary
                  }"
                  disabled
                  style="cursor: not-allowed;"
                >
                  <ng-container
                    *ngFor="let opt of menuCol?.button_option; let i = index"
                  >
                    <option [selected]="getButtonOptionValue(menuCol, opt?.id)">
                      {{ opt?.label }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>

          <div
            class="center-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [class.active]="mobView"
            *ngIf="
              (menuCol?.button_style === rowStyles.CENTERIMG ||
                menuCol?.button_style === rowStyles.CENTERIMG_NEW) &&
              !hideTextOnlySec &&
              menuCol.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />

          <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->

            <img class="image" [src]="menuCol?.button_img_url" />
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>

          <div
            class="top-image"
            [class.active]="mobView"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.TOPIMG ||
              (menuCol?.button_style === rowStyles.TOPIMG_NEW &&
                !hideTextOnlySec &&
                menuCol?.button_form === menuCat.BUTTON)
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          /> -->
            <!-- <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->
            <div class="imag-seaction">
              <img class="image" [src]="menuCol?.button_img_url" />
            </div>
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="bck-image"
            [class.active]="mobView"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="getBck(menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.BGIMG ||
                menuCol?.button_style === rowStyles.WIDEBG ||
                menuCol?.button_style === rowStyles.WIDEBG_NEW) &&
              !hideTextOnlySec &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />
          <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="text-only"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.TEXTONLY ||
                menuCol?.button_style === rowStyles.TEXTONLY_NEW) &&
              !hideTextOnlySec &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />
          <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
            <div
              class="desc"
              [class.active]="mobView"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
          </div>
          <div
            class="button"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              ((menuCol?.button_style === rowStyles.BACK ||
                menuCol?.button_style === rowStyles.BACK_NEW) &&
                menuCol?.button_form === menuCat.BUTTON) ||
              bot
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />
          <img
            *ngIf="!mobView && !config && !bot"
            class="close-btn"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->
            <span
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
              >{{ menuCol?.button_label }}</span
            >
          </div>
          <div
            class="wide-left-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.WIDELIFT ||
                menuCol?.button_style === rowStyles.WIDELIFT_NEW) &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />
          <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->
            <img class="image" [src]="menuCol?.button_img_url" />
            <div class="data-row">
              <span
                [ngStyle]="{
                  color: menuCol?.button_label_color
                }"
                >{{ menuCol?.button_label }}</span
              >
              <div
                class="desc"
                [class.active]="mobView"
                [ngStyle]="{
                  color: menuCol?.button_sublabel_color
                }"
              >
                {{ menuCol?.button_sublabel }}
              </div>
            </div>
          </div>
          <div
            class="small-image"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              (menuCol?.button_style === rowStyles.SMALLHL ||
                menuCol?.button_style === rowStyles.SMALLHL_NEW) &&
              menuCol?.button_form === menuCat.BUTTON
            "
          >
            <!-- <img
            class="middle"
            src="./assets/img/setting-menu-btn.svg"
            *ngIf="!mobView && !config && !bot"
          />
          <img
            class="close-btn"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/menu-remove.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          /> -->

            <img class="image" [src]="menuCol?.button_img_url" />
            <div class="data-row">
              <span
                [ngStyle]="{
                  color: menuCol?.button_label_color
                }"
                >{{ menuCol?.button_label }}</span
              >
              <div
                class="desc"
                [class.active]="mobView"
                [ngStyle]="{
                  color: menuCol?.button_sublabel_color
                }"
              >
                {{ menuCol?.button_sublabel }}
              </div>
            </div>
          </div>
          <div
            class="submit_icon"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              border: '1px solid' + menuCol?.button_bgcolor,
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITICONCOLOR &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <svg
              class="icon-svg"
              [ngStyle]="{
                fill: menuCol?.button_icon_bgcolor
              }"
            >
              <use
                [attr.xlink:href]="
                  ' ./assets/svg/app-icons.svg#' + menuCol?.button_icon
                "
                xlink:href=""
              ></use>
            </svg>
            <div>{{ menuCol?.button_label }}</div>
          </div>

          <div
            class="submit_cell"
            [ngStyle]="{
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMIT &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <div>{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="submit_icon_basic"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              color: menuCol?.button_label_color,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITBASICICON &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <svg
              class="icon-svg"
              [ngStyle]="{
                fill: menuCol?.button_icon_bgcolor
              }"
            >
              <use
                [attr.xlink:href]="
                  ' ./assets/svg/app-icons.svg#' + menuCol?.button_icon
                "
                xlink:href=""
              ></use>
            </svg>
            <div>{{ menuCol?.button_label }}</div>
          </div>

          <div
            class="submit_color_cell"
            [ngStyle]="{
              'background-color': menuCol?.button_bgcolor,
              border: '1px solid' + menuCol?.button_bgcolor,
              color: +menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.SUBMITCOLOR &&
              menuCol?.button_form === menuCat.SUBMIT
            "
          >
            <div>{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="normal-label"
            (click)="readButton({ row: i, col: j }, menuCol)"
            [ngStyle]="{ border: 'solid 1.5px' + systemConfg?.color?.primary }"
            *ngIf="
              menuCol?.button_style === rowStyles.LABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
          </div>
          <div
            class="normal-label-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              ios &&
              mobView
            "
          >
            <div [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
          </div>

          <div
            class="input-with-label"
            [ngStyle]="{
              'border-bottom': 'solid 1.5px' + systemConfg?.color?.primary
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUTLABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div>Input text</div> -->
          </div>

          <div
            class="input-with-label-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUTLABEL &&
              menuCol?.button_form === menuCat.INPUT &&
              (ios && mobView)
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div class="input-ios">Input text</div> -->
          </div>

          <div
            class="normal-input"
            [ngStyle]="{ border: 'solid 1.5px' + systemConfg?.color?.primary }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUT &&
              menuCol?.button_form === menuCat.INPUT &&
              !ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div>Input text</div> -->
          </div>

          <div
            class="normal-input-ios"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INPUT &&
              menuCol?.button_form === menuCat.INPUT &&
              ios
            "
          >
            <div class="label" [ngStyle]="{ color: menuCol?.button_textcolor }">
              {{ menuCol?.button_label }}
            </div>
            <!-- <div class="input-ios">Input text</div> -->
          </div>

          <div
            class="payment-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.PAYMENT &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
          </div>

          <div
            class="cost-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.COST &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
          </div>
          <div
            class="info-cell"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.INFORMATION &&
              menuCol?.button_form === menuCat.OUTPUT
            "
          >
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_label_color
              }"
            >
              {{ menuCol?.button_label }}
            </div>
            <div
              class="label"
              [ngStyle]="{
                color: menuCol?.button_sublabel_color
              }"
            >
              {{ menuCol?.button_sublabel }}
            </div>
            <div
              class="label-price"
              [ngStyle]="{
                color: menuCol?.button_textcolor
              }"
            >
              {{
                (menuCol?.button_value)[0]?.value
                  ? (menuCol?.button_value)[0]?.value
                  : '20$'
              }}
            </div>
          </div>
          <div
            class="empty"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor)
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTY &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          ></div>
          <div
            class="empty-with-title"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTYWITHTITLE &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-title-border"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.v,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.EMPTYWITHTITLEANDBOARDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-title-bottom"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              'border-bottom': '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style ===
                rowStyles.EMPTYWITHTITLEANDBOTTOMBOARDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTITLE &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title-border"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.v,
              border: '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTILEBORDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="empty-with-left-title-bottom"
            [ngStyle]="{
              'background-color': getBtnBg(menuCol?.button_bgcolor),
              color: menuCol?.button_label_color,
              'border-bottom': '1px solid' + menuCol?.button_border_color
            }"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="
              menuCol?.button_style === rowStyles.LEFTTITLEBOTTOMBORDER &&
              menuCol?.button_form === menuCat.SEPARATOR
            "
          >
            <div class="label">{{ menuCol?.button_label }}</div>
          </div>
          <div
            class="drag"
            *ngIf="
              config && !getTabContainer(menuCol)?.type && !menuCol.next_menu
            "
            (drop)="onDrop($event, menuCol.button_id)"
            ngxDroppable
            moves="true"
          >
            {{ getTabContainer(menuCol)?.type }}
            <div
              class="drag-feature"
              [class.active]="menuRows?.buttons.length >= 3"
            >
              <img src="./assets/img/drag.svg" />
              <div class="drag-intro">
                <span class="title-intro">Drop Module here</span>
                <span *ngIf="menuRows?.buttons.length <= 1"
                  >Drag and drop features to create your app's Tab.</span
                >
              </div>
            </div>
          </div>
          <div
            class="drag"
            *ngIf="config && menuCol.next_menu"
            (click)="subMenuSelect(menuCol.next_menu)"
          >
            <div
              class="next-menu"
              [class.active]="menuRows?.buttons.length >= 3"
            >
              <div class="title-title">Assigned to Next Menu:</div>
              <div class="feature-title">
                {{ getSubMenuTitle(menuCol.next_menu) }}
              </div>
            </div>
          </div>
          <div class="drag" *ngIf="config && getTabContainer(menuCol)?.type">
            <div>
              <div
                class="feature"
                (click)="showSetting(getTabContainer(menuCol))"
                [class.active]="menuRows?.buttons.length >= 3"
              >
                <div
                  class="container"
                  [class.active]="menuRows?.buttons.length >= 3"
                >
                  <div
                    class="tab-icon"
                    *ngIf="
                      menuRows?.buttons.length <= 1 &&
                      getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                    "
                  >
                    <svg class="icon-svg">
                      <use [attr.xlink:href]="selectItem" xlink:href=""></use>
                    </svg>
                    <!-- <img [src]="selectItem"/> -->
                  </div>
                  <div class="feature-title-container">
                    <div class="title-title">
                      Added Module:
                    </div>
                    <div class="feature-title">
                      {{
                        getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                          ?.title || getTabContainer(menuCol)?.title
                      }}
                    </div>
                  </div>
                  <div class="controller" *ngIf="menuRows?.buttons.length >= 3">
                    <app-delete-btn
                      (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                    ></app-delete-btn>
                  </div>
                </div>
                <div class="container">
                  <!-- <div
                class="feature-count"
                [class.active]="menuRows?.buttons.length >= 3"
                *ngIf="
                  getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                    ?.features?.length >= 1 && menuRows?.buttons.length < 3
                "
              >
                <div class="count-no">
                  {{ getTabContainer(menuCol)?.features_count }}/{{
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                      ?.features?.length
                  }}
                </div>
                <div
                  *ngIf="
                    menuRows?.buttons.length != 2 &&
                    menuRows?.buttons.length != 3
                  "
                >
                  Selected Features
                </div>
              </div> -->
                  <!-- <div
                class="feature-count"
                [class.active]="menuRows?.buttons.length >= 3"
                *ngIf="
                  getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                    ?.features?.length <= 0 && menuRows?.buttons.length < 3
                "
              >
                <div class="count-no">1/1</div>
                <div
                  *ngIf="
                    menuRows?.buttons.length != 2 &&
                    menuRows?.buttons.length != 3
                  "
                >
                  Selected Features
                </div>
              </div> -->
                  <div class="controller" *ngIf="menuRows?.buttons.length < 3">
                    <app-delete-btn
                      (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                    ></app-delete-btn>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="close-container clickable-cursor"
              (click)="deleteButton({ row: i, col: j })"
            >
              <img class="close-btn" src="./assets/img/close.svg" alt="" />
            </div>
          </div>
          <img
            class="middle"
            [class.sml]="menuRows.buttons.length >= 3"
            [class.mid]="menuRows.buttons.length >= 2"
            [class.long]="menuRows.buttons.length === 1"
            src="./assets/img/settings-menu-form.svg"
            (click)="readButton({ row: i, col: j }, menuCol)"
            *ngIf="!mobView && !config && !bot && menuCol?.button_style"
          />

          <img
            class="close-btn"
            [class.sml]="menuRows.buttons.length >= 3"
            [class.mid]="menuRows.buttons.length >= 2"
            [class.long]="menuRows.buttons.length === 1"
            *ngIf="!mobView && !config && !bot"
            src="./assets/img/delete-menu-form.svg"
            alt=""
            (click)="deleteButton({ row: i, col: j })"
          />
        </div>
      </div>
    </div>
  </ng-container>
  <div class="drag" *ngIf="showSettings">
    <div>
      <div class="feature">
        <div class="container">
          <div class="tab-icon">
            <svg class="tab-icon-svg">
              <use [attr.xlink:href]="selectItem2" xlink:href=""></use>
            </svg>
            <!-- <img [src]="selectItem"/> -->
          </div>
          <div class="feature-title-container">
            <div class="title-title">
              Added Module:
            </div>
            <div class="feature-title">
              {{ selectedFeature?.title }}
            </div>
          </div>
        </div>
        <div class="container">
          <!-- <div class="feature-count">
            <div class="count-no">
              {{ selectedFeature?.features?.length }}
            </div>
            <div>
              Selected Features
            </div>
          </div> -->
          <!-- <div
            class="feature-count"
            *ngIf="selectedFeature?.features?.length <= 0"
          >
            <div class="count-no">1/1</div>
            <div>
              Selected Features
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
