import { Store } from '@ngrx/store';
import * as VirtualAppUIActions from './virtualAppsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class VirtualAppUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listVirtualAppUI() {
        this._store.dispatch(new VirtualAppUIActions.ListVirtualAppUI());
    }
    createVirtualAppUI() {
        this._store.dispatch(new VirtualAppUIActions.CreateVirtualAppUI());
    }
    viewVirtualAppUI(selectedVirtualApp) {
        this._store.dispatch(new VirtualAppUIActions.ViewVirtualAppUI(selectedVirtualApp));
    }
    editVirtualAppUI(selectedVirtualApp) {
        this._store.dispatch(new VirtualAppUIActions.EditVirtualAppUI(selectedVirtualApp));
    }
    uploadVirtualAppImage(selectedFile) {
        this._store.dispatch(new VirtualAppUIActions.UploadVirtualAppImage(selectedFile));
    }
    virtualAppPhotoUploadUI(createdVirtualApp) {
        this._store.dispatch(new VirtualAppUIActions.VirtualAppPhotoUploadUI(createdVirtualApp));
    }
    virtualAppCreationComplete() {
        this._store.dispatch(new VirtualAppUIActions.VirtualAppCreationComplete());
    }
    closeViewVirtualAppUI() {
        this._store.dispatch(new VirtualAppUIActions.CloseViewVirtualAppUI());
    }
    closeEditVirtualAppUI() {
        this._store.dispatch(new VirtualAppUIActions.CloseEditVirtualAppUI());
    }
    // paging dispatchers
    getNextVirtualAppPage() {
        this._store.dispatch(new VirtualAppUIActions.GetNextVirtualAppPage());
    }
    getPrevVirtualAppPage() {
        this._store.dispatch(new VirtualAppUIActions.GetPrevVirtualAppPage());
    }
    receivedVirtualAppHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new VirtualAppUIActions.ReceivedVirtualAppHistoryChunk(eop, sop, currentPage));
    }
    resetVirtualAppPaging() {
        this._store.dispatch(new VirtualAppUIActions.ResetVirtualAppPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new VirtualAppUIActions.SetStatusAsIdle());
    }
}
VirtualAppUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VirtualAppUiDispatchers_Factory() { return new VirtualAppUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: VirtualAppUiDispatchers, providedIn: "root" });
