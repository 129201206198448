import { Action } from '@ngrx/store/public_api';
import {
  AppJSON,
  AppPublishInfo,
  AppStoreJSON
} from 'src/models/AppPublishInfo';

export const enum AppPublishingActionTypes {
  REQUEST_APP_STORE_INFO = 'REQUEST_APP_STORE_INFO',
  SET_APP_STORE_INFO = 'SET_APP_STORE_INFO',
  SET_APPLE_STORE_INFO = 'SET_APPLE_STORE_INFO',
  SET_APP_INFO = 'SET_APP_INFO',
  RECEIVE_APP_STORE_INFO = 'RECEIVE_APP_STORE_INFO',
  RECEIVE_APPS_SETUPS = 'RECEIVE_APPS_SETUPS',
  REQUEST_APP_TESTERS = 'REQUEST_APP_TESTERS',
  RECEIVE_PUBLISH_INFO_ERROR = 'RECEIVE_PUBLISH_INFO_ERROR',
  RECEIVE_ERROR_CODE = 'RECEIVE_ERROR_CODE',
  RECEIVE_APP_SETUP_INFO = 'RECEIVE_APP_SETUP_INFO',
  GET_APPS_SETUP_INFO = 'GET_APPS_SETUP_INFO',
  RESET = 'RESET'
}

export class SetAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.SET_APP_STORE_INFO;
  constructor(public appPublishInfo: AppPublishInfo) {}
}

export class SetAppleStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.SET_APPLE_STORE_INFO;
  constructor(
    public os: number,
    public appStoreInfo: AppStoreJSON,
    public setCompanyName?: boolean
  ) {}
}

export class SetAppInfo implements Action {
  readonly type = AppPublishingActionTypes.SET_APP_INFO;
  constructor(public os: number, public appInfo: AppJSON) {}
}
export class ReceiveAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_APP_STORE_INFO;
  constructor(public appPublishInfo: AppPublishInfo) {}
}
export class ReceiveAppsSetups implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_APPS_SETUPS;
  constructor(public appPublishInfo: AppJSON[]) {}
}

export class RequestAppTesters implements Action {
  readonly type = AppPublishingActionTypes.REQUEST_APP_TESTERS;
  constructor() {}
}

export class ReceivePublishInfoError implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_PUBLISH_INFO_ERROR;
  constructor(public errorNumber: number) {}
}

export class ReceiveErrorCode implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_ERROR_CODE;
  constructor(public errorNumber: number) {}
}

export class RequestAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.REQUEST_APP_STORE_INFO;
  constructor(public appID: string) {}
}

export class ReceiveAppSetupInfo implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_APP_SETUP_INFO;
  constructor(public app: AppJSON) {}
}
export class GetAppsSetupInfo implements Action {
  readonly type = AppPublishingActionTypes.GET_APPS_SETUP_INFO;
}

export class Reset implements Action {
  readonly type = AppPublishingActionTypes.RESET;
}

export type Actions =
  | SetAppStoreInfo
  | ReceiveAppStoreInfo
  | RequestAppTesters
  | ReceivePublishInfoError
  | ReceiveAppsSetups
  | ReceiveErrorCode
  | RequestAppStoreInfo
  | ReceiveAppSetupInfo
  | SetAppleStoreInfo
  | SetAppInfo
  | Reset;
