import {
  GET_MAP_ROUTE_METHOD,
  GET_MAP_ROUTES_METHOD,
  CREATE_MAP_ROUTE_METHOD,
  REMOVE_MAP_ROUTE_METHOD,
  SET_MAP_ROUTE_METHOD,
  CREATE_MAP_ROUTE_REF,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE
} from './constants';

export interface MapRoute {
  id?: string; // The key
  map_id?: string; // The map_id is set from server side only .. no need to send it
  short_name?: string;
  long_name?: string;
  route_id?: string;
}

export interface MapRouteUI {
  currentPage?: MapRoute[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  currentMapRouteScreen?: string;
}

export class GetMapRoute {
  readonly method = GET_MAP_ROUTE_METHOD;
  constructor(public route_id: string) {}
}

export class GetMapRoutes {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_ROUTES_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public hash?: string
  ) {}
}

export class RemoveMapRoute {
  readonly method = REMOVE_MAP_ROUTE_METHOD;
  constructor(public id: string) {}
}

export class CreateMapRoute {
  readonly method = CREATE_MAP_ROUTE_METHOD;
  readonly reference = CREATE_MAP_ROUTE_REF;
  constructor(mapRoute: MapRoute) {
    for (const key in mapRoute) {
      if (mapRoute.hasOwnProperty(key)) {
        this[key] = mapRoute[key];
      }
    }
  }
}

export class SetMapRoute {
  readonly method = SET_MAP_ROUTE_METHOD;
  constructor(mapRoute: MapRoute) {
    for (const key in mapRoute) {
      if (mapRoute.hasOwnProperty(key)) {
        this[key] = mapRoute[key];
      }
    }
  }
}
