<ng-container *ngIf="!showLoginScreen">
  <div class="change-password">
    <div class="change-password-header">
      Reset Your Password
    </div>
    <div class="change-password-title">
      Please enter the reset code we sent to your email
      {{ accountEmail }}
    </div>

    <form
      form-body
      class="change-password-form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <!-- TAC Filed -->
      <div class="faild">
        <!-- <mat-form-field> -->
        <label for="Code">Code</label>
        <input
          placeholder="Reset Code"
          type="text"
          formControlName="tac"
          autocomplete="off"
          cdkAutofill="off"
          maxlength="6"
        />
        <!-- </mat-form-field> -->
        <div style="min-height: 25px;">
          <app-validation-errors
            class="change-password-error"
            *ngIf="tac?.errors && tac?.touched"
            [fieldName]="'Reset Code'"
            [fieldErrors]="tac?.errors"
          >
          </app-validation-errors>
        </div>
      </div>

      <!-- New Password Filed -->
      <div class="faild">
        <!-- <mat-form-field> -->
        <label for="Password">New Password</label>
        <input
          placeholder="Enter New Password"
          type="password"
          formControlName="newPassword"
          autocomplete="new-password"
        />
        <!-- </mat-form-field> -->
        <div style="min-height: 25px;">
          <app-validation-errors
            class="change-password-error"
            *ngIf="newPassword?.errors && newPassword?.touched"
            [fieldName]="'Password'"
            [fieldErrors]="newPassword?.errors"
          >
          </app-validation-errors>
        </div>
      </div>

      <div class="faild">
        <!-- <mat-form-field> -->
        <label for="Password">Retype New Password</label>
        <input
          placeholder="Retype New Password"
          type="password"
          formControlName="confirmNewPassword"
          autocomplete="off"
          cdkAutofill="off"
        />
        <!-- </mat-form-field> -->
        <div style="min-height: 25px;">
          <app-validation-errors
            class="change-password-error"
            *ngIf="confirmNewPassword?.errors && confirmNewPassword?.touched"
            [fieldName]="'Password'"
            [fieldErrors]="confirmNewPassword?.errors"
          >
          </app-validation-errors>
        </div>
      </div>

      <!-- <div class="form-submit">
        <app-border-btn next invert lg [type]="'submit'">
          Reset Password <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
      </div> -->
      <button type="submit" class="submit-btn">Reset Password</button>
    </form>
    <div class="change-password-terms">
      Didn’t receive the rest code?&nbsp;
      <app-link-btn (btnClicked)="reSendCode()" *ngIf="!hideResendCode">
        <ng-container ngProjectAs="link-content-clicked">
          Resend Code
        </ng-container>
      </app-link-btn>
      <div id="countdown" style="color: #1096eb;"></div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showLoginScreen">
  <app-login [newEmail]="accountEmail"></app-login>
</ng-container>
