import { Actions } from './billingUI.actions';
import * as actions from './billingUI.actions';
import { BillingUI } from 'src/models/billings';
import { BillingsPageScreens } from 'src/models/constants';

const INITIAL_STATE: BillingUI = {
  selectedPLan: '',
  currentScreen: BillingsPageScreens.BILLINGS_DETAILS_SCREEN,
  currentPlanDetails: '',
  invoicesList: [],
  errorMessage: '',
  status: '',
  lastScreen: '',
  billingPlanStatus: true,
  subscriptions: [],
  mainPlan: {},
  coupon: null,
  cancel_end: null,
  trialEndDate: null,
  expire: false
};

export function billingUiReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.BillingUIActionTypes.RECEIVED_SELECETED_PLAN: {
      return {
        ...state,
        selectedPLan: action.planID,
        currentScreen:
          action.planID === 'plan_4' || !action.planID
            ? BillingsPageScreens.BILLINGS_PLANS_SCREEN
            : BillingsPageScreens.BILLINGS_DETAILS_SCREEN,
        status: '',
        cancel_end: action.cancelEnd ? action.cancelEnd : null,
        billingPlanStatus:
          action.planID === 'plan_1000' ||
          action.planID === 'plan_2000' ||
          action.planID === 'plan_3000'
            ? false
            : true
      };
    }
    case actions.BillingUIActionTypes.SET_CURRENT_SCREEN_UI: {
      return {
        ...state,
        currentScreen: action.screen,
        coupon: null,
        lastScreen: action.lastScreen
      };
    }
    case actions.BillingUIActionTypes.SET_CURRENT_SELECTED_PLAN_UI: {
      return {
        ...state,
        currentScreen: BillingsPageScreens.BILLINGS_FORM_SCREEN,
        currentPlanDetails: { ...action.plan }
      };
    }
    case actions.BillingUIActionTypes.RECEVE_INVOICES_LIST_UI: {
      return { ...state, invoicesList: [...action.list] };
    }
    case actions.BillingUIActionTypes.RECEVE_STRIPE_ERROR_MESAGE_UI: {
      return { ...state, errorMessage: action.msg };
    }
    case actions.BillingUIActionTypes.SET_BILLING_STATUS_UI: {
      return { ...state, status: action.status };
    }
    case actions.BillingUIActionTypes.RECEVE_COUPON_RESPONSE_UI: {
      return { ...state, coupon: { ...action.coupon } };
    }
    case actions.BillingUIActionTypes.RECEVE_BILLING_INFO_RESPONSE_UI: {
      let arrWithoutMainPlan = [];
      let sort = [];
      if (
        action.data &&
        action.data.data.subscription &&
        action.data.data.subscription.length > 0
      ) {
        action.data.data.subscription.map(res =>
          res.sub_main === 'true'
            ? sort.push({
                ...res.item.find(item => item.main_plan === 'true'),
                current_period_start: res.current_period_start,
                current_period_end: res.current_period_end
              })
            : arrWithoutMainPlan.push({
                ...res.item.find(item => !item.main_plan),
                current_period_start: res.current_period_start,
                current_period_end: res.current_period_end
              })
        );
      }
      const mainPlan = sort[0];
      return {
        ...state,
        // status: '',
        billingInfo: { ...state.billingInfo, ...action.data },
        mainPlan: {
          ...state.mainPlan,
          ...mainPlan
        },
        selectedPLan: mainPlan.plan_id,
        subscriptions: [...sort, ...arrWithoutMainPlan],
        billingPlanStatus:
          mainPlan && mainPlan.period && mainPlan.period === 'year'
            ? true
            : false
      };
    }
    case actions.BillingUIActionTypes.SET_BILLING_PERIOD_STATUS_UI: {
      return {
        ...state,
        billingPlanStatus: action.status
      };
    }
    case actions.BillingUIActionTypes.RECEVE_END_FREE_TRILE_DATE_UI: {
      return {
        ...state,
        trialEndDate: action.date
      };
    }
    case actions.BillingUIActionTypes.RECEVE_IS_MAIN_PLAN_EXPIRE_UI: {
      return {
        ...state,
        expire: action.status
      };
    }
    case actions.BillingUIActionTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
