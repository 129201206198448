import { Store } from '@ngrx/store';
import * as actions from './appMenu.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppMenuDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getAppMenus(menuType) {
        this._store.dispatch(new actions.GetAppMenus(menuType));
    }
    receiveAppMenus(appMenus) {
        this._store.dispatch(new actions.ReceiveAppMenus(appMenus));
    }
    appMenuDetailsReceived(appMenu) {
        this._store.dispatch(new actions.AppMenuDetailsReceived(appMenu));
    }
}
AppMenuDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppMenuDispatchers_Factory() { return new AppMenuDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppMenuDispatchers, providedIn: "root" });
