import { Action } from '@ngrx/store';
import { MapRoute } from 'src/models/MapRoute';

export const enum MapRouteActionTypes {
  RESET_MAP_ROUTES_PAGING = 'RESET_MAP_ROUTES_PAGING',
  GET_NEXT_MAP_ROUTES_PAGE = 'GET_NEXT_MAP_ROUTES_PAGE',
  GET_PREV_MAP_ROUTES_PAGE = 'GET_PREV_MAP_ROUTES_PAGE',
  GET_MAP_ROUTE_BY_ID = 'GET_MAP_ROUTE_BY_ID',
  CREATE_MAP_ROUTE = 'CREATE_MAP_ROUTE',
  MAP_ROUTE_CREATED = 'MAP_ROUTE_CREATED',
  UPDATE_MAP_ROUTE = 'UPDATE_MAP_ROUTE',
  MAP_ROUTE_DETAILS_RECEIVED = 'MAP_ROUTE_DETAILS_RECEIVED',
  MAP_ROUTE_LIST_RECEIVED = 'MAP_ROUTE_LIST_RECEIVED',
  UI_MAP_ROUTE_SET_IDLE_STATUS = 'UI_MAP_ROUTE_SET_IDLE_STATUS',
  DELETE_MAP_ROUTE = 'DELETE_MAP_ROUTE',
  MAP_ROUTE_DELETED = 'MAP_ROUTE_DELETED',
  UI_SET_MAP_ROUTE_CURRENT_SCREEN = 'UI_SET_MAP_ROUTE_CURRENT_SCREEN',
  RESET = 'RESET'
}

export class ResetMapRoutesPaging implements Action {
  readonly type = MapRouteActionTypes.RESET_MAP_ROUTES_PAGING;
}

export class GetNextMapRoutesPage implements Action {
  readonly type = MapRouteActionTypes.GET_NEXT_MAP_ROUTES_PAGE;
  constructor() {}
}
export class GetPrevMapRoutesPage implements Action {
  readonly type = MapRouteActionTypes.GET_PREV_MAP_ROUTES_PAGE;
  constructor() {}
}

export class GetMapRouteByID implements Action {
  readonly type = MapRouteActionTypes.GET_MAP_ROUTE_BY_ID;
  constructor(public id: string) {}
}
export class CreateMapRoute implements Action {
  readonly type = MapRouteActionTypes.CREATE_MAP_ROUTE;
  constructor(public mapRoute: MapRoute) {}
}

export class MapRouteCreated implements Action {
  readonly type = MapRouteActionTypes.MAP_ROUTE_CREATED;
  constructor(public mapRoute: MapRoute) {}
}

export class UpdateMapRoute implements Action {
  readonly type = MapRouteActionTypes.UPDATE_MAP_ROUTE;
  constructor(public id: string, public mapRouteDetails: MapRoute) {}
}

export class MapRouteDetailsReceived implements Action {
  readonly type = MapRouteActionTypes.MAP_ROUTE_DETAILS_RECEIVED;
  constructor(public mapRoute: MapRoute) {}
}

export class SetCurrentScreen implements Action {
  readonly type = MapRouteActionTypes.UI_SET_MAP_ROUTE_CURRENT_SCREEN;
  constructor(public screen: string) {}
}

export class MapRouteListReceived implements Action {
  readonly type = MapRouteActionTypes.MAP_ROUTE_LIST_RECEIVED;
  constructor(
    public eop: number,
    public sop: number,
    public mapRoutes: MapRoute[],
    public hash: string
  ) {}
}

export class DeleteMapRoute implements Action {
  readonly type = MapRouteActionTypes.DELETE_MAP_ROUTE;
  constructor(public id: string) {}
}

export class MapRouteDeleted implements Action {
  readonly type = MapRouteActionTypes.MAP_ROUTE_DELETED;
  constructor(public id: string) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = MapRouteActionTypes.UI_MAP_ROUTE_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = MapRouteActionTypes.RESET;
}

export type Actions =
  | ResetMapRoutesPaging
  | GetNextMapRoutesPage
  | GetPrevMapRoutesPage
  | SetStatusAsIdle
  | CreateMapRoute
  | MapRouteCreated
  | UpdateMapRoute
  | MapRouteDetailsReceived
  | MapRouteListReceived
  | DeleteMapRoute
  | MapRouteDeleted
  | SetCurrentScreen
  | Reset;
