import { BreakpointObserver } from '@angular/cdk/layout';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/layout";
export class ScreenSizeService {
    constructor(breakpointObserver) {
        this.breakpointObserver = breakpointObserver;
        this.mobileQuery = this.breakpointObserver.observe(['(max-width: 1400px)']);
        this.mobileQuerySmall = this.breakpointObserver.observe([
            '(max-width: 960px)'
        ]);
    }
}
ScreenSizeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScreenSizeService_Factory() { return new ScreenSizeService(i0.ɵɵinject(i1.BreakpointObserver)); }, token: ScreenSizeService, providedIn: "root" });
