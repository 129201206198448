import { Action } from '@ngrx/store';
import { BookingPeriods, DayPeriods } from 'src/models/BookingPeriods';

export const enum BookingPeriodsActionTypes {
  RECEIVE_BOOKING_PERIODS = 'RECEIVE_BOOKING_PERIODS',
  CREATE_BOOKING_PERIODS = 'CREATE_BOOKING_PERIODS',
  UPDATE_BOOKING_PERIODS = 'UPDATE_BOOKING_PERIODS',
  DELETE_BOOKING_PERIODS = 'DELETE_BOOKING_PERIODS',
  RESET = 'RESET'
}

export class ReceiveBookingPeriods implements Action {
  readonly type = BookingPeriodsActionTypes.RECEIVE_BOOKING_PERIODS;
  constructor(public bookingPeriods: BookingPeriods) {}
}

export class CreateBookingPeriods implements Action {
  readonly type = BookingPeriodsActionTypes.CREATE_BOOKING_PERIODS;
  constructor(public bookingPeriods: BookingPeriods) {}
}

export class UpdateBookingPeriods implements Action {
  readonly type = BookingPeriodsActionTypes.UPDATE_BOOKING_PERIODS;
  constructor(public bookingPeriods: BookingPeriods) {}
}

export class DeleteBookingPeriods implements Action {
  readonly type = BookingPeriodsActionTypes.DELETE_BOOKING_PERIODS;
  constructor(public chatId: string, public data: DayPeriods[]) {}
}

export class Reset implements Action {
  readonly type = BookingPeriodsActionTypes.RESET;
}

export type Actions =
  | ReceiveBookingPeriods
  | CreateBookingPeriods
  | UpdateBookingPeriods
  | DeleteBookingPeriods
  | Reset;
