import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CalendarUiDispatchers } from './calendarUI.dispatchers';
import { GetCalendars, SetCalendar, GetCalendar, GetCalendarDetails, SetCalendarDetail, RemoveCalendarDetail, CreateCalendar } from 'src/models/Calendar';
import * as isEqual from 'lodash.isequal';
export class CalendarUiEffects {
    constructor(actions$, _socketGateway, _store, _calendarDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._calendarDispatchers = _calendarDispatchers;
        this.createCalendar = this.actions$.pipe(ofType("CREATE_CALENDAR" /* CREATE_CALENDAR */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateCalendar(action.calendar));
        }));
        this.updateCalendar = this.actions$.pipe(ofType("UPDATE_CALENDAR" /* UPDATE_CALENDAR */), map((action) => {
            const calendar = Object.assign({}, action.calendar, { id: action.calendarId });
            this._socketGateway.sendSocketMessage(new SetCalendar(calendar));
        }));
        this.listReceived = this.actions$.pipe(ofType("UI_CALENDAR_RECEIVED_HISTORY_CHUNK" /* UI_CALENDAR_RECEIVED_HISTORY_CHUNK */), map((action) => {
            if (action.currentPage && action.currentPage.length > 0) {
                action.currentPage.forEach(calendar => {
                    this._socketGateway.sendSocketMessage(new GetCalendar(calendar.id));
                    this._socketGateway.sendSocketMessage(new GetCalendarDetails(calendar.id));
                });
            }
        }));
        this.setCalendarDetail = this.actions$.pipe(ofType("SET_CALENDAR_DETAIL" /* SET_CALENDAR_DETAIL */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetCalendarDetail(action.calendar_id, action.data));
        }));
        this.removeDetail = this.actions$.pipe(ofType("DELETE_CALENDAR_DETAIL" /* DELETE_CALENDAR_DETAIL */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveCalendarDetail(action.calendar_id, action.references));
        }));
        this.getNextCalendarPage = this.actions$.pipe(ofType("UI_CALENDAR_GET_NEXT_PAGE" /* UI_CALENDAR_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.calendarUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, calendarUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCalendars(0, calendarUiState.hash, calendarUiState.eop));
        }));
        this.getPrevCalendarPage = this.actions$.pipe(ofType("UI_CALENDAR_GET_PREV_PAGE" /* UI_CALENDAR_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.calendarUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, calendarUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCalendars(1, calendarUiState.hash, calendarUiState.sop));
        }));
        this.afterCalendarSectionSelection = this.actions$.pipe(ofType("UI_CALENDAR_LIST" /* UI_CALENDAR_LIST */), map((action) => {
            this._calendarDispatchers.getNextCalendarPage();
        }));
        this.afterCalendarResetPaging = this.actions$.pipe(ofType("UI_CALENDAR_RESET_PAGING" /* UI_CALENDAR_RESET_PAGING */), map((action) => {
            this._calendarDispatchers.getNextCalendarPage();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "createCalendar", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "updateCalendar", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "listReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "setCalendarDetail", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "removeDetail", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "getNextCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "getPrevCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "afterCalendarSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CalendarUiEffects.prototype, "afterCalendarResetPaging", void 0);
