import {
  LocalComponent,
  LocalItem,
  ContainerChildren
} from 'src/models/ChannelAppLocalConfig';
import {
  uuidv4,
  AppClassNames,
  CHANNEL_APP_SEARCH_CATEGORIES,
  ContainerTypes
} from 'src/models/constants';
import { SystemConfig } from 'src/models/ChannelAppConfig';
import { extractComponentData } from '../channelAppConfig/appConfig.middleware';

const DEFAULT_NAV_ITEM: LocalItem = {
  icon: 'ic_baseline_chat_24dp',
  link: 'web_view',
  title: 'Web view',
  icon_ios: 'ic-news-29-px'
};

export function initNewItem(
  parentComponent: LocalComponent,
  specialItemValues?: LocalItem,
  itemList?: LocalItem[]
): LocalItem {
  let newItem: LocalItem = {};
  newItem.ref = uuidv4();
  newItem.parentComponentRef = parentComponent.ref;
  newItem.order = itemList ? itemList.length : 0;
  newItem.appClassName = parentComponent.appClassName;
  newItem.containerType = parentComponent.containerType;
  newItem.componentType = parentComponent.component_type;

  switch (parentComponent.appClassName) {
    case AppClassNames.NAV_MENU: {
      newItem = { ...newItem, ...DEFAULT_NAV_ITEM };
      break;
    }
    case AppClassNames.SEARCH: {
      newItem.link = ContainerTypes.SEARCH;
      const filterNumber = newItem.order + 1;
      newItem = {
        ...newItem,
        type: getSearchType(filterNumber),
        title: 'Filter #' + filterNumber
      };
      break;
    }
  }
  if (specialItemValues) {
    newItem = { ...newItem, ...specialItemValues };
  }

  return newItem;
}

export function extractItemData(
  defaultComponent: LocalComponent,
  newItem: LocalItem,
  systemConfig: SystemConfig
): ContainerChildren {
  let components: LocalComponent[] = [];
  let items: LocalItem[] = [];

  const compChildren = extractComponentData(
    defaultComponent,
    newItem,
    systemConfig
  );
  components = [...components, ...compChildren.components];

  const children: ContainerChildren = { components, items };
  return children;
}

export function getSearchType(filterNumber: number): string {
  let searchKey = CHANNEL_APP_SEARCH_CATEGORIES.AREA;
  switch (filterNumber) {
    case 1: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.AREA;
      break;
    }
    case 2: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY;
      break;
    }
    case 3: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS;
      break;
    }
  }
  return searchKey;
}
