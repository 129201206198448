import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

import { CellsService } from '../cells.service';
import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-audio-cell',
  templateUrl: './audio-cell.component.html',
  styleUrls: ['./audio-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioCellComponent implements OnDestroy {
  @Input() type: string;
  @Input() percent: number;
  @Input() localMedia: string;
  @Input() mediaStatus: string;
  @Input() performer: string;
  @Input() title: string;
  @Input() fileSize: number;
  @Input() mediaDuration: number;
  @Input() caption: string;
  @Input() mainThemeColor: string;
  @Input() secondThemeColor: string;

  @Output() retryNotify = new EventEmitter<boolean>(false);
  @Output() cancelNotify = new EventEmitter<boolean>(false);

  @ViewChild('audioPlayer', { static: false }) audioPlayer: ElementRef;

  isPause = true;
  currentValue = 0;
  currentTime = 0;
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  constructor(private _cellsService: CellsService) {}

  ngOnDestroy() {
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.pause();
    }
  }
  get theme() {
    return {
      'border-top-color': this.mainThemeColor,
      'border-right-color': this.mainThemeColor
    };
  }

  get media() {
    return this._cellsService.getMedia(this.localMedia);
  }

  get audioSekeer() {
    return {
      background: `
          linear-gradient(
          to left,
           ${this.secondThemeColor}  ${(1 - this.currentValue) * 100}% ,
           #f4952e ${this.currentValue * 100}%)
        `
    };
  }

  audioPlayNow() {
    if (
      this._cellsService.currentPlayingMedia &&
      this._cellsService.currentPlayingMedia !== this.audioPlayer.nativeElement
    ) {
      this._cellsService.currentPlayingMedia.pause();
    }
    this._cellsService.currentPlayingMedia = this.audioPlayer.nativeElement;
  }

  playAudio() {
    this.isPause = false;
    if (this.audioPlayer) {
      this.audioPlayer.nativeElement.play();
    }
  }

  audioEnded() {
    this.isPause = true;
    this.audioPlayer.nativeElement.pause();
    this.audioPlayer.nativeElement.currentTime = 0;
  }

  pauseAudio() {
    this.isPause = true;
    this.audioPlayer.nativeElement.pause();
  }

  onTimeUpdate() {
    this.currentValue =
      this.audioPlayer.nativeElement.currentTime / (this.mediaDuration / 1000);
    this.currentTime =
      (this.audioPlayer.nativeElement.currentTime - this.mediaDuration / 1000) *
      1000;

    if (this.currentValue === 1) {
      this.isPause = true;
      this.currentValue = 0;
    }
  }

  seek(value: number) {
    if (value) {
      this.audioPlayer.nativeElement.currentTime =
        value * (this.mediaDuration / 1000);
    }
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
