import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

import { LayoutService } from '../layout.service';

import { UISections } from 'src/models/constants';
import { AppInfo } from 'src/models/ChannelAppConfig';

@Component({
  selector: 'app-navbar-pagename',
  templateUrl: './navbar-pagename.component.html',
  styleUrls: ['./navbar-pagename.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarPagenameComponent implements OnInit {
  @Input() currentPage: any;
  @Input() appInfo: AppInfo;
  uiSections: typeof UISections = UISections;

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {}

  getSectionTitle(m: string) {
    if (this.appInfo) {
      this.layoutService.getDocumentationLinkOfSection(
        m,
        this.currentPage,
        this.appInfo.layout
      );
    }
    let title = '';
    if (this.layoutService.appMenu.filter(r => r.section === m)[0]) {
      title = this.layoutService.appMenu.filter(r => r.section === m)[0].title;
    } else if (m === UISections.BILLINGS) {
      title = 'Billing';
    }
    return title;
  }
}
