import {
  GET_PAYMENT_TOKEN_METHOD,
  GET_PAYMENT_INFO_METHOD,
  SET_FREE_PAYMENT_METHOD,
  CANCEL_FREE_PAYMENT_METHOD
} from './constants';
import { IPricePlan } from './IPricePlan';

export interface IPayment {
  domain?: string;
  email?: string;
  first_name?: string;
  groupId?: string;
  last_name?: string;
  payment_account?: string;
  phone_number?: string;
  paymentToken?: string;
  payment_status?: string;
  payment_failure_message?: string;
  payment_plan?: string;
  current_plan?: IPricePlan; // set current_plan for alrady pay plan
  url?: string; // set url for selected plan to pay
  plan?: IPricePlan; // set plan for selected plan to pay
  canceled_url?: string;
  canceled_plan?: IPricePlan;
  pricePlans?: IPricePlan[][];
}

export interface RcvdPayment {
  domain?: string;
  email?: string;
  first_name?: string;
  groupId?: string;
  last_name?: string;
  payment_status?: string;
  payment_failure_message?: string;
  payment_account?: string;
  payment_plan?: string;
  plan_id?: string;
  phone_number?: string;
  payment_sub_monthly?: string;
}

export class SetFreePayment {
  readonly method = SET_FREE_PAYMENT_METHOD;
  constructor() {}
}

export class CancelFreePayment {
  readonly method = CANCEL_FREE_PAYMENT_METHOD;
  constructor() {}
}

export class GetPaymentToken {
  readonly method = GET_PAYMENT_TOKEN_METHOD;
}
export class GetPaymentInfo {
  readonly method = GET_PAYMENT_INFO_METHOD;
}

export type PaymentMethods = GetPaymentToken | GetPaymentInfo;
