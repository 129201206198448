/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chips-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chips-frame.component";
var styles_ChipsFrameComponent = [i0.styles];
var RenderType_ChipsFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChipsFrameComponent, data: {} });
export { RenderType_ChipsFrameComponent as RenderType_ChipsFrameComponent };
export function View_ChipsFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "chips-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "chips-frame-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], null, null); }
export function View_ChipsFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chips-frame", [], null, null, null, View_ChipsFrameComponent_0, RenderType_ChipsFrameComponent)), i1.ɵdid(1, 114688, null, 0, i2.ChipsFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChipsFrameComponentNgFactory = i1.ɵccf("app-chips-frame", i2.ChipsFrameComponent, View_ChipsFrameComponent_Host_0, {}, { removeNotify: "removeNotify" }, ["*"]);
export { ChipsFrameComponentNgFactory as ChipsFrameComponentNgFactory };
