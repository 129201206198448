import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-media-center-loading-cover',
  templateUrl: './media-center-loading-cover.component.html',
  styleUrls: ['./media-center-loading-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterLoadingCoverComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() size: string;
  @Input() status: string;
  @Input() progress: number;

  @Output() hideMediaCover = new EventEmitter<boolean>();
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(chang: SimpleChanges) {
    const chng = chang['status'];
    if (
      chng &&
      chng.currentValue &&
      (chng.currentValue === this.messageMediaStatus.DOWNLOADED ||
        chng.currentValue === this.messageMediaStatus.UPLOADED)
    ) {
      setTimeout(() => {
        this.hideMediaCover.emit(true);
      }, 500);
    }
  }
}
