import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { IMessage } from 'src/models/IMessage';

@Component({
  selector: 'app-message-post-operations',
  templateUrl: './message-post-operations.component.html',
  styleUrls: ['./message-post-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagePostOperationsComponent {
  @Input() message: IMessage;
  @Input() parentId: string;
  @Input() isNested: boolean;

  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() backNotify = new EventEmitter<boolean>();

  closeEvent() {
    this.closeNotify.emit(true);
  }

  backEvent() {
    this.backNotify.emit(true);
  }
}
