import { Action } from '@ngrx/store';
import {
  MyPageForm,
  MyPage,
  MyPageContainer,
  MyPageComponent
} from 'src/models/MyPageConfig';

export const enum MyPageActionTypes {
  REQUEST_MY_PAGE = 'REQUEST_MY_PAGE',
  RECEIVE_MY_PAGE = 'RECEIVE_MY_PAGE',
  RECEIVE_MY_PAGES = 'RECEIVE_MY_PAGES',
  CREATE_MY_PAGE = 'CREATE_MY_PAGE',
  MY_PAGE_CREATED = 'MY_PAGE_CREATED',
  UPDATE_MY_PAGE = 'UPDATE_MY_PAGE',
  UPDATE_MY_PAGE_FORM = 'UPDATE_MY_PAGE_FORM',
  UPDATE_MY_PAGE_CONTAINER = 'UPDATE_MY_PAGE_CONTAINER',
  REORDER_MY_PAGE_CONTAINERS = 'REORDER_MY_PAGE_CONTAINERS',
  DELETE_MY_PAGE_CONTAINER = 'DELETE_MY_PAGE_CONTAINER',
  ADD_MY_PAGE_CONTAINER = 'ADD_MY_PAGE_CONTAINER',
  UPDATE_MY_PAGE_COMPONENT = 'UPDATE_MY_PAGE_COMPONENT',
  REORDER_MY_PAGE_COMPONENTS = 'REORDER_MY_PAGE_COMPONENTS',
  DELETE_MY_PAGE_COMPONENT = 'DELETE_MY_PAGE_COMPONENT',
  ADD_MY_PAGE_COMPONENT = 'ADD_MY_PAGE_COMPONENT',
  DESELECT_CHANNEL = 'DESELECT_CHANNEL',
  GET_DETAILS_OF_LINKED_PAGE = 'GET_DETAILS_OF_LINKED_PAGE',
  DELETE_MY_PAGE = 'DELETE_MY_PAGE',
  MY_PAGE_DELETED = 'MY_PAGE_DELETED',
  CREATE_PAGE_TEMPLATE = 'CREATE_PAGE_TEMPLATE',
  PAGE_TEMPLATE_CREATED = 'PAGE_TEMPLATE_CREATED',
  RESET = 'RESET'
}

export class RequestMyPage implements Action {
  readonly type = MyPageActionTypes.REQUEST_MY_PAGE;
  constructor(public id: string) {}
}

export class GetDetailsOfLinkedPage implements Action {
  readonly type = MyPageActionTypes.GET_DETAILS_OF_LINKED_PAGE;
  constructor(public pageID: string) {}
}

export class ReceiveMyPage implements Action {
  readonly type = MyPageActionTypes.RECEIVE_MY_PAGE;
  constructor(public rcvdPage: MyPage) {}
}

export class ReceiveMyPages implements Action {
  readonly type = MyPageActionTypes.RECEIVE_MY_PAGES;
  constructor(public rcvdPages: MyPage[]) {}
}

export class CreateMyPage implements Action {
  readonly type = MyPageActionTypes.CREATE_MY_PAGE;
  constructor(
    public reference: string,
    public myPageForm?: MyPageForm,
    public name?: string,
    public url?: string,
    public image?: string,
    public desc?: string,
    public templateId?: string
  ) {}
}

export class MyPageCreated implements Action {
  readonly type = MyPageActionTypes.MY_PAGE_CREATED;
  constructor(public createdPage: MyPage, public reference: string) {}
}

// this should be called from effect and don't be updated from reducer (server call)
export class UpdateMyPage implements Action {
  readonly type = MyPageActionTypes.UPDATE_MY_PAGE;
  constructor(public id: string, public myPageDetails: MyPage) {}
}
// The following are for client side operations

export class UpdateMyPageForm implements Action {
  readonly type = MyPageActionTypes.UPDATE_MY_PAGE_FORM;
  constructor(
    public formID: string,
    public title: string,
    public image: string,
    public template: number,
    public background: string
  ) {}
}

// updating page containers client side
export class UpdateMyPageContainer implements Action {
  readonly type = MyPageActionTypes.UPDATE_MY_PAGE_CONTAINER;
  constructor(
    public formID: string,
    public containerID: string,
    public containerUpdatedProps: MyPageContainer
  ) {}
}

export class DeleteMyPageContainer implements Action {
  readonly type = MyPageActionTypes.DELETE_MY_PAGE_CONTAINER;
  constructor(public formID: string, public container: MyPageContainer) {}
}

export class AddMyPageContainer implements Action {
  readonly type = MyPageActionTypes.ADD_MY_PAGE_CONTAINER;
  constructor(public formID: string, public container: MyPageContainer) {}
}

export class ReorderPageContainers implements Action {
  readonly type = MyPageActionTypes.REORDER_MY_PAGE_CONTAINERS;
  constructor(
    public formID: string,
    public containerID: string,
    public newPosition: number
  ) {}
}
// updating page components client side
export class UpdateMyPageComponent implements Action {
  readonly type = MyPageActionTypes.UPDATE_MY_PAGE_COMPONENT;
  constructor(
    public formID: string,
    public containerID: string,
    public componentID: string,
    public componentUpdatedProps: MyPageComponent,
    public needPageID?: boolean
  ) {}
}

export class DeleteMyPageComponent implements Action {
  readonly type = MyPageActionTypes.DELETE_MY_PAGE_COMPONENT;
  constructor(
    public formID: string,
    public containerID: string,
    public component: MyPageComponent
  ) {}
}

export class AddMyPageComponent implements Action {
  readonly type = MyPageActionTypes.ADD_MY_PAGE_COMPONENT;
  constructor(
    public formID: string,
    public containerID: string,
    public component: MyPageComponent
  ) {}
}

export class ReorderPageComponents implements Action {
  readonly type = MyPageActionTypes.REORDER_MY_PAGE_COMPONENTS;
  constructor(
    public formID: string,
    public containerID: string,
    public componentID: string,
    public newPosition: number
  ) {}
}

export class DeleteMyPage implements Action {
  readonly type = MyPageActionTypes.DELETE_MY_PAGE;
  constructor(public pageID: string) {}
}

export class MyPageDeleted implements Action {
  readonly type = MyPageActionTypes.MY_PAGE_DELETED;
  constructor(public pageID: string) {}
}

export class DeselectChannel implements Action {
  readonly type = MyPageActionTypes.DESELECT_CHANNEL;
}

export class CreatePageTemplate implements Action {
  readonly type = MyPageActionTypes.CREATE_PAGE_TEMPLATE;
  constructor(
    public myPage?: MyPage,
    public name?: string,
    public image?: string,
    public desc?: string
  ) {}
}

export class PageTemplateCreated implements Action {
  readonly type = MyPageActionTypes.PAGE_TEMPLATE_CREATED;
  constructor(public pageId: string, public childTemplateId: string) {}
}

export class Reset implements Action {
  readonly type = MyPageActionTypes.RESET;
}

export type Actions =
  | RequestMyPage
  | ReceiveMyPage
  | ReceiveMyPages
  | CreateMyPage
  | MyPageCreated
  | UpdateMyPage
  | UpdateMyPageForm
  | UpdateMyPageContainer
  | DeleteMyPageContainer
  | AddMyPageContainer
  | ReorderPageContainers
  | UpdateMyPageComponent
  | DeleteMyPageComponent
  | AddMyPageComponent
  | ReorderPageComponents
  | DeselectChannel
  | GetDetailsOfLinkedPage
  | DeleteMyPage
  | MyPageDeleted
  | CreatePageTemplate
  | PageTemplateCreated
  | Reset;
