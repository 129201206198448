import { LIKE_MESSAGE_METHOD, SHARE_MESSAGE_METHOD } from './constants';

export interface LikeMessageAck {
  method?: string;
  chat_id?: string;
  message_id?: string;
  myLike?: number;
  likes?: number;
}
export class LikePost {
  readonly method = LIKE_MESSAGE_METHOD;
  constructor(
    public myLike: number,
    public chat_id: string,
    public message_id: string
  ) {}
}

export class SharePost {
  readonly method = SHARE_MESSAGE_METHOD;
  constructor(
    public from_user_id: string,
    public from_user_name: string,
    public message_id: string,
    public chat_id: string,
    public chat_name: string,
    public date: number,
    public text?: string,
    public attachment?: string
  ) {}
}

export type PostMethods = SharePost | LikePost;
