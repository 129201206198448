<app-headerless-closable-frame (closeNotify)="closeEvent()">
  <ng-container ngProjectAs="content">
    <div class="chart-container">
      <canvas
        *ngIf="messageStats && messageStats.length > 0"
        baseChart
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
      >
      </canvas>

      <div *ngIf="!messageStats || messageStats.length === 0" class="empty">
        <h1>No data available yet.</h1>
      </div>
    </div>
  </ng-container>
</app-headerless-closable-frame>
