import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { EmojiboxService } from '../emojibox.service';

@Component({
  selector: 'app-emoji-keyboard',
  templateUrl: './emoji-keyboard.component.html',
  styleUrls: ['./emoji-keyboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiKeyboardComponent {
  @Output() emoijNotify = new EventEmitter<string>();

  constructor(public emojiService: EmojiboxService) {}

  emoijEvent(event: HTMLImageElement) {
    this.emoijNotify.emit(event.outerHTML);
  }

  bkGroundPos(x, y) {
    return {
      'background-position': `-${x}px -${y}px`
    };
  }
}
