<div #codeContainer class="code-container">
  <div class="circle-input-container">
    <app-circle-input
      [focused]="firstFocused"
      (changeEvent)="onChange($event, 0)"
      (input)="onInput(0)"
    ></app-circle-input>
    <app-circle-input
      (changeEvent)="onChange($event, 1)"
      (input)="onInput(1)"
      [focused]="secondFocused"
    ></app-circle-input>
    <app-circle-input
      (changeEvent)="onChange($event, 2)"
      (input)="onInput(2)"
      [focused]="thirdFocused"
    ></app-circle-input>
  </div>
  <div class="circle-input-container">
    <app-circle-input
      (changeEvent)="onChange($event, 3)"
      (input)="onInput(3)"
      [focused]="fourthFocused"
    ></app-circle-input>
    <app-circle-input
      (changeEvent)="onChange($event, 4)"
      (input)="onInput(4)"
      [focused]="fifthFocused"
    ></app-circle-input>
    <app-circle-input
      (changeEvent)="onChange($event, 5)"
      (input)="onInput(5)"
      [focused]="sixthFocused"
    ></app-circle-input>
  </div>
</div>
