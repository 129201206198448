import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

import { AppMgmtService } from '../appMgmt.service';
import { ScreenSizeService } from 'src/app/core/screen-size.service';
import {
  ChannelAppScreens,
  FeaturesCategories,
  IS_PRODUCTION,
  LOGIN_TYPES,
  MENU_CELLS_CATEGORIES,
  PaymentProviderIDs,
  ReleasesIOSPageScreens,
  ReleasesPageScreens,
  TabTypes,
  UISections,
  VendorsNames,
  VendorsTypes
} from 'src/models/constants';
import { LayoutService } from './../../../+layout/layout.service';
import { LocalContainer, LocalItem } from 'src/models/ChannelAppLocalConfig';
import { AuthService } from 'src/app/core/auth.service';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { ThirdPartyInfoUI } from 'src/models/thirdPartyInfo';
import { UiService } from 'src/app/core/ui.service';
import { MainPageConfComponent } from '../custom/custom-area/main-page-conf/main-page-conf.component';
import { SubChatsService } from '../../sub-chats/sub-chats.service';
import { BotsService } from '../../bots/bots.service';
import { MyChannelService } from '../../my-channel/my-channel.service';
import { DashboardService } from '../../dashboard.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { ColorConfgComponent } from '../custom/custom-area/color-confg/color-confg.component';
import { MenusService } from '../../menus/menus.service';
import { PlansGateway } from 'src/app/network/gateway/plans.gateway';
import { PrivilegesName } from 'src/models/privileges';
import { CustomComponent } from '../custom/custom.component';
import { StorageService } from 'src/app/core/storage.service';
import { MobileWorkFlowStoreService } from '../../mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-mgmt-progress',
  templateUrl: './mgmt-progress.component.html',
  styleUrls: ['./mgmt-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MgmtProgressComponent implements OnInit {
  branding: string;
  splash: string;
  login: string;
  profile: string;
  home: string;
  activation: string;
  sideMenu: string;
  features: string;
  realease: string;
  publish: string;
  indexData: string;
  featuresIds: string[] = [];
  helpText = false;
  privilegesName = PrivilegesName;
  @Input() section: string;
  @Input() currentFeature: any;
  appScreens = ChannelAppScreens;
  @Input() progressWeb: any;
  @Input() currentPage: number;
  @Input() showSetting: boolean;
  @Output() pageChangedNotify = new EventEmitter<boolean>();
  @Output() stripeConnectNotify = new EventEmitter<boolean>();
  @Output() startOverNotify = new EventEmitter<boolean>();
  @Input() screenOpen: boolean;
  @Input() tabContainerList: LocalContainer[];
  @Input() currentTabs: LocalContainer[];
  @Input() itemList: LocalItem[];
  @Input() sideMenuOpen: boolean;
  @Input() isSubChannel: boolean;
  @Input() appInfo: AppInfo;
  @Input() splashPages: any[];
  @Input() splashFooter: any;
  @Input() vendors: ThirdPartyInfoUI[];
  @Input() selectedVendor: string;
  @Input() oldVend: ThirdPartyInfoUI[];
  @Input() showSettings: any;
  @Input() hideBackButton: any;
  @Input() authCollection: any;
  @Input() selectedTab: any;
  @Input() optionFormValidation: boolean;
  @Input() mobileStoreAppList: any[];
  paymentProviderIDs = PaymentProviderIDs;
  loginTypes = LOGIN_TYPES;
  tabTypes = TabTypes;
  isProduction = IS_PRODUCTION;
  uiSection = UISections;
  vendorsNames = VendorsNames;
  vendorsTypes = VendorsTypes;
  appScreen = ChannelAppScreens;
  releasesPageScreens = ReleasesPageScreens;
  releasesIOSPageScreens = ReleasesIOSPageScreens;
  social: FeaturesCategories = {};
  menucatOne: any = {};
  menuCategories = MENU_CELLS_CATEGORIES;
  errorsVendors = [];
  mainConfComponent = new MainPageConfComponent(
    this.appManagementService,
    this.botsService,
    this.uiService,
    this.layoutService,
    this.changeDetector,
    this._paymentProvidersDispatchers,
    this.myChannelService,
    this.dashboardService,
    this.subChatsService,
    this.authService,
    this.subscriptionService,
    this.mobileWorkflowService
  );

  colorConfigComp = new ColorConfgComponent(
    this.appManagementService,
    this.menusService,
    this.uiService,
    this.layoutService,
    this.authService,
    this.changeDetector,
    this._planeGateway
  );

  customComp = new CustomComponent(
    this.appManagementService,
    this.changeDetector,
    this.botsService,
    this.menusService,
    this.uiService,
    this._paymentProvidersDispatchers,
    this.layoutService,
    this.authService,
    this.myChannelService,
    this.dashboardService,
    this.subChatsService,
    this.subscriptionService,
    this.localStorage,
    this.mobileWorkflowService
  );

  constructor(
    public screenSize: ScreenSizeService,
    public appManagementService: AppMgmtService,
    public authService: AuthService,
    public layoutService: LayoutService,
    public uiService: UiService,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers,
    public botsService: BotsService,
    public changeDetector: ChangeDetectorRef,
    public myChannelService: MyChannelService,
    public dashboardService: DashboardService,
    public subChatsService: SubChatsService,
    public subscriptionService: SubscriptionService,
    public menusService: MenusService,
    public _planeGateway: PlansGateway,
    public localStorage: StorageService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {
    // this.menucatOne.title = 'Buttons';
    // this.menucatOne.icon = 'icon-button-cell';
    // this.menucatOne.selectedIcon = 'icon-selected-button-cell';
    // this.menucatOne.background = '';
    // this.menucatOne.backgroundClicked = '';
    // this.menucatOne.id = this.menuCategories.BUTTON;
    this.menucatOne.title = 'Separators Cell';
    this.menucatOne.icon = 'icon-separator';
    this.menucatOne.selectedIcon = 'icon-selected-separator';
    this.menucatOne.background = '';
    this.menucatOne.backgroundClicked = '';
    this.menucatOne.id = this.menuCategories.SEPARATOR;
  }

  ngOnInit() {
    if (
      this.authCollection &&
      this.authCollection.main_group_id !== this.authCollection.chatId
    ) {
      this.currentPage = 2;
    }

    this.showSetting = false;
    this.appManagementService._showSetting.next(false);
  }

  goToSpecificPage(screen: number, progressWeb: string) {
    this.social.title = 'Social';
    this.appManagementService._menuStatusTabNav.next(false);
    this.currentPage = screen;

    this.changeDetector.detectChanges();

    this.social.icon = 'icon-Social';

    this.social.background = 'socialbg.jpg';
    this.social.backgroundClicked = 'socialclicked.jpg';
    this.social.id = 'social';
    this.errorsVendors = [];
    let validation = true;
    if (this.currentFeature && this.currentFeature.id === 10008) {
      validation = this.validateSplashPages();
    }
    if (this.currentFeature && this.currentFeature.id === 10009) {
      validation = this.validateVendorsPage();
    }

    if (validation && this.optionFormValidation) {
      // const progressWebNum = parseInt(progressWeb, 10);

      if (screen == 2 || screen == 3) {
        // const appScreensStatus = this.localStorage.getRecord('appScreens');

        // this.localStorage.setRecord('appScreens', true);
        this.layoutService.setSideMenuStatus(1);
        this.appManagementService._workFlowScreens.next(true);
        // this.localStorage.setRecord('appScreens', true);
        this.appManagementService._selectedMenuButtonTab.next(false);
        this.appManagementService._selectedMenuButton.next(false);
        this.appManagementService._selectedMenuButtonCat.next(false);
        this.appManagementService._menuOnclickAction.next(false);
      }
      if (screen != 2) {
        this.appManagementService._createNewTab.next(false);
      } else if (screen == 2 && this.currentTabs.length <= 0) {
        this.appManagementService._createNewTab.next(true);
      }
      this.appManagementService.goToSpecificPage(screen);
      this.pageChangedNotify.emit(false);
    }
    if (screen === 5) {
      this.appManagementService.setCurrentScreen(
        this.releasesPageScreens.RELEASE_LIST
      );
      this.appManagementService.setCurrentIOSScreen(
        this.releasesIOSPageScreens.IOS_SETUP
      );
    }
    this.authService.updateProgressWeb('' + screen, 2);
    this.customComp.showMenuConfig(false);
    this.appManagementService._showMenuBtn.next(false);
    if (
      this.currentPage === this.appScreen.TABS &&
      this.selectedTab &&
      this.selectedTab === this.tabTypes.MENUS
    ) {
      this.customComp.selectedCategory(this.menucatOne);
    } else if (this.selectedTab && this.selectedTab !== this.tabTypes.MENUS) {
      this.customComp.selectedCategory(this.social);
    }

    if (this.selectedTab && this.selectedTab.type === 'page') {
      this.appManagementService._selectedPageIdToView.next(
        this.selectedTab.page_id
      );
      this.appManagementService._selectedPageId.next(undefined);
      this.appManagementService._gotSubPages.next(false);
    }
    this.appManagementService.unselectItem();
  }

  get progressBar() {
    if (this.currentPage == 1) {
      return {
        // transition: 'width 300ms ease-in-out',
        width: !this.isProduction ? '12%' : '15%'
      };
    } else if (this.currentPage == 2) {
      return {
        // transition: 'width 300ms ease-in-out',
        width: !this.isProduction ? '30%' : '40%'
      };
    } else if (this.currentPage == 3) {
      return {
        // transition: 'width 300ms ease-in-out',
        width: !this.isProduction ? '53%' : '65%'
      };
    } else if (this.currentPage == 4) {
      return {
        // transition: 'width 300ms ease-in-out',
        width: !this.isProduction ? '73%' : '90%'
      };
    } else if (this.currentPage == 5) {
      if (!this.isProduction) {
        return {
          // transition: 'width 300ms ease-in-out',
          width: '93%'
        };
      } else {
        return {
          // transition: 'width 300ms ease-in-out',
          width: '93%',
          background: '#1c3b4d'
        };
      }
    } else if (this.currentPage == 6) {
      return {
        // transition: 'width 300ms ease-in-out',
        width: '93%',
        background: '#1c3b4d'
      };
    }
  }

  get beforeBranding() {
    if (this.currentPage < 1) {
      this.branding = '1';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#d3e1ed',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage == 1) {
      this.branding = '1';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage > 1) {
      this.branding = '1';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowBranding() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #59BFCD',
      'margin-top': '-0.5px'
    };
  }
  get afterBranding() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#59BFCD',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeSplash() {
    if (this.currentPage < 4) {
      if (this.section === this.uiSection.CHANNEL) {
        this.splash = '3';
      } else if (this.section === this.uiSection.APP) {
        this.splash = '4';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage === 4) {
      if (this.section === this.uiSection.CHANNEL) {
        this.splash = '3';
      } else if (this.section === this.uiSection.APP) {
        this.splash = '4';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage > 4) {
      if (this.section === this.uiSection.CHANNEL) {
        this.splash = '3';
      } else if (this.section === this.uiSection.APP) {
        this.splash = '4';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }

  get beforePublish() {
    if (this.currentPage < 6) {
      if (this.section === this.uiSection.CHANNEL) {
        this.publish = '4';
      } else if (this.section === this.uiSection.APP) {
        this.publish = '6';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage === 6) {
      if (this.section === this.uiSection.CHANNEL) {
        this.publish = '4';
      } else if (this.section === this.uiSection.APP) {
        this.publish = '6';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage > 6) {
      if (this.section === this.uiSection.CHANNEL) {
        this.publish = '4';
      } else if (this.section === this.uiSection.APP) {
        this.publish = '6';
      }
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowSplash() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #ed7b69',
      'margin-top': '-0.5px'
    };
  }
  get afterSplash() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#ed7b69',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeLogin() {
    if (this.currentPage < 3) {
      this.login = '3';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage == 3) {
      this.login = '3';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage > 3) {
      this.login = '3';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowLogin() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #f5a623',
      'margin-top': '-0.5px'
    };
  }
  get afterLogin() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#f5a623',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeProfile() {
    if (this.currentPage < 4) {
      this.profile = '4';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage == 4) {
      this.profile = '4';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage > 4) {
      this.profile = '4';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowProfile() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #7c769d',
      'margin-top': '-0.5px'
    };
  }
  get afterProfile() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#7c769d',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeSideMenu() {
    if (this.currentPage < 3) {
      this.sideMenu = '3';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 3) {
      this.sideMenu = '3';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowSideMenu() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #4a90e2',
      'margin-top': '-0.5px'
    };
  }
  get afterSideMenu() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#4a90e2',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeHome() {
    if (this.currentPage < 2) {
      this.home = '2';

      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 2) {
      this.home = '2';

      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowHome() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #c4394a',
      'margin-top': '-0.5px'
    };
  }
  get afterHome() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#c4394a',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeFeatures() {
    if (this.currentPage < 7) {
      this.features = '7';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 7) {
      this.features = '7';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowFeatures() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #7ed321',
      'margin-top': '-0.5px'
    };
  }
  get afterFeatures() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#7ed321',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeRelease() {
    if (this.currentPage < 5) {
      this.realease = '5';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 5) {
      this.realease = '5';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowRelease() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #3ba9d1',
      'margin-top': '-0.5px'
    };
  }
  get afterRelease() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#3ba9d1',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }
  get beforeActivate() {
    if (this.currentPage < 7) {
      this.activation = '7';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 7) {
      this.activation = '7';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }
  get arrowActivate() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #1ac260',
      'margin-top': '-0.5px'
    };
  }
  get afterActivate() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#1ac260',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }

  get beforeIndexData() {
    if (this.currentPage < 8) {
      this.indexData = '8';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#e5ecf2',
        color: '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    } else if (this.currentPage >= 8) {
      this.indexData = '8';
      return {
        'border-radius': '50%',
        width: '14px',
        height: '14px',
        'background-color': '#1c3b4d',
        'margin-right': '4px',
        'margin-bottom': '6px',
        'font-size': '1em',
        'font-weight': '500',
        color: '#ffffff',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      };
    }
  }

  get arrowIndexData() {
    return {
      'border-top': '5px solid transparent',
      'border-bottom': '5px solid transparent',
      'border-left': '5px solid #3E3BED',
      'margin-top': '-0.5px'
    };
  }
  get afterIndexData() {
    return {
      width: '10px',
      height: '2px',
      'background-color': '#3E3BED',
      left: '6%',
      'z-index': '-1',
      top: '-7px',
      'margin-bottom': '1px',
      'margin-left': '5px'
    };
  }

  // stripeConnect() {
  //   this._paymentProvidersDispatchers.getPaymentProviders();
  //   this.stripeConnectNotify.emit(true);
  // }
  showHelpItem(event) {
    this.helpText = event;
  }
  validateSplashPages() {
    let validate = true;
    this.splashPages.forEach(res => {
      if (
        res.title === '' ||
        res.title === null ||
        !res.title ||
        res.desc === '' ||
        res.desc === null ||
        !res.desc ||
        this.splashFooter.title === '' ||
        this.splashFooter.title === null
      ) {
        validate = false;
      }
    });
    return validate;
  }
  validateVendorsPage() {
    const errorsVendors = [];
    let isSaved = true;
    if (
      this.vendors &&
      this.vendors.length > 0 &&
      this.appInfo.login_type === 'MSISDN'
    ) {
      this.vendors.map(res => {
        const vendorError = {
          type: res.type,
          name: res.name,
          user_error:
            (!res.access.user || res.access.user === '') &&
            res.name === this.vendorsNames.VONAGE,
          password_error:
            (!res.access.password || res.access.password === '') &&
            res.name === this.vendorsNames.VONAGE,
          key_error:
            (!res.access.api_key || res.access.api_key === '') &&
            (res.name === this.vendorsNames.INFOBIP ||
              res.name === this.vendorsNames.CLICKATELL),
          url_error:
            (!res.access ||
              !res.access.base_url ||
              res.access.base_url === '') &&
            res.name !== this.vendorsNames.VONAGE,
          from_error:
            (!res.access.from || res.access.from === '') &&
            (res.name === this.vendorsNames.INFOBIP ||
              res.name === this.vendorsNames.VONAGE) &&
            res.type !== this.vendorsTypes.SMS
        };
        if (Object.values(vendorError).includes(true)) {
          errorsVendors.push(vendorError);
        }
      });
      if (this.errorsVendors.length === 0) {
        isSaved = JSON.stringify(this.vendors) === JSON.stringify(this.oldVend);

        if (!isSaved) {
          this.appManagementService._showSavePopup.next(true);
        }
      }
    } else if (this.appInfo.login_type === 'MSISDN' && !this.selectedVendor) {
      const vendorError = {
        name_error: true
      };
      errorsVendors.push(vendorError);
      if (Object.values(vendorError).includes(true)) {
        this.uiService.showErrorToast(
          'Please select one of the supported vendors.'
        );
      }
    } else if (this.appInfo.login_type === 'MSISDN' && this.selectedVendor) {
      const vendorError = {
        name_error: true
      };
      errorsVendors.push(vendorError);
      if (Object.values(vendorError).includes(true)) {
        this.uiService.showErrorToast(
          'Please complete the configuration of the selceted vendor.'
        );
      }
    }

    if (errorsVendors.length > 0 || !isSaved) {
      return false;
    } else {
      return true;
    }
  }

  saveApp(event) {
    this.tabContainerList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });
    this.itemList.map(res => {
      if (this.featuresIds.indexOf(res.ui_module_id) <= -1) {
        this.featuresIds.push(res.ui_module_id);
      }
    });

    this.featuresIds = this.addExtraModules(this.featuresIds);

    if (this.section === this.uiSection.CHANNEL) {
      this.appManagementService.savePage(true, null, this.featuresIds);
      this.appManagementService.getNextPage();
    } else {
      this.appManagementService.unselectItem();
      this.appManagementService.savePage(false, null, this.featuresIds);
      this.appManagementService.getNextPage();
    }
  }

  startOver() {
    this.authService.resetSocketStatus();
    window.open('https://template.ourpage.me', '_self');
    // this.startOverNotify.emit();
  }

  save(event) {
    if (this.optionFormValidation) {
      const nextPage = parseInt(this.progressWeb);
      if (nextPage == 2 || nextPage == 3) {
        this.layoutService.setSideMenuStatus(1);
        this.appManagementService._workFlowScreens.next(true);
        // const appScreensStatus = this.localStorage.getRecord('appScreens');

        // this.layoutService.setSideMenuStatus(1);
        // this.appManagementService._workFlowScreens.next(true);
        // this.localStorage.setRecord('appScreens', true);
        this.appManagementService._selectedMenuButtonTab.next(false);
        this.appManagementService._selectedMenuButton.next(false);
        this.appManagementService._selectedMenuButtonCat.next(false);
        this.appManagementService._menuOnclickAction.next(false);

        this.customComp.showMenuConfig(false);
        this.appManagementService._showMenuBtn.next(false);
        if (
          this.currentPage === this.appScreen.TABS &&
          this.selectedTab &&
          this.selectedTab === this.tabTypes.MENUS
        ) {
          this.customComp.selectedCategory(this.menucatOne);
        } else if (
          this.selectedTab &&
          this.selectedTab !== this.tabTypes.MENUS
        ) {
          this.customComp.selectedCategory(this.social);
        }

        if (this.selectedTab && this.selectedTab.type === 'page') {
          this.appManagementService._selectedPageIdToView.next(
            this.selectedTab.page_id
          );
          this.appManagementService._selectedPageId.next(undefined);
          this.appManagementService._gotSubPages.next(false);
        }
        this.appManagementService.unselectItem();
        this.pageChangedNotify.emit(false);
      }
      this.authService.updateProgressWeb('' + (this.currentPage + 1), 2);
      this.appManagementService._createNewTab.next(false);
      this.pageChangedNotify.emit(false);

      this.mainConfComponent.saveApp(event, this.appInfo);
      if (this.mobileStoreAppList && this.mobileStoreAppList.length > 0) {
        this.mobileStoreAppList.forEach(store => {
          if (store.isUpdated) {
            if (store.image) {
              this.mobileWorkflowService.setStore(
                store.data.menu_id,
                store.currency,
                store.data.shop,
                store.image,
                store.name,
                store.desc,
                store.is_default,
                store.id
              );
            } else {
              this.mobileWorkflowService.setStore(
                store.data.menu_id,
                store.currency,
                store.data.shop,
                undefined,
                undefined,
                undefined,
                store.is_default,
                store.id
              );
            }
          }
        });
      }
    }
  }

  addExtraModules(featuresIds) {
    const cashModuleId = '30105';
    const payPalModuleId = '30104';
    const stripeModuleId = '30100';
    const emailModuleId = '30305';
    const noLoginModuleId = '30311';
    const mobileModuleId = '30200';
    const googleAdsModuleId = '30103';
    const appRatingModuleId = '30304';

    if (this.appInfo && this.appInfo.payment_provider) {
      this.appInfo.payment_provider.forEach(provider => {
        if (provider === this.paymentProviderIDs.CASH) {
          featuresIds.push(cashModuleId);
        } else if (provider === this.paymentProviderIDs.PAYPAL) {
          featuresIds.push(payPalModuleId);
        } else if (provider === this.paymentProviderIDs.STRIPE) {
          featuresIds.push(stripeModuleId);
        }
      });
    }

    if (this.appInfo.login_type === this.loginTypes.EMAIL) {
      featuresIds.push(emailModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.NO_LOGIN) {
      featuresIds.push(noLoginModuleId);
    }
    if (this.appInfo.login_type === this.loginTypes.MSISDN) {
      featuresIds.push(mobileModuleId);
    }

    if (this.appInfo.show_submitting_app_review) {
      featuresIds.push(appRatingModuleId);
    }

    if (this.appInfo.ads_provider === this.paymentProviderIDs.GOOGLEPROVIDER) {
      featuresIds.push(googleAdsModuleId);
    }

    return featuresIds;
  }
}
