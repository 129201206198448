import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { CreatePackageMethod, Package, SetPackageMethod, GetPackagesMethod, RemovePackageBundlesMethod, AddPackageBundlesMethod, RemovePackageMethod } from 'src/models/Package';
import { Store } from '@ngrx/store';
import { PageDirection } from 'src/models/constants';
import { PackagesUiDispatchers } from './packagesUI.dispatchers';
import * as isEqual from 'lodash.isequal';
export class PackagesUiEffects {
    constructor(actions$, _socketGateway, _uiPackagesDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiPackagesDispatchers = _uiPackagesDispatchers;
        this._store = _store;
        this.createPackage = this.actions$.pipe(ofType("CREATE_PACKAGE" /* CREATE_PACKAGE */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreatePackageMethod(action.packageDetails));
        }));
        this.updatePackage = this.actions$.pipe(ofType("SET_PACKAGE" /* SET_PACKAGE */), map((action) => {
            const packageDetails = Object.assign({}, action.packageDetails, { id: action.packageID });
            this._socketGateway.sendSocketMessage(new SetPackageMethod(packageDetails));
        }));
        this.addPackageBundles = this.actions$.pipe(ofType("ADD_PACKAGE_ITEMS" /* ADD_PACKAGE_ITEMS */), map((action) => {
            this._socketGateway.sendSocketMessage(new AddPackageBundlesMethod(action.packageID, action.itemsIDs));
        }));
        this.removePackageBundles = this.actions$.pipe(ofType("REMOVE_PACKAGE_BUNDLES" /* REMOVE_PACKAGE_BUNDLES */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemovePackageBundlesMethod(action.packageID, action.bundlesIDs));
        }));
        //
        this.getNextPackagesPage = this.actions$.pipe(ofType("UI_PACKAGES_GET_NEXT_PAGE" /* UI_PACKAGES_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.packagesUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, packagesUiState]) => {
            const action = val;
            const prevRequest = packagesUiState.previousRequest;
            if (!(packagesUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                packagesUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetPackagesMethod(0, packagesUiState.eop));
            }
            else {
                this._uiPackagesDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevPackagesPage = this.actions$.pipe(ofType("UI_PACKAGES_GET_PREV_PAGE" /* UI_PACKAGES_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.packagesUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, packagesUiState]) => {
            const action = val;
            const prevRequest = packagesUiState.previousRequest;
            if (!(packagesUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                packagesUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetPackagesMethod(1, packagesUiState.sop));
            }
            else {
                this._uiPackagesDispatchers.setStatusAsIdle();
            }
        }));
        this.afterPackagesResetPaging = this.actions$.pipe(ofType("UI_PACKAGES_RESET_PAGING" /* UI_PACKAGES_RESET_PAGING */), map((action) => {
            this._uiPackagesDispatchers.getNextPackagesPage();
        }));
        this.deletePackage = this.actions$.pipe(ofType("DELETE_PACKAGE" /* DELETE_PACKAGE */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemovePackageMethod(action.packageID));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "createPackage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "updatePackage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "addPackageBundles", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "removePackageBundles", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "getNextPackagesPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "getPrevPackagesPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "afterPackagesResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PackagesUiEffects.prototype, "deletePackage", void 0);
