import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  Output,
  OnDestroy
} from '@angular/core';
import {
  LocalItem,
  LocalComponent,
  TabBg
} from 'src/models/ChannelAppLocalConfig';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import {
  SECTION_CARRIED_TYPES,
  ChannelAppScreens,
  FeatureItem,
  FeaturesModule,
  TabTypes,
  PaymentProviderIDs,
  UISections,
  BubbleStatus,
  ChatTypes,
  MessageSendingType,
  MyListTabTypes,
  ThemeStatus,
  VendorsNames,
  VendorsTypes,
  OpenChatTypes,
  LOGIN_TYPES
} from 'src/models/constants';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { BotsService } from './../../../../bots/bots.service';
import { UiService } from './../../../../../../core/ui.service';
import { FeaturesSublistComponent } from '../../dragable-features/features-sublist/features-sublist.component';
import { AppInfo, AppClass } from 'src/models/ChannelAppConfig';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
import { BotMenu } from 'src/models/IBotMenu';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import { IProfile } from 'src/models/IProfile';
import { MyChannelService } from 'src/app/+merchant/+dashboard/my-channel/my-channel.service';
import { SubChatsService } from 'src/app/+merchant/+dashboard/sub-chats/sub-chats.service';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { IOption } from 'src/models/campaign';
import { AuthService } from 'src/app/core/auth.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { PlansGateway } from 'src/app/network/gateway/plans.gateway';
import { Smtp, Stmpuser } from 'src/models/Smtp';
import { ThirdPartyInfoUI } from 'src/models/thirdPartyInfo';
import { BehaviorSubject } from 'rxjs';
import { AppMenu } from 'src/models/AppMenu';
import { LocalAppClass } from './../../../../../../../models/ChannelAppLocalConfig';
import { CURRENCY } from 'src/models/currency';
import { MobileWorkFlowStoreService } from 'src/app/+merchant/+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-main-page-conf',
  templateUrl: './main-page-conf.component.html',
  styleUrls: ['./main-page-conf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPageConfComponent implements OnInit, OnChanges, OnDestroy {
  // test
  public chatTypes: typeof ChatTypes = ChatTypes;
  messageSendingType: typeof MessageSendingType = MessageSendingType;
  showComposeMessage = false;
  themeStatus = ThemeStatus;
  myListTypes = MyListTabTypes;
  @Input() tabContainerList: any[];
  @Input() allTabsContainerList: any[];
  @Input() itemList: any[];
  @Input() isChannelApp: boolean;
  @Input() appClass: LocalAppClass;
  mainContainer: any[];
  @Input() createMenu;
  @Input() currentMenu;
  @Input() section: string;
  @Input() component: any;
  showSubChatList = false;
  feature: any;
  @Input() myBotsList: any[];
  @Input() tabContainer: any = {};
  @Input() tabAppClass: LocalAppClass;
  @Input() hideBackButton: boolean;
  @Output() hidingMenuNotify = new EventEmitter<boolean>();
  @Input() showSettings: boolean;
  @Input() hideSettingMenu: boolean;
  @Input() currentPage: ChannelAppScreens;
  @Input() pageTemp: any;
  @Input() currentTabs: IOption[];
  @Output() mobViewNotify = new EventEmitter<string>();
  @Output() pageTempNotify = new EventEmitter<any>();
  @Output() selectedMenuNotify = new EventEmitter<any>();
  @Output() showSettingsNotify = new EventEmitter<any>();
  @Output() menuFormNotify = new EventEmitter<any>();
  @Output() youTubeNotify = new EventEmitter<any[]>();
  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() menuBtnConfigNotify = new EventEmitter<boolean>();
  @Output() closeMenuConfNotify = new EventEmitter<any>();
  @Output() createNewMenuNotify = new EventEmitter<any>();
  @Input() profileFields: any[];
  @Input() vendors: ThirdPartyInfoUI[];
  @Input() oldVendors: ThirdPartyInfoUI[];
  @Input() selectedButtonTab: any;
  @Input() isDefault: any;
  @Input() selectedItem: any;
  @Input() chatLabel: any[];
  @Input() mobileStoreAppList: any[];
  @Input() selectedStoreId: string;
  @Input() mainMenuId: string;
  storeId: any;
  @Output() selectedFeatureNotify: EventEmitter<any> = new EventEmitter<any>();
  enableTags = false;
  enableAdim = false;
  enableFooter = false;
  enableSticky = false;
  enableContext = false;
  openChatTypes = OpenChatTypes;
  @Input() selectedButton: any;
  @Input() selectedButtonStore: any;
  @Input() lastMenu: any;
  instantPage: string;
  subPage: boolean;
  pageId: string;
  currencies = CURRENCY;
  parentIds: string[] = [];
  page: boolean;
  featuresIds: string[] = [];
  containerType: string;
  waiting: boolean;
  // mainContainer: FeaturesModule[];
  splash: FeaturesModule = {};
  companyDetails: FeaturesModule = {};
  yourChannel: FeaturesModule = {};
  login: FeaturesModule = {};
  demoAcc: FeaturesModule = {};
  profile: FeaturesModule = {};
  bubble: FeaturesModule = {};
  creditCard: FeaturesModule = {};
  stripe: FeaturesModule = {};
  enableAllService: FeaturesModule = {};
  payTabs: FeaturesModule = {};
  googleAdds: FeaturesModule = {};
  weAccept: FeaturesModule = {};
  unused: FeaturesModule = {};
  paypal: FeaturesModule = {};
  cash: FeaturesModule = {};
  whitelist: FeaturesModule = {};
  invitationalLinks: FeaturesModule = {};
  showAppDetails: FeaturesModule = {};
  submitAppReview: FeaturesModule = {};
  inAppPurchases: FeaturesModule = {};
  chatBots: FeaturesModule = {};
  defaults: FeaturesModule = {};
  termAndConditions: FeaturesModule = {};
  privacyPolicy: FeaturesModule = {};
  appLang: FeaturesModule = {};
  help: FeaturesModule = {};
  @Input() sideMenuContainer: any;
  @Input() formTitle: string;
  @Input() formSubTitle: string;
  @Input() appInfo: AppInfo;
  @Input() menusAppClass: AppClass;
  @Input() showAction = false;
  @Input() formActive;
  @Input() isNandbox: boolean;
  @Input() selectedChat;
  @Input() mobileStoreList: any[];
  @Output() dropEmitter = new EventEmitter<any>();
  @Output() selectedCategoryNotify = new EventEmitter<any>();
  menuItem: AppMenu;
  appScreens = ChannelAppScreens;
  carriedTypes = SECTION_CARRIED_TYPES;
  uiSections = UISections;
  title: string;
  actionTitle = 'Add item';
  hideSaveButton: boolean;
  settingsIcon = './assets/svg/app-icons.svg#icon-settings';
  builderIcon = './assets/svg/app-icons.svg#icon-drawing';
  actionIcon = './assets/svg/app-features-icons.svg#icon-close-modual';
  homeTabsIcon = './assets/svg/app-features-icons.svg#icon-home-tabs';
  tabTypes = TabTypes;
  grid: boolean;
  list: boolean;
  dragContainer: boolean;
  paymentProviderTypes = PaymentProviderIDs;
  bubbleStatus = BubbleStatus;
  showVerify = false;
  @Input() appMenu: AppMenu[];
  @Input() navComponent: any;
  floating_button = [
    {
      type: 'talk_admin',
      size: 'm',
      bg_color: null
    }
  ];

  iconSize = [
    {
      label: 'Large',
      value: 'l'
    },
    {
      label: 'Medium',
      value: 'm'
    },
    {
      label: 'Small',
      value: 's'
    }
  ];

  workFlowBg = false;
  private tabs = null;
  private navMenu = null;
  private tab = null;

  getFeature = new FeaturesSublistComponent(
    this.changeDetector,
    this.appManagementService,
    this.botsService,
    this.layoutService,
    this.uiService,
    this.mobileWorkflowService
  );
  menu = [
    {
      disabled: false,
      icon: 'ic_outline_share_24dp',
      link: 'share',
      title: 'Share'
    },
    {
      disabled: false,
      icon: 'ic_baseline_forward_24dp',
      link: 'forward',
      title: 'Forward'
    },
    {
      disabled: false,
      icon: 'ic_copy_24dp',
      link: 'copy',
      title: 'Copy'
    },
    {
      disabled: false,
      icon: 'ic_baseline_edit_24dp',
      link: 'edit',
      title: 'Edit'
    },
    {
      disabled: false,
      icon: 'icon-image-gallery-1',
      link: 'save_to_gallery',
      title: 'Save to Gallery'
    },
    {
      disabled: false,
      icon: 'icon-musical-note',
      link: 'save_to_music',
      title: 'Save to Music'
    },
    {
      disabled: false,
      icon: 'icon-download-to-storage-drive-1',
      link: 'save_to_downloads',
      title: 'Save to Downloads'
    },
    {
      disabled: false,
      icon: 'ic_report_abuse_24dp',
      link: 'abuse',
      title: 'Report abuse'
    },
    {
      disabled: false,
      icon: 'ic_baseline_delete_24dp',
      link: 'delete',
      title: 'Delete'
    }
  ];
  imageConfg = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#ffffff', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: false, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: false, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-repeat',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt'
    },
    btnShow: {
      zoomIn: false,
      zoomOut: false,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: false,
      prev: false
    }
  };
  isError = false;
  generalSettings: any[];
  signupAndRegistration: any[];
  ads: any[];
  paymentMethods: any[];
  apiAndIntegration: any[];
  channelDefaults: any[];
  privacy: any[];
  lang: any[];
  helpItems: any[];
  creditCardTypes: any[];
  creditCardTypesPopup: any[];
  whitelistArray: any[];
  selectedSettingOpj: FeaturesModule = {};
  selectedCreditCard;
  hideSittingHeader = false;
  togglePopupCreditCard = false;
  bot: any;
  botData: IProfile;
  showSettingsPopup: boolean;
  oldselectedChat;
  errorsProfilesFields = [];
  errorsVendors = [];
  @Input() selectedVendor: string;
  @Input() pageToView: any;
  showDemoAccData = false;
  demoAccItem: any;
  vendorsNames = VendorsNames;
  vendorsTypes = VendorsTypes;
  vendorsConfirmationMsg = false;
  oldLoginType: string;
  showCofigBtn: any;
  tabTybe = TabTypes;
  settingsTitle = 'Settings';
  paymentProviderIDs = PaymentProviderIDs;
  loginTypes = LOGIN_TYPES;
  disabledBtn = false;
  constructor(
    public appManagementService: AppMgmtService,
    public botsService: BotsService,
    public uiService: UiService,
    public layoutService: LayoutService,
    public changeDetector: ChangeDetectorRef,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers,
    public myChannelService: MyChannelService,
    public dashboardService: DashboardService,
    public subChatsService: SubChatsService,
    public authService: AuthService,
    public subscriptionService: SubscriptionService,
    public mobileWorkflowService: MobileWorkFlowStoreService
  ) {
    // this._paymentProvidersDispatchers.getPaymentProviders();
  }
  ngOnDestroy() {
    if (this.tabs) {
      this.tabs.unsubscribe();
    }
    if (this.navMenu) {
      this.navMenu.unsubscribe();
    }
    if (this.tab) {
      this.tab.unsubscribe();
    }
  }

  ngOnInit() {
    if (
      this.tabContainer &&
      (this.tabContainer.type === this.tabTypes.MENUS ||
        this.tabContainer.containerType === this.tabTypes.MENUS) &&
      this.tabContainer.sub_type === this.tabTypes.BUNDLE &&
      this.tabContainer.store_id
    ) {
      if (this.mobileStoreList && this.mobileStoreList.length <= 0) {
        let getStore = false;
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (
              store.is_default ||
              store.id + '' === this.tabContainer.store_id
            ) {
              store.data.shop.forEach(shop => {
                if (shop.menu_id === store.data.menu_id) {
                  this.mobileWorkflowService._selectedMenu.next(shop);
                  this.appManagementService._selectedMenu.next(shop);
                }
              });
              this.mobileWorkflowService._selectedMenuList.next(
                store.data.shop
              );
              this.mobileWorkflowService._selectedStoreId.next(store.id);

              getStore = true;
            }
          });
        }

        if (!getStore) {
          this.mobileWorkflowService.getMobileStoreById(
            this.tabContainer.store_id
          );
        }
      }
    }
    if (
      (this.currentPage === this.appScreens.TABS &&
        ((this.tabContainer &&
          this.tabContainer.type === this.tabTypes.MENUS) ||
          this.containerType === this.tabTypes.MENUS)) ||
      (this.currentPage === this.appScreens.NAV_MENU &&
        ((this.selectedItem &&
          this.selectedItem.link === this.tabTypes.MENUS) ||
          this.containerType === this.tabTypes.MENUS))
    ) {
      this.settingsTitle = 'Design';
      this.changeDetector.detectChanges();
    } else {
      this.settingsTitle = 'Settings';
      this.changeDetector.detectChanges();
    }
    this.waiting = false;
    this.oldselectedChat = this.selectedChat;
    this.changeDetector.detectChanges();
    this.appManagementService._selectedCreditCard.next(this.unused);

    if (
      this.appInfo &&
      !this.appInfo.hide_side_menu &&
      this.appInfo.hide_side_menu == 0
    ) {
      const newAppInfo: AppInfo = {};
      newAppInfo.hide_side_menu = 0;
      this.appManagementService.updateAppInfo(newAppInfo);
    }

    // if (
    //   this.menusAppClass &&
    //   this.tabContainerList &&
    //   this.tabContainerList.length <= 0 &&
    //   this.menusAppClass.menus
    // ) {
    //   const newContainer: LocalContainer = {};
    //   this.addTabs(newContainer);
    // }

    this.changeDetector.detectChanges();

    if (
      this.currentPage === this.appScreens.MAIN &&
      !this.showSettings &&
      !this.isNandbox
    ) {
      // this.appManagementService.getAppChannelSettings();
      this.appManagementService.getVerificationMessageTemplate();
      this.splash.title = 'Splash';
      this.splash.icon = 'icon-splash';
      this.splash.id = 10008;
      this.splash.desc =
        'An introductory graphical screen that shows a welcome message when a user first installs an app.';
      this.splash.category = ['main'];
      this.splash.doc_link =
        'https://docs.nandbox.com/docs/app-settings#splash-screen';

      this.companyDetails.title = 'Company Details';
      this.companyDetails.desc =
        'Allows you to enter your brand name and your company name.';
      this.companyDetails.icon = 'icon-company-details';
      this.companyDetails.id = 33;
      this.companyDetails.category = ['main'];

      this.login.title = 'Login & Signup';
      this.login.icon = 'icon-login';
      this.login.desc = 'Where a user can signup and login in to the app.';
      this.login.id = 10009;
      this.login.category = ['main'];
      this.login.doc_link =
        'https://docs.nandbox.com/docs/app-settings#login-and-signup';

      this.demoAcc.title = 'Store Verification Account';
      this.demoAcc.icon = 'icon-demoAcc';
      this.demoAcc.desc =
        'Setup an account to be accessed by Google Play Store & App Store to verify your app.';
      this.demoAcc.id = 10020;
      this.demoAcc.category = ['main'];
      this.demoAcc.doc_link =
        'https://docs.nandbox.com/docs/app-settings#store-verification-account';

      this.profile.title = 'User Profile';
      this.profile.icon = 'icon-profile';
      this.profile.desc =
        'Visual display of personal data associated with a specific user';
      this.profile.id = 10010;
      this.profile.category = ['main'];
      this.profile.doc_link =
        'https://docs.nandbox.com/docs/app-settings#user-profile';

      this.bubble.title = 'Bubble Themes';
      this.bubble.icon = 'icon-bubbles';
      this.bubble.id = 10011;
      this.bubble.desc = 'Choose your favorite chat bubble theme.';
      this.bubble.category = ['main'];
      this.bubble.doc_link =
        'https://docs.nandbox.com/docs/app-settings#bubble-themes';

      this.stripe.title = 'Credit Card (Stripe)';
      this.stripe.icon = 'icon-credit-card';
      this.stripe.desc =
        'Toggle the button to enable credit card payment (Stripe).';
      // this.stripe.icon = './assets/img/stripe.png';
      this.stripe.id = 30100;
      this.stripe.category = ['main'];
      this.stripe.doc_link =
        'https://docs.nandbox.com/docs/app-settings#credit-card';
      this.stripe.moduleId = 'payment_credit_card';

      this.enableAllService.title = 'Enable all payment services';
      this.enableAllService.icon = 'icon-credit-card';
      // this.enableAllService.desc =
      //   'Toggle the button to enable credit card payment (Stripe).';
      // this.stripe.icon = './assets/img/stripe.png';
      this.enableAllService.id = 10025;
      this.enableAllService.category = ['main'];
      // this.enableAllService.doc_link =
      //   'https://docs.nandbox.com/docs/app-settings#credit-card';
      this.enableAllService.moduleId = 'payment_credit_card';

      this.weAccept.title = 'Accept';
      // this.weAccept.icon = 'icon-weaccept';
      this.weAccept.icon = './assets/img/accept.png';
      this.weAccept.id = 30101;
      this.weAccept.category = ['main'];

      this.payTabs.title = 'Paytabs';
      // this.payTabs.icon = 'icon-paytabs-logo';
      this.payTabs.icon = './assets/img/paytaps.png';
      this.payTabs.id = 30102;
      this.payTabs.category = ['main'];

      this.unused.title = 'Disable';
      this.unused.id = 30;
      this.unused.category = ['main'];

      this.googleAdds.title = 'Google Ads';
      this.googleAdds.icon = 'icon-30103';
      this.googleAdds.id = 30103;
      this.googleAdds.category = ['main'];
      this.googleAdds.doc_link =
        'https://docs.nandbox.com/docs/app-settings#google-ads';
      this.googleAdds.moduleId = 'google_admob';

      this.creditCard.title = 'Credit Card';
      this.creditCard.icon = 'icon-credit-card';
      this.creditCard.id = 25;
      this.creditCard.category = ['main'];
      this.creditCard.desc = 'Choose between Stripe, Accept, and PayTabs';

      this.paypal.title = 'PayPal';
      this.paypal.icon = 'icon-paypal';
      this.paypal.id = 30104;
      this.paypal.category = ['main'];
      this.paypal.doc_link =
        'https://docs.nandbox.com/docs/app-settings#credit-card';
      this.paypal.moduleId = 'paypal';

      this.cash.title = 'Cash';
      this.cash.icon = 'icon-30105';
      this.cash.id = 30105;
      this.cash.category = ['main'];

      this.inAppPurchases.title = 'In App Purchases';
      this.inAppPurchases.icon = 'icon-buy';
      this.inAppPurchases.id = 30200;
      this.inAppPurchases.desc =
        'Allows you to make additional purchases in the app.';
      this.inAppPurchases.category = ['main'];
      this.inAppPurchases.doc_link =
        'https://docs.nandbox.com/docs/app-settings#in-app-purchases';
      this.inAppPurchases.moduleId = 'payment_in_app_purchase';

      this.chatBots.title = 'Chat Bots';
      this.chatBots.icon = 'icon-ic-bot-black-24-dp';
      this.chatBots.desc = 'Manage your bots and integrations.';
      this.chatBots.id = 31;
      this.chatBots.category = ['main'];
      this.chatBots.doc_link =
        'https://docs.nandbox.com/docs/app-settings#api-and-integrations';
      this.chatBots.moduleId = 'chatbot';

      this.defaults.title = 'Channel Default';
      this.defaults.icon = 'icon-channel-section';
      this.defaults.desc = 'Setup your Channel default configuration';
      this.defaults.id = 35;
      this.defaults.category = ['main'];
      this.defaults.doc_link = '';
      this.defaults.moduleId = 'chatbot';
      this.defaults.doc_link =
        'https://docs.nandbox.com/docs/app-settings#channel-default';

      this.termAndConditions.title = 'Terms of Service';
      this.termAndConditions.icon = 'icon-accept';
      this.termAndConditions.id = 26;
      this.termAndConditions.category = ['main'];
      this.termAndConditions.doc_link =
        'https://docs.nandbox.com/docs/app-settings#privacy-policy-and-terms--conditions';

      this.privacyPolicy.title = 'Privacy Policy';
      this.privacyPolicy.icon = 'icon-shield-black-24-dp';
      this.privacyPolicy.id = 60;
      this.privacyPolicy.category = ['main'];
      this.privacyPolicy.doc_link =
        'https://docs.nandbox.com/docs/app-settings#privacy-policy-and-terms--conditions';

      this.appLang.title = 'Application  Language';
      this.appLang.icon = 'icon-lang';
      this.appLang.id = 600;
      this.appLang.category = ['main'];
      this.appLang.doc_link = '';

      this.help.title = 'Customer Support Email';
      this.help.desc =
        'Provide your clients with a customer support email to contact you on.';
      this.help.icon = 'icon-accept';
      this.help.id = 5000;
      this.help.category = ['main'];
      this.help.doc_link =
        'https://docs.nandbox.com/docs/app-settings#customer-support-email';

      this.whitelist.title = 'Enable Whitelist';
      this.whitelist.icon = 'icon-Whitelist';
      this.whitelist.id = 27;
      this.whitelist.category = ['main'];
      this.whitelist.doc_link =
        'https://docs.nandbox.com/docs/user-management#whitelist';

      this.invitationalLinks.title = 'Invitational Links';
      this.invitationalLinks.icon = 'icon-link-2';
      this.invitationalLinks.id = 28;
      this.invitationalLinks.category = ['main'];
      this.invitationalLinks.desc =
        ' Hides invitational links to groups, channels, events, and bookings.';
      this.invitationalLinks.doc_link =
        'https://docs.nandbox.com/docs/app-settings#invitational-links';

      this.showAppDetails.title = 'Show App Details';
      this.showAppDetails.icon = 'icon-info_black';
      this.showAppDetails.id = 29;
      this.showAppDetails.category = ['main'];
      this.showAppDetails.desc =
        'Displays the app details like the app description and the number of participants.';
      this.showAppDetails.doc_link =
        'https://docs.nandbox.com/docs/app-settings#show-app-details';
      this.submitAppReview.title = 'Request App Rating';
      this.submitAppReview.icon = 'icon-star-rate';
      this.submitAppReview.id = 32;
      this.submitAppReview.category = ['main'];
      this.submitAppReview.desc =
        'Users will be asked to rate your app after a successful voice or video call over 30 minutes.';
      this.submitAppReview.doc_link =
        'https://docs.nandbox.com/docs/app-settings#request-app-rating';

      this.generalSettings = [
        this.splash,
        this.bubble,
        this.invitationalLinks,
        this.showAppDetails,
        this.submitAppReview
      ];
      this.signupAndRegistration = [this.login, this.profile, this.demoAcc];
      this.ads = [this.googleAdds];
      this.creditCardTypes = [
        this.unused,
        this.stripe
        // this.weAccept,
        // this.payTabs
      ];
      this.creditCardTypesPopup = [this.stripe];
      this.paymentMethods = [
        this.stripe,
        this.paypal,
        this.cash,
        this.inAppPurchases
      ];
      this.apiAndIntegration = [this.chatBots];
      this.channelDefaults = [this.defaults];
      this.privacy = [this.termAndConditions, this.privacyPolicy];
      this.lang = [this.appLang];
      this.helpItems = [this.help];
      this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
      if (this.appInfo && !this.appInfo.bubble_theme) {
        const appInfo: AppInfo = {};
        appInfo.bubble_theme = this.bubbleStatus.nandbox_bubble;
        this.appManagementService.updateAppInfo(appInfo);
      }
      if (this.myBotsList && this.myBotsList.length > 0) {
        for (let i = 0; i < this.myBotsList.length; i++) {
          this.mainContainer.push(this.myBotsList[i]);
        }
      }
      const paymentProviders = [];
      if (this.appInfo && this.appInfo.payment_provider) {
        this.appInfo.payment_provider.forEach(res => {
          switch (res) {
            case this.paymentProviderTypes.STRIPE: {
              this.appManagementService._selectedCreditCard.next(this.stripe);
              // this.selectedCreditCard = this.stripe;
              this.mainContainer.push(this.stripe);
              paymentProviders.push(this.paymentProviderTypes.STRIPE);
              break;
            }
            case this.paymentProviderTypes.WEACCEPT: {
              this.appManagementService._selectedCreditCard.next(this.weAccept);
              // this.selectedCreditCard = this.weAccept;
              this.mainContainer.push(this.weAccept);
              paymentProviders.push(this.paymentProviderTypes.WEACCEPT);
              break;
            }
            case this.paymentProviderTypes.PAYTABS: {
              this.appManagementService._selectedCreditCard.next(this.payTabs);
              // this.selectedCreditCard = this.payTabs;
              this.mainContainer.push(this.payTabs);
              paymentProviders.push(this.paymentProviderTypes.PAYTABS);
              break;
            }
            case this.paymentProviderTypes.PAYPAL: {
              this.mainContainer.push(this.paypal);
              paymentProviders.push(this.paymentProviderTypes.PAYPAL);
              break;
            }
            case this.paymentProviderTypes.CASH: {
              this.mainContainer.push(this.cash);
              paymentProviders.push(this.paymentProviderTypes.CASH);
              break;
            }
            case this.paymentProviderTypes.INAPPGOOGLE: {
              paymentProviders.push(this.paymentProviderTypes.INAPPGOOGLE);
              break;
            }
          }
        });
      }

      if (
        this.appInfo &&
        this.appInfo.payment_provider &&
        this.appInfo.payment_provider.length !== paymentProviders.length
      ) {
        const appInfo: AppInfo = {};
        appInfo.payment_provider = paymentProviders;
        this.appManagementService.updateAppInfo(appInfo);
      }
      if (this.appInfo && this.appInfo.ads_provider) {
        this.mainContainer.push(this.googleAdds);
      }
      this.tabContainerList = this.mainContainer;
      this.mobViewNotify.emit('10008');

      this.dragContainer = true;
    }

    if (
      this.currentPage === this.appScreens.MAIN &&
      !this.showSettings &&
      this.isNandbox
    ) {
      this.yourChannel.title = 'Your Channel';
      this.yourChannel.icon = 'icon-channel';
      this.yourChannel.id = 30104;
      this.yourChannel.desc = 'Adjust the settings for your channel';
      this.yourChannel.category = ['main'];

      this.creditCard.title = 'Credit Card';
      this.creditCard.icon = 'icon-credit-card';
      this.creditCard.id = 25;
      this.creditCard.category = ['main'];
      this.creditCard.desc =
        'include a credit/debit card payment option via Stripe';

      this.cash.title = 'Cash';
      this.cash.icon = 'icon-30105';
      this.cash.desc =
        'Please note that the default payment method is cash on delivery';
      this.cash.id = 30105;
      this.cash.category = ['main'];

      this.generalSettings = [this.yourChannel];
      this.paymentMethods = [this.cash, this.creditCard];
      this.apiAndIntegration = [this.chatBots];
      this.channelDefaults = [this.defaults];
      this.privacy = [this.termAndConditions, this.privacyPolicy];
      // this.helpItems = [this.help];
      this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
      if (this.appInfo && !this.appInfo.bubble_theme) {
        const appInfo: AppInfo = {};
        appInfo.bubble_theme = this.bubbleStatus.nandbox_bubble;
        this.appManagementService.updateAppInfo(appInfo);
      }
      if (this.myBotsList && this.myBotsList.length > 0) {
        for (let i = 0; i < this.myBotsList.length; i++) {
          this.mainContainer.push(this.myBotsList[i]);
        }
      }
      const paymentProviders = [];
      if (this.appInfo && this.appInfo.payment_provider) {
        this.appInfo.payment_provider.forEach(res => {
          switch (res) {
            case this.paymentProviderTypes.STRIPE: {
              this.appManagementService._selectedCreditCard.next(this.stripe);
              // this.selectedCreditCard = this.stripe;
              this.mainContainer.push(this.stripe);
              paymentProviders.push(this.paymentProviderTypes.STRIPE);
              break;
            }
            case this.paymentProviderTypes.CASH: {
              this.mainContainer.push(this.cash);
              paymentProviders.push(this.paymentProviderTypes.CASH);
              break;
            }
          }
        });
      }

      if (
        this.appInfo &&
        this.appInfo.payment_provider &&
        this.appInfo.payment_provider.length !== paymentProviders.length
      ) {
        const appInfo: AppInfo = {};
        appInfo.payment_provider = paymentProviders;
        this.appManagementService.updateAppInfo(appInfo);
      }

      this.tabContainerList = this.mainContainer;
      this.mobViewNotify.emit('30104');

      this.dragContainer = true;
    }

    // this.splash.title = 'Splash';
    // this.splash.icon = 'icon-splash';
    // this.splash.id = 10008;
    // this.splash.category = ['main'];

    // this.login.title = 'Login';
    // this.login.icon = 'icon-login';
    // this.login.id = 10009;
    // this.login.category = ['main'];

    // this.profile.title = 'Profile';
    // this.profile.icon = 'icon-profile';
    // this.profile.id = 10010;
    // this.profile.category = ['main'];

    // this.bubble.title = 'Bubbles Themes';
    // this.bubble.icon = 'icon-bubbles';
    // this.bubble.id = 10011;
    // this.bubble.category = ['main'];

    // this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
    this.hideSaveButton = false;
    this.title = 'Title';
    if (this.appInfo) {
      this.oldLoginType = this.appInfo.login_type;
    }
  }
  setFirstMenu() {
    const date = new Date();
    const newId = Math.floor(date.getDate() + Math.random() * 90000000);
    let id = null;

    id = 1;

    id = 'store' + newId;

    const api = null;

    return {
      id: null,
      menu_id: id,
      api_id: api,
      menu_ref: id,
      menu_date: null,
      menu_name: id,
      next_menu: null,
      rows: []
    };
  }
  addTabs(menucontainer, moduleId) {
    if (this.menusAppClass) {
      let mainMenu: BotMenu = {};
      let maianMenuAppClass: AppClass = {};
      mainMenu = this.menusAppClass.menus[0];
      maianMenuAppClass = this.menusAppClass;
      if (moduleId === '20002') {
        const newParam: any = {};
        newParam.function = 'store';
        menucontainer.param = newParam;
        menucontainer.sub_type = this.tabTybe.BUNDLE;
        menucontainer.store_currency = '';
        let getStore = false;
        if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
          this.mobileStoreAppList.forEach(store => {
            if (store.is_default) {
              store.data.shop.forEach(shop => {
                if (shop.menu_id === store.data.menu_id) {
                  this.mobileWorkflowService._selectedMenu.next(shop);
                  this.appManagementService._selectedMenu.next(shop);
                }
              });
              this.mobileWorkflowService._selectedMenuList.next(
                store.data.shop
              );
              this.mobileWorkflowService._selectedStoreId.next(store.id);

              getStore = true;
            }
          });
        }

        if (!getStore) {
          this.mobileWorkflowService.getMobileStoreById();
        }
      }

      menucontainer.type = this.tabTypes.MENUS;
      menucontainer.menu_id = mainMenu.menu_ref;
      this.appManagementService.addContainer(this.tabAppClass, menucontainer);
      let buttonId = 0;

      for (let i = 0; i < mainMenu.rows.length; i++) {
        for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
          buttonId++;
          const buttonContainer: LocalContainer = {};
          buttonContainer.type = '';

          buttonContainer.layout = 'button';
          buttonContainer.id = mainMenu.rows[i].buttons[j].button_id;
          buttonContainer.menu_id = mainMenu.menu_ref;
          buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
          buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

          this.appManagementService.addContainer(
            this.tabAppClass,
            buttonContainer
          );
        }
      }
    }
  }

  addItems(menucontainer) {
    if (this.menusAppClass) {
      let mainMenu: BotMenu = {};
      let maianMenuAppClass: AppClass = {};
      mainMenu = this.menusAppClass.menus[0];
      maianMenuAppClass = this.menusAppClass;
      menucontainer.type = this.tabTypes.MENUS;
      menucontainer.menu_id = mainMenu.menu_ref;
      this.appManagementService.addContainer(this.tabAppClass, menucontainer);
      let buttonId = 0;

      for (let i = 0; i < mainMenu.rows.length; i++) {
        for (let j = 0; j < mainMenu.rows[i].buttons.length; j++) {
          buttonId++;
          const buttonContainer: LocalContainer = {};
          buttonContainer.link = menucontainer.type;
          buttonContainer.layout = 'button';
          buttonContainer.id = mainMenu.rows[i].buttons[j].button_id;
          buttonContainer.menu_id = mainMenu.menu_ref;
          buttonContainer.title = mainMenu.rows[i].buttons[j].button_label;
          buttonContainer.title_ios = mainMenu.rows[i].buttons[j].button_label;

          this.appManagementService.addItem(this.component, buttonContainer);
        }
      }
    }
  }
  ngOnChanges() {
    if (
      this.tabContainer &&
      (this.tabContainer.type === this.tabTypes.MENUS ||
        this.tabContainer.containerType === this.tabTypes.MENUS) &&
      this.tabContainer.sub_type === this.tabTypes.BUNDLE &&
      this.tabContainer.store_id
    ) {
      this.storeId = this.tabContainer.store_id;
    }
    // if (
    //   this.tabContainer &&
    //   !this.tabContainer.floating_button &&
    //   (this.tabContainer.type === this.tabTybe.MEMBERSHIP ||
    //     this.tabContainer.type === this.tabTybe.WALLET)
    // ) {
    //   const floating_button = [
    //     {
    //       type: 'talk_admin'
    //     }
    //   ];

    //   const newContainer: LocalContainer = {};

    //   newContainer.floating_button = floating_button;

    //   this.appManagementService.updateContainer(
    //     this.tabContainer.ref,
    //     newContainer
    //   );
    // }
    if (
      (this.currentPage === this.appScreens.TABS &&
        ((this.tabContainer &&
          this.tabContainer.type === this.tabTypes.MENUS) ||
          this.containerType === this.tabTypes.MENUS)) ||
      (this.currentPage === this.appScreens.NAV_MENU &&
        ((this.selectedItem &&
          this.selectedItem.link === this.tabTypes.MENUS) ||
          this.containerType === this.tabTypes.MENUS))
    ) {
      this.settingsTitle = 'Design';
      if (
        this.selectedItem &&
        this.selectedItem.sub_type &&
        this.selectedItem.link === this.tabTypes.MENUS &&
        this.selectedItem.store_id
      ) {
        this.storeId = this.selectedItem.store_id;
      }
      if (
        this.tabContainer &&
        this.tabContainer.sub_type &&
        this.tabContainer.type === this.tabTypes.MENUS &&
        this.tabContainer.store_id
      ) {
        this.storeId = this.tabContainer.store_id;
      }
      this.changeDetector.detectChanges();
    } else {
      this.settingsTitle = 'Settings';
      this.changeDetector.detectChanges();
    }
    if (this.oldselectedChat !== this.selectedChat && this.isNandbox) {
      this.oldselectedChat = this.selectedChat;
      this._paymentProvidersDispatchers.getPaymentProviders();
    }
    if (this.currentPage === this.appScreens.MAIN && !this.showSettings) {
      this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
      if (this.myBotsList && this.myBotsList.length > 0) {
        for (let i = 0; i < this.myBotsList.length; i++) {
          this.mainContainer.push(this.myBotsList[i]);
        }
      }
      if (this.appInfo && !Array.isArray(this.appInfo.payment_provider)) {
        const appInfo: AppInfo = {};
        appInfo.payment_provider = [this.appInfo.payment_provider];
        this.appManagementService.updateAppInfo(appInfo);
      }
      if (this.appInfo && this.appInfo.payment_provider) {
        this.changeDetector.detectChanges();

        this.appInfo.payment_provider.forEach(res => {
          switch (res) {
            case this.paymentProviderTypes.STRIPE: {
              this.appManagementService._selectedCreditCard.next(this.stripe);
              this.mainContainer.push(this.stripe);
              break;
            }
            case this.paymentProviderTypes.WEACCEPT: {
              this.appManagementService._selectedCreditCard.next(this.weAccept);
              this.mainContainer.push(this.weAccept);
              break;
            }
            case this.paymentProviderTypes.PAYTABS: {
              this.appManagementService._selectedCreditCard.next(this.payTabs);
              this.mainContainer.push(this.payTabs);
              break;
            }
          }
        });
      }
      if (this.appInfo && this.appInfo.ads_provider) {
        this.mainContainer.push(this.googleAdds);
        this.changeDetector.detectChanges();
      }
      this.tabContainerList = this.mainContainer;
    } else if (
      !this.showSettings &&
      (this.currentPage === this.appScreens.TABS ||
        this.currentPage === this.appScreens.NAV_MENU)
    ) {
      this.mainContainer = this.tabContainerList;
    }

    this.changeDetector.detectChanges();
  }

  showConfig(event) {
    this.containerType = null;

    if (
      event.link === 'menu' &&
      !event.sub_type &&
      this.currentPage === this.appScreens.NAV_MENU
    ) {
      this.containerType = 'menu';
      const workflow: any = {};
      workflow.title = 'Work flow';
      workflow.icon = 'icon-workflow-item';
      workflow.id = '20001';
      this.feature = workflow;
    }
    if (
      event.row >= 0 &&
      this.currentPage !== this.appScreens.MAIN &&
      !event.sub_type
    ) {
      this.containerType = 'menu';
      const workflow: any = {};
      workflow.title = 'Work flow';
      workflow.icon = 'icon-workflow-item';
      this.feature = workflow;
    }
    if (
      this.currentPage !== this.appScreens.MAIN &&
      event &&
      !this.containerType
    ) {
      this.feature = this.getFeature.getModuleData(event.ui_module_id);
      if (this.feature && this.feature.id == 20002) {
        this.containerType = 'menu';
        this.appManagementService._selectedMenuButtonStore.next(
          this.selectedButton
        );

        if (event.store_id) {
          let getStore = false;
          if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
            this.mobileStoreAppList.forEach(store => {
              if (store.id + '' === event.store_id) {
                store.data.shop.forEach(shop => {
                  if (shop.menu_id === store.data.menu_id) {
                    setTimeout(() => {
                      this.mobileWorkflowService._selectedMenu.next(shop);
                      this.appManagementService._selectedMenu.next(shop);
                    }, 300);
                  }
                });

                this.mobileWorkflowService._selectedMenuList.next(
                  store.data.shop
                );
                this.mobileWorkflowService._selectedStoreId.next(store.id);

                getStore = true;
              }
            });
          }

          if (!getStore) {
            this.mobileWorkflowService.getMobileStoreById(event.store_id);
          }
        } else {
          let getStore = false;

          if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
            this.mobileStoreAppList.forEach(store => {
              if (store.is_default) {
                store.data.shop.forEach(shop => {
                  if (shop.menu_id === store.data.menu_id) {
                    setTimeout(() => {
                      this.mobileWorkflowService._selectedMenu.next(shop);
                      this.appManagementService._selectedMenu.next(shop);
                    }, 300);
                  }
                });
                this.mobileWorkflowService._selectedMenuList.next(
                  store.data.shop
                );
                this.mobileWorkflowService._selectedStoreId.next(store.id);

                getStore = true;
              }
            });
          }

          if (!getStore) {
            this.mobileWorkflowService.getMobileStoreById();
          }
        }
      }
    } else if (this.containerType !== 'menu') {
      this.feature = event;
    }

    // this.appManagementService.selectTab(event);
    this.changeDetector.detectChanges();
    this.tabContainer = event;
    this.mainContainer = [event];
    this.tabContainerList = [event];
    this.selectedItem = event;
    this.selectedItem.link = event.type;
    if (event.sub_type) {
      this.tabContainer.sub_type = event.sub_type;
      this.selectedItem.sub_type = event.sub_type;
    }
    this.changeDetector.detectChanges();
    if (this.tabContainer) {
      this.hidingMenuNotify.emit(true);
      this.dragContainer = false;
    } else {
      this.hideSettings();
    }
    if (
      event &&
      ((event.type === 'page' && this.currentPage === this.appScreens.TABS) ||
        (event.link === 'page' &&
          this.currentPage === this.appScreens.NAV_MENU))
    ) {
      this.containerType = 'page';
    } else if (!this.containerType) {
      this.containerType = event.type;
    }
    if (
      event.id == 10020 &&
      this.appInfo &&
      this.appInfo.demoAccData &&
      this.appInfo.demoAccData.countryDial
    ) {
      this.demoAccItem = event;

      this.showDemoAccInfo();
    } else if (
      event.id != 10020 ||
      (event.id == 10020 &&
        this.appInfo &&
        this.appInfo.demoAccData &&
        !this.appInfo.demoAccData.countryDial) ||
      (event.id == 10020 && this.appInfo && !this.appInfo.demoAccData)
    ) {
      this.demoAccItem = event;
      if (this.currentPage !== this.appScreens.MAIN) {
        this.appManagementService._containerType.next(this.containerType);
      }
      this.appManagementService.setAppSettingFeature(event);
      if (
        !this.selectedButton ||
        this.currentPage !== this.appScreens.NAV_MENU
      ) {
        this.showSettingsNotify.emit(event);
      }
    }

    if (this.feature && this.feature.id == 20002) {
      this.appManagementService._selectedMenuButton.next(false);
    }
    this.changeDetector.detectChanges();
  }
  hideSettings() {
    let firstBack = false;
    this.appManagementService._menuOnclickAction.next(false);

    if (this.currentPage === this.appScreens.TABS) {
      if (
        (this.selectedButton || this.selectedButtonStore) &&
        this.selectedButtonTab &&
        ((this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.selectedButtonTab.sub_type) ||
          this.selectedButtonTab.type !== this.tabTypes.MENUS) &&
        this.selectedButtonTab.type === this.tabContainer.type &&
        (this.pageId === this.tabContainer.page_id || !this.pageId)
      ) {
        firstBack = true;
        this.dragContainer = true;
        if (this.selectedButton) {
          this.appManagementService._selectedMenuButton.next(
            this.selectedButton
          );
          if (
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type
          ) {
            this.appManagementService._selectedMenuButton.next(false);

            // this.showConfig(this.selectedButtonTab);
          } else {
            this.appManagementService._selectedMenuButtonTab.next(undefined);
            const getTab = this.appManagementService.effectiveSelectedTab$.subscribe(
              tab => {
                const tabToCall = tab;

                if (tab.type === this.tabTypes.MENUS) {
                  tabToCall.ui_module_id = '20001';
                }
                this.showConfig(tab);
              }
            );
            getTab.unsubscribe();
          }
        } else {
          if (this.selectedButtonStore) {
            this.appManagementService._selectedMenuButton.next(
              this.selectedButtonStore
            );
          }
          this.appManagementService._selectedMenuButtonTab.next(undefined);
          this.appManagementService._selectedMenuButtonStore.next(false);
          if (this.lastMenu) {
            this.appManagementService._selectedMenu.next(this.lastMenu);
            this.mobileWorkflowService._selectedMenu.next(this.lastMenu);
          }
          const getTab = this.appManagementService.effectiveSelectedTab$.subscribe(
            tab => {
              const tabToCall = tab;

              if (tab.type === this.tabTypes.MENUS) {
                tabToCall.ui_module_id = '20001';
              }
              this.showConfig(tab);
            }
          );
          getTab.unsubscribe();
        }
        this.appManagementService._showSetting.next(true);

        this.containerType = 'menu';

        this.appManagementService._selectedMenuButtonCat.next(false);
        this.changeDetector.detectChanges();
      } else if (
        this.selectedButton &&
        (!this.selectedButtonTab ||
          (this.selectedButtonTab.type === this.tabTypes.MENUS &&
            !this.selectedButtonTab.sub_type) ||
          this.selectedButtonTab.type !== this.tabContainer.type)
      ) {
        firstBack = true;
        this.dragContainer = true;
        this.appManagementService._selectedMenuButton.next(false);
        this.appManagementService._showSetting.next(true);
        const getTab = this.appManagementService.effectiveSelectedTab$.subscribe(
          tab => {
            this.showConfig(tab);
          }
        );
        getTab.unsubscribe();
        this.containerType = 'menu';
        // this.hidingMenuNotify.emit(false);
        this.appManagementService._selectedMenuButtonTab.next(undefined);
        this.appManagementService._selectedMenuButtonCat.next(false);
        this.appManagementService._showMenuBtn.next(false);
        this.appManagementService._showSetting.next(true);

        this.containerType = 'menu';

        this.appManagementService._selectedMenuButtonCat.next(false);
        this.changeDetector.detectChanges();
      } else if (this.pageId === this.tabContainer.page_id || !this.pageId) {
        this.mainContainer = this.tabContainerList;
        this.containerType = null;
        this.showConfigButton(false);
        this.dragContainer = true;
        this.hidingMenuNotify.emit(false);
      }
    } else if (this.currentPage === this.appScreens.NAV_MENU) {
      this.mainContainer = this.sideMenuContainer;

      if (
        (this.selectedButton || this.selectedButtonStore) &&
        this.selectedButtonTab &&
        ((this.selectedButtonTab.type === this.tabTypes.MENUS &&
          this.tabContainer.sub_type) ||
          this.selectedButtonTab.type !== this.tabTypes.MENUS) &&
        this.selectedButtonTab.type === this.tabContainer.type &&
        (this.pageId === this.tabContainer.page_id || !this.pageId)
      ) {
        firstBack = true;
        this.dragContainer = true;
        if (this.selectedButton) {
          this.appManagementService._selectedMenuButton.next(
            this.selectedButton
          );
          if (
            this.selectedButtonTab.type === this.tabTypes.MENUS &&
            this.selectedButtonTab.sub_type
          ) {
            this.appManagementService._selectedMenuButton.next(false);
            // this.showConfig(this.selectedButtonTab);
          } else {
            this.appManagementService._selectedMenuButtonTab.next(undefined);

            const getTab = this.appManagementService.effectiveSelectedItem$.subscribe(
              tab => {
                this.selectedItem = tab;

                if (tab.link === this.tabTypes.MENUS) {
                  this.selectedItem.ui_module_id = '20001';
                }
                this.selectedItem.type = this.selectedItem.link;
                this.showConfig(tab);
              }
            );
            getTab.unsubscribe();
          }
          // console.log('selectedButtonTab', this.selectedButtonTab);
          // this.showConfig(this.selectedButtonTab);
        } else {
          if (this.selectedButtonStore) {
            this.appManagementService._selectedMenuButton.next(
              this.selectedButtonStore
            );
          }

          this.appManagementService._selectedMenuButtonTab.next(undefined);
          this.appManagementService._selectedMenuButtonStore.next(false);
          this.appManagementService._selectedMenu.next(this.lastMenu);
          this.mobileWorkflowService._selectedMenu.next(this.lastMenu);

          const getTab = this.appManagementService.effectiveSelectedItem$.subscribe(
            tab => {
              this.selectedItem = tab;

              if (tab.link === this.tabTypes.MENUS) {
                this.selectedItem.ui_module_id = '20001';
              }
              this.selectedItem.type = this.selectedItem.link;
              this.showConfig(tab);
            }
          );
          getTab.unsubscribe();
        }

        this.appManagementService._showSetting.next(true);

        this.containerType = 'menu';
        // this.appManagementService._selectedMenuButtonTab.next(undefined);
        this.appManagementService._selectedMenuButtonCat.next(false);
        this.changeDetector.detectChanges();
      } else if (
        this.selectedButton &&
        (!this.selectedButtonTab ||
          this.selectedButtonTab ||
          this.selectedButtonTab.type !== this.tabContainer.type)
      ) {
        firstBack = true;
        this.dragContainer = true;
        if (!this.selectedButtonTab) {
          this.appManagementService._selectedMenuButton.next(false);
        }

        this.appManagementService._showSetting.next(true);
        const getTab = this.appManagementService.effectiveSelectedItem$.subscribe(
          tab => {
            if (tab.link === this.tabTypes.MENUS) {
              tab.ui_module_id = '20001';
            }
            tab.type = tab.link;
            this.selectedItem = tab;
            this.showConfig(this.selectedItem);
          }
        );
        getTab.unsubscribe();
        this.containerType = 'menu';
        this.appManagementService._selectedMenuButtonTab.next(undefined);
        this.appManagementService._selectedMenuButtonCat.next(false);
        this.appManagementService._showMenuBtn.next(false);
        this.changeDetector.detectChanges();
        // this.hidingMenuNotify.emit(false);
      } else if (this.pageId === this.selectedItem.page_id || !this.pageId) {
        this.mainContainer = this.sideMenuContainer;
        this.showConfigButton(false);
        this.appManagementService.unselectItem();
        this.dragContainer = true;
        this.containerType = null;
        this.hidingMenuNotify.emit(false);
      }
    } else if (this.currentPage === this.appScreens.MAIN) {
      this.showConfigButton(false);
      this.hidingMenuNotify.emit(false);
      this.hideSittingHeader = false;
      this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
      if (this.myBotsList.length > 0) {
        for (let i = 0; i < this.myBotsList.length; i++) {
          this.mainContainer.push(this.myBotsList[i]);
        }
      }
    }

    if (
      this.pageId &&
      this.pageId !== this.tabContainer.page_id &&
      (this.tabContainer.type === this.tabTypes.PAGE ||
        this.tabContainer.link === this.tabTypes.PAGE)
    ) {
      this.removeParentId();
      this.changeDetector.detectChanges();
    } else if (this.selectedButton && !firstBack) {
      this.appManagementService._selectedMenuButtonTab.next(false);
      this.appManagementService._selectedMenuButton.next(undefined);
      this.appManagementService._selectedMenuButtonTab.next(undefined);
      this.appManagementService._selectedMenuButtonCat.next(false);
      this.dragContainer = true;
      this.showConfigButton(false);
      this.hidingMenuNotify.emit(false);
    }
  }

  mobView(event) {
    this.mobViewNotify.emit(event);
  }

  selectList() {
    this.list = true;
    this.grid = false;

    if (this.tabContainer !== null && this.tabContainer.container_type) {
      const containerDetails: LocalContainer = {};
      containerDetails.view_style = 0;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        containerDetails
      );
    } else {
      const itemDetails: LocalItem = {};
      itemDetails.view_style = 0;
      this.appManagementService.updateItem(this.tabContainer.ref, itemDetails);
    }
  }
  hideConformation() {
    this.containerType = null;
    this.showSettingsPopup = false;
    this.adsError(false);
    // this.hideSettings();
  }
  get gridStyle() {
    if (this.grid) {
      return {
        'background-color': '#d1effd'
      };
    }
  }
  selectGrid() {
    this.list = false;
    this.grid = true;
    if (this.tabContainer !== null && this.tabContainer.container_type) {
      const containerDetails: LocalContainer = {};
      containerDetails.view_style = 1;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        containerDetails
      );
    } else {
      const itemDetails: LocalItem = {};
      itemDetails.view_style = 1;
      this.appManagementService.updateItem(this.tabContainer.ref, itemDetails);
    }
  }
  get listStyle() {
    if (this.list) {
      return {
        'background-color': '#d1effd'
      };
    }
  }

  openPreviewMedia(event: ISelectedFiles, erarnComponent) {
    this.appManagementService.uploadComponentImage(
      event.localFile,
      erarnComponent,
      true
    );
  }

  onFilesSelected(ev, component) {
    const event: ISelectedFiles = ev;
    if (event.localFile) {
      const newComponent: LocalComponent = {};
      newComponent.uploadingSplashImage = 'UPLOADING';
      this.appManagementService.updateComponent(component.ref, newComponent);
      this.waiting = true;
      component.image_url = '';
      this.openPreviewMedia(event, component);
      this.closeEvent();
    }
  }

  onFilesCardSelected(ev, component) {
    const event: ISelectedFiles = ev;
    if (event.localFile) {
      const newComponent: LocalComponent = {};
      newComponent.uploadingSplashImage = 'UPLOADING';
      this.appManagementService.updateComponent(component.ref, newComponent);
      this.waiting = true;
      component.image_url = '';
      this.opencardPreviewMedia(event, component);
      this.closeEvent();
    }
  }

  closeEvent() {
    this.closeNotify.emit(false);
  }
  updateTitleEvent(value: string, component: LocalComponent) {
    const newComponent: LocalComponent = {};
    newComponent.title = value;
    this.appManagementService.updateComponent(component.ref, newComponent);
  }

  updateDescEvent(value: string, component: LocalComponent) {
    const newComponent: LocalComponent = {};
    newComponent.desc = value;
    this.appManagementService.updateComponent(component.ref, newComponent);
  }

  updateTargetEvent(value: number, component: LocalComponent) {
    const newComponent: LocalComponent = {};
    newComponent.target = value;
    this.appManagementService.updateComponent(component.ref, newComponent);
  }

  updatecardDescEvent(value: string, component: LocalComponent) {
    const newComponent: LocalComponent = {};
    newComponent.desc = value;
    this.appManagementService.updateComponent(component.ref, newComponent);
  }
  updatecardTitleEvent(value: string, component: LocalComponent) {
    const newComponent: LocalComponent = {};
    newComponent.title = value;
    this.appManagementService.updateComponent(component.ref, newComponent);
  }

  opencardPreviewMedia(event: ISelectedFiles, cardcomponent) {
    this.appManagementService.uploadComponentImage(
      event.localFile,
      cardcomponent
    );
  }

  asignTemp(event) {
    this.pageTempNotify.emit(event);
  }
  adsError(event) {
    this.isError = event;
  }

  showPopupSittings(event) {
    this.showSettingsPopup = true;

    this.togglePopupCreditCard = false;
    this.selectedSettingOpj = event;
  }
  saveApp(event, appInfo?) {
    this.featuresIds = [];
    if (!this.isError) {
      this.hideSettings();
    }

    // const tabHasVideo = this.tabContainerList.find(
    //   res => res.ui_module_id === '503'
    // );
    // const navHasVideo = this.itemList.find(res => res.ui_module_id === '503');
    // if (!tabHasVideo && !navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 0;
    //   appInfo.call_enabled = 0;
    //   this.appManagementService.updateAppInfo(appInfo);
    // } else if (tabHasVideo || navHasVideo) {
    //   const appInfo: AppInfo = {};
    //   appInfo.video_enabled = 1;
    //   appInfo.call_enabled = 1;
    //   this.appManagementService.updateAppInfo(appInfo);
    // }

    this.tabs = this.appManagementService.tabsContainers$.subscribe(res => {
      res.map(result => {
        if (this.featuresIds.indexOf(result.ui_module_id) <= -1) {
          this.featuresIds.push(result.ui_module_id);
        }
      });
    });
    this.navMenu = this.appManagementService.navMenuItems$.subscribe(res => {
      res.map(result => {
        if (this.featuresIds.indexOf(result.ui_module_id) <= -1) {
          this.featuresIds.push(result.ui_module_id);
        }
      });
    });
    if (appInfo) {
      this.appInfo = appInfo;
    }
    this.featuresIds = this.addExtraModules(this.featuresIds);
    if (this.section === this.uiSections.CHANNEL) {
      this.appManagementService.savePage(true, null, this.featuresIds);
      this.appManagementService.getNextPage();
    } else {
      this.appManagementService.savePage(false, null, this.featuresIds);
      this.appManagementService.getNextPage();
    }

    this.appManagementService.sendVerificationMessageTemplate();
    this.appManagementService.deleteVerificationMessageTemplate();
  }

  saveAppSettings(event) {
    this.featuresIds = [];

    this.tabs = this.appManagementService.tabsContainers$.subscribe(res => {
      res.map(result => {
        if (this.featuresIds.indexOf(result.ui_module_id) <= -1) {
          this.featuresIds.push(result.ui_module_id);
        }
      });
    });
    this.navMenu = this.appManagementService.navMenuItems$.subscribe(res => {
      res.map(result => {
        if (this.featuresIds.indexOf(result.ui_module_id) <= -1) {
          this.featuresIds.push(result.ui_module_id);
        }
      });
    });
    this.featuresIds = this.addExtraModules(this.featuresIds);
    if (this.section === this.uiSections.CHANNEL) {
      this.appManagementService.savePage(true, null, this.featuresIds);
    } else {
      this.appManagementService.savePage(false, null, this.featuresIds);
    }

    if (this.mobileStoreAppList && this.mobileStoreAppList.length > 0) {
      this.mobileStoreAppList.forEach(store => {
        if (store.isUpdated) {
          if (store.image) {
            this.mobileWorkflowService.setStore(
              store.data.menu_id,
              store.currency,
              store.data.shop,
              store.image,
              store.name,
              store.desc,
              store.is_default,
              store.id
            );
          } else {
            this.mobileWorkflowService.setStore(
              store.data.menu_id,
              store.currency,
              store.data.shop,
              undefined,
              undefined,
              undefined,
              store.is_default,
              store.id
            );
          }
        }
      });
    }

    if (!this.isError) {
      this.hideSettings();
    }
  }

  hideSettingTitle(event) {
    this.hideSittingHeader = event;
  }
  botDetails(event) {
    this.bot = event;
  }
  updateBotInAppSetting(event) {
    this.botData = event;
  }
  onSaveClicked(event) {
    this.errorsProfilesFields = [];
    this.errorsVendors = [];
    const oldVendorName = this.appInfo.vendors
      ? this.appInfo.vendors[0].name
      : null;
    if (this.tabContainer.id === 10010) {
      this.profileFields.map(res => {
        if (!res.title || res.title === '') {
          this.errorsProfilesFields.push(true);
        } else {
          this.errorsProfilesFields.push(false);
        }
      });
    }

    if (
      this.tabContainer.id === 10009 &&
      this.appInfo.login_type === 'MSISDN'
    ) {
      if (this.vendors && this.vendors.length > 0) {
        this.vendors.map(res => {
          const vendorError = {
            type: res.type,
            name: res.name,
            user_error:
              (!res.access.user || res.access.user === '') &&
              res.name === this.vendorsNames.VONAGE,
            password_error:
              (!res.access.password || res.access.password === '') &&
              res.name === this.vendorsNames.VONAGE,
            key_error:
              (!res.access.api_key || res.access.api_key === '') &&
              (res.name === this.vendorsNames.INFOBIP ||
                res.name === this.vendorsNames.CLICKATELL),
            url_error:
              (!res.access ||
                !res.access.base_url ||
                res.access.base_url === '') &&
              res.name !== this.vendorsNames.VONAGE,
            from_error:
              (!res.access.from || res.access.from === '') &&
              (res.name === this.vendorsNames.INFOBIP ||
                res.name === this.vendorsNames.VONAGE) &&
              res.type !== this.vendorsTypes.SMS
          };
          if (Object.values(vendorError).includes(true)) {
            this.errorsVendors.push(vendorError);
          }
        });
      } else if (this.appInfo.login_type === 'MSISDN' && !this.selectedVendor) {
        const vendorError = {
          name_error: true
        };
        this.errorsVendors.push(vendorError);
        if (Object.values(vendorError).includes(true)) {
          this.uiService.showErrorToast(
            'Please select one of the supported vendors.'
          );
        }
      } else if (this.appInfo.login_type === 'MSISDN' && this.selectedVendor) {
        const vendorError = {
          name_error: true
        };
        this.errorsVendors.push(vendorError);
        if (Object.values(vendorError).includes(true)) {
          this.uiService.showErrorToast(
            'Please complete the configuration of the selceted vendor.'
          );
        }
      }
    }
    if (!this.hideSittingHeader) {
      if (
        this.errorsVendors.length == 0 &&
        this.errorsProfilesFields.indexOf(true) === -1
      ) {
        this.saveAppSettings(event);
      }
    } else if (this.botData && this.tabContainer.id !== 10009) {
      this.hideSettingTitle(false);
      this.botsService.updateBot(this.botData);
    }
    if (
      this.errorsVendors.length == 0 &&
      this.appInfo.login_type === 'MSISDN' &&
      this.tabContainer.id === 10009
    ) {
      this.vendorsConfirmationMsg =
        oldVendorName && this.vendors[0].name !== oldVendorName;
      this.saveVendor();
      this.hideSettingTitle(false);
    }
  }

  saveSitting(event) {
    if (!this.togglePopupCreditCard) {
      if (!this.isError) {
        this.saveAppSettings(event);
        this.showSettingsPopup = false;
      }
    } else {
      this.showSettingsPopup = true;

      const appInfo: AppInfo = {};
      switch (this.selectedSettingOpj.id) {
        case 30100: {
          const newProviders: any[] = [];
          if (
            this.appInfo.payment_provider &&
            this.appInfo.payment_provider.length > 0
          ) {
            this.appInfo.payment_provider.forEach(res => {
              if (!this.paymentProviderChecker(res)) {
                newProviders.push(res);
              }
            });
          }
          newProviders.push(this.paymentProviderTypes.STRIPE);
          appInfo.payment_provider = newProviders;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
        case 30101: {
          const newProviders: any[] = [];
          if (
            this.appInfo.payment_provider &&
            this.appInfo.payment_provider.length > 0
          ) {
            this.appInfo.payment_provider.forEach(res => {
              if (!this.paymentProviderChecker(res)) {
                newProviders.push(res);
              }
            });
          }
          newProviders.push(this.paymentProviderTypes.WEACCEPT);
          appInfo.payment_provider = newProviders;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
        case 30102: {
          const newProviders: any[] = [];
          if (
            this.appInfo.payment_provider &&
            this.appInfo.payment_provider.length > 0
          ) {
            this.appInfo.payment_provider.forEach(res => {
              if (!this.paymentProviderChecker(res)) {
                newProviders.push(res);
              }
            });
          }
          newProviders.push(this.paymentProviderTypes.PAYTABS);
          appInfo.payment_provider = newProviders;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
      }
      this.togglePopupCreditCard = false;
      this.saveAppSettings(event);
    }
  }
  toggleChangeCreditCard(event) {
    this.togglePopupCreditCard = event;
    // this.containerType = null;
    // this.showSettingsPopup = false;
  }
  changeCreditCard(event) {
    this.selectedSettingOpj = event;
  }
  paymentProviderChecker(provider) {
    const payments = [
      this.paymentProviderTypes.STRIPE,
      this.paymentProviderTypes.PAYTABS,
      this.paymentProviderTypes.WEACCEPT
    ];
    if (payments.indexOf(provider) > -1) {
      return true;
    } else {
      return false;
    }
  }

  selectedMenu(selectedMenu) {
    this.selectedMenuNotify.emit(selectedMenu);
  }

  toggleMobileType(e) {
    const appInfo: AppInfo = {};
    if (!e.target.checked) {
      appInfo.hide_side_menu = 1;
    } else {
      appInfo.hide_side_menu = 0;
    }
    this.appManagementService.updateAppInfo(appInfo);
  }

  addParentId(values) {
    this.pageId = values[0];
    this.parentIds.push(values[1]);
    this.appManagementService._selectedPageIdToView.next(this.pageId);
    this.page = true;
    this.appManagementService._gotSubPages.next(true);
    this.changeDetector.detectChanges();
  }
  removeParentId() {
    this.pageId = this.parentIds[this.parentIds.length - 1];
    this.parentIds.splice(this.parentIds.length - 1, 1);

    if (this.pageId) {
      this.appManagementService._selectedPageIdToView.next(this.pageId);
    }

    if (this.parentIds.length <= 1) {
      this.page = false;
      this.changeDetector.detectChanges();
      this.appManagementService._gotSubPages.next(false);
    } else {
      this.page = true;

      this.appManagementService._gotSubPages.next(true);
    }
  }

  goToPage(pageId) {
    this.subPage = true;
    this.instantPage = this.pageId;
    this.pageId = pageId;
  }
  youTube(event) {
    this.youTubeNotify.emit(event);
  }

  setShowComposeMessage(e: boolean) {
    this.showComposeMessage = e;
  }
  pageVideo(event) {
    this.appManagementService.updateMyPageContainer(
      event.formId,
      event.containerId,
      event.newContainer
    );
    this.changeDetector.detectChanges();
  }
  setMode(e: Event) {
    e.stopPropagation();
    const buttons: IOption[] = [];
    if (this.appInfo.layout === 'Menu') {
      this.tabContainerList.map(tap => {
        if (tap.type !== 'menu') {
          const button: IOption = {};
          button.key = tap.id;
          button.value = tap.ref;
          buttons.push(button);
        }
      });
    }
    const newAppInfo: AppInfo = {};
    newAppInfo.menuTabs = buttons;
    this.appManagementService.updateAppInfo(newAppInfo);
    this.appManagementService.savePage(false);
    this.authService.updateProgressWeb('4', 1);
  }
  goToMenus() {
    this.authService.updateProgressWeb('9', 1);
  }
  showConfirmationMsg(e: Event) {
    e.stopPropagation();
    const buttons: IOption[] = [];
    // if (this.appInfo.layout === 'Menu') {
    this.tabContainerList.map(tap => {
      if (tap.type !== 'menu') {
        const button: IOption = {};
        button.key = tap.id;
        button.value = tap.ref;
        buttons.push(button);
      }
    });
    // }
    const newAppInfo: AppInfo = {};
    newAppInfo.menuTabs = buttons;
    this.appManagementService.updateAppInfo(newAppInfo);
    this.appManagementService.savePage(false);
    // this.appManagementService._buttonArray.next([...buttons]);
    this.goToMenus();
  }

  cancelConfirmMsg(e) {
    if (e.id == 27) {
      this.appManagementService._showConfirmMsg.next('Cancel27');
    }
    if (e.id == 28) {
      this.appManagementService._showConfirmMsg.next('Cancel28');
    }
  }

  confirmMsg(e) {
    this.appManagementService.confirmMassage(e);
    if (e.id !== 27) {
      this.saveAppSettings(e);
    }
    // this.appManagementService._showConfirmMsg.next('Confirm');
  }
  accVerified() {
    this.hideSettings();
    this.showDemoAccData = true;
    this.changeDetector.detectChanges();
    this.featuresIds = this.addExtraModules(this.featuresIds);
    this.appManagementService.savePage(false, null, this.featuresIds);
  }

  showDemoAccInfo() {
    this.showDemoAccData = true;
    this.changeDetector.detectChanges();
  }
  hideDemoAccData() {
    this.showDemoAccData = false;
    this.changeDetector.detectChanges();
  }
  resetAcc() {
    this.hideDemoAccData();
    this.showConfig(this.demoAccItem);
  }

  verifyTac(tac) {
    this.showVerify = false;

    if (this.appInfo.demoAccData.typ === 'Email') {
      this.appManagementService.registerdemoAccount(
        '12345678910',
        'web',
        '1',
        this.appInfo.demoAccData.email,
        'email',
        tac,
        this.appInfo.demoAccData.countryDial,
        this.appInfo.demoAccData.countryName,
        this.appInfo.demoAccData.isoCode,
        this.appInfo.demoAccData.email
      );
    } else {
      this.appManagementService.registerdemoAccount(
        '12345678910',
        'web',
        '1',
        this.appInfo.demoAccData.msisdn,
        'sms_http',
        tac,
        this.appInfo.demoAccData.countryDial,
        this.appInfo.demoAccData.countryName,
        this.appInfo.demoAccData.isoCode,
        this.appInfo.demoAccData.msisdn
      );
    }

    this.changeDetector.detectChanges();
  }

  hideVerify() {
    this.showVerify = false;
    this.changeDetector.detectChanges();
  }

  showVerification() {
    this.showVerify = true;
    this.changeDetector.detectChanges();
  }
  reactivate() {
    if (this.appInfo.demoAccData.typ === 'Email') {
      this.appManagementService.signUpdemoAccount(
        '12345678910',
        'web',
        '1',
        this.appInfo.demoAccData.msisdn,
        this.appInfo.demoAccData.email,
        'email',
        this.appInfo.demoAccData.countryDial,
        this.appInfo.demoAccData.isoCode
      );
    } else {
      this.appManagementService.signUpdemoAccount(
        '12345678910',
        'web',
        '1',
        this.appInfo.demoAccData.msisdn,
        this.appInfo.demoAccData.email,
        'sms_http',
        this.appInfo.demoAccData.countryDial,
        this.appInfo.demoAccData.isoCode
      );
    }
    this.showVerification();
  }
  resend() {
    this.appManagementService.resendVerCode(
      this.appInfo.demoAccData.msisdn,
      this.appInfo.demoAccData.countryDial
    );
  }

  saveVendor() {
    this.appManagementService.saveThirdPartyInfo();
    this.toggleConfirmationVendors(false);
    if (this.vendors && this.vendors.length > 0) {
      const vendors: ThirdPartyInfoUI[] = [];
      this.vendors.map(res => {
        const vendor: ThirdPartyInfoUI = {};
        vendor.name = res.name;
        vendor.type = res.type;
        vendors.push(vendor);
      });
      const appInfo: AppInfo = {};
      appInfo.vendors = [...vendors];
      this.appManagementService.updateAppInfo(appInfo);
    }
  }

  toggleConfirmationVendors(status) {
    this.appManagementService._showSavePopup.next(status);
  }
  goToLogin() {
    this.showConfig(this.login);
    this.changeDetector.detectChanges();
  }

  getMenuForm(event) {
    this.menuFormNotify.emit(event);
  }

  selectedBtn(event) {
    this.appManagementService._selectedMenuButton.next(event);
    this.showConfigButton(true);
  }

  showConfigButton(event) {
    this.showCofigBtn = event;

    this.changeDetector.detectChanges();
    this.menuBtnConfigNotify.emit(this.showCofigBtn);
  }

  dropUpdateHandler(event) {
    const data = event;
    const feature = data.split(',');

    const newContainer: any = {};
    if (this.pageTemp) {
      this.pageTemp.button_id = feature[feature.length - 1];
      this.pageTempNotify.emit(this.pageTemp);
    } else if (feature[3] === 'botTemp') {
      this.botsService.cloneBot(feature[0]);

      this.mainContainer = [this.splash, this.login, this.profile, this.bubble];
      if (this.myBotsList.length > 0) {
        for (let i = 0; i < this.myBotsList.length; i++) {
          this.mainContainer.push(this.myBotsList[i]);
        }
      }
    } else if (
      feature[4] === '30100' ||
      feature[4] === '30101' ||
      feature[4] === '30102' ||
      feature[4] === '30103'
    ) {
      const appInfo: AppInfo = {};
      switch (feature[4]) {
        case '30100': {
          appInfo.payment_provider = this.paymentProviderTypes.STRIPE;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
        case '30101': {
          appInfo.payment_provider = this.paymentProviderTypes.WEACCEPT;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
        case '30102': {
          appInfo.payment_provider = this.paymentProviderTypes.PAYTABS;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
        case '30103': {
          appInfo.ads_provider = this.paymentProviderTypes.GOOGLEPROVIDER;
          this.appManagementService.updateAppInfo(appInfo);
          break;
        }
      }
    } else if (feature[4] === 'pages' || feature[3] === 'pages') {
      newContainer.page_id = feature[0];
      newContainer.title = feature[1];
      newContainer.icon = 'ic_feeds_books_white_24_dp';
      newContainer.icon_ios = 'ic_feeds_books_white_24_dp';
      newContainer.url = feature[feature.length - 2];
      newContainer.type = this.tabTypes.PAGE;
      newContainer.ui_module_id = '0';
    } else {
      if (feature[4] === '200000') {
        newContainer.options = '1';
      }

      newContainer.icon = 'ic_feeds_books_white_24_dp';
      newContainer.icon_ios = 'ic_feeds_books_white_24_dp';
      newContainer.type = feature[2];
      if (
        newContainer.type === this.tabTypes.CHATS ||
        newContainer.type === this.tabTypes.FEED
      ) {
        newContainer.is_on = feature[3];
        if (newContainer.type === this.tabTypes.FEED) {
          newContainer.menu = this.menu;
        }
      } else if (
        newContainer.type === this.tabTypes.MYLIST ||
        newContainer.type === this.tabTypes.OPENCHAT
      ) {
        newContainer.sub_type = feature[3];
        if (newContainer.sub_type === this.myListTypes.CONTACT) {
          newContainer.show_invite_friends = 1;
        }
        if (newContainer.sub_type === OpenChatTypes.ADMIN) {
          newContainer.chat_id = this.selectedChat;
        }
      } else if (newContainer.type === this.tabTypes.QR_PAGE) {
        if (feature[4] === '10020') {
          newContainer.options = '1';
        } else if (feature[4] === '10021') {
          newContainer.options = '2';
        }
      } else if (newContainer.type === this.tabTypes.QR) {
        newContainer.options = '1';
      }
      newContainer.ui_module_id = feature[4];
    }
    const container: LocalContainer = this.tabContainerList.find(
      res => res.id === feature[feature.length - 1]
    );

    if (
      (this.appScreens.TABS === this.currentPage ||
        this.appScreens.NAV_MENU === this.currentPage) &&
      container
    ) {
      if (newContainer.type === this.tabTybe.MAP) {
        this.menuItem = this.appMenu.find(menu => menu.id === 93);
        newContainer.map_menu_id = this.menuItem.id;
        newContainer.map = this.menuItem.value;
        newContainer.url = this.menuItem.url;
      }
      if (newContainer.type) {
        newContainer.order = this.tabContainerList.length;
      }

      if (feature[4] === '20002') {
        const newParam: any = {};
        newParam.function = 'store';
        newContainer.param = newParam;
        newContainer.sub_type = this.tabTybe.BUNDLE;
        newContainer.store_currency = '';
      }
      // this.addTabs(newContainer, feature[4]);
    } else if (!container && !this.pageTemp) {
      newContainer.layout = 'button';
      newContainer.id = feature[feature.length - 1];
      if (
        newContainer.type === this.tabTypes.FEED ||
        newContainer.type === this.tabTypes.WALLET ||
        newContainer.type === this.tabTypes.MEMBERSHIP
      ) {
        let newChatLabel;

        if (this.chatLabel && this.chatLabel !== null) {
          newChatLabel = this.chatLabel;
        } else {
          newChatLabel = [];
        }
        if (this.chatLabel.length > 0) {
          const newLabels = [];
          this.chatLabel.forEach(label => {
            if (label.id !== newContainer.id) {
              newLabels.push(label);
            }
          });
          const newLabel: any = {};
          newLabel.id = newContainer.id;
          newLabel.label = feature[0];
          newLabel.layout = 'button';
          newLabels.push(newLabel);
          newChatLabel = newLabels;
        } else {
          const newLabel: any = {};
          newLabel.id = newContainer.id;
          newLabel.label = feature[0];
          newLabel.layout = 'button';
          newChatLabel.push(newLabel);
        }
        this.uiService.setChatLabel(newChatLabel);
        this.appManagementService._updatedChatLabel.next(newChatLabel);
      }
      if (feature[4] !== 'pages' && feature[3] !== 'pages') {
        newContainer.title = feature[0];
        newContainer.ui_module_id = feature[4];
      }
      if (feature[4] === '20002') {
        const newParam: any = {};
        newParam.function = 'store';
        newContainer.param = newParam;
        newContainer.sub_type = this.tabTybe.BUNDLE;
        newContainer.store_currency = '';
      }

      this.appManagementService.addContainer(this.tabAppClass, newContainer);
    } else if (!this.pageTemp) {
      newContainer.ui_module_id = feature[4];

      this.appManagementService.updateContainer(container.ref, newContainer);
    }
    if (this.allTabsContainerList && newContainer.layout === 'button') {
      // if (
      //   newContainer.type === this.tabTypes.PAGE ||
      //   newContainer.link === this.tabTypes.PAGE
      // ) {
      //   this.appManagementService._selectedMenuButtonTab.next(newContainer);
      // }
      if (newContainer.page_id) {
        this.appManagementService._pageId.next(newContainer.page_id);
        this.appManagementService._selectedPageId.next(newContainer.page_id);
        this.appManagementService._selectedPageIdToView.next(
          newContainer.page_id
        );
      }
    }
  }

  setMenuItem(container) {
    this.menuItem = this.appMenu.find(menu => menu.id === 93);
    this.updateListEvent(container);
  }

  updateListEvent(container) {
    if (this.menuItem) {
      const tabContainer: LocalContainer = {};
      tabContainer.map_menu_id = this.menuItem.id;
      tabContainer.map = this.menuItem.value;
      tabContainer.url = this.menuItem.url;
      this.appManagementService.updateContainer(container.ref, tabContainer);
    }
  }

  updateApi(value, container) {
    value = value.trim();
    if (value === '') {
      value = null;
    }
    if (this.currentPage == this.appScreens.TABS) {
      const tabContainer: LocalContainer = {};

      tabContainer.api_id = value;
      this.appManagementService.updateContainer(container.ref, tabContainer);
    } else if (this.currentPage == this.appScreens.NAV_MENU) {
      const itemContainer: LocalItem = {};

      itemContainer.api_id = value;
      this.appManagementService.updateItem(container.ref, itemContainer);
    }

    if (
      container.type === this.tabTypes.MENUS ||
      container.link === this.tabTypes.MENUS
    ) {
      const newMenusList = [];
      this.menusAppClass.menus.forEach(menu => {
        let catched = false;
        if (container.menu_id === menu.menu_id) {
          const mainMenu = menu;
          mainMenu.api_id = value;
          newMenusList.push(mainMenu);
        } else {
          if (container.sub_menus) {
            container.sub_menus.forEach(subMenuId => {
              if (subMenuId === menu.menu_id) {
                const subMenu = menu;
                subMenu.api_id = value;
                newMenusList.push(subMenu);
                catched = true;
              }
            });
          }
          if (!catched) {
            newMenusList.push(menu);
          }
        }
      });

      const newMenuAppClass: LocalAppClass = {};
      newMenuAppClass.menus = newMenusList;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        newMenuAppClass
      );
    }
  }

  closeMenuForm() {
    this.closeMenuConfNotify.emit();
  }

  createNewMenu() {
    this.createNewMenuNotify.emit();
  }

  updateTabWithSubMen(event) {
    let contanier: LocalContainer = {};
    this.tab = this.appManagementService.effectiveSelectedTab$.subscribe(
      res => {
        contanier = res;
      }
    );

    const newTabContainer: LocalContainer = {};
    newTabContainer.sub_menus = contanier.sub_menus;
    if (
      (!newTabContainer.sub_menus || newTabContainer.sub_menus.length < 0) &&
      contanier &&
      event &&
      !contanier.sub_type &&
      contanier.menu_id !== event.menu_id &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      newTabContainer.sub_menus = [];
      newTabContainer.sub_menus.push(event.menu_id);
    } else if (
      contanier &&
      event &&
      contanier.menu_id !== event.menu_id &&
      !contanier.sub_type &&
      contanier.sub_menus.indexOf(event.menu_id) < 0 &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      newTabContainer.sub_menus.push(event.menu_id);
    }
    if (
      event &&
      this.findMenuToBeUpdated(event.menu_id) === null &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      !contanier.sub_type &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      this.appManagementService.updateContainer(contanier.ref, newTabContainer);
      const menus = this.menusAppClass.menus;

      menus.push(event);
      const newContanier: any = {};
      newContanier.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        newContanier
      );
    } else if (
      event &&
      this.findMenuToBeUpdated(event.menu_id) !== null &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      !contanier.sub_type &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      this.appManagementService.updateContainer(contanier.ref, newTabContainer);
      const menus = [];
      for (let i = 0; i < this.menusAppClass.menus.length; i++) {
        if (this.findMenuToBeUpdated(event.menu_id) !== i) {
          menus.push(this.menusAppClass.menus[i]);
        } else {
          menus.push(event);
        }
      }

      const newContanier: any = {};
      newContanier.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        newContanier
      );
    }

    this.appManagementService._selectedMenu.next(event);
  }

  updateItemWithSubMen(event) {
    let item: LocalItem = {};
    const itemSub = this.appManagementService.effectiveSelectedItem$.subscribe(
      res => {
        item = res;
      }
    );

    const newItemContainer: LocalItem = {};
    newItemContainer.sub_menus = item.sub_menus;
    if (
      (!newItemContainer.sub_menus || newItemContainer.sub_menus.length < 0) &&
      item &&
      event &&
      item.menu_id !== event.menu_id &&
      !item.sub_type &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      newItemContainer.sub_menus = [];
      newItemContainer.sub_menus.push(event.menu_id);
    } else if (
      item &&
      event &&
      item.menu_id !== event.menu_id &&
      !item.sub_type &&
      item.sub_menus.indexOf(event.menu_id) < 0 &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      newItemContainer.sub_menus.push(event.menu_id);
    }
    if (
      event &&
      !item.sub_type &&
      this.findMenuToBeUpdated(event.menu_id) === null &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      this.appManagementService.updateItem(item.ref, newItemContainer);
      const menus = this.menusAppClass.menus;

      menus.push(event);
      const newContanier: any = {};
      newContanier.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        newContanier
      );
    } else if (
      event &&
      !item.sub_type &&
      this.findMenuToBeUpdated(event.menu_id) !== null &&
      this.menusAppClass &&
      this.menusAppClass.menus &&
      ((this.selectedButtonTab && !this.selectedButtonTab.sub_type) ||
        !this.selectedButtonTab)
    ) {
      this.appManagementService.updateItem(item.ref, newItemContainer);
      const menus = [];
      for (let i = 0; i < this.menusAppClass.menus.length; i++) {
        if (this.findMenuToBeUpdated(event.menu_id) !== i) {
          menus.push(this.menusAppClass.menus[i]);
        } else {
          menus.push(event);
        }
      }

      const newContanier: any = {};
      newContanier.menus = menus;

      this.appManagementService.updateAppClass(
        this.menusAppClass.ref,
        newContanier
      );
    }

    this.appManagementService._selectedMenu.next(event);
  }

  findMenuToBeUpdated(id) {
    if (this.menusAppClass.menus) {
      for (let i = 0; i < this.menusAppClass.menus.length; i++) {
        if (id === this.menusAppClass.menus[i].menu_id) {
          return i;
        }
      }
    }
    return null;
  }

  removeTab(event) {
    this.selectedCategoryNotify.emit(event);
  }

  selectFeature(event) {
    this.selectedFeatureNotify.emit(event);
  }

  showTags(event) {
    if (event.target.checked) {
      this.enableTags = true;
    } else {
      this.enableTags = false;
      if (
        this.tabContainer &&
        this.tabContainer.post_tag_values &&
        this.tabContainer.post_tag_values.length >= 1
      ) {
        const newContainer: LocalContainer = {};
        newContainer.post_tag_values = [];
        this.appManagementService.updateContainer(
          this.tabContainer.ref,
          newContainer
        );
      }
    }

    this.changeDetector.detectChanges();
  }

  showTagsAdmin(event) {
    this.showChatAdmin(event);
    if (event.target.checked) {
      this.enableAdim = true;
    } else {
      this.enableAdim = false;
      if (
        this.tabContainer &&
        this.tabContainer.talk_admin_tag_values &&
        this.tabContainer.talk_admin_tag_values.length >= 1
      ) {
        const newContainer: LocalContainer = {};
        newContainer.talk_admin_tag_values = [];
        this.appManagementService.updateContainer(
          this.tabContainer.ref,
          newContainer
        );
      }
    }

    this.changeDetector.detectChanges();
  }

  showFooter(event, component) {
    const newComponet: LocalComponent = {};
    if (event.target.checked) {
      this.enableFooter = true;
      newComponet.hidden = 0;
    } else {
      this.enableFooter = false;
      newComponet.hidden = 1;
    }
    this.appManagementService.updateComponent(component.ref, newComponet);
    this.changeDetector.detectChanges();
  }

  showSticky(event, component) {
    const newComponet: LocalComponent = {};
    if (event.target.checked) {
      this.enableSticky = true;
      newComponet.sticky_visible = 1;
    } else {
      this.enableSticky = false;
      newComponet.sticky_visible = 0;
    }
    this.appManagementService.updateComponent(component.ref, newComponet);
    this.changeDetector.detectChanges();
  }

  showContext(event, component) {
    const newComponet: LocalComponent = {};
    if (event.target.checked) {
      this.enableContext = true;

      newComponet.hidden = 0;
    } else {
      this.enableContext = false;
      newComponet.hidden = 1;
    }
    this.appManagementService.updateComponent(component.ref, newComponet);
    this.changeDetector.detectChanges();
  }

  get getBorder() {
    if (this.tabAppClass) {
      return {
        border: 'solid 2px ' + this.tabAppClass.bg
      };
    }
  }

  get getBg() {
    if (this.tabAppClass) {
      return {
        'background-color': this.tabAppClass.bg,

        'border-radius': '4px'
      };
    }
  }
  get getBgDwn() {
    if (this.tabAppClass) {
      return {
        'background-color': this.tabAppClass.bg,

        'border-top-right-radius': 'unset',
        'border-top-left-radius': 'unset'
      };
    }
  }
  get getBgsplt() {
    if (this.tabAppClass) {
      return {
        'background-color': this.tabAppClass.bg,
        'object-fit': 'contain'
      };
    }
  }

  updateProfileStatus(event) {
    const appInfo: AppInfo = {};
    if (!event.target.checked) {
      appInfo.hide_profile = 1;
    } else {
      appInfo.hide_profile = 0;
    }
    this.appManagementService.updateAppInfo(appInfo);
  }

  updateIosMoreTitle(event) {
    const appInfo: AppInfo = {};

    appInfo.ios_more_title = event;

    this.appManagementService.updateAppInfo(appInfo);
  }

  updateStartColor(event, section) {
    if (section === this.appScreens.NAV_MENU) {
      const tabbg: TabBg = {};
      if (this.selectedItem && this.selectedItem.tab_bg) {
        tabbg.image = this.selectedItem.tab_bg.image;
        tabbg.end = this.selectedItem.tab_bg.end;
      } else {
        tabbg.image = null;
        tabbg.end = null;
      }
      tabbg.start = event;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;

      this.appManagementService.updateItem(this.selectedItem.ref, newContanier);
    } else if (section === this.appScreens.TABS) {
      const tabbg: TabBg = {};
      if (this.tabContainer && this.tabContainer.tab_bg) {
        tabbg.image = this.tabContainer.tab_bg.image;
        tabbg.end = this.tabContainer.tab_bg.end;
      } else {
        tabbg.image = null;
        tabbg.end = null;
      }
      tabbg.start = event;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        newContanier
      );
    }
  }
  updateEndColor(event, section) {
    if (section === this.appScreens.NAV_MENU) {
      const tabbg: TabBg = {};
      if (this.selectedItem.tab_bg) {
        tabbg.image = this.selectedItem.tab_bg.image;
        tabbg.start = this.selectedItem.tab_bg.start;
      } else {
        tabbg.image = null;
        tabbg.start = null;
      }
      tabbg.end = event;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.selectedItem.tab_bg = tabbg;

      this.appManagementService.updateItem(this.selectedItem.ref, newContanier);
    } else if (section === this.appScreens.TABS) {
      const tabbg: TabBg = {};
      if (this.tabContainer.tab_bg) {
        tabbg.image = this.tabContainer.tab_bg.image;
        tabbg.start = this.tabContainer.tab_bg.start;
      } else {
        tabbg.image = null;
        tabbg.start = null;
      }
      tabbg.end = event;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.tabContainer.tab_bg = tabbg;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        newContanier
      );
    }
  }

  hideWorkFlowBg() {
    this.workFlowBg = false;
    this.changeDetector.detectChanges();
  }

  showWorkFlowBg() {
    this.workFlowBg = true;
    this.changeDetector.detectChanges();
  }
  updateWorkFlowBg(event, section) {
    if (section === this.appScreens.NAV_MENU) {
      const tabbg: TabBg = {};
      if (this.selectedItem.tab_bg) {
        tabbg.end = this.selectedItem.tab_bg.end;
        tabbg.start = this.selectedItem.tab_bg.start;
      } else {
        tabbg.end = null;
        tabbg.start = null;
      }
      tabbg.image = event.url;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.selectedItem.tab_bg = tabbg;

      this.appManagementService.updateItem(this.selectedItem.ref, newContanier);
    } else if (section === this.appScreens.TABS) {
      const tabbg: TabBg = {};
      if (this.tabContainer.tab_bg) {
        tabbg.end = this.tabContainer.tab_bg.end;
        tabbg.start = this.tabContainer.tab_bg.start;
      } else {
        tabbg.end = null;
        tabbg.start = null;
      }
      tabbg.image = event.url;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.tabContainer.tab_bg = tabbg;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        newContanier
      );
    }
    this.workFlowBg = false;
    this.changeDetector.detectChanges();
  }
  get uploadedWorkFlowImage() {
    if (this.currentPage === this.appScreens.NAV_MENU) {
      if (
        this.selectedItem &&
        this.selectedItem !== null &&
        this.selectedItem.tab_bg &&
        this.selectedItem.tab_bg.image
      ) {
        return {
          'background-image': 'url(' + this.selectedItem.tab_bg.image + ')',
          'background-size': '100% 100%'
        };
      }
    }
    if (this.currentPage === this.appScreens.TABS) {
      if (
        this.tabContainer &&
        this.tabContainer !== null &&
        this.tabContainer.tab_bg &&
        this.tabContainer.tab_bg.image
      ) {
        return {
          'background-image': 'url(' + this.tabContainer.tab_bg.image + ')',
          'background-size': '100% 100%'
        };
      }
    }
  }

  deleteTabBgImage(section) {
    if (section === this.appScreens.NAV_MENU) {
      const tabbg: TabBg = {};
      if (this.selectedItem.tab_bg) {
        tabbg.end = this.selectedItem.tab_bg.end;
        tabbg.start = this.selectedItem.tab_bg.start;
      } else {
        tabbg.end = null;
        tabbg.start = null;
      }
      tabbg.image = null;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.selectedItem.tab_bg = tabbg;

      this.appManagementService.updateItem(this.selectedItem.ref, newContanier);
    } else if (section === this.appScreens.TABS) {
      const tabbg: TabBg = {};
      if (this.tabContainer.tab_bg) {
        tabbg.end = this.tabContainer.tab_bg.end;
        tabbg.start = this.tabContainer.tab_bg.start;
      } else {
        tabbg.end = null;
        tabbg.start = null;
      }
      tabbg.image = null;
      const newContanier: any = {};
      newContanier.tab_bg = tabbg;
      this.tabContainer.tab_bg = tabbg;
      this.appManagementService.updateContainer(
        this.tabContainer.ref,
        newContanier
      );
    }
    this.workFlowBg = false;
    this.changeDetector.detectChanges();
  }

  showChatAdmin(event) {
    const newContainer: LocalContainer = {};

    if (event.target.checked) {
      newContainer.floating_button = this.floating_button;
    } else {
      newContainer.floating_button = [];
    }
    this.appManagementService.updateContainer(
      this.tabContainer.ref,
      newContainer
    );
    if (this.tabContainer.layout) {
      let contaner: any = {};
      contaner = this.tabContainer;
      contaner.floating_button = newContainer.floating_button;
      this.appManagementService._selectedMenuButtonTab.next(contaner);
    }
  }
  showNeedHelp(webSite) {
    // this.helpVideoVisibility = b;
    window.open(webSite);
  }

  changeSettingStatus() {}
  addExtraModules(featuresIds) {
    const cashModuleId = '30105';
    const payPalModuleId = '30104';
    const stripeModuleId = '30100';
    const emailModuleId = '30305';
    const noLoginModuleId = '30311';
    const mobileModuleId = '30200';
    const googleAdsModuleId = '30103';
    const appRatingModuleId = '30304';

    if (this.appInfo && this.appInfo.payment_provider) {
      this.appInfo.payment_provider.forEach(provider => {
        if (provider === this.paymentProviderIDs.CASH) {
          featuresIds.push(cashModuleId);
        } else if (provider === this.paymentProviderIDs.PAYPAL) {
          featuresIds.push(payPalModuleId);
        } else if (provider === this.paymentProviderIDs.STRIPE) {
          featuresIds.push(stripeModuleId);
        }
      });
    }
    if (this.appInfo && this.appInfo.login_type === this.loginTypes.EMAIL) {
      featuresIds.push(emailModuleId);
    }
    if (this.appInfo && this.appInfo.login_type === this.loginTypes.NO_LOGIN) {
      featuresIds.push(noLoginModuleId);
    }
    if (this.appInfo && this.appInfo.login_type === this.loginTypes.MSISDN) {
      featuresIds.push(mobileModuleId);
    }

    if (this.appInfo && this.appInfo.show_submitting_app_review) {
      featuresIds.push(appRatingModuleId);
    }

    if (
      this.appInfo &&
      this.appInfo.ads_provider === this.paymentProviderIDs.GOOGLEPROVIDER
    ) {
      featuresIds.push(googleAdsModuleId);
    }

    return featuresIds;
  }

  // onCurrencyOptionsSelected(curr) {
  //   const newContainer: any = {};
  //   newContainer.currency = curr;
  //   if (this.currentPage === this.appScreens.TABS) {
  //     this.appManagementService.updateContainer(
  //       this.tabContainer.ref,
  //       newContainer
  //     );
  //   } else if (this.currentPage === this.appScreens.NAV_MENU) {
  //     this.appManagementService.updateItem(this.selectedItem.ref, newContainer);
  //   }
  // }

  hideSubChatList() {
    this.showSubChatList = false;
  }

  showSubList() {
    this.showSubChatList = true;
  }

  updateStoreEvent(value) {
    this.storeId = value[0].product_id + '';
    const newParam: any = {};
    newParam.function = 'store';
    newParam.id = value[0].product_id;

    if (
      this.selectedItem &&
      this.currentPage === this.appScreens.TABS &&
      !this.selectedButtonTab
    ) {
      const newContainer: LocalContainer = {};
      newContainer.store_id = value[0].product_id + '';
      newContainer.param = newParam;
      this.appManagementService.updateContainer(
        this.selectedItem.ref,
        newContainer
      );
    } else if (
      !this.selectedButtonTab &&
      this.currentPage === this.appScreens.NAV_MENU
    ) {
      const newItem: LocalItem = {};
      newItem.store_id = value[0].product_id + '';
      newItem.param = newParam;
      this.appManagementService.updateItem(this.selectedItem.ref, newItem);
    } else if (this.selectedButtonTab) {
      const newContainer: LocalContainer = {};
      newContainer.store_id = value[0].product_id + '';
      newContainer.param = newParam;
      this.selectedButtonTab.store_id = value[0].product_id + '';
      this.appManagementService._selectedMenuButtonTab.next(
        this.selectedButtonTab
      );
      this.appManagementService.updateContainer(
        this.selectedButtonTab.ref,
        newContainer
      );
    }
    if (
      this.selectedItem &&
      this.selectedItem.sub_type === this.tabTypes.BUNDLE
    ) {
      let getStore = false;
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (
            store.id + '' === value[0].product_id ||
            store.id == value[0].product_id
          ) {
            store.data.shop.forEach(shop => {
              if (shop.menu_id === store.data.menu_id) {
                this.mobileWorkflowService._selectedMenu.next(shop);
                this.appManagementService._selectedMenu.next(shop);
              }
            });
            this.mobileWorkflowService._selectedMenuList.next(store.data.shop);
            this.mobileWorkflowService._selectedStoreId.next(store.id);
            this.mobileWorkflowService._isDefault.next(store.is_default);

            this.mobileWorkflowService._mainMenuId.next(store.data.menu_id);
            getStore = true;
          }
        });
      }

      if (!getStore) {
        this.mobileWorkflowService.getMobileStoreById(value[0].product_id + '');
      }
    }
    this.hideSubChatList();
  }
  useDefaultStore(event) {
    if (event.target.checked) {
      if (
        this.selectedItem &&
        this.currentPage === this.appScreens.TABS &&
        !this.selectedButtonTab
      ) {
        const newParam: any = {};
        newParam.function = 'store';

        const newContainer: LocalContainer = {};
        newContainer.store_id = null;
        newContainer.param = newParam;
        this.appManagementService.updateContainer(
          this.selectedItem.ref,
          newContainer
        );
      } else if (this.selectedButtonTab) {
        const newParam: any = {};
        newParam.function = 'store';

        const newContainer: LocalContainer = {};
        newContainer.store_id = null;
        newContainer.param = newParam;
        this.selectedButtonTab.store_id = undefined;
        this.appManagementService._selectedMenuButtonTab.next(
          this.selectedButtonTab
        );
        this.appManagementService.updateContainer(
          this.selectedButtonTab.ref,
          newContainer
        );
      } else {
        const newItem: LocalItem = this.selectedItem;
        newItem.store_id = null;
        const newParam: any = {};
        newParam.function = 'store';
        newItem.param = newParam;
        this.appManagementService.updateItem(this.selectedItem.ref, newItem);
      }
      this.storeId = null;
      let getStore = false;
      if (this.mobileStoreAppList && this.mobileStoreAppList.length >= 1) {
        this.mobileStoreAppList.forEach(store => {
          if (store.is_default) {
            store.data.shop.forEach(shop => {
              if (shop.menu_id === store.data.menu_id) {
                this.mobileWorkflowService._selectedMenu.next(shop);
                this.appManagementService._selectedMenu.next(shop);
              }
            });
            this.mobileWorkflowService._selectedMenuList.next(store.data.shop);
            this.mobileWorkflowService._selectedStoreId.next(store.id);
            this.mobileWorkflowService._mainMenuId.next(store.data.menu_id);
            this.mobileWorkflowService._isDefault.next(1);

            getStore = true;
          }
        });
      }

      if (!getStore) {
        this.mobileWorkflowService.getMobileStoreById();
      }
    }
  }

  get chatLabelTitle() {
    let title = '';
    this.chatLabel.forEach(label => {
      if (this.tabContainer && this.tabContainer.id === label.id) {
        title = label.label;
      }
    });

    return title;
  }

  updateChatLabel(value) {
    const newChatLabel = [];
    this.chatLabel.forEach(label => {
      if (label.id === this.tabContainer.id) {
        const updatedLabel: any = {};
        updatedLabel.id = label.id;
        updatedLabel.label = value;
        newChatLabel.push(updatedLabel);
      } else {
        newChatLabel.push(label);
      }
    });

    this.appManagementService._updatedChatLabel.next(newChatLabel);
    this.uiService.setChatLabel(newChatLabel);
  }

  updateTabId(id) {
    const newContainer: any = {};
    newContainer.id = id;
    this.selectedItem.id = id;
    this.appManagementService.updateContainer(
      this.selectedItem.ref,
      newContainer
    );
  }

  btnValidation() {
    this.disabledBtn = true;
    this.changeDetector.detectChanges();
  }
  refreshStore() {
    if (this.selectedItem.store_id) {
      this.mobileWorkflowService.getMobileStoreById(this.selectedItem.store_id);
    } else {
      this.mobileWorkflowService.getMobileStoreById();
    }
  }

  adminIconSize(event) {
    const newContainer: any = {};
    if (
      this.tabContainer &&
      this.tabContainer.floating_button &&
      this.tabContainer.floating_button.length > 0
    ) {
      this.floating_button = this.tabContainer.floating_button;
    }
    const floatButton: any = this.floating_button;
    floatButton[0].size = event;
    newContainer.floating_button = floatButton;
    this.appManagementService.updateContainer(
      this.tabContainer.ref,
      newContainer
    );
  }

  adminIconColor(event) {
    const newContainer: any = {};

    if (
      this.tabContainer &&
      this.tabContainer.floating_button &&
      this.tabContainer.floating_button.length > 0
    ) {
      this.floating_button = this.tabContainer.floating_button;
    }
    const floatButton: any = this.floating_button;
    floatButton[0].bg_color = event;
    newContainer.floating_button = floatButton;

    this.appManagementService.updateContainer(
      this.tabContainer.ref,
      newContainer
    );
  }

  checkAdminIconSize(event) {
    if (
      this.tabContainer &&
      this.tabContainer.floating_button &&
      this.tabContainer.floating_button.length > 0 &&
      this.tabContainer.floating_button[0].size === event
    ) {
      return true;
    } else if (!this.tabContainer.floating_button[0].size && event === 'm') {
      return true;
    }
  }
}
