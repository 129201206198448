import {
  GET_MAP_ACTIVE_TRIPS_METHOD,
  ListRequestDetails,
  MERCHANT_PAGE_SIZE,
  PageDirection,
  SCREEN_STATUS
} from './constants';

export interface MapTripsHistoryOpj {
  ms?: number;
  diff?: number;
  id?: string;
  day?: string;
  tid?: string;
  customer?: string;
  object?: string;
  status?: string;
  trip_id?: string;
}

export interface MapTripHistoryUI {
  currentPage?: MapTripsHistoryOpj[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  startDate?: string;
  endDate?: string;
}

export class GetMapTripsHistory {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_MAP_ACTIVE_TRIPS_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public hash?: string,
    public start_date?: string,
    public end_date?: string
  ) {}
}
