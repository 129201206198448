import { ProfileDetails } from '../../../models/ProfileDetails';
import { IMyProfile } from '../../../models/IMyProfile';
import { THEME_COLORS } from '../../../models/constants';

export class MyProfileMiddleware {
  public static initMyProfile(profileDetails: ProfileDetails): IMyProfile {
    const profile: IMyProfile = {};
    profile.id = profileDetails.id;
    profile.name = profileDetails.name;
    profile.status = profileDetails.status;
    profile.version = profileDetails.version;
    profile.relation = profileDetails.profile;
    if (profileDetails.image) {
      profile.imageThumbnail = `data:image/jpeg;base64,${profileDetails.image}`;
    } else {
      profile.imageThumbnail = null;
    }
    if (profileDetails.photo) {
      profile.photo_id = profileDetails.photo.id;
      profile.photo_height = profileDetails.photo.height;
      profile.photo_width = profileDetails.photo.width;
      if (profileDetails.photo.thumbnail) {
        profile.thumbnail_id = profileDetails.photo.thumbnail.id;
        profile.thumbnail_width = profileDetails.photo.thumbnail.width;
        profile.thumbnail_height = profileDetails.photo.thumbnail.height;
      }
    }
    profile.backgroundColor =
      THEME_COLORS[Math.floor(Math.random() * THEME_COLORS.length)];
    return profile;
  }

  public static getProfileByRelation(
    myProfile: IMyProfile,
    state: IMyProfile[]
  ): IMyProfile {
    return state.find(profile => profile.relation === myProfile.relation);
  }
}
