import { Injectable } from '@angular/core';

import {
  ImageFileMeta,
  VideoFileMeta,
  AudioFileMeta
} from 'src/models/MetaData';
import {
  MessageTypes,
  MAX_FILE_SIZE,
  MAX_VIDEO_SIZE
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor() {}

  public async getFileToUpload(event: ISelectedFiles) {
    const file = event.localFile;
    const fileAsArrayBuffer = await this.readFileAsArrayBuffer(file);
    let messageType = this.getMessageTypeFromMimeType(file.type);
    messageType =
      messageType === MessageTypes.GIF && event.localFile.name.endsWith('.mp4')
        ? MessageTypes.VIDEO
        : event.type;

    const mimeType = this.getMimeTypeFromMessageType(messageType);

    const blobUrl = await this.readArrayBufferAsBlobUrl(
      fileAsArrayBuffer,
      messageType
    );

    return {
      fileAsArrayBuffer,
      fileName: event.localFile.name,
      fileSize: event.localFile.size,
      blobUrl,
      mimeType,
      messageType
    };
  }

  /**
   * Read Section
   */
  readStringAsFile(content: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const file = new File([content], '');
      resolve(file);
    });
  }

  readFileAsText(file: File): Promise<string> {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = (event: any) => resolve(event.target.result);
    });
  }

  readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (event: any) => resolve(event.target.result);
    });
  }

  readArrayBufferAsBlobUrl(
    fileAsArrayBuffer: ArrayBuffer,
    mediaType: string
  ): Promise<string> {
    const type = this.getMimeTypeFromMessageType(mediaType);
    return new Promise<string>((resolve, reject) => {
      const dataBlob = new Blob([fileAsArrayBuffer], { type });
      resolve(window.URL.createObjectURL(dataBlob));
    });
  }
  /**************************************************************/

  /**
   * Metadata based on type
   */
  getVideoMetadata(blobUrl: string): Promise<VideoFileMeta> {
    return new Promise<VideoFileMeta>((resolve, reject) => {
      const videoElm = document.createElement('video');
      videoElm.preload = 'metadata';
      videoElm.onloadedmetadata = () => {
        resolve({
          duration: videoElm.duration * 1000,
          width: videoElm.videoWidth,
          height: videoElm.videoHeight
        });
      };
      videoElm.onerror = err => {
        reject(err);
      };
      videoElm.src = blobUrl;
    });
  }

  getAudioMetadata(blobUrl: string): Promise<AudioFileMeta> {
    return new Promise<AudioFileMeta>((resolve, reject) => {
      const audioElm = document.createElement('audio');
      audioElm.preload = 'metadata';
      audioElm.onloadedmetadata = () => {
        resolve({
          duration: audioElm.duration * 1000
        });
      };
      audioElm.onerror = err => {
        reject(err);
      };
      audioElm.src = blobUrl;
    });
  }

  getImageMetadata(blobUrl: string): Promise<ImageFileMeta> {
    return new Promise<ImageFileMeta>((resolve, reject) => {
      const imageElm = new Image();
      imageElm.onload = () => {
        resolve({
          width: imageElm.width,
          height: imageElm.height
        });
      };
      imageElm.onerror = err => {
        reject(err);
      };
      imageElm.src = blobUrl;
    });
  }
  /*************************************************************************************/

  getMimeTypeFromMessageType(msgType: string): string {
    switch (msgType) {
      case MessageTypes.PHOTO:
      case MessageTypes.STICKER:
        return 'image/jpeg';
      case MessageTypes.VIDEO:
        return 'video/mp4';
      case MessageTypes.GIF:
        return 'image/gif';
      case MessageTypes.AUDIO:
        return 'audio/mpeg';
      case MessageTypes.VOICE:
        return 'audio/aac';
      case MessageTypes.TEXT_FILE:
        return 'text/plain';
      case MessageTypes.DOCUMENT:
        return 'application/octet-stream';
      default:
        return '';
    }
  }

  getMessageTypeFromMimeType(mimeType: string): string {
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/png':
        return MessageTypes.PHOTO;
      case 'image/gif':
        return MessageTypes.GIF;
      case 'audio/mid':
      case 'audio/midi':
      case 'audio/mp4':
      case 'audio/mpeg':
      case 'audio/ogg':
      case 'audio/basic':
      case 'audio/mp3':
        return MessageTypes.AUDIO;
      case 'video/mp4':
      case 'video/mpeg':
      case 'video/ogg':
        return MessageTypes.VIDEO;
      case 'text/plain':
      case 'application/rtf':
      case 'application/pdf':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/zip':
      case 'application/x-tar':
      case 'application/x-7z-compressed':
      case 'application/x-zip-compressed':
      case 'application/x-rar-compressed':
      case 'application/vnd.android.package-archive':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return MessageTypes.DOCUMENT;
    }
  }

  isMimeTypeBulkValid(mimeType: string): boolean {
    const allowedExtensions = /(\.csv|\.txt)$/i;
    if (!allowedExtensions.exec(mimeType)) {
      return false;
    } else {
      return true;
    }
    // return (
    //   ['text/plain', 'text/csv', 'application/vnd.ms-excel'].indexOf(
    //     mimeType
    //   ) !== -1
    // );
  }

  isMimeTypeValid(msgType: string, mimeType: string): boolean {
    if (!mimeType) {
      return false;
    } else if (msgType !== MessageTypes.DOCUMENT) {
      return (
        [
          'image/bmp',
          'image/jpeg',
          'image/png',
          'image/gif',
          'audio/mid',
          'audio/midi',
          'audio/mp4',
          'audio/mpeg',
          'audio/ogg',
          'audio/basic',
          'audio/mp3',
          'video/mp4',
          'video/mpeg',
          'video/ogg'
        ].indexOf(mimeType) !== -1
      );
    } else if (msgType === MessageTypes.DOCUMENT) {
      return (
        [
          'text/plain',
          'application/rtf',
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/zip',
          'application/x-tar',
          'application/x-7z-compressed',
          'application/x-zip-compressed',
          'application/x-rar-compressed',
          'application/vnd.android.package-archive',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ].indexOf(mimeType) !== -1
      );
    }
  }

  isFileSizeValid(type: string, size: number): boolean {
    if (
      ((type === MessageTypes.VIDEO || type === MessageTypes.DOCUMENT) &&
        size >= MAX_VIDEO_SIZE) ||
      (type !== MessageTypes.VIDEO &&
        type !== MessageTypes.DOCUMENT &&
        size >= MAX_FILE_SIZE)
    ) {
      return false;
    }
    return true;
  }
}
