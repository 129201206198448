import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  Renderer2,
  OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HelperService } from 'src/app/core/helper.service';

@Component({
  selector: 'app-compose-text-input',
  templateUrl: './compose-text-input.component.html',
  styleUrls: ['./compose-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeTextInputComponent implements OnInit {
  index = 0;

  showColors = true;
  currentBoxStyle: any = {
    'background-color': '#fff',
    'font-size': '1.15em',
    'font-weight': 'normal',
    color: '#2d2d2d',
    display: 'flex',
    fill: '#37507c',
    emojiBtn: '#97a6c8'
  };
  colorsList = [
    '#2d546b',
    '#4a4fe1',
    '#a84c67',
    '#494193',
    '#7d4e42',
    '#1e6b77',
    '#b52e56',
    '#F5A623',
    '#d33540',
    '#4a90e2',
    '#f4644e',
    '#7a92a5',
    '#b6388f',
    '#3cc29a',
    '#f67d41',
    '#40b1c8',
    '#9ccf67',
    '#1d77e1',
    '#7c52a0',
    '#000000'
  ];

  hoverEmoji: boolean;
  currentBackGroundColor = '#fff';

  @ViewChild('textBox', { static: true }) textBox: ElementRef;
  @ViewChild('menuButton', { static: false }) menuButton: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @Input() isMenuAttache = false;

  public _toggleMenu = new BehaviorSubject<boolean>(false);
  public toggleMenu$ = this._toggleMenu.asObservable();

  constructor(
    private _helperService: HelperService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu) {
        const btnClicked = this.menuButton.nativeElement.contains(e.target);
        const menuClicked = this.menu.nativeElement.contains(e.target);
        if (
          this.menu &&
          this.menu.nativeElement &&
          !btnClicked &&
          !menuClicked
        ) {
          this._toggleMenu.next(false);
          this.menuButton.nativeElement.checked = false;
        }
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (this.textBox) {
      // Send message when hit enter not shift key
      if (event.which === 13 || event.keyCode === 13) {
        // event.shiftKey
      }
      if (event.keyCode === 8 || event.keyCode === 46) {
        const htmlContent = this.textBox.nativeElement;
        if (
          (htmlContent.textContent.length === 0 &&
            htmlContent.innerHTML.length === 0) ||
          htmlContent.innerHTML === '<br>'
        ) {
          this.textBox.nativeElement.textContent = '';
        }
      }
    }
  }

  onRichPaste(e) {
    const cData = (e.originalEvent || e).clipboardData;
    const items = (cData && cData.items) || [];
    let text;

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        e.preventDefault();
        return true;
      }
    }

    try {
      text = cData.getData('text/plain');
    } catch (e) {
      return true;
    }
    if (text.length) {
      const textAfter = this._helperService.convertUnifiedAndColonsToEmojiSpan(
        this._helperService.getMultiLine(text)
      );
      document.execCommand('insertHTML', false, textAfter);
      return this._helperService.cancelEvent(e);
    }
    return true;
  }

  emoijNotify($event: string) {
    this.setFocus();
    this._helperService.insertEmojiAtCursor($event);
  }

  setFocus() {
    if (this.textBox) {
      this.textBox.nativeElement.focus();
    }
  }

  lastfocus() {
    this.index = this._helperService.saveSelection();
  }

  checkIfMessageIsShort() {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0 && textToSend.length < 150) {
        this.showColors = true;
      } else if (textToSend && textToSend.length >= 100) {
        this.resetStyles();
        this.showColors = false;
      }
    }
  }

  createNewLine(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      const newValue = '<br><br>';
      document.execCommand('insertHTML', false, newValue);
      return this._helperService.cancelEvent(e);
    }
  }

  resetStyles() {
    this.currentBoxStyle = {
      ...this.currentBoxStyle,
      'background-color': '#fff',
      'font-size': '1.15em',
      'font-weight': 'normal',
      color: '#4b4657',
      display: 'flex',
      fill: '#37507c',
      emojiBtn: '#97a6c8',
      flex: '1',
      height: '100%',
      'align-items': 'unset',
      'justify-content': 'unset',
      'text-align': 'Left'
    };
    this.currentBackGroundColor = '#fff';
    this.setFocus();
  }

  setCurrentBoxBackgroundColor(color: string) {
    this.currentBoxStyle = {
      ...this.currentBoxStyle,
      'background-color': color,
      'font-size': '1.75em',
      'font-weight': '500',
      color: '#ffffff',
      display: 'flex',
      fill: '#fff',
      emojiBtn: '#ffffff',
      flex: '0 0 75%',
      width: '75%',
      'align-items': 'center',
      'justify-content': 'center',
      'text-align': 'center'
    };
    this.currentBackGroundColor = color;
    this._toggleMenu.next(false);
    this.setFocus();
  }

  getTextToSend() {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        return textToSend;
      }
      return null;
    }
    return null;
  }

  get checkForm(): string {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        return textToSend;
      }
    }
    return null;
  }

  get canSend(): boolean {
    if (this.checkForm) {
      return true;
    }
    return false;
  }

  getSelectedColor() {
    if (this.canSend) {
      const textToSend = this.getTextToSend();
      if (textToSend && textToSend.length > 0 && textToSend.length < 150) {
        const color = this.colorsList.filter(
          cl => cl === this.currentBoxStyle['background-color']
        );
        if (color && color.length > 0) {
          return color[0];
        }
        return null;
      } else {
        return null;
      }
    }
  }
  toggleTooltip(e) {
    this.hoverEmoji = e;
  }

  onFocus() {
    if (this.textBox.nativeElement.innerHTML) {
      let range = new Range();

      range.setStartAfter(this.textBox.nativeElement.lastChild);
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }
  }

  get styleLargeText() {
    if (this.currentBackGroundColor !== '#fff') {
      return {
        height: '100%',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      };
    }
    return;
  }

  selectedColor() {
    if (this.currentBackGroundColor !== '#fff') {
      return {
        'background-color': this.currentBackGroundColor,
        'background-image': 'unset'
      };
    }
    return;
  }

  onClick(event) {
    event.stopPropagation();
    if (
      this.menuButton.nativeElement.checked &&
      this.currentBackGroundColor === '#fff'
    ) {
      this._toggleMenu.next(true);
    } else {
      this._toggleMenu.next(false);
      this.resetStyles();
    }
  }
}
