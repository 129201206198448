import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { FileService } from 'src/app/core/file.service';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ERROR_NOT_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes, NOT_SUPPORTED_FILE_TYPE, NO_FILE_NAME, BOOKING_CHANNEL, UPDATE_BOOKING_REF } from 'src/models/constants';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { SetChat, GetSubChats } from 'src/models/IChat';
import { BookingUiDispatchers } from './bookingUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { SetEnableService, SetPaymentService } from 'src/models/BookingUI';
import { BookingsService } from 'src/app/+merchant/+dashboard/bookings/bookings.service';
export class BookingUiEffects {
    constructor(actions$, _socketGateway, _uiDispatchers, _uiBookingDispatchers, _chatDispatchers, _fileService, _uploadGateway, bookingsService, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this._uiBookingDispatchers = _uiBookingDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this.bookingsService = bookingsService;
        this._store = _store;
        this.uploadBookingImage = this.actions$.pipe(ofType("UPLOAD_BOOKING_IMAGE" /* UPLOAD_BOOKING_IMAGE */), withLatestFrom(this._store
            .select(state => state.bookingUiReducer.creationProcessBooking)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.bookingUiReducer.selectedBooking)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.enableSetTemplateBtn)
            .pipe(distinctUntilChanged(isEqual))), map(([val, creationBooking, updatedBooking, enableSetTemplateBtn]) => {
            const action = val;
            if (this.validateFile(action.booking.file, 'image')) {
                let groupID = '';
                if (!enableSetTemplateBtn) {
                    groupID = creationBooking
                        ? creationBooking.id
                        : updatedBooking && updatedBooking.id
                            ? updatedBooking.id
                            : action.booking.chat.id;
                }
                this.handleFileUpload(action.booking, groupID, enableSetTemplateBtn);
            }
        }));
        this.afterBookingCreation = this.actions$.pipe(ofType("UI_UPLOAD_BOOKING_PHOTO" /* UI_UPLOAD_BOOKING_PHOTO */), map((action) => {
            this._chatDispatchers.chatInvalidated(action.createdBooking.id);
        }));
        this.getNextBookingPage = this.actions$.pipe(ofType("UI_BOOKING_GET_NEXT_PAGE" /* UI_BOOKING_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.bookingUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, bookingUiState]) => {
            this._socketGateway.sendSocketMessage(new GetSubChats([BOOKING_CHANNEL], 0, bookingUiState.hash, bookingUiState.eop));
        }));
        this.getPrevBookingPage = this.actions$.pipe(ofType("UI_BOOKING_GET_PREV_PAGE" /* UI_BOOKING_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.bookingUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, bookingUiState]) => {
            this._socketGateway.sendSocketMessage(new GetSubChats([BOOKING_CHANNEL], 1, bookingUiState.hash, bookingUiState.sop));
        }));
        this.afterBookingSectionSelection = this.actions$.pipe(ofType("UI_LIST_BOOKING" /* UI_LIST_BOOKING */), map((action) => {
            this._uiBookingDispatchers.getNextBookingPage();
        }));
        //
        this.afterBookingResetPaging = this.actions$.pipe(ofType("UI_BOOKING_RESET_PAGING" /* UI_BOOKING_RESET_PAGING */), map((action) => {
            this._uiBookingDispatchers.getNextBookingPage();
        }));
        //
        this.setEnableService = this.actions$.pipe(ofType("SET_ENABLE_SERVICE" /* SET_ENABLE_SERVICE */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetEnableService(action.config));
        }));
        this.setPaymentService = this.actions$.pipe(ofType("SET_PAYMENT_SERVICE" /* SET_PAYMENT_SERVICE */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetPaymentService(action.config));
        }));
        this.setChatBooking = this.actions$.pipe(ofType("SET_CHAT_UI_BOOKING" /* SET_CHAT_UI_BOOKING */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetChat(action.chat));
        }));
        this.setChannelChat = this.actions$.pipe(ofType("UPDATE_BOOKING_AFTER_CREATION" /* UPDATE_BOOKING_AFTER_CREATION */), withLatestFrom(this._store
            .select(state => state.bookingUiReducer.file)
            .pipe(distinctUntilChanged(isEqual))), map(([val, file]) => {
            const action = val;
            const chat = Object.assign({}, file.booking, { id: action.data.chat_id });
            const evntObject = Object.assign({}, file);
            evntObject.chat = chat;
            if (file.periods) {
                const bookingPeriodsForm = {
                    chat_id: chat.id,
                    data: file.periods
                };
                this.bookingsService.createBookingPeriods(bookingPeriodsForm);
                this.bookingsService.updateBookingHolidays(chat.id, file.holidays);
            }
            this._uiBookingDispatchers.uploadBookingImage(evntObject);
            // this._socketGateway.sendSocketMessage(new SetChat(chat));
        }));
    }
    handleFileUpload(booking, chatId, enableSetTemplateBtn) {
        this._fileService
            .readFileAsArrayBuffer(booking.file.localFile)
            .then(fileAsArrayBuffer => {
            this._uploadGateway
                .uploadWithProgress(fileAsArrayBuffer, booking.file.localFile.type, booking.file.localFile.name, false, null, null, chatId, true, false, '', false, false, 0, '1x1')
                .subscribe(event => {
                if (event.type === HttpEventType.Response) {
                    const res = event.body;
                    let chat = {};
                    const photo = { id: res.file };
                    chat = Object.assign({}, chat, booking.booking);
                    chat.id = chatId;
                    chat.photo = photo;
                    this.bookingsService.showEditBookingScreen(chat);
                    this.bookingsService._wattingForUploadImage.next(false);
                    this._socketGateway.sendSocketMessage(new SetChat(chat, UPDATE_BOOKING_REF));
                    // if (enableSetTemplateBtn) {
                    //   const id = chat.id;
                    //   const type = chat.type.toLocaleLowerCase();
                    //   const tempChat = { ...chat };
                    //   delete tempChat['id'];
                    //   this._uiDispatchers.setChatToTemplate(id, type, tempChat);
                    // }
                }
            }, error => {
                console.log('Failed to upload booking image');
            });
        });
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "uploadBookingImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "afterBookingCreation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "getNextBookingPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "getPrevBookingPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "afterBookingSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "afterBookingResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "setEnableService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "setPaymentService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "setChatBooking", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], BookingUiEffects.prototype, "setChannelChat", void 0);
