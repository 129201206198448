import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import * as SubMenuUIActionTypes from './subMenuUI.actions';
import { SubMenuStore } from './../../../models/SubMenu';

@Injectable({
  providedIn: 'root'
})
export class SubMenuUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createSubMenu(storeSubMenuDetails: SubMenuStore): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.CreateSubMenu(storeSubMenuDetails)
    );
  }

  setSubMenu(storeSubMenuID: string, storeSubMenuDetails: SubMenuStore): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.SetSubMenu(storeSubMenuID, storeSubMenuDetails)
    );
  }

  addSubMenuItem(storeSubMenuID: string, itemIDs: any[]): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.AddSubMenuItem(storeSubMenuID, itemIDs)
    );
  }

  RemoveSubMenuItem(storeSubMenuID: string, itemIDs: string[]): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.RemoveSubMenuItem(storeSubMenuID, itemIDs)
    );
  }

  // paging dispatchers
  getNextSubMenuPage(): void {
    this._store.dispatch(new SubMenuUIActionTypes.GetNextSubMenuPage());
  }

  getPrevSubMenuPage(): void {
    this._store.dispatch(new SubMenuUIActionTypes.GetPrevSubMenuPage());
  }

  receivedSubMenuHistoryChunk(
    eop: number,
    sop: number,
    currentPage: SubMenuStore[]
  ): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.ReceivedSubMenuHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  subMenuStoreReceived(subMenuStore: SubMenuStore): void {
    this._store.dispatch(
      new SubMenuUIActionTypes.SubMenuStoreReceived(subMenuStore)
    );
  }

  resetSubMenuPaging(): void {
    this._store.dispatch(new SubMenuUIActionTypes.ResetSubMenuPaging());
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new SubMenuUIActionTypes.SetStatusAsIdle());
  }
}
