import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { DashboardUI, WidgetUI } from 'src/models/summaryUI';
import * as SummaryUIActions from './summaryUI.actions';

@Injectable({
  providedIn: 'root'
})
export class SummaryUIDispatchers {
  constructor(private _store: Store<AppState>) {}

  setDashboard(dashboard: DashboardUI) {
    this._store.dispatch(new SummaryUIActions.SetDashboard(dashboard));
  }

  createNewWidget(widget: WidgetUI) {
    this._store.dispatch(new SummaryUIActions.CreateNewWidget(widget));
  }

  updateWidget(widget: WidgetUI) {
    this._store.dispatch(new SummaryUIActions.UpdateWidget(widget));
  }

  getUserDashboardList() {
    this._store.dispatch(new SummaryUIActions.GetUserDashboardList());
  }

  reOrderList(widgets: WidgetUI[]) {
    this._store.dispatch(new SummaryUIActions.ReOrderList(widgets));
  }

  updateDashboardList() {
    this._store.dispatch(new SummaryUIActions.UpdateDashboardList());
  }

  receivedDashboardHistoryChunk(dashboard: DashboardUI) {
    this._store.dispatch(
      new SummaryUIActions.ReceivedDashboardHistoryChunk(dashboard)
    );
  }

  saveDefaultedDashboardList() {
    this._store.dispatch(new SummaryUIActions.SaveDefaultedDashboardList());
  }

  removeWidget(widget: WidgetUI) {
    this._store.dispatch(new SummaryUIActions.RemoveWidget(widget));
  }
}
