import {
  GET_PRODUCT_METHOD,
  GET_PRODUCTS_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_PRODUCT_METHOD,
  SET_PRODUCT_METHOD,
  CREATE_PRODUCT_REF,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  ADD_INVENTORY_METHOD,
  REMOVE_PRODUCT_METHOD
} from './constants';
import { StoreMenu } from './StoreMenu';

export interface Product {
  id?: string;
  date?: string;
  service_profile?: string;
  image?: string;
  params?: object;
  version?: string;
  url?: string;
  group_id?: string;
  price?: string;
  balance?: string;
  name?: string;
  sku?: string;
  desc?: string;
  status?: string;
  currency?: string;
  photo_id?: string;
  display_name?: string;
  store_menu_id?: string;
  store_menu?: StoreMenu;
  max_unit?: number;
  area?: string;
  classification?: string;
  category?: string;
  keywords?: string;
  recordIndex?: number;
  reference?: string;
  localMedia?: string;
  owner?: string;
  period?: string;
  period_number?: number;
}

export interface ProductUI {
  currentPage?: Product[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  productGroupId?: number;
  ownerGroupId?: string;
}

export class GetProductMethod {
  readonly method = GET_PRODUCT_METHOD;
  constructor(
    public id: string,
    public bundle_id?: string,
    public product_group_id?: number
  ) {}
}

export class GetProductsMethod {
  readonly page_size = MERCHANT_PAGE_SIZE;
  readonly method = GET_PRODUCTS_METHOD;
  constructor(
    public direction?: number,
    public reference?: number,
    public product_group_id?: number,
    public owner_group_id?: string
  ) {}
}

export class CreateProductMethod {
  readonly method = CREATE_PRODUCT_METHOD;
  readonly reference = CREATE_PRODUCT_REF;
  constructor(productToBeCreated: Product) {
    for (const key in productToBeCreated) {
      if (productToBeCreated.hasOwnProperty(key)) {
        this[key] = productToBeCreated[key];
      }
    }
  }
}

export class SetProductMethod {
  readonly method = SET_PRODUCT_METHOD;
  constructor(productToBeCreated: Product) {
    for (const key in productToBeCreated) {
      if (productToBeCreated.hasOwnProperty(key)) {
        this[key] = productToBeCreated[key];
      }
    }
  }
}

export class AddInventoryMethod {
  readonly method = ADD_INVENTORY_METHOD;
  constructor(public product_id: string, public units: number) {}
}

export class RemoveProductMethod {
  readonly method = REMOVE_PRODUCT_METHOD;
  constructor(public id: string) {}
}
