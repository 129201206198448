import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as HistoryRepliesActions from './repliesEOP.actions';

import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class RepliesEOPDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateFirstDepthEOP(parentMsgID, eopFirstDepth, chatID): void {
    this._store.dispatch(
      new HistoryRepliesActions.UpdateFirstDepthEOP(
        parentMsgID,
        eopFirstDepth,
        chatID
      )
    );
  }

  updateSecondDepthEOP(parentMsgID, eopSecondDepth, userID, chatID): void {
    this._store.dispatch(
      new HistoryRepliesActions.UpdateSecondDepthEOP(
        parentMsgID,
        eopSecondDepth,
        userID,
        chatID
      )
    );
  }
}
