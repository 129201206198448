import { Routes } from '@angular/router';
import { AuthComponent } from './+auth/auth.component';
import { SupportLogInComponent } from './+auth/support-log-in/support-log-in.component';
import { RegistrationComponent } from './+registration/registration.component';
import { VerifyAccountComponent } from './+registration/verify-account/verify-account.component';
const ɵ0 = { title: 'The Native App Builder Login' }, ɵ1 = { title: 'The Native App Builder Signup' }, ɵ2 = { title: 'The Native App Builder Signup' };
const routes = [
    { path: '', redirectTo: '', pathMatch: 'full' },
    {
        path: 'login',
        data: ɵ0,
        component: AuthComponent
    },
    {
        path: 'signup',
        data: ɵ1,
        component: RegistrationComponent
    },
    {
        path: 'signup/:id',
        data: ɵ2,
        component: RegistrationComponent
    },
    { path: 'spt', component: SupportLogInComponent },
    {
        path: 'verifyCode',
        component: VerifyAccountComponent
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
