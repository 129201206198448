import { Store } from '@ngrx/store';
import * as WhitelistUIActionTypes from './whitelistMainUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class WhitelistUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetPaging(name) {
        this._store.dispatch(new WhitelistUIActionTypes.ResetWhitelistPaging(name));
    }
    getNextWhitelistPage() {
        this._store.dispatch(new WhitelistUIActionTypes.GetNextWhitelistPage());
    }
    getPrevWhitelistPage() {
        this._store.dispatch(new WhitelistUIActionTypes.GetPrevWhitelistPage());
    }
    addSignupIDToWhitelist(chat_id, users) {
        this._store.dispatch(new WhitelistUIActionTypes.AddSignupIDToWhitelist(chat_id, users));
    }
    addWhitelistSuccess(users) {
        this._store.dispatch(new WhitelistUIActionTypes.AddWhitelistSuccess(users));
    }
    deleteUsersFromWhitelist(chat_id, users) {
        this._store.dispatch(new WhitelistUIActionTypes.DeleteUsersFromWhitelist(users, chat_id));
    }
    deleteUsersFromWhitelistSuccess(users) {
        this._store.dispatch(new WhitelistUIActionTypes.DeleteUsersFromWhitelistSuccess(users));
    }
    receivedWhitelistHistoryChunk(eop, sop, currentPage, hash) {
        this._store.dispatch(new WhitelistUIActionTypes.ReceivedWhitelistHistoryChunk(eop, sop, currentPage, hash));
    }
    setStatusAsIdle() {
        this._store.dispatch(new WhitelistUIActionTypes.SetStatusAsIdle());
    }
    setLoadingScreenStatus(status) {
        this._store.dispatch(new WhitelistUIActionTypes.SetLoadingScreenStatus(status));
    }
    reset() {
        this._store.dispatch(new WhitelistUIActionTypes.ResetWhitelist());
    }
}
WhitelistUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhitelistUiDispatchers_Factory() { return new WhitelistUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: WhitelistUiDispatchers, providedIn: "root" });
