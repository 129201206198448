import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  OnChanges
} from '@angular/core';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';
import {
  FeaturesCategories,
  TabTypes,
  ChannelAppScreens,
  UISections,
  MENU_BOTTON_STYLE,
  MENU_CELLS_CATEGORIES
} from 'src/models/constants';
import { AuthService } from './../../../../../../core/auth.service';
import { AppMgmtService } from './../../../appMgmt.service';
import { BUNDLES } from './../../../../../../../models/AppSections';
@Component({
  selector: 'app-features-categories',
  templateUrl: './features-categories.component.html',
  styleUrls: ['./features-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesCategoriesComponent implements OnInit, OnChanges {
  @Input() currentPage: any;
  appScreen = ChannelAppScreens;
  categoryList: FeaturesCategories[];
  social: FeaturesCategories = {};
  productivity: FeaturesCategories = {};
  ecommerce: FeaturesCategories = {};
  financepayment: FeaturesCategories = {};
  essentials: FeaturesCategories = {};
  maptracking: FeaturesCategories = {};
  ordermanagement: FeaturesCategories = {};
  workFlow: FeaturesCategories = {};
  appTheme: FeaturesCategories = {};
  mStore: FeaturesCategories = {};
  payment: FeaturesCategories = {};
  color: FeaturesCategories = {};
  secuirtyprivacy: FeaturesCategories = {};
  advertisements: FeaturesCategories = {};
  uiSections = UISections;
  accounts: FeaturesCategories = {};
  @Input() currentCategory: any;
  @Input() selectedFeature: any;
  @Input() isNandbox: boolean;
  @Input() section: any;
  @Output()
  featureNotify = new EventEmitter<any>();
  tabTypes = TabTypes;
  menuCategories = MENU_CELLS_CATEGORIES;
  @Input() polls;
  @Input() menu;
  @Input() menuShow: any;
  @Input() tabType: any;
  @Input() createNewTab: any;
  @Input() showSettings: any;
  @Input() selectedType: any;
  @Input() selectedMenuButtonTab: any;
  buildMenuscat = false;
  enterdNav = false;
  enterdTab = false;
  enterdWorkFlow = false;
  btnWorkFlow = false;
  constructor(
    private _ref: ChangeDetectorRef,
    public layoutService: LayoutService,
    public authService: AuthService,
    public appMngmentService: AppMgmtService
  ) {}
  ngOnChanges() {
    const menucatOne: any = {};
    menucatOne.title = 'Buttons';
    menucatOne.icon = 'icon-button-cell';
    menucatOne.selectedIcon = 'icon-selected-button-cell';
    menucatOne.background = '';
    menucatOne.backgroundClicked = '';
    menucatOne.id = this.menuCategories.BUTTON;
    if (this.menu && !this.buildMenuscat && !this.enterdWorkFlow) {
      const menucatTwo: any = {};

      menucatTwo.title = 'Input Cells';
      menucatTwo.icon = 'icon-input-cell';
      menucatTwo.selectedIcon = 'icon-selected-input-cell';

      menucatTwo.background = '';
      menucatTwo.backgroundClicked = '';
      menucatTwo.id = this.menuCategories.INPUT;

      const menucatThree: any = {};

      menucatThree.title = 'Output Cells';

      menucatThree.icon = 'icon-output-cell';

      menucatThree.selectedIcon = 'icon-selected-output-cell';
      menucatThree.background = '';
      menucatThree.backgroundClicked = '';
      menucatThree.id = this.menuCategories.OUTPUT;

      const menucaTFour: any = {};

      menucaTFour.title = 'Submit';
      menucaTFour.icon = 'icon-submit-cell';
      menucaTFour.selectedIcon = 'icon-selected-submit-cell';

      menucaTFour.background = '';
      menucaTFour.backgroundClicked = '';
      menucaTFour.id = this.menuCategories.SUBMIT;

      const menucaTFive: any = {};

      menucaTFive.title = 'Separators Cell';
      menucaTFive.icon = 'icon-separator';
      menucaTFive.selectedIcon = 'icon-selected-separator';
      menucaTFive.background = '';
      menucaTFive.backgroundClicked = '';
      menucaTFive.id = this.menuCategories.SEPARATOR;

      const menucaTSix: any = {};

      menucaTSix.title = 'Single Choice';
      menucaTSix.icon = 'icon-single-choice';
      menucaTSix.selectedIcon = 'icon-selected-single-choice';
      menucaTSix.background = '';
      menucaTSix.backgroundClicked = '';
      menucaTSix.id = this.menuCategories.SINGLE_CHOICE;

      const menucaTSevent: any = {};

      menucaTSevent.title = 'Multiple Choice';
      menucaTSevent.icon = 'icon-multi-choice';
      menucaTSevent.selectedIcon = 'icon-selected-multi-choice';
      menucaTSevent.background = '';
      menucaTSevent.backgroundClicked = '';
      menucaTSevent.id = this.menuCategories.MULTI_CHOICE;

      const menuCatSelect: any = {};
      menuCatSelect.title = 'Dropdown Menus';
      menuCatSelect.icon = 'dropdown';
      menuCatSelect.selectedIcon = 'dropdown-selected';
      menuCatSelect.background = '';
      menuCatSelect.backgroundClicked = '';
      menuCatSelect.id = this.menuCategories.SELECT_CHOICE;

      // const menucatProd: any = {};
      // menucatProd.title = 'Products';
      // menucatProd.icon = 'icon-multi-choice';
      // menucatProd.selectedIcon = 'icon-selected-multi-choice';
      // menucatProd.background = '';
      // menucatProd.backgroundClicked = '';
      // menucatProd.id = this.menuCategories.PRODUCT;

      const menucatCollection: any = {};
      menucatCollection.title = 'Collections';
      menucatCollection.icon = 'icon-multi-choice';
      menucatCollection.selectedIcon = 'icon-selected-multi-choice';
      menucatCollection.background = '';
      menucatCollection.backgroundClicked = '';
      menucatCollection.id = this.menuCategories.COLLECTION;
      // const menucaTEight: any = {};

      // menucaTEight.title = 'Switches Toggle';
      // menucaTEight.icon = 'icon-toggle-choice';
      // menucaTEight.selectedIcon = 'icon-selected-toggle-choice';
      // menucaTEight.background = '';
      // menucaTEight.backgroundClicked = '';
      // menucaTEight.id = this.menuCategories.TOGGLE_CHOICE;

      if (
        (this.selectedType &&
          this.selectedType.sub_type &&
          this.selectedType.sub_type === this.tabTypes.BUNDLE) ||
        this.section === this.uiSections.MOBILESTORE ||
        (this.selectedMenuButtonTab &&
          this.selectedMenuButtonTab.type === this.tabTypes.MENUS)
      ) {
        this.categoryList = [menucaTFive, menucatOne, menucatCollection];
      } else {
        this.categoryList = [
          menucaTFive,
          menucatOne,
          menucatTwo,
          menucatThree,
          menucaTSix,
          menucaTSevent,
          menucaTFour,
          menuCatSelect
        ];
      }
      // this.selectedFeature = menucatOne.id;

      this.currentCategory = menucaTFive;

      this.appMngmentService._selectedFeature.next(menucaTFive);

      this.enterdNav = false;
      this.enterdTab = false;
      this.enterdWorkFlow = true;
      this.btnWorkFlow = false;
      this.featureNotify.emit(menucaTFive);
      this.buildMenuscat = true;
      this._ref.detectChanges();
    } else if (!this.menu && this.currentCategory && !this.menuShow) {
      if (this.currentPage === this.appScreen.TABS && !this.enterdTab) {
        this.categoryList = [
          this.social,
          this.productivity,
          this.essentials,
          this.maptracking,
          this.secuirtyprivacy,
          this.ordermanagement,
          this.mStore,
          this.workFlow
        ];
        this.enterdNav = false;
        this.enterdTab = true;
        this.enterdWorkFlow = false;
        this.btnWorkFlow = false;
        // this.selectedFeature = this.social.id;

        this.appMngmentService._selectedFeature.next(this.social);
        this.featureNotify.emit(this.social);
      } else if (
        this.currentPage === this.appScreen.NAV_MENU &&
        !this.enterdNav
      ) {
        if (!this.showSettings) {
          this.categoryList = [
            this.social,
            this.productivity,
            this.essentials,
            this.maptracking,
            this.secuirtyprivacy,
            this.ordermanagement,
            this.mStore,
            this.workFlow
          ];
        } else {
          this.categoryList = [
            this.social,
            this.productivity,
            this.essentials,
            this.maptracking,
            this.secuirtyprivacy,
            this.ordermanagement,
            this.mStore
          ];
        }
        this.enterdNav = true;
        this.enterdTab = false;
        this.enterdWorkFlow = false;
        this.btnWorkFlow = false;
        // this.selectedFeature = this.social.id;

        this.appMngmentService._selectedFeature.next(this.social);
        this.featureNotify.emit(this.social);
      }
      this.buildMenuscat = false;

      this._ref.detectChanges();
    } else if (!this.menu && this.menuShow && !this.btnWorkFlow) {
      this.categoryList = [
        this.social,
        this.productivity,
        this.essentials,
        this.maptracking,
        this.secuirtyprivacy,
        this.ordermanagement,
        this.mStore
      ];
      this.buildMenuscat = false;
      this.enterdNav = false;
      this.enterdTab = false;
      this.enterdWorkFlow = false;
      this.btnWorkFlow = true;

      // this.selectedFeature = this.social.id;

      this.appMngmentService._selectedFeature.next(this.social);
      this.featureNotify.emit(this.social);

      this._ref.detectChanges();
    }
  }

  ngOnInit() {
    this.ordermanagement.title = 'Bookings & Events';

    this.ordermanagement.icon = 'icon-ticket';
    this.ordermanagement.selectedIcon = 'icon-ticket-selected';
    this.ordermanagement.background = 'bookingbg.jpg';
    this.ordermanagement.backgroundClicked = 'bookingclicked.jpg';
    this.ordermanagement.id = 'ordermanagement';

    this.workFlow.title = 'Workflow';

    this.workFlow.icon = 'icon-workflow';
    this.workFlow.selectedIcon = 'icon-workflow-selected';
    this.workFlow.background = 'bookingbg.jpg';
    this.workFlow.backgroundClicked = 'bookingclicked.jpg';
    this.workFlow.id = 'workflow';

    this.productivity.title = 'Communication';

    this.productivity.icon = 'icon-Productivity';
    this.productivity.selectedIcon = 'icon-Productivity-selected';
    this.productivity.background = 'postbg.jpg';
    this.productivity.backgroundClicked = 'postclicked.jpg';
    this.productivity.id = 'productivity';

    this.essentials.title = 'Essentials';

    this.essentials.icon = 'icon-Essentials';
    this.essentials.selectedIcon = 'icon-Essentials-selected';

    this.essentials.background = 'essentialsbg.jpg';
    this.essentials.backgroundClicked = 'searchclicked.jpg';
    this.essentials.id = 'essentials';

    this.maptracking.title = 'Maps';

    this.maptracking.icon = 'icon-MAPTracking';
    this.maptracking.selectedIcon = 'icon-MAPTracking-selected';
    this.maptracking.background = 'mapbg.jpg';
    this.maptracking.backgroundClicked = 'mapclicked.jpg';
    this.maptracking.id = 'maptracking';

    this.mStore.title = 'Mobile Store';

    this.mStore.icon = 'icon-Store';
    this.mStore.selectedIcon = 'icon-Store-selected';
    this.mStore.background = 'storebg.jpg';
    this.mStore.backgroundClicked = 'webviewclicked.jpg';
    this.mStore.id = 'mStore';

    this.secuirtyprivacy.title = 'Security & Privacy';

    this.secuirtyprivacy.icon = 'icon-SecuirtyPrivacy';
    this.secuirtyprivacy.selectedIcon = 'icon-SecuirtyPrivacy-selected';
    this.secuirtyprivacy.background = 'secuirtybg.jpg';
    this.secuirtyprivacy.backgroundClicked = 'webviewclicked.jpg';
    this.secuirtyprivacy.id = 'secuirtyprivacy';

    this.social.title = 'Social';
    this.social.icon = 'icon-Social';
    this.social.selectedIcon = 'icon-Social-selected';
    this.social.background = 'socialbg.jpg';
    this.social.backgroundClicked = 'socialclicked.jpg';
    this.social.id = 'social';

    this.accounts.title = 'Accounts';

    this.accounts.icon = 'icon-Essentials';
    this.accounts.selectedIcon = 'icon-Essentials-selected';
    this.accounts.background = 'searchbg.jpg';
    this.accounts.backgroundClicked = 'searchclicked.jpg';
    this.accounts.id = 'secuirty';

    this.advertisements.title = 'Advertisements';

    this.advertisements.icon = 'icon-Ads';
    this.advertisements.selectedIcon = 'icon-Ads-selected';
    this.advertisements.background = 'searchbg.jpg';
    this.advertisements.backgroundClicked = 'searchclicked.jpg';
    this.advertisements.id = 'ads';

    // this.general.title = 'General';
    // this.general.subTitle = 'Includes 2 modules';
    // this.general.icon = 'icon-General';
    // this.general.modules = [10008,10011];
    // this.general.background = 'bookingbg.jpg';
    // this.general.backgroundClicked = 'bookingclicked.jpg';
    // this.general.id = 'appTheme';

    this.payment.title = 'Payment Options';

    this.payment.icon = 'icon-FinancePayment';

    this.payment.background = 'searchbg.jpg';
    this.payment.backgroundClicked = 'searchclicked.jpg';
    this.payment.id = 'payment';

    if (this.currentPage === this.appScreen.MAIN) {
      this.accounts.modules = [10009, 10010];
      this.advertisements.modules = [30103];
      this.payment.modules = [30100, 30101, 30102, 30104, 30105];
      this.accounts.subTitle =
        'Includes ' + this.accounts.modules.length + ' modules';
      this.payment.subTitle =
        'Includes ' + this.payment.modules.length + ' modules';
      this.advertisements.subTitle =
        'Includes ' + this.advertisements.modules.length + ' modules';
      this.categoryList = [
        this.appTheme,
        this.advertisements,
        this.payment,
        this.accounts
      ];

      // this.selectedFeature = this.appTheme.id;

      this.appMngmentService._selectedFeature.next(this.appTheme);
      this.featureNotify.emit(this.appTheme.modules);
    } else if (this.isNandbox) {
      this.social.subTitle = 'Includes 2 modules';
      this.social.modules = [505, 700];
      this.essentials.subTitle = 'Includes 1 module';
      this.essentials.modules = [1002];
      this.ordermanagement.subTitle = 'Includes 4 modules';
      this.ordermanagement.modules = [515, 1200, 10021, 10020];
      this.mStore.subTitle = 'Includes 1 module';
      this.mStore.modules = [20002];
      this.maptracking.modules = [1101];
      this.maptracking.subTitle = 'Includes 1 module';
      this.categoryList = [
        this.social,
        this.essentials,
        this.maptracking,
        this.ordermanagement,
        this.mStore
      ];

      if (!this.selectedFeature) {
        this.appMngmentService._selectedFeature.next(this.social);
        this.featureNotify.emit(this.social);
      } else {
        this.currentCategory = this.categoryList.filter(
          res => res.id === this.selectedFeature.id
        );
        this.featureNotify.emit(this.currentCategory[0]);
      }
    } else if (!this.isNandbox) {
      this.ordermanagement.modules = [515, 600, 1200, 10020, 10021];
      this.productivity.modules = [500, 502, 503, 508, 511, 700000];
      this.essentials.modules = [900, 1000, 1001, 1002];
      this.maptracking.modules = [1100, 1101];
      this.mStore.modules = [20002];
      this.secuirtyprivacy.modules = [800];
      this.workFlow.subTitle = 'Includes 1 module';
      this.workFlow.modules = [20001];
      this.social.modules = [
        501,
        507,
        516,
        517,
        509,
        512,
        510,
        505,
        518,
        504,
        700,
        519
      ];

      this.ordermanagement.subTitle =
        'Includes ' + this.ordermanagement.modules.length + ' modules';
      this.productivity.subTitle =
        'Includes ' + this.productivity.modules.length + ' modules';
      this.essentials.subTitle =
        'Includes ' + this.essentials.modules.length + ' modules';
      this.maptracking.subTitle =
        'Includes ' + this.maptracking.modules.length + ' modules';
      this.mStore.subTitle =
        'Includes ' + this.mStore.modules.length + ' module';
      this.secuirtyprivacy.subTitle =
        'Includes ' + this.secuirtyprivacy.modules.length + ' module';
      this.social.subTitle =
        'Includes ' + this.social.modules.length + ' modules';
      if (this.currentPage === this.appScreen.TABS && !this.showSettings) {
        this.categoryList = [
          this.social,
          this.productivity,
          this.essentials,
          this.maptracking,
          this.secuirtyprivacy,
          this.ordermanagement,
          this.mStore,
          this.workFlow
        ];
      } else if (
        this.currentPage === this.appScreen.NAV_MENU &&
        !this.showSettings
      ) {
        this.categoryList = [
          this.social,
          this.productivity,
          this.essentials,
          this.maptracking,
          this.secuirtyprivacy,
          this.ordermanagement,
          this.mStore,
          this.workFlow
        ];
      }
      if (this.menu && this.section !== this.uiSections.MENUS) {
        const menucatOne: any = {};

        menucatOne.title = 'Buttons';
        menucatOne.icon = 'icon-button-cell';
        menucatOne.selectedIcon = 'icon-selected-button-cell';
        menucatOne.background = '';
        menucatOne.backgroundClicked = '';
        menucatOne.id = this.menuCategories.BUTTON;

        const menucatTwo: any = {};

        menucatTwo.title = 'Input Cells';
        menucatTwo.icon = 'icon-output-cell';
        menucatTwo.selectedIcon = 'icon-selected-output-cell';
        menucatTwo.background = '';
        menucatTwo.backgroundClicked = '';
        menucatTwo.id = this.menuCategories.INPUT;

        const menucatThree: any = {};

        menucatThree.title = 'Output Cells';
        menucatThree.icon = 'icon-input-cell';
        menucatThree.selectedIcon = 'icon-selected-input-cell';
        menucatThree.background = '';
        menucatThree.backgroundClicked = '';
        menucatThree.id = this.menuCategories.OUTPUT;

        const menucaTFour: any = {};

        menucaTFour.title = 'Submit';
        menucaTFour.icon = 'icon-submit-cell';
        menucaTFour.selectedIcon = 'icon-selected-submit-cell';
        menucaTFour.background = '';
        menucaTFour.backgroundClicked = '';
        menucaTFour.id = this.menuCategories.SUBMIT;

        const menucaTFive: any = {};

        menucaTFive.title = 'Separators Cell';
        menucaTFive.icon = 'icon-separator';
        menucaTFive.selectedIcon = 'icon-selected-separator';
        menucaTFive.background = '';
        menucaTFive.backgroundClicked = '';
        menucaTFive.id = this.menuCategories.SEPARATOR;

        const menucaTSix: any = {};

        menucaTSix.title = 'Single Choice';
        menucaTSix.icon = 'icon-single-choice';
        menucaTSix.selectedIcon = 'icon-selected-single-choice';
        menucaTSix.background = '';
        menucaTSix.backgroundClicked = '';
        menucaTSix.id = this.menuCategories.SINGLE_CHOICE;

        const menucaTSevent: any = {};

        menucaTSevent.title = 'Multiple Choice';
        menucaTSevent.icon = 'icon-multi-choice';
        menucaTSevent.selectedIcon = 'icon-selected-multi-choice';
        menucaTSevent.background = '';
        menucaTSevent.backgroundClicked = '';
        menucaTSevent.id = this.menuCategories.MULTI_CHOICE;

        const menuCatSelect: any = {};
        menuCatSelect.title = 'Dropdown Menus';
        menuCatSelect.icon = 'dropdown';
        menuCatSelect.selectedIcon = 'dropdown-selected';
        menuCatSelect.background = '';
        menuCatSelect.backgroundClicked = '';
        menuCatSelect.id = this.menuCategories.SELECT_CHOICE;

        // const menucatProd: any = {};
        // menucatProd.title = 'Products';
        // menucatProd.icon = 'icon-multi-choice';
        // menucatProd.selectedIcon = 'icon-selected-multi-choice';
        // menucatProd.background = '';
        // menucatProd.backgroundClicked = '';
        // menucatProd.id = this.menuCategories.PRODUCT;

        const menucatCollection: any = {};
        menucatCollection.title = 'Collections';
        menucatCollection.icon = 'icon-multi-choice';
        menucatCollection.selectedIcon = 'icon-selected-multi-choice';
        menucatCollection.background = '';
        menucatCollection.backgroundClicked = '';
        menucatCollection.id = this.menuCategories.COLLECTION;

        // const menucaTEight: any = {};

        // menucaTEight.title = 'Switches Toggle';
        // menucaTEight.icon = 'icon-toggle-choice';
        // menucaTEight.selectedIcon = 'icon-selected-toggle-choice';
        // menucaTEight.background = '';
        // menucaTEight.backgroundClicked = '';
        // menucaTEight.id = this.menuCategories.TOGGLE_CHOICE;
        if (
          (this.selectedType &&
            this.selectedType.sub_type === this.tabTypes.BUNDLE) ||
          this.section === this.uiSections.MOBILESTORE ||
          (this.selectedMenuButtonTab &&
            this.selectedMenuButtonTab.type === this.tabTypes.MENUS)
        ) {
          this.categoryList = [menucaTFive, menucatOne, menucatCollection];
        } else {
          this.categoryList = [
            menucaTFive,
            menucatOne,
            menucatTwo,
            menucatThree,
            menucaTSix,
            menucaTSevent,

            menucaTFour,
            menuCatSelect
          ];
        }

        // this.selectedFeature = menucatOne.id;
        if (!this.polls) {
          this.currentCategory = menucaTFive;

          this.appMngmentService._selectedFeature.next(menucaTFive);
          this.featureNotify.emit(menucaTFive);
        } else if (this.polls) {
          this.currentCategory = menucatOne;

          this.appMngmentService._selectedFeature.next(menucatOne);
          this.featureNotify.emit(menucatOne);
        }

        this._ref.detectChanges();
      } else if (this.section === this.uiSections.MENUS) {
        const menucatOne: any = {};

        // menucatOne.title = 'Buttons';
        // menucatOne.icon = 'icon-button-cell';
        // menucatOne.selectedIcon = 'icon-selected-button-cell';
        // menucatOne.background = '';
        // menucatOne.backgroundClicked = '';
        // menucatOne.id = this.menuCategories.BUTTON;

        menucatOne.title = 'Buttons';
        menucatOne.icon = 'icon-button-cell';
        menucatOne.selectedIcon = 'icon-selected-button-cell';
        menucatOne.background = '';
        menucatOne.backgroundClicked = '';
        menucatOne.id = this.menuCategories.BUTTON;

        this.categoryList = [menucatOne];
        this._ref.detectChanges();
        this.featureNotify.emit(menucatOne);
      } else if (this.section === this.uiSections.MOBILESTORE) {
        const menucaTFive: any = {};

        menucaTFive.title = 'Separators Cell';
        menucaTFive.icon = 'icon-separator';
        menucaTFive.selectedIcon = 'icon-selected-separator';
        menucaTFive.background = '';
        menucaTFive.backgroundClicked = '';
        menucaTFive.id = this.menuCategories.SEPARATOR;

        this.categoryList = [menucaTFive];
        this._ref.detectChanges();
        this.featureNotify.emit(menucaTFive);
      }
      if (!this.selectedFeature) {
        this.appMngmentService._selectedFeature.next(this.social);
        this.featureNotify.emit(this.social);
      } else if (
        this.currentPage === this.appScreen.TABS &&
        this.showSettings
      ) {
        this.buildMenuscat = true;
        this.enterdNav = false;
        this.enterdTab = false;
        this.enterdWorkFlow = true;
        this.btnWorkFlow = false;
        this.selectedCategory(this.social);
        this._ref.detectChanges();
      } else {
        this.currentCategory = this.categoryList.filter(
          res => res.id === this.selectedFeature.id
        );
        this.featureNotify.emit(this.currentCategory[0]);
      }
    }
  }
  selectedCategory(feature) {
    this.currentCategory = feature;
    this._ref.detectChanges();
    this.appMngmentService._selectedFeature.next(feature);
    this.featureNotify.emit(feature);
  }

  backToMenusCat() {
    this.enterdNav = false;
    this.enterdTab = false;
    this.enterdWorkFlow = false;
    this.btnWorkFlow = false;
    this._ref.detectChanges();
  }

  getModuleCategory(id) {
    this.ordermanagement.title = 'Bookings & Events';
    this.workFlow.title = 'Work  Flow';
    this.productivity.title = 'Communication';
    this.essentials.title = 'Essentials';
    this.maptracking.title = 'Maps';
    this.mStore.title = 'Mobile Store';
    this.secuirtyprivacy.title = 'Security & Privacy';
    this.social.title = 'Social';
    this.accounts.title = 'Accounts';
    this.advertisements.title = 'Advertisements';
    this.ordermanagement.modules = [515, 600, 1200, 10020, 10021];
    this.productivity.modules = [500, 502, 503, 508, 511, 700000];
    this.essentials.modules = [900, 1000, 1001, 1002];
    this.maptracking.modules = [1100, 1101];
    this.mStore.modules = [20002];
    this.secuirtyprivacy.modules = [800];
    this.workFlow.subTitle = 'Includes 1 module';
    this.workFlow.modules = [20001];

    this.social.modules = [
      501,
      507,
      516,
      517,
      509,
      512,
      510,
      505,
      518,
      504,
      700,
      519
    ];
    const pages: any = {};
    pages.modules = [0];
    pages.title = 'Pages';
    this.categoryList = [
      this.social,
      this.productivity,
      this.essentials,
      this.maptracking,
      this.secuirtyprivacy,
      this.ordermanagement,
      this.mStore,
      this.workFlow,
      pages
    ];
    let catTitle = '';
    if (this.categoryList) {
      this.categoryList.forEach(cat => {
        cat.modules.forEach(mdl => {
          if (mdl == id) {
            catTitle = cat.title;
          }
        });
      });
    }
    return catTitle;
  }
}
