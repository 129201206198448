/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown-timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./countdown-timer.component";
import * as i4 from "../../../core/ui.service";
import * as i5 from "../../../store/ui/ui.dispatchers";
var styles_CountdownTimerComponent = [i0.styles];
var RenderType_CountdownTimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownTimerComponent, data: {} });
export { RenderType_CountdownTimerComponent as RenderType_CountdownTimerComponent };
export function View_CountdownTimerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "timer"]], [[2, "hidde", null]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "timer-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Free trial"])), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "timer-countdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "count"], ["style", "margin-right: 8px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "value"], ["id", "days"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Days"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "count"], ["style", "margin-right: 4px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "value"], ["id", "hours"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hours"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "dots"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [":"])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "count"], ["style", "margin-left: 4px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "value"], ["id", "minutes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Minutes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.hideCountDown$)); _ck(_v, 0, 0, currVal_0); }); }
export function View_CountdownTimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countdown-timer", [], null, null, null, View_CountdownTimerComponent_0, RenderType_CountdownTimerComponent)), i1.ɵdid(1, 245760, null, 0, i3.CountdownTimerComponent, [i4.UiService, i5.UIDispatchers], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownTimerComponentNgFactory = i1.ɵccf("app-countdown-timer", i3.CountdownTimerComponent, View_CountdownTimerComponent_Host_0, { endDate: "endDate" }, {}, []);
export { CountdownTimerComponentNgFactory as CountdownTimerComponentNgFactory };
