import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ListAppMenus } from 'src/models/AppMenu';
export class AppMenuEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.listAppMenus = this.actions$.pipe(ofType("GET_APP_MENUS" /* GET_APP_MENUS */), map((action) => {
            this._socketGateway.sendSocketMessage(new ListAppMenus(action.menuType));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppMenuEffects.prototype, "listAppMenus", void 0);
