import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { MapTripsHistoryOpj } from 'src/models/MapTripsHistory';
import * as MapTripsHistoryAction from './mapTripsHistory.actions';

@Injectable({
  providedIn: 'root'
})
export class MapTripHistoryDispatchers {
  constructor(private _store: Store<AppState>) {}

  restMapTripsHistoryPaging(startDate?: string, endDate?: string) {
    this._store.dispatch(
      new MapTripsHistoryAction.ResetMapTripHistoryPaging(startDate, endDate)
    );
  }

  getNextMapTripsHistoryPage() {
    this._store.dispatch(
      new MapTripsHistoryAction.GetNextMapTripsHistoryPage()
    );
  }

  getPrevMapTripsHistoryPage() {
    this._store.dispatch(
      new MapTripsHistoryAction.GetPrevMapTripsHistoryPage()
    );
  }

  mapTripHistoryListReceived(
    eop: number,
    sop: number,
    mapTrips: MapTripsHistoryOpj[],
    hash: string
  ): void {
    this._store.dispatch(
      new MapTripsHistoryAction.MapTripHistoryListReceived(
        eop,
        sop,
        mapTrips,
        hash
      )
    );
  }

  setStatusAsIdle() {
    this._store.dispatch(new MapTripsHistoryAction.SetStatusAsIdle());
  }
}
