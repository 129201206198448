import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';

import * as TagsActions from './tags.actions';
import * as ChatMethods from 'src/models/IChat';

@Injectable({
  providedIn: 'root'
})
export class TagsDispatchers {
  constructor(private _store: Store<AppState>) {}

  tagsReceived(payload: ChatMethods.ReceiveChat, id: string): void {
    const { chat } = payload;
    this._store.dispatch(new TagsActions.TagsReceived(chat, id));
  }
}
