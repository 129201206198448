import { AppJSON } from './../../models/AppPublishInfo';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { HelperService } from '../core/helper.service';
import { AppState } from 'src/models/AppState';
import { IProfile } from 'src/models/IProfile';
import { IMyProfile } from 'src/models/IMyProfile';
import { MainChat } from 'src/models/MainChat';
import { UIState } from 'src/models/UIState';
import { IMessage, ChannelPosts, ChatMessages } from 'src/models/IMessage';
import { ChannelMember, ChannelMemberReplies } from 'src/models/ChannelMember';
import {
  INDIVIDUAL,
  CHATS,
  GROUP,
  CHANNEL,
  CHANNELS,
  MessageTypes,
  CHANNEL_PAGE_SIZE,
  CHAT_PAGE_SIZE,
  MERCHANT_PAGE_SIZE,
  PROJECT_NAME,
  MERCHANT_PROJECT,
  AppClassNames,
  ContainerTypes,
  ComponentTypes,
  EVENT_CHANNEL,
  CHATID_LOCAL_STORAGE_KEY,
  APP_MENU_CATEGORIES,
  BOOKING_CHANNEL,
  CHANNEL_APP_SEARCH_CATEGORIES,
  CHATS_LIST_PAGE_SIZE,
  AdsConfig,
  INDIVIDUAL_PROJECT
} from 'src/models/constants';
import {
  LocalItem,
  LocalComponent,
  LocalContainer,
  LocalAppClass,
  LocalAppInfo
} from 'src/models/ChannelAppLocalConfig';
import { ascendingly } from './channelAppConfig/appConfig.middleware';
import { ChannelAppUIState } from 'src/models/ChannelAppUIState';
import {
  AppInfo,
  SystemConfig,
  AppColors,
  AppConfigHistoryRecord,
  ChannelData
} from 'src/models/ChannelAppConfig';
import { ITag } from 'src/models/ITag';
import { IChatMember } from 'src/models/IChatMember';
import { IChat } from 'src/models/IChat';
import { EventUI } from 'src/models/EventUI';
import { AppRelease } from 'src/models/AppRelease';
import { AppTemplate } from 'src/models/AppTemplate';
import { InstantArticle } from 'src/models/InstantArticle';
import { SubChatUI } from 'src/models/SubGroupUI';
import { StorageService } from '../core/storage.service';
import { IRole } from 'src/models/IRole';
import { MyPage } from 'src/models/MyPageConfig';
import { MenuCallBackStats } from 'src/models/MenuCallBackStats';
import { ICampaign } from 'src/models/ICampaign';
import { CampaignUI } from 'src/models/CampaignUI';
import { ICoupon } from 'src/models/ICoupon';
import { CouponUI } from 'src/models/CouponUI';
import { AppPublishInfo } from 'src/models/AppPublishInfo';
import { BookingUI } from 'src/models/BookingUI';
import { IMediaCenter } from 'src/models/IMediaCenter';
import { BookingPeriods } from 'src/models/BookingPeriods';
import { MyBookings } from 'src/models/MyBookings';
import { BookingExceptions } from 'src/models/BookingExceptions';
import { MapService } from 'src/models/MapService';
import { MapMarker } from 'src/models/MapMarker';
import { MapTrip } from 'src/models/MapTrip';
import { MapTripUser } from 'src/models/MapTripUser';
import { UIMiddleware } from './ui/ui.middleware';
import { CalendarTimetable } from 'src/models/Calendar';
import { CalendarUI } from 'src/models/CalendarUI';
import { MembersUI } from 'src/models/MembersUI';
import {
  PaymentProvider,
  PaymentOrder,
  ServiceOrder
} from 'src/models/PaymentGateways';
import { IAuth } from 'src/models/IAuth';
import { Product } from 'src/models/Product';
import { Bundle } from 'src/models/Bundle';
import { Package } from 'src/models/Package';
import { MStore } from 'src/models/Stores';
import { StoreMenu } from 'src/models/StoreMenu';
import { SubMenuStore } from 'src/models/SubMenu';

import * as isEqual from 'lodash.isequal';
import { ChatMiddleware } from './chats/chat.middleware';
import { MapMarkerUI } from 'src/models/MapMarkerUI';
import { BlackListsUI } from 'src/models/BlackListsUI';
import { BookingUiDispatchers } from './bookingsUI/bookingUI.dispatchers';
import { MapRoute } from 'src/models/MapRoute';
import { Stops } from 'src/models/MapStopTime';
import { MapTripsHistoryOpj } from 'src/models/MapTripsHistory';
import { MapTripsNotifiers } from 'src/models/mapTripsNotifiers';
import { Whitelist, WhitelistUI } from 'src/models/Whitelist';
import { DriverTrip } from 'src/models/DrverAssigned';
import { MapDriverUser } from 'src/models/MapDriver';
import { AppChannelSettingsUI } from 'src/models/AppChannelSettings';
import { ChartTypes, WidgetUI } from 'src/models/summaryUI';
import { ProfileDispatchers } from './profile/profile.dispatchers';
import { AllGroupUI } from 'src/models/AllGroupUI';
import { smtpReducer } from './smtp/smtp.reducer';
import { Smtp } from 'src/models/Smtp';
import { ThirdPartyInfoUI, VendorsUI } from 'src/models/thirdPartyInfo';
import { ChartObj } from 'src/models/ChartUI';
import { ChatThemeOpj } from 'src/models/chatThemes';
import { BotMenu } from 'src/models/IBotMenu';
import { MyPageDispatchers } from './channelMyPage/myPage.dispatchers';
import {
  BillingCoupon,
  InvoiceOpj,
  SubscriptionItemUI
} from 'src/models/billings';
import { ProductStore } from 'src/models/productStore';
import { Collection } from 'src/models/collectionsUI';
import { CollectionsUIDispatchers } from 'src/app/store/collectionsUI/collectionsUI.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class RootStoreSelectors {
  constructor(
    private _store: Store<AppState>,
    private _helperService: HelperService,
    private _storageService: StorageService,
    private _bookingDispatcher: BookingUiDispatchers,
    private _profileDispatchers: ProfileDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _collectionDispatcher: CollectionsUIDispatchers
  ) {}

  get authCollection$(): Observable<IAuth> {
    return this._store
      .select(state => state.authReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get selectedChatName$(): Observable<string> {
    return this.getSelectedChat$().pipe(
      map(r => r.name),
      distinctUntilChanged()
    );
  }

  getDetailsReceivedFlag$(): Observable<boolean> {
    return this._store
      .select(state => state.uiReducer.detailsReceived)
      .pipe(distinctUntilChanged());
  }
  getShowDashboard$(): Observable<boolean> {
    return this._store
      .select(state => state.uiReducer.showDashboard)
      .pipe(distinctUntilChanged());
  }
  getChannelsList$(): Observable<MainChat[]> {
    return this.channels$.pipe(
      withLatestFrom(
        this._store
          .select(state => state.uiReducer.channelsListPageNumber)
          .pipe(distinctUntilChanged())
      ),
      map(res => {
        const filteredList = res[0].filter(
          channel => !channel.pinned_date && channel.name
        );
        const pageNumber = res[1];
        const resLength = filteredList.length;
        const result =
          pageNumber * CHATS_LIST_PAGE_SIZE >= resLength
            ? filteredList
            : filteredList.slice(0, pageNumber * CHATS_LIST_PAGE_SIZE);
        return result;
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getChannelsAndGroupList$(): Observable<MainChat[]> {
    return this.channelsAndGroup$.pipe(
      withLatestFrom(
        this._store
          .select(state => state.uiReducer.channelsListPageNumber)
          .pipe(distinctUntilChanged())
      ),
      map(res => {
        const filteredList = res[0].filter(
          channel => !channel.pinned_date && channel.name
        );
        const pageNumber = res[1];
        const resLength = filteredList.length;
        const result =
          pageNumber * CHATS_LIST_PAGE_SIZE >= resLength
            ? filteredList
            : filteredList.slice(0, pageNumber * CHATS_LIST_PAGE_SIZE);
        return result;
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getChatsList$(): Observable<MainChat[]> {
    return this.chats$.pipe(
      withLatestFrom(
        this._store
          .select(state => state.uiReducer.chatsListPageNumber)
          .pipe(distinctUntilChanged()),
        this._store
          .select(state => state.uiReducer.detailsReceived)
          .pipe(distinctUntilChanged())
      ),
      map(res => {
        const filteredList = res[0].filter(
          chat => !chat.pinned_date && chat.detailsReceived
        );
        const pageNumber = res[1];
        const resLength = filteredList.length;
        const result =
          pageNumber * CHATS_LIST_PAGE_SIZE >= resLength
            ? filteredList
            : filteredList.slice(0, pageNumber * CHATS_LIST_PAGE_SIZE);
        return result;
      }),
      distinctUntilChanged(isEqual)
    );
  }

  get selectedList$() {
    return this._store
      .select(state => state.uiReducer.selectedList)
      .pipe(distinctUntilChanged());
  }
  /** selectors  */
  get chats$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(
          chat =>
            chat.type === GROUP ||
            (chat.type === INDIVIDUAL && PROJECT_NAME === INDIVIDUAL_PROJECT)
        )
      )
      .pipe(
        withLatestFrom(this._store.select(state => state.uiReducer)),
        map(res => {
          const isProfileAndChatsRcvd = UIMiddleware.isAllProfilesAndChatsRcvd(
            res[0],
            res[1]
          );

          // if (isProfileAndChatsRcvd) {
          return res[0].filter(chat => {
            // if chat
            if (
              chat.name &&
              res[1].searchKeyWord &&
              res[1].searchKeyWord !== ''
            ) {
              return (
                chat.name
                  .toUpperCase()
                  .indexOf(res[1].searchKeyWord.toUpperCase()) !== -1
              );
            } else {
              return chat;
            }
          });
          // } else {
          //   return [];
          // }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get channels$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(
          chat =>
            chat.type === CHANNEL ||
            chat.type === BOOKING_CHANNEL ||
            chat.type === EVENT_CHANNEL
        )
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.searchKeyWord)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const channels = res[0];
          return channels.filter(channel => {
            if (channel.name && res[1] && res[1] !== '') {
              return (
                channel.name.toUpperCase().indexOf(res[1].toUpperCase()) !== -1
              );
            } else {
              return channel;
            }
          });
        }),
        distinctUntilChanged(isEqual)
      );
  }
  get channelsAndGroup$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(
          chat =>
            (chat.type === CHANNEL && chat.subChannel) || chat.type === GROUP
        )
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.searchKeyWord)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const channels = res[0];
          return channels.filter(channel => {
            if (channel.name && res[1] && res[1] !== '') {
              return (
                channel.name.toUpperCase().indexOf(res[1].toUpperCase()) !== -1
              );
            } else {
              return channel;
            }
          });
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get channelsAndSubChannels$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(chat => chat.type === CHANNEL)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get subChats$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(
          chat =>
            chat.type === GROUP || (chat.type === CHANNEL && chat.subChannel)
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getMainSubChats$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(chat => chat.type === GROUP)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.subGroupUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        distinctUntilChanged(),
        map(([subChats, currentPage]) => {
          let targetSubChats: MainChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetSubChats = subChats.filter(subChat =>
              currentPage.find(chatId => chatId === subChat.id)
            );
          }
          return targetSubChats;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getAllMainSubChats$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(chat => chat.type === GROUP)
      )
      .pipe(
        // withLatestFrom(
        //   this._store.select(state =>
        //     state.allGroupReducer.currentPage.map(chat => chat.id)
        //   )
        // ),
        // map(([subChats, currentPage]) => {
        //   let targetSubChats: MainChat[] = [];
        //   if (currentPage && currentPage.length > 0) {
        //     targetSubChats = subChats.filter(subChat =>
        //       currentPage.find(chatId => chatId === subChat.id)
        //     );
        //   }
        //   return targetSubChats;
        // }),
        distinctUntilChanged(isEqual)
      );
  }

  getUIAllGroup$(): Observable<AllGroupUI> {
    return this._store
      .select(state => state.allGroupReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMainSubChatsPageCount$(): Observable<number> {
    return this._store
      .select(state => state.subGroupUiReducer.pageCount)
      .pipe(distinctUntilChanged());
  }

  getMainSubChannels$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.mainChatReducer.filter(
          chat => chat.type === CHANNEL && chat.subChannel
        )
      )
      .pipe(distinctUntilChanged())
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.subChannelUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        distinctUntilChanged(),
        map(([subChats, currentPage]) => {
          let targetSubChats: MainChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetSubChats = subChats.filter(subChat =>
              currentPage.find(chatId => chatId === subChat.id)
            );
          }
          return targetSubChats;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getPagedSubChats$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat => chat.type === GROUP && chat.detailsReceived
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.subGroupUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([subChats, currentPage]) => {
          let targetSubChats: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetSubChats = subChats.filter(subChat =>
              currentPage.find(chatId => chatId === subChat.id)
            );
          }
          return targetSubChats;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getPagedSubChannels$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat =>
            chat.type === CHANNEL && chat.subChannel && chat.detailsReceived
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.subChannelUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([subChats, currentPage]) => {
          let targetSubChats: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetSubChats = subChats.filter(subChat =>
              currentPage.find(chatId => chatId === subChat.id)
            );
          }
          return targetSubChats;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getPagedVirtualApps$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat =>
            chat.type === CHANNEL &&
            chat.vapp &&
            chat.vapp == 1 &&
            chat.detailsReceived
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.virtualAppUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([subChats, currentPage]) => {
          let targetSubChats: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetSubChats = subChats.filter(subChat =>
              currentPage.find(chatId => chatId === subChat.id)
            );
          }
          return targetSubChats;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getFilteredSubChats$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat =>
            chat.type === GROUP || (chat.type === CHANNEL && chat.subChannel)
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getChatsMenu$(): Observable<MainChat[]> {
    return this._store
      .select(state =>
        state.chatReducer
          .filter(chat => chat.type === CHANNEL && chat.detailsReceived)
          .map(ct => {
            if (ct) {
              return ChatMiddleware.getMainChatFromChat(ct);
            }
            return;
          })
          .filter(res => res)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get allUsers$(): Observable<IProfile[]> {
    return this._store
      .select(state => state.profileReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMessagesToView$(): Observable<ChatMessages> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state => state.messageReducer.filter(msg => !msg.isChannelPost))
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedUserId)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedList)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.messagesPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.scrollDelta)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedTalkToAdmin)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[5];
          scrollDelta = res[6];
          return res[0].filter(msg => {
            const mainChat: MainChat = res[1] ? res[1] : res[8];
            const parentMessage: IMessage = res[2];
            const specificUserID: string = res[3];
            const selectedList: string = res[4];
            const isTalkToAdmin: boolean = res[7];
            if (mainChat && mainChat.type === INDIVIDUAL) {
              return (
                !msg.group_id &&
                (mainChat.id === msg.sender_id ||
                  mainChat.id === msg.receiver_id)
              );
            } else if (mainChat && mainChat.type === GROUP) {
              if (!parentMessage) {
                return mainChat.id === msg.group_id && !msg.reply_to_message_id;
              } else {
                return msg.reply_to_message_id === parentMessage.message_id;
              }
            } else if (mainChat && mainChat.type === CHANNEL) {
              if (!parentMessage && specificUserID && isTalkToAdmin) {
                return (
                  msg.reply_to_message_id === mainChat.id &&
                  (msg.send_to_user_id === specificUserID ||
                    msg.sender_id === specificUserID)
                );
              } else if (parentMessage && specificUserID) {
                return (
                  msg.reply_to_message_id === parentMessage.message_id &&
                  (msg.send_to_user_id === specificUserID ||
                    msg.sender_id === specificUserID)
                );
              } else if (parentMessage) {
                return (
                  mainChat.id === msg.group_id &&
                  msg.reply_to_message_id === parentMessage.message_id
                );
              }
            } else if (!mainChat && selectedList === CHANNELS) {
              if (parentMessage && specificUserID) {
                return (
                  msg.reply_to_message_id === parentMessage.message_id &&
                  (msg.send_to_user_id === specificUserID ||
                    msg.sender_id === specificUserID)
                );
              } else if (parentMessage) {
                return msg.reply_to_message_id === parentMessage.message_id;
              }
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          let msgsToBePaged: IMessage[] = [];
          let notPagedSection: IMessage[] = [];
          let resLength: number;
          let firstUnreadMessageIndex: number;
          if (PROJECT_NAME === MERCHANT_PROJECT) {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isViewed
            );
          } else {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isChatViewed
            );
          }
          if (firstUnreadMessageIndex !== -1) {
            msgsToBePaged = res.slice(0, firstUnreadMessageIndex);

            notPagedSection = res.slice(firstUnreadMessageIndex, res.length);
          } else {
            msgsToBePaged = res;
          }
          resLength = msgsToBePaged.length;

          // if(!unreadMsgs or unreadMsgs.length=0)
          // {
          const totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);

          const TEN_PAGES = 10 * CHAT_PAGE_SIZE;

          // We need max 10 pages in dom plus all unread
          //      if(!unreadMsgs || unreadMsgs.length=0)
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(
                      resLength - pageNumber * CHAT_PAGE_SIZE,
                      resLength
                    );
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(resLength - TEN_PAGES, resLength);
            }
          } else {
            // page number >10
            const offset = Math.abs(scrollDelta) * CHAT_PAGE_SIZE;
            const end = resLength - offset;
            result =
              pageNumber >= totalNumberOfPages
                ? msgsToBePaged.slice(0, TEN_PAGES)
                : msgsToBePaged.slice(end - TEN_PAGES, end);
          }
          // return [...result, ...unreadMsgs];
          const chatMessages: ChatMessages = {};
          if (firstUnreadMessageIndex !== -1) {
            chatMessages.messages = [...result, ...notPagedSection];
          } else {
            chatMessages.messages = result;
          }
          chatMessages.totalNumberOfPages = totalNumberOfPages;
          return chatMessages;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSubMessagesToView$(): Observable<ChatMessages> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state =>
        state.messageReducer.filter(
          msg =>
            !msg.isChannelPost &&
            msg.group_id &&
            msg.group_id !==
              this._storageService.getRecord(CHATID_LOCAL_STORAGE_KEY)
        )
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.subGroupMessagesPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subGroupScrollDelta)
            .pipe(distinctUntilChanged()),

          this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subParentMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subSelectedUserId)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[1];
          scrollDelta = res[2];
          return res[0].filter(msg => {
            const subChat: MainChat = res[3];
            const subParentMessage: IMessage = res[4];
            const specificUserID: string = res[5];

            if (subChat) {
              if (subChat.type === GROUP) {
                if (!subParentMessage) {
                  return (
                    subChat.id === msg.group_id && !msg.reply_to_message_id
                  );
                } else {
                  return (
                    msg.reply_to_message_id === subParentMessage.message_id
                  );
                }
              } else if (subChat.type === CHANNEL) {
                if (subParentMessage && specificUserID) {
                  return (
                    msg.reply_to_message_id === subParentMessage.message_id &&
                    (msg.send_to_user_id === specificUserID ||
                      msg.sender_id === specificUserID)
                  );
                } else if (subParentMessage) {
                  return (
                    subChat.id === msg.group_id &&
                    msg.reply_to_message_id === subParentMessage.message_id
                  );
                }
              }
            } else {
              return [];
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          let msgsToBePaged: IMessage[] = [];
          let notPagedSection: IMessage[] = [];
          let resLength: number;
          let firstUnreadMessageIndex: number;
          if (PROJECT_NAME === MERCHANT_PROJECT) {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isViewed
            );
          } else {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isChatViewed
            );
          }
          if (firstUnreadMessageIndex !== -1) {
            msgsToBePaged = res.slice(0, firstUnreadMessageIndex);

            notPagedSection = res.slice(firstUnreadMessageIndex, res.length);
          } else {
            msgsToBePaged = res;
          }
          resLength = msgsToBePaged.length;

          // if(!unreadMsgs or unreadMsgs.length=0)
          // {
          const totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);

          const TEN_PAGES = 10 * CHAT_PAGE_SIZE;

          // We need max 10 pages in dom plus all unread
          //      if(!unreadMsgs || unreadMsgs.length=0)
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(
                      resLength - pageNumber * CHAT_PAGE_SIZE,
                      resLength
                    );
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(resLength - TEN_PAGES, resLength);
            }
          } else {
            // page number >10
            const offset = Math.abs(scrollDelta) * CHAT_PAGE_SIZE;
            const end = resLength - offset;
            result =
              pageNumber >= totalNumberOfPages
                ? msgsToBePaged.slice(0, TEN_PAGES)
                : msgsToBePaged.slice(end - TEN_PAGES, end);
          }
          // return [...result, ...unreadMsgs];
          const chatMessages: ChatMessages = {};
          if (firstUnreadMessageIndex !== -1) {
            chatMessages.messages = [...result, ...notPagedSection];
          } else {
            chatMessages.messages = result;
          }
          chatMessages.totalNumberOfPages = totalNumberOfPages;
          return chatMessages;
        }),
        distinctUntilChanged(isEqual)
      );
  }
  getSubMessagesToView1$(): Observable<ChatMessages> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state =>
        state.messageReducer.filter(
          msg =>
            !msg.isChannelPost &&
            msg.group_id &&
            msg.group_id !==
              this._storageService.getRecord(CHATID_LOCAL_STORAGE_KEY)
        )
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.subGroupMessagesPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subGroupScrollDelta)
            .pipe(distinctUntilChanged()),

          this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subParentMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subSelectedUserId)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[1];
          scrollDelta = res[2];
          return res[0].filter(msg => {
            const subChat: MainChat = res[3];
            const subParentMessage: IMessage = res[4];
            const specificUserID: string = res[5];

            if (subChat) {
              if (subChat.type === GROUP) {
                return subChat.id === msg.group_id && !msg.reply_to_message_id;
              } else if (subChat.type === CHANNEL) {
                if (subParentMessage && specificUserID) {
                  return (
                    msg.reply_to_message_id === subParentMessage.message_id &&
                    (msg.send_to_user_id === specificUserID ||
                      msg.sender_id === specificUserID)
                  );
                } else if (subParentMessage) {
                  return (
                    subChat.id === msg.group_id &&
                    msg.reply_to_message_id === subParentMessage.message_id
                  );
                }
              }
            } else {
              return [];
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          let msgsToBePaged: IMessage[] = [];
          let notPagedSection: IMessage[] = [];
          let resLength: number;
          let firstUnreadMessageIndex: number;
          if (PROJECT_NAME === MERCHANT_PROJECT) {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isViewed
            );
          } else {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isChatViewed
            );
          }
          if (firstUnreadMessageIndex !== -1) {
            msgsToBePaged = res.slice(0, firstUnreadMessageIndex);

            notPagedSection = res.slice(firstUnreadMessageIndex, res.length);
          } else {
            msgsToBePaged = res;
          }
          resLength = msgsToBePaged.length;

          // if(!unreadMsgs or unreadMsgs.length=0)
          // {
          const totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);

          const TEN_PAGES = 10 * CHAT_PAGE_SIZE;

          // We need max 10 pages in dom plus all unread
          //      if(!unreadMsgs || unreadMsgs.length=0)
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(
                      resLength - pageNumber * CHAT_PAGE_SIZE,
                      resLength
                    );
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(resLength - TEN_PAGES, resLength);
            }
          } else {
            // page number >10
            const offset = Math.abs(scrollDelta) * CHAT_PAGE_SIZE;
            const end = resLength - offset;
            result =
              pageNumber >= totalNumberOfPages
                ? msgsToBePaged.slice(0, TEN_PAGES)
                : msgsToBePaged.slice(end - TEN_PAGES, end);
          }
          // return [...result, ...unreadMsgs];
          const chatMessages: ChatMessages = {};
          if (firstUnreadMessageIndex !== -1) {
            chatMessages.messages = [...result, ...notPagedSection];
          } else {
            chatMessages.messages = result;
          }
          chatMessages.totalNumberOfPages = totalNumberOfPages;
          return chatMessages;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getChannelMessagesToView$(): Observable<ChannelPosts> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state =>
        state.messageReducer.filter(msg => msg.isChannelPost).reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedList)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.postsPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.postsScrollDelta)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[3];
          scrollDelta = res[4];
          return res[0].filter(msg => {
            const mainChat: MainChat = res[1];
            const selectedList: string = res[2];
            if (mainChat && mainChat.type === CHANNEL) {
              return mainChat.id === msg.group_id;
            } else if (!mainChat && selectedList === CHANNELS) {
              return msg;
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          const resLength = res.length;
          const totalNumberOfPages = Math.ceil(resLength / CHANNEL_PAGE_SIZE);

          const TEN_PAGES = 10 * CHANNEL_PAGE_SIZE;

          // We need max 10 pages in dom
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? res
                  : res.slice(0, pageNumber * CHANNEL_PAGE_SIZE);
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? res
                  : res.slice(0, TEN_PAGES);
            }
          } else {
            // page number >10
            const start = Math.abs(scrollDelta) * CHANNEL_PAGE_SIZE;
            result =
              pageNumber >= totalNumberOfPages
                ? res.slice(resLength - TEN_PAGES, resLength)
                : res.slice(start, start + TEN_PAGES);
          }

          const channelPosts: ChannelPosts = {};

          channelPosts.messages = result;
          channelPosts.totalNumberOfPages = totalNumberOfPages;
          return channelPosts;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSubChannelMessagesToView$(): Observable<ChannelPosts> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state =>
        state.messageReducer
          .filter(
            msg =>
              msg.isChannelPost &&
              msg.group_id !==
                this._storageService.getRecord(CHATID_LOCAL_STORAGE_KEY)
          )
          .reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedSubChat)
            .pipe(distinctUntilChanged()),

          this._store
            .select(state => state.uiReducer.subPostsPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subPostsScrollDelta)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[2];
          scrollDelta = res[3];
          return res[0].filter(msg => {
            const subChat: MainChat = res[1];
            if (subChat && subChat.type === CHANNEL) {
              return subChat.id === msg.group_id;
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          const resLength = res.length;
          const totalNumberOfPages = Math.ceil(resLength / CHANNEL_PAGE_SIZE);

          const TEN_PAGES = 10 * CHANNEL_PAGE_SIZE;

          // We need max 10 pages in dom
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? res
                  : res.slice(0, pageNumber * CHANNEL_PAGE_SIZE);
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? res
                  : res.slice(0, TEN_PAGES);
            }
          } else {
            // page number >10
            const start = Math.abs(scrollDelta) * CHANNEL_PAGE_SIZE;
            result =
              pageNumber >= totalNumberOfPages
                ? res.slice(resLength - TEN_PAGES, resLength)
                : res.slice(start, start + TEN_PAGES);
          }

          const channelPosts: ChannelPosts = {};
          channelPosts.messages = result;
          channelPosts.totalNumberOfPages = totalNumberOfPages;
          return channelPosts;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // ***********************************************
  getMessagesToViewByParams$(
    selectedMessageId: string,
    selectedUserId: string
  ): Observable<ChatMessages> {
    let pageNumber: number;
    let scrollDelta: number;
    return this._store
      .select(state => state.messageReducer.filter(msg => !msg.isChannelPost))
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedList)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.messagesPageNumber)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.scrollDelta)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          pageNumber = res[3];
          scrollDelta = res[4];
          return res[0].filter(msg => {
            const mainChat: MainChat = res[1];
            const parentMessageId = selectedMessageId;
            const specificUserID: string = selectedUserId;
            const selectedList: string = res[2];

            if (mainChat && mainChat.type === INDIVIDUAL) {
              return (
                !msg.group_id &&
                (mainChat.id === msg.sender_id ||
                  mainChat.id === msg.receiver_id)
              );
            } else if (mainChat && mainChat.type === GROUP) {
              if (!parentMessageId) {
                return mainChat.id === msg.group_id && !msg.reply_to_message_id;
              } else {
                return msg.reply_to_message_id === parentMessageId;
              }
            } else if (mainChat && mainChat.type === CHANNEL) {
              if (parentMessageId && specificUserID) {
                return (
                  msg.reply_to_message_id === parentMessageId &&
                  (msg.send_to_user_id === specificUserID ||
                    msg.sender_id === specificUserID)
                );
              } else if (parentMessageId) {
                return (
                  mainChat.id === msg.group_id &&
                  msg.reply_to_message_id === parentMessageId
                );
              }
            } else if (!mainChat && selectedList === CHANNELS) {
              if (parentMessageId && specificUserID) {
                return (
                  msg.reply_to_message_id === parentMessageId &&
                  (msg.send_to_user_id === specificUserID ||
                    msg.sender_id === specificUserID)
                );
              } else if (parentMessageId) {
                return msg.reply_to_message_id === parentMessageId;
              }
            }
          });
        }),
        map(res => {
          let result: IMessage[] = [];
          let msgsToBePaged: IMessage[] = [];
          let notPagedSection: IMessage[] = [];
          let resLength: number;
          let firstUnreadMessageIndex: number;
          if (PROJECT_NAME === MERCHANT_PROJECT) {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isViewed
            );
          } else {
            firstUnreadMessageIndex = (<IMessage[]>res).findIndex(
              message => !message.isChatViewed
            );
          }
          if (firstUnreadMessageIndex !== -1) {
            msgsToBePaged = res.slice(0, firstUnreadMessageIndex);

            notPagedSection = res.slice(firstUnreadMessageIndex, res.length);
          } else {
            msgsToBePaged = res;
          }
          resLength = msgsToBePaged.length;

          // if(!unreadMsgs or unreadMsgs.length=0)
          // {
          const totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);

          const TEN_PAGES = 10 * CHAT_PAGE_SIZE;

          // We need max 10 pages in dom plus all unread
          //      if(!unreadMsgs || unreadMsgs.length=0)
          if (pageNumber <= 10) {
            if (scrollDelta >= 0) {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(
                      resLength - pageNumber * CHAT_PAGE_SIZE,
                      resLength
                    );
            } else {
              result =
                pageNumber >= totalNumberOfPages
                  ? msgsToBePaged
                  : msgsToBePaged.slice(resLength - TEN_PAGES, resLength);
            }
          } else {
            // page number >10
            const offset = Math.abs(scrollDelta) * CHAT_PAGE_SIZE;
            const end = resLength - offset;
            result =
              pageNumber >= totalNumberOfPages
                ? msgsToBePaged.slice(0, TEN_PAGES)
                : msgsToBePaged.slice(end - TEN_PAGES, end);
          }
          // return [...result, ...unreadMsgs];
          const chatMessages: ChatMessages = {};
          if (firstUnreadMessageIndex !== -1) {
            chatMessages.messages = [...result, ...notPagedSection];
          } else {
            chatMessages.messages = result;
          }
          chatMessages.totalNumberOfPages = totalNumberOfPages;
          return chatMessages;
        }),
        distinctUntilChanged(isEqual)
      );
  }
  //

  getSelectedChat$(): Observable<MainChat> {
    return this._store
      .select(state => state.chatReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.uiReducer.selectedChat)
        ),
        map(([mainChats, selectedChat]) => {
          if (selectedChat) {
            const cht = mainChats.find(chat => chat.id === selectedChat.id);
            if (cht) {
              return ChatMiddleware.getMainChatFromChat(cht);
            } else {
              return selectedChat;
            }
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSelectedSubChat$(): Observable<MainChat> {
    return this._store
      .select(state => state.chatReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.uiReducer.selectedSubChat)
        ),
        map(([mainChats, selectedSubChat]) => {
          if (selectedSubChat) {
            const cht = mainChats.find(chat => chat.id === selectedSubChat.id);
            if (cht) {
              return ChatMiddleware.getMainChatFromChat(cht);
            } else {
              return selectedSubChat;
            }
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getChatLabel$(): Observable<any> {
    return this._store
      .select(state => state.uiReducer.chatLabels)
      .pipe(distinctUntilChanged(isEqual));
  }

  getSelectedMessage$(): Observable<IMessage> {
    return this._store
      .select(state => state.uiReducer.selectedMessage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getSelectedUser$(): Observable<IProfile> {
    return this._store
      .select(state => state.uiReducer.selectedUserId)
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          return res[1].find(profile => profile.user_id === res[0]);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  groupReplies$(): Observable<boolean> {
    return this._store
      .select(state => state.uiReducer.groupReplies)
      .pipe(distinctUntilChanged());
  }

  getProfilesWhoRepliedToChannelPost$(): Observable<ChannelMemberReplies> {
    return this._store
      .select(state =>
        state.messageReducer
          .filter(msg => msg.reply_to_message_id && msg.group_type === 101)
          .reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.postRepliesPageNumber)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const membersWhoReplied: ChannelMember[] = [];
          const parentMessage: IMessage = res[1];
          const pageNumber: number = res[3];
          if (parentMessage && parentMessage.isChannelPost && res[0]) {
            const allPostReplies = res[0].filter(
              msg => parentMessage.message_id === msg.reply_to_message_id
            );
            if (allPostReplies && allPostReplies.length > 0) {
              const groupedResult = this.groupBySenderProfilesNoAdmins(
                allPostReplies
              );
              if (groupedResult) {
                const profileIDs = Object.getOwnPropertyNames(groupedResult);
                profileIDs.forEach(id => {
                  const profile = res[2].find(prof => prof.user_id === id);
                  const message: IMessage = groupedResult[id][0];
                  const member: ChannelMember = {};
                  if (profile && message) {
                    member.profile = profile;
                    member.lastMessage = this._helperService.handleDifferentReplyTypes(
                      message
                    );
                    membersWhoReplied.push(member);
                  }
                });
              }
            }
          }
          if (membersWhoReplied.length > 0) {
            const resLength = membersWhoReplied.length;

            const result =
              pageNumber * CHAT_PAGE_SIZE >= resLength
                ? membersWhoReplied
                : membersWhoReplied.slice(0, pageNumber * CHAT_PAGE_SIZE);
            const replies: ChannelMemberReplies = {};
            replies.channelMemberList = result;
            replies.totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);
            return replies;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getProfilesWhoRepliedToSubChannelPost$(): Observable<ChannelMemberReplies> {
    return this._store
      .select(state =>
        state.messageReducer
          .filter(
            msg =>
              msg.reply_to_message_id &&
              msg.group_type === 101 &&
              msg.group_id !==
                this._storageService.getRecord(CHATID_LOCAL_STORAGE_KEY)
          )
          .reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.subParentMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.subPostRepliesPageNumber)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const membersWhoReplied: ChannelMember[] = [];
          const parentMessage: IMessage = res[1];
          const pageNumber: number = res[3];
          if (parentMessage && parentMessage.isChannelPost && res[0]) {
            const allPostReplies = res[0].filter(
              msg => parentMessage.message_id === msg.reply_to_message_id
            );
            if (allPostReplies && allPostReplies.length > 0) {
              const groupedResult = this.groupBySenderProfilesNoAdmins(
                allPostReplies
              );
              if (groupedResult) {
                const profileIDs = Object.getOwnPropertyNames(groupedResult);
                profileIDs.forEach(id => {
                  const profile = res[2].find(prof => prof.user_id === id);
                  const message: IMessage = groupedResult[id][0];
                  const member: ChannelMember = {};
                  if (profile && message) {
                    member.profile = profile;
                    member.lastMessage = this._helperService.handleDifferentReplyTypes(
                      message
                    );
                    membersWhoReplied.push(member);
                  }
                });
              }
            }
          }
          if (membersWhoReplied.length > 0) {
            const resLength = membersWhoReplied.length;

            const result =
              pageNumber * CHAT_PAGE_SIZE >= resLength
                ? membersWhoReplied
                : membersWhoReplied.slice(0, pageNumber * CHAT_PAGE_SIZE);
            const replies: ChannelMemberReplies = {};
            replies.channelMemberList = result;
            replies.totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);
            return replies;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getProfilesWhoRepliedToAdmin$(): Observable<ChannelMemberReplies> {
    return this._store
      .select(state =>
        state.messageReducer
          .filter(msg => msg.reply_to_message_id && msg.group_type === 101)
          .reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.postRepliesPageNumber)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const membersWhoReplied: ChannelMember[] = [];
          const parentMessage: MainChat = res[1];
          const pageNumber: number = res[3];
          if (parentMessage && parentMessage.id) {
            const allPostReplies = res[0].filter(
              msg => parentMessage.id === msg.reply_to_message_id
            );
            if (allPostReplies && allPostReplies.length > 0) {
              const groupedResult = this.groupBySenderProfilesNoAdmins(
                allPostReplies
              );
              if (groupedResult) {
                const profileIDs = Object.getOwnPropertyNames(groupedResult);
                profileIDs.forEach(id => {
                  const profile = res[2].find(prof => prof.user_id === id);
                  const message: IMessage = groupedResult[id][0];
                  const member: ChannelMember = {};
                  if (profile && message) {
                    member.profile = profile;
                    member.lastMessage = this._helperService.handleDifferentReplyTypes(
                      message
                    );
                    membersWhoReplied.push(member);
                  }
                });
              }
            }
          }
          if (membersWhoReplied.length > 0) {
            const resLength = membersWhoReplied.length;

            const result =
              pageNumber * CHAT_PAGE_SIZE >= resLength
                ? membersWhoReplied
                : membersWhoReplied.slice(0, pageNumber * CHAT_PAGE_SIZE);
            const replies: ChannelMemberReplies = {};
            replies.channelMemberList = result;
            replies.totalNumberOfPages = Math.ceil(resLength / CHAT_PAGE_SIZE);
            return replies;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  //
  groupBySenderProfilesNoAdmins(messages: IMessage[]) {
    return messages.reduce((groups, msg) => {
      let prop = null;
      if (msg.send_to_user_id) {
        prop = 'send_to_user_id';
      } else if (!msg.from_admin) {
        prop = 'sender_id';
      }
      //
      if (prop) {
        const val = msg[prop];
        groups[val] = groups[val] || [];
        groups[val].push(msg);
        return groups;
      } else {
        return groups;
      }
    }, {});
  }
  //

  myProfiles$(): Observable<IMyProfile[]> {
    return this._store
      .select(state => state.myProfileReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  //
  getEffectiveSelectedIChat$(): Observable<IChat> {
    return this._store
      .select(state => state.uiReducer.selectedChat)
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.chatReducer.filter(chat => chat.type === CHANNEL)
          )
        ),
        map(res => {
          const selectedChat: MainChat = res[0];

          if (selectedChat) {
            const effectiveChat: IChat = res[1].find(
              chat => chat.id === selectedChat.id
            );
            return effectiveChat;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getNumberOfChatsHavingUnreadMsgs$(): Observable<number> {
    return this._store
      .select(state => state.uiReducer.selectedList)
      .pipe(
        withLatestFrom(
          this.chats$.pipe(distinctUntilChanged()),
          this.channels$.pipe(distinctUntilChanged())
        ),
        map(res => {
          const selectedType = res[0];
          if (selectedType === CHANNELS) {
            return res[1].filter(chat => chat.unreadCounter);
          } else if (selectedType === CHATS) {
            return res[2].filter(channel => channel.unreadCounter);
          }
        }),
        map(list => (list ? list.length : 0)),
        distinctUntilChanged()
      );
  }

  getEffectiveSelectedChat$(): Observable<MainChat> {
    return this._store
      .select(state => state.uiReducer.selectedSubChat)
      .pipe(
        withLatestFrom(
          this._store
            .select(state =>
              state.mainChatReducer.filter(
                chat =>
                  chat.type !== INDIVIDUAL ||
                  (chat.type === INDIVIDUAL &&
                    (chat.contact || chat.unknownContact))
              )
            )
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const selectedChat: MainChat = res[0];

          if (selectedChat) {
            const effectiveChat: MainChat = res[1].find(
              chat => chat.id === selectedChat.id
            );
            return effectiveChat;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getEffectiveSubSelectedChat$(): Observable<MainChat> {
    return this._store
      .select(state => state.uiReducer.selectedSubChat)
      .pipe(
        withLatestFrom(
          this._store
            .select(state =>
              state.mainChatReducer.filter(
                chat => chat.type === GROUP || chat.type === CHANNEL
              )
            )
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          const selectedSubChat: MainChat = res[0];

          if (selectedSubChat) {
            const effectiveSubChat: MainChat = res[1].find(
              chat => chat.id === selectedSubChat.id
            );
            return effectiveSubChat;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  getAppChannelSettings$(): Observable<AppChannelSettingsUI> {
    return this._store
      .select(state => state.appChannelSettingsReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getEffectiveChannelPost$(): Observable<IMessage> {
    return this._store
      .select(state => state.uiReducer.selectedMessage)
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.messageReducer.filter(msg => msg.isChannelPost)
          )
        ),
        map(res => {
          const selectedMessage: IMessage = res[0];
          if (selectedMessage) {
            return res[1].find(
              msg =>
                (selectedMessage.message_id &&
                  selectedMessage.message_id === msg.message_id) ||
                (!selectedMessage.message_id &&
                  selectedMessage.reference === msg.reference)
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getEffectiveSelectedMessage$(): Observable<IMessage> {
    return this._store
      .select(state => state.uiReducer.selectedMessage)
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.messageReducer.filter(msg => {
              return !msg.reply_to_message_id;
            })
          )
        ),
        map(res => {
          const selectedMessage: IMessage = res[0];

          if (selectedMessage) {
            const effectiveMessage: IMessage = res[1].find(
              msg =>
                (selectedMessage.message_id &&
                  selectedMessage.message_id === msg.message_id) ||
                (!selectedMessage.message_id &&
                  selectedMessage.reference === msg.reference)
            );
            return effectiveMessage;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getEffectiveSelectedSubGroupMessage$(): Observable<IMessage> {
    return this._store
      .select(state => state.uiReducer.subParentMessage)
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.messageReducer.filter(msg => {
              return !msg.reply_to_message_id && msg.group_id;
            })
          )
        ),
        map(res => {
          const selectedMessage: IMessage = res[0];

          if (selectedMessage) {
            const effectiveMessage: IMessage = res[1].find(
              msg =>
                (selectedMessage.message_id &&
                  selectedMessage.message_id === msg.message_id) ||
                (!selectedMessage.message_id &&
                  selectedMessage.reference === msg.reference)
            );
            return effectiveMessage;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getEffectiveMediaMessage$(): Observable<IMessage> {
    return this._store
      .select(state => state.uiReducer.selectedMediaMessage)
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.messageReducer.filter(msg => {
              return (
                msg.type === MessageTypes.PHOTO ||
                msg.type === MessageTypes.GIF ||
                msg.type === MessageTypes.VIDEO
              );
            })
          )
        ),
        map(res => {
          const selectedMediaMessage: IMessage = res[0];

          if (selectedMediaMessage) {
            const effectiveMessage: IMessage = res[1].find(
              msg =>
                (selectedMediaMessage.message_id &&
                  selectedMediaMessage.message_id === msg.message_id) ||
                (!selectedMediaMessage.message_id &&
                  selectedMediaMessage.reference === msg.reference)
            );
            return effectiveMessage;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getChatByID$(chatID: string): Observable<MainChat> {
    return combineLatest(
      this.chats$.pipe(distinctUntilChanged()),
      // TODO: not know what effect
      this.channelsAndSubChannels$.pipe(distinctUntilChanged())
    ).pipe(
      map(res => {
        if (chatID) {
          let effectiveChat: MainChat = res[0].find(chat => chat.id === chatID);
          if (!effectiveChat) {
            effectiveChat = res[1].find(channel => channel.id === chatID);
          }
          return effectiveChat;
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getChatGroupById$(chatID: string): Observable<IChat> {
    return this._store
      .select(state => state.chatReducer)
      .pipe(
        map(res => {
          return res.find(chat => chat.id === chatID);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getProfileByID$(userID: string): Observable<IProfile> {
    return this._store
      .select(state => state.profileReducer)
      .pipe(
        map(res => {
          return res.find(profile => profile.user_id === userID);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getNumberOfNotViewedMessagesOfSelectedUser$(): Observable<number> {
    return this._store
      .select(state =>
        state.messageReducer
          .filter(msg => msg.reply_to_message_id && msg.group_type === 101)
          .reverse()
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedMessage)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.selectedUserId)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          if (res[0] && res[1] && res[2]) {
            const parentMessage: IMessage = res[1];
            const specificUserID: string = res[2];
            const filteredList = res[0].filter(
              msg =>
                msg.reply_to_message_id === parentMessage.message_id &&
                (msg.sender_id === specificUserID ||
                  (msg.send_to_user_id === specificUserID &&
                    !msg.loggedInIsSender)) &&
                !msg.isViewed
            );
            return filteredList.length;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  // groupByOnMessages(messages: IMessage[], prop) {
  //   return messages.reduce((groups, item) => {
  //     const val = item[prop];
  //     groups[val] = groups[val] || [];
  //     groups[val].push(item);
  //     return groups;
  //   }, {});
  // }

  getMediaMessagesInChat$(): Observable<IMessage[]> {
    return this._store
      .select(state =>
        state.messageReducer.filter(msg => {
          return (
            msg.type === MessageTypes.PHOTO ||
            msg.type === MessageTypes.GIF ||
            msg.type === MessageTypes.VIDEO
          );
        })
      )
      .pipe(
        withLatestFrom(
          this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged()),
          this._store
            .select(state => state.uiReducer.allChannelsSelected)
            .pipe(distinctUntilChanged())
        ),
        map(res => {
          return res[0].filter(msg => {
            const mainChat: MainChat = res[1];
            const allChannelsSelected: boolean = res[2];

            if (mainChat && mainChat.type === INDIVIDUAL) {
              return (
                !msg.group_id &&
                (mainChat.id === msg.sender_id ||
                  mainChat.id === msg.receiver_id)
              );
            } else if (mainChat && mainChat.type === GROUP) {
              return msg.group_id === mainChat.id && msg.group_type === 100;
            } else if (mainChat && mainChat.type === CHANNEL) {
              return msg.group_id === mainChat.id && msg.group_type === 101;
            } else if (!mainChat && allChannelsSelected) {
              return msg.group_type === 101;
            }
          });
        }),
        distinctUntilChanged(isEqual)
      );
  }

  /* Merchant Web Only */

  get campaignUiState$(): Observable<CampaignUI> {
    return this._store
      .select(state => state.campaignUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getCampaignList$(): Observable<ICampaign[]> {
    return this._store
      .select(state => state.campaignReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.campaignUiReducer.currentPage)
        ),
        map(([campaigns, currentPage]) => {
          let targetCampaigns: ICampaign[] = [];
          if (currentPage && currentPage.length > 0) {
            targetCampaigns = campaigns.filter(campaign =>
              currentPage.find(campaignId => campaignId === campaign.campaignId)
            );
          }
          return targetCampaigns;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getCampaignAwardMessage(messageId: string): Observable<IMessage> {
    return this._store
      .select(state => state.awardReducer)
      .pipe(
        map(res => res.filter(message => message.message_id === messageId)[0]),
        distinctUntilChanged(isEqual)
      );
  }

  // merging two reducer to get the message in case of it is sending
  getCampaignScopeMessage(messageId: string): Observable<IMessage> {
    return combineLatest(
      this._store.select(state =>
        state.scheduleReducer.find(res => res.message_id === messageId)
      ),
      this._store.select(state =>
        state.messageReducer.find(res => res.message_id === messageId)
      )
    ).pipe(
      map(res => {
        if (res[0] || res[1]) {
          return res[0] ? res[0] : res[1];
        }
      })
    );
  }

  get scheduleMessagesCollection$(): Observable<IMessage[]> {
    // return this._store
    //   .select(state => state.scheduleReducer)
    //   .pipe(
    //     distinctUntilChanged(),
    //     map((data: IMessage[]) => {
    //       return this.groupByDay(
    //         data.sort(
    //           (a: IMessage, b: IMessage) => a.schedule_date - b.schedule_date
    //         )
    //       );
    //     }),
    //     distinctUntilChanged(isEqual)
    //   );
    return this._store
      .select(state => state.scheduleReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get awardMessages$(): Observable<IMessage[]> {
    return this._store
      .select(state => state.awardReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  get scheduleMessages$(): Observable<IMessage[]> {
    return this._store
      .select(state => state.scheduleReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  get uiCollection$(): Observable<UIState> {
    return this._store
      .select(s => s.uiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  get messagesCollection$(): Observable<any> {
    return combineLatest(
      this._store
        .select(state => state.messageReducer)
        .pipe(distinctUntilChanged()),
      this._store.select(state => state.uiReducer)
    ).pipe(
      map(([messages, factors]) => {
        if (factors && factors.selectedChat) {
          return {
            total: messages.length,
            page: factors.currentPageNumber,
            unread: messages.filter((message: IMessage) => !message.seen)
              .length,
            selected: messages.filter((message: IMessage) => message.isSelected)
              .length,
            messages: this.filterMessagesByReceivingAccount(
              this.filterMessagesByDirectionFlag(
                this.filterMessagesByKeyword(messages, factors.searchKeyWord)
              ),
              factors.selectedChat.id
            ).reverse()
          };
        } else {
          return null;
        }
      }),
      map((res: any) => {
        if (res) {
          return {
            total: res.messages.length,
            unread: res.unread,
            selected: res.selected,
            page: this.calcPageAttributes(res.page, res.messages.length),
            messages: this.filterMessagesByPageNumber(res.messages, res.page)
          };
        }
        return res;
      }),
      distinctUntilChanged(isEqual)
    );
  }
  /** Selectors */
  public groupByDay(value: IMessage[]) {
    const byday = [];
    value.forEach((element: IMessage) => {
      const currentDate = new Date(element.schedule_date * 1);
      const currentDateNum = currentDate.setHours(0, 0, 0, 0);
      byday[currentDateNum] = byday[currentDateNum] || [];
      byday[currentDateNum].push(element);
    });

    return byday;
  }

  private filterMessagesByReceivingAccount(
    messages: IMessage[],
    chatId: string
  ): IMessage[] {
    return messages.filter(message => message.reply_to_message_id !== chatId);
  }

  private filterMessagesByDirectionFlag(messages: IMessage[]): IMessage[] {
    return messages.filter(message => message.isChannelPost);
  }

  private filterMessagesByPageNumber(
    messages: IMessage[],
    pageNumber: number
  ): IMessage[] {
    const page = this.calcPageAttributes(pageNumber, messages.length);
    return messages.slice(page.start - 1, page.end);
  }

  private filterMessagesByKeyword(
    messages: IMessage[],
    keyword: string
  ): IMessage[] {
    return messages.filter(message => {
      if (message.text && keyword.length > 0) {
        return this.isMatch(message, keyword);
      } else if (
        keyword.length <= 0 &&
        message.updateStatus !== 'deleted' &&
        message.updateStatus !== 'recalled'
      ) {
        return message;
      }
    });
  }
  private isMatch(message: IMessage, searchText: string): IMessage {
    const isMatch = message.text
      .toLowerCase()
      .indexOf(searchText.toLowerCase());
    if (isMatch > -1) {
      return message;
    }
  }

  private fillterMessagesByParentIdAndMemberId(
    messages: IMessage[],
    parentId: string,
    memberId: string
  ): IMessage[] {
    if (!memberId) {
      return this.groupByParentIdAndMemberId(messages, parentId);
    } else {
      return messages.filter(message => {
        if (
          message.from_admin &&
          message.reply_to_message_id &&
          message.reply_to_message_id === parentId &&
          message.receiver_id === memberId
        ) {
          return message;
        } else if (
          message.reply_to_message_id &&
          message.reply_to_message_id === parentId &&
          message.sender_id === memberId
        ) {
          return message;
        }
      });
    }
  }

  private fillterMessagesByMessageId(messages: IMessage[], parentId): IMessage {
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].message_id === parentId) {
        return messages[i];
      }
    }
  }

  private groupByParentIdAndMemberId(value: IMessage[], parentId: string) {
    const byMember = [];
    const lastObject = [];
    if (value) {
      value.forEach((element: IMessage) => {
        if (!element.from_admin && element.reply_to_message_id === parentId) {
          const index = element.sender_id;
          byMember[index] = byMember[index] || [];
          byMember[index].push(element);
        }
      });
      for (const memberKey in byMember) {
        if (Array.isArray(byMember[memberKey])) {
          lastObject.push(byMember[memberKey][0]);
        }
      }
    }
    return lastObject;
  }

  private calcPageAttributes(currentPageNumber: number, messagesCount: number) {
    const pagesCount = Math.ceil(messagesCount / MERCHANT_PAGE_SIZE);
    const finalPageNumber: number = currentPageNumber;
    const total =
      finalPageNumber * MERCHANT_PAGE_SIZE > messagesCount
        ? messagesCount
        : finalPageNumber * MERCHANT_PAGE_SIZE;
    return {
      current: finalPageNumber,
      start: (finalPageNumber - 1) * MERCHANT_PAGE_SIZE + 1,
      end: total,
      hasPre: finalPageNumber > 1,
      hasNext: finalPageNumber < pagesCount
    };
  }

  /***************************************** Roles  *******************************************/
  get selectedChatRoles$(): Observable<IRole[]> {
    return this._store
      .select(state => state.chatReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.uiReducer.selectedChat)
        ),
        map(([chats, selectedChat]) =>
          chats.find(chat => chat.id === selectedChat.id)
            ? chats.find(chat => chat.id === selectedChat.id).chatRoles
            : []
        ),
        distinctUntilChanged(isEqual)
      );
  }

  /**************************************** Tags ***********************************************/

  get selectedChatTags$(): Observable<ITag[]> {
    return this._store
      .select(state => state.chatReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.uiReducer.selectedChat)
        ),
        map(([chats, selectedChat]) => {
          if (chats && chats.length > 0) {
            return chats.find(chat => chat.id === selectedChat.id)
              .tagsDefinition;
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getFilterTagsByIds$(tags: string[]): Observable<ITag[]> {
    return this.selectedChatTags$.pipe(
      map(res => {
        if (res && res.length > 0 && tags && tags.length > 0) {
          return res.filter(tag => tags.includes(tag.id));
        }
        return [];
      }),
      distinctUntilChanged(isEqual)
    );
  }

  /******************************** Members **********************************************************/
  // getSubChatMember filter out selectedChat
  getSubChatMember$(userId: string, chatId: string): Observable<IChatMember> {
    return this._store.select(state =>
      state.chatMemberReducer.find(
        chat => chat.user_id === userId && chat.chat_id === chatId
      )
    );
  }

  getMembeSubChats$(userId: string, chatId: string): Observable<IChatMember> {
    return this._store.select(state =>
      state.chatMemberReducer.find(
        chat => chat.user_id === userId && chat.chat_id === chatId
      )
    );
  }

  // getMemberSubChats filter out selectedChat
  getMemberSubChats$(userId: string): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatMemberReducer.filter(mbr => mbr.user_id === userId)
      )
      .pipe(
        withLatestFrom(this._store.select(s => s.chatReducer)),
        map(([memberChats, chats]) => {
          const localChats = memberChats.map(mbrChat => mbrChat.chat_id);
          return chats.filter(chat => localChats.includes(chat.id));
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // getSubChatMembers
  getSubChatMembers$(chatId: string): Observable<IChatMember[]> {
    return this._store
      .select(state =>
        state.chatMemberReducer.filter(mbr => mbr.chat_id === chatId)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getSubChatAdmins$(chatId: string): Observable<IChatMember[]> {
    return this._store
      .select(state =>
        state.chatMemberReducer.filter(
          mbr => mbr.chat_id === chatId && mbr.isAdmin
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  // getSelectedChatMembers from chatMembers filter out selectedChat
  get selectedChatMembers$(): Observable<IChatMember[]> {
    return this._store
      .select(state => state.chatMemberReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.profileReducer),
          this._store.select(state => state.uiReducer.selectedChat),
          this._store.select(state =>
            state.membersUiReducer.currentPage.map(member => member.user_id)
          )
        ),
        map(([chatMembers, profiles, selectedChat, currentPage]) => {
          const effectiveProfiles: IProfile[] = [];
          return chatMembers
            .filter(member =>
              currentPage.find(memberId => memberId === member.user_id)
            )
            .filter(ctMember => {
              const existingProfile = profiles.find(
                pr =>
                  pr.user_id === ctMember.user_id &&
                  ctMember.chat_id === selectedChat.id
              );
              if (existingProfile) {
                effectiveProfiles.push(existingProfile);
              }
              return existingProfile;
            })
            .map(chMember => {
              return {
                ...chMember,
                ...effectiveProfiles.find(
                  prof => prof.user_id === chMember.user_id
                )
              };
            });
        }),
        distinctUntilChanged(isEqual)
      );
  }
  // currentPage.find(chatId => chatId === event.id)

  // return this._store
  // .select(state =>
  //   state.chatReducer.filter(chat => chat.type === EVENT_CHANNEL)
  // )
  // .pipe(distinctUntilChanged())
  // .pipe(
  //   withLatestFrom(
  //     this._store.select(state =>
  //       state.eventUiReducer.currentPage.map(chat => chat.id)
  //     )
  //   ),
  //   distinctUntilChanged(),
  //   map(([events, currentPage]) => {
  //     let targetEvents: IChat[] = [];
  //     if (currentPage && currentPage.length > 0) {
  //       targetEvents = events.filter(event =>
  //         currentPage.find(chatId => chatId === event.id)
  //       );
  //     }
  //     return targetEvents;
  //   })
  // );

  // observable to get tester members
  get testerMembers$(): Observable<IChatMember[]> {
    return this._store
      .select(state => state.chatMemberReducer.filter(member => member.tester))
      .pipe(
        withLatestFrom(
          this._store.select(state => state.profileReducer),
          this._store.select(state => state.uiReducer.selectedChat)
        ),
        map(([chatMembers, profiles, selectedChat]) => {
          return chatMembers
            .filter(ctMember => {
              return profiles.find(
                pr =>
                  pr.user_id === ctMember.user_id &&
                  ctMember.chat_id === selectedChat.id
              );
            })
            .map(chMember => {
              return {
                ...chMember,
                ...profiles.find(prof => prof.user_id === chMember.user_id)
              };
            });
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get qrCodeToAddTester$(): Observable<string> {
    return this._store
      .select(state => {
        if (state.uiReducer && state.uiReducer.selectedChat) {
          return state.uiReducer.selectedChat.inivite_link;
        }
      })
      .pipe(
        distinctUntilChanged(),
        map(link => link + '&tester=1')
      );
  }

  get configQrCode$(): Observable<string> {
    return combineLatest(
      this._store
        .select(state => state.uiReducer.selectedChat.id)
        .pipe(distinctUntilChanged()),
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged())
    ).pipe(
      map(([selectedChatId, chats]) => {
        const targetChat = chats.find(chat => chat.id === selectedChatId);
        if (targetChat) {
          return targetChat.configQrCode;
        } else {
          return null;
        }
      })
    );
  }

  /**************************************************************************************/
  // Event Selectors IChat[]
  getEventList$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === EVENT_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.eventUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([events, currentPage]) => {
          let targetEvents: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetEvents = events.filter(event =>
              currentPage.find(chatId => chatId === event.id)
            );
          }
          return targetEvents;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSelectedEvent$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === EVENT_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state => state.eventUiReducer.selectedEvent)
        ),
        map(([events, selectedEvent]) => {
          if (selectedEvent) {
            return events.find(event => event.id === selectedEvent.id);
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  /*************************************************************************************************/
  /********************** Booking Selectors ***********************************/
  getBookingList$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === BOOKING_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.bookingUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([bookings, currentPage]) => {
          let targetBookings: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetBookings = bookings.filter(booking =>
              currentPage.find(chatId => chatId === booking.id)
            );
          }
          return targetBookings;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getBookingListWithProduct$(): Observable<IChat[]> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat => chat.type === BOOKING_CHANNEL && chat.product_id > 0
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.bookingUiReducer.currentPage.map(chat => chat.id)
          )
        ),
        map(([bookings, currentPage]) => {
          let targetBookings: IChat[] = [];
          if (currentPage && currentPage.length > 0) {
            targetBookings = bookings.filter(booking =>
              currentPage.find(chatId => chatId === booking.id)
            );
          }
          return targetBookings;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // previously added need revisit (in between)

  getSelectedBooking$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === BOOKING_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state => state.bookingUiReducer.selectedBooking)
        ),
        map(([booking, selectedBooking]) => {
          if (selectedBooking) {
            return booking.find(
              bookingChannel => bookingChannel.id === selectedBooking.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  // get selected booking periods
  getSelectedBookingPeriods$(): Observable<BookingPeriods> {
    return this.getSelectedBooking$().pipe(
      withLatestFrom(this._store.select(state => state.bookingPeriodsReducer)),
      map(([selectedBooking, bookingPeriods]) => {
        if (selectedBooking && bookingPeriods) {
          return bookingPeriods.find(
            period => period.chat_id === selectedBooking.id
          );
        } else {
          return null;
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  //
  getSelectedBookingsHolidays$(): Observable<BookingExceptions> {
    return this.getSelectedBooking$().pipe(
      withLatestFrom(
        this._store.select(state => state.bookingExceptionsReducer)
      ),
      map(([selectedBooking, bookingExceptions]) => {
        if (selectedBooking && bookingExceptions) {
          return bookingExceptions.find(
            holiday => holiday.chat_id === selectedBooking.id
          );
        } else {
          return null;
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  //
  getBookingInCreationProcess$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === BOOKING_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.bookingUiReducer.creationProcessBooking
          )
        ),
        map(([bookings, creationProcessBooking]) => {
          if (creationProcessBooking) {
            return bookings.find(
              booking => booking.id === creationProcessBooking.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get bookingUiState$(): Observable<BookingUI> {
    return this._store
      .select(state => state.bookingUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  // previously added need revisit (in between)

  getBookingPeriods$(bookingChannelID: string): Observable<BookingPeriods> {
    return this._store
      .select(state =>
        state.bookingPeriodsReducer.find(
          period => period.chat_id === bookingChannelID
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }
  // to get reservations
  getMyBookings$(): Observable<MyBookings> {
    return this._store
      .select(state => state.myBookingsReducer)
      .pipe(
        withLatestFrom(this.getSelectedBooking$()),
        map(([myBookings, selectedBooking]) => {
          if (selectedBooking) {
            return myBookings.find(
              booking => booking.chat_id === selectedBooking.id
            );
          } else {
            this._bookingDispatcher.listBookingUI();
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  // to get holidays
  getBookingsHolidays$(chatId: string): Observable<BookingExceptions> {
    return this._store
      .select(state =>
        state.bookingExceptionsReducer.find(
          bookingExceptions => bookingExceptions.chat_id === chatId
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  /*************************************************************************************************/
  /********************** Channel App Selectors ***********************************/

  get currentPage$(): Observable<number> {
    return this._store
      .select(state => state.channelAppUiReducer.currentScreen)
      .pipe(distinctUntilChanged());
  }

  get navMenuItems$(): Observable<LocalItem[]> {
    return this._store
      .select(state => state.itemReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            item =>
              item.appClassName === AppClassNames.NAV_MENU &&
              item.containerType === ContainerTypes.MENU &&
              item.componentType === ComponentTypes.MENU
          );

          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  navMenuPagesItems(page_id): Observable<LocalItem[]> {
    return this._store
      .select(state => state.itemReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            item =>
              item.appClassName === AppClassNames.NAV_MENU &&
              item.containerType === ContainerTypes.MENU &&
              item.componentType === ComponentTypes.MENU &&
              item.page_id === page_id
          );

          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get navMenuComponent$(): Observable<LocalComponent> {
    return this._store
      .select(state => state.componentReducer)
      .pipe(
        map(res => {
          return res.find(
            comp =>
              comp.appClassName === AppClassNames.NAV_MENU &&
              comp.containerType === ContainerTypes.MENU &&
              comp.component_type === ComponentTypes.MENU
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get navHeaderComponent$(): Observable<LocalComponent> {
    return this._store
      .select(state => state.componentReducer)
      .pipe(
        map(res => {
          return res.find(
            comp =>
              comp.appClassName === AppClassNames.NAV_MENU &&
              comp.containerType === ContainerTypes.HEADER &&
              comp.component_type === ComponentTypes.HEADER
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get channelAppUiCollection$(): Observable<ChannelAppUIState> {
    return this._store
      .select(state => state.channelAppUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  // Tabs

  get tabsContainers$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container => container.appClassName === AppClassNames.TABS
          );

          const newTablist: any[] = [];
          newTablist.length = result.length;
          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  tabPageContainer(page_id): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container =>
              container.appClassName === AppClassNames.TABS &&
              container.page_id === page_id
          );

          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  tabsWithSubPageContainers(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container =>
              container.appClassName === AppClassNames.TABS &&
              container.page_id &&
              container.sub_pages &&
              container.sub_pages.length >= 1
          );

          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  //Main tabs without layout button
  get mainTabsContainers$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container =>
              container.appClassName === AppClassNames.TABS && !container.layout
          );

          const newTablist: any[] = [];
          newTablist.length = result.length;
          return result.sort((a, b) => (a.order < b.order ? -1 : 1));
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get mainItemsContainers$(): Observable<LocalItem[]> {
    return this._store
      .select(state => state.itemReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            item =>
              item.appClassName === AppClassNames.NAV_MENU &&
              item.containerType === ContainerTypes.MENU &&
              item.componentType === ComponentTypes.MENU &&
              !item.layout
          );

          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // Tab Sections
  getTabSections$(parentTabRef: string): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container =>
              container.appClassName === AppClassNames.SECTIONS &&
              container.parentTabRef === parentTabRef
          );
          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getNavMenuItemByRef$(ref: string): Observable<LocalItem> {
    return this._store
      .select(state =>
        state.itemReducer.find(item => {
          if (
            item.appClassName === AppClassNames.NAV_MENU &&
            item.ref === ref
          ) {
            return item;
          }
        })
      )
      .pipe();
  }

  get searchContainers$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container => container.appClassName === AppClassNames.SEARCH
          );
          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get activeSearchContainers$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container =>
              container.appClassName === AppClassNames.SEARCH && container.is_on
          );
          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get activeSearchItem$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.itemReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            item =>
              item.appClassName === AppClassNames.NAV_MENU && item.selected
          );

          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get tabsAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          const result = res.find(
            appClass => appClass.name === AppClassNames.TABS
          );
          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get menusAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          return res.find(
            appClass => appClass.name === AppClassNames.MENUS || appClass.menus
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get sideMenuAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          return res.find(appClass => appClass.name === AppClassNames.NAV_MENU);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get channelDefaultAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          return res.find(
            appClass =>
              appClass.name === AppClassNames.CHANNEl_DEFAULT ||
              appClass.channel_default
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get searchAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          const result = res.find(
            appClass => appClass.name === AppClassNames.SEARCH
          );
          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getFooterComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.FOOTER
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const footerComponents = res[0];
        const selectedTab = res[1];
        if (footerComponents && selectedTab) {
          return footerComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getFooterItemsOfSelectedTab$(sectionRef?: string): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.TABS ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.FOOTER
          )
        )
        .pipe(distinctUntilChanged()),
      this.getFooterComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const footerItems = res[0];
        const parentComp = res[1];
        if (footerItems && parentComp) {
          return footerItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getPostComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.POST
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const postComponents = res[0];
        const selectedTab = res[1];

        if (postComponents && selectedTab) {
          return postComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getPostComponentOfSelectedTabById$(id?: string): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.POST
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSectionById$(id).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const postComponents = res[0];
        const selectedTab = res[1];

        if (postComponents && selectedTab) {
          return postComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getMenuComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.MENU
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const menuComponents = res[0];
        const selectedTab = res[1];
        if (menuComponents && selectedTab) {
          return menuComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getWalletComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.WALLET
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const walletComponents = res[0];
        const selectedTab = res[1];
        if (walletComponents && selectedTab) {
          return walletComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getSearchComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const searchComponents = res[0];
        const selectedTab = res[1];
        if (searchComponents && selectedTab) {
          return searchComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getSearchComponentOfTab$(tab): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged())
    ).pipe(
      map(res => {
        const searchComponents = res[0];

        if (searchComponents && tab) {
          return searchComponents.find(
            comp => comp.parentContainerRef === tab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getSearchComponentOfSelectedItem$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.NAV_MENU ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedItemOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const searchComponents = res[1].component;
        const selectedItem: LocalItem = res[1];
        if (searchComponents && selectedItem) {
          return searchComponents.find(
            comp => comp.parentContainerRef === selectedItem.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getMapSearchComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.MAP_SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const searchComponents = res[0];
        const selectedTab = res[1];
        if (searchComponents && selectedTab) {
          return searchComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getMapSearchComponentOfSelectedItem$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.NAV_MENU ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.MAP_SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedItemOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const searchComponents = res[0];
        const selectedItem: LocalItem = res[1];

        if (searchComponents && selectedItem) {
          return searchComponents.find(
            comp => comp.parentContainerRef === selectedItem.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getWebviewComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.WEB_VIEW
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const webviewComponents = res[0];
        const selectedTab = res[1];
        if (webviewComponents && selectedTab) {
          return webviewComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getWebviewComponentOfTab$(tab): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.WEB_VIEW
          )
        )
        .pipe(distinctUntilChanged())
    ).pipe(
      map(res => {
        const webviewComponents = res[0];
        const selectedTab = tab;
        if (webviewComponents && selectedTab) {
          return webviewComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getAcceptInvitationCompOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.INVITATION_ACCEPT
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const invitationAcceptComps = res[0];
        const selectedTab = res[1];
        if (invitationAcceptComps && selectedTab) {
          return invitationAcceptComps.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getRejectInvitationCompOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.INVITATION_CANCEL
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const invitationRejectComps = res[0];
        const selectedTab = res[1];
        if (invitationRejectComps && selectedTab) {
          return invitationRejectComps.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getCardComponentOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalComponent> {
    return combineLatest(
      this._store
        .select(state =>
          state.componentReducer.filter(
            comp =>
              (comp.appClassName === AppClassNames.TABS ||
                comp.appClassName === AppClassNames.SECTIONS) &&
              comp.component_type === ComponentTypes.CARD
          )
        )
        .pipe(distinctUntilChanged()),
      this.getEffectiveSelectedTabOrSection$(sectionRef).pipe(
        distinctUntilChanged()
      )
    ).pipe(
      map(res => {
        const cardComponents = res[0];
        const selectedTab = res[1];
        if (cardComponents && selectedTab) {
          return cardComponents.find(
            comp => comp.parentContainerRef === selectedTab.ref
          );
        } else {
          return {};
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getMenuItemsOfSelectedTab$(sectionRef?: string): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.TABS ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.MENU
          )
        )
        .pipe(distinctUntilChanged()),
      this.getMenuComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const footerItems = res[0];
        const parentComp = res[1];
        if (footerItems && parentComp) {
          return footerItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getMenuRefOfSelectedTab$(sectionRef?: string): Observable<any> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.TABS ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.MENU
          )
        )
        .pipe(distinctUntilChanged()),
      this.getMenuComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const footerItems = res[0];
        const parentComp = res[1];
        if (footerItems && parentComp) {
          return parentComp.ref;
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getSearchItemsOfSelectedTab$(sectionRef?: string): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.TABS ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getSearchComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const searchItems = res[0];
        const parentComp = res[1];
        if (searchItems && parentComp) {
          return searchItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getSearchItemsOfSelectedItem$(sectionRef?: string): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.NAV_MENU ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getSearchComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const searchItems = res[0];
        const parentComp = res[1];
        if (searchItems && parentComp) {
          return searchItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getSearchItemsOfTab$(tab) {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.NAV_MENU ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getSearchComponentOfTab$(tab)
    ).pipe(
      map(res => {
        const searchItems = res[0];
        const parentComp = res[1];
        if (searchItems && parentComp) {
          return searchItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }
  getMapSearchItemsOfSelectedTab$(
    sectionRef?: string
  ): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.TABS ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.MAP_SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getMapSearchComponentOfSelectedTab$(sectionRef)
    ).pipe(
      map(res => {
        const searchItems = res[0];
        const parentComp = res[1];

        if (searchItems && parentComp) {
          const result = searchItems.filter(
            item => item.parentComponentRef === parentComp.ref
          );

          return result.sort(ascendingly);
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getMapSearchItemsOfSelectedItem$(
    sectionRef?: string
  ): Observable<LocalItem[]> {
    return combineLatest(
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              (item.appClassName === AppClassNames.NAV_MENU ||
                item.appClassName === AppClassNames.SECTIONS) &&
              item.componentType === ComponentTypes.MAP_SEARCH
          )
        )
        .pipe(distinctUntilChanged()),
      this.getMapSearchComponentOfSelectedItem$(sectionRef)
    ).pipe(
      map(res => {
        const searchItems = res[0];
        const parentComp = res[1];
        if (searchItems && parentComp) {
          return searchItems
            .filter(item => item.parentComponentRef === parentComp.ref)
            .sort(ascendingly);
        } else {
          return [];
        }
      }),
      distinctUntilChanged(isEqual)
    );
  }

  getEffectiveSelectedTabOrSection$(sectionRef?: string) {
    if (!sectionRef) {
      return this._store
        .select(state =>
          state.containerReducer.filter(
            containers =>
              containers.appClassName === AppClassNames.TABS ||
              containers.appClassName === AppClassNames.SECTIONS
          )
        )
        .pipe(
          map(res => {
            if (res) {
              return res.find(cont => cont.selected);
            }
          }),
          distinctUntilChanged(isEqual)
        );
    } else {
      return this._store
        .select(state =>
          state.containerReducer.find(
            container =>
              (container.appClassName === AppClassNames.TABS ||
                container.appClassName === AppClassNames.SECTIONS) &&
              container.ref === sectionRef
          )
        )
        .pipe(distinctUntilChanged(isEqual));
    }
  }

  getEffectiveSelectedTabOrSectionById$(id?: string) {
    if (!id) {
      return this._store
        .select(state =>
          state.containerReducer.filter(
            containers =>
              containers.appClassName === AppClassNames.TABS ||
              containers.appClassName === AppClassNames.SECTIONS
          )
        )
        .pipe(
          map(res => {
            if (res) {
              return res.find(cont => cont.selected);
            }
          }),
          distinctUntilChanged(isEqual)
        );
    } else {
      return this._store
        .select(state =>
          state.containerReducer.find(
            container =>
              (container.appClassName === AppClassNames.TABS ||
                container.appClassName === AppClassNames.SECTIONS) &&
              container.id === id
          )
        )
        .pipe(distinctUntilChanged(isEqual));
    }
  }

  getEffectiveSelectedItemOrSection$(sectionRef?: string) {
    if (!sectionRef) {
      return this._store
        .select(state =>
          state.itemReducer.filter(
            containers =>
              containers.appClassName === AppClassNames.NAV_MENU ||
              containers.appClassName === AppClassNames.SECTIONS
          )
        )
        .pipe(
          map(res => {
            if (res) {
              return res.find(cont => cont.selected);
            }
          }),
          distinctUntilChanged(isEqual)
        );
    } else {
      return this._store
        .select(state =>
          state.itemReducer.find(
            item =>
              item.appClassName === AppClassNames.SECTIONS &&
              item.ref === sectionRef
          )
        )
        .pipe(distinctUntilChanged(isEqual));
    }
  }

  get appInfo$(): Observable<AppInfo> {
    return this._store
      .select(state => state.appConfigReducer.app_info)
      .pipe(distinctUntilChanged(isEqual));
  }

  getEventInCreationProcess$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.type === EVENT_CHANNEL)
      )
      .pipe(
        withLatestFrom(
          this._store.select(state => state.eventUiReducer.creationProcessEvent)
        ),
        map(([events, creationProcessEvent]) => {
          if (creationProcessEvent) {
            return events.find(event => event.id === creationProcessEvent.id);
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get eventUiState$(): Observable<EventUI> {
    return this._store
      .select(state => state.eventUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get systemConfig$(): Observable<SystemConfig> {
    return this._store
      .select(state => state.appConfigReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get systemColors$(): Observable<AppColors> {
    return this._store
      .select(state => state.appConfigReducer.color)
      .pipe(distinctUntilChanged(isEqual));
  }

  get appReleaseList$(): Observable<AppRelease[]> {
    return this._store
      .select(state => state.appReleaseReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getAppReleaseById$(id: number): Observable<AppRelease> {
    return this._store
      .select(state => state.appReleaseReducer.currentPage)
      .pipe(
        map(r => r.find(a => a.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  get appPublishingInfo$(): Observable<AppPublishInfo> {
    return this._store
      .select(state => state.appPublishingReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get templates$(): Observable<AppTemplate[]> {
    return this._store
      .select(state => state.templateReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get localAppInfo$(): Observable<LocalAppInfo> {
    return this._store
      .select(state => state.appLocalInfoReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  // splash
  get splashFooter$(): Observable<LocalContainer> {
    return this._store
      .select(state =>
        state.containerReducer.find(
          cont =>
            cont.appClassName === AppClassNames.SPLASH &&
            cont.container_type === ContainerTypes.FOOTER
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get splashFooterButton$(): Observable<LocalComponent> {
    return this._store
      .select(state =>
        state.componentReducer.filter(
          cont =>
            cont.appClassName === AppClassNames.SPLASH &&
            cont.component_type === ComponentTypes.BUTTON
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.containerReducer.find(
              cont =>
                cont.appClassName === AppClassNames.SPLASH &&
                cont.container_type === ContainerTypes.FOOTER
            )
          )
        ),
        map(([buttonSplashComponents, parentContainer]) => {
          return buttonSplashComponents.find(
            btn => btn.parentContainerRef === parentContainer.ref
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get splashPages$(): Observable<LocalComponent[]> {
    return this._store
      .select(state =>
        state.componentReducer.filter(
          cont =>
            cont.appClassName === AppClassNames.SPLASH &&
            cont.component_type === ComponentTypes.PAGE
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.containerReducer.find(
              cont =>
                cont.appClassName === AppClassNames.SPLASH &&
                cont.container_type === ContainerTypes.HEADER
            )
          )
        ),
        map(([splashPagesComponents, parentContainer]) => {
          const res = splashPagesComponents.filter(
            page => page.parentContainerRef === parentContainer.ref
          );
          return res.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // splash header
  get splashHeader$(): Observable<LocalContainer> {
    return this._store
      .select(state =>
        state.containerReducer.find(
          cont =>
            cont.appClassName === AppClassNames.SPLASH &&
            cont.container_type === ContainerTypes.HEADER
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get selectedSplashPage$(): Observable<LocalComponent> {
    return this._store
      .select(state =>
        state.componentReducer.filter(
          cont =>
            cont.appClassName === AppClassNames.SPLASH &&
            cont.component_type === ComponentTypes.PAGE
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(state =>
            state.containerReducer.find(
              cont =>
                cont.appClassName === AppClassNames.SPLASH &&
                cont.container_type === ContainerTypes.HEADER
            )
          )
        ),
        map(([splashPagesComponents, parentContainer]) => {
          return splashPagesComponents.find(
            page =>
              page.parentContainerRef === parentContainer.ref && page.selected
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // App Profile

  get profileContainers$(): Observable<LocalContainer[]> {
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.filter(
            container => container.appClassName === AppClassNames.FORMS
          );
          return result.sort(ascendingly);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get profilesAppClass$(): Observable<LocalAppClass> {
    return this._store
      .select(state => state.appClassReducer)
      .pipe(
        map(res => {
          const result = res.find(
            appClass => appClass.name === AppClassNames.FORMS
          );
          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get couponUiState$(): Observable<CouponUI> {
    return this._store
      .select(state => state.couponUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getCouponList$(): Observable<ICoupon[]> {
    return this._store
      .select(state => state.couponReducer)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.couponUiReducer.currentPage)
        ),
        map(([coupons, currentPage]) => {
          let targetCampaigns: ICoupon[] = [];
          if (currentPage && currentPage.length > 0) {
            targetCampaigns = coupons.filter(coupon =>
              currentPage.find(
                couponId => couponId.couponId === coupon.couponId
              )
            );
          }
          return targetCampaigns;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getCoupon(couponId: string): Observable<ICoupon> {
    return this._store
      .select(state => state.couponReducer)
      .pipe(
        map(res => {
          return res.find(coupon => coupon.couponId === couponId);
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getNewArticle$(ref: number): Observable<InstantArticle> {
    return this._store
      .select(
        state => state.instantReducer.filter(res => res.reference === ref)[0]
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get botMenus$() {
    return this._store
      .select(s => s.menuUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getBotMenuWRefId$(refId: string) {
    return this._store
      .select(s =>
        s.menuUiReducer.currentPage.find(res => res.menu_ref === refId)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get mapMarkers$() {
    return this._store
      .select(s => s.mapMarkerReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getMapMarkerWId$(id: string) {
    return this._store
      .select(s => s.mapMarkerReducer.currentPage.find(res => res.id === id))
      .pipe(distinctUntilChanged(isEqual));
  }

  get privileges$() {
    return this._store
      .select(s => s.uiReducer.privileges)
      .pipe(distinctUntilChanged(isEqual));
  }

  checkPrivileges$(privillage) {
    return this._store
      .select(s => s.uiReducer.privileges.filter(priv => priv === privillage))
      .pipe(distinctUntilChanged(isEqual));
  }
  get modules$() {
    return this._store
      .select(s => s.uiReducer.modules)
      .pipe(distinctUntilChanged(isEqual));
  }

  get privilegesModule$() {
    return this._store
      .select(s => s.uiReducer.privilegesModules)
      .pipe(distinctUntilChanged(isEqual));
  }

  // Sub Group Selectors
  getSelectedSubGroup$(): Observable<IChat> {
    return this._store
      .select(state => state.chatReducer.filter(chat => chat.type === GROUP))
      .pipe(
        withLatestFrom(
          this._store.select(state => state.subGroupUiReducer.selectedSubGroup)
        ),
        map(([subGroups, selectedSubGroup]) => {
          if (selectedSubGroup) {
            return subGroups.find(
              subGroup => subGroup.id === selectedSubGroup.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSelectedSubChannel$(): Observable<IChat> {
    return this._store
      .select(state => state.chatReducer.filter(chat => chat.type === CHANNEL))
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.subChannelUiReducer.selectedSubGroup
          )
        ),
        map(([subGroups, selectedSubGroup]) => {
          if (selectedSubGroup) {
            return subGroups.find(
              subGroup => subGroup.id === selectedSubGroup.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSelectedVirtualApp$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.vapp && chat.vapp == 1)
      )
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.virtualAppUiReducer.selectedSubGroup
          )
        ),
        map(([subGroups, selectedSubGroup]) => {
          if (selectedSubGroup) {
            return subGroups.find(
              subGroup => subGroup.id === selectedSubGroup.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSubGroupInCreationProcess$(): Observable<IChat> {
    return this._store
      .select(state => state.chatReducer.filter(chat => chat.type === GROUP))
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.subGroupUiReducer.creationProcessSubChat
          )
        ),
        map(([subGroups, creationProcessSubChat]) => {
          if (creationProcessSubChat) {
            return subGroups.find(
              subGroup => subGroup.id === creationProcessSubChat.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSubChannelInCreationProcess$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(
          chat => chat.type === CHANNEL && chat.subChannel
        )
      )
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.subChannelUiReducer.creationProcessSubChat
          )
        ),
        map(([subGroups, creationProcessSubChat]) => {
          if (creationProcessSubChat) {
            return subGroups.find(
              subGroup => subGroup.id === creationProcessSubChat.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getVirtualAppInCreationProcess$(): Observable<IChat> {
    return this._store
      .select(state =>
        state.chatReducer.filter(chat => chat.vapp && chat.vapp == 1)
      )
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.virtualAppUiReducer.creationProcessSubChat
          )
        ),
        map(([subGroups, creationProcessSubChat]) => {
          if (creationProcessSubChat) {
            return subGroups.find(
              subGroup => subGroup.id === creationProcessSubChat.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get subGroupUiState$(): Observable<SubChatUI> {
    return this._store
      .select(state => state.subGroupUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get subChannelUiState$(): Observable<SubChatUI> {
    return this._store
      .select(state => state.subChannelUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get virtualAppUiState$(): Observable<SubChatUI> {
    return this._store
      .select(state => state.virtualAppUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  // Maps

  get mapAppMenus$() {
    return this._store
      .select(state =>
        state.appMenuReducer.filter(
          menu => menu.category === APP_MENU_CATEGORIES.MAP
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  // My Page Selectors
  getMyPageByID$(pageID: string): Observable<MyPage> {
    return this._store.select(state =>
      state.myPageReducer.find(page => page.id === pageID)
        ? state.myPageReducer.find(page => page.id === pageID)
        : state.instantPageReducer.find(page => page.id === pageID)
    );
  }

  getMyPageByIDForSection$(pageID: string): Observable<MyPage> {
    return this._store.select(state =>
      state.myPageReducer.find(page => page.id === pageID)
    );
  }

  getLeatestMyPage$(name, temId): Observable<MyPage> {
    return this._store.select(state =>
      state.myPageReducer.find(
        page => page.name === name && page.template_id === temId
      )
    );
  }

  getMyPages$(pageIdToExclude: string): Observable<MyPage[]> {
    return this._store
      .select(state =>
        state.myPageReducer.filter(
          page => page.id != pageIdToExclude && page.name
        )
      )
      .pipe(distinctUntilChanged(isEqual));
  }
  // instant pages selectors
  getInstantPageByID$(pageID: string): Observable<MyPage> {
    return this._store
      .select(state => state.instantPageReducer.find(page => page.id == pageID))
      .pipe(distinctUntilChanged(isEqual));
  }

  getInstantPages$(): Observable<MyPage[]> {
    return this._store
      .select(state =>
        state.instantPageReducer
          .filter(page => !page.hide)
          .sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
      )
      .pipe(distinctUntilChanged(isEqual));
  }
  //
  getMessageStats$(message_id: string): Observable<MenuCallBackStats[]> {
    return this._store
      .select(
        c =>
          c.messageReducer.find(msg => msg.message_id === message_id).menuStats
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getAppPublishingInfo$(): Observable<AppPublishInfo> {
    return this._store
      .select(state => state.appPublishingReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getAppSetupById$(appId: string): Observable<AppJSON> {
    return this._store
      .select(state => state.appPublishingReducer)
      .pipe(
        map(data => {
          if (data && data.apps) {
            return data.apps.find(app => app && app.app_id === appId);
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }
  //
  getAppConfigHistoryList$(): Observable<AppConfigHistoryRecord[]> {
    return this._store
      .select(state => state.appConfigHistoryReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMediaCenterItems$(
    type: string,
    aspectRatio?: string
  ): Observable<IMediaCenter[]> {
    return this._store
      .select(s =>
        s.mediaCenterReducer.filter(c => {
          if (type) {
            return c.type === type && !aspectRatio
              ? c
              : c.aspectRatio === aspectRatio;
          }
          return c;
        })
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  /**************************************************/
  getPaymentStore$() {
    return this._store
      .select(s => s.paymentsReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  /******************************/
  getMyBots$() {
    return this._store
      .select(store =>
        store.profileReducer.filter(item => item.isBot && item.isMyBot)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getBotTemplates$() {
    return this._store
      .select(store =>
        store.profileReducer.filter(item => item.isBot && item.isTemplateBot)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getMobileStoreShops$() {
    return this._store.select(store => store.MobileWorkflowStoreReducer).pipe();
  }

  getMemberPrivileges$(user_id: string): Observable<IChatMember> {
    return this._store
      .select(state => state.chatMemberReducer.filter(member => member.isAdmin))
      .pipe(
        withLatestFrom(
          this._store.select(state => state.uiReducer.selectedChat)
        ),
        map(([chatMembers, selectedChat]) => {
          return chatMembers.find(
            chat => chat.user_id === user_id && chat.chat_id === selectedChat.id
          );
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getChatWelcomeMessage$(messageId: string): Observable<IMessage> {
    return this._store
      .select(
        state =>
          state.welcomeReducer.filter(
            m => m.message_id === messageId || String(m.reference) === messageId
          )[0]
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  // Search feature
  getAppChannelDataByKey$(
    key: CHANNEL_APP_SEARCH_CATEGORIES
  ): Observable<ChannelData> {
    return this._store
      .select(state =>
        state.appChannelDataReducer.find(item => key === item.key)
      )
      .pipe(distinctUntilChanged(isEqual));
  }
  // Maps Configuration
  getMapServices$(): Observable<MapService[]> {
    return this._store
      .select(state => state.mapServiceReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapServiceCurrentScreen$(): Observable<string> {
    return this._store
      .select(state => state.mapServiceReducer.currentMapServiceScreen)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapServiceById$(id: string): Observable<MapService> {
    return this._store
      .select(state => state.mapServiceReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.id === id)),
        distinctUntilChanged(isEqual)
      );
  }
  getMapServiceByServiceID$(serviceID: string): Observable<MapService> {
    return this._store
      .select(state => state.mapServiceReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.service_id === serviceID)),
        distinctUntilChanged(isEqual)
      );
  }

  getMapMarkers$(): Observable<MapMarkerUI> {
    return this._store
      .select(state => state.mapMarkerReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapRoutes$(): Observable<MapRoute[]> {
    return this._store
      .select(state => state.mapRouteReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getMapRouteCurrentScreen$(): Observable<string> {
    return this._store
      .select(state => state.mapRouteReducer.currentMapRouteScreen)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapRouteById$(id: string): Observable<MapRoute> {
    return this._store
      .select(state => state.mapRouteReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  getMapRouteByRouteId$(routeId: string): Observable<MapRoute> {
    return this._store
      .select(state => state.mapRouteReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.route_id === routeId)),
        distinctUntilChanged(isEqual)
      );
  }

  getMapTrips$(): Observable<MapTrip[]> {
    return this._store
      .select(state => state.mapTripReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapTripCurrentScreen$(): Observable<string> {
    return this._store
      .select(state => state.mapTripReducer.currentMapTripScreen)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapTripsById$(id: string): Observable<MapTrip> {
    return this._store
      .select(state => state.mapTripReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  getAssignMapTrips$(): Observable<DriverTrip[]> {
    return this._store
      .select(state => state.driverTripsReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getOtherTrips$(): Observable<DriverTrip[]> {
    return this._store
      .select(state => state.otherTripsReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getSmtp$(): Observable<Smtp> {
    return this._store
      .select(state => state.smtpReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapTripsByTripId$(tripId: string): Observable<MapTrip> {
    return this._store
      .select(state => state.mapTripReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.trip_id === tripId)),
        distinctUntilChanged(isEqual)
      );
  }

  getMapTimeStops$(): Observable<Stops[]> {
    return this._store
      .select(state => state.mapTimeStopsReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapTripUsers$(): Observable<MapTripUser[]> {
    return this._store
      .select(state => state.mapTripUserReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapDriverUsers$(): Observable<MapDriverUser> {
    return this._store
      .select(state => state.mapDriverReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapTripHistory$(): Observable<MapTripsHistoryOpj[]> {
    return this._store
      .select(state => state.mapTripsHistoryReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getMapTripsNotifiers$(): Observable<MapTripsNotifiers[]> {
    return this._store
      .select(state => state.mapTripsNotifiersReducer.currentPage)
      .pipe(distinctUntilChanged());
  }

  getMapTripsNotifiersScreenState$(): Observable<string> {
    return this._store
      .select(state => state.mapTripsNotifiersReducer.currentStateScreen)
      .pipe(distinctUntilChanged(isEqual));
  }

  getNotifiersList$(): Observable<MapTripsNotifiers[]> {
    return this._store
      .select(state => state.mapTripsNotifiersReducer.notifiersList)
      .pipe(distinctUntilChanged(isEqual));
  }
  // Calendar Selectors

  getCalendarList$(): Observable<CalendarTimetable[]> {
    return this._store
      .select(state => state.calendarUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  // get selected calendar
  getSelectedCalendar$(): Observable<CalendarTimetable> {
    return this._store
      .select(state => state.calendarUiReducer.currentPage)
      .pipe(
        withLatestFrom(
          this._store.select(state => state.calendarUiReducer.selectedCalendar)
        ),
        map(([calendars, selectedCalendar]) => {
          if (selectedCalendar) {
            return calendars.find(
              calendar => calendar.id === selectedCalendar.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  // selection of effective calendar in creation
  getCalendarInCreationProcess$(): Observable<CalendarTimetable> {
    return this._store
      .select(state => state.calendarUiReducer.currentPage)
      .pipe(
        withLatestFrom(
          this._store.select(
            state => state.calendarUiReducer.creationProcessCalendar
          )
        ),
        map(([calendars, creationProcessCalendar]) => {
          if (creationProcessCalendar) {
            return calendars.find(
              calendar => calendar.id === creationProcessCalendar.id
            );
          }
        }),
        distinctUntilChanged(isEqual)
      );
  }

  get calendarUiState$(): Observable<CalendarUI> {
    return this._store
      .select(state => state.calendarUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get membersUi$(): Observable<MembersUI> {
    return this._store
      .select(state => state.membersUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get blackListsUi$(): Observable<BlackListsUI> {
    return this._store
      .select(state => state.blackListUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get blackListsPatternsUi$(): Observable<BlackListsUI> {
    return this._store
      .select(state => state.blackListPatternsUiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getWhitelist$(): Observable<Whitelist[]> {
    return this._store
      .select(state => state.whitelistUIReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getWhitelistPatterns$(): Observable<Whitelist[]> {
    return this._store
      .select(state => state.whitelistPatternsUIReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getWhitelistPatternsScreenState$(): Observable<string> {
    return this._store
      .select(state => state.whitelistPatternsUIReducer.screenState)
      .pipe(distinctUntilChanged(isEqual));
  }

  getWhitelistScreenState$(): Observable<WhitelistUI> {
    return this._store
      .select(state => state.whitelistUIReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  get dashboardList$(): Observable<WidgetUI[]> {
    return this._store
      .select(state => state.summaryUIReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getPaymentProviders$(): Observable<PaymentProvider[]> {
    return this._store
      .select(state => state.paymentProvidersReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getAdsroviders$(): Observable<AdsConfig> {
    return this._store
      .select(state => state.adsProvidersReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getPaymentOrders$(): Observable<PaymentOrder[]> {
    return this._store
      .select(state => state.paymentOrdersUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getServiceOrders$(): Observable<ServiceOrder[]> {
    return this._store
      .select(state => state.serviceOrdersUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getProducts$(): Observable<Product[]> {
    return this._store
      .select(state => state.productsUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getProductById$(id: string): Observable<Product> {
    return this._store
      .select(state => state.productsUiReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  getPackageById$(id: string): Observable<Product> {
    return this._store
      .select(state => state.packagesUiReducer.currentPage)
      .pipe(
        map(res => res.find(r => r.id === id)),
        distinctUntilChanged(isEqual)
      );
  }

  getBundles$(): Observable<Bundle[]> {
    return this._store
      .select(state => state.bundlesUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getBundle$(bundleId: string): Observable<Bundle> {
    return this._store
      .select(state =>
        state.bundlesUiReducer.currentPage.find(r => r.id === bundleId)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getGroupDetailsByID$(id: string): Observable<IChat> {
    return this._store
      .select(state => state.chatReducer.find(chat => chat.id === id))
      .pipe(distinctUntilChanged(isEqual));
  }

  getPackages$(): Observable<Package[]> {
    return this._store
      .select(state => state.packagesUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getStores$(): Observable<any[]> {
    return this._store
      .select(state => state.storeUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getSubMenus$(): Observable<SubMenuStore[]> {
    return this._store
      .select(state => state.subMenuUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getSubMenusByType$(type): Observable<SubMenuStore[]> {
    return this._store
      .select(state => state.subMenuUiReducer.currentPage)
      .pipe(
        map(res => {
          const result = res.filter(item => item.type === type);
          return result;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getSubMenusById$(id): Observable<SubMenuStore> {
    return this._store
      .select(state =>
        state.subMenuUiReducer.currentPage.find(item => item.id === id)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getStoreMenus$(): Observable<StoreMenu[]> {
    return this._store
      .select(state => state.storeMenuUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getStoreMenuById$(id: string): Observable<StoreMenu> {
    return this._store
      .select(state =>
        state.storeMenuUiReducer.currentPage.find(item => item.id === id)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getSideMenuStatus(): Observable<UIState> {
    return this._store
      .select(state => state.uiReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMStoreByID$(id: string): Observable<MStore> {
    return this._store
      .select(state =>
        state.storeUiReducer.currentPage.find(store => store.id === id)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getMapMarkerById$(id: string): Observable<MapMarker> {
    return this._store
      .select(state =>
        state.mapMarkerReducer.currentPage.find(marker => marker.id === id)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  getThirdPartyInfo$(): Observable<ThirdPartyInfoUI[]> {
    return this._store
      .select(state => state.thirdPartyInfoReducer.vendors)
      .pipe(distinctUntilChanged(isEqual));
  }

  getResultOfTestThirdPartyInfo$(): Observable<VendorsUI> {
    return this._store
      .select(state => state.thirdPartyInfoReducer)
      .pipe(distinctUntilChanged(isEqual));
  }

  getChartData$(type): Observable<ChartObj[]> {
    const chartType = ChartTypes.find(res => res.key === type);
    return this._store
      .select(state => state.chartReducer[chartType.value])
      .pipe(
        map(res => res.values),
        distinctUntilChanged(isEqual)
      );
  }

  getChartDataStatus$(type): Observable<boolean> {
    const chartType = ChartTypes.find(res => res.key === type);
    return this._store
      .select(state => state.chartReducer[chartType.value])
      .pipe(
        map(res => res.empty),
        distinctUntilChanged(isEqual)
      );
  }

  getChatTheme$(): Observable<ChatThemeOpj> {
    return this._store
      .select(state => state.chatThemesReducer.selectedTheme)
      .pipe(distinctUntilChanged(isEqual));
  }

  getMenuById(menuId): BotMenu {
    let mainMenu = {};
    this.menusAppClass$.subscribe(res => {
      if (res && res.menus) {
        res.menus.find(menu => {
          if (menu.menu_id === menuId) {
            mainMenu = menu;
          }
        });
      }
    });

    return mainMenu;
  }

  get currentSelecetedBillingScreen$(): Observable<string> {
    return this._store
      .select(state => state.billingUiReducer.currentScreen)
      .pipe(distinctUntilChanged(isEqual));
  }
  get currentSelecetedPlan$(): Observable<string> {
    return this._store
      .select(state => state.billingUiReducer.selectedPLan)
      .pipe(distinctUntilChanged(isEqual));
  }
  get isMainPlanCanceled$(): Observable<any> {
    return this._store
      .select(state => state.billingUiReducer.cancel_end)
      .pipe(distinctUntilChanged(isEqual));
  }
  get currentSelecetedBillingPlan$(): Observable<any> {
    return this._store
      .select(state => state.billingUiReducer.currentPlanDetails)
      .pipe(distinctUntilChanged(isEqual));
  }

  get getStripeErrorMessage$(): Observable<string> {
    return this._store
      .select(state => state.billingUiReducer.errorMessage)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingStatus$(): Observable<string> {
    return this._store
      .select(state => state.billingUiReducer.status)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingCoupon$(): Observable<BillingCoupon> {
    return this._store
      .select(state => state.billingUiReducer.coupon)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingInvoices$(): Observable<InvoiceOpj[]> {
    return this._store
      .select(state => state.billingUiReducer.invoicesList)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingInfo$(): Observable<any> {
    return this._store
      .select(state => state.billingUiReducer.billingInfo)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingMainPlan$(): Observable<any> {
    return this._store
      .select(state => state.billingUiReducer.mainPlan)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingPeriodStatus$(): Observable<boolean> {
    return this._store
      .select(state => state.billingUiReducer.billingPlanStatus)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingSubscriptions$(): Observable<SubscriptionItemUI[]> {
    return this._store
      .select(state => state.billingUiReducer.subscriptions)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getBillingsEndTriles$(): Observable<number> {
    return this._store
      .select(state => state.billingUiReducer.trialEndDate)
      .pipe(distinctUntilChanged(isEqual));
  }
  get getMainPlanStatus$(): Observable<boolean> {
    return this._store
      .select(state => state.billingUiReducer.expire)
      .pipe(distinctUntilChanged(isEqual));
  }

  getProductsStore$(): Observable<ProductStore[]> {
    return this._store
      .select(state => state.productsStoreUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }

  getSelecetedProductStore$(): Observable<ProductStore> {
    return this._store
      .select(state => state.productsStoreUiReducer.selecteedProductStore)
      .pipe(distinctUntilChanged());
  }

  getCollectionsList$(): Observable<Collection[]> {
    return this._store
      .select(state => state.collectionsUiReducer.currentPage)
      .pipe(distinctUntilChanged(isEqual));
  }
  getSelectedCollection$(): Observable<Collection> {
    return this._store
      .select(state => state.collectionsUiReducer.selectedItem)
      .pipe(distinctUntilChanged(isEqual));
  }
  getSelectedCollectionByID$(id: number): Observable<Collection> {
    return this._store
      .select(state =>
        state.collectionsUiReducer.currentPage.find(res => res.id == id)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get getEnableSetTemplateBtn$(): Observable<boolean> {
    return this._store
      .select(state => state.uiReducer.enableSetTemplateBtn)
      .pipe(distinctUntilChanged(isEqual));
  }

  getTagsByTabId$(id?: string): Observable<string[]> {
    let tags = [];
    return this._store
      .select(state => state.containerReducer)
      .pipe(
        map(res => {
          const result = res.forEach(containers => {
            if (
              containers.appClassName === AppClassNames.TABS &&
              containers.id === id
            ) {
              if (
                containers.post_tag_values &&
                containers.post_tag_values.length > 0
              ) {
                tags = [...tags, ...containers.post_tag_values];
              }
            }
          });
          return tags;
        }),
        distinctUntilChanged(isEqual)
      );
  }

  getChatTagsByChatID$(chatID: string) {
    return this._store
      .select(state =>
        state.tagsReducer.find(chatTags => chatTags.id === chatID)
      )
      .pipe(distinctUntilChanged(isEqual));
  }

  get getChatUsersList$() {
    return this._store
      .select(state => state.membersUiReducer.chatUsers)
      .pipe(distinctUntilChanged(isEqual));
  }
}
