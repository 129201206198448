<ng-container>
  <ng-container *ngIf="authService.authCollection$ | async as authCollection">
    <div class="signup-form">
      <div class="signup-form-header">
        <div class="title">Welcome to nandbox</div>
        <div class="desc">Get started - it's free. No credit card needed.</div>
        <div class="google" id="loginRef" #loginRef>
          <svg class="svg-icon icon-icon">
            <use xlink:href="/assets/svg/app-extra-icons.svg#icon-google"></use>
          </svg>
          Sign up with Google
        </div>
        <div class="error" id="sign-up"></div>
      </div>
      <div class="signup-form-body">
        <div class="floting-text">Or sign up with email</div>
        <form
          class="signup-form-form"
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
        >
          <div class="faild">
            <label for="name">Name</label>
            <input
              placeholder="Name"
              type="text"
              formControlName="name"
              id="name"
            />
            <div style="min-height: 25px;">
              <app-validation-errors
                *ngIf="form?.get('name')?.errors && form?.get('name')?.touched"
                class="signup-form-error"
                [fieldName]="'Name'"
                [fieldErrors]="form?.get('name')?.errors"
              >
              </app-validation-errors>
            </div>
          </div>

          <div class="faild">
            <label for="country">Country</label>
            <div
              class="btn-group clickable-cursor list"
              dropdown
              [autoClose]="false"
              #dd="bs-dropdown"
              appClickElsewhere
              (clickElsewhere)="dd.hide()"
            >
              <div
                class="menu"
                dropdownToggle
                [class.selected-faild]="dd?.isOpen"
              >
                <div class="menu-select" *ngIf="selectedCountry">
                  {{ selectedCountry }}
                </div>
                <div style="color: #becad3" *ngIf="!selectedCountry">
                  Please select Country
                </div>
                <div
                  class="fas fa-chevron-down clickable-cursor"
                  [class.fas-open]="dd?.isOpen"
                ></div>
              </div>
              <ul
                *dropdownMenu
                role="menu"
                class="dropdown-menu no-copyable-item"
              >
                <div class="arrow"></div>
                <li>
                  <div class="search">
                    <svg class="svg-icon icon-icon">
                      <use
                        xlink:href="/assets/svg/app-extra-icons.svg#icon-search-2"
                      ></use>
                    </svg>
                    <input
                      type="text"
                      placeholder="Search…"
                      autofocus
                      (input)="searchNotfire($event)"
                    />
                  </div>
                </li>
                <div style="max-height: 160px; overflow: overlay;">
                  <li
                    class="menuitem clickable-cursor"
                    role="menuitem"
                    *ngFor="
                      let country of countryOptions
                        | search: searchValue:'value'
                    "
                    (click)="setSelectedCountry(country, dd)"
                    [id]="country?.key"
                  >
                    {{ country?.value }}
                  </li>
                </div>
              </ul>
            </div>
            <div style="min-height: 25px;">
              <app-validation-errors
                *ngIf="
                  form?.get('countryIso')?.errors &&
                  form?.get('countryIso')?.touched
                "
                class="signup-form-error"
                [fieldName]="'Country'"
                [fieldErrors]="form?.get('countryIso')?.errors"
              >
              </app-validation-errors>
            </div>
          </div>

          <div class="faild">
            <label for="name">Email</label>
            <input placeholder="Email" type="email" formControlName="email" />
            <div style="min-height: 25px;">
              <app-validation-errors
                *ngIf="
                  form?.get('email')?.errors && form?.get('email')?.touched
                "
                class="signup-form-error"
                [fieldName]="'Email'"
                [fieldErrors]="form?.get('email')?.errors"
              >
              </app-validation-errors>
            </div>
          </div>

          <div class="faild">
            <label for="name">Password</label>
            <input
              placeholder="Password"
              [type]="hidePassword ? 'password' : 'text'"
              formControlName="password"
              autocomplete="new-password"
            />
            <div style="min-height: 25px;">
              <app-validation-errors
                *ngIf="
                  form?.get('password')?.errors &&
                  form?.get('password')?.touched
                "
                class="signup-form-error"
                [fieldName]="'Password'"
                [fieldErrors]="form?.get('password')?.errors"
              >
              </app-validation-errors>
            </div>
          </div>

          <button type="submit" class="submit-btn" [disabled]="waiting">
            <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i> Sign Up
          </button>

          <div style="font-size: 12px; margin-top: 10px;">
            By creating an account, you agree to our
            <a
              class="a-link"
              href="https://mailchimp.com/legal/terms/"
              target="_blank"
              >Terms</a
            >
            and have read and acknowledge the
            <a
              class="a-link"
              href="https://nandbox.com/privacy-policy/"
              target="_blank"
              >Privacy Policy</a
            >.
          </div>
        </form>
      </div>
    </div>

    <div class="action-header">
      <div class="text">Are you an app owner?</div>
      <a href="/login" class="signin-btn" (click)="goEmail()">
        <div class="icon">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-login-black"
            ></use>
          </svg>
        </div>
        Sign in as an app owner
      </a>
    </div>
  </ng-container>
  <footer class="footer">
    <div class="footer-term">
      <div
        cla
        style="padding-right: 15px;"
        class="link"
        (click)="openLink('https://nandbox.com')"
      >
        © 2023 nandbox ® Inc.
      </div>
      <div
        class="service link"
        (click)="openLink('https://mailchimp.com/legal/terms/')"
      >
        Terms of Service
      </div>
      <div
        style="padding-left: 15px;"
        class="link"
        (click)="openLink('https://nandbox.com/privacy-policy/')"
      >
        Privacy Policy
      </div>
    </div>
    <div class="footer-policy">
      <div style="display: flex; align-items: center; margin-bottom: 10px;">
        <div
          class="service link"
          (click)="openLink('https://nandbox.com/terms/')"
        >
          Terms of Service
        </div>
        <div
          style="padding-left: 15px;"
          class="link"
          (click)="openLink('https://nandbox.com/privacy-policy/')"
        >
          Privacy Policy
        </div>
      </div>
      <div class="link" (click)="openLink('https://nandbox.com')">
        © 2023 nandbox ® Inc.
      </div>
    </div>
    <div class="version">nandbox App Builder - Version 1.6.145.</div>
  </footer>
</ng-container>

<!-- <div class="loading-temp" *ngIf="localStorage?.getRecord('TOKEN')">
  <div>
    <i
      class="fa fa-spinner fa-spin"
      style="font-size:50px; margin-bottom: 10px;"
    ></i>
  </div>
  <div style="font-size:18px">Your template is being configured..</div>
</div> -->
