import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {
  constructor() {}

  preventBackButton() {
    // history.pushState(null, null, document.URL);
    // window.addEventListener('popstate', () =>
    //   history.pushState(null, null, document.URL)
    // );
  }

  goBack() {
    //   go back
    window.addEventListener('popstate', () =>
      window.history.pushState(history.back(), null, document.URL)
    );
  }
}
