import { Action } from '@ngrx/store';
import { MyBookings } from 'src/models/MyBookings';

export const enum MyBookingsActionTypes {
  REQUEST_MY_BOOKINGS = 'REQUEST_MY_BOOKINGS',
  RECEIVE_MY_BOOKINGS = 'RECEIVE_MY_BOOKINGS',
  REQUEST_MY_BOOKING_DAYS = 'REQUEST_MY_BOOKING_DAYS',
  RECEIVE_MY_BOOKING_DAYS = 'RECEIVE_MY_BOOKING_DAYS',
  CANCEL_MY_BOOKING_RESERVATION = 'CANCEL_MY_BOOKING_RESERVATION',
  MY_BOOKING_STATUS_UPDATED = 'MY_BOOKING_STATUS_UPDATED',
  MY_BOOKINGS_USERS_RECEIVED = 'MY_BOOKINGS_USERS_RECEIVED',
  RECEIVED_MY_BOOKINGS_ERROR = 'RECEIVED_MY_BOOKINGS_ERROR',
  RESET = 'RESET'
}

export class RequestMyBookingDays implements Action {
  readonly type = MyBookingsActionTypes.REQUEST_MY_BOOKING_DAYS;
  constructor(
    public chatID: string,
    public startDate: string,
    public endDate: string
  ) {}
}

export class ReceiveMyBookingDays implements Action {
  readonly type = MyBookingsActionTypes.RECEIVE_MY_BOOKING_DAYS;
  constructor(public chatID: string, public bookedDates: string[]) {}
}

export class RequestMyBookings implements Action {
  readonly type = MyBookingsActionTypes.REQUEST_MY_BOOKINGS;
  constructor(public chatID: string, public date: string) {}
}

export class ReceiveMyBookings implements Action {
  readonly type = MyBookingsActionTypes.RECEIVE_MY_BOOKINGS;
  constructor(public myBookings: MyBookings) {}
}

export class MyBookingsUsersReceived implements Action {
  readonly type = MyBookingsActionTypes.MY_BOOKINGS_USERS_RECEIVED;
  constructor(public userNames: object) {}
}

export class CancelMyBookingReservation implements Action {
  readonly type = MyBookingsActionTypes.CANCEL_MY_BOOKING_RESERVATION;
  constructor(
    public chat_id: string,
    public user_id: string,
    public reference: string,
    public date: string,
    public time: string
  ) {}
}

export class MyBookingStatusUpdated implements Action {
  readonly type = MyBookingsActionTypes.MY_BOOKING_STATUS_UPDATED;
  constructor(
    public chat_id: string,
    public user_id: string,
    public reference: string,
    public date: string,
    public time: string,
    public status: string,
    public cancelable: number
  ) {}
}

export class ReceivedMyBookingsError implements Action {
  readonly type = MyBookingsActionTypes.RECEIVED_MY_BOOKINGS_ERROR;
  constructor(public errorNumber: number) {}
}

export class Reset implements Action {
  readonly type = MyBookingsActionTypes.RESET;
}

export type Actions =
  | RequestMyBookingDays
  | ReceiveMyBookingDays
  | ReceiveMyBookings
  | RequestMyBookings
  | CancelMyBookingReservation
  | MyBookingsUsersReceived
  | MyBookingStatusUpdated
  | ReceivedMyBookingsError
  | Reset;
