/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./startover-temp-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ng-image-slider/ng-image-slider.ngfactory";
import * as i4 from "ng-image-slider";
import * as i5 from "./startover-temp-view.component";
import * as i6 from "../../../core/auth.service";
import * as i7 from "../../../+merchant/+dashboard/app-mgmt/appMgmt.service";
import * as i8 from "../../../core/storage.service";
var styles_StartoverTempViewComponent = [i0.styles];
var RenderType_StartoverTempViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StartoverTempViewComponent, data: {} });
export { RenderType_StartoverTempViewComponent as RenderType_StartoverTempViewComponent };
function View_StartoverTempViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "temp-id"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Template id : ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tempId; _ck(_v, 1, 0, currVal_0); }); }
function View_StartoverTempViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "cat"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCategory(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_StartoverTempViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "temp-desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "created-by"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "create"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Created by ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "categories"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_4)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "sFeatures"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " Features Included "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "tFeaturs"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" (", "/", ") Selected Features. "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isProduction; _ck(_v, 4, 0, currVal_1); var currVal_3 = _co.temp.categories; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.temp.title; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.temp.createdBy; _ck(_v, 7, 0, currVal_2); var currVal_4 = _co.temp.sFeatuers; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.temp.sFeatuers; var currVal_6 = _co.temp.tFeatuers; _ck(_v, 14, 0, currVal_5, currVal_6); }); }
function View_StartoverTempViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "cost"]], null, null, null, null, null))], null, null); }
function View_StartoverTempViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slider-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ng-image-slider", [], null, [["window", "resize"], ["document", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).handleKeyboardEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NgImageSliderComponent_0, i3.RenderType_NgImageSliderComponent)), i1.ɵdid(2, 4964352, [[1, 4], ["nav", 4]], 0, i4.NgImageSliderComponent, [i1.ChangeDetectorRef, i1.PLATFORM_ID, i4.NgImageSliderService, i1.ElementRef], { images: [0, "images"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.temp.images; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StartoverTempViewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "desc-txt"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.temp.desc; _ck(_v, 0, 0, currVal_0); }); }
function View_StartoverTempViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "temp-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "back-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "back-arrow"], ["src", "./assets/img/back-arrow.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "temp-cntrl"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "start"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startwithThisTempate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Start with this template "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_6)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_7)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.temp; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.temp; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.temp; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.temp; _ck(_v, 16, 0, currVal_3); }, null); }
function View_StartoverTempViewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "temp-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "temp-desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "temp-cntrl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "start"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startwithThisTempate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Start with this template "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.choosenTemp.title; _ck(_v, 4, 0, currVal_0); }); }
function View_StartoverTempViewComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "related-temp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeTemplate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "temp-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "temp-cat"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.android_image_url); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.getCategory(((_v.context.$implicit == null) ? null : _v.context.$implicit.category)); _ck(_v, 6, 0, currVal_2); }); }
function View_StartoverTempViewComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "related-temps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "related-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Related Templates"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StartoverTempViewComponent_10)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.filteredrelatedTemp, 0, 5)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_StartoverTempViewComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { slider: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StartoverTempViewComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.temp; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.temp && _co.choosenTemp); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.relatedTemp && (_co.relatedTemp.length > 1)); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_StartoverTempViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-startover-temp-view", [], null, [["window", "popstate"]], function (_v, en, $event) { var ad = true; if (("window:popstate" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPopState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StartoverTempViewComponent_0, RenderType_StartoverTempViewComponent)), i1.ɵdid(1, 638976, null, 0, i5.StartoverTempViewComponent, [i6.AuthService, i7.AppMgmtService, i8.StorageService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StartoverTempViewComponentNgFactory = i1.ɵccf("app-startover-temp-view", i5.StartoverTempViewComponent, View_StartoverTempViewComponent_Host_0, { temp: "temp", relatedTemp: "relatedTemp", category: "category", selectedCategory: "selectedCategory", tempId: "tempId", choosenTemp: "choosenTemp" }, { startWith: "startWith", backNotfiy: "backNotfiy" }, []);
export { StartoverTempViewComponentNgFactory as StartoverTempViewComponentNgFactory };
