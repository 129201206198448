import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxBarcodeModule } from 'ngx-barcode';

import { NgxModule } from 'src/app/ngx.module';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxBarcodeModule,
    NgxModule,
    AbstractFramesModule,
    AbstractControlsModule,
    AbstractBtnsModule,
    AccessorsModule
  ],
  declarations: [CouponFormComponent],
  exports: [CouponFormComponent]
})
export class CouponFormModule {}
