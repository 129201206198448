import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { PaymentOrdersUiDispatchers } from 'src/app/store/paymentOrdersUI/paymentOrdersUI.dispatchers';
import { ServiceOrdersUiDispatchers } from 'src/app/store/serviceOrdersUI/serviceOrdersUI.dispatchers';
import { PaymentOrder } from 'src/models/PaymentGateways';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private _selectedOrderId = new BehaviorSubject<PaymentOrder>(null);
  public selectedOrderId$ = this._selectedOrderId.asObservable();

  constructor(
    private _paymentOrdersUiDispatchers: PaymentOrdersUiDispatchers,
    private _serviceOrdersUiDispatchers: ServiceOrdersUiDispatchers,
    private _rootSelector: RootStoreSelectors
  ) {}

  get paymentOrders$() {
    return this._rootSelector.getPaymentOrders$();
  }
  get serviceOrders$() {
    return this._rootSelector.getServiceOrders$();
  }

  selectPaymentOrderById(payment: PaymentOrder) {
    this.resetServiceOrdersPaging(payment.oid);
    this._selectedOrderId.next(payment);
  }
  resetSelectedPaymentOrder() {
    this._selectedOrderId.next(null);
  }

  /** Payment Orders */
  resetPaymentOrdersPaging() {
    this._paymentOrdersUiDispatchers.resetPaymentOrdersPaging();
  }
  getNextPaymentOrdersPage() {
    this._paymentOrdersUiDispatchers.getNextPaymentOrdersPage();
  }
  getPrevPaymentOrdersPage() {
    this._paymentOrdersUiDispatchers.getPrevPaymentOrdersPage();
  }

  /** service Orders */
  resetServiceOrdersPaging(paymentOrderID?: string) {
    this._serviceOrdersUiDispatchers.resetServiceOrdersPaging(paymentOrderID);
  }
  getNextServiceOrdersPage(paymentOrderID?: string) {
    this._serviceOrdersUiDispatchers.getNextServiceOrdersPage(paymentOrderID);
  }
  getPrevServiceOrdersPage(paymentOrderID?: string) {
    this._serviceOrdersUiDispatchers.getPrevServiceOrdersPage(paymentOrderID);
  }

  createStripConnectionSubChannel() {
    this._paymentOrdersUiDispatchers.paymentStripeConnectSubChannel();
  }
  resetStripConnectionSubChannel(paymentProviderId) {
    this._paymentOrdersUiDispatchers.paymentStripeResetSubChannel(
      paymentProviderId
    );
  }
}
