import { Store } from '@ngrx/store';
import * as InstantActions from './instant.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class InstantDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    instantArticleCreating(payload) {
        this._store.dispatch(new InstantActions.InstantArticleCreating(payload));
    }
    instantArticleReceived(payload) {
        this._store.dispatch(new InstantActions.InstantArticleReceived(payload));
    }
    linkPreviewDetailsReceived(msg) {
        this._store.dispatch(new InstantActions.LinkPreviewDetailsReceived(msg));
    }
    setLinkPreviewStatus(msg) {
        this._store.dispatch(new InstantActions.SetLinkPreviewStatus(msg));
    }
}
InstantDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InstantDispatchers_Factory() { return new InstantDispatchers(i0.ɵɵinject(i1.Store)); }, token: InstantDispatchers, providedIn: "root" });
