import { Store } from '@ngrx/store';
import * as actions from './appConfigHistory.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppConfigHistoryDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    requestAppConfigsHistory() {
        this._store.dispatch(new actions.RequestAppConfigsHistory());
    }
    receiveAppConfigsHistory(configHistoryList) {
        this._store.dispatch(new actions.ReceiveAppConfigsHistory(configHistoryList));
    }
    restoreAppConfigByID(id) {
        this._store.dispatch(new actions.RestoreAppConfigByID(id));
    }
}
AppConfigHistoryDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigHistoryDispatchers_Factory() { return new AppConfigHistoryDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppConfigHistoryDispatchers, providedIn: "root" });
