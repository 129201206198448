import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';

import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';
import { LayoutService } from '../layout.service';

import { ISection } from 'src/models/ISection';
import {
  UISections,
  ChannelAppScreens,
  VendorsNames,
  VendorsTypes
} from 'src/models/constants';
import { AuthService } from './../../../core/auth.service';
import { Title } from '@angular/platform-browser';
import { ThirdPartyInfoUI } from 'src/models/thirdPartyInfo';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { UiService } from 'src/app/core/ui.service';
import { Router } from '@angular/router';
import { PrivilegesName } from 'src/models/privileges';
import { DashboardService } from '../../+dashboard/dashboard.service';
import { StorageService } from './../../../core/storage.service';
import { MobileWorkFlowStoreService } from '../../+dashboard/mobile-workflow-store/mobile-workflow-store.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements OnChanges {
  uiSections: typeof UISections = UISections;

  @Input() direction: string;
  @Input() isOpen: boolean;
  @Input() mode: string;
  @Input() isShowing: boolean;
  @Input() showLogo: boolean;
  @Input() currentScreenNumber: number;
  @Input() progressWeb: string;
  @Input() selectedFeature: any;
  @Input() splashPages: any[];
  @Input() splashFooter: any;
  @Input() vendors: ThirdPartyInfoUI[];
  @Input() selectedVendor: string;
  @Input() appInfo: AppInfo;
  @Input() uiSection: any;
  @Input() oldVend: ThirdPartyInfoUI[];
  @Output() toggleMenuNotify = new EventEmitter<boolean>();
  @Output() sectionNotify = new EventEmitter<string>();
  @Output() startOverNotify = new EventEmitter<boolean>();
  appScreens = ChannelAppScreens;
  privilegesName = PrivilegesName;
  startOverFirstValidation = false;
  vendorsNames = VendorsNames;
  vendorsTypes = VendorsTypes;
  count = 0;
  constructor(
    public appMgmtService: AppMgmtService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public authService: AuthService,
    private _ref: ChangeDetectorRef,
    private _dashboardService: DashboardService,
    private _route: Router,
    private localStorage: StorageService,
    private mobileStoreWorkFlowService: MobileWorkFlowStoreService
  ) {}
  ngOnChanges() {}

  toggleMenuEvent() {
    this.toggleMenuNotify.emit(true);
  }

  sectionEvent(section: string, subMenuScreen: number) {
    let validation = true;
    if (this.selectedFeature && this.selectedFeature.id === 10008) {
      validation = this.validateSplashPages();
    }
    if (this.selectedFeature && this.selectedFeature.id === 10009) {
      validation = this.validateVendorsPage();
    }

    if (section && validation) {
      this.count = this.count++;

      this.sectionNotify.emit(section);
      if (
        section === this.uiSections.APP ||
        section === this.uiSections.CHANNEL
      ) {
        this.goToSpecificPage(section, subMenuScreen);
      }

      this.mobileStoreWorkFlowService._selectedMenuList.next(undefined);
      this.mobileStoreWorkFlowService._selectedMenu.next(undefined);
      this.mobileStoreWorkFlowService._selectedStoreId.next(undefined);
      this.mobileStoreWorkFlowService._mobileStoreAppList.next([]);
      this.mobileStoreWorkFlowService._selectedMenu.next(undefined);
      this.appMgmtService._selectedMenu.next(undefined);
      this.appMgmtService._selectedMenuButtonTab.next(undefined);
      this.appMgmtService._selectedMenuButton.next(undefined);
      this.appMgmtService._selectedMenuButtonStore.next(undefined);
    }
  }

  goToSpecificPage(section: string, screen: number) {
    const progressWebNum = parseInt(this.progressWeb, 10);

    if (screen && progressWebNum <= screen) {
      this.appMgmtService.goToSpecificPage(screen);
    } else {
      if (section === this.uiSections.APP) {
        const selectedScreen = screen ? screen : ChannelAppScreens.UPLOAD_LOGO;
        this.appMgmtService.goToSpecificPage(selectedScreen);
      } else if (section === this.uiSections.CHANNEL) {
        this.appMgmtService.goToSpecificPage(ChannelAppScreens.TABS);
      }
    }
  }

  setSubMenuActive(section: string, screenNumber: number): boolean {
    if (section === this.uiSections.APP) {
      return this.currentScreenNumber === screenNumber;
    } else if (section === this.uiSections.CHANNEL) {
      return this.currentScreenNumber === screenNumber;
    }
    return this.direction === section;
  }

  isActive(section: ISection) {
    return section.subMenuSections.includes(this.direction);
  }

  setItemStyle(section: ISection) {
    const isSectionActive = this.isActive(section);
    if (isSectionActive) {
      return {
        'background-color': '#daeffe',
        'not-open': !this.isOpen
      };
    }
  }

  validateSplashPages() {
    let validate = true;
    this.splashPages.forEach(res => {
      if (
        res.title === '' ||
        res.title === null ||
        !res.title ||
        res.desc === '' ||
        res.desc === null ||
        !res.desc ||
        this.splashFooter.title === '' ||
        this.splashFooter.title === null
      ) {
        validate = false;
      }
    });
    return validate;
  }

  validateVendorsPage() {
    const errorsVendors = [];
    let isSaved = true;
    if (
      this.vendors &&
      this.vendors.length > 0 &&
      this.appInfo.login_type === 'MSISDN'
    ) {
      this.vendors.map(res => {
        const vendorError = {
          type: res.type,
          name: res.name,
          user_error:
            (!res.access.user || res.access.user === '') &&
            res.name === this.vendorsNames.VONAGE,
          password_error:
            (!res.access.password || res.access.password === '') &&
            res.name === this.vendorsNames.VONAGE,
          key_error:
            (!res.access.api_key || res.access.api_key === '') &&
            (res.name === this.vendorsNames.INFOBIP ||
              res.name === this.vendorsNames.CLICKATELL),
          url_error:
            (!res.access ||
              !res.access.base_url ||
              res.access.base_url === '') &&
            res.name !== this.vendorsNames.VONAGE,
          from_error:
            (!res.access.from || res.access.from === '') &&
            (res.name === this.vendorsNames.INFOBIP ||
              res.name === this.vendorsNames.VONAGE) &&
            res.type !== this.vendorsTypes.SMS
        };
        if (Object.values(vendorError).includes(true)) {
          errorsVendors.push(vendorError);
        }
      });
      if (errorsVendors.length === 0) {
        isSaved = JSON.stringify(this.vendors) === JSON.stringify(this.oldVend);

        if (!isSaved) {
          this.appMgmtService._showSavePopup.next(true);
        }
      }
    } else if (this.appInfo.login_type === 'MSISDN' && !this.selectedVendor) {
      const vendorError = {
        name_error: true
      };
      errorsVendors.push(vendorError);
      if (Object.values(vendorError).includes(true)) {
        this.uiService.showErrorToast(
          'Please select one of the supported vendors.'
        );
      }
    } else if (this.appInfo.login_type === 'MSISDN' && this.selectedVendor) {
      const vendorError = {
        name_error: true
      };
      errorsVendors.push(vendorError);
      if (Object.values(vendorError).includes(true)) {
        this.uiService.showErrorToast(
          'Please complete the configuration of the selceted vendor.'
        );
      }
    }

    if (errorsVendors.length > 0 || !isSaved) {
      return false;
    } else {
      return true;
    }
  }

  startOver() {
    this.startOverNotify.emit();
    // this.authService.updateProgressWeb('0', 1);
  }

  setSideNavStatus(status) {
    if (status) {
      this.layoutService.setSideMenuStatus(0);
      this.appMgmtService._workFlowScreens.next(false);
      this.localStorage.setRecord('sideMenu', 'close');
    } else {
      this.localStorage.setRecord('sideMenu', 'open');
      this.layoutService.setSideMenuStatus(1);
      this.appMgmtService._workFlowScreens.next(true);
    }
  }
}
