/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./link-btn.component";
var styles_LinkBtnComponent = [i0.styles];
var RenderType_LinkBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkBtnComponent, data: {} });
export { RenderType_LinkBtnComponent as RenderType_LinkBtnComponent };
function View_LinkBtnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "app-btn"]], [[8, "href", 4], [8, "target", 0], [2, "default", null]], null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.href; var currVal_1 = (_co.openSelf ? "_self" : "_blank"); var currVal_2 = _co.isDefaultLink; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_LinkBtnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "app-btn"]], [[2, "default", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDefaultLink; _ck(_v, 0, 0, currVal_0); }); }
export function View_LinkBtnComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkBtnComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkBtnComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.href; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.href; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LinkBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-btn", [], null, null, null, View_LinkBtnComponent_0, RenderType_LinkBtnComponent)), i1.ɵdid(1, 49152, null, 0, i3.LinkBtnComponent, [], null, null)], null, null); }
var LinkBtnComponentNgFactory = i1.ɵccf("app-link-btn", i3.LinkBtnComponent, View_LinkBtnComponent_Host_0, { openSelf: "openSelf", href: "href", isDefaultLink: "isDefaultLink" }, { btnClicked: "btnClicked" }, ["link-content", "link-content-clicked"]);
export { LinkBtnComponentNgFactory as LinkBtnComponentNgFactory };
