import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { AppState } from 'src/models/AppState';

import * as CampaignUIActions from './campaignUI.actions';
import { CampaignUIDispatchers } from './campaignUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { GetCampaigns, GetCampaign } from 'src/models/ICampaign';

@Injectable()
export class CampaignUiEffects {
  @Effect({ dispatch: false })
  getCampaigns = this.actions$.pipe(
    ofType(
      CampaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_RECEIVED_HISTORY_CHUNK
    ),
    withLatestFrom(
      this._store
        .select(state => state.campaignReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, campaigns]) => {
      const action = <CampaignUIActions.ReceivedCampaignHistoryChunk>val;
      action.currentPage.forEach(campaignId => {
        const isCampExist = campaigns.find(
          camp => camp.campaignId === campaignId
        );
        if (!isCampExist) {
          this._socketGateway.sendSocketMessage(new GetCampaign(campaignId));
        }
      });
    })
  );

  @Effect({ dispatch: false })
  getNextCalendarPage = this.actions$.pipe(
    ofType(CampaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.campaignUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, campaignUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCampaigns(0, campaignUiState.hash, campaignUiState.eop)
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevCalendarPage = this.actions$.pipe(
    ofType(CampaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.campaignUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, campaignUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCampaigns(1, campaignUiState.hash, campaignUiState.sop)
      );
    })
  );

  @Effect({ dispatch: false })
  afterCalendarSectionSelection = this.actions$.pipe(
    ofType(CampaignUIActions.CampaignUIActionTypes.UI_LIST_CAMPAIGN),
    map((action: CampaignUIActions.ListCampaignUI) => {
      this._campaignDispatchers.getNextCampaignPage();
    })
  );

  @Effect({ dispatch: false })
  afterCalendarResetPaging = this.actions$.pipe(
    ofType(CampaignUIActions.CampaignUIActionTypes.UI_CAMPAIGN_RESET_PAGING),
    map((action: CampaignUIActions.ResetCampaignPaging) => {
      this._campaignDispatchers.getNextCampaignPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _campaignDispatchers: CampaignUIDispatchers
  ) {}
}
