import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as MyPageActions from './myPage.actions';
import { MyPageActionTypes } from './myPage.actions';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import {
  CreateMyPageConfigRequest,
  GetMyPageConfigRequest,
  RemoveMyPageRequest,
  MyPageComponent,
  CreateMyPageTemplate,
  SetMyPageConfigRequest,
  MyPageForm,
  MyPageContainer,
  MyPage
} from 'src/models/MyPageConfig';
import {
  AppClassNames,
  TabTypes,
  PageFormContainerTypes,
  uuidv4,
  FAILED_TO_CREATE_PAGE_TEMPLATE,
  ALREADY_HAS_TEMPLATE
} from 'src/models/constants';
import { ItemDispatchers } from '../channelAppItems/item.dispatchers';
import { ContainerDispatchers } from '../channelAppContainers/container.dispatchers';
import { MyPageDispatchers } from './myPage.dispatchers';
import { AppConfigDispatchers } from '../channelAppConfig/appConfig.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import * as isEqual from 'lodash.isequal';
import { AuthService } from 'src/app/core/auth.service';

@Injectable()
export class MyPageEffects {
  @Effect({ dispatch: false })
  createMyPageEffect = this.actions$.pipe(
    ofType(MyPageActionTypes.CREATE_MY_PAGE),
    map((action: MyPageActions.CreateMyPage) => {
      this.authService._waitForTempConfig.next(true);
      this._socketGateway.sendSocketMessage(
        new CreateMyPageConfigRequest(
          action.reference,
          action.myPageForm,
          action.name,
          action.url,
          action.image,
          action.desc,
          action.templateId
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createPageTemplateEffect = this.actions$.pipe(
    ofType(MyPageActionTypes.CREATE_PAGE_TEMPLATE),
    map((action: MyPageActions.CreatePageTemplate) => {
      // If the page has template id -- then nothing needs to be done
      if (
        (!action.myPage.template_id || action.myPage.template_id == '0') &&
        !(action.myPage.content && action.myPage.content.childTemplateId)
      ) {
        const updatedPage = this.preparePageToCreateTemplate(action.myPage);
        if (updatedPage) {
          this._socketGateway.sendSocketMessage(
            new CreateMyPageTemplate(
              action.myPage.id,
              updatedPage,
              action.name,
              action.image,
              action.desc
            )
          );
        } else {
          this._uiDispatchers.showPopup(FAILED_TO_CREATE_PAGE_TEMPLATE);
        }
      } else {
        this._uiDispatchers.showPopup(ALREADY_HAS_TEMPLATE);
      }
    })
  );

  @Effect({ dispatch: false })
  pageTemplateCreatedEffect = this.actions$.pipe(
    ofType(MyPageActionTypes.PAGE_TEMPLATE_CREATED),
    withLatestFrom(
      this._store
        .select(state => state.myPageReducer.filter(page => page.needUpdate))
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, pages]) => {
      const action = <MyPageActions.PageTemplateCreated>val;
      const selectedPage = pages.find(page => page.id === action.pageId);
      this._socketGateway.sendSocketMessage(
        new SetMyPageConfigRequest(
          selectedPage.id,
          selectedPage.content,
          selectedPage.name,
          selectedPage.url,
          selectedPage.image,
          selectedPage.desc
        )
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMyPageEffect = this.actions$.pipe(
    ofType(MyPageActionTypes.DELETE_MY_PAGE),
    map((action: MyPageActions.DeleteMyPage) => {
      this._socketGateway.sendSocketMessage(
        new RemoveMyPageRequest(action.pageID)
      );
    })
  );

  @Effect({ dispatch: false })
  getDetailsOfLinkedPage = this.actions$.pipe(
    ofType(MyPageActionTypes.GET_DETAILS_OF_LINKED_PAGE),
    map((action: MyPageActions.GetDetailsOfLinkedPage) => {
      this._socketGateway.sendSocketMessage(
        new GetMyPageConfigRequest(action.pageID)
      );
    })
  );

  @Effect({ dispatch: false })
  myPageCreated = this.actions$.pipe(
    ofType(MyPageActionTypes.MY_PAGE_CREATED),
    withLatestFrom(
      this._store
        .select(state =>
          state.containerReducer.filter(
            cont =>
              cont.appClassName === AppClassNames.TABS &&
              cont.type === TabTypes.PAGE
          )
        )
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state =>
          state.itemReducer.filter(
            item =>
              item.appClassName === AppClassNames.NAV_MENU &&
              item.link === TabTypes.PAGE
          )
        )
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state =>
          state.myPageReducer.filter(
            page => page.content && page.content.container && page.needPageID
          )
        )
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, pageTabs, pageItems, pages]) => {
      let needAutoSave = false;
      const action = <MyPageActions.MyPageCreated>val;
      const pageCont = pageTabs.find(
        cont => !cont.page_id && cont.page_id === action.createdPage.id
      );
      if (pageCont) {
        needAutoSave = true;
        this._containerDispatchers.updateContainer(pageCont.ref, {
          page_id: action.createdPage.id,
          url: action.createdPage.url
        });
      } else {
        const pageItem = pageItems.find(
          item => !item.page_id && item.page_id === action.createdPage.id
        );
        if (pageItem) {
          needAutoSave = true;
          this._itemDispatchers.updateItem(pageItem.ref, {
            page_id: action.createdPage.id,
            url: action.createdPage.url
          });
        }
      }
      this._myPageDispatchers.receiveInitializedPage(action.createdPage);

      if (pages && pages.length > 0) {
        pages.forEach(page => {
          page.content.container.forEach(cont => {
            if (
              cont.container_type === PageFormContainerTypes.LIST &&
              cont.component
            ) {
              cont.component.forEach(comp => {
                if (
                  !comp.form_id &&
                  comp.template_id &&
                  comp.page_ref &&
                  comp.page_ref === action.createdPage.reference &&
                  comp.template_id === action.createdPage.template_id
                ) {
                  needAutoSave = true;
                  const componentUpdatedProps: MyPageComponent = {};
                  componentUpdatedProps.form_id = action.createdPage.id;
                  this._myPageDispatchers.updateMyPageComponent(
                    page.id,
                    cont.id,
                    comp.id,
                    componentUpdatedProps,
                    true
                  );
                }
              });
            }
          });
        });
      }
      if (needAutoSave) {
        setTimeout(() => this._appConfigDispatchers.setAppConfig(false), 1000);
      }
    })
  );

  @Effect({ dispatch: false })
  myPageRcvd = this.actions$.pipe(
    ofType(MyPageActionTypes.RECEIVE_MY_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.myPageReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, pages]) => {
      const action = <MyPageActions.ReceiveMyPage>val;
      if (action.rcvdPage.content && action.rcvdPage.content.container) {
        const listContainers = action.rcvdPage.content.container.filter(
          cont => cont.container_type === PageFormContainerTypes.LIST
        );
        if (listContainers && listContainers.length > 0) {
          listContainers.forEach(cont => {
            if (cont.component) {
              cont.component.forEach(comp => {
                if (
                  comp.form_id &&
                  !pages.find(page => page.id === comp.form_id)
                ) {
                  this._socketGateway.sendSocketMessage(
                    new GetMyPageConfigRequest(comp.form_id)
                  );
                } else if (
                  !comp.form_id &&
                  comp.template_id &&
                  !comp.page_link_requested
                ) {
                  const pageRef = action.rcvdPage.id;
                  const componentUpdatedProps: MyPageComponent = {};
                  componentUpdatedProps.page_ref = pageRef;
                  componentUpdatedProps.page_link_requested = 1;
                  this._myPageDispatchers.updateMyPageComponent(
                    action.rcvdPage.id,
                    cont.id,
                    comp.id,
                    componentUpdatedProps,
                    true
                  );
                  // should create page with same ref using the template id
                  // this._myPageDispatchers.createMyPage(
                  //   pageRef,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   comp.template_id
                  // );
                }
              });
            }
          });
        }
      }
    })
  );

  preparePageToCreateTemplate = (myPage: MyPage): MyPageForm => {
    let canCreateTemplate = true;
    const myPageForm = myPage.content;
    if (myPageForm && myPageForm.container) {
      const updatedContainers = myPageForm.container.map(cont => {
        if (
          cont.container_type === PageFormContainerTypes.LIST &&
          cont.component
        ) {
          const updatedComponents = cont.component.map(comp => {
            if (comp.form_id || comp.page_ref) {
              if (!comp.template_id) {
                canCreateTemplate = false;
              }
              const updatedComp: MyPageComponent = { ...comp };
              delete updatedComp.form_id;
              delete updatedComp.page_ref;
              return updatedComp;
            }
            return comp;
          });
          const updatedContainer: MyPageContainer = { ...cont };
          updatedContainer.component = updatedComponents;
          return updatedContainer;
        }
        return cont;
      });
      const updatedPageForm = { ...myPageForm };
      delete updatedPageForm.id;
      updatedPageForm.container = updatedContainers;
      return canCreateTemplate ? updatedPageForm : null;
    } else {
      if (!myPageForm) {
        const newPage: MyPageForm = {};
        newPage.id = myPage.id;
        return newPage;
      } else {
        return myPageForm;
      }
    }
  };

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _containerDispatchers: ContainerDispatchers,
    private _itemDispatchers: ItemDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _appConfigDispatchers: AppConfigDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _store: Store<AppState>,
    private authService: AuthService
  ) {}
}
