import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';

import { MapTripsHistoryTypes } from './mapTripsHistory.actions';
import * as MapActions from './mapTripsHistory.actions';
import { MapTripHistoryDispatchers } from './mapTripsHistory.dispatchers';
import { PageDirection } from 'src/models/constants';

import * as Methods from 'src/models/MapTripsHistory';

@Injectable()
export class MapTripsHistoryEffects {
  @Effect({ dispatch: false })
  resetMapTripsHistoryPaging = this.actions$.pipe(
    ofType(MapTripsHistoryTypes.RESET_MAP_TRIPS_HISTORY_PAGING),
    map((action: MapActions.ResetMapTripHistoryPaging) => {
      this._mapTripHistoryDispatchers.getNextMapTripsHistoryPage();
    })
  );

  @Effect({ dispatch: false })
  getNextMapTripsHistoryPaging = this.actions$.pipe(
    ofType(MapTripsHistoryTypes.GET_NEXT_MAP_TRIPS_HISTORY_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripsHistoryReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsHistory]) => {
      const action = <MapActions.GetNextMapTripsHistoryPage>val;
      const prevRequest = mapTripsHistory.previousRequest;
      if (
        !(
          mapTripsHistory.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapTripsHistory.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTripsHistory(
            0,
            mapTripsHistory.eop,
            mapTripsHistory.hash,
            mapTripsHistory.startDate,
            mapTripsHistory.endDate
          )
        );
      } else {
        this._mapTripHistoryDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMapTripsHistoryPaging = this.actions$.pipe(
    ofType(MapTripsHistoryTypes.GET_PREV_MAP_TRIPS_HISTORY_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapTripsHistoryReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapTripsHistory]) => {
      const action = <MapActions.GetPrevMapTripsHistoryPage>val;
      const prevRequest = mapTripsHistory.previousRequest;
      if (
        !(
          mapTripsHistory.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapTripsHistory.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new Methods.GetMapTripsHistory(
            1,
            mapTripsHistory.sop,
            mapTripsHistory.hash,
            mapTripsHistory.startDate,
            mapTripsHistory.endDate
          )
        );
      } else {
        this._mapTripHistoryDispatchers.setStatusAsIdle();
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _mapTripHistoryDispatchers: MapTripHistoryDispatchers,
    private _store: Store<AppState>
  ) {}
}
