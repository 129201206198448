import { Actions } from './bookingUI.actions';
import * as actions from './bookingUI.actions';
import { BookingScreens, SCREEN_STATUS } from '../../../models/constants';
import { BookingUI } from 'src/models/BookingUI';

const INITIAL_STATE: BookingUI = {
  selectedBooking: null,
  creationProcessBooking: null,
  currentBookingScreen: BookingScreens.BOOKING_LIST,
  eop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE
};

export function bookingUiReducer(
  state = INITIAL_STATE,
  action: Actions
): BookingUI {
  switch (action.type) {
    case actions.BookingUIActionTypes.UI_LIST_BOOKING: {
      return {
        ...state,
        selectedBooking: null,
        creationProcessBooking: null,
        eop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        currentBookingScreen: BookingScreens.BOOKING_LIST
      };
    }
    case actions.BookingUIActionTypes.UI_CREATE_BOOKING: {
      const res = {
        ...state,
        selectedBooking: null,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_CREATION
      };
      return res;
    }
    case actions.BookingUIActionTypes.UI_VIEW_BOOKING: {
      return {
        ...state,
        selectedBooking: action.selectedBooking,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_VIEW
      };
    }
    case actions.BookingUIActionTypes.UI_EDIT_BOOKING: {
      return {
        ...state,
        file: action.subChat,
        selectedBooking: action.selectedBooking,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_EDIT
      };
    }
    // the UI_UPLOAD_BOOKING_PHOTO is when the chat is already created and
    // then you can set image for it and you can set booking periods for it
    case actions.BookingUIActionTypes.UI_UPLOAD_BOOKING_PHOTO: {
      if (state.currentBookingScreen === BookingScreens.BOOKING_EDIT) {
        return state;
      } else {
        return {
          ...state,
          selectedBooking: action.createdBooking,
          creationProcessBooking: null,
          currentBookingScreen: BookingScreens.BOOKING_EDIT
        };
      }
    }
    case actions.BookingUIActionTypes.UI_BOOKING_CREATION_COMPLETE: {
      return {
        ...state,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_LIST
      };
    }

    case actions.BookingUIActionTypes.UI_SHOW_RESERVATIONS: {
      return {
        ...state,
        selectedBooking: action.selectedBooking,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.RESERVATIONS
      };
    }
    case actions.BookingUIActionTypes.UI_CLOSE_VIEW_BOOKING: {
      return {
        ...state,
        selectedBooking: null,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_LIST
      };
    }
    case actions.BookingUIActionTypes.UI_CLOSE_EDIT_BOOKING: {
      return {
        ...state,
        creationProcessBooking: null,
        currentBookingScreen: BookingScreens.BOOKING_VIEW
      };
    }
    //
    case actions.BookingUIActionTypes.UI_BOOKING_GET_NEXT_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.BookingUIActionTypes.UI_BOOKING_GET_PREV_PAGE: {
      return {
        ...state,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.BookingUIActionTypes.UI_BOOKING_RECEIVED_HISTORY_CHUNK: {
      return {
        ...state,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.BookingUIActionTypes.UI_BOOKING_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.BookingUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
