<app-form-frame no-padding [showCancel]="true" (closeNotify)="closeEvent()">
  <ng-container ngProjectAs="title" *ngIf="!isSingle"
    >Add Collections</ng-container
  >
  <ng-container ngProjectAs="title" *ngIf="isSingle"
    >Add Collection</ng-container
  >
  <ng-container ngProjectAs="desc">
    Manage your products in this collection or create a new products.
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container
          *ngIf="collectionsService?.getCollectionsList$ | async as collections"
        >
          <app-selectable-cell-frame
            *ngFor="let collection of collections"
            [title]="collection?.name"
            [coverPhoto]="(collection?.image)[0].url"
            [selectorId]="collection?.id"
            [useNewStely]="true"
            [isSelected]="isCollectionSelected(collection?.id)"
            (checkClick)="setSelectMessage(collection)"
            (checkChanged)="resetSelectMessage(collection)"
          >
          </app-selectable-cell-frame>
        </ng-container>
        <app-empty-section
          [notPool]="true"
          [section]="collectionSec"
          style="width: 100%; display: block;"
          *ngIf="
            (collectionsService?.getCollectionsList$ | async)?.length === 0
          "
        >
        </app-empty-section>
      </div>
      <div
        class="navigate"
        *ngIf="(collectionsService?.getCollectionsList$ | async)?.length > 0"
      >
        <div class="navigate-btn" (click)="collectionsService?.getPrevPage()">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
        <div class="navigate-btn" (click)="collectionsService?.getNextPage()">
          <svg class="svg-icon icon-icon" style="transform: rotate(180deg);">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button
        class="add-btn"
        (click)="insertEvent()"
        [disabled]="
          (collectionsService?.getCollectionsList$ | async)?.length === 0 ||
          !currentSelectedCollection
        "
      >
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
