<div [innerHTML]="text | keepHtml | deebLinks | multiLine | twemoji"></div>

<app-link-preview-cell
  *ngIf="message?.linkPreviewStatus === msgLinkPreviewStat.LINK_PREVIEW_FETCHED"
  class="link-preview wrap-text"
  [title]="message?.linkPreviewTitle"
  [description]="message?.linkPreviewDescription"
  [image]="message?.linkPreviewImage"
  [height]="message?.linkPreviewHeight"
  [width]="message?.linkPreviewWidth"
  [url]="message?.linkPreviewUrl"
  [style]="message?.style"
  [rootUrl]="message?.linkPreviewRootUrl"
>
</app-link-preview-cell>
