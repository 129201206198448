import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ChannelAppUIActions from './channelAppUI.actions';

import { AppState } from 'src/models/AppState';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class ChannelAppUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  getNextPage(): void {
    this._store.dispatch(new ChannelAppUIActions.GetNextPage());
  }

  getPreviousPage(): void {
    this._store.dispatch(new ChannelAppUIActions.GetPreviousPage());
  }

  toggleAndroidVersion(payload: boolean): void {
    this._store.dispatch(new ChannelAppUIActions.ToggleAndroidVersion(payload));
  }

  toggleIosVersion(payload: boolean): void {
    this._store.dispatch(new ChannelAppUIActions.ToggleIosVersion(payload));
  }

  selectTab(selectedTab: LocalContainer): void {
    this._store.dispatch(new ChannelAppUIActions.SelectTab(selectedTab));
  }

  goToSpecificPage(pageNumber: number): void {
    this._store.dispatch(new ChannelAppUIActions.GoToSpecificPage(pageNumber));
  }

  updatePublishButtonFlag(canPublish: boolean): void {
    this._store.dispatch(
      new ChannelAppUIActions.UpdatePublishButtonFlag(canPublish)
    );
  }

  updateTotalAppConfFlag(totalAppConf: boolean): void {
    this._store.dispatch(
      new ChannelAppUIActions.UpdateTotalAppConfigFlag(totalAppConf)
    );
  }
}
