<div class="card" [@slideToLeft]>
  <!-- Header -->
  <div class="header">
    <img class="img-logo" alt="nandbox logo" src="./assets/img/icon.svg" />
    <span>nandbox portal</span>
  </div>
  <!-- End of Header -->

  <!-- Body -->
  <div
    *ngIf="
      (authService.authCollection$ | async)?.authStatus ===
        authStatus.AUTH_CHATLIST_SET;
      then list;
      else load
    "
  ></div>
  <!-- IN CASE NOT: AUTH_CHATLIST_SET -->
  <ng-template #load>
    <div class="body">
      <div class="wait-container">
        <i class="fas fa-sync-alt fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </ng-template>

  <ng-template #list>
    <!-- CHATS List length > 0 -->
    <div
      *ngIf="(dashboardService.allChannels$ | async)?.length > 0"
      class="body"
    >
      <div class="instruction">Select Channel</div>
      <div class="channels-list">
        <app-profile-cell
          *ngFor="
            let channel of dashboardService.allChannels$ | async;
            trackBy: TrackByFunction
          "
          (click)="selectChatEvent(channel)"
          [chat]="channel"
        >
        </app-profile-cell>
      </div>
    </div>

    <!-- CHATS List length = 0 -->
    <div
      *ngIf="(dashboardService.allChannels$ | async)?.length === 0"
      class="lead"
    >
      You DoNot Have Business Channels
    </div>
  </ng-template>
  <!-- End of body -->

  <!-- Footer -->
  <div class="footer">
    <a class="btn btn-link" (click)="logoutEvent()">back</a>
  </div>
  <!-- End of Footer -->
</div>
