import { Action } from '@ngrx/store';
import {
  LocalAppConfig,
  LocalItem,
  LocalComponent
} from 'src/models/ChannelAppLocalConfig';

export const enum ItemActionTypes {
  APP_CONFIG_DATA_CHANGED = 'APP_CONFIG_DATA_CHANGED',
  UPDATE_ITEM = 'UPDATE_ITEM',
  UPDATE_ITEM_LINK = 'UPDATE_ITEM_LINK',
  REQUEST_UPDATE_ITEM_LINK = 'REQUEST_UPDATE_ITEM_LINK',
  REORDER_ITEM = 'REORDER_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST',
  ADD_ITEM_REQUEST = 'ADD_ITEM_REQUEST',
  ADD_ITEM = 'ADD_ITEM',
  DELETE_ITEMS_OF_COMPONENT = 'DELETE_ITEMS_OF_COMPONENT',
  ADD_ITEMS = 'ADD_ITEMS',
  RESET = 'RESET'
}

export class AppConfigDataChanged implements Action {
  readonly type = ItemActionTypes.APP_CONFIG_DATA_CHANGED;
  constructor(
    public onlineAppConfig: LocalAppConfig,
    public offlineAppConfig: LocalAppConfig
  ) {}
}

export class UpdateItem implements Action {
  readonly type = ItemActionTypes.UPDATE_ITEM;
  constructor(public itemRef: string, public itemUpdatedProps: LocalItem) {}
}

export class UpdateItemLink implements Action {
  readonly type = ItemActionTypes.UPDATE_ITEM_LINK;
  constructor(
    public itemRef: string,
    public itemLink: string,
    public pageId: string
  ) {}
}

export class RequestUpdateItemLink implements Action {
  readonly type = ItemActionTypes.REQUEST_UPDATE_ITEM_LINK;
  constructor(
    public itemRef: string,
    public itemLink: string,
    public pageId: string
  ) {}
}

export class ReorderItem implements Action {
  readonly type = ItemActionTypes.REORDER_ITEM;
  constructor(public itemRef: string, public newPosition: number) {}
}

export class DeleteItemRequest implements Action {
  readonly type = ItemActionTypes.DELETE_ITEM_REQUEST;
  constructor(public item: LocalItem) {}
}

export class DeleteItem implements Action {
  readonly type = ItemActionTypes.DELETE_ITEM;
  constructor(public item: LocalItem) {}
}

export class AddItemRequest implements Action {
  readonly type = ItemActionTypes.ADD_ITEM_REQUEST;
  constructor(
    public component: LocalComponent,
    public specialItemValues?: LocalItem
  ) {}
}

export class AddItem implements Action {
  readonly type = ItemActionTypes.ADD_ITEM;
  constructor(public item: LocalItem) {}
}

export class DeleteItemsOfComponent implements Action {
  readonly type = ItemActionTypes.DELETE_ITEMS_OF_COMPONENT;
  constructor(public component: LocalComponent) {}
}

export class AddItems implements Action {
  readonly type = ItemActionTypes.ADD_ITEMS;
  constructor(public items: LocalItem[]) {}
}

export class Reset implements Action {
  readonly type = ItemActionTypes.RESET;
}

export type Actions =
  | AppConfigDataChanged
  | UpdateItem
  | UpdateItemLink
  | RequestUpdateItemLink
  | ReorderItem
  | DeleteItemRequest
  | DeleteItem
  | AddItemRequest
  | AddItem
  | DeleteItemsOfComponent
  | AddItems
  | Reset;
