import * as MobileWorkFlowStoreActionTypes from './mobileWorkflowStore.actions';
import { SCREEN_STATUS, PageDirection } from 'src/models/constants';
const ɵ0 = [];
const INITIAL_STATE = {
    eop: 0,
    group_id: '',
    main_group_id: '',
    direction: null,
    data: ɵ0
};
export function MobileWorkflowStoreReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "MOBILE_WORKFLOW_STORE_RECEIVING" /* MOBILE_WORKFLOW_STORE_RECEIVING */:
            {
                return Object.assign({}, state, { data: action.payload.data, eop: action.payload.eop, group_id: action.payload.group_id, main_group_id: action.payload.main_group_id });
            }
        case "MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE" /* MOBILE_WORKFLOW_STORE_GET_NEXT_PAGE */:
            {
                return Object.assign({}, state, { direction: PageDirection.NEXT, status: SCREEN_STATUS.REQUESTING });
            }
        case "MOBILE_WORKFLOW_STORE_GET_PREV_PAGE" /* MOBILE_WORKFLOW_STORE_GET_PREV_PAGE */:
            {
                return Object.assign({}, state, { direction: PageDirection.PREV, status: SCREEN_STATUS.REQUESTING });
            }
        case "MOBILE_WORKFLOW_STORES_RECEIVING" /* MOBILE_WORKFLOW_STORES_RECEIVING */:
            {
                return Object.assign({}, state);
            }
        case "MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS" /* MOBILE_WORKFLOW_STORE_SET_IDLE_STATUS */:
            {
                return Object.assign({}, state, { status: SCREEN_STATUS.IDLE });
            }
        case "MOBILE_WORKFLOW_STORE_RESET_PAGING" /* MOBILE_WORKFLOW_STORE_RESET_PAGING */:
            {
                return INITIAL_STATE;
            }
        case MobileWorkFlowStoreActionTypes.DESELECT_CHANNEL:
        case MobileWorkFlowStoreActionTypes.RESET:
            return INITIAL_STATE;
        default: {
            return state;
        }
    }
}
export { ɵ0 };
