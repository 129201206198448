import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import { ChannelAppScreens } from 'src/models/constants';

@Component({
  selector: 'app-menus-icons-conf',
  templateUrl: './menus-icons-conf.component.html',
  styleUrls: ['./menus-icons-conf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenusIconsConfComponent implements OnInit {
  @Input() tabcontainer: LocalContainer;
  @Input() currentPage: ChannelAppScreens;
  appScreens = ChannelAppScreens;
  constructor(public appManagementService: AppMgmtService) {}

  ngOnInit() {}

  updateItemIconIosEvent(value: string, container: any) {
    const newContainer: any = {};
    newContainer.icon_ios = value;

    this.appManagementService.updateContainer(container.ref, newContainer);
  }

  updateItemIconEvent(value: string, container: any) {
    const newContainer: any = {};
    newContainer.icon = value;
    this.appManagementService.updateContainer(container.ref, newContainer);
  }
}
