import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapServiceActions from './mapService.actions';
import { AppState } from 'src/models/AppState';
import { MapService } from 'src/models/MapService';

@Injectable({
  providedIn: 'root'
})
export class MapServiceDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetMapServicePaging() {
    this._store.dispatch(new MapServiceActions.ResetMapServicePaging());
  }

  getNextMapService() {
    this._store.dispatch(new MapServiceActions.GetNextMapServicePage());
  }
  getPrevMapService() {
    this._store.dispatch(new MapServiceActions.GetPrevMapServicePage());
  }

  getMapServiceByID(id: string) {
    this._store.dispatch(new MapServiceActions.GetMapServiceByID(id));
  }

  createMapService(mapService: MapService): void {
    this._store.dispatch(new MapServiceActions.CreateMapService(mapService));
  }

  mapServiceCreated(mapService: MapService): void {
    this._store.dispatch(new MapServiceActions.MapServiceCreated(mapService));
  }

  updateMapService(id: string, mapService: MapService): void {
    this._store.dispatch(
      new MapServiceActions.UpdateMapService(id, mapService)
    );
  }

  mapServiceDetailsReceived(mapService: MapService): void {
    this._store.dispatch(
      new MapServiceActions.MapServiceDetailsReceived(mapService)
    );
  }

  mapServiceListReceived(
    eop: number,
    sop: number,
    mapServices: MapService[],
    hash: string
  ): void {
    this._store.dispatch(
      new MapServiceActions.MapServiceListReceived(eop, sop, mapServices, hash)
    );
  }

  setCurrentScreen(screen: string) {
    this._store.dispatch(new MapServiceActions.SetCurrentScreen(screen));
  }

  deleteMapService(id: string): void {
    this._store.dispatch(new MapServiceActions.DeleteMapService(id));
  }

  mapServiceDeleted(id: string): void {
    this._store.dispatch(new MapServiceActions.MapServiceDeleted(id));
  }

  setStatusAsIdle() {
    this._store.dispatch(new MapServiceActions.SetStatusAsIdle());
  }

  Reset() {
    this._store.dispatch(new MapServiceActions.Reset());
  }
}
