import { Actions } from './bundlesUI.actions';

import * as actions from './bundlesUI.actions';
import { BundleUI } from 'src/models/Bundle';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';

const INITIAL_STATE: BundleUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function bundlesUiReducer(
  state = INITIAL_STATE,
  action: Actions
): BundleUI {
  switch (action.type) {
    case actions.BundlesUIActionTypes.BUNDLE_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(r => {
          if (r.id === action.bundle.id) {
            return action.bundle;
          }
          return r;
        })
      };
    }
    case actions.BundlesUIActionTypes.BUNDLE_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.id !== action.id)
      };
    case actions.BundlesUIActionTypes.BUNDLE_PRODUCT_INFO_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(bundle => {
          if (bundle.id === action.bundleID) {
            if (bundle.products) {
              const updatedProducts = bundle.products.map(bundleProduct => {
                if (bundleProduct.product_id === action.product.id) {
                  return {
                    ...bundleProduct,
                    product: action.product,
                    balance: action.product.balance
                  };
                }
                return bundleProduct;
              });
              return {
                ...bundle,
                products: [...updatedProducts]
              };
            }
            return bundle;
          }
          return bundle;
        })
      };
    }
    case actions.BundlesUIActionTypes.UI_BUNDLES_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.BundlesUIActionTypes.UI_BUNDLES_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.BundlesUIActionTypes.UI_BUNDLES_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.BundlesUIActionTypes.UI_BUNDLES_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.BundlesUIActionTypes.UI_BUNDLES_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.BundlesUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
