import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { PaymentOrdersUiDispatchers } from './paymentOrdersUI.dispatchers';
import { GetPaymentOrdersMethod } from 'src/models/PaymentGateways';
import { PageDirection, PAYMENT_ORDER_USERS_REF, PAYMENT_ORDER_GROUP_REF } from 'src/models/constants';
import { GetMyMember } from 'src/models/IChatMember';
import { CreateStripeSub, GetChatInfo, ResetStripeSub } from 'src/models/IChat';
import * as isEqual from 'lodash.isequal';
export class PaymentOrdersUiEffects {
    constructor(actions$, _socketGateway, _uiPaymentOrdersDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiPaymentOrdersDispatchers = _uiPaymentOrdersDispatchers;
        this._store = _store;
        this.getNextPaymentOrdersPage = this.actions$.pipe(ofType("UI_PAYMENT_ORDERS_GET_NEXT_PAGE" /* UI_PAYMENT_ORDERS_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.paymentOrdersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, paymentOrdersUiState]) => {
            const action = val;
            const prevRequest = paymentOrdersUiState.previousRequest;
            if (!(paymentOrdersUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                paymentOrdersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetPaymentOrdersMethod(0, paymentOrdersUiState.eop, action.paymentOrderID));
            }
            else {
                this._uiPaymentOrdersDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevPaymentOrdersPage = this.actions$.pipe(ofType("UI_PAYMENT_ORDERS_GET_PREV_PAGE" /* UI_PAYMENT_ORDERS_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.paymentOrdersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, paymentOrdersUiState]) => {
            const action = val;
            const prevRequest = paymentOrdersUiState.previousRequest;
            if (!(paymentOrdersUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                paymentOrdersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetPaymentOrdersMethod(1, paymentOrdersUiState.sop, action.paymentOrderID));
            }
            else {
                this._uiPaymentOrdersDispatchers.setStatusAsIdle();
            }
        }));
        this.afterPaymentOrdersResetPaging = this.actions$.pipe(ofType("UI_PAYMENT_ORDERS_RESET_PAGING" /* UI_PAYMENT_ORDERS_RESET_PAGING */), map((action) => {
            this._uiPaymentOrdersDispatchers.getNextPaymentOrdersPage(action.paymentOrderID);
        }));
        this.createStripeSubChannel = this.actions$.pipe(ofType("PAYMENT_STRIPE_SUB_CHANNEL" /* PAYMENT_STRIPE_SUB_CHANNEL */), map((action) => {
            this._socketGateway.sendSocketMessage(new CreateStripeSub());
        }));
        this.resetStripeSubChannel = this.actions$.pipe(ofType("PAYMENT_STRIPE_SUB_CHANNEL_RESET" /* PAYMENT_STRIPE_SUB_CHANNEL_RESET */), map((action) => {
            this._socketGateway.sendSocketMessage(new ResetStripeSub(action.provider_id));
        }));
        this.afterReceivePaymentOrders = this.actions$.pipe(ofType("UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK" /* UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK */), map((action) => {
            if (action.currentPage && action.currentPage.length > 0) {
                this._socketGateway.sendSocketMessage(new GetMyMember([
                    ...new Set([
                        ...action.currentPage.map(order => order.receiver_id),
                        ...action.currentPage.map(order => order.account_id)
                    ])
                ], PAYMENT_ORDER_USERS_REF));
                this._socketGateway.sendSocketMessage(new GetChatInfo(action.currentPage.map(order => order.group_id), PAYMENT_ORDER_GROUP_REF));
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "getNextPaymentOrdersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "getPrevPaymentOrdersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "afterPaymentOrdersResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "createStripeSubChannel", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "resetStripeSubChannel", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentOrdersUiEffects.prototype, "afterReceivePaymentOrders", void 0);
