import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmojiKeyboardComponent } from './emoji-keyboard/emoji-keyboard.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmojiKeyboardComponent],
  exports: [EmojiKeyboardComponent]
})
export class EmojiboxModule {}
