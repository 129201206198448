import { ascendingly } from '../channelAppConfig/appConfig.middleware';
import { uuidv4 } from 'src/models/constants';
const INITIAL_STATE = [];
export function myPageReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "RECEIVE_MY_PAGES" /* RECEIVE_MY_PAGES */: {
            return action.rcvdPages;
        }
        case "MY_PAGE_DELETED" /* MY_PAGE_DELETED */: {
            return state.filter(page => page.id !== action.pageID);
        }
        // should add the page if not existing or update it by ID
        case "RECEIVE_MY_PAGE" /* RECEIVE_MY_PAGE */: {
            if (!state.find(myPage => myPage.id == action.rcvdPage.id)) {
                return [action.rcvdPage, ...state];
            }
            else {
                return state.map(page => {
                    if (page.id == action.rcvdPage.id) {
                        let updatedPage = {};
                        updatedPage = Object.assign({}, page, action.rcvdPage);
                        return updatedPage;
                    }
                    return page;
                });
            }
        }
        // should insert by timestamp reference
        // case actions.MyPageActionTypes.CREATE_MY_PAGE: {
        //   return [
        //     {
        //       reference: action.reference,
        //       content: action.myPageForm,
        //       name: action.name
        //     },
        //     ...state
        //   ];
        // }
        // should update the page by reference (need to know if I should set the url here as well .. how to get the url)
        case "MY_PAGE_CREATED" /* MY_PAGE_CREATED */: {
            return [
                Object.assign({}, action.createdPage),
                ...state
            ];
        }
        case "UPDATE_MY_PAGE" /* UPDATE_MY_PAGE */: {
            return state.map(page => {
                if (page.id == action.id) {
                    const updatedPage = Object.assign({}, page, action.myPageDetails);
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "PAGE_TEMPLATE_CREATED" /* PAGE_TEMPLATE_CREATED */: {
            return state.map(page => {
                if (page.id == action.pageId) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content = Object.assign({}, page.content, { childTemplateId: action.childTemplateId });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "UPDATE_MY_PAGE_FORM" /* UPDATE_MY_PAGE_FORM */: {
            return state.map(page => {
                if (page.id == action.formID) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content = Object.assign({}, page.content, {
                        title: action.title
                            ? action.title
                            : page.content
                                ? page.content.title
                                : null,
                        image: action.image
                            ? action.image
                            : page.content
                                ? page.content.image
                                : null,
                        template: action.template
                            ? action.template
                            : page.content
                                ? page.content.template
                                : null,
                        background: action.background
                            ? action.background
                            : page.content
                                ? page.content.background
                                : null
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "UPDATE_MY_PAGE_CONTAINER" /* UPDATE_MY_PAGE_CONTAINER */: {
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content.container = page.content.container.map(container => {
                        if (container.id == action.containerID) {
                            let updatedCont = {};
                            updatedCont = Object.assign({}, container, action.containerUpdatedProps);
                            updatedPage.needUpdate = true;
                            return updatedCont;
                        }
                        return container;
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "REORDER_MY_PAGE_CONTAINERS" /* REORDER_MY_PAGE_CONTAINERS */: {
            const targetContainer = state
                .find(page => page.id == action.formID)
                .content.container.find(container => container.id == action.containerID);
            const oldPosition = targetContainer ? targetContainer.order : null;
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    const orderedContainers = page.content.container
                        .map(container => {
                        if (container.id == action.containerID) {
                            let updatedContainer = {};
                            updatedContainer = Object.assign({}, container, { order: action.newPosition });
                            return updatedContainer;
                        }
                        else if (oldPosition !== null && container.order >= 0) {
                            if (action.newPosition < oldPosition &&
                                container.order < oldPosition &&
                                container.order >= action.newPosition) {
                                // MOVING_UP
                                let updatedContainer = {};
                                updatedContainer = Object.assign({}, container, { order: container.order + 1 });
                                return updatedContainer;
                            }
                            else if (action.newPosition > oldPosition &&
                                container.order > oldPosition &&
                                container.order <= action.newPosition) {
                                // MOVING_DOWN
                                let updatedContainer = {};
                                updatedContainer = Object.assign({}, container, { order: container.order !== 0 ? container.order - 1 : 0 });
                                return updatedContainer;
                            }
                            else {
                                return container;
                            }
                        }
                        return container;
                    })
                        .sort(ascendingly);
                    updatedPage.content.container = orderedContainers;
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "DELETE_MY_PAGE_CONTAINER" /* DELETE_MY_PAGE_CONTAINER */: {
            const orderOfDeletedContainer = action.container.order;
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    let remainingContainers = page.content.container.filter(container => container.id != action.container.id);
                    if (remainingContainers) {
                        remainingContainers = remainingContainers
                            .map(container => {
                            if (orderOfDeletedContainer !== null &&
                                orderOfDeletedContainer !== undefined &&
                                container.order >= 0) {
                                if (container.order > orderOfDeletedContainer) {
                                    let updatedContainer = {};
                                    updatedContainer = Object.assign({}, container, { order: container.order - 1 });
                                    return updatedContainer;
                                }
                            }
                            return container;
                        })
                            .sort(ascendingly);
                    }
                    updatedPage.content.container = remainingContainers;
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "ADD_MY_PAGE_CONTAINER" /* ADD_MY_PAGE_CONTAINER */: {
            return state.map(page => {
                let newContainer = {};
                if (page.id === action.formID) {
                    let updatedPage = {};
                    updatedPage = page;
                    // May be I'll need to take the other properties with me in case of new container
                    // updatedPage.content =
                    //   page.content && page.content.container
                    //     ? page.content
                    //     : { container: [] };
                    newContainer = action.container;
                    if (page.content && page.content.container) {
                        newContainer.order = page.content.container.length
                            ? page.content.container.length
                            : 0;
                    }
                    else {
                        newContainer.order = 0;
                    }
                    newContainer.id = uuidv4();
                    if (page.content && page.content.container) {
                        const contaniers = [];
                        page.content.container.forEach(c => contaniers.push(c));
                        contaniers.push(newContainer);
                        updatedPage.content.container = contaniers;
                    }
                    else {
                        if (!updatedPage.content) {
                            updatedPage.content = { container: [newContainer] };
                        }
                        else {
                            updatedPage.content = Object.assign({}, updatedPage.content, { container: [newContainer] });
                        }
                    }
                    updatedPage.needUpdate = true;
                    updatedPage.date = new Date();
                    return updatedPage;
                }
                return page;
            });
        }
        case "UPDATE_MY_PAGE_COMPONENT" /* UPDATE_MY_PAGE_COMPONENT */: {
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.needPageID = action.needPageID;
                    updatedPage.content.container = page.content.container.map(container => {
                        if (container.id == action.containerID) {
                            const updatedContainer = Object.assign({}, container);
                            updatedContainer.component = container.component.map(component => {
                                if (component.id == action.componentID) {
                                    let updatedComp = {};
                                    updatedComp = Object.assign({}, component, action.componentUpdatedProps);
                                    return updatedComp;
                                }
                                return component;
                            });
                            return updatedContainer;
                        }
                        return container;
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "REORDER_MY_PAGE_COMPONENTS" /* REORDER_MY_PAGE_COMPONENTS */: {
            const targetComponent = state
                .find(page => page.id == action.formID)
                .content.container.find(container => container.id == action.containerID)
                .component.find(comp => comp.id == action.componentID);
            const oldPosition = targetComponent ? targetComponent.order : null;
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content.container = page.content.container.map(container => {
                        if (container.id == action.containerID) {
                            const updatedContainer = Object.assign({}, container);
                            updatedContainer.component = container.component
                                .map(component => {
                                if (component.id == action.componentID) {
                                    let updatedComp = {};
                                    updatedComp = Object.assign({}, component, { order: action.newPosition });
                                    return updatedComp;
                                }
                                else if (oldPosition !== null && component.order >= 0) {
                                    if (action.newPosition < oldPosition &&
                                        component.order < oldPosition &&
                                        component.order >= action.newPosition) {
                                        // MOVING_UP
                                        let updatedComp = {};
                                        updatedComp = Object.assign({}, component, { order: component.order + 1 });
                                        return updatedComp;
                                    }
                                    else if (action.newPosition > oldPosition &&
                                        component.order > oldPosition &&
                                        component.order <= action.newPosition) {
                                        // MOVING_DOWN
                                        let updatedComp = {};
                                        updatedComp = Object.assign({}, component, { order: component.order !== 0 ? component.order - 1 : 0 });
                                        return updatedComp;
                                    }
                                }
                                return component;
                            })
                                .sort(ascendingly);
                            return updatedContainer;
                        }
                        return container;
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "DELETE_MY_PAGE_COMPONENT" /* DELETE_MY_PAGE_COMPONENT */: {
            const orderOfDeletedComponent = action.component.order;
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content.container = page.content.container.map(container => {
                        if (container.id == action.containerID) {
                            const updatedContainer = Object.assign({}, container);
                            const remainingComponents = container.component.filter(comp => comp.id != action.component.id);
                            if (remainingComponents) {
                                updatedContainer.component = remainingComponents.map(component => {
                                    if (orderOfDeletedComponent !== null &&
                                        orderOfDeletedComponent !== undefined &&
                                        component.order >= 0) {
                                        if (component.order > orderOfDeletedComponent) {
                                            let updatedComponent = {};
                                            updatedComponent = Object.assign({}, component, { order: component.order - 1 });
                                            return updatedComponent;
                                        }
                                    }
                                    return component;
                                });
                            }
                            else {
                                updatedContainer.component = [];
                            }
                            return updatedContainer;
                        }
                        return container;
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "ADD_MY_PAGE_COMPONENT" /* ADD_MY_PAGE_COMPONENT */: {
            return state.map(page => {
                if (page.id == action.formID &&
                    page.content &&
                    page.content.container) {
                    const updatedPage = Object.assign({}, page);
                    updatedPage.content.container = page.content.container.map(container => {
                        if (container.id == action.containerID) {
                            const updatedContainer = container;
                            const newComp = action.component;
                            newComp.order = container.component
                                ? container.component.length
                                : 0;
                            newComp.id = uuidv4();
                            if (container.component) {
                                updatedContainer.component = [
                                    ...container.component,
                                    newComp
                                ];
                            }
                            else {
                                updatedContainer.component = [newComp];
                            }
                        }
                        return container;
                    });
                    updatedPage.needUpdate = true;
                    return updatedPage;
                }
                return page;
            });
        }
        case "DESELECT_CHANNEL" /* DESELECT_CHANNEL */:
            return INITIAL_STATE;
        case "RESET" /* RESET */:
            return INITIAL_STATE;
        default: {
            return state;
        }
    }
}
