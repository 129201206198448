import { DriverTrip } from 'src/models/DrverAssigned';
import * as DriverAssignedActions from './driverAssigned.actions';
import { DriverAssignedMiddleware } from './driverAssigned.middleware';

const INITIAL_STATE: DriverTrip = {
  text: null,
  employeeID: 0,
  startDate: new Date(),
  endDate: new Date(),
  diff: null
};
export function driverTripsReducer(
  state: DriverTrip[] = [],
  action: DriverAssignedActions.DriverAssignedActions
): DriverTrip[] {
  switch (action.type) {
    case DriverAssignedActions.DriverAssignedActionTypes.DRIVER_TRIPS_RECEIVED:
      const localPayload = DriverAssignedMiddleware.mapListFromServer(
        action.payload
      );

      state = localPayload;

      return state;
    case DriverAssignedActions.DESELECT_CHANNEL:
    case DriverAssignedActions.RESET:
      return [];
    default:
      return state;
  }
}
