import { Actions } from './subChannelsUI.actions';
import * as actions from './subChannelsUI.actions';
import {
  SubChatScreens,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from '../../../models/constants';
import { SubChatUI } from 'src/models/SubGroupUI';

const INITIAL_STATE: SubChatUI = {
  file: null,
  selectedSubGroup: null,
  creationProcessSubChat: null,
  currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST,
  eop: 0,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function subChannelUiReducer(
  state = INITIAL_STATE,
  action: Actions
): SubChatUI {
  switch (action.type) {
    case actions.SubChannelUIActionTypes.UI_LIST_SUB_CHANNEL: {
      return {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST,
        eop: 0,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.SubChannelUIActionTypes.UI_CREATE_SUB_CHANNEL: {
      const res = {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_CREATION
      };
      return res;
    }
    case actions.SubChannelUIActionTypes.UI_VIEW_SUB_CHANNEL: {
      return {
        ...state,
        selectedSubGroup: action.selectedSubChannel,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_VIEW
      };
    }
    case actions.SubChannelUIActionTypes.UI_EDIT_SUB_CHANNEL: {
      return {
        ...state,
        file: action.file,
        selectedSubGroup: action.selectedSubChannel,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_EDIT
      };
    }
    case actions.SubChannelUIActionTypes.UI_UPLOAD_SUB_CHANNEL_PHOTO: {
      if (state.currentSubChatScreen === SubChatScreens.SUB_CHAT_EDIT) {
        return state;
      } else {
        return {
          ...state,
          selectedSubGroup: action.createdSubChannel,
          creationProcessSubChat: null,
          currentSubChatScreen: SubChatScreens.SUB_CHAT_EDIT
        };
      }
    }
    case actions.SubChannelUIActionTypes.UI_SUB_CHANNEL_CREATION_COMPLETE: {
      return {
        ...state,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST
      };
    }

    case actions.SubChannelUIActionTypes.UI_CLOSE_VIEW_SUB_CHANNEL: {
      return {
        ...state,
        selectedSubGroup: null,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_LIST
      };
    }

    case actions.SubChannelUIActionTypes.UI_CLOSE_EDIT_SUB_CHANNEL: {
      return {
        ...state,
        creationProcessSubChat: null,
        currentSubChatScreen: SubChatScreens.SUB_CHAT_VIEW
      };
    }
    case actions.SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubChannelUIActionTypes.UI_SUB_CHANNEL_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubChannelUIActionTypes.UI_SUB_CHANNEL_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.SubChannelUIActionTypes
      .UI_SUB_CHANNEL_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.SubChannelUIActionTypes.UI_SUB_CHANNEL_RESET_PAGING: {
      return {
        ...state,
        eop: 0,
        sop: null,
        currentPage: [],
        hash: '',
        status: SCREEN_STATUS.IDLE,
        direction: null,
        previousRequest: {}
      };
    }
    case actions.SubChannelUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
