import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { MainChat } from 'src/models/MainChat';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements OnInit, OnChanges {
  @Input() selectedChat: MainChat;
  @Input() selectedUserId: string;
  @Input() replyToAdmin: boolean;
  @Input() groupReplies: boolean;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 300);
  }
}
