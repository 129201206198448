import { Action } from '@ngrx/store';

import { SubMenuStore } from 'src/models/SubMenu';

export const enum SubMenuUIActionTypes {
  CREATE_SUB_MENU = 'CREATE_SUB_MENU',
  SUB_MENU_STORE_RECEIVED = 'SUB_MENU_STORE_RECEIVED',
  SET_SUB_MENU = 'SET_SUB_MENU',
  ADD_SUB_MENU_ITEM = 'ADD_SUB_MENU_ITEM',
  REMOVE_SUB_MENU_ITEM = 'REMOVE_SUB_MENU_ITEM',
  UI_SUB_MENU_GET_NEXT_PAGE = 'UI_SUB_MENU_GET_NEXT_PAGE',
  UI_SUB_MENU_GET_PREV_PAGE = 'UI_SUB_MENU_GET_PREV_PAGE',
  UI_SUB_MENU_RECEIVED_HISTORY_CHUNK = 'UI_SUB_MENU_RECEIVED_HISTORY_CHUNK',
  UI_SUB_MENU_RESET_PAGING = 'UI_SUB_MENU_RESET_PAGING',
  UI_SUB_MENU_SET_IDLE_STATUS = 'UI_SUB_MENU_SET_IDLE_STATUS',
  RESET = 'RESET'
}

export class CreateSubMenu implements Action {
  readonly type = SubMenuUIActionTypes.CREATE_SUB_MENU;
  constructor(public subMenusDetails: SubMenuStore) {}
}

export class SetSubMenu implements Action {
  readonly type = SubMenuUIActionTypes.SET_SUB_MENU;
  constructor(public subMenuID: string, public subMenuDetails: SubMenuStore) {}
}

export class AddSubMenuItem implements Action {
  readonly type = SubMenuUIActionTypes.ADD_SUB_MENU_ITEM;
  constructor(public subMenuID: string, public items: any[]) {}
}

export class RemoveSubMenuItem implements Action {
  readonly type = SubMenuUIActionTypes.REMOVE_SUB_MENU_ITEM;
  constructor(public subMenuID: string, public itemIDs: string[]) {}
}

// Paging Actions

export class GetNextSubMenuPage implements Action {
  readonly type = SubMenuUIActionTypes.UI_SUB_MENU_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevSubMenuPage implements Action {
  readonly type = SubMenuUIActionTypes.UI_SUB_MENU_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedSubMenuHistoryChunk implements Action {
  readonly type = SubMenuUIActionTypes.UI_SUB_MENU_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: SubMenuStore[]
  ) {}
}

export class SubMenuStoreReceived implements Action {
  readonly type = SubMenuUIActionTypes.SUB_MENU_STORE_RECEIVED;
  constructor(public subMenuStore: SubMenuStore) {}
}

export class ResetSubMenuPaging implements Action {
  readonly type = SubMenuUIActionTypes.UI_SUB_MENU_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = SubMenuUIActionTypes.UI_SUB_MENU_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = SubMenuUIActionTypes.RESET;
}

export type Actions =
  | CreateSubMenu
  | SetSubMenu
  | AddSubMenuItem
  | RemoveSubMenuItem
  | GetNextSubMenuPage
  | GetPrevSubMenuPage
  | ReceivedSubMenuHistoryChunk
  | SubMenuStoreReceived
  | ResetSubMenuPaging
  | SetStatusAsIdle
  | Reset;
