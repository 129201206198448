import {
  SEND_SCHEDULED_MESSAGE_HISTORY_METHOD,
  SEND_MESSAGE_SCHEDULE_CANCEL_METHOD,
  CONTACTS_GET_METHOD,
  GET_CHAT_LIST_METHOD,
  GET_UPGRADED_CHAT_LIST_METHOD,
  GET_MY_PROFILES_METHOD,
  SEND_MESSAGE_RECALL_METHOD,
  SEND_AWARD_MESSAGE_HISTORY_METHOD,
  SEND_INLINE_MESSAGE_CALLBACK_METHOD,
  GET_MESSAGE_HISTORY_METHOD
} from './constants';
import { BotMenu } from './IBotMenu';
import { MenuCallBackStats } from './MenuCallBackStats';
import { CalendarTimetable } from './Calendar';

export interface IMessage {
  method?: string;
  message_id?: string;
  type?: string; // text, photo, audio, video, gif, location, voice, contact, or document
  date?: number;
  // from
  sender_id?: string;
  sender_name?: string;
  sender_version?: string;
  sender_terminal?: string; // 'Mobile', 'API'
  sender_type?: string; // i.e. Bot
  sender_language_code?: string; // Default is “en”  English
  // to
  receiver_id?: string; // in case of sending use it to put the field chat id which is either for group or user as dest
  //
  reply_to_message_id?: string; // reply_to_message_id This is the post id (parent message id)
  caption?: string; // media can have caption.
  from_admin?: number;
  // chat
  group_id?: string;
  group_name?: string;
  group_type?: number; //  100 Group, 101 Channel
  // text
  text?: string;
  url?: string;
  // audio
  performer?: string;
  title?: string;
  // location
  longitude?: string;
  latitude?: string;
  location_name?: string;
  location_details?: string;
  live_period?: string;
  // contact
  phone_number?: string;
  contact_name?: string;
  contact_id?: string;
  // document
  document_name?: string;
  // common media aspects (ex:video, voice, photo)
  media_id?: string;
  media_width?: number;
  media_height?: number;
  media_duration?: number;
  thumbnail_id?: string;
  thumbnail_width?: number;
  thumbnail_height?: number;
  file_size?: number;
  file_name?: string;
  // used while sending
  reference?: number; // Unique local identifier for the target chat/user
  // if reply or send message to target user within a group chat or channel, unique identifier of the target user
  send_to_user_id?: string;
  // Disables link previews for links in this message
  disable_web_page_preview?: boolean;
  disable_notification?: boolean; // Sends the message silently. Users will receive a notification with no sound.
  auto_accept?: number; // send it to auto_acept calendar
  // infos
  likes?: number;
  views?: number;
  shares?: number;
  // add for local usage
  seen?: number;
  position?: string; // left(other) or right (logged in)
  localMedia?: string;
  localThumbnail?: string;
  thumbnailStatus?: string;
  mediaStatus?: string; //
  status?: string; // PENDING , SENT, DELIVERED , SEEN
  loggedInIsSender?: boolean;
  isChannelPost?: boolean;
  tempFile?: File;
  isRead?: boolean;
  isViewed?: boolean;
  isChatViewed?: boolean;
  isAdminReply?: boolean;
  loadingProgress?: number;
  myLike?: number;
  link?: string;

  // preview links props
  linkPreviewStatus?: string;
  linkPreviewTitle?: string;
  linkPreviewDescription?: string;
  linkPreviewImage?: string;
  linkPreviewWidth?: number;
  linkPreviewHeight?: number;
  linkPreviewUrl?: string;
  linkPreviewRootUrl?: string;
  numberOfReplies?: number;
  numberOfUnreadReplies?: number;
  endOfPage?: number;
  level?: number;
  rcvdHistoryReply?: boolean;
  updateStatus?: string; // 'deleted' or 'updated'
  actionType?: string; // normal, updated, deleted
  existedBefore?: boolean;

  // Messages Misc
  schedule_date?: number;
  award?: number;
  welcome?: number;
  tags?: string[];
  tab?: string;
  style?: number; // message media style
  bg_color?: string; // Bg color
  sticky_title?: string; // sticky message title
  sticky_desc?: string; // sticky message desc
  c_code?: string;
  c_exp?: number;
  menu_ref?: string;
  inline_menu?: BotMenu[];
  json?: CalendarTimetable;
  web_page_preview?: number;
  reply_to_admin?: boolean;
  disable_reply?: number;

  // local use
  lastReplyDate?: number;
  isSelected?: boolean;
  hasMenu?: boolean;
  menuStats?: MenuCallBackStats[];
  chat_settings?: number;
}

export class GetContacts {
  readonly method = CONTACTS_GET_METHOD;
  constructor(public merchant?: boolean) {}
}

export class GetMyProfiles {
  readonly method = GET_MY_PROFILES_METHOD;
}

export class GetChatList {
  readonly method = GET_CHAT_LIST_METHOD;
  constructor(public upgraded?: boolean) {}
}
export class GetUpgradedChatList {
  readonly method = GET_UPGRADED_CHAT_LIST_METHOD;
  constructor(public is_default: boolean) {}
}

export interface ChannelPosts {
  messages?: IMessage[];
  totalNumberOfPages?: number;
}

export interface ChatMessages {
  messages?: IMessage[];
  totalNumberOfPages?: number;
}

export class GetMessagesHistoryFromBusinessServer {
  readonly method = GET_MESSAGE_HISTORY_METHOD;
  constructor(
    public message_id?: string,
    public reply_to_message_id?: string,
    public date?: number,
    public admin?: number,
    public seen?: number,
    public from_user_id?: string,
    public to_user_id?: string,
    public scheduled?: number,
    public user_id?: string,
    public page_size?: number,
    public lv?: number,
    public reference?: number,
    public award?: number,
    public chat_id?: string
  ) {}
}

export class GetAwardMessagesHistory {
  readonly method = SEND_AWARD_MESSAGE_HISTORY_METHOD;
  constructor() {}
}
export class GetScheduledMessagesHistory {
  readonly method = SEND_SCHEDULED_MESSAGE_HISTORY_METHOD;
  constructor() {}
}

export class CancelScheduledMessage {
  readonly method = SEND_MESSAGE_SCHEDULE_CANCEL_METHOD;
  constructor(public message_id: string) {}
}

export class RecallMessage {
  readonly method = SEND_MESSAGE_RECALL_METHOD;
  constructor(
    public chat_id: string,
    public message_id: string,
    public reference: number,
    public reply_to_user_id: string,
    public to_admin?: number,
    public reply_to_message_id?: string
  ) {}
}

export class InlineCallBackMessage {
  readonly method = SEND_INLINE_MESSAGE_CALLBACK_METHOD;
  constructor(
    public to_user_id: number,
    public button_callback: string,
    public next_menu: string,
    public chat_id: number,
    public message_id: string,
    public menu_ref: string,
    public button_label: string,
    public reference: number
  ) {}
}

export type MessagesMethods =
  | GetContacts
  | GetMyProfiles
  | GetChatList
  | GetUpgradedChatList
  | ChannelPosts
  | ChatMessages
  | GetMessagesHistoryFromBusinessServer
  | GetScheduledMessagesHistory
  | CancelScheduledMessage
  | RecallMessage;
