import { Actions } from './appChannelData.actions';
import * as actions from './appChannelData.actions';
import { ChannelData } from 'src/models/ChannelAppConfig';

const INITIAL_STATE = [];
export function appChannelDataReducer(
  state: ChannelData[] = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppChannelDataActionTypes.RECEIVE_APP_CHANNEL_DATA: {
      let updatedState: ChannelData[] = state;
      const dataToBeUpdated: ChannelData[] = [];
      const newData: ChannelData[] = [];

      if (action.channelData && action.channelData.length > 0) {
        action.channelData.forEach(dataRecord => {
          const alreadyExists = state.find(
            channeldataRecord => channeldataRecord.key === dataRecord.key
          );
          if (
            !newData.find(
              channeldataRecord => channeldataRecord.key === dataRecord.key
            ) &&
            !alreadyExists
          ) {
            newData.push(dataRecord);
          } else if (alreadyExists) {
            dataToBeUpdated.push(dataRecord);
          }
        });

        if (dataToBeUpdated.length > 0) {
          updatedState = state.map(dataRec => {
            const rcvdData = dataToBeUpdated.find(
              data => data.key === dataRec.key
            );
            if (rcvdData) {
              return { ...dataRec, ...rcvdData };
            }
            return dataRec;
          });
        }
      }
      return [...updatedState, ...newData];
    }
    case actions.AppChannelDataActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
