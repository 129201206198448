<div class="section-header" [ngStyle]="nextStyle">
  <!-- <app-border-btn *ngIf="!hideBackButton" back (btnClicked)="getPreviousPage()">
    Back
  </app-border-btn> -->
  <!-- <app-info-btn
    *ngIf="showReleases"
    [title]="'Publishing Info'"
    (buttonClicked)="hideReleases()"
  >
  </app-info-btn> -->
  <div class="full-w"></div>
  <!-- <app-border-btn
    *ngIf="showNewAppButton"
    publish
    (btnClicked)="showSystemModal(true)"
  >
    New Release
  </app-border-btn> -->
  <button
    *ngIf="showNewAppButton"
    (click)="showSystemModal(true)"
    class="new-release-btn"
  >
    New Release
  </button>

  <!-- <ng-container *appShowIfHasPrivilege="privilegesName.editappchannel">


    <button
      *ngIf="showPublishButton"
      (click)="showPublishModal(true)"
      class="new-release-btn"
    >
      Publish
    </button>
  </ng-container> -->

  <ng-container>
    <!-- <app-border-btn
      *ngIf="showPublishButton"
      publish
      (btnClicked)="showPublishModal(true)"
    >
      Publish
    </app-border-btn> -->
    <button
      *ngIf="showPublishButton"
      (click)="showPublishModal(true)"
      class="new-release-btn"
    >
      Publish
    </button>
  </ng-container>
  <!-- <ng-container>
    <app-border-btn *ngIf="!hideBackButton" submit (btnClicked)="back()">
      Back
    </app-border-btn>
  </ng-container> -->

  <ng-container>
    <button
      class="save-btn"
      *ngIf="!hideSaveButton && !saveTitle"
      (click)="saveApp()"
    >
      Save
    </button>
    <button
      class="save-btn"
      *ngIf="
        !hideSaveButton &&
        saveTitle &&
        !(appManagementService?.waitForSave$ | async)
      "
      (click)="saveApp()"
    >
      {{ saveTitle }}
    </button>
    <button
      class="save-btn"
      *ngIf="
        !hideSaveButton &&
        saveTitle &&
        (appManagementService?.waitForSave$ | async)
      "
    >
      <i class="fas fa-spinner fa-spin"></i> Please wait
    </button>
  </ng-container>
  <!-- <ng-container *appShowIfHasPrivilege="privilegesName.editappchannel">
    <app-border-btn *ngIf="!hideSaveButton" next (btnClicked)="saveApp()">
      Save
    </app-border-btn>
  </ng-container> -->
  <app-next-button
    *ngIf="!hideNextButton && currentPage < 5"
    [currentScreenNumber]="currentPage"
    [isChannelConfOnly]="isChannelConfOnly"
  >
  </app-next-button>
</div>

<!-- System Modal to relase app -->
<!-- <app-modal-container
  *ngIf="systemModal"
  (cancelNotfiy)="showSystemModal(false)"
>
  <app-systems-modal
    (cancelNotify)="showSystemModal(false)"
    (systemNotify)="getNewApp($event)"
    [availableApps]="appManagementService.availableApps$ | async"
    [publishInfoData]="publishInfoData"
    [appSetupInfo]="
      appManagementService?.getAppsSetupInfoById$(
        (appManagementService.availableApps$ | async)[0]?.key
      ) | async
    "
    [appInfo]="appManagementService?.appInfo$ | async"
    [mainChatID]="(authService?.authCollection$ | async)?.main_group_id"
  >
  </app-systems-modal>
</app-modal-container> -->

<!-- Publish modal to publish the app -->
<!-- <app-modal-container
  *ngIf="publishModal"
  (cancelNotfiy)="showPublishModal(false)"
>
  <app-publish-release
    [isChannelConfOnly]="isChannelConfOnly"
    (cancelNotify)="showPublishModal(false)"
  >
  </app-publish-release>
</app-modal-container> -->
