import { Action } from '@ngrx/store';
import { Package, PackageItem } from 'src/models/Package';

export const enum PackagesUIActionTypes {
  CREATE_PACKAGE = 'CREATE_PACKAGE',
  SET_PACKAGE = 'SET_PACKAGE',
  ADD_PACKAGE_ITEMS = 'ADD_PACKAGE_ITEMS',
  REMOVE_PACKAGE_BUNDLES = 'REMOVE_PACKAGE_BUNDLES',
  PACKAGE_RECEIVED = 'PACKAGE_RECEIVED',
  //
  UI_PACKAGES_GET_NEXT_PAGE = 'UI_PACKAGES_GET_NEXT_PAGE',
  UI_PACKAGES_GET_PREV_PAGE = 'UI_PACKAGES_GET_PREV_PAGE',
  UI_PACKAGES_RECEIVED_HISTORY_CHUNK = 'UI_PACKAGES_RECEIVED_HISTORY_CHUNK',
  UI_PACKAGES_RESET_PAGING = 'UI_PACKAGES_RESET_PAGING',
  UI_PACKAGES_SET_IDLE_STATUS = 'UI_PACKAGES_SET_IDLE_STATUS',
  DELETE_PACKAGE = 'DELETE_PACKAGE',
  PACKAGE_DELETED = 'PACKAGE_DELETED',
  RESET = 'RESET'
}

export class CreatePackage implements Action {
  readonly type = PackagesUIActionTypes.CREATE_PACKAGE;
  constructor(public packageDetails: Package) {}
}

export class SetPackage implements Action {
  readonly type = PackagesUIActionTypes.SET_PACKAGE;
  constructor(public packageID: string, public packageDetails: Package) {}
}

export class AddPackageItems implements Action {
  readonly type = PackagesUIActionTypes.ADD_PACKAGE_ITEMS;
  constructor(public packageID: string, public itemsIDs: PackageItem[]) {}
}

export class RemovePackageBundles implements Action {
  readonly type = PackagesUIActionTypes.REMOVE_PACKAGE_BUNDLES;
  constructor(public packageID: string, public bundlesIDs: string[]) {}
}
export class DeletePackage implements Action {
  readonly type = PackagesUIActionTypes.DELETE_PACKAGE;
  constructor(public packageID: string) {}
}

export class PackageDeleted implements Action {
  readonly type = PackagesUIActionTypes.PACKAGE_DELETED;
  constructor(public id: string) {}
}

export class PackageReceived implements Action {
  readonly type = PackagesUIActionTypes.PACKAGE_RECEIVED;
  constructor(public pkg: Package) {}
}

// Paging Actions

export class GetNextPackagesPage implements Action {
  readonly type = PackagesUIActionTypes.UI_PACKAGES_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevPackagesPage implements Action {
  readonly type = PackagesUIActionTypes.UI_PACKAGES_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedPackagesHistoryChunk implements Action {
  readonly type = PackagesUIActionTypes.UI_PACKAGES_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: Package[]
  ) {}
}

export class ResetPackagesPaging implements Action {
  readonly type = PackagesUIActionTypes.UI_PACKAGES_RESET_PAGING;
  constructor() {}
}

export class SetStatusAsIdle implements Action {
  readonly type = PackagesUIActionTypes.UI_PACKAGES_SET_IDLE_STATUS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = PackagesUIActionTypes.RESET;
}

export type Actions =
  | CreatePackage
  | SetPackage
  | AddPackageItems
  | RemovePackageBundles
  | GetNextPackagesPage
  | GetPrevPackagesPage
  | ReceivedPackagesHistoryChunk
  | ResetPackagesPaging
  | PackageReceived
  | SetStatusAsIdle
  | DeletePackage
  | PackageDeleted
  | Reset;
