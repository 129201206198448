import { MyBookings } from 'src/models/MyBookings';
import * as actions from './myBookings.actions';
import { Actions } from './myBookings.actions';
const INITIAL_STATE: MyBookings[] = [];

export function myBookingsReducer(
  state = INITIAL_STATE,
  action: Actions
): MyBookings[] {
  switch (action.type) {
    case actions.MyBookingsActionTypes.RECEIVE_MY_BOOKINGS: {
      if (
        !state.find(
          myBookings => myBookings.chat_id === action.myBookings.chat_id
        )
      ) {
        return [...state, action.myBookings];
      } else {
        return state.map(myBookings => {
          if (myBookings.chat_id === action.myBookings.chat_id) {
            return { ...myBookings, ...action.myBookings };
          }
          return myBookings;
        });
      }
    }
    case actions.MyBookingsActionTypes.RECEIVE_MY_BOOKING_DAYS: {
      if (!state.find(myBookings => myBookings.chat_id === action.chatID)) {
        const myBooking: MyBookings = {};
        myBooking.chat_id = action.chatID;
        myBooking.bookedDates = action.bookedDates;
        return [...state, myBooking];
      } else {
        return state.map(myBookings => {
          if (myBookings.chat_id === action.chatID) {
            const myBooking: MyBookings = {};
            myBooking.bookedDates = action.bookedDates;
            return { ...myBookings, myBooking };
          }
          return myBookings;
        });
      }
    }
    case actions.MyBookingsActionTypes.MY_BOOKINGS_USERS_RECEIVED: {
      let myBookings = [...state];
      if (myBookings && action.userNames) {
        myBookings = state.map(booking => {
          const updatedBooking = { ...booking };
          const updatedData = booking.data.map(slot => {
            const updatedSlot = { ...slot };
            updatedSlot.user_name = action.userNames[updatedSlot.user_id];
            return updatedSlot;
          });
          updatedBooking.data = updatedData;
          return updatedBooking;
        });
      }
      return myBookings;
    }
    case actions.MyBookingsActionTypes.MY_BOOKING_STATUS_UPDATED: {
      let myBookings = [...state];
      if (myBookings) {
        myBookings = state.map(booking => {
          const updatedBooking = { ...booking };
          if (updatedBooking.chat_id === action.chat_id) {
            const updatedData = booking.data.map(slot => {
              if (
                slot.user_id === action.user_id &&
                slot.date === action.date &&
                slot.start_time === action.time &&
                slot.reference === action.reference
              ) {
                const updatedSlot = { ...slot };
                updatedSlot.status = action.status;
                updatedSlot.cancelable = action.cancelable;
                return updatedSlot;
              } else {
                return slot;
              }
            });
            updatedBooking.data = updatedData;
          }
          return updatedBooking;
        });
      }
      return myBookings;
    }
    case actions.MyBookingsActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
