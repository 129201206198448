import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var Formilla: any;

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      // hide formilla-form
      if (event instanceof NavigationEnd) {
        const formillaElem = document.getElementById('formilla-frame');
        if (formillaElem) {
          if (event.urlAfterRedirects.indexOf('app') === -1) {
            formillaElem.style.display = 'none';
          } else {
            formillaElem.style.display = 'block';
          }
        }
      }
    });
  }
}
