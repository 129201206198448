import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  EventEmitter,
  ElementRef,
  Output,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FileService } from 'src/app/core/file.service';
import { InstantArticle } from 'src/models/InstantArticle';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { MessageTypes } from 'src/models/constants';
import { CalendarTimetable } from 'src/models/Calendar';
import { PrivilegesName } from 'src/models/privileges';
import { UiService } from 'src/app/core/ui.service';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

@Component({
  selector: 'app-select-message-type',
  templateUrl: './select-message-type.component.html',
  styleUrls: ['./select-message-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageTypeComponent implements OnChanges {
  @ViewChild('gifInputFile', { static: false }) gifInputFile: ElementRef;
  @ViewChild('audioInputFile', { static: false }) audioInputFile: ElementRef;
  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;
  @ViewChild('videoInputFile', { static: false }) videoInputFile: ElementRef;
  @ViewChild('documentInputFile', { static: false })
  documentInputFile: ElementRef;

  @Input() messageType: string;
  @Input() messageLinkPreview: InstantArticle;
  @Input() messageMediaPreview: boolean;
  @Input() isPostScreen = false;

  @Output() textNotifiy = new EventEmitter<boolean>();
  @Output() fileSelectedNotifiy = new EventEmitter<ISelectedFiles>();
  @Output() urlSelectedNotifiy = new EventEmitter<number>();
  @Output() calendarSelectedNotifiy = new EventEmitter<any>();
  @Output() linkSelectedNotifiy = new EventEmitter<any>();

  private _isInstant = new BehaviorSubject<boolean>(false);
  public isInstant$ = this._isInstant.asObservable();
  privilegesName: typeof PrivilegesName = PrivilegesName;

  mType = MessageTypes;

  showComposeUrl = false;
  showComposeCalendar = false;

  hoverTooltip = false;
  top: number;
  right: number;
  text: string;
  hoverText: boolean;
  hoverPhoto: boolean;
  hoverVideo: boolean;
  hoverGif: boolean;
  hoverAudio: boolean;
  hoverDocument: boolean;
  hoverArticle: boolean;
  hoverCalendar: boolean;
  hoverContact: boolean;

  constructor(
    private _fileService: FileService,
    private uiService: UiService,
    public layoutService: LayoutService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.messageLinkPreview && this.messageLinkPreview.isInstant) {
      this.uiService._previewMessageType.next(this.mType.ARTICLE);
    } else if (this.messageLinkPreview && !this.messageLinkPreview.isInstant) {
      this.uiService._previewMessageType.next(this.mType.CONTACT);
    }
  }

  setText() {
    this.textNotifiy.emit(true);
  }

  getCircleStyle(type: string) {
    if (type === this.messageType && !this.messageLinkPreview) {
      return { 'background-color': '#459FED' };
    }
    return { 'background-color': '#fff', 'box-shadow': '0 0 4px 0 #c1cde0' };
  }

  getIconStyle(type: string) {
    switch (type) {
      case this.mType.TEXT:
        return {
          fill: '#06a1bf'
        };
      case this.mType.PHOTO:
        return {
          fill: '#4063a8'
        };
      case this.mType.VIDEO:
        return {
          fill: '#09a331'
        };
      case this.mType.GIF:
        return {
          fill: '#e44a1e'
        };
      case this.mType.AUDIO:
        return {
          fill: '#009eea'
        };
      case this.mType.DOCUMENT:
        return {
          fill: '#4a4fe1'
        };
      case this.mType.ARTICLE:
        return {
          fill: '#ef9500'
        };
      case this.mType.CONTACT:
        return {
          fill: '#3673d7'
        };
      case this.mType.CALENDAR:
        return {
          fill: '#e75365'
        };
    }
  }

  getCircleTextUrlStyle() {
    if (this.messageLinkPreview && !this.messageLinkPreview.isInstant) {
      return { 'background-color': '#459FED' };
    }
    return { 'background-color': '#fff', 'box-shadow': '0 0 4px 0 #c1cde0' };
  }

  getIconInstantUrlStyle() {
    if (this.messageLinkPreview && this.messageLinkPreview.isInstant) {
      return { fill: '#fff' };
    }
    return { fill: '#97A6C8' };
  }

  getIconUrlStyle() {
    if (this.messageLinkPreview && !this.messageLinkPreview.isInstant) {
      return { fill: '#fff' };
    }
    return { fill: '#97A6C8' };
  }

  getCircleInstantUrlStyle() {
    if (this.messageLinkPreview && this.messageLinkPreview.isInstant) {
      return { 'background-color': '#459FED' };
    }
    return { 'background-color': '#fff', 'box-shadow': '0 0 4px 0 #c1cde0' };
  }

  getPostIconColors(type: string) {
    switch (type) {
      case this.mType.TEXT:
        return {
          'background-color': 'rgba(66, 186, 209, 0.14)'
        };
      case this.mType.PHOTO:
        return {
          'background-color': '#e4edff'
        };
      case this.mType.VIDEO:
        return {
          'background-color': '#e3f9ec'
        };
      case this.mType.GIF:
        return {
          'background-color': '#f6e5e3'
        };
      case this.mType.AUDIO:
        return {
          'background-color': '#daeffe'
        };
      case this.mType.DOCUMENT:
        return {
          'background-color': '#ecedfc'
        };
      case this.mType.ARTICLE:
        return {
          'background-color': '#feefd6'
        };
      case this.mType.CONTACT:
        return {
          'background-color': '#e9f0fb'
        };
      case this.mType.CALENDAR:
        return {
          'background-color': '#fce9ec'
        };
    }
  }

  onFilesSelected() {
    const event: ISelectedFiles = this.getFile();
    if (event.localFile && event.type === this.mType.PHOTO) {
      this.getFileMetaData(event).then(res => {
        if (event.localFile) {
          this.fileSelectedNotifiy.emit({
            ...event,
            height: res.height,
            width: res.width
          });
          this.resetEvent();
        }
      });
    } else {
      if (event.localFile) {
        this.fileSelectedNotifiy.emit(event);
        this.resetEvent();
      }
    }
  }

  getFile(): ISelectedFiles {
    if (this.videoInputFile.nativeElement.files[0]) {
      const file = this.videoInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.imageInputFile.nativeElement.files[0]) {
      const file = this.imageInputFile.nativeElement.files[0];

      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.gifInputFile.nativeElement.files[0]) {
      const file = this.gifInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.audioInputFile.nativeElement.files[0]) {
      const file = this.audioInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.documentInputFile.nativeElement.files[0]) {
      const file = this.documentInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    }
  }

  resetEvent() {
    if (this.imageInputFile) {
      this.imageInputFile.nativeElement.value = '';
    }
    if (this.gifInputFile) {
      this.gifInputFile.nativeElement.value = '';
    }
    if (this.videoInputFile) {
      this.videoInputFile.nativeElement.value = '';
    }
    if (this.audioInputFile) {
      this.audioInputFile.nativeElement.value = '';
    }
    if (this.documentInputFile) {
      this.documentInputFile.nativeElement.vale = '';
    }
  }

  showComposeUrlEvent(e: boolean, isInstant?: boolean) {
    this.showComposeUrl = e;
    this._isInstant.next(isInstant);
  }

  showComposeCalendarEvent(e: boolean) {
    this.showComposeCalendar = e;
  }

  urlSelectedEvent(ref: number) {
    this.urlSelectedNotifiy.emit(ref);
    this.showComposeUrlEvent(false);
  }

  calendarSelectedEvent(calendar: CalendarTimetable) {
    this.calendarSelectedNotifiy.emit(calendar);
    this.uiService._previewMessageType.next(this.mType.CALENDAR);
    this.showComposeCalendarEvent(false);
  }

  toggleTooltip(e, top?, right?, type?) {
    switch (type) {
      case this.mType.TEXT:
        this.hoverText = e;
        this.top = top;
        this.right = right;
        this.text = 'Write Text';
        break;
      case this.mType.PHOTO:
        this.hoverPhoto = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload Image';
        break;
      case this.mType.VIDEO:
        this.hoverVideo = e;
        this.top = top;
        this.right = right;
        this.text = 'Add Video';
        break;
      case this.mType.GIF:
        this.hoverGif = e;
        this.top = top;
        this.right = right;
        this.text = 'Add Animated Image (GIF)';
        break;
      case this.mType.AUDIO:
        this.hoverAudio = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload Audio';
        break;
      case this.mType.DOCUMENT:
        this.hoverDocument = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload File';
        break;
      case this.mType.ARTICLE:
        this.hoverArticle = e;
        this.top = top;
        this.right = right;
        this.text = 'Attach an Article URL';
        break;
      case this.mType.CONTACT:
        this.hoverContact = e;
        this.top = top;
        this.right = right;
        this.text = 'Link a website';
        break;
      case this.mType.CALENDAR:
        this.hoverCalendar = e;
        this.top = top;
        this.right = right;
        this.text = 'Attach Calendar';
        break;
    }
  }

  setLinkPrivew() {
    // this.uiService._previewMessageType.next(this.mType.CONTACT);
    this.linkSelectedNotifiy.emit(true);
  }

  getFileMetaData(file: any) {
    return this._fileService
      .readFileAsArrayBuffer(file.localFile)
      .then(fileAsArrayBuffer =>
        this._fileService
          .readArrayBufferAsBlobUrl(fileAsArrayBuffer, file.localFile.type)
          .then(blobUrl => this._fileService.getImageMetadata(blobUrl))
      );
  }
}
