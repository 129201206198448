import { BehaviorSubject } from 'rxjs';
import { PackagesUiDispatchers } from 'src/app/store/packagesUI/packagesUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { PackageScreens, MarketTypes } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/packagesUI/packagesUI.dispatchers";
export class PackagesService {
    constructor(_rootStore, _packagesDispatchers) {
        this._rootStore = _rootStore;
        this._packagesDispatchers = _packagesDispatchers;
        this._selectedPackage = new BehaviorSubject(null);
        this.selectedPackage$ = this._selectedPackage.asObservable();
        this._currentPackageScreen = new BehaviorSubject(PackageScreens.PACKAGE_LIST);
        this.currentPackageScreen$ = this._currentPackageScreen.asObservable();
        this.confirmPackage = false;
    }
    get packages$() {
        return this._rootStore.getPackages$();
    }
    resetPaging() {
        this._packagesDispatchers.resetPackagesPaging();
    }
    updatePackage(pkg) {
        if (pkg && pkg.id) {
            if (pkg.bundles) {
                const bundleIds = pkg.bundles.map(r => {
                    return { id: r.id, type: MarketTypes.BUNDLE };
                });
                this._packagesDispatchers.addPackageItems(pkg.id, bundleIds);
            }
            if (pkg.products) {
                const productsIds = pkg.products.map(r => {
                    return { id: r.id, type: MarketTypes.PRODUCT };
                });
                this._packagesDispatchers.addPackageItems(pkg.id, productsIds);
            }
            this._packagesDispatchers.setPackage(pkg.id, pkg);
        }
        else {
            this._packagesDispatchers.createPackage(pkg);
        }
        this.closeItemCardEvent();
    }
    setPackageProducts(pkgId, items) {
        const productIDs = items.map(r => {
            return { id: r.id, type: MarketTypes.PRODUCT };
        });
        this._packagesDispatchers.addPackageItems(pkgId, productIDs);
    }
    setPackageBundles(pkgId, items) {
        const bundleIDs = items.map(r => {
            const pr = { id: r.id, type: MarketTypes.BUNDLE };
            return pr;
        });
        this._packagesDispatchers.addPackageItems(pkgId, bundleIDs);
    }
    removePackageBundle(pkgId, bundleIds) {
        this._packagesDispatchers.removePackageBundles(pkgId, bundleIds);
    }
    createItemEvent() {
        this.setCurrentScreen(PackageScreens.PACKAGE_CREATION);
        this.setSelectedPackage(null);
    }
    readItemEvent(pkg) {
        this.setCurrentScreen(PackageScreens.PACKAGE_VIEW);
        this.setSelectedPackage(pkg);
    }
    updateItemEvent(pkg) {
        this.setCurrentScreen(PackageScreens.PACKAGE_EDIT);
        this.setSelectedPackage(pkg);
    }
    closeItemCardEvent() {
        this.setCurrentScreen(PackageScreens.PACKAGE_LIST);
        this.setSelectedPackage(null);
    }
    deletePackage(packageId) {
        this._packagesDispatchers.deletePackages(packageId);
    }
    toggelConfirmDeletePackage(e, packg) {
        this.confirmPackage = e;
        this.selectedPackage = packg;
    }
    setSelectedPackage(pkg) {
        this._selectedPackage.next(pkg);
    }
    setCurrentScreen(screen) {
        this._currentPackageScreen.next(screen);
    }
    getNextPage() {
        this._packagesDispatchers.getNextPackagesPage();
    }
    getPreviousPage() {
        this._packagesDispatchers.getPrevPackagesPage();
    }
    getChannelDetails$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
}
PackagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PackagesService_Factory() { return new PackagesService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.PackagesUiDispatchers)); }, token: PackagesService, providedIn: "root" });
