import { MainChat } from 'src/models/MainChat';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  OnInit,
  Renderer2,
  Output,
  EventEmitter
} from '@angular/core';

import { ICoupon } from 'src/models/ICoupon';
import { Sticky } from 'src/models/MessageParts';
import { BotMenu } from 'src/models/IBotMenu';
import { PrivilegesName } from 'src/models/privileges';
import { ComposeMessageService } from '../compose-message.service';
import { GROUP, MessageTypes } from 'src/models/constants';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

interface LocalStateType {
  showCouponsPool: boolean;
  attachedCoupon: ICoupon;
  showStickyInput: boolean;
  attachedSticky: Sticky;
  showMenusPool: boolean;
  attachedMenu: BotMenu;
}

@Component({
  selector: 'app-select-message-attachs',
  templateUrl: './select-message-attachs.component.html',
  styleUrls: ['./select-message-attachs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageAttachsComponent implements OnChanges, OnInit {
  localState: LocalStateType = {
    showCouponsPool: false,
    attachedCoupon: null,
    showStickyInput: false,
    attachedSticky: null,
    showMenusPool: false,
    attachedMenu: null
  };
  couponEdit: ICoupon = {};
  privilegesName: typeof PrivilegesName = PrivilegesName;
  mType: typeof MessageTypes = MessageTypes;
  hoverAttach: boolean;
  group = GROUP;
  @Input() selectedTab: any;
  @Input() currentSelectedType: string;
  @Input() selectedChat: MainChat;
  @Input() privileges: string[];
  @Output() stickyNotify = new EventEmitter<Sticky>();
  @Output() menuNotify = new EventEmitter<BotMenu>();
  @Output() couponNotify = new EventEmitter<ICoupon>();
  @ViewChild('menuButton', { static: false }) menuButton: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;

  public _toggleMenu = new BehaviorSubject<boolean>(false);
  public toggleMenu$ = this._toggleMenu.asObservable();

  constructor(
    public composeMessageService: ComposeMessageService,
    private renderer: Renderer2,
    public layoutService: LayoutService
  ) {}
  ngOnInit() {
    // this.resetStickyInput();
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu) {
        const btnClicked = this.menuButton.nativeElement.contains(e.target);
        const menuClicked = this.menu.nativeElement.contains(e.target);
        if (
          this.menu &&
          this.menu.nativeElement &&
          !btnClicked &&
          !menuClicked
        ) {
          this._toggleMenu.next(false);
          this.menuButton.nativeElement.checked = false;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.selectedTab.sticky_visible ||
      this.currentSelectedType === this.mType.TEXT ||
      this.currentSelectedType === this.mType.DOCUMENT ||
      this.currentSelectedType === this.mType.AUDIO ||
      this.currentSelectedType === this.mType.CALENDAR
    ) {
      this.resetStickyInput();
    }
  }

  // Coupos pool events
  toggleCouponsPool(show: boolean) {
    this._toggleMenu.next(false);
    if (this.menuButton) {
      this.menuButton.nativeElement.checked = false;
    }
    this.localState = { ...this.localState, showCouponsPool: show };
  }
  setCouponEvent(coupon: ICoupon) {
    this.localState = { ...this.localState, attachedCoupon: coupon };
    this.toggleCouponsPool(false);
    this.couponNotify.emit(coupon);
  }
  resetCouponEvent() {
    this.localState = { ...this.localState, attachedCoupon: null };
  }

  // Menus pool events
  toggleMenusPool(show: boolean) {
    this._toggleMenu.next(false);
    if (this.menuButton) {
      this.menuButton.nativeElement.checked = false;
    }
    this.localState = { ...this.localState, showMenusPool: show };
  }
  setMenuEvent(menu: BotMenu) {
    this.localState = { ...this.localState, attachedMenu: menu };
    this.toggleMenusPool(false);
    this.menuNotify.emit(menu);
  }
  resetMenuEvent() {
    this.localState = { ...this.localState, attachedMenu: null };
  }

  // sticky note events
  toggleStickyInput(show: boolean) {
    this._toggleMenu.next(false);
    if (this.menuButton) {
      this.menuButton.nativeElement.checked = false;
    }
    this.localState = { ...this.localState, showStickyInput: show };
  }
  setStickyInput(value: Sticky) {
    this.localState = { ...this.localState, attachedSticky: value };

    this.stickyNotify.emit(value);
    this.toggleStickyInput(false);
  }
  resetStickyInput() {
    this.localState = { ...this.localState, attachedSticky: null };
    this.stickyNotify.emit(null);
  }

  // get values
  getSelectedCoupon(): ICoupon {
    return this.localState.attachedCoupon;
  }
  getSelectedMenu(): BotMenu {
    return this.localState.attachedMenu;
  }
  getStickyInput() {
    return this.localState.attachedSticky;
  }
  toggleHoverAttach(e) {
    this.hoverAttach = e;
  }
  onClick(event) {
    event.stopPropagation();

    if (
      this.privileges.indexOf(this.privilegesName.listmenu) !== -1 ||
      this.privileges.indexOf(this.privilegesName.listcoupon) !== -1 ||
      this.selectedTab.sticky_visible
    ) {
      if (this.menuButton.nativeElement.checked) {
        this._toggleMenu.next(false);
      } else {
        this._toggleMenu.next(true);
      }
    }
  }
}
