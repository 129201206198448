import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';
import { SetAdsProviderMethod, GetAdsProvidersMethod } from 'src/models/PaymentGateways';
export class AdsProvidersEffects {
    constructor(actions$, _socketGateway) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this.setAdsProvider = this.actions$.pipe(ofType("SET_ADS_PROVIDER" /* SET_ADS_PROVIDER */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAdsProviderMethod(action.adsProviderId, action.config));
        }));
        this.getAdsProvider = this.actions$.pipe(ofType("GET_ADS_PROVIDERS" /* GET_ADS_PROVIDERS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAdsProvidersMethod(action.adsProviderId));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AdsProvidersEffects.prototype, "setAdsProvider", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AdsProvidersEffects.prototype, "getAdsProvider", void 0);
