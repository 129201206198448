import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { MapMarkerActionTypes } from './mapMarker.actions';
import * as MapActions from './mapMarker.actions';
import * as Methods from 'src/models/MapMarker';
import { MapMarker, GetMapMarkers } from 'src/models/MapMarker';
import { AppState } from 'src/models/AppState';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
import { PageDirection } from 'src/models/constants';
import { MapMarkerDispatchers } from './mapMarker.dispatchers';

@Injectable()
export class MapMarkerEffects {
  @Effect({ dispatch: false })
  createMapMarker = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_CREATE_MAP_MARKER),
    map((action: MapActions.CreateMapMarker) => {
      this._socketGateway.sendSocketMessage(
        new Methods.CreateMapMarker(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  getMapMarker = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_GET_MAP_MARKER),
    map((action: MapActions.GetMapMarker) => {
      this._socketGateway.sendSocketMessage(
        new Methods.GetMapMarker(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  updateMapMarker = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_UPDATE_MAP_MARKER),
    map((action: MapActions.UpdateMapMarker) => {
      const mapMarker: MapMarker = {
        ...action.mapMarkerDetails,
        id: action.id
      };
      this._socketGateway.sendSocketMessage(
        new Methods.SetMapMarker(mapMarker)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMapMarker = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_DELETE_MAP_MARKER),
    map((action: MapActions.DeleteMapMarker) => {
      this._socketGateway.sendSocketMessage(
        new Methods.RemoveMapMarker(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  getNextMapMarkerPage = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_MAP_MARKER_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapMarkerReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapMarkerUiState]) => {
      const action = <MapActions.GetNextMapMarkerPage>val;
      const prevRequest = mapMarkerUiState.previousRequest;
      if (
        !(
          mapMarkerUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          mapMarkerUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMapMarkers(
            0,
            mapMarkerUiState.hash,
            mapMarkerUiState.eop,
            mapMarkerUiState.selected_chat,
            mapMarkerUiState.type
          )
        );
      } else {
        this._mapMarkerDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMapMarkerPage = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_MAP_MARKER_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.mapMarkerReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mapMarkerUiState]) => {
      const action = <MapActions.GetPrevMapMarkerPage>val;
      const prevRequest = mapMarkerUiState.previousRequest;
      if (
        !(
          mapMarkerUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          mapMarkerUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMapMarkers(
            1,
            mapMarkerUiState.hash,
            mapMarkerUiState.sop,
            mapMarkerUiState.selected_chat,
            mapMarkerUiState.type
          )
        );
      } else {
        this._mapMarkerDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterMapMarkerResetPaging = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_MAP_MARKER_RESET_PAGING),
    map((action: MapActions.ResetMapMarkerPaging) => {
      this._mapMarkerDispatchers.getNextMapMarkerPage();
    })
  );

  @Effect({ dispatch: false })
  afterMapMarkerDeleted = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_MAP_MARKER_DELETED),
    map((action: MapActions.DeleteMapMarker) => {
      this._mapMarkerDispatchers.resetMapMarkerPaging();
    })
  );

  @Effect({ dispatch: false })
  setMapMarkerSelectedChat = this.actions$.pipe(
    ofType(MapMarkerActionTypes.UI_MAP_MARKER_SET_SELECTED_CHAT),
    map((action: MapActions.SetMapMarkerSelectedChat) => {
      this._mapMarkerDispatchers.resetMapMarkerPaging();
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _mapMarkerDispatchers: MapMarkerDispatchers,
    private _socketGateway: SocketGateway
  ) {}
}
