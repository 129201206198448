<div class="message-container"
  [class.container]="currentBackGroundColor !== '#fff'">
  <div class="box-container"
    [ngStyle]="currentBoxStyle">
    <div [ngStyle]="styleLargeText">
      <div class="edit wrap-text  copyable-item text-cursor"
        appAutoFocus
        data-text="Type your post here …"
        contenteditable
        dir="auto"
        #textBox
        (paste)="onRichPaste($event)"
        (input)="checkIfMessageIsShort()"
        (mouseup)="lastfocus()"
        (keyup)="lastfocus()"
        (keydown)="createNewLine($event)"></div>
    </div>
  </div>
  <ng-content select="[message-attachs]"></ng-content>
  <div class="emoji-btn"
    [popover]="emojiPopover"
    [outsideClick]="true"
    [placement]="'left'"
    (click)="onFocus()">
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-sections.svg#icon-emoji-copy"></use>
    </svg>
  </div>
  <div class="actions">
    <div class="color-selector">
      <label class="color-btn"
        for="colorMenu"
        (click)="onClick($event)"
        [ngStyle]="selectedColor()">
        <input type="checkbox"
          id="colorMenu"
          #menuButton />
        <svg class="svg-icon icon-icon"
          *ngIf="currentBackGroundColor !== '#fff'">
          <use xlink:href="/assets/svg/app-extra-icons.svg#icon-close-ex"></use>
        </svg>

      </label>
      <div class="color-menu"
        #menu
        *ngIf="toggleMenu$ | async">
        <div class="color-circle"
          *ngFor="let color of colorsList"
          [ngStyle]="{ 'background-color': color }"
          (click)="setCurrentBoxBackgroundColor(color)"></div>
      </div>
    </div>
  </div>
</div>

<!-- Message Attachs -->
<!------------------------------------------------->
<!-- Message Targets ( Tags and Tab ) -->
<ng-content select="[message-target]"></ng-content>
<!------------------------------------------------->

<div class="footer">
  <div class="form-submit">
    <ng-content select="[footer-tag]"></ng-content>
    <ng-content select="[footer-submit]"></ng-content>
    <ng-content select="[footer-cancel]"></ng-content>
  </div>
</div>
<!-- Emoji popover -->
<ng-template #emojiPopover>
  <app-emoji-keyboard (emoijNotify)="emoijNotify($event)"></app-emoji-keyboard>
</ng-template>