import { Store } from '@ngrx/store';
import * as MapRouteActions from './mapRoute.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MapRouteDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetMapRoutePaging() {
        this._store.dispatch(new MapRouteActions.ResetMapRoutesPaging());
    }
    getNextMapRoutePage() {
        this._store.dispatch(new MapRouteActions.GetNextMapRoutesPage());
    }
    getPrevMapRoutePage() {
        this._store.dispatch(new MapRouteActions.GetPrevMapRoutesPage());
    }
    getMapRouteByID(id) {
        this._store.dispatch(new MapRouteActions.GetMapRouteByID(id));
    }
    createMapRoute(mapRoute) {
        this._store.dispatch(new MapRouteActions.CreateMapRoute(mapRoute));
    }
    mapRouteCreated(mapRoute) {
        this._store.dispatch(new MapRouteActions.MapRouteCreated(mapRoute));
    }
    updateMapRoute(id, mapRoute) {
        this._store.dispatch(new MapRouteActions.UpdateMapRoute(id, mapRoute));
    }
    mapRouteDetailsReceived(mapRoute) {
        this._store.dispatch(new MapRouteActions.MapRouteDetailsReceived(mapRoute));
    }
    mapRouteListReceived(eop, sop, mapRoutes, hash) {
        this._store.dispatch(new MapRouteActions.MapRouteListReceived(eop, sop, mapRoutes, hash));
    }
    setCurrentScreen(screen) {
        this._store.dispatch(new MapRouteActions.SetCurrentScreen(screen));
    }
    deleteMapRoute(id) {
        this._store.dispatch(new MapRouteActions.DeleteMapRoute(id));
    }
    mapRouteDeleted(id) {
        this._store.dispatch(new MapRouteActions.MapRouteDeleted(id));
    }
    setStatusAsIdle() {
        this._store.dispatch(new MapRouteActions.SetStatusAsIdle());
    }
}
MapRouteDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapRouteDispatchers_Factory() { return new MapRouteDispatchers(i0.ɵɵinject(i1.Store)); }, token: MapRouteDispatchers, providedIn: "root" });
