import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { PAY_LINK } from 'src/models/constants';

import { IPricePlan } from 'src/models/IPricePlan';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPlanComponent {
  @Input() planInput: IPricePlan[];
  @Input() paymentPlan: string;
  @Input() isYealy: boolean;
  @Input() playStore;
  @Output() closeNotify = new EventEmitter<boolean>();
  brief: any[] = [];

  constructor(private _subscriptionService: SubscriptionService) {}

  get plan() {
    if (this.isYealy) {
      this.brief = this.planInput[1].brief.split(',');
      return this.planInput[1];
    }
    this.brief = this.planInput[1].brief.split(',');
    return this.planInput[0];
  }

  planEvent() {
    if (this.plan.id === 'plan_4') {
      this._subscriptionService.selectFreePayment(this.plan);
      this.closeNotify.emit();
    } else {
      this._subscriptionService.setSelectedPayment(this.plan);
    }
  }
  cancelPlanEvent() {
    if (this.plan.id === 'plan_4') {
      this._subscriptionService.setCanceledFreePayment(this.plan);
      this.closeNotify.emit();
    } else {
      this._subscriptionService.setCanceledPayment(this.plan);
    }
  }
  editPlanEvent() {
    const url = `${PAY_LINK}/edit/${this.playStore.payment_plan}/${this.playStore.paymentToken}`;
    window.open(url, '_blank');
    this.closeNotify.emit(true);
  }
}
