import { Store } from '@ngrx/store';
import * as SummaryUIActions from './summaryUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SummaryUIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    setDashboard(dashboard) {
        this._store.dispatch(new SummaryUIActions.SetDashboard(dashboard));
    }
    createNewWidget(widget) {
        this._store.dispatch(new SummaryUIActions.CreateNewWidget(widget));
    }
    updateWidget(widget) {
        this._store.dispatch(new SummaryUIActions.UpdateWidget(widget));
    }
    getUserDashboardList() {
        this._store.dispatch(new SummaryUIActions.GetUserDashboardList());
    }
    reOrderList(widgets) {
        this._store.dispatch(new SummaryUIActions.ReOrderList(widgets));
    }
    updateDashboardList() {
        this._store.dispatch(new SummaryUIActions.UpdateDashboardList());
    }
    receivedDashboardHistoryChunk(dashboard) {
        this._store.dispatch(new SummaryUIActions.ReceivedDashboardHistoryChunk(dashboard));
    }
    saveDefaultedDashboardList() {
        this._store.dispatch(new SummaryUIActions.SaveDefaultedDashboardList());
    }
    removeWidget(widget) {
        this._store.dispatch(new SummaryUIActions.RemoveWidget(widget));
    }
}
SummaryUIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SummaryUIDispatchers_Factory() { return new SummaryUIDispatchers(i0.ɵɵinject(i1.Store)); }, token: SummaryUIDispatchers, providedIn: "root" });
