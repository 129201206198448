<app-form-frame no-padding [showCancel]="true" (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">{{ 'ADDCOUPON' | translate }}</ng-container>
  <ng-container ngProjectAs="desc">
    {{ 'MANAGEYOURCOUPONOR' | translate }}
    <span class="link" (click)="createNewItem()">{{
      'CREATEANEWCOUPON' | translate
    }}</span
    >.
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <div class="pool">
      <div class="pool-body">
        <ng-container *ngIf="couponService.getCouponList$ | async as coupons">
          <app-selectable-cell-frame
            *ngFor="let coupon of coupons; trackBy: TrackByFunction"
            [title]="coupon?.name"
            [upcCode]="coupon?.code"
            [selectorId]="coupon?.couponId"
            [isSelected]="coupon?.couponId === currentSelectMessage?.couponId"
            [useNewStely]="true"
            [cellSize]="'S'"
            (checkClick)="setSelectMessage(coupon)"
            (checkChanged)="resetSelectMessage()"
          >
          </app-selectable-cell-frame>
        </ng-container>
        <app-empty-item-frame
          *ngIf="(couponService.getCouponList$ | async)?.length === 0"
          [emptySymbol]="couponFet?.symbolImage"
          [emptyTitle]="couponFet?.emptyTitle"
          [emptyDesc]="couponFet?.emptyPoolDesc"
        >
        </app-empty-item-frame>
      </div>
      <div
        class="navigate"
        *ngIf="(couponService.getCouponList$ | async)?.length > 0"
      >
        <div class="navigate-btn" (click)="couponService?.getPrevPage()">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
        <div class="navigate-btn" (click)="couponService?.getNextPage()">
          <svg class="svg-icon icon-icon" style="transform: rotate(180deg);">
            <use
              xlink:href="/assets/svg/app-extra-icons.svg#icon-navigate"
            ></use>
          </svg>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container ngProjectAs="[footer]">
    <div class="footer">
      <button
        class="add-btn"
        (click)="insertEvent()"
        [disabled]="(couponService.getCouponList$ | async)?.length === 0"
      >
        {{ 'ADD' | translate }}
      </button>
    </div>
  </ng-container>
</app-form-frame>
