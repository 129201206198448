import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CouponMiddleware } from './coupon.middleware';
import * as CouponMethods from 'src/models/ICoupon';
export class CouponEffects {
    constructor(actions$, _store, _socketGateway) {
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        // send update to the server after insert in store
        this.getCoupon = this.actions$.pipe(ofType("GET_COUPON" /* GET_COUPON */), map((action) => {
            this._socketGateway.sendSocketMessage(new CouponMethods.GetCoupon(action.payload));
        }));
        this.createCoupon = this.actions$.pipe(ofType("CREATE_COUPON" /* CREATE_COUPON */), map((action) => {
            if (!action.isCreateFromBool) {
                const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
                this._socketGateway.sendSocketMessage(new CouponMethods.CreateCoupon(cpToSend));
            }
            else {
                const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
                this._socketGateway.sendSocketMessage(new CouponMethods.CreateCouponFromBool(cpToSend));
            }
        }));
        this.updateCoupon = this.actions$.pipe(ofType("UPDATE_COUPON" /* UPDATE_COUPON */), map((action) => {
            const cpToSend = CouponMiddleware.sendCouponMessage(action.payload);
            this._socketGateway.sendSocketMessage(new CouponMethods.UpdateCoupon(cpToSend));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponEffects.prototype, "getCoupon", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponEffects.prototype, "createCoupon", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponEffects.prototype, "updateCoupon", void 0);
