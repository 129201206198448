import {
  GET_MY_BOOKINGS_METHOD,
  GET_MY_BOOKING_DAYS_METHOD,
  CANCEL_BOOKING_METHOD,
  REQUEST_MY_BOOKING,
  CANCEL_MY_BOOKING_REF
} from './constants';

export interface ReservedSlot {
  user_id?: string;
  user_name?: string;
  start_time?: string; // '09:00'
  end_time?: string; // '09:50'
  date?: string; // "2019-04-12"
  reference?: string; // wx910
  sequence?: number; // has meaning only in case of group type is queue
  status?: string;
  cancelable?: number;
}

export interface MyBookings {
  chat_id?: string;
  start_date?: string; // "2019-04-12"
  end_date?: string;
  eop?: number;
  data?: ReservedSlot[]; // that will be designed to hold the reservations of one day( the selected date)
  bookedDates?: string[]; // that will hold just array of reserved dates (for example in a month, based on the input start and end dates)
}

// Date format is"2019-10-26"
export class GetMyBookings {
  readonly method = GET_MY_BOOKINGS_METHOD;
  readonly ref = REQUEST_MY_BOOKING;
  constructor(
    public chat_id: string,
    public start_date: string,
    public end_date: string,
    public page_size?: number,
    public direction?: number,
    public reference?: number
  ) {}
}

export class GetMyBookingDays {
  readonly method = GET_MY_BOOKING_DAYS_METHOD;
  constructor(
    public chat_id: string,
    public start_date: string,
    public end_date: string
  ) {}
}

export class CancelBookingReservation {
  readonly method = CANCEL_BOOKING_METHOD;
  readonly override = 1;
  readonly reference = CANCEL_MY_BOOKING_REF;
  constructor(
    public chat_id: string,
    public user_id: string,
    public references: string[],
    public date: string,
    public time: string
  ) {}
}
