import { Actions } from './productsUI.actions';

import * as actions from './productsUI.actions';
import { ProductUI } from 'src/models/Product';
import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';
import { productsUIMiddleware } from './productsUI.middleware';

const INITIAL_STATE: ProductUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  productGroupId: null,
  ownerGroupId: null
};

export function productsUiReducer(
  state = INITIAL_STATE,
  action: Actions
): ProductUI {
  switch (action.type) {
    case actions.ProductsUIActionTypes.PRODUCT_RECEIVED: {
      if (
        !productsUIMiddleware.isProductExist(state.currentPage, action.product)
      ) {
        return {
          ...state,
          currentPage: [action.product, ...state.currentPage]
        };
      }
      return {
        ...state,
        currentPage: state.currentPage.map(r => {
          if (r.id === action.product.id) {
            return action.product;
          }
          return r;
        })
      };
    }
    case actions.ProductsUIActionTypes.PRODUCT_DELETED:
      return {
        ...state,
        currentPage: state.currentPage.filter(res => res.id !== action.id)
      };
    case actions.ProductsUIActionTypes.UI_PRODUCTS_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.ProductsUIActionTypes.UI_PRODUCTS_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.ProductsUIActionTypes.UI_PRODUCTS_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.ProductsUIActionTypes.UI_PRODUCTS_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.ProductsUIActionTypes.UI_PRODUCTS_RESET_PAGING: {
      return {
        ...INITIAL_STATE,
        productGroupId: action.product_group_id,
        ownerGroupId: action.ownerGroupId
      };
    }
    case actions.ProductsUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
