<div class="contact">
  <img
    class="avtar"
    src="./assets/img/AvatarBlank60pt@3x.png"
    width="48"
    [alt]="phoneName"
  />
  <div class="core copyable-item text-cursor">
    <div class="header">{{ phoneName }}</div>
    <div class="footer">{{ phoneNumber }}</div>
  </div>
</div>
