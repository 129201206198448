import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as BookingExceptionsActions from './bookingExceptions.actions';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import {
  SetBookingExceptions,
  RemoveBookingExceptions
} from 'src/models/IChat';

@Injectable()
export class BookingExceptionsEffects {
  @Effect({ dispatch: false })
  updateBookingPeriods = this.actions$.pipe(
    ofType(
      BookingExceptionsActions.BookingExceptionsTypes.SET_BOOKING_EXCEPTIONS
    ),
    map((action: BookingExceptionsActions.SetBookingExceptions) => {
      this._socketGateway.sendSocketMessage(
        new SetBookingExceptions(
          action.chatId,
          action.dates,
          action.recordIndex
        )
      );
    })
  );

  @Effect({ dispatch: false })
  deleteBookingExceptions = this.actions$.pipe(
    ofType(
      BookingExceptionsActions.BookingExceptionsTypes.DELETE_BOOKING_EXCEPTIONS
    ),
    map((action: BookingExceptionsActions.DeleteBookingExceptions) => {
      this._socketGateway.sendSocketMessage(
        new RemoveBookingExceptions(action.chatId, action.dates)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
