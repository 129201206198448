import { Action } from '@ngrx/store';
import { PaymentOrder } from 'src/models/PaymentGateways';

export const enum PaymentOrdersUIActionTypes {
  UI_PAYMENT_ORDERS_GET_NEXT_PAGE = 'UI_PAYMENT_ORDERS_GET_NEXT_PAGE',
  UI_PAYMENT_ORDERS_GET_PREV_PAGE = 'UI_PAYMENT_ORDERS_GET_PREV_PAGE',
  UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK = 'UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK',
  UI_PAYMENT_ORDERS_RESET_PAGING = 'UI_PAYMENT_ORDERS_RESET_PAGING',
  UI_PAYMENT_ORDERS_SET_IDLE_STATUS = 'UI_PAYMENT_ORDERS_SET_IDLE_STATUS',
  PAYMENT_ORDERS_USERS_RECEIVED = 'PAYMENT_ORDERS_USERS_RECEIVED',
  PAYMENT_ORDERS_GROUPS_RECEIVED = 'PAYMENT_ORDERS_GROUPS_RECEIVED',
  PAYMENT_STRIPE_SUB_CHANNEL = 'PAYMENT_STRIPE_SUB_CHANNEL',
  PAYMENT_STRIPE_SUB_CHANNEL_RESET = 'PAYMENT_STRIPE_SUB_CHANNEL_RESET',
  RESET = 'RESET'
}

// Paging Actions

export class GetNextPaymentOrdersPage implements Action {
  readonly type = PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_NEXT_PAGE;
  constructor(public paymentOrderID: string) {}
}

export class GetPrevPaymentOrdersPage implements Action {
  readonly type = PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_GET_PREV_PAGE;
  constructor(public paymentOrderID: string) {}
}

export class ReceivedPaymentOrdersHistoryChunk implements Action {
  readonly type =
    PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: PaymentOrder[]
  ) {}
}

export class PaymentOrdersUsersReceived implements Action {
  readonly type = PaymentOrdersUIActionTypes.PAYMENT_ORDERS_USERS_RECEIVED;
  constructor(public userNames: object) {}
}

export class PaymentOrdersGroupsReceived implements Action {
  readonly type = PaymentOrdersUIActionTypes.PAYMENT_ORDERS_GROUPS_RECEIVED;
  constructor(public groupsNames: object) {}
}

export class ResetPaymentOrdersPaging implements Action {
  readonly type = PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_RESET_PAGING;
  constructor(public paymentOrderID: string) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = PaymentOrdersUIActionTypes.UI_PAYMENT_ORDERS_SET_IDLE_STATUS;
  constructor() {}
}

export class CreateStripeSubChannel implements Action {
  readonly type = PaymentOrdersUIActionTypes.PAYMENT_STRIPE_SUB_CHANNEL;
  constructor() {}
}

export class ResetStripeSubChannel implements Action {
  readonly type = PaymentOrdersUIActionTypes.PAYMENT_STRIPE_SUB_CHANNEL_RESET;
  constructor(public provider_id: string) {}
}

export class Reset implements Action {
  readonly type = PaymentOrdersUIActionTypes.RESET;
}

export type Actions =
  | GetNextPaymentOrdersPage
  | GetPrevPaymentOrdersPage
  | ReceivedPaymentOrdersHistoryChunk
  | ResetPaymentOrdersPaging
  | SetStatusAsIdle
  | PaymentOrdersUsersReceived
  | PaymentOrdersGroupsReceived
  | Reset;
