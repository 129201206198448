import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ItemActions from './item.actions';

import { AppState } from 'src/models/AppState';
import { LocalItem, LocalComponent } from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class ItemDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateItem(itemRef: string, itemUpdatedProps: LocalItem): void {
    this._store.dispatch(new ItemActions.UpdateItem(itemRef, itemUpdatedProps));
  }

  updateItemLink(itemRef: string, itemLink: string, pageId: string): void {
    this._store.dispatch(
      new ItemActions.UpdateItemLink(itemRef, itemLink, pageId)
    );
  }

  requestUpdateItemLink(
    itemRef: string,
    itemLink: string,
    pageId: string
  ): void {
    this._store.dispatch(
      new ItemActions.RequestUpdateItemLink(itemRef, itemLink, pageId)
    );
  }

  reorderItem(itemRef: string, newPosition: number): void {
    this._store.dispatch(new ItemActions.ReorderItem(itemRef, newPosition));
  }

  deleteItemRequest(item: LocalItem): void {
    this._store.dispatch(new ItemActions.DeleteItemRequest(item));
  }

  deleteItem(item: LocalItem): void {
    this._store.dispatch(new ItemActions.DeleteItem(item));
  }

  addItemRequest(
    parentComponent: LocalComponent,
    specialItemValues?: LocalItem
  ): void {
    this._store.dispatch(
      new ItemActions.AddItemRequest(parentComponent, specialItemValues)
    );
  }

  addItem(item: LocalItem): void {
    this._store.dispatch(new ItemActions.AddItem(item));
    this.updateItemLink(item.ref, item.link, item.page_id);
  }

  deleteItemsOfComponent(parentComponent: LocalComponent): void {
    this._store.dispatch(
      new ItemActions.DeleteItemsOfComponent(parentComponent)
    );
  }

  addItems(items: LocalItem[]): void {
    this._store.dispatch(new ItemActions.AddItems(items));
  }
}
