import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as CampaignActions from './campaign.actions';

import { AppState } from 'src/models/AppState';
import { ICampaign, CampaignMessage } from 'src/models/ICampaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignDispatchers {
  constructor(private _store: Store<AppState>) {}

  campaignReceived(payload: CampaignMessage) {
    this._store.dispatch(new CampaignActions.CampaignReceived(payload));
  }
  requestScopeMessage(payload: string) {
    this._store.dispatch(new CampaignActions.RequestScopeMessage(payload));
  }
  createCampaign(payload: ICampaign) {
    this._store.dispatch(new CampaignActions.CreateCampaign(payload));
  }
  updateCampaign(payload: ICampaign) {
    this._store.dispatch(new CampaignActions.UpdateCampaign(payload));
  }
}
