import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { CalendarUiDispatchers } from 'src/app/store/calendarUI/calendarUI.dispatchers';

import { CalendarTimetable, CalendarDay } from 'src/models/Calendar';
import { CalendarUI } from 'src/models/CalendarUI';

@Injectable({ providedIn: 'root' })
export class CalendarsService {
  constructor(
    private _selectors: RootStoreSelectors,
    private _calendarUiDispatchers: CalendarUiDispatchers
  ) {}

  createCalendar(calendar: CalendarTimetable): void {
    this._calendarUiDispatchers.createCalendar(calendar);
  }

  updateCalendar(calendarId: string, calendar: CalendarTimetable) {
    this._calendarUiDispatchers.updateCalendar(calendarId, calendar);
  }

  setCalendarDetails(calendar_id: string, data: CalendarDay[]): void {
    this._calendarUiDispatchers.setCalendarDetails(calendar_id, data);
  }

  // The "references" is an array of the IDs of hours that need to be deleted
  deleteCalendarDetail(calendar_id: string, references: string[]): void {
    this._calendarUiDispatchers.deleteCalendarDetail(calendar_id, references);
  }

  viewCalendar(calendar: CalendarTimetable): void {
    this._calendarUiDispatchers.viewCalendarUI(calendar);
  }

  closeViewCalendar(): void {
    this._calendarUiDispatchers.closeViewCalendar();
  }

  showEditCalendarScreen(calendar: CalendarTimetable): void {
    this._calendarUiDispatchers.editCalendarUI(calendar);
  }

  closeEditCalendarScreen(): void {
    this._calendarUiDispatchers.closeEditCalendar();
  }

  showCreateCalendarScreen() {
    this._calendarUiDispatchers.createCalendarUI();
  }

  calendarCreationComplete(): void {
    this._calendarUiDispatchers.calendarCreationComplete();
  }

  getNextPage(): void {
    this._calendarUiDispatchers.getNextCalendarPage();
  }

  getPrevPage(): void {
    this._calendarUiDispatchers.getPrevCalendarPage();
  }

  resetPaging(): void {
    this._calendarUiDispatchers.resetCalendarPaging();
  }

  // calendar selectors

  get calendarChannelList$(): Observable<CalendarTimetable[]> {
    return this._selectors.getCalendarList$();
  }

  get selectedCalendar$(): Observable<CalendarTimetable> {
    return this._selectors.getSelectedCalendar$();
  }

  get calendarInCreationProcess$(): Observable<CalendarTimetable> {
    return this._selectors.getCalendarInCreationProcess$();
  }

  get calendarUiState$(): Observable<CalendarUI> {
    return this._selectors.calendarUiState$;
  }
}
