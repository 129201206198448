import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { FileService } from 'src/app/core/file.service';
import { ERROR_NOT_GIF_IMAGE, MessageTypes } from 'src/models/constants';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';

@Component({
  selector: 'app-media-center-uploader-input',
  templateUrl: './media-center-uploader-input.component.html',
  styleUrls: ['./media-center-uploader-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterUploaderInputComponent {
  @Input() mimeTypes: string;
  @Input() enableFileMetaData = false;
  @Output() filesSelectedNotify = new EventEmitter<ISelectedFiles>();
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  messageTypes: typeof MessageTypes = MessageTypes;
  constructor(
    private _uiDispatchers: UIDispatchers,
    private _fileService: FileService
  ) {}

  onFileSelectedEvent() {
    if (this.enableFileMetaData) {
      let selectedFiles: ISelectedFiles = {
        ...this.getFile()
      };
      this.getFileMetaData(this.fileUpload.nativeElement.files[0]).then(
        data => {
          selectedFiles = {
            ...selectedFiles,
            width: data.width,
            height: data.height
          };
          this.filesSelectedNotify.emit(selectedFiles);
        }
      );
    } else {
      this.filesSelectedNotify.emit(this.getFile());
    }
    this.resetEvent();
  }

  getFile(): ISelectedFiles {
    const file = this.fileUpload.nativeElement.files[0];
    let selectedFiles: ISelectedFiles = {
      localFile: file,
      type: this._fileService.getMessageTypeFromMimeType(file.type)
    };
    if (file) {
      return selectedFiles;
    }
  }

  resetEvent() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = '';
    }
  }

  getFileMetaData(file: any) {
    return this._fileService
      .readFileAsArrayBuffer(file)
      .then(fileAsArrayBuffer =>
        this._fileService
          .readArrayBufferAsBlobUrl(fileAsArrayBuffer, file.type)
          .then(blobUrl => this._fileService.getImageMetadata(blobUrl))
      );
  }
}
