import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as BlackList from './blacklistUI.actions';

import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class BlackListUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetBlacklistPaging(name: string) {
    this._store.dispatch(new BlackList.ResetBlackListPaging(name));
  }

  getNextBlacklistsPage() {
    this._store.dispatch(new BlackList.GetNextBlacklistsPage());
  }

  getPrevBlacklistsPage() {
    this._store.dispatch(new BlackList.GetPrevBlacklistsPage());
  }

  setStatusAsIdle() {
    this._store.dispatch(new BlackList.SetStatusAsIdle());
  }

  addUserBlacklist(
    users: string[],
    mainChatID: string,
    reference?: string,
    recordIndex?: number
  ) {
    this._store.dispatch(
      new BlackList.AddUserToBlacklist(
        users,
        mainChatID,
        reference,
        recordIndex
      )
    );
  }

  receivedBlacklistsHistoryChunk(
    eop: number,
    sop: number,
    currentPage: [],
    hash: string
  ) {
    this._store.dispatch(
      new BlackList.ReceivedBlacklistsHistoryChunk(eop, sop, currentPage, hash)
    );
  }

  addBlacklistSuccess(users: []) {
    this._store.dispatch(new BlackList.AddBlacklistSuccess(users));
  }

  removeUserFromBlacklist(user: [], mainChatID: string) {
    this._store.dispatch(
      new BlackList.RemoveUserFromBlacklist(user, mainChatID)
    );
  }

  removeUserFromBlacklistSuccess(user: []) {
    this._store.dispatch(new BlackList.RemoveUserFromBlacklistSuccess(user));
  }
  resetObject() {
    this._store.dispatch(new BlackList.Reset());
  }

  setLoadingScreenStatus(status: string) {
    this._store.dispatch(new BlackList.SetLoadingScreenStatus(status));
  }
}
