import * as lf from 'localforage';
import { INFO_DB, CHAT_LIST_DB, CACHED_FILES_DB, PROFILE_LIST_DB, BASE_URL_LOCAL_STORAGE_KEY, DEFAULT_BASE_URL_API, UPLOAD_URL_API_SUFFIX, DOWNLOAD_URL_API_SUFFIX, META_URL_API_SUFFIX, WS_URL_API_SUFFIX, DOWNLOAD_API, UPLOAD_API, META_API, WS_API, CHANNEL_API, CHANNEL_URL_API_SUFFIX, POST_COUNTER_DB } from 'src/models/constants';
import * as i0 from "@angular/core";
export class StorageService {
    constructor() {
        this.filesDB = lf.createInstance({
            name: CACHED_FILES_DB,
            storeName: CACHED_FILES_DB,
            driver: lf.INDEXEDDB
        });
        this.infoDB = lf.createInstance({
            name: INFO_DB,
            storeName: INFO_DB,
            driver: lf.INDEXEDDB
        });
        this.chatListDB = lf.createInstance({
            name: CHAT_LIST_DB,
            storeName: CHAT_LIST_DB,
            driver: lf.INDEXEDDB
        });
        this.profileListDB = lf.createInstance({
            name: PROFILE_LIST_DB,
            storeName: PROFILE_LIST_DB,
            driver: lf.INDEXEDDB
        });
        this.postCounterDB = lf.createInstance({
            name: POST_COUNTER_DB,
            storeName: POST_COUNTER_DB,
            driver: lf.INDEXEDDB
        });
    }
    /** Local Storage */
    getRecord(key) {
        return localStorage.getItem(key);
    }
    setRecord(key, value) {
        localStorage.setItem(key, value);
    }
    removeRecord(key) {
        localStorage.removeItem(key);
    }
    /********************************************************************/
    /** FileDB Media database */
    setMediaWithKey(key, value) {
        this.filesDB.setItem(key, value);
    }
    getMediaWithKey(key) {
        return this.filesDB.getItem(key);
    }
    deleteMediaWithKey(key) {
        return this.filesDB.removeItem(key);
    }
    /********************************************************************/
    /** Info DB */
    setItemInInfoDB(key, value) {
        this.infoDB.setItem(key, value);
    }
    getItemFromInfoDB(key) {
        return this.infoDB.getItem(key);
    }
    /********************************************************************/
    /** Cache of type IChat .. which are the groups and channels */
    setChatToCache(key, value) {
        this.chatListDB
            .setItem(key, value)
            .catch(err => console.log('Could not set chat to cache: ', key));
    }
    getChatFromCache(key) {
        return this.chatListDB.getItem(key);
    }
    /********************************************************************/
    /** Cache of type IProfile .. which are the contacts */
    setProfileToCache(key, value) {
        this.profileListDB
            .setItem(key, value)
            .catch(err => console.log('Could not set profile to cache: ', key));
    }
    getProfileFromCache(key) {
        return this.profileListDB.getItem(key);
    }
    setPostCounterToCache(key, value) {
        this.postCounterDB
            .setItem(key, value)
            .catch(err => console.log('Could not set profile to cache: ', key));
    }
    getPostCounterToCache(key) {
        return this.postCounterDB.getItem(key);
    }
    /********************************************************************/
    // get api url
    getApiUrl(type) {
        let tempUrl = this.getRecord(BASE_URL_LOCAL_STORAGE_KEY);
        if (!tempUrl) {
            tempUrl = DEFAULT_BASE_URL_API;
        }
        switch (type) {
            case UPLOAD_API:
                return `https://${tempUrl}${UPLOAD_URL_API_SUFFIX}`;
            case DOWNLOAD_API:
                return `https://${tempUrl}${DOWNLOAD_URL_API_SUFFIX}`;
            case META_API:
                return `https://${tempUrl}${META_URL_API_SUFFIX}`;
            case CHANNEL_API:
                return `https://${tempUrl}${CHANNEL_URL_API_SUFFIX}`;
            case WS_API:
                return `wss://${tempUrl}${WS_URL_API_SUFFIX}`;
            default:
                return;
        }
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
