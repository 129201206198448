import { Action } from '@ngrx/store';
import { Whitelist } from 'src/models/Whitelist';

export const enum WhitelistPatternsUIActionTypes {
  UI_WHITELIST_PATTERNS_RESET_PAGING = 'UI_WHITELIST_PATTERNS_RESET_PAGING',
  UI_GET_NEXT_WHITELIST_PATTERNS_PAGE = 'UI_GET_NEXT_WHITELIST_PATTERNS_PAGE',
  UI_GET_PREV_WHITELIST_PATTERNS_PAGE = 'UI_GET_PREV_WHITELIST_PATTERNS_PAGE',
  UI_ADD_WHITELIST_PATTERNS_TO_LIST = 'UI_ADD_WHITELIST_PATTERNS_TO_LIST',
  UI_ADD_WHITELIST_PATTERNS_SUCCESS = 'UI_ADD_WHITELIST_PATTERNS_SUCCESS',
  UI_DELETE_PATTERNS_FROM_WHITELIST = 'UI_DELETE_PATTERNS_FROM_WHITELIST',
  UI_DELETE_PATTERNS_FROM_WHITELIST_SUCCESS = 'UI_DELETE_PATTERNS_FROM_WHITELIST_SUCCESS',
  UI_RECEIVED_WHITELIST_PATTERNS_HISTORY_CHUNK = 'UI_RECEIVED_WHITELIST_PATTERNS_HISTORY_CHUNK',
  UI_WHITELIST_PATTERNS_SET_IDLE_STATUS = 'UI_WHITELIST_PATTERNS_SET_IDLE_STATUS',
  UI_RESET_WHITELIST_PATTERNS = 'UI_RESET_WHITELIST_PATTERNS'
}

export class ResetWhitelistPatternsPaging implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_WHITELIST_PATTERNS_RESET_PAGING;
}

export class GetNextWhitelistPatternsPage implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_GET_NEXT_WHITELIST_PATTERNS_PAGE;
  constructor() {}
}
export class GetPrevWhitelistPatternsPage implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_GET_PREV_WHITELIST_PATTERNS_PAGE;
  constructor() {}
}

export class AddWhitelistPatternsToList implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_ADD_WHITELIST_PATTERNS_TO_LIST;
  constructor(public chat_id: string, public users: Whitelist[]) {}
}

export class AddWhitelistPatternsSuccess implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_ADD_WHITELIST_PATTERNS_SUCCESS;
  constructor(public data: Whitelist[]) {}
}

export class DeletePatternFromWhitelist implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_DELETE_PATTERNS_FROM_WHITELIST;
  constructor(public users: string[], public chat_id: string) {}
}

export class DeletePatternCallback implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_DELETE_PATTERNS_FROM_WHITELIST_SUCCESS;
  constructor(public users: string[]) {}
}

export class ReceivedWhitelistPatternsHistoryChunk implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_RECEIVED_WHITELIST_PATTERNS_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: Whitelist[],
    public hash: string
  ) {}
}

export class SetStatusAsIdle implements Action {
  readonly type =
    WhitelistPatternsUIActionTypes.UI_WHITELIST_PATTERNS_SET_IDLE_STATUS;
}

export class ResetWhitelistPatterns implements Action {
  readonly type = WhitelistPatternsUIActionTypes.UI_RESET_WHITELIST_PATTERNS;
}

export type Actions =
  | ResetWhitelistPatternsPaging
  | GetNextWhitelistPatternsPage
  | GetPrevWhitelistPatternsPage
  | ReceivedWhitelistPatternsHistoryChunk
  | AddWhitelistPatternsSuccess
  | DeletePatternCallback
  | SetStatusAsIdle
  | ResetWhitelistPatterns;
