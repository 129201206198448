import { Actions } from './bookingExceptions.actions';
import * as actions from './bookingExceptions.actions';
import { BookingExceptions } from 'src/models/BookingExceptions';

const INITIAL_STATE: BookingExceptions[] = [];

export function bookingExceptionsReducer(
  state = INITIAL_STATE,
  action: Actions
): BookingExceptions[] {
  switch (action.type) {
    case actions.BookingExceptionsTypes.RECEIVE_BOOKING_EXCEPTIONS: {
      if (
        !state.find(bookingPeriods => bookingPeriods.chat_id === action.chatId)
      ) {
        return [...state, { data: action.dates, chat_id: action.chatId }];
      } else {
        return state.map(bookingExceptions => {
          if (bookingExceptions.chat_id === action.chatId) {
            let updatedBooking: BookingExceptions = {};
            updatedBooking = {
              ...bookingExceptions,
              ...{ data: action.dates }
            };
            return updatedBooking;
          }
          return bookingExceptions;
        });
      }
    }
    case actions.BookingExceptionsTypes.DELETE_BOOKING_EXCEPTIONS: {
      return state.map(bookingExceptions => {
        if (bookingExceptions.chat_id === action.chatId) {
          const updatedBooking: BookingExceptions = { ...bookingExceptions };
          if (bookingExceptions.data && action.dates) {
            const updatedBookingExceptionDates = bookingExceptions.data.filter(
              date => !action.dates.includes(date)
            );
            updatedBooking.data = updatedBookingExceptionDates;
          }
          return updatedBooking;
        }
        return bookingExceptions;
      });
    }
    case actions.BookingExceptionsTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
