import { Action } from '@ngrx/store';
import { MapMarker, RcvdMapMarkerMessage } from 'src/models/MapMarker';

export const enum MapMarkerActionTypes {
  UI_CREATE_MAP_MARKER = 'UI_CREATE_MAP_MARKER',
  UI_GET_MAP_MARKER = 'UI_GET_MAP_MARKER',
  UI_MAP_MARKER_CREATED = 'UI_MAP_MARKER_CREATED',
  UI_UPDATE_MAP_MARKER = 'UI_UPDATE_MAP_MARKER',
  UI_MAP_MARKER_DETAILS_RECEIVED = 'UI_MAP_MARKER_DETAILS_RECEIVED',
  UI_MAP_MARKER_LIST_RECEIVED = 'UI_MAP_MARKER_LIST_RECEIVED',
  UI_DELETE_MAP_MARKER = 'UI_DELETE_MAP_MARKER',
  UI_MAP_MARKER_DELETED = 'UI_MAP_MARKER_DELETED',
  UI_MAP_MARKER_GET_NEXT_PAGE = 'UI_MAP_MARKER_GET_NEXT_PAGE',
  UI_MAP_MARKER_GET_PREV_PAGE = 'UI_MAP_MARKER_GET_PREV_PAGE',
  UI_MAP_MARKER_SET_IDLE_STATUS = 'UI_MAP_MARKER_SET_IDLE_STATUS',
  UI_MAP_MARKER_RESET_PAGING = 'UI_MAP_MARKER_RESET_PAGING',
  UI_MAP_MARKER_SET_SELECTED_CHAT = 'UI_MAP_MARKER_SET_SELECTED_CHAT'
}

export class CreateMapMarker implements Action {
  readonly type = MapMarkerActionTypes.UI_CREATE_MAP_MARKER;
  constructor(public payload: MapMarker) {}
}

export class GetMapMarker implements Action {
  readonly type = MapMarkerActionTypes.UI_GET_MAP_MARKER;
  constructor(public id: string) {}
}

export class MapMarkerCreated implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_CREATED;
  constructor(public payload: MapMarker) {}
}

export class UpdateMapMarker implements Action {
  readonly type = MapMarkerActionTypes.UI_UPDATE_MAP_MARKER;
  constructor(public id: string, public mapMarkerDetails: MapMarker) {}
}

export class MapMarkerDetailsReceived implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_DETAILS_RECEIVED;
  constructor(public payload: MapMarker) {}
}

export class MapMarkerListReceived implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_LIST_RECEIVED;
  constructor(public payload: RcvdMapMarkerMessage) {}
}

export class DeleteMapMarker implements Action {
  readonly type = MapMarkerActionTypes.UI_DELETE_MAP_MARKER;
  constructor(public id: string) {}
}

export class MapMarkerDeleted implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_DELETED;
  constructor(public id: string) {}
}

export class GetNextMapMarkerPage implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_GET_NEXT_PAGE;
}

export class GetPrevMapMarkerPage implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_GET_PREV_PAGE;
}
export class SetStatusAsIdle implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_SET_IDLE_STATUS;
}

export class ResetMapMarkerPaging implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_RESET_PAGING;
}

export class SetMapMarkerSelectedChat implements Action {
  readonly type = MapMarkerActionTypes.UI_MAP_MARKER_SET_SELECTED_CHAT;
  constructor(public selected_chat: string, public typeLocation?: string) {}
}

export type Actions =
  | CreateMapMarker
  | GetMapMarker
  | MapMarkerCreated
  | UpdateMapMarker
  | MapMarkerDetailsReceived
  | MapMarkerListReceived
  | DeleteMapMarker
  | MapMarkerDeleted
  | GetNextMapMarkerPage
  | GetPrevMapMarkerPage
  | SetStatusAsIdle
  | ResetMapMarkerPaging
  | SetMapMarkerSelectedChat;
