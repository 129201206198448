import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { PaymentDispatchers } from './payment.dispatchers';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { PlansGateway } from 'src/app/network/gateway/plans.gateway';
import * as PaymentMehods from 'src/models/IPayment';
export class PaymentEffects {
    constructor(actions$, _socketGateway, _plansGateway, _paymentDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._plansGateway = _plansGateway;
        this._paymentDispatchers = _paymentDispatchers;
        this.getPlans = this.actions$.pipe(ofType("[PaymentReducer]GET_PRICE_PLANS" /* GET_PRICE_PLANS */), map(() => {
            // this._plansGateway.getAllPlans().subscribe(res => {
            //   const plans = [];
            //   for (const key in res) {
            //     if (res.hasOwnProperty(key)) {
            //       plans.push(res[key]);
            //     }
            //   }
            //   this._paymentDispatchers.setPricePlans(plans);
            // });
        }));
        // @Effect({ dispatch: false })
        // setPaymentAuthInfo = this.actions$.pipe(
        //   ofType(PaymentActions.PaymentActionTypes.RCVD_PAYMENT_AUTH_INFO),
        //   map((action: PaymentActions.RcvdPaymentAuthInfo) => {
        //     if (action.payload && action.payload.plan_id) {
        //       this._plansGateway
        //         .getPlan(action.payload.plan_id)
        //         .pipe(take(1))
        //         .subscribe(res => {
        //           this._paymentDispatchers.setCurrentPayment(res);
        //         });
        //     }
        //   })
        // );
        // @Effect({ dispatch: false })
        // rcvdPaymentAuthInfo = this.actions$.pipe(
        //   ofType(PaymentActions.PaymentActionTypes.SET_PAYMENT_INFO),
        //   map((action: PaymentActions.SetPaymentInfo) => {
        //     if (action.payload && action.payload.plan_id) {
        //       this._plansGateway
        //         .getPlan(action.payload.plan_id)
        //         .pipe(take(1))
        //         .subscribe(res => {
        //           this._paymentDispatchers.setCurrentPayment(res);
        //         });
        //     }
        //   })
        // );
        this.paymentSet = this.actions$.pipe(ofType("[PaymentReducer]PAYMENT_SET" /* PAYMENT_SET */), map(() => {
            this._paymentDispatchers.getPaymentInfo();
            // this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
        }));
        this.freepaymentSet = this.actions$.pipe(ofType("[PaymentReducer]SET_SELECTED_FREE_PAYMENT" /* SET_SELECTED_FREE_PAYMENT */), map(() => {
            this._socketGateway.sendSocketMessage(new PaymentMehods.SetFreePayment());
        }));
        this.freepaymentCancel = this.actions$.pipe(ofType("[PaymentReducer]SET_CANCELED_FREE_PAYMENT" /* SET_CANCELED_FREE_PAYMENT */), map(() => {
            this._socketGateway.sendSocketMessage(new PaymentMehods.CancelFreePayment());
        }));
        this.getPaymentInfo = this.actions$.pipe(ofType("[PaymentReducer]GET_PAYMENT_INFO" /* GET_PAYMENT_INFO */), map(() => {
            this._socketGateway.sendSocketMessage(new PaymentMehods.GetPaymentInfo());
        }));
        this.getPaymentToken = this.actions$.pipe(ofType("[PaymentReducer]GET_PAYMENT_TOKEN" /* GET_PAYMENT_TOKEN */), map(() => {
            this._socketGateway.sendSocketMessage(new PaymentMehods.GetPaymentToken());
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "getPlans", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "paymentSet", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "freepaymentSet", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "freepaymentCancel", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "getPaymentInfo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], PaymentEffects.prototype, "getPaymentToken", void 0);
