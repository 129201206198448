import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as otherDriverTripsActionTypes from './otherDriverTrips.actions';

import { AppState } from 'src/models/AppState';
@Injectable({
  providedIn: 'root'
})
export class OtherTripsDispatchers {
  constructor(private _store: Store<AppState>) {}

  getOtherTrips(trip_id: number, account_id: string): void {
    this._store.dispatch(
      new otherDriverTripsActionTypes.GetOtherTrips(trip_id, account_id)
    );
  }

  driverTripsReceived(payload: any): void {
    this._store.dispatch(
      new otherDriverTripsActionTypes.OtherTipsReceived(payload)
    );
  }
}
