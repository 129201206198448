import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';

import { Whitelist, WhitelistUI } from 'src/models/Whitelist';
import { WhitelistPatternsUiDispatchers } from 'src/app/store/whitelistPatternsUI/whitelistPatternsUI.dispatchers';
import { WhitelistUiDispatchers } from 'src/app/store/whitelistMainUI/whitelistMainUI.dispatchers';
import { AppChannelSettingsDispatchers } from 'src/app/store/appChannelSettings/appChannelSettings.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {
  public _isPattern = new BehaviorSubject<boolean>(false);
  public isPattern$ = this._isPattern.asObservable();

  public _isLoading = new BehaviorSubject<boolean>(true);
  public isLoading$ = this._isLoading.asObservable();

  public _userViewer = new BehaviorSubject<Whitelist>({});
  public userViewer$ = this._userViewer.asObservable();

  constructor(
    private _whitelistDispatchers: WhitelistUiDispatchers,
    private _whitelistPatternsDispatchers: WhitelistPatternsUiDispatchers,
    private _appChannelSettingsDispatchers: AppChannelSettingsDispatchers,
    private _storeSelector: RootStoreSelectors
  ) {}

  resetWhitelistPaging(name: string) {
    this._whitelistDispatchers.resetPaging(name);
  }

  getWhitelistNextPage() {
    this._whitelistDispatchers.getNextWhitelistPage();
  }
  getWhitelistPrevPage() {
    this._whitelistDispatchers.getPrevWhitelistPage();
  }

  addSignupIDToWhitelist(chat_id: string, users: Whitelist[]) {
    this._whitelistDispatchers.addSignupIDToWhitelist(chat_id, users);
  }

  deleteUserFromWhitelist(chat_id: string, users: string[]) {
    this._whitelistDispatchers.deleteUsersFromWhitelist(chat_id, users);
  }

  get whitelistList$() {
    return this._storeSelector.getWhitelist$;
  }

  resetWhitelistPatternsPaging() {
    this._whitelistPatternsDispatchers.resetPaging();
  }

  getWhitelistPatternsNextPage() {
    this._whitelistPatternsDispatchers.getNextWhitelistPatternsPage();
  }

  getWhitelistPatternsPrevPage() {
    this._whitelistPatternsDispatchers.getPrevWhitelistPatternsPage();
  }

  addWhitelistPatternsToList(chat_id: string, users: Whitelist[]) {
    this._whitelistPatternsDispatchers.addWhitelistPatternsToList(
      chat_id,
      users
    );
  }

  deletePatternFromWhitelist(chat_id: string, users: string[]) {
    this._whitelistPatternsDispatchers.deletePatternFromWhitelist(
      chat_id,
      users
    );
  }

  get getWhitelistPatterns$() {
    return this._storeSelector.getWhitelistPatterns$;
  }

  get getWhitelistScreenState$(): Observable<WhitelistUI> {
    return this._storeSelector.getWhitelistScreenState$();
  }

  get getWhitelistPatternsScreenState$(): Observable<string> {
    return this._storeSelector.getWhitelistPatternsScreenState$();
  }

  reset() {
    this._whitelistDispatchers.reset();
  }

  resetWhitelistPatterns() {
    this._whitelistPatternsDispatchers.reset();
  }

  setLoadingScreenStatus(status: string) {
    this._whitelistDispatchers.setLoadingScreenStatus(status);
  }

  setAppChannelSettings(state: boolean) {
    this._appChannelSettingsDispatchers.setAppChannelSettings(state);
  }

  getAppChannelSettings() {
    this._appChannelSettingsDispatchers.getAppChannelSettings();
  }

  get getAppChannelSettings$() {
    return this._storeSelector.getAppChannelSettings$();
  }
}
