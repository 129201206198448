import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MapMarkerActions from './mapMarker.actions';

import { AppState } from 'src/models/AppState';
import { MapMarker, RcvdMapMarkerMessage } from 'src/models/MapMarker';

@Injectable({
  providedIn: 'root'
})
export class MapMarkerDispatchers {
  constructor(private _store: Store<AppState>) {}

  createMapMarker(mapMarker: MapMarker): void {
    this._store.dispatch(new MapMarkerActions.CreateMapMarker(mapMarker));
  }

  getMapMarker(id: string): void {
    this._store.dispatch(new MapMarkerActions.GetMapMarker(id));
  }

  mapMarkerCreated(mapMarker: MapMarker): void {
    this._store.dispatch(new MapMarkerActions.MapMarkerCreated(mapMarker));
  }

  updateMapMarker(id: string, mapMarker: MapMarker): void {
    this._store.dispatch(new MapMarkerActions.UpdateMapMarker(id, mapMarker));
  }

  mapMarkerDetailsReceived(mapMarker: MapMarker): void {
    this._store.dispatch(
      new MapMarkerActions.MapMarkerDetailsReceived(mapMarker)
    );
  }

  mapMarkerListReceived(payload: RcvdMapMarkerMessage): void {
    this._store.dispatch(new MapMarkerActions.MapMarkerListReceived(payload));
  }

  deleteMapMarker(id: string): void {
    this._store.dispatch(new MapMarkerActions.DeleteMapMarker(id));
  }

  mapMarkerDeleted(id: string): void {
    this._store.dispatch(new MapMarkerActions.MapMarkerDeleted(id));
  }

  getNextMapMarkerPage() {
    this._store.dispatch(new MapMarkerActions.GetNextMapMarkerPage());
  }

  getPrevMapMarkerPage() {
    this._store.dispatch(new MapMarkerActions.GetPrevMapMarkerPage());
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new MapMarkerActions.SetStatusAsIdle());
  }

  resetMapMarkerPaging() {
    this._store.dispatch(new MapMarkerActions.ResetMapMarkerPaging());
  }

  setMapMarkerSelectedChat(selected_chat: string, type?: string) {
    this._store.dispatch(
      new MapMarkerActions.SetMapMarkerSelectedChat(selected_chat, type)
    );
  }
}
