import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { ServiceOrdersUiDispatchers } from './serviceOrdersUI.dispatchers';
import { GetServiceOrdersMethod } from 'src/models/PaymentGateways';
import { PageDirection } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
export class ServiceOrdersUiEffects {
    constructor(actions$, _socketGateway, _uiServiceOrdersDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiServiceOrdersDispatchers = _uiServiceOrdersDispatchers;
        this._store = _store;
        this.getNextServiceOrdersPage = this.actions$.pipe(ofType("UI_SERVICE_ORDERS_GET_NEXT_PAGE" /* UI_SERVICE_ORDERS_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.serviceOrdersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, serviceOrdersUiState]) => {
            const action = val;
            const prevRequest = serviceOrdersUiState.previousRequest;
            if (!(serviceOrdersUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                serviceOrdersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetServiceOrdersMethod(0, serviceOrdersUiState.eop, action.paymentOrderID, action.serviceOrderID));
            }
            else {
                this._uiServiceOrdersDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevServiceOrdersPage = this.actions$.pipe(ofType("UI_SERVICE_ORDERS_GET_PREV_PAGE" /* UI_SERVICE_ORDERS_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.serviceOrdersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, serviceOrdersUiState]) => {
            const action = val;
            const prevRequest = serviceOrdersUiState.previousRequest;
            if (!(serviceOrdersUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                serviceOrdersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetServiceOrdersMethod(1, serviceOrdersUiState.sop, action.paymentOrderID, action.serviceOrderID));
            }
            else {
                this._uiServiceOrdersDispatchers.setStatusAsIdle();
            }
        }));
        this.afterServiceOrdersResetPaging = this.actions$.pipe(ofType("UI_SERVICE_ORDERS_RESET_PAGING" /* UI_SERVICE_ORDERS_RESET_PAGING */), map((action) => {
            this._uiServiceOrdersDispatchers.getNextServiceOrdersPage(action.paymentOrderID, action.serviceOrderID);
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ServiceOrdersUiEffects.prototype, "getNextServiceOrdersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ServiceOrdersUiEffects.prototype, "getPrevServiceOrdersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ServiceOrdersUiEffects.prototype, "afterServiceOrdersResetPaging", void 0);
