/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-confirm-cancel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../abstract-frames/headerfull-frame/headerfull-frame.component.ngfactory";
import * as i3 from "../../abstract-frames/headerfull-frame/headerfull-frame.component";
import * as i4 from "../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i5 from "../../abstract-btns/border-btn/border-btn.component";
import * as i6 from "./subscription-confirm-cancel.component";
var styles_SubscriptionConfirmCancelComponent = [i0.styles];
var RenderType_SubscriptionConfirmCancelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionConfirmCancelComponent, data: {} });
export { RenderType_SubscriptionConfirmCancelComponent as RenderType_SubscriptionConfirmCancelComponent };
export function View_SubscriptionConfirmCancelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "app-headerfull-frame", [["action", ""]], null, [[null, "cancelNotfiy"], [null, "backNotfiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelNotfiy" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } if (("backNotfiy" === en)) {
        var pd_1 = (_co.backEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_HeaderfullFrameComponent_0, i2.RenderType_HeaderfullFrameComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderfullFrameComponent, [], { title: [0, "title"], showBack: [1, "showBack"] }, { cancelNotfiy: "cancelNotfiy", backNotfiy: "backNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 1, 9, "div", [["class", "confirm-submit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "confirm-submit-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are you sure you want to cancel your nandbox subscription? "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "confirm-submit-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-border-btn", [], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.goToPaymentPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_BorderBtnComponent_0, i4.RenderType_BorderBtnComponent)), i1.ɵdid(7, 573440, null, 0, i5.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Yes "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "app-border-btn", [], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.backEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_BorderBtnComponent_0, i4.RenderType_BorderBtnComponent)), i1.ɵdid(10, 573440, null, 0, i5.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" No "]))], function (_ck, _v) { var currVal_0 = "Cancel Subscription"; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SubscriptionConfirmCancelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscription-confirm-cancel", [], null, null, null, View_SubscriptionConfirmCancelComponent_0, RenderType_SubscriptionConfirmCancelComponent)), i1.ɵdid(1, 49152, null, 0, i6.SubscriptionConfirmCancelComponent, [], null, null)], null, null); }
var SubscriptionConfirmCancelComponentNgFactory = i1.ɵccf("app-subscription-confirm-cancel", i6.SubscriptionConfirmCancelComponent, View_SubscriptionConfirmCancelComponent_Host_0, { plan: "plan", url: "url" }, { closeNotify: "closeNotify", backNotify: "backNotify" }, []);
export { SubscriptionConfirmCancelComponentNgFactory as SubscriptionConfirmCancelComponentNgFactory };
