import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { SharedModule } from '../shared/shared.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractTablesModule } from '../abstract-tables/abstract-tables.module';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from '../abstract-controls/abstract-controls.module';

import { ListSelectorPoolComponent } from './list-selector-pool/list-selector-pool.component';
import { AccessorsModule } from '../accessors/accessors.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatRadioModule,
    ReactiveFormsModule,
    AbstractBtnsModule,
    AbstractTablesModule,
    AbstractFramesModule,
    AbstractControlsModule,
    AccessorsModule
  ],
  declarations: [ListSelectorPoolComponent],
  exports: [ListSelectorPoolComponent]
})
export class AbstractPoolsModule {}
