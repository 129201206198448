import { Pipe, PipeTransform } from '@angular/core';

import { HelperService } from 'src/app/core/helper.service';

@Pipe({
  name: 'mediaDuration'
})
export class MediaDurationPipe implements PipeTransform {
  constructor(private _helperService: HelperService) {}
  transform(millis: number): string {
    return this._helperService.convertMilliSecsToReadableTime(millis);
  }
}
