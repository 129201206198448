import { Store } from '@ngrx/store';
import * as CouponActions from './coupon.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CouponDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    couponReceived(payload) {
        this._store.dispatch(new CouponActions.CouponReceived(payload));
    }
    getCoupon(id) {
        this._store.dispatch(new CouponActions.GetCoupon(id));
    }
    createCoupon(payload, isCreateFromBool) {
        this._store.dispatch(new CouponActions.CreateCoupon(payload, isCreateFromBool));
    }
    receivedCouponsList(payload) {
        this._store.dispatch(new CouponActions.ReceivedCouponsList(payload));
    }
    updateCoupon(payload) {
        this._store.dispatch(new CouponActions.UpdateCoupon(payload));
    }
    deleteCoupon(payload) {
        this._store.dispatch(new CouponActions.DeleteCoupon(payload));
    }
}
CouponDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CouponDispatchers_Factory() { return new CouponDispatchers(i0.ɵɵinject(i1.Store)); }, token: CouponDispatchers, providedIn: "root" });
