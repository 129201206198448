import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-delete-frame-icon',
  template: `
    <svg class="svg-icon icon-frame">
      <use xlink:href="./assets/svg/app-sections.svg#icon-delete-frame"></use>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteFrameIconComponent {
  @Input() fillColor: string;
}
